/* eslint-disable */
import { TypedDocumentNode as DocumentNode } from "@graphql-typed-document-node/core";
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K];
};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>;
};
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>;
};
export type MakeEmpty<
  T extends { [key: string]: unknown },
  K extends keyof T,
> = { [_ in K]?: never };
export type Incremental<T> =
  | T
  | {
      [P in keyof T]?: P extends " $fragmentName" | "__typename" ? T[P] : never;
    };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string };
  String: { input: string; output: string };
  Boolean: { input: boolean; output: boolean };
  Int: { input: number; output: number };
  Float: { input: number; output: number };
  /** The `JSON` scalar type represents JSON values as specified by [ECMA-404](http://www.ecma-international.org/publications/files/ECMA-ST/ECMA-404.pdf). */
  JSON: { input: any; output: any };
  /** An ISO 8601-encoded datetime */
  Timestamp: { input: any; output: any };
  TransportOptions: { input: any; output: any };
  _DirectiveExtensions: { input: any; output: any };
};

export type AcceptTradeInInput = {
  conditionUuid: Scalars["String"]["input"];
  tradeInId: Scalars["String"]["input"];
};

export type Ad = INode & {
  __typename?: "Ad";
  _id?: Maybe<Scalars["ID"]["output"]>;
  _source?: Maybe<Scalars["String"]["output"]>;
  adCampaignUuid?: Maybe<Scalars["String"]["output"]>;
  adGroupName?: Maybe<Scalars["String"]["output"]>;
  adGroupUuid?: Maybe<Scalars["String"]["output"]>;
  advertiserUuid?: Maybe<Scalars["String"]["output"]>;
  campaignId?: Maybe<Scalars["Int"]["output"]>;
  deletedAt?: Maybe<Scalars["Timestamp"]["output"]>;
  format?: Maybe<core_apimessages_AdFormat>;
  id?: Maybe<Scalars["Int"]["output"]>;
  imageAdCreative?: Maybe<core_apimessages_ImageAdCreative>;
  localizedContents?: Maybe<Array<Maybe<core_apimessages_AdLocalizedContent>>>;
  uuid?: Maybe<Scalars["String"]["output"]>;
};

export type AdCampaign = INode & {
  __typename?: "AdCampaign";
  _id?: Maybe<Scalars["ID"]["output"]>;
  _source?: Maybe<Scalars["String"]["output"]>;
  adGroupCollection?: Maybe<AdGroupCollection>;
  advertiserUuid?: Maybe<Scalars["String"]["output"]>;
  description?: Maybe<Scalars["String"]["output"]>;
  endsAt?: Maybe<Scalars["Timestamp"]["output"]>;
  name?: Maybe<Scalars["String"]["output"]>;
  startsAt?: Maybe<Scalars["Timestamp"]["output"]>;
  status?: Maybe<core_apimessages_AdCampaign_AdStatus>;
  uuid?: Maybe<Scalars["String"]["output"]>;
};

export type AdCampaignCollection = {
  __typename?: "AdCampaignCollection";
  _ids?: Maybe<Array<Maybe<Scalars["ID"]["output"]>>>;
  _source?: Maybe<Scalars["String"]["output"]>;
  adCampaigns?: Maybe<Array<Maybe<AdCampaign>>>;
  nodes?: Maybe<Array<Maybe<AdCampaign>>>;
  pageInfo?: Maybe<rql_PageInfo>;
};

export type AdCollection = {
  __typename?: "AdCollection";
  _ids?: Maybe<Array<Maybe<Scalars["ID"]["output"]>>>;
  _source?: Maybe<Scalars["String"]["output"]>;
  ads?: Maybe<Array<Maybe<Ad>>>;
  nodes?: Maybe<Array<Maybe<Ad>>>;
  pageInfo?: Maybe<rql_PageInfo>;
};

export type AdGroup = INode & {
  __typename?: "AdGroup";
  _id?: Maybe<Scalars["ID"]["output"]>;
  _source?: Maybe<Scalars["String"]["output"]>;
  adCampaignUuid?: Maybe<Scalars["String"]["output"]>;
  adCollection?: Maybe<AdCollection>;
  adGroupKeywords?: Maybe<Array<Maybe<core_apimessages_AdGroupKeyword>>>;
  adZoneCollection?: Maybe<AdZoneCollection>;
  description?: Maybe<Scalars["String"]["output"]>;
  name?: Maybe<Scalars["String"]["output"]>;
  status?: Maybe<core_apimessages_AdGroup_AdStatus>;
  targetedAdZoneSlugs?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
  targetedAdZones?: Maybe<Array<Maybe<core_apimessages_AdGroupZone>>>;
  targetedBrandSlugs?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
  targetedCategoryUuids?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
  targetedCountryCodes?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
  targetedDevices?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
  uuid?: Maybe<Scalars["String"]["output"]>;
};

export type AdGroupCollection = {
  __typename?: "AdGroupCollection";
  _ids?: Maybe<Array<Maybe<Scalars["ID"]["output"]>>>;
  _source?: Maybe<Scalars["String"]["output"]>;
  adGroups?: Maybe<Array<Maybe<AdGroup>>>;
  nodes?: Maybe<Array<Maybe<AdGroup>>>;
  pageInfo?: Maybe<rql_PageInfo>;
};

export type AdZone = INode & {
  __typename?: "AdZone";
  _id?: Maybe<Scalars["ID"]["output"]>;
  _source?: Maybe<Scalars["String"]["output"]>;
  adGroupCollection?: Maybe<AdGroupCollection>;
  description?: Maybe<Scalars["String"]["output"]>;
  name?: Maybe<Scalars["String"]["output"]>;
  slug?: Maybe<Scalars["String"]["output"]>;
  status?: Maybe<core_apimessages_AdZone_AdZoneStatus>;
  uuid?: Maybe<Scalars["String"]["output"]>;
};

export type AdZoneCollection = {
  __typename?: "AdZoneCollection";
  _ids?: Maybe<Array<Maybe<Scalars["ID"]["output"]>>>;
  _source?: Maybe<Scalars["String"]["output"]>;
  adZones?: Maybe<Array<Maybe<AdZone>>>;
  nodes?: Maybe<Array<Maybe<AdZone>>>;
  pageInfo?: Maybe<rql_PageInfo>;
};

export type Advertiser = INode & {
  __typename?: "Advertiser";
  _id?: Maybe<Scalars["ID"]["output"]>;
  _source?: Maybe<Scalars["String"]["output"]>;
  adCampaignCollection?: Maybe<AdCampaignCollection>;
  brandSlugs?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
  name?: Maybe<Scalars["String"]["output"]>;
  shopSlugs?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
  uuid?: Maybe<Scalars["String"]["output"]>;
};

export type Article = INode & {
  __typename?: "Article";
  _id?: Maybe<Scalars["ID"]["output"]>;
  _source?: Maybe<Scalars["String"]["output"]>;
  articleCategories?: Maybe<Array<Maybe<core_apimessages_ArticleCategory>>>;
  categories?: Maybe<Array<Maybe<core_apimessages_Category>>>;
  /** articles.id */
  id?: Maybe<Scalars["String"]["output"]>;
  images?: Maybe<Array<Maybe<core_apimessages_Image>>>;
  slug?: Maybe<Scalars["String"]["output"]>;
  title?: Maybe<Scalars["String"]["output"]>;
  webLink?: Maybe<core_apimessages_Link>;
};

export type ArticleimagesArgs = {
  input: Input_core_apimessages_ImagesRequest;
};

export type Brand = INode & {
  __typename?: "Brand";
  _id?: Maybe<Scalars["ID"]["output"]>;
  _source?: Maybe<Scalars["String"]["output"]>;
  /** brands.uuid */
  id?: Maybe<Scalars["String"]["output"]>;
  images?: Maybe<Array<Maybe<core_apimessages_Image>>>;
  manager?: Maybe<rql_BrandManager>;
  metrics?: Maybe<reverb_search_SearchResponse>;
  name?: Maybe<Scalars["String"]["output"]>;
  slug?: Maybe<Scalars["String"]["output"]>;
  uuid?: Maybe<Scalars["String"]["output"]>;
  webLink?: Maybe<core_apimessages_Link>;
};

export type BrandimagesArgs = {
  input: Input_core_apimessages_ImagesRequest;
};

export type BrandmetricsArgs = {
  input?: InputMaybe<Input_rql_BrandOrderSearch>;
};

export type BrowsePage = INode & {
  __typename?: "BrowsePage";
  _id?: Maybe<Scalars["ID"]["output"]>;
  _source?: Maybe<Scalars["String"]["output"]>;
  breadcrumbs?: Maybe<Array<Maybe<core_apimessages_BrowsePageBreadcrumb>>>;
  combinedFilters?: Maybe<Scalars["String"]["output"]>;
  id?: Maybe<Scalars["String"]["output"]>;
  links?: Maybe<Array<Maybe<core_apimessages_BrowsePageLink>>>;
  ownFilters?: Maybe<Scalars["String"]["output"]>;
  title?: Maybe<Scalars["String"]["output"]>;
  url?: Maybe<Scalars["String"]["output"]>;
};

export type CMSPage = INode & {
  __typename?: "CMSPage";
  _id?: Maybe<Scalars["ID"]["output"]>;
  _source?: Maybe<Scalars["String"]["output"]>;
  actionText?: Maybe<Scalars["String"]["output"]>;
  actionUrl?: Maybe<Scalars["String"]["output"]>;
  /** @deprecated Field no longer supported */
  albumIds?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
  authorId?: Maybe<Scalars["String"]["output"]>;
  authorName?: Maybe<Scalars["String"]["output"]>;
  breadcrumbs?: Maybe<Scalars["String"]["output"]>;
  channels?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
  /** @deprecated Field no longer supported */
  componentIds?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
  components?: Maybe<Array<Maybe<PublicComponent>>>;
  darkModeReady?: Maybe<Scalars["Boolean"]["output"]>;
  description?: Maybe<Scalars["String"]["output"]>;
  goLiveAt?: Maybe<Scalars["String"]["output"]>;
  headerStyle?: Maybe<Scalars["String"]["output"]>;
  id?: Maybe<Scalars["String"]["output"]>;
  image?: Maybe<cms_PageImage>;
  imageAttributionUri?: Maybe<Scalars["String"]["output"]>;
  layoutId?: Maybe<Scalars["String"]["output"]>;
  layoutSlug?: Maybe<Scalars["String"]["output"]>;
  live?: Maybe<Scalars["Boolean"]["output"]>;
  locale?: Maybe<Scalars["String"]["output"]>;
  metaDescription?: Maybe<Scalars["String"]["output"]>;
  metaImage?: Maybe<cms_PageImage>;
  metaTitle?: Maybe<Scalars["String"]["output"]>;
  noIndex?: Maybe<Scalars["Boolean"]["output"]>;
  /** Flag used to thread to the frontend to indicate a cms slug was not found and we should render the not found page. */
  pageNotFound?: Maybe<Scalars["Boolean"]["output"]>;
  pageType?: Maybe<Scalars["String"]["output"]>;
  searchPlaceholder?: Maybe<Scalars["String"]["output"]>;
  secondaryImage?: Maybe<cms_PageImage>;
  slug?: Maybe<Scalars["String"]["output"]>;
  targetLocales?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
  title?: Maybe<Scalars["String"]["output"]>;
};

export type CSP = INode & {
  __typename?: "CSP";
  _id?: Maybe<Scalars["ID"]["output"]>;
  _source?: Maybe<Scalars["String"]["output"]>;
  averageReviewRating?: Maybe<Scalars["Float"]["output"]>;
  body?: Maybe<Scalars["String"]["output"]>;
  brand?: Maybe<Brand>;
  brandUuid?: Maybe<Scalars["String"]["output"]>;
  breadcrumbs?: Maybe<Array<Maybe<core_apimessages_ProductBreadcrumb>>>;
  canonicalProductIds?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
  canonicalProductImageApprovedIds?: Maybe<
    Array<Maybe<Scalars["String"]["output"]>>
  >;
  canonicalProducts?: Maybe<Array<Maybe<CanonicalProduct>>>;
  categories?: Maybe<Array<Maybe<Category>>>;
  categoryRootUuid?: Maybe<Scalars["String"]["output"]>;
  categoryUuids?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
  expressSaleItemBid?: Maybe<core_apimessages_CSPExpressSaleItemBid>;
  expressSalePriceEstimate?: Maybe<reverb_pricing_PriceRecommendation>;
  featuredListing?: Maybe<arbiter_FeaturedListingResponse>;
  finishes?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
  generic?: Maybe<Scalars["Boolean"]["output"]>;
  /** comparison_shopping_pages.id */
  id?: Maybe<Scalars["String"]["output"]>;
  image?: Maybe<core_apimessages_Image>;
  images?: Maybe<Array<Maybe<core_apimessages_Image>>>;
  indexedFeaturedListing?: Maybe<Listing>;
  inventory?: Maybe<core_apimessages_CSPInventory>;
  isTradeInEligible?: Maybe<Scalars["Boolean"]["output"]>;
  metaDescription?: Maybe<Scalars["String"]["output"]>;
  model?: Maybe<Scalars["String"]["output"]>;
  myReview?: Maybe<core_apimessages_ProductReview>;
  /** @deprecated Field no longer supported */
  newListingsLink?: Maybe<core_apimessages_Link>;
  priceGuide?: Maybe<core_apimessages_PriceGuide>;
  priceRecommendations?: Maybe<
    Array<Maybe<reverb_pricing_PriceRecommendation>>
  >;
  productReviewSearch?: Maybe<rql_CSPProductReviewSearchResponse>;
  reviews?: Maybe<core_apimessages_CSPReviews>;
  reviewsCount?: Maybe<Scalars["Int"]["output"]>;
  similarCSPs?: Maybe<Array<Maybe<CSP>>>;
  slug?: Maybe<Scalars["String"]["output"]>;
  specs?: Maybe<core_apimessages_CSPSpec>;
  summary?: Maybe<Scalars["String"]["output"]>;
  title?: Maybe<Scalars["String"]["output"]>;
  upcs?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
  /** @deprecated Field no longer supported */
  usedListingsLink?: Maybe<core_apimessages_Link>;
  videos?: Maybe<core_apimessages_CSPVideo>;
  webLink?: Maybe<core_apimessages_Link>;
};

export type CSPexpressSalePriceEstimateArgs = {
  input?: InputMaybe<Input_rql_ExpressSalePriceEstimate>;
};

export type CSPimageArgs = {
  input: Input_core_apimessages_ImagesRequest;
};

export type CSPimagesArgs = {
  input: Input_rql_ImageQuery;
};

export type CSPpriceRecommendationsArgs = {
  input?: InputMaybe<Input_rql_CSPPriceRecommendation>;
};

export type CSPproductReviewSearchArgs = {
  input?: InputMaybe<Input_reverb_search_ProductReviewsSearchRequest>;
};

export type CSPreviewsArgs = {
  input?: InputMaybe<Input_core_apimessages_CSPReviewsRequest>;
};

export type CSPsimilarCSPsArgs = {
  input?: InputMaybe<Input_reverb_search_CSPSearchRequest>;
};

export type CanonicalProduct = INode & {
  __typename?: "CanonicalProduct";
  _id?: Maybe<Scalars["ID"]["output"]>;
  _source?: Maybe<Scalars["String"]["output"]>;
  countryOfOrigin?: Maybe<Scalars["String"]["output"]>;
  estimatedNewListingPrice?: Maybe<core_apimessages_EstimatedNewListingPrice>;
  finish?: Maybe<Scalars["String"]["output"]>;
  id?: Maybe<Scalars["String"]["output"]>;
  model?: Maybe<Scalars["String"]["output"]>;
  name?: Maybe<Scalars["String"]["output"]>;
  packageSizeSuggestion?: Maybe<PackageSizeSuggestion>;
  year?: Maybe<Scalars["String"]["output"]>;
};

export type CanonicalProductestimatedNewListingPriceArgs = {
  input?: InputMaybe<Input_core_apimessages_NodeRequest_Lookup_Params_EstimatedNewListingPrices>;
};

export type Category = INode & {
  __typename?: "Category";
  _id?: Maybe<Scalars["ID"]["output"]>;
  _source?: Maybe<Scalars["String"]["output"]>;
  childCategories?: Maybe<Array<Maybe<Category>>>;
  children?: Maybe<Array<Maybe<core_apimessages_Category>>>;
  cmsLink?: Maybe<core_apimessages_Link>;
  cmsUrl?: Maybe<core_apimessages_Link>;
  /** categories.uuid */
  id?: Maybe<Scalars["String"]["output"]>;
  images?: Maybe<Array<Maybe<core_apimessages_Image>>>;
  leaf?: Maybe<Scalars["Boolean"]["output"]>;
  name?: Maybe<Scalars["String"]["output"]>;
  parent?: Maybe<core_apimessages_Category>;
  parentCategories?: Maybe<Array<Maybe<Category>>>;
  root?: Maybe<Scalars["Boolean"]["output"]>;
  rootSlug?: Maybe<Scalars["String"]["output"]>;
  slug?: Maybe<Scalars["String"]["output"]>;
  webLink?: Maybe<core_apimessages_Link>;
};

export type CategorychildCategoriesArgs = {
  input?: InputMaybe<Input_reverb_search_ListingsSearchRequest>;
};

export type CategoryimagesArgs = {
  input: Input_core_apimessages_ImagesRequest;
};

export type Checkout = INode & {
  __typename?: "Checkout";
  _id?: Maybe<Scalars["ID"]["output"]>;
  _source?: Maybe<Scalars["String"]["output"]>;
  action?: Maybe<CheckoutAction>;
  /** A JSON serialized hash */
  adyenPaymentAction?: Maybe<Scalars["String"]["output"]>;
  availablePaymentMethods?: Maybe<
    Array<Maybe<core_apimessages_CheckoutPaymentMethod>>
  >;
  billingAddress?: Maybe<core_apimessages_Address>;
  /** Semi-opaque identifier to bundle a checkout, for example RCP-USD-direct_checkout. */
  checkoutBundlingId?: Maybe<Scalars["String"]["output"]>;
  creditCard?: Maybe<core_apimessages_CreditCard>;
  guest?: Maybe<core_apimessages_CheckoutGuest>;
  /** Checkout UUID */
  id?: Maybe<Scalars["String"]["output"]>;
  isAllPaidWithBucks?: Maybe<Scalars["Boolean"]["output"]>;
  lineItems?: Maybe<Array<Maybe<core_apimessages_CheckoutLineItem>>>;
  orderBundle?: Maybe<OrderBundle>;
  orders?: Maybe<Array<Maybe<core_apimessages_CheckoutOrder>>>;
  paymentErrorMessage?: Maybe<Scalars["String"]["output"]>;
  paymentMethod?: Maybe<core_apimessages_CheckoutPaymentMethod>;
  shippingAddress?: Maybe<core_apimessages_Address>;
  source?: Maybe<core_apimessages_Checkout_Source>;
  status?: Maybe<core_apimessages_Checkout_Status>;
  /** Amount owed. Use this field to send the displayed amount owed value to a finalize checkout */
  total?: Maybe<core_apimessages_Checkout_Total>;
  type?: Maybe<core_apimessages_Checkout_Type>;
};

export type CheckoutactionArgs = {
  input?: InputMaybe<Input_rql_CheckoutActionRequestInput>;
};

export type CheckoutAction = INode & {
  __typename?: "CheckoutAction";
  _id?: Maybe<Scalars["ID"]["output"]>;
  _source?: Maybe<Scalars["String"]["output"]>;
  redirectUrl?: Maybe<Scalars["String"]["output"]>;
  type?: Maybe<core_apimessages_CheckoutAction_Type>;
};

export type CollectionHeader = INode & {
  __typename?: "CollectionHeader";
  _id?: Maybe<Scalars["ID"]["output"]>;
  _source?: Maybe<Scalars["String"]["output"]>;
  collectionId?: Maybe<Scalars["String"]["output"]>;
  collectionType?: Maybe<core_apimessages_CollectionHeader_CollectionType>;
  description?: Maybe<Scalars["String"]["output"]>;
  fullBleedImage?: Maybe<core_apimessages_Image>;
  id?: Maybe<Scalars["String"]["output"]>;
  metaDescription?: Maybe<Scalars["String"]["output"]>;
  metaTitle?: Maybe<Scalars["String"]["output"]>;
  /** sale related to this collection header */
  sale?: Maybe<core_apimessages_Sale>;
  searchFilterPresets?: Maybe<
    Array<Maybe<core_apimessages_SearchFilterPreset>>
  >;
  title?: Maybe<Scalars["String"]["output"]>;
};

export type CollectionHeaderfullBleedImageArgs = {
  transform?: InputMaybe<Scalars["String"]["input"]>;
};

export type CreateTradeInInput = {
  canonicalProductId: Scalars["String"]["input"];
  shopId: Scalars["String"]["input"];
};

export type CuratedSet = INode & {
  __typename?: "CuratedSet";
  _id?: Maybe<Scalars["ID"]["output"]>;
  _source?: Maybe<Scalars["String"]["output"]>;
  /** curated_sets.id */
  id?: Maybe<Scalars["String"]["output"]>;
  images?: Maybe<Array<Maybe<core_apimessages_Image>>>;
  setType?: Maybe<Scalars["String"]["output"]>;
  slug?: Maybe<Scalars["String"]["output"]>;
  title?: Maybe<Scalars["String"]["output"]>;
  webLink?: Maybe<core_apimessages_Link>;
};

export type CuratedSetimagesArgs = {
  input: Input_core_apimessages_ImagesRequest;
};

export type EstimatedNewListingPrice = INode & {
  __typename?: "EstimatedNewListingPrice";
  _id?: Maybe<Scalars["ID"]["output"]>;
  _source?: Maybe<Scalars["String"]["output"]>;
  id?: Maybe<Scalars["String"]["output"]>;
  price?: Maybe<core_apimessages_Money>;
};

export type ExpressSaleCheckout = INode & {
  __typename?: "ExpressSaleCheckout";
  _id?: Maybe<Scalars["ID"]["output"]>;
  _source?: Maybe<Scalars["String"]["output"]>;
  checkoutId?: Maybe<Scalars["String"]["output"]>;
  csp?: Maybe<CSP>;
  cspId?: Maybe<Scalars["String"]["output"]>;
  expressSaleItemBid?: Maybe<core_apimessages_CSPExpressSaleItemBid>;
  status?: Maybe<core_apimessages_ExpressSaleCheckout_Status>;
};

export type FavoriteEntity = Listing | Shop | core_apimessages_Favorite;

export type FeedEntry = Article | Listing;

export type FilterInput = {
  geo?: InputMaybe<GeoInput>;
  key: Scalars["String"]["input"];
  range?: InputMaybe<RangeInput>;
  selected?: InputMaybe<Scalars["Boolean"]["input"]>;
  value?: InputMaybe<Scalars["String"]["input"]>;
  values?: InputMaybe<Array<Scalars["String"]["input"]>>;
};

export type GearCollectionItem = INode & {
  __typename?: "GearCollectionItem";
  _id?: Maybe<Scalars["ID"]["output"]>;
  _source?: Maybe<Scalars["String"]["output"]>;
  canonicalProductId?: Maybe<Scalars["String"]["output"]>;
  categories?: Maybe<Array<Maybe<Category>>>;
  condition?: Maybe<core_apimessages_Condition>;
  created?: Maybe<Scalars["Boolean"]["output"]>;
  csp?: Maybe<CSP>;
  dataServicesPriceEstimate?: Maybe<reverb_pricing_PriceRecommendation>;
  deleted?: Maybe<Scalars["Boolean"]["output"]>;
  description?: Maybe<Scalars["String"]["output"]>;
  esPriceEstimate?: Maybe<reverb_search_PriceEstimate>;
  finish?: Maybe<Scalars["String"]["output"]>;
  /** This field returns ALL gallery images for Gear Collection Item show pages. Count is ignored when passed as an argument. */
  galleryImages?: Maybe<Array<Maybe<core_apimessages_Image>>>;
  hideListingDescription?: Maybe<Scalars["Boolean"]["output"]>;
  hideListingPhotos?: Maybe<Scalars["Boolean"]["output"]>;
  /** gear_collection_item.id */
  id?: Maybe<Scalars["String"]["output"]>;
  images?: Maybe<Array<Maybe<core_apimessages_Image>>>;
  listing?: Maybe<Listing>;
  make?: Maybe<Scalars["String"]["output"]>;
  model?: Maybe<Scalars["String"]["output"]>;
  notes?: Maybe<Scalars["String"]["output"]>;
  originalListing?: Maybe<Listing>;
  originalListingDescription?: Maybe<Scalars["String"]["output"]>;
  ownerCost?: Maybe<core_apimessages_Money>;
  price?: Maybe<core_apimessages_Money>;
  priceGuide?: Maybe<PriceGuide>;
  seedId?: Maybe<Scalars["String"]["output"]>;
  seedType?: Maybe<core_apimessages_GearCollectionItem_SeedType>;
  serialNumber?: Maybe<Scalars["String"]["output"]>;
  state?: Maybe<core_apimessages_GearCollectionItem_State>;
  subcategoryUuids?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
  tags?: Maybe<Array<Maybe<core_apimessages_GearCollectionItemTag>>>;
  title?: Maybe<Scalars["String"]["output"]>;
  updated?: Maybe<Scalars["Boolean"]["output"]>;
  year?: Maybe<Scalars["String"]["output"]>;
};

export type GearCollectionItemgalleryImagesArgs = {
  input: Input_core_apimessages_ImagesRequest;
};

export type GearCollectionItemimagesArgs = {
  input: Input_core_apimessages_ImagesRequest;
};

export type GeoInput = {
  distance: Scalars["Int"]["input"];
  postalCode: Scalars["String"]["input"];
};

export type INode = {
  _id?: Maybe<Scalars["ID"]["output"]>;
  _source?: Maybe<Scalars["String"]["output"]>;
};

export type InputAddressEntry = {
  countryCode?: InputMaybe<Scalars["String"]["input"]>;
  email?: InputMaybe<Scalars["String"]["input"]>;
  extendedAddress?: InputMaybe<Scalars["String"]["input"]>;
  locality?: InputMaybe<Scalars["String"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  phone?: InputMaybe<Scalars["String"]["input"]>;
  postalCode?: InputMaybe<Scalars["String"]["input"]>;
  region?: InputMaybe<Scalars["String"]["input"]>;
  streetAddress?: InputMaybe<Scalars["String"]["input"]>;
};

export type InputReverbSearchBumpedsortedlistingsquery = {
  bumpedMax?: InputMaybe<Scalars["Int"]["input"]>;
  condition?: InputMaybe<Scalars["String"]["input"]>;
  itemRegion?: InputMaybe<Scalars["String"]["input"]>;
  total?: InputMaybe<Scalars["Int"]["input"]>;
};

export type InputReverbSearchDistance = {
  unit?: InputMaybe<reverb_search_Distance_DistanceUnit>;
  value?: InputMaybe<Scalars["String"]["input"]>;
};

export type InputReverbSearchExperimentdata = {
  group?: InputMaybe<Scalars["String"]["input"]>;
  groupNum?: InputMaybe<Scalars["Int"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
};

export type InputReverbSearchLimitedaggregation = {
  brandUuids?: InputMaybe<Scalars["Int"]["input"]>;
  categoryUuids?: InputMaybe<Scalars["Int"]["input"]>;
};

export type InputReverbSearchListingssearchrequest = {
  acceptsAffirm?: InputMaybe<Scalars["Boolean"]["input"]>;
  acceptsAutoOffers?: InputMaybe<Scalars["Boolean"]["input"]>;
  acceptsBuyerOffers?: InputMaybe<Scalars["Boolean"]["input"]>;
  acceptsGiftCards?: InputMaybe<Scalars["Boolean"]["input"]>;
  acceptsOffers?: InputMaybe<Scalars["Boolean"]["input"]>;
  acceptsPaymentPlans?: InputMaybe<Scalars["Boolean"]["input"]>;
  aggregationsOnly?: InputMaybe<Scalars["Boolean"]["input"]>;
  applyProximityBoost?: InputMaybe<Scalars["Boolean"]["input"]>;
  autodirects?: InputMaybe<reverb_search_Autodirects>;
  bestMatchSignalsV2ExperimentGroup?: InputMaybe<Scalars["Int"]["input"]>;
  boostByBumpRate?: InputMaybe<Scalars["Boolean"]["input"]>;
  boostCombinedShippingCategories?: InputMaybe<
    Array<InputMaybe<Scalars["String"]["input"]>>
  >;
  boostedItemRegionCode?: InputMaybe<Scalars["String"]["input"]>;
  brandSlugs?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  brandUuids?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  bumpCount?: InputMaybe<Scalars["Int"]["input"]>;
  bumpedOnly?: InputMaybe<Scalars["Boolean"]["input"]>;
  bumpedSortedListingsQuery?: InputMaybe<InputReverbSearchBumpedsortedlistingsquery>;
  calculatedRate?: InputMaybe<Scalars["Boolean"]["input"]>;
  canonicalFinishes?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  canonicalProductIds?: InputMaybe<
    Array<InputMaybe<Scalars["String"]["input"]>>
  >;
  categorySlugs?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  categoryUuids?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  collapsible?: InputMaybe<reverb_search_ListingsSearchRequest_Collapsible>;
  combinedShipping?: InputMaybe<Scalars["Boolean"]["input"]>;
  conditionSlugs?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  conditionUuids?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  contexts?: InputMaybe<Array<InputMaybe<reverb_search_Context>>>;
  countryOfOrigin?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  cspId?: InputMaybe<Scalars["String"]["input"]>;
  cspSlug?: InputMaybe<Scalars["String"]["input"]>;
  curatedSetId?: InputMaybe<Scalars["String"]["input"]>;
  curatedSetSlugs?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  currency?: InputMaybe<Scalars["String"]["input"]>;
  dealsAndSteals?: InputMaybe<Scalars["Boolean"]["input"]>;
  dealsOrWellPriced?: InputMaybe<Scalars["Boolean"]["input"]>;
  decades?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  ecsBoostExperimentGroup?: InputMaybe<Scalars["Int"]["input"]>;
  eligibleForSales?: InputMaybe<Scalars["Boolean"]["input"]>;
  es7?: InputMaybe<Scalars["Boolean"]["input"]>;
  excludeBrandSlugs?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  excludeCarrierCalculated?: InputMaybe<Scalars["Boolean"]["input"]>;
  excludeCategoryUuids?: InputMaybe<
    Array<InputMaybe<Scalars["String"]["input"]>>
  >;
  excludeCuratedSets?: InputMaybe<
    Array<InputMaybe<Scalars["String"]["input"]>>
  >;
  excludeLocalPickupOnly?: InputMaybe<Scalars["Boolean"]["input"]>;
  excludeMerchandisingTypes?: InputMaybe<
    Array<InputMaybe<Scalars["String"]["input"]>>
  >;
  excludePreorders?: InputMaybe<Scalars["Boolean"]["input"]>;
  excludeShopIds?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  excludeStale?: InputMaybe<Scalars["Boolean"]["input"]>;
  experiments?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  fallbackToEverywhereElse?: InputMaybe<Scalars["Boolean"]["input"]>;
  fallbackToOr?: InputMaybe<Scalars["Boolean"]["input"]>;
  filterSuperRegionCode?: InputMaybe<Scalars["String"]["input"]>;
  financingCuratedSetId?: InputMaybe<Scalars["String"]["input"]>;
  flatRate?: InputMaybe<Scalars["Boolean"]["input"]>;
  freeExpeditedShipping?: InputMaybe<Scalars["Boolean"]["input"]>;
  freeShipping?: InputMaybe<Scalars["Boolean"]["input"]>;
  greatValue?: InputMaybe<Scalars["Boolean"]["input"]>;
  greatValueForUsedListings?: InputMaybe<Scalars["Boolean"]["input"]>;
  handmade?: InputMaybe<Scalars["Boolean"]["input"]>;
  hasWatchers?: InputMaybe<Scalars["Boolean"]["input"]>;
  holidaySale?: InputMaybe<Scalars["Boolean"]["input"]>;
  ids?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  inBuyerCarts?: InputMaybe<Scalars["Boolean"]["input"]>;
  includeDrafts?: InputMaybe<Scalars["Boolean"]["input"]>;
  includeQuerySpellCorrections?: InputMaybe<Scalars["Boolean"]["input"]>;
  isUserInCspOptimizationExp?: InputMaybe<Scalars["Boolean"]["input"]>;
  itemCity?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  itemRegion?: InputMaybe<Scalars["String"]["input"]>;
  itemRegionRelation?: InputMaybe<reverb_search_ListingItemRegionRelation>;
  itemState?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  likelihoodToSellExperimentGroup?: InputMaybe<Scalars["Int"]["input"]>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  listingRecommendationsVersion?: InputMaybe<Scalars["Int"]["input"]>;
  localPickup?: InputMaybe<Scalars["Boolean"]["input"]>;
  locale?: InputMaybe<Scalars["String"]["input"]>;
  minSaleDiscountPercent?: InputMaybe<Scalars["String"]["input"]>;
  mpTotalPriceExperimentGroup?: InputMaybe<Scalars["Int"]["input"]>;
  mpid?: InputMaybe<Scalars["String"]["input"]>;
  multiClientExperiments?: InputMaybe<
    Array<InputMaybe<InputReverbSearchExperimentdata>>
  >;
  noAutoOffers?: InputMaybe<Scalars["Boolean"]["input"]>;
  noBuyerOffers?: InputMaybe<Scalars["Boolean"]["input"]>;
  notGreatValue?: InputMaybe<Scalars["Boolean"]["input"]>;
  notGreatValueForUsedListings?: InputMaybe<Scalars["Boolean"]["input"]>;
  notMatchedToCsp?: InputMaybe<Scalars["Boolean"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  onSale?: InputMaybe<Scalars["Boolean"]["input"]>;
  onlyStale?: InputMaybe<Scalars["Boolean"]["input"]>;
  outlet?: InputMaybe<Scalars["Boolean"]["input"]>;
  preferredSeller?: InputMaybe<Scalars["Boolean"]["input"]>;
  priceMax?: InputMaybe<Scalars["String"]["input"]>;
  priceMin?: InputMaybe<Scalars["String"]["input"]>;
  priceValue?: InputMaybe<reverb_search_ListingsSearchRequest_PriceValue>;
  priceValueV2?: InputMaybe<reverb_search_ListingsSearchRequest_PriceValue>;
  proximity?: InputMaybe<InputReverbSearchProximityrequest>;
  query?: InputMaybe<Scalars["String"]["input"]>;
  querylessBumpBoost?: InputMaybe<Scalars["Boolean"]["input"]>;
  randomSeed?: InputMaybe<Scalars["Int"]["input"]>;
  saleCuratedSetId?: InputMaybe<Scalars["String"]["input"]>;
  saleSlugs?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  searchUrlParams?: InputMaybe<Scalars["String"]["input"]>;
  shippingProfileId?: InputMaybe<Scalars["String"]["input"]>;
  shippingRegionCodes?: InputMaybe<
    Array<InputMaybe<Scalars["String"]["input"]>>
  >;
  shipsInternationally?: InputMaybe<Scalars["Boolean"]["input"]>;
  shipsToMe?: InputMaybe<Scalars["Boolean"]["input"]>;
  shopId?: InputMaybe<Scalars["String"]["input"]>;
  shopRegionCode?: InputMaybe<Scalars["String"]["input"]>;
  shopSlug?: InputMaybe<Scalars["String"]["input"]>;
  shopSlugs?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  shopUuids?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  showBestPriceListingsSort?: InputMaybe<Scalars["Boolean"]["input"]>;
  showLikelihoodToSellSort?: InputMaybe<Scalars["Boolean"]["input"]>;
  showMostWatchedListingsSort?: InputMaybe<Scalars["Boolean"]["input"]>;
  showOnlySold?: InputMaybe<Scalars["Boolean"]["input"]>;
  showSold?: InputMaybe<Scalars["Boolean"]["input"]>;
  similarListingIds?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  skipAutodirects?: InputMaybe<Scalars["Boolean"]["input"]>;
  sort?: InputMaybe<reverb_search_ListingsSearchRequest_Sort>;
  sortSlug?: InputMaybe<Scalars["String"]["input"]>;
  statuses?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  terminateEarly?: InputMaybe<Scalars["Boolean"]["input"]>;
  trackTotalHits?: InputMaybe<reverb_search_TrackTotalHits>;
  traitSlugs?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  traitValues?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  useExperimentalBestMatchSignalsV1?: InputMaybe<Scalars["Boolean"]["input"]>;
  useExperimentalQuery?: InputMaybe<Scalars["Boolean"]["input"]>;
  useExperimentalRecall?: InputMaybe<Scalars["Boolean"]["input"]>;
  useExperimentalRegionBoost?: InputMaybe<Scalars["Boolean"]["input"]>;
  useTotalPrice?: InputMaybe<Scalars["Boolean"]["input"]>;
  withAggregations?: InputMaybe<
    Array<InputMaybe<reverb_search_ListingsSearchRequest_Aggregation>>
  >;
  withLimitedAggregations?: InputMaybe<InputReverbSearchLimitedaggregation>;
  withListingRecommendationStats?: InputMaybe<Scalars["Boolean"]["input"]>;
  withMyShopAggregations?: InputMaybe<
    Array<InputMaybe<reverb_search_ListingsSearchRequest_MyShopAggregation>>
  >;
  withPageMetadata?: InputMaybe<InputReverbSearchPagemetadatarequest>;
  withProximityFilter?: InputMaybe<InputReverbSearchProximityfilterrequest>;
  withTopCollections?: InputMaybe<InputReverbSearchTopcollectionsrequest>;
  yearMax?: InputMaybe<Scalars["String"]["input"]>;
  yearMin?: InputMaybe<Scalars["String"]["input"]>;
  zeroPercentFinancingEligible?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type InputReverbSearchLocation = {
  type?: InputMaybe<reverb_search_Location_LocationType>;
  value?: InputMaybe<Scalars["String"]["input"]>;
};

/** Deprecated */
export type InputReverbSearchPagemetadatarequest = {
  excludeBrands?: InputMaybe<Scalars["Boolean"]["input"]>;
  excludeCategories?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type InputReverbSearchProximityfilterrequest = {
  distance?: InputMaybe<Scalars["String"]["input"]>;
  postalCode?: InputMaybe<Scalars["String"]["input"]>;
  proximity?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type InputReverbSearchProximityrequest = {
  applyFilter?: InputMaybe<Scalars["Boolean"]["input"]>;
  distance?: InputMaybe<InputReverbSearchDistance>;
  location?: InputMaybe<InputReverbSearchLocation>;
};

export type InputReverbSearchTopcollectionsrequest = {
  brandUuids?: InputMaybe<Scalars["Int"]["input"]>;
  categoryUuids?: InputMaybe<Scalars["Int"]["input"]>;
};

export type InputTaxProfileUpsertRequest = {
  addressAttributes?: InputMaybe<InputAddressEntry>;
  firstName?: InputMaybe<Scalars["String"]["input"]>;
  lastName?: InputMaybe<Scalars["String"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  tin?: InputMaybe<Scalars["String"]["input"]>;
  tinType?: InputMaybe<Scalars["String"]["input"]>;
};

export type Input_arbiter_APIError = {
  message?: InputMaybe<Scalars["String"]["input"]>;
};

export type Input_arbiter_APIErrorResponse = {
  errors?: InputMaybe<Array<InputMaybe<Input_arbiter_APIError>>>;
};

export type Input_arbiter_BulkBumpInteractionCountsRequest = {
  requests?: InputMaybe<
    Array<InputMaybe<Input_arbiter_BumpInteractionCountsRequest>>
  >;
};

export type Input_arbiter_BulkBumpInteractionCountsResponse = {
  responses?: InputMaybe<
    Array<InputMaybe<Input_arbiter_BumpInteractionCountsResponse>>
  >;
};

export type Input_arbiter_BulkFeaturedListingRequest = {
  requests?: InputMaybe<
    Array<InputMaybe<Input_arbiter_FeaturedListingRequest>>
  >;
};

export type Input_arbiter_BulkFeaturedListingResponse = {
  responses?: InputMaybe<
    Array<InputMaybe<Input_arbiter_FeaturedListingResponse>>
  >;
};

export type Input_arbiter_BulkTrackImpressionsRequest = {
  listingIds?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
};

export type Input_arbiter_BulkTrackImpressionsResponse = {
  listingIds?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
};

export type Input_arbiter_BumpInteractionCountsRequest = {
  listingId?: InputMaybe<Scalars["String"]["input"]>;
  sellerId?: InputMaybe<Scalars["String"]["input"]>;
};

export type Input_arbiter_BumpInteractionCountsResponse = {
  addToCart?: InputMaybe<Scalars["Int"]["input"]>;
  addToWatchlist?: InputMaybe<Scalars["Int"]["input"]>;
  click?: InputMaybe<Scalars["Int"]["input"]>;
  listingId?: InputMaybe<Scalars["String"]["input"]>;
  makeAnOffer?: InputMaybe<Scalars["Int"]["input"]>;
  messageSeller?: InputMaybe<Scalars["Int"]["input"]>;
  total?: InputMaybe<Scalars["Int"]["input"]>;
};

export type Input_arbiter_BumpedSortedListingResponseListing = {
  bid?: InputMaybe<Scalars["Float"]["input"]>;
  bumped?: InputMaybe<Scalars["Boolean"]["input"]>;
  discountPercent?: InputMaybe<Scalars["Int"]["input"]>;
  productId?: InputMaybe<Scalars["String"]["input"]>;
  qualityScore?: InputMaybe<Scalars["Float"]["input"]>;
  rank?: InputMaybe<Scalars["Float"]["input"]>;
};

export type Input_arbiter_BumpedSortedListingsRequest = {
  auctionType?: InputMaybe<arbiter_AuctionType>;
  bumpedMax?: InputMaybe<Scalars["Int"]["input"]>;
  condition?: InputMaybe<Scalars["String"]["input"]>;
  itemRegion?: InputMaybe<Scalars["String"]["input"]>;
  listingIds?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  total?: InputMaybe<Scalars["Int"]["input"]>;
  userShippingRegion?: InputMaybe<Scalars["String"]["input"]>;
};

export type Input_arbiter_BumpedSortedListingsResponse = {
  products?: InputMaybe<
    Array<InputMaybe<Input_arbiter_BumpedSortedListingResponseListing>>
  >;
  resultIds?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
};

export type Input_arbiter_CreateInteractionRequest = {
  interaction?: InputMaybe<Input_arbiter_Interaction>;
};

export type Input_arbiter_FeaturedListingRequest = {
  applyProximityBoost?: InputMaybe<Scalars["Boolean"]["input"]>;
  auctionType?: InputMaybe<arbiter_AuctionType>;
  canonicalFinish?: InputMaybe<Scalars["String"]["input"]>;
  categoryUuids?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  conditionUuids?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  cspId?: InputMaybe<Scalars["String"]["input"]>;
  cspSlug?: InputMaybe<Scalars["String"]["input"]>;
  deterministic?: InputMaybe<Scalars["Boolean"]["input"]>;
  includeListingsOutsideLowestPriceWindow?: InputMaybe<
    Scalars["Boolean"]["input"]
  >;
  isUserInCspOptimizationExp?: InputMaybe<Scalars["Boolean"]["input"]>;
  itemRegion?: InputMaybe<Scalars["String"]["input"]>;
  listingIds?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  traitValues?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  userCurrency?: InputMaybe<Scalars["String"]["input"]>;
  userShippingRegion?: InputMaybe<Scalars["String"]["input"]>;
};

export type Input_arbiter_FeaturedListingResponse = {
  bid?: InputMaybe<Scalars["Float"]["input"]>;
  bumped?: InputMaybe<Scalars["Boolean"]["input"]>;
  productId?: InputMaybe<Scalars["String"]["input"]>;
  qualityScore?: InputMaybe<Scalars["Float"]["input"]>;
  rank?: InputMaybe<Scalars["Float"]["input"]>;
  runnerUp?: InputMaybe<Input_arbiter_FeaturedListingResponse>;
};

export type Input_arbiter_FindInteractionRequest = {
  cookieId?: InputMaybe<Scalars["String"]["input"]>;
  orderedAt?: InputMaybe<Scalars["Timestamp"]["input"]>;
  productId?: InputMaybe<Scalars["String"]["input"]>;
  userId?: InputMaybe<Scalars["String"]["input"]>;
};

export type Input_arbiter_FindInteractionResponse = {
  interaction?: InputMaybe<Input_arbiter_Interaction>;
};

export type Input_arbiter_Interaction = {
  bumpRate?: InputMaybe<Scalars["String"]["input"]>;
  componentName?: InputMaybe<Scalars["String"]["input"]>;
  cookieId?: InputMaybe<Scalars["String"]["input"]>;
  createdAt?: InputMaybe<Scalars["Timestamp"]["input"]>;
  currentUrl?: InputMaybe<Scalars["String"]["input"]>;
  interactionType?: InputMaybe<arbiter_Interaction_InteractionType>;
  issuedAt?: InputMaybe<Scalars["Timestamp"]["input"]>;
  offsiteSource?: InputMaybe<Scalars["String"]["input"]>;
  position?: InputMaybe<Scalars["Int"]["input"]>;
  productId?: InputMaybe<Scalars["String"]["input"]>;
  referrerUrl?: InputMaybe<Scalars["String"]["input"]>;
  sellerId?: InputMaybe<Scalars["String"]["input"]>;
  sessionId?: InputMaybe<Scalars["String"]["input"]>;
  userDeviceName?: InputMaybe<Scalars["String"]["input"]>;
  userExperimentsJson?: InputMaybe<Scalars["String"]["input"]>;
  userId?: InputMaybe<Scalars["String"]["input"]>;
  uuid?: InputMaybe<Scalars["String"]["input"]>;
};

export type Input_arbiter_InteractionTimeSeriesMetric = {
  addToCart?: InputMaybe<Scalars["Int"]["input"]>;
  addToWatchlist?: InputMaybe<Scalars["Int"]["input"]>;
  click?: InputMaybe<Scalars["Int"]["input"]>;
  date?: InputMaybe<Scalars["String"]["input"]>;
  makeAnOffer?: InputMaybe<Scalars["Int"]["input"]>;
  messageSeller?: InputMaybe<Scalars["Int"]["input"]>;
  total?: InputMaybe<Scalars["Int"]["input"]>;
};

export type Input_arbiter_Listing = {
  id?: InputMaybe<Scalars["String"]["input"]>;
};

export type Input_arbiter_SellerInteractionTimeSeriesRequest = {
  endDate?: InputMaybe<Scalars["String"]["input"]>;
  sellerId?: InputMaybe<Scalars["String"]["input"]>;
  startDate?: InputMaybe<Scalars["String"]["input"]>;
};

export type Input_arbiter_SellerInteractionTimeSeriesResponse = {
  addToCart?: InputMaybe<Scalars["Int"]["input"]>;
  addToWatchlist?: InputMaybe<Scalars["Int"]["input"]>;
  click?: InputMaybe<Scalars["Int"]["input"]>;
  data?: InputMaybe<
    Array<InputMaybe<Input_arbiter_InteractionTimeSeriesMetric>>
  >;
  makeAnOffer?: InputMaybe<Scalars["Int"]["input"]>;
  messageSeller?: InputMaybe<Scalars["Int"]["input"]>;
  total?: InputMaybe<Scalars["Int"]["input"]>;
};

export type Input_braze_BrazeUser = {
  email?: InputMaybe<Scalars["String"]["input"]>;
  emailSubscribe?: InputMaybe<Scalars["String"]["input"]>;
  externalId?: InputMaybe<Scalars["String"]["input"]>;
  pushSubscribe?: InputMaybe<Scalars["String"]["input"]>;
};

export type Input_braze_GetSubscriptionGroupsRequest = {
  email?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  externalId?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
};

export type Input_braze_GetSubscriptionGroupsResponse = {
  message?: InputMaybe<Scalars["String"]["input"]>;
  totalCount?: InputMaybe<Scalars["Int"]["input"]>;
  users?: InputMaybe<Array<InputMaybe<Input_braze_SubscriptionUser>>>;
};

export type Input_braze_GetUserDataRequest = {
  externalIds?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  fieldsToExport?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
};

export type Input_braze_GetUserDataResponse = {
  invalidUserIds?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  message?: InputMaybe<Scalars["String"]["input"]>;
  users?: InputMaybe<Array<InputMaybe<Input_braze_BrazeUser>>>;
};

export type Input_braze_OptInRecipient = {
  externalUserId?: InputMaybe<Scalars["String"]["input"]>;
};

export type Input_braze_SetSubscriptionGroupsRequest = {
  email?: InputMaybe<Scalars["String"]["input"]>;
  externalId?: InputMaybe<Scalars["String"]["input"]>;
  subscriptionGroupId?: InputMaybe<Scalars["String"]["input"]>;
  subscriptionState?: InputMaybe<Scalars["String"]["input"]>;
};

export type Input_braze_SetSubscriptionGroupsResponse = {
  message?: InputMaybe<Scalars["String"]["input"]>;
};

export type Input_braze_SubscriptionGroupConfig = {
  channel?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  status?: InputMaybe<Scalars["String"]["input"]>;
};

export type Input_braze_SubscriptionUser = {
  email?: InputMaybe<Scalars["String"]["input"]>;
  externalId?: InputMaybe<Scalars["String"]["input"]>;
  phone?: InputMaybe<Scalars["String"]["input"]>;
  subscriptionGroups?: InputMaybe<
    Array<InputMaybe<Input_braze_SubscriptionGroupConfig>>
  >;
};

export type Input_braze_TriggerCampaignSendRequest = {
  campaignId?: InputMaybe<Scalars["String"]["input"]>;
  recipients?: InputMaybe<Array<InputMaybe<Input_braze_OptInRecipient>>>;
};

export type Input_braze_TriggerCampaignSendResponse = {
  dispatchId?: InputMaybe<Scalars["String"]["input"]>;
  message?: InputMaybe<Scalars["String"]["input"]>;
};

export type Input_braze_UserAttributes = {
  UpdateExistingOnly?: InputMaybe<Scalars["Boolean"]["input"]>;
  brazeId?: InputMaybe<Scalars["String"]["input"]>;
  emailSubscribe?: InputMaybe<Scalars["String"]["input"]>;
  externalId?: InputMaybe<Scalars["String"]["input"]>;
  pushSubscribe?: InputMaybe<Scalars["String"]["input"]>;
};

export type Input_braze_UserTrackRequest = {
  attributes?: InputMaybe<Array<InputMaybe<Input_braze_UserAttributes>>>;
};

export type Input_braze_UserTrackResponse = {
  message?: InputMaybe<Scalars["String"]["input"]>;
};

export type Input_cms_AdminClonePageRequest = {
  slug?: InputMaybe<Scalars["String"]["input"]>;
};

export type Input_cms_AdminClonePageResponse = {
  clonedPageSlug?: InputMaybe<Scalars["String"]["input"]>;
};

export type Input_cms_AdminComponentsResponse = {
  components?: InputMaybe<Array<InputMaybe<Input_cms_Component>>>;
};

export type Input_cms_AdminCreatePage = {
  authorId?: InputMaybe<Scalars["String"]["input"]>;
  channels?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  componentIds?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  darkModeReady?: InputMaybe<Scalars["Boolean"]["input"]>;
  goLiveAt?: InputMaybe<Scalars["String"]["input"]>;
  headerStyle?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  imageAttributionUri?: InputMaybe<Scalars["String"]["input"]>;
  imageKey?: InputMaybe<Scalars["String"]["input"]>;
  layoutId?: InputMaybe<Scalars["String"]["input"]>;
  localizedContents?: InputMaybe<
    Array<InputMaybe<Input_cms_PageLocalizedContent>>
  >;
  metaImageKey?: InputMaybe<Scalars["String"]["input"]>;
  noIndex?: InputMaybe<Scalars["Boolean"]["input"]>;
  pageTemplateId?: InputMaybe<Scalars["String"]["input"]>;
  pageType?: InputMaybe<Scalars["String"]["input"]>;
  secondaryImageKey?: InputMaybe<Scalars["String"]["input"]>;
  slug?: InputMaybe<Scalars["String"]["input"]>;
};

export type Input_cms_AdminCreatePageTemplate = {
  customVariables?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  layoutId?: InputMaybe<Scalars["String"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
};

export type Input_cms_AdminCreatePageTemplateRequest = {
  pageTemplate?: InputMaybe<Input_cms_AdminCreatePageTemplate>;
};

export type Input_cms_AdminDeletePageRequest = {
  id?: InputMaybe<Scalars["String"]["input"]>;
};

export type Input_cms_AdminPageCreateRequest = {
  page?: InputMaybe<Input_cms_AdminCreatePage>;
};

export type Input_cms_AdminPageIndexRequest = {
  channels?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  ids?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  page?: InputMaybe<Scalars["String"]["input"]>;
  pageType?: InputMaybe<Scalars["String"]["input"]>;
  perPage?: InputMaybe<Scalars["String"]["input"]>;
  q?: InputMaybe<Scalars["String"]["input"]>;
};

export type Input_cms_AdminPageTemplateIndexRequest = {
  ids?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  page?: InputMaybe<Scalars["String"]["input"]>;
  perPage?: InputMaybe<Scalars["String"]["input"]>;
  q?: InputMaybe<Scalars["String"]["input"]>;
};

export type Input_cms_AdminPagesResponse = {
  meta?: InputMaybe<Input_cms_Pagination>;
  pages?: InputMaybe<Array<InputMaybe<Input_cms_Page>>>;
};

export type Input_cms_AdminTakeSlugRequest = {
  currentSlug?: InputMaybe<Scalars["String"]["input"]>;
  desiredSlug?: InputMaybe<Scalars["String"]["input"]>;
};

export type Input_cms_AdminTakeSlugResponse = {
  legacySlug?: InputMaybe<Scalars["String"]["input"]>;
  updatedSlug?: InputMaybe<Scalars["String"]["input"]>;
};

export type Input_cms_Author = {
  id?: InputMaybe<Scalars["String"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  slug?: InputMaybe<Scalars["String"]["input"]>;
};

export type Input_cms_AuthorIndexResponse = {
  authors?: InputMaybe<Array<InputMaybe<Input_cms_Author>>>;
};

export type Input_cms_AuthorRequest = {
  author?: InputMaybe<Input_cms_Author>;
};

export type Input_cms_AuthorResponse = {
  author?: InputMaybe<Input_cms_Author>;
};

export type Input_cms_BulkComponentRequest = {
  components?: InputMaybe<Array<InputMaybe<Input_cms_Component>>>;
};

export type Input_cms_BulkComponentResponse = {
  components?: InputMaybe<Array<InputMaybe<Input_cms_Component>>>;
};

export type Input_cms_BulkPagination = {
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  total?: InputMaybe<Scalars["Int"]["input"]>;
};

export type Input_cms_Component = {
  active?: InputMaybe<Scalars["Boolean"]["input"]>;
  blacklistCountries?: InputMaybe<
    Array<InputMaybe<Scalars["String"]["input"]>>
  >;
  componentType?: InputMaybe<Scalars["String"]["input"]>;
  /** 'default' or 'experiment' */
  experimentBucket?: InputMaybe<Scalars["String"]["input"]>;
  experimentKey?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  layoutSection?: InputMaybe<Scalars["String"]["input"]>;
  localizedContents?: InputMaybe<
    Array<InputMaybe<Input_cms_ComponentLocalizedContent>>
  >;
  pageId?: InputMaybe<Scalars["String"]["input"]>;
  pageTemplateId?: InputMaybe<Scalars["String"]["input"]>;
  position?: InputMaybe<Scalars["Int"]["input"]>;
  targetCountries?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  type?: InputMaybe<Scalars["String"]["input"]>;
};

export type Input_cms_ComponentDeleteRequest = {
  id?: InputMaybe<Scalars["String"]["input"]>;
};

export type Input_cms_ComponentLocalizedContent = {
  data?: InputMaybe<Scalars["String"]["input"]>;
  locale?: InputMaybe<Scalars["String"]["input"]>;
};

export type Input_cms_ComponentRequest = {
  component?: InputMaybe<Input_cms_Component>;
};

export type Input_cms_ComponentResponse = {
  component?: InputMaybe<Input_cms_Component>;
};

export type Input_cms_ComponentTemplatesRequest = {
  layoutSection?: InputMaybe<Scalars["String"]["input"]>;
  pageTemplateId?: InputMaybe<Scalars["String"]["input"]>;
};

export type Input_cms_ComponentsRequest = {
  ids?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  layoutSection?: InputMaybe<Scalars["String"]["input"]>;
  pageId?: InputMaybe<Scalars["String"]["input"]>;
};

export type Input_cms_Layout = {
  id?: InputMaybe<Scalars["String"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  sections?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
};

export type Input_cms_LayoutsIndexRequest = {
  ids?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  q?: InputMaybe<Scalars["String"]["input"]>;
};

export type Input_cms_LayoutsResponse = {
  layouts?: InputMaybe<Array<InputMaybe<Input_cms_Layout>>>;
};

export type Input_cms_NodeRequest = {
  lookups?: InputMaybe<Array<InputMaybe<Input_cms_NodeRequest_Lookup>>>;
};

export type Input_cms_NodeRequest_Lookup = {
  lid?: InputMaybe<Scalars["String"]["input"]>;
  rel?: InputMaybe<Scalars["String"]["input"]>;
};

export type Input_cms_NodeResponse = {
  results?: InputMaybe<Array<InputMaybe<Input_cms_NodeResponse_Result>>>;
};

export type Input_cms_NodeResponse_Result = {
  lookup?: InputMaybe<Input_cms_NodeRequest_Lookup>;
  meta?: InputMaybe<Input_cms_NodeResponse_Result_Meta>;
  publicComponents?: InputMaybe<Array<InputMaybe<Input_cms_PublicComponent>>>;
};

export type Input_cms_NodeResponse_Result_Meta = {
  total?: InputMaybe<Scalars["Int"]["input"]>;
};

export type Input_cms_Page = {
  authorId?: InputMaybe<Scalars["String"]["input"]>;
  channels?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  componentIds?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  darkModeReady?: InputMaybe<Scalars["Boolean"]["input"]>;
  goLiveAt?: InputMaybe<Scalars["String"]["input"]>;
  headerStyle?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  imageAttributionUri?: InputMaybe<Scalars["String"]["input"]>;
  imageKey?: InputMaybe<Scalars["String"]["input"]>;
  layoutId?: InputMaybe<Scalars["String"]["input"]>;
  localizedContents?: InputMaybe<
    Array<InputMaybe<Input_cms_PageLocalizedContent>>
  >;
  metaImageKey?: InputMaybe<Scalars["String"]["input"]>;
  noIndex?: InputMaybe<Scalars["Boolean"]["input"]>;
  pageTemplateId?: InputMaybe<Scalars["String"]["input"]>;
  pageType?: InputMaybe<Scalars["String"]["input"]>;
  secondaryImageKey?: InputMaybe<Scalars["String"]["input"]>;
  slug?: InputMaybe<Scalars["String"]["input"]>;
};

export type Input_cms_PageImage = {
  imageUrl?: InputMaybe<Scalars["String"]["input"]>;
};

export type Input_cms_PageLocalizedContent = {
  actionText?: InputMaybe<Scalars["String"]["input"]>;
  actionUrl?: InputMaybe<Scalars["String"]["input"]>;
  breadcrumbs?: InputMaybe<Scalars["String"]["input"]>;
  customVariableData?: InputMaybe<Scalars["String"]["input"]>;
  description?: InputMaybe<Scalars["String"]["input"]>;
  locale?: InputMaybe<Scalars["String"]["input"]>;
  metaDescription?: InputMaybe<Scalars["String"]["input"]>;
  metaTitle?: InputMaybe<Scalars["String"]["input"]>;
  searchPlaceholder?: InputMaybe<Scalars["String"]["input"]>;
  title?: InputMaybe<Scalars["String"]["input"]>;
};

export type Input_cms_PageRequest = {
  page?: InputMaybe<Input_cms_PublicPage>;
};

export type Input_cms_PageResponse = {
  page?: InputMaybe<Input_cms_Page>;
};

export type Input_cms_PageTemplate = {
  customVariables?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  layoutId?: InputMaybe<Scalars["String"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
};

export type Input_cms_PageTemplateIndexResponse = {
  meta?: InputMaybe<Input_cms_Pagination>;
  pageTemplates?: InputMaybe<Array<InputMaybe<Input_cms_PageTemplate>>>;
};

export type Input_cms_PageTemplatePageInfo = {
  pageCount?: InputMaybe<Scalars["Int"]["input"]>;
  pageTemplateId?: InputMaybe<Scalars["String"]["input"]>;
  pageTitles?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
};

export type Input_cms_PageTemplatePageInfoResponse = {
  pageTemplatePageInfo?: InputMaybe<
    Array<InputMaybe<Input_cms_PageTemplatePageInfo>>
  >;
};

export type Input_cms_PageTemplateResponse = {
  pageTemplate?: InputMaybe<Input_cms_PageTemplate>;
};

export type Input_cms_PagesRequest = {
  channels?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  excludeBlacklistedEmbeds?: InputMaybe<Scalars["Boolean"]["input"]>;
  ids?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  includeDrafts?: InputMaybe<Scalars["String"]["input"]>;
  limit?: InputMaybe<Scalars["String"]["input"]>;
  offset?: InputMaybe<Scalars["String"]["input"]>;
  pageType?: InputMaybe<Scalars["String"]["input"]>;
  pageTypes?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  query?: InputMaybe<Scalars["String"]["input"]>;
  slugs?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
};

export type Input_cms_PagesResponse = {
  meta?: InputMaybe<Input_cms_BulkPagination>;
  pages?: InputMaybe<Array<InputMaybe<Input_cms_PublicPage>>>;
};

export type Input_cms_Pagination = {
  currentPage?: InputMaybe<Scalars["Int"]["input"]>;
  perPage?: InputMaybe<Scalars["Int"]["input"]>;
  total?: InputMaybe<Scalars["Int"]["input"]>;
};

export type Input_cms_PublicComponent = {
  active?: InputMaybe<Scalars["Boolean"]["input"]>;
  componentType?: InputMaybe<Scalars["String"]["input"]>;
  data?: InputMaybe<Scalars["String"]["input"]>;
  /** 'default' or 'experiment' */
  experimentBucket?: InputMaybe<Scalars["String"]["input"]>;
  /** cms_recently_viewed  cms_used_first */
  experimentKey?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  layoutSection?: InputMaybe<Scalars["String"]["input"]>;
  pageId?: InputMaybe<Scalars["String"]["input"]>;
  position?: InputMaybe<Scalars["Int"]["input"]>;
  searchSort?: InputMaybe<Scalars["String"]["input"]>;
  searchType?: InputMaybe<cms_PublicComponent_SearchType>;
  searchUrlParams?: InputMaybe<Scalars["String"]["input"]>;
  subtitle?: InputMaybe<Scalars["String"]["input"]>;
  title?: InputMaybe<Scalars["String"]["input"]>;
};

export type Input_cms_PublicPage = {
  actionText?: InputMaybe<Scalars["String"]["input"]>;
  actionUrl?: InputMaybe<Scalars["String"]["input"]>;
  albumIds?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  authorId?: InputMaybe<Scalars["String"]["input"]>;
  authorName?: InputMaybe<Scalars["String"]["input"]>;
  breadcrumbs?: InputMaybe<Scalars["String"]["input"]>;
  channels?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  componentIds?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  darkModeReady?: InputMaybe<Scalars["Boolean"]["input"]>;
  description?: InputMaybe<Scalars["String"]["input"]>;
  goLiveAt?: InputMaybe<Scalars["String"]["input"]>;
  headerStyle?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  image?: InputMaybe<Input_cms_PageImage>;
  imageAttributionUri?: InputMaybe<Scalars["String"]["input"]>;
  layoutId?: InputMaybe<Scalars["String"]["input"]>;
  layoutSlug?: InputMaybe<Scalars["String"]["input"]>;
  live?: InputMaybe<Scalars["Boolean"]["input"]>;
  locale?: InputMaybe<Scalars["String"]["input"]>;
  metaDescription?: InputMaybe<Scalars["String"]["input"]>;
  metaImage?: InputMaybe<Input_cms_PageImage>;
  metaTitle?: InputMaybe<Scalars["String"]["input"]>;
  noIndex?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Flag used to thread to the frontend to indicate a cms slug was not found and we should render the not found page. */
  pageNotFound?: InputMaybe<Scalars["Boolean"]["input"]>;
  pageType?: InputMaybe<Scalars["String"]["input"]>;
  searchPlaceholder?: InputMaybe<Scalars["String"]["input"]>;
  secondaryImage?: InputMaybe<Input_cms_PageImage>;
  slug?: InputMaybe<Scalars["String"]["input"]>;
  targetLocales?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  title?: InputMaybe<Scalars["String"]["input"]>;
};

export type Input_core_apimessages_AcceptAddressSuggestionRequest = {
  suggestionId?: InputMaybe<Scalars["String"]["input"]>;
};

export type Input_core_apimessages_AcceptAddressSuggestionResponse = {
  /** address uuid */
  id?: InputMaybe<Scalars["String"]["input"]>;
};

export type Input_core_apimessages_AcceptedPaymentMethodsRequest = {
  /** either shops.uuid or products.id */
  ids?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  type?: InputMaybe<core_apimessages_AcceptedPaymentMethodsRequest_PaymentMethodsType>;
};

export type Input_core_apimessages_AcceptedPaymentMethodsResponse = {
  paymentMethods?: InputMaybe<
    Array<InputMaybe<Input_core_apimessages_PaymentMethods>>
  >;
};

export type Input_core_apimessages_AccertifyDeviceInfo = {
  pageId?: InputMaybe<Scalars["String"]["input"]>;
  transactionId?: InputMaybe<Scalars["String"]["input"]>;
  ubaEvents?: InputMaybe<Scalars["String"]["input"]>;
  ubaId?: InputMaybe<Scalars["String"]["input"]>;
  ubaSessionId?: InputMaybe<Scalars["String"]["input"]>;
};

export type Input_core_apimessages_AccountAlertsResponse = {
  alerts?: InputMaybe<Array<InputMaybe<Input_core_apimessages_Alert>>>;
};

export type Input_core_apimessages_ActionSourceAttributes = {
  device?: InputMaybe<Scalars["String"]["input"]>;
};

export type Input_core_apimessages_Ad = {
  adCampaignUuid?: InputMaybe<Scalars["String"]["input"]>;
  adGroupName?: InputMaybe<Scalars["String"]["input"]>;
  adGroupUuid?: InputMaybe<Scalars["String"]["input"]>;
  advertiserUuid?: InputMaybe<Scalars["String"]["input"]>;
  campaignId?: InputMaybe<Scalars["Int"]["input"]>;
  deletedAt?: InputMaybe<Scalars["Timestamp"]["input"]>;
  format?: InputMaybe<core_apimessages_AdFormat>;
  id?: InputMaybe<Scalars["Int"]["input"]>;
  imageAdCreative?: InputMaybe<Input_core_apimessages_ImageAdCreative>;
  localizedContents?: InputMaybe<
    Array<InputMaybe<Input_core_apimessages_AdLocalizedContent>>
  >;
  uuid?: InputMaybe<Scalars["String"]["input"]>;
};

export type Input_core_apimessages_AdCampaign = {
  advertiserUuid?: InputMaybe<Scalars["String"]["input"]>;
  description?: InputMaybe<Scalars["String"]["input"]>;
  endsAt?: InputMaybe<Scalars["Timestamp"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  startsAt?: InputMaybe<Scalars["Timestamp"]["input"]>;
  status?: InputMaybe<core_apimessages_AdCampaign_AdStatus>;
  uuid?: InputMaybe<Scalars["String"]["input"]>;
};

export type Input_core_apimessages_AdCampaignsSearchRequest = {
  adZoneSlugs?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  advertiserUuids?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  status?: InputMaybe<core_apimessages_AdCampaignsSearchRequest_Status>;
  withinDate?: InputMaybe<Scalars["String"]["input"]>;
};

export type Input_core_apimessages_AdCampaignsSearchResponse = {
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  resultUuids?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  total?: InputMaybe<Scalars["Int"]["input"]>;
};

export type Input_core_apimessages_AdGroup = {
  adCampaignUuid?: InputMaybe<Scalars["String"]["input"]>;
  description?: InputMaybe<Scalars["String"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  status?: InputMaybe<core_apimessages_AdGroup_AdStatus>;
  targetedAdZoneSlugs?: InputMaybe<
    Array<InputMaybe<Scalars["String"]["input"]>>
  >;
  targetedAdZones?: InputMaybe<
    Array<InputMaybe<Input_core_apimessages_AdGroupZone>>
  >;
  targetedBrandSlugs?: InputMaybe<
    Array<InputMaybe<Scalars["String"]["input"]>>
  >;
  targetedCategoryUuids?: InputMaybe<
    Array<InputMaybe<Scalars["String"]["input"]>>
  >;
  targetedCountryCodes?: InputMaybe<
    Array<InputMaybe<Scalars["String"]["input"]>>
  >;
  targetedDevices?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  uuid?: InputMaybe<Scalars["String"]["input"]>;
};

export type Input_core_apimessages_AdGroupKeyword = {
  id?: InputMaybe<Scalars["String"]["input"]>;
  keyword?: InputMaybe<Scalars["String"]["input"]>;
};

export type Input_core_apimessages_AdGroupZone = {
  adGroup?: InputMaybe<Input_core_apimessages_AdGroup>;
  adZone?: InputMaybe<Input_core_apimessages_AdZone>;
  endsAt?: InputMaybe<Scalars["Timestamp"]["input"]>;
  startsAt?: InputMaybe<Scalars["Timestamp"]["input"]>;
};

export type Input_core_apimessages_AdGroupsSearchRequest = {
  adCampaignUuids?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
};

export type Input_core_apimessages_AdGroupsSearchResponse = {
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  resultUuids?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  total?: InputMaybe<Scalars["Int"]["input"]>;
};

export type Input_core_apimessages_AdLocalizedContent = {
  imageAdCreative?: InputMaybe<Input_core_apimessages_ImageAdCreative>;
  locale?: InputMaybe<Scalars["String"]["input"]>;
};

export type Input_core_apimessages_AdPlacement = {
  format?: InputMaybe<core_apimessages_AdFormat>;
};

export type Input_core_apimessages_AdPlacementRequest = {
  adTypes?: InputMaybe<Array<InputMaybe<Input_core_apimessages_AdType>>>;
  adZones?: InputMaybe<Array<InputMaybe<Input_core_apimessages_AdZone>>>;
};

export type Input_core_apimessages_AdPlacementResponse = {
  ad?: InputMaybe<Input_core_apimessages_Ad>;
  adZones?: InputMaybe<Array<InputMaybe<Input_core_apimessages_AdZone>>>;
  candidates?: InputMaybe<Array<InputMaybe<Input_core_apimessages_Candidate>>>;
};

export type Input_core_apimessages_AdServeRequest = {
  includeDrafts?: InputMaybe<Scalars["Boolean"]["input"]>;
  placements?: InputMaybe<
    Array<InputMaybe<Input_core_apimessages_AdPlacementRequest>>
  >;
  targeting?: InputMaybe<Input_core_apimessages_TargetingContext>;
  userContext?: InputMaybe<Input_core_apimessages_AdUserContext>;
};

export type Input_core_apimessages_AdServeResponse = {
  ad?: InputMaybe<Input_core_apimessages_Ad>;
  placements?: InputMaybe<
    Array<InputMaybe<Input_core_apimessages_AdPlacementResponse>>
  >;
};

export type Input_core_apimessages_AdType = {
  format?: InputMaybe<core_apimessages_AdFormat>;
};

export type Input_core_apimessages_AdUserContext = {
  mparticleProfileResponse?: InputMaybe<Input_core_apimessages_MparticleProfileResponse>;
};

export type Input_core_apimessages_AdZone = {
  description?: InputMaybe<Scalars["String"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  slug?: InputMaybe<Scalars["String"]["input"]>;
  status?: InputMaybe<core_apimessages_AdZone_AdZoneStatus>;
  uuid?: InputMaybe<Scalars["String"]["input"]>;
};

export type Input_core_apimessages_AdZonesSearchRequest = {
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
};

export type Input_core_apimessages_AdZonesSearchResponse = {
  resultUuids?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
};

export type Input_core_apimessages_AdaChatTokenResponse = {
  token?: InputMaybe<Scalars["String"]["input"]>;
};

/** For API response */
export type Input_core_apimessages_Address = {
  addressPresent?: InputMaybe<Scalars["Boolean"]["input"]>;
  country?: InputMaybe<Input_core_apimessages_Country>;
  countryCode?: InputMaybe<Scalars["String"]["input"]>;
  displayLocation?: InputMaybe<Scalars["String"]["input"]>;
  email?: InputMaybe<Scalars["String"]["input"]>;
  extendedAddress?: InputMaybe<Scalars["String"]["input"]>;
  /** addresses.uuid. deprecated, use uuid */
  id?: InputMaybe<Scalars["String"]["input"]>;
  /** True if the address has all required fields. */
  isComplete?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** addresses.id */
  legacyId?: InputMaybe<Scalars["String"]["input"]>;
  locality?: InputMaybe<Scalars["String"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  phone?: InputMaybe<Scalars["String"]["input"]>;
  postalCode?: InputMaybe<Scalars["String"]["input"]>;
  primary?: InputMaybe<Scalars["Boolean"]["input"]>;
  region?: InputMaybe<Scalars["String"]["input"]>;
  /** Localized based on region field. Example: a region code of CA in the US will have a name of "California." */
  regionName?: InputMaybe<Scalars["String"]["input"]>;
  streetAddress?: InputMaybe<Scalars["String"]["input"]>;
  uuid?: InputMaybe<Scalars["String"]["input"]>;
  /** True if primary address fields match address details in Loqate */
  verified?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type Input_core_apimessages_AddressDeleteRequest = {
  addressType?: InputMaybe<core_apimessages_MyAddressType>;
  /** deprecated, use uuid */
  id?: InputMaybe<Scalars["String"]["input"]>;
  uuid?: InputMaybe<Scalars["String"]["input"]>;
};

/** For POST/PUT API requests */
export type Input_core_apimessages_AddressEntry = {
  countryCode?: InputMaybe<Scalars["String"]["input"]>;
  email?: InputMaybe<Scalars["String"]["input"]>;
  extendedAddress?: InputMaybe<Scalars["String"]["input"]>;
  locality?: InputMaybe<Scalars["String"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  phone?: InputMaybe<Scalars["String"]["input"]>;
  postalCode?: InputMaybe<Scalars["String"]["input"]>;
  region?: InputMaybe<Scalars["String"]["input"]>;
  streetAddress?: InputMaybe<Scalars["String"]["input"]>;
};

export type Input_core_apimessages_AddressVerifyRequest = {
  /** address uuid */
  id?: InputMaybe<Scalars["String"]["input"]>;
};

export type Input_core_apimessages_AddressVerifyResponse = {
  address?: InputMaybe<Input_core_apimessages_Address>;
  /** The verified address, null if no suggestion is found. */
  suggestion?: InputMaybe<Input_core_apimessages_Address>;
};

export type Input_core_apimessages_AdminBlockProductReviewRequest = {
  productReviewId?: InputMaybe<Scalars["String"]["input"]>;
};

export type Input_core_apimessages_AdminBlockProductReviewResponse = {
  /** Blocked Product Review ID */
  id?: InputMaybe<Scalars["String"]["input"]>;
};

export type Input_core_apimessages_AdminCuratedSetsCurateRequest = {
  curatedSetSlug?: InputMaybe<Scalars["String"]["input"]>;
  featuredInSet?: InputMaybe<Scalars["Boolean"]["input"]>;
  listingId?: InputMaybe<Scalars["String"]["input"]>;
};

export type Input_core_apimessages_AdminCuratedSetsCurateResponse = {
  curatedSetSlug?: InputMaybe<Scalars["String"]["input"]>;
  featuredInSet?: InputMaybe<Scalars["Boolean"]["input"]>;
  listingId?: InputMaybe<Scalars["String"]["input"]>;
};

export type Input_core_apimessages_AdminNote = {
  body?: InputMaybe<Scalars["String"]["input"]>;
  createdAt?: InputMaybe<Scalars["Timestamp"]["input"]>;
  createdByName?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  isPinned?: InputMaybe<Scalars["Boolean"]["input"]>;
  lastEditedByName?: InputMaybe<Scalars["String"]["input"]>;
  linkHtml?: InputMaybe<Scalars["String"]["input"]>;
  updatedAt?: InputMaybe<Scalars["Timestamp"]["input"]>;
  userCanEdit?: InputMaybe<Scalars["Boolean"]["input"]>;
  versions?: InputMaybe<
    Array<InputMaybe<Input_core_apimessages_AdminNoteVersion>>
  >;
};

export type Input_core_apimessages_AdminNoteCreateRequest = {
  body?: InputMaybe<Scalars["String"]["input"]>;
  notableId?: InputMaybe<Scalars["String"]["input"]>;
  notableType?: InputMaybe<Scalars["String"]["input"]>;
  pinned?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type Input_core_apimessages_AdminNoteUpdateRequest = {
  body?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  pinned?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type Input_core_apimessages_AdminNoteVersion = {
  body?: InputMaybe<Scalars["String"]["input"]>;
  editedAt?: InputMaybe<Scalars["Timestamp"]["input"]>;
  editedByName?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
};

export type Input_core_apimessages_AdminNotesSearchRequest = {
  bodyText?: InputMaybe<Scalars["String"]["input"]>;
  ignorePinned?: InputMaybe<Scalars["Boolean"]["input"]>;
  limit?: InputMaybe<Scalars["String"]["input"]>;
  linkableId?: InputMaybe<Scalars["String"]["input"]>;
  linkableType?: InputMaybe<Scalars["String"]["input"]>;
  notableId?: InputMaybe<Scalars["String"]["input"]>;
  notableType?: InputMaybe<Scalars["String"]["input"]>;
  offset?: InputMaybe<Scalars["String"]["input"]>;
  sortOrder?: InputMaybe<Scalars["String"]["input"]>;
};

export type Input_core_apimessages_AdminNotesSearchResponse = {
  adminNotes?: InputMaybe<Array<InputMaybe<Input_core_apimessages_AdminNote>>>;
  limit?: InputMaybe<Scalars["String"]["input"]>;
  offset?: InputMaybe<Scalars["String"]["input"]>;
  totalResults?: InputMaybe<Scalars["String"]["input"]>;
};

export type Input_core_apimessages_AdminTax1099kReportingThresholdCreateRequest =
  {
    region?: InputMaybe<Scalars["String"]["input"]>;
    salesAmountCents?: InputMaybe<Scalars["String"]["input"]>;
    transactionCount?: InputMaybe<Scalars["String"]["input"]>;
    year?: InputMaybe<Scalars["String"]["input"]>;
  };

export type Input_core_apimessages_AdminTax1099kReportingThresholdCreateResponse =
  {
    id?: InputMaybe<Scalars["String"]["input"]>;
    region?: InputMaybe<Scalars["String"]["input"]>;
    salesAmountCents?: InputMaybe<Scalars["String"]["input"]>;
    transactionCount?: InputMaybe<Scalars["String"]["input"]>;
    year?: InputMaybe<Scalars["String"]["input"]>;
  };

export type Input_core_apimessages_AdminTax1099kReportingThresholdDeleteRequest =
  {
    id?: InputMaybe<Scalars["String"]["input"]>;
  };

export type Input_core_apimessages_AdminTax1099kReportingThresholdDeleteResponse =
  {
    id?: InputMaybe<Scalars["String"]["input"]>;
  };

export type Input_core_apimessages_AdminTax1099kReportingThresholdSearchRequest =
  {
    limit?: InputMaybe<Scalars["String"]["input"]>;
    offset?: InputMaybe<Scalars["String"]["input"]>;
    region?: InputMaybe<Scalars["String"]["input"]>;
    year?: InputMaybe<Scalars["String"]["input"]>;
  };

export type Input_core_apimessages_AdminTax1099kReportingThresholdSearchResponse =
  {
    limit?: InputMaybe<Scalars["String"]["input"]>;
    offset?: InputMaybe<Scalars["String"]["input"]>;
    taxReportingThresholds?: InputMaybe<
      Array<
        InputMaybe<Input_core_apimessages_Tax1099kReportingThresholdSearchResult>
      >
    >;
    totalResults?: InputMaybe<Scalars["String"]["input"]>;
  };

export type Input_core_apimessages_AdminTax1099kReportingThresholdUpdateRequest =
  {
    id?: InputMaybe<Scalars["String"]["input"]>;
    region?: InputMaybe<Scalars["String"]["input"]>;
    salesAmountCents?: InputMaybe<Scalars["String"]["input"]>;
    transactionCount?: InputMaybe<Scalars["String"]["input"]>;
    year?: InputMaybe<Scalars["String"]["input"]>;
  };

export type Input_core_apimessages_AdminTax1099kReportingThresholdUpdateResponse =
  {
    id?: InputMaybe<Scalars["String"]["input"]>;
    region?: InputMaybe<Scalars["String"]["input"]>;
    salesAmountCents?: InputMaybe<Scalars["String"]["input"]>;
    transactionCount?: InputMaybe<Scalars["String"]["input"]>;
    year?: InputMaybe<Scalars["String"]["input"]>;
  };

export type Input_core_apimessages_AdminUserDatadogErrorsSearchRequest = {
  userId?: InputMaybe<Scalars["String"]["input"]>;
};

export type Input_core_apimessages_AdminUserDatadogErrorsSearchResponse = {
  datadogErrors?: InputMaybe<
    Array<InputMaybe<Input_core_apimessages_DatadogError>>
  >;
};

export type Input_core_apimessages_AdminUserSearchRequest = {
  activated?: InputMaybe<core_apimessages_AdminUserSearchRequest_ActivatedFilter>;
  page?: InputMaybe<Scalars["String"]["input"]>;
  perPage?: InputMaybe<Scalars["String"]["input"]>;
  search?: InputMaybe<Scalars["String"]["input"]>;
  userFilter?: InputMaybe<Scalars["String"]["input"]>;
};

export type Input_core_apimessages_AdminUserSearchResponse = {
  page?: InputMaybe<Scalars["String"]["input"]>;
  perPage?: InputMaybe<Scalars["String"]["input"]>;
  totalResults?: InputMaybe<Scalars["String"]["input"]>;
  users?: InputMaybe<
    Array<InputMaybe<Input_core_apimessages_UserSearchResult>>
  >;
};

export type Input_core_apimessages_AdsSearchRequest = {
  adGroupUuids?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
};

export type Input_core_apimessages_AdsSearchResponse = {
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  resultUuids?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  total?: InputMaybe<Scalars["Int"]["input"]>;
};

export type Input_core_apimessages_Advertiser = {
  brandSlugs?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  shopSlugs?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  uuid?: InputMaybe<Scalars["String"]["input"]>;
};

export type Input_core_apimessages_AdvertisersSearchRequest = {
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
};

export type Input_core_apimessages_AdvertisersSearchResponse = {
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  resultUuids?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  total?: InputMaybe<Scalars["Int"]["input"]>;
};

export type Input_core_apimessages_AdyenBalanceOnboardingResponse = {
  accountHolderId?: InputMaybe<Scalars["String"]["input"]>;
  balanceAccountId?: InputMaybe<Scalars["String"]["input"]>;
  legalEntityId?: InputMaybe<Scalars["String"]["input"]>;
  transferInstrumentId?: InputMaybe<Scalars["String"]["input"]>;
};

export type Input_core_apimessages_AdyenBalancePlatformNotification = {
  createdAt?: InputMaybe<Scalars["Timestamp"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  rawBody?: InputMaybe<Scalars["String"]["input"]>;
};

export type Input_core_apimessages_AdyenChallengeShopperResults = {
  paymentData?: InputMaybe<Scalars["String"]["input"]>;
  threeds2ChallengeResult?: InputMaybe<Scalars["String"]["input"]>;
};

export type Input_core_apimessages_AdyenCheckoutPaymentMethods = {
  /** JSON-encoded Adyen response to parse client-side */
  adyenCheckoutPaymentMethodsConfig?: InputMaybe<Scalars["String"]["input"]>;
};

export type Input_core_apimessages_AdyenCreditCardEntry = {
  /**
   *   When creating a new credit card with adyen_tokenized_fields, for some
   * reason Adyen does not have an "encryptedCardholderName" field so this
   * needs to be passed separately as an unencrypted field.
   */
  cardholderName?: InputMaybe<Scalars["String"]["input"]>;
  /**
   *   JSON-encoded string for creating a new credit card, generated by secured fields (Adyen's new SDK).
   * Each key is the name of an individual credit card field (ex: "encryptedExpiryYear"),
   * and each value is an encrypted string. Core parses the JSON string, then passes the fully parsed
   * object to Adyen without modifying any individual keys or values. Adyen decrypts each field when it reaches their server.
   */
  tokenizedFields?: InputMaybe<Scalars["String"]["input"]>;
};

export type Input_core_apimessages_AdyenIdentifyShopperResults = {
  paymentData?: InputMaybe<Scalars["String"]["input"]>;
  threeds2FingerprintResult?: InputMaybe<Scalars["String"]["input"]>;
};

export type Input_core_apimessages_AdyenOnboardingLinkRequest = {
  redirectUrl?: InputMaybe<Scalars["String"]["input"]>;
};

export type Input_core_apimessages_AdyenOnboardingLinkResponse = {
  url?: InputMaybe<Scalars["String"]["input"]>;
};

export type Input_core_apimessages_AdyenPaymentResult = {
  action?: InputMaybe<Scalars["String"]["input"]>;
  challengeToken?: InputMaybe<Scalars["String"]["input"]>;
  fingerprintToken?: InputMaybe<Scalars["String"]["input"]>;
  paymentData?: InputMaybe<Scalars["String"]["input"]>;
  paymentStatus?: InputMaybe<core_apimessages_AdyenPaymentResult_PaymentStatus>;
  threeDsOneData?: InputMaybe<Input_core_apimessages_ThreeDSOneData>;
  threeDsOneRedirectUrl?: InputMaybe<Scalars["String"]["input"]>;
};

export type Input_core_apimessages_AdyenThreeDSBillingMethodEntry = {
  billingCardEntry?: InputMaybe<Input_core_apimessages_BillingCreditCardEntry>;
  paymentData?: InputMaybe<Scalars["String"]["input"]>;
};

export type Input_core_apimessages_AdyenVerifyShopperResults = {
  threeds2Result?: InputMaybe<Scalars["String"]["input"]>;
};

export type Input_core_apimessages_AffirmFinancingPromotion = {
  availableTermMonths?: InputMaybe<Array<InputMaybe<Scalars["Int"]["input"]>>>;
  endsAt?: InputMaybe<Input_core_apimessages_LegacyTimestamp>;
  financingPlan?: InputMaybe<Scalars["String"]["input"]>;
  /** affirm_financing_promotions.id */
  id?: InputMaybe<Scalars["String"]["input"]>;
  links?: InputMaybe<Input_core_apimessages_AffirmFinancingPromotionLinks>;
  maxTermMonth?: InputMaybe<Scalars["Int"]["input"]>;
  startsAt?: InputMaybe<Input_core_apimessages_LegacyTimestamp>;
  zeroPercentFinancing?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type Input_core_apimessages_AffirmFinancingPromotionLinks = {
  curatedSet?: InputMaybe<Input_core_apimessages_Link>;
};

export type Input_core_apimessages_AffirmFinancingPromotionsRequest = {
  /** products.id */
  ids?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
};

export type Input_core_apimessages_AffirmFinancingPromotionsResponse = {
  affirmFinancingPromotions?: InputMaybe<
    Array<InputMaybe<Input_core_apimessages_AffirmFinancingPromotion>>
  >;
};

export type Input_core_apimessages_Alert = {
  description?: InputMaybe<Scalars["String"]["input"]>;
  title?: InputMaybe<Scalars["String"]["input"]>;
  url?: InputMaybe<Scalars["String"]["input"]>;
};

export type Input_core_apimessages_ApplyCheckoutDiscountCodeRequest = {
  discountCode?: InputMaybe<Scalars["String"]["input"]>;
  /** checkout UUID */
  id?: InputMaybe<Scalars["String"]["input"]>;
};

export type Input_core_apimessages_ApplyCheckoutDiscountCodeResponse = {
  /** checkout UUID */
  id?: InputMaybe<Scalars["String"]["input"]>;
};

export type Input_core_apimessages_Article = {
  articleCategories?: InputMaybe<
    Array<InputMaybe<Input_core_apimessages_ArticleCategory>>
  >;
  /** articles.id */
  id?: InputMaybe<Scalars["String"]["input"]>;
  slug?: InputMaybe<Scalars["String"]["input"]>;
  title?: InputMaybe<Scalars["String"]["input"]>;
  webLink?: InputMaybe<Input_core_apimessages_Link>;
};

export type Input_core_apimessages_ArticleCategories = {
  categories?: InputMaybe<
    Array<InputMaybe<Input_core_apimessages_ArticleCategory>>
  >;
};

export type Input_core_apimessages_ArticleCategoriesResponse = {
  articleCategories?: InputMaybe<Input_core_apimessages_ArticleCategories>;
};

export type Input_core_apimessages_ArticleCategory = {
  /** categories.id */
  id?: InputMaybe<Scalars["String"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  slug?: InputMaybe<Scalars["String"]["input"]>;
};

export type Input_core_apimessages_ArticlesRequest = {
  /** articles.id */
  ids?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  slugs?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
};

export type Input_core_apimessages_ArticlesResponse = {
  articles?: InputMaybe<Array<InputMaybe<Input_core_apimessages_Article>>>;
};

export type Input_core_apimessages_AutoOffer = {
  id?: InputMaybe<Scalars["String"]["input"]>;
  instantOffersEnabled?: InputMaybe<Scalars["Boolean"]["input"]>;
  listingId?: InputMaybe<Scalars["String"]["input"]>;
  message?: InputMaybe<Scalars["String"]["input"]>;
  percentage?: InputMaybe<Scalars["Int"]["input"]>;
  price?: InputMaybe<Input_core_apimessages_Money>;
  pushToExisting?: InputMaybe<Scalars["Boolean"]["input"]>;
  shippingPriceOverride?: InputMaybe<Input_core_apimessages_Money>;
};

export type Input_core_apimessages_AutoOffersRequest = {
  listingIds?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
};

export type Input_core_apimessages_AutoOffersResponse = {
  autoOffers?: InputMaybe<Array<InputMaybe<Input_core_apimessages_AutoOffer>>>;
};

export type Input_core_apimessages_AutomatchingCanonicalProduct = {
  brand?: InputMaybe<Input_core_apimessages_Brand>;
  finish?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  model?: InputMaybe<Scalars["String"]["input"]>;
  primaryImage?: InputMaybe<Input_core_apimessages_Image>;
  upc?: InputMaybe<Scalars["String"]["input"]>;
  year?: InputMaybe<Scalars["String"]["input"]>;
};

export type Input_core_apimessages_AvailableAction = {
  /** Same value as name, but calling it id helps with frontend apollo cache */
  id?: InputMaybe<core_apimessages_UserActionName>;
  name?: InputMaybe<core_apimessages_UserActionName>;
  status?: InputMaybe<core_apimessages_UserActionStatus>;
};

export type Input_core_apimessages_AvailableForPickupSignal = {
  isValid?: InputMaybe<Scalars["Boolean"]["input"]>;
  translations?: InputMaybe<Input_core_apimessages_SignalTranslation>;
};

export type Input_core_apimessages_AvailableNearbyForPickupSignal = {
  isValid?: InputMaybe<Scalars["Boolean"]["input"]>;
  translations?: InputMaybe<Input_core_apimessages_SignalTranslation>;
};

export type Input_core_apimessages_BearerV2TokenRequest = {
  client?: InputMaybe<core_apimessages_BearerV2TokenRequest_Client>;
};

export type Input_core_apimessages_BearerV2TokenResponse = {
  token?: InputMaybe<Scalars["String"]["input"]>;
};

export type Input_core_apimessages_BillingCreditCardEntry = {
  address?: InputMaybe<Input_core_apimessages_AddressEntry>;
  adyenCreditCard?: InputMaybe<Input_core_apimessages_AdyenCreditCardEntry>;
  /**
   *   string token for creating a new credit card, generated by Adyen's Client-Side Encryption.
   * Adyen decrypts ths into an object containing key/value pairs of raw credit card fields.
   */
  adyenToken?: InputMaybe<Scalars["String"]["input"]>;
  /** credit_cards.id for selecting an already created credit card */
  creditCardId?: InputMaybe<Scalars["String"]["input"]>;
};

export type Input_core_apimessages_BillingMethodChallengeShopperRequest = {
  billingMethodUuid?: InputMaybe<Scalars["String"]["input"]>;
  results?: InputMaybe<Input_core_apimessages_AdyenChallengeShopperResults>;
};

export type Input_core_apimessages_BillingMethodChallengeShopperResponse = {
  adyenPaymentResult?: InputMaybe<Input_core_apimessages_AdyenPaymentResult>;
  billingMethodUuid?: InputMaybe<Scalars["String"]["input"]>;
  creditCard?: InputMaybe<Input_core_apimessages_CreditCard>;
  errors?: InputMaybe<Array<InputMaybe<Input_core_apimessages_Error>>>;
};

export type Input_core_apimessages_BillingMethodIdentifyShopperRequest = {
  billingMethodUuid?: InputMaybe<Scalars["String"]["input"]>;
  results?: InputMaybe<Input_core_apimessages_AdyenIdentifyShopperResults>;
};

export type Input_core_apimessages_BillingMethodIdentifyShopperResponse = {
  adyenPaymentResult?: InputMaybe<Input_core_apimessages_AdyenPaymentResult>;
  billingMethodUuid?: InputMaybe<Scalars["String"]["input"]>;
  creditCard?: InputMaybe<Input_core_apimessages_CreditCard>;
  errors?: InputMaybe<Array<InputMaybe<Input_core_apimessages_Error>>>;
};

export type Input_core_apimessages_BillingMethodVerifyShopperRequest = {
  billingMethodUuid?: InputMaybe<Scalars["String"]["input"]>;
  /** optional, to indicate that a new card is being added specifically for a partial payment */
  partialPayment?: InputMaybe<Scalars["Boolean"]["input"]>;
  results?: InputMaybe<Input_core_apimessages_AdyenVerifyShopperResults>;
};

export type Input_core_apimessages_BillingMethodVerifyShopperResponse = {
  adyenPaymentResult?: InputMaybe<Input_core_apimessages_AdyenPaymentResult>;
  billingMethodUuid?: InputMaybe<Scalars["String"]["input"]>;
  creditCard?: InputMaybe<Input_core_apimessages_CreditCard>;
  errors?: InputMaybe<Array<InputMaybe<Input_core_apimessages_Error>>>;
};

export type Input_core_apimessages_Brand = {
  /** brands.uuid */
  id?: InputMaybe<Scalars["String"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  slug?: InputMaybe<Scalars["String"]["input"]>;
  uuid?: InputMaybe<Scalars["String"]["input"]>;
  webLink?: InputMaybe<Input_core_apimessages_Link>;
};

export type Input_core_apimessages_BrandManagerBrandRequest = {
  slug?: InputMaybe<Scalars["String"]["input"]>;
};

export type Input_core_apimessages_BrandManagerBrandResponse = {
  brand?: InputMaybe<Input_core_apimessages_Brand>;
};

export type Input_core_apimessages_BrandManagerBrandsRequest = {
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  slugs?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  uuids?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
};

export type Input_core_apimessages_BrandManagerBrandsResponse = {
  brandManagerBrands?: InputMaybe<
    Array<InputMaybe<Input_core_apimessages_Brand>>
  >;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
};

export type Input_core_apimessages_BrandsRequest = {
  slugs?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  uuids?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
};

export type Input_core_apimessages_BrandsResponse = {
  brands?: InputMaybe<Array<InputMaybe<Input_core_apimessages_Brand>>>;
};

export type Input_core_apimessages_BrowsePage = {
  breadcrumbs?: InputMaybe<
    Array<InputMaybe<Input_core_apimessages_BrowsePageBreadcrumb>>
  >;
  combinedFilters?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  ownFilters?: InputMaybe<Scalars["String"]["input"]>;
  title?: InputMaybe<Scalars["String"]["input"]>;
  url?: InputMaybe<Scalars["String"]["input"]>;
};

export type Input_core_apimessages_BrowsePageBreadcrumb = {
  title?: InputMaybe<Scalars["String"]["input"]>;
  url?: InputMaybe<Scalars["String"]["input"]>;
};

export type Input_core_apimessages_BrowsePageLink = {
  combinedFilters?: InputMaybe<Scalars["String"]["input"]>;
  matchParams?: InputMaybe<
    Array<InputMaybe<Input_core_apimessages_BrowsePageLink_MatchParams>>
  >;
  matchType?: InputMaybe<core_apimessages_BrowsePageLink_MatchType>;
  url?: InputMaybe<Scalars["String"]["input"]>;
};

export type Input_core_apimessages_BrowsePageLink_MatchParams = {
  paramName?: InputMaybe<Scalars["String"]["input"]>;
  value?: InputMaybe<Scalars["String"]["input"]>;
};

export type Input_core_apimessages_BrowsePageLinksRequest = {
  id?: InputMaybe<Scalars["String"]["input"]>;
};

export type Input_core_apimessages_BrowsePageLinksResponse = {
  browsePageLinks?: InputMaybe<
    Array<InputMaybe<Input_core_apimessages_BrowsePageLink>>
  >;
};

export type Input_core_apimessages_BulkAddressesRequest = {
  queries?: InputMaybe<
    Array<InputMaybe<Input_core_apimessages_BulkAddressesRequest_Query>>
  >;
};

export type Input_core_apimessages_BulkAddressesRequest_Query = {
  primaryKey?: InputMaybe<Input_core_apimessages_PrimaryKey>;
  relation?: InputMaybe<core_apimessages_BulkAddressesRequest_Relation>;
};

export type Input_core_apimessages_BulkAddressesResponse = {
  results?: InputMaybe<
    Array<InputMaybe<Input_core_apimessages_BulkAddressesResponse_QueryResult>>
  >;
};

export type Input_core_apimessages_BulkAddressesResponse_QueryResult = {
  addresses?: InputMaybe<Array<InputMaybe<Input_core_apimessages_Address>>>;
};

export type Input_core_apimessages_BulkCategoriesRequest = {
  requests?: InputMaybe<
    Array<InputMaybe<Input_core_apimessages_CategoryRequest>>
  >;
};

export type Input_core_apimessages_BulkCategoriesResponse = {
  responses?: InputMaybe<
    Array<InputMaybe<Input_core_apimessages_CategoriesResponse>>
  >;
};

export type Input_core_apimessages_BulkCreateAutoOffersAsyncRequest = {
  expectedTotal?: InputMaybe<Scalars["Int"]["input"]>;
  percentage?: InputMaybe<Scalars["Int"]["input"]>;
  /** Serialized JSON of dashboard listing search params */
  serializedDashboardSearchParams?: InputMaybe<Scalars["String"]["input"]>;
};

export type Input_core_apimessages_BulkCreateAutoOffersRequest = {
  listingIds?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  percentage?: InputMaybe<Scalars["Int"]["input"]>;
};

export type Input_core_apimessages_BulkCreateBumpedAsyncRequest = {
  expectedTotal?: InputMaybe<Scalars["Int"]["input"]>;
  rate?: InputMaybe<Scalars["Float"]["input"]>;
  /** Serialized JSON of dashboard listing search params */
  serializedDashboardSearchParams?: InputMaybe<Scalars["String"]["input"]>;
};

export type Input_core_apimessages_BulkCreateBumpedRequest = {
  listingIds?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  rate?: InputMaybe<Scalars["Float"]["input"]>;
};

export type Input_core_apimessages_BulkCreateZeroPercentAffirmAsyncRequest = {
  expectedTotal?: InputMaybe<Scalars["Int"]["input"]>;
  /** Serialized JSON of dashboard listing search params */
  serializedDashboardSearchParams?: InputMaybe<Scalars["String"]["input"]>;
};

export type Input_core_apimessages_BulkCreateZeroPercentAffirmRequest = {
  listingIds?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
};

export type Input_core_apimessages_BulkCuratedSetsRequest = {
  queries?: InputMaybe<
    Array<InputMaybe<Input_core_apimessages_BulkCuratedSetsRequest_Query>>
  >;
};

export type Input_core_apimessages_BulkCuratedSetsRequest_Query = {
  primaryKey?: InputMaybe<Input_core_apimessages_PrimaryKey>;
  relation?: InputMaybe<core_apimessages_BulkCuratedSetsRequest_Relation>;
  setTypes?: InputMaybe<Array<InputMaybe<core_apimessages_CuratedSet_SetType>>>;
};

export type Input_core_apimessages_BulkCuratedSetsResponse = {
  results?: InputMaybe<
    Array<
      InputMaybe<Input_core_apimessages_BulkCuratedSetsResponse_QueryResult>
    >
  >;
};

export type Input_core_apimessages_BulkCuratedSetsResponse_QueryResult = {
  curatedSets?: InputMaybe<
    Array<InputMaybe<Input_core_apimessages_CuratedSet>>
  >;
};

export type Input_core_apimessages_BulkDeleteAutoOffersAsyncRequest = {
  expectedTotal?: InputMaybe<Scalars["Int"]["input"]>;
  /** Serialized JSON of dashboard listing search params */
  serializedDashboardSearchParams?: InputMaybe<Scalars["String"]["input"]>;
};

export type Input_core_apimessages_BulkDeleteAutoOffersRequest = {
  listingIds?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
};

export type Input_core_apimessages_BulkDeleteBumpedAsyncRequest = {
  expectedTotal?: InputMaybe<Scalars["Int"]["input"]>;
  /** Serialized JSON of dashboard listing search params */
  serializedDashboardSearchParams?: InputMaybe<Scalars["String"]["input"]>;
};

export type Input_core_apimessages_BulkDeleteBumpedRequest = {
  listingIds?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
};

export type Input_core_apimessages_BulkDeleteListingsAsyncRequest = {
  expectedTotal?: InputMaybe<Scalars["Int"]["input"]>;
  /** Serialized JSON of dashboard listing search params */
  serializedDashboardSearchParams?: InputMaybe<Scalars["String"]["input"]>;
};

export type Input_core_apimessages_BulkDeleteListingsRequest = {
  listingIds?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
};

export type Input_core_apimessages_BulkDeleteListingsResponse = {
  listings?: InputMaybe<
    Array<
      InputMaybe<Input_core_apimessages_BulkDeleteListingsResponse_ListingDeleted>
    >
  >;
};

export type Input_core_apimessages_BulkDeleteListingsResponse_ListingDeleted = {
  deleteErrors?: InputMaybe<Array<InputMaybe<Input_core_apimessages_Error>>>;
  listingId?: InputMaybe<Scalars["String"]["input"]>;
  success?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type Input_core_apimessages_BulkDeleteZeroPercentAffirmAsyncRequest = {
  expectedTotal?: InputMaybe<Scalars["Int"]["input"]>;
  /** Serialized JSON of dashboard listing search params */
  serializedDashboardSearchParams?: InputMaybe<Scalars["String"]["input"]>;
};

export type Input_core_apimessages_BulkDeleteZeroPercentAffirmRequest = {
  listingIds?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
};

export type Input_core_apimessages_BulkExportListingsRequest = {
  listingIds?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  recipientId?: InputMaybe<Scalars["String"]["input"]>;
  serializedDashboardSearchParams?: InputMaybe<Scalars["String"]["input"]>;
  state?: InputMaybe<core_apimessages_BulkExportListingsRequest_ListingState>;
};

export type Input_core_apimessages_BulkExportListingsResponse = {
  enqueued?: InputMaybe<Scalars["Boolean"]["input"]>;
  message?: InputMaybe<Scalars["String"]["input"]>;
};

export type Input_core_apimessages_BulkImageRequest = {
  requests?: InputMaybe<Array<InputMaybe<Input_core_apimessages_ImageRequest>>>;
};

export type Input_core_apimessages_BulkOrdersRequest = {
  queries?: InputMaybe<
    Array<InputMaybe<Input_core_apimessages_BulkOrdersRequest_Query>>
  >;
};

export type Input_core_apimessages_BulkOrdersRequest_Query = {
  as?: InputMaybe<core_apimessages_BulkOrdersRequest_Party>;
  primaryKey?: InputMaybe<Input_core_apimessages_PrimaryKey>;
  relation?: InputMaybe<core_apimessages_BulkOrdersRequest_Relation>;
};

export type Input_core_apimessages_BulkOrdersResponse = {
  results?: InputMaybe<
    Array<InputMaybe<Input_core_apimessages_BulkOrdersResponse_QueryResult>>
  >;
};

export type Input_core_apimessages_BulkOrdersResponse_QueryResult = {
  orders?: InputMaybe<Array<InputMaybe<Input_core_apimessages_Order>>>;
};

export type Input_core_apimessages_BulkPublishListingsAsyncRequest = {
  expectedTotal?: InputMaybe<Scalars["Int"]["input"]>;
  /** Serialized JSON of dashboard listing search params */
  serializedDashboardSearchParams?: InputMaybe<Scalars["String"]["input"]>;
};

export type Input_core_apimessages_BulkPublishListingsRequest = {
  listingIds?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
};

export type Input_core_apimessages_BulkPublishListingsResponse = {
  listings?: InputMaybe<
    Array<
      InputMaybe<Input_core_apimessages_BulkPublishListingsResponse_ListingPublished>
    >
  >;
  prepublishStepRequired?: InputMaybe<Input_core_apimessages_BulkPublishListingsResponse_PrepublishStepRequired>;
};

export type Input_core_apimessages_BulkPublishListingsResponse_ListingPublished =
  {
    listingId?: InputMaybe<Scalars["String"]["input"]>;
    publishErrors?: InputMaybe<Array<InputMaybe<Input_core_apimessages_Error>>>;
    success?: InputMaybe<Scalars["Boolean"]["input"]>;
  };

export type Input_core_apimessages_BulkPublishListingsResponse_PrepublishStepRequired =
  {
    step?: InputMaybe<Scalars["String"]["input"]>;
  };

export type Input_core_apimessages_BulkSaleMembershipsRequest = {
  listingIds?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  saleId?: InputMaybe<Scalars["String"]["input"]>;
};

export type Input_core_apimessages_BulkTaxPoliciesRequest = {
  requests?: InputMaybe<
    Array<InputMaybe<Input_core_apimessages_TaxPoliciesRequest>>
  >;
};

export type Input_core_apimessages_BulkTaxPoliciesResponse = {
  shops?: InputMaybe<Array<InputMaybe<Input_core_apimessages_ShopTaxPolicies>>>;
};

/** this is the encoded bump key sent as a response */
export type Input_core_apimessages_BumpKey = {
  key?: InputMaybe<Scalars["String"]["input"]>;
};

/** this is the shape of the bumpKey before it gets encoded */
export type Input_core_apimessages_BumpKeyContents = {
  cookieId?: InputMaybe<Scalars["String"]["input"]>;
  /** JWT Issued At Token, integer timestamp */
  iat?: InputMaybe<Scalars["Int"]["input"]>;
  /** JWT UUID */
  jti?: InputMaybe<Scalars["String"]["input"]>;
  productId?: InputMaybe<Scalars["String"]["input"]>;
  sessionId?: InputMaybe<Scalars["String"]["input"]>;
  source?: InputMaybe<core_apimessages_BumpKeyContents_OffsiteSource>;
  userId?: InputMaybe<Scalars["String"]["input"]>;
};

export type Input_core_apimessages_BumpKeysRequest = {
  listingIds?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
};

export type Input_core_apimessages_BumpKeysResponse = {
  bumpKeys?: InputMaybe<Array<InputMaybe<Input_core_apimessages_BumpKey>>>;
};

export type Input_core_apimessages_BumpRate = {
  rate?: InputMaybe<Scalars["Float"]["input"]>;
};

export type Input_core_apimessages_BusinessRegistrationNumber = {
  countryCode?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  registrationNumber?: InputMaybe<Scalars["String"]["input"]>;
};

export type Input_core_apimessages_BuyWithConfidenceSignal = {
  isValid?: InputMaybe<Scalars["Boolean"]["input"]>;
  link?: InputMaybe<Input_core_apimessages_SignalLink>;
  translations?: InputMaybe<Input_core_apimessages_SignalTranslation>;
};

export type Input_core_apimessages_BuyerActions = {
  canBeResold?: InputMaybe<Scalars["Boolean"]["input"]>;
  canMarkReceived?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type Input_core_apimessages_BuyerOrder = {
  amountBonusBucks?: InputMaybe<Input_core_apimessages_Money>;
  amountCreditBucks?: InputMaybe<Input_core_apimessages_Money>;
  amountOwed?: InputMaybe<Input_core_apimessages_Money>;
  amountProduct?: InputMaybe<Input_core_apimessages_Money>;
  amountProductSubtotal?: InputMaybe<Input_core_apimessages_Money>;
  amountShipping?: InputMaybe<Input_core_apimessages_Money>;
  amountTax?: InputMaybe<Input_core_apimessages_Money>;
  amountTotal?: InputMaybe<Input_core_apimessages_Money>;
  buyerActions?: InputMaybe<Input_core_apimessages_BuyerActions>;
  protectionPlanDetails?: InputMaybe<Input_core_apimessages_OrderProtectionPlanDetails>;
  thirdPartyAdsAttributes?: InputMaybe<Input_core_apimessages_ThirdPartyAdsAttributes>;
  uuid?: InputMaybe<Scalars["String"]["input"]>;
};

export type Input_core_apimessages_BuyerOrdersResponse = {
  orders?: InputMaybe<Array<InputMaybe<Input_core_apimessages_BuyerOrder>>>;
};

export type Input_core_apimessages_BuyerRefund = {
  id?: InputMaybe<Scalars["String"]["input"]>;
  nonBucksRefundAmount?: InputMaybe<Input_core_apimessages_ConvertedMoney>;
  reverbBucksAmountRefunded?: InputMaybe<Input_core_apimessages_Money>;
};

export type Input_core_apimessages_BuyerRefundsRequest = {
  ids?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
};

export type Input_core_apimessages_BuyerRefundsResponse = {
  buyerRefunds?: InputMaybe<
    Array<InputMaybe<Input_core_apimessages_BuyerRefund>>
  >;
};

export type Input_core_apimessages_CPSuggestion = {
  canonicalProduct?: InputMaybe<Input_core_apimessages_AutomatchingCanonicalProduct>;
  canonicalProductId?: InputMaybe<Scalars["String"]["input"]>;
  confidence?: InputMaybe<Scalars["Float"]["input"]>;
  service?: InputMaybe<Scalars["String"]["input"]>;
};

export type Input_core_apimessages_CPSuggestions = {
  suggestions?: InputMaybe<
    Array<InputMaybe<Input_core_apimessages_CPSuggestion>>
  >;
};

export type Input_core_apimessages_CPSuggestionsRequest = {
  listingIds?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
};

export type Input_core_apimessages_CPSuggestionsResponse = {
  cpSuggestions?: InputMaybe<
    Array<InputMaybe<Input_core_apimessages_CPSuggestions>>
  >;
};

export type Input_core_apimessages_CSP = {
  body?: InputMaybe<Scalars["String"]["input"]>;
  brandUuid?: InputMaybe<Scalars["String"]["input"]>;
  canonicalProductIds?: InputMaybe<
    Array<InputMaybe<Scalars["String"]["input"]>>
  >;
  canonicalProductImageApprovedIds?: InputMaybe<
    Array<InputMaybe<Scalars["String"]["input"]>>
  >;
  categoryRootUuid?: InputMaybe<Scalars["String"]["input"]>;
  categoryUuids?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  finishes?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  generic?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** comparison_shopping_pages.id */
  id?: InputMaybe<Scalars["String"]["input"]>;
  metaDescription?: InputMaybe<Scalars["String"]["input"]>;
  model?: InputMaybe<Scalars["String"]["input"]>;
  newListingsLink?: InputMaybe<Input_core_apimessages_Link>;
  slug?: InputMaybe<Scalars["String"]["input"]>;
  summary?: InputMaybe<Scalars["String"]["input"]>;
  title?: InputMaybe<Scalars["String"]["input"]>;
  upcs?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  usedListingsLink?: InputMaybe<Input_core_apimessages_Link>;
  uuid?: InputMaybe<Scalars["String"]["input"]>;
  webLink?: InputMaybe<Input_core_apimessages_Link>;
};

export type Input_core_apimessages_CSPExpressSaleItemBid = {
  bidId?: InputMaybe<Scalars["String"]["input"]>;
  carrier?: InputMaybe<Scalars["String"]["input"]>;
  cspUuid?: InputMaybe<Scalars["String"]["input"]>;
  estimatedPayout?: InputMaybe<Input_core_apimessages_Money>;
  shopName?: InputMaybe<Scalars["String"]["input"]>;
};

export type Input_core_apimessages_CSPInventory = {
  /** comparison_shopping_pages.id */
  id?: InputMaybe<Scalars["String"]["input"]>;
  newLowPrice?: InputMaybe<Input_core_apimessages_Money>;
  newTotal?: InputMaybe<Scalars["Int"]["input"]>;
  slug?: InputMaybe<Scalars["String"]["input"]>;
  usedLowPrice?: InputMaybe<Input_core_apimessages_Money>;
  usedTotal?: InputMaybe<Scalars["Int"]["input"]>;
  uuid?: InputMaybe<Scalars["String"]["input"]>;
};

export type Input_core_apimessages_CSPPricing = {
  hasData?: InputMaybe<Scalars["Boolean"]["input"]>;
  high?: InputMaybe<Input_core_apimessages_Money>;
  low?: InputMaybe<Input_core_apimessages_Money>;
  mid?: InputMaybe<Input_core_apimessages_Money>;
};

export type Input_core_apimessages_CSPReview = {
  blocked?: InputMaybe<Scalars["Boolean"]["input"]>;
  body?: InputMaybe<Scalars["String"]["input"]>;
  createdAt?: InputMaybe<Scalars["String"]["input"]>;
  didIVote?: InputMaybe<Scalars["Boolean"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  isMyReview?: InputMaybe<Scalars["Boolean"]["input"]>;
  rating?: InputMaybe<Scalars["Int"]["input"]>;
  reviewer?: InputMaybe<Input_core_apimessages_Reviewer>;
  title?: InputMaybe<Scalars["String"]["input"]>;
  verified?: InputMaybe<Scalars["Boolean"]["input"]>;
  voteCount?: InputMaybe<Scalars["Int"]["input"]>;
};

export type Input_core_apimessages_CSPReviews = {
  avgRating?: InputMaybe<Scalars["Float"]["input"]>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  myReview?: InputMaybe<Input_core_apimessages_CSPReview>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  reviews?: InputMaybe<Array<InputMaybe<Input_core_apimessages_CSPReview>>>;
  total?: InputMaybe<Scalars["Int"]["input"]>;
};

export type Input_core_apimessages_CSPReviewsRequest = {
  ids?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  slugs?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  uuids?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
};

export type Input_core_apimessages_CSPReviewsResponse = {
  reviews?: InputMaybe<Array<InputMaybe<Input_core_apimessages_CSPReviews>>>;
};

export type Input_core_apimessages_CSPSpec = {
  productSpecs?: InputMaybe<
    Array<InputMaybe<Input_core_apimessages_ProductSpec>>
  >;
};

export type Input_core_apimessages_CSPVideo = {
  videoLinks?: InputMaybe<Array<InputMaybe<Input_core_apimessages_VideoLink>>>;
};

export type Input_core_apimessages_CSPsFeaturedListingRequest = {
  /** comparison_shopping_pages.id */
  ids?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  slugs?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  uuids?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
};

export type Input_core_apimessages_CSPsFeaturedListingResponse = {
  featuredListingIds?: InputMaybe<
    Array<InputMaybe<Scalars["String"]["input"]>>
  >;
};

export type Input_core_apimessages_CSPsInventoryRequest = {
  /** comparison_shopping_pages.id */
  ids?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  slugs?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  uuids?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
};

export type Input_core_apimessages_CSPsInventoryResponse = {
  inventories?: InputMaybe<
    Array<InputMaybe<Input_core_apimessages_CSPInventory>>
  >;
};

export type Input_core_apimessages_CSPsRequest = {
  ids?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  slugs?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  uuids?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
};

export type Input_core_apimessages_CSPsResponse = {
  csps?: InputMaybe<Array<InputMaybe<Input_core_apimessages_CSP>>>;
};

export type Input_core_apimessages_CSPsSpecsRequest = {
  /** comparison_shopping_pages.id */
  ids?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  slugs?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  uuids?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
};

export type Input_core_apimessages_CSPsSpecsResponse = {
  specs?: InputMaybe<Array<InputMaybe<Input_core_apimessages_CSPSpec>>>;
};

export type Input_core_apimessages_CSPsVideoRequest = {
  /** comparison_shopping_pages.id */
  ids?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  slugs?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  uuids?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
};

export type Input_core_apimessages_CSPsVideoResponse = {
  videos?: InputMaybe<Array<InputMaybe<Input_core_apimessages_CSPVideo>>>;
};

export type Input_core_apimessages_CancelOrderRequest = {
  cancellationReason?: InputMaybe<core_apimessages_CancelOrderRequest_CancellationReason>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  relist?: InputMaybe<Scalars["Boolean"]["input"]>;
  uuid?: InputMaybe<Scalars["String"]["input"]>;
};

export type Input_core_apimessages_CancelOrderResponse = {
  order?: InputMaybe<Input_core_apimessages_Order>;
  relisted?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type Input_core_apimessages_CancelPlaidAccountRequest = {
  _?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type Input_core_apimessages_CancelPlaidAccountResponse = {
  shopId?: InputMaybe<Scalars["String"]["input"]>;
};

export type Input_core_apimessages_Candidate = {
  adGroup?: InputMaybe<Input_core_apimessages_AdGroup>;
  matches?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  weight?: InputMaybe<Scalars["Int"]["input"]>;
};

export type Input_core_apimessages_CanonicalProduct = {
  countryOfOrigin?: InputMaybe<Scalars["String"]["input"]>;
  finish?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  model?: InputMaybe<Scalars["String"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  year?: InputMaybe<Scalars["String"]["input"]>;
};

export type Input_core_apimessages_CarrierRate = {
  carrier?: InputMaybe<Scalars["String"]["input"]>;
  description?: InputMaybe<Scalars["String"]["input"]>;
  displayPosition?: InputMaybe<Scalars["Int"]["input"]>;
  expeditedOrFaster?: InputMaybe<Scalars["Boolean"]["input"]>;
  paymentMethod?: InputMaybe<core_apimessages_FeePaymentMethod>;
  rackRateAmount?: InputMaybe<Input_core_apimessages_Money>;
  reverbDiscountAmount?: InputMaybe<Input_core_apimessages_Money>;
  service?: InputMaybe<Scalars["String"]["input"]>;
  shippingProtectionAmount?: InputMaybe<Input_core_apimessages_Money>;
  signatureRequiredAmount?: InputMaybe<Input_core_apimessages_Money>;
  totalAmount?: InputMaybe<Input_core_apimessages_Money>;
  uuid?: InputMaybe<Scalars["String"]["input"]>;
};

export type Input_core_apimessages_CarrierRateGroup = {
  carrier?: InputMaybe<Scalars["String"]["input"]>;
  displayPosition?: InputMaybe<Scalars["Int"]["input"]>;
  rates?: InputMaybe<Array<InputMaybe<Input_core_apimessages_CarrierRate>>>;
};

export type Input_core_apimessages_CartItem = {
  actions?: InputMaybe<Input_core_apimessages_CartItem_Actions>;
  alerts?: InputMaybe<Array<InputMaybe<Input_core_apimessages_CartItem_Alert>>>;
  listingId?: InputMaybe<Scalars["String"]["input"]>;
  listingTotal?: InputMaybe<Input_core_apimessages_CartItem_ListingTotal>;
  orderId?: InputMaybe<Scalars["String"]["input"]>;
  paymentMethods?: InputMaybe<
    Array<InputMaybe<Input_core_apimessages_CartItem_PaymentMethod>>
  >;
  protectionPlan?: InputMaybe<Input_core_apimessages_ProtectionPlan>;
  protectionPlanEligible?: InputMaybe<Scalars["Boolean"]["input"]>;
  quantity?: InputMaybe<Scalars["Int"]["input"]>;
  shippingTotal?: InputMaybe<Input_core_apimessages_CartItem_ShippingTotal>;
  shopId?: InputMaybe<Scalars["String"]["input"]>;
  /** Estimated subtotal based on listing price and quantity */
  subtotal?: InputMaybe<Input_core_apimessages_Money>;
  uuid?: InputMaybe<Scalars["String"]["input"]>;
};

export type Input_core_apimessages_CartItemAttributes = {
  actionSourceAttributes?: InputMaybe<Input_core_apimessages_ActionSourceAttributes>;
  cartSource?: InputMaybe<Scalars["String"]["input"]>;
  listingId?: InputMaybe<Scalars["Int"]["input"]>;
  quantity?: InputMaybe<Scalars["Int"]["input"]>;
  saleId?: InputMaybe<Scalars["Int"]["input"]>;
  upsell?: InputMaybe<Scalars["Boolean"]["input"]>;
  uuid?: InputMaybe<Scalars["String"]["input"]>;
};

export type Input_core_apimessages_CartItemBundle = {
  availablePaymentMethods?: InputMaybe<
    Array<InputMaybe<Input_core_apimessages_CartItemBundle_PaymentMethod>>
  >;
  cartItems?: InputMaybe<Array<InputMaybe<Input_core_apimessages_CartItem>>>;
  checkoutBundlingId?: InputMaybe<Scalars["String"]["input"]>;
  combineAndSaveEligibleListingId?: InputMaybe<Scalars["String"]["input"]>;
  freeShippingStatuses?: InputMaybe<
    Array<InputMaybe<Input_core_apimessages_CartItemBundle_FreeShippingStatus>>
  >;
  lineItems?: InputMaybe<
    Array<InputMaybe<Input_core_apimessages_CartItemBundle_LineItem>>
  >;
  paymentMethodIcons?: InputMaybe<
    Array<InputMaybe<Input_core_apimessages_PaymentCardIcon>>
  >;
  title?: InputMaybe<Scalars["String"]["input"]>;
  total?: InputMaybe<Input_core_apimessages_CartItemBundle_Total>;
};

export type Input_core_apimessages_CartItemBundle_FreeShippingStatus = {
  amountRemaining?: InputMaybe<Input_core_apimessages_Money>;
  isThresholdMet?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Natural number up to 100 */
  percentProgress?: InputMaybe<Scalars["Int"]["input"]>;
  shopId?: InputMaybe<Scalars["String"]["input"]>;
};

export type Input_core_apimessages_CartItemBundle_LineItem = {
  amount?: InputMaybe<Input_core_apimessages_Money>;
  label?: InputMaybe<Scalars["String"]["input"]>;
  type?: InputMaybe<core_apimessages_CartItemBundle_LineItem_Type>;
};

export type Input_core_apimessages_CartItemBundle_PaymentMethod = {
  affirm?: InputMaybe<Input_core_apimessages_CheckoutAffirmDetails>;
  applePay?: InputMaybe<Input_core_apimessages_CheckoutApplePayDetails>;
  googlePay?: InputMaybe<Input_core_apimessages_CheckoutGooglePayDetails>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  paypal?: InputMaybe<Input_core_apimessages_CheckoutPaypalDetails>;
  type?: InputMaybe<core_apimessages_CheckoutPaymentMethod_Type>;
};

export type Input_core_apimessages_CartItemBundle_Total = {
  amount?: InputMaybe<Input_core_apimessages_Money>;
  label?: InputMaybe<Scalars["String"]["input"]>;
};

export type Input_core_apimessages_CartItem_Actions = {
  canChangeQuantity?: InputMaybe<Scalars["Boolean"]["input"]>;
  canMoveToWatchlist?: InputMaybe<Scalars["Boolean"]["input"]>;
  canRemoveFromCart?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type Input_core_apimessages_CartItem_Alert = {
  severity?: InputMaybe<core_apimessages_CartItem_Alert_Severity>;
  text?: InputMaybe<Scalars["String"]["input"]>;
  type?: InputMaybe<core_apimessages_CartItem_Alert_Type>;
};

export type Input_core_apimessages_CartItem_ListingTotal = {
  discount?: InputMaybe<Input_core_apimessages_CartItem_ListingTotal_Discount>;
  /** True if order listing price is less than original listing price. */
  discounted?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Original unit amount before offer pricing or sales were applied. */
  originalUnitPrice?: InputMaybe<Input_core_apimessages_Money>;
  /** Unit amount owed by the buyer for a cart item. Includes tax and any discounts from an offer or sale. */
  unitPrice?: InputMaybe<Input_core_apimessages_Money>;
};

export type Input_core_apimessages_CartItem_ListingTotal_Discount = {
  label?: InputMaybe<Scalars["String"]["input"]>;
  type?: InputMaybe<core_apimessages_CartItem_ListingTotal_Discount_Type>;
};

export type Input_core_apimessages_CartItem_PaymentMethod = {
  links?: InputMaybe<Input_core_apimessages_CartLinks>;
  type?: InputMaybe<core_apimessages_CheckoutPaymentMethod_Type>;
};

export type Input_core_apimessages_CartItem_ShippingTotal = {
  /** Shipping amount owed by buyer including discounts if eligible. */
  amountOwed?: InputMaybe<Input_core_apimessages_Money>;
  discount?: InputMaybe<Input_core_apimessages_CartItem_ShippingTotal_Discount>;
  /** True if amount owed is less than original amount owed. */
  discounted?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Original shipping amount owed the cart item before any discounts. */
  originalAmountOwed?: InputMaybe<Input_core_apimessages_Money>;
};

export type Input_core_apimessages_CartItem_ShippingTotal_Discount = {
  label?: InputMaybe<Scalars["String"]["input"]>;
  type?: InputMaybe<core_apimessages_CartItem_ShippingTotal_Discount_Type>;
};

export type Input_core_apimessages_CartLinks = {
  restBundle?: InputMaybe<Input_core_apimessages_Link>;
  restIndividual?: InputMaybe<Input_core_apimessages_Link>;
  web?: InputMaybe<Input_core_apimessages_Link>;
};

export type Input_core_apimessages_CartMoveToWatchListRequest = {
  cartItemUuid?: InputMaybe<Scalars["String"]["input"]>;
};

export type Input_core_apimessages_CartMoveToWatchListResponse = {
  /** Listing ID for the watched product */
  listingId?: InputMaybe<Scalars["String"]["input"]>;
};

export type Input_core_apimessages_CategoriesRequest = {
  /** Deprecated- maps to categories.uuid */
  ids?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  slugs?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  uuids?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  withChildren?: InputMaybe<Scalars["Boolean"]["input"]>;
  withParent?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type Input_core_apimessages_CategoriesResponse = {
  categories?: InputMaybe<Array<InputMaybe<Input_core_apimessages_Category>>>;
};

export type Input_core_apimessages_Category = {
  children?: InputMaybe<Array<InputMaybe<Input_core_apimessages_Category>>>;
  cmsLink?: InputMaybe<Input_core_apimessages_Link>;
  cmsUrl?: InputMaybe<Input_core_apimessages_Link>;
  /** categories.uuid */
  id?: InputMaybe<Scalars["String"]["input"]>;
  leaf?: InputMaybe<Scalars["Boolean"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  parent?: InputMaybe<Input_core_apimessages_Category>;
  root?: InputMaybe<Scalars["Boolean"]["input"]>;
  rootSlug?: InputMaybe<Scalars["String"]["input"]>;
  slug?: InputMaybe<Scalars["String"]["input"]>;
  webLink?: InputMaybe<Input_core_apimessages_Link>;
};

export type Input_core_apimessages_CategoryRequest = {
  /** This should only be for Article id, Category should use uuid */
  id?: InputMaybe<Scalars["String"]["input"]>;
  slug?: InputMaybe<Scalars["String"]["input"]>;
  type?: InputMaybe<core_apimessages_CategoryRequest_CategoryType>;
  uuid?: InputMaybe<Scalars["String"]["input"]>;
  withChildren?: InputMaybe<Scalars["Boolean"]["input"]>;
  withParent?: InputMaybe<Scalars["Boolean"]["input"]>;
};

/** Partially copied into abandoned_cart.proto for abandoned cart emails. */
export type Input_core_apimessages_ChannelBranding = {
  abandonUrl?: InputMaybe<Scalars["String"]["input"]>;
  channel?: InputMaybe<core_apimessages_Channel>;
  completeUrl?: InputMaybe<Scalars["String"]["input"]>;
  logoImageUrl?: InputMaybe<Scalars["String"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  /** for seller sites to identify which shop this came from */
  shopIdentifier?: InputMaybe<Scalars["String"]["input"]>;
};

export type Input_core_apimessages_Checkout = {
  /** A JSON serialized hash */
  adyenPaymentAction?: InputMaybe<Scalars["String"]["input"]>;
  availablePaymentMethods?: InputMaybe<
    Array<InputMaybe<Input_core_apimessages_CheckoutPaymentMethod>>
  >;
  billingAddress?: InputMaybe<Input_core_apimessages_Address>;
  /** Semi-opaque identifier to bundle a checkout, for example RCP-USD-direct_checkout. */
  checkoutBundlingId?: InputMaybe<Scalars["String"]["input"]>;
  creditCard?: InputMaybe<Input_core_apimessages_CreditCard>;
  guest?: InputMaybe<Input_core_apimessages_CheckoutGuest>;
  /** Checkout UUID */
  id?: InputMaybe<Scalars["String"]["input"]>;
  isAllPaidWithBucks?: InputMaybe<Scalars["Boolean"]["input"]>;
  lineItems?: InputMaybe<
    Array<InputMaybe<Input_core_apimessages_CheckoutLineItem>>
  >;
  orders?: InputMaybe<Array<InputMaybe<Input_core_apimessages_CheckoutOrder>>>;
  paymentErrorMessage?: InputMaybe<Scalars["String"]["input"]>;
  paymentMethod?: InputMaybe<Input_core_apimessages_CheckoutPaymentMethod>;
  shippingAddress?: InputMaybe<Input_core_apimessages_Address>;
  source?: InputMaybe<core_apimessages_Checkout_Source>;
  status?: InputMaybe<core_apimessages_Checkout_Status>;
  /** Amount owed. Use this field to send the displayed amount owed value to a finalize checkout */
  total?: InputMaybe<Input_core_apimessages_Checkout_Total>;
  type?: InputMaybe<core_apimessages_Checkout_Type>;
};

export type Input_core_apimessages_CheckoutAction = {
  redirectUrl?: InputMaybe<Scalars["String"]["input"]>;
  type?: InputMaybe<core_apimessages_CheckoutAction_Type>;
};

export type Input_core_apimessages_CheckoutActionRequest = {
  checkoutId?: InputMaybe<Scalars["String"]["input"]>;
  /** Native app URL param. Corresponds to _reverb_app query param needed for internal navigation. */
  reverbApp?: InputMaybe<Scalars["String"]["input"]>;
};

export type Input_core_apimessages_CheckoutActionResponse = {
  action?: InputMaybe<Input_core_apimessages_CheckoutAction>;
};

export type Input_core_apimessages_CheckoutAffirmDetails = {
  financingProgramSlug?: InputMaybe<Scalars["String"]["input"]>;
};

export type Input_core_apimessages_CheckoutApplePayDetails = {
  /** Brand types provided by Adyen such as visa, mc, jcb */
  brands?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  /** Apple Pay Merchant ID for payment sheet configuration */
  merchantId?: InputMaybe<Scalars["String"]["input"]>;
  /** Merchant name to display in the payment sheet */
  merchantName?: InputMaybe<Scalars["String"]["input"]>;
};

export type Input_core_apimessages_CheckoutApplePayDetailsRequest = {
  currency?: InputMaybe<Scalars["String"]["input"]>;
};

export type Input_core_apimessages_CheckoutApplePayDetailsResponse = {
  details?: InputMaybe<Input_core_apimessages_CheckoutApplePayDetails>;
};

export type Input_core_apimessages_CheckoutCartEditRequest = {
  listingId?: InputMaybe<Scalars["String"]["input"]>;
  /** Adds a listing by the given quantity if 1 or greater. Removes the listing from the user's cart if zero. */
  quantity?: InputMaybe<Scalars["Int"]["input"]>;
};

export type Input_core_apimessages_CheckoutCartEditResponse = {
  /** If an item was not removed, returns the added item */
  cartItem?: InputMaybe<Input_core_apimessages_CartItem>;
  listingId?: InputMaybe<Scalars["String"]["input"]>;
};

export type Input_core_apimessages_CheckoutChallengeShopperRequest = {
  channelBrandingToken?: InputMaybe<Scalars["String"]["input"]>;
  checkoutId?: InputMaybe<Scalars["String"]["input"]>;
  checkoutUuid?: InputMaybe<Scalars["String"]["input"]>;
  results?: InputMaybe<Input_core_apimessages_AdyenChallengeShopperResults>;
  totalPrice?: InputMaybe<Input_core_apimessages_Money>;
};

export type Input_core_apimessages_CheckoutChallengeShopperResponse = {
  adyenPaymentResult?: InputMaybe<Input_core_apimessages_AdyenPaymentResult>;
  checkoutId?: InputMaybe<Scalars["String"]["input"]>;
  checkoutUuid?: InputMaybe<Scalars["String"]["input"]>;
  orderBundleId?: InputMaybe<Scalars["String"]["input"]>;
  paymentResultUrl?: InputMaybe<Scalars["String"]["input"]>;
};

export type Input_core_apimessages_CheckoutCreatePaymentRedirectUrlRequest = {
  accertifyDeviceInfo?: InputMaybe<Input_core_apimessages_AccertifyDeviceInfo>;
  /** Checkout UUID */
  id?: InputMaybe<Scalars["String"]["input"]>;
  reverbApp?: InputMaybe<Scalars["String"]["input"]>;
};

export type Input_core_apimessages_CheckoutCreatePaymentRedirectUrlResponse = {
  /** Checkout UUID */
  id?: InputMaybe<Scalars["String"]["input"]>;
  /** Redirect url for payment method */
  redirectUrl?: InputMaybe<Scalars["String"]["input"]>;
};

export type Input_core_apimessages_CheckoutFinalizeDigitalWalletPaymentRequest =
  {
    accertifyDeviceInfo?: InputMaybe<Input_core_apimessages_AccertifyDeviceInfo>;
    guestUser?: InputMaybe<Input_core_apimessages_GuestCheckoutUserEntry>;
    /** Checkout UUID */
    id?: InputMaybe<Scalars["String"]["input"]>;
    paymentToken?: InputMaybe<Scalars["String"]["input"]>;
    resultConfirmation?: InputMaybe<core_apimessages_CheckoutFinalizeDigitalWalletPaymentRequest_ResultConfirmation>;
    shippingAddress?: InputMaybe<Input_core_apimessages_AddressEntry>;
    totalPrice?: InputMaybe<Input_core_apimessages_MoneyMinorUnitsEntry>;
  };

export type Input_core_apimessages_CheckoutFinalizeDigitalWalletPaymentResponse =
  {
    /** Checkout UUID */
    id?: InputMaybe<Scalars["String"]["input"]>;
  };

export type Input_core_apimessages_CheckoutFinalizeRedirectPaymentCheckoutRequest =
  {
    displayedAmountOwedCents?: InputMaybe<Scalars["String"]["input"]>;
    /** Checkout UUID */
    id?: InputMaybe<Scalars["String"]["input"]>;
    merchantReference?: InputMaybe<Scalars["String"]["input"]>;
    redirectResult?: InputMaybe<Scalars["String"]["input"]>;
  };

export type Input_core_apimessages_CheckoutFinalizeRedirectPaymentCheckoutResponse =
  {
    /** Checkout UUID */
    id?: InputMaybe<Scalars["String"]["input"]>;
  };

export type Input_core_apimessages_CheckoutGooglePayDetails = {
  /** Card networks supported by both Reverb and Google Pay */
  cardNetworks?: InputMaybe<
    Array<InputMaybe<core_apimessages_CheckoutGooglePayDetails_CardNetwork>>
  >;
  /** Reverb Adyen Merchant ID for the transaction */
  gatewayMerchantId?: InputMaybe<Scalars["String"]["input"]>;
  /** Google Pay Merchant ID */
  merchantId?: InputMaybe<Scalars["String"]["input"]>;
  /** Merchant name to display in the payment sheet */
  merchantName?: InputMaybe<Scalars["String"]["input"]>;
};

export type Input_core_apimessages_CheckoutGuest = {
  email?: InputMaybe<Scalars["String"]["input"]>;
  firstName?: InputMaybe<Scalars["String"]["input"]>;
  isComplete?: InputMaybe<Scalars["Boolean"]["input"]>;
  lastName?: InputMaybe<Scalars["String"]["input"]>;
};

export type Input_core_apimessages_CheckoutIdentifyShopperRequest = {
  channelBrandingToken?: InputMaybe<Scalars["String"]["input"]>;
  checkoutId?: InputMaybe<Scalars["String"]["input"]>;
  checkoutUuid?: InputMaybe<Scalars["String"]["input"]>;
  results?: InputMaybe<Input_core_apimessages_AdyenIdentifyShopperResults>;
  totalPrice?: InputMaybe<Input_core_apimessages_Money>;
};

export type Input_core_apimessages_CheckoutIdentifyShopperResponse = {
  adyenPaymentResult?: InputMaybe<Input_core_apimessages_AdyenPaymentResult>;
  checkoutId?: InputMaybe<Scalars["String"]["input"]>;
  checkoutUuid?: InputMaybe<Scalars["String"]["input"]>;
  orderBundleId?: InputMaybe<Scalars["String"]["input"]>;
  paymentResultUrl?: InputMaybe<Scalars["String"]["input"]>;
};

export type Input_core_apimessages_CheckoutLineItem = {
  amount?: InputMaybe<Input_core_apimessages_Money>;
  label?: InputMaybe<Scalars["String"]["input"]>;
  type?: InputMaybe<core_apimessages_CheckoutLineItem_Type>;
};

export type Input_core_apimessages_CheckoutOrder = {
  availableShippingMethods?: InputMaybe<
    Array<InputMaybe<Input_core_apimessages_CheckoutShippingMethod>>
  >;
  buyerCanChangeQuantity?: InputMaybe<Scalars["Boolean"]["input"]>;
  cannotBeShippedToAddress?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Order ID. Present if order is unpaid */
  id?: InputMaybe<Scalars["String"]["input"]>;
  /** Listing ID */
  listingId?: InputMaybe<Scalars["String"]["input"]>;
  notes?: InputMaybe<
    Array<InputMaybe<Input_core_apimessages_CheckoutOrder_Note>>
  >;
  protectionPlan?: InputMaybe<Input_core_apimessages_ProtectionPlan>;
  quantity?: InputMaybe<Scalars["Int"]["input"]>;
  shippingMethod?: InputMaybe<Input_core_apimessages_CheckoutShippingMethod>;
  shippingTotal?: InputMaybe<Input_core_apimessages_CheckoutOrder_ShippingTotal>;
  subtotal?: InputMaybe<Input_core_apimessages_CheckoutOrder_Subtotal>;
  taxIncluded?: InputMaybe<Scalars["Boolean"]["input"]>;
  taxLabel?: InputMaybe<Scalars["String"]["input"]>;
};

export type Input_core_apimessages_CheckoutOrder_Note = {
  severity?: InputMaybe<core_apimessages_CheckoutOrder_Note_Severity>;
  text?: InputMaybe<Scalars["String"]["input"]>;
};

export type Input_core_apimessages_CheckoutOrder_ShippingTotal = {
  /** Shipping amount owed by buyer for order including discounts if any. */
  amountOwed?: InputMaybe<Input_core_apimessages_Money>;
  discount?: InputMaybe<Input_core_apimessages_CheckoutOrder_ShippingTotalDiscount>;
  /** True if amount owed is less than original amount owed. */
  discounted?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Original shipping amount owed for order before any discounts. */
  originalAmountOwed?: InputMaybe<Input_core_apimessages_Money>;
};

export type Input_core_apimessages_CheckoutOrder_ShippingTotalDiscount = {
  label?: InputMaybe<Scalars["String"]["input"]>;
  type?: InputMaybe<core_apimessages_CheckoutOrder_ShippingTotalDiscount_Type>;
};

export type Input_core_apimessages_CheckoutOrder_Subtotal = {
  discount?: InputMaybe<Input_core_apimessages_CheckoutOrder_SubtotalDiscount>;
  /** True if order listing price is less than original listing price. */
  discounted?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Unit amount owed by the buyer for an order listing. Includes tax and conditional discounts like offer price. */
  listingPrice?: InputMaybe<Input_core_apimessages_Money>;
};

export type Input_core_apimessages_CheckoutOrder_SubtotalDiscount = {
  label?: InputMaybe<Scalars["String"]["input"]>;
  type?: InputMaybe<core_apimessages_CheckoutOrder_SubtotalDiscount_Type>;
};

export type Input_core_apimessages_CheckoutPaymentMethod = {
  affirm?: InputMaybe<Input_core_apimessages_CheckoutAffirmDetails>;
  applePay?: InputMaybe<Input_core_apimessages_CheckoutApplePayDetails>;
  googlePay?: InputMaybe<Input_core_apimessages_CheckoutGooglePayDetails>;
  isComplete?: InputMaybe<Scalars["Boolean"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  paypal?: InputMaybe<Input_core_apimessages_CheckoutPaypalDetails>;
  /** True if discount codes are supported */
  supportsDiscountCodes?: InputMaybe<Scalars["Boolean"]["input"]>;
  type?: InputMaybe<core_apimessages_CheckoutPaymentMethod_Type>;
};

export type Input_core_apimessages_CheckoutPaymentResponse = {
  adyenPaymentResult?: InputMaybe<Input_core_apimessages_AdyenPaymentResult>;
  checkoutId?: InputMaybe<Scalars["String"]["input"]>;
  checkoutUuid?: InputMaybe<Scalars["String"]["input"]>;
  orderBundleId?: InputMaybe<Scalars["String"]["input"]>;
  paymentResultUrl?: InputMaybe<Scalars["String"]["input"]>;
};

export type Input_core_apimessages_CheckoutPaypalDetails = {
  clientId?: InputMaybe<Scalars["String"]["input"]>;
  clientToken?: InputMaybe<Scalars["String"]["input"]>;
  currency?: InputMaybe<Scalars["String"]["input"]>;
  merchantId?: InputMaybe<Scalars["String"]["input"]>;
};

export type Input_core_apimessages_CheckoutSelectOrderShippingMethodRequest = {
  id?: InputMaybe<Scalars["String"]["input"]>;
  order?: InputMaybe<Input_core_apimessages_CheckoutSelectOrderShippingMethodRequest_OrderEntry>;
};

export type Input_core_apimessages_CheckoutSelectOrderShippingMethodRequest_OrderEntry =
  {
    listingId?: InputMaybe<Scalars["String"]["input"]>;
    shippingMethodType?: InputMaybe<core_apimessages_ShippingMethod>;
  };

export type Input_core_apimessages_CheckoutSelectOrderShippingMethodResponse = {
  id?: InputMaybe<Scalars["String"]["input"]>;
};

export type Input_core_apimessages_CheckoutSelectShippingAddressRequest = {
  /** Checkout UUID */
  id?: InputMaybe<Scalars["String"]["input"]>;
  /** addresses.uuid */
  shippingAddressId?: InputMaybe<Scalars["String"]["input"]>;
};

export type Input_core_apimessages_CheckoutSelectShippingAddressResponse = {
  /** Checkout UUID */
  id?: InputMaybe<Scalars["String"]["input"]>;
};

export type Input_core_apimessages_CheckoutShippingMethod = {
  amount?: InputMaybe<Input_core_apimessages_Money>;
  description?: InputMaybe<Scalars["String"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  type?: InputMaybe<core_apimessages_ShippingMethod>;
};

export type Input_core_apimessages_CheckoutShowRequest = {
  /** Checkout UUID */
  id?: InputMaybe<Scalars["String"]["input"]>;
};

export type Input_core_apimessages_CheckoutShowResponse = {
  checkout?: InputMaybe<Input_core_apimessages_Checkout>;
};

export type Input_core_apimessages_CheckoutUpdateOrderQuantityRequest = {
  id?: InputMaybe<Scalars["String"]["input"]>;
  order?: InputMaybe<Input_core_apimessages_CheckoutUpdateOrderQuantityRequest_OrderEntry>;
};

export type Input_core_apimessages_CheckoutUpdateOrderQuantityRequest_OrderEntry =
  {
    listingId?: InputMaybe<Scalars["String"]["input"]>;
    quantity?: InputMaybe<Scalars["Int"]["input"]>;
  };

export type Input_core_apimessages_CheckoutUpdateOrderQuantityResponse = {
  id?: InputMaybe<Scalars["String"]["input"]>;
};

export type Input_core_apimessages_CheckoutUpdateShippingAddressRequest = {
  /** Information required only for guest checkout. */
  guest?: InputMaybe<Input_core_apimessages_GuestCheckoutUserEntry>;
  /** Checkout UUID */
  id?: InputMaybe<Scalars["String"]["input"]>;
  shippingAddress?: InputMaybe<Input_core_apimessages_AddressEntry>;
};

export type Input_core_apimessages_CheckoutUpdateShippingAddressResponse = {
  /** Checkout UUID */
  id?: InputMaybe<Scalars["String"]["input"]>;
};

export type Input_core_apimessages_CheckoutUpdateShippingEstimateRequest = {
  id?: InputMaybe<Scalars["String"]["input"]>;
  shippingAddress?: InputMaybe<Input_core_apimessages_AddressEntry>;
};

export type Input_core_apimessages_CheckoutUpdateShippingEstimateResponse = {
  id?: InputMaybe<Scalars["String"]["input"]>;
};

export type Input_core_apimessages_CheckoutVerifyShopperRequest = {
  checkoutId?: InputMaybe<Scalars["String"]["input"]>;
  checkoutUuid?: InputMaybe<Scalars["String"]["input"]>;
  results?: InputMaybe<Input_core_apimessages_AdyenVerifyShopperResults>;
  totalPrice?: InputMaybe<Input_core_apimessages_Money>;
};

export type Input_core_apimessages_CheckoutVerifyShopperResponse = {
  adyenPaymentResult?: InputMaybe<Input_core_apimessages_AdyenPaymentResult>;
  checkoutId?: InputMaybe<Scalars["String"]["input"]>;
  checkoutUuid?: InputMaybe<Scalars["String"]["input"]>;
  orderBundleId?: InputMaybe<Scalars["String"]["input"]>;
  paymentResultUrl?: InputMaybe<Scalars["String"]["input"]>;
};

export type Input_core_apimessages_Checkout_Total = {
  amount?: InputMaybe<Input_core_apimessages_Money>;
  label?: InputMaybe<Scalars["String"]["input"]>;
};

export type Input_core_apimessages_CollectionHeader = {
  collectionId?: InputMaybe<Scalars["String"]["input"]>;
  collectionType?: InputMaybe<core_apimessages_CollectionHeader_CollectionType>;
  description?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  metaDescription?: InputMaybe<Scalars["String"]["input"]>;
  metaTitle?: InputMaybe<Scalars["String"]["input"]>;
  title?: InputMaybe<Scalars["String"]["input"]>;
};

export type Input_core_apimessages_CollectionHeaderRequest = {
  /** for category, `productType:category` compound key */
  collectionSlug?: InputMaybe<Scalars["String"]["input"]>;
  collectionType?: InputMaybe<core_apimessages_CollectionHeader_CollectionType>;
};

export type Input_core_apimessages_CollectionHeaderResponse = {
  collectionHeader?: InputMaybe<Input_core_apimessages_CollectionHeader>;
};

export type Input_core_apimessages_CompleteExpressSaleCheckoutRequest = {
  checkoutId?: InputMaybe<Scalars["String"]["input"]>;
};

export type Input_core_apimessages_CompleteExpressSaleCheckoutResponse = {
  orderId?: InputMaybe<Scalars["String"]["input"]>;
};

export type Input_core_apimessages_Condition = {
  conditionSlug?: InputMaybe<Scalars["String"]["input"]>;
  conditionUuid?: InputMaybe<Scalars["String"]["input"]>;
  description?: InputMaybe<Scalars["String"]["input"]>;
  displayName?: InputMaybe<Scalars["String"]["input"]>;
};

export type Input_core_apimessages_ContentSponsorship = {
  partnershipName?: InputMaybe<Scalars["String"]["input"]>;
  sponsorshipType?: InputMaybe<core_apimessages_ContentSponsorship_SponsorshipType>;
};

export type Input_core_apimessages_ConversationFlagMessageRequest = {
  messageId?: InputMaybe<Scalars["String"]["input"]>;
  reasonType?: InputMaybe<core_apimessages_ConversationMessageFlag_Reason_Type>;
};

export type Input_core_apimessages_ConversationFlagMessageResponse = {
  /** Created Message Flag ID */
  id?: InputMaybe<Scalars["String"]["input"]>;
};

export type Input_core_apimessages_ConversationMessage = {
  conversationId?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
};

export type Input_core_apimessages_ConversationMessageFlag = {
  id?: InputMaybe<Scalars["String"]["input"]>;
};

export type Input_core_apimessages_ConversationMessageFlag_Reason = {
  _?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type Input_core_apimessages_ConvertedMoney = {
  display?: InputMaybe<Input_core_apimessages_Money>;
  original?: InputMaybe<Input_core_apimessages_Money>;
};

export type Input_core_apimessages_CopyAdGroupRequest = {
  adGroupUuid?: InputMaybe<Scalars["String"]["input"]>;
};

export type Input_core_apimessages_CopyAdGroupResponse = {
  adGroup?: InputMaybe<Input_core_apimessages_AdGroup>;
};

export type Input_core_apimessages_CountriesResponse = {
  countries?: InputMaybe<Array<InputMaybe<Input_core_apimessages_Country>>>;
};

export type Input_core_apimessages_Country = {
  countryCode?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  subregionRequired?: InputMaybe<Scalars["Boolean"]["input"]>;
  subregions?: InputMaybe<Array<InputMaybe<Input_core_apimessages_Subregion>>>;
  superRegion?: InputMaybe<Input_core_apimessages_SuperRegion>;
};

export type Input_core_apimessages_CountsLinks = {
  self?: InputMaybe<Input_core_apimessages_Link>;
};

export type Input_core_apimessages_CountsResponse = {
  Links?: InputMaybe<Input_core_apimessages_CountsLinks>;
  activeCartItemTotal?: InputMaybe<Input_core_apimessages_UserBadge>;
  activeOffers?: InputMaybe<Input_core_apimessages_UserBadge>;
  billingProfile?: InputMaybe<Input_core_apimessages_UserBadge>;
  buyerActiveOffers?: InputMaybe<Input_core_apimessages_UserBadge>;
  buyerOrdersAwaitingPickup?: InputMaybe<Input_core_apimessages_UserBadge>;
  countsLinks?: InputMaybe<Input_core_apimessages_CountsLinks>;
  invoicedStatementCount?: InputMaybe<Input_core_apimessages_UserBadge>;
  layawayOrders?: InputMaybe<Input_core_apimessages_UserBadge>;
  outstandingFeedback?: InputMaybe<Input_core_apimessages_UserBadge>;
  sellerActiveOffers?: InputMaybe<Input_core_apimessages_UserBadge>;
  sellerOrdersAwaitingPickup?: InputMaybe<Input_core_apimessages_UserBadge>;
  sellerOrdersAwaitingShipment?: InputMaybe<Input_core_apimessages_UserBadge>;
  sellerOrdersRefundRequested?: InputMaybe<Input_core_apimessages_UserBadge>;
  total?: InputMaybe<Input_core_apimessages_CountsTotal>;
  unpaidOrders?: InputMaybe<Input_core_apimessages_UserBadge>;
  unreadMessages?: InputMaybe<Input_core_apimessages_UserBadge>;
  userNotifications?: InputMaybe<
    Array<InputMaybe<Input_core_apimessages_UserNotification>>
  >;
};

export type Input_core_apimessages_CountsTotal = {
  count?: InputMaybe<Scalars["Int"]["input"]>;
};

export type Input_core_apimessages_CreateAutoOfferRequest = {
  instantOffersEnabled?: InputMaybe<Scalars["Boolean"]["input"]>;
  listingId?: InputMaybe<Scalars["String"]["input"]>;
  message?: InputMaybe<Scalars["String"]["input"]>;
  percentage?: InputMaybe<Scalars["Int"]["input"]>;
  price?: InputMaybe<Input_core_apimessages_Money>;
  pushToExisting?: InputMaybe<Scalars["Boolean"]["input"]>;
  shippingPriceOverride?: InputMaybe<Input_core_apimessages_Money>;
};

export type Input_core_apimessages_CreateAutoOfferResponse = {
  autoOffer?: InputMaybe<Input_core_apimessages_AutoOffer>;
};

export type Input_core_apimessages_CreateCheckoutPaypalOrderRequest = {
  /** checkout UUID */
  id?: InputMaybe<Scalars["String"]["input"]>;
  noShipping?: InputMaybe<Scalars["Boolean"]["input"]>;
  reverbApp?: InputMaybe<Scalars["String"]["input"]>;
};

export type Input_core_apimessages_CreateCheckoutPaypalOrderResponse = {
  /** checkout UUID */
  id?: InputMaybe<Scalars["String"]["input"]>;
  paypalOrderId?: InputMaybe<Scalars["String"]["input"]>;
  redirectUrl?: InputMaybe<Scalars["String"]["input"]>;
};

export type Input_core_apimessages_CreateExpressSaleCheckoutRequest = {
  address?: InputMaybe<Input_core_apimessages_AddressEntry>;
  addressUuid?: InputMaybe<Scalars["String"]["input"]>;
  checkoutId?: InputMaybe<Scalars["String"]["input"]>;
  isTermsAccepted?: InputMaybe<Scalars["Boolean"]["input"]>;
  payoutAmountCents?: InputMaybe<Scalars["Int"]["input"]>;
};

export type Input_core_apimessages_CreateExpressSaleCheckoutResponse = {
  checkoutId?: InputMaybe<Scalars["String"]["input"]>;
};

export type Input_core_apimessages_CreateMyActionRequest = {
  name?: InputMaybe<core_apimessages_UserActionName>;
};

export type Input_core_apimessages_CreateMyActionResponse = {
  action?: InputMaybe<Input_core_apimessages_AvailableAction>;
  errors?: InputMaybe<Array<InputMaybe<Input_core_apimessages_Error>>>;
};

export type Input_core_apimessages_CreateMyCreditCardRequest = {
  billingAddress?: InputMaybe<Input_core_apimessages_AddressEntry>;
  creditCard?: InputMaybe<Input_core_apimessages_CreateMyCreditCardRequest_CreditCardEntry>;
};

export type Input_core_apimessages_CreateMyCreditCardRequest_CreditCardEntry = {
  cardholderName?: InputMaybe<Scalars["String"]["input"]>;
  tokenizedFields?: InputMaybe<Input_core_apimessages_CreditCardTokenizedFields>;
};

export type Input_core_apimessages_CreateMyCreditCardResponse = {
  creditCard?: InputMaybe<Input_core_apimessages_CreditCard>;
};

export type Input_core_apimessages_CreateMyFeedbackMessageRequest = {
  message?: InputMaybe<Scalars["String"]["input"]>;
};

export type Input_core_apimessages_CreateMyFeedbackMessageResponse = {
  id?: InputMaybe<Scalars["String"]["input"]>;
};

export type Input_core_apimessages_CreateMyPlaidLinkTokenRequest = {
  countryCode?: InputMaybe<Scalars["String"]["input"]>;
  plaidAccountId?: InputMaybe<Scalars["String"]["input"]>;
  requiresMobileRedirect?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type Input_core_apimessages_CreateMyPlaidLinkTokenResponse = {
  linkToken?: InputMaybe<Scalars["String"]["input"]>;
};

export type Input_core_apimessages_CreateMySaleMembershipRequest = {
  listingId?: InputMaybe<Scalars["String"]["input"]>;
  saleId?: InputMaybe<Scalars["String"]["input"]>;
};

export type Input_core_apimessages_CreateMySaleMembershipResponse = {
  saleMembership?: InputMaybe<Input_core_apimessages_ListingSaleMembership>;
};

export type Input_core_apimessages_CreateNegotiationRequest = {
  bumpKey?: InputMaybe<Input_core_apimessages_BumpKey>;
  /** optional, to copy message/photos to relevant conversation if offer is included is messages */
  conversation?: InputMaybe<Input_core_apimessages_CreateNegotiationRequest_ConversationData>;
  countryCode?: InputMaybe<Scalars["String"]["input"]>;
  creditCardId?: InputMaybe<Scalars["String"]["input"]>;
  message?: InputMaybe<Scalars["String"]["input"]>;
  offerItems?: InputMaybe<
    Array<InputMaybe<Input_core_apimessages_OfferItemRequest>>
  >;
  postalCode?: InputMaybe<Scalars["String"]["input"]>;
  recipientUuid?: InputMaybe<Scalars["String"]["input"]>;
  regionCode?: InputMaybe<Scalars["String"]["input"]>;
  shippingAddressUuid?: InputMaybe<Scalars["String"]["input"]>;
  /** country code OR subregion (eg continental US) */
  shippingRegionCode?: InputMaybe<Scalars["String"]["input"]>;
};

export type Input_core_apimessages_CreateNegotiationRequest_ConversationData = {
  listingId?: InputMaybe<Scalars["String"]["input"]>;
  photos?: InputMaybe<Array<InputMaybe<Input_core_apimessages_ImageEntry>>>;
};

export type Input_core_apimessages_CreateOrUpdateUserShopPermissionResponse = {
  userShopPermission?: InputMaybe<Input_core_apimessages_UserShopPermission>;
};

export type Input_core_apimessages_CreateOrderNoteRequest = {
  body?: InputMaybe<Scalars["String"]["input"]>;
  /** orders.id */
  orderId?: InputMaybe<Scalars["String"]["input"]>;
};

export type Input_core_apimessages_CreateOrderNoteResponse = {
  errors?: InputMaybe<Array<InputMaybe<Input_core_apimessages_Error>>>;
  orderNote?: InputMaybe<Input_core_apimessages_OrderNote>;
};

export type Input_core_apimessages_CreatePacklinkDraftRequest = {
  orderId?: InputMaybe<Scalars["String"]["input"]>;
};

export type Input_core_apimessages_CreatePacklinkDraftResponse = {
  shipment?: InputMaybe<Input_core_apimessages_PacklinkShipment>;
};

export type Input_core_apimessages_CreateRecentSearchRequest = {
  query?: InputMaybe<Scalars["String"]["input"]>;
};

export type Input_core_apimessages_CreateRecentSearchResponse = {
  query?: InputMaybe<Scalars["String"]["input"]>;
};

export type Input_core_apimessages_CreateShipmentError = {
  errorType?: InputMaybe<core_apimessages_CreateShipmentErrorType>;
  message?: InputMaybe<Scalars["String"]["input"]>;
};

export type Input_core_apimessages_CreateShipmentRequest = {
  customs?: InputMaybe<Input_core_apimessages_CustomsInfo>;
  fromAddress?: InputMaybe<Input_core_apimessages_Address>;
  /** shipments.uuid */
  id?: InputMaybe<Scalars["String"]["input"]>;
  mediaMailQualified?: InputMaybe<Scalars["Boolean"]["input"]>;
  modifiedCarrierCalculatedShipment?: InputMaybe<Scalars["Boolean"]["input"]>;
  orderUuids?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  package?: InputMaybe<Input_core_apimessages_ShippingLabelPackage>;
  shipmentType?: InputMaybe<core_apimessages_Shipment_ShipmentType>;
  shippingProtection?: InputMaybe<Scalars["Boolean"]["input"]>;
  shippingProtectionPrice?: InputMaybe<Input_core_apimessages_Money>;
  signatureRequired?: InputMaybe<Scalars["Boolean"]["input"]>;
  toAddress?: InputMaybe<Input_core_apimessages_Address>;
  toAddressIsResidential?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type Input_core_apimessages_CreateShipmentResponse = {
  errors?: InputMaybe<
    Array<InputMaybe<Input_core_apimessages_CreateShipmentError>>
  >;
  shipment?: InputMaybe<Input_core_apimessages_Shipment>;
};

export type Input_core_apimessages_CreateTaxIdentificationRequest = {
  countryCode?: InputMaybe<Scalars["String"]["input"]>;
  taxId?: InputMaybe<Scalars["String"]["input"]>;
  taxType?: InputMaybe<Scalars["String"]["input"]>;
};

export type Input_core_apimessages_CreateTaxIdentificationResponse = {
  shopId?: InputMaybe<Scalars["String"]["input"]>;
  taxIdentification?: InputMaybe<Input_core_apimessages_TaxIdentification>;
};

export type Input_core_apimessages_CreateTruliooOnboardingRecordResponse = {
  errors?: InputMaybe<Array<InputMaybe<Input_core_apimessages_Error>>>;
  truliooOnboardingRecord?: InputMaybe<Input_core_apimessages_TruliooOnboardingRecord>;
};

export type Input_core_apimessages_CreateUserShopPermissionRequest = {
  email?: InputMaybe<Scalars["String"]["input"]>;
  permissionLevel?: InputMaybe<core_apimessages_UserShopPermission_PermissionLevel>;
};

export type Input_core_apimessages_CreditCard = {
  address?: InputMaybe<Input_core_apimessages_Address>;
  brand?: InputMaybe<Input_core_apimessages_CreditCard_Brand>;
  cardBin?: InputMaybe<Scalars["String"]["input"]>;
  cardSource?: InputMaybe<Scalars["String"]["input"]>;
  /** Use the brand field instead */
  cardType?: InputMaybe<Scalars["String"]["input"]>;
  cardholderName?: InputMaybe<Scalars["String"]["input"]>;
  display?: InputMaybe<Input_core_apimessages_CreditCard_Display>;
  expirationMonth?: InputMaybe<Scalars["String"]["input"]>;
  expirationYear?: InputMaybe<Scalars["String"]["input"]>;
  /** credit_cards.id */
  id?: InputMaybe<Scalars["String"]["input"]>;
  last4?: InputMaybe<Scalars["String"]["input"]>;
  needsReverification?: InputMaybe<Scalars["Boolean"]["input"]>;
  primaryBilling?: InputMaybe<Scalars["Boolean"]["input"]>;
  primaryForCheckout?: InputMaybe<Scalars["Boolean"]["input"]>;
};

/**
 *   *
 * Fields generated by Adyen's secured fields library.
 * Each key is the name of an individual credit card field (ex: "encryptedExpiryYear"),
 * and each value is an encrypted string.
 * Adyen decrypts each field when it reaches their server.
 */
export type Input_core_apimessages_CreditCardTokenizedFields = {
  encryptedCardNumber?: InputMaybe<Scalars["String"]["input"]>;
  encryptedExpiryMonth?: InputMaybe<Scalars["String"]["input"]>;
  encryptedExpiryYear?: InputMaybe<Scalars["String"]["input"]>;
  encryptedSecurityCode?: InputMaybe<Scalars["String"]["input"]>;
};

export type Input_core_apimessages_CreditCard_Brand = {
  /** Readable brand name of type. Examples: AMEX is "American Express," MASTERCARD is "MasterCard" */
  name?: InputMaybe<Scalars["String"]["input"]>;
  type?: InputMaybe<core_apimessages_CreditCard_Brand_Type>;
};

export type Input_core_apimessages_CreditCard_Display = {
  expirationDate?: InputMaybe<Scalars["String"]["input"]>;
  /** Localized card summary. Example: "Visa ending in 1111" */
  summary?: InputMaybe<Scalars["String"]["input"]>;
};

export type Input_core_apimessages_CrossPlatformListingStatusRequest = {
  listingId?: InputMaybe<Scalars["String"]["input"]>;
};

export type Input_core_apimessages_CrossPlatformListingStatusResponse = {
  status?: InputMaybe<core_apimessages_CrossPlatformListingStatusResponse_SessionStatus>;
  ttlSeconds?: InputMaybe<Scalars["Int"]["input"]>;
};

export type Input_core_apimessages_CspRowQuery = {
  brandSlugs?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  categoryUuids?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  cspSlugs?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  rowTitle?: InputMaybe<Scalars["String"]["input"]>;
  sort?: InputMaybe<Scalars["String"]["input"]>;
};

export type Input_core_apimessages_CuratedSet = {
  /** curated_sets.id */
  id?: InputMaybe<Scalars["String"]["input"]>;
  setType?: InputMaybe<Scalars["String"]["input"]>;
  slug?: InputMaybe<Scalars["String"]["input"]>;
  title?: InputMaybe<Scalars["String"]["input"]>;
  webLink?: InputMaybe<Input_core_apimessages_Link>;
};

export type Input_core_apimessages_CuratedSetFeatureRequest = {
  /** curated_sets.id */
  id?: InputMaybe<Scalars["String"]["input"]>;
  /** products.id */
  productId?: InputMaybe<Scalars["String"]["input"]>;
};

export type Input_core_apimessages_CuratedSetMembership = {
  curatedSetId?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  productId?: InputMaybe<Scalars["String"]["input"]>;
};

export type Input_core_apimessages_CuratedSetsForListing = {
  curatedSets?: InputMaybe<
    Array<InputMaybe<Input_core_apimessages_ListingCuratedSet>>
  >;
  /** products.id */
  id?: InputMaybe<Scalars["String"]["input"]>;
};

export type Input_core_apimessages_CuratedSetsRequest = {
  /** curated_sets.id */
  ids?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  slugs?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
};

export type Input_core_apimessages_CuratedSetsResponse = {
  curatedSets?: InputMaybe<
    Array<InputMaybe<Input_core_apimessages_CuratedSet>>
  >;
};

export type Input_core_apimessages_CustomsInfo = {
  certifiedAccuracy?: InputMaybe<Scalars["Boolean"]["input"]>;
  orders?: InputMaybe<Array<InputMaybe<Input_core_apimessages_OrderCustoms>>>;
  signer?: InputMaybe<Scalars["String"]["input"]>;
};

export type Input_core_apimessages_DatadogError = {
  content?: InputMaybe<Scalars["String"]["input"]>;
  context?: InputMaybe<Scalars["String"]["input"]>;
  key?: InputMaybe<Scalars["String"]["input"]>;
  timestamp?: InputMaybe<Scalars["String"]["input"]>;
  type?: InputMaybe<Scalars["String"]["input"]>;
};

export type Input_core_apimessages_DeactivateTaxIdentificationRequest = {
  id?: InputMaybe<Scalars["String"]["input"]>;
};

export type Input_core_apimessages_DeactivateTaxIdentificationResponse = {
  shopId?: InputMaybe<Scalars["String"]["input"]>;
  taxIdentification?: InputMaybe<Input_core_apimessages_TaxIdentification>;
};

export type Input_core_apimessages_DeclineFeedbackRequest = {
  orderUuid?: InputMaybe<Scalars["String"]["input"]>;
};

export type Input_core_apimessages_DeclineFeedbackResponse = {
  orderUuid?: InputMaybe<Scalars["String"]["input"]>;
};

export type Input_core_apimessages_DeleteAdRequest = {
  uuid?: InputMaybe<Scalars["String"]["input"]>;
};

export type Input_core_apimessages_DeleteAdResponse = {
  ad?: InputMaybe<Input_core_apimessages_Ad>;
};

export type Input_core_apimessages_DeleteAutoOfferRequest = {
  listingId?: InputMaybe<Scalars["String"]["input"]>;
};

export type Input_core_apimessages_DeleteAutoOfferResponse = {
  autoOffer?: InputMaybe<Input_core_apimessages_AutoOffer>;
};

export type Input_core_apimessages_DeleteCartItemProtectionPlanRequest = {
  cartItemUuid?: InputMaybe<Scalars["String"]["input"]>;
};

export type Input_core_apimessages_DeleteCartItemProtectionPlanResponse = {
  cartItemUuid?: InputMaybe<Scalars["String"]["input"]>;
};

export type Input_core_apimessages_DeleteGearCollectionItemRequest = {
  id?: InputMaybe<Scalars["String"]["input"]>;
};

export type Input_core_apimessages_DeleteGearCollectionItemResponse = {
  gearCollectionItem?: InputMaybe<Input_core_apimessages_GearCollectionItem>;
};

export type Input_core_apimessages_DeleteMyCreditCardRequest = {
  id?: InputMaybe<Scalars["String"]["input"]>;
};

export type Input_core_apimessages_DeleteMyCreditCardResponse = {
  /** Deleted credit card ID */
  id?: InputMaybe<Scalars["String"]["input"]>;
};

export type Input_core_apimessages_DeleteMyFavoriteRequest = {
  id?: InputMaybe<Scalars["String"]["input"]>;
};

export type Input_core_apimessages_DeleteMyFavoriteResponse = {
  errors?: InputMaybe<Array<InputMaybe<Input_core_apimessages_Error>>>;
  favorite?: InputMaybe<Input_core_apimessages_Favorite>;
};

export type Input_core_apimessages_DeleteMyFeedbackMessageRequest = {
  id?: InputMaybe<Scalars["String"]["input"]>;
};

export type Input_core_apimessages_DeleteMyFeedbackMessageResponse = {
  id?: InputMaybe<Scalars["String"]["input"]>;
};

export type Input_core_apimessages_DeleteMySaleMembershipRequest = {
  listingId?: InputMaybe<Scalars["String"]["input"]>;
  saleId?: InputMaybe<Scalars["String"]["input"]>;
};

export type Input_core_apimessages_DeleteMySaleMembershipResponse = {
  saleMembership?: InputMaybe<Input_core_apimessages_ListingSaleMembership>;
};

export type Input_core_apimessages_DeleteOrderNotesRequest = {
  orderNoteIds?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
};

export type Input_core_apimessages_DeleteRequest = {
  /** deprecated, use uuid */
  id?: InputMaybe<Scalars["String"]["input"]>;
  uuid?: InputMaybe<Scalars["String"]["input"]>;
};

export type Input_core_apimessages_DeleteResponse = {
  /** deprecated, use uuid */
  id?: InputMaybe<Scalars["String"]["input"]>;
  uuid?: InputMaybe<Scalars["String"]["input"]>;
};

export type Input_core_apimessages_DeleteUserShopPermissionRequest = {
  userId?: InputMaybe<Scalars["String"]["input"]>;
};

export type Input_core_apimessages_DeleteUserShopPermissionResponse = {
  id?: InputMaybe<Scalars["String"]["input"]>;
};

export type Input_core_apimessages_DelinkUserServiceRequest = {
  provider?: InputMaybe<core_apimessages_UserService_Provider>;
};

export type Input_core_apimessages_DelinkUserServiceResponse = {
  _?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type Input_core_apimessages_DigitalListingSendDownloadEmailRequest = {
  listingId?: InputMaybe<Scalars["String"]["input"]>;
  userId?: InputMaybe<Scalars["String"]["input"]>;
};

export type Input_core_apimessages_DigitalListingSendDownloadEmailResponse = {
  success?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type Input_core_apimessages_DraftListingDeleteRequest = {
  listingId?: InputMaybe<Scalars["String"]["input"]>;
};

export type Input_core_apimessages_DraftListingDeleteResponse = {
  listingId?: InputMaybe<Scalars["String"]["input"]>;
};

export type Input_core_apimessages_Error = {
  context?: InputMaybe<Scalars["String"]["input"]>;
  errorType?: InputMaybe<Scalars["String"]["input"]>;
  field?: InputMaybe<Scalars["String"]["input"]>;
  message?: InputMaybe<Scalars["String"]["input"]>;
};

export type Input_core_apimessages_ErrorResponse = {
  errors?: InputMaybe<Array<InputMaybe<Input_core_apimessages_Error>>>;
};

export type Input_core_apimessages_EstimateNegotiationSalesTaxRequest = {
  amountProduct?: InputMaybe<Input_core_apimessages_MoneyMinorUnitsEntry>;
  amountShipping?: InputMaybe<Input_core_apimessages_MoneyMinorUnitsEntry>;
  shippingAddressUuid?: InputMaybe<Scalars["String"]["input"]>;
};

export type Input_core_apimessages_EstimateNegotiationSalesTaxResponse = {
  amountTax?: InputMaybe<Input_core_apimessages_Money>;
};

export type Input_core_apimessages_EstimateShippingResponse = {
  estimatedShipping?: InputMaybe<Input_core_apimessages_EstimatedShipping>;
};

export type Input_core_apimessages_EstimatedMonthlyPayment = {
  availableTermMonths?: InputMaybe<Array<InputMaybe<Scalars["Int"]["input"]>>>;
  estimatedMonthlyPayment?: InputMaybe<Input_core_apimessages_Money>;
  financingProgramSlug?: InputMaybe<Scalars["String"]["input"]>;
  /** products.id */
  id?: InputMaybe<Scalars["String"]["input"]>;
  lowestAprAtMaxTerm?: InputMaybe<Scalars["Int"]["input"]>;
  maxTermMonth?: InputMaybe<Scalars["Int"]["input"]>;
  splitPay?: InputMaybe<Scalars["Boolean"]["input"]>;
  zeroPercentFinancingPlan?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type Input_core_apimessages_EstimatedMonthlyPaymentsRequest = {
  /** products.id */
  ids?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
};

export type Input_core_apimessages_EstimatedMonthlyPaymentsResponse = {
  estimatedMonthlyPayments?: InputMaybe<
    Array<InputMaybe<Input_core_apimessages_EstimatedMonthlyPayment>>
  >;
};

export type Input_core_apimessages_EstimatedNewListingPrice = {
  id?: InputMaybe<Scalars["String"]["input"]>;
  price?: InputMaybe<Input_core_apimessages_Money>;
};

export type Input_core_apimessages_EstimatedShipping = {
  description?: InputMaybe<Scalars["String"]["input"]>;
  rates?: InputMaybe<Array<InputMaybe<Input_core_apimessages_ShippingRate>>>;
};

export type Input_core_apimessages_EstimatedShippingRequest = {
  fromCountrycode?: InputMaybe<Scalars["String"]["input"]>;
  fromPostal?: InputMaybe<Scalars["String"]["input"]>;
  height?: InputMaybe<Scalars["Float"]["input"]>;
  length?: InputMaybe<Scalars["Float"]["input"]>;
  metric?: InputMaybe<Scalars["Boolean"]["input"]>;
  toCountrycode?: InputMaybe<Scalars["String"]["input"]>;
  weight?: InputMaybe<Scalars["Float"]["input"]>;
  width?: InputMaybe<Scalars["Float"]["input"]>;
};

export type Input_core_apimessages_ExpeditedShippingAvailableSignal = {
  isValid?: InputMaybe<Scalars["Boolean"]["input"]>;
  translations?: InputMaybe<Input_core_apimessages_SignalTranslation>;
};

export type Input_core_apimessages_ExperimentData = {
  group?: InputMaybe<Scalars["String"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
};

export type Input_core_apimessages_ExperimentsResponse = {
  experiments?: InputMaybe<Array<InputMaybe<Input_reverb_event_Experiment>>>;
};

export type Input_core_apimessages_ExportSecondaryUserActivitiesRequest = {
  _?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type Input_core_apimessages_ExportSecondaryUserActivitiesResponse = {
  enqueued?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type Input_core_apimessages_ExpressSaleCheckout = {
  checkoutId?: InputMaybe<Scalars["String"]["input"]>;
  cspId?: InputMaybe<Scalars["String"]["input"]>;
  expressSaleItemBid?: InputMaybe<Input_core_apimessages_CSPExpressSaleItemBid>;
  status?: InputMaybe<core_apimessages_ExpressSaleCheckout_Status>;
};

export type Input_core_apimessages_ExpressSaleCheckoutShowRequest = {
  checkoutId?: InputMaybe<Scalars["String"]["input"]>;
};

export type Input_core_apimessages_ExpressSaleCheckoutShowResponse = {
  expressSaleCheckout?: InputMaybe<Input_core_apimessages_ExpressSaleCheckout>;
};

/**
 *   Favorite represents a row in the follows table (a link between a user and a saved search), and is a newer version of the Follow message.
 * It can also represent a row from the user_watch_products table (e.g. { id: 1, type: LISTING, favorited: true })
 */
export type Input_core_apimessages_Favorite = {
  emailEnabled?: InputMaybe<Scalars["Boolean"]["input"]>;
  favoriteType?: InputMaybe<core_apimessages_FavoriteType>;
  favorited?: InputMaybe<Scalars["Boolean"]["input"]>;
  feedEnabled?: InputMaybe<Scalars["Boolean"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  link?: InputMaybe<Input_core_apimessages_Link>;
  queryParams?: InputMaybe<Scalars["String"]["input"]>;
  queryText?: InputMaybe<Scalars["String"]["input"]>;
  searchableId?: InputMaybe<Scalars["String"]["input"]>;
  searchableType?: InputMaybe<reverb_feed_SearchableType>;
  shortSubtitle?: InputMaybe<Scalars["String"]["input"]>;
  subtitle?: InputMaybe<Scalars["String"]["input"]>;
  title?: InputMaybe<Scalars["String"]["input"]>;
};

export type Input_core_apimessages_FeedCustomizationResponse = {
  articleFollows?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  followGroups?: InputMaybe<
    Array<InputMaybe<Input_core_apimessages_FollowGroup>>
  >;
  follows?: InputMaybe<Array<InputMaybe<Input_core_apimessages_Follow>>>;
};

/**
 *   A FeedResult represents one entity that can be displayed in a feed grid/row/etc for the current user.
 * Listings are the only entity to be supported initially, and other types can be added if needed.
 */
export type Input_core_apimessages_FeedResult = {
  entityId?: InputMaybe<Scalars["String"]["input"]>;
  entityType?: InputMaybe<core_apimessages_FeedResult_EntityType>;
  /**
   *   Source indicates whether the entity was returned because it directly matches
   * a saved search, or because it was a recommendation from a direct match, or another reason.
   */
  source?: InputMaybe<core_apimessages_FeedResult_Source>;
};

export type Input_core_apimessages_Feedback = {
  authorUuid?: InputMaybe<Scalars["String"]["input"]>;
  conditionSatisfactory?: InputMaybe<Input_google_protobuf_BoolValue>;
  createdAt?: InputMaybe<Input_core_apimessages_LegacyTimestamp>;
  listingLink?: InputMaybe<Input_core_apimessages_Link>;
  message?: InputMaybe<Scalars["String"]["input"]>;
  orderTitle?: InputMaybe<Scalars["String"]["input"]>;
  rating?: InputMaybe<Scalars["Int"]["input"]>;
  recipientUuid?: InputMaybe<Scalars["String"]["input"]>;
  response?: InputMaybe<Input_core_apimessages_Feedback_Response>;
  shippingSatisfactory?: InputMaybe<Input_google_protobuf_BoolValue>;
  type?: InputMaybe<core_apimessages_Feedback_Type>;
};

export type Input_core_apimessages_FeedbackMessage = {
  id?: InputMaybe<Scalars["String"]["input"]>;
  message?: InputMaybe<Scalars["String"]["input"]>;
};

export type Input_core_apimessages_FeedbackSummariesRequest = {
  ids?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  userIds?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  uuids?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
};

export type Input_core_apimessages_FeedbackSummariesResponse = {
  feedbackSummaries?: InputMaybe<
    Array<InputMaybe<Input_core_apimessages_FeedbackSummary>>
  >;
};

export type Input_core_apimessages_FeedbackSummary = {
  receivedCount?: InputMaybe<Scalars["Int"]["input"]>;
  rollingRatingPercentage?: InputMaybe<Scalars["Int"]["input"]>;
  /** users.id */
  userId?: InputMaybe<Scalars["String"]["input"]>;
};

export type Input_core_apimessages_Feedback_Response = {
  body?: InputMaybe<Scalars["String"]["input"]>;
  createdAt?: InputMaybe<Input_core_apimessages_LegacyTimestamp>;
};

/** Input type for feedback */
export type Input_core_apimessages_FeedbacksRequest = {
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  type?: InputMaybe<core_apimessages_Feedback_Type>;
  userUuid?: InputMaybe<Scalars["String"]["input"]>;
};

export type Input_core_apimessages_FeedbacksResponse = {
  buyerRating?: InputMaybe<Scalars["Float"]["input"]>;
  feedbacks?: InputMaybe<Array<InputMaybe<Input_core_apimessages_Feedback>>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  sellerRating?: InputMaybe<Scalars["Float"]["input"]>;
  total?: InputMaybe<Scalars["Int"]["input"]>;
};

export type Input_core_apimessages_FinalizeAffirmCheckoutPaymentRequest = {
  /** Affirm Checkout Token */
  checkoutToken?: InputMaybe<Scalars["String"]["input"]>;
  displayedAmountOwed?: InputMaybe<Input_core_apimessages_MoneyMinorUnitsEntry>;
  /** Checkout UUID */
  id?: InputMaybe<Scalars["String"]["input"]>;
  /** Native app URL param */
  reverbApp?: InputMaybe<Scalars["String"]["input"]>;
};

export type Input_core_apimessages_FinalizeAffirmCheckoutPaymentResponse = {
  /** Checkout UUID */
  id?: InputMaybe<Scalars["String"]["input"]>;
};

export type Input_core_apimessages_FinalizeCardCheckoutPaymentRequest = {
  accertifyDeviceInfo?: InputMaybe<Input_core_apimessages_AccertifyDeviceInfo>;
  /** Serialized JSON object */
  browserInfoJson?: InputMaybe<Scalars["String"]["input"]>;
  /** Required for guest checkout or when vaulting a new credit card */
  creditCard?: InputMaybe<Input_core_apimessages_FinalizeCardCheckoutPaymentRequest_CreditCardEntry>;
  /** Required when selecting an existing vaulted card */
  creditCardId?: InputMaybe<Scalars["String"]["input"]>;
  displayedAmountOwed?: InputMaybe<Input_core_apimessages_MoneyMinorUnitsEntry>;
  /** Checkout UUID */
  id?: InputMaybe<Scalars["String"]["input"]>;
};

export type Input_core_apimessages_FinalizeCardCheckoutPaymentRequest_CreditCardEntry =
  {
    address?: InputMaybe<Input_core_apimessages_AddressEntry>;
    /** amex, visa, etc. */
    brandType?: InputMaybe<Scalars["String"]["input"]>;
    cardBin?: InputMaybe<Scalars["String"]["input"]>;
    cardholderName?: InputMaybe<Scalars["String"]["input"]>;
    /** Last 4 digits of the credit card number */
    lastDigits?: InputMaybe<Scalars["String"]["input"]>;
    tokenizedFields?: InputMaybe<Input_core_apimessages_CreditCardTokenizedFields>;
  };

export type Input_core_apimessages_FinalizeCardCheckoutPaymentResponse = {
  /** checkout UUID */
  id?: InputMaybe<Scalars["String"]["input"]>;
};

export type Input_core_apimessages_FinalizePaypalCheckoutPaymentRequest = {
  accertifyDeviceInfo?: InputMaybe<Input_core_apimessages_AccertifyDeviceInfo>;
  displayedAmountOwed?: InputMaybe<Input_core_apimessages_MoneyMinorUnitsEntry>;
  /** Checkout UUID */
  id?: InputMaybe<Scalars["String"]["input"]>;
  resultConfirmation?: InputMaybe<core_apimessages_FinalizePaypalCheckoutPaymentRequest_ResultConfirmation>;
  /** Native app URL param */
  reverbApp?: InputMaybe<Scalars["String"]["input"]>;
};

export type Input_core_apimessages_FinalizePaypalCheckoutPaymentResponse = {
  /** Checkout UUID */
  id?: InputMaybe<Scalars["String"]["input"]>;
};

export type Input_core_apimessages_FinalizeRedirectAuthCheckoutPaymentRequest =
  {
    /** Checkout UUID */
    id?: InputMaybe<Scalars["String"]["input"]>;
    /** Required for native clients using Adyen Checkout v66 and below */
    legacyResult?: InputMaybe<Input_core_apimessages_FinalizeRedirectAuthCheckoutPaymentRequest_LegacyResult>;
    /** Payment processor result */
    redirectResult?: InputMaybe<Scalars["String"]["input"]>;
  };

export type Input_core_apimessages_FinalizeRedirectAuthCheckoutPaymentRequest_LegacyResult =
  {
    md?: InputMaybe<Scalars["String"]["input"]>;
    paRes?: InputMaybe<Scalars["String"]["input"]>;
  };

export type Input_core_apimessages_FinalizeRedirectAuthCheckoutPaymentResponse =
  {
    /** Checkout UUID */
    id?: InputMaybe<Scalars["String"]["input"]>;
  };

export type Input_core_apimessages_FindAddressAutocompleteSuggestionsRequest = {
  /** loqate container id */
  container?: InputMaybe<Scalars["String"]["input"]>;
  text?: InputMaybe<Scalars["String"]["input"]>;
};

export type Input_core_apimessages_FindAddressAutocompleteSuggestionsResponse =
  {
    suggestions?: InputMaybe<
      Array<
        InputMaybe<Input_core_apimessages_FindAddressAutocompleteSuggestionsResponse_AutocompleteSuggestion>
      >
    >;
  };

export type Input_core_apimessages_FindAddressAutocompleteSuggestionsResponse_AutocompleteSuggestion =
  {
    /** loqate address id */
    id?: InputMaybe<Scalars["String"]["input"]>;
    text?: InputMaybe<Scalars["String"]["input"]>;
    type?: InputMaybe<core_apimessages_FindAddressAutocompleteSuggestionsResponse_SuggestionType>;
  };

export type Input_core_apimessages_FindFavoriteRequest = {
  listingsSearchRequest?: InputMaybe<Input_reverb_search_ListingsSearchRequest>;
  shopId?: InputMaybe<Scalars["String"]["input"]>;
  shopSlug?: InputMaybe<Scalars["String"]["input"]>;
};

export type Input_core_apimessages_FindFavoriteResponse = {
  canFavorite?: InputMaybe<Scalars["Boolean"]["input"]>;
  favorite?: InputMaybe<Input_core_apimessages_Favorite>;
  isFavorite?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type Input_core_apimessages_FindFollowResponse = {
  Links?: InputMaybe<Input_core_apimessages_FollowLinks>;
  alert?: InputMaybe<Input_core_apimessages_FollowAlert>;
  display?: InputMaybe<Scalars["String"]["input"]>;
  follow?: InputMaybe<Input_core_apimessages_Follow>;
  followedSubcategories?: InputMaybe<
    Array<InputMaybe<Scalars["String"]["input"]>>
  >;
  followers?: InputMaybe<Input_core_apimessages_Followers>;
  following?: InputMaybe<Scalars["Boolean"]["input"]>;
  header?: InputMaybe<Scalars["String"]["input"]>;
  hidden?: InputMaybe<Scalars["Boolean"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  queryParams?: InputMaybe<Scalars["String"]["input"]>;
  savedSearchType?: InputMaybe<Scalars["String"]["input"]>;
  subtitle?: InputMaybe<Scalars["String"]["input"]>;
  title?: InputMaybe<Scalars["String"]["input"]>;
};

export type Input_core_apimessages_FlagProductReviewRequest = {
  productReviewId?: InputMaybe<Scalars["String"]["input"]>;
  reason?: InputMaybe<core_apimessages_FlagProductReviewRequest_Reason>;
};

export type Input_core_apimessages_FlagProductReviewResponse = {
  /** Created Flag ID */
  id?: InputMaybe<Scalars["String"]["input"]>;
};

export type Input_core_apimessages_FlatRateShippingOption = {
  carrier?: InputMaybe<Scalars["String"]["input"]>;
  displayDimensions?: InputMaybe<Scalars["String"]["input"]>;
  displayName?: InputMaybe<Scalars["String"]["input"]>;
  displayPosition?: InputMaybe<Scalars["Int"]["input"]>;
  displayTitle?: InputMaybe<Scalars["String"]["input"]>;
  maximumPounds?: InputMaybe<Scalars["Int"]["input"]>;
  optionValue?: InputMaybe<Scalars["String"]["input"]>;
};

export type Input_core_apimessages_Follow = {
  Links?: InputMaybe<Input_core_apimessages_FollowLinks>;
  alert?: InputMaybe<Input_core_apimessages_FollowAlert>;
  followedSubcategories?: InputMaybe<
    Array<InputMaybe<Scalars["String"]["input"]>>
  >;
  following?: InputMaybe<Scalars["Boolean"]["input"]>;
  header?: InputMaybe<Scalars["String"]["input"]>;
  /** follow.id */
  id?: InputMaybe<Scalars["String"]["input"]>;
  queryParams?: InputMaybe<Scalars["String"]["input"]>;
  savedSearchType?: InputMaybe<Scalars["String"]["input"]>;
  subtitle?: InputMaybe<Scalars["String"]["input"]>;
  title?: InputMaybe<Scalars["String"]["input"]>;
};

export type Input_core_apimessages_FollowAlert = {
  type?: InputMaybe<Scalars["String"]["input"]>;
};

export type Input_core_apimessages_FollowGroup = {
  Links?: InputMaybe<Input_core_apimessages_FollowGroupLinks>;
  calloutLinkText?: InputMaybe<Scalars["String"]["input"]>;
  description?: InputMaybe<Scalars["String"]["input"]>;
  follows?: InputMaybe<Array<InputMaybe<Input_core_apimessages_Follow>>>;
  title?: InputMaybe<Scalars["String"]["input"]>;
};

export type Input_core_apimessages_FollowGroupLinks = {
  callout?: InputMaybe<Input_core_apimessages_Link>;
};

export type Input_core_apimessages_FollowLinks = {
  alert?: InputMaybe<Input_core_apimessages_Link>;
  remove?: InputMaybe<Input_core_apimessages_Link>;
  search?: InputMaybe<Input_core_apimessages_Link>;
  self?: InputMaybe<Input_core_apimessages_Link>;
};

export type Input_core_apimessages_FollowQueryParams = {
  acceptsGiftCards?: InputMaybe<Scalars["Boolean"]["input"]>;
  acceptsPaymentPlans?: InputMaybe<Scalars["Boolean"]["input"]>;
  category?: InputMaybe<Scalars["String"]["input"]>;
  categoryUuid?: InputMaybe<Scalars["String"]["input"]>;
  condition?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  countryOfOrigin?: InputMaybe<Scalars["String"]["input"]>;
  /** canonical_products.id */
  cpIds?: InputMaybe<Array<InputMaybe<Scalars["Int"]["input"]>>>;
  /** curated_searches.id */
  curatedSearchId?: InputMaybe<Scalars["Int"]["input"]>;
  /** curated_sets.id */
  curatedSetId?: InputMaybe<Scalars["Int"]["input"]>;
  currency?: InputMaybe<Scalars["String"]["input"]>;
  decade?: InputMaybe<Scalars["String"]["input"]>;
  decades?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  finish?: InputMaybe<Scalars["String"]["input"]>;
  freeExpeditedShipping?: InputMaybe<Scalars["Boolean"]["input"]>;
  freeShipping?: InputMaybe<Scalars["Boolean"]["input"]>;
  handmade?: InputMaybe<Scalars["String"]["input"]>;
  itemCity?: InputMaybe<Scalars["String"]["input"]>;
  itemCountry?: InputMaybe<Scalars["String"]["input"]>;
  itemRegion?: InputMaybe<Scalars["String"]["input"]>;
  itemState?: InputMaybe<Scalars["String"]["input"]>;
  listingType?: InputMaybe<Scalars["String"]["input"]>;
  make?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  model?: InputMaybe<Scalars["String"]["input"]>;
  outlet?: InputMaybe<Scalars["Boolean"]["input"]>;
  personalProfile?: InputMaybe<Scalars["String"]["input"]>;
  preferredSeller?: InputMaybe<Scalars["Boolean"]["input"]>;
  priceMax?: InputMaybe<Scalars["String"]["input"]>;
  priceMin?: InputMaybe<Scalars["String"]["input"]>;
  productType?: InputMaybe<Scalars["String"]["input"]>;
  productTypeUuid?: InputMaybe<Scalars["String"]["input"]>;
  query?: InputMaybe<Scalars["String"]["input"]>;
  shipsTo?: InputMaybe<Scalars["String"]["input"]>;
  /** shops.id */
  shopId?: InputMaybe<Scalars["Int"]["input"]>;
  yearMax?: InputMaybe<Scalars["String"]["input"]>;
  yearMin?: InputMaybe<Scalars["String"]["input"]>;
  zeroPercentFinancing?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type Input_core_apimessages_Followers = {
  count?: InputMaybe<Scalars["Int"]["input"]>;
  display?: InputMaybe<Scalars["String"]["input"]>;
};

export type Input_core_apimessages_FormSelectCategoriesRequest = {
  entityId?: InputMaybe<Scalars["String"]["input"]>;
  entityType?: InputMaybe<core_apimessages_FormSelectCategoriesRequest_FormSelectEntityType>;
};

export type Input_core_apimessages_FormSelectCategoriesResponse = {
  categories?: InputMaybe<
    Array<InputMaybe<Input_core_apimessages_FormSelectCategory>>
  >;
};

export type Input_core_apimessages_FormSelectCategory = {
  name?: InputMaybe<Scalars["String"]["input"]>;
  subcategories?: InputMaybe<
    Array<InputMaybe<Input_core_apimessages_FormSelectCategory>>
  >;
  uuid?: InputMaybe<Scalars["String"]["input"]>;
};

export type Input_core_apimessages_FreeExpeditedShippingSignal = {
  isValid?: InputMaybe<Scalars["Boolean"]["input"]>;
  translations?: InputMaybe<Input_core_apimessages_SignalTranslation>;
};

export type Input_core_apimessages_FreeStandardShippingSignal = {
  freeShippingThresholdRequirementsMet?: InputMaybe<
    Scalars["Boolean"]["input"]
  >;
  isValid?: InputMaybe<Scalars["Boolean"]["input"]>;
  translations?: InputMaybe<Input_core_apimessages_SignalTranslation>;
};

export type Input_core_apimessages_GearCollectionItem = {
  canonicalProductId?: InputMaybe<Scalars["String"]["input"]>;
  condition?: InputMaybe<Input_core_apimessages_Condition>;
  created?: InputMaybe<Scalars["Boolean"]["input"]>;
  deleted?: InputMaybe<Scalars["Boolean"]["input"]>;
  description?: InputMaybe<Scalars["String"]["input"]>;
  finish?: InputMaybe<Scalars["String"]["input"]>;
  hideListingDescription?: InputMaybe<Scalars["Boolean"]["input"]>;
  hideListingPhotos?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** gear_collection_item.id */
  id?: InputMaybe<Scalars["String"]["input"]>;
  make?: InputMaybe<Scalars["String"]["input"]>;
  model?: InputMaybe<Scalars["String"]["input"]>;
  notes?: InputMaybe<Scalars["String"]["input"]>;
  originalListingDescription?: InputMaybe<Scalars["String"]["input"]>;
  ownerCost?: InputMaybe<Input_core_apimessages_Money>;
  price?: InputMaybe<Input_core_apimessages_Money>;
  seedId?: InputMaybe<Scalars["String"]["input"]>;
  seedType?: InputMaybe<core_apimessages_GearCollectionItem_SeedType>;
  serialNumber?: InputMaybe<Scalars["String"]["input"]>;
  state?: InputMaybe<core_apimessages_GearCollectionItem_State>;
  subcategoryUuids?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  tags?: InputMaybe<
    Array<InputMaybe<Input_core_apimessages_GearCollectionItemTag>>
  >;
  title?: InputMaybe<Scalars["String"]["input"]>;
  updated?: InputMaybe<Scalars["Boolean"]["input"]>;
  year?: InputMaybe<Scalars["String"]["input"]>;
};

export type Input_core_apimessages_GearCollectionItemEntry = {
  canonicalProductId?: InputMaybe<Scalars["String"]["input"]>;
  categoryRootUuid?: InputMaybe<Scalars["String"]["input"]>;
  categoryUuids?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  conditionSlug?: InputMaybe<Scalars["String"]["input"]>;
  description?: InputMaybe<Scalars["String"]["input"]>;
  finish?: InputMaybe<Scalars["String"]["input"]>;
  hideListingDescription?: InputMaybe<Scalars["Boolean"]["input"]>;
  hideListingPhotos?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** gear_collection_item.id */
  id?: InputMaybe<Scalars["String"]["input"]>;
  make?: InputMaybe<Scalars["String"]["input"]>;
  model?: InputMaybe<Scalars["String"]["input"]>;
  notes?: InputMaybe<Scalars["String"]["input"]>;
  ownerCost?: InputMaybe<Input_core_apimessages_Money>;
  photos?: InputMaybe<Array<InputMaybe<Input_core_apimessages_ImageEntry>>>;
  price?: InputMaybe<Input_core_apimessages_Money>;
  quickCreateItemFromSeed?: InputMaybe<Scalars["Boolean"]["input"]>;
  seedId?: InputMaybe<Scalars["String"]["input"]>;
  seedType?: InputMaybe<core_apimessages_GearCollectionItem_SeedType>;
  serialNumber?: InputMaybe<Scalars["String"]["input"]>;
  source?: InputMaybe<core_apimessages_GearCollectionItemEntry_Source>;
  tags?: InputMaybe<
    Array<InputMaybe<Input_core_apimessages_GearCollectionItemTag>>
  >;
  title?: InputMaybe<Scalars["String"]["input"]>;
  year?: InputMaybe<Scalars["String"]["input"]>;
};

export type Input_core_apimessages_GearCollectionItemTag = {
  id?: InputMaybe<Scalars["String"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
};

export type Input_core_apimessages_GearCollectionSettingResponse = {
  autoAddOrders?: InputMaybe<Scalars["Boolean"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
};

export type Input_core_apimessages_GearCollectionSettingsResponse = {
  autoAddOrders?: InputMaybe<Scalars["Boolean"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
};

export type Input_core_apimessages_GenerateAdminReportRequest = {
  endDate?: InputMaybe<Scalars["String"]["input"]>;
  reportName?: InputMaybe<Scalars["String"]["input"]>;
  startDate?: InputMaybe<Scalars["String"]["input"]>;
};

export type Input_core_apimessages_GenerateAdminReportResponse = {
  reportEnqueued?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type Input_core_apimessages_GetMyFavoriteSavedSearchesRequest = {
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
};

export type Input_core_apimessages_GetMyFavoriteSavedSearchesResponse = {
  favorites?: InputMaybe<Array<InputMaybe<Input_core_apimessages_Favorite>>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  total?: InputMaybe<Scalars["Int"]["input"]>;
};

/** copied from common.proto which is also used by data team */
export type Input_core_apimessages_GoogleShoppingClick = {
  comparisonShoppingPageId?: InputMaybe<Scalars["String"]["input"]>;
  /** This field is different than the one in common.proto */
  expiration?: InputMaybe<Scalars["String"]["input"]>;
  listingId?: InputMaybe<Scalars["String"]["input"]>;
  origin?: InputMaybe<Scalars["String"]["input"]>;
  /** / ISO8601 */
  timestamp?: InputMaybe<Scalars["String"]["input"]>;
};

export type Input_core_apimessages_GreatValueSignal = {
  isValid?: InputMaybe<Scalars["Boolean"]["input"]>;
  translations?: InputMaybe<Input_core_apimessages_SignalTranslation>;
};

export type Input_core_apimessages_GuestCheckoutUserEntry = {
  consentToAdData?: InputMaybe<Scalars["Boolean"]["input"]>;
  consentToAdPersonalization?: InputMaybe<Scalars["Boolean"]["input"]>;
  consentToAdStorage?: InputMaybe<Scalars["Boolean"]["input"]>;
  consentToAnalyticsStorage?: InputMaybe<Scalars["Boolean"]["input"]>;
  email?: InputMaybe<Scalars["String"]["input"]>;
  firstName?: InputMaybe<Scalars["String"]["input"]>;
  lastName?: InputMaybe<Scalars["String"]["input"]>;
};

export type Input_core_apimessages_GuestCheckoutVerifyShopperRequest = {
  cartItemAttributes?: InputMaybe<
    Array<InputMaybe<Input_core_apimessages_CartItemAttributes>>
  >;
  checkoutId?: InputMaybe<Scalars["String"]["input"]>;
  checkoutUuid?: InputMaybe<Scalars["String"]["input"]>;
  results?: InputMaybe<Input_core_apimessages_AdyenVerifyShopperResults>;
  totalPrice?: InputMaybe<Input_core_apimessages_Money>;
};

export type Input_core_apimessages_HomepageAdData = {
  ad?: InputMaybe<Input_core_apimessages_Ad>;
  adZones?: InputMaybe<Array<InputMaybe<Input_core_apimessages_AdZone>>>;
};

export type Input_core_apimessages_HomepageComponent = {
  adComponent?: InputMaybe<Input_core_apimessages_HomepageAdData>;
  componentIdentifier?: InputMaybe<core_apimessages_HomepageComponent_ComponentIdentifier>;
  componentType?: InputMaybe<core_apimessages_HomepageComponent_ComponentType>;
  ctaText?: InputMaybe<Scalars["String"]["input"]>;
  ctaUrl?: InputMaybe<Scalars["String"]["input"]>;
  listings?: InputMaybe<Array<InputMaybe<Input_core_apimessages_ListingData>>>;
  searches?: InputMaybe<Array<InputMaybe<Input_core_apimessages_SearchData>>>;
  slug?: InputMaybe<Scalars["String"]["input"]>;
  subtitle?: InputMaybe<Scalars["String"]["input"]>;
  title?: InputMaybe<Scalars["String"]["input"]>;
};

export type Input_core_apimessages_HomepageNotification = {
  calloutText?: InputMaybe<Scalars["String"]["input"]>;
  ctaUrl?: InputMaybe<Scalars["String"]["input"]>;
  deadlineText?: InputMaybe<Scalars["String"]["input"]>;
  descriptionText?: InputMaybe<Scalars["String"]["input"]>;
  dismissed?: InputMaybe<Scalars["Boolean"]["input"]>;
  followText?: InputMaybe<Scalars["String"]["input"]>;
  iconColor?: InputMaybe<Scalars["String"]["input"]>;
  iconUrl?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  imageUrl?: InputMaybe<Scalars["String"]["input"]>;
  type?: InputMaybe<Scalars["String"]["input"]>;
  typeColor?: InputMaybe<core_apimessages_NotificationColor>;
  typeText?: InputMaybe<Scalars["String"]["input"]>;
  updateId?: InputMaybe<Scalars["String"]["input"]>;
};

export type Input_core_apimessages_HomepageRequest = {
  adUserContext?: InputMaybe<Input_core_apimessages_AdUserContext>;
  affinitySlugs?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
};

export type Input_core_apimessages_HomepageResponse = {
  components?: InputMaybe<
    Array<InputMaybe<Input_core_apimessages_HomepageComponent>>
  >;
};

export type Input_core_apimessages_Image = {
  fallbackType?: InputMaybe<core_apimessages_Image_FallbackType>;
  height?: InputMaybe<Scalars["Int"]["input"]>;
  /** image postgres id */
  id?: InputMaybe<Scalars["String"]["input"]>;
  /** parent postgres id (polymorphic with 'item_type') */
  itemId?: InputMaybe<Scalars["String"]["input"]>;
  itemType?: InputMaybe<Scalars["String"]["input"]>;
  namedTransform?: InputMaybe<core_apimessages_NamedImageTransforms>;
  /** attachinary_files.public_id (cloudinary string) */
  publicId?: InputMaybe<Scalars["String"]["input"]>;
  source?: InputMaybe<Scalars["String"]["input"]>;
  transformation?: InputMaybe<Input_core_apimessages_ImageEntry_Transformation>;
  version?: InputMaybe<Scalars["String"]["input"]>;
  width?: InputMaybe<Scalars["Int"]["input"]>;
};

export type Input_core_apimessages_ImageAdCreative = {
  backgroundColor?: InputMaybe<Scalars["String"]["input"]>;
  backgroundColors?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  backgroundImage?: InputMaybe<Scalars["String"]["input"]>;
  cspRowQuery?: InputMaybe<Input_core_apimessages_CspRowQuery>;
  ctaButtonColor?: InputMaybe<Scalars["String"]["input"]>;
  ctaHref?: InputMaybe<Scalars["String"]["input"]>;
  ctaText?: InputMaybe<Scalars["String"]["input"]>;
  ctaTextColor?: InputMaybe<Scalars["String"]["input"]>;
  description?: InputMaybe<Scalars["String"]["input"]>;
  hideAdCallout?: InputMaybe<Scalars["Boolean"]["input"]>;
  mobileBackgroundImage?: InputMaybe<Scalars["String"]["input"]>;
  overlayImage?: InputMaybe<Scalars["String"]["input"]>;
  partnershipText?: InputMaybe<Scalars["String"]["input"]>;
  subtitle?: InputMaybe<Scalars["String"]["input"]>;
  subtitleColor?: InputMaybe<Scalars["String"]["input"]>;
  title?: InputMaybe<Scalars["String"]["input"]>;
  titleColor?: InputMaybe<Scalars["String"]["input"]>;
};

export type Input_core_apimessages_ImageEntry = {
  format?: InputMaybe<Scalars["String"]["input"]>;
  height?: InputMaybe<Scalars["Int"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  originalFilename?: InputMaybe<Scalars["String"]["input"]>;
  publicId?: InputMaybe<Scalars["String"]["input"]>;
  resourceType?: InputMaybe<Scalars["String"]["input"]>;
  transformation?: InputMaybe<Input_core_apimessages_ImageEntry_Transformation>;
  version?: InputMaybe<Scalars["String"]["input"]>;
  width?: InputMaybe<Scalars["Int"]["input"]>;
};

export type Input_core_apimessages_ImageEntry_Transformation = {
  angle?: InputMaybe<Scalars["Int"]["input"]>;
  crop?: InputMaybe<Scalars["String"]["input"]>;
  height?: InputMaybe<Scalars["String"]["input"]>;
  width?: InputMaybe<Scalars["String"]["input"]>;
  x?: InputMaybe<Scalars["String"]["input"]>;
  y?: InputMaybe<Scalars["String"]["input"]>;
};

export type Input_core_apimessages_ImageRequest = {
  id?: InputMaybe<Scalars["String"]["input"]>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  namedTransform?: InputMaybe<core_apimessages_NamedImageTransforms>;
  scope?: InputMaybe<Scalars["String"]["input"]>;
  slug?: InputMaybe<Scalars["String"]["input"]>;
  transform?: InputMaybe<Scalars["String"]["input"]>;
  type?: InputMaybe<Scalars["String"]["input"]>;
  uuid?: InputMaybe<Scalars["String"]["input"]>;
};

/** Input type for fetching images */
export type Input_core_apimessages_ImagesRequest = {
  count?: InputMaybe<Scalars["Int"]["input"]>;
  /** postgres id for corresponding table denoted by 'type' */
  ids?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  namedTransform?: InputMaybe<core_apimessages_NamedImageTransforms>;
  scope?: InputMaybe<Scalars["String"]["input"]>;
  slugs?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  transform?: InputMaybe<Scalars["String"]["input"]>;
  type?: InputMaybe<Scalars["String"]["input"]>;
  uuids?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
};

export type Input_core_apimessages_ImagesResponse = {
  images?: InputMaybe<Array<InputMaybe<Input_core_apimessages_ItemImages>>>;
};

export type Input_core_apimessages_InOtherCartsSignal = {
  isValid?: InputMaybe<Scalars["Boolean"]["input"]>;
  translations?: InputMaybe<Input_core_apimessages_SignalTranslation>;
};

export type Input_core_apimessages_InformActThresholdStatus = {
  bankAccountVerifiedDate?: InputMaybe<Scalars["Timestamp"]["input"]>;
  hasCompletedSellerVerification?: InputMaybe<Scalars["Boolean"]["input"]>;
  hasValidBankAccount?: InputMaybe<Scalars["Boolean"]["input"]>;
  hasValidTaxId?: InputMaybe<Scalars["Boolean"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  isCompliant?: InputMaybe<Scalars["Boolean"]["input"]>;
  isPaypalOnly?: InputMaybe<Scalars["Boolean"]["input"]>;
  thresholdCrossedAt?: InputMaybe<Scalars["Timestamp"]["input"]>;
};

export type Input_core_apimessages_InternationalTaxProfile = {
  address?: InputMaybe<Input_core_apimessages_Address>;
  dateOfBirth?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  tinMask?: InputMaybe<Scalars["String"]["input"]>;
  tinType?: InputMaybe<Scalars["String"]["input"]>;
  updatedAt?: InputMaybe<Scalars["Timestamp"]["input"]>;
};

export type Input_core_apimessages_ItemImages = {
  /** either a uuid or id, depending on what's present in ImagesRequest */
  id?: InputMaybe<Scalars["String"]["input"]>;
  images?: InputMaybe<Array<InputMaybe<Input_core_apimessages_Image>>>;
};

export type Input_core_apimessages_ItemSearchFilterPresets = {
  /** postgres id (polymorphic with 'collection_type') */
  collectionId?: InputMaybe<Scalars["String"]["input"]>;
  collectionSlug?: InputMaybe<Scalars["String"]["input"]>;
  collectionType?: InputMaybe<Scalars["String"]["input"]>;
  presets?: InputMaybe<
    Array<InputMaybe<Input_core_apimessages_SearchFilterPreset>>
  >;
};

export type Input_core_apimessages_LayawayTerms = {
  name?: InputMaybe<Scalars["String"]["input"]>;
  percentDown?: InputMaybe<Scalars["Int"]["input"]>;
  timePeriod?: InputMaybe<Input_google_protobuf_Duration>;
};

/**
 *   Google "fixed" a bug where timestamps should serialize as
 * a JSON string. We don't rely on this in many places, but this
 * type should be compatible without running into issues with the
 *
 * WKT/upb exception here:
 * https://github.com/protocolbuffers/upb/blob/9e1f89ef2c2cf4f9fe98bc4afe651ddfe7c575ab/upb/json/printer.c#L1252
 *
 * Represents seconds of UTC time since Unix epoch
 * 1970-01-01T00:00:00Z. Must be from 0001-01-01T00:00:00Z to
 * 9999-12-31T23:59:59Z inclusive.
 */
export type Input_core_apimessages_LegacyTimestamp = {
  /**
   *   Non-negative fractions of a second at nanosecond resolution. Negative
   * second values with fractions must still have non-negative nanos values
   * that count forward in time. Must be from 0 to 999,999,999
   * inclusive.
   */
  nanos?: InputMaybe<Scalars["Int"]["input"]>;
  /** Yes we'll have a 2038 problem */
  seconds?: InputMaybe<Scalars["Int"]["input"]>;
};

export type Input_core_apimessages_Link = {
  href?: InputMaybe<Scalars["String"]["input"]>;
  /** rel path for use in web routing */
  path?: InputMaybe<Scalars["String"]["input"]>;
};

export type Input_core_apimessages_Listing = {
  /** Total quantity that a buyer can set from a listing to a cart item. This value will be zero if the listing is not inventoried, or if an inventoried listing is out of stock. */
  allowedBuyerQuantity?: InputMaybe<Scalars["Int"]["input"]>;
  brandSlug?: InputMaybe<Scalars["String"]["input"]>;
  bumpEligible?: InputMaybe<Scalars["Boolean"]["input"]>;
  bumped?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** canonical_products.id */
  canonicalProductId?: InputMaybe<Scalars["String"]["input"]>;
  categoryRootUuid?: InputMaybe<Scalars["String"]["input"]>;
  /** includes product_type.uuids */
  categoryUuids?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  combinedShippingParent?: InputMaybe<Scalars["Boolean"]["input"]>;
  condition?: InputMaybe<Input_core_apimessages_Condition>;
  countryOfOrigin?: InputMaybe<Scalars["String"]["input"]>;
  createdAt?: InputMaybe<Input_core_apimessages_LegacyTimestamp>;
  cspId?: InputMaybe<Scalars["String"]["input"]>;
  cspUuid?: InputMaybe<Scalars["String"]["input"]>;
  currency?: InputMaybe<Scalars["String"]["input"]>;
  /** auctions.uuid */
  currentAuctionId?: InputMaybe<Scalars["String"]["input"]>;
  description?: InputMaybe<Scalars["String"]["input"]>;
  exclusiveChannel?: InputMaybe<Scalars["String"]["input"]>;
  finish?: InputMaybe<Scalars["String"]["input"]>;
  hasInventory?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** products.id */
  id?: InputMaybe<Scalars["String"]["input"]>;
  inventory?: InputMaybe<Scalars["Int"]["input"]>;
  listingType?: InputMaybe<core_apimessages_Listing_ListingType>;
  make?: InputMaybe<Scalars["String"]["input"]>;
  maxBuyerQuantity?: InputMaybe<Scalars["Int"]["input"]>;
  merchandisingType?: InputMaybe<Scalars["String"]["input"]>;
  merchandisingUuid?: InputMaybe<Scalars["String"]["input"]>;
  model?: InputMaybe<Scalars["String"]["input"]>;
  offersEnabled?: InputMaybe<Scalars["Boolean"]["input"]>;
  overriddenAttributes?: InputMaybe<
    Array<InputMaybe<Scalars["String"]["input"]>>
  >;
  price?: InputMaybe<Input_core_apimessages_Money>;
  priceGuideUuid?: InputMaybe<Scalars["String"]["input"]>;
  /** product_types.id */
  productTypeId?: InputMaybe<Scalars["String"]["input"]>;
  propSixtyFiveWarning?: InputMaybe<Scalars["String"]["input"]>;
  propSixtyFiveWarningImg?: InputMaybe<Input_core_apimessages_Link>;
  publishedAt?: InputMaybe<Input_core_apimessages_LegacyTimestamp>;
  salePrice?: InputMaybe<Input_core_apimessages_Money>;
  sameDayShippingIneligible?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** postgres id (polymorphic with 'seed_type') */
  seedId?: InputMaybe<Scalars["String"]["input"]>;
  seedType?: InputMaybe<core_apimessages_Listing_SeedType>;
  /** users.id */
  sellerId?: InputMaybe<Scalars["String"]["input"]>;
  /** users.uuid */
  sellerUuid?: InputMaybe<Scalars["String"]["input"]>;
  /** shops.id */
  shopId?: InputMaybe<Scalars["String"]["input"]>;
  shopUuid?: InputMaybe<Scalars["String"]["input"]>;
  sku?: InputMaybe<Scalars["String"]["input"]>;
  slug?: InputMaybe<Scalars["String"]["input"]>;
  soldAsIs?: InputMaybe<Scalars["Boolean"]["input"]>;
  state?: InputMaybe<Scalars["String"]["input"]>;
  stateDescription?: InputMaybe<Scalars["String"]["input"]>;
  subcategoryUuids?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  taxIncluded?: InputMaybe<Scalars["Boolean"]["input"]>;
  taxIncludedHint?: InputMaybe<Scalars["String"]["input"]>;
  title?: InputMaybe<Scalars["String"]["input"]>;
  upc?: InputMaybe<Scalars["String"]["input"]>;
  usOutlet?: InputMaybe<Scalars["Boolean"]["input"]>;
  year?: InputMaybe<Scalars["String"]["input"]>;
};

export type Input_core_apimessages_ListingAffirmZeroPercentFinancingConfiguration =
  {
    isEligible?: InputMaybe<Scalars["Boolean"]["input"]>;
    isEnabled?: InputMaybe<Scalars["Boolean"]["input"]>;
  };

export type Input_core_apimessages_ListingAutoOfferEligibility = {
  isEligible?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type Input_core_apimessages_ListingBuyerOfferEligibility = {
  isEligible?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type Input_core_apimessages_ListingCertifiedPreOwned = {
  badgeIconUrl?: InputMaybe<Scalars["String"]["input"]>;
  brandName?: InputMaybe<Scalars["String"]["input"]>;
  darkModeBrandIconUrl?: InputMaybe<Scalars["String"]["input"]>;
  description?: InputMaybe<Scalars["String"]["input"]>;
  lightModeBrandIconUrl?: InputMaybe<Scalars["String"]["input"]>;
  pageUrl?: InputMaybe<Scalars["String"]["input"]>;
  title?: InputMaybe<Scalars["String"]["input"]>;
  tooltipDescription?: InputMaybe<Scalars["String"]["input"]>;
};

export type Input_core_apimessages_ListingCorrectionRequest = {
  listingId?: InputMaybe<Scalars["String"]["input"]>;
  message?: InputMaybe<Scalars["String"]["input"]>;
};

export type Input_core_apimessages_ListingCorrectionResponse = {
  _?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type Input_core_apimessages_ListingCounts = {
  downloadCount?: InputMaybe<Scalars["Int"]["input"]>;
  downloadCountDisplay?: InputMaybe<Scalars["String"]["input"]>;
  /** products.id */
  id?: InputMaybe<Scalars["String"]["input"]>;
  inBuyerCarts?: InputMaybe<Scalars["Int"]["input"]>;
  inBuyerCartsDisplay?: InputMaybe<Scalars["String"]["input"]>;
  offers?: InputMaybe<Scalars["Int"]["input"]>;
  offersDisplay?: InputMaybe<Scalars["String"]["input"]>;
  views?: InputMaybe<Scalars["Int"]["input"]>;
  viewsDisplay?: InputMaybe<Scalars["String"]["input"]>;
  watchers?: InputMaybe<Scalars["Int"]["input"]>;
  watchersDisplay?: InputMaybe<Scalars["String"]["input"]>;
};

export type Input_core_apimessages_ListingCountsRequest = {
  ids?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
};

export type Input_core_apimessages_ListingCountsResponse = {
  listings?: InputMaybe<
    Array<InputMaybe<Input_core_apimessages_ListingCounts>>
  >;
};

export type Input_core_apimessages_ListingCuratedSet = {
  featuredInSet?: InputMaybe<Scalars["Boolean"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  setType?: InputMaybe<Scalars["String"]["input"]>;
  slug?: InputMaybe<Scalars["String"]["input"]>;
  uuid?: InputMaybe<Scalars["String"]["input"]>;
};

export type Input_core_apimessages_ListingData = {
  condition?: InputMaybe<Scalars["String"]["input"]>;
  conditionIconUrl?: InputMaybe<Scalars["String"]["input"]>;
  countryName?: InputMaybe<Scalars["String"]["input"]>;
  ctaUrl?: InputMaybe<Scalars["String"]["input"]>;
  discountDescription?: InputMaybe<Scalars["String"]["input"]>;
  disruptor?: InputMaybe<core_apimessages_ListingData_Disruptor>;
  eyebrow?: InputMaybe<core_apimessages_ListingData_Disruptor>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  imageUrl?: InputMaybe<Scalars["String"]["input"]>;
  isInCart?: InputMaybe<Scalars["Boolean"]["input"]>;
  isWatching?: InputMaybe<Scalars["Boolean"]["input"]>;
  price?: InputMaybe<Scalars["String"]["input"]>;
  savingsText?: InputMaybe<Scalars["String"]["input"]>;
  strikeThroughPrice?: InputMaybe<Scalars["String"]["input"]>;
  title?: InputMaybe<Scalars["String"]["input"]>;
};

export type Input_core_apimessages_ListingDigitalDetails = {
  installationInstructions?: InputMaybe<Scalars["String"]["input"]>;
  releaseNotes?: InputMaybe<Scalars["String"]["input"]>;
  rollingDownloadCount?: InputMaybe<Scalars["Int"]["input"]>;
  supportedFormats?: InputMaybe<
    Array<InputMaybe<Input_core_apimessages_ListingSupportedFormat>>
  >;
  systemRequirements?: InputMaybe<Scalars["String"]["input"]>;
  variants?: InputMaybe<
    Array<InputMaybe<Input_core_apimessages_ListingDigitalVariant>>
  >;
};

export type Input_core_apimessages_ListingDigitalVariant = {
  downloadText?: InputMaybe<Scalars["String"]["input"]>;
  fileSize?: InputMaybe<Scalars["String"]["input"]>;
  fileTypes?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  fileUrl?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  operatingSystems?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
};

/** For PUT/POST submissions */
export type Input_core_apimessages_ListingEntry = {
  carrierCalculatedPackage?: InputMaybe<Input_core_apimessages_ShippingLabelPackage>;
  categoryRootUuid?: InputMaybe<Scalars["String"]["input"]>;
  categoryUuids?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  conditionSlug?: InputMaybe<Scalars["String"]["input"]>;
  countryOfOrigin?: InputMaybe<Scalars["String"]["input"]>;
  handmade?: InputMaybe<Scalars["Boolean"]["input"]>;
  hasInventory?: InputMaybe<Scalars["Boolean"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  inventory?: InputMaybe<Scalars["Int"]["input"]>;
  localizedContents?: InputMaybe<
    Array<InputMaybe<Input_core_apimessages_LocalizedListingContent>>
  >;
  offersEnabled?: InputMaybe<Scalars["Boolean"]["input"]>;
  offersLocalPickup?: InputMaybe<Scalars["Boolean"]["input"]>;
  photos?: InputMaybe<Array<InputMaybe<Input_core_apimessages_ImageEntry>>>;
  prefersReverbShippingLabel?: InputMaybe<Scalars["Boolean"]["input"]>;
  preorderInfo?: InputMaybe<Input_core_apimessages_PreorderInfoEntry>;
  price?: InputMaybe<Input_core_apimessages_Money>;
  sameDayShippingIneligible?: InputMaybe<Scalars["Boolean"]["input"]>;
  scheduledPriceDrops?: InputMaybe<
    Array<InputMaybe<Input_core_apimessages_ScheduledPriceDropEntry>>
  >;
  seedId?: InputMaybe<Scalars["String"]["input"]>;
  seedType?: InputMaybe<core_apimessages_Listing_SeedType>;
  sellerCost?: InputMaybe<Input_core_apimessages_Money>;
  sellerReportedMap?: InputMaybe<Input_core_apimessages_Money>;
  shippingProfileId?: InputMaybe<Scalars["String"]["input"]>;
  shippingRates?: InputMaybe<
    Array<InputMaybe<Input_core_apimessages_ShippingRates>>
  >;
  sku?: InputMaybe<Scalars["String"]["input"]>;
  soldAsIs?: InputMaybe<Scalars["Boolean"]["input"]>;
  taxExempt?: InputMaybe<Scalars["Boolean"]["input"]>;
  traits?: InputMaybe<Array<InputMaybe<Input_core_apimessages_Trait>>>;
  upc?: InputMaybe<Scalars["String"]["input"]>;
  upcDoesNotApply?: InputMaybe<Scalars["Boolean"]["input"]>;
  videoLinkUrl?: InputMaybe<Scalars["String"]["input"]>;
  year?: InputMaybe<Scalars["String"]["input"]>;
};

export type Input_core_apimessages_ListingExpressPay = {
  /** Encoded value such as "RL-123" to use to start a checkout */
  checkoutBundlingId?: InputMaybe<Scalars["String"]["input"]>;
  /** Initial value for display based on listing price and shipping. May be different than the final value. */
  estimatedTotal?: InputMaybe<Input_core_apimessages_Money>;
  /** Google Pay Config for a listing. */
  googlePay?: InputMaybe<Input_core_apimessages_CheckoutGooglePayDetails>;
  /** products.id */
  id?: InputMaybe<Scalars["String"]["input"]>;
  paypal?: InputMaybe<Input_core_apimessages_CheckoutPaypalDetails>;
};

export type Input_core_apimessages_ListingInCartCount = {
  count?: InputMaybe<Input_google_protobuf_Int32Value>;
};

export type Input_core_apimessages_ListingInUserCart = {
  inCart?: InputMaybe<Scalars["Boolean"]["input"]>;
  listingId?: InputMaybe<Scalars["String"]["input"]>;
};

export type Input_core_apimessages_ListingPriceRecommendationsRequest = {
  countryCode?: InputMaybe<Scalars["String"]["input"]>;
  currency?: InputMaybe<Scalars["String"]["input"]>;
  listingIds?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
};

export type Input_core_apimessages_ListingPriceRecommendationsResponse = {
  priceRecommendations?: InputMaybe<
    Array<InputMaybe<Input_core_apimessages_PriceRecommendation>>
  >;
};

export type Input_core_apimessages_ListingPricing = {
  buyerPrice?: InputMaybe<Input_core_apimessages_Money>;
  /** products.id */
  id?: InputMaybe<Scalars["String"]["input"]>;
  originalPrice?: InputMaybe<Input_core_apimessages_Money>;
  originalPriceDescription?: InputMaybe<Scalars["String"]["input"]>;
  ribbon?: InputMaybe<Input_core_apimessages_Ribbon>;
  typicalNewPriceDisplay?: InputMaybe<Input_core_apimessages_TypicalNewPriceDisplay>;
};

export type Input_core_apimessages_ListingSale = {
  isListingEligible?: InputMaybe<Scalars["Boolean"]["input"]>;
  isListingInSale?: InputMaybe<Scalars["Boolean"]["input"]>;
  sale?: InputMaybe<Input_core_apimessages_Sale>;
  totalLiveListings?: InputMaybe<Scalars["Int"]["input"]>;
};

export type Input_core_apimessages_ListingSaleMembership = {
  isListingEligible?: InputMaybe<Scalars["Boolean"]["input"]>;
  isListingInSale?: InputMaybe<Scalars["Boolean"]["input"]>;
  listingId?: InputMaybe<Scalars["String"]["input"]>;
  sale?: InputMaybe<Input_core_apimessages_Sale>;
  totalLiveListings?: InputMaybe<Scalars["Int"]["input"]>;
};

export type Input_core_apimessages_ListingSales = {
  reverbSales?: InputMaybe<
    Array<InputMaybe<Input_core_apimessages_ListingSale>>
  >;
  sellerSales?: InputMaybe<
    Array<InputMaybe<Input_core_apimessages_ListingSale>>
  >;
};

export type Input_core_apimessages_ListingSalesMemberships = {
  reverbSalesMemberships?: InputMaybe<
    Array<InputMaybe<Input_core_apimessages_ListingSaleMembership>>
  >;
  sellerSalesMemberships?: InputMaybe<
    Array<InputMaybe<Input_core_apimessages_ListingSaleMembership>>
  >;
};

export type Input_core_apimessages_ListingSignals = {
  availableForPickupSignal?: InputMaybe<Input_core_apimessages_AvailableForPickupSignal>;
  availableNearbyForPickupSignal?: InputMaybe<Input_core_apimessages_AvailableNearbyForPickupSignal>;
  buyWithConfidenceSignal?: InputMaybe<Input_core_apimessages_BuyWithConfidenceSignal>;
  expeditedShippingAvailableSignal?: InputMaybe<Input_core_apimessages_ExpeditedShippingAvailableSignal>;
  freeExpeditedShippingSignal?: InputMaybe<Input_core_apimessages_FreeExpeditedShippingSignal>;
  freeStandardShippingSignal?: InputMaybe<Input_core_apimessages_FreeStandardShippingSignal>;
  greatValueSignal?: InputMaybe<Input_core_apimessages_GreatValueSignal>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  inOtherCartsSignal?: InputMaybe<Input_core_apimessages_InOtherCartsSignal>;
  localPickupOnlySignal?: InputMaybe<Input_core_apimessages_LocalPickupOnlySignal>;
  lowStockSignal?: InputMaybe<Input_core_apimessages_LowStockSignal>;
  newListingSignal?: InputMaybe<Input_core_apimessages_NewListingSignal>;
  onSaleSignal?: InputMaybe<Input_core_apimessages_OnSaleSignal>;
  paymentPlanSignal?: InputMaybe<Input_core_apimessages_PaymentPlanSignal>;
  predictedToSellSoonSignal?: InputMaybe<Input_core_apimessages_PredictedToSellSoonSignal>;
  priceDropSignal?: InputMaybe<Input_core_apimessages_PriceDropSignal>;
  rareGearSignal?: InputMaybe<Input_core_apimessages_RareGearSignal>;
  returnPolicySignal?: InputMaybe<Input_core_apimessages_ReturnPolicySignal>;
  secureCheckoutSignal?: InputMaybe<Input_core_apimessages_SecureCheckoutSignal>;
  sharedSignalData?: InputMaybe<Input_core_apimessages_SharedSignalData>;
  /** deprecated */
  shipsSoonSignal?: InputMaybe<Input_core_apimessages_ShipsSoonSignal>;
};

export type Input_core_apimessages_ListingSpec = {
  productSpecs?: InputMaybe<
    Array<InputMaybe<Input_core_apimessages_ProductSpec>>
  >;
};

export type Input_core_apimessages_ListingSupportedFormat = {
  fileTypes?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  operatingSystem?: InputMaybe<Scalars["String"]["input"]>;
};

export type Input_core_apimessages_ListingUserView = {
  /** True if the current user has recently viewed this item */
  isRecentlyViewed?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type Input_core_apimessages_ListingValidForPublish = {
  errors?: InputMaybe<Array<InputMaybe<Input_core_apimessages_Error>>>;
  isValid?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type Input_core_apimessages_ListingsCuratedSetsRequest = {
  curatedSetSlugs?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  ids?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
};

export type Input_core_apimessages_ListingsCuratedSetsResponse = {
  curatedSetsForListings?: InputMaybe<
    Array<InputMaybe<Input_core_apimessages_CuratedSetsForListing>>
  >;
};

export type Input_core_apimessages_ListingsInUserCartRequest = {
  /** product.id */
  listingIds?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
};

export type Input_core_apimessages_ListingsInUserCartResponse = {
  listings?: InputMaybe<
    Array<InputMaybe<Input_core_apimessages_ListingInUserCart>>
  >;
};

export type Input_core_apimessages_ListingsOrderStatsRequest = {
  listingIds?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
};

export type Input_core_apimessages_ListingsOrderStatsResponse = {
  orderStats?: InputMaybe<Array<InputMaybe<Input_core_apimessages_OrderStats>>>;
};

export type Input_core_apimessages_ListingsPricingRequest = {
  ids?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
};

export type Input_core_apimessages_ListingsPricingResponse = {
  listings?: InputMaybe<
    Array<InputMaybe<Input_core_apimessages_ListingPricing>>
  >;
};

export type Input_core_apimessages_ListingsRequest = {
  Aid?: InputMaybe<Scalars["String"]["input"]>;
  /** products.id */
  ids?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  utmCompaign?: InputMaybe<Scalars["String"]["input"]>;
};

export type Input_core_apimessages_ListingsResponse = {
  listings?: InputMaybe<Array<InputMaybe<Input_core_apimessages_Listing>>>;
};

export type Input_core_apimessages_ListingsSignalsRequest = {
  ids?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
};

export type Input_core_apimessages_ListingsSignalsResponse = {
  listingsSignals?: InputMaybe<
    Array<InputMaybe<Input_core_apimessages_ListingSignals>>
  >;
};

export type Input_core_apimessages_ListingsSpecsRequest = {
  ids?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
};

export type Input_core_apimessages_ListingsSpecsResponse = {
  specs?: InputMaybe<Array<InputMaybe<Input_core_apimessages_ListingSpec>>>;
};

export type Input_core_apimessages_ListingsValidForPublishRequest = {
  listingIds?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
};

export type Input_core_apimessages_ListingsValidForPublishResponse = {
  listingsValidForPublish?: InputMaybe<
    Array<InputMaybe<Input_core_apimessages_ListingValidForPublish>>
  >;
};

export type Input_core_apimessages_LocalPickupOnlySignal = {
  isValid?: InputMaybe<Scalars["Boolean"]["input"]>;
  translations?: InputMaybe<Input_core_apimessages_SignalTranslation>;
};

export type Input_core_apimessages_LocalizationSettings = {
  currency?: InputMaybe<Scalars["String"]["input"]>;
  locale?: InputMaybe<Scalars["String"]["input"]>;
  shippingRegion?: InputMaybe<Scalars["String"]["input"]>;
};

export type Input_core_apimessages_LocalizedListingContent = {
  defaultForShop?: InputMaybe<Scalars["Boolean"]["input"]>;
  description?: InputMaybe<Scalars["String"]["input"]>;
  finish?: InputMaybe<Scalars["String"]["input"]>;
  locale?: InputMaybe<Scalars["String"]["input"]>;
  make?: InputMaybe<Scalars["String"]["input"]>;
  model?: InputMaybe<Scalars["String"]["input"]>;
  prop65Warning?: InputMaybe<Scalars["String"]["input"]>;
  title?: InputMaybe<Scalars["String"]["input"]>;
};

export type Input_core_apimessages_LoginMyPacklinkAccountRequest = {
  countryCode?: InputMaybe<Scalars["String"]["input"]>;
  email?: InputMaybe<Scalars["String"]["input"]>;
  password?: InputMaybe<Scalars["String"]["input"]>;
};

export type Input_core_apimessages_LoginMyPacklinkAccountResponse = {
  shopId?: InputMaybe<Scalars["String"]["input"]>;
};

export type Input_core_apimessages_LowStockSignal = {
  isValid?: InputMaybe<Scalars["Boolean"]["input"]>;
  translations?: InputMaybe<Input_core_apimessages_SignalTranslation>;
};

export type Input_core_apimessages_MarkOrderAsReceivedRequest = {
  orderId?: InputMaybe<Scalars["String"]["input"]>;
};

export type Input_core_apimessages_MarkOrderAsReceivedResponse = {
  orderId?: InputMaybe<Scalars["String"]["input"]>;
};

export type Input_core_apimessages_MeResponse = {
  admin?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** True when this user has a single orderBundle, otherwise false. */
  firstTimeBuyer?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** users.id */
  id?: InputMaybe<Scalars["String"]["input"]>;
  profileSlug?: InputMaybe<Scalars["String"]["input"]>;
  requireOptIn?: InputMaybe<Scalars["Boolean"]["input"]>;
  shopId?: InputMaybe<Scalars["String"]["input"]>;
  shopSlug?: InputMaybe<Scalars["String"]["input"]>;
  shopUuid?: InputMaybe<Scalars["String"]["input"]>;
  status?: InputMaybe<core_apimessages_UserStatus>;
  uuid?: InputMaybe<Scalars["String"]["input"]>;
};

export type Input_core_apimessages_Measurement = {
  unit?: InputMaybe<Scalars["String"]["input"]>;
  value?: InputMaybe<Scalars["String"]["input"]>;
};

export type Input_core_apimessages_Money = {
  amount?: InputMaybe<Scalars["String"]["input"]>;
  amountCents?: InputMaybe<Scalars["Int"]["input"]>;
  currency?: InputMaybe<Scalars["String"]["input"]>;
  display?: InputMaybe<Scalars["String"]["input"]>;
  symbol?: InputMaybe<Scalars["String"]["input"]>;
};

/** For POST/PUT API requests for major units, suitable for user text field input */
export type Input_core_apimessages_MoneyMajorUnitsEntry = {
  amount?: InputMaybe<Scalars["String"]["input"]>;
  currency?: InputMaybe<Scalars["String"]["input"]>;
};

/** For POST/PUT API requests where minor units (cents) are known */
export type Input_core_apimessages_MoneyMinorUnitsEntry = {
  amountCents?: InputMaybe<Scalars["Int"]["input"]>;
  currency?: InputMaybe<Scalars["String"]["input"]>;
};

export type Input_core_apimessages_MosaicTile = {
  contentSponsorship?: InputMaybe<Input_core_apimessages_ContentSponsorship>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  imageSubtitle?: InputMaybe<Scalars["String"]["input"]>;
  imageTitle?: InputMaybe<Scalars["String"]["input"]>;
  imageUrl?: InputMaybe<Scalars["String"]["input"]>;
  position?: InputMaybe<core_apimessages_MosaicTilePosition>;
  url?: InputMaybe<Scalars["String"]["input"]>;
};

export type Input_core_apimessages_MosaicTilesResponse = {
  mosaicTiles?: InputMaybe<
    Array<InputMaybe<Input_core_apimessages_MosaicTile>>
  >;
};

/** duplicate of the Mparticle ProfileResponse defined in rql */
export type Input_core_apimessages_MparticleProfileResponse = {
  averageOrderValue?: InputMaybe<Scalars["Float"]["input"]>;
  brandCategories?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  buyerFeedbackRating?: InputMaybe<Scalars["Float"]["input"]>;
  buyerLabel?: InputMaybe<Scalars["String"]["input"]>;
  cumulativeGmv?: InputMaybe<Scalars["Float"]["input"]>;
  firstOrderDate?: InputMaybe<Scalars["String"]["input"]>;
  mostRecentOrderDate?: InputMaybe<Scalars["String"]["input"]>;
  mpid?: InputMaybe<Scalars["String"]["input"]>;
  recentlyViewedCsps?: InputMaybe<
    Array<InputMaybe<Scalars["String"]["input"]>>
  >;
  sellerFeedbackRating?: InputMaybe<Scalars["Float"]["input"]>;
  sellerLabel?: InputMaybe<Scalars["String"]["input"]>;
  topBrandCategories?: InputMaybe<
    Array<InputMaybe<Scalars["String"]["input"]>>
  >;
  topBrands?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  topCategories?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  topCategoryUuids?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  topMostRecentProductTypes?: InputMaybe<
    Array<InputMaybe<Scalars["String"]["input"]>>
  >;
  topProductType?: InputMaybe<Scalars["String"]["input"]>;
  topProductTypeUuids?: InputMaybe<
    Array<InputMaybe<Scalars["String"]["input"]>>
  >;
  topProductTypes?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  totalBuyerOffersAccepted?: InputMaybe<Scalars["Int"]["input"]>;
  totalBuyerOffersCreated?: InputMaybe<Scalars["Int"]["input"]>;
  totalFeedFollows?: InputMaybe<Scalars["Int"]["input"]>;
  totalListingsCreated?: InputMaybe<Scalars["Int"]["input"]>;
  totalListingsDraft?: InputMaybe<Scalars["Int"]["input"]>;
  totalListingsLive?: InputMaybe<Scalars["Int"]["input"]>;
  totalListingsSold?: InputMaybe<Scalars["Int"]["input"]>;
  totalListingsSuspended?: InputMaybe<Scalars["Int"]["input"]>;
  totalPurchases?: InputMaybe<Scalars["Int"]["input"]>;
};

export type Input_core_apimessages_MyAddressesRequest = {
  addressType?: InputMaybe<core_apimessages_MyAddressType>;
};

export type Input_core_apimessages_MyAddressesResponse = {
  addresses?: InputMaybe<Array<InputMaybe<Input_core_apimessages_Address>>>;
};

export type Input_core_apimessages_MyAffirmCuratedSetMembershipRequest = {
  productId?: InputMaybe<Scalars["String"]["input"]>;
};

export type Input_core_apimessages_MyAvailableActionsIndexRequest = {
  listingId?: InputMaybe<Scalars["String"]["input"]>;
};

export type Input_core_apimessages_MyAvailableActionsIndexResponse = {
  availableActions?: InputMaybe<
    Array<InputMaybe<Input_core_apimessages_AvailableAction>>
  >;
};

export type Input_core_apimessages_MyBuyerCouponsRequest = {
  checkoutUuid?: InputMaybe<Scalars["String"]["input"]>;
  context?: InputMaybe<core_apimessages_MyBuyerCouponsRequest_Context>;
  shopSlug?: InputMaybe<Scalars["String"]["input"]>;
};

export type Input_core_apimessages_MyBuyerCouponsResponse = {
  shopCampaignCoupons?: InputMaybe<
    Array<InputMaybe<Input_core_apimessages_ShopCampaignCoupon>>
  >;
};

export type Input_core_apimessages_MyCartItemBundlesIndexResponse = {
  bundles?: InputMaybe<
    Array<InputMaybe<Input_core_apimessages_CartItemBundle>>
  >;
};

export type Input_core_apimessages_MyCartItemsIndexResponse = {
  cartItems?: InputMaybe<Array<InputMaybe<Input_core_apimessages_CartItem>>>;
};

export type Input_core_apimessages_MyCreditCardsResponse = {
  creditCards?: InputMaybe<
    Array<InputMaybe<Input_core_apimessages_CreditCard>>
  >;
};

export type Input_core_apimessages_MyCuratedSetMembershipRequest = {
  productId?: InputMaybe<Scalars["String"]["input"]>;
  setType?: InputMaybe<core_apimessages_CuratedSet_SetType>;
};

export type Input_core_apimessages_MyCuratedSetMembershipResponse = {
  curatedSetMembership?: InputMaybe<Input_core_apimessages_CuratedSetMembership>;
};

export type Input_core_apimessages_MyDirectCheckoutProfileRequest = {
  _?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type Input_core_apimessages_MyDirectCheckoutProfileResponse = {
  bankName?: InputMaybe<Scalars["String"]["input"]>;
  countryCode?: InputMaybe<Scalars["String"]["input"]>;
  currency?: InputMaybe<Scalars["String"]["input"]>;
  hasAcceptedReverbBankTerms?: InputMaybe<Scalars["Boolean"]["input"]>;
  maskedNameSummary?: InputMaybe<Scalars["String"]["input"]>;
  nameOnAccount?: InputMaybe<Scalars["String"]["input"]>;
  payoutMethod?: InputMaybe<Scalars["String"]["input"]>;
  plaidAccountId?: InputMaybe<Scalars["String"]["input"]>;
  profileErrors?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
};

export type Input_core_apimessages_MyFeedSearchRequest = {
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  /**
   *   Optional listings search filters. The following fields are ignored:
   * - limit/offset: filters are optional, but pagination is not optional. Use the top level request proto fields instead.
   * - shipping_region_code: uses the `X-Shipping-Region` HTTP request header instead.
   * - locale: uses the `Accept-Language` HTTP request header instead (which will default to English).
   * - item_region: uses the `X-Item-Region` HTTP request header instead.
   * - experiments: uses the `X-Experiments` HTTP request header instead.
   */
  listingsSearchRequest?: InputMaybe<Input_reverb_search_ListingsSearchRequest>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
};

/**
 *   Similar to a `reverb.search.SearchResponse` message,
 * but instead of a `resultIds` field, it has `results`, which contains ids and metadata:
 * {
 *   "results": [{ "entityType": "LISTING", "entityId": "1", "source": "SAVED_SEARCH" }, ...],
 *   ...
 * }
 */
export type Input_core_apimessages_MyFeedSearchResponse = {
  filters?: InputMaybe<Array<InputMaybe<Input_reverb_search_Filter>>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  results?: InputMaybe<Array<InputMaybe<Input_core_apimessages_FeedResult>>>;
  total?: InputMaybe<Scalars["Int"]["input"]>;
};

export type Input_core_apimessages_MyFeedbackMessagesRequest = {
  _?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type Input_core_apimessages_MyFeedbackMessagesResponse = {
  feedbackMessages?: InputMaybe<
    Array<InputMaybe<Input_core_apimessages_FeedbackMessage>>
  >;
};

export type Input_core_apimessages_MyHomepageNotificationsRequest = {
  imageType?: InputMaybe<core_apimessages_IconImageType>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  multiClientExperiments?: InputMaybe<
    Array<InputMaybe<Input_core_apimessages_ExperimentData>>
  >;
};

export type Input_core_apimessages_MyHomepageNotificationsResponse = {
  errors?: InputMaybe<Array<InputMaybe<Input_core_apimessages_Error>>>;
  homepageNotifications?: InputMaybe<
    Array<InputMaybe<Input_core_apimessages_HomepageNotification>>
  >;
};

export type Input_core_apimessages_MyMessage = {
  body?: InputMaybe<Scalars["String"]["input"]>;
  conversationId?: InputMaybe<Scalars["String"]["input"]>;
  createdAt?: InputMaybe<Scalars["Timestamp"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  read?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type Input_core_apimessages_MyMessagesRequest = {
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  unrepliedOnly?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type Input_core_apimessages_MyMessagesResponse = {
  messages?: InputMaybe<Array<InputMaybe<Input_core_apimessages_MyMessage>>>;
};

export type Input_core_apimessages_MyPacklinkAccountStatusResponse = {
  account?: InputMaybe<Input_core_apimessages_PacklinkAccount>;
};

export type Input_core_apimessages_MyPayoutBalancesResponse = {
  payoutBalances?: InputMaybe<Array<InputMaybe<Input_core_apimessages_Money>>>;
};

export type Input_core_apimessages_MyReverbCreditsResponse = {
  reverbCredits?: InputMaybe<Array<InputMaybe<Input_core_apimessages_Money>>>;
};

export type Input_core_apimessages_MySalesResponse = {
  reverbSales?: InputMaybe<Array<InputMaybe<Input_core_apimessages_Sale>>>;
  sellerSales?: InputMaybe<Array<InputMaybe<Input_core_apimessages_Sale>>>;
};

export type Input_core_apimessages_MyShippingProfile = {
  categoryUuids?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  localPickup?: InputMaybe<Scalars["Boolean"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  offersIncrementalRates?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type Input_core_apimessages_MyShopBadgesResponse = {
  shopBadges?: InputMaybe<Array<InputMaybe<Input_core_apimessages_ShopBadge>>>;
};

export type Input_core_apimessages_MyShopBumpStats = {
  percentageSpent?: InputMaybe<Scalars["Int"]["input"]>;
  sales?: InputMaybe<Input_core_apimessages_Money>;
  spent?: InputMaybe<Input_core_apimessages_Money>;
  views?: InputMaybe<Scalars["Int"]["input"]>;
};

export type Input_core_apimessages_MyShopBumpStatsRequest = {
  timePeriod?: InputMaybe<Scalars["String"]["input"]>;
};

export type Input_core_apimessages_MyShopBumpStatsResponse = {
  brandNew?: InputMaybe<Input_core_apimessages_MyShopBumpStats>;
  used?: InputMaybe<Input_core_apimessages_MyShopBumpStats>;
};

export type Input_core_apimessages_MyShopCampaign = {
  active?: InputMaybe<Scalars["Boolean"]["input"]>;
  applyToBStockConditions?: InputMaybe<Scalars["Boolean"]["input"]>;
  applyToNewConditions?: InputMaybe<Scalars["Boolean"]["input"]>;
  applyToUsedConditions?: InputMaybe<Scalars["Boolean"]["input"]>;
  campaignType?: InputMaybe<core_apimessages_MyShopCampaign_CampaignType>;
  code?: InputMaybe<Scalars["String"]["input"]>;
  discountAmount?: InputMaybe<Input_core_apimessages_Money>;
  discountPercent?: InputMaybe<Scalars["Int"]["input"]>;
  discountSummary?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  maxDiscountAmount?: InputMaybe<Input_core_apimessages_Money>;
  minPurchaseAmount?: InputMaybe<Input_core_apimessages_Money>;
  redeemedCouponCount?: InputMaybe<Scalars["Int"]["input"]>;
  totalCouponCount?: InputMaybe<Scalars["Int"]["input"]>;
  unlimitedUse?: InputMaybe<Scalars["Boolean"]["input"]>;
  useRetroactiveSends?: InputMaybe<Scalars["Boolean"]["input"]>;
};

/** For PUT/POST submissions */
export type Input_core_apimessages_MyShopCampaignEntry = {
  active?: InputMaybe<Scalars["Boolean"]["input"]>;
  applyToBStockConditions?: InputMaybe<Scalars["Boolean"]["input"]>;
  applyToNewConditions?: InputMaybe<Scalars["Boolean"]["input"]>;
  applyToUsedConditions?: InputMaybe<Scalars["Boolean"]["input"]>;
  campaignType?: InputMaybe<core_apimessages_MyShopCampaign_CampaignType>;
  code?: InputMaybe<Scalars["String"]["input"]>;
  /** in shop currency */
  discountAmountInput?: InputMaybe<Scalars["String"]["input"]>;
  discountPercent?: InputMaybe<Scalars["Int"]["input"]>;
  excludedBrandsSlugs?: InputMaybe<
    Array<InputMaybe<Scalars["String"]["input"]>>
  >;
  excludedCategoryUuids?: InputMaybe<
    Array<InputMaybe<Scalars["String"]["input"]>>
  >;
  id?: InputMaybe<Scalars["String"]["input"]>;
  /** in shop currency */
  maxDiscountAmountInput?: InputMaybe<Scalars["String"]["input"]>;
  /** in shop currency */
  minPurchaseAmountInput?: InputMaybe<Scalars["String"]["input"]>;
  unlimitedUse?: InputMaybe<Scalars["Boolean"]["input"]>;
  useRetroactiveSends?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type Input_core_apimessages_MyShopCampaignsResponse = {
  shopCampaigns?: InputMaybe<
    Array<InputMaybe<Input_core_apimessages_MyShopCampaign>>
  >;
};

export type Input_core_apimessages_MyShopComparisonStats = {
  changeStats?: InputMaybe<Input_core_apimessages_MyShopStats>;
  currentStats?: InputMaybe<Input_core_apimessages_MyShopStats>;
  previousStats?: InputMaybe<Input_core_apimessages_MyShopStats>;
};

export type Input_core_apimessages_MyShopEarningsRequest = {
  timePeriod?: InputMaybe<Scalars["String"]["input"]>;
};

export type Input_core_apimessages_MyShopEarningsResponse = {
  accommodationsTotal?: InputMaybe<Input_core_apimessages_Money>;
  ordersTotal?: InputMaybe<Input_core_apimessages_Money>;
};

export type Input_core_apimessages_MyShopExportDac7OrderDataRequest = {
  year?: InputMaybe<Scalars["String"]["input"]>;
};

export type Input_core_apimessages_MyShopExportDac7OrderDataResponse = {
  success?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type Input_core_apimessages_MyShopOnboardingResponse = {
  billingMethodStatus?: InputMaybe<core_apimessages_MyShopOnboardingResponse_BillingMethodStatus>;
  originCountryCode?: InputMaybe<Scalars["String"]["input"]>;
  paymentMethod?: InputMaybe<core_apimessages_MyShopOnboardingResponse_PaymentMethod>;
  paypalSetupStatus?: InputMaybe<core_apimessages_MyShopOnboardingResponse_PaypalSetupStatus>;
};

export type Input_core_apimessages_MyShopOrderRefundStatsRequest = {
  endDate?: InputMaybe<Scalars["String"]["input"]>;
  startDate?: InputMaybe<Scalars["String"]["input"]>;
};

export type Input_core_apimessages_MyShopOrderRefundStatsResponse = {
  sellerInitiatedRefundCount?: InputMaybe<Scalars["Int"]["input"]>;
};

export type Input_core_apimessages_MyShopPaypalProfileResponse = {
  paypalProfile?: InputMaybe<Input_core_apimessages_MyShopPaypalProfileResponse_PaypalProfile>;
};

export type Input_core_apimessages_MyShopPaypalProfileResponse_PaypalProfile = {
  email?: InputMaybe<Scalars["String"]["input"]>;
};

export type Input_core_apimessages_MyShopResponse = {
  shop?: InputMaybe<Input_core_apimessages_Shop>;
};

export type Input_core_apimessages_MyShopSalesChartData = {
  currencySymbol?: InputMaybe<Scalars["String"]["input"]>;
  labels?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  salesInMoreThanOneCurrency?: InputMaybe<Scalars["Boolean"]["input"]>;
  series?: InputMaybe<
    Array<InputMaybe<Input_core_apimessages_MyShopSalesChartDataSet>>
  >;
};

export type Input_core_apimessages_MyShopSalesChartDataSet = {
  dataSet?: InputMaybe<Array<InputMaybe<Scalars["Int"]["input"]>>>;
};

export type Input_core_apimessages_MyShopShippingProfilesRequest = {
  _?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type Input_core_apimessages_MyShopShippingProfilesResponse = {
  shippingProfiles?: InputMaybe<
    Array<InputMaybe<Input_core_apimessages_MyShippingProfile>>
  >;
};

export type Input_core_apimessages_MyShopShippingRatesRequest = {
  shippingProfileIds?: InputMaybe<
    Array<InputMaybe<Scalars["String"]["input"]>>
  >;
};

export type Input_core_apimessages_MyShopShippingRatesResponse = {
  shippingRatesForShippingProfiles?: InputMaybe<
    Array<InputMaybe<Input_core_apimessages_ShippingRatesForShippingProfile>>
  >;
};

export type Input_core_apimessages_MyShopStats = {
  messages?: InputMaybe<Scalars["Int"]["input"]>;
  offers?: InputMaybe<Scalars["Int"]["input"]>;
  orders?: InputMaybe<Scalars["Int"]["input"]>;
  watchers?: InputMaybe<Scalars["Int"]["input"]>;
};

export type Input_core_apimessages_MyShopStatsRequest = {
  timePeriod?: InputMaybe<Scalars["String"]["input"]>;
};

export type Input_core_apimessages_MyShopStatsResponse = {
  comparisonStats?: InputMaybe<Input_core_apimessages_MyShopComparisonStats>;
  salesChartData?: InputMaybe<Input_core_apimessages_MyShopSalesChartData>;
};

export type Input_core_apimessages_MyUpdatesPromotion = {
  id?: InputMaybe<Scalars["String"]["input"]>;
  startsAt?: InputMaybe<Scalars["Timestamp"]["input"]>;
  subtitle?: InputMaybe<Scalars["String"]["input"]>;
  title?: InputMaybe<Scalars["String"]["input"]>;
  url?: InputMaybe<Input_core_apimessages_Link>;
};

export type Input_core_apimessages_MyUserServicesResponse = {
  userServices?: InputMaybe<
    Array<InputMaybe<Input_core_apimessages_UserService>>
  >;
};

export type Input_core_apimessages_Negotiation = {
  /** deprecated in favor of seller_shipping_warning */
  buyerOutsideListingShippingRegions?: InputMaybe<Scalars["Boolean"]["input"]>;
  buyerUuid?: InputMaybe<Scalars["String"]["input"]>;
  buyerWillPayImmediately?: InputMaybe<Scalars["Boolean"]["input"]>;
  expiresAt?: InputMaybe<Input_core_apimessages_LegacyTimestamp>;
  /** negotiations.id */
  id?: InputMaybe<Scalars["String"]["input"]>;
  lastOffer?: InputMaybe<Input_core_apimessages_Offer>;
  negotiationType?: InputMaybe<core_apimessages_NegotiationType>;
  orderUuids?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  sellerShippingWarning?: InputMaybe<Scalars["String"]["input"]>;
  sellerUuid?: InputMaybe<Scalars["String"]["input"]>;
  /** repeated Offer offers = 2; */
  state?: InputMaybe<core_apimessages_NegotiationState>;
  taxIncluded?: InputMaybe<Scalars["Boolean"]["input"]>;
  taxIncludedHint?: InputMaybe<Scalars["String"]["input"]>;
};

export type Input_core_apimessages_NegotiationOffers = {
  offers?: InputMaybe<Array<InputMaybe<Input_core_apimessages_Offer>>>;
};

export type Input_core_apimessages_NegotiationsRequest = {
  /** negotiations.id */
  ids?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
};

export type Input_core_apimessages_NegotiationsResponse = {
  negotiations?: InputMaybe<
    Array<InputMaybe<Input_core_apimessages_Negotiation>>
  >;
};

export type Input_core_apimessages_NegotiationsShippingLocationRequest = {
  /** negotiations.id */
  ids?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
};

export type Input_core_apimessages_NegotiationsShippingLocationResponse = {
  shippingLocations?: InputMaybe<
    Array<InputMaybe<Input_core_apimessages_Address>>
  >;
};

export type Input_core_apimessages_NewListingSignal = {
  isValid?: InputMaybe<Scalars["Boolean"]["input"]>;
  translations?: InputMaybe<Input_core_apimessages_SignalTranslation>;
};

export type Input_core_apimessages_NodeRequest = {
  debug?: InputMaybe<Scalars["Boolean"]["input"]>;
  lookups?: InputMaybe<
    Array<InputMaybe<Input_core_apimessages_NodeRequest_Lookup>>
  >;
};

export type Input_core_apimessages_NodeRequest_Lookup = {
  lid?: InputMaybe<Scalars["String"]["input"]>;
  params?: InputMaybe<Input_core_apimessages_NodeRequest_Lookup_Params>;
  rel?: InputMaybe<Scalars["String"]["input"]>;
};

export type Input_core_apimessages_NodeRequest_Lookup_Params = {
  estimatedNewListingPrices?: InputMaybe<Input_core_apimessages_NodeRequest_Lookup_Params_EstimatedNewListingPrices>;
  /** Node endpoints default to the field name that corresponds to its class name */
  images?: InputMaybe<Input_core_apimessages_NodeRequest_Lookup_Params_Images>;
  listingsCertifiedPreOwned?: InputMaybe<Input_core_apimessages_NodeRequest_Lookup_Params_ListingsCertifiedPreOwned>;
  listingsPricings?: InputMaybe<Input_core_apimessages_NodeRequest_Lookup_Params_ListingsPricings>;
  productReviews?: InputMaybe<Input_core_apimessages_NodeRequest_Lookup_Params_ProductReviews>;
  sales?: InputMaybe<Input_core_apimessages_NodeRequest_Lookup_Params_Sales>;
  shippingRates?: InputMaybe<Input_core_apimessages_NodeRequest_Lookup_Params_ShippingRates>;
  shopsCertifiedPreOwned?: InputMaybe<Input_core_apimessages_NodeRequest_Lookup_Params_ShopsCertifiedPreOwned>;
  truliooOnboardingRecords?: InputMaybe<Input_core_apimessages_NodeRequest_Lookup_Params_TruliooOnboardingRecords>;
  users?: InputMaybe<Input_core_apimessages_NodeRequest_Lookup_Params_Users>;
};

export type Input_core_apimessages_NodeRequest_Lookup_Params_EstimatedNewListingPrices =
  {
    currency?: InputMaybe<Scalars["String"]["input"]>;
  };

export type Input_core_apimessages_NodeRequest_Lookup_Params_Images = {
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  scope?: InputMaybe<core_apimessages_Image_Scope>;
  transform?: InputMaybe<core_apimessages_NamedImageTransforms>;
};

export type Input_core_apimessages_NodeRequest_Lookup_Params_ListingsCertifiedPreOwned =
  {
    imageType?: InputMaybe<core_apimessages_IconImageType>;
  };

export type Input_core_apimessages_NodeRequest_Lookup_Params_ListingsPricings =
  {
    ribbonPercent?: InputMaybe<Scalars["String"]["input"]>;
    ruleOfOneHundred?: InputMaybe<Scalars["Boolean"]["input"]>;
  };

export type Input_core_apimessages_NodeRequest_Lookup_Params_ProductReviews = {
  forCurrentUser?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type Input_core_apimessages_NodeRequest_Lookup_Params_Sales = {
  all?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type Input_core_apimessages_NodeRequest_Lookup_Params_ShippingRates = {
  inOriginalCurrency?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type Input_core_apimessages_NodeRequest_Lookup_Params_ShopsCertifiedPreOwned =
  {
    imageType?: InputMaybe<core_apimessages_IconImageType>;
  };

export type Input_core_apimessages_NodeRequest_Lookup_Params_TruliooOnboardingRecords =
  {
    recordType?: InputMaybe<core_apimessages_NodeRequest_Lookup_Params_TruliooOnboardingRecords_RecordType>;
  };

export type Input_core_apimessages_NodeRequest_Lookup_Params_Users = {
  fullname?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type Input_core_apimessages_NodeResponse = {
  results?: InputMaybe<
    Array<InputMaybe<Input_core_apimessages_NodeResponse_Result>>
  >;
};

export type Input_core_apimessages_NodeResponse_Result = {
  adCampaigns?: InputMaybe<
    Array<InputMaybe<Input_core_apimessages_AdCampaign>>
  >;
  adGroupKeywords?: InputMaybe<
    Array<InputMaybe<Input_core_apimessages_AdGroupKeyword>>
  >;
  adGroups?: InputMaybe<Array<InputMaybe<Input_core_apimessages_AdGroup>>>;
  adZones?: InputMaybe<Array<InputMaybe<Input_core_apimessages_AdZone>>>;
  addresses?: InputMaybe<Array<InputMaybe<Input_core_apimessages_Address>>>;
  ads?: InputMaybe<Array<InputMaybe<Input_core_apimessages_Ad>>>;
  advertisers?: InputMaybe<
    Array<InputMaybe<Input_core_apimessages_Advertiser>>
  >;
  adyenCheckoutPaymentMethods?: InputMaybe<
    Array<InputMaybe<Input_core_apimessages_AdyenCheckoutPaymentMethods>>
  >;
  brands?: InputMaybe<Array<InputMaybe<Input_core_apimessages_Brand>>>;
  browsePages?: InputMaybe<
    Array<InputMaybe<Input_core_apimessages_BrowsePage>>
  >;
  bumpRates?: InputMaybe<Array<InputMaybe<Input_core_apimessages_BumpRate>>>;
  businessRegistrationNumbers?: InputMaybe<
    Array<InputMaybe<Input_core_apimessages_BusinessRegistrationNumber>>
  >;
  canonicalProducts?: InputMaybe<
    Array<InputMaybe<Input_core_apimessages_CanonicalProduct>>
  >;
  cartItems?: InputMaybe<Array<InputMaybe<Input_core_apimessages_CartItem>>>;
  categories?: InputMaybe<
    Array<InputMaybe<Input_core_apimessages_NodesCategory>>
  >;
  cspExpressSaleItemBids?: InputMaybe<
    Array<InputMaybe<Input_core_apimessages_CSPExpressSaleItemBid>>
  >;
  csps?: InputMaybe<Array<InputMaybe<Input_core_apimessages_CSP>>>;
  curatedSets?: InputMaybe<
    Array<InputMaybe<Input_core_apimessages_CuratedSet>>
  >;
  estimatedNewListingPrices?: InputMaybe<
    Array<InputMaybe<Input_core_apimessages_EstimatedNewListingPrice>>
  >;
  gearCollectionItems?: InputMaybe<
    Array<InputMaybe<Input_core_apimessages_GearCollectionItem>>
  >;
  images?: InputMaybe<Array<InputMaybe<Input_core_apimessages_Image>>>;
  informActThresholdStatuses?: InputMaybe<
    Array<InputMaybe<Input_core_apimessages_InformActThresholdStatus>>
  >;
  internationalTaxProfiles?: InputMaybe<
    Array<InputMaybe<Input_core_apimessages_InternationalTaxProfile>>
  >;
  /** Node endpoints default to the field name that corresponds to its class name */
  listings?: InputMaybe<Array<InputMaybe<Input_core_apimessages_Listing>>>;
  listingsAffirmZeroPercentFinancingConfigurations?: InputMaybe<
    Array<
      InputMaybe<Input_core_apimessages_ListingAffirmZeroPercentFinancingConfiguration>
    >
  >;
  listingsAutoOfferEligibilities?: InputMaybe<
    Array<InputMaybe<Input_core_apimessages_ListingAutoOfferEligibility>>
  >;
  listingsBuyerOfferEligibilities?: InputMaybe<
    Array<InputMaybe<Input_core_apimessages_ListingBuyerOfferEligibility>>
  >;
  listingsCertifiedPreOwned?: InputMaybe<
    Array<InputMaybe<Input_core_apimessages_ListingCertifiedPreOwned>>
  >;
  listingsDigitalDetails?: InputMaybe<
    Array<InputMaybe<Input_core_apimessages_ListingDigitalDetails>>
  >;
  listingsExpressPay?: InputMaybe<
    Array<InputMaybe<Input_core_apimessages_ListingExpressPay>>
  >;
  listingsOfferBotRules?: InputMaybe<
    Array<InputMaybe<Input_core_apimessages_OfferBotRule>>
  >;
  listingsPricings?: InputMaybe<
    Array<InputMaybe<Input_core_apimessages_ListingPricing>>
  >;
  listingsProtectionPlanOptions?: InputMaybe<
    Array<InputMaybe<Input_core_apimessages_ProtectionPlanOptions>>
  >;
  listingsSalesMemberships?: InputMaybe<
    Array<InputMaybe<Input_core_apimessages_ListingSalesMemberships>>
  >;
  listingsSignals?: InputMaybe<
    Array<InputMaybe<Input_core_apimessages_ListingSignals>>
  >;
  listingsUserViews?: InputMaybe<
    Array<InputMaybe<Input_core_apimessages_ListingUserView>>
  >;
  lookup?: InputMaybe<Input_core_apimessages_NodeRequest_Lookup>;
  meta?: InputMaybe<Input_core_apimessages_NodeResponse_Result_Meta>;
  myUpdatesPromotions?: InputMaybe<
    Array<InputMaybe<Input_core_apimessages_MyUpdatesPromotion>>
  >;
  nonUserExperiments?: InputMaybe<
    Array<InputMaybe<Input_core_apimessages_NonUserExperiment>>
  >;
  orderBundles?: InputMaybe<
    Array<InputMaybe<Input_core_apimessages_OrderBundle>>
  >;
  orders?: InputMaybe<Array<InputMaybe<Input_core_apimessages_Order>>>;
  packageSizeSuggestions?: InputMaybe<
    Array<InputMaybe<Input_core_apimessages_PackageSizeSuggestion>>
  >;
  packlinkShipments?: InputMaybe<
    Array<InputMaybe<Input_core_apimessages_PacklinkShipment>>
  >;
  priceChanges?: InputMaybe<
    Array<InputMaybe<Input_core_apimessages_PriceChange>>
  >;
  priceGuides?: InputMaybe<
    Array<InputMaybe<Input_core_apimessages_PriceGuide>>
  >;
  productBreadcrumbs?: InputMaybe<
    Array<InputMaybe<Input_core_apimessages_ProductBreadcrumb>>
  >;
  productReviews?: InputMaybe<
    Array<InputMaybe<Input_core_apimessages_ProductReview>>
  >;
  publicPriceRecords?: InputMaybe<
    Array<InputMaybe<Input_core_apimessages_PublicPriceRecord>>
  >;
  returnPolicies?: InputMaybe<
    Array<InputMaybe<Input_core_apimessages_ReturnPolicy>>
  >;
  sales?: InputMaybe<Array<InputMaybe<Input_core_apimessages_Sale>>>;
  shipmentPackages?: InputMaybe<
    Array<InputMaybe<Input_core_apimessages_ShippingLabelPackage>>
  >;
  shippingRates?: InputMaybe<
    Array<InputMaybe<Input_core_apimessages_ShippingRates>>
  >;
  shopCampaignCoupons?: InputMaybe<
    Array<InputMaybe<Input_core_apimessages_ShopCampaignCoupon>>
  >;
  shops?: InputMaybe<Array<InputMaybe<Input_core_apimessages_Shop>>>;
  shopsCertifiedPreOwned?: InputMaybe<
    Array<InputMaybe<Input_core_apimessages_ShopCertifiedPreOwned>>
  >;
  shopsConfigs?: InputMaybe<
    Array<InputMaybe<Input_core_apimessages_ShopConfig>>
  >;
  siteBanners?: InputMaybe<
    Array<InputMaybe<Input_core_apimessages_SiteBanner>>
  >;
  taxIdentifications?: InputMaybe<
    Array<InputMaybe<Input_core_apimessages_TaxIdentification>>
  >;
  taxProfiles?: InputMaybe<
    Array<InputMaybe<Input_core_apimessages_TaxProfile>>
  >;
  truliooOnboardingRecords?: InputMaybe<
    Array<InputMaybe<Input_core_apimessages_TruliooOnboardingRecord>>
  >;
  universalPromoCampaigns?: InputMaybe<
    Array<InputMaybe<Input_core_apimessages_UniversalPromoCampaign>>
  >;
  userShopPermissions?: InputMaybe<
    Array<InputMaybe<Input_core_apimessages_UserShopPermission>>
  >;
  users?: InputMaybe<Array<InputMaybe<Input_core_apimessages_User>>>;
};

export type Input_core_apimessages_NodeResponse_Result_Meta = {
  total?: InputMaybe<Scalars["Int"]["input"]>;
};

export type Input_core_apimessages_NodesCategory = {
  cmsLink?: InputMaybe<Input_core_apimessages_Link>;
  cmsUrl?: InputMaybe<Input_core_apimessages_Link>;
  /** categories.uuid */
  id?: InputMaybe<Scalars["String"]["input"]>;
  leaf?: InputMaybe<Scalars["Boolean"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  root?: InputMaybe<Scalars["Boolean"]["input"]>;
  rootSlug?: InputMaybe<Scalars["String"]["input"]>;
  slug?: InputMaybe<Scalars["String"]["input"]>;
  webLink?: InputMaybe<Input_core_apimessages_Link>;
};

export type Input_core_apimessages_NonUserExperiment = {
  bucket?: InputMaybe<Scalars["String"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
};

export type Input_core_apimessages_Offer = {
  alerts?: InputMaybe<Array<InputMaybe<Input_core_apimessages_OfferAlert>>>;
  counterable?: InputMaybe<Scalars["Boolean"]["input"]>;
  createdAt?: InputMaybe<Input_core_apimessages_LegacyTimestamp>;
  initiatingParty?: InputMaybe<core_apimessages_NegotiationParty>;
  layawayTerms?: InputMaybe<Input_core_apimessages_LayawayTerms>;
  message?: InputMaybe<Scalars["String"]["input"]>;
  offerItems?: InputMaybe<Array<InputMaybe<Input_core_apimessages_OfferItem>>>;
  prices?: InputMaybe<Input_core_apimessages_OfferPrices>;
  shippingMethod?: InputMaybe<core_apimessages_ShippingMethod>;
  status?: InputMaybe<core_apimessages_Offer_OfferStatus>;
};

export type Input_core_apimessages_OfferAlert = {
  body?: InputMaybe<Scalars["String"]["input"]>;
  level?: InputMaybe<core_apimessages_OfferAlert_Level>;
  title?: InputMaybe<Scalars["String"]["input"]>;
};

export type Input_core_apimessages_OfferBotRule = {
  autoRejectPercentage?: InputMaybe<Scalars["Int"]["input"]>;
};

export type Input_core_apimessages_OfferItem = {
  /** products.id */
  listingId?: InputMaybe<Scalars["String"]["input"]>;
  prices?: InputMaybe<Input_core_apimessages_OfferPrices>;
  quantity?: InputMaybe<Scalars["Int"]["input"]>;
};

export type Input_core_apimessages_OfferItemRequest = {
  /** products.id */
  listingId?: InputMaybe<Scalars["String"]["input"]>;
  price?: InputMaybe<Input_core_apimessages_Money>;
  quantity?: InputMaybe<Scalars["Int"]["input"]>;
  shippingPrice?: InputMaybe<Input_core_apimessages_Money>;
};

export type Input_core_apimessages_OfferPrices = {
  price?: InputMaybe<Input_core_apimessages_ConvertedMoney>;
  shippingPrice?: InputMaybe<Input_core_apimessages_ConvertedMoney>;
  totalPrice?: InputMaybe<Input_core_apimessages_ConvertedMoney>;
};

export type Input_core_apimessages_OffersRequest = {
  /** negotiations.id */
  ids?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
};

export type Input_core_apimessages_OffersResponse = {
  offers?: InputMaybe<
    Array<InputMaybe<Input_core_apimessages_NegotiationOffers>>
  >;
};

export type Input_core_apimessages_OnSaleSignal = {
  isValid?: InputMaybe<Scalars["Boolean"]["input"]>;
  link?: InputMaybe<Input_core_apimessages_SignalLink>;
  translations?: InputMaybe<Input_core_apimessages_SignalTranslation>;
};

export type Input_core_apimessages_Order = {
  amountProduct?: InputMaybe<Input_core_apimessages_Money>;
  amountShipping?: InputMaybe<Input_core_apimessages_Money>;
  amountTax?: InputMaybe<Input_core_apimessages_Money>;
  amountTotal?: InputMaybe<Input_core_apimessages_Money>;
  buyerUuid?: InputMaybe<Scalars["String"]["input"]>;
  carrierCalculatedCarrier?: InputMaybe<Scalars["String"]["input"]>;
  carrierCalculatedShippingAmount?: InputMaybe<Input_core_apimessages_Money>;
  checkoutUuid?: InputMaybe<Scalars["String"]["input"]>;
  createdAt?: InputMaybe<Input_core_apimessages_LegacyTimestamp>;
  /** users.id */
  legacyBuyerId?: InputMaybe<Scalars["String"]["input"]>;
  /** orders.id */
  legacyOrderId?: InputMaybe<Scalars["String"]["input"]>;
  /** products.id */
  listingId?: InputMaybe<Scalars["String"]["input"]>;
  mobileStatus?: InputMaybe<Scalars["String"]["input"]>;
  needsFeedbackForBuyer?: InputMaybe<Scalars["Boolean"]["input"]>;
  needsFeedbackForSeller?: InputMaybe<Scalars["Boolean"]["input"]>;
  orderType?: InputMaybe<Scalars["String"]["input"]>;
  orderUrl?: InputMaybe<Input_core_apimessages_Link>;
  paidAt?: InputMaybe<Input_core_apimessages_LegacyTimestamp>;
  paymentRequiredAt?: InputMaybe<Input_core_apimessages_LegacyTimestamp>;
  protectionPlan?: InputMaybe<Scalars["Boolean"]["input"]>;
  protectionPlanEligible?: InputMaybe<Scalars["Boolean"]["input"]>;
  quantity?: InputMaybe<Scalars["Int"]["input"]>;
  sellerUuid?: InputMaybe<Scalars["String"]["input"]>;
  settlementAmountProductSubtotal?: InputMaybe<Input_core_apimessages_Money>;
  shipmentStatus?: InputMaybe<Scalars["String"]["input"]>;
  shippedAt?: InputMaybe<Input_core_apimessages_LegacyTimestamp>;
  shippingCode?: InputMaybe<Scalars["String"]["input"]>;
  shippingMethod?: InputMaybe<core_apimessages_ShippingMethod>;
  shippingProvider?: InputMaybe<Scalars["String"]["input"]>;
  shippingTaxed?: InputMaybe<Scalars["Boolean"]["input"]>;
  shopUuid?: InputMaybe<Scalars["String"]["input"]>;
  status?: InputMaybe<core_apimessages_Order_Status>;
  statusDescription?: InputMaybe<Scalars["String"]["input"]>;
  title?: InputMaybe<Scalars["String"]["input"]>;
  updatedAt?: InputMaybe<Input_core_apimessages_LegacyTimestamp>;
  uuid?: InputMaybe<Scalars["String"]["input"]>;
  webTrackingUrl?: InputMaybe<Scalars["String"]["input"]>;
};

export type Input_core_apimessages_OrderBundle = {
  amountCredit?: InputMaybe<Input_core_apimessages_Money>;
  amountProductSubtotal?: InputMaybe<Input_core_apimessages_Money>;
  amountShipping?: InputMaybe<Input_core_apimessages_Money>;
  amountTax?: InputMaybe<Input_core_apimessages_Money>;
  amountTotal?: InputMaybe<Input_core_apimessages_Money>;
  bonusBucks?: InputMaybe<Input_core_apimessages_Money>;
  checkoutType?: InputMaybe<core_apimessages_Checkout_Type>;
  /** order_bundles.id */
  id?: InputMaybe<Scalars["String"]["input"]>;
  localPickup?: InputMaybe<Scalars["Boolean"]["input"]>;
  orderType?: InputMaybe<Scalars["String"]["input"]>;
  paymentMethod?: InputMaybe<core_apimessages_Payment_PaymentMethod>;
  /** order_bundles.checkout_uuid */
  uuid?: InputMaybe<Scalars["String"]["input"]>;
};

export type Input_core_apimessages_OrderBundleShowResponse = {
  orderBundle?: InputMaybe<Input_core_apimessages_OrderBundle>;
};

export type Input_core_apimessages_OrderCustoms = {
  countryOfOrigin?: InputMaybe<Scalars["String"]["input"]>;
  declaredValue?: InputMaybe<Input_core_apimessages_Money>;
  description?: InputMaybe<Scalars["String"]["input"]>;
  quantity?: InputMaybe<Scalars["Int"]["input"]>;
  tariffNumber?: InputMaybe<Scalars["String"]["input"]>;
  uuid?: InputMaybe<Scalars["String"]["input"]>;
};

export type Input_core_apimessages_OrderCustomsInfo = {
  categoryName?: InputMaybe<Scalars["String"]["input"]>;
  countryOfOrigin?: InputMaybe<Scalars["String"]["input"]>;
  description?: InputMaybe<Scalars["String"]["input"]>;
  tariffNumber?: InputMaybe<Scalars["String"]["input"]>;
};

export type Input_core_apimessages_OrderFeedbacks = {
  feedbacks?: InputMaybe<Array<InputMaybe<Input_core_apimessages_Feedback>>>;
  orderUuid?: InputMaybe<Scalars["String"]["input"]>;
};

export type Input_core_apimessages_OrderFeedbacksRequest = {
  orderIds?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  orderUuids?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
};

export type Input_core_apimessages_OrderFeedbacksResponse = {
  orderFeedbacks?: InputMaybe<
    Array<InputMaybe<Input_core_apimessages_OrderFeedbacks>>
  >;
};

export type Input_core_apimessages_OrderFlatRateShippingOptionsRequest = {
  checkoutUuid?: InputMaybe<Scalars["String"]["input"]>;
  orderUuid?: InputMaybe<Scalars["String"]["input"]>;
};

export type Input_core_apimessages_OrderFlatRateShippingOptionsResponse = {
  flatRateShippingOptions?: InputMaybe<
    Array<InputMaybe<Input_core_apimessages_FlatRateShippingOption>>
  >;
};

export type Input_core_apimessages_OrderNote = {
  body?: InputMaybe<Scalars["String"]["input"]>;
  createdAt?: InputMaybe<Input_core_apimessages_LegacyTimestamp>;
  /** order_notes.id */
  id?: InputMaybe<Scalars["String"]["input"]>;
  /** orders.id */
  orderId?: InputMaybe<Scalars["String"]["input"]>;
};

export type Input_core_apimessages_OrderNotes = {
  notes?: InputMaybe<Array<InputMaybe<Input_core_apimessages_OrderNote>>>;
};

export type Input_core_apimessages_OrderNotesIndexRequest = {
  orderIds?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  orderUuids?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
};

export type Input_core_apimessages_OrderNotesIndexResponse = {
  orderNotes?: InputMaybe<Array<InputMaybe<Input_core_apimessages_OrderNotes>>>;
};

/**
 *   This endpoint is not used with GRPC or with RQL, which is not recommended
 * Do not use this endpoint directly, instead query for order notes using RQL
 */
export type Input_core_apimessages_OrderNotesRequest = {
  orderIds?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
};

export type Input_core_apimessages_OrderNotesResponse = {
  orderNotes?: InputMaybe<Array<InputMaybe<Input_core_apimessages_OrderNote>>>;
};

export type Input_core_apimessages_OrderPackingSlip = {
  packingSlipWebUrl?: InputMaybe<Scalars["String"]["input"]>;
};

export type Input_core_apimessages_OrderPayments = {
  payments?: InputMaybe<Array<InputMaybe<Input_core_apimessages_Payment>>>;
};

export type Input_core_apimessages_OrderPaymentsRequest = {
  orderIds?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  orderUuids?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
};

export type Input_core_apimessages_OrderPaymentsResponse = {
  orderPayments?: InputMaybe<
    Array<InputMaybe<Input_core_apimessages_OrderPayments>>
  >;
};

export type Input_core_apimessages_OrderProtectionPlanDetails = {
  activationLink?: InputMaybe<Input_core_apimessages_Link>;
  associatedCoveredProductOrderId?: InputMaybe<Scalars["String"]["input"]>;
  associatedProtectionPlanOrderId?: InputMaybe<Scalars["String"]["input"]>;
  buyerEmail?: InputMaybe<Scalars["String"]["input"]>;
};

export type Input_core_apimessages_OrderRefundRequests = {
  refundRequests?: InputMaybe<
    Array<InputMaybe<Input_core_apimessages_RefundRequest>>
  >;
};

export type Input_core_apimessages_OrderRefunds = {
  completedRefunds?: InputMaybe<
    Array<InputMaybe<Input_core_apimessages_Refund>>
  >;
  refundRequests?: InputMaybe<
    Array<InputMaybe<Input_core_apimessages_RefundRequest>>
  >;
  refundableState?: InputMaybe<core_apimessages_OrderRefunds_RefundableState>;
};

export type Input_core_apimessages_OrderRefundsRequest = {
  orderIds?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  orderUuids?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
};

export type Input_core_apimessages_OrderRefundsResponse = {
  orderRefunds?: InputMaybe<
    Array<InputMaybe<Input_core_apimessages_OrderRefunds>>
  >;
};

export type Input_core_apimessages_OrderShippingAddressesRequest = {
  orderIds?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  orderUuids?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
};

export type Input_core_apimessages_OrderShippingAddressesResponse = {
  addresses?: InputMaybe<Array<InputMaybe<Input_core_apimessages_Address>>>;
};

export type Input_core_apimessages_OrderShippingLabel = {
  purchaseShippingLabelWebUrl?: InputMaybe<Scalars["String"]["input"]>;
  shippingLabelWebUrl?: InputMaybe<Scalars["String"]["input"]>;
};

export type Input_core_apimessages_OrderStats = {
  isViewable?: InputMaybe<Scalars["Boolean"]["input"]>;
  listingId?: InputMaybe<Scalars["String"]["input"]>;
  totalOrders?: InputMaybe<Scalars["Int"]["input"]>;
};

export type Input_core_apimessages_OrderVerifyShippingAddressRequest = {
  checkoutUuid?: InputMaybe<Scalars["String"]["input"]>;
  orderUuid?: InputMaybe<Scalars["String"]["input"]>;
};

export type Input_core_apimessages_OrderVerifyShippingAddressResponse = {
  address?: InputMaybe<Input_core_apimessages_Address>;
  errorMessage?: InputMaybe<Scalars["String"]["input"]>;
  isResidential?: InputMaybe<Scalars["Boolean"]["input"]>;
  verificationStatus?: InputMaybe<core_apimessages_AddressVerificationStatus>;
};

export type Input_core_apimessages_OrderViewStatus = {
  description?: InputMaybe<Scalars["String"]["input"]>;
  status?: InputMaybe<Scalars["String"]["input"]>;
};

export type Input_core_apimessages_OrdersAddressRequest = {
  addressType?: InputMaybe<Scalars["String"]["input"]>;
  uuids?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
};

export type Input_core_apimessages_OrdersAddressResponse = {
  addresses?: InputMaybe<Array<InputMaybe<Input_core_apimessages_Address>>>;
};

export type Input_core_apimessages_OrdersCustomsInfoRequest = {
  ids?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  uuids?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
};

export type Input_core_apimessages_OrdersCustomsInfoResponse = {
  ordersCustomsInfo?: InputMaybe<
    Array<InputMaybe<Input_core_apimessages_OrderCustomsInfo>>
  >;
};

export type Input_core_apimessages_OrdersRequest = {
  ids?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  uuids?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
};

export type Input_core_apimessages_OrdersResponse = {
  orders?: InputMaybe<Array<InputMaybe<Input_core_apimessages_Order>>>;
};

export type Input_core_apimessages_OtherBuyersWithListingInCartCountsRequest = {
  /** product.id */
  ids?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
};

export type Input_core_apimessages_OtherBuyersWithListingInCartCountsResponse =
  {
    listingInCartCount?: InputMaybe<
      Array<InputMaybe<Input_core_apimessages_ListingInCartCount>>
    >;
  };

export type Input_core_apimessages_PackageSizeSuggestion = {
  height?: InputMaybe<Input_core_apimessages_Measurement>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  length?: InputMaybe<Input_core_apimessages_Measurement>;
  weight?: InputMaybe<Input_core_apimessages_Measurement>;
  width?: InputMaybe<Input_core_apimessages_Measurement>;
};

export type Input_core_apimessages_PacklinkAccount = {
  email?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  status?: InputMaybe<core_apimessages_PacklinkAccount_Status>;
};

export type Input_core_apimessages_PacklinkPolicies = {
  dataProcessing?: InputMaybe<Scalars["Boolean"]["input"]>;
  marketingCalls?: InputMaybe<Scalars["Boolean"]["input"]>;
  marketingEmails?: InputMaybe<Scalars["Boolean"]["input"]>;
  termsAndConditions?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type Input_core_apimessages_PacklinkShipment = {
  id?: InputMaybe<Scalars["String"]["input"]>;
  orderId?: InputMaybe<Scalars["String"]["input"]>;
  packlinkAccountId?: InputMaybe<Scalars["String"]["input"]>;
  reference?: InputMaybe<Scalars["String"]["input"]>;
  status?: InputMaybe<core_apimessages_PacklinkShipment_Status>;
};

export type Input_core_apimessages_PayMyStatementRequest = {
  creditCardId?: InputMaybe<Scalars["String"]["input"]>;
  payment?: InputMaybe<Input_core_apimessages_MoneyMajorUnitsEntry>;
  statementId?: InputMaybe<Scalars["String"]["input"]>;
};

export type Input_core_apimessages_PayMyStatementResponse = {
  amountPaidCents?: InputMaybe<Scalars["Int"]["input"]>;
  statementBalance?: InputMaybe<Scalars["Int"]["input"]>;
  statementId?: InputMaybe<Scalars["String"]["input"]>;
};

export type Input_core_apimessages_Payment = {
  amount?: InputMaybe<Input_core_apimessages_Money>;
  paymentMethod?: InputMaybe<core_apimessages_Payment_PaymentMethod>;
  paymentStatus?: InputMaybe<core_apimessages_Payment_PaymentStatus>;
};

/** Card-shaped icon for a payment method */
export type Input_core_apimessages_PaymentCardIcon = {
  description?: InputMaybe<Scalars["String"]["input"]>;
  slug?: InputMaybe<Scalars["String"]["input"]>;
  url?: InputMaybe<Scalars["String"]["input"]>;
};

export type Input_core_apimessages_PaymentMethods = {
  acceptedPaymentMethods?: InputMaybe<
    Array<InputMaybe<Scalars["String"]["input"]>>
  >;
  /** either shops.uuid or products.id */
  itemId?: InputMaybe<Scalars["String"]["input"]>;
  itemType?: InputMaybe<Scalars["String"]["input"]>;
};

export type Input_core_apimessages_PaymentPlanSignal = {
  isValid?: InputMaybe<Scalars["Boolean"]["input"]>;
  translations?: InputMaybe<Input_core_apimessages_SignalTranslation>;
};

export type Input_core_apimessages_PaypalOnboardSellerRequest = {
  _?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type Input_core_apimessages_PaypalOnboardSellerResponse = {
  actionUrl?: InputMaybe<Scalars["String"]["input"]>;
  /** paypal_profiles.id */
  id?: InputMaybe<Scalars["String"]["input"]>;
};

export type Input_core_apimessages_PredictedToSellSoonSignal = {
  isValid?: InputMaybe<Scalars["Boolean"]["input"]>;
  translations?: InputMaybe<Input_core_apimessages_SignalTranslation>;
};

export type Input_core_apimessages_PreorderInfo = {
  estimatedShipDate?: InputMaybe<Input_core_apimessages_LegacyTimestamp>;
  /** preorder_infos.id */
  id?: InputMaybe<Scalars["String"]["input"]>;
  leadTimeDays?: InputMaybe<Scalars["Int"]["input"]>;
  onPreorder?: InputMaybe<Scalars["Boolean"]["input"]>;
  shipDate?: InputMaybe<Input_core_apimessages_LegacyTimestamp>;
};

export type Input_core_apimessages_PreorderInfoEntry = {
  leadTimeDays?: InputMaybe<Scalars["Int"]["input"]>;
  leadTimeUnit?: InputMaybe<core_apimessages_PreorderInfoEntry_LeadTimeUnit>;
  shipDate?: InputMaybe<Scalars["String"]["input"]>;
  timeType?: InputMaybe<core_apimessages_PreorderInfoEntry_TimeType>;
};

export type Input_core_apimessages_PreorderInfoRequest = {
  listingIds?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
};

export type Input_core_apimessages_PreorderInfoResponse = {
  preorderInfo?: InputMaybe<
    Array<InputMaybe<Input_core_apimessages_PreorderInfo>>
  >;
};

export type Input_core_apimessages_PrepublishStatusResponse = {
  nextStep?: InputMaybe<core_apimessages_PrepublishStatusResponse_PrepublishStatus>;
  nextSteps?: InputMaybe<
    Array<
      InputMaybe<core_apimessages_PrepublishStatusResponse_PrepublishStatus>
    >
  >;
};

export type Input_core_apimessages_PriceChange = {
  currentPriceDropPercent?: InputMaybe<Scalars["Int"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  listingId?: InputMaybe<Scalars["String"]["input"]>;
  newPrice?: InputMaybe<Input_core_apimessages_Money>;
  previousPrice?: InputMaybe<Input_core_apimessages_Money>;
  totalPriceDropPercent?: InputMaybe<Scalars["Int"]["input"]>;
};

export type Input_core_apimessages_PriceDropSignal = {
  isValid?: InputMaybe<Scalars["Boolean"]["input"]>;
  translations?: InputMaybe<Input_core_apimessages_SignalTranslation>;
};

export type Input_core_apimessages_PriceGuide = {
  /** price_guides.id */
  id?: InputMaybe<Scalars["String"]["input"]>;
  priceHigh?: InputMaybe<Input_core_apimessages_Money>;
  priceLow?: InputMaybe<Input_core_apimessages_Money>;
  title?: InputMaybe<Scalars["String"]["input"]>;
  uuid?: InputMaybe<Scalars["String"]["input"]>;
  webLink?: InputMaybe<Input_core_apimessages_Link>;
};

export type Input_core_apimessages_PriceRecommendation = {
  canonicalProductId?: InputMaybe<Scalars["String"]["input"]>;
  comparisonShoppingPageId?: InputMaybe<Scalars["String"]["input"]>;
  conditionUuid?: InputMaybe<Scalars["String"]["input"]>;
  countryCode?: InputMaybe<Scalars["String"]["input"]>;
  priceHigh?: InputMaybe<Input_core_apimessages_Money>;
  priceHighThirtyDaysAgo?: InputMaybe<Input_core_apimessages_Money>;
  priceLow?: InputMaybe<Input_core_apimessages_Money>;
  priceLowThirtyDaysAgo?: InputMaybe<Input_core_apimessages_Money>;
  priceMiddle?: InputMaybe<Input_core_apimessages_Money>;
  priceMiddleThirtyDaysAgo?: InputMaybe<Input_core_apimessages_Money>;
};

export type Input_core_apimessages_PrimaryKey = {
  type?: InputMaybe<core_apimessages_PrimaryKey_Type>;
  value?: InputMaybe<Scalars["String"]["input"]>;
};

export type Input_core_apimessages_ProcessApprovedPaypalOrderRequest = {
  /** checkout UUID */
  id?: InputMaybe<Scalars["String"]["input"]>;
};

export type Input_core_apimessages_ProcessApprovedPaypalOrderResponse = {
  /** checkout UUID */
  id?: InputMaybe<Scalars["String"]["input"]>;
};

export type Input_core_apimessages_ProcessingFees = {
  flatFee?: InputMaybe<Input_core_apimessages_Money>;
  percent?: InputMaybe<Scalars["String"]["input"]>;
};

export type Input_core_apimessages_ProductBreadcrumb = {
  title?: InputMaybe<Scalars["String"]["input"]>;
  url?: InputMaybe<Scalars["String"]["input"]>;
};

export type Input_core_apimessages_ProductReview = {
  body?: InputMaybe<Scalars["String"]["input"]>;
  createdAt?: InputMaybe<Scalars["Timestamp"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  isMyReview?: InputMaybe<Scalars["Boolean"]["input"]>;
  rating?: InputMaybe<Scalars["Int"]["input"]>;
  reported?: InputMaybe<Scalars["Boolean"]["input"]>;
  reviewer?: InputMaybe<Input_core_apimessages_Reviewer>;
  title?: InputMaybe<Scalars["String"]["input"]>;
  verified?: InputMaybe<Scalars["Boolean"]["input"]>;
  voteCount?: InputMaybe<Scalars["Int"]["input"]>;
  voted?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type Input_core_apimessages_ProductReviewVoteRequest = {
  id?: InputMaybe<Scalars["String"]["input"]>;
};

export type Input_core_apimessages_ProductReviewVoteResponse = {
  id?: InputMaybe<Scalars["String"]["input"]>;
};

export type Input_core_apimessages_ProductSpec = {
  id?: InputMaybe<Scalars["String"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  path?: InputMaybe<Scalars["String"]["input"]>;
  slug?: InputMaybe<Scalars["String"]["input"]>;
  type?: InputMaybe<core_apimessages_ProductSpec_Type>;
  value?: InputMaybe<Scalars["String"]["input"]>;
};

export type Input_core_apimessages_ProtectionPlan = {
  amount?: InputMaybe<Input_core_apimessages_Money>;
  condensedTitle?: InputMaybe<Scalars["String"]["input"]>;
  durationMonths?: InputMaybe<Scalars["String"]["input"]>;
  planCoverageDetails?: InputMaybe<
    Array<InputMaybe<Scalars["String"]["input"]>>
  >;
  planId?: InputMaybe<Scalars["String"]["input"]>;
  termsUrl?: InputMaybe<Scalars["String"]["input"]>;
  title?: InputMaybe<Scalars["String"]["input"]>;
};

export type Input_core_apimessages_ProtectionPlanOptions = {
  availablePlans?: InputMaybe<
    Array<InputMaybe<Input_core_apimessages_ProtectionPlan>>
  >;
  coverageDetailsSubheader?: InputMaybe<Scalars["String"]["input"]>;
  protectionHighlights?: InputMaybe<
    Array<InputMaybe<Scalars["String"]["input"]>>
  >;
  protectionPlanCalloutTitle?: InputMaybe<Scalars["String"]["input"]>;
};

export type Input_core_apimessages_PublicPriceRecord = {
  amountProduct?: InputMaybe<Input_core_apimessages_Money>;
  amountShipping?: InputMaybe<Input_core_apimessages_Money>;
  amountTax?: InputMaybe<Input_core_apimessages_Money>;
  amountTotal?: InputMaybe<Input_core_apimessages_Money>;
  condition?: InputMaybe<Input_core_apimessages_Condition>;
  createdAt?: InputMaybe<Input_core_apimessages_LegacyTimestamp>;
  listingId?: InputMaybe<Scalars["String"]["input"]>;
  orderType?: InputMaybe<Scalars["String"]["input"]>;
  settlementAmountProductSubtotal?: InputMaybe<Input_core_apimessages_Money>;
  uuid?: InputMaybe<Scalars["String"]["input"]>;
};

export type Input_core_apimessages_PublishAllListingsResponse = {
  enqueued?: InputMaybe<Scalars["Boolean"]["input"]>;
  message?: InputMaybe<Scalars["String"]["input"]>;
  prepublishStepRequired?: InputMaybe<Input_core_apimessages_PublishAllListingsResponse_PrepublishStepRequired>;
};

export type Input_core_apimessages_PublishAllListingsResponse_PrepublishStepRequired =
  {
    step?: InputMaybe<Scalars["String"]["input"]>;
  };

export type Input_core_apimessages_PurchaseShippingRateRequest = {
  acceptedCarrierTerms?: InputMaybe<Scalars["Boolean"]["input"]>;
  rateUuid?: InputMaybe<Scalars["String"]["input"]>;
  shipmentUuid?: InputMaybe<Scalars["String"]["input"]>;
};

export type Input_core_apimessages_PurchaseShippingRateResponse = {
  errors?: InputMaybe<Array<InputMaybe<Input_core_apimessages_Error>>>;
};

export type Input_core_apimessages_RareGearSignal = {
  isValid?: InputMaybe<Scalars["Boolean"]["input"]>;
  translations?: InputMaybe<Input_core_apimessages_SignalTranslation>;
};

export type Input_core_apimessages_RecentSearchesResponse = {
  queries?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
};

export type Input_core_apimessages_RecentlyViewedListingsRequest = {
  limit?: InputMaybe<Scalars["Int"]["input"]>;
};

export type Input_core_apimessages_RecentlyViewedListingsResponse = {
  listingIds?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
};

export type Input_core_apimessages_Recommendation = {
  id?: InputMaybe<Scalars["String"]["input"]>;
  type?: InputMaybe<core_apimessages_Recommendation_RecommendationType>;
};

export type Input_core_apimessages_RecommendationsRequest = {
  cpIds?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  listingIds?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  multiClientExperiments?: InputMaybe<
    Array<InputMaybe<Input_core_apimessages_ExperimentData>>
  >;
};

export type Input_core_apimessages_RecommendationsResponse = {
  recommendationEngineInputIds?: InputMaybe<
    Array<InputMaybe<Scalars["String"]["input"]>>
  >;
  recommendationEngineOutputIds?: InputMaybe<
    Array<InputMaybe<Scalars["String"]["input"]>>
  >;
  recommendations?: InputMaybe<
    Array<InputMaybe<Input_core_apimessages_Recommendation>>
  >;
};

export type Input_core_apimessages_RedeemPromotionalCodeRequest = {
  token?: InputMaybe<Scalars["String"]["input"]>;
};

export type Input_core_apimessages_RedeemPromotionalCodeResponse = {
  message?: InputMaybe<Scalars["String"]["input"]>;
};

export type Input_core_apimessages_ReferAFriend = {
  minOrderAmount?: InputMaybe<Input_core_apimessages_Money>;
  rewardAmount?: InputMaybe<Input_core_apimessages_Money>;
  shareUrl?: InputMaybe<Scalars["String"]["input"]>;
  signUpBonus?: InputMaybe<Input_core_apimessages_Money>;
};

export type Input_core_apimessages_ReferAFriendResponse = {
  referAFriend?: InputMaybe<Input_core_apimessages_ReferAFriend>;
};

export type Input_core_apimessages_Refund = {
  amount?: InputMaybe<Input_core_apimessages_ConvertedMoney>;
  createdAt?: InputMaybe<Input_core_apimessages_LegacyTimestamp>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  noteToBuyer?: InputMaybe<Scalars["String"]["input"]>;
  updatedAt?: InputMaybe<Input_core_apimessages_LegacyTimestamp>;
};

export type Input_core_apimessages_RefundRequest = {
  amount?: InputMaybe<Input_core_apimessages_Money>;
  createdAt?: InputMaybe<Input_core_apimessages_LegacyTimestamp>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  markItemReturnedWebUrl?: InputMaybe<Scalars["String"]["input"]>;
  respondToRequestWebUrl?: InputMaybe<Scalars["String"]["input"]>;
  state?: InputMaybe<core_apimessages_RefundRequest_RefundState>;
  type?: InputMaybe<core_apimessages_RefundRequest_RefundType>;
  updatedAt?: InputMaybe<Input_core_apimessages_LegacyTimestamp>;
};

export type Input_core_apimessages_RegionalShippingRate = {
  areas?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  rate?: InputMaybe<Input_core_apimessages_Money>;
};

export type Input_core_apimessages_RegisterMyPacklinkAccountRequest = {
  email?: InputMaybe<Scalars["String"]["input"]>;
  password?: InputMaybe<Scalars["String"]["input"]>;
  policies?: InputMaybe<Input_core_apimessages_PacklinkPolicies>;
};

export type Input_core_apimessages_RegisterMyPacklinkAccountResponse = {
  shopId?: InputMaybe<Scalars["String"]["input"]>;
};

export type Input_core_apimessages_RequestAccountDeletionRequest = {
  reason?: InputMaybe<Scalars["String"]["input"]>;
};

export type Input_core_apimessages_RequestAccountDeletionResponse = {
  uuid?: InputMaybe<Scalars["String"]["input"]>;
};

export type Input_core_apimessages_RequestTaxFormDownloadRequest = {
  taxFormId?: InputMaybe<Scalars["String"]["input"]>;
};

export type Input_core_apimessages_RequestTaxFormDownloadResponse = {
  error?: InputMaybe<Scalars["String"]["input"]>;
  taxFormDownload?: InputMaybe<Input_core_apimessages_TaxFormDownload>;
};

export type Input_core_apimessages_ReturnPolicy = {
  id?: InputMaybe<Scalars["String"]["input"]>;
  legacyReturnPolicyText?: InputMaybe<Scalars["String"]["input"]>;
  newReturnWindowDays?: InputMaybe<Scalars["Int"]["input"]>;
  restockingFeePercent?: InputMaybe<Scalars["Int"]["input"]>;
  specialConditions?: InputMaybe<Scalars["String"]["input"]>;
  usedReturnWindowDays?: InputMaybe<Scalars["Int"]["input"]>;
};

export type Input_core_apimessages_ReturnPolicySignal = {
  isValid?: InputMaybe<Scalars["Boolean"]["input"]>;
  link?: InputMaybe<Input_core_apimessages_SignalLink>;
  translations?: InputMaybe<Input_core_apimessages_SignalTranslation>;
};

export type Input_core_apimessages_ReverifyCreditCardRequest = {
  creditCardId?: InputMaybe<Scalars["String"]["input"]>;
  tokenizedFields?: InputMaybe<Scalars["String"]["input"]>;
};

export type Input_core_apimessages_ReverifyCreditCardResponse = {
  creditCard?: InputMaybe<Input_core_apimessages_CreditCard>;
};

export type Input_core_apimessages_Reviewer = {
  links?: InputMaybe<Input_core_apimessages_ReviewerLinks>;
  shortname?: InputMaybe<Scalars["String"]["input"]>;
};

export type Input_core_apimessages_ReviewerLinks = {
  avatar?: InputMaybe<Input_core_apimessages_Link>;
};

export type Input_core_apimessages_Ribbon = {
  display?: InputMaybe<Scalars["String"]["input"]>;
  displayReason?: InputMaybe<Scalars["String"]["input"]>;
  reason?: InputMaybe<core_apimessages_Ribbon_Reason>;
};

export type Input_core_apimessages_Sale = {
  buyerIneligibilityReason?: InputMaybe<Scalars["String"]["input"]>;
  code?: InputMaybe<Scalars["String"]["input"]>;
  discountValue?: InputMaybe<Scalars["String"]["input"]>;
  display?: InputMaybe<Scalars["String"]["input"]>;
  endsAt?: InputMaybe<Input_core_apimessages_LegacyTimestamp>;
  /** sales.id */
  id?: InputMaybe<Scalars["String"]["input"]>;
  links?: InputMaybe<Input_core_apimessages_SaleLinks>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  percent?: InputMaybe<Scalars["Int"]["input"]>;
  saleType?: InputMaybe<Scalars["String"]["input"]>;
  shopName?: InputMaybe<Scalars["String"]["input"]>;
  shopSlug?: InputMaybe<Scalars["String"]["input"]>;
  showCode?: InputMaybe<Scalars["Boolean"]["input"]>;
  slug?: InputMaybe<Scalars["String"]["input"]>;
  startsAt?: InputMaybe<Input_core_apimessages_LegacyTimestamp>;
  state?: InputMaybe<core_apimessages_Sale_SaleState>;
  summary?: InputMaybe<Scalars["String"]["input"]>;
  withSalePriceDisplay?: InputMaybe<Scalars["String"]["input"]>;
};

export type Input_core_apimessages_SaleLinks = {
  self?: InputMaybe<Input_core_apimessages_Link>;
};

export type Input_core_apimessages_SaveMyPlaidAccountRequest = {
  accountId?: InputMaybe<Scalars["String"]["input"]>;
  countryCode?: InputMaybe<Scalars["String"]["input"]>;
  publicToken?: InputMaybe<Scalars["String"]["input"]>;
  verificationStatus?: InputMaybe<Scalars["String"]["input"]>;
};

export type Input_core_apimessages_SaveMyPlaidAccountResponse = {
  plaidAccountId?: InputMaybe<Scalars["String"]["input"]>;
};

export type Input_core_apimessages_ScheduledPriceDrop = {
  active?: InputMaybe<Scalars["Boolean"]["input"]>;
  dropAt?: InputMaybe<Scalars["Timestamp"]["input"]>;
  newPrice?: InputMaybe<Input_core_apimessages_Money>;
};

export type Input_core_apimessages_ScheduledPriceDropEntry = {
  active?: InputMaybe<Scalars["Boolean"]["input"]>;
  newPrice?: InputMaybe<Input_core_apimessages_Money>;
};

export type Input_core_apimessages_SearchData = {
  imageUrl?: InputMaybe<Scalars["String"]["input"]>;
  queryParams?: InputMaybe<Scalars["String"]["input"]>;
  title?: InputMaybe<Scalars["String"]["input"]>;
};

export type Input_core_apimessages_SearchFilterPreset = {
  /** postgres id (polymorphic with 'collection_type') */
  collectionId?: InputMaybe<Scalars["String"]["input"]>;
  collectionType?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  title?: InputMaybe<Scalars["String"]["input"]>;
  url?: InputMaybe<Scalars["String"]["input"]>;
};

export type Input_core_apimessages_SearchFilterPresetsRequest = {
  categoryUuids?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  /** UUIDs */
  collectionIds?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  collectionSlugs?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  collectionType?: InputMaybe<core_apimessages_CollectionHeader_CollectionType>;
};

export type Input_core_apimessages_SearchFilterPresetsResponse = {
  searchFilterPresets?: InputMaybe<
    Array<InputMaybe<Input_core_apimessages_ItemSearchFilterPresets>>
  >;
};

export type Input_core_apimessages_SearchMetadataRequest = {
  brandSlugs?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  categorySlugs?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  conditionSlugs?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  curatedSetSlug?: InputMaybe<Scalars["String"]["input"]>;
  query?: InputMaybe<Scalars["String"]["input"]>;
  searchUrlParams?: InputMaybe<Scalars["String"]["input"]>;
  shopSlug?: InputMaybe<Scalars["String"]["input"]>;
  yearMax?: InputMaybe<Scalars["String"]["input"]>;
  yearMin?: InputMaybe<Scalars["String"]["input"]>;
};

export type Input_core_apimessages_SearchMetadataResponse = {
  title?: InputMaybe<Scalars["String"]["input"]>;
};

export type Input_core_apimessages_SecureCheckoutSignal = {
  isValid?: InputMaybe<Scalars["Boolean"]["input"]>;
  translations?: InputMaybe<Input_core_apimessages_SignalTranslation>;
};

export type Input_core_apimessages_SeedListingRequest = {
  seedId?: InputMaybe<Scalars["String"]["input"]>;
  seedType?: InputMaybe<core_apimessages_Listing_SeedType>;
};

export type Input_core_apimessages_SeedListingResponse = {
  listing?: InputMaybe<Input_core_apimessages_SeededListing>;
};

export type Input_core_apimessages_SeededListing = {
  brandSlug?: InputMaybe<Scalars["String"]["input"]>;
  canonicalProductId?: InputMaybe<Scalars["String"]["input"]>;
  categoryRootUuid?: InputMaybe<Scalars["String"]["input"]>;
  categoryUuids?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  comparisonShoppingPageId?: InputMaybe<Scalars["String"]["input"]>;
  conditionSlug?: InputMaybe<Scalars["String"]["input"]>;
  countryOfOrigin?: InputMaybe<Scalars["String"]["input"]>;
  description?: InputMaybe<Scalars["String"]["input"]>;
  finish?: InputMaybe<Scalars["String"]["input"]>;
  handmade?: InputMaybe<Scalars["Boolean"]["input"]>;
  make?: InputMaybe<Scalars["String"]["input"]>;
  model?: InputMaybe<Scalars["String"]["input"]>;
  productTypeId?: InputMaybe<Scalars["String"]["input"]>;
  seedId?: InputMaybe<Scalars["String"]["input"]>;
  seedType?: InputMaybe<core_apimessages_Listing_SeedType>;
  sellerCost?: InputMaybe<Input_core_apimessages_Money>;
  sellerPrice?: InputMaybe<Input_core_apimessages_Money>;
  shippingProfileId?: InputMaybe<Scalars["String"]["input"]>;
  soldAsIs?: InputMaybe<Scalars["Boolean"]["input"]>;
  subcategoryLeafUuids?: InputMaybe<
    Array<InputMaybe<Scalars["String"]["input"]>>
  >;
  title?: InputMaybe<Scalars["String"]["input"]>;
  year?: InputMaybe<Scalars["String"]["input"]>;
};

export type Input_core_apimessages_SelectAddressAutocompleteSuggestionRequest =
  {
    /** loqate address id */
    id?: InputMaybe<Scalars["String"]["input"]>;
  };

export type Input_core_apimessages_SelectAddressAutocompleteSuggestionResponse =
  {
    suggestion?: InputMaybe<Input_core_apimessages_Address>;
  };

export type Input_core_apimessages_SelectCheckoutCreditCardRequest = {
  creditCardId?: InputMaybe<Scalars["String"]["input"]>;
  /** checkout UUID */
  id?: InputMaybe<Scalars["String"]["input"]>;
};

export type Input_core_apimessages_SelectCheckoutCreditCardResponse = {
  /** checkout UUID */
  id?: InputMaybe<Scalars["String"]["input"]>;
};

export type Input_core_apimessages_SelectCheckoutPaymentMethodRequest = {
  /** checkout UUID */
  id?: InputMaybe<Scalars["String"]["input"]>;
  paymentMethod?: InputMaybe<core_apimessages_CheckoutPaymentMethod_Type>;
};

export type Input_core_apimessages_SelectCheckoutPaymentMethodResponse = {
  /** checkout UUID */
  id?: InputMaybe<Scalars["String"]["input"]>;
};

export type Input_core_apimessages_SellerBulkActionAsyncResponse = {
  enqueued?: InputMaybe<Scalars["Boolean"]["input"]>;
  message?: InputMaybe<Scalars["String"]["input"]>;
};

export type Input_core_apimessages_SellerBulkActionResponse = {
  responses?: InputMaybe<
    Array<InputMaybe<Input_core_apimessages_SellerListingBulkActionResponse>>
  >;
};

export type Input_core_apimessages_SellerListing = {
  handmade?: InputMaybe<Scalars["Boolean"]["input"]>;
  localizedContents?: InputMaybe<
    Array<InputMaybe<Input_core_apimessages_LocalizedListingContent>>
  >;
  prefersReverbShippingLabel?: InputMaybe<Scalars["Boolean"]["input"]>;
  relistable?: InputMaybe<Scalars["Boolean"]["input"]>;
  scheduledPriceDrops?: InputMaybe<
    Array<InputMaybe<Input_core_apimessages_ScheduledPriceDrop>>
  >;
  sellerCost?: InputMaybe<Input_core_apimessages_Money>;
  sellerPrice?: InputMaybe<Input_core_apimessages_Money>;
  sellerReportedMap?: InputMaybe<Input_core_apimessages_Money>;
  shippingProfileId?: InputMaybe<Scalars["String"]["input"]>;
  storageLocation?: InputMaybe<Scalars["String"]["input"]>;
  taxExempt?: InputMaybe<Scalars["Boolean"]["input"]>;
  upcDoesNotApply?: InputMaybe<Scalars["Boolean"]["input"]>;
  vatIncluded?: InputMaybe<Scalars["Boolean"]["input"]>;
  vatIncludedHint?: InputMaybe<Scalars["String"]["input"]>;
};

export type Input_core_apimessages_SellerListingBulkActionResponse = {
  errors?: InputMaybe<Array<InputMaybe<Input_core_apimessages_Error>>>;
  listingId?: InputMaybe<Scalars["String"]["input"]>;
  success?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type Input_core_apimessages_SellerListingsResponse = {
  listings?: InputMaybe<
    Array<InputMaybe<Input_core_apimessages_SellerListing>>
  >;
};

export type Input_core_apimessages_SellerOrder = {
  orderViewStatus?: InputMaybe<Input_core_apimessages_OrderViewStatus>;
  packingSlip?: InputMaybe<Input_core_apimessages_OrderPackingSlip>;
  shippingLabel?: InputMaybe<Input_core_apimessages_OrderShippingLabel>;
  taxResponsibleParty?: InputMaybe<core_apimessages_SellerOrder_TaxResponsibleParty>;
  uuid?: InputMaybe<Scalars["String"]["input"]>;
};

export type Input_core_apimessages_SellerOrdersResponse = {
  orders?: InputMaybe<Array<InputMaybe<Input_core_apimessages_SellerOrder>>>;
};

export type Input_core_apimessages_SellerVerificationPolicyResponse = {
  completedVerification?: InputMaybe<Scalars["Boolean"]["input"]>;
  eligibleForColdStorageSellerVerificationBypass?: InputMaybe<
    Scalars["Boolean"]["input"]
  >;
  eligibleForInformAct?: InputMaybe<Scalars["Boolean"]["input"]>;
  eligibleForSellerVerification?: InputMaybe<Scalars["Boolean"]["input"]>;
  eligibleForSellerVerificationBackcast?: InputMaybe<
    Scalars["Boolean"]["input"]
  >;
};

export type Input_core_apimessages_SellingFees = {
  maxFee?: InputMaybe<Input_core_apimessages_Money>;
  minFee?: InputMaybe<Input_core_apimessages_Money>;
  processingFees?: InputMaybe<Input_core_apimessages_ProcessingFees>;
  sellingFeePercent?: InputMaybe<Scalars["String"]["input"]>;
};

export type Input_core_apimessages_SellingFeesRequest = {
  channel?: InputMaybe<core_apimessages_Channel>;
};

export type Input_core_apimessages_SellingFeesResponse = {
  fees?: InputMaybe<Input_core_apimessages_SellingFees>;
};

export type Input_core_apimessages_SetAccertifyTokensRequest = {
  accertifyDeviceInfo?: InputMaybe<Input_core_apimessages_AccertifyDeviceInfo>;
  checkoutId?: InputMaybe<Scalars["String"]["input"]>;
};

export type Input_core_apimessages_SetAccertifyTokensResponse = {
  /** Checkout UUID */
  id?: InputMaybe<Scalars["String"]["input"]>;
};

export type Input_core_apimessages_SetupPaymentMethodsRequest = {
  adyenDetails?: InputMaybe<Input_core_apimessages_SetupPaymentMethodsRequestAdyenDetails>;
  creditCard?: InputMaybe<Input_core_apimessages_BillingCreditCardEntry>;
  paypalDetails?: InputMaybe<Input_core_apimessages_SetupPaymentMethodsRequestPaypalDetails>;
  returnPolicyDetails?: InputMaybe<Input_core_apimessages_ReturnPolicy>;
  shopCurrency?: InputMaybe<Scalars["String"]["input"]>;
};

export type Input_core_apimessages_SetupPaymentMethodsRequestAdyenDetails = {
  businessDetails?: InputMaybe<Input_core_apimessages_SetupPaymentMethodsRequestBusinessAdyenDetails>;
  individualDetails?: InputMaybe<Input_core_apimessages_SetupPaymentMethodsRequestIndividualAdyenDetails>;
  legalEntity?: InputMaybe<core_apimessages_AdyenLegalEntity>;
  solePropDetails?: InputMaybe<Input_core_apimessages_SetupPaymentMethodsRequestSolePropAdyenDetails>;
};

export type Input_core_apimessages_SetupPaymentMethodsRequestBusinessAdyenDetails =
  {
    legalBusinessName?: InputMaybe<Scalars["String"]["input"]>;
    originCountryCode?: InputMaybe<Scalars["String"]["input"]>;
  };

export type Input_core_apimessages_SetupPaymentMethodsRequestIndividualAdyenDetails =
  {
    firstName?: InputMaybe<Scalars["String"]["input"]>;
    lastName?: InputMaybe<Scalars["String"]["input"]>;
    originCountryCode?: InputMaybe<Scalars["String"]["input"]>;
  };

export type Input_core_apimessages_SetupPaymentMethodsRequestPaypalDetails = {
  email?: InputMaybe<Scalars["String"]["input"]>;
  legalCountryCode?: InputMaybe<Scalars["String"]["input"]>;
};

export type Input_core_apimessages_SetupPaymentMethodsRequestSolePropAdyenDetails =
  {
    legalName?: InputMaybe<Scalars["String"]["input"]>;
    originCountryCode?: InputMaybe<Scalars["String"]["input"]>;
  };

export type Input_core_apimessages_SetupPaymentMethodsResponse = {
  errors?: InputMaybe<Array<InputMaybe<Input_core_apimessages_Error>>>;
};

export type Input_core_apimessages_SharedSignalData = {
  buyerPrice?: InputMaybe<Input_core_apimessages_Money>;
  originalPrice?: InputMaybe<Input_core_apimessages_Money>;
  sellerLocationDisplay?: InputMaybe<Scalars["String"]["input"]>;
};

export type Input_core_apimessages_Shipment = {
  carrierRates?: InputMaybe<
    Array<InputMaybe<Input_core_apimessages_CarrierRateGroup>>
  >;
  defaultPaymentMethod?: InputMaybe<core_apimessages_FeePaymentMethod>;
  fromAddress?: InputMaybe<Input_core_apimessages_Address>;
  /** shipments.uuid */
  id?: InputMaybe<Scalars["String"]["input"]>;
  mediaMailQualified?: InputMaybe<Scalars["Boolean"]["input"]>;
  orderUuids?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  package?: InputMaybe<Input_core_apimessages_ShippingLabelPackage>;
  shippingMethod?: InputMaybe<core_apimessages_ShippingMethod>;
  shippingProtection?: InputMaybe<Scalars["Boolean"]["input"]>;
  shippingProtectionPrice?: InputMaybe<Input_core_apimessages_Money>;
  signatureRequired?: InputMaybe<Scalars["Boolean"]["input"]>;
  toAddress?: InputMaybe<Input_core_apimessages_Address>;
  toAddressIsResidential?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type Input_core_apimessages_ShipmentItem = {
  declaredValue?: InputMaybe<Input_core_apimessages_Money>;
  /** products.id */
  productId?: InputMaybe<Scalars["String"]["input"]>;
};

export type Input_core_apimessages_ShipmentsRequest = {
  shipmentUuids?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
};

export type Input_core_apimessages_ShipmentsResponse = {
  shipments?: InputMaybe<Array<InputMaybe<Input_core_apimessages_Shipment>>>;
};

export type Input_core_apimessages_ShippingLabelPackage = {
  allowedCarriers?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  height?: InputMaybe<Input_core_apimessages_Measurement>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  length?: InputMaybe<Input_core_apimessages_Measurement>;
  packageSizeSuggestionId?: InputMaybe<Scalars["String"]["input"]>;
  predefinedPackage?: InputMaybe<Scalars["String"]["input"]>;
  reverbSuggested?: InputMaybe<Scalars["Boolean"]["input"]>;
  weight?: InputMaybe<Input_core_apimessages_Measurement>;
  width?: InputMaybe<Input_core_apimessages_Measurement>;
};

export type Input_core_apimessages_ShippingPrice = {
  carrierCalculated?: InputMaybe<Scalars["Boolean"]["input"]>;
  destinationPostalCodeNeeded?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** in shop currency, VAT conditionally included */
  originalRate?: InputMaybe<Input_core_apimessages_Money>;
  /** in shop currency, no VAT */
  originalRateVatExcluded?: InputMaybe<Input_core_apimessages_Money>;
  postalCode?: InputMaybe<Scalars["String"]["input"]>;
  /** in user currency, VAT conditionally included */
  rate?: InputMaybe<Input_core_apimessages_Money>;
  regional?: InputMaybe<Scalars["Boolean"]["input"]>;
  shippingMethod?: InputMaybe<core_apimessages_ShippingMethod>;
  shippingRateId?: InputMaybe<Scalars["String"]["input"]>;
  shippingRegionCode?: InputMaybe<Scalars["String"]["input"]>;
};

export type Input_core_apimessages_ShippingPrices = {
  freeExpeditedShipping?: InputMaybe<Scalars["Boolean"]["input"]>;
  localPickup?: InputMaybe<Scalars["Boolean"]["input"]>;
  localPickupOnly?: InputMaybe<Scalars["Boolean"]["input"]>;
  shippingAddress?: InputMaybe<Input_core_apimessages_Address>;
  shippingPrices?: InputMaybe<
    Array<InputMaybe<Input_core_apimessages_ShippingPrice>>
  >;
  /** user shipping region code */
  shippingRegionCode?: InputMaybe<Scalars["String"]["input"]>;
};

export type Input_core_apimessages_ShippingPricesRequest = {
  listingIds?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  postalCode?: InputMaybe<Scalars["String"]["input"]>;
  returnAllShippingPrices?: InputMaybe<Scalars["Boolean"]["input"]>;
  shippingRegionCode?: InputMaybe<Scalars["String"]["input"]>;
  usePrimaryShippingAddress?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type Input_core_apimessages_ShippingPricesResponse = {
  prices?: InputMaybe<Array<InputMaybe<Input_core_apimessages_Money>>>;
  shippingPrices?: InputMaybe<
    Array<InputMaybe<Input_core_apimessages_ShippingPrices>>
  >;
};

export type Input_core_apimessages_ShippingProfile = {
  categoryUuids?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  localPickup?: InputMaybe<Scalars["Boolean"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  shippingRates?: InputMaybe<Input_core_apimessages_ShippingRates>;
};

/** POST /shipping_protection */
export type Input_core_apimessages_ShippingProtectionRequest = {
  fromCountryCode?: InputMaybe<Scalars["String"]["input"]>;
  shipmentItems?: InputMaybe<
    Array<InputMaybe<Input_core_apimessages_ShipmentItem>>
  >;
  shipmentType?: InputMaybe<core_apimessages_Shipment_ShipmentType>;
  toCountryCode?: InputMaybe<Scalars["String"]["input"]>;
};

/** POST /shipping_protection */
export type Input_core_apimessages_ShippingProtectionResponse = {
  price?: InputMaybe<Input_core_apimessages_Money>;
  shippingProtectionStatus?: InputMaybe<core_apimessages_ShippingProtectionStatus>;
  signatureRequiredCosts?: InputMaybe<
    Array<InputMaybe<Input_core_apimessages_SignatureRequiredCost>>
  >;
  signatureRequiredStatus?: InputMaybe<core_apimessages_SignatureRequiredStatus>;
};

export type Input_core_apimessages_ShippingRate = {
  displayName?: InputMaybe<Scalars["String"]["input"]>;
  price?: InputMaybe<Input_core_apimessages_Money>;
};

export type Input_core_apimessages_ShippingRates = {
  expeditedRate?: InputMaybe<Input_core_apimessages_Money>;
  freeShippingThreshold?: InputMaybe<Input_core_apimessages_Money>;
  incrementalRate?: InputMaybe<Input_core_apimessages_Money>;
  rate?: InputMaybe<Input_core_apimessages_Money>;
  rateType?: InputMaybe<Scalars["String"]["input"]>;
  regionCode?: InputMaybe<Scalars["String"]["input"]>;
  regionName?: InputMaybe<Scalars["String"]["input"]>;
  regionType?: InputMaybe<core_apimessages_ShippingRates_RegionType>;
  regionalExpeditedRates?: InputMaybe<
    Array<InputMaybe<Input_core_apimessages_RegionalShippingRate>>
  >;
  regionalRates?: InputMaybe<
    Array<InputMaybe<Input_core_apimessages_RegionalShippingRate>>
  >;
};

export type Input_core_apimessages_ShippingRatesForShippingProfile = {
  shippingProfileId?: InputMaybe<Scalars["String"]["input"]>;
  shippingRates?: InputMaybe<
    Array<InputMaybe<Input_core_apimessages_ShippingRates>>
  >;
};

export type Input_core_apimessages_ShippingRegionSetting = {
  allowedToShipCarrierCalculated?: InputMaybe<Scalars["Boolean"]["input"]>;
  isDomestic?: InputMaybe<Scalars["Boolean"]["input"]>;
  regionCode?: InputMaybe<Scalars["String"]["input"]>;
  regionName?: InputMaybe<Scalars["String"]["input"]>;
};

export type Input_core_apimessages_ShipsSoonSignal = {
  isValid?: InputMaybe<Scalars["Boolean"]["input"]>;
  translations?: InputMaybe<Input_core_apimessages_SignalTranslation>;
};

/** A public representation of a shop, accessible to all users. */
export type Input_core_apimessages_Shop = {
  createdAt?: InputMaybe<Scalars["Timestamp"]["input"]>;
  currency?: InputMaybe<Scalars["String"]["input"]>;
  description?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  lowballOfferPercentage?: InputMaybe<Scalars["Int"]["input"]>;
  manufacturersSafetyInformation?: InputMaybe<Scalars["String"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  onVacation?: InputMaybe<Scalars["Boolean"]["input"]>;
  paymentPolicy?: InputMaybe<Scalars["String"]["input"]>;
  preferredSeller?: InputMaybe<Scalars["Boolean"]["input"]>;
  quickResponder?: InputMaybe<Scalars["Boolean"]["input"]>;
  quickShipper?: InputMaybe<Scalars["Boolean"]["input"]>;
  quickShipperSpeed?: InputMaybe<core_apimessages_Shop_QuickShipperSpeed>;
  /** Unused by any client but needed in mobile apps RQL queries */
  recentlyShipped?: InputMaybe<Scalars["Boolean"]["input"]>;
  shippingPolicy?: InputMaybe<Scalars["String"]["input"]>;
  shopType?: InputMaybe<core_apimessages_Shop_ShopType>;
  showSold?: InputMaybe<Scalars["Boolean"]["input"]>;
  slug?: InputMaybe<Scalars["String"]["input"]>;
  userId?: InputMaybe<Scalars["String"]["input"]>;
  userUuid?: InputMaybe<Scalars["String"]["input"]>;
  uuid?: InputMaybe<Scalars["String"]["input"]>;
  vatId?: InputMaybe<Scalars["String"]["input"]>;
  website?: InputMaybe<Scalars["String"]["input"]>;
};

export type Input_core_apimessages_ShopAdyenBalancePlatformNotificationsIndexRequest =
  {
    eventTypes?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
    limit?: InputMaybe<Scalars["String"]["input"]>;
    offset?: InputMaybe<Scalars["String"]["input"]>;
    shopId?: InputMaybe<Scalars["String"]["input"]>;
  };

export type Input_core_apimessages_ShopAdyenBalancePlatformNotificationsIndexResponse =
  {
    limit?: InputMaybe<Scalars["String"]["input"]>;
    notifications?: InputMaybe<
      Array<InputMaybe<Input_core_apimessages_AdyenBalancePlatformNotification>>
    >;
    offset?: InputMaybe<Scalars["String"]["input"]>;
    totalResults?: InputMaybe<Scalars["String"]["input"]>;
  };

export type Input_core_apimessages_ShopBadge = {
  badgeType?: InputMaybe<Scalars["String"]["input"]>;
  completedPercentage?: InputMaybe<Scalars["Float"]["input"]>;
  /** optional: we won't display requirements for the preferred seller badge */
  requirements?: InputMaybe<
    Array<InputMaybe<Input_core_apimessages_ShopBadgeRequirement>>
  >;
};

export type Input_core_apimessages_ShopBadgeRequirement = {
  completed?: InputMaybe<Scalars["Boolean"]["input"]>;
  requirement?: InputMaybe<Scalars["String"]["input"]>;
};

export type Input_core_apimessages_ShopCampaignCoupon = {
  applyToBStockConditions?: InputMaybe<Scalars["Boolean"]["input"]>;
  applyToNewConditions?: InputMaybe<Scalars["Boolean"]["input"]>;
  applyToUsedConditions?: InputMaybe<Scalars["Boolean"]["input"]>;
  code?: InputMaybe<Scalars["String"]["input"]>;
  couponHeader?: InputMaybe<Scalars["String"]["input"]>;
  descriptionSummary?: InputMaybe<Scalars["String"]["input"]>;
  discountValue?: InputMaybe<Scalars["String"]["input"]>;
  expiresAt?: InputMaybe<Scalars["Timestamp"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  restrictionsSummary?: InputMaybe<Scalars["String"]["input"]>;
  status?: InputMaybe<core_apimessages_ShopCampaignCoupon_Status>;
};

export type Input_core_apimessages_ShopCertifiedPreOwned = {
  badgeIconUrl?: InputMaybe<Scalars["String"]["input"]>;
  brandName?: InputMaybe<Scalars["String"]["input"]>;
  darkModeBrandIconUrl?: InputMaybe<Scalars["String"]["input"]>;
  description?: InputMaybe<Scalars["String"]["input"]>;
  lightModeBrandIconUrl?: InputMaybe<Scalars["String"]["input"]>;
  pageUrl?: InputMaybe<Scalars["String"]["input"]>;
  title?: InputMaybe<Scalars["String"]["input"]>;
  tooltipDescription?: InputMaybe<Scalars["String"]["input"]>;
};

/** A private representation of a shop, accessible to owners and admins. */
export type Input_core_apimessages_ShopConfig = {
  acceptsAffirm?: InputMaybe<Scalars["Boolean"]["input"]>;
  canCurateSales?: InputMaybe<Scalars["Boolean"]["input"]>;
  currencySetting?: InputMaybe<Scalars["String"]["input"]>;
  defaultLocale?: InputMaybe<Scalars["String"]["input"]>;
  firstTimeSeller?: InputMaybe<Scalars["Boolean"]["input"]>;
  hasAccountRep?: InputMaybe<Scalars["Boolean"]["input"]>;
  inclusiveVatPricingEnabled?: InputMaybe<Scalars["Boolean"]["input"]>;
  isShopInEu?: InputMaybe<Scalars["Boolean"]["input"]>;
  isShopInUk?: InputMaybe<Scalars["Boolean"]["input"]>;
  listableConditions?: InputMaybe<
    Array<InputMaybe<Input_core_apimessages_Condition>>
  >;
  marketplaceTaxReportingCountryCode?: InputMaybe<Scalars["String"]["input"]>;
  offerPolicy?: InputMaybe<Input_core_apimessages_ShopConfig_OfferPolicy>;
  sameDayShippingConfigured?: InputMaybe<Scalars["Boolean"]["input"]>;
  shippingRegionSettings?: InputMaybe<
    Array<InputMaybe<Input_core_apimessages_ShippingRegionSetting>>
  >;
  shopSupportedLocaleCodes?: InputMaybe<
    Array<InputMaybe<Scalars["String"]["input"]>>
  >;
};

export type Input_core_apimessages_ShopConfig_OfferPolicy = {
  autoAcceptHighOffers?: InputMaybe<Scalars["Boolean"]["input"]>;
  autoRejectLowOffers?: InputMaybe<Scalars["Boolean"]["input"]>;
  lowballPercentage?: InputMaybe<Scalars["Int"]["input"]>;
};

export type Input_core_apimessages_ShopEntry = {
  address?: InputMaybe<Input_core_apimessages_AddressEntry>;
  currency?: InputMaybe<Scalars["String"]["input"]>;
  defaultLocale?: InputMaybe<Scalars["String"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  originCountryCode?: InputMaybe<Scalars["String"]["input"]>;
  shopType?: InputMaybe<core_apimessages_Shop_ShopType>;
};

export type Input_core_apimessages_ShopReturnPoliciesRequest = {
  ids?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  shopUuids?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  slugs?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  uuids?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
};

export type Input_core_apimessages_ShopReturnPoliciesResponse = {
  shopReturnPolicies?: InputMaybe<
    Array<InputMaybe<Input_core_apimessages_ShopReturnPolicy>>
  >;
};

export type Input_core_apimessages_ShopReturnPolicy = {
  returnPolicy?: InputMaybe<Input_core_apimessages_ReturnPolicy>;
  shopUuid?: InputMaybe<Scalars["String"]["input"]>;
};

export type Input_core_apimessages_ShopShippingProfiles = {
  shippingProfiles?: InputMaybe<
    Array<InputMaybe<Input_core_apimessages_ShippingProfile>>
  >;
  shopUuid?: InputMaybe<Scalars["String"]["input"]>;
};

export type Input_core_apimessages_ShopShippingProfilesRequest = {
  ids?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  shopUuids?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  slugs?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  uuids?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
};

export type Input_core_apimessages_ShopShippingProfilesResponse = {
  shopShippingProfiles?: InputMaybe<
    Array<InputMaybe<Input_core_apimessages_ShopShippingProfiles>>
  >;
};

export type Input_core_apimessages_ShopTaxPolicies = {
  shopUuid?: InputMaybe<Scalars["String"]["input"]>;
  taxPolicies?: InputMaybe<
    Array<InputMaybe<Input_core_apimessages_ShopTaxPolicy>>
  >;
};

export type Input_core_apimessages_ShopTaxPolicy = {
  displayLocation?: InputMaybe<Scalars["String"]["input"]>;
  displayRate?: InputMaybe<Scalars["String"]["input"]>;
  policyType?: InputMaybe<core_apimessages_ShopTaxPolicy_PolicyType>;
  vatPolicy?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type Input_core_apimessages_Shop_OfferPolicy = {
  autoRejectLowOffers?: InputMaybe<Scalars["Boolean"]["input"]>;
  lowballPercentage?: InputMaybe<Scalars["Int"]["input"]>;
};

export type Input_core_apimessages_ShopsRequest = {
  channel?: InputMaybe<core_apimessages_Channel>;
  slugs?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  uuids?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
};

export type Input_core_apimessages_ShopsResponse = {
  shops?: InputMaybe<Array<InputMaybe<Input_core_apimessages_Shop>>>;
};

export type Input_core_apimessages_ShowAdyenRegionRequest = {
  _?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type Input_core_apimessages_ShowAdyenRegionResponse = {
  androidRegion?: InputMaybe<Scalars["String"]["input"]>;
  iosRegion?: InputMaybe<Scalars["String"]["input"]>;
  webRegion?: InputMaybe<Scalars["String"]["input"]>;
};

export type Input_core_apimessages_SignalLink = {
  text?: InputMaybe<Scalars["String"]["input"]>;
  url?: InputMaybe<Scalars["String"]["input"]>;
};

export type Input_core_apimessages_SignalTranslation = {
  subtitle?: InputMaybe<Scalars["String"]["input"]>;
  title?: InputMaybe<Scalars["String"]["input"]>;
  tooltipText?: InputMaybe<Scalars["String"]["input"]>;
};

export type Input_core_apimessages_SignatureRequiredCost = {
  amount?: InputMaybe<Input_core_apimessages_Money>;
  carrier?: InputMaybe<Scalars["String"]["input"]>;
};

export type Input_core_apimessages_SiteBanner = {
  callToAction?: InputMaybe<Scalars["String"]["input"]>;
  contentSponsorship?: InputMaybe<Input_core_apimessages_ContentSponsorship>;
  downtime?: InputMaybe<Scalars["Boolean"]["input"]>;
  endsAt?: InputMaybe<Scalars["Timestamp"]["input"]>;
  hasPhotos?: InputMaybe<Scalars["Boolean"]["input"]>;
  heading?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  imageUrl?: InputMaybe<Scalars["String"]["input"]>;
  mobileImageUrl?: InputMaybe<Scalars["String"]["input"]>;
  mobileUrl?: InputMaybe<Input_core_apimessages_Link>;
  mobileWebUrl?: InputMaybe<Input_core_apimessages_Link>;
  showOnMobile?: InputMaybe<Scalars["Boolean"]["input"]>;
  showOnMyUpdates?: InputMaybe<Scalars["Boolean"]["input"]>;
  startsAt?: InputMaybe<Scalars["Timestamp"]["input"]>;
  subheading?: InputMaybe<Scalars["String"]["input"]>;
  url?: InputMaybe<Input_core_apimessages_Link>;
  /** Deprecated */
  webOnly?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type Input_core_apimessages_SiteBannerRequest = {
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  visibilityScope?: InputMaybe<core_apimessages_SiteBannerRequest_VisibilityScope>;
};

export type Input_core_apimessages_SiteBannerResponse = {
  siteBanner?: InputMaybe<Input_core_apimessages_SiteBanner>;
  siteBanners?: InputMaybe<
    Array<InputMaybe<Input_core_apimessages_SiteBanner>>
  >;
};

export type Input_core_apimessages_StartCheckoutForListingRequest = {
  listingId?: InputMaybe<Scalars["String"]["input"]>;
  paymentMethod?: InputMaybe<core_apimessages_CheckoutPaymentMethod_Type>;
  protectionPlanId?: InputMaybe<Scalars["String"]["input"]>;
  quantity?: InputMaybe<Scalars["Int"]["input"]>;
};

export type Input_core_apimessages_StartCheckoutForListingResponse = {
  checkoutId?: InputMaybe<Scalars["String"]["input"]>;
  redirectUrl?: InputMaybe<Scalars["String"]["input"]>;
};

export type Input_core_apimessages_StartCheckoutRequest = {
  checkoutBundlingId?: InputMaybe<Scalars["String"]["input"]>;
  paymentMethodType?: InputMaybe<core_apimessages_CheckoutPaymentMethod_Type>;
};

export type Input_core_apimessages_StartCheckoutResponse = {
  /** Checkout UUID */
  id?: InputMaybe<Scalars["String"]["input"]>;
};

export type Input_core_apimessages_StartPaypalCheckoutRequest = {
  checkoutBundlingId?: InputMaybe<Scalars["String"]["input"]>;
  paymentMethodType?: InputMaybe<core_apimessages_StartPaypalCheckoutRequest_PaymentMethodType>;
  protectionPlanId?: InputMaybe<Scalars["String"]["input"]>;
  /** Item quantity for Buy It Now checkout on item page */
  quantity?: InputMaybe<Scalars["Int"]["input"]>;
};

export type Input_core_apimessages_StartPaypalCheckoutResponse = {
  /** checkout UUID */
  id?: InputMaybe<Scalars["String"]["input"]>;
  paypalOrderId?: InputMaybe<Scalars["String"]["input"]>;
  redirectUrl?: InputMaybe<Scalars["String"]["input"]>;
};

export type Input_core_apimessages_Storefront = {
  channel?: InputMaybe<Scalars["String"]["input"]>;
  description?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  shippingPolicy?: InputMaybe<Scalars["String"]["input"]>;
  shopUuid?: InputMaybe<Scalars["String"]["input"]>;
  slug?: InputMaybe<Scalars["String"]["input"]>;
};

export type Input_core_apimessages_StorefrontReturnPoliciesRequest = {
  slugs?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
};

export type Input_core_apimessages_StorefrontReturnPoliciesResponse = {
  returnPolicies?: InputMaybe<
    Array<InputMaybe<Input_core_apimessages_ReturnPolicy>>
  >;
};

export type Input_core_apimessages_StorefrontsRequest = {
  slugs?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
};

export type Input_core_apimessages_StorefrontsResponse = {
  storefronts?: InputMaybe<
    Array<InputMaybe<Input_core_apimessages_Storefront>>
  >;
};

export type Input_core_apimessages_Subregion = {
  code?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
};

export type Input_core_apimessages_SuperRegion = {
  code?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
};

export type Input_core_apimessages_TargetingContext = {
  categories?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  keywords?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
};

export type Input_core_apimessages_Tax1099kReportingThresholdSearchResult = {
  id?: InputMaybe<Scalars["String"]["input"]>;
  region?: InputMaybe<Scalars["String"]["input"]>;
  salesAmountCents?: InputMaybe<Scalars["String"]["input"]>;
  transactionCount?: InputMaybe<Scalars["String"]["input"]>;
  year?: InputMaybe<Scalars["String"]["input"]>;
};

export type Input_core_apimessages_TaxFormDownload = {
  id?: InputMaybe<Scalars["String"]["input"]>;
  status?: InputMaybe<core_apimessages_TaxFormDownload_Status>;
  url?: InputMaybe<Scalars["String"]["input"]>;
};

export type Input_core_apimessages_TaxFormDownloadRequest = {
  id?: InputMaybe<Scalars["String"]["input"]>;
};

export type Input_core_apimessages_TaxFormDownloadResponse = {
  taxFormDownload?: InputMaybe<Input_core_apimessages_TaxFormDownload>;
};

export type Input_core_apimessages_TaxIdentification = {
  active?: InputMaybe<Scalars["Boolean"]["input"]>;
  countryCode?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  status?: InputMaybe<core_apimessages_TaxIdentification_Status>;
  taxId?: InputMaybe<Scalars["String"]["input"]>;
  taxType?: InputMaybe<core_apimessages_TaxIdentification_TaxType>;
};

export type Input_core_apimessages_TaxIdentificationUpdate = {
  countryCode?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  taxId?: InputMaybe<Scalars["String"]["input"]>;
};

export type Input_core_apimessages_TaxPoliciesRequest = {
  id?: InputMaybe<Scalars["String"]["input"]>;
  slug?: InputMaybe<Scalars["String"]["input"]>;
  type?: InputMaybe<core_apimessages_TaxPoliciesRequest_TaxPoliciesType>;
  uuid?: InputMaybe<Scalars["String"]["input"]>;
};

export type Input_core_apimessages_TaxProfile = {
  address?: InputMaybe<Input_core_apimessages_Address>;
  createdAt?: InputMaybe<Scalars["Timestamp"]["input"]>;
  firstName?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  lastName?: InputMaybe<Scalars["String"]["input"]>;
  mask?: InputMaybe<Scalars["String"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  state?: InputMaybe<Scalars["String"]["input"]>;
  tinType?: InputMaybe<Scalars["String"]["input"]>;
  updatedAt?: InputMaybe<Scalars["Timestamp"]["input"]>;
  verificationStatus?: InputMaybe<core_apimessages_TaxProfile_TaxProfileVerificationStatus>;
  verifiedAt?: InputMaybe<Scalars["Timestamp"]["input"]>;
};

export type Input_core_apimessages_ThirdPartyAdsAttributes = {
  amount?: InputMaybe<Scalars["String"]["input"]>;
  billingAddress?: InputMaybe<Scalars["String"]["input"]>;
  billingAddressExtended?: InputMaybe<Scalars["String"]["input"]>;
  billingPostalCode?: InputMaybe<Scalars["String"]["input"]>;
  cartItemsJson?: InputMaybe<Scalars["String"]["input"]>;
  country?: InputMaybe<Scalars["String"]["input"]>;
  currency?: InputMaybe<Scalars["String"]["input"]>;
  email?: InputMaybe<Scalars["String"]["input"]>;
  firstName?: InputMaybe<Scalars["String"]["input"]>;
  language?: InputMaybe<Scalars["String"]["input"]>;
  lastName?: InputMaybe<Scalars["String"]["input"]>;
  mobilePhoneNumber?: InputMaybe<Scalars["String"]["input"]>;
  orderBundleId?: InputMaybe<Scalars["String"]["input"]>;
  paymentType?: InputMaybe<Scalars["String"]["input"]>;
  shippingAddress?: InputMaybe<Scalars["String"]["input"]>;
  shippingAddressExtended?: InputMaybe<Scalars["String"]["input"]>;
  shippingPostalCode?: InputMaybe<Scalars["String"]["input"]>;
};

export type Input_core_apimessages_ThreeDSOneData = {
  md?: InputMaybe<Scalars["String"]["input"]>;
  paReq?: InputMaybe<Scalars["String"]["input"]>;
  redirectUrl?: InputMaybe<Scalars["String"]["input"]>;
  termUrl?: InputMaybe<Scalars["String"]["input"]>;
};

export type Input_core_apimessages_TrackBumpInteractionRequest = {
  bumpKey?: InputMaybe<Input_core_apimessages_BumpKey>;
  componentName?: InputMaybe<Scalars["String"]["input"]>;
  interactionType?: InputMaybe<arbiter_Interaction_InteractionType>;
  listingId?: InputMaybe<Scalars["String"]["input"]>;
};

export type Input_core_apimessages_TrackBumpInteractionResponse = {
  bumpKey?: InputMaybe<Input_core_apimessages_BumpKey>;
};

export type Input_core_apimessages_Trait = {
  /** traits.uuid */
  keyId?: InputMaybe<Scalars["String"]["input"]>;
  value?: InputMaybe<Scalars["String"]["input"]>;
  /** traits.value_uuid */
  valueId?: InputMaybe<Scalars["String"]["input"]>;
};

export type Input_core_apimessages_TraitResponse = {
  /** traits.traitable_id (polymorphic foreign key, with 'type') */
  traitableId?: InputMaybe<Scalars["String"]["input"]>;
  traits?: InputMaybe<Array<InputMaybe<Input_core_apimessages_Trait>>>;
};

export type Input_core_apimessages_TraitsRequest = {
  /** traits.traitable_id (polymorphic foreign key, with 'type') */
  ids?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  type?: InputMaybe<core_apimessages_TraitsRequest_TraitableType>;
};

export type Input_core_apimessages_TraitsResponse = {
  traits?: InputMaybe<Array<InputMaybe<Input_core_apimessages_TraitResponse>>>;
};

export type Input_core_apimessages_TruliooOnboarding = {
  completedAt?: InputMaybe<Scalars["Timestamp"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  verificationStatus?: InputMaybe<core_apimessages_TruliooOnboarding_VerificationStatus>;
};

export type Input_core_apimessages_TruliooOnboardingRecord = {
  clientId?: InputMaybe<Scalars["String"]["input"]>;
  email?: InputMaybe<Scalars["String"]["input"]>;
  entityType?: InputMaybe<core_apimessages_TruliooOnboardingRecord_EntityType>;
  flowId?: InputMaybe<Scalars["String"]["input"]>;
  fullName?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  isParent?: InputMaybe<Scalars["Boolean"]["input"]>;
  workflowStatus?: InputMaybe<core_apimessages_TruliooOnboardingRecord_Status>;
};

export type Input_core_apimessages_TruliooOnboardingRecordResendPscEmailRequest =
  {
    truliooOnboardingRecordId?: InputMaybe<Scalars["String"]["input"]>;
  };

export type Input_core_apimessages_TruliooOnboardingRecordResendPscEmailResponse =
  {
    truliooOnboardingRecordId?: InputMaybe<Scalars["String"]["input"]>;
  };

export type Input_core_apimessages_TruliooOnboardingRecordResponse = {
  truliooOnboardingRecord?: InputMaybe<Input_core_apimessages_TruliooOnboardingRecord>;
};

export type Input_core_apimessages_TruliooOnboardingResponse = {
  errors?: InputMaybe<Array<InputMaybe<Input_core_apimessages_Error>>>;
  truliooOnboarding?: InputMaybe<Input_core_apimessages_TruliooOnboarding>;
};

export type Input_core_apimessages_TypicalNewPriceDisplay = {
  amountDisplay?: InputMaybe<Scalars["String"]["input"]>;
  descriptionDisplay?: InputMaybe<Scalars["String"]["input"]>;
  savingsDisplay?: InputMaybe<Scalars["String"]["input"]>;
};

export type Input_core_apimessages_UndoDeleteMyFavoriteRequest = {
  id?: InputMaybe<Scalars["String"]["input"]>;
  type?: InputMaybe<core_apimessages_FavoriteType>;
};

export type Input_core_apimessages_UndoDeleteMyFavoriteResponse = {
  errors?: InputMaybe<Array<InputMaybe<Input_core_apimessages_Error>>>;
  favorite?: InputMaybe<Input_core_apimessages_Favorite>;
};

export type Input_core_apimessages_UniversalPromoCampaign = {
  categories?: InputMaybe<
    Array<InputMaybe<Input_core_apimessages_NodesCategory>>
  >;
  code?: InputMaybe<Scalars["String"]["input"]>;
  expiresAt?: InputMaybe<Scalars["Timestamp"]["input"]>;
  hasRedemptionLimit?: InputMaybe<Scalars["Boolean"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  lapsedBuyer?: InputMaybe<Scalars["Boolean"]["input"]>;
  lapsedBuyerLastPurchaseDate?: InputMaybe<Scalars["Timestamp"]["input"]>;
  marketingChannel?: InputMaybe<Scalars["String"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  newBuyer?: InputMaybe<Scalars["Boolean"]["input"]>;
  redemptionLimit?: InputMaybe<Scalars["Int"]["input"]>;
  universalPromoCurrencies?: InputMaybe<
    Array<InputMaybe<Input_core_apimessages_UniversalPromoCurrency>>
  >;
};

export type Input_core_apimessages_UniversalPromoCurrency = {
  amount?: InputMaybe<Input_core_apimessages_Money>;
  minPurchaseAmount?: InputMaybe<Input_core_apimessages_Money>;
};

export type Input_core_apimessages_UpdateAdCampaignRequest = {
  adCampaign?: InputMaybe<Input_core_apimessages_AdCampaign>;
};

export type Input_core_apimessages_UpdateAdCampaignResponse = {
  adCampaign?: InputMaybe<Input_core_apimessages_AdCampaign>;
};

export type Input_core_apimessages_UpdateAdGroupKeywordsRequest = {
  adGroupUuid?: InputMaybe<Scalars["String"]["input"]>;
  keywords?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
};

export type Input_core_apimessages_UpdateAdGroupKeywordsResponse = {
  adGroupKeywords?: InputMaybe<
    Array<InputMaybe<Input_core_apimessages_AdGroupKeyword>>
  >;
};

export type Input_core_apimessages_UpdateAdGroupRequest = {
  adGroup?: InputMaybe<Input_core_apimessages_AdGroup>;
};

export type Input_core_apimessages_UpdateAdGroupResponse = {
  adGroup?: InputMaybe<Input_core_apimessages_AdGroup>;
};

export type Input_core_apimessages_UpdateAdRequest = {
  ad?: InputMaybe<Input_core_apimessages_Ad>;
};

export type Input_core_apimessages_UpdateAdResponse = {
  ad?: InputMaybe<Input_core_apimessages_Ad>;
};

export type Input_core_apimessages_UpdateAdZoneRequest = {
  adZone?: InputMaybe<Input_core_apimessages_AdZone>;
};

export type Input_core_apimessages_UpdateAdZoneResponse = {
  adZone?: InputMaybe<Input_core_apimessages_AdZone>;
};

export type Input_core_apimessages_UpdateAddressRequest = {
  address?: InputMaybe<Input_core_apimessages_AddressEntry>;
  addressType?: InputMaybe<core_apimessages_MyAddressType>;
  /** addresses.uuid */
  id?: InputMaybe<Scalars["String"]["input"]>;
  isPrimary?: InputMaybe<Scalars["Boolean"]["input"]>;
  uuid?: InputMaybe<Scalars["String"]["input"]>;
};

export type Input_core_apimessages_UpdateAddressResponse = {
  address?: InputMaybe<Input_core_apimessages_Address>;
};

export type Input_core_apimessages_UpdateAdvertiserRequest = {
  advertiser?: InputMaybe<Input_core_apimessages_Advertiser>;
};

export type Input_core_apimessages_UpdateAdvertiserResponse = {
  advertiser?: InputMaybe<Input_core_apimessages_Advertiser>;
};

export type Input_core_apimessages_UpdateBillingMethodRequest = {
  browserInfo?: InputMaybe<Scalars["String"]["input"]>;
  creditCard?: InputMaybe<Input_core_apimessages_BillingCreditCardEntry>;
  origin?: InputMaybe<Scalars["String"]["input"]>;
  /** optional, to indicate that a new card is being added specifically for a partial payment */
  partialPayment?: InputMaybe<Scalars["Boolean"]["input"]>;
  returnUrl?: InputMaybe<Scalars["String"]["input"]>;
};

export type Input_core_apimessages_UpdateBillingMethodResponse = {
  adyenPaymentResult?: InputMaybe<Input_core_apimessages_AdyenPaymentResult>;
  billingMethodUuid?: InputMaybe<Scalars["String"]["input"]>;
  creditCard?: InputMaybe<Input_core_apimessages_CreditCard>;
  errors?: InputMaybe<Array<InputMaybe<Input_core_apimessages_Error>>>;
};

export type Input_core_apimessages_UpdateCartItemProtectionPlanRequest = {
  cartItemUuid?: InputMaybe<Scalars["String"]["input"]>;
  protectionPlan?: InputMaybe<Input_core_apimessages_ProtectionPlan>;
};

export type Input_core_apimessages_UpdateCartItemProtectionPlanResponse = {
  cartItemUuid?: InputMaybe<Scalars["String"]["input"]>;
  protectionPlan?: InputMaybe<Input_core_apimessages_ProtectionPlan>;
};

export type Input_core_apimessages_UpdateCheckoutBillingAddressRequest = {
  accertifyDeviceInfo?: InputMaybe<Input_core_apimessages_AccertifyDeviceInfo>;
  billingAddress?: InputMaybe<Input_core_apimessages_AddressEntry>;
  /** checkout UUID */
  id?: InputMaybe<Scalars["String"]["input"]>;
};

export type Input_core_apimessages_UpdateCheckoutBillingAddressResponse = {
  /** checkout UUID */
  id?: InputMaybe<Scalars["String"]["input"]>;
};

export type Input_core_apimessages_UpdateCheckoutPaypalOrderRequest = {
  /** checkout UUID */
  id?: InputMaybe<Scalars["String"]["input"]>;
  paypalOrderId?: InputMaybe<Scalars["String"]["input"]>;
};

export type Input_core_apimessages_UpdateCheckoutPaypalOrderResponse = {
  /** checkout UUID */
  id?: InputMaybe<Scalars["String"]["input"]>;
};

export type Input_core_apimessages_UpdateCrossPlatformListingSessionRequest = {
  action?: InputMaybe<core_apimessages_UpdateCrossPlatformListingSessionRequest_Action>;
  listingId?: InputMaybe<Scalars["String"]["input"]>;
};

export type Input_core_apimessages_UpdateCrossPlatformListingSessionResponse = {
  _?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type Input_core_apimessages_UpdateFeedbackRequest = {
  conditionSatisfactory?: InputMaybe<Input_google_protobuf_BoolValue>;
  message?: InputMaybe<Scalars["String"]["input"]>;
  orderUuid?: InputMaybe<Scalars["String"]["input"]>;
  rating?: InputMaybe<Scalars["Int"]["input"]>;
  shippingSatisfactory?: InputMaybe<Input_google_protobuf_BoolValue>;
};

export type Input_core_apimessages_UpdateFeedbackResponse = {
  feedback?: InputMaybe<Input_core_apimessages_Feedback>;
};

export type Input_core_apimessages_UpdateGearCollectionItemRequest = {
  gearCollectionItemEntry?: InputMaybe<Input_core_apimessages_GearCollectionItemEntry>;
};

export type Input_core_apimessages_UpdateGearCollectionItemResponse = {
  gearCollectionItem?: InputMaybe<Input_core_apimessages_GearCollectionItem>;
};

export type Input_core_apimessages_UpdateGearCollectionItemStateRequest = {
  id?: InputMaybe<Scalars["String"]["input"]>;
  newState?: InputMaybe<core_apimessages_GearCollectionItem_State>;
};

export type Input_core_apimessages_UpdateGearCollectionItemStateResponse = {
  gearCollectionItem?: InputMaybe<Input_core_apimessages_GearCollectionItem>;
};

export type Input_core_apimessages_UpdateInternationalTaxProfileRequest = {
  addressAttributes?: InputMaybe<Input_core_apimessages_AddressEntry>;
  businessRegistrationNumber?: InputMaybe<Scalars["String"]["input"]>;
  dateOfBirth?: InputMaybe<Scalars["String"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  tin?: InputMaybe<Scalars["String"]["input"]>;
  tinType?: InputMaybe<Scalars["String"]["input"]>;
};

export type Input_core_apimessages_UpdateInternationalTaxProfileResponse = {
  businessRegistrationNumber?: InputMaybe<Input_core_apimessages_BusinessRegistrationNumber>;
  internationalTaxProfile?: InputMaybe<Input_core_apimessages_InternationalTaxProfile>;
};

export type Input_core_apimessages_UpdateListingRequest = {
  fields?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  listingEntry?: InputMaybe<Input_core_apimessages_ListingEntry>;
  updateType?: InputMaybe<core_apimessages_UpdateListingRequest_UpdateType>;
};

export type Input_core_apimessages_UpdateListingResponse = {
  listing?: InputMaybe<Input_core_apimessages_Listing>;
};

export type Input_core_apimessages_UpdateListingStateRequest = {
  endingReason?: InputMaybe<Scalars["String"]["input"]>;
  newState?: InputMaybe<core_apimessages_ListingStateUpdate>;
  productId?: InputMaybe<Scalars["String"]["input"]>;
};

export type Input_core_apimessages_UpdateListingStateResponse = {
  newState?: InputMaybe<core_apimessages_ListingStateUpdate>;
};

export type Input_core_apimessages_UpdateMyCreditCardRequest = {
  billingAddress?: InputMaybe<Input_core_apimessages_AddressEntry>;
  creditCard?: InputMaybe<Input_core_apimessages_UpdateMyCreditCardRequest_CreditCardEntry>;
  id?: InputMaybe<Scalars["String"]["input"]>;
};

export type Input_core_apimessages_UpdateMyCreditCardRequest_CreditCardEntry = {
  cardholderName?: InputMaybe<Scalars["String"]["input"]>;
  /** Expiration month on card. Example: "2" for February */
  expirationMonth?: InputMaybe<Scalars["String"]["input"]>;
  /** Full expiration year on card. Example: "2049" */
  expirationYear?: InputMaybe<Scalars["String"]["input"]>;
  primaryForCheckout?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type Input_core_apimessages_UpdateMyCreditCardResponse = {
  creditCard?: InputMaybe<Input_core_apimessages_CreditCard>;
};

export type Input_core_apimessages_UpdateMyGearCollectionSettingsRequest = {
  autoAddOrders?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type Input_core_apimessages_UpdateMyGearCollectionSettingsResponse = {
  gearCollectionSettings?: InputMaybe<Input_core_apimessages_GearCollectionSettingsResponse>;
};

export type Input_core_apimessages_UpdateMyShippingProfile = {
  categoryUuids?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  localPickup?: InputMaybe<Scalars["Boolean"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  offersIncrementalRates?: InputMaybe<Scalars["Boolean"]["input"]>;
  shippingRates?: InputMaybe<
    Array<InputMaybe<Input_core_apimessages_ShippingRates>>
  >;
};

export type Input_core_apimessages_UpdateMyShopCampaignRequest = {
  shopCampaignEntry?: InputMaybe<Input_core_apimessages_MyShopCampaignEntry>;
};

export type Input_core_apimessages_UpdateMyShopCampaignResponse = {
  shopCampaign?: InputMaybe<Input_core_apimessages_MyShopCampaign>;
};

export type Input_core_apimessages_UpdateMyShopRequest = {
  shop?: InputMaybe<Input_core_apimessages_ShopEntry>;
};

export type Input_core_apimessages_UpdateMyShopResponse = {
  address?: InputMaybe<Input_core_apimessages_Address>;
  errors?: InputMaybe<Array<InputMaybe<Input_core_apimessages_Error>>>;
  shop?: InputMaybe<Input_core_apimessages_Shop>;
};

export type Input_core_apimessages_UpdateMyShopShippingProfilesRequest = {
  shippingProfiles?: InputMaybe<
    Array<InputMaybe<Input_core_apimessages_UpdateMyShippingProfile>>
  >;
};

export type Input_core_apimessages_UpdateMyShopShippingProfilesResponse = {
  shippingProfiles?: InputMaybe<
    Array<InputMaybe<Input_core_apimessages_UpdateMyShippingProfile>>
  >;
};

export type Input_core_apimessages_UpdateNegotiationRequest = {
  action?: InputMaybe<core_apimessages_NegotiationAction>;
  conditionallyAcceptedBySeller?: InputMaybe<Scalars["Boolean"]["input"]>;
  creditCardId?: InputMaybe<Scalars["String"]["input"]>;
  /** negotiations.id */
  id?: InputMaybe<Scalars["String"]["input"]>;
  message?: InputMaybe<Scalars["String"]["input"]>;
  offerItems?: InputMaybe<
    Array<InputMaybe<Input_core_apimessages_OfferItemRequest>>
  >;
  shippingAddressUuid?: InputMaybe<Scalars["String"]["input"]>;
};

export type Input_core_apimessages_UpdateNegotiationResponse = {
  negotiation?: InputMaybe<Input_core_apimessages_Negotiation>;
};

export type Input_core_apimessages_UpdateProductReviewRequest = {
  body?: InputMaybe<Scalars["String"]["input"]>;
  comparisonShoppingPageId?: InputMaybe<Scalars["String"]["input"]>;
  rating?: InputMaybe<Scalars["Int"]["input"]>;
  title?: InputMaybe<Scalars["String"]["input"]>;
};

export type Input_core_apimessages_UpdateProductReviewResponse = {
  id?: InputMaybe<Scalars["String"]["input"]>;
};

export type Input_core_apimessages_UpdateReturnPolicyRequest = {
  newReturnWindowDays?: InputMaybe<Scalars["Int"]["input"]>;
  restockingFeePercent?: InputMaybe<Scalars["Int"]["input"]>;
  specialConditions?: InputMaybe<Scalars["String"]["input"]>;
  usedReturnWindowDays?: InputMaybe<Scalars["Int"]["input"]>;
};

export type Input_core_apimessages_UpdateReturnPolicyResponse = {
  errors?: InputMaybe<Array<InputMaybe<Input_core_apimessages_Error>>>;
  returnPolicy?: InputMaybe<Input_core_apimessages_ReturnPolicy>;
};

export type Input_core_apimessages_UpdateTaxIdentificationRequest = {
  taxIdentification?: InputMaybe<Input_core_apimessages_TaxIdentificationUpdate>;
};

export type Input_core_apimessages_UpdateTaxIdentificationResponse = {
  shopId?: InputMaybe<Scalars["String"]["input"]>;
  taxIdentification?: InputMaybe<Input_core_apimessages_TaxIdentification>;
};

export type Input_core_apimessages_UpdateUniversalPromoCampaignRequest = {
  universalPromoCampaign?: InputMaybe<Input_core_apimessages_UniversalPromoCampaign>;
};

export type Input_core_apimessages_UpdateUniversalPromoCampaignResponse = {
  universalPromoCampaign?: InputMaybe<Input_core_apimessages_UniversalPromoCampaign>;
};

export type Input_core_apimessages_UpdateUserShopPermissionRequest = {
  permissionLevel?: InputMaybe<core_apimessages_UserShopPermission_PermissionLevel>;
  userId?: InputMaybe<Scalars["String"]["input"]>;
};

export type Input_core_apimessages_UpdateWatchResponse = {
  id?: InputMaybe<Scalars["String"]["input"]>;
  listingId?: InputMaybe<Scalars["String"]["input"]>;
  userId?: InputMaybe<Scalars["String"]["input"]>;
};

/**
 *   UpsertMyFavoriteRequest field spec:
 *   - query_params should be used when favoriting a filtered query, a CSP (can include filters), Shop saved searches, etc.
 *   - shop_id should be used when favoriting a shop
 * We could have reused query_params to contain shop_id (e.g. "shop_id=1"), but doing seems
 * burdensome/possibly confusing for clients. So we are opting to keep this request proto explicit,
 * aware that as we add more types to Favorite (articles warrants article_id field), this proto will
 * grow in width.
 */
export type Input_core_apimessages_UpsertMyFavoriteRequest = {
  emailEnabled?: InputMaybe<Scalars["Boolean"]["input"]>;
  feedEnabled?: InputMaybe<Scalars["Boolean"]["input"]>;
  queryParams?: InputMaybe<Scalars["String"]["input"]>;
  shopId?: InputMaybe<Scalars["Int"]["input"]>;
};

export type Input_core_apimessages_UpsertMyFavoriteResponse = {
  errors?: InputMaybe<Array<InputMaybe<Input_core_apimessages_Error>>>;
  favorite?: InputMaybe<Input_core_apimessages_Favorite>;
};

export type Input_core_apimessages_User = {
  firstName?: InputMaybe<Scalars["String"]["input"]>;
  /** users.id */
  id?: InputMaybe<Scalars["String"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  shopUuid?: InputMaybe<Scalars["String"]["input"]>;
  shortname?: InputMaybe<Scalars["String"]["input"]>;
  uuid?: InputMaybe<Scalars["String"]["input"]>;
};

export type Input_core_apimessages_UserBadge = {
  Links?: InputMaybe<Input_core_apimessages_UserBadgeLinks>;
  badgeLinks?: InputMaybe<Input_core_apimessages_UserBadgeLinks>;
  count?: InputMaybe<Scalars["Int"]["input"]>;
  displayText?: InputMaybe<Scalars["String"]["input"]>;
};

export type Input_core_apimessages_UserBadgeLinks = {
  web?: InputMaybe<Input_core_apimessages_Link>;
};

export type Input_core_apimessages_UserNotification = {
  description?: InputMaybe<Scalars["String"]["input"]>;
  hidden?: InputMaybe<Scalars["Boolean"]["input"]>;
  key?: InputMaybe<core_apimessages_UserNotification_UserNotificationKey>;
  notificationLinks?: InputMaybe<Input_core_apimessages_UserNotificationLinks>;
  notificationType?: InputMaybe<core_apimessages_UserNotificationType>;
  title?: InputMaybe<Scalars["String"]["input"]>;
};

export type Input_core_apimessages_UserNotificationLinks = {
  web?: InputMaybe<Input_core_apimessages_Link>;
};

export type Input_core_apimessages_UserSearchResult = {
  email?: InputMaybe<Scalars["String"]["input"]>;
  fullName?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  shopId?: InputMaybe<Scalars["String"]["input"]>;
  shopName?: InputMaybe<Scalars["String"]["input"]>;
};

export type Input_core_apimessages_UserService = {
  /** The provider's user email, not Reverb's */
  email?: InputMaybe<Scalars["String"]["input"]>;
  /** internal id */
  id?: InputMaybe<Scalars["String"]["input"]>;
  provider?: InputMaybe<core_apimessages_UserService_Provider>;
};

export type Input_core_apimessages_UserShopPermission = {
  email?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  permissionLevel?: InputMaybe<core_apimessages_UserShopPermission_PermissionLevel>;
  userId?: InputMaybe<Scalars["String"]["input"]>;
};

export type Input_core_apimessages_UserWatchesRequest = {
  channel?: InputMaybe<core_apimessages_Channel>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  listingIds?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  listingsState?: InputMaybe<core_apimessages_UserWatchesRequest_ListingState>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  profileSlug?: InputMaybe<Scalars["String"]["input"]>;
};

export type Input_core_apimessages_UsersRequest = {
  fullname?: InputMaybe<Scalars["Boolean"]["input"]>;
  uuids?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
};

export type Input_core_apimessages_UsersResponse = {
  users?: InputMaybe<Array<InputMaybe<Input_core_apimessages_User>>>;
};

export type Input_core_apimessages_VerifyPromotionalCodeRequest = {
  token?: InputMaybe<Scalars["String"]["input"]>;
};

export type Input_core_apimessages_VerifyPromotionalCodeResponse = {
  message?: InputMaybe<Scalars["String"]["input"]>;
  success?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type Input_core_apimessages_Video = {
  empty?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** video_links.id */
  id?: InputMaybe<Scalars["String"]["input"]>;
  /** video_links.videolinkable_id (polymorphic foreign key, with 'item_type') */
  itemId?: InputMaybe<Scalars["String"]["input"]>;
  itemType?: InputMaybe<Scalars["String"]["input"]>;
  link?: InputMaybe<Scalars["String"]["input"]>;
  /** video_links.youtube_video_id */
  youtubeVideoId?: InputMaybe<Scalars["String"]["input"]>;
};

export type Input_core_apimessages_VideoLink = {
  youtubeLink?: InputMaybe<Scalars["String"]["input"]>;
  youtubeVideoId?: InputMaybe<Scalars["String"]["input"]>;
};

export type Input_core_apimessages_VideosRequest = {
  /** (products|shops).id */
  ids?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  slugs?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  type?: InputMaybe<core_apimessages_VideosRequest_VideolinkableType>;
  uuids?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
};

export type Input_core_apimessages_VideosResponse = {
  videos?: InputMaybe<Array<InputMaybe<Input_core_apimessages_Video>>>;
};

export type Input_core_apimessages_WatchResponse = {
  listingId?: InputMaybe<Scalars["String"]["input"]>;
  watching?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type Input_core_apimessages_WatchesCreateRequest = {
  bumpKey?: InputMaybe<Input_core_apimessages_BumpKey>;
  channel?: InputMaybe<core_apimessages_Channel>;
  listingId?: InputMaybe<Scalars["String"]["input"]>;
};

export type Input_core_apimessages_WatchesDeleteRequest = {
  listingId?: InputMaybe<Scalars["String"]["input"]>;
};

export type Input_core_apimessages_WatchesRequest = {
  channel?: InputMaybe<core_apimessages_Channel>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  listingIds?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  listingsState?: InputMaybe<core_apimessages_WatchesRequest_ListingState>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
};

export type Input_core_apimessages_WatchesResponse = {
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  total?: InputMaybe<Scalars["Int"]["input"]>;
  watches?: InputMaybe<Array<InputMaybe<Input_core_apimessages_WatchResponse>>>;
};

/** A custom pattern is used for defining custom HTTP verb. */
export type Input_google_api_CustomHttpPattern = {
  /** The name of this custom HTTP verb. */
  kind?: InputMaybe<Scalars["String"]["input"]>;
  /** The path matched by this custom verb. */
  path?: InputMaybe<Scalars["String"]["input"]>;
};

/**
 *   Defines the HTTP configuration for an API service. It contains a list of
 * [HttpRule][google.api.HttpRule], each specifying the mapping of an RPC method
 * to one or more HTTP REST API methods.
 */
export type Input_google_api_Http = {
  /**
   *   When set to true, URL path parmeters will be fully URI-decoded except in
   * cases of single segment matches in reserved expansion, where "%2F" will be
   * left encoded.
   *
   * The default behavior is to not decode RFC 6570 reserved characters in multi
   * segment matches.
   */
  fullyDecodeReservedExpansion?: InputMaybe<Scalars["Boolean"]["input"]>;
  /**
   *   A list of HTTP configuration rules that apply to individual API methods.
   *
   * **NOTE:** All service configuration rules follow "last one wins" order.
   */
  rules?: InputMaybe<Array<InputMaybe<Input_google_api_HttpRule>>>;
};

/**
 *   `HttpRule` defines the mapping of an RPC method to one or more HTTP
 * REST API methods. The mapping specifies how different portions of the RPC
 * request message are mapped to URL path, URL query parameters, and
 * HTTP request body. The mapping is typically specified as an
 * `google.api.http` annotation on the RPC method,
 * see "google/api/annotations.proto" for details.
 *
 * The mapping consists of a field specifying the path template and
 * method kind.  The path template can refer to fields in the request
 * message, as in the example below which describes a REST GET
 * operation on a resource collection of messages:
 *
 *
 *     service Messaging {
 *       rpc GetMessage(GetMessageRequest) returns (Message) {
 *         option (google.api.http).get = "/v1/messages/{message_id}/{sub.subfield}";
 *       }
 *     }
 *     message GetMessageRequest {
 *       message SubMessage {
 *         string subfield = 1;
 *       }
 *       string message_id = 1; // mapped to the URL
 *       SubMessage sub = 2;    // `sub.subfield` is url-mapped
 *     }
 *     message Message {
 *       string text = 1; // content of the resource
 *     }
 *
 * The same http annotation can alternatively be expressed inside the
 * `GRPC API Configuration` YAML file.
 *
 *     http:
 *       rules:
 *         - selector: <proto_package_name>.Messaging.GetMessage
 *           get: /v1/messages/{message_id}/{sub.subfield}
 *
 * This definition enables an automatic, bidrectional mapping of HTTP
 * JSON to RPC. Example:
 *
 * HTTP | RPC
 * -----|-----
 * `GET /v1/messages/123456/foo`  | `GetMessage(message_id: "123456" sub: SubMessage(subfield: "foo"))`
 *
 * In general, not only fields but also field paths can be referenced
 * from a path pattern. Fields mapped to the path pattern cannot be
 * repeated and must have a primitive (non-message) type.
 *
 * Any fields in the request message which are not bound by the path
 * pattern automatically become (optional) HTTP query
 * parameters. Assume the following definition of the request message:
 *
 *
 *     service Messaging {
 *       rpc GetMessage(GetMessageRequest) returns (Message) {
 *         option (google.api.http).get = "/v1/messages/{message_id}";
 *       }
 *     }
 *     message GetMessageRequest {
 *       message SubMessage {
 *         string subfield = 1;
 *       }
 *       string message_id = 1; // mapped to the URL
 *       int64 revision = 2;    // becomes a parameter
 *       SubMessage sub = 3;    // `sub.subfield` becomes a parameter
 *     }
 *
 *
 * This enables a HTTP JSON to RPC mapping as below:
 *
 * HTTP | RPC
 * -----|-----
 * `GET /v1/messages/123456?revision=2&sub.subfield=foo` | `GetMessage(message_id: "123456" revision: 2 sub: SubMessage(subfield: "foo"))`
 *
 * Note that fields which are mapped to HTTP parameters must have a
 * primitive type or a repeated primitive type. Message types are not
 * allowed. In the case of a repeated type, the parameter can be
 * repeated in the URL, as in `...?param=A&param=B`.
 *
 * For HTTP method kinds which allow a request body, the `body` field
 * specifies the mapping. Consider a REST update method on the
 * message resource collection:
 *
 *
 *     service Messaging {
 *       rpc UpdateMessage(UpdateMessageRequest) returns (Message) {
 *         option (google.api.http) = {
 *           put: "/v1/messages/{message_id}"
 *           body: "message"
 *         };
 *       }
 *     }
 *     message UpdateMessageRequest {
 *       string message_id = 1; // mapped to the URL
 *       Message message = 2;   // mapped to the body
 *     }
 *
 *
 * The following HTTP JSON to RPC mapping is enabled, where the
 * representation of the JSON in the request body is determined by
 * protos JSON encoding:
 *
 * HTTP | RPC
 * -----|-----
 * `PUT /v1/messages/123456 { "text": "Hi!" }` | `UpdateMessage(message_id: "123456" message { text: "Hi!" })`
 *
 * The special name `*` can be used in the body mapping to define that
 * every field not bound by the path template should be mapped to the
 * request body.  This enables the following alternative definition of
 * the update method:
 *
 *     service Messaging {
 *       rpc UpdateMessage(Message) returns (Message) {
 *         option (google.api.http) = {
 *           put: "/v1/messages/{message_id}"
 *           body: "*"
 *         };
 *       }
 *     }
 *     message Message {
 *       string message_id = 1;
 *       string text = 2;
 *     }
 *
 *
 * The following HTTP JSON to RPC mapping is enabled:
 *
 * HTTP | RPC
 * -----|-----
 * `PUT /v1/messages/123456 { "text": "Hi!" }` | `UpdateMessage(message_id: "123456" text: "Hi!")`
 *
 * Note that when using `*` in the body mapping, it is not possible to
 * have HTTP parameters, as all fields not bound by the path end in
 * the body. This makes this option more rarely used in practice of
 * defining REST APIs. The common usage of `*` is in custom methods
 * which don't use the URL at all for transferring data.
 *
 * It is possible to define multiple HTTP methods for one RPC by using
 * the `additional_bindings` option. Example:
 *
 *     service Messaging {
 *       rpc GetMessage(GetMessageRequest) returns (Message) {
 *         option (google.api.http) = {
 *           get: "/v1/messages/{message_id}"
 *           additional_bindings {
 *             get: "/v1/users/{user_id}/messages/{message_id}"
 *           }
 *         };
 *       }
 *     }
 *     message GetMessageRequest {
 *       string message_id = 1;
 *       string user_id = 2;
 *     }
 *
 *
 * This enables the following two alternative HTTP JSON to RPC
 * mappings:
 *
 * HTTP | RPC
 * -----|-----
 * `GET /v1/messages/123456` | `GetMessage(message_id: "123456")`
 * `GET /v1/users/me/messages/123456` | `GetMessage(user_id: "me" message_id: "123456")`
 *
 * # Rules for HTTP mapping
 *
 * The rules for mapping HTTP path, query parameters, and body fields
 * to the request message are as follows:
 *
 * 1. The `body` field specifies either `*` or a field path, or is
 *    omitted. If omitted, it indicates there is no HTTP request body.
 * 2. Leaf fields (recursive expansion of nested messages in the
 *    request) can be classified into three types:
 *     (a) Matched in the URL template.
 *     (b) Covered by body (if body is `*`, everything except (a) fields;
 *         else everything under the body field)
 *     (c) All other fields.
 * 3. URL query parameters found in the HTTP request are mapped to (c) fields.
 * 4. Any body sent with an HTTP request can contain only (b) fields.
 *
 * The syntax of the path template is as follows:
 *
 *     Template = "/" Segments [ Verb ] ;
 *     Segments = Segment { "/" Segment } ;
 *     Segment  = "*" | "**" | LITERAL | Variable ;
 *     Variable = "{" FieldPath [ "=" Segments ] "}" ;
 *     FieldPath = IDENT { "." IDENT } ;
 *     Verb     = ":" LITERAL ;
 *
 * The syntax `*` matches a single path segment. The syntax `**` matches zero
 * or more path segments, which must be the last part of the path except the
 * `Verb`. The syntax `LITERAL` matches literal text in the path.
 *
 * The syntax `Variable` matches part of the URL path as specified by its
 * template. A variable template must not contain other variables. If a variable
 * matches a single path segment, its template may be omitted, e.g. `{var}`
 * is equivalent to `{var=*}`.
 *
 * If a variable contains exactly one path segment, such as `"{var}"` or
 * `"{var=*}"`, when such a variable is expanded into a URL path, all characters
 * except `[-_.~0-9a-zA-Z]` are percent-encoded. Such variables show up in the
 * Discovery Document as `{var}`.
 *
 * If a variable contains one or more path segments, such as `"{var=foo/*}"`
 * or `"{var=**}"`, when such a variable is expanded into a URL path, all
 * characters except `[-_.~/0-9a-zA-Z]` are percent-encoded. Such variables
 * show up in the Discovery Document as `{+var}`.
 *
 * NOTE: While the single segment variable matches the semantics of
 * [RFC 6570](https://tools.ietf.org/html/rfc6570) Section 3.2.2
 * Simple String Expansion, the multi segment variable **does not** match
 * RFC 6570 Reserved Expansion. The reason is that the Reserved Expansion
 * does not expand special characters like `?` and `#`, which would lead
 * to invalid URLs.
 *
 * NOTE: the field paths in variables and in the `body` must not refer to
 * repeated fields or map fields.
 */
export type Input_google_api_HttpRule = {
  /**
   *   Additional HTTP bindings for the selector. Nested bindings must
   * not contain an `additional_bindings` field themselves (that is,
   * the nesting may only be one level deep).
   */
  additionalBindings?: InputMaybe<Array<InputMaybe<Input_google_api_HttpRule>>>;
  /**
   *   The name of the request field whose value is mapped to the HTTP body, or
   * `*` for mapping all fields not captured by the path pattern to the HTTP
   * body. NOTE: the referred field must not be a repeated field and must be
   * present at the top-level of request message type.
   */
  body?: InputMaybe<Scalars["String"]["input"]>;
  /**
   *   The custom pattern is used for specifying an HTTP method that is not
   * included in the `pattern` field, such as HEAD, or "*" to leave the
   * HTTP method unspecified for this rule. The wild-card rule is useful
   * for services that provide content to Web (HTML) clients.
   */
  custom?: InputMaybe<Input_google_api_CustomHttpPattern>;
  /** Used for deleting a resource. */
  delete?: InputMaybe<Scalars["String"]["input"]>;
  /** Used for listing and getting information about resources. */
  get?: InputMaybe<Scalars["String"]["input"]>;
  /** Used for updating a resource. */
  patch?: InputMaybe<Scalars["String"]["input"]>;
  /** Used for creating a resource. */
  post?: InputMaybe<Scalars["String"]["input"]>;
  /** Used for updating a resource. */
  put?: InputMaybe<Scalars["String"]["input"]>;
  /**
   *   Selects methods to which this rule applies.
   *
   * Refer to [selector][google.api.DocumentationRule.selector] for syntax details.
   */
  selector?: InputMaybe<Scalars["String"]["input"]>;
};

/**
 *   Wrapper message for `bool`.
 *
 * The JSON representation for `BoolValue` is JSON `true` and `false`.
 */
export type Input_google_protobuf_BoolValue = {
  /** The bool value. */
  value?: InputMaybe<Scalars["Boolean"]["input"]>;
};

/**
 *   Wrapper message for `bytes`.
 *
 * The JSON representation for `BytesValue` is JSON string.
 */
export type Input_google_protobuf_BytesValue = {
  /** The bytes value. */
  value?: InputMaybe<Scalars["String"]["input"]>;
};

/** Describes a message type. */
export type Input_google_protobuf_DescriptorProto = {
  enumType?: InputMaybe<
    Array<InputMaybe<Input_google_protobuf_EnumDescriptorProto>>
  >;
  extension?: InputMaybe<
    Array<InputMaybe<Input_google_protobuf_FieldDescriptorProto>>
  >;
  extensionRange?: InputMaybe<
    Array<InputMaybe<Input_google_protobuf_DescriptorProto_ExtensionRange>>
  >;
  field?: InputMaybe<
    Array<InputMaybe<Input_google_protobuf_FieldDescriptorProto>>
  >;
  name?: InputMaybe<Scalars["String"]["input"]>;
  nestedType?: InputMaybe<
    Array<InputMaybe<Input_google_protobuf_DescriptorProto>>
  >;
  oneofDecl?: InputMaybe<
    Array<InputMaybe<Input_google_protobuf_OneofDescriptorProto>>
  >;
  options?: InputMaybe<Input_google_protobuf_MessageOptions>;
  /**
   *   Reserved field names, which may not be used by fields in the same message.
   * A given name may only be reserved once.
   */
  reservedName?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  reservedRange?: InputMaybe<
    Array<InputMaybe<Input_google_protobuf_DescriptorProto_ReservedRange>>
  >;
};

export type Input_google_protobuf_DescriptorProto_ExtensionRange = {
  end?: InputMaybe<Scalars["Int"]["input"]>;
  options?: InputMaybe<Input_google_protobuf_ExtensionRangeOptions>;
  start?: InputMaybe<Scalars["Int"]["input"]>;
};

/**
 *   Range of reserved tag numbers. Reserved tag numbers may not be used by
 * fields or extension ranges in the same message. Reserved ranges may
 * not overlap.
 */
export type Input_google_protobuf_DescriptorProto_ReservedRange = {
  /** Exclusive. */
  end?: InputMaybe<Scalars["Int"]["input"]>;
  /** Inclusive. */
  start?: InputMaybe<Scalars["Int"]["input"]>;
};

/**
 *   Wrapper message for `double`.
 *
 * The JSON representation for `DoubleValue` is JSON number.
 */
export type Input_google_protobuf_DoubleValue = {
  /** The double value. */
  value?: InputMaybe<Scalars["Float"]["input"]>;
};

/**
 *   A Duration represents a signed, fixed-length span of time represented
 * as a count of seconds and fractions of seconds at nanosecond
 * resolution. It is independent of any calendar and concepts like "day"
 * or "month". It is related to Timestamp in that the difference between
 * two Timestamp values is a Duration and it can be added or subtracted
 * from a Timestamp. Range is approximately +-10,000 years.
 *
 * # Examples
 *
 * Example 1: Compute Duration from two Timestamps in pseudo code.
 *
 *     Timestamp start = ...;
 *     Timestamp end = ...;
 *     Duration duration = ...;
 *
 *     duration.seconds = end.seconds - start.seconds;
 *     duration.nanos = end.nanos - start.nanos;
 *
 *     if (duration.seconds < 0 && duration.nanos > 0) {
 *       duration.seconds += 1;
 *       duration.nanos -= 1000000000;
 *     } else if (durations.seconds > 0 && duration.nanos < 0) {
 *       duration.seconds -= 1;
 *       duration.nanos += 1000000000;
 *     }
 *
 * Example 2: Compute Timestamp from Timestamp + Duration in pseudo code.
 *
 *     Timestamp start = ...;
 *     Duration duration = ...;
 *     Timestamp end = ...;
 *
 *     end.seconds = start.seconds + duration.seconds;
 *     end.nanos = start.nanos + duration.nanos;
 *
 *     if (end.nanos < 0) {
 *       end.seconds -= 1;
 *       end.nanos += 1000000000;
 *     } else if (end.nanos >= 1000000000) {
 *       end.seconds += 1;
 *       end.nanos -= 1000000000;
 *     }
 *
 * Example 3: Compute Duration from datetime.timedelta in Python.
 *
 *     td = datetime.timedelta(days=3, minutes=10)
 *     duration = Duration()
 *     duration.FromTimedelta(td)
 *
 * # JSON Mapping
 *
 * In JSON format, the Duration type is encoded as a string rather than an
 * object, where the string ends in the suffix "s" (indicating seconds) and
 * is preceded by the number of seconds, with nanoseconds expressed as
 * fractional seconds. For example, 3 seconds with 0 nanoseconds should be
 * encoded in JSON format as "3s", while 3 seconds and 1 nanosecond should
 * be expressed in JSON format as "3.000000001s", and 3 seconds and 1
 * microsecond should be expressed in JSON format as "3.000001s".
 */
export type Input_google_protobuf_Duration = {
  /**
   *   Signed fractions of a second at nanosecond resolution of the span
   * of time. Durations less than one second are represented with a 0
   * `seconds` field and a positive or negative `nanos` field. For durations
   * of one second or more, a non-zero value for the `nanos` field must be
   * of the same sign as the `seconds` field. Must be from -999,999,999
   * to +999,999,999 inclusive.
   */
  nanos?: InputMaybe<Scalars["Int"]["input"]>;
  /**
   *   Signed seconds of the span of time. Must be from -315,576,000,000
   * to +315,576,000,000 inclusive. Note: these bounds are computed from:
   * 60 sec/min * 60 min/hr * 24 hr/day * 365.25 days/year * 10000 years
   */
  seconds?: InputMaybe<Scalars["Int"]["input"]>;
};

/**
 *   A generic empty message that you can re-use to avoid defining duplicated
 * empty messages in your APIs. A typical example is to use it as the request
 * or the response type of an API method. For instance:
 *
 *     service Foo {
 *       rpc Bar(google.protobuf.Empty) returns (google.protobuf.Empty);
 *     }
 *
 * The JSON representation for `Empty` is empty JSON object `{}`.
 */
export type Input_google_protobuf_Empty = {
  _?: InputMaybe<Scalars["Boolean"]["input"]>;
};

/** Describes an enum type. */
export type Input_google_protobuf_EnumDescriptorProto = {
  name?: InputMaybe<Scalars["String"]["input"]>;
  options?: InputMaybe<Input_google_protobuf_EnumOptions>;
  /**
   *   Reserved enum value names, which may not be reused. A given name may only
   * be reserved once.
   */
  reservedName?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  /**
   *   Range of reserved numeric values. Reserved numeric values may not be used
   * by enum values in the same enum declaration. Reserved ranges may not
   * overlap.
   */
  reservedRange?: InputMaybe<
    Array<
      InputMaybe<Input_google_protobuf_EnumDescriptorProto_EnumReservedRange>
    >
  >;
  value?: InputMaybe<
    Array<InputMaybe<Input_google_protobuf_EnumValueDescriptorProto>>
  >;
};

/**
 *   Range of reserved numeric values. Reserved values may not be used by
 * entries in the same enum. Reserved ranges may not overlap.
 *
 * Note that this is distinct from DescriptorProto.ReservedRange in that it
 * is inclusive such that it can appropriately represent the entire int32
 * domain.
 */
export type Input_google_protobuf_EnumDescriptorProto_EnumReservedRange = {
  /** Inclusive. */
  end?: InputMaybe<Scalars["Int"]["input"]>;
  /** Inclusive. */
  start?: InputMaybe<Scalars["Int"]["input"]>;
};

export type Input_google_protobuf_EnumOptions = {
  /**
   *   Set this option to true to allow mapping different tag names to the same
   * value.
   */
  allowAlias?: InputMaybe<Scalars["Boolean"]["input"]>;
  /**
   *   Is this enum deprecated?
   * Depending on the target platform, this can emit Deprecated annotations
   * for the enum, or it will be completely ignored; in the very least, this
   * is a formalization for deprecating enums.
   */
  deprecated?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** The parser stores options it doesn't recognize here. See above. */
  uninterpretedOption?: InputMaybe<
    Array<InputMaybe<Input_google_protobuf_UninterpretedOption>>
  >;
};

/** Describes a value within an enum. */
export type Input_google_protobuf_EnumValueDescriptorProto = {
  name?: InputMaybe<Scalars["String"]["input"]>;
  number?: InputMaybe<Scalars["Int"]["input"]>;
  options?: InputMaybe<Input_google_protobuf_EnumValueOptions>;
};

export type Input_google_protobuf_EnumValueOptions = {
  /**
   *   Is this enum value deprecated?
   * Depending on the target platform, this can emit Deprecated annotations
   * for the enum value, or it will be completely ignored; in the very least,
   * this is a formalization for deprecating enum values.
   */
  deprecated?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** The parser stores options it doesn't recognize here. See above. */
  uninterpretedOption?: InputMaybe<
    Array<InputMaybe<Input_google_protobuf_UninterpretedOption>>
  >;
};

export type Input_google_protobuf_ExtensionRangeOptions = {
  /** The parser stores options it doesn't recognize here. See above. */
  uninterpretedOption?: InputMaybe<
    Array<InputMaybe<Input_google_protobuf_UninterpretedOption>>
  >;
};

/** Describes a field within a message. */
export type Input_google_protobuf_FieldDescriptorProto = {
  /**
   *   For numeric types, contains the original text representation of the value.
   * For booleans, "true" or "false".
   * For strings, contains the default text contents (not escaped in any way).
   * For bytes, contains the C escaped value.  All bytes >= 128 are escaped.
   * TODO(kenton):  Base-64 encode?
   */
  defaultValue?: InputMaybe<Scalars["String"]["input"]>;
  /**
   *   For extensions, this is the name of the type being extended.  It is
   * resolved in the same manner as type_name.
   */
  extendee?: InputMaybe<Scalars["String"]["input"]>;
  /**
   *   JSON name of this field. The value is set by protocol compiler. If the
   * user has set a "json_name" option on this field, that option's value
   * will be used. Otherwise, it's deduced from the field's name by converting
   * it to camelCase.
   */
  jsonName?: InputMaybe<Scalars["String"]["input"]>;
  label?: InputMaybe<google_protobuf_FieldDescriptorProto_Label>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  number?: InputMaybe<Scalars["Int"]["input"]>;
  /**
   *   If set, gives the index of a oneof in the containing type's oneof_decl
   * list.  This field is a member of that oneof.
   */
  oneofIndex?: InputMaybe<Scalars["Int"]["input"]>;
  options?: InputMaybe<Input_google_protobuf_FieldOptions>;
  /**
   *   If type_name is set, this need not be set.  If both this and type_name
   * are set, this must be one of TYPE_ENUM, TYPE_MESSAGE or TYPE_GROUP.
   */
  type?: InputMaybe<google_protobuf_FieldDescriptorProto_Type>;
  /**
   *   For message and enum types, this is the name of the type.  If the name
   * starts with a '.', it is fully-qualified.  Otherwise, C++-like scoping
   * rules are used to find the type (i.e. first the nested types within this
   * message are searched, then within the parent, on up to the root
   * namespace).
   */
  typeName?: InputMaybe<Scalars["String"]["input"]>;
};

export type Input_google_protobuf_FieldOptions = {
  /**
   *   The ctype option instructs the C++ code generator to use a different
   * representation of the field than it normally would.  See the specific
   * options below.  This option is not yet implemented in the open source
   * release -- sorry, we'll try to include it in a future version!
   */
  ctype?: InputMaybe<google_protobuf_FieldOptions_CType>;
  /**
   *   Is this field deprecated?
   * Depending on the target platform, this can emit Deprecated annotations
   * for accessors, or it will be completely ignored; in the very least, this
   * is a formalization for deprecating fields.
   */
  deprecated?: InputMaybe<Scalars["Boolean"]["input"]>;
  /**
   *   The jstype option determines the JavaScript type used for values of the
   * field.  The option is permitted only for 64 bit integral and fixed types
   * (int64, uint64, sint64, fixed64, sfixed64).  A field with jstype JS_STRING
   * is represented as JavaScript string, which avoids loss of precision that
   * can happen when a large value is converted to a floating point JavaScript.
   * Specifying JS_NUMBER for the jstype causes the generated JavaScript code to
   * use the JavaScript "number" type.  The behavior of the default option
   * JS_NORMAL is implementation dependent.
   *
   * This option is an enum to permit additional types to be added, e.g.
   * goog.math.Integer.
   */
  jstype?: InputMaybe<google_protobuf_FieldOptions_JSType>;
  /**
   *   Should this field be parsed lazily?  Lazy applies only to message-type
   * fields.  It means that when the outer message is initially parsed, the
   * inner message's contents will not be parsed but instead stored in encoded
   * form.  The inner message will actually be parsed when it is first accessed.
   *
   * This is only a hint.  Implementations are free to choose whether to use
   * eager or lazy parsing regardless of the value of this option.  However,
   * setting this option true suggests that the protocol author believes that
   * using lazy parsing on this field is worth the additional bookkeeping
   * overhead typically needed to implement it.
   *
   * This option does not affect the public interface of any generated code;
   * all method signatures remain the same.  Furthermore, thread-safety of the
   * interface is not affected by this option; const methods remain safe to
   * call from multiple threads concurrently, while non-const methods continue
   * to require exclusive access.
   *
   *
   * Note that implementations may choose not to check required fields within
   * a lazy sub-message.  That is, calling IsInitialized() on the outer message
   * may return true even if the inner message has missing required fields.
   * This is necessary because otherwise the inner message would have to be
   * parsed in order to perform the check, defeating the purpose of lazy
   * parsing.  An implementation which chooses not to check required fields
   * must be consistent about it.  That is, for any particular sub-message, the
   * implementation must either *always* check its required fields, or *never*
   * check its required fields, regardless of whether or not the message has
   * been parsed.
   */
  lazy?: InputMaybe<Scalars["Boolean"]["input"]>;
  /**
   *   The packed option can be enabled for repeated primitive fields to enable
   * a more efficient representation on the wire. Rather than repeatedly
   * writing the tag and type for each element, the entire array is encoded as
   * a single length-delimited blob. In proto3, only explicit setting it to
   * false will avoid using packed encoding.
   */
  packed?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** The parser stores options it doesn't recognize here. See above. */
  uninterpretedOption?: InputMaybe<
    Array<InputMaybe<Input_google_protobuf_UninterpretedOption>>
  >;
  /** For Google-internal migration only. Do not use. */
  weak?: InputMaybe<Scalars["Boolean"]["input"]>;
};

/** Describes a complete .proto file. */
export type Input_google_protobuf_FileDescriptorProto = {
  /** Names of files imported by this file. */
  dependency?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  enumType?: InputMaybe<
    Array<InputMaybe<Input_google_protobuf_EnumDescriptorProto>>
  >;
  extension?: InputMaybe<
    Array<InputMaybe<Input_google_protobuf_FieldDescriptorProto>>
  >;
  /** All top-level definitions in this file. */
  messageType?: InputMaybe<
    Array<InputMaybe<Input_google_protobuf_DescriptorProto>>
  >;
  /** file name, relative to root of source tree */
  name?: InputMaybe<Scalars["String"]["input"]>;
  options?: InputMaybe<Input_google_protobuf_FileOptions>;
  /** e.g. "foo", "foo.bar", etc. */
  package?: InputMaybe<Scalars["String"]["input"]>;
  /** Indexes of the public imported files in the dependency list above. */
  publicDependency?: InputMaybe<Array<InputMaybe<Scalars["Int"]["input"]>>>;
  service?: InputMaybe<
    Array<InputMaybe<Input_google_protobuf_ServiceDescriptorProto>>
  >;
  /**
   *   This field contains optional information about the original source code.
   * You may safely remove this entire field without harming runtime
   * functionality of the descriptors -- the information is needed only by
   * development tools.
   */
  sourceCodeInfo?: InputMaybe<Input_google_protobuf_SourceCodeInfo>;
  /**
   *   The syntax of the proto file.
   * The supported values are "proto2" and "proto3".
   */
  syntax?: InputMaybe<Scalars["String"]["input"]>;
  /**
   *   Indexes of the weak imported files in the dependency list.
   * For Google-internal migration only. Do not use.
   */
  weakDependency?: InputMaybe<Array<InputMaybe<Scalars["Int"]["input"]>>>;
};

/**
 *   The protocol compiler can output a FileDescriptorSet containing the .proto
 * files it parses.
 */
export type Input_google_protobuf_FileDescriptorSet = {
  file?: InputMaybe<
    Array<InputMaybe<Input_google_protobuf_FileDescriptorProto>>
  >;
};

export type Input_google_protobuf_FileOptions = {
  /**
   *   Enables the use of arenas for the proto messages in this file. This applies
   * only to generated classes for C++.
   */
  ccEnableArenas?: InputMaybe<Scalars["Boolean"]["input"]>;
  /**
   *   Should generic services be generated in each language?  "Generic" services
   * are not specific to any particular RPC system.  They are generated by the
   * main code generators in each language (without additional plugins).
   * Generic services were the only kind of service generation supported by
   * early versions of google.protobuf.
   *
   * Generic services are now considered deprecated in favor of using plugins
   * that generate code specific to your particular RPC system.  Therefore,
   * these default to false.  Old code which depends on generic services should
   * explicitly set them to true.
   */
  ccGenericServices?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Namespace for generated classes; defaults to the package. */
  csharpNamespace?: InputMaybe<Scalars["String"]["input"]>;
  /**
   *   Is this file deprecated?
   * Depending on the target platform, this can emit Deprecated annotations
   * for everything in the file, or it will be completely ignored; in the very
   * least, this is a formalization for deprecating files.
   */
  deprecated?: InputMaybe<Scalars["Boolean"]["input"]>;
  /**
   *   Sets the Go package where structs generated from this .proto will be
   * placed. If omitted, the Go package will be derived from the following:
   *   - The basename of the package import path, if provided.
   *   - Otherwise, the package statement in the .proto file, if present.
   *   - Otherwise, the basename of the .proto file, without extension.
   */
  goPackage?: InputMaybe<Scalars["String"]["input"]>;
  /** This option does nothing. */
  javaGenerateEqualsAndHash?: InputMaybe<Scalars["Boolean"]["input"]>;
  javaGenericServices?: InputMaybe<Scalars["Boolean"]["input"]>;
  /**
   *   If set true, then the Java code generator will generate a separate .java
   * file for each top-level message, enum, and service defined in the .proto
   * file.  Thus, these types will *not* be nested inside the outer class
   * named by java_outer_classname.  However, the outer class will still be
   * generated to contain the file's getDescriptor() method as well as any
   * top-level extensions defined in the file.
   */
  javaMultipleFiles?: InputMaybe<Scalars["Boolean"]["input"]>;
  /**
   *   If set, all the classes from the .proto file are wrapped in a single
   * outer class with the given name.  This applies to both Proto1
   * (equivalent to the old "--one_java_file" option) and Proto2 (where
   * a .proto always translates to a single class, but you may want to
   * explicitly choose the class name).
   */
  javaOuterClassname?: InputMaybe<Scalars["String"]["input"]>;
  /**
   *   Sets the Java package where classes generated from this .proto will be
   * placed.  By default, the proto package is used, but this is often
   * inappropriate because proto packages do not normally start with backwards
   * domain names.
   */
  javaPackage?: InputMaybe<Scalars["String"]["input"]>;
  /**
   *   If set true, then the Java2 code generator will generate code that
   * throws an exception whenever an attempt is made to assign a non-UTF-8
   * byte sequence to a string field.
   * Message reflection will do the same.
   * However, an extension field still accepts non-UTF-8 byte sequences.
   * This option has no effect on when used with the lite runtime.
   */
  javaStringCheckUtf8?: InputMaybe<Scalars["Boolean"]["input"]>;
  /**
   *   Sets the objective c class prefix which is prepended to all objective c
   * generated classes from this .proto. There is no default.
   */
  objcClassPrefix?: InputMaybe<Scalars["String"]["input"]>;
  optimizeFor?: InputMaybe<google_protobuf_FileOptions_OptimizeMode>;
  /**
   *   Sets the php class prefix which is prepended to all php generated classes
   * from this .proto. Default is empty.
   */
  phpClassPrefix?: InputMaybe<Scalars["String"]["input"]>;
  phpGenericServices?: InputMaybe<Scalars["Boolean"]["input"]>;
  /**
   *   Use this option to change the namespace of php generated classes. Default
   * is empty. When this option is empty, the package name will be used for
   * determining the namespace.
   */
  phpNamespace?: InputMaybe<Scalars["String"]["input"]>;
  pyGenericServices?: InputMaybe<Scalars["Boolean"]["input"]>;
  /**
   *   By default Swift generators will take the proto package and CamelCase it
   * replacing '.' with underscore and use that to prefix the types/symbols
   * defined. When this options is provided, they will use this value instead
   * to prefix the types/symbols defined.
   */
  swiftPrefix?: InputMaybe<Scalars["String"]["input"]>;
  /**
   *   The parser stores options it doesn't recognize here.
   * See the documentation for the "Options" section above.
   */
  uninterpretedOption?: InputMaybe<
    Array<InputMaybe<Input_google_protobuf_UninterpretedOption>>
  >;
};

/**
 *   Wrapper message for `float`.
 *
 * The JSON representation for `FloatValue` is JSON number.
 */
export type Input_google_protobuf_FloatValue = {
  /** The float value. */
  value?: InputMaybe<Scalars["Float"]["input"]>;
};

/**
 *   Describes the relationship between generated code and its original source
 * file. A GeneratedCodeInfo message is associated with only one generated
 * source file, but may contain references to different source .proto files.
 */
export type Input_google_protobuf_GeneratedCodeInfo = {
  /**
   *   An Annotation connects some span of text in generated code to an element
   * of its generating .proto file.
   */
  annotation?: InputMaybe<
    Array<InputMaybe<Input_google_protobuf_GeneratedCodeInfo_Annotation>>
  >;
};

export type Input_google_protobuf_GeneratedCodeInfo_Annotation = {
  /**
   *   Identifies the starting offset in bytes in the generated code
   * that relates to the identified object.
   */
  begin?: InputMaybe<Scalars["Int"]["input"]>;
  /**
   *   Identifies the ending offset in bytes in the generated code that
   * relates to the identified offset. The end offset should be one past
   * the last relevant byte (so the length of the text = end - begin).
   */
  end?: InputMaybe<Scalars["Int"]["input"]>;
  /**
   *   Identifies the element in the original source .proto file. This field
   * is formatted the same as SourceCodeInfo.Location.path.
   */
  path?: InputMaybe<Array<InputMaybe<Scalars["Int"]["input"]>>>;
  /** Identifies the filesystem path to the original source .proto. */
  sourceFile?: InputMaybe<Scalars["String"]["input"]>;
};

/**
 *   Wrapper message for `int32`.
 *
 * The JSON representation for `Int32Value` is JSON number.
 */
export type Input_google_protobuf_Int32Value = {
  /** The int32 value. */
  value?: InputMaybe<Scalars["Int"]["input"]>;
};

/**
 *   Wrapper message for `int64`.
 *
 * The JSON representation for `Int64Value` is JSON string.
 */
export type Input_google_protobuf_Int64Value = {
  /** The int64 value. */
  value?: InputMaybe<Scalars["Int"]["input"]>;
};

export type Input_google_protobuf_MessageOptions = {
  /**
   *   Is this message deprecated?
   * Depending on the target platform, this can emit Deprecated annotations
   * for the message, or it will be completely ignored; in the very least,
   * this is a formalization for deprecating messages.
   */
  deprecated?: InputMaybe<Scalars["Boolean"]["input"]>;
  /**
   *   Whether the message is an automatically generated map entry type for the
   * maps field.
   *
   * For maps fields:
   *     map<KeyType, ValueType> map_field = 1;
   * The parsed descriptor looks like:
   *     message MapFieldEntry {
   *         option map_entry = true;
   *         optional KeyType key = 1;
   *         optional ValueType value = 2;
   *     }
   *     repeated MapFieldEntry map_field = 1;
   *
   * Implementations may choose not to generate the map_entry=true message, but
   * use a native map in the target language to hold the keys and values.
   * The reflection APIs in such implementions still need to work as
   * if the field is a repeated message field.
   *
   * NOTE: Do not set the option in .proto files. Always use the maps syntax
   * instead. The option should only be implicitly set by the proto compiler
   * parser.
   */
  mapEntry?: InputMaybe<Scalars["Boolean"]["input"]>;
  /**
   *   Set true to use the old proto1 MessageSet wire format for extensions.
   * This is provided for backwards-compatibility with the MessageSet wire
   * format.  You should not use this for any other reason:  It's less
   * efficient, has fewer features, and is more complicated.
   *
   * The message must be defined exactly as follows:
   *   message Foo {
   *     option message_set_wire_format = true;
   *     extensions 4 to max;
   *   }
   * Note that the message cannot have any defined fields; MessageSets only
   * have extensions.
   *
   * All extensions of your type must be singular messages; e.g. they cannot
   * be int32s, enums, or repeated messages.
   *
   * Because this is an option, the above two restrictions are not enforced by
   * the protocol compiler.
   */
  messageSetWireFormat?: InputMaybe<Scalars["Boolean"]["input"]>;
  /**
   *   Disables the generation of the standard "descriptor()" accessor, which can
   * conflict with a field of the same name.  This is meant to make migration
   * from proto1 easier; new code should avoid fields named "descriptor".
   */
  noStandardDescriptorAccessor?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** The parser stores options it doesn't recognize here. See above. */
  uninterpretedOption?: InputMaybe<
    Array<InputMaybe<Input_google_protobuf_UninterpretedOption>>
  >;
};

/** Describes a method of a service. */
export type Input_google_protobuf_MethodDescriptorProto = {
  /** Identifies if client streams multiple client messages */
  clientStreaming?: InputMaybe<Scalars["Boolean"]["input"]>;
  /**
   *   Input and output type names.  These are resolved in the same way as
   * FieldDescriptorProto.type_name, but must refer to a message type.
   */
  inputType?: InputMaybe<Scalars["String"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  options?: InputMaybe<Input_google_protobuf_MethodOptions>;
  outputType?: InputMaybe<Scalars["String"]["input"]>;
  /** Identifies if server streams multiple server messages */
  serverStreaming?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type Input_google_protobuf_MethodOptions = {
  /**
   *   Is this method deprecated?
   * Depending on the target platform, this can emit Deprecated annotations
   * for the method, or it will be completely ignored; in the very least,
   * this is a formalization for deprecating methods.
   */
  deprecated?: InputMaybe<Scalars["Boolean"]["input"]>;
  idempotencyLevel?: InputMaybe<google_protobuf_MethodOptions_IdempotencyLevel>;
  /** The parser stores options it doesn't recognize here. See above. */
  uninterpretedOption?: InputMaybe<
    Array<InputMaybe<Input_google_protobuf_UninterpretedOption>>
  >;
};

/** Describes a oneof. */
export type Input_google_protobuf_OneofDescriptorProto = {
  name?: InputMaybe<Scalars["String"]["input"]>;
  options?: InputMaybe<Input_google_protobuf_OneofOptions>;
};

export type Input_google_protobuf_OneofOptions = {
  /** The parser stores options it doesn't recognize here. See above. */
  uninterpretedOption?: InputMaybe<
    Array<InputMaybe<Input_google_protobuf_UninterpretedOption>>
  >;
};

/** Describes a service. */
export type Input_google_protobuf_ServiceDescriptorProto = {
  method?: InputMaybe<
    Array<InputMaybe<Input_google_protobuf_MethodDescriptorProto>>
  >;
  name?: InputMaybe<Scalars["String"]["input"]>;
  options?: InputMaybe<Input_google_protobuf_ServiceOptions>;
};

export type Input_google_protobuf_ServiceOptions = {
  /**
   *   Is this service deprecated?
   * Depending on the target platform, this can emit Deprecated annotations
   * for the service, or it will be completely ignored; in the very least,
   * this is a formalization for deprecating services.
   */
  deprecated?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** The parser stores options it doesn't recognize here. See above. */
  uninterpretedOption?: InputMaybe<
    Array<InputMaybe<Input_google_protobuf_UninterpretedOption>>
  >;
};

/**
 *   Encapsulates information about the original source file from which a
 * FileDescriptorProto was generated.
 */
export type Input_google_protobuf_SourceCodeInfo = {
  /**
   *   A Location identifies a piece of source code in a .proto file which
   * corresponds to a particular definition.  This information is intended
   * to be useful to IDEs, code indexers, documentation generators, and similar
   * tools.
   *
   * For example, say we have a file like:
   *   message Foo {
   *     optional string foo = 1;
   *   }
   * Let's look at just the field definition:
   *   optional string foo = 1;
   *   ^       ^^     ^^  ^  ^^^
   *   a       bc     de  f  ghi
   * We have the following locations:
   *   span   path               represents
   *   [a,i)  [ 4, 0, 2, 0 ]     The whole field definition.
   *   [a,b)  [ 4, 0, 2, 0, 4 ]  The label (optional).
   *   [c,d)  [ 4, 0, 2, 0, 5 ]  The type (string).
   *   [e,f)  [ 4, 0, 2, 0, 1 ]  The name (foo).
   *   [g,h)  [ 4, 0, 2, 0, 3 ]  The number (1).
   *
   * Notes:
   * - A location may refer to a repeated field itself (i.e. not to any
   *   particular index within it).  This is used whenever a set of elements are
   *   logically enclosed in a single code segment.  For example, an entire
   *   extend block (possibly containing multiple extension definitions) will
   *   have an outer location whose path refers to the "extensions" repeated
   *   field without an index.
   * - Multiple locations may have the same path.  This happens when a single
   *   logical declaration is spread out across multiple places.  The most
   *   obvious example is the "extend" block again -- there may be multiple
   *   extend blocks in the same scope, each of which will have the same path.
   * - A location's span is not always a subset of its parent's span.  For
   *   example, the "extendee" of an extension declaration appears at the
   *   beginning of the "extend" block and is shared by all extensions within
   *   the block.
   * - Just because a location's span is a subset of some other location's span
   *   does not mean that it is a descendent.  For example, a "group" defines
   *   both a type and a field in a single declaration.  Thus, the locations
   *   corresponding to the type and field and their components will overlap.
   * - Code which tries to interpret locations should probably be designed to
   *   ignore those that it doesn't understand, as more types of locations could
   *   be recorded in the future.
   */
  location?: InputMaybe<
    Array<InputMaybe<Input_google_protobuf_SourceCodeInfo_Location>>
  >;
};

export type Input_google_protobuf_SourceCodeInfo_Location = {
  /**
   *   If this SourceCodeInfo represents a complete declaration, these are any
   * comments appearing before and after the declaration which appear to be
   * attached to the declaration.
   *
   * A series of line comments appearing on consecutive lines, with no other
   * tokens appearing on those lines, will be treated as a single comment.
   *
   * leading_detached_comments will keep paragraphs of comments that appear
   * before (but not connected to) the current element. Each paragraph,
   * separated by empty lines, will be one comment element in the repeated
   * field.
   *
   * Only the comment content is provided; comment markers (e.g. //) are
   * stripped out.  For block comments, leading whitespace and an asterisk
   * will be stripped from the beginning of each line other than the first.
   * Newlines are included in the output.
   *
   * Examples:
   *
   *   optional int32 foo = 1;  // Comment attached to foo.
   *   // Comment attached to bar.
   *   optional int32 bar = 2;
   *
   *   optional string baz = 3;
   *   // Comment attached to baz.
   *   // Another line attached to baz.
   *
   *   // Comment attached to qux.
   *   //
   *   // Another line attached to qux.
   *   optional double qux = 4;
   *
   *   // Detached comment for corge. This is not leading or trailing comments
   *   // to qux or corge because there are blank lines separating it from
   *   // both.
   *
   *   // Detached comment for corge paragraph 2.
   *
   *   optional string corge = 5;
   *   /* Block comment attached
   *    * to corge.  Leading asterisks
   *    * will be removed. *\/
   *   /* Block comment attached to
   *    * grault. *\/
   *   optional int32 grault = 6;
   *
   *   // ignored detached comments.
   */
  leadingComments?: InputMaybe<Scalars["String"]["input"]>;
  leadingDetachedComments?: InputMaybe<
    Array<InputMaybe<Scalars["String"]["input"]>>
  >;
  /**
   *   Identifies which part of the FileDescriptorProto was defined at this
   * location.
   *
   * Each element is a field number or an index.  They form a path from
   * the root FileDescriptorProto to the place where the definition.  For
   * example, this path:
   *   [ 4, 3, 2, 7, 1 ]
   * refers to:
   *   file.message_type(3)  // 4, 3
   *       .field(7)         // 2, 7
   *       .name()           // 1
   * This is because FileDescriptorProto.message_type has field number 4:
   *   repeated DescriptorProto message_type = 4;
   * and DescriptorProto.field has field number 2:
   *   repeated FieldDescriptorProto field = 2;
   * and FieldDescriptorProto.name has field number 1:
   *   optional string name = 1;
   *
   * Thus, the above path gives the location of a field name.  If we removed
   * the last element:
   *   [ 4, 3, 2, 7 ]
   * this path refers to the whole field declaration (from the beginning
   * of the label to the terminating semicolon).
   */
  path?: InputMaybe<Array<InputMaybe<Scalars["Int"]["input"]>>>;
  /**
   *   Always has exactly three or four elements: start line, start column,
   * end line (optional, otherwise assumed same as start line), end column.
   * These are packed into a single field for efficiency.  Note that line
   * and column numbers are zero-based -- typically you will want to add
   * 1 to each before displaying to a user.
   */
  span?: InputMaybe<Array<InputMaybe<Scalars["Int"]["input"]>>>;
  trailingComments?: InputMaybe<Scalars["String"]["input"]>;
};

/**
 *   Wrapper message for `string`.
 *
 * The JSON representation for `StringValue` is JSON string.
 */
export type Input_google_protobuf_StringValue = {
  /** The string value. */
  value?: InputMaybe<Scalars["String"]["input"]>;
};

/**
 *   Wrapper message for `uint32`.
 *
 * The JSON representation for `UInt32Value` is JSON number.
 */
export type Input_google_protobuf_UInt32Value = {
  /** The uint32 value. */
  value?: InputMaybe<Scalars["Int"]["input"]>;
};

/**
 *   Wrapper message for `uint64`.
 *
 * The JSON representation for `UInt64Value` is JSON string.
 */
export type Input_google_protobuf_UInt64Value = {
  /** The uint64 value. */
  value?: InputMaybe<Scalars["Int"]["input"]>;
};

/**
 *   A message representing a option the parser does not recognize. This only
 * appears in options protos created by the compiler::Parser class.
 * DescriptorPool resolves these when building Descriptor objects. Therefore,
 * options protos in descriptor objects (e.g. returned by Descriptor::options(),
 * or produced by Descriptor::CopyTo()) will never have UninterpretedOptions
 * in them.
 */
export type Input_google_protobuf_UninterpretedOption = {
  aggregateValue?: InputMaybe<Scalars["String"]["input"]>;
  doubleValue?: InputMaybe<Scalars["Float"]["input"]>;
  /**
   *   The value of the uninterpreted option, in whatever type the tokenizer
   * identified it as during parsing. Exactly one of these should be set.
   */
  identifierValue?: InputMaybe<Scalars["String"]["input"]>;
  name?: InputMaybe<
    Array<InputMaybe<Input_google_protobuf_UninterpretedOption_NamePart>>
  >;
  negativeIntValue?: InputMaybe<Scalars["Int"]["input"]>;
  positiveIntValue?: InputMaybe<Scalars["Int"]["input"]>;
  stringValue?: InputMaybe<Scalars["String"]["input"]>;
};

/**
 *   The name of the uninterpreted option.  Each string represents a segment in
 * a dot-separated name.  is_extension is true iff a segment represents an
 * extension (denoted with parentheses in options specs in .proto files).
 * E.g.,{ ["foo", false], ["bar.baz", true], ["qux", false] } represents
 * "foo.(bar.baz).qux".
 */
export type Input_google_protobuf_UninterpretedOption_NamePart = {
  isExtension?: InputMaybe<Scalars["Boolean"]["input"]>;
  namePart?: InputMaybe<Scalars["String"]["input"]>;
};

export type Input_loqate_AddressCaptureFindRequest = {
  container?: InputMaybe<Scalars["String"]["input"]>;
  countries?: InputMaybe<Scalars["String"]["input"]>;
  key?: InputMaybe<Scalars["String"]["input"]>;
  language?: InputMaybe<Scalars["String"]["input"]>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  text?: InputMaybe<Scalars["String"]["input"]>;
};

export type Input_loqate_AddressCaptureFindResponse = {
  Items?: InputMaybe<
    Array<InputMaybe<Input_loqate_AddressCaptureFindResponse_Item>>
  >;
};

export type Input_loqate_AddressCaptureFindResponse_Item = {
  Description?: InputMaybe<Scalars["String"]["input"]>;
  Id?: InputMaybe<Scalars["String"]["input"]>;
  Text?: InputMaybe<Scalars["String"]["input"]>;
  Type?: InputMaybe<loqate_AddressCaptureFindResponse_Item_ItemType>;
};

export type Input_loqate_AddressCaptureRetrieveRequest = {
  id?: InputMaybe<Scalars["String"]["input"]>;
  key?: InputMaybe<Scalars["String"]["input"]>;
};

export type Input_loqate_AddressCaptureRetrieveResponse = {
  Items?: InputMaybe<
    Array<InputMaybe<Input_loqate_AddressCaptureRetrieveResponse_Item>>
  >;
};

export type Input_loqate_AddressCaptureRetrieveResponse_Item = {
  City?: InputMaybe<Scalars["String"]["input"]>;
  CountryIso2?: InputMaybe<Scalars["String"]["input"]>;
  Id?: InputMaybe<Scalars["String"]["input"]>;
  Line1?: InputMaybe<Scalars["String"]["input"]>;
  PostalCode?: InputMaybe<Scalars["String"]["input"]>;
  ProvinceCode?: InputMaybe<Scalars["String"]["input"]>;
  SubBuilding?: InputMaybe<Scalars["String"]["input"]>;
};

export type Input_mparticle_Identity = {
  type?: InputMaybe<Scalars["String"]["input"]>;
  value?: InputMaybe<Scalars["String"]["input"]>;
};

export type Input_mparticle_Mpid = {
  mpid?: InputMaybe<Scalars["String"]["input"]>;
};

export type Input_mparticle_ProfileRequest = {
  accountId?: InputMaybe<Scalars["String"]["input"]>;
  environmentType?: InputMaybe<Scalars["String"]["input"]>;
  identity?: InputMaybe<Input_mparticle_Identity>;
  mpid?: InputMaybe<Input_mparticle_Mpid>;
  orgId?: InputMaybe<Scalars["String"]["input"]>;
  workspaceId?: InputMaybe<Scalars["String"]["input"]>;
};

export type Input_mparticle_ProfileResponse = {
  averageOrderValue?: InputMaybe<Scalars["Float"]["input"]>;
  brandCategories?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  buyerFeedbackRating?: InputMaybe<Scalars["Float"]["input"]>;
  buyerLabel?: InputMaybe<Scalars["String"]["input"]>;
  cumulativeGmv?: InputMaybe<Scalars["Float"]["input"]>;
  firstOrderDate?: InputMaybe<Scalars["String"]["input"]>;
  mostRecentOrderDate?: InputMaybe<Scalars["String"]["input"]>;
  mpid?: InputMaybe<Scalars["String"]["input"]>;
  recentlyViewedCsps?: InputMaybe<
    Array<InputMaybe<Scalars["String"]["input"]>>
  >;
  sellerFeedbackRating?: InputMaybe<Scalars["Float"]["input"]>;
  sellerLabel?: InputMaybe<Scalars["String"]["input"]>;
  topBrandCategories?: InputMaybe<
    Array<InputMaybe<Scalars["String"]["input"]>>
  >;
  topBrands?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  topCategories?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  topCategoryUuids?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  topMostRecentProductTypes?: InputMaybe<
    Array<InputMaybe<Scalars["String"]["input"]>>
  >;
  topProductType?: InputMaybe<Scalars["String"]["input"]>;
  topProductTypeUuids?: InputMaybe<
    Array<InputMaybe<Scalars["String"]["input"]>>
  >;
  topProductTypes?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  totalBuyerOffersAccepted?: InputMaybe<Scalars["Int"]["input"]>;
  totalBuyerOffersCreated?: InputMaybe<Scalars["Int"]["input"]>;
  totalFeedFollows?: InputMaybe<Scalars["Int"]["input"]>;
  totalListingsCreated?: InputMaybe<Scalars["Int"]["input"]>;
  totalListingsDraft?: InputMaybe<Scalars["Int"]["input"]>;
  totalListingsLive?: InputMaybe<Scalars["Int"]["input"]>;
  totalListingsSold?: InputMaybe<Scalars["Int"]["input"]>;
  totalListingsSuspended?: InputMaybe<Scalars["Int"]["input"]>;
  totalPurchases?: InputMaybe<Scalars["Int"]["input"]>;
};

export type Input_mparticle_TokenRequest = {
  audience?: InputMaybe<Scalars["String"]["input"]>;
  clientId?: InputMaybe<Scalars["String"]["input"]>;
  clientSecret?: InputMaybe<Scalars["String"]["input"]>;
  grantType?: InputMaybe<Scalars["String"]["input"]>;
};

export type Input_mparticle_TokenResponse = {
  accessToken?: InputMaybe<Scalars["String"]["input"]>;
  expiresIn?: InputMaybe<Scalars["Int"]["input"]>;
  tokenType?: InputMaybe<Scalars["String"]["input"]>;
};

export type Input_reverb_config_cache_AddressFormConfig = {
  countryCode?: InputMaybe<Scalars["String"]["input"]>;
  fields?: InputMaybe<
    Array<InputMaybe<Input_reverb_config_cache_AddressFormField>>
  >;
};

export type Input_reverb_config_cache_AddressFormField = {
  displayNames?: InputMaybe<
    Array<InputMaybe<Input_reverb_config_cache_LocalizedAddressFormDisplayName>>
  >;
  fieldName?: InputMaybe<Scalars["String"]["input"]>;
  required?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type Input_reverb_config_cache_AddressValidation = {
  postalCode?: InputMaybe<Scalars["String"]["input"]>;
  state?: InputMaybe<Scalars["String"]["input"]>;
};

export type Input_reverb_config_cache_AddressValidationsResponse = {
  addresses?: InputMaybe<
    Array<InputMaybe<Input_reverb_config_cache_AddressValidation>>
  >;
};

export type Input_reverb_config_cache_CurrencyResponse = {
  rates?: InputMaybe<Array<InputMaybe<Input_reverb_config_cache_ExchangeRate>>>;
};

export type Input_reverb_config_cache_ExchangeCurrencyRequest = {
  amountMinorUnits?: InputMaybe<Scalars["Int"]["input"]>;
  from?: InputMaybe<Scalars["String"]["input"]>;
  inverseReverseRates?: InputMaybe<Scalars["Boolean"]["input"]>;
  to?: InputMaybe<Scalars["String"]["input"]>;
};

export type Input_reverb_config_cache_ExchangeCurrencyResponse = {
  amountMinorUnits?: InputMaybe<Scalars["Int"]["input"]>;
  from?: InputMaybe<Scalars["String"]["input"]>;
  to?: InputMaybe<Scalars["String"]["input"]>;
};

export type Input_reverb_config_cache_ExchangeRate = {
  from?: InputMaybe<Scalars["String"]["input"]>;
  rate?: InputMaybe<Scalars["Float"]["input"]>;
  to?: InputMaybe<Scalars["String"]["input"]>;
};

export type Input_reverb_config_cache_ExchangeRateRequest = {
  inverseReverseRates?: InputMaybe<Scalars["Boolean"]["input"]>;
  useMajorUnitConversion?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type Input_reverb_config_cache_Filters = {
  brand?: InputMaybe<Scalars["String"]["input"]>;
  category?: InputMaybe<Scalars["String"]["input"]>;
  productFamily?: InputMaybe<Scalars["String"]["input"]>;
  productType?: InputMaybe<Scalars["String"]["input"]>;
};

export type Input_reverb_config_cache_InternationalAddressFormConfigResponse = {
  configs?: InputMaybe<
    Array<InputMaybe<Input_reverb_config_cache_AddressFormConfig>>
  >;
};

export type Input_reverb_config_cache_LocalizedAddressFormDisplayName = {
  displayName?: InputMaybe<Scalars["String"]["input"]>;
  locale?: InputMaybe<Scalars["String"]["input"]>;
};

export type Input_reverb_config_cache_SearchRedirect = {
  filters?: InputMaybe<Input_reverb_config_cache_Filters>;
  queryTerm?: InputMaybe<Scalars["String"]["input"]>;
  redirectTerm?: InputMaybe<Scalars["String"]["input"]>;
  redirectTerms?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  type?: InputMaybe<reverb_config_cache_SearchRedirect_Type>;
};

export type Input_reverb_config_cache_SearchRedirectsRequest = {
  _?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type Input_reverb_config_cache_SearchRedirectsResponse = {
  redirects?: InputMaybe<
    Array<InputMaybe<Input_reverb_config_cache_SearchRedirect>>
  >;
};

export type Input_reverb_config_cache_TaxRate = {
  postalcode?: InputMaybe<Scalars["String"]["input"]>;
  state?: InputMaybe<Scalars["String"]["input"]>;
  totalSalesTaxRate?: InputMaybe<Scalars["String"]["input"]>;
  totalSalesTaxRatePrecision?: InputMaybe<Scalars["Int"]["input"]>;
};

export type Input_reverb_config_cache_TaxRatesResponse = {
  rates?: InputMaybe<Array<InputMaybe<Input_reverb_config_cache_TaxRate>>>;
};

export type Input_reverb_config_cache_TranslationsRequest = {
  locales?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  project?: InputMaybe<Scalars["String"]["input"]>;
  resource?: InputMaybe<Scalars["String"]["input"]>;
};

export type Input_reverb_config_cache_TranslationsResponse = {
  translations?: InputMaybe<
    Array<
      InputMaybe<Input_reverb_config_cache_TranslationsResponse_Translation>
    >
  >;
};

export type Input_reverb_config_cache_TranslationsResponse_Translation = {
  content?: InputMaybe<Scalars["String"]["input"]>;
  locale?: InputMaybe<Scalars["String"]["input"]>;
  project?: InputMaybe<Scalars["String"]["input"]>;
  resource?: InputMaybe<Scalars["String"]["input"]>;
};

export type Input_reverb_config_cache_VersionedExchangeRatesRequest = {
  inverseReverseRates?: InputMaybe<Scalars["Boolean"]["input"]>;
  version?: InputMaybe<Scalars["String"]["input"]>;
};

export type Input_reverb_config_cache_VersionedExchangeRatesResponse = {
  rates?: InputMaybe<Array<InputMaybe<Input_reverb_config_cache_ExchangeRate>>>;
};

export type Input_reverb_event_AddedToCart = {
  aid?: InputMaybe<Scalars["String"]["input"]>;
  cartItemUuid?: InputMaybe<Scalars["String"]["input"]>;
  listing?: InputMaybe<Input_reverb_event_Listing>;
  otherListings?: InputMaybe<Array<InputMaybe<Input_reverb_event_Listing>>>;
  referer?: InputMaybe<Scalars["String"]["input"]>;
  userContext?: InputMaybe<Input_reverb_event_UserContext>;
};

export type Input_reverb_event_AdminAbilityAndAbilityProfileUpdated = {
  abilityManagerUserId?: InputMaybe<Scalars["String"]["input"]>;
  abilityProfiles?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  abilityRecipientUserId?: InputMaybe<Scalars["String"]["input"]>;
  adHocAbilities?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
};

export type Input_reverb_event_Article = {
  id?: InputMaybe<Scalars["String"]["input"]>;
};

export type Input_reverb_event_ArticleView = {
  aid?: InputMaybe<Scalars["String"]["input"]>;
  articleId?: InputMaybe<Scalars["String"]["input"]>;
  referer?: InputMaybe<Scalars["String"]["input"]>;
  requestId?: InputMaybe<Scalars["String"]["input"]>;
  url?: InputMaybe<Scalars["String"]["input"]>;
  userContext?: InputMaybe<Input_reverb_event_UserContext>;
  utmCampaign?: InputMaybe<Scalars["String"]["input"]>;
};

export type Input_reverb_event_ArticleViewRelatedContent = {
  articleViewArticleId?: InputMaybe<Scalars["String"]["input"]>;
  articles?: InputMaybe<Array<InputMaybe<Input_reverb_event_Article>>>;
  referer?: InputMaybe<Scalars["String"]["input"]>;
  type?: InputMaybe<Scalars["String"]["input"]>;
  url?: InputMaybe<Scalars["String"]["input"]>;
  userContext?: InputMaybe<Input_reverb_event_UserContext>;
};

export type Input_reverb_event_AttributeChange = {
  name?: InputMaybe<Scalars["String"]["input"]>;
  newValue?: InputMaybe<Scalars["String"]["input"]>;
  previousValue?: InputMaybe<Scalars["String"]["input"]>;
};

export type Input_reverb_event_AttributedLanding = {
  aid?: InputMaybe<Scalars["String"]["input"]>;
  cookieId?: InputMaybe<Scalars["String"]["input"]>;
  landingBucket?: InputMaybe<Scalars["String"]["input"]>;
  landingDevice?: InputMaybe<Scalars["String"]["input"]>;
  landingId?: InputMaybe<Scalars["String"]["input"]>;
  landingTime?: InputMaybe<Scalars["String"]["input"]>;
  orderDevice?: InputMaybe<Scalars["String"]["input"]>;
  orderId?: InputMaybe<Scalars["String"]["input"]>;
  orderedAt?: InputMaybe<Scalars["String"]["input"]>;
  productId?: InputMaybe<Scalars["String"]["input"]>;
  referer?: InputMaybe<Scalars["String"]["input"]>;
  registeredAt?: InputMaybe<Scalars["String"]["input"]>;
  url?: InputMaybe<Scalars["String"]["input"]>;
  userId?: InputMaybe<Scalars["String"]["input"]>;
  userShippingRegion?: InputMaybe<Scalars["String"]["input"]>;
  utmCampaign?: InputMaybe<Scalars["String"]["input"]>;
};

/** Fingerprint from fingerprintjs2 */
export type Input_reverb_event_BrowserFingerprint = {
  /** Hashed fingerprint */
  hash?: InputMaybe<Scalars["String"]["input"]>;
  liedBrowser?: InputMaybe<Scalars["Boolean"]["input"]>;
  liedLanguages?: InputMaybe<Scalars["Boolean"]["input"]>;
  liedOs?: InputMaybe<Scalars["Boolean"]["input"]>;
  liedResolution?: InputMaybe<Scalars["Boolean"]["input"]>;
  localstorage?: InputMaybe<Scalars["Boolean"]["input"]>;
  plugins?: InputMaybe<Scalars["Int"]["input"]>;
  resolution?: InputMaybe<Scalars["String"]["input"]>;
  session?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type Input_reverb_event_BumpsReserved = {
  amount?: InputMaybe<Scalars["Int"]["input"]>;
  budgetType?: InputMaybe<Scalars["String"]["input"]>;
  listing?: InputMaybe<Input_reverb_event_Listing>;
  price?: InputMaybe<Input_reverb_event_Price>;
  userContext?: InputMaybe<Input_reverb_event_UserContext>;
};

export type Input_reverb_event_BundleData = {
  paymentGroups?: InputMaybe<
    Array<InputMaybe<Input_reverb_event_PaymentGroup>>
  >;
  paymentGroupsCount?: InputMaybe<Scalars["Int"]["input"]>;
};

export type Input_reverb_event_CanonicalProduct = {
  id?: InputMaybe<Scalars["String"]["input"]>;
};

export type Input_reverb_event_CanonicalProductUpdated = {
  canonicalProduct?: InputMaybe<Input_reverb_event_CanonicalProduct>;
  changes?: InputMaybe<Array<InputMaybe<Input_reverb_event_AttributeChange>>>;
  userContext?: InputMaybe<Input_reverb_event_UserContext>;
};

export type Input_reverb_event_CartBundles = {
  bundleData?: InputMaybe<Input_reverb_event_BundleData>;
  userContext?: InputMaybe<Input_reverb_event_UserContext>;
};

export type Input_reverb_event_CartItemBundle = {
  cartItemsCount?: InputMaybe<Scalars["Int"]["input"]>;
  currency?: InputMaybe<Scalars["String"]["input"]>;
  paymentMethod?: InputMaybe<Scalars["String"]["input"]>;
};

export type Input_reverb_event_CartView = {
  listings?: InputMaybe<Array<InputMaybe<Input_reverb_event_Listing>>>;
  requestId?: InputMaybe<Scalars["String"]["input"]>;
  userContext?: InputMaybe<Input_reverb_event_UserContext>;
};

/** includes Cart and Checkout step page views */
export type Input_reverb_event_CheckoutView = {
  checkoutId?: InputMaybe<Scalars["String"]["input"]>;
  checkoutUuid?: InputMaybe<Scalars["String"]["input"]>;
  paymentMethod?: InputMaybe<Scalars["String"]["input"]>;
  referer?: InputMaybe<Scalars["String"]["input"]>;
  stepName?: InputMaybe<Scalars["String"]["input"]>;
  userContext?: InputMaybe<Input_reverb_event_UserContext>;
};

export type Input_reverb_event_ClickStats = {
  bumpClicks?: InputMaybe<Scalars["Int"]["input"]>;
  bumpImpressions?: InputMaybe<Scalars["Int"]["input"]>;
  clicks?: InputMaybe<Scalars["Int"]["input"]>;
  entityId?: InputMaybe<Scalars["String"]["input"]>;
  entityType?: InputMaybe<Scalars["String"]["input"]>;
  impressions?: InputMaybe<Scalars["Int"]["input"]>;
};

export type Input_reverb_event_ComparisonShoppingPage = {
  id?: InputMaybe<Scalars["String"]["input"]>;
  lowestPriceForNew?: InputMaybe<Input_reverb_event_Price>;
  lowestPriceForUsed?: InputMaybe<Input_reverb_event_Price>;
  newListingCount?: InputMaybe<Scalars["Int"]["input"]>;
  usedListingCount?: InputMaybe<Scalars["Int"]["input"]>;
};

/** Deprecated, use ComponentInteraction */
export type Input_reverb_event_ComparisonShoppingPageClick = {
  comparisonShoppingPage?: InputMaybe<Input_reverb_event_ComparisonShoppingPage>;
  componentName?: InputMaybe<Scalars["String"]["input"]>;
  referer?: InputMaybe<Scalars["String"]["input"]>;
  url?: InputMaybe<Scalars["String"]["input"]>;
  userContext?: InputMaybe<Input_reverb_event_UserContext>;
};

export type Input_reverb_event_ComparisonShoppingPageView = {
  buyButtonListingId?: InputMaybe<Scalars["String"]["input"]>;
  buyButtonListingType?: InputMaybe<Scalars["String"]["input"]>;
  buyButtonReason?: InputMaybe<Scalars["String"]["input"]>;
  canonicalProductIds?: InputMaybe<
    Array<InputMaybe<Scalars["String"]["input"]>>
  >;
  comparisonShoppingPageId?: InputMaybe<Scalars["String"]["input"]>;
  condition?: InputMaybe<Scalars["String"]["input"]>;
  listings?: InputMaybe<Array<InputMaybe<Input_reverb_event_Listing>>>;
  lowestPriceForNew?: InputMaybe<Input_reverb_event_Price>;
  lowestPriceForUsed?: InputMaybe<Input_reverb_event_Price>;
  priceGuideId?: InputMaybe<Scalars["String"]["input"]>;
  referer?: InputMaybe<Scalars["String"]["input"]>;
  requestId?: InputMaybe<Scalars["String"]["input"]>;
  url?: InputMaybe<Scalars["String"]["input"]>;
  userContext?: InputMaybe<Input_reverb_event_UserContext>;
  utmCampaign?: InputMaybe<Scalars["String"]["input"]>;
  utmMedium?: InputMaybe<Scalars["String"]["input"]>;
  utmSource?: InputMaybe<Scalars["String"]["input"]>;
};

export type Input_reverb_event_ComparisonShoppingPagesView = {
  comparisonShoppingPages?: InputMaybe<
    Array<InputMaybe<Input_reverb_event_ComparisonShoppingPage>>
  >;
  componentName?: InputMaybe<Scalars["String"]["input"]>;
  referer?: InputMaybe<Scalars["String"]["input"]>;
  url?: InputMaybe<Scalars["String"]["input"]>;
  userContext?: InputMaybe<Input_reverb_event_UserContext>;
};

export type Input_reverb_event_ComponentInteraction = {
  bumped?: InputMaybe<Scalars["Boolean"]["input"]>;
  componentName?: InputMaybe<Scalars["String"]["input"]>;
  context?: InputMaybe<Scalars["String"]["input"]>;
  entityId?: InputMaybe<Scalars["String"]["input"]>;
  entityType?: InputMaybe<Scalars["String"]["input"]>;
  freeTextQuery?: InputMaybe<Scalars["String"]["input"]>;
  impressionUuid?: InputMaybe<Scalars["String"]["input"]>;
  interactionType?: InputMaybe<Scalars["String"]["input"]>;
  page?: InputMaybe<Scalars["Int"]["input"]>;
  pageViewId?: InputMaybe<Scalars["String"]["input"]>;
  perPage?: InputMaybe<Scalars["Int"]["input"]>;
  position?: InputMaybe<Scalars["Int"]["input"]>;
  referer?: InputMaybe<Scalars["String"]["input"]>;
  targetUrl?: InputMaybe<Scalars["String"]["input"]>;
  totalResults?: InputMaybe<Scalars["Int"]["input"]>;
  url?: InputMaybe<Scalars["String"]["input"]>;
  userContext?: InputMaybe<Input_reverb_event_UserContext>;
  utmCampaign?: InputMaybe<Scalars["String"]["input"]>;
  utmMedium?: InputMaybe<Scalars["String"]["input"]>;
  utmSource?: InputMaybe<Scalars["String"]["input"]>;
};

export type Input_reverb_event_ComponentsView = {
  algorithmVersion?: InputMaybe<Scalars["String"]["input"]>;
  componentName?: InputMaybe<Scalars["String"]["input"]>;
  context?: InputMaybe<Scalars["String"]["input"]>;
  entityIds?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  entityType?: InputMaybe<Scalars["String"]["input"]>;
  impressionUuid?: InputMaybe<Scalars["String"]["input"]>;
  pageViewId?: InputMaybe<Scalars["String"]["input"]>;
  referer?: InputMaybe<Scalars["String"]["input"]>;
  url?: InputMaybe<Scalars["String"]["input"]>;
  userContext?: InputMaybe<Input_reverb_event_UserContext>;
  utmCampaign?: InputMaybe<Scalars["String"]["input"]>;
  utmMedium?: InputMaybe<Scalars["String"]["input"]>;
  utmSource?: InputMaybe<Scalars["String"]["input"]>;
};

export type Input_reverb_event_ContestSignUp = {
  aid?: InputMaybe<Scalars["String"]["input"]>;
  questionResponse?: InputMaybe<Scalars["String"]["input"]>;
  referer?: InputMaybe<Scalars["String"]["input"]>;
  userContext?: InputMaybe<Input_reverb_event_UserContext>;
  utmCampaign?: InputMaybe<Scalars["String"]["input"]>;
};

export type Input_reverb_event_ConversationUpdated = {
  id?: InputMaybe<Scalars["String"]["input"]>;
  initialAuthorId?: InputMaybe<Scalars["String"]["input"]>;
  initialRecipientId?: InputMaybe<Scalars["String"]["input"]>;
  lastMessageId?: InputMaybe<Scalars["String"]["input"]>;
  listing?: InputMaybe<Input_reverb_event_Listing>;
  requestId?: InputMaybe<Scalars["String"]["input"]>;
  userContext?: InputMaybe<Input_reverb_event_UserContext>;
};

export type Input_reverb_event_CuratedSetView = {
  curatedSetId?: InputMaybe<Scalars["String"]["input"]>;
  listings?: InputMaybe<Array<InputMaybe<Input_reverb_event_Listing>>>;
  requestId?: InputMaybe<Scalars["String"]["input"]>;
  userContext?: InputMaybe<Input_reverb_event_UserContext>;
};

export type Input_reverb_event_Device = {
  appVersion?: InputMaybe<Scalars["String"]["input"]>;
  brand?: InputMaybe<Scalars["String"]["input"]>;
  manufacturer?: InputMaybe<Scalars["String"]["input"]>;
  model?: InputMaybe<Scalars["String"]["input"]>;
  /** / DeviceName */
  name?: InputMaybe<Scalars["String"]["input"]>;
  osVersion?: InputMaybe<Scalars["String"]["input"]>;
  userAgent?: InputMaybe<Scalars["String"]["input"]>;
  version?: InputMaybe<Scalars["String"]["input"]>;
};

export type Input_reverb_event_DigitalListing = {
  categoryUuids?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  price?: InputMaybe<Input_reverb_event_Price>;
  /** / ListingStatus */
  status?: InputMaybe<Scalars["String"]["input"]>;
};

export type Input_reverb_event_DigitalListingDownload = {
  digitalListingId?: InputMaybe<Scalars["String"]["input"]>;
  digitalVariantId?: InputMaybe<Scalars["String"]["input"]>;
  requestId?: InputMaybe<Scalars["String"]["input"]>;
  userContext?: InputMaybe<Input_reverb_event_UserContext>;
};

export type Input_reverb_event_DigitalListingView = {
  digitalListing?: InputMaybe<Input_reverb_event_DigitalListing>;
  requestId?: InputMaybe<Scalars["String"]["input"]>;
  userContext?: InputMaybe<Input_reverb_event_UserContext>;
};

export type Input_reverb_event_DiscountCodeApplied = {
  discountCode?: InputMaybe<Scalars["String"]["input"]>;
  error?: InputMaybe<Scalars["String"]["input"]>;
  referer?: InputMaybe<Scalars["String"]["input"]>;
  success?: InputMaybe<Scalars["Boolean"]["input"]>;
  userContext?: InputMaybe<Input_reverb_event_UserContext>;
};

export type Input_reverb_event_EntityUpdated = {
  action?: InputMaybe<reverb_event_EntityUpdated_Action>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  timestamp?: InputMaybe<Scalars["String"]["input"]>;
};

export type Input_reverb_event_Error = {
  error?: InputMaybe<Scalars["String"]["input"]>;
  userContext?: InputMaybe<Input_reverb_event_UserContext>;
};

export type Input_reverb_event_Event = {
  level?: InputMaybe<Scalars["String"]["input"]>;
  messageName?: InputMaybe<Scalars["String"]["input"]>;
  payload?: InputMaybe<Scalars["String"]["input"]>;
};

export type Input_reverb_event_EventRequest = {
  events?: InputMaybe<Array<InputMaybe<Input_reverb_event_Event>>>;
};

export type Input_reverb_event_Experiment = {
  mobileContext?: InputMaybe<Input_reverb_event_Experiment_MobileContext>;
  multiClientExperimentContext?: InputMaybe<Scalars["String"]["input"]>;
  multivariateExperiment?: InputMaybe<Scalars["Boolean"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  /** / Deprecated in favor of mobile_context */
  qualifyForMobile?: InputMaybe<Scalars["Boolean"]["input"]>;
  useInFrontend?: InputMaybe<Scalars["Boolean"]["input"]>;
  useInMarketplaceListingsSearch?: InputMaybe<Scalars["Boolean"]["input"]>;
  value?: InputMaybe<Scalars["String"]["input"]>;
};

export type Input_reverb_event_Experiment_MobileContext = {
  reportAs?: InputMaybe<Scalars["String"]["input"]>;
  value?: InputMaybe<Scalars["String"]["input"]>;
};

export type Input_reverb_event_FollowAdded = {
  referer?: InputMaybe<Scalars["String"]["input"]>;
  searchParams?: InputMaybe<Scalars["String"]["input"]>;
  searchableId?: InputMaybe<Scalars["String"]["input"]>;
  searchableType?: InputMaybe<Scalars["String"]["input"]>;
  userContext?: InputMaybe<Input_reverb_event_UserContext>;
};

export type Input_reverb_event_FollowUpdated = {
  destroyed?: InputMaybe<Scalars["Boolean"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  savedSearchId?: InputMaybe<Scalars["String"]["input"]>;
  userContext?: InputMaybe<Input_reverb_event_UserContext>;
};

export type Input_reverb_event_GenericMailer = {
  /** / MailerAction */
  action?: InputMaybe<Scalars["String"]["input"]>;
  /** / DEPRECATED */
  emailType?: InputMaybe<Scalars["String"]["input"]>;
  /** / sale_id */
  sourceId?: InputMaybe<Scalars["String"]["input"]>;
  /** / Sale */
  sourceType?: InputMaybe<Scalars["String"]["input"]>;
  userContext?: InputMaybe<Input_reverb_event_UserContext>;
  /** / new_user_sale_invitation */
  utmCampaign?: InputMaybe<Scalars["String"]["input"]>;
  /** / { subject_line: "v2" }.to_json */
  variations?: InputMaybe<Scalars["String"]["input"]>;
};

export type Input_reverb_event_GoogleShoppingClick = {
  comparisonShoppingPageId?: InputMaybe<Scalars["String"]["input"]>;
  /** / GoogleClickOrigin */
  origin?: InputMaybe<Scalars["String"]["input"]>;
  productId?: InputMaybe<Scalars["String"]["input"]>;
  /** / ISO8601 */
  timestamp?: InputMaybe<Scalars["String"]["input"]>;
};

export type Input_reverb_event_JsLoad = {
  app?: InputMaybe<Scalars["String"]["input"]>;
  fingerprint?: InputMaybe<Input_reverb_event_BrowserFingerprint>;
  userContext?: InputMaybe<Input_reverb_event_UserContext>;
};

export type Input_reverb_event_Listing = {
  bumped?: InputMaybe<Scalars["Boolean"]["input"]>;
  canonicalProductId?: InputMaybe<Scalars["String"]["input"]>;
  categoryUuids?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  price?: InputMaybe<Input_reverb_event_Price>;
  /** / ListingStatus */
  status?: InputMaybe<Scalars["String"]["input"]>;
};

export type Input_reverb_event_ListingMatched = {
  canonicalProductId?: InputMaybe<Scalars["String"]["input"]>;
  listingId?: InputMaybe<Scalars["String"]["input"]>;
  userContext?: InputMaybe<Input_reverb_event_UserContext>;
};

export type Input_reverb_event_ListingUpdated = {
  changes?: InputMaybe<Array<InputMaybe<Input_reverb_event_AttributeChange>>>;
  listing?: InputMaybe<Input_reverb_event_Listing>;
  userContext?: InputMaybe<Input_reverb_event_UserContext>;
};

export type Input_reverb_event_ListingView = {
  aid?: InputMaybe<Scalars["String"]["input"]>;
  listing?: InputMaybe<Input_reverb_event_Listing>;
  referer?: InputMaybe<Scalars["String"]["input"]>;
  requestId?: InputMaybe<Scalars["String"]["input"]>;
  url?: InputMaybe<Scalars["String"]["input"]>;
  userContext?: InputMaybe<Input_reverb_event_UserContext>;
  utmCampaign?: InputMaybe<Scalars["String"]["input"]>;
};

export type Input_reverb_event_ListingViewRelatedContent = {
  articles?: InputMaybe<Array<InputMaybe<Input_reverb_event_Article>>>;
  listingViewListingId?: InputMaybe<Scalars["String"]["input"]>;
  listings?: InputMaybe<Array<InputMaybe<Input_reverb_event_Listing>>>;
  referer?: InputMaybe<Scalars["String"]["input"]>;
  type?: InputMaybe<Scalars["String"]["input"]>;
  url?: InputMaybe<Scalars["String"]["input"]>;
  userContext?: InputMaybe<Input_reverb_event_UserContext>;
};

/**
 *   Reverb Analytics Notes:
 * Description: Captures views of any listing and parses product_id from url
 */
export type Input_reverb_event_ListingsView = {
  aid?: InputMaybe<Scalars["String"]["input"]>;
  bumpAlgorithm?: InputMaybe<Scalars["String"]["input"]>;
  bumpedListings?: InputMaybe<Array<InputMaybe<Input_reverb_event_Listing>>>;
  curatedLinkIds?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  curatedSetIds?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  fallbackListings?: InputMaybe<Array<InputMaybe<Input_reverb_event_Listing>>>;
  fallbackStrategy?: InputMaybe<Scalars["String"]["input"]>;
  featuredListing?: InputMaybe<Input_reverb_event_Listing>;
  listings?: InputMaybe<Array<InputMaybe<Input_reverb_event_Listing>>>;
  query?: InputMaybe<Scalars["String"]["input"]>;
  referer?: InputMaybe<Scalars["String"]["input"]>;
  requestId?: InputMaybe<Scalars["String"]["input"]>;
  responseTime?: InputMaybe<Scalars["Float"]["input"]>;
  resultCount?: InputMaybe<Scalars["Int"]["input"]>;
  totalFallbackListings?: InputMaybe<Scalars["Int"]["input"]>;
  totalResults?: InputMaybe<Scalars["Int"]["input"]>;
  url?: InputMaybe<Scalars["String"]["input"]>;
  userContext?: InputMaybe<Input_reverb_event_UserContext>;
  utmCampaign?: InputMaybe<Scalars["String"]["input"]>;
  version?: InputMaybe<Scalars["Int"]["input"]>;
  viewType?: InputMaybe<Scalars["String"]["input"]>;
};

export type Input_reverb_event_MailLinkClick = {
  /** / sale_id */
  sourceId?: InputMaybe<Scalars["String"]["input"]>;
  /** / Sale */
  sourceType?: InputMaybe<Scalars["String"]["input"]>;
  url?: InputMaybe<Scalars["String"]["input"]>;
  userContext?: InputMaybe<Input_reverb_event_UserContext>;
  utmCampaign?: InputMaybe<Scalars["String"]["input"]>;
  /** / { subject_line: "v2" }.to_json */
  variations?: InputMaybe<Scalars["String"]["input"]>;
};

export type Input_reverb_event_MailListSignUp = {
  countryCode?: InputMaybe<Scalars["String"]["input"]>;
  email?: InputMaybe<Scalars["String"]["input"]>;
  listId?: InputMaybe<Scalars["String"]["input"]>;
  referer?: InputMaybe<Scalars["String"]["input"]>;
  source?: InputMaybe<Scalars["String"]["input"]>;
  sourceProductUuid?: InputMaybe<Scalars["String"]["input"]>;
  userContext?: InputMaybe<Input_reverb_event_UserContext>;
  userId?: InputMaybe<Scalars["String"]["input"]>;
};

export type Input_reverb_event_MarketingEmailView = {
  entityIds?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  entityType?: InputMaybe<Scalars["String"]["input"]>;
  listings?: InputMaybe<Array<InputMaybe<Input_reverb_event_Listing>>>;
  persona?: InputMaybe<Scalars["String"]["input"]>;
  upsellOrderId?: InputMaybe<Scalars["String"]["input"]>;
  userContext?: InputMaybe<Input_reverb_event_UserContext>;
  utmCampaign?: InputMaybe<Scalars["String"]["input"]>;
};

export type Input_reverb_event_Message = {
  conversationId?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
};

export type Input_reverb_event_MessageUpdated = {
  changes?: InputMaybe<Array<InputMaybe<Input_reverb_event_AttributeChange>>>;
  message?: InputMaybe<Input_reverb_event_Message>;
  userContext?: InputMaybe<Input_reverb_event_UserContext>;
};

export type Input_reverb_event_MessagesMailer = {
  /** / MailerAction */
  action?: InputMaybe<Scalars["String"]["input"]>;
  messageId?: InputMaybe<Scalars["String"]["input"]>;
  userContext?: InputMaybe<Input_reverb_event_UserContext>;
};

/** / Deprecated in favor of common ComponentInteraction */
export type Input_reverb_event_MobileInteraction = {
  action?: InputMaybe<Scalars["String"]["input"]>;
  context?: InputMaybe<Scalars["String"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  type?: InputMaybe<reverb_event_MobileInteraction_InteractionType>;
  userContext?: InputMaybe<Input_reverb_event_UserContext>;
};

export type Input_reverb_event_NegotiationStarted = {
  buyerId?: InputMaybe<Scalars["String"]["input"]>;
  buyerInitiated?: InputMaybe<Scalars["Boolean"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  listingIds?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  offerId?: InputMaybe<Scalars["String"]["input"]>;
  userContext?: InputMaybe<Input_reverb_event_UserContext>;
};

export type Input_reverb_event_NegotiationUpdated = {
  id?: InputMaybe<Scalars["String"]["input"]>;
  lastOfferId?: InputMaybe<Scalars["String"]["input"]>;
  listing?: InputMaybe<Input_reverb_event_Listing>;
  /** / Status */
  status?: InputMaybe<Scalars["String"]["input"]>;
  userContext?: InputMaybe<Input_reverb_event_UserContext>;
};

export type Input_reverb_event_OrderPaid = {
  buyerId?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  listingId?: InputMaybe<Scalars["String"]["input"]>;
  userContext?: InputMaybe<Input_reverb_event_UserContext>;
};

export type Input_reverb_event_OrderUpdated = {
  buyerId?: InputMaybe<Scalars["String"]["input"]>;
  createdAt?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  listing?: InputMaybe<Input_reverb_event_Listing>;
  status?: InputMaybe<Scalars["String"]["input"]>;
  userContext?: InputMaybe<Input_reverb_event_UserContext>;
};

export type Input_reverb_event_PMBView = {
  listings?: InputMaybe<Array<InputMaybe<Input_reverb_event_Listing>>>;
  persona?: InputMaybe<Scalars["String"]["input"]>;
  userContext?: InputMaybe<Input_reverb_event_UserContext>;
  utmCampaign?: InputMaybe<Scalars["String"]["input"]>;
};

/**
 *   Reverb Analytics Notes:
 * Description: Captures generic catch-all pages viewed by a user on reverb
 * Warnings:
 * Includes many backend "views" like API calls and image requests
 * Any query of events.page_views must reference the partition key dt in the where clause
 */
export type Input_reverb_event_PageView = {
  action?: InputMaybe<Scalars["String"]["input"]>;
  aid?: InputMaybe<Scalars["String"]["input"]>;
  controller?: InputMaybe<Scalars["String"]["input"]>;
  customHeaders?: InputMaybe<Scalars["String"]["input"]>;
  dbRuntime?: InputMaybe<Scalars["Float"]["input"]>;
  devTeam?: InputMaybe<Scalars["String"]["input"]>;
  duration?: InputMaybe<Scalars["Float"]["input"]>;
  /** Client-side pageview ID */
  id?: InputMaybe<Scalars["String"]["input"]>;
  method?: InputMaybe<Scalars["String"]["input"]>;
  oauthAppId?: InputMaybe<Scalars["String"]["input"]>;
  /** expectin a JSON serialized hash */
  pageExperiments?: InputMaybe<Scalars["String"]["input"]>;
  params?: InputMaybe<Scalars["String"]["input"]>;
  /** params[id] for debugging and kibana health */
  paramsId?: InputMaybe<Scalars["String"]["input"]>;
  referer?: InputMaybe<Scalars["String"]["input"]>;
  remoteAddress?: InputMaybe<Scalars["String"]["input"]>;
  requestId?: InputMaybe<Scalars["String"]["input"]>;
  /** params[sku] for debugging and kibana health */
  sku?: InputMaybe<Scalars["String"]["input"]>;
  status?: InputMaybe<Scalars["String"]["input"]>;
  threadId?: InputMaybe<Scalars["String"]["input"]>;
  url?: InputMaybe<Scalars["String"]["input"]>;
  /** _region prefix of requested URL */
  urlRegion?: InputMaybe<Scalars["String"]["input"]>;
  userContext?: InputMaybe<Input_reverb_event_UserContext>;
  utmCampaign?: InputMaybe<Scalars["String"]["input"]>;
  utmMedium?: InputMaybe<Scalars["String"]["input"]>;
  utmSource?: InputMaybe<Scalars["String"]["input"]>;
  viewRuntime?: InputMaybe<Scalars["Float"]["input"]>;
};

export type Input_reverb_event_PaymentGroup = {
  cartItemBundles?: InputMaybe<
    Array<InputMaybe<Input_reverb_event_CartItemBundle>>
  >;
  cartItemBundlesCount?: InputMaybe<Scalars["Int"]["input"]>;
};

export type Input_reverb_event_Price = {
  amount?: InputMaybe<Scalars["Int"]["input"]>;
  currency?: InputMaybe<Scalars["String"]["input"]>;
};

export type Input_reverb_event_RemovedFromCart = {
  cartItemUuid?: InputMaybe<Scalars["String"]["input"]>;
  listing?: InputMaybe<Input_reverb_event_Listing>;
  userContext?: InputMaybe<Input_reverb_event_UserContext>;
};

export type Input_reverb_event_SaleView = {
  listings?: InputMaybe<Array<InputMaybe<Input_reverb_event_Listing>>>;
  requestId?: InputMaybe<Scalars["String"]["input"]>;
  saleId?: InputMaybe<Scalars["String"]["input"]>;
  userContext?: InputMaybe<Input_reverb_event_UserContext>;
};

export type Input_reverb_event_ScreenView = {
  context?: InputMaybe<Scalars["String"]["input"]>;
  referer?: InputMaybe<Scalars["String"]["input"]>;
  screenName?: InputMaybe<Scalars["String"]["input"]>;
  userContext?: InputMaybe<Input_reverb_event_UserContext>;
};

export type Input_reverb_event_SessionStart = {
  aid?: InputMaybe<Scalars["String"]["input"]>;
  gsPartner?: InputMaybe<Scalars["String"]["input"]>;
  /** session id */
  id?: InputMaybe<Scalars["String"]["input"]>;
  referer?: InputMaybe<Scalars["String"]["input"]>;
  requestId?: InputMaybe<Scalars["String"]["input"]>;
  url?: InputMaybe<Scalars["String"]["input"]>;
  userContext?: InputMaybe<Input_reverb_event_UserContext>;
  utmCampaign?: InputMaybe<Scalars["String"]["input"]>;
  utmMedium?: InputMaybe<Scalars["String"]["input"]>;
  utmSource?: InputMaybe<Scalars["String"]["input"]>;
};

export type Input_reverb_event_ShippingLabelUpdated = {
  id?: InputMaybe<Scalars["String"]["input"]>;
  status?: InputMaybe<Scalars["String"]["input"]>;
  userContext?: InputMaybe<Input_reverb_event_UserContext>;
};

export type Input_reverb_event_ShopEmptied = {
  shopId?: InputMaybe<Scalars["String"]["input"]>;
};

/** / Polymorphic message wrapper */
export type Input_reverb_event_TrackingPixelEvent = {
  data?: InputMaybe<Scalars["String"]["input"]>;
  /** / ex. MessagesMailer */
  name?: InputMaybe<Scalars["String"]["input"]>;
};

export type Input_reverb_event_UserContext = {
  actingUserId?: InputMaybe<Scalars["String"]["input"]>;
  adminId?: InputMaybe<Scalars["String"]["input"]>;
  advertisingId?: InputMaybe<Scalars["String"]["input"]>;
  bot?: InputMaybe<Scalars["Boolean"]["input"]>;
  cookieId?: InputMaybe<Scalars["String"]["input"]>;
  countryCode?: InputMaybe<Scalars["String"]["input"]>;
  currency?: InputMaybe<Scalars["String"]["input"]>;
  device?: InputMaybe<Input_reverb_event_Device>;
  distinctId?: InputMaybe<Scalars["String"]["input"]>;
  experiments?: InputMaybe<Array<InputMaybe<Input_reverb_event_Experiment>>>;
  hasLoggedIn?: InputMaybe<Scalars["Boolean"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  lastGoogleClick?: InputMaybe<Input_reverb_event_GoogleShoppingClick>;
  lat?: InputMaybe<Scalars["Float"]["input"]>;
  locale?: InputMaybe<Scalars["String"]["input"]>;
  long?: InputMaybe<Scalars["Float"]["input"]>;
  requestId?: InputMaybe<Scalars["String"]["input"]>;
  reverbApp?: InputMaybe<Scalars["String"]["input"]>;
  sellerSitesId?: InputMaybe<Scalars["String"]["input"]>;
  sessionId?: InputMaybe<Scalars["String"]["input"]>;
  shippingRegion?: InputMaybe<Scalars["String"]["input"]>;
  /** / ISO8601 */
  timestamp?: InputMaybe<Scalars["String"]["input"]>;
};

export type Input_reverb_event_UserSignedIn = {
  referer?: InputMaybe<Scalars["String"]["input"]>;
  signup?: InputMaybe<Scalars["Boolean"]["input"]>;
  userContext?: InputMaybe<Input_reverb_event_UserContext>;
  userId?: InputMaybe<Scalars["String"]["input"]>;
};

export type Input_reverb_event_UserSignedUp = {
  aid?: InputMaybe<Scalars["String"]["input"]>;
  countryCode?: InputMaybe<Scalars["String"]["input"]>;
  /**
   *   Reverb Analytics Notes:
   * Description: Captures user registration event
   * Known Issues: Currently drops/does not capture user_context_id for majority of events on desktop and mobile web
   */
  newsletterOptIn?: InputMaybe<Scalars["Boolean"]["input"]>;
  referer?: InputMaybe<Scalars["String"]["input"]>;
  registrationSource?: InputMaybe<Scalars["String"]["input"]>;
  sourceProductUuid?: InputMaybe<Scalars["String"]["input"]>;
  url?: InputMaybe<Scalars["String"]["input"]>;
  userContext?: InputMaybe<Input_reverb_event_UserContext>;
  userId?: InputMaybe<Scalars["String"]["input"]>;
  utmCampaign?: InputMaybe<Scalars["String"]["input"]>;
};

export type Input_reverb_event_UserUpdated = {
  id?: InputMaybe<Scalars["String"]["input"]>;
  newsletterOptIn?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** / UserStatus */
  status?: InputMaybe<Scalars["String"]["input"]>;
  userContext?: InputMaybe<Input_reverb_event_UserContext>;
};

export type Input_reverb_event_WatchAdded = {
  listingId?: InputMaybe<Scalars["String"]["input"]>;
  referer?: InputMaybe<Scalars["String"]["input"]>;
  userContext?: InputMaybe<Input_reverb_event_UserContext>;
  watchId?: InputMaybe<Scalars["String"]["input"]>;
};

export type Input_reverb_event_WatcherListingSoldMailer = {
  /** / MailerAction */
  action?: InputMaybe<Scalars["String"]["input"]>;
  comparisonShoppingPageId?: InputMaybe<Scalars["String"]["input"]>;
  orderId?: InputMaybe<Scalars["String"]["input"]>;
  productId?: InputMaybe<Scalars["String"]["input"]>;
  similarProductIds?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  userContext?: InputMaybe<Input_reverb_event_UserContext>;
};

export type Input_reverb_feed_AlertableUserWithTz = {
  tzRegion?: InputMaybe<Scalars["Int"]["input"]>;
  userId?: InputMaybe<Scalars["String"]["input"]>;
};

export type Input_reverb_feed_AlertableUsersInTzStreamRequest = {
  chunkSize?: InputMaybe<Scalars["Int"]["input"]>;
  fromId?: InputMaybe<Scalars["Int"]["input"]>;
  tzRegion?: InputMaybe<Scalars["Int"]["input"]>;
};

export type Input_reverb_feed_AlertableUsersRequest = {
  _?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type Input_reverb_feed_AlertableUsersResponse = {
  userIds?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
};

export type Input_reverb_feed_AlertableUsersStreamRequest = {
  chunkSize?: InputMaybe<Scalars["Int"]["input"]>;
};

export type Input_reverb_feed_AlertableUsersWithTzResponse = {
  users?: InputMaybe<Array<InputMaybe<Input_reverb_feed_AlertableUserWithTz>>>;
};

export type Input_reverb_feed_AlertableUsersWithTzStreamRequest = {
  chunkSize?: InputMaybe<Scalars["Int"]["input"]>;
};

export type Input_reverb_feed_BackfillSearchDuplicate = {
  normalizedParams?: InputMaybe<Scalars["String"]["input"]>;
  searchIds?: InputMaybe<Array<InputMaybe<Scalars["Int"]["input"]>>>;
};

export type Input_reverb_feed_BatchUpdateSavedSearchRecentListingCountRequest =
  {
    savedSearches?: InputMaybe<
      Array<
        InputMaybe<Input_reverb_feed_BatchUpdateSavedSearchRecentListingCountRequest_SavedSearchInput>
      >
    >;
  };

export type Input_reverb_feed_BatchUpdateSavedSearchRecentListingCountRequest_SavedSearchInput =
  {
    id?: InputMaybe<Scalars["String"]["input"]>;
    recentListingCount?: InputMaybe<Scalars["Int"]["input"]>;
  };

export type Input_reverb_feed_BatchUpdateSavedSearchRecentListingCountResponse =
  {
    updatedCount?: InputMaybe<Scalars["Int"]["input"]>;
  };

export type Input_reverb_feed_ClearUserDigestRequest = {
  userId?: InputMaybe<Scalars["String"]["input"]>;
};

export type Input_reverb_feed_ClearUserDigestResponse = {
  _?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type Input_reverb_feed_CreateFollowResponse = {
  follow?: InputMaybe<Input_reverb_feed_Follow>;
};

export type Input_reverb_feed_CreateSavedSearchRequest = {
  esQuery?: InputMaybe<Scalars["String"]["input"]>;
  localizedContents?: InputMaybe<
    Array<InputMaybe<Input_reverb_feed_FollowLocalizedContents>>
  >;
  queryParams?: InputMaybe<Scalars["String"]["input"]>;
  searchableType?: InputMaybe<reverb_feed_SearchableType>;
  searchableUuid?: InputMaybe<Scalars["String"]["input"]>;
};

export type Input_reverb_feed_CreateSavedSearchResponse = {
  id?: InputMaybe<Scalars["Int"]["input"]>;
};

export type Input_reverb_feed_DataBackfillTopic = {
  action?: InputMaybe<reverb_feed_DataBackfillTopic_Action>;
  followIds?: InputMaybe<Array<InputMaybe<Scalars["Int"]["input"]>>>;
  searchDuplicates?: InputMaybe<
    Array<InputMaybe<Input_reverb_feed_BackfillSearchDuplicate>>
  >;
};

export type Input_reverb_feed_DigestFollows = {
  entries?: InputMaybe<Array<InputMaybe<Input_reverb_feed_FeedEntry>>>;
  follow?: InputMaybe<Input_reverb_feed_Follow>;
};

export type Input_reverb_feed_DigestListings = {
  listingIds?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
};

export type Input_reverb_feed_EntriesRequest = {
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  locale?: InputMaybe<Scalars["String"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  page?: InputMaybe<Scalars["Int"]["input"]>;
  perPage?: InputMaybe<Scalars["Int"]["input"]>;
  readFromDatabase?: InputMaybe<Scalars["Boolean"]["input"]>;
  userId?: InputMaybe<Scalars["String"]["input"]>;
};

/** Favorite represents a row in the follows table (a link between a user and a saved search), and is a newer version of the Follow message. */
export type Input_reverb_feed_Favorite = {
  emailEnabled?: InputMaybe<Scalars["Boolean"]["input"]>;
  favorited?: InputMaybe<Scalars["Boolean"]["input"]>;
  feedEnabled?: InputMaybe<Scalars["Boolean"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  queryParams?: InputMaybe<Scalars["String"]["input"]>;
  queryText?: InputMaybe<Scalars["String"]["input"]>;
  savedSearchId?: InputMaybe<Scalars["String"]["input"]>;
  searchableId?: InputMaybe<Scalars["String"]["input"]>;
  searchableType?: InputMaybe<reverb_feed_SearchableType>;
  subtitle?: InputMaybe<Scalars["String"]["input"]>;
  title?: InputMaybe<Scalars["String"]["input"]>;
  userId?: InputMaybe<Scalars["String"]["input"]>;
};

export type Input_reverb_feed_FeedEntry = {
  /** The `deleted` field is for client state management, primarily Apollo Client in frontend. */
  deleted?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** The `id` field contains a Listing ID. Technically it is the ID of the `EntryType` entity (`entries.entry_id`), but all entries are listings. It does not contain `entries.id`, which is a unique per-user-per-listing value. */
  id?: InputMaybe<Scalars["Int"]["input"]>;
  /** Contains an array of _all_ localized content, 1 per supported locale. This method of handling localization is unusual, and makes client code more complex. The more common pattern is providing a single `FollowLocalizedContents` message (not an array) that is aware of the current user's locale. */
  localizedContents?: InputMaybe<
    Array<InputMaybe<Input_reverb_feed_FollowLocalizedContents>>
  >;
  metadata?: InputMaybe<Input_reverb_feed_FeedMetadata>;
  /** Unused by clients, still referenced in feed code & tests. */
  queryParams?: InputMaybe<Scalars["String"]["input"]>;
  /** Unused by clients, still referenced in feed code & tests. */
  searchableId?: InputMaybe<Scalars["String"]["input"]>;
  /** Unused by clients, still referenced in feed code & tests. */
  searchableType?: InputMaybe<reverb_feed_SearchableType>;
  /** Currently, all entries are listings. */
  type?: InputMaybe<reverb_feed_FeedEntry_EntryType>;
  /** Only relevant for `UserDigestsResponse`, which returns entries for a batch of users. */
  userId?: InputMaybe<Scalars["String"]["input"]>;
  /** The `uuid` field contains a Listing ID as a string, which is never a UUID. In the past when LP related feed entries were stored, this may have stored a UUID. */
  uuid?: InputMaybe<Scalars["String"]["input"]>;
};

export type Input_reverb_feed_FeedEntryUpdated = {
  action?: InputMaybe<reverb_feed_FeedEntryUpdated_Action>;
  /** timestamp of first entry in feed pipeline */
  entityUpdatedAt?: InputMaybe<Scalars["Timestamp"]["input"]>;
  entries?: InputMaybe<Array<InputMaybe<Input_reverb_feed_FeedEntry>>>;
  sourceId?: InputMaybe<Scalars["Int"]["input"]>;
  sourceIds?: InputMaybe<Array<InputMaybe<Scalars["Int"]["input"]>>>;
  sourceType?: InputMaybe<reverb_feed_FeedSourceType>;
  userId?: InputMaybe<Scalars["Int"]["input"]>;
  userIds?: InputMaybe<Array<InputMaybe<Scalars["Int"]["input"]>>>;
};

/** Metadata for a feed entry. */
export type Input_reverb_feed_FeedMetadata = {
  /** Unused by clients, still referenced in feed code & tests. */
  fromFeedService?: InputMaybe<Scalars["Boolean"]["input"]>;
  matchedAt?: InputMaybe<Scalars["Timestamp"]["input"]>;
  /** The id of the saved search this entry was matched from. */
  sourceId?: InputMaybe<Scalars["Int"]["input"]>;
  /** All `source_type` values are `SAVED_SEARCH`. */
  sourceType?: InputMaybe<reverb_feed_FeedSourceType>;
};

export type Input_reverb_feed_FeedResponse = {
  entries?: InputMaybe<Array<InputMaybe<Input_reverb_feed_FeedEntry>>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  page?: InputMaybe<Scalars["Int"]["input"]>;
  perPage?: InputMaybe<Scalars["Int"]["input"]>;
  total?: InputMaybe<Scalars["Int"]["input"]>;
};

export type Input_reverb_feed_FindFavoriteRequest = {
  queryParams?: InputMaybe<Scalars["String"]["input"]>;
  userId?: InputMaybe<Scalars["String"]["input"]>;
};

export type Input_reverb_feed_FindFavoriteResponse = {
  favorite?: InputMaybe<Input_reverb_feed_Favorite>;
};

export type Input_reverb_feed_FindFollowByIdRequest = {
  id?: InputMaybe<Scalars["String"]["input"]>;
  userId?: InputMaybe<Scalars["String"]["input"]>;
};

export type Input_reverb_feed_FindFollowBySearchableRequest = {
  searchableId?: InputMaybe<Scalars["String"]["input"]>;
  searchableType?: InputMaybe<reverb_feed_SearchableType>;
  userId?: InputMaybe<Scalars["String"]["input"]>;
};

export type Input_reverb_feed_FindFollowRequest = {
  queryParams?: InputMaybe<Scalars["String"]["input"]>;
  userId?: InputMaybe<Scalars["String"]["input"]>;
};

export type Input_reverb_feed_FindFollowsBySearchableRequest = {
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  searchableId?: InputMaybe<Scalars["String"]["input"]>;
  searchableType?: InputMaybe<reverb_feed_SearchableType>;
};

export type Input_reverb_feed_FindFollowsBySearchableResponse = {
  follows?: InputMaybe<Array<InputMaybe<Input_reverb_feed_Follow>>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  total?: InputMaybe<Scalars["Int"]["input"]>;
};

export type Input_reverb_feed_Follow = {
  createdAt?: InputMaybe<Scalars["Timestamp"]["input"]>;
  digestEnabled?: InputMaybe<Scalars["Boolean"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  localizedContents?: InputMaybe<
    Array<InputMaybe<Input_reverb_feed_FollowLocalizedContents>>
  >;
  queryParams?: InputMaybe<Scalars["String"]["input"]>;
  savedSearchId?: InputMaybe<Scalars["String"]["input"]>;
  searchableId?: InputMaybe<Scalars["String"]["input"]>;
  searchableType?: InputMaybe<reverb_feed_SearchableType>;
  userId?: InputMaybe<Scalars["String"]["input"]>;
};

/** Contains localized content for a saved search. */
export type Input_reverb_feed_FollowLocalizedContents = {
  description?: InputMaybe<Scalars["String"]["input"]>;
  locale?: InputMaybe<Scalars["String"]["input"]>;
  shortDescription?: InputMaybe<Scalars["String"]["input"]>;
  subtitle?: InputMaybe<Scalars["String"]["input"]>;
  title?: InputMaybe<Scalars["String"]["input"]>;
};

export type Input_reverb_feed_FollowUpdated = {
  digestEnabled?: InputMaybe<Scalars["Boolean"]["input"]>;
  esQuery?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  localizedContents?: InputMaybe<
    Array<InputMaybe<Input_reverb_feed_FollowLocalizedContents>>
  >;
  queryParams?: InputMaybe<Scalars["String"]["input"]>;
  savedSearchId?: InputMaybe<Scalars["Int"]["input"]>;
  searchableId?: InputMaybe<Scalars["Int"]["input"]>;
  searchableType?: InputMaybe<reverb_feed_SearchableType>;
  searchableUuid?: InputMaybe<Scalars["String"]["input"]>;
  userId?: InputMaybe<Scalars["Int"]["input"]>;
};

export type Input_reverb_feed_FollowsRequest = {
  locale?: InputMaybe<Scalars["String"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  page?: InputMaybe<Scalars["Int"]["input"]>;
  perPage?: InputMaybe<Scalars["Int"]["input"]>;
  userId?: InputMaybe<Scalars["String"]["input"]>;
};

export type Input_reverb_feed_FollowsTopic = {
  action?: InputMaybe<reverb_feed_FollowsTopic_Action>;
  followId?: InputMaybe<Scalars["Int"]["input"]>;
  followIds?: InputMaybe<Array<InputMaybe<Scalars["Int"]["input"]>>>;
  searchableId?: InputMaybe<Scalars["Int"]["input"]>;
  searchableType?: InputMaybe<reverb_feed_SearchableType>;
  userId?: InputMaybe<Scalars["Int"]["input"]>;
};

export type Input_reverb_feed_GetFavoriteSavedSearchesRequest = {
  excludeOutlet?: InputMaybe<Scalars["Boolean"]["input"]>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  locale?: InputMaybe<Scalars["String"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  userId?: InputMaybe<Scalars["String"]["input"]>;
};

export type Input_reverb_feed_GetFavoriteSavedSearchesResponse = {
  excludeOutlet?: InputMaybe<Scalars["Boolean"]["input"]>;
  favorites?: InputMaybe<Array<InputMaybe<Input_reverb_feed_Favorite>>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  total?: InputMaybe<Scalars["Int"]["input"]>;
};

export type Input_reverb_feed_GetFavoriteShopsRequest = {
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  locale?: InputMaybe<Scalars["String"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  userId?: InputMaybe<Scalars["String"]["input"]>;
};

export type Input_reverb_feed_GetFavoriteShopsResponse = {
  favoriteShops?: InputMaybe<Array<InputMaybe<Input_reverb_feed_Favorite>>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  total?: InputMaybe<Scalars["Int"]["input"]>;
};

export type Input_reverb_feed_GetFollowResponse = {
  follow?: InputMaybe<Input_reverb_feed_Follow>;
  followCount?: InputMaybe<Scalars["Int"]["input"]>;
  following?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type Input_reverb_feed_GetFollowedSavedSearchesRequest = {
  startId?: InputMaybe<Scalars["Int"]["input"]>;
};

export type Input_reverb_feed_GetFollowedSavedSearchesResponse = {
  savedSearchIds?: InputMaybe<Array<InputMaybe<Scalars["Int"]["input"]>>>;
};

export type Input_reverb_feed_GetFollowsResponse = {
  follows?: InputMaybe<Array<InputMaybe<Input_reverb_feed_Follow>>>;
  page?: InputMaybe<Scalars["Int"]["input"]>;
  perPage?: InputMaybe<Scalars["Int"]["input"]>;
  total?: InputMaybe<Scalars["Int"]["input"]>;
};

export type Input_reverb_feed_GetSavedSearchRequest = {
  id?: InputMaybe<Scalars["Int"]["input"]>;
  withMostRecentFollow?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type Input_reverb_feed_GetSavedSearchResponse = {
  esQuery?: InputMaybe<Scalars["String"]["input"]>;
  localizedContents?: InputMaybe<
    Array<InputMaybe<Input_reverb_feed_FollowLocalizedContents>>
  >;
  mostRecentFollowedUserId?: InputMaybe<Scalars["String"]["input"]>;
  queryParams?: InputMaybe<Scalars["String"]["input"]>;
  searchableId?: InputMaybe<Scalars["String"]["input"]>;
  searchableType?: InputMaybe<reverb_feed_SearchableType>;
};

export type Input_reverb_feed_PreviewUserDigestsRequest = {
  matchedAfter?: InputMaybe<Scalars["Timestamp"]["input"]>;
  normalized?: InputMaybe<Scalars["Boolean"]["input"]>;
  users?: InputMaybe<Array<InputMaybe<Input_reverb_feed_User>>>;
};

export type Input_reverb_feed_PruneFeedRequest = {
  fromFeedService?: InputMaybe<Scalars["Boolean"]["input"]>;
  savedSearchId?: InputMaybe<Scalars["Int"]["input"]>;
  userId?: InputMaybe<Scalars["String"]["input"]>;
};

export type Input_reverb_feed_PruneFeedResponse = {
  _?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type Input_reverb_feed_RemoveEntryRequest = {
  entryId?: InputMaybe<Scalars["String"]["input"]>;
  entryType?: InputMaybe<reverb_feed_FeedEntry_EntryType>;
  userId?: InputMaybe<Scalars["String"]["input"]>;
};

export type Input_reverb_feed_RemoveEntryResponse = {
  entry?: InputMaybe<Input_reverb_feed_FeedEntry>;
};

export type Input_reverb_feed_RemoveFavoriteRequest = {
  id?: InputMaybe<Scalars["String"]["input"]>;
  userId?: InputMaybe<Scalars["String"]["input"]>;
};

export type Input_reverb_feed_RemoveFavoriteResponse = {
  favorite?: InputMaybe<Input_reverb_feed_Favorite>;
};

export type Input_reverb_feed_RemoveFollowRequest = {
  follow?: InputMaybe<Input_reverb_feed_Follow>;
  locale?: InputMaybe<Scalars["String"]["input"]>;
  removeEntries?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type Input_reverb_feed_RemoveFollowsBySearchableRequest = {
  searchableId?: InputMaybe<Scalars["Int"]["input"]>;
  searchableType?: InputMaybe<reverb_feed_SearchableType>;
};

export type Input_reverb_feed_RemoveFollowsBySearchableResponse = {
  _?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type Input_reverb_feed_ReplaceFollowsSavedSearchIdRequest = {
  newSavedSearchId?: InputMaybe<Scalars["Int"]["input"]>;
  oldSavedSearchId?: InputMaybe<Scalars["Int"]["input"]>;
};

export type Input_reverb_feed_ReplaceFollowsSavedSearchIdResponse = {
  followsUpdatedCount?: InputMaybe<Scalars["Int"]["input"]>;
  newSavedSearchId?: InputMaybe<Scalars["Int"]["input"]>;
  oldSavedSearchId?: InputMaybe<Scalars["Int"]["input"]>;
};

export type Input_reverb_feed_SavedSearchStreamResponse = {
  queryParams?: InputMaybe<Scalars["String"]["input"]>;
  savedSearchId?: InputMaybe<Scalars["Int"]["input"]>;
};

export type Input_reverb_feed_SavedSearchesStreamRequest = {
  chunkSize?: InputMaybe<Scalars["Int"]["input"]>;
  fromId?: InputMaybe<Scalars["Int"]["input"]>;
};

export type Input_reverb_feed_SavedSearchesStreamResponse = {
  savedSearches?: InputMaybe<
    Array<InputMaybe<Input_reverb_feed_SavedSearchStreamResponse>>
  >;
};

export type Input_reverb_feed_SetAlertableUsersTzRequest = {
  tzRegion?: InputMaybe<Scalars["Int"]["input"]>;
  userIds?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
};

export type Input_reverb_feed_SetAlertableUsersTzResponse = {
  _?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type Input_reverb_feed_SetFollowDigestRequest = {
  digestEnabled?: InputMaybe<Scalars["Boolean"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  locale?: InputMaybe<Scalars["String"]["input"]>;
  userId?: InputMaybe<Scalars["String"]["input"]>;
};

export type Input_reverb_feed_SetFollowDigestResponse = {
  follow?: InputMaybe<Input_reverb_feed_Follow>;
};

export type Input_reverb_feed_SetFollowDigestsByUserRequest = {
  digestsEnabled?: InputMaybe<Scalars["Boolean"]["input"]>;
  userId?: InputMaybe<Scalars["String"]["input"]>;
};

export type Input_reverb_feed_SetFollowDigestsByUserResponse = {
  follows?: InputMaybe<Array<InputMaybe<Input_reverb_feed_Follow>>>;
};

export type Input_reverb_feed_UndoRemoveFavoriteRequest = {
  id?: InputMaybe<Scalars["String"]["input"]>;
  userId?: InputMaybe<Scalars["String"]["input"]>;
};

export type Input_reverb_feed_UndoRemoveFavoriteResponse = {
  favorite?: InputMaybe<Input_reverb_feed_Favorite>;
};

export type Input_reverb_feed_UpdateSavedSearchRequest = {
  esQuery?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["Int"]["input"]>;
  localizedContents?: InputMaybe<
    Array<InputMaybe<Input_reverb_feed_FollowLocalizedContents>>
  >;
  queryParams?: InputMaybe<Scalars["String"]["input"]>;
  searchableId?: InputMaybe<Scalars["String"]["input"]>;
  searchableType?: InputMaybe<reverb_feed_SearchableType>;
};

export type Input_reverb_feed_UpdateSavedSearchResponse = {
  id?: InputMaybe<Scalars["Int"]["input"]>;
};

export type Input_reverb_feed_UpsertFavoriteRequest = {
  emailEnabled?: InputMaybe<Scalars["Boolean"]["input"]>;
  esQuery?: InputMaybe<Scalars["String"]["input"]>;
  feedEnabled?: InputMaybe<Scalars["Boolean"]["input"]>;
  localizedContents?: InputMaybe<
    Array<InputMaybe<Input_reverb_feed_FollowLocalizedContents>>
  >;
  queryParams?: InputMaybe<Scalars["String"]["input"]>;
  searchableId?: InputMaybe<Scalars["String"]["input"]>;
  searchableType?: InputMaybe<reverb_feed_SearchableType>;
  userId?: InputMaybe<Scalars["Int"]["input"]>;
};

export type Input_reverb_feed_UpsertFavoriteResponse = {
  favorite?: InputMaybe<Input_reverb_feed_Favorite>;
};

export type Input_reverb_feed_UpsertFollowResponse = {
  follow?: InputMaybe<Input_reverb_feed_Follow>;
  followCount?: InputMaybe<Scalars["Int"]["input"]>;
  following?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type Input_reverb_feed_User = {
  lastActiveAt?: InputMaybe<Scalars["Timestamp"]["input"]>;
  locale?: InputMaybe<Scalars["String"]["input"]>;
  userId?: InputMaybe<Scalars["String"]["input"]>;
};

export type Input_reverb_feed_UserDigest = {
  digestFollows?: InputMaybe<
    Array<InputMaybe<Input_reverb_feed_DigestFollows>>
  >;
  userId?: InputMaybe<Scalars["String"]["input"]>;
};

export type Input_reverb_feed_UserDigestsRequest = {
  matchedAfter?: InputMaybe<Scalars["Timestamp"]["input"]>;
  users?: InputMaybe<Array<InputMaybe<Input_reverb_feed_User>>>;
};

export type Input_reverb_feed_UserDigestsResponse = {
  userDigests?: InputMaybe<Array<InputMaybe<Input_reverb_feed_UserDigest>>>;
};

export type Input_reverb_feed_Users = {
  users?: InputMaybe<Array<InputMaybe<Input_reverb_feed_User>>>;
};

export type Input_reverb_personalization_GetTrendingSearchesRequest = {
  categoryUuids?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  nResults?: InputMaybe<Scalars["Int"]["input"]>;
};

export type Input_reverb_personalization_GetUserRequest = {
  id?: InputMaybe<Scalars["String"]["input"]>;
  version?: InputMaybe<Scalars["Int"]["input"]>;
};

export type Input_reverb_personalization_QuerySuggestionResponse = {
  query?: InputMaybe<Input_reverb_personalization_SearchQuery>;
  suggestions?: InputMaybe<
    Array<InputMaybe<Input_reverb_personalization_SearchQuery>>
  >;
};

export type Input_reverb_personalization_QuerySuggestionsRequest = {
  limitPerQuery?: InputMaybe<Scalars["Int"]["input"]>;
  queries?: InputMaybe<
    Array<InputMaybe<Input_reverb_personalization_SearchQuery>>
  >;
};

export type Input_reverb_personalization_QuerySuggestionsResponse = {
  queries?: InputMaybe<
    Array<InputMaybe<Input_reverb_personalization_QuerySuggestionResponse>>
  >;
};

export type Input_reverb_personalization_RefreshListingRecommendationsRequest =
  {
    _?: InputMaybe<Scalars["Boolean"]["input"]>;
  };

export type Input_reverb_personalization_RefreshListingRecommendationsRequestV2 =
  {
    runId?: InputMaybe<Scalars["String"]["input"]>;
  };

export type Input_reverb_personalization_RefreshQuerySuggestionsRequest = {
  _?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type Input_reverb_personalization_RefreshTrendingSearchesRequest = {
  _?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type Input_reverb_personalization_SearchQuery = {
  category?: InputMaybe<Scalars["String"]["input"]>;
  listingIds?: InputMaybe<Array<InputMaybe<Scalars["Int"]["input"]>>>;
  make?: InputMaybe<Scalars["String"]["input"]>;
  productType?: InputMaybe<Scalars["String"]["input"]>;
  query?: InputMaybe<Scalars["String"]["input"]>;
};

export type Input_reverb_personalization_TrendingSearch = {
  categorySlug?: InputMaybe<Scalars["String"]["input"]>;
  categoryUuid?: InputMaybe<Scalars["String"]["input"]>;
  listingIds?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  query?: InputMaybe<Scalars["String"]["input"]>;
  score?: InputMaybe<Scalars["Float"]["input"]>;
};

export type Input_reverb_personalization_TrendingSearchesResponse = {
  trendingSearches?: InputMaybe<
    Array<InputMaybe<Input_reverb_personalization_TrendingSearch>>
  >;
};

export type Input_reverb_personalization_User = {
  id?: InputMaybe<Scalars["String"]["input"]>;
  recommendedListingIds?: InputMaybe<
    Array<InputMaybe<Scalars["String"]["input"]>>
  >;
};

export type Input_reverb_pricing_CSPPriceRecommendationQuery = {
  comparisonShoppingPageId?: InputMaybe<Scalars["String"]["input"]>;
  conditionUuid?: InputMaybe<Scalars["String"]["input"]>;
  countryCode?: InputMaybe<Scalars["String"]["input"]>;
  currency?: InputMaybe<Scalars["String"]["input"]>;
};

export type Input_reverb_pricing_CSPPriceRecommendationsRequest = {
  cspPriceRecommendationQueries?: InputMaybe<
    Array<InputMaybe<Input_reverb_pricing_CSPPriceRecommendationQuery>>
  >;
  modelVersion?: InputMaybe<reverb_pricing_ModelVersion>;
  version?: InputMaybe<Scalars["Int"]["input"]>;
};

export type Input_reverb_pricing_Money = {
  amount?: InputMaybe<Scalars["String"]["input"]>;
  amountCents?: InputMaybe<Scalars["Int"]["input"]>;
  currency?: InputMaybe<Scalars["String"]["input"]>;
  display?: InputMaybe<Scalars["String"]["input"]>;
  symbol?: InputMaybe<Scalars["String"]["input"]>;
};

export type Input_reverb_pricing_PriceRecommendation = {
  canonicalProductId?: InputMaybe<Scalars["String"]["input"]>;
  comparisonShoppingPageId?: InputMaybe<Scalars["String"]["input"]>;
  conditionUuid?: InputMaybe<Scalars["String"]["input"]>;
  countryCode?: InputMaybe<Scalars["String"]["input"]>;
  priceHigh?: InputMaybe<Input_reverb_pricing_Money>;
  priceHighThirtyDaysAgo?: InputMaybe<Input_reverb_pricing_Money>;
  priceLow?: InputMaybe<Input_reverb_pricing_Money>;
  priceLowThirtyDaysAgo?: InputMaybe<Input_reverb_pricing_Money>;
  priceMiddle?: InputMaybe<Input_reverb_pricing_Money>;
  priceMiddleThirtyDaysAgo?: InputMaybe<Input_reverb_pricing_Money>;
};

export type Input_reverb_pricing_PriceRecommendationQuery = {
  canonicalProductId?: InputMaybe<Scalars["String"]["input"]>;
  conditionUuid?: InputMaybe<Scalars["String"]["input"]>;
  countryCode?: InputMaybe<Scalars["String"]["input"]>;
  currency?: InputMaybe<Scalars["String"]["input"]>;
};

export type Input_reverb_pricing_PriceRecommendationsRequest = {
  modelVersion?: InputMaybe<reverb_pricing_ModelVersion>;
  priceRecommendationQueries?: InputMaybe<
    Array<InputMaybe<Input_reverb_pricing_PriceRecommendationQuery>>
  >;
  version?: InputMaybe<Scalars["Int"]["input"]>;
};

export type Input_reverb_pricing_PriceRecommendationsResponse = {
  priceRecommendations?: InputMaybe<
    Array<InputMaybe<Input_reverb_pricing_PriceRecommendation>>
  >;
};

export type Input_reverb_pricing_PriceScore = {
  absResidual?: InputMaybe<Scalars["Float"]["input"]>;
  canonicalProductId?: InputMaybe<Scalars["String"]["input"]>;
  conditionUuid?: InputMaybe<Scalars["String"]["input"]>;
  priceMiddle?: InputMaybe<Input_reverb_pricing_Money>;
  priceScore?: InputMaybe<Scalars["Float"]["input"]>;
};

export type Input_reverb_pricing_PriceScoreQuery = {
  canonicalProductId?: InputMaybe<Scalars["String"]["input"]>;
  conditionUuid?: InputMaybe<Scalars["String"]["input"]>;
  countryCode?: InputMaybe<Scalars["String"]["input"]>;
  price?: InputMaybe<Input_reverb_pricing_Money>;
};

export type Input_reverb_pricing_PriceScoresRequest = {
  modelVersion?: InputMaybe<reverb_pricing_ModelVersion>;
  priceScoreQueries?: InputMaybe<
    Array<InputMaybe<Input_reverb_pricing_PriceScoreQuery>>
  >;
  version?: InputMaybe<Scalars["Int"]["input"]>;
};

export type Input_reverb_pricing_PriceScoresResponse = {
  priceScores?: InputMaybe<Array<InputMaybe<Input_reverb_pricing_PriceScore>>>;
};

export type Input_reverb_reporting_CSPStats = {
  /** total_order_value_usc / order_quantity */
  avgOrderPriceUsc?: InputMaybe<Input_reverb_reporting_Int32Value>;
  liveListingCount?: InputMaybe<Scalars["Int"]["input"]>;
  minListingPriceUsc?: InputMaybe<Input_reverb_reporting_Int32Value>;
  orderQuantity?: InputMaybe<Scalars["Int"]["input"]>;
  totalOrderValueUsc?: InputMaybe<Scalars["Int"]["input"]>;
};

export type Input_reverb_reporting_CSPStatsByCSP = {
  /** total_order_value_usc / order_quantity */
  avgOrderPriceUsc?: InputMaybe<Input_reverb_reporting_Int32Value>;
  brandSlug?: InputMaybe<Scalars["String"]["input"]>;
  cspId?: InputMaybe<Scalars["String"]["input"]>;
  cspSlug?: InputMaybe<Scalars["String"]["input"]>;
  liveListingCount?: InputMaybe<Scalars["Int"]["input"]>;
  minListingPriceUsc?: InputMaybe<Input_reverb_reporting_Int32Value>;
  orderQuantity?: InputMaybe<Scalars["Int"]["input"]>;
  totalOrderValueUsc?: InputMaybe<Scalars["Int"]["input"]>;
};

export type Input_reverb_reporting_CSPStatsByCSPRequest = {
  brandSlugs?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  condition?: InputMaybe<reverb_reporting_Condition>;
  cspIds?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  cspSlugs?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  dateEnd?: InputMaybe<Scalars["String"]["input"]>;
  dateStart?: InputMaybe<Scalars["String"]["input"]>;
  includeTotal?: InputMaybe<Scalars["Boolean"]["input"]>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<reverb_reporting_CSPStatsMetric>;
  orderDesc?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type Input_reverb_reporting_CSPStatsByCSPResponse = {
  byCsp?: InputMaybe<Array<InputMaybe<Input_reverb_reporting_CSPStatsByCSP>>>;
  totalCount?: InputMaybe<Scalars["Int"]["input"]>;
};

export type Input_reverb_reporting_CSPStatsByDate = {
  /** total_order_value_usc / order_quantity */
  avgOrderPriceUsc?: InputMaybe<Input_reverb_reporting_Int32Value>;
  date?: InputMaybe<Scalars["String"]["input"]>;
  liveListingCount?: InputMaybe<Scalars["Int"]["input"]>;
  minListingPriceUsc?: InputMaybe<Input_reverb_reporting_Int32Value>;
  orderQuantity?: InputMaybe<Scalars["Int"]["input"]>;
  totalOrderValueUsc?: InputMaybe<Scalars["Int"]["input"]>;
};

export type Input_reverb_reporting_CSPStatsByDateResponse = {
  /** Agg across csps, 1 row/date */
  byDate?: InputMaybe<Array<InputMaybe<Input_reverb_reporting_CSPStatsByDate>>>;
};

export type Input_reverb_reporting_CSPStatsRequest = {
  brandSlugs?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  condition?: InputMaybe<reverb_reporting_Condition>;
  cspIds?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  cspSlugs?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  dateEnd?: InputMaybe<Scalars["String"]["input"]>;
  dateStart?: InputMaybe<Scalars["String"]["input"]>;
};

/**
 *   To represent a null or an integer. Including in this package instead of using
 * the google wrapper because we need to implement a Scan method for reading
 * from db
 */
export type Input_reverb_reporting_Int32Value = {
  value?: InputMaybe<Scalars["Int"]["input"]>;
};

export type Input_reverb_reporting_ShopStatsByDateRequest = {
  currency?: InputMaybe<Scalars["String"]["input"]>;
  endTime?: InputMaybe<Scalars["String"]["input"]>;
  shopId?: InputMaybe<Scalars["String"]["input"]>;
  startTime?: InputMaybe<Scalars["String"]["input"]>;
  timeGrouping?: InputMaybe<reverb_reporting_TimeGrouping>;
  timeZone?: InputMaybe<Scalars["String"]["input"]>;
};

export type Input_reverb_reporting_ShopStatsByDateResponse = {
  buckets?: InputMaybe<
    Array<InputMaybe<Input_reverb_reporting_ShopStatsTimeBucket>>
  >;
};

export type Input_reverb_reporting_ShopStatsCurrencyCountResponse = {
  currencyCount?: InputMaybe<Scalars["Int"]["input"]>;
};

export type Input_reverb_reporting_ShopStatsRequest = {
  currency?: InputMaybe<Scalars["String"]["input"]>;
  endTime?: InputMaybe<Scalars["String"]["input"]>;
  shopId?: InputMaybe<Scalars["String"]["input"]>;
  startTime?: InputMaybe<Scalars["String"]["input"]>;
};

export type Input_reverb_reporting_ShopStatsResponse = {
  messagesCount?: InputMaybe<Scalars["Int"]["input"]>;
  offersCount?: InputMaybe<Scalars["Int"]["input"]>;
  ordersCount?: InputMaybe<Scalars["Int"]["input"]>;
  salesCents?: InputMaybe<Scalars["Int"]["input"]>;
  watchersCount?: InputMaybe<Scalars["Int"]["input"]>;
};

export type Input_reverb_reporting_ShopStatsTimeBucket = {
  salesCents?: InputMaybe<Scalars["Int"]["input"]>;
  timestamp?: InputMaybe<Scalars["String"]["input"]>;
};

export type Input_reverb_reporting_ctr_CspCtr = {
  clicks?: InputMaybe<Scalars["Int"]["input"]>;
  cspId?: InputMaybe<Scalars["String"]["input"]>;
  impressions?: InputMaybe<Scalars["Int"]["input"]>;
};

export type Input_reverb_reporting_ctr_CspsCtrByDateRangeRequest = {
  cspIds?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  datetimeEnd?: InputMaybe<Scalars["String"]["input"]>;
  datetimeStart?: InputMaybe<Scalars["String"]["input"]>;
};

export type Input_reverb_reporting_ctr_CspsCtrByDateRangeResponse = {
  ctrs?: InputMaybe<Array<InputMaybe<Input_reverb_reporting_ctr_CspCtr>>>;
};

export type Input_reverb_search_AggregationDetails = {
  count?: InputMaybe<Scalars["Int"]["input"]>;
  displayName?: InputMaybe<Scalars["String"]["input"]>;
  key?: InputMaybe<Scalars["String"]["input"]>;
};

export type Input_reverb_search_AggregationResult = {
  aggregationDetails?: InputMaybe<
    Array<InputMaybe<Input_reverb_search_AggregationDetails>>
  >;
  displayName?: InputMaybe<Scalars["String"]["input"]>;
  name?: InputMaybe<reverb_search_ListingsSearchRequest_Aggregation>;
};

export type Input_reverb_search_AuctionsSearchRequest = {
  bidderId?: InputMaybe<Scalars["String"]["input"]>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  merchandisingTypes?: InputMaybe<
    Array<InputMaybe<reverb_search_AuctionsSearchRequest_MerchandisingType>>
  >;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  query?: InputMaybe<Scalars["String"]["input"]>;
  userBidStatus?: InputMaybe<reverb_search_AuctionsSearchRequest_UserBidStatus>;
  withAggregations?: InputMaybe<
    Array<InputMaybe<reverb_search_AuctionsSearchRequest_Aggregation>>
  >;
};

export type Input_reverb_search_AutocompleteRequest = {
  currency?: InputMaybe<Scalars["String"]["input"]>;
  limit?: InputMaybe<reverb_search_AutocompleteRequest_Limit>;
  locale?: InputMaybe<Scalars["String"]["input"]>;
  query?: InputMaybe<Scalars["String"]["input"]>;
  shippingRegionCode?: InputMaybe<Scalars["String"]["input"]>;
};

export type Input_reverb_search_AutocompleteResponse = {
  suggestions?: InputMaybe<
    Array<InputMaybe<Input_reverb_search_AutocompleteSuggestion>>
  >;
};

export type Input_reverb_search_AutocompleteSuggestion = {
  subtext?: InputMaybe<Scalars["String"]["input"]>;
  text?: InputMaybe<Scalars["String"]["input"]>;
  type?: InputMaybe<Scalars["String"]["input"]>;
  url?: InputMaybe<Input_reverb_search_Link>;
};

export type Input_reverb_search_AverageMonthlyProductPrice = {
  averageProductPrice?: InputMaybe<Input_reverb_search_Money>;
  averageProductPriceInUsd?: InputMaybe<Input_reverb_search_Money>;
  date?: InputMaybe<Scalars["String"]["input"]>;
  docCount?: InputMaybe<Scalars["Int"]["input"]>;
};

/** Used for feed related queries to find live listings that match any of a user's saved searches. */
export type Input_reverb_search_BatchListingsSearchRequest = {
  /** Optional top-level pagination param, use this instead of `listings_filters.limit`. */
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  /** Optional top-level filters to apply across all results: ((query_params_1 OR query_params_2) AND (listings_filters)). */
  listingsFilters?: InputMaybe<Input_reverb_search_ListingsSearchRequest>;
  /** Optional top-level pagination param, use this instead of `listings_filters.offset`. */
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  /** Array of JSON-serialized strings. Each string represents query params for 1 search (saved search filters), such as '[{"query":"sp 1200", "currency": "USD", "price_max": "2000.00" }, {"query": "telecaster", "brand_slugs":["fender"]}]'. */
  queryParams?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
};

/** Request for feed related queries that check if a batch of searches matched any recently updated listings. */
export type Input_reverb_search_BatchMatchedListingsSearchRequest = {
  searches?: InputMaybe<
    Array<
      InputMaybe<Input_reverb_search_BatchMatchedListingsSearchRequest_SearchInput>
    >
  >;
  /** ISO-8601 timestamp string */
  updatedAfter?: InputMaybe<Scalars["String"]["input"]>;
};

export type Input_reverb_search_BatchMatchedListingsSearchRequest_SearchInput =
  {
    queryParams?: InputMaybe<Scalars["String"]["input"]>;
    searchId?: InputMaybe<Scalars["String"]["input"]>;
  };

/** Response for feed related queries that check if a batch of searches matched any recently updated listings. */
export type Input_reverb_search_BatchMatchedListingsSearchResponse = {
  matchedListingsSearchResults?: InputMaybe<
    Array<
      InputMaybe<Input_reverb_search_BatchMatchedListingsSearchResponse_SearchResult>
    >
  >;
};

export type Input_reverb_search_BatchMatchedListingsSearchResponse_SearchResult =
  {
    result?: InputMaybe<reverb_search_BatchMatchedListingsSearchResponse_SearchResult_ResultType>;
    searchId?: InputMaybe<Scalars["String"]["input"]>;
    total?: InputMaybe<Scalars["Int"]["input"]>;
  };

export type Input_reverb_search_BestListing = {
  id?: InputMaybe<Scalars["String"]["input"]>;
  key?: InputMaybe<Scalars["String"]["input"]>;
};

export type Input_reverb_search_BumpRecommendationsResults = {
  bumpRecommendationAmount?: InputMaybe<Scalars["Float"]["input"]>;
  maxBumpRecommendationAmount?: InputMaybe<Scalars["Float"]["input"]>;
};

export type Input_reverb_search_BumpedSortedListingsQuery = {
  bumpedMax?: InputMaybe<Scalars["Int"]["input"]>;
  condition?: InputMaybe<Scalars["String"]["input"]>;
  itemRegion?: InputMaybe<Scalars["String"]["input"]>;
  total?: InputMaybe<Scalars["Int"]["input"]>;
};

export type Input_reverb_search_CMSPagesSearchRequest = {
  fullTextQuery?: InputMaybe<Scalars["String"]["input"]>;
  ids?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  pageTypes?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
};

export type Input_reverb_search_CSPInventory = {
  newLowPrice?: InputMaybe<Input_reverb_search_Money>;
  newTotal?: InputMaybe<Scalars["Int"]["input"]>;
  usedLowPrice?: InputMaybe<Input_reverb_search_Money>;
  usedTotal?: InputMaybe<Scalars["Int"]["input"]>;
};

export type Input_reverb_search_CSPResponse = {
  averageReviewRating?: InputMaybe<Scalars["Float"]["input"]>;
  cardSquareImageUrl?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  inventory?: InputMaybe<Input_reverb_search_CSPInventory>;
  newListingsLink?: InputMaybe<Scalars["String"]["input"]>;
  reviewsCount?: InputMaybe<Scalars["Int"]["input"]>;
  slug?: InputMaybe<Scalars["String"]["input"]>;
  thumbnailImageUrl?: InputMaybe<Scalars["String"]["input"]>;
  title?: InputMaybe<Scalars["String"]["input"]>;
  usedListingsLink?: InputMaybe<Scalars["String"]["input"]>;
  uuid?: InputMaybe<Scalars["String"]["input"]>;
  webLink?: InputMaybe<Scalars["String"]["input"]>;
};

export type Input_reverb_search_CSPSearchRequest = {
  autodirects?: InputMaybe<reverb_search_Autodirects>;
  boostByClicks?: InputMaybe<Scalars["Boolean"]["input"]>;
  brandNames?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  brandSlugs?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  categorySlugs?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  categoryUuids?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  condition?: InputMaybe<Scalars["String"]["input"]>;
  contexts?: InputMaybe<Array<InputMaybe<reverb_search_Context>>>;
  countryOfOrigin?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  curatedSetId?: InputMaybe<Scalars["String"]["input"]>;
  curatedSetSlugs?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  currency?: InputMaybe<Scalars["String"]["input"]>;
  decades?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  es7?: InputMaybe<Scalars["Boolean"]["input"]>;
  excludedCategoryUuids?: InputMaybe<
    Array<InputMaybe<Scalars["String"]["input"]>>
  >;
  finishes?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  fullTextQuery?: InputMaybe<Scalars["String"]["input"]>;
  fullTextQueryOperand?: InputMaybe<reverb_search_FullTextQueryOperand>;
  fuzzy?: InputMaybe<Scalars["Boolean"]["input"]>;
  hasExpressSaleBid?: InputMaybe<Scalars["Boolean"]["input"]>;
  ids?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  includeFields?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  includeOutOfStock?: InputMaybe<Scalars["Boolean"]["input"]>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  listingsThatShipTo?: InputMaybe<Scalars["String"]["input"]>;
  locale?: InputMaybe<Scalars["String"]["input"]>;
  maxPriceUsdCents?: InputMaybe<Scalars["Int"]["input"]>;
  minPriceUsdCents?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  priceMax?: InputMaybe<Scalars["String"]["input"]>;
  priceMin?: InputMaybe<Scalars["String"]["input"]>;
  searchUrlParams?: InputMaybe<Scalars["String"]["input"]>;
  similarCspSlug?: InputMaybe<Scalars["String"]["input"]>;
  similarListingId?: InputMaybe<Scalars["String"]["input"]>;
  skipAutodirects?: InputMaybe<Scalars["Boolean"]["input"]>;
  slugs?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  sort?: InputMaybe<reverb_search_CSPSearchRequest_Sort>;
  sortByQuality?: InputMaybe<Scalars["Boolean"]["input"]>;
  suggestTitle?: InputMaybe<Scalars["String"]["input"]>;
  traitSlugs?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  traitValues?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  uuids?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  withAggregations?: InputMaybe<
    Array<InputMaybe<reverb_search_CSPSearchRequest_Aggregation>>
  >;
  withInventory?: InputMaybe<Scalars["Boolean"]["input"]>;
  withLimitedAggregations?: InputMaybe<Input_reverb_search_LimitedAggregation>;
  yearMax?: InputMaybe<Scalars["Int"]["input"]>;
  yearMin?: InputMaybe<Scalars["Int"]["input"]>;
};

export type Input_reverb_search_Completion = {
  options?: InputMaybe<Array<InputMaybe<Input_reverb_search_SuggestOption>>>;
  text?: InputMaybe<Scalars["String"]["input"]>;
  type?: InputMaybe<reverb_search_CompletionType>;
};

export type Input_reverb_search_CompletionsRequest = {
  currency?: InputMaybe<Scalars["String"]["input"]>;
  experiments?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  hideStaleListings?: InputMaybe<Scalars["Boolean"]["input"]>;
  locale?: InputMaybe<Scalars["String"]["input"]>;
  query?: InputMaybe<Scalars["String"]["input"]>;
  shippingRegionCode?: InputMaybe<Scalars["String"]["input"]>;
  types?: InputMaybe<Array<InputMaybe<reverb_search_CompletionType>>>;
};

export type Input_reverb_search_DateBucket = {
  /** ISO8601 date */
  fromDate?: InputMaybe<Scalars["String"]["input"]>;
  metrics?: InputMaybe<Input_reverb_search_OrderMetrics>;
  /** ISO8601 date */
  toDate?: InputMaybe<Scalars["String"]["input"]>;
};

export type Input_reverb_search_Distance = {
  unit?: InputMaybe<reverb_search_Distance_DistanceUnit>;
  /** / Both whole numbers and decimals are supported. */
  value?: InputMaybe<Scalars["String"]["input"]>;
};

export type Input_reverb_search_Document = {
  esCsp?: InputMaybe<Input_reverb_search_CSPResponse>;
  esGearCollectionItem?: InputMaybe<Input_reverb_search_GearCollectionItemResponse>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  source?: InputMaybe<Scalars["String"]["input"]>;
  type?: InputMaybe<Scalars["String"]["input"]>;
};

export type Input_reverb_search_ExperimentData = {
  group?: InputMaybe<Scalars["String"]["input"]>;
  groupNum?: InputMaybe<Scalars["Int"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
};

export type Input_reverb_search_Filter = {
  aggregationName?: InputMaybe<Scalars["String"]["input"]>;
  key?: InputMaybe<Scalars["String"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  options?: InputMaybe<Array<InputMaybe<Input_reverb_search_FilterOption>>>;
  widgetType?: InputMaybe<reverb_search_Filter_WidgetType>;
};

export type Input_reverb_search_FilterOption = {
  all?: InputMaybe<Scalars["Boolean"]["input"]>;
  autoselected?: InputMaybe<Scalars["Boolean"]["input"]>;
  count?: InputMaybe<Input_google_protobuf_Int32Value>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  optionValue?: InputMaybe<Scalars["String"]["input"]>;
  paramName?: InputMaybe<Scalars["String"]["input"]>;
  selected?: InputMaybe<Scalars["Boolean"]["input"]>;
  setValues?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  subFilter?: InputMaybe<Input_reverb_search_Filter>;
  trackingValue?: InputMaybe<Scalars["String"]["input"]>;
  unsetValues?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  urlParamName?: InputMaybe<Scalars["String"]["input"]>;
};

export type Input_reverb_search_GearCollectionItemResponse = {
  id?: InputMaybe<Scalars["String"]["input"]>;
  priceEstimate?: InputMaybe<Input_reverb_search_PriceEstimate>;
};

export type Input_reverb_search_GearCollectionItemsSearchRequest = {
  canonicalProductIds?: InputMaybe<
    Array<InputMaybe<Scalars["String"]["input"]>>
  >;
  categorySlugs?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  categoryUuids?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  countryCode?: InputMaybe<Scalars["String"]["input"]>;
  currency?: InputMaybe<Scalars["String"]["input"]>;
  ids?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  locale?: InputMaybe<Scalars["String"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  states?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  tags?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  userUuids?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  withAggregations?: InputMaybe<
    Array<
      InputMaybe<reverb_search_GearCollectionItemsSearchRequest_Aggregation>
    >
  >;
  withCollectionStats?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type Input_reverb_search_GearCollectionStats = {
  totalCollectionEstimate?: InputMaybe<Input_reverb_search_GearCollectionTotalEstimate>;
};

export type Input_reverb_search_GearCollectionTotalEstimate = {
  totalEstimateHigh?: InputMaybe<Input_reverb_search_Money>;
  totalEstimateLow?: InputMaybe<Input_reverb_search_Money>;
};

export type Input_reverb_search_Inventory = {
  listingCount?: InputMaybe<Scalars["Int"]["input"]>;
  listingMinPrice?: InputMaybe<Input_reverb_search_Money>;
};

export type Input_reverb_search_LPAlbumSearchRequest = {
  artistSlug?: InputMaybe<Scalars["String"]["input"]>;
  artistUuid?: InputMaybe<Scalars["String"]["input"]>;
  ids?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  slugs?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  suggest?: InputMaybe<Scalars["String"]["input"]>;
  withInventory?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type Input_reverb_search_LPArtistSearchRequest = {
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  suggest?: InputMaybe<Scalars["String"]["input"]>;
};

export type Input_reverb_search_LPCompletionsRequest = {
  query?: InputMaybe<Scalars["String"]["input"]>;
};

export type Input_reverb_search_LPLabelSearchRequest = {
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  suggest?: InputMaybe<Scalars["String"]["input"]>;
};

export type Input_reverb_search_LPListingsSearchRequest = {
  acceptsDirectCheckout?: InputMaybe<Scalars["Boolean"]["input"]>;
  acceptsOffers?: InputMaybe<Scalars["Boolean"]["input"]>;
  albumSlug?: InputMaybe<Scalars["String"]["input"]>;
  auctions?: InputMaybe<Scalars["Boolean"]["input"]>;
  condition?: InputMaybe<reverb_search_LPListingsSearchRequest_Condition>;
  country?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  currency?: InputMaybe<Scalars["String"]["input"]>;
  excludeIds?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  format?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  ids?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  includeBestListings?: InputMaybe<Scalars["Boolean"]["input"]>;
  itemRegion?: InputMaybe<Scalars["String"]["input"]>;
  lengths?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  mediaCondition?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  noAuctions?: InputMaybe<Scalars["Boolean"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  onSale?: InputMaybe<Scalars["Boolean"]["input"]>;
  priceMaxCents?: InputMaybe<Scalars["Int"]["input"]>;
  priceMinCents?: InputMaybe<Scalars["Int"]["input"]>;
  priceRange?: InputMaybe<Scalars["String"]["input"]>;
  releaseDecades?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  releaseSlug?: InputMaybe<Scalars["String"]["input"]>;
  releaseUuid?: InputMaybe<Scalars["String"]["input"]>;
  shippingRegion?: InputMaybe<Scalars["String"]["input"]>;
  sidebarSearch?: InputMaybe<Scalars["String"]["input"]>;
  sleeveCondition?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  sort?: InputMaybe<Scalars["String"]["input"]>;
  storefrontSlug?: InputMaybe<Scalars["String"]["input"]>;
};

export type Input_reverb_search_LPReleasesSearchRequest = {
  countries?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  formatUuids?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  fullTextQuery?: InputMaybe<Scalars["String"]["input"]>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  masterUuid?: InputMaybe<Scalars["String"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  releaseDecades?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  sort?: InputMaybe<Scalars["String"]["input"]>;
  withAggregations?: InputMaybe<
    Array<InputMaybe<reverb_search_LPReleasesSearchRequest_Aggregation>>
  >;
  withInventory?: InputMaybe<Scalars["Boolean"]["input"]>;
  withLimitedAggregations?: InputMaybe<Input_reverb_search_LimitedAggregation>;
};

export type Input_reverb_search_LPTransactionsSearchRequest = {
  actionableStatuses?: InputMaybe<
    Array<
      InputMaybe<reverb_search_LPTransactionsSearchRequest_ActionableStatus>
    >
  >;
  createdAfter?: InputMaybe<Scalars["String"]["input"]>;
  createdBefore?: InputMaybe<Scalars["String"]["input"]>;
  filterPurchased?: InputMaybe<Scalars["Boolean"]["input"]>;
  releaseUuids?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  withAggregations?: InputMaybe<
    Array<InputMaybe<reverb_search_LPTransactionsSearchRequest_Aggregation>>
  >;
  withLimitedAggregations?: InputMaybe<Input_reverb_search_LimitedAggregation>;
};

export type Input_reverb_search_LimitedAggregation = {
  brandUuids?: InputMaybe<Scalars["Int"]["input"]>;
  categoryUuids?: InputMaybe<Scalars["Int"]["input"]>;
};

export type Input_reverb_search_Link = {
  href?: InputMaybe<Scalars["String"]["input"]>;
  /** rel path for use in web routing */
  path?: InputMaybe<Scalars["String"]["input"]>;
};

export type Input_reverb_search_ListingRecommendationStats = {
  totalGreaterThanCompetitivePrice?: InputMaybe<Scalars["Int"]["input"]>;
  totalWithWatchersAndWithoutAutoOffers?: InputMaybe<Scalars["Int"]["input"]>;
};

export type Input_reverb_search_ListingRecommendationsRequest = {
  brandCategories?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  mparticleProfileResponse?: InputMaybe<Input_reverb_search_MparticleProfileResponse>;
};

export type Input_reverb_search_ListingsAggregationResponse = {
  aggregationResults?: InputMaybe<
    Array<InputMaybe<Input_reverb_search_AggregationResult>>
  >;
  topCollections?: InputMaybe<Input_reverb_search_TopCollectionsResult>;
};

export type Input_reverb_search_ListingsPercolationQueryRequest = {
  savedSearchQueryParams?: InputMaybe<Scalars["String"]["input"]>;
};

export type Input_reverb_search_ListingsPercolationQueryResponse = {
  esQuery?: InputMaybe<Scalars["String"]["input"]>;
};

export type Input_reverb_search_ListingsSearchRequest = {
  acceptsAffirm?: InputMaybe<Scalars["Boolean"]["input"]>;
  acceptsAutoOffers?: InputMaybe<Scalars["Boolean"]["input"]>;
  acceptsBuyerOffers?: InputMaybe<Scalars["Boolean"]["input"]>;
  acceptsGiftCards?: InputMaybe<Scalars["Boolean"]["input"]>;
  acceptsOffers?: InputMaybe<Scalars["Boolean"]["input"]>;
  acceptsPaymentPlans?: InputMaybe<Scalars["Boolean"]["input"]>;
  aggregationsOnly?: InputMaybe<Scalars["Boolean"]["input"]>;
  applyProximityBoost?: InputMaybe<Scalars["Boolean"]["input"]>;
  autodirects?: InputMaybe<reverb_search_Autodirects>;
  bestMatchSignalsV2ExperimentGroup?: InputMaybe<Scalars["Int"]["input"]>;
  boostByBumpRate?: InputMaybe<Scalars["Boolean"]["input"]>;
  boostCombinedShippingCategories?: InputMaybe<
    Array<InputMaybe<Scalars["String"]["input"]>>
  >;
  boostedItemRegionCode?: InputMaybe<Scalars["String"]["input"]>;
  brandSlugs?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  brandUuids?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  bumpCount?: InputMaybe<Scalars["Int"]["input"]>;
  bumpedOnly?: InputMaybe<Scalars["Boolean"]["input"]>;
  bumpedSortedListingsQuery?: InputMaybe<Input_reverb_search_BumpedSortedListingsQuery>;
  calculatedRate?: InputMaybe<Scalars["Boolean"]["input"]>;
  canonicalFinishes?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  canonicalProductIds?: InputMaybe<
    Array<InputMaybe<Scalars["String"]["input"]>>
  >;
  categorySlugs?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  categoryUuids?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  collapsible?: InputMaybe<reverb_search_ListingsSearchRequest_Collapsible>;
  combinedShipping?: InputMaybe<Scalars["Boolean"]["input"]>;
  conditionSlugs?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  conditionUuids?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  contexts?: InputMaybe<Array<InputMaybe<reverb_search_Context>>>;
  countryOfOrigin?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  cspId?: InputMaybe<Scalars["String"]["input"]>;
  cspSlug?: InputMaybe<Scalars["String"]["input"]>;
  curatedSetId?: InputMaybe<Scalars["String"]["input"]>;
  curatedSetSlugs?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  currency?: InputMaybe<Scalars["String"]["input"]>;
  dealsAndSteals?: InputMaybe<Scalars["Boolean"]["input"]>;
  dealsOrWellPriced?: InputMaybe<Scalars["Boolean"]["input"]>;
  decades?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  ecsBoostExperimentGroup?: InputMaybe<Scalars["Int"]["input"]>;
  eligibleForSales?: InputMaybe<Scalars["Boolean"]["input"]>;
  es7?: InputMaybe<Scalars["Boolean"]["input"]>;
  excludeBrandSlugs?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  excludeCarrierCalculated?: InputMaybe<Scalars["Boolean"]["input"]>;
  excludeCategoryUuids?: InputMaybe<
    Array<InputMaybe<Scalars["String"]["input"]>>
  >;
  excludeCuratedSets?: InputMaybe<
    Array<InputMaybe<Scalars["String"]["input"]>>
  >;
  excludeLocalPickupOnly?: InputMaybe<Scalars["Boolean"]["input"]>;
  excludeMerchandisingTypes?: InputMaybe<
    Array<InputMaybe<Scalars["String"]["input"]>>
  >;
  excludePreorders?: InputMaybe<Scalars["Boolean"]["input"]>;
  excludeShopIds?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  excludeStale?: InputMaybe<Scalars["Boolean"]["input"]>;
  experiments?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  fallbackToEverywhereElse?: InputMaybe<Scalars["Boolean"]["input"]>;
  fallbackToOr?: InputMaybe<Scalars["Boolean"]["input"]>;
  filterSuperRegionCode?: InputMaybe<Scalars["String"]["input"]>;
  financingCuratedSetId?: InputMaybe<Scalars["String"]["input"]>;
  flatRate?: InputMaybe<Scalars["Boolean"]["input"]>;
  freeExpeditedShipping?: InputMaybe<Scalars["Boolean"]["input"]>;
  freeShipping?: InputMaybe<Scalars["Boolean"]["input"]>;
  greatValue?: InputMaybe<Scalars["Boolean"]["input"]>;
  greatValueForUsedListings?: InputMaybe<Scalars["Boolean"]["input"]>;
  handmade?: InputMaybe<Scalars["Boolean"]["input"]>;
  hasWatchers?: InputMaybe<Scalars["Boolean"]["input"]>;
  holidaySale?: InputMaybe<Scalars["Boolean"]["input"]>;
  ids?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  inBuyerCarts?: InputMaybe<Scalars["Boolean"]["input"]>;
  includeDrafts?: InputMaybe<Scalars["Boolean"]["input"]>;
  includeQuerySpellCorrections?: InputMaybe<Scalars["Boolean"]["input"]>;
  isUserInCspOptimizationExp?: InputMaybe<Scalars["Boolean"]["input"]>;
  itemCity?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  itemRegion?: InputMaybe<Scalars["String"]["input"]>;
  itemRegionRelation?: InputMaybe<reverb_search_ListingItemRegionRelation>;
  itemState?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  likelihoodToSellExperimentGroup?: InputMaybe<Scalars["Int"]["input"]>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  listingRecommendationsVersion?: InputMaybe<Scalars["Int"]["input"]>;
  localPickup?: InputMaybe<Scalars["Boolean"]["input"]>;
  locale?: InputMaybe<Scalars["String"]["input"]>;
  minSaleDiscountPercent?: InputMaybe<Scalars["String"]["input"]>;
  mpTotalPriceExperimentGroup?: InputMaybe<Scalars["Int"]["input"]>;
  mpid?: InputMaybe<Scalars["String"]["input"]>;
  multiClientExperiments?: InputMaybe<
    Array<InputMaybe<Input_reverb_search_ExperimentData>>
  >;
  noAutoOffers?: InputMaybe<Scalars["Boolean"]["input"]>;
  noBuyerOffers?: InputMaybe<Scalars["Boolean"]["input"]>;
  notGreatValue?: InputMaybe<Scalars["Boolean"]["input"]>;
  notGreatValueForUsedListings?: InputMaybe<Scalars["Boolean"]["input"]>;
  notMatchedToCsp?: InputMaybe<Scalars["Boolean"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  onSale?: InputMaybe<Scalars["Boolean"]["input"]>;
  onlyStale?: InputMaybe<Scalars["Boolean"]["input"]>;
  outlet?: InputMaybe<Scalars["Boolean"]["input"]>;
  preferredSeller?: InputMaybe<Scalars["Boolean"]["input"]>;
  priceMax?: InputMaybe<Scalars["String"]["input"]>;
  priceMin?: InputMaybe<Scalars["String"]["input"]>;
  priceValue?: InputMaybe<reverb_search_ListingsSearchRequest_PriceValue>;
  priceValueV2?: InputMaybe<reverb_search_ListingsSearchRequest_PriceValue>;
  proximity?: InputMaybe<Input_reverb_search_ProximityRequest>;
  query?: InputMaybe<Scalars["String"]["input"]>;
  querylessBumpBoost?: InputMaybe<Scalars["Boolean"]["input"]>;
  randomSeed?: InputMaybe<Scalars["Int"]["input"]>;
  saleCuratedSetId?: InputMaybe<Scalars["String"]["input"]>;
  saleSlugs?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  searchUrlParams?: InputMaybe<Scalars["String"]["input"]>;
  shippingProfileId?: InputMaybe<Scalars["String"]["input"]>;
  shippingRegionCodes?: InputMaybe<
    Array<InputMaybe<Scalars["String"]["input"]>>
  >;
  shipsInternationally?: InputMaybe<Scalars["Boolean"]["input"]>;
  shipsToMe?: InputMaybe<Scalars["Boolean"]["input"]>;
  shopId?: InputMaybe<Scalars["String"]["input"]>;
  shopRegionCode?: InputMaybe<Scalars["String"]["input"]>;
  shopSlug?: InputMaybe<Scalars["String"]["input"]>;
  shopSlugs?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  shopUuids?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  showBestPriceListingsSort?: InputMaybe<Scalars["Boolean"]["input"]>;
  showLikelihoodToSellSort?: InputMaybe<Scalars["Boolean"]["input"]>;
  showMostWatchedListingsSort?: InputMaybe<Scalars["Boolean"]["input"]>;
  showOnlySold?: InputMaybe<Scalars["Boolean"]["input"]>;
  showSold?: InputMaybe<Scalars["Boolean"]["input"]>;
  similarListingIds?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  skipAutodirects?: InputMaybe<Scalars["Boolean"]["input"]>;
  sort?: InputMaybe<reverb_search_ListingsSearchRequest_Sort>;
  sortSlug?: InputMaybe<Scalars["String"]["input"]>;
  statuses?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  terminateEarly?: InputMaybe<Scalars["Boolean"]["input"]>;
  trackTotalHits?: InputMaybe<reverb_search_TrackTotalHits>;
  traitSlugs?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  traitValues?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  useExperimentalBestMatchSignalsV1?: InputMaybe<Scalars["Boolean"]["input"]>;
  useExperimentalQuery?: InputMaybe<Scalars["Boolean"]["input"]>;
  useExperimentalRecall?: InputMaybe<Scalars["Boolean"]["input"]>;
  useExperimentalRegionBoost?: InputMaybe<Scalars["Boolean"]["input"]>;
  useTotalPrice?: InputMaybe<Scalars["Boolean"]["input"]>;
  withAggregations?: InputMaybe<
    Array<InputMaybe<reverb_search_ListingsSearchRequest_Aggregation>>
  >;
  withLimitedAggregations?: InputMaybe<Input_reverb_search_LimitedAggregation>;
  withListingRecommendationStats?: InputMaybe<Scalars["Boolean"]["input"]>;
  withMyShopAggregations?: InputMaybe<
    Array<InputMaybe<reverb_search_ListingsSearchRequest_MyShopAggregation>>
  >;
  /** Deprecated */
  withPageMetadata?: InputMaybe<Input_reverb_search_PageMetaDataRequest>;
  withProximityFilter?: InputMaybe<Input_reverb_search_ProximityFilterRequest>;
  withTopCollections?: InputMaybe<Input_reverb_search_TopCollectionsRequest>;
  yearMax?: InputMaybe<Scalars["String"]["input"]>;
  yearMin?: InputMaybe<Scalars["String"]["input"]>;
  zeroPercentFinancingEligible?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type Input_reverb_search_ListingsSearchResult = {
  fieldKeys?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  fieldValues?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  id?: InputMaybe<Scalars["String"]["input"]>;
};

export type Input_reverb_search_Location = {
  type?: InputMaybe<reverb_search_Location_LocationType>;
  value?: InputMaybe<Scalars["String"]["input"]>;
};

export type Input_reverb_search_MeResponse = {
  admin?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** users.id */
  id?: InputMaybe<Scalars["String"]["input"]>;
  requireOptIn?: InputMaybe<Scalars["Boolean"]["input"]>;
  shopId?: InputMaybe<Scalars["String"]["input"]>;
  shopSlug?: InputMaybe<Scalars["String"]["input"]>;
  shopUuid?: InputMaybe<Scalars["String"]["input"]>;
  status?: InputMaybe<reverb_search_UserStatus>;
  uuid?: InputMaybe<Scalars["String"]["input"]>;
};

export type Input_reverb_search_Money = {
  amount?: InputMaybe<Scalars["String"]["input"]>;
  /** carries same data as `cents`, but uses field naming expected by frontend/core money helpers */
  amountCents?: InputMaybe<Scalars["Int"]["input"]>;
  cents?: InputMaybe<Scalars["Float"]["input"]>;
  currency?: InputMaybe<Scalars["String"]["input"]>;
  display?: InputMaybe<Scalars["String"]["input"]>;
  symbol?: InputMaybe<Scalars["String"]["input"]>;
};

export type Input_reverb_search_MparticleProfileResponse = {
  averageOrderValue?: InputMaybe<Scalars["Float"]["input"]>;
  brandCategories?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  buyerFeedbackRating?: InputMaybe<Scalars["Float"]["input"]>;
  buyerLabel?: InputMaybe<Scalars["String"]["input"]>;
  cumulativeGmv?: InputMaybe<Scalars["Float"]["input"]>;
  firstOrderDate?: InputMaybe<Scalars["String"]["input"]>;
  mostRecentOrderDate?: InputMaybe<Scalars["String"]["input"]>;
  mpid?: InputMaybe<Scalars["String"]["input"]>;
  recentlyViewedCsps?: InputMaybe<
    Array<InputMaybe<Scalars["String"]["input"]>>
  >;
  sellerFeedbackRating?: InputMaybe<Scalars["Float"]["input"]>;
  sellerLabel?: InputMaybe<Scalars["String"]["input"]>;
  topBrandCategories?: InputMaybe<
    Array<InputMaybe<Scalars["String"]["input"]>>
  >;
  topBrands?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  topCategories?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  topCategoryUuids?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  topMostRecentProductTypes?: InputMaybe<
    Array<InputMaybe<Scalars["String"]["input"]>>
  >;
  topProductType?: InputMaybe<Scalars["String"]["input"]>;
  topProductTypeUuids?: InputMaybe<
    Array<InputMaybe<Scalars["String"]["input"]>>
  >;
  topProductTypes?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  totalBuyerOffersAccepted?: InputMaybe<Scalars["Int"]["input"]>;
  totalBuyerOffersCreated?: InputMaybe<Scalars["Int"]["input"]>;
  totalFeedFollows?: InputMaybe<Scalars["Int"]["input"]>;
  totalListingsCreated?: InputMaybe<Scalars["Int"]["input"]>;
  totalListingsDraft?: InputMaybe<Scalars["Int"]["input"]>;
  totalListingsLive?: InputMaybe<Scalars["Int"]["input"]>;
  totalListingsSold?: InputMaybe<Scalars["Int"]["input"]>;
  totalListingsSuspended?: InputMaybe<Scalars["Int"]["input"]>;
  totalPurchases?: InputMaybe<Scalars["Int"]["input"]>;
};

export type Input_reverb_search_NegotiationSearchRequest = {
  buyerUuids?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  fullTextQuery?: InputMaybe<Scalars["String"]["input"]>;
  ids?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  listingIds?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  negotiationTypes?: InputMaybe<
    Array<InputMaybe<reverb_search_NegotiationSearchRequest_Type>>
  >;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  participantUuids?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  sellerUuids?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  sort?: InputMaybe<Scalars["String"]["input"]>;
  states?: InputMaybe<
    Array<InputMaybe<reverb_search_NegotiationSearchRequest_State>>
  >;
  withAggregations?: InputMaybe<
    Array<InputMaybe<reverb_search_NegotiationSearchRequest_Aggregation>>
  >;
};

export type Input_reverb_search_OrderMetrics = {
  averageProductPriceCentsUsd?: InputMaybe<Scalars["Int"]["input"]>;
  orderCount?: InputMaybe<Scalars["Int"]["input"]>;
  topCsps?: InputMaybe<Array<InputMaybe<Input_reverb_search_TopCSP>>>;
  totalAmountProductCentsUsd?: InputMaybe<Scalars["Int"]["input"]>;
  totalAmountProductCentsUsdDouble?: InputMaybe<Scalars["Float"]["input"]>;
  totalBumpSpendAmount?: InputMaybe<Input_reverb_search_Money>;
  totalSellerRevenueAmount?: InputMaybe<Input_reverb_search_Money>;
};

export type Input_reverb_search_OrderSearchRequest = {
  actionableStatusGroup?: InputMaybe<Scalars["String"]["input"]>;
  actionableStatuses?: InputMaybe<
    Array<InputMaybe<Scalars["String"]["input"]>>
  >;
  aggregationsOnly?: InputMaybe<Scalars["Boolean"]["input"]>;
  averageMonthlyProductPricesCurrency?: InputMaybe<Scalars["String"]["input"]>;
  brandSlugs?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  buyerUuid?: InputMaybe<Scalars["String"]["input"]>;
  canonicalProductIds?: InputMaybe<
    Array<InputMaybe<Scalars["String"]["input"]>>
  >;
  categorySlugs?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  categoryUuids?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  checkoutUuid?: InputMaybe<Scalars["String"]["input"]>;
  conditionSlugs?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  createdAfterDate?: InputMaybe<Scalars["String"]["input"]>;
  createdBeforeDate?: InputMaybe<Scalars["String"]["input"]>;
  cspSlugs?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  expressSaleItemEvaluation?: InputMaybe<Scalars["String"]["input"]>;
  fullTextQuery?: InputMaybe<Scalars["String"]["input"]>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  listingCategoryUuids?: InputMaybe<
    Array<InputMaybe<Scalars["String"]["input"]>>
  >;
  listingConditionSlugs?: InputMaybe<
    Array<InputMaybe<Scalars["String"]["input"]>>
  >;
  listingConditionUuids?: InputMaybe<
    Array<InputMaybe<Scalars["String"]["input"]>>
  >;
  listingId?: InputMaybe<Scalars["String"]["input"]>;
  maxAmountProductCentsUsd?: InputMaybe<Scalars["String"]["input"]>;
  minAmountProductCentsUsd?: InputMaybe<Scalars["String"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderType?: InputMaybe<Scalars["String"]["input"]>;
  searchUrlParams?: InputMaybe<Scalars["String"]["input"]>;
  sellerCountries?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  sellerUuid?: InputMaybe<Scalars["String"]["input"]>;
  shippingMethods?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  shopCurrency?: InputMaybe<Scalars["String"]["input"]>;
  soldWithBumpOnly?: InputMaybe<Scalars["Boolean"]["input"]>;
  soldWithoutBumpOnly?: InputMaybe<Scalars["Boolean"]["input"]>;
  withAggregations?: InputMaybe<
    Array<InputMaybe<reverb_search_OrderSearchRequest_Aggregation>>
  >;
  withAverageMonthlyProductPricesAggregations?: InputMaybe<
    Scalars["Boolean"]["input"]
  >;
  withGearCollectionItemOnly?: InputMaybe<Scalars["Boolean"]["input"]>;
  withMetricAggregations?: InputMaybe<Scalars["Boolean"]["input"]>;
  withPriceStatsAggregations?: InputMaybe<Scalars["Boolean"]["input"]>;
  withoutGearCollectionItemOnly?: InputMaybe<Scalars["Boolean"]["input"]>;
};

/** Deprecated */
export type Input_reverb_search_PageMetaDataRequest = {
  excludeBrands?: InputMaybe<Scalars["Boolean"]["input"]>;
  excludeCategories?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type Input_reverb_search_Percentile = {
  count?: InputMaybe<Scalars["Int"]["input"]>;
  key?: InputMaybe<Scalars["String"]["input"]>;
  p1?: InputMaybe<Scalars["Float"]["input"]>;
  p5?: InputMaybe<Scalars["Float"]["input"]>;
  p25?: InputMaybe<Scalars["Float"]["input"]>;
  p50?: InputMaybe<Scalars["Float"]["input"]>;
  p75?: InputMaybe<Scalars["Float"]["input"]>;
  p95?: InputMaybe<Scalars["Float"]["input"]>;
  p99?: InputMaybe<Scalars["Float"]["input"]>;
};

export type Input_reverb_search_PreprocessListingsSearchResponse = {
  preprocessedListingsSearchRequest?: InputMaybe<Input_reverb_search_ListingsSearchRequest>;
};

export type Input_reverb_search_PriceEstimate = {
  priceHigh?: InputMaybe<Input_reverb_search_Money>;
  priceLow?: InputMaybe<Input_reverb_search_Money>;
};

export type Input_reverb_search_PriceGuidesSearchRequest = {
  brandNames?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  categorySlugs?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  categoryUuids?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  decades?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  fullTextQuery?: InputMaybe<Scalars["String"]["input"]>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  locale?: InputMaybe<Scalars["String"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  withAggregations?: InputMaybe<
    Array<InputMaybe<reverb_search_PriceGuidesSearchRequest_Aggregation>>
  >;
  withLimitedAggregations?: InputMaybe<Input_reverb_search_LimitedAggregation>;
};

export type Input_reverb_search_PriceStatsResult = {
  middle?: InputMaybe<Input_reverb_search_Money>;
};

export type Input_reverb_search_PriceStatsResults = {
  docCount?: InputMaybe<Scalars["Int"]["input"]>;
  publicPrice?: InputMaybe<Input_reverb_search_PriceStatsResult>;
  purchasePrice?: InputMaybe<Input_reverb_search_PriceStatsResult>;
};

export type Input_reverb_search_ProductReviewStats = {
  averageRating?: InputMaybe<Scalars["Float"]["input"]>;
  ratingsDistribution?: InputMaybe<
    Array<InputMaybe<Input_reverb_search_ProductReviewStats_RatingDistribution>>
  >;
  total?: InputMaybe<Scalars["Int"]["input"]>;
};

export type Input_reverb_search_ProductReviewStats_RatingDistribution = {
  rating?: InputMaybe<Scalars["String"]["input"]>;
  reviewCount?: InputMaybe<Scalars["Int"]["input"]>;
};

export type Input_reverb_search_ProductReviewsSearchRequest = {
  canonicalProductIds?: InputMaybe<
    Array<InputMaybe<Scalars["String"]["input"]>>
  >;
  comparisonShoppingPageIds?: InputMaybe<
    Array<InputMaybe<Scalars["String"]["input"]>>
  >;
  fullTextQuery?: InputMaybe<Scalars["String"]["input"]>;
  ids?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  ratings?: InputMaybe<
    Array<InputMaybe<reverb_search_ProductReviewsSearchRequest_Rating>>
  >;
  sort?: InputMaybe<reverb_search_ProductReviewsSearchRequest_Sort>;
  verified?: InputMaybe<Scalars["Boolean"]["input"]>;
  withAggregations?: InputMaybe<
    Array<InputMaybe<reverb_search_ProductReviewsSearchRequest_Aggregation>>
  >;
};

export type Input_reverb_search_ProximityFilterRequest = {
  /** / Distance limit in miles. Both whole numbers and decimals are supported. */
  distance?: InputMaybe<Scalars["String"]["input"]>;
  /** / 5 digit US postal code */
  postalCode?: InputMaybe<Scalars["String"]["input"]>;
  /** / Whether the "distance from postal code" filter should be activated. `postal_code` is also used for `Sort.PROXIMITY_ASC`. While not mutually exclusive, we may want to sort but not filter. */
  proximity?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type Input_reverb_search_ProximityRequest = {
  /** / applies proximity filter.  Both distance and location params are required. */
  applyFilter?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** / radius used for filter. */
  distance?: InputMaybe<Input_reverb_search_Distance>;
  /** / used for both proximity filter and proximity sort. */
  location?: InputMaybe<Input_reverb_search_Location>;
};

export type Input_reverb_search_QuerySpellCorrection = {
  correction?: InputMaybe<Scalars["String"]["input"]>;
};

export type Input_reverb_search_SearchResponse = {
  autodirected?: InputMaybe<Scalars["Boolean"]["input"]>;
  averageMonthlyProductPrices?: InputMaybe<
    Array<InputMaybe<Input_reverb_search_AverageMonthlyProductPrice>>
  >;
  bestListings?: InputMaybe<Array<InputMaybe<Input_reverb_search_BestListing>>>;
  bumpIds?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  bumpRecommendations?: InputMaybe<Input_reverb_search_BumpRecommendationsResults>;
  completions?: InputMaybe<Array<InputMaybe<Input_reverb_search_Completion>>>;
  dateBuckets?: InputMaybe<Array<InputMaybe<Input_reverb_search_DateBucket>>>;
  eligibleAutodirects?: InputMaybe<
    Array<InputMaybe<reverb_search_Autodirects>>
  >;
  esScores?: InputMaybe<Array<InputMaybe<Scalars["Float"]["input"]>>>;
  fallbackResultIds?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  filters?: InputMaybe<Array<InputMaybe<Input_reverb_search_Filter>>>;
  gearCollectionStats?: InputMaybe<Input_reverb_search_GearCollectionStats>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  listingRecommendationStats?: InputMaybe<Input_reverb_search_ListingRecommendationStats>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderMetrics?: InputMaybe<Input_reverb_search_OrderMetrics>;
  percentiles?: InputMaybe<Array<InputMaybe<Input_reverb_search_Percentile>>>;
  priceStats?: InputMaybe<Input_reverb_search_PriceStatsResults>;
  productReviewStats?: InputMaybe<Input_reverb_search_ProductReviewStats>;
  querySpellCorrections?: InputMaybe<
    Array<InputMaybe<Input_reverb_search_QuerySpellCorrection>>
  >;
  resultIds?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  resultUuids?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  results?: InputMaybe<Array<InputMaybe<Input_reverb_search_Document>>>;
  suggestedQueries?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  suggestions?: InputMaybe<
    Array<InputMaybe<Input_reverb_search_SearchResponse_Suggestion>>
  >;
  topCollections?: InputMaybe<Input_reverb_search_TopCollectionsResult>;
  total?: InputMaybe<Scalars["Int"]["input"]>;
  totalHits?: InputMaybe<Input_reverb_search_SearchResponse_TotalHits>;
};

export type Input_reverb_search_SearchResponse_Suggestion = {
  frequency?: InputMaybe<Scalars["Int"]["input"]>;
  score?: InputMaybe<Scalars["Float"]["input"]>;
  text?: InputMaybe<Scalars["String"]["input"]>;
};

export type Input_reverb_search_SearchResponse_TotalHits = {
  relation?: InputMaybe<reverb_search_SearchResponse_TotalHits_Relation>;
  value?: InputMaybe<Scalars["Int"]["input"]>;
};

export type Input_reverb_search_ShadowRerankingRequest = {
  listingsSearchRequest?: InputMaybe<Input_reverb_search_ListingsSearchRequest>;
  listingsSearchResults?: InputMaybe<
    Array<InputMaybe<Input_reverb_search_ListingsSearchResult>>
  >;
};

export type Input_reverb_search_ShippingRegionFilterRequest = {
  locale?: InputMaybe<Scalars["String"]["input"]>;
  paramName?: InputMaybe<Scalars["String"]["input"]>;
  selectedValues?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
};

export type Input_reverb_search_ShippingRegionFilterResponse = {
  filter?: InputMaybe<Input_reverb_search_Filter>;
};

export type Input_reverb_search_SimilarListingsSearchRequest = {
  includeForLiveListings?: InputMaybe<Scalars["Boolean"]["input"]>;
  itemRegion?: InputMaybe<Scalars["String"]["input"]>;
  itemRegionRelation?: InputMaybe<reverb_search_ListingItemRegionRelation>;
  likelihoodToSellExperimentGroup?: InputMaybe<Scalars["Int"]["input"]>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  listingId?: InputMaybe<Scalars["String"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  outlet?: InputMaybe<Scalars["Boolean"]["input"]>;
  shippingRegionCodes?: InputMaybe<
    Array<InputMaybe<Scalars["String"]["input"]>>
  >;
  useFallback?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type Input_reverb_search_SuggestOption = {
  completionPayload?: InputMaybe<Input_reverb_search_SuggestOption_CompletionPayload>;
  corePayload?: InputMaybe<Input_reverb_search_SuggestOption_CorePayload>;
  cspPayload?: InputMaybe<Input_reverb_search_SuggestOption_CSPPayload>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  lpPayload?: InputMaybe<Input_reverb_search_SuggestOption_LPPayload>;
  output?: InputMaybe<Scalars["String"]["input"]>;
  scope?: InputMaybe<Input_reverb_search_SuggestOptionScope>;
  scopes?: InputMaybe<
    Array<InputMaybe<Input_reverb_search_SuggestOptionScope>>
  >;
  score?: InputMaybe<Scalars["Int"]["input"]>;
  slug?: InputMaybe<Scalars["String"]["input"]>;
  text?: InputMaybe<Scalars["String"]["input"]>;
};

export type Input_reverb_search_SuggestOptionScope = {
  id?: InputMaybe<Scalars["String"]["input"]>;
  output?: InputMaybe<Scalars["String"]["input"]>;
  slug?: InputMaybe<Scalars["String"]["input"]>;
  type?: InputMaybe<reverb_search_SuggestOptionScope_Type>;
};

export type Input_reverb_search_SuggestOption_CSPPayload = {
  categories?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  primaryImage?: InputMaybe<Scalars["String"]["input"]>;
  title?: InputMaybe<Scalars["String"]["input"]>;
};

export type Input_reverb_search_SuggestOption_CompletionPayload = {
  inventoryNew?: InputMaybe<Input_reverb_search_Inventory>;
  inventoryUsed?: InputMaybe<Input_reverb_search_Inventory>;
  thumbnailUrl?: InputMaybe<Scalars["String"]["input"]>;
};

export type Input_reverb_search_SuggestOption_CoreFilter = {
  count?: InputMaybe<Scalars["Int"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  params?: InputMaybe<Input_reverb_search_SuggestOption_CorePayloadParams>;
  type?: InputMaybe<Scalars["String"]["input"]>;
};

export type Input_reverb_search_SuggestOption_CorePayload = {
  filter?: InputMaybe<Scalars["String"]["input"]>;
  filters?: InputMaybe<
    Array<InputMaybe<Input_reverb_search_SuggestOption_CoreFilter>>
  >;
  params?: InputMaybe<Input_reverb_search_SuggestOption_CorePayloadParams>;
  type?: InputMaybe<Scalars["String"]["input"]>;
  uuid?: InputMaybe<Scalars["String"]["input"]>;
};

export type Input_reverb_search_SuggestOption_CorePayloadParams = {
  brandSlug?: InputMaybe<Scalars["String"]["input"]>;
  category?: InputMaybe<Scalars["String"]["input"]>;
  categoryUuid?: InputMaybe<Scalars["String"]["input"]>;
  curatedSetSlug?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  productType?: InputMaybe<Scalars["String"]["input"]>;
  query?: InputMaybe<Scalars["String"]["input"]>;
  shop?: InputMaybe<Scalars["String"]["input"]>;
};

export type Input_reverb_search_SuggestOption_LPMetadata = {
  artistName?: InputMaybe<Scalars["String"]["input"]>;
  formatDescriptions?: InputMaybe<
    Array<InputMaybe<Scalars["String"]["input"]>>
  >;
  labelName?: InputMaybe<Scalars["String"]["input"]>;
  primaryImageUuid?: InputMaybe<Scalars["String"]["input"]>;
  releaseDate?: InputMaybe<Scalars["String"]["input"]>;
};

export type Input_reverb_search_SuggestOption_LPParams = {
  album?: InputMaybe<Scalars["String"]["input"]>;
  artist?: InputMaybe<Scalars["String"]["input"]>;
  label?: InputMaybe<Scalars["String"]["input"]>;
};

export type Input_reverb_search_SuggestOption_LPPayload = {
  metadata?: InputMaybe<Input_reverb_search_SuggestOption_LPMetadata>;
  params?: InputMaybe<Input_reverb_search_SuggestOption_LPParams>;
  type?: InputMaybe<Scalars["String"]["input"]>;
};

export type Input_reverb_search_TopCSP = {
  count?: InputMaybe<Scalars["Int"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
};

export type Input_reverb_search_TopCollectionsRequest = {
  brandUuids?: InputMaybe<Scalars["Int"]["input"]>;
  categoryUuids?: InputMaybe<Scalars["Int"]["input"]>;
};

export type Input_reverb_search_TopCollectionsResult = {
  brandUuids?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  categoryUuids?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
};

export type Input_reverb_signals_Signal = {
  group?: InputMaybe<reverb_signals_Signal_Group>;
  icon?: InputMaybe<Scalars["String"]["input"]>;
  iconDark?: InputMaybe<Scalars["String"]["input"]>;
  link?: InputMaybe<Input_reverb_signals_SignalLink>;
  name?: InputMaybe<reverb_signals_Signal_Name>;
  slot?: InputMaybe<reverb_signals_Signal_Slot>;
  subtitle?: InputMaybe<Scalars["String"]["input"]>;
  title?: InputMaybe<Scalars["String"]["input"]>;
  tooltipText?: InputMaybe<Scalars["String"]["input"]>;
};

export type Input_reverb_signals_SignalLink = {
  text?: InputMaybe<Scalars["String"]["input"]>;
  url?: InputMaybe<Scalars["String"]["input"]>;
};

export type Input_reverb_signals_SignalRequest = {
  groups?: InputMaybe<Array<InputMaybe<reverb_signals_Signal_Group>>>;
  imageType?: InputMaybe<reverb_signals_SignalImageType>;
};

export type Input_reverb_updates_Category = {
  uuid?: InputMaybe<Scalars["String"]["input"]>;
};

export type Input_reverb_updates_CreateMyUpdateRequest = {
  entity?: InputMaybe<Input_reverb_updates_Entity>;
};

export type Input_reverb_updates_DimissMyUpdateResponse = {
  dismissed?: InputMaybe<Scalars["Boolean"]["input"]>;
  updateId?: InputMaybe<Scalars["String"]["input"]>;
};

export type Input_reverb_updates_DismissMyUpdateRequest = {
  updateId?: InputMaybe<Scalars["String"]["input"]>;
  userId?: InputMaybe<Scalars["String"]["input"]>;
};

export type Input_reverb_updates_DismissUpdatesByEntityRequest = {
  entityIds?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  entityTypes?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  metadata?: InputMaybe<Input_reverb_updates_Metadata>;
  userId?: InputMaybe<Scalars["String"]["input"]>;
};

export type Input_reverb_updates_Entity = {
  /** String containing the identifier of the primary piece of content related to this update (i.e. a listing ID) */
  entityId?: InputMaybe<Scalars["String"]["input"]>;
  /** The type of the entity contained within this update */
  entityType?: InputMaybe<reverb_updates_Entity_EntityType>;
  metadata?: InputMaybe<Input_reverb_updates_Metadata>;
};

export type Input_reverb_updates_Listing = {
  id?: InputMaybe<Scalars["String"]["input"]>;
};

export type Input_reverb_updates_Metadata = {
  cartCount?: InputMaybe<Scalars["Int"]["input"]>;
  categories?: InputMaybe<Array<InputMaybe<Input_reverb_updates_Category>>>;
  listing?: InputMaybe<Input_reverb_updates_Listing>;
  listings?: InputMaybe<Array<InputMaybe<Input_reverb_updates_Listing>>>;
  listingsCount?: InputMaybe<Scalars["Int"]["input"]>;
  offer?: InputMaybe<Input_reverb_updates_Offer>;
  offerCount?: InputMaybe<Scalars["Int"]["input"]>;
  priceDrop?: InputMaybe<Input_reverb_updates_PriceDrop>;
  promotion?: InputMaybe<Input_reverb_updates_Promotion>;
  sale?: InputMaybe<Input_reverb_updates_Sale>;
  shop?: InputMaybe<Input_reverb_updates_Shop>;
  siteBanner?: InputMaybe<Input_reverb_updates_SiteBanner>;
};

export type Input_reverb_updates_MyHomepageNotificationsRequest = {
  includeExperimentalNotifications?: InputMaybe<Scalars["Boolean"]["input"]>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  userId?: InputMaybe<Scalars["String"]["input"]>;
};

export type Input_reverb_updates_MyHomepageNotificationsResponse = {
  updates?: InputMaybe<Array<InputMaybe<Input_reverb_updates_Update>>>;
};

export type Input_reverb_updates_MyUpdateResponse = {
  update?: InputMaybe<Input_reverb_updates_Update>;
};

export type Input_reverb_updates_MyUpdatesRequest = {
  userId?: InputMaybe<Scalars["String"]["input"]>;
};

export type Input_reverb_updates_MyUpdatesResponse = {
  updates?: InputMaybe<Array<InputMaybe<Input_reverb_updates_Update>>>;
};

export type Input_reverb_updates_Offer = {
  id?: InputMaybe<Scalars["String"]["input"]>;
  typeContext?: InputMaybe<Scalars["String"]["input"]>;
};

export type Input_reverb_updates_PriceDrop = {
  id?: InputMaybe<Scalars["String"]["input"]>;
  listingId?: InputMaybe<Scalars["String"]["input"]>;
};

export type Input_reverb_updates_Promotion = {
  id?: InputMaybe<Scalars["String"]["input"]>;
};

export type Input_reverb_updates_PruneMyUpdates = {
  pruneType?: InputMaybe<reverb_updates_PruneMyUpdates_PruneType>;
  updateId?: InputMaybe<Scalars["Int"]["input"]>;
  userId?: InputMaybe<Scalars["Int"]["input"]>;
};

export type Input_reverb_updates_Sale = {
  id?: InputMaybe<Scalars["String"]["input"]>;
};

export type Input_reverb_updates_Shop = {
  id?: InputMaybe<Scalars["String"]["input"]>;
};

export type Input_reverb_updates_SiteBanner = {
  id?: InputMaybe<Scalars["String"]["input"]>;
};

export type Input_reverb_updates_Update = {
  /** Describes what the My Updates consumer should do with this message (i.e. an ADD action tells the consumer to write the update to the database) */
  action?: InputMaybe<reverb_updates_Update_Action>;
  /** The timestamp of this update */
  createdAt?: InputMaybe<Scalars["String"]["input"]>;
  /** The underlying content of this update */
  entity?: InputMaybe<Input_reverb_updates_Entity>;
  updateId?: InputMaybe<Scalars["Int"]["input"]>;
  /** The timestamp of this update */
  updatedAt?: InputMaybe<Scalars["String"]["input"]>;
  /** On write, tells the My Updates consumer which users should receive this update */
  userIds?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
};

export type Input_rql_AddressSuggestion = {
  id?: InputMaybe<Scalars["String"]["input"]>;
  text?: InputMaybe<Scalars["String"]["input"]>;
  type?: InputMaybe<rql_AddressSuggestion_Type>;
};

export type Input_rql_AuthToken = {
  token?: InputMaybe<Scalars["String"]["input"]>;
};

/** Used as input type for mutations that don't have any arguments */
export type Input_rql_Blank = {
  _?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type Input_rql_BrandManager = {
  id?: InputMaybe<Scalars["String"]["input"]>;
  slug?: InputMaybe<Scalars["String"]["input"]>;
};

export type Input_rql_BrandOrderSearch = {
  completedOnly?: InputMaybe<Scalars["Boolean"]["input"]>;
  createdAfterDate?: InputMaybe<Scalars["String"]["input"]>;
  createdBeforeDate?: InputMaybe<Scalars["String"]["input"]>;
  cspSlugs?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  listingCategoryUuids?: InputMaybe<
    Array<InputMaybe<Scalars["String"]["input"]>>
  >;
  listingConditionSlugs?: InputMaybe<
    Array<InputMaybe<Scalars["String"]["input"]>>
  >;
};

export type Input_rql_BrandStatsSearch = {
  condition?: InputMaybe<reverb_reporting_Condition>;
  dateEnd?: InputMaybe<Scalars["String"]["input"]>;
  dateStart?: InputMaybe<Scalars["String"]["input"]>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<reverb_reporting_CSPStatsMetric>;
  orderDesc?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type Input_rql_BumpedListingsQuery = {
  count?: InputMaybe<Scalars["Int"]["input"]>;
  entityType?: InputMaybe<Scalars["String"]["input"]>;
};

export type Input_rql_CPRecommendationsRequest = {
  cpIds?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  listingIds?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  listingsSearchRequest?: InputMaybe<Input_reverb_search_ListingsSearchRequest>;
  multiClientExperiments?: InputMaybe<
    Array<InputMaybe<Input_rql_ExperimentData>>
  >;
};

export type Input_rql_CSPPriceRecommendation = {
  conditionUuids?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  countryCode?: InputMaybe<Scalars["String"]["input"]>;
  currency?: InputMaybe<Scalars["String"]["input"]>;
};

export type Input_rql_CSPProductReviewSearchResponse = {
  stats?: InputMaybe<Input_reverb_search_ProductReviewStats>;
};

export type Input_rql_CheckoutActionRequestInput = {
  reverbApp?: InputMaybe<Scalars["String"]["input"]>;
};

export type Input_rql_CheckoutCartEditRequest = {
  /** Checkout UUID */
  id?: InputMaybe<Scalars["String"]["input"]>;
  order?: InputMaybe<Input_rql_CheckoutCartEditRequest_OrderEntry>;
};

export type Input_rql_CheckoutCartEditRequest_OrderEntry = {
  listingId?: InputMaybe<Scalars["String"]["input"]>;
  quantity?: InputMaybe<Scalars["Int"]["input"]>;
};

export type Input_rql_CheckoutCartEditResponse = {
  /** Checkout UUID */
  id?: InputMaybe<Scalars["String"]["input"]>;
};

/** Input type for bulk fetching objects by ID/UUID/slug */
export type Input_rql_CollectionQuery = {
  ids?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  slugs?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  uuids?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
};

export type Input_rql_CollectionWithDraftsQuery = {
  ids?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  includeDrafts?: InputMaybe<Scalars["Boolean"]["input"]>;
  slugs?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  uuids?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
};

export type Input_rql_DiscoverEntry = {
  ad?: InputMaybe<Input_core_apimessages_Ad>;
  articleEntry?: InputMaybe<Input_rql_DiscoverEntry_ArticleEntry>;
  description?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  localizedContents?: InputMaybe<
    Array<InputMaybe<Input_reverb_feed_FollowLocalizedContents>>
  >;
  type?: InputMaybe<rql_DiscoverEntryType>;
  update?: InputMaybe<Input_rql_Update>;
  video?: InputMaybe<Input_youtube_Video>;
};

export type Input_rql_DiscoverEntry_ArticleEntry = {
  productTypeName?: InputMaybe<Scalars["String"]["input"]>;
};

export type Input_rql_DiscoverRequest = {
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  typesToInclude?: InputMaybe<Array<InputMaybe<rql_DiscoverInclude>>>;
};

export type Input_rql_DiscoverResponse = {
  entries?: InputMaybe<Array<InputMaybe<Input_rql_DiscoverEntry>>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
};

export type Input_rql_EmailSubscription = {
  id?: InputMaybe<Scalars["String"]["input"]>;
  subscribed?: InputMaybe<Scalars["Boolean"]["input"]>;
  translationKey?: InputMaybe<rql_EmailSubscription_TranslationKey>;
};

export type Input_rql_ExperimentData = {
  group?: InputMaybe<Scalars["String"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
};

export type Input_rql_ExpressSalePriceEstimate = {
  conditionUuid?: InputMaybe<Scalars["String"]["input"]>;
};

export type Input_rql_Favorite = {
  favorite?: InputMaybe<Input_core_apimessages_Favorite>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  listingsSearchUrlParams?: InputMaybe<Scalars["String"]["input"]>;
  subtitle?: InputMaybe<Scalars["String"]["input"]>;
  title?: InputMaybe<Scalars["String"]["input"]>;
  type?: InputMaybe<rql_FavoriteType>;
};

export type Input_rql_FavoritesRequest = {
  itemRegion?: InputMaybe<Scalars["String"]["input"]>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  listingState?: InputMaybe<rql_FavoritesRequest_ListingState>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  sortSlug?: InputMaybe<Scalars["String"]["input"]>;
  type?: InputMaybe<rql_FavoriteType>;
};

export type Input_rql_FavoritesResponse = {
  favorites?: InputMaybe<Array<InputMaybe<Input_rql_Favorite>>>;
  filters?: InputMaybe<Array<InputMaybe<Input_reverb_search_Filter>>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  total?: InputMaybe<Scalars["Int"]["input"]>;
};

export type Input_rql_FeedQuery = {
  channel?: InputMaybe<rql_FeedQuery_Channel>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  listingsFilter?: InputMaybe<Input_reverb_search_ListingsSearchRequest>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  readFromDatabase?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type Input_rql_FindAddressSuggestionRequest = {
  container?: InputMaybe<Scalars["String"]["input"]>;
  countryCode?: InputMaybe<Scalars["String"]["input"]>;
  text?: InputMaybe<Scalars["String"]["input"]>;
};

export type Input_rql_FindAddressSuggestionResponse = {
  suggestions?: InputMaybe<Array<InputMaybe<Input_rql_AddressSuggestion>>>;
};

export type Input_rql_FollowsQuery = {
  channel?: InputMaybe<rql_FollowsQuery_Channel>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
};

export type Input_rql_GearCollectionItemsSearchRequest = {
  as?: InputMaybe<rql_GearCollectionItemsSearchRequest_Party>;
  searchInput?: InputMaybe<Input_reverb_search_GearCollectionItemsSearchRequest>;
};

export type Input_rql_HomepageRequest = {
  mpid?: InputMaybe<Scalars["String"]["input"]>;
};

export type Input_rql_ImageQuery = {
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  namedTransform?: InputMaybe<core_apimessages_NamedImageTransforms>;
  scope?: InputMaybe<Scalars["String"]["input"]>;
  transform?: InputMaybe<Scalars["String"]["input"]>;
};

/** Input type for fetching listing price recommendations */
export type Input_rql_ListingPriceRecommendationRequest = {
  countryCode?: InputMaybe<Scalars["String"]["input"]>;
  currency?: InputMaybe<Scalars["String"]["input"]>;
};

export type Input_rql_Me = {
  token?: InputMaybe<Scalars["String"]["input"]>;
};

export type Input_rql_MeProfileRequest = {
  mpid?: InputMaybe<Scalars["String"]["input"]>;
};

/**
 *   *
 * This monitoring data can be attached to each endpoint to help us better
 * document and monitor for changes in performance. This allows clients
 * to be automatically generated fully aware of this contract, which should
 * allow us to more easily set things like per request timeouts, warning logs,
 * or automate monitors to hit specific teams.
 *
 * As of this writing nothing is yet consuming these extensions, but we hope
 * to extend RQL to use these to increase our visibility (and configurability)
 * of each endpoint.
 */
export type Input_rql_MonitoringData = {
  owner?: InputMaybe<Scalars["String"]["input"]>;
  timeout?: InputMaybe<Scalars["Int"]["input"]>;
  warn?: InputMaybe<Scalars["Int"]["input"]>;
};

export type Input_rql_MyCart = {
  _?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type Input_rql_MyUpdatesQuery = {
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
};

export type Input_rql_MyUpdatesResponse = {
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  total?: InputMaybe<Scalars["Int"]["input"]>;
  updates?: InputMaybe<Array<InputMaybe<Input_rql_Update>>>;
};

export type Input_rql_NegotiationQuery = {
  as?: InputMaybe<rql_NegotiationQuery_Party>;
  fullTextQuery?: InputMaybe<Scalars["String"]["input"]>;
  ids?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  listingIds?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  sort?: InputMaybe<Scalars["String"]["input"]>;
  state?: InputMaybe<rql_NegotiationQuery_State>;
};

export type Input_rql_Node = {
  Id?: InputMaybe<Scalars["String"]["input"]>;
};

export type Input_rql_NodeError = {
  message?: InputMaybe<Scalars["String"]["input"]>;
  type?: InputMaybe<rql_NodeError_ErrorType>;
};

/** Input type for fetching objects by ID/UUID/slug */
export type Input_rql_NodeQuery = {
  id?: InputMaybe<Scalars["String"]["input"]>;
  slug?: InputMaybe<Scalars["String"]["input"]>;
  uuid?: InputMaybe<Scalars["String"]["input"]>;
};

export type Input_rql_OrderQuery = {
  actionableStatuses?: InputMaybe<
    Array<InputMaybe<Scalars["String"]["input"]>>
  >;
  checkoutUuid?: InputMaybe<Scalars["String"]["input"]>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
};

export type Input_rql_OrdersSearchRequest = {
  as?: InputMaybe<core_apimessages_BulkOrdersRequest_Party>;
  searchFilters?: InputMaybe<Input_reverb_search_OrderSearchRequest>;
};

export type Input_rql_PageInfo = {
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  total?: InputMaybe<Scalars["Int"]["input"]>;
};

/** Deprecated */
export type Input_rql_PageMetadata = {
  collectionClause?: InputMaybe<Scalars["String"]["input"]>;
  fallbackSubject?: InputMaybe<Scalars["String"]["input"]>;
  hasSubjectClause?: InputMaybe<Scalars["Boolean"]["input"]>;
  inPreposition?: InputMaybe<Scalars["String"]["input"]>;
  mainClause?: InputMaybe<Scalars["String"]["input"]>;
  prefixClause?: InputMaybe<Scalars["String"]["input"]>;
};

export type Input_rql_PriceRecordSearchRequest = {
  actionableStatusGroup?: InputMaybe<Scalars["String"]["input"]>;
  actionableStatuses?: InputMaybe<
    Array<InputMaybe<Scalars["String"]["input"]>>
  >;
  averageMonthlyProductPricesCurrency?: InputMaybe<Scalars["String"]["input"]>;
  canonicalProductIds?: InputMaybe<
    Array<InputMaybe<Scalars["String"]["input"]>>
  >;
  conditionSlugs?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  createdAfterDate?: InputMaybe<Scalars["String"]["input"]>;
  createdBeforeDate?: InputMaybe<Scalars["String"]["input"]>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  listingConditionSlugs?: InputMaybe<
    Array<InputMaybe<Scalars["String"]["input"]>>
  >;
  listingConditionUuids?: InputMaybe<
    Array<InputMaybe<Scalars["String"]["input"]>>
  >;
  listingId?: InputMaybe<Scalars["String"]["input"]>;
  maxAmountProductCentsUsd?: InputMaybe<Scalars["String"]["input"]>;
  minAmountProductCentsUsd?: InputMaybe<Scalars["String"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderType?: InputMaybe<Scalars["String"]["input"]>;
  sellerCountries?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  shopCurrency?: InputMaybe<Scalars["String"]["input"]>;
  withAverageMonthlyProductPricesAggregations?: InputMaybe<
    Scalars["Boolean"]["input"]
  >;
};

export type Input_rql_PublicComponentEntity = {
  id?: InputMaybe<Scalars["String"]["input"]>;
  type?: InputMaybe<Scalars["String"]["input"]>;
};

export type Input_rql_RecommendationsGenericResponse = {
  recommendationEngineInputIds?: InputMaybe<
    Array<InputMaybe<Scalars["String"]["input"]>>
  >;
  recommendationEngineOutputIds?: InputMaybe<
    Array<InputMaybe<Scalars["String"]["input"]>>
  >;
  searchResponse?: InputMaybe<Input_reverb_search_SearchResponse>;
};

export type Input_rql_ReflectCollectionQuery = {
  keys?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
};

export type Input_rql_ReflectQuery = {
  key?: InputMaybe<Scalars["String"]["input"]>;
};

export type Input_rql_SelectAddressSuggestionRequest = {
  id?: InputMaybe<Scalars["String"]["input"]>;
};

export type Input_rql_SelectAddressSuggestionResponse = {
  address?: InputMaybe<Input_core_apimessages_AddressEntry>;
  id?: InputMaybe<Scalars["String"]["input"]>;
};

export type Input_rql_SellerCheckoutInput = {
  id?: InputMaybe<Scalars["String"]["input"]>;
};

export type Input_rql_SetGlobalSubscribeRequest = {
  state?: InputMaybe<rql_SubscribeState>;
};

export type Input_rql_ShopsRecommendationsRequest = {
  limit?: InputMaybe<Scalars["Int"]["input"]>;
};

export type Input_rql_Update = {
  /** The timestamp of this update */
  createdAt?: InputMaybe<Scalars["String"]["input"]>;
  /** The underlying content of this update */
  entity?: InputMaybe<Input_rql_UpdateEntity>;
};

export type Input_rql_UpdateEntity = {
  _?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type Input_youtube_PlaylistItem = {
  snippet?: InputMaybe<Input_youtube_PlaylistSnippet>;
};

export type Input_youtube_PlaylistResourceId = {
  videoId?: InputMaybe<Scalars["String"]["input"]>;
};

export type Input_youtube_PlaylistSnippet = {
  description?: InputMaybe<Scalars["String"]["input"]>;
  resourceId?: InputMaybe<Input_youtube_PlaylistResourceId>;
  title?: InputMaybe<Scalars["String"]["input"]>;
};

export type Input_youtube_Video = {
  description?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  title?: InputMaybe<Scalars["String"]["input"]>;
};

export type Listing = INode & {
  __typename?: "Listing";
  _id?: Maybe<Scalars["ID"]["output"]>;
  _source?: Maybe<Scalars["String"]["output"]>;
  acceptedPaymentMethods?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
  /** JSON-encoded Adyen response to parse client-side */
  adyenCheckoutPaymentMethodsConfig?: Maybe<Scalars["String"]["output"]>;
  affirmFinancingPromotion?: Maybe<core_apimessages_AffirmFinancingPromotion>;
  /** Returns 0% configuration for Affirm. Includes whether listing is eligible and if listing is enabled. User must be an admin or seller on listing. */
  affirmZeroPercentFinancingConfiguration?: Maybe<core_apimessages_ListingAffirmZeroPercentFinancingConfiguration>;
  allShippingPrices?: Maybe<core_apimessages_ShippingPrices>;
  /** Total quantity that a buyer can set from a listing to a cart item. This value will be zero if the listing is not inventoried, or if an inventoried listing is out of stock. */
  allowedBuyerQuantity?: Maybe<Scalars["Int"]["output"]>;
  autoOffers?: Maybe<core_apimessages_AutoOffer>;
  /** Available sales memberships for the listing - includes whether listing is in the sale and whether listing is eligible for the sale. User must be an admin or seller on listing. */
  availableSalesMemberships?: Maybe<core_apimessages_ListingSalesMemberships>;
  brandSlug?: Maybe<Scalars["String"]["output"]>;
  breadcrumbs?: Maybe<Array<Maybe<core_apimessages_ProductBreadcrumb>>>;
  bumpEligible?: Maybe<Scalars["Boolean"]["output"]>;
  bumpInteractionsCounts?: Maybe<arbiter_BumpInteractionCountsResponse>;
  bumpKey?: Maybe<core_apimessages_BumpKey>;
  bumpRate?: Maybe<core_apimessages_BumpRate>;
  bumpRecommendations?: Maybe<reverb_search_BumpRecommendationsResults>;
  bumped?: Maybe<Scalars["Boolean"]["output"]>;
  /** canonical_products.id */
  canonicalProductId?: Maybe<Scalars["String"]["output"]>;
  categories?: Maybe<Array<Maybe<Category>>>;
  categoryRootUuid?: Maybe<Scalars["String"]["output"]>;
  /** includes product_type.uuids */
  categoryUuids?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
  certifiedPreOwned?: Maybe<core_apimessages_ListingCertifiedPreOwned>;
  combinedShippingParent?: Maybe<Scalars["Boolean"]["output"]>;
  condition?: Maybe<core_apimessages_Condition>;
  countryOfOrigin?: Maybe<Scalars["String"]["output"]>;
  counts?: Maybe<core_apimessages_ListingCounts>;
  createdAt?: Maybe<google_protobuf_Timestamp>;
  csp?: Maybe<CSP>;
  cspId?: Maybe<Scalars["String"]["output"]>;
  cspUuid?: Maybe<Scalars["String"]["output"]>;
  curatedSets?: Maybe<Array<Maybe<CuratedSet>>>;
  currency?: Maybe<Scalars["String"]["output"]>;
  /** auctions.uuid */
  currentAuctionId?: Maybe<Scalars["String"]["output"]>;
  description?: Maybe<Scalars["String"]["output"]>;
  digitalDetails?: Maybe<core_apimessages_ListingDigitalDetails>;
  esScore?: Maybe<Scalars["Float"]["output"]>;
  estimatedMonthlyPayment?: Maybe<core_apimessages_Money>;
  estimatedMonthlyPaymentPlan?: Maybe<core_apimessages_EstimatedMonthlyPayment>;
  exclusiveChannel?: Maybe<Scalars["String"]["output"]>;
  experiments?: Maybe<Array<Maybe<core_apimessages_NonUserExperiment>>>;
  expressPay?: Maybe<core_apimessages_ListingExpressPay>;
  finish?: Maybe<Scalars["String"]["output"]>;
  handmade?: Maybe<Scalars["Boolean"]["output"]>;
  hasInventory?: Maybe<Scalars["Boolean"]["output"]>;
  /** products.id */
  id?: Maybe<Scalars["String"]["output"]>;
  images?: Maybe<Array<Maybe<core_apimessages_Image>>>;
  inventory?: Maybe<Scalars["Int"]["output"]>;
  /** True if listing is eligible for auto offers. User must be an admin or seller on listing. */
  isAutoOfferEligible?: Maybe<Scalars["Boolean"]["output"]>;
  /** True if buyer is eligible to make an offer for the listing. */
  isBuyerOfferEligible?: Maybe<Scalars["Boolean"]["output"]>;
  isInCart?: Maybe<Scalars["Boolean"]["output"]>;
  listingType?: Maybe<core_apimessages_Listing_ListingType>;
  localizedContents?: Maybe<
    Array<Maybe<core_apimessages_LocalizedListingContent>>
  >;
  make?: Maybe<Scalars["String"]["output"]>;
  maxBuyerQuantity?: Maybe<Scalars["Int"]["output"]>;
  merchandisingType?: Maybe<Scalars["String"]["output"]>;
  merchandisingUuid?: Maybe<Scalars["String"]["output"]>;
  model?: Maybe<Scalars["String"]["output"]>;
  offerBotRule?: Maybe<core_apimessages_OfferBotRule>;
  offersEnabled?: Maybe<Scalars["Boolean"]["output"]>;
  orderStats?: Maybe<core_apimessages_OrderStats>;
  orders?: Maybe<OrderCollection>;
  otherBuyersWithListingInCartCounts?: Maybe<Scalars["Int"]["output"]>;
  overriddenAttributes?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
  prefersReverbShippingLabel?: Maybe<Scalars["Boolean"]["output"]>;
  preorderInfo?: Maybe<core_apimessages_PreorderInfo>;
  price?: Maybe<core_apimessages_Money>;
  priceGuide?: Maybe<PriceGuide>;
  priceGuideUuid?: Maybe<Scalars["String"]["output"]>;
  priceRecommendation?: Maybe<reverb_pricing_PriceRecommendation>;
  pricing?: Maybe<core_apimessages_ListingPricing>;
  /** product_types.id */
  productTypeId?: Maybe<Scalars["String"]["output"]>;
  propSixtyFiveWarning?: Maybe<Scalars["String"]["output"]>;
  propSixtyFiveWarningImg?: Maybe<core_apimessages_Link>;
  protectionPlanOptions?: Maybe<
    Array<Maybe<core_apimessages_ProtectionPlanOptions>>
  >;
  /** True if listing is valid for publish. If not valid, returns error messages. */
  publishValidation?: Maybe<core_apimessages_ListingValidForPublish>;
  publishedAt?: Maybe<google_protobuf_Timestamp>;
  relistable?: Maybe<Scalars["Boolean"]["output"]>;
  sale?: Maybe<core_apimessages_Sale>;
  /** @deprecated Field no longer supported */
  salePrice?: Maybe<core_apimessages_Money>;
  sales?: Maybe<Array<Maybe<core_apimessages_Sale>>>;
  sameDayShippingIneligible?: Maybe<Scalars["Boolean"]["output"]>;
  scheduledPriceDrops?: Maybe<
    Array<Maybe<core_apimessages_ScheduledPriceDrop>>
  >;
  /** postgres id (polymorphic with 'seed_type') */
  seedId?: Maybe<Scalars["String"]["output"]>;
  seedType?: Maybe<core_apimessages_Listing_SeedType>;
  seller?: Maybe<User>;
  sellerCost?: Maybe<core_apimessages_Money>;
  /** users.id */
  sellerId?: Maybe<Scalars["String"]["output"]>;
  sellerPrice?: Maybe<core_apimessages_Money>;
  sellerReportedMap?: Maybe<core_apimessages_Money>;
  /** users.uuid */
  sellerUuid?: Maybe<Scalars["String"]["output"]>;
  shipmentPackage?: Maybe<ShipmentPackage>;
  shipping?: Maybe<core_apimessages_ShippingPrices>;
  /** @deprecated Field no longer supported */
  shippingPrice?: Maybe<core_apimessages_Money>;
  shippingProfileId?: Maybe<Scalars["String"]["output"]>;
  shop?: Maybe<Shop>;
  /** shops.id */
  shopId?: Maybe<Scalars["String"]["output"]>;
  shopUuid?: Maybe<Scalars["String"]["output"]>;
  signals?: Maybe<Array<Maybe<reverb_signals_Signal>>>;
  similarCSPs?: Maybe<Array<Maybe<CSP>>>;
  similarListings?: Maybe<Array<Maybe<Listing>>>;
  sku?: Maybe<Scalars["String"]["output"]>;
  slug?: Maybe<Scalars["String"]["output"]>;
  soldAsIs?: Maybe<Scalars["Boolean"]["output"]>;
  specs?: Maybe<core_apimessages_ListingSpec>;
  state?: Maybe<Scalars["String"]["output"]>;
  stateDescription?: Maybe<Scalars["String"]["output"]>;
  storageLocation?: Maybe<Scalars["String"]["output"]>;
  subcategoryUuids?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
  taxExempt?: Maybe<Scalars["Boolean"]["output"]>;
  taxIncluded?: Maybe<Scalars["Boolean"]["output"]>;
  taxIncludedHint?: Maybe<Scalars["String"]["output"]>;
  taxPolicies?: Maybe<Array<Maybe<core_apimessages_ShopTaxPolicy>>>;
  title?: Maybe<Scalars["String"]["output"]>;
  traits?: Maybe<Array<Maybe<core_apimessages_Trait>>>;
  upc?: Maybe<Scalars["String"]["output"]>;
  upcDoesNotApply?: Maybe<Scalars["Boolean"]["output"]>;
  usOutlet?: Maybe<Scalars["Boolean"]["output"]>;
  vatIncluded?: Maybe<Scalars["Boolean"]["output"]>;
  vatIncludedHint?: Maybe<Scalars["String"]["output"]>;
  video?: Maybe<core_apimessages_Video>;
  watching?: Maybe<Scalars["Boolean"]["output"]>;
  year?: Maybe<Scalars["String"]["output"]>;
};

export type ListingcertifiedPreOwnedArgs = {
  input?: InputMaybe<Input_core_apimessages_NodeRequest_Lookup_Params_ListingsCertifiedPreOwned>;
};

export type ListingimagesArgs = {
  input: Input_core_apimessages_ImagesRequest;
};

export type ListingordersArgs = {
  input?: InputMaybe<Input_core_apimessages_BulkOrdersRequest_Query>;
};

export type ListingpriceRecommendationArgs = {
  input?: InputMaybe<Input_rql_ListingPriceRecommendationRequest>;
};

export type ListingpricingArgs = {
  input?: InputMaybe<Input_core_apimessages_NodeRequest_Lookup_Params_ListingsPricings>;
};

export type ListingshippingArgs = {
  input?: InputMaybe<Input_core_apimessages_ShippingPricesRequest>;
};

export type ListingshopArgs = {
  input?: InputMaybe<Input_core_apimessages_ShopsRequest>;
};

export type ListingsignalsArgs = {
  input?: InputMaybe<Input_reverb_signals_SignalRequest>;
};

export type ListingsimilarCSPsArgs = {
  input?: InputMaybe<Input_reverb_search_CSPSearchRequest>;
};

export type ListingsimilarListingsArgs = {
  input?: InputMaybe<Input_reverb_search_SimilarListingsSearchRequest>;
};

export type ListingCollection = {
  __typename?: "ListingCollection";
  _ids?: Maybe<Array<Maybe<Scalars["ID"]["output"]>>>;
  _source?: Maybe<Scalars["String"]["output"]>;
  listings?: Maybe<Array<Maybe<Listing>>>;
  nodes?: Maybe<Array<Maybe<Listing>>>;
  pageInfo?: Maybe<rql_PageInfo>;
};

export type MarketplaceSearchInput = {
  legacyInput?: InputMaybe<InputReverbSearchListingssearchrequest>;
  searchRemixerV0Input?: InputMaybe<SearchInput>;
};

export type Mutation = {
  __typename?: "Mutation";
  DeleteAd?: Maybe<core_apimessages_DeleteAdResponse>;
  _source?: Maybe<Scalars["String"]["output"]>;
  _trace: _Trace;
  acceptAddressSuggestion?: Maybe<core_apimessages_AcceptAddressSuggestionResponse>;
  acceptTradeIn?: Maybe<TradeIn>;
  addListingToCuratedSet?: Maybe<core_apimessages_AdminCuratedSetsCurateResponse>;
  addListingToMyAffirmCuratedSet?: Maybe<core_apimessages_MyCuratedSetMembershipResponse>;
  addListingToMyCuratedSet?: Maybe<core_apimessages_MyCuratedSetMembershipResponse>;
  adminBlockProductReview?: Maybe<core_apimessages_AdminBlockProductReviewResponse>;
  applyCheckoutDiscountCode?: Maybe<core_apimessages_ApplyCheckoutDiscountCodeResponse>;
  billingMethodChallengeShopper?: Maybe<core_apimessages_BillingMethodChallengeShopperResponse>;
  billingMethodIdentifyShopper?: Maybe<core_apimessages_BillingMethodIdentifyShopperResponse>;
  billingMethodVerifyShopper?: Maybe<core_apimessages_BillingMethodVerifyShopperResponse>;
  bulkCreateAutoOffers?: Maybe<core_apimessages_SellerBulkActionResponse>;
  bulkCreateAutoOffersAsync?: Maybe<core_apimessages_SellerBulkActionAsyncResponse>;
  bulkCreateBumped?: Maybe<core_apimessages_SellerBulkActionResponse>;
  bulkCreateBumpedAsync?: Maybe<core_apimessages_SellerBulkActionAsyncResponse>;
  bulkCreateSaleMemberships?: Maybe<core_apimessages_SellerBulkActionResponse>;
  bulkCreateZeroPercentAffirm?: Maybe<core_apimessages_SellerBulkActionResponse>;
  bulkCreateZeroPercentAffirmAsync?: Maybe<core_apimessages_SellerBulkActionAsyncResponse>;
  bulkDeleteAutoOffers?: Maybe<core_apimessages_SellerBulkActionResponse>;
  bulkDeleteAutoOffersAsync?: Maybe<core_apimessages_SellerBulkActionAsyncResponse>;
  bulkDeleteBumped?: Maybe<core_apimessages_SellerBulkActionResponse>;
  bulkDeleteBumpedAsync?: Maybe<core_apimessages_SellerBulkActionAsyncResponse>;
  bulkDeleteListings?: Maybe<core_apimessages_BulkDeleteListingsResponse>;
  bulkDeleteListingsAsync?: Maybe<core_apimessages_SellerBulkActionAsyncResponse>;
  bulkDeleteSaleMemberships?: Maybe<core_apimessages_SellerBulkActionResponse>;
  bulkDeleteZeroPercentAffirm?: Maybe<core_apimessages_SellerBulkActionResponse>;
  bulkDeleteZeroPercentAffirmAsync?: Maybe<core_apimessages_SellerBulkActionAsyncResponse>;
  bulkExportListings?: Maybe<core_apimessages_BulkExportListingsResponse>;
  bulkPublishListings?: Maybe<core_apimessages_BulkPublishListingsResponse>;
  bulkPublishListingsAsync?: Maybe<core_apimessages_SellerBulkActionAsyncResponse>;
  bulkTrackImpressions?: Maybe<arbiter_BulkTrackImpressionsResponse>;
  cancelOrder?: Maybe<core_apimessages_CancelOrderResponse>;
  cancelPlaidAccount?: Maybe<core_apimessages_CancelPlaidAccountResponse>;
  checkoutChallengeShopper?: Maybe<core_apimessages_CheckoutChallengeShopperResponse>;
  checkoutIdentifyShopper?: Maybe<core_apimessages_CheckoutIdentifyShopperResponse>;
  checkoutVerifyShopper?: Maybe<core_apimessages_CheckoutVerifyShopperResponse>;
  clearBrowsingHistory?: Maybe<core_apimessages_DeleteResponse>;
  completeExpressSaleCheckout?: Maybe<core_apimessages_CompleteExpressSaleCheckoutResponse>;
  copyAdGroup?: Maybe<core_apimessages_CopyAdGroupResponse>;
  createAdminNote?: Maybe<core_apimessages_AdminNote>;
  createAdminTax1099kReportingThreshold?: Maybe<core_apimessages_AdminTax1099kReportingThresholdCreateResponse>;
  createAdyenOnboardingLink?: Maybe<core_apimessages_AdyenOnboardingLinkResponse>;
  createAutoOffer?: Maybe<core_apimessages_CreateAutoOfferResponse>;
  createCheckoutPaymentRedirectUrl?: Maybe<core_apimessages_CheckoutCreatePaymentRedirectUrlResponse>;
  createCheckoutPaypalOrder?: Maybe<core_apimessages_CreateCheckoutPaypalOrderResponse>;
  createExpressSaleCheckout?: Maybe<core_apimessages_CreateExpressSaleCheckoutResponse>;
  createMyAction?: Maybe<core_apimessages_CreateMyActionResponse>;
  createMyCreditCard?: Maybe<core_apimessages_CreateMyCreditCardResponse>;
  createMyFeedbackMessage?: Maybe<core_apimessages_CreateMyFeedbackMessageResponse>;
  createMyPlaidLinkToken?: Maybe<core_apimessages_CreateMyPlaidLinkTokenResponse>;
  createMySaleMembership?: Maybe<core_apimessages_CreateMySaleMembershipResponse>;
  createNegotiation?: Maybe<core_apimessages_NegotiationsResponse>;
  createPacklinkDraft?: Maybe<core_apimessages_CreatePacklinkDraftResponse>;
  createRecentSearch?: Maybe<core_apimessages_CreateRecentSearchResponse>;
  createShipment?: Maybe<Shipment>;
  createTaxIdentification?: Maybe<core_apimessages_CreateTaxIdentificationResponse>;
  createTradeIn?: Maybe<TradeIn>;
  createTruliooOnboardingRecord?: Maybe<core_apimessages_CreateTruliooOnboardingRecordResponse>;
  createUserShopPermission?: Maybe<core_apimessages_CreateOrUpdateUserShopPermissionResponse>;
  createWatch?: Maybe<core_apimessages_UpdateWatchResponse>;
  crupdateCartItemProtectionPlan?: Maybe<core_apimessages_UpdateCartItemProtectionPlanResponse>;
  crupdateGearCollectionItem?: Maybe<GearCollectionItem>;
  crupdateListing?: Maybe<Listing>;
  crupdateMyShopCampaign?: Maybe<core_apimessages_MyShopCampaign>;
  deactivateTaxIdentification?: Maybe<core_apimessages_DeactivateTaxIdentificationResponse>;
  declineFeedbackRequest?: Maybe<core_apimessages_DeclineFeedbackResponse>;
  deleteAdminTax1099kReportingThreshold?: Maybe<core_apimessages_AdminTax1099kReportingThresholdDeleteResponse>;
  deleteAutoOffer?: Maybe<core_apimessages_DeleteAutoOfferResponse>;
  deleteCartItemProtectionPlan?: Maybe<core_apimessages_DeleteCartItemProtectionPlanResponse>;
  deleteDraftListing?: Maybe<core_apimessages_DraftListingDeleteResponse>;
  deleteGearCollectionItem?: Maybe<GearCollectionItem>;
  deleteMyAddress?: Maybe<core_apimessages_DeleteResponse>;
  deleteMyCreditCard?: Maybe<core_apimessages_DeleteMyCreditCardResponse>;
  deleteMyFavorite?: Maybe<core_apimessages_DeleteMyFavoriteResponse>;
  deleteMyFeedbackMessage?: Maybe<core_apimessages_DeleteMyFeedbackMessageResponse>;
  deleteMySaleMembership?: Maybe<core_apimessages_DeleteMySaleMembershipResponse>;
  deleteTaxProfile?: Maybe<core_apimessages_TaxProfile>;
  deleteUserShopPermission?: Maybe<core_apimessages_DeleteUserShopPermissionResponse>;
  deleteWatch?: Maybe<core_apimessages_UpdateWatchResponse>;
  delinkUserService?: Maybe<core_apimessages_DelinkUserServiceResponse>;
  dismissUserUpdate?: Maybe<reverb_updates_DimissMyUpdateResponse>;
  editCart?: Maybe<core_apimessages_CheckoutCartEditResponse>;
  editCheckoutCart?: Maybe<rql_CheckoutCartEditResponse>;
  exchangeCurrency?: Maybe<reverb_config_cache_ExchangeCurrencyResponse>;
  exportSecondaryUserActivities?: Maybe<core_apimessages_ExportSecondaryUserActivitiesResponse>;
  featureListingInCuratedSet?: Maybe<core_apimessages_AdminCuratedSetsCurateResponse>;
  finalizeAffirmCheckoutPayment?: Maybe<core_apimessages_FinalizeAffirmCheckoutPaymentResponse>;
  finalizeCardCheckoutPayment?: Maybe<core_apimessages_FinalizeCardCheckoutPaymentResponse>;
  finalizeDigitalWalletPayment?: Maybe<core_apimessages_CheckoutFinalizeDigitalWalletPaymentResponse>;
  finalizePaypalCheckoutPayment?: Maybe<core_apimessages_FinalizePaypalCheckoutPaymentResponse>;
  finalizeRedirectAuthCheckoutPayment?: Maybe<core_apimessages_FinalizeRedirectAuthCheckoutPaymentResponse>;
  finalizeRedirectPaymentCheckout?: Maybe<core_apimessages_CheckoutFinalizeRedirectPaymentCheckoutResponse>;
  flagMessage?: Maybe<core_apimessages_ConversationFlagMessageResponse>;
  flagProductReview?: Maybe<core_apimessages_FlagProductReviewResponse>;
  generateAdminReport?: Maybe<core_apimessages_GenerateAdminReportResponse>;
  generateDraft?: Maybe<core_apimessages_SeedListingResponse>;
  guestCheckoutVerifyShopper?: Maybe<core_apimessages_CheckoutVerifyShopperResponse>;
  listingCorrection?: Maybe<core_apimessages_ListingCorrectionResponse>;
  loginMyPacklinkAccount?: Maybe<core_apimessages_LoginMyPacklinkAccountResponse>;
  markOrderAsReceived?: Maybe<core_apimessages_MarkOrderAsReceivedResponse>;
  moveCartItemToWatchList?: Maybe<core_apimessages_CartMoveToWatchListResponse>;
  myShopExportDac7OrderData?: Maybe<core_apimessages_MyShopExportDac7OrderDataResponse>;
  payMyStatement?: Maybe<core_apimessages_PayMyStatementResponse>;
  processApprovedPaypalOrder?: Maybe<core_apimessages_ProcessApprovedPaypalOrderResponse>;
  promotionalCodeRedeem?: Maybe<core_apimessages_RedeemPromotionalCodeResponse>;
  publishAllListings?: Maybe<core_apimessages_PublishAllListingsResponse>;
  purchaseShippingRate?: Maybe<core_apimessages_PurchaseShippingRateResponse>;
  registerMyPacklinkAccount?: Maybe<core_apimessages_RegisterMyPacklinkAccountResponse>;
  rejectTradeIn?: Maybe<TradeIn>;
  removeFeedEntry?: Maybe<reverb_feed_RemoveEntryResponse>;
  removeListingFromCuratedSet?: Maybe<core_apimessages_AdminCuratedSetsCurateResponse>;
  removeListingFromMyAffirmCuratedSet?: Maybe<core_apimessages_MyCuratedSetMembershipResponse>;
  removeListingFromMyCuratedSet?: Maybe<core_apimessages_MyCuratedSetMembershipResponse>;
  requestAccountDeletion?: Maybe<core_apimessages_RequestAccountDeletionResponse>;
  requestTaxFormDownload?: Maybe<core_apimessages_RequestTaxFormDownloadResponse>;
  restartTruliooOnboardingWorkflow?: Maybe<core_apimessages_TruliooOnboardingResponse>;
  reverifyCreditCard?: Maybe<core_apimessages_ReverifyCreditCardResponse>;
  saveMyPlaidAccount?: Maybe<core_apimessages_SaveMyPlaidAccountResponse>;
  selectAddressAutocompleteSuggestion?: Maybe<core_apimessages_SelectAddressAutocompleteSuggestionResponse>;
  selectCheckoutCreditCard?: Maybe<core_apimessages_SelectCheckoutCreditCardResponse>;
  selectCheckoutOrderShippingMethod?: Maybe<core_apimessages_CheckoutSelectOrderShippingMethodResponse>;
  selectCheckoutPaymentMethod?: Maybe<core_apimessages_SelectCheckoutPaymentMethodResponse>;
  selectCheckoutShippingAddress?: Maybe<core_apimessages_CheckoutSelectShippingAddressResponse>;
  sendDigitalListingDownloadEmail?: Maybe<core_apimessages_DigitalListingSendDownloadEmailResponse>;
  setAccertifyTokens?: Maybe<core_apimessages_SetAccertifyTokensResponse>;
  setEmailGlobalSubscribe?: Maybe<rql_Me>;
  setEmailSubscription?: Maybe<rql_EmailSubscription>;
  setupShopPaymentMethods?: Maybe<core_apimessages_SetupPaymentMethodsResponse>;
  shippingProtection?: Maybe<core_apimessages_ShippingProtectionResponse>;
  startCheckout?: Maybe<core_apimessages_StartCheckoutResponse>;
  startCheckoutForListing?: Maybe<core_apimessages_StartCheckoutForListingResponse>;
  startPaypalCheckout?: Maybe<core_apimessages_StartPaypalCheckoutResponse>;
  taxFormDownload?: Maybe<core_apimessages_TaxFormDownloadResponse>;
  trackBumpInteraction?: Maybe<core_apimessages_TrackBumpInteractionResponse>;
  triggerOptInEmail?: Maybe<braze_TriggerCampaignSendResponse>;
  truliooOnboardingRecordResendPscEmail?: Maybe<core_apimessages_TruliooOnboardingRecordResendPscEmailResponse>;
  undoDeleteMyFavorite?: Maybe<core_apimessages_UndoDeleteMyFavoriteResponse>;
  unfeatureListingInCuratedSet?: Maybe<core_apimessages_AdminCuratedSetsCurateResponse>;
  updateAd?: Maybe<core_apimessages_UpdateAdResponse>;
  updateAdCampaign?: Maybe<core_apimessages_UpdateAdCampaignResponse>;
  updateAdGroup?: Maybe<core_apimessages_UpdateAdGroupResponse>;
  updateAdGroupKeywords?: Maybe<core_apimessages_UpdateAdGroupKeywordsResponse>;
  updateAdZone?: Maybe<core_apimessages_UpdateAdZoneResponse>;
  updateAdminNote?: Maybe<core_apimessages_AdminNote>;
  updateAdminTax1099kReportingThreshold?: Maybe<core_apimessages_AdminTax1099kReportingThresholdUpdateResponse>;
  updateAdvertiser?: Maybe<core_apimessages_UpdateAdvertiserResponse>;
  updateCheckoutBillingAddress?: Maybe<core_apimessages_UpdateCheckoutBillingAddressResponse>;
  updateCheckoutPaypalOrder?: Maybe<core_apimessages_UpdateCheckoutPaypalOrderResponse>;
  updateCheckoutShippingAddress?: Maybe<core_apimessages_CheckoutUpdateShippingAddressResponse>;
  updateCheckoutShippingEstimate?: Maybe<core_apimessages_CheckoutUpdateShippingEstimateResponse>;
  updateCrossPlatformListingSession?: Maybe<core_apimessages_UpdateCrossPlatformListingSessionResponse>;
  updateFeedbackRequest?: Maybe<core_apimessages_UpdateFeedbackResponse>;
  updateGearCollectionItemState?: Maybe<GearCollectionItem>;
  updateInternationalTaxProfile?: Maybe<core_apimessages_UpdateInternationalTaxProfileResponse>;
  updateListingState?: Maybe<core_apimessages_UpdateListingStateResponse>;
  updateMyAddress?: Maybe<core_apimessages_UpdateAddressResponse>;
  updateMyCreditCard?: Maybe<core_apimessages_UpdateMyCreditCardResponse>;
  updateMyGearCollectionSettings?: Maybe<core_apimessages_UpdateMyGearCollectionSettingsResponse>;
  updateMyShop?: Maybe<core_apimessages_UpdateMyShopResponse>;
  updateMyShopBillingMethod?: Maybe<core_apimessages_UpdateBillingMethodResponse>;
  updateMyShopShippingProfiles?: Maybe<core_apimessages_UpdateMyShopShippingProfilesResponse>;
  updateNegotiation?: Maybe<core_apimessages_UpdateNegotiationResponse>;
  updateProductReview?: Maybe<core_apimessages_UpdateProductReviewResponse>;
  updateReturnPolicy?: Maybe<core_apimessages_UpdateReturnPolicyResponse>;
  updateTaxIdentification?: Maybe<core_apimessages_UpdateTaxIdentificationResponse>;
  updateUniversalPromoCampaign?: Maybe<core_apimessages_UpdateUniversalPromoCampaignResponse>;
  updateUserShopPermission?: Maybe<core_apimessages_CreateOrUpdateUserShopPermissionResponse>;
  upsertMyFavorite?: Maybe<core_apimessages_UpsertMyFavoriteResponse>;
  upsertTaxProfile?: Maybe<core_apimessages_TaxProfile>;
  voteProductReview?: Maybe<core_apimessages_ProductReviewVoteResponse>;
};

export type MutationDeleteAdArgs = {
  input?: InputMaybe<Input_core_apimessages_DeleteAdRequest>;
};

export type MutationacceptAddressSuggestionArgs = {
  input?: InputMaybe<Input_core_apimessages_AcceptAddressSuggestionRequest>;
};

export type MutationacceptTradeInArgs = {
  input: AcceptTradeInInput;
};

export type MutationaddListingToCuratedSetArgs = {
  input?: InputMaybe<Input_core_apimessages_AdminCuratedSetsCurateRequest>;
};

export type MutationaddListingToMyAffirmCuratedSetArgs = {
  input?: InputMaybe<Input_core_apimessages_MyAffirmCuratedSetMembershipRequest>;
};

export type MutationaddListingToMyCuratedSetArgs = {
  input?: InputMaybe<Input_core_apimessages_MyCuratedSetMembershipRequest>;
};

export type MutationadminBlockProductReviewArgs = {
  input?: InputMaybe<Input_core_apimessages_AdminBlockProductReviewRequest>;
};

export type MutationapplyCheckoutDiscountCodeArgs = {
  input?: InputMaybe<Input_core_apimessages_ApplyCheckoutDiscountCodeRequest>;
};

export type MutationbillingMethodChallengeShopperArgs = {
  input?: InputMaybe<Input_core_apimessages_BillingMethodChallengeShopperRequest>;
};

export type MutationbillingMethodIdentifyShopperArgs = {
  input?: InputMaybe<Input_core_apimessages_BillingMethodIdentifyShopperRequest>;
};

export type MutationbillingMethodVerifyShopperArgs = {
  input?: InputMaybe<Input_core_apimessages_BillingMethodVerifyShopperRequest>;
};

export type MutationbulkCreateAutoOffersArgs = {
  input?: InputMaybe<Input_core_apimessages_BulkCreateAutoOffersRequest>;
};

export type MutationbulkCreateAutoOffersAsyncArgs = {
  input?: InputMaybe<Input_core_apimessages_BulkCreateAutoOffersAsyncRequest>;
};

export type MutationbulkCreateBumpedArgs = {
  input?: InputMaybe<Input_core_apimessages_BulkCreateBumpedRequest>;
};

export type MutationbulkCreateBumpedAsyncArgs = {
  input?: InputMaybe<Input_core_apimessages_BulkCreateBumpedAsyncRequest>;
};

export type MutationbulkCreateSaleMembershipsArgs = {
  input?: InputMaybe<Input_core_apimessages_BulkSaleMembershipsRequest>;
};

export type MutationbulkCreateZeroPercentAffirmArgs = {
  input?: InputMaybe<Input_core_apimessages_BulkCreateZeroPercentAffirmRequest>;
};

export type MutationbulkCreateZeroPercentAffirmAsyncArgs = {
  input?: InputMaybe<Input_core_apimessages_BulkCreateZeroPercentAffirmAsyncRequest>;
};

export type MutationbulkDeleteAutoOffersArgs = {
  input?: InputMaybe<Input_core_apimessages_BulkDeleteAutoOffersRequest>;
};

export type MutationbulkDeleteAutoOffersAsyncArgs = {
  input?: InputMaybe<Input_core_apimessages_BulkDeleteAutoOffersAsyncRequest>;
};

export type MutationbulkDeleteBumpedArgs = {
  input?: InputMaybe<Input_core_apimessages_BulkDeleteBumpedRequest>;
};

export type MutationbulkDeleteBumpedAsyncArgs = {
  input?: InputMaybe<Input_core_apimessages_BulkDeleteBumpedAsyncRequest>;
};

export type MutationbulkDeleteListingsArgs = {
  input?: InputMaybe<Input_core_apimessages_BulkDeleteListingsRequest>;
};

export type MutationbulkDeleteListingsAsyncArgs = {
  input?: InputMaybe<Input_core_apimessages_BulkDeleteListingsAsyncRequest>;
};

export type MutationbulkDeleteSaleMembershipsArgs = {
  input?: InputMaybe<Input_core_apimessages_BulkSaleMembershipsRequest>;
};

export type MutationbulkDeleteZeroPercentAffirmArgs = {
  input?: InputMaybe<Input_core_apimessages_BulkDeleteZeroPercentAffirmRequest>;
};

export type MutationbulkDeleteZeroPercentAffirmAsyncArgs = {
  input?: InputMaybe<Input_core_apimessages_BulkDeleteZeroPercentAffirmAsyncRequest>;
};

export type MutationbulkExportListingsArgs = {
  input?: InputMaybe<Input_core_apimessages_BulkExportListingsRequest>;
};

export type MutationbulkPublishListingsArgs = {
  input?: InputMaybe<Input_core_apimessages_BulkPublishListingsRequest>;
};

export type MutationbulkPublishListingsAsyncArgs = {
  input?: InputMaybe<Input_core_apimessages_BulkPublishListingsAsyncRequest>;
};

export type MutationbulkTrackImpressionsArgs = {
  input?: InputMaybe<Input_arbiter_BulkTrackImpressionsRequest>;
};

export type MutationcancelOrderArgs = {
  input?: InputMaybe<Input_core_apimessages_CancelOrderRequest>;
};

export type MutationcancelPlaidAccountArgs = {
  input?: InputMaybe<Input_core_apimessages_CancelPlaidAccountRequest>;
};

export type MutationcheckoutChallengeShopperArgs = {
  input?: InputMaybe<Input_core_apimessages_CheckoutChallengeShopperRequest>;
};

export type MutationcheckoutIdentifyShopperArgs = {
  input?: InputMaybe<Input_core_apimessages_CheckoutIdentifyShopperRequest>;
};

export type MutationcheckoutVerifyShopperArgs = {
  input?: InputMaybe<Input_core_apimessages_CheckoutVerifyShopperRequest>;
};

export type MutationcompleteExpressSaleCheckoutArgs = {
  input?: InputMaybe<Input_core_apimessages_CompleteExpressSaleCheckoutRequest>;
};

export type MutationcopyAdGroupArgs = {
  input?: InputMaybe<Input_core_apimessages_CopyAdGroupRequest>;
};

export type MutationcreateAdminNoteArgs = {
  input?: InputMaybe<Input_core_apimessages_AdminNoteCreateRequest>;
};

export type MutationcreateAdminTax1099kReportingThresholdArgs = {
  input?: InputMaybe<Input_core_apimessages_AdminTax1099kReportingThresholdCreateRequest>;
};

export type MutationcreateAdyenOnboardingLinkArgs = {
  input?: InputMaybe<Input_core_apimessages_AdyenOnboardingLinkRequest>;
};

export type MutationcreateAutoOfferArgs = {
  input?: InputMaybe<Input_core_apimessages_CreateAutoOfferRequest>;
};

export type MutationcreateCheckoutPaymentRedirectUrlArgs = {
  input?: InputMaybe<Input_core_apimessages_CheckoutCreatePaymentRedirectUrlRequest>;
};

export type MutationcreateCheckoutPaypalOrderArgs = {
  input?: InputMaybe<Input_core_apimessages_CreateCheckoutPaypalOrderRequest>;
};

export type MutationcreateExpressSaleCheckoutArgs = {
  input?: InputMaybe<Input_core_apimessages_CreateExpressSaleCheckoutRequest>;
};

export type MutationcreateMyActionArgs = {
  input?: InputMaybe<Input_core_apimessages_CreateMyActionRequest>;
};

export type MutationcreateMyCreditCardArgs = {
  input?: InputMaybe<Input_core_apimessages_CreateMyCreditCardRequest>;
};

export type MutationcreateMyFeedbackMessageArgs = {
  input?: InputMaybe<Input_core_apimessages_CreateMyFeedbackMessageRequest>;
};

export type MutationcreateMyPlaidLinkTokenArgs = {
  input?: InputMaybe<Input_core_apimessages_CreateMyPlaidLinkTokenRequest>;
};

export type MutationcreateMySaleMembershipArgs = {
  input?: InputMaybe<Input_core_apimessages_CreateMySaleMembershipRequest>;
};

export type MutationcreateNegotiationArgs = {
  input?: InputMaybe<Input_core_apimessages_CreateNegotiationRequest>;
};

export type MutationcreatePacklinkDraftArgs = {
  input?: InputMaybe<Input_core_apimessages_CreatePacklinkDraftRequest>;
};

export type MutationcreateRecentSearchArgs = {
  input?: InputMaybe<Input_core_apimessages_CreateRecentSearchRequest>;
};

export type MutationcreateShipmentArgs = {
  input?: InputMaybe<Input_core_apimessages_CreateShipmentRequest>;
};

export type MutationcreateTaxIdentificationArgs = {
  input?: InputMaybe<Input_core_apimessages_CreateTaxIdentificationRequest>;
};

export type MutationcreateTradeInArgs = {
  input: CreateTradeInInput;
};

export type MutationcreateUserShopPermissionArgs = {
  input?: InputMaybe<Input_core_apimessages_CreateUserShopPermissionRequest>;
};

export type MutationcreateWatchArgs = {
  input?: InputMaybe<Input_core_apimessages_WatchesCreateRequest>;
};

export type MutationcrupdateCartItemProtectionPlanArgs = {
  input?: InputMaybe<Input_core_apimessages_UpdateCartItemProtectionPlanRequest>;
};

export type MutationcrupdateGearCollectionItemArgs = {
  input?: InputMaybe<Input_core_apimessages_UpdateGearCollectionItemRequest>;
};

export type MutationcrupdateListingArgs = {
  input?: InputMaybe<Input_core_apimessages_UpdateListingRequest>;
};

export type MutationcrupdateMyShopCampaignArgs = {
  input?: InputMaybe<Input_core_apimessages_UpdateMyShopCampaignRequest>;
};

export type MutationdeactivateTaxIdentificationArgs = {
  input?: InputMaybe<Input_core_apimessages_DeactivateTaxIdentificationRequest>;
};

export type MutationdeclineFeedbackRequestArgs = {
  input?: InputMaybe<Input_core_apimessages_DeclineFeedbackRequest>;
};

export type MutationdeleteAdminTax1099kReportingThresholdArgs = {
  input?: InputMaybe<Input_core_apimessages_AdminTax1099kReportingThresholdDeleteRequest>;
};

export type MutationdeleteAutoOfferArgs = {
  input?: InputMaybe<Input_core_apimessages_DeleteAutoOfferRequest>;
};

export type MutationdeleteCartItemProtectionPlanArgs = {
  input?: InputMaybe<Input_core_apimessages_DeleteCartItemProtectionPlanRequest>;
};

export type MutationdeleteDraftListingArgs = {
  input?: InputMaybe<Input_core_apimessages_DraftListingDeleteRequest>;
};

export type MutationdeleteGearCollectionItemArgs = {
  input?: InputMaybe<Input_core_apimessages_DeleteGearCollectionItemRequest>;
};

export type MutationdeleteMyAddressArgs = {
  input?: InputMaybe<Input_core_apimessages_AddressDeleteRequest>;
};

export type MutationdeleteMyCreditCardArgs = {
  input?: InputMaybe<Input_core_apimessages_DeleteMyCreditCardRequest>;
};

export type MutationdeleteMyFavoriteArgs = {
  input?: InputMaybe<Input_core_apimessages_DeleteMyFavoriteRequest>;
};

export type MutationdeleteMyFeedbackMessageArgs = {
  input?: InputMaybe<Input_core_apimessages_DeleteMyFeedbackMessageRequest>;
};

export type MutationdeleteMySaleMembershipArgs = {
  input?: InputMaybe<Input_core_apimessages_DeleteMySaleMembershipRequest>;
};

export type MutationdeleteUserShopPermissionArgs = {
  input?: InputMaybe<Input_core_apimessages_DeleteUserShopPermissionRequest>;
};

export type MutationdeleteWatchArgs = {
  input?: InputMaybe<Input_core_apimessages_WatchesDeleteRequest>;
};

export type MutationdelinkUserServiceArgs = {
  input?: InputMaybe<Input_core_apimessages_DelinkUserServiceRequest>;
};

export type MutationdismissUserUpdateArgs = {
  input?: InputMaybe<Input_reverb_updates_DismissMyUpdateRequest>;
};

export type MutationeditCartArgs = {
  input?: InputMaybe<Input_core_apimessages_CheckoutCartEditRequest>;
};

export type MutationeditCheckoutCartArgs = {
  input?: InputMaybe<Input_rql_CheckoutCartEditRequest>;
};

export type MutationexchangeCurrencyArgs = {
  input?: InputMaybe<Input_reverb_config_cache_ExchangeCurrencyRequest>;
};

export type MutationexportSecondaryUserActivitiesArgs = {
  input?: InputMaybe<Input_core_apimessages_ExportSecondaryUserActivitiesRequest>;
};

export type MutationfeatureListingInCuratedSetArgs = {
  input?: InputMaybe<Input_core_apimessages_AdminCuratedSetsCurateRequest>;
};

export type MutationfinalizeAffirmCheckoutPaymentArgs = {
  input?: InputMaybe<Input_core_apimessages_FinalizeAffirmCheckoutPaymentRequest>;
};

export type MutationfinalizeCardCheckoutPaymentArgs = {
  input?: InputMaybe<Input_core_apimessages_FinalizeCardCheckoutPaymentRequest>;
};

export type MutationfinalizeDigitalWalletPaymentArgs = {
  input?: InputMaybe<Input_core_apimessages_CheckoutFinalizeDigitalWalletPaymentRequest>;
};

export type MutationfinalizePaypalCheckoutPaymentArgs = {
  input?: InputMaybe<Input_core_apimessages_FinalizePaypalCheckoutPaymentRequest>;
};

export type MutationfinalizeRedirectAuthCheckoutPaymentArgs = {
  input?: InputMaybe<Input_core_apimessages_FinalizeRedirectAuthCheckoutPaymentRequest>;
};

export type MutationfinalizeRedirectPaymentCheckoutArgs = {
  input?: InputMaybe<Input_core_apimessages_CheckoutFinalizeRedirectPaymentCheckoutRequest>;
};

export type MutationflagMessageArgs = {
  input?: InputMaybe<Input_core_apimessages_ConversationFlagMessageRequest>;
};

export type MutationflagProductReviewArgs = {
  input?: InputMaybe<Input_core_apimessages_FlagProductReviewRequest>;
};

export type MutationgenerateAdminReportArgs = {
  input?: InputMaybe<Input_core_apimessages_GenerateAdminReportRequest>;
};

export type MutationgenerateDraftArgs = {
  input?: InputMaybe<Input_core_apimessages_SeedListingRequest>;
};

export type MutationguestCheckoutVerifyShopperArgs = {
  input?: InputMaybe<Input_core_apimessages_GuestCheckoutVerifyShopperRequest>;
};

export type MutationlistingCorrectionArgs = {
  input?: InputMaybe<Input_core_apimessages_ListingCorrectionRequest>;
};

export type MutationloginMyPacklinkAccountArgs = {
  input?: InputMaybe<Input_core_apimessages_LoginMyPacklinkAccountRequest>;
};

export type MutationmarkOrderAsReceivedArgs = {
  input?: InputMaybe<Input_core_apimessages_MarkOrderAsReceivedRequest>;
};

export type MutationmoveCartItemToWatchListArgs = {
  input?: InputMaybe<Input_core_apimessages_CartMoveToWatchListRequest>;
};

export type MutationmyShopExportDac7OrderDataArgs = {
  input?: InputMaybe<Input_core_apimessages_MyShopExportDac7OrderDataRequest>;
};

export type MutationpayMyStatementArgs = {
  input?: InputMaybe<Input_core_apimessages_PayMyStatementRequest>;
};

export type MutationprocessApprovedPaypalOrderArgs = {
  input?: InputMaybe<Input_core_apimessages_ProcessApprovedPaypalOrderRequest>;
};

export type MutationpromotionalCodeRedeemArgs = {
  input?: InputMaybe<Input_core_apimessages_RedeemPromotionalCodeRequest>;
};

export type MutationpurchaseShippingRateArgs = {
  input?: InputMaybe<Input_core_apimessages_PurchaseShippingRateRequest>;
};

export type MutationregisterMyPacklinkAccountArgs = {
  input?: InputMaybe<Input_core_apimessages_RegisterMyPacklinkAccountRequest>;
};

export type MutationrejectTradeInArgs = {
  input: RejectTradeInInput;
};

export type MutationremoveFeedEntryArgs = {
  input?: InputMaybe<Input_reverb_feed_RemoveEntryRequest>;
};

export type MutationremoveListingFromCuratedSetArgs = {
  input?: InputMaybe<Input_core_apimessages_AdminCuratedSetsCurateRequest>;
};

export type MutationremoveListingFromMyAffirmCuratedSetArgs = {
  input?: InputMaybe<Input_core_apimessages_MyAffirmCuratedSetMembershipRequest>;
};

export type MutationremoveListingFromMyCuratedSetArgs = {
  input?: InputMaybe<Input_core_apimessages_MyCuratedSetMembershipRequest>;
};

export type MutationrequestAccountDeletionArgs = {
  input?: InputMaybe<Input_core_apimessages_RequestAccountDeletionRequest>;
};

export type MutationrequestTaxFormDownloadArgs = {
  input?: InputMaybe<Input_core_apimessages_RequestTaxFormDownloadRequest>;
};

export type MutationreverifyCreditCardArgs = {
  input?: InputMaybe<Input_core_apimessages_ReverifyCreditCardRequest>;
};

export type MutationsaveMyPlaidAccountArgs = {
  input?: InputMaybe<Input_core_apimessages_SaveMyPlaidAccountRequest>;
};

export type MutationselectAddressAutocompleteSuggestionArgs = {
  input?: InputMaybe<Input_core_apimessages_SelectAddressAutocompleteSuggestionRequest>;
};

export type MutationselectCheckoutCreditCardArgs = {
  input?: InputMaybe<Input_core_apimessages_SelectCheckoutCreditCardRequest>;
};

export type MutationselectCheckoutOrderShippingMethodArgs = {
  input?: InputMaybe<Input_core_apimessages_CheckoutSelectOrderShippingMethodRequest>;
};

export type MutationselectCheckoutPaymentMethodArgs = {
  input?: InputMaybe<Input_core_apimessages_SelectCheckoutPaymentMethodRequest>;
};

export type MutationselectCheckoutShippingAddressArgs = {
  input?: InputMaybe<Input_core_apimessages_CheckoutSelectShippingAddressRequest>;
};

export type MutationsendDigitalListingDownloadEmailArgs = {
  input?: InputMaybe<Input_core_apimessages_DigitalListingSendDownloadEmailRequest>;
};

export type MutationsetAccertifyTokensArgs = {
  input?: InputMaybe<Input_core_apimessages_SetAccertifyTokensRequest>;
};

export type MutationsetEmailGlobalSubscribeArgs = {
  input?: InputMaybe<Input_rql_SetGlobalSubscribeRequest>;
};

export type MutationsetEmailSubscriptionArgs = {
  input?: InputMaybe<Input_rql_EmailSubscription>;
};

export type MutationsetupShopPaymentMethodsArgs = {
  input?: InputMaybe<Input_core_apimessages_SetupPaymentMethodsRequest>;
};

export type MutationshippingProtectionArgs = {
  input?: InputMaybe<Input_core_apimessages_ShippingProtectionRequest>;
};

export type MutationstartCheckoutArgs = {
  input?: InputMaybe<Input_core_apimessages_StartCheckoutRequest>;
};

export type MutationstartCheckoutForListingArgs = {
  input?: InputMaybe<Input_core_apimessages_StartCheckoutForListingRequest>;
};

export type MutationstartPaypalCheckoutArgs = {
  input?: InputMaybe<Input_core_apimessages_StartPaypalCheckoutRequest>;
};

export type MutationtaxFormDownloadArgs = {
  input?: InputMaybe<Input_core_apimessages_TaxFormDownloadRequest>;
};

export type MutationtrackBumpInteractionArgs = {
  input?: InputMaybe<Input_core_apimessages_TrackBumpInteractionRequest>;
};

export type MutationtriggerOptInEmailArgs = {
  input?: InputMaybe<Input_rql_Blank>;
};

export type MutationtruliooOnboardingRecordResendPscEmailArgs = {
  input?: InputMaybe<Input_core_apimessages_TruliooOnboardingRecordResendPscEmailRequest>;
};

export type MutationundoDeleteMyFavoriteArgs = {
  input?: InputMaybe<Input_core_apimessages_UndoDeleteMyFavoriteRequest>;
};

export type MutationunfeatureListingInCuratedSetArgs = {
  input?: InputMaybe<Input_core_apimessages_AdminCuratedSetsCurateRequest>;
};

export type MutationupdateAdArgs = {
  input?: InputMaybe<Input_core_apimessages_UpdateAdRequest>;
};

export type MutationupdateAdCampaignArgs = {
  input?: InputMaybe<Input_core_apimessages_UpdateAdCampaignRequest>;
};

export type MutationupdateAdGroupArgs = {
  input?: InputMaybe<Input_core_apimessages_UpdateAdGroupRequest>;
};

export type MutationupdateAdGroupKeywordsArgs = {
  input?: InputMaybe<Input_core_apimessages_UpdateAdGroupKeywordsRequest>;
};

export type MutationupdateAdZoneArgs = {
  input?: InputMaybe<Input_core_apimessages_UpdateAdZoneRequest>;
};

export type MutationupdateAdminNoteArgs = {
  input?: InputMaybe<Input_core_apimessages_AdminNoteUpdateRequest>;
};

export type MutationupdateAdminTax1099kReportingThresholdArgs = {
  input?: InputMaybe<Input_core_apimessages_AdminTax1099kReportingThresholdUpdateRequest>;
};

export type MutationupdateAdvertiserArgs = {
  input?: InputMaybe<Input_core_apimessages_UpdateAdvertiserRequest>;
};

export type MutationupdateCheckoutBillingAddressArgs = {
  input?: InputMaybe<Input_core_apimessages_UpdateCheckoutBillingAddressRequest>;
};

export type MutationupdateCheckoutPaypalOrderArgs = {
  input?: InputMaybe<Input_core_apimessages_UpdateCheckoutPaypalOrderRequest>;
};

export type MutationupdateCheckoutShippingAddressArgs = {
  input?: InputMaybe<Input_core_apimessages_CheckoutUpdateShippingAddressRequest>;
};

export type MutationupdateCheckoutShippingEstimateArgs = {
  input?: InputMaybe<Input_core_apimessages_CheckoutUpdateShippingEstimateRequest>;
};

export type MutationupdateCrossPlatformListingSessionArgs = {
  input?: InputMaybe<Input_core_apimessages_UpdateCrossPlatformListingSessionRequest>;
};

export type MutationupdateFeedbackRequestArgs = {
  input?: InputMaybe<Input_core_apimessages_UpdateFeedbackRequest>;
};

export type MutationupdateGearCollectionItemStateArgs = {
  input?: InputMaybe<Input_core_apimessages_UpdateGearCollectionItemStateRequest>;
};

export type MutationupdateInternationalTaxProfileArgs = {
  input?: InputMaybe<Input_core_apimessages_UpdateInternationalTaxProfileRequest>;
};

export type MutationupdateListingStateArgs = {
  input?: InputMaybe<Input_core_apimessages_UpdateListingStateRequest>;
};

export type MutationupdateMyAddressArgs = {
  input?: InputMaybe<Input_core_apimessages_UpdateAddressRequest>;
};

export type MutationupdateMyCreditCardArgs = {
  input?: InputMaybe<Input_core_apimessages_UpdateMyCreditCardRequest>;
};

export type MutationupdateMyGearCollectionSettingsArgs = {
  input?: InputMaybe<Input_core_apimessages_UpdateMyGearCollectionSettingsRequest>;
};

export type MutationupdateMyShopArgs = {
  input?: InputMaybe<Input_core_apimessages_UpdateMyShopRequest>;
};

export type MutationupdateMyShopBillingMethodArgs = {
  input?: InputMaybe<Input_core_apimessages_UpdateBillingMethodRequest>;
};

export type MutationupdateMyShopShippingProfilesArgs = {
  input?: InputMaybe<Input_core_apimessages_UpdateMyShopShippingProfilesRequest>;
};

export type MutationupdateNegotiationArgs = {
  input?: InputMaybe<Input_core_apimessages_UpdateNegotiationRequest>;
};

export type MutationupdateProductReviewArgs = {
  input?: InputMaybe<Input_core_apimessages_UpdateProductReviewRequest>;
};

export type MutationupdateReturnPolicyArgs = {
  input?: InputMaybe<Input_core_apimessages_UpdateReturnPolicyRequest>;
};

export type MutationupdateTaxIdentificationArgs = {
  input?: InputMaybe<Input_core_apimessages_UpdateTaxIdentificationRequest>;
};

export type MutationupdateUniversalPromoCampaignArgs = {
  input?: InputMaybe<Input_core_apimessages_UpdateUniversalPromoCampaignRequest>;
};

export type MutationupdateUserShopPermissionArgs = {
  input?: InputMaybe<Input_core_apimessages_UpdateUserShopPermissionRequest>;
};

export type MutationupsertMyFavoriteArgs = {
  input?: InputMaybe<Input_core_apimessages_UpsertMyFavoriteRequest>;
};

export type MutationupsertTaxProfileArgs = {
  input?: InputMaybe<InputTaxProfileUpsertRequest>;
};

export type MutationvoteProductReviewArgs = {
  input?: InputMaybe<Input_core_apimessages_ProductReviewVoteRequest>;
};

export type MyShop = INode & {
  __typename?: "MyShop";
  _id?: Maybe<Scalars["ID"]["output"]>;
  _source?: Maybe<Scalars["String"]["output"]>;
  /** Only visible to the shop owner */
  acceptsAffirm?: Maybe<Scalars["Boolean"]["output"]>;
  address?: Maybe<core_apimessages_Address>;
  adyenBalanceOnboardingDetails?: Maybe<core_apimessages_AdyenBalanceOnboardingResponse>;
  badges?: Maybe<Array<Maybe<core_apimessages_ShopBadge>>>;
  billingMethodStatus?: Maybe<core_apimessages_MyShopOnboardingResponse_BillingMethodStatus>;
  bumpStats?: Maybe<core_apimessages_MyShopBumpStatsResponse>;
  businessRegistrationNumber?: Maybe<core_apimessages_BusinessRegistrationNumber>;
  /** Only visible to the shop owner */
  canCurateSales?: Maybe<Scalars["Boolean"]["output"]>;
  createdAt?: Maybe<Scalars["Timestamp"]["output"]>;
  currency?: Maybe<Scalars["String"]["output"]>;
  /** Only visible to the shop owner */
  currencySetting?: Maybe<Scalars["String"]["output"]>;
  /** Only visible to the shop owner */
  defaultLocale?: Maybe<Scalars["String"]["output"]>;
  description?: Maybe<Scalars["String"]["output"]>;
  earnings?: Maybe<core_apimessages_MyShopEarningsResponse>;
  /** Only visible to the shop owner */
  firstTimeSeller?: Maybe<Scalars["Boolean"]["output"]>;
  /** Only visible to the shop owner */
  hasAccountRep?: Maybe<Scalars["Boolean"]["output"]>;
  id?: Maybe<Scalars["String"]["output"]>;
  /** Only visible to the shop owner */
  inclusiveVatPricingEnabled?: Maybe<Scalars["Boolean"]["output"]>;
  informActThresholdStatus?: Maybe<core_apimessages_InformActThresholdStatus>;
  interactions?: Maybe<arbiter_SellerInteractionTimeSeriesResponse>;
  internationalTaxProfile?: Maybe<core_apimessages_InternationalTaxProfile>;
  /** Only visible to the shop owner */
  isShopInEu?: Maybe<Scalars["Boolean"]["output"]>;
  /** Only visible to the shop owner */
  isShopInUk?: Maybe<Scalars["Boolean"]["output"]>;
  /** Only visible to the shop owner */
  listableConditions?: Maybe<Array<Maybe<core_apimessages_Condition>>>;
  listings?: Maybe<reverb_search_SearchResponse>;
  lowballOfferPercentage?: Maybe<Scalars["Int"]["output"]>;
  manufacturersSafetyInformation?: Maybe<Scalars["String"]["output"]>;
  /** Only visible to the shop owner */
  marketplaceTaxReportingCountryCode?: Maybe<Scalars["String"]["output"]>;
  name?: Maybe<Scalars["String"]["output"]>;
  /** Only visible to the shop owner */
  offerPolicy?: Maybe<core_apimessages_ShopConfig_OfferPolicy>;
  onVacation?: Maybe<Scalars["Boolean"]["output"]>;
  orderRefundStats?: Maybe<core_apimessages_MyShopOrderRefundStatsResponse>;
  ordersSearch?: Maybe<reverb_search_SearchResponse>;
  originCountryCode?: Maybe<Scalars["String"]["output"]>;
  packlink?: Maybe<core_apimessages_MyPacklinkAccountStatusResponse>;
  paymentMethod?: Maybe<core_apimessages_MyShopOnboardingResponse_PaymentMethod>;
  paymentPolicy?: Maybe<Scalars["String"]["output"]>;
  paypalProfile?: Maybe<core_apimessages_MyShopPaypalProfileResponse_PaypalProfile>;
  paypalSetupStatus?: Maybe<core_apimessages_MyShopOnboardingResponse_PaypalSetupStatus>;
  preferredSeller?: Maybe<Scalars["Boolean"]["output"]>;
  quickResponder?: Maybe<Scalars["Boolean"]["output"]>;
  quickShipper?: Maybe<Scalars["Boolean"]["output"]>;
  quickShipperSpeed?: Maybe<core_apimessages_Shop_QuickShipperSpeed>;
  /**
   * Unused by any client but needed in mobile apps RQL queries
   * @deprecated Unused by new clients. Required for legacy mobile app queries
   */
  recentlyShipped?: Maybe<Scalars["Boolean"]["output"]>;
  returnPolicy?: Maybe<core_apimessages_ReturnPolicy>;
  sales?: Maybe<core_apimessages_MySalesResponse>;
  /** Only visible to the shop owner */
  sameDayShippingConfigured?: Maybe<Scalars["Boolean"]["output"]>;
  sellerVerificationPolicy?: Maybe<core_apimessages_SellerVerificationPolicyResponse>;
  shippingPolicy?: Maybe<Scalars["String"]["output"]>;
  shippingProfiles?: Maybe<Array<Maybe<core_apimessages_MyShippingProfile>>>;
  /** Only visible to the shop owner */
  shippingRegionSettings?: Maybe<
    Array<Maybe<core_apimessages_ShippingRegionSetting>>
  >;
  shopCampaigns?: Maybe<Array<Maybe<core_apimessages_MyShopCampaign>>>;
  shopStats?: Maybe<core_apimessages_MyShopStatsResponse>;
  /** Only visible to the shop owner */
  shopSupportedLocaleCodes?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
  shopType?: Maybe<core_apimessages_Shop_ShopType>;
  showSold?: Maybe<Scalars["Boolean"]["output"]>;
  slug?: Maybe<Scalars["String"]["output"]>;
  taxIdentifications?: Maybe<Array<Maybe<core_apimessages_TaxIdentification>>>;
  taxPolicies?: Maybe<Array<Maybe<core_apimessages_ShopTaxPolicy>>>;
  taxProfile?: Maybe<core_apimessages_TaxProfile>;
  tradeIns?: Maybe<Array<Maybe<TradeIn>>>;
  truliooOnboarding?: Maybe<core_apimessages_TruliooOnboarding>;
  userId?: Maybe<Scalars["String"]["output"]>;
  userShopPermissions?: Maybe<Array<Maybe<UserShopPermission>>>;
  userUuid?: Maybe<Scalars["String"]["output"]>;
  uuid?: Maybe<Scalars["String"]["output"]>;
  vatId?: Maybe<Scalars["String"]["output"]>;
  website?: Maybe<Scalars["String"]["output"]>;
};

export type MyShopbumpStatsArgs = {
  input?: InputMaybe<Input_core_apimessages_MyShopBumpStatsRequest>;
};

export type MyShopearningsArgs = {
  input?: InputMaybe<Input_core_apimessages_MyShopEarningsRequest>;
};

export type MyShopinteractionsArgs = {
  input?: InputMaybe<Input_arbiter_SellerInteractionTimeSeriesRequest>;
};

export type MyShoplistingsArgs = {
  input?: InputMaybe<Input_reverb_search_ListingsSearchRequest>;
};

export type MyShoporderRefundStatsArgs = {
  input?: InputMaybe<Input_core_apimessages_MyShopOrderRefundStatsRequest>;
};

export type MyShopordersSearchArgs = {
  input?: InputMaybe<Input_reverb_search_OrderSearchRequest>;
};

export type MyShopshopStatsArgs = {
  input?: InputMaybe<Input_core_apimessages_MyShopStatsRequest>;
};

export type MyUpdate = INode & {
  __typename?: "MyUpdate";
  _id?: Maybe<Scalars["ID"]["output"]>;
  _source?: Maybe<Scalars["String"]["output"]>;
};

export type MyUpdatesPromotion = INode & {
  __typename?: "MyUpdatesPromotion";
  _id?: Maybe<Scalars["ID"]["output"]>;
  _source?: Maybe<Scalars["String"]["output"]>;
  id?: Maybe<Scalars["String"]["output"]>;
  images?: Maybe<Array<Maybe<core_apimessages_Image>>>;
  startsAt?: Maybe<Scalars["Timestamp"]["output"]>;
  subtitle?: Maybe<Scalars["String"]["output"]>;
  title?: Maybe<Scalars["String"]["output"]>;
  url?: Maybe<core_apimessages_Link>;
};

export type MyUpdatesPromotionimagesArgs = {
  input: Input_core_apimessages_ImagesRequest;
};

export type Negotiation = INode & {
  __typename?: "Negotiation";
  _id?: Maybe<Scalars["ID"]["output"]>;
  _source?: Maybe<Scalars["String"]["output"]>;
  buyer?: Maybe<User>;
  /**
   * deprecated in favor of seller_shipping_warning
   * @deprecated Field no longer supported
   */
  buyerOutsideListingShippingRegions?: Maybe<Scalars["Boolean"]["output"]>;
  buyerUuid?: Maybe<Scalars["String"]["output"]>;
  buyerWillPayImmediately?: Maybe<Scalars["Boolean"]["output"]>;
  expiresAt?: Maybe<google_protobuf_Timestamp>;
  /** negotiations.id */
  id?: Maybe<Scalars["String"]["output"]>;
  lastOffer?: Maybe<core_apimessages_Offer>;
  negotiationType?: Maybe<core_apimessages_NegotiationType>;
  offers?: Maybe<Array<Maybe<core_apimessages_Offer>>>;
  orderHistory?: Maybe<reverb_search_SearchResponse>;
  orderUuids?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
  orders?: Maybe<Array<Maybe<Order>>>;
  seller?: Maybe<User>;
  sellerShippingWarning?: Maybe<Scalars["String"]["output"]>;
  sellerUuid?: Maybe<Scalars["String"]["output"]>;
  shippingLocation?: Maybe<core_apimessages_Address>;
  /** repeated Offer offers = 2; */
  state?: Maybe<core_apimessages_NegotiationState>;
  taxIncluded?: Maybe<Scalars["Boolean"]["output"]>;
  taxIncludedHint?: Maybe<Scalars["String"]["output"]>;
};

export type NegotiationorderHistoryArgs = {
  input?: InputMaybe<Input_rql_OrderQuery>;
};

export type Node =
  | Article
  | CMSPage
  | CSP
  | GearCollectionItem
  | Listing
  | Negotiation
  | Order
  | PriceGuide
  | ProductReview
  | User;

export type Order = INode & {
  __typename?: "Order";
  _id?: Maybe<Scalars["ID"]["output"]>;
  _source?: Maybe<Scalars["String"]["output"]>;
  amountProduct?: Maybe<core_apimessages_Money>;
  amountShipping?: Maybe<core_apimessages_Money>;
  amountTax?: Maybe<core_apimessages_Money>;
  amountTotal?: Maybe<core_apimessages_Money>;
  buyer?: Maybe<User>;
  buyerOrder?: Maybe<core_apimessages_BuyerOrder>;
  buyerUuid?: Maybe<Scalars["String"]["output"]>;
  carrierCalculatedCarrier?: Maybe<Scalars["String"]["output"]>;
  carrierCalculatedShippingAmount?: Maybe<core_apimessages_Money>;
  checkoutUuid?: Maybe<Scalars["String"]["output"]>;
  createdAt?: Maybe<google_protobuf_Timestamp>;
  customsInfo?: Maybe<core_apimessages_OrderCustomsInfo>;
  feedback?: Maybe<Array<Maybe<core_apimessages_Feedback>>>;
  flatRateShippingOptions?: Maybe<core_apimessages_OrderFlatRateShippingOptionsResponse>;
  id?: Maybe<Scalars["String"]["output"]>;
  /** users.id */
  legacyBuyerId?: Maybe<Scalars["String"]["output"]>;
  /** orders.id */
  legacyOrderId?: Maybe<Scalars["String"]["output"]>;
  listing?: Maybe<Listing>;
  /** products.id */
  listingId?: Maybe<Scalars["String"]["output"]>;
  mobileStatus?: Maybe<Scalars["String"]["output"]>;
  needsFeedbackForBuyer?: Maybe<Scalars["Boolean"]["output"]>;
  needsFeedbackForSeller?: Maybe<Scalars["Boolean"]["output"]>;
  notes?: Maybe<Array<Maybe<core_apimessages_OrderNote>>>;
  orderType?: Maybe<Scalars["String"]["output"]>;
  orderUrl?: Maybe<core_apimessages_Link>;
  packlinkShipment?: Maybe<core_apimessages_PacklinkShipment>;
  paidAt?: Maybe<google_protobuf_Timestamp>;
  paymentRequiredAt?: Maybe<google_protobuf_Timestamp>;
  payments?: Maybe<Array<Maybe<core_apimessages_Payment>>>;
  protectionPlan?: Maybe<Scalars["Boolean"]["output"]>;
  protectionPlanEligible?: Maybe<Scalars["Boolean"]["output"]>;
  quantity?: Maybe<Scalars["Int"]["output"]>;
  refunds?: Maybe<core_apimessages_OrderRefunds>;
  seller?: Maybe<User>;
  sellerOrder?: Maybe<core_apimessages_SellerOrder>;
  sellerUuid?: Maybe<Scalars["String"]["output"]>;
  settlementAmountProductSubtotal?: Maybe<core_apimessages_Money>;
  shipmentStatus?: Maybe<Scalars["String"]["output"]>;
  shippedAt?: Maybe<google_protobuf_Timestamp>;
  shippingAddress?: Maybe<core_apimessages_Address>;
  shippingCode?: Maybe<Scalars["String"]["output"]>;
  shippingMethod?: Maybe<core_apimessages_ShippingMethod>;
  shippingProvider?: Maybe<Scalars["String"]["output"]>;
  shippingTaxed?: Maybe<Scalars["Boolean"]["output"]>;
  shopUuid?: Maybe<Scalars["String"]["output"]>;
  status?: Maybe<core_apimessages_Order_Status>;
  statusDescription?: Maybe<Scalars["String"]["output"]>;
  title?: Maybe<Scalars["String"]["output"]>;
  updatedAt?: Maybe<google_protobuf_Timestamp>;
  uuid?: Maybe<Scalars["String"]["output"]>;
  verifiedShippingAddress?: Maybe<core_apimessages_OrderVerifyShippingAddressResponse>;
  webTrackingUrl?: Maybe<Scalars["String"]["output"]>;
};

export type OrderBundle = INode & {
  __typename?: "OrderBundle";
  _id?: Maybe<Scalars["ID"]["output"]>;
  _source?: Maybe<Scalars["String"]["output"]>;
  amountCredit?: Maybe<core_apimessages_Money>;
  amountProductSubtotal?: Maybe<core_apimessages_Money>;
  amountShipping?: Maybe<core_apimessages_Money>;
  amountTax?: Maybe<core_apimessages_Money>;
  amountTotal?: Maybe<core_apimessages_Money>;
  bonusBucks?: Maybe<core_apimessages_Money>;
  checkoutType?: Maybe<core_apimessages_Checkout_Type>;
  /** order_bundles.id */
  id?: Maybe<Scalars["String"]["output"]>;
  localPickup?: Maybe<Scalars["Boolean"]["output"]>;
  orderType?: Maybe<Scalars["String"]["output"]>;
  orders?: Maybe<OrderCollection>;
  paymentMethod?: Maybe<core_apimessages_Payment_PaymentMethod>;
  shippingAddress?: Maybe<core_apimessages_Address>;
  /** order_bundles.checkout_uuid */
  uuid?: Maybe<Scalars["String"]["output"]>;
};

export type OrderCollection = {
  __typename?: "OrderCollection";
  _ids?: Maybe<Array<Maybe<Scalars["ID"]["output"]>>>;
  _source?: Maybe<Scalars["String"]["output"]>;
  nodes?: Maybe<Array<Maybe<Order>>>;
  pageInfo?: Maybe<rql_PageInfo>;
};

export type PackageSizeSuggestion = INode & {
  __typename?: "PackageSizeSuggestion";
  _id?: Maybe<Scalars["ID"]["output"]>;
  _source?: Maybe<Scalars["String"]["output"]>;
  height?: Maybe<core_apimessages_Measurement>;
  id?: Maybe<Scalars["String"]["output"]>;
  length?: Maybe<core_apimessages_Measurement>;
  weight?: Maybe<core_apimessages_Measurement>;
  width?: Maybe<core_apimessages_Measurement>;
};

export type PriceChange = INode & {
  __typename?: "PriceChange";
  _id?: Maybe<Scalars["ID"]["output"]>;
  _source?: Maybe<Scalars["String"]["output"]>;
  currentPriceDropPercent?: Maybe<Scalars["Int"]["output"]>;
  id?: Maybe<Scalars["String"]["output"]>;
  listing?: Maybe<Listing>;
  listingId?: Maybe<Scalars["String"]["output"]>;
  newPrice?: Maybe<core_apimessages_Money>;
  previousPrice?: Maybe<core_apimessages_Money>;
  totalPriceDropPercent?: Maybe<Scalars["Int"]["output"]>;
};

export type PriceGuide = INode & {
  __typename?: "PriceGuide";
  _id?: Maybe<Scalars["ID"]["output"]>;
  _source?: Maybe<Scalars["String"]["output"]>;
  /** price_guides.id */
  id?: Maybe<Scalars["String"]["output"]>;
  images?: Maybe<Array<Maybe<core_apimessages_Image>>>;
  priceHigh?: Maybe<core_apimessages_Money>;
  priceLow?: Maybe<core_apimessages_Money>;
  title?: Maybe<Scalars["String"]["output"]>;
  webLink?: Maybe<core_apimessages_Link>;
};

export type PriceGuideimagesArgs = {
  input: Input_core_apimessages_ImagesRequest;
};

export type ProductReview = INode & {
  __typename?: "ProductReview";
  _id?: Maybe<Scalars["ID"]["output"]>;
  _source?: Maybe<Scalars["String"]["output"]>;
  body?: Maybe<Scalars["String"]["output"]>;
  createdAt?: Maybe<Scalars["Timestamp"]["output"]>;
  id?: Maybe<Scalars["String"]["output"]>;
  isMyReview?: Maybe<Scalars["Boolean"]["output"]>;
  rating?: Maybe<Scalars["Int"]["output"]>;
  reported?: Maybe<Scalars["Boolean"]["output"]>;
  reviewer?: Maybe<core_apimessages_Reviewer>;
  title?: Maybe<Scalars["String"]["output"]>;
  verified?: Maybe<Scalars["Boolean"]["output"]>;
  voteCount?: Maybe<Scalars["Int"]["output"]>;
  voted?: Maybe<Scalars["Boolean"]["output"]>;
};

export type PublicComponent = INode & {
  __typename?: "PublicComponent";
  _id?: Maybe<Scalars["ID"]["output"]>;
  _source?: Maybe<Scalars["String"]["output"]>;
  active?: Maybe<Scalars["Boolean"]["output"]>;
  componentType?: Maybe<Scalars["String"]["output"]>;
  ctaUrl?: Maybe<Scalars["String"]["output"]>;
  data?: Maybe<Scalars["String"]["output"]>;
  entities?: Maybe<Array<Maybe<rql_PublicComponentEntity>>>;
  /** 'default' or 'experiment' */
  experimentBucket?: Maybe<Scalars["String"]["output"]>;
  /** cms_recently_viewed  cms_used_first */
  experimentKey?: Maybe<Scalars["String"]["output"]>;
  id?: Maybe<Scalars["String"]["output"]>;
  layoutSection?: Maybe<Scalars["String"]["output"]>;
  pageId?: Maybe<Scalars["String"]["output"]>;
  position?: Maybe<Scalars["Int"]["output"]>;
  searchSort?: Maybe<Scalars["String"]["output"]>;
  searchType?: Maybe<cms_PublicComponent_SearchType>;
  searchUrlParams?: Maybe<Scalars["String"]["output"]>;
  subtitle?: Maybe<Scalars["String"]["output"]>;
  title?: Maybe<Scalars["String"]["output"]>;
};

export type PublicComponentEntity = CSP | Listing;

export type PublicPriceRecord = INode & {
  __typename?: "PublicPriceRecord";
  _id?: Maybe<Scalars["ID"]["output"]>;
  _source?: Maybe<Scalars["String"]["output"]>;
  amountProduct?: Maybe<core_apimessages_Money>;
  amountShipping?: Maybe<core_apimessages_Money>;
  amountTax?: Maybe<core_apimessages_Money>;
  amountTotal?: Maybe<core_apimessages_Money>;
  condition?: Maybe<core_apimessages_Condition>;
  createdAt?: Maybe<google_protobuf_Timestamp>;
  listingId?: Maybe<Scalars["String"]["output"]>;
  orderType?: Maybe<Scalars["String"]["output"]>;
  settlementAmountProductSubtotal?: Maybe<core_apimessages_Money>;
  uuid?: Maybe<Scalars["String"]["output"]>;
};

export type Query = {
  __typename?: "Query";
  _source?: Maybe<Scalars["String"]["output"]>;
  _trace: _Trace;
  ad?: Maybe<Ad>;
  adCampaignsSearch?: Maybe<core_apimessages_AdCampaignsSearchResponse>;
  adGroupsSearch?: Maybe<core_apimessages_AdGroupsSearchResponse>;
  adServe?: Maybe<core_apimessages_AdServeResponse>;
  adZonesSearch?: Maybe<core_apimessages_AdZonesSearchResponse>;
  adcampaign?: Maybe<AdCampaign>;
  addressVerify?: Maybe<core_apimessages_AddressVerifyResponse>;
  adgroup?: Maybe<AdGroup>;
  adminNotesSearch?: Maybe<core_apimessages_AdminNotesSearchResponse>;
  adsSearch?: Maybe<core_apimessages_AdsSearchResponse>;
  advertiser?: Maybe<Advertiser>;
  advertisersSearch?: Maybe<core_apimessages_AdvertisersSearchResponse>;
  adzone?: Maybe<AdZone>;
  article?: Maybe<Article>;
  articles?: Maybe<Array<Maybe<Article>>>;
  autocomplete?: Maybe<reverb_search_AutocompleteResponse>;
  bearerV2Token?: Maybe<core_apimessages_BearerV2TokenResponse>;
  brand?: Maybe<Brand>;
  brands?: Maybe<Array<Maybe<Brand>>>;
  browsepage?: Maybe<BrowsePage>;
  bumpedSortedListings?: Maybe<ListingCollection>;
  canonicalproduct?: Maybe<CanonicalProduct>;
  categories?: Maybe<Array<Maybe<Category>>>;
  category?: Maybe<Category>;
  checkout?: Maybe<Checkout>;
  checkoutaction?: Maybe<CheckoutAction>;
  cmsPages?: Maybe<cms_PagesResponse>;
  cmsPagesFetch?: Maybe<Array<Maybe<CMSPage>>>;
  cmsPagesSearch?: Maybe<reverb_search_SearchResponse>;
  cmspage?: Maybe<CMSPage>;
  collection?: Maybe<Array<Maybe<Reflection>>>;
  collectionHeader?: Maybe<CollectionHeader>;
  collectionheader?: Maybe<CollectionHeader>;
  completions?: Maybe<reverb_search_SearchResponse>;
  countries?: Maybe<core_apimessages_CountriesResponse>;
  cpSuggestions?: Maybe<Array<Maybe<core_apimessages_CPSuggestions>>>;
  cpToListingRecs?: Maybe<rql_RecommendationsGenericResponse>;
  crossPlatformListingStatus?: Maybe<core_apimessages_CrossPlatformListingStatusResponse>;
  csp?: Maybe<CSP>;
  cspPriceRecommendations?: Maybe<reverb_pricing_PriceRecommendationsResponse>;
  cspSearch?: Maybe<reverb_search_SearchResponse>;
  cspSponsoredListing?: Maybe<arbiter_FeaturedListingResponse>;
  csps?: Maybe<Array<Maybe<CSP>>>;
  curatedSets?: Maybe<Array<Maybe<CuratedSet>>>;
  curatedset?: Maybe<CuratedSet>;
  datadogErrorsSearch?: Maybe<core_apimessages_AdminUserDatadogErrorsSearchResponse>;
  estimateNegotiationSalesTax?: Maybe<core_apimessages_EstimateNegotiationSalesTaxResponse>;
  estimatednewlistingprice?: Maybe<EstimatedNewListingPrice>;
  exchangeRates?: Maybe<reverb_config_cache_CurrencyResponse>;
  experiments?: Maybe<core_apimessages_ExperimentsResponse>;
  expresssalecheckout?: Maybe<ExpressSaleCheckout>;
  featuredListing?: Maybe<arbiter_FeaturedListingResponse>;
  findAddressAutocompleteSuggestions?: Maybe<core_apimessages_FindAddressAutocompleteSuggestionsResponse>;
  findAddressSuggestions?: Maybe<rql_FindAddressSuggestionResponse>;
  findFavorite?: Maybe<core_apimessages_FindFavoriteResponse>;
  formSelectCategories?: Maybe<core_apimessages_FormSelectCategoriesResponse>;
  gearCollectionItemsSearch?: Maybe<reverb_search_SearchResponse>;
  gearcollectionitem?: Maybe<GearCollectionItem>;
  getAdyenRegion?: Maybe<core_apimessages_ShowAdyenRegionResponse>;
  homepage?: Maybe<core_apimessages_HomepageResponse>;
  intlAddressFormConfig?: Maybe<reverb_config_cache_InternationalAddressFormConfigResponse>;
  listing?: Maybe<Listing>;
  listings?: Maybe<Array<Maybe<Listing>>>;
  listingsAggregationSearch?: Maybe<reverb_search_ListingsAggregationResponse>;
  listingsSearch?: Maybe<reverb_search_SearchResponse>;
  loggedOutRecommendations?: Maybe<reverb_search_SearchResponse>;
  marketplaceListingsSearch: SearchResult;
  me?: Maybe<rql_Me>;
  mosaicTilesHomepage?: Maybe<core_apimessages_MosaicTilesResponse>;
  myDirectCheckoutProfile?: Maybe<core_apimessages_MyDirectCheckoutProfileResponse>;
  myFeedbackMessages?: Maybe<core_apimessages_MyFeedbackMessagesResponse>;
  myshop?: Maybe<MyShop>;
  myupdate?: Maybe<MyUpdate>;
  myupdatespromotion?: Maybe<MyUpdatesPromotion>;
  negotiation?: Maybe<Negotiation>;
  node?: Maybe<Reflection>;
  order?: Maybe<Order>;
  orderBundles?: Maybe<Array<Maybe<OrderBundle>>>;
  orderbundle?: Maybe<OrderBundle>;
  ordersSearch?: Maybe<reverb_search_SearchResponse>;
  packagesizesuggestion?: Maybe<PackageSizeSuggestion>;
  paypalOnboardSeller?: Maybe<core_apimessages_PaypalOnboardSellerResponse>;
  priceGuidesSearch?: Maybe<reverb_search_SearchResponse>;
  priceRecommendations?: Maybe<reverb_pricing_PriceRecommendationsResponse>;
  priceRecordsSearch?: Maybe<reverb_search_SearchResponse>;
  priceScores?: Maybe<reverb_pricing_PriceScoresResponse>;
  pricechange?: Maybe<PriceChange>;
  priceguide?: Maybe<PriceGuide>;
  productReviewsSearch?: Maybe<reverb_search_SearchResponse>;
  productreview?: Maybe<ProductReview>;
  promotionalCodeVerify?: Maybe<core_apimessages_VerifyPromotionalCodeResponse>;
  publiccomponent?: Maybe<PublicComponent>;
  publicpricerecord?: Maybe<PublicPriceRecord>;
  querySuggestions?: Maybe<reverb_personalization_QuerySuggestionsResponse>;
  recentlyViewedListings?: Maybe<Array<Maybe<Listing>>>;
  recommendedShops?: Maybe<Array<Maybe<Shop>>>;
  sale?: Maybe<Sale>;
  searchMetadata?: Maybe<core_apimessages_SearchMetadataResponse>;
  seedListing?: Maybe<core_apimessages_SeedListingResponse>;
  selectAddressSuggestion?: Maybe<rql_SelectAddressSuggestionResponse>;
  sellercheckout?: Maybe<SellerCheckout>;
  sellingFees?: Maybe<core_apimessages_SellingFeesResponse>;
  shipment?: Maybe<Shipment>;
  shipmentpackage?: Maybe<ShipmentPackage>;
  shippingRegionFilter?: Maybe<reverb_search_ShippingRegionFilterResponse>;
  shop?: Maybe<Shop>;
  shopAdyenBalancePlatformNotifications?: Maybe<core_apimessages_ShopAdyenBalancePlatformNotificationsIndexResponse>;
  shopcampaigncoupon?: Maybe<ShopCampaignCoupon>;
  similarListingsSearch?: Maybe<reverb_search_SearchResponse>;
  siteBanner?: Maybe<core_apimessages_SiteBanner>;
  sitebanner?: Maybe<SiteBanner>;
  storefront?: Maybe<Storefront>;
  taxReportingThresholdSearch?: Maybe<core_apimessages_AdminTax1099kReportingThresholdSearchResponse>;
  tradeIn?: Maybe<TradeIn>;
  tradeInEligibility?: Maybe<TradeInEligibility>;
  trendingSearches?: Maybe<reverb_personalization_TrendingSearchesResponse>;
  universalpromocampaign?: Maybe<UniversalPromoCampaign>;
  user?: Maybe<User>;
  userSearch?: Maybe<core_apimessages_AdminUserSearchResponse>;
  userWatchesSearch?: Maybe<WatchCollection>;
  usershoppermission?: Maybe<UserShopPermission>;
  watch?: Maybe<Watch>;
};

export type QueryadArgs = {
  input?: InputMaybe<Input_rql_NodeQuery>;
};

export type QueryadCampaignsSearchArgs = {
  input?: InputMaybe<Input_core_apimessages_AdCampaignsSearchRequest>;
};

export type QueryadGroupsSearchArgs = {
  input?: InputMaybe<Input_core_apimessages_AdGroupsSearchRequest>;
};

export type QueryadServeArgs = {
  input?: InputMaybe<Input_core_apimessages_AdServeRequest>;
};

export type QueryadZonesSearchArgs = {
  input?: InputMaybe<Input_core_apimessages_AdZonesSearchRequest>;
};

export type QueryadcampaignArgs = {
  input?: InputMaybe<Input_rql_NodeQuery>;
};

export type QueryaddressVerifyArgs = {
  input?: InputMaybe<Input_core_apimessages_AddressVerifyRequest>;
};

export type QueryadgroupArgs = {
  input?: InputMaybe<Input_rql_NodeQuery>;
};

export type QueryadminNotesSearchArgs = {
  input?: InputMaybe<Input_core_apimessages_AdminNotesSearchRequest>;
};

export type QueryadsSearchArgs = {
  input?: InputMaybe<Input_core_apimessages_AdsSearchRequest>;
};

export type QueryadvertiserArgs = {
  input?: InputMaybe<Input_rql_NodeQuery>;
};

export type QueryadvertisersSearchArgs = {
  input?: InputMaybe<Input_core_apimessages_AdvertisersSearchRequest>;
};

export type QueryadzoneArgs = {
  input?: InputMaybe<Input_rql_NodeQuery>;
};

export type QueryarticleArgs = {
  input?: InputMaybe<Input_rql_NodeQuery>;
};

export type QueryarticlesArgs = {
  input?: InputMaybe<Input_rql_CollectionQuery>;
};

export type QueryautocompleteArgs = {
  input?: InputMaybe<Input_reverb_search_AutocompleteRequest>;
};

export type QuerybearerV2TokenArgs = {
  input?: InputMaybe<Input_core_apimessages_BearerV2TokenRequest>;
};

export type QuerybrandArgs = {
  input?: InputMaybe<Input_rql_NodeQuery>;
};

export type QuerybrandsArgs = {
  input?: InputMaybe<Input_rql_CollectionQuery>;
};

export type QuerybrowsepageArgs = {
  input?: InputMaybe<Input_rql_NodeQuery>;
};

export type QuerybumpedSortedListingsArgs = {
  input?: InputMaybe<Input_reverb_search_ListingsSearchRequest>;
};

export type QuerycanonicalproductArgs = {
  input?: InputMaybe<Input_rql_NodeQuery>;
};

export type QuerycategoriesArgs = {
  input?: InputMaybe<Input_core_apimessages_CategoriesRequest>;
};

export type QuerycategoryArgs = {
  input?: InputMaybe<Input_rql_NodeQuery>;
};

export type QuerycheckoutArgs = {
  input?: InputMaybe<Input_rql_NodeQuery>;
};

export type QuerycheckoutactionArgs = {
  input?: InputMaybe<Input_rql_NodeQuery>;
};

export type QuerycmsPagesArgs = {
  input?: InputMaybe<Input_cms_PagesRequest>;
};

export type QuerycmsPagesFetchArgs = {
  input?: InputMaybe<Input_rql_CollectionWithDraftsQuery>;
};

export type QuerycmsPagesSearchArgs = {
  input?: InputMaybe<Input_reverb_search_CMSPagesSearchRequest>;
};

export type QuerycmspageArgs = {
  input?: InputMaybe<Input_rql_NodeQuery>;
};

export type QuerycollectionArgs = {
  input?: InputMaybe<Input_rql_ReflectCollectionQuery>;
};

export type QuerycollectionHeaderArgs = {
  input?: InputMaybe<Input_core_apimessages_CollectionHeaderRequest>;
};

export type QuerycollectionheaderArgs = {
  input?: InputMaybe<Input_rql_NodeQuery>;
};

export type QuerycompletionsArgs = {
  input?: InputMaybe<Input_reverb_search_CompletionsRequest>;
};

export type QuerycpSuggestionsArgs = {
  input?: InputMaybe<Input_rql_CollectionQuery>;
};

export type QuerycpToListingRecsArgs = {
  input?: InputMaybe<Input_rql_CPRecommendationsRequest>;
};

export type QuerycrossPlatformListingStatusArgs = {
  input?: InputMaybe<Input_core_apimessages_CrossPlatformListingStatusRequest>;
};

export type QuerycspArgs = {
  input?: InputMaybe<Input_rql_NodeQuery>;
};

export type QuerycspPriceRecommendationsArgs = {
  input?: InputMaybe<Input_reverb_pricing_CSPPriceRecommendationsRequest>;
};

export type QuerycspSearchArgs = {
  input?: InputMaybe<Input_reverb_search_CSPSearchRequest>;
};

export type QuerycspSponsoredListingArgs = {
  input?: InputMaybe<Input_reverb_search_ListingsSearchRequest>;
};

export type QuerycspsArgs = {
  input?: InputMaybe<Input_rql_CollectionQuery>;
};

export type QuerycuratedSetsArgs = {
  input?: InputMaybe<Input_rql_CollectionQuery>;
};

export type QuerycuratedsetArgs = {
  input?: InputMaybe<Input_rql_NodeQuery>;
};

export type QuerydatadogErrorsSearchArgs = {
  input?: InputMaybe<Input_core_apimessages_AdminUserDatadogErrorsSearchRequest>;
};

export type QueryestimateNegotiationSalesTaxArgs = {
  input?: InputMaybe<Input_core_apimessages_EstimateNegotiationSalesTaxRequest>;
};

export type QueryestimatednewlistingpriceArgs = {
  input?: InputMaybe<Input_rql_NodeQuery>;
};

export type QueryexchangeRatesArgs = {
  input?: InputMaybe<Input_reverb_config_cache_ExchangeRateRequest>;
};

export type QueryexpresssalecheckoutArgs = {
  input?: InputMaybe<Input_rql_NodeQuery>;
};

export type QueryfeaturedListingArgs = {
  input?: InputMaybe<Input_arbiter_FeaturedListingRequest>;
};

export type QueryfindAddressAutocompleteSuggestionsArgs = {
  input?: InputMaybe<Input_core_apimessages_FindAddressAutocompleteSuggestionsRequest>;
};

export type QueryfindAddressSuggestionsArgs = {
  input?: InputMaybe<Input_rql_FindAddressSuggestionRequest>;
};

export type QueryfindFavoriteArgs = {
  input?: InputMaybe<Input_core_apimessages_FindFavoriteRequest>;
};

export type QueryformSelectCategoriesArgs = {
  input?: InputMaybe<Input_core_apimessages_FormSelectCategoriesRequest>;
};

export type QuerygearCollectionItemsSearchArgs = {
  input?: InputMaybe<Input_rql_GearCollectionItemsSearchRequest>;
};

export type QuerygearcollectionitemArgs = {
  input?: InputMaybe<Input_rql_NodeQuery>;
};

export type QueryhomepageArgs = {
  input?: InputMaybe<Input_rql_HomepageRequest>;
};

export type QuerylistingArgs = {
  input?: InputMaybe<Input_rql_NodeQuery>;
};

export type QuerylistingsArgs = {
  input?: InputMaybe<Input_rql_CollectionQuery>;
};

export type QuerylistingsAggregationSearchArgs = {
  input?: InputMaybe<Input_reverb_search_ListingsSearchRequest>;
};

export type QuerylistingsSearchArgs = {
  input?: InputMaybe<Input_reverb_search_ListingsSearchRequest>;
};

export type QueryloggedOutRecommendationsArgs = {
  input?: InputMaybe<Input_mparticle_Mpid>;
};

export type QuerymarketplaceListingsSearchArgs = {
  input: MarketplaceSearchInput;
};

export type QuerymyDirectCheckoutProfileArgs = {
  input?: InputMaybe<Input_core_apimessages_MyDirectCheckoutProfileRequest>;
};

export type QuerymyFeedbackMessagesArgs = {
  input?: InputMaybe<Input_core_apimessages_MyFeedbackMessagesRequest>;
};

export type QuerymyshopArgs = {
  input?: InputMaybe<Input_rql_NodeQuery>;
};

export type QuerymyupdateArgs = {
  input?: InputMaybe<Input_rql_NodeQuery>;
};

export type QuerymyupdatespromotionArgs = {
  input?: InputMaybe<Input_rql_NodeQuery>;
};

export type QuerynegotiationArgs = {
  input?: InputMaybe<Input_rql_NodeQuery>;
};

export type QuerynodeArgs = {
  input?: InputMaybe<Input_rql_ReflectQuery>;
};

export type QueryorderArgs = {
  input?: InputMaybe<Input_rql_NodeQuery>;
};

export type QueryorderBundlesArgs = {
  input?: InputMaybe<Input_rql_CollectionQuery>;
};

export type QueryorderbundleArgs = {
  input?: InputMaybe<Input_rql_NodeQuery>;
};

export type QueryordersSearchArgs = {
  input?: InputMaybe<Input_rql_OrdersSearchRequest>;
};

export type QuerypackagesizesuggestionArgs = {
  input?: InputMaybe<Input_rql_NodeQuery>;
};

export type QuerypriceGuidesSearchArgs = {
  input?: InputMaybe<Input_reverb_search_PriceGuidesSearchRequest>;
};

export type QuerypriceRecommendationsArgs = {
  input?: InputMaybe<Input_reverb_pricing_PriceRecommendationsRequest>;
};

export type QuerypriceRecordsSearchArgs = {
  input?: InputMaybe<Input_rql_PriceRecordSearchRequest>;
};

export type QuerypriceScoresArgs = {
  input?: InputMaybe<Input_reverb_pricing_PriceScoresRequest>;
};

export type QuerypricechangeArgs = {
  input?: InputMaybe<Input_rql_NodeQuery>;
};

export type QuerypriceguideArgs = {
  input?: InputMaybe<Input_rql_NodeQuery>;
};

export type QueryproductReviewsSearchArgs = {
  input?: InputMaybe<Input_reverb_search_ProductReviewsSearchRequest>;
};

export type QueryproductreviewArgs = {
  input?: InputMaybe<Input_rql_NodeQuery>;
};

export type QuerypromotionalCodeVerifyArgs = {
  input?: InputMaybe<Input_core_apimessages_VerifyPromotionalCodeRequest>;
};

export type QuerypubliccomponentArgs = {
  input?: InputMaybe<Input_rql_NodeQuery>;
};

export type QuerypublicpricerecordArgs = {
  input?: InputMaybe<Input_rql_NodeQuery>;
};

export type QueryquerySuggestionsArgs = {
  input?: InputMaybe<Input_reverb_personalization_QuerySuggestionsRequest>;
};

export type QueryrecentlyViewedListingsArgs = {
  input?: InputMaybe<Input_core_apimessages_RecentlyViewedListingsRequest>;
};

export type QueryrecommendedShopsArgs = {
  input?: InputMaybe<Input_rql_ShopsRecommendationsRequest>;
};

export type QuerysaleArgs = {
  input?: InputMaybe<Input_rql_NodeQuery>;
};

export type QuerysearchMetadataArgs = {
  input?: InputMaybe<Input_core_apimessages_SearchMetadataRequest>;
};

export type QueryseedListingArgs = {
  input?: InputMaybe<Input_core_apimessages_SeedListingRequest>;
};

export type QueryselectAddressSuggestionArgs = {
  input?: InputMaybe<Input_rql_SelectAddressSuggestionRequest>;
};

export type QuerysellercheckoutArgs = {
  input?: InputMaybe<Input_rql_NodeQuery>;
};

export type QuerysellingFeesArgs = {
  input?: InputMaybe<Input_core_apimessages_SellingFeesRequest>;
};

export type QueryshipmentArgs = {
  input?: InputMaybe<Input_rql_NodeQuery>;
};

export type QueryshipmentpackageArgs = {
  input?: InputMaybe<Input_rql_NodeQuery>;
};

export type QueryshippingRegionFilterArgs = {
  input?: InputMaybe<Input_reverb_search_ShippingRegionFilterRequest>;
};

export type QueryshopArgs = {
  input?: InputMaybe<Input_rql_NodeQuery>;
};

export type QueryshopAdyenBalancePlatformNotificationsArgs = {
  input?: InputMaybe<Input_core_apimessages_ShopAdyenBalancePlatformNotificationsIndexRequest>;
};

export type QueryshopcampaigncouponArgs = {
  input?: InputMaybe<Input_rql_NodeQuery>;
};

export type QuerysimilarListingsSearchArgs = {
  input?: InputMaybe<Input_reverb_search_SimilarListingsSearchRequest>;
};

export type QuerysiteBannerArgs = {
  input?: InputMaybe<Input_core_apimessages_SiteBannerRequest>;
};

export type QuerysitebannerArgs = {
  input?: InputMaybe<Input_rql_NodeQuery>;
};

export type QuerystorefrontArgs = {
  input?: InputMaybe<Input_rql_NodeQuery>;
};

export type QuerytaxReportingThresholdSearchArgs = {
  input?: InputMaybe<Input_core_apimessages_AdminTax1099kReportingThresholdSearchRequest>;
};

export type QuerytradeInArgs = {
  input?: InputMaybe<Input_rql_NodeQuery>;
};

export type QuerytradeInEligibilityArgs = {
  input: TradeInEligibilityInput;
};

export type QuerytrendingSearchesArgs = {
  input?: InputMaybe<Input_reverb_personalization_GetTrendingSearchesRequest>;
};

export type QueryuniversalpromocampaignArgs = {
  input?: InputMaybe<Input_rql_NodeQuery>;
};

export type QueryuserArgs = {
  input?: InputMaybe<Input_rql_NodeQuery>;
};

export type QueryuserSearchArgs = {
  input?: InputMaybe<Input_core_apimessages_AdminUserSearchRequest>;
};

export type QueryuserWatchesSearchArgs = {
  input?: InputMaybe<Input_core_apimessages_UserWatchesRequest>;
};

export type QueryusershoppermissionArgs = {
  input?: InputMaybe<Input_rql_NodeQuery>;
};

export type QuerywatchArgs = {
  input?: InputMaybe<Input_rql_NodeQuery>;
};

export type RangeInput = {
  max?: InputMaybe<Scalars["Float"]["input"]>;
  min?: InputMaybe<Scalars["Float"]["input"]>;
};

export type Reflection =
  | Ad
  | AdCampaign
  | AdGroup
  | AdZone
  | Advertiser
  | Article
  | Brand
  | BrowsePage
  | CMSPage
  | CSP
  | CanonicalProduct
  | Category
  | Checkout
  | CheckoutAction
  | CollectionHeader
  | CuratedSet
  | EstimatedNewListingPrice
  | ExpressSaleCheckout
  | GearCollectionItem
  | Listing
  | MyShop
  | MyUpdate
  | MyUpdatesPromotion
  | Negotiation
  | Order
  | OrderBundle
  | PackageSizeSuggestion
  | PriceChange
  | PriceGuide
  | ProductReview
  | PublicComponent
  | PublicPriceRecord
  | Sale
  | SellerCheckout
  | Shipment
  | ShipmentPackage
  | Shop
  | ShopCampaignCoupon
  | SiteBanner
  | Storefront
  | UniversalPromoCampaign
  | User
  | UserShopPermission
  | Watch;

export type RejectTradeInInput = {
  rejectionMessage?: InputMaybe<Scalars["String"]["input"]>;
  rejectionReason: TradeInRejectionReason;
  tradeInId: Scalars["String"]["input"];
};

export type Sale = INode & {
  __typename?: "Sale";
  _id?: Maybe<Scalars["ID"]["output"]>;
  _source?: Maybe<Scalars["String"]["output"]>;
  buyerIneligibilityReason?: Maybe<Scalars["String"]["output"]>;
  code?: Maybe<Scalars["String"]["output"]>;
  discountValue?: Maybe<Scalars["String"]["output"]>;
  display?: Maybe<Scalars["String"]["output"]>;
  endsAt?: Maybe<google_protobuf_Timestamp>;
  /** sales.id */
  id?: Maybe<Scalars["String"]["output"]>;
  links?: Maybe<core_apimessages_SaleLinks>;
  name?: Maybe<Scalars["String"]["output"]>;
  percent?: Maybe<Scalars["Int"]["output"]>;
  saleType?: Maybe<Scalars["String"]["output"]>;
  shopName?: Maybe<Scalars["String"]["output"]>;
  shopSlug?: Maybe<Scalars["String"]["output"]>;
  /** @deprecated Field no longer supported */
  showCode?: Maybe<Scalars["Boolean"]["output"]>;
  slug?: Maybe<Scalars["String"]["output"]>;
  startsAt?: Maybe<google_protobuf_Timestamp>;
  state?: Maybe<core_apimessages_Sale_SaleState>;
  summary?: Maybe<Scalars["String"]["output"]>;
  /** @deprecated Field no longer supported */
  withSalePriceDisplay?: Maybe<Scalars["String"]["output"]>;
};

export type SearchDocument = {
  __typename?: "SearchDocument";
  fields?: Maybe<Scalars["JSON"]["output"]>;
  id: Scalars["ID"]["output"];
  score?: Maybe<Scalars["Float"]["output"]>;
};

export type SearchFilterInfo = {
  __typename?: "SearchFilterInfo";
  filterGroups: Array<SearchResultFilterGroup>;
};

export type SearchInput = {
  filters?: InputMaybe<Array<FilterInput>>;
  locationContext?: InputMaybe<SearchLocationContext>;
  page?: Scalars["Int"]["input"];
  pageSize?: Scalars["Int"]["input"];
  query?: InputMaybe<Scalars["String"]["input"]>;
  sort?: Sort;
  userContext?: InputMaybe<SearchUserContext>;
};

export type SearchLocationContext = {
  locale?: InputMaybe<Scalars["String"]["input"]>;
  shipFrom?: InputMaybe<Scalars["String"]["input"]>;
  shipTo?: InputMaybe<Scalars["String"]["input"]>;
};

export type SearchPagination = {
  __typename?: "SearchPagination";
  page: Scalars["Int"]["output"];
  pageSize: Scalars["Int"]["output"];
  total: Scalars["Int"]["output"];
};

export type SearchQueryInfo = {
  __typename?: "SearchQueryInfo";
  appliedQuery: Scalars["String"]["output"];
  originalQuery?: Maybe<Scalars["String"]["output"]>;
  suggestedQueries?: Maybe<Array<Scalars["String"]["output"]>>;
};

export type SearchResult = {
  __typename?: "SearchResult";
  filterInfo?: Maybe<SearchFilterInfo>;
  listings: Array<Listing>;
  pagination: SearchPagination;
  query?: Maybe<Scalars["String"]["output"]>;
  queryInfo?: Maybe<SearchQueryInfo>;
  results: Array<SearchDocument>;
};

export type SearchResultFilter = {
  __typename?: "SearchResultFilter";
  count?: Maybe<Scalars["Int"]["output"]>;
  key: Scalars["String"]["output"];
};

export type SearchResultFilterGroup = {
  __typename?: "SearchResultFilterGroup";
  filters: Array<SearchResultFilter>;
  key: Scalars["String"]["output"];
};

export type SearchUserContext = {
  appVersion?: InputMaybe<Scalars["String"]["input"]>;
  deviceId?: InputMaybe<Scalars["String"]["input"]>;
  mpid?: InputMaybe<Scalars["String"]["input"]>;
  platform?: InputMaybe<Scalars["String"]["input"]>;
  platformVersion?: InputMaybe<Scalars["String"]["input"]>;
  ra?: Scalars["Boolean"]["input"];
  sessionId?: InputMaybe<Scalars["String"]["input"]>;
  shippingRegion?: InputMaybe<Scalars["String"]["input"]>;
  userId?: InputMaybe<Scalars["String"]["input"]>;
};

export type SellerCheckout = INode & {
  __typename?: "SellerCheckout";
  _id?: Maybe<Scalars["ID"]["output"]>;
  _source?: Maybe<Scalars["String"]["output"]>;
  flatRateShippingOptions?: Maybe<core_apimessages_OrderFlatRateShippingOptionsResponse>;
  ordersForShipmentSearch?: Maybe<reverb_search_SearchResponse>;
  verifiedShippingAddress?: Maybe<core_apimessages_OrderVerifyShippingAddressResponse>;
};

export type SellerCheckoutordersForShipmentSearchArgs = {
  input?: InputMaybe<Input_rql_OrderQuery>;
};

export type Shipment = INode & {
  __typename?: "Shipment";
  _id?: Maybe<Scalars["ID"]["output"]>;
  _source?: Maybe<Scalars["String"]["output"]>;
  carrierRates?: Maybe<Array<Maybe<core_apimessages_CarrierRateGroup>>>;
  defaultPaymentMethod?: Maybe<core_apimessages_FeePaymentMethod>;
  fromAddress?: Maybe<core_apimessages_Address>;
  /** shipments.uuid */
  id?: Maybe<Scalars["String"]["output"]>;
  mediaMailQualified?: Maybe<Scalars["Boolean"]["output"]>;
  orderUuids?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
  orders?: Maybe<Array<Maybe<Order>>>;
  package?: Maybe<core_apimessages_ShippingLabelPackage>;
  shippingMethod?: Maybe<core_apimessages_ShippingMethod>;
  shippingProtection?: Maybe<Scalars["Boolean"]["output"]>;
  shippingProtectionPrice?: Maybe<core_apimessages_Money>;
  signatureRequired?: Maybe<Scalars["Boolean"]["output"]>;
  toAddress?: Maybe<core_apimessages_Address>;
  toAddressIsResidential?: Maybe<Scalars["Boolean"]["output"]>;
};

export type ShipmentPackage = INode & {
  __typename?: "ShipmentPackage";
  _id?: Maybe<Scalars["ID"]["output"]>;
  _source?: Maybe<Scalars["String"]["output"]>;
  allowedCarriers?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
  height?: Maybe<core_apimessages_Measurement>;
  id?: Maybe<Scalars["String"]["output"]>;
  length?: Maybe<core_apimessages_Measurement>;
  packageSizeSuggestionId?: Maybe<Scalars["String"]["output"]>;
  predefinedPackage?: Maybe<Scalars["String"]["output"]>;
  /** @deprecated Unused by clients */
  reverbSuggested?: Maybe<Scalars["Boolean"]["output"]>;
  weight?: Maybe<core_apimessages_Measurement>;
  width?: Maybe<core_apimessages_Measurement>;
};

export type Shop = INode & {
  __typename?: "Shop";
  _id?: Maybe<Scalars["ID"]["output"]>;
  _source?: Maybe<Scalars["String"]["output"]>;
  /** Only visible to the shop owner */
  acceptsAffirm?: Maybe<Scalars["Boolean"]["output"]>;
  address?: Maybe<core_apimessages_Address>;
  banner?: Maybe<core_apimessages_Image>;
  buyerCoupons?: Maybe<Array<Maybe<ShopCampaignCoupon>>>;
  /** Only visible to the shop owner */
  canCurateSales?: Maybe<Scalars["Boolean"]["output"]>;
  certifiedPreOwned?: Maybe<core_apimessages_ShopCertifiedPreOwned>;
  createdAt?: Maybe<Scalars["Timestamp"]["output"]>;
  currency?: Maybe<Scalars["String"]["output"]>;
  /** Only visible to the shop owner */
  currencySetting?: Maybe<Scalars["String"]["output"]>;
  /** Only visible to the shop owner */
  defaultLocale?: Maybe<Scalars["String"]["output"]>;
  description?: Maybe<Scalars["String"]["output"]>;
  euOdrAddress?: Maybe<core_apimessages_Address>;
  /** Only visible to the shop owner */
  firstTimeSeller?: Maybe<Scalars["Boolean"]["output"]>;
  freeDomesticShippingRate?: Maybe<core_apimessages_ShippingRates>;
  /** Only visible to the shop owner */
  hasAccountRep?: Maybe<Scalars["Boolean"]["output"]>;
  id?: Maybe<Scalars["String"]["output"]>;
  images?: Maybe<Array<Maybe<core_apimessages_Image>>>;
  /** Only visible to the shop owner */
  inclusiveVatPricingEnabled?: Maybe<Scalars["Boolean"]["output"]>;
  /** Only visible to the shop owner */
  isShopInEu?: Maybe<Scalars["Boolean"]["output"]>;
  /** Only visible to the shop owner */
  isShopInUk?: Maybe<Scalars["Boolean"]["output"]>;
  /** Only visible to the shop owner */
  listableConditions?: Maybe<Array<Maybe<core_apimessages_Condition>>>;
  lowballOfferPercentage?: Maybe<Scalars["Int"]["output"]>;
  manufacturersSafetyInformation?: Maybe<Scalars["String"]["output"]>;
  /** Only visible to the shop owner */
  marketplaceTaxReportingCountryCode?: Maybe<Scalars["String"]["output"]>;
  name?: Maybe<Scalars["String"]["output"]>;
  /** Only visible to the shop owner */
  offerPolicy?: Maybe<core_apimessages_ShopConfig_OfferPolicy>;
  onVacation?: Maybe<Scalars["Boolean"]["output"]>;
  orders?: Maybe<reverb_search_SearchResponse>;
  paymentPolicy?: Maybe<Scalars["String"]["output"]>;
  preferredSeller?: Maybe<Scalars["Boolean"]["output"]>;
  quickResponder?: Maybe<Scalars["Boolean"]["output"]>;
  quickShipper?: Maybe<Scalars["Boolean"]["output"]>;
  quickShipperSpeed?: Maybe<core_apimessages_Shop_QuickShipperSpeed>;
  /**
   * Unused by any client but needed in mobile apps RQL queries
   * @deprecated Unused by new clients. Required for legacy mobile app queries
   */
  recentlyShipped?: Maybe<Scalars["Boolean"]["output"]>;
  returnPolicy?: Maybe<core_apimessages_ReturnPolicy>;
  sales?: Maybe<Array<Maybe<core_apimessages_Sale>>>;
  /** Only visible to the shop owner */
  sameDayShippingConfigured?: Maybe<Scalars["Boolean"]["output"]>;
  shippingPolicy?: Maybe<Scalars["String"]["output"]>;
  shippingProfiles?: Maybe<Array<Maybe<core_apimessages_ShippingProfile>>>;
  /** Only visible to the shop owner */
  shippingRegionSettings?: Maybe<
    Array<Maybe<core_apimessages_ShippingRegionSetting>>
  >;
  /** Only visible to the shop owner */
  shopSupportedLocaleCodes?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
  shopType?: Maybe<core_apimessages_Shop_ShopType>;
  showSold?: Maybe<Scalars["Boolean"]["output"]>;
  slug?: Maybe<Scalars["String"]["output"]>;
  taxPolicies?: Maybe<Array<Maybe<core_apimessages_ShopTaxPolicy>>>;
  user?: Maybe<User>;
  userId?: Maybe<Scalars["String"]["output"]>;
  userUuid?: Maybe<Scalars["String"]["output"]>;
  uuid?: Maybe<Scalars["String"]["output"]>;
  vatId?: Maybe<Scalars["String"]["output"]>;
  video?: Maybe<core_apimessages_Video>;
  website?: Maybe<Scalars["String"]["output"]>;
};

export type ShopbannerArgs = {
  namedTransform?: InputMaybe<core_apimessages_NamedImageTransforms>;
};

export type ShopcertifiedPreOwnedArgs = {
  input?: InputMaybe<Input_core_apimessages_NodeRequest_Lookup_Params_ShopsCertifiedPreOwned>;
};

export type ShopimagesArgs = {
  input: Input_core_apimessages_ImagesRequest;
};

export type ShopCampaignCoupon = INode & {
  __typename?: "ShopCampaignCoupon";
  _id?: Maybe<Scalars["ID"]["output"]>;
  _source?: Maybe<Scalars["String"]["output"]>;
  applyToBStockConditions?: Maybe<Scalars["Boolean"]["output"]>;
  applyToNewConditions?: Maybe<Scalars["Boolean"]["output"]>;
  applyToUsedConditions?: Maybe<Scalars["Boolean"]["output"]>;
  code?: Maybe<Scalars["String"]["output"]>;
  couponHeader?: Maybe<Scalars["String"]["output"]>;
  descriptionSummary?: Maybe<Scalars["String"]["output"]>;
  discountValue?: Maybe<Scalars["String"]["output"]>;
  excludedBrands?: Maybe<Array<Maybe<Brand>>>;
  excludedCategories?: Maybe<Array<Maybe<Category>>>;
  expiresAt?: Maybe<Scalars["Timestamp"]["output"]>;
  id?: Maybe<Scalars["String"]["output"]>;
  restrictionsSummary?: Maybe<Scalars["String"]["output"]>;
  status?: Maybe<core_apimessages_ShopCampaignCoupon_Status>;
};

export type SiteBanner = INode & {
  __typename?: "SiteBanner";
  _id?: Maybe<Scalars["ID"]["output"]>;
  _source?: Maybe<Scalars["String"]["output"]>;
  callToAction?: Maybe<Scalars["String"]["output"]>;
  contentSponsorship?: Maybe<core_apimessages_ContentSponsorship>;
  downtime?: Maybe<Scalars["Boolean"]["output"]>;
  endsAt?: Maybe<Scalars["Timestamp"]["output"]>;
  hasPhotos?: Maybe<Scalars["Boolean"]["output"]>;
  heading?: Maybe<Scalars["String"]["output"]>;
  id?: Maybe<Scalars["String"]["output"]>;
  imageUrl?: Maybe<Scalars["String"]["output"]>;
  mobileImageUrl?: Maybe<Scalars["String"]["output"]>;
  mobileUrl?: Maybe<core_apimessages_Link>;
  mobileWebUrl?: Maybe<core_apimessages_Link>;
  showOnMobile?: Maybe<Scalars["Boolean"]["output"]>;
  showOnMyUpdates?: Maybe<Scalars["Boolean"]["output"]>;
  startsAt?: Maybe<Scalars["Timestamp"]["output"]>;
  subheading?: Maybe<Scalars["String"]["output"]>;
  url?: Maybe<core_apimessages_Link>;
  /** Deprecated */
  webOnly?: Maybe<Scalars["Boolean"]["output"]>;
};

export enum Sort {
  BEST_MATCH = "BEST_MATCH",
  MOST_RECENT_FIRST = "MOST_RECENT_FIRST",
  PRICE_HIGHEST_FIRST = "PRICE_HIGHEST_FIRST",
  PRICE_LOWEST_FIRST = "PRICE_LOWEST_FIRST",
  PROXIMITY = "PROXIMITY",
}

export type Storefront = INode & {
  __typename?: "Storefront";
  _id?: Maybe<Scalars["ID"]["output"]>;
  _source?: Maybe<Scalars["String"]["output"]>;
  banner?: Maybe<core_apimessages_Image>;
  channel?: Maybe<Scalars["String"]["output"]>;
  curatedSets?: Maybe<Array<Maybe<core_apimessages_CuratedSet>>>;
  description?: Maybe<Scalars["String"]["output"]>;
  id?: Maybe<Scalars["String"]["output"]>;
  name?: Maybe<Scalars["String"]["output"]>;
  returnPolicy?: Maybe<core_apimessages_ReturnPolicy>;
  shippingPolicy?: Maybe<Scalars["String"]["output"]>;
  shop?: Maybe<Shop>;
  shopUuid?: Maybe<Scalars["String"]["output"]>;
  slug?: Maybe<Scalars["String"]["output"]>;
};

export type TradeIn = {
  __typename?: "TradeIn";
  _id?: Maybe<Scalars["ID"]["output"]>;
  _source?: Maybe<Scalars["String"]["output"]>;
  /** Current trade in appraisal prices */
  appraisals?: Maybe<Array<Maybe<TradeInAppraisal>>>;
  canonicalProduct?: Maybe<core_apimessages_CanonicalProduct>;
  createdAt?: Maybe<Scalars["Timestamp"]["output"]>;
  dealer?: Maybe<Shop>;
  dealerAddress?: Maybe<core_apimessages_Address>;
  /** Whether the trade in appraisal prices have changed since it was created */
  haveAppraisalsChanged?: Maybe<Scalars["Boolean"]["output"]>;
  id?: Maybe<Scalars["String"]["output"]>;
  /** Appraisal prices when the trade in was created */
  originalAppraisals?: Maybe<Array<Maybe<TradeInAppraisal>>>;
  seller?: Maybe<User>;
  state?: Maybe<TradeInStatus>;
};

export type TradeInAppraisal = {
  __typename?: "TradeInAppraisal";
  _source?: Maybe<Scalars["String"]["output"]>;
  condition?: Maybe<core_apimessages_Condition>;
  price?: Maybe<core_apimessages_Money>;
};

export type TradeInEligibility = {
  __typename?: "TradeInEligibility";
  _source?: Maybe<Scalars["String"]["output"]>;
  appraisals?: Maybe<Array<Maybe<TradeInAppraisal>>>;
  dealers?: Maybe<Array<Maybe<Shop>>>;
  isEligible?: Maybe<Scalars["Boolean"]["output"]>;
};

export type TradeInEligibilityInput = {
  canonicalProductId?: InputMaybe<Scalars["String"]["input"]>;
  cspId?: InputMaybe<Scalars["String"]["input"]>;
};

export enum TradeInRejectionReason {
  CONDITION_NOT_MET = "CONDITION_NOT_MET",
  LISTING_INELIGIBLE = "LISTING_INELIGIBLE",
  SELLER_REJECTED = "SELLER_REJECTED",
}

export enum TradeInStatus {
  ACCEPTED = "ACCEPTED",
  PENDING = "PENDING",
  REJECTED = "REJECTED",
}

export type UniversalPromoCampaign = INode & {
  __typename?: "UniversalPromoCampaign";
  _id?: Maybe<Scalars["ID"]["output"]>;
  _source?: Maybe<Scalars["String"]["output"]>;
  categories?: Maybe<Array<Maybe<core_apimessages_NodesCategory>>>;
  code?: Maybe<Scalars["String"]["output"]>;
  expiresAt?: Maybe<Scalars["Timestamp"]["output"]>;
  hasRedemptionLimit?: Maybe<Scalars["Boolean"]["output"]>;
  id?: Maybe<Scalars["String"]["output"]>;
  lapsedBuyer?: Maybe<Scalars["Boolean"]["output"]>;
  lapsedBuyerLastPurchaseDate?: Maybe<Scalars["Timestamp"]["output"]>;
  marketingChannel?: Maybe<Scalars["String"]["output"]>;
  name?: Maybe<Scalars["String"]["output"]>;
  newBuyer?: Maybe<Scalars["Boolean"]["output"]>;
  redemptionLimit?: Maybe<Scalars["Int"]["output"]>;
  universalPromoCurrencies?: Maybe<
    Array<Maybe<core_apimessages_UniversalPromoCurrency>>
  >;
};

export type UniversalPromoCampaignCollection = {
  __typename?: "UniversalPromoCampaignCollection";
  _ids?: Maybe<Array<Maybe<Scalars["ID"]["output"]>>>;
  _source?: Maybe<Scalars["String"]["output"]>;
  nodes?: Maybe<Array<Maybe<UniversalPromoCampaign>>>;
  pageInfo?: Maybe<rql_PageInfo>;
};

export type User = INode & {
  __typename?: "User";
  _id?: Maybe<Scalars["ID"]["output"]>;
  _source?: Maybe<Scalars["String"]["output"]>;
  avatar?: Maybe<core_apimessages_Image>;
  feedback?: Maybe<core_apimessages_FeedbacksResponse>;
  feedbackSummary?: Maybe<core_apimessages_FeedbackSummary>;
  firstName?: Maybe<Scalars["String"]["output"]>;
  /** users.id */
  id?: Maybe<Scalars["String"]["output"]>;
  name?: Maybe<Scalars["String"]["output"]>;
  shop?: Maybe<core_apimessages_Shop>;
  shopUuid?: Maybe<Scalars["String"]["output"]>;
  shortname?: Maybe<Scalars["String"]["output"]>;
  uuid?: Maybe<Scalars["String"]["output"]>;
};

export type UserfeedbackArgs = {
  input: Input_core_apimessages_FeedbacksRequest;
};

export type UsershopArgs = {
  input?: InputMaybe<Input_core_apimessages_ShopsRequest>;
};

export type UserShopPermission = INode & {
  __typename?: "UserShopPermission";
  _id?: Maybe<Scalars["ID"]["output"]>;
  _source?: Maybe<Scalars["String"]["output"]>;
  email?: Maybe<Scalars["String"]["output"]>;
  id?: Maybe<Scalars["String"]["output"]>;
  permissionLevel?: Maybe<core_apimessages_UserShopPermission_PermissionLevel>;
  user?: Maybe<User>;
  userId?: Maybe<Scalars["String"]["output"]>;
};

export type Watch = INode & {
  __typename?: "Watch";
  _id?: Maybe<Scalars["ID"]["output"]>;
  _source?: Maybe<Scalars["String"]["output"]>;
  listing?: Maybe<Listing>;
};

export type WatchCollection = {
  __typename?: "WatchCollection";
  _ids?: Maybe<Array<Maybe<Scalars["ID"]["output"]>>>;
  _source?: Maybe<Scalars["String"]["output"]>;
  nodes?: Maybe<Array<Maybe<Watch>>>;
  pageInfo?: Maybe<rql_PageInfo>;
};

export type _Trace = {
  __typename?: "_Trace";
  enhancedTrace: Scalars["String"]["output"];
  explorer: Scalars["String"]["output"];
  trace: Scalars["String"]["output"];
};

export type arbiter_APIError = {
  __typename?: "arbiter_APIError";
  _source?: Maybe<Scalars["String"]["output"]>;
  message?: Maybe<Scalars["String"]["output"]>;
};

export type arbiter_APIErrorResponse = {
  __typename?: "arbiter_APIErrorResponse";
  _source?: Maybe<Scalars["String"]["output"]>;
  errors?: Maybe<Array<Maybe<arbiter_APIError>>>;
};

export type arbiter_Arbiter = {
  __typename?: "arbiter_Arbiter";
  BulkBumpInteractionCounts?: Maybe<arbiter_BulkBumpInteractionCountsResponse>;
  BulkFeaturedListing?: Maybe<arbiter_BulkFeaturedListingResponse>;
  BulkTrackImpressions?: Maybe<arbiter_BulkTrackImpressionsResponse>;
  BumpedSortedListings?: Maybe<arbiter_BumpedSortedListingsResponse>;
  FeaturedListing?: Maybe<arbiter_FeaturedListingResponse>;
  SellerInteractionTimeSeries?: Maybe<arbiter_SellerInteractionTimeSeriesResponse>;
  _source?: Maybe<Scalars["String"]["output"]>;
};

export type arbiter_ArbiterBulkBumpInteractionCountsArgs = {
  input?: InputMaybe<Input_arbiter_BulkBumpInteractionCountsRequest>;
};

export type arbiter_ArbiterBulkFeaturedListingArgs = {
  input?: InputMaybe<Input_arbiter_BulkFeaturedListingRequest>;
};

export type arbiter_ArbiterBulkTrackImpressionsArgs = {
  input?: InputMaybe<Input_arbiter_BulkTrackImpressionsRequest>;
};

export type arbiter_ArbiterBumpedSortedListingsArgs = {
  input?: InputMaybe<Input_arbiter_BumpedSortedListingsRequest>;
};

export type arbiter_ArbiterFeaturedListingArgs = {
  input?: InputMaybe<Input_arbiter_FeaturedListingRequest>;
};

export type arbiter_ArbiterSellerInteractionTimeSeriesArgs = {
  input?: InputMaybe<Input_arbiter_SellerInteractionTimeSeriesRequest>;
};

export enum arbiter_AuctionType {
  ALL_INCLUSIVE = "ALL_INCLUSIVE",
  CONDITION_INCLUSIVE = "CONDITION_INCLUSIVE",
  CSP = "CSP",
  EXPERIMENTAL_CSP = "EXPERIMENTAL_CSP",
  EXPERIMENTAL_FEATURED_LISTING = "EXPERIMENTAL_FEATURED_LISTING",
}

export type arbiter_BulkBumpInteractionCountsRequest = {
  __typename?: "arbiter_BulkBumpInteractionCountsRequest";
  _source?: Maybe<Scalars["String"]["output"]>;
  requests?: Maybe<Array<Maybe<arbiter_BumpInteractionCountsRequest>>>;
};

export type arbiter_BulkBumpInteractionCountsResponse = {
  __typename?: "arbiter_BulkBumpInteractionCountsResponse";
  _source?: Maybe<Scalars["String"]["output"]>;
  responses?: Maybe<Array<Maybe<arbiter_BumpInteractionCountsResponse>>>;
};

export type arbiter_BulkFeaturedListingRequest = {
  __typename?: "arbiter_BulkFeaturedListingRequest";
  _source?: Maybe<Scalars["String"]["output"]>;
  requests?: Maybe<Array<Maybe<arbiter_FeaturedListingRequest>>>;
};

export type arbiter_BulkFeaturedListingResponse = {
  __typename?: "arbiter_BulkFeaturedListingResponse";
  _source?: Maybe<Scalars["String"]["output"]>;
  responses?: Maybe<Array<Maybe<arbiter_FeaturedListingResponse>>>;
};

export type arbiter_BulkTrackImpressionsRequest = {
  __typename?: "arbiter_BulkTrackImpressionsRequest";
  _source?: Maybe<Scalars["String"]["output"]>;
  listingIds?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
};

export type arbiter_BulkTrackImpressionsResponse = {
  __typename?: "arbiter_BulkTrackImpressionsResponse";
  _source?: Maybe<Scalars["String"]["output"]>;
  listingIds?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
};

export type arbiter_BumpInteractionCountsRequest = {
  __typename?: "arbiter_BumpInteractionCountsRequest";
  _source?: Maybe<Scalars["String"]["output"]>;
  listingId?: Maybe<Scalars["String"]["output"]>;
  sellerId?: Maybe<Scalars["String"]["output"]>;
};

export type arbiter_BumpInteractionCountsResponse = {
  __typename?: "arbiter_BumpInteractionCountsResponse";
  _source?: Maybe<Scalars["String"]["output"]>;
  addToCart?: Maybe<Scalars["Int"]["output"]>;
  addToWatchlist?: Maybe<Scalars["Int"]["output"]>;
  click?: Maybe<Scalars["Int"]["output"]>;
  listingId?: Maybe<Scalars["String"]["output"]>;
  makeAnOffer?: Maybe<Scalars["Int"]["output"]>;
  messageSeller?: Maybe<Scalars["Int"]["output"]>;
  total?: Maybe<Scalars["Int"]["output"]>;
};

export type arbiter_BumpedSortedListingResponseListing = {
  __typename?: "arbiter_BumpedSortedListingResponseListing";
  _source?: Maybe<Scalars["String"]["output"]>;
  bid?: Maybe<Scalars["Float"]["output"]>;
  bumped?: Maybe<Scalars["Boolean"]["output"]>;
  discountPercent?: Maybe<Scalars["Int"]["output"]>;
  productId?: Maybe<Scalars["String"]["output"]>;
  qualityScore?: Maybe<Scalars["Float"]["output"]>;
  rank?: Maybe<Scalars["Float"]["output"]>;
};

export type arbiter_BumpedSortedListingsRequest = {
  __typename?: "arbiter_BumpedSortedListingsRequest";
  _source?: Maybe<Scalars["String"]["output"]>;
  auctionType?: Maybe<arbiter_AuctionType>;
  bumpedMax?: Maybe<Scalars["Int"]["output"]>;
  condition?: Maybe<Scalars["String"]["output"]>;
  itemRegion?: Maybe<Scalars["String"]["output"]>;
  listingIds?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
  total?: Maybe<Scalars["Int"]["output"]>;
  userShippingRegion?: Maybe<Scalars["String"]["output"]>;
};

export type arbiter_BumpedSortedListingsResponse = {
  __typename?: "arbiter_BumpedSortedListingsResponse";
  _source?: Maybe<Scalars["String"]["output"]>;
  products?: Maybe<Array<Maybe<arbiter_BumpedSortedListingResponseListing>>>;
  resultIds?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
};

export type arbiter_CreateInteractionRequest = {
  __typename?: "arbiter_CreateInteractionRequest";
  _source?: Maybe<Scalars["String"]["output"]>;
  interaction?: Maybe<arbiter_Interaction>;
};

export type arbiter_FeaturedListingRequest = {
  __typename?: "arbiter_FeaturedListingRequest";
  _source?: Maybe<Scalars["String"]["output"]>;
  applyProximityBoost?: Maybe<Scalars["Boolean"]["output"]>;
  auctionType?: Maybe<arbiter_AuctionType>;
  canonicalFinish?: Maybe<Scalars["String"]["output"]>;
  categoryUuids?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
  conditionUuids?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
  cspId?: Maybe<Scalars["String"]["output"]>;
  cspSlug?: Maybe<Scalars["String"]["output"]>;
  deterministic?: Maybe<Scalars["Boolean"]["output"]>;
  includeListingsOutsideLowestPriceWindow?: Maybe<Scalars["Boolean"]["output"]>;
  isUserInCspOptimizationExp?: Maybe<Scalars["Boolean"]["output"]>;
  itemRegion?: Maybe<Scalars["String"]["output"]>;
  listingIds?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
  traitValues?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
  userCurrency?: Maybe<Scalars["String"]["output"]>;
  userShippingRegion?: Maybe<Scalars["String"]["output"]>;
};

export type arbiter_FeaturedListingResponse = {
  __typename?: "arbiter_FeaturedListingResponse";
  _source?: Maybe<Scalars["String"]["output"]>;
  bid?: Maybe<Scalars["Float"]["output"]>;
  bumped?: Maybe<Scalars["Boolean"]["output"]>;
  listing?: Maybe<Listing>;
  productId?: Maybe<Scalars["String"]["output"]>;
  qualityScore?: Maybe<Scalars["Float"]["output"]>;
  rank?: Maybe<Scalars["Float"]["output"]>;
  runnerUp?: Maybe<arbiter_FeaturedListingResponse>;
};

export type arbiter_FindInteractionRequest = {
  __typename?: "arbiter_FindInteractionRequest";
  _source?: Maybe<Scalars["String"]["output"]>;
  cookieId?: Maybe<Scalars["String"]["output"]>;
  orderedAt?: Maybe<Scalars["Timestamp"]["output"]>;
  productId?: Maybe<Scalars["String"]["output"]>;
  userId?: Maybe<Scalars["String"]["output"]>;
};

export type arbiter_FindInteractionResponse = {
  __typename?: "arbiter_FindInteractionResponse";
  _source?: Maybe<Scalars["String"]["output"]>;
  interaction?: Maybe<arbiter_Interaction>;
};

export type arbiter_Interaction = {
  __typename?: "arbiter_Interaction";
  _source?: Maybe<Scalars["String"]["output"]>;
  bumpRate?: Maybe<Scalars["String"]["output"]>;
  componentName?: Maybe<Scalars["String"]["output"]>;
  cookieId?: Maybe<Scalars["String"]["output"]>;
  createdAt?: Maybe<Scalars["Timestamp"]["output"]>;
  currentUrl?: Maybe<Scalars["String"]["output"]>;
  interactionType?: Maybe<arbiter_Interaction_InteractionType>;
  issuedAt?: Maybe<Scalars["Timestamp"]["output"]>;
  offsiteSource?: Maybe<Scalars["String"]["output"]>;
  position?: Maybe<Scalars["Int"]["output"]>;
  productId?: Maybe<Scalars["String"]["output"]>;
  referrerUrl?: Maybe<Scalars["String"]["output"]>;
  sellerId?: Maybe<Scalars["String"]["output"]>;
  sessionId?: Maybe<Scalars["String"]["output"]>;
  userDeviceName?: Maybe<Scalars["String"]["output"]>;
  userExperimentsJson?: Maybe<Scalars["String"]["output"]>;
  userId?: Maybe<Scalars["String"]["output"]>;
  uuid?: Maybe<Scalars["String"]["output"]>;
};

export type arbiter_InteractionTimeSeriesMetric = {
  __typename?: "arbiter_InteractionTimeSeriesMetric";
  _source?: Maybe<Scalars["String"]["output"]>;
  addToCart?: Maybe<Scalars["Int"]["output"]>;
  addToWatchlist?: Maybe<Scalars["Int"]["output"]>;
  click?: Maybe<Scalars["Int"]["output"]>;
  date?: Maybe<Scalars["String"]["output"]>;
  makeAnOffer?: Maybe<Scalars["Int"]["output"]>;
  messageSeller?: Maybe<Scalars["Int"]["output"]>;
  total?: Maybe<Scalars["Int"]["output"]>;
};

export enum arbiter_Interaction_InteractionType {
  ADD_TO_CART = "ADD_TO_CART",
  ADD_TO_WATCHLIST = "ADD_TO_WATCHLIST",
  CLICK = "CLICK",
  MAKE_AN_OFFER = "MAKE_AN_OFFER",
  MESSAGE_SELLER = "MESSAGE_SELLER",
}

export type arbiter_Listing = {
  __typename?: "arbiter_Listing";
  _source?: Maybe<Scalars["String"]["output"]>;
  id?: Maybe<Scalars["String"]["output"]>;
};

export type arbiter_SellerInteractionTimeSeriesRequest = {
  __typename?: "arbiter_SellerInteractionTimeSeriesRequest";
  _source?: Maybe<Scalars["String"]["output"]>;
  endDate?: Maybe<Scalars["String"]["output"]>;
  sellerId?: Maybe<Scalars["String"]["output"]>;
  startDate?: Maybe<Scalars["String"]["output"]>;
};

export type arbiter_SellerInteractionTimeSeriesResponse = {
  __typename?: "arbiter_SellerInteractionTimeSeriesResponse";
  _source?: Maybe<Scalars["String"]["output"]>;
  addToCart?: Maybe<Scalars["Int"]["output"]>;
  addToWatchlist?: Maybe<Scalars["Int"]["output"]>;
  click?: Maybe<Scalars["Int"]["output"]>;
  data?: Maybe<Array<Maybe<arbiter_InteractionTimeSeriesMetric>>>;
  makeAnOffer?: Maybe<Scalars["Int"]["output"]>;
  messageSeller?: Maybe<Scalars["Int"]["output"]>;
  total?: Maybe<Scalars["Int"]["output"]>;
};

export type braze_Braze = {
  __typename?: "braze_Braze";
  GetSubscriptionGroups?: Maybe<braze_GetSubscriptionGroupsResponse>;
  GetUserData?: Maybe<braze_GetUserDataResponse>;
  SetSubscriptionGroups?: Maybe<braze_SetSubscriptionGroupsResponse>;
  TriggerCampaignSend?: Maybe<braze_TriggerCampaignSendResponse>;
  UserTrack?: Maybe<braze_UserTrackResponse>;
  _source?: Maybe<Scalars["String"]["output"]>;
};

export type braze_BrazeGetSubscriptionGroupsArgs = {
  input?: InputMaybe<Input_braze_GetSubscriptionGroupsRequest>;
};

export type braze_BrazeGetUserDataArgs = {
  input?: InputMaybe<Input_braze_GetUserDataRequest>;
};

export type braze_BrazeSetSubscriptionGroupsArgs = {
  input?: InputMaybe<Input_braze_SetSubscriptionGroupsRequest>;
};

export type braze_BrazeTriggerCampaignSendArgs = {
  input?: InputMaybe<Input_braze_TriggerCampaignSendRequest>;
};

export type braze_BrazeUserTrackArgs = {
  input?: InputMaybe<Input_braze_UserTrackRequest>;
};

export type braze_BrazeUser = {
  __typename?: "braze_BrazeUser";
  _source?: Maybe<Scalars["String"]["output"]>;
  email?: Maybe<Scalars["String"]["output"]>;
  emailSubscribe?: Maybe<Scalars["String"]["output"]>;
  externalId?: Maybe<Scalars["String"]["output"]>;
  pushSubscribe?: Maybe<Scalars["String"]["output"]>;
};

export type braze_GetSubscriptionGroupsRequest = {
  __typename?: "braze_GetSubscriptionGroupsRequest";
  _source?: Maybe<Scalars["String"]["output"]>;
  email?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
  externalId?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
};

export type braze_GetSubscriptionGroupsResponse = {
  __typename?: "braze_GetSubscriptionGroupsResponse";
  _source?: Maybe<Scalars["String"]["output"]>;
  message?: Maybe<Scalars["String"]["output"]>;
  totalCount?: Maybe<Scalars["Int"]["output"]>;
  users?: Maybe<Array<Maybe<braze_SubscriptionUser>>>;
};

export type braze_GetUserDataRequest = {
  __typename?: "braze_GetUserDataRequest";
  _source?: Maybe<Scalars["String"]["output"]>;
  externalIds?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
  fieldsToExport?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
};

export type braze_GetUserDataResponse = {
  __typename?: "braze_GetUserDataResponse";
  _source?: Maybe<Scalars["String"]["output"]>;
  invalidUserIds?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
  message?: Maybe<Scalars["String"]["output"]>;
  users?: Maybe<Array<Maybe<braze_BrazeUser>>>;
};

export type braze_OptInRecipient = {
  __typename?: "braze_OptInRecipient";
  _source?: Maybe<Scalars["String"]["output"]>;
  externalUserId?: Maybe<Scalars["String"]["output"]>;
};

export type braze_SetSubscriptionGroupsRequest = {
  __typename?: "braze_SetSubscriptionGroupsRequest";
  _source?: Maybe<Scalars["String"]["output"]>;
  email?: Maybe<Scalars["String"]["output"]>;
  externalId?: Maybe<Scalars["String"]["output"]>;
  subscriptionGroupId?: Maybe<Scalars["String"]["output"]>;
  subscriptionState?: Maybe<Scalars["String"]["output"]>;
};

export type braze_SetSubscriptionGroupsResponse = {
  __typename?: "braze_SetSubscriptionGroupsResponse";
  _source?: Maybe<Scalars["String"]["output"]>;
  message?: Maybe<Scalars["String"]["output"]>;
};

export type braze_SubscriptionGroupConfig = {
  __typename?: "braze_SubscriptionGroupConfig";
  _source?: Maybe<Scalars["String"]["output"]>;
  channel?: Maybe<Scalars["String"]["output"]>;
  id?: Maybe<Scalars["String"]["output"]>;
  name?: Maybe<Scalars["String"]["output"]>;
  status?: Maybe<Scalars["String"]["output"]>;
};

export type braze_SubscriptionUser = {
  __typename?: "braze_SubscriptionUser";
  _source?: Maybe<Scalars["String"]["output"]>;
  email?: Maybe<Scalars["String"]["output"]>;
  externalId?: Maybe<Scalars["String"]["output"]>;
  phone?: Maybe<Scalars["String"]["output"]>;
  subscriptionGroups?: Maybe<Array<Maybe<braze_SubscriptionGroupConfig>>>;
};

export type braze_TriggerCampaignSendRequest = {
  __typename?: "braze_TriggerCampaignSendRequest";
  _source?: Maybe<Scalars["String"]["output"]>;
  campaignId?: Maybe<Scalars["String"]["output"]>;
  recipients?: Maybe<Array<Maybe<braze_OptInRecipient>>>;
};

export type braze_TriggerCampaignSendResponse = {
  __typename?: "braze_TriggerCampaignSendResponse";
  _source?: Maybe<Scalars["String"]["output"]>;
  dispatchId?: Maybe<Scalars["String"]["output"]>;
  message?: Maybe<Scalars["String"]["output"]>;
};

export type braze_UserAttributes = {
  __typename?: "braze_UserAttributes";
  UpdateExistingOnly?: Maybe<Scalars["Boolean"]["output"]>;
  _source?: Maybe<Scalars["String"]["output"]>;
  brazeId?: Maybe<Scalars["String"]["output"]>;
  emailSubscribe?: Maybe<Scalars["String"]["output"]>;
  externalId?: Maybe<Scalars["String"]["output"]>;
  pushSubscribe?: Maybe<Scalars["String"]["output"]>;
};

export type braze_UserTrackRequest = {
  __typename?: "braze_UserTrackRequest";
  _source?: Maybe<Scalars["String"]["output"]>;
  attributes?: Maybe<Array<Maybe<braze_UserAttributes>>>;
};

export type braze_UserTrackResponse = {
  __typename?: "braze_UserTrackResponse";
  _source?: Maybe<Scalars["String"]["output"]>;
  message?: Maybe<Scalars["String"]["output"]>;
};

export type cms_AdminClonePageRequest = {
  __typename?: "cms_AdminClonePageRequest";
  _source?: Maybe<Scalars["String"]["output"]>;
  slug?: Maybe<Scalars["String"]["output"]>;
};

export type cms_AdminClonePageResponse = {
  __typename?: "cms_AdminClonePageResponse";
  _source?: Maybe<Scalars["String"]["output"]>;
  clonedPageSlug?: Maybe<Scalars["String"]["output"]>;
};

export type cms_AdminComponentsResponse = {
  __typename?: "cms_AdminComponentsResponse";
  _source?: Maybe<Scalars["String"]["output"]>;
  components?: Maybe<Array<Maybe<cms_Component>>>;
};

export type cms_AdminCreatePage = {
  __typename?: "cms_AdminCreatePage";
  _source?: Maybe<Scalars["String"]["output"]>;
  authorId?: Maybe<Scalars["String"]["output"]>;
  channels?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
  componentIds?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
  darkModeReady?: Maybe<Scalars["Boolean"]["output"]>;
  goLiveAt?: Maybe<Scalars["String"]["output"]>;
  headerStyle?: Maybe<Scalars["String"]["output"]>;
  id?: Maybe<Scalars["String"]["output"]>;
  imageAttributionUri?: Maybe<Scalars["String"]["output"]>;
  imageKey?: Maybe<Scalars["String"]["output"]>;
  layoutId?: Maybe<Scalars["String"]["output"]>;
  localizedContents?: Maybe<Array<Maybe<cms_PageLocalizedContent>>>;
  metaImageKey?: Maybe<Scalars["String"]["output"]>;
  noIndex?: Maybe<Scalars["Boolean"]["output"]>;
  pageTemplateId?: Maybe<Scalars["String"]["output"]>;
  pageType?: Maybe<Scalars["String"]["output"]>;
  secondaryImageKey?: Maybe<Scalars["String"]["output"]>;
  slug?: Maybe<Scalars["String"]["output"]>;
};

export type cms_AdminCreatePageTemplate = {
  __typename?: "cms_AdminCreatePageTemplate";
  _source?: Maybe<Scalars["String"]["output"]>;
  customVariables?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
  id?: Maybe<Scalars["String"]["output"]>;
  layoutId?: Maybe<Scalars["String"]["output"]>;
  name?: Maybe<Scalars["String"]["output"]>;
};

export type cms_AdminCreatePageTemplateRequest = {
  __typename?: "cms_AdminCreatePageTemplateRequest";
  _source?: Maybe<Scalars["String"]["output"]>;
  pageTemplate?: Maybe<cms_AdminCreatePageTemplate>;
};

export type cms_AdminDeletePageRequest = {
  __typename?: "cms_AdminDeletePageRequest";
  _source?: Maybe<Scalars["String"]["output"]>;
  id?: Maybe<Scalars["String"]["output"]>;
};

export type cms_AdminPageCreateRequest = {
  __typename?: "cms_AdminPageCreateRequest";
  _source?: Maybe<Scalars["String"]["output"]>;
  page?: Maybe<cms_AdminCreatePage>;
};

export type cms_AdminPageIndexRequest = {
  __typename?: "cms_AdminPageIndexRequest";
  _source?: Maybe<Scalars["String"]["output"]>;
  channels?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
  ids?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
  page?: Maybe<Scalars["String"]["output"]>;
  pageType?: Maybe<Scalars["String"]["output"]>;
  perPage?: Maybe<Scalars["String"]["output"]>;
  q?: Maybe<Scalars["String"]["output"]>;
};

export type cms_AdminPageTemplateIndexRequest = {
  __typename?: "cms_AdminPageTemplateIndexRequest";
  _source?: Maybe<Scalars["String"]["output"]>;
  ids?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
  page?: Maybe<Scalars["String"]["output"]>;
  perPage?: Maybe<Scalars["String"]["output"]>;
  q?: Maybe<Scalars["String"]["output"]>;
};

export type cms_AdminPagesResponse = {
  __typename?: "cms_AdminPagesResponse";
  _source?: Maybe<Scalars["String"]["output"]>;
  meta?: Maybe<cms_Pagination>;
  pages?: Maybe<Array<Maybe<cms_Page>>>;
};

export type cms_AdminTakeSlugRequest = {
  __typename?: "cms_AdminTakeSlugRequest";
  _source?: Maybe<Scalars["String"]["output"]>;
  currentSlug?: Maybe<Scalars["String"]["output"]>;
  desiredSlug?: Maybe<Scalars["String"]["output"]>;
};

export type cms_AdminTakeSlugResponse = {
  __typename?: "cms_AdminTakeSlugResponse";
  _source?: Maybe<Scalars["String"]["output"]>;
  legacySlug?: Maybe<Scalars["String"]["output"]>;
  updatedSlug?: Maybe<Scalars["String"]["output"]>;
};

export type cms_Author = {
  __typename?: "cms_Author";
  _source?: Maybe<Scalars["String"]["output"]>;
  id?: Maybe<Scalars["String"]["output"]>;
  name?: Maybe<Scalars["String"]["output"]>;
  slug?: Maybe<Scalars["String"]["output"]>;
};

export type cms_AuthorIndexResponse = {
  __typename?: "cms_AuthorIndexResponse";
  _source?: Maybe<Scalars["String"]["output"]>;
  authors?: Maybe<Array<Maybe<cms_Author>>>;
};

export type cms_AuthorRequest = {
  __typename?: "cms_AuthorRequest";
  _source?: Maybe<Scalars["String"]["output"]>;
  author?: Maybe<cms_Author>;
};

export type cms_AuthorResponse = {
  __typename?: "cms_AuthorResponse";
  _source?: Maybe<Scalars["String"]["output"]>;
  author?: Maybe<cms_Author>;
};

export type cms_BulkComponentRequest = {
  __typename?: "cms_BulkComponentRequest";
  _source?: Maybe<Scalars["String"]["output"]>;
  components?: Maybe<Array<Maybe<cms_Component>>>;
};

export type cms_BulkComponentResponse = {
  __typename?: "cms_BulkComponentResponse";
  _source?: Maybe<Scalars["String"]["output"]>;
  components?: Maybe<Array<Maybe<cms_Component>>>;
};

export type cms_BulkPagination = {
  __typename?: "cms_BulkPagination";
  _source?: Maybe<Scalars["String"]["output"]>;
  limit?: Maybe<Scalars["Int"]["output"]>;
  offset?: Maybe<Scalars["Int"]["output"]>;
  total?: Maybe<Scalars["Int"]["output"]>;
};

export type cms_Component = {
  __typename?: "cms_Component";
  _source?: Maybe<Scalars["String"]["output"]>;
  active?: Maybe<Scalars["Boolean"]["output"]>;
  blacklistCountries?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
  componentType?: Maybe<Scalars["String"]["output"]>;
  /** 'default' or 'experiment' */
  experimentBucket?: Maybe<Scalars["String"]["output"]>;
  experimentKey?: Maybe<Scalars["String"]["output"]>;
  id?: Maybe<Scalars["String"]["output"]>;
  layoutSection?: Maybe<Scalars["String"]["output"]>;
  localizedContents?: Maybe<Array<Maybe<cms_ComponentLocalizedContent>>>;
  pageId?: Maybe<Scalars["String"]["output"]>;
  pageTemplateId?: Maybe<Scalars["String"]["output"]>;
  position?: Maybe<Scalars["Int"]["output"]>;
  targetCountries?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
  type?: Maybe<Scalars["String"]["output"]>;
};

export type cms_ComponentDeleteRequest = {
  __typename?: "cms_ComponentDeleteRequest";
  _source?: Maybe<Scalars["String"]["output"]>;
  id?: Maybe<Scalars["String"]["output"]>;
};

export type cms_ComponentLocalizedContent = {
  __typename?: "cms_ComponentLocalizedContent";
  _source?: Maybe<Scalars["String"]["output"]>;
  data?: Maybe<Scalars["String"]["output"]>;
  locale?: Maybe<Scalars["String"]["output"]>;
};

export type cms_ComponentRequest = {
  __typename?: "cms_ComponentRequest";
  _source?: Maybe<Scalars["String"]["output"]>;
  component?: Maybe<cms_Component>;
};

export type cms_ComponentResponse = {
  __typename?: "cms_ComponentResponse";
  _source?: Maybe<Scalars["String"]["output"]>;
  component?: Maybe<cms_Component>;
};

export type cms_ComponentTemplatesRequest = {
  __typename?: "cms_ComponentTemplatesRequest";
  _source?: Maybe<Scalars["String"]["output"]>;
  layoutSection?: Maybe<Scalars["String"]["output"]>;
  pageTemplateId?: Maybe<Scalars["String"]["output"]>;
};

export type cms_ComponentsRequest = {
  __typename?: "cms_ComponentsRequest";
  _source?: Maybe<Scalars["String"]["output"]>;
  ids?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
  layoutSection?: Maybe<Scalars["String"]["output"]>;
  pageId?: Maybe<Scalars["String"]["output"]>;
};

export type cms_Layout = {
  __typename?: "cms_Layout";
  _source?: Maybe<Scalars["String"]["output"]>;
  id?: Maybe<Scalars["String"]["output"]>;
  name?: Maybe<Scalars["String"]["output"]>;
  sections?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
};

export type cms_LayoutsIndexRequest = {
  __typename?: "cms_LayoutsIndexRequest";
  _source?: Maybe<Scalars["String"]["output"]>;
  ids?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
  q?: Maybe<Scalars["String"]["output"]>;
};

export type cms_LayoutsResponse = {
  __typename?: "cms_LayoutsResponse";
  _source?: Maybe<Scalars["String"]["output"]>;
  layouts?: Maybe<Array<Maybe<cms_Layout>>>;
};

export type cms_NodeRequest = {
  __typename?: "cms_NodeRequest";
  _source?: Maybe<Scalars["String"]["output"]>;
  lookups?: Maybe<Array<Maybe<cms_NodeRequest_Lookup>>>;
};

export type cms_NodeRequest_Lookup = {
  __typename?: "cms_NodeRequest_Lookup";
  _source?: Maybe<Scalars["String"]["output"]>;
  lid?: Maybe<Scalars["String"]["output"]>;
  rel?: Maybe<Scalars["String"]["output"]>;
};

export type cms_NodeResponse = {
  __typename?: "cms_NodeResponse";
  _source?: Maybe<Scalars["String"]["output"]>;
  results?: Maybe<Array<Maybe<cms_NodeResponse_Result>>>;
};

export type cms_NodeResponse_Result = {
  __typename?: "cms_NodeResponse_Result";
  _source?: Maybe<Scalars["String"]["output"]>;
  lookup?: Maybe<cms_NodeRequest_Lookup>;
  meta?: Maybe<cms_NodeResponse_Result_Meta>;
  publicComponents?: Maybe<Array<Maybe<cms_PublicComponent>>>;
};

export type cms_NodeResponse_Result_Meta = {
  __typename?: "cms_NodeResponse_Result_Meta";
  _source?: Maybe<Scalars["String"]["output"]>;
  total?: Maybe<Scalars["Int"]["output"]>;
};

export type cms_Page = {
  __typename?: "cms_Page";
  _source?: Maybe<Scalars["String"]["output"]>;
  authorId?: Maybe<Scalars["String"]["output"]>;
  channels?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
  componentIds?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
  darkModeReady?: Maybe<Scalars["Boolean"]["output"]>;
  goLiveAt?: Maybe<Scalars["String"]["output"]>;
  headerStyle?: Maybe<Scalars["String"]["output"]>;
  id?: Maybe<Scalars["String"]["output"]>;
  imageAttributionUri?: Maybe<Scalars["String"]["output"]>;
  imageKey?: Maybe<Scalars["String"]["output"]>;
  layoutId?: Maybe<Scalars["String"]["output"]>;
  localizedContents?: Maybe<Array<Maybe<cms_PageLocalizedContent>>>;
  metaImageKey?: Maybe<Scalars["String"]["output"]>;
  noIndex?: Maybe<Scalars["Boolean"]["output"]>;
  pageTemplateId?: Maybe<Scalars["String"]["output"]>;
  pageType?: Maybe<Scalars["String"]["output"]>;
  secondaryImageKey?: Maybe<Scalars["String"]["output"]>;
  slug?: Maybe<Scalars["String"]["output"]>;
};

export type cms_PageImage = {
  __typename?: "cms_PageImage";
  _source?: Maybe<Scalars["String"]["output"]>;
  imageUrl?: Maybe<Scalars["String"]["output"]>;
};

export type cms_PageLocalizedContent = {
  __typename?: "cms_PageLocalizedContent";
  _source?: Maybe<Scalars["String"]["output"]>;
  actionText?: Maybe<Scalars["String"]["output"]>;
  actionUrl?: Maybe<Scalars["String"]["output"]>;
  breadcrumbs?: Maybe<Scalars["String"]["output"]>;
  customVariableData?: Maybe<Scalars["String"]["output"]>;
  description?: Maybe<Scalars["String"]["output"]>;
  locale?: Maybe<Scalars["String"]["output"]>;
  metaDescription?: Maybe<Scalars["String"]["output"]>;
  metaTitle?: Maybe<Scalars["String"]["output"]>;
  searchPlaceholder?: Maybe<Scalars["String"]["output"]>;
  title?: Maybe<Scalars["String"]["output"]>;
};

export type cms_PageRequest = {
  __typename?: "cms_PageRequest";
  _source?: Maybe<Scalars["String"]["output"]>;
  page?: Maybe<cms_PublicPage>;
};

export type cms_PageResponse = {
  __typename?: "cms_PageResponse";
  _source?: Maybe<Scalars["String"]["output"]>;
  page?: Maybe<cms_Page>;
};

export type cms_PageTemplate = {
  __typename?: "cms_PageTemplate";
  _source?: Maybe<Scalars["String"]["output"]>;
  customVariables?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
  id?: Maybe<Scalars["String"]["output"]>;
  layoutId?: Maybe<Scalars["String"]["output"]>;
  name?: Maybe<Scalars["String"]["output"]>;
};

export type cms_PageTemplateIndexResponse = {
  __typename?: "cms_PageTemplateIndexResponse";
  _source?: Maybe<Scalars["String"]["output"]>;
  meta?: Maybe<cms_Pagination>;
  pageTemplates?: Maybe<Array<Maybe<cms_PageTemplate>>>;
};

export type cms_PageTemplatePageInfo = {
  __typename?: "cms_PageTemplatePageInfo";
  _source?: Maybe<Scalars["String"]["output"]>;
  pageCount?: Maybe<Scalars["Int"]["output"]>;
  pageTemplateId?: Maybe<Scalars["String"]["output"]>;
  pageTitles?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
};

export type cms_PageTemplatePageInfoResponse = {
  __typename?: "cms_PageTemplatePageInfoResponse";
  _source?: Maybe<Scalars["String"]["output"]>;
  pageTemplatePageInfo?: Maybe<Array<Maybe<cms_PageTemplatePageInfo>>>;
};

export type cms_PageTemplateResponse = {
  __typename?: "cms_PageTemplateResponse";
  _source?: Maybe<Scalars["String"]["output"]>;
  pageTemplate?: Maybe<cms_PageTemplate>;
};

export type cms_PagesRequest = {
  __typename?: "cms_PagesRequest";
  _source?: Maybe<Scalars["String"]["output"]>;
  channels?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
  excludeBlacklistedEmbeds?: Maybe<Scalars["Boolean"]["output"]>;
  ids?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
  includeDrafts?: Maybe<Scalars["String"]["output"]>;
  limit?: Maybe<Scalars["String"]["output"]>;
  offset?: Maybe<Scalars["String"]["output"]>;
  pageType?: Maybe<Scalars["String"]["output"]>;
  pageTypes?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
  query?: Maybe<Scalars["String"]["output"]>;
  slugs?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
};

export type cms_PagesResponse = {
  __typename?: "cms_PagesResponse";
  _source?: Maybe<Scalars["String"]["output"]>;
  meta?: Maybe<cms_BulkPagination>;
  pages?: Maybe<Array<Maybe<cms_PublicPage>>>;
};

export type cms_Pagination = {
  __typename?: "cms_Pagination";
  _source?: Maybe<Scalars["String"]["output"]>;
  currentPage?: Maybe<Scalars["Int"]["output"]>;
  perPage?: Maybe<Scalars["Int"]["output"]>;
  total?: Maybe<Scalars["Int"]["output"]>;
};

export type cms_PublicAPI = {
  __typename?: "cms_PublicAPI";
  GetPages?: Maybe<cms_PagesResponse>;
  NodesPublicComponents?: Maybe<cms_NodeResponse>;
  _source?: Maybe<Scalars["String"]["output"]>;
};

export type cms_PublicAPIGetPagesArgs = {
  input?: InputMaybe<Input_cms_PagesRequest>;
};

export type cms_PublicAPINodesPublicComponentsArgs = {
  input?: InputMaybe<Input_cms_NodeRequest>;
};

export type cms_PublicComponent = {
  __typename?: "cms_PublicComponent";
  _source?: Maybe<Scalars["String"]["output"]>;
  active?: Maybe<Scalars["Boolean"]["output"]>;
  componentType?: Maybe<Scalars["String"]["output"]>;
  data?: Maybe<Scalars["String"]["output"]>;
  /** 'default' or 'experiment' */
  experimentBucket?: Maybe<Scalars["String"]["output"]>;
  /** cms_recently_viewed  cms_used_first */
  experimentKey?: Maybe<Scalars["String"]["output"]>;
  id?: Maybe<Scalars["String"]["output"]>;
  layoutSection?: Maybe<Scalars["String"]["output"]>;
  pageId?: Maybe<Scalars["String"]["output"]>;
  position?: Maybe<Scalars["Int"]["output"]>;
  searchSort?: Maybe<Scalars["String"]["output"]>;
  searchType?: Maybe<cms_PublicComponent_SearchType>;
  searchUrlParams?: Maybe<Scalars["String"]["output"]>;
  subtitle?: Maybe<Scalars["String"]["output"]>;
  title?: Maybe<Scalars["String"]["output"]>;
};

export enum cms_PublicComponent_SearchType {
  CSP = "CSP",
  LISTING = "LISTING",
  NONE = "NONE",
}

export type cms_PublicPage = {
  __typename?: "cms_PublicPage";
  _source?: Maybe<Scalars["String"]["output"]>;
  actionText?: Maybe<Scalars["String"]["output"]>;
  actionUrl?: Maybe<Scalars["String"]["output"]>;
  /** @deprecated Field no longer supported */
  albumIds?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
  authorId?: Maybe<Scalars["String"]["output"]>;
  authorName?: Maybe<Scalars["String"]["output"]>;
  breadcrumbs?: Maybe<Scalars["String"]["output"]>;
  channels?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
  /** @deprecated Field no longer supported */
  componentIds?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
  components?: Maybe<Array<Maybe<cms_PublicComponent>>>;
  darkModeReady?: Maybe<Scalars["Boolean"]["output"]>;
  description?: Maybe<Scalars["String"]["output"]>;
  goLiveAt?: Maybe<Scalars["String"]["output"]>;
  headerStyle?: Maybe<Scalars["String"]["output"]>;
  id?: Maybe<Scalars["String"]["output"]>;
  image?: Maybe<cms_PageImage>;
  imageAttributionUri?: Maybe<Scalars["String"]["output"]>;
  layoutId?: Maybe<Scalars["String"]["output"]>;
  layoutSlug?: Maybe<Scalars["String"]["output"]>;
  live?: Maybe<Scalars["Boolean"]["output"]>;
  locale?: Maybe<Scalars["String"]["output"]>;
  metaDescription?: Maybe<Scalars["String"]["output"]>;
  metaImage?: Maybe<cms_PageImage>;
  metaTitle?: Maybe<Scalars["String"]["output"]>;
  noIndex?: Maybe<Scalars["Boolean"]["output"]>;
  /** Flag used to thread to the frontend to indicate a cms slug was not found and we should render the not found page. */
  pageNotFound?: Maybe<Scalars["Boolean"]["output"]>;
  pageType?: Maybe<Scalars["String"]["output"]>;
  searchPlaceholder?: Maybe<Scalars["String"]["output"]>;
  secondaryImage?: Maybe<cms_PageImage>;
  slug?: Maybe<Scalars["String"]["output"]>;
  targetLocales?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
  title?: Maybe<Scalars["String"]["output"]>;
};

export type core_apimessages_AcceptAddressSuggestionRequest = {
  __typename?: "core_apimessages_AcceptAddressSuggestionRequest";
  _source?: Maybe<Scalars["String"]["output"]>;
  suggestionId?: Maybe<Scalars["String"]["output"]>;
};

export type core_apimessages_AcceptAddressSuggestionResponse = {
  __typename?: "core_apimessages_AcceptAddressSuggestionResponse";
  _source?: Maybe<Scalars["String"]["output"]>;
  /** address uuid */
  id?: Maybe<Scalars["String"]["output"]>;
};

export type core_apimessages_AcceptedPaymentMethodsRequest = {
  __typename?: "core_apimessages_AcceptedPaymentMethodsRequest";
  _source?: Maybe<Scalars["String"]["output"]>;
  /** either shops.uuid or products.id */
  ids?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
  type?: Maybe<core_apimessages_AcceptedPaymentMethodsRequest_PaymentMethodsType>;
};

export enum core_apimessages_AcceptedPaymentMethodsRequest_PaymentMethodsType {
  PRODUCT = "PRODUCT",
  SHOP = "SHOP",
}

export type core_apimessages_AcceptedPaymentMethodsResponse = {
  __typename?: "core_apimessages_AcceptedPaymentMethodsResponse";
  _source?: Maybe<Scalars["String"]["output"]>;
  paymentMethods?: Maybe<Array<Maybe<core_apimessages_PaymentMethods>>>;
};

export type core_apimessages_AccertifyDeviceInfo = {
  __typename?: "core_apimessages_AccertifyDeviceInfo";
  _source?: Maybe<Scalars["String"]["output"]>;
  pageId?: Maybe<Scalars["String"]["output"]>;
  transactionId?: Maybe<Scalars["String"]["output"]>;
  ubaEvents?: Maybe<Scalars["String"]["output"]>;
  ubaId?: Maybe<Scalars["String"]["output"]>;
  ubaSessionId?: Maybe<Scalars["String"]["output"]>;
};

export type core_apimessages_AccountAlertsResponse = {
  __typename?: "core_apimessages_AccountAlertsResponse";
  _source?: Maybe<Scalars["String"]["output"]>;
  alerts?: Maybe<Array<Maybe<core_apimessages_Alert>>>;
};

export type core_apimessages_ActionSourceAttributes = {
  __typename?: "core_apimessages_ActionSourceAttributes";
  _source?: Maybe<Scalars["String"]["output"]>;
  device?: Maybe<Scalars["String"]["output"]>;
};

export type core_apimessages_Ad = {
  __typename?: "core_apimessages_Ad";
  _source?: Maybe<Scalars["String"]["output"]>;
  adCampaignUuid?: Maybe<Scalars["String"]["output"]>;
  adGroupName?: Maybe<Scalars["String"]["output"]>;
  adGroupUuid?: Maybe<Scalars["String"]["output"]>;
  advertiserUuid?: Maybe<Scalars["String"]["output"]>;
  campaignId?: Maybe<Scalars["Int"]["output"]>;
  deletedAt?: Maybe<Scalars["Timestamp"]["output"]>;
  format?: Maybe<core_apimessages_AdFormat>;
  id?: Maybe<Scalars["Int"]["output"]>;
  imageAdCreative?: Maybe<core_apimessages_ImageAdCreative>;
  localizedContents?: Maybe<Array<Maybe<core_apimessages_AdLocalizedContent>>>;
  uuid?: Maybe<Scalars["String"]["output"]>;
};

export type core_apimessages_AdCampaign = {
  __typename?: "core_apimessages_AdCampaign";
  _source?: Maybe<Scalars["String"]["output"]>;
  advertiserUuid?: Maybe<Scalars["String"]["output"]>;
  description?: Maybe<Scalars["String"]["output"]>;
  endsAt?: Maybe<Scalars["Timestamp"]["output"]>;
  name?: Maybe<Scalars["String"]["output"]>;
  startsAt?: Maybe<Scalars["Timestamp"]["output"]>;
  status?: Maybe<core_apimessages_AdCampaign_AdStatus>;
  uuid?: Maybe<Scalars["String"]["output"]>;
};

export enum core_apimessages_AdCampaign_AdStatus {
  ACTIVE = "ACTIVE",
  DRAFT = "DRAFT",
  INACTIVE = "INACTIVE",
}

export type core_apimessages_AdCampaignsSearchRequest = {
  __typename?: "core_apimessages_AdCampaignsSearchRequest";
  _source?: Maybe<Scalars["String"]["output"]>;
  adZoneSlugs?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
  advertiserUuids?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
  limit?: Maybe<Scalars["Int"]["output"]>;
  offset?: Maybe<Scalars["Int"]["output"]>;
  status?: Maybe<core_apimessages_AdCampaignsSearchRequest_Status>;
  withinDate?: Maybe<Scalars["String"]["output"]>;
};

export enum core_apimessages_AdCampaignsSearchRequest_Status {
  ACTIVE = "ACTIVE",
  ANY = "ANY",
  DRAFT = "DRAFT",
  INACTIVE = "INACTIVE",
}

export type core_apimessages_AdCampaignsSearchResponse = {
  __typename?: "core_apimessages_AdCampaignsSearchResponse";
  _source?: Maybe<Scalars["String"]["output"]>;
  adCampaigns?: Maybe<Array<Maybe<AdCampaign>>>;
  limit?: Maybe<Scalars["Int"]["output"]>;
  offset?: Maybe<Scalars["Int"]["output"]>;
  resultUuids?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
  total?: Maybe<Scalars["Int"]["output"]>;
};

export enum core_apimessages_AdFormat {
  IMAGE_AD = "IMAGE_AD",
}

export type core_apimessages_AdGroup = {
  __typename?: "core_apimessages_AdGroup";
  _source?: Maybe<Scalars["String"]["output"]>;
  adCampaignUuid?: Maybe<Scalars["String"]["output"]>;
  description?: Maybe<Scalars["String"]["output"]>;
  name?: Maybe<Scalars["String"]["output"]>;
  status?: Maybe<core_apimessages_AdGroup_AdStatus>;
  targetedAdZoneSlugs?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
  targetedAdZones?: Maybe<Array<Maybe<core_apimessages_AdGroupZone>>>;
  targetedBrandSlugs?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
  targetedCategoryUuids?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
  targetedCountryCodes?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
  targetedDevices?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
  uuid?: Maybe<Scalars["String"]["output"]>;
};

export type core_apimessages_AdGroupKeyword = {
  __typename?: "core_apimessages_AdGroupKeyword";
  _source?: Maybe<Scalars["String"]["output"]>;
  id?: Maybe<Scalars["String"]["output"]>;
  keyword?: Maybe<Scalars["String"]["output"]>;
};

export type core_apimessages_AdGroupZone = {
  __typename?: "core_apimessages_AdGroupZone";
  _source?: Maybe<Scalars["String"]["output"]>;
  adGroup?: Maybe<core_apimessages_AdGroup>;
  adZone?: Maybe<core_apimessages_AdZone>;
  endsAt?: Maybe<Scalars["Timestamp"]["output"]>;
  startsAt?: Maybe<Scalars["Timestamp"]["output"]>;
};

export enum core_apimessages_AdGroup_AdStatus {
  ACTIVE = "ACTIVE",
  DRAFT = "DRAFT",
  INACTIVE = "INACTIVE",
}

export type core_apimessages_AdGroupsSearchRequest = {
  __typename?: "core_apimessages_AdGroupsSearchRequest";
  _source?: Maybe<Scalars["String"]["output"]>;
  adCampaignUuids?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
  limit?: Maybe<Scalars["Int"]["output"]>;
  offset?: Maybe<Scalars["Int"]["output"]>;
};

export type core_apimessages_AdGroupsSearchResponse = {
  __typename?: "core_apimessages_AdGroupsSearchResponse";
  _source?: Maybe<Scalars["String"]["output"]>;
  adGroups?: Maybe<Array<Maybe<AdGroup>>>;
  limit?: Maybe<Scalars["Int"]["output"]>;
  offset?: Maybe<Scalars["Int"]["output"]>;
  resultUuids?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
  total?: Maybe<Scalars["Int"]["output"]>;
};

export type core_apimessages_AdLocalizedContent = {
  __typename?: "core_apimessages_AdLocalizedContent";
  _source?: Maybe<Scalars["String"]["output"]>;
  imageAdCreative?: Maybe<core_apimessages_ImageAdCreative>;
  locale?: Maybe<Scalars["String"]["output"]>;
};

export type core_apimessages_AdPlacement = {
  __typename?: "core_apimessages_AdPlacement";
  _source?: Maybe<Scalars["String"]["output"]>;
  format?: Maybe<core_apimessages_AdFormat>;
};

export type core_apimessages_AdPlacementRequest = {
  __typename?: "core_apimessages_AdPlacementRequest";
  _source?: Maybe<Scalars["String"]["output"]>;
  adTypes?: Maybe<Array<Maybe<core_apimessages_AdType>>>;
  adZones?: Maybe<Array<Maybe<core_apimessages_AdZone>>>;
};

export type core_apimessages_AdPlacementResponse = {
  __typename?: "core_apimessages_AdPlacementResponse";
  _id?: Maybe<Scalars["ID"]["output"]>;
  _source?: Maybe<Scalars["String"]["output"]>;
  ad?: Maybe<core_apimessages_Ad>;
  adZones?: Maybe<Array<Maybe<core_apimessages_AdZone>>>;
  candidates?: Maybe<Array<Maybe<core_apimessages_Candidate>>>;
};

export type core_apimessages_AdServeRequest = {
  __typename?: "core_apimessages_AdServeRequest";
  _source?: Maybe<Scalars["String"]["output"]>;
  includeDrafts?: Maybe<Scalars["Boolean"]["output"]>;
  placements?: Maybe<Array<Maybe<core_apimessages_AdPlacementRequest>>>;
  targeting?: Maybe<core_apimessages_TargetingContext>;
  userContext?: Maybe<core_apimessages_AdUserContext>;
};

export type core_apimessages_AdServeResponse = {
  __typename?: "core_apimessages_AdServeResponse";
  _source?: Maybe<Scalars["String"]["output"]>;
  ad?: Maybe<core_apimessages_Ad>;
  placements?: Maybe<Array<Maybe<core_apimessages_AdPlacementResponse>>>;
};

export type core_apimessages_AdType = {
  __typename?: "core_apimessages_AdType";
  _source?: Maybe<Scalars["String"]["output"]>;
  format?: Maybe<core_apimessages_AdFormat>;
};

export type core_apimessages_AdUserContext = {
  __typename?: "core_apimessages_AdUserContext";
  _source?: Maybe<Scalars["String"]["output"]>;
  mparticleProfileResponse?: Maybe<core_apimessages_MparticleProfileResponse>;
};

export type core_apimessages_AdZone = {
  __typename?: "core_apimessages_AdZone";
  _source?: Maybe<Scalars["String"]["output"]>;
  description?: Maybe<Scalars["String"]["output"]>;
  name?: Maybe<Scalars["String"]["output"]>;
  slug?: Maybe<Scalars["String"]["output"]>;
  status?: Maybe<core_apimessages_AdZone_AdZoneStatus>;
  uuid?: Maybe<Scalars["String"]["output"]>;
};

export enum core_apimessages_AdZone_AdZoneStatus {
  ACTIVE = "ACTIVE",
  INACTIVE = "INACTIVE",
}

export type core_apimessages_AdZonesSearchRequest = {
  __typename?: "core_apimessages_AdZonesSearchRequest";
  _source?: Maybe<Scalars["String"]["output"]>;
  limit?: Maybe<Scalars["Int"]["output"]>;
  offset?: Maybe<Scalars["Int"]["output"]>;
};

export type core_apimessages_AdZonesSearchResponse = {
  __typename?: "core_apimessages_AdZonesSearchResponse";
  _source?: Maybe<Scalars["String"]["output"]>;
  adZones?: Maybe<Array<Maybe<AdZone>>>;
  resultUuids?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
};

export type core_apimessages_AdaChatTokenResponse = {
  __typename?: "core_apimessages_AdaChatTokenResponse";
  _source?: Maybe<Scalars["String"]["output"]>;
  token?: Maybe<Scalars["String"]["output"]>;
};

/** For API response */
export type core_apimessages_Address = {
  __typename?: "core_apimessages_Address";
  _id?: Maybe<Scalars["ID"]["output"]>;
  _source?: Maybe<Scalars["String"]["output"]>;
  addressPresent?: Maybe<Scalars["Boolean"]["output"]>;
  country?: Maybe<core_apimessages_Country>;
  countryCode?: Maybe<Scalars["String"]["output"]>;
  displayLocation?: Maybe<Scalars["String"]["output"]>;
  email?: Maybe<Scalars["String"]["output"]>;
  extendedAddress?: Maybe<Scalars["String"]["output"]>;
  /** Equivalent to uuid */
  id?: Maybe<Scalars["String"]["output"]>;
  /** True if the address has all required fields */
  isComplete?: Maybe<Scalars["Boolean"]["output"]>;
  /** addresses.id */
  legacyId?: Maybe<Scalars["String"]["output"]>;
  locality?: Maybe<Scalars["String"]["output"]>;
  name?: Maybe<Scalars["String"]["output"]>;
  phone?: Maybe<Scalars["String"]["output"]>;
  postalCode?: Maybe<Scalars["String"]["output"]>;
  primary?: Maybe<Scalars["Boolean"]["output"]>;
  region?: Maybe<Scalars["String"]["output"]>;
  /** Localized based on region field. Example: a region code of CA in the US will have a name of "California." */
  regionName?: Maybe<Scalars["String"]["output"]>;
  streetAddress?: Maybe<Scalars["String"]["output"]>;
  uuid?: Maybe<Scalars["String"]["output"]>;
  /** True if primary address fields match address details in Loqate */
  verified?: Maybe<Scalars["Boolean"]["output"]>;
};

export type core_apimessages_AddressDeleteRequest = {
  __typename?: "core_apimessages_AddressDeleteRequest";
  _source?: Maybe<Scalars["String"]["output"]>;
  addressType?: Maybe<core_apimessages_MyAddressType>;
  /** deprecated, use uuid */
  id?: Maybe<Scalars["String"]["output"]>;
  uuid?: Maybe<Scalars["String"]["output"]>;
};

/** For POST/PUT API requests */
export type core_apimessages_AddressEntry = {
  __typename?: "core_apimessages_AddressEntry";
  _source?: Maybe<Scalars["String"]["output"]>;
  countryCode?: Maybe<Scalars["String"]["output"]>;
  email?: Maybe<Scalars["String"]["output"]>;
  extendedAddress?: Maybe<Scalars["String"]["output"]>;
  locality?: Maybe<Scalars["String"]["output"]>;
  name?: Maybe<Scalars["String"]["output"]>;
  phone?: Maybe<Scalars["String"]["output"]>;
  postalCode?: Maybe<Scalars["String"]["output"]>;
  region?: Maybe<Scalars["String"]["output"]>;
  streetAddress?: Maybe<Scalars["String"]["output"]>;
};

export enum core_apimessages_AddressVerificationStatus {
  NOT_VERIFIED = "NOT_VERIFIED",
  VERIFIED = "VERIFIED",
}

export type core_apimessages_AddressVerifyRequest = {
  __typename?: "core_apimessages_AddressVerifyRequest";
  _source?: Maybe<Scalars["String"]["output"]>;
  /** address uuid */
  id?: Maybe<Scalars["String"]["output"]>;
};

export type core_apimessages_AddressVerifyResponse = {
  __typename?: "core_apimessages_AddressVerifyResponse";
  _source?: Maybe<Scalars["String"]["output"]>;
  address?: Maybe<core_apimessages_Address>;
  /** The verified address, null if no suggestion is found. */
  suggestion?: Maybe<core_apimessages_Address>;
};

export type core_apimessages_AdminBlockProductReviewRequest = {
  __typename?: "core_apimessages_AdminBlockProductReviewRequest";
  _source?: Maybe<Scalars["String"]["output"]>;
  productReviewId?: Maybe<Scalars["String"]["output"]>;
};

export type core_apimessages_AdminBlockProductReviewResponse = {
  __typename?: "core_apimessages_AdminBlockProductReviewResponse";
  _source?: Maybe<Scalars["String"]["output"]>;
  /** Blocked Product Review ID */
  id?: Maybe<Scalars["String"]["output"]>;
};

export type core_apimessages_AdminCuratedSetsCurateRequest = {
  __typename?: "core_apimessages_AdminCuratedSetsCurateRequest";
  _source?: Maybe<Scalars["String"]["output"]>;
  curatedSetSlug?: Maybe<Scalars["String"]["output"]>;
  featuredInSet?: Maybe<Scalars["Boolean"]["output"]>;
  listingId?: Maybe<Scalars["String"]["output"]>;
};

export type core_apimessages_AdminCuratedSetsCurateResponse = {
  __typename?: "core_apimessages_AdminCuratedSetsCurateResponse";
  _source?: Maybe<Scalars["String"]["output"]>;
  curatedSetSlug?: Maybe<Scalars["String"]["output"]>;
  featuredInSet?: Maybe<Scalars["Boolean"]["output"]>;
  listingId?: Maybe<Scalars["String"]["output"]>;
};

export type core_apimessages_AdminNote = {
  __typename?: "core_apimessages_AdminNote";
  _source?: Maybe<Scalars["String"]["output"]>;
  body?: Maybe<Scalars["String"]["output"]>;
  createdAt?: Maybe<Scalars["Timestamp"]["output"]>;
  createdByName?: Maybe<Scalars["String"]["output"]>;
  id?: Maybe<Scalars["String"]["output"]>;
  isPinned?: Maybe<Scalars["Boolean"]["output"]>;
  lastEditedByName?: Maybe<Scalars["String"]["output"]>;
  linkHtml?: Maybe<Scalars["String"]["output"]>;
  updatedAt?: Maybe<Scalars["Timestamp"]["output"]>;
  userCanEdit?: Maybe<Scalars["Boolean"]["output"]>;
  versions?: Maybe<Array<Maybe<core_apimessages_AdminNoteVersion>>>;
};

export type core_apimessages_AdminNoteCreateRequest = {
  __typename?: "core_apimessages_AdminNoteCreateRequest";
  _source?: Maybe<Scalars["String"]["output"]>;
  body?: Maybe<Scalars["String"]["output"]>;
  notableId?: Maybe<Scalars["String"]["output"]>;
  notableType?: Maybe<Scalars["String"]["output"]>;
  pinned?: Maybe<Scalars["Boolean"]["output"]>;
};

export type core_apimessages_AdminNoteUpdateRequest = {
  __typename?: "core_apimessages_AdminNoteUpdateRequest";
  _source?: Maybe<Scalars["String"]["output"]>;
  body?: Maybe<Scalars["String"]["output"]>;
  id?: Maybe<Scalars["String"]["output"]>;
  pinned?: Maybe<Scalars["Boolean"]["output"]>;
};

export type core_apimessages_AdminNoteVersion = {
  __typename?: "core_apimessages_AdminNoteVersion";
  _source?: Maybe<Scalars["String"]["output"]>;
  body?: Maybe<Scalars["String"]["output"]>;
  editedAt?: Maybe<Scalars["Timestamp"]["output"]>;
  editedByName?: Maybe<Scalars["String"]["output"]>;
  id?: Maybe<Scalars["String"]["output"]>;
};

export type core_apimessages_AdminNotesSearchRequest = {
  __typename?: "core_apimessages_AdminNotesSearchRequest";
  _source?: Maybe<Scalars["String"]["output"]>;
  bodyText?: Maybe<Scalars["String"]["output"]>;
  ignorePinned?: Maybe<Scalars["Boolean"]["output"]>;
  limit?: Maybe<Scalars["String"]["output"]>;
  linkableId?: Maybe<Scalars["String"]["output"]>;
  linkableType?: Maybe<Scalars["String"]["output"]>;
  notableId?: Maybe<Scalars["String"]["output"]>;
  notableType?: Maybe<Scalars["String"]["output"]>;
  offset?: Maybe<Scalars["String"]["output"]>;
  sortOrder?: Maybe<Scalars["String"]["output"]>;
};

export type core_apimessages_AdminNotesSearchResponse = {
  __typename?: "core_apimessages_AdminNotesSearchResponse";
  _source?: Maybe<Scalars["String"]["output"]>;
  adminNotes?: Maybe<Array<Maybe<core_apimessages_AdminNote>>>;
  limit?: Maybe<Scalars["String"]["output"]>;
  offset?: Maybe<Scalars["String"]["output"]>;
  totalResults?: Maybe<Scalars["String"]["output"]>;
};

export type core_apimessages_AdminTax1099kReportingThresholdCreateRequest = {
  __typename?: "core_apimessages_AdminTax1099kReportingThresholdCreateRequest";
  _source?: Maybe<Scalars["String"]["output"]>;
  region?: Maybe<Scalars["String"]["output"]>;
  salesAmountCents?: Maybe<Scalars["String"]["output"]>;
  transactionCount?: Maybe<Scalars["String"]["output"]>;
  year?: Maybe<Scalars["String"]["output"]>;
};

export type core_apimessages_AdminTax1099kReportingThresholdCreateResponse = {
  __typename?: "core_apimessages_AdminTax1099kReportingThresholdCreateResponse";
  _source?: Maybe<Scalars["String"]["output"]>;
  id?: Maybe<Scalars["String"]["output"]>;
  region?: Maybe<Scalars["String"]["output"]>;
  salesAmountCents?: Maybe<Scalars["String"]["output"]>;
  transactionCount?: Maybe<Scalars["String"]["output"]>;
  year?: Maybe<Scalars["String"]["output"]>;
};

export type core_apimessages_AdminTax1099kReportingThresholdDeleteRequest = {
  __typename?: "core_apimessages_AdminTax1099kReportingThresholdDeleteRequest";
  _source?: Maybe<Scalars["String"]["output"]>;
  id?: Maybe<Scalars["String"]["output"]>;
};

export type core_apimessages_AdminTax1099kReportingThresholdDeleteResponse = {
  __typename?: "core_apimessages_AdminTax1099kReportingThresholdDeleteResponse";
  _source?: Maybe<Scalars["String"]["output"]>;
  id?: Maybe<Scalars["String"]["output"]>;
};

export type core_apimessages_AdminTax1099kReportingThresholdSearchRequest = {
  __typename?: "core_apimessages_AdminTax1099kReportingThresholdSearchRequest";
  _source?: Maybe<Scalars["String"]["output"]>;
  limit?: Maybe<Scalars["String"]["output"]>;
  offset?: Maybe<Scalars["String"]["output"]>;
  region?: Maybe<Scalars["String"]["output"]>;
  year?: Maybe<Scalars["String"]["output"]>;
};

export type core_apimessages_AdminTax1099kReportingThresholdSearchResponse = {
  __typename?: "core_apimessages_AdminTax1099kReportingThresholdSearchResponse";
  _source?: Maybe<Scalars["String"]["output"]>;
  limit?: Maybe<Scalars["String"]["output"]>;
  offset?: Maybe<Scalars["String"]["output"]>;
  taxReportingThresholds?: Maybe<
    Array<Maybe<core_apimessages_Tax1099kReportingThresholdSearchResult>>
  >;
  totalResults?: Maybe<Scalars["String"]["output"]>;
};

export type core_apimessages_AdminTax1099kReportingThresholdUpdateRequest = {
  __typename?: "core_apimessages_AdminTax1099kReportingThresholdUpdateRequest";
  _source?: Maybe<Scalars["String"]["output"]>;
  id?: Maybe<Scalars["String"]["output"]>;
  region?: Maybe<Scalars["String"]["output"]>;
  salesAmountCents?: Maybe<Scalars["String"]["output"]>;
  transactionCount?: Maybe<Scalars["String"]["output"]>;
  year?: Maybe<Scalars["String"]["output"]>;
};

export type core_apimessages_AdminTax1099kReportingThresholdUpdateResponse = {
  __typename?: "core_apimessages_AdminTax1099kReportingThresholdUpdateResponse";
  _source?: Maybe<Scalars["String"]["output"]>;
  id?: Maybe<Scalars["String"]["output"]>;
  region?: Maybe<Scalars["String"]["output"]>;
  salesAmountCents?: Maybe<Scalars["String"]["output"]>;
  transactionCount?: Maybe<Scalars["String"]["output"]>;
  year?: Maybe<Scalars["String"]["output"]>;
};

export type core_apimessages_AdminUserDatadogErrorsSearchRequest = {
  __typename?: "core_apimessages_AdminUserDatadogErrorsSearchRequest";
  _source?: Maybe<Scalars["String"]["output"]>;
  userId?: Maybe<Scalars["String"]["output"]>;
};

export type core_apimessages_AdminUserDatadogErrorsSearchResponse = {
  __typename?: "core_apimessages_AdminUserDatadogErrorsSearchResponse";
  _source?: Maybe<Scalars["String"]["output"]>;
  datadogErrors?: Maybe<Array<Maybe<core_apimessages_DatadogError>>>;
};

export type core_apimessages_AdminUserSearchRequest = {
  __typename?: "core_apimessages_AdminUserSearchRequest";
  _source?: Maybe<Scalars["String"]["output"]>;
  activated?: Maybe<core_apimessages_AdminUserSearchRequest_ActivatedFilter>;
  page?: Maybe<Scalars["String"]["output"]>;
  perPage?: Maybe<Scalars["String"]["output"]>;
  search?: Maybe<Scalars["String"]["output"]>;
  userFilter?: Maybe<Scalars["String"]["output"]>;
};

export enum core_apimessages_AdminUserSearchRequest_ActivatedFilter {
  ACTIVATED = "ACTIVATED",
  NONE = "NONE",
  NOT_ACTIVATED = "NOT_ACTIVATED",
}

export type core_apimessages_AdminUserSearchResponse = {
  __typename?: "core_apimessages_AdminUserSearchResponse";
  _source?: Maybe<Scalars["String"]["output"]>;
  page?: Maybe<Scalars["String"]["output"]>;
  perPage?: Maybe<Scalars["String"]["output"]>;
  totalResults?: Maybe<Scalars["String"]["output"]>;
  users?: Maybe<Array<Maybe<core_apimessages_UserSearchResult>>>;
};

export type core_apimessages_AdsSearchRequest = {
  __typename?: "core_apimessages_AdsSearchRequest";
  _source?: Maybe<Scalars["String"]["output"]>;
  adGroupUuids?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
  limit?: Maybe<Scalars["Int"]["output"]>;
  offset?: Maybe<Scalars["Int"]["output"]>;
};

export type core_apimessages_AdsSearchResponse = {
  __typename?: "core_apimessages_AdsSearchResponse";
  _source?: Maybe<Scalars["String"]["output"]>;
  ads?: Maybe<Array<Maybe<Ad>>>;
  limit?: Maybe<Scalars["Int"]["output"]>;
  offset?: Maybe<Scalars["Int"]["output"]>;
  resultUuids?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
  total?: Maybe<Scalars["Int"]["output"]>;
};

export type core_apimessages_Advertiser = {
  __typename?: "core_apimessages_Advertiser";
  _source?: Maybe<Scalars["String"]["output"]>;
  brandSlugs?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
  name?: Maybe<Scalars["String"]["output"]>;
  shopSlugs?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
  uuid?: Maybe<Scalars["String"]["output"]>;
};

export type core_apimessages_AdvertisersSearchRequest = {
  __typename?: "core_apimessages_AdvertisersSearchRequest";
  _source?: Maybe<Scalars["String"]["output"]>;
  limit?: Maybe<Scalars["Int"]["output"]>;
  offset?: Maybe<Scalars["Int"]["output"]>;
};

export type core_apimessages_AdvertisersSearchResponse = {
  __typename?: "core_apimessages_AdvertisersSearchResponse";
  _source?: Maybe<Scalars["String"]["output"]>;
  advertisers?: Maybe<Array<Maybe<Advertiser>>>;
  limit?: Maybe<Scalars["Int"]["output"]>;
  offset?: Maybe<Scalars["Int"]["output"]>;
  resultUuids?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
  total?: Maybe<Scalars["Int"]["output"]>;
};

export type core_apimessages_AdyenBalanceOnboardingResponse = {
  __typename?: "core_apimessages_AdyenBalanceOnboardingResponse";
  _source?: Maybe<Scalars["String"]["output"]>;
  accountHolderId?: Maybe<Scalars["String"]["output"]>;
  balanceAccountId?: Maybe<Scalars["String"]["output"]>;
  legalEntityId?: Maybe<Scalars["String"]["output"]>;
  transferInstrumentId?: Maybe<Scalars["String"]["output"]>;
};

export type core_apimessages_AdyenBalancePlatformNotification = {
  __typename?: "core_apimessages_AdyenBalancePlatformNotification";
  _source?: Maybe<Scalars["String"]["output"]>;
  createdAt?: Maybe<Scalars["Timestamp"]["output"]>;
  id?: Maybe<Scalars["String"]["output"]>;
  rawBody?: Maybe<Scalars["String"]["output"]>;
};

export type core_apimessages_AdyenChallengeShopperResults = {
  __typename?: "core_apimessages_AdyenChallengeShopperResults";
  _source?: Maybe<Scalars["String"]["output"]>;
  paymentData?: Maybe<Scalars["String"]["output"]>;
  threeds2ChallengeResult?: Maybe<Scalars["String"]["output"]>;
};

export type core_apimessages_AdyenCheckoutPaymentMethods = {
  __typename?: "core_apimessages_AdyenCheckoutPaymentMethods";
  _source?: Maybe<Scalars["String"]["output"]>;
  /** JSON-encoded Adyen response to parse client-side */
  adyenCheckoutPaymentMethodsConfig?: Maybe<Scalars["String"]["output"]>;
};

export type core_apimessages_AdyenCreditCardEntry = {
  __typename?: "core_apimessages_AdyenCreditCardEntry";
  _source?: Maybe<Scalars["String"]["output"]>;
  /**
   *   When creating a new credit card with adyen_tokenized_fields, for some
   * reason Adyen does not have an "encryptedCardholderName" field so this
   * needs to be passed separately as an unencrypted field.
   */
  cardholderName?: Maybe<Scalars["String"]["output"]>;
  /**
   *   JSON-encoded string for creating a new credit card, generated by secured fields (Adyen's new SDK).
   * Each key is the name of an individual credit card field (ex: "encryptedExpiryYear"),
   * and each value is an encrypted string. Core parses the JSON string, then passes the fully parsed
   * object to Adyen without modifying any individual keys or values. Adyen decrypts each field when it reaches their server.
   */
  tokenizedFields?: Maybe<Scalars["String"]["output"]>;
};

export type core_apimessages_AdyenIdentifyShopperResults = {
  __typename?: "core_apimessages_AdyenIdentifyShopperResults";
  _source?: Maybe<Scalars["String"]["output"]>;
  paymentData?: Maybe<Scalars["String"]["output"]>;
  threeds2FingerprintResult?: Maybe<Scalars["String"]["output"]>;
};

export enum core_apimessages_AdyenLegalEntity {
  BUSINESS = "BUSINESS",
  INDIVIDUAL = "INDIVIDUAL",
}

export type core_apimessages_AdyenOnboardingLinkRequest = {
  __typename?: "core_apimessages_AdyenOnboardingLinkRequest";
  _source?: Maybe<Scalars["String"]["output"]>;
  redirectUrl?: Maybe<Scalars["String"]["output"]>;
};

export type core_apimessages_AdyenOnboardingLinkResponse = {
  __typename?: "core_apimessages_AdyenOnboardingLinkResponse";
  _source?: Maybe<Scalars["String"]["output"]>;
  url?: Maybe<Scalars["String"]["output"]>;
};

export type core_apimessages_AdyenPaymentResult = {
  __typename?: "core_apimessages_AdyenPaymentResult";
  _source?: Maybe<Scalars["String"]["output"]>;
  action?: Maybe<Scalars["String"]["output"]>;
  challengeToken?: Maybe<Scalars["String"]["output"]>;
  fingerprintToken?: Maybe<Scalars["String"]["output"]>;
  paymentData?: Maybe<Scalars["String"]["output"]>;
  paymentStatus?: Maybe<core_apimessages_AdyenPaymentResult_PaymentStatus>;
  threeDsOneData?: Maybe<core_apimessages_ThreeDSOneData>;
  threeDsOneRedirectUrl?: Maybe<Scalars["String"]["output"]>;
};

export enum core_apimessages_AdyenPaymentResult_PaymentStatus {
  CHALLENGE_SHOPPER_REQUIRED = "CHALLENGE_SHOPPER_REQUIRED",
  FAILED = "FAILED",
  IDENTIFY_SHOPPER_REQUIRED = "IDENTIFY_SHOPPER_REQUIRED",
  REDIRECT_SHOPPER_REQUIRED = "REDIRECT_SHOPPER_REQUIRED",
  SUCCESS = "SUCCESS",
}

export type core_apimessages_AdyenThreeDSBillingMethodEntry = {
  __typename?: "core_apimessages_AdyenThreeDSBillingMethodEntry";
  _source?: Maybe<Scalars["String"]["output"]>;
  billingCardEntry?: Maybe<core_apimessages_BillingCreditCardEntry>;
  paymentData?: Maybe<Scalars["String"]["output"]>;
};

export type core_apimessages_AdyenVerifyShopperResults = {
  __typename?: "core_apimessages_AdyenVerifyShopperResults";
  _source?: Maybe<Scalars["String"]["output"]>;
  threeds2Result?: Maybe<Scalars["String"]["output"]>;
};

export type core_apimessages_AffirmFinancingPromotion = {
  __typename?: "core_apimessages_AffirmFinancingPromotion";
  _source?: Maybe<Scalars["String"]["output"]>;
  availableTermMonths?: Maybe<Array<Maybe<Scalars["Int"]["output"]>>>;
  endsAt?: Maybe<google_protobuf_Timestamp>;
  financingPlan?: Maybe<Scalars["String"]["output"]>;
  /** affirm_financing_promotions.id */
  id?: Maybe<Scalars["String"]["output"]>;
  links?: Maybe<core_apimessages_AffirmFinancingPromotionLinks>;
  maxTermMonth?: Maybe<Scalars["Int"]["output"]>;
  startsAt?: Maybe<google_protobuf_Timestamp>;
  zeroPercentFinancing?: Maybe<Scalars["Boolean"]["output"]>;
};

export type core_apimessages_AffirmFinancingPromotionLinks = {
  __typename?: "core_apimessages_AffirmFinancingPromotionLinks";
  _source?: Maybe<Scalars["String"]["output"]>;
  curatedSet?: Maybe<core_apimessages_Link>;
};

export type core_apimessages_AffirmFinancingPromotionsRequest = {
  __typename?: "core_apimessages_AffirmFinancingPromotionsRequest";
  _source?: Maybe<Scalars["String"]["output"]>;
  /** products.id */
  ids?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
};

export type core_apimessages_AffirmFinancingPromotionsResponse = {
  __typename?: "core_apimessages_AffirmFinancingPromotionsResponse";
  _source?: Maybe<Scalars["String"]["output"]>;
  affirmFinancingPromotions?: Maybe<
    Array<Maybe<core_apimessages_AffirmFinancingPromotion>>
  >;
};

export type core_apimessages_Alert = {
  __typename?: "core_apimessages_Alert";
  _source?: Maybe<Scalars["String"]["output"]>;
  description?: Maybe<Scalars["String"]["output"]>;
  title?: Maybe<Scalars["String"]["output"]>;
  url?: Maybe<Scalars["String"]["output"]>;
};

export type core_apimessages_ApplyCheckoutDiscountCodeRequest = {
  __typename?: "core_apimessages_ApplyCheckoutDiscountCodeRequest";
  _source?: Maybe<Scalars["String"]["output"]>;
  discountCode?: Maybe<Scalars["String"]["output"]>;
  /** checkout UUID */
  id?: Maybe<Scalars["String"]["output"]>;
};

export type core_apimessages_ApplyCheckoutDiscountCodeResponse = {
  __typename?: "core_apimessages_ApplyCheckoutDiscountCodeResponse";
  _source?: Maybe<Scalars["String"]["output"]>;
  checkout?: Maybe<Checkout>;
  /** checkout UUID */
  id?: Maybe<Scalars["String"]["output"]>;
};

export type core_apimessages_Article = {
  __typename?: "core_apimessages_Article";
  _source?: Maybe<Scalars["String"]["output"]>;
  articleCategories?: Maybe<Array<Maybe<core_apimessages_ArticleCategory>>>;
  /** articles.id */
  id?: Maybe<Scalars["String"]["output"]>;
  slug?: Maybe<Scalars["String"]["output"]>;
  title?: Maybe<Scalars["String"]["output"]>;
  webLink?: Maybe<core_apimessages_Link>;
};

export type core_apimessages_ArticleCategories = {
  __typename?: "core_apimessages_ArticleCategories";
  _source?: Maybe<Scalars["String"]["output"]>;
  categories?: Maybe<Array<Maybe<core_apimessages_ArticleCategory>>>;
};

export type core_apimessages_ArticleCategoriesResponse = {
  __typename?: "core_apimessages_ArticleCategoriesResponse";
  _source?: Maybe<Scalars["String"]["output"]>;
  articleCategories?: Maybe<core_apimessages_ArticleCategories>;
};

export type core_apimessages_ArticleCategory = {
  __typename?: "core_apimessages_ArticleCategory";
  _source?: Maybe<Scalars["String"]["output"]>;
  /** categories.id */
  id?: Maybe<Scalars["String"]["output"]>;
  name?: Maybe<Scalars["String"]["output"]>;
  slug?: Maybe<Scalars["String"]["output"]>;
};

export type core_apimessages_ArticlesRequest = {
  __typename?: "core_apimessages_ArticlesRequest";
  _source?: Maybe<Scalars["String"]["output"]>;
  /** articles.id */
  ids?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
  slugs?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
};

export type core_apimessages_ArticlesResponse = {
  __typename?: "core_apimessages_ArticlesResponse";
  _source?: Maybe<Scalars["String"]["output"]>;
  articles?: Maybe<Array<Maybe<core_apimessages_Article>>>;
};

export type core_apimessages_AutoOffer = {
  __typename?: "core_apimessages_AutoOffer";
  _source?: Maybe<Scalars["String"]["output"]>;
  id?: Maybe<Scalars["String"]["output"]>;
  /** @deprecated Field no longer supported */
  instantOffersEnabled?: Maybe<Scalars["Boolean"]["output"]>;
  listingId?: Maybe<Scalars["String"]["output"]>;
  message?: Maybe<Scalars["String"]["output"]>;
  percentage?: Maybe<Scalars["Int"]["output"]>;
  price?: Maybe<core_apimessages_Money>;
  /** @deprecated Field no longer supported */
  pushToExisting?: Maybe<Scalars["Boolean"]["output"]>;
  shippingPriceOverride?: Maybe<core_apimessages_Money>;
};

export type core_apimessages_AutoOffersRequest = {
  __typename?: "core_apimessages_AutoOffersRequest";
  _source?: Maybe<Scalars["String"]["output"]>;
  listingIds?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
};

export type core_apimessages_AutoOffersResponse = {
  __typename?: "core_apimessages_AutoOffersResponse";
  _source?: Maybe<Scalars["String"]["output"]>;
  autoOffers?: Maybe<Array<Maybe<core_apimessages_AutoOffer>>>;
};

export type core_apimessages_AutomatchingCanonicalProduct = {
  __typename?: "core_apimessages_AutomatchingCanonicalProduct";
  _source?: Maybe<Scalars["String"]["output"]>;
  brand?: Maybe<core_apimessages_Brand>;
  finish?: Maybe<Scalars["String"]["output"]>;
  id?: Maybe<Scalars["String"]["output"]>;
  model?: Maybe<Scalars["String"]["output"]>;
  primaryImage?: Maybe<core_apimessages_Image>;
  upc?: Maybe<Scalars["String"]["output"]>;
  year?: Maybe<Scalars["String"]["output"]>;
};

export type core_apimessages_AvailableAction = {
  __typename?: "core_apimessages_AvailableAction";
  _source?: Maybe<Scalars["String"]["output"]>;
  /** Same value as name, but calling it id helps with frontend apollo cache */
  id?: Maybe<core_apimessages_UserActionName>;
  name?: Maybe<core_apimessages_UserActionName>;
  status?: Maybe<core_apimessages_UserActionStatus>;
};

export type core_apimessages_AvailableForPickupSignal = {
  __typename?: "core_apimessages_AvailableForPickupSignal";
  _source?: Maybe<Scalars["String"]["output"]>;
  isValid?: Maybe<Scalars["Boolean"]["output"]>;
  translations?: Maybe<core_apimessages_SignalTranslation>;
};

export type core_apimessages_AvailableNearbyForPickupSignal = {
  __typename?: "core_apimessages_AvailableNearbyForPickupSignal";
  _source?: Maybe<Scalars["String"]["output"]>;
  isValid?: Maybe<Scalars["Boolean"]["output"]>;
  translations?: Maybe<core_apimessages_SignalTranslation>;
};

export type core_apimessages_BearerV2TokenRequest = {
  __typename?: "core_apimessages_BearerV2TokenRequest";
  _source?: Maybe<Scalars["String"]["output"]>;
  client?: Maybe<core_apimessages_BearerV2TokenRequest_Client>;
};

export enum core_apimessages_BearerV2TokenRequest_Client {
  LISTING_PHOTO_UPLOADER = "LISTING_PHOTO_UPLOADER",
  MOBILE = "MOBILE",
  UNKNOWN = "UNKNOWN",
}

export type core_apimessages_BearerV2TokenResponse = {
  __typename?: "core_apimessages_BearerV2TokenResponse";
  _source?: Maybe<Scalars["String"]["output"]>;
  token?: Maybe<Scalars["String"]["output"]>;
};

export type core_apimessages_BillingCreditCardEntry = {
  __typename?: "core_apimessages_BillingCreditCardEntry";
  _source?: Maybe<Scalars["String"]["output"]>;
  address?: Maybe<core_apimessages_AddressEntry>;
  adyenCreditCard?: Maybe<core_apimessages_AdyenCreditCardEntry>;
  /**
   *   string token for creating a new credit card, generated by Adyen's Client-Side Encryption.
   * Adyen decrypts ths into an object containing key/value pairs of raw credit card fields.
   */
  adyenToken?: Maybe<Scalars["String"]["output"]>;
  /** credit_cards.id for selecting an already created credit card */
  creditCardId?: Maybe<Scalars["String"]["output"]>;
};

export type core_apimessages_BillingMethodChallengeShopperRequest = {
  __typename?: "core_apimessages_BillingMethodChallengeShopperRequest";
  _source?: Maybe<Scalars["String"]["output"]>;
  billingMethodUuid?: Maybe<Scalars["String"]["output"]>;
  results?: Maybe<core_apimessages_AdyenChallengeShopperResults>;
};

export type core_apimessages_BillingMethodChallengeShopperResponse = {
  __typename?: "core_apimessages_BillingMethodChallengeShopperResponse";
  _source?: Maybe<Scalars["String"]["output"]>;
  adyenPaymentResult?: Maybe<core_apimessages_AdyenPaymentResult>;
  billingMethodUuid?: Maybe<Scalars["String"]["output"]>;
  creditCard?: Maybe<core_apimessages_CreditCard>;
  errors?: Maybe<Array<Maybe<core_apimessages_Error>>>;
  me?: Maybe<rql_Me>;
};

export type core_apimessages_BillingMethodIdentifyShopperRequest = {
  __typename?: "core_apimessages_BillingMethodIdentifyShopperRequest";
  _source?: Maybe<Scalars["String"]["output"]>;
  billingMethodUuid?: Maybe<Scalars["String"]["output"]>;
  results?: Maybe<core_apimessages_AdyenIdentifyShopperResults>;
};

export type core_apimessages_BillingMethodIdentifyShopperResponse = {
  __typename?: "core_apimessages_BillingMethodIdentifyShopperResponse";
  _source?: Maybe<Scalars["String"]["output"]>;
  adyenPaymentResult?: Maybe<core_apimessages_AdyenPaymentResult>;
  billingMethodUuid?: Maybe<Scalars["String"]["output"]>;
  creditCard?: Maybe<core_apimessages_CreditCard>;
  errors?: Maybe<Array<Maybe<core_apimessages_Error>>>;
  me?: Maybe<rql_Me>;
};

export type core_apimessages_BillingMethodVerifyShopperRequest = {
  __typename?: "core_apimessages_BillingMethodVerifyShopperRequest";
  _source?: Maybe<Scalars["String"]["output"]>;
  billingMethodUuid?: Maybe<Scalars["String"]["output"]>;
  /** optional, to indicate that a new card is being added specifically for a partial payment */
  partialPayment?: Maybe<Scalars["Boolean"]["output"]>;
  results?: Maybe<core_apimessages_AdyenVerifyShopperResults>;
};

export type core_apimessages_BillingMethodVerifyShopperResponse = {
  __typename?: "core_apimessages_BillingMethodVerifyShopperResponse";
  _source?: Maybe<Scalars["String"]["output"]>;
  adyenPaymentResult?: Maybe<core_apimessages_AdyenPaymentResult>;
  billingMethodUuid?: Maybe<Scalars["String"]["output"]>;
  creditCard?: Maybe<core_apimessages_CreditCard>;
  errors?: Maybe<Array<Maybe<core_apimessages_Error>>>;
  me?: Maybe<rql_Me>;
};

export type core_apimessages_Brand = {
  __typename?: "core_apimessages_Brand";
  _id?: Maybe<Scalars["ID"]["output"]>;
  _source?: Maybe<Scalars["String"]["output"]>;
  /** brands.uuid */
  id?: Maybe<Scalars["String"]["output"]>;
  name?: Maybe<Scalars["String"]["output"]>;
  slug?: Maybe<Scalars["String"]["output"]>;
  uuid?: Maybe<Scalars["String"]["output"]>;
  webLink?: Maybe<core_apimessages_Link>;
};

export type core_apimessages_BrandManagerBrandRequest = {
  __typename?: "core_apimessages_BrandManagerBrandRequest";
  _source?: Maybe<Scalars["String"]["output"]>;
  slug?: Maybe<Scalars["String"]["output"]>;
};

export type core_apimessages_BrandManagerBrandResponse = {
  __typename?: "core_apimessages_BrandManagerBrandResponse";
  _source?: Maybe<Scalars["String"]["output"]>;
  brand?: Maybe<core_apimessages_Brand>;
};

export type core_apimessages_BrandManagerBrandsRequest = {
  __typename?: "core_apimessages_BrandManagerBrandsRequest";
  _source?: Maybe<Scalars["String"]["output"]>;
  limit?: Maybe<Scalars["Int"]["output"]>;
  offset?: Maybe<Scalars["Int"]["output"]>;
  slugs?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
  uuids?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
};

export type core_apimessages_BrandManagerBrandsResponse = {
  __typename?: "core_apimessages_BrandManagerBrandsResponse";
  _source?: Maybe<Scalars["String"]["output"]>;
  brandManagerBrands?: Maybe<Array<Maybe<core_apimessages_Brand>>>;
  limit?: Maybe<Scalars["Int"]["output"]>;
  offset?: Maybe<Scalars["Int"]["output"]>;
};

export type core_apimessages_BrandsRequest = {
  __typename?: "core_apimessages_BrandsRequest";
  _source?: Maybe<Scalars["String"]["output"]>;
  slugs?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
  uuids?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
};

export type core_apimessages_BrandsResponse = {
  __typename?: "core_apimessages_BrandsResponse";
  _source?: Maybe<Scalars["String"]["output"]>;
  brands?: Maybe<Array<Maybe<core_apimessages_Brand>>>;
};

export type core_apimessages_BrowsePage = {
  __typename?: "core_apimessages_BrowsePage";
  _source?: Maybe<Scalars["String"]["output"]>;
  breadcrumbs?: Maybe<Array<Maybe<core_apimessages_BrowsePageBreadcrumb>>>;
  combinedFilters?: Maybe<Scalars["String"]["output"]>;
  id?: Maybe<Scalars["String"]["output"]>;
  ownFilters?: Maybe<Scalars["String"]["output"]>;
  title?: Maybe<Scalars["String"]["output"]>;
  url?: Maybe<Scalars["String"]["output"]>;
};

export type core_apimessages_BrowsePageBreadcrumb = {
  __typename?: "core_apimessages_BrowsePageBreadcrumb";
  _source?: Maybe<Scalars["String"]["output"]>;
  title?: Maybe<Scalars["String"]["output"]>;
  url?: Maybe<Scalars["String"]["output"]>;
};

export type core_apimessages_BrowsePageLink = {
  __typename?: "core_apimessages_BrowsePageLink";
  _source?: Maybe<Scalars["String"]["output"]>;
  combinedFilters?: Maybe<Scalars["String"]["output"]>;
  matchParams?: Maybe<
    Array<Maybe<core_apimessages_BrowsePageLink_MatchParams>>
  >;
  matchType?: Maybe<core_apimessages_BrowsePageLink_MatchType>;
  url?: Maybe<Scalars["String"]["output"]>;
};

export type core_apimessages_BrowsePageLink_MatchParams = {
  __typename?: "core_apimessages_BrowsePageLink_MatchParams";
  _source?: Maybe<Scalars["String"]["output"]>;
  paramName?: Maybe<Scalars["String"]["output"]>;
  value?: Maybe<Scalars["String"]["output"]>;
};

export enum core_apimessages_BrowsePageLink_MatchType {
  MULTIPLE_OPTION_VALUES = "MULTIPLE_OPTION_VALUES",
  OPTION_VALUE = "OPTION_VALUE",
  PARAM_NAME = "PARAM_NAME",
  REMOVE_OPTION_VALUE = "REMOVE_OPTION_VALUE",
  REMOVE_SELF = "REMOVE_SELF",
}

export type core_apimessages_BrowsePageLinksRequest = {
  __typename?: "core_apimessages_BrowsePageLinksRequest";
  _source?: Maybe<Scalars["String"]["output"]>;
  id?: Maybe<Scalars["String"]["output"]>;
};

export type core_apimessages_BrowsePageLinksResponse = {
  __typename?: "core_apimessages_BrowsePageLinksResponse";
  _source?: Maybe<Scalars["String"]["output"]>;
  browsePageLinks?: Maybe<Array<Maybe<core_apimessages_BrowsePageLink>>>;
};

export type core_apimessages_BulkAddressesRequest = {
  __typename?: "core_apimessages_BulkAddressesRequest";
  _source?: Maybe<Scalars["String"]["output"]>;
  queries?: Maybe<Array<Maybe<core_apimessages_BulkAddressesRequest_Query>>>;
};

export type core_apimessages_BulkAddressesRequest_Query = {
  __typename?: "core_apimessages_BulkAddressesRequest_Query";
  _source?: Maybe<Scalars["String"]["output"]>;
  primaryKey?: Maybe<core_apimessages_PrimaryKey>;
  relation?: Maybe<core_apimessages_BulkAddressesRequest_Relation>;
};

export enum core_apimessages_BulkAddressesRequest_Relation {
  EU_ODR_PLATFORM = "EU_ODR_PLATFORM",
  /** no pk */
  MY_SHIPPING = "MY_SHIPPING",
  /** no pk */
  MY_SHOP = "MY_SHOP",
  /** OrderBundle pk */
  ORDER_BUNDLE_SHIPPING = "ORDER_BUNDLE_SHIPPING",
  /** Order pk */
  ORDER_SHIPPING = "ORDER_SHIPPING",
  UNKNOWN = "UNKNOWN",
}

export type core_apimessages_BulkAddressesResponse = {
  __typename?: "core_apimessages_BulkAddressesResponse";
  _source?: Maybe<Scalars["String"]["output"]>;
  results?: Maybe<
    Array<Maybe<core_apimessages_BulkAddressesResponse_QueryResult>>
  >;
};

export type core_apimessages_BulkAddressesResponse_QueryResult = {
  __typename?: "core_apimessages_BulkAddressesResponse_QueryResult";
  _source?: Maybe<Scalars["String"]["output"]>;
  addresses?: Maybe<Array<Maybe<core_apimessages_Address>>>;
};

export type core_apimessages_BulkCategoriesRequest = {
  __typename?: "core_apimessages_BulkCategoriesRequest";
  _source?: Maybe<Scalars["String"]["output"]>;
  requests?: Maybe<Array<Maybe<core_apimessages_CategoryRequest>>>;
};

export type core_apimessages_BulkCategoriesResponse = {
  __typename?: "core_apimessages_BulkCategoriesResponse";
  _source?: Maybe<Scalars["String"]["output"]>;
  responses?: Maybe<Array<Maybe<core_apimessages_CategoriesResponse>>>;
};

export type core_apimessages_BulkCreateAutoOffersAsyncRequest = {
  __typename?: "core_apimessages_BulkCreateAutoOffersAsyncRequest";
  _source?: Maybe<Scalars["String"]["output"]>;
  expectedTotal?: Maybe<Scalars["Int"]["output"]>;
  percentage?: Maybe<Scalars["Int"]["output"]>;
  /** Serialized JSON of dashboard listing search params */
  serializedDashboardSearchParams?: Maybe<Scalars["String"]["output"]>;
};

export type core_apimessages_BulkCreateAutoOffersRequest = {
  __typename?: "core_apimessages_BulkCreateAutoOffersRequest";
  _source?: Maybe<Scalars["String"]["output"]>;
  listingIds?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
  percentage?: Maybe<Scalars["Int"]["output"]>;
};

export type core_apimessages_BulkCreateBumpedAsyncRequest = {
  __typename?: "core_apimessages_BulkCreateBumpedAsyncRequest";
  _source?: Maybe<Scalars["String"]["output"]>;
  expectedTotal?: Maybe<Scalars["Int"]["output"]>;
  rate?: Maybe<Scalars["Float"]["output"]>;
  /** Serialized JSON of dashboard listing search params */
  serializedDashboardSearchParams?: Maybe<Scalars["String"]["output"]>;
};

export type core_apimessages_BulkCreateBumpedRequest = {
  __typename?: "core_apimessages_BulkCreateBumpedRequest";
  _source?: Maybe<Scalars["String"]["output"]>;
  listingIds?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
  rate?: Maybe<Scalars["Float"]["output"]>;
};

export type core_apimessages_BulkCreateZeroPercentAffirmAsyncRequest = {
  __typename?: "core_apimessages_BulkCreateZeroPercentAffirmAsyncRequest";
  _source?: Maybe<Scalars["String"]["output"]>;
  expectedTotal?: Maybe<Scalars["Int"]["output"]>;
  /** Serialized JSON of dashboard listing search params */
  serializedDashboardSearchParams?: Maybe<Scalars["String"]["output"]>;
};

export type core_apimessages_BulkCreateZeroPercentAffirmRequest = {
  __typename?: "core_apimessages_BulkCreateZeroPercentAffirmRequest";
  _source?: Maybe<Scalars["String"]["output"]>;
  listingIds?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
};

export type core_apimessages_BulkCuratedSetsRequest = {
  __typename?: "core_apimessages_BulkCuratedSetsRequest";
  _source?: Maybe<Scalars["String"]["output"]>;
  queries?: Maybe<Array<Maybe<core_apimessages_BulkCuratedSetsRequest_Query>>>;
};

export type core_apimessages_BulkCuratedSetsRequest_Query = {
  __typename?: "core_apimessages_BulkCuratedSetsRequest_Query";
  _source?: Maybe<Scalars["String"]["output"]>;
  primaryKey?: Maybe<core_apimessages_PrimaryKey>;
  relation?: Maybe<core_apimessages_BulkCuratedSetsRequest_Relation>;
  setTypes?: Maybe<Array<Maybe<core_apimessages_CuratedSet_SetType>>>;
};

export enum core_apimessages_BulkCuratedSetsRequest_Relation {
  SELF = "SELF",
  STOREFRONT = "STOREFRONT",
}

export type core_apimessages_BulkCuratedSetsResponse = {
  __typename?: "core_apimessages_BulkCuratedSetsResponse";
  _source?: Maybe<Scalars["String"]["output"]>;
  results?: Maybe<
    Array<Maybe<core_apimessages_BulkCuratedSetsResponse_QueryResult>>
  >;
};

export type core_apimessages_BulkCuratedSetsResponse_QueryResult = {
  __typename?: "core_apimessages_BulkCuratedSetsResponse_QueryResult";
  _source?: Maybe<Scalars["String"]["output"]>;
  curatedSets?: Maybe<Array<Maybe<core_apimessages_CuratedSet>>>;
};

export type core_apimessages_BulkDeleteAutoOffersAsyncRequest = {
  __typename?: "core_apimessages_BulkDeleteAutoOffersAsyncRequest";
  _source?: Maybe<Scalars["String"]["output"]>;
  expectedTotal?: Maybe<Scalars["Int"]["output"]>;
  /** Serialized JSON of dashboard listing search params */
  serializedDashboardSearchParams?: Maybe<Scalars["String"]["output"]>;
};

export type core_apimessages_BulkDeleteAutoOffersRequest = {
  __typename?: "core_apimessages_BulkDeleteAutoOffersRequest";
  _source?: Maybe<Scalars["String"]["output"]>;
  listingIds?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
};

export type core_apimessages_BulkDeleteBumpedAsyncRequest = {
  __typename?: "core_apimessages_BulkDeleteBumpedAsyncRequest";
  _source?: Maybe<Scalars["String"]["output"]>;
  expectedTotal?: Maybe<Scalars["Int"]["output"]>;
  /** Serialized JSON of dashboard listing search params */
  serializedDashboardSearchParams?: Maybe<Scalars["String"]["output"]>;
};

export type core_apimessages_BulkDeleteBumpedRequest = {
  __typename?: "core_apimessages_BulkDeleteBumpedRequest";
  _source?: Maybe<Scalars["String"]["output"]>;
  listingIds?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
};

export type core_apimessages_BulkDeleteListingsAsyncRequest = {
  __typename?: "core_apimessages_BulkDeleteListingsAsyncRequest";
  _source?: Maybe<Scalars["String"]["output"]>;
  expectedTotal?: Maybe<Scalars["Int"]["output"]>;
  /** Serialized JSON of dashboard listing search params */
  serializedDashboardSearchParams?: Maybe<Scalars["String"]["output"]>;
};

export type core_apimessages_BulkDeleteListingsRequest = {
  __typename?: "core_apimessages_BulkDeleteListingsRequest";
  _source?: Maybe<Scalars["String"]["output"]>;
  listingIds?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
};

export type core_apimessages_BulkDeleteListingsResponse = {
  __typename?: "core_apimessages_BulkDeleteListingsResponse";
  _source?: Maybe<Scalars["String"]["output"]>;
  listings?: Maybe<
    Array<Maybe<core_apimessages_BulkDeleteListingsResponse_ListingDeleted>>
  >;
};

export type core_apimessages_BulkDeleteListingsResponse_ListingDeleted = {
  __typename?: "core_apimessages_BulkDeleteListingsResponse_ListingDeleted";
  _source?: Maybe<Scalars["String"]["output"]>;
  deleteErrors?: Maybe<Array<Maybe<core_apimessages_Error>>>;
  listingId?: Maybe<Scalars["String"]["output"]>;
  success?: Maybe<Scalars["Boolean"]["output"]>;
};

export type core_apimessages_BulkDeleteZeroPercentAffirmAsyncRequest = {
  __typename?: "core_apimessages_BulkDeleteZeroPercentAffirmAsyncRequest";
  _source?: Maybe<Scalars["String"]["output"]>;
  expectedTotal?: Maybe<Scalars["Int"]["output"]>;
  /** Serialized JSON of dashboard listing search params */
  serializedDashboardSearchParams?: Maybe<Scalars["String"]["output"]>;
};

export type core_apimessages_BulkDeleteZeroPercentAffirmRequest = {
  __typename?: "core_apimessages_BulkDeleteZeroPercentAffirmRequest";
  _source?: Maybe<Scalars["String"]["output"]>;
  listingIds?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
};

export type core_apimessages_BulkExportListingsRequest = {
  __typename?: "core_apimessages_BulkExportListingsRequest";
  _source?: Maybe<Scalars["String"]["output"]>;
  listingIds?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
  recipientId?: Maybe<Scalars["String"]["output"]>;
  serializedDashboardSearchParams?: Maybe<Scalars["String"]["output"]>;
  state?: Maybe<core_apimessages_BulkExportListingsRequest_ListingState>;
};

export enum core_apimessages_BulkExportListingsRequest_ListingState {
  ALL = "ALL",
  DRAFT = "DRAFT",
  ENDED = "ENDED",
  LIVE = "LIVE",
  ORDERED = "ORDERED",
  SOLD_OUT = "SOLD_OUT",
  SUSPENDED = "SUSPENDED",
  VACATION = "VACATION",
}

export type core_apimessages_BulkExportListingsResponse = {
  __typename?: "core_apimessages_BulkExportListingsResponse";
  _source?: Maybe<Scalars["String"]["output"]>;
  enqueued?: Maybe<Scalars["Boolean"]["output"]>;
  message?: Maybe<Scalars["String"]["output"]>;
};

export type core_apimessages_BulkImageRequest = {
  __typename?: "core_apimessages_BulkImageRequest";
  _source?: Maybe<Scalars["String"]["output"]>;
  requests?: Maybe<Array<Maybe<core_apimessages_ImageRequest>>>;
};

export type core_apimessages_BulkOrdersRequest = {
  __typename?: "core_apimessages_BulkOrdersRequest";
  _source?: Maybe<Scalars["String"]["output"]>;
  queries?: Maybe<Array<Maybe<core_apimessages_BulkOrdersRequest_Query>>>;
};

export enum core_apimessages_BulkOrdersRequest_Party {
  BUYER = "BUYER",
  SELLER = "SELLER",
}

export type core_apimessages_BulkOrdersRequest_Query = {
  __typename?: "core_apimessages_BulkOrdersRequest_Query";
  _source?: Maybe<Scalars["String"]["output"]>;
  as?: Maybe<core_apimessages_BulkOrdersRequest_Party>;
  primaryKey?: Maybe<core_apimessages_PrimaryKey>;
  relation?: Maybe<core_apimessages_BulkOrdersRequest_Relation>;
};

export enum core_apimessages_BulkOrdersRequest_Relation {
  /** Listing PK */
  LISTING = "LISTING",
  /** OrderBundle pk */
  ORDER_BUNDLE = "ORDER_BUNDLE",
  /** Order pk */
  SELF = "SELF",
  UNKNOWN = "UNKNOWN",
}

export type core_apimessages_BulkOrdersResponse = {
  __typename?: "core_apimessages_BulkOrdersResponse";
  _source?: Maybe<Scalars["String"]["output"]>;
  results?: Maybe<
    Array<Maybe<core_apimessages_BulkOrdersResponse_QueryResult>>
  >;
};

export type core_apimessages_BulkOrdersResponse_QueryResult = {
  __typename?: "core_apimessages_BulkOrdersResponse_QueryResult";
  _source?: Maybe<Scalars["String"]["output"]>;
  orders?: Maybe<Array<Maybe<core_apimessages_Order>>>;
};

export type core_apimessages_BulkPublishListingsAsyncRequest = {
  __typename?: "core_apimessages_BulkPublishListingsAsyncRequest";
  _source?: Maybe<Scalars["String"]["output"]>;
  expectedTotal?: Maybe<Scalars["Int"]["output"]>;
  /** Serialized JSON of dashboard listing search params */
  serializedDashboardSearchParams?: Maybe<Scalars["String"]["output"]>;
};

export type core_apimessages_BulkPublishListingsRequest = {
  __typename?: "core_apimessages_BulkPublishListingsRequest";
  _source?: Maybe<Scalars["String"]["output"]>;
  listingIds?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
};

export type core_apimessages_BulkPublishListingsResponse = {
  __typename?: "core_apimessages_BulkPublishListingsResponse";
  _source?: Maybe<Scalars["String"]["output"]>;
  listings?: Maybe<
    Array<Maybe<core_apimessages_BulkPublishListingsResponse_ListingPublished>>
  >;
  prepublishStepRequired?: Maybe<core_apimessages_BulkPublishListingsResponse_PrepublishStepRequired>;
};

export type core_apimessages_BulkPublishListingsResponse_ListingPublished = {
  __typename?: "core_apimessages_BulkPublishListingsResponse_ListingPublished";
  _source?: Maybe<Scalars["String"]["output"]>;
  listingId?: Maybe<Scalars["String"]["output"]>;
  publishErrors?: Maybe<Array<Maybe<core_apimessages_Error>>>;
  success?: Maybe<Scalars["Boolean"]["output"]>;
};

export type core_apimessages_BulkPublishListingsResponse_PrepublishStepRequired =
  {
    __typename?: "core_apimessages_BulkPublishListingsResponse_PrepublishStepRequired";
    _source?: Maybe<Scalars["String"]["output"]>;
    step?: Maybe<Scalars["String"]["output"]>;
  };

export type core_apimessages_BulkSaleMembershipsRequest = {
  __typename?: "core_apimessages_BulkSaleMembershipsRequest";
  _source?: Maybe<Scalars["String"]["output"]>;
  listingIds?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
  saleId?: Maybe<Scalars["String"]["output"]>;
};

export type core_apimessages_BulkTaxPoliciesRequest = {
  __typename?: "core_apimessages_BulkTaxPoliciesRequest";
  _source?: Maybe<Scalars["String"]["output"]>;
  requests?: Maybe<Array<Maybe<core_apimessages_TaxPoliciesRequest>>>;
};

export type core_apimessages_BulkTaxPoliciesResponse = {
  __typename?: "core_apimessages_BulkTaxPoliciesResponse";
  _source?: Maybe<Scalars["String"]["output"]>;
  shops?: Maybe<Array<Maybe<core_apimessages_ShopTaxPolicies>>>;
};

/** this is the encoded bump key sent as a response */
export type core_apimessages_BumpKey = {
  __typename?: "core_apimessages_BumpKey";
  _source?: Maybe<Scalars["String"]["output"]>;
  /** encoded bump key */
  key?: Maybe<Scalars["String"]["output"]>;
};

/** this is the shape of the bumpKey before it gets encoded */
export type core_apimessages_BumpKeyContents = {
  __typename?: "core_apimessages_BumpKeyContents";
  _source?: Maybe<Scalars["String"]["output"]>;
  cookieId?: Maybe<Scalars["String"]["output"]>;
  /** JWT Issued At Token, integer timestamp */
  iat?: Maybe<Scalars["Int"]["output"]>;
  /** JWT UUID */
  jti?: Maybe<Scalars["String"]["output"]>;
  productId?: Maybe<Scalars["String"]["output"]>;
  sessionId?: Maybe<Scalars["String"]["output"]>;
  source?: Maybe<core_apimessages_BumpKeyContents_OffsiteSource>;
  userId?: Maybe<Scalars["String"]["output"]>;
};

export enum core_apimessages_BumpKeyContents_OffsiteSource {
  EMAIL = "EMAIL",
  NONE = "NONE",
}

export type core_apimessages_BumpKeysRequest = {
  __typename?: "core_apimessages_BumpKeysRequest";
  _source?: Maybe<Scalars["String"]["output"]>;
  listingIds?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
};

export type core_apimessages_BumpKeysResponse = {
  __typename?: "core_apimessages_BumpKeysResponse";
  _source?: Maybe<Scalars["String"]["output"]>;
  bumpKeys?: Maybe<Array<Maybe<core_apimessages_BumpKey>>>;
};

export type core_apimessages_BumpRate = {
  __typename?: "core_apimessages_BumpRate";
  _source?: Maybe<Scalars["String"]["output"]>;
  rate?: Maybe<Scalars["Float"]["output"]>;
};

export type core_apimessages_BusinessRegistrationNumber = {
  __typename?: "core_apimessages_BusinessRegistrationNumber";
  _source?: Maybe<Scalars["String"]["output"]>;
  countryCode?: Maybe<Scalars["String"]["output"]>;
  id?: Maybe<Scalars["String"]["output"]>;
  registrationNumber?: Maybe<Scalars["String"]["output"]>;
};

export type core_apimessages_BuyWithConfidenceSignal = {
  __typename?: "core_apimessages_BuyWithConfidenceSignal";
  _source?: Maybe<Scalars["String"]["output"]>;
  isValid?: Maybe<Scalars["Boolean"]["output"]>;
  link?: Maybe<core_apimessages_SignalLink>;
  translations?: Maybe<core_apimessages_SignalTranslation>;
};

export type core_apimessages_BuyerActions = {
  __typename?: "core_apimessages_BuyerActions";
  _source?: Maybe<Scalars["String"]["output"]>;
  canBeResold?: Maybe<Scalars["Boolean"]["output"]>;
  canMarkReceived?: Maybe<Scalars["Boolean"]["output"]>;
};

export type core_apimessages_BuyerOrder = {
  __typename?: "core_apimessages_BuyerOrder";
  _source?: Maybe<Scalars["String"]["output"]>;
  amountBonusBucks?: Maybe<core_apimessages_Money>;
  amountCreditBucks?: Maybe<core_apimessages_Money>;
  amountOwed?: Maybe<core_apimessages_Money>;
  amountProduct?: Maybe<core_apimessages_Money>;
  amountProductSubtotal?: Maybe<core_apimessages_Money>;
  amountShipping?: Maybe<core_apimessages_Money>;
  amountTax?: Maybe<core_apimessages_Money>;
  amountTotal?: Maybe<core_apimessages_Money>;
  buyerActions?: Maybe<core_apimessages_BuyerActions>;
  protectionPlanDetails?: Maybe<core_apimessages_OrderProtectionPlanDetails>;
  thirdPartyAdsAttributes?: Maybe<core_apimessages_ThirdPartyAdsAttributes>;
  uuid?: Maybe<Scalars["String"]["output"]>;
};

export type core_apimessages_BuyerOrdersResponse = {
  __typename?: "core_apimessages_BuyerOrdersResponse";
  _source?: Maybe<Scalars["String"]["output"]>;
  orders?: Maybe<Array<Maybe<core_apimessages_BuyerOrder>>>;
};

export type core_apimessages_BuyerRefund = {
  __typename?: "core_apimessages_BuyerRefund";
  _source?: Maybe<Scalars["String"]["output"]>;
  id?: Maybe<Scalars["String"]["output"]>;
  nonBucksRefundAmount?: Maybe<core_apimessages_ConvertedMoney>;
  reverbBucksAmountRefunded?: Maybe<core_apimessages_Money>;
};

export type core_apimessages_BuyerRefundsRequest = {
  __typename?: "core_apimessages_BuyerRefundsRequest";
  _source?: Maybe<Scalars["String"]["output"]>;
  ids?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
};

export type core_apimessages_BuyerRefundsResponse = {
  __typename?: "core_apimessages_BuyerRefundsResponse";
  _source?: Maybe<Scalars["String"]["output"]>;
  buyerRefunds?: Maybe<Array<Maybe<core_apimessages_BuyerRefund>>>;
};

export type core_apimessages_CPSuggestion = {
  __typename?: "core_apimessages_CPSuggestion";
  _source?: Maybe<Scalars["String"]["output"]>;
  canonicalProduct?: Maybe<core_apimessages_AutomatchingCanonicalProduct>;
  canonicalProductId?: Maybe<Scalars["String"]["output"]>;
  confidence?: Maybe<Scalars["Float"]["output"]>;
  service?: Maybe<Scalars["String"]["output"]>;
};

export type core_apimessages_CPSuggestions = {
  __typename?: "core_apimessages_CPSuggestions";
  _source?: Maybe<Scalars["String"]["output"]>;
  suggestions?: Maybe<Array<Maybe<core_apimessages_CPSuggestion>>>;
};

export type core_apimessages_CPSuggestionsRequest = {
  __typename?: "core_apimessages_CPSuggestionsRequest";
  _source?: Maybe<Scalars["String"]["output"]>;
  listingIds?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
};

export type core_apimessages_CPSuggestionsResponse = {
  __typename?: "core_apimessages_CPSuggestionsResponse";
  _source?: Maybe<Scalars["String"]["output"]>;
  cpSuggestions?: Maybe<Array<Maybe<core_apimessages_CPSuggestions>>>;
};

export type core_apimessages_CSP = {
  __typename?: "core_apimessages_CSP";
  _source?: Maybe<Scalars["String"]["output"]>;
  body?: Maybe<Scalars["String"]["output"]>;
  brandUuid?: Maybe<Scalars["String"]["output"]>;
  canonicalProductIds?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
  canonicalProductImageApprovedIds?: Maybe<
    Array<Maybe<Scalars["String"]["output"]>>
  >;
  categoryRootUuid?: Maybe<Scalars["String"]["output"]>;
  categoryUuids?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
  finishes?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
  generic?: Maybe<Scalars["Boolean"]["output"]>;
  /** comparison_shopping_pages.id */
  id?: Maybe<Scalars["String"]["output"]>;
  metaDescription?: Maybe<Scalars["String"]["output"]>;
  model?: Maybe<Scalars["String"]["output"]>;
  /** @deprecated Field no longer supported */
  newListingsLink?: Maybe<core_apimessages_Link>;
  slug?: Maybe<Scalars["String"]["output"]>;
  summary?: Maybe<Scalars["String"]["output"]>;
  title?: Maybe<Scalars["String"]["output"]>;
  upcs?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
  /** @deprecated Field no longer supported */
  usedListingsLink?: Maybe<core_apimessages_Link>;
  uuid?: Maybe<Scalars["String"]["output"]>;
  webLink?: Maybe<core_apimessages_Link>;
};

export type core_apimessages_CSPExpressSaleItemBid = {
  __typename?: "core_apimessages_CSPExpressSaleItemBid";
  _source?: Maybe<Scalars["String"]["output"]>;
  bidId?: Maybe<Scalars["String"]["output"]>;
  carrier?: Maybe<Scalars["String"]["output"]>;
  cspUuid?: Maybe<Scalars["String"]["output"]>;
  estimatedPayout?: Maybe<core_apimessages_Money>;
  shopName?: Maybe<Scalars["String"]["output"]>;
};

export type core_apimessages_CSPInventory = {
  __typename?: "core_apimessages_CSPInventory";
  _source?: Maybe<Scalars["String"]["output"]>;
  /** comparison_shopping_pages.id */
  id?: Maybe<Scalars["String"]["output"]>;
  newLowPrice?: Maybe<core_apimessages_Money>;
  newTotal?: Maybe<Scalars["Int"]["output"]>;
  /** comparison_shopping_pages.slug */
  slug?: Maybe<Scalars["String"]["output"]>;
  usedLowPrice?: Maybe<core_apimessages_Money>;
  usedTotal?: Maybe<Scalars["Int"]["output"]>;
  /** comparison_shopping_pages.uuid */
  uuid?: Maybe<Scalars["String"]["output"]>;
};

export type core_apimessages_CSPPricing = {
  __typename?: "core_apimessages_CSPPricing";
  _source?: Maybe<Scalars["String"]["output"]>;
  hasData?: Maybe<Scalars["Boolean"]["output"]>;
  high?: Maybe<core_apimessages_Money>;
  low?: Maybe<core_apimessages_Money>;
  mid?: Maybe<core_apimessages_Money>;
};

export type core_apimessages_CSPReview = {
  __typename?: "core_apimessages_CSPReview";
  _source?: Maybe<Scalars["String"]["output"]>;
  blocked?: Maybe<Scalars["Boolean"]["output"]>;
  body?: Maybe<Scalars["String"]["output"]>;
  createdAt?: Maybe<Scalars["String"]["output"]>;
  didIVote?: Maybe<Scalars["Boolean"]["output"]>;
  id?: Maybe<Scalars["String"]["output"]>;
  isMyReview?: Maybe<Scalars["Boolean"]["output"]>;
  rating?: Maybe<Scalars["Int"]["output"]>;
  reviewer?: Maybe<core_apimessages_Reviewer>;
  title?: Maybe<Scalars["String"]["output"]>;
  verified?: Maybe<Scalars["Boolean"]["output"]>;
  voteCount?: Maybe<Scalars["Int"]["output"]>;
};

export type core_apimessages_CSPReviews = {
  __typename?: "core_apimessages_CSPReviews";
  _source?: Maybe<Scalars["String"]["output"]>;
  avgRating?: Maybe<Scalars["Float"]["output"]>;
  limit?: Maybe<Scalars["Int"]["output"]>;
  myReview?: Maybe<core_apimessages_CSPReview>;
  offset?: Maybe<Scalars["Int"]["output"]>;
  reviews?: Maybe<Array<Maybe<core_apimessages_CSPReview>>>;
  total?: Maybe<Scalars["Int"]["output"]>;
};

export type core_apimessages_CSPReviewsRequest = {
  __typename?: "core_apimessages_CSPReviewsRequest";
  _source?: Maybe<Scalars["String"]["output"]>;
  ids?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
  limit?: Maybe<Scalars["Int"]["output"]>;
  offset?: Maybe<Scalars["Int"]["output"]>;
  slugs?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
  uuids?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
};

export type core_apimessages_CSPReviewsResponse = {
  __typename?: "core_apimessages_CSPReviewsResponse";
  _source?: Maybe<Scalars["String"]["output"]>;
  reviews?: Maybe<Array<Maybe<core_apimessages_CSPReviews>>>;
};

export type core_apimessages_CSPSpec = {
  __typename?: "core_apimessages_CSPSpec";
  _source?: Maybe<Scalars["String"]["output"]>;
  productSpecs?: Maybe<Array<Maybe<core_apimessages_ProductSpec>>>;
};

export type core_apimessages_CSPVideo = {
  __typename?: "core_apimessages_CSPVideo";
  _source?: Maybe<Scalars["String"]["output"]>;
  videoLinks?: Maybe<Array<Maybe<core_apimessages_VideoLink>>>;
};

export type core_apimessages_CSPsFeaturedListingRequest = {
  __typename?: "core_apimessages_CSPsFeaturedListingRequest";
  _source?: Maybe<Scalars["String"]["output"]>;
  /** comparison_shopping_pages.id */
  ids?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
  slugs?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
  uuids?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
};

export type core_apimessages_CSPsFeaturedListingResponse = {
  __typename?: "core_apimessages_CSPsFeaturedListingResponse";
  _source?: Maybe<Scalars["String"]["output"]>;
  featuredListingIds?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
};

export type core_apimessages_CSPsInventoryRequest = {
  __typename?: "core_apimessages_CSPsInventoryRequest";
  _source?: Maybe<Scalars["String"]["output"]>;
  /** comparison_shopping_pages.id */
  ids?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
  slugs?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
  uuids?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
};

export type core_apimessages_CSPsInventoryResponse = {
  __typename?: "core_apimessages_CSPsInventoryResponse";
  _source?: Maybe<Scalars["String"]["output"]>;
  inventories?: Maybe<Array<Maybe<core_apimessages_CSPInventory>>>;
};

export type core_apimessages_CSPsRequest = {
  __typename?: "core_apimessages_CSPsRequest";
  _source?: Maybe<Scalars["String"]["output"]>;
  ids?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
  slugs?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
  uuids?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
};

export type core_apimessages_CSPsResponse = {
  __typename?: "core_apimessages_CSPsResponse";
  _source?: Maybe<Scalars["String"]["output"]>;
  csps?: Maybe<Array<Maybe<core_apimessages_CSP>>>;
};

export type core_apimessages_CSPsSpecsRequest = {
  __typename?: "core_apimessages_CSPsSpecsRequest";
  _source?: Maybe<Scalars["String"]["output"]>;
  /** comparison_shopping_pages.id */
  ids?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
  slugs?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
  uuids?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
};

export type core_apimessages_CSPsSpecsResponse = {
  __typename?: "core_apimessages_CSPsSpecsResponse";
  _source?: Maybe<Scalars["String"]["output"]>;
  specs?: Maybe<Array<Maybe<core_apimessages_CSPSpec>>>;
};

export type core_apimessages_CSPsVideoRequest = {
  __typename?: "core_apimessages_CSPsVideoRequest";
  _source?: Maybe<Scalars["String"]["output"]>;
  /** comparison_shopping_pages.id */
  ids?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
  slugs?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
  uuids?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
};

export type core_apimessages_CSPsVideoResponse = {
  __typename?: "core_apimessages_CSPsVideoResponse";
  _source?: Maybe<Scalars["String"]["output"]>;
  videos?: Maybe<Array<Maybe<core_apimessages_CSPVideo>>>;
};

export type core_apimessages_CancelOrderRequest = {
  __typename?: "core_apimessages_CancelOrderRequest";
  _source?: Maybe<Scalars["String"]["output"]>;
  cancellationReason?: Maybe<core_apimessages_CancelOrderRequest_CancellationReason>;
  id?: Maybe<Scalars["String"]["output"]>;
  relist?: Maybe<Scalars["Boolean"]["output"]>;
  uuid?: Maybe<Scalars["String"]["output"]>;
};

export enum core_apimessages_CancelOrderRequest_CancellationReason {
  ALREADY_SOLD_REASON = "ALREADY_SOLD_REASON",
  BUYER_ABANDONED_REASON = "BUYER_ABANDONED_REASON",
  PAYMENT_ARRANGEMENT_REASON = "PAYMENT_ARRANGEMENT_REASON",
  UNKNOWN = "UNKNOWN",
}

export type core_apimessages_CancelOrderResponse = {
  __typename?: "core_apimessages_CancelOrderResponse";
  _source?: Maybe<Scalars["String"]["output"]>;
  order?: Maybe<core_apimessages_Order>;
  relisted?: Maybe<Scalars["Boolean"]["output"]>;
};

export type core_apimessages_CancelPlaidAccountRequest = {
  __typename?: "core_apimessages_CancelPlaidAccountRequest";
  _?: Maybe<Scalars["Boolean"]["output"]>;
  _source?: Maybe<Scalars["String"]["output"]>;
};

export type core_apimessages_CancelPlaidAccountResponse = {
  __typename?: "core_apimessages_CancelPlaidAccountResponse";
  _source?: Maybe<Scalars["String"]["output"]>;
  shopId?: Maybe<Scalars["String"]["output"]>;
};

export type core_apimessages_Candidate = {
  __typename?: "core_apimessages_Candidate";
  _source?: Maybe<Scalars["String"]["output"]>;
  adGroup?: Maybe<core_apimessages_AdGroup>;
  matches?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
  weight?: Maybe<Scalars["Int"]["output"]>;
};

export type core_apimessages_CanonicalProduct = {
  __typename?: "core_apimessages_CanonicalProduct";
  _source?: Maybe<Scalars["String"]["output"]>;
  countryOfOrigin?: Maybe<Scalars["String"]["output"]>;
  finish?: Maybe<Scalars["String"]["output"]>;
  id?: Maybe<Scalars["String"]["output"]>;
  make?: Maybe<Scalars["String"]["output"]>;
  model?: Maybe<Scalars["String"]["output"]>;
  name?: Maybe<Scalars["String"]["output"]>;
  primaryImage?: Maybe<core_apimessages_Image>;
  year?: Maybe<Scalars["String"]["output"]>;
};

export type core_apimessages_CanonicalProductprimaryImageArgs = {
  input: Input_core_apimessages_ImagesRequest;
};

export type core_apimessages_CarrierRate = {
  __typename?: "core_apimessages_CarrierRate";
  _source?: Maybe<Scalars["String"]["output"]>;
  carrier?: Maybe<Scalars["String"]["output"]>;
  description?: Maybe<Scalars["String"]["output"]>;
  displayPosition?: Maybe<Scalars["Int"]["output"]>;
  expeditedOrFaster?: Maybe<Scalars["Boolean"]["output"]>;
  paymentMethod?: Maybe<core_apimessages_FeePaymentMethod>;
  rackRateAmount?: Maybe<core_apimessages_Money>;
  reverbDiscountAmount?: Maybe<core_apimessages_Money>;
  service?: Maybe<Scalars["String"]["output"]>;
  shippingProtectionAmount?: Maybe<core_apimessages_Money>;
  signatureRequiredAmount?: Maybe<core_apimessages_Money>;
  totalAmount?: Maybe<core_apimessages_Money>;
  uuid?: Maybe<Scalars["String"]["output"]>;
};

export type core_apimessages_CarrierRateGroup = {
  __typename?: "core_apimessages_CarrierRateGroup";
  _source?: Maybe<Scalars["String"]["output"]>;
  carrier?: Maybe<Scalars["String"]["output"]>;
  displayPosition?: Maybe<Scalars["Int"]["output"]>;
  rates?: Maybe<Array<Maybe<core_apimessages_CarrierRate>>>;
};

export type core_apimessages_CartItem = {
  __typename?: "core_apimessages_CartItem";
  _id?: Maybe<Scalars["ID"]["output"]>;
  _source?: Maybe<Scalars["String"]["output"]>;
  actions?: Maybe<core_apimessages_CartItem_Actions>;
  alerts?: Maybe<Array<Maybe<core_apimessages_CartItem_Alert>>>;
  listing?: Maybe<Listing>;
  listingId?: Maybe<Scalars["String"]["output"]>;
  listingTotal?: Maybe<core_apimessages_CartItem_ListingTotal>;
  order?: Maybe<Order>;
  orderId?: Maybe<Scalars["String"]["output"]>;
  paymentMethods?: Maybe<Array<Maybe<core_apimessages_CartItem_PaymentMethod>>>;
  protectionPlan?: Maybe<core_apimessages_ProtectionPlan>;
  protectionPlanEligible?: Maybe<Scalars["Boolean"]["output"]>;
  protectionPlanOptions?: Maybe<
    Array<Maybe<core_apimessages_ProtectionPlanOptions>>
  >;
  quantity?: Maybe<Scalars["Int"]["output"]>;
  shippingTotal?: Maybe<core_apimessages_CartItem_ShippingTotal>;
  shopId?: Maybe<Scalars["String"]["output"]>;
  /** Estimated subtotal based on listing price and quantity */
  subtotal?: Maybe<core_apimessages_Money>;
  uuid?: Maybe<Scalars["String"]["output"]>;
};

export type core_apimessages_CartItemAttributes = {
  __typename?: "core_apimessages_CartItemAttributes";
  _source?: Maybe<Scalars["String"]["output"]>;
  actionSourceAttributes?: Maybe<core_apimessages_ActionSourceAttributes>;
  cartSource?: Maybe<Scalars["String"]["output"]>;
  listingId?: Maybe<Scalars["Int"]["output"]>;
  quantity?: Maybe<Scalars["Int"]["output"]>;
  saleId?: Maybe<Scalars["Int"]["output"]>;
  upsell?: Maybe<Scalars["Boolean"]["output"]>;
  uuid?: Maybe<Scalars["String"]["output"]>;
};

export type core_apimessages_CartItemBundle = {
  __typename?: "core_apimessages_CartItemBundle";
  _source?: Maybe<Scalars["String"]["output"]>;
  availablePaymentMethods?: Maybe<
    Array<Maybe<core_apimessages_CartItemBundle_PaymentMethod>>
  >;
  cartItems?: Maybe<Array<Maybe<core_apimessages_CartItem>>>;
  checkoutBundlingId?: Maybe<Scalars["String"]["output"]>;
  combineAndSaveEligibleListing?: Maybe<Listing>;
  combineAndSaveEligibleListingId?: Maybe<Scalars["String"]["output"]>;
  freeShippingStatuses?: Maybe<
    Array<Maybe<core_apimessages_CartItemBundle_FreeShippingStatus>>
  >;
  lineItems?: Maybe<Array<Maybe<core_apimessages_CartItemBundle_LineItem>>>;
  paymentMethodIcons?: Maybe<Array<Maybe<core_apimessages_PaymentCardIcon>>>;
  title?: Maybe<Scalars["String"]["output"]>;
  total?: Maybe<core_apimessages_CartItemBundle_Total>;
};

export type core_apimessages_CartItemBundle_FreeShippingStatus = {
  __typename?: "core_apimessages_CartItemBundle_FreeShippingStatus";
  _source?: Maybe<Scalars["String"]["output"]>;
  amountRemaining?: Maybe<core_apimessages_Money>;
  isThresholdMet?: Maybe<Scalars["Boolean"]["output"]>;
  /** Natural number up to 100 */
  percentProgress?: Maybe<Scalars["Int"]["output"]>;
  shop?: Maybe<Shop>;
  shopId?: Maybe<Scalars["String"]["output"]>;
};

export type core_apimessages_CartItemBundle_LineItem = {
  __typename?: "core_apimessages_CartItemBundle_LineItem";
  _source?: Maybe<Scalars["String"]["output"]>;
  amount?: Maybe<core_apimessages_Money>;
  label?: Maybe<Scalars["String"]["output"]>;
  type?: Maybe<core_apimessages_CartItemBundle_LineItem_Type>;
};

export enum core_apimessages_CartItemBundle_LineItem_Type {
  AMOUNT_LISTING_SUBTOTAL = "AMOUNT_LISTING_SUBTOTAL",
  AMOUNT_SHIPPING = "AMOUNT_SHIPPING",
  AMOUNT_TAX = "AMOUNT_TAX",
}

export type core_apimessages_CartItemBundle_PaymentMethod = {
  __typename?: "core_apimessages_CartItemBundle_PaymentMethod";
  _source?: Maybe<Scalars["String"]["output"]>;
  affirm?: Maybe<core_apimessages_CheckoutAffirmDetails>;
  applePay?: Maybe<core_apimessages_CheckoutApplePayDetails>;
  googlePay?: Maybe<core_apimessages_CheckoutGooglePayDetails>;
  name?: Maybe<Scalars["String"]["output"]>;
  paypal?: Maybe<core_apimessages_CheckoutPaypalDetails>;
  type?: Maybe<core_apimessages_CheckoutPaymentMethod_Type>;
};

export type core_apimessages_CartItemBundle_Total = {
  __typename?: "core_apimessages_CartItemBundle_Total";
  _source?: Maybe<Scalars["String"]["output"]>;
  amount?: Maybe<core_apimessages_Money>;
  label?: Maybe<Scalars["String"]["output"]>;
};

export type core_apimessages_CartItem_Actions = {
  __typename?: "core_apimessages_CartItem_Actions";
  _source?: Maybe<Scalars["String"]["output"]>;
  canChangeQuantity?: Maybe<Scalars["Boolean"]["output"]>;
  canMoveToWatchlist?: Maybe<Scalars["Boolean"]["output"]>;
  canRemoveFromCart?: Maybe<Scalars["Boolean"]["output"]>;
};

export type core_apimessages_CartItem_Alert = {
  __typename?: "core_apimessages_CartItem_Alert";
  _source?: Maybe<Scalars["String"]["output"]>;
  severity?: Maybe<core_apimessages_CartItem_Alert_Severity>;
  text?: Maybe<Scalars["String"]["output"]>;
  type?: Maybe<core_apimessages_CartItem_Alert_Type>;
};

export enum core_apimessages_CartItem_Alert_Severity {
  INFO = "INFO",
  WARNING = "WARNING",
}

export enum core_apimessages_CartItem_Alert_Type {
  INTERNATIONAL_SHIPPING = "INTERNATIONAL_SHIPPING",
  REGION_NOT_SUPPORTED = "REGION_NOT_SUPPORTED",
  REPURCHASED_PRICE_CHANGE = "REPURCHASED_PRICE_CHANGE",
}

export type core_apimessages_CartItem_ListingTotal = {
  __typename?: "core_apimessages_CartItem_ListingTotal";
  _source?: Maybe<Scalars["String"]["output"]>;
  discount?: Maybe<core_apimessages_CartItem_ListingTotal_Discount>;
  /** True if order listing price is less than original listing price. */
  discounted?: Maybe<Scalars["Boolean"]["output"]>;
  /** Original unit amount before offer pricing or sales were applied. */
  originalUnitPrice?: Maybe<core_apimessages_Money>;
  /** Unit amount owed by the buyer for a cart item. Includes tax and any discounts from an offer or sale. */
  unitPrice?: Maybe<core_apimessages_Money>;
};

export type core_apimessages_CartItem_ListingTotal_Discount = {
  __typename?: "core_apimessages_CartItem_ListingTotal_Discount";
  _source?: Maybe<Scalars["String"]["output"]>;
  label?: Maybe<Scalars["String"]["output"]>;
  type?: Maybe<core_apimessages_CartItem_ListingTotal_Discount_Type>;
};

export enum core_apimessages_CartItem_ListingTotal_Discount_Type {
  OFFER = "OFFER",
  SALE = "SALE",
}

export type core_apimessages_CartItem_PaymentMethod = {
  __typename?: "core_apimessages_CartItem_PaymentMethod";
  _source?: Maybe<Scalars["String"]["output"]>;
  links?: Maybe<core_apimessages_CartLinks>;
  type?: Maybe<core_apimessages_CheckoutPaymentMethod_Type>;
};

export type core_apimessages_CartItem_ShippingTotal = {
  __typename?: "core_apimessages_CartItem_ShippingTotal";
  _source?: Maybe<Scalars["String"]["output"]>;
  /** Shipping amount owed by buyer including discounts if eligible. */
  amountOwed?: Maybe<core_apimessages_Money>;
  discount?: Maybe<core_apimessages_CartItem_ShippingTotal_Discount>;
  /** True if amount owed is less than original amount owed. */
  discounted?: Maybe<Scalars["Boolean"]["output"]>;
  /** Original shipping amount owed the cart item before any discounts. */
  originalAmountOwed?: Maybe<core_apimessages_Money>;
};

export type core_apimessages_CartItem_ShippingTotal_Discount = {
  __typename?: "core_apimessages_CartItem_ShippingTotal_Discount";
  _source?: Maybe<Scalars["String"]["output"]>;
  label?: Maybe<Scalars["String"]["output"]>;
  type?: Maybe<core_apimessages_CartItem_ShippingTotal_Discount_Type>;
};

export enum core_apimessages_CartItem_ShippingTotal_Discount_Type {
  COMBINED_CHILD = "COMBINED_CHILD",
  COMBINED_PARENT = "COMBINED_PARENT",
  FREE_SHIPPING_THRESHOLD = "FREE_SHIPPING_THRESHOLD",
}

export type core_apimessages_CartLinks = {
  __typename?: "core_apimessages_CartLinks";
  _source?: Maybe<Scalars["String"]["output"]>;
  restBundle?: Maybe<core_apimessages_Link>;
  restIndividual?: Maybe<core_apimessages_Link>;
  web?: Maybe<core_apimessages_Link>;
};

export type core_apimessages_CartMoveToWatchListRequest = {
  __typename?: "core_apimessages_CartMoveToWatchListRequest";
  _source?: Maybe<Scalars["String"]["output"]>;
  cartItemUuid?: Maybe<Scalars["String"]["output"]>;
};

export type core_apimessages_CartMoveToWatchListResponse = {
  __typename?: "core_apimessages_CartMoveToWatchListResponse";
  _source?: Maybe<Scalars["String"]["output"]>;
  /** Listing ID for the watched product */
  listingId?: Maybe<Scalars["String"]["output"]>;
};

export type core_apimessages_CategoriesRequest = {
  __typename?: "core_apimessages_CategoriesRequest";
  _source?: Maybe<Scalars["String"]["output"]>;
  /** Deprecated- maps to categories.uuid */
  ids?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
  slugs?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
  uuids?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
  withChildren?: Maybe<Scalars["Boolean"]["output"]>;
  withParent?: Maybe<Scalars["Boolean"]["output"]>;
};

export type core_apimessages_CategoriesResponse = {
  __typename?: "core_apimessages_CategoriesResponse";
  _source?: Maybe<Scalars["String"]["output"]>;
  categories?: Maybe<Array<Maybe<core_apimessages_Category>>>;
};

export type core_apimessages_Category = {
  __typename?: "core_apimessages_Category";
  _id?: Maybe<Scalars["ID"]["output"]>;
  _source?: Maybe<Scalars["String"]["output"]>;
  children?: Maybe<Array<Maybe<core_apimessages_Category>>>;
  cmsLink?: Maybe<core_apimessages_Link>;
  cmsUrl?: Maybe<core_apimessages_Link>;
  /** categories.uuid */
  id?: Maybe<Scalars["String"]["output"]>;
  leaf?: Maybe<Scalars["Boolean"]["output"]>;
  name?: Maybe<Scalars["String"]["output"]>;
  parent?: Maybe<core_apimessages_Category>;
  root?: Maybe<Scalars["Boolean"]["output"]>;
  rootSlug?: Maybe<Scalars["String"]["output"]>;
  slug?: Maybe<Scalars["String"]["output"]>;
  webLink?: Maybe<core_apimessages_Link>;
};

export type core_apimessages_CategoryRequest = {
  __typename?: "core_apimessages_CategoryRequest";
  _source?: Maybe<Scalars["String"]["output"]>;
  /** This should only be for Article id, Category should use uuid */
  id?: Maybe<Scalars["String"]["output"]>;
  slug?: Maybe<Scalars["String"]["output"]>;
  type?: Maybe<core_apimessages_CategoryRequest_CategoryType>;
  uuid?: Maybe<Scalars["String"]["output"]>;
  withChildren?: Maybe<Scalars["Boolean"]["output"]>;
  withParent?: Maybe<Scalars["Boolean"]["output"]>;
};

export enum core_apimessages_CategoryRequest_CategoryType {
  ALL_FLAT = "ALL_FLAT",
  ARTICLE = "ARTICLE",
  CATEGORY = "CATEGORY",
  UNKNOWN = "UNKNOWN",
}

export enum core_apimessages_Channel {
  LP = "LP",
  REVERB = "REVERB",
  SELLER_SITE = "SELLER_SITE",
}

/** Partially copied into abandoned_cart.proto for abandoned cart emails. */
export type core_apimessages_ChannelBranding = {
  __typename?: "core_apimessages_ChannelBranding";
  _source?: Maybe<Scalars["String"]["output"]>;
  abandonUrl?: Maybe<Scalars["String"]["output"]>;
  channel?: Maybe<core_apimessages_Channel>;
  completeUrl?: Maybe<Scalars["String"]["output"]>;
  logoImageUrl?: Maybe<Scalars["String"]["output"]>;
  name?: Maybe<Scalars["String"]["output"]>;
  /** for seller sites to identify which shop this came from */
  shopIdentifier?: Maybe<Scalars["String"]["output"]>;
};

export type core_apimessages_Checkout = {
  __typename?: "core_apimessages_Checkout";
  _source?: Maybe<Scalars["String"]["output"]>;
  /** A JSON serialized hash */
  adyenPaymentAction?: Maybe<Scalars["String"]["output"]>;
  availablePaymentMethods?: Maybe<
    Array<Maybe<core_apimessages_CheckoutPaymentMethod>>
  >;
  billingAddress?: Maybe<core_apimessages_Address>;
  /** Semi-opaque identifier to bundle a checkout, for example RCP-USD-direct_checkout. */
  checkoutBundlingId?: Maybe<Scalars["String"]["output"]>;
  creditCard?: Maybe<core_apimessages_CreditCard>;
  guest?: Maybe<core_apimessages_CheckoutGuest>;
  /** Checkout UUID */
  id?: Maybe<Scalars["String"]["output"]>;
  isAllPaidWithBucks?: Maybe<Scalars["Boolean"]["output"]>;
  lineItems?: Maybe<Array<Maybe<core_apimessages_CheckoutLineItem>>>;
  orders?: Maybe<Array<Maybe<core_apimessages_CheckoutOrder>>>;
  paymentErrorMessage?: Maybe<Scalars["String"]["output"]>;
  paymentMethod?: Maybe<core_apimessages_CheckoutPaymentMethod>;
  shippingAddress?: Maybe<core_apimessages_Address>;
  source?: Maybe<core_apimessages_Checkout_Source>;
  status?: Maybe<core_apimessages_Checkout_Status>;
  /** Amount owed. Use this field to send the displayed amount owed value to a finalize checkout */
  total?: Maybe<core_apimessages_Checkout_Total>;
  type?: Maybe<core_apimessages_Checkout_Type>;
};

export type core_apimessages_CheckoutAction = {
  __typename?: "core_apimessages_CheckoutAction";
  _source?: Maybe<Scalars["String"]["output"]>;
  redirectUrl?: Maybe<Scalars["String"]["output"]>;
  type?: Maybe<core_apimessages_CheckoutAction_Type>;
};

export type core_apimessages_CheckoutActionRequest = {
  __typename?: "core_apimessages_CheckoutActionRequest";
  _source?: Maybe<Scalars["String"]["output"]>;
  checkoutId?: Maybe<Scalars["String"]["output"]>;
  /** Native app URL param. Corresponds to _reverb_app query param needed for internal navigation. */
  reverbApp?: Maybe<Scalars["String"]["output"]>;
};

export type core_apimessages_CheckoutActionResponse = {
  __typename?: "core_apimessages_CheckoutActionResponse";
  _source?: Maybe<Scalars["String"]["output"]>;
  action?: Maybe<core_apimessages_CheckoutAction>;
};

export enum core_apimessages_CheckoutAction_Type {
  AWAITING_PAYMENT = "AWAITING_PAYMENT",
  CHECKOUT_FAILURE = "CHECKOUT_FAILURE",
  CHECKOUT_SUCCESS = "CHECKOUT_SUCCESS",
  PAYMENT_FAILURE = "PAYMENT_FAILURE",
  PROCESSING_PAYMENT = "PROCESSING_PAYMENT",
  REQUIRES_AUTHENTICATION = "REQUIRES_AUTHENTICATION",
}

export type core_apimessages_CheckoutAffirmDetails = {
  __typename?: "core_apimessages_CheckoutAffirmDetails";
  _source?: Maybe<Scalars["String"]["output"]>;
  financingProgramSlug?: Maybe<Scalars["String"]["output"]>;
};

export type core_apimessages_CheckoutApplePayDetails = {
  __typename?: "core_apimessages_CheckoutApplePayDetails";
  _source?: Maybe<Scalars["String"]["output"]>;
  /** Brand types provided by Adyen such as visa, mc, jcb */
  brands?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
  /** Apple Pay Merchant ID for payment sheet configuration */
  merchantId?: Maybe<Scalars["String"]["output"]>;
  /** Merchant name to display in the payment sheet */
  merchantName?: Maybe<Scalars["String"]["output"]>;
};

export type core_apimessages_CheckoutApplePayDetailsRequest = {
  __typename?: "core_apimessages_CheckoutApplePayDetailsRequest";
  _source?: Maybe<Scalars["String"]["output"]>;
  currency?: Maybe<Scalars["String"]["output"]>;
};

export type core_apimessages_CheckoutApplePayDetailsResponse = {
  __typename?: "core_apimessages_CheckoutApplePayDetailsResponse";
  _source?: Maybe<Scalars["String"]["output"]>;
  details?: Maybe<core_apimessages_CheckoutApplePayDetails>;
};

export type core_apimessages_CheckoutCartEditRequest = {
  __typename?: "core_apimessages_CheckoutCartEditRequest";
  _source?: Maybe<Scalars["String"]["output"]>;
  listingId?: Maybe<Scalars["String"]["output"]>;
  /** Adds a listing by the given quantity if 1 or greater. Removes the listing from the user's cart if zero. */
  quantity?: Maybe<Scalars["Int"]["output"]>;
};

export type core_apimessages_CheckoutCartEditResponse = {
  __typename?: "core_apimessages_CheckoutCartEditResponse";
  _source?: Maybe<Scalars["String"]["output"]>;
  /** If an item was not removed, returns the added item */
  cartItem?: Maybe<core_apimessages_CartItem>;
  listingId?: Maybe<Scalars["String"]["output"]>;
};

export type core_apimessages_CheckoutChallengeShopperRequest = {
  __typename?: "core_apimessages_CheckoutChallengeShopperRequest";
  _source?: Maybe<Scalars["String"]["output"]>;
  channelBrandingToken?: Maybe<Scalars["String"]["output"]>;
  checkoutId?: Maybe<Scalars["String"]["output"]>;
  checkoutUuid?: Maybe<Scalars["String"]["output"]>;
  results?: Maybe<core_apimessages_AdyenChallengeShopperResults>;
  totalPrice?: Maybe<core_apimessages_Money>;
};

export type core_apimessages_CheckoutChallengeShopperResponse = {
  __typename?: "core_apimessages_CheckoutChallengeShopperResponse";
  _source?: Maybe<Scalars["String"]["output"]>;
  adyenPaymentResult?: Maybe<core_apimessages_AdyenPaymentResult>;
  checkoutId?: Maybe<Scalars["String"]["output"]>;
  checkoutUuid?: Maybe<Scalars["String"]["output"]>;
  orderBundleId?: Maybe<Scalars["String"]["output"]>;
  paymentResultUrl?: Maybe<Scalars["String"]["output"]>;
};

export type core_apimessages_CheckoutCreatePaymentRedirectUrlRequest = {
  __typename?: "core_apimessages_CheckoutCreatePaymentRedirectUrlRequest";
  _source?: Maybe<Scalars["String"]["output"]>;
  accertifyDeviceInfo?: Maybe<core_apimessages_AccertifyDeviceInfo>;
  /** Checkout UUID */
  id?: Maybe<Scalars["String"]["output"]>;
  reverbApp?: Maybe<Scalars["String"]["output"]>;
};

export type core_apimessages_CheckoutCreatePaymentRedirectUrlResponse = {
  __typename?: "core_apimessages_CheckoutCreatePaymentRedirectUrlResponse";
  _source?: Maybe<Scalars["String"]["output"]>;
  checkout?: Maybe<Checkout>;
  /** Checkout UUID */
  id?: Maybe<Scalars["String"]["output"]>;
  /** Redirect url for payment method */
  redirectUrl?: Maybe<Scalars["String"]["output"]>;
};

export type core_apimessages_CheckoutFinalizeDigitalWalletPaymentRequest = {
  __typename?: "core_apimessages_CheckoutFinalizeDigitalWalletPaymentRequest";
  _source?: Maybe<Scalars["String"]["output"]>;
  accertifyDeviceInfo?: Maybe<core_apimessages_AccertifyDeviceInfo>;
  guestUser?: Maybe<core_apimessages_GuestCheckoutUserEntry>;
  /** Checkout UUID */
  id?: Maybe<Scalars["String"]["output"]>;
  paymentToken?: Maybe<Scalars["String"]["output"]>;
  resultConfirmation?: Maybe<core_apimessages_CheckoutFinalizeDigitalWalletPaymentRequest_ResultConfirmation>;
  shippingAddress?: Maybe<core_apimessages_AddressEntry>;
  totalPrice?: Maybe<core_apimessages_MoneyMinorUnitsEntry>;
};

export enum core_apimessages_CheckoutFinalizeDigitalWalletPaymentRequest_ResultConfirmation {
  /** Default for all web browser-based flows. Confirmation will be available via the Checkout endpoint. */
  ASYNC = "ASYNC",
  /** Intended for native apps only. Confirmation will be available synchronously in the response body. */
  SYNC = "SYNC",
}

export type core_apimessages_CheckoutFinalizeDigitalWalletPaymentResponse = {
  __typename?: "core_apimessages_CheckoutFinalizeDigitalWalletPaymentResponse";
  _source?: Maybe<Scalars["String"]["output"]>;
  checkout?: Maybe<Checkout>;
  /** Checkout UUID */
  id?: Maybe<Scalars["String"]["output"]>;
};

export type core_apimessages_CheckoutFinalizeRedirectPaymentCheckoutRequest = {
  __typename?: "core_apimessages_CheckoutFinalizeRedirectPaymentCheckoutRequest";
  _source?: Maybe<Scalars["String"]["output"]>;
  displayedAmountOwedCents?: Maybe<Scalars["String"]["output"]>;
  /** Checkout UUID */
  id?: Maybe<Scalars["String"]["output"]>;
  merchantReference?: Maybe<Scalars["String"]["output"]>;
  redirectResult?: Maybe<Scalars["String"]["output"]>;
};

export type core_apimessages_CheckoutFinalizeRedirectPaymentCheckoutResponse = {
  __typename?: "core_apimessages_CheckoutFinalizeRedirectPaymentCheckoutResponse";
  _source?: Maybe<Scalars["String"]["output"]>;
  checkout?: Maybe<Checkout>;
  /** Checkout UUID */
  id?: Maybe<Scalars["String"]["output"]>;
};

export type core_apimessages_CheckoutGooglePayDetails = {
  __typename?: "core_apimessages_CheckoutGooglePayDetails";
  _source?: Maybe<Scalars["String"]["output"]>;
  /** Card networks supported by both Reverb and Google Pay */
  cardNetworks?: Maybe<
    Array<Maybe<core_apimessages_CheckoutGooglePayDetails_CardNetwork>>
  >;
  /** Reverb Adyen Merchant ID for the transaction */
  gatewayMerchantId?: Maybe<Scalars["String"]["output"]>;
  /** Google Pay Merchant ID */
  merchantId?: Maybe<Scalars["String"]["output"]>;
  /** Merchant name to display in the payment sheet */
  merchantName?: Maybe<Scalars["String"]["output"]>;
};

export enum core_apimessages_CheckoutGooglePayDetails_CardNetwork {
  AMEX = "AMEX",
  DISCOVER = "DISCOVER",
  MASTERCARD = "MASTERCARD",
  VISA = "VISA",
}

export type core_apimessages_CheckoutGuest = {
  __typename?: "core_apimessages_CheckoutGuest";
  _source?: Maybe<Scalars["String"]["output"]>;
  email?: Maybe<Scalars["String"]["output"]>;
  firstName?: Maybe<Scalars["String"]["output"]>;
  isComplete?: Maybe<Scalars["Boolean"]["output"]>;
  lastName?: Maybe<Scalars["String"]["output"]>;
};

export type core_apimessages_CheckoutIdentifyShopperRequest = {
  __typename?: "core_apimessages_CheckoutIdentifyShopperRequest";
  _source?: Maybe<Scalars["String"]["output"]>;
  channelBrandingToken?: Maybe<Scalars["String"]["output"]>;
  checkoutId?: Maybe<Scalars["String"]["output"]>;
  checkoutUuid?: Maybe<Scalars["String"]["output"]>;
  results?: Maybe<core_apimessages_AdyenIdentifyShopperResults>;
  totalPrice?: Maybe<core_apimessages_Money>;
};

export type core_apimessages_CheckoutIdentifyShopperResponse = {
  __typename?: "core_apimessages_CheckoutIdentifyShopperResponse";
  _source?: Maybe<Scalars["String"]["output"]>;
  adyenPaymentResult?: Maybe<core_apimessages_AdyenPaymentResult>;
  checkoutId?: Maybe<Scalars["String"]["output"]>;
  checkoutUuid?: Maybe<Scalars["String"]["output"]>;
  orderBundleId?: Maybe<Scalars["String"]["output"]>;
  paymentResultUrl?: Maybe<Scalars["String"]["output"]>;
};

export type core_apimessages_CheckoutLineItem = {
  __typename?: "core_apimessages_CheckoutLineItem";
  _source?: Maybe<Scalars["String"]["output"]>;
  amount?: Maybe<core_apimessages_Money>;
  label?: Maybe<Scalars["String"]["output"]>;
  type?: Maybe<core_apimessages_CheckoutLineItem_Type>;
};

export enum core_apimessages_CheckoutLineItem_Type {
  AMOUNT_CREDIT = "AMOUNT_CREDIT",
  AMOUNT_LISTING_SUBTOTAL = "AMOUNT_LISTING_SUBTOTAL",
  AMOUNT_SHIPPING = "AMOUNT_SHIPPING",
  AMOUNT_TAX = "AMOUNT_TAX",
}

export type core_apimessages_CheckoutOrder = {
  __typename?: "core_apimessages_CheckoutOrder";
  _source?: Maybe<Scalars["String"]["output"]>;
  availableShippingMethods?: Maybe<
    Array<Maybe<core_apimessages_CheckoutShippingMethod>>
  >;
  buyerCanChangeQuantity?: Maybe<Scalars["Boolean"]["output"]>;
  cannotBeShippedToAddress?: Maybe<Scalars["Boolean"]["output"]>;
  /** Order ID. Present if order is unpaid */
  id?: Maybe<Scalars["String"]["output"]>;
  listing?: Maybe<Listing>;
  /** Listing ID */
  listingId?: Maybe<Scalars["String"]["output"]>;
  notes?: Maybe<Array<Maybe<core_apimessages_CheckoutOrder_Note>>>;
  protectionPlan?: Maybe<core_apimessages_ProtectionPlan>;
  quantity?: Maybe<Scalars["Int"]["output"]>;
  shippingMethod?: Maybe<core_apimessages_CheckoutShippingMethod>;
  shippingTotal?: Maybe<core_apimessages_CheckoutOrder_ShippingTotal>;
  subtotal?: Maybe<core_apimessages_CheckoutOrder_Subtotal>;
  taxIncluded?: Maybe<Scalars["Boolean"]["output"]>;
  taxLabel?: Maybe<Scalars["String"]["output"]>;
};

export type core_apimessages_CheckoutOrder_Note = {
  __typename?: "core_apimessages_CheckoutOrder_Note";
  _source?: Maybe<Scalars["String"]["output"]>;
  severity?: Maybe<core_apimessages_CheckoutOrder_Note_Severity>;
  text?: Maybe<Scalars["String"]["output"]>;
};

export enum core_apimessages_CheckoutOrder_Note_Severity {
  INFO = "INFO",
  WARNING = "WARNING",
}

export type core_apimessages_CheckoutOrder_ShippingTotal = {
  __typename?: "core_apimessages_CheckoutOrder_ShippingTotal";
  _source?: Maybe<Scalars["String"]["output"]>;
  /** Shipping amount owed by buyer for order including discounts if any. */
  amountOwed?: Maybe<core_apimessages_Money>;
  discount?: Maybe<core_apimessages_CheckoutOrder_ShippingTotalDiscount>;
  /** True if amount owed is less than original amount owed. */
  discounted?: Maybe<Scalars["Boolean"]["output"]>;
  /** Original shipping amount owed for order before any discounts. */
  originalAmountOwed?: Maybe<core_apimessages_Money>;
};

export type core_apimessages_CheckoutOrder_ShippingTotalDiscount = {
  __typename?: "core_apimessages_CheckoutOrder_ShippingTotalDiscount";
  _source?: Maybe<Scalars["String"]["output"]>;
  label?: Maybe<Scalars["String"]["output"]>;
  type?: Maybe<core_apimessages_CheckoutOrder_ShippingTotalDiscount_Type>;
};

export enum core_apimessages_CheckoutOrder_ShippingTotalDiscount_Type {
  COMBINED_CHILD = "COMBINED_CHILD",
  COMBINED_PARENT = "COMBINED_PARENT",
  FREE_SHIPPING_THRESHOLD = "FREE_SHIPPING_THRESHOLD",
}

export type core_apimessages_CheckoutOrder_Subtotal = {
  __typename?: "core_apimessages_CheckoutOrder_Subtotal";
  _source?: Maybe<Scalars["String"]["output"]>;
  discount?: Maybe<core_apimessages_CheckoutOrder_SubtotalDiscount>;
  /** True if order listing price is less than original listing price. */
  discounted?: Maybe<Scalars["Boolean"]["output"]>;
  /** Unit amount owed by the buyer for an order listing. Includes tax and conditional discounts like offer price. */
  listingPrice?: Maybe<core_apimessages_Money>;
};

export type core_apimessages_CheckoutOrder_SubtotalDiscount = {
  __typename?: "core_apimessages_CheckoutOrder_SubtotalDiscount";
  _source?: Maybe<Scalars["String"]["output"]>;
  label?: Maybe<Scalars["String"]["output"]>;
  type?: Maybe<core_apimessages_CheckoutOrder_SubtotalDiscount_Type>;
};

export enum core_apimessages_CheckoutOrder_SubtotalDiscount_Type {
  OFFER = "OFFER",
  SALE = "SALE",
  SHOP_CAMPAIGN = "SHOP_CAMPAIGN",
}

export type core_apimessages_CheckoutPaymentMethod = {
  __typename?: "core_apimessages_CheckoutPaymentMethod";
  _source?: Maybe<Scalars["String"]["output"]>;
  affirm?: Maybe<core_apimessages_CheckoutAffirmDetails>;
  applePay?: Maybe<core_apimessages_CheckoutApplePayDetails>;
  googlePay?: Maybe<core_apimessages_CheckoutGooglePayDetails>;
  isComplete?: Maybe<Scalars["Boolean"]["output"]>;
  name?: Maybe<Scalars["String"]["output"]>;
  paypal?: Maybe<core_apimessages_CheckoutPaypalDetails>;
  /** True if discount codes are supported */
  supportsDiscountCodes?: Maybe<Scalars["Boolean"]["output"]>;
  type?: Maybe<core_apimessages_CheckoutPaymentMethod_Type>;
};

export enum core_apimessages_CheckoutPaymentMethod_Type {
  AFFIRM = "AFFIRM",
  /** Currently known as Google Pay */
  ANDROID_PAY = "ANDROID_PAY",
  APPLE_PAY = "APPLE_PAY",
  DC_VIA_PAYPAL = "DC_VIA_PAYPAL",
  /** Card or scheme payments */
  DIRECT_CHECKOUT = "DIRECT_CHECKOUT",
  /** Klarna Pay Over Time */
  KLARNA_ACCOUNT = "KLARNA_ACCOUNT",
  PAYPAL = "PAYPAL",
  PAYPAL_PAY_LATER = "PAYPAL_PAY_LATER",
  SOFORT = "SOFORT",
}

export type core_apimessages_CheckoutPaymentResponse = {
  __typename?: "core_apimessages_CheckoutPaymentResponse";
  _source?: Maybe<Scalars["String"]["output"]>;
  adyenPaymentResult?: Maybe<core_apimessages_AdyenPaymentResult>;
  checkoutId?: Maybe<Scalars["String"]["output"]>;
  checkoutUuid?: Maybe<Scalars["String"]["output"]>;
  orderBundleId?: Maybe<Scalars["String"]["output"]>;
  paymentResultUrl?: Maybe<Scalars["String"]["output"]>;
};

export type core_apimessages_CheckoutPaypalDetails = {
  __typename?: "core_apimessages_CheckoutPaypalDetails";
  _source?: Maybe<Scalars["String"]["output"]>;
  clientId?: Maybe<Scalars["String"]["output"]>;
  clientToken?: Maybe<Scalars["String"]["output"]>;
  currency?: Maybe<Scalars["String"]["output"]>;
  merchantId?: Maybe<Scalars["String"]["output"]>;
};

export type core_apimessages_CheckoutSelectOrderShippingMethodRequest = {
  __typename?: "core_apimessages_CheckoutSelectOrderShippingMethodRequest";
  _source?: Maybe<Scalars["String"]["output"]>;
  id?: Maybe<Scalars["String"]["output"]>;
  order?: Maybe<core_apimessages_CheckoutSelectOrderShippingMethodRequest_OrderEntry>;
};

export type core_apimessages_CheckoutSelectOrderShippingMethodRequest_OrderEntry =
  {
    __typename?: "core_apimessages_CheckoutSelectOrderShippingMethodRequest_OrderEntry";
    _source?: Maybe<Scalars["String"]["output"]>;
    listingId?: Maybe<Scalars["String"]["output"]>;
    shippingMethodType?: Maybe<core_apimessages_ShippingMethod>;
  };

export type core_apimessages_CheckoutSelectOrderShippingMethodResponse = {
  __typename?: "core_apimessages_CheckoutSelectOrderShippingMethodResponse";
  _source?: Maybe<Scalars["String"]["output"]>;
  checkout?: Maybe<Checkout>;
  id?: Maybe<Scalars["String"]["output"]>;
};

export type core_apimessages_CheckoutSelectShippingAddressRequest = {
  __typename?: "core_apimessages_CheckoutSelectShippingAddressRequest";
  _source?: Maybe<Scalars["String"]["output"]>;
  /** Checkout UUID */
  id?: Maybe<Scalars["String"]["output"]>;
  /** addresses.uuid */
  shippingAddressId?: Maybe<Scalars["String"]["output"]>;
};

export type core_apimessages_CheckoutSelectShippingAddressResponse = {
  __typename?: "core_apimessages_CheckoutSelectShippingAddressResponse";
  _source?: Maybe<Scalars["String"]["output"]>;
  checkout?: Maybe<Checkout>;
  /** Checkout UUID */
  id?: Maybe<Scalars["String"]["output"]>;
};

export type core_apimessages_CheckoutShippingMethod = {
  __typename?: "core_apimessages_CheckoutShippingMethod";
  _source?: Maybe<Scalars["String"]["output"]>;
  amount?: Maybe<core_apimessages_Money>;
  description?: Maybe<Scalars["String"]["output"]>;
  name?: Maybe<Scalars["String"]["output"]>;
  type?: Maybe<core_apimessages_ShippingMethod>;
};

export type core_apimessages_CheckoutShowRequest = {
  __typename?: "core_apimessages_CheckoutShowRequest";
  _source?: Maybe<Scalars["String"]["output"]>;
  /** Checkout UUID */
  id?: Maybe<Scalars["String"]["output"]>;
};

export type core_apimessages_CheckoutShowResponse = {
  __typename?: "core_apimessages_CheckoutShowResponse";
  _source?: Maybe<Scalars["String"]["output"]>;
  checkout?: Maybe<core_apimessages_Checkout>;
};

export type core_apimessages_CheckoutUpdateOrderQuantityRequest = {
  __typename?: "core_apimessages_CheckoutUpdateOrderQuantityRequest";
  _source?: Maybe<Scalars["String"]["output"]>;
  id?: Maybe<Scalars["String"]["output"]>;
  order?: Maybe<core_apimessages_CheckoutUpdateOrderQuantityRequest_OrderEntry>;
};

export type core_apimessages_CheckoutUpdateOrderQuantityRequest_OrderEntry = {
  __typename?: "core_apimessages_CheckoutUpdateOrderQuantityRequest_OrderEntry";
  _source?: Maybe<Scalars["String"]["output"]>;
  listingId?: Maybe<Scalars["String"]["output"]>;
  quantity?: Maybe<Scalars["Int"]["output"]>;
};

export type core_apimessages_CheckoutUpdateOrderQuantityResponse = {
  __typename?: "core_apimessages_CheckoutUpdateOrderQuantityResponse";
  _source?: Maybe<Scalars["String"]["output"]>;
  id?: Maybe<Scalars["String"]["output"]>;
};

export type core_apimessages_CheckoutUpdateShippingAddressRequest = {
  __typename?: "core_apimessages_CheckoutUpdateShippingAddressRequest";
  _source?: Maybe<Scalars["String"]["output"]>;
  /** Information required only for guest checkout. */
  guest?: Maybe<core_apimessages_GuestCheckoutUserEntry>;
  /** Checkout UUID */
  id?: Maybe<Scalars["String"]["output"]>;
  shippingAddress?: Maybe<core_apimessages_AddressEntry>;
};

export type core_apimessages_CheckoutUpdateShippingAddressResponse = {
  __typename?: "core_apimessages_CheckoutUpdateShippingAddressResponse";
  _source?: Maybe<Scalars["String"]["output"]>;
  checkout?: Maybe<Checkout>;
  /** Checkout UUID */
  id?: Maybe<Scalars["String"]["output"]>;
};

export type core_apimessages_CheckoutUpdateShippingEstimateRequest = {
  __typename?: "core_apimessages_CheckoutUpdateShippingEstimateRequest";
  _source?: Maybe<Scalars["String"]["output"]>;
  id?: Maybe<Scalars["String"]["output"]>;
  shippingAddress?: Maybe<core_apimessages_AddressEntry>;
};

export type core_apimessages_CheckoutUpdateShippingEstimateResponse = {
  __typename?: "core_apimessages_CheckoutUpdateShippingEstimateResponse";
  _source?: Maybe<Scalars["String"]["output"]>;
  checkout?: Maybe<Checkout>;
  id?: Maybe<Scalars["String"]["output"]>;
};

export type core_apimessages_CheckoutVerifyShopperRequest = {
  __typename?: "core_apimessages_CheckoutVerifyShopperRequest";
  _source?: Maybe<Scalars["String"]["output"]>;
  checkoutId?: Maybe<Scalars["String"]["output"]>;
  checkoutUuid?: Maybe<Scalars["String"]["output"]>;
  results?: Maybe<core_apimessages_AdyenVerifyShopperResults>;
  totalPrice?: Maybe<core_apimessages_Money>;
};

export type core_apimessages_CheckoutVerifyShopperResponse = {
  __typename?: "core_apimessages_CheckoutVerifyShopperResponse";
  _source?: Maybe<Scalars["String"]["output"]>;
  adyenPaymentResult?: Maybe<core_apimessages_AdyenPaymentResult>;
  checkoutId?: Maybe<Scalars["String"]["output"]>;
  checkoutUuid?: Maybe<Scalars["String"]["output"]>;
  orderBundleId?: Maybe<Scalars["String"]["output"]>;
  paymentResultUrl?: Maybe<Scalars["String"]["output"]>;
};

export enum core_apimessages_Checkout_Source {
  BUY_NOW = "BUY_NOW",
  CART = "CART",
}

export enum core_apimessages_Checkout_Status {
  /** Initial status */
  AWAITING_PAYMENT = "AWAITING_PAYMENT",
  /** Checkout info such as tax or sales are no longer valid, return to payment */
  CHECKOUT_INFORMATION_FAILURE = "CHECKOUT_INFORMATION_FAILURE",
  /** Payment processor failure. Return to payment */
  CHECKOUT_PROCESSING_FAILURE = "CHECKOUT_PROCESSING_FAILURE",
  DUPLICATE_PAYMENT = "DUPLICATE_PAYMENT",
  /** User's shipping details were incorrect. Return to shipping */
  INVALID_SHIPPING_ADDRESS_FAILURE = "INVALID_SHIPPING_ADDRESS_FAILURE",
  /** Return to cart */
  LISTING_AVAILABILITY_FAILURE = "LISTING_AVAILABILITY_FAILURE",
  /** Return to cart */
  LISTING_PURCHASE_RATE_LIMIT_FAILURE = "LISTING_PURCHASE_RATE_LIMIT_FAILURE",
  /** User's payment method was incorrect. Return to payment */
  PAYMENT_INFORMATION_FAILURE = "PAYMENT_INFORMATION_FAILURE",
  PAYMENT_IN_PROGRESS = "PAYMENT_IN_PROGRESS",
  PAYMENT_SUCCESS = "PAYMENT_SUCCESS",
  /** Express Sale only, seller must wait for pending ES orders to be evaluated by warehouse team before processing another ES checkout */
  PENDING_EXPRESS_SALE_ORDERS_LIMIT_FAILURE = "PENDING_EXPRESS_SALE_ORDERS_LIMIT_FAILURE",
  /** User needs to enter SCA/3DS */
  REQUIRES_3DS_AUTHENTICATION = "REQUIRES_3DS_AUTHENTICATION",
  /** Express Sale only, retryable if EP was down, otherwise indicates the shipment cannot be created for this seller */
  SHIPMENT_CREATION_FAILURE = "SHIPMENT_CREATION_FAILURE",
  /** Return to shipping */
  STANDARD_USER_ATTEMPTED_GUEST_CHECKOUT_FAILURE = "STANDARD_USER_ATTEMPTED_GUEST_CHECKOUT_FAILURE",
  /** User cannot proceed with current region. Return to cart */
  USER_IN_SANCTIONED_REGION_FAILURE = "USER_IN_SANCTIONED_REGION_FAILURE",
}

export type core_apimessages_Checkout_Total = {
  __typename?: "core_apimessages_Checkout_Total";
  _source?: Maybe<Scalars["String"]["output"]>;
  amount?: Maybe<core_apimessages_Money>;
  label?: Maybe<Scalars["String"]["output"]>;
};

export enum core_apimessages_Checkout_Type {
  GUEST = "GUEST",
  STANDARD = "STANDARD",
}

export type core_apimessages_CollectionHeader = {
  __typename?: "core_apimessages_CollectionHeader";
  _source?: Maybe<Scalars["String"]["output"]>;
  collectionId?: Maybe<Scalars["String"]["output"]>;
  collectionType?: Maybe<core_apimessages_CollectionHeader_CollectionType>;
  description?: Maybe<Scalars["String"]["output"]>;
  id?: Maybe<Scalars["String"]["output"]>;
  metaDescription?: Maybe<Scalars["String"]["output"]>;
  metaTitle?: Maybe<Scalars["String"]["output"]>;
  title?: Maybe<Scalars["String"]["output"]>;
};

export type core_apimessages_CollectionHeaderRequest = {
  __typename?: "core_apimessages_CollectionHeaderRequest";
  _source?: Maybe<Scalars["String"]["output"]>;
  /** for category, `productType:category` compound key */
  collectionSlug?: Maybe<Scalars["String"]["output"]>;
  collectionType?: Maybe<core_apimessages_CollectionHeader_CollectionType>;
};

export type core_apimessages_CollectionHeaderResponse = {
  __typename?: "core_apimessages_CollectionHeaderResponse";
  _source?: Maybe<Scalars["String"]["output"]>;
  collectionHeader?: Maybe<core_apimessages_CollectionHeader>;
};

export enum core_apimessages_CollectionHeader_CollectionType {
  Brand = "Brand",
  Category = "Category",
  CuratedSearch = "CuratedSearch",
  CuratedSet = "CuratedSet",
  ProductType = "ProductType",
}

export type core_apimessages_CompleteExpressSaleCheckoutRequest = {
  __typename?: "core_apimessages_CompleteExpressSaleCheckoutRequest";
  _source?: Maybe<Scalars["String"]["output"]>;
  checkoutId?: Maybe<Scalars["String"]["output"]>;
};

export type core_apimessages_CompleteExpressSaleCheckoutResponse = {
  __typename?: "core_apimessages_CompleteExpressSaleCheckoutResponse";
  _source?: Maybe<Scalars["String"]["output"]>;
  orderId?: Maybe<Scalars["String"]["output"]>;
};

export type core_apimessages_Condition = {
  __typename?: "core_apimessages_Condition";
  _source?: Maybe<Scalars["String"]["output"]>;
  conditionSlug?: Maybe<Scalars["String"]["output"]>;
  conditionUuid?: Maybe<Scalars["String"]["output"]>;
  description?: Maybe<Scalars["String"]["output"]>;
  displayName?: Maybe<Scalars["String"]["output"]>;
};

export type core_apimessages_ContentSponsorship = {
  __typename?: "core_apimessages_ContentSponsorship";
  _source?: Maybe<Scalars["String"]["output"]>;
  partnershipName?: Maybe<Scalars["String"]["output"]>;
  sponsorshipType?: Maybe<core_apimessages_ContentSponsorship_SponsorshipType>;
};

export enum core_apimessages_ContentSponsorship_SponsorshipType {
  AD = "AD",
  PARTNERSHIP = "PARTNERSHIP",
}

export type core_apimessages_ConversationFlagMessageRequest = {
  __typename?: "core_apimessages_ConversationFlagMessageRequest";
  _source?: Maybe<Scalars["String"]["output"]>;
  messageId?: Maybe<Scalars["String"]["output"]>;
  reasonType?: Maybe<core_apimessages_ConversationMessageFlag_Reason_Type>;
};

export type core_apimessages_ConversationFlagMessageResponse = {
  __typename?: "core_apimessages_ConversationFlagMessageResponse";
  _source?: Maybe<Scalars["String"]["output"]>;
  /** Created Message Flag ID */
  id?: Maybe<Scalars["String"]["output"]>;
};

export type core_apimessages_ConversationMessage = {
  __typename?: "core_apimessages_ConversationMessage";
  _source?: Maybe<Scalars["String"]["output"]>;
  conversationId?: Maybe<Scalars["String"]["output"]>;
  id?: Maybe<Scalars["String"]["output"]>;
};

export type core_apimessages_ConversationMessageFlag = {
  __typename?: "core_apimessages_ConversationMessageFlag";
  _source?: Maybe<Scalars["String"]["output"]>;
  id?: Maybe<Scalars["String"]["output"]>;
};

export type core_apimessages_ConversationMessageFlag_Reason = {
  __typename?: "core_apimessages_ConversationMessageFlag_Reason";
  _?: Maybe<Scalars["Boolean"]["output"]>;
  _source?: Maybe<Scalars["String"]["output"]>;
};

export enum core_apimessages_ConversationMessageFlag_Reason_Type {
  ABUSE = "ABUSE",
  FRAUD = "FRAUD",
  OFFSITE = "OFFSITE",
}

export type core_apimessages_ConvertedMoney = {
  __typename?: "core_apimessages_ConvertedMoney";
  _source?: Maybe<Scalars["String"]["output"]>;
  display?: Maybe<core_apimessages_Money>;
  original?: Maybe<core_apimessages_Money>;
};

export type core_apimessages_CopyAdGroupRequest = {
  __typename?: "core_apimessages_CopyAdGroupRequest";
  _source?: Maybe<Scalars["String"]["output"]>;
  adGroupUuid?: Maybe<Scalars["String"]["output"]>;
};

export type core_apimessages_CopyAdGroupResponse = {
  __typename?: "core_apimessages_CopyAdGroupResponse";
  _source?: Maybe<Scalars["String"]["output"]>;
  adGroup?: Maybe<core_apimessages_AdGroup>;
};

export type core_apimessages_Core = {
  __typename?: "core_apimessages_Core";
  AcceptAddressSuggestion?: Maybe<core_apimessages_AcceptAddressSuggestionResponse>;
  AcceptedPaymentMethods?: Maybe<core_apimessages_AcceptedPaymentMethodsResponse>;
  AccoutAlerts?: Maybe<core_apimessages_AccountAlertsResponse>;
  AdCampaignsSearch?: Maybe<core_apimessages_AdCampaignsSearchResponse>;
  AdGroupsSearch?: Maybe<core_apimessages_AdGroupsSearchResponse>;
  AdServe?: Maybe<core_apimessages_AdServeResponse>;
  AdZonesSearch?: Maybe<core_apimessages_AdZonesSearchResponse>;
  AdaChatToken?: Maybe<core_apimessages_AdaChatTokenResponse>;
  AddListingToCuratedSet?: Maybe<core_apimessages_AdminCuratedSetsCurateResponse>;
  AddressVerify?: Maybe<core_apimessages_AddressVerifyResponse>;
  AdminBlockProductReview?: Maybe<core_apimessages_AdminBlockProductReviewResponse>;
  AdminNotesSearch?: Maybe<core_apimessages_AdminNotesSearchResponse>;
  AdminTax1099kReportingThresholdSearch?: Maybe<core_apimessages_AdminTax1099kReportingThresholdSearchResponse>;
  AdminUserDatadogErrorsSearch?: Maybe<core_apimessages_AdminUserDatadogErrorsSearchResponse>;
  AdminUserSearch?: Maybe<core_apimessages_AdminUserSearchResponse>;
  AdsSearch?: Maybe<core_apimessages_AdsSearchResponse>;
  AdvertisersSearch?: Maybe<core_apimessages_AdvertisersSearchResponse>;
  AffirmFinancingPromotions?: Maybe<core_apimessages_AffirmFinancingPromotionsResponse>;
  ApplyCheckoutDiscountCode?: Maybe<core_apimessages_ApplyCheckoutDiscountCodeResponse>;
  Articles?: Maybe<core_apimessages_ArticlesResponse>;
  AutoOfferCreate?: Maybe<core_apimessages_CreateAutoOfferResponse>;
  AutoOffers?: Maybe<core_apimessages_AutoOffersResponse>;
  AutoOffersDelete?: Maybe<core_apimessages_DeleteAutoOfferResponse>;
  BearerV2Token?: Maybe<core_apimessages_BearerV2TokenResponse>;
  BillingMethodChallengeShopper?: Maybe<core_apimessages_BillingMethodChallengeShopperResponse>;
  BillingMethodIdentifyShopper?: Maybe<core_apimessages_BillingMethodIdentifyShopperResponse>;
  BillingMethodVerifyShopper?: Maybe<core_apimessages_BillingMethodVerifyShopperResponse>;
  BrandManagerBrand?: Maybe<core_apimessages_BrandManagerBrandResponse>;
  BrandManagerBrands?: Maybe<core_apimessages_BrandManagerBrandsResponse>;
  Brands?: Maybe<core_apimessages_BrandsResponse>;
  BrowsePageLinks?: Maybe<core_apimessages_BrowsePageLinksResponse>;
  BulkAddresses?: Maybe<core_apimessages_BulkAddressesResponse>;
  BulkCategories?: Maybe<core_apimessages_BulkCategoriesResponse>;
  BulkCreateAutoOffers?: Maybe<core_apimessages_SellerBulkActionResponse>;
  BulkCreateAutoOffersAsync?: Maybe<core_apimessages_SellerBulkActionAsyncResponse>;
  BulkCreateBumped?: Maybe<core_apimessages_SellerBulkActionResponse>;
  BulkCreateBumpedAsync?: Maybe<core_apimessages_SellerBulkActionAsyncResponse>;
  BulkCreateSaleMemberships?: Maybe<core_apimessages_SellerBulkActionResponse>;
  BulkCreateZeroPercentAffirm?: Maybe<core_apimessages_SellerBulkActionResponse>;
  BulkCreateZeroPercentAffirmAsync?: Maybe<core_apimessages_SellerBulkActionAsyncResponse>;
  BulkCuratedSets?: Maybe<core_apimessages_BulkCuratedSetsResponse>;
  BulkDeleteAutoOffers?: Maybe<core_apimessages_SellerBulkActionResponse>;
  BulkDeleteAutoOffersAsync?: Maybe<core_apimessages_SellerBulkActionAsyncResponse>;
  BulkDeleteBumped?: Maybe<core_apimessages_SellerBulkActionResponse>;
  BulkDeleteBumpedAsync?: Maybe<core_apimessages_SellerBulkActionAsyncResponse>;
  BulkDeleteListings?: Maybe<core_apimessages_BulkDeleteListingsResponse>;
  BulkDeleteListingsAsync?: Maybe<core_apimessages_SellerBulkActionAsyncResponse>;
  BulkDeleteSaleMemberships?: Maybe<core_apimessages_SellerBulkActionResponse>;
  BulkDeleteZeroPercentAffirm?: Maybe<core_apimessages_SellerBulkActionResponse>;
  BulkDeleteZeroPercentAffirmAsync?: Maybe<core_apimessages_SellerBulkActionAsyncResponse>;
  BulkExportListings?: Maybe<core_apimessages_BulkExportListingsResponse>;
  BulkImages?: Maybe<core_apimessages_ImagesResponse>;
  BulkOrders?: Maybe<core_apimessages_BulkOrdersResponse>;
  BulkPublishListings?: Maybe<core_apimessages_BulkPublishListingsResponse>;
  BulkPublishListingsAsync?: Maybe<core_apimessages_SellerBulkActionAsyncResponse>;
  BulkTaxPolicies?: Maybe<core_apimessages_BulkTaxPoliciesResponse>;
  BumpKeys?: Maybe<core_apimessages_BumpKeysResponse>;
  BuyerOrders?: Maybe<core_apimessages_BuyerOrdersResponse>;
  BuyerRefunds?: Maybe<core_apimessages_BuyerRefundsResponse>;
  CPSuggestions?: Maybe<core_apimessages_CPSuggestionsResponse>;
  CSPReviews?: Maybe<core_apimessages_CSPReviewsResponse>;
  CSPs?: Maybe<core_apimessages_CSPsResponse>;
  CSPsFeaturedListing?: Maybe<core_apimessages_CSPsFeaturedListingResponse>;
  CSPsInventory?: Maybe<core_apimessages_CSPsInventoryResponse>;
  CSPsSpecs?: Maybe<core_apimessages_CSPsSpecsResponse>;
  CSPsVideo?: Maybe<core_apimessages_CSPsVideoResponse>;
  CancelOrder?: Maybe<core_apimessages_CancelOrderResponse>;
  CancelPlaidAccount?: Maybe<core_apimessages_CancelPlaidAccountResponse>;
  CanonicalProductRecommendations?: Maybe<core_apimessages_RecommendationsResponse>;
  CartMoveToWatchList?: Maybe<core_apimessages_CartMoveToWatchListResponse>;
  Categories?: Maybe<core_apimessages_CategoriesResponse>;
  CheckoutAction?: Maybe<core_apimessages_CheckoutActionResponse>;
  CheckoutApplePayDetails?: Maybe<core_apimessages_CheckoutApplePayDetailsResponse>;
  CheckoutCartEdit?: Maybe<core_apimessages_CheckoutCartEditResponse>;
  CheckoutChallengeShopper?: Maybe<core_apimessages_CheckoutChallengeShopperResponse>;
  CheckoutCreatePaymentRedirectUrl?: Maybe<core_apimessages_CheckoutCreatePaymentRedirectUrlResponse>;
  CheckoutFinalizeDigitalWalletPayment?: Maybe<core_apimessages_CheckoutFinalizeDigitalWalletPaymentResponse>;
  CheckoutFinalizeRedirectPaymentCheckout?: Maybe<core_apimessages_CheckoutFinalizeRedirectPaymentCheckoutResponse>;
  CheckoutIdentifyShopper?: Maybe<core_apimessages_CheckoutIdentifyShopperResponse>;
  CheckoutSelectOrderShippingMethod?: Maybe<core_apimessages_CheckoutSelectOrderShippingMethodResponse>;
  CheckoutSelectShippingAddress?: Maybe<core_apimessages_CheckoutSelectShippingAddressResponse>;
  CheckoutShow?: Maybe<core_apimessages_CheckoutShowResponse>;
  CheckoutUpdateOrderQuantity?: Maybe<core_apimessages_CheckoutUpdateOrderQuantityResponse>;
  CheckoutUpdateShippingAddress?: Maybe<core_apimessages_CheckoutUpdateShippingAddressResponse>;
  CheckoutUpdateShippingEstimate?: Maybe<core_apimessages_CheckoutUpdateShippingEstimateResponse>;
  CheckoutVerifyShopper?: Maybe<core_apimessages_CheckoutVerifyShopperResponse>;
  ClearBrowsingHistory?: Maybe<core_apimessages_DeleteResponse>;
  CollectionHeader?: Maybe<core_apimessages_CollectionHeaderResponse>;
  CompleteExpressSaleCheckout?: Maybe<core_apimessages_CompleteExpressSaleCheckoutResponse>;
  ConversationFlagMessage?: Maybe<core_apimessages_ConversationFlagMessageResponse>;
  CopyAdGroup?: Maybe<core_apimessages_CopyAdGroupResponse>;
  Countries?: Maybe<core_apimessages_CountriesResponse>;
  CreateAdminNote?: Maybe<core_apimessages_AdminNote>;
  CreateAdminTax1099kReportingThreshold?: Maybe<core_apimessages_AdminTax1099kReportingThresholdCreateResponse>;
  CreateAdyenOnboardingLink?: Maybe<core_apimessages_AdyenOnboardingLinkResponse>;
  CreateCheckoutPaypalOrder?: Maybe<core_apimessages_CreateCheckoutPaypalOrderResponse>;
  CreateExpressSaleCheckout?: Maybe<core_apimessages_CreateExpressSaleCheckoutResponse>;
  CreateMyAction?: Maybe<core_apimessages_CreateMyActionResponse>;
  CreateMyAffirmCuratedSetMembership?: Maybe<core_apimessages_MyCuratedSetMembershipResponse>;
  CreateMyCreditCard?: Maybe<core_apimessages_CreateMyCreditCardResponse>;
  CreateMyCuratedSetsMembership?: Maybe<core_apimessages_MyCuratedSetMembershipResponse>;
  CreateMyFeedbackMessage?: Maybe<core_apimessages_CreateMyFeedbackMessageResponse>;
  CreateMyPlaidLinkToken?: Maybe<core_apimessages_CreateMyPlaidLinkTokenResponse>;
  CreateMySaleMembership?: Maybe<core_apimessages_CreateMySaleMembershipResponse>;
  CreateMyWatches?: Maybe<core_apimessages_UpdateWatchResponse>;
  CreatePacklinkDraft?: Maybe<core_apimessages_CreatePacklinkDraftResponse>;
  CreateRecentSearch?: Maybe<core_apimessages_CreateRecentSearchResponse>;
  CreateShipment?: Maybe<core_apimessages_CreateShipmentResponse>;
  CreateTaxIdentification?: Maybe<core_apimessages_CreateTaxIdentificationResponse>;
  CreateTruliooOnboardingRecord?: Maybe<core_apimessages_CreateTruliooOnboardingRecordResponse>;
  CreateUserShopPermission?: Maybe<core_apimessages_CreateOrUpdateUserShopPermissionResponse>;
  CrossPlatformListingStatus?: Maybe<core_apimessages_CrossPlatformListingStatusResponse>;
  CuratedSets?: Maybe<core_apimessages_CuratedSetsResponse>;
  DeactivateTaxIdentification?: Maybe<core_apimessages_DeactivateTaxIdentificationResponse>;
  DeclineFeedback?: Maybe<core_apimessages_DeclineFeedbackResponse>;
  DeleteAd?: Maybe<core_apimessages_DeleteAdResponse>;
  DeleteAdminTax1099kReportingThreshold?: Maybe<core_apimessages_AdminTax1099kReportingThresholdDeleteResponse>;
  DeleteCartItemProtectionPlan?: Maybe<core_apimessages_DeleteCartItemProtectionPlanResponse>;
  DeleteDraftListing?: Maybe<core_apimessages_DraftListingDeleteResponse>;
  DeleteMyAddress?: Maybe<core_apimessages_DeleteResponse>;
  DeleteMyAffirmCuratedSetMembership?: Maybe<core_apimessages_MyCuratedSetMembershipResponse>;
  DeleteMyCreditCard?: Maybe<core_apimessages_DeleteMyCreditCardResponse>;
  DeleteMyCuratedSetsMembership?: Maybe<core_apimessages_MyCuratedSetMembershipResponse>;
  DeleteMyFavorite?: Maybe<core_apimessages_DeleteMyFavoriteResponse>;
  DeleteMyFeedbackMessage?: Maybe<core_apimessages_DeleteMyFeedbackMessageResponse>;
  DeleteMyGearCollectionItem?: Maybe<core_apimessages_DeleteGearCollectionItemResponse>;
  DeleteMySaleMembership?: Maybe<core_apimessages_DeleteMySaleMembershipResponse>;
  DeleteMyWatches?: Maybe<core_apimessages_UpdateWatchResponse>;
  DeleteUserShopPermission?: Maybe<core_apimessages_DeleteUserShopPermissionResponse>;
  DelinkUserService?: Maybe<core_apimessages_DelinkUserServiceResponse>;
  EstimateNegotiationSalesTax?: Maybe<core_apimessages_EstimateNegotiationSalesTaxResponse>;
  EstimatedMonthlyPayments?: Maybe<core_apimessages_EstimatedMonthlyPaymentsResponse>;
  Experiments?: Maybe<core_apimessages_ExperimentsResponse>;
  ExportSecondaryUserActivities?: Maybe<core_apimessages_ExportSecondaryUserActivitiesResponse>;
  ExpressSaleCheckoutShow?: Maybe<core_apimessages_ExpressSaleCheckoutShowResponse>;
  FeatureListingInCuratedSet?: Maybe<core_apimessages_AdminCuratedSetsCurateResponse>;
  Feedback?: Maybe<core_apimessages_FeedbacksResponse>;
  FeedbackSummaries?: Maybe<core_apimessages_FeedbackSummariesResponse>;
  FinalizeAffirmCheckoutPayment?: Maybe<core_apimessages_FinalizeAffirmCheckoutPaymentResponse>;
  FinalizeCardCheckoutPayment?: Maybe<core_apimessages_FinalizeCardCheckoutPaymentResponse>;
  FinalizePaypalCheckoutPayment?: Maybe<core_apimessages_FinalizePaypalCheckoutPaymentResponse>;
  FinalizeRedirectAuthCheckoutPayment?: Maybe<core_apimessages_FinalizeRedirectAuthCheckoutPaymentResponse>;
  FindAddressAutocompleteSuggestions?: Maybe<core_apimessages_FindAddressAutocompleteSuggestionsResponse>;
  FindFavorite?: Maybe<core_apimessages_FindFavoriteResponse>;
  FlagProductReview?: Maybe<core_apimessages_FlagProductReviewResponse>;
  FormSelectCategories?: Maybe<core_apimessages_FormSelectCategoriesResponse>;
  GearCollectionSetting?: Maybe<core_apimessages_GearCollectionSettingResponse>;
  GenerateAdminReport?: Maybe<core_apimessages_GenerateAdminReportResponse>;
  GuestCheckoutVerifyShopper?: Maybe<core_apimessages_CheckoutVerifyShopperResponse>;
  Homepage?: Maybe<core_apimessages_HomepageResponse>;
  Images?: Maybe<core_apimessages_ImagesResponse>;
  ListingCorrection?: Maybe<core_apimessages_ListingCorrectionResponse>;
  ListingCounts?: Maybe<core_apimessages_ListingCountsResponse>;
  ListingPriceRecommendations?: Maybe<core_apimessages_ListingPriceRecommendationsResponse>;
  Listings?: Maybe<core_apimessages_ListingsResponse>;
  ListingsCuratedSets?: Maybe<core_apimessages_ListingsCuratedSetsResponse>;
  ListingsInUserCart?: Maybe<core_apimessages_ListingsInUserCartResponse>;
  ListingsOrderStats?: Maybe<core_apimessages_ListingsOrderStatsResponse>;
  ListingsPricing?: Maybe<core_apimessages_ListingsPricingResponse>;
  ListingsSpecs?: Maybe<core_apimessages_ListingsSpecsResponse>;
  ListingsValidForPublish?: Maybe<core_apimessages_ListingsValidForPublishResponse>;
  LoginMyPacklinkAccount?: Maybe<core_apimessages_LoginMyPacklinkAccountResponse>;
  MarkOrderAsReceived?: Maybe<core_apimessages_MarkOrderAsReceivedResponse>;
  Me?: Maybe<core_apimessages_MeResponse>;
  MosaicTilesHomepage?: Maybe<core_apimessages_MosaicTilesResponse>;
  MyAddresses?: Maybe<core_apimessages_MyAddressesResponse>;
  MyAvailableActionsIndex?: Maybe<core_apimessages_MyAvailableActionsIndexResponse>;
  MyBuyerCoupons?: Maybe<core_apimessages_MyBuyerCouponsResponse>;
  MyCartItemBundlesIndex?: Maybe<core_apimessages_MyCartItemBundlesIndexResponse>;
  MyCartItemsIndex?: Maybe<core_apimessages_MyCartItemsIndexResponse>;
  MyCounts?: Maybe<core_apimessages_CountsResponse>;
  MyCreditCards?: Maybe<core_apimessages_MyCreditCardsResponse>;
  MyDirectCheckoutProfile?: Maybe<core_apimessages_MyDirectCheckoutProfileResponse>;
  MyFavoriteSearches?: Maybe<core_apimessages_GetMyFavoriteSavedSearchesResponse>;
  MyFeedSearch?: Maybe<core_apimessages_MyFeedSearchResponse>;
  MyFeedbackMessages?: Maybe<core_apimessages_MyFeedbackMessagesResponse>;
  MyGearCollectionSettings?: Maybe<core_apimessages_GearCollectionSettingsResponse>;
  MyHomepageNotifications?: Maybe<core_apimessages_MyHomepageNotificationsResponse>;
  MyMessages?: Maybe<core_apimessages_MyMessagesResponse>;
  MyPacklinkAccountStatus?: Maybe<core_apimessages_MyPacklinkAccountStatusResponse>;
  MyPayoutBalances?: Maybe<core_apimessages_MyPayoutBalancesResponse>;
  MyReverbCredits?: Maybe<core_apimessages_MyReverbCreditsResponse>;
  MySales?: Maybe<core_apimessages_MySalesResponse>;
  MyShop?: Maybe<core_apimessages_MyShopResponse>;
  MyShopAdyenBalanceOnboarding?: Maybe<core_apimessages_AdyenBalanceOnboardingResponse>;
  MyShopBadges?: Maybe<core_apimessages_MyShopBadgesResponse>;
  MyShopBumpStats?: Maybe<core_apimessages_MyShopBumpStatsResponse>;
  MyShopCampaigns?: Maybe<core_apimessages_MyShopCampaignsResponse>;
  MyShopEarnings?: Maybe<core_apimessages_MyShopEarningsResponse>;
  MyShopExportDac7OrderData?: Maybe<core_apimessages_MyShopExportDac7OrderDataResponse>;
  MyShopOnboarding?: Maybe<core_apimessages_MyShopOnboardingResponse>;
  MyShopOrderRefundStats?: Maybe<core_apimessages_MyShopOrderRefundStatsResponse>;
  MyShopPaypalProfile?: Maybe<core_apimessages_MyShopPaypalProfileResponse>;
  MyShopShippingProfiles?: Maybe<core_apimessages_MyShopShippingProfilesResponse>;
  MyShopShippingRates?: Maybe<core_apimessages_MyShopShippingRatesResponse>;
  MyShopStats?: Maybe<core_apimessages_MyShopStatsResponse>;
  MyUserServices?: Maybe<core_apimessages_MyUserServicesResponse>;
  MyWatches?: Maybe<core_apimessages_WatchesResponse>;
  Negotiations?: Maybe<core_apimessages_NegotiationsResponse>;
  NegotiationsAccept?: Maybe<core_apimessages_UpdateNegotiationResponse>;
  NegotiationsCounter?: Maybe<core_apimessages_UpdateNegotiationResponse>;
  NegotiationsCreate?: Maybe<core_apimessages_NegotiationsResponse>;
  NegotiationsReject?: Maybe<core_apimessages_UpdateNegotiationResponse>;
  NegotiationsShippingLocations?: Maybe<core_apimessages_NegotiationsShippingLocationResponse>;
  NodesAdCampaigns?: Maybe<core_apimessages_NodeResponse>;
  NodesAdGroupKeywords?: Maybe<core_apimessages_NodeResponse>;
  NodesAdGroups?: Maybe<core_apimessages_NodeResponse>;
  NodesAdZones?: Maybe<core_apimessages_NodeResponse>;
  NodesAddresses?: Maybe<core_apimessages_NodeResponse>;
  NodesAds?: Maybe<core_apimessages_NodeResponse>;
  NodesAdvertisers?: Maybe<core_apimessages_NodeResponse>;
  NodesAdyenCheckoutPaymentMethods?: Maybe<core_apimessages_NodeResponse>;
  NodesBrands?: Maybe<core_apimessages_NodeResponse>;
  NodesBrowsePages?: Maybe<core_apimessages_NodeResponse>;
  NodesBumpRates?: Maybe<core_apimessages_NodeResponse>;
  NodesBusinessRegistrationNumbers?: Maybe<core_apimessages_NodeResponse>;
  NodesCanonicalProducts?: Maybe<core_apimessages_NodeResponse>;
  NodesCategories?: Maybe<core_apimessages_NodeResponse>;
  NodesCspExpressSaleItemBids?: Maybe<core_apimessages_NodeResponse>;
  NodesCsps?: Maybe<core_apimessages_NodeResponse>;
  NodesCuratedSets?: Maybe<core_apimessages_NodeResponse>;
  NodesEstimatedNewListingPrices?: Maybe<core_apimessages_NodeResponse>;
  NodesGearCollectionItems?: Maybe<core_apimessages_NodeResponse>;
  NodesImages?: Maybe<core_apimessages_NodeResponse>;
  NodesInformActThresholdStatuses?: Maybe<core_apimessages_NodeResponse>;
  NodesInternationalTaxProfiles?: Maybe<core_apimessages_NodeResponse>;
  NodesListings?: Maybe<core_apimessages_NodeResponse>;
  NodesListingsAffirmZeroPercentFinancingConfigurations?: Maybe<core_apimessages_NodeResponse>;
  NodesListingsAutoOfferEligibilities?: Maybe<core_apimessages_NodeResponse>;
  NodesListingsBuyerOfferEligibilities?: Maybe<core_apimessages_NodeResponse>;
  NodesListingsCertifiedPreOwned?: Maybe<core_apimessages_NodeResponse>;
  NodesListingsDigitalDetails?: Maybe<core_apimessages_NodeResponse>;
  NodesListingsExpressPay?: Maybe<core_apimessages_NodeResponse>;
  NodesListingsOfferBotRules?: Maybe<core_apimessages_NodeResponse>;
  NodesListingsPricings?: Maybe<core_apimessages_NodeResponse>;
  NodesListingsProtectionPlanOptions?: Maybe<core_apimessages_NodeResponse>;
  NodesListingsSalesMemberships?: Maybe<core_apimessages_NodeResponse>;
  NodesListingsSignals?: Maybe<core_apimessages_NodeResponse>;
  NodesListingsUserViews?: Maybe<core_apimessages_NodeResponse>;
  NodesMyUpdatesPromotions?: Maybe<core_apimessages_NodeResponse>;
  NodesNonUserExperiments?: Maybe<core_apimessages_NodeResponse>;
  NodesOrderBundles?: Maybe<core_apimessages_NodeResponse>;
  NodesOrders?: Maybe<core_apimessages_NodeResponse>;
  NodesPackageSizeSuggestions?: Maybe<core_apimessages_NodeResponse>;
  NodesPacklinkShipments?: Maybe<core_apimessages_NodeResponse>;
  NodesPriceChanges?: Maybe<core_apimessages_NodeResponse>;
  NodesPriceGuides?: Maybe<core_apimessages_NodeResponse>;
  NodesProductBreadcrumbs?: Maybe<core_apimessages_NodeResponse>;
  NodesProductReviews?: Maybe<core_apimessages_NodeResponse>;
  NodesPublicPriceRecords?: Maybe<core_apimessages_NodeResponse>;
  NodesReturnPolicies?: Maybe<core_apimessages_NodeResponse>;
  NodesSales?: Maybe<core_apimessages_NodeResponse>;
  NodesShipmentPackages?: Maybe<core_apimessages_NodeResponse>;
  NodesShippingRates?: Maybe<core_apimessages_NodeResponse>;
  NodesShopCampaignCoupons?: Maybe<core_apimessages_NodeResponse>;
  NodesShops?: Maybe<core_apimessages_NodeResponse>;
  NodesShopsCertifiedPreOwned?: Maybe<core_apimessages_NodeResponse>;
  NodesShopsConfigs?: Maybe<core_apimessages_NodeResponse>;
  NodesSiteBanners?: Maybe<core_apimessages_NodeResponse>;
  NodesTaxIdentifications?: Maybe<core_apimessages_NodeResponse>;
  NodesTaxProfiles?: Maybe<core_apimessages_NodeResponse>;
  NodesTruliooOnboardingRecords?: Maybe<core_apimessages_NodeResponse>;
  NodesUniversalPromoCampaigns?: Maybe<core_apimessages_NodeResponse>;
  NodesUserShopPermissions?: Maybe<core_apimessages_NodeResponse>;
  NodesUsers?: Maybe<core_apimessages_NodeResponse>;
  Offers?: Maybe<core_apimessages_OffersResponse>;
  OrderFlatRateShippingOptions?: Maybe<core_apimessages_OrderFlatRateShippingOptionsResponse>;
  OrderNotes?: Maybe<core_apimessages_OrderNotesIndexResponse>;
  OrderPayments?: Maybe<core_apimessages_OrderPaymentsResponse>;
  OrderRefunds?: Maybe<core_apimessages_OrderRefundsResponse>;
  OrderShippingAddresses?: Maybe<core_apimessages_OrderShippingAddressesResponse>;
  Orders?: Maybe<core_apimessages_OrdersResponse>;
  OrdersCustomsInfo?: Maybe<core_apimessages_OrdersCustomsInfoResponse>;
  OrdersFeedbacks?: Maybe<core_apimessages_OrderFeedbacksResponse>;
  OtherBuyersWithListingInCartCounts?: Maybe<core_apimessages_OtherBuyersWithListingInCartCountsResponse>;
  PayMyStatement?: Maybe<core_apimessages_PayMyStatementResponse>;
  PaypalOnboardSeller?: Maybe<core_apimessages_PaypalOnboardSellerResponse>;
  PreorderInfo?: Maybe<core_apimessages_PreorderInfoResponse>;
  PrepublishStatus?: Maybe<core_apimessages_PrepublishStatusResponse>;
  ProcessApprovedPaypalOrder?: Maybe<core_apimessages_ProcessApprovedPaypalOrderResponse>;
  PromotionalCodesRedeem?: Maybe<core_apimessages_RedeemPromotionalCodeResponse>;
  PromotionalCodesVerify?: Maybe<core_apimessages_VerifyPromotionalCodeResponse>;
  PublishAllListings?: Maybe<core_apimessages_PublishAllListingsResponse>;
  PurchaseShippingRate?: Maybe<core_apimessages_PurchaseShippingRateResponse>;
  RecentSearches?: Maybe<core_apimessages_RecentSearchesResponse>;
  RecentlyViewedListings?: Maybe<core_apimessages_RecentlyViewedListingsResponse>;
  ReferAFriend?: Maybe<core_apimessages_ReferAFriendResponse>;
  RegisterMyPacklinkAccount?: Maybe<core_apimessages_RegisterMyPacklinkAccountResponse>;
  RemoveListingFromCuratedSet?: Maybe<core_apimessages_AdminCuratedSetsCurateResponse>;
  RequestAccountDeletion?: Maybe<core_apimessages_RequestAccountDeletionResponse>;
  RequestTaxFormDownload?: Maybe<core_apimessages_RequestTaxFormDownloadResponse>;
  RestartTruliooOnboardingWorkflow?: Maybe<core_apimessages_TruliooOnboardingResponse>;
  ReverifyCreditCard?: Maybe<core_apimessages_ReverifyCreditCardResponse>;
  SaveMyPlaidAccount?: Maybe<core_apimessages_SaveMyPlaidAccountResponse>;
  SearchFilterPresets?: Maybe<core_apimessages_SearchFilterPresetsResponse>;
  SearchMetadata?: Maybe<core_apimessages_SearchMetadataResponse>;
  SeedListing?: Maybe<core_apimessages_SeedListingResponse>;
  SelectAddressAutocompleteSuggestion?: Maybe<core_apimessages_SelectAddressAutocompleteSuggestionResponse>;
  SelectCheckoutCreditCard?: Maybe<core_apimessages_SelectCheckoutCreditCardResponse>;
  SelectCheckoutPaymentMethod?: Maybe<core_apimessages_SelectCheckoutPaymentMethodResponse>;
  SellerListings?: Maybe<core_apimessages_SellerListingsResponse>;
  SellerOrders?: Maybe<core_apimessages_SellerOrdersResponse>;
  SellerVerificationPolicy?: Maybe<core_apimessages_SellerVerificationPolicyResponse>;
  SellingFees?: Maybe<core_apimessages_SellingFeesResponse>;
  SendDigitalListingDownloadEmail?: Maybe<core_apimessages_DigitalListingSendDownloadEmailResponse>;
  SetAccertifyTokens?: Maybe<core_apimessages_SetAccertifyTokensResponse>;
  SetupShopPaymentMethods?: Maybe<core_apimessages_SetupPaymentMethodsResponse>;
  Shipments?: Maybe<core_apimessages_ShipmentsResponse>;
  ShippingPrices?: Maybe<core_apimessages_ShippingPricesResponse>;
  ShippingProtection?: Maybe<core_apimessages_ShippingProtectionResponse>;
  ShopAdyenBalancePlatformNotifications?: Maybe<core_apimessages_ShopAdyenBalancePlatformNotificationsIndexResponse>;
  ShopReturnPolicies?: Maybe<core_apimessages_ShopReturnPoliciesResponse>;
  ShopShippingProfiles?: Maybe<core_apimessages_ShopShippingProfilesResponse>;
  Shops?: Maybe<core_apimessages_ShopsResponse>;
  ShowAdyenRegion?: Maybe<core_apimessages_ShowAdyenRegionResponse>;
  SiteBanner?: Maybe<core_apimessages_SiteBannerResponse>;
  SiteBanners?: Maybe<core_apimessages_SiteBannerResponse>;
  StartCheckout?: Maybe<core_apimessages_StartCheckoutResponse>;
  StartCheckoutForListing?: Maybe<core_apimessages_StartCheckoutForListingResponse>;
  StartPaypalCheckout?: Maybe<core_apimessages_StartPaypalCheckoutResponse>;
  StorefrontReturnPolicies?: Maybe<core_apimessages_StorefrontReturnPoliciesResponse>;
  Storefronts?: Maybe<core_apimessages_StorefrontsResponse>;
  TaxFormDownload?: Maybe<core_apimessages_TaxFormDownloadResponse>;
  TrackBumpInteraction?: Maybe<core_apimessages_TrackBumpInteractionResponse>;
  Traits?: Maybe<core_apimessages_TraitsResponse>;
  TruliooOnboarding?: Maybe<core_apimessages_TruliooOnboardingResponse>;
  TruliooOnboardingRecordResendPscEmail?: Maybe<core_apimessages_TruliooOnboardingRecordResendPscEmailResponse>;
  UndoDeleteMyFavorite?: Maybe<core_apimessages_UndoDeleteMyFavoriteResponse>;
  UnfeatureListingInCuratedSet?: Maybe<core_apimessages_AdminCuratedSetsCurateResponse>;
  UpdateAd?: Maybe<core_apimessages_UpdateAdResponse>;
  UpdateAdCampaign?: Maybe<core_apimessages_UpdateAdCampaignResponse>;
  UpdateAdGroup?: Maybe<core_apimessages_UpdateAdGroupResponse>;
  UpdateAdGroupKeywords?: Maybe<core_apimessages_UpdateAdGroupKeywordsResponse>;
  UpdateAdZone?: Maybe<core_apimessages_UpdateAdZoneResponse>;
  UpdateAdminNote?: Maybe<core_apimessages_AdminNote>;
  UpdateAdminTax1099kReportingThreshold?: Maybe<core_apimessages_AdminTax1099kReportingThresholdUpdateResponse>;
  UpdateAdvertiser?: Maybe<core_apimessages_UpdateAdvertiserResponse>;
  UpdateCartItemProtectionPlan?: Maybe<core_apimessages_UpdateCartItemProtectionPlanResponse>;
  UpdateCheckoutBillingAddress?: Maybe<core_apimessages_UpdateCheckoutBillingAddressResponse>;
  UpdateCheckoutPaypalOrder?: Maybe<core_apimessages_UpdateCheckoutPaypalOrderResponse>;
  UpdateCrossPlatformListingSession?: Maybe<core_apimessages_UpdateCrossPlatformListingSessionResponse>;
  UpdateFeedback?: Maybe<core_apimessages_UpdateFeedbackResponse>;
  UpdateGearCollectionItem?: Maybe<core_apimessages_UpdateGearCollectionItemResponse>;
  UpdateGearCollectionItemState?: Maybe<core_apimessages_UpdateGearCollectionItemStateResponse>;
  UpdateInternationalTaxProfile?: Maybe<core_apimessages_UpdateInternationalTaxProfileResponse>;
  UpdateListing?: Maybe<core_apimessages_UpdateListingResponse>;
  UpdateListingState?: Maybe<core_apimessages_UpdateListingStateResponse>;
  UpdateMyAddress?: Maybe<core_apimessages_UpdateAddressResponse>;
  UpdateMyCreditCard?: Maybe<core_apimessages_UpdateMyCreditCardResponse>;
  UpdateMyGearCollectionSettings?: Maybe<core_apimessages_UpdateMyGearCollectionSettingsResponse>;
  UpdateMyShop?: Maybe<core_apimessages_UpdateMyShopResponse>;
  UpdateMyShopBillingMethod?: Maybe<core_apimessages_UpdateBillingMethodResponse>;
  UpdateMyShopCampaign?: Maybe<core_apimessages_UpdateMyShopCampaignResponse>;
  UpdateMyShopShippingProfiles?: Maybe<core_apimessages_UpdateMyShopShippingProfilesResponse>;
  UpdateProductReview?: Maybe<core_apimessages_UpdateProductReviewResponse>;
  UpdateReturnPolicy?: Maybe<core_apimessages_UpdateReturnPolicyResponse>;
  UpdateTaxIdentification?: Maybe<core_apimessages_UpdateTaxIdentificationResponse>;
  UpdateUniversalPromoCampaign?: Maybe<core_apimessages_UpdateUniversalPromoCampaignResponse>;
  UpdateUserShopPermission?: Maybe<core_apimessages_CreateOrUpdateUserShopPermissionResponse>;
  UpsertMyFavorite?: Maybe<core_apimessages_UpsertMyFavoriteResponse>;
  UserWatches?: Maybe<core_apimessages_WatchesResponse>;
  Users?: Maybe<core_apimessages_UsersResponse>;
  VerifiedShippingAddress?: Maybe<core_apimessages_OrderVerifyShippingAddressResponse>;
  Videos?: Maybe<core_apimessages_VideosResponse>;
  VoteProductReview?: Maybe<core_apimessages_ProductReviewVoteResponse>;
  _source?: Maybe<Scalars["String"]["output"]>;
};

export type core_apimessages_CoreAcceptAddressSuggestionArgs = {
  input?: InputMaybe<Input_core_apimessages_AcceptAddressSuggestionRequest>;
};

export type core_apimessages_CoreAcceptedPaymentMethodsArgs = {
  input?: InputMaybe<Input_core_apimessages_AcceptedPaymentMethodsRequest>;
};

export type core_apimessages_CoreAccoutAlertsArgs = {
  input?: InputMaybe<Input_google_protobuf_Empty>;
};

export type core_apimessages_CoreAdCampaignsSearchArgs = {
  input?: InputMaybe<Input_core_apimessages_AdCampaignsSearchRequest>;
};

export type core_apimessages_CoreAdGroupsSearchArgs = {
  input?: InputMaybe<Input_core_apimessages_AdGroupsSearchRequest>;
};

export type core_apimessages_CoreAdServeArgs = {
  input?: InputMaybe<Input_core_apimessages_AdServeRequest>;
};

export type core_apimessages_CoreAdZonesSearchArgs = {
  input?: InputMaybe<Input_core_apimessages_AdZonesSearchRequest>;
};

export type core_apimessages_CoreAdaChatTokenArgs = {
  input?: InputMaybe<Input_google_protobuf_Empty>;
};

export type core_apimessages_CoreAddListingToCuratedSetArgs = {
  input?: InputMaybe<Input_core_apimessages_AdminCuratedSetsCurateRequest>;
};

export type core_apimessages_CoreAddressVerifyArgs = {
  input?: InputMaybe<Input_core_apimessages_AddressVerifyRequest>;
};

export type core_apimessages_CoreAdminBlockProductReviewArgs = {
  input?: InputMaybe<Input_core_apimessages_AdminBlockProductReviewRequest>;
};

export type core_apimessages_CoreAdminNotesSearchArgs = {
  input?: InputMaybe<Input_core_apimessages_AdminNotesSearchRequest>;
};

export type core_apimessages_CoreAdminTax1099kReportingThresholdSearchArgs = {
  input?: InputMaybe<Input_core_apimessages_AdminTax1099kReportingThresholdSearchRequest>;
};

export type core_apimessages_CoreAdminUserDatadogErrorsSearchArgs = {
  input?: InputMaybe<Input_core_apimessages_AdminUserDatadogErrorsSearchRequest>;
};

export type core_apimessages_CoreAdminUserSearchArgs = {
  input?: InputMaybe<Input_core_apimessages_AdminUserSearchRequest>;
};

export type core_apimessages_CoreAdsSearchArgs = {
  input?: InputMaybe<Input_core_apimessages_AdsSearchRequest>;
};

export type core_apimessages_CoreAdvertisersSearchArgs = {
  input?: InputMaybe<Input_core_apimessages_AdvertisersSearchRequest>;
};

export type core_apimessages_CoreAffirmFinancingPromotionsArgs = {
  input?: InputMaybe<Input_core_apimessages_AffirmFinancingPromotionsRequest>;
};

export type core_apimessages_CoreApplyCheckoutDiscountCodeArgs = {
  input?: InputMaybe<Input_core_apimessages_ApplyCheckoutDiscountCodeRequest>;
};

export type core_apimessages_CoreArticlesArgs = {
  input?: InputMaybe<Input_core_apimessages_ArticlesRequest>;
};

export type core_apimessages_CoreAutoOfferCreateArgs = {
  input?: InputMaybe<Input_core_apimessages_CreateAutoOfferRequest>;
};

export type core_apimessages_CoreAutoOffersArgs = {
  input?: InputMaybe<Input_core_apimessages_AutoOffersRequest>;
};

export type core_apimessages_CoreAutoOffersDeleteArgs = {
  input?: InputMaybe<Input_core_apimessages_DeleteAutoOfferRequest>;
};

export type core_apimessages_CoreBearerV2TokenArgs = {
  input?: InputMaybe<Input_core_apimessages_BearerV2TokenRequest>;
};

export type core_apimessages_CoreBillingMethodChallengeShopperArgs = {
  input?: InputMaybe<Input_core_apimessages_BillingMethodChallengeShopperRequest>;
};

export type core_apimessages_CoreBillingMethodIdentifyShopperArgs = {
  input?: InputMaybe<Input_core_apimessages_BillingMethodIdentifyShopperRequest>;
};

export type core_apimessages_CoreBillingMethodVerifyShopperArgs = {
  input?: InputMaybe<Input_core_apimessages_BillingMethodVerifyShopperRequest>;
};

export type core_apimessages_CoreBrandManagerBrandArgs = {
  input?: InputMaybe<Input_core_apimessages_BrandManagerBrandRequest>;
};

export type core_apimessages_CoreBrandManagerBrandsArgs = {
  input?: InputMaybe<Input_core_apimessages_BrandManagerBrandsRequest>;
};

export type core_apimessages_CoreBrandsArgs = {
  input?: InputMaybe<Input_core_apimessages_BrandsRequest>;
};

export type core_apimessages_CoreBrowsePageLinksArgs = {
  input?: InputMaybe<Input_core_apimessages_BrowsePageLinksRequest>;
};

export type core_apimessages_CoreBulkAddressesArgs = {
  input?: InputMaybe<Input_core_apimessages_BulkAddressesRequest>;
};

export type core_apimessages_CoreBulkCategoriesArgs = {
  input?: InputMaybe<Input_core_apimessages_BulkCategoriesRequest>;
};

export type core_apimessages_CoreBulkCreateAutoOffersArgs = {
  input?: InputMaybe<Input_core_apimessages_BulkCreateAutoOffersRequest>;
};

export type core_apimessages_CoreBulkCreateAutoOffersAsyncArgs = {
  input?: InputMaybe<Input_core_apimessages_BulkCreateAutoOffersAsyncRequest>;
};

export type core_apimessages_CoreBulkCreateBumpedArgs = {
  input?: InputMaybe<Input_core_apimessages_BulkCreateBumpedRequest>;
};

export type core_apimessages_CoreBulkCreateBumpedAsyncArgs = {
  input?: InputMaybe<Input_core_apimessages_BulkCreateBumpedAsyncRequest>;
};

export type core_apimessages_CoreBulkCreateSaleMembershipsArgs = {
  input?: InputMaybe<Input_core_apimessages_BulkSaleMembershipsRequest>;
};

export type core_apimessages_CoreBulkCreateZeroPercentAffirmArgs = {
  input?: InputMaybe<Input_core_apimessages_BulkCreateZeroPercentAffirmRequest>;
};

export type core_apimessages_CoreBulkCreateZeroPercentAffirmAsyncArgs = {
  input?: InputMaybe<Input_core_apimessages_BulkCreateZeroPercentAffirmAsyncRequest>;
};

export type core_apimessages_CoreBulkCuratedSetsArgs = {
  input?: InputMaybe<Input_core_apimessages_BulkCuratedSetsRequest>;
};

export type core_apimessages_CoreBulkDeleteAutoOffersArgs = {
  input?: InputMaybe<Input_core_apimessages_BulkDeleteAutoOffersRequest>;
};

export type core_apimessages_CoreBulkDeleteAutoOffersAsyncArgs = {
  input?: InputMaybe<Input_core_apimessages_BulkDeleteAutoOffersAsyncRequest>;
};

export type core_apimessages_CoreBulkDeleteBumpedArgs = {
  input?: InputMaybe<Input_core_apimessages_BulkDeleteBumpedRequest>;
};

export type core_apimessages_CoreBulkDeleteBumpedAsyncArgs = {
  input?: InputMaybe<Input_core_apimessages_BulkDeleteBumpedAsyncRequest>;
};

export type core_apimessages_CoreBulkDeleteListingsArgs = {
  input?: InputMaybe<Input_core_apimessages_BulkDeleteListingsRequest>;
};

export type core_apimessages_CoreBulkDeleteListingsAsyncArgs = {
  input?: InputMaybe<Input_core_apimessages_BulkDeleteListingsAsyncRequest>;
};

export type core_apimessages_CoreBulkDeleteSaleMembershipsArgs = {
  input?: InputMaybe<Input_core_apimessages_BulkSaleMembershipsRequest>;
};

export type core_apimessages_CoreBulkDeleteZeroPercentAffirmArgs = {
  input?: InputMaybe<Input_core_apimessages_BulkDeleteZeroPercentAffirmRequest>;
};

export type core_apimessages_CoreBulkDeleteZeroPercentAffirmAsyncArgs = {
  input?: InputMaybe<Input_core_apimessages_BulkDeleteZeroPercentAffirmAsyncRequest>;
};

export type core_apimessages_CoreBulkExportListingsArgs = {
  input?: InputMaybe<Input_core_apimessages_BulkExportListingsRequest>;
};

export type core_apimessages_CoreBulkImagesArgs = {
  input?: InputMaybe<Input_core_apimessages_BulkImageRequest>;
};

export type core_apimessages_CoreBulkOrdersArgs = {
  input?: InputMaybe<Input_core_apimessages_BulkOrdersRequest>;
};

export type core_apimessages_CoreBulkPublishListingsArgs = {
  input?: InputMaybe<Input_core_apimessages_BulkPublishListingsRequest>;
};

export type core_apimessages_CoreBulkPublishListingsAsyncArgs = {
  input?: InputMaybe<Input_core_apimessages_BulkPublishListingsAsyncRequest>;
};

export type core_apimessages_CoreBulkTaxPoliciesArgs = {
  input?: InputMaybe<Input_core_apimessages_BulkTaxPoliciesRequest>;
};

export type core_apimessages_CoreBumpKeysArgs = {
  input?: InputMaybe<Input_core_apimessages_BumpKeysRequest>;
};

export type core_apimessages_CoreBuyerOrdersArgs = {
  input?: InputMaybe<Input_core_apimessages_OrdersRequest>;
};

export type core_apimessages_CoreBuyerRefundsArgs = {
  input?: InputMaybe<Input_core_apimessages_BuyerRefundsRequest>;
};

export type core_apimessages_CoreCPSuggestionsArgs = {
  input?: InputMaybe<Input_core_apimessages_CPSuggestionsRequest>;
};

export type core_apimessages_CoreCSPReviewsArgs = {
  input?: InputMaybe<Input_core_apimessages_CSPReviewsRequest>;
};

export type core_apimessages_CoreCSPsArgs = {
  input?: InputMaybe<Input_core_apimessages_CSPsRequest>;
};

export type core_apimessages_CoreCSPsFeaturedListingArgs = {
  input?: InputMaybe<Input_core_apimessages_CSPsFeaturedListingRequest>;
};

export type core_apimessages_CoreCSPsInventoryArgs = {
  input?: InputMaybe<Input_core_apimessages_CSPsInventoryRequest>;
};

export type core_apimessages_CoreCSPsSpecsArgs = {
  input?: InputMaybe<Input_core_apimessages_CSPsSpecsRequest>;
};

export type core_apimessages_CoreCSPsVideoArgs = {
  input?: InputMaybe<Input_core_apimessages_CSPsVideoRequest>;
};

export type core_apimessages_CoreCancelOrderArgs = {
  input?: InputMaybe<Input_core_apimessages_CancelOrderRequest>;
};

export type core_apimessages_CoreCancelPlaidAccountArgs = {
  input?: InputMaybe<Input_core_apimessages_CancelPlaidAccountRequest>;
};

export type core_apimessages_CoreCanonicalProductRecommendationsArgs = {
  input?: InputMaybe<Input_core_apimessages_RecommendationsRequest>;
};

export type core_apimessages_CoreCartMoveToWatchListArgs = {
  input?: InputMaybe<Input_core_apimessages_CartMoveToWatchListRequest>;
};

export type core_apimessages_CoreCategoriesArgs = {
  input?: InputMaybe<Input_core_apimessages_CategoriesRequest>;
};

export type core_apimessages_CoreCheckoutActionArgs = {
  input?: InputMaybe<Input_core_apimessages_CheckoutActionRequest>;
};

export type core_apimessages_CoreCheckoutApplePayDetailsArgs = {
  input?: InputMaybe<Input_core_apimessages_CheckoutApplePayDetailsRequest>;
};

export type core_apimessages_CoreCheckoutCartEditArgs = {
  input?: InputMaybe<Input_core_apimessages_CheckoutCartEditRequest>;
};

export type core_apimessages_CoreCheckoutChallengeShopperArgs = {
  input?: InputMaybe<Input_core_apimessages_CheckoutChallengeShopperRequest>;
};

export type core_apimessages_CoreCheckoutCreatePaymentRedirectUrlArgs = {
  input?: InputMaybe<Input_core_apimessages_CheckoutCreatePaymentRedirectUrlRequest>;
};

export type core_apimessages_CoreCheckoutFinalizeDigitalWalletPaymentArgs = {
  input?: InputMaybe<Input_core_apimessages_CheckoutFinalizeDigitalWalletPaymentRequest>;
};

export type core_apimessages_CoreCheckoutFinalizeRedirectPaymentCheckoutArgs = {
  input?: InputMaybe<Input_core_apimessages_CheckoutFinalizeRedirectPaymentCheckoutRequest>;
};

export type core_apimessages_CoreCheckoutIdentifyShopperArgs = {
  input?: InputMaybe<Input_core_apimessages_CheckoutIdentifyShopperRequest>;
};

export type core_apimessages_CoreCheckoutSelectOrderShippingMethodArgs = {
  input?: InputMaybe<Input_core_apimessages_CheckoutSelectOrderShippingMethodRequest>;
};

export type core_apimessages_CoreCheckoutSelectShippingAddressArgs = {
  input?: InputMaybe<Input_core_apimessages_CheckoutSelectShippingAddressRequest>;
};

export type core_apimessages_CoreCheckoutShowArgs = {
  input?: InputMaybe<Input_core_apimessages_CheckoutShowRequest>;
};

export type core_apimessages_CoreCheckoutUpdateOrderQuantityArgs = {
  input?: InputMaybe<Input_core_apimessages_CheckoutUpdateOrderQuantityRequest>;
};

export type core_apimessages_CoreCheckoutUpdateShippingAddressArgs = {
  input?: InputMaybe<Input_core_apimessages_CheckoutUpdateShippingAddressRequest>;
};

export type core_apimessages_CoreCheckoutUpdateShippingEstimateArgs = {
  input?: InputMaybe<Input_core_apimessages_CheckoutUpdateShippingEstimateRequest>;
};

export type core_apimessages_CoreCheckoutVerifyShopperArgs = {
  input?: InputMaybe<Input_core_apimessages_CheckoutVerifyShopperRequest>;
};

export type core_apimessages_CoreClearBrowsingHistoryArgs = {
  input?: InputMaybe<Input_google_protobuf_Empty>;
};

export type core_apimessages_CoreCollectionHeaderArgs = {
  input?: InputMaybe<Input_core_apimessages_CollectionHeaderRequest>;
};

export type core_apimessages_CoreCompleteExpressSaleCheckoutArgs = {
  input?: InputMaybe<Input_core_apimessages_CompleteExpressSaleCheckoutRequest>;
};

export type core_apimessages_CoreConversationFlagMessageArgs = {
  input?: InputMaybe<Input_core_apimessages_ConversationFlagMessageRequest>;
};

export type core_apimessages_CoreCopyAdGroupArgs = {
  input?: InputMaybe<Input_core_apimessages_CopyAdGroupRequest>;
};

export type core_apimessages_CoreCountriesArgs = {
  input?: InputMaybe<Input_google_protobuf_Empty>;
};

export type core_apimessages_CoreCreateAdminNoteArgs = {
  input?: InputMaybe<Input_core_apimessages_AdminNoteCreateRequest>;
};

export type core_apimessages_CoreCreateAdminTax1099kReportingThresholdArgs = {
  input?: InputMaybe<Input_core_apimessages_AdminTax1099kReportingThresholdCreateRequest>;
};

export type core_apimessages_CoreCreateAdyenOnboardingLinkArgs = {
  input?: InputMaybe<Input_core_apimessages_AdyenOnboardingLinkRequest>;
};

export type core_apimessages_CoreCreateCheckoutPaypalOrderArgs = {
  input?: InputMaybe<Input_core_apimessages_CreateCheckoutPaypalOrderRequest>;
};

export type core_apimessages_CoreCreateExpressSaleCheckoutArgs = {
  input?: InputMaybe<Input_core_apimessages_CreateExpressSaleCheckoutRequest>;
};

export type core_apimessages_CoreCreateMyActionArgs = {
  input?: InputMaybe<Input_core_apimessages_CreateMyActionRequest>;
};

export type core_apimessages_CoreCreateMyAffirmCuratedSetMembershipArgs = {
  input?: InputMaybe<Input_core_apimessages_MyAffirmCuratedSetMembershipRequest>;
};

export type core_apimessages_CoreCreateMyCreditCardArgs = {
  input?: InputMaybe<Input_core_apimessages_CreateMyCreditCardRequest>;
};

export type core_apimessages_CoreCreateMyCuratedSetsMembershipArgs = {
  input?: InputMaybe<Input_core_apimessages_MyCuratedSetMembershipRequest>;
};

export type core_apimessages_CoreCreateMyFeedbackMessageArgs = {
  input?: InputMaybe<Input_core_apimessages_CreateMyFeedbackMessageRequest>;
};

export type core_apimessages_CoreCreateMyPlaidLinkTokenArgs = {
  input?: InputMaybe<Input_core_apimessages_CreateMyPlaidLinkTokenRequest>;
};

export type core_apimessages_CoreCreateMySaleMembershipArgs = {
  input?: InputMaybe<Input_core_apimessages_CreateMySaleMembershipRequest>;
};

export type core_apimessages_CoreCreateMyWatchesArgs = {
  input?: InputMaybe<Input_core_apimessages_WatchesCreateRequest>;
};

export type core_apimessages_CoreCreatePacklinkDraftArgs = {
  input?: InputMaybe<Input_core_apimessages_CreatePacklinkDraftRequest>;
};

export type core_apimessages_CoreCreateRecentSearchArgs = {
  input?: InputMaybe<Input_core_apimessages_CreateRecentSearchRequest>;
};

export type core_apimessages_CoreCreateShipmentArgs = {
  input?: InputMaybe<Input_core_apimessages_CreateShipmentRequest>;
};

export type core_apimessages_CoreCreateTaxIdentificationArgs = {
  input?: InputMaybe<Input_core_apimessages_CreateTaxIdentificationRequest>;
};

export type core_apimessages_CoreCreateTruliooOnboardingRecordArgs = {
  input?: InputMaybe<Input_google_protobuf_Empty>;
};

export type core_apimessages_CoreCreateUserShopPermissionArgs = {
  input?: InputMaybe<Input_core_apimessages_CreateUserShopPermissionRequest>;
};

export type core_apimessages_CoreCrossPlatformListingStatusArgs = {
  input?: InputMaybe<Input_core_apimessages_CrossPlatformListingStatusRequest>;
};

export type core_apimessages_CoreCuratedSetsArgs = {
  input?: InputMaybe<Input_core_apimessages_CuratedSetsRequest>;
};

export type core_apimessages_CoreDeactivateTaxIdentificationArgs = {
  input?: InputMaybe<Input_core_apimessages_DeactivateTaxIdentificationRequest>;
};

export type core_apimessages_CoreDeclineFeedbackArgs = {
  input?: InputMaybe<Input_core_apimessages_DeclineFeedbackRequest>;
};

export type core_apimessages_CoreDeleteAdArgs = {
  input?: InputMaybe<Input_core_apimessages_DeleteAdRequest>;
};

export type core_apimessages_CoreDeleteAdminTax1099kReportingThresholdArgs = {
  input?: InputMaybe<Input_core_apimessages_AdminTax1099kReportingThresholdDeleteRequest>;
};

export type core_apimessages_CoreDeleteCartItemProtectionPlanArgs = {
  input?: InputMaybe<Input_core_apimessages_DeleteCartItemProtectionPlanRequest>;
};

export type core_apimessages_CoreDeleteDraftListingArgs = {
  input?: InputMaybe<Input_core_apimessages_DraftListingDeleteRequest>;
};

export type core_apimessages_CoreDeleteMyAddressArgs = {
  input?: InputMaybe<Input_core_apimessages_AddressDeleteRequest>;
};

export type core_apimessages_CoreDeleteMyAffirmCuratedSetMembershipArgs = {
  input?: InputMaybe<Input_core_apimessages_MyAffirmCuratedSetMembershipRequest>;
};

export type core_apimessages_CoreDeleteMyCreditCardArgs = {
  input?: InputMaybe<Input_core_apimessages_DeleteMyCreditCardRequest>;
};

export type core_apimessages_CoreDeleteMyCuratedSetsMembershipArgs = {
  input?: InputMaybe<Input_core_apimessages_MyCuratedSetMembershipRequest>;
};

export type core_apimessages_CoreDeleteMyFavoriteArgs = {
  input?: InputMaybe<Input_core_apimessages_DeleteMyFavoriteRequest>;
};

export type core_apimessages_CoreDeleteMyFeedbackMessageArgs = {
  input?: InputMaybe<Input_core_apimessages_DeleteMyFeedbackMessageRequest>;
};

export type core_apimessages_CoreDeleteMyGearCollectionItemArgs = {
  input?: InputMaybe<Input_core_apimessages_DeleteGearCollectionItemRequest>;
};

export type core_apimessages_CoreDeleteMySaleMembershipArgs = {
  input?: InputMaybe<Input_core_apimessages_DeleteMySaleMembershipRequest>;
};

export type core_apimessages_CoreDeleteMyWatchesArgs = {
  input?: InputMaybe<Input_core_apimessages_WatchesDeleteRequest>;
};

export type core_apimessages_CoreDeleteUserShopPermissionArgs = {
  input?: InputMaybe<Input_core_apimessages_DeleteUserShopPermissionRequest>;
};

export type core_apimessages_CoreDelinkUserServiceArgs = {
  input?: InputMaybe<Input_core_apimessages_DelinkUserServiceRequest>;
};

export type core_apimessages_CoreEstimateNegotiationSalesTaxArgs = {
  input?: InputMaybe<Input_core_apimessages_EstimateNegotiationSalesTaxRequest>;
};

export type core_apimessages_CoreEstimatedMonthlyPaymentsArgs = {
  input?: InputMaybe<Input_core_apimessages_EstimatedMonthlyPaymentsRequest>;
};

export type core_apimessages_CoreExperimentsArgs = {
  input?: InputMaybe<Input_google_protobuf_Empty>;
};

export type core_apimessages_CoreExportSecondaryUserActivitiesArgs = {
  input?: InputMaybe<Input_core_apimessages_ExportSecondaryUserActivitiesRequest>;
};

export type core_apimessages_CoreExpressSaleCheckoutShowArgs = {
  input?: InputMaybe<Input_core_apimessages_ExpressSaleCheckoutShowRequest>;
};

export type core_apimessages_CoreFeatureListingInCuratedSetArgs = {
  input?: InputMaybe<Input_core_apimessages_AdminCuratedSetsCurateRequest>;
};

export type core_apimessages_CoreFeedbackArgs = {
  input?: InputMaybe<Input_core_apimessages_FeedbacksRequest>;
};

export type core_apimessages_CoreFeedbackSummariesArgs = {
  input?: InputMaybe<Input_core_apimessages_FeedbackSummariesRequest>;
};

export type core_apimessages_CoreFinalizeAffirmCheckoutPaymentArgs = {
  input?: InputMaybe<Input_core_apimessages_FinalizeAffirmCheckoutPaymentRequest>;
};

export type core_apimessages_CoreFinalizeCardCheckoutPaymentArgs = {
  input?: InputMaybe<Input_core_apimessages_FinalizeCardCheckoutPaymentRequest>;
};

export type core_apimessages_CoreFinalizePaypalCheckoutPaymentArgs = {
  input?: InputMaybe<Input_core_apimessages_FinalizePaypalCheckoutPaymentRequest>;
};

export type core_apimessages_CoreFinalizeRedirectAuthCheckoutPaymentArgs = {
  input?: InputMaybe<Input_core_apimessages_FinalizeRedirectAuthCheckoutPaymentRequest>;
};

export type core_apimessages_CoreFindAddressAutocompleteSuggestionsArgs = {
  input?: InputMaybe<Input_core_apimessages_FindAddressAutocompleteSuggestionsRequest>;
};

export type core_apimessages_CoreFindFavoriteArgs = {
  input?: InputMaybe<Input_core_apimessages_FindFavoriteRequest>;
};

export type core_apimessages_CoreFlagProductReviewArgs = {
  input?: InputMaybe<Input_core_apimessages_FlagProductReviewRequest>;
};

export type core_apimessages_CoreFormSelectCategoriesArgs = {
  input?: InputMaybe<Input_core_apimessages_FormSelectCategoriesRequest>;
};

export type core_apimessages_CoreGearCollectionSettingArgs = {
  input?: InputMaybe<Input_google_protobuf_Empty>;
};

export type core_apimessages_CoreGenerateAdminReportArgs = {
  input?: InputMaybe<Input_core_apimessages_GenerateAdminReportRequest>;
};

export type core_apimessages_CoreGuestCheckoutVerifyShopperArgs = {
  input?: InputMaybe<Input_core_apimessages_GuestCheckoutVerifyShopperRequest>;
};

export type core_apimessages_CoreHomepageArgs = {
  input?: InputMaybe<Input_core_apimessages_HomepageRequest>;
};

export type core_apimessages_CoreImagesArgs = {
  input?: InputMaybe<Input_core_apimessages_ImagesRequest>;
};

export type core_apimessages_CoreListingCorrectionArgs = {
  input?: InputMaybe<Input_core_apimessages_ListingCorrectionRequest>;
};

export type core_apimessages_CoreListingCountsArgs = {
  input?: InputMaybe<Input_core_apimessages_ListingCountsRequest>;
};

export type core_apimessages_CoreListingPriceRecommendationsArgs = {
  input?: InputMaybe<Input_core_apimessages_ListingPriceRecommendationsRequest>;
};

export type core_apimessages_CoreListingsArgs = {
  input?: InputMaybe<Input_core_apimessages_ListingsRequest>;
};

export type core_apimessages_CoreListingsCuratedSetsArgs = {
  input?: InputMaybe<Input_core_apimessages_ListingsCuratedSetsRequest>;
};

export type core_apimessages_CoreListingsInUserCartArgs = {
  input?: InputMaybe<Input_core_apimessages_ListingsInUserCartRequest>;
};

export type core_apimessages_CoreListingsOrderStatsArgs = {
  input?: InputMaybe<Input_core_apimessages_ListingsOrderStatsRequest>;
};

export type core_apimessages_CoreListingsPricingArgs = {
  input?: InputMaybe<Input_core_apimessages_ListingsPricingRequest>;
};

export type core_apimessages_CoreListingsSpecsArgs = {
  input?: InputMaybe<Input_core_apimessages_ListingsSpecsRequest>;
};

export type core_apimessages_CoreListingsValidForPublishArgs = {
  input?: InputMaybe<Input_core_apimessages_ListingsValidForPublishRequest>;
};

export type core_apimessages_CoreLoginMyPacklinkAccountArgs = {
  input?: InputMaybe<Input_core_apimessages_LoginMyPacklinkAccountRequest>;
};

export type core_apimessages_CoreMarkOrderAsReceivedArgs = {
  input?: InputMaybe<Input_core_apimessages_MarkOrderAsReceivedRequest>;
};

export type core_apimessages_CoreMeArgs = {
  input?: InputMaybe<Input_google_protobuf_Empty>;
};

export type core_apimessages_CoreMosaicTilesHomepageArgs = {
  input?: InputMaybe<Input_google_protobuf_Empty>;
};

export type core_apimessages_CoreMyAddressesArgs = {
  input?: InputMaybe<Input_core_apimessages_MyAddressesRequest>;
};

export type core_apimessages_CoreMyAvailableActionsIndexArgs = {
  input?: InputMaybe<Input_core_apimessages_MyAvailableActionsIndexRequest>;
};

export type core_apimessages_CoreMyBuyerCouponsArgs = {
  input?: InputMaybe<Input_core_apimessages_MyBuyerCouponsRequest>;
};

export type core_apimessages_CoreMyCartItemBundlesIndexArgs = {
  input?: InputMaybe<Input_google_protobuf_Empty>;
};

export type core_apimessages_CoreMyCartItemsIndexArgs = {
  input?: InputMaybe<Input_google_protobuf_Empty>;
};

export type core_apimessages_CoreMyCountsArgs = {
  input?: InputMaybe<Input_google_protobuf_Empty>;
};

export type core_apimessages_CoreMyCreditCardsArgs = {
  input?: InputMaybe<Input_google_protobuf_Empty>;
};

export type core_apimessages_CoreMyDirectCheckoutProfileArgs = {
  input?: InputMaybe<Input_core_apimessages_MyDirectCheckoutProfileRequest>;
};

export type core_apimessages_CoreMyFavoriteSearchesArgs = {
  input?: InputMaybe<Input_core_apimessages_GetMyFavoriteSavedSearchesRequest>;
};

export type core_apimessages_CoreMyFeedSearchArgs = {
  input?: InputMaybe<Input_core_apimessages_MyFeedSearchRequest>;
};

export type core_apimessages_CoreMyFeedbackMessagesArgs = {
  input?: InputMaybe<Input_core_apimessages_MyFeedbackMessagesRequest>;
};

export type core_apimessages_CoreMyGearCollectionSettingsArgs = {
  input?: InputMaybe<Input_google_protobuf_Empty>;
};

export type core_apimessages_CoreMyHomepageNotificationsArgs = {
  input?: InputMaybe<Input_core_apimessages_MyHomepageNotificationsRequest>;
};

export type core_apimessages_CoreMyMessagesArgs = {
  input?: InputMaybe<Input_core_apimessages_MyMessagesRequest>;
};

export type core_apimessages_CoreMyPacklinkAccountStatusArgs = {
  input?: InputMaybe<Input_google_protobuf_Empty>;
};

export type core_apimessages_CoreMyPayoutBalancesArgs = {
  input?: InputMaybe<Input_google_protobuf_Empty>;
};

export type core_apimessages_CoreMyReverbCreditsArgs = {
  input?: InputMaybe<Input_google_protobuf_Empty>;
};

export type core_apimessages_CoreMySalesArgs = {
  input?: InputMaybe<Input_google_protobuf_Empty>;
};

export type core_apimessages_CoreMyShopArgs = {
  input?: InputMaybe<Input_google_protobuf_Empty>;
};

export type core_apimessages_CoreMyShopAdyenBalanceOnboardingArgs = {
  input?: InputMaybe<Input_google_protobuf_Empty>;
};

export type core_apimessages_CoreMyShopBadgesArgs = {
  input?: InputMaybe<Input_google_protobuf_Empty>;
};

export type core_apimessages_CoreMyShopBumpStatsArgs = {
  input?: InputMaybe<Input_core_apimessages_MyShopBumpStatsRequest>;
};

export type core_apimessages_CoreMyShopCampaignsArgs = {
  input?: InputMaybe<Input_google_protobuf_Empty>;
};

export type core_apimessages_CoreMyShopEarningsArgs = {
  input?: InputMaybe<Input_core_apimessages_MyShopEarningsRequest>;
};

export type core_apimessages_CoreMyShopExportDac7OrderDataArgs = {
  input?: InputMaybe<Input_core_apimessages_MyShopExportDac7OrderDataRequest>;
};

export type core_apimessages_CoreMyShopOnboardingArgs = {
  input?: InputMaybe<Input_google_protobuf_Empty>;
};

export type core_apimessages_CoreMyShopOrderRefundStatsArgs = {
  input?: InputMaybe<Input_core_apimessages_MyShopOrderRefundStatsRequest>;
};

export type core_apimessages_CoreMyShopPaypalProfileArgs = {
  input?: InputMaybe<Input_google_protobuf_Empty>;
};

export type core_apimessages_CoreMyShopShippingProfilesArgs = {
  input?: InputMaybe<Input_core_apimessages_MyShopShippingProfilesRequest>;
};

export type core_apimessages_CoreMyShopShippingRatesArgs = {
  input?: InputMaybe<Input_core_apimessages_MyShopShippingRatesRequest>;
};

export type core_apimessages_CoreMyShopStatsArgs = {
  input?: InputMaybe<Input_core_apimessages_MyShopStatsRequest>;
};

export type core_apimessages_CoreMyUserServicesArgs = {
  input?: InputMaybe<Input_google_protobuf_Empty>;
};

export type core_apimessages_CoreMyWatchesArgs = {
  input?: InputMaybe<Input_core_apimessages_WatchesRequest>;
};

export type core_apimessages_CoreNegotiationsArgs = {
  input?: InputMaybe<Input_core_apimessages_NegotiationsRequest>;
};

export type core_apimessages_CoreNegotiationsAcceptArgs = {
  input?: InputMaybe<Input_core_apimessages_UpdateNegotiationRequest>;
};

export type core_apimessages_CoreNegotiationsCounterArgs = {
  input?: InputMaybe<Input_core_apimessages_UpdateNegotiationRequest>;
};

export type core_apimessages_CoreNegotiationsCreateArgs = {
  input?: InputMaybe<Input_core_apimessages_CreateNegotiationRequest>;
};

export type core_apimessages_CoreNegotiationsRejectArgs = {
  input?: InputMaybe<Input_core_apimessages_UpdateNegotiationRequest>;
};

export type core_apimessages_CoreNegotiationsShippingLocationsArgs = {
  input?: InputMaybe<Input_core_apimessages_NegotiationsShippingLocationRequest>;
};

export type core_apimessages_CoreNodesAdCampaignsArgs = {
  input?: InputMaybe<Input_core_apimessages_NodeRequest>;
};

export type core_apimessages_CoreNodesAdGroupKeywordsArgs = {
  input?: InputMaybe<Input_core_apimessages_NodeRequest>;
};

export type core_apimessages_CoreNodesAdGroupsArgs = {
  input?: InputMaybe<Input_core_apimessages_NodeRequest>;
};

export type core_apimessages_CoreNodesAdZonesArgs = {
  input?: InputMaybe<Input_core_apimessages_NodeRequest>;
};

export type core_apimessages_CoreNodesAddressesArgs = {
  input?: InputMaybe<Input_core_apimessages_NodeRequest>;
};

export type core_apimessages_CoreNodesAdsArgs = {
  input?: InputMaybe<Input_core_apimessages_NodeRequest>;
};

export type core_apimessages_CoreNodesAdvertisersArgs = {
  input?: InputMaybe<Input_core_apimessages_NodeRequest>;
};

export type core_apimessages_CoreNodesAdyenCheckoutPaymentMethodsArgs = {
  input?: InputMaybe<Input_core_apimessages_NodeRequest>;
};

export type core_apimessages_CoreNodesBrandsArgs = {
  input?: InputMaybe<Input_core_apimessages_NodeRequest>;
};

export type core_apimessages_CoreNodesBrowsePagesArgs = {
  input?: InputMaybe<Input_core_apimessages_NodeRequest>;
};

export type core_apimessages_CoreNodesBumpRatesArgs = {
  input?: InputMaybe<Input_core_apimessages_NodeRequest>;
};

export type core_apimessages_CoreNodesBusinessRegistrationNumbersArgs = {
  input?: InputMaybe<Input_core_apimessages_NodeRequest>;
};

export type core_apimessages_CoreNodesCanonicalProductsArgs = {
  input?: InputMaybe<Input_core_apimessages_NodeRequest>;
};

export type core_apimessages_CoreNodesCategoriesArgs = {
  input?: InputMaybe<Input_core_apimessages_NodeRequest>;
};

export type core_apimessages_CoreNodesCspExpressSaleItemBidsArgs = {
  input?: InputMaybe<Input_core_apimessages_NodeRequest>;
};

export type core_apimessages_CoreNodesCspsArgs = {
  input?: InputMaybe<Input_core_apimessages_NodeRequest>;
};

export type core_apimessages_CoreNodesCuratedSetsArgs = {
  input?: InputMaybe<Input_core_apimessages_NodeRequest>;
};

export type core_apimessages_CoreNodesEstimatedNewListingPricesArgs = {
  input?: InputMaybe<Input_core_apimessages_NodeRequest>;
};

export type core_apimessages_CoreNodesGearCollectionItemsArgs = {
  input?: InputMaybe<Input_core_apimessages_NodeRequest>;
};

export type core_apimessages_CoreNodesImagesArgs = {
  input?: InputMaybe<Input_core_apimessages_NodeRequest>;
};

export type core_apimessages_CoreNodesInformActThresholdStatusesArgs = {
  input?: InputMaybe<Input_core_apimessages_NodeRequest>;
};

export type core_apimessages_CoreNodesInternationalTaxProfilesArgs = {
  input?: InputMaybe<Input_core_apimessages_NodeRequest>;
};

export type core_apimessages_CoreNodesListingsArgs = {
  input?: InputMaybe<Input_core_apimessages_NodeRequest>;
};

export type core_apimessages_CoreNodesListingsAffirmZeroPercentFinancingConfigurationsArgs =
  {
    input?: InputMaybe<Input_core_apimessages_NodeRequest>;
  };

export type core_apimessages_CoreNodesListingsAutoOfferEligibilitiesArgs = {
  input?: InputMaybe<Input_core_apimessages_NodeRequest>;
};

export type core_apimessages_CoreNodesListingsBuyerOfferEligibilitiesArgs = {
  input?: InputMaybe<Input_core_apimessages_NodeRequest>;
};

export type core_apimessages_CoreNodesListingsCertifiedPreOwnedArgs = {
  input?: InputMaybe<Input_core_apimessages_NodeRequest>;
};

export type core_apimessages_CoreNodesListingsDigitalDetailsArgs = {
  input?: InputMaybe<Input_core_apimessages_NodeRequest>;
};

export type core_apimessages_CoreNodesListingsExpressPayArgs = {
  input?: InputMaybe<Input_core_apimessages_NodeRequest>;
};

export type core_apimessages_CoreNodesListingsOfferBotRulesArgs = {
  input?: InputMaybe<Input_core_apimessages_NodeRequest>;
};

export type core_apimessages_CoreNodesListingsPricingsArgs = {
  input?: InputMaybe<Input_core_apimessages_NodeRequest>;
};

export type core_apimessages_CoreNodesListingsProtectionPlanOptionsArgs = {
  input?: InputMaybe<Input_core_apimessages_NodeRequest>;
};

export type core_apimessages_CoreNodesListingsSalesMembershipsArgs = {
  input?: InputMaybe<Input_core_apimessages_NodeRequest>;
};

export type core_apimessages_CoreNodesListingsSignalsArgs = {
  input?: InputMaybe<Input_core_apimessages_NodeRequest>;
};

export type core_apimessages_CoreNodesListingsUserViewsArgs = {
  input?: InputMaybe<Input_core_apimessages_NodeRequest>;
};

export type core_apimessages_CoreNodesMyUpdatesPromotionsArgs = {
  input?: InputMaybe<Input_core_apimessages_NodeRequest>;
};

export type core_apimessages_CoreNodesNonUserExperimentsArgs = {
  input?: InputMaybe<Input_core_apimessages_NodeRequest>;
};

export type core_apimessages_CoreNodesOrderBundlesArgs = {
  input?: InputMaybe<Input_core_apimessages_NodeRequest>;
};

export type core_apimessages_CoreNodesOrdersArgs = {
  input?: InputMaybe<Input_core_apimessages_NodeRequest>;
};

export type core_apimessages_CoreNodesPackageSizeSuggestionsArgs = {
  input?: InputMaybe<Input_core_apimessages_NodeRequest>;
};

export type core_apimessages_CoreNodesPacklinkShipmentsArgs = {
  input?: InputMaybe<Input_core_apimessages_NodeRequest>;
};

export type core_apimessages_CoreNodesPriceChangesArgs = {
  input?: InputMaybe<Input_core_apimessages_NodeRequest>;
};

export type core_apimessages_CoreNodesPriceGuidesArgs = {
  input?: InputMaybe<Input_core_apimessages_NodeRequest>;
};

export type core_apimessages_CoreNodesProductBreadcrumbsArgs = {
  input?: InputMaybe<Input_core_apimessages_NodeRequest>;
};

export type core_apimessages_CoreNodesProductReviewsArgs = {
  input?: InputMaybe<Input_core_apimessages_NodeRequest>;
};

export type core_apimessages_CoreNodesPublicPriceRecordsArgs = {
  input?: InputMaybe<Input_core_apimessages_NodeRequest>;
};

export type core_apimessages_CoreNodesReturnPoliciesArgs = {
  input?: InputMaybe<Input_core_apimessages_NodeRequest>;
};

export type core_apimessages_CoreNodesSalesArgs = {
  input?: InputMaybe<Input_core_apimessages_NodeRequest>;
};

export type core_apimessages_CoreNodesShipmentPackagesArgs = {
  input?: InputMaybe<Input_core_apimessages_NodeRequest>;
};

export type core_apimessages_CoreNodesShippingRatesArgs = {
  input?: InputMaybe<Input_core_apimessages_NodeRequest>;
};

export type core_apimessages_CoreNodesShopCampaignCouponsArgs = {
  input?: InputMaybe<Input_core_apimessages_NodeRequest>;
};

export type core_apimessages_CoreNodesShopsArgs = {
  input?: InputMaybe<Input_core_apimessages_NodeRequest>;
};

export type core_apimessages_CoreNodesShopsCertifiedPreOwnedArgs = {
  input?: InputMaybe<Input_core_apimessages_NodeRequest>;
};

export type core_apimessages_CoreNodesShopsConfigsArgs = {
  input?: InputMaybe<Input_core_apimessages_NodeRequest>;
};

export type core_apimessages_CoreNodesSiteBannersArgs = {
  input?: InputMaybe<Input_core_apimessages_NodeRequest>;
};

export type core_apimessages_CoreNodesTaxIdentificationsArgs = {
  input?: InputMaybe<Input_core_apimessages_NodeRequest>;
};

export type core_apimessages_CoreNodesTaxProfilesArgs = {
  input?: InputMaybe<Input_core_apimessages_NodeRequest>;
};

export type core_apimessages_CoreNodesTruliooOnboardingRecordsArgs = {
  input?: InputMaybe<Input_core_apimessages_NodeRequest>;
};

export type core_apimessages_CoreNodesUniversalPromoCampaignsArgs = {
  input?: InputMaybe<Input_core_apimessages_NodeRequest>;
};

export type core_apimessages_CoreNodesUserShopPermissionsArgs = {
  input?: InputMaybe<Input_core_apimessages_NodeRequest>;
};

export type core_apimessages_CoreNodesUsersArgs = {
  input?: InputMaybe<Input_core_apimessages_NodeRequest>;
};

export type core_apimessages_CoreOffersArgs = {
  input?: InputMaybe<Input_core_apimessages_OffersRequest>;
};

export type core_apimessages_CoreOrderFlatRateShippingOptionsArgs = {
  input?: InputMaybe<Input_core_apimessages_OrderFlatRateShippingOptionsRequest>;
};

export type core_apimessages_CoreOrderNotesArgs = {
  input?: InputMaybe<Input_core_apimessages_OrderNotesIndexRequest>;
};

export type core_apimessages_CoreOrderPaymentsArgs = {
  input?: InputMaybe<Input_core_apimessages_OrderPaymentsRequest>;
};

export type core_apimessages_CoreOrderRefundsArgs = {
  input?: InputMaybe<Input_core_apimessages_OrderRefundsRequest>;
};

export type core_apimessages_CoreOrderShippingAddressesArgs = {
  input?: InputMaybe<Input_core_apimessages_OrderShippingAddressesRequest>;
};

export type core_apimessages_CoreOrdersArgs = {
  input?: InputMaybe<Input_core_apimessages_OrdersRequest>;
};

export type core_apimessages_CoreOrdersCustomsInfoArgs = {
  input?: InputMaybe<Input_core_apimessages_OrdersCustomsInfoRequest>;
};

export type core_apimessages_CoreOrdersFeedbacksArgs = {
  input?: InputMaybe<Input_core_apimessages_OrderFeedbacksRequest>;
};

export type core_apimessages_CoreOtherBuyersWithListingInCartCountsArgs = {
  input?: InputMaybe<Input_core_apimessages_OtherBuyersWithListingInCartCountsRequest>;
};

export type core_apimessages_CorePayMyStatementArgs = {
  input?: InputMaybe<Input_core_apimessages_PayMyStatementRequest>;
};

export type core_apimessages_CorePaypalOnboardSellerArgs = {
  input?: InputMaybe<Input_core_apimessages_PaypalOnboardSellerRequest>;
};

export type core_apimessages_CorePreorderInfoArgs = {
  input?: InputMaybe<Input_core_apimessages_PreorderInfoRequest>;
};

export type core_apimessages_CorePrepublishStatusArgs = {
  input?: InputMaybe<Input_google_protobuf_Empty>;
};

export type core_apimessages_CoreProcessApprovedPaypalOrderArgs = {
  input?: InputMaybe<Input_core_apimessages_ProcessApprovedPaypalOrderRequest>;
};

export type core_apimessages_CorePromotionalCodesRedeemArgs = {
  input?: InputMaybe<Input_core_apimessages_RedeemPromotionalCodeRequest>;
};

export type core_apimessages_CorePromotionalCodesVerifyArgs = {
  input?: InputMaybe<Input_core_apimessages_VerifyPromotionalCodeRequest>;
};

export type core_apimessages_CorePublishAllListingsArgs = {
  input?: InputMaybe<Input_google_protobuf_Empty>;
};

export type core_apimessages_CorePurchaseShippingRateArgs = {
  input?: InputMaybe<Input_core_apimessages_PurchaseShippingRateRequest>;
};

export type core_apimessages_CoreRecentSearchesArgs = {
  input?: InputMaybe<Input_google_protobuf_Empty>;
};

export type core_apimessages_CoreRecentlyViewedListingsArgs = {
  input?: InputMaybe<Input_core_apimessages_RecentlyViewedListingsRequest>;
};

export type core_apimessages_CoreReferAFriendArgs = {
  input?: InputMaybe<Input_google_protobuf_Empty>;
};

export type core_apimessages_CoreRegisterMyPacklinkAccountArgs = {
  input?: InputMaybe<Input_core_apimessages_RegisterMyPacklinkAccountRequest>;
};

export type core_apimessages_CoreRemoveListingFromCuratedSetArgs = {
  input?: InputMaybe<Input_core_apimessages_AdminCuratedSetsCurateRequest>;
};

export type core_apimessages_CoreRequestAccountDeletionArgs = {
  input?: InputMaybe<Input_core_apimessages_RequestAccountDeletionRequest>;
};

export type core_apimessages_CoreRequestTaxFormDownloadArgs = {
  input?: InputMaybe<Input_core_apimessages_RequestTaxFormDownloadRequest>;
};

export type core_apimessages_CoreRestartTruliooOnboardingWorkflowArgs = {
  input?: InputMaybe<Input_google_protobuf_Empty>;
};

export type core_apimessages_CoreReverifyCreditCardArgs = {
  input?: InputMaybe<Input_core_apimessages_ReverifyCreditCardRequest>;
};

export type core_apimessages_CoreSaveMyPlaidAccountArgs = {
  input?: InputMaybe<Input_core_apimessages_SaveMyPlaidAccountRequest>;
};

export type core_apimessages_CoreSearchFilterPresetsArgs = {
  input?: InputMaybe<Input_core_apimessages_SearchFilterPresetsRequest>;
};

export type core_apimessages_CoreSearchMetadataArgs = {
  input?: InputMaybe<Input_core_apimessages_SearchMetadataRequest>;
};

export type core_apimessages_CoreSeedListingArgs = {
  input?: InputMaybe<Input_core_apimessages_SeedListingRequest>;
};

export type core_apimessages_CoreSelectAddressAutocompleteSuggestionArgs = {
  input?: InputMaybe<Input_core_apimessages_SelectAddressAutocompleteSuggestionRequest>;
};

export type core_apimessages_CoreSelectCheckoutCreditCardArgs = {
  input?: InputMaybe<Input_core_apimessages_SelectCheckoutCreditCardRequest>;
};

export type core_apimessages_CoreSelectCheckoutPaymentMethodArgs = {
  input?: InputMaybe<Input_core_apimessages_SelectCheckoutPaymentMethodRequest>;
};

export type core_apimessages_CoreSellerListingsArgs = {
  input?: InputMaybe<Input_core_apimessages_ListingsRequest>;
};

export type core_apimessages_CoreSellerOrdersArgs = {
  input?: InputMaybe<Input_core_apimessages_OrdersRequest>;
};

export type core_apimessages_CoreSellerVerificationPolicyArgs = {
  input?: InputMaybe<Input_google_protobuf_Empty>;
};

export type core_apimessages_CoreSellingFeesArgs = {
  input?: InputMaybe<Input_core_apimessages_SellingFeesRequest>;
};

export type core_apimessages_CoreSendDigitalListingDownloadEmailArgs = {
  input?: InputMaybe<Input_core_apimessages_DigitalListingSendDownloadEmailRequest>;
};

export type core_apimessages_CoreSetAccertifyTokensArgs = {
  input?: InputMaybe<Input_core_apimessages_SetAccertifyTokensRequest>;
};

export type core_apimessages_CoreSetupShopPaymentMethodsArgs = {
  input?: InputMaybe<Input_core_apimessages_SetupPaymentMethodsRequest>;
};

export type core_apimessages_CoreShipmentsArgs = {
  input?: InputMaybe<Input_core_apimessages_ShipmentsRequest>;
};

export type core_apimessages_CoreShippingPricesArgs = {
  input?: InputMaybe<Input_core_apimessages_ShippingPricesRequest>;
};

export type core_apimessages_CoreShippingProtectionArgs = {
  input?: InputMaybe<Input_core_apimessages_ShippingProtectionRequest>;
};

export type core_apimessages_CoreShopAdyenBalancePlatformNotificationsArgs = {
  input?: InputMaybe<Input_core_apimessages_ShopAdyenBalancePlatformNotificationsIndexRequest>;
};

export type core_apimessages_CoreShopReturnPoliciesArgs = {
  input?: InputMaybe<Input_core_apimessages_ShopReturnPoliciesRequest>;
};

export type core_apimessages_CoreShopShippingProfilesArgs = {
  input?: InputMaybe<Input_core_apimessages_ShopShippingProfilesRequest>;
};

export type core_apimessages_CoreShopsArgs = {
  input?: InputMaybe<Input_core_apimessages_ShopsRequest>;
};

export type core_apimessages_CoreShowAdyenRegionArgs = {
  input?: InputMaybe<Input_core_apimessages_ShowAdyenRegionRequest>;
};

export type core_apimessages_CoreSiteBannerArgs = {
  input?: InputMaybe<Input_core_apimessages_SiteBannerRequest>;
};

export type core_apimessages_CoreSiteBannersArgs = {
  input?: InputMaybe<Input_core_apimessages_SiteBannerRequest>;
};

export type core_apimessages_CoreStartCheckoutArgs = {
  input?: InputMaybe<Input_core_apimessages_StartCheckoutRequest>;
};

export type core_apimessages_CoreStartCheckoutForListingArgs = {
  input?: InputMaybe<Input_core_apimessages_StartCheckoutForListingRequest>;
};

export type core_apimessages_CoreStartPaypalCheckoutArgs = {
  input?: InputMaybe<Input_core_apimessages_StartPaypalCheckoutRequest>;
};

export type core_apimessages_CoreStorefrontReturnPoliciesArgs = {
  input?: InputMaybe<Input_core_apimessages_StorefrontReturnPoliciesRequest>;
};

export type core_apimessages_CoreStorefrontsArgs = {
  input?: InputMaybe<Input_core_apimessages_StorefrontsRequest>;
};

export type core_apimessages_CoreTaxFormDownloadArgs = {
  input?: InputMaybe<Input_core_apimessages_TaxFormDownloadRequest>;
};

export type core_apimessages_CoreTrackBumpInteractionArgs = {
  input?: InputMaybe<Input_core_apimessages_TrackBumpInteractionRequest>;
};

export type core_apimessages_CoreTraitsArgs = {
  input?: InputMaybe<Input_core_apimessages_TraitsRequest>;
};

export type core_apimessages_CoreTruliooOnboardingArgs = {
  input?: InputMaybe<Input_google_protobuf_Empty>;
};

export type core_apimessages_CoreTruliooOnboardingRecordResendPscEmailArgs = {
  input?: InputMaybe<Input_core_apimessages_TruliooOnboardingRecordResendPscEmailRequest>;
};

export type core_apimessages_CoreUndoDeleteMyFavoriteArgs = {
  input?: InputMaybe<Input_core_apimessages_UndoDeleteMyFavoriteRequest>;
};

export type core_apimessages_CoreUnfeatureListingInCuratedSetArgs = {
  input?: InputMaybe<Input_core_apimessages_AdminCuratedSetsCurateRequest>;
};

export type core_apimessages_CoreUpdateAdArgs = {
  input?: InputMaybe<Input_core_apimessages_UpdateAdRequest>;
};

export type core_apimessages_CoreUpdateAdCampaignArgs = {
  input?: InputMaybe<Input_core_apimessages_UpdateAdCampaignRequest>;
};

export type core_apimessages_CoreUpdateAdGroupArgs = {
  input?: InputMaybe<Input_core_apimessages_UpdateAdGroupRequest>;
};

export type core_apimessages_CoreUpdateAdGroupKeywordsArgs = {
  input?: InputMaybe<Input_core_apimessages_UpdateAdGroupKeywordsRequest>;
};

export type core_apimessages_CoreUpdateAdZoneArgs = {
  input?: InputMaybe<Input_core_apimessages_UpdateAdZoneRequest>;
};

export type core_apimessages_CoreUpdateAdminNoteArgs = {
  input?: InputMaybe<Input_core_apimessages_AdminNoteUpdateRequest>;
};

export type core_apimessages_CoreUpdateAdminTax1099kReportingThresholdArgs = {
  input?: InputMaybe<Input_core_apimessages_AdminTax1099kReportingThresholdUpdateRequest>;
};

export type core_apimessages_CoreUpdateAdvertiserArgs = {
  input?: InputMaybe<Input_core_apimessages_UpdateAdvertiserRequest>;
};

export type core_apimessages_CoreUpdateCartItemProtectionPlanArgs = {
  input?: InputMaybe<Input_core_apimessages_UpdateCartItemProtectionPlanRequest>;
};

export type core_apimessages_CoreUpdateCheckoutBillingAddressArgs = {
  input?: InputMaybe<Input_core_apimessages_UpdateCheckoutBillingAddressRequest>;
};

export type core_apimessages_CoreUpdateCheckoutPaypalOrderArgs = {
  input?: InputMaybe<Input_core_apimessages_UpdateCheckoutPaypalOrderRequest>;
};

export type core_apimessages_CoreUpdateCrossPlatformListingSessionArgs = {
  input?: InputMaybe<Input_core_apimessages_UpdateCrossPlatformListingSessionRequest>;
};

export type core_apimessages_CoreUpdateFeedbackArgs = {
  input?: InputMaybe<Input_core_apimessages_UpdateFeedbackRequest>;
};

export type core_apimessages_CoreUpdateGearCollectionItemArgs = {
  input?: InputMaybe<Input_core_apimessages_UpdateGearCollectionItemRequest>;
};

export type core_apimessages_CoreUpdateGearCollectionItemStateArgs = {
  input?: InputMaybe<Input_core_apimessages_UpdateGearCollectionItemStateRequest>;
};

export type core_apimessages_CoreUpdateInternationalTaxProfileArgs = {
  input?: InputMaybe<Input_core_apimessages_UpdateInternationalTaxProfileRequest>;
};

export type core_apimessages_CoreUpdateListingArgs = {
  input?: InputMaybe<Input_core_apimessages_UpdateListingRequest>;
};

export type core_apimessages_CoreUpdateListingStateArgs = {
  input?: InputMaybe<Input_core_apimessages_UpdateListingStateRequest>;
};

export type core_apimessages_CoreUpdateMyAddressArgs = {
  input?: InputMaybe<Input_core_apimessages_UpdateAddressRequest>;
};

export type core_apimessages_CoreUpdateMyCreditCardArgs = {
  input?: InputMaybe<Input_core_apimessages_UpdateMyCreditCardRequest>;
};

export type core_apimessages_CoreUpdateMyGearCollectionSettingsArgs = {
  input?: InputMaybe<Input_core_apimessages_UpdateMyGearCollectionSettingsRequest>;
};

export type core_apimessages_CoreUpdateMyShopArgs = {
  input?: InputMaybe<Input_core_apimessages_UpdateMyShopRequest>;
};

export type core_apimessages_CoreUpdateMyShopBillingMethodArgs = {
  input?: InputMaybe<Input_core_apimessages_UpdateBillingMethodRequest>;
};

export type core_apimessages_CoreUpdateMyShopCampaignArgs = {
  input?: InputMaybe<Input_core_apimessages_UpdateMyShopCampaignRequest>;
};

export type core_apimessages_CoreUpdateMyShopShippingProfilesArgs = {
  input?: InputMaybe<Input_core_apimessages_UpdateMyShopShippingProfilesRequest>;
};

export type core_apimessages_CoreUpdateProductReviewArgs = {
  input?: InputMaybe<Input_core_apimessages_UpdateProductReviewRequest>;
};

export type core_apimessages_CoreUpdateReturnPolicyArgs = {
  input?: InputMaybe<Input_core_apimessages_UpdateReturnPolicyRequest>;
};

export type core_apimessages_CoreUpdateTaxIdentificationArgs = {
  input?: InputMaybe<Input_core_apimessages_UpdateTaxIdentificationRequest>;
};

export type core_apimessages_CoreUpdateUniversalPromoCampaignArgs = {
  input?: InputMaybe<Input_core_apimessages_UpdateUniversalPromoCampaignRequest>;
};

export type core_apimessages_CoreUpdateUserShopPermissionArgs = {
  input?: InputMaybe<Input_core_apimessages_UpdateUserShopPermissionRequest>;
};

export type core_apimessages_CoreUpsertMyFavoriteArgs = {
  input?: InputMaybe<Input_core_apimessages_UpsertMyFavoriteRequest>;
};

export type core_apimessages_CoreUserWatchesArgs = {
  input?: InputMaybe<Input_core_apimessages_UserWatchesRequest>;
};

export type core_apimessages_CoreUsersArgs = {
  input?: InputMaybe<Input_core_apimessages_UsersRequest>;
};

export type core_apimessages_CoreVerifiedShippingAddressArgs = {
  input?: InputMaybe<Input_core_apimessages_OrderVerifyShippingAddressRequest>;
};

export type core_apimessages_CoreVideosArgs = {
  input?: InputMaybe<Input_core_apimessages_VideosRequest>;
};

export type core_apimessages_CoreVoteProductReviewArgs = {
  input?: InputMaybe<Input_core_apimessages_ProductReviewVoteRequest>;
};

export type core_apimessages_CountriesResponse = {
  __typename?: "core_apimessages_CountriesResponse";
  _source?: Maybe<Scalars["String"]["output"]>;
  countries?: Maybe<Array<Maybe<core_apimessages_Country>>>;
};

export type core_apimessages_Country = {
  __typename?: "core_apimessages_Country";
  _id?: Maybe<Scalars["ID"]["output"]>;
  _source?: Maybe<Scalars["String"]["output"]>;
  countryCode?: Maybe<Scalars["String"]["output"]>;
  id?: Maybe<Scalars["String"]["output"]>;
  name?: Maybe<Scalars["String"]["output"]>;
  subregionRequired?: Maybe<Scalars["Boolean"]["output"]>;
  subregions?: Maybe<Array<Maybe<core_apimessages_Subregion>>>;
  superRegion?: Maybe<core_apimessages_SuperRegion>;
};

export type core_apimessages_CountsLinks = {
  __typename?: "core_apimessages_CountsLinks";
  _source?: Maybe<Scalars["String"]["output"]>;
  self?: Maybe<core_apimessages_Link>;
};

export type core_apimessages_CountsResponse = {
  __typename?: "core_apimessages_CountsResponse";
  /** @deprecated Field no longer supported */
  Links?: Maybe<core_apimessages_CountsLinks>;
  _source?: Maybe<Scalars["String"]["output"]>;
  activeCartItemTotal?: Maybe<core_apimessages_UserBadge>;
  activeOffers?: Maybe<core_apimessages_UserBadge>;
  billingProfile?: Maybe<core_apimessages_UserBadge>;
  buyerActiveOffers?: Maybe<core_apimessages_UserBadge>;
  buyerOrdersAwaitingPickup?: Maybe<core_apimessages_UserBadge>;
  countsLinks?: Maybe<core_apimessages_CountsLinks>;
  invoicedStatementCount?: Maybe<core_apimessages_UserBadge>;
  layawayOrders?: Maybe<core_apimessages_UserBadge>;
  outstandingFeedback?: Maybe<core_apimessages_UserBadge>;
  sellerActiveOffers?: Maybe<core_apimessages_UserBadge>;
  sellerOrdersAwaitingPickup?: Maybe<core_apimessages_UserBadge>;
  sellerOrdersAwaitingShipment?: Maybe<core_apimessages_UserBadge>;
  sellerOrdersRefundRequested?: Maybe<core_apimessages_UserBadge>;
  total?: Maybe<core_apimessages_CountsTotal>;
  unpaidOrders?: Maybe<core_apimessages_UserBadge>;
  unreadMessages?: Maybe<core_apimessages_UserBadge>;
  userNotifications?: Maybe<Array<Maybe<core_apimessages_UserNotification>>>;
};

export type core_apimessages_CountsTotal = {
  __typename?: "core_apimessages_CountsTotal";
  _source?: Maybe<Scalars["String"]["output"]>;
  count?: Maybe<Scalars["Int"]["output"]>;
};

export type core_apimessages_CreateAutoOfferRequest = {
  __typename?: "core_apimessages_CreateAutoOfferRequest";
  _source?: Maybe<Scalars["String"]["output"]>;
  /** @deprecated Field no longer supported */
  instantOffersEnabled?: Maybe<Scalars["Boolean"]["output"]>;
  listingId?: Maybe<Scalars["String"]["output"]>;
  message?: Maybe<Scalars["String"]["output"]>;
  percentage?: Maybe<Scalars["Int"]["output"]>;
  /** @deprecated Field no longer supported */
  price?: Maybe<core_apimessages_Money>;
  /** @deprecated Field no longer supported */
  pushToExisting?: Maybe<Scalars["Boolean"]["output"]>;
  shippingPriceOverride?: Maybe<core_apimessages_Money>;
};

export type core_apimessages_CreateAutoOfferResponse = {
  __typename?: "core_apimessages_CreateAutoOfferResponse";
  _source?: Maybe<Scalars["String"]["output"]>;
  autoOffer?: Maybe<core_apimessages_AutoOffer>;
};

export type core_apimessages_CreateCheckoutPaypalOrderRequest = {
  __typename?: "core_apimessages_CreateCheckoutPaypalOrderRequest";
  _source?: Maybe<Scalars["String"]["output"]>;
  /** checkout UUID */
  id?: Maybe<Scalars["String"]["output"]>;
  noShipping?: Maybe<Scalars["Boolean"]["output"]>;
  reverbApp?: Maybe<Scalars["String"]["output"]>;
};

export type core_apimessages_CreateCheckoutPaypalOrderResponse = {
  __typename?: "core_apimessages_CreateCheckoutPaypalOrderResponse";
  _source?: Maybe<Scalars["String"]["output"]>;
  /** checkout UUID */
  id?: Maybe<Scalars["String"]["output"]>;
  paypalOrderId?: Maybe<Scalars["String"]["output"]>;
  redirectUrl?: Maybe<Scalars["String"]["output"]>;
};

export type core_apimessages_CreateExpressSaleCheckoutRequest = {
  __typename?: "core_apimessages_CreateExpressSaleCheckoutRequest";
  _source?: Maybe<Scalars["String"]["output"]>;
  address?: Maybe<core_apimessages_AddressEntry>;
  addressUuid?: Maybe<Scalars["String"]["output"]>;
  checkoutId?: Maybe<Scalars["String"]["output"]>;
  isTermsAccepted?: Maybe<Scalars["Boolean"]["output"]>;
  payoutAmountCents?: Maybe<Scalars["Int"]["output"]>;
};

export type core_apimessages_CreateExpressSaleCheckoutResponse = {
  __typename?: "core_apimessages_CreateExpressSaleCheckoutResponse";
  _source?: Maybe<Scalars["String"]["output"]>;
  checkoutId?: Maybe<Scalars["String"]["output"]>;
};

export type core_apimessages_CreateMyActionRequest = {
  __typename?: "core_apimessages_CreateMyActionRequest";
  _source?: Maybe<Scalars["String"]["output"]>;
  name?: Maybe<core_apimessages_UserActionName>;
};

export type core_apimessages_CreateMyActionResponse = {
  __typename?: "core_apimessages_CreateMyActionResponse";
  _source?: Maybe<Scalars["String"]["output"]>;
  action?: Maybe<core_apimessages_AvailableAction>;
  errors?: Maybe<Array<Maybe<core_apimessages_Error>>>;
};

export type core_apimessages_CreateMyCreditCardRequest = {
  __typename?: "core_apimessages_CreateMyCreditCardRequest";
  _source?: Maybe<Scalars["String"]["output"]>;
  billingAddress?: Maybe<core_apimessages_AddressEntry>;
  creditCard?: Maybe<core_apimessages_CreateMyCreditCardRequest_CreditCardEntry>;
};

export type core_apimessages_CreateMyCreditCardRequest_CreditCardEntry = {
  __typename?: "core_apimessages_CreateMyCreditCardRequest_CreditCardEntry";
  _source?: Maybe<Scalars["String"]["output"]>;
  cardholderName?: Maybe<Scalars["String"]["output"]>;
  tokenizedFields?: Maybe<core_apimessages_CreditCardTokenizedFields>;
};

export type core_apimessages_CreateMyCreditCardResponse = {
  __typename?: "core_apimessages_CreateMyCreditCardResponse";
  _source?: Maybe<Scalars["String"]["output"]>;
  creditCard?: Maybe<core_apimessages_CreditCard>;
};

export type core_apimessages_CreateMyFeedbackMessageRequest = {
  __typename?: "core_apimessages_CreateMyFeedbackMessageRequest";
  _source?: Maybe<Scalars["String"]["output"]>;
  message?: Maybe<Scalars["String"]["output"]>;
};

export type core_apimessages_CreateMyFeedbackMessageResponse = {
  __typename?: "core_apimessages_CreateMyFeedbackMessageResponse";
  _source?: Maybe<Scalars["String"]["output"]>;
  id?: Maybe<Scalars["String"]["output"]>;
};

export type core_apimessages_CreateMyPlaidLinkTokenRequest = {
  __typename?: "core_apimessages_CreateMyPlaidLinkTokenRequest";
  _source?: Maybe<Scalars["String"]["output"]>;
  countryCode?: Maybe<Scalars["String"]["output"]>;
  plaidAccountId?: Maybe<Scalars["String"]["output"]>;
  requiresMobileRedirect?: Maybe<Scalars["Boolean"]["output"]>;
};

export type core_apimessages_CreateMyPlaidLinkTokenResponse = {
  __typename?: "core_apimessages_CreateMyPlaidLinkTokenResponse";
  _source?: Maybe<Scalars["String"]["output"]>;
  linkToken?: Maybe<Scalars["String"]["output"]>;
};

export type core_apimessages_CreateMySaleMembershipRequest = {
  __typename?: "core_apimessages_CreateMySaleMembershipRequest";
  _source?: Maybe<Scalars["String"]["output"]>;
  listingId?: Maybe<Scalars["String"]["output"]>;
  saleId?: Maybe<Scalars["String"]["output"]>;
};

export type core_apimessages_CreateMySaleMembershipResponse = {
  __typename?: "core_apimessages_CreateMySaleMembershipResponse";
  _source?: Maybe<Scalars["String"]["output"]>;
  saleMembership?: Maybe<core_apimessages_ListingSaleMembership>;
};

export type core_apimessages_CreateNegotiationRequest = {
  __typename?: "core_apimessages_CreateNegotiationRequest";
  _source?: Maybe<Scalars["String"]["output"]>;
  bumpKey?: Maybe<core_apimessages_BumpKey>;
  /** optional, to copy message/photos to relevant conversation if offer is included is messages */
  conversation?: Maybe<core_apimessages_CreateNegotiationRequest_ConversationData>;
  countryCode?: Maybe<Scalars["String"]["output"]>;
  creditCardId?: Maybe<Scalars["String"]["output"]>;
  message?: Maybe<Scalars["String"]["output"]>;
  offerItems?: Maybe<Array<Maybe<core_apimessages_OfferItemRequest>>>;
  postalCode?: Maybe<Scalars["String"]["output"]>;
  recipientUuid?: Maybe<Scalars["String"]["output"]>;
  regionCode?: Maybe<Scalars["String"]["output"]>;
  shippingAddressUuid?: Maybe<Scalars["String"]["output"]>;
  /** country code OR subregion (eg continental US) */
  shippingRegionCode?: Maybe<Scalars["String"]["output"]>;
};

export type core_apimessages_CreateNegotiationRequest_ConversationData = {
  __typename?: "core_apimessages_CreateNegotiationRequest_ConversationData";
  _source?: Maybe<Scalars["String"]["output"]>;
  listingId?: Maybe<Scalars["String"]["output"]>;
  photos?: Maybe<Array<Maybe<core_apimessages_ImageEntry>>>;
};

export type core_apimessages_CreateOrUpdateUserShopPermissionResponse = {
  __typename?: "core_apimessages_CreateOrUpdateUserShopPermissionResponse";
  _source?: Maybe<Scalars["String"]["output"]>;
  userShopPermission?: Maybe<core_apimessages_UserShopPermission>;
};

export type core_apimessages_CreateOrderNoteRequest = {
  __typename?: "core_apimessages_CreateOrderNoteRequest";
  _source?: Maybe<Scalars["String"]["output"]>;
  body?: Maybe<Scalars["String"]["output"]>;
  /** orders.id */
  orderId?: Maybe<Scalars["String"]["output"]>;
};

export type core_apimessages_CreateOrderNoteResponse = {
  __typename?: "core_apimessages_CreateOrderNoteResponse";
  _source?: Maybe<Scalars["String"]["output"]>;
  errors?: Maybe<Array<Maybe<core_apimessages_Error>>>;
  orderNote?: Maybe<core_apimessages_OrderNote>;
};

export type core_apimessages_CreatePacklinkDraftRequest = {
  __typename?: "core_apimessages_CreatePacklinkDraftRequest";
  _source?: Maybe<Scalars["String"]["output"]>;
  orderId?: Maybe<Scalars["String"]["output"]>;
};

export type core_apimessages_CreatePacklinkDraftResponse = {
  __typename?: "core_apimessages_CreatePacklinkDraftResponse";
  _source?: Maybe<Scalars["String"]["output"]>;
  shipment?: Maybe<core_apimessages_PacklinkShipment>;
};

export type core_apimessages_CreateRecentSearchRequest = {
  __typename?: "core_apimessages_CreateRecentSearchRequest";
  _source?: Maybe<Scalars["String"]["output"]>;
  query?: Maybe<Scalars["String"]["output"]>;
};

export type core_apimessages_CreateRecentSearchResponse = {
  __typename?: "core_apimessages_CreateRecentSearchResponse";
  _source?: Maybe<Scalars["String"]["output"]>;
  query?: Maybe<Scalars["String"]["output"]>;
};

export type core_apimessages_CreateShipmentError = {
  __typename?: "core_apimessages_CreateShipmentError";
  _source?: Maybe<Scalars["String"]["output"]>;
  errorType?: Maybe<core_apimessages_CreateShipmentErrorType>;
  message?: Maybe<Scalars["String"]["output"]>;
};

export enum core_apimessages_CreateShipmentErrorType {
  SHIPMENT_FORM_BASE_ERROR = "SHIPMENT_FORM_BASE_ERROR",
  SHIPMENT_FORM_CARRIER_CALCULATED_UPS_PO_BOX_ERROR = "SHIPMENT_FORM_CARRIER_CALCULATED_UPS_PO_BOX_ERROR",
  SHIPMENT_FORM_CUSTOMS_ERROR = "SHIPMENT_FORM_CUSTOMS_ERROR",
  SHIPMENT_FORM_CUSTOMS_SIGNER_ERROR = "SHIPMENT_FORM_CUSTOMS_SIGNER_ERROR",
  SHIPMENT_FORM_SHIPPING_ZONE_CHANGED_ERROR = "SHIPMENT_FORM_SHIPPING_ZONE_CHANGED_ERROR",
}

export type core_apimessages_CreateShipmentRequest = {
  __typename?: "core_apimessages_CreateShipmentRequest";
  _source?: Maybe<Scalars["String"]["output"]>;
  customs?: Maybe<core_apimessages_CustomsInfo>;
  fromAddress?: Maybe<core_apimessages_Address>;
  /** shipments.uuid */
  id?: Maybe<Scalars["String"]["output"]>;
  mediaMailQualified?: Maybe<Scalars["Boolean"]["output"]>;
  modifiedCarrierCalculatedShipment?: Maybe<Scalars["Boolean"]["output"]>;
  orderUuids?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
  package?: Maybe<core_apimessages_ShippingLabelPackage>;
  shipmentType?: Maybe<core_apimessages_Shipment_ShipmentType>;
  shippingProtection?: Maybe<Scalars["Boolean"]["output"]>;
  shippingProtectionPrice?: Maybe<core_apimessages_Money>;
  signatureRequired?: Maybe<Scalars["Boolean"]["output"]>;
  toAddress?: Maybe<core_apimessages_Address>;
  toAddressIsResidential?: Maybe<Scalars["Boolean"]["output"]>;
};

export type core_apimessages_CreateShipmentResponse = {
  __typename?: "core_apimessages_CreateShipmentResponse";
  _source?: Maybe<Scalars["String"]["output"]>;
  errors?: Maybe<Array<Maybe<core_apimessages_CreateShipmentError>>>;
  shipment?: Maybe<core_apimessages_Shipment>;
};

export type core_apimessages_CreateTaxIdentificationRequest = {
  __typename?: "core_apimessages_CreateTaxIdentificationRequest";
  _source?: Maybe<Scalars["String"]["output"]>;
  countryCode?: Maybe<Scalars["String"]["output"]>;
  taxId?: Maybe<Scalars["String"]["output"]>;
  taxType?: Maybe<Scalars["String"]["output"]>;
};

export type core_apimessages_CreateTaxIdentificationResponse = {
  __typename?: "core_apimessages_CreateTaxIdentificationResponse";
  _source?: Maybe<Scalars["String"]["output"]>;
  shop?: Maybe<MyShop>;
  shopId?: Maybe<Scalars["String"]["output"]>;
  taxIdentification?: Maybe<core_apimessages_TaxIdentification>;
};

export type core_apimessages_CreateTruliooOnboardingRecordResponse = {
  __typename?: "core_apimessages_CreateTruliooOnboardingRecordResponse";
  _source?: Maybe<Scalars["String"]["output"]>;
  errors?: Maybe<Array<Maybe<core_apimessages_Error>>>;
  truliooOnboardingRecord?: Maybe<core_apimessages_TruliooOnboardingRecord>;
};

export type core_apimessages_CreateUserShopPermissionRequest = {
  __typename?: "core_apimessages_CreateUserShopPermissionRequest";
  _source?: Maybe<Scalars["String"]["output"]>;
  email?: Maybe<Scalars["String"]["output"]>;
  permissionLevel?: Maybe<core_apimessages_UserShopPermission_PermissionLevel>;
};

export type core_apimessages_CreditCard = {
  __typename?: "core_apimessages_CreditCard";
  _source?: Maybe<Scalars["String"]["output"]>;
  address?: Maybe<core_apimessages_Address>;
  brand?: Maybe<core_apimessages_CreditCard_Brand>;
  cardBin?: Maybe<Scalars["String"]["output"]>;
  cardSource?: Maybe<Scalars["String"]["output"]>;
  /**
   * Use the brand field instead
   * @deprecated Field no longer supported
   */
  cardType?: Maybe<Scalars["String"]["output"]>;
  cardholderName?: Maybe<Scalars["String"]["output"]>;
  display?: Maybe<core_apimessages_CreditCard_Display>;
  expirationMonth?: Maybe<Scalars["String"]["output"]>;
  expirationYear?: Maybe<Scalars["String"]["output"]>;
  /** credit_cards.id */
  id?: Maybe<Scalars["String"]["output"]>;
  last4?: Maybe<Scalars["String"]["output"]>;
  needsReverification?: Maybe<Scalars["Boolean"]["output"]>;
  primaryBilling?: Maybe<Scalars["Boolean"]["output"]>;
  primaryForCheckout?: Maybe<Scalars["Boolean"]["output"]>;
};

/**
 *   *
 * Fields generated by Adyen's secured fields library.
 * Each key is the name of an individual credit card field (ex: "encryptedExpiryYear"),
 * and each value is an encrypted string.
 * Adyen decrypts each field when it reaches their server.
 */
export type core_apimessages_CreditCardTokenizedFields = {
  __typename?: "core_apimessages_CreditCardTokenizedFields";
  _source?: Maybe<Scalars["String"]["output"]>;
  encryptedCardNumber?: Maybe<Scalars["String"]["output"]>;
  encryptedExpiryMonth?: Maybe<Scalars["String"]["output"]>;
  encryptedExpiryYear?: Maybe<Scalars["String"]["output"]>;
  encryptedSecurityCode?: Maybe<Scalars["String"]["output"]>;
};

export type core_apimessages_CreditCard_Brand = {
  __typename?: "core_apimessages_CreditCard_Brand";
  _source?: Maybe<Scalars["String"]["output"]>;
  /** Readable brand name of type. Examples: AMEX is "American Express," MASTERCARD is "MasterCard" */
  name?: Maybe<Scalars["String"]["output"]>;
  type?: Maybe<core_apimessages_CreditCard_Brand_Type>;
};

export enum core_apimessages_CreditCard_Brand_Type {
  AMEX = "AMEX",
  DISCOVER = "DISCOVER",
  JCB = "JCB",
  MASTERCARD = "MASTERCARD",
  /** Default if brand is not registered */
  UNKNOWN = "UNKNOWN",
  VISA = "VISA",
}

export type core_apimessages_CreditCard_Display = {
  __typename?: "core_apimessages_CreditCard_Display";
  _source?: Maybe<Scalars["String"]["output"]>;
  expirationDate?: Maybe<Scalars["String"]["output"]>;
  /** Localized card summary. Example: "Visa ending in 1111" */
  summary?: Maybe<Scalars["String"]["output"]>;
};

export type core_apimessages_CrossPlatformListingStatusRequest = {
  __typename?: "core_apimessages_CrossPlatformListingStatusRequest";
  _source?: Maybe<Scalars["String"]["output"]>;
  listingId?: Maybe<Scalars["String"]["output"]>;
};

export type core_apimessages_CrossPlatformListingStatusResponse = {
  __typename?: "core_apimessages_CrossPlatformListingStatusResponse";
  _source?: Maybe<Scalars["String"]["output"]>;
  status?: Maybe<core_apimessages_CrossPlatformListingStatusResponse_SessionStatus>;
  ttlSeconds?: Maybe<Scalars["Int"]["output"]>;
};

export enum core_apimessages_CrossPlatformListingStatusResponse_SessionStatus {
  ACTIVE = "ACTIVE",
  BUSY = "BUSY",
  COMPLETED = "COMPLETED",
  NONE = "NONE",
}

export type core_apimessages_CspRowQuery = {
  __typename?: "core_apimessages_CspRowQuery";
  _source?: Maybe<Scalars["String"]["output"]>;
  brandSlugs?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
  categoryUuids?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
  cspSlugs?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
  rowTitle?: Maybe<Scalars["String"]["output"]>;
  sort?: Maybe<Scalars["String"]["output"]>;
};

export type core_apimessages_CuratedSet = {
  __typename?: "core_apimessages_CuratedSet";
  _source?: Maybe<Scalars["String"]["output"]>;
  /** curated_sets.id */
  id?: Maybe<Scalars["String"]["output"]>;
  setType?: Maybe<Scalars["String"]["output"]>;
  slug?: Maybe<Scalars["String"]["output"]>;
  title?: Maybe<Scalars["String"]["output"]>;
  webLink?: Maybe<core_apimessages_Link>;
};

export type core_apimessages_CuratedSetFeatureRequest = {
  __typename?: "core_apimessages_CuratedSetFeatureRequest";
  _source?: Maybe<Scalars["String"]["output"]>;
  /** curated_sets.id */
  id?: Maybe<Scalars["String"]["output"]>;
  /** products.id */
  productId?: Maybe<Scalars["String"]["output"]>;
};

export type core_apimessages_CuratedSetMembership = {
  __typename?: "core_apimessages_CuratedSetMembership";
  _source?: Maybe<Scalars["String"]["output"]>;
  curatedSetId?: Maybe<Scalars["String"]["output"]>;
  id?: Maybe<Scalars["String"]["output"]>;
  productId?: Maybe<Scalars["String"]["output"]>;
};

export enum core_apimessages_CuratedSet_SetType {
  LP_SELLER_PICKS = "LP_SELLER_PICKS",
  NONE = "NONE",
}

export type core_apimessages_CuratedSetsForListing = {
  __typename?: "core_apimessages_CuratedSetsForListing";
  _source?: Maybe<Scalars["String"]["output"]>;
  curatedSets?: Maybe<Array<Maybe<core_apimessages_ListingCuratedSet>>>;
  /** products.id */
  id?: Maybe<Scalars["String"]["output"]>;
};

export type core_apimessages_CuratedSetsRequest = {
  __typename?: "core_apimessages_CuratedSetsRequest";
  _source?: Maybe<Scalars["String"]["output"]>;
  /** curated_sets.id */
  ids?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
  slugs?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
};

export type core_apimessages_CuratedSetsResponse = {
  __typename?: "core_apimessages_CuratedSetsResponse";
  _source?: Maybe<Scalars["String"]["output"]>;
  curatedSets?: Maybe<Array<Maybe<core_apimessages_CuratedSet>>>;
};

export type core_apimessages_CustomsInfo = {
  __typename?: "core_apimessages_CustomsInfo";
  _source?: Maybe<Scalars["String"]["output"]>;
  certifiedAccuracy?: Maybe<Scalars["Boolean"]["output"]>;
  orders?: Maybe<Array<Maybe<core_apimessages_OrderCustoms>>>;
  signer?: Maybe<Scalars["String"]["output"]>;
};

export type core_apimessages_DatadogError = {
  __typename?: "core_apimessages_DatadogError";
  _source?: Maybe<Scalars["String"]["output"]>;
  content?: Maybe<Scalars["String"]["output"]>;
  context?: Maybe<Scalars["String"]["output"]>;
  key?: Maybe<Scalars["String"]["output"]>;
  timestamp?: Maybe<Scalars["String"]["output"]>;
  type?: Maybe<Scalars["String"]["output"]>;
};

export type core_apimessages_DeactivateTaxIdentificationRequest = {
  __typename?: "core_apimessages_DeactivateTaxIdentificationRequest";
  _source?: Maybe<Scalars["String"]["output"]>;
  id?: Maybe<Scalars["String"]["output"]>;
};

export type core_apimessages_DeactivateTaxIdentificationResponse = {
  __typename?: "core_apimessages_DeactivateTaxIdentificationResponse";
  _source?: Maybe<Scalars["String"]["output"]>;
  shop?: Maybe<MyShop>;
  shopId?: Maybe<Scalars["String"]["output"]>;
  taxIdentification?: Maybe<core_apimessages_TaxIdentification>;
};

export type core_apimessages_DeclineFeedbackRequest = {
  __typename?: "core_apimessages_DeclineFeedbackRequest";
  _source?: Maybe<Scalars["String"]["output"]>;
  orderUuid?: Maybe<Scalars["String"]["output"]>;
};

export type core_apimessages_DeclineFeedbackResponse = {
  __typename?: "core_apimessages_DeclineFeedbackResponse";
  _source?: Maybe<Scalars["String"]["output"]>;
  orderUuid?: Maybe<Scalars["String"]["output"]>;
};

export type core_apimessages_DeleteAdRequest = {
  __typename?: "core_apimessages_DeleteAdRequest";
  _source?: Maybe<Scalars["String"]["output"]>;
  uuid?: Maybe<Scalars["String"]["output"]>;
};

export type core_apimessages_DeleteAdResponse = {
  __typename?: "core_apimessages_DeleteAdResponse";
  _source?: Maybe<Scalars["String"]["output"]>;
  ad?: Maybe<core_apimessages_Ad>;
};

export type core_apimessages_DeleteAutoOfferRequest = {
  __typename?: "core_apimessages_DeleteAutoOfferRequest";
  _source?: Maybe<Scalars["String"]["output"]>;
  listingId?: Maybe<Scalars["String"]["output"]>;
};

export type core_apimessages_DeleteAutoOfferResponse = {
  __typename?: "core_apimessages_DeleteAutoOfferResponse";
  _source?: Maybe<Scalars["String"]["output"]>;
  autoOffer?: Maybe<core_apimessages_AutoOffer>;
};

export type core_apimessages_DeleteCartItemProtectionPlanRequest = {
  __typename?: "core_apimessages_DeleteCartItemProtectionPlanRequest";
  _source?: Maybe<Scalars["String"]["output"]>;
  cartItemUuid?: Maybe<Scalars["String"]["output"]>;
};

export type core_apimessages_DeleteCartItemProtectionPlanResponse = {
  __typename?: "core_apimessages_DeleteCartItemProtectionPlanResponse";
  _source?: Maybe<Scalars["String"]["output"]>;
  cartItemUuid?: Maybe<Scalars["String"]["output"]>;
};

export type core_apimessages_DeleteGearCollectionItemRequest = {
  __typename?: "core_apimessages_DeleteGearCollectionItemRequest";
  _source?: Maybe<Scalars["String"]["output"]>;
  id?: Maybe<Scalars["String"]["output"]>;
};

export type core_apimessages_DeleteGearCollectionItemResponse = {
  __typename?: "core_apimessages_DeleteGearCollectionItemResponse";
  _source?: Maybe<Scalars["String"]["output"]>;
  gearCollectionItem?: Maybe<core_apimessages_GearCollectionItem>;
};

export type core_apimessages_DeleteMyCreditCardRequest = {
  __typename?: "core_apimessages_DeleteMyCreditCardRequest";
  _source?: Maybe<Scalars["String"]["output"]>;
  id?: Maybe<Scalars["String"]["output"]>;
};

export type core_apimessages_DeleteMyCreditCardResponse = {
  __typename?: "core_apimessages_DeleteMyCreditCardResponse";
  _source?: Maybe<Scalars["String"]["output"]>;
  /** Deleted credit card ID */
  id?: Maybe<Scalars["String"]["output"]>;
};

export type core_apimessages_DeleteMyFavoriteRequest = {
  __typename?: "core_apimessages_DeleteMyFavoriteRequest";
  _source?: Maybe<Scalars["String"]["output"]>;
  id?: Maybe<Scalars["String"]["output"]>;
};

export type core_apimessages_DeleteMyFavoriteResponse = {
  __typename?: "core_apimessages_DeleteMyFavoriteResponse";
  _source?: Maybe<Scalars["String"]["output"]>;
  errors?: Maybe<Array<Maybe<core_apimessages_Error>>>;
  favorite?: Maybe<core_apimessages_Favorite>;
};

export type core_apimessages_DeleteMyFeedbackMessageRequest = {
  __typename?: "core_apimessages_DeleteMyFeedbackMessageRequest";
  _source?: Maybe<Scalars["String"]["output"]>;
  id?: Maybe<Scalars["String"]["output"]>;
};

export type core_apimessages_DeleteMyFeedbackMessageResponse = {
  __typename?: "core_apimessages_DeleteMyFeedbackMessageResponse";
  _source?: Maybe<Scalars["String"]["output"]>;
  id?: Maybe<Scalars["String"]["output"]>;
};

export type core_apimessages_DeleteMySaleMembershipRequest = {
  __typename?: "core_apimessages_DeleteMySaleMembershipRequest";
  _source?: Maybe<Scalars["String"]["output"]>;
  listingId?: Maybe<Scalars["String"]["output"]>;
  saleId?: Maybe<Scalars["String"]["output"]>;
};

export type core_apimessages_DeleteMySaleMembershipResponse = {
  __typename?: "core_apimessages_DeleteMySaleMembershipResponse";
  _source?: Maybe<Scalars["String"]["output"]>;
  saleMembership?: Maybe<core_apimessages_ListingSaleMembership>;
};

export type core_apimessages_DeleteOrderNotesRequest = {
  __typename?: "core_apimessages_DeleteOrderNotesRequest";
  _source?: Maybe<Scalars["String"]["output"]>;
  orderNoteIds?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
};

export type core_apimessages_DeleteRequest = {
  __typename?: "core_apimessages_DeleteRequest";
  _source?: Maybe<Scalars["String"]["output"]>;
  /** deprecated, use uuid */
  id?: Maybe<Scalars["String"]["output"]>;
  uuid?: Maybe<Scalars["String"]["output"]>;
};

export type core_apimessages_DeleteResponse = {
  __typename?: "core_apimessages_DeleteResponse";
  _source?: Maybe<Scalars["String"]["output"]>;
  /** deprecated, use uuid */
  id?: Maybe<Scalars["String"]["output"]>;
  uuid?: Maybe<Scalars["String"]["output"]>;
};

export type core_apimessages_DeleteUserShopPermissionRequest = {
  __typename?: "core_apimessages_DeleteUserShopPermissionRequest";
  _source?: Maybe<Scalars["String"]["output"]>;
  userId?: Maybe<Scalars["String"]["output"]>;
};

export type core_apimessages_DeleteUserShopPermissionResponse = {
  __typename?: "core_apimessages_DeleteUserShopPermissionResponse";
  _source?: Maybe<Scalars["String"]["output"]>;
  id?: Maybe<Scalars["String"]["output"]>;
};

export type core_apimessages_DelinkUserServiceRequest = {
  __typename?: "core_apimessages_DelinkUserServiceRequest";
  _source?: Maybe<Scalars["String"]["output"]>;
  provider?: Maybe<core_apimessages_UserService_Provider>;
};

export type core_apimessages_DelinkUserServiceResponse = {
  __typename?: "core_apimessages_DelinkUserServiceResponse";
  _?: Maybe<Scalars["Boolean"]["output"]>;
  _source?: Maybe<Scalars["String"]["output"]>;
};

export type core_apimessages_DigitalListingSendDownloadEmailRequest = {
  __typename?: "core_apimessages_DigitalListingSendDownloadEmailRequest";
  _source?: Maybe<Scalars["String"]["output"]>;
  listingId?: Maybe<Scalars["String"]["output"]>;
  userId?: Maybe<Scalars["String"]["output"]>;
};

export type core_apimessages_DigitalListingSendDownloadEmailResponse = {
  __typename?: "core_apimessages_DigitalListingSendDownloadEmailResponse";
  _source?: Maybe<Scalars["String"]["output"]>;
  success?: Maybe<Scalars["Boolean"]["output"]>;
};

export type core_apimessages_DraftListingDeleteRequest = {
  __typename?: "core_apimessages_DraftListingDeleteRequest";
  _source?: Maybe<Scalars["String"]["output"]>;
  listingId?: Maybe<Scalars["String"]["output"]>;
};

export type core_apimessages_DraftListingDeleteResponse = {
  __typename?: "core_apimessages_DraftListingDeleteResponse";
  _source?: Maybe<Scalars["String"]["output"]>;
  listingId?: Maybe<Scalars["String"]["output"]>;
};

export type core_apimessages_Error = {
  __typename?: "core_apimessages_Error";
  _source?: Maybe<Scalars["String"]["output"]>;
  context?: Maybe<Scalars["String"]["output"]>;
  errorType?: Maybe<Scalars["String"]["output"]>;
  field?: Maybe<Scalars["String"]["output"]>;
  message?: Maybe<Scalars["String"]["output"]>;
};

export type core_apimessages_ErrorResponse = {
  __typename?: "core_apimessages_ErrorResponse";
  _source?: Maybe<Scalars["String"]["output"]>;
  errors?: Maybe<Array<Maybe<core_apimessages_Error>>>;
};

export type core_apimessages_EstimateNegotiationSalesTaxRequest = {
  __typename?: "core_apimessages_EstimateNegotiationSalesTaxRequest";
  _source?: Maybe<Scalars["String"]["output"]>;
  amountProduct?: Maybe<core_apimessages_MoneyMinorUnitsEntry>;
  amountShipping?: Maybe<core_apimessages_MoneyMinorUnitsEntry>;
  shippingAddressUuid?: Maybe<Scalars["String"]["output"]>;
};

export type core_apimessages_EstimateNegotiationSalesTaxResponse = {
  __typename?: "core_apimessages_EstimateNegotiationSalesTaxResponse";
  _source?: Maybe<Scalars["String"]["output"]>;
  amountTax?: Maybe<core_apimessages_Money>;
};

export type core_apimessages_EstimateShippingResponse = {
  __typename?: "core_apimessages_EstimateShippingResponse";
  _source?: Maybe<Scalars["String"]["output"]>;
  estimatedShipping?: Maybe<core_apimessages_EstimatedShipping>;
};

export type core_apimessages_EstimatedMonthlyPayment = {
  __typename?: "core_apimessages_EstimatedMonthlyPayment";
  _source?: Maybe<Scalars["String"]["output"]>;
  availableTermMonths?: Maybe<Array<Maybe<Scalars["Int"]["output"]>>>;
  estimatedMonthlyPayment?: Maybe<core_apimessages_Money>;
  financingProgramSlug?: Maybe<Scalars["String"]["output"]>;
  /** products.id */
  id?: Maybe<Scalars["String"]["output"]>;
  lowestAprAtMaxTerm?: Maybe<Scalars["Int"]["output"]>;
  maxTermMonth?: Maybe<Scalars["Int"]["output"]>;
  splitPay?: Maybe<Scalars["Boolean"]["output"]>;
  zeroPercentFinancingPlan?: Maybe<Scalars["Boolean"]["output"]>;
};

export type core_apimessages_EstimatedMonthlyPaymentsRequest = {
  __typename?: "core_apimessages_EstimatedMonthlyPaymentsRequest";
  _source?: Maybe<Scalars["String"]["output"]>;
  /** products.id */
  ids?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
};

export type core_apimessages_EstimatedMonthlyPaymentsResponse = {
  __typename?: "core_apimessages_EstimatedMonthlyPaymentsResponse";
  _source?: Maybe<Scalars["String"]["output"]>;
  estimatedMonthlyPayments?: Maybe<
    Array<Maybe<core_apimessages_EstimatedMonthlyPayment>>
  >;
};

export type core_apimessages_EstimatedNewListingPrice = {
  __typename?: "core_apimessages_EstimatedNewListingPrice";
  _source?: Maybe<Scalars["String"]["output"]>;
  id?: Maybe<Scalars["String"]["output"]>;
  price?: Maybe<core_apimessages_Money>;
};

export type core_apimessages_EstimatedShipping = {
  __typename?: "core_apimessages_EstimatedShipping";
  _source?: Maybe<Scalars["String"]["output"]>;
  description?: Maybe<Scalars["String"]["output"]>;
  rates?: Maybe<Array<Maybe<core_apimessages_ShippingRate>>>;
};

export type core_apimessages_EstimatedShippingRequest = {
  __typename?: "core_apimessages_EstimatedShippingRequest";
  _source?: Maybe<Scalars["String"]["output"]>;
  fromCountrycode?: Maybe<Scalars["String"]["output"]>;
  fromPostal?: Maybe<Scalars["String"]["output"]>;
  height?: Maybe<Scalars["Float"]["output"]>;
  length?: Maybe<Scalars["Float"]["output"]>;
  metric?: Maybe<Scalars["Boolean"]["output"]>;
  toCountrycode?: Maybe<Scalars["String"]["output"]>;
  weight?: Maybe<Scalars["Float"]["output"]>;
  width?: Maybe<Scalars["Float"]["output"]>;
};

export type core_apimessages_ExpeditedShippingAvailableSignal = {
  __typename?: "core_apimessages_ExpeditedShippingAvailableSignal";
  _source?: Maybe<Scalars["String"]["output"]>;
  isValid?: Maybe<Scalars["Boolean"]["output"]>;
  translations?: Maybe<core_apimessages_SignalTranslation>;
};

export type core_apimessages_ExperimentData = {
  __typename?: "core_apimessages_ExperimentData";
  _source?: Maybe<Scalars["String"]["output"]>;
  group?: Maybe<Scalars["String"]["output"]>;
  name?: Maybe<Scalars["String"]["output"]>;
};

export type core_apimessages_ExperimentsResponse = {
  __typename?: "core_apimessages_ExperimentsResponse";
  _source?: Maybe<Scalars["String"]["output"]>;
  experiments?: Maybe<Array<Maybe<reverb_event_Experiment>>>;
};

export type core_apimessages_ExportSecondaryUserActivitiesRequest = {
  __typename?: "core_apimessages_ExportSecondaryUserActivitiesRequest";
  _?: Maybe<Scalars["Boolean"]["output"]>;
  _source?: Maybe<Scalars["String"]["output"]>;
};

export type core_apimessages_ExportSecondaryUserActivitiesResponse = {
  __typename?: "core_apimessages_ExportSecondaryUserActivitiesResponse";
  _source?: Maybe<Scalars["String"]["output"]>;
  enqueued?: Maybe<Scalars["Boolean"]["output"]>;
};

export type core_apimessages_ExpressSaleCheckout = {
  __typename?: "core_apimessages_ExpressSaleCheckout";
  _source?: Maybe<Scalars["String"]["output"]>;
  checkoutId?: Maybe<Scalars["String"]["output"]>;
  cspId?: Maybe<Scalars["String"]["output"]>;
  expressSaleItemBid?: Maybe<core_apimessages_CSPExpressSaleItemBid>;
  status?: Maybe<core_apimessages_ExpressSaleCheckout_Status>;
};

export type core_apimessages_ExpressSaleCheckoutShowRequest = {
  __typename?: "core_apimessages_ExpressSaleCheckoutShowRequest";
  _source?: Maybe<Scalars["String"]["output"]>;
  checkoutId?: Maybe<Scalars["String"]["output"]>;
};

export type core_apimessages_ExpressSaleCheckoutShowResponse = {
  __typename?: "core_apimessages_ExpressSaleCheckoutShowResponse";
  _source?: Maybe<Scalars["String"]["output"]>;
  expressSaleCheckout?: Maybe<core_apimessages_ExpressSaleCheckout>;
};

export enum core_apimessages_ExpressSaleCheckout_Status {
  AWAITING_PAYMENT = "AWAITING_PAYMENT",
  COMPLETED = "COMPLETED",
  INACTIVE_BID = "INACTIVE_BID",
  PAYMENT_IN_PROGRESS = "PAYMENT_IN_PROGRESS",
}

/**
 *   Favorite represents a row in the follows table (a link between a user and a saved search), and is a newer version of the Follow message.
 * It can also represent a row from the user_watch_products table (e.g. { id: 1, type: LISTING, favorited: true })
 */
export type core_apimessages_Favorite = {
  __typename?: "core_apimessages_Favorite";
  _source?: Maybe<Scalars["String"]["output"]>;
  emailEnabled?: Maybe<Scalars["Boolean"]["output"]>;
  favoriteType?: Maybe<core_apimessages_FavoriteType>;
  favorited?: Maybe<Scalars["Boolean"]["output"]>;
  feedEnabled?: Maybe<Scalars["Boolean"]["output"]>;
  id?: Maybe<Scalars["String"]["output"]>;
  images?: Maybe<Array<Maybe<core_apimessages_Image>>>;
  link?: Maybe<core_apimessages_Link>;
  listings?: Maybe<Array<Maybe<Listing>>>;
  queryParams?: Maybe<Scalars["String"]["output"]>;
  queryText?: Maybe<Scalars["String"]["output"]>;
  searchableId?: Maybe<Scalars["String"]["output"]>;
  searchableType?: Maybe<reverb_feed_SearchableType>;
  shortSubtitle?: Maybe<Scalars["String"]["output"]>;
  subtitle?: Maybe<Scalars["String"]["output"]>;
  title?: Maybe<Scalars["String"]["output"]>;
};

/**
 *   Favorite represents a row in the follows table (a link between a user and a saved search), and is a newer version of the Follow message.
 * It can also represent a row from the user_watch_products table (e.g. { id: 1, type: LISTING, favorited: true })
 */
export type core_apimessages_FavoriteimagesArgs = {
  input: Input_core_apimessages_ImagesRequest;
};

/**
 *   Favorite represents a row in the follows table (a link between a user and a saved search), and is a newer version of the Follow message.
 * It can also represent a row from the user_watch_products table (e.g. { id: 1, type: LISTING, favorited: true })
 */
export type core_apimessages_FavoritelistingsArgs = {
  input: Input_reverb_search_ListingsSearchRequest;
};

export enum core_apimessages_FavoriteType {
  FAVORITE_SHOP = "FAVORITE_SHOP",
  LISTING = "LISTING",
  SAVED_SEARCH = "SAVED_SEARCH",
}

export enum core_apimessages_FeePaymentMethod {
  PAYOUT_DEDUCTION = "PAYOUT_DEDUCTION",
  STATEMENT_BILLING = "STATEMENT_BILLING",
}

export type core_apimessages_FeedCustomizationResponse = {
  __typename?: "core_apimessages_FeedCustomizationResponse";
  _source?: Maybe<Scalars["String"]["output"]>;
  articleFollows?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
  followGroups?: Maybe<Array<Maybe<core_apimessages_FollowGroup>>>;
  follows?: Maybe<Array<Maybe<core_apimessages_Follow>>>;
};

/**
 *   A FeedResult represents one entity that can be displayed in a feed grid/row/etc for the current user.
 * Listings are the only entity to be supported initially, and other types can be added if needed.
 */
export type core_apimessages_FeedResult = {
  __typename?: "core_apimessages_FeedResult";
  _source?: Maybe<Scalars["String"]["output"]>;
  entityId?: Maybe<Scalars["String"]["output"]>;
  entityType?: Maybe<core_apimessages_FeedResult_EntityType>;
  /**
   *   Source indicates whether the entity was returned because it directly matches
   * a saved search, or because it was a recommendation from a direct match, or another reason.
   */
  source?: Maybe<core_apimessages_FeedResult_Source>;
};

export enum core_apimessages_FeedResult_EntityType {
  LISTING = "LISTING",
}

export enum core_apimessages_FeedResult_Source {
  RECOMMENDATION = "RECOMMENDATION",
  SAVED_SEARCH = "SAVED_SEARCH",
}

export type core_apimessages_Feedback = {
  __typename?: "core_apimessages_Feedback";
  _source?: Maybe<Scalars["String"]["output"]>;
  author?: Maybe<User>;
  authorUuid?: Maybe<Scalars["String"]["output"]>;
  conditionSatisfactory?: Maybe<google_protobuf_BoolValue>;
  createdAt?: Maybe<google_protobuf_Timestamp>;
  listingLink?: Maybe<core_apimessages_Link>;
  message?: Maybe<Scalars["String"]["output"]>;
  orderTitle?: Maybe<Scalars["String"]["output"]>;
  rating?: Maybe<Scalars["Int"]["output"]>;
  recipient?: Maybe<User>;
  recipientUuid?: Maybe<Scalars["String"]["output"]>;
  response?: Maybe<core_apimessages_Feedback_Response>;
  shippingSatisfactory?: Maybe<google_protobuf_BoolValue>;
  type?: Maybe<core_apimessages_Feedback_Type>;
};

export type core_apimessages_FeedbackMessage = {
  __typename?: "core_apimessages_FeedbackMessage";
  _source?: Maybe<Scalars["String"]["output"]>;
  id?: Maybe<Scalars["String"]["output"]>;
  message?: Maybe<Scalars["String"]["output"]>;
};

export type core_apimessages_FeedbackSummariesRequest = {
  __typename?: "core_apimessages_FeedbackSummariesRequest";
  _source?: Maybe<Scalars["String"]["output"]>;
  ids?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
  userIds?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
  uuids?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
};

export type core_apimessages_FeedbackSummariesResponse = {
  __typename?: "core_apimessages_FeedbackSummariesResponse";
  _source?: Maybe<Scalars["String"]["output"]>;
  feedbackSummaries?: Maybe<Array<Maybe<core_apimessages_FeedbackSummary>>>;
};

export type core_apimessages_FeedbackSummary = {
  __typename?: "core_apimessages_FeedbackSummary";
  _source?: Maybe<Scalars["String"]["output"]>;
  receivedCount?: Maybe<Scalars["Int"]["output"]>;
  rollingRatingPercentage?: Maybe<Scalars["Int"]["output"]>;
  /** users.id */
  userId?: Maybe<Scalars["String"]["output"]>;
};

export type core_apimessages_Feedback_Response = {
  __typename?: "core_apimessages_Feedback_Response";
  _source?: Maybe<Scalars["String"]["output"]>;
  body?: Maybe<Scalars["String"]["output"]>;
  createdAt?: Maybe<google_protobuf_Timestamp>;
};

export enum core_apimessages_Feedback_Type {
  BUYER = "BUYER",
  RECEIVED = "RECEIVED",
  SELLER = "SELLER",
}

export type core_apimessages_FeedbacksRequest = {
  __typename?: "core_apimessages_FeedbacksRequest";
  _source?: Maybe<Scalars["String"]["output"]>;
  limit?: Maybe<Scalars["Int"]["output"]>;
  offset?: Maybe<Scalars["Int"]["output"]>;
  type?: Maybe<core_apimessages_Feedback_Type>;
  userUuid?: Maybe<Scalars["String"]["output"]>;
};

export type core_apimessages_FeedbacksResponse = {
  __typename?: "core_apimessages_FeedbacksResponse";
  _source?: Maybe<Scalars["String"]["output"]>;
  buyerRating?: Maybe<Scalars["Float"]["output"]>;
  feedbacks?: Maybe<Array<Maybe<core_apimessages_Feedback>>>;
  limit?: Maybe<Scalars["Int"]["output"]>;
  offset?: Maybe<Scalars["Int"]["output"]>;
  sellerRating?: Maybe<Scalars["Float"]["output"]>;
  total?: Maybe<Scalars["Int"]["output"]>;
};

export type core_apimessages_FinalizeAffirmCheckoutPaymentRequest = {
  __typename?: "core_apimessages_FinalizeAffirmCheckoutPaymentRequest";
  _source?: Maybe<Scalars["String"]["output"]>;
  /** Affirm Checkout Token */
  checkoutToken?: Maybe<Scalars["String"]["output"]>;
  displayedAmountOwed?: Maybe<core_apimessages_MoneyMinorUnitsEntry>;
  /** Checkout UUID */
  id?: Maybe<Scalars["String"]["output"]>;
  /** Native app URL param */
  reverbApp?: Maybe<Scalars["String"]["output"]>;
};

export type core_apimessages_FinalizeAffirmCheckoutPaymentResponse = {
  __typename?: "core_apimessages_FinalizeAffirmCheckoutPaymentResponse";
  _source?: Maybe<Scalars["String"]["output"]>;
  checkout?: Maybe<Checkout>;
  /** Checkout UUID */
  id?: Maybe<Scalars["String"]["output"]>;
};

export type core_apimessages_FinalizeCardCheckoutPaymentRequest = {
  __typename?: "core_apimessages_FinalizeCardCheckoutPaymentRequest";
  _source?: Maybe<Scalars["String"]["output"]>;
  accertifyDeviceInfo?: Maybe<core_apimessages_AccertifyDeviceInfo>;
  /** Serialized JSON object */
  browserInfoJson?: Maybe<Scalars["String"]["output"]>;
  /** Required for guest checkout or when vaulting a new credit card */
  creditCard?: Maybe<core_apimessages_FinalizeCardCheckoutPaymentRequest_CreditCardEntry>;
  /** Required when selecting an existing vaulted card */
  creditCardId?: Maybe<Scalars["String"]["output"]>;
  displayedAmountOwed?: Maybe<core_apimessages_MoneyMinorUnitsEntry>;
  /** Checkout UUID */
  id?: Maybe<Scalars["String"]["output"]>;
};

export type core_apimessages_FinalizeCardCheckoutPaymentRequest_CreditCardEntry =
  {
    __typename?: "core_apimessages_FinalizeCardCheckoutPaymentRequest_CreditCardEntry";
    _source?: Maybe<Scalars["String"]["output"]>;
    address?: Maybe<core_apimessages_AddressEntry>;
    /** amex, visa, etc. */
    brandType?: Maybe<Scalars["String"]["output"]>;
    cardBin?: Maybe<Scalars["String"]["output"]>;
    cardholderName?: Maybe<Scalars["String"]["output"]>;
    /** Last 4 digits of the credit card number */
    lastDigits?: Maybe<Scalars["String"]["output"]>;
    tokenizedFields?: Maybe<core_apimessages_CreditCardTokenizedFields>;
  };

export type core_apimessages_FinalizeCardCheckoutPaymentResponse = {
  __typename?: "core_apimessages_FinalizeCardCheckoutPaymentResponse";
  _source?: Maybe<Scalars["String"]["output"]>;
  checkout?: Maybe<Checkout>;
  /** checkout UUID */
  id?: Maybe<Scalars["String"]["output"]>;
};

export type core_apimessages_FinalizePaypalCheckoutPaymentRequest = {
  __typename?: "core_apimessages_FinalizePaypalCheckoutPaymentRequest";
  _source?: Maybe<Scalars["String"]["output"]>;
  accertifyDeviceInfo?: Maybe<core_apimessages_AccertifyDeviceInfo>;
  displayedAmountOwed?: Maybe<core_apimessages_MoneyMinorUnitsEntry>;
  /** Checkout UUID */
  id?: Maybe<Scalars["String"]["output"]>;
  resultConfirmation?: Maybe<core_apimessages_FinalizePaypalCheckoutPaymentRequest_ResultConfirmation>;
  /** Native app URL param */
  reverbApp?: Maybe<Scalars["String"]["output"]>;
};

export enum core_apimessages_FinalizePaypalCheckoutPaymentRequest_ResultConfirmation {
  /** Default for all web browser-based flows. Confirmation will be available via the Checkout endpoint. */
  ASYNC = "ASYNC",
  /** Intended for native apps only. Confirmation will be available synchronously in the response body. */
  SYNC = "SYNC",
}

export type core_apimessages_FinalizePaypalCheckoutPaymentResponse = {
  __typename?: "core_apimessages_FinalizePaypalCheckoutPaymentResponse";
  _source?: Maybe<Scalars["String"]["output"]>;
  checkout?: Maybe<Checkout>;
  /** Checkout UUID */
  id?: Maybe<Scalars["String"]["output"]>;
};

export type core_apimessages_FinalizeRedirectAuthCheckoutPaymentRequest = {
  __typename?: "core_apimessages_FinalizeRedirectAuthCheckoutPaymentRequest";
  _source?: Maybe<Scalars["String"]["output"]>;
  /** Checkout UUID */
  id?: Maybe<Scalars["String"]["output"]>;
  /** Required for native clients using Adyen Checkout v66 and below */
  legacyResult?: Maybe<core_apimessages_FinalizeRedirectAuthCheckoutPaymentRequest_LegacyResult>;
  /** Payment processor result */
  redirectResult?: Maybe<Scalars["String"]["output"]>;
};

export type core_apimessages_FinalizeRedirectAuthCheckoutPaymentRequest_LegacyResult =
  {
    __typename?: "core_apimessages_FinalizeRedirectAuthCheckoutPaymentRequest_LegacyResult";
    _source?: Maybe<Scalars["String"]["output"]>;
    md?: Maybe<Scalars["String"]["output"]>;
    paRes?: Maybe<Scalars["String"]["output"]>;
  };

export type core_apimessages_FinalizeRedirectAuthCheckoutPaymentResponse = {
  __typename?: "core_apimessages_FinalizeRedirectAuthCheckoutPaymentResponse";
  _source?: Maybe<Scalars["String"]["output"]>;
  checkout?: Maybe<Checkout>;
  /** Checkout UUID */
  id?: Maybe<Scalars["String"]["output"]>;
};

export type core_apimessages_FindAddressAutocompleteSuggestionsRequest = {
  __typename?: "core_apimessages_FindAddressAutocompleteSuggestionsRequest";
  _source?: Maybe<Scalars["String"]["output"]>;
  /** loqate container id */
  container?: Maybe<Scalars["String"]["output"]>;
  text?: Maybe<Scalars["String"]["output"]>;
};

export type core_apimessages_FindAddressAutocompleteSuggestionsResponse = {
  __typename?: "core_apimessages_FindAddressAutocompleteSuggestionsResponse";
  _source?: Maybe<Scalars["String"]["output"]>;
  suggestions?: Maybe<
    Array<
      Maybe<core_apimessages_FindAddressAutocompleteSuggestionsResponse_AutocompleteSuggestion>
    >
  >;
};

export type core_apimessages_FindAddressAutocompleteSuggestionsResponse_AutocompleteSuggestion =
  {
    __typename?: "core_apimessages_FindAddressAutocompleteSuggestionsResponse_AutocompleteSuggestion";
    _source?: Maybe<Scalars["String"]["output"]>;
    /** loqate address id */
    id?: Maybe<Scalars["String"]["output"]>;
    text?: Maybe<Scalars["String"]["output"]>;
    type?: Maybe<core_apimessages_FindAddressAutocompleteSuggestionsResponse_SuggestionType>;
  };

export enum core_apimessages_FindAddressAutocompleteSuggestionsResponse_SuggestionType {
  ADDRESS = "ADDRESS",
  CONTAINER = "CONTAINER",
}

export type core_apimessages_FindFavoriteRequest = {
  __typename?: "core_apimessages_FindFavoriteRequest";
  _source?: Maybe<Scalars["String"]["output"]>;
  listingsSearchRequest?: Maybe<reverb_search_ListingsSearchRequest>;
  shopId?: Maybe<Scalars["String"]["output"]>;
  shopSlug?: Maybe<Scalars["String"]["output"]>;
};

export type core_apimessages_FindFavoriteResponse = {
  __typename?: "core_apimessages_FindFavoriteResponse";
  _source?: Maybe<Scalars["String"]["output"]>;
  canFavorite?: Maybe<Scalars["Boolean"]["output"]>;
  favorite?: Maybe<core_apimessages_Favorite>;
  isFavorite?: Maybe<Scalars["Boolean"]["output"]>;
};

export type core_apimessages_FindFollowResponse = {
  __typename?: "core_apimessages_FindFollowResponse";
  Links?: Maybe<core_apimessages_FollowLinks>;
  _source?: Maybe<Scalars["String"]["output"]>;
  alert?: Maybe<core_apimessages_FollowAlert>;
  display?: Maybe<Scalars["String"]["output"]>;
  follow?: Maybe<core_apimessages_Follow>;
  followedSubcategories?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
  followers?: Maybe<core_apimessages_Followers>;
  following?: Maybe<Scalars["Boolean"]["output"]>;
  header?: Maybe<Scalars["String"]["output"]>;
  hidden?: Maybe<Scalars["Boolean"]["output"]>;
  id?: Maybe<Scalars["String"]["output"]>;
  queryParams?: Maybe<Scalars["String"]["output"]>;
  savedSearchType?: Maybe<Scalars["String"]["output"]>;
  subtitle?: Maybe<Scalars["String"]["output"]>;
  title?: Maybe<Scalars["String"]["output"]>;
};

export type core_apimessages_FlagProductReviewRequest = {
  __typename?: "core_apimessages_FlagProductReviewRequest";
  _source?: Maybe<Scalars["String"]["output"]>;
  productReviewId?: Maybe<Scalars["String"]["output"]>;
  reason?: Maybe<core_apimessages_FlagProductReviewRequest_Reason>;
};

export enum core_apimessages_FlagProductReviewRequest_Reason {
  NOT_RELEVANT = "NOT_RELEVANT",
  OFFENSIVE = "OFFENSIVE",
  SPAM = "SPAM",
}

export type core_apimessages_FlagProductReviewResponse = {
  __typename?: "core_apimessages_FlagProductReviewResponse";
  _source?: Maybe<Scalars["String"]["output"]>;
  /** Created Flag ID */
  id?: Maybe<Scalars["String"]["output"]>;
};

export type core_apimessages_FlatRateShippingOption = {
  __typename?: "core_apimessages_FlatRateShippingOption";
  _source?: Maybe<Scalars["String"]["output"]>;
  carrier?: Maybe<Scalars["String"]["output"]>;
  displayDimensions?: Maybe<Scalars["String"]["output"]>;
  displayName?: Maybe<Scalars["String"]["output"]>;
  displayPosition?: Maybe<Scalars["Int"]["output"]>;
  displayTitle?: Maybe<Scalars["String"]["output"]>;
  maximumPounds?: Maybe<Scalars["Int"]["output"]>;
  optionValue?: Maybe<Scalars["String"]["output"]>;
};

export type core_apimessages_Follow = {
  __typename?: "core_apimessages_Follow";
  Links?: Maybe<core_apimessages_FollowLinks>;
  _source?: Maybe<Scalars["String"]["output"]>;
  alert?: Maybe<core_apimessages_FollowAlert>;
  followedSubcategories?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
  following?: Maybe<Scalars["Boolean"]["output"]>;
  header?: Maybe<Scalars["String"]["output"]>;
  /** follow.id */
  id?: Maybe<Scalars["String"]["output"]>;
  queryParams?: Maybe<Scalars["String"]["output"]>;
  savedSearchType?: Maybe<Scalars["String"]["output"]>;
  subtitle?: Maybe<Scalars["String"]["output"]>;
  title?: Maybe<Scalars["String"]["output"]>;
};

export type core_apimessages_FollowAlert = {
  __typename?: "core_apimessages_FollowAlert";
  _source?: Maybe<Scalars["String"]["output"]>;
  type?: Maybe<Scalars["String"]["output"]>;
};

export type core_apimessages_FollowGroup = {
  __typename?: "core_apimessages_FollowGroup";
  Links?: Maybe<core_apimessages_FollowGroupLinks>;
  _source?: Maybe<Scalars["String"]["output"]>;
  calloutLinkText?: Maybe<Scalars["String"]["output"]>;
  description?: Maybe<Scalars["String"]["output"]>;
  follows?: Maybe<Array<Maybe<core_apimessages_Follow>>>;
  title?: Maybe<Scalars["String"]["output"]>;
};

export type core_apimessages_FollowGroupLinks = {
  __typename?: "core_apimessages_FollowGroupLinks";
  _source?: Maybe<Scalars["String"]["output"]>;
  callout?: Maybe<core_apimessages_Link>;
};

export type core_apimessages_FollowLinks = {
  __typename?: "core_apimessages_FollowLinks";
  _source?: Maybe<Scalars["String"]["output"]>;
  alert?: Maybe<core_apimessages_Link>;
  remove?: Maybe<core_apimessages_Link>;
  search?: Maybe<core_apimessages_Link>;
  self?: Maybe<core_apimessages_Link>;
};

export type core_apimessages_FollowQueryParams = {
  __typename?: "core_apimessages_FollowQueryParams";
  _source?: Maybe<Scalars["String"]["output"]>;
  acceptsGiftCards?: Maybe<Scalars["Boolean"]["output"]>;
  acceptsPaymentPlans?: Maybe<Scalars["Boolean"]["output"]>;
  category?: Maybe<Scalars["String"]["output"]>;
  categoryUuid?: Maybe<Scalars["String"]["output"]>;
  condition?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
  countryOfOrigin?: Maybe<Scalars["String"]["output"]>;
  /** canonical_products.id */
  cpIds?: Maybe<Array<Maybe<Scalars["Int"]["output"]>>>;
  /** curated_searches.id */
  curatedSearchId?: Maybe<Scalars["Int"]["output"]>;
  /** curated_sets.id */
  curatedSetId?: Maybe<Scalars["Int"]["output"]>;
  currency?: Maybe<Scalars["String"]["output"]>;
  decade?: Maybe<Scalars["String"]["output"]>;
  decades?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
  finish?: Maybe<Scalars["String"]["output"]>;
  freeExpeditedShipping?: Maybe<Scalars["Boolean"]["output"]>;
  freeShipping?: Maybe<Scalars["Boolean"]["output"]>;
  handmade?: Maybe<Scalars["String"]["output"]>;
  itemCity?: Maybe<Scalars["String"]["output"]>;
  itemCountry?: Maybe<Scalars["String"]["output"]>;
  itemRegion?: Maybe<Scalars["String"]["output"]>;
  itemState?: Maybe<Scalars["String"]["output"]>;
  listingType?: Maybe<Scalars["String"]["output"]>;
  make?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
  model?: Maybe<Scalars["String"]["output"]>;
  outlet?: Maybe<Scalars["Boolean"]["output"]>;
  personalProfile?: Maybe<Scalars["String"]["output"]>;
  preferredSeller?: Maybe<Scalars["Boolean"]["output"]>;
  priceMax?: Maybe<Scalars["String"]["output"]>;
  priceMin?: Maybe<Scalars["String"]["output"]>;
  productType?: Maybe<Scalars["String"]["output"]>;
  productTypeUuid?: Maybe<Scalars["String"]["output"]>;
  query?: Maybe<Scalars["String"]["output"]>;
  shipsTo?: Maybe<Scalars["String"]["output"]>;
  /** shops.id */
  shopId?: Maybe<Scalars["Int"]["output"]>;
  yearMax?: Maybe<Scalars["String"]["output"]>;
  yearMin?: Maybe<Scalars["String"]["output"]>;
  zeroPercentFinancing?: Maybe<Scalars["Boolean"]["output"]>;
};

export type core_apimessages_Followers = {
  __typename?: "core_apimessages_Followers";
  _source?: Maybe<Scalars["String"]["output"]>;
  count?: Maybe<Scalars["Int"]["output"]>;
  display?: Maybe<Scalars["String"]["output"]>;
};

export type core_apimessages_FormSelectCategoriesRequest = {
  __typename?: "core_apimessages_FormSelectCategoriesRequest";
  _source?: Maybe<Scalars["String"]["output"]>;
  entityId?: Maybe<Scalars["String"]["output"]>;
  entityType?: Maybe<core_apimessages_FormSelectCategoriesRequest_FormSelectEntityType>;
};

export enum core_apimessages_FormSelectCategoriesRequest_FormSelectEntityType {
  GEAR_COLLECTION_ITEM = "GEAR_COLLECTION_ITEM",
  PRODUCT = "PRODUCT",
  UNKNOWN = "UNKNOWN",
}

export type core_apimessages_FormSelectCategoriesResponse = {
  __typename?: "core_apimessages_FormSelectCategoriesResponse";
  _source?: Maybe<Scalars["String"]["output"]>;
  categories?: Maybe<Array<Maybe<core_apimessages_FormSelectCategory>>>;
};

export type core_apimessages_FormSelectCategory = {
  __typename?: "core_apimessages_FormSelectCategory";
  _source?: Maybe<Scalars["String"]["output"]>;
  name?: Maybe<Scalars["String"]["output"]>;
  subcategories?: Maybe<Array<Maybe<core_apimessages_FormSelectCategory>>>;
  uuid?: Maybe<Scalars["String"]["output"]>;
};

export type core_apimessages_FreeExpeditedShippingSignal = {
  __typename?: "core_apimessages_FreeExpeditedShippingSignal";
  _source?: Maybe<Scalars["String"]["output"]>;
  isValid?: Maybe<Scalars["Boolean"]["output"]>;
  translations?: Maybe<core_apimessages_SignalTranslation>;
};

export type core_apimessages_FreeStandardShippingSignal = {
  __typename?: "core_apimessages_FreeStandardShippingSignal";
  _source?: Maybe<Scalars["String"]["output"]>;
  /** @deprecated Field no longer supported */
  freeShippingThresholdRequirementsMet?: Maybe<Scalars["Boolean"]["output"]>;
  isValid?: Maybe<Scalars["Boolean"]["output"]>;
  translations?: Maybe<core_apimessages_SignalTranslation>;
};

export type core_apimessages_GearCollectionItem = {
  __typename?: "core_apimessages_GearCollectionItem";
  _source?: Maybe<Scalars["String"]["output"]>;
  canonicalProductId?: Maybe<Scalars["String"]["output"]>;
  condition?: Maybe<core_apimessages_Condition>;
  created?: Maybe<Scalars["Boolean"]["output"]>;
  deleted?: Maybe<Scalars["Boolean"]["output"]>;
  description?: Maybe<Scalars["String"]["output"]>;
  finish?: Maybe<Scalars["String"]["output"]>;
  hideListingDescription?: Maybe<Scalars["Boolean"]["output"]>;
  hideListingPhotos?: Maybe<Scalars["Boolean"]["output"]>;
  /** gear_collection_item.id */
  id?: Maybe<Scalars["String"]["output"]>;
  make?: Maybe<Scalars["String"]["output"]>;
  model?: Maybe<Scalars["String"]["output"]>;
  notes?: Maybe<Scalars["String"]["output"]>;
  originalListingDescription?: Maybe<Scalars["String"]["output"]>;
  ownerCost?: Maybe<core_apimessages_Money>;
  price?: Maybe<core_apimessages_Money>;
  seedId?: Maybe<Scalars["String"]["output"]>;
  seedType?: Maybe<core_apimessages_GearCollectionItem_SeedType>;
  serialNumber?: Maybe<Scalars["String"]["output"]>;
  state?: Maybe<core_apimessages_GearCollectionItem_State>;
  subcategoryUuids?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
  tags?: Maybe<Array<Maybe<core_apimessages_GearCollectionItemTag>>>;
  title?: Maybe<Scalars["String"]["output"]>;
  updated?: Maybe<Scalars["Boolean"]["output"]>;
  year?: Maybe<Scalars["String"]["output"]>;
};

export type core_apimessages_GearCollectionItemEntry = {
  __typename?: "core_apimessages_GearCollectionItemEntry";
  _source?: Maybe<Scalars["String"]["output"]>;
  canonicalProductId?: Maybe<Scalars["String"]["output"]>;
  categoryRootUuid?: Maybe<Scalars["String"]["output"]>;
  categoryUuids?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
  conditionSlug?: Maybe<Scalars["String"]["output"]>;
  description?: Maybe<Scalars["String"]["output"]>;
  finish?: Maybe<Scalars["String"]["output"]>;
  hideListingDescription?: Maybe<Scalars["Boolean"]["output"]>;
  hideListingPhotos?: Maybe<Scalars["Boolean"]["output"]>;
  /** gear_collection_item.id */
  id?: Maybe<Scalars["String"]["output"]>;
  make?: Maybe<Scalars["String"]["output"]>;
  model?: Maybe<Scalars["String"]["output"]>;
  notes?: Maybe<Scalars["String"]["output"]>;
  ownerCost?: Maybe<core_apimessages_Money>;
  photos?: Maybe<Array<Maybe<core_apimessages_ImageEntry>>>;
  price?: Maybe<core_apimessages_Money>;
  quickCreateItemFromSeed?: Maybe<Scalars["Boolean"]["output"]>;
  seedId?: Maybe<Scalars["String"]["output"]>;
  seedType?: Maybe<core_apimessages_GearCollectionItem_SeedType>;
  serialNumber?: Maybe<Scalars["String"]["output"]>;
  source?: Maybe<core_apimessages_GearCollectionItemEntry_Source>;
  tags?: Maybe<Array<Maybe<core_apimessages_GearCollectionItemTag>>>;
  title?: Maybe<Scalars["String"]["output"]>;
  year?: Maybe<Scalars["String"]["output"]>;
};

export enum core_apimessages_GearCollectionItemEntry_Source {
  ADMIN_CSV_IMPORT = "ADMIN_CSV_IMPORT",
  BLANK_FORM = "BLANK_FORM",
  CREATED_BY_REVERB = "CREATED_BY_REVERB",
  EMAIL = "EMAIL",
  NONE = "NONE",
  PAST_PURCHASE = "PAST_PURCHASE",
  PRICE_GUIDE_TOOL = "PRICE_GUIDE_TOOL",
  SEEDED_FORM = "SEEDED_FORM",
}

export type core_apimessages_GearCollectionItemTag = {
  __typename?: "core_apimessages_GearCollectionItemTag";
  _source?: Maybe<Scalars["String"]["output"]>;
  id?: Maybe<Scalars["String"]["output"]>;
  name?: Maybe<Scalars["String"]["output"]>;
};

export enum core_apimessages_GearCollectionItem_SeedType {
  CanonicalProduct = "CanonicalProduct",
  None = "None",
  Order = "Order",
  Product = "Product",
  Unknown = "Unknown",
}

export enum core_apimessages_GearCollectionItem_State {
  ACTIVE = "ACTIVE",
  ARCHIVED = "ARCHIVED",
}

export type core_apimessages_GearCollectionSettingResponse = {
  __typename?: "core_apimessages_GearCollectionSettingResponse";
  _source?: Maybe<Scalars["String"]["output"]>;
  autoAddOrders?: Maybe<Scalars["Boolean"]["output"]>;
  id?: Maybe<Scalars["String"]["output"]>;
};

export type core_apimessages_GearCollectionSettingsResponse = {
  __typename?: "core_apimessages_GearCollectionSettingsResponse";
  _source?: Maybe<Scalars["String"]["output"]>;
  autoAddOrders?: Maybe<Scalars["Boolean"]["output"]>;
  id?: Maybe<Scalars["String"]["output"]>;
};

export type core_apimessages_GenerateAdminReportRequest = {
  __typename?: "core_apimessages_GenerateAdminReportRequest";
  _source?: Maybe<Scalars["String"]["output"]>;
  endDate?: Maybe<Scalars["String"]["output"]>;
  reportName?: Maybe<Scalars["String"]["output"]>;
  startDate?: Maybe<Scalars["String"]["output"]>;
};

export type core_apimessages_GenerateAdminReportResponse = {
  __typename?: "core_apimessages_GenerateAdminReportResponse";
  _source?: Maybe<Scalars["String"]["output"]>;
  reportEnqueued?: Maybe<Scalars["Boolean"]["output"]>;
};

export type core_apimessages_GetMyFavoriteSavedSearchesRequest = {
  __typename?: "core_apimessages_GetMyFavoriteSavedSearchesRequest";
  _source?: Maybe<Scalars["String"]["output"]>;
  limit?: Maybe<Scalars["Int"]["output"]>;
  offset?: Maybe<Scalars["Int"]["output"]>;
};

export type core_apimessages_GetMyFavoriteSavedSearchesResponse = {
  __typename?: "core_apimessages_GetMyFavoriteSavedSearchesResponse";
  _source?: Maybe<Scalars["String"]["output"]>;
  favorites?: Maybe<Array<Maybe<core_apimessages_Favorite>>>;
  limit?: Maybe<Scalars["Int"]["output"]>;
  offset?: Maybe<Scalars["Int"]["output"]>;
  total?: Maybe<Scalars["Int"]["output"]>;
};

/** copied from common.proto which is also used by data team */
export type core_apimessages_GoogleShoppingClick = {
  __typename?: "core_apimessages_GoogleShoppingClick";
  _source?: Maybe<Scalars["String"]["output"]>;
  comparisonShoppingPageId?: Maybe<Scalars["String"]["output"]>;
  /** This field is different than the one in common.proto */
  expiration?: Maybe<Scalars["String"]["output"]>;
  listingId?: Maybe<Scalars["String"]["output"]>;
  origin?: Maybe<Scalars["String"]["output"]>;
  /** / ISO8601 */
  timestamp?: Maybe<Scalars["String"]["output"]>;
};

export type core_apimessages_GreatValueSignal = {
  __typename?: "core_apimessages_GreatValueSignal";
  _source?: Maybe<Scalars["String"]["output"]>;
  isValid?: Maybe<Scalars["Boolean"]["output"]>;
  translations?: Maybe<core_apimessages_SignalTranslation>;
};

export type core_apimessages_GuestCheckoutUserEntry = {
  __typename?: "core_apimessages_GuestCheckoutUserEntry";
  _source?: Maybe<Scalars["String"]["output"]>;
  consentToAdData?: Maybe<Scalars["Boolean"]["output"]>;
  consentToAdPersonalization?: Maybe<Scalars["Boolean"]["output"]>;
  consentToAdStorage?: Maybe<Scalars["Boolean"]["output"]>;
  consentToAnalyticsStorage?: Maybe<Scalars["Boolean"]["output"]>;
  email?: Maybe<Scalars["String"]["output"]>;
  firstName?: Maybe<Scalars["String"]["output"]>;
  lastName?: Maybe<Scalars["String"]["output"]>;
};

export type core_apimessages_GuestCheckoutVerifyShopperRequest = {
  __typename?: "core_apimessages_GuestCheckoutVerifyShopperRequest";
  _source?: Maybe<Scalars["String"]["output"]>;
  cartItemAttributes?: Maybe<Array<Maybe<core_apimessages_CartItemAttributes>>>;
  checkoutId?: Maybe<Scalars["String"]["output"]>;
  checkoutUuid?: Maybe<Scalars["String"]["output"]>;
  results?: Maybe<core_apimessages_AdyenVerifyShopperResults>;
  totalPrice?: Maybe<core_apimessages_Money>;
};

export type core_apimessages_HomepageAdData = {
  __typename?: "core_apimessages_HomepageAdData";
  _source?: Maybe<Scalars["String"]["output"]>;
  ad?: Maybe<core_apimessages_Ad>;
  adZones?: Maybe<Array<Maybe<core_apimessages_AdZone>>>;
};

export type core_apimessages_HomepageComponent = {
  __typename?: "core_apimessages_HomepageComponent";
  _source?: Maybe<Scalars["String"]["output"]>;
  adComponent?: Maybe<core_apimessages_HomepageAdData>;
  componentIdentifier?: Maybe<core_apimessages_HomepageComponent_ComponentIdentifier>;
  componentType?: Maybe<core_apimessages_HomepageComponent_ComponentType>;
  ctaText?: Maybe<Scalars["String"]["output"]>;
  ctaUrl?: Maybe<Scalars["String"]["output"]>;
  listings?: Maybe<Array<Maybe<core_apimessages_ListingData>>>;
  searches?: Maybe<Array<Maybe<core_apimessages_SearchData>>>;
  slug?: Maybe<Scalars["String"]["output"]>;
  subtitle?: Maybe<Scalars["String"]["output"]>;
  title?: Maybe<Scalars["String"]["output"]>;
};

export enum core_apimessages_HomepageComponent_ComponentIdentifier {
  BANNER_MID_1 = "BANNER_MID_1",
  BANNER_MID_2 = "BANNER_MID_2",
  BANNER_TOP = "BANNER_TOP",
  BASED_ON_RECENTLY_VIEWED_LISTINGS = "BASED_ON_RECENTLY_VIEWED_LISTINGS",
  BEST_SELLERS = "BEST_SELLERS",
  DEALS_STEALS = "DEALS_STEALS",
  EMPTY_WATCHLIST = "EMPTY_WATCHLIST",
  FEED = "FEED",
  JUST_LISTED = "JUST_LISTED",
  MOST_WATCHED_LISTINGS = "MOST_WATCHED_LISTINGS",
  NEW_TO_YOU = "NEW_TO_YOU",
  NONE = "NONE",
  NOTIFICATIONS = "NOTIFICATIONS",
  RECENTLY_VIEWED_LISTINGS = "RECENTLY_VIEWED_LISTINGS",
  RECENTLY_VIEWED_PRODUCTS = "RECENTLY_VIEWED_PRODUCTS",
  RECENT_CATEGORY = "RECENT_CATEGORY",
  RECENT_SEARCH = "RECENT_SEARCH",
  RECOMMENDED_LISTINGS = "RECOMMENDED_LISTINGS",
  REGIONAL_LISTINGS = "REGIONAL_LISTINGS",
  SUB_CATEGORY = "SUB_CATEGORY",
  TRENDING_SEARCHES = "TRENDING_SEARCHES",
  WATCHLIST = "WATCHLIST",
}

export enum core_apimessages_HomepageComponent_ComponentType {
  CMS_BANNER = "CMS_BANNER",
  IMAGE_MOSAIC = "IMAGE_MOSAIC",
  LISTINGS_GRID = "LISTINGS_GRID",
  PLACEHOLDER = "PLACEHOLDER",
  PRODUCTS_GRID = "PRODUCTS_GRID",
  ROUNDED_GRID = "ROUNDED_GRID",
}

export type core_apimessages_HomepageNotification = {
  __typename?: "core_apimessages_HomepageNotification";
  _source?: Maybe<Scalars["String"]["output"]>;
  calloutText?: Maybe<Scalars["String"]["output"]>;
  ctaUrl?: Maybe<Scalars["String"]["output"]>;
  deadlineText?: Maybe<Scalars["String"]["output"]>;
  descriptionText?: Maybe<Scalars["String"]["output"]>;
  dismissed?: Maybe<Scalars["Boolean"]["output"]>;
  followText?: Maybe<Scalars["String"]["output"]>;
  iconColor?: Maybe<Scalars["String"]["output"]>;
  iconUrl?: Maybe<Scalars["String"]["output"]>;
  id?: Maybe<Scalars["String"]["output"]>;
  imageUrl?: Maybe<Scalars["String"]["output"]>;
  type?: Maybe<Scalars["String"]["output"]>;
  typeColor?: Maybe<core_apimessages_NotificationColor>;
  typeText?: Maybe<Scalars["String"]["output"]>;
  updateId?: Maybe<Scalars["String"]["output"]>;
};

export type core_apimessages_HomepageRequest = {
  __typename?: "core_apimessages_HomepageRequest";
  _source?: Maybe<Scalars["String"]["output"]>;
  adUserContext?: Maybe<core_apimessages_AdUserContext>;
  affinitySlugs?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
};

export type core_apimessages_HomepageResponse = {
  __typename?: "core_apimessages_HomepageResponse";
  _source?: Maybe<Scalars["String"]["output"]>;
  components?: Maybe<Array<Maybe<core_apimessages_HomepageComponent>>>;
};

export enum core_apimessages_IconImageType {
  PNG_18_3X = "PNG_18_3X",
  SVG = "SVG",
}

export type core_apimessages_Image = {
  __typename?: "core_apimessages_Image";
  _id?: Maybe<Scalars["ID"]["output"]>;
  _source?: Maybe<Scalars["String"]["output"]>;
  fallbackType?: Maybe<core_apimessages_Image_FallbackType>;
  height?: Maybe<Scalars["Int"]["output"]>;
  /** image postgres id */
  id?: Maybe<Scalars["String"]["output"]>;
  /** parent postgres id (polymorphic with 'item_type') */
  itemId?: Maybe<Scalars["String"]["output"]>;
  itemType?: Maybe<Scalars["String"]["output"]>;
  namedTransform?: Maybe<core_apimessages_NamedImageTransforms>;
  /** attachinary_files.public_id (cloudinary string) */
  publicId?: Maybe<Scalars["String"]["output"]>;
  source?: Maybe<Scalars["String"]["output"]>;
  transformation?: Maybe<core_apimessages_ImageEntry_Transformation>;
  version?: Maybe<Scalars["String"]["output"]>;
  width?: Maybe<Scalars["Int"]["output"]>;
};

export type core_apimessages_ImageAdCreative = {
  __typename?: "core_apimessages_ImageAdCreative";
  _source?: Maybe<Scalars["String"]["output"]>;
  backgroundColor?: Maybe<Scalars["String"]["output"]>;
  backgroundColors?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
  backgroundImage?: Maybe<Scalars["String"]["output"]>;
  cspRowQuery?: Maybe<core_apimessages_CspRowQuery>;
  ctaButtonColor?: Maybe<Scalars["String"]["output"]>;
  ctaHref?: Maybe<Scalars["String"]["output"]>;
  ctaText?: Maybe<Scalars["String"]["output"]>;
  ctaTextColor?: Maybe<Scalars["String"]["output"]>;
  description?: Maybe<Scalars["String"]["output"]>;
  hideAdCallout?: Maybe<Scalars["Boolean"]["output"]>;
  mobileBackgroundImage?: Maybe<Scalars["String"]["output"]>;
  overlayImage?: Maybe<Scalars["String"]["output"]>;
  partnershipText?: Maybe<Scalars["String"]["output"]>;
  subtitle?: Maybe<Scalars["String"]["output"]>;
  subtitleColor?: Maybe<Scalars["String"]["output"]>;
  title?: Maybe<Scalars["String"]["output"]>;
  titleColor?: Maybe<Scalars["String"]["output"]>;
};

export type core_apimessages_ImageEntry = {
  __typename?: "core_apimessages_ImageEntry";
  _source?: Maybe<Scalars["String"]["output"]>;
  format?: Maybe<Scalars["String"]["output"]>;
  height?: Maybe<Scalars["Int"]["output"]>;
  id?: Maybe<Scalars["String"]["output"]>;
  originalFilename?: Maybe<Scalars["String"]["output"]>;
  publicId?: Maybe<Scalars["String"]["output"]>;
  resourceType?: Maybe<Scalars["String"]["output"]>;
  transformation?: Maybe<core_apimessages_ImageEntry_Transformation>;
  version?: Maybe<Scalars["String"]["output"]>;
  width?: Maybe<Scalars["Int"]["output"]>;
};

export type core_apimessages_ImageEntry_Transformation = {
  __typename?: "core_apimessages_ImageEntry_Transformation";
  _source?: Maybe<Scalars["String"]["output"]>;
  angle?: Maybe<Scalars["Int"]["output"]>;
  crop?: Maybe<Scalars["String"]["output"]>;
  height?: Maybe<Scalars["String"]["output"]>;
  width?: Maybe<Scalars["String"]["output"]>;
  x?: Maybe<Scalars["String"]["output"]>;
  y?: Maybe<Scalars["String"]["output"]>;
};

export type core_apimessages_ImageRequest = {
  __typename?: "core_apimessages_ImageRequest";
  _source?: Maybe<Scalars["String"]["output"]>;
  id?: Maybe<Scalars["String"]["output"]>;
  limit?: Maybe<Scalars["Int"]["output"]>;
  namedTransform?: Maybe<core_apimessages_NamedImageTransforms>;
  scope?: Maybe<Scalars["String"]["output"]>;
  slug?: Maybe<Scalars["String"]["output"]>;
  transform?: Maybe<Scalars["String"]["output"]>;
  type?: Maybe<Scalars["String"]["output"]>;
  uuid?: Maybe<Scalars["String"]["output"]>;
};

export enum core_apimessages_Image_FallbackType {
  /** either the release or the album */
  LP_CANONICAL = "LP_CANONICAL",
  NONE = "NONE",
}

export enum core_apimessages_Image_Scope {
  ALL = "ALL",
  APP_STORE_BANNER = "APP_STORE_BANNER",
  APP_STORE_ICON = "APP_STORE_ICON",
  AVATAR = "AVATAR",
  DESKTOP_IMAGE = "DESKTOP_IMAGE",
  FACEBOOK_IMAGE = "FACEBOOK_IMAGE",
  FULL_BLEED_BRAND = "FULL_BLEED_BRAND",
  FULL_BLEED_HOMEPAGE_IMAGE = "FULL_BLEED_HOMEPAGE_IMAGE",
  FULL_BLEED_IMAGE = "FULL_BLEED_IMAGE",
  HEADER_IMAGE = "HEADER_IMAGE",
  HERO_IMAGE = "HERO_IMAGE",
  HOMEPAGE_IMAGE = "HOMEPAGE_IMAGE",
  IMAGE = "IMAGE",
  MOBILE_IMAGE = "MOBILE_IMAGE",
  PHOTOS = "PHOTOS",
  PRODUCT_MANUAL = "PRODUCT_MANUAL",
  SHOP_BANNER = "SHOP_BANNER",
  SHOP_BANNER_960 = "SHOP_BANNER_960",
  SMALL_HEADER_IMAGE = "SMALL_HEADER_IMAGE",
  SQUARE_MOSAIC_IMAGE = "SQUARE_MOSAIC_IMAGE",
  STOREFRONT_BANNER_1500 = "STOREFRONT_BANNER_1500",
  VERTICAL_MOSAIC_IMAGE = "VERTICAL_MOSAIC_IMAGE",
}

export type core_apimessages_ImagesRequest = {
  __typename?: "core_apimessages_ImagesRequest";
  _source?: Maybe<Scalars["String"]["output"]>;
  count?: Maybe<Scalars["Int"]["output"]>;
  /** postgres id for corresponding table denoted by 'type' */
  ids?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
  namedTransform?: Maybe<core_apimessages_NamedImageTransforms>;
  scope?: Maybe<Scalars["String"]["output"]>;
  slugs?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
  transform?: Maybe<Scalars["String"]["output"]>;
  type?: Maybe<Scalars["String"]["output"]>;
  uuids?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
};

export type core_apimessages_ImagesResponse = {
  __typename?: "core_apimessages_ImagesResponse";
  _source?: Maybe<Scalars["String"]["output"]>;
  images?: Maybe<Array<Maybe<core_apimessages_ItemImages>>>;
};

export type core_apimessages_InOtherCartsSignal = {
  __typename?: "core_apimessages_InOtherCartsSignal";
  _source?: Maybe<Scalars["String"]["output"]>;
  isValid?: Maybe<Scalars["Boolean"]["output"]>;
  translations?: Maybe<core_apimessages_SignalTranslation>;
};

export type core_apimessages_InformActThresholdStatus = {
  __typename?: "core_apimessages_InformActThresholdStatus";
  _source?: Maybe<Scalars["String"]["output"]>;
  bankAccountVerifiedDate?: Maybe<Scalars["Timestamp"]["output"]>;
  hasCompletedSellerVerification?: Maybe<Scalars["Boolean"]["output"]>;
  hasValidBankAccount?: Maybe<Scalars["Boolean"]["output"]>;
  hasValidTaxId?: Maybe<Scalars["Boolean"]["output"]>;
  id?: Maybe<Scalars["String"]["output"]>;
  isCompliant?: Maybe<Scalars["Boolean"]["output"]>;
  isPaypalOnly?: Maybe<Scalars["Boolean"]["output"]>;
  thresholdCrossedAt?: Maybe<Scalars["Timestamp"]["output"]>;
};

export type core_apimessages_InternationalTaxProfile = {
  __typename?: "core_apimessages_InternationalTaxProfile";
  _source?: Maybe<Scalars["String"]["output"]>;
  address?: Maybe<core_apimessages_Address>;
  dateOfBirth?: Maybe<Scalars["String"]["output"]>;
  id?: Maybe<Scalars["String"]["output"]>;
  name?: Maybe<Scalars["String"]["output"]>;
  tinMask?: Maybe<Scalars["String"]["output"]>;
  tinType?: Maybe<Scalars["String"]["output"]>;
  updatedAt?: Maybe<Scalars["Timestamp"]["output"]>;
};

export type core_apimessages_ItemImages = {
  __typename?: "core_apimessages_ItemImages";
  _source?: Maybe<Scalars["String"]["output"]>;
  /** either a uuid or id, depending on what's present in ImagesRequest */
  id?: Maybe<Scalars["String"]["output"]>;
  images?: Maybe<Array<Maybe<core_apimessages_Image>>>;
};

export type core_apimessages_ItemSearchFilterPresets = {
  __typename?: "core_apimessages_ItemSearchFilterPresets";
  _source?: Maybe<Scalars["String"]["output"]>;
  /** postgres id (polymorphic with 'collection_type') */
  collectionId?: Maybe<Scalars["String"]["output"]>;
  collectionSlug?: Maybe<Scalars["String"]["output"]>;
  collectionType?: Maybe<Scalars["String"]["output"]>;
  presets?: Maybe<Array<Maybe<core_apimessages_SearchFilterPreset>>>;
};

export type core_apimessages_LayawayTerms = {
  __typename?: "core_apimessages_LayawayTerms";
  _source?: Maybe<Scalars["String"]["output"]>;
  name?: Maybe<Scalars["String"]["output"]>;
  percentDown?: Maybe<Scalars["Int"]["output"]>;
  timePeriod?: Maybe<google_protobuf_Duration>;
};

export type core_apimessages_Link = {
  __typename?: "core_apimessages_Link";
  _source?: Maybe<Scalars["String"]["output"]>;
  href?: Maybe<Scalars["String"]["output"]>;
  /** rel path for use in web routing */
  path?: Maybe<Scalars["String"]["output"]>;
};

export type core_apimessages_Listing = {
  __typename?: "core_apimessages_Listing";
  _source?: Maybe<Scalars["String"]["output"]>;
  /** Total quantity that a buyer can set from a listing to a cart item. This value will be zero if the listing is not inventoried, or if an inventoried listing is out of stock. */
  allowedBuyerQuantity?: Maybe<Scalars["Int"]["output"]>;
  brandSlug?: Maybe<Scalars["String"]["output"]>;
  bumpEligible?: Maybe<Scalars["Boolean"]["output"]>;
  bumped?: Maybe<Scalars["Boolean"]["output"]>;
  /** canonical_products.id */
  canonicalProductId?: Maybe<Scalars["String"]["output"]>;
  categoryRootUuid?: Maybe<Scalars["String"]["output"]>;
  /** includes product_type.uuids */
  categoryUuids?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
  combinedShippingParent?: Maybe<Scalars["Boolean"]["output"]>;
  condition?: Maybe<core_apimessages_Condition>;
  countryOfOrigin?: Maybe<Scalars["String"]["output"]>;
  createdAt?: Maybe<google_protobuf_Timestamp>;
  cspId?: Maybe<Scalars["String"]["output"]>;
  cspUuid?: Maybe<Scalars["String"]["output"]>;
  currency?: Maybe<Scalars["String"]["output"]>;
  /** auctions.uuid */
  currentAuctionId?: Maybe<Scalars["String"]["output"]>;
  description?: Maybe<Scalars["String"]["output"]>;
  exclusiveChannel?: Maybe<Scalars["String"]["output"]>;
  finish?: Maybe<Scalars["String"]["output"]>;
  hasInventory?: Maybe<Scalars["Boolean"]["output"]>;
  /** products.id */
  id?: Maybe<Scalars["String"]["output"]>;
  inventory?: Maybe<Scalars["Int"]["output"]>;
  listingType?: Maybe<core_apimessages_Listing_ListingType>;
  make?: Maybe<Scalars["String"]["output"]>;
  maxBuyerQuantity?: Maybe<Scalars["Int"]["output"]>;
  merchandisingType?: Maybe<Scalars["String"]["output"]>;
  merchandisingUuid?: Maybe<Scalars["String"]["output"]>;
  model?: Maybe<Scalars["String"]["output"]>;
  offersEnabled?: Maybe<Scalars["Boolean"]["output"]>;
  overriddenAttributes?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
  price?: Maybe<core_apimessages_Money>;
  priceGuideUuid?: Maybe<Scalars["String"]["output"]>;
  /** product_types.id */
  productTypeId?: Maybe<Scalars["String"]["output"]>;
  propSixtyFiveWarning?: Maybe<Scalars["String"]["output"]>;
  propSixtyFiveWarningImg?: Maybe<core_apimessages_Link>;
  publishedAt?: Maybe<google_protobuf_Timestamp>;
  /** @deprecated Field no longer supported */
  salePrice?: Maybe<core_apimessages_Money>;
  sameDayShippingIneligible?: Maybe<Scalars["Boolean"]["output"]>;
  /** postgres id (polymorphic with 'seed_type') */
  seedId?: Maybe<Scalars["String"]["output"]>;
  seedType?: Maybe<core_apimessages_Listing_SeedType>;
  /** users.id */
  sellerId?: Maybe<Scalars["String"]["output"]>;
  /** users.uuid */
  sellerUuid?: Maybe<Scalars["String"]["output"]>;
  /** shops.id */
  shopId?: Maybe<Scalars["String"]["output"]>;
  shopUuid?: Maybe<Scalars["String"]["output"]>;
  sku?: Maybe<Scalars["String"]["output"]>;
  slug?: Maybe<Scalars["String"]["output"]>;
  soldAsIs?: Maybe<Scalars["Boolean"]["output"]>;
  state?: Maybe<Scalars["String"]["output"]>;
  stateDescription?: Maybe<Scalars["String"]["output"]>;
  subcategoryUuids?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
  taxIncluded?: Maybe<Scalars["Boolean"]["output"]>;
  taxIncludedHint?: Maybe<Scalars["String"]["output"]>;
  title?: Maybe<Scalars["String"]["output"]>;
  upc?: Maybe<Scalars["String"]["output"]>;
  usOutlet?: Maybe<Scalars["Boolean"]["output"]>;
  year?: Maybe<Scalars["String"]["output"]>;
};

export type core_apimessages_ListingAffirmZeroPercentFinancingConfiguration = {
  __typename?: "core_apimessages_ListingAffirmZeroPercentFinancingConfiguration";
  _source?: Maybe<Scalars["String"]["output"]>;
  isEligible?: Maybe<Scalars["Boolean"]["output"]>;
  isEnabled?: Maybe<Scalars["Boolean"]["output"]>;
};

export type core_apimessages_ListingAutoOfferEligibility = {
  __typename?: "core_apimessages_ListingAutoOfferEligibility";
  _source?: Maybe<Scalars["String"]["output"]>;
  isEligible?: Maybe<Scalars["Boolean"]["output"]>;
};

export type core_apimessages_ListingBuyerOfferEligibility = {
  __typename?: "core_apimessages_ListingBuyerOfferEligibility";
  _source?: Maybe<Scalars["String"]["output"]>;
  isEligible?: Maybe<Scalars["Boolean"]["output"]>;
};

export type core_apimessages_ListingCertifiedPreOwned = {
  __typename?: "core_apimessages_ListingCertifiedPreOwned";
  _source?: Maybe<Scalars["String"]["output"]>;
  badgeIconUrl?: Maybe<Scalars["String"]["output"]>;
  brandName?: Maybe<Scalars["String"]["output"]>;
  darkModeBrandIconUrl?: Maybe<Scalars["String"]["output"]>;
  description?: Maybe<Scalars["String"]["output"]>;
  lightModeBrandIconUrl?: Maybe<Scalars["String"]["output"]>;
  pageUrl?: Maybe<Scalars["String"]["output"]>;
  title?: Maybe<Scalars["String"]["output"]>;
  tooltipDescription?: Maybe<Scalars["String"]["output"]>;
};

export type core_apimessages_ListingCorrectionRequest = {
  __typename?: "core_apimessages_ListingCorrectionRequest";
  _source?: Maybe<Scalars["String"]["output"]>;
  listingId?: Maybe<Scalars["String"]["output"]>;
  message?: Maybe<Scalars["String"]["output"]>;
};

export type core_apimessages_ListingCorrectionResponse = {
  __typename?: "core_apimessages_ListingCorrectionResponse";
  _?: Maybe<Scalars["Boolean"]["output"]>;
  _source?: Maybe<Scalars["String"]["output"]>;
};

export type core_apimessages_ListingCounts = {
  __typename?: "core_apimessages_ListingCounts";
  _source?: Maybe<Scalars["String"]["output"]>;
  downloadCount?: Maybe<Scalars["Int"]["output"]>;
  downloadCountDisplay?: Maybe<Scalars["String"]["output"]>;
  /** products.id */
  id?: Maybe<Scalars["String"]["output"]>;
  inBuyerCarts?: Maybe<Scalars["Int"]["output"]>;
  inBuyerCartsDisplay?: Maybe<Scalars["String"]["output"]>;
  offers?: Maybe<Scalars["Int"]["output"]>;
  offersDisplay?: Maybe<Scalars["String"]["output"]>;
  views?: Maybe<Scalars["Int"]["output"]>;
  viewsDisplay?: Maybe<Scalars["String"]["output"]>;
  watchers?: Maybe<Scalars["Int"]["output"]>;
  watchersDisplay?: Maybe<Scalars["String"]["output"]>;
};

export type core_apimessages_ListingCountsRequest = {
  __typename?: "core_apimessages_ListingCountsRequest";
  _source?: Maybe<Scalars["String"]["output"]>;
  ids?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
};

export type core_apimessages_ListingCountsResponse = {
  __typename?: "core_apimessages_ListingCountsResponse";
  _source?: Maybe<Scalars["String"]["output"]>;
  listings?: Maybe<Array<Maybe<core_apimessages_ListingCounts>>>;
};

export type core_apimessages_ListingCuratedSet = {
  __typename?: "core_apimessages_ListingCuratedSet";
  _source?: Maybe<Scalars["String"]["output"]>;
  featuredInSet?: Maybe<Scalars["Boolean"]["output"]>;
  id?: Maybe<Scalars["String"]["output"]>;
  name?: Maybe<Scalars["String"]["output"]>;
  setType?: Maybe<Scalars["String"]["output"]>;
  slug?: Maybe<Scalars["String"]["output"]>;
  uuid?: Maybe<Scalars["String"]["output"]>;
};

export type core_apimessages_ListingData = {
  __typename?: "core_apimessages_ListingData";
  _source?: Maybe<Scalars["String"]["output"]>;
  condition?: Maybe<Scalars["String"]["output"]>;
  conditionIconUrl?: Maybe<Scalars["String"]["output"]>;
  countryName?: Maybe<Scalars["String"]["output"]>;
  ctaUrl?: Maybe<Scalars["String"]["output"]>;
  discountDescription?: Maybe<Scalars["String"]["output"]>;
  disruptor?: Maybe<core_apimessages_ListingData_Disruptor>;
  eyebrow?: Maybe<core_apimessages_ListingData_Disruptor>;
  id?: Maybe<Scalars["String"]["output"]>;
  imageUrl?: Maybe<Scalars["String"]["output"]>;
  isInCart?: Maybe<Scalars["Boolean"]["output"]>;
  isWatching?: Maybe<Scalars["Boolean"]["output"]>;
  price?: Maybe<Scalars["String"]["output"]>;
  savingsText?: Maybe<Scalars["String"]["output"]>;
  strikeThroughPrice?: Maybe<Scalars["String"]["output"]>;
  title?: Maybe<Scalars["String"]["output"]>;
};

export enum core_apimessages_ListingData_Disruptor {
  BUMPED = "BUMPED",
  NONE = "NONE",
  OUTLET = "OUTLET",
  PRICE_DROP = "PRICE_DROP",
  SALE = "SALE",
}

export type core_apimessages_ListingDigitalDetails = {
  __typename?: "core_apimessages_ListingDigitalDetails";
  _source?: Maybe<Scalars["String"]["output"]>;
  installationInstructions?: Maybe<Scalars["String"]["output"]>;
  releaseNotes?: Maybe<Scalars["String"]["output"]>;
  rollingDownloadCount?: Maybe<Scalars["Int"]["output"]>;
  supportedFormats?: Maybe<
    Array<Maybe<core_apimessages_ListingSupportedFormat>>
  >;
  systemRequirements?: Maybe<Scalars["String"]["output"]>;
  variants?: Maybe<Array<Maybe<core_apimessages_ListingDigitalVariant>>>;
};

export type core_apimessages_ListingDigitalVariant = {
  __typename?: "core_apimessages_ListingDigitalVariant";
  _source?: Maybe<Scalars["String"]["output"]>;
  downloadText?: Maybe<Scalars["String"]["output"]>;
  fileSize?: Maybe<Scalars["String"]["output"]>;
  fileTypes?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
  fileUrl?: Maybe<Scalars["String"]["output"]>;
  id?: Maybe<Scalars["String"]["output"]>;
  operatingSystems?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
};

/** For PUT/POST submissions */
export type core_apimessages_ListingEntry = {
  __typename?: "core_apimessages_ListingEntry";
  _source?: Maybe<Scalars["String"]["output"]>;
  carrierCalculatedPackage?: Maybe<core_apimessages_ShippingLabelPackage>;
  categoryRootUuid?: Maybe<Scalars["String"]["output"]>;
  categoryUuids?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
  conditionSlug?: Maybe<Scalars["String"]["output"]>;
  countryOfOrigin?: Maybe<Scalars["String"]["output"]>;
  handmade?: Maybe<Scalars["Boolean"]["output"]>;
  hasInventory?: Maybe<Scalars["Boolean"]["output"]>;
  id?: Maybe<Scalars["String"]["output"]>;
  inventory?: Maybe<Scalars["Int"]["output"]>;
  localizedContents?: Maybe<
    Array<Maybe<core_apimessages_LocalizedListingContent>>
  >;
  offersEnabled?: Maybe<Scalars["Boolean"]["output"]>;
  offersLocalPickup?: Maybe<Scalars["Boolean"]["output"]>;
  photos?: Maybe<Array<Maybe<core_apimessages_ImageEntry>>>;
  prefersReverbShippingLabel?: Maybe<Scalars["Boolean"]["output"]>;
  preorderInfo?: Maybe<core_apimessages_PreorderInfoEntry>;
  price?: Maybe<core_apimessages_Money>;
  sameDayShippingIneligible?: Maybe<Scalars["Boolean"]["output"]>;
  scheduledPriceDrops?: Maybe<
    Array<Maybe<core_apimessages_ScheduledPriceDropEntry>>
  >;
  seedId?: Maybe<Scalars["String"]["output"]>;
  seedType?: Maybe<core_apimessages_Listing_SeedType>;
  sellerCost?: Maybe<core_apimessages_Money>;
  sellerReportedMap?: Maybe<core_apimessages_Money>;
  shippingProfileId?: Maybe<Scalars["String"]["output"]>;
  shippingRates?: Maybe<Array<Maybe<core_apimessages_ShippingRates>>>;
  sku?: Maybe<Scalars["String"]["output"]>;
  soldAsIs?: Maybe<Scalars["Boolean"]["output"]>;
  taxExempt?: Maybe<Scalars["Boolean"]["output"]>;
  traits?: Maybe<Array<Maybe<core_apimessages_Trait>>>;
  upc?: Maybe<Scalars["String"]["output"]>;
  upcDoesNotApply?: Maybe<Scalars["Boolean"]["output"]>;
  videoLinkUrl?: Maybe<Scalars["String"]["output"]>;
  year?: Maybe<Scalars["String"]["output"]>;
};

export type core_apimessages_ListingExpressPay = {
  __typename?: "core_apimessages_ListingExpressPay";
  _id?: Maybe<Scalars["ID"]["output"]>;
  _source?: Maybe<Scalars["String"]["output"]>;
  /** Encoded value such as "RL-123" to use to start a checkout */
  checkoutBundlingId?: Maybe<Scalars["String"]["output"]>;
  /** Initial value for display based on listing price and shipping. May be different than the final value. */
  estimatedTotal?: Maybe<core_apimessages_Money>;
  /** Google Pay Config for a listing. */
  googlePay?: Maybe<core_apimessages_CheckoutGooglePayDetails>;
  /** products.id */
  id?: Maybe<Scalars["String"]["output"]>;
  paypal?: Maybe<core_apimessages_CheckoutPaypalDetails>;
};

export type core_apimessages_ListingInCartCount = {
  __typename?: "core_apimessages_ListingInCartCount";
  _source?: Maybe<Scalars["String"]["output"]>;
  count?: Maybe<google_protobuf_Int32Value>;
};

export type core_apimessages_ListingInUserCart = {
  __typename?: "core_apimessages_ListingInUserCart";
  _source?: Maybe<Scalars["String"]["output"]>;
  inCart?: Maybe<Scalars["Boolean"]["output"]>;
  listingId?: Maybe<Scalars["String"]["output"]>;
};

export type core_apimessages_ListingPriceRecommendationsRequest = {
  __typename?: "core_apimessages_ListingPriceRecommendationsRequest";
  _source?: Maybe<Scalars["String"]["output"]>;
  countryCode?: Maybe<Scalars["String"]["output"]>;
  currency?: Maybe<Scalars["String"]["output"]>;
  listingIds?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
};

export type core_apimessages_ListingPriceRecommendationsResponse = {
  __typename?: "core_apimessages_ListingPriceRecommendationsResponse";
  _source?: Maybe<Scalars["String"]["output"]>;
  priceRecommendations?: Maybe<
    Array<Maybe<core_apimessages_PriceRecommendation>>
  >;
};

export type core_apimessages_ListingPricing = {
  __typename?: "core_apimessages_ListingPricing";
  _source?: Maybe<Scalars["String"]["output"]>;
  buyerPrice?: Maybe<core_apimessages_Money>;
  /** products.id */
  id?: Maybe<Scalars["String"]["output"]>;
  originalPrice?: Maybe<core_apimessages_Money>;
  originalPriceDescription?: Maybe<Scalars["String"]["output"]>;
  ribbon?: Maybe<core_apimessages_Ribbon>;
  typicalNewPriceDisplay?: Maybe<core_apimessages_TypicalNewPriceDisplay>;
};

export type core_apimessages_ListingSale = {
  __typename?: "core_apimessages_ListingSale";
  _source?: Maybe<Scalars["String"]["output"]>;
  isListingEligible?: Maybe<Scalars["Boolean"]["output"]>;
  isListingInSale?: Maybe<Scalars["Boolean"]["output"]>;
  sale?: Maybe<core_apimessages_Sale>;
  totalLiveListings?: Maybe<Scalars["Int"]["output"]>;
};

export type core_apimessages_ListingSaleMembership = {
  __typename?: "core_apimessages_ListingSaleMembership";
  _source?: Maybe<Scalars["String"]["output"]>;
  isListingEligible?: Maybe<Scalars["Boolean"]["output"]>;
  isListingInSale?: Maybe<Scalars["Boolean"]["output"]>;
  listingId?: Maybe<Scalars["String"]["output"]>;
  sale?: Maybe<core_apimessages_Sale>;
  totalLiveListings?: Maybe<Scalars["Int"]["output"]>;
};

export type core_apimessages_ListingSales = {
  __typename?: "core_apimessages_ListingSales";
  _source?: Maybe<Scalars["String"]["output"]>;
  reverbSales?: Maybe<Array<Maybe<core_apimessages_ListingSale>>>;
  sellerSales?: Maybe<Array<Maybe<core_apimessages_ListingSale>>>;
};

export type core_apimessages_ListingSalesMemberships = {
  __typename?: "core_apimessages_ListingSalesMemberships";
  _source?: Maybe<Scalars["String"]["output"]>;
  reverbSalesMemberships?: Maybe<
    Array<Maybe<core_apimessages_ListingSaleMembership>>
  >;
  sellerSalesMemberships?: Maybe<
    Array<Maybe<core_apimessages_ListingSaleMembership>>
  >;
};

export type core_apimessages_ListingSignals = {
  __typename?: "core_apimessages_ListingSignals";
  _source?: Maybe<Scalars["String"]["output"]>;
  availableForPickupSignal?: Maybe<core_apimessages_AvailableForPickupSignal>;
  availableNearbyForPickupSignal?: Maybe<core_apimessages_AvailableNearbyForPickupSignal>;
  buyWithConfidenceSignal?: Maybe<core_apimessages_BuyWithConfidenceSignal>;
  expeditedShippingAvailableSignal?: Maybe<core_apimessages_ExpeditedShippingAvailableSignal>;
  freeExpeditedShippingSignal?: Maybe<core_apimessages_FreeExpeditedShippingSignal>;
  freeStandardShippingSignal?: Maybe<core_apimessages_FreeStandardShippingSignal>;
  greatValueSignal?: Maybe<core_apimessages_GreatValueSignal>;
  id?: Maybe<Scalars["String"]["output"]>;
  inOtherCartsSignal?: Maybe<core_apimessages_InOtherCartsSignal>;
  localPickupOnlySignal?: Maybe<core_apimessages_LocalPickupOnlySignal>;
  lowStockSignal?: Maybe<core_apimessages_LowStockSignal>;
  newListingSignal?: Maybe<core_apimessages_NewListingSignal>;
  onSaleSignal?: Maybe<core_apimessages_OnSaleSignal>;
  /** @deprecated Field no longer supported */
  paymentPlanSignal?: Maybe<core_apimessages_PaymentPlanSignal>;
  predictedToSellSoonSignal?: Maybe<core_apimessages_PredictedToSellSoonSignal>;
  /** @deprecated Field no longer supported */
  priceDropSignal?: Maybe<core_apimessages_PriceDropSignal>;
  rareGearSignal?: Maybe<core_apimessages_RareGearSignal>;
  returnPolicySignal?: Maybe<core_apimessages_ReturnPolicySignal>;
  secureCheckoutSignal?: Maybe<core_apimessages_SecureCheckoutSignal>;
  sharedSignalData?: Maybe<core_apimessages_SharedSignalData>;
  /** deprecated */
  shipsSoonSignal?: Maybe<core_apimessages_ShipsSoonSignal>;
};

export type core_apimessages_ListingSpec = {
  __typename?: "core_apimessages_ListingSpec";
  _source?: Maybe<Scalars["String"]["output"]>;
  productSpecs?: Maybe<Array<Maybe<core_apimessages_ProductSpec>>>;
};

export enum core_apimessages_ListingStateUpdate {
  ENDED = "ENDED",
  LIVE = "LIVE",
}

export type core_apimessages_ListingSupportedFormat = {
  __typename?: "core_apimessages_ListingSupportedFormat";
  _source?: Maybe<Scalars["String"]["output"]>;
  fileTypes?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
  operatingSystem?: Maybe<Scalars["String"]["output"]>;
};

export type core_apimessages_ListingUserView = {
  __typename?: "core_apimessages_ListingUserView";
  _source?: Maybe<Scalars["String"]["output"]>;
  /** True if the current user has recently viewed this item */
  isRecentlyViewed?: Maybe<Scalars["Boolean"]["output"]>;
};

export type core_apimessages_ListingValidForPublish = {
  __typename?: "core_apimessages_ListingValidForPublish";
  _source?: Maybe<Scalars["String"]["output"]>;
  errors?: Maybe<Array<Maybe<core_apimessages_Error>>>;
  isValid?: Maybe<Scalars["Boolean"]["output"]>;
};

export enum core_apimessages_Listing_ListingType {
  AUCTION = "AUCTION",
  BUY_IT_NOW = "BUY_IT_NOW",
  CORE_AUCTION = "CORE_AUCTION",
  DIGITAL = "DIGITAL",
  MAKE_AN_OFFER = "MAKE_AN_OFFER",
}

export enum core_apimessages_Listing_SeedType {
  CanonicalProduct = "CanonicalProduct",
  ComparisonShoppingPage = "ComparisonShoppingPage",
  GearCollectionItem = "GearCollectionItem",
  Order = "Order",
  /** DEPRECATED, we no longer support PriceGuide seeds */
  PriceGuide = "PriceGuide",
  Product = "Product",
  TradeIn = "TradeIn",
}

export type core_apimessages_ListingsCuratedSetsRequest = {
  __typename?: "core_apimessages_ListingsCuratedSetsRequest";
  _source?: Maybe<Scalars["String"]["output"]>;
  curatedSetSlugs?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
  ids?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
};

export type core_apimessages_ListingsCuratedSetsResponse = {
  __typename?: "core_apimessages_ListingsCuratedSetsResponse";
  _source?: Maybe<Scalars["String"]["output"]>;
  curatedSetsForListings?: Maybe<
    Array<Maybe<core_apimessages_CuratedSetsForListing>>
  >;
};

export type core_apimessages_ListingsInUserCartRequest = {
  __typename?: "core_apimessages_ListingsInUserCartRequest";
  _source?: Maybe<Scalars["String"]["output"]>;
  /** product.id */
  listingIds?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
};

export type core_apimessages_ListingsInUserCartResponse = {
  __typename?: "core_apimessages_ListingsInUserCartResponse";
  _source?: Maybe<Scalars["String"]["output"]>;
  listings?: Maybe<Array<Maybe<core_apimessages_ListingInUserCart>>>;
};

export type core_apimessages_ListingsOrderStatsRequest = {
  __typename?: "core_apimessages_ListingsOrderStatsRequest";
  _source?: Maybe<Scalars["String"]["output"]>;
  listingIds?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
};

export type core_apimessages_ListingsOrderStatsResponse = {
  __typename?: "core_apimessages_ListingsOrderStatsResponse";
  _source?: Maybe<Scalars["String"]["output"]>;
  orderStats?: Maybe<Array<Maybe<core_apimessages_OrderStats>>>;
};

export type core_apimessages_ListingsPricingRequest = {
  __typename?: "core_apimessages_ListingsPricingRequest";
  _source?: Maybe<Scalars["String"]["output"]>;
  ids?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
};

export type core_apimessages_ListingsPricingResponse = {
  __typename?: "core_apimessages_ListingsPricingResponse";
  _source?: Maybe<Scalars["String"]["output"]>;
  listings?: Maybe<Array<Maybe<core_apimessages_ListingPricing>>>;
};

export type core_apimessages_ListingsRequest = {
  __typename?: "core_apimessages_ListingsRequest";
  Aid?: Maybe<Scalars["String"]["output"]>;
  _source?: Maybe<Scalars["String"]["output"]>;
  /** products.id */
  ids?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
  utmCompaign?: Maybe<Scalars["String"]["output"]>;
};

export type core_apimessages_ListingsResponse = {
  __typename?: "core_apimessages_ListingsResponse";
  _source?: Maybe<Scalars["String"]["output"]>;
  listings?: Maybe<Array<Maybe<core_apimessages_Listing>>>;
};

export type core_apimessages_ListingsSignalsRequest = {
  __typename?: "core_apimessages_ListingsSignalsRequest";
  _source?: Maybe<Scalars["String"]["output"]>;
  ids?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
};

export type core_apimessages_ListingsSignalsResponse = {
  __typename?: "core_apimessages_ListingsSignalsResponse";
  _source?: Maybe<Scalars["String"]["output"]>;
  listingsSignals?: Maybe<Array<Maybe<core_apimessages_ListingSignals>>>;
};

export type core_apimessages_ListingsSpecsRequest = {
  __typename?: "core_apimessages_ListingsSpecsRequest";
  _source?: Maybe<Scalars["String"]["output"]>;
  ids?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
};

export type core_apimessages_ListingsSpecsResponse = {
  __typename?: "core_apimessages_ListingsSpecsResponse";
  _source?: Maybe<Scalars["String"]["output"]>;
  specs?: Maybe<Array<Maybe<core_apimessages_ListingSpec>>>;
};

export type core_apimessages_ListingsValidForPublishRequest = {
  __typename?: "core_apimessages_ListingsValidForPublishRequest";
  _source?: Maybe<Scalars["String"]["output"]>;
  listingIds?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
};

export type core_apimessages_ListingsValidForPublishResponse = {
  __typename?: "core_apimessages_ListingsValidForPublishResponse";
  _source?: Maybe<Scalars["String"]["output"]>;
  listingsValidForPublish?: Maybe<
    Array<Maybe<core_apimessages_ListingValidForPublish>>
  >;
};

export type core_apimessages_LocalPickupOnlySignal = {
  __typename?: "core_apimessages_LocalPickupOnlySignal";
  _source?: Maybe<Scalars["String"]["output"]>;
  isValid?: Maybe<Scalars["Boolean"]["output"]>;
  translations?: Maybe<core_apimessages_SignalTranslation>;
};

export type core_apimessages_LocalizationSettings = {
  __typename?: "core_apimessages_LocalizationSettings";
  _source?: Maybe<Scalars["String"]["output"]>;
  currency?: Maybe<Scalars["String"]["output"]>;
  locale?: Maybe<Scalars["String"]["output"]>;
  shippingRegion?: Maybe<Scalars["String"]["output"]>;
};

export type core_apimessages_LocalizedListingContent = {
  __typename?: "core_apimessages_LocalizedListingContent";
  _source?: Maybe<Scalars["String"]["output"]>;
  defaultForShop?: Maybe<Scalars["Boolean"]["output"]>;
  description?: Maybe<Scalars["String"]["output"]>;
  finish?: Maybe<Scalars["String"]["output"]>;
  locale?: Maybe<Scalars["String"]["output"]>;
  make?: Maybe<Scalars["String"]["output"]>;
  model?: Maybe<Scalars["String"]["output"]>;
  prop65Warning?: Maybe<Scalars["String"]["output"]>;
  title?: Maybe<Scalars["String"]["output"]>;
};

export type core_apimessages_LoginMyPacklinkAccountRequest = {
  __typename?: "core_apimessages_LoginMyPacklinkAccountRequest";
  _source?: Maybe<Scalars["String"]["output"]>;
  countryCode?: Maybe<Scalars["String"]["output"]>;
  email?: Maybe<Scalars["String"]["output"]>;
  password?: Maybe<Scalars["String"]["output"]>;
};

export type core_apimessages_LoginMyPacklinkAccountResponse = {
  __typename?: "core_apimessages_LoginMyPacklinkAccountResponse";
  _source?: Maybe<Scalars["String"]["output"]>;
  shopId?: Maybe<Scalars["String"]["output"]>;
};

export type core_apimessages_LowStockSignal = {
  __typename?: "core_apimessages_LowStockSignal";
  _source?: Maybe<Scalars["String"]["output"]>;
  isValid?: Maybe<Scalars["Boolean"]["output"]>;
  translations?: Maybe<core_apimessages_SignalTranslation>;
};

export type core_apimessages_MarkOrderAsReceivedRequest = {
  __typename?: "core_apimessages_MarkOrderAsReceivedRequest";
  _source?: Maybe<Scalars["String"]["output"]>;
  orderId?: Maybe<Scalars["String"]["output"]>;
};

export type core_apimessages_MarkOrderAsReceivedResponse = {
  __typename?: "core_apimessages_MarkOrderAsReceivedResponse";
  _source?: Maybe<Scalars["String"]["output"]>;
  order?: Maybe<Order>;
  orderId?: Maybe<Scalars["String"]["output"]>;
};

export type core_apimessages_MeResponse = {
  __typename?: "core_apimessages_MeResponse";
  _source?: Maybe<Scalars["String"]["output"]>;
  admin?: Maybe<Scalars["Boolean"]["output"]>;
  /** True when this user has a single orderBundle, otherwise false. */
  firstTimeBuyer?: Maybe<Scalars["Boolean"]["output"]>;
  /** users.id */
  id?: Maybe<Scalars["String"]["output"]>;
  profileSlug?: Maybe<Scalars["String"]["output"]>;
  requireOptIn?: Maybe<Scalars["Boolean"]["output"]>;
  shopId?: Maybe<Scalars["String"]["output"]>;
  shopSlug?: Maybe<Scalars["String"]["output"]>;
  shopUuid?: Maybe<Scalars["String"]["output"]>;
  status?: Maybe<core_apimessages_UserStatus>;
  uuid?: Maybe<Scalars["String"]["output"]>;
};

export type core_apimessages_Measurement = {
  __typename?: "core_apimessages_Measurement";
  _source?: Maybe<Scalars["String"]["output"]>;
  unit?: Maybe<Scalars["String"]["output"]>;
  value?: Maybe<Scalars["String"]["output"]>;
};

export type core_apimessages_Money = {
  __typename?: "core_apimessages_Money";
  _source?: Maybe<Scalars["String"]["output"]>;
  amount?: Maybe<Scalars["String"]["output"]>;
  amountCents?: Maybe<Scalars["Int"]["output"]>;
  currency?: Maybe<Scalars["String"]["output"]>;
  display?: Maybe<Scalars["String"]["output"]>;
  symbol?: Maybe<Scalars["String"]["output"]>;
};

/** For POST/PUT API requests for major units, suitable for user text field input */
export type core_apimessages_MoneyMajorUnitsEntry = {
  __typename?: "core_apimessages_MoneyMajorUnitsEntry";
  _source?: Maybe<Scalars["String"]["output"]>;
  amount?: Maybe<Scalars["String"]["output"]>;
  currency?: Maybe<Scalars["String"]["output"]>;
};

/** For POST/PUT API requests where minor units (cents) are known */
export type core_apimessages_MoneyMinorUnitsEntry = {
  __typename?: "core_apimessages_MoneyMinorUnitsEntry";
  _source?: Maybe<Scalars["String"]["output"]>;
  amountCents?: Maybe<Scalars["Int"]["output"]>;
  currency?: Maybe<Scalars["String"]["output"]>;
};

export type core_apimessages_MosaicTile = {
  __typename?: "core_apimessages_MosaicTile";
  _source?: Maybe<Scalars["String"]["output"]>;
  contentSponsorship?: Maybe<core_apimessages_ContentSponsorship>;
  id?: Maybe<Scalars["String"]["output"]>;
  imageSubtitle?: Maybe<Scalars["String"]["output"]>;
  imageTitle?: Maybe<Scalars["String"]["output"]>;
  imageUrl?: Maybe<Scalars["String"]["output"]>;
  position?: Maybe<core_apimessages_MosaicTilePosition>;
  url?: Maybe<Scalars["String"]["output"]>;
};

export enum core_apimessages_MosaicTilePosition {
  CENTER = "CENTER",
  LEFT = "LEFT",
  RIGHT = "RIGHT",
}

export type core_apimessages_MosaicTilesResponse = {
  __typename?: "core_apimessages_MosaicTilesResponse";
  _source?: Maybe<Scalars["String"]["output"]>;
  mosaicTiles?: Maybe<Array<Maybe<core_apimessages_MosaicTile>>>;
};

/** duplicate of the Mparticle ProfileResponse defined in rql */
export type core_apimessages_MparticleProfileResponse = {
  __typename?: "core_apimessages_MparticleProfileResponse";
  _source?: Maybe<Scalars["String"]["output"]>;
  averageOrderValue?: Maybe<Scalars["Float"]["output"]>;
  brandCategories?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
  buyerFeedbackRating?: Maybe<Scalars["Float"]["output"]>;
  buyerLabel?: Maybe<Scalars["String"]["output"]>;
  cumulativeGmv?: Maybe<Scalars["Float"]["output"]>;
  firstOrderDate?: Maybe<Scalars["String"]["output"]>;
  mostRecentOrderDate?: Maybe<Scalars["String"]["output"]>;
  mpid?: Maybe<Scalars["String"]["output"]>;
  recentlyViewedCsps?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
  sellerFeedbackRating?: Maybe<Scalars["Float"]["output"]>;
  sellerLabel?: Maybe<Scalars["String"]["output"]>;
  topBrandCategories?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
  topBrands?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
  topCategories?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
  topCategoryUuids?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
  topMostRecentProductTypes?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
  topProductType?: Maybe<Scalars["String"]["output"]>;
  topProductTypeUuids?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
  topProductTypes?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
  totalBuyerOffersAccepted?: Maybe<Scalars["Int"]["output"]>;
  totalBuyerOffersCreated?: Maybe<Scalars["Int"]["output"]>;
  totalFeedFollows?: Maybe<Scalars["Int"]["output"]>;
  totalListingsCreated?: Maybe<Scalars["Int"]["output"]>;
  totalListingsDraft?: Maybe<Scalars["Int"]["output"]>;
  totalListingsLive?: Maybe<Scalars["Int"]["output"]>;
  totalListingsSold?: Maybe<Scalars["Int"]["output"]>;
  totalListingsSuspended?: Maybe<Scalars["Int"]["output"]>;
  totalPurchases?: Maybe<Scalars["Int"]["output"]>;
};

export enum core_apimessages_MyAddressType {
  EU_ODR_PLATFORM = "EU_ODR_PLATFORM",
  SHIPPING = "SHIPPING",
  SHOP = "SHOP",
}

export type core_apimessages_MyAddressesRequest = {
  __typename?: "core_apimessages_MyAddressesRequest";
  _source?: Maybe<Scalars["String"]["output"]>;
  addressType?: Maybe<core_apimessages_MyAddressType>;
};

export type core_apimessages_MyAddressesResponse = {
  __typename?: "core_apimessages_MyAddressesResponse";
  _source?: Maybe<Scalars["String"]["output"]>;
  addresses?: Maybe<Array<Maybe<core_apimessages_Address>>>;
};

export type core_apimessages_MyAffirmCuratedSetMembershipRequest = {
  __typename?: "core_apimessages_MyAffirmCuratedSetMembershipRequest";
  _source?: Maybe<Scalars["String"]["output"]>;
  productId?: Maybe<Scalars["String"]["output"]>;
};

export type core_apimessages_MyAvailableActionsIndexRequest = {
  __typename?: "core_apimessages_MyAvailableActionsIndexRequest";
  _source?: Maybe<Scalars["String"]["output"]>;
  listingId?: Maybe<Scalars["String"]["output"]>;
};

export type core_apimessages_MyAvailableActionsIndexResponse = {
  __typename?: "core_apimessages_MyAvailableActionsIndexResponse";
  _source?: Maybe<Scalars["String"]["output"]>;
  availableActions?: Maybe<Array<Maybe<core_apimessages_AvailableAction>>>;
};

export type core_apimessages_MyBuyerCouponsRequest = {
  __typename?: "core_apimessages_MyBuyerCouponsRequest";
  _source?: Maybe<Scalars["String"]["output"]>;
  checkoutUuid?: Maybe<Scalars["String"]["output"]>;
  context?: Maybe<core_apimessages_MyBuyerCouponsRequest_Context>;
  shopSlug?: Maybe<Scalars["String"]["output"]>;
};

export enum core_apimessages_MyBuyerCouponsRequest_Context {
  ALL_UNUSED = "ALL_UNUSED",
  CART = "CART",
  CHECKOUT = "CHECKOUT",
  SHOP = "SHOP",
}

export type core_apimessages_MyBuyerCouponsResponse = {
  __typename?: "core_apimessages_MyBuyerCouponsResponse";
  _source?: Maybe<Scalars["String"]["output"]>;
  shopCampaignCoupons?: Maybe<
    Array<Maybe<core_apimessages_ShopCampaignCoupon>>
  >;
};

export type core_apimessages_MyCartItemBundlesIndexResponse = {
  __typename?: "core_apimessages_MyCartItemBundlesIndexResponse";
  _source?: Maybe<Scalars["String"]["output"]>;
  bundles?: Maybe<Array<Maybe<core_apimessages_CartItemBundle>>>;
};

export type core_apimessages_MyCartItemsIndexResponse = {
  __typename?: "core_apimessages_MyCartItemsIndexResponse";
  _source?: Maybe<Scalars["String"]["output"]>;
  cartItems?: Maybe<Array<Maybe<core_apimessages_CartItem>>>;
};

export type core_apimessages_MyCreditCardsResponse = {
  __typename?: "core_apimessages_MyCreditCardsResponse";
  _source?: Maybe<Scalars["String"]["output"]>;
  creditCards?: Maybe<Array<Maybe<core_apimessages_CreditCard>>>;
};

export type core_apimessages_MyCuratedSetMembershipRequest = {
  __typename?: "core_apimessages_MyCuratedSetMembershipRequest";
  _source?: Maybe<Scalars["String"]["output"]>;
  productId?: Maybe<Scalars["String"]["output"]>;
  setType?: Maybe<core_apimessages_CuratedSet_SetType>;
};

export type core_apimessages_MyCuratedSetMembershipResponse = {
  __typename?: "core_apimessages_MyCuratedSetMembershipResponse";
  _source?: Maybe<Scalars["String"]["output"]>;
  curatedSetMembership?: Maybe<core_apimessages_CuratedSetMembership>;
};

export type core_apimessages_MyDirectCheckoutProfileRequest = {
  __typename?: "core_apimessages_MyDirectCheckoutProfileRequest";
  _?: Maybe<Scalars["Boolean"]["output"]>;
  _source?: Maybe<Scalars["String"]["output"]>;
};

export type core_apimessages_MyDirectCheckoutProfileResponse = {
  __typename?: "core_apimessages_MyDirectCheckoutProfileResponse";
  _source?: Maybe<Scalars["String"]["output"]>;
  bankName?: Maybe<Scalars["String"]["output"]>;
  countryCode?: Maybe<Scalars["String"]["output"]>;
  currency?: Maybe<Scalars["String"]["output"]>;
  hasAcceptedReverbBankTerms?: Maybe<Scalars["Boolean"]["output"]>;
  maskedNameSummary?: Maybe<Scalars["String"]["output"]>;
  nameOnAccount?: Maybe<Scalars["String"]["output"]>;
  payoutMethod?: Maybe<Scalars["String"]["output"]>;
  plaidAccountId?: Maybe<Scalars["String"]["output"]>;
  profileErrors?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
};

export type core_apimessages_MyFeedSearchRequest = {
  __typename?: "core_apimessages_MyFeedSearchRequest";
  _source?: Maybe<Scalars["String"]["output"]>;
  limit?: Maybe<Scalars["Int"]["output"]>;
  /**
   *   Optional listings search filters. The following fields are ignored:
   * - limit/offset: filters are optional, but pagination is not optional. Use the top level request proto fields instead.
   * - shipping_region_code: uses the `X-Shipping-Region` HTTP request header instead.
   * - locale: uses the `Accept-Language` HTTP request header instead (which will default to English).
   * - item_region: uses the `X-Item-Region` HTTP request header instead.
   * - experiments: uses the `X-Experiments` HTTP request header instead.
   */
  listingsSearchRequest?: Maybe<reverb_search_ListingsSearchRequest>;
  offset?: Maybe<Scalars["Int"]["output"]>;
};

/**
 *   Similar to a `reverb.search.SearchResponse` message,
 * but instead of a `resultIds` field, it has `results`, which contains ids and metadata:
 * {
 *   "results": [{ "entityType": "LISTING", "entityId": "1", "source": "SAVED_SEARCH" }, ...],
 *   ...
 * }
 */
export type core_apimessages_MyFeedSearchResponse = {
  __typename?: "core_apimessages_MyFeedSearchResponse";
  _source?: Maybe<Scalars["String"]["output"]>;
  filters?: Maybe<Array<Maybe<reverb_search_Filter>>>;
  limit?: Maybe<Scalars["Int"]["output"]>;
  offset?: Maybe<Scalars["Int"]["output"]>;
  results?: Maybe<Array<Maybe<core_apimessages_FeedResult>>>;
  total?: Maybe<Scalars["Int"]["output"]>;
};

export type core_apimessages_MyFeedbackMessagesRequest = {
  __typename?: "core_apimessages_MyFeedbackMessagesRequest";
  _?: Maybe<Scalars["Boolean"]["output"]>;
  _source?: Maybe<Scalars["String"]["output"]>;
};

export type core_apimessages_MyFeedbackMessagesResponse = {
  __typename?: "core_apimessages_MyFeedbackMessagesResponse";
  _source?: Maybe<Scalars["String"]["output"]>;
  feedbackMessages?: Maybe<Array<Maybe<core_apimessages_FeedbackMessage>>>;
};

export type core_apimessages_MyHomepageNotificationsRequest = {
  __typename?: "core_apimessages_MyHomepageNotificationsRequest";
  _source?: Maybe<Scalars["String"]["output"]>;
  imageType?: Maybe<core_apimessages_IconImageType>;
  limit?: Maybe<Scalars["Int"]["output"]>;
  multiClientExperiments?: Maybe<Array<Maybe<core_apimessages_ExperimentData>>>;
};

export type core_apimessages_MyHomepageNotificationsResponse = {
  __typename?: "core_apimessages_MyHomepageNotificationsResponse";
  _source?: Maybe<Scalars["String"]["output"]>;
  errors?: Maybe<Array<Maybe<core_apimessages_Error>>>;
  homepageNotifications?: Maybe<
    Array<Maybe<core_apimessages_HomepageNotification>>
  >;
};

export type core_apimessages_MyMessage = {
  __typename?: "core_apimessages_MyMessage";
  _source?: Maybe<Scalars["String"]["output"]>;
  body?: Maybe<Scalars["String"]["output"]>;
  conversationId?: Maybe<Scalars["String"]["output"]>;
  createdAt?: Maybe<Scalars["Timestamp"]["output"]>;
  id?: Maybe<Scalars["String"]["output"]>;
  read?: Maybe<Scalars["Boolean"]["output"]>;
};

export type core_apimessages_MyMessagesRequest = {
  __typename?: "core_apimessages_MyMessagesRequest";
  _source?: Maybe<Scalars["String"]["output"]>;
  limit?: Maybe<Scalars["Int"]["output"]>;
  unrepliedOnly?: Maybe<Scalars["Boolean"]["output"]>;
};

export type core_apimessages_MyMessagesResponse = {
  __typename?: "core_apimessages_MyMessagesResponse";
  _source?: Maybe<Scalars["String"]["output"]>;
  messages?: Maybe<Array<Maybe<core_apimessages_MyMessage>>>;
};

export type core_apimessages_MyPacklinkAccountStatusResponse = {
  __typename?: "core_apimessages_MyPacklinkAccountStatusResponse";
  _source?: Maybe<Scalars["String"]["output"]>;
  account?: Maybe<core_apimessages_PacklinkAccount>;
};

export type core_apimessages_MyPayoutBalancesResponse = {
  __typename?: "core_apimessages_MyPayoutBalancesResponse";
  _source?: Maybe<Scalars["String"]["output"]>;
  payoutBalances?: Maybe<Array<Maybe<core_apimessages_Money>>>;
};

export type core_apimessages_MyReverbCreditsResponse = {
  __typename?: "core_apimessages_MyReverbCreditsResponse";
  _source?: Maybe<Scalars["String"]["output"]>;
  reverbCredits?: Maybe<Array<Maybe<core_apimessages_Money>>>;
};

export type core_apimessages_MySalesResponse = {
  __typename?: "core_apimessages_MySalesResponse";
  _source?: Maybe<Scalars["String"]["output"]>;
  reverbSales?: Maybe<Array<Maybe<core_apimessages_Sale>>>;
  sellerSales?: Maybe<Array<Maybe<core_apimessages_Sale>>>;
};

export type core_apimessages_MyShippingProfile = {
  __typename?: "core_apimessages_MyShippingProfile";
  _source?: Maybe<Scalars["String"]["output"]>;
  categoryUuids?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
  id?: Maybe<Scalars["String"]["output"]>;
  localPickup?: Maybe<Scalars["Boolean"]["output"]>;
  name?: Maybe<Scalars["String"]["output"]>;
  offersIncrementalRates?: Maybe<Scalars["Boolean"]["output"]>;
  shippingRates?: Maybe<Array<Maybe<core_apimessages_ShippingRates>>>;
};

export type core_apimessages_MyShopBadgesResponse = {
  __typename?: "core_apimessages_MyShopBadgesResponse";
  _source?: Maybe<Scalars["String"]["output"]>;
  shopBadges?: Maybe<Array<Maybe<core_apimessages_ShopBadge>>>;
};

export type core_apimessages_MyShopBumpStats = {
  __typename?: "core_apimessages_MyShopBumpStats";
  _source?: Maybe<Scalars["String"]["output"]>;
  percentageSpent?: Maybe<Scalars["Int"]["output"]>;
  sales?: Maybe<core_apimessages_Money>;
  spent?: Maybe<core_apimessages_Money>;
  views?: Maybe<Scalars["Int"]["output"]>;
};

export type core_apimessages_MyShopBumpStatsRequest = {
  __typename?: "core_apimessages_MyShopBumpStatsRequest";
  _source?: Maybe<Scalars["String"]["output"]>;
  timePeriod?: Maybe<Scalars["String"]["output"]>;
};

export type core_apimessages_MyShopBumpStatsResponse = {
  __typename?: "core_apimessages_MyShopBumpStatsResponse";
  _source?: Maybe<Scalars["String"]["output"]>;
  brandNew?: Maybe<core_apimessages_MyShopBumpStats>;
  used?: Maybe<core_apimessages_MyShopBumpStats>;
};

export type core_apimessages_MyShopCampaign = {
  __typename?: "core_apimessages_MyShopCampaign";
  _id?: Maybe<Scalars["ID"]["output"]>;
  _source?: Maybe<Scalars["String"]["output"]>;
  active?: Maybe<Scalars["Boolean"]["output"]>;
  applyToBStockConditions?: Maybe<Scalars["Boolean"]["output"]>;
  applyToNewConditions?: Maybe<Scalars["Boolean"]["output"]>;
  applyToUsedConditions?: Maybe<Scalars["Boolean"]["output"]>;
  campaignType?: Maybe<core_apimessages_MyShopCampaign_CampaignType>;
  code?: Maybe<Scalars["String"]["output"]>;
  discountAmount?: Maybe<core_apimessages_Money>;
  discountPercent?: Maybe<Scalars["Int"]["output"]>;
  discountSummary?: Maybe<Scalars["String"]["output"]>;
  excludedBrands?: Maybe<Array<Maybe<Brand>>>;
  excludedCategories?: Maybe<Array<Maybe<Category>>>;
  id?: Maybe<Scalars["String"]["output"]>;
  maxDiscountAmount?: Maybe<core_apimessages_Money>;
  minPurchaseAmount?: Maybe<core_apimessages_Money>;
  redeemedCouponCount?: Maybe<Scalars["Int"]["output"]>;
  totalCouponCount?: Maybe<Scalars["Int"]["output"]>;
  unlimitedUse?: Maybe<Scalars["Boolean"]["output"]>;
  useRetroactiveSends?: Maybe<Scalars["Boolean"]["output"]>;
};

/** For PUT/POST submissions */
export type core_apimessages_MyShopCampaignEntry = {
  __typename?: "core_apimessages_MyShopCampaignEntry";
  _source?: Maybe<Scalars["String"]["output"]>;
  active?: Maybe<Scalars["Boolean"]["output"]>;
  applyToBStockConditions?: Maybe<Scalars["Boolean"]["output"]>;
  applyToNewConditions?: Maybe<Scalars["Boolean"]["output"]>;
  applyToUsedConditions?: Maybe<Scalars["Boolean"]["output"]>;
  campaignType?: Maybe<core_apimessages_MyShopCampaign_CampaignType>;
  code?: Maybe<Scalars["String"]["output"]>;
  /** in shop currency */
  discountAmountInput?: Maybe<Scalars["String"]["output"]>;
  discountPercent?: Maybe<Scalars["Int"]["output"]>;
  excludedBrandsSlugs?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
  excludedCategoryUuids?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
  id?: Maybe<Scalars["String"]["output"]>;
  /** in shop currency */
  maxDiscountAmountInput?: Maybe<Scalars["String"]["output"]>;
  /** in shop currency */
  minPurchaseAmountInput?: Maybe<Scalars["String"]["output"]>;
  unlimitedUse?: Maybe<Scalars["Boolean"]["output"]>;
  useRetroactiveSends?: Maybe<Scalars["Boolean"]["output"]>;
};

export enum core_apimessages_MyShopCampaign_CampaignType {
  AD_HOC = "AD_HOC",
  REPEAT_BUYER_COUPON = "REPEAT_BUYER_COUPON",
  UNKNOWN = "UNKNOWN",
}

export type core_apimessages_MyShopCampaignsResponse = {
  __typename?: "core_apimessages_MyShopCampaignsResponse";
  _source?: Maybe<Scalars["String"]["output"]>;
  shopCampaigns?: Maybe<Array<Maybe<core_apimessages_MyShopCampaign>>>;
};

export type core_apimessages_MyShopComparisonStats = {
  __typename?: "core_apimessages_MyShopComparisonStats";
  _source?: Maybe<Scalars["String"]["output"]>;
  changeStats?: Maybe<core_apimessages_MyShopStats>;
  currentStats?: Maybe<core_apimessages_MyShopStats>;
  previousStats?: Maybe<core_apimessages_MyShopStats>;
};

export type core_apimessages_MyShopEarningsRequest = {
  __typename?: "core_apimessages_MyShopEarningsRequest";
  _source?: Maybe<Scalars["String"]["output"]>;
  timePeriod?: Maybe<Scalars["String"]["output"]>;
};

export type core_apimessages_MyShopEarningsResponse = {
  __typename?: "core_apimessages_MyShopEarningsResponse";
  _source?: Maybe<Scalars["String"]["output"]>;
  accommodationsTotal?: Maybe<core_apimessages_Money>;
  ordersTotal?: Maybe<core_apimessages_Money>;
};

export type core_apimessages_MyShopExportDac7OrderDataRequest = {
  __typename?: "core_apimessages_MyShopExportDac7OrderDataRequest";
  _source?: Maybe<Scalars["String"]["output"]>;
  year?: Maybe<Scalars["String"]["output"]>;
};

export type core_apimessages_MyShopExportDac7OrderDataResponse = {
  __typename?: "core_apimessages_MyShopExportDac7OrderDataResponse";
  _source?: Maybe<Scalars["String"]["output"]>;
  success?: Maybe<Scalars["Boolean"]["output"]>;
};

export type core_apimessages_MyShopOnboardingResponse = {
  __typename?: "core_apimessages_MyShopOnboardingResponse";
  _source?: Maybe<Scalars["String"]["output"]>;
  billingMethodStatus?: Maybe<core_apimessages_MyShopOnboardingResponse_BillingMethodStatus>;
  originCountryCode?: Maybe<Scalars["String"]["output"]>;
  paymentMethod?: Maybe<core_apimessages_MyShopOnboardingResponse_PaymentMethod>;
  paypalSetupStatus?: Maybe<core_apimessages_MyShopOnboardingResponse_PaypalSetupStatus>;
};

export enum core_apimessages_MyShopOnboardingResponse_BillingMethodStatus {
  COMPLETED = "COMPLETED",
  NOT_REQUIRED = "NOT_REQUIRED",
  REQUIRED = "REQUIRED",
}

export enum core_apimessages_MyShopOnboardingResponse_PaymentMethod {
  NOT_SETUP = "NOT_SETUP",
  PAYPAL = "PAYPAL",
  REVERB_PAYMENTS = "REVERB_PAYMENTS",
}

export enum core_apimessages_MyShopOnboardingResponse_PaypalSetupStatus {
  PAYPAL_COMPLETED = "PAYPAL_COMPLETED",
  PAYPAL_NOT_COMPLETED = "PAYPAL_NOT_COMPLETED",
}

export type core_apimessages_MyShopOrderRefundStatsRequest = {
  __typename?: "core_apimessages_MyShopOrderRefundStatsRequest";
  _source?: Maybe<Scalars["String"]["output"]>;
  endDate?: Maybe<Scalars["String"]["output"]>;
  startDate?: Maybe<Scalars["String"]["output"]>;
};

export type core_apimessages_MyShopOrderRefundStatsResponse = {
  __typename?: "core_apimessages_MyShopOrderRefundStatsResponse";
  _source?: Maybe<Scalars["String"]["output"]>;
  sellerInitiatedRefundCount?: Maybe<Scalars["Int"]["output"]>;
};

export type core_apimessages_MyShopPaypalProfileResponse = {
  __typename?: "core_apimessages_MyShopPaypalProfileResponse";
  _source?: Maybe<Scalars["String"]["output"]>;
  paypalProfile?: Maybe<core_apimessages_MyShopPaypalProfileResponse_PaypalProfile>;
};

export type core_apimessages_MyShopPaypalProfileResponse_PaypalProfile = {
  __typename?: "core_apimessages_MyShopPaypalProfileResponse_PaypalProfile";
  _source?: Maybe<Scalars["String"]["output"]>;
  email?: Maybe<Scalars["String"]["output"]>;
};

export type core_apimessages_MyShopResponse = {
  __typename?: "core_apimessages_MyShopResponse";
  _source?: Maybe<Scalars["String"]["output"]>;
  shop?: Maybe<core_apimessages_Shop>;
};

export type core_apimessages_MyShopSalesChartData = {
  __typename?: "core_apimessages_MyShopSalesChartData";
  _source?: Maybe<Scalars["String"]["output"]>;
  currencySymbol?: Maybe<Scalars["String"]["output"]>;
  labels?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
  salesInMoreThanOneCurrency?: Maybe<Scalars["Boolean"]["output"]>;
  series?: Maybe<Array<Maybe<core_apimessages_MyShopSalesChartDataSet>>>;
};

export type core_apimessages_MyShopSalesChartDataSet = {
  __typename?: "core_apimessages_MyShopSalesChartDataSet";
  _source?: Maybe<Scalars["String"]["output"]>;
  dataSet?: Maybe<Array<Maybe<Scalars["Int"]["output"]>>>;
};

export type core_apimessages_MyShopShippingProfilesRequest = {
  __typename?: "core_apimessages_MyShopShippingProfilesRequest";
  _?: Maybe<Scalars["Boolean"]["output"]>;
  _source?: Maybe<Scalars["String"]["output"]>;
};

export type core_apimessages_MyShopShippingProfilesResponse = {
  __typename?: "core_apimessages_MyShopShippingProfilesResponse";
  _source?: Maybe<Scalars["String"]["output"]>;
  shippingProfiles?: Maybe<Array<Maybe<core_apimessages_MyShippingProfile>>>;
};

export type core_apimessages_MyShopShippingRatesRequest = {
  __typename?: "core_apimessages_MyShopShippingRatesRequest";
  _source?: Maybe<Scalars["String"]["output"]>;
  shippingProfileIds?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
};

export type core_apimessages_MyShopShippingRatesResponse = {
  __typename?: "core_apimessages_MyShopShippingRatesResponse";
  _source?: Maybe<Scalars["String"]["output"]>;
  shippingRatesForShippingProfiles?: Maybe<
    Array<Maybe<core_apimessages_ShippingRatesForShippingProfile>>
  >;
};

export type core_apimessages_MyShopStats = {
  __typename?: "core_apimessages_MyShopStats";
  _source?: Maybe<Scalars["String"]["output"]>;
  messages?: Maybe<Scalars["Int"]["output"]>;
  offers?: Maybe<Scalars["Int"]["output"]>;
  orders?: Maybe<Scalars["Int"]["output"]>;
  watchers?: Maybe<Scalars["Int"]["output"]>;
};

export type core_apimessages_MyShopStatsRequest = {
  __typename?: "core_apimessages_MyShopStatsRequest";
  _source?: Maybe<Scalars["String"]["output"]>;
  timePeriod?: Maybe<Scalars["String"]["output"]>;
};

export type core_apimessages_MyShopStatsResponse = {
  __typename?: "core_apimessages_MyShopStatsResponse";
  _source?: Maybe<Scalars["String"]["output"]>;
  comparisonStats?: Maybe<core_apimessages_MyShopComparisonStats>;
  salesChartData?: Maybe<core_apimessages_MyShopSalesChartData>;
};

export type core_apimessages_MyUpdatesPromotion = {
  __typename?: "core_apimessages_MyUpdatesPromotion";
  _source?: Maybe<Scalars["String"]["output"]>;
  id?: Maybe<Scalars["String"]["output"]>;
  startsAt?: Maybe<Scalars["Timestamp"]["output"]>;
  subtitle?: Maybe<Scalars["String"]["output"]>;
  title?: Maybe<Scalars["String"]["output"]>;
  url?: Maybe<core_apimessages_Link>;
};

export type core_apimessages_MyUserServicesResponse = {
  __typename?: "core_apimessages_MyUserServicesResponse";
  _source?: Maybe<Scalars["String"]["output"]>;
  userServices?: Maybe<Array<Maybe<core_apimessages_UserService>>>;
};

export enum core_apimessages_NamedImageTransforms {
  BANNER_4_1 = "BANNER_4_1",
  BANNER_WIDE = "BANNER_WIDE",
  CARD_SQUARE = "CARD_SQUARE",
  CARD_WIDE = "CARD_WIDE",
  LARGE = "LARGE",
  LARGE_SQUARE = "LARGE_SQUARE",
  /** deprecated but still in use by mobile clients. Use CARD_SQUARE instead. */
  MOBILE_SMALL = "MOBILE_SMALL",
  SHOP_BANNER = "SHOP_BANNER",
  SUPERSIZE = "SUPERSIZE",
  THUMB = "THUMB",
  USER_AVATAR = "USER_AVATAR",
}

export type core_apimessages_Negotiation = {
  __typename?: "core_apimessages_Negotiation";
  _source?: Maybe<Scalars["String"]["output"]>;
  /**
   * deprecated in favor of seller_shipping_warning
   * @deprecated Field no longer supported
   */
  buyerOutsideListingShippingRegions?: Maybe<Scalars["Boolean"]["output"]>;
  buyerUuid?: Maybe<Scalars["String"]["output"]>;
  buyerWillPayImmediately?: Maybe<Scalars["Boolean"]["output"]>;
  expiresAt?: Maybe<google_protobuf_Timestamp>;
  /** negotiations.id */
  id?: Maybe<Scalars["String"]["output"]>;
  lastOffer?: Maybe<core_apimessages_Offer>;
  negotiationType?: Maybe<core_apimessages_NegotiationType>;
  orderUuids?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
  sellerShippingWarning?: Maybe<Scalars["String"]["output"]>;
  sellerUuid?: Maybe<Scalars["String"]["output"]>;
  /** repeated Offer offers = 2; */
  state?: Maybe<core_apimessages_NegotiationState>;
  taxIncluded?: Maybe<Scalars["Boolean"]["output"]>;
  taxIncludedHint?: Maybe<Scalars["String"]["output"]>;
};

export enum core_apimessages_NegotiationAction {
  ACCEPT = "ACCEPT",
  COUNTER = "COUNTER",
  CREATE = "CREATE",
  REJECT = "REJECT",
}

export type core_apimessages_NegotiationOffers = {
  __typename?: "core_apimessages_NegotiationOffers";
  _source?: Maybe<Scalars["String"]["output"]>;
  offers?: Maybe<Array<Maybe<core_apimessages_Offer>>>;
};

export enum core_apimessages_NegotiationParty {
  BUYER = "BUYER",
  SELLER = "SELLER",
}

export enum core_apimessages_NegotiationState {
  ACCEPTED = "ACCEPTED",
  ACTIVE = "ACTIVE",
  EXPIRED = "EXPIRED",
  REJECTED = "REJECTED",
}

export enum core_apimessages_NegotiationType {
  AUTO_PUSH_OFFER = "AUTO_PUSH_OFFER",
  EMAIL = "EMAIL",
  STANDARD = "STANDARD",
}

export type core_apimessages_NegotiationsRequest = {
  __typename?: "core_apimessages_NegotiationsRequest";
  _source?: Maybe<Scalars["String"]["output"]>;
  /** negotiations.id */
  ids?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
};

export type core_apimessages_NegotiationsResponse = {
  __typename?: "core_apimessages_NegotiationsResponse";
  _source?: Maybe<Scalars["String"]["output"]>;
  negotiations?: Maybe<Array<Maybe<core_apimessages_Negotiation>>>;
};

export type core_apimessages_NegotiationsShippingLocationRequest = {
  __typename?: "core_apimessages_NegotiationsShippingLocationRequest";
  _source?: Maybe<Scalars["String"]["output"]>;
  /** negotiations.id */
  ids?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
};

export type core_apimessages_NegotiationsShippingLocationResponse = {
  __typename?: "core_apimessages_NegotiationsShippingLocationResponse";
  _source?: Maybe<Scalars["String"]["output"]>;
  shippingLocations?: Maybe<Array<Maybe<core_apimessages_Address>>>;
};

export type core_apimessages_NewListingSignal = {
  __typename?: "core_apimessages_NewListingSignal";
  _source?: Maybe<Scalars["String"]["output"]>;
  isValid?: Maybe<Scalars["Boolean"]["output"]>;
  translations?: Maybe<core_apimessages_SignalTranslation>;
};

export type core_apimessages_NodeRequest = {
  __typename?: "core_apimessages_NodeRequest";
  _source?: Maybe<Scalars["String"]["output"]>;
  debug?: Maybe<Scalars["Boolean"]["output"]>;
  lookups?: Maybe<Array<Maybe<core_apimessages_NodeRequest_Lookup>>>;
};

export type core_apimessages_NodeRequest_Lookup = {
  __typename?: "core_apimessages_NodeRequest_Lookup";
  _source?: Maybe<Scalars["String"]["output"]>;
  lid?: Maybe<Scalars["String"]["output"]>;
  params?: Maybe<core_apimessages_NodeRequest_Lookup_Params>;
  rel?: Maybe<Scalars["String"]["output"]>;
};

export type core_apimessages_NodeRequest_Lookup_Params = {
  __typename?: "core_apimessages_NodeRequest_Lookup_Params";
  _source?: Maybe<Scalars["String"]["output"]>;
  estimatedNewListingPrices?: Maybe<core_apimessages_NodeRequest_Lookup_Params_EstimatedNewListingPrices>;
  /** Node endpoints default to the field name that corresponds to its class name */
  images?: Maybe<core_apimessages_NodeRequest_Lookup_Params_Images>;
  listingsCertifiedPreOwned?: Maybe<core_apimessages_NodeRequest_Lookup_Params_ListingsCertifiedPreOwned>;
  listingsPricings?: Maybe<core_apimessages_NodeRequest_Lookup_Params_ListingsPricings>;
  productReviews?: Maybe<core_apimessages_NodeRequest_Lookup_Params_ProductReviews>;
  sales?: Maybe<core_apimessages_NodeRequest_Lookup_Params_Sales>;
  shippingRates?: Maybe<core_apimessages_NodeRequest_Lookup_Params_ShippingRates>;
  shopsCertifiedPreOwned?: Maybe<core_apimessages_NodeRequest_Lookup_Params_ShopsCertifiedPreOwned>;
  truliooOnboardingRecords?: Maybe<core_apimessages_NodeRequest_Lookup_Params_TruliooOnboardingRecords>;
  users?: Maybe<core_apimessages_NodeRequest_Lookup_Params_Users>;
};

export type core_apimessages_NodeRequest_Lookup_Params_EstimatedNewListingPrices =
  {
    __typename?: "core_apimessages_NodeRequest_Lookup_Params_EstimatedNewListingPrices";
    _source?: Maybe<Scalars["String"]["output"]>;
    currency?: Maybe<Scalars["String"]["output"]>;
  };

export type core_apimessages_NodeRequest_Lookup_Params_Images = {
  __typename?: "core_apimessages_NodeRequest_Lookup_Params_Images";
  _source?: Maybe<Scalars["String"]["output"]>;
  limit?: Maybe<Scalars["Int"]["output"]>;
  scope?: Maybe<core_apimessages_Image_Scope>;
  transform?: Maybe<core_apimessages_NamedImageTransforms>;
};

export type core_apimessages_NodeRequest_Lookup_Params_ListingsCertifiedPreOwned =
  {
    __typename?: "core_apimessages_NodeRequest_Lookup_Params_ListingsCertifiedPreOwned";
    _source?: Maybe<Scalars["String"]["output"]>;
    imageType?: Maybe<core_apimessages_IconImageType>;
  };

export type core_apimessages_NodeRequest_Lookup_Params_ListingsPricings = {
  __typename?: "core_apimessages_NodeRequest_Lookup_Params_ListingsPricings";
  _source?: Maybe<Scalars["String"]["output"]>;
  ribbonPercent?: Maybe<Scalars["String"]["output"]>;
  ruleOfOneHundred?: Maybe<Scalars["Boolean"]["output"]>;
};

export type core_apimessages_NodeRequest_Lookup_Params_ProductReviews = {
  __typename?: "core_apimessages_NodeRequest_Lookup_Params_ProductReviews";
  _source?: Maybe<Scalars["String"]["output"]>;
  forCurrentUser?: Maybe<Scalars["Boolean"]["output"]>;
};

export type core_apimessages_NodeRequest_Lookup_Params_Sales = {
  __typename?: "core_apimessages_NodeRequest_Lookup_Params_Sales";
  _source?: Maybe<Scalars["String"]["output"]>;
  all?: Maybe<Scalars["Boolean"]["output"]>;
};

export type core_apimessages_NodeRequest_Lookup_Params_ShippingRates = {
  __typename?: "core_apimessages_NodeRequest_Lookup_Params_ShippingRates";
  _source?: Maybe<Scalars["String"]["output"]>;
  inOriginalCurrency?: Maybe<Scalars["Boolean"]["output"]>;
};

export type core_apimessages_NodeRequest_Lookup_Params_ShopsCertifiedPreOwned =
  {
    __typename?: "core_apimessages_NodeRequest_Lookup_Params_ShopsCertifiedPreOwned";
    _source?: Maybe<Scalars["String"]["output"]>;
    imageType?: Maybe<core_apimessages_IconImageType>;
  };

export type core_apimessages_NodeRequest_Lookup_Params_TruliooOnboardingRecords =
  {
    __typename?: "core_apimessages_NodeRequest_Lookup_Params_TruliooOnboardingRecords";
    _source?: Maybe<Scalars["String"]["output"]>;
    recordType?: Maybe<core_apimessages_NodeRequest_Lookup_Params_TruliooOnboardingRecords_RecordType>;
  };

export enum core_apimessages_NodeRequest_Lookup_Params_TruliooOnboardingRecords_RecordType {
  ALL = "ALL",
  CHILDREN = "CHILDREN",
  PARENT = "PARENT",
}

export type core_apimessages_NodeRequest_Lookup_Params_Users = {
  __typename?: "core_apimessages_NodeRequest_Lookup_Params_Users";
  _source?: Maybe<Scalars["String"]["output"]>;
  fullname?: Maybe<Scalars["Boolean"]["output"]>;
};

export type core_apimessages_NodeResponse = {
  __typename?: "core_apimessages_NodeResponse";
  _source?: Maybe<Scalars["String"]["output"]>;
  results?: Maybe<Array<Maybe<core_apimessages_NodeResponse_Result>>>;
};

export type core_apimessages_NodeResponse_Result = {
  __typename?: "core_apimessages_NodeResponse_Result";
  _source?: Maybe<Scalars["String"]["output"]>;
  adCampaigns?: Maybe<Array<Maybe<core_apimessages_AdCampaign>>>;
  adGroupKeywords?: Maybe<Array<Maybe<core_apimessages_AdGroupKeyword>>>;
  adGroups?: Maybe<Array<Maybe<core_apimessages_AdGroup>>>;
  adZones?: Maybe<Array<Maybe<core_apimessages_AdZone>>>;
  addresses?: Maybe<Array<Maybe<core_apimessages_Address>>>;
  ads?: Maybe<Array<Maybe<core_apimessages_Ad>>>;
  advertisers?: Maybe<Array<Maybe<core_apimessages_Advertiser>>>;
  adyenCheckoutPaymentMethods?: Maybe<
    Array<Maybe<core_apimessages_AdyenCheckoutPaymentMethods>>
  >;
  brands?: Maybe<Array<Maybe<core_apimessages_Brand>>>;
  browsePages?: Maybe<Array<Maybe<core_apimessages_BrowsePage>>>;
  bumpRates?: Maybe<Array<Maybe<core_apimessages_BumpRate>>>;
  businessRegistrationNumbers?: Maybe<
    Array<Maybe<core_apimessages_BusinessRegistrationNumber>>
  >;
  canonicalProducts?: Maybe<Array<Maybe<core_apimessages_CanonicalProduct>>>;
  cartItems?: Maybe<Array<Maybe<core_apimessages_CartItem>>>;
  categories?: Maybe<Array<Maybe<core_apimessages_NodesCategory>>>;
  cspExpressSaleItemBids?: Maybe<
    Array<Maybe<core_apimessages_CSPExpressSaleItemBid>>
  >;
  csps?: Maybe<Array<Maybe<core_apimessages_CSP>>>;
  curatedSets?: Maybe<Array<Maybe<core_apimessages_CuratedSet>>>;
  estimatedNewListingPrices?: Maybe<
    Array<Maybe<core_apimessages_EstimatedNewListingPrice>>
  >;
  gearCollectionItems?: Maybe<
    Array<Maybe<core_apimessages_GearCollectionItem>>
  >;
  images?: Maybe<Array<Maybe<core_apimessages_Image>>>;
  informActThresholdStatuses?: Maybe<
    Array<Maybe<core_apimessages_InformActThresholdStatus>>
  >;
  internationalTaxProfiles?: Maybe<
    Array<Maybe<core_apimessages_InternationalTaxProfile>>
  >;
  /** Node endpoints default to the field name that corresponds to its class name */
  listings?: Maybe<Array<Maybe<core_apimessages_Listing>>>;
  listingsAffirmZeroPercentFinancingConfigurations?: Maybe<
    Array<
      Maybe<core_apimessages_ListingAffirmZeroPercentFinancingConfiguration>
    >
  >;
  listingsAutoOfferEligibilities?: Maybe<
    Array<Maybe<core_apimessages_ListingAutoOfferEligibility>>
  >;
  listingsBuyerOfferEligibilities?: Maybe<
    Array<Maybe<core_apimessages_ListingBuyerOfferEligibility>>
  >;
  listingsCertifiedPreOwned?: Maybe<
    Array<Maybe<core_apimessages_ListingCertifiedPreOwned>>
  >;
  listingsDigitalDetails?: Maybe<
    Array<Maybe<core_apimessages_ListingDigitalDetails>>
  >;
  listingsExpressPay?: Maybe<Array<Maybe<core_apimessages_ListingExpressPay>>>;
  listingsOfferBotRules?: Maybe<Array<Maybe<core_apimessages_OfferBotRule>>>;
  listingsPricings?: Maybe<Array<Maybe<core_apimessages_ListingPricing>>>;
  listingsProtectionPlanOptions?: Maybe<
    Array<Maybe<core_apimessages_ProtectionPlanOptions>>
  >;
  listingsSalesMemberships?: Maybe<
    Array<Maybe<core_apimessages_ListingSalesMemberships>>
  >;
  listingsSignals?: Maybe<Array<Maybe<core_apimessages_ListingSignals>>>;
  listingsUserViews?: Maybe<Array<Maybe<core_apimessages_ListingUserView>>>;
  lookup?: Maybe<core_apimessages_NodeRequest_Lookup>;
  meta?: Maybe<core_apimessages_NodeResponse_Result_Meta>;
  myUpdatesPromotions?: Maybe<
    Array<Maybe<core_apimessages_MyUpdatesPromotion>>
  >;
  nonUserExperiments?: Maybe<Array<Maybe<core_apimessages_NonUserExperiment>>>;
  orderBundles?: Maybe<Array<Maybe<core_apimessages_OrderBundle>>>;
  orders?: Maybe<Array<Maybe<core_apimessages_Order>>>;
  packageSizeSuggestions?: Maybe<
    Array<Maybe<core_apimessages_PackageSizeSuggestion>>
  >;
  packlinkShipments?: Maybe<Array<Maybe<core_apimessages_PacklinkShipment>>>;
  priceChanges?: Maybe<Array<Maybe<core_apimessages_PriceChange>>>;
  priceGuides?: Maybe<Array<Maybe<core_apimessages_PriceGuide>>>;
  productBreadcrumbs?: Maybe<Array<Maybe<core_apimessages_ProductBreadcrumb>>>;
  productReviews?: Maybe<Array<Maybe<core_apimessages_ProductReview>>>;
  publicPriceRecords?: Maybe<Array<Maybe<core_apimessages_PublicPriceRecord>>>;
  returnPolicies?: Maybe<Array<Maybe<core_apimessages_ReturnPolicy>>>;
  sales?: Maybe<Array<Maybe<core_apimessages_Sale>>>;
  shipmentPackages?: Maybe<Array<Maybe<core_apimessages_ShippingLabelPackage>>>;
  shippingRates?: Maybe<Array<Maybe<core_apimessages_ShippingRates>>>;
  shopCampaignCoupons?: Maybe<
    Array<Maybe<core_apimessages_ShopCampaignCoupon>>
  >;
  shops?: Maybe<Array<Maybe<core_apimessages_Shop>>>;
  shopsCertifiedPreOwned?: Maybe<
    Array<Maybe<core_apimessages_ShopCertifiedPreOwned>>
  >;
  shopsConfigs?: Maybe<Array<Maybe<core_apimessages_ShopConfig>>>;
  siteBanners?: Maybe<Array<Maybe<core_apimessages_SiteBanner>>>;
  taxIdentifications?: Maybe<Array<Maybe<core_apimessages_TaxIdentification>>>;
  taxProfiles?: Maybe<Array<Maybe<core_apimessages_TaxProfile>>>;
  truliooOnboardingRecords?: Maybe<
    Array<Maybe<core_apimessages_TruliooOnboardingRecord>>
  >;
  universalPromoCampaigns?: Maybe<
    Array<Maybe<core_apimessages_UniversalPromoCampaign>>
  >;
  userShopPermissions?: Maybe<
    Array<Maybe<core_apimessages_UserShopPermission>>
  >;
  users?: Maybe<Array<Maybe<core_apimessages_User>>>;
};

export type core_apimessages_NodeResponse_Result_Meta = {
  __typename?: "core_apimessages_NodeResponse_Result_Meta";
  _source?: Maybe<Scalars["String"]["output"]>;
  total?: Maybe<Scalars["Int"]["output"]>;
};

export type core_apimessages_NodesCategory = {
  __typename?: "core_apimessages_NodesCategory";
  _source?: Maybe<Scalars["String"]["output"]>;
  cmsLink?: Maybe<core_apimessages_Link>;
  cmsUrl?: Maybe<core_apimessages_Link>;
  /** categories.uuid */
  id?: Maybe<Scalars["String"]["output"]>;
  leaf?: Maybe<Scalars["Boolean"]["output"]>;
  name?: Maybe<Scalars["String"]["output"]>;
  root?: Maybe<Scalars["Boolean"]["output"]>;
  rootSlug?: Maybe<Scalars["String"]["output"]>;
  slug?: Maybe<Scalars["String"]["output"]>;
  webLink?: Maybe<core_apimessages_Link>;
};

export type core_apimessages_NonUserExperiment = {
  __typename?: "core_apimessages_NonUserExperiment";
  _source?: Maybe<Scalars["String"]["output"]>;
  bucket?: Maybe<Scalars["String"]["output"]>;
  name?: Maybe<Scalars["String"]["output"]>;
};

export enum core_apimessages_NotificationColor {
  BLUE = "BLUE",
  DEFAULT = "DEFAULT",
  GREEN = "GREEN",
  RED = "RED",
}

export type core_apimessages_Offer = {
  __typename?: "core_apimessages_Offer";
  _source?: Maybe<Scalars["String"]["output"]>;
  alerts?: Maybe<Array<Maybe<core_apimessages_OfferAlert>>>;
  counterable?: Maybe<Scalars["Boolean"]["output"]>;
  createdAt?: Maybe<google_protobuf_Timestamp>;
  initiatingParty?: Maybe<core_apimessages_NegotiationParty>;
  layawayTerms?: Maybe<core_apimessages_LayawayTerms>;
  message?: Maybe<Scalars["String"]["output"]>;
  offerItems?: Maybe<Array<Maybe<core_apimessages_OfferItem>>>;
  prices?: Maybe<core_apimessages_OfferPrices>;
  shippingMethod?: Maybe<core_apimessages_ShippingMethod>;
  status?: Maybe<core_apimessages_Offer_OfferStatus>;
};

export type core_apimessages_OfferAlert = {
  __typename?: "core_apimessages_OfferAlert";
  _source?: Maybe<Scalars["String"]["output"]>;
  body?: Maybe<Scalars["String"]["output"]>;
  level?: Maybe<core_apimessages_OfferAlert_Level>;
  title?: Maybe<Scalars["String"]["output"]>;
};

export enum core_apimessages_OfferAlert_Level {
  SUCCESS = "SUCCESS",
}

export type core_apimessages_OfferBotRule = {
  __typename?: "core_apimessages_OfferBotRule";
  _source?: Maybe<Scalars["String"]["output"]>;
  autoRejectPercentage?: Maybe<Scalars["Int"]["output"]>;
};

export type core_apimessages_OfferItem = {
  __typename?: "core_apimessages_OfferItem";
  _source?: Maybe<Scalars["String"]["output"]>;
  listing?: Maybe<Listing>;
  /** products.id */
  listingId?: Maybe<Scalars["String"]["output"]>;
  prices?: Maybe<core_apimessages_OfferPrices>;
  quantity?: Maybe<Scalars["Int"]["output"]>;
};

export type core_apimessages_OfferItemRequest = {
  __typename?: "core_apimessages_OfferItemRequest";
  _source?: Maybe<Scalars["String"]["output"]>;
  /** products.id */
  listingId?: Maybe<Scalars["String"]["output"]>;
  price?: Maybe<core_apimessages_Money>;
  quantity?: Maybe<Scalars["Int"]["output"]>;
  shippingPrice?: Maybe<core_apimessages_Money>;
};

export type core_apimessages_OfferPrices = {
  __typename?: "core_apimessages_OfferPrices";
  _source?: Maybe<Scalars["String"]["output"]>;
  price?: Maybe<core_apimessages_ConvertedMoney>;
  shippingPrice?: Maybe<core_apimessages_ConvertedMoney>;
  totalPrice?: Maybe<core_apimessages_ConvertedMoney>;
};

export enum core_apimessages_Offer_OfferStatus {
  ACCEPTED = "ACCEPTED",
  ACTIVE = "ACTIVE",
  COUNTERED = "COUNTERED",
  EXPIRED = "EXPIRED",
  REJECTED = "REJECTED",
}

export type core_apimessages_OffersRequest = {
  __typename?: "core_apimessages_OffersRequest";
  _source?: Maybe<Scalars["String"]["output"]>;
  /** negotiations.id */
  ids?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
};

export type core_apimessages_OffersResponse = {
  __typename?: "core_apimessages_OffersResponse";
  _source?: Maybe<Scalars["String"]["output"]>;
  offers?: Maybe<Array<Maybe<core_apimessages_NegotiationOffers>>>;
};

export type core_apimessages_OnSaleSignal = {
  __typename?: "core_apimessages_OnSaleSignal";
  _source?: Maybe<Scalars["String"]["output"]>;
  isValid?: Maybe<Scalars["Boolean"]["output"]>;
  link?: Maybe<core_apimessages_SignalLink>;
  translations?: Maybe<core_apimessages_SignalTranslation>;
};

export type core_apimessages_Order = {
  __typename?: "core_apimessages_Order";
  _source?: Maybe<Scalars["String"]["output"]>;
  amountProduct?: Maybe<core_apimessages_Money>;
  amountShipping?: Maybe<core_apimessages_Money>;
  amountTax?: Maybe<core_apimessages_Money>;
  amountTotal?: Maybe<core_apimessages_Money>;
  buyerUuid?: Maybe<Scalars["String"]["output"]>;
  carrierCalculatedCarrier?: Maybe<Scalars["String"]["output"]>;
  carrierCalculatedShippingAmount?: Maybe<core_apimessages_Money>;
  checkoutUuid?: Maybe<Scalars["String"]["output"]>;
  createdAt?: Maybe<google_protobuf_Timestamp>;
  /** users.id */
  legacyBuyerId?: Maybe<Scalars["String"]["output"]>;
  /** orders.id */
  legacyOrderId?: Maybe<Scalars["String"]["output"]>;
  /** products.id */
  listingId?: Maybe<Scalars["String"]["output"]>;
  mobileStatus?: Maybe<Scalars["String"]["output"]>;
  needsFeedbackForBuyer?: Maybe<Scalars["Boolean"]["output"]>;
  needsFeedbackForSeller?: Maybe<Scalars["Boolean"]["output"]>;
  orderType?: Maybe<Scalars["String"]["output"]>;
  orderUrl?: Maybe<core_apimessages_Link>;
  paidAt?: Maybe<google_protobuf_Timestamp>;
  paymentRequiredAt?: Maybe<google_protobuf_Timestamp>;
  protectionPlan?: Maybe<Scalars["Boolean"]["output"]>;
  protectionPlanEligible?: Maybe<Scalars["Boolean"]["output"]>;
  quantity?: Maybe<Scalars["Int"]["output"]>;
  sellerUuid?: Maybe<Scalars["String"]["output"]>;
  settlementAmountProductSubtotal?: Maybe<core_apimessages_Money>;
  shipmentStatus?: Maybe<Scalars["String"]["output"]>;
  shippedAt?: Maybe<google_protobuf_Timestamp>;
  shippingCode?: Maybe<Scalars["String"]["output"]>;
  shippingMethod?: Maybe<core_apimessages_ShippingMethod>;
  shippingProvider?: Maybe<Scalars["String"]["output"]>;
  shippingTaxed?: Maybe<Scalars["Boolean"]["output"]>;
  shopUuid?: Maybe<Scalars["String"]["output"]>;
  status?: Maybe<core_apimessages_Order_Status>;
  statusDescription?: Maybe<Scalars["String"]["output"]>;
  title?: Maybe<Scalars["String"]["output"]>;
  updatedAt?: Maybe<google_protobuf_Timestamp>;
  uuid?: Maybe<Scalars["String"]["output"]>;
  webTrackingUrl?: Maybe<Scalars["String"]["output"]>;
};

export type core_apimessages_OrderBundle = {
  __typename?: "core_apimessages_OrderBundle";
  _source?: Maybe<Scalars["String"]["output"]>;
  amountCredit?: Maybe<core_apimessages_Money>;
  amountProductSubtotal?: Maybe<core_apimessages_Money>;
  amountShipping?: Maybe<core_apimessages_Money>;
  amountTax?: Maybe<core_apimessages_Money>;
  amountTotal?: Maybe<core_apimessages_Money>;
  bonusBucks?: Maybe<core_apimessages_Money>;
  checkoutType?: Maybe<core_apimessages_Checkout_Type>;
  /** order_bundles.id */
  id?: Maybe<Scalars["String"]["output"]>;
  localPickup?: Maybe<Scalars["Boolean"]["output"]>;
  orderType?: Maybe<Scalars["String"]["output"]>;
  paymentMethod?: Maybe<core_apimessages_Payment_PaymentMethod>;
  /** order_bundles.checkout_uuid */
  uuid?: Maybe<Scalars["String"]["output"]>;
};

export type core_apimessages_OrderBundleShowResponse = {
  __typename?: "core_apimessages_OrderBundleShowResponse";
  _source?: Maybe<Scalars["String"]["output"]>;
  orderBundle?: Maybe<core_apimessages_OrderBundle>;
};

export type core_apimessages_OrderCustoms = {
  __typename?: "core_apimessages_OrderCustoms";
  _source?: Maybe<Scalars["String"]["output"]>;
  countryOfOrigin?: Maybe<Scalars["String"]["output"]>;
  declaredValue?: Maybe<core_apimessages_Money>;
  description?: Maybe<Scalars["String"]["output"]>;
  quantity?: Maybe<Scalars["Int"]["output"]>;
  tariffNumber?: Maybe<Scalars["String"]["output"]>;
  uuid?: Maybe<Scalars["String"]["output"]>;
};

export type core_apimessages_OrderCustomsInfo = {
  __typename?: "core_apimessages_OrderCustomsInfo";
  _source?: Maybe<Scalars["String"]["output"]>;
  categoryName?: Maybe<Scalars["String"]["output"]>;
  countryOfOrigin?: Maybe<Scalars["String"]["output"]>;
  description?: Maybe<Scalars["String"]["output"]>;
  tariffNumber?: Maybe<Scalars["String"]["output"]>;
};

export type core_apimessages_OrderFeedbacks = {
  __typename?: "core_apimessages_OrderFeedbacks";
  _source?: Maybe<Scalars["String"]["output"]>;
  feedbacks?: Maybe<Array<Maybe<core_apimessages_Feedback>>>;
  orderUuid?: Maybe<Scalars["String"]["output"]>;
};

export type core_apimessages_OrderFeedbacksRequest = {
  __typename?: "core_apimessages_OrderFeedbacksRequest";
  _source?: Maybe<Scalars["String"]["output"]>;
  orderIds?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
  orderUuids?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
};

export type core_apimessages_OrderFeedbacksResponse = {
  __typename?: "core_apimessages_OrderFeedbacksResponse";
  _source?: Maybe<Scalars["String"]["output"]>;
  orderFeedbacks?: Maybe<Array<Maybe<core_apimessages_OrderFeedbacks>>>;
};

export type core_apimessages_OrderFlatRateShippingOptionsRequest = {
  __typename?: "core_apimessages_OrderFlatRateShippingOptionsRequest";
  _source?: Maybe<Scalars["String"]["output"]>;
  checkoutUuid?: Maybe<Scalars["String"]["output"]>;
  orderUuid?: Maybe<Scalars["String"]["output"]>;
};

export type core_apimessages_OrderFlatRateShippingOptionsResponse = {
  __typename?: "core_apimessages_OrderFlatRateShippingOptionsResponse";
  _source?: Maybe<Scalars["String"]["output"]>;
  flatRateShippingOptions?: Maybe<
    Array<Maybe<core_apimessages_FlatRateShippingOption>>
  >;
};

export type core_apimessages_OrderNote = {
  __typename?: "core_apimessages_OrderNote";
  _source?: Maybe<Scalars["String"]["output"]>;
  body?: Maybe<Scalars["String"]["output"]>;
  createdAt?: Maybe<google_protobuf_Timestamp>;
  /** order_notes.id */
  id?: Maybe<Scalars["String"]["output"]>;
  /** orders.id */
  orderId?: Maybe<Scalars["String"]["output"]>;
};

export type core_apimessages_OrderNotes = {
  __typename?: "core_apimessages_OrderNotes";
  _source?: Maybe<Scalars["String"]["output"]>;
  notes?: Maybe<Array<Maybe<core_apimessages_OrderNote>>>;
};

export type core_apimessages_OrderNotesIndexRequest = {
  __typename?: "core_apimessages_OrderNotesIndexRequest";
  _source?: Maybe<Scalars["String"]["output"]>;
  orderIds?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
  orderUuids?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
};

export type core_apimessages_OrderNotesIndexResponse = {
  __typename?: "core_apimessages_OrderNotesIndexResponse";
  _source?: Maybe<Scalars["String"]["output"]>;
  orderNotes?: Maybe<Array<Maybe<core_apimessages_OrderNotes>>>;
};

/**
 *   This endpoint is not used with GRPC or with RQL, which is not recommended
 * Do not use this endpoint directly, instead query for order notes using RQL
 */
export type core_apimessages_OrderNotesRequest = {
  __typename?: "core_apimessages_OrderNotesRequest";
  _source?: Maybe<Scalars["String"]["output"]>;
  orderIds?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
};

export type core_apimessages_OrderNotesResponse = {
  __typename?: "core_apimessages_OrderNotesResponse";
  _source?: Maybe<Scalars["String"]["output"]>;
  orderNotes?: Maybe<Array<Maybe<core_apimessages_OrderNote>>>;
};

export type core_apimessages_OrderPackingSlip = {
  __typename?: "core_apimessages_OrderPackingSlip";
  _source?: Maybe<Scalars["String"]["output"]>;
  packingSlipWebUrl?: Maybe<Scalars["String"]["output"]>;
};

export type core_apimessages_OrderPayments = {
  __typename?: "core_apimessages_OrderPayments";
  _source?: Maybe<Scalars["String"]["output"]>;
  payments?: Maybe<Array<Maybe<core_apimessages_Payment>>>;
};

export type core_apimessages_OrderPaymentsRequest = {
  __typename?: "core_apimessages_OrderPaymentsRequest";
  _source?: Maybe<Scalars["String"]["output"]>;
  orderIds?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
  orderUuids?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
};

export type core_apimessages_OrderPaymentsResponse = {
  __typename?: "core_apimessages_OrderPaymentsResponse";
  _source?: Maybe<Scalars["String"]["output"]>;
  orderPayments?: Maybe<Array<Maybe<core_apimessages_OrderPayments>>>;
};

export type core_apimessages_OrderProtectionPlanDetails = {
  __typename?: "core_apimessages_OrderProtectionPlanDetails";
  _source?: Maybe<Scalars["String"]["output"]>;
  activationLink?: Maybe<core_apimessages_Link>;
  associatedCoveredProductOrderId?: Maybe<Scalars["String"]["output"]>;
  associatedProtectionPlanOrderId?: Maybe<Scalars["String"]["output"]>;
  buyerEmail?: Maybe<Scalars["String"]["output"]>;
};

export type core_apimessages_OrderRefundRequests = {
  __typename?: "core_apimessages_OrderRefundRequests";
  _source?: Maybe<Scalars["String"]["output"]>;
  refundRequests?: Maybe<Array<Maybe<core_apimessages_RefundRequest>>>;
};

export type core_apimessages_OrderRefunds = {
  __typename?: "core_apimessages_OrderRefunds";
  _source?: Maybe<Scalars["String"]["output"]>;
  completedRefunds?: Maybe<Array<Maybe<core_apimessages_Refund>>>;
  refundRequests?: Maybe<Array<Maybe<core_apimessages_RefundRequest>>>;
  refundableState?: Maybe<core_apimessages_OrderRefunds_RefundableState>;
};

export type core_apimessages_OrderRefundsRequest = {
  __typename?: "core_apimessages_OrderRefundsRequest";
  _source?: Maybe<Scalars["String"]["output"]>;
  orderIds?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
  orderUuids?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
};

export type core_apimessages_OrderRefundsResponse = {
  __typename?: "core_apimessages_OrderRefundsResponse";
  _source?: Maybe<Scalars["String"]["output"]>;
  orderRefunds?: Maybe<Array<Maybe<core_apimessages_OrderRefunds>>>;
};

export enum core_apimessages_OrderRefunds_RefundableState {
  NON_REFUNDABLE = "NON_REFUNDABLE",
  REFUNDABLE = "REFUNDABLE",
  SELLER_REFUNDABLE = "SELLER_REFUNDABLE",
}

export type core_apimessages_OrderShippingAddressesRequest = {
  __typename?: "core_apimessages_OrderShippingAddressesRequest";
  _source?: Maybe<Scalars["String"]["output"]>;
  orderIds?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
  orderUuids?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
};

export type core_apimessages_OrderShippingAddressesResponse = {
  __typename?: "core_apimessages_OrderShippingAddressesResponse";
  _source?: Maybe<Scalars["String"]["output"]>;
  addresses?: Maybe<Array<Maybe<core_apimessages_Address>>>;
};

export type core_apimessages_OrderShippingLabel = {
  __typename?: "core_apimessages_OrderShippingLabel";
  _source?: Maybe<Scalars["String"]["output"]>;
  purchaseShippingLabelWebUrl?: Maybe<Scalars["String"]["output"]>;
  shippingLabelWebUrl?: Maybe<Scalars["String"]["output"]>;
};

export type core_apimessages_OrderStats = {
  __typename?: "core_apimessages_OrderStats";
  _source?: Maybe<Scalars["String"]["output"]>;
  isViewable?: Maybe<Scalars["Boolean"]["output"]>;
  listingId?: Maybe<Scalars["String"]["output"]>;
  totalOrders?: Maybe<Scalars["Int"]["output"]>;
};

export type core_apimessages_OrderVerifyShippingAddressRequest = {
  __typename?: "core_apimessages_OrderVerifyShippingAddressRequest";
  _source?: Maybe<Scalars["String"]["output"]>;
  checkoutUuid?: Maybe<Scalars["String"]["output"]>;
  orderUuid?: Maybe<Scalars["String"]["output"]>;
};

export type core_apimessages_OrderVerifyShippingAddressResponse = {
  __typename?: "core_apimessages_OrderVerifyShippingAddressResponse";
  _source?: Maybe<Scalars["String"]["output"]>;
  address?: Maybe<core_apimessages_Address>;
  errorMessage?: Maybe<Scalars["String"]["output"]>;
  isResidential?: Maybe<Scalars["Boolean"]["output"]>;
  verificationStatus?: Maybe<core_apimessages_AddressVerificationStatus>;
};

export type core_apimessages_OrderViewStatus = {
  __typename?: "core_apimessages_OrderViewStatus";
  _source?: Maybe<Scalars["String"]["output"]>;
  description?: Maybe<Scalars["String"]["output"]>;
  status?: Maybe<Scalars["String"]["output"]>;
};

export enum core_apimessages_Order_Status {
  CANCELLED = "CANCELLED",
  LAYAWAY_FORFEITED = "LAYAWAY_FORFEITED",
  PAID = "PAID",
  PARTIALLY_PAID = "PARTIALLY_PAID",
  PAYMENT_PENDING = "PAYMENT_PENDING",
  PICKED_UP = "PICKED_UP",
  PRESUMED_RECEIVED = "PRESUMED_RECEIVED",
  RECEIVED = "RECEIVED",
  REFUNDED = "REFUNDED",
  SHIPPED = "SHIPPED",
  UNPAID = "UNPAID",
}

export type core_apimessages_OrdersAddressRequest = {
  __typename?: "core_apimessages_OrdersAddressRequest";
  _source?: Maybe<Scalars["String"]["output"]>;
  addressType?: Maybe<Scalars["String"]["output"]>;
  uuids?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
};

export type core_apimessages_OrdersAddressResponse = {
  __typename?: "core_apimessages_OrdersAddressResponse";
  _source?: Maybe<Scalars["String"]["output"]>;
  addresses?: Maybe<Array<Maybe<core_apimessages_Address>>>;
};

export type core_apimessages_OrdersCustomsInfoRequest = {
  __typename?: "core_apimessages_OrdersCustomsInfoRequest";
  _source?: Maybe<Scalars["String"]["output"]>;
  ids?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
  uuids?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
};

export type core_apimessages_OrdersCustomsInfoResponse = {
  __typename?: "core_apimessages_OrdersCustomsInfoResponse";
  _source?: Maybe<Scalars["String"]["output"]>;
  ordersCustomsInfo?: Maybe<Array<Maybe<core_apimessages_OrderCustomsInfo>>>;
};

export type core_apimessages_OrdersRequest = {
  __typename?: "core_apimessages_OrdersRequest";
  _source?: Maybe<Scalars["String"]["output"]>;
  ids?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
  uuids?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
};

export type core_apimessages_OrdersResponse = {
  __typename?: "core_apimessages_OrdersResponse";
  _source?: Maybe<Scalars["String"]["output"]>;
  orders?: Maybe<Array<Maybe<core_apimessages_Order>>>;
};

export type core_apimessages_OtherBuyersWithListingInCartCountsRequest = {
  __typename?: "core_apimessages_OtherBuyersWithListingInCartCountsRequest";
  _source?: Maybe<Scalars["String"]["output"]>;
  /** product.id */
  ids?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
};

export type core_apimessages_OtherBuyersWithListingInCartCountsResponse = {
  __typename?: "core_apimessages_OtherBuyersWithListingInCartCountsResponse";
  _source?: Maybe<Scalars["String"]["output"]>;
  listingInCartCount?: Maybe<Array<Maybe<core_apimessages_ListingInCartCount>>>;
};

export type core_apimessages_PackageSizeSuggestion = {
  __typename?: "core_apimessages_PackageSizeSuggestion";
  _source?: Maybe<Scalars["String"]["output"]>;
  height?: Maybe<core_apimessages_Measurement>;
  id?: Maybe<Scalars["String"]["output"]>;
  length?: Maybe<core_apimessages_Measurement>;
  weight?: Maybe<core_apimessages_Measurement>;
  width?: Maybe<core_apimessages_Measurement>;
};

export type core_apimessages_PacklinkAccount = {
  __typename?: "core_apimessages_PacklinkAccount";
  _source?: Maybe<Scalars["String"]["output"]>;
  email?: Maybe<Scalars["String"]["output"]>;
  id?: Maybe<Scalars["String"]["output"]>;
  status?: Maybe<core_apimessages_PacklinkAccount_Status>;
};

export enum core_apimessages_PacklinkAccount_Status {
  COMPLETED = "COMPLETED",
  FAILED = "FAILED",
  PENDING = "PENDING",
}

export type core_apimessages_PacklinkPolicies = {
  __typename?: "core_apimessages_PacklinkPolicies";
  _source?: Maybe<Scalars["String"]["output"]>;
  dataProcessing?: Maybe<Scalars["Boolean"]["output"]>;
  marketingCalls?: Maybe<Scalars["Boolean"]["output"]>;
  marketingEmails?: Maybe<Scalars["Boolean"]["output"]>;
  termsAndConditions?: Maybe<Scalars["Boolean"]["output"]>;
};

export type core_apimessages_PacklinkShipment = {
  __typename?: "core_apimessages_PacklinkShipment";
  _source?: Maybe<Scalars["String"]["output"]>;
  id?: Maybe<Scalars["String"]["output"]>;
  orderId?: Maybe<Scalars["String"]["output"]>;
  packlinkAccountId?: Maybe<Scalars["String"]["output"]>;
  reference?: Maybe<Scalars["String"]["output"]>;
  status?: Maybe<core_apimessages_PacklinkShipment_Status>;
};

export enum core_apimessages_PacklinkShipment_Status {
  FAILED = "FAILED",
  PENDING = "PENDING",
  SUCCESS = "SUCCESS",
}

export type core_apimessages_PayMyStatementRequest = {
  __typename?: "core_apimessages_PayMyStatementRequest";
  _source?: Maybe<Scalars["String"]["output"]>;
  creditCardId?: Maybe<Scalars["String"]["output"]>;
  payment?: Maybe<core_apimessages_MoneyMajorUnitsEntry>;
  statementId?: Maybe<Scalars["String"]["output"]>;
};

export type core_apimessages_PayMyStatementResponse = {
  __typename?: "core_apimessages_PayMyStatementResponse";
  _source?: Maybe<Scalars["String"]["output"]>;
  amountPaidCents?: Maybe<Scalars["Int"]["output"]>;
  statementBalance?: Maybe<Scalars["Int"]["output"]>;
  statementId?: Maybe<Scalars["String"]["output"]>;
};

export type core_apimessages_Payment = {
  __typename?: "core_apimessages_Payment";
  _source?: Maybe<Scalars["String"]["output"]>;
  amount?: Maybe<core_apimessages_Money>;
  paymentMethod?: Maybe<core_apimessages_Payment_PaymentMethod>;
  paymentStatus?: Maybe<core_apimessages_Payment_PaymentStatus>;
};

/** Card-shaped icon for a payment method */
export type core_apimessages_PaymentCardIcon = {
  __typename?: "core_apimessages_PaymentCardIcon";
  _source?: Maybe<Scalars["String"]["output"]>;
  description?: Maybe<Scalars["String"]["output"]>;
  slug?: Maybe<Scalars["String"]["output"]>;
  url?: Maybe<Scalars["String"]["output"]>;
};

export type core_apimessages_PaymentMethods = {
  __typename?: "core_apimessages_PaymentMethods";
  _source?: Maybe<Scalars["String"]["output"]>;
  acceptedPaymentMethods?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
  /** either shops.uuid or products.id */
  itemId?: Maybe<Scalars["String"]["output"]>;
  itemType?: Maybe<Scalars["String"]["output"]>;
};

export type core_apimessages_PaymentPlanSignal = {
  __typename?: "core_apimessages_PaymentPlanSignal";
  _source?: Maybe<Scalars["String"]["output"]>;
  isValid?: Maybe<Scalars["Boolean"]["output"]>;
  translations?: Maybe<core_apimessages_SignalTranslation>;
};

export enum core_apimessages_Payment_PaymentMethod {
  AFFIRM = "AFFIRM",
  ANDROID_PAY = "ANDROID_PAY",
  APPLE_PAY = "APPLE_PAY",
  BANK_WIRE = "BANK_WIRE",
  CASH = "CASH",
  CHECK = "CHECK",
  CREDIT_CARD = "CREDIT_CARD",
  DC_VIA_PAYPAL = "DC_VIA_PAYPAL",
  DIRECT_CHECKOUT = "DIRECT_CHECKOUT",
  ESCROW_COM = "ESCROW_COM",
  KLARNA_ACCOUNT = "KLARNA_ACCOUNT",
  MONEY_ORDER = "MONEY_ORDER",
  OTHER = "OTHER",
  PAYPAL = "PAYPAL",
  PAYPAL_MANUAL = "PAYPAL_MANUAL",
  PAYPAL_PAY_LATER = "PAYPAL_PAY_LATER",
  SOFORT = "SOFORT",
}

export enum core_apimessages_Payment_PaymentStatus {
  AUTHORIZED = "AUTHORIZED",
  CANCELLED = "CANCELLED",
  COMPLETED = "COMPLETED",
  FAILED = "FAILED",
  NONE = "NONE",
  PENDING = "PENDING",
  REFUNDED = "REFUNDED",
}

export type core_apimessages_PaypalOnboardSellerRequest = {
  __typename?: "core_apimessages_PaypalOnboardSellerRequest";
  _?: Maybe<Scalars["Boolean"]["output"]>;
  _source?: Maybe<Scalars["String"]["output"]>;
};

export type core_apimessages_PaypalOnboardSellerResponse = {
  __typename?: "core_apimessages_PaypalOnboardSellerResponse";
  _source?: Maybe<Scalars["String"]["output"]>;
  actionUrl?: Maybe<Scalars["String"]["output"]>;
  /** paypal_profiles.id */
  id?: Maybe<Scalars["String"]["output"]>;
};

export type core_apimessages_PredictedToSellSoonSignal = {
  __typename?: "core_apimessages_PredictedToSellSoonSignal";
  _source?: Maybe<Scalars["String"]["output"]>;
  isValid?: Maybe<Scalars["Boolean"]["output"]>;
  translations?: Maybe<core_apimessages_SignalTranslation>;
};

export type core_apimessages_PreorderInfo = {
  __typename?: "core_apimessages_PreorderInfo";
  _source?: Maybe<Scalars["String"]["output"]>;
  estimatedShipDate?: Maybe<google_protobuf_Timestamp>;
  /** preorder_infos.id */
  id?: Maybe<Scalars["String"]["output"]>;
  leadTimeDays?: Maybe<Scalars["Int"]["output"]>;
  onPreorder?: Maybe<Scalars["Boolean"]["output"]>;
  shipDate?: Maybe<google_protobuf_Timestamp>;
};

export type core_apimessages_PreorderInfoEntry = {
  __typename?: "core_apimessages_PreorderInfoEntry";
  _source?: Maybe<Scalars["String"]["output"]>;
  leadTimeDays?: Maybe<Scalars["Int"]["output"]>;
  leadTimeUnit?: Maybe<core_apimessages_PreorderInfoEntry_LeadTimeUnit>;
  shipDate?: Maybe<Scalars["String"]["output"]>;
  timeType?: Maybe<core_apimessages_PreorderInfoEntry_TimeType>;
};

export enum core_apimessages_PreorderInfoEntry_LeadTimeUnit {
  DAYS = "DAYS",
  WEEKS = "WEEKS",
}

export enum core_apimessages_PreorderInfoEntry_TimeType {
  LEAD_TIME = "LEAD_TIME",
  SHIP_DATE = "SHIP_DATE",
}

export type core_apimessages_PreorderInfoRequest = {
  __typename?: "core_apimessages_PreorderInfoRequest";
  _source?: Maybe<Scalars["String"]["output"]>;
  listingIds?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
};

export type core_apimessages_PreorderInfoResponse = {
  __typename?: "core_apimessages_PreorderInfoResponse";
  _source?: Maybe<Scalars["String"]["output"]>;
  preorderInfo?: Maybe<Array<Maybe<core_apimessages_PreorderInfo>>>;
};

export type core_apimessages_PrepublishStatusResponse = {
  __typename?: "core_apimessages_PrepublishStatusResponse";
  _source?: Maybe<Scalars["String"]["output"]>;
  nextStep?: Maybe<core_apimessages_PrepublishStatusResponse_PrepublishStatus>;
  nextSteps?: Maybe<
    Array<Maybe<core_apimessages_PrepublishStatusResponse_PrepublishStatus>>
  >;
};

export enum core_apimessages_PrepublishStatusResponse_PrepublishStatus {
  BILLING_METHOD_REQUIRED = "BILLING_METHOD_REQUIRED",
  PAYMENT_POLICY_REQUIRED = "PAYMENT_POLICY_REQUIRED",
  PROGRESSIVE_ONBOARDING_REQUIRED = "PROGRESSIVE_ONBOARDING_REQUIRED",
  READY_FOR_PUBLISH = "READY_FOR_PUBLISH",
  RETURN_POLICY_REQUIRED = "RETURN_POLICY_REQUIRED",
  SELLER_VERIFICATION_REQUIRED = "SELLER_VERIFICATION_REQUIRED",
  UNKNOWN = "UNKNOWN",
}

export type core_apimessages_PriceChange = {
  __typename?: "core_apimessages_PriceChange";
  _source?: Maybe<Scalars["String"]["output"]>;
  currentPriceDropPercent?: Maybe<Scalars["Int"]["output"]>;
  id?: Maybe<Scalars["String"]["output"]>;
  listingId?: Maybe<Scalars["String"]["output"]>;
  newPrice?: Maybe<core_apimessages_Money>;
  previousPrice?: Maybe<core_apimessages_Money>;
  totalPriceDropPercent?: Maybe<Scalars["Int"]["output"]>;
};

export type core_apimessages_PriceDropSignal = {
  __typename?: "core_apimessages_PriceDropSignal";
  _source?: Maybe<Scalars["String"]["output"]>;
  isValid?: Maybe<Scalars["Boolean"]["output"]>;
  translations?: Maybe<core_apimessages_SignalTranslation>;
};

export type core_apimessages_PriceGuide = {
  __typename?: "core_apimessages_PriceGuide";
  _source?: Maybe<Scalars["String"]["output"]>;
  /** price_guides.id */
  id?: Maybe<Scalars["String"]["output"]>;
  priceHigh?: Maybe<core_apimessages_Money>;
  priceLow?: Maybe<core_apimessages_Money>;
  title?: Maybe<Scalars["String"]["output"]>;
  uuid?: Maybe<Scalars["String"]["output"]>;
  webLink?: Maybe<core_apimessages_Link>;
};

export type core_apimessages_PriceRecommendation = {
  __typename?: "core_apimessages_PriceRecommendation";
  _source?: Maybe<Scalars["String"]["output"]>;
  canonicalProductId?: Maybe<Scalars["String"]["output"]>;
  comparisonShoppingPageId?: Maybe<Scalars["String"]["output"]>;
  conditionUuid?: Maybe<Scalars["String"]["output"]>;
  countryCode?: Maybe<Scalars["String"]["output"]>;
  priceHigh?: Maybe<core_apimessages_Money>;
  priceHighThirtyDaysAgo?: Maybe<core_apimessages_Money>;
  priceLow?: Maybe<core_apimessages_Money>;
  priceLowThirtyDaysAgo?: Maybe<core_apimessages_Money>;
  priceMiddle?: Maybe<core_apimessages_Money>;
  priceMiddleThirtyDaysAgo?: Maybe<core_apimessages_Money>;
};

export type core_apimessages_PrimaryKey = {
  __typename?: "core_apimessages_PrimaryKey";
  _source?: Maybe<Scalars["String"]["output"]>;
  type?: Maybe<core_apimessages_PrimaryKey_Type>;
  value?: Maybe<Scalars["String"]["output"]>;
};

export enum core_apimessages_PrimaryKey_Type {
  ID = "ID",
  SLUG = "SLUG",
  UNKNOWN = "UNKNOWN",
  UUID = "UUID",
}

export type core_apimessages_ProcessApprovedPaypalOrderRequest = {
  __typename?: "core_apimessages_ProcessApprovedPaypalOrderRequest";
  _source?: Maybe<Scalars["String"]["output"]>;
  /** checkout UUID */
  id?: Maybe<Scalars["String"]["output"]>;
};

export type core_apimessages_ProcessApprovedPaypalOrderResponse = {
  __typename?: "core_apimessages_ProcessApprovedPaypalOrderResponse";
  _source?: Maybe<Scalars["String"]["output"]>;
  checkout?: Maybe<Checkout>;
  /** checkout UUID */
  id?: Maybe<Scalars["String"]["output"]>;
};

export type core_apimessages_ProcessingFees = {
  __typename?: "core_apimessages_ProcessingFees";
  _source?: Maybe<Scalars["String"]["output"]>;
  flatFee?: Maybe<core_apimessages_Money>;
  percent?: Maybe<Scalars["String"]["output"]>;
};

export type core_apimessages_ProductBreadcrumb = {
  __typename?: "core_apimessages_ProductBreadcrumb";
  _source?: Maybe<Scalars["String"]["output"]>;
  title?: Maybe<Scalars["String"]["output"]>;
  url?: Maybe<Scalars["String"]["output"]>;
};

export type core_apimessages_ProductReview = {
  __typename?: "core_apimessages_ProductReview";
  _source?: Maybe<Scalars["String"]["output"]>;
  body?: Maybe<Scalars["String"]["output"]>;
  createdAt?: Maybe<Scalars["Timestamp"]["output"]>;
  id?: Maybe<Scalars["String"]["output"]>;
  isMyReview?: Maybe<Scalars["Boolean"]["output"]>;
  rating?: Maybe<Scalars["Int"]["output"]>;
  reported?: Maybe<Scalars["Boolean"]["output"]>;
  reviewer?: Maybe<core_apimessages_Reviewer>;
  title?: Maybe<Scalars["String"]["output"]>;
  verified?: Maybe<Scalars["Boolean"]["output"]>;
  voteCount?: Maybe<Scalars["Int"]["output"]>;
  voted?: Maybe<Scalars["Boolean"]["output"]>;
};

export type core_apimessages_ProductReviewVoteRequest = {
  __typename?: "core_apimessages_ProductReviewVoteRequest";
  _source?: Maybe<Scalars["String"]["output"]>;
  id?: Maybe<Scalars["String"]["output"]>;
};

export type core_apimessages_ProductReviewVoteResponse = {
  __typename?: "core_apimessages_ProductReviewVoteResponse";
  _source?: Maybe<Scalars["String"]["output"]>;
  id?: Maybe<Scalars["String"]["output"]>;
};

export type core_apimessages_ProductSpec = {
  __typename?: "core_apimessages_ProductSpec";
  _id?: Maybe<Scalars["ID"]["output"]>;
  _source?: Maybe<Scalars["String"]["output"]>;
  id?: Maybe<Scalars["String"]["output"]>;
  name?: Maybe<Scalars["String"]["output"]>;
  path?: Maybe<Scalars["String"]["output"]>;
  slug?: Maybe<Scalars["String"]["output"]>;
  type?: Maybe<core_apimessages_ProductSpec_Type>;
  value?: Maybe<Scalars["String"]["output"]>;
};

export enum core_apimessages_ProductSpec_Type {
  BRAND = "BRAND",
  CATEGORY = "CATEGORY",
  CONDITION = "CONDITION",
  COUNTRY_OF_ORIGIN = "COUNTRY_OF_ORIGIN",
  FINISH = "FINISH",
  MODEL = "MODEL",
  TRAIT = "TRAIT",
  YEAR = "YEAR",
}

export type core_apimessages_ProtectionPlan = {
  __typename?: "core_apimessages_ProtectionPlan";
  _source?: Maybe<Scalars["String"]["output"]>;
  amount?: Maybe<core_apimessages_Money>;
  condensedTitle?: Maybe<Scalars["String"]["output"]>;
  durationMonths?: Maybe<Scalars["String"]["output"]>;
  planCoverageDetails?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
  planId?: Maybe<Scalars["String"]["output"]>;
  termsUrl?: Maybe<Scalars["String"]["output"]>;
  title?: Maybe<Scalars["String"]["output"]>;
};

export type core_apimessages_ProtectionPlanOptions = {
  __typename?: "core_apimessages_ProtectionPlanOptions";
  _source?: Maybe<Scalars["String"]["output"]>;
  availablePlans?: Maybe<Array<Maybe<core_apimessages_ProtectionPlan>>>;
  coverageDetailsSubheader?: Maybe<Scalars["String"]["output"]>;
  protectionHighlights?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
  protectionPlanCalloutTitle?: Maybe<Scalars["String"]["output"]>;
};

export type core_apimessages_PublicPriceRecord = {
  __typename?: "core_apimessages_PublicPriceRecord";
  _source?: Maybe<Scalars["String"]["output"]>;
  amountProduct?: Maybe<core_apimessages_Money>;
  amountShipping?: Maybe<core_apimessages_Money>;
  amountTax?: Maybe<core_apimessages_Money>;
  amountTotal?: Maybe<core_apimessages_Money>;
  condition?: Maybe<core_apimessages_Condition>;
  createdAt?: Maybe<google_protobuf_Timestamp>;
  listingId?: Maybe<Scalars["String"]["output"]>;
  orderType?: Maybe<Scalars["String"]["output"]>;
  settlementAmountProductSubtotal?: Maybe<core_apimessages_Money>;
  uuid?: Maybe<Scalars["String"]["output"]>;
};

export type core_apimessages_PublishAllListingsResponse = {
  __typename?: "core_apimessages_PublishAllListingsResponse";
  _source?: Maybe<Scalars["String"]["output"]>;
  enqueued?: Maybe<Scalars["Boolean"]["output"]>;
  message?: Maybe<Scalars["String"]["output"]>;
  prepublishStepRequired?: Maybe<core_apimessages_PublishAllListingsResponse_PrepublishStepRequired>;
};

export type core_apimessages_PublishAllListingsResponse_PrepublishStepRequired =
  {
    __typename?: "core_apimessages_PublishAllListingsResponse_PrepublishStepRequired";
    _source?: Maybe<Scalars["String"]["output"]>;
    step?: Maybe<Scalars["String"]["output"]>;
  };

export type core_apimessages_PurchaseShippingRateRequest = {
  __typename?: "core_apimessages_PurchaseShippingRateRequest";
  _source?: Maybe<Scalars["String"]["output"]>;
  acceptedCarrierTerms?: Maybe<Scalars["Boolean"]["output"]>;
  rateUuid?: Maybe<Scalars["String"]["output"]>;
  shipmentUuid?: Maybe<Scalars["String"]["output"]>;
};

export type core_apimessages_PurchaseShippingRateResponse = {
  __typename?: "core_apimessages_PurchaseShippingRateResponse";
  _source?: Maybe<Scalars["String"]["output"]>;
  errors?: Maybe<Array<Maybe<core_apimessages_Error>>>;
};

export type core_apimessages_RareGearSignal = {
  __typename?: "core_apimessages_RareGearSignal";
  _source?: Maybe<Scalars["String"]["output"]>;
  isValid?: Maybe<Scalars["Boolean"]["output"]>;
  translations?: Maybe<core_apimessages_SignalTranslation>;
};

export type core_apimessages_RecentSearchesResponse = {
  __typename?: "core_apimessages_RecentSearchesResponse";
  _source?: Maybe<Scalars["String"]["output"]>;
  queries?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
};

export type core_apimessages_RecentlyViewedListingsRequest = {
  __typename?: "core_apimessages_RecentlyViewedListingsRequest";
  _source?: Maybe<Scalars["String"]["output"]>;
  limit?: Maybe<Scalars["Int"]["output"]>;
};

export type core_apimessages_RecentlyViewedListingsResponse = {
  __typename?: "core_apimessages_RecentlyViewedListingsResponse";
  _source?: Maybe<Scalars["String"]["output"]>;
  listingIds?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
  listings?: Maybe<Array<Maybe<Listing>>>;
};

export type core_apimessages_Recommendation = {
  __typename?: "core_apimessages_Recommendation";
  _source?: Maybe<Scalars["String"]["output"]>;
  id?: Maybe<Scalars["String"]["output"]>;
  type?: Maybe<core_apimessages_Recommendation_RecommendationType>;
};

export enum core_apimessages_Recommendation_RecommendationType {
  CANONICAL_PRODUCT = "CANONICAL_PRODUCT",
}

export type core_apimessages_RecommendationsRequest = {
  __typename?: "core_apimessages_RecommendationsRequest";
  _source?: Maybe<Scalars["String"]["output"]>;
  cpIds?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
  limit?: Maybe<Scalars["Int"]["output"]>;
  listingIds?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
  multiClientExperiments?: Maybe<Array<Maybe<core_apimessages_ExperimentData>>>;
};

export type core_apimessages_RecommendationsResponse = {
  __typename?: "core_apimessages_RecommendationsResponse";
  _source?: Maybe<Scalars["String"]["output"]>;
  recommendationEngineInputIds?: Maybe<
    Array<Maybe<Scalars["String"]["output"]>>
  >;
  recommendationEngineOutputIds?: Maybe<
    Array<Maybe<Scalars["String"]["output"]>>
  >;
  recommendations?: Maybe<Array<Maybe<core_apimessages_Recommendation>>>;
};

export type core_apimessages_RedeemPromotionalCodeRequest = {
  __typename?: "core_apimessages_RedeemPromotionalCodeRequest";
  _source?: Maybe<Scalars["String"]["output"]>;
  token?: Maybe<Scalars["String"]["output"]>;
};

export type core_apimessages_RedeemPromotionalCodeResponse = {
  __typename?: "core_apimessages_RedeemPromotionalCodeResponse";
  _source?: Maybe<Scalars["String"]["output"]>;
  message?: Maybe<Scalars["String"]["output"]>;
};

export type core_apimessages_ReferAFriend = {
  __typename?: "core_apimessages_ReferAFriend";
  _source?: Maybe<Scalars["String"]["output"]>;
  minOrderAmount?: Maybe<core_apimessages_Money>;
  rewardAmount?: Maybe<core_apimessages_Money>;
  shareUrl?: Maybe<Scalars["String"]["output"]>;
  signUpBonus?: Maybe<core_apimessages_Money>;
};

export type core_apimessages_ReferAFriendResponse = {
  __typename?: "core_apimessages_ReferAFriendResponse";
  _source?: Maybe<Scalars["String"]["output"]>;
  referAFriend?: Maybe<core_apimessages_ReferAFriend>;
};

export type core_apimessages_Refund = {
  __typename?: "core_apimessages_Refund";
  _source?: Maybe<Scalars["String"]["output"]>;
  amount?: Maybe<core_apimessages_ConvertedMoney>;
  buyerRefund?: Maybe<core_apimessages_BuyerRefund>;
  createdAt?: Maybe<google_protobuf_Timestamp>;
  id?: Maybe<Scalars["String"]["output"]>;
  noteToBuyer?: Maybe<Scalars["String"]["output"]>;
  updatedAt?: Maybe<google_protobuf_Timestamp>;
};

export type core_apimessages_RefundRequest = {
  __typename?: "core_apimessages_RefundRequest";
  _source?: Maybe<Scalars["String"]["output"]>;
  amount?: Maybe<core_apimessages_Money>;
  createdAt?: Maybe<google_protobuf_Timestamp>;
  id?: Maybe<Scalars["String"]["output"]>;
  markItemReturnedWebUrl?: Maybe<Scalars["String"]["output"]>;
  respondToRequestWebUrl?: Maybe<Scalars["String"]["output"]>;
  state?: Maybe<core_apimessages_RefundRequest_RefundState>;
  type?: Maybe<core_apimessages_RefundRequest_RefundType>;
  updatedAt?: Maybe<google_protobuf_Timestamp>;
};

export enum core_apimessages_RefundRequest_RefundState {
  APPROVED = "APPROVED",
  CANCELLED = "CANCELLED",
  CONDITIONALLY_APPROVED = "CONDITIONALLY_APPROVED",
  DENIED = "DENIED",
  PENDING = "PENDING",
  PENDING_SELLER_RESPONSE = "PENDING_SELLER_RESPONSE",
}

export enum core_apimessages_RefundRequest_RefundType {
  FULL = "FULL",
  PARTIAL = "PARTIAL",
}

export type core_apimessages_RegionalShippingRate = {
  __typename?: "core_apimessages_RegionalShippingRate";
  _source?: Maybe<Scalars["String"]["output"]>;
  areas?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
  rate?: Maybe<core_apimessages_Money>;
};

export type core_apimessages_RegisterMyPacklinkAccountRequest = {
  __typename?: "core_apimessages_RegisterMyPacklinkAccountRequest";
  _source?: Maybe<Scalars["String"]["output"]>;
  email?: Maybe<Scalars["String"]["output"]>;
  password?: Maybe<Scalars["String"]["output"]>;
  policies?: Maybe<core_apimessages_PacklinkPolicies>;
};

export type core_apimessages_RegisterMyPacklinkAccountResponse = {
  __typename?: "core_apimessages_RegisterMyPacklinkAccountResponse";
  _source?: Maybe<Scalars["String"]["output"]>;
  shopId?: Maybe<Scalars["String"]["output"]>;
};

export type core_apimessages_RequestAccountDeletionRequest = {
  __typename?: "core_apimessages_RequestAccountDeletionRequest";
  _source?: Maybe<Scalars["String"]["output"]>;
  reason?: Maybe<Scalars["String"]["output"]>;
};

export type core_apimessages_RequestAccountDeletionResponse = {
  __typename?: "core_apimessages_RequestAccountDeletionResponse";
  _source?: Maybe<Scalars["String"]["output"]>;
  uuid?: Maybe<Scalars["String"]["output"]>;
};

export type core_apimessages_RequestTaxFormDownloadRequest = {
  __typename?: "core_apimessages_RequestTaxFormDownloadRequest";
  _source?: Maybe<Scalars["String"]["output"]>;
  taxFormId?: Maybe<Scalars["String"]["output"]>;
};

export type core_apimessages_RequestTaxFormDownloadResponse = {
  __typename?: "core_apimessages_RequestTaxFormDownloadResponse";
  _source?: Maybe<Scalars["String"]["output"]>;
  error?: Maybe<Scalars["String"]["output"]>;
  taxFormDownload?: Maybe<core_apimessages_TaxFormDownload>;
};

export type core_apimessages_ReturnPolicy = {
  __typename?: "core_apimessages_ReturnPolicy";
  _id?: Maybe<Scalars["ID"]["output"]>;
  _source?: Maybe<Scalars["String"]["output"]>;
  id?: Maybe<Scalars["String"]["output"]>;
  legacyReturnPolicyText?: Maybe<Scalars["String"]["output"]>;
  newReturnWindowDays?: Maybe<Scalars["Int"]["output"]>;
  restockingFeePercent?: Maybe<Scalars["Int"]["output"]>;
  specialConditions?: Maybe<Scalars["String"]["output"]>;
  usedReturnWindowDays?: Maybe<Scalars["Int"]["output"]>;
};

export type core_apimessages_ReturnPolicySignal = {
  __typename?: "core_apimessages_ReturnPolicySignal";
  _source?: Maybe<Scalars["String"]["output"]>;
  isValid?: Maybe<Scalars["Boolean"]["output"]>;
  link?: Maybe<core_apimessages_SignalLink>;
  translations?: Maybe<core_apimessages_SignalTranslation>;
};

export type core_apimessages_ReverifyCreditCardRequest = {
  __typename?: "core_apimessages_ReverifyCreditCardRequest";
  _source?: Maybe<Scalars["String"]["output"]>;
  creditCardId?: Maybe<Scalars["String"]["output"]>;
  tokenizedFields?: Maybe<Scalars["String"]["output"]>;
};

export type core_apimessages_ReverifyCreditCardResponse = {
  __typename?: "core_apimessages_ReverifyCreditCardResponse";
  _source?: Maybe<Scalars["String"]["output"]>;
  creditCard?: Maybe<core_apimessages_CreditCard>;
};

export type core_apimessages_Reviewer = {
  __typename?: "core_apimessages_Reviewer";
  _source?: Maybe<Scalars["String"]["output"]>;
  links?: Maybe<core_apimessages_ReviewerLinks>;
  shortname?: Maybe<Scalars["String"]["output"]>;
};

export type core_apimessages_ReviewerLinks = {
  __typename?: "core_apimessages_ReviewerLinks";
  _source?: Maybe<Scalars["String"]["output"]>;
  avatar?: Maybe<core_apimessages_Link>;
};

export type core_apimessages_Ribbon = {
  __typename?: "core_apimessages_Ribbon";
  _source?: Maybe<Scalars["String"]["output"]>;
  display?: Maybe<Scalars["String"]["output"]>;
  displayReason?: Maybe<Scalars["String"]["output"]>;
  reason?: Maybe<core_apimessages_Ribbon_Reason>;
};

export enum core_apimessages_Ribbon_Reason {
  PRICE_DROP = "PRICE_DROP",
  SALE = "SALE",
}

export type core_apimessages_Sale = {
  __typename?: "core_apimessages_Sale";
  _id?: Maybe<Scalars["ID"]["output"]>;
  _source?: Maybe<Scalars["String"]["output"]>;
  buyerIneligibilityReason?: Maybe<Scalars["String"]["output"]>;
  code?: Maybe<Scalars["String"]["output"]>;
  discountValue?: Maybe<Scalars["String"]["output"]>;
  display?: Maybe<Scalars["String"]["output"]>;
  endsAt?: Maybe<google_protobuf_Timestamp>;
  /** sales.id */
  id?: Maybe<Scalars["String"]["output"]>;
  links?: Maybe<core_apimessages_SaleLinks>;
  name?: Maybe<Scalars["String"]["output"]>;
  percent?: Maybe<Scalars["Int"]["output"]>;
  saleType?: Maybe<Scalars["String"]["output"]>;
  shopName?: Maybe<Scalars["String"]["output"]>;
  shopSlug?: Maybe<Scalars["String"]["output"]>;
  /** @deprecated Field no longer supported */
  showCode?: Maybe<Scalars["Boolean"]["output"]>;
  slug?: Maybe<Scalars["String"]["output"]>;
  startsAt?: Maybe<google_protobuf_Timestamp>;
  state?: Maybe<core_apimessages_Sale_SaleState>;
  summary?: Maybe<Scalars["String"]["output"]>;
  /** @deprecated Field no longer supported */
  withSalePriceDisplay?: Maybe<Scalars["String"]["output"]>;
};

export type core_apimessages_SaleLinks = {
  __typename?: "core_apimessages_SaleLinks";
  _source?: Maybe<Scalars["String"]["output"]>;
  self?: Maybe<core_apimessages_Link>;
};

export enum core_apimessages_Sale_SaleState {
  ENDED = "ENDED",
  LIVE = "LIVE",
  UPCOMING = "UPCOMING",
}

export type core_apimessages_SaveMyPlaidAccountRequest = {
  __typename?: "core_apimessages_SaveMyPlaidAccountRequest";
  _source?: Maybe<Scalars["String"]["output"]>;
  accountId?: Maybe<Scalars["String"]["output"]>;
  countryCode?: Maybe<Scalars["String"]["output"]>;
  publicToken?: Maybe<Scalars["String"]["output"]>;
  verificationStatus?: Maybe<Scalars["String"]["output"]>;
};

export type core_apimessages_SaveMyPlaidAccountResponse = {
  __typename?: "core_apimessages_SaveMyPlaidAccountResponse";
  _source?: Maybe<Scalars["String"]["output"]>;
  plaidAccountId?: Maybe<Scalars["String"]["output"]>;
};

export type core_apimessages_ScheduledPriceDrop = {
  __typename?: "core_apimessages_ScheduledPriceDrop";
  _source?: Maybe<Scalars["String"]["output"]>;
  active?: Maybe<Scalars["Boolean"]["output"]>;
  dropAt?: Maybe<Scalars["Timestamp"]["output"]>;
  newPrice?: Maybe<core_apimessages_Money>;
};

export type core_apimessages_ScheduledPriceDropEntry = {
  __typename?: "core_apimessages_ScheduledPriceDropEntry";
  _source?: Maybe<Scalars["String"]["output"]>;
  active?: Maybe<Scalars["Boolean"]["output"]>;
  newPrice?: Maybe<core_apimessages_Money>;
};

export type core_apimessages_SearchData = {
  __typename?: "core_apimessages_SearchData";
  _source?: Maybe<Scalars["String"]["output"]>;
  imageUrl?: Maybe<Scalars["String"]["output"]>;
  queryParams?: Maybe<Scalars["String"]["output"]>;
  title?: Maybe<Scalars["String"]["output"]>;
};

export type core_apimessages_SearchFilterPreset = {
  __typename?: "core_apimessages_SearchFilterPreset";
  _source?: Maybe<Scalars["String"]["output"]>;
  /** postgres id (polymorphic with 'collection_type') */
  collectionId?: Maybe<Scalars["String"]["output"]>;
  collectionType?: Maybe<Scalars["String"]["output"]>;
  id?: Maybe<Scalars["String"]["output"]>;
  image?: Maybe<core_apimessages_Image>;
  title?: Maybe<Scalars["String"]["output"]>;
  url?: Maybe<Scalars["String"]["output"]>;
};

export type core_apimessages_SearchFilterPresetimageArgs = {
  transform?: InputMaybe<Scalars["String"]["input"]>;
};

export type core_apimessages_SearchFilterPresetsRequest = {
  __typename?: "core_apimessages_SearchFilterPresetsRequest";
  _source?: Maybe<Scalars["String"]["output"]>;
  categoryUuids?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
  /** UUIDs */
  collectionIds?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
  collectionSlugs?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
  collectionType?: Maybe<core_apimessages_CollectionHeader_CollectionType>;
};

export type core_apimessages_SearchFilterPresetsResponse = {
  __typename?: "core_apimessages_SearchFilterPresetsResponse";
  _source?: Maybe<Scalars["String"]["output"]>;
  searchFilterPresets?: Maybe<
    Array<Maybe<core_apimessages_ItemSearchFilterPresets>>
  >;
};

export type core_apimessages_SearchMetadataRequest = {
  __typename?: "core_apimessages_SearchMetadataRequest";
  _source?: Maybe<Scalars["String"]["output"]>;
  brandSlugs?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
  categorySlugs?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
  conditionSlugs?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
  curatedSetSlug?: Maybe<Scalars["String"]["output"]>;
  query?: Maybe<Scalars["String"]["output"]>;
  searchUrlParams?: Maybe<Scalars["String"]["output"]>;
  shopSlug?: Maybe<Scalars["String"]["output"]>;
  yearMax?: Maybe<Scalars["String"]["output"]>;
  yearMin?: Maybe<Scalars["String"]["output"]>;
};

export type core_apimessages_SearchMetadataResponse = {
  __typename?: "core_apimessages_SearchMetadataResponse";
  _source?: Maybe<Scalars["String"]["output"]>;
  title?: Maybe<Scalars["String"]["output"]>;
};

export type core_apimessages_SecureCheckoutSignal = {
  __typename?: "core_apimessages_SecureCheckoutSignal";
  _source?: Maybe<Scalars["String"]["output"]>;
  isValid?: Maybe<Scalars["Boolean"]["output"]>;
  translations?: Maybe<core_apimessages_SignalTranslation>;
};

export type core_apimessages_SeedListingRequest = {
  __typename?: "core_apimessages_SeedListingRequest";
  _source?: Maybe<Scalars["String"]["output"]>;
  seedId?: Maybe<Scalars["String"]["output"]>;
  seedType?: Maybe<core_apimessages_Listing_SeedType>;
};

export type core_apimessages_SeedListingResponse = {
  __typename?: "core_apimessages_SeedListingResponse";
  _source?: Maybe<Scalars["String"]["output"]>;
  listing?: Maybe<core_apimessages_SeededListing>;
};

export type core_apimessages_SeededListing = {
  __typename?: "core_apimessages_SeededListing";
  _source?: Maybe<Scalars["String"]["output"]>;
  brandSlug?: Maybe<Scalars["String"]["output"]>;
  canonicalProductId?: Maybe<Scalars["String"]["output"]>;
  categoryRootUuid?: Maybe<Scalars["String"]["output"]>;
  categoryUuids?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
  comparisonShoppingPageId?: Maybe<Scalars["String"]["output"]>;
  conditionSlug?: Maybe<Scalars["String"]["output"]>;
  countryOfOrigin?: Maybe<Scalars["String"]["output"]>;
  description?: Maybe<Scalars["String"]["output"]>;
  finish?: Maybe<Scalars["String"]["output"]>;
  handmade?: Maybe<Scalars["Boolean"]["output"]>;
  make?: Maybe<Scalars["String"]["output"]>;
  model?: Maybe<Scalars["String"]["output"]>;
  productTypeId?: Maybe<Scalars["String"]["output"]>;
  seedId?: Maybe<Scalars["String"]["output"]>;
  seedType?: Maybe<core_apimessages_Listing_SeedType>;
  sellerCost?: Maybe<core_apimessages_Money>;
  sellerPrice?: Maybe<core_apimessages_Money>;
  shippingProfileId?: Maybe<Scalars["String"]["output"]>;
  soldAsIs?: Maybe<Scalars["Boolean"]["output"]>;
  subcategoryLeafUuids?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
  title?: Maybe<Scalars["String"]["output"]>;
  year?: Maybe<Scalars["String"]["output"]>;
};

export type core_apimessages_SelectAddressAutocompleteSuggestionRequest = {
  __typename?: "core_apimessages_SelectAddressAutocompleteSuggestionRequest";
  _source?: Maybe<Scalars["String"]["output"]>;
  /** loqate address id */
  id?: Maybe<Scalars["String"]["output"]>;
};

export type core_apimessages_SelectAddressAutocompleteSuggestionResponse = {
  __typename?: "core_apimessages_SelectAddressAutocompleteSuggestionResponse";
  _source?: Maybe<Scalars["String"]["output"]>;
  suggestion?: Maybe<core_apimessages_Address>;
};

export type core_apimessages_SelectCheckoutCreditCardRequest = {
  __typename?: "core_apimessages_SelectCheckoutCreditCardRequest";
  _source?: Maybe<Scalars["String"]["output"]>;
  creditCardId?: Maybe<Scalars["String"]["output"]>;
  /** checkout UUID */
  id?: Maybe<Scalars["String"]["output"]>;
};

export type core_apimessages_SelectCheckoutCreditCardResponse = {
  __typename?: "core_apimessages_SelectCheckoutCreditCardResponse";
  _source?: Maybe<Scalars["String"]["output"]>;
  checkout?: Maybe<Checkout>;
  /** checkout UUID */
  id?: Maybe<Scalars["String"]["output"]>;
};

export type core_apimessages_SelectCheckoutPaymentMethodRequest = {
  __typename?: "core_apimessages_SelectCheckoutPaymentMethodRequest";
  _source?: Maybe<Scalars["String"]["output"]>;
  /** checkout UUID */
  id?: Maybe<Scalars["String"]["output"]>;
  paymentMethod?: Maybe<core_apimessages_CheckoutPaymentMethod_Type>;
};

export type core_apimessages_SelectCheckoutPaymentMethodResponse = {
  __typename?: "core_apimessages_SelectCheckoutPaymentMethodResponse";
  _source?: Maybe<Scalars["String"]["output"]>;
  checkout?: Maybe<Checkout>;
  /** checkout UUID */
  id?: Maybe<Scalars["String"]["output"]>;
};

export type core_apimessages_SellerBulkActionAsyncResponse = {
  __typename?: "core_apimessages_SellerBulkActionAsyncResponse";
  _source?: Maybe<Scalars["String"]["output"]>;
  enqueued?: Maybe<Scalars["Boolean"]["output"]>;
  message?: Maybe<Scalars["String"]["output"]>;
};

export type core_apimessages_SellerBulkActionResponse = {
  __typename?: "core_apimessages_SellerBulkActionResponse";
  _source?: Maybe<Scalars["String"]["output"]>;
  responses?: Maybe<
    Array<Maybe<core_apimessages_SellerListingBulkActionResponse>>
  >;
};

export type core_apimessages_SellerListing = {
  __typename?: "core_apimessages_SellerListing";
  _source?: Maybe<Scalars["String"]["output"]>;
  handmade?: Maybe<Scalars["Boolean"]["output"]>;
  localizedContents?: Maybe<
    Array<Maybe<core_apimessages_LocalizedListingContent>>
  >;
  prefersReverbShippingLabel?: Maybe<Scalars["Boolean"]["output"]>;
  relistable?: Maybe<Scalars["Boolean"]["output"]>;
  scheduledPriceDrops?: Maybe<
    Array<Maybe<core_apimessages_ScheduledPriceDrop>>
  >;
  sellerCost?: Maybe<core_apimessages_Money>;
  sellerPrice?: Maybe<core_apimessages_Money>;
  sellerReportedMap?: Maybe<core_apimessages_Money>;
  shippingProfileId?: Maybe<Scalars["String"]["output"]>;
  storageLocation?: Maybe<Scalars["String"]["output"]>;
  taxExempt?: Maybe<Scalars["Boolean"]["output"]>;
  upcDoesNotApply?: Maybe<Scalars["Boolean"]["output"]>;
  vatIncluded?: Maybe<Scalars["Boolean"]["output"]>;
  vatIncludedHint?: Maybe<Scalars["String"]["output"]>;
};

export type core_apimessages_SellerListingBulkActionResponse = {
  __typename?: "core_apimessages_SellerListingBulkActionResponse";
  _source?: Maybe<Scalars["String"]["output"]>;
  errors?: Maybe<Array<Maybe<core_apimessages_Error>>>;
  listingId?: Maybe<Scalars["String"]["output"]>;
  success?: Maybe<Scalars["Boolean"]["output"]>;
};

export type core_apimessages_SellerListingsResponse = {
  __typename?: "core_apimessages_SellerListingsResponse";
  _source?: Maybe<Scalars["String"]["output"]>;
  listings?: Maybe<Array<Maybe<core_apimessages_SellerListing>>>;
};

export type core_apimessages_SellerOrder = {
  __typename?: "core_apimessages_SellerOrder";
  _source?: Maybe<Scalars["String"]["output"]>;
  orderViewStatus?: Maybe<core_apimessages_OrderViewStatus>;
  packingSlip?: Maybe<core_apimessages_OrderPackingSlip>;
  shippingLabel?: Maybe<core_apimessages_OrderShippingLabel>;
  taxResponsibleParty?: Maybe<core_apimessages_SellerOrder_TaxResponsibleParty>;
  uuid?: Maybe<Scalars["String"]["output"]>;
};

export enum core_apimessages_SellerOrder_TaxResponsibleParty {
  REVERB = "REVERB",
  SELLER = "SELLER",
}

export type core_apimessages_SellerOrdersResponse = {
  __typename?: "core_apimessages_SellerOrdersResponse";
  _source?: Maybe<Scalars["String"]["output"]>;
  orders?: Maybe<Array<Maybe<core_apimessages_SellerOrder>>>;
};

export type core_apimessages_SellerVerificationPolicyResponse = {
  __typename?: "core_apimessages_SellerVerificationPolicyResponse";
  _source?: Maybe<Scalars["String"]["output"]>;
  completedVerification?: Maybe<Scalars["Boolean"]["output"]>;
  eligibleForColdStorageSellerVerificationBypass?: Maybe<
    Scalars["Boolean"]["output"]
  >;
  eligibleForInformAct?: Maybe<Scalars["Boolean"]["output"]>;
  eligibleForSellerVerification?: Maybe<Scalars["Boolean"]["output"]>;
  eligibleForSellerVerificationBackcast?: Maybe<Scalars["Boolean"]["output"]>;
};

export type core_apimessages_SellingFees = {
  __typename?: "core_apimessages_SellingFees";
  _source?: Maybe<Scalars["String"]["output"]>;
  maxFee?: Maybe<core_apimessages_Money>;
  minFee?: Maybe<core_apimessages_Money>;
  processingFees?: Maybe<core_apimessages_ProcessingFees>;
  sellingFeePercent?: Maybe<Scalars["String"]["output"]>;
};

export type core_apimessages_SellingFeesRequest = {
  __typename?: "core_apimessages_SellingFeesRequest";
  _source?: Maybe<Scalars["String"]["output"]>;
  channel?: Maybe<core_apimessages_Channel>;
};

export type core_apimessages_SellingFeesResponse = {
  __typename?: "core_apimessages_SellingFeesResponse";
  _source?: Maybe<Scalars["String"]["output"]>;
  fees?: Maybe<core_apimessages_SellingFees>;
};

export type core_apimessages_SetAccertifyTokensRequest = {
  __typename?: "core_apimessages_SetAccertifyTokensRequest";
  _source?: Maybe<Scalars["String"]["output"]>;
  accertifyDeviceInfo?: Maybe<core_apimessages_AccertifyDeviceInfo>;
  checkoutId?: Maybe<Scalars["String"]["output"]>;
};

export type core_apimessages_SetAccertifyTokensResponse = {
  __typename?: "core_apimessages_SetAccertifyTokensResponse";
  _source?: Maybe<Scalars["String"]["output"]>;
  /** Checkout UUID */
  id?: Maybe<Scalars["String"]["output"]>;
};

export type core_apimessages_SetupPaymentMethodsRequest = {
  __typename?: "core_apimessages_SetupPaymentMethodsRequest";
  _source?: Maybe<Scalars["String"]["output"]>;
  adyenDetails?: Maybe<core_apimessages_SetupPaymentMethodsRequestAdyenDetails>;
  creditCard?: Maybe<core_apimessages_BillingCreditCardEntry>;
  paypalDetails?: Maybe<core_apimessages_SetupPaymentMethodsRequestPaypalDetails>;
  returnPolicyDetails?: Maybe<core_apimessages_ReturnPolicy>;
  shopCurrency?: Maybe<Scalars["String"]["output"]>;
};

export type core_apimessages_SetupPaymentMethodsRequestAdyenDetails = {
  __typename?: "core_apimessages_SetupPaymentMethodsRequestAdyenDetails";
  _source?: Maybe<Scalars["String"]["output"]>;
  businessDetails?: Maybe<core_apimessages_SetupPaymentMethodsRequestBusinessAdyenDetails>;
  individualDetails?: Maybe<core_apimessages_SetupPaymentMethodsRequestIndividualAdyenDetails>;
  legalEntity?: Maybe<core_apimessages_AdyenLegalEntity>;
  solePropDetails?: Maybe<core_apimessages_SetupPaymentMethodsRequestSolePropAdyenDetails>;
};

export type core_apimessages_SetupPaymentMethodsRequestBusinessAdyenDetails = {
  __typename?: "core_apimessages_SetupPaymentMethodsRequestBusinessAdyenDetails";
  _source?: Maybe<Scalars["String"]["output"]>;
  legalBusinessName?: Maybe<Scalars["String"]["output"]>;
  originCountryCode?: Maybe<Scalars["String"]["output"]>;
};

export type core_apimessages_SetupPaymentMethodsRequestIndividualAdyenDetails =
  {
    __typename?: "core_apimessages_SetupPaymentMethodsRequestIndividualAdyenDetails";
    _source?: Maybe<Scalars["String"]["output"]>;
    firstName?: Maybe<Scalars["String"]["output"]>;
    lastName?: Maybe<Scalars["String"]["output"]>;
    originCountryCode?: Maybe<Scalars["String"]["output"]>;
  };

export type core_apimessages_SetupPaymentMethodsRequestPaypalDetails = {
  __typename?: "core_apimessages_SetupPaymentMethodsRequestPaypalDetails";
  _source?: Maybe<Scalars["String"]["output"]>;
  email?: Maybe<Scalars["String"]["output"]>;
  legalCountryCode?: Maybe<Scalars["String"]["output"]>;
};

export type core_apimessages_SetupPaymentMethodsRequestSolePropAdyenDetails = {
  __typename?: "core_apimessages_SetupPaymentMethodsRequestSolePropAdyenDetails";
  _source?: Maybe<Scalars["String"]["output"]>;
  legalName?: Maybe<Scalars["String"]["output"]>;
  originCountryCode?: Maybe<Scalars["String"]["output"]>;
};

export type core_apimessages_SetupPaymentMethodsResponse = {
  __typename?: "core_apimessages_SetupPaymentMethodsResponse";
  _source?: Maybe<Scalars["String"]["output"]>;
  errors?: Maybe<Array<Maybe<core_apimessages_Error>>>;
};

export type core_apimessages_SharedSignalData = {
  __typename?: "core_apimessages_SharedSignalData";
  _source?: Maybe<Scalars["String"]["output"]>;
  buyerPrice?: Maybe<core_apimessages_Money>;
  originalPrice?: Maybe<core_apimessages_Money>;
  sellerLocationDisplay?: Maybe<Scalars["String"]["output"]>;
};

export type core_apimessages_Shipment = {
  __typename?: "core_apimessages_Shipment";
  _source?: Maybe<Scalars["String"]["output"]>;
  carrierRates?: Maybe<Array<Maybe<core_apimessages_CarrierRateGroup>>>;
  defaultPaymentMethod?: Maybe<core_apimessages_FeePaymentMethod>;
  fromAddress?: Maybe<core_apimessages_Address>;
  /** shipments.uuid */
  id?: Maybe<Scalars["String"]["output"]>;
  mediaMailQualified?: Maybe<Scalars["Boolean"]["output"]>;
  orderUuids?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
  package?: Maybe<core_apimessages_ShippingLabelPackage>;
  shippingMethod?: Maybe<core_apimessages_ShippingMethod>;
  shippingProtection?: Maybe<Scalars["Boolean"]["output"]>;
  shippingProtectionPrice?: Maybe<core_apimessages_Money>;
  signatureRequired?: Maybe<Scalars["Boolean"]["output"]>;
  toAddress?: Maybe<core_apimessages_Address>;
  toAddressIsResidential?: Maybe<Scalars["Boolean"]["output"]>;
};

export type core_apimessages_ShipmentItem = {
  __typename?: "core_apimessages_ShipmentItem";
  _source?: Maybe<Scalars["String"]["output"]>;
  declaredValue?: Maybe<core_apimessages_Money>;
  /** products.id */
  productId?: Maybe<Scalars["String"]["output"]>;
};

export enum core_apimessages_Shipment_ShipmentType {
  ADDITIONAL = "ADDITIONAL",
  STANDARD = "STANDARD",
}

export type core_apimessages_ShipmentsRequest = {
  __typename?: "core_apimessages_ShipmentsRequest";
  _source?: Maybe<Scalars["String"]["output"]>;
  shipmentUuids?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
};

export type core_apimessages_ShipmentsResponse = {
  __typename?: "core_apimessages_ShipmentsResponse";
  _source?: Maybe<Scalars["String"]["output"]>;
  shipments?: Maybe<Array<Maybe<core_apimessages_Shipment>>>;
};

export type core_apimessages_ShippingLabelPackage = {
  __typename?: "core_apimessages_ShippingLabelPackage";
  _source?: Maybe<Scalars["String"]["output"]>;
  allowedCarriers?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
  height?: Maybe<core_apimessages_Measurement>;
  id?: Maybe<Scalars["String"]["output"]>;
  length?: Maybe<core_apimessages_Measurement>;
  packageSizeSuggestionId?: Maybe<Scalars["String"]["output"]>;
  predefinedPackage?: Maybe<Scalars["String"]["output"]>;
  /** @deprecated Field no longer supported */
  reverbSuggested?: Maybe<Scalars["Boolean"]["output"]>;
  weight?: Maybe<core_apimessages_Measurement>;
  width?: Maybe<core_apimessages_Measurement>;
};

export enum core_apimessages_ShippingMethod {
  COMBINED_SHIPPING = "COMBINED_SHIPPING",
  DIGITAL_DELIVERY = "DIGITAL_DELIVERY",
  EMAIL_DELIVERY = "EMAIL_DELIVERY",
  EXPEDITED_SHIPPING = "EXPEDITED_SHIPPING",
  LOCAL = "LOCAL",
  SHIPPED = "SHIPPED",
  UNKNOWN = "UNKNOWN",
}

export type core_apimessages_ShippingPrice = {
  __typename?: "core_apimessages_ShippingPrice";
  _id?: Maybe<Scalars["ID"]["output"]>;
  _source?: Maybe<Scalars["String"]["output"]>;
  carrierCalculated?: Maybe<Scalars["Boolean"]["output"]>;
  destinationPostalCodeNeeded?: Maybe<Scalars["Boolean"]["output"]>;
  /** in shop currency, VAT conditionally included */
  originalRate?: Maybe<core_apimessages_Money>;
  /** in shop currency, no VAT */
  originalRateVatExcluded?: Maybe<core_apimessages_Money>;
  postalCode?: Maybe<Scalars["String"]["output"]>;
  /** in user currency, VAT conditionally included */
  rate?: Maybe<core_apimessages_Money>;
  regional?: Maybe<Scalars["Boolean"]["output"]>;
  shippingMethod?: Maybe<core_apimessages_ShippingMethod>;
  shippingRateId?: Maybe<Scalars["String"]["output"]>;
  shippingRegionCode?: Maybe<Scalars["String"]["output"]>;
};

export type core_apimessages_ShippingPrices = {
  __typename?: "core_apimessages_ShippingPrices";
  _source?: Maybe<Scalars["String"]["output"]>;
  freeExpeditedShipping?: Maybe<Scalars["Boolean"]["output"]>;
  localPickup?: Maybe<Scalars["Boolean"]["output"]>;
  localPickupOnly?: Maybe<Scalars["Boolean"]["output"]>;
  shippingAddress?: Maybe<core_apimessages_Address>;
  shippingPrices?: Maybe<Array<Maybe<core_apimessages_ShippingPrice>>>;
  /** user shipping region code */
  shippingRegionCode?: Maybe<Scalars["String"]["output"]>;
};

export type core_apimessages_ShippingPricesRequest = {
  __typename?: "core_apimessages_ShippingPricesRequest";
  _source?: Maybe<Scalars["String"]["output"]>;
  listingIds?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
  postalCode?: Maybe<Scalars["String"]["output"]>;
  returnAllShippingPrices?: Maybe<Scalars["Boolean"]["output"]>;
  shippingRegionCode?: Maybe<Scalars["String"]["output"]>;
  usePrimaryShippingAddress?: Maybe<Scalars["Boolean"]["output"]>;
};

export type core_apimessages_ShippingPricesResponse = {
  __typename?: "core_apimessages_ShippingPricesResponse";
  _source?: Maybe<Scalars["String"]["output"]>;
  prices?: Maybe<Array<Maybe<core_apimessages_Money>>>;
  shippingPrices?: Maybe<Array<Maybe<core_apimessages_ShippingPrices>>>;
};

export type core_apimessages_ShippingProfile = {
  __typename?: "core_apimessages_ShippingProfile";
  _source?: Maybe<Scalars["String"]["output"]>;
  /** Only visible to the shop owner */
  allShippingRates?: Maybe<Array<Maybe<core_apimessages_ShippingRates>>>;
  categoryUuids?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
  id?: Maybe<Scalars["String"]["output"]>;
  localPickup?: Maybe<Scalars["Boolean"]["output"]>;
  name?: Maybe<Scalars["String"]["output"]>;
  /** @deprecated Field no longer supported */
  shippingRates?: Maybe<core_apimessages_ShippingRates>;
};

export type core_apimessages_ShippingProfileallShippingRatesArgs = {
  input?: InputMaybe<Input_core_apimessages_NodeRequest_Lookup_Params_ShippingRates>;
};

/** POST /shipping_protection */
export type core_apimessages_ShippingProtectionRequest = {
  __typename?: "core_apimessages_ShippingProtectionRequest";
  _source?: Maybe<Scalars["String"]["output"]>;
  fromCountryCode?: Maybe<Scalars["String"]["output"]>;
  shipmentItems?: Maybe<Array<Maybe<core_apimessages_ShipmentItem>>>;
  shipmentType?: Maybe<core_apimessages_Shipment_ShipmentType>;
  toCountryCode?: Maybe<Scalars["String"]["output"]>;
};

/** POST /shipping_protection */
export type core_apimessages_ShippingProtectionResponse = {
  __typename?: "core_apimessages_ShippingProtectionResponse";
  _source?: Maybe<Scalars["String"]["output"]>;
  price?: Maybe<core_apimessages_Money>;
  shippingProtectionStatus?: Maybe<core_apimessages_ShippingProtectionStatus>;
  signatureRequiredCosts?: Maybe<
    Array<Maybe<core_apimessages_SignatureRequiredCost>>
  >;
  signatureRequiredStatus?: Maybe<core_apimessages_SignatureRequiredStatus>;
};

export enum core_apimessages_ShippingProtectionStatus {
  PROTECTION_NOT_SUPPORTED = "PROTECTION_NOT_SUPPORTED",
  PROTECTION_OPTIONAL = "PROTECTION_OPTIONAL",
  PROTECTION_REQUIRED = "PROTECTION_REQUIRED",
}

export type core_apimessages_ShippingRate = {
  __typename?: "core_apimessages_ShippingRate";
  _source?: Maybe<Scalars["String"]["output"]>;
  displayName?: Maybe<Scalars["String"]["output"]>;
  price?: Maybe<core_apimessages_Money>;
};

export type core_apimessages_ShippingRates = {
  __typename?: "core_apimessages_ShippingRates";
  _source?: Maybe<Scalars["String"]["output"]>;
  expeditedRate?: Maybe<core_apimessages_Money>;
  freeShippingThreshold?: Maybe<core_apimessages_Money>;
  incrementalRate?: Maybe<core_apimessages_Money>;
  rate?: Maybe<core_apimessages_Money>;
  rateType?: Maybe<Scalars["String"]["output"]>;
  regionCode?: Maybe<Scalars["String"]["output"]>;
  regionName?: Maybe<Scalars["String"]["output"]>;
  regionType?: Maybe<core_apimessages_ShippingRates_RegionType>;
  regionalExpeditedRates?: Maybe<
    Array<Maybe<core_apimessages_RegionalShippingRate>>
  >;
  regionalRates?: Maybe<Array<Maybe<core_apimessages_RegionalShippingRate>>>;
};

export type core_apimessages_ShippingRatesForShippingProfile = {
  __typename?: "core_apimessages_ShippingRatesForShippingProfile";
  _source?: Maybe<Scalars["String"]["output"]>;
  shippingProfileId?: Maybe<Scalars["String"]["output"]>;
  shippingRates?: Maybe<Array<Maybe<core_apimessages_ShippingRates>>>;
};

export enum core_apimessages_ShippingRates_RegionType {
  COUNTRY = "COUNTRY",
  EVERYWHERE_ELSE = "EVERYWHERE_ELSE",
  SUBREGION = "SUBREGION",
  SUPERREGION = "SUPERREGION",
}

export type core_apimessages_ShippingRegionSetting = {
  __typename?: "core_apimessages_ShippingRegionSetting";
  _source?: Maybe<Scalars["String"]["output"]>;
  allowedToShipCarrierCalculated?: Maybe<Scalars["Boolean"]["output"]>;
  isDomestic?: Maybe<Scalars["Boolean"]["output"]>;
  regionCode?: Maybe<Scalars["String"]["output"]>;
  regionName?: Maybe<Scalars["String"]["output"]>;
};

export type core_apimessages_ShipsSoonSignal = {
  __typename?: "core_apimessages_ShipsSoonSignal";
  _source?: Maybe<Scalars["String"]["output"]>;
  isValid?: Maybe<Scalars["Boolean"]["output"]>;
  translations?: Maybe<core_apimessages_SignalTranslation>;
};

/** A public representation of a shop, accessible to all users. */
export type core_apimessages_Shop = {
  __typename?: "core_apimessages_Shop";
  _source?: Maybe<Scalars["String"]["output"]>;
  createdAt?: Maybe<Scalars["Timestamp"]["output"]>;
  currency?: Maybe<Scalars["String"]["output"]>;
  description?: Maybe<Scalars["String"]["output"]>;
  id?: Maybe<Scalars["String"]["output"]>;
  lowballOfferPercentage?: Maybe<Scalars["Int"]["output"]>;
  manufacturersSafetyInformation?: Maybe<Scalars["String"]["output"]>;
  name?: Maybe<Scalars["String"]["output"]>;
  onVacation?: Maybe<Scalars["Boolean"]["output"]>;
  paymentPolicy?: Maybe<Scalars["String"]["output"]>;
  preferredSeller?: Maybe<Scalars["Boolean"]["output"]>;
  quickResponder?: Maybe<Scalars["Boolean"]["output"]>;
  quickShipper?: Maybe<Scalars["Boolean"]["output"]>;
  quickShipperSpeed?: Maybe<core_apimessages_Shop_QuickShipperSpeed>;
  /**
   * Unused by any client but needed in mobile apps RQL queries
   * @deprecated Field no longer supported
   */
  recentlyShipped?: Maybe<Scalars["Boolean"]["output"]>;
  shippingPolicy?: Maybe<Scalars["String"]["output"]>;
  shopType?: Maybe<core_apimessages_Shop_ShopType>;
  showSold?: Maybe<Scalars["Boolean"]["output"]>;
  slug?: Maybe<Scalars["String"]["output"]>;
  userId?: Maybe<Scalars["String"]["output"]>;
  userUuid?: Maybe<Scalars["String"]["output"]>;
  uuid?: Maybe<Scalars["String"]["output"]>;
  vatId?: Maybe<Scalars["String"]["output"]>;
  website?: Maybe<Scalars["String"]["output"]>;
};

export type core_apimessages_ShopAdyenBalancePlatformNotificationsIndexRequest =
  {
    __typename?: "core_apimessages_ShopAdyenBalancePlatformNotificationsIndexRequest";
    _source?: Maybe<Scalars["String"]["output"]>;
    eventTypes?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
    limit?: Maybe<Scalars["String"]["output"]>;
    offset?: Maybe<Scalars["String"]["output"]>;
    shopId?: Maybe<Scalars["String"]["output"]>;
  };

export type core_apimessages_ShopAdyenBalancePlatformNotificationsIndexResponse =
  {
    __typename?: "core_apimessages_ShopAdyenBalancePlatformNotificationsIndexResponse";
    _source?: Maybe<Scalars["String"]["output"]>;
    limit?: Maybe<Scalars["String"]["output"]>;
    notifications?: Maybe<
      Array<Maybe<core_apimessages_AdyenBalancePlatformNotification>>
    >;
    offset?: Maybe<Scalars["String"]["output"]>;
    totalResults?: Maybe<Scalars["String"]["output"]>;
  };

export type core_apimessages_ShopBadge = {
  __typename?: "core_apimessages_ShopBadge";
  _source?: Maybe<Scalars["String"]["output"]>;
  badgeType?: Maybe<Scalars["String"]["output"]>;
  completedPercentage?: Maybe<Scalars["Float"]["output"]>;
  /** optional: we won't display requirements for the preferred seller badge */
  requirements?: Maybe<Array<Maybe<core_apimessages_ShopBadgeRequirement>>>;
};

export type core_apimessages_ShopBadgeRequirement = {
  __typename?: "core_apimessages_ShopBadgeRequirement";
  _source?: Maybe<Scalars["String"]["output"]>;
  completed?: Maybe<Scalars["Boolean"]["output"]>;
  requirement?: Maybe<Scalars["String"]["output"]>;
};

export type core_apimessages_ShopCampaignCoupon = {
  __typename?: "core_apimessages_ShopCampaignCoupon";
  _id?: Maybe<Scalars["ID"]["output"]>;
  _source?: Maybe<Scalars["String"]["output"]>;
  applyToBStockConditions?: Maybe<Scalars["Boolean"]["output"]>;
  applyToNewConditions?: Maybe<Scalars["Boolean"]["output"]>;
  applyToUsedConditions?: Maybe<Scalars["Boolean"]["output"]>;
  code?: Maybe<Scalars["String"]["output"]>;
  couponHeader?: Maybe<Scalars["String"]["output"]>;
  descriptionSummary?: Maybe<Scalars["String"]["output"]>;
  discountValue?: Maybe<Scalars["String"]["output"]>;
  excludedBrands?: Maybe<Array<Maybe<Brand>>>;
  excludedCategories?: Maybe<Array<Maybe<Category>>>;
  expiresAt?: Maybe<Scalars["Timestamp"]["output"]>;
  id?: Maybe<Scalars["String"]["output"]>;
  restrictionsSummary?: Maybe<Scalars["String"]["output"]>;
  shop?: Maybe<Shop>;
  status?: Maybe<core_apimessages_ShopCampaignCoupon_Status>;
};

export enum core_apimessages_ShopCampaignCoupon_Status {
  APPLIED_AND_ACTIVE = "APPLIED_AND_ACTIVE",
  APPLIED_AND_INACTIVE = "APPLIED_AND_INACTIVE",
  ELIGIBLE = "ELIGIBLE",
  INELIGIBLE = "INELIGIBLE",
  NONE = "NONE",
}

export type core_apimessages_ShopCertifiedPreOwned = {
  __typename?: "core_apimessages_ShopCertifiedPreOwned";
  _source?: Maybe<Scalars["String"]["output"]>;
  badgeIconUrl?: Maybe<Scalars["String"]["output"]>;
  brandName?: Maybe<Scalars["String"]["output"]>;
  darkModeBrandIconUrl?: Maybe<Scalars["String"]["output"]>;
  description?: Maybe<Scalars["String"]["output"]>;
  lightModeBrandIconUrl?: Maybe<Scalars["String"]["output"]>;
  pageUrl?: Maybe<Scalars["String"]["output"]>;
  title?: Maybe<Scalars["String"]["output"]>;
  tooltipDescription?: Maybe<Scalars["String"]["output"]>;
};

/** A private representation of a shop, accessible to owners and admins. */
export type core_apimessages_ShopConfig = {
  __typename?: "core_apimessages_ShopConfig";
  _source?: Maybe<Scalars["String"]["output"]>;
  acceptsAffirm?: Maybe<Scalars["Boolean"]["output"]>;
  canCurateSales?: Maybe<Scalars["Boolean"]["output"]>;
  currencySetting?: Maybe<Scalars["String"]["output"]>;
  defaultLocale?: Maybe<Scalars["String"]["output"]>;
  firstTimeSeller?: Maybe<Scalars["Boolean"]["output"]>;
  hasAccountRep?: Maybe<Scalars["Boolean"]["output"]>;
  inclusiveVatPricingEnabled?: Maybe<Scalars["Boolean"]["output"]>;
  isShopInEu?: Maybe<Scalars["Boolean"]["output"]>;
  isShopInUk?: Maybe<Scalars["Boolean"]["output"]>;
  listableConditions?: Maybe<Array<Maybe<core_apimessages_Condition>>>;
  marketplaceTaxReportingCountryCode?: Maybe<Scalars["String"]["output"]>;
  offerPolicy?: Maybe<core_apimessages_ShopConfig_OfferPolicy>;
  sameDayShippingConfigured?: Maybe<Scalars["Boolean"]["output"]>;
  shippingRegionSettings?: Maybe<
    Array<Maybe<core_apimessages_ShippingRegionSetting>>
  >;
  shopSupportedLocaleCodes?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
};

export type core_apimessages_ShopConfig_OfferPolicy = {
  __typename?: "core_apimessages_ShopConfig_OfferPolicy";
  _source?: Maybe<Scalars["String"]["output"]>;
  autoAcceptHighOffers?: Maybe<Scalars["Boolean"]["output"]>;
  autoRejectLowOffers?: Maybe<Scalars["Boolean"]["output"]>;
  lowballPercentage?: Maybe<Scalars["Int"]["output"]>;
};

export type core_apimessages_ShopEntry = {
  __typename?: "core_apimessages_ShopEntry";
  _source?: Maybe<Scalars["String"]["output"]>;
  address?: Maybe<core_apimessages_AddressEntry>;
  currency?: Maybe<Scalars["String"]["output"]>;
  defaultLocale?: Maybe<Scalars["String"]["output"]>;
  name?: Maybe<Scalars["String"]["output"]>;
  originCountryCode?: Maybe<Scalars["String"]["output"]>;
  shopType?: Maybe<core_apimessages_Shop_ShopType>;
};

export type core_apimessages_ShopReturnPoliciesRequest = {
  __typename?: "core_apimessages_ShopReturnPoliciesRequest";
  _source?: Maybe<Scalars["String"]["output"]>;
  ids?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
  shopUuids?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
  slugs?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
  uuids?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
};

export type core_apimessages_ShopReturnPoliciesResponse = {
  __typename?: "core_apimessages_ShopReturnPoliciesResponse";
  _source?: Maybe<Scalars["String"]["output"]>;
  shopReturnPolicies?: Maybe<Array<Maybe<core_apimessages_ShopReturnPolicy>>>;
};

export type core_apimessages_ShopReturnPolicy = {
  __typename?: "core_apimessages_ShopReturnPolicy";
  _source?: Maybe<Scalars["String"]["output"]>;
  returnPolicy?: Maybe<core_apimessages_ReturnPolicy>;
  shopUuid?: Maybe<Scalars["String"]["output"]>;
};

export type core_apimessages_ShopShippingProfiles = {
  __typename?: "core_apimessages_ShopShippingProfiles";
  _source?: Maybe<Scalars["String"]["output"]>;
  shippingProfiles?: Maybe<Array<Maybe<core_apimessages_ShippingProfile>>>;
  shopUuid?: Maybe<Scalars["String"]["output"]>;
};

export type core_apimessages_ShopShippingProfilesRequest = {
  __typename?: "core_apimessages_ShopShippingProfilesRequest";
  _source?: Maybe<Scalars["String"]["output"]>;
  ids?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
  shopUuids?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
  slugs?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
  uuids?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
};

export type core_apimessages_ShopShippingProfilesResponse = {
  __typename?: "core_apimessages_ShopShippingProfilesResponse";
  _source?: Maybe<Scalars["String"]["output"]>;
  shopShippingProfiles?: Maybe<
    Array<Maybe<core_apimessages_ShopShippingProfiles>>
  >;
};

export type core_apimessages_ShopTaxPolicies = {
  __typename?: "core_apimessages_ShopTaxPolicies";
  _source?: Maybe<Scalars["String"]["output"]>;
  shopUuid?: Maybe<Scalars["String"]["output"]>;
  taxPolicies?: Maybe<Array<Maybe<core_apimessages_ShopTaxPolicy>>>;
};

export type core_apimessages_ShopTaxPolicy = {
  __typename?: "core_apimessages_ShopTaxPolicy";
  _source?: Maybe<Scalars["String"]["output"]>;
  displayLocation?: Maybe<Scalars["String"]["output"]>;
  displayRate?: Maybe<Scalars["String"]["output"]>;
  policyType?: Maybe<core_apimessages_ShopTaxPolicy_PolicyType>;
  vatPolicy?: Maybe<Scalars["Boolean"]["output"]>;
};

export enum core_apimessages_ShopTaxPolicy_PolicyType {
  AUTO_CALCULATED = "AUTO_CALCULATED",
  MARKETPLACE_REGION = "MARKETPLACE_REGION",
  SELLER = "SELLER",
}

export type core_apimessages_Shop_OfferPolicy = {
  __typename?: "core_apimessages_Shop_OfferPolicy";
  _source?: Maybe<Scalars["String"]["output"]>;
  autoRejectLowOffers?: Maybe<Scalars["Boolean"]["output"]>;
  lowballPercentage?: Maybe<Scalars["Int"]["output"]>;
};

export enum core_apimessages_Shop_QuickShipperSpeed {
  NO_BUCKET = "NO_BUCKET",
  UNDER_24 = "UNDER_24",
  UNDER_48 = "UNDER_48",
  UNDER_72 = "UNDER_72",
}

export enum core_apimessages_Shop_ShopType {
  BUSINESS = "BUSINESS",
  INDIVIDUAL = "INDIVIDUAL",
  UNKNOWN = "UNKNOWN",
}

export type core_apimessages_ShopsRequest = {
  __typename?: "core_apimessages_ShopsRequest";
  _source?: Maybe<Scalars["String"]["output"]>;
  channel?: Maybe<core_apimessages_Channel>;
  slugs?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
  uuids?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
};

export type core_apimessages_ShopsResponse = {
  __typename?: "core_apimessages_ShopsResponse";
  _source?: Maybe<Scalars["String"]["output"]>;
  shops?: Maybe<Array<Maybe<core_apimessages_Shop>>>;
};

export type core_apimessages_ShowAdyenRegionRequest = {
  __typename?: "core_apimessages_ShowAdyenRegionRequest";
  _?: Maybe<Scalars["Boolean"]["output"]>;
  _source?: Maybe<Scalars["String"]["output"]>;
};

export type core_apimessages_ShowAdyenRegionResponse = {
  __typename?: "core_apimessages_ShowAdyenRegionResponse";
  _source?: Maybe<Scalars["String"]["output"]>;
  androidRegion?: Maybe<Scalars["String"]["output"]>;
  iosRegion?: Maybe<Scalars["String"]["output"]>;
  webRegion?: Maybe<Scalars["String"]["output"]>;
};

export type core_apimessages_SignalLink = {
  __typename?: "core_apimessages_SignalLink";
  _source?: Maybe<Scalars["String"]["output"]>;
  text?: Maybe<Scalars["String"]["output"]>;
  url?: Maybe<Scalars["String"]["output"]>;
};

export type core_apimessages_SignalTranslation = {
  __typename?: "core_apimessages_SignalTranslation";
  _source?: Maybe<Scalars["String"]["output"]>;
  subtitle?: Maybe<Scalars["String"]["output"]>;
  title?: Maybe<Scalars["String"]["output"]>;
  tooltipText?: Maybe<Scalars["String"]["output"]>;
};

export type core_apimessages_SignatureRequiredCost = {
  __typename?: "core_apimessages_SignatureRequiredCost";
  _source?: Maybe<Scalars["String"]["output"]>;
  amount?: Maybe<core_apimessages_Money>;
  carrier?: Maybe<Scalars["String"]["output"]>;
};

export enum core_apimessages_SignatureRequiredStatus {
  SIGNATURE_NOT_SUPPORTED = "SIGNATURE_NOT_SUPPORTED",
  SIGNATURE_OPTIONAL = "SIGNATURE_OPTIONAL",
  SIGNATURE_REQUIRED = "SIGNATURE_REQUIRED",
  SIGNATURE_REQUIRED_WITH_INSURANCE = "SIGNATURE_REQUIRED_WITH_INSURANCE",
}

export type core_apimessages_SiteBanner = {
  __typename?: "core_apimessages_SiteBanner";
  _source?: Maybe<Scalars["String"]["output"]>;
  callToAction?: Maybe<Scalars["String"]["output"]>;
  contentSponsorship?: Maybe<core_apimessages_ContentSponsorship>;
  downtime?: Maybe<Scalars["Boolean"]["output"]>;
  endsAt?: Maybe<Scalars["Timestamp"]["output"]>;
  hasPhotos?: Maybe<Scalars["Boolean"]["output"]>;
  heading?: Maybe<Scalars["String"]["output"]>;
  id?: Maybe<Scalars["String"]["output"]>;
  imageUrl?: Maybe<Scalars["String"]["output"]>;
  mobileImageUrl?: Maybe<Scalars["String"]["output"]>;
  mobileUrl?: Maybe<core_apimessages_Link>;
  mobileWebUrl?: Maybe<core_apimessages_Link>;
  showOnMobile?: Maybe<Scalars["Boolean"]["output"]>;
  showOnMyUpdates?: Maybe<Scalars["Boolean"]["output"]>;
  startsAt?: Maybe<Scalars["Timestamp"]["output"]>;
  subheading?: Maybe<Scalars["String"]["output"]>;
  url?: Maybe<core_apimessages_Link>;
  /** Deprecated */
  webOnly?: Maybe<Scalars["Boolean"]["output"]>;
};

export type core_apimessages_SiteBannerRequest = {
  __typename?: "core_apimessages_SiteBannerRequest";
  _source?: Maybe<Scalars["String"]["output"]>;
  limit?: Maybe<Scalars["Int"]["output"]>;
  visibilityScope?: Maybe<core_apimessages_SiteBannerRequest_VisibilityScope>;
};

export enum core_apimessages_SiteBannerRequest_VisibilityScope {
  MOBILE = "MOBILE",
  MY_UPDATES = "MY_UPDATES",
  NONE = "NONE",
}

export type core_apimessages_SiteBannerResponse = {
  __typename?: "core_apimessages_SiteBannerResponse";
  _source?: Maybe<Scalars["String"]["output"]>;
  siteBanner?: Maybe<core_apimessages_SiteBanner>;
  siteBanners?: Maybe<Array<Maybe<core_apimessages_SiteBanner>>>;
};

export type core_apimessages_StartCheckoutForListingRequest = {
  __typename?: "core_apimessages_StartCheckoutForListingRequest";
  _source?: Maybe<Scalars["String"]["output"]>;
  listingId?: Maybe<Scalars["String"]["output"]>;
  paymentMethod?: Maybe<core_apimessages_CheckoutPaymentMethod_Type>;
  protectionPlanId?: Maybe<Scalars["String"]["output"]>;
  quantity?: Maybe<Scalars["Int"]["output"]>;
};

export type core_apimessages_StartCheckoutForListingResponse = {
  __typename?: "core_apimessages_StartCheckoutForListingResponse";
  _source?: Maybe<Scalars["String"]["output"]>;
  checkout?: Maybe<Checkout>;
  checkoutId?: Maybe<Scalars["String"]["output"]>;
  redirectUrl?: Maybe<Scalars["String"]["output"]>;
};

export type core_apimessages_StartCheckoutRequest = {
  __typename?: "core_apimessages_StartCheckoutRequest";
  _source?: Maybe<Scalars["String"]["output"]>;
  checkoutBundlingId?: Maybe<Scalars["String"]["output"]>;
  paymentMethodType?: Maybe<core_apimessages_CheckoutPaymentMethod_Type>;
};

export type core_apimessages_StartCheckoutResponse = {
  __typename?: "core_apimessages_StartCheckoutResponse";
  _source?: Maybe<Scalars["String"]["output"]>;
  checkout?: Maybe<Checkout>;
  /** Checkout UUID */
  id?: Maybe<Scalars["String"]["output"]>;
};

export type core_apimessages_StartPaypalCheckoutRequest = {
  __typename?: "core_apimessages_StartPaypalCheckoutRequest";
  _source?: Maybe<Scalars["String"]["output"]>;
  checkoutBundlingId?: Maybe<Scalars["String"]["output"]>;
  paymentMethodType?: Maybe<core_apimessages_StartPaypalCheckoutRequest_PaymentMethodType>;
  protectionPlanId?: Maybe<Scalars["String"]["output"]>;
  /** Item quantity for Buy It Now checkout on item page */
  quantity?: Maybe<Scalars["Int"]["output"]>;
};

export enum core_apimessages_StartPaypalCheckoutRequest_PaymentMethodType {
  DC_VIA_PAYPAL = "DC_VIA_PAYPAL",
  PAYPAL = "PAYPAL",
}

export type core_apimessages_StartPaypalCheckoutResponse = {
  __typename?: "core_apimessages_StartPaypalCheckoutResponse";
  _source?: Maybe<Scalars["String"]["output"]>;
  checkout?: Maybe<Checkout>;
  /** checkout UUID */
  id?: Maybe<Scalars["String"]["output"]>;
  paypalOrderId?: Maybe<Scalars["String"]["output"]>;
  redirectUrl?: Maybe<Scalars["String"]["output"]>;
};

export type core_apimessages_Storefront = {
  __typename?: "core_apimessages_Storefront";
  _source?: Maybe<Scalars["String"]["output"]>;
  channel?: Maybe<Scalars["String"]["output"]>;
  description?: Maybe<Scalars["String"]["output"]>;
  id?: Maybe<Scalars["String"]["output"]>;
  name?: Maybe<Scalars["String"]["output"]>;
  shippingPolicy?: Maybe<Scalars["String"]["output"]>;
  shopUuid?: Maybe<Scalars["String"]["output"]>;
  slug?: Maybe<Scalars["String"]["output"]>;
};

export type core_apimessages_StorefrontReturnPoliciesRequest = {
  __typename?: "core_apimessages_StorefrontReturnPoliciesRequest";
  _source?: Maybe<Scalars["String"]["output"]>;
  slugs?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
};

export type core_apimessages_StorefrontReturnPoliciesResponse = {
  __typename?: "core_apimessages_StorefrontReturnPoliciesResponse";
  _source?: Maybe<Scalars["String"]["output"]>;
  returnPolicies?: Maybe<Array<Maybe<core_apimessages_ReturnPolicy>>>;
};

export type core_apimessages_StorefrontsRequest = {
  __typename?: "core_apimessages_StorefrontsRequest";
  _source?: Maybe<Scalars["String"]["output"]>;
  slugs?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
};

export type core_apimessages_StorefrontsResponse = {
  __typename?: "core_apimessages_StorefrontsResponse";
  _source?: Maybe<Scalars["String"]["output"]>;
  storefronts?: Maybe<Array<Maybe<core_apimessages_Storefront>>>;
};

export type core_apimessages_Subregion = {
  __typename?: "core_apimessages_Subregion";
  _id?: Maybe<Scalars["ID"]["output"]>;
  _source?: Maybe<Scalars["String"]["output"]>;
  code?: Maybe<Scalars["String"]["output"]>;
  id?: Maybe<Scalars["String"]["output"]>;
  name?: Maybe<Scalars["String"]["output"]>;
};

export type core_apimessages_SuperRegion = {
  __typename?: "core_apimessages_SuperRegion";
  _id?: Maybe<Scalars["ID"]["output"]>;
  _source?: Maybe<Scalars["String"]["output"]>;
  code?: Maybe<Scalars["String"]["output"]>;
  id?: Maybe<Scalars["String"]["output"]>;
  name?: Maybe<Scalars["String"]["output"]>;
};

export type core_apimessages_TargetingContext = {
  __typename?: "core_apimessages_TargetingContext";
  _source?: Maybe<Scalars["String"]["output"]>;
  categories?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
  keywords?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
};

export type core_apimessages_Tax1099kReportingThresholdSearchResult = {
  __typename?: "core_apimessages_Tax1099kReportingThresholdSearchResult";
  _source?: Maybe<Scalars["String"]["output"]>;
  id?: Maybe<Scalars["String"]["output"]>;
  region?: Maybe<Scalars["String"]["output"]>;
  salesAmountCents?: Maybe<Scalars["String"]["output"]>;
  transactionCount?: Maybe<Scalars["String"]["output"]>;
  year?: Maybe<Scalars["String"]["output"]>;
};

export type core_apimessages_TaxFormDownload = {
  __typename?: "core_apimessages_TaxFormDownload";
  _source?: Maybe<Scalars["String"]["output"]>;
  id?: Maybe<Scalars["String"]["output"]>;
  status?: Maybe<core_apimessages_TaxFormDownload_Status>;
  url?: Maybe<Scalars["String"]["output"]>;
};

export type core_apimessages_TaxFormDownloadRequest = {
  __typename?: "core_apimessages_TaxFormDownloadRequest";
  _source?: Maybe<Scalars["String"]["output"]>;
  id?: Maybe<Scalars["String"]["output"]>;
};

export type core_apimessages_TaxFormDownloadResponse = {
  __typename?: "core_apimessages_TaxFormDownloadResponse";
  _source?: Maybe<Scalars["String"]["output"]>;
  taxFormDownload?: Maybe<core_apimessages_TaxFormDownload>;
};

export enum core_apimessages_TaxFormDownload_Status {
  COMPLETE = "COMPLETE",
  FAILED = "FAILED",
  PENDING = "PENDING",
}

export type core_apimessages_TaxIdentification = {
  __typename?: "core_apimessages_TaxIdentification";
  _source?: Maybe<Scalars["String"]["output"]>;
  active?: Maybe<Scalars["Boolean"]["output"]>;
  countryCode?: Maybe<Scalars["String"]["output"]>;
  id?: Maybe<Scalars["String"]["output"]>;
  status?: Maybe<core_apimessages_TaxIdentification_Status>;
  taxId?: Maybe<Scalars["String"]["output"]>;
  taxType?: Maybe<core_apimessages_TaxIdentification_TaxType>;
};

export type core_apimessages_TaxIdentificationUpdate = {
  __typename?: "core_apimessages_TaxIdentificationUpdate";
  _source?: Maybe<Scalars["String"]["output"]>;
  countryCode?: Maybe<Scalars["String"]["output"]>;
  id?: Maybe<Scalars["String"]["output"]>;
  taxId?: Maybe<Scalars["String"]["output"]>;
};

export enum core_apimessages_TaxIdentification_Status {
  FAILED_VERIFICATION = "FAILED_VERIFICATION",
  PENDING_VERIFICATION = "PENDING_VERIFICATION",
  VERIFIED = "VERIFIED",
}

export enum core_apimessages_TaxIdentification_TaxType {
  EPR = "EPR",
  GST = "GST",
  QST = "QST",
  SALES_TAX = "SALES_TAX",
  VAT = "VAT",
}

export type core_apimessages_TaxPoliciesRequest = {
  __typename?: "core_apimessages_TaxPoliciesRequest";
  _source?: Maybe<Scalars["String"]["output"]>;
  id?: Maybe<Scalars["String"]["output"]>;
  slug?: Maybe<Scalars["String"]["output"]>;
  type?: Maybe<core_apimessages_TaxPoliciesRequest_TaxPoliciesType>;
  uuid?: Maybe<Scalars["String"]["output"]>;
};

export enum core_apimessages_TaxPoliciesRequest_TaxPoliciesType {
  PRODUCT = "PRODUCT",
  SHOP = "SHOP",
}

export type core_apimessages_TaxProfile = {
  __typename?: "core_apimessages_TaxProfile";
  _source?: Maybe<Scalars["String"]["output"]>;
  address?: Maybe<core_apimessages_Address>;
  createdAt?: Maybe<Scalars["Timestamp"]["output"]>;
  firstName?: Maybe<Scalars["String"]["output"]>;
  id?: Maybe<Scalars["String"]["output"]>;
  lastName?: Maybe<Scalars["String"]["output"]>;
  mask?: Maybe<Scalars["String"]["output"]>;
  name?: Maybe<Scalars["String"]["output"]>;
  state?: Maybe<Scalars["String"]["output"]>;
  tinType?: Maybe<Scalars["String"]["output"]>;
  updatedAt?: Maybe<Scalars["Timestamp"]["output"]>;
  verificationStatus?: Maybe<core_apimessages_TaxProfile_TaxProfileVerificationStatus>;
  verifiedAt?: Maybe<Scalars["Timestamp"]["output"]>;
};

export enum core_apimessages_TaxProfile_TaxProfileVerificationStatus {
  FAILED_VERIFICATION = "FAILED_VERIFICATION",
  PENDING_VERIFICATION = "PENDING_VERIFICATION",
  VERIFIED = "VERIFIED",
}

export type core_apimessages_ThirdPartyAdsAttributes = {
  __typename?: "core_apimessages_ThirdPartyAdsAttributes";
  _source?: Maybe<Scalars["String"]["output"]>;
  amount?: Maybe<Scalars["String"]["output"]>;
  billingAddress?: Maybe<Scalars["String"]["output"]>;
  billingAddressExtended?: Maybe<Scalars["String"]["output"]>;
  billingPostalCode?: Maybe<Scalars["String"]["output"]>;
  cartItemsJson?: Maybe<Scalars["String"]["output"]>;
  country?: Maybe<Scalars["String"]["output"]>;
  currency?: Maybe<Scalars["String"]["output"]>;
  email?: Maybe<Scalars["String"]["output"]>;
  firstName?: Maybe<Scalars["String"]["output"]>;
  language?: Maybe<Scalars["String"]["output"]>;
  lastName?: Maybe<Scalars["String"]["output"]>;
  mobilePhoneNumber?: Maybe<Scalars["String"]["output"]>;
  orderBundleId?: Maybe<Scalars["String"]["output"]>;
  paymentType?: Maybe<Scalars["String"]["output"]>;
  shippingAddress?: Maybe<Scalars["String"]["output"]>;
  shippingAddressExtended?: Maybe<Scalars["String"]["output"]>;
  shippingPostalCode?: Maybe<Scalars["String"]["output"]>;
};

export type core_apimessages_ThreeDSOneData = {
  __typename?: "core_apimessages_ThreeDSOneData";
  _source?: Maybe<Scalars["String"]["output"]>;
  md?: Maybe<Scalars["String"]["output"]>;
  paReq?: Maybe<Scalars["String"]["output"]>;
  redirectUrl?: Maybe<Scalars["String"]["output"]>;
  termUrl?: Maybe<Scalars["String"]["output"]>;
};

export type core_apimessages_TrackBumpInteractionRequest = {
  __typename?: "core_apimessages_TrackBumpInteractionRequest";
  _source?: Maybe<Scalars["String"]["output"]>;
  bumpKey?: Maybe<core_apimessages_BumpKey>;
  componentName?: Maybe<Scalars["String"]["output"]>;
  interactionType?: Maybe<arbiter_Interaction_InteractionType>;
  listingId?: Maybe<Scalars["String"]["output"]>;
};

export type core_apimessages_TrackBumpInteractionResponse = {
  __typename?: "core_apimessages_TrackBumpInteractionResponse";
  _source?: Maybe<Scalars["String"]["output"]>;
  bumpKey?: Maybe<core_apimessages_BumpKey>;
};

export type core_apimessages_Trait = {
  __typename?: "core_apimessages_Trait";
  _source?: Maybe<Scalars["String"]["output"]>;
  /** traits.uuid */
  keyId?: Maybe<Scalars["String"]["output"]>;
  value?: Maybe<Scalars["String"]["output"]>;
  /** traits.value_uuid */
  valueId?: Maybe<Scalars["String"]["output"]>;
};

export type core_apimessages_TraitResponse = {
  __typename?: "core_apimessages_TraitResponse";
  _source?: Maybe<Scalars["String"]["output"]>;
  /** traits.traitable_id (polymorphic foreign key, with 'type') */
  traitableId?: Maybe<Scalars["String"]["output"]>;
  traits?: Maybe<Array<Maybe<core_apimessages_Trait>>>;
};

export type core_apimessages_TraitsRequest = {
  __typename?: "core_apimessages_TraitsRequest";
  _source?: Maybe<Scalars["String"]["output"]>;
  /** traits.traitable_id (polymorphic foreign key, with 'type') */
  ids?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
  type?: Maybe<core_apimessages_TraitsRequest_TraitableType>;
};

export enum core_apimessages_TraitsRequest_TraitableType {
  Product = "Product",
}

export type core_apimessages_TraitsResponse = {
  __typename?: "core_apimessages_TraitsResponse";
  _source?: Maybe<Scalars["String"]["output"]>;
  traits?: Maybe<Array<Maybe<core_apimessages_TraitResponse>>>;
};

export type core_apimessages_TruliooOnboarding = {
  __typename?: "core_apimessages_TruliooOnboarding";
  _source?: Maybe<Scalars["String"]["output"]>;
  completedAt?: Maybe<Scalars["Timestamp"]["output"]>;
  id?: Maybe<Scalars["String"]["output"]>;
  truliooOnboardingRecords?: Maybe<
    Array<Maybe<core_apimessages_TruliooOnboardingRecord>>
  >;
  verificationStatus?: Maybe<core_apimessages_TruliooOnboarding_VerificationStatus>;
};

export type core_apimessages_TruliooOnboardingtruliooOnboardingRecordsArgs = {
  input?: InputMaybe<Input_core_apimessages_NodeRequest_Lookup_Params_TruliooOnboardingRecords>;
};

export type core_apimessages_TruliooOnboardingRecord = {
  __typename?: "core_apimessages_TruliooOnboardingRecord";
  _source?: Maybe<Scalars["String"]["output"]>;
  clientId?: Maybe<Scalars["String"]["output"]>;
  email?: Maybe<Scalars["String"]["output"]>;
  entityType?: Maybe<core_apimessages_TruliooOnboardingRecord_EntityType>;
  flowId?: Maybe<Scalars["String"]["output"]>;
  fullName?: Maybe<Scalars["String"]["output"]>;
  id?: Maybe<Scalars["String"]["output"]>;
  isParent?: Maybe<Scalars["Boolean"]["output"]>;
  pscOwners?: Maybe<Array<Maybe<core_apimessages_TruliooOnboardingRecord>>>;
  workflowStatus?: Maybe<core_apimessages_TruliooOnboardingRecord_Status>;
};

export type core_apimessages_TruliooOnboardingRecordResendPscEmailRequest = {
  __typename?: "core_apimessages_TruliooOnboardingRecordResendPscEmailRequest";
  _source?: Maybe<Scalars["String"]["output"]>;
  truliooOnboardingRecordId?: Maybe<Scalars["String"]["output"]>;
};

export type core_apimessages_TruliooOnboardingRecordResendPscEmailResponse = {
  __typename?: "core_apimessages_TruliooOnboardingRecordResendPscEmailResponse";
  _source?: Maybe<Scalars["String"]["output"]>;
  truliooOnboardingRecordId?: Maybe<Scalars["String"]["output"]>;
};

export type core_apimessages_TruliooOnboardingRecordResponse = {
  __typename?: "core_apimessages_TruliooOnboardingRecordResponse";
  _source?: Maybe<Scalars["String"]["output"]>;
  truliooOnboardingRecord?: Maybe<core_apimessages_TruliooOnboardingRecord>;
};

export enum core_apimessages_TruliooOnboardingRecord_EntityType {
  BUSINESS = "BUSINESS",
  INDIVIDUAL = "INDIVIDUAL",
}

export enum core_apimessages_TruliooOnboardingRecord_Status {
  ACCEPTED = "ACCEPTED",
  ADDED_PENDING_OUTCOME_NOTE_BUT_AWAITING_REJECT = "ADDED_PENDING_OUTCOME_NOTE_BUT_AWAITING_REJECT",
  ADDED_SUSPENDED_NOTE_BUT_AWAITING_REJECT = "ADDED_SUSPENDED_NOTE_BUT_AWAITING_REJECT",
  ERROR = "ERROR",
  NOT_APPLICABLE = "NOT_APPLICABLE",
  NOT_STARTED = "NOT_STARTED",
  PENDING_REVIEW = "PENDING_REVIEW",
  REJECTED = "REJECTED",
  REJECTED_BUT_AWAITING_NOTE = "REJECTED_BUT_AWAITING_NOTE",
  STARTED = "STARTED",
}

export type core_apimessages_TruliooOnboardingResponse = {
  __typename?: "core_apimessages_TruliooOnboardingResponse";
  _source?: Maybe<Scalars["String"]["output"]>;
  errors?: Maybe<Array<Maybe<core_apimessages_Error>>>;
  truliooOnboarding?: Maybe<core_apimessages_TruliooOnboarding>;
};

export enum core_apimessages_TruliooOnboarding_VerificationStatus {
  ACCEPTED = "ACCEPTED",
  ERROR = "ERROR",
  NOT_STARTED = "NOT_STARTED",
  REJECTED = "REJECTED",
  STARTED = "STARTED",
}

export type core_apimessages_TypicalNewPriceDisplay = {
  __typename?: "core_apimessages_TypicalNewPriceDisplay";
  _source?: Maybe<Scalars["String"]["output"]>;
  amountDisplay?: Maybe<Scalars["String"]["output"]>;
  descriptionDisplay?: Maybe<Scalars["String"]["output"]>;
  savingsDisplay?: Maybe<Scalars["String"]["output"]>;
};

export type core_apimessages_UndoDeleteMyFavoriteRequest = {
  __typename?: "core_apimessages_UndoDeleteMyFavoriteRequest";
  _source?: Maybe<Scalars["String"]["output"]>;
  id?: Maybe<Scalars["String"]["output"]>;
  type?: Maybe<core_apimessages_FavoriteType>;
};

export type core_apimessages_UndoDeleteMyFavoriteResponse = {
  __typename?: "core_apimessages_UndoDeleteMyFavoriteResponse";
  _source?: Maybe<Scalars["String"]["output"]>;
  errors?: Maybe<Array<Maybe<core_apimessages_Error>>>;
  favorite?: Maybe<core_apimessages_Favorite>;
  listing?: Maybe<Listing>;
};

export type core_apimessages_UniversalPromoCampaign = {
  __typename?: "core_apimessages_UniversalPromoCampaign";
  _source?: Maybe<Scalars["String"]["output"]>;
  categories?: Maybe<Array<Maybe<core_apimessages_NodesCategory>>>;
  code?: Maybe<Scalars["String"]["output"]>;
  expiresAt?: Maybe<Scalars["Timestamp"]["output"]>;
  hasRedemptionLimit?: Maybe<Scalars["Boolean"]["output"]>;
  id?: Maybe<Scalars["String"]["output"]>;
  lapsedBuyer?: Maybe<Scalars["Boolean"]["output"]>;
  lapsedBuyerLastPurchaseDate?: Maybe<Scalars["Timestamp"]["output"]>;
  marketingChannel?: Maybe<Scalars["String"]["output"]>;
  name?: Maybe<Scalars["String"]["output"]>;
  newBuyer?: Maybe<Scalars["Boolean"]["output"]>;
  redemptionLimit?: Maybe<Scalars["Int"]["output"]>;
  universalPromoCurrencies?: Maybe<
    Array<Maybe<core_apimessages_UniversalPromoCurrency>>
  >;
};

export type core_apimessages_UniversalPromoCurrency = {
  __typename?: "core_apimessages_UniversalPromoCurrency";
  _source?: Maybe<Scalars["String"]["output"]>;
  amount?: Maybe<core_apimessages_Money>;
  minPurchaseAmount?: Maybe<core_apimessages_Money>;
};

export type core_apimessages_UpdateAdCampaignRequest = {
  __typename?: "core_apimessages_UpdateAdCampaignRequest";
  _source?: Maybe<Scalars["String"]["output"]>;
  adCampaign?: Maybe<core_apimessages_AdCampaign>;
};

export type core_apimessages_UpdateAdCampaignResponse = {
  __typename?: "core_apimessages_UpdateAdCampaignResponse";
  _source?: Maybe<Scalars["String"]["output"]>;
  adCampaign?: Maybe<core_apimessages_AdCampaign>;
};

export type core_apimessages_UpdateAdGroupKeywordsRequest = {
  __typename?: "core_apimessages_UpdateAdGroupKeywordsRequest";
  _source?: Maybe<Scalars["String"]["output"]>;
  adGroupUuid?: Maybe<Scalars["String"]["output"]>;
  keywords?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
};

export type core_apimessages_UpdateAdGroupKeywordsResponse = {
  __typename?: "core_apimessages_UpdateAdGroupKeywordsResponse";
  _source?: Maybe<Scalars["String"]["output"]>;
  adGroupKeywords?: Maybe<Array<Maybe<core_apimessages_AdGroupKeyword>>>;
};

export type core_apimessages_UpdateAdGroupRequest = {
  __typename?: "core_apimessages_UpdateAdGroupRequest";
  _source?: Maybe<Scalars["String"]["output"]>;
  adGroup?: Maybe<core_apimessages_AdGroup>;
};

export type core_apimessages_UpdateAdGroupResponse = {
  __typename?: "core_apimessages_UpdateAdGroupResponse";
  _source?: Maybe<Scalars["String"]["output"]>;
  adGroup?: Maybe<core_apimessages_AdGroup>;
};

export type core_apimessages_UpdateAdRequest = {
  __typename?: "core_apimessages_UpdateAdRequest";
  _source?: Maybe<Scalars["String"]["output"]>;
  ad?: Maybe<core_apimessages_Ad>;
};

export type core_apimessages_UpdateAdResponse = {
  __typename?: "core_apimessages_UpdateAdResponse";
  _source?: Maybe<Scalars["String"]["output"]>;
  ad?: Maybe<core_apimessages_Ad>;
};

export type core_apimessages_UpdateAdZoneRequest = {
  __typename?: "core_apimessages_UpdateAdZoneRequest";
  _source?: Maybe<Scalars["String"]["output"]>;
  adZone?: Maybe<core_apimessages_AdZone>;
};

export type core_apimessages_UpdateAdZoneResponse = {
  __typename?: "core_apimessages_UpdateAdZoneResponse";
  _source?: Maybe<Scalars["String"]["output"]>;
  adZone?: Maybe<core_apimessages_AdZone>;
};

export type core_apimessages_UpdateAddressRequest = {
  __typename?: "core_apimessages_UpdateAddressRequest";
  _source?: Maybe<Scalars["String"]["output"]>;
  address?: Maybe<core_apimessages_AddressEntry>;
  addressType?: Maybe<core_apimessages_MyAddressType>;
  /** addresses.uuid */
  id?: Maybe<Scalars["String"]["output"]>;
  isPrimary?: Maybe<Scalars["Boolean"]["output"]>;
  uuid?: Maybe<Scalars["String"]["output"]>;
};

export type core_apimessages_UpdateAddressResponse = {
  __typename?: "core_apimessages_UpdateAddressResponse";
  _source?: Maybe<Scalars["String"]["output"]>;
  address?: Maybe<core_apimessages_Address>;
  suggestion?: Maybe<core_apimessages_Address>;
};

export type core_apimessages_UpdateAdvertiserRequest = {
  __typename?: "core_apimessages_UpdateAdvertiserRequest";
  _source?: Maybe<Scalars["String"]["output"]>;
  advertiser?: Maybe<core_apimessages_Advertiser>;
};

export type core_apimessages_UpdateAdvertiserResponse = {
  __typename?: "core_apimessages_UpdateAdvertiserResponse";
  _source?: Maybe<Scalars["String"]["output"]>;
  advertiser?: Maybe<core_apimessages_Advertiser>;
};

export type core_apimessages_UpdateBillingMethodRequest = {
  __typename?: "core_apimessages_UpdateBillingMethodRequest";
  _source?: Maybe<Scalars["String"]["output"]>;
  browserInfo?: Maybe<Scalars["String"]["output"]>;
  creditCard?: Maybe<core_apimessages_BillingCreditCardEntry>;
  origin?: Maybe<Scalars["String"]["output"]>;
  /** optional, to indicate that a new card is being added specifically for a partial payment */
  partialPayment?: Maybe<Scalars["Boolean"]["output"]>;
  returnUrl?: Maybe<Scalars["String"]["output"]>;
};

export type core_apimessages_UpdateBillingMethodResponse = {
  __typename?: "core_apimessages_UpdateBillingMethodResponse";
  _source?: Maybe<Scalars["String"]["output"]>;
  adyenPaymentResult?: Maybe<core_apimessages_AdyenPaymentResult>;
  billingMethodUuid?: Maybe<Scalars["String"]["output"]>;
  creditCard?: Maybe<core_apimessages_CreditCard>;
  errors?: Maybe<Array<Maybe<core_apimessages_Error>>>;
  me?: Maybe<rql_Me>;
};

export type core_apimessages_UpdateCartItemProtectionPlanRequest = {
  __typename?: "core_apimessages_UpdateCartItemProtectionPlanRequest";
  _source?: Maybe<Scalars["String"]["output"]>;
  cartItemUuid?: Maybe<Scalars["String"]["output"]>;
  protectionPlan?: Maybe<core_apimessages_ProtectionPlan>;
};

export type core_apimessages_UpdateCartItemProtectionPlanResponse = {
  __typename?: "core_apimessages_UpdateCartItemProtectionPlanResponse";
  _source?: Maybe<Scalars["String"]["output"]>;
  cartItemUuid?: Maybe<Scalars["String"]["output"]>;
  protectionPlan?: Maybe<core_apimessages_ProtectionPlan>;
};

export type core_apimessages_UpdateCheckoutBillingAddressRequest = {
  __typename?: "core_apimessages_UpdateCheckoutBillingAddressRequest";
  _source?: Maybe<Scalars["String"]["output"]>;
  accertifyDeviceInfo?: Maybe<core_apimessages_AccertifyDeviceInfo>;
  billingAddress?: Maybe<core_apimessages_AddressEntry>;
  /** checkout UUID */
  id?: Maybe<Scalars["String"]["output"]>;
};

export type core_apimessages_UpdateCheckoutBillingAddressResponse = {
  __typename?: "core_apimessages_UpdateCheckoutBillingAddressResponse";
  _source?: Maybe<Scalars["String"]["output"]>;
  checkout?: Maybe<Checkout>;
  /** checkout UUID */
  id?: Maybe<Scalars["String"]["output"]>;
};

export type core_apimessages_UpdateCheckoutPaypalOrderRequest = {
  __typename?: "core_apimessages_UpdateCheckoutPaypalOrderRequest";
  _source?: Maybe<Scalars["String"]["output"]>;
  /** checkout UUID */
  id?: Maybe<Scalars["String"]["output"]>;
  paypalOrderId?: Maybe<Scalars["String"]["output"]>;
};

export type core_apimessages_UpdateCheckoutPaypalOrderResponse = {
  __typename?: "core_apimessages_UpdateCheckoutPaypalOrderResponse";
  _source?: Maybe<Scalars["String"]["output"]>;
  /** checkout UUID */
  id?: Maybe<Scalars["String"]["output"]>;
};

export type core_apimessages_UpdateCrossPlatformListingSessionRequest = {
  __typename?: "core_apimessages_UpdateCrossPlatformListingSessionRequest";
  _source?: Maybe<Scalars["String"]["output"]>;
  action?: Maybe<core_apimessages_UpdateCrossPlatformListingSessionRequest_Action>;
  listingId?: Maybe<Scalars["String"]["output"]>;
};

export enum core_apimessages_UpdateCrossPlatformListingSessionRequest_Action {
  CANCEL = "CANCEL",
  START = "START",
}

export type core_apimessages_UpdateCrossPlatformListingSessionResponse = {
  __typename?: "core_apimessages_UpdateCrossPlatformListingSessionResponse";
  _?: Maybe<Scalars["Boolean"]["output"]>;
  _source?: Maybe<Scalars["String"]["output"]>;
};

export type core_apimessages_UpdateFeedbackRequest = {
  __typename?: "core_apimessages_UpdateFeedbackRequest";
  _source?: Maybe<Scalars["String"]["output"]>;
  conditionSatisfactory?: Maybe<google_protobuf_BoolValue>;
  message?: Maybe<Scalars["String"]["output"]>;
  orderUuid?: Maybe<Scalars["String"]["output"]>;
  rating?: Maybe<Scalars["Int"]["output"]>;
  shippingSatisfactory?: Maybe<google_protobuf_BoolValue>;
};

export type core_apimessages_UpdateFeedbackResponse = {
  __typename?: "core_apimessages_UpdateFeedbackResponse";
  _source?: Maybe<Scalars["String"]["output"]>;
  feedback?: Maybe<core_apimessages_Feedback>;
};

export type core_apimessages_UpdateGearCollectionItemRequest = {
  __typename?: "core_apimessages_UpdateGearCollectionItemRequest";
  _source?: Maybe<Scalars["String"]["output"]>;
  gearCollectionItemEntry?: Maybe<core_apimessages_GearCollectionItemEntry>;
};

export type core_apimessages_UpdateGearCollectionItemResponse = {
  __typename?: "core_apimessages_UpdateGearCollectionItemResponse";
  _source?: Maybe<Scalars["String"]["output"]>;
  gearCollectionItem?: Maybe<core_apimessages_GearCollectionItem>;
};

export type core_apimessages_UpdateGearCollectionItemStateRequest = {
  __typename?: "core_apimessages_UpdateGearCollectionItemStateRequest";
  _source?: Maybe<Scalars["String"]["output"]>;
  id?: Maybe<Scalars["String"]["output"]>;
  newState?: Maybe<core_apimessages_GearCollectionItem_State>;
};

export type core_apimessages_UpdateGearCollectionItemStateResponse = {
  __typename?: "core_apimessages_UpdateGearCollectionItemStateResponse";
  _source?: Maybe<Scalars["String"]["output"]>;
  gearCollectionItem?: Maybe<core_apimessages_GearCollectionItem>;
};

export type core_apimessages_UpdateInternationalTaxProfileRequest = {
  __typename?: "core_apimessages_UpdateInternationalTaxProfileRequest";
  _source?: Maybe<Scalars["String"]["output"]>;
  addressAttributes?: Maybe<core_apimessages_AddressEntry>;
  businessRegistrationNumber?: Maybe<Scalars["String"]["output"]>;
  dateOfBirth?: Maybe<Scalars["String"]["output"]>;
  name?: Maybe<Scalars["String"]["output"]>;
  tin?: Maybe<Scalars["String"]["output"]>;
  tinType?: Maybe<Scalars["String"]["output"]>;
};

export type core_apimessages_UpdateInternationalTaxProfileResponse = {
  __typename?: "core_apimessages_UpdateInternationalTaxProfileResponse";
  _source?: Maybe<Scalars["String"]["output"]>;
  businessRegistrationNumber?: Maybe<core_apimessages_BusinessRegistrationNumber>;
  internationalTaxProfile?: Maybe<core_apimessages_InternationalTaxProfile>;
};

export type core_apimessages_UpdateListingRequest = {
  __typename?: "core_apimessages_UpdateListingRequest";
  _source?: Maybe<Scalars["String"]["output"]>;
  fields?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
  listingEntry?: Maybe<core_apimessages_ListingEntry>;
  updateType?: Maybe<core_apimessages_UpdateListingRequest_UpdateType>;
};

export enum core_apimessages_UpdateListingRequest_UpdateType {
  CROSS_PLATFORM_PHOTOS = "CROSS_PLATFORM_PHOTOS",
  DEFAULT = "DEFAULT",
  SPECIFIC_FIELDS = "SPECIFIC_FIELDS",
}

export type core_apimessages_UpdateListingResponse = {
  __typename?: "core_apimessages_UpdateListingResponse";
  _source?: Maybe<Scalars["String"]["output"]>;
  listing?: Maybe<core_apimessages_Listing>;
};

export type core_apimessages_UpdateListingStateRequest = {
  __typename?: "core_apimessages_UpdateListingStateRequest";
  _source?: Maybe<Scalars["String"]["output"]>;
  endingReason?: Maybe<Scalars["String"]["output"]>;
  newState?: Maybe<core_apimessages_ListingStateUpdate>;
  productId?: Maybe<Scalars["String"]["output"]>;
};

export type core_apimessages_UpdateListingStateResponse = {
  __typename?: "core_apimessages_UpdateListingStateResponse";
  _source?: Maybe<Scalars["String"]["output"]>;
  newState?: Maybe<core_apimessages_ListingStateUpdate>;
};

export type core_apimessages_UpdateMyCreditCardRequest = {
  __typename?: "core_apimessages_UpdateMyCreditCardRequest";
  _source?: Maybe<Scalars["String"]["output"]>;
  billingAddress?: Maybe<core_apimessages_AddressEntry>;
  creditCard?: Maybe<core_apimessages_UpdateMyCreditCardRequest_CreditCardEntry>;
  id?: Maybe<Scalars["String"]["output"]>;
};

export type core_apimessages_UpdateMyCreditCardRequest_CreditCardEntry = {
  __typename?: "core_apimessages_UpdateMyCreditCardRequest_CreditCardEntry";
  _source?: Maybe<Scalars["String"]["output"]>;
  cardholderName?: Maybe<Scalars["String"]["output"]>;
  /** Expiration month on card. Example: "2" for February */
  expirationMonth?: Maybe<Scalars["String"]["output"]>;
  /** Full expiration year on card. Example: "2049" */
  expirationYear?: Maybe<Scalars["String"]["output"]>;
  primaryForCheckout?: Maybe<Scalars["Boolean"]["output"]>;
};

export type core_apimessages_UpdateMyCreditCardResponse = {
  __typename?: "core_apimessages_UpdateMyCreditCardResponse";
  _source?: Maybe<Scalars["String"]["output"]>;
  creditCard?: Maybe<core_apimessages_CreditCard>;
};

export type core_apimessages_UpdateMyGearCollectionSettingsRequest = {
  __typename?: "core_apimessages_UpdateMyGearCollectionSettingsRequest";
  _source?: Maybe<Scalars["String"]["output"]>;
  autoAddOrders?: Maybe<Scalars["Boolean"]["output"]>;
};

export type core_apimessages_UpdateMyGearCollectionSettingsResponse = {
  __typename?: "core_apimessages_UpdateMyGearCollectionSettingsResponse";
  _source?: Maybe<Scalars["String"]["output"]>;
  gearCollectionSettings?: Maybe<core_apimessages_GearCollectionSettingsResponse>;
};

export type core_apimessages_UpdateMyShippingProfile = {
  __typename?: "core_apimessages_UpdateMyShippingProfile";
  _source?: Maybe<Scalars["String"]["output"]>;
  categoryUuids?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
  id?: Maybe<Scalars["String"]["output"]>;
  localPickup?: Maybe<Scalars["Boolean"]["output"]>;
  name?: Maybe<Scalars["String"]["output"]>;
  offersIncrementalRates?: Maybe<Scalars["Boolean"]["output"]>;
  shippingRates?: Maybe<Array<Maybe<core_apimessages_ShippingRates>>>;
};

export type core_apimessages_UpdateMyShopCampaignRequest = {
  __typename?: "core_apimessages_UpdateMyShopCampaignRequest";
  _source?: Maybe<Scalars["String"]["output"]>;
  shopCampaignEntry?: Maybe<core_apimessages_MyShopCampaignEntry>;
};

export type core_apimessages_UpdateMyShopCampaignResponse = {
  __typename?: "core_apimessages_UpdateMyShopCampaignResponse";
  _source?: Maybe<Scalars["String"]["output"]>;
  shopCampaign?: Maybe<core_apimessages_MyShopCampaign>;
};

export type core_apimessages_UpdateMyShopRequest = {
  __typename?: "core_apimessages_UpdateMyShopRequest";
  _source?: Maybe<Scalars["String"]["output"]>;
  shop?: Maybe<core_apimessages_ShopEntry>;
};

export type core_apimessages_UpdateMyShopResponse = {
  __typename?: "core_apimessages_UpdateMyShopResponse";
  _source?: Maybe<Scalars["String"]["output"]>;
  address?: Maybe<core_apimessages_Address>;
  errors?: Maybe<Array<Maybe<core_apimessages_Error>>>;
  shop?: Maybe<core_apimessages_Shop>;
};

export type core_apimessages_UpdateMyShopShippingProfilesRequest = {
  __typename?: "core_apimessages_UpdateMyShopShippingProfilesRequest";
  _source?: Maybe<Scalars["String"]["output"]>;
  shippingProfiles?: Maybe<
    Array<Maybe<core_apimessages_UpdateMyShippingProfile>>
  >;
};

export type core_apimessages_UpdateMyShopShippingProfilesResponse = {
  __typename?: "core_apimessages_UpdateMyShopShippingProfilesResponse";
  _source?: Maybe<Scalars["String"]["output"]>;
  shippingProfiles?: Maybe<
    Array<Maybe<core_apimessages_UpdateMyShippingProfile>>
  >;
};

export type core_apimessages_UpdateNegotiationRequest = {
  __typename?: "core_apimessages_UpdateNegotiationRequest";
  _source?: Maybe<Scalars["String"]["output"]>;
  action?: Maybe<core_apimessages_NegotiationAction>;
  conditionallyAcceptedBySeller?: Maybe<Scalars["Boolean"]["output"]>;
  creditCardId?: Maybe<Scalars["String"]["output"]>;
  /** negotiations.id */
  id?: Maybe<Scalars["String"]["output"]>;
  message?: Maybe<Scalars["String"]["output"]>;
  offerItems?: Maybe<Array<Maybe<core_apimessages_OfferItemRequest>>>;
  shippingAddressUuid?: Maybe<Scalars["String"]["output"]>;
};

export type core_apimessages_UpdateNegotiationResponse = {
  __typename?: "core_apimessages_UpdateNegotiationResponse";
  _source?: Maybe<Scalars["String"]["output"]>;
  negotiation?: Maybe<core_apimessages_Negotiation>;
};

export type core_apimessages_UpdateProductReviewRequest = {
  __typename?: "core_apimessages_UpdateProductReviewRequest";
  _source?: Maybe<Scalars["String"]["output"]>;
  body?: Maybe<Scalars["String"]["output"]>;
  comparisonShoppingPageId?: Maybe<Scalars["String"]["output"]>;
  rating?: Maybe<Scalars["Int"]["output"]>;
  title?: Maybe<Scalars["String"]["output"]>;
};

export type core_apimessages_UpdateProductReviewResponse = {
  __typename?: "core_apimessages_UpdateProductReviewResponse";
  _source?: Maybe<Scalars["String"]["output"]>;
  id?: Maybe<Scalars["String"]["output"]>;
};

export type core_apimessages_UpdateReturnPolicyRequest = {
  __typename?: "core_apimessages_UpdateReturnPolicyRequest";
  _source?: Maybe<Scalars["String"]["output"]>;
  newReturnWindowDays?: Maybe<Scalars["Int"]["output"]>;
  restockingFeePercent?: Maybe<Scalars["Int"]["output"]>;
  specialConditions?: Maybe<Scalars["String"]["output"]>;
  usedReturnWindowDays?: Maybe<Scalars["Int"]["output"]>;
};

export type core_apimessages_UpdateReturnPolicyResponse = {
  __typename?: "core_apimessages_UpdateReturnPolicyResponse";
  _source?: Maybe<Scalars["String"]["output"]>;
  errors?: Maybe<Array<Maybe<core_apimessages_Error>>>;
  returnPolicy?: Maybe<core_apimessages_ReturnPolicy>;
};

export type core_apimessages_UpdateTaxIdentificationRequest = {
  __typename?: "core_apimessages_UpdateTaxIdentificationRequest";
  _source?: Maybe<Scalars["String"]["output"]>;
  taxIdentification?: Maybe<core_apimessages_TaxIdentificationUpdate>;
};

export type core_apimessages_UpdateTaxIdentificationResponse = {
  __typename?: "core_apimessages_UpdateTaxIdentificationResponse";
  _source?: Maybe<Scalars["String"]["output"]>;
  shop?: Maybe<MyShop>;
  shopId?: Maybe<Scalars["String"]["output"]>;
  taxIdentification?: Maybe<core_apimessages_TaxIdentification>;
};

export type core_apimessages_UpdateUniversalPromoCampaignRequest = {
  __typename?: "core_apimessages_UpdateUniversalPromoCampaignRequest";
  _source?: Maybe<Scalars["String"]["output"]>;
  universalPromoCampaign?: Maybe<core_apimessages_UniversalPromoCampaign>;
};

export type core_apimessages_UpdateUniversalPromoCampaignResponse = {
  __typename?: "core_apimessages_UpdateUniversalPromoCampaignResponse";
  _source?: Maybe<Scalars["String"]["output"]>;
  universalPromoCampaign?: Maybe<core_apimessages_UniversalPromoCampaign>;
};

export type core_apimessages_UpdateUserShopPermissionRequest = {
  __typename?: "core_apimessages_UpdateUserShopPermissionRequest";
  _source?: Maybe<Scalars["String"]["output"]>;
  permissionLevel?: Maybe<core_apimessages_UserShopPermission_PermissionLevel>;
  userId?: Maybe<Scalars["String"]["output"]>;
};

export type core_apimessages_UpdateWatchResponse = {
  __typename?: "core_apimessages_UpdateWatchResponse";
  _source?: Maybe<Scalars["String"]["output"]>;
  id?: Maybe<Scalars["String"]["output"]>;
  listing?: Maybe<Listing>;
  listingId?: Maybe<Scalars["String"]["output"]>;
  userId?: Maybe<Scalars["String"]["output"]>;
};

/**
 *   UpsertMyFavoriteRequest field spec:
 *   - query_params should be used when favoriting a filtered query, a CSP (can include filters), Shop saved searches, etc.
 *   - shop_id should be used when favoriting a shop
 * We could have reused query_params to contain shop_id (e.g. "shop_id=1"), but doing seems
 * burdensome/possibly confusing for clients. So we are opting to keep this request proto explicit,
 * aware that as we add more types to Favorite (articles warrants article_id field), this proto will
 * grow in width.
 */
export type core_apimessages_UpsertMyFavoriteRequest = {
  __typename?: "core_apimessages_UpsertMyFavoriteRequest";
  _source?: Maybe<Scalars["String"]["output"]>;
  emailEnabled?: Maybe<Scalars["Boolean"]["output"]>;
  feedEnabled?: Maybe<Scalars["Boolean"]["output"]>;
  queryParams?: Maybe<Scalars["String"]["output"]>;
  shopId?: Maybe<Scalars["Int"]["output"]>;
};

export type core_apimessages_UpsertMyFavoriteResponse = {
  __typename?: "core_apimessages_UpsertMyFavoriteResponse";
  _source?: Maybe<Scalars["String"]["output"]>;
  errors?: Maybe<Array<Maybe<core_apimessages_Error>>>;
  favorite?: Maybe<core_apimessages_Favorite>;
};

export type core_apimessages_User = {
  __typename?: "core_apimessages_User";
  _id?: Maybe<Scalars["ID"]["output"]>;
  _source?: Maybe<Scalars["String"]["output"]>;
  firstName?: Maybe<Scalars["String"]["output"]>;
  /** users.id */
  id?: Maybe<Scalars["String"]["output"]>;
  name?: Maybe<Scalars["String"]["output"]>;
  shopUuid?: Maybe<Scalars["String"]["output"]>;
  shortname?: Maybe<Scalars["String"]["output"]>;
  uuid?: Maybe<Scalars["String"]["output"]>;
};

export enum core_apimessages_UserActionName {
  CUSTOMIZE_FEED_EDUCATION_CALLOUT = "CUSTOMIZE_FEED_EDUCATION_CALLOUT",
  FAVORITES_EDUCATION_CALLOUT = "FAVORITES_EDUCATION_CALLOUT",
  QUICK_PRICE_EDIT_CALLOUT = "QUICK_PRICE_EDIT_CALLOUT",
  SAVE_SEARCH_EDUCATION_CALLOUT = "SAVE_SEARCH_EDUCATION_CALLOUT",
  SAVE_SHOP_EDUCATION_CALLOUT = "SAVE_SHOP_EDUCATION_CALLOUT",
  SELLER_BULK_ACTIONS_CALLOUT = "SELLER_BULK_ACTIONS_CALLOUT",
  SELLER_OPEN_TO_OFFERS_CALLOUT = "SELLER_OPEN_TO_OFFERS_CALLOUT",
  SUBSEQUENT_LISTING_VIEW_CALLOUT = "SUBSEQUENT_LISTING_VIEW_CALLOUT",
  VIEW_HOW_TO_WATCH_CALLOUT = "VIEW_HOW_TO_WATCH_CALLOUT",
  VIEW_WATCH_HIATUS_CALLOUT = "VIEW_WATCH_HIATUS_CALLOUT",
}

export enum core_apimessages_UserActionStatus {
  AVAILABLE = "AVAILABLE",
  UNAVAILABLE = "UNAVAILABLE",
}

export type core_apimessages_UserBadge = {
  __typename?: "core_apimessages_UserBadge";
  /** @deprecated Field no longer supported */
  Links?: Maybe<core_apimessages_UserBadgeLinks>;
  _source?: Maybe<Scalars["String"]["output"]>;
  badgeLinks?: Maybe<core_apimessages_UserBadgeLinks>;
  count?: Maybe<Scalars["Int"]["output"]>;
  displayText?: Maybe<Scalars["String"]["output"]>;
};

export type core_apimessages_UserBadgeLinks = {
  __typename?: "core_apimessages_UserBadgeLinks";
  _source?: Maybe<Scalars["String"]["output"]>;
  web?: Maybe<core_apimessages_Link>;
};

export type core_apimessages_UserNotification = {
  __typename?: "core_apimessages_UserNotification";
  _source?: Maybe<Scalars["String"]["output"]>;
  description?: Maybe<Scalars["String"]["output"]>;
  hidden?: Maybe<Scalars["Boolean"]["output"]>;
  key?: Maybe<core_apimessages_UserNotification_UserNotificationKey>;
  notificationLinks?: Maybe<core_apimessages_UserNotificationLinks>;
  notificationType?: Maybe<core_apimessages_UserNotificationType>;
  title?: Maybe<Scalars["String"]["output"]>;
};

export type core_apimessages_UserNotificationLinks = {
  __typename?: "core_apimessages_UserNotificationLinks";
  _source?: Maybe<Scalars["String"]["output"]>;
  web?: Maybe<core_apimessages_Link>;
};

export enum core_apimessages_UserNotificationType {
  ACTION_NEEDED = "ACTION_NEEDED",
  NEW_FEATURE_ALERT = "NEW_FEATURE_ALERT",
  UNKNOWN_USER_NOTIFICATION_TYPE = "UNKNOWN_USER_NOTIFICATION_TYPE",
}

export enum core_apimessages_UserNotification_UserNotificationKey {
  /** @deprecated Field no longer supported */
  CANADA_POST_STRIKE_BUYER = "CANADA_POST_STRIKE_BUYER",
  COMBINE_AND_SAVE = "COMBINE_AND_SAVE",
  FREE_SHIPPING_THRESHOLD = "FREE_SHIPPING_THRESHOLD",
  GEAR_COLLECTIONS = "GEAR_COLLECTIONS",
  HAS_ABOVE_AVERAGE_PRICED_LISTINGS = "HAS_ABOVE_AVERAGE_PRICED_LISTINGS",
  INFORM_ACT_LOCKED_MODE = "INFORM_ACT_LOCKED_MODE",
  /** @deprecated Field no longer supported */
  NEW_AD_DASH = "NEW_AD_DASH",
  PACKLINK = "PACKLINK",
  PAYPAL_LOCKED_MODE = "PAYPAL_LOCKED_MODE",
  SELLER_SHOP_COUPONS = "SELLER_SHOP_COUPONS",
  UNKNOWN = "UNKNOWN",
  UPDATE_ADDRESS = "UPDATE_ADDRESS",
  WITHHOLD_PAYOUTS = "WITHHOLD_PAYOUTS",
}

export type core_apimessages_UserSearchResult = {
  __typename?: "core_apimessages_UserSearchResult";
  _source?: Maybe<Scalars["String"]["output"]>;
  email?: Maybe<Scalars["String"]["output"]>;
  fullName?: Maybe<Scalars["String"]["output"]>;
  id?: Maybe<Scalars["String"]["output"]>;
  shopId?: Maybe<Scalars["String"]["output"]>;
  shopName?: Maybe<Scalars["String"]["output"]>;
};

export type core_apimessages_UserService = {
  __typename?: "core_apimessages_UserService";
  _source?: Maybe<Scalars["String"]["output"]>;
  /** The provider's user email, not Reverb's */
  email?: Maybe<Scalars["String"]["output"]>;
  /** internal id */
  id?: Maybe<Scalars["String"]["output"]>;
  provider?: Maybe<core_apimessages_UserService_Provider>;
};

export enum core_apimessages_UserService_Provider {
  APPLE = "APPLE",
  FACEBOOK = "FACEBOOK",
  GOOGLE = "GOOGLE",
  UNKNOWN = "UNKNOWN",
}

export type core_apimessages_UserShopPermission = {
  __typename?: "core_apimessages_UserShopPermission";
  _source?: Maybe<Scalars["String"]["output"]>;
  email?: Maybe<Scalars["String"]["output"]>;
  id?: Maybe<Scalars["String"]["output"]>;
  permissionLevel?: Maybe<core_apimessages_UserShopPermission_PermissionLevel>;
  userId?: Maybe<Scalars["String"]["output"]>;
};

export enum core_apimessages_UserShopPermission_PermissionLevel {
  ADMINISTRATOR = "ADMINISTRATOR",
  MANAGER = "MANAGER",
  UNKNOWN = "UNKNOWN",
}

export enum core_apimessages_UserStatus {
  IN_GOOD_STANDING = "IN_GOOD_STANDING",
  LOCKED_VACATION_MODE = "LOCKED_VACATION_MODE",
  NOT_SET = "NOT_SET",
  REQUIRES_EMAIL_VERIFICATION = "REQUIRES_EMAIL_VERIFICATION",
  SUSPENDED = "SUSPENDED",
  SUSPENDED_OUTSTANDING_INVOICE = "SUSPENDED_OUTSTANDING_INVOICE",
  UNDER_REVIEW = "UNDER_REVIEW",
}

export type core_apimessages_UserWatchesRequest = {
  __typename?: "core_apimessages_UserWatchesRequest";
  _source?: Maybe<Scalars["String"]["output"]>;
  channel?: Maybe<core_apimessages_Channel>;
  limit?: Maybe<Scalars["Int"]["output"]>;
  listingIds?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
  listingsState?: Maybe<core_apimessages_UserWatchesRequest_ListingState>;
  offset?: Maybe<Scalars["Int"]["output"]>;
  profileSlug?: Maybe<Scalars["String"]["output"]>;
};

export enum core_apimessages_UserWatchesRequest_ListingState {
  ALL = "ALL",
  LIVE = "LIVE",
  NOT_LIVE = "NOT_LIVE",
}

export type core_apimessages_UsersRequest = {
  __typename?: "core_apimessages_UsersRequest";
  _source?: Maybe<Scalars["String"]["output"]>;
  fullname?: Maybe<Scalars["Boolean"]["output"]>;
  uuids?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
};

export type core_apimessages_UsersResponse = {
  __typename?: "core_apimessages_UsersResponse";
  _source?: Maybe<Scalars["String"]["output"]>;
  users?: Maybe<Array<Maybe<core_apimessages_User>>>;
};

export type core_apimessages_VerifyPromotionalCodeRequest = {
  __typename?: "core_apimessages_VerifyPromotionalCodeRequest";
  _source?: Maybe<Scalars["String"]["output"]>;
  token?: Maybe<Scalars["String"]["output"]>;
};

export type core_apimessages_VerifyPromotionalCodeResponse = {
  __typename?: "core_apimessages_VerifyPromotionalCodeResponse";
  _source?: Maybe<Scalars["String"]["output"]>;
  message?: Maybe<Scalars["String"]["output"]>;
  success?: Maybe<Scalars["Boolean"]["output"]>;
};

export type core_apimessages_Video = {
  __typename?: "core_apimessages_Video";
  _source?: Maybe<Scalars["String"]["output"]>;
  empty?: Maybe<Scalars["Boolean"]["output"]>;
  /** video_links.id */
  id?: Maybe<Scalars["String"]["output"]>;
  /** video_links.videolinkable_id (polymorphic foreign key, with 'item_type') */
  itemId?: Maybe<Scalars["String"]["output"]>;
  itemType?: Maybe<Scalars["String"]["output"]>;
  link?: Maybe<Scalars["String"]["output"]>;
  /** video_links.youtube_video_id */
  youtubeVideoId?: Maybe<Scalars["String"]["output"]>;
};

export type core_apimessages_VideoLink = {
  __typename?: "core_apimessages_VideoLink";
  _source?: Maybe<Scalars["String"]["output"]>;
  youtubeLink?: Maybe<Scalars["String"]["output"]>;
  youtubeVideoId?: Maybe<Scalars["String"]["output"]>;
};

export type core_apimessages_VideosRequest = {
  __typename?: "core_apimessages_VideosRequest";
  _source?: Maybe<Scalars["String"]["output"]>;
  /** (products|shops).id */
  ids?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
  slugs?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
  type?: Maybe<core_apimessages_VideosRequest_VideolinkableType>;
  uuids?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
};

export enum core_apimessages_VideosRequest_VideolinkableType {
  PRODUCT = "PRODUCT",
  SHOP = "SHOP",
}

export type core_apimessages_VideosResponse = {
  __typename?: "core_apimessages_VideosResponse";
  _source?: Maybe<Scalars["String"]["output"]>;
  videos?: Maybe<Array<Maybe<core_apimessages_Video>>>;
};

export type core_apimessages_WatchResponse = {
  __typename?: "core_apimessages_WatchResponse";
  _source?: Maybe<Scalars["String"]["output"]>;
  listingId?: Maybe<Scalars["String"]["output"]>;
  watching?: Maybe<Scalars["Boolean"]["output"]>;
};

export type core_apimessages_WatchesCreateRequest = {
  __typename?: "core_apimessages_WatchesCreateRequest";
  _source?: Maybe<Scalars["String"]["output"]>;
  bumpKey?: Maybe<core_apimessages_BumpKey>;
  channel?: Maybe<core_apimessages_Channel>;
  listingId?: Maybe<Scalars["String"]["output"]>;
};

export type core_apimessages_WatchesDeleteRequest = {
  __typename?: "core_apimessages_WatchesDeleteRequest";
  _source?: Maybe<Scalars["String"]["output"]>;
  listingId?: Maybe<Scalars["String"]["output"]>;
};

export type core_apimessages_WatchesRequest = {
  __typename?: "core_apimessages_WatchesRequest";
  _source?: Maybe<Scalars["String"]["output"]>;
  channel?: Maybe<core_apimessages_Channel>;
  limit?: Maybe<Scalars["Int"]["output"]>;
  listingIds?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
  listingsState?: Maybe<core_apimessages_WatchesRequest_ListingState>;
  offset?: Maybe<Scalars["Int"]["output"]>;
};

export enum core_apimessages_WatchesRequest_ListingState {
  ALL = "ALL",
  LIVE = "LIVE",
  NOT_LIVE = "NOT_LIVE",
}

export type core_apimessages_WatchesResponse = {
  __typename?: "core_apimessages_WatchesResponse";
  _source?: Maybe<Scalars["String"]["output"]>;
  limit?: Maybe<Scalars["Int"]["output"]>;
  offset?: Maybe<Scalars["Int"]["output"]>;
  total?: Maybe<Scalars["Int"]["output"]>;
  watches?: Maybe<Array<Maybe<core_apimessages_WatchResponse>>>;
};

/** A custom pattern is used for defining custom HTTP verb. */
export type google_api_CustomHttpPattern = {
  __typename?: "google_api_CustomHttpPattern";
  _source?: Maybe<Scalars["String"]["output"]>;
  /** The name of this custom HTTP verb. */
  kind?: Maybe<Scalars["String"]["output"]>;
  /** The path matched by this custom verb. */
  path?: Maybe<Scalars["String"]["output"]>;
};

/**
 *   Defines the HTTP configuration for an API service. It contains a list of
 * [HttpRule][google.api.HttpRule], each specifying the mapping of an RPC method
 * to one or more HTTP REST API methods.
 */
export type google_api_Http = {
  __typename?: "google_api_Http";
  _source?: Maybe<Scalars["String"]["output"]>;
  /**
   *   When set to true, URL path parmeters will be fully URI-decoded except in
   * cases of single segment matches in reserved expansion, where "%2F" will be
   * left encoded.
   *
   * The default behavior is to not decode RFC 6570 reserved characters in multi
   * segment matches.
   */
  fullyDecodeReservedExpansion?: Maybe<Scalars["Boolean"]["output"]>;
  /**
   *   A list of HTTP configuration rules that apply to individual API methods.
   *
   * **NOTE:** All service configuration rules follow "last one wins" order.
   */
  rules?: Maybe<Array<Maybe<google_api_HttpRule>>>;
};

/**
 *   `HttpRule` defines the mapping of an RPC method to one or more HTTP
 * REST API methods. The mapping specifies how different portions of the RPC
 * request message are mapped to URL path, URL query parameters, and
 * HTTP request body. The mapping is typically specified as an
 * `google.api.http` annotation on the RPC method,
 * see "google/api/annotations.proto" for details.
 *
 * The mapping consists of a field specifying the path template and
 * method kind.  The path template can refer to fields in the request
 * message, as in the example below which describes a REST GET
 * operation on a resource collection of messages:
 *
 *
 *     service Messaging {
 *       rpc GetMessage(GetMessageRequest) returns (Message) {
 *         option (google.api.http).get = "/v1/messages/{message_id}/{sub.subfield}";
 *       }
 *     }
 *     message GetMessageRequest {
 *       message SubMessage {
 *         string subfield = 1;
 *       }
 *       string message_id = 1; // mapped to the URL
 *       SubMessage sub = 2;    // `sub.subfield` is url-mapped
 *     }
 *     message Message {
 *       string text = 1; // content of the resource
 *     }
 *
 * The same http annotation can alternatively be expressed inside the
 * `GRPC API Configuration` YAML file.
 *
 *     http:
 *       rules:
 *         - selector: <proto_package_name>.Messaging.GetMessage
 *           get: /v1/messages/{message_id}/{sub.subfield}
 *
 * This definition enables an automatic, bidrectional mapping of HTTP
 * JSON to RPC. Example:
 *
 * HTTP | RPC
 * -----|-----
 * `GET /v1/messages/123456/foo`  | `GetMessage(message_id: "123456" sub: SubMessage(subfield: "foo"))`
 *
 * In general, not only fields but also field paths can be referenced
 * from a path pattern. Fields mapped to the path pattern cannot be
 * repeated and must have a primitive (non-message) type.
 *
 * Any fields in the request message which are not bound by the path
 * pattern automatically become (optional) HTTP query
 * parameters. Assume the following definition of the request message:
 *
 *
 *     service Messaging {
 *       rpc GetMessage(GetMessageRequest) returns (Message) {
 *         option (google.api.http).get = "/v1/messages/{message_id}";
 *       }
 *     }
 *     message GetMessageRequest {
 *       message SubMessage {
 *         string subfield = 1;
 *       }
 *       string message_id = 1; // mapped to the URL
 *       int64 revision = 2;    // becomes a parameter
 *       SubMessage sub = 3;    // `sub.subfield` becomes a parameter
 *     }
 *
 *
 * This enables a HTTP JSON to RPC mapping as below:
 *
 * HTTP | RPC
 * -----|-----
 * `GET /v1/messages/123456?revision=2&sub.subfield=foo` | `GetMessage(message_id: "123456" revision: 2 sub: SubMessage(subfield: "foo"))`
 *
 * Note that fields which are mapped to HTTP parameters must have a
 * primitive type or a repeated primitive type. Message types are not
 * allowed. In the case of a repeated type, the parameter can be
 * repeated in the URL, as in `...?param=A&param=B`.
 *
 * For HTTP method kinds which allow a request body, the `body` field
 * specifies the mapping. Consider a REST update method on the
 * message resource collection:
 *
 *
 *     service Messaging {
 *       rpc UpdateMessage(UpdateMessageRequest) returns (Message) {
 *         option (google.api.http) = {
 *           put: "/v1/messages/{message_id}"
 *           body: "message"
 *         };
 *       }
 *     }
 *     message UpdateMessageRequest {
 *       string message_id = 1; // mapped to the URL
 *       Message message = 2;   // mapped to the body
 *     }
 *
 *
 * The following HTTP JSON to RPC mapping is enabled, where the
 * representation of the JSON in the request body is determined by
 * protos JSON encoding:
 *
 * HTTP | RPC
 * -----|-----
 * `PUT /v1/messages/123456 { "text": "Hi!" }` | `UpdateMessage(message_id: "123456" message { text: "Hi!" })`
 *
 * The special name `*` can be used in the body mapping to define that
 * every field not bound by the path template should be mapped to the
 * request body.  This enables the following alternative definition of
 * the update method:
 *
 *     service Messaging {
 *       rpc UpdateMessage(Message) returns (Message) {
 *         option (google.api.http) = {
 *           put: "/v1/messages/{message_id}"
 *           body: "*"
 *         };
 *       }
 *     }
 *     message Message {
 *       string message_id = 1;
 *       string text = 2;
 *     }
 *
 *
 * The following HTTP JSON to RPC mapping is enabled:
 *
 * HTTP | RPC
 * -----|-----
 * `PUT /v1/messages/123456 { "text": "Hi!" }` | `UpdateMessage(message_id: "123456" text: "Hi!")`
 *
 * Note that when using `*` in the body mapping, it is not possible to
 * have HTTP parameters, as all fields not bound by the path end in
 * the body. This makes this option more rarely used in practice of
 * defining REST APIs. The common usage of `*` is in custom methods
 * which don't use the URL at all for transferring data.
 *
 * It is possible to define multiple HTTP methods for one RPC by using
 * the `additional_bindings` option. Example:
 *
 *     service Messaging {
 *       rpc GetMessage(GetMessageRequest) returns (Message) {
 *         option (google.api.http) = {
 *           get: "/v1/messages/{message_id}"
 *           additional_bindings {
 *             get: "/v1/users/{user_id}/messages/{message_id}"
 *           }
 *         };
 *       }
 *     }
 *     message GetMessageRequest {
 *       string message_id = 1;
 *       string user_id = 2;
 *     }
 *
 *
 * This enables the following two alternative HTTP JSON to RPC
 * mappings:
 *
 * HTTP | RPC
 * -----|-----
 * `GET /v1/messages/123456` | `GetMessage(message_id: "123456")`
 * `GET /v1/users/me/messages/123456` | `GetMessage(user_id: "me" message_id: "123456")`
 *
 * # Rules for HTTP mapping
 *
 * The rules for mapping HTTP path, query parameters, and body fields
 * to the request message are as follows:
 *
 * 1. The `body` field specifies either `*` or a field path, or is
 *    omitted. If omitted, it indicates there is no HTTP request body.
 * 2. Leaf fields (recursive expansion of nested messages in the
 *    request) can be classified into three types:
 *     (a) Matched in the URL template.
 *     (b) Covered by body (if body is `*`, everything except (a) fields;
 *         else everything under the body field)
 *     (c) All other fields.
 * 3. URL query parameters found in the HTTP request are mapped to (c) fields.
 * 4. Any body sent with an HTTP request can contain only (b) fields.
 *
 * The syntax of the path template is as follows:
 *
 *     Template = "/" Segments [ Verb ] ;
 *     Segments = Segment { "/" Segment } ;
 *     Segment  = "*" | "**" | LITERAL | Variable ;
 *     Variable = "{" FieldPath [ "=" Segments ] "}" ;
 *     FieldPath = IDENT { "." IDENT } ;
 *     Verb     = ":" LITERAL ;
 *
 * The syntax `*` matches a single path segment. The syntax `**` matches zero
 * or more path segments, which must be the last part of the path except the
 * `Verb`. The syntax `LITERAL` matches literal text in the path.
 *
 * The syntax `Variable` matches part of the URL path as specified by its
 * template. A variable template must not contain other variables. If a variable
 * matches a single path segment, its template may be omitted, e.g. `{var}`
 * is equivalent to `{var=*}`.
 *
 * If a variable contains exactly one path segment, such as `"{var}"` or
 * `"{var=*}"`, when such a variable is expanded into a URL path, all characters
 * except `[-_.~0-9a-zA-Z]` are percent-encoded. Such variables show up in the
 * Discovery Document as `{var}`.
 *
 * If a variable contains one or more path segments, such as `"{var=foo/*}"`
 * or `"{var=**}"`, when such a variable is expanded into a URL path, all
 * characters except `[-_.~/0-9a-zA-Z]` are percent-encoded. Such variables
 * show up in the Discovery Document as `{+var}`.
 *
 * NOTE: While the single segment variable matches the semantics of
 * [RFC 6570](https://tools.ietf.org/html/rfc6570) Section 3.2.2
 * Simple String Expansion, the multi segment variable **does not** match
 * RFC 6570 Reserved Expansion. The reason is that the Reserved Expansion
 * does not expand special characters like `?` and `#`, which would lead
 * to invalid URLs.
 *
 * NOTE: the field paths in variables and in the `body` must not refer to
 * repeated fields or map fields.
 */
export type google_api_HttpRule = {
  __typename?: "google_api_HttpRule";
  _source?: Maybe<Scalars["String"]["output"]>;
  /**
   *   Additional HTTP bindings for the selector. Nested bindings must
   * not contain an `additional_bindings` field themselves (that is,
   * the nesting may only be one level deep).
   */
  additionalBindings?: Maybe<Array<Maybe<google_api_HttpRule>>>;
  /**
   *   The name of the request field whose value is mapped to the HTTP body, or
   * `*` for mapping all fields not captured by the path pattern to the HTTP
   * body. NOTE: the referred field must not be a repeated field and must be
   * present at the top-level of request message type.
   */
  body?: Maybe<Scalars["String"]["output"]>;
  /**
   *   The custom pattern is used for specifying an HTTP method that is not
   * included in the `pattern` field, such as HEAD, or "*" to leave the
   * HTTP method unspecified for this rule. The wild-card rule is useful
   * for services that provide content to Web (HTML) clients.
   */
  custom?: Maybe<google_api_CustomHttpPattern>;
  /** Used for deleting a resource. */
  delete?: Maybe<Scalars["String"]["output"]>;
  /** Used for listing and getting information about resources. */
  get?: Maybe<Scalars["String"]["output"]>;
  /** Used for updating a resource. */
  patch?: Maybe<Scalars["String"]["output"]>;
  /** Used for creating a resource. */
  post?: Maybe<Scalars["String"]["output"]>;
  /** Used for updating a resource. */
  put?: Maybe<Scalars["String"]["output"]>;
  /**
   *   Selects methods to which this rule applies.
   *
   * Refer to [selector][google.api.DocumentationRule.selector] for syntax details.
   */
  selector?: Maybe<Scalars["String"]["output"]>;
};

/**
 *   Wrapper message for `bool`.
 *
 * The JSON representation for `BoolValue` is JSON `true` and `false`.
 */
export type google_protobuf_BoolValue = {
  __typename?: "google_protobuf_BoolValue";
  _source?: Maybe<Scalars["String"]["output"]>;
  /** The bool value. */
  value?: Maybe<Scalars["Boolean"]["output"]>;
};

/**
 *   Wrapper message for `bytes`.
 *
 * The JSON representation for `BytesValue` is JSON string.
 */
export type google_protobuf_BytesValue = {
  __typename?: "google_protobuf_BytesValue";
  _source?: Maybe<Scalars["String"]["output"]>;
  /** The bytes value. */
  value?: Maybe<Scalars["String"]["output"]>;
};

/** Describes a message type. */
export type google_protobuf_DescriptorProto = {
  __typename?: "google_protobuf_DescriptorProto";
  _source?: Maybe<Scalars["String"]["output"]>;
  enumType?: Maybe<Array<Maybe<google_protobuf_EnumDescriptorProto>>>;
  extension?: Maybe<Array<Maybe<google_protobuf_FieldDescriptorProto>>>;
  extensionRange?: Maybe<
    Array<Maybe<google_protobuf_DescriptorProto_ExtensionRange>>
  >;
  field?: Maybe<Array<Maybe<google_protobuf_FieldDescriptorProto>>>;
  name?: Maybe<Scalars["String"]["output"]>;
  nestedType?: Maybe<Array<Maybe<google_protobuf_DescriptorProto>>>;
  oneofDecl?: Maybe<Array<Maybe<google_protobuf_OneofDescriptorProto>>>;
  options?: Maybe<google_protobuf_MessageOptions>;
  /**
   *   Reserved field names, which may not be used by fields in the same message.
   * A given name may only be reserved once.
   */
  reservedName?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
  reservedRange?: Maybe<
    Array<Maybe<google_protobuf_DescriptorProto_ReservedRange>>
  >;
};

export type google_protobuf_DescriptorProto_ExtensionRange = {
  __typename?: "google_protobuf_DescriptorProto_ExtensionRange";
  _source?: Maybe<Scalars["String"]["output"]>;
  end?: Maybe<Scalars["Int"]["output"]>;
  options?: Maybe<google_protobuf_ExtensionRangeOptions>;
  start?: Maybe<Scalars["Int"]["output"]>;
};

/**
 *   Range of reserved tag numbers. Reserved tag numbers may not be used by
 * fields or extension ranges in the same message. Reserved ranges may
 * not overlap.
 */
export type google_protobuf_DescriptorProto_ReservedRange = {
  __typename?: "google_protobuf_DescriptorProto_ReservedRange";
  _source?: Maybe<Scalars["String"]["output"]>;
  /** Exclusive. */
  end?: Maybe<Scalars["Int"]["output"]>;
  /** Inclusive. */
  start?: Maybe<Scalars["Int"]["output"]>;
};

/**
 *   Wrapper message for `double`.
 *
 * The JSON representation for `DoubleValue` is JSON number.
 */
export type google_protobuf_DoubleValue = {
  __typename?: "google_protobuf_DoubleValue";
  _source?: Maybe<Scalars["String"]["output"]>;
  /** The double value. */
  value?: Maybe<Scalars["Float"]["output"]>;
};

/**
 *   A Duration represents a signed, fixed-length span of time represented
 * as a count of seconds and fractions of seconds at nanosecond
 * resolution. It is independent of any calendar and concepts like "day"
 * or "month". It is related to Timestamp in that the difference between
 * two Timestamp values is a Duration and it can be added or subtracted
 * from a Timestamp. Range is approximately +-10,000 years.
 *
 * # Examples
 *
 * Example 1: Compute Duration from two Timestamps in pseudo code.
 *
 *     Timestamp start = ...;
 *     Timestamp end = ...;
 *     Duration duration = ...;
 *
 *     duration.seconds = end.seconds - start.seconds;
 *     duration.nanos = end.nanos - start.nanos;
 *
 *     if (duration.seconds < 0 && duration.nanos > 0) {
 *       duration.seconds += 1;
 *       duration.nanos -= 1000000000;
 *     } else if (durations.seconds > 0 && duration.nanos < 0) {
 *       duration.seconds -= 1;
 *       duration.nanos += 1000000000;
 *     }
 *
 * Example 2: Compute Timestamp from Timestamp + Duration in pseudo code.
 *
 *     Timestamp start = ...;
 *     Duration duration = ...;
 *     Timestamp end = ...;
 *
 *     end.seconds = start.seconds + duration.seconds;
 *     end.nanos = start.nanos + duration.nanos;
 *
 *     if (end.nanos < 0) {
 *       end.seconds -= 1;
 *       end.nanos += 1000000000;
 *     } else if (end.nanos >= 1000000000) {
 *       end.seconds += 1;
 *       end.nanos -= 1000000000;
 *     }
 *
 * Example 3: Compute Duration from datetime.timedelta in Python.
 *
 *     td = datetime.timedelta(days=3, minutes=10)
 *     duration = Duration()
 *     duration.FromTimedelta(td)
 *
 * # JSON Mapping
 *
 * In JSON format, the Duration type is encoded as a string rather than an
 * object, where the string ends in the suffix "s" (indicating seconds) and
 * is preceded by the number of seconds, with nanoseconds expressed as
 * fractional seconds. For example, 3 seconds with 0 nanoseconds should be
 * encoded in JSON format as "3s", while 3 seconds and 1 nanosecond should
 * be expressed in JSON format as "3.000000001s", and 3 seconds and 1
 * microsecond should be expressed in JSON format as "3.000001s".
 */
export type google_protobuf_Duration = {
  __typename?: "google_protobuf_Duration";
  _source?: Maybe<Scalars["String"]["output"]>;
  /**
   *   Signed fractions of a second at nanosecond resolution of the span
   * of time. Durations less than one second are represented with a 0
   * `seconds` field and a positive or negative `nanos` field. For durations
   * of one second or more, a non-zero value for the `nanos` field must be
   * of the same sign as the `seconds` field. Must be from -999,999,999
   * to +999,999,999 inclusive.
   */
  nanos?: Maybe<Scalars["Int"]["output"]>;
  /**
   *   Signed seconds of the span of time. Must be from -315,576,000,000
   * to +315,576,000,000 inclusive. Note: these bounds are computed from:
   * 60 sec/min * 60 min/hr * 24 hr/day * 365.25 days/year * 10000 years
   */
  seconds?: Maybe<Scalars["Int"]["output"]>;
};

/**
 *   A generic empty message that you can re-use to avoid defining duplicated
 * empty messages in your APIs. A typical example is to use it as the request
 * or the response type of an API method. For instance:
 *
 *     service Foo {
 *       rpc Bar(google.protobuf.Empty) returns (google.protobuf.Empty);
 *     }
 *
 * The JSON representation for `Empty` is empty JSON object `{}`.
 */
export type google_protobuf_Empty = {
  __typename?: "google_protobuf_Empty";
  _?: Maybe<Scalars["Boolean"]["output"]>;
  _source?: Maybe<Scalars["String"]["output"]>;
};

/** Describes an enum type. */
export type google_protobuf_EnumDescriptorProto = {
  __typename?: "google_protobuf_EnumDescriptorProto";
  _source?: Maybe<Scalars["String"]["output"]>;
  name?: Maybe<Scalars["String"]["output"]>;
  options?: Maybe<google_protobuf_EnumOptions>;
  /**
   *   Reserved enum value names, which may not be reused. A given name may only
   * be reserved once.
   */
  reservedName?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
  /**
   *   Range of reserved numeric values. Reserved numeric values may not be used
   * by enum values in the same enum declaration. Reserved ranges may not
   * overlap.
   */
  reservedRange?: Maybe<
    Array<Maybe<google_protobuf_EnumDescriptorProto_EnumReservedRange>>
  >;
  value?: Maybe<Array<Maybe<google_protobuf_EnumValueDescriptorProto>>>;
};

/**
 *   Range of reserved numeric values. Reserved values may not be used by
 * entries in the same enum. Reserved ranges may not overlap.
 *
 * Note that this is distinct from DescriptorProto.ReservedRange in that it
 * is inclusive such that it can appropriately represent the entire int32
 * domain.
 */
export type google_protobuf_EnumDescriptorProto_EnumReservedRange = {
  __typename?: "google_protobuf_EnumDescriptorProto_EnumReservedRange";
  _source?: Maybe<Scalars["String"]["output"]>;
  /** Inclusive. */
  end?: Maybe<Scalars["Int"]["output"]>;
  /** Inclusive. */
  start?: Maybe<Scalars["Int"]["output"]>;
};

export type google_protobuf_EnumOptions = {
  __typename?: "google_protobuf_EnumOptions";
  _source?: Maybe<Scalars["String"]["output"]>;
  /**
   *   Set this option to true to allow mapping different tag names to the same
   * value.
   */
  allowAlias?: Maybe<Scalars["Boolean"]["output"]>;
  /**
   *   Is this enum deprecated?
   * Depending on the target platform, this can emit Deprecated annotations
   * for the enum, or it will be completely ignored; in the very least, this
   * is a formalization for deprecating enums.
   */
  deprecated?: Maybe<Scalars["Boolean"]["output"]>;
  /** The parser stores options it doesn't recognize here. See above. */
  uninterpretedOption?: Maybe<
    Array<Maybe<google_protobuf_UninterpretedOption>>
  >;
};

/** Describes a value within an enum. */
export type google_protobuf_EnumValueDescriptorProto = {
  __typename?: "google_protobuf_EnumValueDescriptorProto";
  _source?: Maybe<Scalars["String"]["output"]>;
  name?: Maybe<Scalars["String"]["output"]>;
  number?: Maybe<Scalars["Int"]["output"]>;
  options?: Maybe<google_protobuf_EnumValueOptions>;
};

export type google_protobuf_EnumValueOptions = {
  __typename?: "google_protobuf_EnumValueOptions";
  _source?: Maybe<Scalars["String"]["output"]>;
  /**
   *   Is this enum value deprecated?
   * Depending on the target platform, this can emit Deprecated annotations
   * for the enum value, or it will be completely ignored; in the very least,
   * this is a formalization for deprecating enum values.
   */
  deprecated?: Maybe<Scalars["Boolean"]["output"]>;
  /** The parser stores options it doesn't recognize here. See above. */
  uninterpretedOption?: Maybe<
    Array<Maybe<google_protobuf_UninterpretedOption>>
  >;
};

export type google_protobuf_ExtensionRangeOptions = {
  __typename?: "google_protobuf_ExtensionRangeOptions";
  _source?: Maybe<Scalars["String"]["output"]>;
  /** The parser stores options it doesn't recognize here. See above. */
  uninterpretedOption?: Maybe<
    Array<Maybe<google_protobuf_UninterpretedOption>>
  >;
};

/** Describes a field within a message. */
export type google_protobuf_FieldDescriptorProto = {
  __typename?: "google_protobuf_FieldDescriptorProto";
  _source?: Maybe<Scalars["String"]["output"]>;
  /**
   *   For numeric types, contains the original text representation of the value.
   * For booleans, "true" or "false".
   * For strings, contains the default text contents (not escaped in any way).
   * For bytes, contains the C escaped value.  All bytes >= 128 are escaped.
   * TODO(kenton):  Base-64 encode?
   */
  defaultValue?: Maybe<Scalars["String"]["output"]>;
  /**
   *   For extensions, this is the name of the type being extended.  It is
   * resolved in the same manner as type_name.
   */
  extendee?: Maybe<Scalars["String"]["output"]>;
  /**
   *   JSON name of this field. The value is set by protocol compiler. If the
   * user has set a "json_name" option on this field, that option's value
   * will be used. Otherwise, it's deduced from the field's name by converting
   * it to camelCase.
   */
  jsonName?: Maybe<Scalars["String"]["output"]>;
  label?: Maybe<google_protobuf_FieldDescriptorProto_Label>;
  name?: Maybe<Scalars["String"]["output"]>;
  number?: Maybe<Scalars["Int"]["output"]>;
  /**
   *   If set, gives the index of a oneof in the containing type's oneof_decl
   * list.  This field is a member of that oneof.
   */
  oneofIndex?: Maybe<Scalars["Int"]["output"]>;
  options?: Maybe<google_protobuf_FieldOptions>;
  /**
   *   If type_name is set, this need not be set.  If both this and type_name
   * are set, this must be one of TYPE_ENUM, TYPE_MESSAGE or TYPE_GROUP.
   */
  type?: Maybe<google_protobuf_FieldDescriptorProto_Type>;
  /**
   *   For message and enum types, this is the name of the type.  If the name
   * starts with a '.', it is fully-qualified.  Otherwise, C++-like scoping
   * rules are used to find the type (i.e. first the nested types within this
   * message are searched, then within the parent, on up to the root
   * namespace).
   */
  typeName?: Maybe<Scalars["String"]["output"]>;
};

export enum google_protobuf_FieldDescriptorProto_Label {
  /** 0 is reserved for errors */
  LABEL_OPTIONAL = "LABEL_OPTIONAL",
  LABEL_REPEATED = "LABEL_REPEATED",
  LABEL_REQUIRED = "LABEL_REQUIRED",
}

export enum google_protobuf_FieldDescriptorProto_Type {
  TYPE_BOOL = "TYPE_BOOL",
  /** New in version 2. */
  TYPE_BYTES = "TYPE_BYTES",
  /**
   *   0 is reserved for errors.
   * Order is weird for historical reasons.
   */
  TYPE_DOUBLE = "TYPE_DOUBLE",
  TYPE_ENUM = "TYPE_ENUM",
  TYPE_FIXED32 = "TYPE_FIXED32",
  TYPE_FIXED64 = "TYPE_FIXED64",
  TYPE_FLOAT = "TYPE_FLOAT",
  /**
   *   Tag-delimited aggregate.
   * Group type is deprecated and not supported in proto3. However, Proto3
   * implementations should still be able to parse the group wire format and
   * treat group fields as unknown fields.
   */
  TYPE_GROUP = "TYPE_GROUP",
  /**
   *   Not ZigZag encoded.  Negative numbers take 10 bytes.  Use TYPE_SINT32 if
   * negative values are likely.
   */
  TYPE_INT32 = "TYPE_INT32",
  /**
   *   Not ZigZag encoded.  Negative numbers take 10 bytes.  Use TYPE_SINT64 if
   * negative values are likely.
   */
  TYPE_INT64 = "TYPE_INT64",
  /** Length-delimited aggregate. */
  TYPE_MESSAGE = "TYPE_MESSAGE",
  TYPE_SFIXED32 = "TYPE_SFIXED32",
  TYPE_SFIXED64 = "TYPE_SFIXED64",
  /** Uses ZigZag encoding. */
  TYPE_SINT32 = "TYPE_SINT32",
  /** Uses ZigZag encoding. */
  TYPE_SINT64 = "TYPE_SINT64",
  TYPE_STRING = "TYPE_STRING",
  TYPE_UINT32 = "TYPE_UINT32",
  TYPE_UINT64 = "TYPE_UINT64",
}

export type google_protobuf_FieldOptions = {
  __typename?: "google_protobuf_FieldOptions";
  _source?: Maybe<Scalars["String"]["output"]>;
  /**
   *   The ctype option instructs the C++ code generator to use a different
   * representation of the field than it normally would.  See the specific
   * options below.  This option is not yet implemented in the open source
   * release -- sorry, we'll try to include it in a future version!
   */
  ctype?: Maybe<google_protobuf_FieldOptions_CType>;
  /**
   *   Is this field deprecated?
   * Depending on the target platform, this can emit Deprecated annotations
   * for accessors, or it will be completely ignored; in the very least, this
   * is a formalization for deprecating fields.
   */
  deprecated?: Maybe<Scalars["Boolean"]["output"]>;
  /**
   *   The jstype option determines the JavaScript type used for values of the
   * field.  The option is permitted only for 64 bit integral and fixed types
   * (int64, uint64, sint64, fixed64, sfixed64).  A field with jstype JS_STRING
   * is represented as JavaScript string, which avoids loss of precision that
   * can happen when a large value is converted to a floating point JavaScript.
   * Specifying JS_NUMBER for the jstype causes the generated JavaScript code to
   * use the JavaScript "number" type.  The behavior of the default option
   * JS_NORMAL is implementation dependent.
   *
   * This option is an enum to permit additional types to be added, e.g.
   * goog.math.Integer.
   */
  jstype?: Maybe<google_protobuf_FieldOptions_JSType>;
  /**
   *   Should this field be parsed lazily?  Lazy applies only to message-type
   * fields.  It means that when the outer message is initially parsed, the
   * inner message's contents will not be parsed but instead stored in encoded
   * form.  The inner message will actually be parsed when it is first accessed.
   *
   * This is only a hint.  Implementations are free to choose whether to use
   * eager or lazy parsing regardless of the value of this option.  However,
   * setting this option true suggests that the protocol author believes that
   * using lazy parsing on this field is worth the additional bookkeeping
   * overhead typically needed to implement it.
   *
   * This option does not affect the public interface of any generated code;
   * all method signatures remain the same.  Furthermore, thread-safety of the
   * interface is not affected by this option; const methods remain safe to
   * call from multiple threads concurrently, while non-const methods continue
   * to require exclusive access.
   *
   *
   * Note that implementations may choose not to check required fields within
   * a lazy sub-message.  That is, calling IsInitialized() on the outer message
   * may return true even if the inner message has missing required fields.
   * This is necessary because otherwise the inner message would have to be
   * parsed in order to perform the check, defeating the purpose of lazy
   * parsing.  An implementation which chooses not to check required fields
   * must be consistent about it.  That is, for any particular sub-message, the
   * implementation must either *always* check its required fields, or *never*
   * check its required fields, regardless of whether or not the message has
   * been parsed.
   */
  lazy?: Maybe<Scalars["Boolean"]["output"]>;
  /**
   *   The packed option can be enabled for repeated primitive fields to enable
   * a more efficient representation on the wire. Rather than repeatedly
   * writing the tag and type for each element, the entire array is encoded as
   * a single length-delimited blob. In proto3, only explicit setting it to
   * false will avoid using packed encoding.
   */
  packed?: Maybe<Scalars["Boolean"]["output"]>;
  /** The parser stores options it doesn't recognize here. See above. */
  uninterpretedOption?: Maybe<
    Array<Maybe<google_protobuf_UninterpretedOption>>
  >;
  /** For Google-internal migration only. Do not use. */
  weak?: Maybe<Scalars["Boolean"]["output"]>;
};

export enum google_protobuf_FieldOptions_CType {
  CORD = "CORD",
  /** Default mode. */
  STRING = "STRING",
  STRING_PIECE = "STRING_PIECE",
}

export enum google_protobuf_FieldOptions_JSType {
  /** Use the default type. */
  JS_NORMAL = "JS_NORMAL",
  /** Use JavaScript numbers. */
  JS_NUMBER = "JS_NUMBER",
  /** Use JavaScript strings. */
  JS_STRING = "JS_STRING",
}

/** Describes a complete .proto file. */
export type google_protobuf_FileDescriptorProto = {
  __typename?: "google_protobuf_FileDescriptorProto";
  _source?: Maybe<Scalars["String"]["output"]>;
  /** Names of files imported by this file. */
  dependency?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
  enumType?: Maybe<Array<Maybe<google_protobuf_EnumDescriptorProto>>>;
  extension?: Maybe<Array<Maybe<google_protobuf_FieldDescriptorProto>>>;
  /** All top-level definitions in this file. */
  messageType?: Maybe<Array<Maybe<google_protobuf_DescriptorProto>>>;
  /** file name, relative to root of source tree */
  name?: Maybe<Scalars["String"]["output"]>;
  options?: Maybe<google_protobuf_FileOptions>;
  /** e.g. "foo", "foo.bar", etc. */
  package?: Maybe<Scalars["String"]["output"]>;
  /** Indexes of the public imported files in the dependency list above. */
  publicDependency?: Maybe<Array<Maybe<Scalars["Int"]["output"]>>>;
  service?: Maybe<Array<Maybe<google_protobuf_ServiceDescriptorProto>>>;
  /**
   *   This field contains optional information about the original source code.
   * You may safely remove this entire field without harming runtime
   * functionality of the descriptors -- the information is needed only by
   * development tools.
   */
  sourceCodeInfo?: Maybe<google_protobuf_SourceCodeInfo>;
  /**
   *   The syntax of the proto file.
   * The supported values are "proto2" and "proto3".
   */
  syntax?: Maybe<Scalars["String"]["output"]>;
  /**
   *   Indexes of the weak imported files in the dependency list.
   * For Google-internal migration only. Do not use.
   */
  weakDependency?: Maybe<Array<Maybe<Scalars["Int"]["output"]>>>;
};

/**
 *   The protocol compiler can output a FileDescriptorSet containing the .proto
 * files it parses.
 */
export type google_protobuf_FileDescriptorSet = {
  __typename?: "google_protobuf_FileDescriptorSet";
  _source?: Maybe<Scalars["String"]["output"]>;
  file?: Maybe<Array<Maybe<google_protobuf_FileDescriptorProto>>>;
};

export type google_protobuf_FileOptions = {
  __typename?: "google_protobuf_FileOptions";
  _source?: Maybe<Scalars["String"]["output"]>;
  /**
   *   Enables the use of arenas for the proto messages in this file. This applies
   * only to generated classes for C++.
   */
  ccEnableArenas?: Maybe<Scalars["Boolean"]["output"]>;
  /**
   *   Should generic services be generated in each language?  "Generic" services
   * are not specific to any particular RPC system.  They are generated by the
   * main code generators in each language (without additional plugins).
   * Generic services were the only kind of service generation supported by
   * early versions of google.protobuf.
   *
   * Generic services are now considered deprecated in favor of using plugins
   * that generate code specific to your particular RPC system.  Therefore,
   * these default to false.  Old code which depends on generic services should
   * explicitly set them to true.
   */
  ccGenericServices?: Maybe<Scalars["Boolean"]["output"]>;
  /** Namespace for generated classes; defaults to the package. */
  csharpNamespace?: Maybe<Scalars["String"]["output"]>;
  /**
   *   Is this file deprecated?
   * Depending on the target platform, this can emit Deprecated annotations
   * for everything in the file, or it will be completely ignored; in the very
   * least, this is a formalization for deprecating files.
   */
  deprecated?: Maybe<Scalars["Boolean"]["output"]>;
  /**
   *   Sets the Go package where structs generated from this .proto will be
   * placed. If omitted, the Go package will be derived from the following:
   *   - The basename of the package import path, if provided.
   *   - Otherwise, the package statement in the .proto file, if present.
   *   - Otherwise, the basename of the .proto file, without extension.
   */
  goPackage?: Maybe<Scalars["String"]["output"]>;
  /**
   * This option does nothing.
   * @deprecated Field no longer supported
   */
  javaGenerateEqualsAndHash?: Maybe<Scalars["Boolean"]["output"]>;
  javaGenericServices?: Maybe<Scalars["Boolean"]["output"]>;
  /**
   *   If set true, then the Java code generator will generate a separate .java
   * file for each top-level message, enum, and service defined in the .proto
   * file.  Thus, these types will *not* be nested inside the outer class
   * named by java_outer_classname.  However, the outer class will still be
   * generated to contain the file's getDescriptor() method as well as any
   * top-level extensions defined in the file.
   */
  javaMultipleFiles?: Maybe<Scalars["Boolean"]["output"]>;
  /**
   *   If set, all the classes from the .proto file are wrapped in a single
   * outer class with the given name.  This applies to both Proto1
   * (equivalent to the old "--one_java_file" option) and Proto2 (where
   * a .proto always translates to a single class, but you may want to
   * explicitly choose the class name).
   */
  javaOuterClassname?: Maybe<Scalars["String"]["output"]>;
  /**
   *   Sets the Java package where classes generated from this .proto will be
   * placed.  By default, the proto package is used, but this is often
   * inappropriate because proto packages do not normally start with backwards
   * domain names.
   */
  javaPackage?: Maybe<Scalars["String"]["output"]>;
  /**
   *   If set true, then the Java2 code generator will generate code that
   * throws an exception whenever an attempt is made to assign a non-UTF-8
   * byte sequence to a string field.
   * Message reflection will do the same.
   * However, an extension field still accepts non-UTF-8 byte sequences.
   * This option has no effect on when used with the lite runtime.
   */
  javaStringCheckUtf8?: Maybe<Scalars["Boolean"]["output"]>;
  /**
   *   Sets the objective c class prefix which is prepended to all objective c
   * generated classes from this .proto. There is no default.
   */
  objcClassPrefix?: Maybe<Scalars["String"]["output"]>;
  optimizeFor?: Maybe<google_protobuf_FileOptions_OptimizeMode>;
  /**
   *   Sets the php class prefix which is prepended to all php generated classes
   * from this .proto. Default is empty.
   */
  phpClassPrefix?: Maybe<Scalars["String"]["output"]>;
  phpGenericServices?: Maybe<Scalars["Boolean"]["output"]>;
  /**
   *   Use this option to change the namespace of php generated classes. Default
   * is empty. When this option is empty, the package name will be used for
   * determining the namespace.
   */
  phpNamespace?: Maybe<Scalars["String"]["output"]>;
  pyGenericServices?: Maybe<Scalars["Boolean"]["output"]>;
  /**
   *   By default Swift generators will take the proto package and CamelCase it
   * replacing '.' with underscore and use that to prefix the types/symbols
   * defined. When this options is provided, they will use this value instead
   * to prefix the types/symbols defined.
   */
  swiftPrefix?: Maybe<Scalars["String"]["output"]>;
  /**
   *   The parser stores options it doesn't recognize here.
   * See the documentation for the "Options" section above.
   */
  uninterpretedOption?: Maybe<
    Array<Maybe<google_protobuf_UninterpretedOption>>
  >;
};

export enum google_protobuf_FileOptions_OptimizeMode {
  /**
   *   etc.
   *
   * Use ReflectionOps to implement these methods.
   */
  CODE_SIZE = "CODE_SIZE",
  /** Generate code using MessageLite and the lite runtime. */
  LITE_RUNTIME = "LITE_RUNTIME",
  /** Generate complete code for parsing, serialization, */
  SPEED = "SPEED",
}

/**
 *   Wrapper message for `float`.
 *
 * The JSON representation for `FloatValue` is JSON number.
 */
export type google_protobuf_FloatValue = {
  __typename?: "google_protobuf_FloatValue";
  _source?: Maybe<Scalars["String"]["output"]>;
  /** The float value. */
  value?: Maybe<Scalars["Float"]["output"]>;
};

/**
 *   Describes the relationship between generated code and its original source
 * file. A GeneratedCodeInfo message is associated with only one generated
 * source file, but may contain references to different source .proto files.
 */
export type google_protobuf_GeneratedCodeInfo = {
  __typename?: "google_protobuf_GeneratedCodeInfo";
  _source?: Maybe<Scalars["String"]["output"]>;
  /**
   *   An Annotation connects some span of text in generated code to an element
   * of its generating .proto file.
   */
  annotation?: Maybe<
    Array<Maybe<google_protobuf_GeneratedCodeInfo_Annotation>>
  >;
};

export type google_protobuf_GeneratedCodeInfo_Annotation = {
  __typename?: "google_protobuf_GeneratedCodeInfo_Annotation";
  _source?: Maybe<Scalars["String"]["output"]>;
  /**
   *   Identifies the starting offset in bytes in the generated code
   * that relates to the identified object.
   */
  begin?: Maybe<Scalars["Int"]["output"]>;
  /**
   *   Identifies the ending offset in bytes in the generated code that
   * relates to the identified offset. The end offset should be one past
   * the last relevant byte (so the length of the text = end - begin).
   */
  end?: Maybe<Scalars["Int"]["output"]>;
  /**
   *   Identifies the element in the original source .proto file. This field
   * is formatted the same as SourceCodeInfo.Location.path.
   */
  path?: Maybe<Array<Maybe<Scalars["Int"]["output"]>>>;
  /** Identifies the filesystem path to the original source .proto. */
  sourceFile?: Maybe<Scalars["String"]["output"]>;
};

/**
 *   Wrapper message for `int32`.
 *
 * The JSON representation for `Int32Value` is JSON number.
 */
export type google_protobuf_Int32Value = {
  __typename?: "google_protobuf_Int32Value";
  _source?: Maybe<Scalars["String"]["output"]>;
  /** The int32 value. */
  value?: Maybe<Scalars["Int"]["output"]>;
};

/**
 *   Wrapper message for `int64`.
 *
 * The JSON representation for `Int64Value` is JSON string.
 */
export type google_protobuf_Int64Value = {
  __typename?: "google_protobuf_Int64Value";
  _source?: Maybe<Scalars["String"]["output"]>;
  /** The int64 value. */
  value?: Maybe<Scalars["Int"]["output"]>;
};

export type google_protobuf_MessageOptions = {
  __typename?: "google_protobuf_MessageOptions";
  _source?: Maybe<Scalars["String"]["output"]>;
  /**
   *   Is this message deprecated?
   * Depending on the target platform, this can emit Deprecated annotations
   * for the message, or it will be completely ignored; in the very least,
   * this is a formalization for deprecating messages.
   */
  deprecated?: Maybe<Scalars["Boolean"]["output"]>;
  /**
   *   Whether the message is an automatically generated map entry type for the
   * maps field.
   *
   * For maps fields:
   *     map<KeyType, ValueType> map_field = 1;
   * The parsed descriptor looks like:
   *     message MapFieldEntry {
   *         option map_entry = true;
   *         optional KeyType key = 1;
   *         optional ValueType value = 2;
   *     }
   *     repeated MapFieldEntry map_field = 1;
   *
   * Implementations may choose not to generate the map_entry=true message, but
   * use a native map in the target language to hold the keys and values.
   * The reflection APIs in such implementions still need to work as
   * if the field is a repeated message field.
   *
   * NOTE: Do not set the option in .proto files. Always use the maps syntax
   * instead. The option should only be implicitly set by the proto compiler
   * parser.
   */
  mapEntry?: Maybe<Scalars["Boolean"]["output"]>;
  /**
   *   Set true to use the old proto1 MessageSet wire format for extensions.
   * This is provided for backwards-compatibility with the MessageSet wire
   * format.  You should not use this for any other reason:  It's less
   * efficient, has fewer features, and is more complicated.
   *
   * The message must be defined exactly as follows:
   *   message Foo {
   *     option message_set_wire_format = true;
   *     extensions 4 to max;
   *   }
   * Note that the message cannot have any defined fields; MessageSets only
   * have extensions.
   *
   * All extensions of your type must be singular messages; e.g. they cannot
   * be int32s, enums, or repeated messages.
   *
   * Because this is an option, the above two restrictions are not enforced by
   * the protocol compiler.
   */
  messageSetWireFormat?: Maybe<Scalars["Boolean"]["output"]>;
  /**
   *   Disables the generation of the standard "descriptor()" accessor, which can
   * conflict with a field of the same name.  This is meant to make migration
   * from proto1 easier; new code should avoid fields named "descriptor".
   */
  noStandardDescriptorAccessor?: Maybe<Scalars["Boolean"]["output"]>;
  /** The parser stores options it doesn't recognize here. See above. */
  uninterpretedOption?: Maybe<
    Array<Maybe<google_protobuf_UninterpretedOption>>
  >;
};

/** Describes a method of a service. */
export type google_protobuf_MethodDescriptorProto = {
  __typename?: "google_protobuf_MethodDescriptorProto";
  _source?: Maybe<Scalars["String"]["output"]>;
  /** Identifies if client streams multiple client messages */
  clientStreaming?: Maybe<Scalars["Boolean"]["output"]>;
  /**
   *   Input and output type names.  These are resolved in the same way as
   * FieldDescriptorProto.type_name, but must refer to a message type.
   */
  inputType?: Maybe<Scalars["String"]["output"]>;
  name?: Maybe<Scalars["String"]["output"]>;
  options?: Maybe<google_protobuf_MethodOptions>;
  outputType?: Maybe<Scalars["String"]["output"]>;
  /** Identifies if server streams multiple server messages */
  serverStreaming?: Maybe<Scalars["Boolean"]["output"]>;
};

export type google_protobuf_MethodOptions = {
  __typename?: "google_protobuf_MethodOptions";
  _source?: Maybe<Scalars["String"]["output"]>;
  /**
   *   Is this method deprecated?
   * Depending on the target platform, this can emit Deprecated annotations
   * for the method, or it will be completely ignored; in the very least,
   * this is a formalization for deprecating methods.
   */
  deprecated?: Maybe<Scalars["Boolean"]["output"]>;
  idempotencyLevel?: Maybe<google_protobuf_MethodOptions_IdempotencyLevel>;
  /** The parser stores options it doesn't recognize here. See above. */
  uninterpretedOption?: Maybe<
    Array<Maybe<google_protobuf_UninterpretedOption>>
  >;
};

export enum google_protobuf_MethodOptions_IdempotencyLevel {
  IDEMPOTENCY_UNKNOWN = "IDEMPOTENCY_UNKNOWN",
  /** idempotent, but may have side effects */
  IDEMPOTENT = "IDEMPOTENT",
  /** implies idempotent */
  NO_SIDE_EFFECTS = "NO_SIDE_EFFECTS",
}

/** Describes a oneof. */
export type google_protobuf_OneofDescriptorProto = {
  __typename?: "google_protobuf_OneofDescriptorProto";
  _source?: Maybe<Scalars["String"]["output"]>;
  name?: Maybe<Scalars["String"]["output"]>;
  options?: Maybe<google_protobuf_OneofOptions>;
};

export type google_protobuf_OneofOptions = {
  __typename?: "google_protobuf_OneofOptions";
  _source?: Maybe<Scalars["String"]["output"]>;
  /** The parser stores options it doesn't recognize here. See above. */
  uninterpretedOption?: Maybe<
    Array<Maybe<google_protobuf_UninterpretedOption>>
  >;
};

/** Describes a service. */
export type google_protobuf_ServiceDescriptorProto = {
  __typename?: "google_protobuf_ServiceDescriptorProto";
  _source?: Maybe<Scalars["String"]["output"]>;
  method?: Maybe<Array<Maybe<google_protobuf_MethodDescriptorProto>>>;
  name?: Maybe<Scalars["String"]["output"]>;
  options?: Maybe<google_protobuf_ServiceOptions>;
};

export type google_protobuf_ServiceOptions = {
  __typename?: "google_protobuf_ServiceOptions";
  _source?: Maybe<Scalars["String"]["output"]>;
  /**
   *   Is this service deprecated?
   * Depending on the target platform, this can emit Deprecated annotations
   * for the service, or it will be completely ignored; in the very least,
   * this is a formalization for deprecating services.
   */
  deprecated?: Maybe<Scalars["Boolean"]["output"]>;
  /** The parser stores options it doesn't recognize here. See above. */
  uninterpretedOption?: Maybe<
    Array<Maybe<google_protobuf_UninterpretedOption>>
  >;
};

/**
 *   Encapsulates information about the original source file from which a
 * FileDescriptorProto was generated.
 */
export type google_protobuf_SourceCodeInfo = {
  __typename?: "google_protobuf_SourceCodeInfo";
  _source?: Maybe<Scalars["String"]["output"]>;
  /**
   *   A Location identifies a piece of source code in a .proto file which
   * corresponds to a particular definition.  This information is intended
   * to be useful to IDEs, code indexers, documentation generators, and similar
   * tools.
   *
   * For example, say we have a file like:
   *   message Foo {
   *     optional string foo = 1;
   *   }
   * Let's look at just the field definition:
   *   optional string foo = 1;
   *   ^       ^^     ^^  ^  ^^^
   *   a       bc     de  f  ghi
   * We have the following locations:
   *   span   path               represents
   *   [a,i)  [ 4, 0, 2, 0 ]     The whole field definition.
   *   [a,b)  [ 4, 0, 2, 0, 4 ]  The label (optional).
   *   [c,d)  [ 4, 0, 2, 0, 5 ]  The type (string).
   *   [e,f)  [ 4, 0, 2, 0, 1 ]  The name (foo).
   *   [g,h)  [ 4, 0, 2, 0, 3 ]  The number (1).
   *
   * Notes:
   * - A location may refer to a repeated field itself (i.e. not to any
   *   particular index within it).  This is used whenever a set of elements are
   *   logically enclosed in a single code segment.  For example, an entire
   *   extend block (possibly containing multiple extension definitions) will
   *   have an outer location whose path refers to the "extensions" repeated
   *   field without an index.
   * - Multiple locations may have the same path.  This happens when a single
   *   logical declaration is spread out across multiple places.  The most
   *   obvious example is the "extend" block again -- there may be multiple
   *   extend blocks in the same scope, each of which will have the same path.
   * - A location's span is not always a subset of its parent's span.  For
   *   example, the "extendee" of an extension declaration appears at the
   *   beginning of the "extend" block and is shared by all extensions within
   *   the block.
   * - Just because a location's span is a subset of some other location's span
   *   does not mean that it is a descendent.  For example, a "group" defines
   *   both a type and a field in a single declaration.  Thus, the locations
   *   corresponding to the type and field and their components will overlap.
   * - Code which tries to interpret locations should probably be designed to
   *   ignore those that it doesn't understand, as more types of locations could
   *   be recorded in the future.
   */
  location?: Maybe<Array<Maybe<google_protobuf_SourceCodeInfo_Location>>>;
};

export type google_protobuf_SourceCodeInfo_Location = {
  __typename?: "google_protobuf_SourceCodeInfo_Location";
  _source?: Maybe<Scalars["String"]["output"]>;
  /**
   *   If this SourceCodeInfo represents a complete declaration, these are any
   * comments appearing before and after the declaration which appear to be
   * attached to the declaration.
   *
   * A series of line comments appearing on consecutive lines, with no other
   * tokens appearing on those lines, will be treated as a single comment.
   *
   * leading_detached_comments will keep paragraphs of comments that appear
   * before (but not connected to) the current element. Each paragraph,
   * separated by empty lines, will be one comment element in the repeated
   * field.
   *
   * Only the comment content is provided; comment markers (e.g. //) are
   * stripped out.  For block comments, leading whitespace and an asterisk
   * will be stripped from the beginning of each line other than the first.
   * Newlines are included in the output.
   *
   * Examples:
   *
   *   optional int32 foo = 1;  // Comment attached to foo.
   *   // Comment attached to bar.
   *   optional int32 bar = 2;
   *
   *   optional string baz = 3;
   *   // Comment attached to baz.
   *   // Another line attached to baz.
   *
   *   // Comment attached to qux.
   *   //
   *   // Another line attached to qux.
   *   optional double qux = 4;
   *
   *   // Detached comment for corge. This is not leading or trailing comments
   *   // to qux or corge because there are blank lines separating it from
   *   // both.
   *
   *   // Detached comment for corge paragraph 2.
   *
   *   optional string corge = 5;
   *   /* Block comment attached
   *    * to corge.  Leading asterisks
   *    * will be removed. *\/
   *   /* Block comment attached to
   *    * grault. *\/
   *   optional int32 grault = 6;
   *
   *   // ignored detached comments.
   */
  leadingComments?: Maybe<Scalars["String"]["output"]>;
  leadingDetachedComments?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
  /**
   *   Identifies which part of the FileDescriptorProto was defined at this
   * location.
   *
   * Each element is a field number or an index.  They form a path from
   * the root FileDescriptorProto to the place where the definition.  For
   * example, this path:
   *   [ 4, 3, 2, 7, 1 ]
   * refers to:
   *   file.message_type(3)  // 4, 3
   *       .field(7)         // 2, 7
   *       .name()           // 1
   * This is because FileDescriptorProto.message_type has field number 4:
   *   repeated DescriptorProto message_type = 4;
   * and DescriptorProto.field has field number 2:
   *   repeated FieldDescriptorProto field = 2;
   * and FieldDescriptorProto.name has field number 1:
   *   optional string name = 1;
   *
   * Thus, the above path gives the location of a field name.  If we removed
   * the last element:
   *   [ 4, 3, 2, 7 ]
   * this path refers to the whole field declaration (from the beginning
   * of the label to the terminating semicolon).
   */
  path?: Maybe<Array<Maybe<Scalars["Int"]["output"]>>>;
  /**
   *   Always has exactly three or four elements: start line, start column,
   * end line (optional, otherwise assumed same as start line), end column.
   * These are packed into a single field for efficiency.  Note that line
   * and column numbers are zero-based -- typically you will want to add
   * 1 to each before displaying to a user.
   */
  span?: Maybe<Array<Maybe<Scalars["Int"]["output"]>>>;
  trailingComments?: Maybe<Scalars["String"]["output"]>;
};

/**
 *   Wrapper message for `string`.
 *
 * The JSON representation for `StringValue` is JSON string.
 */
export type google_protobuf_StringValue = {
  __typename?: "google_protobuf_StringValue";
  _source?: Maybe<Scalars["String"]["output"]>;
  /** The string value. */
  value?: Maybe<Scalars["String"]["output"]>;
};

/**
 *   Google "fixed" a bug where timestamps should serialize as
 * a JSON string. We don't rely on this in many places, but this
 * type should be compatible without running into issues with the
 *
 * WKT/upb exception here:
 * https://github.com/protocolbuffers/upb/blob/9e1f89ef2c2cf4f9fe98bc4afe651ddfe7c575ab/upb/json/printer.c#L1252
 *
 * Represents seconds of UTC time since Unix epoch
 * 1970-01-01T00:00:00Z. Must be from 0001-01-01T00:00:00Z to
 * 9999-12-31T23:59:59Z inclusive.
 */
export type google_protobuf_Timestamp = {
  __typename?: "google_protobuf_Timestamp";
  _source?: Maybe<Scalars["String"]["output"]>;
  /**
   *   Non-negative fractions of a second at nanosecond resolution. Negative
   * second values with fractions must still have non-negative nanos values
   * that count forward in time. Must be from 0 to 999,999,999
   * inclusive.
   */
  nanos?: Maybe<Scalars["Int"]["output"]>;
  /** Yes we'll have a 2038 problem */
  seconds?: Maybe<Scalars["Int"]["output"]>;
};

/**
 *   Wrapper message for `uint32`.
 *
 * The JSON representation for `UInt32Value` is JSON number.
 */
export type google_protobuf_UInt32Value = {
  __typename?: "google_protobuf_UInt32Value";
  _source?: Maybe<Scalars["String"]["output"]>;
  /** The uint32 value. */
  value?: Maybe<Scalars["Int"]["output"]>;
};

/**
 *   Wrapper message for `uint64`.
 *
 * The JSON representation for `UInt64Value` is JSON string.
 */
export type google_protobuf_UInt64Value = {
  __typename?: "google_protobuf_UInt64Value";
  _source?: Maybe<Scalars["String"]["output"]>;
  /** The uint64 value. */
  value?: Maybe<Scalars["Int"]["output"]>;
};

/**
 *   A message representing a option the parser does not recognize. This only
 * appears in options protos created by the compiler::Parser class.
 * DescriptorPool resolves these when building Descriptor objects. Therefore,
 * options protos in descriptor objects (e.g. returned by Descriptor::options(),
 * or produced by Descriptor::CopyTo()) will never have UninterpretedOptions
 * in them.
 */
export type google_protobuf_UninterpretedOption = {
  __typename?: "google_protobuf_UninterpretedOption";
  _source?: Maybe<Scalars["String"]["output"]>;
  aggregateValue?: Maybe<Scalars["String"]["output"]>;
  doubleValue?: Maybe<Scalars["Float"]["output"]>;
  /**
   *   The value of the uninterpreted option, in whatever type the tokenizer
   * identified it as during parsing. Exactly one of these should be set.
   */
  identifierValue?: Maybe<Scalars["String"]["output"]>;
  name?: Maybe<Array<Maybe<google_protobuf_UninterpretedOption_NamePart>>>;
  negativeIntValue?: Maybe<Scalars["Int"]["output"]>;
  positiveIntValue?: Maybe<Scalars["Int"]["output"]>;
  stringValue?: Maybe<Scalars["String"]["output"]>;
};

/**
 *   The name of the uninterpreted option.  Each string represents a segment in
 * a dot-separated name.  is_extension is true iff a segment represents an
 * extension (denoted with parentheses in options specs in .proto files).
 * E.g.,{ ["foo", false], ["bar.baz", true], ["qux", false] } represents
 * "foo.(bar.baz).qux".
 */
export type google_protobuf_UninterpretedOption_NamePart = {
  __typename?: "google_protobuf_UninterpretedOption_NamePart";
  _source?: Maybe<Scalars["String"]["output"]>;
  isExtension?: Maybe<Scalars["Boolean"]["output"]>;
  namePart?: Maybe<Scalars["String"]["output"]>;
};

export type loqate_AddressCaptureFindRequest = {
  __typename?: "loqate_AddressCaptureFindRequest";
  _source?: Maybe<Scalars["String"]["output"]>;
  container?: Maybe<Scalars["String"]["output"]>;
  countries?: Maybe<Scalars["String"]["output"]>;
  key?: Maybe<Scalars["String"]["output"]>;
  language?: Maybe<Scalars["String"]["output"]>;
  limit?: Maybe<Scalars["Int"]["output"]>;
  text?: Maybe<Scalars["String"]["output"]>;
};

export type loqate_AddressCaptureFindResponse = {
  __typename?: "loqate_AddressCaptureFindResponse";
  Items?: Maybe<Array<Maybe<loqate_AddressCaptureFindResponse_Item>>>;
  _source?: Maybe<Scalars["String"]["output"]>;
};

export type loqate_AddressCaptureFindResponse_Item = {
  __typename?: "loqate_AddressCaptureFindResponse_Item";
  Description?: Maybe<Scalars["String"]["output"]>;
  Id?: Maybe<Scalars["String"]["output"]>;
  Text?: Maybe<Scalars["String"]["output"]>;
  Type?: Maybe<loqate_AddressCaptureFindResponse_Item_ItemType>;
  _source?: Maybe<Scalars["String"]["output"]>;
};

export enum loqate_AddressCaptureFindResponse_Item_ItemType {
  ADDRESS = "ADDRESS",
  CONTAINER = "CONTAINER",
}

export type loqate_AddressCaptureRetrieveRequest = {
  __typename?: "loqate_AddressCaptureRetrieveRequest";
  _source?: Maybe<Scalars["String"]["output"]>;
  id?: Maybe<Scalars["String"]["output"]>;
  key?: Maybe<Scalars["String"]["output"]>;
};

export type loqate_AddressCaptureRetrieveResponse = {
  __typename?: "loqate_AddressCaptureRetrieveResponse";
  Items?: Maybe<Array<Maybe<loqate_AddressCaptureRetrieveResponse_Item>>>;
  _source?: Maybe<Scalars["String"]["output"]>;
};

export type loqate_AddressCaptureRetrieveResponse_Item = {
  __typename?: "loqate_AddressCaptureRetrieveResponse_Item";
  City?: Maybe<Scalars["String"]["output"]>;
  CountryIso2?: Maybe<Scalars["String"]["output"]>;
  Id?: Maybe<Scalars["String"]["output"]>;
  Line1?: Maybe<Scalars["String"]["output"]>;
  PostalCode?: Maybe<Scalars["String"]["output"]>;
  ProvinceCode?: Maybe<Scalars["String"]["output"]>;
  SubBuilding?: Maybe<Scalars["String"]["output"]>;
  _source?: Maybe<Scalars["String"]["output"]>;
};

export type loqate_Loqate = {
  __typename?: "loqate_Loqate";
  FindAddressSuggestions?: Maybe<loqate_AddressCaptureFindResponse>;
  SelectAddressSuggestion?: Maybe<loqate_AddressCaptureRetrieveResponse>;
  _source?: Maybe<Scalars["String"]["output"]>;
};

export type loqate_LoqateFindAddressSuggestionsArgs = {
  input?: InputMaybe<Input_loqate_AddressCaptureFindRequest>;
};

export type loqate_LoqateSelectAddressSuggestionArgs = {
  input?: InputMaybe<Input_loqate_AddressCaptureRetrieveRequest>;
};

export type mparticle_Identity = {
  __typename?: "mparticle_Identity";
  _source?: Maybe<Scalars["String"]["output"]>;
  type?: Maybe<Scalars["String"]["output"]>;
  value?: Maybe<Scalars["String"]["output"]>;
};

export type mparticle_Mparticle = {
  __typename?: "mparticle_Mparticle";
  Profile?: Maybe<mparticle_ProfileResponse>;
  ProfileByMpid?: Maybe<mparticle_ProfileResponse>;
  _source?: Maybe<Scalars["String"]["output"]>;
};

export type mparticle_MparticleProfileArgs = {
  input?: InputMaybe<Input_mparticle_ProfileRequest>;
};

export type mparticle_MparticleProfileByMpidArgs = {
  input?: InputMaybe<Input_mparticle_ProfileRequest>;
};

export type mparticle_MparticleOauth = {
  __typename?: "mparticle_MparticleOauth";
  Token?: Maybe<mparticle_TokenResponse>;
  _source?: Maybe<Scalars["String"]["output"]>;
};

export type mparticle_MparticleOauthTokenArgs = {
  input?: InputMaybe<Input_mparticle_TokenRequest>;
};

export type mparticle_Mpid = {
  __typename?: "mparticle_Mpid";
  _source?: Maybe<Scalars["String"]["output"]>;
  mpid?: Maybe<Scalars["String"]["output"]>;
};

export type mparticle_ProfileRequest = {
  __typename?: "mparticle_ProfileRequest";
  _source?: Maybe<Scalars["String"]["output"]>;
  accountId?: Maybe<Scalars["String"]["output"]>;
  environmentType?: Maybe<Scalars["String"]["output"]>;
  identity?: Maybe<mparticle_Identity>;
  mpid?: Maybe<mparticle_Mpid>;
  orgId?: Maybe<Scalars["String"]["output"]>;
  workspaceId?: Maybe<Scalars["String"]["output"]>;
};

export type mparticle_ProfileResponse = {
  __typename?: "mparticle_ProfileResponse";
  _source?: Maybe<Scalars["String"]["output"]>;
  averageOrderValue?: Maybe<Scalars["Float"]["output"]>;
  brandCategories?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
  buyerFeedbackRating?: Maybe<Scalars["Float"]["output"]>;
  buyerLabel?: Maybe<Scalars["String"]["output"]>;
  cumulativeGmv?: Maybe<Scalars["Float"]["output"]>;
  firstOrderDate?: Maybe<Scalars["String"]["output"]>;
  mostRecentOrderDate?: Maybe<Scalars["String"]["output"]>;
  mpid?: Maybe<Scalars["String"]["output"]>;
  recentlyViewedCsps?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
  sellerFeedbackRating?: Maybe<Scalars["Float"]["output"]>;
  sellerLabel?: Maybe<Scalars["String"]["output"]>;
  topBrandCategories?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
  topBrands?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
  topCategories?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
  topCategoryUuids?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
  topMostRecentProductTypes?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
  topProductType?: Maybe<Scalars["String"]["output"]>;
  topProductTypeUuids?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
  topProductTypes?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
  totalBuyerOffersAccepted?: Maybe<Scalars["Int"]["output"]>;
  totalBuyerOffersCreated?: Maybe<Scalars["Int"]["output"]>;
  totalFeedFollows?: Maybe<Scalars["Int"]["output"]>;
  totalListingsCreated?: Maybe<Scalars["Int"]["output"]>;
  totalListingsDraft?: Maybe<Scalars["Int"]["output"]>;
  totalListingsLive?: Maybe<Scalars["Int"]["output"]>;
  totalListingsSold?: Maybe<Scalars["Int"]["output"]>;
  totalListingsSuspended?: Maybe<Scalars["Int"]["output"]>;
  totalPurchases?: Maybe<Scalars["Int"]["output"]>;
};

export type mparticle_TokenRequest = {
  __typename?: "mparticle_TokenRequest";
  _source?: Maybe<Scalars["String"]["output"]>;
  audience?: Maybe<Scalars["String"]["output"]>;
  clientId?: Maybe<Scalars["String"]["output"]>;
  clientSecret?: Maybe<Scalars["String"]["output"]>;
  grantType?: Maybe<Scalars["String"]["output"]>;
};

export type mparticle_TokenResponse = {
  __typename?: "mparticle_TokenResponse";
  _source?: Maybe<Scalars["String"]["output"]>;
  accessToken?: Maybe<Scalars["String"]["output"]>;
  expiresIn?: Maybe<Scalars["Int"]["output"]>;
  tokenType?: Maybe<Scalars["String"]["output"]>;
};

export type reverb_config_cache_AddressFormConfig = {
  __typename?: "reverb_config_cache_AddressFormConfig";
  _source?: Maybe<Scalars["String"]["output"]>;
  countryCode?: Maybe<Scalars["String"]["output"]>;
  fields?: Maybe<Array<Maybe<reverb_config_cache_AddressFormField>>>;
};

export type reverb_config_cache_AddressFormField = {
  __typename?: "reverb_config_cache_AddressFormField";
  _source?: Maybe<Scalars["String"]["output"]>;
  displayNames?: Maybe<
    Array<Maybe<reverb_config_cache_LocalizedAddressFormDisplayName>>
  >;
  fieldName?: Maybe<Scalars["String"]["output"]>;
  required?: Maybe<Scalars["Boolean"]["output"]>;
};

export type reverb_config_cache_AddressValidation = {
  __typename?: "reverb_config_cache_AddressValidation";
  _source?: Maybe<Scalars["String"]["output"]>;
  postalCode?: Maybe<Scalars["String"]["output"]>;
  state?: Maybe<Scalars["String"]["output"]>;
};

export type reverb_config_cache_AddressValidationsResponse = {
  __typename?: "reverb_config_cache_AddressValidationsResponse";
  _source?: Maybe<Scalars["String"]["output"]>;
  addresses?: Maybe<Array<Maybe<reverb_config_cache_AddressValidation>>>;
};

export type reverb_config_cache_ConfigService = {
  __typename?: "reverb_config_cache_ConfigService";
  AddressValidations?: Maybe<reverb_config_cache_AddressValidationsResponse>;
  ExchangeCurrency?: Maybe<reverb_config_cache_ExchangeCurrencyResponse>;
  GetExchangeRates?: Maybe<reverb_config_cache_CurrencyResponse>;
  GetInternationalAddressFormConfig?: Maybe<reverb_config_cache_InternationalAddressFormConfigResponse>;
  GetSearchRedirects?: Maybe<reverb_config_cache_SearchRedirectsResponse>;
  GetTranslations?: Maybe<reverb_config_cache_TranslationsResponse>;
  GetVersionedExchangeRates?: Maybe<reverb_config_cache_VersionedExchangeRatesResponse>;
  TaxRates?: Maybe<reverb_config_cache_TaxRatesResponse>;
  _source?: Maybe<Scalars["String"]["output"]>;
};

export type reverb_config_cache_ConfigServiceAddressValidationsArgs = {
  input?: InputMaybe<Input_google_protobuf_Empty>;
};

export type reverb_config_cache_ConfigServiceExchangeCurrencyArgs = {
  input?: InputMaybe<Input_reverb_config_cache_ExchangeCurrencyRequest>;
};

export type reverb_config_cache_ConfigServiceGetExchangeRatesArgs = {
  input?: InputMaybe<Input_reverb_config_cache_ExchangeRateRequest>;
};

export type reverb_config_cache_ConfigServiceGetInternationalAddressFormConfigArgs =
  {
    input?: InputMaybe<Input_google_protobuf_Empty>;
  };

export type reverb_config_cache_ConfigServiceGetSearchRedirectsArgs = {
  input?: InputMaybe<Input_reverb_config_cache_SearchRedirectsRequest>;
};

export type reverb_config_cache_ConfigServiceGetTranslationsArgs = {
  input?: InputMaybe<Input_reverb_config_cache_TranslationsRequest>;
};

export type reverb_config_cache_ConfigServiceGetVersionedExchangeRatesArgs = {
  input?: InputMaybe<Input_reverb_config_cache_VersionedExchangeRatesRequest>;
};

export type reverb_config_cache_ConfigServiceTaxRatesArgs = {
  input?: InputMaybe<Input_google_protobuf_Empty>;
};

export type reverb_config_cache_CurrencyResponse = {
  __typename?: "reverb_config_cache_CurrencyResponse";
  _source?: Maybe<Scalars["String"]["output"]>;
  rates?: Maybe<Array<Maybe<reverb_config_cache_ExchangeRate>>>;
};

export type reverb_config_cache_ExchangeCurrencyRequest = {
  __typename?: "reverb_config_cache_ExchangeCurrencyRequest";
  _source?: Maybe<Scalars["String"]["output"]>;
  amountMinorUnits?: Maybe<Scalars["Int"]["output"]>;
  from?: Maybe<Scalars["String"]["output"]>;
  inverseReverseRates?: Maybe<Scalars["Boolean"]["output"]>;
  to?: Maybe<Scalars["String"]["output"]>;
};

export type reverb_config_cache_ExchangeCurrencyResponse = {
  __typename?: "reverb_config_cache_ExchangeCurrencyResponse";
  _source?: Maybe<Scalars["String"]["output"]>;
  amountMinorUnits?: Maybe<Scalars["Int"]["output"]>;
  from?: Maybe<Scalars["String"]["output"]>;
  to?: Maybe<Scalars["String"]["output"]>;
};

export type reverb_config_cache_ExchangeRate = {
  __typename?: "reverb_config_cache_ExchangeRate";
  _source?: Maybe<Scalars["String"]["output"]>;
  from?: Maybe<Scalars["String"]["output"]>;
  rate?: Maybe<Scalars["Float"]["output"]>;
  to?: Maybe<Scalars["String"]["output"]>;
};

export type reverb_config_cache_ExchangeRateRequest = {
  __typename?: "reverb_config_cache_ExchangeRateRequest";
  _source?: Maybe<Scalars["String"]["output"]>;
  inverseReverseRates?: Maybe<Scalars["Boolean"]["output"]>;
  useMajorUnitConversion?: Maybe<Scalars["Boolean"]["output"]>;
};

export type reverb_config_cache_Filters = {
  __typename?: "reverb_config_cache_Filters";
  _source?: Maybe<Scalars["String"]["output"]>;
  brand?: Maybe<Scalars["String"]["output"]>;
  category?: Maybe<Scalars["String"]["output"]>;
  productFamily?: Maybe<Scalars["String"]["output"]>;
  productType?: Maybe<Scalars["String"]["output"]>;
};

export type reverb_config_cache_InternationalAddressFormConfigResponse = {
  __typename?: "reverb_config_cache_InternationalAddressFormConfigResponse";
  _source?: Maybe<Scalars["String"]["output"]>;
  configs?: Maybe<Array<Maybe<reverb_config_cache_AddressFormConfig>>>;
};

export type reverb_config_cache_LocalizedAddressFormDisplayName = {
  __typename?: "reverb_config_cache_LocalizedAddressFormDisplayName";
  _source?: Maybe<Scalars["String"]["output"]>;
  displayName?: Maybe<Scalars["String"]["output"]>;
  locale?: Maybe<Scalars["String"]["output"]>;
};

export type reverb_config_cache_SearchRedirect = {
  __typename?: "reverb_config_cache_SearchRedirect";
  _source?: Maybe<Scalars["String"]["output"]>;
  filters?: Maybe<reverb_config_cache_Filters>;
  queryTerm?: Maybe<Scalars["String"]["output"]>;
  redirectTerm?: Maybe<Scalars["String"]["output"]>;
  /** @deprecated Field no longer supported */
  redirectTerms?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
  type?: Maybe<reverb_config_cache_SearchRedirect_Type>;
};

export enum reverb_config_cache_SearchRedirect_Type {
  Brand = "Brand",
  BrandModel = "BrandModel",
  ProductFamily = "ProductFamily",
}

export type reverb_config_cache_SearchRedirectsRequest = {
  __typename?: "reverb_config_cache_SearchRedirectsRequest";
  _?: Maybe<Scalars["Boolean"]["output"]>;
  _source?: Maybe<Scalars["String"]["output"]>;
};

export type reverb_config_cache_SearchRedirectsResponse = {
  __typename?: "reverb_config_cache_SearchRedirectsResponse";
  _source?: Maybe<Scalars["String"]["output"]>;
  redirects?: Maybe<Array<Maybe<reverb_config_cache_SearchRedirect>>>;
};

export type reverb_config_cache_TaxRate = {
  __typename?: "reverb_config_cache_TaxRate";
  _source?: Maybe<Scalars["String"]["output"]>;
  postalcode?: Maybe<Scalars["String"]["output"]>;
  state?: Maybe<Scalars["String"]["output"]>;
  totalSalesTaxRate?: Maybe<Scalars["String"]["output"]>;
  totalSalesTaxRatePrecision?: Maybe<Scalars["Int"]["output"]>;
};

export type reverb_config_cache_TaxRatesResponse = {
  __typename?: "reverb_config_cache_TaxRatesResponse";
  _source?: Maybe<Scalars["String"]["output"]>;
  rates?: Maybe<Array<Maybe<reverb_config_cache_TaxRate>>>;
};

export type reverb_config_cache_TranslationsRequest = {
  __typename?: "reverb_config_cache_TranslationsRequest";
  _source?: Maybe<Scalars["String"]["output"]>;
  locales?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
  project?: Maybe<Scalars["String"]["output"]>;
  resource?: Maybe<Scalars["String"]["output"]>;
};

export type reverb_config_cache_TranslationsResponse = {
  __typename?: "reverb_config_cache_TranslationsResponse";
  _source?: Maybe<Scalars["String"]["output"]>;
  translations?: Maybe<
    Array<Maybe<reverb_config_cache_TranslationsResponse_Translation>>
  >;
};

export type reverb_config_cache_TranslationsResponse_Translation = {
  __typename?: "reverb_config_cache_TranslationsResponse_Translation";
  _source?: Maybe<Scalars["String"]["output"]>;
  content?: Maybe<Scalars["String"]["output"]>;
  locale?: Maybe<Scalars["String"]["output"]>;
  project?: Maybe<Scalars["String"]["output"]>;
  resource?: Maybe<Scalars["String"]["output"]>;
};

export type reverb_config_cache_VersionedExchangeRatesRequest = {
  __typename?: "reverb_config_cache_VersionedExchangeRatesRequest";
  _source?: Maybe<Scalars["String"]["output"]>;
  inverseReverseRates?: Maybe<Scalars["Boolean"]["output"]>;
  version?: Maybe<Scalars["String"]["output"]>;
};

export type reverb_config_cache_VersionedExchangeRatesResponse = {
  __typename?: "reverb_config_cache_VersionedExchangeRatesResponse";
  _source?: Maybe<Scalars["String"]["output"]>;
  rates?: Maybe<Array<Maybe<reverb_config_cache_ExchangeRate>>>;
};

export type reverb_event_AddedToCart = {
  __typename?: "reverb_event_AddedToCart";
  _source?: Maybe<Scalars["String"]["output"]>;
  aid?: Maybe<Scalars["String"]["output"]>;
  cartItemUuid?: Maybe<Scalars["String"]["output"]>;
  listing?: Maybe<reverb_event_Listing>;
  otherListings?: Maybe<Array<Maybe<reverb_event_Listing>>>;
  referer?: Maybe<Scalars["String"]["output"]>;
  userContext?: Maybe<reverb_event_UserContext>;
};

export type reverb_event_AdminAbilityAndAbilityProfileUpdated = {
  __typename?: "reverb_event_AdminAbilityAndAbilityProfileUpdated";
  _source?: Maybe<Scalars["String"]["output"]>;
  abilityManagerUserId?: Maybe<Scalars["String"]["output"]>;
  abilityProfiles?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
  abilityRecipientUserId?: Maybe<Scalars["String"]["output"]>;
  adHocAbilities?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
};

export type reverb_event_Article = {
  __typename?: "reverb_event_Article";
  _source?: Maybe<Scalars["String"]["output"]>;
  id?: Maybe<Scalars["String"]["output"]>;
};

export type reverb_event_ArticleView = {
  __typename?: "reverb_event_ArticleView";
  _source?: Maybe<Scalars["String"]["output"]>;
  aid?: Maybe<Scalars["String"]["output"]>;
  articleId?: Maybe<Scalars["String"]["output"]>;
  referer?: Maybe<Scalars["String"]["output"]>;
  requestId?: Maybe<Scalars["String"]["output"]>;
  url?: Maybe<Scalars["String"]["output"]>;
  userContext?: Maybe<reverb_event_UserContext>;
  utmCampaign?: Maybe<Scalars["String"]["output"]>;
};

export type reverb_event_ArticleViewRelatedContent = {
  __typename?: "reverb_event_ArticleViewRelatedContent";
  _source?: Maybe<Scalars["String"]["output"]>;
  articleViewArticleId?: Maybe<Scalars["String"]["output"]>;
  articles?: Maybe<Array<Maybe<reverb_event_Article>>>;
  referer?: Maybe<Scalars["String"]["output"]>;
  type?: Maybe<Scalars["String"]["output"]>;
  url?: Maybe<Scalars["String"]["output"]>;
  userContext?: Maybe<reverb_event_UserContext>;
};

export enum reverb_event_ArticleViewRelatedContent_RelatedContentType {
  ARTICLES = "ARTICLES",
}

export type reverb_event_AttributeChange = {
  __typename?: "reverb_event_AttributeChange";
  _source?: Maybe<Scalars["String"]["output"]>;
  name?: Maybe<Scalars["String"]["output"]>;
  newValue?: Maybe<Scalars["String"]["output"]>;
  previousValue?: Maybe<Scalars["String"]["output"]>;
};

export type reverb_event_AttributedLanding = {
  __typename?: "reverb_event_AttributedLanding";
  _source?: Maybe<Scalars["String"]["output"]>;
  aid?: Maybe<Scalars["String"]["output"]>;
  cookieId?: Maybe<Scalars["String"]["output"]>;
  landingBucket?: Maybe<Scalars["String"]["output"]>;
  landingDevice?: Maybe<Scalars["String"]["output"]>;
  landingId?: Maybe<Scalars["String"]["output"]>;
  landingTime?: Maybe<Scalars["String"]["output"]>;
  orderDevice?: Maybe<Scalars["String"]["output"]>;
  orderId?: Maybe<Scalars["String"]["output"]>;
  orderedAt?: Maybe<Scalars["String"]["output"]>;
  productId?: Maybe<Scalars["String"]["output"]>;
  referer?: Maybe<Scalars["String"]["output"]>;
  registeredAt?: Maybe<Scalars["String"]["output"]>;
  url?: Maybe<Scalars["String"]["output"]>;
  userId?: Maybe<Scalars["String"]["output"]>;
  userShippingRegion?: Maybe<Scalars["String"]["output"]>;
  utmCampaign?: Maybe<Scalars["String"]["output"]>;
};

/** Fingerprint from fingerprintjs2 */
export type reverb_event_BrowserFingerprint = {
  __typename?: "reverb_event_BrowserFingerprint";
  _source?: Maybe<Scalars["String"]["output"]>;
  /** Hashed fingerprint */
  hash?: Maybe<Scalars["String"]["output"]>;
  liedBrowser?: Maybe<Scalars["Boolean"]["output"]>;
  liedLanguages?: Maybe<Scalars["Boolean"]["output"]>;
  liedOs?: Maybe<Scalars["Boolean"]["output"]>;
  liedResolution?: Maybe<Scalars["Boolean"]["output"]>;
  localstorage?: Maybe<Scalars["Boolean"]["output"]>;
  plugins?: Maybe<Scalars["Int"]["output"]>;
  resolution?: Maybe<Scalars["String"]["output"]>;
  session?: Maybe<Scalars["Boolean"]["output"]>;
};

export type reverb_event_BumpsReserved = {
  __typename?: "reverb_event_BumpsReserved";
  _source?: Maybe<Scalars["String"]["output"]>;
  amount?: Maybe<Scalars["Int"]["output"]>;
  budgetType?: Maybe<Scalars["String"]["output"]>;
  listing?: Maybe<reverb_event_Listing>;
  price?: Maybe<reverb_event_Price>;
  userContext?: Maybe<reverb_event_UserContext>;
};

export type reverb_event_BundleData = {
  __typename?: "reverb_event_BundleData";
  _source?: Maybe<Scalars["String"]["output"]>;
  paymentGroups?: Maybe<Array<Maybe<reverb_event_PaymentGroup>>>;
  paymentGroupsCount?: Maybe<Scalars["Int"]["output"]>;
};

export type reverb_event_CanonicalProduct = {
  __typename?: "reverb_event_CanonicalProduct";
  _source?: Maybe<Scalars["String"]["output"]>;
  id?: Maybe<Scalars["String"]["output"]>;
};

export type reverb_event_CanonicalProductUpdated = {
  __typename?: "reverb_event_CanonicalProductUpdated";
  _source?: Maybe<Scalars["String"]["output"]>;
  canonicalProduct?: Maybe<reverb_event_CanonicalProduct>;
  changes?: Maybe<Array<Maybe<reverb_event_AttributeChange>>>;
  userContext?: Maybe<reverb_event_UserContext>;
};

export type reverb_event_CartBundles = {
  __typename?: "reverb_event_CartBundles";
  _source?: Maybe<Scalars["String"]["output"]>;
  bundleData?: Maybe<reverb_event_BundleData>;
  userContext?: Maybe<reverb_event_UserContext>;
};

export type reverb_event_CartItemBundle = {
  __typename?: "reverb_event_CartItemBundle";
  _source?: Maybe<Scalars["String"]["output"]>;
  cartItemsCount?: Maybe<Scalars["Int"]["output"]>;
  currency?: Maybe<Scalars["String"]["output"]>;
  paymentMethod?: Maybe<Scalars["String"]["output"]>;
};

export type reverb_event_CartView = {
  __typename?: "reverb_event_CartView";
  _source?: Maybe<Scalars["String"]["output"]>;
  listings?: Maybe<Array<Maybe<reverb_event_Listing>>>;
  requestId?: Maybe<Scalars["String"]["output"]>;
  userContext?: Maybe<reverb_event_UserContext>;
};

/** includes Cart and Checkout step page views */
export type reverb_event_CheckoutView = {
  __typename?: "reverb_event_CheckoutView";
  _source?: Maybe<Scalars["String"]["output"]>;
  checkoutId?: Maybe<Scalars["String"]["output"]>;
  checkoutUuid?: Maybe<Scalars["String"]["output"]>;
  paymentMethod?: Maybe<Scalars["String"]["output"]>;
  referer?: Maybe<Scalars["String"]["output"]>;
  stepName?: Maybe<Scalars["String"]["output"]>;
  userContext?: Maybe<reverb_event_UserContext>;
};

export enum reverb_event_CheckoutView_StepName {
  ASYNC_PAYMENT_IN_PROGRESS = "ASYNC_PAYMENT_IN_PROGRESS",
  ASYNC_PAYPAL_TOKEN_FETCH_IN_PROGRESS = "ASYNC_PAYPAL_TOKEN_FETCH_IN_PROGRESS",
  BILLING = "BILLING",
  CART = "CART",
  CONFIRMATION = "CONFIRMATION",
  PLACE_ORDER = "PLACE_ORDER",
  SHIPPING_REGISTRATION = "SHIPPING_REGISTRATION",
}

export type reverb_event_ClickStats = {
  __typename?: "reverb_event_ClickStats";
  _source?: Maybe<Scalars["String"]["output"]>;
  bumpClicks?: Maybe<Scalars["Int"]["output"]>;
  bumpImpressions?: Maybe<Scalars["Int"]["output"]>;
  clicks?: Maybe<Scalars["Int"]["output"]>;
  entityId?: Maybe<Scalars["String"]["output"]>;
  entityType?: Maybe<Scalars["String"]["output"]>;
  impressions?: Maybe<Scalars["Int"]["output"]>;
};

export enum reverb_event_CollectionView {
  CURATED_SET = "CURATED_SET",
  FEED = "FEED",
  HOMEPAGE = "HOMEPAGE",
  HYDRA = "HYDRA",
  PRICE_GUIDE = "PRICE_GUIDE",
  SALE = "SALE",
  SEARCH = "SEARCH",
}

export type reverb_event_ComparisonShoppingPage = {
  __typename?: "reverb_event_ComparisonShoppingPage";
  _source?: Maybe<Scalars["String"]["output"]>;
  id?: Maybe<Scalars["String"]["output"]>;
  lowestPriceForNew?: Maybe<reverb_event_Price>;
  lowestPriceForUsed?: Maybe<reverb_event_Price>;
  newListingCount?: Maybe<Scalars["Int"]["output"]>;
  usedListingCount?: Maybe<Scalars["Int"]["output"]>;
};

/** Deprecated, use ComponentInteraction */
export type reverb_event_ComparisonShoppingPageClick = {
  __typename?: "reverb_event_ComparisonShoppingPageClick";
  _source?: Maybe<Scalars["String"]["output"]>;
  comparisonShoppingPage?: Maybe<reverb_event_ComparisonShoppingPage>;
  componentName?: Maybe<Scalars["String"]["output"]>;
  referer?: Maybe<Scalars["String"]["output"]>;
  url?: Maybe<Scalars["String"]["output"]>;
  userContext?: Maybe<reverb_event_UserContext>;
};

export type reverb_event_ComparisonShoppingPageView = {
  __typename?: "reverb_event_ComparisonShoppingPageView";
  _source?: Maybe<Scalars["String"]["output"]>;
  buyButtonListingId?: Maybe<Scalars["String"]["output"]>;
  buyButtonListingType?: Maybe<Scalars["String"]["output"]>;
  buyButtonReason?: Maybe<Scalars["String"]["output"]>;
  canonicalProductIds?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
  comparisonShoppingPageId?: Maybe<Scalars["String"]["output"]>;
  condition?: Maybe<Scalars["String"]["output"]>;
  listings?: Maybe<Array<Maybe<reverb_event_Listing>>>;
  lowestPriceForNew?: Maybe<reverb_event_Price>;
  lowestPriceForUsed?: Maybe<reverb_event_Price>;
  priceGuideId?: Maybe<Scalars["String"]["output"]>;
  referer?: Maybe<Scalars["String"]["output"]>;
  requestId?: Maybe<Scalars["String"]["output"]>;
  url?: Maybe<Scalars["String"]["output"]>;
  userContext?: Maybe<reverb_event_UserContext>;
  utmCampaign?: Maybe<Scalars["String"]["output"]>;
  utmMedium?: Maybe<Scalars["String"]["output"]>;
  utmSource?: Maybe<Scalars["String"]["output"]>;
};

export type reverb_event_ComparisonShoppingPagesView = {
  __typename?: "reverb_event_ComparisonShoppingPagesView";
  _source?: Maybe<Scalars["String"]["output"]>;
  comparisonShoppingPages?: Maybe<
    Array<Maybe<reverb_event_ComparisonShoppingPage>>
  >;
  componentName?: Maybe<Scalars["String"]["output"]>;
  referer?: Maybe<Scalars["String"]["output"]>;
  url?: Maybe<Scalars["String"]["output"]>;
  userContext?: Maybe<reverb_event_UserContext>;
};

export type reverb_event_ComponentInteraction = {
  __typename?: "reverb_event_ComponentInteraction";
  _source?: Maybe<Scalars["String"]["output"]>;
  bumped?: Maybe<Scalars["Boolean"]["output"]>;
  componentName?: Maybe<Scalars["String"]["output"]>;
  context?: Maybe<Scalars["String"]["output"]>;
  entityId?: Maybe<Scalars["String"]["output"]>;
  entityType?: Maybe<Scalars["String"]["output"]>;
  freeTextQuery?: Maybe<Scalars["String"]["output"]>;
  impressionUuid?: Maybe<Scalars["String"]["output"]>;
  interactionType?: Maybe<Scalars["String"]["output"]>;
  page?: Maybe<Scalars["Int"]["output"]>;
  pageViewId?: Maybe<Scalars["String"]["output"]>;
  perPage?: Maybe<Scalars["Int"]["output"]>;
  position?: Maybe<Scalars["Int"]["output"]>;
  referer?: Maybe<Scalars["String"]["output"]>;
  targetUrl?: Maybe<Scalars["String"]["output"]>;
  totalResults?: Maybe<Scalars["Int"]["output"]>;
  url?: Maybe<Scalars["String"]["output"]>;
  userContext?: Maybe<reverb_event_UserContext>;
  utmCampaign?: Maybe<Scalars["String"]["output"]>;
  utmMedium?: Maybe<Scalars["String"]["output"]>;
  utmSource?: Maybe<Scalars["String"]["output"]>;
};

export enum reverb_event_ComponentInteraction_InteractionType {
  CLICK = "CLICK",
  FORCE_TAP = "FORCE_TAP",
  HOVER = "HOVER",
  SWIPE = "SWIPE",
}

export type reverb_event_ComponentsView = {
  __typename?: "reverb_event_ComponentsView";
  _source?: Maybe<Scalars["String"]["output"]>;
  algorithmVersion?: Maybe<Scalars["String"]["output"]>;
  componentName?: Maybe<Scalars["String"]["output"]>;
  context?: Maybe<Scalars["String"]["output"]>;
  entityIds?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
  entityType?: Maybe<Scalars["String"]["output"]>;
  impressionUuid?: Maybe<Scalars["String"]["output"]>;
  pageViewId?: Maybe<Scalars["String"]["output"]>;
  referer?: Maybe<Scalars["String"]["output"]>;
  url?: Maybe<Scalars["String"]["output"]>;
  userContext?: Maybe<reverb_event_UserContext>;
  utmCampaign?: Maybe<Scalars["String"]["output"]>;
  utmMedium?: Maybe<Scalars["String"]["output"]>;
  utmSource?: Maybe<Scalars["String"]["output"]>;
};

export type reverb_event_ContestSignUp = {
  __typename?: "reverb_event_ContestSignUp";
  _source?: Maybe<Scalars["String"]["output"]>;
  aid?: Maybe<Scalars["String"]["output"]>;
  questionResponse?: Maybe<Scalars["String"]["output"]>;
  referer?: Maybe<Scalars["String"]["output"]>;
  userContext?: Maybe<reverb_event_UserContext>;
  utmCampaign?: Maybe<Scalars["String"]["output"]>;
};

export type reverb_event_ConversationUpdated = {
  __typename?: "reverb_event_ConversationUpdated";
  _source?: Maybe<Scalars["String"]["output"]>;
  id?: Maybe<Scalars["String"]["output"]>;
  initialAuthorId?: Maybe<Scalars["String"]["output"]>;
  initialRecipientId?: Maybe<Scalars["String"]["output"]>;
  lastMessageId?: Maybe<Scalars["String"]["output"]>;
  listing?: Maybe<reverb_event_Listing>;
  requestId?: Maybe<Scalars["String"]["output"]>;
  userContext?: Maybe<reverb_event_UserContext>;
};

export type reverb_event_CuratedSetView = {
  __typename?: "reverb_event_CuratedSetView";
  _source?: Maybe<Scalars["String"]["output"]>;
  curatedSetId?: Maybe<Scalars["String"]["output"]>;
  listings?: Maybe<Array<Maybe<reverb_event_Listing>>>;
  requestId?: Maybe<Scalars["String"]["output"]>;
  userContext?: Maybe<reverb_event_UserContext>;
};

export type reverb_event_Device = {
  __typename?: "reverb_event_Device";
  _source?: Maybe<Scalars["String"]["output"]>;
  appVersion?: Maybe<Scalars["String"]["output"]>;
  brand?: Maybe<Scalars["String"]["output"]>;
  manufacturer?: Maybe<Scalars["String"]["output"]>;
  model?: Maybe<Scalars["String"]["output"]>;
  /** / DeviceName */
  name?: Maybe<Scalars["String"]["output"]>;
  osVersion?: Maybe<Scalars["String"]["output"]>;
  userAgent?: Maybe<Scalars["String"]["output"]>;
  version?: Maybe<Scalars["String"]["output"]>;
};

export enum reverb_event_DeviceName {
  ANDROID = "ANDROID",
  IOS = "IOS",
  TOUCH = "TOUCH",
  WEB = "WEB",
}

export type reverb_event_DigitalListing = {
  __typename?: "reverb_event_DigitalListing";
  _source?: Maybe<Scalars["String"]["output"]>;
  categoryUuids?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
  id?: Maybe<Scalars["String"]["output"]>;
  price?: Maybe<reverb_event_Price>;
  /** / ListingStatus */
  status?: Maybe<Scalars["String"]["output"]>;
};

export type reverb_event_DigitalListingDownload = {
  __typename?: "reverb_event_DigitalListingDownload";
  _source?: Maybe<Scalars["String"]["output"]>;
  digitalListingId?: Maybe<Scalars["String"]["output"]>;
  digitalVariantId?: Maybe<Scalars["String"]["output"]>;
  requestId?: Maybe<Scalars["String"]["output"]>;
  userContext?: Maybe<reverb_event_UserContext>;
};

export type reverb_event_DigitalListingView = {
  __typename?: "reverb_event_DigitalListingView";
  _source?: Maybe<Scalars["String"]["output"]>;
  digitalListing?: Maybe<reverb_event_DigitalListing>;
  requestId?: Maybe<Scalars["String"]["output"]>;
  userContext?: Maybe<reverb_event_UserContext>;
};

export type reverb_event_DiscountCodeApplied = {
  __typename?: "reverb_event_DiscountCodeApplied";
  _source?: Maybe<Scalars["String"]["output"]>;
  discountCode?: Maybe<Scalars["String"]["output"]>;
  error?: Maybe<Scalars["String"]["output"]>;
  referer?: Maybe<Scalars["String"]["output"]>;
  success?: Maybe<Scalars["Boolean"]["output"]>;
  userContext?: Maybe<reverb_event_UserContext>;
};

export type reverb_event_EntityUpdated = {
  __typename?: "reverb_event_EntityUpdated";
  _source?: Maybe<Scalars["String"]["output"]>;
  action?: Maybe<reverb_event_EntityUpdated_Action>;
  id?: Maybe<Scalars["String"]["output"]>;
  name?: Maybe<Scalars["String"]["output"]>;
  timestamp?: Maybe<Scalars["String"]["output"]>;
};

export enum reverb_event_EntityUpdated_Action {
  DELETED = "DELETED",
  UPDATED = "UPDATED",
}

export type reverb_event_Error = {
  __typename?: "reverb_event_Error";
  _source?: Maybe<Scalars["String"]["output"]>;
  error?: Maybe<Scalars["String"]["output"]>;
  userContext?: Maybe<reverb_event_UserContext>;
};

export type reverb_event_Event = {
  __typename?: "reverb_event_Event";
  _source?: Maybe<Scalars["String"]["output"]>;
  level?: Maybe<Scalars["String"]["output"]>;
  messageName?: Maybe<Scalars["String"]["output"]>;
  payload?: Maybe<Scalars["String"]["output"]>;
};

export type reverb_event_EventRequest = {
  __typename?: "reverb_event_EventRequest";
  _source?: Maybe<Scalars["String"]["output"]>;
  events?: Maybe<Array<Maybe<reverb_event_Event>>>;
};

export type reverb_event_Experiment = {
  __typename?: "reverb_event_Experiment";
  _source?: Maybe<Scalars["String"]["output"]>;
  mobileContext?: Maybe<reverb_event_Experiment_MobileContext>;
  multiClientExperimentContext?: Maybe<Scalars["String"]["output"]>;
  multivariateExperiment?: Maybe<Scalars["Boolean"]["output"]>;
  name?: Maybe<Scalars["String"]["output"]>;
  /** / Deprecated in favor of mobile_context */
  qualifyForMobile?: Maybe<Scalars["Boolean"]["output"]>;
  useInFrontend?: Maybe<Scalars["Boolean"]["output"]>;
  useInMarketplaceListingsSearch?: Maybe<Scalars["Boolean"]["output"]>;
  value?: Maybe<Scalars["String"]["output"]>;
};

export type reverb_event_Experiment_MobileContext = {
  __typename?: "reverb_event_Experiment_MobileContext";
  _source?: Maybe<Scalars["String"]["output"]>;
  reportAs?: Maybe<Scalars["String"]["output"]>;
  value?: Maybe<Scalars["String"]["output"]>;
};

export type reverb_event_FollowAdded = {
  __typename?: "reverb_event_FollowAdded";
  _source?: Maybe<Scalars["String"]["output"]>;
  referer?: Maybe<Scalars["String"]["output"]>;
  searchParams?: Maybe<Scalars["String"]["output"]>;
  searchableId?: Maybe<Scalars["String"]["output"]>;
  searchableType?: Maybe<Scalars["String"]["output"]>;
  userContext?: Maybe<reverb_event_UserContext>;
};

export type reverb_event_FollowUpdated = {
  __typename?: "reverb_event_FollowUpdated";
  _source?: Maybe<Scalars["String"]["output"]>;
  destroyed?: Maybe<Scalars["Boolean"]["output"]>;
  id?: Maybe<Scalars["String"]["output"]>;
  savedSearchId?: Maybe<Scalars["String"]["output"]>;
  userContext?: Maybe<reverb_event_UserContext>;
};

export type reverb_event_GenericMailer = {
  __typename?: "reverb_event_GenericMailer";
  _source?: Maybe<Scalars["String"]["output"]>;
  /** / MailerAction */
  action?: Maybe<Scalars["String"]["output"]>;
  /** / DEPRECATED */
  emailType?: Maybe<Scalars["String"]["output"]>;
  /** / sale_id */
  sourceId?: Maybe<Scalars["String"]["output"]>;
  /** / Sale */
  sourceType?: Maybe<Scalars["String"]["output"]>;
  userContext?: Maybe<reverb_event_UserContext>;
  /** / new_user_sale_invitation */
  utmCampaign?: Maybe<Scalars["String"]["output"]>;
  /** / { subject_line: "v2" }.to_json */
  variations?: Maybe<Scalars["String"]["output"]>;
};

export enum reverb_event_GoogleClickOrigin {
  REVERB = "REVERB",
  SELLERSITES = "SELLERSITES",
}

export type reverb_event_GoogleShoppingClick = {
  __typename?: "reverb_event_GoogleShoppingClick";
  _source?: Maybe<Scalars["String"]["output"]>;
  comparisonShoppingPageId?: Maybe<Scalars["String"]["output"]>;
  /** / GoogleClickOrigin */
  origin?: Maybe<Scalars["String"]["output"]>;
  productId?: Maybe<Scalars["String"]["output"]>;
  /** / ISO8601 */
  timestamp?: Maybe<Scalars["String"]["output"]>;
};

export type reverb_event_JsLoad = {
  __typename?: "reverb_event_JsLoad";
  _source?: Maybe<Scalars["String"]["output"]>;
  app?: Maybe<Scalars["String"]["output"]>;
  fingerprint?: Maybe<reverb_event_BrowserFingerprint>;
  userContext?: Maybe<reverb_event_UserContext>;
};

export type reverb_event_Listing = {
  __typename?: "reverb_event_Listing";
  _source?: Maybe<Scalars["String"]["output"]>;
  bumped?: Maybe<Scalars["Boolean"]["output"]>;
  canonicalProductId?: Maybe<Scalars["String"]["output"]>;
  categoryUuids?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
  id?: Maybe<Scalars["String"]["output"]>;
  price?: Maybe<reverb_event_Price>;
  /** / ListingStatus */
  status?: Maybe<Scalars["String"]["output"]>;
};

export type reverb_event_ListingMatched = {
  __typename?: "reverb_event_ListingMatched";
  _source?: Maybe<Scalars["String"]["output"]>;
  canonicalProductId?: Maybe<Scalars["String"]["output"]>;
  listingId?: Maybe<Scalars["String"]["output"]>;
  userContext?: Maybe<reverb_event_UserContext>;
};

export enum reverb_event_ListingStatus {
  DRAFT = "DRAFT",
  DRAFTS = "DRAFTS",
  ENDED = "ENDED",
  LIVE = "LIVE",
  NOT_DRAFTS = "NOT_DRAFTS",
  ORDERED = "ORDERED",
  SOLD_OUT = "SOLD_OUT",
  SUSPENDED = "SUSPENDED",
  VACATION = "VACATION",
}

export type reverb_event_ListingUpdated = {
  __typename?: "reverb_event_ListingUpdated";
  _source?: Maybe<Scalars["String"]["output"]>;
  changes?: Maybe<Array<Maybe<reverb_event_AttributeChange>>>;
  listing?: Maybe<reverb_event_Listing>;
  userContext?: Maybe<reverb_event_UserContext>;
};

export type reverb_event_ListingView = {
  __typename?: "reverb_event_ListingView";
  _source?: Maybe<Scalars["String"]["output"]>;
  aid?: Maybe<Scalars["String"]["output"]>;
  listing?: Maybe<reverb_event_Listing>;
  referer?: Maybe<Scalars["String"]["output"]>;
  requestId?: Maybe<Scalars["String"]["output"]>;
  url?: Maybe<Scalars["String"]["output"]>;
  userContext?: Maybe<reverb_event_UserContext>;
  utmCampaign?: Maybe<Scalars["String"]["output"]>;
};

export type reverb_event_ListingViewRelatedContent = {
  __typename?: "reverb_event_ListingViewRelatedContent";
  _source?: Maybe<Scalars["String"]["output"]>;
  articles?: Maybe<Array<Maybe<reverb_event_Article>>>;
  listingViewListingId?: Maybe<Scalars["String"]["output"]>;
  listings?: Maybe<Array<Maybe<reverb_event_Listing>>>;
  referer?: Maybe<Scalars["String"]["output"]>;
  type?: Maybe<Scalars["String"]["output"]>;
  url?: Maybe<Scalars["String"]["output"]>;
  userContext?: Maybe<reverb_event_UserContext>;
};

/**
 *   Reverb Analytics Notes:
 * Description: Captures views of any listing and parses product_id from url
 */
export type reverb_event_ListingsView = {
  __typename?: "reverb_event_ListingsView";
  _source?: Maybe<Scalars["String"]["output"]>;
  aid?: Maybe<Scalars["String"]["output"]>;
  bumpAlgorithm?: Maybe<Scalars["String"]["output"]>;
  bumpedListings?: Maybe<Array<Maybe<reverb_event_Listing>>>;
  curatedLinkIds?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
  curatedSetIds?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
  fallbackListings?: Maybe<Array<Maybe<reverb_event_Listing>>>;
  fallbackStrategy?: Maybe<Scalars["String"]["output"]>;
  featuredListing?: Maybe<reverb_event_Listing>;
  listings?: Maybe<Array<Maybe<reverb_event_Listing>>>;
  query?: Maybe<Scalars["String"]["output"]>;
  referer?: Maybe<Scalars["String"]["output"]>;
  requestId?: Maybe<Scalars["String"]["output"]>;
  responseTime?: Maybe<Scalars["Float"]["output"]>;
  resultCount?: Maybe<Scalars["Int"]["output"]>;
  totalFallbackListings?: Maybe<Scalars["Int"]["output"]>;
  totalResults?: Maybe<Scalars["Int"]["output"]>;
  url?: Maybe<Scalars["String"]["output"]>;
  userContext?: Maybe<reverb_event_UserContext>;
  utmCampaign?: Maybe<Scalars["String"]["output"]>;
  version?: Maybe<Scalars["Int"]["output"]>;
  viewType?: Maybe<Scalars["String"]["output"]>;
};

export enum reverb_event_ListingsView_ViewType {
  BRAND = "BRAND",
  BROWSING = "BROWSING",
  CURATED_SEARCH = "CURATED_SEARCH",
  CURATED_SET = "CURATED_SET",
  HOMEPAGE = "HOMEPAGE",
  SEARCHING = "SEARCHING",
  SHOP = "SHOP",
}

export type reverb_event_MailLinkClick = {
  __typename?: "reverb_event_MailLinkClick";
  _source?: Maybe<Scalars["String"]["output"]>;
  /** / sale_id */
  sourceId?: Maybe<Scalars["String"]["output"]>;
  /** / Sale */
  sourceType?: Maybe<Scalars["String"]["output"]>;
  url?: Maybe<Scalars["String"]["output"]>;
  userContext?: Maybe<reverb_event_UserContext>;
  utmCampaign?: Maybe<Scalars["String"]["output"]>;
  /** / { subject_line: "v2" }.to_json */
  variations?: Maybe<Scalars["String"]["output"]>;
};

export type reverb_event_MailListSignUp = {
  __typename?: "reverb_event_MailListSignUp";
  _source?: Maybe<Scalars["String"]["output"]>;
  countryCode?: Maybe<Scalars["String"]["output"]>;
  email?: Maybe<Scalars["String"]["output"]>;
  listId?: Maybe<Scalars["String"]["output"]>;
  referer?: Maybe<Scalars["String"]["output"]>;
  source?: Maybe<Scalars["String"]["output"]>;
  sourceProductUuid?: Maybe<Scalars["String"]["output"]>;
  userContext?: Maybe<reverb_event_UserContext>;
  userId?: Maybe<Scalars["String"]["output"]>;
};

export enum reverb_event_MailerAction {
  OPENED = "OPENED",
  SENT = "SENT",
}

export type reverb_event_MarketingEmailView = {
  __typename?: "reverb_event_MarketingEmailView";
  _source?: Maybe<Scalars["String"]["output"]>;
  entityIds?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
  entityType?: Maybe<Scalars["String"]["output"]>;
  listings?: Maybe<Array<Maybe<reverb_event_Listing>>>;
  persona?: Maybe<Scalars["String"]["output"]>;
  upsellOrderId?: Maybe<Scalars["String"]["output"]>;
  userContext?: Maybe<reverb_event_UserContext>;
  utmCampaign?: Maybe<Scalars["String"]["output"]>;
};

export type reverb_event_Message = {
  __typename?: "reverb_event_Message";
  _source?: Maybe<Scalars["String"]["output"]>;
  conversationId?: Maybe<Scalars["String"]["output"]>;
  id?: Maybe<Scalars["String"]["output"]>;
};

export type reverb_event_MessageUpdated = {
  __typename?: "reverb_event_MessageUpdated";
  _source?: Maybe<Scalars["String"]["output"]>;
  changes?: Maybe<Array<Maybe<reverb_event_AttributeChange>>>;
  message?: Maybe<reverb_event_Message>;
  userContext?: Maybe<reverb_event_UserContext>;
};

export type reverb_event_MessagesMailer = {
  __typename?: "reverb_event_MessagesMailer";
  _source?: Maybe<Scalars["String"]["output"]>;
  /** / MailerAction */
  action?: Maybe<Scalars["String"]["output"]>;
  messageId?: Maybe<Scalars["String"]["output"]>;
  userContext?: Maybe<reverb_event_UserContext>;
};

/** / Deprecated in favor of common ComponentInteraction */
export type reverb_event_MobileInteraction = {
  __typename?: "reverb_event_MobileInteraction";
  _source?: Maybe<Scalars["String"]["output"]>;
  action?: Maybe<Scalars["String"]["output"]>;
  context?: Maybe<Scalars["String"]["output"]>;
  name?: Maybe<Scalars["String"]["output"]>;
  type?: Maybe<reverb_event_MobileInteraction_InteractionType>;
  userContext?: Maybe<reverb_event_UserContext>;
};

export enum reverb_event_MobileInteraction_InteractionType {
  DRAG = "DRAG",
  FORCE_TAP = "FORCE_TAP",
  TAP = "TAP",
}

export type reverb_event_NegotiationStarted = {
  __typename?: "reverb_event_NegotiationStarted";
  _source?: Maybe<Scalars["String"]["output"]>;
  buyerId?: Maybe<Scalars["String"]["output"]>;
  buyerInitiated?: Maybe<Scalars["Boolean"]["output"]>;
  id?: Maybe<Scalars["String"]["output"]>;
  listingIds?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
  offerId?: Maybe<Scalars["String"]["output"]>;
  userContext?: Maybe<reverb_event_UserContext>;
};

export type reverb_event_NegotiationUpdated = {
  __typename?: "reverb_event_NegotiationUpdated";
  _source?: Maybe<Scalars["String"]["output"]>;
  id?: Maybe<Scalars["String"]["output"]>;
  lastOfferId?: Maybe<Scalars["String"]["output"]>;
  listing?: Maybe<reverb_event_Listing>;
  /** / Status */
  status?: Maybe<Scalars["String"]["output"]>;
  userContext?: Maybe<reverb_event_UserContext>;
};

export enum reverb_event_NegotiationUpdated_Status {
  ACCEPTED = "ACCEPTED",
  EXPIRED = "EXPIRED",
  REJECTED = "REJECTED",
}

export type reverb_event_OrderPaid = {
  __typename?: "reverb_event_OrderPaid";
  _source?: Maybe<Scalars["String"]["output"]>;
  buyerId?: Maybe<Scalars["String"]["output"]>;
  id?: Maybe<Scalars["String"]["output"]>;
  listingId?: Maybe<Scalars["String"]["output"]>;
  userContext?: Maybe<reverb_event_UserContext>;
};

export type reverb_event_OrderUpdated = {
  __typename?: "reverb_event_OrderUpdated";
  _source?: Maybe<Scalars["String"]["output"]>;
  buyerId?: Maybe<Scalars["String"]["output"]>;
  createdAt?: Maybe<Scalars["String"]["output"]>;
  id?: Maybe<Scalars["String"]["output"]>;
  listing?: Maybe<reverb_event_Listing>;
  status?: Maybe<Scalars["String"]["output"]>;
  userContext?: Maybe<reverb_event_UserContext>;
};

export enum reverb_event_OrderUpdated_Status {
  CANCELLED = "CANCELLED",
  LAYAWAY_FORFEITED = "LAYAWAY_FORFEITED",
  PAID = "PAID",
  PARTIALLY_PAID = "PARTIALLY_PAID",
  PAYMENT_PENDING = "PAYMENT_PENDING",
  PICKED_UP = "PICKED_UP",
  PRESUMED_RECEIVED = "PRESUMED_RECEIVED",
  RECEIVED = "RECEIVED",
  REFUNDED = "REFUNDED",
  SHIPPED = "SHIPPED",
  UNPAID = "UNPAID",
}

export type reverb_event_PMBView = {
  __typename?: "reverb_event_PMBView";
  _source?: Maybe<Scalars["String"]["output"]>;
  listings?: Maybe<Array<Maybe<reverb_event_Listing>>>;
  persona?: Maybe<Scalars["String"]["output"]>;
  userContext?: Maybe<reverb_event_UserContext>;
  utmCampaign?: Maybe<Scalars["String"]["output"]>;
};

/**
 *   Reverb Analytics Notes:
 * Description: Captures generic catch-all pages viewed by a user on reverb
 * Warnings:
 * Includes many backend "views" like API calls and image requests
 * Any query of events.page_views must reference the partition key dt in the where clause
 */
export type reverb_event_PageView = {
  __typename?: "reverb_event_PageView";
  _source?: Maybe<Scalars["String"]["output"]>;
  action?: Maybe<Scalars["String"]["output"]>;
  aid?: Maybe<Scalars["String"]["output"]>;
  controller?: Maybe<Scalars["String"]["output"]>;
  customHeaders?: Maybe<Scalars["String"]["output"]>;
  dbRuntime?: Maybe<Scalars["Float"]["output"]>;
  devTeam?: Maybe<Scalars["String"]["output"]>;
  duration?: Maybe<Scalars["Float"]["output"]>;
  /** Client-side pageview ID */
  id?: Maybe<Scalars["String"]["output"]>;
  method?: Maybe<Scalars["String"]["output"]>;
  oauthAppId?: Maybe<Scalars["String"]["output"]>;
  /** expectin a JSON serialized hash */
  pageExperiments?: Maybe<Scalars["String"]["output"]>;
  params?: Maybe<Scalars["String"]["output"]>;
  /** params[id] for debugging and kibana health */
  paramsId?: Maybe<Scalars["String"]["output"]>;
  referer?: Maybe<Scalars["String"]["output"]>;
  remoteAddress?: Maybe<Scalars["String"]["output"]>;
  requestId?: Maybe<Scalars["String"]["output"]>;
  /** params[sku] for debugging and kibana health */
  sku?: Maybe<Scalars["String"]["output"]>;
  status?: Maybe<Scalars["String"]["output"]>;
  threadId?: Maybe<Scalars["String"]["output"]>;
  url?: Maybe<Scalars["String"]["output"]>;
  /** _region prefix of requested URL */
  urlRegion?: Maybe<Scalars["String"]["output"]>;
  userContext?: Maybe<reverb_event_UserContext>;
  utmCampaign?: Maybe<Scalars["String"]["output"]>;
  utmMedium?: Maybe<Scalars["String"]["output"]>;
  utmSource?: Maybe<Scalars["String"]["output"]>;
  viewRuntime?: Maybe<Scalars["Float"]["output"]>;
};

export type reverb_event_PaymentGroup = {
  __typename?: "reverb_event_PaymentGroup";
  _source?: Maybe<Scalars["String"]["output"]>;
  cartItemBundles?: Maybe<Array<Maybe<reverb_event_CartItemBundle>>>;
  cartItemBundlesCount?: Maybe<Scalars["Int"]["output"]>;
};

export type reverb_event_Price = {
  __typename?: "reverb_event_Price";
  _source?: Maybe<Scalars["String"]["output"]>;
  amount?: Maybe<Scalars["Int"]["output"]>;
  currency?: Maybe<Scalars["String"]["output"]>;
};

export type reverb_event_RemovedFromCart = {
  __typename?: "reverb_event_RemovedFromCart";
  _source?: Maybe<Scalars["String"]["output"]>;
  cartItemUuid?: Maybe<Scalars["String"]["output"]>;
  listing?: Maybe<reverb_event_Listing>;
  userContext?: Maybe<reverb_event_UserContext>;
};

export type reverb_event_SaleView = {
  __typename?: "reverb_event_SaleView";
  _source?: Maybe<Scalars["String"]["output"]>;
  listings?: Maybe<Array<Maybe<reverb_event_Listing>>>;
  requestId?: Maybe<Scalars["String"]["output"]>;
  saleId?: Maybe<Scalars["String"]["output"]>;
  userContext?: Maybe<reverb_event_UserContext>;
};

export type reverb_event_ScreenView = {
  __typename?: "reverb_event_ScreenView";
  _source?: Maybe<Scalars["String"]["output"]>;
  context?: Maybe<Scalars["String"]["output"]>;
  referer?: Maybe<Scalars["String"]["output"]>;
  screenName?: Maybe<Scalars["String"]["output"]>;
  userContext?: Maybe<reverb_event_UserContext>;
};

export type reverb_event_SessionStart = {
  __typename?: "reverb_event_SessionStart";
  _source?: Maybe<Scalars["String"]["output"]>;
  aid?: Maybe<Scalars["String"]["output"]>;
  gsPartner?: Maybe<Scalars["String"]["output"]>;
  /** session id */
  id?: Maybe<Scalars["String"]["output"]>;
  referer?: Maybe<Scalars["String"]["output"]>;
  requestId?: Maybe<Scalars["String"]["output"]>;
  url?: Maybe<Scalars["String"]["output"]>;
  userContext?: Maybe<reverb_event_UserContext>;
  utmCampaign?: Maybe<Scalars["String"]["output"]>;
  utmMedium?: Maybe<Scalars["String"]["output"]>;
  utmSource?: Maybe<Scalars["String"]["output"]>;
};

export type reverb_event_ShippingLabelUpdated = {
  __typename?: "reverb_event_ShippingLabelUpdated";
  _source?: Maybe<Scalars["String"]["output"]>;
  id?: Maybe<Scalars["String"]["output"]>;
  status?: Maybe<Scalars["String"]["output"]>;
  userContext?: Maybe<reverb_event_UserContext>;
};

export type reverb_event_ShopEmptied = {
  __typename?: "reverb_event_ShopEmptied";
  _source?: Maybe<Scalars["String"]["output"]>;
  shopId?: Maybe<Scalars["String"]["output"]>;
};

/** / Polymorphic message wrapper */
export type reverb_event_TrackingPixelEvent = {
  __typename?: "reverb_event_TrackingPixelEvent";
  _source?: Maybe<Scalars["String"]["output"]>;
  data?: Maybe<Scalars["String"]["output"]>;
  /** / ex. MessagesMailer */
  name?: Maybe<Scalars["String"]["output"]>;
};

export type reverb_event_UserContext = {
  __typename?: "reverb_event_UserContext";
  _source?: Maybe<Scalars["String"]["output"]>;
  actingUserId?: Maybe<Scalars["String"]["output"]>;
  adminId?: Maybe<Scalars["String"]["output"]>;
  advertisingId?: Maybe<Scalars["String"]["output"]>;
  bot?: Maybe<Scalars["Boolean"]["output"]>;
  cookieId?: Maybe<Scalars["String"]["output"]>;
  countryCode?: Maybe<Scalars["String"]["output"]>;
  currency?: Maybe<Scalars["String"]["output"]>;
  device?: Maybe<reverb_event_Device>;
  distinctId?: Maybe<Scalars["String"]["output"]>;
  experiments?: Maybe<Array<Maybe<reverb_event_Experiment>>>;
  hasLoggedIn?: Maybe<Scalars["Boolean"]["output"]>;
  id?: Maybe<Scalars["String"]["output"]>;
  lastGoogleClick?: Maybe<reverb_event_GoogleShoppingClick>;
  lat?: Maybe<Scalars["Float"]["output"]>;
  locale?: Maybe<Scalars["String"]["output"]>;
  long?: Maybe<Scalars["Float"]["output"]>;
  requestId?: Maybe<Scalars["String"]["output"]>;
  reverbApp?: Maybe<Scalars["String"]["output"]>;
  sellerSitesId?: Maybe<Scalars["String"]["output"]>;
  sessionId?: Maybe<Scalars["String"]["output"]>;
  shippingRegion?: Maybe<Scalars["String"]["output"]>;
  /** / ISO8601 */
  timestamp?: Maybe<Scalars["String"]["output"]>;
};

export type reverb_event_UserSignedIn = {
  __typename?: "reverb_event_UserSignedIn";
  _source?: Maybe<Scalars["String"]["output"]>;
  referer?: Maybe<Scalars["String"]["output"]>;
  signup?: Maybe<Scalars["Boolean"]["output"]>;
  userContext?: Maybe<reverb_event_UserContext>;
  userId?: Maybe<Scalars["String"]["output"]>;
};

export type reverb_event_UserSignedUp = {
  __typename?: "reverb_event_UserSignedUp";
  _source?: Maybe<Scalars["String"]["output"]>;
  aid?: Maybe<Scalars["String"]["output"]>;
  countryCode?: Maybe<Scalars["String"]["output"]>;
  /**
   *   Reverb Analytics Notes:
   * Description: Captures user registration event
   * Known Issues: Currently drops/does not capture user_context_id for majority of events on desktop and mobile web
   */
  newsletterOptIn?: Maybe<Scalars["Boolean"]["output"]>;
  referer?: Maybe<Scalars["String"]["output"]>;
  registrationSource?: Maybe<Scalars["String"]["output"]>;
  sourceProductUuid?: Maybe<Scalars["String"]["output"]>;
  url?: Maybe<Scalars["String"]["output"]>;
  userContext?: Maybe<reverb_event_UserContext>;
  userId?: Maybe<Scalars["String"]["output"]>;
  utmCampaign?: Maybe<Scalars["String"]["output"]>;
};

export type reverb_event_UserUpdated = {
  __typename?: "reverb_event_UserUpdated";
  _source?: Maybe<Scalars["String"]["output"]>;
  id?: Maybe<Scalars["String"]["output"]>;
  newsletterOptIn?: Maybe<Scalars["Boolean"]["output"]>;
  /** / UserStatus */
  status?: Maybe<Scalars["String"]["output"]>;
  userContext?: Maybe<reverb_event_UserContext>;
};

export enum reverb_event_UserUpdated_UserStatus {
  IN_GOOD_STANDING = "IN_GOOD_STANDING",
  LOCKED_VACATION_MODE = "LOCKED_VACATION_MODE",
  SUSPENDED = "SUSPENDED",
  SUSPENDED_OUTSTANDING_INVOICE = "SUSPENDED_OUTSTANDING_INVOICE",
  UNDER_REVIEW = "UNDER_REVIEW",
}

export type reverb_event_WatchAdded = {
  __typename?: "reverb_event_WatchAdded";
  _source?: Maybe<Scalars["String"]["output"]>;
  listingId?: Maybe<Scalars["String"]["output"]>;
  referer?: Maybe<Scalars["String"]["output"]>;
  userContext?: Maybe<reverb_event_UserContext>;
  watchId?: Maybe<Scalars["String"]["output"]>;
};

export type reverb_event_WatcherListingSoldMailer = {
  __typename?: "reverb_event_WatcherListingSoldMailer";
  _source?: Maybe<Scalars["String"]["output"]>;
  /** / MailerAction */
  action?: Maybe<Scalars["String"]["output"]>;
  comparisonShoppingPageId?: Maybe<Scalars["String"]["output"]>;
  orderId?: Maybe<Scalars["String"]["output"]>;
  productId?: Maybe<Scalars["String"]["output"]>;
  similarProductIds?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
  userContext?: Maybe<reverb_event_UserContext>;
};

export type reverb_feed_AlertableUserWithTz = {
  __typename?: "reverb_feed_AlertableUserWithTz";
  _source?: Maybe<Scalars["String"]["output"]>;
  tzRegion?: Maybe<Scalars["Int"]["output"]>;
  userId?: Maybe<Scalars["String"]["output"]>;
};

export type reverb_feed_AlertableUsersInTzStreamRequest = {
  __typename?: "reverb_feed_AlertableUsersInTzStreamRequest";
  _source?: Maybe<Scalars["String"]["output"]>;
  chunkSize?: Maybe<Scalars["Int"]["output"]>;
  fromId?: Maybe<Scalars["Int"]["output"]>;
  tzRegion?: Maybe<Scalars["Int"]["output"]>;
};

export type reverb_feed_AlertableUsersRequest = {
  __typename?: "reverb_feed_AlertableUsersRequest";
  _?: Maybe<Scalars["Boolean"]["output"]>;
  _source?: Maybe<Scalars["String"]["output"]>;
};

export type reverb_feed_AlertableUsersResponse = {
  __typename?: "reverb_feed_AlertableUsersResponse";
  _source?: Maybe<Scalars["String"]["output"]>;
  userIds?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
};

export type reverb_feed_AlertableUsersStreamRequest = {
  __typename?: "reverb_feed_AlertableUsersStreamRequest";
  _source?: Maybe<Scalars["String"]["output"]>;
  chunkSize?: Maybe<Scalars["Int"]["output"]>;
};

export type reverb_feed_AlertableUsersWithTzResponse = {
  __typename?: "reverb_feed_AlertableUsersWithTzResponse";
  _source?: Maybe<Scalars["String"]["output"]>;
  users?: Maybe<Array<Maybe<reverb_feed_AlertableUserWithTz>>>;
};

export type reverb_feed_AlertableUsersWithTzStreamRequest = {
  __typename?: "reverb_feed_AlertableUsersWithTzStreamRequest";
  _source?: Maybe<Scalars["String"]["output"]>;
  chunkSize?: Maybe<Scalars["Int"]["output"]>;
};

export type reverb_feed_BackfillSearchDuplicate = {
  __typename?: "reverb_feed_BackfillSearchDuplicate";
  _source?: Maybe<Scalars["String"]["output"]>;
  normalizedParams?: Maybe<Scalars["String"]["output"]>;
  searchIds?: Maybe<Array<Maybe<Scalars["Int"]["output"]>>>;
};

export type reverb_feed_BatchUpdateSavedSearchRecentListingCountRequest = {
  __typename?: "reverb_feed_BatchUpdateSavedSearchRecentListingCountRequest";
  _source?: Maybe<Scalars["String"]["output"]>;
  savedSearches?: Maybe<
    Array<
      Maybe<reverb_feed_BatchUpdateSavedSearchRecentListingCountRequest_SavedSearchInput>
    >
  >;
};

export type reverb_feed_BatchUpdateSavedSearchRecentListingCountRequest_SavedSearchInput =
  {
    __typename?: "reverb_feed_BatchUpdateSavedSearchRecentListingCountRequest_SavedSearchInput";
    _source?: Maybe<Scalars["String"]["output"]>;
    id?: Maybe<Scalars["String"]["output"]>;
    recentListingCount?: Maybe<Scalars["Int"]["output"]>;
  };

export type reverb_feed_BatchUpdateSavedSearchRecentListingCountResponse = {
  __typename?: "reverb_feed_BatchUpdateSavedSearchRecentListingCountResponse";
  _source?: Maybe<Scalars["String"]["output"]>;
  updatedCount?: Maybe<Scalars["Int"]["output"]>;
};

export type reverb_feed_ClearUserDigestRequest = {
  __typename?: "reverb_feed_ClearUserDigestRequest";
  _source?: Maybe<Scalars["String"]["output"]>;
  userId?: Maybe<Scalars["String"]["output"]>;
};

export type reverb_feed_ClearUserDigestResponse = {
  __typename?: "reverb_feed_ClearUserDigestResponse";
  _?: Maybe<Scalars["Boolean"]["output"]>;
  _source?: Maybe<Scalars["String"]["output"]>;
};

export type reverb_feed_CreateFollowResponse = {
  __typename?: "reverb_feed_CreateFollowResponse";
  _source?: Maybe<Scalars["String"]["output"]>;
  follow?: Maybe<reverb_feed_Follow>;
};

export type reverb_feed_CreateSavedSearchRequest = {
  __typename?: "reverb_feed_CreateSavedSearchRequest";
  _source?: Maybe<Scalars["String"]["output"]>;
  esQuery?: Maybe<Scalars["String"]["output"]>;
  localizedContents?: Maybe<Array<Maybe<reverb_feed_FollowLocalizedContents>>>;
  queryParams?: Maybe<Scalars["String"]["output"]>;
  searchableType?: Maybe<reverb_feed_SearchableType>;
  searchableUuid?: Maybe<Scalars["String"]["output"]>;
};

export type reverb_feed_CreateSavedSearchResponse = {
  __typename?: "reverb_feed_CreateSavedSearchResponse";
  _source?: Maybe<Scalars["String"]["output"]>;
  id?: Maybe<Scalars["Int"]["output"]>;
};

export type reverb_feed_DataBackfillTopic = {
  __typename?: "reverb_feed_DataBackfillTopic";
  _source?: Maybe<Scalars["String"]["output"]>;
  action?: Maybe<reverb_feed_DataBackfillTopic_Action>;
  followIds?: Maybe<Array<Maybe<Scalars["Int"]["output"]>>>;
  searchDuplicates?: Maybe<Array<Maybe<reverb_feed_BackfillSearchDuplicate>>>;
};

export enum reverb_feed_DataBackfillTopic_Action {
  BACKFILL_DUPLICATE_SAVED_SEARCHES_CLEANUP = "BACKFILL_DUPLICATE_SAVED_SEARCHES_CLEANUP",
  BACKFILL_EVERYWHERE_ITEM_REGION_CLEANUP = "BACKFILL_EVERYWHERE_ITEM_REGION_CLEANUP",
}

export type reverb_feed_DigestFollows = {
  __typename?: "reverb_feed_DigestFollows";
  _source?: Maybe<Scalars["String"]["output"]>;
  entries?: Maybe<Array<Maybe<reverb_feed_FeedEntry>>>;
  follow?: Maybe<reverb_feed_Follow>;
};

export type reverb_feed_DigestListings = {
  __typename?: "reverb_feed_DigestListings";
  _source?: Maybe<Scalars["String"]["output"]>;
  listingIds?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
};

export type reverb_feed_EntriesRequest = {
  __typename?: "reverb_feed_EntriesRequest";
  _source?: Maybe<Scalars["String"]["output"]>;
  limit?: Maybe<Scalars["Int"]["output"]>;
  locale?: Maybe<Scalars["String"]["output"]>;
  offset?: Maybe<Scalars["Int"]["output"]>;
  page?: Maybe<Scalars["Int"]["output"]>;
  perPage?: Maybe<Scalars["Int"]["output"]>;
  readFromDatabase?: Maybe<Scalars["Boolean"]["output"]>;
  userId?: Maybe<Scalars["String"]["output"]>;
};

/** Favorite represents a row in the follows table (a link between a user and a saved search), and is a newer version of the Follow message. */
export type reverb_feed_Favorite = {
  __typename?: "reverb_feed_Favorite";
  _source?: Maybe<Scalars["String"]["output"]>;
  emailEnabled?: Maybe<Scalars["Boolean"]["output"]>;
  favorited?: Maybe<Scalars["Boolean"]["output"]>;
  feedEnabled?: Maybe<Scalars["Boolean"]["output"]>;
  id?: Maybe<Scalars["String"]["output"]>;
  queryParams?: Maybe<Scalars["String"]["output"]>;
  queryText?: Maybe<Scalars["String"]["output"]>;
  savedSearchId?: Maybe<Scalars["String"]["output"]>;
  searchableId?: Maybe<Scalars["String"]["output"]>;
  searchableType?: Maybe<reverb_feed_SearchableType>;
  subtitle?: Maybe<Scalars["String"]["output"]>;
  title?: Maybe<Scalars["String"]["output"]>;
  userId?: Maybe<Scalars["String"]["output"]>;
};

export type reverb_feed_FeedEntry = {
  __typename?: "reverb_feed_FeedEntry";
  _source?: Maybe<Scalars["String"]["output"]>;
  /** The `deleted` field is for client state management, primarily Apollo Client in frontend. */
  deleted?: Maybe<Scalars["Boolean"]["output"]>;
  entry?: Maybe<FeedEntry>;
  /** The `id` field contains a Listing ID. Technically it is the ID of the `EntryType` entity (`entries.entry_id`), but all entries are listings. It does not contain `entries.id`, which is a unique per-user-per-listing value. */
  id?: Maybe<Scalars["Int"]["output"]>;
  /** Contains an array of _all_ localized content, 1 per supported locale. This method of handling localization is unusual, and makes client code more complex. The more common pattern is providing a single `FollowLocalizedContents` message (not an array) that is aware of the current user's locale. */
  localizedContents?: Maybe<Array<Maybe<reverb_feed_FollowLocalizedContents>>>;
  metadata?: Maybe<reverb_feed_FeedMetadata>;
  /**
   * Unused by clients, still referenced in feed code & tests.
   * @deprecated Field no longer supported
   */
  queryParams?: Maybe<Scalars["String"]["output"]>;
  /**
   * Unused by clients, still referenced in feed code & tests.
   * @deprecated Field no longer supported
   */
  searchableId?: Maybe<Scalars["String"]["output"]>;
  /**
   * Unused by clients, still referenced in feed code & tests.
   * @deprecated Field no longer supported
   */
  searchableType?: Maybe<reverb_feed_SearchableType>;
  /** Currently, all entries are listings. */
  type?: Maybe<reverb_feed_FeedEntry_EntryType>;
  /** Only relevant for `UserDigestsResponse`, which returns entries for a batch of users. */
  userId?: Maybe<Scalars["String"]["output"]>;
  /** The `uuid` field contains a Listing ID as a string, which is never a UUID. In the past when LP related feed entries were stored, this may have stored a UUID. */
  uuid?: Maybe<Scalars["String"]["output"]>;
};

export type reverb_feed_FeedEntryUpdated = {
  __typename?: "reverb_feed_FeedEntryUpdated";
  _source?: Maybe<Scalars["String"]["output"]>;
  action?: Maybe<reverb_feed_FeedEntryUpdated_Action>;
  /** timestamp of first entry in feed pipeline */
  entityUpdatedAt?: Maybe<Scalars["Timestamp"]["output"]>;
  entries?: Maybe<Array<Maybe<reverb_feed_FeedEntry>>>;
  sourceId?: Maybe<Scalars["Int"]["output"]>;
  sourceIds?: Maybe<Array<Maybe<Scalars["Int"]["output"]>>>;
  sourceType?: Maybe<reverb_feed_FeedSourceType>;
  userId?: Maybe<Scalars["Int"]["output"]>;
  userIds?: Maybe<Array<Maybe<Scalars["Int"]["output"]>>>;
};

export enum reverb_feed_FeedEntryUpdated_Action {
  ADD = "ADD",
  ADD_BY_SOURCE = "ADD_BY_SOURCE",
  REMOVE = "REMOVE",
  REMOVE_ALL = "REMOVE_ALL",
  REMOVE_BY_SOURCE = "REMOVE_BY_SOURCE",
  REMOVE_DIGEST = "REMOVE_DIGEST",
}

export enum reverb_feed_FeedEntry_EntryType {
  /**
   * Deprecated, but still referenced in reverb core and feed code.
   * @deprecated Field no longer supported
   */
  ALBUM = "ALBUM",
  /**
   * Deprecated, but there are still some article entries in the feed DB, and references in reverb core and feed.
   * @deprecated Field no longer supported
   */
  ARTICLE = "ARTICLE",
  /**
   * Deprecated, but still referenced in reverb core.
   * @deprecated Field no longer supported
   */
  ARTIST = "ARTIST",
  /**
   * Deprecated, but still referenced in reverb core.
   * @deprecated Field no longer supported
   */
  CSP = "CSP",
  /**
   * Deprecated, but still referenced in reverb core.
   * @deprecated Field no longer supported
   */
  FILTERED_QUERY = "FILTERED_QUERY",
  /**
   * Deprecated, but still referenced in reverb core.
   * @deprecated Field no longer supported
   */
  LABEL = "LABEL",
  /** The only entry type that is actively in use. */
  LISTING = "LISTING",
  /**
   * Deprecated, but still referenced in reverb core.
   * @deprecated Field no longer supported
   */
  PAGE = "PAGE",
  /**
   * Deprecated, but still referenced in reverb core code.
   * @deprecated Field no longer supported
   */
  PROFILE = "PROFILE",
  /**
   * Deprecated, but still referenced in reverb core.
   * @deprecated Field no longer supported
   */
  RELEASE = "RELEASE",
  /**
   * Deprecated, but still referenced in reverb core and feed code.
   * @deprecated Field no longer supported
   */
  REMOVE = "REMOVE",
  /**
   * Deprecated, but still referenced in reverb core.
   * @deprecated Field no longer supported
   */
  SHOP = "SHOP",
}

/** Metadata for a feed entry. */
export type reverb_feed_FeedMetadata = {
  __typename?: "reverb_feed_FeedMetadata";
  _source?: Maybe<Scalars["String"]["output"]>;
  /**
   * Unused by clients, still referenced in feed code & tests.
   * @deprecated Field no longer supported
   */
  fromFeedService?: Maybe<Scalars["Boolean"]["output"]>;
  matchedAt?: Maybe<Scalars["Timestamp"]["output"]>;
  matchedAtIso?: Maybe<Scalars["String"]["output"]>;
  /** The id of the saved search this entry was matched from. */
  sourceId?: Maybe<Scalars["Int"]["output"]>;
  /** All `source_type` values are `SAVED_SEARCH`. */
  sourceType?: Maybe<reverb_feed_FeedSourceType>;
};

export type reverb_feed_FeedResponse = {
  __typename?: "reverb_feed_FeedResponse";
  _source?: Maybe<Scalars["String"]["output"]>;
  entries?: Maybe<Array<Maybe<reverb_feed_FeedEntry>>>;
  filters?: Maybe<Array<Maybe<reverb_search_Filter>>>;
  limit?: Maybe<Scalars["Int"]["output"]>;
  offset?: Maybe<Scalars["Int"]["output"]>;
  page?: Maybe<Scalars["Int"]["output"]>;
  perPage?: Maybe<Scalars["Int"]["output"]>;
  total?: Maybe<Scalars["Int"]["output"]>;
};

export type reverb_feed_FeedService = {
  __typename?: "reverb_feed_FeedService";
  BatchUpdateSavedSearchRecentListingCount?: Maybe<reverb_feed_BatchUpdateSavedSearchRecentListingCountResponse>;
  ClearUserDigest?: Maybe<reverb_feed_ClearUserDigestResponse>;
  CreateSavedSearch?: Maybe<reverb_feed_CreateSavedSearchResponse>;
  FindFavorite?: Maybe<reverb_feed_FindFavoriteResponse>;
  FindFollow?: Maybe<reverb_feed_GetFollowResponse>;
  FindFollowById?: Maybe<reverb_feed_GetFollowResponse>;
  FindFollowBySearchable?: Maybe<reverb_feed_GetFollowResponse>;
  FindFollowsBySearchable?: Maybe<reverb_feed_FindFollowsBySearchableResponse>;
  GetAlertableUsers?: Maybe<reverb_feed_AlertableUsersResponse>;
  GetAlertableUsersInTzStream?: Maybe<reverb_feed_AlertableUsersResponse>;
  GetAlertableUsersStream?: Maybe<reverb_feed_AlertableUsersResponse>;
  GetAlertableUsersWithTzStream?: Maybe<reverb_feed_AlertableUsersWithTzResponse>;
  GetFavoriteSavedSearches?: Maybe<reverb_feed_GetFavoriteSavedSearchesResponse>;
  GetFavoriteShops?: Maybe<reverb_feed_GetFavoriteShopsResponse>;
  GetFeedEntries?: Maybe<reverb_feed_FeedResponse>;
  GetFollowedSavedSearches?: Maybe<reverb_feed_GetFollowedSavedSearchesResponse>;
  GetFollows?: Maybe<reverb_feed_GetFollowsResponse>;
  GetSavedSearch?: Maybe<reverb_feed_GetSavedSearchResponse>;
  GetSavedSearchesStream?: Maybe<reverb_feed_SavedSearchesStreamResponse>;
  GetUserDigests?: Maybe<reverb_feed_UserDigestsResponse>;
  PreviewUserDigests?: Maybe<reverb_feed_UserDigestsResponse>;
  PruneFeed?: Maybe<reverb_feed_PruneFeedResponse>;
  RemoveEntry?: Maybe<reverb_feed_RemoveEntryResponse>;
  RemoveFavorite?: Maybe<reverb_feed_RemoveFavoriteResponse>;
  RemoveFollow?: Maybe<reverb_feed_GetFollowsResponse>;
  RemoveFollowsBySearchable?: Maybe<reverb_feed_RemoveFollowsBySearchableResponse>;
  ReplaceFollowsSavedSearchId?: Maybe<reverb_feed_ReplaceFollowsSavedSearchIdResponse>;
  SetAlertableUsersTz?: Maybe<reverb_feed_SetAlertableUsersTzResponse>;
  SetFollowDigest?: Maybe<reverb_feed_SetFollowDigestResponse>;
  SetFollowDigestsByUser?: Maybe<reverb_feed_SetFollowDigestsByUserResponse>;
  UndoRemoveFavorite?: Maybe<reverb_feed_UndoRemoveFavoriteResponse>;
  UpdateSavedSearch?: Maybe<reverb_feed_UpdateSavedSearchResponse>;
  UpsertFavorite?: Maybe<reverb_feed_UpsertFavoriteResponse>;
  UpsertFollow?: Maybe<reverb_feed_UpsertFollowResponse>;
  _source?: Maybe<Scalars["String"]["output"]>;
};

export type reverb_feed_FeedServiceBatchUpdateSavedSearchRecentListingCountArgs =
  {
    input?: InputMaybe<Input_reverb_feed_BatchUpdateSavedSearchRecentListingCountRequest>;
  };

export type reverb_feed_FeedServiceClearUserDigestArgs = {
  input?: InputMaybe<Input_reverb_feed_ClearUserDigestRequest>;
};

export type reverb_feed_FeedServiceCreateSavedSearchArgs = {
  input?: InputMaybe<Input_reverb_feed_CreateSavedSearchRequest>;
};

export type reverb_feed_FeedServiceFindFavoriteArgs = {
  input?: InputMaybe<Input_reverb_feed_FindFavoriteRequest>;
};

export type reverb_feed_FeedServiceFindFollowArgs = {
  input?: InputMaybe<Input_reverb_feed_FindFollowRequest>;
};

export type reverb_feed_FeedServiceFindFollowByIdArgs = {
  input?: InputMaybe<Input_reverb_feed_FindFollowByIdRequest>;
};

export type reverb_feed_FeedServiceFindFollowBySearchableArgs = {
  input?: InputMaybe<Input_reverb_feed_FindFollowBySearchableRequest>;
};

export type reverb_feed_FeedServiceFindFollowsBySearchableArgs = {
  input?: InputMaybe<Input_reverb_feed_FindFollowsBySearchableRequest>;
};

export type reverb_feed_FeedServiceGetAlertableUsersArgs = {
  input?: InputMaybe<Input_reverb_feed_AlertableUsersRequest>;
};

export type reverb_feed_FeedServiceGetAlertableUsersInTzStreamArgs = {
  input?: InputMaybe<Input_reverb_feed_AlertableUsersInTzStreamRequest>;
};

export type reverb_feed_FeedServiceGetAlertableUsersStreamArgs = {
  input?: InputMaybe<Input_reverb_feed_AlertableUsersStreamRequest>;
};

export type reverb_feed_FeedServiceGetAlertableUsersWithTzStreamArgs = {
  input?: InputMaybe<Input_reverb_feed_AlertableUsersWithTzStreamRequest>;
};

export type reverb_feed_FeedServiceGetFavoriteSavedSearchesArgs = {
  input?: InputMaybe<Input_reverb_feed_GetFavoriteSavedSearchesRequest>;
};

export type reverb_feed_FeedServiceGetFavoriteShopsArgs = {
  input?: InputMaybe<Input_reverb_feed_GetFavoriteShopsRequest>;
};

export type reverb_feed_FeedServiceGetFeedEntriesArgs = {
  input?: InputMaybe<Input_reverb_feed_EntriesRequest>;
};

export type reverb_feed_FeedServiceGetFollowedSavedSearchesArgs = {
  input?: InputMaybe<Input_reverb_feed_GetFollowedSavedSearchesRequest>;
};

export type reverb_feed_FeedServiceGetFollowsArgs = {
  input?: InputMaybe<Input_reverb_feed_FollowsRequest>;
};

export type reverb_feed_FeedServiceGetSavedSearchArgs = {
  input?: InputMaybe<Input_reverb_feed_GetSavedSearchRequest>;
};

export type reverb_feed_FeedServiceGetSavedSearchesStreamArgs = {
  input?: InputMaybe<Input_reverb_feed_SavedSearchesStreamRequest>;
};

export type reverb_feed_FeedServiceGetUserDigestsArgs = {
  input?: InputMaybe<Input_reverb_feed_UserDigestsRequest>;
};

export type reverb_feed_FeedServicePreviewUserDigestsArgs = {
  input?: InputMaybe<Input_reverb_feed_PreviewUserDigestsRequest>;
};

export type reverb_feed_FeedServicePruneFeedArgs = {
  input?: InputMaybe<Input_reverb_feed_PruneFeedRequest>;
};

export type reverb_feed_FeedServiceRemoveEntryArgs = {
  input?: InputMaybe<Input_reverb_feed_RemoveEntryRequest>;
};

export type reverb_feed_FeedServiceRemoveFavoriteArgs = {
  input?: InputMaybe<Input_reverb_feed_RemoveFavoriteRequest>;
};

export type reverb_feed_FeedServiceRemoveFollowArgs = {
  input?: InputMaybe<Input_reverb_feed_RemoveFollowRequest>;
};

export type reverb_feed_FeedServiceRemoveFollowsBySearchableArgs = {
  input?: InputMaybe<Input_reverb_feed_RemoveFollowsBySearchableRequest>;
};

export type reverb_feed_FeedServiceReplaceFollowsSavedSearchIdArgs = {
  input?: InputMaybe<Input_reverb_feed_ReplaceFollowsSavedSearchIdRequest>;
};

export type reverb_feed_FeedServiceSetAlertableUsersTzArgs = {
  input?: InputMaybe<Input_reverb_feed_SetAlertableUsersTzRequest>;
};

export type reverb_feed_FeedServiceSetFollowDigestArgs = {
  input?: InputMaybe<Input_reverb_feed_SetFollowDigestRequest>;
};

export type reverb_feed_FeedServiceSetFollowDigestsByUserArgs = {
  input?: InputMaybe<Input_reverb_feed_SetFollowDigestsByUserRequest>;
};

export type reverb_feed_FeedServiceUndoRemoveFavoriteArgs = {
  input?: InputMaybe<Input_reverb_feed_UndoRemoveFavoriteRequest>;
};

export type reverb_feed_FeedServiceUpdateSavedSearchArgs = {
  input?: InputMaybe<Input_reverb_feed_UpdateSavedSearchRequest>;
};

export type reverb_feed_FeedServiceUpsertFavoriteArgs = {
  input?: InputMaybe<Input_reverb_feed_UpsertFavoriteRequest>;
};

export type reverb_feed_FeedServiceUpsertFollowArgs = {
  input?: InputMaybe<Input_reverb_feed_FollowUpdated>;
};

export enum reverb_feed_FeedSourceType {
  NONE = "NONE",
  SAVED_SEARCH = "SAVED_SEARCH",
  WATCH = "WATCH",
}

export type reverb_feed_FindFavoriteRequest = {
  __typename?: "reverb_feed_FindFavoriteRequest";
  _source?: Maybe<Scalars["String"]["output"]>;
  queryParams?: Maybe<Scalars["String"]["output"]>;
  userId?: Maybe<Scalars["String"]["output"]>;
};

export type reverb_feed_FindFavoriteResponse = {
  __typename?: "reverb_feed_FindFavoriteResponse";
  _source?: Maybe<Scalars["String"]["output"]>;
  favorite?: Maybe<reverb_feed_Favorite>;
};

export type reverb_feed_FindFollowByIdRequest = {
  __typename?: "reverb_feed_FindFollowByIdRequest";
  _source?: Maybe<Scalars["String"]["output"]>;
  id?: Maybe<Scalars["String"]["output"]>;
  userId?: Maybe<Scalars["String"]["output"]>;
};

export type reverb_feed_FindFollowBySearchableRequest = {
  __typename?: "reverb_feed_FindFollowBySearchableRequest";
  _source?: Maybe<Scalars["String"]["output"]>;
  searchableId?: Maybe<Scalars["String"]["output"]>;
  searchableType?: Maybe<reverb_feed_SearchableType>;
  userId?: Maybe<Scalars["String"]["output"]>;
};

export type reverb_feed_FindFollowRequest = {
  __typename?: "reverb_feed_FindFollowRequest";
  _source?: Maybe<Scalars["String"]["output"]>;
  queryParams?: Maybe<Scalars["String"]["output"]>;
  userId?: Maybe<Scalars["String"]["output"]>;
};

export type reverb_feed_FindFollowsBySearchableRequest = {
  __typename?: "reverb_feed_FindFollowsBySearchableRequest";
  _source?: Maybe<Scalars["String"]["output"]>;
  limit?: Maybe<Scalars["Int"]["output"]>;
  offset?: Maybe<Scalars["Int"]["output"]>;
  searchableId?: Maybe<Scalars["String"]["output"]>;
  searchableType?: Maybe<reverb_feed_SearchableType>;
};

export type reverb_feed_FindFollowsBySearchableResponse = {
  __typename?: "reverb_feed_FindFollowsBySearchableResponse";
  _source?: Maybe<Scalars["String"]["output"]>;
  follows?: Maybe<Array<Maybe<reverb_feed_Follow>>>;
  limit?: Maybe<Scalars["Int"]["output"]>;
  offset?: Maybe<Scalars["Int"]["output"]>;
  total?: Maybe<Scalars["Int"]["output"]>;
};

export type reverb_feed_Follow = {
  __typename?: "reverb_feed_Follow";
  _source?: Maybe<Scalars["String"]["output"]>;
  createdAt?: Maybe<Scalars["Timestamp"]["output"]>;
  digestEnabled?: Maybe<Scalars["Boolean"]["output"]>;
  id?: Maybe<Scalars["String"]["output"]>;
  localizedContents?: Maybe<Array<Maybe<reverb_feed_FollowLocalizedContents>>>;
  queryParams?: Maybe<Scalars["String"]["output"]>;
  savedSearchId?: Maybe<Scalars["String"]["output"]>;
  searchableId?: Maybe<Scalars["String"]["output"]>;
  searchableType?: Maybe<reverb_feed_SearchableType>;
  userId?: Maybe<Scalars["String"]["output"]>;
};

/** Contains localized content for a saved search. */
export type reverb_feed_FollowLocalizedContents = {
  __typename?: "reverb_feed_FollowLocalizedContents";
  _source?: Maybe<Scalars["String"]["output"]>;
  description?: Maybe<Scalars["String"]["output"]>;
  locale?: Maybe<Scalars["String"]["output"]>;
  shortDescription?: Maybe<Scalars["String"]["output"]>;
  subtitle?: Maybe<Scalars["String"]["output"]>;
  title?: Maybe<Scalars["String"]["output"]>;
};

export type reverb_feed_FollowUpdated = {
  __typename?: "reverb_feed_FollowUpdated";
  _source?: Maybe<Scalars["String"]["output"]>;
  digestEnabled?: Maybe<Scalars["Boolean"]["output"]>;
  esQuery?: Maybe<Scalars["String"]["output"]>;
  id?: Maybe<Scalars["String"]["output"]>;
  localizedContents?: Maybe<Array<Maybe<reverb_feed_FollowLocalizedContents>>>;
  queryParams?: Maybe<Scalars["String"]["output"]>;
  savedSearchId?: Maybe<Scalars["Int"]["output"]>;
  searchableId?: Maybe<Scalars["Int"]["output"]>;
  searchableType?: Maybe<reverb_feed_SearchableType>;
  searchableUuid?: Maybe<Scalars["String"]["output"]>;
  userId?: Maybe<Scalars["Int"]["output"]>;
};

export type reverb_feed_FollowsRequest = {
  __typename?: "reverb_feed_FollowsRequest";
  _source?: Maybe<Scalars["String"]["output"]>;
  locale?: Maybe<Scalars["String"]["output"]>;
  offset?: Maybe<Scalars["Int"]["output"]>;
  page?: Maybe<Scalars["Int"]["output"]>;
  perPage?: Maybe<Scalars["Int"]["output"]>;
  userId?: Maybe<Scalars["String"]["output"]>;
};

export type reverb_feed_FollowsTopic = {
  __typename?: "reverb_feed_FollowsTopic";
  _source?: Maybe<Scalars["String"]["output"]>;
  action?: Maybe<reverb_feed_FollowsTopic_Action>;
  followId?: Maybe<Scalars["Int"]["output"]>;
  followIds?: Maybe<Array<Maybe<Scalars["Int"]["output"]>>>;
  searchableId?: Maybe<Scalars["Int"]["output"]>;
  searchableType?: Maybe<reverb_feed_SearchableType>;
  userId?: Maybe<Scalars["Int"]["output"]>;
};

export enum reverb_feed_FollowsTopic_Action {
  BACKFILL_FAVORITE_SHOPS = "BACKFILL_FAVORITE_SHOPS",
  BACKFILL_FEED_ENABLED = "BACKFILL_FEED_ENABLED",
  REMOVE = "REMOVE",
  REMOVE_BY_SEARCHABLE = "REMOVE_BY_SEARCHABLE",
}

export type reverb_feed_GetFavoriteSavedSearchesRequest = {
  __typename?: "reverb_feed_GetFavoriteSavedSearchesRequest";
  _source?: Maybe<Scalars["String"]["output"]>;
  excludeOutlet?: Maybe<Scalars["Boolean"]["output"]>;
  limit?: Maybe<Scalars["Int"]["output"]>;
  locale?: Maybe<Scalars["String"]["output"]>;
  offset?: Maybe<Scalars["Int"]["output"]>;
  userId?: Maybe<Scalars["String"]["output"]>;
};

export type reverb_feed_GetFavoriteSavedSearchesResponse = {
  __typename?: "reverb_feed_GetFavoriteSavedSearchesResponse";
  _source?: Maybe<Scalars["String"]["output"]>;
  excludeOutlet?: Maybe<Scalars["Boolean"]["output"]>;
  favorites?: Maybe<Array<Maybe<reverb_feed_Favorite>>>;
  limit?: Maybe<Scalars["Int"]["output"]>;
  offset?: Maybe<Scalars["Int"]["output"]>;
  total?: Maybe<Scalars["Int"]["output"]>;
};

export type reverb_feed_GetFavoriteShopsRequest = {
  __typename?: "reverb_feed_GetFavoriteShopsRequest";
  _source?: Maybe<Scalars["String"]["output"]>;
  limit?: Maybe<Scalars["Int"]["output"]>;
  locale?: Maybe<Scalars["String"]["output"]>;
  offset?: Maybe<Scalars["Int"]["output"]>;
  userId?: Maybe<Scalars["String"]["output"]>;
};

export type reverb_feed_GetFavoriteShopsResponse = {
  __typename?: "reverb_feed_GetFavoriteShopsResponse";
  _source?: Maybe<Scalars["String"]["output"]>;
  favoriteShops?: Maybe<Array<Maybe<reverb_feed_Favorite>>>;
  limit?: Maybe<Scalars["Int"]["output"]>;
  offset?: Maybe<Scalars["Int"]["output"]>;
  total?: Maybe<Scalars["Int"]["output"]>;
};

export type reverb_feed_GetFollowResponse = {
  __typename?: "reverb_feed_GetFollowResponse";
  _source?: Maybe<Scalars["String"]["output"]>;
  follow?: Maybe<reverb_feed_Follow>;
  followCount?: Maybe<Scalars["Int"]["output"]>;
  following?: Maybe<Scalars["Boolean"]["output"]>;
};

export type reverb_feed_GetFollowedSavedSearchesRequest = {
  __typename?: "reverb_feed_GetFollowedSavedSearchesRequest";
  _source?: Maybe<Scalars["String"]["output"]>;
  startId?: Maybe<Scalars["Int"]["output"]>;
};

export type reverb_feed_GetFollowedSavedSearchesResponse = {
  __typename?: "reverb_feed_GetFollowedSavedSearchesResponse";
  _source?: Maybe<Scalars["String"]["output"]>;
  savedSearchIds?: Maybe<Array<Maybe<Scalars["Int"]["output"]>>>;
};

export type reverb_feed_GetFollowsResponse = {
  __typename?: "reverb_feed_GetFollowsResponse";
  _source?: Maybe<Scalars["String"]["output"]>;
  follows?: Maybe<Array<Maybe<reverb_feed_Follow>>>;
  page?: Maybe<Scalars["Int"]["output"]>;
  perPage?: Maybe<Scalars["Int"]["output"]>;
  total?: Maybe<Scalars["Int"]["output"]>;
};

export type reverb_feed_GetSavedSearchRequest = {
  __typename?: "reverb_feed_GetSavedSearchRequest";
  _source?: Maybe<Scalars["String"]["output"]>;
  id?: Maybe<Scalars["Int"]["output"]>;
  withMostRecentFollow?: Maybe<Scalars["Boolean"]["output"]>;
};

export type reverb_feed_GetSavedSearchResponse = {
  __typename?: "reverb_feed_GetSavedSearchResponse";
  _source?: Maybe<Scalars["String"]["output"]>;
  esQuery?: Maybe<Scalars["String"]["output"]>;
  localizedContents?: Maybe<Array<Maybe<reverb_feed_FollowLocalizedContents>>>;
  mostRecentFollowedUserId?: Maybe<Scalars["String"]["output"]>;
  queryParams?: Maybe<Scalars["String"]["output"]>;
  searchableId?: Maybe<Scalars["String"]["output"]>;
  searchableType?: Maybe<reverb_feed_SearchableType>;
};

export type reverb_feed_PreviewUserDigestsRequest = {
  __typename?: "reverb_feed_PreviewUserDigestsRequest";
  _source?: Maybe<Scalars["String"]["output"]>;
  matchedAfter?: Maybe<Scalars["Timestamp"]["output"]>;
  normalized?: Maybe<Scalars["Boolean"]["output"]>;
  users?: Maybe<Array<Maybe<reverb_feed_User>>>;
};

export type reverb_feed_PruneFeedRequest = {
  __typename?: "reverb_feed_PruneFeedRequest";
  _source?: Maybe<Scalars["String"]["output"]>;
  fromFeedService?: Maybe<Scalars["Boolean"]["output"]>;
  savedSearchId?: Maybe<Scalars["Int"]["output"]>;
  userId?: Maybe<Scalars["String"]["output"]>;
};

export type reverb_feed_PruneFeedResponse = {
  __typename?: "reverb_feed_PruneFeedResponse";
  _?: Maybe<Scalars["Boolean"]["output"]>;
  _source?: Maybe<Scalars["String"]["output"]>;
};

export type reverb_feed_RemoveEntryRequest = {
  __typename?: "reverb_feed_RemoveEntryRequest";
  _source?: Maybe<Scalars["String"]["output"]>;
  entryId?: Maybe<Scalars["String"]["output"]>;
  entryType?: Maybe<reverb_feed_FeedEntry_EntryType>;
  userId?: Maybe<Scalars["String"]["output"]>;
};

export type reverb_feed_RemoveEntryResponse = {
  __typename?: "reverb_feed_RemoveEntryResponse";
  _source?: Maybe<Scalars["String"]["output"]>;
  entry?: Maybe<reverb_feed_FeedEntry>;
};

export type reverb_feed_RemoveFavoriteRequest = {
  __typename?: "reverb_feed_RemoveFavoriteRequest";
  _source?: Maybe<Scalars["String"]["output"]>;
  id?: Maybe<Scalars["String"]["output"]>;
  userId?: Maybe<Scalars["String"]["output"]>;
};

export type reverb_feed_RemoveFavoriteResponse = {
  __typename?: "reverb_feed_RemoveFavoriteResponse";
  _source?: Maybe<Scalars["String"]["output"]>;
  favorite?: Maybe<reverb_feed_Favorite>;
};

export type reverb_feed_RemoveFollowRequest = {
  __typename?: "reverb_feed_RemoveFollowRequest";
  _source?: Maybe<Scalars["String"]["output"]>;
  follow?: Maybe<reverb_feed_Follow>;
  locale?: Maybe<Scalars["String"]["output"]>;
  removeEntries?: Maybe<Scalars["Boolean"]["output"]>;
};

export type reverb_feed_RemoveFollowsBySearchableRequest = {
  __typename?: "reverb_feed_RemoveFollowsBySearchableRequest";
  _source?: Maybe<Scalars["String"]["output"]>;
  searchableId?: Maybe<Scalars["Int"]["output"]>;
  searchableType?: Maybe<reverb_feed_SearchableType>;
};

export type reverb_feed_RemoveFollowsBySearchableResponse = {
  __typename?: "reverb_feed_RemoveFollowsBySearchableResponse";
  _?: Maybe<Scalars["Boolean"]["output"]>;
  _source?: Maybe<Scalars["String"]["output"]>;
};

export type reverb_feed_ReplaceFollowsSavedSearchIdRequest = {
  __typename?: "reverb_feed_ReplaceFollowsSavedSearchIdRequest";
  _source?: Maybe<Scalars["String"]["output"]>;
  newSavedSearchId?: Maybe<Scalars["Int"]["output"]>;
  oldSavedSearchId?: Maybe<Scalars["Int"]["output"]>;
};

export type reverb_feed_ReplaceFollowsSavedSearchIdResponse = {
  __typename?: "reverb_feed_ReplaceFollowsSavedSearchIdResponse";
  _source?: Maybe<Scalars["String"]["output"]>;
  followsUpdatedCount?: Maybe<Scalars["Int"]["output"]>;
  newSavedSearchId?: Maybe<Scalars["Int"]["output"]>;
  oldSavedSearchId?: Maybe<Scalars["Int"]["output"]>;
};

export type reverb_feed_SavedSearchStreamResponse = {
  __typename?: "reverb_feed_SavedSearchStreamResponse";
  _source?: Maybe<Scalars["String"]["output"]>;
  queryParams?: Maybe<Scalars["String"]["output"]>;
  savedSearchId?: Maybe<Scalars["Int"]["output"]>;
};

export type reverb_feed_SavedSearchesStreamRequest = {
  __typename?: "reverb_feed_SavedSearchesStreamRequest";
  _source?: Maybe<Scalars["String"]["output"]>;
  chunkSize?: Maybe<Scalars["Int"]["output"]>;
  fromId?: Maybe<Scalars["Int"]["output"]>;
};

export type reverb_feed_SavedSearchesStreamResponse = {
  __typename?: "reverb_feed_SavedSearchesStreamResponse";
  _source?: Maybe<Scalars["String"]["output"]>;
  savedSearches?: Maybe<Array<Maybe<reverb_feed_SavedSearchStreamResponse>>>;
};

export enum reverb_feed_SearchableType {
  ALBUM = "ALBUM",
  ARTIST = "ARTIST",
  BRAND = "BRAND",
  CATEGORY = "CATEGORY",
  COMPARISON_SHOPPING_PAGE = "COMPARISON_SHOPPING_PAGE",
  CURATED_SEARCH = "CURATED_SEARCH",
  CURATED_SET = "CURATED_SET",
  FAVORITE_SHOP = "FAVORITE_SHOP",
  FILTERED_QUERY = "FILTERED_QUERY",
  LABEL = "LABEL",
  PRODUCT_TYPE = "PRODUCT_TYPE",
  PROFILE = "PROFILE",
  RELEASE = "RELEASE",
  SHOP = "SHOP",
}

export type reverb_feed_SetAlertableUsersTzRequest = {
  __typename?: "reverb_feed_SetAlertableUsersTzRequest";
  _source?: Maybe<Scalars["String"]["output"]>;
  tzRegion?: Maybe<Scalars["Int"]["output"]>;
  userIds?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
};

export type reverb_feed_SetAlertableUsersTzResponse = {
  __typename?: "reverb_feed_SetAlertableUsersTzResponse";
  _?: Maybe<Scalars["Boolean"]["output"]>;
  _source?: Maybe<Scalars["String"]["output"]>;
};

export type reverb_feed_SetFollowDigestRequest = {
  __typename?: "reverb_feed_SetFollowDigestRequest";
  _source?: Maybe<Scalars["String"]["output"]>;
  digestEnabled?: Maybe<Scalars["Boolean"]["output"]>;
  id?: Maybe<Scalars["String"]["output"]>;
  locale?: Maybe<Scalars["String"]["output"]>;
  userId?: Maybe<Scalars["String"]["output"]>;
};

export type reverb_feed_SetFollowDigestResponse = {
  __typename?: "reverb_feed_SetFollowDigestResponse";
  _source?: Maybe<Scalars["String"]["output"]>;
  follow?: Maybe<reverb_feed_Follow>;
};

export type reverb_feed_SetFollowDigestsByUserRequest = {
  __typename?: "reverb_feed_SetFollowDigestsByUserRequest";
  _source?: Maybe<Scalars["String"]["output"]>;
  digestsEnabled?: Maybe<Scalars["Boolean"]["output"]>;
  userId?: Maybe<Scalars["String"]["output"]>;
};

export type reverb_feed_SetFollowDigestsByUserResponse = {
  __typename?: "reverb_feed_SetFollowDigestsByUserResponse";
  _source?: Maybe<Scalars["String"]["output"]>;
  follows?: Maybe<Array<Maybe<reverb_feed_Follow>>>;
};

export type reverb_feed_UndoRemoveFavoriteRequest = {
  __typename?: "reverb_feed_UndoRemoveFavoriteRequest";
  _source?: Maybe<Scalars["String"]["output"]>;
  id?: Maybe<Scalars["String"]["output"]>;
  userId?: Maybe<Scalars["String"]["output"]>;
};

export type reverb_feed_UndoRemoveFavoriteResponse = {
  __typename?: "reverb_feed_UndoRemoveFavoriteResponse";
  _source?: Maybe<Scalars["String"]["output"]>;
  favorite?: Maybe<reverb_feed_Favorite>;
};

export type reverb_feed_UpdateSavedSearchRequest = {
  __typename?: "reverb_feed_UpdateSavedSearchRequest";
  _source?: Maybe<Scalars["String"]["output"]>;
  esQuery?: Maybe<Scalars["String"]["output"]>;
  id?: Maybe<Scalars["Int"]["output"]>;
  localizedContents?: Maybe<Array<Maybe<reverb_feed_FollowLocalizedContents>>>;
  queryParams?: Maybe<Scalars["String"]["output"]>;
  searchableId?: Maybe<Scalars["String"]["output"]>;
  searchableType?: Maybe<reverb_feed_SearchableType>;
};

export type reverb_feed_UpdateSavedSearchResponse = {
  __typename?: "reverb_feed_UpdateSavedSearchResponse";
  _source?: Maybe<Scalars["String"]["output"]>;
  id?: Maybe<Scalars["Int"]["output"]>;
};

export type reverb_feed_UpsertFavoriteRequest = {
  __typename?: "reverb_feed_UpsertFavoriteRequest";
  _source?: Maybe<Scalars["String"]["output"]>;
  emailEnabled?: Maybe<Scalars["Boolean"]["output"]>;
  esQuery?: Maybe<Scalars["String"]["output"]>;
  feedEnabled?: Maybe<Scalars["Boolean"]["output"]>;
  localizedContents?: Maybe<Array<Maybe<reverb_feed_FollowLocalizedContents>>>;
  queryParams?: Maybe<Scalars["String"]["output"]>;
  searchableId?: Maybe<Scalars["String"]["output"]>;
  searchableType?: Maybe<reverb_feed_SearchableType>;
  userId?: Maybe<Scalars["Int"]["output"]>;
};

export type reverb_feed_UpsertFavoriteResponse = {
  __typename?: "reverb_feed_UpsertFavoriteResponse";
  _source?: Maybe<Scalars["String"]["output"]>;
  favorite?: Maybe<reverb_feed_Favorite>;
};

export type reverb_feed_UpsertFollowResponse = {
  __typename?: "reverb_feed_UpsertFollowResponse";
  _source?: Maybe<Scalars["String"]["output"]>;
  follow?: Maybe<reverb_feed_Follow>;
  followCount?: Maybe<Scalars["Int"]["output"]>;
  following?: Maybe<Scalars["Boolean"]["output"]>;
};

export type reverb_feed_User = {
  __typename?: "reverb_feed_User";
  _source?: Maybe<Scalars["String"]["output"]>;
  lastActiveAt?: Maybe<Scalars["Timestamp"]["output"]>;
  locale?: Maybe<Scalars["String"]["output"]>;
  userId?: Maybe<Scalars["String"]["output"]>;
};

export type reverb_feed_UserDigest = {
  __typename?: "reverb_feed_UserDigest";
  _source?: Maybe<Scalars["String"]["output"]>;
  digestFollows?: Maybe<Array<Maybe<reverb_feed_DigestFollows>>>;
  userId?: Maybe<Scalars["String"]["output"]>;
};

export type reverb_feed_UserDigestsRequest = {
  __typename?: "reverb_feed_UserDigestsRequest";
  _source?: Maybe<Scalars["String"]["output"]>;
  matchedAfter?: Maybe<Scalars["Timestamp"]["output"]>;
  users?: Maybe<Array<Maybe<reverb_feed_User>>>;
};

export type reverb_feed_UserDigestsResponse = {
  __typename?: "reverb_feed_UserDigestsResponse";
  _source?: Maybe<Scalars["String"]["output"]>;
  userDigests?: Maybe<Array<Maybe<reverb_feed_UserDigest>>>;
};

export type reverb_feed_Users = {
  __typename?: "reverb_feed_Users";
  _source?: Maybe<Scalars["String"]["output"]>;
  users?: Maybe<Array<Maybe<reverb_feed_User>>>;
};

export type reverb_personalization_GetTrendingSearchesRequest = {
  __typename?: "reverb_personalization_GetTrendingSearchesRequest";
  _source?: Maybe<Scalars["String"]["output"]>;
  categoryUuids?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
  nResults?: Maybe<Scalars["Int"]["output"]>;
};

export type reverb_personalization_GetUserRequest = {
  __typename?: "reverb_personalization_GetUserRequest";
  _source?: Maybe<Scalars["String"]["output"]>;
  id?: Maybe<Scalars["String"]["output"]>;
  version?: Maybe<Scalars["Int"]["output"]>;
};

export type reverb_personalization_Personalization = {
  __typename?: "reverb_personalization_Personalization";
  GetQuerySuggestions?: Maybe<reverb_personalization_QuerySuggestionsResponse>;
  GetTrendingSearches?: Maybe<reverb_personalization_TrendingSearchesResponse>;
  GetUser?: Maybe<reverb_personalization_User>;
  RefreshListingRecommendations?: Maybe<google_protobuf_Empty>;
  RefreshListingRecsV2?: Maybe<google_protobuf_Empty>;
  RefreshQuerySuggestions?: Maybe<google_protobuf_Empty>;
  RefreshTrendingSearches?: Maybe<google_protobuf_Empty>;
  _source?: Maybe<Scalars["String"]["output"]>;
};

export type reverb_personalization_PersonalizationGetQuerySuggestionsArgs = {
  input?: InputMaybe<Input_reverb_personalization_QuerySuggestionsRequest>;
};

export type reverb_personalization_PersonalizationGetTrendingSearchesArgs = {
  input?: InputMaybe<Input_reverb_personalization_GetTrendingSearchesRequest>;
};

export type reverb_personalization_PersonalizationGetUserArgs = {
  input?: InputMaybe<Input_reverb_personalization_GetUserRequest>;
};

export type reverb_personalization_PersonalizationRefreshListingRecommendationsArgs =
  {
    input?: InputMaybe<Input_reverb_personalization_RefreshListingRecommendationsRequest>;
  };

export type reverb_personalization_PersonalizationRefreshListingRecsV2Args = {
  input?: InputMaybe<Input_reverb_personalization_RefreshListingRecommendationsRequestV2>;
};

export type reverb_personalization_PersonalizationRefreshQuerySuggestionsArgs =
  {
    input?: InputMaybe<Input_reverb_personalization_RefreshQuerySuggestionsRequest>;
  };

export type reverb_personalization_PersonalizationRefreshTrendingSearchesArgs =
  {
    input?: InputMaybe<Input_reverb_personalization_RefreshTrendingSearchesRequest>;
  };

export type reverb_personalization_QuerySuggestionResponse = {
  __typename?: "reverb_personalization_QuerySuggestionResponse";
  _source?: Maybe<Scalars["String"]["output"]>;
  query?: Maybe<reverb_personalization_SearchQuery>;
  suggestions?: Maybe<Array<Maybe<reverb_personalization_SearchQuery>>>;
};

export type reverb_personalization_QuerySuggestionsRequest = {
  __typename?: "reverb_personalization_QuerySuggestionsRequest";
  _source?: Maybe<Scalars["String"]["output"]>;
  limitPerQuery?: Maybe<Scalars["Int"]["output"]>;
  queries?: Maybe<Array<Maybe<reverb_personalization_SearchQuery>>>;
};

export type reverb_personalization_QuerySuggestionsResponse = {
  __typename?: "reverb_personalization_QuerySuggestionsResponse";
  _source?: Maybe<Scalars["String"]["output"]>;
  queries?: Maybe<Array<Maybe<reverb_personalization_QuerySuggestionResponse>>>;
};

export type reverb_personalization_RefreshListingRecommendationsRequest = {
  __typename?: "reverb_personalization_RefreshListingRecommendationsRequest";
  _?: Maybe<Scalars["Boolean"]["output"]>;
  _source?: Maybe<Scalars["String"]["output"]>;
};

export type reverb_personalization_RefreshListingRecommendationsRequestV2 = {
  __typename?: "reverb_personalization_RefreshListingRecommendationsRequestV2";
  _source?: Maybe<Scalars["String"]["output"]>;
  runId?: Maybe<Scalars["String"]["output"]>;
};

export type reverb_personalization_RefreshQuerySuggestionsRequest = {
  __typename?: "reverb_personalization_RefreshQuerySuggestionsRequest";
  _?: Maybe<Scalars["Boolean"]["output"]>;
  _source?: Maybe<Scalars["String"]["output"]>;
};

export type reverb_personalization_RefreshTrendingSearchesRequest = {
  __typename?: "reverb_personalization_RefreshTrendingSearchesRequest";
  _?: Maybe<Scalars["Boolean"]["output"]>;
  _source?: Maybe<Scalars["String"]["output"]>;
};

export type reverb_personalization_SearchQuery = {
  __typename?: "reverb_personalization_SearchQuery";
  _source?: Maybe<Scalars["String"]["output"]>;
  category?: Maybe<Scalars["String"]["output"]>;
  listingIds?: Maybe<Array<Maybe<Scalars["Int"]["output"]>>>;
  listings?: Maybe<Array<Maybe<Listing>>>;
  make?: Maybe<Scalars["String"]["output"]>;
  productType?: Maybe<Scalars["String"]["output"]>;
  query?: Maybe<Scalars["String"]["output"]>;
};

export type reverb_personalization_TrendingSearch = {
  __typename?: "reverb_personalization_TrendingSearch";
  _source?: Maybe<Scalars["String"]["output"]>;
  categorySlug?: Maybe<Scalars["String"]["output"]>;
  categoryUuid?: Maybe<Scalars["String"]["output"]>;
  listingIds?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
  listings?: Maybe<Array<Maybe<Listing>>>;
  query?: Maybe<Scalars["String"]["output"]>;
  queryParams?: Maybe<Scalars["String"]["output"]>;
  score?: Maybe<Scalars["Float"]["output"]>;
};

export type reverb_personalization_TrendingSearchesResponse = {
  __typename?: "reverb_personalization_TrendingSearchesResponse";
  _source?: Maybe<Scalars["String"]["output"]>;
  trendingSearches?: Maybe<Array<Maybe<reverb_personalization_TrendingSearch>>>;
};

export type reverb_personalization_User = {
  __typename?: "reverb_personalization_User";
  _source?: Maybe<Scalars["String"]["output"]>;
  id?: Maybe<Scalars["String"]["output"]>;
  recommendedListingIds?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
};

export type reverb_pricing_CSPPriceRecommendationQuery = {
  __typename?: "reverb_pricing_CSPPriceRecommendationQuery";
  _source?: Maybe<Scalars["String"]["output"]>;
  comparisonShoppingPageId?: Maybe<Scalars["String"]["output"]>;
  conditionUuid?: Maybe<Scalars["String"]["output"]>;
  countryCode?: Maybe<Scalars["String"]["output"]>;
  currency?: Maybe<Scalars["String"]["output"]>;
};

export type reverb_pricing_CSPPriceRecommendationsRequest = {
  __typename?: "reverb_pricing_CSPPriceRecommendationsRequest";
  _source?: Maybe<Scalars["String"]["output"]>;
  cspPriceRecommendationQueries?: Maybe<
    Array<Maybe<reverb_pricing_CSPPriceRecommendationQuery>>
  >;
  modelVersion?: Maybe<reverb_pricing_ModelVersion>;
  /** @deprecated Field no longer supported */
  version?: Maybe<Scalars["Int"]["output"]>;
};

export enum reverb_pricing_ModelVersion {
  DESCRIPTIVE = "DESCRIPTIVE",
  MACHINE_LEARNING = "MACHINE_LEARNING",
}

/** Only for use with Price Recommendations. Prefer Types::Money. */
export type reverb_pricing_Money = {
  __typename?: "reverb_pricing_Money";
  _source?: Maybe<Scalars["String"]["output"]>;
  amount?: Maybe<Scalars["String"]["output"]>;
  amountCents?: Maybe<Scalars["Int"]["output"]>;
  currency?: Maybe<Scalars["String"]["output"]>;
  display?: Maybe<Scalars["String"]["output"]>;
  symbol?: Maybe<Scalars["String"]["output"]>;
};

export type reverb_pricing_PriceRecommendation = {
  __typename?: "reverb_pricing_PriceRecommendation";
  _source?: Maybe<Scalars["String"]["output"]>;
  canonicalProductId?: Maybe<Scalars["String"]["output"]>;
  comparisonShoppingPageId?: Maybe<Scalars["String"]["output"]>;
  conditionUuid?: Maybe<Scalars["String"]["output"]>;
  countryCode?: Maybe<Scalars["String"]["output"]>;
  priceHigh?: Maybe<reverb_pricing_Money>;
  priceHighThirtyDaysAgo?: Maybe<reverb_pricing_Money>;
  priceLow?: Maybe<reverb_pricing_Money>;
  priceLowThirtyDaysAgo?: Maybe<reverb_pricing_Money>;
  priceMiddle?: Maybe<reverb_pricing_Money>;
  priceMiddleThirtyDaysAgo?: Maybe<reverb_pricing_Money>;
};

export type reverb_pricing_PriceRecommendationQuery = {
  __typename?: "reverb_pricing_PriceRecommendationQuery";
  _source?: Maybe<Scalars["String"]["output"]>;
  canonicalProductId?: Maybe<Scalars["String"]["output"]>;
  conditionUuid?: Maybe<Scalars["String"]["output"]>;
  countryCode?: Maybe<Scalars["String"]["output"]>;
  currency?: Maybe<Scalars["String"]["output"]>;
};

export type reverb_pricing_PriceRecommendationsRequest = {
  __typename?: "reverb_pricing_PriceRecommendationsRequest";
  _source?: Maybe<Scalars["String"]["output"]>;
  modelVersion?: Maybe<reverb_pricing_ModelVersion>;
  priceRecommendationQueries?: Maybe<
    Array<Maybe<reverb_pricing_PriceRecommendationQuery>>
  >;
  /** @deprecated Field no longer supported */
  version?: Maybe<Scalars["Int"]["output"]>;
};

export type reverb_pricing_PriceRecommendationsResponse = {
  __typename?: "reverb_pricing_PriceRecommendationsResponse";
  _source?: Maybe<Scalars["String"]["output"]>;
  priceRecommendations?: Maybe<
    Array<Maybe<reverb_pricing_PriceRecommendation>>
  >;
};

export type reverb_pricing_PriceScore = {
  __typename?: "reverb_pricing_PriceScore";
  _source?: Maybe<Scalars["String"]["output"]>;
  absResidual?: Maybe<Scalars["Float"]["output"]>;
  canonicalProductId?: Maybe<Scalars["String"]["output"]>;
  conditionUuid?: Maybe<Scalars["String"]["output"]>;
  priceMiddle?: Maybe<reverb_pricing_Money>;
  priceScore?: Maybe<Scalars["Float"]["output"]>;
};

export type reverb_pricing_PriceScoreQuery = {
  __typename?: "reverb_pricing_PriceScoreQuery";
  _source?: Maybe<Scalars["String"]["output"]>;
  canonicalProductId?: Maybe<Scalars["String"]["output"]>;
  conditionUuid?: Maybe<Scalars["String"]["output"]>;
  countryCode?: Maybe<Scalars["String"]["output"]>;
  price?: Maybe<reverb_pricing_Money>;
};

export type reverb_pricing_PriceScoresRequest = {
  __typename?: "reverb_pricing_PriceScoresRequest";
  _source?: Maybe<Scalars["String"]["output"]>;
  modelVersion?: Maybe<reverb_pricing_ModelVersion>;
  priceScoreQueries?: Maybe<Array<Maybe<reverb_pricing_PriceScoreQuery>>>;
  /** @deprecated Field no longer supported */
  version?: Maybe<Scalars["Int"]["output"]>;
};

export type reverb_pricing_PriceScoresResponse = {
  __typename?: "reverb_pricing_PriceScoresResponse";
  _source?: Maybe<Scalars["String"]["output"]>;
  priceScores?: Maybe<Array<Maybe<reverb_pricing_PriceScore>>>;
};

export type reverb_pricing_Pricing = {
  __typename?: "reverb_pricing_Pricing";
  GetCSPPriceRecommendations?: Maybe<reverb_pricing_PriceRecommendationsResponse>;
  GetPriceRecommendations?: Maybe<reverb_pricing_PriceRecommendationsResponse>;
  GetPriceScores?: Maybe<reverb_pricing_PriceScoresResponse>;
  _source?: Maybe<Scalars["String"]["output"]>;
};

export type reverb_pricing_PricingGetCSPPriceRecommendationsArgs = {
  input?: InputMaybe<Input_reverb_pricing_CSPPriceRecommendationsRequest>;
};

export type reverb_pricing_PricingGetPriceRecommendationsArgs = {
  input?: InputMaybe<Input_reverb_pricing_PriceRecommendationsRequest>;
};

export type reverb_pricing_PricingGetPriceScoresArgs = {
  input?: InputMaybe<Input_reverb_pricing_PriceScoresRequest>;
};

export type reverb_reporting_CSPStats = {
  __typename?: "reverb_reporting_CSPStats";
  _source?: Maybe<Scalars["String"]["output"]>;
  /** total_order_value_usc / order_quantity */
  avgOrderPriceUsc?: Maybe<reverb_reporting_Int32Value>;
  liveListingCount?: Maybe<Scalars["Int"]["output"]>;
  minListingPriceUsc?: Maybe<reverb_reporting_Int32Value>;
  orderQuantity?: Maybe<Scalars["Int"]["output"]>;
  totalOrderValueUsc?: Maybe<Scalars["Int"]["output"]>;
};

export type reverb_reporting_CSPStatsByCSP = {
  __typename?: "reverb_reporting_CSPStatsByCSP";
  _source?: Maybe<Scalars["String"]["output"]>;
  /** total_order_value_usc / order_quantity */
  avgOrderPriceUsc?: Maybe<reverb_reporting_Int32Value>;
  brandSlug?: Maybe<Scalars["String"]["output"]>;
  csp?: Maybe<CSP>;
  cspId?: Maybe<Scalars["String"]["output"]>;
  cspSlug?: Maybe<Scalars["String"]["output"]>;
  liveListingCount?: Maybe<Scalars["Int"]["output"]>;
  minListingPriceUsc?: Maybe<reverb_reporting_Int32Value>;
  orderQuantity?: Maybe<Scalars["Int"]["output"]>;
  totalOrderValueUsc?: Maybe<Scalars["Int"]["output"]>;
};

export type reverb_reporting_CSPStatsByCSPRequest = {
  __typename?: "reverb_reporting_CSPStatsByCSPRequest";
  _source?: Maybe<Scalars["String"]["output"]>;
  brandSlugs?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
  condition?: Maybe<reverb_reporting_Condition>;
  cspIds?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
  cspSlugs?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
  dateEnd?: Maybe<Scalars["String"]["output"]>;
  dateStart?: Maybe<Scalars["String"]["output"]>;
  includeTotal?: Maybe<Scalars["Boolean"]["output"]>;
  limit?: Maybe<Scalars["Int"]["output"]>;
  offset?: Maybe<Scalars["Int"]["output"]>;
  orderBy?: Maybe<reverb_reporting_CSPStatsMetric>;
  orderDesc?: Maybe<Scalars["Boolean"]["output"]>;
};

export type reverb_reporting_CSPStatsByCSPResponse = {
  __typename?: "reverb_reporting_CSPStatsByCSPResponse";
  _source?: Maybe<Scalars["String"]["output"]>;
  byCsp?: Maybe<Array<Maybe<reverb_reporting_CSPStatsByCSP>>>;
  totalCount?: Maybe<Scalars["Int"]["output"]>;
};

export type reverb_reporting_CSPStatsByDate = {
  __typename?: "reverb_reporting_CSPStatsByDate";
  _source?: Maybe<Scalars["String"]["output"]>;
  /** total_order_value_usc / order_quantity */
  avgOrderPriceUsc?: Maybe<reverb_reporting_Int32Value>;
  date?: Maybe<Scalars["String"]["output"]>;
  liveListingCount?: Maybe<Scalars["Int"]["output"]>;
  minListingPriceUsc?: Maybe<reverb_reporting_Int32Value>;
  orderQuantity?: Maybe<Scalars["Int"]["output"]>;
  totalOrderValueUsc?: Maybe<Scalars["Int"]["output"]>;
};

export type reverb_reporting_CSPStatsByDateResponse = {
  __typename?: "reverb_reporting_CSPStatsByDateResponse";
  _source?: Maybe<Scalars["String"]["output"]>;
  /** Agg across csps, 1 row/date */
  byDate?: Maybe<Array<Maybe<reverb_reporting_CSPStatsByDate>>>;
};

export enum reverb_reporting_CSPStatsMetric {
  AVG_ORDER_PRICE_USC = "AVG_ORDER_PRICE_USC",
  LIVE_LISTING_COUNT = "LIVE_LISTING_COUNT",
  MIN_LISTING_PRICE_USC = "MIN_LISTING_PRICE_USC",
  ORDER_QUANTITY = "ORDER_QUANTITY",
  TOTAL_ORDER_VALUE_USC = "TOTAL_ORDER_VALUE_USC",
}

export type reverb_reporting_CSPStatsRequest = {
  __typename?: "reverb_reporting_CSPStatsRequest";
  _source?: Maybe<Scalars["String"]["output"]>;
  brandSlugs?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
  condition?: Maybe<reverb_reporting_Condition>;
  cspIds?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
  cspSlugs?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
  dateEnd?: Maybe<Scalars["String"]["output"]>;
  dateStart?: Maybe<Scalars["String"]["output"]>;
};

export enum reverb_reporting_Condition {
  ALL = "ALL",
  NEW = "NEW",
  USED = "USED",
}

/**
 *   To represent a null or an integer. Including in this package instead of using
 * the google wrapper because we need to implement a Scan method for reading
 * from db
 */
export type reverb_reporting_Int32Value = {
  __typename?: "reverb_reporting_Int32Value";
  _source?: Maybe<Scalars["String"]["output"]>;
  value?: Maybe<Scalars["Int"]["output"]>;
};

export type reverb_reporting_Reporting = {
  __typename?: "reverb_reporting_Reporting";
  CSPStatsByCSP?: Maybe<reverb_reporting_CSPStatsByCSPResponse>;
  CSPStatsByDate?: Maybe<reverb_reporting_CSPStatsByDateResponse>;
  CSPStatsTotal?: Maybe<reverb_reporting_CSPStats>;
  CspsCtrByDateRange?: Maybe<reverb_reporting_ctr_CspsCtrByDateRangeResponse>;
  ShopStats?: Maybe<reverb_reporting_ShopStatsResponse>;
  ShopStatsByDate?: Maybe<reverb_reporting_ShopStatsByDateResponse>;
  ShopStatsCurrencyCount?: Maybe<reverb_reporting_ShopStatsCurrencyCountResponse>;
  _source?: Maybe<Scalars["String"]["output"]>;
};

export type reverb_reporting_ReportingCSPStatsByCSPArgs = {
  input?: InputMaybe<Input_reverb_reporting_CSPStatsByCSPRequest>;
};

export type reverb_reporting_ReportingCSPStatsByDateArgs = {
  input?: InputMaybe<Input_reverb_reporting_CSPStatsRequest>;
};

export type reverb_reporting_ReportingCSPStatsTotalArgs = {
  input?: InputMaybe<Input_reverb_reporting_CSPStatsRequest>;
};

export type reverb_reporting_ReportingCspsCtrByDateRangeArgs = {
  input?: InputMaybe<Input_reverb_reporting_ctr_CspsCtrByDateRangeRequest>;
};

export type reverb_reporting_ReportingShopStatsArgs = {
  input?: InputMaybe<Input_reverb_reporting_ShopStatsRequest>;
};

export type reverb_reporting_ReportingShopStatsByDateArgs = {
  input?: InputMaybe<Input_reverb_reporting_ShopStatsByDateRequest>;
};

export type reverb_reporting_ReportingShopStatsCurrencyCountArgs = {
  input?: InputMaybe<Input_reverb_reporting_ShopStatsRequest>;
};

export type reverb_reporting_ShopStatsByDateRequest = {
  __typename?: "reverb_reporting_ShopStatsByDateRequest";
  _source?: Maybe<Scalars["String"]["output"]>;
  currency?: Maybe<Scalars["String"]["output"]>;
  endTime?: Maybe<Scalars["String"]["output"]>;
  shopId?: Maybe<Scalars["String"]["output"]>;
  startTime?: Maybe<Scalars["String"]["output"]>;
  timeGrouping?: Maybe<reverb_reporting_TimeGrouping>;
  timeZone?: Maybe<Scalars["String"]["output"]>;
};

export type reverb_reporting_ShopStatsByDateResponse = {
  __typename?: "reverb_reporting_ShopStatsByDateResponse";
  _source?: Maybe<Scalars["String"]["output"]>;
  buckets?: Maybe<Array<Maybe<reverb_reporting_ShopStatsTimeBucket>>>;
};

export type reverb_reporting_ShopStatsCurrencyCountResponse = {
  __typename?: "reverb_reporting_ShopStatsCurrencyCountResponse";
  _source?: Maybe<Scalars["String"]["output"]>;
  currencyCount?: Maybe<Scalars["Int"]["output"]>;
};

export type reverb_reporting_ShopStatsRequest = {
  __typename?: "reverb_reporting_ShopStatsRequest";
  _source?: Maybe<Scalars["String"]["output"]>;
  currency?: Maybe<Scalars["String"]["output"]>;
  endTime?: Maybe<Scalars["String"]["output"]>;
  shopId?: Maybe<Scalars["String"]["output"]>;
  startTime?: Maybe<Scalars["String"]["output"]>;
};

export type reverb_reporting_ShopStatsResponse = {
  __typename?: "reverb_reporting_ShopStatsResponse";
  _source?: Maybe<Scalars["String"]["output"]>;
  messagesCount?: Maybe<Scalars["Int"]["output"]>;
  offersCount?: Maybe<Scalars["Int"]["output"]>;
  ordersCount?: Maybe<Scalars["Int"]["output"]>;
  salesCents?: Maybe<Scalars["Int"]["output"]>;
  watchersCount?: Maybe<Scalars["Int"]["output"]>;
};

export type reverb_reporting_ShopStatsTimeBucket = {
  __typename?: "reverb_reporting_ShopStatsTimeBucket";
  _source?: Maybe<Scalars["String"]["output"]>;
  salesCents?: Maybe<Scalars["Int"]["output"]>;
  timestamp?: Maybe<Scalars["String"]["output"]>;
};

export enum reverb_reporting_TimeGrouping {
  DAY = "DAY",
  MONTH = "MONTH",
}

export type reverb_reporting_ctr_CspCtr = {
  __typename?: "reverb_reporting_ctr_CspCtr";
  _source?: Maybe<Scalars["String"]["output"]>;
  clicks?: Maybe<Scalars["Int"]["output"]>;
  cspId?: Maybe<Scalars["String"]["output"]>;
  impressions?: Maybe<Scalars["Int"]["output"]>;
};

export type reverb_reporting_ctr_CspsCtrByDateRangeRequest = {
  __typename?: "reverb_reporting_ctr_CspsCtrByDateRangeRequest";
  _source?: Maybe<Scalars["String"]["output"]>;
  cspIds?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
  datetimeEnd?: Maybe<Scalars["String"]["output"]>;
  datetimeStart?: Maybe<Scalars["String"]["output"]>;
};

export type reverb_reporting_ctr_CspsCtrByDateRangeResponse = {
  __typename?: "reverb_reporting_ctr_CspsCtrByDateRangeResponse";
  _source?: Maybe<Scalars["String"]["output"]>;
  ctrs?: Maybe<Array<Maybe<reverb_reporting_ctr_CspCtr>>>;
};

export type reverb_search_AggregationDetails = {
  __typename?: "reverb_search_AggregationDetails";
  _source?: Maybe<Scalars["String"]["output"]>;
  count?: Maybe<Scalars["Int"]["output"]>;
  displayName?: Maybe<Scalars["String"]["output"]>;
  key?: Maybe<Scalars["String"]["output"]>;
};

export type reverb_search_AggregationResult = {
  __typename?: "reverb_search_AggregationResult";
  _source?: Maybe<Scalars["String"]["output"]>;
  aggregationDetails?: Maybe<Array<Maybe<reverb_search_AggregationDetails>>>;
  displayName?: Maybe<Scalars["String"]["output"]>;
  name?: Maybe<reverb_search_ListingsSearchRequest_Aggregation>;
};

export type reverb_search_AuctionsSearchRequest = {
  __typename?: "reverb_search_AuctionsSearchRequest";
  _source?: Maybe<Scalars["String"]["output"]>;
  bidderId?: Maybe<Scalars["String"]["output"]>;
  limit?: Maybe<Scalars["Int"]["output"]>;
  merchandisingTypes?: Maybe<
    Array<Maybe<reverb_search_AuctionsSearchRequest_MerchandisingType>>
  >;
  offset?: Maybe<Scalars["Int"]["output"]>;
  query?: Maybe<Scalars["String"]["output"]>;
  userBidStatus?: Maybe<reverb_search_AuctionsSearchRequest_UserBidStatus>;
  withAggregations?: Maybe<
    Array<Maybe<reverb_search_AuctionsSearchRequest_Aggregation>>
  >;
};

export enum reverb_search_AuctionsSearchRequest_Aggregation {
  USER_BID_STATUS = "USER_BID_STATUS",
}

export enum reverb_search_AuctionsSearchRequest_MerchandisingType {
  DIGITAL = "DIGITAL",
  LP = "LP",
}

export enum reverb_search_AuctionsSearchRequest_UserBidStatus {
  ACTIVE = "ACTIVE",
  ALL = "ALL",
  LOST = "LOST",
  WON = "WON",
}

export type reverb_search_AutocompleteRequest = {
  __typename?: "reverb_search_AutocompleteRequest";
  _source?: Maybe<Scalars["String"]["output"]>;
  currency?: Maybe<Scalars["String"]["output"]>;
  limit?: Maybe<reverb_search_AutocompleteRequest_Limit>;
  locale?: Maybe<Scalars["String"]["output"]>;
  query?: Maybe<Scalars["String"]["output"]>;
  shippingRegionCode?: Maybe<Scalars["String"]["output"]>;
};

export enum reverb_search_AutocompleteRequest_Limit {
  COMPACT = "COMPACT",
  DEFAULT = "DEFAULT",
}

export type reverb_search_AutocompleteResponse = {
  __typename?: "reverb_search_AutocompleteResponse";
  _source?: Maybe<Scalars["String"]["output"]>;
  suggestions?: Maybe<Array<Maybe<reverb_search_AutocompleteSuggestion>>>;
};

export type reverb_search_AutocompleteSuggestion = {
  __typename?: "reverb_search_AutocompleteSuggestion";
  _source?: Maybe<Scalars["String"]["output"]>;
  subtext?: Maybe<Scalars["String"]["output"]>;
  text?: Maybe<Scalars["String"]["output"]>;
  type?: Maybe<Scalars["String"]["output"]>;
  url?: Maybe<reverb_search_Link>;
};

export enum reverb_search_Autodirects {
  DEFAULT = "DEFAULT",
  IMPROVED_DATA = "IMPROVED_DATA",
  NEVER = "NEVER",
  STANDARDIZED = "STANDARDIZED",
}

export type reverb_search_AverageMonthlyProductPrice = {
  __typename?: "reverb_search_AverageMonthlyProductPrice";
  _source?: Maybe<Scalars["String"]["output"]>;
  averageProductPrice?: Maybe<reverb_search_Money>;
  averageProductPriceInUsd?: Maybe<reverb_search_Money>;
  date?: Maybe<Scalars["String"]["output"]>;
  docCount?: Maybe<Scalars["Int"]["output"]>;
};

/** Used for feed related queries to find live listings that match any of a user's saved searches. */
export type reverb_search_BatchListingsSearchRequest = {
  __typename?: "reverb_search_BatchListingsSearchRequest";
  _source?: Maybe<Scalars["String"]["output"]>;
  /** Optional top-level pagination param, use this instead of `listings_filters.limit`. */
  limit?: Maybe<Scalars["Int"]["output"]>;
  /** Optional top-level filters to apply across all results: ((query_params_1 OR query_params_2) AND (listings_filters)). */
  listingsFilters?: Maybe<reverb_search_ListingsSearchRequest>;
  /** Optional top-level pagination param, use this instead of `listings_filters.offset`. */
  offset?: Maybe<Scalars["Int"]["output"]>;
  /** Array of JSON-serialized strings. Each string represents query params for 1 search (saved search filters), such as '[{"query":"sp 1200", "currency": "USD", "price_max": "2000.00" }, {"query": "telecaster", "brand_slugs":["fender"]}]'. */
  queryParams?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
};

/** Request for feed related queries that check if a batch of searches matched any recently updated listings. */
export type reverb_search_BatchMatchedListingsSearchRequest = {
  __typename?: "reverb_search_BatchMatchedListingsSearchRequest";
  _source?: Maybe<Scalars["String"]["output"]>;
  searches?: Maybe<
    Array<Maybe<reverb_search_BatchMatchedListingsSearchRequest_SearchInput>>
  >;
  /** ISO-8601 timestamp string */
  updatedAfter?: Maybe<Scalars["String"]["output"]>;
};

export type reverb_search_BatchMatchedListingsSearchRequest_SearchInput = {
  __typename?: "reverb_search_BatchMatchedListingsSearchRequest_SearchInput";
  _source?: Maybe<Scalars["String"]["output"]>;
  queryParams?: Maybe<Scalars["String"]["output"]>;
  searchId?: Maybe<Scalars["String"]["output"]>;
};

/** Response for feed related queries that check if a batch of searches matched any recently updated listings. */
export type reverb_search_BatchMatchedListingsSearchResponse = {
  __typename?: "reverb_search_BatchMatchedListingsSearchResponse";
  _source?: Maybe<Scalars["String"]["output"]>;
  matchedListingsSearchResults?: Maybe<
    Array<Maybe<reverb_search_BatchMatchedListingsSearchResponse_SearchResult>>
  >;
};

export type reverb_search_BatchMatchedListingsSearchResponse_SearchResult = {
  __typename?: "reverb_search_BatchMatchedListingsSearchResponse_SearchResult";
  _source?: Maybe<Scalars["String"]["output"]>;
  result?: Maybe<reverb_search_BatchMatchedListingsSearchResponse_SearchResult_ResultType>;
  searchId?: Maybe<Scalars["String"]["output"]>;
  total?: Maybe<Scalars["Int"]["output"]>;
};

export enum reverb_search_BatchMatchedListingsSearchResponse_SearchResult_ResultType {
  ERROR = "ERROR",
  SUCCESS = "SUCCESS",
  TIMEOUT = "TIMEOUT",
}

export type reverb_search_BestListing = {
  __typename?: "reverb_search_BestListing";
  _source?: Maybe<Scalars["String"]["output"]>;
  id?: Maybe<Scalars["String"]["output"]>;
  key?: Maybe<Scalars["String"]["output"]>;
};

export type reverb_search_BumpRecommendationsResults = {
  __typename?: "reverb_search_BumpRecommendationsResults";
  _source?: Maybe<Scalars["String"]["output"]>;
  bumpRecommendationAmount?: Maybe<Scalars["Float"]["output"]>;
  maxBumpRecommendationAmount?: Maybe<Scalars["Float"]["output"]>;
};

export type reverb_search_BumpedSortedListingsQuery = {
  __typename?: "reverb_search_BumpedSortedListingsQuery";
  _source?: Maybe<Scalars["String"]["output"]>;
  bumpedMax?: Maybe<Scalars["Int"]["output"]>;
  condition?: Maybe<Scalars["String"]["output"]>;
  itemRegion?: Maybe<Scalars["String"]["output"]>;
  total?: Maybe<Scalars["Int"]["output"]>;
};

export type reverb_search_CMSPagesSearchRequest = {
  __typename?: "reverb_search_CMSPagesSearchRequest";
  _source?: Maybe<Scalars["String"]["output"]>;
  fullTextQuery?: Maybe<Scalars["String"]["output"]>;
  ids?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
  limit?: Maybe<Scalars["Int"]["output"]>;
  offset?: Maybe<Scalars["Int"]["output"]>;
  pageTypes?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
};

export type reverb_search_CSPInventory = {
  __typename?: "reverb_search_CSPInventory";
  _source?: Maybe<Scalars["String"]["output"]>;
  newLowPrice?: Maybe<reverb_search_Money>;
  newTotal?: Maybe<Scalars["Int"]["output"]>;
  usedLowPrice?: Maybe<reverb_search_Money>;
  usedTotal?: Maybe<Scalars["Int"]["output"]>;
};

export type reverb_search_CSPResponse = {
  __typename?: "reverb_search_CSPResponse";
  _source?: Maybe<Scalars["String"]["output"]>;
  averageReviewRating?: Maybe<Scalars["Float"]["output"]>;
  cardSquareImageUrl?: Maybe<Scalars["String"]["output"]>;
  id?: Maybe<Scalars["String"]["output"]>;
  inventory?: Maybe<reverb_search_CSPInventory>;
  /** @deprecated Field no longer supported */
  newListingsLink?: Maybe<Scalars["String"]["output"]>;
  reviewsCount?: Maybe<Scalars["Int"]["output"]>;
  slug?: Maybe<Scalars["String"]["output"]>;
  thumbnailImageUrl?: Maybe<Scalars["String"]["output"]>;
  title?: Maybe<Scalars["String"]["output"]>;
  /** @deprecated Field no longer supported */
  usedListingsLink?: Maybe<Scalars["String"]["output"]>;
  uuid?: Maybe<Scalars["String"]["output"]>;
  webLink?: Maybe<Scalars["String"]["output"]>;
};

export type reverb_search_CSPSearchRequest = {
  __typename?: "reverb_search_CSPSearchRequest";
  _source?: Maybe<Scalars["String"]["output"]>;
  autodirects?: Maybe<reverb_search_Autodirects>;
  boostByClicks?: Maybe<Scalars["Boolean"]["output"]>;
  brandNames?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
  brandSlugs?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
  categorySlugs?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
  categoryUuids?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
  condition?: Maybe<Scalars["String"]["output"]>;
  contexts?: Maybe<Array<Maybe<reverb_search_Context>>>;
  countryOfOrigin?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
  curatedSetId?: Maybe<Scalars["String"]["output"]>;
  curatedSetSlugs?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
  currency?: Maybe<Scalars["String"]["output"]>;
  decades?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
  es7?: Maybe<Scalars["Boolean"]["output"]>;
  excludedCategoryUuids?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
  finishes?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
  fullTextQuery?: Maybe<Scalars["String"]["output"]>;
  fullTextQueryOperand?: Maybe<reverb_search_FullTextQueryOperand>;
  fuzzy?: Maybe<Scalars["Boolean"]["output"]>;
  hasExpressSaleBid?: Maybe<Scalars["Boolean"]["output"]>;
  ids?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
  includeFields?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
  includeOutOfStock?: Maybe<Scalars["Boolean"]["output"]>;
  limit?: Maybe<Scalars["Int"]["output"]>;
  listingsThatShipTo?: Maybe<Scalars["String"]["output"]>;
  locale?: Maybe<Scalars["String"]["output"]>;
  maxPriceUsdCents?: Maybe<Scalars["Int"]["output"]>;
  minPriceUsdCents?: Maybe<Scalars["Int"]["output"]>;
  offset?: Maybe<Scalars["Int"]["output"]>;
  priceMax?: Maybe<Scalars["String"]["output"]>;
  priceMin?: Maybe<Scalars["String"]["output"]>;
  searchUrlParams?: Maybe<Scalars["String"]["output"]>;
  similarCspSlug?: Maybe<Scalars["String"]["output"]>;
  similarListingId?: Maybe<Scalars["String"]["output"]>;
  skipAutodirects?: Maybe<Scalars["Boolean"]["output"]>;
  slugs?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
  sort?: Maybe<reverb_search_CSPSearchRequest_Sort>;
  sortByQuality?: Maybe<Scalars["Boolean"]["output"]>;
  suggestTitle?: Maybe<Scalars["String"]["output"]>;
  traitSlugs?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
  traitValues?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
  uuids?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
  withAggregations?: Maybe<
    Array<Maybe<reverb_search_CSPSearchRequest_Aggregation>>
  >;
  withInventory?: Maybe<Scalars["Boolean"]["output"]>;
  withLimitedAggregations?: Maybe<reverb_search_LimitedAggregation>;
  yearMax?: Maybe<Scalars["Int"]["output"]>;
  yearMin?: Maybe<Scalars["Int"]["output"]>;
};

export enum reverb_search_CSPSearchRequest_Aggregation {
  BRAND_NAMES = "BRAND_NAMES",
  CATEGORY_UUIDS = "CATEGORY_UUIDS",
  DECADES = "DECADES",
  FINISHES = "FINISHES",
}

export enum reverb_search_CSPSearchRequest_Sort {
  AVERAGE_ORDER_PRICE_ASC = "AVERAGE_ORDER_PRICE_ASC",
  AVERAGE_ORDER_PRICE_DESC = "AVERAGE_ORDER_PRICE_DESC",
  AVERAGE_ORDER_PRICE_NEW_ASC = "AVERAGE_ORDER_PRICE_NEW_ASC",
  AVERAGE_ORDER_PRICE_NEW_DESC = "AVERAGE_ORDER_PRICE_NEW_DESC",
  AVERAGE_ORDER_PRICE_USED_ASC = "AVERAGE_ORDER_PRICE_USED_ASC",
  AVERAGE_ORDER_PRICE_USED_DESC = "AVERAGE_ORDER_PRICE_USED_DESC",
  CREATED_AT_ASC = "CREATED_AT_ASC",
  IMPRESSIONS_ASC = "IMPRESSIONS_ASC",
  IMPRESSIONS_DESC = "IMPRESSIONS_DESC",
  LISTING_COUNT_DESC = "LISTING_COUNT_DESC",
  LISTING_MIN_PRICE_NEW_ASC = "LISTING_MIN_PRICE_NEW_ASC",
  LISTING_MIN_PRICE_NEW_DESC = "LISTING_MIN_PRICE_NEW_DESC",
  LISTING_MIN_PRICE_USED_ASC = "LISTING_MIN_PRICE_USED_ASC",
  LISTING_MIN_PRICE_USED_DESC = "LISTING_MIN_PRICE_USED_DESC",
  LIVE_LISTINGS_ASC = "LIVE_LISTINGS_ASC",
  LIVE_LISTINGS_DESC = "LIVE_LISTINGS_DESC",
  LIVE_LISTINGS_NEW_ASC = "LIVE_LISTINGS_NEW_ASC",
  LIVE_LISTINGS_NEW_DESC = "LIVE_LISTINGS_NEW_DESC",
  LIVE_LISTINGS_USED_ASC = "LIVE_LISTINGS_USED_ASC",
  LIVE_LISTINGS_USED_DESC = "LIVE_LISTINGS_USED_DESC",
  NONE = "NONE",
  QUALITY_DESC = "QUALITY_DESC",
  RECENT_ORDERS_COUNT_ASC = "RECENT_ORDERS_COUNT_ASC",
  RECENT_ORDERS_COUNT_DESC = "RECENT_ORDERS_COUNT_DESC",
  RECENT_ORDERS_COUNT_NEW_ASC = "RECENT_ORDERS_COUNT_NEW_ASC",
  RECENT_ORDERS_COUNT_NEW_DESC = "RECENT_ORDERS_COUNT_NEW_DESC",
  RECENT_ORDERS_COUNT_USED_ASC = "RECENT_ORDERS_COUNT_USED_ASC",
  RECENT_ORDERS_COUNT_USED_DESC = "RECENT_ORDERS_COUNT_USED_DESC",
  TITLE_RAW_ASC = "TITLE_RAW_ASC",
  TRENDING_DESC = "TRENDING_DESC",
}

export type reverb_search_Completion = {
  __typename?: "reverb_search_Completion";
  _source?: Maybe<Scalars["String"]["output"]>;
  options?: Maybe<Array<Maybe<reverb_search_SuggestOption>>>;
  text?: Maybe<Scalars["String"]["output"]>;
  type?: Maybe<reverb_search_CompletionType>;
};

export enum reverb_search_CompletionType {
  ALL = "ALL",
  BRAND = "BRAND",
  BRAND_MODEL = "BRAND_MODEL",
  CSP = "CSP",
  FREE_TEXT = "FREE_TEXT",
  PRODUCT_FAMILY = "PRODUCT_FAMILY",
  PRODUCT_TYPE = "PRODUCT_TYPE",
  SHOP = "SHOP",
  SUB_CATEGORY = "SUB_CATEGORY",
}

export type reverb_search_CompletionsRequest = {
  __typename?: "reverb_search_CompletionsRequest";
  _source?: Maybe<Scalars["String"]["output"]>;
  currency?: Maybe<Scalars["String"]["output"]>;
  experiments?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
  hideStaleListings?: Maybe<Scalars["Boolean"]["output"]>;
  locale?: Maybe<Scalars["String"]["output"]>;
  query?: Maybe<Scalars["String"]["output"]>;
  shippingRegionCode?: Maybe<Scalars["String"]["output"]>;
  types?: Maybe<Array<Maybe<reverb_search_CompletionType>>>;
};

export enum reverb_search_Context {
  INITIAL_QUERY = "INITIAL_QUERY",
}

export type reverb_search_DateBucket = {
  __typename?: "reverb_search_DateBucket";
  _source?: Maybe<Scalars["String"]["output"]>;
  /** ISO8601 date */
  fromDate?: Maybe<Scalars["String"]["output"]>;
  metrics?: Maybe<reverb_search_OrderMetrics>;
  /** ISO8601 date */
  toDate?: Maybe<Scalars["String"]["output"]>;
};

export type reverb_search_Distance = {
  __typename?: "reverb_search_Distance";
  _source?: Maybe<Scalars["String"]["output"]>;
  unit?: Maybe<reverb_search_Distance_DistanceUnit>;
  /** / Both whole numbers and decimals are supported. */
  value?: Maybe<Scalars["String"]["output"]>;
};

export enum reverb_search_Distance_DistanceUnit {
  MI = "MI",
}

export type reverb_search_Document = {
  __typename?: "reverb_search_Document";
  _source?: Maybe<Scalars["String"]["output"]>;
  esCsp?: Maybe<reverb_search_CSPResponse>;
  esGearCollectionItem?: Maybe<reverb_search_GearCollectionItemResponse>;
  id?: Maybe<Scalars["String"]["output"]>;
  node?: Maybe<Node>;
  source?: Maybe<Scalars["String"]["output"]>;
  type?: Maybe<Scalars["String"]["output"]>;
};

export type reverb_search_ExperimentData = {
  __typename?: "reverb_search_ExperimentData";
  _source?: Maybe<Scalars["String"]["output"]>;
  group?: Maybe<Scalars["String"]["output"]>;
  groupNum?: Maybe<Scalars["Int"]["output"]>;
  name?: Maybe<Scalars["String"]["output"]>;
};

export type reverb_search_Filter = {
  __typename?: "reverb_search_Filter";
  _source?: Maybe<Scalars["String"]["output"]>;
  aggregationName?: Maybe<Scalars["String"]["output"]>;
  key?: Maybe<Scalars["String"]["output"]>;
  name?: Maybe<Scalars["String"]["output"]>;
  options?: Maybe<Array<Maybe<reverb_search_FilterOption>>>;
  widgetType?: Maybe<reverb_search_Filter_WidgetType>;
};

export type reverb_search_FilterOption = {
  __typename?: "reverb_search_FilterOption";
  _source?: Maybe<Scalars["String"]["output"]>;
  all?: Maybe<Scalars["Boolean"]["output"]>;
  autoselected?: Maybe<Scalars["Boolean"]["output"]>;
  count?: Maybe<google_protobuf_Int32Value>;
  name?: Maybe<Scalars["String"]["output"]>;
  optionValue?: Maybe<Scalars["String"]["output"]>;
  paramName?: Maybe<Scalars["String"]["output"]>;
  selected?: Maybe<Scalars["Boolean"]["output"]>;
  setValues?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
  subFilter?: Maybe<reverb_search_Filter>;
  trackingValue?: Maybe<Scalars["String"]["output"]>;
  unsetValues?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
  urlParamName?: Maybe<Scalars["String"]["output"]>;
};

export enum reverb_search_Filter_WidgetType {
  CHECKBOX = "CHECKBOX",
  NESTED_SELECT = "NESTED_SELECT",
  NONE = "NONE",
  RADIO = "RADIO",
  RANGE = "RANGE",
  REGION_SELECT = "REGION_SELECT",
  SORT = "SORT",
  TEXT = "TEXT",
}

export enum reverb_search_FullTextQueryOperand {
  AND = "AND",
  OR = "OR",
}

export type reverb_search_GearCollectionItemResponse = {
  __typename?: "reverb_search_GearCollectionItemResponse";
  _source?: Maybe<Scalars["String"]["output"]>;
  id?: Maybe<Scalars["String"]["output"]>;
  priceEstimate?: Maybe<reverb_search_PriceEstimate>;
};

export type reverb_search_GearCollectionItemsSearchRequest = {
  __typename?: "reverb_search_GearCollectionItemsSearchRequest";
  _source?: Maybe<Scalars["String"]["output"]>;
  canonicalProductIds?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
  categorySlugs?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
  categoryUuids?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
  countryCode?: Maybe<Scalars["String"]["output"]>;
  currency?: Maybe<Scalars["String"]["output"]>;
  ids?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
  limit?: Maybe<Scalars["Int"]["output"]>;
  locale?: Maybe<Scalars["String"]["output"]>;
  offset?: Maybe<Scalars["Int"]["output"]>;
  states?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
  tags?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
  userUuids?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
  withAggregations?: Maybe<
    Array<Maybe<reverb_search_GearCollectionItemsSearchRequest_Aggregation>>
  >;
  withCollectionStats?: Maybe<Scalars["Boolean"]["output"]>;
};

export enum reverb_search_GearCollectionItemsSearchRequest_Aggregation {
  CATEGORY_UUIDS = "CATEGORY_UUIDS",
  TAGS = "TAGS",
}

export type reverb_search_GearCollectionStats = {
  __typename?: "reverb_search_GearCollectionStats";
  _source?: Maybe<Scalars["String"]["output"]>;
  totalCollectionEstimate?: Maybe<reverb_search_GearCollectionTotalEstimate>;
};

export type reverb_search_GearCollectionTotalEstimate = {
  __typename?: "reverb_search_GearCollectionTotalEstimate";
  _source?: Maybe<Scalars["String"]["output"]>;
  totalEstimateHigh?: Maybe<reverb_search_Money>;
  totalEstimateLow?: Maybe<reverb_search_Money>;
};

export type reverb_search_Inventory = {
  __typename?: "reverb_search_Inventory";
  _source?: Maybe<Scalars["String"]["output"]>;
  listingCount?: Maybe<Scalars["Int"]["output"]>;
  listingMinPrice?: Maybe<reverb_search_Money>;
};

export type reverb_search_LPAlbumSearchRequest = {
  __typename?: "reverb_search_LPAlbumSearchRequest";
  _source?: Maybe<Scalars["String"]["output"]>;
  artistSlug?: Maybe<Scalars["String"]["output"]>;
  artistUuid?: Maybe<Scalars["String"]["output"]>;
  ids?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
  limit?: Maybe<Scalars["Int"]["output"]>;
  offset?: Maybe<Scalars["Int"]["output"]>;
  slugs?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
  suggest?: Maybe<Scalars["String"]["output"]>;
  withInventory?: Maybe<Scalars["Boolean"]["output"]>;
};

export type reverb_search_LPArtistSearchRequest = {
  __typename?: "reverb_search_LPArtistSearchRequest";
  _source?: Maybe<Scalars["String"]["output"]>;
  limit?: Maybe<Scalars["Int"]["output"]>;
  offset?: Maybe<Scalars["Int"]["output"]>;
  suggest?: Maybe<Scalars["String"]["output"]>;
};

export type reverb_search_LPCompletionsRequest = {
  __typename?: "reverb_search_LPCompletionsRequest";
  _source?: Maybe<Scalars["String"]["output"]>;
  query?: Maybe<Scalars["String"]["output"]>;
};

export type reverb_search_LPLabelSearchRequest = {
  __typename?: "reverb_search_LPLabelSearchRequest";
  _source?: Maybe<Scalars["String"]["output"]>;
  limit?: Maybe<Scalars["Int"]["output"]>;
  offset?: Maybe<Scalars["Int"]["output"]>;
  suggest?: Maybe<Scalars["String"]["output"]>;
};

export type reverb_search_LPListingsSearchRequest = {
  __typename?: "reverb_search_LPListingsSearchRequest";
  _source?: Maybe<Scalars["String"]["output"]>;
  acceptsDirectCheckout?: Maybe<Scalars["Boolean"]["output"]>;
  acceptsOffers?: Maybe<Scalars["Boolean"]["output"]>;
  albumSlug?: Maybe<Scalars["String"]["output"]>;
  auctions?: Maybe<Scalars["Boolean"]["output"]>;
  condition?: Maybe<reverb_search_LPListingsSearchRequest_Condition>;
  country?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
  currency?: Maybe<Scalars["String"]["output"]>;
  excludeIds?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
  format?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
  ids?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
  includeBestListings?: Maybe<Scalars["Boolean"]["output"]>;
  itemRegion?: Maybe<Scalars["String"]["output"]>;
  lengths?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
  limit?: Maybe<Scalars["Int"]["output"]>;
  mediaCondition?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
  noAuctions?: Maybe<Scalars["Boolean"]["output"]>;
  offset?: Maybe<Scalars["Int"]["output"]>;
  onSale?: Maybe<Scalars["Boolean"]["output"]>;
  priceMaxCents?: Maybe<Scalars["Int"]["output"]>;
  priceMinCents?: Maybe<Scalars["Int"]["output"]>;
  priceRange?: Maybe<Scalars["String"]["output"]>;
  releaseDecades?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
  releaseSlug?: Maybe<Scalars["String"]["output"]>;
  releaseUuid?: Maybe<Scalars["String"]["output"]>;
  shippingRegion?: Maybe<Scalars["String"]["output"]>;
  sidebarSearch?: Maybe<Scalars["String"]["output"]>;
  sleeveCondition?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
  sort?: Maybe<Scalars["String"]["output"]>;
  storefrontSlug?: Maybe<Scalars["String"]["output"]>;
};

export enum reverb_search_LPListingsSearchRequest_Condition {
  NEW = "NEW",
  NONE = "NONE",
  USED = "USED",
}

export type reverb_search_LPReleasesSearchRequest = {
  __typename?: "reverb_search_LPReleasesSearchRequest";
  _source?: Maybe<Scalars["String"]["output"]>;
  countries?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
  formatUuids?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
  fullTextQuery?: Maybe<Scalars["String"]["output"]>;
  limit?: Maybe<Scalars["Int"]["output"]>;
  masterUuid?: Maybe<Scalars["String"]["output"]>;
  offset?: Maybe<Scalars["Int"]["output"]>;
  releaseDecades?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
  sort?: Maybe<Scalars["String"]["output"]>;
  withAggregations?: Maybe<
    Array<Maybe<reverb_search_LPReleasesSearchRequest_Aggregation>>
  >;
  withInventory?: Maybe<Scalars["Boolean"]["output"]>;
  withLimitedAggregations?: Maybe<reverb_search_LimitedAggregation>;
};

export enum reverb_search_LPReleasesSearchRequest_Aggregation {
  COUNTRIES = "COUNTRIES",
  FORMATS = "FORMATS",
  INVENTORY = "INVENTORY",
}

export type reverb_search_LPTransactionsSearchRequest = {
  __typename?: "reverb_search_LPTransactionsSearchRequest";
  _source?: Maybe<Scalars["String"]["output"]>;
  actionableStatuses?: Maybe<
    Array<Maybe<reverb_search_LPTransactionsSearchRequest_ActionableStatus>>
  >;
  createdAfter?: Maybe<Scalars["String"]["output"]>;
  createdBefore?: Maybe<Scalars["String"]["output"]>;
  filterPurchased?: Maybe<Scalars["Boolean"]["output"]>;
  releaseUuids?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
  withAggregations?: Maybe<
    Array<Maybe<reverb_search_LPTransactionsSearchRequest_Aggregation>>
  >;
  withLimitedAggregations?: Maybe<reverb_search_LimitedAggregation>;
};

export enum reverb_search_LPTransactionsSearchRequest_ActionableStatus {
  AWAITING_PICKUP = "AWAITING_PICKUP",
  AWAITING_SHIPMENT = "AWAITING_SHIPMENT",
  CANCELLED = "CANCELLED",
  LAYAWAY = "LAYAWAY",
  LAYAWAY_FORFEITED = "LAYAWAY_FORFEITED",
  PAYMENT_PENDING = "PAYMENT_PENDING",
  PICKED_UP = "PICKED_UP",
  PREORDER = "PREORDER",
  RECEIVED = "RECEIVED",
  REFUNDED = "REFUNDED",
  REFUND_APPROVED = "REFUND_APPROVED",
  REFUND_REQUESTED = "REFUND_REQUESTED",
  SHIPPED = "SHIPPED",
  UNPAID = "UNPAID",
}

export enum reverb_search_LPTransactionsSearchRequest_Aggregation {
  ACTIONABLE_STATUSES = "ACTIONABLE_STATUSES",
  PRICE_PERCENTILES = "PRICE_PERCENTILES",
  PRICE_PERCENTILES_BY_MEDIA_CONDITION = "PRICE_PERCENTILES_BY_MEDIA_CONDITION",
}

export type reverb_search_LimitedAggregation = {
  __typename?: "reverb_search_LimitedAggregation";
  _source?: Maybe<Scalars["String"]["output"]>;
  brandUuids?: Maybe<Scalars["Int"]["output"]>;
  categoryUuids?: Maybe<Scalars["Int"]["output"]>;
};

export type reverb_search_Link = {
  __typename?: "reverb_search_Link";
  _source?: Maybe<Scalars["String"]["output"]>;
  href?: Maybe<Scalars["String"]["output"]>;
  /** rel path for use in web routing */
  path?: Maybe<Scalars["String"]["output"]>;
};

export enum reverb_search_ListingItemRegionRelation {
  INCLUDE_TRADE_REGIONS = "INCLUDE_TRADE_REGIONS",
  ITEM_REGION_ONLY = "ITEM_REGION_ONLY",
}

export type reverb_search_ListingRecommendationStats = {
  __typename?: "reverb_search_ListingRecommendationStats";
  _source?: Maybe<Scalars["String"]["output"]>;
  totalGreaterThanCompetitivePrice?: Maybe<Scalars["Int"]["output"]>;
  totalWithWatchersAndWithoutAutoOffers?: Maybe<Scalars["Int"]["output"]>;
};

export type reverb_search_ListingRecommendationsRequest = {
  __typename?: "reverb_search_ListingRecommendationsRequest";
  _source?: Maybe<Scalars["String"]["output"]>;
  brandCategories?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
  /** @deprecated Field no longer supported */
  mparticleProfileResponse?: Maybe<reverb_search_MparticleProfileResponse>;
};

export type reverb_search_ListingsAggregationResponse = {
  __typename?: "reverb_search_ListingsAggregationResponse";
  _source?: Maybe<Scalars["String"]["output"]>;
  aggregationResults?: Maybe<Array<Maybe<reverb_search_AggregationResult>>>;
  shops?: Maybe<Array<Maybe<Shop>>>;
  topCollections?: Maybe<reverb_search_TopCollectionsResult>;
};

export type reverb_search_ListingsPercolationQueryRequest = {
  __typename?: "reverb_search_ListingsPercolationQueryRequest";
  _source?: Maybe<Scalars["String"]["output"]>;
  savedSearchQueryParams?: Maybe<Scalars["String"]["output"]>;
};

export type reverb_search_ListingsPercolationQueryResponse = {
  __typename?: "reverb_search_ListingsPercolationQueryResponse";
  _source?: Maybe<Scalars["String"]["output"]>;
  esQuery?: Maybe<Scalars["String"]["output"]>;
};

export type reverb_search_ListingsSearchRequest = {
  __typename?: "reverb_search_ListingsSearchRequest";
  _source?: Maybe<Scalars["String"]["output"]>;
  acceptsAffirm?: Maybe<Scalars["Boolean"]["output"]>;
  acceptsAutoOffers?: Maybe<Scalars["Boolean"]["output"]>;
  acceptsBuyerOffers?: Maybe<Scalars["Boolean"]["output"]>;
  acceptsGiftCards?: Maybe<Scalars["Boolean"]["output"]>;
  acceptsOffers?: Maybe<Scalars["Boolean"]["output"]>;
  acceptsPaymentPlans?: Maybe<Scalars["Boolean"]["output"]>;
  aggregationsOnly?: Maybe<Scalars["Boolean"]["output"]>;
  applyProximityBoost?: Maybe<Scalars["Boolean"]["output"]>;
  autodirects?: Maybe<reverb_search_Autodirects>;
  /** @deprecated Field no longer supported */
  bestMatchSignalsV2ExperimentGroup?: Maybe<Scalars["Int"]["output"]>;
  boostByBumpRate?: Maybe<Scalars["Boolean"]["output"]>;
  boostCombinedShippingCategories?: Maybe<
    Array<Maybe<Scalars["String"]["output"]>>
  >;
  boostedItemRegionCode?: Maybe<Scalars["String"]["output"]>;
  brandSlugs?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
  brandUuids?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
  bumpCount?: Maybe<Scalars["Int"]["output"]>;
  bumpedOnly?: Maybe<Scalars["Boolean"]["output"]>;
  bumpedSortedListingsQuery?: Maybe<reverb_search_BumpedSortedListingsQuery>;
  calculatedRate?: Maybe<Scalars["Boolean"]["output"]>;
  canonicalFinishes?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
  canonicalProductIds?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
  categorySlugs?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
  categoryUuids?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
  collapsible?: Maybe<reverb_search_ListingsSearchRequest_Collapsible>;
  combinedShipping?: Maybe<Scalars["Boolean"]["output"]>;
  conditionSlugs?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
  conditionUuids?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
  contexts?: Maybe<Array<Maybe<reverb_search_Context>>>;
  countryOfOrigin?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
  cspId?: Maybe<Scalars["String"]["output"]>;
  cspSlug?: Maybe<Scalars["String"]["output"]>;
  curatedSetId?: Maybe<Scalars["String"]["output"]>;
  curatedSetSlugs?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
  currency?: Maybe<Scalars["String"]["output"]>;
  dealsAndSteals?: Maybe<Scalars["Boolean"]["output"]>;
  dealsOrWellPriced?: Maybe<Scalars["Boolean"]["output"]>;
  decades?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
  /** @deprecated Field no longer supported */
  ecsBoostExperimentGroup?: Maybe<Scalars["Int"]["output"]>;
  eligibleForSales?: Maybe<Scalars["Boolean"]["output"]>;
  /** @deprecated Field no longer supported */
  es7?: Maybe<Scalars["Boolean"]["output"]>;
  excludeBrandSlugs?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
  excludeCarrierCalculated?: Maybe<Scalars["Boolean"]["output"]>;
  excludeCategoryUuids?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
  excludeCuratedSets?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
  excludeLocalPickupOnly?: Maybe<Scalars["Boolean"]["output"]>;
  excludeMerchandisingTypes?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
  excludePreorders?: Maybe<Scalars["Boolean"]["output"]>;
  excludeShopIds?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
  excludeStale?: Maybe<Scalars["Boolean"]["output"]>;
  experiments?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
  fallbackToEverywhereElse?: Maybe<Scalars["Boolean"]["output"]>;
  fallbackToOr?: Maybe<Scalars["Boolean"]["output"]>;
  filterSuperRegionCode?: Maybe<Scalars["String"]["output"]>;
  financingCuratedSetId?: Maybe<Scalars["String"]["output"]>;
  flatRate?: Maybe<Scalars["Boolean"]["output"]>;
  freeExpeditedShipping?: Maybe<Scalars["Boolean"]["output"]>;
  freeShipping?: Maybe<Scalars["Boolean"]["output"]>;
  greatValue?: Maybe<Scalars["Boolean"]["output"]>;
  greatValueForUsedListings?: Maybe<Scalars["Boolean"]["output"]>;
  handmade?: Maybe<Scalars["Boolean"]["output"]>;
  hasWatchers?: Maybe<Scalars["Boolean"]["output"]>;
  holidaySale?: Maybe<Scalars["Boolean"]["output"]>;
  ids?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
  inBuyerCarts?: Maybe<Scalars["Boolean"]["output"]>;
  includeDrafts?: Maybe<Scalars["Boolean"]["output"]>;
  includeQuerySpellCorrections?: Maybe<Scalars["Boolean"]["output"]>;
  isUserInCspOptimizationExp?: Maybe<Scalars["Boolean"]["output"]>;
  itemCity?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
  itemRegion?: Maybe<Scalars["String"]["output"]>;
  itemRegionRelation?: Maybe<reverb_search_ListingItemRegionRelation>;
  itemState?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
  /** @deprecated Field no longer supported */
  likelihoodToSellExperimentGroup?: Maybe<Scalars["Int"]["output"]>;
  limit?: Maybe<Scalars["Int"]["output"]>;
  listingRecommendationsVersion?: Maybe<Scalars["Int"]["output"]>;
  localPickup?: Maybe<Scalars["Boolean"]["output"]>;
  locale?: Maybe<Scalars["String"]["output"]>;
  minSaleDiscountPercent?: Maybe<Scalars["String"]["output"]>;
  /** @deprecated Field no longer supported */
  mpTotalPriceExperimentGroup?: Maybe<Scalars["Int"]["output"]>;
  mpid?: Maybe<Scalars["String"]["output"]>;
  multiClientExperiments?: Maybe<Array<Maybe<reverb_search_ExperimentData>>>;
  noAutoOffers?: Maybe<Scalars["Boolean"]["output"]>;
  noBuyerOffers?: Maybe<Scalars["Boolean"]["output"]>;
  notGreatValue?: Maybe<Scalars["Boolean"]["output"]>;
  notGreatValueForUsedListings?: Maybe<Scalars["Boolean"]["output"]>;
  notMatchedToCsp?: Maybe<Scalars["Boolean"]["output"]>;
  offset?: Maybe<Scalars["Int"]["output"]>;
  onSale?: Maybe<Scalars["Boolean"]["output"]>;
  onlyStale?: Maybe<Scalars["Boolean"]["output"]>;
  outlet?: Maybe<Scalars["Boolean"]["output"]>;
  preferredSeller?: Maybe<Scalars["Boolean"]["output"]>;
  priceMax?: Maybe<Scalars["String"]["output"]>;
  priceMin?: Maybe<Scalars["String"]["output"]>;
  priceValue?: Maybe<reverb_search_ListingsSearchRequest_PriceValue>;
  priceValueV2?: Maybe<reverb_search_ListingsSearchRequest_PriceValue>;
  proximity?: Maybe<reverb_search_ProximityRequest>;
  query?: Maybe<Scalars["String"]["output"]>;
  /** @deprecated Field no longer supported */
  querylessBumpBoost?: Maybe<Scalars["Boolean"]["output"]>;
  randomSeed?: Maybe<Scalars["Int"]["output"]>;
  saleCuratedSetId?: Maybe<Scalars["String"]["output"]>;
  saleSlugs?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
  searchUrlParams?: Maybe<Scalars["String"]["output"]>;
  shippingProfileId?: Maybe<Scalars["String"]["output"]>;
  shippingRegionCodes?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
  shipsInternationally?: Maybe<Scalars["Boolean"]["output"]>;
  shipsToMe?: Maybe<Scalars["Boolean"]["output"]>;
  shopId?: Maybe<Scalars["String"]["output"]>;
  shopRegionCode?: Maybe<Scalars["String"]["output"]>;
  shopSlug?: Maybe<Scalars["String"]["output"]>;
  shopSlugs?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
  shopUuids?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
  showBestPriceListingsSort?: Maybe<Scalars["Boolean"]["output"]>;
  showLikelihoodToSellSort?: Maybe<Scalars["Boolean"]["output"]>;
  showMostWatchedListingsSort?: Maybe<Scalars["Boolean"]["output"]>;
  showOnlySold?: Maybe<Scalars["Boolean"]["output"]>;
  showSold?: Maybe<Scalars["Boolean"]["output"]>;
  similarListingIds?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
  skipAutodirects?: Maybe<Scalars["Boolean"]["output"]>;
  sort?: Maybe<reverb_search_ListingsSearchRequest_Sort>;
  sortSlug?: Maybe<Scalars["String"]["output"]>;
  statuses?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
  terminateEarly?: Maybe<Scalars["Boolean"]["output"]>;
  trackTotalHits?: Maybe<reverb_search_TrackTotalHits>;
  traitSlugs?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
  traitValues?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
  /** @deprecated Field no longer supported */
  useExperimentalBestMatchSignalsV1?: Maybe<Scalars["Boolean"]["output"]>;
  /** @deprecated Field no longer supported */
  useExperimentalQuery?: Maybe<Scalars["Boolean"]["output"]>;
  useExperimentalRecall?: Maybe<Scalars["Boolean"]["output"]>;
  /** @deprecated Field no longer supported */
  useExperimentalRegionBoost?: Maybe<Scalars["Boolean"]["output"]>;
  /** @deprecated Field no longer supported */
  useTotalPrice?: Maybe<Scalars["Boolean"]["output"]>;
  withAggregations?: Maybe<
    Array<Maybe<reverb_search_ListingsSearchRequest_Aggregation>>
  >;
  withLimitedAggregations?: Maybe<reverb_search_LimitedAggregation>;
  withListingRecommendationStats?: Maybe<Scalars["Boolean"]["output"]>;
  withMyShopAggregations?: Maybe<
    Array<Maybe<reverb_search_ListingsSearchRequest_MyShopAggregation>>
  >;
  /** Deprecated */
  withPageMetadata?: Maybe<reverb_search_PageMetaDataRequest>;
  withProximityFilter?: Maybe<reverb_search_ProximityFilterRequest>;
  withTopCollections?: Maybe<reverb_search_TopCollectionsRequest>;
  yearMax?: Maybe<Scalars["String"]["output"]>;
  yearMin?: Maybe<Scalars["String"]["output"]>;
  zeroPercentFinancingEligible?: Maybe<Scalars["Boolean"]["output"]>;
};

export enum reverb_search_ListingsSearchRequest_Aggregation {
  BRAND_SLUGS = "BRAND_SLUGS",
  BRAND_UUIDS = "BRAND_UUIDS",
  /** deprecating this in favor of the more generic BUMP_RATE_RECOMMENDATIONS */
  BUMP_RATE_PERCENTILES = "BUMP_RATE_PERCENTILES",
  BUMP_RATE_RECOMMENDATIONS = "BUMP_RATE_RECOMMENDATIONS",
  CANONICAL_FINISH = "CANONICAL_FINISH",
  CATEGORY_SLUGS = "CATEGORY_SLUGS",
  CATEGORY_UUIDS = "CATEGORY_UUIDS",
  CONDITION_SLUGS = "CONDITION_SLUGS",
  COUNTRY_OF_ORIGIN = "COUNTRY_OF_ORIGIN",
  CURATED_SETS = "CURATED_SETS",
  DECADES = "DECADES",
  FIND_A_DEAL = "FIND_A_DEAL",
  SALE_DISCOUNT_PERCENT = "SALE_DISCOUNT_PERCENT",
  SHOP_ID = "SHOP_ID",
  TRAITS = "TRAITS",
}

export enum reverb_search_ListingsSearchRequest_Collapsible {
  CANONICAL_PRODUCT_ID = "CANONICAL_PRODUCT_ID",
  COLLAPSIBLE_NONE = "COLLAPSIBLE_NONE",
  CSP_NEW_CONDITION = "CSP_NEW_CONDITION",
  CSP_NEW_CONDITION_SINGLE_FINISH = "CSP_NEW_CONDITION_SINGLE_FINISH",
}

export enum reverb_search_ListingsSearchRequest_MyShopAggregation {
  STATES = "STATES",
}

export enum reverb_search_ListingsSearchRequest_PriceValue {
  GOOD = "GOOD",
  GREAT = "GREAT",
  PRICE_VALUE_NONE = "PRICE_VALUE_NONE",
}

export enum reverb_search_ListingsSearchRequest_Sort {
  BUMPED_FIRST = "BUMPED_FIRST",
  /** not an actual sort, used as pivot point in RQL */
  BUMP_SCORE = "BUMP_SCORE",
  CREATED_AT_ASC = "CREATED_AT_ASC",
  CREATED_AT_DESC = "CREATED_AT_DESC",
  CURATED = "CURATED",
  CURATED_DATE_ADDED = "CURATED_DATE_ADDED",
  /** @deprecated Field no longer supported */
  EXPERIMENTAL_BUMP_RANKING = "EXPERIMENTAL_BUMP_RANKING",
  FILTERED_IDS = "FILTERED_IDS",
  LIKELIHOOD_TO_SELL = "LIKELIHOOD_TO_SELL",
  /** @deprecated Field no longer supported */
  LQS_DESCENDING = "LQS_DESCENDING",
  NONE = "NONE",
  PRICE_ASC = "PRICE_ASC",
  PRICE_DESC = "PRICE_DESC",
  PRICE_IN_COUNTRY = "PRICE_IN_COUNTRY",
  PRICE_VALUE_SCORE_ASC = "PRICE_VALUE_SCORE_ASC",
  PRICE_WITH_SALE_ASC = "PRICE_WITH_SALE_ASC",
  PRICE_WITH_SALE_DESC = "PRICE_WITH_SALE_DESC",
  /** @deprecated Field no longer supported */
  PRICING_SCORE = "PRICING_SCORE",
  PROXIMITY_ASC = "PROXIMITY_ASC",
  PUBLISHED_AT_ASC = "PUBLISHED_AT_ASC",
  PUBLISHED_AT_DESC = "PUBLISHED_AT_DESC",
  RANDOM = "RANDOM",
  RECENT_WATCHERS_COUNT_DESC = "RECENT_WATCHERS_COUNT_DESC",
  RETURN_WINDOW = "RETURN_WINDOW",
  /** @deprecated Field no longer supported */
  RETURN_WINDOW_PRICE_DESC = "RETURN_WINDOW_PRICE_DESC",
  WATCHERS_COUNT_ASC = "WATCHERS_COUNT_ASC",
  WATCHERS_COUNT_DESC = "WATCHERS_COUNT_DESC",
}

export type reverb_search_ListingsSearchResult = {
  __typename?: "reverb_search_ListingsSearchResult";
  _source?: Maybe<Scalars["String"]["output"]>;
  fieldKeys?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
  fieldValues?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
  id?: Maybe<Scalars["String"]["output"]>;
};

export type reverb_search_Location = {
  __typename?: "reverb_search_Location";
  _source?: Maybe<Scalars["String"]["output"]>;
  type?: Maybe<reverb_search_Location_LocationType>;
  value?: Maybe<Scalars["String"]["output"]>;
};

export enum reverb_search_Location_LocationType {
  POSTAL_CODE = "POSTAL_CODE",
}

export type reverb_search_MeResponse = {
  __typename?: "reverb_search_MeResponse";
  _source?: Maybe<Scalars["String"]["output"]>;
  admin?: Maybe<Scalars["Boolean"]["output"]>;
  /** users.id */
  id?: Maybe<Scalars["String"]["output"]>;
  requireOptIn?: Maybe<Scalars["Boolean"]["output"]>;
  shopId?: Maybe<Scalars["String"]["output"]>;
  shopSlug?: Maybe<Scalars["String"]["output"]>;
  shopUuid?: Maybe<Scalars["String"]["output"]>;
  status?: Maybe<reverb_search_UserStatus>;
  uuid?: Maybe<Scalars["String"]["output"]>;
};

export type reverb_search_Money = {
  __typename?: "reverb_search_Money";
  _source?: Maybe<Scalars["String"]["output"]>;
  amount?: Maybe<Scalars["String"]["output"]>;
  /** carries same data as `cents`, but uses field naming expected by frontend/core money helpers */
  amountCents?: Maybe<Scalars["Int"]["output"]>;
  cents?: Maybe<Scalars["Float"]["output"]>;
  currency?: Maybe<Scalars["String"]["output"]>;
  display?: Maybe<Scalars["String"]["output"]>;
  symbol?: Maybe<Scalars["String"]["output"]>;
};

export type reverb_search_MparticleProfileResponse = {
  __typename?: "reverb_search_MparticleProfileResponse";
  _source?: Maybe<Scalars["String"]["output"]>;
  averageOrderValue?: Maybe<Scalars["Float"]["output"]>;
  brandCategories?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
  buyerFeedbackRating?: Maybe<Scalars["Float"]["output"]>;
  buyerLabel?: Maybe<Scalars["String"]["output"]>;
  cumulativeGmv?: Maybe<Scalars["Float"]["output"]>;
  firstOrderDate?: Maybe<Scalars["String"]["output"]>;
  mostRecentOrderDate?: Maybe<Scalars["String"]["output"]>;
  mpid?: Maybe<Scalars["String"]["output"]>;
  recentlyViewedCsps?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
  sellerFeedbackRating?: Maybe<Scalars["Float"]["output"]>;
  sellerLabel?: Maybe<Scalars["String"]["output"]>;
  topBrandCategories?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
  topBrands?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
  topCategories?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
  topCategoryUuids?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
  topMostRecentProductTypes?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
  topProductType?: Maybe<Scalars["String"]["output"]>;
  topProductTypeUuids?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
  topProductTypes?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
  totalBuyerOffersAccepted?: Maybe<Scalars["Int"]["output"]>;
  totalBuyerOffersCreated?: Maybe<Scalars["Int"]["output"]>;
  totalFeedFollows?: Maybe<Scalars["Int"]["output"]>;
  totalListingsCreated?: Maybe<Scalars["Int"]["output"]>;
  totalListingsDraft?: Maybe<Scalars["Int"]["output"]>;
  totalListingsLive?: Maybe<Scalars["Int"]["output"]>;
  totalListingsSold?: Maybe<Scalars["Int"]["output"]>;
  totalListingsSuspended?: Maybe<Scalars["Int"]["output"]>;
  totalPurchases?: Maybe<Scalars["Int"]["output"]>;
};

export type reverb_search_NegotiationSearchRequest = {
  __typename?: "reverb_search_NegotiationSearchRequest";
  _source?: Maybe<Scalars["String"]["output"]>;
  buyerUuids?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
  fullTextQuery?: Maybe<Scalars["String"]["output"]>;
  ids?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
  limit?: Maybe<Scalars["Int"]["output"]>;
  listingIds?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
  negotiationTypes?: Maybe<
    Array<Maybe<reverb_search_NegotiationSearchRequest_Type>>
  >;
  offset?: Maybe<Scalars["Int"]["output"]>;
  participantUuids?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
  sellerUuids?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
  sort?: Maybe<Scalars["String"]["output"]>;
  states?: Maybe<Array<Maybe<reverb_search_NegotiationSearchRequest_State>>>;
  withAggregations?: Maybe<
    Array<Maybe<reverb_search_NegotiationSearchRequest_Aggregation>>
  >;
};

export enum reverb_search_NegotiationSearchRequest_Aggregation {
  ACTIVE_SELLER_ACTION_REQUIRED = "ACTIVE_SELLER_ACTION_REQUIRED",
  CHANNELS = "CHANNELS",
  NEGOTIATION_TYPES = "NEGOTIATION_TYPES",
  SELLER_CHANNELS = "SELLER_CHANNELS",
  STATES = "STATES",
}

export enum reverb_search_NegotiationSearchRequest_State {
  ACCEPTED = "ACCEPTED",
  ACTIVE = "ACTIVE",
  EXPIRED = "EXPIRED",
  REJECTED = "REJECTED",
}

export enum reverb_search_NegotiationSearchRequest_Type {
  AUTO_PUSH_OFFER = "AUTO_PUSH_OFFER",
  EMAIL = "EMAIL",
  STANDARD = "STANDARD",
}

export type reverb_search_OrderMetrics = {
  __typename?: "reverb_search_OrderMetrics";
  _source?: Maybe<Scalars["String"]["output"]>;
  averageProductPriceCentsUsd?: Maybe<Scalars["Int"]["output"]>;
  orderCount?: Maybe<Scalars["Int"]["output"]>;
  topCsps?: Maybe<Array<Maybe<reverb_search_TopCSP>>>;
  totalAmountProductCentsUsd?: Maybe<Scalars["Int"]["output"]>;
  totalAmountProductCentsUsdDouble?: Maybe<Scalars["Float"]["output"]>;
  totalBumpSpendAmount?: Maybe<reverb_search_Money>;
  totalSellerRevenueAmount?: Maybe<reverb_search_Money>;
};

export type reverb_search_OrderSearchRequest = {
  __typename?: "reverb_search_OrderSearchRequest";
  _source?: Maybe<Scalars["String"]["output"]>;
  actionableStatusGroup?: Maybe<Scalars["String"]["output"]>;
  actionableStatuses?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
  aggregationsOnly?: Maybe<Scalars["Boolean"]["output"]>;
  averageMonthlyProductPricesCurrency?: Maybe<Scalars["String"]["output"]>;
  brandSlugs?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
  buyerUuid?: Maybe<Scalars["String"]["output"]>;
  canonicalProductIds?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
  categorySlugs?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
  categoryUuids?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
  checkoutUuid?: Maybe<Scalars["String"]["output"]>;
  conditionSlugs?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
  createdAfterDate?: Maybe<Scalars["String"]["output"]>;
  createdBeforeDate?: Maybe<Scalars["String"]["output"]>;
  cspSlugs?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
  expressSaleItemEvaluation?: Maybe<Scalars["String"]["output"]>;
  fullTextQuery?: Maybe<Scalars["String"]["output"]>;
  limit?: Maybe<Scalars["Int"]["output"]>;
  listingCategoryUuids?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
  listingConditionSlugs?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
  listingConditionUuids?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
  listingId?: Maybe<Scalars["String"]["output"]>;
  maxAmountProductCentsUsd?: Maybe<Scalars["String"]["output"]>;
  minAmountProductCentsUsd?: Maybe<Scalars["String"]["output"]>;
  offset?: Maybe<Scalars["Int"]["output"]>;
  orderType?: Maybe<Scalars["String"]["output"]>;
  searchUrlParams?: Maybe<Scalars["String"]["output"]>;
  sellerCountries?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
  sellerUuid?: Maybe<Scalars["String"]["output"]>;
  shippingMethods?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
  shopCurrency?: Maybe<Scalars["String"]["output"]>;
  soldWithBumpOnly?: Maybe<Scalars["Boolean"]["output"]>;
  soldWithoutBumpOnly?: Maybe<Scalars["Boolean"]["output"]>;
  withAggregations?: Maybe<
    Array<Maybe<reverb_search_OrderSearchRequest_Aggregation>>
  >;
  withAverageMonthlyProductPricesAggregations?: Maybe<
    Scalars["Boolean"]["output"]
  >;
  withGearCollectionItemOnly?: Maybe<Scalars["Boolean"]["output"]>;
  withMetricAggregations?: Maybe<Scalars["Boolean"]["output"]>;
  withPriceStatsAggregations?: Maybe<Scalars["Boolean"]["output"]>;
  withoutGearCollectionItemOnly?: Maybe<Scalars["Boolean"]["output"]>;
};

export enum reverb_search_OrderSearchRequest_Aggregation {
  ACTIONABLE_STATUS_GROUPS = "ACTIONABLE_STATUS_GROUPS",
  BRAND_SLUGS = "BRAND_SLUGS",
  CATEGORY_SLUGS = "CATEGORY_SLUGS",
  CONDITION_SLUGS = "CONDITION_SLUGS",
  SHIPPING_METHODS = "SHIPPING_METHODS",
}

/** Deprecated */
export type reverb_search_PageMetaDataRequest = {
  __typename?: "reverb_search_PageMetaDataRequest";
  _source?: Maybe<Scalars["String"]["output"]>;
  excludeBrands?: Maybe<Scalars["Boolean"]["output"]>;
  excludeCategories?: Maybe<Scalars["Boolean"]["output"]>;
};

export type reverb_search_Percentile = {
  __typename?: "reverb_search_Percentile";
  _source?: Maybe<Scalars["String"]["output"]>;
  count?: Maybe<Scalars["Int"]["output"]>;
  key?: Maybe<Scalars["String"]["output"]>;
  p1?: Maybe<Scalars["Float"]["output"]>;
  p5?: Maybe<Scalars["Float"]["output"]>;
  p25?: Maybe<Scalars["Float"]["output"]>;
  p50?: Maybe<Scalars["Float"]["output"]>;
  p75?: Maybe<Scalars["Float"]["output"]>;
  p95?: Maybe<Scalars["Float"]["output"]>;
  p99?: Maybe<Scalars["Float"]["output"]>;
};

export type reverb_search_PreprocessListingsSearchResponse = {
  __typename?: "reverb_search_PreprocessListingsSearchResponse";
  _source?: Maybe<Scalars["String"]["output"]>;
  preprocessedListingsSearchRequest?: Maybe<reverb_search_ListingsSearchRequest>;
};

export type reverb_search_PriceEstimate = {
  __typename?: "reverb_search_PriceEstimate";
  _source?: Maybe<Scalars["String"]["output"]>;
  priceHigh?: Maybe<reverb_search_Money>;
  priceLow?: Maybe<reverb_search_Money>;
};

export type reverb_search_PriceGuidesSearchRequest = {
  __typename?: "reverb_search_PriceGuidesSearchRequest";
  _source?: Maybe<Scalars["String"]["output"]>;
  brandNames?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
  categorySlugs?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
  categoryUuids?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
  decades?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
  fullTextQuery?: Maybe<Scalars["String"]["output"]>;
  limit?: Maybe<Scalars["Int"]["output"]>;
  locale?: Maybe<Scalars["String"]["output"]>;
  offset?: Maybe<Scalars["Int"]["output"]>;
  withAggregations?: Maybe<
    Array<Maybe<reverb_search_PriceGuidesSearchRequest_Aggregation>>
  >;
  withLimitedAggregations?: Maybe<reverb_search_LimitedAggregation>;
};

export enum reverb_search_PriceGuidesSearchRequest_Aggregation {
  BRAND_NAMES = "BRAND_NAMES",
  CATEGORY_UUIDS = "CATEGORY_UUIDS",
  DECADES = "DECADES",
}

export type reverb_search_PriceStatsResult = {
  __typename?: "reverb_search_PriceStatsResult";
  _source?: Maybe<Scalars["String"]["output"]>;
  middle?: Maybe<reverb_search_Money>;
};

export type reverb_search_PriceStatsResults = {
  __typename?: "reverb_search_PriceStatsResults";
  _source?: Maybe<Scalars["String"]["output"]>;
  docCount?: Maybe<Scalars["Int"]["output"]>;
  publicPrice?: Maybe<reverb_search_PriceStatsResult>;
  purchasePrice?: Maybe<reverb_search_PriceStatsResult>;
};

export type reverb_search_ProductReviewStats = {
  __typename?: "reverb_search_ProductReviewStats";
  _source?: Maybe<Scalars["String"]["output"]>;
  averageRating?: Maybe<Scalars["Float"]["output"]>;
  ratingsDistribution?: Maybe<
    Array<Maybe<reverb_search_ProductReviewStats_RatingDistribution>>
  >;
  total?: Maybe<Scalars["Int"]["output"]>;
};

export type reverb_search_ProductReviewStats_RatingDistribution = {
  __typename?: "reverb_search_ProductReviewStats_RatingDistribution";
  _source?: Maybe<Scalars["String"]["output"]>;
  rating?: Maybe<Scalars["String"]["output"]>;
  reviewCount?: Maybe<Scalars["Int"]["output"]>;
};

export type reverb_search_ProductReviewsSearchRequest = {
  __typename?: "reverb_search_ProductReviewsSearchRequest";
  _source?: Maybe<Scalars["String"]["output"]>;
  canonicalProductIds?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
  comparisonShoppingPageIds?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
  fullTextQuery?: Maybe<Scalars["String"]["output"]>;
  ids?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
  limit?: Maybe<Scalars["Int"]["output"]>;
  offset?: Maybe<Scalars["Int"]["output"]>;
  ratings?: Maybe<
    Array<Maybe<reverb_search_ProductReviewsSearchRequest_Rating>>
  >;
  sort?: Maybe<reverb_search_ProductReviewsSearchRequest_Sort>;
  verified?: Maybe<Scalars["Boolean"]["output"]>;
  withAggregations?: Maybe<
    Array<Maybe<reverb_search_ProductReviewsSearchRequest_Aggregation>>
  >;
};

export enum reverb_search_ProductReviewsSearchRequest_Aggregation {
  RATING = "RATING",
}

export enum reverb_search_ProductReviewsSearchRequest_Rating {
  FIVE = "FIVE",
  FOUR = "FOUR",
  ONE = "ONE",
  THREE = "THREE",
  TWO = "TWO",
}

export enum reverb_search_ProductReviewsSearchRequest_Sort {
  CREATED_AT_ASC = "CREATED_AT_ASC",
  CREATED_AT_DESC = "CREATED_AT_DESC",
  ID_DESC = "ID_DESC",
  NONE = "NONE",
  RATING_ASC = "RATING_ASC",
  RATING_DESC = "RATING_DESC",
  UPDATED_AT_ASC = "UPDATED_AT_ASC",
  UPDATED_AT_DESC = "UPDATED_AT_DESC",
  VOTES_ASC = "VOTES_ASC",
  VOTES_DESC = "VOTES_DESC",
}

export type reverb_search_ProximityFilterRequest = {
  __typename?: "reverb_search_ProximityFilterRequest";
  _source?: Maybe<Scalars["String"]["output"]>;
  /** / Distance limit in miles. Both whole numbers and decimals are supported. */
  distance?: Maybe<Scalars["String"]["output"]>;
  /** / 5 digit US postal code */
  postalCode?: Maybe<Scalars["String"]["output"]>;
  /** / Whether the "distance from postal code" filter should be activated. `postal_code` is also used for `Sort.PROXIMITY_ASC`. While not mutually exclusive, we may want to sort but not filter. */
  proximity?: Maybe<Scalars["Boolean"]["output"]>;
};

export type reverb_search_ProximityRequest = {
  __typename?: "reverb_search_ProximityRequest";
  _source?: Maybe<Scalars["String"]["output"]>;
  /** / applies proximity filter.  Both distance and location params are required. */
  applyFilter?: Maybe<Scalars["Boolean"]["output"]>;
  /** / radius used for filter. */
  distance?: Maybe<reverb_search_Distance>;
  /** / used for both proximity filter and proximity sort. */
  location?: Maybe<reverb_search_Location>;
};

export type reverb_search_QuerySpellCorrection = {
  __typename?: "reverb_search_QuerySpellCorrection";
  _source?: Maybe<Scalars["String"]["output"]>;
  correction?: Maybe<Scalars["String"]["output"]>;
};

export type reverb_search_RPCSearch = {
  __typename?: "reverb_search_RPCSearch";
  AuctionsSearch?: Maybe<reverb_search_SearchResponse>;
  Autocomplete?: Maybe<reverb_search_AutocompleteResponse>;
  BatchListingsSearch?: Maybe<reverb_search_SearchResponse>;
  BatchMatchedListingsSearch?: Maybe<reverb_search_BatchMatchedListingsSearchResponse>;
  CMSPagesSearch?: Maybe<reverb_search_SearchResponse>;
  CSPSearch?: Maybe<reverb_search_SearchResponse>;
  Completions?: Maybe<reverb_search_SearchResponse>;
  GearCollectionItemsSearch?: Maybe<reverb_search_SearchResponse>;
  LPAlbumsSearch?: Maybe<reverb_search_SearchResponse>;
  LPArtistsSearch?: Maybe<reverb_search_SearchResponse>;
  LPCompletions?: Maybe<reverb_search_SearchResponse>;
  LPLabelsSearch?: Maybe<reverb_search_SearchResponse>;
  LPListingsSearch?: Maybe<reverb_search_SearchResponse>;
  LPReleasesSearch?: Maybe<reverb_search_SearchResponse>;
  LPTransactionsSearch?: Maybe<reverb_search_SearchResponse>;
  ListingRecommendations?: Maybe<reverb_search_SearchResponse>;
  ListingsAggregation?: Maybe<reverb_search_ListingsAggregationResponse>;
  ListingsPercolationQuery?: Maybe<reverb_search_ListingsPercolationQueryResponse>;
  ListingsSearch?: Maybe<reverb_search_SearchResponse>;
  NegotiationsSearch?: Maybe<reverb_search_SearchResponse>;
  OrdersSearch?: Maybe<reverb_search_SearchResponse>;
  PreprocessListingsSearch?: Maybe<reverb_search_PreprocessListingsSearchResponse>;
  PriceGuidesSearch?: Maybe<reverb_search_SearchResponse>;
  ProductReviewsSearch?: Maybe<reverb_search_SearchResponse>;
  ShippingRegionFilter?: Maybe<reverb_search_ShippingRegionFilterResponse>;
  SimilarListingsSearch?: Maybe<reverb_search_SearchResponse>;
  _source?: Maybe<Scalars["String"]["output"]>;
};

export type reverb_search_RPCSearchAuctionsSearchArgs = {
  input?: InputMaybe<Input_reverb_search_AuctionsSearchRequest>;
};

export type reverb_search_RPCSearchAutocompleteArgs = {
  input?: InputMaybe<Input_reverb_search_AutocompleteRequest>;
};

export type reverb_search_RPCSearchBatchListingsSearchArgs = {
  input?: InputMaybe<Input_reverb_search_BatchListingsSearchRequest>;
};

export type reverb_search_RPCSearchBatchMatchedListingsSearchArgs = {
  input?: InputMaybe<Input_reverb_search_BatchMatchedListingsSearchRequest>;
};

export type reverb_search_RPCSearchCMSPagesSearchArgs = {
  input?: InputMaybe<Input_reverb_search_CMSPagesSearchRequest>;
};

export type reverb_search_RPCSearchCSPSearchArgs = {
  input?: InputMaybe<Input_reverb_search_CSPSearchRequest>;
};

export type reverb_search_RPCSearchCompletionsArgs = {
  input?: InputMaybe<Input_reverb_search_CompletionsRequest>;
};

export type reverb_search_RPCSearchGearCollectionItemsSearchArgs = {
  input?: InputMaybe<Input_reverb_search_GearCollectionItemsSearchRequest>;
};

export type reverb_search_RPCSearchLPAlbumsSearchArgs = {
  input?: InputMaybe<Input_reverb_search_LPAlbumSearchRequest>;
};

export type reverb_search_RPCSearchLPArtistsSearchArgs = {
  input?: InputMaybe<Input_reverb_search_LPArtistSearchRequest>;
};

export type reverb_search_RPCSearchLPCompletionsArgs = {
  input?: InputMaybe<Input_reverb_search_LPCompletionsRequest>;
};

export type reverb_search_RPCSearchLPLabelsSearchArgs = {
  input?: InputMaybe<Input_reverb_search_LPLabelSearchRequest>;
};

export type reverb_search_RPCSearchLPListingsSearchArgs = {
  input?: InputMaybe<Input_reverb_search_LPListingsSearchRequest>;
};

export type reverb_search_RPCSearchLPReleasesSearchArgs = {
  input?: InputMaybe<Input_reverb_search_LPReleasesSearchRequest>;
};

export type reverb_search_RPCSearchLPTransactionsSearchArgs = {
  input?: InputMaybe<Input_reverb_search_LPTransactionsSearchRequest>;
};

export type reverb_search_RPCSearchListingRecommendationsArgs = {
  input?: InputMaybe<Input_reverb_search_ListingRecommendationsRequest>;
};

export type reverb_search_RPCSearchListingsAggregationArgs = {
  input?: InputMaybe<Input_reverb_search_ListingsSearchRequest>;
};

export type reverb_search_RPCSearchListingsPercolationQueryArgs = {
  input?: InputMaybe<Input_reverb_search_ListingsPercolationQueryRequest>;
};

export type reverb_search_RPCSearchListingsSearchArgs = {
  input?: InputMaybe<Input_reverb_search_ListingsSearchRequest>;
};

export type reverb_search_RPCSearchNegotiationsSearchArgs = {
  input?: InputMaybe<Input_reverb_search_NegotiationSearchRequest>;
};

export type reverb_search_RPCSearchOrdersSearchArgs = {
  input?: InputMaybe<Input_reverb_search_OrderSearchRequest>;
};

export type reverb_search_RPCSearchPreprocessListingsSearchArgs = {
  input?: InputMaybe<Input_reverb_search_ListingsSearchRequest>;
};

export type reverb_search_RPCSearchPriceGuidesSearchArgs = {
  input?: InputMaybe<Input_reverb_search_PriceGuidesSearchRequest>;
};

export type reverb_search_RPCSearchProductReviewsSearchArgs = {
  input?: InputMaybe<Input_reverb_search_ProductReviewsSearchRequest>;
};

export type reverb_search_RPCSearchShippingRegionFilterArgs = {
  input?: InputMaybe<Input_reverb_search_ShippingRegionFilterRequest>;
};

export type reverb_search_RPCSearchSimilarListingsSearchArgs = {
  input?: InputMaybe<Input_reverb_search_SimilarListingsSearchRequest>;
};

export type reverb_search_SearchResponse = {
  __typename?: "reverb_search_SearchResponse";
  _source?: Maybe<Scalars["String"]["output"]>;
  autodirected?: Maybe<Scalars["Boolean"]["output"]>;
  averageMonthlyProductPrices?: Maybe<
    Array<Maybe<reverb_search_AverageMonthlyProductPrice>>
  >;
  bestListings?: Maybe<Array<Maybe<reverb_search_BestListing>>>;
  bumpIds?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
  bumpRecommendations?: Maybe<reverb_search_BumpRecommendationsResults>;
  bumpedListings?: Maybe<Array<Maybe<Listing>>>;
  cmsPages?: Maybe<Array<Maybe<CMSPage>>>;
  completions?: Maybe<Array<Maybe<reverb_search_Completion>>>;
  csps?: Maybe<Array<Maybe<CSP>>>;
  dateBuckets?: Maybe<Array<Maybe<reverb_search_DateBucket>>>;
  eligibleAutodirects?: Maybe<Array<Maybe<reverb_search_Autodirects>>>;
  esScores?: Maybe<Array<Maybe<Scalars["Float"]["output"]>>>;
  fallbackListings?: Maybe<Array<Maybe<Listing>>>;
  fallbackResultIds?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
  filters?: Maybe<Array<Maybe<reverb_search_Filter>>>;
  gearCollectionItems?: Maybe<Array<Maybe<GearCollectionItem>>>;
  gearCollectionStats?: Maybe<reverb_search_GearCollectionStats>;
  limit?: Maybe<Scalars["Int"]["output"]>;
  listingRecommendationStats?: Maybe<reverb_search_ListingRecommendationStats>;
  listings?: Maybe<Array<Maybe<Listing>>>;
  /** @deprecated Field no longer supported */
  metadata?: Maybe<rql_PageMetadata>;
  negotiations?: Maybe<Array<Maybe<Negotiation>>>;
  offset?: Maybe<Scalars["Int"]["output"]>;
  orderMetrics?: Maybe<reverb_search_OrderMetrics>;
  orders?: Maybe<Array<Maybe<Order>>>;
  ordersForShipment?: Maybe<Array<Maybe<Order>>>;
  percentiles?: Maybe<Array<Maybe<reverb_search_Percentile>>>;
  priceGuides?: Maybe<Array<Maybe<PriceGuide>>>;
  priceRecords?: Maybe<Array<Maybe<PublicPriceRecord>>>;
  priceStats?: Maybe<reverb_search_PriceStatsResults>;
  productReviewStats?: Maybe<reverb_search_ProductReviewStats>;
  productReviews?: Maybe<Array<Maybe<ProductReview>>>;
  querySpellCorrections?: Maybe<
    Array<Maybe<reverb_search_QuerySpellCorrection>>
  >;
  resultIds?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
  resultUuids?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
  results?: Maybe<Array<Maybe<reverb_search_Document>>>;
  suggestedQueries?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
  suggestions?: Maybe<Array<Maybe<reverb_search_SearchResponse_Suggestion>>>;
  topCollections?: Maybe<reverb_search_TopCollectionsResult>;
  total?: Maybe<Scalars["Int"]["output"]>;
  totalHits?: Maybe<reverb_search_SearchResponse_TotalHits>;
};

export type reverb_search_SearchResponse_Suggestion = {
  __typename?: "reverb_search_SearchResponse_Suggestion";
  _source?: Maybe<Scalars["String"]["output"]>;
  frequency?: Maybe<Scalars["Int"]["output"]>;
  score?: Maybe<Scalars["Float"]["output"]>;
  text?: Maybe<Scalars["String"]["output"]>;
};

export type reverb_search_SearchResponse_TotalHits = {
  __typename?: "reverb_search_SearchResponse_TotalHits";
  _source?: Maybe<Scalars["String"]["output"]>;
  relation?: Maybe<reverb_search_SearchResponse_TotalHits_Relation>;
  value?: Maybe<Scalars["Int"]["output"]>;
};

export enum reverb_search_SearchResponse_TotalHits_Relation {
  EQ = "EQ",
  GTE = "GTE",
}

export type reverb_search_ShadowRerankingRequest = {
  __typename?: "reverb_search_ShadowRerankingRequest";
  _source?: Maybe<Scalars["String"]["output"]>;
  listingsSearchRequest?: Maybe<reverb_search_ListingsSearchRequest>;
  listingsSearchResults?: Maybe<
    Array<Maybe<reverb_search_ListingsSearchResult>>
  >;
};

export type reverb_search_ShippingRegionFilterRequest = {
  __typename?: "reverb_search_ShippingRegionFilterRequest";
  _source?: Maybe<Scalars["String"]["output"]>;
  locale?: Maybe<Scalars["String"]["output"]>;
  paramName?: Maybe<Scalars["String"]["output"]>;
  selectedValues?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
};

export type reverb_search_ShippingRegionFilterResponse = {
  __typename?: "reverb_search_ShippingRegionFilterResponse";
  _source?: Maybe<Scalars["String"]["output"]>;
  filter?: Maybe<reverb_search_Filter>;
};

export type reverb_search_SimilarListingsSearchRequest = {
  __typename?: "reverb_search_SimilarListingsSearchRequest";
  _source?: Maybe<Scalars["String"]["output"]>;
  includeForLiveListings?: Maybe<Scalars["Boolean"]["output"]>;
  itemRegion?: Maybe<Scalars["String"]["output"]>;
  itemRegionRelation?: Maybe<reverb_search_ListingItemRegionRelation>;
  likelihoodToSellExperimentGroup?: Maybe<Scalars["Int"]["output"]>;
  limit?: Maybe<Scalars["Int"]["output"]>;
  listingId?: Maybe<Scalars["String"]["output"]>;
  offset?: Maybe<Scalars["Int"]["output"]>;
  outlet?: Maybe<Scalars["Boolean"]["output"]>;
  shippingRegionCodes?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
  useFallback?: Maybe<Scalars["Boolean"]["output"]>;
};

export type reverb_search_SuggestOption = {
  __typename?: "reverb_search_SuggestOption";
  _source?: Maybe<Scalars["String"]["output"]>;
  completionPayload?: Maybe<reverb_search_SuggestOption_CompletionPayload>;
  corePayload?: Maybe<reverb_search_SuggestOption_CorePayload>;
  cspPayload?: Maybe<reverb_search_SuggestOption_CSPPayload>;
  id?: Maybe<Scalars["String"]["output"]>;
  lpPayload?: Maybe<reverb_search_SuggestOption_LPPayload>;
  output?: Maybe<Scalars["String"]["output"]>;
  scope?: Maybe<reverb_search_SuggestOptionScope>;
  scopes?: Maybe<Array<Maybe<reverb_search_SuggestOptionScope>>>;
  score?: Maybe<Scalars["Int"]["output"]>;
  slug?: Maybe<Scalars["String"]["output"]>;
  text?: Maybe<Scalars["String"]["output"]>;
};

export type reverb_search_SuggestOptionScope = {
  __typename?: "reverb_search_SuggestOptionScope";
  _source?: Maybe<Scalars["String"]["output"]>;
  id?: Maybe<Scalars["String"]["output"]>;
  output?: Maybe<Scalars["String"]["output"]>;
  slug?: Maybe<Scalars["String"]["output"]>;
  type?: Maybe<reverb_search_SuggestOptionScope_Type>;
};

export enum reverb_search_SuggestOptionScope_Type {
  BRAND = "BRAND",
  NONE = "NONE",
  PRODUCT_TYPE = "PRODUCT_TYPE",
}

export type reverb_search_SuggestOption_CSPPayload = {
  __typename?: "reverb_search_SuggestOption_CSPPayload";
  _source?: Maybe<Scalars["String"]["output"]>;
  categories?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
  primaryImage?: Maybe<Scalars["String"]["output"]>;
  title?: Maybe<Scalars["String"]["output"]>;
};

export type reverb_search_SuggestOption_CompletionPayload = {
  __typename?: "reverb_search_SuggestOption_CompletionPayload";
  _source?: Maybe<Scalars["String"]["output"]>;
  inventoryNew?: Maybe<reverb_search_Inventory>;
  inventoryUsed?: Maybe<reverb_search_Inventory>;
  thumbnailUrl?: Maybe<Scalars["String"]["output"]>;
};

export type reverb_search_SuggestOption_CoreFilter = {
  __typename?: "reverb_search_SuggestOption_CoreFilter";
  _source?: Maybe<Scalars["String"]["output"]>;
  count?: Maybe<Scalars["Int"]["output"]>;
  name?: Maybe<Scalars["String"]["output"]>;
  params?: Maybe<reverb_search_SuggestOption_CorePayloadParams>;
  type?: Maybe<Scalars["String"]["output"]>;
};

export type reverb_search_SuggestOption_CorePayload = {
  __typename?: "reverb_search_SuggestOption_CorePayload";
  _source?: Maybe<Scalars["String"]["output"]>;
  filter?: Maybe<Scalars["String"]["output"]>;
  filters?: Maybe<Array<Maybe<reverb_search_SuggestOption_CoreFilter>>>;
  params?: Maybe<reverb_search_SuggestOption_CorePayloadParams>;
  type?: Maybe<Scalars["String"]["output"]>;
  uuid?: Maybe<Scalars["String"]["output"]>;
};

export type reverb_search_SuggestOption_CorePayloadParams = {
  __typename?: "reverb_search_SuggestOption_CorePayloadParams";
  _source?: Maybe<Scalars["String"]["output"]>;
  brandSlug?: Maybe<Scalars["String"]["output"]>;
  category?: Maybe<Scalars["String"]["output"]>;
  categoryUuid?: Maybe<Scalars["String"]["output"]>;
  curatedSetSlug?: Maybe<Scalars["String"]["output"]>;
  id?: Maybe<Scalars["String"]["output"]>;
  productType?: Maybe<Scalars["String"]["output"]>;
  query?: Maybe<Scalars["String"]["output"]>;
  shop?: Maybe<Scalars["String"]["output"]>;
};

export type reverb_search_SuggestOption_LPMetadata = {
  __typename?: "reverb_search_SuggestOption_LPMetadata";
  _source?: Maybe<Scalars["String"]["output"]>;
  artistName?: Maybe<Scalars["String"]["output"]>;
  formatDescriptions?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
  labelName?: Maybe<Scalars["String"]["output"]>;
  primaryImageUuid?: Maybe<Scalars["String"]["output"]>;
  releaseDate?: Maybe<Scalars["String"]["output"]>;
};

export type reverb_search_SuggestOption_LPParams = {
  __typename?: "reverb_search_SuggestOption_LPParams";
  _source?: Maybe<Scalars["String"]["output"]>;
  album?: Maybe<Scalars["String"]["output"]>;
  artist?: Maybe<Scalars["String"]["output"]>;
  label?: Maybe<Scalars["String"]["output"]>;
};

export type reverb_search_SuggestOption_LPPayload = {
  __typename?: "reverb_search_SuggestOption_LPPayload";
  _source?: Maybe<Scalars["String"]["output"]>;
  metadata?: Maybe<reverb_search_SuggestOption_LPMetadata>;
  params?: Maybe<reverb_search_SuggestOption_LPParams>;
  type?: Maybe<Scalars["String"]["output"]>;
};

export type reverb_search_TopCSP = {
  __typename?: "reverb_search_TopCSP";
  CSP?: Maybe<CSP>;
  _source?: Maybe<Scalars["String"]["output"]>;
  count?: Maybe<Scalars["Int"]["output"]>;
  id?: Maybe<Scalars["String"]["output"]>;
};

export type reverb_search_TopCollectionsRequest = {
  __typename?: "reverb_search_TopCollectionsRequest";
  _source?: Maybe<Scalars["String"]["output"]>;
  brandUuids?: Maybe<Scalars["Int"]["output"]>;
  categoryUuids?: Maybe<Scalars["Int"]["output"]>;
};

export type reverb_search_TopCollectionsResult = {
  __typename?: "reverb_search_TopCollectionsResult";
  _source?: Maybe<Scalars["String"]["output"]>;
  brandUuids?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
  brands?: Maybe<Array<Maybe<Brand>>>;
  categories?: Maybe<Array<Maybe<Category>>>;
  categoryUuids?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
};

export enum reverb_search_TrackTotalHits {
  EXACT = "EXACT",
  FUZZ = "FUZZ",
  NONE = "NONE",
}

export enum reverb_search_UserStatus {
  IN_GOOD_STANDING = "IN_GOOD_STANDING",
  LOCKED_VACATION_MODE = "LOCKED_VACATION_MODE",
  NOT_SET = "NOT_SET",
  REQUIRES_EMAIL_VERIFICATION = "REQUIRES_EMAIL_VERIFICATION",
  SUSPENDED = "SUSPENDED",
  SUSPENDED_OUTSTANDING_INVOICE = "SUSPENDED_OUTSTANDING_INVOICE",
  UNDER_REVIEW = "UNDER_REVIEW",
}

export type reverb_signals_Signal = {
  __typename?: "reverb_signals_Signal";
  _source?: Maybe<Scalars["String"]["output"]>;
  group?: Maybe<reverb_signals_Signal_Group>;
  icon?: Maybe<Scalars["String"]["output"]>;
  iconDark?: Maybe<Scalars["String"]["output"]>;
  link?: Maybe<reverb_signals_SignalLink>;
  name?: Maybe<reverb_signals_Signal_Name>;
  slot?: Maybe<reverb_signals_Signal_Slot>;
  subtitle?: Maybe<Scalars["String"]["output"]>;
  title?: Maybe<Scalars["String"]["output"]>;
  tooltipText?: Maybe<Scalars["String"]["output"]>;
};

export enum reverb_signals_SignalImageType {
  PNG_34_3X = "PNG_34_3X",
  SVG = "SVG",
}

export type reverb_signals_SignalLink = {
  __typename?: "reverb_signals_SignalLink";
  _source?: Maybe<Scalars["String"]["output"]>;
  text?: Maybe<Scalars["String"]["output"]>;
  url?: Maybe<Scalars["String"]["output"]>;
};

export type reverb_signals_SignalRequest = {
  __typename?: "reverb_signals_SignalRequest";
  _source?: Maybe<Scalars["String"]["output"]>;
  groups?: Maybe<Array<Maybe<reverb_signals_Signal_Group>>>;
  imageType?: Maybe<reverb_signals_SignalImageType>;
};

export enum reverb_signals_Signal_Group {
  ANDROID_NUDGES = "ANDROID_NUDGES",
  ANDROID_WHY_REVERB = "ANDROID_WHY_REVERB",
  ANDROID_WHY_TO_BUY = "ANDROID_WHY_TO_BUY",
  CART_PAGE_WHY_TO_BUY = "CART_PAGE_WHY_TO_BUY",
  EXPERIMENTAL = "EXPERIMENTAL",
  IOS_NUDGES = "IOS_NUDGES",
  IOS_WHY_REVERB = "IOS_WHY_REVERB",
  IOS_WHY_TO_BUY = "IOS_WHY_TO_BUY",
  ITEM_PAGE_NUDGES = "ITEM_PAGE_NUDGES",
  ITEM_PAGE_WHY_REVERB = "ITEM_PAGE_WHY_REVERB",
  ITEM_PAGE_WHY_TO_BUY = "ITEM_PAGE_WHY_TO_BUY",
  MP_GRID_CARD = "MP_GRID_CARD",
  MP_GRID_PROX_CARD = "MP_GRID_PROX_CARD",
  MP_LIST_CARD = "MP_LIST_CARD",
  MP_LIST_PROX_CARD = "MP_LIST_PROX_CARD",
  NUDGE = "NUDGE",
  RESERVED = "RESERVED",
}

export enum reverb_signals_Signal_Name {
  AVAILABLE_FOR_PICKUP = "AVAILABLE_FOR_PICKUP",
  AVAILABLE_NEARBY_FOR_PICKUP = "AVAILABLE_NEARBY_FOR_PICKUP",
  BUY_WITH_CONFIDENCE = "BUY_WITH_CONFIDENCE",
  EXPEDITED_SHIPPING_AVAILABLE = "EXPEDITED_SHIPPING_AVAILABLE",
  FREE_EXPEDITED_SHIPPING = "FREE_EXPEDITED_SHIPPING",
  FREE_STANDARD_SHIPPING = "FREE_STANDARD_SHIPPING",
  GREAT_VALUE = "GREAT_VALUE",
  IN_OTHER_CARTS = "IN_OTHER_CARTS",
  LOCAL_PICKUP_ONLY = "LOCAL_PICKUP_ONLY",
  LOW_STOCK = "LOW_STOCK",
  NEW_LISTING = "NEW_LISTING",
  ON_SALE = "ON_SALE",
  PAYMENT_PLAN = "PAYMENT_PLAN",
  PREDICTED_TO_SELL_SOON = "PREDICTED_TO_SELL_SOON",
  PRICE_DROP = "PRICE_DROP",
  RARE_GEAR = "RARE_GEAR",
  RETURN_POLICY = "RETURN_POLICY",
  SECURE_CHECKOUT = "SECURE_CHECKOUT",
  /** deprecated */
  SHIPS_SOON = "SHIPS_SOON",
}

export enum reverb_signals_Signal_Slot {
  CHECKOUT_TRUST = "CHECKOUT_TRUST",
  EXCLUSIVE = "EXCLUSIVE",
  FINANCING = "FINANCING",
  LOCAL = "LOCAL",
  RECENCY = "RECENCY",
  REVERB_TRUST = "REVERB_TRUST",
  SELLER_TRUST = "SELLER_TRUST",
  SHIPPING_CHARGE = "SHIPPING_CHARGE",
  SPEED = "SPEED",
  URGENCY = "URGENCY",
  VALUE = "VALUE",
}

export type reverb_updates_Category = {
  __typename?: "reverb_updates_Category";
  _source?: Maybe<Scalars["String"]["output"]>;
  uuid?: Maybe<Scalars["String"]["output"]>;
};

export type reverb_updates_CreateMyUpdateRequest = {
  __typename?: "reverb_updates_CreateMyUpdateRequest";
  _source?: Maybe<Scalars["String"]["output"]>;
  entity?: Maybe<reverb_updates_Entity>;
};

export type reverb_updates_DimissMyUpdateResponse = {
  __typename?: "reverb_updates_DimissMyUpdateResponse";
  _source?: Maybe<Scalars["String"]["output"]>;
  dismissed?: Maybe<Scalars["Boolean"]["output"]>;
  updateId?: Maybe<Scalars["String"]["output"]>;
};

export type reverb_updates_DismissMyUpdateRequest = {
  __typename?: "reverb_updates_DismissMyUpdateRequest";
  _source?: Maybe<Scalars["String"]["output"]>;
  updateId?: Maybe<Scalars["String"]["output"]>;
  userId?: Maybe<Scalars["String"]["output"]>;
};

export type reverb_updates_DismissUpdatesByEntityRequest = {
  __typename?: "reverb_updates_DismissUpdatesByEntityRequest";
  _source?: Maybe<Scalars["String"]["output"]>;
  entityIds?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
  entityTypes?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
  metadata?: Maybe<reverb_updates_Metadata>;
  userId?: Maybe<Scalars["String"]["output"]>;
};

export type reverb_updates_Entity = {
  __typename?: "reverb_updates_Entity";
  _source?: Maybe<Scalars["String"]["output"]>;
  entity?: Maybe<rql_UpdateEntity>;
  /** String containing the identifier of the primary piece of content related to this update (i.e. a listing ID) */
  entityId?: Maybe<Scalars["String"]["output"]>;
  /** The type of the entity contained within this update */
  entityType?: Maybe<reverb_updates_Entity_EntityType>;
  metadata?: Maybe<reverb_updates_Metadata>;
};

export enum reverb_updates_Entity_EntityType {
  CATEGORY_AFFINITY = "CATEGORY_AFFINITY",
  NEW_LISTINGS_FROM_FOLLOWED_SHOP = "NEW_LISTINGS_FROM_FOLLOWED_SHOP",
  /** used for homepage notifications */
  NEW_OFFER = "NEW_OFFER",
  NEW_OFFER_ON_WATCHED_ITEM = "NEW_OFFER_ON_WATCHED_ITEM",
  NEW_SALE_FROM_FOLLOWED_SHOP = "NEW_SALE_FROM_FOLLOWED_SHOP",
  /** used for homepage notifications */
  OFFER_ACCEPTED = "OFFER_ACCEPTED",
  /** used for homepage notifications */
  OFFER_COUNTERED = "OFFER_COUNTERED",
  PREDICTED_TO_SELL_SOON = "PREDICTED_TO_SELL_SOON",
  /** used for my updates & homepage notifications */
  PRICE_DROP_ON_ITEM_IN_CART = "PRICE_DROP_ON_ITEM_IN_CART",
  /** used for my updates & homepage notifications */
  PRICE_DROP_ON_WATCHED = "PRICE_DROP_ON_WATCHED",
  PROMOTION = "PROMOTION",
  RECOMMENDED_LISTING = "RECOMMENDED_LISTING",
  SITE_BANNER = "SITE_BANNER",
  /** if you add a type to be used in My Updates, they must be added to dao/updates/updates.go */
  UNKNOWN = "UNKNOWN",
  WATCHED_ITEM_SOLD = "WATCHED_ITEM_SOLD",
  /** used for homepage notifications */
  WATCHER_ON_SALE = "WATCHER_ON_SALE",
}

export type reverb_updates_Listing = {
  __typename?: "reverb_updates_Listing";
  _source?: Maybe<Scalars["String"]["output"]>;
  id?: Maybe<Scalars["String"]["output"]>;
};

export type reverb_updates_Metadata = {
  __typename?: "reverb_updates_Metadata";
  _source?: Maybe<Scalars["String"]["output"]>;
  cartCount?: Maybe<Scalars["Int"]["output"]>;
  categories?: Maybe<Array<Maybe<reverb_updates_Category>>>;
  listing?: Maybe<reverb_updates_Listing>;
  listings?: Maybe<Array<Maybe<reverb_updates_Listing>>>;
  listingsCount?: Maybe<Scalars["Int"]["output"]>;
  offer?: Maybe<reverb_updates_Offer>;
  offerCount?: Maybe<Scalars["Int"]["output"]>;
  priceDrop?: Maybe<reverb_updates_PriceDrop>;
  promotion?: Maybe<reverb_updates_Promotion>;
  sale?: Maybe<reverb_updates_Sale>;
  shop?: Maybe<reverb_updates_Shop>;
  siteBanner?: Maybe<reverb_updates_SiteBanner>;
};

export type reverb_updates_MyHomepageNotificationsRequest = {
  __typename?: "reverb_updates_MyHomepageNotificationsRequest";
  _source?: Maybe<Scalars["String"]["output"]>;
  includeExperimentalNotifications?: Maybe<Scalars["Boolean"]["output"]>;
  limit?: Maybe<Scalars["Int"]["output"]>;
  userId?: Maybe<Scalars["String"]["output"]>;
};

export type reverb_updates_MyHomepageNotificationsResponse = {
  __typename?: "reverb_updates_MyHomepageNotificationsResponse";
  _source?: Maybe<Scalars["String"]["output"]>;
  updates?: Maybe<Array<Maybe<reverb_updates_Update>>>;
};

export type reverb_updates_MyUpdateResponse = {
  __typename?: "reverb_updates_MyUpdateResponse";
  _source?: Maybe<Scalars["String"]["output"]>;
  update?: Maybe<reverb_updates_Update>;
};

export type reverb_updates_MyUpdatesRequest = {
  __typename?: "reverb_updates_MyUpdatesRequest";
  _source?: Maybe<Scalars["String"]["output"]>;
  userId?: Maybe<Scalars["String"]["output"]>;
};

export type reverb_updates_MyUpdatesResponse = {
  __typename?: "reverb_updates_MyUpdatesResponse";
  _source?: Maybe<Scalars["String"]["output"]>;
  updates?: Maybe<Array<Maybe<reverb_updates_Update>>>;
};

export type reverb_updates_Offer = {
  __typename?: "reverb_updates_Offer";
  _source?: Maybe<Scalars["String"]["output"]>;
  id?: Maybe<Scalars["String"]["output"]>;
  typeContext?: Maybe<Scalars["String"]["output"]>;
};

export type reverb_updates_PriceDrop = {
  __typename?: "reverb_updates_PriceDrop";
  _source?: Maybe<Scalars["String"]["output"]>;
  id?: Maybe<Scalars["String"]["output"]>;
  listingId?: Maybe<Scalars["String"]["output"]>;
};

export type reverb_updates_Promotion = {
  __typename?: "reverb_updates_Promotion";
  _source?: Maybe<Scalars["String"]["output"]>;
  id?: Maybe<Scalars["String"]["output"]>;
};

export type reverb_updates_PruneMyUpdates = {
  __typename?: "reverb_updates_PruneMyUpdates";
  _source?: Maybe<Scalars["String"]["output"]>;
  pruneType?: Maybe<reverb_updates_PruneMyUpdates_PruneType>;
  updateId?: Maybe<Scalars["Int"]["output"]>;
  userId?: Maybe<Scalars["Int"]["output"]>;
};

export enum reverb_updates_PruneMyUpdates_PruneType {
  UNKNOWN = "UNKNOWN",
  UPDATE = "UPDATE",
  USER = "USER",
}

export type reverb_updates_Sale = {
  __typename?: "reverb_updates_Sale";
  _source?: Maybe<Scalars["String"]["output"]>;
  id?: Maybe<Scalars["String"]["output"]>;
};

export type reverb_updates_Shop = {
  __typename?: "reverb_updates_Shop";
  _source?: Maybe<Scalars["String"]["output"]>;
  id?: Maybe<Scalars["String"]["output"]>;
};

export type reverb_updates_SiteBanner = {
  __typename?: "reverb_updates_SiteBanner";
  _source?: Maybe<Scalars["String"]["output"]>;
  id?: Maybe<Scalars["String"]["output"]>;
};

export type reverb_updates_Update = {
  __typename?: "reverb_updates_Update";
  _source?: Maybe<Scalars["String"]["output"]>;
  /** Describes what the My Updates consumer should do with this message (i.e. an ADD action tells the consumer to write the update to the database) */
  action?: Maybe<reverb_updates_Update_Action>;
  /** The timestamp of this update */
  createdAt?: Maybe<Scalars["String"]["output"]>;
  /** The underlying content of this update */
  entity?: Maybe<reverb_updates_Entity>;
  updateId?: Maybe<Scalars["Int"]["output"]>;
  /** The timestamp of this update */
  updatedAt?: Maybe<Scalars["String"]["output"]>;
  /** On write, tells the My Updates consumer which users should receive this update */
  userIds?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
};

export enum reverb_updates_Update_Action {
  ADD = "ADD",
  ADD_USERS = "ADD_USERS",
  UNKNOWN = "UNKNOWN",
}

export type reverb_updates_UpdatesService = {
  __typename?: "reverb_updates_UpdatesService";
  CreateMyUpdate?: Maybe<reverb_updates_MyUpdateResponse>;
  DismissMyUpdate?: Maybe<reverb_updates_DimissMyUpdateResponse>;
  DismissUpdatesByEntity?: Maybe<reverb_updates_MyUpdatesResponse>;
  GetMyHomepageNotifications?: Maybe<reverb_updates_MyHomepageNotificationsResponse>;
  GetMyUpdates?: Maybe<reverb_updates_MyUpdatesResponse>;
  _source?: Maybe<Scalars["String"]["output"]>;
};

export type reverb_updates_UpdatesServiceCreateMyUpdateArgs = {
  input?: InputMaybe<Input_reverb_updates_CreateMyUpdateRequest>;
};

export type reverb_updates_UpdatesServiceDismissMyUpdateArgs = {
  input?: InputMaybe<Input_reverb_updates_DismissMyUpdateRequest>;
};

export type reverb_updates_UpdatesServiceDismissUpdatesByEntityArgs = {
  input?: InputMaybe<Input_reverb_updates_DismissUpdatesByEntityRequest>;
};

export type reverb_updates_UpdatesServiceGetMyHomepageNotificationsArgs = {
  input?: InputMaybe<Input_reverb_updates_MyHomepageNotificationsRequest>;
};

export type reverb_updates_UpdatesServiceGetMyUpdatesArgs = {
  input?: InputMaybe<Input_reverb_updates_MyUpdatesRequest>;
};

export type rql_AddressSuggestion = {
  __typename?: "rql_AddressSuggestion";
  _source?: Maybe<Scalars["String"]["output"]>;
  id?: Maybe<Scalars["String"]["output"]>;
  text?: Maybe<Scalars["String"]["output"]>;
  type?: Maybe<rql_AddressSuggestion_Type>;
};

export enum rql_AddressSuggestion_Type {
  ADDRESS = "ADDRESS",
  CONTAINER = "CONTAINER",
}

export type rql_AuthToken = {
  __typename?: "rql_AuthToken";
  _source?: Maybe<Scalars["String"]["output"]>;
  token?: Maybe<Scalars["String"]["output"]>;
};

/** Used as input type for mutations that don't have any arguments */
export type rql_Blank = {
  __typename?: "rql_Blank";
  _?: Maybe<Scalars["Boolean"]["output"]>;
  _source?: Maybe<Scalars["String"]["output"]>;
};

export type rql_BrandManager = {
  __typename?: "rql_BrandManager";
  _source?: Maybe<Scalars["String"]["output"]>;
  id?: Maybe<Scalars["String"]["output"]>;
  slug?: Maybe<Scalars["String"]["output"]>;
  statsByCSP?: Maybe<reverb_reporting_CSPStatsByCSPResponse>;
  statsByDate?: Maybe<reverb_reporting_CSPStatsByDateResponse>;
  totalStats?: Maybe<reverb_reporting_CSPStats>;
};

export type rql_BrandManagerstatsByCSPArgs = {
  input?: InputMaybe<Input_reverb_reporting_CSPStatsByCSPRequest>;
};

export type rql_BrandManagerstatsByDateArgs = {
  input?: InputMaybe<Input_reverb_reporting_CSPStatsRequest>;
};

export type rql_BrandManagertotalStatsArgs = {
  input?: InputMaybe<Input_reverb_reporting_CSPStatsRequest>;
};

export type rql_BrandOrderSearch = {
  __typename?: "rql_BrandOrderSearch";
  _source?: Maybe<Scalars["String"]["output"]>;
  completedOnly?: Maybe<Scalars["Boolean"]["output"]>;
  createdAfterDate?: Maybe<Scalars["String"]["output"]>;
  createdBeforeDate?: Maybe<Scalars["String"]["output"]>;
  cspSlugs?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
  listingCategoryUuids?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
  listingConditionSlugs?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
};

export type rql_BrandStatsSearch = {
  __typename?: "rql_BrandStatsSearch";
  _source?: Maybe<Scalars["String"]["output"]>;
  condition?: Maybe<reverb_reporting_Condition>;
  dateEnd?: Maybe<Scalars["String"]["output"]>;
  dateStart?: Maybe<Scalars["String"]["output"]>;
  limit?: Maybe<Scalars["Int"]["output"]>;
  offset?: Maybe<Scalars["Int"]["output"]>;
  orderBy?: Maybe<reverb_reporting_CSPStatsMetric>;
  orderDesc?: Maybe<Scalars["Boolean"]["output"]>;
};

export type rql_BumpedListingsQuery = {
  __typename?: "rql_BumpedListingsQuery";
  _source?: Maybe<Scalars["String"]["output"]>;
  count?: Maybe<Scalars["Int"]["output"]>;
  entityType?: Maybe<Scalars["String"]["output"]>;
};

export type rql_CPRecommendationsRequest = {
  __typename?: "rql_CPRecommendationsRequest";
  _source?: Maybe<Scalars["String"]["output"]>;
  cpIds?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
  limit?: Maybe<Scalars["Int"]["output"]>;
  listingIds?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
  listingsSearchRequest?: Maybe<reverb_search_ListingsSearchRequest>;
  multiClientExperiments?: Maybe<Array<Maybe<rql_ExperimentData>>>;
};

export type rql_CSPPriceRecommendation = {
  __typename?: "rql_CSPPriceRecommendation";
  _source?: Maybe<Scalars["String"]["output"]>;
  conditionUuids?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
  countryCode?: Maybe<Scalars["String"]["output"]>;
  currency?: Maybe<Scalars["String"]["output"]>;
};

export type rql_CSPProductReviewSearchResponse = {
  __typename?: "rql_CSPProductReviewSearchResponse";
  _source?: Maybe<Scalars["String"]["output"]>;
  filters?: Maybe<Array<Maybe<reverb_search_Filter>>>;
  productReviews?: Maybe<Array<Maybe<ProductReview>>>;
  stats?: Maybe<reverb_search_ProductReviewStats>;
  total?: Maybe<Scalars["Int"]["output"]>;
};

export type rql_CheckoutActionRequestInput = {
  __typename?: "rql_CheckoutActionRequestInput";
  _source?: Maybe<Scalars["String"]["output"]>;
  reverbApp?: Maybe<Scalars["String"]["output"]>;
};

export type rql_CheckoutCartEditRequest = {
  __typename?: "rql_CheckoutCartEditRequest";
  _source?: Maybe<Scalars["String"]["output"]>;
  /** Checkout UUID */
  id?: Maybe<Scalars["String"]["output"]>;
  order?: Maybe<rql_CheckoutCartEditRequest_OrderEntry>;
};

export type rql_CheckoutCartEditRequest_OrderEntry = {
  __typename?: "rql_CheckoutCartEditRequest_OrderEntry";
  _source?: Maybe<Scalars["String"]["output"]>;
  listingId?: Maybe<Scalars["String"]["output"]>;
  quantity?: Maybe<Scalars["Int"]["output"]>;
};

export type rql_CheckoutCartEditResponse = {
  __typename?: "rql_CheckoutCartEditResponse";
  _source?: Maybe<Scalars["String"]["output"]>;
  checkout?: Maybe<Checkout>;
  /** Checkout UUID */
  id?: Maybe<Scalars["String"]["output"]>;
};

export type rql_CollectionQuery = {
  __typename?: "rql_CollectionQuery";
  _source?: Maybe<Scalars["String"]["output"]>;
  ids?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
  slugs?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
  uuids?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
};

export type rql_CollectionWithDraftsQuery = {
  __typename?: "rql_CollectionWithDraftsQuery";
  _source?: Maybe<Scalars["String"]["output"]>;
  ids?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
  includeDrafts?: Maybe<Scalars["Boolean"]["output"]>;
  slugs?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
  uuids?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
};

export type rql_DiscoverEntry = {
  __typename?: "rql_DiscoverEntry";
  _source?: Maybe<Scalars["String"]["output"]>;
  ad?: Maybe<core_apimessages_Ad>;
  article?: Maybe<Article>;
  articleEntry?: Maybe<rql_DiscoverEntry_ArticleEntry>;
  description?: Maybe<Scalars["String"]["output"]>;
  id?: Maybe<Scalars["String"]["output"]>;
  listing?: Maybe<Listing>;
  localizedContents?: Maybe<Array<Maybe<reverb_feed_FollowLocalizedContents>>>;
  type?: Maybe<rql_DiscoverEntryType>;
  update?: Maybe<rql_Update>;
  video?: Maybe<youtube_Video>;
};

export enum rql_DiscoverEntryType {
  AD = "AD",
  ARTICLE_RECOMMENDATION = "ARTICLE_RECOMMENDATION",
  CP_TO_LISTING_RECOMMENDATION = "CP_TO_LISTING_RECOMMENDATION",
  ITEM_RECOMMENDATION = "ITEM_RECOMMENDATION",
  SAVED_SEARCH_FEED = "SAVED_SEARCH_FEED",
  SHOP_RECOMMENDATION = "SHOP_RECOMMENDATION",
  UPDATE = "UPDATE",
  VIDEO = "VIDEO",
  WATCH = "WATCH",
}

export type rql_DiscoverEntry_ArticleEntry = {
  __typename?: "rql_DiscoverEntry_ArticleEntry";
  _source?: Maybe<Scalars["String"]["output"]>;
  productTypeName?: Maybe<Scalars["String"]["output"]>;
};

export enum rql_DiscoverInclude {
  ARTICLES = "ARTICLES",
  RECOMMENDATIONS = "RECOMMENDATIONS",
  SAVED_SEARCHES = "SAVED_SEARCHES",
  SHOPS = "SHOPS",
  UPDATES = "UPDATES",
  VIDEOS = "VIDEOS",
  WATCHLIST = "WATCHLIST",
}

export type rql_DiscoverRequest = {
  __typename?: "rql_DiscoverRequest";
  _source?: Maybe<Scalars["String"]["output"]>;
  limit?: Maybe<Scalars["Int"]["output"]>;
  offset?: Maybe<Scalars["Int"]["output"]>;
  typesToInclude?: Maybe<Array<Maybe<rql_DiscoverInclude>>>;
};

export type rql_DiscoverResponse = {
  __typename?: "rql_DiscoverResponse";
  _source?: Maybe<Scalars["String"]["output"]>;
  entries?: Maybe<Array<Maybe<rql_DiscoverEntry>>>;
  limit?: Maybe<Scalars["Int"]["output"]>;
  offset?: Maybe<Scalars["Int"]["output"]>;
};

export type rql_EmailSubscription = {
  __typename?: "rql_EmailSubscription";
  _source?: Maybe<Scalars["String"]["output"]>;
  id?: Maybe<Scalars["String"]["output"]>;
  subscribed?: Maybe<Scalars["Boolean"]["output"]>;
  translationKey?: Maybe<rql_EmailSubscription_TranslationKey>;
};

export enum rql_EmailSubscription_TranslationKey {
  DEALS_AND_STEALS = "DEALS_AND_STEALS",
  FEED = "FEED",
  NEWSLETTER = "NEWSLETTER",
  OFFERS_AND_PROMOTIONS = "OFFERS_AND_PROMOTIONS",
  SALE_ALERTS = "SALE_ALERTS",
  SELLER_NEWSLETTER = "SELLER_NEWSLETTER",
  SPECIAL_EVENTS = "SPECIAL_EVENTS",
  WATCH = "WATCH",
}

export type rql_ExperimentData = {
  __typename?: "rql_ExperimentData";
  _source?: Maybe<Scalars["String"]["output"]>;
  group?: Maybe<Scalars["String"]["output"]>;
  name?: Maybe<Scalars["String"]["output"]>;
};

export type rql_ExpressSalePriceEstimate = {
  __typename?: "rql_ExpressSalePriceEstimate";
  _source?: Maybe<Scalars["String"]["output"]>;
  conditionUuid?: Maybe<Scalars["String"]["output"]>;
};

export type rql_Favorite = {
  __typename?: "rql_Favorite";
  _source?: Maybe<Scalars["String"]["output"]>;
  entity?: Maybe<FavoriteEntity>;
  favorite?: Maybe<core_apimessages_Favorite>;
  id?: Maybe<Scalars["String"]["output"]>;
  listingsSearchUrlParams?: Maybe<Scalars["String"]["output"]>;
  subtitle?: Maybe<Scalars["String"]["output"]>;
  title?: Maybe<Scalars["String"]["output"]>;
  type?: Maybe<rql_FavoriteType>;
};

export enum rql_FavoriteType {
  LISTING = "LISTING",
  SAVED_SEARCH = "SAVED_SEARCH",
  SHOP = "SHOP",
}

export type rql_FavoritesRequest = {
  __typename?: "rql_FavoritesRequest";
  _source?: Maybe<Scalars["String"]["output"]>;
  itemRegion?: Maybe<Scalars["String"]["output"]>;
  limit?: Maybe<Scalars["Int"]["output"]>;
  listingState?: Maybe<rql_FavoritesRequest_ListingState>;
  offset?: Maybe<Scalars["Int"]["output"]>;
  sortSlug?: Maybe<Scalars["String"]["output"]>;
  type?: Maybe<rql_FavoriteType>;
};

export enum rql_FavoritesRequest_ListingState {
  ALL = "ALL",
  LIVE = "LIVE",
  NOT_LIVE = "NOT_LIVE",
}

export type rql_FavoritesResponse = {
  __typename?: "rql_FavoritesResponse";
  _source?: Maybe<Scalars["String"]["output"]>;
  favorites?: Maybe<Array<Maybe<rql_Favorite>>>;
  filters?: Maybe<Array<Maybe<reverb_search_Filter>>>;
  limit?: Maybe<Scalars["Int"]["output"]>;
  offset?: Maybe<Scalars["Int"]["output"]>;
  total?: Maybe<Scalars["Int"]["output"]>;
};

export type rql_FeedQuery = {
  __typename?: "rql_FeedQuery";
  _source?: Maybe<Scalars["String"]["output"]>;
  channel?: Maybe<rql_FeedQuery_Channel>;
  limit?: Maybe<Scalars["Int"]["output"]>;
  listingsFilter?: Maybe<reverb_search_ListingsSearchRequest>;
  offset?: Maybe<Scalars["Int"]["output"]>;
  readFromDatabase?: Maybe<Scalars["Boolean"]["output"]>;
};

export enum rql_FeedQuery_Channel {
  LP = "LP",
  REVERB = "REVERB",
}

export type rql_FindAddressSuggestionRequest = {
  __typename?: "rql_FindAddressSuggestionRequest";
  _source?: Maybe<Scalars["String"]["output"]>;
  container?: Maybe<Scalars["String"]["output"]>;
  countryCode?: Maybe<Scalars["String"]["output"]>;
  text?: Maybe<Scalars["String"]["output"]>;
};

export type rql_FindAddressSuggestionResponse = {
  __typename?: "rql_FindAddressSuggestionResponse";
  _source?: Maybe<Scalars["String"]["output"]>;
  suggestions?: Maybe<Array<Maybe<rql_AddressSuggestion>>>;
};

export type rql_FollowsQuery = {
  __typename?: "rql_FollowsQuery";
  _source?: Maybe<Scalars["String"]["output"]>;
  channel?: Maybe<rql_FollowsQuery_Channel>;
  limit?: Maybe<Scalars["Int"]["output"]>;
  offset?: Maybe<Scalars["Int"]["output"]>;
};

export enum rql_FollowsQuery_Channel {
  LP = "LP",
  REVERB = "REVERB",
}

export type rql_GearCollectionItemsSearchRequest = {
  __typename?: "rql_GearCollectionItemsSearchRequest";
  _source?: Maybe<Scalars["String"]["output"]>;
  as?: Maybe<rql_GearCollectionItemsSearchRequest_Party>;
  searchInput?: Maybe<reverb_search_GearCollectionItemsSearchRequest>;
};

export enum rql_GearCollectionItemsSearchRequest_Party {
  OWNER = "OWNER",
}

export type rql_HomepageRequest = {
  __typename?: "rql_HomepageRequest";
  _source?: Maybe<Scalars["String"]["output"]>;
  mpid?: Maybe<Scalars["String"]["output"]>;
};

export type rql_ImageQuery = {
  __typename?: "rql_ImageQuery";
  _source?: Maybe<Scalars["String"]["output"]>;
  limit?: Maybe<Scalars["Int"]["output"]>;
  namedTransform?: Maybe<core_apimessages_NamedImageTransforms>;
  scope?: Maybe<Scalars["String"]["output"]>;
  transform?: Maybe<Scalars["String"]["output"]>;
};

export type rql_ListingPriceRecommendationRequest = {
  __typename?: "rql_ListingPriceRecommendationRequest";
  _source?: Maybe<Scalars["String"]["output"]>;
  countryCode?: Maybe<Scalars["String"]["output"]>;
  currency?: Maybe<Scalars["String"]["output"]>;
};

export type rql_Me = {
  __typename?: "rql_Me";
  _id?: Maybe<Scalars["ID"]["output"]>;
  _source?: Maybe<Scalars["String"]["output"]>;
  adaChatToken?: Maybe<core_apimessages_AdaChatTokenResponse>;
  admin?: Maybe<Scalars["Boolean"]["output"]>;
  alerts?: Maybe<Array<Maybe<core_apimessages_Alert>>>;
  availableActions?: Maybe<Array<Maybe<core_apimessages_AvailableAction>>>;
  avatar?: Maybe<core_apimessages_Image>;
  brandManagerBrand?: Maybe<Brand>;
  brandManagerBrands?: Maybe<Array<Maybe<Brand>>>;
  buyerCoupons?: Maybe<core_apimessages_MyBuyerCouponsResponse>;
  cart?: Maybe<rql_MyCart>;
  counts?: Maybe<core_apimessages_CountsResponse>;
  creditCards?: Maybe<Array<Maybe<core_apimessages_CreditCard>>>;
  discover?: Maybe<rql_DiscoverResponse>;
  emailGlobalSubscribe?: Maybe<rql_SubscribeState>;
  emailSubscriptions?: Maybe<Array<Maybe<rql_EmailSubscription>>>;
  favorites?: Maybe<rql_FavoritesResponse>;
  feed?: Maybe<reverb_feed_FeedResponse>;
  firstName?: Maybe<Scalars["String"]["output"]>;
  /** True when this user has a single orderBundle, otherwise false. */
  firstTimeBuyer?: Maybe<Scalars["Boolean"]["output"]>;
  follows?: Maybe<reverb_feed_GetFollowsResponse>;
  gearCollectionItems?: Maybe<Array<Maybe<GearCollectionItem>>>;
  /**
   * Returns a user's gear collection settings
   * @deprecated Field no longer supported
   */
  gearCollectionSetting?: Maybe<core_apimessages_GearCollectionSettingResponse>;
  /** Returns a user's gear collection settings */
  gearCollectionSettings?: Maybe<core_apimessages_GearCollectionSettingsResponse>;
  homepageNotifications?: Maybe<core_apimessages_MyHomepageNotificationsResponse>;
  /** users.id */
  id?: Maybe<Scalars["String"]["output"]>;
  myMessages?: Maybe<Array<Maybe<core_apimessages_MyMessage>>>;
  myNegotiations?: Maybe<reverb_search_SearchResponse>;
  name?: Maybe<Scalars["String"]["output"]>;
  orders?: Maybe<Array<Maybe<Order>>>;
  /** Only visible to shop administrators */
  payoutBalances?: Maybe<Array<Maybe<core_apimessages_Money>>>;
  plaidLinkToken?: Maybe<Scalars["String"]["output"]>;
  prepublishStatus?: Maybe<core_apimessages_PrepublishStatusResponse>;
  profile?: Maybe<mparticle_ProfileResponse>;
  profileSlug?: Maybe<Scalars["String"]["output"]>;
  recentSearches?: Maybe<core_apimessages_RecentSearchesResponse>;
  recentlyViewedCsps?: Maybe<Array<Maybe<CSP>>>;
  recentlyViewedListings?: Maybe<core_apimessages_RecentlyViewedListingsResponse>;
  recommendedListings?: Maybe<reverb_search_SearchResponse>;
  requireOptIn?: Maybe<Scalars["Boolean"]["output"]>;
  reverbCredits?: Maybe<Array<Maybe<core_apimessages_Money>>>;
  sellerCheckout?: Maybe<SellerCheckout>;
  shipments?: Maybe<Array<Maybe<Shipment>>>;
  shippingAddresses?: Maybe<Array<Maybe<core_apimessages_Address>>>;
  shop?: Maybe<MyShop>;
  shopId?: Maybe<Scalars["String"]["output"]>;
  shopSlug?: Maybe<Scalars["String"]["output"]>;
  shopUuid?: Maybe<Scalars["String"]["output"]>;
  shortname?: Maybe<Scalars["String"]["output"]>;
  status?: Maybe<core_apimessages_UserStatus>;
  token?: Maybe<Scalars["String"]["output"]>;
  updates?: Maybe<rql_MyUpdatesResponse>;
  userServices?: Maybe<Array<Maybe<core_apimessages_UserService>>>;
  uuid?: Maybe<Scalars["String"]["output"]>;
  watchedListings?: Maybe<reverb_search_SearchResponse>;
  watches?: Maybe<WatchCollection>;
};

export type rql_MeavailableActionsArgs = {
  input?: InputMaybe<Input_core_apimessages_MyAvailableActionsIndexRequest>;
};

export type rql_MebrandManagerBrandArgs = {
  input: Input_core_apimessages_BrandManagerBrandRequest;
};

export type rql_MebrandManagerBrandsArgs = {
  input?: InputMaybe<Input_core_apimessages_BrandManagerBrandsRequest>;
};

export type rql_MebuyerCouponsArgs = {
  input: Input_core_apimessages_MyBuyerCouponsRequest;
};

export type rql_MediscoverArgs = {
  input?: InputMaybe<Input_rql_DiscoverRequest>;
};

export type rql_MefavoritesArgs = {
  input?: InputMaybe<Input_rql_FavoritesRequest>;
};

export type rql_MefeedArgs = {
  input: Input_rql_FeedQuery;
};

export type rql_MefollowsArgs = {
  input?: InputMaybe<Input_rql_FollowsQuery>;
};

export type rql_MehomepageNotificationsArgs = {
  input?: InputMaybe<Input_core_apimessages_MyHomepageNotificationsRequest>;
};

export type rql_MemyMessagesArgs = {
  input?: InputMaybe<Input_core_apimessages_MyMessagesRequest>;
};

export type rql_MemyNegotiationsArgs = {
  input?: InputMaybe<Input_rql_NegotiationQuery>;
};

export type rql_MeordersArgs = {
  input: Input_rql_CollectionQuery;
};

export type rql_MeprofileArgs = {
  input?: InputMaybe<Input_rql_MeProfileRequest>;
};

export type rql_MerecentlyViewedListingsArgs = {
  input: Input_core_apimessages_RecentlyViewedListingsRequest;
};

export type rql_MerecommendedListingsArgs = {
  input?: InputMaybe<Input_reverb_search_ListingsSearchRequest>;
};

export type rql_MesellerCheckoutArgs = {
  input?: InputMaybe<Input_rql_SellerCheckoutInput>;
};

export type rql_MeshipmentsArgs = {
  input: Input_rql_CollectionQuery;
};

export type rql_MeupdatesArgs = {
  input?: InputMaybe<Input_rql_MyUpdatesQuery>;
};

export type rql_MewatchedListingsArgs = {
  input?: InputMaybe<Input_reverb_search_ListingsSearchRequest>;
};

export type rql_MewatchesArgs = {
  input?: InputMaybe<Input_core_apimessages_WatchesRequest>;
};

export type rql_MeProfileRequest = {
  __typename?: "rql_MeProfileRequest";
  _source?: Maybe<Scalars["String"]["output"]>;
  mpid?: Maybe<Scalars["String"]["output"]>;
};

/**
 *   *
 * This monitoring data can be attached to each endpoint to help us better
 * document and monitor for changes in performance. This allows clients
 * to be automatically generated fully aware of this contract, which should
 * allow us to more easily set things like per request timeouts, warning logs,
 * or automate monitors to hit specific teams.
 *
 * As of this writing nothing is yet consuming these extensions, but we hope
 * to extend RQL to use these to increase our visibility (and configurability)
 * of each endpoint.
 */
export type rql_MonitoringData = {
  __typename?: "rql_MonitoringData";
  _source?: Maybe<Scalars["String"]["output"]>;
  owner?: Maybe<Scalars["String"]["output"]>;
  timeout?: Maybe<Scalars["Int"]["output"]>;
  warn?: Maybe<Scalars["Int"]["output"]>;
};

export type rql_MyCart = {
  __typename?: "rql_MyCart";
  _?: Maybe<Scalars["Boolean"]["output"]>;
  _source?: Maybe<Scalars["String"]["output"]>;
  bundles?: Maybe<Array<Maybe<core_apimessages_CartItemBundle>>>;
  cartItems?: Maybe<Array<Maybe<core_apimessages_CartItem>>>;
};

export type rql_MyUpdatesQuery = {
  __typename?: "rql_MyUpdatesQuery";
  _source?: Maybe<Scalars["String"]["output"]>;
  limit?: Maybe<Scalars["Int"]["output"]>;
  offset?: Maybe<Scalars["Int"]["output"]>;
};

export type rql_MyUpdatesResponse = {
  __typename?: "rql_MyUpdatesResponse";
  _source?: Maybe<Scalars["String"]["output"]>;
  limit?: Maybe<Scalars["Int"]["output"]>;
  offset?: Maybe<Scalars["Int"]["output"]>;
  total?: Maybe<Scalars["Int"]["output"]>;
  updates?: Maybe<Array<Maybe<rql_Update>>>;
};

export type rql_NegotiationQuery = {
  __typename?: "rql_NegotiationQuery";
  _source?: Maybe<Scalars["String"]["output"]>;
  as?: Maybe<rql_NegotiationQuery_Party>;
  fullTextQuery?: Maybe<Scalars["String"]["output"]>;
  ids?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
  limit?: Maybe<Scalars["Int"]["output"]>;
  listingIds?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
  offset?: Maybe<Scalars["Int"]["output"]>;
  sort?: Maybe<Scalars["String"]["output"]>;
  state?: Maybe<rql_NegotiationQuery_State>;
};

export enum rql_NegotiationQuery_Party {
  BUYER = "BUYER",
  SELLER = "SELLER",
}

export enum rql_NegotiationQuery_State {
  ACCEPTED = "ACCEPTED",
  ACTIVE = "ACTIVE",
  EXPIRED = "EXPIRED",
  REJECTED = "REJECTED",
}

export type rql_Node = {
  __typename?: "rql_Node";
  Id?: Maybe<Scalars["String"]["output"]>;
  _source?: Maybe<Scalars["String"]["output"]>;
};

export type rql_NodeError = {
  __typename?: "rql_NodeError";
  _source?: Maybe<Scalars["String"]["output"]>;
  message?: Maybe<Scalars["String"]["output"]>;
  type?: Maybe<rql_NodeError_ErrorType>;
};

export enum rql_NodeError_ErrorType {
  NOT_FOUND = "NOT_FOUND",
  UNKNOWN = "UNKNOWN",
}

export type rql_NodeQuery = {
  __typename?: "rql_NodeQuery";
  _source?: Maybe<Scalars["String"]["output"]>;
  id?: Maybe<Scalars["String"]["output"]>;
  slug?: Maybe<Scalars["String"]["output"]>;
  uuid?: Maybe<Scalars["String"]["output"]>;
};

export type rql_OrderQuery = {
  __typename?: "rql_OrderQuery";
  _source?: Maybe<Scalars["String"]["output"]>;
  actionableStatuses?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
  checkoutUuid?: Maybe<Scalars["String"]["output"]>;
  limit?: Maybe<Scalars["Int"]["output"]>;
  offset?: Maybe<Scalars["Int"]["output"]>;
};

export type rql_OrdersSearchRequest = {
  __typename?: "rql_OrdersSearchRequest";
  _source?: Maybe<Scalars["String"]["output"]>;
  as?: Maybe<core_apimessages_BulkOrdersRequest_Party>;
  searchFilters?: Maybe<reverb_search_OrderSearchRequest>;
};

export type rql_PageInfo = {
  __typename?: "rql_PageInfo";
  _source?: Maybe<Scalars["String"]["output"]>;
  limit?: Maybe<Scalars["Int"]["output"]>;
  offset?: Maybe<Scalars["Int"]["output"]>;
  total?: Maybe<Scalars["Int"]["output"]>;
};

/** Deprecated */
export type rql_PageMetadata = {
  __typename?: "rql_PageMetadata";
  _source?: Maybe<Scalars["String"]["output"]>;
  collectionClause?: Maybe<Scalars["String"]["output"]>;
  fallbackSubject?: Maybe<Scalars["String"]["output"]>;
  hasSubjectClause?: Maybe<Scalars["Boolean"]["output"]>;
  inPreposition?: Maybe<Scalars["String"]["output"]>;
  mainClause?: Maybe<Scalars["String"]["output"]>;
  prefixClause?: Maybe<Scalars["String"]["output"]>;
};

export type rql_PriceRecordSearchRequest = {
  __typename?: "rql_PriceRecordSearchRequest";
  _source?: Maybe<Scalars["String"]["output"]>;
  actionableStatusGroup?: Maybe<Scalars["String"]["output"]>;
  actionableStatuses?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
  averageMonthlyProductPricesCurrency?: Maybe<Scalars["String"]["output"]>;
  canonicalProductIds?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
  conditionSlugs?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
  createdAfterDate?: Maybe<Scalars["String"]["output"]>;
  createdBeforeDate?: Maybe<Scalars["String"]["output"]>;
  limit?: Maybe<Scalars["Int"]["output"]>;
  listingConditionSlugs?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
  listingConditionUuids?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
  listingId?: Maybe<Scalars["String"]["output"]>;
  maxAmountProductCentsUsd?: Maybe<Scalars["String"]["output"]>;
  minAmountProductCentsUsd?: Maybe<Scalars["String"]["output"]>;
  offset?: Maybe<Scalars["Int"]["output"]>;
  orderType?: Maybe<Scalars["String"]["output"]>;
  sellerCountries?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
  shopCurrency?: Maybe<Scalars["String"]["output"]>;
  withAverageMonthlyProductPricesAggregations?: Maybe<
    Scalars["Boolean"]["output"]
  >;
};

export type rql_PublicComponentEntity = {
  __typename?: "rql_PublicComponentEntity";
  _source?: Maybe<Scalars["String"]["output"]>;
  entity?: Maybe<PublicComponentEntity>;
  id?: Maybe<Scalars["String"]["output"]>;
  type?: Maybe<Scalars["String"]["output"]>;
};

export enum rql_PublicComponentEntity_EntityType {
  CSP = "CSP",
  LISTING = "LISTING",
}

export type rql_RecommendationsGenericResponse = {
  __typename?: "rql_RecommendationsGenericResponse";
  _source?: Maybe<Scalars["String"]["output"]>;
  recommendationEngineInputIds?: Maybe<
    Array<Maybe<Scalars["String"]["output"]>>
  >;
  recommendationEngineOutputIds?: Maybe<
    Array<Maybe<Scalars["String"]["output"]>>
  >;
  searchResponse?: Maybe<reverb_search_SearchResponse>;
};

export type rql_ReflectCollectionQuery = {
  __typename?: "rql_ReflectCollectionQuery";
  _source?: Maybe<Scalars["String"]["output"]>;
  keys?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
};

export type rql_ReflectQuery = {
  __typename?: "rql_ReflectQuery";
  _source?: Maybe<Scalars["String"]["output"]>;
  key?: Maybe<Scalars["String"]["output"]>;
};

export type rql_SelectAddressSuggestionRequest = {
  __typename?: "rql_SelectAddressSuggestionRequest";
  _source?: Maybe<Scalars["String"]["output"]>;
  id?: Maybe<Scalars["String"]["output"]>;
};

export type rql_SelectAddressSuggestionResponse = {
  __typename?: "rql_SelectAddressSuggestionResponse";
  _source?: Maybe<Scalars["String"]["output"]>;
  address?: Maybe<core_apimessages_AddressEntry>;
  id?: Maybe<Scalars["String"]["output"]>;
};

export type rql_SellerCheckoutInput = {
  __typename?: "rql_SellerCheckoutInput";
  _source?: Maybe<Scalars["String"]["output"]>;
  id?: Maybe<Scalars["String"]["output"]>;
};

export type rql_SetGlobalSubscribeRequest = {
  __typename?: "rql_SetGlobalSubscribeRequest";
  _source?: Maybe<Scalars["String"]["output"]>;
  state?: Maybe<rql_SubscribeState>;
};

export type rql_ShopsRecommendationsRequest = {
  __typename?: "rql_ShopsRecommendationsRequest";
  _source?: Maybe<Scalars["String"]["output"]>;
  limit?: Maybe<Scalars["Int"]["output"]>;
};

export enum rql_SubscribeState {
  OPTED_IN = "OPTED_IN",
  SUBSCRIBED = "SUBSCRIBED",
  UNSUBSCRIBED = "UNSUBSCRIBED",
}

export type rql_Update = {
  __typename?: "rql_Update";
  _source?: Maybe<Scalars["String"]["output"]>;
  /** The timestamp of this update */
  createdAt?: Maybe<Scalars["String"]["output"]>;
  /** The underlying content of this update */
  entity?: Maybe<rql_UpdateEntity>;
};

export type rql_UpdateEntity = {
  __typename?: "rql_UpdateEntity";
  _?: Maybe<Scalars["Boolean"]["output"]>;
  _id?: Maybe<Scalars["String"]["output"]>;
  _source?: Maybe<Scalars["String"]["output"]>;
  /** the user's top affinity categories (presently only the top one is returned, but in the future there may be more than one) */
  affinityCategories?: Maybe<Array<Maybe<Category>>>;
  /** up to 12 listings filtered by the user's top categories */
  affinityListingByCategory?: Maybe<Array<Maybe<Listing>>>;
  /** the cart count relevant to this update */
  cartCount?: Maybe<Scalars["Int"]["output"]>;
  id?: Maybe<Scalars["String"]["output"]>;
  /** the listing of the update, e.g. the watched listing */
  listing?: Maybe<Listing>;
  /** listings related to this update, e.g. some new listings from a followed shop */
  listings?: Maybe<Array<Maybe<Listing>>>;
  /** the listings count relevant to this update */
  listingsCount?: Maybe<Scalars["Int"]["output"]>;
  /** the offer count relevant to this update */
  offerCount?: Maybe<Scalars["Int"]["output"]>;
  /** the price drop of the update */
  priceDrop?: Maybe<PriceChange>;
  /** promotion related to this update */
  promotion?: Maybe<MyUpdatesPromotion>;
  /** sale related to this update */
  sale?: Maybe<core_apimessages_Sale>;
  /** shop related to this update */
  shop?: Maybe<Shop>;
  /** the site banner of the update */
  siteBanner?: Maybe<SiteBanner>;
  type?: Maybe<reverb_updates_Entity_EntityType>;
};

export type youtube_PlaylistItem = {
  __typename?: "youtube_PlaylistItem";
  _source?: Maybe<Scalars["String"]["output"]>;
  snippet?: Maybe<youtube_PlaylistSnippet>;
};

export type youtube_PlaylistResourceId = {
  __typename?: "youtube_PlaylistResourceId";
  _source?: Maybe<Scalars["String"]["output"]>;
  videoId?: Maybe<Scalars["String"]["output"]>;
};

export type youtube_PlaylistSnippet = {
  __typename?: "youtube_PlaylistSnippet";
  _source?: Maybe<Scalars["String"]["output"]>;
  description?: Maybe<Scalars["String"]["output"]>;
  resourceId?: Maybe<youtube_PlaylistResourceId>;
  title?: Maybe<Scalars["String"]["output"]>;
};

export type youtube_Video = {
  __typename?: "youtube_Video";
  _source?: Maybe<Scalars["String"]["output"]>;
  description?: Maybe<Scalars["String"]["output"]>;
  id?: Maybe<Scalars["String"]["output"]>;
  title?: Maybe<Scalars["String"]["output"]>;
};

export type Core_GetAdyenRegionQueryVariables = Exact<{ [key: string]: never }>;

export type Core_GetAdyenRegionQuery = {
  __typename?: "Query";
  getAdyenRegion?: {
    __typename?: "core_apimessages_ShowAdyenRegionResponse";
    webRegion?: string | null;
  } | null;
};

export type Commons_Cms_ArticlesCollectionQueryVariables = Exact<{
  ids?: InputMaybe<
    | Array<InputMaybe<Scalars["String"]["input"]>>
    | InputMaybe<Scalars["String"]["input"]>
  >;
}>;

export type Commons_Cms_ArticlesCollectionQuery = {
  __typename?: "Query";
  articles?: Array<{
    __typename?: "Article";
    _id?: string | null;
    id?: string | null;
    title?: string | null;
    articleCategories?: Array<{
      __typename?: "core_apimessages_ArticleCategory";
      name?: string | null;
    } | null> | null;
    webLink?: {
      __typename?: "core_apimessages_Link";
      href?: string | null;
    } | null;
    images?: Array<{
      __typename?: "core_apimessages_Image";
      _id?: string | null;
      source?: string | null;
    } | null> | null;
  } | null> | null;
};

export type Commons_Cms_BrandCollectionQueryVariables = Exact<{
  maxCount?: InputMaybe<Scalars["Int"]["input"]>;
  curatedSetId?: InputMaybe<Scalars["String"]["input"]>;
  onSale?: InputMaybe<Scalars["Boolean"]["input"]>;
  saleCuratedSetId?: InputMaybe<Scalars["String"]["input"]>;
  priceMax?: InputMaybe<Scalars["String"]["input"]>;
  priceMin?: InputMaybe<Scalars["String"]["input"]>;
  yearMax?: InputMaybe<Scalars["String"]["input"]>;
  yearMin?: InputMaybe<Scalars["String"]["input"]>;
  categorySlugs?: InputMaybe<
    | Array<InputMaybe<Scalars["String"]["input"]>>
    | InputMaybe<Scalars["String"]["input"]>
  >;
  query?: InputMaybe<Scalars["String"]["input"]>;
}>;

export type Commons_Cms_BrandCollectionQuery = {
  __typename?: "Query";
  listingsSearch?: {
    __typename?: "reverb_search_SearchResponse";
    topCollections?: {
      __typename?: "reverb_search_TopCollectionsResult";
      brands?: Array<{
        __typename?: "Brand";
        _id?: string | null;
        id?: string | null;
        name?: string | null;
        slug?: string | null;
        webLink?: {
          __typename?: "core_apimessages_Link";
          href?: string | null;
        } | null;
        images?: Array<{
          __typename?: "core_apimessages_Image";
          _id?: string | null;
          source?: string | null;
        } | null> | null;
      } | null> | null;
    } | null;
  } | null;
};

export type Commons_Cms_CategoryCollectionQueryVariables = Exact<{
  categoryUuids?: InputMaybe<
    | Array<InputMaybe<Scalars["String"]["input"]>>
    | InputMaybe<Scalars["String"]["input"]>
  >;
}>;

export type Commons_Cms_CategoryCollectionQuery = {
  __typename?: "Query";
  categories?: Array<{
    __typename?: "Category";
    _id?: string | null;
    id?: string | null;
    name?: string | null;
    slug?: string | null;
    rootSlug?: string | null;
    cmsLink?: {
      __typename?: "core_apimessages_Link";
      href?: string | null;
    } | null;
    images?: Array<{
      __typename?: "core_apimessages_Image";
      _id?: string | null;
      source?: string | null;
    } | null> | null;
  } | null> | null;
};

export type Commons_Cms_ListingsCollectionQueryVariables = Exact<{
  acceptsGiftCards?: InputMaybe<Scalars["Boolean"]["input"]>;
  acceptsOffers?: InputMaybe<Scalars["Boolean"]["input"]>;
  acceptsPaymentPlans?: InputMaybe<Scalars["Boolean"]["input"]>;
  brandUuids?: InputMaybe<
    | Array<InputMaybe<Scalars["String"]["input"]>>
    | InputMaybe<Scalars["String"]["input"]>
  >;
  brandSlugs?: InputMaybe<
    | Array<InputMaybe<Scalars["String"]["input"]>>
    | InputMaybe<Scalars["String"]["input"]>
  >;
  bumpedOnly: Scalars["Boolean"]["input"];
  boostByBumpRate: Scalars["Boolean"]["input"];
  categorySlugs?: InputMaybe<
    | Array<InputMaybe<Scalars["String"]["input"]>>
    | InputMaybe<Scalars["String"]["input"]>
  >;
  categoryUuids?: InputMaybe<
    | Array<InputMaybe<Scalars["String"]["input"]>>
    | InputMaybe<Scalars["String"]["input"]>
  >;
  conditionSlugs?: InputMaybe<
    | Array<InputMaybe<Scalars["String"]["input"]>>
    | InputMaybe<Scalars["String"]["input"]>
  >;
  countryOfOrigin?: InputMaybe<
    | Array<InputMaybe<Scalars["String"]["input"]>>
    | InputMaybe<Scalars["String"]["input"]>
  >;
  cspId?: InputMaybe<Scalars["String"]["input"]>;
  curatedSetId?: InputMaybe<Scalars["String"]["input"]>;
  currency?: InputMaybe<Scalars["String"]["input"]>;
  freeExpeditedShipping?: InputMaybe<Scalars["Boolean"]["input"]>;
  freeShipping?: InputMaybe<Scalars["Boolean"]["input"]>;
  handmade?: InputMaybe<Scalars["Boolean"]["input"]>;
  holidaySale?: InputMaybe<Scalars["Boolean"]["input"]>;
  itemRegion?: InputMaybe<Scalars["String"]["input"]>;
  itemRegionRelation?: InputMaybe<reverb_search_ListingItemRegionRelation>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  listingIds?: InputMaybe<
    | Array<InputMaybe<Scalars["String"]["input"]>>
    | InputMaybe<Scalars["String"]["input"]>
  >;
  onSale?: InputMaybe<Scalars["Boolean"]["input"]>;
  preferredSeller?: InputMaybe<Scalars["Boolean"]["input"]>;
  priceMax?: InputMaybe<Scalars["String"]["input"]>;
  priceMin?: InputMaybe<Scalars["String"]["input"]>;
  query?: InputMaybe<Scalars["String"]["input"]>;
  saleCuratedSetId?: InputMaybe<Scalars["String"]["input"]>;
  saleSlugs?: InputMaybe<
    | Array<InputMaybe<Scalars["String"]["input"]>>
    | InputMaybe<Scalars["String"]["input"]>
  >;
  shippingRegionCodes?: InputMaybe<
    | Array<InputMaybe<Scalars["String"]["input"]>>
    | InputMaybe<Scalars["String"]["input"]>
  >;
  shopSlugs?: InputMaybe<
    | Array<InputMaybe<Scalars["String"]["input"]>>
    | InputMaybe<Scalars["String"]["input"]>
  >;
  sort?: InputMaybe<reverb_search_ListingsSearchRequest_Sort>;
  yearMax?: InputMaybe<Scalars["String"]["input"]>;
  yearMin?: InputMaybe<Scalars["String"]["input"]>;
  showListingLocation: Scalars["Boolean"]["input"];
  adKeywords?: InputMaybe<
    | Array<InputMaybe<Scalars["String"]["input"]>>
    | InputMaybe<Scalars["String"]["input"]>
  >;
  hasAdKeywords: Scalars["Boolean"]["input"];
  traitSlugs?: InputMaybe<
    | Array<InputMaybe<Scalars["String"]["input"]>>
    | InputMaybe<Scalars["String"]["input"]>
  >;
  dealsOrWellPriced?: InputMaybe<Scalars["Boolean"]["input"]>;
  priceValueV2?: InputMaybe<reverb_search_ListingsSearchRequest_PriceValue>;
  shipsToMe?: InputMaybe<Scalars["Boolean"]["input"]>;
  shouldSkipTracking: Scalars["Boolean"]["input"];
  similarListingIds?: InputMaybe<
    | Array<InputMaybe<Scalars["String"]["input"]>>
    | InputMaybe<Scalars["String"]["input"]>
  >;
  localPickup?: InputMaybe<Scalars["Boolean"]["input"]>;
  excludeShopIds?: InputMaybe<
    | Array<InputMaybe<Scalars["String"]["input"]>>
    | InputMaybe<Scalars["String"]["input"]>
  >;
  randomSeed?: InputMaybe<Scalars["Int"]["input"]>;
}>;

export type Commons_Cms_ListingsCollectionQuery = {
  __typename?: "Query";
  listingsSearch?: {
    __typename?: "reverb_search_SearchResponse";
    listings?: Array<{
      __typename?: "Listing";
      _id?: string | null;
      id?: string | null;
      usOutlet?: boolean | null;
      bumped?: boolean | null;
      categoryUuids?: Array<string | null> | null;
      slug?: string | null;
      title?: string | null;
      description?: string | null;
      listingType?: core_apimessages_Listing_ListingType | null;
      watching?: boolean | null;
      state?: string | null;
      stateDescription?: string | null;
      brandSlug?: string | null;
      categoryRootUuid?: string | null;
      make?: string | null;
      bumpEligible?: boolean | null;
      shopId?: string | null;
      inventory?: number | null;
      soldAsIs?: boolean | null;
      acceptedPaymentMethods?: Array<string | null> | null;
      currency?: string | null;
      sellerId?: string | null;
      offersEnabled?: boolean | null;
      isBuyerOfferEligible?: boolean | null;
      condition?: {
        __typename?: "core_apimessages_Condition";
        displayName?: string | null;
        conditionSlug?: string | null;
        conditionUuid?: string | null;
      } | null;
      price?: {
        __typename?: "core_apimessages_Money";
        amountCents?: number | null;
        display?: string | null;
      } | null;
      pricing?: {
        __typename?: "core_apimessages_ListingPricing";
        buyerPrice?: {
          __typename?: "core_apimessages_Money";
          display?: string | null;
          currency?: string | null;
          amountCents?: number | null;
          amount?: string | null;
        } | null;
        originalPrice?: {
          __typename?: "core_apimessages_Money";
          display?: string | null;
        } | null;
        ribbon?: {
          __typename?: "core_apimessages_Ribbon";
          display?: string | null;
          reason?: core_apimessages_Ribbon_Reason | null;
        } | null;
      } | null;
      shipping?: {
        __typename?: "core_apimessages_ShippingPrices";
        freeExpeditedShipping?: boolean | null;
        localPickupOnly?: boolean | null;
        localPickup?: boolean | null;
        shippingPrices?: Array<{
          __typename?: "core_apimessages_ShippingPrice";
          _id?: string | null;
          shippingMethod?: core_apimessages_ShippingMethod | null;
          carrierCalculated?: boolean | null;
          destinationPostalCodeNeeded?: boolean | null;
          rate?: {
            __typename?: "core_apimessages_Money";
            amount?: string | null;
            amountCents?: number | null;
            currency?: string | null;
            display?: string | null;
          } | null;
        } | null> | null;
      } | null;
      shop?: {
        __typename?: "Shop";
        _id?: string | null;
        address?: {
          __typename?: "core_apimessages_Address";
          _id?: string | null;
          countryCode?: string | null;
          locality?: string | null;
          region?: string | null;
          country?: {
            __typename?: "core_apimessages_Country";
            _id?: string | null;
            countryCode?: string | null;
            name?: string | null;
          } | null;
        } | null;
        returnPolicy?: {
          __typename?: "core_apimessages_ReturnPolicy";
          _id?: string | null;
          newReturnWindowDays?: number | null;
          usedReturnWindowDays?: number | null;
        } | null;
      } | null;
      categories?: Array<{
        __typename?: "Category";
        _id?: string | null;
        slug?: string | null;
        rootSlug?: string | null;
      } | null> | null;
      csp?: {
        __typename?: "CSP";
        _id?: string | null;
        id?: string | null;
        slug?: string | null;
        brand?: {
          __typename?: "Brand";
          _id?: string | null;
          slug?: string | null;
        } | null;
      } | null;
      publishedAt?: {
        __typename?: "google_protobuf_Timestamp";
        seconds?: number | null;
      } | null;
      sale?: {
        __typename?: "core_apimessages_Sale";
        _id?: string | null;
        id?: string | null;
        code?: string | null;
        buyerIneligibilityReason?: string | null;
      } | null;
      certifiedPreOwned?: {
        __typename?: "core_apimessages_ListingCertifiedPreOwned";
        title?: string | null;
        badgeIconUrl?: string | null;
      } | null;
      bumpKey?: {
        __typename?: "core_apimessages_BumpKey";
        key?: string | null;
      } | null;
      preorderInfo?: {
        __typename?: "core_apimessages_PreorderInfo";
        onPreorder?: boolean | null;
        estimatedShipDate?: {
          __typename?: "google_protobuf_Timestamp";
          seconds?: number | null;
        } | null;
      } | null;
      watchThumbnails?: Array<{
        __typename?: "core_apimessages_Image";
        _id?: string | null;
        source?: string | null;
      } | null> | null;
      priceRecommendation?: {
        __typename?: "reverb_pricing_PriceRecommendation";
        priceMiddle?: {
          __typename?: "reverb_pricing_Money";
          amountCents?: number | null;
          currency?: string | null;
        } | null;
      } | null;
      images?: Array<{
        __typename?: "core_apimessages_Image";
        _id?: string | null;
        source?: string | null;
      } | null> | null;
      largeSquareImages?: Array<{
        __typename?: "core_apimessages_Image";
        _id?: string | null;
        source?: string | null;
      } | null> | null;
    } | null> | null;
  } | null;
  adServe?: {
    __typename?: "core_apimessages_AdServeResponse";
    ad?: {
      __typename?: "core_apimessages_Ad";
      uuid?: string | null;
      format?: core_apimessages_AdFormat | null;
      adGroupUuid?: string | null;
      adGroupName?: string | null;
      adCampaignUuid?: string | null;
      advertiserUuid?: string | null;
      imageAdCreative?: {
        __typename?: "core_apimessages_ImageAdCreative";
        title?: string | null;
        titleColor?: string | null;
        subtitle?: string | null;
        subtitleColor?: string | null;
        ctaText?: string | null;
        ctaHref?: string | null;
        ctaTextColor?: string | null;
        ctaButtonColor?: string | null;
        backgroundImage?: string | null;
        mobileBackgroundImage?: string | null;
        backgroundColor?: string | null;
        backgroundColors?: Array<string | null> | null;
        overlayImage?: string | null;
        description?: string | null;
        partnershipText?: string | null;
        hideAdCallout?: boolean | null;
      } | null;
    } | null;
  } | null;
};

export type Core_Cms_PageCardsQueryVariables = Exact<{
  cms1Slugs?: InputMaybe<
    | Array<InputMaybe<Scalars["String"]["input"]>>
    | InputMaybe<Scalars["String"]["input"]>
  >;
  cms2Slugs?: InputMaybe<
    | Array<InputMaybe<Scalars["String"]["input"]>>
    | InputMaybe<Scalars["String"]["input"]>
  >;
}>;

export type Core_Cms_PageCardsQuery = {
  __typename?: "Query";
  cmsPages?: Array<{
    __typename?: "CMSPage";
    _id?: string | null;
    slug?: string | null;
    title?: string | null;
    pageType?: string | null;
    channels?: Array<string | null> | null;
    pageNotFound?: boolean | null;
    image?: { __typename?: "cms_PageImage"; imageUrl?: string | null } | null;
  } | null> | null;
  articles?: Array<{
    __typename?: "Article";
    _id?: string | null;
    slug?: string | null;
    title?: string | null;
    webLink?: {
      __typename?: "core_apimessages_Link";
      href?: string | null;
    } | null;
    images?: Array<{
      __typename?: "core_apimessages_Image";
      _id?: string | null;
      source?: string | null;
    } | null> | null;
  } | null> | null;
};

export type Commons_Cms_CspCollectionQueryVariables = Exact<{
  curatedSetId?: InputMaybe<Scalars["String"]["input"]>;
  fullTextQuery?: InputMaybe<Scalars["String"]["input"]>;
  brandNames?: InputMaybe<
    | Array<InputMaybe<Scalars["String"]["input"]>>
    | InputMaybe<Scalars["String"]["input"]>
  >;
  brandSlugs?: InputMaybe<
    | Array<InputMaybe<Scalars["String"]["input"]>>
    | InputMaybe<Scalars["String"]["input"]>
  >;
  condition?: InputMaybe<Scalars["String"]["input"]>;
  countryOfOrigin?: InputMaybe<
    | Array<InputMaybe<Scalars["String"]["input"]>>
    | InputMaybe<Scalars["String"]["input"]>
  >;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  categoryUuids?: InputMaybe<
    | Array<InputMaybe<Scalars["String"]["input"]>>
    | InputMaybe<Scalars["String"]["input"]>
  >;
  sort?: InputMaybe<reverb_search_CSPSearchRequest_Sort>;
  traitSlugs?: InputMaybe<
    | Array<InputMaybe<Scalars["String"]["input"]>>
    | InputMaybe<Scalars["String"]["input"]>
  >;
  slugs?: InputMaybe<
    | Array<InputMaybe<Scalars["String"]["input"]>>
    | InputMaybe<Scalars["String"]["input"]>
  >;
  yearMin?: InputMaybe<Scalars["Int"]["input"]>;
  yearMax?: InputMaybe<Scalars["Int"]["input"]>;
}>;

export type Commons_Cms_CspCollectionQuery = {
  __typename?: "Query";
  cspSearch?: {
    __typename?: "reverb_search_SearchResponse";
    csps?: Array<{
      __typename?: "CSP";
      _id?: string | null;
      metaDescription?: string | null;
      summary?: string | null;
      id?: string | null;
      title?: string | null;
      slug?: string | null;
      averageReviewRating?: number | null;
      reviewsCount?: number | null;
      finishes?: Array<string | null> | null;
      brand?: {
        __typename?: "Brand";
        _id?: string | null;
        name?: string | null;
        slug?: string | null;
      } | null;
      webLink?: {
        __typename?: "core_apimessages_Link";
        path?: string | null;
        href?: string | null;
      } | null;
      image?: {
        __typename?: "core_apimessages_Image";
        _id?: string | null;
        source?: string | null;
      } | null;
      categories?: Array<{
        __typename?: "Category";
        _id?: string | null;
        slug?: string | null;
        rootSlug?: string | null;
        name?: string | null;
        parentCategories?: Array<{
          __typename?: "Category";
          _id?: string | null;
          name?: string | null;
          slug?: string | null;
        } | null> | null;
      } | null> | null;
      inventory?: {
        __typename?: "core_apimessages_CSPInventory";
        usedTotal?: number | null;
        newTotal?: number | null;
        newLowPrice?: {
          __typename?: "core_apimessages_Money";
          display?: string | null;
          amount?: string | null;
          amountCents?: number | null;
          currency?: string | null;
        } | null;
        usedLowPrice?: {
          __typename?: "core_apimessages_Money";
          display?: string | null;
          amount?: string | null;
          amountCents?: number | null;
          currency?: string | null;
        } | null;
      } | null;
    } | null> | null;
  } | null;
};

export type Commons_Cms_CspFeatureQueryVariables = Exact<{
  ids?: InputMaybe<
    | Array<InputMaybe<Scalars["String"]["input"]>>
    | InputMaybe<Scalars["String"]["input"]>
  >;
}>;

export type Commons_Cms_CspFeatureQuery = {
  __typename?: "Query";
  csps?: Array<{
    __typename?: "CSP";
    _id?: string | null;
    summary?: string | null;
    id?: string | null;
    title?: string | null;
    slug?: string | null;
    averageReviewRating?: number | null;
    reviewsCount?: number | null;
    finishes?: Array<string | null> | null;
    webLink?: {
      __typename?: "core_apimessages_Link";
      path?: string | null;
      href?: string | null;
    } | null;
    image?: {
      __typename?: "core_apimessages_Image";
      _id?: string | null;
      source?: string | null;
    } | null;
    brand?: {
      __typename?: "Brand";
      _id?: string | null;
      slug?: string | null;
      name?: string | null;
    } | null;
    categories?: Array<{
      __typename?: "Category";
      _id?: string | null;
      slug?: string | null;
      rootSlug?: string | null;
      name?: string | null;
      parentCategories?: Array<{
        __typename?: "Category";
        _id?: string | null;
        name?: string | null;
        slug?: string | null;
      } | null> | null;
    } | null> | null;
    inventory?: {
      __typename?: "core_apimessages_CSPInventory";
      usedTotal?: number | null;
      newTotal?: number | null;
      newLowPrice?: {
        __typename?: "core_apimessages_Money";
        display?: string | null;
        amount?: string | null;
        amountCents?: number | null;
        currency?: string | null;
      } | null;
      usedLowPrice?: {
        __typename?: "core_apimessages_Money";
        display?: string | null;
        amount?: string | null;
        amountCents?: number | null;
        currency?: string | null;
      } | null;
    } | null;
  } | null> | null;
};

export type Commons_Cms_CspTilesQueryVariables = Exact<{
  ids?: InputMaybe<
    | Array<InputMaybe<Scalars["String"]["input"]>>
    | InputMaybe<Scalars["String"]["input"]>
  >;
  fullTextQuery?: InputMaybe<Scalars["String"]["input"]>;
  brandNames?: InputMaybe<
    | Array<InputMaybe<Scalars["String"]["input"]>>
    | InputMaybe<Scalars["String"]["input"]>
  >;
  condition?: InputMaybe<Scalars["String"]["input"]>;
  countryOfOrigin?: InputMaybe<
    | Array<InputMaybe<Scalars["String"]["input"]>>
    | InputMaybe<Scalars["String"]["input"]>
  >;
  categoryUuids?: InputMaybe<
    | Array<InputMaybe<Scalars["String"]["input"]>>
    | InputMaybe<Scalars["String"]["input"]>
  >;
  sort?: InputMaybe<reverb_search_CSPSearchRequest_Sort>;
  traitSlugs?: InputMaybe<
    | Array<InputMaybe<Scalars["String"]["input"]>>
    | InputMaybe<Scalars["String"]["input"]>
  >;
  yearMin?: InputMaybe<Scalars["Int"]["input"]>;
  yearMax?: InputMaybe<Scalars["Int"]["input"]>;
}>;

export type Commons_Cms_CspTilesQuery = {
  __typename?: "Query";
  cspSearch?: {
    __typename?: "reverb_search_SearchResponse";
    csps?: Array<{
      __typename?: "CSP";
      _id?: string | null;
      metaDescription?: string | null;
      summary?: string | null;
      id?: string | null;
      title?: string | null;
      slug?: string | null;
      averageReviewRating?: number | null;
      reviewsCount?: number | null;
      finishes?: Array<string | null> | null;
      brand?: {
        __typename?: "Brand";
        _id?: string | null;
        name?: string | null;
        slug?: string | null;
      } | null;
      webLink?: {
        __typename?: "core_apimessages_Link";
        path?: string | null;
        href?: string | null;
      } | null;
      image?: {
        __typename?: "core_apimessages_Image";
        _id?: string | null;
        source?: string | null;
      } | null;
      categories?: Array<{
        __typename?: "Category";
        _id?: string | null;
        slug?: string | null;
        rootSlug?: string | null;
        name?: string | null;
        parentCategories?: Array<{
          __typename?: "Category";
          _id?: string | null;
          name?: string | null;
          slug?: string | null;
        } | null> | null;
      } | null> | null;
      inventory?: {
        __typename?: "core_apimessages_CSPInventory";
        usedTotal?: number | null;
        newTotal?: number | null;
        newLowPrice?: {
          __typename?: "core_apimessages_Money";
          display?: string | null;
          amount?: string | null;
          amountCents?: number | null;
          currency?: string | null;
        } | null;
        usedLowPrice?: {
          __typename?: "core_apimessages_Money";
          display?: string | null;
          amount?: string | null;
          amountCents?: number | null;
          currency?: string | null;
        } | null;
      } | null;
    } | null> | null;
  } | null;
};

export type Commons_Cms_CuratedSetCollectionQueryVariables = Exact<{
  ids?: InputMaybe<
    | Array<InputMaybe<Scalars["String"]["input"]>>
    | InputMaybe<Scalars["String"]["input"]>
  >;
}>;

export type Commons_Cms_CuratedSetCollectionQuery = {
  __typename?: "Query";
  curatedSets?: Array<{
    __typename?: "CuratedSet";
    _id?: string | null;
    id?: string | null;
    title?: string | null;
    webLink?: {
      __typename?: "core_apimessages_Link";
      href?: string | null;
    } | null;
    images?: Array<{
      __typename?: "core_apimessages_Image";
      _id?: string | null;
      source?: string | null;
    } | null> | null;
  } | null> | null;
};

export type Commons_Cms_PersonalizedListingsCollectionQueryVariables = Exact<{
  collapsible?: InputMaybe<reverb_search_ListingsSearchRequest_Collapsible>;
  conditionSlugs?: InputMaybe<
    | Array<InputMaybe<Scalars["String"]["input"]>>
    | InputMaybe<Scalars["String"]["input"]>
  >;
  holidaySale?: InputMaybe<Scalars["Boolean"]["input"]>;
  itemRegion?: InputMaybe<Scalars["String"]["input"]>;
  itemRegionRelation?: InputMaybe<reverb_search_ListingItemRegionRelation>;
  listingsLimit?: InputMaybe<Scalars["Int"]["input"]>;
  recsLimit?: InputMaybe<Scalars["Int"]["input"]>;
  shippingRegionCodes?: InputMaybe<
    | Array<InputMaybe<Scalars["String"]["input"]>>
    | InputMaybe<Scalars["String"]["input"]>
  >;
  shouldSkipTracking: Scalars["Boolean"]["input"];
  showListingLocation: Scalars["Boolean"]["input"];
  sort?: InputMaybe<reverb_search_ListingsSearchRequest_Sort>;
}>;

export type Commons_Cms_PersonalizedListingsCollectionQuery = {
  __typename?: "Query";
  cpToListingRecs?: {
    __typename?: "rql_RecommendationsGenericResponse";
    searchResponse?: {
      __typename?: "reverb_search_SearchResponse";
      limit?: number | null;
      total?: number | null;
      listings?: Array<{
        __typename?: "Listing";
        _id?: string | null;
        id?: string | null;
        usOutlet?: boolean | null;
        bumped?: boolean | null;
        categoryUuids?: Array<string | null> | null;
        slug?: string | null;
        title?: string | null;
        description?: string | null;
        listingType?: core_apimessages_Listing_ListingType | null;
        watching?: boolean | null;
        state?: string | null;
        stateDescription?: string | null;
        brandSlug?: string | null;
        categoryRootUuid?: string | null;
        make?: string | null;
        bumpEligible?: boolean | null;
        shopId?: string | null;
        inventory?: number | null;
        soldAsIs?: boolean | null;
        acceptedPaymentMethods?: Array<string | null> | null;
        currency?: string | null;
        sellerId?: string | null;
        offersEnabled?: boolean | null;
        isBuyerOfferEligible?: boolean | null;
        condition?: {
          __typename?: "core_apimessages_Condition";
          displayName?: string | null;
          conditionSlug?: string | null;
          conditionUuid?: string | null;
        } | null;
        price?: {
          __typename?: "core_apimessages_Money";
          amountCents?: number | null;
          display?: string | null;
        } | null;
        pricing?: {
          __typename?: "core_apimessages_ListingPricing";
          buyerPrice?: {
            __typename?: "core_apimessages_Money";
            display?: string | null;
            currency?: string | null;
            amountCents?: number | null;
            amount?: string | null;
          } | null;
          originalPrice?: {
            __typename?: "core_apimessages_Money";
            display?: string | null;
          } | null;
          ribbon?: {
            __typename?: "core_apimessages_Ribbon";
            display?: string | null;
            reason?: core_apimessages_Ribbon_Reason | null;
          } | null;
        } | null;
        shipping?: {
          __typename?: "core_apimessages_ShippingPrices";
          freeExpeditedShipping?: boolean | null;
          localPickupOnly?: boolean | null;
          localPickup?: boolean | null;
          shippingPrices?: Array<{
            __typename?: "core_apimessages_ShippingPrice";
            _id?: string | null;
            shippingMethod?: core_apimessages_ShippingMethod | null;
            carrierCalculated?: boolean | null;
            destinationPostalCodeNeeded?: boolean | null;
            rate?: {
              __typename?: "core_apimessages_Money";
              amount?: string | null;
              amountCents?: number | null;
              currency?: string | null;
              display?: string | null;
            } | null;
          } | null> | null;
        } | null;
        shop?: {
          __typename?: "Shop";
          _id?: string | null;
          address?: {
            __typename?: "core_apimessages_Address";
            _id?: string | null;
            countryCode?: string | null;
            locality?: string | null;
            region?: string | null;
            country?: {
              __typename?: "core_apimessages_Country";
              _id?: string | null;
              countryCode?: string | null;
              name?: string | null;
            } | null;
          } | null;
          returnPolicy?: {
            __typename?: "core_apimessages_ReturnPolicy";
            _id?: string | null;
            newReturnWindowDays?: number | null;
            usedReturnWindowDays?: number | null;
          } | null;
        } | null;
        categories?: Array<{
          __typename?: "Category";
          _id?: string | null;
          slug?: string | null;
          rootSlug?: string | null;
        } | null> | null;
        csp?: {
          __typename?: "CSP";
          _id?: string | null;
          id?: string | null;
          slug?: string | null;
          brand?: {
            __typename?: "Brand";
            _id?: string | null;
            slug?: string | null;
          } | null;
        } | null;
        publishedAt?: {
          __typename?: "google_protobuf_Timestamp";
          seconds?: number | null;
        } | null;
        sale?: {
          __typename?: "core_apimessages_Sale";
          _id?: string | null;
          id?: string | null;
          code?: string | null;
          buyerIneligibilityReason?: string | null;
        } | null;
        certifiedPreOwned?: {
          __typename?: "core_apimessages_ListingCertifiedPreOwned";
          title?: string | null;
          badgeIconUrl?: string | null;
        } | null;
        preorderInfo?: {
          __typename?: "core_apimessages_PreorderInfo";
          onPreorder?: boolean | null;
          estimatedShipDate?: {
            __typename?: "google_protobuf_Timestamp";
            seconds?: number | null;
          } | null;
        } | null;
        watchThumbnails?: Array<{
          __typename?: "core_apimessages_Image";
          _id?: string | null;
          source?: string | null;
        } | null> | null;
        priceRecommendation?: {
          __typename?: "reverb_pricing_PriceRecommendation";
          priceMiddle?: {
            __typename?: "reverb_pricing_Money";
            amountCents?: number | null;
            currency?: string | null;
          } | null;
        } | null;
        images?: Array<{
          __typename?: "core_apimessages_Image";
          _id?: string | null;
          source?: string | null;
        } | null> | null;
        largeSquareImages?: Array<{
          __typename?: "core_apimessages_Image";
          _id?: string | null;
          source?: string | null;
        } | null> | null;
      } | null> | null;
    } | null;
  } | null;
};

export type Commons_Cms_RecentlyViewedListingsQueryVariables = Exact<{
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  showListingLocation: Scalars["Boolean"]["input"];
  shouldSkipTracking: Scalars["Boolean"]["input"];
}>;

export type Commons_Cms_RecentlyViewedListingsQuery = {
  __typename?: "Query";
  recentlyViewedListings?: Array<{
    __typename?: "Listing";
    _id?: string | null;
    id?: string | null;
    usOutlet?: boolean | null;
    bumped?: boolean | null;
    categoryUuids?: Array<string | null> | null;
    slug?: string | null;
    title?: string | null;
    description?: string | null;
    listingType?: core_apimessages_Listing_ListingType | null;
    watching?: boolean | null;
    state?: string | null;
    stateDescription?: string | null;
    brandSlug?: string | null;
    categoryRootUuid?: string | null;
    make?: string | null;
    bumpEligible?: boolean | null;
    shopId?: string | null;
    inventory?: number | null;
    soldAsIs?: boolean | null;
    acceptedPaymentMethods?: Array<string | null> | null;
    currency?: string | null;
    sellerId?: string | null;
    offersEnabled?: boolean | null;
    isBuyerOfferEligible?: boolean | null;
    condition?: {
      __typename?: "core_apimessages_Condition";
      displayName?: string | null;
      conditionSlug?: string | null;
      conditionUuid?: string | null;
    } | null;
    price?: {
      __typename?: "core_apimessages_Money";
      amountCents?: number | null;
      display?: string | null;
    } | null;
    pricing?: {
      __typename?: "core_apimessages_ListingPricing";
      buyerPrice?: {
        __typename?: "core_apimessages_Money";
        display?: string | null;
        currency?: string | null;
        amountCents?: number | null;
        amount?: string | null;
      } | null;
      originalPrice?: {
        __typename?: "core_apimessages_Money";
        display?: string | null;
      } | null;
      ribbon?: {
        __typename?: "core_apimessages_Ribbon";
        display?: string | null;
        reason?: core_apimessages_Ribbon_Reason | null;
      } | null;
    } | null;
    shipping?: {
      __typename?: "core_apimessages_ShippingPrices";
      freeExpeditedShipping?: boolean | null;
      localPickupOnly?: boolean | null;
      localPickup?: boolean | null;
      shippingPrices?: Array<{
        __typename?: "core_apimessages_ShippingPrice";
        _id?: string | null;
        shippingMethod?: core_apimessages_ShippingMethod | null;
        carrierCalculated?: boolean | null;
        destinationPostalCodeNeeded?: boolean | null;
        rate?: {
          __typename?: "core_apimessages_Money";
          amount?: string | null;
          amountCents?: number | null;
          currency?: string | null;
          display?: string | null;
        } | null;
      } | null> | null;
    } | null;
    shop?: {
      __typename?: "Shop";
      _id?: string | null;
      address?: {
        __typename?: "core_apimessages_Address";
        _id?: string | null;
        countryCode?: string | null;
        locality?: string | null;
        region?: string | null;
        country?: {
          __typename?: "core_apimessages_Country";
          _id?: string | null;
          countryCode?: string | null;
          name?: string | null;
        } | null;
      } | null;
      returnPolicy?: {
        __typename?: "core_apimessages_ReturnPolicy";
        _id?: string | null;
        newReturnWindowDays?: number | null;
        usedReturnWindowDays?: number | null;
      } | null;
    } | null;
    categories?: Array<{
      __typename?: "Category";
      _id?: string | null;
      slug?: string | null;
      rootSlug?: string | null;
    } | null> | null;
    csp?: {
      __typename?: "CSP";
      _id?: string | null;
      id?: string | null;
      slug?: string | null;
      brand?: {
        __typename?: "Brand";
        _id?: string | null;
        slug?: string | null;
      } | null;
    } | null;
    publishedAt?: {
      __typename?: "google_protobuf_Timestamp";
      seconds?: number | null;
    } | null;
    sale?: {
      __typename?: "core_apimessages_Sale";
      _id?: string | null;
      id?: string | null;
      code?: string | null;
      buyerIneligibilityReason?: string | null;
    } | null;
    certifiedPreOwned?: {
      __typename?: "core_apimessages_ListingCertifiedPreOwned";
      title?: string | null;
      badgeIconUrl?: string | null;
    } | null;
    preorderInfo?: {
      __typename?: "core_apimessages_PreorderInfo";
      onPreorder?: boolean | null;
      estimatedShipDate?: {
        __typename?: "google_protobuf_Timestamp";
        seconds?: number | null;
      } | null;
    } | null;
    watchThumbnails?: Array<{
      __typename?: "core_apimessages_Image";
      _id?: string | null;
      source?: string | null;
    } | null> | null;
    priceRecommendation?: {
      __typename?: "reverb_pricing_PriceRecommendation";
      priceMiddle?: {
        __typename?: "reverb_pricing_Money";
        amountCents?: number | null;
        currency?: string | null;
      } | null;
    } | null;
    images?: Array<{
      __typename?: "core_apimessages_Image";
      _id?: string | null;
      source?: string | null;
    } | null> | null;
    largeSquareImages?: Array<{
      __typename?: "core_apimessages_Image";
      _id?: string | null;
      source?: string | null;
    } | null> | null;
  } | null> | null;
};

export type Commons_Cms_SubcategoryNavQueryVariables = Exact<{
  ids?: InputMaybe<
    | Array<InputMaybe<Scalars["String"]["input"]>>
    | InputMaybe<Scalars["String"]["input"]>
  >;
  slugs?: InputMaybe<
    | Array<InputMaybe<Scalars["String"]["input"]>>
    | InputMaybe<Scalars["String"]["input"]>
  >;
}>;

export type Commons_Cms_SubcategoryNavQuery = {
  __typename?: "Query";
  categories?: Array<{
    __typename?: "Category";
    _id?: string | null;
    id?: string | null;
    name?: string | null;
    slug?: string | null;
    rootSlug?: string | null;
    cmsLink?: {
      __typename?: "core_apimessages_Link";
      href?: string | null;
    } | null;
    images?: Array<{
      __typename?: "core_apimessages_Image";
      _id?: string | null;
      source?: string | null;
    } | null> | null;
    childCategories?: Array<{
      __typename?: "Category";
      _id?: string | null;
      id?: string | null;
      name?: string | null;
      slug?: string | null;
      rootSlug?: string | null;
      cmsLink?: {
        __typename?: "core_apimessages_Link";
        href?: string | null;
      } | null;
      images?: Array<{
        __typename?: "core_apimessages_Image";
        _id?: string | null;
        source?: string | null;
      } | null> | null;
    } | null> | null;
    parentCategories?: Array<{
      __typename?: "Category";
      _id?: string | null;
      id?: string | null;
      name?: string | null;
      slug?: string | null;
      rootSlug?: string | null;
      cmsLink?: {
        __typename?: "core_apimessages_Link";
        href?: string | null;
      } | null;
      images?: Array<{
        __typename?: "core_apimessages_Image";
        _id?: string | null;
        source?: string | null;
      } | null> | null;
    } | null> | null;
  } | null> | null;
};

export type AddToCartButtonFieldsFragment = {
  __typename?: "Listing";
  _id?: string | null;
  id?: string | null;
  sellerId?: string | null;
  listingType?: core_apimessages_Listing_ListingType | null;
  pricing?: {
    __typename?: "core_apimessages_ListingPricing";
    buyerPrice?: {
      __typename?: "core_apimessages_Money";
      amount?: string | null;
      amountCents?: number | null;
    } | null;
  } | null;
  preorderInfo?: {
    __typename?: "core_apimessages_PreorderInfo";
    onPreorder?: boolean | null;
    estimatedShipDate?: {
      __typename?: "google_protobuf_Timestamp";
      seconds?: number | null;
    } | null;
  } | null;
};

export type ImageAdCreativeDataFragment = {
  __typename?: "core_apimessages_ImageAdCreative";
  title?: string | null;
  titleColor?: string | null;
  subtitle?: string | null;
  subtitleColor?: string | null;
  ctaText?: string | null;
  ctaHref?: string | null;
  ctaTextColor?: string | null;
  ctaButtonColor?: string | null;
  backgroundImage?: string | null;
  mobileBackgroundImage?: string | null;
  backgroundColor?: string | null;
  backgroundColors?: Array<string | null> | null;
  overlayImage?: string | null;
  description?: string | null;
  partnershipText?: string | null;
  hideAdCallout?: boolean | null;
};

export type AdCampaignDataFragment = {
  __typename?: "AdCampaign";
  _id?: string | null;
  uuid?: string | null;
  name?: string | null;
  status?: core_apimessages_AdCampaign_AdStatus | null;
  startsAt?: any | null;
  endsAt?: any | null;
  advertiserUuid?: string | null;
};

export type AdDataFragment = {
  __typename?: "core_apimessages_Ad";
  uuid?: string | null;
  format?: core_apimessages_AdFormat | null;
  adGroupUuid?: string | null;
  adGroupName?: string | null;
  adCampaignUuid?: string | null;
  advertiserUuid?: string | null;
};

export type AdCspRowQueryDataFragment = {
  __typename?: "core_apimessages_CspRowQuery";
  brandSlugs?: Array<string | null> | null;
  categoryUuids?: Array<string | null> | null;
  cspSlugs?: Array<string | null> | null;
  rowTitle?: string | null;
  sort?: string | null;
};

export type Core_Ad_Banner_WrapperQueryVariables = Exact<{
  keywords?: InputMaybe<
    | Array<InputMaybe<Scalars["String"]["input"]>>
    | InputMaybe<Scalars["String"]["input"]>
  >;
}>;

export type Core_Ad_Banner_WrapperQuery = {
  __typename?: "Query";
  adServe?: {
    __typename?: "core_apimessages_AdServeResponse";
    ad?: {
      __typename?: "core_apimessages_Ad";
      uuid?: string | null;
      format?: core_apimessages_AdFormat | null;
      adGroupUuid?: string | null;
      adGroupName?: string | null;
      adCampaignUuid?: string | null;
      advertiserUuid?: string | null;
      imageAdCreative?: {
        __typename?: "core_apimessages_ImageAdCreative";
        title?: string | null;
        titleColor?: string | null;
        subtitle?: string | null;
        subtitleColor?: string | null;
        ctaText?: string | null;
        ctaHref?: string | null;
        ctaTextColor?: string | null;
        ctaButtonColor?: string | null;
        backgroundImage?: string | null;
        mobileBackgroundImage?: string | null;
        backgroundColor?: string | null;
        backgroundColors?: Array<string | null> | null;
        overlayImage?: string | null;
        description?: string | null;
        partnershipText?: string | null;
        hideAdCallout?: boolean | null;
      } | null;
    } | null;
  } | null;
};

export type Core_Ad_Ended_Listing_PageQueryVariables = Exact<{
  categories?: InputMaybe<
    | Array<InputMaybe<Scalars["String"]["input"]>>
    | InputMaybe<Scalars["String"]["input"]>
  >;
}>;

export type Core_Ad_Ended_Listing_PageQuery = {
  __typename?: "Query";
  adServe?: {
    __typename?: "core_apimessages_AdServeResponse";
    ad?: {
      __typename?: "core_apimessages_Ad";
      uuid?: string | null;
      format?: core_apimessages_AdFormat | null;
      adGroupUuid?: string | null;
      adGroupName?: string | null;
      adCampaignUuid?: string | null;
      advertiserUuid?: string | null;
      imageAdCreative?: {
        __typename?: "core_apimessages_ImageAdCreative";
        title?: string | null;
        titleColor?: string | null;
        subtitle?: string | null;
        subtitleColor?: string | null;
        ctaText?: string | null;
        ctaHref?: string | null;
        ctaTextColor?: string | null;
        ctaButtonColor?: string | null;
        backgroundImage?: string | null;
        mobileBackgroundImage?: string | null;
        backgroundColor?: string | null;
        backgroundColors?: Array<string | null> | null;
        overlayImage?: string | null;
        description?: string | null;
        partnershipText?: string | null;
        hideAdCallout?: boolean | null;
        cspRowQuery?: {
          __typename?: "core_apimessages_CspRowQuery";
          brandSlugs?: Array<string | null> | null;
          categoryUuids?: Array<string | null> | null;
          cspSlugs?: Array<string | null> | null;
          rowTitle?: string | null;
          sort?: string | null;
        } | null;
      } | null;
    } | null;
  } | null;
};

export type AdGroupWithoutKeywordsDataFragment = {
  __typename?: "AdGroup";
  _id?: string | null;
  uuid?: string | null;
  name?: string | null;
  adCampaignUuid?: string | null;
  targetedCategoryUuids?: Array<string | null> | null;
  targetedCountryCodes?: Array<string | null> | null;
  targetedDevices?: Array<string | null> | null;
  targetedBrandSlugs?: Array<string | null> | null;
  targetedAdZones?: Array<{
    __typename?: "core_apimessages_AdGroupZone";
    startsAt?: any | null;
    endsAt?: any | null;
    adZone?: {
      __typename?: "core_apimessages_AdZone";
      slug?: string | null;
    } | null;
  } | null> | null;
};

export type Core_Dev_Ad_PreviewsQueryVariables = Exact<{
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  status?: InputMaybe<core_apimessages_AdCampaignsSearchRequest_Status>;
  adZoneSlugs?: InputMaybe<
    | Array<InputMaybe<Scalars["String"]["input"]>>
    | InputMaybe<Scalars["String"]["input"]>
  >;
  withinDate?: InputMaybe<Scalars["String"]["input"]>;
  advertiserUuids?: InputMaybe<
    | Array<InputMaybe<Scalars["String"]["input"]>>
    | InputMaybe<Scalars["String"]["input"]>
  >;
  advertiserOffset?: InputMaybe<Scalars["Int"]["input"]>;
  advertiserLimit?: InputMaybe<Scalars["Int"]["input"]>;
}>;

export type Core_Dev_Ad_PreviewsQuery = {
  __typename?: "Query";
  adCampaignsSearch?: {
    __typename?: "core_apimessages_AdCampaignsSearchResponse";
    offset?: number | null;
    limit?: number | null;
    total?: number | null;
    adCampaigns?: Array<{
      __typename?: "AdCampaign";
      _id?: string | null;
      uuid?: string | null;
      name?: string | null;
      status?: core_apimessages_AdCampaign_AdStatus | null;
      startsAt?: any | null;
      endsAt?: any | null;
      advertiserUuid?: string | null;
      adGroupCollection?: {
        __typename?: "AdGroupCollection";
        _ids?: Array<string | null> | null;
        adGroups?: Array<{
          __typename?: "AdGroup";
          _id?: string | null;
          uuid?: string | null;
          name?: string | null;
          adCampaignUuid?: string | null;
          targetedCategoryUuids?: Array<string | null> | null;
          targetedCountryCodes?: Array<string | null> | null;
          targetedDevices?: Array<string | null> | null;
          targetedBrandSlugs?: Array<string | null> | null;
          adCollection?: {
            __typename?: "AdCollection";
            _ids?: Array<string | null> | null;
            ads?: Array<{
              __typename?: "Ad";
              _id?: string | null;
              uuid?: string | null;
              imageAdCreative?: {
                __typename?: "core_apimessages_ImageAdCreative";
                title?: string | null;
                titleColor?: string | null;
                subtitle?: string | null;
                subtitleColor?: string | null;
                ctaText?: string | null;
                ctaHref?: string | null;
                ctaTextColor?: string | null;
                ctaButtonColor?: string | null;
                backgroundImage?: string | null;
                mobileBackgroundImage?: string | null;
                backgroundColor?: string | null;
                backgroundColors?: Array<string | null> | null;
                overlayImage?: string | null;
                description?: string | null;
                partnershipText?: string | null;
                hideAdCallout?: boolean | null;
                cspRowQuery?: {
                  __typename?: "core_apimessages_CspRowQuery";
                  brandSlugs?: Array<string | null> | null;
                  categoryUuids?: Array<string | null> | null;
                  cspSlugs?: Array<string | null> | null;
                  rowTitle?: string | null;
                  sort?: string | null;
                } | null;
              } | null;
            } | null> | null;
          } | null;
          targetedAdZones?: Array<{
            __typename?: "core_apimessages_AdGroupZone";
            startsAt?: any | null;
            endsAt?: any | null;
            adZone?: {
              __typename?: "core_apimessages_AdZone";
              slug?: string | null;
            } | null;
          } | null> | null;
        } | null> | null;
      } | null;
    } | null> | null;
  } | null;
  advertisersSearch?: {
    __typename?: "core_apimessages_AdvertisersSearchResponse";
    offset?: number | null;
    limit?: number | null;
    total?: number | null;
    advertisers?: Array<{
      __typename?: "Advertiser";
      _id?: string | null;
      uuid?: string | null;
      name?: string | null;
    } | null> | null;
  } | null;
  countries?: {
    __typename?: "core_apimessages_CountriesResponse";
    countries?: Array<{
      __typename?: "core_apimessages_Country";
      _id?: string | null;
      name?: string | null;
      countryCode?: string | null;
    } | null> | null;
  } | null;
  adZonesSearch?: {
    __typename?: "core_apimessages_AdZonesSearchResponse";
    adZones?: Array<{
      __typename?: "AdZone";
      _id?: string | null;
      slug?: string | null;
    } | null> | null;
  } | null;
};

export type Core_Ad_Image_FeatureQueryVariables = Exact<{
  adServeInput?: InputMaybe<Input_core_apimessages_AdServeRequest>;
}>;

export type Core_Ad_Image_FeatureQuery = {
  __typename?: "Query";
  adServe?: {
    __typename?: "core_apimessages_AdServeResponse";
    placements?: Array<{
      __typename?: "core_apimessages_AdPlacementResponse";
      _id?: string | null;
      adZones?: Array<{
        __typename?: "core_apimessages_AdZone";
        slug?: string | null;
      } | null> | null;
      ad?: {
        __typename?: "core_apimessages_Ad";
        uuid?: string | null;
        format?: core_apimessages_AdFormat | null;
        adGroupUuid?: string | null;
        adGroupName?: string | null;
        adCampaignUuid?: string | null;
        advertiserUuid?: string | null;
        imageAdCreative?: {
          __typename?: "core_apimessages_ImageAdCreative";
          title?: string | null;
          titleColor?: string | null;
          subtitle?: string | null;
          subtitleColor?: string | null;
          ctaText?: string | null;
          ctaHref?: string | null;
          ctaTextColor?: string | null;
          ctaButtonColor?: string | null;
          backgroundImage?: string | null;
          mobileBackgroundImage?: string | null;
          backgroundColor?: string | null;
          backgroundColors?: Array<string | null> | null;
          overlayImage?: string | null;
          description?: string | null;
          partnershipText?: string | null;
          hideAdCallout?: boolean | null;
        } | null;
      } | null;
    } | null> | null;
  } | null;
};

export type FullFinancingFieldsFragment = {
  __typename?: "Listing";
  _id?: string | null;
  estimatedMonthlyPaymentPlan?: {
    __typename?: "core_apimessages_EstimatedMonthlyPayment";
    financingProgramSlug?: string | null;
  } | null;
  pricing?: {
    __typename?: "core_apimessages_ListingPricing";
    buyerPrice?: {
      __typename?: "core_apimessages_Money";
      amountCents?: number | null;
    } | null;
  } | null;
};

export type Commons_Cms_CmsEmbedQueryVariables = Exact<{
  slug?: InputMaybe<Scalars["String"]["input"]>;
  admin?: InputMaybe<Scalars["Boolean"]["input"]>;
}>;

export type Commons_Cms_CmsEmbedQuery = {
  __typename?: "Query";
  pages?: Array<{
    __typename?: "CMSPage";
    _id?: string | null;
    id?: string | null;
    slug?: string | null;
    actionText?: string | null;
    actionUrl?: string | null;
    authorName?: string | null;
    description?: string | null;
    goLiveAt?: string | null;
    headerStyle?: string | null;
    layoutSlug?: string | null;
    pageType?: string | null;
    searchPlaceholder?: string | null;
    title?: string | null;
    metaTitle?: string | null;
    metaDescription?: string | null;
    noIndex?: boolean | null;
    live?: boolean | null;
    pageNotFound?: boolean | null;
    image?: { __typename?: "cms_PageImage"; imageUrl?: string | null } | null;
    metaImage?: {
      __typename?: "cms_PageImage";
      imageUrl?: string | null;
    } | null;
    secondaryImage?: {
      __typename?: "cms_PageImage";
      imageUrl?: string | null;
    } | null;
    components?: Array<{
      __typename?: "PublicComponent";
      _id?: string | null;
      id?: string | null;
      position?: number | null;
      componentType?: string | null;
      data?: string | null;
      layoutSection?: string | null;
      experimentKey?: string | null;
      experimentBucket?: string | null;
    } | null> | null;
  } | null> | null;
};

export type CSPLightboxDataFragment = {
  __typename?: "CSP";
  _id?: string | null;
  squareImage?: Array<{
    __typename?: "core_apimessages_Image";
    source?: string | null;
  } | null> | null;
  fullsizeImage?: Array<{
    __typename?: "core_apimessages_Image";
    source?: string | null;
  } | null> | null;
  videos?: {
    __typename?: "core_apimessages_CSPVideo";
    videoLinks?: Array<{
      __typename?: "core_apimessages_VideoLink";
      youtubeVideoId?: string | null;
    } | null> | null;
  } | null;
};

export type CSPAdditionalTrackingFieldsFragment = {
  __typename?: "CSP";
  _id?: string | null;
  id?: string | null;
  slug?: string | null;
  brand?: {
    __typename?: "Brand";
    _id?: string | null;
    slug?: string | null;
  } | null;
  categories?: Array<{
    __typename?: "Category";
    _id?: string | null;
    slug?: string | null;
    rootSlug?: string | null;
    parentCategories?: Array<{
      __typename?: "Category";
      _id?: string | null;
      slug?: string | null;
    } | null> | null;
  } | null> | null;
  inventory?: {
    __typename?: "core_apimessages_CSPInventory";
    usedTotal?: number | null;
    newTotal?: number | null;
  } | null;
};

export type CSPCardFragment = {
  __typename?: "CSP";
  _id?: string | null;
  id?: string | null;
  title?: string | null;
  slug?: string | null;
  averageReviewRating?: number | null;
  reviewsCount?: number | null;
  finishes?: Array<string | null> | null;
  webLink?: {
    __typename?: "core_apimessages_Link";
    path?: string | null;
    href?: string | null;
  } | null;
  image?: {
    __typename?: "core_apimessages_Image";
    _id?: string | null;
    source?: string | null;
  } | null;
  brand?: {
    __typename?: "Brand";
    _id?: string | null;
    slug?: string | null;
    name?: string | null;
  } | null;
  categories?: Array<{
    __typename?: "Category";
    _id?: string | null;
    slug?: string | null;
    rootSlug?: string | null;
    name?: string | null;
    parentCategories?: Array<{
      __typename?: "Category";
      _id?: string | null;
      name?: string | null;
      slug?: string | null;
    } | null> | null;
  } | null> | null;
  inventory?: {
    __typename?: "core_apimessages_CSPInventory";
    usedTotal?: number | null;
    newTotal?: number | null;
    newLowPrice?: {
      __typename?: "core_apimessages_Money";
      display?: string | null;
      amount?: string | null;
      amountCents?: number | null;
      currency?: string | null;
    } | null;
    usedLowPrice?: {
      __typename?: "core_apimessages_Money";
      display?: string | null;
      amount?: string | null;
      amountCents?: number | null;
      currency?: string | null;
    } | null;
  } | null;
};

export type Commons_Cms_DynamicPageQueryVariables = Exact<{
  slug?: InputMaybe<Scalars["String"]["input"]>;
  admin?: InputMaybe<Scalars["Boolean"]["input"]>;
}>;

export type Commons_Cms_DynamicPageQuery = {
  __typename?: "Query";
  pages?: Array<{
    __typename?: "CMSPage";
    _id?: string | null;
    id?: string | null;
    slug?: string | null;
    title?: string | null;
    description?: string | null;
    metaTitle?: string | null;
    metaDescription?: string | null;
    componentIds?: Array<string | null> | null;
    pageType?: string | null;
    channels?: Array<string | null> | null;
    locale?: string | null;
    layoutSlug?: string | null;
    headerStyle?: string | null;
    actionText?: string | null;
    actionUrl?: string | null;
    darkModeReady?: boolean | null;
    searchPlaceholder?: string | null;
    noIndex?: boolean | null;
    breadcrumbs?: string | null;
    authorName?: string | null;
    goLiveAt?: string | null;
    live?: boolean | null;
    pageNotFound?: boolean | null;
    components?: Array<{
      __typename?: "PublicComponent";
      _id?: string | null;
      id?: string | null;
      position?: number | null;
      componentType?: string | null;
      data?: string | null;
      layoutSection?: string | null;
      experimentKey?: string | null;
      experimentBucket?: string | null;
    } | null> | null;
    image?: { __typename?: "cms_PageImage"; imageUrl?: string | null } | null;
    metaImage?: {
      __typename?: "cms_PageImage";
      imageUrl?: string | null;
    } | null;
    secondaryImage?: {
      __typename?: "cms_PageImage";
      imageUrl?: string | null;
    } | null;
  } | null> | null;
};

export type Commons_FavoriteShop_ListingsCollectionQueryVariables = Exact<{
  shopSlugs?: InputMaybe<
    | Array<InputMaybe<Scalars["String"]["input"]>>
    | InputMaybe<Scalars["String"]["input"]>
  >;
  sort?: InputMaybe<reverb_search_ListingsSearchRequest_Sort>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
}>;

export type Commons_FavoriteShop_ListingsCollectionQuery = {
  __typename?: "Query";
  listingsSearch?: {
    __typename?: "reverb_search_SearchResponse";
    listings?: Array<{
      __typename?: "Listing";
      _id?: string | null;
      id?: string | null;
      usOutlet?: boolean | null;
      bumped?: boolean | null;
      categoryUuids?: Array<string | null> | null;
      slug?: string | null;
      title?: string | null;
      description?: string | null;
      listingType?: core_apimessages_Listing_ListingType | null;
      watching?: boolean | null;
      state?: string | null;
      stateDescription?: string | null;
      sellerId?: string | null;
      currency?: string | null;
      offersEnabled?: boolean | null;
      isBuyerOfferEligible?: boolean | null;
      brandSlug?: string | null;
      categoryRootUuid?: string | null;
      make?: string | null;
      bumpEligible?: boolean | null;
      shopId?: string | null;
      inventory?: number | null;
      soldAsIs?: boolean | null;
      acceptedPaymentMethods?: Array<string | null> | null;
      condition?: {
        __typename?: "core_apimessages_Condition";
        displayName?: string | null;
        conditionSlug?: string | null;
        conditionUuid?: string | null;
      } | null;
      price?: {
        __typename?: "core_apimessages_Money";
        amountCents?: number | null;
        display?: string | null;
      } | null;
      pricing?: {
        __typename?: "core_apimessages_ListingPricing";
        buyerPrice?: {
          __typename?: "core_apimessages_Money";
          display?: string | null;
          currency?: string | null;
          amountCents?: number | null;
          amount?: string | null;
        } | null;
        originalPrice?: {
          __typename?: "core_apimessages_Money";
          display?: string | null;
        } | null;
        ribbon?: {
          __typename?: "core_apimessages_Ribbon";
          display?: string | null;
          reason?: core_apimessages_Ribbon_Reason | null;
        } | null;
      } | null;
      shipping?: {
        __typename?: "core_apimessages_ShippingPrices";
        freeExpeditedShipping?: boolean | null;
        localPickupOnly?: boolean | null;
        localPickup?: boolean | null;
        shippingPrices?: Array<{
          __typename?: "core_apimessages_ShippingPrice";
          _id?: string | null;
          shippingMethod?: core_apimessages_ShippingMethod | null;
          carrierCalculated?: boolean | null;
          destinationPostalCodeNeeded?: boolean | null;
          rate?: {
            __typename?: "core_apimessages_Money";
            amount?: string | null;
            amountCents?: number | null;
            currency?: string | null;
            display?: string | null;
          } | null;
        } | null> | null;
      } | null;
      shop?: {
        __typename?: "Shop";
        _id?: string | null;
        address?: {
          __typename?: "core_apimessages_Address";
          _id?: string | null;
          countryCode?: string | null;
          country?: {
            __typename?: "core_apimessages_Country";
            _id?: string | null;
            countryCode?: string | null;
            name?: string | null;
          } | null;
        } | null;
        returnPolicy?: {
          __typename?: "core_apimessages_ReturnPolicy";
          _id?: string | null;
          newReturnWindowDays?: number | null;
          usedReturnWindowDays?: number | null;
        } | null;
      } | null;
      preorderInfo?: {
        __typename?: "core_apimessages_PreorderInfo";
        onPreorder?: boolean | null;
        estimatedShipDate?: {
          __typename?: "google_protobuf_Timestamp";
          seconds?: number | null;
        } | null;
      } | null;
      watchThumbnails?: Array<{
        __typename?: "core_apimessages_Image";
        _id?: string | null;
        source?: string | null;
      } | null> | null;
      priceRecommendation?: {
        __typename?: "reverb_pricing_PriceRecommendation";
        priceMiddle?: {
          __typename?: "reverb_pricing_Money";
          amountCents?: number | null;
          currency?: string | null;
        } | null;
      } | null;
      images?: Array<{
        __typename?: "core_apimessages_Image";
        _id?: string | null;
        source?: string | null;
      } | null> | null;
      largeSquareImages?: Array<{
        __typename?: "core_apimessages_Image";
        _id?: string | null;
        source?: string | null;
      } | null> | null;
      certifiedPreOwned?: {
        __typename?: "core_apimessages_ListingCertifiedPreOwned";
        title?: string | null;
        badgeIconUrl?: string | null;
      } | null;
      categories?: Array<{
        __typename?: "Category";
        _id?: string | null;
        slug?: string | null;
        rootSlug?: string | null;
      } | null> | null;
      csp?: {
        __typename?: "CSP";
        _id?: string | null;
        id?: string | null;
        slug?: string | null;
        brand?: {
          __typename?: "Brand";
          _id?: string | null;
          slug?: string | null;
        } | null;
      } | null;
      publishedAt?: {
        __typename?: "google_protobuf_Timestamp";
        seconds?: number | null;
      } | null;
      sale?: {
        __typename?: "core_apimessages_Sale";
        _id?: string | null;
        id?: string | null;
        code?: string | null;
        buyerIneligibilityReason?: string | null;
      } | null;
    } | null> | null;
  } | null;
};

export type UndoDeleteMyFavoriteMutationVariables = Exact<{
  input?: InputMaybe<Input_core_apimessages_UndoDeleteMyFavoriteRequest>;
}>;

export type UndoDeleteMyFavoriteMutation = {
  __typename?: "Mutation";
  undoDeleteMyFavorite?: {
    __typename?: "core_apimessages_UndoDeleteMyFavoriteResponse";
    favorite?: {
      __typename?: "core_apimessages_Favorite";
      id?: string | null;
      favorited?: boolean | null;
      favoriteType?: core_apimessages_FavoriteType | null;
    } | null;
    listing?: {
      __typename?: "Listing";
      _id?: string | null;
      id?: string | null;
      watching?: boolean | null;
    } | null;
  } | null;
};

export type InOtherCartsCardDataFragment = {
  __typename?: "Listing";
  _id?: string | null;
  id?: string | null;
  otherBuyersWithListingInCartCounts?: number | null;
};

export type ListingCardFieldsFragment = {
  __typename?: "Listing";
  _id?: string | null;
  id?: string | null;
  title?: string | null;
  slug?: string | null;
  listingType?: core_apimessages_Listing_ListingType | null;
  make?: string | null;
  model?: string | null;
  upc?: string | null;
  state?: string | null;
  stateDescription?: string | null;
  bumped?: boolean | null;
  watching?: boolean | null;
  soldAsIs?: boolean | null;
  usOutlet?: boolean | null;
  sellerId?: string | null;
  offersEnabled?: boolean | null;
  isBuyerOfferEligible?: boolean | null;
  currency?: string | null;
  brandSlug?: string | null;
  categoryRootUuid?: string | null;
  categoryUuids?: Array<string | null> | null;
  bumpEligible?: boolean | null;
  shopId?: string | null;
  inventory?: number | null;
  acceptedPaymentMethods?: Array<string | null> | null;
  publishedAt?: {
    __typename?: "google_protobuf_Timestamp";
    seconds?: number | null;
  } | null;
  condition?: {
    __typename?: "core_apimessages_Condition";
    displayName?: string | null;
    conditionSlug?: string | null;
    conditionUuid?: string | null;
  } | null;
  pricing?: {
    __typename?: "core_apimessages_ListingPricing";
    buyerPrice?: {
      __typename?: "core_apimessages_Money";
      display?: string | null;
      currency?: string | null;
      amount?: string | null;
      amountCents?: number | null;
    } | null;
    originalPrice?: {
      __typename?: "core_apimessages_Money";
      display?: string | null;
    } | null;
    ribbon?: {
      __typename?: "core_apimessages_Ribbon";
      display?: string | null;
      reason?: core_apimessages_Ribbon_Reason | null;
    } | null;
  } | null;
  images?: Array<{
    __typename?: "core_apimessages_Image";
    source?: string | null;
  } | null> | null;
  shipping?: {
    __typename?: "core_apimessages_ShippingPrices";
    freeExpeditedShipping?: boolean | null;
    localPickupOnly?: boolean | null;
    localPickup?: boolean | null;
    shippingPrices?: Array<{
      __typename?: "core_apimessages_ShippingPrice";
      _id?: string | null;
      shippingMethod?: core_apimessages_ShippingMethod | null;
      carrierCalculated?: boolean | null;
      destinationPostalCodeNeeded?: boolean | null;
      regional?: boolean | null;
      postalCode?: string | null;
      rate?: {
        __typename?: "core_apimessages_Money";
        amount?: string | null;
        amountCents?: number | null;
        currency?: string | null;
        display?: string | null;
      } | null;
    } | null> | null;
  } | null;
  shop?: {
    __typename?: "Shop";
    _id?: string | null;
    name?: string | null;
    returnPolicy?: {
      __typename?: "core_apimessages_ReturnPolicy";
      usedReturnWindowDays?: number | null;
      newReturnWindowDays?: number | null;
      _id?: string | null;
    } | null;
    address?: {
      __typename?: "core_apimessages_Address";
      _id?: string | null;
      locality?: string | null;
      region?: string | null;
      displayLocation?: string | null;
      countryCode?: string | null;
      country?: {
        __typename?: "core_apimessages_Country";
        _id?: string | null;
        countryCode?: string | null;
        name?: string | null;
      } | null;
    } | null;
  } | null;
  watchThumbnails?: Array<{
    __typename?: "core_apimessages_Image";
    _id?: string | null;
    source?: string | null;
  } | null> | null;
  priceRecommendation?: {
    __typename?: "reverb_pricing_PriceRecommendation";
    priceMiddle?: {
      __typename?: "reverb_pricing_Money";
      amountCents?: number | null;
      currency?: string | null;
    } | null;
  } | null;
  certifiedPreOwned?: {
    __typename?: "core_apimessages_ListingCertifiedPreOwned";
    title?: string | null;
    badgeIconUrl?: string | null;
  } | null;
  categories?: Array<{
    __typename?: "Category";
    _id?: string | null;
    slug?: string | null;
    rootSlug?: string | null;
  } | null> | null;
  csp?: {
    __typename?: "CSP";
    _id?: string | null;
    id?: string | null;
    slug?: string | null;
    brand?: {
      __typename?: "Brand";
      _id?: string | null;
      slug?: string | null;
    } | null;
  } | null;
  sale?: {
    __typename?: "core_apimessages_Sale";
    _id?: string | null;
    id?: string | null;
    code?: string | null;
    buyerIneligibilityReason?: string | null;
  } | null;
};

export type ListingCardCPODataFragment = {
  __typename?: "Listing";
  _id?: string | null;
  id?: string | null;
  certifiedPreOwned?: {
    __typename?: "core_apimessages_ListingCertifiedPreOwned";
    title?: string | null;
    badgeIconUrl?: string | null;
  } | null;
};

export type ListingCardSignalsDataFragment = {
  __typename?: "reverb_signals_Signal";
  name?: reverb_signals_Signal_Name | null;
  title?: string | null;
  icon?: string | null;
};

export type ListingShippingDisplayDataFragment = {
  __typename?: "Listing";
  _id?: string | null;
  shop?: {
    __typename?: "Shop";
    _id?: string | null;
    freeDomesticShippingRate?: {
      __typename?: "core_apimessages_ShippingRates";
      regionCode?: string | null;
      freeShippingThreshold?: {
        __typename?: "core_apimessages_Money";
        amountCents?: number | null;
        currency?: string | null;
      } | null;
    } | null;
  } | null;
  shipping?: {
    __typename?: "core_apimessages_ShippingPrices";
    freeExpeditedShipping?: boolean | null;
    localPickupOnly?: boolean | null;
    shippingPrices?: Array<{
      __typename?: "core_apimessages_ShippingPrice";
      _id?: string | null;
      shippingMethod?: core_apimessages_ShippingMethod | null;
      carrierCalculated?: boolean | null;
      regional?: boolean | null;
      destinationPostalCodeNeeded?: boolean | null;
      postalCode?: string | null;
      rate?: {
        __typename?: "core_apimessages_Money";
        amount?: string | null;
        amountCents?: number | null;
        currency?: string | null;
        display?: string | null;
      } | null;
    } | null> | null;
  } | null;
};

export type BumpKeyFragment = {
  __typename?: "Listing";
  _id?: string | null;
  bumpKey?: {
    __typename?: "core_apimessages_BumpKey";
    key?: string | null;
  } | null;
};

export type FinancingFieldsFragment = {
  __typename?: "Listing";
  _id?: string | null;
  estimatedMonthlyPaymentPlan?: {
    __typename?: "core_apimessages_EstimatedMonthlyPayment";
    estimatedMonthlyPayment?: {
      __typename?: "core_apimessages_Money";
      display?: string | null;
    } | null;
  } | null;
};

export type ListingImagesFieldsFragment = {
  __typename?: "Listing";
  _id?: string | null;
  images?: Array<{
    __typename?: "core_apimessages_Image";
    _id?: string | null;
    source?: string | null;
  } | null> | null;
  largeSquareImages?: Array<{
    __typename?: "core_apimessages_Image";
    _id?: string | null;
    source?: string | null;
  } | null> | null;
};

export type ShopFieldsFragment = {
  __typename?: "Listing";
  _id?: string | null;
  shop?: {
    __typename?: "Shop";
    _id?: string | null;
    address?: {
      __typename?: "core_apimessages_Address";
      _id?: string | null;
      locality?: string | null;
      countryCode?: string | null;
      region?: string | null;
    } | null;
  } | null;
};

export type ListingsCollectionFragment = {
  __typename?: "Listing";
  _id?: string | null;
  id?: string | null;
  usOutlet?: boolean | null;
  bumped?: boolean | null;
  categoryUuids?: Array<string | null> | null;
  slug?: string | null;
  title?: string | null;
  description?: string | null;
  listingType?: core_apimessages_Listing_ListingType | null;
  watching?: boolean | null;
  state?: string | null;
  stateDescription?: string | null;
  sellerId?: string | null;
  currency?: string | null;
  offersEnabled?: boolean | null;
  isBuyerOfferEligible?: boolean | null;
  brandSlug?: string | null;
  categoryRootUuid?: string | null;
  make?: string | null;
  bumpEligible?: boolean | null;
  shopId?: string | null;
  inventory?: number | null;
  soldAsIs?: boolean | null;
  acceptedPaymentMethods?: Array<string | null> | null;
  condition?: {
    __typename?: "core_apimessages_Condition";
    displayName?: string | null;
    conditionSlug?: string | null;
    conditionUuid?: string | null;
  } | null;
  price?: {
    __typename?: "core_apimessages_Money";
    amountCents?: number | null;
    display?: string | null;
  } | null;
  pricing?: {
    __typename?: "core_apimessages_ListingPricing";
    buyerPrice?: {
      __typename?: "core_apimessages_Money";
      display?: string | null;
      currency?: string | null;
      amountCents?: number | null;
      amount?: string | null;
    } | null;
    originalPrice?: {
      __typename?: "core_apimessages_Money";
      display?: string | null;
    } | null;
    ribbon?: {
      __typename?: "core_apimessages_Ribbon";
      display?: string | null;
      reason?: core_apimessages_Ribbon_Reason | null;
    } | null;
  } | null;
  shipping?: {
    __typename?: "core_apimessages_ShippingPrices";
    freeExpeditedShipping?: boolean | null;
    localPickupOnly?: boolean | null;
    localPickup?: boolean | null;
    shippingPrices?: Array<{
      __typename?: "core_apimessages_ShippingPrice";
      _id?: string | null;
      shippingMethod?: core_apimessages_ShippingMethod | null;
      carrierCalculated?: boolean | null;
      destinationPostalCodeNeeded?: boolean | null;
      rate?: {
        __typename?: "core_apimessages_Money";
        amount?: string | null;
        amountCents?: number | null;
        currency?: string | null;
        display?: string | null;
      } | null;
    } | null> | null;
  } | null;
  shop?: {
    __typename?: "Shop";
    _id?: string | null;
    address?: {
      __typename?: "core_apimessages_Address";
      _id?: string | null;
      countryCode?: string | null;
      country?: {
        __typename?: "core_apimessages_Country";
        _id?: string | null;
        countryCode?: string | null;
        name?: string | null;
      } | null;
    } | null;
    returnPolicy?: {
      __typename?: "core_apimessages_ReturnPolicy";
      _id?: string | null;
      newReturnWindowDays?: number | null;
      usedReturnWindowDays?: number | null;
    } | null;
  } | null;
  preorderInfo?: {
    __typename?: "core_apimessages_PreorderInfo";
    onPreorder?: boolean | null;
    estimatedShipDate?: {
      __typename?: "google_protobuf_Timestamp";
      seconds?: number | null;
    } | null;
  } | null;
  watchThumbnails?: Array<{
    __typename?: "core_apimessages_Image";
    _id?: string | null;
    source?: string | null;
  } | null> | null;
  priceRecommendation?: {
    __typename?: "reverb_pricing_PriceRecommendation";
    priceMiddle?: {
      __typename?: "reverb_pricing_Money";
      amountCents?: number | null;
      currency?: string | null;
    } | null;
  } | null;
  images?: Array<{
    __typename?: "core_apimessages_Image";
    _id?: string | null;
    source?: string | null;
  } | null> | null;
  largeSquareImages?: Array<{
    __typename?: "core_apimessages_Image";
    _id?: string | null;
    source?: string | null;
  } | null> | null;
  certifiedPreOwned?: {
    __typename?: "core_apimessages_ListingCertifiedPreOwned";
    title?: string | null;
    badgeIconUrl?: string | null;
  } | null;
  categories?: Array<{
    __typename?: "Category";
    _id?: string | null;
    slug?: string | null;
    rootSlug?: string | null;
  } | null> | null;
  csp?: {
    __typename?: "CSP";
    _id?: string | null;
    id?: string | null;
    slug?: string | null;
    brand?: {
      __typename?: "Brand";
      _id?: string | null;
      slug?: string | null;
    } | null;
  } | null;
  publishedAt?: {
    __typename?: "google_protobuf_Timestamp";
    seconds?: number | null;
  } | null;
  sale?: {
    __typename?: "core_apimessages_Sale";
    _id?: string | null;
    id?: string | null;
    code?: string | null;
    buyerIneligibilityReason?: string | null;
  } | null;
};

export type ModalSidebarListingFragmentFragment = {
  __typename?: "Listing";
  _id?: string | null;
  id?: string | null;
  title?: string | null;
  slug?: string | null;
  condition?: {
    __typename?: "core_apimessages_Condition";
    displayName?: string | null;
  } | null;
  images?: Array<{
    __typename?: "core_apimessages_Image";
    source?: string | null;
  } | null> | null;
};

export type PhotoTileFieldsFragment = {
  __typename?: "Listing";
  _id?: string | null;
  id?: string | null;
  slug?: string | null;
  title?: string | null;
  images?: Array<{
    __typename?: "core_apimessages_Image";
    _id?: string | null;
    source?: string | null;
  } | null> | null;
};

export type Item2PriceWithShippingCartItemDataFragment = {
  __typename?: "rql_Me";
  _id?: string | null;
  uuid?: string | null;
  cart?: {
    __typename?: "rql_MyCart";
    cartItems?: Array<{
      __typename?: "core_apimessages_CartItem";
      _id?: string | null;
      shopId?: string | null;
      listingId?: string | null;
      subtotal?: {
        __typename?: "core_apimessages_Money";
        amountCents?: number | null;
        currency?: string | null;
      } | null;
    } | null> | null;
  } | null;
};

export type Item2PriceWithShippingDataFragment = {
  __typename?: "Listing";
  _id?: string | null;
  id?: string | null;
  state?: string | null;
  stateDescription?: string | null;
  taxIncluded?: boolean | null;
  taxIncludedHint?: string | null;
  listingType?: core_apimessages_Listing_ListingType | null;
  pricing?: {
    __typename?: "core_apimessages_ListingPricing";
    originalPriceDescription?: string | null;
    originalPrice?: {
      __typename?: "core_apimessages_Money";
      display?: string | null;
    } | null;
    ribbon?: {
      __typename?: "core_apimessages_Ribbon";
      display?: string | null;
      reason?: core_apimessages_Ribbon_Reason | null;
    } | null;
    buyerPrice?: {
      __typename?: "core_apimessages_Money";
      amountCents?: number | null;
      currency?: string | null;
      display?: string | null;
    } | null;
    typicalNewPriceDisplay?: {
      __typename?: "core_apimessages_TypicalNewPriceDisplay";
      amountDisplay?: string | null;
    } | null;
  } | null;
  shop?: {
    __typename?: "Shop";
    _id?: string | null;
    id?: string | null;
    freeDomesticShippingRate?: {
      __typename?: "core_apimessages_ShippingRates";
      regionCode?: string | null;
      freeShippingThreshold?: {
        __typename?: "core_apimessages_Money";
        amountCents?: number | null;
        currency?: string | null;
      } | null;
    } | null;
  } | null;
  condition?: {
    __typename?: "core_apimessages_Condition";
    conditionUuid?: string | null;
  } | null;
  shipping?: {
    __typename?: "core_apimessages_ShippingPrices";
    freeExpeditedShipping?: boolean | null;
    localPickupOnly?: boolean | null;
    shippingPrices?: Array<{
      __typename?: "core_apimessages_ShippingPrice";
      _id?: string | null;
      shippingMethod?: core_apimessages_ShippingMethod | null;
      carrierCalculated?: boolean | null;
      regional?: boolean | null;
      destinationPostalCodeNeeded?: boolean | null;
      postalCode?: string | null;
      rate?: {
        __typename?: "core_apimessages_Money";
        amount?: string | null;
        amountCents?: number | null;
        currency?: string | null;
        display?: string | null;
      } | null;
    } | null> | null;
  } | null;
};

export type Core_Homepage_RecentlyViewedSimilarListingsSearchQueryVariables =
  Exact<{
    limit?: InputMaybe<Scalars["Int"]["input"]>;
    recentSearchId0?: InputMaybe<Scalars["String"]["input"]>;
    recentSearchId1?: InputMaybe<Scalars["String"]["input"]>;
    skipQuery1: Scalars["Boolean"]["input"];
  }>;

export type Core_Homepage_RecentlyViewedSimilarListingsSearchQuery = {
  __typename?: "Query";
  similarListings0?: {
    __typename?: "reverb_search_SearchResponse";
    listings?: Array<{
      __typename?: "Listing";
      _id?: string | null;
      id?: string | null;
      usOutlet?: boolean | null;
      bumped?: boolean | null;
      categoryUuids?: Array<string | null> | null;
      slug?: string | null;
      title?: string | null;
      description?: string | null;
      listingType?: core_apimessages_Listing_ListingType | null;
      watching?: boolean | null;
      state?: string | null;
      stateDescription?: string | null;
      brandSlug?: string | null;
      categoryRootUuid?: string | null;
      make?: string | null;
      bumpEligible?: boolean | null;
      shopId?: string | null;
      inventory?: number | null;
      soldAsIs?: boolean | null;
      acceptedPaymentMethods?: Array<string | null> | null;
      currency?: string | null;
      sellerId?: string | null;
      offersEnabled?: boolean | null;
      isBuyerOfferEligible?: boolean | null;
      condition?: {
        __typename?: "core_apimessages_Condition";
        displayName?: string | null;
        conditionSlug?: string | null;
        conditionUuid?: string | null;
      } | null;
      price?: {
        __typename?: "core_apimessages_Money";
        amountCents?: number | null;
        display?: string | null;
      } | null;
      pricing?: {
        __typename?: "core_apimessages_ListingPricing";
        buyerPrice?: {
          __typename?: "core_apimessages_Money";
          display?: string | null;
          currency?: string | null;
          amountCents?: number | null;
          amount?: string | null;
        } | null;
        originalPrice?: {
          __typename?: "core_apimessages_Money";
          display?: string | null;
        } | null;
        ribbon?: {
          __typename?: "core_apimessages_Ribbon";
          display?: string | null;
          reason?: core_apimessages_Ribbon_Reason | null;
        } | null;
      } | null;
      shipping?: {
        __typename?: "core_apimessages_ShippingPrices";
        freeExpeditedShipping?: boolean | null;
        localPickupOnly?: boolean | null;
        localPickup?: boolean | null;
        shippingPrices?: Array<{
          __typename?: "core_apimessages_ShippingPrice";
          _id?: string | null;
          shippingMethod?: core_apimessages_ShippingMethod | null;
          carrierCalculated?: boolean | null;
          destinationPostalCodeNeeded?: boolean | null;
          rate?: {
            __typename?: "core_apimessages_Money";
            amount?: string | null;
            amountCents?: number | null;
            currency?: string | null;
            display?: string | null;
          } | null;
        } | null> | null;
      } | null;
      shop?: {
        __typename?: "Shop";
        _id?: string | null;
        address?: {
          __typename?: "core_apimessages_Address";
          _id?: string | null;
          countryCode?: string | null;
          country?: {
            __typename?: "core_apimessages_Country";
            _id?: string | null;
            countryCode?: string | null;
            name?: string | null;
          } | null;
        } | null;
        returnPolicy?: {
          __typename?: "core_apimessages_ReturnPolicy";
          _id?: string | null;
          newReturnWindowDays?: number | null;
          usedReturnWindowDays?: number | null;
        } | null;
      } | null;
      categories?: Array<{
        __typename?: "Category";
        _id?: string | null;
        slug?: string | null;
        rootSlug?: string | null;
      } | null> | null;
      csp?: {
        __typename?: "CSP";
        _id?: string | null;
        id?: string | null;
        slug?: string | null;
        brand?: {
          __typename?: "Brand";
          _id?: string | null;
          slug?: string | null;
        } | null;
      } | null;
      publishedAt?: {
        __typename?: "google_protobuf_Timestamp";
        seconds?: number | null;
      } | null;
      sale?: {
        __typename?: "core_apimessages_Sale";
        _id?: string | null;
        id?: string | null;
        code?: string | null;
        buyerIneligibilityReason?: string | null;
      } | null;
      certifiedPreOwned?: {
        __typename?: "core_apimessages_ListingCertifiedPreOwned";
        title?: string | null;
        badgeIconUrl?: string | null;
      } | null;
      preorderInfo?: {
        __typename?: "core_apimessages_PreorderInfo";
        onPreorder?: boolean | null;
        estimatedShipDate?: {
          __typename?: "google_protobuf_Timestamp";
          seconds?: number | null;
        } | null;
      } | null;
      watchThumbnails?: Array<{
        __typename?: "core_apimessages_Image";
        _id?: string | null;
        source?: string | null;
      } | null> | null;
      priceRecommendation?: {
        __typename?: "reverb_pricing_PriceRecommendation";
        priceMiddle?: {
          __typename?: "reverb_pricing_Money";
          amountCents?: number | null;
          currency?: string | null;
        } | null;
      } | null;
      images?: Array<{
        __typename?: "core_apimessages_Image";
        _id?: string | null;
        source?: string | null;
      } | null> | null;
      largeSquareImages?: Array<{
        __typename?: "core_apimessages_Image";
        _id?: string | null;
        source?: string | null;
      } | null> | null;
    } | null> | null;
  } | null;
  similarListings1?: {
    __typename?: "reverb_search_SearchResponse";
    listings?: Array<{
      __typename?: "Listing";
      _id?: string | null;
      id?: string | null;
      usOutlet?: boolean | null;
      bumped?: boolean | null;
      categoryUuids?: Array<string | null> | null;
      slug?: string | null;
      title?: string | null;
      description?: string | null;
      listingType?: core_apimessages_Listing_ListingType | null;
      watching?: boolean | null;
      state?: string | null;
      stateDescription?: string | null;
      brandSlug?: string | null;
      categoryRootUuid?: string | null;
      make?: string | null;
      bumpEligible?: boolean | null;
      shopId?: string | null;
      inventory?: number | null;
      soldAsIs?: boolean | null;
      acceptedPaymentMethods?: Array<string | null> | null;
      currency?: string | null;
      sellerId?: string | null;
      offersEnabled?: boolean | null;
      isBuyerOfferEligible?: boolean | null;
      condition?: {
        __typename?: "core_apimessages_Condition";
        displayName?: string | null;
        conditionSlug?: string | null;
        conditionUuid?: string | null;
      } | null;
      price?: {
        __typename?: "core_apimessages_Money";
        amountCents?: number | null;
        display?: string | null;
      } | null;
      pricing?: {
        __typename?: "core_apimessages_ListingPricing";
        buyerPrice?: {
          __typename?: "core_apimessages_Money";
          display?: string | null;
          currency?: string | null;
          amountCents?: number | null;
          amount?: string | null;
        } | null;
        originalPrice?: {
          __typename?: "core_apimessages_Money";
          display?: string | null;
        } | null;
        ribbon?: {
          __typename?: "core_apimessages_Ribbon";
          display?: string | null;
          reason?: core_apimessages_Ribbon_Reason | null;
        } | null;
      } | null;
      shipping?: {
        __typename?: "core_apimessages_ShippingPrices";
        freeExpeditedShipping?: boolean | null;
        localPickupOnly?: boolean | null;
        localPickup?: boolean | null;
        shippingPrices?: Array<{
          __typename?: "core_apimessages_ShippingPrice";
          _id?: string | null;
          shippingMethod?: core_apimessages_ShippingMethod | null;
          carrierCalculated?: boolean | null;
          destinationPostalCodeNeeded?: boolean | null;
          rate?: {
            __typename?: "core_apimessages_Money";
            amount?: string | null;
            amountCents?: number | null;
            currency?: string | null;
            display?: string | null;
          } | null;
        } | null> | null;
      } | null;
      shop?: {
        __typename?: "Shop";
        _id?: string | null;
        address?: {
          __typename?: "core_apimessages_Address";
          _id?: string | null;
          countryCode?: string | null;
          country?: {
            __typename?: "core_apimessages_Country";
            _id?: string | null;
            countryCode?: string | null;
            name?: string | null;
          } | null;
        } | null;
        returnPolicy?: {
          __typename?: "core_apimessages_ReturnPolicy";
          _id?: string | null;
          newReturnWindowDays?: number | null;
          usedReturnWindowDays?: number | null;
        } | null;
      } | null;
      categories?: Array<{
        __typename?: "Category";
        _id?: string | null;
        slug?: string | null;
        rootSlug?: string | null;
      } | null> | null;
      csp?: {
        __typename?: "CSP";
        _id?: string | null;
        id?: string | null;
        slug?: string | null;
        brand?: {
          __typename?: "Brand";
          _id?: string | null;
          slug?: string | null;
        } | null;
      } | null;
      publishedAt?: {
        __typename?: "google_protobuf_Timestamp";
        seconds?: number | null;
      } | null;
      sale?: {
        __typename?: "core_apimessages_Sale";
        _id?: string | null;
        id?: string | null;
        code?: string | null;
        buyerIneligibilityReason?: string | null;
      } | null;
      certifiedPreOwned?: {
        __typename?: "core_apimessages_ListingCertifiedPreOwned";
        title?: string | null;
        badgeIconUrl?: string | null;
      } | null;
      preorderInfo?: {
        __typename?: "core_apimessages_PreorderInfo";
        onPreorder?: boolean | null;
        estimatedShipDate?: {
          __typename?: "google_protobuf_Timestamp";
          seconds?: number | null;
        } | null;
      } | null;
      watchThumbnails?: Array<{
        __typename?: "core_apimessages_Image";
        _id?: string | null;
        source?: string | null;
      } | null> | null;
      priceRecommendation?: {
        __typename?: "reverb_pricing_PriceRecommendation";
        priceMiddle?: {
          __typename?: "reverb_pricing_Money";
          amountCents?: number | null;
          currency?: string | null;
        } | null;
      } | null;
      images?: Array<{
        __typename?: "core_apimessages_Image";
        _id?: string | null;
        source?: string | null;
      } | null> | null;
      largeSquareImages?: Array<{
        __typename?: "core_apimessages_Image";
        _id?: string | null;
        source?: string | null;
      } | null> | null;
    } | null> | null;
  } | null;
};

export type Disco_RecentlyViewedListingPhotosRowQueryVariables = Exact<{
  [key: string]: never;
}>;

export type Disco_RecentlyViewedListingPhotosRowQuery = {
  __typename?: "Query";
  recentlyViewedListings?: Array<{
    __typename?: "Listing";
    _id?: string | null;
    id?: string | null;
    slug?: string | null;
    title?: string | null;
    images?: Array<{
      __typename?: "core_apimessages_Image";
      _id?: string | null;
      source?: string | null;
    } | null> | null;
  } | null> | null;
};

export type Commons_ShippingRegionFilterQueryVariables = Exact<{
  paramName?: InputMaybe<Scalars["String"]["input"]>;
  selectedValues?: InputMaybe<
    | Array<InputMaybe<Scalars["String"]["input"]>>
    | InputMaybe<Scalars["String"]["input"]>
  >;
}>;

export type Commons_ShippingRegionFilterQuery = {
  __typename?: "Query";
  shippingRegionFilter?: {
    __typename?: "reverb_search_ShippingRegionFilterResponse";
    filter?: {
      __typename?: "reverb_search_Filter";
      name?: string | null;
      aggregationName?: string | null;
      widgetType?: reverb_search_Filter_WidgetType | null;
      options?: Array<{
        __typename?: "reverb_search_FilterOption";
        name?: string | null;
        selected?: boolean | null;
        paramName?: string | null;
        setValues?: Array<string | null> | null;
        unsetValues?: Array<string | null> | null;
        all?: boolean | null;
        optionValue?: string | null;
        count?: {
          __typename?: "google_protobuf_Int32Value";
          value?: number | null;
        } | null;
      } | null> | null;
    } | null;
  } | null;
};

export type NestedFilterFragment = {
  __typename?: "reverb_search_Filter";
  name?: string | null;
  key?: string | null;
  aggregationName?: string | null;
  widgetType?: reverb_search_Filter_WidgetType | null;
  options?: Array<{
    __typename?: "reverb_search_FilterOption";
    name?: string | null;
    selected?: boolean | null;
    autoselected?: boolean | null;
    paramName?: string | null;
    setValues?: Array<string | null> | null;
    unsetValues?: Array<string | null> | null;
    all?: boolean | null;
    optionValue?: string | null;
    trackingValue?: string | null;
    count?: {
      __typename?: "google_protobuf_Int32Value";
      value?: number | null;
    } | null;
    subFilter?: {
      __typename?: "reverb_search_Filter";
      widgetType?: reverb_search_Filter_WidgetType | null;
      options?: Array<{
        __typename?: "reverb_search_FilterOption";
        name?: string | null;
        selected?: boolean | null;
        autoselected?: boolean | null;
        paramName?: string | null;
        setValues?: Array<string | null> | null;
        unsetValues?: Array<string | null> | null;
        all?: boolean | null;
        optionValue?: string | null;
        trackingValue?: string | null;
        count?: {
          __typename?: "google_protobuf_Int32Value";
          value?: number | null;
        } | null;
        subFilter?: {
          __typename?: "reverb_search_Filter";
          widgetType?: reverb_search_Filter_WidgetType | null;
          options?: Array<{
            __typename?: "reverb_search_FilterOption";
            name?: string | null;
            selected?: boolean | null;
            autoselected?: boolean | null;
            paramName?: string | null;
            setValues?: Array<string | null> | null;
            unsetValues?: Array<string | null> | null;
            all?: boolean | null;
            optionValue?: string | null;
            trackingValue?: string | null;
            count?: {
              __typename?: "google_protobuf_Int32Value";
              value?: number | null;
            } | null;
            subFilter?: {
              __typename?: "reverb_search_Filter";
              widgetType?: reverb_search_Filter_WidgetType | null;
              options?: Array<{
                __typename?: "reverb_search_FilterOption";
                name?: string | null;
                selected?: boolean | null;
                autoselected?: boolean | null;
                paramName?: string | null;
                setValues?: Array<string | null> | null;
                unsetValues?: Array<string | null> | null;
                all?: boolean | null;
                optionValue?: string | null;
                trackingValue?: string | null;
                count?: {
                  __typename?: "google_protobuf_Int32Value";
                  value?: number | null;
                } | null;
              } | null> | null;
            } | null;
          } | null> | null;
        } | null;
      } | null> | null;
    } | null;
  } | null> | null;
};

export type FlatFilterFragment = {
  __typename?: "reverb_search_Filter";
  name?: string | null;
  key?: string | null;
  aggregationName?: string | null;
  widgetType?: reverb_search_Filter_WidgetType | null;
  options?: Array<{
    __typename?: "reverb_search_FilterOption";
    name?: string | null;
    selected?: boolean | null;
    paramName?: string | null;
    setValues?: Array<string | null> | null;
    unsetValues?: Array<string | null> | null;
    all?: boolean | null;
    optionValue?: string | null;
    count?: {
      __typename?: "google_protobuf_Int32Value";
      value?: number | null;
    } | null;
  } | null> | null;
};

export type Commons_SavedSearch_ListingsCollectionQueryVariables = Exact<{
  searchUrlParams?: InputMaybe<Scalars["String"]["input"]>;
  sort?: InputMaybe<reverb_search_ListingsSearchRequest_Sort>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
}>;

export type Commons_SavedSearch_ListingsCollectionQuery = {
  __typename?: "Query";
  listingsSearch?: {
    __typename?: "reverb_search_SearchResponse";
    listings?: Array<{
      __typename?: "Listing";
      _id?: string | null;
      id?: string | null;
      usOutlet?: boolean | null;
      bumped?: boolean | null;
      categoryUuids?: Array<string | null> | null;
      slug?: string | null;
      title?: string | null;
      description?: string | null;
      listingType?: core_apimessages_Listing_ListingType | null;
      watching?: boolean | null;
      state?: string | null;
      stateDescription?: string | null;
      sellerId?: string | null;
      currency?: string | null;
      offersEnabled?: boolean | null;
      isBuyerOfferEligible?: boolean | null;
      brandSlug?: string | null;
      categoryRootUuid?: string | null;
      make?: string | null;
      bumpEligible?: boolean | null;
      shopId?: string | null;
      inventory?: number | null;
      soldAsIs?: boolean | null;
      acceptedPaymentMethods?: Array<string | null> | null;
      condition?: {
        __typename?: "core_apimessages_Condition";
        displayName?: string | null;
        conditionSlug?: string | null;
        conditionUuid?: string | null;
      } | null;
      price?: {
        __typename?: "core_apimessages_Money";
        amountCents?: number | null;
        display?: string | null;
      } | null;
      pricing?: {
        __typename?: "core_apimessages_ListingPricing";
        buyerPrice?: {
          __typename?: "core_apimessages_Money";
          display?: string | null;
          currency?: string | null;
          amountCents?: number | null;
          amount?: string | null;
        } | null;
        originalPrice?: {
          __typename?: "core_apimessages_Money";
          display?: string | null;
        } | null;
        ribbon?: {
          __typename?: "core_apimessages_Ribbon";
          display?: string | null;
          reason?: core_apimessages_Ribbon_Reason | null;
        } | null;
      } | null;
      shipping?: {
        __typename?: "core_apimessages_ShippingPrices";
        freeExpeditedShipping?: boolean | null;
        localPickupOnly?: boolean | null;
        localPickup?: boolean | null;
        shippingPrices?: Array<{
          __typename?: "core_apimessages_ShippingPrice";
          _id?: string | null;
          shippingMethod?: core_apimessages_ShippingMethod | null;
          carrierCalculated?: boolean | null;
          destinationPostalCodeNeeded?: boolean | null;
          rate?: {
            __typename?: "core_apimessages_Money";
            amount?: string | null;
            amountCents?: number | null;
            currency?: string | null;
            display?: string | null;
          } | null;
        } | null> | null;
      } | null;
      shop?: {
        __typename?: "Shop";
        _id?: string | null;
        address?: {
          __typename?: "core_apimessages_Address";
          _id?: string | null;
          countryCode?: string | null;
          country?: {
            __typename?: "core_apimessages_Country";
            _id?: string | null;
            countryCode?: string | null;
            name?: string | null;
          } | null;
        } | null;
        returnPolicy?: {
          __typename?: "core_apimessages_ReturnPolicy";
          _id?: string | null;
          newReturnWindowDays?: number | null;
          usedReturnWindowDays?: number | null;
        } | null;
      } | null;
      preorderInfo?: {
        __typename?: "core_apimessages_PreorderInfo";
        onPreorder?: boolean | null;
        estimatedShipDate?: {
          __typename?: "google_protobuf_Timestamp";
          seconds?: number | null;
        } | null;
      } | null;
      watchThumbnails?: Array<{
        __typename?: "core_apimessages_Image";
        _id?: string | null;
        source?: string | null;
      } | null> | null;
      priceRecommendation?: {
        __typename?: "reverb_pricing_PriceRecommendation";
        priceMiddle?: {
          __typename?: "reverb_pricing_Money";
          amountCents?: number | null;
          currency?: string | null;
        } | null;
      } | null;
      images?: Array<{
        __typename?: "core_apimessages_Image";
        _id?: string | null;
        source?: string | null;
      } | null> | null;
      largeSquareImages?: Array<{
        __typename?: "core_apimessages_Image";
        _id?: string | null;
        source?: string | null;
      } | null> | null;
      certifiedPreOwned?: {
        __typename?: "core_apimessages_ListingCertifiedPreOwned";
        title?: string | null;
        badgeIconUrl?: string | null;
      } | null;
      categories?: Array<{
        __typename?: "Category";
        _id?: string | null;
        slug?: string | null;
        rootSlug?: string | null;
      } | null> | null;
      csp?: {
        __typename?: "CSP";
        _id?: string | null;
        id?: string | null;
        slug?: string | null;
        brand?: {
          __typename?: "Brand";
          _id?: string | null;
          slug?: string | null;
        } | null;
      } | null;
      publishedAt?: {
        __typename?: "google_protobuf_Timestamp";
        seconds?: number | null;
      } | null;
      sale?: {
        __typename?: "core_apimessages_Sale";
        _id?: string | null;
        id?: string | null;
        code?: string | null;
        buyerIneligibilityReason?: string | null;
      } | null;
    } | null> | null;
  } | null;
};

export type SellerInfoDataFragment = {
  __typename?: "Listing";
  _id?: string | null;
  seller?: {
    __typename?: "User";
    _id?: string | null;
    feedbackSummary?: {
      __typename?: "core_apimessages_FeedbackSummary";
      receivedCount?: number | null;
      rollingRatingPercentage?: number | null;
    } | null;
  } | null;
  shop?: {
    __typename?: "Shop";
    _id?: string | null;
    name?: string | null;
    slug?: string | null;
    preferredSeller?: boolean | null;
    quickShipper?: boolean | null;
    quickResponder?: boolean | null;
    address?: {
      __typename?: "core_apimessages_Address";
      _id?: string | null;
      displayLocation?: string | null;
    } | null;
  } | null;
};

export type Commons_SimilarCspsRowQueryVariables = Exact<{
  similarCspSlug?: InputMaybe<Scalars["String"]["input"]>;
  similarListingId?: InputMaybe<Scalars["String"]["input"]>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
}>;

export type Commons_SimilarCspsRowQuery = {
  __typename?: "Query";
  cspSearch?: {
    __typename?: "reverb_search_SearchResponse";
    csps?: Array<{
      __typename?: "CSP";
      _id?: string | null;
      id?: string | null;
      title?: string | null;
      slug?: string | null;
      averageReviewRating?: number | null;
      reviewsCount?: number | null;
      finishes?: Array<string | null> | null;
      webLink?: {
        __typename?: "core_apimessages_Link";
        path?: string | null;
        href?: string | null;
      } | null;
      image?: {
        __typename?: "core_apimessages_Image";
        _id?: string | null;
        source?: string | null;
      } | null;
      brand?: {
        __typename?: "Brand";
        _id?: string | null;
        slug?: string | null;
        name?: string | null;
      } | null;
      categories?: Array<{
        __typename?: "Category";
        _id?: string | null;
        slug?: string | null;
        rootSlug?: string | null;
        name?: string | null;
        parentCategories?: Array<{
          __typename?: "Category";
          _id?: string | null;
          name?: string | null;
          slug?: string | null;
        } | null> | null;
      } | null> | null;
      inventory?: {
        __typename?: "core_apimessages_CSPInventory";
        usedTotal?: number | null;
        newTotal?: number | null;
        newLowPrice?: {
          __typename?: "core_apimessages_Money";
          display?: string | null;
          amount?: string | null;
          amountCents?: number | null;
          currency?: string | null;
        } | null;
        usedLowPrice?: {
          __typename?: "core_apimessages_Money";
          display?: string | null;
          amount?: string | null;
          amountCents?: number | null;
          currency?: string | null;
        } | null;
      } | null;
    } | null> | null;
  } | null;
};

export type Core_Search_CompletionsQueryQueryVariables = Exact<{
  query?: InputMaybe<Scalars["String"]["input"]>;
  skip: Scalars["Boolean"]["input"];
}>;

export type Core_Search_CompletionsQueryQuery = {
  __typename?: "Query";
  completions?: {
    __typename?: "reverb_search_SearchResponse";
    completions?: Array<{
      __typename?: "reverb_search_Completion";
      text?: string | null;
      type?: reverb_search_CompletionType | null;
      options?: Array<{
        __typename?: "reverb_search_SuggestOption";
        score?: number | null;
        slug?: string | null;
        id?: string | null;
        text?: string | null;
        output?: string | null;
        scopes?: Array<{
          __typename?: "reverb_search_SuggestOptionScope";
          output?: string | null;
          slug?: string | null;
          type?: reverb_search_SuggestOptionScope_Type | null;
        } | null> | null;
        completionPayload?: {
          __typename?: "reverb_search_SuggestOption_CompletionPayload";
          thumbnailUrl?: string | null;
          inventoryNew?: {
            __typename?: "reverb_search_Inventory";
            listingCount?: number | null;
            listingMinPrice?: {
              __typename?: "reverb_search_Money";
              cents?: number | null;
              currency?: string | null;
            } | null;
          } | null;
          inventoryUsed?: {
            __typename?: "reverb_search_Inventory";
            listingCount?: number | null;
            listingMinPrice?: {
              __typename?: "reverb_search_Money";
              cents?: number | null;
              currency?: string | null;
            } | null;
          } | null;
        } | null;
      } | null> | null;
    } | null> | null;
  } | null;
};

export type Commons_MParticle_ArticleQueryVariables = Exact<{
  slug?: InputMaybe<Scalars["String"]["input"]>;
}>;

export type Commons_MParticle_ArticleQuery = {
  __typename?: "Query";
  article?: {
    __typename?: "Article";
    _id?: string | null;
    categories?: Array<{
      __typename?: "core_apimessages_Category";
      _id?: string | null;
      slug?: string | null;
    } | null> | null;
  } | null;
};

export type Commons_MParticle_OrderBundlesQueryVariables = Exact<{
  id?: InputMaybe<Scalars["String"]["input"]>;
}>;

export type Commons_MParticle_OrderBundlesQuery = {
  __typename?: "Query";
  orderBundle?: {
    __typename?: "OrderBundle";
    _id?: string | null;
    id?: string | null;
    checkoutType?: core_apimessages_Checkout_Type | null;
    paymentMethod?: core_apimessages_Payment_PaymentMethod | null;
    localPickup?: boolean | null;
    orderType?: string | null;
    amountTax?: {
      __typename?: "core_apimessages_Money";
      amount?: string | null;
    } | null;
    amountShipping?: {
      __typename?: "core_apimessages_Money";
      amount?: string | null;
    } | null;
    amountProductSubtotal?: {
      __typename?: "core_apimessages_Money";
      amount?: string | null;
    } | null;
    orders?: {
      __typename?: "OrderCollection";
      nodes?: Array<{
        __typename?: "Order";
        _id?: string | null;
        id?: string | null;
        shopUuid?: string | null;
        quantity?: number | null;
        protectionPlanEligible?: boolean | null;
        settlementAmountProductSubtotal?: {
          __typename?: "core_apimessages_Money";
          amount?: string | null;
        } | null;
        listing?: {
          __typename?: "Listing";
          _id?: string | null;
          id?: string | null;
          title?: string | null;
          brandSlug?: string | null;
          categoryRootUuid?: string | null;
          make?: string | null;
          categoryUuids?: Array<string | null> | null;
          state?: string | null;
          listingType?: core_apimessages_Listing_ListingType | null;
          bumpEligible?: boolean | null;
          shopId?: string | null;
          inventory?: number | null;
          soldAsIs?: boolean | null;
          acceptedPaymentMethods?: Array<string | null> | null;
          currency?: string | null;
          usOutlet?: boolean | null;
          condition?: {
            __typename?: "core_apimessages_Condition";
            conditionUuid?: string | null;
            conditionSlug?: string | null;
          } | null;
          categories?: Array<{
            __typename?: "Category";
            _id?: string | null;
            slug?: string | null;
            rootSlug?: string | null;
          } | null> | null;
          csp?: {
            __typename?: "CSP";
            _id?: string | null;
            id?: string | null;
            slug?: string | null;
            brand?: {
              __typename?: "Brand";
              _id?: string | null;
              slug?: string | null;
            } | null;
          } | null;
          pricing?: {
            __typename?: "core_apimessages_ListingPricing";
            buyerPrice?: {
              __typename?: "core_apimessages_Money";
              amount?: string | null;
              currency?: string | null;
              amountCents?: number | null;
            } | null;
          } | null;
          publishedAt?: {
            __typename?: "google_protobuf_Timestamp";
            seconds?: number | null;
          } | null;
          sale?: {
            __typename?: "core_apimessages_Sale";
            _id?: string | null;
            id?: string | null;
            code?: string | null;
            buyerIneligibilityReason?: string | null;
          } | null;
          shipping?: {
            __typename?: "core_apimessages_ShippingPrices";
            freeExpeditedShipping?: boolean | null;
            localPickupOnly?: boolean | null;
            localPickup?: boolean | null;
            shippingPrices?: Array<{
              __typename?: "core_apimessages_ShippingPrice";
              _id?: string | null;
              shippingMethod?: core_apimessages_ShippingMethod | null;
              carrierCalculated?: boolean | null;
              destinationPostalCodeNeeded?: boolean | null;
              rate?: {
                __typename?: "core_apimessages_Money";
                amount?: string | null;
                amountCents?: number | null;
                currency?: string | null;
                display?: string | null;
              } | null;
            } | null> | null;
          } | null;
          certifiedPreOwned?: {
            __typename?: "core_apimessages_ListingCertifiedPreOwned";
            title?: string | null;
            badgeIconUrl?: string | null;
          } | null;
          shop?: {
            __typename?: "Shop";
            _id?: string | null;
            address?: {
              __typename?: "core_apimessages_Address";
              _id?: string | null;
              countryCode?: string | null;
            } | null;
            returnPolicy?: {
              __typename?: "core_apimessages_ReturnPolicy";
              _id?: string | null;
              newReturnWindowDays?: number | null;
              usedReturnWindowDays?: number | null;
            } | null;
          } | null;
        } | null;
        payments?: Array<{
          __typename?: "core_apimessages_Payment";
          paymentMethod?: core_apimessages_Payment_PaymentMethod | null;
        } | null> | null;
      } | null> | null;
    } | null;
  } | null;
};

export type TypicalNewPriceDisplayFieldsFragment = {
  __typename?: "Listing";
  _id?: string | null;
  id?: string | null;
  condition?: {
    __typename?: "core_apimessages_Condition";
    conditionUuid?: string | null;
  } | null;
  pricing?: {
    __typename?: "core_apimessages_ListingPricing";
    originalPriceDescription?: string | null;
    typicalNewPriceDisplay?: {
      __typename?: "core_apimessages_TypicalNewPriceDisplay";
      amountDisplay?: string | null;
    } | null;
  } | null;
};

export type ReverifyCreditCardMutationVariables = Exact<{
  input?: InputMaybe<Input_core_apimessages_ReverifyCreditCardRequest>;
}>;

export type ReverifyCreditCardMutation = {
  __typename?: "Mutation";
  reverifyCreditCard?: {
    __typename?: "core_apimessages_ReverifyCreditCardResponse";
    creditCard?: {
      __typename?: "core_apimessages_CreditCard";
      id?: string | null;
      last4?: string | null;
      cardType?: string | null;
      expirationYear?: string | null;
      expirationMonth?: string | null;
      cardholderName?: string | null;
      needsReverification?: boolean | null;
      primaryForCheckout?: boolean | null;
    } | null;
  } | null;
};

export type WatchBadgeDataFragment = {
  __typename?: "Listing";
  _id?: string | null;
  id?: string | null;
  title?: string | null;
  sellerId?: string | null;
  watching?: boolean | null;
  watchThumbnails?: Array<{
    __typename?: "core_apimessages_Image";
    _id?: string | null;
    source?: string | null;
  } | null> | null;
};

export type CreateMyWatchesMutationVariables = Exact<{
  input?: InputMaybe<Input_core_apimessages_WatchesCreateRequest>;
}>;

export type CreateMyWatchesMutation = {
  __typename?: "Mutation";
  createWatch?: {
    __typename?: "core_apimessages_UpdateWatchResponse";
    id?: string | null;
    listing?: {
      __typename?: "Listing";
      id?: string | null;
      _id?: string | null;
      watching?: boolean | null;
    } | null;
  } | null;
};

export type DeleteMyWatchesMutationVariables = Exact<{
  input?: InputMaybe<Input_core_apimessages_WatchesDeleteRequest>;
}>;

export type DeleteMyWatchesMutation = {
  __typename?: "Mutation";
  deleteWatch?: {
    __typename?: "core_apimessages_UpdateWatchResponse";
    id?: string | null;
    listing?: {
      __typename?: "Listing";
      _id?: string | null;
      id?: string | null;
      watching?: boolean | null;
    } | null;
  } | null;
};

export type Commons_WatchedListingsQueryVariables = Exact<{
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  showListingLocation: Scalars["Boolean"]["input"];
  shouldSkipTracking: Scalars["Boolean"]["input"];
}>;

export type Commons_WatchedListingsQuery = {
  __typename?: "Query";
  me?: {
    __typename?: "rql_Me";
    _id?: string | null;
    uuid?: string | null;
    profile?: {
      __typename?: "mparticle_ProfileResponse";
      topProductTypeUuids?: Array<string | null> | null;
      topProductTypes?: Array<string | null> | null;
    } | null;
    watches?: {
      __typename?: "WatchCollection";
      nodes?: Array<{
        __typename?: "Watch";
        _id?: string | null;
        listing?: {
          __typename?: "Listing";
          _id?: string | null;
          id?: string | null;
          usOutlet?: boolean | null;
          bumped?: boolean | null;
          categoryUuids?: Array<string | null> | null;
          slug?: string | null;
          title?: string | null;
          description?: string | null;
          listingType?: core_apimessages_Listing_ListingType | null;
          watching?: boolean | null;
          state?: string | null;
          stateDescription?: string | null;
          brandSlug?: string | null;
          categoryRootUuid?: string | null;
          make?: string | null;
          bumpEligible?: boolean | null;
          shopId?: string | null;
          inventory?: number | null;
          soldAsIs?: boolean | null;
          acceptedPaymentMethods?: Array<string | null> | null;
          currency?: string | null;
          sellerId?: string | null;
          offersEnabled?: boolean | null;
          isBuyerOfferEligible?: boolean | null;
          condition?: {
            __typename?: "core_apimessages_Condition";
            displayName?: string | null;
            conditionSlug?: string | null;
            conditionUuid?: string | null;
          } | null;
          price?: {
            __typename?: "core_apimessages_Money";
            amountCents?: number | null;
            display?: string | null;
          } | null;
          pricing?: {
            __typename?: "core_apimessages_ListingPricing";
            buyerPrice?: {
              __typename?: "core_apimessages_Money";
              display?: string | null;
              currency?: string | null;
              amountCents?: number | null;
              amount?: string | null;
            } | null;
            originalPrice?: {
              __typename?: "core_apimessages_Money";
              display?: string | null;
            } | null;
            ribbon?: {
              __typename?: "core_apimessages_Ribbon";
              display?: string | null;
              reason?: core_apimessages_Ribbon_Reason | null;
            } | null;
          } | null;
          shipping?: {
            __typename?: "core_apimessages_ShippingPrices";
            freeExpeditedShipping?: boolean | null;
            localPickupOnly?: boolean | null;
            localPickup?: boolean | null;
            shippingPrices?: Array<{
              __typename?: "core_apimessages_ShippingPrice";
              _id?: string | null;
              shippingMethod?: core_apimessages_ShippingMethod | null;
              carrierCalculated?: boolean | null;
              destinationPostalCodeNeeded?: boolean | null;
              rate?: {
                __typename?: "core_apimessages_Money";
                amount?: string | null;
                amountCents?: number | null;
                currency?: string | null;
                display?: string | null;
              } | null;
            } | null> | null;
          } | null;
          shop?: {
            __typename?: "Shop";
            _id?: string | null;
            address?: {
              __typename?: "core_apimessages_Address";
              _id?: string | null;
              countryCode?: string | null;
              locality?: string | null;
              region?: string | null;
              country?: {
                __typename?: "core_apimessages_Country";
                _id?: string | null;
                countryCode?: string | null;
                name?: string | null;
              } | null;
            } | null;
            returnPolicy?: {
              __typename?: "core_apimessages_ReturnPolicy";
              _id?: string | null;
              newReturnWindowDays?: number | null;
              usedReturnWindowDays?: number | null;
            } | null;
          } | null;
          categories?: Array<{
            __typename?: "Category";
            _id?: string | null;
            slug?: string | null;
            rootSlug?: string | null;
          } | null> | null;
          csp?: {
            __typename?: "CSP";
            _id?: string | null;
            id?: string | null;
            slug?: string | null;
            brand?: {
              __typename?: "Brand";
              _id?: string | null;
              slug?: string | null;
            } | null;
          } | null;
          publishedAt?: {
            __typename?: "google_protobuf_Timestamp";
            seconds?: number | null;
          } | null;
          sale?: {
            __typename?: "core_apimessages_Sale";
            _id?: string | null;
            id?: string | null;
            code?: string | null;
            buyerIneligibilityReason?: string | null;
          } | null;
          certifiedPreOwned?: {
            __typename?: "core_apimessages_ListingCertifiedPreOwned";
            title?: string | null;
            badgeIconUrl?: string | null;
          } | null;
          preorderInfo?: {
            __typename?: "core_apimessages_PreorderInfo";
            onPreorder?: boolean | null;
            estimatedShipDate?: {
              __typename?: "google_protobuf_Timestamp";
              seconds?: number | null;
            } | null;
          } | null;
          watchThumbnails?: Array<{
            __typename?: "core_apimessages_Image";
            _id?: string | null;
            source?: string | null;
          } | null> | null;
          priceRecommendation?: {
            __typename?: "reverb_pricing_PriceRecommendation";
            priceMiddle?: {
              __typename?: "reverb_pricing_Money";
              amountCents?: number | null;
              currency?: string | null;
            } | null;
          } | null;
          images?: Array<{
            __typename?: "core_apimessages_Image";
            _id?: string | null;
            source?: string | null;
          } | null> | null;
          largeSquareImages?: Array<{
            __typename?: "core_apimessages_Image";
            _id?: string | null;
            source?: string | null;
          } | null> | null;
        } | null;
      } | null> | null;
    } | null;
  } | null;
};

export type Core_Get_Favorite_SearchesQueryQueryVariables = Exact<{
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
}>;

export type Core_Get_Favorite_SearchesQueryQuery = {
  __typename?: "Query";
  me?: {
    __typename?: "rql_Me";
    _id?: string | null;
    uuid?: string | null;
    favorites?: {
      __typename?: "rql_FavoritesResponse";
      favorites?: Array<{
        __typename?: "rql_Favorite";
        id?: string | null;
        title?: string | null;
        subtitle?: string | null;
        listingsSearchUrlParams?: string | null;
        favorite?: {
          __typename?: "core_apimessages_Favorite";
          id?: string | null;
          title?: string | null;
          subtitle?: string | null;
          favorited?: boolean | null;
          feedEnabled?: boolean | null;
          emailEnabled?: boolean | null;
          queryParams?: string | null;
          link?: {
            __typename?: "core_apimessages_Link";
            href?: string | null;
          } | null;
        } | null;
      } | null> | null;
    } | null;
  } | null;
};

export type mParticleListingFieldsFragment = {
  __typename?: "Listing";
  id?: string | null;
  _id?: string | null;
  title?: string | null;
  brandSlug?: string | null;
  categoryRootUuid?: string | null;
  make?: string | null;
  categoryUuids?: Array<string | null> | null;
  state?: string | null;
  listingType?: core_apimessages_Listing_ListingType | null;
  bumpEligible?: boolean | null;
  shopId?: string | null;
  inventory?: number | null;
  soldAsIs?: boolean | null;
  acceptedPaymentMethods?: Array<string | null> | null;
  currency?: string | null;
  usOutlet?: boolean | null;
  condition?: {
    __typename?: "core_apimessages_Condition";
    conditionUuid?: string | null;
    conditionSlug?: string | null;
  } | null;
  categories?: Array<{
    __typename?: "Category";
    _id?: string | null;
    slug?: string | null;
    rootSlug?: string | null;
  } | null> | null;
  csp?: {
    __typename?: "CSP";
    _id?: string | null;
    id?: string | null;
    slug?: string | null;
    brand?: {
      __typename?: "Brand";
      _id?: string | null;
      slug?: string | null;
    } | null;
  } | null;
  pricing?: {
    __typename?: "core_apimessages_ListingPricing";
    buyerPrice?: {
      __typename?: "core_apimessages_Money";
      amount?: string | null;
      currency?: string | null;
      amountCents?: number | null;
    } | null;
  } | null;
  publishedAt?: {
    __typename?: "google_protobuf_Timestamp";
    seconds?: number | null;
  } | null;
  sale?: {
    __typename?: "core_apimessages_Sale";
    _id?: string | null;
    id?: string | null;
    code?: string | null;
    buyerIneligibilityReason?: string | null;
  } | null;
  shipping?: {
    __typename?: "core_apimessages_ShippingPrices";
    freeExpeditedShipping?: boolean | null;
    localPickupOnly?: boolean | null;
    localPickup?: boolean | null;
    shippingPrices?: Array<{
      __typename?: "core_apimessages_ShippingPrice";
      _id?: string | null;
      shippingMethod?: core_apimessages_ShippingMethod | null;
      carrierCalculated?: boolean | null;
      destinationPostalCodeNeeded?: boolean | null;
      rate?: {
        __typename?: "core_apimessages_Money";
        amount?: string | null;
        amountCents?: number | null;
        currency?: string | null;
        display?: string | null;
      } | null;
    } | null> | null;
  } | null;
  certifiedPreOwned?: {
    __typename?: "core_apimessages_ListingCertifiedPreOwned";
    title?: string | null;
    badgeIconUrl?: string | null;
  } | null;
  shop?: {
    __typename?: "Shop";
    _id?: string | null;
    address?: {
      __typename?: "core_apimessages_Address";
      _id?: string | null;
      countryCode?: string | null;
    } | null;
    returnPolicy?: {
      __typename?: "core_apimessages_ReturnPolicy";
      _id?: string | null;
      newReturnWindowDays?: number | null;
      usedReturnWindowDays?: number | null;
    } | null;
  } | null;
};

export type Commons_MParticle_ListingQueryVariables = Exact<{
  ids?: InputMaybe<
    | Array<InputMaybe<Scalars["String"]["input"]>>
    | InputMaybe<Scalars["String"]["input"]>
  >;
  shouldSkipTracking: Scalars["Boolean"]["input"];
}>;

export type Commons_MParticle_ListingQuery = {
  __typename?: "Query";
  listings?: Array<{
    __typename?: "Listing";
    _id?: string | null;
    id?: string | null;
    title?: string | null;
    brandSlug?: string | null;
    categoryRootUuid?: string | null;
    make?: string | null;
    categoryUuids?: Array<string | null> | null;
    state?: string | null;
    listingType?: core_apimessages_Listing_ListingType | null;
    bumpEligible?: boolean | null;
    shopId?: string | null;
    inventory?: number | null;
    soldAsIs?: boolean | null;
    acceptedPaymentMethods?: Array<string | null> | null;
    currency?: string | null;
    usOutlet?: boolean | null;
    condition?: {
      __typename?: "core_apimessages_Condition";
      conditionUuid?: string | null;
      conditionSlug?: string | null;
    } | null;
    categories?: Array<{
      __typename?: "Category";
      _id?: string | null;
      slug?: string | null;
      rootSlug?: string | null;
    } | null> | null;
    csp?: {
      __typename?: "CSP";
      _id?: string | null;
      id?: string | null;
      slug?: string | null;
      brand?: {
        __typename?: "Brand";
        _id?: string | null;
        slug?: string | null;
      } | null;
    } | null;
    pricing?: {
      __typename?: "core_apimessages_ListingPricing";
      buyerPrice?: {
        __typename?: "core_apimessages_Money";
        amount?: string | null;
        currency?: string | null;
        amountCents?: number | null;
      } | null;
    } | null;
    publishedAt?: {
      __typename?: "google_protobuf_Timestamp";
      seconds?: number | null;
    } | null;
    sale?: {
      __typename?: "core_apimessages_Sale";
      _id?: string | null;
      id?: string | null;
      code?: string | null;
      buyerIneligibilityReason?: string | null;
    } | null;
    shipping?: {
      __typename?: "core_apimessages_ShippingPrices";
      freeExpeditedShipping?: boolean | null;
      localPickupOnly?: boolean | null;
      localPickup?: boolean | null;
      shippingPrices?: Array<{
        __typename?: "core_apimessages_ShippingPrice";
        _id?: string | null;
        shippingMethod?: core_apimessages_ShippingMethod | null;
        carrierCalculated?: boolean | null;
        destinationPostalCodeNeeded?: boolean | null;
        rate?: {
          __typename?: "core_apimessages_Money";
          amount?: string | null;
          amountCents?: number | null;
          currency?: string | null;
          display?: string | null;
        } | null;
      } | null> | null;
    } | null;
    certifiedPreOwned?: {
      __typename?: "core_apimessages_ListingCertifiedPreOwned";
      title?: string | null;
      badgeIconUrl?: string | null;
    } | null;
    shop?: {
      __typename?: "Shop";
      _id?: string | null;
      address?: {
        __typename?: "core_apimessages_Address";
        _id?: string | null;
        countryCode?: string | null;
      } | null;
      returnPolicy?: {
        __typename?: "core_apimessages_ReturnPolicy";
        _id?: string | null;
        newReturnWindowDays?: number | null;
        usedReturnWindowDays?: number | null;
      } | null;
    } | null;
  } | null> | null;
};

export type TrendingSearchesQueryQueryVariables = Exact<{
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  shouldSkipListings: Scalars["Boolean"]["input"];
}>;

export type TrendingSearchesQueryQuery = {
  __typename?: "Query";
  trendingSearches?: {
    __typename?: "reverb_personalization_TrendingSearchesResponse";
    trendingSearches?: Array<{
      __typename?: "reverb_personalization_TrendingSearch";
      query?: string | null;
      categoryUuid?: string | null;
      categorySlug?: string | null;
      queryParams?: string | null;
      listings?: Array<{
        __typename?: "Listing";
        _id?: string | null;
        id?: string | null;
        state?: string | null;
        slug?: string | null;
        images?: Array<{
          __typename?: "core_apimessages_Image";
          _id?: string | null;
          source?: string | null;
        } | null> | null;
      } | null> | null;
    } | null> | null;
  } | null;
};

export type Core_Exchange_RatesQueryVariables = Exact<{ [key: string]: never }>;

export type Core_Exchange_RatesQuery = {
  __typename?: "Query";
  exchangeRates?: {
    __typename?: "reverb_config_cache_CurrencyResponse";
    rates?: Array<{
      __typename?: "reverb_config_cache_ExchangeRate";
      from?: string | null;
      to?: string | null;
      rate?: number | null;
    } | null> | null;
  } | null;
};

export type ListingGreatValueDataFragment = {
  __typename?: "Listing";
  _id?: string | null;
  currency?: string | null;
  pricing?: {
    __typename?: "core_apimessages_ListingPricing";
    buyerPrice?: {
      __typename?: "core_apimessages_Money";
      currency?: string | null;
      amountCents?: number | null;
    } | null;
  } | null;
  condition?: {
    __typename?: "core_apimessages_Condition";
    conditionSlug?: string | null;
  } | null;
  priceRecommendation?: {
    __typename?: "reverb_pricing_PriceRecommendation";
    priceMiddle?: {
      __typename?: "reverb_pricing_Money";
      amountCents?: number | null;
      currency?: string | null;
    } | null;
  } | null;
  shop?: {
    __typename?: "Shop";
    _id?: string | null;
    address?: {
      __typename?: "core_apimessages_Address";
      _id?: string | null;
      country?: {
        __typename?: "core_apimessages_Country";
        _id?: string | null;
        countryCode?: string | null;
      } | null;
    } | null;
  } | null;
};

export type ListingForBuyerShippingFieldsFragment = {
  __typename?: "Listing";
  _id?: string | null;
  shipping?: {
    __typename?: "core_apimessages_ShippingPrices";
    freeExpeditedShipping?: boolean | null;
    localPickupOnly?: boolean | null;
    shippingPrices?: Array<{
      __typename?: "core_apimessages_ShippingPrice";
      _id?: string | null;
      shippingMethod?: core_apimessages_ShippingMethod | null;
      carrierCalculated?: boolean | null;
      regional?: boolean | null;
      destinationPostalCodeNeeded?: boolean | null;
      postalCode?: string | null;
      rate?: {
        __typename?: "core_apimessages_Money";
        amount?: string | null;
        amountCents?: number | null;
        currency?: string | null;
        display?: string | null;
      } | null;
    } | null> | null;
  } | null;
};

export type ListingForBuyerFieldsFragment = {
  __typename?: "Listing";
  _id?: string | null;
  id?: string | null;
  title?: string | null;
  slug?: string | null;
  listingType?: core_apimessages_Listing_ListingType | null;
  make?: string | null;
  model?: string | null;
  upc?: string | null;
  state?: string | null;
  stateDescription?: string | null;
  bumped?: boolean | null;
  watching?: boolean | null;
  soldAsIs?: boolean | null;
  usOutlet?: boolean | null;
  currency?: string | null;
  publishedAt?: {
    __typename?: "google_protobuf_Timestamp";
    seconds?: number | null;
  } | null;
  condition?: {
    __typename?: "core_apimessages_Condition";
    displayName?: string | null;
    conditionSlug?: string | null;
    conditionUuid?: string | null;
  } | null;
  pricing?: {
    __typename?: "core_apimessages_ListingPricing";
    buyerPrice?: {
      __typename?: "core_apimessages_Money";
      display?: string | null;
      currency?: string | null;
      amount?: string | null;
      amountCents?: number | null;
    } | null;
    originalPrice?: {
      __typename?: "core_apimessages_Money";
      display?: string | null;
    } | null;
    ribbon?: {
      __typename?: "core_apimessages_Ribbon";
      display?: string | null;
      reason?: core_apimessages_Ribbon_Reason | null;
    } | null;
  } | null;
  images?: Array<{
    __typename?: "core_apimessages_Image";
    source?: string | null;
  } | null> | null;
  shipping?: {
    __typename?: "core_apimessages_ShippingPrices";
    freeExpeditedShipping?: boolean | null;
    localPickupOnly?: boolean | null;
    localPickup?: boolean | null;
    shippingPrices?: Array<{
      __typename?: "core_apimessages_ShippingPrice";
      _id?: string | null;
      shippingMethod?: core_apimessages_ShippingMethod | null;
      carrierCalculated?: boolean | null;
      destinationPostalCodeNeeded?: boolean | null;
      regional?: boolean | null;
      postalCode?: string | null;
      rate?: {
        __typename?: "core_apimessages_Money";
        amount?: string | null;
        amountCents?: number | null;
        currency?: string | null;
        display?: string | null;
      } | null;
    } | null> | null;
  } | null;
  shop?: {
    __typename?: "Shop";
    _id?: string | null;
    name?: string | null;
    returnPolicy?: {
      __typename?: "core_apimessages_ReturnPolicy";
      usedReturnWindowDays?: number | null;
      newReturnWindowDays?: number | null;
    } | null;
    address?: {
      __typename?: "core_apimessages_Address";
      _id?: string | null;
      locality?: string | null;
      region?: string | null;
      displayLocation?: string | null;
      country?: {
        __typename?: "core_apimessages_Country";
        _id?: string | null;
        countryCode?: string | null;
        name?: string | null;
      } | null;
    } | null;
  } | null;
  priceRecommendation?: {
    __typename?: "reverb_pricing_PriceRecommendation";
    priceMiddle?: {
      __typename?: "reverb_pricing_Money";
      amountCents?: number | null;
      currency?: string | null;
    } | null;
  } | null;
  certifiedPreOwned?: {
    __typename?: "core_apimessages_ListingCertifiedPreOwned";
    title?: string | null;
    badgeIconUrl?: string | null;
  } | null;
};

export type ListingCreateOfferButtonDataFragment = {
  __typename?: "Listing";
  id?: string | null;
  _id?: string | null;
  state?: string | null;
  offersEnabled?: boolean | null;
  listingType?: core_apimessages_Listing_ListingType | null;
  sellerId?: string | null;
  isBuyerOfferEligible?: boolean | null;
  title?: string | null;
  brandSlug?: string | null;
  categoryRootUuid?: string | null;
  make?: string | null;
  categoryUuids?: Array<string | null> | null;
  bumpEligible?: boolean | null;
  shopId?: string | null;
  inventory?: number | null;
  soldAsIs?: boolean | null;
  acceptedPaymentMethods?: Array<string | null> | null;
  currency?: string | null;
  usOutlet?: boolean | null;
  condition?: {
    __typename?: "core_apimessages_Condition";
    conditionUuid?: string | null;
    conditionSlug?: string | null;
  } | null;
  categories?: Array<{
    __typename?: "Category";
    _id?: string | null;
    slug?: string | null;
    rootSlug?: string | null;
  } | null> | null;
  csp?: {
    __typename?: "CSP";
    _id?: string | null;
    id?: string | null;
    slug?: string | null;
    brand?: {
      __typename?: "Brand";
      _id?: string | null;
      slug?: string | null;
    } | null;
  } | null;
  pricing?: {
    __typename?: "core_apimessages_ListingPricing";
    buyerPrice?: {
      __typename?: "core_apimessages_Money";
      amount?: string | null;
      currency?: string | null;
      amountCents?: number | null;
    } | null;
  } | null;
  publishedAt?: {
    __typename?: "google_protobuf_Timestamp";
    seconds?: number | null;
  } | null;
  sale?: {
    __typename?: "core_apimessages_Sale";
    _id?: string | null;
    id?: string | null;
    code?: string | null;
    buyerIneligibilityReason?: string | null;
  } | null;
  shipping?: {
    __typename?: "core_apimessages_ShippingPrices";
    freeExpeditedShipping?: boolean | null;
    localPickupOnly?: boolean | null;
    localPickup?: boolean | null;
    shippingPrices?: Array<{
      __typename?: "core_apimessages_ShippingPrice";
      _id?: string | null;
      shippingMethod?: core_apimessages_ShippingMethod | null;
      carrierCalculated?: boolean | null;
      destinationPostalCodeNeeded?: boolean | null;
      rate?: {
        __typename?: "core_apimessages_Money";
        amount?: string | null;
        amountCents?: number | null;
        currency?: string | null;
        display?: string | null;
      } | null;
    } | null> | null;
  } | null;
  certifiedPreOwned?: {
    __typename?: "core_apimessages_ListingCertifiedPreOwned";
    title?: string | null;
    badgeIconUrl?: string | null;
  } | null;
  shop?: {
    __typename?: "Shop";
    _id?: string | null;
    address?: {
      __typename?: "core_apimessages_Address";
      _id?: string | null;
      countryCode?: string | null;
    } | null;
    returnPolicy?: {
      __typename?: "core_apimessages_ReturnPolicy";
      _id?: string | null;
      newReturnWindowDays?: number | null;
      usedReturnWindowDays?: number | null;
    } | null;
  } | null;
};

export type Item2LoggedInOffersCountDataFragment = {
  __typename?: "rql_Me";
  _id?: string | null;
  id?: string | null;
  profile?: {
    __typename?: "mparticle_ProfileResponse";
    totalBuyerOffersCreated?: number | null;
  } | null;
};

export type Commons_Offers_ImmediatePaymentReviewQueryVariables = Exact<{
  input?: InputMaybe<Input_core_apimessages_EstimateNegotiationSalesTaxRequest>;
}>;

export type Commons_Offers_ImmediatePaymentReviewQuery = {
  __typename?: "Query";
  estimateNegotiationSalesTax?: {
    __typename?: "core_apimessages_EstimateNegotiationSalesTaxResponse";
    amountTax?: {
      __typename?: "core_apimessages_Money";
      display?: string | null;
      amountCents?: number | null;
    } | null;
  } | null;
};

export type SellerOfferListingFragmentFragment = {
  __typename?: "Listing";
  _id?: string | null;
  sellerPrice?: {
    __typename?: "core_apimessages_Money";
    amount?: string | null;
    amountCents?: number | null;
    currency?: string | null;
    symbol?: string | null;
    display?: string | null;
  } | null;
  sellerCost?: {
    __typename?: "core_apimessages_Money";
    display?: string | null;
  } | null;
};

export type OfferListingFragmentFragment = {
  __typename?: "Listing";
  _id?: string | null;
  id?: string | null;
  slug?: string | null;
  title?: string | null;
  hasInventory?: boolean | null;
  inventory?: number | null;
  taxIncluded?: boolean | null;
  taxIncludedHint?: string | null;
  canonicalProductId?: string | null;
  images?: Array<{
    __typename?: "core_apimessages_Image";
    _id?: string | null;
    source?: string | null;
  } | null> | null;
  condition?: {
    __typename?: "core_apimessages_Condition";
    displayName?: string | null;
    conditionSlug?: string | null;
  } | null;
  price?: {
    __typename?: "core_apimessages_Money";
    amount?: string | null;
    amountCents?: number | null;
    currency?: string | null;
    symbol?: string | null;
    display?: string | null;
  } | null;
  shop?: {
    __typename?: "Shop";
    _id?: string | null;
    name?: string | null;
    currency?: string | null;
    slug?: string | null;
    lowballOfferPercentage?: number | null;
    address?: {
      __typename?: "core_apimessages_Address";
      _id?: string | null;
      displayLocation?: string | null;
      locality?: string | null;
      region?: string | null;
      postalCode?: string | null;
      countryCode?: string | null;
    } | null;
  } | null;
  sale?: {
    __typename?: "core_apimessages_Sale";
    _id?: string | null;
    id?: string | null;
  } | null;
};

export type Commons_Offers_EditOfferFormQueryVariables = Exact<{
  listingId?: InputMaybe<Scalars["String"]["input"]>;
  negotiationId?: InputMaybe<Scalars["String"]["input"]>;
  isSeller: Scalars["Boolean"]["input"];
  shippingRegionCode?: InputMaybe<Scalars["String"]["input"]>;
}>;

export type Commons_Offers_EditOfferFormQuery = {
  __typename?: "Query";
  exchangeRates?: {
    __typename?: "reverb_config_cache_CurrencyResponse";
    rates?: Array<{
      __typename?: "reverb_config_cache_ExchangeRate";
      from?: string | null;
      to?: string | null;
      rate?: number | null;
    } | null> | null;
  } | null;
  listing?: {
    __typename?: "Listing";
    _id?: string | null;
    usOutlet?: boolean | null;
    id?: string | null;
    slug?: string | null;
    title?: string | null;
    hasInventory?: boolean | null;
    inventory?: number | null;
    taxIncluded?: boolean | null;
    taxIncludedHint?: string | null;
    canonicalProductId?: string | null;
    priceRecommendation?: {
      __typename?: "reverb_pricing_PriceRecommendation";
      priceLow?: {
        __typename?: "reverb_pricing_Money";
        amountCents?: number | null;
        currency?: string | null;
        display?: string | null;
      } | null;
      priceMiddle?: {
        __typename?: "reverb_pricing_Money";
        amountCents?: number | null;
        currency?: string | null;
      } | null;
      priceHigh?: {
        __typename?: "reverb_pricing_Money";
        amountCents?: number | null;
        currency?: string | null;
        display?: string | null;
      } | null;
    } | null;
    shipping?: {
      __typename?: "core_apimessages_ShippingPrices";
      localPickupOnly?: boolean | null;
    } | null;
    offerBotRule?: {
      __typename?: "core_apimessages_OfferBotRule";
      autoRejectPercentage?: number | null;
    } | null;
    images?: Array<{
      __typename?: "core_apimessages_Image";
      _id?: string | null;
      source?: string | null;
    } | null> | null;
    condition?: {
      __typename?: "core_apimessages_Condition";
      displayName?: string | null;
      conditionSlug?: string | null;
    } | null;
    price?: {
      __typename?: "core_apimessages_Money";
      amount?: string | null;
      amountCents?: number | null;
      currency?: string | null;
      symbol?: string | null;
      display?: string | null;
    } | null;
    shop?: {
      __typename?: "Shop";
      _id?: string | null;
      name?: string | null;
      currency?: string | null;
      slug?: string | null;
      lowballOfferPercentage?: number | null;
      address?: {
        __typename?: "core_apimessages_Address";
        _id?: string | null;
        displayLocation?: string | null;
        locality?: string | null;
        region?: string | null;
        postalCode?: string | null;
        countryCode?: string | null;
      } | null;
    } | null;
    sale?: {
      __typename?: "core_apimessages_Sale";
      _id?: string | null;
      id?: string | null;
    } | null;
    sellerPrice?: {
      __typename?: "core_apimessages_Money";
      amount?: string | null;
      amountCents?: number | null;
      currency?: string | null;
      symbol?: string | null;
      display?: string | null;
    } | null;
    sellerCost?: {
      __typename?: "core_apimessages_Money";
      display?: string | null;
    } | null;
  } | null;
  me?: {
    __typename?: "rql_Me";
    _id?: string | null;
    uuid?: string | null;
    activeNegotiation?: {
      __typename?: "reverb_search_SearchResponse";
      negotiations?: Array<{
        __typename?: "Negotiation";
        _id?: string | null;
        id?: string | null;
        buyerUuid?: string | null;
        sellerUuid?: string | null;
        taxIncluded?: boolean | null;
        taxIncludedHint?: string | null;
        sellerShippingWarning?: string | null;
        state?: core_apimessages_NegotiationState | null;
        buyerWillPayImmediately?: boolean | null;
        buyer?: {
          __typename?: "User";
          _id?: string | null;
          name?: string | null;
          shop?: {
            __typename?: "core_apimessages_Shop";
            name?: string | null;
            slug?: string | null;
          } | null;
        } | null;
        shippingLocation?: {
          __typename?: "core_apimessages_Address";
          _id?: string | null;
          displayLocation?: string | null;
        } | null;
        lastOffer?: {
          __typename?: "core_apimessages_Offer";
          status?: core_apimessages_Offer_OfferStatus | null;
          offerItems?: Array<{
            __typename?: "core_apimessages_OfferItem";
            quantity?: number | null;
          } | null> | null;
          prices?: {
            __typename?: "core_apimessages_OfferPrices";
            price?: {
              __typename?: "core_apimessages_ConvertedMoney";
              display?: {
                __typename?: "core_apimessages_Money";
                amount?: string | null;
                amountCents?: number | null;
                currency?: string | null;
                symbol?: string | null;
                display?: string | null;
              } | null;
              original?: {
                __typename?: "core_apimessages_Money";
                amount?: string | null;
                amountCents?: number | null;
                currency?: string | null;
                symbol?: string | null;
                display?: string | null;
              } | null;
            } | null;
            shippingPrice?: {
              __typename?: "core_apimessages_ConvertedMoney";
              display?: {
                __typename?: "core_apimessages_Money";
                amount?: string | null;
                amountCents?: number | null;
                currency?: string | null;
                symbol?: string | null;
                display?: string | null;
              } | null;
              original?: {
                __typename?: "core_apimessages_Money";
                amount?: string | null;
                amountCents?: number | null;
                currency?: string | null;
                symbol?: string | null;
                display?: string | null;
              } | null;
            } | null;
            totalPrice?: {
              __typename?: "core_apimessages_ConvertedMoney";
              display?: {
                __typename?: "core_apimessages_Money";
                amount?: string | null;
                amountCents?: number | null;
                currency?: string | null;
                symbol?: string | null;
                display?: string | null;
              } | null;
              original?: {
                __typename?: "core_apimessages_Money";
                amount?: string | null;
                amountCents?: number | null;
                currency?: string | null;
                symbol?: string | null;
                display?: string | null;
              } | null;
            } | null;
          } | null;
        } | null;
      } | null> | null;
    } | null;
    shippingAddresses?: Array<{
      __typename?: "core_apimessages_Address";
      _id?: string | null;
      id?: string | null;
      name?: string | null;
      displayLocation?: string | null;
      streetAddress?: string | null;
      isComplete?: boolean | null;
      countryCode?: string | null;
      primary?: boolean | null;
    } | null> | null;
    creditCards?: Array<{
      __typename?: "core_apimessages_CreditCard";
      id?: string | null;
      primaryForCheckout?: boolean | null;
      expirationYear?: string | null;
      expirationMonth?: string | null;
      needsReverification?: boolean | null;
      cardType?: string | null;
      display?: {
        __typename?: "core_apimessages_CreditCard_Display";
        summary?: string | null;
        expirationDate?: string | null;
      } | null;
      address?: {
        __typename?: "core_apimessages_Address";
        _id?: string | null;
        isComplete?: boolean | null;
      } | null;
      brand?: {
        __typename?: "core_apimessages_CreditCard_Brand";
        type?: core_apimessages_CreditCard_Brand_Type | null;
        name?: string | null;
      } | null;
    } | null> | null;
  } | null;
};

export type Commons_Offers_NewOfferFormQueryVariables = Exact<{
  listingId?: InputMaybe<Scalars["String"]["input"]>;
  shippingRegionCode?: InputMaybe<Scalars["String"]["input"]>;
  usePrimaryShippingAddress?: InputMaybe<Scalars["Boolean"]["input"]>;
  isSeller: Scalars["Boolean"]["input"];
  postalCode?: InputMaybe<Scalars["String"]["input"]>;
}>;

export type Commons_Offers_NewOfferFormQuery = {
  __typename?: "Query";
  countries?: {
    __typename?: "core_apimessages_CountriesResponse";
    countries?: Array<{
      __typename?: "core_apimessages_Country";
      _id?: string | null;
      name?: string | null;
      countryCode?: string | null;
    } | null> | null;
  } | null;
  exchangeRates?: {
    __typename?: "reverb_config_cache_CurrencyResponse";
    rates?: Array<{
      __typename?: "reverb_config_cache_ExchangeRate";
      from?: string | null;
      to?: string | null;
      rate?: number | null;
    } | null> | null;
  } | null;
  listing?: {
    __typename?: "Listing";
    _id?: string | null;
    usOutlet?: boolean | null;
    otherBuyersWithListingInCartCounts?: number | null;
    id?: string | null;
    slug?: string | null;
    title?: string | null;
    hasInventory?: boolean | null;
    inventory?: number | null;
    taxIncluded?: boolean | null;
    taxIncludedHint?: string | null;
    canonicalProductId?: string | null;
    shipping?: {
      __typename?: "core_apimessages_ShippingPrices";
      localPickupOnly?: boolean | null;
      shippingRegionCode?: string | null;
      shippingAddress?: {
        __typename?: "core_apimessages_Address";
        _id?: string | null;
        postalCode?: string | null;
      } | null;
      shippingPrices?: Array<{
        __typename?: "core_apimessages_ShippingPrice";
        _id?: string | null;
        shippingMethod?: core_apimessages_ShippingMethod | null;
        carrierCalculated?: boolean | null;
        destinationPostalCodeNeeded?: boolean | null;
        postalCode?: string | null;
        rate?: {
          __typename?: "core_apimessages_Money";
          amount?: string | null;
          amountCents?: number | null;
          currency?: string | null;
          symbol?: string | null;
          display?: string | null;
        } | null;
        originalRate?: {
          __typename?: "core_apimessages_Money";
          amount?: string | null;
          amountCents?: number | null;
          currency?: string | null;
          symbol?: string | null;
          display?: string | null;
        } | null;
      } | null> | null;
    } | null;
    offerBotRule?: {
      __typename?: "core_apimessages_OfferBotRule";
      autoRejectPercentage?: number | null;
    } | null;
    priceRecommendation?: {
      __typename?: "reverb_pricing_PriceRecommendation";
      priceLow?: {
        __typename?: "reverb_pricing_Money";
        amountCents?: number | null;
        currency?: string | null;
        display?: string | null;
      } | null;
      priceMiddle?: {
        __typename?: "reverb_pricing_Money";
        amountCents?: number | null;
        currency?: string | null;
      } | null;
      priceHigh?: {
        __typename?: "reverb_pricing_Money";
        amountCents?: number | null;
        currency?: string | null;
        display?: string | null;
      } | null;
    } | null;
    images?: Array<{
      __typename?: "core_apimessages_Image";
      _id?: string | null;
      source?: string | null;
    } | null> | null;
    condition?: {
      __typename?: "core_apimessages_Condition";
      displayName?: string | null;
      conditionSlug?: string | null;
    } | null;
    price?: {
      __typename?: "core_apimessages_Money";
      amount?: string | null;
      amountCents?: number | null;
      currency?: string | null;
      symbol?: string | null;
      display?: string | null;
    } | null;
    shop?: {
      __typename?: "Shop";
      _id?: string | null;
      name?: string | null;
      currency?: string | null;
      slug?: string | null;
      lowballOfferPercentage?: number | null;
      address?: {
        __typename?: "core_apimessages_Address";
        _id?: string | null;
        displayLocation?: string | null;
        locality?: string | null;
        region?: string | null;
        postalCode?: string | null;
        countryCode?: string | null;
      } | null;
    } | null;
    sale?: {
      __typename?: "core_apimessages_Sale";
      _id?: string | null;
      id?: string | null;
    } | null;
    sellerPrice?: {
      __typename?: "core_apimessages_Money";
      amount?: string | null;
      amountCents?: number | null;
      currency?: string | null;
      symbol?: string | null;
      display?: string | null;
    } | null;
    sellerCost?: {
      __typename?: "core_apimessages_Money";
      display?: string | null;
    } | null;
  } | null;
  me?: {
    __typename?: "rql_Me";
    _id?: string | null;
    uuid?: string | null;
    offerCount?: {
      __typename?: "reverb_search_SearchResponse";
      total?: number | null;
    } | null;
    shippingAddresses?: Array<{
      __typename?: "core_apimessages_Address";
      _id?: string | null;
      id?: string | null;
      name?: string | null;
      displayLocation?: string | null;
      streetAddress?: string | null;
      isComplete?: boolean | null;
      countryCode?: string | null;
      primary?: boolean | null;
    } | null> | null;
    creditCards?: Array<{
      __typename?: "core_apimessages_CreditCard";
      id?: string | null;
      primaryForCheckout?: boolean | null;
      expirationYear?: string | null;
      expirationMonth?: string | null;
      needsReverification?: boolean | null;
      cardType?: string | null;
      display?: {
        __typename?: "core_apimessages_CreditCard_Display";
        summary?: string | null;
        expirationDate?: string | null;
      } | null;
      address?: {
        __typename?: "core_apimessages_Address";
        _id?: string | null;
        isComplete?: boolean | null;
      } | null;
      brand?: {
        __typename?: "core_apimessages_CreditCard_Brand";
        type?: core_apimessages_CreditCard_Brand_Type | null;
        name?: string | null;
      } | null;
    } | null> | null;
  } | null;
};

export type Commons_Offers_CreateOfferFormMutationVariables = Exact<{
  input?: InputMaybe<Input_core_apimessages_CreateNegotiationRequest>;
}>;

export type Commons_Offers_CreateOfferFormMutation = {
  __typename?: "Mutation";
  createNegotiation?: {
    __typename?: "core_apimessages_NegotiationsResponse";
    negotiations?: Array<{
      __typename?: "core_apimessages_Negotiation";
      id?: string | null;
      lastOffer?: {
        __typename?: "core_apimessages_Offer";
        initiatingParty?: core_apimessages_NegotiationParty | null;
        offerItems?: Array<{
          __typename?: "core_apimessages_OfferItem";
          listingId?: string | null;
          quantity?: number | null;
        } | null> | null;
        prices?: {
          __typename?: "core_apimessages_OfferPrices";
          price?: {
            __typename?: "core_apimessages_ConvertedMoney";
            display?: {
              __typename?: "core_apimessages_Money";
              amount?: string | null;
              amountCents?: number | null;
              currency?: string | null;
              symbol?: string | null;
              display?: string | null;
            } | null;
            original?: {
              __typename?: "core_apimessages_Money";
              amount?: string | null;
              amountCents?: number | null;
              currency?: string | null;
              symbol?: string | null;
              display?: string | null;
            } | null;
          } | null;
          shippingPrice?: {
            __typename?: "core_apimessages_ConvertedMoney";
            display?: {
              __typename?: "core_apimessages_Money";
              amount?: string | null;
              amountCents?: number | null;
              currency?: string | null;
              symbol?: string | null;
              display?: string | null;
            } | null;
            original?: {
              __typename?: "core_apimessages_Money";
              amount?: string | null;
              amountCents?: number | null;
              currency?: string | null;
              symbol?: string | null;
              display?: string | null;
            } | null;
          } | null;
          totalPrice?: {
            __typename?: "core_apimessages_ConvertedMoney";
            display?: {
              __typename?: "core_apimessages_Money";
              amount?: string | null;
              amountCents?: number | null;
              currency?: string | null;
              symbol?: string | null;
              display?: string | null;
            } | null;
            original?: {
              __typename?: "core_apimessages_Money";
              amount?: string | null;
              amountCents?: number | null;
              currency?: string | null;
              symbol?: string | null;
              display?: string | null;
            } | null;
          } | null;
        } | null;
      } | null;
    } | null> | null;
  } | null;
};

export type Commons_Offers_UpdateOfferFormMutationVariables = Exact<{
  input?: InputMaybe<Input_core_apimessages_UpdateNegotiationRequest>;
}>;

export type Commons_Offers_UpdateOfferFormMutation = {
  __typename?: "Mutation";
  updateNegotiation?: {
    __typename?: "core_apimessages_UpdateNegotiationResponse";
    negotiation?: {
      __typename?: "core_apimessages_Negotiation";
      id?: string | null;
      state?: core_apimessages_NegotiationState | null;
      lastOffer?: {
        __typename?: "core_apimessages_Offer";
        prices?: {
          __typename?: "core_apimessages_OfferPrices";
          price?: {
            __typename?: "core_apimessages_ConvertedMoney";
            display?: {
              __typename?: "core_apimessages_Money";
              display?: string | null;
              amountCents?: number | null;
              currency?: string | null;
            } | null;
          } | null;
          shippingPrice?: {
            __typename?: "core_apimessages_ConvertedMoney";
            display?: {
              __typename?: "core_apimessages_Money";
              display?: string | null;
            } | null;
          } | null;
          totalPrice?: {
            __typename?: "core_apimessages_ConvertedMoney";
            display?: {
              __typename?: "core_apimessages_Money";
              display?: string | null;
            } | null;
          } | null;
        } | null;
      } | null;
    } | null;
  } | null;
};

export type AutoOfferListingFragmentFragment = {
  __typename?: "Listing";
  _id?: string | null;
  id?: string | null;
  title?: string | null;
  taxIncluded?: boolean | null;
  taxIncludedHint?: string | null;
  price?: {
    __typename?: "core_apimessages_Money";
    amount?: string | null;
    amountCents?: number | null;
    currency?: string | null;
    symbol?: string | null;
    display?: string | null;
  } | null;
  condition?: {
    __typename?: "core_apimessages_Condition";
    displayName?: string | null;
  } | null;
  sellerCost?: {
    __typename?: "core_apimessages_Money";
    display?: string | null;
  } | null;
  sellerPrice?: {
    __typename?: "core_apimessages_Money";
    amountCents?: number | null;
    display?: string | null;
    currency?: string | null;
  } | null;
  counts?: {
    __typename?: "core_apimessages_ListingCounts";
    watchers?: number | null;
  } | null;
  autoOffers?: {
    __typename?: "core_apimessages_AutoOffer";
    id?: string | null;
    listingId?: string | null;
    message?: string | null;
    percentage?: number | null;
    price?: {
      __typename?: "core_apimessages_Money";
      amount?: string | null;
      currency?: string | null;
    } | null;
    shippingPriceOverride?: {
      __typename?: "core_apimessages_Money";
      amount?: string | null;
      amountCents?: number | null;
      currency?: string | null;
      symbol?: string | null;
      display?: string | null;
    } | null;
  } | null;
  shipmentPackage?: {
    __typename?: "ShipmentPackage";
    _id?: string | null;
  } | null;
};

export type Core_AutoOffers_CreateAutoOfferMutationMutationVariables = Exact<{
  input?: InputMaybe<Input_core_apimessages_CreateAutoOfferRequest>;
}>;

export type Core_AutoOffers_CreateAutoOfferMutationMutation = {
  __typename?: "Mutation";
  createAutoOffer?: {
    __typename?: "core_apimessages_CreateAutoOfferResponse";
    autoOffer?: {
      __typename?: "core_apimessages_AutoOffer";
      id?: string | null;
      listingId?: string | null;
      message?: string | null;
      percentage?: number | null;
      price?: {
        __typename?: "core_apimessages_Money";
        amount?: string | null;
        amountCents?: number | null;
        currency?: string | null;
        symbol?: string | null;
        display?: string | null;
      } | null;
      shippingPriceOverride?: {
        __typename?: "core_apimessages_Money";
        amount?: string | null;
        amountCents?: number | null;
        currency?: string | null;
        symbol?: string | null;
        display?: string | null;
      } | null;
    } | null;
  } | null;
};

export type Core_AutoOffers_DeleteAutoOfferMutationMutationVariables = Exact<{
  input?: InputMaybe<Input_core_apimessages_DeleteAutoOfferRequest>;
}>;

export type Core_AutoOffers_DeleteAutoOfferMutationMutation = {
  __typename?: "Mutation";
  deleteAutoOffer?: {
    __typename?: "core_apimessages_DeleteAutoOfferResponse";
    autoOffer?: {
      __typename?: "core_apimessages_AutoOffer";
      id?: string | null;
      listingId?: string | null;
      message?: string | null;
      percentage?: number | null;
      price?: {
        __typename?: "core_apimessages_Money";
        amount?: string | null;
        amountCents?: number | null;
        currency?: string | null;
        symbol?: string | null;
        display?: string | null;
      } | null;
      shippingPriceOverride?: {
        __typename?: "core_apimessages_Money";
        amount?: string | null;
        amountCents?: number | null;
        currency?: string | null;
        symbol?: string | null;
        display?: string | null;
      } | null;
    } | null;
  } | null;
};

export type Core_Track_Bump_InteractionMutationVariables = Exact<{
  input?: InputMaybe<Input_core_apimessages_TrackBumpInteractionRequest>;
}>;

export type Core_Track_Bump_InteractionMutation = {
  __typename?: "Mutation";
  trackBumpInteraction?: {
    __typename?: "core_apimessages_TrackBumpInteractionResponse";
    bumpKey?: {
      __typename?: "core_apimessages_BumpKey";
      key?: string | null;
    } | null;
  } | null;
};

export type Core_FetchCountriesQueryVariables = Exact<{ [key: string]: never }>;

export type Core_FetchCountriesQuery = {
  __typename?: "Query";
  countries?: {
    __typename?: "core_apimessages_CountriesResponse";
    countries?: Array<{
      __typename?: "core_apimessages_Country";
      _id?: string | null;
      name?: string | null;
      countryCode?: string | null;
      subregionRequired?: boolean | null;
      subregions?: Array<{
        __typename?: "core_apimessages_Subregion";
        _id?: string | null;
        name?: string | null;
        code?: string | null;
      } | null> | null;
    } | null> | null;
  } | null;
};

export type Core_Test_UseMutationMutationVariables = Exact<{
  listingId?: InputMaybe<Scalars["String"]["input"]>;
  quantity?: InputMaybe<Scalars["Int"]["input"]>;
}>;

export type Core_Test_UseMutationMutation = {
  __typename?: "Mutation";
  editCart?: {
    __typename?: "core_apimessages_CheckoutCartEditResponse";
    listingId?: string | null;
  } | null;
};

export type CreateUserActionMeDataFragment = {
  __typename?: "rql_Me";
  _id?: string | null;
  uuid?: string | null;
};

export type ActionCacheFragmentDataFragment = {
  __typename?: "core_apimessages_AvailableAction";
  id?: core_apimessages_UserActionName | null;
  name?: core_apimessages_UserActionName | null;
  status?: core_apimessages_UserActionStatus | null;
};

export type Core_UserActions_CreateMutationVariables = Exact<{
  input?: InputMaybe<Input_core_apimessages_CreateMyActionRequest>;
}>;

export type Core_UserActions_CreateMutation = {
  __typename?: "Mutation";
  createMyAction?: {
    __typename?: "core_apimessages_CreateMyActionResponse";
    errors?: Array<{
      __typename?: "core_apimessages_Error";
      message?: string | null;
    } | null> | null;
    action?: {
      __typename?: "core_apimessages_AvailableAction";
      id?: core_apimessages_UserActionName | null;
      name?: core_apimessages_UserActionName | null;
      status?: core_apimessages_UserActionStatus | null;
    } | null;
  } | null;
};

export type Core_RequestAccountDeletionMutationVariables = Exact<{
  input?: InputMaybe<Input_core_apimessages_RequestAccountDeletionRequest>;
}>;

export type Core_RequestAccountDeletionMutation = {
  __typename?: "Mutation";
  requestAccountDeletion?: {
    __typename?: "core_apimessages_RequestAccountDeletionResponse";
    uuid?: string | null;
  } | null;
};

export type Core_AddressAutocomplete_FindAutocompleteSuggestionsQueryVariables =
  Exact<{
    query?: InputMaybe<Scalars["String"]["input"]>;
    containerId?: InputMaybe<Scalars["String"]["input"]>;
  }>;

export type Core_AddressAutocomplete_FindAutocompleteSuggestionsQuery = {
  __typename?: "Query";
  findAddressAutocompleteSuggestions?: {
    __typename?: "core_apimessages_FindAddressAutocompleteSuggestionsResponse";
    suggestions?: Array<{
      __typename?: "core_apimessages_FindAddressAutocompleteSuggestionsResponse_AutocompleteSuggestion";
      id?: string | null;
      type?: core_apimessages_FindAddressAutocompleteSuggestionsResponse_SuggestionType | null;
      text?: string | null;
    } | null> | null;
  } | null;
};

export type Core_AddressAutocomplete_SelectAutocompleteSuggestionMutationVariables =
  Exact<{
    id?: InputMaybe<Scalars["String"]["input"]>;
  }>;

export type Core_AddressAutocomplete_SelectAutocompleteSuggestionMutation = {
  __typename?: "Mutation";
  selectAddressAutocompleteSuggestion?: {
    __typename?: "core_apimessages_SelectAddressAutocompleteSuggestionResponse";
    suggestion?: {
      __typename?: "core_apimessages_Address";
      _id?: string | null;
      streetAddress?: string | null;
      extendedAddress?: string | null;
      locality?: string | null;
      region?: string | null;
      postalCode?: string | null;
      countryCode?: string | null;
    } | null;
  } | null;
};

export type Core_AdminRollouts_UserSearchQueryVariables = Exact<{
  search?: InputMaybe<Scalars["String"]["input"]>;
  skip: Scalars["Boolean"]["input"];
}>;

export type Core_AdminRollouts_UserSearchQuery = {
  __typename?: "Query";
  userSearch?: {
    __typename?: "core_apimessages_AdminUserSearchResponse";
    users?: Array<{
      __typename?: "core_apimessages_UserSearchResult";
      id?: string | null;
      shopName?: string | null;
      email?: string | null;
    } | null> | null;
  } | null;
};

export type Core_AdminRolloutsCompare_UserSearchQueryVariables = Exact<{
  search?: InputMaybe<Scalars["String"]["input"]>;
}>;

export type Core_AdminRolloutsCompare_UserSearchQuery = {
  __typename?: "Query";
  userSearch?: {
    __typename?: "core_apimessages_AdminUserSearchResponse";
    users?: Array<{
      __typename?: "core_apimessages_UserSearchResult";
      id?: string | null;
      shopName?: string | null;
      email?: string | null;
    } | null> | null;
  } | null;
};

export type BuyItNowButtonFieldsFragment = {
  __typename?: "Listing";
  _id?: string | null;
  id?: string | null;
  usOutlet?: boolean | null;
  acceptedPaymentMethods?: Array<string | null> | null;
  sellerId?: string | null;
  brandSlug?: string | null;
  currency?: string | null;
  adyenCheckoutPaymentMethodsConfig?: string | null;
  shipping?: {
    __typename?: "core_apimessages_ShippingPrices";
    localPickupOnly?: boolean | null;
  } | null;
  shop?: {
    __typename?: "Shop";
    _id?: string | null;
    address?: {
      __typename?: "core_apimessages_Address";
      _id?: string | null;
      countryCode?: string | null;
      country?: {
        __typename?: "core_apimessages_Country";
        _id?: string | null;
        name?: string | null;
      } | null;
    } | null;
  } | null;
  pricing?: {
    __typename?: "core_apimessages_ListingPricing";
    buyerPrice?: {
      __typename?: "core_apimessages_Money";
      amount?: string | null;
      amountCents?: number | null;
    } | null;
  } | null;
  certifiedPreOwned?: {
    __typename?: "core_apimessages_ListingCertifiedPreOwned";
    title?: string | null;
    description?: string | null;
    tooltipDescription?: string | null;
    badgeIconUrl?: string | null;
    brandName?: string | null;
    pageUrl?: string | null;
    lightModeBrandIconUrl?: string | null;
    darkModeBrandIconUrl?: string | null;
  } | null;
  expressPay?: {
    __typename?: "core_apimessages_ListingExpressPay";
    _id?: string | null;
    checkoutBundlingId?: string | null;
    paypal?: {
      __typename?: "core_apimessages_CheckoutPaypalDetails";
      currency?: string | null;
    } | null;
    estimatedTotal?: {
      __typename?: "core_apimessages_Money";
      amount?: string | null;
      currency?: string | null;
    } | null;
    googlePay?: {
      __typename?: "core_apimessages_CheckoutGooglePayDetails";
      merchantId?: string | null;
      merchantName?: string | null;
      gatewayMerchantId?: string | null;
      cardNetworks?: Array<core_apimessages_CheckoutGooglePayDetails_CardNetwork | null> | null;
    } | null;
  } | null;
};

export type BuyItNowExpressPayOptionsFieldsFragment = {
  __typename?: "Listing";
  _id?: string | null;
  id?: string | null;
  currency?: string | null;
  adyenCheckoutPaymentMethodsConfig?: string | null;
  expressPay?: {
    __typename?: "core_apimessages_ListingExpressPay";
    _id?: string | null;
    checkoutBundlingId?: string | null;
    paypal?: {
      __typename?: "core_apimessages_CheckoutPaypalDetails";
      currency?: string | null;
    } | null;
    estimatedTotal?: {
      __typename?: "core_apimessages_Money";
      amount?: string | null;
      currency?: string | null;
    } | null;
    googlePay?: {
      __typename?: "core_apimessages_CheckoutGooglePayDetails";
      merchantId?: string | null;
      merchantName?: string | null;
      gatewayMerchantId?: string | null;
      cardNetworks?: Array<core_apimessages_CheckoutGooglePayDetails_CardNetwork | null> | null;
    } | null;
  } | null;
  pricing?: {
    __typename?: "core_apimessages_ListingPricing";
    buyerPrice?: {
      __typename?: "core_apimessages_Money";
      amount?: string | null;
      amountCents?: number | null;
    } | null;
  } | null;
};

export type BuyItNowPaymentOptionsFieldsFragment = {
  __typename?: "Listing";
  _id?: string | null;
  acceptedPaymentMethods?: Array<string | null> | null;
  sellerId?: string | null;
  id?: string | null;
  currency?: string | null;
  adyenCheckoutPaymentMethodsConfig?: string | null;
  shop?: {
    __typename?: "Shop";
    _id?: string | null;
    address?: {
      __typename?: "core_apimessages_Address";
      _id?: string | null;
      countryCode?: string | null;
      country?: {
        __typename?: "core_apimessages_Country";
        _id?: string | null;
        name?: string | null;
      } | null;
    } | null;
  } | null;
  shipping?: {
    __typename?: "core_apimessages_ShippingPrices";
    localPickupOnly?: boolean | null;
  } | null;
  pricing?: {
    __typename?: "core_apimessages_ListingPricing";
    buyerPrice?: {
      __typename?: "core_apimessages_Money";
      amount?: string | null;
      amountCents?: number | null;
    } | null;
  } | null;
  expressPay?: {
    __typename?: "core_apimessages_ListingExpressPay";
    _id?: string | null;
    checkoutBundlingId?: string | null;
    paypal?: {
      __typename?: "core_apimessages_CheckoutPaypalDetails";
      currency?: string | null;
    } | null;
    estimatedTotal?: {
      __typename?: "core_apimessages_Money";
      amount?: string | null;
      currency?: string | null;
    } | null;
    googlePay?: {
      __typename?: "core_apimessages_CheckoutGooglePayDetails";
      merchantId?: string | null;
      merchantName?: string | null;
      gatewayMerchantId?: string | null;
      cardNetworks?: Array<core_apimessages_CheckoutGooglePayDetails_CardNetwork | null> | null;
    } | null;
  } | null;
};

export type Core_Checkout_FetchDigitalWalletPaymentCheckoutQueryVariables =
  Exact<{
    id?: InputMaybe<Scalars["String"]["input"]>;
    reverbApp?: InputMaybe<Scalars["String"]["input"]>;
  }>;

export type Core_Checkout_FetchDigitalWalletPaymentCheckoutQuery = {
  __typename?: "Query";
  checkout?: {
    __typename?: "Checkout";
    _id?: string | null;
    paymentErrorMessage?: string | null;
    action?: {
      __typename?: "CheckoutAction";
      _id?: string | null;
      type?: core_apimessages_CheckoutAction_Type | null;
      redirectUrl?: string | null;
    } | null;
  } | null;
};

export type StartCheckoutForListingMutationVariables = Exact<{
  input?: InputMaybe<Input_core_apimessages_StartCheckoutForListingRequest>;
}>;

export type StartCheckoutForListingMutation = {
  __typename?: "Mutation";
  startCheckoutForListing?: {
    __typename?: "core_apimessages_StartCheckoutForListingResponse";
    redirectUrl?: string | null;
  } | null;
};

export type BuyListingNowApplePayDataFragment = {
  __typename?: "Listing";
  _id?: string | null;
  id?: string | null;
  currency?: string | null;
  adyenCheckoutPaymentMethodsConfig?: string | null;
};

export type StartDigitalWalletCheckoutForListingMutationVariables = Exact<{
  input?: InputMaybe<Input_core_apimessages_StartCheckoutForListingRequest>;
}>;

export type StartDigitalWalletCheckoutForListingMutation = {
  __typename?: "Mutation";
  startCheckoutForListing?: {
    __typename?: "core_apimessages_StartCheckoutForListingResponse";
    checkoutId?: string | null;
  } | null;
};

export type UpdateShippingEstimateMutationMutationVariables = Exact<{
  input?: InputMaybe<Input_core_apimessages_CheckoutUpdateShippingEstimateRequest>;
}>;

export type UpdateShippingEstimateMutationMutation = {
  __typename?: "Mutation";
  updateCheckoutShippingEstimate?: {
    __typename?: "core_apimessages_CheckoutUpdateShippingEstimateResponse";
    checkout?: {
      __typename?: "Checkout";
      _id?: string | null;
      total?: {
        __typename?: "core_apimessages_Checkout_Total";
        label?: string | null;
        amount?: {
          __typename?: "core_apimessages_Money";
          amount?: string | null;
          amountCents?: number | null;
          currency?: string | null;
        } | null;
      } | null;
      lineItems?: Array<{
        __typename?: "core_apimessages_CheckoutLineItem";
        type?: core_apimessages_CheckoutLineItem_Type | null;
        label?: string | null;
        amount?: {
          __typename?: "core_apimessages_Money";
          amount?: string | null;
          amountCents?: number | null;
          currency?: string | null;
          symbol?: string | null;
          display?: string | null;
        } | null;
      } | null> | null;
    } | null;
  } | null;
};

export type FinalizeDigitalWalletPaymentMutationVariables = Exact<{
  input?: InputMaybe<Input_core_apimessages_CheckoutFinalizeDigitalWalletPaymentRequest>;
}>;

export type FinalizeDigitalWalletPaymentMutation = {
  __typename?: "Mutation";
  finalizeDigitalWalletPayment?: {
    __typename?: "core_apimessages_CheckoutFinalizeDigitalWalletPaymentResponse";
    id?: string | null;
  } | null;
};

export type CheckoutCardContinueButtonFieldsFragment = {
  __typename?: "Checkout";
  _id?: string | null;
  id?: string | null;
};

export type CheckoutCreditCardFormFieldsFragment = {
  __typename?: "Checkout";
  _id?: string | null;
  id?: string | null;
  shippingAddress?: {
    __typename?: "core_apimessages_Address";
    _id?: string | null;
    uuid?: string | null;
    id?: string | null;
    name?: string | null;
    streetAddress?: string | null;
    extendedAddress?: string | null;
    postalCode?: string | null;
    region?: string | null;
    regionName?: string | null;
    locality?: string | null;
    phone?: string | null;
    primary?: boolean | null;
    isComplete?: boolean | null;
    countryCode?: string | null;
    country?: {
      __typename?: "core_apimessages_Country";
      _id?: string | null;
      name?: string | null;
    } | null;
  } | null;
  paymentMethod?: {
    __typename?: "core_apimessages_CheckoutPaymentMethod";
    isComplete?: boolean | null;
  } | null;
  creditCard?: {
    __typename?: "core_apimessages_CreditCard";
    id?: string | null;
    needsReverification?: boolean | null;
    cardType?: string | null;
    brand?: {
      __typename?: "core_apimessages_CreditCard_Brand";
      type?: core_apimessages_CreditCard_Brand_Type | null;
      name?: string | null;
    } | null;
    display?: {
      __typename?: "core_apimessages_CreditCard_Display";
      summary?: string | null;
      expirationDate?: string | null;
    } | null;
  } | null;
  orders?: Array<{
    __typename?: "core_apimessages_CheckoutOrder";
    id?: string | null;
    shippingMethod?: {
      __typename?: "core_apimessages_CheckoutShippingMethod";
      type?: core_apimessages_ShippingMethod | null;
    } | null;
  } | null> | null;
};

export type CheckoutDiscountCodeFormFieldsFragment = {
  __typename?: "Checkout";
  _id?: string | null;
  id?: string | null;
  paymentMethod?: {
    __typename?: "core_apimessages_CheckoutPaymentMethod";
    supportsDiscountCodes?: boolean | null;
  } | null;
};

export type Core_Checkout_ApplyDiscountCodeMutationVariables = Exact<{
  input?: InputMaybe<Input_core_apimessages_ApplyCheckoutDiscountCodeRequest>;
}>;

export type Core_Checkout_ApplyDiscountCodeMutation = {
  __typename?: "Mutation";
  applyCheckoutDiscountCode?: {
    __typename?: "core_apimessages_ApplyCheckoutDiscountCodeResponse";
    checkout?: { __typename?: "Checkout"; _id?: string | null } | null;
  } | null;
};

export type Core_Checkout_HeaderQueryVariables = Exact<{
  id?: InputMaybe<Scalars["String"]["input"]>;
}>;

export type Core_Checkout_HeaderQuery = {
  __typename?: "Query";
  checkout?: {
    __typename?: "Checkout";
    _id?: string | null;
    id?: string | null;
    source?: core_apimessages_Checkout_Source | null;
    paymentMethod?: {
      __typename?: "core_apimessages_CheckoutPaymentMethod";
      type?: core_apimessages_CheckoutPaymentMethod_Type | null;
    } | null;
  } | null;
};

export type CheckoutKlarnaContinueButtonFieldsFragment = {
  __typename?: "Checkout";
  _id?: string | null;
  id?: string | null;
};

export type Core_Checkout_CreateKlarnaPaymentRedirectUrlMutationVariables =
  Exact<{
    input?: InputMaybe<Input_core_apimessages_CheckoutCreatePaymentRedirectUrlRequest>;
  }>;

export type Core_Checkout_CreateKlarnaPaymentRedirectUrlMutation = {
  __typename?: "Mutation";
  createCheckoutPaymentRedirectUrl?: {
    __typename?: "core_apimessages_CheckoutCreatePaymentRedirectUrlResponse";
    redirectUrl?: string | null;
    checkout?: { __typename?: "Checkout"; _id?: string | null } | null;
  } | null;
};

export type Core_Checkout_StartPaypalCheckout_RedirectMutationVariables =
  Exact<{
    input?: InputMaybe<Input_core_apimessages_StartPaypalCheckoutRequest>;
  }>;

export type Core_Checkout_StartPaypalCheckout_RedirectMutation = {
  __typename?: "Mutation";
  startPaypalCheckout?: {
    __typename?: "core_apimessages_StartPaypalCheckoutResponse";
    redirectUrl?: string | null;
    checkout?: { __typename?: "Checkout"; _id?: string | null } | null;
  } | null;
};

export type CheckoutShippingAddressFormFieldsFragment = {
  __typename?: "Checkout";
  _id?: string | null;
  id?: string | null;
  source?: core_apimessages_Checkout_Source | null;
  shippingAddress?: {
    __typename?: "core_apimessages_Address";
    _id?: string | null;
    countryCode?: string | null;
    name?: string | null;
    streetAddress?: string | null;
    extendedAddress?: string | null;
    locality?: string | null;
    region?: string | null;
    postalCode?: string | null;
    phone?: string | null;
    isComplete?: boolean | null;
    regionName?: string | null;
    id?: string | null;
  } | null;
  guest?: {
    __typename?: "core_apimessages_CheckoutGuest";
    firstName?: string | null;
    lastName?: string | null;
    email?: string | null;
  } | null;
  orders?: Array<{
    __typename?: "core_apimessages_CheckoutOrder";
    id?: string | null;
    shippingMethod?: {
      __typename?: "core_apimessages_CheckoutShippingMethod";
      type?: core_apimessages_ShippingMethod | null;
    } | null;
  } | null> | null;
  paymentMethod?: {
    __typename?: "core_apimessages_CheckoutPaymentMethod";
    type?: core_apimessages_CheckoutPaymentMethod_Type | null;
  } | null;
};

export type CheckoutTokenizedCardPaymentFormFieldsFragment = {
  __typename?: "Checkout";
  _id?: string | null;
  id?: string | null;
  orders?: Array<{
    __typename?: "core_apimessages_CheckoutOrder";
    id?: string | null;
    shippingMethod?: {
      __typename?: "core_apimessages_CheckoutShippingMethod";
      type?: core_apimessages_ShippingMethod | null;
    } | null;
  } | null> | null;
  shippingAddress?: {
    __typename?: "core_apimessages_Address";
    _id?: string | null;
    id?: string | null;
    uuid?: string | null;
    name?: string | null;
    streetAddress?: string | null;
    extendedAddress?: string | null;
    postalCode?: string | null;
    region?: string | null;
    regionName?: string | null;
    locality?: string | null;
    phone?: string | null;
    primary?: boolean | null;
    isComplete?: boolean | null;
    countryCode?: string | null;
    country?: {
      __typename?: "core_apimessages_Country";
      _id?: string | null;
      name?: string | null;
    } | null;
  } | null;
};

export type Core_Conversations_FlagMessageMutationVariables = Exact<{
  input?: InputMaybe<Input_core_apimessages_ConversationFlagMessageRequest>;
}>;

export type Core_Conversations_FlagMessageMutation = {
  __typename?: "Mutation";
  flagMessage?: {
    __typename?: "core_apimessages_ConversationFlagMessageResponse";
    id?: string | null;
  } | null;
};

export type CountriesDataFragment = {
  __typename?: "Query";
  countries?: {
    __typename?: "core_apimessages_CountriesResponse";
    countries?: Array<{
      __typename?: "core_apimessages_Country";
      _id?: string | null;
      name?: string | null;
      countryCode?: string | null;
    } | null> | null;
  } | null;
};

export type CreditCardDeleteButtonFieldsFragment = {
  __typename?: "rql_Me";
  _id?: string | null;
  creditCards?: Array<{
    __typename?: "core_apimessages_CreditCard";
    id?: string | null;
  } | null> | null;
};

export type Core_Checkout_DeleteCreditCardMutationVariables = Exact<{
  input?: InputMaybe<Input_core_apimessages_DeleteMyCreditCardRequest>;
}>;

export type Core_Checkout_DeleteCreditCardMutation = {
  __typename?: "Mutation";
  deleteMyCreditCard?: {
    __typename?: "core_apimessages_DeleteMyCreditCardResponse";
    id?: string | null;
  } | null;
};

export type Core_MyAccount_CreditCardsOnFileQueryVariables = Exact<{
  [key: string]: never;
}>;

export type Core_MyAccount_CreditCardsOnFileQuery = {
  __typename?: "Query";
  countries?: {
    __typename?: "core_apimessages_CountriesResponse";
    countries?: Array<{
      __typename?: "core_apimessages_Country";
      _id?: string | null;
      countryCode?: string | null;
      name?: string | null;
      subregionRequired?: boolean | null;
      subregions?: Array<{
        __typename?: "core_apimessages_Subregion";
        _id?: string | null;
        code?: string | null;
        name?: string | null;
      } | null> | null;
    } | null> | null;
  } | null;
  me?: {
    __typename?: "rql_Me";
    _id?: string | null;
    uuid?: string | null;
    creditCards?: Array<{
      __typename?: "core_apimessages_CreditCard";
      id?: string | null;
      last4?: string | null;
      expirationMonth?: string | null;
      expirationYear?: string | null;
      cardholderName?: string | null;
      primaryBilling?: boolean | null;
      primaryForCheckout?: boolean | null;
      brand?: {
        __typename?: "core_apimessages_CreditCard_Brand";
        type?: core_apimessages_CreditCard_Brand_Type | null;
        name?: string | null;
      } | null;
      display?: {
        __typename?: "core_apimessages_CreditCard_Display";
        summary?: string | null;
        expirationDate?: string | null;
      } | null;
      address?: {
        __typename?: "core_apimessages_Address";
        _id?: string | null;
        countryCode?: string | null;
        name?: string | null;
        streetAddress?: string | null;
        extendedAddress?: string | null;
        locality?: string | null;
        region?: string | null;
        postalCode?: string | null;
        phone?: string | null;
      } | null;
    } | null> | null;
  } | null;
};

export type ListingAffirmCalloutFieldsFragment = {
  __typename?: "Listing";
  _id?: string | null;
  estimatedMonthlyPaymentPlan?: {
    __typename?: "core_apimessages_EstimatedMonthlyPayment";
    financingProgramSlug?: string | null;
  } | null;
  pricing?: {
    __typename?: "core_apimessages_ListingPricing";
    buyerPrice?: {
      __typename?: "core_apimessages_Money";
      display?: string | null;
      amountCents?: number | null;
    } | null;
  } | null;
  shipping?: {
    __typename?: "core_apimessages_ShippingPrices";
    shippingPrices?: Array<{
      __typename?: "core_apimessages_ShippingPrice";
      _id?: string | null;
      shippingMethod?: core_apimessages_ShippingMethod | null;
      rate?: {
        __typename?: "core_apimessages_Money";
        amountCents?: number | null;
      } | null;
    } | null> | null;
  } | null;
};

export type ListingFinancingCalloutFieldsFragment = {
  __typename?: "Listing";
  _id?: string | null;
  acceptedPaymentMethods?: Array<string | null> | null;
  id?: string | null;
  pricing?: {
    __typename?: "core_apimessages_ListingPricing";
    buyerPrice?: {
      __typename?: "core_apimessages_Money";
      amountCents?: number | null;
      amount?: string | null;
      currency?: string | null;
      display?: string | null;
    } | null;
  } | null;
  estimatedMonthlyPaymentPlan?: {
    __typename?: "core_apimessages_EstimatedMonthlyPayment";
    financingProgramSlug?: string | null;
  } | null;
  shipping?: {
    __typename?: "core_apimessages_ShippingPrices";
    shippingPrices?: Array<{
      __typename?: "core_apimessages_ShippingPrice";
      _id?: string | null;
      shippingMethod?: core_apimessages_ShippingMethod | null;
      rate?: {
        __typename?: "core_apimessages_Money";
        amountCents?: number | null;
      } | null;
    } | null> | null;
  } | null;
};

export type ListingKlarnaCalloutFieldsFragment = {
  __typename?: "Listing";
  _id?: string | null;
  id?: string | null;
  pricing?: {
    __typename?: "core_apimessages_ListingPricing";
    buyerPrice?: {
      __typename?: "core_apimessages_Money";
      amountCents?: number | null;
      currency?: string | null;
    } | null;
  } | null;
  shipping?: {
    __typename?: "core_apimessages_ShippingPrices";
    shippingPrices?: Array<{
      __typename?: "core_apimessages_ShippingPrice";
      _id?: string | null;
      shippingMethod?: core_apimessages_ShippingMethod | null;
      rate?: {
        __typename?: "core_apimessages_Money";
        amountCents?: number | null;
      } | null;
    } | null> | null;
  } | null;
};

export type ListingPayPalCalloutFieldsFragment = {
  __typename?: "Listing";
  _id?: string | null;
  currency?: string | null;
  pricing?: {
    __typename?: "core_apimessages_ListingPricing";
    buyerPrice?: {
      __typename?: "core_apimessages_Money";
      amount?: string | null;
      currency?: string | null;
    } | null;
  } | null;
};

export type ListingPaymentOptionsFieldsFragment = {
  __typename?: "Listing";
  _id?: string | null;
  id?: string | null;
  acceptedPaymentMethods?: Array<string | null> | null;
};

export type Core_PaypalOnboardSellerQueryVariables = Exact<{
  [key: string]: never;
}>;

export type Core_PaypalOnboardSellerQuery = {
  __typename?: "Query";
  paypalOnboardSeller?: {
    __typename?: "core_apimessages_PaypalOnboardSellerResponse";
    actionUrl?: string | null;
  } | null;
};

export type Core_AdminBlockProductReviewMutationVariables = Exact<{
  input?: InputMaybe<Input_core_apimessages_AdminBlockProductReviewRequest>;
}>;

export type Core_AdminBlockProductReviewMutation = {
  __typename?: "Mutation";
  adminBlockProductReview?: {
    __typename?: "core_apimessages_AdminBlockProductReviewResponse";
    id?: string | null;
  } | null;
};

export type ProductReviewCardFieldsFragment = {
  __typename?: "ProductReview";
  _id?: string | null;
  id?: string | null;
  title?: string | null;
  body?: string | null;
  rating?: number | null;
  createdAt?: any | null;
  voteCount?: number | null;
  verified?: boolean | null;
  voted?: boolean | null;
  isMyReview?: boolean | null;
  reported?: boolean | null;
  reviewer?: {
    __typename?: "core_apimessages_Reviewer";
    shortname?: string | null;
    links?: {
      __typename?: "core_apimessages_ReviewerLinks";
      avatar?: {
        __typename?: "core_apimessages_Link";
        href?: string | null;
      } | null;
    } | null;
  } | null;
};

export type ProductReviewFilterFieldsFragment = {
  __typename?: "reverb_search_Filter";
  name?: string | null;
  key?: string | null;
  widgetType?: reverb_search_Filter_WidgetType | null;
  options?: Array<{
    __typename?: "reverb_search_FilterOption";
    name?: string | null;
    selected?: boolean | null;
    paramName?: string | null;
    setValues?: Array<string | null> | null;
    unsetValues?: Array<string | null> | null;
    optionValue?: string | null;
    count?: {
      __typename?: "google_protobuf_Int32Value";
      value?: number | null;
    } | null;
  } | null> | null;
};

export type Core_ProductReview_FlagMutationVariables = Exact<{
  input?: InputMaybe<Input_core_apimessages_FlagProductReviewRequest>;
}>;

export type Core_ProductReview_FlagMutation = {
  __typename?: "Mutation";
  flagProductReview?: {
    __typename?: "core_apimessages_FlagProductReviewResponse";
    id?: string | null;
  } | null;
};

export type Core_ProductReview_UpdateMutationVariables = Exact<{
  input?: InputMaybe<Input_core_apimessages_UpdateProductReviewRequest>;
}>;

export type Core_ProductReview_UpdateMutation = {
  __typename?: "Mutation";
  updateProductReview?: {
    __typename?: "core_apimessages_UpdateProductReviewResponse";
    id?: string | null;
  } | null;
};

export type Core_ProductReview_VoteMutationVariables = Exact<{
  id?: InputMaybe<Scalars["String"]["input"]>;
}>;

export type Core_ProductReview_VoteMutation = {
  __typename?: "Mutation";
  voteProductReview?: {
    __typename?: "core_apimessages_ProductReviewVoteResponse";
    id?: string | null;
  } | null;
};

export type ProductReviewCSPFieldsFragment = {
  __typename?: "CSP";
  _id?: string | null;
  id?: string | null;
  slug?: string | null;
  title?: string | null;
  myReview?: {
    __typename?: "core_apimessages_ProductReview";
    id?: string | null;
    body?: string | null;
    title?: string | null;
    rating?: number | null;
  } | null;
};

export type Core_Product_ReviewsQueryVariables = Exact<{
  cspId?: InputMaybe<Scalars["String"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  ratings?: InputMaybe<
    | Array<InputMaybe<reverb_search_ProductReviewsSearchRequest_Rating>>
    | InputMaybe<reverb_search_ProductReviewsSearchRequest_Rating>
  >;
  verified?: InputMaybe<Scalars["Boolean"]["input"]>;
  sort?: InputMaybe<reverb_search_ProductReviewsSearchRequest_Sort>;
  fullTextQuery?: InputMaybe<Scalars["String"]["input"]>;
}>;

export type Core_Product_ReviewsQuery = {
  __typename?: "Query";
  csp?: {
    __typename?: "CSP";
    _id?: string | null;
    id?: string | null;
    slug?: string | null;
    title?: string | null;
    productReviewSearch?: {
      __typename?: "rql_CSPProductReviewSearchResponse";
      total?: number | null;
      productReviews?: Array<{
        __typename?: "ProductReview";
        _id?: string | null;
        id?: string | null;
        title?: string | null;
        body?: string | null;
        rating?: number | null;
        createdAt?: any | null;
        voteCount?: number | null;
        verified?: boolean | null;
        voted?: boolean | null;
        isMyReview?: boolean | null;
        reported?: boolean | null;
        reviewer?: {
          __typename?: "core_apimessages_Reviewer";
          shortname?: string | null;
          links?: {
            __typename?: "core_apimessages_ReviewerLinks";
            avatar?: {
              __typename?: "core_apimessages_Link";
              href?: string | null;
            } | null;
          } | null;
        } | null;
      } | null> | null;
      filters?: Array<{
        __typename?: "reverb_search_Filter";
        name?: string | null;
        key?: string | null;
        widgetType?: reverb_search_Filter_WidgetType | null;
        options?: Array<{
          __typename?: "reverb_search_FilterOption";
          name?: string | null;
          selected?: boolean | null;
          paramName?: string | null;
          setValues?: Array<string | null> | null;
          unsetValues?: Array<string | null> | null;
          optionValue?: string | null;
          count?: {
            __typename?: "google_protobuf_Int32Value";
            value?: number | null;
          } | null;
        } | null> | null;
      } | null> | null;
    } | null;
    statsSearch?: {
      __typename?: "rql_CSPProductReviewSearchResponse";
      stats?: {
        __typename?: "reverb_search_ProductReviewStats";
        total?: number | null;
        averageRating?: number | null;
        ratingsDistribution?: Array<{
          __typename?: "reverb_search_ProductReviewStats_RatingDistribution";
          rating?: string | null;
          reviewCount?: number | null;
        } | null> | null;
      } | null;
    } | null;
    myReview?: {
      __typename?: "core_apimessages_ProductReview";
      id?: string | null;
      body?: string | null;
      title?: string | null;
      rating?: number | null;
    } | null;
  } | null;
};

export type Core_AdminReports_GenerateAdminReportMutationVariables = Exact<{
  input?: InputMaybe<Input_core_apimessages_GenerateAdminReportRequest>;
}>;

export type Core_AdminReports_GenerateAdminReportMutation = {
  __typename?: "Mutation";
  generateAdminReport?: {
    __typename?: "core_apimessages_GenerateAdminReportResponse";
    reportEnqueued?: boolean | null;
  } | null;
};

export type Core_Update_Ad_CampaignMutationVariables = Exact<{
  input?: InputMaybe<Input_core_apimessages_UpdateAdCampaignRequest>;
}>;

export type Core_Update_Ad_CampaignMutation = {
  __typename?: "Mutation";
  updateAdCampaign?: {
    __typename?: "core_apimessages_UpdateAdCampaignResponse";
    adCampaign?: {
      __typename?: "core_apimessages_AdCampaign";
      name?: string | null;
      uuid?: string | null;
      description?: string | null;
      status?: core_apimessages_AdCampaign_AdStatus | null;
      startsAt?: any | null;
      endsAt?: any | null;
      advertiserUuid?: string | null;
    } | null;
  } | null;
};

export type Core_Ad_Admin_Ad_Campaign_FormQueryVariables = Exact<{
  uuid?: InputMaybe<Scalars["String"]["input"]>;
}>;

export type Core_Ad_Admin_Ad_Campaign_FormQuery = {
  __typename?: "Query";
  adcampaign?: {
    __typename?: "AdCampaign";
    _id?: string | null;
    uuid?: string | null;
    name?: string | null;
    status?: core_apimessages_AdCampaign_AdStatus | null;
    startsAt?: any | null;
    endsAt?: any | null;
    advertiserUuid?: string | null;
  } | null;
};

export type Core_Ad_Admin_AdCampaignsQueryVariables = Exact<{
  advertiserUuid?: InputMaybe<Scalars["String"]["input"]>;
}>;

export type Core_Ad_Admin_AdCampaignsQuery = {
  __typename?: "Query";
  advertiser?: {
    __typename?: "Advertiser";
    _id?: string | null;
    uuid?: string | null;
    name?: string | null;
    brandSlugs?: Array<string | null> | null;
    shopSlugs?: Array<string | null> | null;
    adCampaignCollection?: {
      __typename?: "AdCampaignCollection";
      _ids?: Array<string | null> | null;
      adCampaigns?: Array<{
        __typename?: "AdCampaign";
        _id?: string | null;
        uuid?: string | null;
        name?: string | null;
        status?: core_apimessages_AdCampaign_AdStatus | null;
        startsAt?: any | null;
        endsAt?: any | null;
        advertiserUuid?: string | null;
      } | null> | null;
    } | null;
  } | null;
};

export type Core_Update_AdMutationVariables = Exact<{
  input?: InputMaybe<Input_core_apimessages_UpdateAdRequest>;
}>;

export type Core_Update_AdMutation = {
  __typename?: "Mutation";
  updateAd?: {
    __typename?: "core_apimessages_UpdateAdResponse";
    ad?: {
      __typename?: "core_apimessages_Ad";
      uuid?: string | null;
      adGroupUuid?: string | null;
      adCampaignUuid?: string | null;
      imageAdCreative?: {
        __typename?: "core_apimessages_ImageAdCreative";
        title?: string | null;
        titleColor?: string | null;
        subtitle?: string | null;
        subtitleColor?: string | null;
        ctaText?: string | null;
        ctaHref?: string | null;
        ctaTextColor?: string | null;
        ctaButtonColor?: string | null;
        backgroundImage?: string | null;
        mobileBackgroundImage?: string | null;
        backgroundColor?: string | null;
        backgroundColors?: Array<string | null> | null;
        overlayImage?: string | null;
        description?: string | null;
        partnershipText?: string | null;
        hideAdCallout?: boolean | null;
      } | null;
      localizedContents?: Array<{
        __typename?: "core_apimessages_AdLocalizedContent";
        locale?: string | null;
        imageAdCreative?: {
          __typename?: "core_apimessages_ImageAdCreative";
          title?: string | null;
          titleColor?: string | null;
          subtitle?: string | null;
          subtitleColor?: string | null;
          ctaText?: string | null;
          ctaHref?: string | null;
          ctaTextColor?: string | null;
          ctaButtonColor?: string | null;
          backgroundImage?: string | null;
          mobileBackgroundImage?: string | null;
          backgroundColor?: string | null;
          backgroundColors?: Array<string | null> | null;
          overlayImage?: string | null;
          description?: string | null;
          partnershipText?: string | null;
          hideAdCallout?: boolean | null;
        } | null;
      } | null> | null;
    } | null;
  } | null;
};

export type Core_Ad_Admin_Ad_FormQueryVariables = Exact<{
  uuid?: InputMaybe<Scalars["String"]["input"]>;
}>;

export type Core_Ad_Admin_Ad_FormQuery = {
  __typename?: "Query";
  ad?: {
    __typename?: "Ad";
    _id?: string | null;
    format?: core_apimessages_AdFormat | null;
    uuid?: string | null;
    localizedContents?: Array<{
      __typename?: "core_apimessages_AdLocalizedContent";
      locale?: string | null;
      imageAdCreative?: {
        __typename?: "core_apimessages_ImageAdCreative";
        title?: string | null;
        titleColor?: string | null;
        subtitle?: string | null;
        subtitleColor?: string | null;
        ctaText?: string | null;
        ctaHref?: string | null;
        ctaTextColor?: string | null;
        ctaButtonColor?: string | null;
        backgroundImage?: string | null;
        mobileBackgroundImage?: string | null;
        backgroundColor?: string | null;
        backgroundColors?: Array<string | null> | null;
        overlayImage?: string | null;
        description?: string | null;
        partnershipText?: string | null;
        hideAdCallout?: boolean | null;
        cspRowQuery?: {
          __typename?: "core_apimessages_CspRowQuery";
          brandSlugs?: Array<string | null> | null;
          categoryUuids?: Array<string | null> | null;
          cspSlugs?: Array<string | null> | null;
          rowTitle?: string | null;
          sort?: string | null;
        } | null;
      } | null;
    } | null> | null;
    imageAdCreative?: {
      __typename?: "core_apimessages_ImageAdCreative";
      title?: string | null;
      titleColor?: string | null;
      subtitle?: string | null;
      subtitleColor?: string | null;
      ctaText?: string | null;
      ctaHref?: string | null;
      ctaTextColor?: string | null;
      ctaButtonColor?: string | null;
      backgroundImage?: string | null;
      mobileBackgroundImage?: string | null;
      backgroundColor?: string | null;
      backgroundColors?: Array<string | null> | null;
      overlayImage?: string | null;
      description?: string | null;
      partnershipText?: string | null;
      hideAdCallout?: boolean | null;
    } | null;
  } | null;
};

export type Core_Copy_AdGroupMutationVariables = Exact<{
  input?: InputMaybe<Input_core_apimessages_CopyAdGroupRequest>;
}>;

export type Core_Copy_AdGroupMutation = {
  __typename?: "Mutation";
  copyAdGroup?: {
    __typename?: "core_apimessages_CopyAdGroupResponse";
    adGroup?: {
      __typename?: "core_apimessages_AdGroup";
      name?: string | null;
      uuid?: string | null;
      status?: core_apimessages_AdGroup_AdStatus | null;
      targetedCountryCodes?: Array<string | null> | null;
      targetedCategoryUuids?: Array<string | null> | null;
      targetedBrandSlugs?: Array<string | null> | null;
      targetedDevices?: Array<string | null> | null;
      targetedAdZones?: Array<{
        __typename?: "core_apimessages_AdGroupZone";
        startsAt?: any | null;
        endsAt?: any | null;
        adZone?: {
          __typename?: "core_apimessages_AdZone";
          slug?: string | null;
        } | null;
      } | null> | null;
    } | null;
  } | null;
};

export type Core_Update_AdGroupMutationVariables = Exact<{
  input?: InputMaybe<Input_core_apimessages_UpdateAdGroupRequest>;
}>;

export type Core_Update_AdGroupMutation = {
  __typename?: "Mutation";
  updateAdGroup?: {
    __typename?: "core_apimessages_UpdateAdGroupResponse";
    adGroup?: {
      __typename?: "core_apimessages_AdGroup";
      name?: string | null;
      uuid?: string | null;
      status?: core_apimessages_AdGroup_AdStatus | null;
      targetedCountryCodes?: Array<string | null> | null;
      targetedCategoryUuids?: Array<string | null> | null;
      targetedBrandSlugs?: Array<string | null> | null;
      targetedDevices?: Array<string | null> | null;
      targetedAdZones?: Array<{
        __typename?: "core_apimessages_AdGroupZone";
        startsAt?: any | null;
        endsAt?: any | null;
        adZone?: {
          __typename?: "core_apimessages_AdZone";
          slug?: string | null;
        } | null;
      } | null> | null;
    } | null;
  } | null;
};

export type Core_Update_AdGroupKeywordsMutationVariables = Exact<{
  input?: InputMaybe<Input_core_apimessages_UpdateAdGroupKeywordsRequest>;
}>;

export type Core_Update_AdGroupKeywordsMutation = {
  __typename?: "Mutation";
  updateAdGroupKeywords?: {
    __typename?: "core_apimessages_UpdateAdGroupKeywordsResponse";
    adGroupKeywords?: Array<{
      __typename?: "core_apimessages_AdGroupKeyword";
      id?: string | null;
    } | null> | null;
  } | null;
};

export type AdGroupWithKeywordsDataFragment = {
  __typename?: "AdGroup";
  _id?: string | null;
  uuid?: string | null;
  name?: string | null;
  targetedCategoryUuids?: Array<string | null> | null;
  targetedBrandSlugs?: Array<string | null> | null;
  targetedCountryCodes?: Array<string | null> | null;
  targetedDevices?: Array<string | null> | null;
  targetedAdZones?: Array<{
    __typename?: "core_apimessages_AdGroupZone";
    startsAt?: any | null;
    endsAt?: any | null;
    adZone?: {
      __typename?: "core_apimessages_AdZone";
      slug?: string | null;
    } | null;
  } | null> | null;
  adGroupKeywords?: Array<{
    __typename?: "core_apimessages_AdGroupKeyword";
    keyword?: string | null;
  } | null> | null;
};

export type Core_Ad_Admin_AdGroup_FormQueryVariables = Exact<{
  uuid?: InputMaybe<Scalars["String"]["input"]>;
}>;

export type Core_Ad_Admin_AdGroup_FormQuery = {
  __typename?: "Query";
  adgroup?: {
    __typename?: "AdGroup";
    _id?: string | null;
    uuid?: string | null;
    name?: string | null;
    targetedCategoryUuids?: Array<string | null> | null;
    targetedBrandSlugs?: Array<string | null> | null;
    targetedCountryCodes?: Array<string | null> | null;
    targetedDevices?: Array<string | null> | null;
    targetedAdZones?: Array<{
      __typename?: "core_apimessages_AdGroupZone";
      startsAt?: any | null;
      endsAt?: any | null;
      adZone?: {
        __typename?: "core_apimessages_AdZone";
        slug?: string | null;
      } | null;
    } | null> | null;
    adGroupKeywords?: Array<{
      __typename?: "core_apimessages_AdGroupKeyword";
      keyword?: string | null;
    } | null> | null;
  } | null;
  countries?: {
    __typename?: "core_apimessages_CountriesResponse";
    countries?: Array<{
      __typename?: "core_apimessages_Country";
      _id?: string | null;
      name?: string | null;
      countryCode?: string | null;
    } | null> | null;
  } | null;
  adZonesSearch?: {
    __typename?: "core_apimessages_AdZonesSearchResponse";
    adZones?: Array<{
      __typename?: "AdZone";
      _id?: string | null;
      slug?: string | null;
    } | null> | null;
  } | null;
};

export type AdGroupDataFragment = {
  __typename?: "AdGroup";
  _id?: string | null;
  uuid?: string | null;
  name?: string | null;
};

export type Core_Ad_Admin_AdGroupsQueryVariables = Exact<{
  adCampaignUuid?: InputMaybe<Scalars["String"]["input"]>;
  advertiserUuid?: InputMaybe<Scalars["String"]["input"]>;
}>;

export type Core_Ad_Admin_AdGroupsQuery = {
  __typename?: "Query";
  adcampaign?: {
    __typename?: "AdCampaign";
    _id?: string | null;
    uuid?: string | null;
    name?: string | null;
    adGroupCollection?: {
      __typename?: "AdGroupCollection";
      _ids?: Array<string | null> | null;
      adGroups?: Array<{
        __typename?: "AdGroup";
        _id?: string | null;
        uuid?: string | null;
        name?: string | null;
      } | null> | null;
    } | null;
  } | null;
  advertiser?: {
    __typename?: "Advertiser";
    _id?: string | null;
    uuid?: string | null;
    name?: string | null;
    brandSlugs?: Array<string | null> | null;
    shopSlugs?: Array<string | null> | null;
  } | null;
};

export type Core_Delete_AdMutationVariables = Exact<{
  input?: InputMaybe<Input_core_apimessages_DeleteAdRequest>;
}>;

export type Core_Delete_AdMutation = {
  __typename?: "Mutation";
  DeleteAd?: {
    __typename?: "core_apimessages_DeleteAdResponse";
    ad?: {
      __typename?: "core_apimessages_Ad";
      uuid?: string | null;
      deletedAt?: any | null;
    } | null;
  } | null;
};

export type Core_Update_AdZoneMutationVariables = Exact<{
  input?: InputMaybe<Input_core_apimessages_UpdateAdZoneRequest>;
}>;

export type Core_Update_AdZoneMutation = {
  __typename?: "Mutation";
  updateAdZone?: {
    __typename?: "core_apimessages_UpdateAdZoneResponse";
    adZone?: {
      __typename?: "core_apimessages_AdZone";
      uuid?: string | null;
      name?: string | null;
      slug?: string | null;
      status?: core_apimessages_AdZone_AdZoneStatus | null;
      description?: string | null;
    } | null;
  } | null;
};

export type Core_Ad_Admin_Ad_Zone_FormQueryVariables = Exact<{
  uuid?: InputMaybe<Scalars["String"]["input"]>;
}>;

export type Core_Ad_Admin_Ad_Zone_FormQuery = {
  __typename?: "Query";
  adzone?: {
    __typename?: "AdZone";
    _id?: string | null;
    uuid?: string | null;
    name?: string | null;
    slug?: string | null;
    status?: core_apimessages_AdZone_AdZoneStatus | null;
    description?: string | null;
  } | null;
};

export type AdZoneDataFragment = {
  __typename?: "AdZone";
  _id?: string | null;
  uuid?: string | null;
  name?: string | null;
  slug?: string | null;
  status?: core_apimessages_AdZone_AdZoneStatus | null;
  description?: string | null;
};

export type Core_Ad_Admin_Ad_ZonesQueryVariables = Exact<{
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
}>;

export type Core_Ad_Admin_Ad_ZonesQuery = {
  __typename?: "Query";
  adZonesSearch?: {
    __typename?: "core_apimessages_AdZonesSearchResponse";
    adZones?: Array<{
      __typename?: "AdZone";
      _id?: string | null;
      uuid?: string | null;
      name?: string | null;
      slug?: string | null;
      status?: core_apimessages_AdZone_AdZoneStatus | null;
      description?: string | null;
    } | null> | null;
  } | null;
};

export type AdCardDataFragment = {
  __typename?: "Ad";
  _id?: string | null;
  format?: core_apimessages_AdFormat | null;
  uuid?: string | null;
  imageAdCreative?: {
    __typename?: "core_apimessages_ImageAdCreative";
    title?: string | null;
    titleColor?: string | null;
    subtitle?: string | null;
    subtitleColor?: string | null;
    ctaText?: string | null;
    ctaHref?: string | null;
    ctaTextColor?: string | null;
    ctaButtonColor?: string | null;
    backgroundImage?: string | null;
    mobileBackgroundImage?: string | null;
    backgroundColor?: string | null;
    backgroundColors?: Array<string | null> | null;
    overlayImage?: string | null;
    description?: string | null;
    partnershipText?: string | null;
    hideAdCallout?: boolean | null;
  } | null;
};

export type Core_Ad_Admin_AdsQueryVariables = Exact<{
  adGroupUuid?: InputMaybe<Scalars["String"]["input"]>;
  adCampaignUuid?: InputMaybe<Scalars["String"]["input"]>;
  advertiserUuid?: InputMaybe<Scalars["String"]["input"]>;
}>;

export type Core_Ad_Admin_AdsQuery = {
  __typename?: "Query";
  adgroup?: {
    __typename?: "AdGroup";
    _id?: string | null;
    name?: string | null;
    uuid?: string | null;
    adCollection?: {
      __typename?: "AdCollection";
      _ids?: Array<string | null> | null;
      ads?: Array<{
        __typename?: "Ad";
        _id?: string | null;
        format?: core_apimessages_AdFormat | null;
        uuid?: string | null;
        imageAdCreative?: {
          __typename?: "core_apimessages_ImageAdCreative";
          title?: string | null;
          titleColor?: string | null;
          subtitle?: string | null;
          subtitleColor?: string | null;
          ctaText?: string | null;
          ctaHref?: string | null;
          ctaTextColor?: string | null;
          ctaButtonColor?: string | null;
          backgroundImage?: string | null;
          mobileBackgroundImage?: string | null;
          backgroundColor?: string | null;
          backgroundColors?: Array<string | null> | null;
          overlayImage?: string | null;
          description?: string | null;
          partnershipText?: string | null;
          hideAdCallout?: boolean | null;
        } | null;
      } | null> | null;
    } | null;
  } | null;
  adcampaign?: {
    __typename?: "AdCampaign";
    _id?: string | null;
    uuid?: string | null;
    name?: string | null;
  } | null;
  advertiser?: {
    __typename?: "Advertiser";
    _id?: string | null;
    uuid?: string | null;
    name?: string | null;
    brandSlugs?: Array<string | null> | null;
    shopSlugs?: Array<string | null> | null;
  } | null;
};

export type Core_Update_AdvertiserMutationVariables = Exact<{
  input?: InputMaybe<Input_core_apimessages_UpdateAdvertiserRequest>;
}>;

export type Core_Update_AdvertiserMutation = {
  __typename?: "Mutation";
  updateAdvertiser?: {
    __typename?: "core_apimessages_UpdateAdvertiserResponse";
    advertiser?: {
      __typename?: "core_apimessages_Advertiser";
      name?: string | null;
      uuid?: string | null;
      brandSlugs?: Array<string | null> | null;
      shopSlugs?: Array<string | null> | null;
    } | null;
  } | null;
};

export type Core_Ad_Admin_Advertiser_FormQueryVariables = Exact<{
  uuid?: InputMaybe<Scalars["String"]["input"]>;
}>;

export type Core_Ad_Admin_Advertiser_FormQuery = {
  __typename?: "Query";
  advertiser?: {
    __typename?: "Advertiser";
    _id?: string | null;
    uuid?: string | null;
    name?: string | null;
    brandSlugs?: Array<string | null> | null;
    shopSlugs?: Array<string | null> | null;
  } | null;
};

export type AdvertiserDataFragment = {
  __typename?: "Advertiser";
  _id?: string | null;
  uuid?: string | null;
  name?: string | null;
  brandSlugs?: Array<string | null> | null;
  shopSlugs?: Array<string | null> | null;
};

export type Core_Ad_Admin_AdvertisersQueryVariables = Exact<{
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
}>;

export type Core_Ad_Admin_AdvertisersQuery = {
  __typename?: "Query";
  advertisersSearch?: {
    __typename?: "core_apimessages_AdvertisersSearchResponse";
    offset?: number | null;
    limit?: number | null;
    total?: number | null;
    advertisers?: Array<{
      __typename?: "Advertiser";
      _id?: string | null;
      uuid?: string | null;
      name?: string | null;
      brandSlugs?: Array<string | null> | null;
      shopSlugs?: Array<string | null> | null;
    } | null> | null;
  } | null;
};

export type Core_AdaChat_TokenQueryQueryVariables = Exact<{
  [key: string]: never;
}>;

export type Core_AdaChat_TokenQueryQuery = {
  __typename?: "Query";
  me?: {
    __typename?: "rql_Me";
    _id?: string | null;
    id?: string | null;
    uuid?: string | null;
    adaChatToken?: {
      __typename?: "core_apimessages_AdaChatTokenResponse";
      token?: string | null;
    } | null;
  } | null;
};

export type Core_Address_Form_Fields_Configs_QueryQueryVariables = Exact<{
  [key: string]: never;
}>;

export type Core_Address_Form_Fields_Configs_QueryQuery = {
  __typename?: "Query";
  intlAddressFormConfig?: {
    __typename?: "reverb_config_cache_InternationalAddressFormConfigResponse";
    configs?: Array<{
      __typename?: "reverb_config_cache_AddressFormConfig";
      countryCode?: string | null;
      fields?: Array<{
        __typename?: "reverb_config_cache_AddressFormField";
        fieldName?: string | null;
        required?: boolean | null;
        displayNames?: Array<{
          __typename?: "reverb_config_cache_LocalizedAddressFormDisplayName";
          locale?: string | null;
          displayName?: string | null;
        } | null> | null;
      } | null> | null;
    } | null> | null;
  } | null;
};

export type Core_Address_VerifyQueryVariables = Exact<{
  input?: InputMaybe<Input_core_apimessages_AddressVerifyRequest>;
}>;

export type Core_Address_VerifyQuery = {
  __typename?: "Query";
  addressVerify?: {
    __typename?: "core_apimessages_AddressVerifyResponse";
    suggestion?: {
      __typename?: "core_apimessages_Address";
      _id?: string | null;
      id?: string | null;
      streetAddress?: string | null;
      extendedAddress?: string | null;
      locality?: string | null;
      region?: string | null;
      postalCode?: string | null;
      countryCode?: string | null;
    } | null;
    address?: {
      __typename?: "core_apimessages_Address";
      _id?: string | null;
      id?: string | null;
      streetAddress?: string | null;
      extendedAddress?: string | null;
      locality?: string | null;
      region?: string | null;
      postalCode?: string | null;
      countryCode?: string | null;
    } | null;
  } | null;
};

export type Core_Accept_AddressSuggestionMutationVariables = Exact<{
  input?: InputMaybe<Input_core_apimessages_AcceptAddressSuggestionRequest>;
}>;

export type Core_Accept_AddressSuggestionMutation = {
  __typename?: "Mutation";
  acceptAddressSuggestion?: {
    __typename?: "core_apimessages_AcceptAddressSuggestionResponse";
    id?: string | null;
  } | null;
};

export type AddressBookQueryVariables = Exact<{ [key: string]: never }>;

export type AddressBookQuery = {
  __typename?: "Query";
  countries?: {
    __typename?: "core_apimessages_CountriesResponse";
    countries?: Array<{
      __typename?: "core_apimessages_Country";
      _id?: string | null;
      name?: string | null;
      countryCode?: string | null;
      subregions?: Array<{
        __typename?: "core_apimessages_Subregion";
        _id?: string | null;
        name?: string | null;
        code?: string | null;
      } | null> | null;
    } | null> | null;
  } | null;
  me?: {
    __typename?: "rql_Me";
    _id?: string | null;
    uuid?: string | null;
    shippingAddresses?: Array<{
      __typename?: "core_apimessages_Address";
      _id?: string | null;
      uuid?: string | null;
      name?: string | null;
      streetAddress?: string | null;
      extendedAddress?: string | null;
      locality?: string | null;
      region?: string | null;
      regionName?: string | null;
      postalCode?: string | null;
      countryCode?: string | null;
      phone?: string | null;
      primary?: boolean | null;
      legacyId?: string | null;
      isComplete?: boolean | null;
      verified?: boolean | null;
      country?: {
        __typename?: "core_apimessages_Country";
        _id?: string | null;
        countryCode?: string | null;
        name?: string | null;
      } | null;
    } | null> | null;
  } | null;
};

export type UpdateMyAddressMutationVariables = Exact<{
  input?: InputMaybe<Input_core_apimessages_UpdateAddressRequest>;
}>;

export type UpdateMyAddressMutation = {
  __typename?: "Mutation";
  updateMyAddress?: {
    __typename?: "core_apimessages_UpdateAddressResponse";
    address?: {
      __typename?: "core_apimessages_Address";
      _id?: string | null;
      uuid?: string | null;
      name?: string | null;
      streetAddress?: string | null;
      extendedAddress?: string | null;
      locality?: string | null;
      region?: string | null;
      regionName?: string | null;
      postalCode?: string | null;
      countryCode?: string | null;
      phone?: string | null;
      primary?: boolean | null;
      legacyId?: string | null;
      isComplete?: boolean | null;
      country?: {
        __typename?: "core_apimessages_Country";
        _id?: string | null;
        countryCode?: string | null;
        name?: string | null;
      } | null;
    } | null;
  } | null;
};

export type DeleteMyAddressMutationVariables = Exact<{
  input?: InputMaybe<Input_core_apimessages_AddressDeleteRequest>;
}>;

export type DeleteMyAddressMutation = {
  __typename?: "Mutation";
  deleteMyAddress?: {
    __typename?: "core_apimessages_DeleteResponse";
    uuid?: string | null;
  } | null;
};

export type CountriesQueryVariables = Exact<{ [key: string]: never }>;

export type CountriesQuery = {
  __typename?: "Query";
  countries?: {
    __typename?: "core_apimessages_CountriesResponse";
    countries?: Array<{
      __typename?: "core_apimessages_Country";
      _id?: string | null;
      name?: string | null;
      countryCode?: string | null;
      subregions?: Array<{
        __typename?: "core_apimessages_Subregion";
        _id?: string | null;
        name?: string | null;
        code?: string | null;
      } | null> | null;
    } | null> | null;
  } | null;
};

export type Core_AdminClaimOrderBundle_UserSearchQueryVariables = Exact<{
  search?: InputMaybe<Scalars["String"]["input"]>;
}>;

export type Core_AdminClaimOrderBundle_UserSearchQuery = {
  __typename?: "Query";
  userSearch?: {
    __typename?: "core_apimessages_AdminUserSearchResponse";
    users?: Array<{
      __typename?: "core_apimessages_UserSearchResult";
      id?: string | null;
      email?: string | null;
      fullName?: string | null;
    } | null> | null;
  } | null;
};

export type Core_UpdateAdminNoteMutationVariables = Exact<{
  input?: InputMaybe<Input_core_apimessages_AdminNoteUpdateRequest>;
}>;

export type Core_UpdateAdminNoteMutation = {
  __typename?: "Mutation";
  updateAdminNote?: {
    __typename?: "core_apimessages_AdminNote";
    id?: string | null;
    linkHtml?: string | null;
    createdAt?: any | null;
    createdByName?: string | null;
    lastEditedByName?: string | null;
    updatedAt?: any | null;
    userCanEdit?: boolean | null;
    body?: string | null;
    isPinned?: boolean | null;
    versions?: Array<{
      __typename?: "core_apimessages_AdminNoteVersion";
      id?: string | null;
      editedAt?: any | null;
      editedByName?: string | null;
      body?: string | null;
    } | null> | null;
  } | null;
};

export type Core_CreateAdminNoteMutationVariables = Exact<{
  input?: InputMaybe<Input_core_apimessages_AdminNoteCreateRequest>;
}>;

export type Core_CreateAdminNoteMutation = {
  __typename?: "Mutation";
  createAdminNote?: {
    __typename?: "core_apimessages_AdminNote";
    id?: string | null;
    linkHtml?: string | null;
    createdAt?: any | null;
    createdByName?: string | null;
    lastEditedByName?: string | null;
    updatedAt?: any | null;
    userCanEdit?: boolean | null;
    body?: string | null;
    isPinned?: boolean | null;
    versions?: Array<{
      __typename?: "core_apimessages_AdminNoteVersion";
      id?: string | null;
      editedAt?: any | null;
      editedByName?: string | null;
      body?: string | null;
    } | null> | null;
  } | null;
};

export type adminNotesSearchQueryVariables = Exact<{
  input?: InputMaybe<Input_core_apimessages_AdminNotesSearchRequest>;
}>;

export type adminNotesSearchQuery = {
  __typename?: "Query";
  adminNotesSearch?: {
    __typename?: "core_apimessages_AdminNotesSearchResponse";
    limit?: string | null;
    offset?: string | null;
    totalResults?: string | null;
    adminNotes?: Array<{
      __typename?: "core_apimessages_AdminNote";
      id?: string | null;
      linkHtml?: string | null;
      createdAt?: any | null;
      createdByName?: string | null;
      lastEditedByName?: string | null;
      updatedAt?: any | null;
      userCanEdit?: boolean | null;
      body?: string | null;
      isPinned?: boolean | null;
      versions?: Array<{
        __typename?: "core_apimessages_AdminNoteVersion";
        id?: string | null;
        editedAt?: any | null;
        editedByName?: string | null;
        body?: string | null;
      } | null> | null;
    } | null> | null;
  } | null;
};

export type shopAdyenBalancePlatformNotificationsQueryVariables = Exact<{
  input?: InputMaybe<Input_core_apimessages_ShopAdyenBalancePlatformNotificationsIndexRequest>;
}>;

export type shopAdyenBalancePlatformNotificationsQuery = {
  __typename?: "Query";
  shopAdyenBalancePlatformNotifications?: {
    __typename?: "core_apimessages_ShopAdyenBalancePlatformNotificationsIndexResponse";
    limit?: string | null;
    offset?: string | null;
    totalResults?: string | null;
    notifications?: Array<{
      __typename?: "core_apimessages_AdyenBalancePlatformNotification";
      id?: string | null;
      createdAt?: any | null;
      rawBody?: string | null;
    } | null> | null;
  } | null;
};

export type Core_SearchAdminTax1099kReportingThresholdsQueryVariables = Exact<{
  input?: InputMaybe<Input_core_apimessages_AdminTax1099kReportingThresholdSearchRequest>;
}>;

export type Core_SearchAdminTax1099kReportingThresholdsQuery = {
  __typename?: "Query";
  taxReportingThresholdSearch?: {
    __typename?: "core_apimessages_AdminTax1099kReportingThresholdSearchResponse";
    totalResults?: string | null;
    offset?: string | null;
    limit?: string | null;
    taxReportingThresholds?: Array<{
      __typename?: "core_apimessages_Tax1099kReportingThresholdSearchResult";
      id?: string | null;
      year?: string | null;
      region?: string | null;
      salesAmountCents?: string | null;
      transactionCount?: string | null;
    } | null> | null;
  } | null;
};

export type Core_DeleteAdminTax1099kReportingThresholdMutationVariables =
  Exact<{
    input?: InputMaybe<Input_core_apimessages_AdminTax1099kReportingThresholdDeleteRequest>;
  }>;

export type Core_DeleteAdminTax1099kReportingThresholdMutation = {
  __typename?: "Mutation";
  deleteAdminTax1099kReportingThreshold?: {
    __typename?: "core_apimessages_AdminTax1099kReportingThresholdDeleteResponse";
    id?: string | null;
  } | null;
};

export type Core_UpdateAdminTax1099kReportingThresholdMutationVariables =
  Exact<{
    input?: InputMaybe<Input_core_apimessages_AdminTax1099kReportingThresholdUpdateRequest>;
  }>;

export type Core_UpdateAdminTax1099kReportingThresholdMutation = {
  __typename?: "Mutation";
  updateAdminTax1099kReportingThreshold?: {
    __typename?: "core_apimessages_AdminTax1099kReportingThresholdUpdateResponse";
    id?: string | null;
    year?: string | null;
    region?: string | null;
    salesAmountCents?: string | null;
    transactionCount?: string | null;
  } | null;
};

export type Core_CreateAdminTax1099kReportingThresholdMutationVariables =
  Exact<{
    input?: InputMaybe<Input_core_apimessages_AdminTax1099kReportingThresholdCreateRequest>;
  }>;

export type Core_CreateAdminTax1099kReportingThresholdMutation = {
  __typename?: "Mutation";
  createAdminTax1099kReportingThreshold?: {
    __typename?: "core_apimessages_AdminTax1099kReportingThresholdCreateResponse";
    id?: string | null;
    year?: string | null;
    region?: string | null;
    salesAmountCents?: string | null;
    transactionCount?: string | null;
  } | null;
};

export type SEARCH_USERSQueryVariables = Exact<{
  input?: InputMaybe<Input_core_apimessages_AdminUserSearchRequest>;
}>;

export type SEARCH_USERSQuery = {
  __typename?: "Query";
  userSearch?: {
    __typename?: "core_apimessages_AdminUserSearchResponse";
    users?: Array<{
      __typename?: "core_apimessages_UserSearchResult";
      id?: string | null;
      email?: string | null;
      shopId?: string | null;
      shopName?: string | null;
    } | null> | null;
  } | null;
};

export type SearchUserDatadogErrorsQueryVariables = Exact<{
  input?: InputMaybe<Input_core_apimessages_AdminUserDatadogErrorsSearchRequest>;
}>;

export type SearchUserDatadogErrorsQuery = {
  __typename?: "Query";
  datadogErrorsSearch?: {
    __typename?: "core_apimessages_AdminUserDatadogErrorsSearchResponse";
    datadogErrors?: Array<{
      __typename?: "core_apimessages_DatadogError";
      key?: string | null;
      type?: string | null;
      content?: string | null;
      context?: string | null;
      timestamp?: string | null;
    } | null> | null;
  } | null;
};

export type Core_Category_BestSellersSearchQueryVariables = Exact<{
  categoryUuids?: InputMaybe<
    | Array<InputMaybe<Scalars["String"]["input"]>>
    | InputMaybe<Scalars["String"]["input"]>
  >;
  bestSellerThreshold?: InputMaybe<Scalars["Int"]["input"]>;
}>;

export type Core_Category_BestSellersSearchQuery = {
  __typename?: "Query";
  cspSearch?: {
    __typename?: "reverb_search_SearchResponse";
    resultIds?: Array<string | null> | null;
  } | null;
};

export type myShopBillingMethodsFragment = {
  __typename?: "rql_Me";
  _id?: string | null;
  uuid?: string | null;
  shop?: {
    __typename?: "MyShop";
    _id?: string | null;
    id?: string | null;
    currency?: string | null;
    originCountryCode?: string | null;
    shopType?: core_apimessages_Shop_ShopType | null;
    billingMethodStatus?: core_apimessages_MyShopOnboardingResponse_BillingMethodStatus | null;
    paypalSetupStatus?: core_apimessages_MyShopOnboardingResponse_PaypalSetupStatus | null;
    paypalProfile?: {
      __typename?: "core_apimessages_MyShopPaypalProfileResponse_PaypalProfile";
      email?: string | null;
    } | null;
  } | null;
  creditCards?: Array<{
    __typename?: "core_apimessages_CreditCard";
    id?: string | null;
    last4?: string | null;
    cardType?: string | null;
    expirationYear?: string | null;
    expirationMonth?: string | null;
    cardholderName?: string | null;
    primaryBilling?: boolean | null;
    address?: {
      __typename?: "core_apimessages_Address";
      _id?: string | null;
      postalCode?: string | null;
      countryCode?: string | null;
    } | null;
  } | null> | null;
};

export type GetBillingMethodQueryVariables = Exact<{ [key: string]: never }>;

export type GetBillingMethodQuery = {
  __typename?: "Query";
  countries?: {
    __typename?: "core_apimessages_CountriesResponse";
    countries?: Array<{
      __typename?: "core_apimessages_Country";
      _id?: string | null;
      name?: string | null;
      countryCode?: string | null;
      subregions?: Array<{
        __typename?: "core_apimessages_Subregion";
        _id?: string | null;
        name?: string | null;
        code?: string | null;
      } | null> | null;
    } | null> | null;
  } | null;
  me?: {
    __typename?: "rql_Me";
    _id?: string | null;
    uuid?: string | null;
    shop?: {
      __typename?: "MyShop";
      _id?: string | null;
      id?: string | null;
      currency?: string | null;
      originCountryCode?: string | null;
      shopType?: core_apimessages_Shop_ShopType | null;
      billingMethodStatus?: core_apimessages_MyShopOnboardingResponse_BillingMethodStatus | null;
      paypalSetupStatus?: core_apimessages_MyShopOnboardingResponse_PaypalSetupStatus | null;
      paypalProfile?: {
        __typename?: "core_apimessages_MyShopPaypalProfileResponse_PaypalProfile";
        email?: string | null;
      } | null;
    } | null;
    creditCards?: Array<{
      __typename?: "core_apimessages_CreditCard";
      id?: string | null;
      last4?: string | null;
      cardType?: string | null;
      expirationYear?: string | null;
      expirationMonth?: string | null;
      cardholderName?: string | null;
      primaryBilling?: boolean | null;
      address?: {
        __typename?: "core_apimessages_Address";
        _id?: string | null;
        postalCode?: string | null;
        countryCode?: string | null;
      } | null;
    } | null> | null;
  } | null;
};

export type UpdateMyShopBillingMethodMutationVariables = Exact<{
  input?: InputMaybe<Input_core_apimessages_UpdateBillingMethodRequest>;
}>;

export type UpdateMyShopBillingMethodMutation = {
  __typename?: "Mutation";
  updateMyShopBillingMethod?: {
    __typename?: "core_apimessages_UpdateBillingMethodResponse";
    billingMethodUuid?: string | null;
    errors?: Array<{
      __typename?: "core_apimessages_Error";
      message?: string | null;
    } | null> | null;
    creditCard?: {
      __typename?: "core_apimessages_CreditCard";
      id?: string | null;
    } | null;
    adyenPaymentResult?: {
      __typename?: "core_apimessages_AdyenPaymentResult";
      action?: string | null;
      paymentStatus?: core_apimessages_AdyenPaymentResult_PaymentStatus | null;
      fingerprintToken?: string | null;
      challengeToken?: string | null;
      paymentData?: string | null;
      threeDsOneData?: {
        __typename?: "core_apimessages_ThreeDSOneData";
        paReq?: string | null;
        md?: string | null;
        termUrl?: string | null;
        redirectUrl?: string | null;
      } | null;
    } | null;
    me?: {
      __typename?: "rql_Me";
      _id?: string | null;
      uuid?: string | null;
      shop?: {
        __typename?: "MyShop";
        _id?: string | null;
        id?: string | null;
        currency?: string | null;
        originCountryCode?: string | null;
        shopType?: core_apimessages_Shop_ShopType | null;
        billingMethodStatus?: core_apimessages_MyShopOnboardingResponse_BillingMethodStatus | null;
        paypalSetupStatus?: core_apimessages_MyShopOnboardingResponse_PaypalSetupStatus | null;
        paypalProfile?: {
          __typename?: "core_apimessages_MyShopPaypalProfileResponse_PaypalProfile";
          email?: string | null;
        } | null;
      } | null;
      creditCards?: Array<{
        __typename?: "core_apimessages_CreditCard";
        id?: string | null;
        last4?: string | null;
        cardType?: string | null;
        expirationYear?: string | null;
        expirationMonth?: string | null;
        cardholderName?: string | null;
        primaryBilling?: boolean | null;
        address?: {
          __typename?: "core_apimessages_Address";
          _id?: string | null;
          postalCode?: string | null;
          countryCode?: string | null;
        } | null;
      } | null> | null;
    } | null;
  } | null;
};

export type BillingMethodVerifyShopperMutationVariables = Exact<{
  input?: InputMaybe<Input_core_apimessages_BillingMethodVerifyShopperRequest>;
}>;

export type BillingMethodVerifyShopperMutation = {
  __typename?: "Mutation";
  billingMethodVerifyShopper?: {
    __typename?: "core_apimessages_BillingMethodVerifyShopperResponse";
    billingMethodUuid?: string | null;
    creditCard?: {
      __typename?: "core_apimessages_CreditCard";
      id?: string | null;
    } | null;
    adyenPaymentResult?: {
      __typename?: "core_apimessages_AdyenPaymentResult";
      action?: string | null;
      paymentStatus?: core_apimessages_AdyenPaymentResult_PaymentStatus | null;
      challengeToken?: string | null;
      paymentData?: string | null;
    } | null;
    me?: {
      __typename?: "rql_Me";
      _id?: string | null;
      uuid?: string | null;
      shop?: {
        __typename?: "MyShop";
        _id?: string | null;
        id?: string | null;
        currency?: string | null;
        originCountryCode?: string | null;
        shopType?: core_apimessages_Shop_ShopType | null;
        billingMethodStatus?: core_apimessages_MyShopOnboardingResponse_BillingMethodStatus | null;
        paypalSetupStatus?: core_apimessages_MyShopOnboardingResponse_PaypalSetupStatus | null;
        paypalProfile?: {
          __typename?: "core_apimessages_MyShopPaypalProfileResponse_PaypalProfile";
          email?: string | null;
        } | null;
      } | null;
      creditCards?: Array<{
        __typename?: "core_apimessages_CreditCard";
        id?: string | null;
        last4?: string | null;
        cardType?: string | null;
        expirationYear?: string | null;
        expirationMonth?: string | null;
        cardholderName?: string | null;
        primaryBilling?: boolean | null;
        address?: {
          __typename?: "core_apimessages_Address";
          _id?: string | null;
          postalCode?: string | null;
          countryCode?: string | null;
        } | null;
      } | null> | null;
    } | null;
  } | null;
};

export type Core_BrandCompletionsQueryVariables = Exact<{
  query?: InputMaybe<Scalars["String"]["input"]>;
  skip: Scalars["Boolean"]["input"];
}>;

export type Core_BrandCompletionsQuery = {
  __typename?: "Query";
  completions?: {
    __typename?: "reverb_search_SearchResponse";
    completions?: Array<{
      __typename?: "reverb_search_Completion";
      text?: string | null;
      type?: reverb_search_CompletionType | null;
      options?: Array<{
        __typename?: "reverb_search_SuggestOption";
        slug?: string | null;
        output?: string | null;
      } | null> | null;
    } | null> | null;
  } | null;
};

export type Core_Layout_BrowsePageQueryVariables = Exact<{
  id?: InputMaybe<Scalars["String"]["input"]>;
}>;

export type Core_Layout_BrowsePageQuery = {
  __typename?: "Query";
  browsepage?: {
    __typename?: "BrowsePage";
    _id?: string | null;
    id?: string | null;
    title?: string | null;
    url?: string | null;
    combinedFilters?: string | null;
    breadcrumbs?: Array<{
      __typename?: "core_apimessages_BrowsePageBreadcrumb";
      title?: string | null;
      url?: string | null;
    } | null> | null;
  } | null;
};

export type Core_Layout_BrowsePageLinksQueryVariables = Exact<{
  id?: InputMaybe<Scalars["String"]["input"]>;
}>;

export type Core_Layout_BrowsePageLinksQuery = {
  __typename?: "Query";
  browsepage?: {
    __typename?: "BrowsePage";
    _id?: string | null;
    id?: string | null;
    links?: Array<{
      __typename?: "core_apimessages_BrowsePageLink";
      url?: string | null;
      matchType?: core_apimessages_BrowsePageLink_MatchType | null;
      combinedFilters?: string | null;
      matchParams?: Array<{
        __typename?: "core_apimessages_BrowsePageLink_MatchParams";
        paramName?: string | null;
        value?: string | null;
      } | null> | null;
    } | null> | null;
  } | null;
};

export type BrowseListingsFieldsFragment = {
  __typename?: "Listing";
  _id?: string | null;
  id?: string | null;
  sellerId?: string | null;
  listingType?: core_apimessages_Listing_ListingType | null;
  title?: string | null;
  slug?: string | null;
  make?: string | null;
  model?: string | null;
  upc?: string | null;
  state?: string | null;
  stateDescription?: string | null;
  bumped?: boolean | null;
  watching?: boolean | null;
  soldAsIs?: boolean | null;
  usOutlet?: boolean | null;
  offersEnabled?: boolean | null;
  isBuyerOfferEligible?: boolean | null;
  currency?: string | null;
  brandSlug?: string | null;
  categoryRootUuid?: string | null;
  categoryUuids?: Array<string | null> | null;
  bumpEligible?: boolean | null;
  shopId?: string | null;
  inventory?: number | null;
  acceptedPaymentMethods?: Array<string | null> | null;
  csp?: {
    __typename?: "CSP";
    _id?: string | null;
    averageReviewRating?: number | null;
    reviewsCount?: number | null;
    id?: string | null;
    slug?: string | null;
    brand?: {
      __typename?: "Brand";
      _id?: string | null;
      slug?: string | null;
    } | null;
  } | null;
  pricing?: {
    __typename?: "core_apimessages_ListingPricing";
    buyerPrice?: {
      __typename?: "core_apimessages_Money";
      amount?: string | null;
      amountCents?: number | null;
      display?: string | null;
      currency?: string | null;
    } | null;
    originalPrice?: {
      __typename?: "core_apimessages_Money";
      display?: string | null;
    } | null;
    ribbon?: {
      __typename?: "core_apimessages_Ribbon";
      display?: string | null;
      reason?: core_apimessages_Ribbon_Reason | null;
    } | null;
  } | null;
  preorderInfo?: {
    __typename?: "core_apimessages_PreorderInfo";
    onPreorder?: boolean | null;
    estimatedShipDate?: {
      __typename?: "google_protobuf_Timestamp";
      seconds?: number | null;
    } | null;
  } | null;
  publishedAt?: {
    __typename?: "google_protobuf_Timestamp";
    seconds?: number | null;
  } | null;
  condition?: {
    __typename?: "core_apimessages_Condition";
    displayName?: string | null;
    conditionSlug?: string | null;
    conditionUuid?: string | null;
  } | null;
  images?: Array<{
    __typename?: "core_apimessages_Image";
    source?: string | null;
  } | null> | null;
  shipping?: {
    __typename?: "core_apimessages_ShippingPrices";
    freeExpeditedShipping?: boolean | null;
    localPickupOnly?: boolean | null;
    localPickup?: boolean | null;
    shippingPrices?: Array<{
      __typename?: "core_apimessages_ShippingPrice";
      _id?: string | null;
      shippingMethod?: core_apimessages_ShippingMethod | null;
      carrierCalculated?: boolean | null;
      destinationPostalCodeNeeded?: boolean | null;
      regional?: boolean | null;
      postalCode?: string | null;
      rate?: {
        __typename?: "core_apimessages_Money";
        amount?: string | null;
        amountCents?: number | null;
        currency?: string | null;
        display?: string | null;
      } | null;
    } | null> | null;
  } | null;
  shop?: {
    __typename?: "Shop";
    _id?: string | null;
    name?: string | null;
    returnPolicy?: {
      __typename?: "core_apimessages_ReturnPolicy";
      usedReturnWindowDays?: number | null;
      newReturnWindowDays?: number | null;
      _id?: string | null;
    } | null;
    address?: {
      __typename?: "core_apimessages_Address";
      _id?: string | null;
      locality?: string | null;
      region?: string | null;
      displayLocation?: string | null;
      countryCode?: string | null;
      country?: {
        __typename?: "core_apimessages_Country";
        _id?: string | null;
        countryCode?: string | null;
        name?: string | null;
      } | null;
    } | null;
  } | null;
  watchThumbnails?: Array<{
    __typename?: "core_apimessages_Image";
    _id?: string | null;
    source?: string | null;
  } | null> | null;
  priceRecommendation?: {
    __typename?: "reverb_pricing_PriceRecommendation";
    priceMiddle?: {
      __typename?: "reverb_pricing_Money";
      amountCents?: number | null;
      currency?: string | null;
    } | null;
  } | null;
  certifiedPreOwned?: {
    __typename?: "core_apimessages_ListingCertifiedPreOwned";
    title?: string | null;
    badgeIconUrl?: string | null;
  } | null;
  categories?: Array<{
    __typename?: "Category";
    _id?: string | null;
    slug?: string | null;
    rootSlug?: string | null;
  } | null> | null;
  sale?: {
    __typename?: "core_apimessages_Sale";
    _id?: string | null;
    id?: string | null;
    code?: string | null;
    buyerIneligibilityReason?: string | null;
  } | null;
};

export type Core_Listing_BrowsePageGridQueryVariables = Exact<{
  acceptsAffirm?: InputMaybe<Scalars["Boolean"]["input"]>;
  acceptsOffers?: InputMaybe<Scalars["Boolean"]["input"]>;
  acceptsPaymentPlans?: InputMaybe<Scalars["Boolean"]["input"]>;
  applyProximityBoost?: InputMaybe<Scalars["Boolean"]["input"]>;
  boostedItemRegionCode?: InputMaybe<Scalars["String"]["input"]>;
  brandSlugs?: InputMaybe<
    | Array<InputMaybe<Scalars["String"]["input"]>>
    | InputMaybe<Scalars["String"]["input"]>
  >;
  categorySlugs?: InputMaybe<
    | Array<InputMaybe<Scalars["String"]["input"]>>
    | InputMaybe<Scalars["String"]["input"]>
  >;
  combinedShipping?: InputMaybe<Scalars["Boolean"]["input"]>;
  conditionSlugs?: InputMaybe<
    | Array<InputMaybe<Scalars["String"]["input"]>>
    | InputMaybe<Scalars["String"]["input"]>
  >;
  contexts?: InputMaybe<
    Array<InputMaybe<reverb_search_Context>> | InputMaybe<reverb_search_Context>
  >;
  cspSlug?: InputMaybe<Scalars["String"]["input"]>;
  countryOfOrigin?: InputMaybe<
    | Array<InputMaybe<Scalars["String"]["input"]>>
    | InputMaybe<Scalars["String"]["input"]>
  >;
  curatedSetSlugs?: InputMaybe<
    | Array<InputMaybe<Scalars["String"]["input"]>>
    | InputMaybe<Scalars["String"]["input"]>
  >;
  currency?: InputMaybe<Scalars["String"]["input"]>;
  dealsAndSteals?: InputMaybe<Scalars["Boolean"]["input"]>;
  decades?: InputMaybe<
    | Array<InputMaybe<Scalars["String"]["input"]>>
    | InputMaybe<Scalars["String"]["input"]>
  >;
  fallbackToOr?: InputMaybe<Scalars["Boolean"]["input"]>;
  freeShipping?: InputMaybe<Scalars["Boolean"]["input"]>;
  freeExpeditedShipping?: InputMaybe<Scalars["Boolean"]["input"]>;
  handmade?: InputMaybe<Scalars["Boolean"]["input"]>;
  holidaySale?: InputMaybe<Scalars["Boolean"]["input"]>;
  itemCity?: InputMaybe<
    | Array<InputMaybe<Scalars["String"]["input"]>>
    | InputMaybe<Scalars["String"]["input"]>
  >;
  itemRegion?: InputMaybe<Scalars["String"]["input"]>;
  itemState?: InputMaybe<
    | Array<InputMaybe<Scalars["String"]["input"]>>
    | InputMaybe<Scalars["String"]["input"]>
  >;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  localPickup?: InputMaybe<Scalars["Boolean"]["input"]>;
  minSaleDiscountPercent?: InputMaybe<Scalars["String"]["input"]>;
  multiClientExperiments?: InputMaybe<
    | Array<InputMaybe<Input_reverb_search_ExperimentData>>
    | InputMaybe<Input_reverb_search_ExperimentData>
  >;
  preferredSeller?: InputMaybe<Scalars["Boolean"]["input"]>;
  priceMax?: InputMaybe<Scalars["String"]["input"]>;
  priceMin?: InputMaybe<Scalars["String"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  onSale?: InputMaybe<Scalars["Boolean"]["input"]>;
  query?: InputMaybe<Scalars["String"]["input"]>;
  saleSlugs?: InputMaybe<
    | Array<InputMaybe<Scalars["String"]["input"]>>
    | InputMaybe<Scalars["String"]["input"]>
  >;
  shippingRegionCode?: InputMaybe<
    | Array<InputMaybe<Scalars["String"]["input"]>>
    | InputMaybe<Scalars["String"]["input"]>
  >;
  showOnlySold?: InputMaybe<Scalars["Boolean"]["input"]>;
  shopSlug?: InputMaybe<Scalars["String"]["input"]>;
  showSold?: InputMaybe<Scalars["Boolean"]["input"]>;
  similarListingIds?: InputMaybe<
    | Array<InputMaybe<Scalars["String"]["input"]>>
    | InputMaybe<Scalars["String"]["input"]>
  >;
  sortSlug?: InputMaybe<Scalars["String"]["input"]>;
  terminateEarly?: InputMaybe<Scalars["Boolean"]["input"]>;
  traitValues?: InputMaybe<
    | Array<InputMaybe<Scalars["String"]["input"]>>
    | InputMaybe<Scalars["String"]["input"]>
  >;
  useExperimentalRecall?: InputMaybe<Scalars["Boolean"]["input"]>;
  withAggregations?: InputMaybe<
    | Array<InputMaybe<reverb_search_ListingsSearchRequest_Aggregation>>
    | InputMaybe<reverb_search_ListingsSearchRequest_Aggregation>
  >;
  withProximityFilter?: InputMaybe<Input_reverb_search_ProximityFilterRequest>;
  yearMax?: InputMaybe<Scalars["String"]["input"]>;
  yearMin?: InputMaybe<Scalars["String"]["input"]>;
}>;

export type Core_Listing_BrowsePageGridQuery = {
  __typename?: "Query";
  listingsSearch?: {
    __typename?: "reverb_search_SearchResponse";
    total?: number | null;
    offset?: number | null;
    limit?: number | null;
    listings?: Array<{
      __typename?: "Listing";
      _id?: string | null;
      id?: string | null;
      sellerId?: string | null;
      listingType?: core_apimessages_Listing_ListingType | null;
      title?: string | null;
      slug?: string | null;
      make?: string | null;
      model?: string | null;
      upc?: string | null;
      state?: string | null;
      stateDescription?: string | null;
      bumped?: boolean | null;
      watching?: boolean | null;
      soldAsIs?: boolean | null;
      usOutlet?: boolean | null;
      offersEnabled?: boolean | null;
      isBuyerOfferEligible?: boolean | null;
      currency?: string | null;
      brandSlug?: string | null;
      categoryRootUuid?: string | null;
      categoryUuids?: Array<string | null> | null;
      bumpEligible?: boolean | null;
      shopId?: string | null;
      inventory?: number | null;
      acceptedPaymentMethods?: Array<string | null> | null;
      csp?: {
        __typename?: "CSP";
        _id?: string | null;
        averageReviewRating?: number | null;
        reviewsCount?: number | null;
        id?: string | null;
        slug?: string | null;
        brand?: {
          __typename?: "Brand";
          _id?: string | null;
          slug?: string | null;
        } | null;
      } | null;
      pricing?: {
        __typename?: "core_apimessages_ListingPricing";
        buyerPrice?: {
          __typename?: "core_apimessages_Money";
          amount?: string | null;
          amountCents?: number | null;
          display?: string | null;
          currency?: string | null;
        } | null;
        originalPrice?: {
          __typename?: "core_apimessages_Money";
          display?: string | null;
        } | null;
        ribbon?: {
          __typename?: "core_apimessages_Ribbon";
          display?: string | null;
          reason?: core_apimessages_Ribbon_Reason | null;
        } | null;
      } | null;
      preorderInfo?: {
        __typename?: "core_apimessages_PreorderInfo";
        onPreorder?: boolean | null;
        estimatedShipDate?: {
          __typename?: "google_protobuf_Timestamp";
          seconds?: number | null;
        } | null;
      } | null;
      publishedAt?: {
        __typename?: "google_protobuf_Timestamp";
        seconds?: number | null;
      } | null;
      condition?: {
        __typename?: "core_apimessages_Condition";
        displayName?: string | null;
        conditionSlug?: string | null;
        conditionUuid?: string | null;
      } | null;
      images?: Array<{
        __typename?: "core_apimessages_Image";
        source?: string | null;
      } | null> | null;
      shipping?: {
        __typename?: "core_apimessages_ShippingPrices";
        freeExpeditedShipping?: boolean | null;
        localPickupOnly?: boolean | null;
        localPickup?: boolean | null;
        shippingPrices?: Array<{
          __typename?: "core_apimessages_ShippingPrice";
          _id?: string | null;
          shippingMethod?: core_apimessages_ShippingMethod | null;
          carrierCalculated?: boolean | null;
          destinationPostalCodeNeeded?: boolean | null;
          regional?: boolean | null;
          postalCode?: string | null;
          rate?: {
            __typename?: "core_apimessages_Money";
            amount?: string | null;
            amountCents?: number | null;
            currency?: string | null;
            display?: string | null;
          } | null;
        } | null> | null;
      } | null;
      shop?: {
        __typename?: "Shop";
        _id?: string | null;
        name?: string | null;
        returnPolicy?: {
          __typename?: "core_apimessages_ReturnPolicy";
          usedReturnWindowDays?: number | null;
          newReturnWindowDays?: number | null;
          _id?: string | null;
        } | null;
        address?: {
          __typename?: "core_apimessages_Address";
          _id?: string | null;
          locality?: string | null;
          region?: string | null;
          displayLocation?: string | null;
          countryCode?: string | null;
          country?: {
            __typename?: "core_apimessages_Country";
            _id?: string | null;
            countryCode?: string | null;
            name?: string | null;
          } | null;
        } | null;
      } | null;
      watchThumbnails?: Array<{
        __typename?: "core_apimessages_Image";
        _id?: string | null;
        source?: string | null;
      } | null> | null;
      priceRecommendation?: {
        __typename?: "reverb_pricing_PriceRecommendation";
        priceMiddle?: {
          __typename?: "reverb_pricing_Money";
          amountCents?: number | null;
          currency?: string | null;
        } | null;
      } | null;
      certifiedPreOwned?: {
        __typename?: "core_apimessages_ListingCertifiedPreOwned";
        title?: string | null;
        badgeIconUrl?: string | null;
      } | null;
      categories?: Array<{
        __typename?: "Category";
        _id?: string | null;
        slug?: string | null;
        rootSlug?: string | null;
      } | null> | null;
      sale?: {
        __typename?: "core_apimessages_Sale";
        _id?: string | null;
        id?: string | null;
        code?: string | null;
        buyerIneligibilityReason?: string | null;
      } | null;
    } | null> | null;
    filters?: Array<{
      __typename?: "reverb_search_Filter";
      name?: string | null;
      key?: string | null;
      aggregationName?: string | null;
      widgetType?: reverb_search_Filter_WidgetType | null;
      options?: Array<{
        __typename?: "reverb_search_FilterOption";
        selected?: boolean | null;
        autoselected?: boolean | null;
        name?: string | null;
        paramName?: string | null;
        setValues?: Array<string | null> | null;
        unsetValues?: Array<string | null> | null;
        all?: boolean | null;
        optionValue?: string | null;
        trackingValue?: string | null;
        count?: {
          __typename?: "google_protobuf_Int32Value";
          value?: number | null;
        } | null;
        subFilter?: {
          __typename?: "reverb_search_Filter";
          widgetType?: reverb_search_Filter_WidgetType | null;
          options?: Array<{
            __typename?: "reverb_search_FilterOption";
            name?: string | null;
            selected?: boolean | null;
            autoselected?: boolean | null;
            paramName?: string | null;
            setValues?: Array<string | null> | null;
            unsetValues?: Array<string | null> | null;
            all?: boolean | null;
            optionValue?: string | null;
            trackingValue?: string | null;
            count?: {
              __typename?: "google_protobuf_Int32Value";
              value?: number | null;
            } | null;
            subFilter?: {
              __typename?: "reverb_search_Filter";
              widgetType?: reverb_search_Filter_WidgetType | null;
              options?: Array<{
                __typename?: "reverb_search_FilterOption";
                name?: string | null;
                selected?: boolean | null;
                autoselected?: boolean | null;
                paramName?: string | null;
                setValues?: Array<string | null> | null;
                unsetValues?: Array<string | null> | null;
                all?: boolean | null;
                optionValue?: string | null;
                trackingValue?: string | null;
                count?: {
                  __typename?: "google_protobuf_Int32Value";
                  value?: number | null;
                } | null;
                subFilter?: {
                  __typename?: "reverb_search_Filter";
                  widgetType?: reverb_search_Filter_WidgetType | null;
                  options?: Array<{
                    __typename?: "reverb_search_FilterOption";
                    name?: string | null;
                    selected?: boolean | null;
                    autoselected?: boolean | null;
                    paramName?: string | null;
                    setValues?: Array<string | null> | null;
                    unsetValues?: Array<string | null> | null;
                    all?: boolean | null;
                    optionValue?: string | null;
                    trackingValue?: string | null;
                    count?: {
                      __typename?: "google_protobuf_Int32Value";
                      value?: number | null;
                    } | null;
                  } | null> | null;
                } | null;
              } | null> | null;
            } | null;
          } | null> | null;
        } | null;
      } | null> | null;
    } | null> | null;
  } | null;
};

export type BumpRecommendationsAttributesFragment = {
  __typename?: "Listing";
  _id?: string | null;
  condition?: {
    __typename?: "core_apimessages_Condition";
    conditionUuid?: string | null;
    conditionSlug?: string | null;
    displayName?: string | null;
  } | null;
  csp?: {
    __typename?: "CSP";
    _id?: string | null;
    id?: string | null;
    title?: string | null;
    slug?: string | null;
  } | null;
};

export type BumpRecommendationsFragmentFragment = {
  __typename?: "Listing";
  _id?: string | null;
  bumpRecommendations?: {
    __typename?: "reverb_search_BumpRecommendationsResults";
    bumpRecommendationAmount?: number | null;
  } | null;
};

export type CoreBumpRecommendationListingCardFragment = {
  __typename?: "Listing";
  _id?: string | null;
  hasInventory?: boolean | null;
  id?: string | null;
  title?: string | null;
  brandSlug?: string | null;
  categoryRootUuid?: string | null;
  make?: string | null;
  categoryUuids?: Array<string | null> | null;
  state?: string | null;
  listingType?: core_apimessages_Listing_ListingType | null;
  bumpEligible?: boolean | null;
  shopId?: string | null;
  inventory?: number | null;
  soldAsIs?: boolean | null;
  acceptedPaymentMethods?: Array<string | null> | null;
  currency?: string | null;
  usOutlet?: boolean | null;
  images?: Array<{
    __typename?: "core_apimessages_Image";
    _id?: string | null;
    source?: string | null;
  } | null> | null;
  sellerPrice?: {
    __typename?: "core_apimessages_Money";
    amount?: string | null;
    amountCents?: number | null;
    currency?: string | null;
    display?: string | null;
  } | null;
  shipping?: {
    __typename?: "core_apimessages_ShippingPrices";
    freeExpeditedShipping?: boolean | null;
    localPickupOnly?: boolean | null;
    localPickup?: boolean | null;
    shippingPrices?: Array<{
      __typename?: "core_apimessages_ShippingPrice";
      _id?: string | null;
      shippingRegionCode?: string | null;
      shippingMethod?: core_apimessages_ShippingMethod | null;
      carrierCalculated?: boolean | null;
      destinationPostalCodeNeeded?: boolean | null;
      originalRate?: {
        __typename?: "core_apimessages_Money";
        amount?: string | null;
        amountCents?: number | null;
        currency?: string | null;
        display?: string | null;
      } | null;
      rate?: {
        __typename?: "core_apimessages_Money";
        amount?: string | null;
        amountCents?: number | null;
        currency?: string | null;
        display?: string | null;
      } | null;
    } | null> | null;
  } | null;
  condition?: {
    __typename?: "core_apimessages_Condition";
    conditionUuid?: string | null;
    conditionSlug?: string | null;
    displayName?: string | null;
  } | null;
  categories?: Array<{
    __typename?: "Category";
    _id?: string | null;
    slug?: string | null;
    rootSlug?: string | null;
  } | null> | null;
  csp?: {
    __typename?: "CSP";
    _id?: string | null;
    id?: string | null;
    slug?: string | null;
    title?: string | null;
    brand?: {
      __typename?: "Brand";
      _id?: string | null;
      slug?: string | null;
    } | null;
  } | null;
  pricing?: {
    __typename?: "core_apimessages_ListingPricing";
    buyerPrice?: {
      __typename?: "core_apimessages_Money";
      amount?: string | null;
      currency?: string | null;
      amountCents?: number | null;
    } | null;
  } | null;
  publishedAt?: {
    __typename?: "google_protobuf_Timestamp";
    seconds?: number | null;
  } | null;
  sale?: {
    __typename?: "core_apimessages_Sale";
    _id?: string | null;
    id?: string | null;
    code?: string | null;
    buyerIneligibilityReason?: string | null;
  } | null;
  certifiedPreOwned?: {
    __typename?: "core_apimessages_ListingCertifiedPreOwned";
    title?: string | null;
    badgeIconUrl?: string | null;
  } | null;
  shop?: {
    __typename?: "Shop";
    _id?: string | null;
    address?: {
      __typename?: "core_apimessages_Address";
      _id?: string | null;
      countryCode?: string | null;
    } | null;
    returnPolicy?: {
      __typename?: "core_apimessages_ReturnPolicy";
      _id?: string | null;
      newReturnWindowDays?: number | null;
      usedReturnWindowDays?: number | null;
    } | null;
  } | null;
};

export type Core_Bump_Recommendation_ListingQueryVariables = Exact<{
  id?: InputMaybe<Scalars["String"]["input"]>;
}>;

export type Core_Bump_Recommendation_ListingQuery = {
  __typename?: "Query";
  listing?: {
    __typename?: "Listing";
    _id?: string | null;
    hasInventory?: boolean | null;
    id?: string | null;
    title?: string | null;
    brandSlug?: string | null;
    categoryRootUuid?: string | null;
    make?: string | null;
    categoryUuids?: Array<string | null> | null;
    state?: string | null;
    listingType?: core_apimessages_Listing_ListingType | null;
    bumpEligible?: boolean | null;
    shopId?: string | null;
    inventory?: number | null;
    soldAsIs?: boolean | null;
    acceptedPaymentMethods?: Array<string | null> | null;
    currency?: string | null;
    usOutlet?: boolean | null;
    images?: Array<{
      __typename?: "core_apimessages_Image";
      _id?: string | null;
      source?: string | null;
    } | null> | null;
    sellerPrice?: {
      __typename?: "core_apimessages_Money";
      amount?: string | null;
      amountCents?: number | null;
      currency?: string | null;
      display?: string | null;
    } | null;
    shipping?: {
      __typename?: "core_apimessages_ShippingPrices";
      freeExpeditedShipping?: boolean | null;
      localPickupOnly?: boolean | null;
      localPickup?: boolean | null;
      shippingPrices?: Array<{
        __typename?: "core_apimessages_ShippingPrice";
        _id?: string | null;
        shippingRegionCode?: string | null;
        shippingMethod?: core_apimessages_ShippingMethod | null;
        carrierCalculated?: boolean | null;
        destinationPostalCodeNeeded?: boolean | null;
        originalRate?: {
          __typename?: "core_apimessages_Money";
          amount?: string | null;
          amountCents?: number | null;
          currency?: string | null;
          display?: string | null;
        } | null;
        rate?: {
          __typename?: "core_apimessages_Money";
          amount?: string | null;
          amountCents?: number | null;
          currency?: string | null;
          display?: string | null;
        } | null;
      } | null> | null;
    } | null;
    bumpRecommendations?: {
      __typename?: "reverb_search_BumpRecommendationsResults";
      bumpRecommendationAmount?: number | null;
    } | null;
    condition?: {
      __typename?: "core_apimessages_Condition";
      conditionUuid?: string | null;
      conditionSlug?: string | null;
      displayName?: string | null;
    } | null;
    categories?: Array<{
      __typename?: "Category";
      _id?: string | null;
      slug?: string | null;
      rootSlug?: string | null;
    } | null> | null;
    csp?: {
      __typename?: "CSP";
      _id?: string | null;
      id?: string | null;
      slug?: string | null;
      title?: string | null;
      brand?: {
        __typename?: "Brand";
        _id?: string | null;
        slug?: string | null;
      } | null;
    } | null;
    pricing?: {
      __typename?: "core_apimessages_ListingPricing";
      buyerPrice?: {
        __typename?: "core_apimessages_Money";
        amount?: string | null;
        currency?: string | null;
        amountCents?: number | null;
      } | null;
    } | null;
    publishedAt?: {
      __typename?: "google_protobuf_Timestamp";
      seconds?: number | null;
    } | null;
    sale?: {
      __typename?: "core_apimessages_Sale";
      _id?: string | null;
      id?: string | null;
      code?: string | null;
      buyerIneligibilityReason?: string | null;
    } | null;
    certifiedPreOwned?: {
      __typename?: "core_apimessages_ListingCertifiedPreOwned";
      title?: string | null;
      badgeIconUrl?: string | null;
    } | null;
    shop?: {
      __typename?: "Shop";
      _id?: string | null;
      address?: {
        __typename?: "core_apimessages_Address";
        _id?: string | null;
        countryCode?: string | null;
      } | null;
      returnPolicy?: {
        __typename?: "core_apimessages_ReturnPolicy";
        _id?: string | null;
        newReturnWindowDays?: number | null;
        usedReturnWindowDays?: number | null;
      } | null;
    } | null;
  } | null;
};

export type CartBundleFreeShippingStatusesFieldsFragment = {
  __typename?: "rql_Me";
  _id?: string | null;
  cart?: {
    __typename?: "rql_MyCart";
    bundles?: Array<{
      __typename?: "core_apimessages_CartItemBundle";
      freeShippingStatuses?: Array<{
        __typename?: "core_apimessages_CartItemBundle_FreeShippingStatus";
        shopId?: string | null;
        isThresholdMet?: boolean | null;
        percentProgress?: number | null;
        amountRemaining?: {
          __typename?: "core_apimessages_Money";
          display?: string | null;
        } | null;
        shop?: {
          __typename?: "Shop";
          _id?: string | null;
          name?: string | null;
          slug?: string | null;
        } | null;
      } | null> | null;
    } | null> | null;
  } | null;
};

export type CartBundleItemListFieldsFragment = {
  __typename?: "rql_Me";
  _id?: string | null;
  uuid?: string | null;
  cart?: {
    __typename?: "rql_MyCart";
    bundles?: Array<{
      __typename?: "core_apimessages_CartItemBundle";
      cartItems?: Array<{
        __typename?: "core_apimessages_CartItem";
        _id?: string | null;
        uuid?: string | null;
        quantity?: number | null;
        protectionPlanEligible?: boolean | null;
        actions?: {
          __typename?: "core_apimessages_CartItem_Actions";
          canChangeQuantity?: boolean | null;
          canRemoveFromCart?: boolean | null;
          canMoveToWatchlist?: boolean | null;
        } | null;
        alerts?: Array<{
          __typename?: "core_apimessages_CartItem_Alert";
          type?: core_apimessages_CartItem_Alert_Type | null;
          text?: string | null;
          severity?: core_apimessages_CartItem_Alert_Severity | null;
        } | null> | null;
        listing?: {
          __typename?: "Listing";
          _id?: string | null;
          id?: string | null;
          otherBuyersWithListingInCartCounts?: number | null;
          title?: string | null;
          allowedBuyerQuantity?: number | null;
          slug?: string | null;
          listingType?: core_apimessages_Listing_ListingType | null;
          brandSlug?: string | null;
          categoryRootUuid?: string | null;
          make?: string | null;
          categoryUuids?: Array<string | null> | null;
          state?: string | null;
          bumpEligible?: boolean | null;
          shopId?: string | null;
          inventory?: number | null;
          soldAsIs?: boolean | null;
          acceptedPaymentMethods?: Array<string | null> | null;
          currency?: string | null;
          usOutlet?: boolean | null;
          images?: Array<{
            __typename?: "core_apimessages_Image";
            source?: string | null;
          } | null> | null;
          shop?: {
            __typename?: "Shop";
            _id?: string | null;
            slug?: string | null;
            name?: string | null;
            address?: {
              __typename?: "core_apimessages_Address";
              _id?: string | null;
              displayLocation?: string | null;
              countryCode?: string | null;
            } | null;
            returnPolicy?: {
              __typename?: "core_apimessages_ReturnPolicy";
              _id?: string | null;
              newReturnWindowDays?: number | null;
              usedReturnWindowDays?: number | null;
            } | null;
          } | null;
          preorderInfo?: {
            __typename?: "core_apimessages_PreorderInfo";
            onPreorder?: boolean | null;
            estimatedShipDate?: {
              __typename?: "google_protobuf_Timestamp";
              seconds?: number | null;
            } | null;
          } | null;
          shipping?: {
            __typename?: "core_apimessages_ShippingPrices";
            freeExpeditedShipping?: boolean | null;
            localPickupOnly?: boolean | null;
            localPickup?: boolean | null;
            shippingPrices?: Array<{
              __typename?: "core_apimessages_ShippingPrice";
              _id?: string | null;
              carrierCalculated?: boolean | null;
              destinationPostalCodeNeeded?: boolean | null;
              regional?: boolean | null;
              shippingMethod?: core_apimessages_ShippingMethod | null;
              rate?: {
                __typename?: "core_apimessages_Money";
                amount?: string | null;
                amountCents?: number | null;
                currency?: string | null;
                display?: string | null;
              } | null;
            } | null> | null;
          } | null;
          condition?: {
            __typename?: "core_apimessages_Condition";
            conditionUuid?: string | null;
            conditionSlug?: string | null;
          } | null;
          categories?: Array<{
            __typename?: "Category";
            _id?: string | null;
            slug?: string | null;
            rootSlug?: string | null;
          } | null> | null;
          csp?: {
            __typename?: "CSP";
            _id?: string | null;
            id?: string | null;
            slug?: string | null;
            brand?: {
              __typename?: "Brand";
              _id?: string | null;
              slug?: string | null;
            } | null;
          } | null;
          pricing?: {
            __typename?: "core_apimessages_ListingPricing";
            buyerPrice?: {
              __typename?: "core_apimessages_Money";
              amount?: string | null;
              currency?: string | null;
              amountCents?: number | null;
            } | null;
          } | null;
          publishedAt?: {
            __typename?: "google_protobuf_Timestamp";
            seconds?: number | null;
          } | null;
          sale?: {
            __typename?: "core_apimessages_Sale";
            _id?: string | null;
            id?: string | null;
            code?: string | null;
            buyerIneligibilityReason?: string | null;
          } | null;
          certifiedPreOwned?: {
            __typename?: "core_apimessages_ListingCertifiedPreOwned";
            title?: string | null;
            badgeIconUrl?: string | null;
          } | null;
        } | null;
        order?: {
          __typename?: "Order";
          _id?: string | null;
          orderType?: string | null;
          id?: string | null;
          legacyOrderId?: string | null;
        } | null;
        cartItemProtectionPlan?: {
          __typename?: "core_apimessages_ProtectionPlan";
          planId?: string | null;
          durationMonths?: string | null;
          amount?: {
            __typename?: "core_apimessages_Money";
            display?: string | null;
            amount?: string | null;
          } | null;
        } | null;
        listingTotal?: {
          __typename?: "core_apimessages_CartItem_ListingTotal";
          discounted?: boolean | null;
          discount?: {
            __typename?: "core_apimessages_CartItem_ListingTotal_Discount";
            type?: core_apimessages_CartItem_ListingTotal_Discount_Type | null;
            label?: string | null;
          } | null;
          unitPrice?: {
            __typename?: "core_apimessages_Money";
            display?: string | null;
            currency?: string | null;
          } | null;
          originalUnitPrice?: {
            __typename?: "core_apimessages_Money";
            display?: string | null;
          } | null;
        } | null;
        shippingTotal?: {
          __typename?: "core_apimessages_CartItem_ShippingTotal";
          discounted?: boolean | null;
          discount?: {
            __typename?: "core_apimessages_CartItem_ShippingTotal_Discount";
            type?: core_apimessages_CartItem_ShippingTotal_Discount_Type | null;
            label?: string | null;
          } | null;
          amountOwed?: {
            __typename?: "core_apimessages_Money";
            display?: string | null;
          } | null;
          originalAmountOwed?: {
            __typename?: "core_apimessages_Money";
            display?: string | null;
          } | null;
        } | null;
        paymentMethods?: Array<{
          __typename?: "core_apimessages_CartItem_PaymentMethod";
          type?: core_apimessages_CheckoutPaymentMethod_Type | null;
        } | null> | null;
      } | null> | null;
    } | null> | null;
  } | null;
};

export type CartBundleOrderSummaryFieldsFragment = {
  __typename?: "rql_Me";
  _id?: string | null;
  cart?: {
    __typename?: "rql_MyCart";
    bundles?: Array<{
      __typename?: "core_apimessages_CartItemBundle";
      checkoutBundlingId?: string | null;
      availablePaymentMethods?: Array<{
        __typename?: "core_apimessages_CartItemBundle_PaymentMethod";
        type?: core_apimessages_CheckoutPaymentMethod_Type | null;
        name?: string | null;
        applePay?: {
          __typename?: "core_apimessages_CheckoutApplePayDetails";
          merchantId?: string | null;
          merchantName?: string | null;
        } | null;
        paypal?: {
          __typename?: "core_apimessages_CheckoutPaypalDetails";
          currency?: string | null;
        } | null;
        affirm?: {
          __typename?: "core_apimessages_CheckoutAffirmDetails";
          financingProgramSlug?: string | null;
        } | null;
      } | null> | null;
      paymentMethodIcons?: Array<{
        __typename?: "core_apimessages_PaymentCardIcon";
        slug?: string | null;
        description?: string | null;
        url?: string | null;
      } | null> | null;
      total?: {
        __typename?: "core_apimessages_CartItemBundle_Total";
        label?: string | null;
        amount?: {
          __typename?: "core_apimessages_Money";
          display?: string | null;
          currency?: string | null;
          amountCents?: number | null;
        } | null;
      } | null;
      lineItems?: Array<{
        __typename?: "core_apimessages_CartItemBundle_LineItem";
        type?: core_apimessages_CartItemBundle_LineItem_Type | null;
        label?: string | null;
        amount?: {
          __typename?: "core_apimessages_Money";
          display?: string | null;
          currency?: string | null;
          amountCents?: number | null;
        } | null;
      } | null> | null;
    } | null> | null;
  } | null;
};

export type CartBundlePaymentMethodSelectorFieldsFragment = {
  __typename?: "rql_Me";
  _id?: string | null;
  cart?: {
    __typename?: "rql_MyCart";
    bundles?: Array<{
      __typename?: "core_apimessages_CartItemBundle";
      checkoutBundlingId?: string | null;
      total?: {
        __typename?: "core_apimessages_CartItemBundle_Total";
        amount?: {
          __typename?: "core_apimessages_Money";
          amountCents?: number | null;
          currency?: string | null;
        } | null;
      } | null;
      availablePaymentMethods?: Array<{
        __typename?: "core_apimessages_CartItemBundle_PaymentMethod";
        type?: core_apimessages_CheckoutPaymentMethod_Type | null;
        name?: string | null;
        affirm?: {
          __typename?: "core_apimessages_CheckoutAffirmDetails";
          financingProgramSlug?: string | null;
        } | null;
      } | null> | null;
    } | null> | null;
  } | null;
};

export type CartBundleRowFieldsFragment = {
  __typename?: "rql_Me";
  _id?: string | null;
  uuid?: string | null;
  cart?: {
    __typename?: "rql_MyCart";
    bundles?: Array<{
      __typename?: "core_apimessages_CartItemBundle";
      checkoutBundlingId?: string | null;
      title?: string | null;
      freeShippingStatuses?: Array<{
        __typename?: "core_apimessages_CartItemBundle_FreeShippingStatus";
        shopId?: string | null;
        isThresholdMet?: boolean | null;
        percentProgress?: number | null;
        amountRemaining?: {
          __typename?: "core_apimessages_Money";
          display?: string | null;
        } | null;
        shop?: {
          __typename?: "Shop";
          _id?: string | null;
          name?: string | null;
          slug?: string | null;
        } | null;
      } | null> | null;
      cartItems?: Array<{
        __typename?: "core_apimessages_CartItem";
        _id?: string | null;
        uuid?: string | null;
        quantity?: number | null;
        protectionPlanEligible?: boolean | null;
        actions?: {
          __typename?: "core_apimessages_CartItem_Actions";
          canChangeQuantity?: boolean | null;
          canRemoveFromCart?: boolean | null;
          canMoveToWatchlist?: boolean | null;
        } | null;
        alerts?: Array<{
          __typename?: "core_apimessages_CartItem_Alert";
          type?: core_apimessages_CartItem_Alert_Type | null;
          text?: string | null;
          severity?: core_apimessages_CartItem_Alert_Severity | null;
        } | null> | null;
        listing?: {
          __typename?: "Listing";
          _id?: string | null;
          id?: string | null;
          otherBuyersWithListingInCartCounts?: number | null;
          title?: string | null;
          allowedBuyerQuantity?: number | null;
          slug?: string | null;
          listingType?: core_apimessages_Listing_ListingType | null;
          brandSlug?: string | null;
          categoryRootUuid?: string | null;
          make?: string | null;
          categoryUuids?: Array<string | null> | null;
          state?: string | null;
          bumpEligible?: boolean | null;
          shopId?: string | null;
          inventory?: number | null;
          soldAsIs?: boolean | null;
          acceptedPaymentMethods?: Array<string | null> | null;
          currency?: string | null;
          usOutlet?: boolean | null;
          images?: Array<{
            __typename?: "core_apimessages_Image";
            source?: string | null;
          } | null> | null;
          shop?: {
            __typename?: "Shop";
            _id?: string | null;
            slug?: string | null;
            name?: string | null;
            address?: {
              __typename?: "core_apimessages_Address";
              _id?: string | null;
              displayLocation?: string | null;
              countryCode?: string | null;
            } | null;
            returnPolicy?: {
              __typename?: "core_apimessages_ReturnPolicy";
              _id?: string | null;
              newReturnWindowDays?: number | null;
              usedReturnWindowDays?: number | null;
            } | null;
          } | null;
          preorderInfo?: {
            __typename?: "core_apimessages_PreorderInfo";
            onPreorder?: boolean | null;
            estimatedShipDate?: {
              __typename?: "google_protobuf_Timestamp";
              seconds?: number | null;
            } | null;
          } | null;
          shipping?: {
            __typename?: "core_apimessages_ShippingPrices";
            freeExpeditedShipping?: boolean | null;
            localPickupOnly?: boolean | null;
            localPickup?: boolean | null;
            shippingPrices?: Array<{
              __typename?: "core_apimessages_ShippingPrice";
              _id?: string | null;
              carrierCalculated?: boolean | null;
              destinationPostalCodeNeeded?: boolean | null;
              regional?: boolean | null;
              shippingMethod?: core_apimessages_ShippingMethod | null;
              rate?: {
                __typename?: "core_apimessages_Money";
                amount?: string | null;
                amountCents?: number | null;
                currency?: string | null;
                display?: string | null;
              } | null;
            } | null> | null;
          } | null;
          condition?: {
            __typename?: "core_apimessages_Condition";
            conditionUuid?: string | null;
            conditionSlug?: string | null;
          } | null;
          categories?: Array<{
            __typename?: "Category";
            _id?: string | null;
            slug?: string | null;
            rootSlug?: string | null;
          } | null> | null;
          csp?: {
            __typename?: "CSP";
            _id?: string | null;
            id?: string | null;
            slug?: string | null;
            brand?: {
              __typename?: "Brand";
              _id?: string | null;
              slug?: string | null;
            } | null;
          } | null;
          pricing?: {
            __typename?: "core_apimessages_ListingPricing";
            buyerPrice?: {
              __typename?: "core_apimessages_Money";
              amount?: string | null;
              currency?: string | null;
              amountCents?: number | null;
            } | null;
          } | null;
          publishedAt?: {
            __typename?: "google_protobuf_Timestamp";
            seconds?: number | null;
          } | null;
          sale?: {
            __typename?: "core_apimessages_Sale";
            _id?: string | null;
            id?: string | null;
            code?: string | null;
            buyerIneligibilityReason?: string | null;
          } | null;
          certifiedPreOwned?: {
            __typename?: "core_apimessages_ListingCertifiedPreOwned";
            title?: string | null;
            badgeIconUrl?: string | null;
          } | null;
        } | null;
        order?: {
          __typename?: "Order";
          _id?: string | null;
          orderType?: string | null;
          id?: string | null;
          legacyOrderId?: string | null;
        } | null;
        cartItemProtectionPlan?: {
          __typename?: "core_apimessages_ProtectionPlan";
          planId?: string | null;
          durationMonths?: string | null;
          amount?: {
            __typename?: "core_apimessages_Money";
            display?: string | null;
            amount?: string | null;
          } | null;
        } | null;
        listingTotal?: {
          __typename?: "core_apimessages_CartItem_ListingTotal";
          discounted?: boolean | null;
          discount?: {
            __typename?: "core_apimessages_CartItem_ListingTotal_Discount";
            type?: core_apimessages_CartItem_ListingTotal_Discount_Type | null;
            label?: string | null;
          } | null;
          unitPrice?: {
            __typename?: "core_apimessages_Money";
            display?: string | null;
            currency?: string | null;
          } | null;
          originalUnitPrice?: {
            __typename?: "core_apimessages_Money";
            display?: string | null;
          } | null;
        } | null;
        shippingTotal?: {
          __typename?: "core_apimessages_CartItem_ShippingTotal";
          discounted?: boolean | null;
          discount?: {
            __typename?: "core_apimessages_CartItem_ShippingTotal_Discount";
            type?: core_apimessages_CartItem_ShippingTotal_Discount_Type | null;
            label?: string | null;
          } | null;
          amountOwed?: {
            __typename?: "core_apimessages_Money";
            display?: string | null;
          } | null;
          originalAmountOwed?: {
            __typename?: "core_apimessages_Money";
            display?: string | null;
          } | null;
        } | null;
        paymentMethods?: Array<{
          __typename?: "core_apimessages_CartItem_PaymentMethod";
          type?: core_apimessages_CheckoutPaymentMethod_Type | null;
        } | null> | null;
      } | null> | null;
      availablePaymentMethods?: Array<{
        __typename?: "core_apimessages_CartItemBundle_PaymentMethod";
        type?: core_apimessages_CheckoutPaymentMethod_Type | null;
        name?: string | null;
        applePay?: {
          __typename?: "core_apimessages_CheckoutApplePayDetails";
          merchantId?: string | null;
          merchantName?: string | null;
        } | null;
        paypal?: {
          __typename?: "core_apimessages_CheckoutPaypalDetails";
          currency?: string | null;
        } | null;
        affirm?: {
          __typename?: "core_apimessages_CheckoutAffirmDetails";
          financingProgramSlug?: string | null;
        } | null;
      } | null> | null;
      paymentMethodIcons?: Array<{
        __typename?: "core_apimessages_PaymentCardIcon";
        slug?: string | null;
        description?: string | null;
        url?: string | null;
      } | null> | null;
      total?: {
        __typename?: "core_apimessages_CartItemBundle_Total";
        label?: string | null;
        amount?: {
          __typename?: "core_apimessages_Money";
          display?: string | null;
          currency?: string | null;
          amountCents?: number | null;
        } | null;
      } | null;
      lineItems?: Array<{
        __typename?: "core_apimessages_CartItemBundle_LineItem";
        type?: core_apimessages_CartItemBundle_LineItem_Type | null;
        label?: string | null;
        amount?: {
          __typename?: "core_apimessages_Money";
          display?: string | null;
          currency?: string | null;
          amountCents?: number | null;
        } | null;
      } | null> | null;
    } | null> | null;
  } | null;
};

export type CartBundleTitleFieldsFragment = {
  __typename?: "rql_Me";
  _id?: string | null;
  cart?: {
    __typename?: "rql_MyCart";
    bundles?: Array<{
      __typename?: "core_apimessages_CartItemBundle";
      title?: string | null;
    } | null> | null;
  } | null;
};

export type CartCombineAndSaveRowFieldsFragment = {
  __typename?: "rql_Me";
  _id?: string | null;
  cart?: {
    __typename?: "rql_MyCart";
    bundles?: Array<{
      __typename?: "core_apimessages_CartItemBundle";
      cartItems?: Array<{
        __typename?: "core_apimessages_CartItem";
        _id?: string | null;
        listingId?: string | null;
      } | null> | null;
      combineAndSaveEligibleListing?: {
        __typename?: "Listing";
        _id?: string | null;
        id?: string | null;
        categoryRootUuid?: string | null;
        shippingPrice?: {
          __typename?: "core_apimessages_Money";
          amountCents?: number | null;
        } | null;
        shop?: {
          __typename?: "Shop";
          _id?: string | null;
          id?: string | null;
          name?: string | null;
          slug?: string | null;
        } | null;
      } | null;
    } | null> | null;
  } | null;
};

export type CartGooglePayButtonFieldsFragment = {
  __typename?: "rql_Me";
  _id?: string | null;
  id?: string | null;
  cart?: {
    __typename?: "rql_MyCart";
    bundles?: Array<{
      __typename?: "core_apimessages_CartItemBundle";
      checkoutBundlingId?: string | null;
      availablePaymentMethods?: Array<{
        __typename?: "core_apimessages_CartItemBundle_PaymentMethod";
        type?: core_apimessages_CheckoutPaymentMethod_Type | null;
        googlePay?: {
          __typename?: "core_apimessages_CheckoutGooglePayDetails";
          merchantId?: string | null;
          merchantName?: string | null;
          gatewayMerchantId?: string | null;
          cardNetworks?: Array<core_apimessages_CheckoutGooglePayDetails_CardNetwork | null> | null;
        } | null;
      } | null> | null;
      total?: {
        __typename?: "core_apimessages_CartItemBundle_Total";
        amount?: {
          __typename?: "core_apimessages_Money";
          amount?: string | null;
          currency?: string | null;
        } | null;
      } | null;
    } | null> | null;
  } | null;
};

export type Core_Checkout_StartCartGooglePayMutationVariables = Exact<{
  checkoutBundlingId?: InputMaybe<Scalars["String"]["input"]>;
}>;

export type Core_Checkout_StartCartGooglePayMutation = {
  __typename?: "Mutation";
  startCheckout?: {
    __typename?: "core_apimessages_StartCheckoutResponse";
    checkout?: {
      __typename?: "Checkout";
      _id?: string | null;
      id?: string | null;
      status?: core_apimessages_Checkout_Status | null;
      paymentErrorMessage?: string | null;
      type?: core_apimessages_Checkout_Type | null;
      total?: {
        __typename?: "core_apimessages_Checkout_Total";
        label?: string | null;
        amount?: {
          __typename?: "core_apimessages_Money";
          amount?: string | null;
          amountCents?: number | null;
          currency?: string | null;
        } | null;
      } | null;
      guest?: {
        __typename?: "core_apimessages_CheckoutGuest";
        email?: string | null;
        firstName?: string | null;
        lastName?: string | null;
      } | null;
      shippingAddress?: {
        __typename?: "core_apimessages_Address";
        _id?: string | null;
        name?: string | null;
        streetAddress?: string | null;
        extendedAddress?: string | null;
        locality?: string | null;
        region?: string | null;
        postalCode?: string | null;
        countryCode?: string | null;
        phone?: string | null;
      } | null;
      lineItems?: Array<{
        __typename?: "core_apimessages_CheckoutLineItem";
        type?: core_apimessages_CheckoutLineItem_Type | null;
        label?: string | null;
        amount?: {
          __typename?: "core_apimessages_Money";
          amount?: string | null;
        } | null;
      } | null> | null;
    } | null;
  } | null;
};

export type CartHoistedExpressPayFieldsFragment = {
  __typename?: "rql_Me";
  _id?: string | null;
  cart?: {
    __typename?: "rql_MyCart";
    bundles?: Array<{
      __typename?: "core_apimessages_CartItemBundle";
      checkoutBundlingId?: string | null;
      total?: {
        __typename?: "core_apimessages_CartItemBundle_Total";
        amount?: {
          __typename?: "core_apimessages_Money";
          amount?: string | null;
          amountCents?: number | null;
          currency?: string | null;
        } | null;
      } | null;
      availablePaymentMethods?: Array<{
        __typename?: "core_apimessages_CartItemBundle_PaymentMethod";
        type?: core_apimessages_CheckoutPaymentMethod_Type | null;
        applePay?: {
          __typename?: "core_apimessages_CheckoutApplePayDetails";
          merchantId?: string | null;
          merchantName?: string | null;
        } | null;
        paypal?: {
          __typename?: "core_apimessages_CheckoutPaypalDetails";
          currency?: string | null;
        } | null;
      } | null> | null;
    } | null> | null;
  } | null;
};

export type CartItemBuyNowButtonFieldsFragment = {
  __typename?: "core_apimessages_CartItem";
  _id?: string | null;
  order?: {
    __typename?: "Order";
    _id?: string | null;
    legacyOrderId?: string | null;
  } | null;
  paymentMethods?: Array<{
    __typename?: "core_apimessages_CartItem_PaymentMethod";
    type?: core_apimessages_CheckoutPaymentMethod_Type | null;
  } | null> | null;
};

export type CartItemAvailableProtectionPlansFragment = {
  __typename?: "rql_Me";
  _id?: string | null;
  uuid?: string | null;
  cart?: {
    __typename?: "rql_MyCart";
    bundles?: Array<{
      __typename?: "core_apimessages_CartItemBundle";
      cartItems?: Array<{
        __typename?: "core_apimessages_CartItem";
        _id?: string | null;
        uuid?: string | null;
        protectionPlanOptions?: Array<{
          __typename?: "core_apimessages_ProtectionPlanOptions";
          availablePlans?: Array<{
            __typename?: "core_apimessages_ProtectionPlan";
            planId?: string | null;
            durationMonths?: string | null;
            amount?: {
              __typename?: "core_apimessages_Money";
              display?: string | null;
              amount?: string | null;
            } | null;
          } | null> | null;
        } | null> | null;
      } | null> | null;
    } | null> | null;
  } | null;
};

export type CartItemRowProtectionPlanFieldsFragment = {
  __typename?: "rql_Me";
  _id?: string | null;
  uuid?: string | null;
  cart?: {
    __typename?: "rql_MyCart";
    bundles?: Array<{
      __typename?: "core_apimessages_CartItemBundle";
      cartItems?: Array<{
        __typename?: "core_apimessages_CartItem";
        _id?: string | null;
        protectionPlanEligible?: boolean | null;
        cartItemProtectionPlan?: {
          __typename?: "core_apimessages_ProtectionPlan";
          planId?: string | null;
          durationMonths?: string | null;
          amount?: {
            __typename?: "core_apimessages_Money";
            display?: string | null;
            amount?: string | null;
          } | null;
        } | null;
        listing?: {
          __typename?: "Listing";
          _id?: string | null;
          slug?: string | null;
        } | null;
      } | null> | null;
    } | null> | null;
  } | null;
};

export type Core_CartItemUpdateProtectionPlanMutationVariables = Exact<{
  input?: InputMaybe<Input_core_apimessages_UpdateCartItemProtectionPlanRequest>;
}>;

export type Core_CartItemUpdateProtectionPlanMutation = {
  __typename?: "Mutation";
  crupdateCartItemProtectionPlan?: {
    __typename?: "core_apimessages_UpdateCartItemProtectionPlanResponse";
    cartItemUuid?: string | null;
    protectionPlan?: {
      __typename?: "core_apimessages_ProtectionPlan";
      planId?: string | null;
    } | null;
  } | null;
};

export type Core_CartItemDeleteProtectionPlanMutationVariables = Exact<{
  input?: InputMaybe<Input_core_apimessages_DeleteCartItemProtectionPlanRequest>;
}>;

export type Core_CartItemDeleteProtectionPlanMutation = {
  __typename?: "Mutation";
  deleteCartItemProtectionPlan?: {
    __typename?: "core_apimessages_DeleteCartItemProtectionPlanResponse";
    cartItemUuid?: string | null;
  } | null;
};

export type Core_AvailableCartItemProtectionPlansQueryVariables = Exact<{
  [key: string]: never;
}>;

export type Core_AvailableCartItemProtectionPlansQuery = {
  __typename?: "Query";
  me?: {
    __typename?: "rql_Me";
    _id?: string | null;
    uuid?: string | null;
    cart?: {
      __typename?: "rql_MyCart";
      bundles?: Array<{
        __typename?: "core_apimessages_CartItemBundle";
        cartItems?: Array<{
          __typename?: "core_apimessages_CartItem";
          _id?: string | null;
          uuid?: string | null;
          protectionPlanOptions?: Array<{
            __typename?: "core_apimessages_ProtectionPlanOptions";
            availablePlans?: Array<{
              __typename?: "core_apimessages_ProtectionPlan";
              planId?: string | null;
              durationMonths?: string | null;
              amount?: {
                __typename?: "core_apimessages_Money";
                display?: string | null;
                amount?: string | null;
              } | null;
            } | null> | null;
          } | null> | null;
        } | null> | null;
      } | null> | null;
    } | null;
  } | null;
};

export type CartItemQuantitySelectFieldsFragment = {
  __typename?: "rql_Me";
  _id?: string | null;
  uuid?: string | null;
  cart?: {
    __typename?: "rql_MyCart";
    bundles?: Array<{
      __typename?: "core_apimessages_CartItemBundle";
      cartItems?: Array<{
        __typename?: "core_apimessages_CartItem";
        _id?: string | null;
        uuid?: string | null;
        quantity?: number | null;
        listing?: {
          __typename?: "Listing";
          _id?: string | null;
          id?: string | null;
          allowedBuyerQuantity?: number | null;
          title?: string | null;
          brandSlug?: string | null;
          categoryRootUuid?: string | null;
          make?: string | null;
          categoryUuids?: Array<string | null> | null;
          state?: string | null;
          listingType?: core_apimessages_Listing_ListingType | null;
          bumpEligible?: boolean | null;
          shopId?: string | null;
          inventory?: number | null;
          soldAsIs?: boolean | null;
          acceptedPaymentMethods?: Array<string | null> | null;
          currency?: string | null;
          usOutlet?: boolean | null;
          condition?: {
            __typename?: "core_apimessages_Condition";
            conditionUuid?: string | null;
            conditionSlug?: string | null;
          } | null;
          categories?: Array<{
            __typename?: "Category";
            _id?: string | null;
            slug?: string | null;
            rootSlug?: string | null;
          } | null> | null;
          csp?: {
            __typename?: "CSP";
            _id?: string | null;
            id?: string | null;
            slug?: string | null;
            brand?: {
              __typename?: "Brand";
              _id?: string | null;
              slug?: string | null;
            } | null;
          } | null;
          pricing?: {
            __typename?: "core_apimessages_ListingPricing";
            buyerPrice?: {
              __typename?: "core_apimessages_Money";
              amount?: string | null;
              currency?: string | null;
              amountCents?: number | null;
            } | null;
          } | null;
          publishedAt?: {
            __typename?: "google_protobuf_Timestamp";
            seconds?: number | null;
          } | null;
          sale?: {
            __typename?: "core_apimessages_Sale";
            _id?: string | null;
            id?: string | null;
            code?: string | null;
            buyerIneligibilityReason?: string | null;
          } | null;
          shipping?: {
            __typename?: "core_apimessages_ShippingPrices";
            freeExpeditedShipping?: boolean | null;
            localPickupOnly?: boolean | null;
            localPickup?: boolean | null;
            shippingPrices?: Array<{
              __typename?: "core_apimessages_ShippingPrice";
              _id?: string | null;
              shippingMethod?: core_apimessages_ShippingMethod | null;
              carrierCalculated?: boolean | null;
              destinationPostalCodeNeeded?: boolean | null;
              rate?: {
                __typename?: "core_apimessages_Money";
                amount?: string | null;
                amountCents?: number | null;
                currency?: string | null;
                display?: string | null;
              } | null;
            } | null> | null;
          } | null;
          certifiedPreOwned?: {
            __typename?: "core_apimessages_ListingCertifiedPreOwned";
            title?: string | null;
            badgeIconUrl?: string | null;
          } | null;
          shop?: {
            __typename?: "Shop";
            _id?: string | null;
            address?: {
              __typename?: "core_apimessages_Address";
              _id?: string | null;
              countryCode?: string | null;
            } | null;
            returnPolicy?: {
              __typename?: "core_apimessages_ReturnPolicy";
              _id?: string | null;
              newReturnWindowDays?: number | null;
              usedReturnWindowDays?: number | null;
            } | null;
          } | null;
        } | null;
      } | null> | null;
    } | null> | null;
  } | null;
};

export type Core_Cart_UpdateItemQuantityMutationVariables = Exact<{
  listingId?: InputMaybe<Scalars["String"]["input"]>;
  quantity?: InputMaybe<Scalars["Int"]["input"]>;
}>;

export type Core_Cart_UpdateItemQuantityMutation = {
  __typename?: "Mutation";
  editCart?: {
    __typename?: "core_apimessages_CheckoutCartEditResponse";
    listingId?: string | null;
  } | null;
};

export type CartItemRowFieldsFragment = {
  __typename?: "rql_Me";
  _id?: string | null;
  uuid?: string | null;
  cart?: {
    __typename?: "rql_MyCart";
    bundles?: Array<{
      __typename?: "core_apimessages_CartItemBundle";
      cartItems?: Array<{
        __typename?: "core_apimessages_CartItem";
        _id?: string | null;
        uuid?: string | null;
        quantity?: number | null;
        protectionPlanEligible?: boolean | null;
        actions?: {
          __typename?: "core_apimessages_CartItem_Actions";
          canChangeQuantity?: boolean | null;
          canRemoveFromCart?: boolean | null;
          canMoveToWatchlist?: boolean | null;
        } | null;
        alerts?: Array<{
          __typename?: "core_apimessages_CartItem_Alert";
          type?: core_apimessages_CartItem_Alert_Type | null;
          text?: string | null;
          severity?: core_apimessages_CartItem_Alert_Severity | null;
        } | null> | null;
        listing?: {
          __typename?: "Listing";
          _id?: string | null;
          id?: string | null;
          otherBuyersWithListingInCartCounts?: number | null;
          title?: string | null;
          allowedBuyerQuantity?: number | null;
          slug?: string | null;
          listingType?: core_apimessages_Listing_ListingType | null;
          brandSlug?: string | null;
          categoryRootUuid?: string | null;
          make?: string | null;
          categoryUuids?: Array<string | null> | null;
          state?: string | null;
          bumpEligible?: boolean | null;
          shopId?: string | null;
          inventory?: number | null;
          soldAsIs?: boolean | null;
          acceptedPaymentMethods?: Array<string | null> | null;
          currency?: string | null;
          usOutlet?: boolean | null;
          images?: Array<{
            __typename?: "core_apimessages_Image";
            source?: string | null;
          } | null> | null;
          shop?: {
            __typename?: "Shop";
            _id?: string | null;
            slug?: string | null;
            name?: string | null;
            address?: {
              __typename?: "core_apimessages_Address";
              _id?: string | null;
              displayLocation?: string | null;
              countryCode?: string | null;
            } | null;
            returnPolicy?: {
              __typename?: "core_apimessages_ReturnPolicy";
              _id?: string | null;
              newReturnWindowDays?: number | null;
              usedReturnWindowDays?: number | null;
            } | null;
          } | null;
          preorderInfo?: {
            __typename?: "core_apimessages_PreorderInfo";
            onPreorder?: boolean | null;
            estimatedShipDate?: {
              __typename?: "google_protobuf_Timestamp";
              seconds?: number | null;
            } | null;
          } | null;
          shipping?: {
            __typename?: "core_apimessages_ShippingPrices";
            freeExpeditedShipping?: boolean | null;
            localPickupOnly?: boolean | null;
            localPickup?: boolean | null;
            shippingPrices?: Array<{
              __typename?: "core_apimessages_ShippingPrice";
              _id?: string | null;
              carrierCalculated?: boolean | null;
              destinationPostalCodeNeeded?: boolean | null;
              regional?: boolean | null;
              shippingMethod?: core_apimessages_ShippingMethod | null;
              rate?: {
                __typename?: "core_apimessages_Money";
                amount?: string | null;
                amountCents?: number | null;
                currency?: string | null;
                display?: string | null;
              } | null;
            } | null> | null;
          } | null;
          condition?: {
            __typename?: "core_apimessages_Condition";
            conditionUuid?: string | null;
            conditionSlug?: string | null;
          } | null;
          categories?: Array<{
            __typename?: "Category";
            _id?: string | null;
            slug?: string | null;
            rootSlug?: string | null;
          } | null> | null;
          csp?: {
            __typename?: "CSP";
            _id?: string | null;
            id?: string | null;
            slug?: string | null;
            brand?: {
              __typename?: "Brand";
              _id?: string | null;
              slug?: string | null;
            } | null;
          } | null;
          pricing?: {
            __typename?: "core_apimessages_ListingPricing";
            buyerPrice?: {
              __typename?: "core_apimessages_Money";
              amount?: string | null;
              currency?: string | null;
              amountCents?: number | null;
            } | null;
          } | null;
          publishedAt?: {
            __typename?: "google_protobuf_Timestamp";
            seconds?: number | null;
          } | null;
          sale?: {
            __typename?: "core_apimessages_Sale";
            _id?: string | null;
            id?: string | null;
            code?: string | null;
            buyerIneligibilityReason?: string | null;
          } | null;
          certifiedPreOwned?: {
            __typename?: "core_apimessages_ListingCertifiedPreOwned";
            title?: string | null;
            badgeIconUrl?: string | null;
          } | null;
        } | null;
        order?: {
          __typename?: "Order";
          _id?: string | null;
          orderType?: string | null;
          id?: string | null;
          legacyOrderId?: string | null;
        } | null;
        cartItemProtectionPlan?: {
          __typename?: "core_apimessages_ProtectionPlan";
          planId?: string | null;
          durationMonths?: string | null;
          amount?: {
            __typename?: "core_apimessages_Money";
            display?: string | null;
            amount?: string | null;
          } | null;
        } | null;
        listingTotal?: {
          __typename?: "core_apimessages_CartItem_ListingTotal";
          discounted?: boolean | null;
          discount?: {
            __typename?: "core_apimessages_CartItem_ListingTotal_Discount";
            type?: core_apimessages_CartItem_ListingTotal_Discount_Type | null;
            label?: string | null;
          } | null;
          unitPrice?: {
            __typename?: "core_apimessages_Money";
            display?: string | null;
            currency?: string | null;
          } | null;
          originalUnitPrice?: {
            __typename?: "core_apimessages_Money";
            display?: string | null;
          } | null;
        } | null;
        shippingTotal?: {
          __typename?: "core_apimessages_CartItem_ShippingTotal";
          discounted?: boolean | null;
          discount?: {
            __typename?: "core_apimessages_CartItem_ShippingTotal_Discount";
            type?: core_apimessages_CartItem_ShippingTotal_Discount_Type | null;
            label?: string | null;
          } | null;
          amountOwed?: {
            __typename?: "core_apimessages_Money";
            display?: string | null;
          } | null;
          originalAmountOwed?: {
            __typename?: "core_apimessages_Money";
            display?: string | null;
          } | null;
        } | null;
        paymentMethods?: Array<{
          __typename?: "core_apimessages_CartItem_PaymentMethod";
          type?: core_apimessages_CheckoutPaymentMethod_Type | null;
        } | null> | null;
      } | null> | null;
    } | null> | null;
  } | null;
};

export type CartItemRowPricingFieldsFragment = {
  __typename?: "core_apimessages_CartItem";
  _id?: string | null;
  listingTotal?: {
    __typename?: "core_apimessages_CartItem_ListingTotal";
    discounted?: boolean | null;
    discount?: {
      __typename?: "core_apimessages_CartItem_ListingTotal_Discount";
      type?: core_apimessages_CartItem_ListingTotal_Discount_Type | null;
      label?: string | null;
    } | null;
    unitPrice?: {
      __typename?: "core_apimessages_Money";
      display?: string | null;
      currency?: string | null;
    } | null;
    originalUnitPrice?: {
      __typename?: "core_apimessages_Money";
      display?: string | null;
    } | null;
  } | null;
  shippingTotal?: {
    __typename?: "core_apimessages_CartItem_ShippingTotal";
    discounted?: boolean | null;
    discount?: {
      __typename?: "core_apimessages_CartItem_ShippingTotal_Discount";
      type?: core_apimessages_CartItem_ShippingTotal_Discount_Type | null;
      label?: string | null;
    } | null;
    amountOwed?: {
      __typename?: "core_apimessages_Money";
      display?: string | null;
    } | null;
    originalAmountOwed?: {
      __typename?: "core_apimessages_Money";
      display?: string | null;
    } | null;
  } | null;
  listing?: {
    __typename?: "Listing";
    _id?: string | null;
    listingType?: core_apimessages_Listing_ListingType | null;
    shipping?: {
      __typename?: "core_apimessages_ShippingPrices";
      localPickupOnly?: boolean | null;
      shippingPrices?: Array<{
        __typename?: "core_apimessages_ShippingPrice";
        _id?: string | null;
        carrierCalculated?: boolean | null;
        destinationPostalCodeNeeded?: boolean | null;
        regional?: boolean | null;
        shippingMethod?: core_apimessages_ShippingMethod | null;
      } | null> | null;
    } | null;
  } | null;
  order?: {
    __typename?: "Order";
    _id?: string | null;
    id?: string | null;
    orderType?: string | null;
  } | null;
};

export type CartLayoutMeFieldsFragment = {
  __typename?: "rql_Me";
  _id?: string | null;
  uuid?: string | null;
  cart?: {
    __typename?: "rql_MyCart";
    cartItems?: Array<{
      __typename?: "core_apimessages_CartItem";
      _id?: string | null;
      uuid?: string | null;
    } | null> | null;
    bundles?: Array<{
      __typename?: "core_apimessages_CartItemBundle";
      checkoutBundlingId?: string | null;
      title?: string | null;
      total?: {
        __typename?: "core_apimessages_CartItemBundle_Total";
        label?: string | null;
        amount?: {
          __typename?: "core_apimessages_Money";
          display?: string | null;
          currency?: string | null;
          amount?: string | null;
          amountCents?: number | null;
        } | null;
      } | null;
      lineItems?: Array<{
        __typename?: "core_apimessages_CartItemBundle_LineItem";
        type?: core_apimessages_CartItemBundle_LineItem_Type | null;
        label?: string | null;
        amount?: {
          __typename?: "core_apimessages_Money";
          currency?: string | null;
          display?: string | null;
          amountCents?: number | null;
        } | null;
      } | null> | null;
      freeShippingStatuses?: Array<{
        __typename?: "core_apimessages_CartItemBundle_FreeShippingStatus";
        shopId?: string | null;
        isThresholdMet?: boolean | null;
        percentProgress?: number | null;
        amountRemaining?: {
          __typename?: "core_apimessages_Money";
          display?: string | null;
        } | null;
        shop?: {
          __typename?: "Shop";
          _id?: string | null;
          name?: string | null;
          slug?: string | null;
        } | null;
      } | null> | null;
      cartItems?: Array<{
        __typename?: "core_apimessages_CartItem";
        _id?: string | null;
        listingId?: string | null;
        uuid?: string | null;
        quantity?: number | null;
        protectionPlanEligible?: boolean | null;
        actions?: {
          __typename?: "core_apimessages_CartItem_Actions";
          canChangeQuantity?: boolean | null;
          canRemoveFromCart?: boolean | null;
          canMoveToWatchlist?: boolean | null;
        } | null;
        alerts?: Array<{
          __typename?: "core_apimessages_CartItem_Alert";
          type?: core_apimessages_CartItem_Alert_Type | null;
          text?: string | null;
          severity?: core_apimessages_CartItem_Alert_Severity | null;
        } | null> | null;
        listing?: {
          __typename?: "Listing";
          _id?: string | null;
          id?: string | null;
          otherBuyersWithListingInCartCounts?: number | null;
          title?: string | null;
          allowedBuyerQuantity?: number | null;
          slug?: string | null;
          listingType?: core_apimessages_Listing_ListingType | null;
          brandSlug?: string | null;
          categoryRootUuid?: string | null;
          make?: string | null;
          categoryUuids?: Array<string | null> | null;
          state?: string | null;
          bumpEligible?: boolean | null;
          shopId?: string | null;
          inventory?: number | null;
          soldAsIs?: boolean | null;
          acceptedPaymentMethods?: Array<string | null> | null;
          currency?: string | null;
          usOutlet?: boolean | null;
          images?: Array<{
            __typename?: "core_apimessages_Image";
            source?: string | null;
          } | null> | null;
          shop?: {
            __typename?: "Shop";
            _id?: string | null;
            slug?: string | null;
            name?: string | null;
            address?: {
              __typename?: "core_apimessages_Address";
              _id?: string | null;
              displayLocation?: string | null;
              countryCode?: string | null;
            } | null;
            returnPolicy?: {
              __typename?: "core_apimessages_ReturnPolicy";
              _id?: string | null;
              newReturnWindowDays?: number | null;
              usedReturnWindowDays?: number | null;
            } | null;
          } | null;
          preorderInfo?: {
            __typename?: "core_apimessages_PreorderInfo";
            onPreorder?: boolean | null;
            estimatedShipDate?: {
              __typename?: "google_protobuf_Timestamp";
              seconds?: number | null;
            } | null;
          } | null;
          shipping?: {
            __typename?: "core_apimessages_ShippingPrices";
            freeExpeditedShipping?: boolean | null;
            localPickupOnly?: boolean | null;
            localPickup?: boolean | null;
            shippingPrices?: Array<{
              __typename?: "core_apimessages_ShippingPrice";
              _id?: string | null;
              carrierCalculated?: boolean | null;
              destinationPostalCodeNeeded?: boolean | null;
              regional?: boolean | null;
              shippingMethod?: core_apimessages_ShippingMethod | null;
              rate?: {
                __typename?: "core_apimessages_Money";
                amount?: string | null;
                amountCents?: number | null;
                currency?: string | null;
                display?: string | null;
              } | null;
            } | null> | null;
          } | null;
          condition?: {
            __typename?: "core_apimessages_Condition";
            conditionUuid?: string | null;
            conditionSlug?: string | null;
          } | null;
          categories?: Array<{
            __typename?: "Category";
            _id?: string | null;
            slug?: string | null;
            rootSlug?: string | null;
          } | null> | null;
          csp?: {
            __typename?: "CSP";
            _id?: string | null;
            id?: string | null;
            slug?: string | null;
            brand?: {
              __typename?: "Brand";
              _id?: string | null;
              slug?: string | null;
            } | null;
          } | null;
          pricing?: {
            __typename?: "core_apimessages_ListingPricing";
            buyerPrice?: {
              __typename?: "core_apimessages_Money";
              amount?: string | null;
              currency?: string | null;
              amountCents?: number | null;
            } | null;
          } | null;
          publishedAt?: {
            __typename?: "google_protobuf_Timestamp";
            seconds?: number | null;
          } | null;
          sale?: {
            __typename?: "core_apimessages_Sale";
            _id?: string | null;
            id?: string | null;
            code?: string | null;
            buyerIneligibilityReason?: string | null;
          } | null;
          certifiedPreOwned?: {
            __typename?: "core_apimessages_ListingCertifiedPreOwned";
            title?: string | null;
            badgeIconUrl?: string | null;
          } | null;
        } | null;
        order?: {
          __typename?: "Order";
          _id?: string | null;
          orderType?: string | null;
          id?: string | null;
          legacyOrderId?: string | null;
        } | null;
        cartItemProtectionPlan?: {
          __typename?: "core_apimessages_ProtectionPlan";
          planId?: string | null;
          durationMonths?: string | null;
          amount?: {
            __typename?: "core_apimessages_Money";
            display?: string | null;
            amount?: string | null;
          } | null;
        } | null;
        listingTotal?: {
          __typename?: "core_apimessages_CartItem_ListingTotal";
          discounted?: boolean | null;
          discount?: {
            __typename?: "core_apimessages_CartItem_ListingTotal_Discount";
            type?: core_apimessages_CartItem_ListingTotal_Discount_Type | null;
            label?: string | null;
          } | null;
          unitPrice?: {
            __typename?: "core_apimessages_Money";
            display?: string | null;
            currency?: string | null;
          } | null;
          originalUnitPrice?: {
            __typename?: "core_apimessages_Money";
            display?: string | null;
          } | null;
        } | null;
        shippingTotal?: {
          __typename?: "core_apimessages_CartItem_ShippingTotal";
          discounted?: boolean | null;
          discount?: {
            __typename?: "core_apimessages_CartItem_ShippingTotal_Discount";
            type?: core_apimessages_CartItem_ShippingTotal_Discount_Type | null;
            label?: string | null;
          } | null;
          amountOwed?: {
            __typename?: "core_apimessages_Money";
            display?: string | null;
          } | null;
          originalAmountOwed?: {
            __typename?: "core_apimessages_Money";
            display?: string | null;
          } | null;
        } | null;
        paymentMethods?: Array<{
          __typename?: "core_apimessages_CartItem_PaymentMethod";
          type?: core_apimessages_CheckoutPaymentMethod_Type | null;
        } | null> | null;
      } | null> | null;
      combineAndSaveEligibleListing?: {
        __typename?: "Listing";
        _id?: string | null;
        id?: string | null;
        categoryRootUuid?: string | null;
        shippingPrice?: {
          __typename?: "core_apimessages_Money";
          amountCents?: number | null;
        } | null;
        shop?: {
          __typename?: "Shop";
          _id?: string | null;
          id?: string | null;
          name?: string | null;
          slug?: string | null;
        } | null;
      } | null;
      availablePaymentMethods?: Array<{
        __typename?: "core_apimessages_CartItemBundle_PaymentMethod";
        type?: core_apimessages_CheckoutPaymentMethod_Type | null;
        name?: string | null;
        applePay?: {
          __typename?: "core_apimessages_CheckoutApplePayDetails";
          merchantId?: string | null;
          merchantName?: string | null;
        } | null;
        paypal?: {
          __typename?: "core_apimessages_CheckoutPaypalDetails";
          currency?: string | null;
        } | null;
        affirm?: {
          __typename?: "core_apimessages_CheckoutAffirmDetails";
          financingProgramSlug?: string | null;
        } | null;
      } | null> | null;
      paymentMethodIcons?: Array<{
        __typename?: "core_apimessages_PaymentCardIcon";
        slug?: string | null;
        description?: string | null;
        url?: string | null;
      } | null> | null;
    } | null> | null;
  } | null;
};

export type Core_Cart_LayoutQueryVariables = Exact<{
  checkoutID?: InputMaybe<Scalars["String"]["input"]>;
  skip: Scalars["Boolean"]["input"];
}>;

export type Core_Cart_LayoutQuery = {
  __typename?: "Query";
  getAdyenRegion?: {
    __typename?: "core_apimessages_ShowAdyenRegionResponse";
    webRegion?: string | null;
  } | null;
  me?: {
    __typename?: "rql_Me";
    _id?: string | null;
    uuid?: string | null;
    cart?: {
      __typename?: "rql_MyCart";
      cartItems?: Array<{
        __typename?: "core_apimessages_CartItem";
        _id?: string | null;
        uuid?: string | null;
      } | null> | null;
      bundles?: Array<{
        __typename?: "core_apimessages_CartItemBundle";
        checkoutBundlingId?: string | null;
        title?: string | null;
        total?: {
          __typename?: "core_apimessages_CartItemBundle_Total";
          label?: string | null;
          amount?: {
            __typename?: "core_apimessages_Money";
            display?: string | null;
            currency?: string | null;
            amount?: string | null;
            amountCents?: number | null;
          } | null;
        } | null;
        lineItems?: Array<{
          __typename?: "core_apimessages_CartItemBundle_LineItem";
          type?: core_apimessages_CartItemBundle_LineItem_Type | null;
          label?: string | null;
          amount?: {
            __typename?: "core_apimessages_Money";
            currency?: string | null;
            display?: string | null;
            amountCents?: number | null;
          } | null;
        } | null> | null;
        freeShippingStatuses?: Array<{
          __typename?: "core_apimessages_CartItemBundle_FreeShippingStatus";
          shopId?: string | null;
          isThresholdMet?: boolean | null;
          percentProgress?: number | null;
          amountRemaining?: {
            __typename?: "core_apimessages_Money";
            display?: string | null;
          } | null;
          shop?: {
            __typename?: "Shop";
            _id?: string | null;
            name?: string | null;
            slug?: string | null;
          } | null;
        } | null> | null;
        cartItems?: Array<{
          __typename?: "core_apimessages_CartItem";
          _id?: string | null;
          listingId?: string | null;
          uuid?: string | null;
          quantity?: number | null;
          protectionPlanEligible?: boolean | null;
          actions?: {
            __typename?: "core_apimessages_CartItem_Actions";
            canChangeQuantity?: boolean | null;
            canRemoveFromCart?: boolean | null;
            canMoveToWatchlist?: boolean | null;
          } | null;
          alerts?: Array<{
            __typename?: "core_apimessages_CartItem_Alert";
            type?: core_apimessages_CartItem_Alert_Type | null;
            text?: string | null;
            severity?: core_apimessages_CartItem_Alert_Severity | null;
          } | null> | null;
          listing?: {
            __typename?: "Listing";
            _id?: string | null;
            id?: string | null;
            otherBuyersWithListingInCartCounts?: number | null;
            title?: string | null;
            allowedBuyerQuantity?: number | null;
            slug?: string | null;
            listingType?: core_apimessages_Listing_ListingType | null;
            brandSlug?: string | null;
            categoryRootUuid?: string | null;
            make?: string | null;
            categoryUuids?: Array<string | null> | null;
            state?: string | null;
            bumpEligible?: boolean | null;
            shopId?: string | null;
            inventory?: number | null;
            soldAsIs?: boolean | null;
            acceptedPaymentMethods?: Array<string | null> | null;
            currency?: string | null;
            usOutlet?: boolean | null;
            images?: Array<{
              __typename?: "core_apimessages_Image";
              source?: string | null;
            } | null> | null;
            shop?: {
              __typename?: "Shop";
              _id?: string | null;
              slug?: string | null;
              name?: string | null;
              address?: {
                __typename?: "core_apimessages_Address";
                _id?: string | null;
                displayLocation?: string | null;
                countryCode?: string | null;
              } | null;
              returnPolicy?: {
                __typename?: "core_apimessages_ReturnPolicy";
                _id?: string | null;
                newReturnWindowDays?: number | null;
                usedReturnWindowDays?: number | null;
              } | null;
            } | null;
            preorderInfo?: {
              __typename?: "core_apimessages_PreorderInfo";
              onPreorder?: boolean | null;
              estimatedShipDate?: {
                __typename?: "google_protobuf_Timestamp";
                seconds?: number | null;
              } | null;
            } | null;
            shipping?: {
              __typename?: "core_apimessages_ShippingPrices";
              freeExpeditedShipping?: boolean | null;
              localPickupOnly?: boolean | null;
              localPickup?: boolean | null;
              shippingPrices?: Array<{
                __typename?: "core_apimessages_ShippingPrice";
                _id?: string | null;
                carrierCalculated?: boolean | null;
                destinationPostalCodeNeeded?: boolean | null;
                regional?: boolean | null;
                shippingMethod?: core_apimessages_ShippingMethod | null;
                rate?: {
                  __typename?: "core_apimessages_Money";
                  amount?: string | null;
                  amountCents?: number | null;
                  currency?: string | null;
                  display?: string | null;
                } | null;
              } | null> | null;
            } | null;
            condition?: {
              __typename?: "core_apimessages_Condition";
              conditionUuid?: string | null;
              conditionSlug?: string | null;
            } | null;
            categories?: Array<{
              __typename?: "Category";
              _id?: string | null;
              slug?: string | null;
              rootSlug?: string | null;
            } | null> | null;
            csp?: {
              __typename?: "CSP";
              _id?: string | null;
              id?: string | null;
              slug?: string | null;
              brand?: {
                __typename?: "Brand";
                _id?: string | null;
                slug?: string | null;
              } | null;
            } | null;
            pricing?: {
              __typename?: "core_apimessages_ListingPricing";
              buyerPrice?: {
                __typename?: "core_apimessages_Money";
                amount?: string | null;
                currency?: string | null;
                amountCents?: number | null;
              } | null;
            } | null;
            publishedAt?: {
              __typename?: "google_protobuf_Timestamp";
              seconds?: number | null;
            } | null;
            sale?: {
              __typename?: "core_apimessages_Sale";
              _id?: string | null;
              id?: string | null;
              code?: string | null;
              buyerIneligibilityReason?: string | null;
            } | null;
            certifiedPreOwned?: {
              __typename?: "core_apimessages_ListingCertifiedPreOwned";
              title?: string | null;
              badgeIconUrl?: string | null;
            } | null;
          } | null;
          order?: {
            __typename?: "Order";
            _id?: string | null;
            orderType?: string | null;
            id?: string | null;
            legacyOrderId?: string | null;
          } | null;
          cartItemProtectionPlan?: {
            __typename?: "core_apimessages_ProtectionPlan";
            planId?: string | null;
            durationMonths?: string | null;
            amount?: {
              __typename?: "core_apimessages_Money";
              display?: string | null;
              amount?: string | null;
            } | null;
          } | null;
          listingTotal?: {
            __typename?: "core_apimessages_CartItem_ListingTotal";
            discounted?: boolean | null;
            discount?: {
              __typename?: "core_apimessages_CartItem_ListingTotal_Discount";
              type?: core_apimessages_CartItem_ListingTotal_Discount_Type | null;
              label?: string | null;
            } | null;
            unitPrice?: {
              __typename?: "core_apimessages_Money";
              display?: string | null;
              currency?: string | null;
            } | null;
            originalUnitPrice?: {
              __typename?: "core_apimessages_Money";
              display?: string | null;
            } | null;
          } | null;
          shippingTotal?: {
            __typename?: "core_apimessages_CartItem_ShippingTotal";
            discounted?: boolean | null;
            discount?: {
              __typename?: "core_apimessages_CartItem_ShippingTotal_Discount";
              type?: core_apimessages_CartItem_ShippingTotal_Discount_Type | null;
              label?: string | null;
            } | null;
            amountOwed?: {
              __typename?: "core_apimessages_Money";
              display?: string | null;
            } | null;
            originalAmountOwed?: {
              __typename?: "core_apimessages_Money";
              display?: string | null;
            } | null;
          } | null;
          paymentMethods?: Array<{
            __typename?: "core_apimessages_CartItem_PaymentMethod";
            type?: core_apimessages_CheckoutPaymentMethod_Type | null;
          } | null> | null;
        } | null> | null;
        combineAndSaveEligibleListing?: {
          __typename?: "Listing";
          _id?: string | null;
          id?: string | null;
          categoryRootUuid?: string | null;
          shippingPrice?: {
            __typename?: "core_apimessages_Money";
            amountCents?: number | null;
          } | null;
          shop?: {
            __typename?: "Shop";
            _id?: string | null;
            id?: string | null;
            name?: string | null;
            slug?: string | null;
          } | null;
        } | null;
        availablePaymentMethods?: Array<{
          __typename?: "core_apimessages_CartItemBundle_PaymentMethod";
          type?: core_apimessages_CheckoutPaymentMethod_Type | null;
          name?: string | null;
          applePay?: {
            __typename?: "core_apimessages_CheckoutApplePayDetails";
            merchantId?: string | null;
            merchantName?: string | null;
          } | null;
          paypal?: {
            __typename?: "core_apimessages_CheckoutPaypalDetails";
            currency?: string | null;
          } | null;
          affirm?: {
            __typename?: "core_apimessages_CheckoutAffirmDetails";
            financingProgramSlug?: string | null;
          } | null;
        } | null> | null;
        paymentMethodIcons?: Array<{
          __typename?: "core_apimessages_PaymentCardIcon";
          slug?: string | null;
          description?: string | null;
          url?: string | null;
        } | null> | null;
      } | null> | null;
    } | null;
  } | null;
  checkout?: {
    __typename?: "Checkout";
    _id?: string | null;
    paymentErrorMessage?: string | null;
  } | null;
};

export type CartMoveItemToWatchListFieldsFragment = {
  __typename?: "rql_Me";
  _id?: string | null;
  uuid?: string | null;
  cart?: {
    __typename?: "rql_MyCart";
    bundles?: Array<{
      __typename?: "core_apimessages_CartItemBundle";
      cartItems?: Array<{
        __typename?: "core_apimessages_CartItem";
        _id?: string | null;
        uuid?: string | null;
        listing?: {
          __typename?: "Listing";
          _id?: string | null;
          id?: string | null;
          title?: string | null;
          brandSlug?: string | null;
          categoryRootUuid?: string | null;
          make?: string | null;
          categoryUuids?: Array<string | null> | null;
          state?: string | null;
          listingType?: core_apimessages_Listing_ListingType | null;
          bumpEligible?: boolean | null;
          shopId?: string | null;
          inventory?: number | null;
          soldAsIs?: boolean | null;
          acceptedPaymentMethods?: Array<string | null> | null;
          currency?: string | null;
          usOutlet?: boolean | null;
          condition?: {
            __typename?: "core_apimessages_Condition";
            conditionUuid?: string | null;
            conditionSlug?: string | null;
          } | null;
          categories?: Array<{
            __typename?: "Category";
            _id?: string | null;
            slug?: string | null;
            rootSlug?: string | null;
          } | null> | null;
          csp?: {
            __typename?: "CSP";
            _id?: string | null;
            id?: string | null;
            slug?: string | null;
            brand?: {
              __typename?: "Brand";
              _id?: string | null;
              slug?: string | null;
            } | null;
          } | null;
          pricing?: {
            __typename?: "core_apimessages_ListingPricing";
            buyerPrice?: {
              __typename?: "core_apimessages_Money";
              amount?: string | null;
              currency?: string | null;
              amountCents?: number | null;
            } | null;
          } | null;
          publishedAt?: {
            __typename?: "google_protobuf_Timestamp";
            seconds?: number | null;
          } | null;
          sale?: {
            __typename?: "core_apimessages_Sale";
            _id?: string | null;
            id?: string | null;
            code?: string | null;
            buyerIneligibilityReason?: string | null;
          } | null;
          shipping?: {
            __typename?: "core_apimessages_ShippingPrices";
            freeExpeditedShipping?: boolean | null;
            localPickupOnly?: boolean | null;
            localPickup?: boolean | null;
            shippingPrices?: Array<{
              __typename?: "core_apimessages_ShippingPrice";
              _id?: string | null;
              shippingMethod?: core_apimessages_ShippingMethod | null;
              carrierCalculated?: boolean | null;
              destinationPostalCodeNeeded?: boolean | null;
              rate?: {
                __typename?: "core_apimessages_Money";
                amount?: string | null;
                amountCents?: number | null;
                currency?: string | null;
                display?: string | null;
              } | null;
            } | null> | null;
          } | null;
          certifiedPreOwned?: {
            __typename?: "core_apimessages_ListingCertifiedPreOwned";
            title?: string | null;
            badgeIconUrl?: string | null;
          } | null;
          shop?: {
            __typename?: "Shop";
            _id?: string | null;
            address?: {
              __typename?: "core_apimessages_Address";
              _id?: string | null;
              countryCode?: string | null;
            } | null;
            returnPolicy?: {
              __typename?: "core_apimessages_ReturnPolicy";
              _id?: string | null;
              newReturnWindowDays?: number | null;
              usedReturnWindowDays?: number | null;
            } | null;
          } | null;
        } | null;
      } | null> | null;
    } | null> | null;
  } | null;
};

export type Core_Cart_MoveItemToWatchListMutationVariables = Exact<{
  cartItemUuid?: InputMaybe<Scalars["String"]["input"]>;
}>;

export type Core_Cart_MoveItemToWatchListMutation = {
  __typename?: "Mutation";
  moveCartItemToWatchList?: {
    __typename?: "core_apimessages_CartMoveToWatchListResponse";
    listingId?: string | null;
  } | null;
};

export type CartRemoveItemFieldsFragment = {
  __typename?: "rql_Me";
  _id?: string | null;
  uuid?: string | null;
  cart?: {
    __typename?: "rql_MyCart";
    bundles?: Array<{
      __typename?: "core_apimessages_CartItemBundle";
      cartItems?: Array<{
        __typename?: "core_apimessages_CartItem";
        _id?: string | null;
        uuid?: string | null;
        listing?: {
          __typename?: "Listing";
          _id?: string | null;
          id?: string | null;
          title?: string | null;
          brandSlug?: string | null;
          categoryRootUuid?: string | null;
          make?: string | null;
          categoryUuids?: Array<string | null> | null;
          state?: string | null;
          listingType?: core_apimessages_Listing_ListingType | null;
          bumpEligible?: boolean | null;
          shopId?: string | null;
          inventory?: number | null;
          soldAsIs?: boolean | null;
          acceptedPaymentMethods?: Array<string | null> | null;
          currency?: string | null;
          usOutlet?: boolean | null;
          condition?: {
            __typename?: "core_apimessages_Condition";
            conditionUuid?: string | null;
            conditionSlug?: string | null;
          } | null;
          categories?: Array<{
            __typename?: "Category";
            _id?: string | null;
            slug?: string | null;
            rootSlug?: string | null;
          } | null> | null;
          csp?: {
            __typename?: "CSP";
            _id?: string | null;
            id?: string | null;
            slug?: string | null;
            brand?: {
              __typename?: "Brand";
              _id?: string | null;
              slug?: string | null;
            } | null;
          } | null;
          pricing?: {
            __typename?: "core_apimessages_ListingPricing";
            buyerPrice?: {
              __typename?: "core_apimessages_Money";
              amount?: string | null;
              currency?: string | null;
              amountCents?: number | null;
            } | null;
          } | null;
          publishedAt?: {
            __typename?: "google_protobuf_Timestamp";
            seconds?: number | null;
          } | null;
          sale?: {
            __typename?: "core_apimessages_Sale";
            _id?: string | null;
            id?: string | null;
            code?: string | null;
            buyerIneligibilityReason?: string | null;
          } | null;
          shipping?: {
            __typename?: "core_apimessages_ShippingPrices";
            freeExpeditedShipping?: boolean | null;
            localPickupOnly?: boolean | null;
            localPickup?: boolean | null;
            shippingPrices?: Array<{
              __typename?: "core_apimessages_ShippingPrice";
              _id?: string | null;
              shippingMethod?: core_apimessages_ShippingMethod | null;
              carrierCalculated?: boolean | null;
              destinationPostalCodeNeeded?: boolean | null;
              rate?: {
                __typename?: "core_apimessages_Money";
                amount?: string | null;
                amountCents?: number | null;
                currency?: string | null;
                display?: string | null;
              } | null;
            } | null> | null;
          } | null;
          certifiedPreOwned?: {
            __typename?: "core_apimessages_ListingCertifiedPreOwned";
            title?: string | null;
            badgeIconUrl?: string | null;
          } | null;
          shop?: {
            __typename?: "Shop";
            _id?: string | null;
            address?: {
              __typename?: "core_apimessages_Address";
              _id?: string | null;
              countryCode?: string | null;
            } | null;
            returnPolicy?: {
              __typename?: "core_apimessages_ReturnPolicy";
              _id?: string | null;
              newReturnWindowDays?: number | null;
              usedReturnWindowDays?: number | null;
            } | null;
          } | null;
        } | null;
      } | null> | null;
    } | null> | null;
  } | null;
};

export type Core_Cart_RemoveItemMutationVariables = Exact<{
  listingId?: InputMaybe<Scalars["String"]["input"]>;
}>;

export type Core_Cart_RemoveItemMutation = {
  __typename?: "Mutation";
  editCart?: {
    __typename?: "core_apimessages_CheckoutCartEditResponse";
    listingId?: string | null;
  } | null;
};

export type Core_Cart_StartCheckoutMutationVariables = Exact<{
  checkoutBundlingId?: InputMaybe<Scalars["String"]["input"]>;
  paymentMethodType?: InputMaybe<core_apimessages_CheckoutPaymentMethod_Type>;
}>;

export type Core_Cart_StartCheckoutMutation = {
  __typename?: "Mutation";
  startCheckout?: {
    __typename?: "core_apimessages_StartCheckoutResponse";
    id?: string | null;
  } | null;
};

export type Cart_SignalsQueryVariables = Exact<{
  id?: InputMaybe<Scalars["String"]["input"]>;
  skipLoadingSignals: Scalars["Boolean"]["input"];
}>;

export type Cart_SignalsQuery = {
  __typename?: "Query";
  listing?: {
    __typename?: "Listing";
    _id?: string | null;
    id?: string | null;
    signals?: Array<{
      __typename?: "reverb_signals_Signal";
      name?: reverb_signals_Signal_Name | null;
      title?: string | null;
      icon?: string | null;
    } | null> | null;
  } | null;
};

export type Core_Form_Select_CategoriesQueryVariables = Exact<{
  entityId?: InputMaybe<Scalars["String"]["input"]>;
  entityType?: InputMaybe<core_apimessages_FormSelectCategoriesRequest_FormSelectEntityType>;
}>;

export type Core_Form_Select_CategoriesQuery = {
  __typename?: "Query";
  formSelectCategories?: {
    __typename?: "core_apimessages_FormSelectCategoriesResponse";
    categories?: Array<{
      __typename?: "core_apimessages_FormSelectCategory";
      uuid?: string | null;
      name?: string | null;
      subcategories?: Array<{
        __typename?: "core_apimessages_FormSelectCategory";
        uuid?: string | null;
        name?: string | null;
        subcategories?: Array<{
          __typename?: "core_apimessages_FormSelectCategory";
          uuid?: string | null;
          name?: string | null;
          subcategories?: Array<{
            __typename?: "core_apimessages_FormSelectCategory";
            uuid?: string | null;
            name?: string | null;
          } | null> | null;
        } | null> | null;
      } | null> | null;
    } | null> | null;
  } | null;
};

export type TopProductsCategoryFragment = {
  __typename?: "Category";
  _id?: string | null;
  id?: string | null;
  slug?: string | null;
  children?: Array<{
    __typename?: "core_apimessages_Category";
    _id?: string | null;
    name?: string | null;
    slug?: string | null;
  } | null> | null;
};

export type TopProductsCSPFragment = {
  __typename?: "CSP";
  _id?: string | null;
  summary?: string | null;
  id?: string | null;
  title?: string | null;
  slug?: string | null;
  averageReviewRating?: number | null;
  reviewsCount?: number | null;
  finishes?: Array<string | null> | null;
  categories?: Array<{
    __typename?: "Category";
    _id?: string | null;
    name?: string | null;
    slug?: string | null;
    rootSlug?: string | null;
    parentCategories?: Array<{
      __typename?: "Category";
      _id?: string | null;
      name?: string | null;
      slug?: string | null;
    } | null> | null;
  } | null> | null;
  webLink?: {
    __typename?: "core_apimessages_Link";
    path?: string | null;
    href?: string | null;
  } | null;
  image?: {
    __typename?: "core_apimessages_Image";
    _id?: string | null;
    source?: string | null;
  } | null;
  brand?: {
    __typename?: "Brand";
    _id?: string | null;
    slug?: string | null;
    name?: string | null;
  } | null;
  inventory?: {
    __typename?: "core_apimessages_CSPInventory";
    usedTotal?: number | null;
    newTotal?: number | null;
    newLowPrice?: {
      __typename?: "core_apimessages_Money";
      display?: string | null;
      amount?: string | null;
      amountCents?: number | null;
      currency?: string | null;
    } | null;
    usedLowPrice?: {
      __typename?: "core_apimessages_Money";
      display?: string | null;
      amount?: string | null;
      amountCents?: number | null;
      currency?: string | null;
    } | null;
  } | null;
};

export type Core_Category_TopProductsQueryVariables = Exact<{
  currentCategorySlugs?: InputMaybe<
    | Array<InputMaybe<Scalars["String"]["input"]>>
    | InputMaybe<Scalars["String"]["input"]>
  >;
  categoryUUIDs?: InputMaybe<
    | Array<InputMaybe<Scalars["String"]["input"]>>
    | InputMaybe<Scalars["String"]["input"]>
  >;
  bestSellerThreshold?: InputMaybe<Scalars["Int"]["input"]>;
}>;

export type Core_Category_TopProductsQuery = {
  __typename?: "Query";
  categories?: Array<{
    __typename?: "Category";
    _id?: string | null;
    id?: string | null;
    slug?: string | null;
    children?: Array<{
      __typename?: "core_apimessages_Category";
      _id?: string | null;
      name?: string | null;
      slug?: string | null;
    } | null> | null;
  } | null> | null;
  cspSearch?: {
    __typename?: "reverb_search_SearchResponse";
    csps?: Array<{
      __typename?: "CSP";
      _id?: string | null;
      summary?: string | null;
      id?: string | null;
      title?: string | null;
      slug?: string | null;
      averageReviewRating?: number | null;
      reviewsCount?: number | null;
      finishes?: Array<string | null> | null;
      categories?: Array<{
        __typename?: "Category";
        _id?: string | null;
        name?: string | null;
        slug?: string | null;
        rootSlug?: string | null;
        parentCategories?: Array<{
          __typename?: "Category";
          _id?: string | null;
          name?: string | null;
          slug?: string | null;
        } | null> | null;
      } | null> | null;
      webLink?: {
        __typename?: "core_apimessages_Link";
        path?: string | null;
        href?: string | null;
      } | null;
      image?: {
        __typename?: "core_apimessages_Image";
        _id?: string | null;
        source?: string | null;
      } | null;
      brand?: {
        __typename?: "Brand";
        _id?: string | null;
        slug?: string | null;
        name?: string | null;
      } | null;
      inventory?: {
        __typename?: "core_apimessages_CSPInventory";
        usedTotal?: number | null;
        newTotal?: number | null;
        newLowPrice?: {
          __typename?: "core_apimessages_Money";
          display?: string | null;
          amount?: string | null;
          amountCents?: number | null;
          currency?: string | null;
        } | null;
        usedLowPrice?: {
          __typename?: "core_apimessages_Money";
          display?: string | null;
          amount?: string | null;
          amountCents?: number | null;
          currency?: string | null;
        } | null;
      } | null;
    } | null> | null;
  } | null;
};

export type Core_Checkout_BuyerCouponListQueryVariables = Exact<{
  id?: InputMaybe<Scalars["String"]["input"]>;
  loggedOut: Scalars["Boolean"]["input"];
}>;

export type Core_Checkout_BuyerCouponListQuery = {
  __typename?: "Query";
  me?: {
    __typename?: "rql_Me";
    _id?: string | null;
    uuid?: string | null;
    buyerCoupons?: {
      __typename?: "core_apimessages_MyBuyerCouponsResponse";
      shopCampaignCoupons?: Array<{
        __typename?: "core_apimessages_ShopCampaignCoupon";
        _id?: string | null;
        id?: string | null;
        code?: string | null;
        couponHeader?: string | null;
        descriptionSummary?: string | null;
        restrictionsSummary?: string | null;
        expiresAt?: any | null;
        discountValue?: string | null;
        status?: core_apimessages_ShopCampaignCoupon_Status | null;
        excludedBrands?: Array<{
          __typename?: "Brand";
          _id?: string | null;
          name?: string | null;
        } | null> | null;
        excludedCategories?: Array<{
          __typename?: "Category";
          _id?: string | null;
          name?: string | null;
        } | null> | null;
      } | null> | null;
    } | null;
  } | null;
};

export type CheckoutAffirmBillingFormFieldsFragment = {
  __typename?: "Checkout";
  _id?: string | null;
  id?: string | null;
  billingAddress?: {
    __typename?: "core_apimessages_Address";
    _id?: string | null;
    isComplete?: boolean | null;
    name?: string | null;
    streetAddress?: string | null;
    extendedAddress?: string | null;
    locality?: string | null;
    region?: string | null;
    postalCode?: string | null;
    countryCode?: string | null;
    phone?: string | null;
    regionName?: string | null;
  } | null;
};

export type Core_Checkout_AuthorizationLayoutQueryVariables = Exact<{
  id?: InputMaybe<Scalars["String"]["input"]>;
  reverbApp?: InputMaybe<Scalars["String"]["input"]>;
}>;

export type Core_Checkout_AuthorizationLayoutQuery = {
  __typename?: "Query";
  checkout?: {
    __typename?: "Checkout";
    _id?: string | null;
    id?: string | null;
    checkoutBundlingId?: string | null;
    adyenPaymentAction?: string | null;
    source?: core_apimessages_Checkout_Source | null;
    action?: {
      __typename?: "CheckoutAction";
      _id?: string | null;
      type?: core_apimessages_CheckoutAction_Type | null;
      redirectUrl?: string | null;
    } | null;
    total?: {
      __typename?: "core_apimessages_Checkout_Total";
      amount?: {
        __typename?: "core_apimessages_Money";
        amountCents?: number | null;
        currency?: string | null;
      } | null;
    } | null;
    paymentMethod?: {
      __typename?: "core_apimessages_CheckoutPaymentMethod";
      type?: core_apimessages_CheckoutPaymentMethod_Type | null;
    } | null;
  } | null;
};

export type CheckoutContinueButtonFieldsFragment = {
  __typename?: "Checkout";
  _id?: string | null;
  id?: string | null;
  type?: core_apimessages_Checkout_Type | null;
  status?: core_apimessages_Checkout_Status | null;
  paymentErrorMessage?: string | null;
  paymentMethod?: {
    __typename?: "core_apimessages_CheckoutPaymentMethod";
    type?: core_apimessages_CheckoutPaymentMethod_Type | null;
    applePay?: {
      __typename?: "core_apimessages_CheckoutApplePayDetails";
      merchantName?: string | null;
      merchantId?: string | null;
    } | null;
    googlePay?: {
      __typename?: "core_apimessages_CheckoutGooglePayDetails";
      merchantId?: string | null;
      merchantName?: string | null;
      gatewayMerchantId?: string | null;
      cardNetworks?: Array<core_apimessages_CheckoutGooglePayDetails_CardNetwork | null> | null;
    } | null;
    paypal?: {
      __typename?: "core_apimessages_CheckoutPaypalDetails";
      currency?: string | null;
    } | null;
  } | null;
  guest?: {
    __typename?: "core_apimessages_CheckoutGuest";
    firstName?: string | null;
    lastName?: string | null;
    email?: string | null;
  } | null;
  shippingAddress?: {
    __typename?: "core_apimessages_Address";
    _id?: string | null;
    name?: string | null;
    streetAddress?: string | null;
    extendedAddress?: string | null;
    locality?: string | null;
    region?: string | null;
    postalCode?: string | null;
    countryCode?: string | null;
    phone?: string | null;
  } | null;
  total?: {
    __typename?: "core_apimessages_Checkout_Total";
    label?: string | null;
    amount?: {
      __typename?: "core_apimessages_Money";
      amountCents?: number | null;
      currency?: string | null;
      amount?: string | null;
    } | null;
  } | null;
  lineItems?: Array<{
    __typename?: "core_apimessages_CheckoutLineItem";
    type?: core_apimessages_CheckoutLineItem_Type | null;
    label?: string | null;
    amount?: {
      __typename?: "core_apimessages_Money";
      amount?: string | null;
      amountCents?: number | null;
      currency?: string | null;
    } | null;
  } | null> | null;
};

export type CheckoutExistingShippingAddressFormFieldsFragment = {
  __typename?: "Checkout";
  _id?: string | null;
  id?: string | null;
  source?: core_apimessages_Checkout_Source | null;
  orders?: Array<{
    __typename?: "core_apimessages_CheckoutOrder";
    id?: string | null;
    shippingMethod?: {
      __typename?: "core_apimessages_CheckoutShippingMethod";
      type?: core_apimessages_ShippingMethod | null;
    } | null;
  } | null> | null;
  shippingAddress?: {
    __typename?: "core_apimessages_Address";
    _id?: string | null;
    id?: string | null;
  } | null;
  paymentMethod?: {
    __typename?: "core_apimessages_CheckoutPaymentMethod";
    type?: core_apimessages_CheckoutPaymentMethod_Type | null;
  } | null;
};

export type Core_Checkout_SelectShippingAddressMutationVariables = Exact<{
  input?: InputMaybe<Input_core_apimessages_CheckoutSelectShippingAddressRequest>;
}>;

export type Core_Checkout_SelectShippingAddressMutation = {
  __typename?: "Mutation";
  selectCheckoutShippingAddress?: {
    __typename?: "core_apimessages_CheckoutSelectShippingAddressResponse";
    checkout?: { __typename?: "Checkout"; _id?: string | null } | null;
  } | null;
};

export type CheckoutGooglePayContinueButtonFieldsFragment = {
  __typename?: "Checkout";
  _id?: string | null;
  id?: string | null;
  status?: core_apimessages_Checkout_Status | null;
  paymentErrorMessage?: string | null;
  type?: core_apimessages_Checkout_Type | null;
  paymentMethod?: {
    __typename?: "core_apimessages_CheckoutPaymentMethod";
    googlePay?: {
      __typename?: "core_apimessages_CheckoutGooglePayDetails";
      merchantId?: string | null;
      merchantName?: string | null;
      gatewayMerchantId?: string | null;
      cardNetworks?: Array<core_apimessages_CheckoutGooglePayDetails_CardNetwork | null> | null;
    } | null;
  } | null;
  total?: {
    __typename?: "core_apimessages_Checkout_Total";
    label?: string | null;
    amount?: {
      __typename?: "core_apimessages_Money";
      amount?: string | null;
      currency?: string | null;
      amountCents?: number | null;
    } | null;
  } | null;
  guest?: {
    __typename?: "core_apimessages_CheckoutGuest";
    email?: string | null;
    firstName?: string | null;
    lastName?: string | null;
  } | null;
  shippingAddress?: {
    __typename?: "core_apimessages_Address";
    _id?: string | null;
    name?: string | null;
    streetAddress?: string | null;
    extendedAddress?: string | null;
    locality?: string | null;
    region?: string | null;
    postalCode?: string | null;
    countryCode?: string | null;
    phone?: string | null;
  } | null;
  lineItems?: Array<{
    __typename?: "core_apimessages_CheckoutLineItem";
    type?: core_apimessages_CheckoutLineItem_Type | null;
    label?: string | null;
    amount?: {
      __typename?: "core_apimessages_Money";
      amount?: string | null;
    } | null;
  } | null> | null;
};

export type CheckoutGuestContactInfoSummaryFieldsFragment = {
  __typename?: "Checkout";
  _id?: string | null;
  guest?: {
    __typename?: "core_apimessages_CheckoutGuest";
    firstName?: string | null;
    lastName?: string | null;
    email?: string | null;
  } | null;
};

export type Core_Checkout_LayoutQueryVariables = Exact<{
  id?: InputMaybe<Scalars["String"]["input"]>;
  reverbApp?: InputMaybe<Scalars["String"]["input"]>;
  loggedOut: Scalars["Boolean"]["input"];
}>;

export type Core_Checkout_LayoutQuery = {
  __typename?: "Query";
  checkout?: {
    __typename?: "Checkout";
    _id?: string | null;
    checkoutBundlingId?: string | null;
    isAllPaidWithBucks?: boolean | null;
    id?: string | null;
    paymentErrorMessage?: string | null;
    source?: core_apimessages_Checkout_Source | null;
    type?: core_apimessages_Checkout_Type | null;
    status?: core_apimessages_Checkout_Status | null;
    paymentMethod?: {
      __typename?: "core_apimessages_CheckoutPaymentMethod";
      type?: core_apimessages_CheckoutPaymentMethod_Type | null;
      isComplete?: boolean | null;
      supportsDiscountCodes?: boolean | null;
      applePay?: {
        __typename?: "core_apimessages_CheckoutApplePayDetails";
        merchantId?: string | null;
        merchantName?: string | null;
      } | null;
      googlePay?: {
        __typename?: "core_apimessages_CheckoutGooglePayDetails";
        merchantId?: string | null;
        merchantName?: string | null;
        gatewayMerchantId?: string | null;
        cardNetworks?: Array<core_apimessages_CheckoutGooglePayDetails_CardNetwork | null> | null;
      } | null;
      paypal?: {
        __typename?: "core_apimessages_CheckoutPaypalDetails";
        currency?: string | null;
        merchantId?: string | null;
        clientId?: string | null;
      } | null;
      affirm?: {
        __typename?: "core_apimessages_CheckoutAffirmDetails";
        financingProgramSlug?: string | null;
      } | null;
    } | null;
    shippingAddress?: {
      __typename?: "core_apimessages_Address";
      _id?: string | null;
      isComplete?: boolean | null;
      countryCode?: string | null;
      name?: string | null;
      streetAddress?: string | null;
      extendedAddress?: string | null;
      locality?: string | null;
      region?: string | null;
      postalCode?: string | null;
      phone?: string | null;
      regionName?: string | null;
      id?: string | null;
      uuid?: string | null;
      primary?: boolean | null;
      country?: {
        __typename?: "core_apimessages_Country";
        _id?: string | null;
        name?: string | null;
      } | null;
    } | null;
    billingAddress?: {
      __typename?: "core_apimessages_Address";
      _id?: string | null;
      id?: string | null;
      isComplete?: boolean | null;
      name?: string | null;
      streetAddress?: string | null;
      extendedAddress?: string | null;
      locality?: string | null;
      region?: string | null;
      postalCode?: string | null;
      countryCode?: string | null;
      phone?: string | null;
      regionName?: string | null;
      country?: {
        __typename?: "core_apimessages_Country";
        _id?: string | null;
        name?: string | null;
      } | null;
    } | null;
    orders?: Array<{
      __typename?: "core_apimessages_CheckoutOrder";
      cannotBeShippedToAddress?: boolean | null;
      listingId?: string | null;
      quantity?: number | null;
      taxIncluded?: boolean | null;
      taxLabel?: string | null;
      id?: string | null;
      buyerCanChangeQuantity?: boolean | null;
      shippingMethod?: {
        __typename?: "core_apimessages_CheckoutShippingMethod";
        type?: core_apimessages_ShippingMethod | null;
        name?: string | null;
      } | null;
      listing?: {
        __typename?: "Listing";
        _id?: string | null;
        title?: string | null;
        allowedBuyerQuantity?: number | null;
        id?: string | null;
        categories?: Array<{
          __typename?: "Category";
          _id?: string | null;
          slug?: string | null;
          rootSlug?: string | null;
        } | null> | null;
        shop?: {
          __typename?: "Shop";
          _id?: string | null;
          name?: string | null;
          userUuid?: string | null;
          address?: {
            __typename?: "core_apimessages_Address";
            _id?: string | null;
            displayLocation?: string | null;
          } | null;
        } | null;
        pricing?: {
          __typename?: "core_apimessages_ListingPricing";
          originalPrice?: {
            __typename?: "core_apimessages_Money";
            display?: string | null;
            currency?: string | null;
          } | null;
          buyerPrice?: {
            __typename?: "core_apimessages_Money";
            display?: string | null;
            currency?: string | null;
          } | null;
        } | null;
        images?: Array<{
          __typename?: "core_apimessages_Image";
          source?: string | null;
        } | null> | null;
        affirmImages?: Array<{
          __typename?: "core_apimessages_Image";
          _id?: string | null;
          source?: string | null;
        } | null> | null;
      } | null;
      subtotal?: {
        __typename?: "core_apimessages_CheckoutOrder_Subtotal";
        discounted?: boolean | null;
        listingPrice?: {
          __typename?: "core_apimessages_Money";
          amount?: string | null;
          display?: string | null;
          currency?: string | null;
          amountCents?: number | null;
        } | null;
        discount?: {
          __typename?: "core_apimessages_CheckoutOrder_SubtotalDiscount";
          label?: string | null;
          type?: core_apimessages_CheckoutOrder_SubtotalDiscount_Type | null;
        } | null;
      } | null;
      protectionPlan?: {
        __typename?: "core_apimessages_ProtectionPlan";
        title?: string | null;
      } | null;
      shippingTotal?: {
        __typename?: "core_apimessages_CheckoutOrder_ShippingTotal";
        discounted?: boolean | null;
        originalAmountOwed?: {
          __typename?: "core_apimessages_Money";
          display?: string | null;
        } | null;
        amountOwed?: {
          __typename?: "core_apimessages_Money";
          display?: string | null;
        } | null;
        discount?: {
          __typename?: "core_apimessages_CheckoutOrder_ShippingTotalDiscount";
          type?: core_apimessages_CheckoutOrder_ShippingTotalDiscount_Type | null;
          label?: string | null;
        } | null;
      } | null;
      availableShippingMethods?: Array<{
        __typename?: "core_apimessages_CheckoutShippingMethod";
        name?: string | null;
        type?: core_apimessages_ShippingMethod | null;
      } | null> | null;
    } | null> | null;
    action?: {
      __typename?: "CheckoutAction";
      _id?: string | null;
      type?: core_apimessages_CheckoutAction_Type | null;
      redirectUrl?: string | null;
    } | null;
    availablePaymentMethods?: Array<{
      __typename?: "core_apimessages_CheckoutPaymentMethod";
      name?: string | null;
      type?: core_apimessages_CheckoutPaymentMethod_Type | null;
      applePay?: {
        __typename?: "core_apimessages_CheckoutApplePayDetails";
        merchantId?: string | null;
        merchantName?: string | null;
      } | null;
      googlePay?: {
        __typename?: "core_apimessages_CheckoutGooglePayDetails";
        merchantId?: string | null;
        merchantName?: string | null;
        gatewayMerchantId?: string | null;
        cardNetworks?: Array<core_apimessages_CheckoutGooglePayDetails_CardNetwork | null> | null;
      } | null;
      affirm?: {
        __typename?: "core_apimessages_CheckoutAffirmDetails";
        financingProgramSlug?: string | null;
      } | null;
    } | null> | null;
    total?: {
      __typename?: "core_apimessages_Checkout_Total";
      label?: string | null;
      amount?: {
        __typename?: "core_apimessages_Money";
        amount?: string | null;
        currency?: string | null;
        display?: string | null;
        amountCents?: number | null;
      } | null;
    } | null;
    guest?: {
      __typename?: "core_apimessages_CheckoutGuest";
      firstName?: string | null;
      lastName?: string | null;
      email?: string | null;
      isComplete?: boolean | null;
    } | null;
    lineItems?: Array<{
      __typename?: "core_apimessages_CheckoutLineItem";
      type?: core_apimessages_CheckoutLineItem_Type | null;
      label?: string | null;
      amount?: {
        __typename?: "core_apimessages_Money";
        display?: string | null;
        currency?: string | null;
        amount?: string | null;
        amountCents?: number | null;
      } | null;
    } | null> | null;
    creditCard?: {
      __typename?: "core_apimessages_CreditCard";
      id?: string | null;
      needsReverification?: boolean | null;
      cardType?: string | null;
      brand?: {
        __typename?: "core_apimessages_CreditCard_Brand";
        type?: core_apimessages_CreditCard_Brand_Type | null;
        name?: string | null;
      } | null;
      display?: {
        __typename?: "core_apimessages_CreditCard_Display";
        summary?: string | null;
        expirationDate?: string | null;
      } | null;
    } | null;
  } | null;
  me?: {
    __typename?: "rql_Me";
    uuid?: string | null;
    _id?: string | null;
    shippingAddresses?: Array<{
      __typename?: "core_apimessages_Address";
      _id?: string | null;
      uuid?: string | null;
    } | null> | null;
    creditCards?: Array<{
      __typename?: "core_apimessages_CreditCard";
      id?: string | null;
    } | null> | null;
  } | null;
};

export type CheckoutNewShippingAddressFormFieldsFragment = {
  __typename?: "Checkout";
  _id?: string | null;
  id?: string | null;
  guest?: {
    __typename?: "core_apimessages_CheckoutGuest";
    firstName?: string | null;
    lastName?: string | null;
    email?: string | null;
  } | null;
  orders?: Array<{
    __typename?: "core_apimessages_CheckoutOrder";
    shippingMethod?: {
      __typename?: "core_apimessages_CheckoutShippingMethod";
      type?: core_apimessages_ShippingMethod | null;
    } | null;
  } | null> | null;
  shippingAddress?: {
    __typename?: "core_apimessages_Address";
    _id?: string | null;
    name?: string | null;
    streetAddress?: string | null;
    extendedAddress?: string | null;
    locality?: string | null;
    region?: string | null;
    postalCode?: string | null;
    countryCode?: string | null;
    phone?: string | null;
    isComplete?: boolean | null;
    regionName?: string | null;
  } | null;
};

export type CheckoutOrderQuantitySelectorFieldsFragment = {
  __typename?: "Checkout";
  _id?: string | null;
  orders?: Array<{
    __typename?: "core_apimessages_CheckoutOrder";
    quantity?: number | null;
    listingId?: string | null;
    buyerCanChangeQuantity?: boolean | null;
    listing?: {
      __typename?: "Listing";
      _id?: string | null;
      allowedBuyerQuantity?: number | null;
    } | null;
  } | null> | null;
};

export type CheckoutOrderShippingSelectorFieldsFragment = {
  __typename?: "Checkout";
  _id?: string | null;
  orders?: Array<{
    __typename?: "core_apimessages_CheckoutOrder";
    listingId?: string | null;
    availableShippingMethods?: Array<{
      __typename?: "core_apimessages_CheckoutShippingMethod";
      name?: string | null;
      type?: core_apimessages_ShippingMethod | null;
    } | null> | null;
    shippingMethod?: {
      __typename?: "core_apimessages_CheckoutShippingMethod";
      name?: string | null;
      type?: core_apimessages_ShippingMethod | null;
    } | null;
  } | null> | null;
};

export type CheckoutOrderSummaryFieldsFragment = {
  __typename?: "Checkout";
  _id?: string | null;
  id?: string | null;
  orders?: Array<{
    __typename?: "core_apimessages_CheckoutOrder";
    listingId?: string | null;
    cannotBeShippedToAddress?: boolean | null;
    taxIncluded?: boolean | null;
    taxLabel?: string | null;
    quantity?: number | null;
    buyerCanChangeQuantity?: boolean | null;
    subtotal?: {
      __typename?: "core_apimessages_CheckoutOrder_Subtotal";
      discounted?: boolean | null;
      discount?: {
        __typename?: "core_apimessages_CheckoutOrder_SubtotalDiscount";
        label?: string | null;
        type?: core_apimessages_CheckoutOrder_SubtotalDiscount_Type | null;
      } | null;
      listingPrice?: {
        __typename?: "core_apimessages_Money";
        display?: string | null;
        currency?: string | null;
      } | null;
    } | null;
    listing?: {
      __typename?: "Listing";
      _id?: string | null;
      title?: string | null;
      allowedBuyerQuantity?: number | null;
      shop?: {
        __typename?: "Shop";
        _id?: string | null;
        name?: string | null;
        userUuid?: string | null;
        address?: {
          __typename?: "core_apimessages_Address";
          _id?: string | null;
          displayLocation?: string | null;
        } | null;
      } | null;
      pricing?: {
        __typename?: "core_apimessages_ListingPricing";
        originalPrice?: {
          __typename?: "core_apimessages_Money";
          display?: string | null;
          currency?: string | null;
        } | null;
        buyerPrice?: {
          __typename?: "core_apimessages_Money";
          display?: string | null;
          currency?: string | null;
        } | null;
      } | null;
      images?: Array<{
        __typename?: "core_apimessages_Image";
        source?: string | null;
      } | null> | null;
    } | null;
    protectionPlan?: {
      __typename?: "core_apimessages_ProtectionPlan";
      title?: string | null;
    } | null;
    shippingTotal?: {
      __typename?: "core_apimessages_CheckoutOrder_ShippingTotal";
      discounted?: boolean | null;
      originalAmountOwed?: {
        __typename?: "core_apimessages_Money";
        display?: string | null;
      } | null;
      amountOwed?: {
        __typename?: "core_apimessages_Money";
        display?: string | null;
      } | null;
      discount?: {
        __typename?: "core_apimessages_CheckoutOrder_ShippingTotalDiscount";
        type?: core_apimessages_CheckoutOrder_ShippingTotalDiscount_Type | null;
        label?: string | null;
      } | null;
    } | null;
    availableShippingMethods?: Array<{
      __typename?: "core_apimessages_CheckoutShippingMethod";
      name?: string | null;
      type?: core_apimessages_ShippingMethod | null;
    } | null> | null;
    shippingMethod?: {
      __typename?: "core_apimessages_CheckoutShippingMethod";
      name?: string | null;
      type?: core_apimessages_ShippingMethod | null;
    } | null;
  } | null> | null;
  paymentMethod?: {
    __typename?: "core_apimessages_CheckoutPaymentMethod";
    type?: core_apimessages_CheckoutPaymentMethod_Type | null;
    isComplete?: boolean | null;
  } | null;
  total?: {
    __typename?: "core_apimessages_Checkout_Total";
    label?: string | null;
    amount?: {
      __typename?: "core_apimessages_Money";
      display?: string | null;
    } | null;
  } | null;
  lineItems?: Array<{
    __typename?: "core_apimessages_CheckoutLineItem";
    type?: core_apimessages_CheckoutLineItem_Type | null;
    label?: string | null;
    amount?: {
      __typename?: "core_apimessages_Money";
      display?: string | null;
      currency?: string | null;
    } | null;
  } | null> | null;
};

export type Core_Checkout_UpdateOrderQuantityMutationVariables = Exact<{
  checkoutId?: InputMaybe<Scalars["String"]["input"]>;
  listingId?: InputMaybe<Scalars["String"]["input"]>;
  quantity?: InputMaybe<Scalars["Int"]["input"]>;
}>;

export type Core_Checkout_UpdateOrderQuantityMutation = {
  __typename?: "Mutation";
  editCheckoutCart?: {
    __typename?: "rql_CheckoutCartEditResponse";
    checkout?: { __typename?: "Checkout"; _id?: string | null } | null;
  } | null;
};

export type Core_Checkout_SelectOrderShippingMethodMutationVariables = Exact<{
  input?: InputMaybe<Input_core_apimessages_CheckoutSelectOrderShippingMethodRequest>;
}>;

export type Core_Checkout_SelectOrderShippingMethodMutation = {
  __typename?: "Mutation";
  selectCheckoutOrderShippingMethod?: {
    __typename?: "core_apimessages_CheckoutSelectOrderShippingMethodResponse";
    checkout?: { __typename?: "Checkout"; _id?: string | null } | null;
  } | null;
};

export type CheckoutOrderSummaryImageFieldsFragment = {
  __typename?: "Checkout";
  _id?: string | null;
  orders?: Array<{
    __typename?: "core_apimessages_CheckoutOrder";
    listing?: {
      __typename?: "Listing";
      _id?: string | null;
      title?: string | null;
      images?: Array<{
        __typename?: "core_apimessages_Image";
        source?: string | null;
      } | null> | null;
    } | null;
  } | null> | null;
};

export type CheckoutOrderSummaryLineItemsFieldsFragment = {
  __typename?: "Checkout";
  _id?: string | null;
  total?: {
    __typename?: "core_apimessages_Checkout_Total";
    label?: string | null;
    amount?: {
      __typename?: "core_apimessages_Money";
      display?: string | null;
    } | null;
  } | null;
  lineItems?: Array<{
    __typename?: "core_apimessages_CheckoutLineItem";
    type?: core_apimessages_CheckoutLineItem_Type | null;
    label?: string | null;
    amount?: {
      __typename?: "core_apimessages_Money";
      display?: string | null;
      currency?: string | null;
    } | null;
  } | null> | null;
};

export type CheckoutOrderSummaryOrderItemFieldsFragment = {
  __typename?: "Checkout";
  _id?: string | null;
  orders?: Array<{
    __typename?: "core_apimessages_CheckoutOrder";
    cannotBeShippedToAddress?: boolean | null;
    taxIncluded?: boolean | null;
    taxLabel?: string | null;
    listingId?: string | null;
    quantity?: number | null;
    buyerCanChangeQuantity?: boolean | null;
    subtotal?: {
      __typename?: "core_apimessages_CheckoutOrder_Subtotal";
      discounted?: boolean | null;
      discount?: {
        __typename?: "core_apimessages_CheckoutOrder_SubtotalDiscount";
        label?: string | null;
        type?: core_apimessages_CheckoutOrder_SubtotalDiscount_Type | null;
      } | null;
      listingPrice?: {
        __typename?: "core_apimessages_Money";
        display?: string | null;
        currency?: string | null;
      } | null;
    } | null;
    listing?: {
      __typename?: "Listing";
      _id?: string | null;
      title?: string | null;
      allowedBuyerQuantity?: number | null;
      shop?: {
        __typename?: "Shop";
        _id?: string | null;
        name?: string | null;
        userUuid?: string | null;
        address?: {
          __typename?: "core_apimessages_Address";
          _id?: string | null;
          displayLocation?: string | null;
        } | null;
      } | null;
      pricing?: {
        __typename?: "core_apimessages_ListingPricing";
        originalPrice?: {
          __typename?: "core_apimessages_Money";
          display?: string | null;
          currency?: string | null;
        } | null;
        buyerPrice?: {
          __typename?: "core_apimessages_Money";
          display?: string | null;
          currency?: string | null;
        } | null;
      } | null;
      images?: Array<{
        __typename?: "core_apimessages_Image";
        source?: string | null;
      } | null> | null;
    } | null;
    protectionPlan?: {
      __typename?: "core_apimessages_ProtectionPlan";
      title?: string | null;
    } | null;
    shippingTotal?: {
      __typename?: "core_apimessages_CheckoutOrder_ShippingTotal";
      discounted?: boolean | null;
      originalAmountOwed?: {
        __typename?: "core_apimessages_Money";
        display?: string | null;
      } | null;
      amountOwed?: {
        __typename?: "core_apimessages_Money";
        display?: string | null;
      } | null;
      discount?: {
        __typename?: "core_apimessages_CheckoutOrder_ShippingTotalDiscount";
        type?: core_apimessages_CheckoutOrder_ShippingTotalDiscount_Type | null;
        label?: string | null;
      } | null;
    } | null;
    availableShippingMethods?: Array<{
      __typename?: "core_apimessages_CheckoutShippingMethod";
      name?: string | null;
      type?: core_apimessages_ShippingMethod | null;
    } | null> | null;
    shippingMethod?: {
      __typename?: "core_apimessages_CheckoutShippingMethod";
      name?: string | null;
      type?: core_apimessages_ShippingMethod | null;
    } | null;
  } | null> | null;
};

export type CheckoutOrderSummaryOrderItemShippingTotalFieldsFragment = {
  __typename?: "Checkout";
  _id?: string | null;
  orders?: Array<{
    __typename?: "core_apimessages_CheckoutOrder";
    shippingTotal?: {
      __typename?: "core_apimessages_CheckoutOrder_ShippingTotal";
      discounted?: boolean | null;
      originalAmountOwed?: {
        __typename?: "core_apimessages_Money";
        display?: string | null;
      } | null;
      amountOwed?: {
        __typename?: "core_apimessages_Money";
        display?: string | null;
      } | null;
      discount?: {
        __typename?: "core_apimessages_CheckoutOrder_ShippingTotalDiscount";
        type?: core_apimessages_CheckoutOrder_ShippingTotalDiscount_Type | null;
        label?: string | null;
      } | null;
    } | null;
  } | null> | null;
};

export type CheckoutOrderSummaryOrderItemSubtotalFieldsFragment = {
  __typename?: "Checkout";
  _id?: string | null;
  orders?: Array<{
    __typename?: "core_apimessages_CheckoutOrder";
    quantity?: number | null;
    listing?: {
      __typename?: "Listing";
      _id?: string | null;
      pricing?: {
        __typename?: "core_apimessages_ListingPricing";
        originalPrice?: {
          __typename?: "core_apimessages_Money";
          display?: string | null;
          currency?: string | null;
        } | null;
        buyerPrice?: {
          __typename?: "core_apimessages_Money";
          display?: string | null;
          currency?: string | null;
        } | null;
      } | null;
    } | null;
    subtotal?: {
      __typename?: "core_apimessages_CheckoutOrder_Subtotal";
      discounted?: boolean | null;
      listingPrice?: {
        __typename?: "core_apimessages_Money";
        display?: string | null;
        currency?: string | null;
      } | null;
      discount?: {
        __typename?: "core_apimessages_CheckoutOrder_SubtotalDiscount";
        type?: core_apimessages_CheckoutOrder_SubtotalDiscount_Type | null;
        label?: string | null;
      } | null;
    } | null;
  } | null> | null;
};

export type Core_Checkout_UpdateStandalonePaypalPayOrderMutationVariables =
  Exact<{
    input?: InputMaybe<Input_core_apimessages_UpdateCheckoutPaypalOrderRequest>;
  }>;

export type Core_Checkout_UpdateStandalonePaypalPayOrderMutation = {
  __typename?: "Mutation";
  updateCheckoutPaypalOrder?: {
    __typename?: "core_apimessages_UpdateCheckoutPaypalOrderResponse";
    id?: string | null;
  } | null;
};

export type CheckoutPayPalPlaceOrderButtonFieldsFragment = {
  __typename?: "Checkout";
  _id?: string | null;
  id?: string | null;
  total?: {
    __typename?: "core_apimessages_Checkout_Total";
    amount?: {
      __typename?: "core_apimessages_Money";
      amountCents?: number | null;
      currency?: string | null;
    } | null;
  } | null;
};

export type Core_Checkout_FinalizePayPalPaymentMutationVariables = Exact<{
  input?: InputMaybe<Input_core_apimessages_FinalizePaypalCheckoutPaymentRequest>;
}>;

export type Core_Checkout_FinalizePayPalPaymentMutation = {
  __typename?: "Mutation";
  finalizePaypalCheckoutPayment?: {
    __typename?: "core_apimessages_FinalizePaypalCheckoutPaymentResponse";
    id?: string | null;
  } | null;
};

export type Core_Checkout_PaymentInProgressLayoutQueryVariables = Exact<{
  id?: InputMaybe<Scalars["String"]["input"]>;
  reverbApp?: InputMaybe<Scalars["String"]["input"]>;
}>;

export type Core_Checkout_PaymentInProgressLayoutQuery = {
  __typename?: "Query";
  checkout?: {
    __typename?: "Checkout";
    _id?: string | null;
    id?: string | null;
    source?: core_apimessages_Checkout_Source | null;
    action?: {
      __typename?: "CheckoutAction";
      _id?: string | null;
      type?: core_apimessages_CheckoutAction_Type | null;
      redirectUrl?: string | null;
    } | null;
    paymentMethod?: {
      __typename?: "core_apimessages_CheckoutPaymentMethod";
      type?: core_apimessages_CheckoutPaymentMethod_Type | null;
    } | null;
  } | null;
};

export type SelectCheckoutPaymentMethodFieldsFragment = {
  __typename?: "Checkout";
  _id?: string | null;
  source?: core_apimessages_Checkout_Source | null;
  paymentMethod?: {
    __typename?: "core_apimessages_CheckoutPaymentMethod";
    type?: core_apimessages_CheckoutPaymentMethod_Type | null;
    applePay?: {
      __typename?: "core_apimessages_CheckoutApplePayDetails";
      merchantId?: string | null;
      merchantName?: string | null;
    } | null;
    googlePay?: {
      __typename?: "core_apimessages_CheckoutGooglePayDetails";
      merchantId?: string | null;
      merchantName?: string | null;
      gatewayMerchantId?: string | null;
      cardNetworks?: Array<core_apimessages_CheckoutGooglePayDetails_CardNetwork | null> | null;
    } | null;
  } | null;
};

export type CheckoutPaymentMethodFieldsFragment = {
  __typename?: "Checkout";
  _id?: string | null;
  id?: string | null;
  source?: core_apimessages_Checkout_Source | null;
  availablePaymentMethods?: Array<{
    __typename?: "core_apimessages_CheckoutPaymentMethod";
    name?: string | null;
    type?: core_apimessages_CheckoutPaymentMethod_Type | null;
    applePay?: {
      __typename?: "core_apimessages_CheckoutApplePayDetails";
      merchantId?: string | null;
      merchantName?: string | null;
    } | null;
    googlePay?: {
      __typename?: "core_apimessages_CheckoutGooglePayDetails";
      merchantId?: string | null;
      merchantName?: string | null;
      gatewayMerchantId?: string | null;
      cardNetworks?: Array<core_apimessages_CheckoutGooglePayDetails_CardNetwork | null> | null;
    } | null;
    affirm?: {
      __typename?: "core_apimessages_CheckoutAffirmDetails";
      financingProgramSlug?: string | null;
    } | null;
  } | null> | null;
  billingAddress?: {
    __typename?: "core_apimessages_Address";
    _id?: string | null;
    isComplete?: boolean | null;
    name?: string | null;
    streetAddress?: string | null;
    extendedAddress?: string | null;
    locality?: string | null;
    region?: string | null;
    postalCode?: string | null;
    countryCode?: string | null;
    phone?: string | null;
    regionName?: string | null;
  } | null;
  paymentMethod?: {
    __typename?: "core_apimessages_CheckoutPaymentMethod";
    type?: core_apimessages_CheckoutPaymentMethod_Type | null;
    isComplete?: boolean | null;
    applePay?: {
      __typename?: "core_apimessages_CheckoutApplePayDetails";
      merchantId?: string | null;
      merchantName?: string | null;
    } | null;
    googlePay?: {
      __typename?: "core_apimessages_CheckoutGooglePayDetails";
      merchantId?: string | null;
      merchantName?: string | null;
      gatewayMerchantId?: string | null;
      cardNetworks?: Array<core_apimessages_CheckoutGooglePayDetails_CardNetwork | null> | null;
    } | null;
    paypal?: {
      __typename?: "core_apimessages_CheckoutPaypalDetails";
      currency?: string | null;
      merchantId?: string | null;
      clientId?: string | null;
    } | null;
  } | null;
  lineItems?: Array<{
    __typename?: "core_apimessages_CheckoutLineItem";
    type?: core_apimessages_CheckoutLineItem_Type | null;
  } | null> | null;
  total?: {
    __typename?: "core_apimessages_Checkout_Total";
    amount?: {
      __typename?: "core_apimessages_Money";
      amount?: string | null;
      amountCents?: number | null;
      currency?: string | null;
    } | null;
  } | null;
  shippingAddress?: {
    __typename?: "core_apimessages_Address";
    _id?: string | null;
    uuid?: string | null;
    id?: string | null;
    name?: string | null;
    streetAddress?: string | null;
    extendedAddress?: string | null;
    postalCode?: string | null;
    region?: string | null;
    regionName?: string | null;
    locality?: string | null;
    phone?: string | null;
    primary?: boolean | null;
    isComplete?: boolean | null;
    countryCode?: string | null;
    country?: {
      __typename?: "core_apimessages_Country";
      _id?: string | null;
      name?: string | null;
    } | null;
  } | null;
  creditCard?: {
    __typename?: "core_apimessages_CreditCard";
    id?: string | null;
    needsReverification?: boolean | null;
    cardType?: string | null;
    brand?: {
      __typename?: "core_apimessages_CreditCard_Brand";
      type?: core_apimessages_CreditCard_Brand_Type | null;
      name?: string | null;
    } | null;
    display?: {
      __typename?: "core_apimessages_CreditCard_Display";
      summary?: string | null;
      expirationDate?: string | null;
    } | null;
  } | null;
  orders?: Array<{
    __typename?: "core_apimessages_CheckoutOrder";
    id?: string | null;
    shippingMethod?: {
      __typename?: "core_apimessages_CheckoutShippingMethod";
      type?: core_apimessages_ShippingMethod | null;
    } | null;
  } | null> | null;
};

export type Core_Checkout_SelectCheckoutPaymentMethodMutationVariables = Exact<{
  input?: InputMaybe<Input_core_apimessages_SelectCheckoutPaymentMethodRequest>;
}>;

export type Core_Checkout_SelectCheckoutPaymentMethodMutation = {
  __typename?: "Mutation";
  selectCheckoutPaymentMethod?: {
    __typename?: "core_apimessages_SelectCheckoutPaymentMethodResponse";
    checkout?: {
      __typename?: "Checkout";
      _id?: string | null;
      source?: core_apimessages_Checkout_Source | null;
      paymentMethod?: {
        __typename?: "core_apimessages_CheckoutPaymentMethod";
        type?: core_apimessages_CheckoutPaymentMethod_Type | null;
        applePay?: {
          __typename?: "core_apimessages_CheckoutApplePayDetails";
          merchantId?: string | null;
          merchantName?: string | null;
        } | null;
        googlePay?: {
          __typename?: "core_apimessages_CheckoutGooglePayDetails";
          merchantId?: string | null;
          merchantName?: string | null;
          gatewayMerchantId?: string | null;
          cardNetworks?: Array<core_apimessages_CheckoutGooglePayDetails_CardNetwork | null> | null;
        } | null;
      } | null;
    } | null;
  } | null;
};

export type CheckoutPayPalContinueButtonFieldsFragment = {
  __typename?: "Checkout";
  _id?: string | null;
  id?: string | null;
};

export type Core_Checkout_CreatePaypalOrderMutationVariables = Exact<{
  input?: InputMaybe<Input_core_apimessages_CreateCheckoutPaypalOrderRequest>;
}>;

export type Core_Checkout_CreatePaypalOrderMutation = {
  __typename?: "Mutation";
  createCheckoutPaypalOrder?: {
    __typename?: "core_apimessages_CreateCheckoutPaypalOrderResponse";
    redirectUrl?: string | null;
  } | null;
};

export type CheckoutPaypalPayLaterButtonFieldsFragment = {
  __typename?: "Checkout";
  _id?: string | null;
  id?: string | null;
  total?: {
    __typename?: "core_apimessages_Checkout_Total";
    amount?: {
      __typename?: "core_apimessages_Money";
      currency?: string | null;
    } | null;
  } | null;
  paymentMethod?: {
    __typename?: "core_apimessages_CheckoutPaymentMethod";
    paypal?: {
      __typename?: "core_apimessages_CheckoutPaypalDetails";
      currency?: string | null;
    } | null;
  } | null;
};

export type Core_Checkout_CreatePaypalPayLaterOrderMutationVariables = Exact<{
  input?: InputMaybe<Input_core_apimessages_CreateCheckoutPaypalOrderRequest>;
}>;

export type Core_Checkout_CreatePaypalPayLaterOrderMutation = {
  __typename?: "Mutation";
  createCheckoutPaypalOrder?: {
    __typename?: "core_apimessages_CreateCheckoutPaypalOrderResponse";
    paypalOrderId?: string | null;
  } | null;
};

export type RedirectReturnCheckoutFieldsFragment = {
  __typename?: "Checkout";
  _id?: string | null;
  id?: string | null;
  status?: core_apimessages_Checkout_Status | null;
};

export type Core_Checkout_RedirectReturnLayoutQueryVariables = Exact<{
  id?: InputMaybe<Scalars["String"]["input"]>;
  reverbApp?: InputMaybe<Scalars["String"]["input"]>;
}>;

export type Core_Checkout_RedirectReturnLayoutQuery = {
  __typename?: "Query";
  checkout?: {
    __typename?: "Checkout";
    _id?: string | null;
    id?: string | null;
    status?: core_apimessages_Checkout_Status | null;
    source?: core_apimessages_Checkout_Source | null;
    paymentMethod?: {
      __typename?: "core_apimessages_CheckoutPaymentMethod";
      type?: core_apimessages_CheckoutPaymentMethod_Type | null;
    } | null;
    action?: {
      __typename?: "CheckoutAction";
      _id?: string | null;
      type?: core_apimessages_CheckoutAction_Type | null;
      redirectUrl?: string | null;
    } | null;
  } | null;
};

export type Core_Checkout_FinalizeRedirectPaymentCheckoutMutationVariables =
  Exact<{
    input?: InputMaybe<Input_core_apimessages_CheckoutFinalizeRedirectPaymentCheckoutRequest>;
  }>;

export type Core_Checkout_FinalizeRedirectPaymentCheckoutMutation = {
  __typename?: "Mutation";
  finalizeRedirectPaymentCheckout?: {
    __typename?: "core_apimessages_CheckoutFinalizeRedirectPaymentCheckoutResponse";
    checkout?: { __typename?: "Checkout"; _id?: string | null } | null;
  } | null;
};

export type Core_Checkout_FinalizeAffirmCheckoutPaymentMutationVariables =
  Exact<{
    input?: InputMaybe<Input_core_apimessages_FinalizeAffirmCheckoutPaymentRequest>;
  }>;

export type Core_Checkout_FinalizeAffirmCheckoutPaymentMutation = {
  __typename?: "Mutation";
  finalizeAffirmCheckoutPayment?: {
    __typename?: "core_apimessages_FinalizeAffirmCheckoutPaymentResponse";
    checkout?: { __typename?: "Checkout"; _id?: string | null } | null;
  } | null;
};

export type Core_Checkout_FinalizeRedirectAuthPaymentMutationVariables = Exact<{
  input?: InputMaybe<Input_core_apimessages_FinalizeRedirectAuthCheckoutPaymentRequest>;
}>;

export type Core_Checkout_FinalizeRedirectAuthPaymentMutation = {
  __typename?: "Mutation";
  finalizeRedirectAuthCheckoutPayment?: {
    __typename?: "core_apimessages_FinalizeRedirectAuthCheckoutPaymentResponse";
    checkout?: { __typename?: "Checkout"; _id?: string | null } | null;
  } | null;
};

export type CheckoutStandalonePayPalPaymentOptionsFieldsFragment = {
  __typename?: "Checkout";
  _id?: string | null;
  id?: string | null;
  total?: {
    __typename?: "core_apimessages_Checkout_Total";
    amount?: {
      __typename?: "core_apimessages_Money";
      currency?: string | null;
      amountCents?: number | null;
    } | null;
  } | null;
  paymentMethod?: {
    __typename?: "core_apimessages_CheckoutPaymentMethod";
    type?: core_apimessages_CheckoutPaymentMethod_Type | null;
    paypal?: {
      __typename?: "core_apimessages_CheckoutPaypalDetails";
      currency?: string | null;
      merchantId?: string | null;
      clientId?: string | null;
    } | null;
  } | null;
};

export type Core_Checkout_CreateStandalonePaypalPayOrderMutationVariables =
  Exact<{
    input?: InputMaybe<Input_core_apimessages_CreateCheckoutPaypalOrderRequest>;
  }>;

export type Core_Checkout_CreateStandalonePaypalPayOrderMutation = {
  __typename?: "Mutation";
  createCheckoutPaypalOrder?: {
    __typename?: "core_apimessages_CreateCheckoutPaypalOrderResponse";
    paypalOrderId?: string | null;
  } | null;
};

export type Core_Checkout_FinalizeStandalonePaypalPayOrderMutationVariables =
  Exact<{
    input?: InputMaybe<Input_core_apimessages_FinalizePaypalCheckoutPaymentRequest>;
  }>;

export type Core_Checkout_FinalizeStandalonePaypalPayOrderMutation = {
  __typename?: "Mutation";
  finalizePaypalCheckoutPayment?: {
    __typename?: "core_apimessages_FinalizePaypalCheckoutPaymentResponse";
    id?: string | null;
  } | null;
};

export type CheckoutThirdPartyAdsFieldsFragment = {
  __typename?: "Checkout";
  _id?: string | null;
  type?: core_apimessages_Checkout_Type | null;
  total?: {
    __typename?: "core_apimessages_Checkout_Total";
    amount?: {
      __typename?: "core_apimessages_Money";
      amount?: string | null;
      currency?: string | null;
    } | null;
  } | null;
  orders?: Array<{
    __typename?: "core_apimessages_CheckoutOrder";
    quantity?: number | null;
    listing?: {
      __typename?: "Listing";
      _id?: string | null;
      title?: string | null;
      categories?: Array<{
        __typename?: "Category";
        _id?: string | null;
        slug?: string | null;
        rootSlug?: string | null;
      } | null> | null;
    } | null;
    subtotal?: {
      __typename?: "core_apimessages_CheckoutOrder_Subtotal";
      listingPrice?: {
        __typename?: "core_apimessages_Money";
        amount?: string | null;
      } | null;
    } | null;
  } | null> | null;
  guest?: {
    __typename?: "core_apimessages_CheckoutGuest";
    firstName?: string | null;
    lastName?: string | null;
    email?: string | null;
  } | null;
};

export type Core_Checkout_StartPaypalCheckoutMutationVariables = Exact<{
  input?: InputMaybe<Input_core_apimessages_StartPaypalCheckoutRequest>;
}>;

export type Core_Checkout_StartPaypalCheckoutMutation = {
  __typename?: "Mutation";
  startPaypalCheckout?: {
    __typename?: "core_apimessages_StartPaypalCheckoutResponse";
    paypalOrderId?: string | null;
    checkout?: {
      __typename?: "Checkout";
      _id?: string | null;
      id?: string | null;
    } | null;
  } | null;
};

export type ListingGooglePayButtonFieldsFragment = {
  __typename?: "Listing";
  _id?: string | null;
  id?: string | null;
  expressPay?: {
    __typename?: "core_apimessages_ListingExpressPay";
    _id?: string | null;
    estimatedTotal?: {
      __typename?: "core_apimessages_Money";
      amount?: string | null;
      currency?: string | null;
    } | null;
    googlePay?: {
      __typename?: "core_apimessages_CheckoutGooglePayDetails";
      merchantId?: string | null;
      merchantName?: string | null;
      gatewayMerchantId?: string | null;
      cardNetworks?: Array<core_apimessages_CheckoutGooglePayDetails_CardNetwork | null> | null;
    } | null;
  } | null;
};

export type Core_Checkout_StartListingGooglePayMutationVariables = Exact<{
  listingId?: InputMaybe<Scalars["String"]["input"]>;
  quantity?: InputMaybe<Scalars["Int"]["input"]>;
  protectionPlanId?: InputMaybe<Scalars["String"]["input"]>;
}>;

export type Core_Checkout_StartListingGooglePayMutation = {
  __typename?: "Mutation";
  startCheckoutForListing?: {
    __typename?: "core_apimessages_StartCheckoutForListingResponse";
    checkout?: {
      __typename?: "Checkout";
      _id?: string | null;
      id?: string | null;
      status?: core_apimessages_Checkout_Status | null;
      paymentErrorMessage?: string | null;
      type?: core_apimessages_Checkout_Type | null;
      total?: {
        __typename?: "core_apimessages_Checkout_Total";
        label?: string | null;
        amount?: {
          __typename?: "core_apimessages_Money";
          amount?: string | null;
          amountCents?: number | null;
          currency?: string | null;
        } | null;
      } | null;
      guest?: {
        __typename?: "core_apimessages_CheckoutGuest";
        email?: string | null;
        firstName?: string | null;
        lastName?: string | null;
      } | null;
      shippingAddress?: {
        __typename?: "core_apimessages_Address";
        _id?: string | null;
        name?: string | null;
        streetAddress?: string | null;
        extendedAddress?: string | null;
        locality?: string | null;
        region?: string | null;
        postalCode?: string | null;
        countryCode?: string | null;
        phone?: string | null;
      } | null;
      lineItems?: Array<{
        __typename?: "core_apimessages_CheckoutLineItem";
        type?: core_apimessages_CheckoutLineItem_Type | null;
        label?: string | null;
        amount?: {
          __typename?: "core_apimessages_Money";
          amount?: string | null;
        } | null;
      } | null> | null;
    } | null;
  } | null;
};

export type PaymentMethodLabelFieldsFragment = {
  __typename?: "Checkout";
  _id?: string | null;
  availablePaymentMethods?: Array<{
    __typename?: "core_apimessages_CheckoutPaymentMethod";
    name?: string | null;
    type?: core_apimessages_CheckoutPaymentMethod_Type | null;
    affirm?: {
      __typename?: "core_apimessages_CheckoutAffirmDetails";
      financingProgramSlug?: string | null;
    } | null;
  } | null> | null;
  lineItems?: Array<{
    __typename?: "core_apimessages_CheckoutLineItem";
    type?: core_apimessages_CheckoutLineItem_Type | null;
  } | null> | null;
  total?: {
    __typename?: "core_apimessages_Checkout_Total";
    amount?: {
      __typename?: "core_apimessages_Money";
      amount?: string | null;
      amountCents?: number | null;
      currency?: string | null;
    } | null;
  } | null;
};

export type AffirmCheckoutBuilderFieldsFragment = {
  __typename?: "Checkout";
  _id?: string | null;
  id?: string | null;
  total?: {
    __typename?: "core_apimessages_Checkout_Total";
    amount?: {
      __typename?: "core_apimessages_Money";
      amountCents?: number | null;
      currency?: string | null;
    } | null;
  } | null;
  orders?: Array<{
    __typename?: "core_apimessages_CheckoutOrder";
    quantity?: number | null;
    subtotal?: {
      __typename?: "core_apimessages_CheckoutOrder_Subtotal";
      listingPrice?: {
        __typename?: "core_apimessages_Money";
        amountCents?: number | null;
      } | null;
    } | null;
    listing?: {
      __typename?: "Listing";
      _id?: string | null;
      id?: string | null;
      title?: string | null;
      affirmImages?: Array<{
        __typename?: "core_apimessages_Image";
        _id?: string | null;
        source?: string | null;
      } | null> | null;
    } | null;
  } | null> | null;
  paymentMethod?: {
    __typename?: "core_apimessages_CheckoutPaymentMethod";
    affirm?: {
      __typename?: "core_apimessages_CheckoutAffirmDetails";
      financingProgramSlug?: string | null;
    } | null;
  } | null;
  shippingAddress?: {
    __typename?: "core_apimessages_Address";
    _id?: string | null;
    name?: string | null;
    streetAddress?: string | null;
    extendedAddress?: string | null;
    locality?: string | null;
    region?: string | null;
    postalCode?: string | null;
    countryCode?: string | null;
    phone?: string | null;
  } | null;
  billingAddress?: {
    __typename?: "core_apimessages_Address";
    _id?: string | null;
    name?: string | null;
    streetAddress?: string | null;
    extendedAddress?: string | null;
    locality?: string | null;
    region?: string | null;
    postalCode?: string | null;
    countryCode?: string | null;
    phone?: string | null;
  } | null;
  lineItems?: Array<{
    __typename?: "core_apimessages_CheckoutLineItem";
    type?: core_apimessages_CheckoutLineItem_Type | null;
    amount?: {
      __typename?: "core_apimessages_Money";
      amountCents?: number | null;
      currency?: string | null;
    } | null;
  } | null> | null;
  guest?: {
    __typename?: "core_apimessages_CheckoutGuest";
    email?: string | null;
  } | null;
};

export type CheckoutAvailablePaymentMethodsHookFieldsFragment = {
  __typename?: "Checkout";
  _id?: string | null;
  availablePaymentMethods?: Array<{
    __typename?: "core_apimessages_CheckoutPaymentMethod";
    type?: core_apimessages_CheckoutPaymentMethod_Type | null;
    googlePay?: {
      __typename?: "core_apimessages_CheckoutGooglePayDetails";
      merchantId?: string | null;
      merchantName?: string | null;
      gatewayMerchantId?: string | null;
      cardNetworks?: Array<core_apimessages_CheckoutGooglePayDetails_CardNetwork | null> | null;
    } | null;
    applePay?: {
      __typename?: "core_apimessages_CheckoutApplePayDetails";
      merchantId?: string | null;
    } | null;
  } | null> | null;
};

export type CheckoutTrackingFieldsFragment = {
  __typename?: "Checkout";
  _id?: string | null;
  id?: string | null;
  source?: core_apimessages_Checkout_Source | null;
  paymentMethod?: {
    __typename?: "core_apimessages_CheckoutPaymentMethod";
    type?: core_apimessages_CheckoutPaymentMethod_Type | null;
  } | null;
};

export type GooglePayManagerFieldsFragment = {
  __typename?: "Checkout";
  _id?: string | null;
  id?: string | null;
  status?: core_apimessages_Checkout_Status | null;
  paymentErrorMessage?: string | null;
  type?: core_apimessages_Checkout_Type | null;
  total?: {
    __typename?: "core_apimessages_Checkout_Total";
    label?: string | null;
    amount?: {
      __typename?: "core_apimessages_Money";
      amount?: string | null;
      amountCents?: number | null;
      currency?: string | null;
    } | null;
  } | null;
  guest?: {
    __typename?: "core_apimessages_CheckoutGuest";
    email?: string | null;
    firstName?: string | null;
    lastName?: string | null;
  } | null;
  shippingAddress?: {
    __typename?: "core_apimessages_Address";
    _id?: string | null;
    name?: string | null;
    streetAddress?: string | null;
    extendedAddress?: string | null;
    locality?: string | null;
    region?: string | null;
    postalCode?: string | null;
    countryCode?: string | null;
    phone?: string | null;
  } | null;
  lineItems?: Array<{
    __typename?: "core_apimessages_CheckoutLineItem";
    type?: core_apimessages_CheckoutLineItem_Type | null;
    label?: string | null;
    amount?: {
      __typename?: "core_apimessages_Money";
      amount?: string | null;
    } | null;
  } | null> | null;
};

export type GooglePayFinalizeActionFieldsFragment = {
  __typename?: "CheckoutAction";
  _id?: string | null;
  type?: core_apimessages_CheckoutAction_Type | null;
  redirectUrl?: string | null;
};

export type CheckoutAffirmPaymentFormHookFieldsFragment = {
  __typename?: "Checkout";
  _id?: string | null;
  id?: string | null;
  total?: {
    __typename?: "core_apimessages_Checkout_Total";
    amount?: {
      __typename?: "core_apimessages_Money";
      amountCents?: number | null;
      currency?: string | null;
    } | null;
  } | null;
  orders?: Array<{
    __typename?: "core_apimessages_CheckoutOrder";
    quantity?: number | null;
    subtotal?: {
      __typename?: "core_apimessages_CheckoutOrder_Subtotal";
      listingPrice?: {
        __typename?: "core_apimessages_Money";
        amountCents?: number | null;
      } | null;
    } | null;
    listing?: {
      __typename?: "Listing";
      _id?: string | null;
      id?: string | null;
      title?: string | null;
      affirmImages?: Array<{
        __typename?: "core_apimessages_Image";
        _id?: string | null;
        source?: string | null;
      } | null> | null;
    } | null;
  } | null> | null;
  paymentMethod?: {
    __typename?: "core_apimessages_CheckoutPaymentMethod";
    affirm?: {
      __typename?: "core_apimessages_CheckoutAffirmDetails";
      financingProgramSlug?: string | null;
    } | null;
  } | null;
  shippingAddress?: {
    __typename?: "core_apimessages_Address";
    _id?: string | null;
    name?: string | null;
    streetAddress?: string | null;
    extendedAddress?: string | null;
    locality?: string | null;
    region?: string | null;
    postalCode?: string | null;
    countryCode?: string | null;
    phone?: string | null;
  } | null;
  billingAddress?: {
    __typename?: "core_apimessages_Address";
    _id?: string | null;
    name?: string | null;
    streetAddress?: string | null;
    extendedAddress?: string | null;
    locality?: string | null;
    region?: string | null;
    postalCode?: string | null;
    countryCode?: string | null;
    phone?: string | null;
  } | null;
  lineItems?: Array<{
    __typename?: "core_apimessages_CheckoutLineItem";
    type?: core_apimessages_CheckoutLineItem_Type | null;
    amount?: {
      __typename?: "core_apimessages_Money";
      amountCents?: number | null;
      currency?: string | null;
    } | null;
  } | null> | null;
  guest?: {
    __typename?: "core_apimessages_CheckoutGuest";
    email?: string | null;
  } | null;
};

export type Core_Checkout_UpdateAffirmBillingAddressMutationVariables = Exact<{
  input?: InputMaybe<Input_core_apimessages_UpdateCheckoutBillingAddressRequest>;
}>;

export type Core_Checkout_UpdateAffirmBillingAddressMutation = {
  __typename?: "Mutation";
  updateCheckoutBillingAddress?: {
    __typename?: "core_apimessages_UpdateCheckoutBillingAddressResponse";
    checkout?: {
      __typename?: "Checkout";
      _id?: string | null;
      id?: string | null;
      total?: {
        __typename?: "core_apimessages_Checkout_Total";
        amount?: {
          __typename?: "core_apimessages_Money";
          amountCents?: number | null;
          currency?: string | null;
        } | null;
      } | null;
      orders?: Array<{
        __typename?: "core_apimessages_CheckoutOrder";
        quantity?: number | null;
        subtotal?: {
          __typename?: "core_apimessages_CheckoutOrder_Subtotal";
          listingPrice?: {
            __typename?: "core_apimessages_Money";
            amountCents?: number | null;
          } | null;
        } | null;
        listing?: {
          __typename?: "Listing";
          _id?: string | null;
          id?: string | null;
          title?: string | null;
          affirmImages?: Array<{
            __typename?: "core_apimessages_Image";
            _id?: string | null;
            source?: string | null;
          } | null> | null;
        } | null;
      } | null> | null;
      paymentMethod?: {
        __typename?: "core_apimessages_CheckoutPaymentMethod";
        affirm?: {
          __typename?: "core_apimessages_CheckoutAffirmDetails";
          financingProgramSlug?: string | null;
        } | null;
      } | null;
      shippingAddress?: {
        __typename?: "core_apimessages_Address";
        _id?: string | null;
        name?: string | null;
        streetAddress?: string | null;
        extendedAddress?: string | null;
        locality?: string | null;
        region?: string | null;
        postalCode?: string | null;
        countryCode?: string | null;
        phone?: string | null;
      } | null;
      billingAddress?: {
        __typename?: "core_apimessages_Address";
        _id?: string | null;
        name?: string | null;
        streetAddress?: string | null;
        extendedAddress?: string | null;
        locality?: string | null;
        region?: string | null;
        postalCode?: string | null;
        countryCode?: string | null;
        phone?: string | null;
      } | null;
      lineItems?: Array<{
        __typename?: "core_apimessages_CheckoutLineItem";
        type?: core_apimessages_CheckoutLineItem_Type | null;
        amount?: {
          __typename?: "core_apimessages_Money";
          amountCents?: number | null;
          currency?: string | null;
        } | null;
      } | null> | null;
      guest?: {
        __typename?: "core_apimessages_CheckoutGuest";
        email?: string | null;
      } | null;
    } | null;
  } | null;
};

export type CheckoutApplePayHookFieldsFragment = {
  __typename?: "Checkout";
  _id?: string | null;
  id?: string | null;
  type?: core_apimessages_Checkout_Type | null;
  guest?: {
    __typename?: "core_apimessages_CheckoutGuest";
    firstName?: string | null;
    lastName?: string | null;
    email?: string | null;
  } | null;
  shippingAddress?: {
    __typename?: "core_apimessages_Address";
    _id?: string | null;
    name?: string | null;
    streetAddress?: string | null;
    extendedAddress?: string | null;
    locality?: string | null;
    region?: string | null;
    postalCode?: string | null;
    countryCode?: string | null;
    phone?: string | null;
  } | null;
  paymentMethod?: {
    __typename?: "core_apimessages_CheckoutPaymentMethod";
    applePay?: {
      __typename?: "core_apimessages_CheckoutApplePayDetails";
      merchantName?: string | null;
      merchantId?: string | null;
    } | null;
  } | null;
  total?: {
    __typename?: "core_apimessages_Checkout_Total";
    amount?: {
      __typename?: "core_apimessages_Money";
      amountCents?: number | null;
      currency?: string | null;
      amount?: string | null;
    } | null;
  } | null;
  lineItems?: Array<{
    __typename?: "core_apimessages_CheckoutLineItem";
    type?: core_apimessages_CheckoutLineItem_Type | null;
    label?: string | null;
    amount?: {
      __typename?: "core_apimessages_Money";
      amount?: string | null;
      amountCents?: number | null;
      currency?: string | null;
    } | null;
  } | null> | null;
};

export type CheckoutCardPaymentHookCheckoutFieldsFragment = {
  __typename?: "Checkout";
  _id?: string | null;
  id?: string | null;
  total?: {
    __typename?: "core_apimessages_Checkout_Total";
    amount?: {
      __typename?: "core_apimessages_Money";
      amountCents?: number | null;
      currency?: string | null;
    } | null;
  } | null;
};

export type CheckoutCardPaymentHookMeFieldsFragment = {
  __typename?: "rql_Me";
  _id?: string | null;
  uuid?: string | null;
  creditCards?: Array<{
    __typename?: "core_apimessages_CreditCard";
    id?: string | null;
  } | null> | null;
};

export type Core_Checkout_FinalizeCardPaymentMutationVariables = Exact<{
  input?: InputMaybe<Input_core_apimessages_FinalizeCardCheckoutPaymentRequest>;
}>;

export type Core_Checkout_FinalizeCardPaymentMutation = {
  __typename?: "Mutation";
  finalizeCardCheckoutPayment?: {
    __typename?: "core_apimessages_FinalizeCardCheckoutPaymentResponse";
    checkout?: { __typename?: "Checkout"; _id?: string | null } | null;
  } | null;
};

export type CheckoutContinueButtonStateFieldsFragment = {
  __typename?: "Checkout";
  _id?: string | null;
  id?: string | null;
  shippingAddress?: {
    __typename?: "core_apimessages_Address";
    _id?: string | null;
    isComplete?: boolean | null;
  } | null;
  paymentMethod?: {
    __typename?: "core_apimessages_CheckoutPaymentMethod";
    isComplete?: boolean | null;
  } | null;
  orders?: Array<{
    __typename?: "core_apimessages_CheckoutOrder";
    cannotBeShippedToAddress?: boolean | null;
    shippingMethod?: {
      __typename?: "core_apimessages_CheckoutShippingMethod";
      type?: core_apimessages_ShippingMethod | null;
    } | null;
  } | null> | null;
  guest?: {
    __typename?: "core_apimessages_CheckoutGuest";
    isComplete?: boolean | null;
  } | null;
};

export type CheckoutDisplayStepHookFieldsFragment = {
  __typename?: "Checkout";
  _id?: string | null;
  paymentMethod?: {
    __typename?: "core_apimessages_CheckoutPaymentMethod";
    type?: core_apimessages_CheckoutPaymentMethod_Type | null;
    isComplete?: boolean | null;
  } | null;
  orders?: Array<{
    __typename?: "core_apimessages_CheckoutOrder";
    shippingMethod?: {
      __typename?: "core_apimessages_CheckoutShippingMethod";
      type?: core_apimessages_ShippingMethod | null;
    } | null;
  } | null> | null;
  guest?: {
    __typename?: "core_apimessages_CheckoutGuest";
    isComplete?: boolean | null;
  } | null;
  shippingAddress?: {
    __typename?: "core_apimessages_Address";
    _id?: string | null;
    isComplete?: boolean | null;
  } | null;
  billingAddress?: {
    __typename?: "core_apimessages_Address";
    _id?: string | null;
    isComplete?: boolean | null;
  } | null;
};

export type Core_Checkout_UpdateShippingInfoMutationVariables = Exact<{
  input?: InputMaybe<Input_core_apimessages_CheckoutUpdateShippingAddressRequest>;
}>;

export type Core_Checkout_UpdateShippingInfoMutation = {
  __typename?: "Mutation";
  updateCheckoutShippingAddress?: {
    __typename?: "core_apimessages_CheckoutUpdateShippingAddressResponse";
    checkout?: { __typename?: "Checkout"; _id?: string | null } | null;
  } | null;
};

export type Core_Checkout_UpdateGooglePayShippingEstimateMutationVariables =
  Exact<{
    input?: InputMaybe<Input_core_apimessages_CheckoutUpdateShippingEstimateRequest>;
  }>;

export type Core_Checkout_UpdateGooglePayShippingEstimateMutation = {
  __typename?: "Mutation";
  updateCheckoutShippingEstimate?: {
    __typename?: "core_apimessages_CheckoutUpdateShippingEstimateResponse";
    checkout?: {
      __typename?: "Checkout";
      _id?: string | null;
      id?: string | null;
      status?: core_apimessages_Checkout_Status | null;
      paymentErrorMessage?: string | null;
      type?: core_apimessages_Checkout_Type | null;
      total?: {
        __typename?: "core_apimessages_Checkout_Total";
        label?: string | null;
        amount?: {
          __typename?: "core_apimessages_Money";
          amount?: string | null;
          amountCents?: number | null;
          currency?: string | null;
        } | null;
      } | null;
      guest?: {
        __typename?: "core_apimessages_CheckoutGuest";
        email?: string | null;
        firstName?: string | null;
        lastName?: string | null;
      } | null;
      shippingAddress?: {
        __typename?: "core_apimessages_Address";
        _id?: string | null;
        name?: string | null;
        streetAddress?: string | null;
        extendedAddress?: string | null;
        locality?: string | null;
        region?: string | null;
        postalCode?: string | null;
        countryCode?: string | null;
        phone?: string | null;
      } | null;
      lineItems?: Array<{
        __typename?: "core_apimessages_CheckoutLineItem";
        type?: core_apimessages_CheckoutLineItem_Type | null;
        label?: string | null;
        amount?: {
          __typename?: "core_apimessages_Money";
          amount?: string | null;
        } | null;
      } | null> | null;
    } | null;
  } | null;
};

export type Core_Checkout_FinalizeGooglePayCheckoutMutationVariables = Exact<{
  input?: InputMaybe<Input_core_apimessages_CheckoutFinalizeDigitalWalletPaymentRequest>;
}>;

export type Core_Checkout_FinalizeGooglePayCheckoutMutation = {
  __typename?: "Mutation";
  finalizeDigitalWalletPayment?: {
    __typename?: "core_apimessages_CheckoutFinalizeDigitalWalletPaymentResponse";
    checkout?: {
      __typename?: "Checkout";
      _id?: string | null;
      id?: string | null;
      status?: core_apimessages_Checkout_Status | null;
      paymentErrorMessage?: string | null;
      type?: core_apimessages_Checkout_Type | null;
      action?: {
        __typename?: "CheckoutAction";
        _id?: string | null;
        type?: core_apimessages_CheckoutAction_Type | null;
        redirectUrl?: string | null;
      } | null;
      total?: {
        __typename?: "core_apimessages_Checkout_Total";
        label?: string | null;
        amount?: {
          __typename?: "core_apimessages_Money";
          amount?: string | null;
          amountCents?: number | null;
          currency?: string | null;
        } | null;
      } | null;
      guest?: {
        __typename?: "core_apimessages_CheckoutGuest";
        email?: string | null;
        firstName?: string | null;
        lastName?: string | null;
      } | null;
      shippingAddress?: {
        __typename?: "core_apimessages_Address";
        _id?: string | null;
        name?: string | null;
        streetAddress?: string | null;
        extendedAddress?: string | null;
        locality?: string | null;
        region?: string | null;
        postalCode?: string | null;
        countryCode?: string | null;
        phone?: string | null;
      } | null;
      lineItems?: Array<{
        __typename?: "core_apimessages_CheckoutLineItem";
        type?: core_apimessages_CheckoutLineItem_Type | null;
        label?: string | null;
        amount?: {
          __typename?: "core_apimessages_Money";
          amount?: string | null;
        } | null;
      } | null> | null;
    } | null;
  } | null;
};

export type PaymentMethodSelectorVisibilityHookFieldsFragment = {
  __typename?: "Checkout";
  _id?: string | null;
  source?: core_apimessages_Checkout_Source | null;
  availablePaymentMethods?: Array<{
    __typename?: "core_apimessages_CheckoutPaymentMethod";
    type?: core_apimessages_CheckoutPaymentMethod_Type | null;
  } | null> | null;
};

export type Core_Checkout_ProcessApprovedPaypalOrderMutationVariables = Exact<{
  input?: InputMaybe<Input_core_apimessages_ProcessApprovedPaypalOrderRequest>;
}>;

export type Core_Checkout_ProcessApprovedPaypalOrderMutation = {
  __typename?: "Mutation";
  processApprovedPaypalOrder?: {
    __typename?: "core_apimessages_ProcessApprovedPaypalOrderResponse";
    checkout?: { __typename?: "Checkout"; _id?: string | null } | null;
  } | null;
};

export type CheckoutActionHookFieldsFragment = {
  __typename?: "CheckoutAction";
  _id?: string | null;
  type?: core_apimessages_CheckoutAction_Type | null;
  redirectUrl?: string | null;
};

export type Core_Checkout_FinalizeApplePayPaymentMutationVariables = Exact<{
  input?: InputMaybe<Input_core_apimessages_CheckoutFinalizeDigitalWalletPaymentRequest>;
}>;

export type Core_Checkout_FinalizeApplePayPaymentMutation = {
  __typename?: "Mutation";
  finalizeDigitalWalletPayment?: {
    __typename?: "core_apimessages_CheckoutFinalizeDigitalWalletPaymentResponse";
    id?: string | null;
    checkout?: {
      __typename?: "Checkout";
      _id?: string | null;
      action?: {
        __typename?: "CheckoutAction";
        _id?: string | null;
        type?: core_apimessages_CheckoutAction_Type | null;
        redirectUrl?: string | null;
      } | null;
    } | null;
  } | null;
};

export type CheckoutApplePayLineItemFieldsFragment = {
  __typename?: "Checkout";
  _id?: string | null;
  total?: {
    __typename?: "core_apimessages_Checkout_Total";
    amount?: {
      __typename?: "core_apimessages_Money";
      amount?: string | null;
      currency?: string | null;
    } | null;
  } | null;
  lineItems?: Array<{
    __typename?: "core_apimessages_CheckoutLineItem";
    type?: core_apimessages_CheckoutLineItem_Type | null;
    label?: string | null;
    amount?: {
      __typename?: "core_apimessages_Money";
      amount?: string | null;
      amountCents?: number | null;
      currency?: string | null;
    } | null;
  } | null> | null;
};

export type CheckoutDisplayCheckoutFieldsFragment = {
  __typename?: "Checkout";
  _id?: string | null;
  id?: string | null;
  shippingAddress?: {
    __typename?: "core_apimessages_Address";
    _id?: string | null;
    isComplete?: boolean | null;
    name?: string | null;
    streetAddress?: string | null;
    extendedAddress?: string | null;
    locality?: string | null;
    region?: string | null;
    postalCode?: string | null;
    countryCode?: string | null;
    phone?: string | null;
  } | null;
  paymentMethod?: {
    __typename?: "core_apimessages_CheckoutPaymentMethod";
    isComplete?: boolean | null;
    type?: core_apimessages_CheckoutPaymentMethod_Type | null;
    affirm?: {
      __typename?: "core_apimessages_CheckoutAffirmDetails";
      financingProgramSlug?: string | null;
    } | null;
  } | null;
  orders?: Array<{
    __typename?: "core_apimessages_CheckoutOrder";
    cannotBeShippedToAddress?: boolean | null;
    quantity?: number | null;
    shippingMethod?: {
      __typename?: "core_apimessages_CheckoutShippingMethod";
      type?: core_apimessages_ShippingMethod | null;
    } | null;
    subtotal?: {
      __typename?: "core_apimessages_CheckoutOrder_Subtotal";
      listingPrice?: {
        __typename?: "core_apimessages_Money";
        amountCents?: number | null;
      } | null;
    } | null;
    listing?: {
      __typename?: "Listing";
      _id?: string | null;
      id?: string | null;
      title?: string | null;
      affirmImages?: Array<{
        __typename?: "core_apimessages_Image";
        _id?: string | null;
        source?: string | null;
      } | null> | null;
    } | null;
  } | null> | null;
  guest?: {
    __typename?: "core_apimessages_CheckoutGuest";
    isComplete?: boolean | null;
    email?: string | null;
  } | null;
  total?: {
    __typename?: "core_apimessages_Checkout_Total";
    amount?: {
      __typename?: "core_apimessages_Money";
      amountCents?: number | null;
      currency?: string | null;
    } | null;
  } | null;
  billingAddress?: {
    __typename?: "core_apimessages_Address";
    _id?: string | null;
    isComplete?: boolean | null;
    name?: string | null;
    streetAddress?: string | null;
    extendedAddress?: string | null;
    locality?: string | null;
    region?: string | null;
    postalCode?: string | null;
    countryCode?: string | null;
    phone?: string | null;
  } | null;
  lineItems?: Array<{
    __typename?: "core_apimessages_CheckoutLineItem";
    type?: core_apimessages_CheckoutLineItem_Type | null;
    amount?: {
      __typename?: "core_apimessages_Money";
      amountCents?: number | null;
      currency?: string | null;
    } | null;
  } | null> | null;
};

export type CheckoutDisplayMeFieldsFragment = {
  __typename?: "rql_Me";
  _id?: string | null;
  uuid?: string | null;
  creditCards?: Array<{
    __typename?: "core_apimessages_CreditCard";
    id?: string | null;
  } | null> | null;
};

export type CheckoutCreditCardWalletFieldsFragment = {
  __typename?: "Checkout";
  _id?: string | null;
  id?: string | null;
  shippingAddress?: {
    __typename?: "core_apimessages_Address";
    _id?: string | null;
    uuid?: string | null;
    id?: string | null;
  } | null;
  paymentMethod?: {
    __typename?: "core_apimessages_CheckoutPaymentMethod";
    isComplete?: boolean | null;
  } | null;
  creditCard?: {
    __typename?: "core_apimessages_CreditCard";
    id?: string | null;
    needsReverification?: boolean | null;
    cardType?: string | null;
    brand?: {
      __typename?: "core_apimessages_CreditCard_Brand";
      type?: core_apimessages_CreditCard_Brand_Type | null;
      name?: string | null;
    } | null;
    display?: {
      __typename?: "core_apimessages_CreditCard_Display";
      summary?: string | null;
      expirationDate?: string | null;
    } | null;
  } | null;
};

export type SubmitCheckoutVerifyShopperMutationVariables = Exact<{
  input?: InputMaybe<Input_core_apimessages_CheckoutVerifyShopperRequest>;
}>;

export type SubmitCheckoutVerifyShopperMutation = {
  __typename?: "Mutation";
  checkoutVerifyShopper?: {
    __typename?: "core_apimessages_CheckoutVerifyShopperResponse";
    paymentResultUrl?: string | null;
    adyenPaymentResult?: {
      __typename?: "core_apimessages_AdyenPaymentResult";
      action?: string | null;
    } | null;
  } | null;
};

export type SubmitGuestCheckoutVerifyShopperMutationVariables = Exact<{
  input?: InputMaybe<Input_core_apimessages_GuestCheckoutVerifyShopperRequest>;
}>;

export type SubmitGuestCheckoutVerifyShopperMutation = {
  __typename?: "Mutation";
  guestCheckoutVerifyShopper?: {
    __typename?: "core_apimessages_CheckoutVerifyShopperResponse";
    paymentResultUrl?: string | null;
    adyenPaymentResult?: {
      __typename?: "core_apimessages_AdyenPaymentResult";
      action?: string | null;
    } | null;
  } | null;
};

export type Core_Checkout_SelectCreditCardMutationVariables = Exact<{
  input?: InputMaybe<Input_core_apimessages_SelectCheckoutCreditCardRequest>;
}>;

export type Core_Checkout_SelectCreditCardMutation = {
  __typename?: "Mutation";
  selectCheckoutCreditCard?: {
    __typename?: "core_apimessages_SelectCheckoutCreditCardResponse";
    checkout?: { __typename?: "Checkout"; _id?: string | null } | null;
  } | null;
};

export type CheckoutStepErrorMessageFieldsFragment = {
  __typename?: "Checkout";
  _id?: string | null;
  paymentErrorMessage?: string | null;
};

export type CompareOtherSellersLinkDataFragment = {
  __typename?: "Listing";
  _id?: string | null;
  csp?: {
    __typename?: "CSP";
    _id?: string | null;
    id?: string | null;
    webLink?: {
      __typename?: "core_apimessages_Link";
      href?: string | null;
    } | null;
  } | null;
};

export type CreditCardEditFormFieldsFragment = {
  __typename?: "rql_Me";
  _id?: string | null;
  creditCards?: Array<{
    __typename?: "core_apimessages_CreditCard";
    id?: string | null;
    expirationMonth?: string | null;
    expirationYear?: string | null;
    cardholderName?: string | null;
    primaryForCheckout?: boolean | null;
    brand?: {
      __typename?: "core_apimessages_CreditCard_Brand";
      type?: core_apimessages_CreditCard_Brand_Type | null;
      name?: string | null;
    } | null;
    display?: {
      __typename?: "core_apimessages_CreditCard_Display";
      summary?: string | null;
      expirationDate?: string | null;
    } | null;
    address?: {
      __typename?: "core_apimessages_Address";
      _id?: string | null;
      countryCode?: string | null;
      name?: string | null;
      streetAddress?: string | null;
      extendedAddress?: string | null;
      locality?: string | null;
      region?: string | null;
      postalCode?: string | null;
      phone?: string | null;
    } | null;
  } | null> | null;
};

export type Core_CreditCardWallet_UpdateMyCreditCardMutationVariables = Exact<{
  input?: InputMaybe<Input_core_apimessages_UpdateMyCreditCardRequest>;
}>;

export type Core_CreditCardWallet_UpdateMyCreditCardMutation = {
  __typename?: "Mutation";
  updateMyCreditCard?: {
    __typename?: "core_apimessages_UpdateMyCreditCardResponse";
    creditCard?: {
      __typename?: "core_apimessages_CreditCard";
      id?: string | null;
      cardholderName?: string | null;
      primaryForCheckout?: boolean | null;
      display?: {
        __typename?: "core_apimessages_CreditCard_Display";
        summary?: string | null;
        expirationDate?: string | null;
      } | null;
      address?: {
        __typename?: "core_apimessages_Address";
        _id?: string | null;
        name?: string | null;
        streetAddress?: string | null;
        extendedAddress?: string | null;
        locality?: string | null;
        region?: string | null;
        regionName?: string | null;
        postalCode?: string | null;
        phone?: string | null;
        countryCode?: string | null;
        country?: {
          __typename?: "core_apimessages_Country";
          _id?: string | null;
          countryCode?: string | null;
        } | null;
      } | null;
    } | null;
  } | null;
};

export type CreditCardWalletCreateFormFieldsFragment = {
  __typename?: "rql_Me";
  _id?: string | null;
  shippingAddresses?: Array<{
    __typename?: "core_apimessages_Address";
    _id?: string | null;
    id?: string | null;
    uuid?: string | null;
    name?: string | null;
    streetAddress?: string | null;
    extendedAddress?: string | null;
    postalCode?: string | null;
    region?: string | null;
    regionName?: string | null;
    locality?: string | null;
    phone?: string | null;
    primary?: boolean | null;
    isComplete?: boolean | null;
    countryCode?: string | null;
    country?: {
      __typename?: "core_apimessages_Country";
      _id?: string | null;
      name?: string | null;
    } | null;
  } | null> | null;
};

export type Core_CreditCardWallet_CreateMyCreditCardMutationVariables = Exact<{
  input?: InputMaybe<Input_core_apimessages_CreateMyCreditCardRequest>;
}>;

export type Core_CreditCardWallet_CreateMyCreditCardMutation = {
  __typename?: "Mutation";
  createMyCreditCard?: {
    __typename?: "core_apimessages_CreateMyCreditCardResponse";
    creditCard?: {
      __typename?: "core_apimessages_CreditCard";
      id?: string | null;
    } | null;
  } | null;
};

export type Core_CreditCardWallet_CreateFormLayoutQueryVariables = Exact<{
  [key: string]: never;
}>;

export type Core_CreditCardWallet_CreateFormLayoutQuery = {
  __typename?: "Query";
  countries?: {
    __typename?: "core_apimessages_CountriesResponse";
    countries?: Array<{
      __typename?: "core_apimessages_Country";
      _id?: string | null;
      countryCode?: string | null;
      name?: string | null;
      subregionRequired?: boolean | null;
      subregions?: Array<{
        __typename?: "core_apimessages_Subregion";
        _id?: string | null;
        code?: string | null;
        name?: string | null;
      } | null> | null;
    } | null> | null;
  } | null;
  me?: {
    __typename?: "rql_Me";
    _id?: string | null;
    uuid?: string | null;
    shippingAddresses?: Array<{
      __typename?: "core_apimessages_Address";
      _id?: string | null;
      primary?: boolean | null;
      id?: string | null;
      uuid?: string | null;
      name?: string | null;
      streetAddress?: string | null;
      extendedAddress?: string | null;
      postalCode?: string | null;
      region?: string | null;
      regionName?: string | null;
      locality?: string | null;
      phone?: string | null;
      isComplete?: boolean | null;
      countryCode?: string | null;
      country?: {
        __typename?: "core_apimessages_Country";
        _id?: string | null;
        name?: string | null;
      } | null;
    } | null> | null;
  } | null;
};

export type Core_CreditCardWallet_EditFormLayoutQueryVariables = Exact<{
  [key: string]: never;
}>;

export type Core_CreditCardWallet_EditFormLayoutQuery = {
  __typename?: "Query";
  countries?: {
    __typename?: "core_apimessages_CountriesResponse";
    countries?: Array<{
      __typename?: "core_apimessages_Country";
      _id?: string | null;
      countryCode?: string | null;
      name?: string | null;
      subregionRequired?: boolean | null;
      subregions?: Array<{
        __typename?: "core_apimessages_Subregion";
        _id?: string | null;
        code?: string | null;
        name?: string | null;
      } | null> | null;
    } | null> | null;
  } | null;
  me?: {
    __typename?: "rql_Me";
    _id?: string | null;
    uuid?: string | null;
    creditCards?: Array<{
      __typename?: "core_apimessages_CreditCard";
      id?: string | null;
      primaryBilling?: boolean | null;
      expirationMonth?: string | null;
      expirationYear?: string | null;
      cardholderName?: string | null;
      primaryForCheckout?: boolean | null;
      brand?: {
        __typename?: "core_apimessages_CreditCard_Brand";
        type?: core_apimessages_CreditCard_Brand_Type | null;
        name?: string | null;
      } | null;
      display?: {
        __typename?: "core_apimessages_CreditCard_Display";
        summary?: string | null;
        expirationDate?: string | null;
      } | null;
      address?: {
        __typename?: "core_apimessages_Address";
        _id?: string | null;
        countryCode?: string | null;
        name?: string | null;
        streetAddress?: string | null;
        extendedAddress?: string | null;
        locality?: string | null;
        region?: string | null;
        postalCode?: string | null;
        phone?: string | null;
      } | null;
    } | null> | null;
  } | null;
};

export type CreditCardWalletLayoutFieldsFragment = {
  __typename?: "rql_Me";
  _id?: string | null;
  uuid?: string | null;
  creditCards?: Array<{
    __typename?: "core_apimessages_CreditCard";
    id?: string | null;
    primaryForCheckout?: boolean | null;
    display?: {
      __typename?: "core_apimessages_CreditCard_Display";
      summary?: string | null;
      expirationDate?: string | null;
    } | null;
    brand?: {
      __typename?: "core_apimessages_CreditCard_Brand";
      type?: core_apimessages_CreditCard_Brand_Type | null;
      name?: string | null;
    } | null;
    address?: {
      __typename?: "core_apimessages_Address";
      _id?: string | null;
      name?: string | null;
      streetAddress?: string | null;
      extendedAddress?: string | null;
      locality?: string | null;
      regionName?: string | null;
      postalCode?: string | null;
      country?: {
        __typename?: "core_apimessages_Country";
        _id?: string | null;
        countryCode?: string | null;
      } | null;
    } | null;
  } | null> | null;
};

export type Core_CreditCardWallet_IndexQueryVariables = Exact<{
  [key: string]: never;
}>;

export type Core_CreditCardWallet_IndexQuery = {
  __typename?: "Query";
  me?: {
    __typename?: "rql_Me";
    _id?: string | null;
    uuid?: string | null;
    creditCards?: Array<{
      __typename?: "core_apimessages_CreditCard";
      id?: string | null;
      primaryForCheckout?: boolean | null;
      display?: {
        __typename?: "core_apimessages_CreditCard_Display";
        summary?: string | null;
        expirationDate?: string | null;
      } | null;
      brand?: {
        __typename?: "core_apimessages_CreditCard_Brand";
        type?: core_apimessages_CreditCard_Brand_Type | null;
        name?: string | null;
      } | null;
      address?: {
        __typename?: "core_apimessages_Address";
        _id?: string | null;
        name?: string | null;
        streetAddress?: string | null;
        extendedAddress?: string | null;
        locality?: string | null;
        regionName?: string | null;
        postalCode?: string | null;
        country?: {
          __typename?: "core_apimessages_Country";
          _id?: string | null;
          countryCode?: string | null;
        } | null;
      } | null;
    } | null> | null;
  } | null;
};

export type CreditCardWalletRowFieldsFragment = {
  __typename?: "rql_Me";
  _id?: string | null;
  uuid?: string | null;
  creditCards?: Array<{
    __typename?: "core_apimessages_CreditCard";
    id?: string | null;
    primaryForCheckout?: boolean | null;
    display?: {
      __typename?: "core_apimessages_CreditCard_Display";
      summary?: string | null;
      expirationDate?: string | null;
    } | null;
    brand?: {
      __typename?: "core_apimessages_CreditCard_Brand";
      type?: core_apimessages_CreditCard_Brand_Type | null;
      name?: string | null;
    } | null;
    address?: {
      __typename?: "core_apimessages_Address";
      _id?: string | null;
      name?: string | null;
      streetAddress?: string | null;
      extendedAddress?: string | null;
      locality?: string | null;
      regionName?: string | null;
      postalCode?: string | null;
      country?: {
        __typename?: "core_apimessages_Country";
        _id?: string | null;
        countryCode?: string | null;
      } | null;
    } | null;
  } | null> | null;
};

export type CreditCardWalletRowSummaryFieldsFragment = {
  __typename?: "rql_Me";
  _id?: string | null;
  uuid?: string | null;
  creditCards?: Array<{
    __typename?: "core_apimessages_CreditCard";
    id?: string | null;
    primaryForCheckout?: boolean | null;
    display?: {
      __typename?: "core_apimessages_CreditCard_Display";
      summary?: string | null;
      expirationDate?: string | null;
    } | null;
    brand?: {
      __typename?: "core_apimessages_CreditCard_Brand";
      type?: core_apimessages_CreditCard_Brand_Type | null;
      name?: string | null;
    } | null;
    address?: {
      __typename?: "core_apimessages_Address";
      _id?: string | null;
      name?: string | null;
      streetAddress?: string | null;
      extendedAddress?: string | null;
      locality?: string | null;
      regionName?: string | null;
      postalCode?: string | null;
      country?: {
        __typename?: "core_apimessages_Country";
        _id?: string | null;
        countryCode?: string | null;
      } | null;
    } | null;
  } | null> | null;
};

export type FeaturedListingWithPriceFragment = {
  __typename?: "Listing";
  _id?: string | null;
  bumped?: boolean | null;
  title?: string | null;
  slug?: string | null;
  description?: string | null;
  state?: string | null;
  id?: string | null;
  offersEnabled?: boolean | null;
  inventory?: number | null;
  hasInventory?: boolean | null;
  maxBuyerQuantity?: number | null;
  make?: string | null;
  model?: string | null;
  usOutlet?: boolean | null;
  currency?: string | null;
  soldAsIs?: boolean | null;
  sellerId?: string | null;
  listingType?: core_apimessages_Listing_ListingType | null;
  stateDescription?: string | null;
  taxIncluded?: boolean | null;
  taxIncludedHint?: string | null;
  acceptedPaymentMethods?: Array<string | null> | null;
  isBuyerOfferEligible?: boolean | null;
  brandSlug?: string | null;
  categoryRootUuid?: string | null;
  categoryUuids?: Array<string | null> | null;
  bumpEligible?: boolean | null;
  shopId?: string | null;
  adyenCheckoutPaymentMethodsConfig?: string | null;
  price?: {
    __typename?: "core_apimessages_Money";
    amount?: string | null;
    amountCents?: number | null;
  } | null;
  condition?: {
    __typename?: "core_apimessages_Condition";
    displayName?: string | null;
    conditionSlug?: string | null;
    conditionUuid?: string | null;
  } | null;
  largeImages?: Array<{
    __typename?: "core_apimessages_Image";
    _id?: string | null;
    source?: string | null;
  } | null> | null;
  supersizeImages?: Array<{
    __typename?: "core_apimessages_Image";
    _id?: string | null;
    source?: string | null;
  } | null> | null;
  cardSquareImages?: Array<{
    __typename?: "core_apimessages_Image";
    _id?: string | null;
    source?: string | null;
  } | null> | null;
  video?: {
    __typename?: "core_apimessages_Video";
    youtubeVideoId?: string | null;
    empty?: boolean | null;
  } | null;
  csp?: {
    __typename?: "CSP";
    _id?: string | null;
    id?: string | null;
    slug?: string | null;
    videos?: {
      __typename?: "core_apimessages_CSPVideo";
      videoLinks?: Array<{
        __typename?: "core_apimessages_VideoLink";
        youtubeVideoId?: string | null;
      } | null> | null;
    } | null;
    webLink?: {
      __typename?: "core_apimessages_Link";
      href?: string | null;
    } | null;
    brand?: {
      __typename?: "Brand";
      _id?: string | null;
      slug?: string | null;
    } | null;
  } | null;
  seller?: {
    __typename?: "User";
    _id?: string | null;
    id?: string | null;
    uuid?: string | null;
    feedbackSummary?: {
      __typename?: "core_apimessages_FeedbackSummary";
      rollingRatingPercentage?: number | null;
      receivedCount?: number | null;
    } | null;
  } | null;
  shop?: {
    __typename?: "Shop";
    _id?: string | null;
    name?: string | null;
    slug?: string | null;
    preferredSeller?: boolean | null;
    quickShipper?: boolean | null;
    quickResponder?: boolean | null;
    paymentPolicy?: string | null;
    createdAt?: any | null;
    id?: string | null;
    address?: {
      __typename?: "core_apimessages_Address";
      _id?: string | null;
      displayLocation?: string | null;
      countryCode?: string | null;
      country?: {
        __typename?: "core_apimessages_Country";
        _id?: string | null;
        name?: string | null;
      } | null;
    } | null;
    orders?: {
      __typename?: "reverb_search_SearchResponse";
      total?: number | null;
    } | null;
    returnPolicy?: {
      __typename?: "core_apimessages_ReturnPolicy";
      newReturnWindowDays?: number | null;
      usedReturnWindowDays?: number | null;
      _id?: string | null;
    } | null;
    freeDomesticShippingRate?: {
      __typename?: "core_apimessages_ShippingRates";
      regionCode?: string | null;
      freeShippingThreshold?: {
        __typename?: "core_apimessages_Money";
        amountCents?: number | null;
        currency?: string | null;
      } | null;
    } | null;
  } | null;
  shipping?: {
    __typename?: "core_apimessages_ShippingPrices";
    localPickupOnly?: boolean | null;
    freeExpeditedShipping?: boolean | null;
    localPickup?: boolean | null;
    shippingPrices?: Array<{
      __typename?: "core_apimessages_ShippingPrice";
      _id?: string | null;
      shippingMethod?: core_apimessages_ShippingMethod | null;
      carrierCalculated?: boolean | null;
      destinationPostalCodeNeeded?: boolean | null;
      regional?: boolean | null;
      postalCode?: string | null;
      rate?: {
        __typename?: "core_apimessages_Money";
        amountCents?: number | null;
        amount?: string | null;
        currency?: string | null;
        display?: string | null;
      } | null;
    } | null> | null;
  } | null;
  signals?: Array<{
    __typename?: "reverb_signals_Signal";
    name?: reverb_signals_Signal_Name | null;
    group?: reverb_signals_Signal_Group | null;
    title?: string | null;
    subtitle?: string | null;
    icon?: string | null;
    iconDark?: string | null;
    tooltipText?: string | null;
    link?: {
      __typename?: "reverb_signals_SignalLink";
      text?: string | null;
      url?: string | null;
    } | null;
  } | null> | null;
  pricing?: {
    __typename?: "core_apimessages_ListingPricing";
    originalPriceDescription?: string | null;
    buyerPrice?: {
      __typename?: "core_apimessages_Money";
      amount?: string | null;
      amountCents?: number | null;
      currency?: string | null;
      display?: string | null;
    } | null;
    originalPrice?: {
      __typename?: "core_apimessages_Money";
      display?: string | null;
    } | null;
    ribbon?: {
      __typename?: "core_apimessages_Ribbon";
      display?: string | null;
      reason?: core_apimessages_Ribbon_Reason | null;
    } | null;
    typicalNewPriceDisplay?: {
      __typename?: "core_apimessages_TypicalNewPriceDisplay";
      amountDisplay?: string | null;
    } | null;
  } | null;
  preorderInfo?: {
    __typename?: "core_apimessages_PreorderInfo";
    onPreorder?: boolean | null;
    estimatedShipDate?: {
      __typename?: "google_protobuf_Timestamp";
      seconds?: number | null;
    } | null;
  } | null;
  publishedAt?: {
    __typename?: "google_protobuf_Timestamp";
    seconds?: number | null;
  } | null;
  counts?: {
    __typename?: "core_apimessages_ListingCounts";
    id?: string | null;
    viewsDisplay?: string | null;
    offersDisplay?: string | null;
    watchersDisplay?: string | null;
    downloadCountDisplay?: string | null;
  } | null;
  digitalDetails?: {
    __typename?: "core_apimessages_ListingDigitalDetails";
    variants?: Array<{
      __typename?: "core_apimessages_ListingDigitalVariant";
      id?: string | null;
      downloadText?: string | null;
      fileUrl?: string | null;
      fileSize?: string | null;
      operatingSystems?: Array<string | null> | null;
      fileTypes?: Array<string | null> | null;
    } | null> | null;
    supportedFormats?: Array<{
      __typename?: "core_apimessages_ListingSupportedFormat";
      operatingSystem?: string | null;
      fileTypes?: Array<string | null> | null;
    } | null> | null;
  } | null;
  estimatedMonthlyPaymentPlan?: {
    __typename?: "core_apimessages_EstimatedMonthlyPayment";
    financingProgramSlug?: string | null;
  } | null;
  certifiedPreOwned?: {
    __typename?: "core_apimessages_ListingCertifiedPreOwned";
    title?: string | null;
    description?: string | null;
    tooltipDescription?: string | null;
    badgeIconUrl?: string | null;
    brandName?: string | null;
    pageUrl?: string | null;
    lightModeBrandIconUrl?: string | null;
    darkModeBrandIconUrl?: string | null;
  } | null;
  categories?: Array<{
    __typename?: "Category";
    _id?: string | null;
    slug?: string | null;
    rootSlug?: string | null;
  } | null> | null;
  sale?: {
    __typename?: "core_apimessages_Sale";
    _id?: string | null;
    id?: string | null;
    code?: string | null;
    buyerIneligibilityReason?: string | null;
  } | null;
  expressPay?: {
    __typename?: "core_apimessages_ListingExpressPay";
    _id?: string | null;
    checkoutBundlingId?: string | null;
    paypal?: {
      __typename?: "core_apimessages_CheckoutPaypalDetails";
      currency?: string | null;
    } | null;
    estimatedTotal?: {
      __typename?: "core_apimessages_Money";
      amount?: string | null;
      currency?: string | null;
    } | null;
    googlePay?: {
      __typename?: "core_apimessages_CheckoutGooglePayDetails";
      merchantId?: string | null;
      merchantName?: string | null;
      gatewayMerchantId?: string | null;
      cardNetworks?: Array<core_apimessages_CheckoutGooglePayDetails_CardNetwork | null> | null;
    } | null;
  } | null;
};

export type FeaturedListingCSPFragment = {
  __typename?: "CSP";
  _id?: string | null;
  id?: string | null;
  inventory?: {
    __typename?: "core_apimessages_CSPInventory";
    newTotal?: number | null;
    usedTotal?: number | null;
  } | null;
  categories?: Array<{
    __typename?: "Category";
    _id?: string | null;
    rootSlug?: string | null;
    slug?: string | null;
  } | null> | null;
};

export type Csp_Featured_ListingQueryVariables = Exact<{
  id?: InputMaybe<Scalars["String"]["input"]>;
}>;

export type Csp_Featured_ListingQuery = {
  __typename?: "Query";
  listing?: {
    __typename?: "Listing";
    _id?: string | null;
    id?: string | null;
    bumped?: boolean | null;
    title?: string | null;
    sellerId?: string | null;
    watching?: boolean | null;
    acceptedPaymentMethods?: Array<string | null> | null;
    slug?: string | null;
    description?: string | null;
    brandSlug?: string | null;
    categoryRootUuid?: string | null;
    make?: string | null;
    categoryUuids?: Array<string | null> | null;
    state?: string | null;
    listingType?: core_apimessages_Listing_ListingType | null;
    bumpEligible?: boolean | null;
    shopId?: string | null;
    inventory?: number | null;
    soldAsIs?: boolean | null;
    currency?: string | null;
    usOutlet?: boolean | null;
    offersEnabled?: boolean | null;
    hasInventory?: boolean | null;
    maxBuyerQuantity?: number | null;
    model?: string | null;
    stateDescription?: string | null;
    taxIncluded?: boolean | null;
    taxIncludedHint?: string | null;
    isBuyerOfferEligible?: boolean | null;
    adyenCheckoutPaymentMethodsConfig?: string | null;
    price?: {
      __typename?: "core_apimessages_Money";
      amount?: string | null;
      amountCents?: number | null;
    } | null;
    watchThumbnails?: Array<{
      __typename?: "core_apimessages_Image";
      _id?: string | null;
      source?: string | null;
    } | null> | null;
    condition?: {
      __typename?: "core_apimessages_Condition";
      displayName?: string | null;
      conditionSlug?: string | null;
      conditionUuid?: string | null;
    } | null;
    categories?: Array<{
      __typename?: "Category";
      _id?: string | null;
      slug?: string | null;
      rootSlug?: string | null;
    } | null> | null;
    csp?: {
      __typename?: "CSP";
      _id?: string | null;
      id?: string | null;
      slug?: string | null;
      brand?: {
        __typename?: "Brand";
        _id?: string | null;
        slug?: string | null;
      } | null;
      videos?: {
        __typename?: "core_apimessages_CSPVideo";
        videoLinks?: Array<{
          __typename?: "core_apimessages_VideoLink";
          youtubeVideoId?: string | null;
        } | null> | null;
      } | null;
      webLink?: {
        __typename?: "core_apimessages_Link";
        href?: string | null;
      } | null;
    } | null;
    pricing?: {
      __typename?: "core_apimessages_ListingPricing";
      originalPriceDescription?: string | null;
      buyerPrice?: {
        __typename?: "core_apimessages_Money";
        amount?: string | null;
        currency?: string | null;
        amountCents?: number | null;
        display?: string | null;
      } | null;
      originalPrice?: {
        __typename?: "core_apimessages_Money";
        display?: string | null;
      } | null;
      ribbon?: {
        __typename?: "core_apimessages_Ribbon";
        display?: string | null;
        reason?: core_apimessages_Ribbon_Reason | null;
      } | null;
      typicalNewPriceDisplay?: {
        __typename?: "core_apimessages_TypicalNewPriceDisplay";
        amountDisplay?: string | null;
      } | null;
    } | null;
    publishedAt?: {
      __typename?: "google_protobuf_Timestamp";
      seconds?: number | null;
    } | null;
    sale?: {
      __typename?: "core_apimessages_Sale";
      _id?: string | null;
      id?: string | null;
      code?: string | null;
      buyerIneligibilityReason?: string | null;
    } | null;
    shipping?: {
      __typename?: "core_apimessages_ShippingPrices";
      freeExpeditedShipping?: boolean | null;
      localPickupOnly?: boolean | null;
      localPickup?: boolean | null;
      shippingPrices?: Array<{
        __typename?: "core_apimessages_ShippingPrice";
        _id?: string | null;
        shippingMethod?: core_apimessages_ShippingMethod | null;
        carrierCalculated?: boolean | null;
        destinationPostalCodeNeeded?: boolean | null;
        regional?: boolean | null;
        postalCode?: string | null;
        rate?: {
          __typename?: "core_apimessages_Money";
          amount?: string | null;
          amountCents?: number | null;
          currency?: string | null;
          display?: string | null;
        } | null;
      } | null> | null;
    } | null;
    certifiedPreOwned?: {
      __typename?: "core_apimessages_ListingCertifiedPreOwned";
      title?: string | null;
      badgeIconUrl?: string | null;
      description?: string | null;
      tooltipDescription?: string | null;
      brandName?: string | null;
      pageUrl?: string | null;
      lightModeBrandIconUrl?: string | null;
      darkModeBrandIconUrl?: string | null;
    } | null;
    shop?: {
      __typename?: "Shop";
      _id?: string | null;
      name?: string | null;
      slug?: string | null;
      preferredSeller?: boolean | null;
      quickShipper?: boolean | null;
      quickResponder?: boolean | null;
      paymentPolicy?: string | null;
      createdAt?: any | null;
      id?: string | null;
      address?: {
        __typename?: "core_apimessages_Address";
        _id?: string | null;
        countryCode?: string | null;
        displayLocation?: string | null;
        country?: {
          __typename?: "core_apimessages_Country";
          _id?: string | null;
          name?: string | null;
        } | null;
      } | null;
      returnPolicy?: {
        __typename?: "core_apimessages_ReturnPolicy";
        _id?: string | null;
        newReturnWindowDays?: number | null;
        usedReturnWindowDays?: number | null;
      } | null;
      orders?: {
        __typename?: "reverb_search_SearchResponse";
        total?: number | null;
      } | null;
      freeDomesticShippingRate?: {
        __typename?: "core_apimessages_ShippingRates";
        regionCode?: string | null;
        freeShippingThreshold?: {
          __typename?: "core_apimessages_Money";
          amountCents?: number | null;
          currency?: string | null;
        } | null;
      } | null;
    } | null;
    largeImages?: Array<{
      __typename?: "core_apimessages_Image";
      _id?: string | null;
      source?: string | null;
    } | null> | null;
    supersizeImages?: Array<{
      __typename?: "core_apimessages_Image";
      _id?: string | null;
      source?: string | null;
    } | null> | null;
    cardSquareImages?: Array<{
      __typename?: "core_apimessages_Image";
      _id?: string | null;
      source?: string | null;
    } | null> | null;
    video?: {
      __typename?: "core_apimessages_Video";
      youtubeVideoId?: string | null;
      empty?: boolean | null;
    } | null;
    seller?: {
      __typename?: "User";
      _id?: string | null;
      id?: string | null;
      uuid?: string | null;
      feedbackSummary?: {
        __typename?: "core_apimessages_FeedbackSummary";
        rollingRatingPercentage?: number | null;
        receivedCount?: number | null;
      } | null;
    } | null;
    signals?: Array<{
      __typename?: "reverb_signals_Signal";
      name?: reverb_signals_Signal_Name | null;
      group?: reverb_signals_Signal_Group | null;
      title?: string | null;
      subtitle?: string | null;
      icon?: string | null;
      iconDark?: string | null;
      tooltipText?: string | null;
      link?: {
        __typename?: "reverb_signals_SignalLink";
        text?: string | null;
        url?: string | null;
      } | null;
    } | null> | null;
    preorderInfo?: {
      __typename?: "core_apimessages_PreorderInfo";
      onPreorder?: boolean | null;
      estimatedShipDate?: {
        __typename?: "google_protobuf_Timestamp";
        seconds?: number | null;
      } | null;
    } | null;
    counts?: {
      __typename?: "core_apimessages_ListingCounts";
      id?: string | null;
      viewsDisplay?: string | null;
      offersDisplay?: string | null;
      watchersDisplay?: string | null;
      downloadCountDisplay?: string | null;
    } | null;
    digitalDetails?: {
      __typename?: "core_apimessages_ListingDigitalDetails";
      variants?: Array<{
        __typename?: "core_apimessages_ListingDigitalVariant";
        id?: string | null;
        downloadText?: string | null;
        fileUrl?: string | null;
        fileSize?: string | null;
        operatingSystems?: Array<string | null> | null;
        fileTypes?: Array<string | null> | null;
      } | null> | null;
      supportedFormats?: Array<{
        __typename?: "core_apimessages_ListingSupportedFormat";
        operatingSystem?: string | null;
        fileTypes?: Array<string | null> | null;
      } | null> | null;
    } | null;
    estimatedMonthlyPaymentPlan?: {
      __typename?: "core_apimessages_EstimatedMonthlyPayment";
      financingProgramSlug?: string | null;
    } | null;
    expressPay?: {
      __typename?: "core_apimessages_ListingExpressPay";
      _id?: string | null;
      checkoutBundlingId?: string | null;
      paypal?: {
        __typename?: "core_apimessages_CheckoutPaypalDetails";
        currency?: string | null;
      } | null;
      estimatedTotal?: {
        __typename?: "core_apimessages_Money";
        amount?: string | null;
        currency?: string | null;
      } | null;
      googlePay?: {
        __typename?: "core_apimessages_CheckoutGooglePayDetails";
        merchantId?: string | null;
        merchantName?: string | null;
        gatewayMerchantId?: string | null;
        cardNetworks?: Array<core_apimessages_CheckoutGooglePayDetails_CardNetwork | null> | null;
      } | null;
    } | null;
  } | null;
};

export type CSP2HeaderDataFragment = {
  __typename?: "CSP";
  _id?: string | null;
  id?: string | null;
  title?: string | null;
  brand?: {
    __typename?: "Brand";
    _id?: string | null;
    name?: string | null;
    webLink?: {
      __typename?: "core_apimessages_Link";
      href?: string | null;
    } | null;
  } | null;
  categories?: Array<{
    __typename?: "Category";
    _id?: string | null;
    name?: string | null;
    cmsUrl?: {
      __typename?: "core_apimessages_Link";
      href?: string | null;
    } | null;
  } | null> | null;
  cspImage?: Array<{
    __typename?: "core_apimessages_Image";
    _id?: string | null;
    source?: string | null;
  } | null> | null;
  specs?: {
    __typename?: "core_apimessages_CSPSpec";
    productSpecs?: Array<{
      __typename?: "core_apimessages_ProductSpec";
      _id?: string | null;
      type?: core_apimessages_ProductSpec_Type | null;
      name?: string | null;
      value?: string | null;
      path?: string | null;
    } | null> | null;
  } | null;
};

export type Core_Csp_HeaderQueryVariables = Exact<{
  soldListingId?: InputMaybe<Scalars["String"]["input"]>;
}>;

export type Core_Csp_HeaderQuery = {
  __typename?: "Query";
  listing?: {
    __typename?: "Listing";
    _id?: string | null;
    title?: string | null;
    images?: Array<{
      __typename?: "core_apimessages_Image";
      _id?: string | null;
      source?: string | null;
    } | null> | null;
  } | null;
};

export type CSPReviewJumplinksDataFragment = {
  __typename?: "CSP";
  _id?: string | null;
  productReviewSearch?: {
    __typename?: "rql_CSPProductReviewSearchResponse";
    stats?: {
      __typename?: "reverb_search_ProductReviewStats";
      total?: number | null;
      averageRating?: number | null;
    } | null;
  } | null;
};

export type ListingDataFragment = {
  __typename?: "Listing";
  _id?: string | null;
  upc?: string | null;
  finish?: string | null;
  model?: string | null;
  shopUuid?: string | null;
  id?: string | null;
  title?: string | null;
  slug?: string | null;
  listingType?: core_apimessages_Listing_ListingType | null;
  make?: string | null;
  state?: string | null;
  stateDescription?: string | null;
  bumped?: boolean | null;
  watching?: boolean | null;
  soldAsIs?: boolean | null;
  usOutlet?: boolean | null;
  sellerId?: string | null;
  offersEnabled?: boolean | null;
  isBuyerOfferEligible?: boolean | null;
  currency?: string | null;
  brandSlug?: string | null;
  categoryRootUuid?: string | null;
  categoryUuids?: Array<string | null> | null;
  bumpEligible?: boolean | null;
  shopId?: string | null;
  inventory?: number | null;
  acceptedPaymentMethods?: Array<string | null> | null;
  seller?: {
    __typename?: "User";
    _id?: string | null;
    feedbackSummary?: {
      __typename?: "core_apimessages_FeedbackSummary";
      receivedCount?: number | null;
      rollingRatingPercentage?: number | null;
    } | null;
  } | null;
  shop?: {
    __typename?: "Shop";
    _id?: string | null;
    name?: string | null;
    slug?: string | null;
    preferredSeller?: boolean | null;
    quickShipper?: boolean | null;
    quickResponder?: boolean | null;
    address?: {
      __typename?: "core_apimessages_Address";
      _id?: string | null;
      displayLocation?: string | null;
      locality?: string | null;
      region?: string | null;
      countryCode?: string | null;
      country?: {
        __typename?: "core_apimessages_Country";
        _id?: string | null;
        countryCode?: string | null;
        name?: string | null;
      } | null;
    } | null;
    returnPolicy?: {
      __typename?: "core_apimessages_ReturnPolicy";
      usedReturnWindowDays?: number | null;
      newReturnWindowDays?: number | null;
      _id?: string | null;
    } | null;
  } | null;
  publishedAt?: {
    __typename?: "google_protobuf_Timestamp";
    seconds?: number | null;
  } | null;
  condition?: {
    __typename?: "core_apimessages_Condition";
    displayName?: string | null;
    conditionSlug?: string | null;
    conditionUuid?: string | null;
  } | null;
  pricing?: {
    __typename?: "core_apimessages_ListingPricing";
    buyerPrice?: {
      __typename?: "core_apimessages_Money";
      display?: string | null;
      currency?: string | null;
      amount?: string | null;
      amountCents?: number | null;
    } | null;
    originalPrice?: {
      __typename?: "core_apimessages_Money";
      display?: string | null;
    } | null;
    ribbon?: {
      __typename?: "core_apimessages_Ribbon";
      display?: string | null;
      reason?: core_apimessages_Ribbon_Reason | null;
    } | null;
  } | null;
  images?: Array<{
    __typename?: "core_apimessages_Image";
    source?: string | null;
  } | null> | null;
  shipping?: {
    __typename?: "core_apimessages_ShippingPrices";
    freeExpeditedShipping?: boolean | null;
    localPickupOnly?: boolean | null;
    localPickup?: boolean | null;
    shippingPrices?: Array<{
      __typename?: "core_apimessages_ShippingPrice";
      _id?: string | null;
      shippingMethod?: core_apimessages_ShippingMethod | null;
      carrierCalculated?: boolean | null;
      destinationPostalCodeNeeded?: boolean | null;
      regional?: boolean | null;
      postalCode?: string | null;
      rate?: {
        __typename?: "core_apimessages_Money";
        amount?: string | null;
        amountCents?: number | null;
        currency?: string | null;
        display?: string | null;
      } | null;
    } | null> | null;
  } | null;
  watchThumbnails?: Array<{
    __typename?: "core_apimessages_Image";
    _id?: string | null;
    source?: string | null;
  } | null> | null;
  priceRecommendation?: {
    __typename?: "reverb_pricing_PriceRecommendation";
    priceMiddle?: {
      __typename?: "reverb_pricing_Money";
      amountCents?: number | null;
      currency?: string | null;
    } | null;
  } | null;
  certifiedPreOwned?: {
    __typename?: "core_apimessages_ListingCertifiedPreOwned";
    title?: string | null;
    badgeIconUrl?: string | null;
  } | null;
  categories?: Array<{
    __typename?: "Category";
    _id?: string | null;
    slug?: string | null;
    rootSlug?: string | null;
  } | null> | null;
  csp?: {
    __typename?: "CSP";
    _id?: string | null;
    id?: string | null;
    slug?: string | null;
    brand?: {
      __typename?: "Brand";
      _id?: string | null;
      slug?: string | null;
    } | null;
  } | null;
  sale?: {
    __typename?: "core_apimessages_Sale";
    _id?: string | null;
    id?: string | null;
    code?: string | null;
    buyerIneligibilityReason?: string | null;
  } | null;
};

export type Csp_Filters_And_ListingsQueryQueryVariables = Exact<{
  acceptsAffirm?: InputMaybe<Scalars["Boolean"]["input"]>;
  acceptsGiftCards?: InputMaybe<Scalars["Boolean"]["input"]>;
  acceptsOffers?: InputMaybe<Scalars["Boolean"]["input"]>;
  acceptsPaymentPlans?: InputMaybe<Scalars["Boolean"]["input"]>;
  aggs?: InputMaybe<
    | Array<InputMaybe<reverb_search_ListingsSearchRequest_Aggregation>>
    | InputMaybe<reverb_search_ListingsSearchRequest_Aggregation>
  >;
  applyProximityBoost?: InputMaybe<Scalars["Boolean"]["input"]>;
  boostedItemRegionCode?: InputMaybe<Scalars["String"]["input"]>;
  bumpLimit?: InputMaybe<Scalars["Int"]["input"]>;
  dealsAndSteals?: InputMaybe<Scalars["Boolean"]["input"]>;
  decades?: InputMaybe<
    | Array<InputMaybe<Scalars["String"]["input"]>>
    | InputMaybe<Scalars["String"]["input"]>
  >;
  canonicalFinishes?: InputMaybe<
    | Array<InputMaybe<Scalars["String"]["input"]>>
    | InputMaybe<Scalars["String"]["input"]>
  >;
  categorySlugs?: InputMaybe<
    | Array<InputMaybe<Scalars["String"]["input"]>>
    | InputMaybe<Scalars["String"]["input"]>
  >;
  combinedShipping?: InputMaybe<Scalars["Boolean"]["input"]>;
  conditionSlugs?: InputMaybe<
    | Array<InputMaybe<Scalars["String"]["input"]>>
    | InputMaybe<Scalars["String"]["input"]>
  >;
  cspSlug?: InputMaybe<Scalars["String"]["input"]>;
  excludeLocalPickupOnly?: InputMaybe<Scalars["Boolean"]["input"]>;
  fallbackToEverywhereElse?: InputMaybe<Scalars["Boolean"]["input"]>;
  freeExpeditedShipping?: InputMaybe<Scalars["Boolean"]["input"]>;
  freeShipping?: InputMaybe<Scalars["Boolean"]["input"]>;
  fretboardMaterial?: InputMaybe<Scalars["String"]["input"]>;
  includeFullFinancingFields: Scalars["Boolean"]["input"];
  itemRegion?: InputMaybe<Scalars["String"]["input"]>;
  itemRegionRelation?: InputMaybe<reverb_search_ListingItemRegionRelation>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  localPickup?: InputMaybe<Scalars["Boolean"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  onSale?: InputMaybe<Scalars["Boolean"]["input"]>;
  preferredSeller?: InputMaybe<Scalars["Boolean"]["input"]>;
  priceMin?: InputMaybe<Scalars["String"]["input"]>;
  priceMax?: InputMaybe<Scalars["String"]["input"]>;
  query?: InputMaybe<Scalars["String"]["input"]>;
  shippingRegionCode?: InputMaybe<Scalars["String"]["input"]>;
  shippingRegionCodes?: InputMaybe<
    | Array<InputMaybe<Scalars["String"]["input"]>>
    | InputMaybe<Scalars["String"]["input"]>
  >;
  shouldHideBumpAndSponsoredListings: Scalars["Boolean"]["input"];
  shouldSkipTracking: Scalars["Boolean"]["input"];
  sort?: InputMaybe<reverb_search_ListingsSearchRequest_Sort>;
  sortSlug?: InputMaybe<Scalars["String"]["input"]>;
  sponsoredConditionSlugs?: InputMaybe<
    | Array<InputMaybe<Scalars["String"]["input"]>>
    | InputMaybe<Scalars["String"]["input"]>
  >;
  traitValues?: InputMaybe<
    | Array<InputMaybe<Scalars["String"]["input"]>>
    | InputMaybe<Scalars["String"]["input"]>
  >;
  withProximityFilter?: InputMaybe<Input_reverb_search_ProximityFilterRequest>;
  yearMax?: InputMaybe<Scalars["String"]["input"]>;
  yearMin?: InputMaybe<Scalars["String"]["input"]>;
  userCurrency?: InputMaybe<Scalars["String"]["input"]>;
}>;

export type Csp_Filters_And_ListingsQueryQuery = {
  __typename?: "Query";
  allListings?: {
    __typename?: "reverb_search_SearchResponse";
    total?: number | null;
    offset?: number | null;
    filters?: Array<{
      __typename?: "reverb_search_Filter";
      name?: string | null;
      key?: string | null;
      aggregationName?: string | null;
      widgetType?: reverb_search_Filter_WidgetType | null;
      options?: Array<{
        __typename?: "reverb_search_FilterOption";
        name?: string | null;
        selected?: boolean | null;
        autoselected?: boolean | null;
        paramName?: string | null;
        setValues?: Array<string | null> | null;
        unsetValues?: Array<string | null> | null;
        all?: boolean | null;
        optionValue?: string | null;
        trackingValue?: string | null;
        count?: {
          __typename?: "google_protobuf_Int32Value";
          value?: number | null;
        } | null;
        subFilter?: {
          __typename?: "reverb_search_Filter";
          widgetType?: reverb_search_Filter_WidgetType | null;
          options?: Array<{
            __typename?: "reverb_search_FilterOption";
            name?: string | null;
            selected?: boolean | null;
            autoselected?: boolean | null;
            paramName?: string | null;
            setValues?: Array<string | null> | null;
            unsetValues?: Array<string | null> | null;
            all?: boolean | null;
            optionValue?: string | null;
            trackingValue?: string | null;
            count?: {
              __typename?: "google_protobuf_Int32Value";
              value?: number | null;
            } | null;
            subFilter?: {
              __typename?: "reverb_search_Filter";
              widgetType?: reverb_search_Filter_WidgetType | null;
              options?: Array<{
                __typename?: "reverb_search_FilterOption";
                name?: string | null;
                selected?: boolean | null;
                autoselected?: boolean | null;
                paramName?: string | null;
                setValues?: Array<string | null> | null;
                unsetValues?: Array<string | null> | null;
                all?: boolean | null;
                optionValue?: string | null;
                trackingValue?: string | null;
                count?: {
                  __typename?: "google_protobuf_Int32Value";
                  value?: number | null;
                } | null;
                subFilter?: {
                  __typename?: "reverb_search_Filter";
                  widgetType?: reverb_search_Filter_WidgetType | null;
                  options?: Array<{
                    __typename?: "reverb_search_FilterOption";
                    name?: string | null;
                    selected?: boolean | null;
                    autoselected?: boolean | null;
                    paramName?: string | null;
                    setValues?: Array<string | null> | null;
                    unsetValues?: Array<string | null> | null;
                    all?: boolean | null;
                    optionValue?: string | null;
                    trackingValue?: string | null;
                    count?: {
                      __typename?: "google_protobuf_Int32Value";
                      value?: number | null;
                    } | null;
                  } | null> | null;
                } | null;
              } | null> | null;
            } | null;
          } | null> | null;
        } | null;
      } | null> | null;
    } | null> | null;
    listings?: Array<{
      __typename?: "Listing";
      _id?: string | null;
      upc?: string | null;
      finish?: string | null;
      model?: string | null;
      shopUuid?: string | null;
      id?: string | null;
      usOutlet?: boolean | null;
      bumped?: boolean | null;
      categoryUuids?: Array<string | null> | null;
      slug?: string | null;
      title?: string | null;
      description?: string | null;
      listingType?: core_apimessages_Listing_ListingType | null;
      watching?: boolean | null;
      state?: string | null;
      stateDescription?: string | null;
      brandSlug?: string | null;
      categoryRootUuid?: string | null;
      make?: string | null;
      bumpEligible?: boolean | null;
      shopId?: string | null;
      inventory?: number | null;
      soldAsIs?: boolean | null;
      acceptedPaymentMethods?: Array<string | null> | null;
      currency?: string | null;
      sellerId?: string | null;
      offersEnabled?: boolean | null;
      isBuyerOfferEligible?: boolean | null;
      condition?: {
        __typename?: "core_apimessages_Condition";
        displayName?: string | null;
        conditionSlug?: string | null;
        conditionUuid?: string | null;
      } | null;
      price?: {
        __typename?: "core_apimessages_Money";
        amountCents?: number | null;
        display?: string | null;
      } | null;
      pricing?: {
        __typename?: "core_apimessages_ListingPricing";
        buyerPrice?: {
          __typename?: "core_apimessages_Money";
          display?: string | null;
          currency?: string | null;
          amountCents?: number | null;
          amount?: string | null;
        } | null;
        originalPrice?: {
          __typename?: "core_apimessages_Money";
          display?: string | null;
        } | null;
        ribbon?: {
          __typename?: "core_apimessages_Ribbon";
          display?: string | null;
          reason?: core_apimessages_Ribbon_Reason | null;
        } | null;
      } | null;
      shipping?: {
        __typename?: "core_apimessages_ShippingPrices";
        freeExpeditedShipping?: boolean | null;
        localPickupOnly?: boolean | null;
        localPickup?: boolean | null;
        shippingPrices?: Array<{
          __typename?: "core_apimessages_ShippingPrice";
          _id?: string | null;
          shippingMethod?: core_apimessages_ShippingMethod | null;
          carrierCalculated?: boolean | null;
          destinationPostalCodeNeeded?: boolean | null;
          regional?: boolean | null;
          postalCode?: string | null;
          rate?: {
            __typename?: "core_apimessages_Money";
            amount?: string | null;
            amountCents?: number | null;
            currency?: string | null;
            display?: string | null;
          } | null;
        } | null> | null;
      } | null;
      shop?: {
        __typename?: "Shop";
        _id?: string | null;
        name?: string | null;
        slug?: string | null;
        preferredSeller?: boolean | null;
        quickShipper?: boolean | null;
        quickResponder?: boolean | null;
        address?: {
          __typename?: "core_apimessages_Address";
          _id?: string | null;
          countryCode?: string | null;
          displayLocation?: string | null;
          locality?: string | null;
          region?: string | null;
          country?: {
            __typename?: "core_apimessages_Country";
            _id?: string | null;
            countryCode?: string | null;
            name?: string | null;
          } | null;
        } | null;
        returnPolicy?: {
          __typename?: "core_apimessages_ReturnPolicy";
          _id?: string | null;
          newReturnWindowDays?: number | null;
          usedReturnWindowDays?: number | null;
        } | null;
      } | null;
      categories?: Array<{
        __typename?: "Category";
        _id?: string | null;
        slug?: string | null;
        rootSlug?: string | null;
      } | null> | null;
      csp?: {
        __typename?: "CSP";
        _id?: string | null;
        id?: string | null;
        slug?: string | null;
        brand?: {
          __typename?: "Brand";
          _id?: string | null;
          slug?: string | null;
        } | null;
      } | null;
      publishedAt?: {
        __typename?: "google_protobuf_Timestamp";
        seconds?: number | null;
      } | null;
      sale?: {
        __typename?: "core_apimessages_Sale";
        _id?: string | null;
        id?: string | null;
        code?: string | null;
        buyerIneligibilityReason?: string | null;
      } | null;
      certifiedPreOwned?: {
        __typename?: "core_apimessages_ListingCertifiedPreOwned";
        title?: string | null;
        badgeIconUrl?: string | null;
      } | null;
      estimatedMonthlyPaymentPlan?: {
        __typename?: "core_apimessages_EstimatedMonthlyPayment";
        financingProgramSlug?: string | null;
      } | null;
      priceRecommendation?: {
        __typename?: "reverb_pricing_PriceRecommendation";
        priceMiddle?: {
          __typename?: "reverb_pricing_Money";
          amountCents?: number | null;
          currency?: string | null;
        } | null;
      } | null;
      seller?: {
        __typename?: "User";
        _id?: string | null;
        feedbackSummary?: {
          __typename?: "core_apimessages_FeedbackSummary";
          receivedCount?: number | null;
          rollingRatingPercentage?: number | null;
        } | null;
      } | null;
      preorderInfo?: {
        __typename?: "core_apimessages_PreorderInfo";
        onPreorder?: boolean | null;
        estimatedShipDate?: {
          __typename?: "google_protobuf_Timestamp";
          seconds?: number | null;
        } | null;
      } | null;
      watchThumbnails?: Array<{
        __typename?: "core_apimessages_Image";
        _id?: string | null;
        source?: string | null;
      } | null> | null;
      images?: Array<{
        __typename?: "core_apimessages_Image";
        _id?: string | null;
        source?: string | null;
      } | null> | null;
      largeSquareImages?: Array<{
        __typename?: "core_apimessages_Image";
        _id?: string | null;
        source?: string | null;
      } | null> | null;
    } | null> | null;
    fallbackListings?: Array<{
      __typename?: "Listing";
      _id?: string | null;
      upc?: string | null;
      finish?: string | null;
      model?: string | null;
      shopUuid?: string | null;
      id?: string | null;
      usOutlet?: boolean | null;
      bumped?: boolean | null;
      categoryUuids?: Array<string | null> | null;
      slug?: string | null;
      title?: string | null;
      description?: string | null;
      listingType?: core_apimessages_Listing_ListingType | null;
      watching?: boolean | null;
      state?: string | null;
      stateDescription?: string | null;
      brandSlug?: string | null;
      categoryRootUuid?: string | null;
      make?: string | null;
      bumpEligible?: boolean | null;
      shopId?: string | null;
      inventory?: number | null;
      soldAsIs?: boolean | null;
      acceptedPaymentMethods?: Array<string | null> | null;
      currency?: string | null;
      sellerId?: string | null;
      offersEnabled?: boolean | null;
      isBuyerOfferEligible?: boolean | null;
      condition?: {
        __typename?: "core_apimessages_Condition";
        displayName?: string | null;
        conditionSlug?: string | null;
        conditionUuid?: string | null;
      } | null;
      price?: {
        __typename?: "core_apimessages_Money";
        amountCents?: number | null;
        display?: string | null;
      } | null;
      pricing?: {
        __typename?: "core_apimessages_ListingPricing";
        buyerPrice?: {
          __typename?: "core_apimessages_Money";
          display?: string | null;
          currency?: string | null;
          amountCents?: number | null;
          amount?: string | null;
        } | null;
        originalPrice?: {
          __typename?: "core_apimessages_Money";
          display?: string | null;
        } | null;
        ribbon?: {
          __typename?: "core_apimessages_Ribbon";
          display?: string | null;
          reason?: core_apimessages_Ribbon_Reason | null;
        } | null;
      } | null;
      shipping?: {
        __typename?: "core_apimessages_ShippingPrices";
        freeExpeditedShipping?: boolean | null;
        localPickupOnly?: boolean | null;
        localPickup?: boolean | null;
        shippingPrices?: Array<{
          __typename?: "core_apimessages_ShippingPrice";
          _id?: string | null;
          shippingMethod?: core_apimessages_ShippingMethod | null;
          carrierCalculated?: boolean | null;
          destinationPostalCodeNeeded?: boolean | null;
          regional?: boolean | null;
          postalCode?: string | null;
          rate?: {
            __typename?: "core_apimessages_Money";
            amount?: string | null;
            amountCents?: number | null;
            currency?: string | null;
            display?: string | null;
          } | null;
        } | null> | null;
      } | null;
      shop?: {
        __typename?: "Shop";
        _id?: string | null;
        name?: string | null;
        slug?: string | null;
        preferredSeller?: boolean | null;
        quickShipper?: boolean | null;
        quickResponder?: boolean | null;
        address?: {
          __typename?: "core_apimessages_Address";
          _id?: string | null;
          countryCode?: string | null;
          displayLocation?: string | null;
          locality?: string | null;
          region?: string | null;
          country?: {
            __typename?: "core_apimessages_Country";
            _id?: string | null;
            countryCode?: string | null;
            name?: string | null;
          } | null;
        } | null;
        returnPolicy?: {
          __typename?: "core_apimessages_ReturnPolicy";
          _id?: string | null;
          newReturnWindowDays?: number | null;
          usedReturnWindowDays?: number | null;
        } | null;
      } | null;
      categories?: Array<{
        __typename?: "Category";
        _id?: string | null;
        slug?: string | null;
        rootSlug?: string | null;
      } | null> | null;
      csp?: {
        __typename?: "CSP";
        _id?: string | null;
        id?: string | null;
        slug?: string | null;
        brand?: {
          __typename?: "Brand";
          _id?: string | null;
          slug?: string | null;
        } | null;
      } | null;
      publishedAt?: {
        __typename?: "google_protobuf_Timestamp";
        seconds?: number | null;
      } | null;
      sale?: {
        __typename?: "core_apimessages_Sale";
        _id?: string | null;
        id?: string | null;
        code?: string | null;
        buyerIneligibilityReason?: string | null;
      } | null;
      certifiedPreOwned?: {
        __typename?: "core_apimessages_ListingCertifiedPreOwned";
        title?: string | null;
        badgeIconUrl?: string | null;
      } | null;
      estimatedMonthlyPaymentPlan?: {
        __typename?: "core_apimessages_EstimatedMonthlyPayment";
        financingProgramSlug?: string | null;
      } | null;
      priceRecommendation?: {
        __typename?: "reverb_pricing_PriceRecommendation";
        priceMiddle?: {
          __typename?: "reverb_pricing_Money";
          amountCents?: number | null;
          currency?: string | null;
        } | null;
      } | null;
      seller?: {
        __typename?: "User";
        _id?: string | null;
        feedbackSummary?: {
          __typename?: "core_apimessages_FeedbackSummary";
          receivedCount?: number | null;
          rollingRatingPercentage?: number | null;
        } | null;
      } | null;
      preorderInfo?: {
        __typename?: "core_apimessages_PreorderInfo";
        onPreorder?: boolean | null;
        estimatedShipDate?: {
          __typename?: "google_protobuf_Timestamp";
          seconds?: number | null;
        } | null;
      } | null;
      watchThumbnails?: Array<{
        __typename?: "core_apimessages_Image";
        _id?: string | null;
        source?: string | null;
      } | null> | null;
      images?: Array<{
        __typename?: "core_apimessages_Image";
        _id?: string | null;
        source?: string | null;
      } | null> | null;
      largeSquareImages?: Array<{
        __typename?: "core_apimessages_Image";
        _id?: string | null;
        source?: string | null;
      } | null> | null;
    } | null> | null;
  } | null;
  allBumped?: {
    __typename?: "ListingCollection";
    listings?: Array<{
      __typename?: "Listing";
      _id?: string | null;
      upc?: string | null;
      finish?: string | null;
      model?: string | null;
      shopUuid?: string | null;
      id?: string | null;
      usOutlet?: boolean | null;
      bumped?: boolean | null;
      categoryUuids?: Array<string | null> | null;
      slug?: string | null;
      title?: string | null;
      description?: string | null;
      listingType?: core_apimessages_Listing_ListingType | null;
      watching?: boolean | null;
      state?: string | null;
      stateDescription?: string | null;
      brandSlug?: string | null;
      categoryRootUuid?: string | null;
      make?: string | null;
      bumpEligible?: boolean | null;
      shopId?: string | null;
      inventory?: number | null;
      soldAsIs?: boolean | null;
      acceptedPaymentMethods?: Array<string | null> | null;
      currency?: string | null;
      sellerId?: string | null;
      offersEnabled?: boolean | null;
      isBuyerOfferEligible?: boolean | null;
      condition?: {
        __typename?: "core_apimessages_Condition";
        displayName?: string | null;
        conditionSlug?: string | null;
        conditionUuid?: string | null;
      } | null;
      price?: {
        __typename?: "core_apimessages_Money";
        amountCents?: number | null;
        display?: string | null;
      } | null;
      pricing?: {
        __typename?: "core_apimessages_ListingPricing";
        buyerPrice?: {
          __typename?: "core_apimessages_Money";
          display?: string | null;
          currency?: string | null;
          amountCents?: number | null;
          amount?: string | null;
        } | null;
        originalPrice?: {
          __typename?: "core_apimessages_Money";
          display?: string | null;
        } | null;
        ribbon?: {
          __typename?: "core_apimessages_Ribbon";
          display?: string | null;
          reason?: core_apimessages_Ribbon_Reason | null;
        } | null;
      } | null;
      shipping?: {
        __typename?: "core_apimessages_ShippingPrices";
        freeExpeditedShipping?: boolean | null;
        localPickupOnly?: boolean | null;
        localPickup?: boolean | null;
        shippingPrices?: Array<{
          __typename?: "core_apimessages_ShippingPrice";
          _id?: string | null;
          shippingMethod?: core_apimessages_ShippingMethod | null;
          carrierCalculated?: boolean | null;
          destinationPostalCodeNeeded?: boolean | null;
          regional?: boolean | null;
          postalCode?: string | null;
          rate?: {
            __typename?: "core_apimessages_Money";
            amount?: string | null;
            amountCents?: number | null;
            currency?: string | null;
            display?: string | null;
          } | null;
        } | null> | null;
      } | null;
      shop?: {
        __typename?: "Shop";
        _id?: string | null;
        name?: string | null;
        slug?: string | null;
        preferredSeller?: boolean | null;
        quickShipper?: boolean | null;
        quickResponder?: boolean | null;
        address?: {
          __typename?: "core_apimessages_Address";
          _id?: string | null;
          countryCode?: string | null;
          displayLocation?: string | null;
          locality?: string | null;
          region?: string | null;
          country?: {
            __typename?: "core_apimessages_Country";
            _id?: string | null;
            countryCode?: string | null;
            name?: string | null;
          } | null;
        } | null;
        returnPolicy?: {
          __typename?: "core_apimessages_ReturnPolicy";
          _id?: string | null;
          newReturnWindowDays?: number | null;
          usedReturnWindowDays?: number | null;
        } | null;
      } | null;
      categories?: Array<{
        __typename?: "Category";
        _id?: string | null;
        slug?: string | null;
        rootSlug?: string | null;
      } | null> | null;
      csp?: {
        __typename?: "CSP";
        _id?: string | null;
        id?: string | null;
        slug?: string | null;
        brand?: {
          __typename?: "Brand";
          _id?: string | null;
          slug?: string | null;
        } | null;
      } | null;
      publishedAt?: {
        __typename?: "google_protobuf_Timestamp";
        seconds?: number | null;
      } | null;
      sale?: {
        __typename?: "core_apimessages_Sale";
        _id?: string | null;
        id?: string | null;
        code?: string | null;
        buyerIneligibilityReason?: string | null;
      } | null;
      certifiedPreOwned?: {
        __typename?: "core_apimessages_ListingCertifiedPreOwned";
        title?: string | null;
        badgeIconUrl?: string | null;
      } | null;
      estimatedMonthlyPaymentPlan?: {
        __typename?: "core_apimessages_EstimatedMonthlyPayment";
        financingProgramSlug?: string | null;
      } | null;
      bumpKey?: {
        __typename?: "core_apimessages_BumpKey";
        key?: string | null;
      } | null;
      priceRecommendation?: {
        __typename?: "reverb_pricing_PriceRecommendation";
        priceMiddle?: {
          __typename?: "reverb_pricing_Money";
          amountCents?: number | null;
          currency?: string | null;
        } | null;
      } | null;
      seller?: {
        __typename?: "User";
        _id?: string | null;
        feedbackSummary?: {
          __typename?: "core_apimessages_FeedbackSummary";
          receivedCount?: number | null;
          rollingRatingPercentage?: number | null;
        } | null;
      } | null;
      preorderInfo?: {
        __typename?: "core_apimessages_PreorderInfo";
        onPreorder?: boolean | null;
        estimatedShipDate?: {
          __typename?: "google_protobuf_Timestamp";
          seconds?: number | null;
        } | null;
      } | null;
      watchThumbnails?: Array<{
        __typename?: "core_apimessages_Image";
        _id?: string | null;
        source?: string | null;
      } | null> | null;
      images?: Array<{
        __typename?: "core_apimessages_Image";
        _id?: string | null;
        source?: string | null;
      } | null> | null;
      largeSquareImages?: Array<{
        __typename?: "core_apimessages_Image";
        _id?: string | null;
        source?: string | null;
      } | null> | null;
    } | null> | null;
  } | null;
  cspSponsoredListing?: {
    __typename?: "arbiter_FeaturedListingResponse";
    listing?: {
      __typename?: "Listing";
      _id?: string | null;
      upc?: string | null;
      finish?: string | null;
      model?: string | null;
      shopUuid?: string | null;
      id?: string | null;
      usOutlet?: boolean | null;
      bumped?: boolean | null;
      categoryUuids?: Array<string | null> | null;
      slug?: string | null;
      title?: string | null;
      description?: string | null;
      listingType?: core_apimessages_Listing_ListingType | null;
      watching?: boolean | null;
      state?: string | null;
      stateDescription?: string | null;
      brandSlug?: string | null;
      categoryRootUuid?: string | null;
      make?: string | null;
      bumpEligible?: boolean | null;
      shopId?: string | null;
      inventory?: number | null;
      soldAsIs?: boolean | null;
      acceptedPaymentMethods?: Array<string | null> | null;
      currency?: string | null;
      sellerId?: string | null;
      offersEnabled?: boolean | null;
      isBuyerOfferEligible?: boolean | null;
      condition?: {
        __typename?: "core_apimessages_Condition";
        displayName?: string | null;
        conditionSlug?: string | null;
        conditionUuid?: string | null;
      } | null;
      price?: {
        __typename?: "core_apimessages_Money";
        amountCents?: number | null;
        display?: string | null;
      } | null;
      pricing?: {
        __typename?: "core_apimessages_ListingPricing";
        buyerPrice?: {
          __typename?: "core_apimessages_Money";
          display?: string | null;
          currency?: string | null;
          amountCents?: number | null;
          amount?: string | null;
        } | null;
        originalPrice?: {
          __typename?: "core_apimessages_Money";
          display?: string | null;
        } | null;
        ribbon?: {
          __typename?: "core_apimessages_Ribbon";
          display?: string | null;
          reason?: core_apimessages_Ribbon_Reason | null;
        } | null;
      } | null;
      shipping?: {
        __typename?: "core_apimessages_ShippingPrices";
        freeExpeditedShipping?: boolean | null;
        localPickupOnly?: boolean | null;
        localPickup?: boolean | null;
        shippingPrices?: Array<{
          __typename?: "core_apimessages_ShippingPrice";
          _id?: string | null;
          shippingMethod?: core_apimessages_ShippingMethod | null;
          carrierCalculated?: boolean | null;
          destinationPostalCodeNeeded?: boolean | null;
          regional?: boolean | null;
          postalCode?: string | null;
          rate?: {
            __typename?: "core_apimessages_Money";
            amount?: string | null;
            amountCents?: number | null;
            currency?: string | null;
            display?: string | null;
          } | null;
        } | null> | null;
      } | null;
      shop?: {
        __typename?: "Shop";
        _id?: string | null;
        name?: string | null;
        slug?: string | null;
        preferredSeller?: boolean | null;
        quickShipper?: boolean | null;
        quickResponder?: boolean | null;
        address?: {
          __typename?: "core_apimessages_Address";
          _id?: string | null;
          countryCode?: string | null;
          displayLocation?: string | null;
          locality?: string | null;
          region?: string | null;
          country?: {
            __typename?: "core_apimessages_Country";
            _id?: string | null;
            countryCode?: string | null;
            name?: string | null;
          } | null;
        } | null;
        returnPolicy?: {
          __typename?: "core_apimessages_ReturnPolicy";
          _id?: string | null;
          newReturnWindowDays?: number | null;
          usedReturnWindowDays?: number | null;
        } | null;
      } | null;
      categories?: Array<{
        __typename?: "Category";
        _id?: string | null;
        slug?: string | null;
        rootSlug?: string | null;
      } | null> | null;
      csp?: {
        __typename?: "CSP";
        _id?: string | null;
        id?: string | null;
        slug?: string | null;
        brand?: {
          __typename?: "Brand";
          _id?: string | null;
          slug?: string | null;
        } | null;
      } | null;
      publishedAt?: {
        __typename?: "google_protobuf_Timestamp";
        seconds?: number | null;
      } | null;
      sale?: {
        __typename?: "core_apimessages_Sale";
        _id?: string | null;
        id?: string | null;
        code?: string | null;
        buyerIneligibilityReason?: string | null;
      } | null;
      certifiedPreOwned?: {
        __typename?: "core_apimessages_ListingCertifiedPreOwned";
        title?: string | null;
        badgeIconUrl?: string | null;
      } | null;
      estimatedMonthlyPaymentPlan?: {
        __typename?: "core_apimessages_EstimatedMonthlyPayment";
        financingProgramSlug?: string | null;
      } | null;
      bumpKey?: {
        __typename?: "core_apimessages_BumpKey";
        key?: string | null;
      } | null;
      priceRecommendation?: {
        __typename?: "reverb_pricing_PriceRecommendation";
        priceMiddle?: {
          __typename?: "reverb_pricing_Money";
          amountCents?: number | null;
          currency?: string | null;
        } | null;
      } | null;
      seller?: {
        __typename?: "User";
        _id?: string | null;
        feedbackSummary?: {
          __typename?: "core_apimessages_FeedbackSummary";
          receivedCount?: number | null;
          rollingRatingPercentage?: number | null;
        } | null;
      } | null;
      preorderInfo?: {
        __typename?: "core_apimessages_PreorderInfo";
        onPreorder?: boolean | null;
        estimatedShipDate?: {
          __typename?: "google_protobuf_Timestamp";
          seconds?: number | null;
        } | null;
      } | null;
      watchThumbnails?: Array<{
        __typename?: "core_apimessages_Image";
        _id?: string | null;
        source?: string | null;
      } | null> | null;
      images?: Array<{
        __typename?: "core_apimessages_Image";
        _id?: string | null;
        source?: string | null;
      } | null> | null;
      largeSquareImages?: Array<{
        __typename?: "core_apimessages_Image";
        _id?: string | null;
        source?: string | null;
      } | null> | null;
    } | null;
    runnerUp?: {
      __typename?: "arbiter_FeaturedListingResponse";
      listing?: {
        __typename?: "Listing";
        _id?: string | null;
        upc?: string | null;
        finish?: string | null;
        model?: string | null;
        shopUuid?: string | null;
        id?: string | null;
        usOutlet?: boolean | null;
        bumped?: boolean | null;
        categoryUuids?: Array<string | null> | null;
        slug?: string | null;
        title?: string | null;
        description?: string | null;
        listingType?: core_apimessages_Listing_ListingType | null;
        watching?: boolean | null;
        state?: string | null;
        stateDescription?: string | null;
        brandSlug?: string | null;
        categoryRootUuid?: string | null;
        make?: string | null;
        bumpEligible?: boolean | null;
        shopId?: string | null;
        inventory?: number | null;
        soldAsIs?: boolean | null;
        acceptedPaymentMethods?: Array<string | null> | null;
        currency?: string | null;
        sellerId?: string | null;
        offersEnabled?: boolean | null;
        isBuyerOfferEligible?: boolean | null;
        condition?: {
          __typename?: "core_apimessages_Condition";
          displayName?: string | null;
          conditionSlug?: string | null;
          conditionUuid?: string | null;
        } | null;
        price?: {
          __typename?: "core_apimessages_Money";
          amountCents?: number | null;
          display?: string | null;
        } | null;
        pricing?: {
          __typename?: "core_apimessages_ListingPricing";
          buyerPrice?: {
            __typename?: "core_apimessages_Money";
            display?: string | null;
            currency?: string | null;
            amountCents?: number | null;
            amount?: string | null;
          } | null;
          originalPrice?: {
            __typename?: "core_apimessages_Money";
            display?: string | null;
          } | null;
          ribbon?: {
            __typename?: "core_apimessages_Ribbon";
            display?: string | null;
            reason?: core_apimessages_Ribbon_Reason | null;
          } | null;
        } | null;
        shipping?: {
          __typename?: "core_apimessages_ShippingPrices";
          freeExpeditedShipping?: boolean | null;
          localPickupOnly?: boolean | null;
          localPickup?: boolean | null;
          shippingPrices?: Array<{
            __typename?: "core_apimessages_ShippingPrice";
            _id?: string | null;
            shippingMethod?: core_apimessages_ShippingMethod | null;
            carrierCalculated?: boolean | null;
            destinationPostalCodeNeeded?: boolean | null;
            regional?: boolean | null;
            postalCode?: string | null;
            rate?: {
              __typename?: "core_apimessages_Money";
              amount?: string | null;
              amountCents?: number | null;
              currency?: string | null;
              display?: string | null;
            } | null;
          } | null> | null;
        } | null;
        shop?: {
          __typename?: "Shop";
          _id?: string | null;
          name?: string | null;
          slug?: string | null;
          preferredSeller?: boolean | null;
          quickShipper?: boolean | null;
          quickResponder?: boolean | null;
          address?: {
            __typename?: "core_apimessages_Address";
            _id?: string | null;
            countryCode?: string | null;
            displayLocation?: string | null;
            locality?: string | null;
            region?: string | null;
            country?: {
              __typename?: "core_apimessages_Country";
              _id?: string | null;
              countryCode?: string | null;
              name?: string | null;
            } | null;
          } | null;
          returnPolicy?: {
            __typename?: "core_apimessages_ReturnPolicy";
            _id?: string | null;
            newReturnWindowDays?: number | null;
            usedReturnWindowDays?: number | null;
          } | null;
        } | null;
        categories?: Array<{
          __typename?: "Category";
          _id?: string | null;
          slug?: string | null;
          rootSlug?: string | null;
        } | null> | null;
        csp?: {
          __typename?: "CSP";
          _id?: string | null;
          id?: string | null;
          slug?: string | null;
          brand?: {
            __typename?: "Brand";
            _id?: string | null;
            slug?: string | null;
          } | null;
        } | null;
        publishedAt?: {
          __typename?: "google_protobuf_Timestamp";
          seconds?: number | null;
        } | null;
        sale?: {
          __typename?: "core_apimessages_Sale";
          _id?: string | null;
          id?: string | null;
          code?: string | null;
          buyerIneligibilityReason?: string | null;
        } | null;
        certifiedPreOwned?: {
          __typename?: "core_apimessages_ListingCertifiedPreOwned";
          title?: string | null;
          badgeIconUrl?: string | null;
        } | null;
        estimatedMonthlyPaymentPlan?: {
          __typename?: "core_apimessages_EstimatedMonthlyPayment";
          financingProgramSlug?: string | null;
        } | null;
        bumpKey?: {
          __typename?: "core_apimessages_BumpKey";
          key?: string | null;
        } | null;
        priceRecommendation?: {
          __typename?: "reverb_pricing_PriceRecommendation";
          priceMiddle?: {
            __typename?: "reverb_pricing_Money";
            amountCents?: number | null;
            currency?: string | null;
          } | null;
        } | null;
        seller?: {
          __typename?: "User";
          _id?: string | null;
          feedbackSummary?: {
            __typename?: "core_apimessages_FeedbackSummary";
            receivedCount?: number | null;
            rollingRatingPercentage?: number | null;
          } | null;
        } | null;
        preorderInfo?: {
          __typename?: "core_apimessages_PreorderInfo";
          onPreorder?: boolean | null;
          estimatedShipDate?: {
            __typename?: "google_protobuf_Timestamp";
            seconds?: number | null;
          } | null;
        } | null;
        watchThumbnails?: Array<{
          __typename?: "core_apimessages_Image";
          _id?: string | null;
          source?: string | null;
        } | null> | null;
        images?: Array<{
          __typename?: "core_apimessages_Image";
          _id?: string | null;
          source?: string | null;
        } | null> | null;
        largeSquareImages?: Array<{
          __typename?: "core_apimessages_Image";
          _id?: string | null;
          source?: string | null;
        } | null> | null;
      } | null;
      runnerUp?: {
        __typename?: "arbiter_FeaturedListingResponse";
        listing?: {
          __typename?: "Listing";
          _id?: string | null;
          upc?: string | null;
          finish?: string | null;
          model?: string | null;
          shopUuid?: string | null;
          id?: string | null;
          usOutlet?: boolean | null;
          bumped?: boolean | null;
          categoryUuids?: Array<string | null> | null;
          slug?: string | null;
          title?: string | null;
          description?: string | null;
          listingType?: core_apimessages_Listing_ListingType | null;
          watching?: boolean | null;
          state?: string | null;
          stateDescription?: string | null;
          brandSlug?: string | null;
          categoryRootUuid?: string | null;
          make?: string | null;
          bumpEligible?: boolean | null;
          shopId?: string | null;
          inventory?: number | null;
          soldAsIs?: boolean | null;
          acceptedPaymentMethods?: Array<string | null> | null;
          currency?: string | null;
          sellerId?: string | null;
          offersEnabled?: boolean | null;
          isBuyerOfferEligible?: boolean | null;
          condition?: {
            __typename?: "core_apimessages_Condition";
            displayName?: string | null;
            conditionSlug?: string | null;
            conditionUuid?: string | null;
          } | null;
          price?: {
            __typename?: "core_apimessages_Money";
            amountCents?: number | null;
            display?: string | null;
          } | null;
          pricing?: {
            __typename?: "core_apimessages_ListingPricing";
            buyerPrice?: {
              __typename?: "core_apimessages_Money";
              display?: string | null;
              currency?: string | null;
              amountCents?: number | null;
              amount?: string | null;
            } | null;
            originalPrice?: {
              __typename?: "core_apimessages_Money";
              display?: string | null;
            } | null;
            ribbon?: {
              __typename?: "core_apimessages_Ribbon";
              display?: string | null;
              reason?: core_apimessages_Ribbon_Reason | null;
            } | null;
          } | null;
          shipping?: {
            __typename?: "core_apimessages_ShippingPrices";
            freeExpeditedShipping?: boolean | null;
            localPickupOnly?: boolean | null;
            localPickup?: boolean | null;
            shippingPrices?: Array<{
              __typename?: "core_apimessages_ShippingPrice";
              _id?: string | null;
              shippingMethod?: core_apimessages_ShippingMethod | null;
              carrierCalculated?: boolean | null;
              destinationPostalCodeNeeded?: boolean | null;
              regional?: boolean | null;
              postalCode?: string | null;
              rate?: {
                __typename?: "core_apimessages_Money";
                amount?: string | null;
                amountCents?: number | null;
                currency?: string | null;
                display?: string | null;
              } | null;
            } | null> | null;
          } | null;
          shop?: {
            __typename?: "Shop";
            _id?: string | null;
            name?: string | null;
            slug?: string | null;
            preferredSeller?: boolean | null;
            quickShipper?: boolean | null;
            quickResponder?: boolean | null;
            address?: {
              __typename?: "core_apimessages_Address";
              _id?: string | null;
              countryCode?: string | null;
              displayLocation?: string | null;
              locality?: string | null;
              region?: string | null;
              country?: {
                __typename?: "core_apimessages_Country";
                _id?: string | null;
                countryCode?: string | null;
                name?: string | null;
              } | null;
            } | null;
            returnPolicy?: {
              __typename?: "core_apimessages_ReturnPolicy";
              _id?: string | null;
              newReturnWindowDays?: number | null;
              usedReturnWindowDays?: number | null;
            } | null;
          } | null;
          categories?: Array<{
            __typename?: "Category";
            _id?: string | null;
            slug?: string | null;
            rootSlug?: string | null;
          } | null> | null;
          csp?: {
            __typename?: "CSP";
            _id?: string | null;
            id?: string | null;
            slug?: string | null;
            brand?: {
              __typename?: "Brand";
              _id?: string | null;
              slug?: string | null;
            } | null;
          } | null;
          publishedAt?: {
            __typename?: "google_protobuf_Timestamp";
            seconds?: number | null;
          } | null;
          sale?: {
            __typename?: "core_apimessages_Sale";
            _id?: string | null;
            id?: string | null;
            code?: string | null;
            buyerIneligibilityReason?: string | null;
          } | null;
          certifiedPreOwned?: {
            __typename?: "core_apimessages_ListingCertifiedPreOwned";
            title?: string | null;
            badgeIconUrl?: string | null;
          } | null;
          estimatedMonthlyPaymentPlan?: {
            __typename?: "core_apimessages_EstimatedMonthlyPayment";
            financingProgramSlug?: string | null;
          } | null;
          bumpKey?: {
            __typename?: "core_apimessages_BumpKey";
            key?: string | null;
          } | null;
          priceRecommendation?: {
            __typename?: "reverb_pricing_PriceRecommendation";
            priceMiddle?: {
              __typename?: "reverb_pricing_Money";
              amountCents?: number | null;
              currency?: string | null;
            } | null;
          } | null;
          seller?: {
            __typename?: "User";
            _id?: string | null;
            feedbackSummary?: {
              __typename?: "core_apimessages_FeedbackSummary";
              receivedCount?: number | null;
              rollingRatingPercentage?: number | null;
            } | null;
          } | null;
          preorderInfo?: {
            __typename?: "core_apimessages_PreorderInfo";
            onPreorder?: boolean | null;
            estimatedShipDate?: {
              __typename?: "google_protobuf_Timestamp";
              seconds?: number | null;
            } | null;
          } | null;
          watchThumbnails?: Array<{
            __typename?: "core_apimessages_Image";
            _id?: string | null;
            source?: string | null;
          } | null> | null;
          images?: Array<{
            __typename?: "core_apimessages_Image";
            _id?: string | null;
            source?: string | null;
          } | null> | null;
          largeSquareImages?: Array<{
            __typename?: "core_apimessages_Image";
            _id?: string | null;
            source?: string | null;
          } | null> | null;
        } | null;
      } | null;
    } | null;
  } | null;
  finishAgg?: {
    __typename?: "reverb_search_ListingsAggregationResponse";
    aggregationResults?: Array<{
      __typename?: "reverb_search_AggregationResult";
      name?: reverb_search_ListingsSearchRequest_Aggregation | null;
      aggregationDetails?: Array<{
        __typename?: "reverb_search_AggregationDetails";
        key?: string | null;
        count?: number | null;
      } | null> | null;
    } | null> | null;
  } | null;
  fretboardMaterialAgg?: {
    __typename?: "reverb_search_ListingsAggregationResponse";
    aggregationResults?: Array<{
      __typename?: "reverb_search_AggregationResult";
      name?: reverb_search_ListingsSearchRequest_Aggregation | null;
      displayName?: string | null;
      aggregationDetails?: Array<{
        __typename?: "reverb_search_AggregationDetails";
        key?: string | null;
        displayName?: string | null;
        count?: number | null;
      } | null> | null;
    } | null> | null;
  } | null;
};

export type Core_Csp_LayoutQueryQueryVariables = Exact<{
  auctionType?: InputMaybe<arbiter_AuctionType>;
  canonicalFinish?: InputMaybe<Scalars["String"]["input"]>;
  cspSlug?: InputMaybe<Scalars["String"]["input"]>;
  hfid?: InputMaybe<Scalars["String"]["input"]>;
  hfidPresent: Scalars["Boolean"]["input"];
  includeFullFinancingFields: Scalars["Boolean"]["input"];
  itemRegion?: InputMaybe<Scalars["String"]["input"]>;
  shippingRegionCode?: InputMaybe<Scalars["String"]["input"]>;
  shouldSkipTracking: Scalars["Boolean"]["input"];
  showListingLocation: Scalars["Boolean"]["input"];
  traitValues?: InputMaybe<
    | Array<InputMaybe<Scalars["String"]["input"]>>
    | InputMaybe<Scalars["String"]["input"]>
  >;
  userCurrency?: InputMaybe<Scalars["String"]["input"]>;
  useNewBreadcrumbs: Scalars["Boolean"]["input"];
  applyProximityBoost: Scalars["Boolean"]["input"];
}>;

export type Core_Csp_LayoutQueryQuery = {
  __typename?: "Query";
  csp?: {
    __typename?: "CSP";
    _id?: string | null;
    id?: string | null;
    title?: string | null;
    summary?: string | null;
    body?: string | null;
    metaDescription?: string | null;
    model?: string | null;
    generic?: boolean | null;
    finishes?: Array<string | null> | null;
    slug?: string | null;
    brand?: {
      __typename?: "Brand";
      _id?: string | null;
      name?: string | null;
      slug?: string | null;
      webLink?: {
        __typename?: "core_apimessages_Link";
        href?: string | null;
      } | null;
    } | null;
    categories?: Array<{
      __typename?: "Category";
      _id?: string | null;
      id?: string | null;
      name?: string | null;
      slug?: string | null;
      root?: boolean | null;
      rootSlug?: string | null;
      webLink?: {
        __typename?: "core_apimessages_Link";
        href?: string | null;
      } | null;
      cmsUrl?: {
        __typename?: "core_apimessages_Link";
        href?: string | null;
      } | null;
      parentCategories?: Array<{
        __typename?: "Category";
        _id?: string | null;
        id?: string | null;
        name?: string | null;
        slug?: string | null;
        rootSlug?: string | null;
        webLink?: {
          __typename?: "core_apimessages_Link";
          href?: string | null;
        } | null;
        cmsUrl?: {
          __typename?: "core_apimessages_Link";
          href?: string | null;
        } | null;
      } | null> | null;
    } | null> | null;
    videos?: {
      __typename?: "core_apimessages_CSPVideo";
      videoLinks?: Array<{
        __typename?: "core_apimessages_VideoLink";
        youtubeLink?: string | null;
        youtubeVideoId?: string | null;
      } | null> | null;
    } | null;
    specs?: {
      __typename?: "core_apimessages_CSPSpec";
      productSpecs?: Array<{
        __typename?: "core_apimessages_ProductSpec";
        _id?: string | null;
        type?: core_apimessages_ProductSpec_Type | null;
        name?: string | null;
        path?: string | null;
        value?: string | null;
      } | null> | null;
    } | null;
    breadcrumbs?: Array<{
      __typename?: "core_apimessages_ProductBreadcrumb";
      title?: string | null;
      url?: string | null;
    } | null> | null;
    supersize_images?: Array<{
      __typename?: "core_apimessages_Image";
      _id?: string | null;
      source?: string | null;
    } | null> | null;
    large_images?: Array<{
      __typename?: "core_apimessages_Image";
      _id?: string | null;
      source?: string | null;
    } | null> | null;
    hero_image?: Array<{
      __typename?: "core_apimessages_Image";
      _id?: string | null;
      source?: string | null;
    } | null> | null;
    cspImage?: Array<{
      __typename?: "core_apimessages_Image";
      _id?: string | null;
      source?: string | null;
    } | null> | null;
    canonicalProducts?: Array<{
      __typename?: "CanonicalProduct";
      _id?: string | null;
      id?: string | null;
      name?: string | null;
    } | null> | null;
    inventory?: {
      __typename?: "core_apimessages_CSPInventory";
      usedTotal?: number | null;
      newTotal?: number | null;
    } | null;
    productReviewSearch?: {
      __typename?: "rql_CSPProductReviewSearchResponse";
      stats?: {
        __typename?: "reverb_search_ProductReviewStats";
        total?: number | null;
        averageRating?: number | null;
      } | null;
    } | null;
  } | null;
  featuredListing?: {
    __typename?: "arbiter_FeaturedListingResponse";
    listing?: {
      __typename?: "Listing";
      _id?: string | null;
      upc?: string | null;
      finish?: string | null;
      model?: string | null;
      shopUuid?: string | null;
      id?: string | null;
      usOutlet?: boolean | null;
      bumped?: boolean | null;
      categoryUuids?: Array<string | null> | null;
      slug?: string | null;
      title?: string | null;
      description?: string | null;
      listingType?: core_apimessages_Listing_ListingType | null;
      watching?: boolean | null;
      state?: string | null;
      stateDescription?: string | null;
      brandSlug?: string | null;
      categoryRootUuid?: string | null;
      make?: string | null;
      bumpEligible?: boolean | null;
      shopId?: string | null;
      inventory?: number | null;
      soldAsIs?: boolean | null;
      acceptedPaymentMethods?: Array<string | null> | null;
      currency?: string | null;
      sellerId?: string | null;
      offersEnabled?: boolean | null;
      isBuyerOfferEligible?: boolean | null;
      hasInventory?: boolean | null;
      maxBuyerQuantity?: number | null;
      taxIncluded?: boolean | null;
      taxIncludedHint?: string | null;
      adyenCheckoutPaymentMethodsConfig?: string | null;
      condition?: {
        __typename?: "core_apimessages_Condition";
        displayName?: string | null;
        conditionSlug?: string | null;
        conditionUuid?: string | null;
      } | null;
      price?: {
        __typename?: "core_apimessages_Money";
        amountCents?: number | null;
        display?: string | null;
        amount?: string | null;
      } | null;
      pricing?: {
        __typename?: "core_apimessages_ListingPricing";
        originalPriceDescription?: string | null;
        buyerPrice?: {
          __typename?: "core_apimessages_Money";
          display?: string | null;
          currency?: string | null;
          amountCents?: number | null;
          amount?: string | null;
        } | null;
        originalPrice?: {
          __typename?: "core_apimessages_Money";
          display?: string | null;
        } | null;
        ribbon?: {
          __typename?: "core_apimessages_Ribbon";
          display?: string | null;
          reason?: core_apimessages_Ribbon_Reason | null;
        } | null;
        typicalNewPriceDisplay?: {
          __typename?: "core_apimessages_TypicalNewPriceDisplay";
          amountDisplay?: string | null;
        } | null;
      } | null;
      shipping?: {
        __typename?: "core_apimessages_ShippingPrices";
        freeExpeditedShipping?: boolean | null;
        localPickupOnly?: boolean | null;
        localPickup?: boolean | null;
        shippingPrices?: Array<{
          __typename?: "core_apimessages_ShippingPrice";
          _id?: string | null;
          shippingMethod?: core_apimessages_ShippingMethod | null;
          carrierCalculated?: boolean | null;
          destinationPostalCodeNeeded?: boolean | null;
          regional?: boolean | null;
          postalCode?: string | null;
          rate?: {
            __typename?: "core_apimessages_Money";
            amount?: string | null;
            amountCents?: number | null;
            currency?: string | null;
            display?: string | null;
          } | null;
        } | null> | null;
      } | null;
      shop?: {
        __typename?: "Shop";
        _id?: string | null;
        name?: string | null;
        slug?: string | null;
        preferredSeller?: boolean | null;
        quickShipper?: boolean | null;
        quickResponder?: boolean | null;
        paymentPolicy?: string | null;
        createdAt?: any | null;
        id?: string | null;
        address?: {
          __typename?: "core_apimessages_Address";
          _id?: string | null;
          countryCode?: string | null;
          locality?: string | null;
          region?: string | null;
          displayLocation?: string | null;
          country?: {
            __typename?: "core_apimessages_Country";
            _id?: string | null;
            countryCode?: string | null;
            name?: string | null;
          } | null;
        } | null;
        returnPolicy?: {
          __typename?: "core_apimessages_ReturnPolicy";
          _id?: string | null;
          newReturnWindowDays?: number | null;
          usedReturnWindowDays?: number | null;
        } | null;
        orders?: {
          __typename?: "reverb_search_SearchResponse";
          total?: number | null;
        } | null;
        freeDomesticShippingRate?: {
          __typename?: "core_apimessages_ShippingRates";
          regionCode?: string | null;
          freeShippingThreshold?: {
            __typename?: "core_apimessages_Money";
            amountCents?: number | null;
            currency?: string | null;
          } | null;
        } | null;
      } | null;
      categories?: Array<{
        __typename?: "Category";
        _id?: string | null;
        slug?: string | null;
        rootSlug?: string | null;
      } | null> | null;
      csp?: {
        __typename?: "CSP";
        _id?: string | null;
        id?: string | null;
        slug?: string | null;
        brand?: {
          __typename?: "Brand";
          _id?: string | null;
          slug?: string | null;
        } | null;
        videos?: {
          __typename?: "core_apimessages_CSPVideo";
          videoLinks?: Array<{
            __typename?: "core_apimessages_VideoLink";
            youtubeVideoId?: string | null;
          } | null> | null;
        } | null;
        webLink?: {
          __typename?: "core_apimessages_Link";
          href?: string | null;
        } | null;
      } | null;
      publishedAt?: {
        __typename?: "google_protobuf_Timestamp";
        seconds?: number | null;
      } | null;
      sale?: {
        __typename?: "core_apimessages_Sale";
        _id?: string | null;
        id?: string | null;
        code?: string | null;
        buyerIneligibilityReason?: string | null;
      } | null;
      certifiedPreOwned?: {
        __typename?: "core_apimessages_ListingCertifiedPreOwned";
        title?: string | null;
        badgeIconUrl?: string | null;
        description?: string | null;
        tooltipDescription?: string | null;
        brandName?: string | null;
        pageUrl?: string | null;
        lightModeBrandIconUrl?: string | null;
        darkModeBrandIconUrl?: string | null;
      } | null;
      estimatedMonthlyPaymentPlan?: {
        __typename?: "core_apimessages_EstimatedMonthlyPayment";
        financingProgramSlug?: string | null;
      } | null;
      bumpKey?: {
        __typename?: "core_apimessages_BumpKey";
        key?: string | null;
      } | null;
      seller?: {
        __typename?: "User";
        _id?: string | null;
        id?: string | null;
        uuid?: string | null;
        feedbackSummary?: {
          __typename?: "core_apimessages_FeedbackSummary";
          receivedCount?: number | null;
          rollingRatingPercentage?: number | null;
        } | null;
      } | null;
      preorderInfo?: {
        __typename?: "core_apimessages_PreorderInfo";
        onPreorder?: boolean | null;
        estimatedShipDate?: {
          __typename?: "google_protobuf_Timestamp";
          seconds?: number | null;
        } | null;
      } | null;
      watchThumbnails?: Array<{
        __typename?: "core_apimessages_Image";
        _id?: string | null;
        source?: string | null;
      } | null> | null;
      priceRecommendation?: {
        __typename?: "reverb_pricing_PriceRecommendation";
        priceMiddle?: {
          __typename?: "reverb_pricing_Money";
          amountCents?: number | null;
          currency?: string | null;
        } | null;
      } | null;
      images?: Array<{
        __typename?: "core_apimessages_Image";
        _id?: string | null;
        source?: string | null;
      } | null> | null;
      largeSquareImages?: Array<{
        __typename?: "core_apimessages_Image";
        _id?: string | null;
        source?: string | null;
      } | null> | null;
      largeImages?: Array<{
        __typename?: "core_apimessages_Image";
        _id?: string | null;
        source?: string | null;
      } | null> | null;
      supersizeImages?: Array<{
        __typename?: "core_apimessages_Image";
        _id?: string | null;
        source?: string | null;
      } | null> | null;
      cardSquareImages?: Array<{
        __typename?: "core_apimessages_Image";
        _id?: string | null;
        source?: string | null;
      } | null> | null;
      video?: {
        __typename?: "core_apimessages_Video";
        youtubeVideoId?: string | null;
        empty?: boolean | null;
      } | null;
      signals?: Array<{
        __typename?: "reverb_signals_Signal";
        name?: reverb_signals_Signal_Name | null;
        group?: reverb_signals_Signal_Group | null;
        title?: string | null;
        subtitle?: string | null;
        icon?: string | null;
        iconDark?: string | null;
        tooltipText?: string | null;
        link?: {
          __typename?: "reverb_signals_SignalLink";
          text?: string | null;
          url?: string | null;
        } | null;
      } | null> | null;
      counts?: {
        __typename?: "core_apimessages_ListingCounts";
        id?: string | null;
        viewsDisplay?: string | null;
        offersDisplay?: string | null;
        watchersDisplay?: string | null;
        downloadCountDisplay?: string | null;
      } | null;
      digitalDetails?: {
        __typename?: "core_apimessages_ListingDigitalDetails";
        variants?: Array<{
          __typename?: "core_apimessages_ListingDigitalVariant";
          id?: string | null;
          downloadText?: string | null;
          fileUrl?: string | null;
          fileSize?: string | null;
          operatingSystems?: Array<string | null> | null;
          fileTypes?: Array<string | null> | null;
        } | null> | null;
        supportedFormats?: Array<{
          __typename?: "core_apimessages_ListingSupportedFormat";
          operatingSystem?: string | null;
          fileTypes?: Array<string | null> | null;
        } | null> | null;
      } | null;
      expressPay?: {
        __typename?: "core_apimessages_ListingExpressPay";
        _id?: string | null;
        checkoutBundlingId?: string | null;
        paypal?: {
          __typename?: "core_apimessages_CheckoutPaypalDetails";
          currency?: string | null;
        } | null;
        estimatedTotal?: {
          __typename?: "core_apimessages_Money";
          amount?: string | null;
          currency?: string | null;
        } | null;
        googlePay?: {
          __typename?: "core_apimessages_CheckoutGooglePayDetails";
          merchantId?: string | null;
          merchantName?: string | null;
          gatewayMerchantId?: string | null;
          cardNetworks?: Array<core_apimessages_CheckoutGooglePayDetails_CardNetwork | null> | null;
        } | null;
      } | null;
    } | null;
  } | null;
  hfid?: {
    __typename?: "Listing";
    _id?: string | null;
    upc?: string | null;
    finish?: string | null;
    model?: string | null;
    shopUuid?: string | null;
    id?: string | null;
    usOutlet?: boolean | null;
    bumped?: boolean | null;
    categoryUuids?: Array<string | null> | null;
    slug?: string | null;
    title?: string | null;
    description?: string | null;
    listingType?: core_apimessages_Listing_ListingType | null;
    watching?: boolean | null;
    state?: string | null;
    stateDescription?: string | null;
    brandSlug?: string | null;
    categoryRootUuid?: string | null;
    make?: string | null;
    bumpEligible?: boolean | null;
    shopId?: string | null;
    inventory?: number | null;
    soldAsIs?: boolean | null;
    acceptedPaymentMethods?: Array<string | null> | null;
    currency?: string | null;
    sellerId?: string | null;
    offersEnabled?: boolean | null;
    isBuyerOfferEligible?: boolean | null;
    hasInventory?: boolean | null;
    maxBuyerQuantity?: number | null;
    taxIncluded?: boolean | null;
    taxIncludedHint?: string | null;
    adyenCheckoutPaymentMethodsConfig?: string | null;
    condition?: {
      __typename?: "core_apimessages_Condition";
      displayName?: string | null;
      conditionSlug?: string | null;
      conditionUuid?: string | null;
    } | null;
    price?: {
      __typename?: "core_apimessages_Money";
      amountCents?: number | null;
      display?: string | null;
      amount?: string | null;
    } | null;
    pricing?: {
      __typename?: "core_apimessages_ListingPricing";
      originalPriceDescription?: string | null;
      buyerPrice?: {
        __typename?: "core_apimessages_Money";
        display?: string | null;
        currency?: string | null;
        amountCents?: number | null;
        amount?: string | null;
      } | null;
      originalPrice?: {
        __typename?: "core_apimessages_Money";
        display?: string | null;
      } | null;
      ribbon?: {
        __typename?: "core_apimessages_Ribbon";
        display?: string | null;
        reason?: core_apimessages_Ribbon_Reason | null;
      } | null;
      typicalNewPriceDisplay?: {
        __typename?: "core_apimessages_TypicalNewPriceDisplay";
        amountDisplay?: string | null;
      } | null;
    } | null;
    shipping?: {
      __typename?: "core_apimessages_ShippingPrices";
      freeExpeditedShipping?: boolean | null;
      localPickupOnly?: boolean | null;
      localPickup?: boolean | null;
      shippingPrices?: Array<{
        __typename?: "core_apimessages_ShippingPrice";
        _id?: string | null;
        shippingMethod?: core_apimessages_ShippingMethod | null;
        carrierCalculated?: boolean | null;
        destinationPostalCodeNeeded?: boolean | null;
        regional?: boolean | null;
        postalCode?: string | null;
        rate?: {
          __typename?: "core_apimessages_Money";
          amount?: string | null;
          amountCents?: number | null;
          currency?: string | null;
          display?: string | null;
        } | null;
      } | null> | null;
    } | null;
    shop?: {
      __typename?: "Shop";
      _id?: string | null;
      name?: string | null;
      slug?: string | null;
      preferredSeller?: boolean | null;
      quickShipper?: boolean | null;
      quickResponder?: boolean | null;
      paymentPolicy?: string | null;
      createdAt?: any | null;
      id?: string | null;
      address?: {
        __typename?: "core_apimessages_Address";
        _id?: string | null;
        countryCode?: string | null;
        displayLocation?: string | null;
        locality?: string | null;
        region?: string | null;
        country?: {
          __typename?: "core_apimessages_Country";
          _id?: string | null;
          countryCode?: string | null;
          name?: string | null;
        } | null;
      } | null;
      returnPolicy?: {
        __typename?: "core_apimessages_ReturnPolicy";
        _id?: string | null;
        newReturnWindowDays?: number | null;
        usedReturnWindowDays?: number | null;
      } | null;
      orders?: {
        __typename?: "reverb_search_SearchResponse";
        total?: number | null;
      } | null;
      freeDomesticShippingRate?: {
        __typename?: "core_apimessages_ShippingRates";
        regionCode?: string | null;
        freeShippingThreshold?: {
          __typename?: "core_apimessages_Money";
          amountCents?: number | null;
          currency?: string | null;
        } | null;
      } | null;
    } | null;
    categories?: Array<{
      __typename?: "Category";
      _id?: string | null;
      slug?: string | null;
      rootSlug?: string | null;
    } | null> | null;
    csp?: {
      __typename?: "CSP";
      _id?: string | null;
      id?: string | null;
      slug?: string | null;
      brand?: {
        __typename?: "Brand";
        _id?: string | null;
        slug?: string | null;
      } | null;
      videos?: {
        __typename?: "core_apimessages_CSPVideo";
        videoLinks?: Array<{
          __typename?: "core_apimessages_VideoLink";
          youtubeVideoId?: string | null;
        } | null> | null;
      } | null;
      webLink?: {
        __typename?: "core_apimessages_Link";
        href?: string | null;
      } | null;
    } | null;
    publishedAt?: {
      __typename?: "google_protobuf_Timestamp";
      seconds?: number | null;
    } | null;
    sale?: {
      __typename?: "core_apimessages_Sale";
      _id?: string | null;
      id?: string | null;
      code?: string | null;
      buyerIneligibilityReason?: string | null;
    } | null;
    certifiedPreOwned?: {
      __typename?: "core_apimessages_ListingCertifiedPreOwned";
      title?: string | null;
      badgeIconUrl?: string | null;
      description?: string | null;
      tooltipDescription?: string | null;
      brandName?: string | null;
      pageUrl?: string | null;
      lightModeBrandIconUrl?: string | null;
      darkModeBrandIconUrl?: string | null;
    } | null;
    estimatedMonthlyPaymentPlan?: {
      __typename?: "core_apimessages_EstimatedMonthlyPayment";
      financingProgramSlug?: string | null;
    } | null;
    bumpKey?: {
      __typename?: "core_apimessages_BumpKey";
      key?: string | null;
    } | null;
    seller?: {
      __typename?: "User";
      _id?: string | null;
      id?: string | null;
      uuid?: string | null;
      feedbackSummary?: {
        __typename?: "core_apimessages_FeedbackSummary";
        receivedCount?: number | null;
        rollingRatingPercentage?: number | null;
      } | null;
    } | null;
    preorderInfo?: {
      __typename?: "core_apimessages_PreorderInfo";
      onPreorder?: boolean | null;
      estimatedShipDate?: {
        __typename?: "google_protobuf_Timestamp";
        seconds?: number | null;
      } | null;
    } | null;
    watchThumbnails?: Array<{
      __typename?: "core_apimessages_Image";
      _id?: string | null;
      source?: string | null;
    } | null> | null;
    priceRecommendation?: {
      __typename?: "reverb_pricing_PriceRecommendation";
      priceMiddle?: {
        __typename?: "reverb_pricing_Money";
        amountCents?: number | null;
        currency?: string | null;
      } | null;
    } | null;
    images?: Array<{
      __typename?: "core_apimessages_Image";
      _id?: string | null;
      source?: string | null;
    } | null> | null;
    largeSquareImages?: Array<{
      __typename?: "core_apimessages_Image";
      _id?: string | null;
      source?: string | null;
    } | null> | null;
    largeImages?: Array<{
      __typename?: "core_apimessages_Image";
      _id?: string | null;
      source?: string | null;
    } | null> | null;
    supersizeImages?: Array<{
      __typename?: "core_apimessages_Image";
      _id?: string | null;
      source?: string | null;
    } | null> | null;
    cardSquareImages?: Array<{
      __typename?: "core_apimessages_Image";
      _id?: string | null;
      source?: string | null;
    } | null> | null;
    video?: {
      __typename?: "core_apimessages_Video";
      youtubeVideoId?: string | null;
      empty?: boolean | null;
    } | null;
    signals?: Array<{
      __typename?: "reverb_signals_Signal";
      name?: reverb_signals_Signal_Name | null;
      group?: reverb_signals_Signal_Group | null;
      title?: string | null;
      subtitle?: string | null;
      icon?: string | null;
      iconDark?: string | null;
      tooltipText?: string | null;
      link?: {
        __typename?: "reverb_signals_SignalLink";
        text?: string | null;
        url?: string | null;
      } | null;
    } | null> | null;
    counts?: {
      __typename?: "core_apimessages_ListingCounts";
      id?: string | null;
      viewsDisplay?: string | null;
      offersDisplay?: string | null;
      watchersDisplay?: string | null;
      downloadCountDisplay?: string | null;
    } | null;
    digitalDetails?: {
      __typename?: "core_apimessages_ListingDigitalDetails";
      variants?: Array<{
        __typename?: "core_apimessages_ListingDigitalVariant";
        id?: string | null;
        downloadText?: string | null;
        fileUrl?: string | null;
        fileSize?: string | null;
        operatingSystems?: Array<string | null> | null;
        fileTypes?: Array<string | null> | null;
      } | null> | null;
      supportedFormats?: Array<{
        __typename?: "core_apimessages_ListingSupportedFormat";
        operatingSystem?: string | null;
        fileTypes?: Array<string | null> | null;
      } | null> | null;
    } | null;
    expressPay?: {
      __typename?: "core_apimessages_ListingExpressPay";
      _id?: string | null;
      checkoutBundlingId?: string | null;
      paypal?: {
        __typename?: "core_apimessages_CheckoutPaypalDetails";
        currency?: string | null;
      } | null;
      estimatedTotal?: {
        __typename?: "core_apimessages_Money";
        amount?: string | null;
        currency?: string | null;
      } | null;
      googlePay?: {
        __typename?: "core_apimessages_CheckoutGooglePayDetails";
        merchantId?: string | null;
        merchantName?: string | null;
        gatewayMerchantId?: string | null;
        cardNetworks?: Array<core_apimessages_CheckoutGooglePayDetails_CardNetwork | null> | null;
      } | null;
    } | null;
  } | null;
};

export type Core_Dashboard_UpdateShopEuAddressMutationVariables = Exact<{
  input?: InputMaybe<Input_core_apimessages_UpdateAddressRequest>;
}>;

export type Core_Dashboard_UpdateShopEuAddressMutation = {
  __typename?: "Mutation";
  updateMyAddress?: {
    __typename?: "core_apimessages_UpdateAddressResponse";
    address?: {
      __typename?: "core_apimessages_Address";
      _id?: string | null;
      uuid?: string | null;
    } | null;
  } | null;
};

export type DeleteEuAddressMutationVariables = Exact<{
  input?: InputMaybe<Input_core_apimessages_AddressDeleteRequest>;
}>;

export type DeleteEuAddressMutation = {
  __typename?: "Mutation";
  deleteMyAddress?: {
    __typename?: "core_apimessages_DeleteResponse";
    uuid?: string | null;
  } | null;
};

export type Core_Dashboard_EuOdrAddressQueryVariables = Exact<{
  slug?: InputMaybe<Scalars["String"]["input"]>;
}>;

export type Core_Dashboard_EuOdrAddressQuery = {
  __typename?: "Query";
  countries?: {
    __typename?: "core_apimessages_CountriesResponse";
    countries?: Array<{
      __typename?: "core_apimessages_Country";
      _id?: string | null;
      name?: string | null;
      countryCode?: string | null;
      subregions?: Array<{
        __typename?: "core_apimessages_Subregion";
        _id?: string | null;
        name?: string | null;
        code?: string | null;
      } | null> | null;
    } | null> | null;
  } | null;
  shop?: {
    __typename?: "Shop";
    _id?: string | null;
    euOdrAddress?: {
      __typename?: "core_apimessages_Address";
      _id?: string | null;
      name?: string | null;
      streetAddress?: string | null;
      displayLocation?: string | null;
      postalCode?: string | null;
      email?: string | null;
      phone?: string | null;
      countryCode?: string | null;
      region?: string | null;
      locality?: string | null;
      uuid?: string | null;
      country?: {
        __typename?: "core_apimessages_Country";
        _id?: string | null;
        countryCode?: string | null;
        name?: string | null;
      } | null;
    } | null;
  } | null;
};

export type MyShopTaxPolicyDataQueryVariables = Exact<{ [key: string]: never }>;

export type MyShopTaxPolicyDataQuery = {
  __typename?: "Query";
  me?: {
    __typename?: "rql_Me";
    _id?: string | null;
    uuid?: string | null;
    shop?: {
      __typename?: "MyShop";
      _id?: string | null;
      address?: {
        __typename?: "core_apimessages_Address";
        _id?: string | null;
        countryCode?: string | null;
      } | null;
    } | null;
  } | null;
  countries?: {
    __typename?: "core_apimessages_CountriesResponse";
    countries?: Array<{
      __typename?: "core_apimessages_Country";
      _id?: string | null;
      name?: string | null;
      countryCode?: string | null;
      subregions?: Array<{
        __typename?: "core_apimessages_Subregion";
        _id?: string | null;
        name?: string | null;
        code?: string | null;
      } | null> | null;
    } | null> | null;
  } | null;
};

export type Core_Listing_CombineAndSaveRowQueryVariables = Exact<{
  combinedShipping?: InputMaybe<Scalars["Boolean"]["input"]>;
  boostCombinedShippingCategories?: InputMaybe<
    | Array<InputMaybe<Scalars["String"]["input"]>>
    | InputMaybe<Scalars["String"]["input"]>
  >;
  shopId?: InputMaybe<Scalars["String"]["input"]>;
}>;

export type Core_Listing_CombineAndSaveRowQuery = {
  __typename?: "Query";
  listingsSearch?: {
    __typename?: "reverb_search_SearchResponse";
    listings?: Array<{
      __typename?: "Listing";
      _id?: string | null;
      id?: string | null;
      usOutlet?: boolean | null;
      bumped?: boolean | null;
      categoryUuids?: Array<string | null> | null;
      slug?: string | null;
      title?: string | null;
      description?: string | null;
      listingType?: core_apimessages_Listing_ListingType | null;
      watching?: boolean | null;
      state?: string | null;
      stateDescription?: string | null;
      sellerId?: string | null;
      currency?: string | null;
      offersEnabled?: boolean | null;
      isBuyerOfferEligible?: boolean | null;
      brandSlug?: string | null;
      categoryRootUuid?: string | null;
      make?: string | null;
      bumpEligible?: boolean | null;
      shopId?: string | null;
      inventory?: number | null;
      soldAsIs?: boolean | null;
      acceptedPaymentMethods?: Array<string | null> | null;
      condition?: {
        __typename?: "core_apimessages_Condition";
        displayName?: string | null;
        conditionSlug?: string | null;
        conditionUuid?: string | null;
      } | null;
      price?: {
        __typename?: "core_apimessages_Money";
        amountCents?: number | null;
        display?: string | null;
      } | null;
      pricing?: {
        __typename?: "core_apimessages_ListingPricing";
        buyerPrice?: {
          __typename?: "core_apimessages_Money";
          display?: string | null;
          currency?: string | null;
          amountCents?: number | null;
          amount?: string | null;
        } | null;
        originalPrice?: {
          __typename?: "core_apimessages_Money";
          display?: string | null;
        } | null;
        ribbon?: {
          __typename?: "core_apimessages_Ribbon";
          display?: string | null;
          reason?: core_apimessages_Ribbon_Reason | null;
        } | null;
      } | null;
      shipping?: {
        __typename?: "core_apimessages_ShippingPrices";
        freeExpeditedShipping?: boolean | null;
        localPickupOnly?: boolean | null;
        localPickup?: boolean | null;
        shippingPrices?: Array<{
          __typename?: "core_apimessages_ShippingPrice";
          _id?: string | null;
          shippingMethod?: core_apimessages_ShippingMethod | null;
          carrierCalculated?: boolean | null;
          destinationPostalCodeNeeded?: boolean | null;
          rate?: {
            __typename?: "core_apimessages_Money";
            amount?: string | null;
            amountCents?: number | null;
            currency?: string | null;
            display?: string | null;
          } | null;
        } | null> | null;
      } | null;
      shop?: {
        __typename?: "Shop";
        _id?: string | null;
        address?: {
          __typename?: "core_apimessages_Address";
          _id?: string | null;
          countryCode?: string | null;
          country?: {
            __typename?: "core_apimessages_Country";
            _id?: string | null;
            countryCode?: string | null;
            name?: string | null;
          } | null;
        } | null;
        returnPolicy?: {
          __typename?: "core_apimessages_ReturnPolicy";
          _id?: string | null;
          newReturnWindowDays?: number | null;
          usedReturnWindowDays?: number | null;
        } | null;
      } | null;
      preorderInfo?: {
        __typename?: "core_apimessages_PreorderInfo";
        onPreorder?: boolean | null;
        estimatedShipDate?: {
          __typename?: "google_protobuf_Timestamp";
          seconds?: number | null;
        } | null;
      } | null;
      watchThumbnails?: Array<{
        __typename?: "core_apimessages_Image";
        _id?: string | null;
        source?: string | null;
      } | null> | null;
      priceRecommendation?: {
        __typename?: "reverb_pricing_PriceRecommendation";
        priceMiddle?: {
          __typename?: "reverb_pricing_Money";
          amountCents?: number | null;
          currency?: string | null;
        } | null;
      } | null;
      images?: Array<{
        __typename?: "core_apimessages_Image";
        _id?: string | null;
        source?: string | null;
      } | null> | null;
      largeSquareImages?: Array<{
        __typename?: "core_apimessages_Image";
        _id?: string | null;
        source?: string | null;
      } | null> | null;
      certifiedPreOwned?: {
        __typename?: "core_apimessages_ListingCertifiedPreOwned";
        title?: string | null;
        badgeIconUrl?: string | null;
      } | null;
      categories?: Array<{
        __typename?: "Category";
        _id?: string | null;
        slug?: string | null;
        rootSlug?: string | null;
      } | null> | null;
      csp?: {
        __typename?: "CSP";
        _id?: string | null;
        id?: string | null;
        slug?: string | null;
        brand?: {
          __typename?: "Brand";
          _id?: string | null;
          slug?: string | null;
        } | null;
      } | null;
      publishedAt?: {
        __typename?: "google_protobuf_Timestamp";
        seconds?: number | null;
      } | null;
      sale?: {
        __typename?: "core_apimessages_Sale";
        _id?: string | null;
        id?: string | null;
        code?: string | null;
        buyerIneligibilityReason?: string | null;
      } | null;
    } | null> | null;
  } | null;
};

export type Core_Cart_MyCouponListQueryVariables = Exact<{
  [key: string]: never;
}>;

export type Core_Cart_MyCouponListQuery = {
  __typename?: "Query";
  me?: {
    __typename?: "rql_Me";
    _id?: string | null;
    uuid?: string | null;
    buyerCoupons?: {
      __typename?: "core_apimessages_MyBuyerCouponsResponse";
      shopCampaignCoupons?: Array<{
        __typename?: "core_apimessages_ShopCampaignCoupon";
        _id?: string | null;
        code?: string | null;
        descriptionSummary?: string | null;
        restrictionsSummary?: string | null;
        discountValue?: string | null;
        couponHeader?: string | null;
        status?: core_apimessages_ShopCampaignCoupon_Status | null;
        excludedBrands?: Array<{
          __typename?: "Brand";
          _id?: string | null;
          name?: string | null;
        } | null> | null;
        excludedCategories?: Array<{
          __typename?: "Category";
          _id?: string | null;
          name?: string | null;
        } | null> | null;
      } | null> | null;
    } | null;
  } | null;
};

export type Core_Marketplace_CspRowQueryVariables = Exact<{
  autodirects?: InputMaybe<reverb_search_Autodirects>;
  brands?: InputMaybe<
    | Array<InputMaybe<Scalars["String"]["input"]>>
    | InputMaybe<Scalars["String"]["input"]>
  >;
  categories?: InputMaybe<
    | Array<InputMaybe<Scalars["String"]["input"]>>
    | InputMaybe<Scalars["String"]["input"]>
  >;
  condition?: InputMaybe<Scalars["String"]["input"]>;
  contexts?: InputMaybe<
    Array<InputMaybe<reverb_search_Context>> | InputMaybe<reverb_search_Context>
  >;
  countryOfOrigin?: InputMaybe<
    | Array<InputMaybe<Scalars["String"]["input"]>>
    | InputMaybe<Scalars["String"]["input"]>
  >;
  curatedSetSlugs?: InputMaybe<
    | Array<InputMaybe<Scalars["String"]["input"]>>
    | InputMaybe<Scalars["String"]["input"]>
  >;
  currency?: InputMaybe<Scalars["String"]["input"]>;
  decades?: InputMaybe<
    | Array<InputMaybe<Scalars["String"]["input"]>>
    | InputMaybe<Scalars["String"]["input"]>
  >;
  fullTextQuery?: InputMaybe<Scalars["String"]["input"]>;
  listingsThatShipTo?: InputMaybe<Scalars["String"]["input"]>;
  priceMin?: InputMaybe<Scalars["String"]["input"]>;
  priceMax?: InputMaybe<Scalars["String"]["input"]>;
  sort?: InputMaybe<reverb_search_CSPSearchRequest_Sort>;
  traitValues?: InputMaybe<
    | Array<InputMaybe<Scalars["String"]["input"]>>
    | InputMaybe<Scalars["String"]["input"]>
  >;
  yearMax?: InputMaybe<Scalars["Int"]["input"]>;
  yearMin?: InputMaybe<Scalars["Int"]["input"]>;
}>;

export type Core_Marketplace_CspRowQuery = {
  __typename?: "Query";
  cspSearch?: {
    __typename?: "reverb_search_SearchResponse";
    csps?: Array<{
      __typename?: "CSP";
      _id?: string | null;
      id?: string | null;
      title?: string | null;
      slug?: string | null;
      averageReviewRating?: number | null;
      reviewsCount?: number | null;
      finishes?: Array<string | null> | null;
      webLink?: {
        __typename?: "core_apimessages_Link";
        path?: string | null;
        href?: string | null;
      } | null;
      image?: {
        __typename?: "core_apimessages_Image";
        _id?: string | null;
        source?: string | null;
      } | null;
      brand?: {
        __typename?: "Brand";
        _id?: string | null;
        slug?: string | null;
        name?: string | null;
      } | null;
      categories?: Array<{
        __typename?: "Category";
        _id?: string | null;
        slug?: string | null;
        rootSlug?: string | null;
        name?: string | null;
        parentCategories?: Array<{
          __typename?: "Category";
          _id?: string | null;
          name?: string | null;
          slug?: string | null;
        } | null> | null;
      } | null> | null;
      inventory?: {
        __typename?: "core_apimessages_CSPInventory";
        usedTotal?: number | null;
        newTotal?: number | null;
        newLowPrice?: {
          __typename?: "core_apimessages_Money";
          display?: string | null;
          amount?: string | null;
          amountCents?: number | null;
          currency?: string | null;
        } | null;
        usedLowPrice?: {
          __typename?: "core_apimessages_Money";
          display?: string | null;
          amount?: string | null;
          amountCents?: number | null;
          currency?: string | null;
        } | null;
      } | null;
    } | null> | null;
  } | null;
};

export type Category_Under_Price_QueryQueryVariables = Exact<{
  [key: string]: never;
}>;

export type Category_Under_Price_QueryQuery = {
  __typename?: "Query";
  me?: {
    __typename?: "rql_Me";
    _id?: string | null;
    uuid?: string | null;
    profile?: {
      __typename?: "mparticle_ProfileResponse";
      topProductTypeUuids?: Array<string | null> | null;
    } | null;
  } | null;
  exchangeRates?: {
    __typename?: "reverb_config_cache_CurrencyResponse";
    rates?: Array<{
      __typename?: "reverb_config_cache_ExchangeRate";
      from?: string | null;
      to?: string | null;
      rate?: number | null;
    } | null> | null;
  } | null;
};

export type Core_MosaicTilesHomepageQueryVariables = Exact<{
  [key: string]: never;
}>;

export type Core_MosaicTilesHomepageQuery = {
  __typename?: "Query";
  mosaicTilesHomepage?: {
    __typename?: "core_apimessages_MosaicTilesResponse";
    mosaicTiles?: Array<{
      __typename?: "core_apimessages_MosaicTile";
      id?: string | null;
      url?: string | null;
      imageUrl?: string | null;
      position?: core_apimessages_MosaicTilePosition | null;
      imageTitle?: string | null;
      imageSubtitle?: string | null;
      contentSponsorship?: {
        __typename?: "core_apimessages_ContentSponsorship";
        sponsorshipType?: core_apimessages_ContentSponsorship_SponsorshipType | null;
        partnershipName?: string | null;
      } | null;
    } | null> | null;
  } | null;
};

export type RecentSearchListingDataFragment = {
  __typename?: "Listing";
  _id?: string | null;
  id?: string | null;
  images?: Array<{
    __typename?: "core_apimessages_Image";
    _id?: string | null;
    source?: string | null;
  } | null> | null;
};

export type Core_Homepage_RecentSearchesListingsSearchQueryVariables = Exact<{
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  query0?: InputMaybe<Scalars["String"]["input"]>;
  query1?: InputMaybe<Scalars["String"]["input"]>;
  query2?: InputMaybe<Scalars["String"]["input"]>;
  skipQuery2: Scalars["Boolean"]["input"];
  query3?: InputMaybe<Scalars["String"]["input"]>;
  skipQuery3: Scalars["Boolean"]["input"];
}>;

export type Core_Homepage_RecentSearchesListingsSearchQuery = {
  __typename?: "Query";
  recentSearchListings0?: {
    __typename?: "reverb_search_SearchResponse";
    total?: number | null;
    listings?: Array<{
      __typename?: "Listing";
      _id?: string | null;
      slug?: string | null;
      id?: string | null;
      images?: Array<{
        __typename?: "core_apimessages_Image";
        _id?: string | null;
        source?: string | null;
      } | null> | null;
    } | null> | null;
  } | null;
  recentSearchListings1?: {
    __typename?: "reverb_search_SearchResponse";
    total?: number | null;
    listings?: Array<{
      __typename?: "Listing";
      _id?: string | null;
      slug?: string | null;
      id?: string | null;
      images?: Array<{
        __typename?: "core_apimessages_Image";
        _id?: string | null;
        source?: string | null;
      } | null> | null;
    } | null> | null;
  } | null;
  recentSearchListings2?: {
    __typename?: "reverb_search_SearchResponse";
    total?: number | null;
    listings?: Array<{
      __typename?: "Listing";
      _id?: string | null;
      slug?: string | null;
      id?: string | null;
      images?: Array<{
        __typename?: "core_apimessages_Image";
        _id?: string | null;
        source?: string | null;
      } | null> | null;
    } | null> | null;
  } | null;
  recentSearchListings3?: {
    __typename?: "reverb_search_SearchResponse";
    total?: number | null;
    listings?: Array<{
      __typename?: "Listing";
      _id?: string | null;
      slug?: string | null;
      id?: string | null;
      images?: Array<{
        __typename?: "core_apimessages_Image";
        _id?: string | null;
        source?: string | null;
      } | null> | null;
    } | null> | null;
  } | null;
};

export type Just_Listed_For_You_QueryQueryVariables = Exact<{
  [key: string]: never;
}>;

export type Just_Listed_For_You_QueryQuery = {
  __typename?: "Query";
  me?: {
    __typename?: "rql_Me";
    _id?: string | null;
    uuid?: string | null;
    profile?: {
      __typename?: "mparticle_ProfileResponse";
      topProductTypeUuids?: Array<string | null> | null;
      topProductTypes?: Array<string | null> | null;
    } | null;
  } | null;
};

export type Core_HomepageDependenciesQueryVariables = Exact<{
  [key: string]: never;
}>;

export type Core_HomepageDependenciesQuery = {
  __typename?: "Query";
  me?: {
    __typename?: "rql_Me";
    _id?: string | null;
    uuid?: string | null;
    feed?: {
      __typename?: "reverb_feed_FeedResponse";
      total?: number | null;
    } | null;
  } | null;
};

export type LoggedOutMParticleProfileQueryQueryVariables = Exact<{
  mpid?: InputMaybe<Scalars["String"]["input"]>;
}>;

export type LoggedOutMParticleProfileQueryQuery = {
  __typename?: "Query";
  me?: {
    __typename?: "rql_Me";
    _id?: string | null;
    uuid?: string | null;
    profile?: {
      __typename?: "mparticle_ProfileResponse";
      topMostRecentProductTypes?: Array<string | null> | null;
    } | null;
  } | null;
};

export type Regional_Listings_Row_QueryQueryVariables = Exact<{
  itemRegion?: InputMaybe<Scalars["String"]["input"]>;
  conditionSlugs?: InputMaybe<
    | Array<InputMaybe<Scalars["String"]["input"]>>
    | InputMaybe<Scalars["String"]["input"]>
  >;
  sort?: InputMaybe<reverb_search_ListingsSearchRequest_Sort>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
}>;

export type Regional_Listings_Row_QueryQuery = {
  __typename?: "Query";
  listingsSearch?: {
    __typename?: "reverb_search_SearchResponse";
    listings?: Array<{
      __typename?: "Listing";
      _id?: string | null;
      id?: string | null;
      usOutlet?: boolean | null;
      bumped?: boolean | null;
      categoryUuids?: Array<string | null> | null;
      slug?: string | null;
      title?: string | null;
      description?: string | null;
      listingType?: core_apimessages_Listing_ListingType | null;
      watching?: boolean | null;
      state?: string | null;
      stateDescription?: string | null;
      sellerId?: string | null;
      currency?: string | null;
      offersEnabled?: boolean | null;
      isBuyerOfferEligible?: boolean | null;
      brandSlug?: string | null;
      categoryRootUuid?: string | null;
      make?: string | null;
      bumpEligible?: boolean | null;
      shopId?: string | null;
      inventory?: number | null;
      soldAsIs?: boolean | null;
      acceptedPaymentMethods?: Array<string | null> | null;
      condition?: {
        __typename?: "core_apimessages_Condition";
        displayName?: string | null;
        conditionSlug?: string | null;
        conditionUuid?: string | null;
      } | null;
      price?: {
        __typename?: "core_apimessages_Money";
        amountCents?: number | null;
        display?: string | null;
      } | null;
      pricing?: {
        __typename?: "core_apimessages_ListingPricing";
        buyerPrice?: {
          __typename?: "core_apimessages_Money";
          display?: string | null;
          currency?: string | null;
          amountCents?: number | null;
          amount?: string | null;
        } | null;
        originalPrice?: {
          __typename?: "core_apimessages_Money";
          display?: string | null;
        } | null;
        ribbon?: {
          __typename?: "core_apimessages_Ribbon";
          display?: string | null;
          reason?: core_apimessages_Ribbon_Reason | null;
        } | null;
      } | null;
      shipping?: {
        __typename?: "core_apimessages_ShippingPrices";
        freeExpeditedShipping?: boolean | null;
        localPickupOnly?: boolean | null;
        localPickup?: boolean | null;
        shippingPrices?: Array<{
          __typename?: "core_apimessages_ShippingPrice";
          _id?: string | null;
          shippingMethod?: core_apimessages_ShippingMethod | null;
          carrierCalculated?: boolean | null;
          destinationPostalCodeNeeded?: boolean | null;
          rate?: {
            __typename?: "core_apimessages_Money";
            amount?: string | null;
            amountCents?: number | null;
            currency?: string | null;
            display?: string | null;
          } | null;
        } | null> | null;
      } | null;
      shop?: {
        __typename?: "Shop";
        _id?: string | null;
        address?: {
          __typename?: "core_apimessages_Address";
          _id?: string | null;
          countryCode?: string | null;
          country?: {
            __typename?: "core_apimessages_Country";
            _id?: string | null;
            countryCode?: string | null;
            name?: string | null;
          } | null;
        } | null;
        returnPolicy?: {
          __typename?: "core_apimessages_ReturnPolicy";
          _id?: string | null;
          newReturnWindowDays?: number | null;
          usedReturnWindowDays?: number | null;
        } | null;
      } | null;
      preorderInfo?: {
        __typename?: "core_apimessages_PreorderInfo";
        onPreorder?: boolean | null;
        estimatedShipDate?: {
          __typename?: "google_protobuf_Timestamp";
          seconds?: number | null;
        } | null;
      } | null;
      watchThumbnails?: Array<{
        __typename?: "core_apimessages_Image";
        _id?: string | null;
        source?: string | null;
      } | null> | null;
      priceRecommendation?: {
        __typename?: "reverb_pricing_PriceRecommendation";
        priceMiddle?: {
          __typename?: "reverb_pricing_Money";
          amountCents?: number | null;
          currency?: string | null;
        } | null;
      } | null;
      images?: Array<{
        __typename?: "core_apimessages_Image";
        _id?: string | null;
        source?: string | null;
      } | null> | null;
      largeSquareImages?: Array<{
        __typename?: "core_apimessages_Image";
        _id?: string | null;
        source?: string | null;
      } | null> | null;
      certifiedPreOwned?: {
        __typename?: "core_apimessages_ListingCertifiedPreOwned";
        title?: string | null;
        badgeIconUrl?: string | null;
      } | null;
      categories?: Array<{
        __typename?: "Category";
        _id?: string | null;
        slug?: string | null;
        rootSlug?: string | null;
      } | null> | null;
      csp?: {
        __typename?: "CSP";
        _id?: string | null;
        id?: string | null;
        slug?: string | null;
        brand?: {
          __typename?: "Brand";
          _id?: string | null;
          slug?: string | null;
        } | null;
      } | null;
      publishedAt?: {
        __typename?: "google_protobuf_Timestamp";
        seconds?: number | null;
      } | null;
      sale?: {
        __typename?: "core_apimessages_Sale";
        _id?: string | null;
        id?: string | null;
        code?: string | null;
        buyerIneligibilityReason?: string | null;
      } | null;
    } | null> | null;
  } | null;
};

export type Core_ReverbHeaderQueryVariables = Exact<{
  isShopAdmin: Scalars["Boolean"]["input"];
}>;

export type Core_ReverbHeaderQuery = {
  __typename?: "Query";
  me?: {
    __typename?: "rql_Me";
    _id?: string | null;
    uuid?: string | null;
    shop?: {
      __typename?: "MyShop";
      _id?: string | null;
      slug?: string | null;
      paymentMethod?: core_apimessages_MyShopOnboardingResponse_PaymentMethod | null;
    } | null;
    reverbCredits?: Array<{
      __typename?: "core_apimessages_Money";
      display?: string | null;
      currency?: string | null;
    } | null> | null;
    payoutBalances?: Array<{
      __typename?: "core_apimessages_Money";
      display?: string | null;
      currency?: string | null;
    } | null> | null;
    cart?: {
      __typename?: "rql_MyCart";
      cartItems?: Array<{
        __typename?: "core_apimessages_CartItem";
        _id?: string | null;
        uuid?: string | null;
        quantity?: number | null;
        listingTotal?: {
          __typename?: "core_apimessages_CartItem_ListingTotal";
          discounted?: boolean | null;
          discount?: {
            __typename?: "core_apimessages_CartItem_ListingTotal_Discount";
            type?: core_apimessages_CartItem_ListingTotal_Discount_Type | null;
            label?: string | null;
          } | null;
          unitPrice?: {
            __typename?: "core_apimessages_Money";
            display?: string | null;
          } | null;
          originalUnitPrice?: {
            __typename?: "core_apimessages_Money";
            display?: string | null;
          } | null;
        } | null;
        listing?: {
          __typename?: "Listing";
          _id?: string | null;
          title?: string | null;
          images?: Array<{
            __typename?: "core_apimessages_Image";
            source?: string | null;
          } | null> | null;
        } | null;
      } | null> | null;
    } | null;
  } | null;
};

export type ReverbHeaderCartBadgeDataFragment = {
  __typename?: "rql_Me";
  _id?: string | null;
  uuid?: string | null;
  reverbCredits?: Array<{
    __typename?: "core_apimessages_Money";
    display?: string | null;
    currency?: string | null;
  } | null> | null;
  cart?: {
    __typename?: "rql_MyCart";
    cartItems?: Array<{
      __typename?: "core_apimessages_CartItem";
      _id?: string | null;
      uuid?: string | null;
      quantity?: number | null;
      listingTotal?: {
        __typename?: "core_apimessages_CartItem_ListingTotal";
        discounted?: boolean | null;
        discount?: {
          __typename?: "core_apimessages_CartItem_ListingTotal_Discount";
          type?: core_apimessages_CartItem_ListingTotal_Discount_Type | null;
          label?: string | null;
        } | null;
        unitPrice?: {
          __typename?: "core_apimessages_Money";
          display?: string | null;
        } | null;
        originalUnitPrice?: {
          __typename?: "core_apimessages_Money";
          display?: string | null;
        } | null;
      } | null;
      listing?: {
        __typename?: "Listing";
        _id?: string | null;
        title?: string | null;
        images?: Array<{
          __typename?: "core_apimessages_Image";
          source?: string | null;
        } | null> | null;
      } | null;
    } | null> | null;
  } | null;
};

export type ReverbHeaderCartItemFieldsFragment = {
  __typename?: "rql_Me";
  _id?: string | null;
  uuid?: string | null;
  cart?: {
    __typename?: "rql_MyCart";
    cartItems?: Array<{
      __typename?: "core_apimessages_CartItem";
      _id?: string | null;
      uuid?: string | null;
      quantity?: number | null;
      listingTotal?: {
        __typename?: "core_apimessages_CartItem_ListingTotal";
        discounted?: boolean | null;
        discount?: {
          __typename?: "core_apimessages_CartItem_ListingTotal_Discount";
          type?: core_apimessages_CartItem_ListingTotal_Discount_Type | null;
          label?: string | null;
        } | null;
        unitPrice?: {
          __typename?: "core_apimessages_Money";
          display?: string | null;
        } | null;
        originalUnitPrice?: {
          __typename?: "core_apimessages_Money";
          display?: string | null;
        } | null;
      } | null;
      listing?: {
        __typename?: "Listing";
        _id?: string | null;
        title?: string | null;
        images?: Array<{
          __typename?: "core_apimessages_Image";
          source?: string | null;
        } | null> | null;
      } | null;
    } | null> | null;
  } | null;
};

export type Core_Sale_HeaderQueryVariables = Exact<{
  collectionType?: InputMaybe<core_apimessages_CollectionHeader_CollectionType>;
  collectionSlug?: InputMaybe<Scalars["String"]["input"]>;
}>;

export type Core_Sale_HeaderQuery = {
  __typename?: "Query";
  collectionHeader?: {
    __typename?: "CollectionHeader";
    _id?: string | null;
    title?: string | null;
    metaTitle?: string | null;
    collectionId?: string | null;
    collectionType?: core_apimessages_CollectionHeader_CollectionType | null;
    description?: string | null;
    sale?: {
      __typename?: "core_apimessages_Sale";
      _id?: string | null;
      id?: string | null;
      buyerIneligibilityReason?: string | null;
      state?: core_apimessages_Sale_SaleState | null;
      percent?: number | null;
      discountValue?: string | null;
      code?: string | null;
      shopName?: string | null;
      shopSlug?: string | null;
      saleType?: string | null;
      startsAt?: {
        __typename?: "google_protobuf_Timestamp";
        seconds?: number | null;
      } | null;
      endsAt?: {
        __typename?: "google_protobuf_Timestamp";
        seconds?: number | null;
      } | null;
    } | null;
    fullBleedImage?: {
      __typename?: "core_apimessages_Image";
      _id?: string | null;
      source?: string | null;
    } | null;
  } | null;
};

export type Core_ShippingLabel_EditShipmentQueryVariables = Exact<{
  shipmentId?: InputMaybe<Scalars["String"]["input"]>;
  checkoutUuid?: InputMaybe<Scalars["String"]["input"]>;
  actionableStatuses?: InputMaybe<
    | Array<InputMaybe<Scalars["String"]["input"]>>
    | InputMaybe<Scalars["String"]["input"]>
  >;
}>;

export type Core_ShippingLabel_EditShipmentQuery = {
  __typename?: "Query";
  me?: {
    __typename?: "rql_Me";
    _id?: string | null;
    uuid?: string | null;
    shipments?: Array<{
      __typename?: "Shipment";
      _id?: string | null;
      id?: string | null;
      shippingProtection?: boolean | null;
      signatureRequired?: boolean | null;
      mediaMailQualified?: boolean | null;
      toAddressIsResidential?: boolean | null;
      orders?: Array<{
        __typename?: "Order";
        _id?: string | null;
        id?: string | null;
        legacyOrderId?: string | null;
      } | null> | null;
      toAddress?: {
        __typename?: "core_apimessages_Address";
        _id?: string | null;
        name?: string | null;
        streetAddress?: string | null;
        extendedAddress?: string | null;
        postalCode?: string | null;
        phone?: string | null;
        region?: string | null;
        locality?: string | null;
        countryCode?: string | null;
      } | null;
      fromAddress?: {
        __typename?: "core_apimessages_Address";
        _id?: string | null;
        name?: string | null;
        streetAddress?: string | null;
        extendedAddress?: string | null;
        postalCode?: string | null;
        phone?: string | null;
        region?: string | null;
        locality?: string | null;
        countryCode?: string | null;
      } | null;
      package?: {
        __typename?: "core_apimessages_ShippingLabelPackage";
        predefinedPackage?: string | null;
        length?: {
          __typename?: "core_apimessages_Measurement";
          value?: string | null;
          unit?: string | null;
        } | null;
        height?: {
          __typename?: "core_apimessages_Measurement";
          value?: string | null;
          unit?: string | null;
        } | null;
        width?: {
          __typename?: "core_apimessages_Measurement";
          value?: string | null;
          unit?: string | null;
        } | null;
        weight?: {
          __typename?: "core_apimessages_Measurement";
          value?: string | null;
          unit?: string | null;
        } | null;
      } | null;
      shippingProtectionPrice?: {
        __typename?: "core_apimessages_Money";
        amount?: string | null;
        amountCents?: number | null;
        currency?: string | null;
        symbol?: string | null;
      } | null;
    } | null> | null;
    sellerCheckout?: {
      __typename?: "SellerCheckout";
      _id?: string | null;
      ordersForShipmentSearch?: {
        __typename?: "reverb_search_SearchResponse";
        ordersForShipment?: Array<{
          __typename?: "Order";
          _id?: string | null;
          id?: string | null;
          buyerUuid?: string | null;
          shippingMethod?: core_apimessages_ShippingMethod | null;
          legacyOrderId?: string | null;
          quantity?: number | null;
          listingId?: string | null;
          orderType?: string | null;
          carrierCalculatedCarrier?: string | null;
          listing?: {
            __typename?: "Listing";
            _id?: string | null;
            title?: string | null;
            merchandisingType?: string | null;
            images?: Array<{
              __typename?: "core_apimessages_Image";
              _id?: string | null;
              source?: string | null;
            } | null> | null;
          } | null;
          settlementAmountProductSubtotal?: {
            __typename?: "core_apimessages_Money";
            amount?: string | null;
            amountCents?: number | null;
            currency?: string | null;
            symbol?: string | null;
          } | null;
          createdAt?: {
            __typename?: "google_protobuf_Timestamp";
            seconds?: number | null;
          } | null;
          paidAt?: {
            __typename?: "google_protobuf_Timestamp";
            seconds?: number | null;
          } | null;
          customsInfo?: {
            __typename?: "core_apimessages_OrderCustomsInfo";
            description?: string | null;
            tariffNumber?: string | null;
            categoryName?: string | null;
            countryOfOrigin?: string | null;
          } | null;
          amountShipping?: {
            __typename?: "core_apimessages_Money";
            amount?: string | null;
            amountCents?: number | null;
            currency?: string | null;
            symbol?: string | null;
            display?: string | null;
          } | null;
          carrierCalculatedShippingAmount?: {
            __typename?: "core_apimessages_Money";
            amount?: string | null;
            amountCents?: number | null;
            currency?: string | null;
            symbol?: string | null;
            display?: string | null;
          } | null;
        } | null> | null;
      } | null;
      flatRateShippingOptions?: {
        __typename?: "core_apimessages_OrderFlatRateShippingOptionsResponse";
        flatRateShippingOptions?: Array<{
          __typename?: "core_apimessages_FlatRateShippingOption";
          carrier?: string | null;
          displayTitle?: string | null;
          displayName?: string | null;
          optionValue?: string | null;
          displayDimensions?: string | null;
          maximumPounds?: number | null;
          displayPosition?: number | null;
        } | null> | null;
      } | null;
    } | null;
  } | null;
  countries?: {
    __typename?: "core_apimessages_CountriesResponse";
    countries?: Array<{
      __typename?: "core_apimessages_Country";
      _id?: string | null;
      name?: string | null;
      countryCode?: string | null;
      subregionRequired?: boolean | null;
      subregions?: Array<{
        __typename?: "core_apimessages_Subregion";
        _id?: string | null;
        code?: string | null;
        name?: string | null;
      } | null> | null;
    } | null> | null;
  } | null;
};

export type Core_ShippingLabel_EditShipmentForManualOrderQueryVariables =
  Exact<{
    shipmentId?: InputMaybe<Scalars["String"]["input"]>;
    orderUuid?: InputMaybe<Scalars["String"]["input"]>;
  }>;

export type Core_ShippingLabel_EditShipmentForManualOrderQuery = {
  __typename?: "Query";
  me?: {
    __typename?: "rql_Me";
    _id?: string | null;
    uuid?: string | null;
    shipments?: Array<{
      __typename?: "Shipment";
      _id?: string | null;
      id?: string | null;
      shippingProtection?: boolean | null;
      signatureRequired?: boolean | null;
      mediaMailQualified?: boolean | null;
      orders?: Array<{
        __typename?: "Order";
        _id?: string | null;
        id?: string | null;
        legacyOrderId?: string | null;
      } | null> | null;
      toAddress?: {
        __typename?: "core_apimessages_Address";
        _id?: string | null;
        name?: string | null;
        streetAddress?: string | null;
        extendedAddress?: string | null;
        postalCode?: string | null;
        phone?: string | null;
        region?: string | null;
        locality?: string | null;
        countryCode?: string | null;
      } | null;
      fromAddress?: {
        __typename?: "core_apimessages_Address";
        _id?: string | null;
        name?: string | null;
        streetAddress?: string | null;
        extendedAddress?: string | null;
        postalCode?: string | null;
        phone?: string | null;
        region?: string | null;
        locality?: string | null;
        countryCode?: string | null;
      } | null;
      package?: {
        __typename?: "core_apimessages_ShippingLabelPackage";
        predefinedPackage?: string | null;
        length?: {
          __typename?: "core_apimessages_Measurement";
          value?: string | null;
          unit?: string | null;
        } | null;
        height?: {
          __typename?: "core_apimessages_Measurement";
          value?: string | null;
          unit?: string | null;
        } | null;
        width?: {
          __typename?: "core_apimessages_Measurement";
          value?: string | null;
          unit?: string | null;
        } | null;
        weight?: {
          __typename?: "core_apimessages_Measurement";
          value?: string | null;
          unit?: string | null;
        } | null;
      } | null;
      shippingProtectionPrice?: {
        __typename?: "core_apimessages_Money";
        amount?: string | null;
        amountCents?: number | null;
        currency?: string | null;
        symbol?: string | null;
      } | null;
    } | null> | null;
  } | null;
  order?: {
    __typename?: "Order";
    _id?: string | null;
    id?: string | null;
    buyerUuid?: string | null;
    shippingMethod?: core_apimessages_ShippingMethod | null;
    legacyOrderId?: string | null;
    quantity?: number | null;
    listingId?: string | null;
    orderType?: string | null;
    carrierCalculatedCarrier?: string | null;
    listing?: {
      __typename?: "Listing";
      _id?: string | null;
      title?: string | null;
      merchandisingType?: string | null;
      images?: Array<{
        __typename?: "core_apimessages_Image";
        _id?: string | null;
        source?: string | null;
      } | null> | null;
    } | null;
    flatRateShippingOptions?: {
      __typename?: "core_apimessages_OrderFlatRateShippingOptionsResponse";
      flatRateShippingOptions?: Array<{
        __typename?: "core_apimessages_FlatRateShippingOption";
        carrier?: string | null;
        displayTitle?: string | null;
        displayName?: string | null;
        optionValue?: string | null;
        displayDimensions?: string | null;
        maximumPounds?: number | null;
        displayPosition?: number | null;
      } | null> | null;
    } | null;
    settlementAmountProductSubtotal?: {
      __typename?: "core_apimessages_Money";
      amount?: string | null;
      amountCents?: number | null;
      currency?: string | null;
      symbol?: string | null;
    } | null;
    createdAt?: {
      __typename?: "google_protobuf_Timestamp";
      seconds?: number | null;
    } | null;
    paidAt?: {
      __typename?: "google_protobuf_Timestamp";
      seconds?: number | null;
    } | null;
    customsInfo?: {
      __typename?: "core_apimessages_OrderCustomsInfo";
      description?: string | null;
      tariffNumber?: string | null;
      categoryName?: string | null;
      countryOfOrigin?: string | null;
    } | null;
    amountShipping?: {
      __typename?: "core_apimessages_Money";
      amount?: string | null;
      amountCents?: number | null;
      currency?: string | null;
      symbol?: string | null;
      display?: string | null;
    } | null;
    carrierCalculatedShippingAmount?: {
      __typename?: "core_apimessages_Money";
      amount?: string | null;
      amountCents?: number | null;
      currency?: string | null;
      symbol?: string | null;
      display?: string | null;
    } | null;
  } | null;
  countries?: {
    __typename?: "core_apimessages_CountriesResponse";
    countries?: Array<{
      __typename?: "core_apimessages_Country";
      _id?: string | null;
      name?: string | null;
      countryCode?: string | null;
      subregionRequired?: boolean | null;
      subregions?: Array<{
        __typename?: "core_apimessages_Subregion";
        _id?: string | null;
        code?: string | null;
        name?: string | null;
      } | null> | null;
    } | null> | null;
  } | null;
};

export type MultiOrderCustomsInfoQueryQueryVariables = Exact<{
  [key: string]: never;
}>;

export type MultiOrderCustomsInfoQueryQuery = {
  __typename?: "Query";
  exchangeRates?: {
    __typename?: "reverb_config_cache_CurrencyResponse";
    rates?: Array<{
      __typename?: "reverb_config_cache_ExchangeRate";
      from?: string | null;
      to?: string | null;
      rate?: number | null;
    } | null> | null;
  } | null;
};

export type ShippingLabelOrderDataFragment = {
  __typename?: "Order";
  _id?: string | null;
  id?: string | null;
  buyerUuid?: string | null;
  shippingMethod?: core_apimessages_ShippingMethod | null;
  legacyOrderId?: string | null;
  quantity?: number | null;
  listingId?: string | null;
  orderType?: string | null;
  carrierCalculatedCarrier?: string | null;
  settlementAmountProductSubtotal?: {
    __typename?: "core_apimessages_Money";
    amount?: string | null;
    amountCents?: number | null;
    currency?: string | null;
    symbol?: string | null;
  } | null;
  createdAt?: {
    __typename?: "google_protobuf_Timestamp";
    seconds?: number | null;
  } | null;
  paidAt?: {
    __typename?: "google_protobuf_Timestamp";
    seconds?: number | null;
  } | null;
  customsInfo?: {
    __typename?: "core_apimessages_OrderCustomsInfo";
    description?: string | null;
    tariffNumber?: string | null;
    categoryName?: string | null;
    countryOfOrigin?: string | null;
  } | null;
  amountShipping?: {
    __typename?: "core_apimessages_Money";
    amount?: string | null;
    amountCents?: number | null;
    currency?: string | null;
    symbol?: string | null;
    display?: string | null;
  } | null;
  carrierCalculatedShippingAmount?: {
    __typename?: "core_apimessages_Money";
    amount?: string | null;
    amountCents?: number | null;
    currency?: string | null;
    symbol?: string | null;
    display?: string | null;
  } | null;
};

export type ShippingLabelCountriesDataFragment = {
  __typename?: "core_apimessages_Country";
  _id?: string | null;
  name?: string | null;
  countryCode?: string | null;
  subregionRequired?: boolean | null;
  subregions?: Array<{
    __typename?: "core_apimessages_Subregion";
    _id?: string | null;
    code?: string | null;
    name?: string | null;
  } | null> | null;
};

export type ShippingLabelListingDataFragment = {
  __typename?: "Listing";
  _id?: string | null;
  title?: string | null;
  merchandisingType?: string | null;
  images?: Array<{
    __typename?: "core_apimessages_Image";
    _id?: string | null;
    source?: string | null;
  } | null> | null;
};

export type Core_ShippingLabel_FormQueryVariables = Exact<{
  checkoutUuid?: InputMaybe<Scalars["String"]["input"]>;
  actionableStatuses?: InputMaybe<
    | Array<InputMaybe<Scalars["String"]["input"]>>
    | InputMaybe<Scalars["String"]["input"]>
  >;
}>;

export type Core_ShippingLabel_FormQuery = {
  __typename?: "Query";
  me?: {
    __typename?: "rql_Me";
    _id?: string | null;
    uuid?: string | null;
    sellerCheckout?: {
      __typename?: "SellerCheckout";
      _id?: string | null;
      ordersForShipmentSearch?: {
        __typename?: "reverb_search_SearchResponse";
        ordersForShipment?: Array<{
          __typename?: "Order";
          _id?: string | null;
          id?: string | null;
          buyerUuid?: string | null;
          shippingMethod?: core_apimessages_ShippingMethod | null;
          legacyOrderId?: string | null;
          quantity?: number | null;
          listingId?: string | null;
          orderType?: string | null;
          carrierCalculatedCarrier?: string | null;
          listing?: {
            __typename?: "Listing";
            _id?: string | null;
            title?: string | null;
            merchandisingType?: string | null;
            shipmentPackage?: {
              __typename?: "ShipmentPackage";
              _id?: string | null;
              width?: {
                __typename?: "core_apimessages_Measurement";
                value?: string | null;
                unit?: string | null;
              } | null;
              height?: {
                __typename?: "core_apimessages_Measurement";
                value?: string | null;
                unit?: string | null;
              } | null;
              length?: {
                __typename?: "core_apimessages_Measurement";
                value?: string | null;
                unit?: string | null;
              } | null;
              weight?: {
                __typename?: "core_apimessages_Measurement";
                value?: string | null;
                unit?: string | null;
              } | null;
            } | null;
            images?: Array<{
              __typename?: "core_apimessages_Image";
              _id?: string | null;
              source?: string | null;
            } | null> | null;
          } | null;
          settlementAmountProductSubtotal?: {
            __typename?: "core_apimessages_Money";
            amount?: string | null;
            amountCents?: number | null;
            currency?: string | null;
            symbol?: string | null;
          } | null;
          createdAt?: {
            __typename?: "google_protobuf_Timestamp";
            seconds?: number | null;
          } | null;
          paidAt?: {
            __typename?: "google_protobuf_Timestamp";
            seconds?: number | null;
          } | null;
          customsInfo?: {
            __typename?: "core_apimessages_OrderCustomsInfo";
            description?: string | null;
            tariffNumber?: string | null;
            categoryName?: string | null;
            countryOfOrigin?: string | null;
          } | null;
          amountShipping?: {
            __typename?: "core_apimessages_Money";
            amount?: string | null;
            amountCents?: number | null;
            currency?: string | null;
            symbol?: string | null;
            display?: string | null;
          } | null;
          carrierCalculatedShippingAmount?: {
            __typename?: "core_apimessages_Money";
            amount?: string | null;
            amountCents?: number | null;
            currency?: string | null;
            symbol?: string | null;
            display?: string | null;
          } | null;
        } | null> | null;
      } | null;
      verifiedShippingAddress?: {
        __typename?: "core_apimessages_OrderVerifyShippingAddressResponse";
        verificationStatus?: core_apimessages_AddressVerificationStatus | null;
        isResidential?: boolean | null;
        address?: {
          __typename?: "core_apimessages_Address";
          _id?: string | null;
          name?: string | null;
          streetAddress?: string | null;
          extendedAddress?: string | null;
          locality?: string | null;
          region?: string | null;
          postalCode?: string | null;
          countryCode?: string | null;
          phone?: string | null;
          regionName?: string | null;
          country?: {
            __typename?: "core_apimessages_Country";
            _id?: string | null;
            name?: string | null;
          } | null;
        } | null;
      } | null;
      flatRateShippingOptions?: {
        __typename?: "core_apimessages_OrderFlatRateShippingOptionsResponse";
        flatRateShippingOptions?: Array<{
          __typename?: "core_apimessages_FlatRateShippingOption";
          carrier?: string | null;
          displayTitle?: string | null;
          displayName?: string | null;
          optionValue?: string | null;
          displayDimensions?: string | null;
          maximumPounds?: number | null;
          displayPosition?: number | null;
        } | null> | null;
      } | null;
    } | null;
    shop?: {
      __typename?: "MyShop";
      _id?: string | null;
      address?: {
        __typename?: "core_apimessages_Address";
        _id?: string | null;
        name?: string | null;
        streetAddress?: string | null;
        extendedAddress?: string | null;
        locality?: string | null;
        region?: string | null;
        postalCode?: string | null;
        countryCode?: string | null;
        phone?: string | null;
        regionName?: string | null;
        country?: {
          __typename?: "core_apimessages_Country";
          _id?: string | null;
          name?: string | null;
        } | null;
      } | null;
    } | null;
  } | null;
  countries?: {
    __typename?: "core_apimessages_CountriesResponse";
    countries?: Array<{
      __typename?: "core_apimessages_Country";
      _id?: string | null;
      name?: string | null;
      countryCode?: string | null;
      subregionRequired?: boolean | null;
      subregions?: Array<{
        __typename?: "core_apimessages_Subregion";
        _id?: string | null;
        code?: string | null;
        name?: string | null;
      } | null> | null;
    } | null> | null;
  } | null;
};

export type Core_ShippingLabel_FormForManualOrderQueryVariables = Exact<{
  orderUuid?: InputMaybe<Scalars["String"]["input"]>;
}>;

export type Core_ShippingLabel_FormForManualOrderQuery = {
  __typename?: "Query";
  order?: {
    __typename?: "Order";
    _id?: string | null;
    id?: string | null;
    buyerUuid?: string | null;
    shippingMethod?: core_apimessages_ShippingMethod | null;
    legacyOrderId?: string | null;
    quantity?: number | null;
    listingId?: string | null;
    orderType?: string | null;
    carrierCalculatedCarrier?: string | null;
    listing?: {
      __typename?: "Listing";
      _id?: string | null;
      title?: string | null;
      merchandisingType?: string | null;
      images?: Array<{
        __typename?: "core_apimessages_Image";
        _id?: string | null;
        source?: string | null;
      } | null> | null;
    } | null;
    verifiedShippingAddress?: {
      __typename?: "core_apimessages_OrderVerifyShippingAddressResponse";
      verificationStatus?: core_apimessages_AddressVerificationStatus | null;
      isResidential?: boolean | null;
      address?: {
        __typename?: "core_apimessages_Address";
        _id?: string | null;
        name?: string | null;
        streetAddress?: string | null;
        extendedAddress?: string | null;
        locality?: string | null;
        region?: string | null;
        postalCode?: string | null;
        countryCode?: string | null;
        phone?: string | null;
        regionName?: string | null;
      } | null;
    } | null;
    flatRateShippingOptions?: {
      __typename?: "core_apimessages_OrderFlatRateShippingOptionsResponse";
      flatRateShippingOptions?: Array<{
        __typename?: "core_apimessages_FlatRateShippingOption";
        carrier?: string | null;
        displayTitle?: string | null;
        displayName?: string | null;
        optionValue?: string | null;
        displayDimensions?: string | null;
        maximumPounds?: number | null;
        displayPosition?: number | null;
      } | null> | null;
    } | null;
    settlementAmountProductSubtotal?: {
      __typename?: "core_apimessages_Money";
      amount?: string | null;
      amountCents?: number | null;
      currency?: string | null;
      symbol?: string | null;
    } | null;
    createdAt?: {
      __typename?: "google_protobuf_Timestamp";
      seconds?: number | null;
    } | null;
    paidAt?: {
      __typename?: "google_protobuf_Timestamp";
      seconds?: number | null;
    } | null;
    customsInfo?: {
      __typename?: "core_apimessages_OrderCustomsInfo";
      description?: string | null;
      tariffNumber?: string | null;
      categoryName?: string | null;
      countryOfOrigin?: string | null;
    } | null;
    amountShipping?: {
      __typename?: "core_apimessages_Money";
      amount?: string | null;
      amountCents?: number | null;
      currency?: string | null;
      symbol?: string | null;
      display?: string | null;
    } | null;
    carrierCalculatedShippingAmount?: {
      __typename?: "core_apimessages_Money";
      amount?: string | null;
      amountCents?: number | null;
      currency?: string | null;
      symbol?: string | null;
      display?: string | null;
    } | null;
  } | null;
  me?: {
    __typename?: "rql_Me";
    _id?: string | null;
    uuid?: string | null;
    shop?: {
      __typename?: "MyShop";
      _id?: string | null;
      address?: {
        __typename?: "core_apimessages_Address";
        _id?: string | null;
        name?: string | null;
        streetAddress?: string | null;
        extendedAddress?: string | null;
        locality?: string | null;
        region?: string | null;
        postalCode?: string | null;
        countryCode?: string | null;
        phone?: string | null;
        regionName?: string | null;
      } | null;
    } | null;
  } | null;
  countries?: {
    __typename?: "core_apimessages_CountriesResponse";
    countries?: Array<{
      __typename?: "core_apimessages_Country";
      _id?: string | null;
      name?: string | null;
      countryCode?: string | null;
      subregionRequired?: boolean | null;
      subregions?: Array<{
        __typename?: "core_apimessages_Subregion";
        _id?: string | null;
        code?: string | null;
        name?: string | null;
      } | null> | null;
    } | null> | null;
  } | null;
};

export type ShippingProtectionMutationVariables = Exact<{
  input?: InputMaybe<Input_core_apimessages_ShippingProtectionRequest>;
}>;

export type ShippingProtectionMutation = {
  __typename?: "Mutation";
  shippingProtection?: {
    __typename?: "core_apimessages_ShippingProtectionResponse";
    shippingProtectionStatus?: core_apimessages_ShippingProtectionStatus | null;
    signatureRequiredStatus?: core_apimessages_SignatureRequiredStatus | null;
    price?: {
      __typename?: "core_apimessages_Money";
      amount?: string | null;
      amountCents?: number | null;
      currency?: string | null;
      symbol?: string | null;
      display?: string | null;
    } | null;
    signatureRequiredCosts?: Array<{
      __typename?: "core_apimessages_SignatureRequiredCost";
      carrier?: string | null;
      amount?: {
        __typename?: "core_apimessages_Money";
        display?: string | null;
      } | null;
    } | null> | null;
  } | null;
};

export type CreateShipmentMutationVariables = Exact<{
  input?: InputMaybe<Input_core_apimessages_CreateShipmentRequest>;
}>;

export type CreateShipmentMutation = {
  __typename?: "Mutation";
  createShipment?: {
    __typename?: "Shipment";
    _id?: string | null;
    id?: string | null;
    shippingProtection?: boolean | null;
    signatureRequired?: boolean | null;
    mediaMailQualified?: boolean | null;
    defaultPaymentMethod?: core_apimessages_FeePaymentMethod | null;
    shippingMethod?: core_apimessages_ShippingMethod | null;
    toAddressIsResidential?: boolean | null;
    toAddress?: {
      __typename?: "core_apimessages_Address";
      _id?: string | null;
      name?: string | null;
      streetAddress?: string | null;
      extendedAddress?: string | null;
      postalCode?: string | null;
      phone?: string | null;
      region?: string | null;
      locality?: string | null;
      countryCode?: string | null;
    } | null;
    fromAddress?: {
      __typename?: "core_apimessages_Address";
      _id?: string | null;
      name?: string | null;
      streetAddress?: string | null;
      extendedAddress?: string | null;
      postalCode?: string | null;
      phone?: string | null;
      region?: string | null;
      locality?: string | null;
      countryCode?: string | null;
    } | null;
    package?: {
      __typename?: "core_apimessages_ShippingLabelPackage";
      predefinedPackage?: string | null;
      length?: {
        __typename?: "core_apimessages_Measurement";
        value?: string | null;
        unit?: string | null;
      } | null;
      height?: {
        __typename?: "core_apimessages_Measurement";
        value?: string | null;
        unit?: string | null;
      } | null;
      width?: {
        __typename?: "core_apimessages_Measurement";
        value?: string | null;
        unit?: string | null;
      } | null;
      weight?: {
        __typename?: "core_apimessages_Measurement";
        value?: string | null;
        unit?: string | null;
      } | null;
    } | null;
    carrierRates?: Array<{
      __typename?: "core_apimessages_CarrierRateGroup";
      displayPosition?: number | null;
      carrier?: string | null;
      rates?: Array<{
        __typename?: "core_apimessages_CarrierRate";
        displayPosition?: number | null;
        uuid?: string | null;
        carrier?: string | null;
        service?: string | null;
        description?: string | null;
        paymentMethod?: core_apimessages_FeePaymentMethod | null;
        expeditedOrFaster?: boolean | null;
        rackRateAmount?: {
          __typename?: "core_apimessages_Money";
          amount?: string | null;
          amountCents?: number | null;
          currency?: string | null;
          symbol?: string | null;
          display?: string | null;
        } | null;
        shippingProtectionAmount?: {
          __typename?: "core_apimessages_Money";
          amount?: string | null;
          amountCents?: number | null;
          currency?: string | null;
          symbol?: string | null;
          display?: string | null;
        } | null;
        reverbDiscountAmount?: {
          __typename?: "core_apimessages_Money";
          amount?: string | null;
          amountCents?: number | null;
          currency?: string | null;
          symbol?: string | null;
          display?: string | null;
        } | null;
        totalAmount?: {
          __typename?: "core_apimessages_Money";
          amount?: string | null;
          amountCents?: number | null;
          currency?: string | null;
          symbol?: string | null;
          display?: string | null;
        } | null;
        signatureRequiredAmount?: {
          __typename?: "core_apimessages_Money";
          amount?: string | null;
          amountCents?: number | null;
          currency?: string | null;
          symbol?: string | null;
          display?: string | null;
        } | null;
      } | null> | null;
    } | null> | null;
  } | null;
};

export type PurchaseShippingRateMutationVariables = Exact<{
  input?: InputMaybe<Input_core_apimessages_PurchaseShippingRateRequest>;
}>;

export type PurchaseShippingRateMutation = {
  __typename?: "Mutation";
  purchaseShippingRate?: {
    __typename?: "core_apimessages_PurchaseShippingRateResponse";
    errors?: Array<{
      __typename?: "core_apimessages_Error";
      message?: string | null;
    } | null> | null;
  } | null;
};

export type Core_ShippingLabel_ShipmentsQueryVariables = Exact<{
  shipmentId?: InputMaybe<Scalars["String"]["input"]>;
}>;

export type Core_ShippingLabel_ShipmentsQuery = {
  __typename?: "Query";
  me?: {
    __typename?: "rql_Me";
    _id?: string | null;
    uuid?: string | null;
    shipments?: Array<{
      __typename?: "Shipment";
      _id?: string | null;
      id?: string | null;
      shippingProtection?: boolean | null;
      signatureRequired?: boolean | null;
      mediaMailQualified?: boolean | null;
      defaultPaymentMethod?: core_apimessages_FeePaymentMethod | null;
      shippingMethod?: core_apimessages_ShippingMethod | null;
      orders?: Array<{
        __typename?: "Order";
        _id?: string | null;
        id?: string | null;
        legacyOrderId?: string | null;
      } | null> | null;
      toAddress?: {
        __typename?: "core_apimessages_Address";
        _id?: string | null;
        name?: string | null;
        streetAddress?: string | null;
        extendedAddress?: string | null;
        postalCode?: string | null;
        phone?: string | null;
        region?: string | null;
        locality?: string | null;
        countryCode?: string | null;
      } | null;
      fromAddress?: {
        __typename?: "core_apimessages_Address";
        _id?: string | null;
        name?: string | null;
        streetAddress?: string | null;
        extendedAddress?: string | null;
        postalCode?: string | null;
        phone?: string | null;
        region?: string | null;
        locality?: string | null;
        countryCode?: string | null;
      } | null;
      package?: {
        __typename?: "core_apimessages_ShippingLabelPackage";
        predefinedPackage?: string | null;
        length?: {
          __typename?: "core_apimessages_Measurement";
          value?: string | null;
          unit?: string | null;
        } | null;
        height?: {
          __typename?: "core_apimessages_Measurement";
          value?: string | null;
          unit?: string | null;
        } | null;
        width?: {
          __typename?: "core_apimessages_Measurement";
          value?: string | null;
          unit?: string | null;
        } | null;
        weight?: {
          __typename?: "core_apimessages_Measurement";
          value?: string | null;
          unit?: string | null;
        } | null;
      } | null;
      carrierRates?: Array<{
        __typename?: "core_apimessages_CarrierRateGroup";
        displayPosition?: number | null;
        carrier?: string | null;
        rates?: Array<{
          __typename?: "core_apimessages_CarrierRate";
          displayPosition?: number | null;
          uuid?: string | null;
          carrier?: string | null;
          service?: string | null;
          description?: string | null;
          paymentMethod?: core_apimessages_FeePaymentMethod | null;
          expeditedOrFaster?: boolean | null;
          rackRateAmount?: {
            __typename?: "core_apimessages_Money";
            amount?: string | null;
            amountCents?: number | null;
            currency?: string | null;
            symbol?: string | null;
            display?: string | null;
          } | null;
          shippingProtectionAmount?: {
            __typename?: "core_apimessages_Money";
            amount?: string | null;
            amountCents?: number | null;
            currency?: string | null;
            symbol?: string | null;
            display?: string | null;
          } | null;
          reverbDiscountAmount?: {
            __typename?: "core_apimessages_Money";
            amount?: string | null;
            amountCents?: number | null;
            currency?: string | null;
            symbol?: string | null;
            display?: string | null;
          } | null;
          totalAmount?: {
            __typename?: "core_apimessages_Money";
            amount?: string | null;
            amountCents?: number | null;
            currency?: string | null;
            symbol?: string | null;
            display?: string | null;
          } | null;
          signatureRequiredAmount?: {
            __typename?: "core_apimessages_Money";
            amount?: string | null;
            amountCents?: number | null;
            currency?: string | null;
            symbol?: string | null;
            display?: string | null;
          } | null;
        } | null> | null;
      } | null> | null;
    } | null> | null;
  } | null;
};

export type ShopContainerFragmentFragment = {
  __typename?: "Shop";
  _id?: string | null;
  id?: string | null;
  showSold?: boolean | null;
  slug?: string | null;
  freeDomesticShippingRate?: {
    __typename?: "core_apimessages_ShippingRates";
    regionCode?: string | null;
    freeShippingThreshold?: {
      __typename?: "core_apimessages_Money";
      display?: string | null;
    } | null;
  } | null;
  sales?: Array<{
    __typename?: "core_apimessages_Sale";
    _id?: string | null;
    slug?: string | null;
    name?: string | null;
    discountValue?: string | null;
    buyerIneligibilityReason?: string | null;
    endsAt?: {
      __typename?: "google_protobuf_Timestamp";
      seconds?: number | null;
    } | null;
  } | null> | null;
};

export type MyShopCampaignCouponFieldsFragment = {
  __typename?: "core_apimessages_ShopCampaignCoupon";
  _id?: string | null;
  code?: string | null;
  discountValue?: string | null;
  descriptionSummary?: string | null;
  restrictionsSummary?: string | null;
  expiresAt?: any | null;
  status?: core_apimessages_ShopCampaignCoupon_Status | null;
  couponHeader?: string | null;
  applyToNewConditions?: boolean | null;
  applyToUsedConditions?: boolean | null;
  applyToBStockConditions?: boolean | null;
  excludedBrands?: Array<{
    __typename?: "Brand";
    _id?: string | null;
    name?: string | null;
    slug?: string | null;
  } | null> | null;
  excludedCategories?: Array<{
    __typename?: "Category";
    _id?: string | null;
    name?: string | null;
    id?: string | null;
  } | null> | null;
};

export type Core_Shop_ListingsCountQueryVariables = Exact<{
  shop?: InputMaybe<Scalars["String"]["input"]>;
}>;

export type Core_Shop_ListingsCountQuery = {
  __typename?: "Query";
  shopListingsLive?: {
    __typename?: "reverb_search_SearchResponse";
    total?: number | null;
  } | null;
  shopListingsAll?: {
    __typename?: "reverb_search_SearchResponse";
    total?: number | null;
  } | null;
};

export type Core_Shop_FindFavoriteQueryVariables = Exact<{
  shop?: InputMaybe<Scalars["String"]["input"]>;
  loggedOut: Scalars["Boolean"]["input"];
}>;

export type Core_Shop_FindFavoriteQuery = {
  __typename?: "Query";
  findFavorite?: {
    __typename?: "core_apimessages_FindFavoriteResponse";
    favorite?: {
      __typename?: "core_apimessages_Favorite";
      id?: string | null;
    } | null;
  } | null;
};

export type Core_Shop_ContainerQueryVariables = Exact<{
  shop?: InputMaybe<Scalars["String"]["input"]>;
}>;

export type Core_Shop_ContainerQuery = {
  __typename?: "Query";
  shop?: {
    __typename?: "Shop";
    _id?: string | null;
    id?: string | null;
    showSold?: boolean | null;
    slug?: string | null;
    name?: string | null;
    preferredSeller?: boolean | null;
    quickShipper?: boolean | null;
    quickResponder?: boolean | null;
    description?: string | null;
    userUuid?: string | null;
    userId?: string | null;
    freeDomesticShippingRate?: {
      __typename?: "core_apimessages_ShippingRates";
      regionCode?: string | null;
      freeShippingThreshold?: {
        __typename?: "core_apimessages_Money";
        display?: string | null;
      } | null;
    } | null;
    sales?: Array<{
      __typename?: "core_apimessages_Sale";
      _id?: string | null;
      slug?: string | null;
      name?: string | null;
      discountValue?: string | null;
      buyerIneligibilityReason?: string | null;
      endsAt?: {
        __typename?: "google_protobuf_Timestamp";
        seconds?: number | null;
      } | null;
    } | null> | null;
    address?: {
      __typename?: "core_apimessages_Address";
      _id?: string | null;
      displayLocation?: string | null;
      countryCode?: string | null;
    } | null;
    banner?: {
      __typename?: "core_apimessages_Image";
      _id?: string | null;
      source?: string | null;
    } | null;
    user?: {
      __typename?: "User";
      _id?: string | null;
      id?: string | null;
      avatar?: {
        __typename?: "core_apimessages_Image";
        _id?: string | null;
        source?: string | null;
      } | null;
      feedbackSummary?: {
        __typename?: "core_apimessages_FeedbackSummary";
        rollingRatingPercentage?: number | null;
        receivedCount?: number | null;
      } | null;
    } | null;
  } | null;
  me?: {
    __typename?: "rql_Me";
    _id?: string | null;
    uuid?: string | null;
    buyerCoupons?: {
      __typename?: "core_apimessages_MyBuyerCouponsResponse";
      shopCampaignCoupons?: Array<{
        __typename?: "core_apimessages_ShopCampaignCoupon";
        _id?: string | null;
        code?: string | null;
        discountValue?: string | null;
        descriptionSummary?: string | null;
        restrictionsSummary?: string | null;
        expiresAt?: any | null;
        status?: core_apimessages_ShopCampaignCoupon_Status | null;
        couponHeader?: string | null;
        applyToNewConditions?: boolean | null;
        applyToUsedConditions?: boolean | null;
        applyToBStockConditions?: boolean | null;
        excludedBrands?: Array<{
          __typename?: "Brand";
          _id?: string | null;
          name?: string | null;
          slug?: string | null;
        } | null> | null;
        excludedCategories?: Array<{
          __typename?: "Category";
          _id?: string | null;
          name?: string | null;
          id?: string | null;
        } | null> | null;
      } | null> | null;
    } | null;
  } | null;
};

export type Core_Shop_FeedbackQueryVariables = Exact<{
  shop?: InputMaybe<Scalars["String"]["input"]>;
}>;

export type Core_Shop_FeedbackQuery = {
  __typename?: "Query";
  shop?: {
    __typename?: "Shop";
    _id?: string | null;
    id?: string | null;
    slug?: string | null;
  } | null;
};

export type ShopHeaderFragmentFragment = {
  __typename?: "Shop";
  _id?: string | null;
  id?: string | null;
  slug?: string | null;
  name?: string | null;
  preferredSeller?: boolean | null;
  quickShipper?: boolean | null;
  quickResponder?: boolean | null;
  description?: string | null;
  userUuid?: string | null;
  userId?: string | null;
  address?: {
    __typename?: "core_apimessages_Address";
    _id?: string | null;
    displayLocation?: string | null;
  } | null;
  banner?: {
    __typename?: "core_apimessages_Image";
    _id?: string | null;
    source?: string | null;
  } | null;
  user?: {
    __typename?: "User";
    _id?: string | null;
    id?: string | null;
    avatar?: {
      __typename?: "core_apimessages_Image";
      _id?: string | null;
      source?: string | null;
    } | null;
  } | null;
};

export type Core_Shop_InfoQueryVariables = Exact<{
  shop?: InputMaybe<Scalars["String"]["input"]>;
}>;

export type Core_Shop_InfoQuery = {
  __typename?: "Query";
  shop?: {
    __typename?: "Shop";
    _id?: string | null;
    id?: string | null;
    description?: string | null;
    website?: string | null;
    name?: string | null;
    shippingPolicy?: string | null;
    manufacturersSafetyInformation?: string | null;
    userUuid?: string | null;
    video?: {
      __typename?: "core_apimessages_Video";
      youtubeVideoId?: string | null;
      empty?: boolean | null;
    } | null;
    returnPolicy?: {
      __typename?: "core_apimessages_ReturnPolicy";
      _id?: string | null;
      newReturnWindowDays?: number | null;
      usedReturnWindowDays?: number | null;
      restockingFeePercent?: number | null;
      specialConditions?: string | null;
    } | null;
    taxPolicies?: Array<{
      __typename?: "core_apimessages_ShopTaxPolicy";
      displayLocation?: string | null;
      displayRate?: string | null;
      policyType?: core_apimessages_ShopTaxPolicy_PolicyType | null;
    } | null> | null;
  } | null;
};

export type ShopMetadataFragmentFragment = {
  __typename?: "Shop";
  _id?: string | null;
  name?: string | null;
  slug?: string | null;
  user?: {
    __typename?: "User";
    _id?: string | null;
    feedbackSummary?: {
      __typename?: "core_apimessages_FeedbackSummary";
      rollingRatingPercentage?: number | null;
      receivedCount?: number | null;
    } | null;
  } | null;
};

export type ShopReturnPoliciesFragmentFragment = {
  __typename?: "Shop";
  _id?: string | null;
  userUuid?: string | null;
  returnPolicy?: {
    __typename?: "core_apimessages_ReturnPolicy";
    _id?: string | null;
    newReturnWindowDays?: number | null;
    usedReturnWindowDays?: number | null;
    restockingFeePercent?: number | null;
    specialConditions?: string | null;
  } | null;
};

export type ShopTaxPoliciesDataFragment = {
  __typename?: "Shop";
  _id?: string | null;
  taxPolicies?: Array<{
    __typename?: "core_apimessages_ShopTaxPolicy";
    displayLocation?: string | null;
    displayRate?: string | null;
    policyType?: core_apimessages_ShopTaxPolicy_PolicyType | null;
  } | null> | null;
};

export type ShopTabsFragmentFragment = {
  __typename?: "Shop";
  _id?: string | null;
  user?: {
    __typename?: "User";
    _id?: string | null;
    feedbackSummary?: {
      __typename?: "core_apimessages_FeedbackSummary";
      rollingRatingPercentage?: number | null;
      receivedCount?: number | null;
    } | null;
  } | null;
};

export type Core_Listing_SimilarListingsGridQueryVariables = Exact<{
  acceptsAffirm?: InputMaybe<Scalars["Boolean"]["input"]>;
  acceptsGiftCards?: InputMaybe<Scalars["Boolean"]["input"]>;
  acceptsOffers?: InputMaybe<Scalars["Boolean"]["input"]>;
  acceptsPaymentPlans?: InputMaybe<Scalars["Boolean"]["input"]>;
  brandSlugs?: InputMaybe<
    | Array<InputMaybe<Scalars["String"]["input"]>>
    | InputMaybe<Scalars["String"]["input"]>
  >;
  categorySlugs?: InputMaybe<
    | Array<InputMaybe<Scalars["String"]["input"]>>
    | InputMaybe<Scalars["String"]["input"]>
  >;
  combinedShipping?: InputMaybe<Scalars["Boolean"]["input"]>;
  conditionSlugs?: InputMaybe<
    | Array<InputMaybe<Scalars["String"]["input"]>>
    | InputMaybe<Scalars["String"]["input"]>
  >;
  contexts?: InputMaybe<
    Array<InputMaybe<reverb_search_Context>> | InputMaybe<reverb_search_Context>
  >;
  cspSlug?: InputMaybe<Scalars["String"]["input"]>;
  countryOfOrigin?: InputMaybe<
    | Array<InputMaybe<Scalars["String"]["input"]>>
    | InputMaybe<Scalars["String"]["input"]>
  >;
  curatedSetSlugs?: InputMaybe<
    | Array<InputMaybe<Scalars["String"]["input"]>>
    | InputMaybe<Scalars["String"]["input"]>
  >;
  currency?: InputMaybe<Scalars["String"]["input"]>;
  decades?: InputMaybe<
    | Array<InputMaybe<Scalars["String"]["input"]>>
    | InputMaybe<Scalars["String"]["input"]>
  >;
  fallbackToOr?: InputMaybe<Scalars["Boolean"]["input"]>;
  freeShipping?: InputMaybe<Scalars["Boolean"]["input"]>;
  freeExpeditedShipping?: InputMaybe<Scalars["Boolean"]["input"]>;
  handmade?: InputMaybe<Scalars["Boolean"]["input"]>;
  holidaySale?: InputMaybe<Scalars["Boolean"]["input"]>;
  itemCity?: InputMaybe<
    | Array<InputMaybe<Scalars["String"]["input"]>>
    | InputMaybe<Scalars["String"]["input"]>
  >;
  itemRegion?: InputMaybe<Scalars["String"]["input"]>;
  itemState?: InputMaybe<
    | Array<InputMaybe<Scalars["String"]["input"]>>
    | InputMaybe<Scalars["String"]["input"]>
  >;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  localPickup?: InputMaybe<Scalars["Boolean"]["input"]>;
  minSaleDiscountPercent?: InputMaybe<Scalars["String"]["input"]>;
  multiClientExperiments?: InputMaybe<
    | Array<InputMaybe<Input_reverb_search_ExperimentData>>
    | InputMaybe<Input_reverb_search_ExperimentData>
  >;
  preferredSeller?: InputMaybe<Scalars["Boolean"]["input"]>;
  priceMax?: InputMaybe<Scalars["String"]["input"]>;
  priceMin?: InputMaybe<Scalars["String"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  onSale?: InputMaybe<Scalars["Boolean"]["input"]>;
  query?: InputMaybe<Scalars["String"]["input"]>;
  saleSlugs?: InputMaybe<
    | Array<InputMaybe<Scalars["String"]["input"]>>
    | InputMaybe<Scalars["String"]["input"]>
  >;
  shippingRegionCode?: InputMaybe<
    | Array<InputMaybe<Scalars["String"]["input"]>>
    | InputMaybe<Scalars["String"]["input"]>
  >;
  showOnlySold?: InputMaybe<Scalars["Boolean"]["input"]>;
  shopSlug?: InputMaybe<Scalars["String"]["input"]>;
  showSold?: InputMaybe<Scalars["Boolean"]["input"]>;
  similarListingIds?: InputMaybe<
    | Array<InputMaybe<Scalars["String"]["input"]>>
    | InputMaybe<Scalars["String"]["input"]>
  >;
  sortSlug?: InputMaybe<Scalars["String"]["input"]>;
  terminateEarly?: InputMaybe<Scalars["Boolean"]["input"]>;
  traitValues?: InputMaybe<
    | Array<InputMaybe<Scalars["String"]["input"]>>
    | InputMaybe<Scalars["String"]["input"]>
  >;
  useExperimentalRecall?: InputMaybe<Scalars["Boolean"]["input"]>;
  withAggregations?: InputMaybe<
    | Array<InputMaybe<reverb_search_ListingsSearchRequest_Aggregation>>
    | InputMaybe<reverb_search_ListingsSearchRequest_Aggregation>
  >;
  withProximityFilter?: InputMaybe<Input_reverb_search_ProximityFilterRequest>;
  yearMax?: InputMaybe<Scalars["String"]["input"]>;
  yearMin?: InputMaybe<Scalars["String"]["input"]>;
}>;

export type Core_Listing_SimilarListingsGridQuery = {
  __typename?: "Query";
  listingsSearch?: {
    __typename?: "reverb_search_SearchResponse";
    total?: number | null;
    offset?: number | null;
    limit?: number | null;
    listings?: Array<{
      __typename?: "Listing";
      _id?: string | null;
      id?: string | null;
      sellerId?: string | null;
      listingType?: core_apimessages_Listing_ListingType | null;
      title?: string | null;
      slug?: string | null;
      make?: string | null;
      model?: string | null;
      upc?: string | null;
      state?: string | null;
      stateDescription?: string | null;
      bumped?: boolean | null;
      watching?: boolean | null;
      soldAsIs?: boolean | null;
      usOutlet?: boolean | null;
      offersEnabled?: boolean | null;
      isBuyerOfferEligible?: boolean | null;
      currency?: string | null;
      brandSlug?: string | null;
      categoryRootUuid?: string | null;
      categoryUuids?: Array<string | null> | null;
      bumpEligible?: boolean | null;
      shopId?: string | null;
      inventory?: number | null;
      acceptedPaymentMethods?: Array<string | null> | null;
      pricing?: {
        __typename?: "core_apimessages_ListingPricing";
        buyerPrice?: {
          __typename?: "core_apimessages_Money";
          amount?: string | null;
          amountCents?: number | null;
          display?: string | null;
          currency?: string | null;
        } | null;
        originalPrice?: {
          __typename?: "core_apimessages_Money";
          display?: string | null;
        } | null;
        ribbon?: {
          __typename?: "core_apimessages_Ribbon";
          display?: string | null;
          reason?: core_apimessages_Ribbon_Reason | null;
        } | null;
      } | null;
      preorderInfo?: {
        __typename?: "core_apimessages_PreorderInfo";
        onPreorder?: boolean | null;
        estimatedShipDate?: {
          __typename?: "google_protobuf_Timestamp";
          seconds?: number | null;
        } | null;
      } | null;
      publishedAt?: {
        __typename?: "google_protobuf_Timestamp";
        seconds?: number | null;
      } | null;
      condition?: {
        __typename?: "core_apimessages_Condition";
        displayName?: string | null;
        conditionSlug?: string | null;
        conditionUuid?: string | null;
      } | null;
      images?: Array<{
        __typename?: "core_apimessages_Image";
        source?: string | null;
      } | null> | null;
      shipping?: {
        __typename?: "core_apimessages_ShippingPrices";
        freeExpeditedShipping?: boolean | null;
        localPickupOnly?: boolean | null;
        localPickup?: boolean | null;
        shippingPrices?: Array<{
          __typename?: "core_apimessages_ShippingPrice";
          _id?: string | null;
          shippingMethod?: core_apimessages_ShippingMethod | null;
          carrierCalculated?: boolean | null;
          destinationPostalCodeNeeded?: boolean | null;
          regional?: boolean | null;
          postalCode?: string | null;
          rate?: {
            __typename?: "core_apimessages_Money";
            amount?: string | null;
            amountCents?: number | null;
            currency?: string | null;
            display?: string | null;
          } | null;
        } | null> | null;
      } | null;
      shop?: {
        __typename?: "Shop";
        _id?: string | null;
        name?: string | null;
        returnPolicy?: {
          __typename?: "core_apimessages_ReturnPolicy";
          usedReturnWindowDays?: number | null;
          newReturnWindowDays?: number | null;
          _id?: string | null;
        } | null;
        address?: {
          __typename?: "core_apimessages_Address";
          _id?: string | null;
          locality?: string | null;
          region?: string | null;
          displayLocation?: string | null;
          countryCode?: string | null;
          country?: {
            __typename?: "core_apimessages_Country";
            _id?: string | null;
            countryCode?: string | null;
            name?: string | null;
          } | null;
        } | null;
      } | null;
      watchThumbnails?: Array<{
        __typename?: "core_apimessages_Image";
        _id?: string | null;
        source?: string | null;
      } | null> | null;
      priceRecommendation?: {
        __typename?: "reverb_pricing_PriceRecommendation";
        priceMiddle?: {
          __typename?: "reverb_pricing_Money";
          amountCents?: number | null;
          currency?: string | null;
        } | null;
      } | null;
      certifiedPreOwned?: {
        __typename?: "core_apimessages_ListingCertifiedPreOwned";
        title?: string | null;
        badgeIconUrl?: string | null;
      } | null;
      categories?: Array<{
        __typename?: "Category";
        _id?: string | null;
        slug?: string | null;
        rootSlug?: string | null;
      } | null> | null;
      csp?: {
        __typename?: "CSP";
        _id?: string | null;
        id?: string | null;
        slug?: string | null;
        brand?: {
          __typename?: "Brand";
          _id?: string | null;
          slug?: string | null;
        } | null;
      } | null;
      sale?: {
        __typename?: "core_apimessages_Sale";
        _id?: string | null;
        id?: string | null;
        code?: string | null;
        buyerIneligibilityReason?: string | null;
      } | null;
    } | null> | null;
    filters?: Array<{
      __typename?: "reverb_search_Filter";
      name?: string | null;
      key?: string | null;
      aggregationName?: string | null;
      widgetType?: reverb_search_Filter_WidgetType | null;
      options?: Array<{
        __typename?: "reverb_search_FilterOption";
        selected?: boolean | null;
        autoselected?: boolean | null;
        name?: string | null;
        paramName?: string | null;
        setValues?: Array<string | null> | null;
        unsetValues?: Array<string | null> | null;
        all?: boolean | null;
        optionValue?: string | null;
        trackingValue?: string | null;
        count?: {
          __typename?: "google_protobuf_Int32Value";
          value?: number | null;
        } | null;
        subFilter?: {
          __typename?: "reverb_search_Filter";
          widgetType?: reverb_search_Filter_WidgetType | null;
          options?: Array<{
            __typename?: "reverb_search_FilterOption";
            name?: string | null;
            selected?: boolean | null;
            autoselected?: boolean | null;
            paramName?: string | null;
            setValues?: Array<string | null> | null;
            unsetValues?: Array<string | null> | null;
            all?: boolean | null;
            optionValue?: string | null;
            trackingValue?: string | null;
            count?: {
              __typename?: "google_protobuf_Int32Value";
              value?: number | null;
            } | null;
            subFilter?: {
              __typename?: "reverb_search_Filter";
              widgetType?: reverb_search_Filter_WidgetType | null;
              options?: Array<{
                __typename?: "reverb_search_FilterOption";
                name?: string | null;
                selected?: boolean | null;
                autoselected?: boolean | null;
                paramName?: string | null;
                setValues?: Array<string | null> | null;
                unsetValues?: Array<string | null> | null;
                all?: boolean | null;
                optionValue?: string | null;
                trackingValue?: string | null;
                count?: {
                  __typename?: "google_protobuf_Int32Value";
                  value?: number | null;
                } | null;
                subFilter?: {
                  __typename?: "reverb_search_Filter";
                  widgetType?: reverb_search_Filter_WidgetType | null;
                  options?: Array<{
                    __typename?: "reverb_search_FilterOption";
                    name?: string | null;
                    selected?: boolean | null;
                    autoselected?: boolean | null;
                    paramName?: string | null;
                    setValues?: Array<string | null> | null;
                    unsetValues?: Array<string | null> | null;
                    all?: boolean | null;
                    optionValue?: string | null;
                    trackingValue?: string | null;
                    count?: {
                      __typename?: "google_protobuf_Int32Value";
                      value?: number | null;
                    } | null;
                  } | null> | null;
                } | null;
              } | null> | null;
            } | null;
          } | null> | null;
        } | null;
      } | null> | null;
    } | null> | null;
  } | null;
};

export type SimilarListingsFieldsFragment = {
  __typename?: "Listing";
  _id?: string | null;
  id?: string | null;
  sellerId?: string | null;
  listingType?: core_apimessages_Listing_ListingType | null;
  title?: string | null;
  slug?: string | null;
  make?: string | null;
  model?: string | null;
  upc?: string | null;
  state?: string | null;
  stateDescription?: string | null;
  bumped?: boolean | null;
  watching?: boolean | null;
  soldAsIs?: boolean | null;
  usOutlet?: boolean | null;
  offersEnabled?: boolean | null;
  isBuyerOfferEligible?: boolean | null;
  currency?: string | null;
  brandSlug?: string | null;
  categoryRootUuid?: string | null;
  categoryUuids?: Array<string | null> | null;
  bumpEligible?: boolean | null;
  shopId?: string | null;
  inventory?: number | null;
  acceptedPaymentMethods?: Array<string | null> | null;
  pricing?: {
    __typename?: "core_apimessages_ListingPricing";
    buyerPrice?: {
      __typename?: "core_apimessages_Money";
      amount?: string | null;
      amountCents?: number | null;
      display?: string | null;
      currency?: string | null;
    } | null;
    originalPrice?: {
      __typename?: "core_apimessages_Money";
      display?: string | null;
    } | null;
    ribbon?: {
      __typename?: "core_apimessages_Ribbon";
      display?: string | null;
      reason?: core_apimessages_Ribbon_Reason | null;
    } | null;
  } | null;
  preorderInfo?: {
    __typename?: "core_apimessages_PreorderInfo";
    onPreorder?: boolean | null;
    estimatedShipDate?: {
      __typename?: "google_protobuf_Timestamp";
      seconds?: number | null;
    } | null;
  } | null;
  publishedAt?: {
    __typename?: "google_protobuf_Timestamp";
    seconds?: number | null;
  } | null;
  condition?: {
    __typename?: "core_apimessages_Condition";
    displayName?: string | null;
    conditionSlug?: string | null;
    conditionUuid?: string | null;
  } | null;
  images?: Array<{
    __typename?: "core_apimessages_Image";
    source?: string | null;
  } | null> | null;
  shipping?: {
    __typename?: "core_apimessages_ShippingPrices";
    freeExpeditedShipping?: boolean | null;
    localPickupOnly?: boolean | null;
    localPickup?: boolean | null;
    shippingPrices?: Array<{
      __typename?: "core_apimessages_ShippingPrice";
      _id?: string | null;
      shippingMethod?: core_apimessages_ShippingMethod | null;
      carrierCalculated?: boolean | null;
      destinationPostalCodeNeeded?: boolean | null;
      regional?: boolean | null;
      postalCode?: string | null;
      rate?: {
        __typename?: "core_apimessages_Money";
        amount?: string | null;
        amountCents?: number | null;
        currency?: string | null;
        display?: string | null;
      } | null;
    } | null> | null;
  } | null;
  shop?: {
    __typename?: "Shop";
    _id?: string | null;
    name?: string | null;
    returnPolicy?: {
      __typename?: "core_apimessages_ReturnPolicy";
      usedReturnWindowDays?: number | null;
      newReturnWindowDays?: number | null;
      _id?: string | null;
    } | null;
    address?: {
      __typename?: "core_apimessages_Address";
      _id?: string | null;
      locality?: string | null;
      region?: string | null;
      displayLocation?: string | null;
      countryCode?: string | null;
      country?: {
        __typename?: "core_apimessages_Country";
        _id?: string | null;
        countryCode?: string | null;
        name?: string | null;
      } | null;
    } | null;
  } | null;
  watchThumbnails?: Array<{
    __typename?: "core_apimessages_Image";
    _id?: string | null;
    source?: string | null;
  } | null> | null;
  priceRecommendation?: {
    __typename?: "reverb_pricing_PriceRecommendation";
    priceMiddle?: {
      __typename?: "reverb_pricing_Money";
      amountCents?: number | null;
      currency?: string | null;
    } | null;
  } | null;
  certifiedPreOwned?: {
    __typename?: "core_apimessages_ListingCertifiedPreOwned";
    title?: string | null;
    badgeIconUrl?: string | null;
  } | null;
  categories?: Array<{
    __typename?: "Category";
    _id?: string | null;
    slug?: string | null;
    rootSlug?: string | null;
  } | null> | null;
  csp?: {
    __typename?: "CSP";
    _id?: string | null;
    id?: string | null;
    slug?: string | null;
    brand?: {
      __typename?: "Brand";
      _id?: string | null;
      slug?: string | null;
    } | null;
  } | null;
  sale?: {
    __typename?: "core_apimessages_Sale";
    _id?: string | null;
    id?: string | null;
    code?: string | null;
    buyerIneligibilityReason?: string | null;
  } | null;
};

export type Core_Listing_SimilarListingsQueryVariables = Exact<{
  listingId?: InputMaybe<Scalars["String"]["input"]>;
  itemRegion?: InputMaybe<Scalars["String"]["input"]>;
  itemRegionRelation?: InputMaybe<reverb_search_ListingItemRegionRelation>;
}>;

export type Core_Listing_SimilarListingsQuery = {
  __typename?: "Query";
  listing?: {
    __typename?: "Listing";
    _id?: string | null;
    similarListings?: Array<{
      __typename?: "Listing";
      _id?: string | null;
      id?: string | null;
      sellerId?: string | null;
      listingType?: core_apimessages_Listing_ListingType | null;
      title?: string | null;
      slug?: string | null;
      make?: string | null;
      model?: string | null;
      upc?: string | null;
      state?: string | null;
      stateDescription?: string | null;
      bumped?: boolean | null;
      watching?: boolean | null;
      soldAsIs?: boolean | null;
      usOutlet?: boolean | null;
      offersEnabled?: boolean | null;
      isBuyerOfferEligible?: boolean | null;
      currency?: string | null;
      brandSlug?: string | null;
      categoryRootUuid?: string | null;
      categoryUuids?: Array<string | null> | null;
      bumpEligible?: boolean | null;
      shopId?: string | null;
      inventory?: number | null;
      acceptedPaymentMethods?: Array<string | null> | null;
      pricing?: {
        __typename?: "core_apimessages_ListingPricing";
        buyerPrice?: {
          __typename?: "core_apimessages_Money";
          amount?: string | null;
          amountCents?: number | null;
          display?: string | null;
          currency?: string | null;
        } | null;
        originalPrice?: {
          __typename?: "core_apimessages_Money";
          display?: string | null;
        } | null;
        ribbon?: {
          __typename?: "core_apimessages_Ribbon";
          display?: string | null;
          reason?: core_apimessages_Ribbon_Reason | null;
        } | null;
      } | null;
      preorderInfo?: {
        __typename?: "core_apimessages_PreorderInfo";
        onPreorder?: boolean | null;
        estimatedShipDate?: {
          __typename?: "google_protobuf_Timestamp";
          seconds?: number | null;
        } | null;
      } | null;
      publishedAt?: {
        __typename?: "google_protobuf_Timestamp";
        seconds?: number | null;
      } | null;
      condition?: {
        __typename?: "core_apimessages_Condition";
        displayName?: string | null;
        conditionSlug?: string | null;
        conditionUuid?: string | null;
      } | null;
      images?: Array<{
        __typename?: "core_apimessages_Image";
        source?: string | null;
      } | null> | null;
      shipping?: {
        __typename?: "core_apimessages_ShippingPrices";
        freeExpeditedShipping?: boolean | null;
        localPickupOnly?: boolean | null;
        localPickup?: boolean | null;
        shippingPrices?: Array<{
          __typename?: "core_apimessages_ShippingPrice";
          _id?: string | null;
          shippingMethod?: core_apimessages_ShippingMethod | null;
          carrierCalculated?: boolean | null;
          destinationPostalCodeNeeded?: boolean | null;
          regional?: boolean | null;
          postalCode?: string | null;
          rate?: {
            __typename?: "core_apimessages_Money";
            amount?: string | null;
            amountCents?: number | null;
            currency?: string | null;
            display?: string | null;
          } | null;
        } | null> | null;
      } | null;
      shop?: {
        __typename?: "Shop";
        _id?: string | null;
        name?: string | null;
        returnPolicy?: {
          __typename?: "core_apimessages_ReturnPolicy";
          usedReturnWindowDays?: number | null;
          newReturnWindowDays?: number | null;
          _id?: string | null;
        } | null;
        address?: {
          __typename?: "core_apimessages_Address";
          _id?: string | null;
          locality?: string | null;
          region?: string | null;
          displayLocation?: string | null;
          countryCode?: string | null;
          country?: {
            __typename?: "core_apimessages_Country";
            _id?: string | null;
            countryCode?: string | null;
            name?: string | null;
          } | null;
        } | null;
      } | null;
      watchThumbnails?: Array<{
        __typename?: "core_apimessages_Image";
        _id?: string | null;
        source?: string | null;
      } | null> | null;
      priceRecommendation?: {
        __typename?: "reverb_pricing_PriceRecommendation";
        priceMiddle?: {
          __typename?: "reverb_pricing_Money";
          amountCents?: number | null;
          currency?: string | null;
        } | null;
      } | null;
      certifiedPreOwned?: {
        __typename?: "core_apimessages_ListingCertifiedPreOwned";
        title?: string | null;
        badgeIconUrl?: string | null;
      } | null;
      categories?: Array<{
        __typename?: "Category";
        _id?: string | null;
        slug?: string | null;
        rootSlug?: string | null;
      } | null> | null;
      csp?: {
        __typename?: "CSP";
        _id?: string | null;
        id?: string | null;
        slug?: string | null;
        brand?: {
          __typename?: "Brand";
          _id?: string | null;
          slug?: string | null;
        } | null;
      } | null;
      sale?: {
        __typename?: "core_apimessages_Sale";
        _id?: string | null;
        id?: string | null;
        code?: string | null;
        buyerIneligibilityReason?: string | null;
      } | null;
    } | null> | null;
  } | null;
};

export type Core_SiteBannerHeroQueryVariables = Exact<{ [key: string]: never }>;

export type Core_SiteBannerHeroQuery = {
  __typename?: "Query";
  siteBanner?: {
    __typename?: "core_apimessages_SiteBanner";
    id?: string | null;
    heading?: string | null;
    subheading?: string | null;
    callToAction?: string | null;
    hasPhotos?: boolean | null;
    imageUrl?: string | null;
    url?: { __typename?: "core_apimessages_Link"; href?: string | null } | null;
    contentSponsorship?: {
      __typename?: "core_apimessages_ContentSponsorship";
      sponsorshipType?: core_apimessages_ContentSponsorship_SponsorshipType | null;
      partnershipName?: string | null;
    } | null;
  } | null;
};

export type EUODRAddressFragment = {
  __typename?: "Shop";
  _id?: string | null;
  vatId?: string | null;
  euOdrAddress?: {
    __typename?: "core_apimessages_Address";
    _id?: string | null;
    name?: string | null;
    streetAddress?: string | null;
    displayLocation?: string | null;
    postalCode?: string | null;
    email?: string | null;
    phone?: string | null;
  } | null;
};

export type Core_Listing_EuOdrAddressQueryVariables = Exact<{
  slug?: InputMaybe<Scalars["String"]["input"]>;
}>;

export type Core_Listing_EuOdrAddressQuery = {
  __typename?: "Query";
  shop?: {
    __typename?: "Shop";
    _id?: string | null;
    vatId?: string | null;
    euOdrAddress?: {
      __typename?: "core_apimessages_Address";
      _id?: string | null;
      name?: string | null;
      streetAddress?: string | null;
      displayLocation?: string | null;
      postalCode?: string | null;
      email?: string | null;
      phone?: string | null;
    } | null;
  } | null;
};

export type Core_ExpressSaleCheckoutShowQueryVariables = Exact<{
  checkoutId?: InputMaybe<Scalars["String"]["input"]>;
}>;

export type Core_ExpressSaleCheckoutShowQuery = {
  __typename?: "Query";
  expresssalecheckout?: {
    __typename?: "ExpressSaleCheckout";
    _id?: string | null;
    status?: core_apimessages_ExpressSaleCheckout_Status | null;
    csp?: {
      __typename?: "CSP";
      _id?: string | null;
      id?: string | null;
      title?: string | null;
      finishes?: Array<string | null> | null;
      brand?: {
        __typename?: "Brand";
        _id?: string | null;
        name?: string | null;
      } | null;
      image?: {
        __typename?: "core_apimessages_Image";
        _id?: string | null;
        source?: string | null;
      } | null;
      specs?: {
        __typename?: "core_apimessages_CSPSpec";
        productSpecs?: Array<{
          __typename?: "core_apimessages_ProductSpec";
          _id?: string | null;
          type?: core_apimessages_ProductSpec_Type | null;
          name?: string | null;
          value?: string | null;
        } | null> | null;
      } | null;
    } | null;
    expressSaleItemBid?: {
      __typename?: "core_apimessages_CSPExpressSaleItemBid";
      bidId?: string | null;
      carrier?: string | null;
      shopName?: string | null;
      estimatedPayout?: {
        __typename?: "core_apimessages_Money";
        display?: string | null;
        amount?: string | null;
        amountCents?: number | null;
      } | null;
    } | null;
  } | null;
  me?: {
    __typename?: "rql_Me";
    _id?: string | null;
    uuid?: string | null;
    shippingAddresses?: Array<{
      __typename?: "core_apimessages_Address";
      _id?: string | null;
      uuid?: string | null;
      name?: string | null;
      streetAddress?: string | null;
      extendedAddress?: string | null;
      locality?: string | null;
      region?: string | null;
      postalCode?: string | null;
      countryCode?: string | null;
      phone?: string | null;
      primary?: boolean | null;
      legacyId?: string | null;
    } | null> | null;
  } | null;
};

export type Core_Create_ExpressSaleCheckoutMutationVariables = Exact<{
  input?: InputMaybe<Input_core_apimessages_CreateExpressSaleCheckoutRequest>;
}>;

export type Core_Create_ExpressSaleCheckoutMutation = {
  __typename?: "Mutation";
  createExpressSaleCheckout?: {
    __typename?: "core_apimessages_CreateExpressSaleCheckoutResponse";
    checkoutId?: string | null;
  } | null;
};

export type Core_Complete_ExpressSaleCheckoutMutationVariables = Exact<{
  input?: InputMaybe<Input_core_apimessages_CompleteExpressSaleCheckoutRequest>;
}>;

export type Core_Complete_ExpressSaleCheckoutMutation = {
  __typename?: "Mutation";
  completeExpressSaleCheckout?: {
    __typename?: "core_apimessages_CompleteExpressSaleCheckoutResponse";
    orderId?: string | null;
  } | null;
};

export type ExpressSaleItemBidDataFragment = {
  __typename?: "CSP";
  _id?: string | null;
  expressSalePriceEstimate?: {
    __typename?: "reverb_pricing_PriceRecommendation";
    priceLow?: {
      __typename?: "reverb_pricing_Money";
      amountCents?: number | null;
      currency?: string | null;
    } | null;
    priceHigh?: {
      __typename?: "reverb_pricing_Money";
      amountCents?: number | null;
      currency?: string | null;
    } | null;
  } | null;
  expressSaleItemBid?: {
    __typename?: "core_apimessages_CSPExpressSaleItemBid";
    cspUuid?: string | null;
    bidId?: string | null;
    carrier?: string | null;
    shopName?: string | null;
    estimatedPayout?: {
      __typename?: "core_apimessages_Money";
      display?: string | null;
      amount?: string | null;
    } | null;
  } | null;
};

export type DeleteMyFavoriteMutationVariables = Exact<{
  input?: InputMaybe<Input_core_apimessages_DeleteMyFavoriteRequest>;
}>;

export type DeleteMyFavoriteMutation = {
  __typename?: "Mutation";
  deleteMyFavorite?: {
    __typename?: "core_apimessages_DeleteMyFavoriteResponse";
    favorite?: {
      __typename?: "core_apimessages_Favorite";
      id?: string | null;
      favorited?: boolean | null;
    } | null;
  } | null;
};

export type Core_Listing_ShopsRecommendedContentQueryVariables = Exact<{
  [key: string]: never;
}>;

export type Core_Listing_ShopsRecommendedContentQuery = {
  __typename?: "Query";
  recommendedShops?: Array<{
    __typename?: "Shop";
    _id?: string | null;
    id?: string | null;
    name?: string | null;
    slug?: string | null;
    address?: {
      __typename?: "core_apimessages_Address";
      _id?: string | null;
      id?: string | null;
      displayLocation?: string | null;
    } | null;
    user?: {
      __typename?: "User";
      _id?: string | null;
      feedbackSummary?: {
        __typename?: "core_apimessages_FeedbackSummary";
        rollingRatingPercentage?: number | null;
        receivedCount?: number | null;
      } | null;
      avatar?: {
        __typename?: "core_apimessages_Image";
        _id?: string | null;
        id?: string | null;
        source?: string | null;
      } | null;
    } | null;
  } | null> | null;
};

export type FavoriteCTADataFragment = {
  __typename?: "rql_Favorite";
  favorite?: {
    __typename?: "core_apimessages_Favorite";
    id?: string | null;
    favorited?: boolean | null;
    title?: string | null;
    searchableId?: string | null;
    link?: {
      __typename?: "core_apimessages_Link";
      href?: string | null;
    } | null;
  } | null;
};

export type UpsertMyFavoriteMutationVariables = Exact<{
  input?: InputMaybe<Input_core_apimessages_UpsertMyFavoriteRequest>;
}>;

export type UpsertMyFavoriteMutation = {
  __typename?: "Mutation";
  upsertMyFavorite?: {
    __typename?: "core_apimessages_UpsertMyFavoriteResponse";
    favorite?: {
      __typename?: "core_apimessages_Favorite";
      id?: string | null;
      favorited?: boolean | null;
      emailEnabled?: boolean | null;
      feedEnabled?: boolean | null;
      searchableId?: string | null;
      searchableType?: reverb_feed_SearchableType | null;
      queryParams?: string | null;
    } | null;
  } | null;
};

export type Core_FindFavoriteProvider_UpsertMyFavoriteMutationVariables =
  Exact<{
    input?: InputMaybe<Input_core_apimessages_UpsertMyFavoriteRequest>;
  }>;

export type Core_FindFavoriteProvider_UpsertMyFavoriteMutation = {
  __typename?: "Mutation";
  upsertMyFavorite?: {
    __typename?: "core_apimessages_UpsertMyFavoriteResponse";
    favorite?: {
      __typename?: "core_apimessages_Favorite";
      id?: string | null;
      favorited?: boolean | null;
      emailEnabled?: boolean | null;
      feedEnabled?: boolean | null;
      searchableId?: string | null;
      searchableType?: reverb_feed_SearchableType | null;
      queryParams?: string | null;
    } | null;
  } | null;
};

export type FavoriteSearchDataFragment = {
  __typename?: "rql_Favorite";
  listingsSearchUrlParams?: string | null;
  favorite?: {
    __typename?: "core_apimessages_Favorite";
    id?: string | null;
    title?: string | null;
    subtitle?: string | null;
    favorited?: boolean | null;
    feedEnabled?: boolean | null;
    emailEnabled?: boolean | null;
    queryParams?: string | null;
  } | null;
  entity?:
    | { __typename?: "Listing" }
    | { __typename?: "Shop" }
    | {
        __typename?: "core_apimessages_Favorite";
        id?: string | null;
        link?: {
          __typename?: "core_apimessages_Link";
          href?: string | null;
        } | null;
        images?: Array<{
          __typename?: "core_apimessages_Image";
          source?: string | null;
        } | null> | null;
      }
    | null;
};

export type Core_Get_Favorite_SearchesQueryVariables = Exact<{
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
}>;

export type Core_Get_Favorite_SearchesQuery = {
  __typename?: "Query";
  me?: {
    __typename?: "rql_Me";
    _id?: string | null;
    uuid?: string | null;
    favorites?: {
      __typename?: "rql_FavoritesResponse";
      total?: number | null;
      favorites?: Array<{
        __typename?: "rql_Favorite";
        id?: string | null;
        title?: string | null;
        subtitle?: string | null;
        listingsSearchUrlParams?: string | null;
        favorite?: {
          __typename?: "core_apimessages_Favorite";
          id?: string | null;
          title?: string | null;
          subtitle?: string | null;
          favorited?: boolean | null;
          feedEnabled?: boolean | null;
          emailEnabled?: boolean | null;
          queryParams?: string | null;
          searchableId?: string | null;
          link?: {
            __typename?: "core_apimessages_Link";
            href?: string | null;
          } | null;
        } | null;
        entity?:
          | { __typename?: "Listing" }
          | { __typename?: "Shop" }
          | {
              __typename?: "core_apimessages_Favorite";
              id?: string | null;
              link?: {
                __typename?: "core_apimessages_Link";
                href?: string | null;
              } | null;
              images?: Array<{
                __typename?: "core_apimessages_Image";
                source?: string | null;
              } | null> | null;
            }
          | null;
      } | null> | null;
    } | null;
  } | null;
};

export type FavoriteShopCTADataFragment = {
  __typename?: "Shop";
  _id?: string | null;
  id?: string | null;
  name?: string | null;
  address?: {
    __typename?: "core_apimessages_Address";
    _id?: string | null;
    countryCode?: string | null;
  } | null;
  user?: {
    __typename?: "User";
    _id?: string | null;
    feedbackSummary?: {
      __typename?: "core_apimessages_FeedbackSummary";
      rollingRatingPercentage?: number | null;
      receivedCount?: number | null;
    } | null;
  } | null;
};

export type UpsertMyFavoriteShopMutationVariables = Exact<{
  input?: InputMaybe<Input_core_apimessages_UpsertMyFavoriteRequest>;
}>;

export type UpsertMyFavoriteShopMutation = {
  __typename?: "Mutation";
  upsertMyFavorite?: {
    __typename?: "core_apimessages_UpsertMyFavoriteResponse";
    favorite?: {
      __typename?: "core_apimessages_Favorite";
      id?: string | null;
      favorited?: boolean | null;
      searchableId?: string | null;
      searchableType?: reverb_feed_SearchableType | null;
      queryParams?: string | null;
    } | null;
  } | null;
};

export type DeleteMyFavoriteShopMutationVariables = Exact<{
  input?: InputMaybe<Input_core_apimessages_DeleteMyFavoriteRequest>;
}>;

export type DeleteMyFavoriteShopMutation = {
  __typename?: "Mutation";
  deleteMyFavorite?: {
    __typename?: "core_apimessages_DeleteMyFavoriteResponse";
    favorite?: {
      __typename?: "core_apimessages_Favorite";
      id?: string | null;
      favorited?: boolean | null;
    } | null;
  } | null;
};

export type FavoriteShopDataFragment = {
  __typename?: "rql_Favorite";
  favorite?: {
    __typename?: "core_apimessages_Favorite";
    id?: string | null;
    title?: string | null;
    subtitle?: string | null;
    favorited?: boolean | null;
  } | null;
  entity?:
    | { __typename?: "Listing" }
    | {
        __typename?: "Shop";
        _id?: string | null;
        id?: string | null;
        name?: string | null;
        slug?: string | null;
        address?: {
          __typename?: "core_apimessages_Address";
          _id?: string | null;
          id?: string | null;
          displayLocation?: string | null;
        } | null;
        user?: {
          __typename?: "User";
          _id?: string | null;
          feedbackSummary?: {
            __typename?: "core_apimessages_FeedbackSummary";
            rollingRatingPercentage?: number | null;
            receivedCount?: number | null;
          } | null;
          avatar?: {
            __typename?: "core_apimessages_Image";
            _id?: string | null;
            id?: string | null;
            source?: string | null;
          } | null;
        } | null;
      }
    | { __typename?: "core_apimessages_Favorite" }
    | null;
};

export type Core_FavoritesShops_LayoutQueryVariables = Exact<{
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
}>;

export type Core_FavoritesShops_LayoutQuery = {
  __typename?: "Query";
  me?: {
    __typename?: "rql_Me";
    _id?: string | null;
    id?: string | null;
    uuid?: string | null;
    favorites?: {
      __typename?: "rql_FavoritesResponse";
      total?: number | null;
      favorites?: Array<{
        __typename?: "rql_Favorite";
        favorite?: {
          __typename?: "core_apimessages_Favorite";
          id?: string | null;
          title?: string | null;
          subtitle?: string | null;
          favorited?: boolean | null;
          searchableId?: string | null;
          link?: {
            __typename?: "core_apimessages_Link";
            href?: string | null;
          } | null;
        } | null;
        entity?:
          | { __typename?: "Listing" }
          | {
              __typename?: "Shop";
              _id?: string | null;
              id?: string | null;
              name?: string | null;
              slug?: string | null;
              address?: {
                __typename?: "core_apimessages_Address";
                _id?: string | null;
                id?: string | null;
                displayLocation?: string | null;
              } | null;
              user?: {
                __typename?: "User";
                _id?: string | null;
                feedbackSummary?: {
                  __typename?: "core_apimessages_FeedbackSummary";
                  rollingRatingPercentage?: number | null;
                  receivedCount?: number | null;
                } | null;
                avatar?: {
                  __typename?: "core_apimessages_Image";
                  _id?: string | null;
                  id?: string | null;
                  source?: string | null;
                } | null;
              } | null;
            }
          | { __typename?: "core_apimessages_Favorite" }
          | null;
      } | null> | null;
    } | null;
  } | null;
};

export type FavoriteWatchListCardFragment = {
  __typename?: "Listing";
  id?: string | null;
  _id?: string | null;
  slug?: string | null;
  state?: string | null;
  title?: string | null;
  usOutlet?: boolean | null;
  watching?: boolean | null;
  sellerId?: string | null;
  listingType?: core_apimessages_Listing_ListingType | null;
  offersEnabled?: boolean | null;
  isBuyerOfferEligible?: boolean | null;
  otherBuyersWithListingInCartCounts?: number | null;
  currency?: string | null;
  brandSlug?: string | null;
  categoryRootUuid?: string | null;
  make?: string | null;
  categoryUuids?: Array<string | null> | null;
  bumpEligible?: boolean | null;
  shopId?: string | null;
  inventory?: number | null;
  soldAsIs?: boolean | null;
  acceptedPaymentMethods?: Array<string | null> | null;
  condition?: {
    __typename?: "core_apimessages_Condition";
    displayName?: string | null;
    conditionSlug?: string | null;
    conditionUuid?: string | null;
  } | null;
  pricing?: {
    __typename?: "core_apimessages_ListingPricing";
    ribbon?: {
      __typename?: "core_apimessages_Ribbon";
      display?: string | null;
      reason?: core_apimessages_Ribbon_Reason | null;
    } | null;
    buyerPrice?: {
      __typename?: "core_apimessages_Money";
      display?: string | null;
      amount?: string | null;
      amountCents?: number | null;
      currency?: string | null;
    } | null;
    originalPrice?: {
      __typename?: "core_apimessages_Money";
      display?: string | null;
    } | null;
  } | null;
  shop?: {
    __typename?: "Shop";
    _id?: string | null;
    name?: string | null;
    address?: {
      __typename?: "core_apimessages_Address";
      _id?: string | null;
      displayLocation?: string | null;
      countryCode?: string | null;
      country?: {
        __typename?: "core_apimessages_Country";
        _id?: string | null;
        countryCode?: string | null;
      } | null;
    } | null;
    returnPolicy?: {
      __typename?: "core_apimessages_ReturnPolicy";
      _id?: string | null;
      newReturnWindowDays?: number | null;
      usedReturnWindowDays?: number | null;
    } | null;
  } | null;
  signals?: Array<{
    __typename?: "reverb_signals_Signal";
    icon?: string | null;
    title?: string | null;
    name?: reverb_signals_Signal_Name | null;
  } | null> | null;
  seller?: {
    __typename?: "User";
    _id?: string | null;
    feedbackSummary?: {
      __typename?: "core_apimessages_FeedbackSummary";
      rollingRatingPercentage?: number | null;
    } | null;
  } | null;
  shippingPrice?: {
    __typename?: "core_apimessages_Money";
    amountCents?: number | null;
    display?: string | null;
  } | null;
  shipping?: {
    __typename?: "core_apimessages_ShippingPrices";
    localPickupOnly?: boolean | null;
    freeExpeditedShipping?: boolean | null;
    localPickup?: boolean | null;
    shippingPrices?: Array<{
      __typename?: "core_apimessages_ShippingPrice";
      _id?: string | null;
      shippingMethod?: core_apimessages_ShippingMethod | null;
      carrierCalculated?: boolean | null;
      destinationPostalCodeNeeded?: boolean | null;
      rate?: {
        __typename?: "core_apimessages_Money";
        amount?: string | null;
        amountCents?: number | null;
        currency?: string | null;
        display?: string | null;
      } | null;
    } | null> | null;
  } | null;
  images?: Array<{
    __typename?: "core_apimessages_Image";
    source?: string | null;
  } | null> | null;
  watchThumbnails?: Array<{
    __typename?: "core_apimessages_Image";
    _id?: string | null;
    source?: string | null;
  } | null> | null;
  preorderInfo?: {
    __typename?: "core_apimessages_PreorderInfo";
    onPreorder?: boolean | null;
    estimatedShipDate?: {
      __typename?: "google_protobuf_Timestamp";
      seconds?: number | null;
    } | null;
  } | null;
  priceRecommendation?: {
    __typename?: "reverb_pricing_PriceRecommendation";
    priceMiddle?: {
      __typename?: "reverb_pricing_Money";
      amountCents?: number | null;
      currency?: string | null;
    } | null;
  } | null;
  certifiedPreOwned?: {
    __typename?: "core_apimessages_ListingCertifiedPreOwned";
    title?: string | null;
    badgeIconUrl?: string | null;
  } | null;
  categories?: Array<{
    __typename?: "Category";
    _id?: string | null;
    slug?: string | null;
    rootSlug?: string | null;
  } | null> | null;
  csp?: {
    __typename?: "CSP";
    _id?: string | null;
    id?: string | null;
    slug?: string | null;
    brand?: {
      __typename?: "Brand";
      _id?: string | null;
      slug?: string | null;
    } | null;
  } | null;
  publishedAt?: {
    __typename?: "google_protobuf_Timestamp";
    seconds?: number | null;
  } | null;
  sale?: {
    __typename?: "core_apimessages_Sale";
    _id?: string | null;
    id?: string | null;
    code?: string | null;
    buyerIneligibilityReason?: string | null;
  } | null;
};

export type Core_Favorites_Watch_List_LayoutQueryVariables = Exact<{
  liveInput?: InputMaybe<Input_rql_FavoritesRequest>;
  endedInput?: InputMaybe<Input_rql_FavoritesRequest>;
  shouldSkipTracking: Scalars["Boolean"]["input"];
  onEndedTab: Scalars["Boolean"]["input"];
  favoriteImagesTransform: Scalars["String"]["input"];
}>;

export type Core_Favorites_Watch_List_LayoutQuery = {
  __typename?: "Query";
  me?: {
    __typename?: "rql_Me";
    _id?: string | null;
    id?: string | null;
    uuid?: string | null;
    profileSlug?: string | null;
    liveWatches?: {
      __typename?: "rql_FavoritesResponse";
      total?: number | null;
      filters?: Array<{
        __typename?: "reverb_search_Filter";
        name?: string | null;
        key?: string | null;
        aggregationName?: string | null;
        widgetType?: reverb_search_Filter_WidgetType | null;
        options?: Array<{
          __typename?: "reverb_search_FilterOption";
          name?: string | null;
          selected?: boolean | null;
          autoselected?: boolean | null;
          paramName?: string | null;
          setValues?: Array<string | null> | null;
          unsetValues?: Array<string | null> | null;
          all?: boolean | null;
          optionValue?: string | null;
          trackingValue?: string | null;
          count?: {
            __typename?: "google_protobuf_Int32Value";
            value?: number | null;
          } | null;
          subFilter?: {
            __typename?: "reverb_search_Filter";
            widgetType?: reverb_search_Filter_WidgetType | null;
            options?: Array<{
              __typename?: "reverb_search_FilterOption";
              name?: string | null;
              selected?: boolean | null;
              autoselected?: boolean | null;
              paramName?: string | null;
              setValues?: Array<string | null> | null;
              unsetValues?: Array<string | null> | null;
              all?: boolean | null;
              optionValue?: string | null;
              trackingValue?: string | null;
              count?: {
                __typename?: "google_protobuf_Int32Value";
                value?: number | null;
              } | null;
              subFilter?: {
                __typename?: "reverb_search_Filter";
                widgetType?: reverb_search_Filter_WidgetType | null;
                options?: Array<{
                  __typename?: "reverb_search_FilterOption";
                  name?: string | null;
                  selected?: boolean | null;
                  autoselected?: boolean | null;
                  paramName?: string | null;
                  setValues?: Array<string | null> | null;
                  unsetValues?: Array<string | null> | null;
                  all?: boolean | null;
                  optionValue?: string | null;
                  trackingValue?: string | null;
                  count?: {
                    __typename?: "google_protobuf_Int32Value";
                    value?: number | null;
                  } | null;
                  subFilter?: {
                    __typename?: "reverb_search_Filter";
                    widgetType?: reverb_search_Filter_WidgetType | null;
                    options?: Array<{
                      __typename?: "reverb_search_FilterOption";
                      name?: string | null;
                      selected?: boolean | null;
                      autoselected?: boolean | null;
                      paramName?: string | null;
                      setValues?: Array<string | null> | null;
                      unsetValues?: Array<string | null> | null;
                      all?: boolean | null;
                      optionValue?: string | null;
                      trackingValue?: string | null;
                      count?: {
                        __typename?: "google_protobuf_Int32Value";
                        value?: number | null;
                      } | null;
                    } | null> | null;
                  } | null;
                } | null> | null;
              } | null;
            } | null> | null;
          } | null;
        } | null> | null;
      } | null> | null;
      favorites?: Array<{
        __typename?: "rql_Favorite";
        entity?:
          | {
              __typename?: "Listing";
              id?: string | null;
              _id?: string | null;
              slug?: string | null;
              state?: string | null;
              title?: string | null;
              usOutlet?: boolean | null;
              watching?: boolean | null;
              brandSlug?: string | null;
              categoryRootUuid?: string | null;
              make?: string | null;
              categoryUuids?: Array<string | null> | null;
              listingType?: core_apimessages_Listing_ListingType | null;
              bumpEligible?: boolean | null;
              shopId?: string | null;
              inventory?: number | null;
              soldAsIs?: boolean | null;
              acceptedPaymentMethods?: Array<string | null> | null;
              currency?: string | null;
              sellerId?: string | null;
              offersEnabled?: boolean | null;
              isBuyerOfferEligible?: boolean | null;
              otherBuyersWithListingInCartCounts?: number | null;
              condition?: {
                __typename?: "core_apimessages_Condition";
                displayName?: string | null;
                conditionSlug?: string | null;
                conditionUuid?: string | null;
              } | null;
              pricing?: {
                __typename?: "core_apimessages_ListingPricing";
                ribbon?: {
                  __typename?: "core_apimessages_Ribbon";
                  display?: string | null;
                  reason?: core_apimessages_Ribbon_Reason | null;
                } | null;
                buyerPrice?: {
                  __typename?: "core_apimessages_Money";
                  display?: string | null;
                  amount?: string | null;
                  currency?: string | null;
                  amountCents?: number | null;
                } | null;
                originalPrice?: {
                  __typename?: "core_apimessages_Money";
                  display?: string | null;
                } | null;
              } | null;
              shop?: {
                __typename?: "Shop";
                _id?: string | null;
                name?: string | null;
                address?: {
                  __typename?: "core_apimessages_Address";
                  _id?: string | null;
                  displayLocation?: string | null;
                  countryCode?: string | null;
                  country?: {
                    __typename?: "core_apimessages_Country";
                    _id?: string | null;
                    countryCode?: string | null;
                  } | null;
                } | null;
                returnPolicy?: {
                  __typename?: "core_apimessages_ReturnPolicy";
                  _id?: string | null;
                  newReturnWindowDays?: number | null;
                  usedReturnWindowDays?: number | null;
                } | null;
              } | null;
              signals?: Array<{
                __typename?: "reverb_signals_Signal";
                icon?: string | null;
                title?: string | null;
                name?: reverb_signals_Signal_Name | null;
              } | null> | null;
              seller?: {
                __typename?: "User";
                _id?: string | null;
                feedbackSummary?: {
                  __typename?: "core_apimessages_FeedbackSummary";
                  rollingRatingPercentage?: number | null;
                } | null;
              } | null;
              shippingPrice?: {
                __typename?: "core_apimessages_Money";
                amountCents?: number | null;
                display?: string | null;
              } | null;
              shipping?: {
                __typename?: "core_apimessages_ShippingPrices";
                localPickupOnly?: boolean | null;
                freeExpeditedShipping?: boolean | null;
                localPickup?: boolean | null;
                shippingPrices?: Array<{
                  __typename?: "core_apimessages_ShippingPrice";
                  _id?: string | null;
                  shippingMethod?: core_apimessages_ShippingMethod | null;
                  carrierCalculated?: boolean | null;
                  destinationPostalCodeNeeded?: boolean | null;
                  rate?: {
                    __typename?: "core_apimessages_Money";
                    amount?: string | null;
                    amountCents?: number | null;
                    currency?: string | null;
                    display?: string | null;
                  } | null;
                } | null> | null;
              } | null;
              images?: Array<{
                __typename?: "core_apimessages_Image";
                source?: string | null;
              } | null> | null;
              categories?: Array<{
                __typename?: "Category";
                _id?: string | null;
                slug?: string | null;
                rootSlug?: string | null;
              } | null> | null;
              csp?: {
                __typename?: "CSP";
                _id?: string | null;
                id?: string | null;
                slug?: string | null;
                brand?: {
                  __typename?: "Brand";
                  _id?: string | null;
                  slug?: string | null;
                } | null;
              } | null;
              publishedAt?: {
                __typename?: "google_protobuf_Timestamp";
                seconds?: number | null;
              } | null;
              sale?: {
                __typename?: "core_apimessages_Sale";
                _id?: string | null;
                id?: string | null;
                code?: string | null;
                buyerIneligibilityReason?: string | null;
              } | null;
              certifiedPreOwned?: {
                __typename?: "core_apimessages_ListingCertifiedPreOwned";
                title?: string | null;
                badgeIconUrl?: string | null;
              } | null;
              watchThumbnails?: Array<{
                __typename?: "core_apimessages_Image";
                _id?: string | null;
                source?: string | null;
              } | null> | null;
              preorderInfo?: {
                __typename?: "core_apimessages_PreorderInfo";
                onPreorder?: boolean | null;
                estimatedShipDate?: {
                  __typename?: "google_protobuf_Timestamp";
                  seconds?: number | null;
                } | null;
              } | null;
              priceRecommendation?: {
                __typename?: "reverb_pricing_PriceRecommendation";
                priceMiddle?: {
                  __typename?: "reverb_pricing_Money";
                  amountCents?: number | null;
                  currency?: string | null;
                } | null;
              } | null;
            }
          | { __typename?: "Shop" }
          | { __typename?: "core_apimessages_Favorite" }
          | null;
      } | null> | null;
    } | null;
    endedWatches?: {
      __typename?: "rql_FavoritesResponse";
      total?: number | null;
      filters?: Array<{
        __typename?: "reverb_search_Filter";
        name?: string | null;
        key?: string | null;
        aggregationName?: string | null;
        widgetType?: reverb_search_Filter_WidgetType | null;
        options?: Array<{
          __typename?: "reverb_search_FilterOption";
          name?: string | null;
          selected?: boolean | null;
          autoselected?: boolean | null;
          paramName?: string | null;
          setValues?: Array<string | null> | null;
          unsetValues?: Array<string | null> | null;
          all?: boolean | null;
          optionValue?: string | null;
          trackingValue?: string | null;
          count?: {
            __typename?: "google_protobuf_Int32Value";
            value?: number | null;
          } | null;
          subFilter?: {
            __typename?: "reverb_search_Filter";
            widgetType?: reverb_search_Filter_WidgetType | null;
            options?: Array<{
              __typename?: "reverb_search_FilterOption";
              name?: string | null;
              selected?: boolean | null;
              autoselected?: boolean | null;
              paramName?: string | null;
              setValues?: Array<string | null> | null;
              unsetValues?: Array<string | null> | null;
              all?: boolean | null;
              optionValue?: string | null;
              trackingValue?: string | null;
              count?: {
                __typename?: "google_protobuf_Int32Value";
                value?: number | null;
              } | null;
              subFilter?: {
                __typename?: "reverb_search_Filter";
                widgetType?: reverb_search_Filter_WidgetType | null;
                options?: Array<{
                  __typename?: "reverb_search_FilterOption";
                  name?: string | null;
                  selected?: boolean | null;
                  autoselected?: boolean | null;
                  paramName?: string | null;
                  setValues?: Array<string | null> | null;
                  unsetValues?: Array<string | null> | null;
                  all?: boolean | null;
                  optionValue?: string | null;
                  trackingValue?: string | null;
                  count?: {
                    __typename?: "google_protobuf_Int32Value";
                    value?: number | null;
                  } | null;
                  subFilter?: {
                    __typename?: "reverb_search_Filter";
                    widgetType?: reverb_search_Filter_WidgetType | null;
                    options?: Array<{
                      __typename?: "reverb_search_FilterOption";
                      name?: string | null;
                      selected?: boolean | null;
                      autoselected?: boolean | null;
                      paramName?: string | null;
                      setValues?: Array<string | null> | null;
                      unsetValues?: Array<string | null> | null;
                      all?: boolean | null;
                      optionValue?: string | null;
                      trackingValue?: string | null;
                      count?: {
                        __typename?: "google_protobuf_Int32Value";
                        value?: number | null;
                      } | null;
                    } | null> | null;
                  } | null;
                } | null> | null;
              } | null;
            } | null> | null;
          } | null;
        } | null> | null;
      } | null> | null;
      favorites?: Array<{
        __typename?: "rql_Favorite";
        entity?:
          | {
              __typename?: "Listing";
              id?: string | null;
              _id?: string | null;
              slug?: string | null;
              state?: string | null;
              title?: string | null;
              usOutlet?: boolean | null;
              watching?: boolean | null;
              brandSlug?: string | null;
              categoryRootUuid?: string | null;
              make?: string | null;
              categoryUuids?: Array<string | null> | null;
              listingType?: core_apimessages_Listing_ListingType | null;
              bumpEligible?: boolean | null;
              shopId?: string | null;
              inventory?: number | null;
              soldAsIs?: boolean | null;
              acceptedPaymentMethods?: Array<string | null> | null;
              currency?: string | null;
              sellerId?: string | null;
              offersEnabled?: boolean | null;
              isBuyerOfferEligible?: boolean | null;
              otherBuyersWithListingInCartCounts?: number | null;
              condition?: {
                __typename?: "core_apimessages_Condition";
                displayName?: string | null;
                conditionSlug?: string | null;
                conditionUuid?: string | null;
              } | null;
              pricing?: {
                __typename?: "core_apimessages_ListingPricing";
                ribbon?: {
                  __typename?: "core_apimessages_Ribbon";
                  display?: string | null;
                  reason?: core_apimessages_Ribbon_Reason | null;
                } | null;
                buyerPrice?: {
                  __typename?: "core_apimessages_Money";
                  display?: string | null;
                  amount?: string | null;
                  currency?: string | null;
                  amountCents?: number | null;
                } | null;
                originalPrice?: {
                  __typename?: "core_apimessages_Money";
                  display?: string | null;
                } | null;
              } | null;
              shop?: {
                __typename?: "Shop";
                _id?: string | null;
                name?: string | null;
                address?: {
                  __typename?: "core_apimessages_Address";
                  _id?: string | null;
                  displayLocation?: string | null;
                  countryCode?: string | null;
                  country?: {
                    __typename?: "core_apimessages_Country";
                    _id?: string | null;
                    countryCode?: string | null;
                  } | null;
                } | null;
                returnPolicy?: {
                  __typename?: "core_apimessages_ReturnPolicy";
                  _id?: string | null;
                  newReturnWindowDays?: number | null;
                  usedReturnWindowDays?: number | null;
                } | null;
              } | null;
              signals?: Array<{
                __typename?: "reverb_signals_Signal";
                icon?: string | null;
                title?: string | null;
                name?: reverb_signals_Signal_Name | null;
              } | null> | null;
              seller?: {
                __typename?: "User";
                _id?: string | null;
                feedbackSummary?: {
                  __typename?: "core_apimessages_FeedbackSummary";
                  rollingRatingPercentage?: number | null;
                } | null;
              } | null;
              shippingPrice?: {
                __typename?: "core_apimessages_Money";
                amountCents?: number | null;
                display?: string | null;
              } | null;
              shipping?: {
                __typename?: "core_apimessages_ShippingPrices";
                localPickupOnly?: boolean | null;
                freeExpeditedShipping?: boolean | null;
                localPickup?: boolean | null;
                shippingPrices?: Array<{
                  __typename?: "core_apimessages_ShippingPrice";
                  _id?: string | null;
                  shippingMethod?: core_apimessages_ShippingMethod | null;
                  carrierCalculated?: boolean | null;
                  destinationPostalCodeNeeded?: boolean | null;
                  rate?: {
                    __typename?: "core_apimessages_Money";
                    amount?: string | null;
                    amountCents?: number | null;
                    currency?: string | null;
                    display?: string | null;
                  } | null;
                } | null> | null;
              } | null;
              images?: Array<{
                __typename?: "core_apimessages_Image";
                source?: string | null;
              } | null> | null;
              categories?: Array<{
                __typename?: "Category";
                _id?: string | null;
                slug?: string | null;
                rootSlug?: string | null;
              } | null> | null;
              csp?: {
                __typename?: "CSP";
                _id?: string | null;
                id?: string | null;
                slug?: string | null;
                brand?: {
                  __typename?: "Brand";
                  _id?: string | null;
                  slug?: string | null;
                } | null;
              } | null;
              publishedAt?: {
                __typename?: "google_protobuf_Timestamp";
                seconds?: number | null;
              } | null;
              sale?: {
                __typename?: "core_apimessages_Sale";
                _id?: string | null;
                id?: string | null;
                code?: string | null;
                buyerIneligibilityReason?: string | null;
              } | null;
              certifiedPreOwned?: {
                __typename?: "core_apimessages_ListingCertifiedPreOwned";
                title?: string | null;
                badgeIconUrl?: string | null;
              } | null;
              watchThumbnails?: Array<{
                __typename?: "core_apimessages_Image";
                _id?: string | null;
                source?: string | null;
              } | null> | null;
              preorderInfo?: {
                __typename?: "core_apimessages_PreorderInfo";
                onPreorder?: boolean | null;
                estimatedShipDate?: {
                  __typename?: "google_protobuf_Timestamp";
                  seconds?: number | null;
                } | null;
              } | null;
              priceRecommendation?: {
                __typename?: "reverb_pricing_PriceRecommendation";
                priceMiddle?: {
                  __typename?: "reverb_pricing_Money";
                  amountCents?: number | null;
                  currency?: string | null;
                } | null;
              } | null;
            }
          | { __typename?: "Shop" }
          | { __typename?: "core_apimessages_Favorite" }
          | null;
      } | null> | null;
    } | null;
  } | null;
};

export type Core_FindFavoriteQueryVariables = Exact<{
  listingsSearchRequest?: InputMaybe<Input_reverb_search_ListingsSearchRequest>;
  shopSlug?: InputMaybe<Scalars["String"]["input"]>;
}>;

export type Core_FindFavoriteQuery = {
  __typename?: "Query";
  findFavorite?: {
    __typename?: "core_apimessages_FindFavoriteResponse";
    isFavorite?: boolean | null;
    canFavorite?: boolean | null;
    favorite?: {
      __typename?: "core_apimessages_Favorite";
      id?: string | null;
      favorited?: boolean | null;
      searchableId?: string | null;
      searchableType?: reverb_feed_SearchableType | null;
      title?: string | null;
      emailEnabled?: boolean | null;
      feedEnabled?: boolean | null;
      queryParams?: string | null;
      subtitle?: string | null;
      link?: {
        __typename?: "core_apimessages_Link";
        href?: string | null;
      } | null;
    } | null;
  } | null;
};

export type Core_Post_Purchase_Shop_NudgeQueryVariables = Exact<{
  shop?: InputMaybe<Scalars["String"]["input"]>;
}>;

export type Core_Post_Purchase_Shop_NudgeQuery = {
  __typename?: "Query";
  shop?: {
    __typename?: "Shop";
    _id?: string | null;
    id?: string | null;
    slug?: string | null;
    name?: string | null;
    preferredSeller?: boolean | null;
    quickShipper?: boolean | null;
    quickResponder?: boolean | null;
    description?: string | null;
    userUuid?: string | null;
    userId?: string | null;
    address?: {
      __typename?: "core_apimessages_Address";
      _id?: string | null;
      displayLocation?: string | null;
      countryCode?: string | null;
    } | null;
    banner?: {
      __typename?: "core_apimessages_Image";
      _id?: string | null;
      source?: string | null;
    } | null;
    user?: {
      __typename?: "User";
      _id?: string | null;
      id?: string | null;
      avatar?: {
        __typename?: "core_apimessages_Image";
        _id?: string | null;
        source?: string | null;
      } | null;
      feedbackSummary?: {
        __typename?: "core_apimessages_FeedbackSummary";
        rollingRatingPercentage?: number | null;
        receivedCount?: number | null;
      } | null;
    } | null;
  } | null;
  findFavorite?: {
    __typename?: "core_apimessages_FindFavoriteResponse";
    isFavorite?: boolean | null;
    canFavorite?: boolean | null;
  } | null;
};

export type FeaturedListingModuleDataFragment = {
  __typename?: "Listing";
  _id?: string | null;
  title?: string | null;
  slug?: string | null;
  description?: string | null;
  state?: string | null;
  id?: string | null;
  offersEnabled?: boolean | null;
  inventory?: number | null;
  hasInventory?: boolean | null;
  maxBuyerQuantity?: number | null;
  make?: string | null;
  model?: string | null;
  usOutlet?: boolean | null;
  currency?: string | null;
  soldAsIs?: boolean | null;
  sellerId?: string | null;
  listingType?: core_apimessages_Listing_ListingType | null;
  stateDescription?: string | null;
  taxIncluded?: boolean | null;
  taxIncludedHint?: string | null;
  acceptedPaymentMethods?: Array<string | null> | null;
  isBuyerOfferEligible?: boolean | null;
  brandSlug?: string | null;
  categoryRootUuid?: string | null;
  categoryUuids?: Array<string | null> | null;
  bumpEligible?: boolean | null;
  shopId?: string | null;
  adyenCheckoutPaymentMethodsConfig?: string | null;
  condition?: {
    __typename?: "core_apimessages_Condition";
    displayName?: string | null;
    conditionSlug?: string | null;
    conditionUuid?: string | null;
  } | null;
  largeImages?: Array<{
    __typename?: "core_apimessages_Image";
    _id?: string | null;
    source?: string | null;
  } | null> | null;
  supersizeImages?: Array<{
    __typename?: "core_apimessages_Image";
    _id?: string | null;
    source?: string | null;
  } | null> | null;
  cardSquareImages?: Array<{
    __typename?: "core_apimessages_Image";
    _id?: string | null;
    source?: string | null;
  } | null> | null;
  video?: {
    __typename?: "core_apimessages_Video";
    youtubeVideoId?: string | null;
    empty?: boolean | null;
  } | null;
  csp?: {
    __typename?: "CSP";
    _id?: string | null;
    id?: string | null;
    slug?: string | null;
    videos?: {
      __typename?: "core_apimessages_CSPVideo";
      videoLinks?: Array<{
        __typename?: "core_apimessages_VideoLink";
        youtubeVideoId?: string | null;
      } | null> | null;
    } | null;
    webLink?: {
      __typename?: "core_apimessages_Link";
      href?: string | null;
    } | null;
    brand?: {
      __typename?: "Brand";
      _id?: string | null;
      slug?: string | null;
    } | null;
  } | null;
  seller?: {
    __typename?: "User";
    _id?: string | null;
    id?: string | null;
    uuid?: string | null;
    feedbackSummary?: {
      __typename?: "core_apimessages_FeedbackSummary";
      rollingRatingPercentage?: number | null;
      receivedCount?: number | null;
    } | null;
  } | null;
  shop?: {
    __typename?: "Shop";
    _id?: string | null;
    name?: string | null;
    slug?: string | null;
    preferredSeller?: boolean | null;
    quickShipper?: boolean | null;
    quickResponder?: boolean | null;
    paymentPolicy?: string | null;
    createdAt?: any | null;
    id?: string | null;
    address?: {
      __typename?: "core_apimessages_Address";
      _id?: string | null;
      displayLocation?: string | null;
      countryCode?: string | null;
      country?: {
        __typename?: "core_apimessages_Country";
        _id?: string | null;
        name?: string | null;
      } | null;
    } | null;
    orders?: {
      __typename?: "reverb_search_SearchResponse";
      total?: number | null;
    } | null;
    returnPolicy?: {
      __typename?: "core_apimessages_ReturnPolicy";
      newReturnWindowDays?: number | null;
      usedReturnWindowDays?: number | null;
      _id?: string | null;
    } | null;
    freeDomesticShippingRate?: {
      __typename?: "core_apimessages_ShippingRates";
      regionCode?: string | null;
      freeShippingThreshold?: {
        __typename?: "core_apimessages_Money";
        amountCents?: number | null;
        currency?: string | null;
      } | null;
    } | null;
  } | null;
  shipping?: {
    __typename?: "core_apimessages_ShippingPrices";
    localPickupOnly?: boolean | null;
    freeExpeditedShipping?: boolean | null;
    localPickup?: boolean | null;
    shippingPrices?: Array<{
      __typename?: "core_apimessages_ShippingPrice";
      _id?: string | null;
      shippingMethod?: core_apimessages_ShippingMethod | null;
      carrierCalculated?: boolean | null;
      destinationPostalCodeNeeded?: boolean | null;
      regional?: boolean | null;
      postalCode?: string | null;
      rate?: {
        __typename?: "core_apimessages_Money";
        amountCents?: number | null;
        amount?: string | null;
        currency?: string | null;
        display?: string | null;
      } | null;
    } | null> | null;
  } | null;
  signals?: Array<{
    __typename?: "reverb_signals_Signal";
    name?: reverb_signals_Signal_Name | null;
    group?: reverb_signals_Signal_Group | null;
    title?: string | null;
    subtitle?: string | null;
    icon?: string | null;
    iconDark?: string | null;
    tooltipText?: string | null;
    link?: {
      __typename?: "reverb_signals_SignalLink";
      text?: string | null;
      url?: string | null;
    } | null;
  } | null> | null;
  pricing?: {
    __typename?: "core_apimessages_ListingPricing";
    originalPriceDescription?: string | null;
    buyerPrice?: {
      __typename?: "core_apimessages_Money";
      amount?: string | null;
      amountCents?: number | null;
      currency?: string | null;
      display?: string | null;
    } | null;
    originalPrice?: {
      __typename?: "core_apimessages_Money";
      display?: string | null;
    } | null;
    ribbon?: {
      __typename?: "core_apimessages_Ribbon";
      display?: string | null;
      reason?: core_apimessages_Ribbon_Reason | null;
    } | null;
    typicalNewPriceDisplay?: {
      __typename?: "core_apimessages_TypicalNewPriceDisplay";
      amountDisplay?: string | null;
    } | null;
  } | null;
  preorderInfo?: {
    __typename?: "core_apimessages_PreorderInfo";
    onPreorder?: boolean | null;
    estimatedShipDate?: {
      __typename?: "google_protobuf_Timestamp";
      seconds?: number | null;
    } | null;
  } | null;
  publishedAt?: {
    __typename?: "google_protobuf_Timestamp";
    seconds?: number | null;
  } | null;
  counts?: {
    __typename?: "core_apimessages_ListingCounts";
    id?: string | null;
    viewsDisplay?: string | null;
    offersDisplay?: string | null;
    watchersDisplay?: string | null;
    downloadCountDisplay?: string | null;
  } | null;
  digitalDetails?: {
    __typename?: "core_apimessages_ListingDigitalDetails";
    variants?: Array<{
      __typename?: "core_apimessages_ListingDigitalVariant";
      id?: string | null;
      downloadText?: string | null;
      fileUrl?: string | null;
      fileSize?: string | null;
      operatingSystems?: Array<string | null> | null;
      fileTypes?: Array<string | null> | null;
    } | null> | null;
    supportedFormats?: Array<{
      __typename?: "core_apimessages_ListingSupportedFormat";
      operatingSystem?: string | null;
      fileTypes?: Array<string | null> | null;
    } | null> | null;
  } | null;
  estimatedMonthlyPaymentPlan?: {
    __typename?: "core_apimessages_EstimatedMonthlyPayment";
    financingProgramSlug?: string | null;
  } | null;
  certifiedPreOwned?: {
    __typename?: "core_apimessages_ListingCertifiedPreOwned";
    title?: string | null;
    description?: string | null;
    tooltipDescription?: string | null;
    badgeIconUrl?: string | null;
    brandName?: string | null;
    pageUrl?: string | null;
    lightModeBrandIconUrl?: string | null;
    darkModeBrandIconUrl?: string | null;
  } | null;
  categories?: Array<{
    __typename?: "Category";
    _id?: string | null;
    slug?: string | null;
    rootSlug?: string | null;
  } | null> | null;
  sale?: {
    __typename?: "core_apimessages_Sale";
    _id?: string | null;
    id?: string | null;
    code?: string | null;
    buyerIneligibilityReason?: string | null;
  } | null;
  expressPay?: {
    __typename?: "core_apimessages_ListingExpressPay";
    _id?: string | null;
    checkoutBundlingId?: string | null;
    paypal?: {
      __typename?: "core_apimessages_CheckoutPaypalDetails";
      currency?: string | null;
    } | null;
    estimatedTotal?: {
      __typename?: "core_apimessages_Money";
      amount?: string | null;
      currency?: string | null;
    } | null;
    googlePay?: {
      __typename?: "core_apimessages_CheckoutGooglePayDetails";
      merchantId?: string | null;
      merchantName?: string | null;
      gatewayMerchantId?: string | null;
      cardNetworks?: Array<core_apimessages_CheckoutGooglePayDetails_CardNetwork | null> | null;
    } | null;
  } | null;
};

export type FeaturedListingShopInfoDataFragment = {
  __typename?: "Listing";
  _id?: string | null;
  id?: string | null;
  state?: string | null;
  seller?: {
    __typename?: "User";
    _id?: string | null;
    id?: string | null;
    uuid?: string | null;
    feedbackSummary?: {
      __typename?: "core_apimessages_FeedbackSummary";
      rollingRatingPercentage?: number | null;
      receivedCount?: number | null;
    } | null;
  } | null;
  shop?: {
    __typename?: "Shop";
    _id?: string | null;
    name?: string | null;
    slug?: string | null;
    preferredSeller?: boolean | null;
    quickShipper?: boolean | null;
    quickResponder?: boolean | null;
    paymentPolicy?: string | null;
    createdAt?: any | null;
    address?: {
      __typename?: "core_apimessages_Address";
      _id?: string | null;
      displayLocation?: string | null;
      countryCode?: string | null;
    } | null;
    orders?: {
      __typename?: "reverb_search_SearchResponse";
      total?: number | null;
    } | null;
  } | null;
  shipping?: {
    __typename?: "core_apimessages_ShippingPrices";
    localPickupOnly?: boolean | null;
  } | null;
};

export type DeclineFeedbackMutationMutationVariables = Exact<{
  input?: InputMaybe<Input_core_apimessages_DeclineFeedbackRequest>;
}>;

export type DeclineFeedbackMutationMutation = {
  __typename?: "Mutation";
  declineFeedbackRequest?: {
    __typename?: "core_apimessages_DeclineFeedbackResponse";
    orderUuid?: string | null;
  } | null;
};

export type Core_Feedback_OrderInfoQueryVariables = Exact<{
  orderUuid?: InputMaybe<Scalars["String"]["input"]>;
}>;

export type Core_Feedback_OrderInfoQuery = {
  __typename?: "Query";
  me?: {
    __typename?: "rql_Me";
    _id?: string | null;
    uuid?: string | null;
    orders?: Array<{
      __typename?: "Order";
      _id?: string | null;
      legacyOrderId?: string | null;
      buyerUuid?: string | null;
      status?: core_apimessages_Order_Status | null;
      buyer?: {
        __typename?: "User";
        _id?: string | null;
        name?: string | null;
        shop?: {
          __typename?: "core_apimessages_Shop";
          name?: string | null;
          slug?: string | null;
        } | null;
      } | null;
      amountTotal?: {
        __typename?: "core_apimessages_Money";
        amount?: string | null;
        amountCents?: number | null;
        currency?: string | null;
        symbol?: string | null;
        display?: string | null;
      } | null;
      shippedAt?: {
        __typename?: "google_protobuf_Timestamp";
        seconds?: number | null;
      } | null;
      createdAt?: {
        __typename?: "google_protobuf_Timestamp";
        seconds?: number | null;
      } | null;
      listing?: {
        __typename?: "Listing";
        _id?: string | null;
        id?: string | null;
        slug?: string | null;
        title?: string | null;
        merchandisingType?: string | null;
        shopUuid?: string | null;
        images?: Array<{
          __typename?: "core_apimessages_Image";
          _id?: string | null;
          source?: string | null;
        } | null> | null;
      } | null;
    } | null> | null;
  } | null;
};

export type UpdateFeedbackMutationVariables = Exact<{
  input?: InputMaybe<Input_core_apimessages_UpdateFeedbackRequest>;
}>;

export type UpdateFeedbackMutation = {
  __typename?: "Mutation";
  updateFeedbackRequest?: {
    __typename?: "core_apimessages_UpdateFeedbackResponse";
    feedback?: {
      __typename?: "core_apimessages_Feedback";
      rating?: number | null;
      message?: string | null;
      shippingSatisfactory?: {
        __typename?: "google_protobuf_BoolValue";
        value?: boolean | null;
      } | null;
      conditionSatisfactory?: {
        __typename?: "google_protobuf_BoolValue";
        value?: boolean | null;
      } | null;
    } | null;
  } | null;
};

export type CreateMyFeedbackMessageMutationVariables = Exact<{
  input?: InputMaybe<Input_core_apimessages_CreateMyFeedbackMessageRequest>;
}>;

export type CreateMyFeedbackMessageMutation = {
  __typename?: "Mutation";
  createMyFeedbackMessage?: {
    __typename?: "core_apimessages_CreateMyFeedbackMessageResponse";
    id?: string | null;
  } | null;
};

export type DeleteMyFeedbackMessageMutationVariables = Exact<{
  input?: InputMaybe<Input_core_apimessages_DeleteMyFeedbackMessageRequest>;
}>;

export type DeleteMyFeedbackMessageMutation = {
  __typename?: "Mutation";
  deleteMyFeedbackMessage?: {
    __typename?: "core_apimessages_DeleteMyFeedbackMessageResponse";
    id?: string | null;
  } | null;
};

export type MyFeedbackMessagesQueryVariables = Exact<{ [key: string]: never }>;

export type MyFeedbackMessagesQuery = {
  __typename?: "Query";
  myFeedbackMessages?: {
    __typename?: "core_apimessages_MyFeedbackMessagesResponse";
    feedbackMessages?: Array<{
      __typename?: "core_apimessages_FeedbackMessage";
      id?: string | null;
      message?: string | null;
    } | null> | null;
  } | null;
};

export type Core_Feedback_SellerShopInfoQueryVariables = Exact<{
  listingId?: InputMaybe<Scalars["String"]["input"]>;
}>;

export type Core_Feedback_SellerShopInfoQuery = {
  __typename?: "Query";
  listing?: {
    __typename?: "Listing";
    _id?: string | null;
    shop?: {
      __typename?: "Shop";
      _id?: string | null;
      name?: string | null;
      slug?: string | null;
    } | null;
  } | null;
};

export type GearCollectionSettingsDataFragment = {
  __typename?: "rql_Me";
  _id?: string | null;
  uuid?: string | null;
  gearCollectionSettings?: {
    __typename?: "core_apimessages_GearCollectionSettingsResponse";
    id?: string | null;
    autoAddOrders?: boolean | null;
  } | null;
};

export type Core_GearCollection_SettingsQueryVariables = Exact<{
  [key: string]: never;
}>;

export type Core_GearCollection_SettingsQuery = {
  __typename?: "Query";
  me?: {
    __typename?: "rql_Me";
    _id?: string | null;
    uuid?: string | null;
    gearCollectionSettings?: {
      __typename?: "core_apimessages_GearCollectionSettingsResponse";
      id?: string | null;
      autoAddOrders?: boolean | null;
    } | null;
  } | null;
};

export type Core_GearCollection_UpdateSettingsMutationVariables = Exact<{
  input?: InputMaybe<Input_core_apimessages_UpdateMyGearCollectionSettingsRequest>;
}>;

export type Core_GearCollection_UpdateSettingsMutation = {
  __typename?: "Mutation";
  updateMyGearCollectionSettings?: {
    __typename?: "core_apimessages_UpdateMyGearCollectionSettingsResponse";
    gearCollectionSettings?: {
      __typename?: "core_apimessages_GearCollectionSettingsResponse";
      id?: string | null;
      autoAddOrders?: boolean | null;
    } | null;
  } | null;
};

export type GearCollectionCSPCardDataFragment = {
  __typename?: "CSP";
  _id?: string | null;
  id?: string | null;
  title?: string | null;
  finishes?: Array<string | null> | null;
  canonicalProductIds?: Array<string | null> | null;
  image?: {
    __typename?: "core_apimessages_Image";
    source?: string | null;
  } | null;
  brand?: { __typename?: "Brand"; name?: string | null } | null;
};

export type GearCollectionCSPSummaryCardDataFragment = {
  __typename?: "CSP";
  _id?: string | null;
  id?: string | null;
  title?: string | null;
  image?: {
    __typename?: "core_apimessages_Image";
    _id?: string | null;
    source?: string | null;
  } | null;
  brand?: {
    __typename?: "Brand";
    _id?: string | null;
    name?: string | null;
  } | null;
  canonicalProducts?: Array<{
    __typename?: "CanonicalProduct";
    _id?: string | null;
    id?: string | null;
    name?: string | null;
    model?: string | null;
    year?: string | null;
    finish?: string | null;
  } | null> | null;
};

export type Core_CSPVariantPicker_CSPQueryVariables = Exact<{
  id?: InputMaybe<Scalars["String"]["input"]>;
}>;

export type Core_CSPVariantPicker_CSPQuery = {
  __typename?: "Query";
  csp?: {
    __typename?: "CSP";
    _id?: string | null;
    id?: string | null;
    title?: string | null;
    image?: {
      __typename?: "core_apimessages_Image";
      _id?: string | null;
      source?: string | null;
    } | null;
    brand?: {
      __typename?: "Brand";
      _id?: string | null;
      name?: string | null;
    } | null;
    canonicalProducts?: Array<{
      __typename?: "CanonicalProduct";
      _id?: string | null;
      id?: string | null;
      name?: string | null;
      model?: string | null;
      year?: string | null;
      finish?: string | null;
    } | null> | null;
  } | null;
};

export type DataServices_GearCollections_PriceRecommendationsQueryVariables =
  Exact<{
    priceRecommendationQueries?: InputMaybe<
      | Array<InputMaybe<Input_reverb_pricing_PriceRecommendationQuery>>
      | InputMaybe<Input_reverb_pricing_PriceRecommendationQuery>
    >;
  }>;

export type DataServices_GearCollections_PriceRecommendationsQuery = {
  __typename?: "Query";
  priceRecommendations?: {
    __typename?: "reverb_pricing_PriceRecommendationsResponse";
    priceRecommendations?: Array<{
      __typename?: "reverb_pricing_PriceRecommendation";
      conditionUuid?: string | null;
      priceLow?: {
        __typename?: "reverb_pricing_Money";
        amountCents?: number | null;
        display?: string | null;
        currency?: string | null;
      } | null;
      priceMiddle?: {
        __typename?: "reverb_pricing_Money";
        amountCents?: number | null;
        display?: string | null;
        currency?: string | null;
      } | null;
      priceHigh?: {
        __typename?: "reverb_pricing_Money";
        amountCents?: number | null;
        display?: string | null;
        currency?: string | null;
      } | null;
      priceLowThirtyDaysAgo?: {
        __typename?: "reverb_pricing_Money";
        amountCents?: number | null;
        display?: string | null;
        currency?: string | null;
      } | null;
      priceMiddleThirtyDaysAgo?: {
        __typename?: "reverb_pricing_Money";
        amountCents?: number | null;
        display?: string | null;
        currency?: string | null;
      } | null;
      priceHighThirtyDaysAgo?: {
        __typename?: "reverb_pricing_Money";
        amountCents?: number | null;
        display?: string | null;
        currency?: string | null;
      } | null;
    } | null> | null;
  } | null;
};

export type GearCollectionCSPDataFragment = {
  __typename?: "CSP";
  _id?: string | null;
  id?: string | null;
  title?: string | null;
  brand?: {
    __typename?: "Brand";
    _id?: string | null;
    name?: string | null;
  } | null;
  webLink?: {
    __typename?: "core_apimessages_Link";
    href?: string | null;
    path?: string | null;
  } | null;
  image?: {
    __typename?: "core_apimessages_Image";
    _id?: string | null;
    source?: string | null;
  } | null;
};

export type Core_Crupdate_GearCollectionItemMutationVariables = Exact<{
  input?: InputMaybe<Input_core_apimessages_UpdateGearCollectionItemRequest>;
}>;

export type Core_Crupdate_GearCollectionItemMutation = {
  __typename?: "Mutation";
  crupdateGearCollectionItem?: {
    __typename?: "GearCollectionItem";
    _id?: string | null;
    id?: string | null;
    title?: string | null;
    description?: string | null;
    notes?: string | null;
    make?: string | null;
    model?: string | null;
    finish?: string | null;
    year?: string | null;
    canonicalProductId?: string | null;
    subcategoryUuids?: Array<string | null> | null;
    serialNumber?: string | null;
    hideListingPhotos?: boolean | null;
    hideListingDescription?: boolean | null;
    condition?: {
      __typename?: "core_apimessages_Condition";
      conditionSlug?: string | null;
    } | null;
    tags?: Array<{
      __typename?: "core_apimessages_GearCollectionItemTag";
      name?: string | null;
    } | null> | null;
    price?: {
      __typename?: "core_apimessages_Money";
      amount?: string | null;
      amountCents?: number | null;
      currency?: string | null;
      display?: string | null;
    } | null;
    ownerCost?: {
      __typename?: "core_apimessages_Money";
      amount?: string | null;
      amountCents?: number | null;
      currency?: string | null;
      display?: string | null;
    } | null;
    categories?: Array<{
      __typename?: "Category";
      _id?: string | null;
      id?: string | null;
      name?: string | null;
      root?: boolean | null;
      leaf?: boolean | null;
    } | null> | null;
    originalListing?: {
      __typename?: "Listing";
      _id?: string | null;
      id?: string | null;
      description?: string | null;
      title?: string | null;
      images?: Array<{
        __typename?: "core_apimessages_Image";
        _id?: string | null;
        source?: string | null;
      } | null> | null;
    } | null;
    listing?: {
      __typename?: "Listing";
      _id?: string | null;
      id?: string | null;
    } | null;
    csp?: {
      __typename?: "CSP";
      _id?: string | null;
      id?: string | null;
      title?: string | null;
      brand?: {
        __typename?: "Brand";
        _id?: string | null;
        name?: string | null;
      } | null;
      webLink?: {
        __typename?: "core_apimessages_Link";
        href?: string | null;
        path?: string | null;
      } | null;
      image?: {
        __typename?: "core_apimessages_Image";
        _id?: string | null;
        source?: string | null;
      } | null;
    } | null;
    dataServicesPriceEstimate?: {
      __typename?: "reverb_pricing_PriceRecommendation";
      priceLow?: {
        __typename?: "reverb_pricing_Money";
        amountCents?: number | null;
        currency?: string | null;
      } | null;
      priceHigh?: {
        __typename?: "reverb_pricing_Money";
        amountCents?: number | null;
        currency?: string | null;
      } | null;
      priceMiddle?: {
        __typename?: "reverb_pricing_Money";
        amountCents?: number | null;
        currency?: string | null;
      } | null;
      priceMiddleThirtyDaysAgo?: {
        __typename?: "reverb_pricing_Money";
        amountCents?: number | null;
        currency?: string | null;
      } | null;
    } | null;
    images?: Array<{
      __typename?: "core_apimessages_Image";
      _id?: string | null;
      id?: string | null;
      source?: string | null;
      publicId?: string | null;
      version?: string | null;
      fallbackType?: core_apimessages_Image_FallbackType | null;
      height?: number | null;
      width?: number | null;
      transformation?: {
        __typename?: "core_apimessages_ImageEntry_Transformation";
        x?: string | null;
        y?: string | null;
        width?: string | null;
        height?: string | null;
        angle?: number | null;
        crop?: string | null;
      } | null;
    } | null> | null;
  } | null;
};

export type Core_Delete_GearCollectionItemMutationVariables = Exact<{
  input?: InputMaybe<Input_core_apimessages_DeleteGearCollectionItemRequest>;
}>;

export type Core_Delete_GearCollectionItemMutation = {
  __typename?: "Mutation";
  deleteGearCollectionItem?: {
    __typename?: "GearCollectionItem";
    _id?: string | null;
    id?: string | null;
    title?: string | null;
    description?: string | null;
    notes?: string | null;
    make?: string | null;
    model?: string | null;
    finish?: string | null;
    year?: string | null;
    canonicalProductId?: string | null;
    subcategoryUuids?: Array<string | null> | null;
    serialNumber?: string | null;
    hideListingPhotos?: boolean | null;
    hideListingDescription?: boolean | null;
    condition?: {
      __typename?: "core_apimessages_Condition";
      conditionSlug?: string | null;
    } | null;
    tags?: Array<{
      __typename?: "core_apimessages_GearCollectionItemTag";
      name?: string | null;
    } | null> | null;
    price?: {
      __typename?: "core_apimessages_Money";
      amount?: string | null;
      amountCents?: number | null;
      currency?: string | null;
      display?: string | null;
    } | null;
    ownerCost?: {
      __typename?: "core_apimessages_Money";
      amount?: string | null;
      amountCents?: number | null;
      currency?: string | null;
      display?: string | null;
    } | null;
    categories?: Array<{
      __typename?: "Category";
      _id?: string | null;
      id?: string | null;
      name?: string | null;
      root?: boolean | null;
      leaf?: boolean | null;
    } | null> | null;
    originalListing?: {
      __typename?: "Listing";
      _id?: string | null;
      id?: string | null;
      description?: string | null;
      title?: string | null;
      images?: Array<{
        __typename?: "core_apimessages_Image";
        _id?: string | null;
        source?: string | null;
      } | null> | null;
    } | null;
    listing?: {
      __typename?: "Listing";
      _id?: string | null;
      id?: string | null;
    } | null;
    csp?: {
      __typename?: "CSP";
      _id?: string | null;
      id?: string | null;
      title?: string | null;
      brand?: {
        __typename?: "Brand";
        _id?: string | null;
        name?: string | null;
      } | null;
      webLink?: {
        __typename?: "core_apimessages_Link";
        href?: string | null;
        path?: string | null;
      } | null;
      image?: {
        __typename?: "core_apimessages_Image";
        _id?: string | null;
        source?: string | null;
      } | null;
    } | null;
    dataServicesPriceEstimate?: {
      __typename?: "reverb_pricing_PriceRecommendation";
      priceLow?: {
        __typename?: "reverb_pricing_Money";
        amountCents?: number | null;
        currency?: string | null;
      } | null;
      priceHigh?: {
        __typename?: "reverb_pricing_Money";
        amountCents?: number | null;
        currency?: string | null;
      } | null;
      priceMiddle?: {
        __typename?: "reverb_pricing_Money";
        amountCents?: number | null;
        currency?: string | null;
      } | null;
      priceMiddleThirtyDaysAgo?: {
        __typename?: "reverb_pricing_Money";
        amountCents?: number | null;
        currency?: string | null;
      } | null;
    } | null;
    images?: Array<{
      __typename?: "core_apimessages_Image";
      _id?: string | null;
      id?: string | null;
      source?: string | null;
      publicId?: string | null;
      version?: string | null;
      fallbackType?: core_apimessages_Image_FallbackType | null;
      height?: number | null;
      width?: number | null;
      transformation?: {
        __typename?: "core_apimessages_ImageEntry_Transformation";
        x?: string | null;
        y?: string | null;
        width?: string | null;
        height?: string | null;
        angle?: number | null;
        crop?: string | null;
      } | null;
    } | null> | null;
  } | null;
};

export type Core_GearCollectionItem_FormQueryVariables = Exact<{
  shouldSeedListingData: Scalars["Boolean"]["input"];
  shouldFetchCsp: Scalars["Boolean"]["input"];
  seedId?: InputMaybe<Scalars["String"]["input"]>;
  seedType?: InputMaybe<core_apimessages_Listing_SeedType>;
  cspId?: InputMaybe<Scalars["String"]["input"]>;
}>;

export type Core_GearCollectionItem_FormQuery = {
  __typename?: "Query";
  seedListing?: {
    __typename?: "core_apimessages_SeedListingResponse";
    listing?: {
      __typename?: "core_apimessages_SeededListing";
      make?: string | null;
      model?: string | null;
      brandSlug?: string | null;
      categoryRootUuid?: string | null;
      subcategoryLeafUuids?: Array<string | null> | null;
      canonicalProductId?: string | null;
      finish?: string | null;
      year?: string | null;
      conditionSlug?: string | null;
      sellerCost?: {
        __typename?: "core_apimessages_Money";
        amount?: string | null;
        amountCents?: number | null;
        currency?: string | null;
        display?: string | null;
      } | null;
    } | null;
  } | null;
  csp?: {
    __typename?: "CSP";
    _id?: string | null;
    id?: string | null;
    title?: string | null;
    brand?: {
      __typename?: "Brand";
      _id?: string | null;
      name?: string | null;
    } | null;
    webLink?: {
      __typename?: "core_apimessages_Link";
      href?: string | null;
      path?: string | null;
    } | null;
    image?: {
      __typename?: "core_apimessages_Image";
      _id?: string | null;
      source?: string | null;
    } | null;
  } | null;
};

export type GearCollectionIndexCardFragment = {
  __typename?: "GearCollectionItem";
  _id?: string | null;
  id?: string | null;
  deleted?: boolean | null;
  title?: string | null;
  description?: string | null;
  created?: boolean | null;
  updated?: boolean | null;
  canonicalProductId?: string | null;
  tags?: Array<{
    __typename?: "core_apimessages_GearCollectionItemTag";
    name?: string | null;
  } | null> | null;
  images?: Array<{
    __typename?: "core_apimessages_Image";
    _id?: string | null;
    source?: string | null;
  } | null> | null;
  listing?: {
    __typename?: "Listing";
    _id?: string | null;
    id?: string | null;
  } | null;
  originalListing?: {
    __typename?: "Listing";
    _id?: string | null;
    images?: Array<{
      __typename?: "core_apimessages_Image";
      _id?: string | null;
      source?: string | null;
    } | null> | null;
  } | null;
  csp?: {
    __typename?: "CSP";
    _id?: string | null;
    slug?: string | null;
    image?: {
      __typename?: "core_apimessages_Image";
      _id?: string | null;
      source?: string | null;
    } | null;
  } | null;
  price?: {
    __typename?: "core_apimessages_Money";
    amountCents?: number | null;
    currency?: string | null;
    display?: string | null;
  } | null;
  esPriceEstimate?: {
    __typename?: "reverb_search_PriceEstimate";
    priceLow?: {
      __typename?: "reverb_search_Money";
      amountCents?: number | null;
      currency?: string | null;
      display?: string | null;
    } | null;
    priceHigh?: {
      __typename?: "reverb_search_Money";
      amountCents?: number | null;
      currency?: string | null;
      display?: string | null;
    } | null;
  } | null;
};

export type Core_GearCollectionItem_DeleteFromIndexMutationVariables = Exact<{
  input?: InputMaybe<Input_core_apimessages_DeleteGearCollectionItemRequest>;
}>;

export type Core_GearCollectionItem_DeleteFromIndexMutation = {
  __typename?: "Mutation";
  deleteGearCollectionItem?: {
    __typename?: "GearCollectionItem";
    _id?: string | null;
    id?: string | null;
    deleted?: boolean | null;
  } | null;
};

export type GcIndexCardPricesFragment = {
  __typename?: "GearCollectionItem";
  _id?: string | null;
  id?: string | null;
  created?: boolean | null;
  updated?: boolean | null;
  canonicalProductId?: string | null;
  price?: {
    __typename?: "core_apimessages_Money";
    amountCents?: number | null;
    currency?: string | null;
    display?: string | null;
  } | null;
  esPriceEstimate?: {
    __typename?: "reverb_search_PriceEstimate";
    priceLow?: {
      __typename?: "reverb_search_Money";
      amountCents?: number | null;
      currency?: string | null;
      display?: string | null;
    } | null;
    priceHigh?: {
      __typename?: "reverb_search_Money";
      amountCents?: number | null;
      currency?: string | null;
      display?: string | null;
    } | null;
  } | null;
};

export type Search_GearCollectionItem_IndexQueryVariables = Exact<{
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  categoryUuids?: InputMaybe<
    | Array<InputMaybe<Scalars["String"]["input"]>>
    | InputMaybe<Scalars["String"]["input"]>
  >;
  currency?: InputMaybe<Scalars["String"]["input"]>;
  tags?: InputMaybe<
    | Array<InputMaybe<Scalars["String"]["input"]>>
    | InputMaybe<Scalars["String"]["input"]>
  >;
}>;

export type Search_GearCollectionItem_IndexQuery = {
  __typename?: "Query";
  gearCollectionItemsSearch?: {
    __typename?: "reverb_search_SearchResponse";
    total?: number | null;
    offset?: number | null;
    limit?: number | null;
    filters?: Array<{
      __typename?: "reverb_search_Filter";
      name?: string | null;
      key?: string | null;
      aggregationName?: string | null;
      widgetType?: reverb_search_Filter_WidgetType | null;
      options?: Array<{
        __typename?: "reverb_search_FilterOption";
        name?: string | null;
        selected?: boolean | null;
        paramName?: string | null;
        setValues?: Array<string | null> | null;
        unsetValues?: Array<string | null> | null;
        all?: boolean | null;
        optionValue?: string | null;
        count?: {
          __typename?: "google_protobuf_Int32Value";
          value?: number | null;
        } | null;
      } | null> | null;
    } | null> | null;
    gearCollectionItems?: Array<{
      __typename?: "GearCollectionItem";
      _id?: string | null;
      id?: string | null;
      deleted?: boolean | null;
      title?: string | null;
      description?: string | null;
      created?: boolean | null;
      updated?: boolean | null;
      canonicalProductId?: string | null;
      tags?: Array<{
        __typename?: "core_apimessages_GearCollectionItemTag";
        name?: string | null;
      } | null> | null;
      images?: Array<{
        __typename?: "core_apimessages_Image";
        _id?: string | null;
        source?: string | null;
      } | null> | null;
      listing?: {
        __typename?: "Listing";
        _id?: string | null;
        id?: string | null;
      } | null;
      originalListing?: {
        __typename?: "Listing";
        _id?: string | null;
        images?: Array<{
          __typename?: "core_apimessages_Image";
          _id?: string | null;
          source?: string | null;
        } | null> | null;
      } | null;
      csp?: {
        __typename?: "CSP";
        _id?: string | null;
        slug?: string | null;
        image?: {
          __typename?: "core_apimessages_Image";
          _id?: string | null;
          source?: string | null;
        } | null;
      } | null;
      price?: {
        __typename?: "core_apimessages_Money";
        amountCents?: number | null;
        currency?: string | null;
        display?: string | null;
      } | null;
      esPriceEstimate?: {
        __typename?: "reverb_search_PriceEstimate";
        priceLow?: {
          __typename?: "reverb_search_Money";
          amountCents?: number | null;
          currency?: string | null;
          display?: string | null;
        } | null;
        priceHigh?: {
          __typename?: "reverb_search_Money";
          amountCents?: number | null;
          currency?: string | null;
          display?: string | null;
        } | null;
      } | null;
    } | null> | null;
  } | null;
  statsSearch?: {
    __typename?: "reverb_search_SearchResponse";
    total?: number | null;
    gearCollectionStats?: {
      __typename?: "reverb_search_GearCollectionStats";
      totalCollectionEstimate?: {
        __typename?: "reverb_search_GearCollectionTotalEstimate";
        totalEstimateLow?: {
          __typename?: "reverb_search_Money";
          amountCents?: number | null;
          currency?: string | null;
          display?: string | null;
        } | null;
        totalEstimateHigh?: {
          __typename?: "reverb_search_Money";
          amountCents?: number | null;
          currency?: string | null;
          display?: string | null;
        } | null;
      } | null;
    } | null;
  } | null;
};

export type Core_OrderSearch_AddPastPurchasesToGearCollectionQueryVariables =
  Exact<{
    actionableStatuses?: InputMaybe<
      | Array<InputMaybe<Scalars["String"]["input"]>>
      | InputMaybe<Scalars["String"]["input"]>
    >;
    listingCategoryUuids?: InputMaybe<
      | Array<InputMaybe<Scalars["String"]["input"]>>
      | InputMaybe<Scalars["String"]["input"]>
    >;
    createdAfterDate?: InputMaybe<Scalars["String"]["input"]>;
    minAmountProductCentsUsd?: InputMaybe<Scalars["String"]["input"]>;
    limit?: InputMaybe<Scalars["Int"]["input"]>;
    withoutGearCollectionItemOnly?: InputMaybe<Scalars["Boolean"]["input"]>;
  }>;

export type Core_OrderSearch_AddPastPurchasesToGearCollectionQuery = {
  __typename?: "Query";
  ordersSearch?: {
    __typename?: "reverb_search_SearchResponse";
    orders?: Array<{
      __typename?: "Order";
      _id?: string | null;
      legacyOrderId?: string | null;
      createdAt?: {
        __typename?: "google_protobuf_Timestamp";
        seconds?: number | null;
      } | null;
      paidAt?: {
        __typename?: "google_protobuf_Timestamp";
        seconds?: number | null;
      } | null;
      listing?: {
        __typename?: "Listing";
        _id?: string | null;
        title?: string | null;
        csp?: {
          __typename?: "CSP";
          _id?: string | null;
          id?: string | null;
        } | null;
        images?: Array<{
          __typename?: "core_apimessages_Image";
          _id?: string | null;
          source?: string | null;
        } | null> | null;
      } | null;
    } | null> | null;
  } | null;
};

export type QuickAddOrderCardFieldsFragment = {
  __typename?: "Order";
  _id?: string | null;
  legacyOrderId?: string | null;
  createdAt?: {
    __typename?: "google_protobuf_Timestamp";
    seconds?: number | null;
  } | null;
  paidAt?: {
    __typename?: "google_protobuf_Timestamp";
    seconds?: number | null;
  } | null;
  listing?: {
    __typename?: "Listing";
    _id?: string | null;
    title?: string | null;
    csp?: {
      __typename?: "CSP";
      _id?: string | null;
      id?: string | null;
    } | null;
    images?: Array<{
      __typename?: "core_apimessages_Image";
      _id?: string | null;
      source?: string | null;
    } | null> | null;
  } | null;
};

export type GearCollectionItemShowDisplayDataFragment = {
  __typename?: "GearCollectionItem";
  _id?: string | null;
  id?: string | null;
  title?: string | null;
  description?: string | null;
  notes?: string | null;
  make?: string | null;
  model?: string | null;
  finish?: string | null;
  year?: string | null;
  canonicalProductId?: string | null;
  subcategoryUuids?: Array<string | null> | null;
  serialNumber?: string | null;
  hideListingPhotos?: boolean | null;
  hideListingDescription?: boolean | null;
  condition?: {
    __typename?: "core_apimessages_Condition";
    conditionSlug?: string | null;
  } | null;
  tags?: Array<{
    __typename?: "core_apimessages_GearCollectionItemTag";
    name?: string | null;
  } | null> | null;
  price?: {
    __typename?: "core_apimessages_Money";
    amount?: string | null;
    amountCents?: number | null;
    currency?: string | null;
    display?: string | null;
  } | null;
  ownerCost?: {
    __typename?: "core_apimessages_Money";
    amount?: string | null;
    amountCents?: number | null;
    currency?: string | null;
    display?: string | null;
  } | null;
  categories?: Array<{
    __typename?: "Category";
    _id?: string | null;
    id?: string | null;
    name?: string | null;
    root?: boolean | null;
    leaf?: boolean | null;
  } | null> | null;
  originalListing?: {
    __typename?: "Listing";
    _id?: string | null;
    id?: string | null;
    description?: string | null;
    title?: string | null;
    images?: Array<{
      __typename?: "core_apimessages_Image";
      _id?: string | null;
      source?: string | null;
    } | null> | null;
  } | null;
  listing?: {
    __typename?: "Listing";
    _id?: string | null;
    id?: string | null;
  } | null;
  csp?: {
    __typename?: "CSP";
    _id?: string | null;
    id?: string | null;
    title?: string | null;
    brand?: {
      __typename?: "Brand";
      _id?: string | null;
      name?: string | null;
    } | null;
    webLink?: {
      __typename?: "core_apimessages_Link";
      href?: string | null;
      path?: string | null;
    } | null;
    image?: {
      __typename?: "core_apimessages_Image";
      _id?: string | null;
      source?: string | null;
    } | null;
  } | null;
  dataServicesPriceEstimate?: {
    __typename?: "reverb_pricing_PriceRecommendation";
    priceLow?: {
      __typename?: "reverb_pricing_Money";
      amountCents?: number | null;
      currency?: string | null;
    } | null;
    priceHigh?: {
      __typename?: "reverb_pricing_Money";
      amountCents?: number | null;
      currency?: string | null;
    } | null;
    priceMiddle?: {
      __typename?: "reverb_pricing_Money";
      amountCents?: number | null;
      currency?: string | null;
    } | null;
    priceMiddleThirtyDaysAgo?: {
      __typename?: "reverb_pricing_Money";
      amountCents?: number | null;
      currency?: string | null;
    } | null;
  } | null;
  images?: Array<{
    __typename?: "core_apimessages_Image";
    _id?: string | null;
    id?: string | null;
    source?: string | null;
    publicId?: string | null;
    version?: string | null;
    fallbackType?: core_apimessages_Image_FallbackType | null;
    height?: number | null;
    width?: number | null;
    transformation?: {
      __typename?: "core_apimessages_ImageEntry_Transformation";
      x?: string | null;
      y?: string | null;
      width?: string | null;
      height?: string | null;
      angle?: number | null;
      crop?: string | null;
    } | null;
  } | null> | null;
};

export type CoreGearCollectionItemShowLayoutQueryVariables = Exact<{
  id?: InputMaybe<Scalars["String"]["input"]>;
}>;

export type CoreGearCollectionItemShowLayoutQuery = {
  __typename?: "Query";
  gearcollectionitem?: {
    __typename?: "GearCollectionItem";
    _id?: string | null;
    id?: string | null;
    title?: string | null;
    description?: string | null;
    notes?: string | null;
    make?: string | null;
    model?: string | null;
    finish?: string | null;
    year?: string | null;
    canonicalProductId?: string | null;
    subcategoryUuids?: Array<string | null> | null;
    serialNumber?: string | null;
    hideListingPhotos?: boolean | null;
    hideListingDescription?: boolean | null;
    condition?: {
      __typename?: "core_apimessages_Condition";
      conditionSlug?: string | null;
    } | null;
    tags?: Array<{
      __typename?: "core_apimessages_GearCollectionItemTag";
      name?: string | null;
    } | null> | null;
    price?: {
      __typename?: "core_apimessages_Money";
      amount?: string | null;
      amountCents?: number | null;
      currency?: string | null;
      display?: string | null;
    } | null;
    ownerCost?: {
      __typename?: "core_apimessages_Money";
      amount?: string | null;
      amountCents?: number | null;
      currency?: string | null;
      display?: string | null;
    } | null;
    categories?: Array<{
      __typename?: "Category";
      _id?: string | null;
      id?: string | null;
      name?: string | null;
      root?: boolean | null;
      leaf?: boolean | null;
    } | null> | null;
    originalListing?: {
      __typename?: "Listing";
      _id?: string | null;
      id?: string | null;
      description?: string | null;
      title?: string | null;
      images?: Array<{
        __typename?: "core_apimessages_Image";
        _id?: string | null;
        source?: string | null;
      } | null> | null;
    } | null;
    listing?: {
      __typename?: "Listing";
      _id?: string | null;
      id?: string | null;
    } | null;
    csp?: {
      __typename?: "CSP";
      _id?: string | null;
      id?: string | null;
      title?: string | null;
      brand?: {
        __typename?: "Brand";
        _id?: string | null;
        name?: string | null;
      } | null;
      webLink?: {
        __typename?: "core_apimessages_Link";
        href?: string | null;
        path?: string | null;
      } | null;
      image?: {
        __typename?: "core_apimessages_Image";
        _id?: string | null;
        source?: string | null;
      } | null;
    } | null;
    dataServicesPriceEstimate?: {
      __typename?: "reverb_pricing_PriceRecommendation";
      priceLow?: {
        __typename?: "reverb_pricing_Money";
        amountCents?: number | null;
        currency?: string | null;
      } | null;
      priceHigh?: {
        __typename?: "reverb_pricing_Money";
        amountCents?: number | null;
        currency?: string | null;
      } | null;
      priceMiddle?: {
        __typename?: "reverb_pricing_Money";
        amountCents?: number | null;
        currency?: string | null;
      } | null;
      priceMiddleThirtyDaysAgo?: {
        __typename?: "reverb_pricing_Money";
        amountCents?: number | null;
        currency?: string | null;
      } | null;
    } | null;
    images?: Array<{
      __typename?: "core_apimessages_Image";
      _id?: string | null;
      id?: string | null;
      source?: string | null;
      publicId?: string | null;
      version?: string | null;
      fallbackType?: core_apimessages_Image_FallbackType | null;
      height?: number | null;
      width?: number | null;
      transformation?: {
        __typename?: "core_apimessages_ImageEntry_Transformation";
        x?: string | null;
        y?: string | null;
        width?: string | null;
        height?: string | null;
        angle?: number | null;
        crop?: string | null;
      } | null;
    } | null> | null;
  } | null;
};

export type Core_Gift_Card_Credit_CardsQueryVariables = Exact<{
  [key: string]: never;
}>;

export type Core_Gift_Card_Credit_CardsQuery = {
  __typename?: "Query";
  me?: {
    __typename?: "rql_Me";
    _id?: string | null;
    uuid?: string | null;
    creditCards?: Array<{
      __typename?: "core_apimessages_CreditCard";
      id?: string | null;
      last4?: string | null;
      cardType?: string | null;
      expirationMonth?: string | null;
      expirationYear?: string | null;
    } | null> | null;
  } | null;
};

export type Core_ShopSettings_InternationalTaxProfileQueryVariables = Exact<{
  [key: string]: never;
}>;

export type Core_ShopSettings_InternationalTaxProfileQuery = {
  __typename?: "Query";
  me?: {
    __typename?: "rql_Me";
    _id?: string | null;
    uuid?: string | null;
    shop?: {
      __typename?: "MyShop";
      _id?: string | null;
      shopType?: core_apimessages_Shop_ShopType | null;
      marketplaceTaxReportingCountryCode?: string | null;
      internationalTaxProfile?: {
        __typename?: "core_apimessages_InternationalTaxProfile";
        id?: string | null;
        tinMask?: string | null;
        tinType?: string | null;
        name?: string | null;
        dateOfBirth?: string | null;
        address?: {
          __typename?: "core_apimessages_Address";
          _id?: string | null;
          name?: string | null;
          streetAddress?: string | null;
          extendedAddress?: string | null;
          locality?: string | null;
          region?: string | null;
          postalCode?: string | null;
          countryCode?: string | null;
          country?: {
            __typename?: "core_apimessages_Country";
            _id?: string | null;
            name?: string | null;
          } | null;
        } | null;
      } | null;
      businessRegistrationNumber?: {
        __typename?: "core_apimessages_BusinessRegistrationNumber";
        id?: string | null;
        registrationNumber?: string | null;
        countryCode?: string | null;
      } | null;
      taxIdentifications?: Array<{
        __typename?: "core_apimessages_TaxIdentification";
        id?: string | null;
        taxId?: string | null;
        taxType?: core_apimessages_TaxIdentification_TaxType | null;
        countryCode?: string | null;
      } | null> | null;
      address?: {
        __typename?: "core_apimessages_Address";
        _id?: string | null;
        countryCode?: string | null;
      } | null;
    } | null;
  } | null;
  countries?: {
    __typename?: "core_apimessages_CountriesResponse";
    countries?: Array<{
      __typename?: "core_apimessages_Country";
      _id?: string | null;
      countryCode?: string | null;
      name?: string | null;
      subregionRequired?: boolean | null;
      subregions?: Array<{
        __typename?: "core_apimessages_Subregion";
        _id?: string | null;
        code?: string | null;
        name?: string | null;
      } | null> | null;
    } | null> | null;
  } | null;
};

export type UpdateInternationalTaxProfileMutationVariables = Exact<{
  input?: InputMaybe<Input_core_apimessages_UpdateInternationalTaxProfileRequest>;
}>;

export type UpdateInternationalTaxProfileMutation = {
  __typename?: "Mutation";
  updateInternationalTaxProfile?: {
    __typename?: "core_apimessages_UpdateInternationalTaxProfileResponse";
    internationalTaxProfile?: {
      __typename?: "core_apimessages_InternationalTaxProfile";
      tinMask?: string | null;
      tinType?: string | null;
      name?: string | null;
      dateOfBirth?: string | null;
      address?: {
        __typename?: "core_apimessages_Address";
        _id?: string | null;
        name?: string | null;
        streetAddress?: string | null;
        extendedAddress?: string | null;
        locality?: string | null;
        region?: string | null;
        postalCode?: string | null;
        countryCode?: string | null;
        country?: {
          __typename?: "core_apimessages_Country";
          _id?: string | null;
          name?: string | null;
        } | null;
      } | null;
    } | null;
    businessRegistrationNumber?: {
      __typename?: "core_apimessages_BusinessRegistrationNumber";
      registrationNumber?: string | null;
      countryCode?: string | null;
    } | null;
  } | null;
};

export type Core_MyShopExportDac7OrderDataMutationVariables = Exact<{
  input?: InputMaybe<Input_core_apimessages_MyShopExportDac7OrderDataRequest>;
}>;

export type Core_MyShopExportDac7OrderDataMutation = {
  __typename?: "Mutation";
  myShopExportDac7OrderData?: {
    __typename?: "core_apimessages_MyShopExportDac7OrderDataResponse";
    success?: boolean | null;
  } | null;
};

export type Item2AboutThisListingDataFragment = {
  __typename?: "Listing";
  _id?: string | null;
  id?: string | null;
  description?: string | null;
  hasInventory?: boolean | null;
  sellerId?: string | null;
  propSixtyFiveWarning?: string | null;
  publishedAt?: {
    __typename?: "google_protobuf_Timestamp";
    seconds?: number | null;
  } | null;
  condition?: {
    __typename?: "core_apimessages_Condition";
    conditionUuid?: string | null;
  } | null;
  specs?: {
    __typename?: "core_apimessages_ListingSpec";
    productSpecs?: Array<{
      __typename?: "core_apimessages_ProductSpec";
      _id?: string | null;
      name?: string | null;
      value?: string | null;
      path?: string | null;
      slug?: string | null;
      type?: core_apimessages_ProductSpec_Type | null;
    } | null> | null;
  } | null;
  propSixtyFiveWarningImg?: {
    __typename?: "core_apimessages_Link";
    href?: string | null;
  } | null;
};

export type Item2AboutThisSellerDataFragment = {
  __typename?: "Listing";
  _id?: string | null;
  id?: string | null;
  seller?: {
    __typename?: "User";
    _id?: string | null;
    id?: string | null;
    uuid?: string | null;
    avatar?: {
      __typename?: "core_apimessages_Image";
      _id?: string | null;
      source?: string | null;
    } | null;
    feedbackSummary?: {
      __typename?: "core_apimessages_FeedbackSummary";
      receivedCount?: number | null;
      rollingRatingPercentage?: number | null;
    } | null;
  } | null;
  shop?: {
    __typename?: "Shop";
    _id?: string | null;
    name?: string | null;
    description?: string | null;
    slug?: string | null;
    preferredSeller?: boolean | null;
    quickShipper?: boolean | null;
    quickResponder?: boolean | null;
    createdAt?: any | null;
    address?: {
      __typename?: "core_apimessages_Address";
      _id?: string | null;
      displayLocation?: string | null;
      countryCode?: string | null;
    } | null;
    orders?: {
      __typename?: "reverb_search_SearchResponse";
      total?: number | null;
    } | null;
  } | null;
};

export type Core_About_This_Seller_Find_FavoriteQueryVariables = Exact<{
  shopSlug?: InputMaybe<Scalars["String"]["input"]>;
  loggedOut: Scalars["Boolean"]["input"];
}>;

export type Core_About_This_Seller_Find_FavoriteQuery = {
  __typename?: "Query";
  findFavorite?: {
    __typename?: "core_apimessages_FindFavoriteResponse";
    favorite?: {
      __typename?: "core_apimessages_Favorite";
      id?: string | null;
    } | null;
  } | null;
};

export type Item2AdWithCspsRowContainerDataFragment = {
  __typename?: "Listing";
  _id?: string | null;
  id?: string | null;
  categoryRootUuid?: string | null;
  categoryUuids?: Array<string | null> | null;
};

export type Item2AdminButtonsDataFragment = {
  __typename?: "Listing";
  _id?: string | null;
  id?: string | null;
  slug?: string | null;
  canonicalProductId?: string | null;
};

export type Item2FallbackBreadcrumbsDataFragment = {
  __typename?: "Listing";
  _id?: string | null;
  id?: string | null;
  make?: string | null;
  brandSlug?: string | null;
  categories?: Array<{
    __typename?: "Category";
    _id?: string | null;
    id?: string | null;
    slug?: string | null;
    name?: string | null;
    rootSlug?: string | null;
    webLink?: {
      __typename?: "core_apimessages_Link";
      href?: string | null;
    } | null;
    cmsLink?: {
      __typename?: "core_apimessages_Link";
      href?: string | null;
    } | null;
  } | null> | null;
  csp?: {
    __typename?: "CSP";
    _id?: string | null;
    id?: string | null;
    specs?: {
      __typename?: "core_apimessages_CSPSpec";
      productSpecs?: Array<{
        __typename?: "core_apimessages_ProductSpec";
        _id?: string | null;
        type?: core_apimessages_ProductSpec_Type | null;
        name?: string | null;
        value?: string | null;
        path?: string | null;
      } | null> | null;
    } | null;
  } | null;
};

export type Item2BreadcrumbsDataFragment = {
  __typename?: "Listing";
  _id?: string | null;
  breadcrumbs?: Array<{
    __typename?: "core_apimessages_ProductBreadcrumb";
    title?: string | null;
    url?: string | null;
  } | null> | null;
  csp?: {
    __typename?: "CSP";
    _id?: string | null;
    slug?: string | null;
    title?: string | null;
  } | null;
};

export type Item2CPODataFragment = {
  __typename?: "Listing";
  _id?: string | null;
  id?: string | null;
  brandSlug?: string | null;
  certifiedPreOwned?: {
    __typename?: "core_apimessages_ListingCertifiedPreOwned";
    title?: string | null;
    description?: string | null;
    tooltipDescription?: string | null;
    badgeIconUrl?: string | null;
    brandName?: string | null;
    pageUrl?: string | null;
    lightModeBrandIconUrl?: string | null;
    darkModeBrandIconUrl?: string | null;
  } | null;
};

export type Item2DescriptionDataFragment = {
  __typename?: "Listing";
  _id?: string | null;
  id?: string | null;
  description?: string | null;
  soldAsIs?: boolean | null;
  sellerId?: string | null;
  propSixtyFiveWarning?: string | null;
  propSixtyFiveWarningImg?: {
    __typename?: "core_apimessages_Link";
    href?: string | null;
  } | null;
};

export type Item2_Feedback_MetadataQueryVariables = Exact<{
  sellerUuid?: InputMaybe<Scalars["String"]["input"]>;
}>;

export type Item2_Feedback_MetadataQuery = {
  __typename?: "Query";
  user?: {
    __typename?: "User";
    _id?: string | null;
    allTotal?: {
      __typename?: "core_apimessages_FeedbacksResponse";
      total?: number | null;
    } | null;
    buyerTotal?: {
      __typename?: "core_apimessages_FeedbacksResponse";
      total?: number | null;
    } | null;
    sellerTotal?: {
      __typename?: "core_apimessages_FeedbacksResponse";
      total?: number | null;
    } | null;
  } | null;
};

export type Item2FeedbackItemDataFragment = {
  __typename?: "core_apimessages_Feedback";
  message?: string | null;
  orderTitle?: string | null;
  rating?: number | null;
  type?: core_apimessages_Feedback_Type | null;
  author?: {
    __typename?: "User";
    _id?: string | null;
    shortname?: string | null;
  } | null;
  createdAt?: {
    __typename?: "google_protobuf_Timestamp";
    seconds?: number | null;
  } | null;
  recipient?: {
    __typename?: "User";
    _id?: string | null;
    shortname?: string | null;
  } | null;
  listingLink?: {
    __typename?: "core_apimessages_Link";
    href?: string | null;
  } | null;
  response?: {
    __typename?: "core_apimessages_Feedback_Response";
    body?: string | null;
    createdAt?: {
      __typename?: "google_protobuf_Timestamp";
      seconds?: number | null;
    } | null;
  } | null;
};

export type Item2_Seller_ReviewsQueryVariables = Exact<{
  sellerUuid?: InputMaybe<Scalars["String"]["input"]>;
  type?: InputMaybe<core_apimessages_Feedback_Type>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
}>;

export type Item2_Seller_ReviewsQuery = {
  __typename?: "Query";
  user?: {
    __typename?: "User";
    _id?: string | null;
    feedback?: {
      __typename?: "core_apimessages_FeedbacksResponse";
      total?: number | null;
      feedbacks?: Array<{
        __typename?: "core_apimessages_Feedback";
        message?: string | null;
        orderTitle?: string | null;
        rating?: number | null;
        type?: core_apimessages_Feedback_Type | null;
        author?: {
          __typename?: "User";
          _id?: string | null;
          shortname?: string | null;
        } | null;
        createdAt?: {
          __typename?: "google_protobuf_Timestamp";
          seconds?: number | null;
        } | null;
        recipient?: {
          __typename?: "User";
          _id?: string | null;
          shortname?: string | null;
        } | null;
        listingLink?: {
          __typename?: "core_apimessages_Link";
          href?: string | null;
        } | null;
        response?: {
          __typename?: "core_apimessages_Feedback_Response";
          body?: string | null;
          createdAt?: {
            __typename?: "google_protobuf_Timestamp";
            seconds?: number | null;
          } | null;
        } | null;
      } | null> | null;
    } | null;
    shop?: {
      __typename?: "core_apimessages_Shop";
      slug?: string | null;
    } | null;
  } | null;
};

export type Item2FlagListingDataFragment = {
  __typename?: "Listing";
  _id?: string | null;
  id?: string | null;
  state?: string | null;
  sellerId?: string | null;
};

export type Item2FlagListingMeDataFragment = {
  __typename?: "rql_Me";
  _id?: string | null;
  uuid?: string | null;
  status?: core_apimessages_UserStatus | null;
};

export type ItemFooterFieldsFragment = {
  __typename?: "Listing";
  _id?: string | null;
  id?: string | null;
  categoryRootUuid?: string | null;
  state?: string | null;
  shopId?: string | null;
  combinedShippingParent?: boolean | null;
  brandSlug?: string | null;
  shop?: {
    __typename?: "Shop";
    _id?: string | null;
    name?: string | null;
    slug?: string | null;
    address?: {
      __typename?: "core_apimessages_Address";
      _id?: string | null;
      countryCode?: string | null;
    } | null;
  } | null;
  shipping?: {
    __typename?: "core_apimessages_ShippingPrices";
    localPickupOnly?: boolean | null;
    shippingPrices?: Array<{
      __typename?: "core_apimessages_ShippingPrice";
      _id?: string | null;
      shippingMethod?: core_apimessages_ShippingMethod | null;
      rate?: {
        __typename?: "core_apimessages_Money";
        amountCents?: number | null;
      } | null;
    } | null> | null;
  } | null;
  csp?: {
    __typename?: "CSP";
    _id?: string | null;
    slug?: string | null;
  } | null;
  categories?: Array<{
    __typename?: "Category";
    _id?: string | null;
    slug?: string | null;
  } | null> | null;
};

export type ItemImageFieldsFragment = {
  __typename?: "Listing";
  _id?: string | null;
  title?: string | null;
  state?: string | null;
  largeImages?: Array<{
    __typename?: "core_apimessages_Image";
    _id?: string | null;
    source?: string | null;
  } | null> | null;
  supersizeImages?: Array<{
    __typename?: "core_apimessages_Image";
    _id?: string | null;
    source?: string | null;
  } | null> | null;
  cardSquareImages?: Array<{
    __typename?: "core_apimessages_Image";
    _id?: string | null;
    source?: string | null;
  } | null> | null;
  video?: {
    __typename?: "core_apimessages_Video";
    youtubeVideoId?: string | null;
    empty?: boolean | null;
  } | null;
  csp?: {
    __typename?: "CSP";
    _id?: string | null;
    videos?: {
      __typename?: "core_apimessages_CSPVideo";
      videoLinks?: Array<{
        __typename?: "core_apimessages_VideoLink";
        youtubeVideoId?: string | null;
      } | null> | null;
    } | null;
  } | null;
};

export type Item2AvailableActionsDataFragment = {
  __typename?: "core_apimessages_AvailableAction";
  id?: core_apimessages_UserActionName | null;
  name?: core_apimessages_UserActionName | null;
  status?: core_apimessages_UserActionStatus | null;
};

export type Core_Listing_LayoutQueryVariables = Exact<{
  id?: InputMaybe<Scalars["String"]["input"]>;
  loggedOut: Scalars["Boolean"]["input"];
  shouldSkipTracking: Scalars["Boolean"]["input"];
  useNewBreadcrumbs: Scalars["Boolean"]["input"];
}>;

export type Core_Listing_LayoutQuery = {
  __typename?: "Query";
  listing?: {
    __typename?: "Listing";
    _id?: string | null;
    id?: string | null;
    state?: string | null;
    usOutlet?: boolean | null;
    slug?: string | null;
    offersEnabled?: boolean | null;
    inventory?: number | null;
    hasInventory?: boolean | null;
    maxBuyerQuantity?: number | null;
    make?: string | null;
    model?: string | null;
    description?: string | null;
    soldAsIs?: boolean | null;
    title?: string | null;
    listingType?: core_apimessages_Listing_ListingType | null;
    brandSlug?: string | null;
    sellerId?: string | null;
    finish?: string | null;
    acceptedPaymentMethods?: Array<string | null> | null;
    watching?: boolean | null;
    categoryRootUuid?: string | null;
    shopId?: string | null;
    combinedShippingParent?: boolean | null;
    categoryUuids?: Array<string | null> | null;
    canonicalProductId?: string | null;
    stateDescription?: string | null;
    taxIncluded?: boolean | null;
    taxIncludedHint?: string | null;
    isBuyerOfferEligible?: boolean | null;
    propSixtyFiveWarning?: string | null;
    bumpEligible?: boolean | null;
    currency?: string | null;
    sellerUuid?: string | null;
    adyenCheckoutPaymentMethodsConfig?: string | null;
    csp?: {
      __typename?: "CSP";
      _id?: string | null;
      slug?: string | null;
      id?: string | null;
      title?: string | null;
      specs?: {
        __typename?: "core_apimessages_CSPSpec";
        productSpecs?: Array<{
          __typename?: "core_apimessages_ProductSpec";
          _id?: string | null;
          type?: core_apimessages_ProductSpec_Type | null;
          name?: string | null;
          value?: string | null;
          path?: string | null;
        } | null> | null;
      } | null;
      videos?: {
        __typename?: "core_apimessages_CSPVideo";
        videoLinks?: Array<{
          __typename?: "core_apimessages_VideoLink";
          youtubeVideoId?: string | null;
        } | null> | null;
      } | null;
      productReviewSearch?: {
        __typename?: "rql_CSPProductReviewSearchResponse";
        stats?: {
          __typename?: "reverb_search_ProductReviewStats";
          total?: number | null;
          averageRating?: number | null;
        } | null;
      } | null;
      webLink?: {
        __typename?: "core_apimessages_Link";
        path?: string | null;
        href?: string | null;
      } | null;
      image?: {
        __typename?: "core_apimessages_Image";
        _id?: string | null;
        source?: string | null;
      } | null;
      inventory?: {
        __typename?: "core_apimessages_CSPInventory";
        usedTotal?: number | null;
        newTotal?: number | null;
        newLowPrice?: {
          __typename?: "core_apimessages_Money";
          display?: string | null;
          amountCents?: number | null;
        } | null;
        usedLowPrice?: {
          __typename?: "core_apimessages_Money";
          display?: string | null;
          amountCents?: number | null;
        } | null;
      } | null;
      brand?: {
        __typename?: "Brand";
        _id?: string | null;
        slug?: string | null;
      } | null;
    } | null;
    shop?: {
      __typename?: "Shop";
      _id?: string | null;
      name?: string | null;
      userUuid?: string | null;
      onVacation?: boolean | null;
      slug?: string | null;
      preferredSeller?: boolean | null;
      quickShipper?: boolean | null;
      quickResponder?: boolean | null;
      createdAt?: any | null;
      description?: string | null;
      id?: string | null;
      address?: {
        __typename?: "core_apimessages_Address";
        _id?: string | null;
        countryCode?: string | null;
        displayLocation?: string | null;
        country?: {
          __typename?: "core_apimessages_Country";
          _id?: string | null;
          name?: string | null;
        } | null;
      } | null;
      returnPolicy?: {
        __typename?: "core_apimessages_ReturnPolicy";
        _id?: string | null;
        usedReturnWindowDays?: number | null;
        newReturnWindowDays?: number | null;
      } | null;
      orders?: {
        __typename?: "reverb_search_SearchResponse";
        total?: number | null;
      } | null;
      freeDomesticShippingRate?: {
        __typename?: "core_apimessages_ShippingRates";
        regionCode?: string | null;
        freeShippingThreshold?: {
          __typename?: "core_apimessages_Money";
          amountCents?: number | null;
          currency?: string | null;
        } | null;
      } | null;
      user?: {
        __typename?: "User";
        _id?: string | null;
        feedbackSummary?: {
          __typename?: "core_apimessages_FeedbackSummary";
          rollingRatingPercentage?: number | null;
          receivedCount?: number | null;
        } | null;
      } | null;
    } | null;
    pricing?: {
      __typename?: "core_apimessages_ListingPricing";
      originalPriceDescription?: string | null;
      buyerPrice?: {
        __typename?: "core_apimessages_Money";
        amount?: string | null;
        currency?: string | null;
        amountCents?: number | null;
        display?: string | null;
      } | null;
      originalPrice?: {
        __typename?: "core_apimessages_Money";
        display?: string | null;
      } | null;
      ribbon?: {
        __typename?: "core_apimessages_Ribbon";
        display?: string | null;
        reason?: core_apimessages_Ribbon_Reason | null;
      } | null;
      typicalNewPriceDisplay?: {
        __typename?: "core_apimessages_TypicalNewPriceDisplay";
        amountDisplay?: string | null;
      } | null;
    } | null;
    shipmentPackage?: {
      __typename?: "ShipmentPackage";
      _id?: string | null;
      width?: {
        __typename?: "core_apimessages_Measurement";
        value?: string | null;
        unit?: string | null;
      } | null;
      height?: {
        __typename?: "core_apimessages_Measurement";
        value?: string | null;
        unit?: string | null;
      } | null;
      length?: {
        __typename?: "core_apimessages_Measurement";
        value?: string | null;
        unit?: string | null;
      } | null;
      weight?: {
        __typename?: "core_apimessages_Measurement";
        value?: string | null;
        unit?: string | null;
      } | null;
    } | null;
    experiments?: Array<{
      __typename?: "core_apimessages_NonUserExperiment";
      name?: string | null;
      bucket?: string | null;
    } | null> | null;
    affirmFinancingPromotion?: {
      __typename?: "core_apimessages_AffirmFinancingPromotion";
      zeroPercentFinancing?: boolean | null;
    } | null;
    digitalDetails?: {
      __typename?: "core_apimessages_ListingDigitalDetails";
      variants?: Array<{
        __typename?: "core_apimessages_ListingDigitalVariant";
        id?: string | null;
        downloadText?: string | null;
        fileUrl?: string | null;
        fileSize?: string | null;
        operatingSystems?: Array<string | null> | null;
        fileTypes?: Array<string | null> | null;
      } | null> | null;
      supportedFormats?: Array<{
        __typename?: "core_apimessages_ListingSupportedFormat";
        operatingSystem?: string | null;
        fileTypes?: Array<string | null> | null;
      } | null> | null;
    } | null;
    shipping?: {
      __typename?: "core_apimessages_ShippingPrices";
      localPickupOnly?: boolean | null;
      freeExpeditedShipping?: boolean | null;
      localPickup?: boolean | null;
      shippingPrices?: Array<{
        __typename?: "core_apimessages_ShippingPrice";
        _id?: string | null;
        shippingMethod?: core_apimessages_ShippingMethod | null;
        carrierCalculated?: boolean | null;
        destinationPostalCodeNeeded?: boolean | null;
        regional?: boolean | null;
        postalCode?: string | null;
        rate?: {
          __typename?: "core_apimessages_Money";
          amount?: string | null;
          amountCents?: number | null;
          currency?: string | null;
          display?: string | null;
        } | null;
      } | null> | null;
    } | null;
    condition?: {
      __typename?: "core_apimessages_Condition";
      conditionSlug?: string | null;
      displayName?: string | null;
      conditionUuid?: string | null;
    } | null;
    categories?: Array<{
      __typename?: "Category";
      _id?: string | null;
      id?: string | null;
      slug?: string | null;
      name?: string | null;
      rootSlug?: string | null;
      webLink?: {
        __typename?: "core_apimessages_Link";
        href?: string | null;
      } | null;
      cmsLink?: {
        __typename?: "core_apimessages_Link";
        href?: string | null;
      } | null;
    } | null> | null;
    breadcrumbs?: Array<{
      __typename?: "core_apimessages_ProductBreadcrumb";
      title?: string | null;
      url?: string | null;
    } | null> | null;
    largeImages?: Array<{
      __typename?: "core_apimessages_Image";
      _id?: string | null;
      source?: string | null;
    } | null> | null;
    supersizeImages?: Array<{
      __typename?: "core_apimessages_Image";
      _id?: string | null;
      source?: string | null;
    } | null> | null;
    cardSquareImages?: Array<{
      __typename?: "core_apimessages_Image";
      _id?: string | null;
      source?: string | null;
    } | null> | null;
    video?: {
      __typename?: "core_apimessages_Video";
      youtubeVideoId?: string | null;
      empty?: boolean | null;
      id?: string | null;
    } | null;
    preorderInfo?: {
      __typename?: "core_apimessages_PreorderInfo";
      onPreorder?: boolean | null;
      estimatedShipDate?: {
        __typename?: "google_protobuf_Timestamp";
        seconds?: number | null;
      } | null;
    } | null;
    metaImage?: Array<{
      __typename?: "core_apimessages_Image";
      _id?: string | null;
      source?: string | null;
    } | null> | null;
    orders?: {
      __typename?: "OrderCollection";
      nodes?: Array<{
        __typename?: "Order";
        _id?: string | null;
        legacyOrderId?: string | null;
        status?: core_apimessages_Order_Status | null;
        paidAt?: {
          __typename?: "google_protobuf_Timestamp";
          seconds?: number | null;
        } | null;
        orderUrl?: {
          __typename?: "core_apimessages_Link";
          href?: string | null;
        } | null;
        createdAt?: {
          __typename?: "google_protobuf_Timestamp";
          seconds?: number | null;
        } | null;
      } | null> | null;
    } | null;
    watchThumbnails?: Array<{
      __typename?: "core_apimessages_Image";
      _id?: string | null;
      source?: string | null;
    } | null> | null;
    publishedAt?: {
      __typename?: "google_protobuf_Timestamp";
      seconds?: number | null;
    } | null;
    specs?: {
      __typename?: "core_apimessages_ListingSpec";
      productSpecs?: Array<{
        __typename?: "core_apimessages_ProductSpec";
        _id?: string | null;
        name?: string | null;
        value?: string | null;
        path?: string | null;
        slug?: string | null;
        type?: core_apimessages_ProductSpec_Type | null;
      } | null> | null;
    } | null;
    seller?: {
      __typename?: "User";
      _id?: string | null;
      id?: string | null;
      uuid?: string | null;
      avatar?: {
        __typename?: "core_apimessages_Image";
        _id?: string | null;
        source?: string | null;
      } | null;
      feedbackSummary?: {
        __typename?: "core_apimessages_FeedbackSummary";
        receivedCount?: number | null;
        rollingRatingPercentage?: number | null;
      } | null;
    } | null;
    certifiedPreOwned?: {
      __typename?: "core_apimessages_ListingCertifiedPreOwned";
      title?: string | null;
      description?: string | null;
      tooltipDescription?: string | null;
      badgeIconUrl?: string | null;
      brandName?: string | null;
      pageUrl?: string | null;
      lightModeBrandIconUrl?: string | null;
      darkModeBrandIconUrl?: string | null;
    } | null;
    counts?: {
      __typename?: "core_apimessages_ListingCounts";
      id?: string | null;
      viewsDisplay?: string | null;
      offersDisplay?: string | null;
      watchersDisplay?: string | null;
      downloadCountDisplay?: string | null;
    } | null;
    propSixtyFiveWarningImg?: {
      __typename?: "core_apimessages_Link";
      href?: string | null;
    } | null;
    sale?: {
      __typename?: "core_apimessages_Sale";
      _id?: string | null;
      id?: string | null;
      code?: string | null;
      buyerIneligibilityReason?: string | null;
    } | null;
    estimatedMonthlyPaymentPlan?: {
      __typename?: "core_apimessages_EstimatedMonthlyPayment";
      financingProgramSlug?: string | null;
    } | null;
    expressPay?: {
      __typename?: "core_apimessages_ListingExpressPay";
      _id?: string | null;
      checkoutBundlingId?: string | null;
      paypal?: {
        __typename?: "core_apimessages_CheckoutPaypalDetails";
        currency?: string | null;
      } | null;
      estimatedTotal?: {
        __typename?: "core_apimessages_Money";
        amount?: string | null;
        currency?: string | null;
      } | null;
      googlePay?: {
        __typename?: "core_apimessages_CheckoutGooglePayDetails";
        merchantId?: string | null;
        merchantName?: string | null;
        gatewayMerchantId?: string | null;
        cardNetworks?: Array<core_apimessages_CheckoutGooglePayDetails_CardNetwork | null> | null;
      } | null;
    } | null;
  } | null;
};

export type Core_Listing_Layout_SignalsQueryVariables = Exact<{
  id?: InputMaybe<Scalars["String"]["input"]>;
}>;

export type Core_Listing_Layout_SignalsQuery = {
  __typename?: "Query";
  listing?: {
    __typename?: "Listing";
    _id?: string | null;
    id?: string | null;
    state?: string | null;
    usOutlet?: boolean | null;
    currency?: string | null;
    signals?: Array<{
      __typename?: "reverb_signals_Signal";
      name?: reverb_signals_Signal_Name | null;
      group?: reverb_signals_Signal_Group | null;
      title?: string | null;
      subtitle?: string | null;
      icon?: string | null;
      iconDark?: string | null;
      tooltipText?: string | null;
      link?: {
        __typename?: "reverb_signals_SignalLink";
        text?: string | null;
        url?: string | null;
      } | null;
    } | null> | null;
  } | null;
};

export type Core_Listing_Layout_SimilarListingsQueryVariables = Exact<{
  id?: InputMaybe<Scalars["String"]["input"]>;
  itemRegion?: InputMaybe<Scalars["String"]["input"]>;
  itemRegionRelation?: InputMaybe<reverb_search_ListingItemRegionRelation>;
}>;

export type Core_Listing_Layout_SimilarListingsQuery = {
  __typename?: "Query";
  listing?: {
    __typename?: "Listing";
    _id?: string | null;
    similarListings?: Array<{
      __typename?: "Listing";
      _id?: string | null;
      id?: string | null;
      sellerId?: string | null;
      listingType?: core_apimessages_Listing_ListingType | null;
      title?: string | null;
      slug?: string | null;
      make?: string | null;
      model?: string | null;
      upc?: string | null;
      state?: string | null;
      stateDescription?: string | null;
      bumped?: boolean | null;
      watching?: boolean | null;
      soldAsIs?: boolean | null;
      usOutlet?: boolean | null;
      offersEnabled?: boolean | null;
      isBuyerOfferEligible?: boolean | null;
      currency?: string | null;
      brandSlug?: string | null;
      categoryRootUuid?: string | null;
      categoryUuids?: Array<string | null> | null;
      bumpEligible?: boolean | null;
      shopId?: string | null;
      inventory?: number | null;
      acceptedPaymentMethods?: Array<string | null> | null;
      pricing?: {
        __typename?: "core_apimessages_ListingPricing";
        buyerPrice?: {
          __typename?: "core_apimessages_Money";
          amount?: string | null;
          amountCents?: number | null;
          display?: string | null;
          currency?: string | null;
        } | null;
        originalPrice?: {
          __typename?: "core_apimessages_Money";
          display?: string | null;
        } | null;
        ribbon?: {
          __typename?: "core_apimessages_Ribbon";
          display?: string | null;
          reason?: core_apimessages_Ribbon_Reason | null;
        } | null;
      } | null;
      preorderInfo?: {
        __typename?: "core_apimessages_PreorderInfo";
        onPreorder?: boolean | null;
        estimatedShipDate?: {
          __typename?: "google_protobuf_Timestamp";
          seconds?: number | null;
        } | null;
      } | null;
      publishedAt?: {
        __typename?: "google_protobuf_Timestamp";
        seconds?: number | null;
      } | null;
      condition?: {
        __typename?: "core_apimessages_Condition";
        displayName?: string | null;
        conditionSlug?: string | null;
        conditionUuid?: string | null;
      } | null;
      images?: Array<{
        __typename?: "core_apimessages_Image";
        source?: string | null;
      } | null> | null;
      shipping?: {
        __typename?: "core_apimessages_ShippingPrices";
        freeExpeditedShipping?: boolean | null;
        localPickupOnly?: boolean | null;
        localPickup?: boolean | null;
        shippingPrices?: Array<{
          __typename?: "core_apimessages_ShippingPrice";
          _id?: string | null;
          shippingMethod?: core_apimessages_ShippingMethod | null;
          carrierCalculated?: boolean | null;
          destinationPostalCodeNeeded?: boolean | null;
          regional?: boolean | null;
          postalCode?: string | null;
          rate?: {
            __typename?: "core_apimessages_Money";
            amount?: string | null;
            amountCents?: number | null;
            currency?: string | null;
            display?: string | null;
          } | null;
        } | null> | null;
      } | null;
      shop?: {
        __typename?: "Shop";
        _id?: string | null;
        name?: string | null;
        returnPolicy?: {
          __typename?: "core_apimessages_ReturnPolicy";
          usedReturnWindowDays?: number | null;
          newReturnWindowDays?: number | null;
          _id?: string | null;
        } | null;
        address?: {
          __typename?: "core_apimessages_Address";
          _id?: string | null;
          locality?: string | null;
          region?: string | null;
          displayLocation?: string | null;
          countryCode?: string | null;
          country?: {
            __typename?: "core_apimessages_Country";
            _id?: string | null;
            countryCode?: string | null;
            name?: string | null;
          } | null;
        } | null;
      } | null;
      watchThumbnails?: Array<{
        __typename?: "core_apimessages_Image";
        _id?: string | null;
        source?: string | null;
      } | null> | null;
      priceRecommendation?: {
        __typename?: "reverb_pricing_PriceRecommendation";
        priceMiddle?: {
          __typename?: "reverb_pricing_Money";
          amountCents?: number | null;
          currency?: string | null;
        } | null;
      } | null;
      certifiedPreOwned?: {
        __typename?: "core_apimessages_ListingCertifiedPreOwned";
        title?: string | null;
        badgeIconUrl?: string | null;
      } | null;
      categories?: Array<{
        __typename?: "Category";
        _id?: string | null;
        slug?: string | null;
        rootSlug?: string | null;
      } | null> | null;
      csp?: {
        __typename?: "CSP";
        _id?: string | null;
        id?: string | null;
        slug?: string | null;
        brand?: {
          __typename?: "Brand";
          _id?: string | null;
          slug?: string | null;
        } | null;
      } | null;
      sale?: {
        __typename?: "core_apimessages_Sale";
        _id?: string | null;
        id?: string | null;
        code?: string | null;
        buyerIneligibilityReason?: string | null;
      } | null;
    } | null> | null;
  } | null;
};

export type Core_Listing_Layout_MeQueryVariables = Exact<{
  listingId?: InputMaybe<Scalars["String"]["input"]>;
}>;

export type Core_Listing_Layout_MeQuery = {
  __typename?: "Query";
  me?: {
    __typename?: "rql_Me";
    _id?: string | null;
    uuid?: string | null;
    id?: string | null;
    status?: core_apimessages_UserStatus | null;
    myNegotiations?: {
      __typename?: "reverb_search_SearchResponse";
      negotiations?: Array<{
        __typename?: "Negotiation";
        _id?: string | null;
        id?: string | null;
        lastOffer?: {
          __typename?: "core_apimessages_Offer";
          status?: core_apimessages_Offer_OfferStatus | null;
        } | null;
      } | null> | null;
    } | null;
    availableActions?: Array<{
      __typename?: "core_apimessages_AvailableAction";
      id?: core_apimessages_UserActionName | null;
      name?: core_apimessages_UserActionName | null;
      status?: core_apimessages_UserActionStatus | null;
    } | null> | null;
    cart?: {
      __typename?: "rql_MyCart";
      cartItems?: Array<{
        __typename?: "core_apimessages_CartItem";
        _id?: string | null;
        shopId?: string | null;
        listingId?: string | null;
        subtotal?: {
          __typename?: "core_apimessages_Money";
          amountCents?: number | null;
          currency?: string | null;
        } | null;
      } | null> | null;
    } | null;
    profile?: {
      __typename?: "mparticle_ProfileResponse";
      totalBuyerOffersCreated?: number | null;
    } | null;
  } | null;
};

export type Item2ListingPaymentPoliciesDataFragment = {
  __typename?: "Listing";
  _id?: string | null;
  acceptedPaymentMethods?: Array<string | null> | null;
};

export type Item2ShopReturnPoliciesDataFragment = {
  __typename?: "Listing";
  _id?: string | null;
  id?: string | null;
  listingType?: core_apimessages_Listing_ListingType | null;
  acceptedPaymentMethods?: Array<string | null> | null;
  soldAsIs?: boolean | null;
  condition?: {
    __typename?: "core_apimessages_Condition";
    conditionSlug?: string | null;
  } | null;
  estimatedMonthlyPaymentPlan?: {
    __typename?: "core_apimessages_EstimatedMonthlyPayment";
    maxTermMonth?: number | null;
    estimatedMonthlyPayment?: {
      __typename?: "core_apimessages_Money";
      display?: string | null;
    } | null;
  } | null;
  shop?: {
    __typename?: "Shop";
    _id?: string | null;
    userUuid?: string | null;
    returnPolicy?: {
      __typename?: "core_apimessages_ReturnPolicy";
      newReturnWindowDays?: number | null;
      usedReturnWindowDays?: number | null;
      restockingFeePercent?: number | null;
      specialConditions?: string | null;
      legacyReturnPolicyText?: string | null;
    } | null;
  } | null;
};

export type Item2ShopShippingPoliciesDataFragment = {
  __typename?: "Listing";
  _id?: string | null;
  shipping?: {
    __typename?: "core_apimessages_ShippingPrices";
    localPickup?: boolean | null;
    shippingPrices?: Array<{
      __typename?: "core_apimessages_ShippingPrice";
      _id?: string | null;
      shippingMethod?: core_apimessages_ShippingMethod | null;
      rate?: {
        __typename?: "core_apimessages_Money";
        display?: string | null;
      } | null;
    } | null> | null;
  } | null;
  shop?: {
    __typename?: "Shop";
    _id?: string | null;
    shippingPolicy?: string | null;
    address?: {
      __typename?: "core_apimessages_Address";
      _id?: string | null;
      displayLocation?: string | null;
    } | null;
  } | null;
  allShippingPrices?: {
    __typename?: "core_apimessages_ShippingPrices";
    shippingPrices?: Array<{
      __typename?: "core_apimessages_ShippingPrice";
      _id?: string | null;
      shippingMethod?: core_apimessages_ShippingMethod | null;
      shippingRegionCode?: string | null;
      rate?: {
        __typename?: "core_apimessages_Money";
        display?: string | null;
      } | null;
    } | null> | null;
  } | null;
};

export type Item2MetadataDataFragment = {
  __typename?: "Listing";
  _id?: string | null;
  id?: string | null;
  title?: string | null;
  description?: string | null;
  finish?: string | null;
  make?: string | null;
  model?: string | null;
  slug?: string | null;
  inventory?: number | null;
  state?: string | null;
  brandSlug?: string | null;
  soldAsIs?: boolean | null;
  categories?: Array<{
    __typename?: "Category";
    _id?: string | null;
    id?: string | null;
    name?: string | null;
    rootSlug?: string | null;
    cmsLink?: {
      __typename?: "core_apimessages_Link";
      href?: string | null;
    } | null;
  } | null> | null;
  condition?: {
    __typename?: "core_apimessages_Condition";
    conditionSlug?: string | null;
  } | null;
  pricing?: {
    __typename?: "core_apimessages_ListingPricing";
    buyerPrice?: {
      __typename?: "core_apimessages_Money";
      amount?: string | null;
      currency?: string | null;
    } | null;
  } | null;
  preorderInfo?: {
    __typename?: "core_apimessages_PreorderInfo";
    onPreorder?: boolean | null;
  } | null;
  csp?: {
    __typename?: "CSP";
    _id?: string | null;
    productReviewSearch?: {
      __typename?: "rql_CSPProductReviewSearchResponse";
      stats?: {
        __typename?: "reverb_search_ProductReviewStats";
        total?: number | null;
        averageRating?: number | null;
      } | null;
    } | null;
  } | null;
  shop?: {
    __typename?: "Shop";
    _id?: string | null;
    name?: string | null;
    onVacation?: boolean | null;
    returnPolicy?: {
      __typename?: "core_apimessages_ReturnPolicy";
      _id?: string | null;
      usedReturnWindowDays?: number | null;
      newReturnWindowDays?: number | null;
    } | null;
  } | null;
  metaImage?: Array<{
    __typename?: "core_apimessages_Image";
    _id?: string | null;
    source?: string | null;
  } | null> | null;
  experiments?: Array<{
    __typename?: "core_apimessages_NonUserExperiment";
    name?: string | null;
    bucket?: string | null;
  } | null> | null;
  shipping?: {
    __typename?: "core_apimessages_ShippingPrices";
    localPickupOnly?: boolean | null;
    shippingPrices?: Array<{
      __typename?: "core_apimessages_ShippingPrice";
      _id?: string | null;
      shippingMethod?: core_apimessages_ShippingMethod | null;
      carrierCalculated?: boolean | null;
      regional?: boolean | null;
      rate?: {
        __typename?: "core_apimessages_Money";
        amount?: string | null;
        currency?: string | null;
      } | null;
    } | null> | null;
  } | null;
};

export type SignalsDataFragment = {
  __typename?: "Listing";
  _id?: string | null;
  id?: string | null;
  state?: string | null;
  usOutlet?: boolean | null;
  currency?: string | null;
  signals?: Array<{
    __typename?: "reverb_signals_Signal";
    name?: reverb_signals_Signal_Name | null;
    group?: reverb_signals_Signal_Group | null;
    title?: string | null;
    subtitle?: string | null;
    icon?: string | null;
    iconDark?: string | null;
    tooltipText?: string | null;
    link?: {
      __typename?: "reverb_signals_SignalLink";
      text?: string | null;
      url?: string | null;
    } | null;
  } | null> | null;
};

export type Item2OrderDetailsDataFragment = {
  __typename?: "Listing";
  _id?: string | null;
  orders?: {
    __typename?: "OrderCollection";
    nodes?: Array<{
      __typename?: "Order";
      _id?: string | null;
      legacyOrderId?: string | null;
      status?: core_apimessages_Order_Status | null;
      paidAt?: {
        __typename?: "google_protobuf_Timestamp";
        seconds?: number | null;
      } | null;
      orderUrl?: {
        __typename?: "core_apimessages_Link";
        href?: string | null;
      } | null;
      createdAt?: {
        __typename?: "google_protobuf_Timestamp";
        seconds?: number | null;
      } | null;
    } | null> | null;
  } | null;
};

export type Item2PaidLandingDataFragment = {
  __typename?: "Listing";
  _id?: string | null;
  id?: string | null;
  state?: string | null;
};

export type Item2PricingDataFragment = {
  __typename?: "Listing";
  _id?: string | null;
  id?: string | null;
  offersEnabled?: boolean | null;
  inventory?: number | null;
  hasInventory?: boolean | null;
  maxBuyerQuantity?: number | null;
  make?: string | null;
  model?: string | null;
  state?: string | null;
  sellerId?: string | null;
  listingType?: core_apimessages_Listing_ListingType | null;
  stateDescription?: string | null;
  taxIncluded?: boolean | null;
  taxIncludedHint?: string | null;
  acceptedPaymentMethods?: Array<string | null> | null;
  usOutlet?: boolean | null;
  isBuyerOfferEligible?: boolean | null;
  brandSlug?: string | null;
  title?: string | null;
  categoryRootUuid?: string | null;
  categoryUuids?: Array<string | null> | null;
  bumpEligible?: boolean | null;
  shopId?: string | null;
  soldAsIs?: boolean | null;
  currency?: string | null;
  adyenCheckoutPaymentMethodsConfig?: string | null;
  condition?: {
    __typename?: "core_apimessages_Condition";
    conditionSlug?: string | null;
    conditionUuid?: string | null;
  } | null;
  shop?: {
    __typename?: "Shop";
    _id?: string | null;
    id?: string | null;
    address?: {
      __typename?: "core_apimessages_Address";
      _id?: string | null;
      countryCode?: string | null;
      country?: {
        __typename?: "core_apimessages_Country";
        _id?: string | null;
        name?: string | null;
      } | null;
    } | null;
    freeDomesticShippingRate?: {
      __typename?: "core_apimessages_ShippingRates";
      regionCode?: string | null;
      freeShippingThreshold?: {
        __typename?: "core_apimessages_Money";
        amountCents?: number | null;
        currency?: string | null;
      } | null;
    } | null;
    returnPolicy?: {
      __typename?: "core_apimessages_ReturnPolicy";
      _id?: string | null;
      newReturnWindowDays?: number | null;
      usedReturnWindowDays?: number | null;
    } | null;
  } | null;
  pricing?: {
    __typename?: "core_apimessages_ListingPricing";
    originalPriceDescription?: string | null;
    buyerPrice?: {
      __typename?: "core_apimessages_Money";
      amount?: string | null;
      amountCents?: number | null;
      currency?: string | null;
      display?: string | null;
    } | null;
    originalPrice?: {
      __typename?: "core_apimessages_Money";
      display?: string | null;
    } | null;
    ribbon?: {
      __typename?: "core_apimessages_Ribbon";
      display?: string | null;
      reason?: core_apimessages_Ribbon_Reason | null;
    } | null;
    typicalNewPriceDisplay?: {
      __typename?: "core_apimessages_TypicalNewPriceDisplay";
      amountDisplay?: string | null;
    } | null;
  } | null;
  preorderInfo?: {
    __typename?: "core_apimessages_PreorderInfo";
    onPreorder?: boolean | null;
    estimatedShipDate?: {
      __typename?: "google_protobuf_Timestamp";
      seconds?: number | null;
    } | null;
  } | null;
  csp?: {
    __typename?: "CSP";
    _id?: string | null;
    id?: string | null;
    slug?: string | null;
    webLink?: {
      __typename?: "core_apimessages_Link";
      href?: string | null;
    } | null;
    brand?: {
      __typename?: "Brand";
      _id?: string | null;
      slug?: string | null;
    } | null;
  } | null;
  publishedAt?: {
    __typename?: "google_protobuf_Timestamp";
    seconds?: number | null;
  } | null;
  counts?: {
    __typename?: "core_apimessages_ListingCounts";
    id?: string | null;
    viewsDisplay?: string | null;
    offersDisplay?: string | null;
    watchersDisplay?: string | null;
    downloadCountDisplay?: string | null;
  } | null;
  shipping?: {
    __typename?: "core_apimessages_ShippingPrices";
    localPickupOnly?: boolean | null;
    freeExpeditedShipping?: boolean | null;
    localPickup?: boolean | null;
    shippingPrices?: Array<{
      __typename?: "core_apimessages_ShippingPrice";
      _id?: string | null;
      shippingMethod?: core_apimessages_ShippingMethod | null;
      carrierCalculated?: boolean | null;
      destinationPostalCodeNeeded?: boolean | null;
      regional?: boolean | null;
      postalCode?: string | null;
      rate?: {
        __typename?: "core_apimessages_Money";
        amountCents?: number | null;
        amount?: string | null;
        currency?: string | null;
        display?: string | null;
      } | null;
    } | null> | null;
  } | null;
  digitalDetails?: {
    __typename?: "core_apimessages_ListingDigitalDetails";
    variants?: Array<{
      __typename?: "core_apimessages_ListingDigitalVariant";
      id?: string | null;
      downloadText?: string | null;
      fileUrl?: string | null;
      fileSize?: string | null;
      operatingSystems?: Array<string | null> | null;
      fileTypes?: Array<string | null> | null;
    } | null> | null;
    supportedFormats?: Array<{
      __typename?: "core_apimessages_ListingSupportedFormat";
      operatingSystem?: string | null;
      fileTypes?: Array<string | null> | null;
    } | null> | null;
  } | null;
  estimatedMonthlyPaymentPlan?: {
    __typename?: "core_apimessages_EstimatedMonthlyPayment";
    financingProgramSlug?: string | null;
  } | null;
  certifiedPreOwned?: {
    __typename?: "core_apimessages_ListingCertifiedPreOwned";
    title?: string | null;
    description?: string | null;
    tooltipDescription?: string | null;
    badgeIconUrl?: string | null;
    brandName?: string | null;
    pageUrl?: string | null;
    lightModeBrandIconUrl?: string | null;
    darkModeBrandIconUrl?: string | null;
  } | null;
  categories?: Array<{
    __typename?: "Category";
    _id?: string | null;
    slug?: string | null;
    rootSlug?: string | null;
  } | null> | null;
  sale?: {
    __typename?: "core_apimessages_Sale";
    _id?: string | null;
    id?: string | null;
    code?: string | null;
    buyerIneligibilityReason?: string | null;
  } | null;
  expressPay?: {
    __typename?: "core_apimessages_ListingExpressPay";
    _id?: string | null;
    checkoutBundlingId?: string | null;
    paypal?: {
      __typename?: "core_apimessages_CheckoutPaypalDetails";
      currency?: string | null;
    } | null;
    estimatedTotal?: {
      __typename?: "core_apimessages_Money";
      amount?: string | null;
      currency?: string | null;
    } | null;
    googlePay?: {
      __typename?: "core_apimessages_CheckoutGooglePayDetails";
      merchantId?: string | null;
      merchantName?: string | null;
      gatewayMerchantId?: string | null;
      cardNetworks?: Array<core_apimessages_CheckoutGooglePayDetails_CardNetwork | null> | null;
    } | null;
  } | null;
};

export type Item2PricingNegotiationsDataFragment = {
  __typename?: "Negotiation";
  _id?: string | null;
  id?: string | null;
  lastOffer?: {
    __typename?: "core_apimessages_Offer";
    status?: core_apimessages_Offer_OfferStatus | null;
  } | null;
};

export type Core_ListingProtectionPlanOptionsQueryVariables = Exact<{
  listingId?: InputMaybe<Scalars["String"]["input"]>;
}>;

export type Core_ListingProtectionPlanOptionsQuery = {
  __typename?: "Query";
  listing?: {
    __typename?: "Listing";
    _id?: string | null;
    protectionPlanOptions?: Array<{
      __typename?: "core_apimessages_ProtectionPlanOptions";
      availablePlans?: Array<{
        __typename?: "core_apimessages_ProtectionPlan";
        planId?: string | null;
        durationMonths?: string | null;
        title?: string | null;
        termsUrl?: string | null;
        amount?: {
          __typename?: "core_apimessages_Money";
          amount?: string | null;
          display?: string | null;
        } | null;
      } | null> | null;
    } | null> | null;
  } | null;
};

export type Item2ProductModuleDataFragment = {
  __typename?: "Listing";
  _id?: string | null;
  id?: string | null;
  canonicalProductId?: string | null;
  condition?: {
    __typename?: "core_apimessages_Condition";
    conditionSlug?: string | null;
  } | null;
  categories?: Array<{
    __typename?: "Category";
    _id?: string | null;
    id?: string | null;
    slug?: string | null;
    name?: string | null;
    rootSlug?: string | null;
    webLink?: {
      __typename?: "core_apimessages_Link";
      href?: string | null;
    } | null;
    cmsLink?: {
      __typename?: "core_apimessages_Link";
      href?: string | null;
    } | null;
  } | null> | null;
  csp?: {
    __typename?: "CSP";
    _id?: string | null;
    id?: string | null;
    title?: string | null;
    slug?: string | null;
    webLink?: {
      __typename?: "core_apimessages_Link";
      path?: string | null;
    } | null;
    image?: {
      __typename?: "core_apimessages_Image";
      _id?: string | null;
      source?: string | null;
    } | null;
    inventory?: {
      __typename?: "core_apimessages_CSPInventory";
      usedTotal?: number | null;
      newTotal?: number | null;
      newLowPrice?: {
        __typename?: "core_apimessages_Money";
        display?: string | null;
        amountCents?: number | null;
      } | null;
      usedLowPrice?: {
        __typename?: "core_apimessages_Money";
        display?: string | null;
        amountCents?: number | null;
      } | null;
    } | null;
    specs?: {
      __typename?: "core_apimessages_CSPSpec";
      productSpecs?: Array<{
        __typename?: "core_apimessages_ProductSpec";
        _id?: string | null;
        type?: core_apimessages_ProductSpec_Type | null;
        name?: string | null;
        value?: string | null;
        path?: string | null;
      } | null> | null;
    } | null;
  } | null;
};

export type Core_Listing_Item2ProductModuleListingRowQueryVariables = Exact<{
  canonicalProductIds?: InputMaybe<
    | Array<InputMaybe<Scalars["String"]["input"]>>
    | InputMaybe<Scalars["String"]["input"]>
  >;
  conditionSlugs?: InputMaybe<
    | Array<InputMaybe<Scalars["String"]["input"]>>
    | InputMaybe<Scalars["String"]["input"]>
  >;
  sort?: InputMaybe<reverb_search_ListingsSearchRequest_Sort>;
}>;

export type Core_Listing_Item2ProductModuleListingRowQuery = {
  __typename?: "Query";
  listingsSearch?: {
    __typename?: "reverb_search_SearchResponse";
    listings?: Array<{
      __typename?: "Listing";
      _id?: string | null;
      id?: string | null;
      usOutlet?: boolean | null;
      bumped?: boolean | null;
      categoryUuids?: Array<string | null> | null;
      slug?: string | null;
      title?: string | null;
      description?: string | null;
      listingType?: core_apimessages_Listing_ListingType | null;
      watching?: boolean | null;
      state?: string | null;
      stateDescription?: string | null;
      sellerId?: string | null;
      currency?: string | null;
      offersEnabled?: boolean | null;
      isBuyerOfferEligible?: boolean | null;
      brandSlug?: string | null;
      categoryRootUuid?: string | null;
      make?: string | null;
      bumpEligible?: boolean | null;
      shopId?: string | null;
      inventory?: number | null;
      soldAsIs?: boolean | null;
      acceptedPaymentMethods?: Array<string | null> | null;
      condition?: {
        __typename?: "core_apimessages_Condition";
        displayName?: string | null;
        conditionSlug?: string | null;
        conditionUuid?: string | null;
      } | null;
      price?: {
        __typename?: "core_apimessages_Money";
        amountCents?: number | null;
        display?: string | null;
      } | null;
      pricing?: {
        __typename?: "core_apimessages_ListingPricing";
        buyerPrice?: {
          __typename?: "core_apimessages_Money";
          display?: string | null;
          currency?: string | null;
          amountCents?: number | null;
          amount?: string | null;
        } | null;
        originalPrice?: {
          __typename?: "core_apimessages_Money";
          display?: string | null;
        } | null;
        ribbon?: {
          __typename?: "core_apimessages_Ribbon";
          display?: string | null;
          reason?: core_apimessages_Ribbon_Reason | null;
        } | null;
      } | null;
      shipping?: {
        __typename?: "core_apimessages_ShippingPrices";
        freeExpeditedShipping?: boolean | null;
        localPickupOnly?: boolean | null;
        localPickup?: boolean | null;
        shippingPrices?: Array<{
          __typename?: "core_apimessages_ShippingPrice";
          _id?: string | null;
          shippingMethod?: core_apimessages_ShippingMethod | null;
          carrierCalculated?: boolean | null;
          destinationPostalCodeNeeded?: boolean | null;
          rate?: {
            __typename?: "core_apimessages_Money";
            amount?: string | null;
            amountCents?: number | null;
            currency?: string | null;
            display?: string | null;
          } | null;
        } | null> | null;
      } | null;
      shop?: {
        __typename?: "Shop";
        _id?: string | null;
        address?: {
          __typename?: "core_apimessages_Address";
          _id?: string | null;
          countryCode?: string | null;
          country?: {
            __typename?: "core_apimessages_Country";
            _id?: string | null;
            countryCode?: string | null;
            name?: string | null;
          } | null;
        } | null;
        returnPolicy?: {
          __typename?: "core_apimessages_ReturnPolicy";
          _id?: string | null;
          newReturnWindowDays?: number | null;
          usedReturnWindowDays?: number | null;
        } | null;
      } | null;
      preorderInfo?: {
        __typename?: "core_apimessages_PreorderInfo";
        onPreorder?: boolean | null;
        estimatedShipDate?: {
          __typename?: "google_protobuf_Timestamp";
          seconds?: number | null;
        } | null;
      } | null;
      watchThumbnails?: Array<{
        __typename?: "core_apimessages_Image";
        _id?: string | null;
        source?: string | null;
      } | null> | null;
      priceRecommendation?: {
        __typename?: "reverb_pricing_PriceRecommendation";
        priceMiddle?: {
          __typename?: "reverb_pricing_Money";
          amountCents?: number | null;
          currency?: string | null;
        } | null;
      } | null;
      images?: Array<{
        __typename?: "core_apimessages_Image";
        _id?: string | null;
        source?: string | null;
      } | null> | null;
      largeSquareImages?: Array<{
        __typename?: "core_apimessages_Image";
        _id?: string | null;
        source?: string | null;
      } | null> | null;
      certifiedPreOwned?: {
        __typename?: "core_apimessages_ListingCertifiedPreOwned";
        title?: string | null;
        badgeIconUrl?: string | null;
      } | null;
      categories?: Array<{
        __typename?: "Category";
        _id?: string | null;
        slug?: string | null;
        rootSlug?: string | null;
      } | null> | null;
      csp?: {
        __typename?: "CSP";
        _id?: string | null;
        id?: string | null;
        slug?: string | null;
        brand?: {
          __typename?: "Brand";
          _id?: string | null;
          slug?: string | null;
        } | null;
      } | null;
      publishedAt?: {
        __typename?: "google_protobuf_Timestamp";
        seconds?: number | null;
      } | null;
      sale?: {
        __typename?: "core_apimessages_Sale";
        _id?: string | null;
        id?: string | null;
        code?: string | null;
        buyerIneligibilityReason?: string | null;
      } | null;
    } | null> | null;
  } | null;
};

export type Item2ProductSpecsDataFragment = {
  __typename?: "Listing";
  _id?: string | null;
  hasInventory?: boolean | null;
  publishedAt?: {
    __typename?: "google_protobuf_Timestamp";
    seconds?: number | null;
  } | null;
  condition?: {
    __typename?: "core_apimessages_Condition";
    conditionUuid?: string | null;
  } | null;
  shop?: {
    __typename?: "Shop";
    _id?: string | null;
    slug?: string | null;
  } | null;
  specs?: {
    __typename?: "core_apimessages_ListingSpec";
    productSpecs?: Array<{
      __typename?: "core_apimessages_ProductSpec";
      _id?: string | null;
      name?: string | null;
      value?: string | null;
      path?: string | null;
      slug?: string | null;
      type?: core_apimessages_ProductSpec_Type | null;
    } | null> | null;
  } | null;
};

export type Item2Prop65WarningDataFragment = {
  __typename?: "Listing";
  _id?: string | null;
  sellerId?: string | null;
  propSixtyFiveWarning?: string | null;
  propSixtyFiveWarningImg?: {
    __typename?: "core_apimessages_Link";
    href?: string | null;
  } | null;
};

export type Item2ReturnPolicyDataFragment = {
  __typename?: "Listing";
  _id?: string | null;
  soldAsIs?: boolean | null;
  condition?: {
    __typename?: "core_apimessages_Condition";
    conditionSlug?: string | null;
  } | null;
  shop?: {
    __typename?: "Shop";
    _id?: string | null;
    returnPolicy?: {
      __typename?: "core_apimessages_ReturnPolicy";
      newReturnWindowDays?: number | null;
      usedReturnWindowDays?: number | null;
    } | null;
  } | null;
};

export type Core_Listing_ShippingBoxImageQueryVariables = Exact<{
  listingId?: InputMaybe<Scalars["String"]["input"]>;
}>;

export type Core_Listing_ShippingBoxImageQuery = {
  __typename?: "Query";
  listing?: {
    __typename?: "Listing";
    _id?: string | null;
    images?: Array<{
      __typename?: "core_apimessages_Image";
      _id?: string | null;
      source?: string | null;
    } | null> | null;
  } | null;
};

export type ShippingWarningDataFragment = {
  __typename?: "Listing";
  _id?: string | null;
  id?: string | null;
  slug?: string | null;
  state?: string | null;
  shipping?: {
    __typename?: "core_apimessages_ShippingPrices";
    localPickupOnly?: boolean | null;
    shippingPrices?: Array<{
      __typename?: "core_apimessages_ShippingPrice";
      _id?: string | null;
      shippingMethod?: core_apimessages_ShippingMethod | null;
      carrierCalculated?: boolean | null;
      destinationPostalCodeNeeded?: boolean | null;
      rate?: {
        __typename?: "core_apimessages_Money";
        amount?: string | null;
        amountCents?: number | null;
        currency?: string | null;
        display?: string | null;
      } | null;
    } | null> | null;
  } | null;
  shop?: {
    __typename?: "Shop";
    _id?: string | null;
    userUuid?: string | null;
  } | null;
};

export type Item2ShopModuleDataFragment = {
  __typename?: "Listing";
  _id?: string | null;
  id?: string | null;
  sellerUuid?: string | null;
  seller?: {
    __typename?: "User";
    _id?: string | null;
    id?: string | null;
    uuid?: string | null;
    avatar?: {
      __typename?: "core_apimessages_Image";
      _id?: string | null;
      source?: string | null;
    } | null;
    feedbackSummary?: {
      __typename?: "core_apimessages_FeedbackSummary";
      receivedCount?: number | null;
      rollingRatingPercentage?: number | null;
    } | null;
  } | null;
  shop?: {
    __typename?: "Shop";
    _id?: string | null;
    name?: string | null;
    slug?: string | null;
    preferredSeller?: boolean | null;
    quickShipper?: boolean | null;
    quickResponder?: boolean | null;
    createdAt?: any | null;
    id?: string | null;
    address?: {
      __typename?: "core_apimessages_Address";
      _id?: string | null;
      displayLocation?: string | null;
      countryCode?: string | null;
    } | null;
    orders?: {
      __typename?: "reverb_search_SearchResponse";
      total?: number | null;
    } | null;
  } | null;
};

export type Item2ShopOverviewDataFragment = {
  __typename?: "Listing";
  _id?: string | null;
  seller?: {
    __typename?: "User";
    _id?: string | null;
    feedbackSummary?: {
      __typename?: "core_apimessages_FeedbackSummary";
      rollingRatingPercentage?: number | null;
      receivedCount?: number | null;
    } | null;
    avatar?: {
      __typename?: "core_apimessages_Image";
      _id?: string | null;
      source?: string | null;
    } | null;
  } | null;
  shop?: {
    __typename?: "Shop";
    _id?: string | null;
    name?: string | null;
    slug?: string | null;
    address?: {
      __typename?: "core_apimessages_Address";
      _id?: string | null;
      displayLocation?: string | null;
      countryCode?: string | null;
    } | null;
  } | null;
};

export type ShopPoliciesDataFragment = {
  __typename?: "Listing";
  _id?: string | null;
  id?: string | null;
  acceptedPaymentMethods?: Array<string | null> | null;
  listingType?: core_apimessages_Listing_ListingType | null;
  soldAsIs?: boolean | null;
  shipping?: {
    __typename?: "core_apimessages_ShippingPrices";
    localPickup?: boolean | null;
    shippingPrices?: Array<{
      __typename?: "core_apimessages_ShippingPrice";
      _id?: string | null;
      shippingMethod?: core_apimessages_ShippingMethod | null;
      rate?: {
        __typename?: "core_apimessages_Money";
        display?: string | null;
      } | null;
    } | null> | null;
  } | null;
  shop?: {
    __typename?: "Shop";
    _id?: string | null;
    shippingPolicy?: string | null;
    userUuid?: string | null;
    address?: {
      __typename?: "core_apimessages_Address";
      _id?: string | null;
      displayLocation?: string | null;
    } | null;
    returnPolicy?: {
      __typename?: "core_apimessages_ReturnPolicy";
      newReturnWindowDays?: number | null;
      usedReturnWindowDays?: number | null;
      restockingFeePercent?: number | null;
      specialConditions?: string | null;
      legacyReturnPolicyText?: string | null;
    } | null;
  } | null;
  allShippingPrices?: {
    __typename?: "core_apimessages_ShippingPrices";
    shippingPrices?: Array<{
      __typename?: "core_apimessages_ShippingPrice";
      _id?: string | null;
      shippingMethod?: core_apimessages_ShippingMethod | null;
      shippingRegionCode?: string | null;
      rate?: {
        __typename?: "core_apimessages_Money";
        display?: string | null;
      } | null;
    } | null> | null;
  } | null;
  condition?: {
    __typename?: "core_apimessages_Condition";
    conditionSlug?: string | null;
  } | null;
  estimatedMonthlyPaymentPlan?: {
    __typename?: "core_apimessages_EstimatedMonthlyPayment";
    maxTermMonth?: number | null;
    estimatedMonthlyPayment?: {
      __typename?: "core_apimessages_Money";
      display?: string | null;
    } | null;
  } | null;
  taxPolicies?: Array<{
    __typename?: "core_apimessages_ShopTaxPolicy";
    displayRate?: string | null;
    displayLocation?: string | null;
    policyType?: core_apimessages_ShopTaxPolicy_PolicyType | null;
  } | null> | null;
};

export type Item2_Shop_PoliciesQueryVariables = Exact<{
  listingId?: InputMaybe<Scalars["String"]["input"]>;
  euUser: Scalars["Boolean"]["input"];
}>;

export type Item2_Shop_PoliciesQuery = {
  __typename?: "Query";
  listing?: {
    __typename?: "Listing";
    _id?: string | null;
    id?: string | null;
    acceptedPaymentMethods?: Array<string | null> | null;
    listingType?: core_apimessages_Listing_ListingType | null;
    soldAsIs?: boolean | null;
    shop?: {
      __typename?: "Shop";
      _id?: string | null;
      uuid?: string | null;
      shippingPolicy?: string | null;
      userUuid?: string | null;
      vatId?: string | null;
      address?: {
        __typename?: "core_apimessages_Address";
        _id?: string | null;
        displayLocation?: string | null;
      } | null;
      returnPolicy?: {
        __typename?: "core_apimessages_ReturnPolicy";
        newReturnWindowDays?: number | null;
        usedReturnWindowDays?: number | null;
        restockingFeePercent?: number | null;
        specialConditions?: string | null;
        legacyReturnPolicyText?: string | null;
      } | null;
      euOdrAddress?: {
        __typename?: "core_apimessages_Address";
        _id?: string | null;
        name?: string | null;
        streetAddress?: string | null;
        displayLocation?: string | null;
        postalCode?: string | null;
        email?: string | null;
        phone?: string | null;
      } | null;
    } | null;
    shipping?: {
      __typename?: "core_apimessages_ShippingPrices";
      localPickup?: boolean | null;
      shippingPrices?: Array<{
        __typename?: "core_apimessages_ShippingPrice";
        _id?: string | null;
        shippingMethod?: core_apimessages_ShippingMethod | null;
        rate?: {
          __typename?: "core_apimessages_Money";
          display?: string | null;
        } | null;
      } | null> | null;
    } | null;
    allShippingPrices?: {
      __typename?: "core_apimessages_ShippingPrices";
      shippingPrices?: Array<{
        __typename?: "core_apimessages_ShippingPrice";
        _id?: string | null;
        shippingMethod?: core_apimessages_ShippingMethod | null;
        shippingRegionCode?: string | null;
        rate?: {
          __typename?: "core_apimessages_Money";
          display?: string | null;
        } | null;
      } | null> | null;
    } | null;
    condition?: {
      __typename?: "core_apimessages_Condition";
      conditionSlug?: string | null;
    } | null;
    estimatedMonthlyPaymentPlan?: {
      __typename?: "core_apimessages_EstimatedMonthlyPayment";
      maxTermMonth?: number | null;
      estimatedMonthlyPayment?: {
        __typename?: "core_apimessages_Money";
        display?: string | null;
      } | null;
    } | null;
    taxPolicies?: Array<{
      __typename?: "core_apimessages_ShopTaxPolicy";
      displayRate?: string | null;
      displayLocation?: string | null;
      policyType?: core_apimessages_ShopTaxPolicy_PolicyType | null;
    } | null> | null;
  } | null;
};

export type Item2ShopReviewsDataFragment = {
  __typename?: "Listing";
  _id?: string | null;
  sellerUuid?: string | null;
  shop?: {
    __typename?: "Shop";
    _id?: string | null;
    id?: string | null;
  } | null;
  seller?: {
    __typename?: "User";
    _id?: string | null;
    feedbackSummary?: {
      __typename?: "core_apimessages_FeedbackSummary";
      receivedCount?: number | null;
      rollingRatingPercentage?: number | null;
    } | null;
  } | null;
};

export type Item2ShopTaxPoliciesDataFragment = {
  __typename?: "Listing";
  _id?: string | null;
  taxPolicies?: Array<{
    __typename?: "core_apimessages_ShopTaxPolicy";
    displayRate?: string | null;
    displayLocation?: string | null;
    policyType?: core_apimessages_ShopTaxPolicy_PolicyType | null;
  } | null> | null;
};

export type Item2StatsDataFragment = {
  __typename?: "Listing";
  _id?: string | null;
  id?: string | null;
  hasInventory?: boolean | null;
  listingType?: core_apimessages_Listing_ListingType | null;
  offersEnabled?: boolean | null;
  condition?: {
    __typename?: "core_apimessages_Condition";
    conditionUuid?: string | null;
  } | null;
  publishedAt?: {
    __typename?: "google_protobuf_Timestamp";
    seconds?: number | null;
  } | null;
  counts?: {
    __typename?: "core_apimessages_ListingCounts";
    id?: string | null;
    viewsDisplay?: string | null;
    offersDisplay?: string | null;
    watchersDisplay?: string | null;
    downloadCountDisplay?: string | null;
  } | null;
};

export type Item2TitleDataFragment = {
  __typename?: "Listing";
  _id?: string | null;
  id?: string | null;
  title?: string | null;
  listingType?: core_apimessages_Listing_ListingType | null;
  condition?: {
    __typename?: "core_apimessages_Condition";
    conditionSlug?: string | null;
    displayName?: string | null;
  } | null;
  categories?: Array<{
    __typename?: "Category";
    _id?: string | null;
    id?: string | null;
    slug?: string | null;
    name?: string | null;
    rootSlug?: string | null;
  } | null> | null;
  csp?: { __typename?: "CSP"; _id?: string | null; id?: string | null } | null;
};

export type Item2VideoFragment = {
  __typename?: "Listing";
  _id?: string | null;
  video?: {
    __typename?: "core_apimessages_Video";
    id?: string | null;
    youtubeVideoId?: string | null;
    empty?: boolean | null;
  } | null;
  csp?: {
    __typename?: "CSP";
    _id?: string | null;
    videos?: {
      __typename?: "core_apimessages_CSPVideo";
      videoLinks?: Array<{
        __typename?: "core_apimessages_VideoLink";
        youtubeVideoId?: string | null;
      } | null> | null;
    } | null;
  } | null;
};

export type ItemPageEndedListingExploreRowFieldsFragment = {
  __typename?: "Listing";
  _id?: string | null;
  id?: string | null;
  csp?: { __typename?: "CSP"; _id?: string | null; id?: string | null } | null;
};

export type ItemPageEndedListingExploreRowSearchFieldsFragment = {
  __typename?: "Listing";
  _id?: string | null;
  id?: string | null;
  usOutlet?: boolean | null;
  bumped?: boolean | null;
  categoryUuids?: Array<string | null> | null;
  slug?: string | null;
  title?: string | null;
  description?: string | null;
  listingType?: core_apimessages_Listing_ListingType | null;
  watching?: boolean | null;
  state?: string | null;
  stateDescription?: string | null;
  brandSlug?: string | null;
  categoryRootUuid?: string | null;
  make?: string | null;
  bumpEligible?: boolean | null;
  shopId?: string | null;
  inventory?: number | null;
  soldAsIs?: boolean | null;
  acceptedPaymentMethods?: Array<string | null> | null;
  currency?: string | null;
  sellerId?: string | null;
  offersEnabled?: boolean | null;
  isBuyerOfferEligible?: boolean | null;
  condition?: {
    __typename?: "core_apimessages_Condition";
    displayName?: string | null;
    conditionSlug?: string | null;
    conditionUuid?: string | null;
  } | null;
  price?: {
    __typename?: "core_apimessages_Money";
    amountCents?: number | null;
    display?: string | null;
  } | null;
  pricing?: {
    __typename?: "core_apimessages_ListingPricing";
    buyerPrice?: {
      __typename?: "core_apimessages_Money";
      display?: string | null;
      currency?: string | null;
      amountCents?: number | null;
      amount?: string | null;
    } | null;
    originalPrice?: {
      __typename?: "core_apimessages_Money";
      display?: string | null;
    } | null;
    ribbon?: {
      __typename?: "core_apimessages_Ribbon";
      display?: string | null;
      reason?: core_apimessages_Ribbon_Reason | null;
    } | null;
  } | null;
  shipping?: {
    __typename?: "core_apimessages_ShippingPrices";
    freeExpeditedShipping?: boolean | null;
    localPickupOnly?: boolean | null;
    localPickup?: boolean | null;
    shippingPrices?: Array<{
      __typename?: "core_apimessages_ShippingPrice";
      _id?: string | null;
      shippingMethod?: core_apimessages_ShippingMethod | null;
      carrierCalculated?: boolean | null;
      destinationPostalCodeNeeded?: boolean | null;
      rate?: {
        __typename?: "core_apimessages_Money";
        amount?: string | null;
        amountCents?: number | null;
        currency?: string | null;
        display?: string | null;
      } | null;
    } | null> | null;
  } | null;
  shop?: {
    __typename?: "Shop";
    _id?: string | null;
    address?: {
      __typename?: "core_apimessages_Address";
      _id?: string | null;
      countryCode?: string | null;
      country?: {
        __typename?: "core_apimessages_Country";
        _id?: string | null;
        countryCode?: string | null;
        name?: string | null;
      } | null;
    } | null;
    returnPolicy?: {
      __typename?: "core_apimessages_ReturnPolicy";
      _id?: string | null;
      newReturnWindowDays?: number | null;
      usedReturnWindowDays?: number | null;
    } | null;
  } | null;
  categories?: Array<{
    __typename?: "Category";
    _id?: string | null;
    slug?: string | null;
    rootSlug?: string | null;
  } | null> | null;
  csp?: {
    __typename?: "CSP";
    _id?: string | null;
    id?: string | null;
    slug?: string | null;
    brand?: {
      __typename?: "Brand";
      _id?: string | null;
      slug?: string | null;
    } | null;
  } | null;
  publishedAt?: {
    __typename?: "google_protobuf_Timestamp";
    seconds?: number | null;
  } | null;
  sale?: {
    __typename?: "core_apimessages_Sale";
    _id?: string | null;
    id?: string | null;
    code?: string | null;
    buyerIneligibilityReason?: string | null;
  } | null;
  certifiedPreOwned?: {
    __typename?: "core_apimessages_ListingCertifiedPreOwned";
    title?: string | null;
    badgeIconUrl?: string | null;
  } | null;
  bumpKey?: {
    __typename?: "core_apimessages_BumpKey";
    key?: string | null;
  } | null;
  preorderInfo?: {
    __typename?: "core_apimessages_PreorderInfo";
    onPreorder?: boolean | null;
    estimatedShipDate?: {
      __typename?: "google_protobuf_Timestamp";
      seconds?: number | null;
    } | null;
  } | null;
  watchThumbnails?: Array<{
    __typename?: "core_apimessages_Image";
    _id?: string | null;
    source?: string | null;
  } | null> | null;
  priceRecommendation?: {
    __typename?: "reverb_pricing_PriceRecommendation";
    priceMiddle?: {
      __typename?: "reverb_pricing_Money";
      amountCents?: number | null;
      currency?: string | null;
    } | null;
  } | null;
  images?: Array<{
    __typename?: "core_apimessages_Image";
    _id?: string | null;
    source?: string | null;
  } | null> | null;
  largeSquareImages?: Array<{
    __typename?: "core_apimessages_Image";
    _id?: string | null;
    source?: string | null;
  } | null> | null;
};

export type Core_Listing_ExploreRowQueryVariables = Exact<{
  cspId?: InputMaybe<Scalars["String"]["input"]>;
  itemRegion?: InputMaybe<Scalars["String"]["input"]>;
  itemRegionRelation?: InputMaybe<reverb_search_ListingItemRegionRelation>;
  shippingRegionCodes?: InputMaybe<
    | Array<InputMaybe<Scalars["String"]["input"]>>
    | InputMaybe<Scalars["String"]["input"]>
  >;
  similarListingIds?: InputMaybe<
    | Array<InputMaybe<Scalars["String"]["input"]>>
    | InputMaybe<Scalars["String"]["input"]>
  >;
  sort?: InputMaybe<reverb_search_ListingsSearchRequest_Sort>;
}>;

export type Core_Listing_ExploreRowQuery = {
  __typename?: "Query";
  listingsSearch?: {
    __typename?: "reverb_search_SearchResponse";
    listings?: Array<{
      __typename?: "Listing";
      _id?: string | null;
      id?: string | null;
      usOutlet?: boolean | null;
      bumped?: boolean | null;
      categoryUuids?: Array<string | null> | null;
      slug?: string | null;
      title?: string | null;
      description?: string | null;
      listingType?: core_apimessages_Listing_ListingType | null;
      watching?: boolean | null;
      state?: string | null;
      stateDescription?: string | null;
      brandSlug?: string | null;
      categoryRootUuid?: string | null;
      make?: string | null;
      bumpEligible?: boolean | null;
      shopId?: string | null;
      inventory?: number | null;
      soldAsIs?: boolean | null;
      acceptedPaymentMethods?: Array<string | null> | null;
      currency?: string | null;
      sellerId?: string | null;
      offersEnabled?: boolean | null;
      isBuyerOfferEligible?: boolean | null;
      condition?: {
        __typename?: "core_apimessages_Condition";
        displayName?: string | null;
        conditionSlug?: string | null;
        conditionUuid?: string | null;
      } | null;
      price?: {
        __typename?: "core_apimessages_Money";
        amountCents?: number | null;
        display?: string | null;
      } | null;
      pricing?: {
        __typename?: "core_apimessages_ListingPricing";
        buyerPrice?: {
          __typename?: "core_apimessages_Money";
          display?: string | null;
          currency?: string | null;
          amountCents?: number | null;
          amount?: string | null;
        } | null;
        originalPrice?: {
          __typename?: "core_apimessages_Money";
          display?: string | null;
        } | null;
        ribbon?: {
          __typename?: "core_apimessages_Ribbon";
          display?: string | null;
          reason?: core_apimessages_Ribbon_Reason | null;
        } | null;
      } | null;
      shipping?: {
        __typename?: "core_apimessages_ShippingPrices";
        freeExpeditedShipping?: boolean | null;
        localPickupOnly?: boolean | null;
        localPickup?: boolean | null;
        shippingPrices?: Array<{
          __typename?: "core_apimessages_ShippingPrice";
          _id?: string | null;
          shippingMethod?: core_apimessages_ShippingMethod | null;
          carrierCalculated?: boolean | null;
          destinationPostalCodeNeeded?: boolean | null;
          rate?: {
            __typename?: "core_apimessages_Money";
            amount?: string | null;
            amountCents?: number | null;
            currency?: string | null;
            display?: string | null;
          } | null;
        } | null> | null;
      } | null;
      shop?: {
        __typename?: "Shop";
        _id?: string | null;
        address?: {
          __typename?: "core_apimessages_Address";
          _id?: string | null;
          countryCode?: string | null;
          country?: {
            __typename?: "core_apimessages_Country";
            _id?: string | null;
            countryCode?: string | null;
            name?: string | null;
          } | null;
        } | null;
        returnPolicy?: {
          __typename?: "core_apimessages_ReturnPolicy";
          _id?: string | null;
          newReturnWindowDays?: number | null;
          usedReturnWindowDays?: number | null;
        } | null;
      } | null;
      categories?: Array<{
        __typename?: "Category";
        _id?: string | null;
        slug?: string | null;
        rootSlug?: string | null;
      } | null> | null;
      csp?: {
        __typename?: "CSP";
        _id?: string | null;
        id?: string | null;
        slug?: string | null;
        brand?: {
          __typename?: "Brand";
          _id?: string | null;
          slug?: string | null;
        } | null;
      } | null;
      publishedAt?: {
        __typename?: "google_protobuf_Timestamp";
        seconds?: number | null;
      } | null;
      sale?: {
        __typename?: "core_apimessages_Sale";
        _id?: string | null;
        id?: string | null;
        code?: string | null;
        buyerIneligibilityReason?: string | null;
      } | null;
      certifiedPreOwned?: {
        __typename?: "core_apimessages_ListingCertifiedPreOwned";
        title?: string | null;
        badgeIconUrl?: string | null;
      } | null;
      bumpKey?: {
        __typename?: "core_apimessages_BumpKey";
        key?: string | null;
      } | null;
      preorderInfo?: {
        __typename?: "core_apimessages_PreorderInfo";
        onPreorder?: boolean | null;
        estimatedShipDate?: {
          __typename?: "google_protobuf_Timestamp";
          seconds?: number | null;
        } | null;
      } | null;
      watchThumbnails?: Array<{
        __typename?: "core_apimessages_Image";
        _id?: string | null;
        source?: string | null;
      } | null> | null;
      priceRecommendation?: {
        __typename?: "reverb_pricing_PriceRecommendation";
        priceMiddle?: {
          __typename?: "reverb_pricing_Money";
          amountCents?: number | null;
          currency?: string | null;
        } | null;
      } | null;
      images?: Array<{
        __typename?: "core_apimessages_Image";
        _id?: string | null;
        source?: string | null;
      } | null> | null;
      largeSquareImages?: Array<{
        __typename?: "core_apimessages_Image";
        _id?: string | null;
        source?: string | null;
      } | null> | null;
    } | null> | null;
  } | null;
};

export type ListViewListingsFragment = {
  __typename?: "Listing";
  _id?: string | null;
  id?: string | null;
  categoryUuids?: Array<string | null> | null;
  state?: string | null;
  sellerId?: string | null;
  listingType?: core_apimessages_Listing_ListingType | null;
  offersEnabled?: boolean | null;
  isBuyerOfferEligible?: boolean | null;
  otherBuyersWithListingInCartCounts?: number | null;
  title?: string | null;
  slug?: string | null;
  make?: string | null;
  model?: string | null;
  upc?: string | null;
  stateDescription?: string | null;
  bumped?: boolean | null;
  watching?: boolean | null;
  soldAsIs?: boolean | null;
  usOutlet?: boolean | null;
  brandSlug?: string | null;
  categoryRootUuid?: string | null;
  bumpEligible?: boolean | null;
  shopId?: string | null;
  inventory?: number | null;
  acceptedPaymentMethods?: Array<string | null> | null;
  currency?: string | null;
  shop?: {
    __typename?: "Shop";
    _id?: string | null;
    name?: string | null;
    slug?: string | null;
    preferredSeller?: boolean | null;
    quickShipper?: boolean | null;
    quickResponder?: boolean | null;
    address?: {
      __typename?: "core_apimessages_Address";
      _id?: string | null;
      locality?: string | null;
      region?: string | null;
      displayLocation?: string | null;
      countryCode?: string | null;
      country?: {
        __typename?: "core_apimessages_Country";
        _id?: string | null;
        countryCode?: string | null;
        name?: string | null;
      } | null;
    } | null;
    returnPolicy?: {
      __typename?: "core_apimessages_ReturnPolicy";
      usedReturnWindowDays?: number | null;
      newReturnWindowDays?: number | null;
      _id?: string | null;
    } | null;
  } | null;
  seller?: {
    __typename?: "User";
    _id?: string | null;
    feedbackSummary?: {
      __typename?: "core_apimessages_FeedbackSummary";
      receivedCount?: number | null;
      rollingRatingPercentage?: number | null;
    } | null;
  } | null;
  csp?: {
    __typename?: "CSP";
    _id?: string | null;
    id?: string | null;
    slug?: string | null;
    webLink?: {
      __typename?: "core_apimessages_Link";
      href?: string | null;
    } | null;
    brand?: {
      __typename?: "Brand";
      _id?: string | null;
      slug?: string | null;
    } | null;
  } | null;
  pricing?: {
    __typename?: "core_apimessages_ListingPricing";
    buyerPrice?: {
      __typename?: "core_apimessages_Money";
      amount?: string | null;
      amountCents?: number | null;
      display?: string | null;
      currency?: string | null;
    } | null;
    originalPrice?: {
      __typename?: "core_apimessages_Money";
      display?: string | null;
    } | null;
    ribbon?: {
      __typename?: "core_apimessages_Ribbon";
      display?: string | null;
      reason?: core_apimessages_Ribbon_Reason | null;
    } | null;
  } | null;
  preorderInfo?: {
    __typename?: "core_apimessages_PreorderInfo";
    onPreorder?: boolean | null;
    estimatedShipDate?: {
      __typename?: "google_protobuf_Timestamp";
      seconds?: number | null;
    } | null;
  } | null;
  publishedAt?: {
    __typename?: "google_protobuf_Timestamp";
    seconds?: number | null;
  } | null;
  condition?: {
    __typename?: "core_apimessages_Condition";
    displayName?: string | null;
    conditionSlug?: string | null;
    conditionUuid?: string | null;
  } | null;
  images?: Array<{
    __typename?: "core_apimessages_Image";
    source?: string | null;
  } | null> | null;
  shipping?: {
    __typename?: "core_apimessages_ShippingPrices";
    freeExpeditedShipping?: boolean | null;
    localPickupOnly?: boolean | null;
    localPickup?: boolean | null;
    shippingPrices?: Array<{
      __typename?: "core_apimessages_ShippingPrice";
      _id?: string | null;
      shippingMethod?: core_apimessages_ShippingMethod | null;
      carrierCalculated?: boolean | null;
      destinationPostalCodeNeeded?: boolean | null;
      regional?: boolean | null;
      postalCode?: string | null;
      rate?: {
        __typename?: "core_apimessages_Money";
        amount?: string | null;
        amountCents?: number | null;
        currency?: string | null;
        display?: string | null;
      } | null;
    } | null> | null;
  } | null;
  watchThumbnails?: Array<{
    __typename?: "core_apimessages_Image";
    _id?: string | null;
    source?: string | null;
  } | null> | null;
  categories?: Array<{
    __typename?: "Category";
    _id?: string | null;
    slug?: string | null;
    rootSlug?: string | null;
  } | null> | null;
  sale?: {
    __typename?: "core_apimessages_Sale";
    _id?: string | null;
    id?: string | null;
    code?: string | null;
    buyerIneligibilityReason?: string | null;
  } | null;
  certifiedPreOwned?: {
    __typename?: "core_apimessages_ListingCertifiedPreOwned";
    title?: string | null;
    badgeIconUrl?: string | null;
  } | null;
  priceRecommendation?: {
    __typename?: "reverb_pricing_PriceRecommendation";
    priceMiddle?: {
      __typename?: "reverb_pricing_Money";
      amountCents?: number | null;
      currency?: string | null;
    } | null;
  } | null;
};

export type Core_QuickInventoryEdit_ListingMutationVariables = Exact<{
  input?: InputMaybe<Input_core_apimessages_UpdateListingRequest>;
}>;

export type Core_QuickInventoryEdit_ListingMutation = {
  __typename?: "Mutation";
  crupdateListing?: {
    __typename?: "Listing";
    _id?: string | null;
    state?: string | null;
  } | null;
};

export type Core_AutoOfferModalQueryVariables = Exact<{
  listingId?: InputMaybe<Scalars["String"]["input"]>;
}>;

export type Core_AutoOfferModalQuery = {
  __typename?: "Query";
  listing?: {
    __typename?: "Listing";
    _id?: string | null;
    id?: string | null;
    title?: string | null;
    slug?: string | null;
    taxIncluded?: boolean | null;
    taxIncludedHint?: string | null;
    condition?: {
      __typename?: "core_apimessages_Condition";
      displayName?: string | null;
    } | null;
    images?: Array<{
      __typename?: "core_apimessages_Image";
      source?: string | null;
    } | null> | null;
    price?: {
      __typename?: "core_apimessages_Money";
      amount?: string | null;
      amountCents?: number | null;
      currency?: string | null;
      symbol?: string | null;
      display?: string | null;
    } | null;
    sellerCost?: {
      __typename?: "core_apimessages_Money";
      display?: string | null;
    } | null;
    sellerPrice?: {
      __typename?: "core_apimessages_Money";
      amountCents?: number | null;
      display?: string | null;
      currency?: string | null;
    } | null;
    counts?: {
      __typename?: "core_apimessages_ListingCounts";
      watchers?: number | null;
    } | null;
    autoOffers?: {
      __typename?: "core_apimessages_AutoOffer";
      id?: string | null;
      listingId?: string | null;
      message?: string | null;
      percentage?: number | null;
      price?: {
        __typename?: "core_apimessages_Money";
        amount?: string | null;
        currency?: string | null;
      } | null;
      shippingPriceOverride?: {
        __typename?: "core_apimessages_Money";
        amount?: string | null;
        amountCents?: number | null;
        currency?: string | null;
        symbol?: string | null;
        display?: string | null;
      } | null;
    } | null;
    shipmentPackage?: {
      __typename?: "ShipmentPackage";
      _id?: string | null;
    } | null;
  } | null;
};

export type Core_QuickPriceEdit_ListingQueryVariables = Exact<{
  listingId?: InputMaybe<Scalars["String"]["input"]>;
  shopCurrency?: InputMaybe<Scalars["String"]["input"]>;
  shopCountryCode?: InputMaybe<Scalars["String"]["input"]>;
  skipPriceRecommendation: Scalars["Boolean"]["input"];
}>;

export type Core_QuickPriceEdit_ListingQuery = {
  __typename?: "Query";
  listing?: {
    __typename?: "Listing";
    _id?: string | null;
    id?: string | null;
    state?: string | null;
    sku?: string | null;
    year?: string | null;
    make?: string | null;
    countryOfOrigin?: string | null;
    canonicalProductId?: string | null;
    subcategoryUuids?: Array<string | null> | null;
    categoryRootUuid?: string | null;
    seedId?: string | null;
    seedType?: core_apimessages_Listing_SeedType | null;
    handmade?: boolean | null;
    hasInventory?: boolean | null;
    inventory?: number | null;
    upc?: string | null;
    upcDoesNotApply?: boolean | null;
    taxExempt?: boolean | null;
    relistable?: boolean | null;
    bumpEligible?: boolean | null;
    shippingProfileId?: string | null;
    prefersReverbShippingLabel?: boolean | null;
    vatIncluded?: boolean | null;
    vatIncludedHint?: string | null;
    soldAsIs?: boolean | null;
    listingType?: core_apimessages_Listing_ListingType | null;
    overriddenAttributes?: Array<string | null> | null;
    publishedAt?: {
      __typename?: "google_protobuf_Timestamp";
      seconds?: number | null;
    } | null;
    categories?: Array<{
      __typename?: "Category";
      _id?: string | null;
      id?: string | null;
      name?: string | null;
      root?: boolean | null;
      leaf?: boolean | null;
      rootSlug?: string | null;
    } | null> | null;
    localizedContents?: Array<{
      __typename?: "core_apimessages_LocalizedListingContent";
      locale?: string | null;
      make?: string | null;
      model?: string | null;
      finish?: string | null;
      title?: string | null;
      description?: string | null;
      prop65Warning?: string | null;
      defaultForShop?: boolean | null;
    } | null> | null;
    traits?: Array<{
      __typename?: "core_apimessages_Trait";
      keyId?: string | null;
      valueId?: string | null;
    } | null> | null;
    condition?: {
      __typename?: "core_apimessages_Condition";
      conditionSlug?: string | null;
    } | null;
    video?: {
      __typename?: "core_apimessages_Video";
      link?: string | null;
    } | null;
    bumpRate?: {
      __typename?: "core_apimessages_BumpRate";
      rate?: number | null;
    } | null;
    images?: Array<{
      __typename?: "core_apimessages_Image";
      _id?: string | null;
      id?: string | null;
      source?: string | null;
      itemId?: string | null;
      version?: string | null;
      itemType?: string | null;
      publicId?: string | null;
      height?: number | null;
      width?: number | null;
      transformation?: {
        __typename?: "core_apimessages_ImageEntry_Transformation";
        x?: string | null;
        y?: string | null;
        width?: string | null;
        height?: string | null;
        crop?: string | null;
        angle?: number | null;
      } | null;
    } | null> | null;
    allShippingPrices?: {
      __typename?: "core_apimessages_ShippingPrices";
      localPickup?: boolean | null;
      shippingPrices?: Array<{
        __typename?: "core_apimessages_ShippingPrice";
        _id?: string | null;
        shippingRegionCode?: string | null;
        shippingMethod?: core_apimessages_ShippingMethod | null;
        carrierCalculated?: boolean | null;
        originalRateVatExcluded?: {
          __typename?: "core_apimessages_Money";
          amount?: string | null;
          currency?: string | null;
        } | null;
      } | null> | null;
    } | null;
    shipmentPackage?: {
      __typename?: "ShipmentPackage";
      _id?: string | null;
      allowedCarriers?: Array<string | null> | null;
      packageSizeSuggestionId?: string | null;
      length?: {
        __typename?: "core_apimessages_Measurement";
        value?: string | null;
        unit?: string | null;
      } | null;
      width?: {
        __typename?: "core_apimessages_Measurement";
        value?: string | null;
        unit?: string | null;
      } | null;
      height?: {
        __typename?: "core_apimessages_Measurement";
        value?: string | null;
        unit?: string | null;
      } | null;
      weight?: {
        __typename?: "core_apimessages_Measurement";
        value?: string | null;
        unit?: string | null;
      } | null;
    } | null;
    sellerPrice?: {
      __typename?: "core_apimessages_Money";
      amountCents?: number | null;
      currency?: string | null;
      amount?: string | null;
      display?: string | null;
    } | null;
    sellerCost?: {
      __typename?: "core_apimessages_Money";
      amountCents?: number | null;
      currency?: string | null;
      amount?: string | null;
      display?: string | null;
    } | null;
    sellerReportedMap?: {
      __typename?: "core_apimessages_Money";
      amountCents?: number | null;
      currency?: string | null;
      amount?: string | null;
      display?: string | null;
    } | null;
    preorderInfo?: {
      __typename?: "core_apimessages_PreorderInfo";
      leadTimeDays?: number | null;
      shipDate?: {
        __typename?: "google_protobuf_Timestamp";
        seconds?: number | null;
      } | null;
    } | null;
    shop?: {
      __typename?: "Shop";
      _id?: string | null;
      currencySetting?: string | null;
      defaultLocale?: string | null;
      shopSupportedLocaleCodes?: Array<string | null> | null;
      preferredSeller?: boolean | null;
      hasAccountRep?: boolean | null;
      inclusiveVatPricingEnabled?: boolean | null;
      vatId?: string | null;
      firstTimeSeller?: boolean | null;
      sameDayShippingConfigured?: boolean | null;
      listableConditions?: Array<{
        __typename?: "core_apimessages_Condition";
        displayName?: string | null;
        conditionSlug?: string | null;
      } | null> | null;
      offerPolicy?: {
        __typename?: "core_apimessages_ShopConfig_OfferPolicy";
        autoRejectLowOffers?: boolean | null;
        lowballPercentage?: number | null;
      } | null;
      returnPolicy?: {
        __typename?: "core_apimessages_ReturnPolicy";
        _id?: string | null;
        newReturnWindowDays?: number | null;
        usedReturnWindowDays?: number | null;
      } | null;
      taxPolicies?: Array<{
        __typename?: "core_apimessages_ShopTaxPolicy";
        vatPolicy?: boolean | null;
      } | null> | null;
      shippingRegionSettings?: Array<{
        __typename?: "core_apimessages_ShippingRegionSetting";
        regionCode?: string | null;
        regionName?: string | null;
        isDomestic?: boolean | null;
        allowedToShipCarrierCalculated?: boolean | null;
      } | null> | null;
      address?: {
        __typename?: "core_apimessages_Address";
        _id?: string | null;
        region?: string | null;
        postalCode?: string | null;
        countryCode?: string | null;
      } | null;
      shippingProfiles?: Array<{
        __typename?: "core_apimessages_ShippingProfile";
        name?: string | null;
        id?: string | null;
        categoryUuids?: Array<string | null> | null;
        localPickup?: boolean | null;
        allShippingRates?: Array<{
          __typename?: "core_apimessages_ShippingRates";
          regionCode?: string | null;
          regionName?: string | null;
          rateType?: string | null;
          rate?: {
            __typename?: "core_apimessages_Money";
            display?: string | null;
          } | null;
          incrementalRate?: {
            __typename?: "core_apimessages_Money";
            display?: string | null;
          } | null;
          expeditedRate?: {
            __typename?: "core_apimessages_Money";
            display?: string | null;
          } | null;
          regionalRates?: Array<{
            __typename?: "core_apimessages_RegionalShippingRate";
            areas?: Array<string | null> | null;
            rate?: {
              __typename?: "core_apimessages_Money";
              display?: string | null;
            } | null;
          } | null> | null;
          regionalExpeditedRates?: Array<{
            __typename?: "core_apimessages_RegionalShippingRate";
            areas?: Array<string | null> | null;
            rate?: {
              __typename?: "core_apimessages_Money";
              display?: string | null;
            } | null;
          } | null> | null;
        } | null> | null;
      } | null> | null;
    } | null;
    scheduledPriceDrops?: Array<{
      __typename?: "core_apimessages_ScheduledPriceDrop";
      active?: boolean | null;
      dropAt?: any | null;
      newPrice?: {
        __typename?: "core_apimessages_Money";
        amountCents?: number | null;
        currency?: string | null;
        amount?: string | null;
        display?: string | null;
      } | null;
    } | null> | null;
    priceRecommendation?: {
      __typename?: "reverb_pricing_PriceRecommendation";
      priceLow?: {
        __typename?: "reverb_pricing_Money";
        amount?: string | null;
        amountCents?: number | null;
        display?: string | null;
        currency?: string | null;
      } | null;
      priceMiddle?: {
        __typename?: "reverb_pricing_Money";
        amount?: string | null;
        amountCents?: number | null;
        display?: string | null;
        currency?: string | null;
      } | null;
      priceHigh?: {
        __typename?: "reverb_pricing_Money";
        amount?: string | null;
        amountCents?: number | null;
        display?: string | null;
        currency?: string | null;
      } | null;
    } | null;
  } | null;
};

export type Core_QuickPriceEdit_PriceUpdateMutationVariables = Exact<{
  input?: InputMaybe<Input_core_apimessages_UpdateListingRequest>;
}>;

export type Core_QuickPriceEdit_PriceUpdateMutation = {
  __typename?: "Mutation";
  crupdateListing?: {
    __typename?: "Listing";
    _id?: string | null;
    taxExempt?: boolean | null;
    sellerPrice?: {
      __typename?: "core_apimessages_Money";
      amountCents?: number | null;
      currency?: string | null;
      amount?: string | null;
      display?: string | null;
      symbol?: string | null;
    } | null;
  } | null;
};

export type SellerListingPriceRecommendationFragment = {
  __typename?: "Listing";
  _id?: string | null;
  priceRecommendation?: {
    __typename?: "reverb_pricing_PriceRecommendation";
    priceLow?: {
      __typename?: "reverb_pricing_Money";
      amount?: string | null;
      amountCents?: number | null;
      display?: string | null;
      currency?: string | null;
    } | null;
    priceMiddle?: {
      __typename?: "reverb_pricing_Money";
      amount?: string | null;
      amountCents?: number | null;
      display?: string | null;
      currency?: string | null;
    } | null;
    priceHigh?: {
      __typename?: "reverb_pricing_Money";
      amount?: string | null;
      amountCents?: number | null;
      display?: string | null;
      currency?: string | null;
    } | null;
  } | null;
};

export type SellerListingCardFragment = {
  __typename?: "Listing";
  _id?: string | null;
  id?: string | null;
  sellerUuid?: string | null;
  title?: string | null;
  slug?: string | null;
  sku?: string | null;
  state?: string | null;
  inventory?: number | null;
  hasInventory?: boolean | null;
  isAutoOfferEligible?: boolean | null;
  exclusiveChannel?: string | null;
  relistable?: boolean | null;
  vatIncluded?: boolean | null;
  vatIncludedHint?: string | null;
  taxExempt?: boolean | null;
  condition?: {
    __typename?: "core_apimessages_Condition";
    conditionSlug?: string | null;
    displayName?: string | null;
  } | null;
  bumpRate?: {
    __typename?: "core_apimessages_BumpRate";
    rate?: number | null;
  } | null;
  sellerPrice?: {
    __typename?: "core_apimessages_Money";
    amount?: string | null;
    amountCents?: number | null;
    symbol?: string | null;
    currency?: string | null;
    display?: string | null;
  } | null;
  images?: Array<{
    __typename?: "core_apimessages_Image";
    _id?: string | null;
    source?: string | null;
  } | null> | null;
  counts?: {
    __typename?: "core_apimessages_ListingCounts";
    views?: number | null;
    offers?: number | null;
    watchers?: number | null;
    inBuyerCarts?: number | null;
  } | null;
  orderStats?: {
    __typename?: "core_apimessages_OrderStats";
    totalOrders?: number | null;
  } | null;
  publishedAt?: {
    __typename?: "google_protobuf_Timestamp";
    seconds?: number | null;
  } | null;
  affirmZeroPercentFinancingConfiguration?: {
    __typename?: "core_apimessages_ListingAffirmZeroPercentFinancingConfiguration";
    isEligible?: boolean | null;
    isEnabled?: boolean | null;
  } | null;
  autoOffers?: {
    __typename?: "core_apimessages_AutoOffer";
    id?: string | null;
  } | null;
  shop?: {
    __typename?: "Shop";
    _id?: string | null;
    currency?: string | null;
    canCurateSales?: boolean | null;
  } | null;
  publishValidation?: {
    __typename?: "core_apimessages_ListingValidForPublish";
    isValid?: boolean | null;
    errors?: Array<{
      __typename?: "core_apimessages_Error";
      message?: string | null;
    } | null> | null;
  } | null;
  shipmentPackage?: {
    __typename?: "ShipmentPackage";
    _id?: string | null;
    length?: {
      __typename?: "core_apimessages_Measurement";
      value?: string | null;
      unit?: string | null;
    } | null;
    width?: {
      __typename?: "core_apimessages_Measurement";
      value?: string | null;
      unit?: string | null;
    } | null;
    height?: {
      __typename?: "core_apimessages_Measurement";
      value?: string | null;
      unit?: string | null;
    } | null;
    weight?: {
      __typename?: "core_apimessages_Measurement";
      value?: string | null;
      unit?: string | null;
    } | null;
  } | null;
};

export type Core_MyListings_SellerListingCardsQueryVariables = Exact<{
  listingIds?: InputMaybe<
    | Array<InputMaybe<Scalars["String"]["input"]>>
    | InputMaybe<Scalars["String"]["input"]>
  >;
  shippingRegionCodes?: InputMaybe<
    | Array<InputMaybe<Scalars["String"]["input"]>>
    | InputMaybe<Scalars["String"]["input"]>
  >;
  itemRegion?: InputMaybe<Scalars["String"]["input"]>;
  shopCurrency?: InputMaybe<Scalars["String"]["input"]>;
  shopCountryCode?: InputMaybe<Scalars["String"]["input"]>;
  skipPriceRecommendation: Scalars["Boolean"]["input"];
}>;

export type Core_MyListings_SellerListingCardsQuery = {
  __typename?: "Query";
  me?: {
    __typename?: "rql_Me";
    _id?: string | null;
    uuid?: string | null;
    availableActions?: Array<{
      __typename?: "core_apimessages_AvailableAction";
      id?: core_apimessages_UserActionName | null;
      name?: core_apimessages_UserActionName | null;
    } | null> | null;
    shop?: {
      __typename?: "MyShop";
      _id?: string | null;
      acceptsAffirm?: boolean | null;
      canCurateSales?: boolean | null;
      currency?: string | null;
      listings?: {
        __typename?: "reverb_search_SearchResponse";
        listings?: Array<{
          __typename?: "Listing";
          _id?: string | null;
          id?: string | null;
          sellerUuid?: string | null;
          title?: string | null;
          slug?: string | null;
          sku?: string | null;
          state?: string | null;
          inventory?: number | null;
          hasInventory?: boolean | null;
          isAutoOfferEligible?: boolean | null;
          exclusiveChannel?: string | null;
          relistable?: boolean | null;
          vatIncluded?: boolean | null;
          vatIncludedHint?: string | null;
          taxExempt?: boolean | null;
          condition?: {
            __typename?: "core_apimessages_Condition";
            conditionSlug?: string | null;
            displayName?: string | null;
          } | null;
          bumpRate?: {
            __typename?: "core_apimessages_BumpRate";
            rate?: number | null;
          } | null;
          sellerPrice?: {
            __typename?: "core_apimessages_Money";
            amount?: string | null;
            amountCents?: number | null;
            symbol?: string | null;
            currency?: string | null;
            display?: string | null;
          } | null;
          images?: Array<{
            __typename?: "core_apimessages_Image";
            _id?: string | null;
            source?: string | null;
          } | null> | null;
          counts?: {
            __typename?: "core_apimessages_ListingCounts";
            views?: number | null;
            offers?: number | null;
            watchers?: number | null;
            inBuyerCarts?: number | null;
          } | null;
          orderStats?: {
            __typename?: "core_apimessages_OrderStats";
            totalOrders?: number | null;
          } | null;
          publishedAt?: {
            __typename?: "google_protobuf_Timestamp";
            seconds?: number | null;
          } | null;
          affirmZeroPercentFinancingConfiguration?: {
            __typename?: "core_apimessages_ListingAffirmZeroPercentFinancingConfiguration";
            isEligible?: boolean | null;
            isEnabled?: boolean | null;
          } | null;
          autoOffers?: {
            __typename?: "core_apimessages_AutoOffer";
            id?: string | null;
          } | null;
          shop?: {
            __typename?: "Shop";
            _id?: string | null;
            currency?: string | null;
            canCurateSales?: boolean | null;
          } | null;
          publishValidation?: {
            __typename?: "core_apimessages_ListingValidForPublish";
            isValid?: boolean | null;
            errors?: Array<{
              __typename?: "core_apimessages_Error";
              message?: string | null;
            } | null> | null;
          } | null;
          shipmentPackage?: {
            __typename?: "ShipmentPackage";
            _id?: string | null;
            length?: {
              __typename?: "core_apimessages_Measurement";
              value?: string | null;
              unit?: string | null;
            } | null;
            width?: {
              __typename?: "core_apimessages_Measurement";
              value?: string | null;
              unit?: string | null;
            } | null;
            height?: {
              __typename?: "core_apimessages_Measurement";
              value?: string | null;
              unit?: string | null;
            } | null;
            weight?: {
              __typename?: "core_apimessages_Measurement";
              value?: string | null;
              unit?: string | null;
            } | null;
          } | null;
          priceRecommendation?: {
            __typename?: "reverb_pricing_PriceRecommendation";
            priceLow?: {
              __typename?: "reverb_pricing_Money";
              amount?: string | null;
              amountCents?: number | null;
              display?: string | null;
              currency?: string | null;
            } | null;
            priceMiddle?: {
              __typename?: "reverb_pricing_Money";
              amount?: string | null;
              amountCents?: number | null;
              display?: string | null;
              currency?: string | null;
            } | null;
            priceHigh?: {
              __typename?: "reverb_pricing_Money";
              amount?: string | null;
              amountCents?: number | null;
              display?: string | null;
              currency?: string | null;
            } | null;
          } | null;
        } | null> | null;
      } | null;
    } | null;
  } | null;
};

export type Core_MyListings_BulkDeleteMutationVariables = Exact<{
  input?: InputMaybe<Input_core_apimessages_BulkDeleteListingsRequest>;
}>;

export type Core_MyListings_BulkDeleteMutation = {
  __typename?: "Mutation";
  bulkDeleteListings?: {
    __typename?: "core_apimessages_BulkDeleteListingsResponse";
    responses?: Array<{
      __typename?: "core_apimessages_BulkDeleteListingsResponse_ListingDeleted";
      listingId?: string | null;
      success?: boolean | null;
      errors?: Array<{
        __typename?: "core_apimessages_Error";
        message?: string | null;
      } | null> | null;
    } | null> | null;
  } | null;
};

export type Core_MyListings_BulkDeleteAsyncMutationVariables = Exact<{
  input?: InputMaybe<Input_core_apimessages_BulkDeleteListingsAsyncRequest>;
}>;

export type Core_MyListings_BulkDeleteAsyncMutation = {
  __typename?: "Mutation";
  bulkDeleteListingsAsync?: {
    __typename?: "core_apimessages_SellerBulkActionAsyncResponse";
    enqueued?: boolean | null;
    message?: string | null;
  } | null;
};

export type Core_MyListings_BulkPublishMutationVariables = Exact<{
  input?: InputMaybe<Input_core_apimessages_BulkPublishListingsRequest>;
}>;

export type Core_MyListings_BulkPublishMutation = {
  __typename?: "Mutation";
  bulkPublishListings?: {
    __typename?: "core_apimessages_BulkPublishListingsResponse";
    responses?: Array<{
      __typename?: "core_apimessages_BulkPublishListingsResponse_ListingPublished";
      listingId?: string | null;
      success?: boolean | null;
      errors?: Array<{
        __typename?: "core_apimessages_Error";
        message?: string | null;
      } | null> | null;
    } | null> | null;
    prepublishStepRequired?: {
      __typename?: "core_apimessages_BulkPublishListingsResponse_PrepublishStepRequired";
      step?: string | null;
    } | null;
  } | null;
};

export type Core_MyListings_BulkPublishAsyncMutationVariables = Exact<{
  input?: InputMaybe<Input_core_apimessages_BulkPublishListingsAsyncRequest>;
}>;

export type Core_MyListings_BulkPublishAsyncMutation = {
  __typename?: "Mutation";
  bulkPublishListingsAsync?: {
    __typename?: "core_apimessages_SellerBulkActionAsyncResponse";
    enqueued?: boolean | null;
    message?: string | null;
  } | null;
};

export type Core_MyListings_ExportListingsMutationVariables = Exact<{
  input?: InputMaybe<Input_core_apimessages_BulkExportListingsRequest>;
}>;

export type Core_MyListings_ExportListingsMutation = {
  __typename?: "Mutation";
  bulkExportListings?: {
    __typename?: "core_apimessages_BulkExportListingsResponse";
    enqueued?: boolean | null;
    message?: string | null;
  } | null;
};

export type Core_MyListings_PublishAllListingsMutationVariables = Exact<{
  [key: string]: never;
}>;

export type Core_MyListings_PublishAllListingsMutation = {
  __typename?: "Mutation";
  publishAllListings?: {
    __typename?: "core_apimessages_PublishAllListingsResponse";
    enqueued?: boolean | null;
    message?: string | null;
    prepublishStepRequired?: {
      __typename?: "core_apimessages_PublishAllListingsResponse_PrepublishStepRequired";
      step?: string | null;
    } | null;
  } | null;
};

export type Core_MyListings_BulkCreateAffirmMutationVariables = Exact<{
  input?: InputMaybe<Input_core_apimessages_BulkCreateZeroPercentAffirmRequest>;
}>;

export type Core_MyListings_BulkCreateAffirmMutation = {
  __typename?: "Mutation";
  bulkCreateZeroPercentAffirm?: {
    __typename?: "core_apimessages_SellerBulkActionResponse";
    responses?: Array<{
      __typename?: "core_apimessages_SellerListingBulkActionResponse";
      listingId?: string | null;
      success?: boolean | null;
      errors?: Array<{
        __typename?: "core_apimessages_Error";
        message?: string | null;
      } | null> | null;
    } | null> | null;
  } | null;
};

export type Core_MyListings_BulkCreateAffirmAsyncMutationVariables = Exact<{
  input?: InputMaybe<Input_core_apimessages_BulkCreateZeroPercentAffirmAsyncRequest>;
}>;

export type Core_MyListings_BulkCreateAffirmAsyncMutation = {
  __typename?: "Mutation";
  bulkCreateZeroPercentAffirmAsync?: {
    __typename?: "core_apimessages_SellerBulkActionAsyncResponse";
    enqueued?: boolean | null;
    message?: string | null;
  } | null;
};

export type Core_MyListings_BulkDeleteAffirmMutationVariables = Exact<{
  input?: InputMaybe<Input_core_apimessages_BulkDeleteZeroPercentAffirmRequest>;
}>;

export type Core_MyListings_BulkDeleteAffirmMutation = {
  __typename?: "Mutation";
  bulkDeleteZeroPercentAffirm?: {
    __typename?: "core_apimessages_SellerBulkActionResponse";
    responses?: Array<{
      __typename?: "core_apimessages_SellerListingBulkActionResponse";
      listingId?: string | null;
      success?: boolean | null;
      errors?: Array<{
        __typename?: "core_apimessages_Error";
        message?: string | null;
      } | null> | null;
    } | null> | null;
  } | null;
};

export type Core_MyListings_BulkDeleteAffirmAsyncMutationVariables = Exact<{
  input?: InputMaybe<Input_core_apimessages_BulkDeleteZeroPercentAffirmAsyncRequest>;
}>;

export type Core_MyListings_BulkDeleteAffirmAsyncMutation = {
  __typename?: "Mutation";
  bulkDeleteZeroPercentAffirmAsync?: {
    __typename?: "core_apimessages_SellerBulkActionAsyncResponse";
    enqueued?: boolean | null;
    message?: string | null;
  } | null;
};

export type Core_MyListings_BulkCreateBumpedMutationVariables = Exact<{
  input?: InputMaybe<Input_core_apimessages_BulkCreateBumpedRequest>;
}>;

export type Core_MyListings_BulkCreateBumpedMutation = {
  __typename?: "Mutation";
  bulkCreateBumped?: {
    __typename?: "core_apimessages_SellerBulkActionResponse";
    responses?: Array<{
      __typename?: "core_apimessages_SellerListingBulkActionResponse";
      listingId?: string | null;
      success?: boolean | null;
      errors?: Array<{
        __typename?: "core_apimessages_Error";
        message?: string | null;
      } | null> | null;
    } | null> | null;
  } | null;
};

export type Core_MyListings_BulkCreateBumpedAsyncMutationVariables = Exact<{
  input?: InputMaybe<Input_core_apimessages_BulkCreateBumpedAsyncRequest>;
}>;

export type Core_MyListings_BulkCreateBumpedAsyncMutation = {
  __typename?: "Mutation";
  bulkCreateBumpedAsync?: {
    __typename?: "core_apimessages_SellerBulkActionAsyncResponse";
    enqueued?: boolean | null;
    message?: string | null;
  } | null;
};

export type Core_MyListings_BulkDeleteBumpedMutationVariables = Exact<{
  input?: InputMaybe<Input_core_apimessages_BulkDeleteBumpedRequest>;
}>;

export type Core_MyListings_BulkDeleteBumpedMutation = {
  __typename?: "Mutation";
  bulkDeleteBumped?: {
    __typename?: "core_apimessages_SellerBulkActionResponse";
    responses?: Array<{
      __typename?: "core_apimessages_SellerListingBulkActionResponse";
      listingId?: string | null;
      success?: boolean | null;
      errors?: Array<{
        __typename?: "core_apimessages_Error";
        message?: string | null;
      } | null> | null;
    } | null> | null;
  } | null;
};

export type Core_MyListings_BulkDeleteBumpedAsyncMutationVariables = Exact<{
  input?: InputMaybe<Input_core_apimessages_BulkDeleteBumpedAsyncRequest>;
}>;

export type Core_MyListings_BulkDeleteBumpedAsyncMutation = {
  __typename?: "Mutation";
  bulkDeleteBumpedAsync?: {
    __typename?: "core_apimessages_SellerBulkActionAsyncResponse";
    enqueued?: boolean | null;
    message?: string | null;
  } | null;
};

export type Core_MyListings_BulkCreateAutoOffersMutationVariables = Exact<{
  input?: InputMaybe<Input_core_apimessages_BulkCreateAutoOffersRequest>;
}>;

export type Core_MyListings_BulkCreateAutoOffersMutation = {
  __typename?: "Mutation";
  bulkCreateAutoOffers?: {
    __typename?: "core_apimessages_SellerBulkActionResponse";
    responses?: Array<{
      __typename?: "core_apimessages_SellerListingBulkActionResponse";
      listingId?: string | null;
      success?: boolean | null;
      errors?: Array<{
        __typename?: "core_apimessages_Error";
        message?: string | null;
      } | null> | null;
    } | null> | null;
  } | null;
};

export type Core_MyListings_BulkCreateAutoOffersAsyncMutationVariables = Exact<{
  input?: InputMaybe<Input_core_apimessages_BulkCreateAutoOffersAsyncRequest>;
}>;

export type Core_MyListings_BulkCreateAutoOffersAsyncMutation = {
  __typename?: "Mutation";
  bulkCreateAutoOffersAsync?: {
    __typename?: "core_apimessages_SellerBulkActionAsyncResponse";
    enqueued?: boolean | null;
    message?: string | null;
  } | null;
};

export type Core_MyListings_BulkDeleteAutoOffersMutationVariables = Exact<{
  input?: InputMaybe<Input_core_apimessages_BulkDeleteAutoOffersRequest>;
}>;

export type Core_MyListings_BulkDeleteAutoOffersMutation = {
  __typename?: "Mutation";
  bulkDeleteAutoOffers?: {
    __typename?: "core_apimessages_SellerBulkActionResponse";
    responses?: Array<{
      __typename?: "core_apimessages_SellerListingBulkActionResponse";
      listingId?: string | null;
      success?: boolean | null;
      errors?: Array<{
        __typename?: "core_apimessages_Error";
        message?: string | null;
      } | null> | null;
    } | null> | null;
  } | null;
};

export type Core_MyListings_BulkDeleteAutoOffersAsyncMutationVariables = Exact<{
  input?: InputMaybe<Input_core_apimessages_BulkDeleteAutoOffersAsyncRequest>;
}>;

export type Core_MyListings_BulkDeleteAutoOffersAsyncMutation = {
  __typename?: "Mutation";
  bulkDeleteAutoOffersAsync?: {
    __typename?: "core_apimessages_SellerBulkActionAsyncResponse";
    enqueued?: boolean | null;
    message?: string | null;
  } | null;
};

export type Core_MyListings_BulkCreateSaleMembershipsMutationVariables = Exact<{
  input?: InputMaybe<Input_core_apimessages_BulkSaleMembershipsRequest>;
}>;

export type Core_MyListings_BulkCreateSaleMembershipsMutation = {
  __typename?: "Mutation";
  bulkCreateSaleMemberships?: {
    __typename?: "core_apimessages_SellerBulkActionResponse";
    responses?: Array<{
      __typename?: "core_apimessages_SellerListingBulkActionResponse";
      listingId?: string | null;
      success?: boolean | null;
      errors?: Array<{
        __typename?: "core_apimessages_Error";
        message?: string | null;
      } | null> | null;
    } | null> | null;
  } | null;
};

export type Core_MyListings_BulkDeleteSaleMembershipsMutationVariables = Exact<{
  input?: InputMaybe<Input_core_apimessages_BulkSaleMembershipsRequest>;
}>;

export type Core_MyListings_BulkDeleteSaleMembershipsMutation = {
  __typename?: "Mutation";
  bulkDeleteSaleMemberships?: {
    __typename?: "core_apimessages_SellerBulkActionResponse";
    responses?: Array<{
      __typename?: "core_apimessages_SellerListingBulkActionResponse";
      listingId?: string | null;
      success?: boolean | null;
      errors?: Array<{
        __typename?: "core_apimessages_Error";
        message?: string | null;
      } | null> | null;
    } | null> | null;
  } | null;
};

export type Core_MySalesQueryVariables = Exact<{
  listingIds?: InputMaybe<
    | Array<InputMaybe<Scalars["String"]["input"]>>
    | InputMaybe<Scalars["String"]["input"]>
  >;
}>;

export type Core_MySalesQuery = {
  __typename?: "Query";
  me?: {
    __typename?: "rql_Me";
    _id?: string | null;
    uuid?: string | null;
    shop?: {
      __typename?: "MyShop";
      _id?: string | null;
      sales?: {
        __typename?: "core_apimessages_MySalesResponse";
        reverbSales?: Array<{
          __typename?: "core_apimessages_Sale";
          _id?: string | null;
          id?: string | null;
          name?: string | null;
          state?: core_apimessages_Sale_SaleState | null;
          discountValue?: string | null;
          startsAt?: {
            __typename?: "google_protobuf_Timestamp";
            seconds?: number | null;
          } | null;
          endsAt?: {
            __typename?: "google_protobuf_Timestamp";
            seconds?: number | null;
          } | null;
          links?: {
            __typename?: "core_apimessages_SaleLinks";
            self?: {
              __typename?: "core_apimessages_Link";
              href?: string | null;
            } | null;
          } | null;
        } | null> | null;
        sellerSales?: Array<{
          __typename?: "core_apimessages_Sale";
          _id?: string | null;
          id?: string | null;
          name?: string | null;
          state?: core_apimessages_Sale_SaleState | null;
          discountValue?: string | null;
          startsAt?: {
            __typename?: "google_protobuf_Timestamp";
            seconds?: number | null;
          } | null;
          endsAt?: {
            __typename?: "google_protobuf_Timestamp";
            seconds?: number | null;
          } | null;
          links?: {
            __typename?: "core_apimessages_SaleLinks";
            self?: {
              __typename?: "core_apimessages_Link";
              href?: string | null;
            } | null;
          } | null;
        } | null> | null;
      } | null;
    } | null;
  } | null;
  listings?: Array<{
    __typename?: "Listing";
    _id?: string | null;
    id?: string | null;
    availableSalesMemberships?: {
      __typename?: "core_apimessages_ListingSalesMemberships";
      reverbSalesMemberships?: Array<{
        __typename?: "core_apimessages_ListingSaleMembership";
        listingId?: string | null;
        totalLiveListings?: number | null;
        isListingInSale?: boolean | null;
        isListingEligible?: boolean | null;
        sale?: {
          __typename?: "core_apimessages_Sale";
          _id?: string | null;
          id?: string | null;
          name?: string | null;
          percent?: number | null;
          startsAt?: {
            __typename?: "google_protobuf_Timestamp";
            seconds?: number | null;
          } | null;
          endsAt?: {
            __typename?: "google_protobuf_Timestamp";
            seconds?: number | null;
          } | null;
          links?: {
            __typename?: "core_apimessages_SaleLinks";
            self?: {
              __typename?: "core_apimessages_Link";
              href?: string | null;
            } | null;
          } | null;
        } | null;
      } | null> | null;
      sellerSalesMemberships?: Array<{
        __typename?: "core_apimessages_ListingSaleMembership";
        listingId?: string | null;
        totalLiveListings?: number | null;
        isListingInSale?: boolean | null;
        isListingEligible?: boolean | null;
        sale?: {
          __typename?: "core_apimessages_Sale";
          _id?: string | null;
          id?: string | null;
          name?: string | null;
          percent?: number | null;
          startsAt?: {
            __typename?: "google_protobuf_Timestamp";
            seconds?: number | null;
          } | null;
          endsAt?: {
            __typename?: "google_protobuf_Timestamp";
            seconds?: number | null;
          } | null;
          links?: {
            __typename?: "core_apimessages_SaleLinks";
            self?: {
              __typename?: "core_apimessages_Link";
              href?: string | null;
            } | null;
          } | null;
        } | null;
      } | null> | null;
    } | null;
  } | null> | null;
};

export type Core_Listing_DeleteDraftMutationVariables = Exact<{
  input?: InputMaybe<Input_core_apimessages_DraftListingDeleteRequest>;
}>;

export type Core_Listing_DeleteDraftMutation = {
  __typename?: "Mutation";
  deleteDraftListing?: {
    __typename?: "core_apimessages_DraftListingDeleteResponse";
    listingId?: string | null;
  } | null;
};

export type Core_ZeroFinancing_EnableMutationVariables = Exact<{
  input?: InputMaybe<Input_core_apimessages_MyAffirmCuratedSetMembershipRequest>;
}>;

export type Core_ZeroFinancing_EnableMutation = {
  __typename?: "Mutation";
  addListingToMyAffirmCuratedSet?: {
    __typename?: "core_apimessages_MyCuratedSetMembershipResponse";
    curatedSetMembership?: {
      __typename?: "core_apimessages_CuratedSetMembership";
      id?: string | null;
      curatedSetId?: string | null;
      productId?: string | null;
    } | null;
  } | null;
};

export type Core_ZeroFinancing_DisableMutationVariables = Exact<{
  input?: InputMaybe<Input_core_apimessages_MyAffirmCuratedSetMembershipRequest>;
}>;

export type Core_ZeroFinancing_DisableMutation = {
  __typename?: "Mutation";
  removeListingFromMyAffirmCuratedSet?: {
    __typename?: "core_apimessages_MyCuratedSetMembershipResponse";
    curatedSetMembership?: {
      __typename?: "core_apimessages_CuratedSetMembership";
      id?: string | null;
      curatedSetId?: string | null;
      productId?: string | null;
    } | null;
  } | null;
};

export type Core_MyListing_CreateSaleMembershipMutationVariables = Exact<{
  input?: InputMaybe<Input_core_apimessages_CreateMySaleMembershipRequest>;
}>;

export type Core_MyListing_CreateSaleMembershipMutation = {
  __typename?: "Mutation";
  createMySaleMembership?: {
    __typename?: "core_apimessages_CreateMySaleMembershipResponse";
    saleMembership?: {
      __typename?: "core_apimessages_ListingSaleMembership";
      listingId?: string | null;
      totalLiveListings?: number | null;
      isListingInSale?: boolean | null;
      isListingEligible?: boolean | null;
      sale?: {
        __typename?: "core_apimessages_Sale";
        _id?: string | null;
        id?: string | null;
        name?: string | null;
        percent?: number | null;
        startsAt?: {
          __typename?: "google_protobuf_Timestamp";
          seconds?: number | null;
        } | null;
        endsAt?: {
          __typename?: "google_protobuf_Timestamp";
          seconds?: number | null;
        } | null;
        links?: {
          __typename?: "core_apimessages_SaleLinks";
          self?: {
            __typename?: "core_apimessages_Link";
            href?: string | null;
          } | null;
        } | null;
      } | null;
    } | null;
  } | null;
};

export type Core_MyListing_DeleteSaleMembershipMutationVariables = Exact<{
  input?: InputMaybe<Input_core_apimessages_DeleteMySaleMembershipRequest>;
}>;

export type Core_MyListing_DeleteSaleMembershipMutation = {
  __typename?: "Mutation";
  deleteMySaleMembership?: {
    __typename?: "core_apimessages_DeleteMySaleMembershipResponse";
    saleMembership?: {
      __typename?: "core_apimessages_ListingSaleMembership";
      listingId?: string | null;
      totalLiveListings?: number | null;
      isListingInSale?: boolean | null;
      isListingEligible?: boolean | null;
      sale?: {
        __typename?: "core_apimessages_Sale";
        _id?: string | null;
        id?: string | null;
        name?: string | null;
        percent?: number | null;
        startsAt?: {
          __typename?: "google_protobuf_Timestamp";
          seconds?: number | null;
        } | null;
        endsAt?: {
          __typename?: "google_protobuf_Timestamp";
          seconds?: number | null;
        } | null;
        links?: {
          __typename?: "core_apimessages_SaleLinks";
          self?: {
            __typename?: "core_apimessages_Link";
            href?: string | null;
          } | null;
        } | null;
      } | null;
    } | null;
  } | null;
};

export type AvailableSalesMembershipsFragment = {
  __typename?: "Listing";
  _id?: string | null;
  availableSalesMemberships?: {
    __typename?: "core_apimessages_ListingSalesMemberships";
    reverbSalesMemberships?: Array<{
      __typename?: "core_apimessages_ListingSaleMembership";
      listingId?: string | null;
      totalLiveListings?: number | null;
      isListingInSale?: boolean | null;
      isListingEligible?: boolean | null;
      sale?: {
        __typename?: "core_apimessages_Sale";
        _id?: string | null;
        id?: string | null;
        name?: string | null;
        percent?: number | null;
        startsAt?: {
          __typename?: "google_protobuf_Timestamp";
          seconds?: number | null;
        } | null;
        endsAt?: {
          __typename?: "google_protobuf_Timestamp";
          seconds?: number | null;
        } | null;
        links?: {
          __typename?: "core_apimessages_SaleLinks";
          self?: {
            __typename?: "core_apimessages_Link";
            href?: string | null;
          } | null;
        } | null;
      } | null;
    } | null> | null;
    sellerSalesMemberships?: Array<{
      __typename?: "core_apimessages_ListingSaleMembership";
      listingId?: string | null;
      totalLiveListings?: number | null;
      isListingInSale?: boolean | null;
      isListingEligible?: boolean | null;
      sale?: {
        __typename?: "core_apimessages_Sale";
        _id?: string | null;
        id?: string | null;
        name?: string | null;
        percent?: number | null;
        startsAt?: {
          __typename?: "google_protobuf_Timestamp";
          seconds?: number | null;
        } | null;
        endsAt?: {
          __typename?: "google_protobuf_Timestamp";
          seconds?: number | null;
        } | null;
        links?: {
          __typename?: "core_apimessages_SaleLinks";
          self?: {
            __typename?: "core_apimessages_Link";
            href?: string | null;
          } | null;
        } | null;
      } | null;
    } | null> | null;
  } | null;
};

export type Core_MyListing_AvailableSalesMembershipsQueryVariables = Exact<{
  listingId?: InputMaybe<Scalars["String"]["input"]>;
}>;

export type Core_MyListing_AvailableSalesMembershipsQuery = {
  __typename?: "Query";
  listing?: {
    __typename?: "Listing";
    _id?: string | null;
    id?: string | null;
    availableSalesMemberships?: {
      __typename?: "core_apimessages_ListingSalesMemberships";
      reverbSalesMemberships?: Array<{
        __typename?: "core_apimessages_ListingSaleMembership";
        listingId?: string | null;
        totalLiveListings?: number | null;
        isListingInSale?: boolean | null;
        isListingEligible?: boolean | null;
        sale?: {
          __typename?: "core_apimessages_Sale";
          _id?: string | null;
          id?: string | null;
          name?: string | null;
          percent?: number | null;
          startsAt?: {
            __typename?: "google_protobuf_Timestamp";
            seconds?: number | null;
          } | null;
          endsAt?: {
            __typename?: "google_protobuf_Timestamp";
            seconds?: number | null;
          } | null;
          links?: {
            __typename?: "core_apimessages_SaleLinks";
            self?: {
              __typename?: "core_apimessages_Link";
              href?: string | null;
            } | null;
          } | null;
        } | null;
      } | null> | null;
      sellerSalesMemberships?: Array<{
        __typename?: "core_apimessages_ListingSaleMembership";
        listingId?: string | null;
        totalLiveListings?: number | null;
        isListingInSale?: boolean | null;
        isListingEligible?: boolean | null;
        sale?: {
          __typename?: "core_apimessages_Sale";
          _id?: string | null;
          id?: string | null;
          name?: string | null;
          percent?: number | null;
          startsAt?: {
            __typename?: "google_protobuf_Timestamp";
            seconds?: number | null;
          } | null;
          endsAt?: {
            __typename?: "google_protobuf_Timestamp";
            seconds?: number | null;
          } | null;
          links?: {
            __typename?: "core_apimessages_SaleLinks";
            self?: {
              __typename?: "core_apimessages_Link";
              href?: string | null;
            } | null;
          } | null;
        } | null;
      } | null> | null;
    } | null;
  } | null;
};

export type Core_Listing_UpdateStateMutationVariables = Exact<{
  input?: InputMaybe<Input_core_apimessages_UpdateListingStateRequest>;
}>;

export type Core_Listing_UpdateStateMutation = {
  __typename?: "Mutation";
  updateListingState?: {
    __typename?: "core_apimessages_UpdateListingStateResponse";
    newState?: core_apimessages_ListingStateUpdate | null;
  } | null;
};

export type CollectionInfoFragment = {
  __typename?: "CollectionHeader";
  _id?: string | null;
  title?: string | null;
  metaTitle?: string | null;
  collectionId?: string | null;
  collectionType?: core_apimessages_CollectionHeader_CollectionType | null;
  description?: string | null;
  fullBleedImage?: {
    __typename?: "core_apimessages_Image";
    _id?: string | null;
    source?: string | null;
  } | null;
};

export type CombinedMarketplaceBumpTrackingMutationVariables = Exact<{
  input?: InputMaybe<Input_arbiter_BulkTrackImpressionsRequest>;
}>;

export type CombinedMarketplaceBumpTrackingMutation = {
  __typename?: "Mutation";
  bulkTrackImpressions?: {
    __typename?: "arbiter_BulkTrackImpressionsResponse";
    listingIds?: Array<string | null> | null;
  } | null;
};

export type Core_Marketplace_HeaderQueryVariables = Exact<{
  collectionType?: InputMaybe<core_apimessages_CollectionHeader_CollectionType>;
  collectionSlug?: InputMaybe<Scalars["String"]["input"]>;
  skipCollectionHeader: Scalars["Boolean"]["input"];
  categorySlugs?: InputMaybe<
    | Array<InputMaybe<Scalars["String"]["input"]>>
    | InputMaybe<Scalars["String"]["input"]>
  >;
  brandSlugs?: InputMaybe<
    | Array<InputMaybe<Scalars["String"]["input"]>>
    | InputMaybe<Scalars["String"]["input"]>
  >;
  conditionSlugs?: InputMaybe<
    | Array<InputMaybe<Scalars["String"]["input"]>>
    | InputMaybe<Scalars["String"]["input"]>
  >;
  curatedSetSlug?: InputMaybe<Scalars["String"]["input"]>;
  shopSlug?: InputMaybe<Scalars["String"]["input"]>;
  yearMin?: InputMaybe<Scalars["String"]["input"]>;
  yearMax?: InputMaybe<Scalars["String"]["input"]>;
  query?: InputMaybe<Scalars["String"]["input"]>;
}>;

export type Core_Marketplace_HeaderQuery = {
  __typename?: "Query";
  collectionHeader?: {
    __typename?: "CollectionHeader";
    _id?: string | null;
    title?: string | null;
    metaTitle?: string | null;
    collectionId?: string | null;
    collectionType?: core_apimessages_CollectionHeader_CollectionType | null;
    description?: string | null;
    fullBleedImage?: {
      __typename?: "core_apimessages_Image";
      _id?: string | null;
      source?: string | null;
    } | null;
  } | null;
  searchMetadata?: {
    __typename?: "core_apimessages_SearchMetadataResponse";
    title?: string | null;
  } | null;
};

export type Core_Marketplace_CombinedMarketplaceSearchQueryVariables = Exact<{
  inputListings?: InputMaybe<Input_reverb_search_ListingsSearchRequest>;
  inputBumped?: InputMaybe<Input_reverb_search_ListingsSearchRequest>;
  inputAggs?: InputMaybe<Input_reverb_search_ListingsSearchRequest>;
  shouldntLoadBumps: Scalars["Boolean"]["input"];
  shouldntLoadSuggestions: Scalars["Boolean"]["input"];
  usingListView: Scalars["Boolean"]["input"];
  signalGroups?: InputMaybe<
    | Array<InputMaybe<reverb_signals_Signal_Group>>
    | InputMaybe<reverb_signals_Signal_Group>
  >;
  useSignalSystem: Scalars["Boolean"]["input"];
}>;

export type Core_Marketplace_CombinedMarketplaceSearchQuery = {
  __typename?: "Query";
  bumpedSearch?: {
    __typename?: "reverb_search_SearchResponse";
    listings?: Array<{
      __typename?: "Listing";
      _id?: string | null;
      id?: string | null;
      title?: string | null;
      sellerId?: string | null;
      watching?: boolean | null;
      categoryUuids?: Array<string | null> | null;
      state?: string | null;
      slug?: string | null;
      listingType?: core_apimessages_Listing_ListingType | null;
      make?: string | null;
      model?: string | null;
      upc?: string | null;
      stateDescription?: string | null;
      bumped?: boolean | null;
      soldAsIs?: boolean | null;
      usOutlet?: boolean | null;
      offersEnabled?: boolean | null;
      isBuyerOfferEligible?: boolean | null;
      otherBuyersWithListingInCartCounts?: number | null;
      currency?: string | null;
      brandSlug?: string | null;
      categoryRootUuid?: string | null;
      bumpEligible?: boolean | null;
      shopId?: string | null;
      inventory?: number | null;
      acceptedPaymentMethods?: Array<string | null> | null;
      signals?: Array<{
        __typename?: "reverb_signals_Signal";
        name?: reverb_signals_Signal_Name | null;
        title?: string | null;
        icon?: string | null;
      } | null> | null;
      watchThumbnails?: Array<{
        __typename?: "core_apimessages_Image";
        _id?: string | null;
        source?: string | null;
      } | null> | null;
      bumpKey?: {
        __typename?: "core_apimessages_BumpKey";
        key?: string | null;
      } | null;
      shop?: {
        __typename?: "Shop";
        _id?: string | null;
        name?: string | null;
        slug?: string | null;
        preferredSeller?: boolean | null;
        quickShipper?: boolean | null;
        quickResponder?: boolean | null;
        address?: {
          __typename?: "core_apimessages_Address";
          _id?: string | null;
          locality?: string | null;
          countryCode?: string | null;
          region?: string | null;
          displayLocation?: string | null;
          country?: {
            __typename?: "core_apimessages_Country";
            _id?: string | null;
            countryCode?: string | null;
            name?: string | null;
          } | null;
        } | null;
        returnPolicy?: {
          __typename?: "core_apimessages_ReturnPolicy";
          usedReturnWindowDays?: number | null;
          newReturnWindowDays?: number | null;
          _id?: string | null;
        } | null;
      } | null;
      seller?: {
        __typename?: "User";
        _id?: string | null;
        feedbackSummary?: {
          __typename?: "core_apimessages_FeedbackSummary";
          receivedCount?: number | null;
          rollingRatingPercentage?: number | null;
        } | null;
      } | null;
      csp?: {
        __typename?: "CSP";
        _id?: string | null;
        id?: string | null;
        slug?: string | null;
        webLink?: {
          __typename?: "core_apimessages_Link";
          href?: string | null;
        } | null;
        brand?: {
          __typename?: "Brand";
          _id?: string | null;
          slug?: string | null;
        } | null;
      } | null;
      publishedAt?: {
        __typename?: "google_protobuf_Timestamp";
        seconds?: number | null;
      } | null;
      condition?: {
        __typename?: "core_apimessages_Condition";
        displayName?: string | null;
        conditionSlug?: string | null;
        conditionUuid?: string | null;
      } | null;
      pricing?: {
        __typename?: "core_apimessages_ListingPricing";
        buyerPrice?: {
          __typename?: "core_apimessages_Money";
          display?: string | null;
          currency?: string | null;
          amount?: string | null;
          amountCents?: number | null;
        } | null;
        originalPrice?: {
          __typename?: "core_apimessages_Money";
          display?: string | null;
        } | null;
        ribbon?: {
          __typename?: "core_apimessages_Ribbon";
          display?: string | null;
          reason?: core_apimessages_Ribbon_Reason | null;
        } | null;
      } | null;
      images?: Array<{
        __typename?: "core_apimessages_Image";
        source?: string | null;
      } | null> | null;
      shipping?: {
        __typename?: "core_apimessages_ShippingPrices";
        freeExpeditedShipping?: boolean | null;
        localPickupOnly?: boolean | null;
        localPickup?: boolean | null;
        shippingPrices?: Array<{
          __typename?: "core_apimessages_ShippingPrice";
          _id?: string | null;
          shippingMethod?: core_apimessages_ShippingMethod | null;
          carrierCalculated?: boolean | null;
          destinationPostalCodeNeeded?: boolean | null;
          regional?: boolean | null;
          postalCode?: string | null;
          rate?: {
            __typename?: "core_apimessages_Money";
            amount?: string | null;
            amountCents?: number | null;
            currency?: string | null;
            display?: string | null;
          } | null;
        } | null> | null;
      } | null;
      preorderInfo?: {
        __typename?: "core_apimessages_PreorderInfo";
        onPreorder?: boolean | null;
        estimatedShipDate?: {
          __typename?: "google_protobuf_Timestamp";
          seconds?: number | null;
        } | null;
      } | null;
      priceRecommendation?: {
        __typename?: "reverb_pricing_PriceRecommendation";
        priceMiddle?: {
          __typename?: "reverb_pricing_Money";
          amountCents?: number | null;
          currency?: string | null;
        } | null;
      } | null;
      certifiedPreOwned?: {
        __typename?: "core_apimessages_ListingCertifiedPreOwned";
        title?: string | null;
        badgeIconUrl?: string | null;
      } | null;
      categories?: Array<{
        __typename?: "Category";
        _id?: string | null;
        slug?: string | null;
        rootSlug?: string | null;
      } | null> | null;
      sale?: {
        __typename?: "core_apimessages_Sale";
        _id?: string | null;
        id?: string | null;
        code?: string | null;
        buyerIneligibilityReason?: string | null;
      } | null;
    } | null> | null;
  } | null;
  aggsSearch?: {
    __typename?: "reverb_search_SearchResponse";
    filters?: Array<{
      __typename?: "reverb_search_Filter";
      name?: string | null;
      key?: string | null;
      aggregationName?: string | null;
      widgetType?: reverb_search_Filter_WidgetType | null;
      options?: Array<{
        __typename?: "reverb_search_FilterOption";
        name?: string | null;
        selected?: boolean | null;
        autoselected?: boolean | null;
        paramName?: string | null;
        setValues?: Array<string | null> | null;
        unsetValues?: Array<string | null> | null;
        all?: boolean | null;
        optionValue?: string | null;
        trackingValue?: string | null;
        count?: {
          __typename?: "google_protobuf_Int32Value";
          value?: number | null;
        } | null;
        subFilter?: {
          __typename?: "reverb_search_Filter";
          widgetType?: reverb_search_Filter_WidgetType | null;
          options?: Array<{
            __typename?: "reverb_search_FilterOption";
            name?: string | null;
            selected?: boolean | null;
            autoselected?: boolean | null;
            paramName?: string | null;
            setValues?: Array<string | null> | null;
            unsetValues?: Array<string | null> | null;
            all?: boolean | null;
            optionValue?: string | null;
            trackingValue?: string | null;
            count?: {
              __typename?: "google_protobuf_Int32Value";
              value?: number | null;
            } | null;
            subFilter?: {
              __typename?: "reverb_search_Filter";
              widgetType?: reverb_search_Filter_WidgetType | null;
              options?: Array<{
                __typename?: "reverb_search_FilterOption";
                name?: string | null;
                selected?: boolean | null;
                autoselected?: boolean | null;
                paramName?: string | null;
                setValues?: Array<string | null> | null;
                unsetValues?: Array<string | null> | null;
                all?: boolean | null;
                optionValue?: string | null;
                trackingValue?: string | null;
                count?: {
                  __typename?: "google_protobuf_Int32Value";
                  value?: number | null;
                } | null;
                subFilter?: {
                  __typename?: "reverb_search_Filter";
                  widgetType?: reverb_search_Filter_WidgetType | null;
                  options?: Array<{
                    __typename?: "reverb_search_FilterOption";
                    name?: string | null;
                    selected?: boolean | null;
                    autoselected?: boolean | null;
                    paramName?: string | null;
                    setValues?: Array<string | null> | null;
                    unsetValues?: Array<string | null> | null;
                    all?: boolean | null;
                    optionValue?: string | null;
                    trackingValue?: string | null;
                    count?: {
                      __typename?: "google_protobuf_Int32Value";
                      value?: number | null;
                    } | null;
                  } | null> | null;
                } | null;
              } | null> | null;
            } | null;
          } | null> | null;
        } | null;
      } | null> | null;
    } | null> | null;
  } | null;
  listingsSearch?: {
    __typename?: "reverb_search_SearchResponse";
    total?: number | null;
    offset?: number | null;
    limit?: number | null;
    suggestedQueries?: Array<string | null> | null;
    eligibleAutodirects?: Array<reverb_search_Autodirects | null> | null;
    listings?: Array<{
      __typename?: "Listing";
      _id?: string | null;
      esScore?: number | null;
      id?: string | null;
      title?: string | null;
      sellerId?: string | null;
      watching?: boolean | null;
      otherBuyersWithListingInCartCounts?: number | null;
      categoryUuids?: Array<string | null> | null;
      state?: string | null;
      slug?: string | null;
      listingType?: core_apimessages_Listing_ListingType | null;
      make?: string | null;
      model?: string | null;
      upc?: string | null;
      stateDescription?: string | null;
      bumped?: boolean | null;
      soldAsIs?: boolean | null;
      usOutlet?: boolean | null;
      offersEnabled?: boolean | null;
      isBuyerOfferEligible?: boolean | null;
      currency?: string | null;
      brandSlug?: string | null;
      categoryRootUuid?: string | null;
      bumpEligible?: boolean | null;
      shopId?: string | null;
      inventory?: number | null;
      acceptedPaymentMethods?: Array<string | null> | null;
      signals?: Array<{
        __typename?: "reverb_signals_Signal";
        name?: reverb_signals_Signal_Name | null;
        title?: string | null;
        icon?: string | null;
      } | null> | null;
      watchThumbnails?: Array<{
        __typename?: "core_apimessages_Image";
        _id?: string | null;
        source?: string | null;
      } | null> | null;
      shop?: {
        __typename?: "Shop";
        _id?: string | null;
        name?: string | null;
        slug?: string | null;
        preferredSeller?: boolean | null;
        quickShipper?: boolean | null;
        quickResponder?: boolean | null;
        address?: {
          __typename?: "core_apimessages_Address";
          _id?: string | null;
          locality?: string | null;
          countryCode?: string | null;
          region?: string | null;
          displayLocation?: string | null;
          country?: {
            __typename?: "core_apimessages_Country";
            _id?: string | null;
            countryCode?: string | null;
            name?: string | null;
          } | null;
        } | null;
        returnPolicy?: {
          __typename?: "core_apimessages_ReturnPolicy";
          usedReturnWindowDays?: number | null;
          newReturnWindowDays?: number | null;
          _id?: string | null;
        } | null;
      } | null;
      seller?: {
        __typename?: "User";
        _id?: string | null;
        feedbackSummary?: {
          __typename?: "core_apimessages_FeedbackSummary";
          receivedCount?: number | null;
          rollingRatingPercentage?: number | null;
        } | null;
      } | null;
      csp?: {
        __typename?: "CSP";
        _id?: string | null;
        id?: string | null;
        slug?: string | null;
        webLink?: {
          __typename?: "core_apimessages_Link";
          href?: string | null;
        } | null;
        brand?: {
          __typename?: "Brand";
          _id?: string | null;
          slug?: string | null;
        } | null;
      } | null;
      publishedAt?: {
        __typename?: "google_protobuf_Timestamp";
        seconds?: number | null;
      } | null;
      condition?: {
        __typename?: "core_apimessages_Condition";
        displayName?: string | null;
        conditionSlug?: string | null;
        conditionUuid?: string | null;
      } | null;
      pricing?: {
        __typename?: "core_apimessages_ListingPricing";
        buyerPrice?: {
          __typename?: "core_apimessages_Money";
          display?: string | null;
          currency?: string | null;
          amount?: string | null;
          amountCents?: number | null;
        } | null;
        originalPrice?: {
          __typename?: "core_apimessages_Money";
          display?: string | null;
        } | null;
        ribbon?: {
          __typename?: "core_apimessages_Ribbon";
          display?: string | null;
          reason?: core_apimessages_Ribbon_Reason | null;
        } | null;
      } | null;
      images?: Array<{
        __typename?: "core_apimessages_Image";
        source?: string | null;
      } | null> | null;
      shipping?: {
        __typename?: "core_apimessages_ShippingPrices";
        freeExpeditedShipping?: boolean | null;
        localPickupOnly?: boolean | null;
        localPickup?: boolean | null;
        shippingPrices?: Array<{
          __typename?: "core_apimessages_ShippingPrice";
          _id?: string | null;
          shippingMethod?: core_apimessages_ShippingMethod | null;
          carrierCalculated?: boolean | null;
          destinationPostalCodeNeeded?: boolean | null;
          regional?: boolean | null;
          postalCode?: string | null;
          rate?: {
            __typename?: "core_apimessages_Money";
            amount?: string | null;
            amountCents?: number | null;
            currency?: string | null;
            display?: string | null;
          } | null;
        } | null> | null;
      } | null;
      preorderInfo?: {
        __typename?: "core_apimessages_PreorderInfo";
        onPreorder?: boolean | null;
        estimatedShipDate?: {
          __typename?: "google_protobuf_Timestamp";
          seconds?: number | null;
        } | null;
      } | null;
      priceRecommendation?: {
        __typename?: "reverb_pricing_PriceRecommendation";
        priceMiddle?: {
          __typename?: "reverb_pricing_Money";
          amountCents?: number | null;
          currency?: string | null;
        } | null;
      } | null;
      certifiedPreOwned?: {
        __typename?: "core_apimessages_ListingCertifiedPreOwned";
        title?: string | null;
        badgeIconUrl?: string | null;
      } | null;
      categories?: Array<{
        __typename?: "Category";
        _id?: string | null;
        slug?: string | null;
        rootSlug?: string | null;
      } | null> | null;
      sale?: {
        __typename?: "core_apimessages_Sale";
        _id?: string | null;
        id?: string | null;
        code?: string | null;
        buyerIneligibilityReason?: string | null;
      } | null;
    } | null> | null;
    fallbackListings?: Array<{
      __typename?: "Listing";
      _id?: string | null;
      id?: string | null;
      otherBuyersWithListingInCartCounts?: number | null;
      title?: string | null;
      sellerId?: string | null;
      watching?: boolean | null;
      slug?: string | null;
      listingType?: core_apimessages_Listing_ListingType | null;
      make?: string | null;
      model?: string | null;
      upc?: string | null;
      state?: string | null;
      stateDescription?: string | null;
      bumped?: boolean | null;
      soldAsIs?: boolean | null;
      usOutlet?: boolean | null;
      offersEnabled?: boolean | null;
      isBuyerOfferEligible?: boolean | null;
      currency?: string | null;
      brandSlug?: string | null;
      categoryRootUuid?: string | null;
      categoryUuids?: Array<string | null> | null;
      bumpEligible?: boolean | null;
      shopId?: string | null;
      inventory?: number | null;
      acceptedPaymentMethods?: Array<string | null> | null;
      signals?: Array<{
        __typename?: "reverb_signals_Signal";
        name?: reverb_signals_Signal_Name | null;
        title?: string | null;
        icon?: string | null;
      } | null> | null;
      watchThumbnails?: Array<{
        __typename?: "core_apimessages_Image";
        _id?: string | null;
        source?: string | null;
      } | null> | null;
      publishedAt?: {
        __typename?: "google_protobuf_Timestamp";
        seconds?: number | null;
      } | null;
      condition?: {
        __typename?: "core_apimessages_Condition";
        displayName?: string | null;
        conditionSlug?: string | null;
        conditionUuid?: string | null;
      } | null;
      pricing?: {
        __typename?: "core_apimessages_ListingPricing";
        buyerPrice?: {
          __typename?: "core_apimessages_Money";
          display?: string | null;
          currency?: string | null;
          amount?: string | null;
          amountCents?: number | null;
        } | null;
        originalPrice?: {
          __typename?: "core_apimessages_Money";
          display?: string | null;
        } | null;
        ribbon?: {
          __typename?: "core_apimessages_Ribbon";
          display?: string | null;
          reason?: core_apimessages_Ribbon_Reason | null;
        } | null;
      } | null;
      images?: Array<{
        __typename?: "core_apimessages_Image";
        source?: string | null;
      } | null> | null;
      shipping?: {
        __typename?: "core_apimessages_ShippingPrices";
        freeExpeditedShipping?: boolean | null;
        localPickupOnly?: boolean | null;
        localPickup?: boolean | null;
        shippingPrices?: Array<{
          __typename?: "core_apimessages_ShippingPrice";
          _id?: string | null;
          shippingMethod?: core_apimessages_ShippingMethod | null;
          carrierCalculated?: boolean | null;
          destinationPostalCodeNeeded?: boolean | null;
          regional?: boolean | null;
          postalCode?: string | null;
          rate?: {
            __typename?: "core_apimessages_Money";
            amount?: string | null;
            amountCents?: number | null;
            currency?: string | null;
            display?: string | null;
          } | null;
        } | null> | null;
      } | null;
      shop?: {
        __typename?: "Shop";
        _id?: string | null;
        name?: string | null;
        returnPolicy?: {
          __typename?: "core_apimessages_ReturnPolicy";
          usedReturnWindowDays?: number | null;
          newReturnWindowDays?: number | null;
          _id?: string | null;
        } | null;
        address?: {
          __typename?: "core_apimessages_Address";
          _id?: string | null;
          locality?: string | null;
          region?: string | null;
          displayLocation?: string | null;
          countryCode?: string | null;
          country?: {
            __typename?: "core_apimessages_Country";
            _id?: string | null;
            countryCode?: string | null;
            name?: string | null;
          } | null;
        } | null;
      } | null;
      priceRecommendation?: {
        __typename?: "reverb_pricing_PriceRecommendation";
        priceMiddle?: {
          __typename?: "reverb_pricing_Money";
          amountCents?: number | null;
          currency?: string | null;
        } | null;
      } | null;
      certifiedPreOwned?: {
        __typename?: "core_apimessages_ListingCertifiedPreOwned";
        title?: string | null;
        badgeIconUrl?: string | null;
      } | null;
      categories?: Array<{
        __typename?: "Category";
        _id?: string | null;
        slug?: string | null;
        rootSlug?: string | null;
      } | null> | null;
      csp?: {
        __typename?: "CSP";
        _id?: string | null;
        id?: string | null;
        slug?: string | null;
        brand?: {
          __typename?: "Brand";
          _id?: string | null;
          slug?: string | null;
        } | null;
      } | null;
      sale?: {
        __typename?: "core_apimessages_Sale";
        _id?: string | null;
        id?: string | null;
        code?: string | null;
        buyerIneligibilityReason?: string | null;
      } | null;
    } | null> | null;
    suggestions?: Array<{
      __typename?: "reverb_search_SearchResponse_Suggestion";
      text?: string | null;
    } | null> | null;
  } | null;
};

export type Core_Marketplace_MarketplaceListingsCountQueryVariables = Exact<{
  inputListings?: InputMaybe<Input_reverb_search_ListingsSearchRequest>;
}>;

export type Core_Marketplace_MarketplaceListingsCountQuery = {
  __typename?: "Query";
  listingsSearch?: {
    __typename: "reverb_search_SearchResponse";
    total?: number | null;
  } | null;
};

export type Core_Shop_MarketplaceQueryVariables = Exact<{
  shop?: InputMaybe<Scalars["String"]["input"]>;
}>;

export type Core_Shop_MarketplaceQuery = {
  __typename?: "Query";
  shop?: {
    __typename?: "Shop";
    _id?: string | null;
    name?: string | null;
    id?: string | null;
    userId?: string | null;
    onVacation?: boolean | null;
    showSold?: boolean | null;
  } | null;
};

export type MarketplaceSuggestionsFragment = {
  __typename?: "reverb_search_SearchResponse_Suggestion";
  text?: string | null;
};

export type OutletListingsFieldsFragment = {
  __typename?: "Listing";
  _id?: string | null;
  id?: string | null;
  sellerId?: string | null;
  listingType?: core_apimessages_Listing_ListingType | null;
  title?: string | null;
  slug?: string | null;
  make?: string | null;
  model?: string | null;
  upc?: string | null;
  state?: string | null;
  stateDescription?: string | null;
  bumped?: boolean | null;
  watching?: boolean | null;
  soldAsIs?: boolean | null;
  usOutlet?: boolean | null;
  offersEnabled?: boolean | null;
  isBuyerOfferEligible?: boolean | null;
  currency?: string | null;
  brandSlug?: string | null;
  categoryRootUuid?: string | null;
  categoryUuids?: Array<string | null> | null;
  bumpEligible?: boolean | null;
  shopId?: string | null;
  inventory?: number | null;
  acceptedPaymentMethods?: Array<string | null> | null;
  pricing?: {
    __typename?: "core_apimessages_ListingPricing";
    buyerPrice?: {
      __typename?: "core_apimessages_Money";
      amount?: string | null;
      amountCents?: number | null;
      display?: string | null;
      currency?: string | null;
    } | null;
    originalPrice?: {
      __typename?: "core_apimessages_Money";
      display?: string | null;
    } | null;
    ribbon?: {
      __typename?: "core_apimessages_Ribbon";
      display?: string | null;
      reason?: core_apimessages_Ribbon_Reason | null;
    } | null;
  } | null;
  preorderInfo?: {
    __typename?: "core_apimessages_PreorderInfo";
    onPreorder?: boolean | null;
    estimatedShipDate?: {
      __typename?: "google_protobuf_Timestamp";
      seconds?: number | null;
    } | null;
  } | null;
  publishedAt?: {
    __typename?: "google_protobuf_Timestamp";
    seconds?: number | null;
  } | null;
  condition?: {
    __typename?: "core_apimessages_Condition";
    displayName?: string | null;
    conditionSlug?: string | null;
    conditionUuid?: string | null;
  } | null;
  images?: Array<{
    __typename?: "core_apimessages_Image";
    source?: string | null;
  } | null> | null;
  shipping?: {
    __typename?: "core_apimessages_ShippingPrices";
    freeExpeditedShipping?: boolean | null;
    localPickupOnly?: boolean | null;
    localPickup?: boolean | null;
    shippingPrices?: Array<{
      __typename?: "core_apimessages_ShippingPrice";
      _id?: string | null;
      shippingMethod?: core_apimessages_ShippingMethod | null;
      carrierCalculated?: boolean | null;
      destinationPostalCodeNeeded?: boolean | null;
      regional?: boolean | null;
      postalCode?: string | null;
      rate?: {
        __typename?: "core_apimessages_Money";
        amount?: string | null;
        amountCents?: number | null;
        currency?: string | null;
        display?: string | null;
      } | null;
    } | null> | null;
  } | null;
  shop?: {
    __typename?: "Shop";
    _id?: string | null;
    name?: string | null;
    returnPolicy?: {
      __typename?: "core_apimessages_ReturnPolicy";
      usedReturnWindowDays?: number | null;
      newReturnWindowDays?: number | null;
      _id?: string | null;
    } | null;
    address?: {
      __typename?: "core_apimessages_Address";
      _id?: string | null;
      locality?: string | null;
      region?: string | null;
      displayLocation?: string | null;
      countryCode?: string | null;
      country?: {
        __typename?: "core_apimessages_Country";
        _id?: string | null;
        countryCode?: string | null;
        name?: string | null;
      } | null;
    } | null;
  } | null;
  watchThumbnails?: Array<{
    __typename?: "core_apimessages_Image";
    _id?: string | null;
    source?: string | null;
  } | null> | null;
  priceRecommendation?: {
    __typename?: "reverb_pricing_PriceRecommendation";
    priceMiddle?: {
      __typename?: "reverb_pricing_Money";
      amountCents?: number | null;
      currency?: string | null;
    } | null;
  } | null;
  certifiedPreOwned?: {
    __typename?: "core_apimessages_ListingCertifiedPreOwned";
    title?: string | null;
    badgeIconUrl?: string | null;
  } | null;
  categories?: Array<{
    __typename?: "Category";
    _id?: string | null;
    slug?: string | null;
    rootSlug?: string | null;
  } | null> | null;
  csp?: {
    __typename?: "CSP";
    _id?: string | null;
    id?: string | null;
    slug?: string | null;
    brand?: {
      __typename?: "Brand";
      _id?: string | null;
      slug?: string | null;
    } | null;
  } | null;
  sale?: {
    __typename?: "core_apimessages_Sale";
    _id?: string | null;
    id?: string | null;
    code?: string | null;
    buyerIneligibilityReason?: string | null;
  } | null;
};

export type Core_Listing_OutletPageGridQueryVariables = Exact<{
  autodirects?: InputMaybe<reverb_search_Autodirects>;
  acceptsAffirm?: InputMaybe<Scalars["Boolean"]["input"]>;
  acceptsOffers?: InputMaybe<Scalars["Boolean"]["input"]>;
  acceptsPaymentPlans?: InputMaybe<Scalars["Boolean"]["input"]>;
  brandSlugs?: InputMaybe<
    | Array<InputMaybe<Scalars["String"]["input"]>>
    | InputMaybe<Scalars["String"]["input"]>
  >;
  categorySlugs?: InputMaybe<
    | Array<InputMaybe<Scalars["String"]["input"]>>
    | InputMaybe<Scalars["String"]["input"]>
  >;
  combinedShipping?: InputMaybe<Scalars["Boolean"]["input"]>;
  conditionSlugs?: InputMaybe<
    | Array<InputMaybe<Scalars["String"]["input"]>>
    | InputMaybe<Scalars["String"]["input"]>
  >;
  contexts?: InputMaybe<
    Array<InputMaybe<reverb_search_Context>> | InputMaybe<reverb_search_Context>
  >;
  cspSlug?: InputMaybe<Scalars["String"]["input"]>;
  countryOfOrigin?: InputMaybe<
    | Array<InputMaybe<Scalars["String"]["input"]>>
    | InputMaybe<Scalars["String"]["input"]>
  >;
  curatedSetSlugs?: InputMaybe<
    | Array<InputMaybe<Scalars["String"]["input"]>>
    | InputMaybe<Scalars["String"]["input"]>
  >;
  currency?: InputMaybe<Scalars["String"]["input"]>;
  dealsAndSteals?: InputMaybe<Scalars["Boolean"]["input"]>;
  decades?: InputMaybe<
    | Array<InputMaybe<Scalars["String"]["input"]>>
    | InputMaybe<Scalars["String"]["input"]>
  >;
  fallbackToOr?: InputMaybe<Scalars["Boolean"]["input"]>;
  freeShipping?: InputMaybe<Scalars["Boolean"]["input"]>;
  freeExpeditedShipping?: InputMaybe<Scalars["Boolean"]["input"]>;
  handmade?: InputMaybe<Scalars["Boolean"]["input"]>;
  holidaySale?: InputMaybe<Scalars["Boolean"]["input"]>;
  itemCity?: InputMaybe<
    | Array<InputMaybe<Scalars["String"]["input"]>>
    | InputMaybe<Scalars["String"]["input"]>
  >;
  itemRegion?: InputMaybe<Scalars["String"]["input"]>;
  itemState?: InputMaybe<
    | Array<InputMaybe<Scalars["String"]["input"]>>
    | InputMaybe<Scalars["String"]["input"]>
  >;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  localPickup?: InputMaybe<Scalars["Boolean"]["input"]>;
  minSaleDiscountPercent?: InputMaybe<Scalars["String"]["input"]>;
  multiClientExperiments?: InputMaybe<
    | Array<InputMaybe<Input_reverb_search_ExperimentData>>
    | InputMaybe<Input_reverb_search_ExperimentData>
  >;
  preferredSeller?: InputMaybe<Scalars["Boolean"]["input"]>;
  priceMax?: InputMaybe<Scalars["String"]["input"]>;
  priceMin?: InputMaybe<Scalars["String"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  onSale?: InputMaybe<Scalars["Boolean"]["input"]>;
  query?: InputMaybe<Scalars["String"]["input"]>;
  saleSlugs?: InputMaybe<
    | Array<InputMaybe<Scalars["String"]["input"]>>
    | InputMaybe<Scalars["String"]["input"]>
  >;
  shippingRegionCode?: InputMaybe<
    | Array<InputMaybe<Scalars["String"]["input"]>>
    | InputMaybe<Scalars["String"]["input"]>
  >;
  showOnlySold?: InputMaybe<Scalars["Boolean"]["input"]>;
  shopSlug?: InputMaybe<Scalars["String"]["input"]>;
  showSold?: InputMaybe<Scalars["Boolean"]["input"]>;
  similarListingIds?: InputMaybe<
    | Array<InputMaybe<Scalars["String"]["input"]>>
    | InputMaybe<Scalars["String"]["input"]>
  >;
  sortSlug?: InputMaybe<Scalars["String"]["input"]>;
  terminateEarly?: InputMaybe<Scalars["Boolean"]["input"]>;
  traitValues?: InputMaybe<
    | Array<InputMaybe<Scalars["String"]["input"]>>
    | InputMaybe<Scalars["String"]["input"]>
  >;
  useExperimentalRecall?: InputMaybe<Scalars["Boolean"]["input"]>;
  withAggregations?: InputMaybe<
    | Array<InputMaybe<reverb_search_ListingsSearchRequest_Aggregation>>
    | InputMaybe<reverb_search_ListingsSearchRequest_Aggregation>
  >;
  withProximityFilter?: InputMaybe<Input_reverb_search_ProximityFilterRequest>;
  yearMax?: InputMaybe<Scalars["String"]["input"]>;
  yearMin?: InputMaybe<Scalars["String"]["input"]>;
  outlet?: InputMaybe<Scalars["Boolean"]["input"]>;
  showMostWatchedListingsSort?: InputMaybe<Scalars["Boolean"]["input"]>;
}>;

export type Core_Listing_OutletPageGridQuery = {
  __typename?: "Query";
  listingsSearch?: {
    __typename?: "reverb_search_SearchResponse";
    total?: number | null;
    offset?: number | null;
    limit?: number | null;
    listings?: Array<{
      __typename?: "Listing";
      _id?: string | null;
      id?: string | null;
      sellerId?: string | null;
      listingType?: core_apimessages_Listing_ListingType | null;
      title?: string | null;
      slug?: string | null;
      make?: string | null;
      model?: string | null;
      upc?: string | null;
      state?: string | null;
      stateDescription?: string | null;
      bumped?: boolean | null;
      watching?: boolean | null;
      soldAsIs?: boolean | null;
      usOutlet?: boolean | null;
      offersEnabled?: boolean | null;
      isBuyerOfferEligible?: boolean | null;
      currency?: string | null;
      brandSlug?: string | null;
      categoryRootUuid?: string | null;
      categoryUuids?: Array<string | null> | null;
      bumpEligible?: boolean | null;
      shopId?: string | null;
      inventory?: number | null;
      acceptedPaymentMethods?: Array<string | null> | null;
      pricing?: {
        __typename?: "core_apimessages_ListingPricing";
        buyerPrice?: {
          __typename?: "core_apimessages_Money";
          amount?: string | null;
          amountCents?: number | null;
          display?: string | null;
          currency?: string | null;
        } | null;
        originalPrice?: {
          __typename?: "core_apimessages_Money";
          display?: string | null;
        } | null;
        ribbon?: {
          __typename?: "core_apimessages_Ribbon";
          display?: string | null;
          reason?: core_apimessages_Ribbon_Reason | null;
        } | null;
      } | null;
      preorderInfo?: {
        __typename?: "core_apimessages_PreorderInfo";
        onPreorder?: boolean | null;
        estimatedShipDate?: {
          __typename?: "google_protobuf_Timestamp";
          seconds?: number | null;
        } | null;
      } | null;
      publishedAt?: {
        __typename?: "google_protobuf_Timestamp";
        seconds?: number | null;
      } | null;
      condition?: {
        __typename?: "core_apimessages_Condition";
        displayName?: string | null;
        conditionSlug?: string | null;
        conditionUuid?: string | null;
      } | null;
      images?: Array<{
        __typename?: "core_apimessages_Image";
        source?: string | null;
      } | null> | null;
      shipping?: {
        __typename?: "core_apimessages_ShippingPrices";
        freeExpeditedShipping?: boolean | null;
        localPickupOnly?: boolean | null;
        localPickup?: boolean | null;
        shippingPrices?: Array<{
          __typename?: "core_apimessages_ShippingPrice";
          _id?: string | null;
          shippingMethod?: core_apimessages_ShippingMethod | null;
          carrierCalculated?: boolean | null;
          destinationPostalCodeNeeded?: boolean | null;
          regional?: boolean | null;
          postalCode?: string | null;
          rate?: {
            __typename?: "core_apimessages_Money";
            amount?: string | null;
            amountCents?: number | null;
            currency?: string | null;
            display?: string | null;
          } | null;
        } | null> | null;
      } | null;
      shop?: {
        __typename?: "Shop";
        _id?: string | null;
        name?: string | null;
        returnPolicy?: {
          __typename?: "core_apimessages_ReturnPolicy";
          usedReturnWindowDays?: number | null;
          newReturnWindowDays?: number | null;
          _id?: string | null;
        } | null;
        address?: {
          __typename?: "core_apimessages_Address";
          _id?: string | null;
          locality?: string | null;
          region?: string | null;
          displayLocation?: string | null;
          countryCode?: string | null;
          country?: {
            __typename?: "core_apimessages_Country";
            _id?: string | null;
            countryCode?: string | null;
            name?: string | null;
          } | null;
        } | null;
      } | null;
      watchThumbnails?: Array<{
        __typename?: "core_apimessages_Image";
        _id?: string | null;
        source?: string | null;
      } | null> | null;
      priceRecommendation?: {
        __typename?: "reverb_pricing_PriceRecommendation";
        priceMiddle?: {
          __typename?: "reverb_pricing_Money";
          amountCents?: number | null;
          currency?: string | null;
        } | null;
      } | null;
      certifiedPreOwned?: {
        __typename?: "core_apimessages_ListingCertifiedPreOwned";
        title?: string | null;
        badgeIconUrl?: string | null;
      } | null;
      categories?: Array<{
        __typename?: "Category";
        _id?: string | null;
        slug?: string | null;
        rootSlug?: string | null;
      } | null> | null;
      csp?: {
        __typename?: "CSP";
        _id?: string | null;
        id?: string | null;
        slug?: string | null;
        brand?: {
          __typename?: "Brand";
          _id?: string | null;
          slug?: string | null;
        } | null;
      } | null;
      sale?: {
        __typename?: "core_apimessages_Sale";
        _id?: string | null;
        id?: string | null;
        code?: string | null;
        buyerIneligibilityReason?: string | null;
      } | null;
    } | null> | null;
    filters?: Array<{
      __typename?: "reverb_search_Filter";
      name?: string | null;
      key?: string | null;
      aggregationName?: string | null;
      widgetType?: reverb_search_Filter_WidgetType | null;
      options?: Array<{
        __typename?: "reverb_search_FilterOption";
        selected?: boolean | null;
        autoselected?: boolean | null;
        name?: string | null;
        paramName?: string | null;
        setValues?: Array<string | null> | null;
        unsetValues?: Array<string | null> | null;
        all?: boolean | null;
        optionValue?: string | null;
        trackingValue?: string | null;
        count?: {
          __typename?: "google_protobuf_Int32Value";
          value?: number | null;
        } | null;
        subFilter?: {
          __typename?: "reverb_search_Filter";
          widgetType?: reverb_search_Filter_WidgetType | null;
          options?: Array<{
            __typename?: "reverb_search_FilterOption";
            name?: string | null;
            selected?: boolean | null;
            autoselected?: boolean | null;
            paramName?: string | null;
            setValues?: Array<string | null> | null;
            unsetValues?: Array<string | null> | null;
            all?: boolean | null;
            optionValue?: string | null;
            trackingValue?: string | null;
            count?: {
              __typename?: "google_protobuf_Int32Value";
              value?: number | null;
            } | null;
            subFilter?: {
              __typename?: "reverb_search_Filter";
              widgetType?: reverb_search_Filter_WidgetType | null;
              options?: Array<{
                __typename?: "reverb_search_FilterOption";
                name?: string | null;
                selected?: boolean | null;
                autoselected?: boolean | null;
                paramName?: string | null;
                setValues?: Array<string | null> | null;
                unsetValues?: Array<string | null> | null;
                all?: boolean | null;
                optionValue?: string | null;
                trackingValue?: string | null;
                count?: {
                  __typename?: "google_protobuf_Int32Value";
                  value?: number | null;
                } | null;
                subFilter?: {
                  __typename?: "reverb_search_Filter";
                  widgetType?: reverb_search_Filter_WidgetType | null;
                  options?: Array<{
                    __typename?: "reverb_search_FilterOption";
                    name?: string | null;
                    selected?: boolean | null;
                    autoselected?: boolean | null;
                    paramName?: string | null;
                    setValues?: Array<string | null> | null;
                    unsetValues?: Array<string | null> | null;
                    all?: boolean | null;
                    optionValue?: string | null;
                    trackingValue?: string | null;
                    count?: {
                      __typename?: "google_protobuf_Int32Value";
                      value?: number | null;
                    } | null;
                  } | null> | null;
                } | null;
              } | null> | null;
            } | null;
          } | null> | null;
        } | null;
      } | null> | null;
    } | null> | null;
  } | null;
};

export type Core_Packlink_AccountStatusQueryVariables = Exact<{
  [key: string]: never;
}>;

export type Core_Packlink_AccountStatusQuery = {
  __typename?: "Query";
  me?: {
    __typename?: "rql_Me";
    _id?: string | null;
    uuid?: string | null;
    shop?: {
      __typename?: "MyShop";
      _id?: string | null;
      packlink?: {
        __typename?: "core_apimessages_MyPacklinkAccountStatusResponse";
        account?: {
          __typename?: "core_apimessages_PacklinkAccount";
          email?: string | null;
          status?: core_apimessages_PacklinkAccount_Status | null;
        } | null;
      } | null;
    } | null;
  } | null;
};

export type OrderPacklinkShipmentDataFragment = {
  __typename?: "Order";
  _id?: string | null;
  legacyOrderId?: string | null;
  packlinkShipment?: {
    __typename?: "core_apimessages_PacklinkShipment";
    reference?: string | null;
    status?: core_apimessages_PacklinkShipment_Status | null;
  } | null;
};

export type MyShopPacklinkAccountDataFragment = {
  __typename?: "rql_Me";
  _id?: string | null;
  uuid?: string | null;
  shop?: {
    __typename?: "MyShop";
    _id?: string | null;
    packlink?: {
      __typename?: "core_apimessages_MyPacklinkAccountStatusResponse";
      account?: {
        __typename?: "core_apimessages_PacklinkAccount";
        status?: core_apimessages_PacklinkAccount_Status | null;
      } | null;
    } | null;
  } | null;
};

export type Core_Packlink_ShippingLabelButtonQueryVariables = Exact<{
  orderId?: InputMaybe<Scalars["String"]["input"]>;
  authOnly: Scalars["Boolean"]["input"];
}>;

export type Core_Packlink_ShippingLabelButtonQuery = {
  __typename?: "Query";
  order?: {
    __typename?: "Order";
    _id?: string | null;
    legacyOrderId?: string | null;
    packlinkShipment?: {
      __typename?: "core_apimessages_PacklinkShipment";
      reference?: string | null;
      status?: core_apimessages_PacklinkShipment_Status | null;
    } | null;
  } | null;
  me?: {
    __typename?: "rql_Me";
    _id?: string | null;
    uuid?: string | null;
    shop?: {
      __typename?: "MyShop";
      _id?: string | null;
      packlink?: {
        __typename?: "core_apimessages_MyPacklinkAccountStatusResponse";
        account?: {
          __typename?: "core_apimessages_PacklinkAccount";
          status?: core_apimessages_PacklinkAccount_Status | null;
        } | null;
      } | null;
    } | null;
  } | null;
};

export type Core_Packlink_CreateDraftMutationVariables = Exact<{
  input?: InputMaybe<Input_core_apimessages_CreatePacklinkDraftRequest>;
}>;

export type Core_Packlink_CreateDraftMutation = {
  __typename?: "Mutation";
  createPacklinkDraft?: {
    __typename?: "core_apimessages_CreatePacklinkDraftResponse";
    shipment?: {
      __typename?: "core_apimessages_PacklinkShipment";
      id?: string | null;
    } | null;
  } | null;
};

export type Core_Packlink_LoginAccountMutationVariables = Exact<{
  input?: InputMaybe<Input_core_apimessages_LoginMyPacklinkAccountRequest>;
}>;

export type Core_Packlink_LoginAccountMutation = {
  __typename?: "Mutation";
  loginMyPacklinkAccount?: {
    __typename?: "core_apimessages_LoginMyPacklinkAccountResponse";
    shopId?: string | null;
  } | null;
};

export type Core_Packlink_RegisterAccountMutationVariables = Exact<{
  input?: InputMaybe<Input_core_apimessages_RegisterMyPacklinkAccountRequest>;
}>;

export type Core_Packlink_RegisterAccountMutation = {
  __typename?: "Mutation";
  registerMyPacklinkAccount?: {
    __typename?: "core_apimessages_RegisterMyPacklinkAccountResponse";
    shopId?: string | null;
  } | null;
};

export type myShopCountryFragment = {
  __typename?: "rql_Me";
  _id?: string | null;
  uuid?: string | null;
  shop?: {
    __typename?: "MyShop";
    _id?: string | null;
    originCountryCode?: string | null;
  } | null;
};

export type PayStatementCountriesQueryVariables = Exact<{
  [key: string]: never;
}>;

export type PayStatementCountriesQuery = {
  __typename?: "Query";
  me?: {
    __typename?: "rql_Me";
    _id?: string | null;
    uuid?: string | null;
    shop?: {
      __typename?: "MyShop";
      _id?: string | null;
      originCountryCode?: string | null;
    } | null;
  } | null;
  countries?: {
    __typename?: "core_apimessages_CountriesResponse";
    countries?: Array<{
      __typename?: "core_apimessages_Country";
      _id?: string | null;
      countryCode?: string | null;
      name?: string | null;
    } | null> | null;
  } | null;
};

export type PayStatementCreditCardListFieldsFragment = {
  __typename?: "rql_Me";
  _id?: string | null;
  creditCards?: Array<{
    __typename?: "core_apimessages_CreditCard";
    id?: string | null;
    cardType?: string | null;
    needsReverification?: boolean | null;
    primaryBilling?: boolean | null;
    display?: {
      __typename?: "core_apimessages_CreditCard_Display";
      summary?: string | null;
      expirationDate?: string | null;
    } | null;
    brand?: {
      __typename: "core_apimessages_CreditCard_Brand";
      type?: core_apimessages_CreditCard_Brand_Type | null;
      name?: string | null;
    } | null;
  } | null> | null;
};

export type CreditCardListQueryQueryVariables = Exact<{ [key: string]: never }>;

export type CreditCardListQueryQuery = {
  __typename?: "Query";
  me?: {
    __typename?: "rql_Me";
    _id?: string | null;
    uuid?: string | null;
    creditCards?: Array<{
      __typename?: "core_apimessages_CreditCard";
      id?: string | null;
      cardType?: string | null;
      needsReverification?: boolean | null;
      primaryBilling?: boolean | null;
      display?: {
        __typename?: "core_apimessages_CreditCard_Display";
        summary?: string | null;
        expirationDate?: string | null;
      } | null;
      brand?: {
        __typename: "core_apimessages_CreditCard_Brand";
        type?: core_apimessages_CreditCard_Brand_Type | null;
        name?: string | null;
      } | null;
    } | null> | null;
  } | null;
};

export type PayMyStatementMutationVariables = Exact<{
  input?: InputMaybe<Input_core_apimessages_PayMyStatementRequest>;
}>;

export type PayMyStatementMutation = {
  __typename?: "Mutation";
  payMyStatement?: {
    __typename?: "core_apimessages_PayMyStatementResponse";
    statementId?: string | null;
    statementBalance?: number | null;
    amountPaidCents?: number | null;
  } | null;
};

export type PhotoTileDataFragment = {
  __typename?: "Listing";
  _id?: string | null;
  id?: string | null;
  slug?: string | null;
  title?: string | null;
  images?: Array<{
    __typename?: "core_apimessages_Image";
    source?: string | null;
  } | null> | null;
};

export type Core_Payouts_CreateMyPlaidLinkTokenMutationVariables = Exact<{
  input?: InputMaybe<Input_core_apimessages_CreateMyPlaidLinkTokenRequest>;
}>;

export type Core_Payouts_CreateMyPlaidLinkTokenMutation = {
  __typename?: "Mutation";
  createMyPlaidLinkToken?: {
    __typename?: "core_apimessages_CreateMyPlaidLinkTokenResponse";
    linkToken?: string | null;
  } | null;
};

export type Core_Payouts_SaveMyPlaidAccountMutationVariables = Exact<{
  input?: InputMaybe<Input_core_apimessages_SaveMyPlaidAccountRequest>;
}>;

export type Core_Payouts_SaveMyPlaidAccountMutation = {
  __typename?: "Mutation";
  saveMyPlaidAccount?: {
    __typename?: "core_apimessages_SaveMyPlaidAccountResponse";
    plaidAccountId?: string | null;
  } | null;
};

export type Core_Payouts_CancelPlaidAccountMutationVariables = Exact<{
  input?: InputMaybe<Input_core_apimessages_CancelPlaidAccountRequest>;
}>;

export type Core_Payouts_CancelPlaidAccountMutation = {
  __typename?: "Mutation";
  cancelPlaidAccount?: {
    __typename?: "core_apimessages_CancelPlaidAccountResponse";
    shopId?: string | null;
  } | null;
};

export type DataServices_PriceGuide_CspEstimatesQueryVariables = Exact<{
  cspPriceRecommendationQueries?: InputMaybe<
    | Array<InputMaybe<Input_reverb_pricing_CSPPriceRecommendationQuery>>
    | InputMaybe<Input_reverb_pricing_CSPPriceRecommendationQuery>
  >;
}>;

export type DataServices_PriceGuide_CspEstimatesQuery = {
  __typename?: "Query";
  cspPriceRecommendations?: {
    __typename?: "reverb_pricing_PriceRecommendationsResponse";
    priceRecommendations?: Array<{
      __typename?: "reverb_pricing_PriceRecommendation";
      conditionUuid?: string | null;
      priceLow?: {
        __typename?: "reverb_pricing_Money";
        amountCents?: number | null;
        currency?: string | null;
      } | null;
      priceMiddle?: {
        __typename?: "reverb_pricing_Money";
        amountCents?: number | null;
        currency?: string | null;
      } | null;
      priceHigh?: {
        __typename?: "reverb_pricing_Money";
        amountCents?: number | null;
        currency?: string | null;
      } | null;
      priceLowThirtyDaysAgo?: {
        __typename?: "reverb_pricing_Money";
        amountCents?: number | null;
        currency?: string | null;
      } | null;
      priceMiddleThirtyDaysAgo?: {
        __typename?: "reverb_pricing_Money";
        amountCents?: number | null;
        currency?: string | null;
      } | null;
      priceHighThirtyDaysAgo?: {
        __typename?: "reverb_pricing_Money";
        amountCents?: number | null;
        currency?: string | null;
      } | null;
    } | null> | null;
  } | null;
};

export type DataServices_PriceGuide_CpEstimatesQueryVariables = Exact<{
  priceRecommendationQueries?: InputMaybe<
    | Array<InputMaybe<Input_reverb_pricing_PriceRecommendationQuery>>
    | InputMaybe<Input_reverb_pricing_PriceRecommendationQuery>
  >;
}>;

export type DataServices_PriceGuide_CpEstimatesQuery = {
  __typename?: "Query";
  priceRecommendations?: {
    __typename?: "reverb_pricing_PriceRecommendationsResponse";
    priceRecommendations?: Array<{
      __typename?: "reverb_pricing_PriceRecommendation";
      conditionUuid?: string | null;
      priceLow?: {
        __typename?: "reverb_pricing_Money";
        amountCents?: number | null;
        currency?: string | null;
      } | null;
      priceMiddle?: {
        __typename?: "reverb_pricing_Money";
        amountCents?: number | null;
        currency?: string | null;
      } | null;
      priceHigh?: {
        __typename?: "reverb_pricing_Money";
        amountCents?: number | null;
        currency?: string | null;
      } | null;
      priceLowThirtyDaysAgo?: {
        __typename?: "reverb_pricing_Money";
        amountCents?: number | null;
        currency?: string | null;
      } | null;
      priceMiddleThirtyDaysAgo?: {
        __typename?: "reverb_pricing_Money";
        amountCents?: number | null;
        currency?: string | null;
      } | null;
      priceHighThirtyDaysAgo?: {
        __typename?: "reverb_pricing_Money";
        amountCents?: number | null;
        currency?: string | null;
      } | null;
    } | null> | null;
  } | null;
};

export type Search_PriceGuide_TotalOrdersQueryVariables = Exact<{
  canonicalProductIds?: InputMaybe<
    | Array<InputMaybe<Scalars["String"]["input"]>>
    | InputMaybe<Scalars["String"]["input"]>
  >;
  sellerCountries?: InputMaybe<
    | Array<InputMaybe<Scalars["String"]["input"]>>
    | InputMaybe<Scalars["String"]["input"]>
  >;
  conditionSlugs?: InputMaybe<
    | Array<InputMaybe<Scalars["String"]["input"]>>
    | InputMaybe<Scalars["String"]["input"]>
  >;
  actionableStatuses?: InputMaybe<
    | Array<InputMaybe<Scalars["String"]["input"]>>
    | InputMaybe<Scalars["String"]["input"]>
  >;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
}>;

export type Search_PriceGuide_TotalOrdersQuery = {
  __typename?: "Query";
  priceRecordsSearch?: {
    __typename?: "reverb_search_SearchResponse";
    total?: number | null;
    offset?: number | null;
  } | null;
};

export type TransactionTablePriceRecordsDataFragment = {
  __typename?: "PublicPriceRecord";
  _id?: string | null;
  condition?: {
    __typename?: "core_apimessages_Condition";
    displayName?: string | null;
  } | null;
  createdAt?: {
    __typename?: "google_protobuf_Timestamp";
    seconds?: number | null;
  } | null;
  amountProduct?: {
    __typename?: "core_apimessages_Money";
    display?: string | null;
  } | null;
};

export type Search_PriceGuideTool_TransactionTableQueryVariables = Exact<{
  canonicalProductIds?: InputMaybe<
    | Array<InputMaybe<Scalars["String"]["input"]>>
    | InputMaybe<Scalars["String"]["input"]>
  >;
  sellerCountries?: InputMaybe<
    | Array<InputMaybe<Scalars["String"]["input"]>>
    | InputMaybe<Scalars["String"]["input"]>
  >;
  conditionSlugs?: InputMaybe<
    | Array<InputMaybe<Scalars["String"]["input"]>>
    | InputMaybe<Scalars["String"]["input"]>
  >;
  createdAfterDate?: InputMaybe<Scalars["String"]["input"]>;
  actionableStatuses?: InputMaybe<
    | Array<InputMaybe<Scalars["String"]["input"]>>
    | InputMaybe<Scalars["String"]["input"]>
  >;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
}>;

export type Search_PriceGuideTool_TransactionTableQuery = {
  __typename?: "Query";
  priceRecordsSearch?: {
    __typename?: "reverb_search_SearchResponse";
    total?: number | null;
    offset?: number | null;
    priceRecords?: Array<{
      __typename?: "PublicPriceRecord";
      _id?: string | null;
      condition?: {
        __typename?: "core_apimessages_Condition";
        displayName?: string | null;
      } | null;
      createdAt?: {
        __typename?: "google_protobuf_Timestamp";
        seconds?: number | null;
      } | null;
      amountProduct?: {
        __typename?: "core_apimessages_Money";
        display?: string | null;
      } | null;
    } | null> | null;
  } | null;
};

export type DataServices_PriceGuideToolEstimatesContainerQueryVariables =
  Exact<{
    priceRecommendationQueries?: InputMaybe<
      | Array<InputMaybe<Input_reverb_pricing_PriceRecommendationQuery>>
      | InputMaybe<Input_reverb_pricing_PriceRecommendationQuery>
    >;
  }>;

export type DataServices_PriceGuideToolEstimatesContainerQuery = {
  __typename?: "Query";
  priceRecommendations?: {
    __typename?: "reverb_pricing_PriceRecommendationsResponse";
    priceRecommendations?: Array<{
      __typename?: "reverb_pricing_PriceRecommendation";
      priceLow?: {
        __typename?: "reverb_pricing_Money";
        amountCents?: number | null;
        currency?: string | null;
      } | null;
      priceMiddle?: {
        __typename?: "reverb_pricing_Money";
        amountCents?: number | null;
        currency?: string | null;
      } | null;
      priceHigh?: {
        __typename?: "reverb_pricing_Money";
        amountCents?: number | null;
        currency?: string | null;
      } | null;
      priceMiddleThirtyDaysAgo?: {
        __typename?: "reverb_pricing_Money";
        amountCents?: number | null;
        currency?: string | null;
      } | null;
    } | null> | null;
  } | null;
};

export type PriceGuideToolFormContainerDataFragment = {
  __typename?: "CSP";
  _id?: string | null;
  id?: string | null;
  title?: string | null;
  categoryRootUuid?: string | null;
  categoryUuids?: Array<string | null> | null;
  slug?: string | null;
  brand?: {
    __typename?: "Brand";
    _id?: string | null;
    id?: string | null;
    name?: string | null;
  } | null;
  image?: {
    __typename?: "core_apimessages_Image";
    _id?: string | null;
    source?: string | null;
  } | null;
  canonicalProducts?: Array<{
    __typename?: "CanonicalProduct";
    _id?: string | null;
    id?: string | null;
    finish?: string | null;
    model?: string | null;
    name?: string | null;
    year?: string | null;
  } | null> | null;
};

export type Core_PriceGuideToolFormContainerQueryVariables = Exact<{
  cspSlug?: InputMaybe<Scalars["String"]["input"]>;
}>;

export type Core_PriceGuideToolFormContainerQuery = {
  __typename?: "Query";
  csp?: {
    __typename?: "CSP";
    _id?: string | null;
    id?: string | null;
    title?: string | null;
    categoryRootUuid?: string | null;
    categoryUuids?: Array<string | null> | null;
    slug?: string | null;
    brand?: {
      __typename?: "Brand";
      _id?: string | null;
      id?: string | null;
      name?: string | null;
    } | null;
    image?: {
      __typename?: "core_apimessages_Image";
      _id?: string | null;
      source?: string | null;
    } | null;
    canonicalProducts?: Array<{
      __typename?: "CanonicalProduct";
      _id?: string | null;
      id?: string | null;
      finish?: string | null;
      model?: string | null;
      name?: string | null;
      year?: string | null;
    } | null> | null;
  } | null;
};

export type Search_PriceGuideTool_GearCollectionItemsQueryVariables = Exact<{
  canonicalProductIds?: InputMaybe<
    | Array<InputMaybe<Scalars["String"]["input"]>>
    | InputMaybe<Scalars["String"]["input"]>
  >;
}>;

export type Search_PriceGuideTool_GearCollectionItemsQuery = {
  __typename?: "Query";
  gearCollectionItemsSearch?: {
    __typename?: "reverb_search_SearchResponse";
    gearCollectionItems?: Array<{
      __typename?: "GearCollectionItem";
      _id?: string | null;
      id?: string | null;
    } | null> | null;
  } | null;
};

export type Search_PriceGuideToolTransactionGraphQueryVariables = Exact<{
  canonicalProductIds?: InputMaybe<
    | Array<InputMaybe<Scalars["String"]["input"]>>
    | InputMaybe<Scalars["String"]["input"]>
  >;
  sellerCountries?: InputMaybe<
    | Array<InputMaybe<Scalars["String"]["input"]>>
    | InputMaybe<Scalars["String"]["input"]>
  >;
  conditionUuids?: InputMaybe<
    | Array<InputMaybe<Scalars["String"]["input"]>>
    | InputMaybe<Scalars["String"]["input"]>
  >;
  createdAfterDate?: InputMaybe<Scalars["String"]["input"]>;
  actionableStatuses?: InputMaybe<
    | Array<InputMaybe<Scalars["String"]["input"]>>
    | InputMaybe<Scalars["String"]["input"]>
  >;
}>;

export type Search_PriceGuideToolTransactionGraphQuery = {
  __typename?: "Query";
  priceRecordsSearch?: {
    __typename?: "reverb_search_SearchResponse";
    averageMonthlyProductPrices?: Array<{
      __typename?: "reverb_search_AverageMonthlyProductPrice";
      date?: string | null;
      docCount?: number | null;
      averageProductPrice?: {
        __typename?: "reverb_search_Money";
        amount?: string | null;
        amountCents?: number | null;
        currency?: string | null;
        display?: string | null;
      } | null;
    } | null> | null;
  } | null;
};

export type PriceWithShippingSortListingDataFragment = {
  __typename?: "Listing";
  _id?: string | null;
  id?: string | null;
  pricing?: {
    __typename?: "core_apimessages_ListingPricing";
    buyerPrice?: {
      __typename?: "core_apimessages_Money";
      amountCents?: number | null;
    } | null;
  } | null;
  shipping?: {
    __typename?: "core_apimessages_ShippingPrices";
    shippingPrices?: Array<{
      __typename?: "core_apimessages_ShippingPrice";
      _id?: string | null;
      shippingMethod?: core_apimessages_ShippingMethod | null;
      rate?: {
        __typename?: "core_apimessages_Money";
        amountCents?: number | null;
      } | null;
    } | null> | null;
  } | null;
};

export type CMSPageEducationSectionDataFragment = {
  __typename?: "CMSPage";
  _id?: string | null;
  slug?: string | null;
  title?: string | null;
  image?: { __typename?: "cms_PageImage"; imageUrl?: string | null } | null;
};

export type ProductFamilyHeaderFieldsFragment = {
  __typename?: "CSP";
  _id?: string | null;
  brand?: {
    __typename?: "Brand";
    _id?: string | null;
    slug?: string | null;
    name?: string | null;
  } | null;
  categories?: Array<{
    __typename?: "Category";
    _id?: string | null;
    parent?: {
      __typename?: "core_apimessages_Category";
      _id?: string | null;
      slug?: string | null;
      name?: string | null;
      cmsLink?: {
        __typename?: "core_apimessages_Link";
        href?: string | null;
      } | null;
    } | null;
  } | null> | null;
};

export type Core_ProductFamilyPage_LayoutQueryVariables = Exact<{
  query?: InputMaybe<Scalars["String"]["input"]>;
  traitSlugs?: InputMaybe<
    | Array<InputMaybe<Scalars["String"]["input"]>>
    | InputMaybe<Scalars["String"]["input"]>
  >;
  listingsRowLimit?: InputMaybe<Scalars["Int"]["input"]>;
  listingsRowCuratedSetSlugs?: InputMaybe<
    | Array<InputMaybe<Scalars["String"]["input"]>>
    | InputMaybe<Scalars["String"]["input"]>
  >;
  listingsGridLimit?: InputMaybe<Scalars["Int"]["input"]>;
  listingsGridOffset?: InputMaybe<Scalars["Int"]["input"]>;
  currency?: InputMaybe<Scalars["String"]["input"]>;
  priceMax?: InputMaybe<Scalars["String"]["input"]>;
  priceMin?: InputMaybe<Scalars["String"]["input"]>;
  yearMax?: InputMaybe<Scalars["String"]["input"]>;
  yearMin?: InputMaybe<Scalars["String"]["input"]>;
  sortSlug?: InputMaybe<Scalars["String"]["input"]>;
  categorySlugs?: InputMaybe<
    | Array<InputMaybe<Scalars["String"]["input"]>>
    | InputMaybe<Scalars["String"]["input"]>
  >;
  brandSlugs?: InputMaybe<
    | Array<InputMaybe<Scalars["String"]["input"]>>
    | InputMaybe<Scalars["String"]["input"]>
  >;
  aggs?: InputMaybe<
    | Array<InputMaybe<reverb_search_ListingsSearchRequest_Aggregation>>
    | InputMaybe<reverb_search_ListingsSearchRequest_Aggregation>
  >;
  conditionSlugs?: InputMaybe<
    | Array<InputMaybe<Scalars["String"]["input"]>>
    | InputMaybe<Scalars["String"]["input"]>
  >;
  onSale?: InputMaybe<Scalars["Boolean"]["input"]>;
  handmade?: InputMaybe<Scalars["Boolean"]["input"]>;
  freeShipping?: InputMaybe<Scalars["Boolean"]["input"]>;
  freeExpeditedShipping?: InputMaybe<Scalars["Boolean"]["input"]>;
  acceptsOffers?: InputMaybe<Scalars["Boolean"]["input"]>;
  acceptsGiftCards?: InputMaybe<Scalars["Boolean"]["input"]>;
  preferredSeller?: InputMaybe<Scalars["Boolean"]["input"]>;
  acceptsPaymentPlans?: InputMaybe<Scalars["Boolean"]["input"]>;
  itemRegion?: InputMaybe<Scalars["String"]["input"]>;
  shippingRegionCodes?: InputMaybe<
    | Array<InputMaybe<Scalars["String"]["input"]>>
    | InputMaybe<Scalars["String"]["input"]>
  >;
  decades?: InputMaybe<
    | Array<InputMaybe<Scalars["String"]["input"]>>
    | InputMaybe<Scalars["String"]["input"]>
  >;
  cspSort?: InputMaybe<reverb_search_CSPSearchRequest_Sort>;
  localPickup?: InputMaybe<Scalars["Boolean"]["input"]>;
  combinedShipping?: InputMaybe<Scalars["Boolean"]["input"]>;
  acceptsAffirm?: InputMaybe<Scalars["Boolean"]["input"]>;
  showOnlySold?: InputMaybe<Scalars["Boolean"]["input"]>;
  withProximityFilter?: InputMaybe<Input_reverb_search_ProximityFilterRequest>;
  traitValues?: InputMaybe<
    | Array<InputMaybe<Scalars["String"]["input"]>>
    | InputMaybe<Scalars["String"]["input"]>
  >;
  dealsOrWellPriced?: InputMaybe<Scalars["Boolean"]["input"]>;
  slug?: InputMaybe<Scalars["String"]["input"]>;
  shouldUseListingsGrid: Scalars["Boolean"]["input"];
}>;

export type Core_ProductFamilyPage_LayoutQuery = {
  __typename?: "Query";
  cspSearch?: {
    __typename?: "reverb_search_SearchResponse";
    csps?: Array<{
      __typename?: "CSP";
      _id?: string | null;
      id?: string | null;
      title?: string | null;
      slug?: string | null;
      averageReviewRating?: number | null;
      reviewsCount?: number | null;
      finishes?: Array<string | null> | null;
      webLink?: {
        __typename?: "core_apimessages_Link";
        path?: string | null;
        href?: string | null;
      } | null;
      image?: {
        __typename?: "core_apimessages_Image";
        _id?: string | null;
        source?: string | null;
      } | null;
      brand?: {
        __typename?: "Brand";
        _id?: string | null;
        slug?: string | null;
        name?: string | null;
      } | null;
      categories?: Array<{
        __typename?: "Category";
        _id?: string | null;
        slug?: string | null;
        rootSlug?: string | null;
        name?: string | null;
        parentCategories?: Array<{
          __typename?: "Category";
          _id?: string | null;
          name?: string | null;
          slug?: string | null;
        } | null> | null;
        parent?: {
          __typename?: "core_apimessages_Category";
          _id?: string | null;
          slug?: string | null;
          name?: string | null;
          cmsLink?: {
            __typename?: "core_apimessages_Link";
            href?: string | null;
          } | null;
        } | null;
      } | null> | null;
      inventory?: {
        __typename?: "core_apimessages_CSPInventory";
        usedTotal?: number | null;
        newTotal?: number | null;
        newLowPrice?: {
          __typename?: "core_apimessages_Money";
          display?: string | null;
          amount?: string | null;
          amountCents?: number | null;
          currency?: string | null;
        } | null;
        usedLowPrice?: {
          __typename?: "core_apimessages_Money";
          display?: string | null;
          amount?: string | null;
          amountCents?: number | null;
          currency?: string | null;
        } | null;
      } | null;
    } | null> | null;
  } | null;
  listingsRowSearch?: {
    __typename?: "reverb_search_SearchResponse";
    listings?: Array<{
      __typename?: "Listing";
      _id?: string | null;
      id?: string | null;
      title?: string | null;
      slug?: string | null;
      listingType?: core_apimessages_Listing_ListingType | null;
      make?: string | null;
      model?: string | null;
      upc?: string | null;
      state?: string | null;
      stateDescription?: string | null;
      bumped?: boolean | null;
      watching?: boolean | null;
      soldAsIs?: boolean | null;
      usOutlet?: boolean | null;
      sellerId?: string | null;
      offersEnabled?: boolean | null;
      isBuyerOfferEligible?: boolean | null;
      currency?: string | null;
      brandSlug?: string | null;
      categoryRootUuid?: string | null;
      categoryUuids?: Array<string | null> | null;
      bumpEligible?: boolean | null;
      shopId?: string | null;
      inventory?: number | null;
      acceptedPaymentMethods?: Array<string | null> | null;
      publishedAt?: {
        __typename?: "google_protobuf_Timestamp";
        seconds?: number | null;
      } | null;
      condition?: {
        __typename?: "core_apimessages_Condition";
        displayName?: string | null;
        conditionSlug?: string | null;
        conditionUuid?: string | null;
      } | null;
      pricing?: {
        __typename?: "core_apimessages_ListingPricing";
        buyerPrice?: {
          __typename?: "core_apimessages_Money";
          display?: string | null;
          currency?: string | null;
          amount?: string | null;
          amountCents?: number | null;
        } | null;
        originalPrice?: {
          __typename?: "core_apimessages_Money";
          display?: string | null;
        } | null;
        ribbon?: {
          __typename?: "core_apimessages_Ribbon";
          display?: string | null;
          reason?: core_apimessages_Ribbon_Reason | null;
        } | null;
      } | null;
      images?: Array<{
        __typename?: "core_apimessages_Image";
        source?: string | null;
      } | null> | null;
      shipping?: {
        __typename?: "core_apimessages_ShippingPrices";
        freeExpeditedShipping?: boolean | null;
        localPickupOnly?: boolean | null;
        localPickup?: boolean | null;
        shippingPrices?: Array<{
          __typename?: "core_apimessages_ShippingPrice";
          _id?: string | null;
          shippingMethod?: core_apimessages_ShippingMethod | null;
          carrierCalculated?: boolean | null;
          destinationPostalCodeNeeded?: boolean | null;
          regional?: boolean | null;
          postalCode?: string | null;
          rate?: {
            __typename?: "core_apimessages_Money";
            amount?: string | null;
            amountCents?: number | null;
            currency?: string | null;
            display?: string | null;
          } | null;
        } | null> | null;
      } | null;
      shop?: {
        __typename?: "Shop";
        _id?: string | null;
        name?: string | null;
        returnPolicy?: {
          __typename?: "core_apimessages_ReturnPolicy";
          usedReturnWindowDays?: number | null;
          newReturnWindowDays?: number | null;
          _id?: string | null;
        } | null;
        address?: {
          __typename?: "core_apimessages_Address";
          _id?: string | null;
          locality?: string | null;
          region?: string | null;
          displayLocation?: string | null;
          countryCode?: string | null;
          country?: {
            __typename?: "core_apimessages_Country";
            _id?: string | null;
            countryCode?: string | null;
            name?: string | null;
          } | null;
        } | null;
      } | null;
      watchThumbnails?: Array<{
        __typename?: "core_apimessages_Image";
        _id?: string | null;
        source?: string | null;
      } | null> | null;
      priceRecommendation?: {
        __typename?: "reverb_pricing_PriceRecommendation";
        priceMiddle?: {
          __typename?: "reverb_pricing_Money";
          amountCents?: number | null;
          currency?: string | null;
        } | null;
      } | null;
      certifiedPreOwned?: {
        __typename?: "core_apimessages_ListingCertifiedPreOwned";
        title?: string | null;
        badgeIconUrl?: string | null;
      } | null;
      categories?: Array<{
        __typename?: "Category";
        _id?: string | null;
        slug?: string | null;
        rootSlug?: string | null;
      } | null> | null;
      csp?: {
        __typename?: "CSP";
        _id?: string | null;
        id?: string | null;
        slug?: string | null;
        brand?: {
          __typename?: "Brand";
          _id?: string | null;
          slug?: string | null;
        } | null;
      } | null;
      sale?: {
        __typename?: "core_apimessages_Sale";
        _id?: string | null;
        id?: string | null;
        code?: string | null;
        buyerIneligibilityReason?: string | null;
      } | null;
    } | null> | null;
  } | null;
  listingsGridSearch?: {
    __typename?: "reverb_search_SearchResponse";
    total?: number | null;
    offset?: number | null;
    limit?: number | null;
    filters?: Array<{
      __typename?: "reverb_search_Filter";
      name?: string | null;
      key?: string | null;
      aggregationName?: string | null;
      widgetType?: reverb_search_Filter_WidgetType | null;
      options?: Array<{
        __typename?: "reverb_search_FilterOption";
        name?: string | null;
        selected?: boolean | null;
        autoselected?: boolean | null;
        paramName?: string | null;
        setValues?: Array<string | null> | null;
        unsetValues?: Array<string | null> | null;
        all?: boolean | null;
        optionValue?: string | null;
        trackingValue?: string | null;
        count?: {
          __typename?: "google_protobuf_Int32Value";
          value?: number | null;
        } | null;
        subFilter?: {
          __typename?: "reverb_search_Filter";
          widgetType?: reverb_search_Filter_WidgetType | null;
          options?: Array<{
            __typename?: "reverb_search_FilterOption";
            name?: string | null;
            selected?: boolean | null;
            autoselected?: boolean | null;
            paramName?: string | null;
            setValues?: Array<string | null> | null;
            unsetValues?: Array<string | null> | null;
            all?: boolean | null;
            optionValue?: string | null;
            trackingValue?: string | null;
            count?: {
              __typename?: "google_protobuf_Int32Value";
              value?: number | null;
            } | null;
            subFilter?: {
              __typename?: "reverb_search_Filter";
              widgetType?: reverb_search_Filter_WidgetType | null;
              options?: Array<{
                __typename?: "reverb_search_FilterOption";
                name?: string | null;
                selected?: boolean | null;
                autoselected?: boolean | null;
                paramName?: string | null;
                setValues?: Array<string | null> | null;
                unsetValues?: Array<string | null> | null;
                all?: boolean | null;
                optionValue?: string | null;
                trackingValue?: string | null;
                count?: {
                  __typename?: "google_protobuf_Int32Value";
                  value?: number | null;
                } | null;
                subFilter?: {
                  __typename?: "reverb_search_Filter";
                  widgetType?: reverb_search_Filter_WidgetType | null;
                  options?: Array<{
                    __typename?: "reverb_search_FilterOption";
                    name?: string | null;
                    selected?: boolean | null;
                    autoselected?: boolean | null;
                    paramName?: string | null;
                    setValues?: Array<string | null> | null;
                    unsetValues?: Array<string | null> | null;
                    all?: boolean | null;
                    optionValue?: string | null;
                    trackingValue?: string | null;
                    count?: {
                      __typename?: "google_protobuf_Int32Value";
                      value?: number | null;
                    } | null;
                  } | null> | null;
                } | null;
              } | null> | null;
            } | null;
          } | null> | null;
        } | null;
      } | null> | null;
    } | null> | null;
    listings?: Array<{
      __typename?: "Listing";
      _id?: string | null;
      id?: string | null;
      title?: string | null;
      slug?: string | null;
      listingType?: core_apimessages_Listing_ListingType | null;
      make?: string | null;
      model?: string | null;
      upc?: string | null;
      state?: string | null;
      stateDescription?: string | null;
      bumped?: boolean | null;
      watching?: boolean | null;
      soldAsIs?: boolean | null;
      usOutlet?: boolean | null;
      sellerId?: string | null;
      offersEnabled?: boolean | null;
      isBuyerOfferEligible?: boolean | null;
      currency?: string | null;
      brandSlug?: string | null;
      categoryRootUuid?: string | null;
      categoryUuids?: Array<string | null> | null;
      bumpEligible?: boolean | null;
      shopId?: string | null;
      inventory?: number | null;
      acceptedPaymentMethods?: Array<string | null> | null;
      publishedAt?: {
        __typename?: "google_protobuf_Timestamp";
        seconds?: number | null;
      } | null;
      condition?: {
        __typename?: "core_apimessages_Condition";
        displayName?: string | null;
        conditionSlug?: string | null;
        conditionUuid?: string | null;
      } | null;
      pricing?: {
        __typename?: "core_apimessages_ListingPricing";
        buyerPrice?: {
          __typename?: "core_apimessages_Money";
          display?: string | null;
          currency?: string | null;
          amount?: string | null;
          amountCents?: number | null;
        } | null;
        originalPrice?: {
          __typename?: "core_apimessages_Money";
          display?: string | null;
        } | null;
        ribbon?: {
          __typename?: "core_apimessages_Ribbon";
          display?: string | null;
          reason?: core_apimessages_Ribbon_Reason | null;
        } | null;
      } | null;
      images?: Array<{
        __typename?: "core_apimessages_Image";
        source?: string | null;
      } | null> | null;
      shipping?: {
        __typename?: "core_apimessages_ShippingPrices";
        freeExpeditedShipping?: boolean | null;
        localPickupOnly?: boolean | null;
        localPickup?: boolean | null;
        shippingPrices?: Array<{
          __typename?: "core_apimessages_ShippingPrice";
          _id?: string | null;
          shippingMethod?: core_apimessages_ShippingMethod | null;
          carrierCalculated?: boolean | null;
          destinationPostalCodeNeeded?: boolean | null;
          regional?: boolean | null;
          postalCode?: string | null;
          rate?: {
            __typename?: "core_apimessages_Money";
            amount?: string | null;
            amountCents?: number | null;
            currency?: string | null;
            display?: string | null;
          } | null;
        } | null> | null;
      } | null;
      shop?: {
        __typename?: "Shop";
        _id?: string | null;
        name?: string | null;
        returnPolicy?: {
          __typename?: "core_apimessages_ReturnPolicy";
          usedReturnWindowDays?: number | null;
          newReturnWindowDays?: number | null;
          _id?: string | null;
        } | null;
        address?: {
          __typename?: "core_apimessages_Address";
          _id?: string | null;
          locality?: string | null;
          region?: string | null;
          displayLocation?: string | null;
          countryCode?: string | null;
          country?: {
            __typename?: "core_apimessages_Country";
            _id?: string | null;
            countryCode?: string | null;
            name?: string | null;
          } | null;
        } | null;
      } | null;
      watchThumbnails?: Array<{
        __typename?: "core_apimessages_Image";
        _id?: string | null;
        source?: string | null;
      } | null> | null;
      priceRecommendation?: {
        __typename?: "reverb_pricing_PriceRecommendation";
        priceMiddle?: {
          __typename?: "reverb_pricing_Money";
          amountCents?: number | null;
          currency?: string | null;
        } | null;
      } | null;
      certifiedPreOwned?: {
        __typename?: "core_apimessages_ListingCertifiedPreOwned";
        title?: string | null;
        badgeIconUrl?: string | null;
      } | null;
      categories?: Array<{
        __typename?: "Category";
        _id?: string | null;
        slug?: string | null;
        rootSlug?: string | null;
      } | null> | null;
      csp?: {
        __typename?: "CSP";
        _id?: string | null;
        id?: string | null;
        slug?: string | null;
        brand?: {
          __typename?: "Brand";
          _id?: string | null;
          slug?: string | null;
        } | null;
      } | null;
      sale?: {
        __typename?: "core_apimessages_Sale";
        _id?: string | null;
        id?: string | null;
        code?: string | null;
        buyerIneligibilityReason?: string | null;
      } | null;
    } | null> | null;
  } | null;
  cmsPage?: Array<{
    __typename?: "CMSPage";
    _id?: string | null;
    title?: string | null;
    metaTitle?: string | null;
    metaDescription?: string | null;
  } | null> | null;
};

export type Core_Update_Universal_Promo_CampaignMutationVariables = Exact<{
  input?: InputMaybe<Input_core_apimessages_UpdateUniversalPromoCampaignRequest>;
}>;

export type Core_Update_Universal_Promo_CampaignMutation = {
  __typename?: "Mutation";
  updateUniversalPromoCampaign?: {
    __typename?: "core_apimessages_UpdateUniversalPromoCampaignResponse";
    universalPromoCampaign?: {
      __typename?: "core_apimessages_UniversalPromoCampaign";
      id?: string | null;
    } | null;
  } | null;
};

export type Core_Universal_Promo_CampaignQueryVariables = Exact<{
  campaignId?: InputMaybe<Scalars["String"]["input"]>;
}>;

export type Core_Universal_Promo_CampaignQuery = {
  __typename?: "Query";
  universalpromocampaign?: {
    __typename?: "UniversalPromoCampaign";
    _id?: string | null;
    id?: string | null;
    name?: string | null;
    code?: string | null;
    expiresAt?: any | null;
    marketingChannel?: string | null;
    newBuyer?: boolean | null;
    lapsedBuyer?: boolean | null;
    lapsedBuyerLastPurchaseDate?: any | null;
    redemptionLimit?: number | null;
    hasRedemptionLimit?: boolean | null;
    categories?: Array<{
      __typename?: "core_apimessages_NodesCategory";
      id?: string | null;
    } | null> | null;
    universalPromoCurrencies?: Array<{
      __typename?: "core_apimessages_UniversalPromoCurrency";
      amount?: {
        __typename?: "core_apimessages_Money";
        currency?: string | null;
        amount?: string | null;
      } | null;
      minPurchaseAmount?: {
        __typename?: "core_apimessages_Money";
        currency?: string | null;
        amount?: string | null;
      } | null;
    } | null> | null;
  } | null;
};

export type Core_RecentlyViewedCspsRowQueryVariables = Exact<{
  [key: string]: never;
}>;

export type Core_RecentlyViewedCspsRowQuery = {
  __typename?: "Query";
  recentlyViewedListings?: Array<{
    __typename?: "Listing";
    _id?: string | null;
    csp?: {
      __typename?: "CSP";
      _id?: string | null;
      id?: string | null;
      title?: string | null;
      slug?: string | null;
      averageReviewRating?: number | null;
      reviewsCount?: number | null;
      finishes?: Array<string | null> | null;
      webLink?: {
        __typename?: "core_apimessages_Link";
        path?: string | null;
        href?: string | null;
      } | null;
      image?: {
        __typename?: "core_apimessages_Image";
        _id?: string | null;
        source?: string | null;
      } | null;
      brand?: {
        __typename?: "Brand";
        _id?: string | null;
        slug?: string | null;
        name?: string | null;
      } | null;
      categories?: Array<{
        __typename?: "Category";
        _id?: string | null;
        slug?: string | null;
        rootSlug?: string | null;
        name?: string | null;
        parentCategories?: Array<{
          __typename?: "Category";
          _id?: string | null;
          name?: string | null;
          slug?: string | null;
        } | null> | null;
      } | null> | null;
      inventory?: {
        __typename?: "core_apimessages_CSPInventory";
        usedTotal?: number | null;
        newTotal?: number | null;
        newLowPrice?: {
          __typename?: "core_apimessages_Money";
          display?: string | null;
          amount?: string | null;
          amountCents?: number | null;
          currency?: string | null;
        } | null;
        usedLowPrice?: {
          __typename?: "core_apimessages_Money";
          display?: string | null;
          amount?: string | null;
          amountCents?: number | null;
          currency?: string | null;
        } | null;
      } | null;
    } | null;
  } | null> | null;
};

export type Core_RecommendedCspsRowQueryVariables = Exact<{
  [key: string]: never;
}>;

export type Core_RecommendedCspsRowQuery = {
  __typename?: "Query";
  me?: {
    __typename?: "rql_Me";
    _id?: string | null;
    uuid?: string | null;
    recommendedListings?: {
      __typename?: "reverb_search_SearchResponse";
      listings?: Array<{
        __typename?: "Listing";
        _id?: string | null;
        csp?: {
          __typename?: "CSP";
          _id?: string | null;
          id?: string | null;
          title?: string | null;
          slug?: string | null;
          averageReviewRating?: number | null;
          reviewsCount?: number | null;
          finishes?: Array<string | null> | null;
          webLink?: {
            __typename?: "core_apimessages_Link";
            path?: string | null;
            href?: string | null;
          } | null;
          image?: {
            __typename?: "core_apimessages_Image";
            _id?: string | null;
            source?: string | null;
          } | null;
          brand?: {
            __typename?: "Brand";
            _id?: string | null;
            slug?: string | null;
            name?: string | null;
          } | null;
          categories?: Array<{
            __typename?: "Category";
            _id?: string | null;
            slug?: string | null;
            rootSlug?: string | null;
            name?: string | null;
            parentCategories?: Array<{
              __typename?: "Category";
              _id?: string | null;
              name?: string | null;
              slug?: string | null;
            } | null> | null;
          } | null> | null;
          inventory?: {
            __typename?: "core_apimessages_CSPInventory";
            usedTotal?: number | null;
            newTotal?: number | null;
            newLowPrice?: {
              __typename?: "core_apimessages_Money";
              display?: string | null;
              amount?: string | null;
              amountCents?: number | null;
              currency?: string | null;
            } | null;
            usedLowPrice?: {
              __typename?: "core_apimessages_Money";
              display?: string | null;
              amount?: string | null;
              amountCents?: number | null;
              currency?: string | null;
            } | null;
          } | null;
        } | null;
      } | null> | null;
    } | null;
  } | null;
};

export type MeRecommendedListingsGridQueryVariables = Exact<{
  query?: InputMaybe<Scalars["String"]["input"]>;
  currency?: InputMaybe<Scalars["String"]["input"]>;
  priceMax?: InputMaybe<Scalars["String"]["input"]>;
  priceMin?: InputMaybe<Scalars["String"]["input"]>;
  decades?: InputMaybe<
    | Array<InputMaybe<Scalars["String"]["input"]>>
    | InputMaybe<Scalars["String"]["input"]>
  >;
  yearMax?: InputMaybe<Scalars["String"]["input"]>;
  yearMin?: InputMaybe<Scalars["String"]["input"]>;
  sortSlug?: InputMaybe<Scalars["String"]["input"]>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  categorySlugs?: InputMaybe<
    | Array<InputMaybe<Scalars["String"]["input"]>>
    | InputMaybe<Scalars["String"]["input"]>
  >;
  brandSlugs?: InputMaybe<
    | Array<InputMaybe<Scalars["String"]["input"]>>
    | InputMaybe<Scalars["String"]["input"]>
  >;
  aggs?: InputMaybe<
    | Array<InputMaybe<reverb_search_ListingsSearchRequest_Aggregation>>
    | InputMaybe<reverb_search_ListingsSearchRequest_Aggregation>
  >;
  conditionSlugs?: InputMaybe<
    | Array<InputMaybe<Scalars["String"]["input"]>>
    | InputMaybe<Scalars["String"]["input"]>
  >;
  onSale?: InputMaybe<Scalars["Boolean"]["input"]>;
  handmade?: InputMaybe<Scalars["Boolean"]["input"]>;
  freeShipping?: InputMaybe<Scalars["Boolean"]["input"]>;
  freeExpeditedShipping?: InputMaybe<Scalars["Boolean"]["input"]>;
  acceptsOffers?: InputMaybe<Scalars["Boolean"]["input"]>;
  acceptsGiftCards?: InputMaybe<Scalars["Boolean"]["input"]>;
  preferredSeller?: InputMaybe<Scalars["Boolean"]["input"]>;
  acceptsPaymentPlans?: InputMaybe<Scalars["Boolean"]["input"]>;
  itemRegion?: InputMaybe<Scalars["String"]["input"]>;
  shippingRegionCode?: InputMaybe<
    | Array<InputMaybe<Scalars["String"]["input"]>>
    | InputMaybe<Scalars["String"]["input"]>
  >;
  showOnlySold?: InputMaybe<Scalars["Boolean"]["input"]>;
  showSold?: InputMaybe<Scalars["Boolean"]["input"]>;
  traitValues?: InputMaybe<
    | Array<InputMaybe<Scalars["String"]["input"]>>
    | InputMaybe<Scalars["String"]["input"]>
  >;
  itemState?: InputMaybe<
    | Array<InputMaybe<Scalars["String"]["input"]>>
    | InputMaybe<Scalars["String"]["input"]>
  >;
  itemCity?: InputMaybe<
    | Array<InputMaybe<Scalars["String"]["input"]>>
    | InputMaybe<Scalars["String"]["input"]>
  >;
  shopSlug?: InputMaybe<Scalars["String"]["input"]>;
  curatedSetSlugs?: InputMaybe<
    | Array<InputMaybe<Scalars["String"]["input"]>>
    | InputMaybe<Scalars["String"]["input"]>
  >;
  saleSlugs?: InputMaybe<
    | Array<InputMaybe<Scalars["String"]["input"]>>
    | InputMaybe<Scalars["String"]["input"]>
  >;
  cspSlug?: InputMaybe<Scalars["String"]["input"]>;
  sort?: InputMaybe<reverb_search_ListingsSearchRequest_Sort>;
  combinedShipping?: InputMaybe<Scalars["Boolean"]["input"]>;
  acceptsAffirm?: InputMaybe<Scalars["Boolean"]["input"]>;
  terminateEarly?: InputMaybe<Scalars["Boolean"]["input"]>;
  collapsible?: InputMaybe<reverb_search_ListingsSearchRequest_Collapsible>;
  fallbackToOr?: InputMaybe<Scalars["Boolean"]["input"]>;
  withProximityFilter?: InputMaybe<Input_reverb_search_ProximityFilterRequest>;
  localPickup?: InputMaybe<Scalars["Boolean"]["input"]>;
  shouldSkipTracking: Scalars["Boolean"]["input"];
  version?: InputMaybe<Scalars["Int"]["input"]>;
}>;

export type MeRecommendedListingsGridQuery = {
  __typename?: "Query";
  me?: {
    __typename?: "rql_Me";
    _id?: string | null;
    uuid?: string | null;
    recommendedListings?: {
      __typename?: "reverb_search_SearchResponse";
      total?: number | null;
      offset?: number | null;
      limit?: number | null;
      filters?: Array<{
        __typename?: "reverb_search_Filter";
        name?: string | null;
        key?: string | null;
        aggregationName?: string | null;
        widgetType?: reverb_search_Filter_WidgetType | null;
        options?: Array<{
          __typename?: "reverb_search_FilterOption";
          name?: string | null;
          selected?: boolean | null;
          autoselected?: boolean | null;
          paramName?: string | null;
          setValues?: Array<string | null> | null;
          unsetValues?: Array<string | null> | null;
          all?: boolean | null;
          optionValue?: string | null;
          trackingValue?: string | null;
          count?: {
            __typename?: "google_protobuf_Int32Value";
            value?: number | null;
          } | null;
          subFilter?: {
            __typename?: "reverb_search_Filter";
            widgetType?: reverb_search_Filter_WidgetType | null;
            options?: Array<{
              __typename?: "reverb_search_FilterOption";
              name?: string | null;
              selected?: boolean | null;
              autoselected?: boolean | null;
              paramName?: string | null;
              setValues?: Array<string | null> | null;
              unsetValues?: Array<string | null> | null;
              all?: boolean | null;
              optionValue?: string | null;
              trackingValue?: string | null;
              count?: {
                __typename?: "google_protobuf_Int32Value";
                value?: number | null;
              } | null;
              subFilter?: {
                __typename?: "reverb_search_Filter";
                widgetType?: reverb_search_Filter_WidgetType | null;
                options?: Array<{
                  __typename?: "reverb_search_FilterOption";
                  name?: string | null;
                  selected?: boolean | null;
                  autoselected?: boolean | null;
                  paramName?: string | null;
                  setValues?: Array<string | null> | null;
                  unsetValues?: Array<string | null> | null;
                  all?: boolean | null;
                  optionValue?: string | null;
                  trackingValue?: string | null;
                  count?: {
                    __typename?: "google_protobuf_Int32Value";
                    value?: number | null;
                  } | null;
                  subFilter?: {
                    __typename?: "reverb_search_Filter";
                    widgetType?: reverb_search_Filter_WidgetType | null;
                    options?: Array<{
                      __typename?: "reverb_search_FilterOption";
                      name?: string | null;
                      selected?: boolean | null;
                      autoselected?: boolean | null;
                      paramName?: string | null;
                      setValues?: Array<string | null> | null;
                      unsetValues?: Array<string | null> | null;
                      all?: boolean | null;
                      optionValue?: string | null;
                      trackingValue?: string | null;
                      count?: {
                        __typename?: "google_protobuf_Int32Value";
                        value?: number | null;
                      } | null;
                    } | null> | null;
                  } | null;
                } | null> | null;
              } | null;
            } | null> | null;
          } | null;
        } | null> | null;
      } | null> | null;
      listings?: Array<{
        __typename?: "Listing";
        _id?: string | null;
        id?: string | null;
        usOutlet?: boolean | null;
        bumped?: boolean | null;
        categoryUuids?: Array<string | null> | null;
        slug?: string | null;
        title?: string | null;
        description?: string | null;
        listingType?: core_apimessages_Listing_ListingType | null;
        watching?: boolean | null;
        state?: string | null;
        stateDescription?: string | null;
        brandSlug?: string | null;
        categoryRootUuid?: string | null;
        make?: string | null;
        bumpEligible?: boolean | null;
        shopId?: string | null;
        inventory?: number | null;
        soldAsIs?: boolean | null;
        acceptedPaymentMethods?: Array<string | null> | null;
        currency?: string | null;
        sellerId?: string | null;
        offersEnabled?: boolean | null;
        isBuyerOfferEligible?: boolean | null;
        condition?: {
          __typename?: "core_apimessages_Condition";
          displayName?: string | null;
          conditionSlug?: string | null;
          conditionUuid?: string | null;
        } | null;
        price?: {
          __typename?: "core_apimessages_Money";
          amountCents?: number | null;
          display?: string | null;
        } | null;
        pricing?: {
          __typename?: "core_apimessages_ListingPricing";
          buyerPrice?: {
            __typename?: "core_apimessages_Money";
            display?: string | null;
            currency?: string | null;
            amountCents?: number | null;
            amount?: string | null;
          } | null;
          originalPrice?: {
            __typename?: "core_apimessages_Money";
            display?: string | null;
          } | null;
          ribbon?: {
            __typename?: "core_apimessages_Ribbon";
            display?: string | null;
            reason?: core_apimessages_Ribbon_Reason | null;
          } | null;
        } | null;
        shipping?: {
          __typename?: "core_apimessages_ShippingPrices";
          freeExpeditedShipping?: boolean | null;
          localPickupOnly?: boolean | null;
          localPickup?: boolean | null;
          shippingPrices?: Array<{
            __typename?: "core_apimessages_ShippingPrice";
            _id?: string | null;
            shippingMethod?: core_apimessages_ShippingMethod | null;
            carrierCalculated?: boolean | null;
            destinationPostalCodeNeeded?: boolean | null;
            rate?: {
              __typename?: "core_apimessages_Money";
              amount?: string | null;
              amountCents?: number | null;
              currency?: string | null;
              display?: string | null;
            } | null;
          } | null> | null;
        } | null;
        shop?: {
          __typename?: "Shop";
          _id?: string | null;
          address?: {
            __typename?: "core_apimessages_Address";
            _id?: string | null;
            countryCode?: string | null;
            country?: {
              __typename?: "core_apimessages_Country";
              _id?: string | null;
              countryCode?: string | null;
              name?: string | null;
            } | null;
          } | null;
          returnPolicy?: {
            __typename?: "core_apimessages_ReturnPolicy";
            _id?: string | null;
            newReturnWindowDays?: number | null;
            usedReturnWindowDays?: number | null;
          } | null;
        } | null;
        categories?: Array<{
          __typename?: "Category";
          _id?: string | null;
          slug?: string | null;
          rootSlug?: string | null;
        } | null> | null;
        csp?: {
          __typename?: "CSP";
          _id?: string | null;
          id?: string | null;
          slug?: string | null;
          brand?: {
            __typename?: "Brand";
            _id?: string | null;
            slug?: string | null;
          } | null;
        } | null;
        publishedAt?: {
          __typename?: "google_protobuf_Timestamp";
          seconds?: number | null;
        } | null;
        sale?: {
          __typename?: "core_apimessages_Sale";
          _id?: string | null;
          id?: string | null;
          code?: string | null;
          buyerIneligibilityReason?: string | null;
        } | null;
        certifiedPreOwned?: {
          __typename?: "core_apimessages_ListingCertifiedPreOwned";
          title?: string | null;
          badgeIconUrl?: string | null;
        } | null;
        preorderInfo?: {
          __typename?: "core_apimessages_PreorderInfo";
          onPreorder?: boolean | null;
          estimatedShipDate?: {
            __typename?: "google_protobuf_Timestamp";
            seconds?: number | null;
          } | null;
        } | null;
        watchThumbnails?: Array<{
          __typename?: "core_apimessages_Image";
          _id?: string | null;
          source?: string | null;
        } | null> | null;
        priceRecommendation?: {
          __typename?: "reverb_pricing_PriceRecommendation";
          priceMiddle?: {
            __typename?: "reverb_pricing_Money";
            amountCents?: number | null;
            currency?: string | null;
          } | null;
        } | null;
        images?: Array<{
          __typename?: "core_apimessages_Image";
          _id?: string | null;
          source?: string | null;
        } | null> | null;
        largeSquareImages?: Array<{
          __typename?: "core_apimessages_Image";
          _id?: string | null;
          source?: string | null;
        } | null> | null;
      } | null> | null;
    } | null;
  } | null;
};

export type MeRecommendedListingsQueryVariables = Exact<{
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  shouldSkipTracking: Scalars["Boolean"]["input"];
  version?: InputMaybe<Scalars["Int"]["input"]>;
  multiClientExperiments?: InputMaybe<
    | Array<InputMaybe<Input_reverb_search_ExperimentData>>
    | InputMaybe<Input_reverb_search_ExperimentData>
  >;
}>;

export type MeRecommendedListingsQuery = {
  __typename?: "Query";
  me?: {
    __typename?: "rql_Me";
    _id?: string | null;
    uuid?: string | null;
    recommendedListings?: {
      __typename?: "reverb_search_SearchResponse";
      listings?: Array<{
        __typename?: "Listing";
        _id?: string | null;
        id?: string | null;
        usOutlet?: boolean | null;
        bumped?: boolean | null;
        categoryUuids?: Array<string | null> | null;
        slug?: string | null;
        title?: string | null;
        description?: string | null;
        listingType?: core_apimessages_Listing_ListingType | null;
        watching?: boolean | null;
        state?: string | null;
        stateDescription?: string | null;
        brandSlug?: string | null;
        categoryRootUuid?: string | null;
        make?: string | null;
        bumpEligible?: boolean | null;
        shopId?: string | null;
        inventory?: number | null;
        soldAsIs?: boolean | null;
        acceptedPaymentMethods?: Array<string | null> | null;
        currency?: string | null;
        sellerId?: string | null;
        offersEnabled?: boolean | null;
        isBuyerOfferEligible?: boolean | null;
        condition?: {
          __typename?: "core_apimessages_Condition";
          displayName?: string | null;
          conditionSlug?: string | null;
          conditionUuid?: string | null;
        } | null;
        price?: {
          __typename?: "core_apimessages_Money";
          amountCents?: number | null;
          display?: string | null;
        } | null;
        pricing?: {
          __typename?: "core_apimessages_ListingPricing";
          buyerPrice?: {
            __typename?: "core_apimessages_Money";
            display?: string | null;
            currency?: string | null;
            amountCents?: number | null;
            amount?: string | null;
          } | null;
          originalPrice?: {
            __typename?: "core_apimessages_Money";
            display?: string | null;
          } | null;
          ribbon?: {
            __typename?: "core_apimessages_Ribbon";
            display?: string | null;
            reason?: core_apimessages_Ribbon_Reason | null;
          } | null;
        } | null;
        shipping?: {
          __typename?: "core_apimessages_ShippingPrices";
          freeExpeditedShipping?: boolean | null;
          localPickupOnly?: boolean | null;
          localPickup?: boolean | null;
          shippingPrices?: Array<{
            __typename?: "core_apimessages_ShippingPrice";
            _id?: string | null;
            shippingMethod?: core_apimessages_ShippingMethod | null;
            carrierCalculated?: boolean | null;
            destinationPostalCodeNeeded?: boolean | null;
            rate?: {
              __typename?: "core_apimessages_Money";
              amount?: string | null;
              amountCents?: number | null;
              currency?: string | null;
              display?: string | null;
            } | null;
          } | null> | null;
        } | null;
        shop?: {
          __typename?: "Shop";
          _id?: string | null;
          address?: {
            __typename?: "core_apimessages_Address";
            _id?: string | null;
            countryCode?: string | null;
            country?: {
              __typename?: "core_apimessages_Country";
              _id?: string | null;
              countryCode?: string | null;
              name?: string | null;
            } | null;
          } | null;
          returnPolicy?: {
            __typename?: "core_apimessages_ReturnPolicy";
            _id?: string | null;
            newReturnWindowDays?: number | null;
            usedReturnWindowDays?: number | null;
          } | null;
        } | null;
        categories?: Array<{
          __typename?: "Category";
          _id?: string | null;
          slug?: string | null;
          rootSlug?: string | null;
        } | null> | null;
        csp?: {
          __typename?: "CSP";
          _id?: string | null;
          id?: string | null;
          slug?: string | null;
          brand?: {
            __typename?: "Brand";
            _id?: string | null;
            slug?: string | null;
          } | null;
        } | null;
        publishedAt?: {
          __typename?: "google_protobuf_Timestamp";
          seconds?: number | null;
        } | null;
        sale?: {
          __typename?: "core_apimessages_Sale";
          _id?: string | null;
          id?: string | null;
          code?: string | null;
          buyerIneligibilityReason?: string | null;
        } | null;
        certifiedPreOwned?: {
          __typename?: "core_apimessages_ListingCertifiedPreOwned";
          title?: string | null;
          badgeIconUrl?: string | null;
        } | null;
        preorderInfo?: {
          __typename?: "core_apimessages_PreorderInfo";
          onPreorder?: boolean | null;
          estimatedShipDate?: {
            __typename?: "google_protobuf_Timestamp";
            seconds?: number | null;
          } | null;
        } | null;
        watchThumbnails?: Array<{
          __typename?: "core_apimessages_Image";
          _id?: string | null;
          source?: string | null;
        } | null> | null;
        priceRecommendation?: {
          __typename?: "reverb_pricing_PriceRecommendation";
          priceMiddle?: {
            __typename?: "reverb_pricing_Money";
            amountCents?: number | null;
            currency?: string | null;
          } | null;
        } | null;
        images?: Array<{
          __typename?: "core_apimessages_Image";
          _id?: string | null;
          source?: string | null;
        } | null> | null;
        largeSquareImages?: Array<{
          __typename?: "core_apimessages_Image";
          _id?: string | null;
          source?: string | null;
        } | null> | null;
      } | null> | null;
    } | null;
  } | null;
};

export type CPListingsRecommendationsQueryVariables = Exact<{
  listingsLimit?: InputMaybe<Scalars["Int"]["input"]>;
  recsLimit?: InputMaybe<Scalars["Int"]["input"]>;
  shouldSkipTracking: Scalars["Boolean"]["input"];
  sort?: InputMaybe<reverb_search_ListingsSearchRequest_Sort>;
  collapsible?: InputMaybe<reverb_search_ListingsSearchRequest_Collapsible>;
  shippingRegionCodes?: InputMaybe<
    | Array<InputMaybe<Scalars["String"]["input"]>>
    | InputMaybe<Scalars["String"]["input"]>
  >;
  multiClientExperiments?: InputMaybe<
    | Array<InputMaybe<Input_rql_ExperimentData>>
    | InputMaybe<Input_rql_ExperimentData>
  >;
}>;

export type CPListingsRecommendationsQuery = {
  __typename?: "Query";
  cpToListingRecs?: {
    __typename?: "rql_RecommendationsGenericResponse";
    recommendationEngineInputIds?: Array<string | null> | null;
    recommendationEngineOutputIds?: Array<string | null> | null;
    searchResponse?: {
      __typename?: "reverb_search_SearchResponse";
      limit?: number | null;
      total?: number | null;
      listings?: Array<{
        __typename?: "Listing";
        _id?: string | null;
        id?: string | null;
        usOutlet?: boolean | null;
        bumped?: boolean | null;
        categoryUuids?: Array<string | null> | null;
        slug?: string | null;
        title?: string | null;
        description?: string | null;
        listingType?: core_apimessages_Listing_ListingType | null;
        watching?: boolean | null;
        state?: string | null;
        stateDescription?: string | null;
        brandSlug?: string | null;
        categoryRootUuid?: string | null;
        make?: string | null;
        bumpEligible?: boolean | null;
        shopId?: string | null;
        inventory?: number | null;
        soldAsIs?: boolean | null;
        acceptedPaymentMethods?: Array<string | null> | null;
        currency?: string | null;
        sellerId?: string | null;
        offersEnabled?: boolean | null;
        isBuyerOfferEligible?: boolean | null;
        condition?: {
          __typename?: "core_apimessages_Condition";
          displayName?: string | null;
          conditionSlug?: string | null;
          conditionUuid?: string | null;
        } | null;
        price?: {
          __typename?: "core_apimessages_Money";
          amountCents?: number | null;
          display?: string | null;
        } | null;
        pricing?: {
          __typename?: "core_apimessages_ListingPricing";
          buyerPrice?: {
            __typename?: "core_apimessages_Money";
            display?: string | null;
            currency?: string | null;
            amountCents?: number | null;
            amount?: string | null;
          } | null;
          originalPrice?: {
            __typename?: "core_apimessages_Money";
            display?: string | null;
          } | null;
          ribbon?: {
            __typename?: "core_apimessages_Ribbon";
            display?: string | null;
            reason?: core_apimessages_Ribbon_Reason | null;
          } | null;
        } | null;
        shipping?: {
          __typename?: "core_apimessages_ShippingPrices";
          freeExpeditedShipping?: boolean | null;
          localPickupOnly?: boolean | null;
          localPickup?: boolean | null;
          shippingPrices?: Array<{
            __typename?: "core_apimessages_ShippingPrice";
            _id?: string | null;
            shippingMethod?: core_apimessages_ShippingMethod | null;
            carrierCalculated?: boolean | null;
            destinationPostalCodeNeeded?: boolean | null;
            rate?: {
              __typename?: "core_apimessages_Money";
              amount?: string | null;
              amountCents?: number | null;
              currency?: string | null;
              display?: string | null;
            } | null;
          } | null> | null;
        } | null;
        shop?: {
          __typename?: "Shop";
          _id?: string | null;
          address?: {
            __typename?: "core_apimessages_Address";
            _id?: string | null;
            countryCode?: string | null;
            country?: {
              __typename?: "core_apimessages_Country";
              _id?: string | null;
              countryCode?: string | null;
              name?: string | null;
            } | null;
          } | null;
          returnPolicy?: {
            __typename?: "core_apimessages_ReturnPolicy";
            _id?: string | null;
            newReturnWindowDays?: number | null;
            usedReturnWindowDays?: number | null;
          } | null;
        } | null;
        categories?: Array<{
          __typename?: "Category";
          _id?: string | null;
          slug?: string | null;
          rootSlug?: string | null;
        } | null> | null;
        csp?: {
          __typename?: "CSP";
          _id?: string | null;
          id?: string | null;
          slug?: string | null;
          brand?: {
            __typename?: "Brand";
            _id?: string | null;
            slug?: string | null;
          } | null;
        } | null;
        publishedAt?: {
          __typename?: "google_protobuf_Timestamp";
          seconds?: number | null;
        } | null;
        sale?: {
          __typename?: "core_apimessages_Sale";
          _id?: string | null;
          id?: string | null;
          code?: string | null;
          buyerIneligibilityReason?: string | null;
        } | null;
        certifiedPreOwned?: {
          __typename?: "core_apimessages_ListingCertifiedPreOwned";
          title?: string | null;
          badgeIconUrl?: string | null;
        } | null;
        preorderInfo?: {
          __typename?: "core_apimessages_PreorderInfo";
          onPreorder?: boolean | null;
          estimatedShipDate?: {
            __typename?: "google_protobuf_Timestamp";
            seconds?: number | null;
          } | null;
        } | null;
        watchThumbnails?: Array<{
          __typename?: "core_apimessages_Image";
          _id?: string | null;
          source?: string | null;
        } | null> | null;
        priceRecommendation?: {
          __typename?: "reverb_pricing_PriceRecommendation";
          priceMiddle?: {
            __typename?: "reverb_pricing_Money";
            amountCents?: number | null;
            currency?: string | null;
          } | null;
        } | null;
        images?: Array<{
          __typename?: "core_apimessages_Image";
          _id?: string | null;
          source?: string | null;
        } | null> | null;
        largeSquareImages?: Array<{
          __typename?: "core_apimessages_Image";
          _id?: string | null;
          source?: string | null;
        } | null> | null;
      } | null> | null;
    } | null;
  } | null;
};

export type LoggedOutRecommendationsQueryVariables = Exact<{
  mpid?: InputMaybe<Scalars["String"]["input"]>;
}>;

export type LoggedOutRecommendationsQuery = {
  __typename?: "Query";
  loggedOutRecommendations?: {
    __typename?: "reverb_search_SearchResponse";
    resultIds?: Array<string | null> | null;
    listings?: Array<{
      __typename?: "Listing";
      _id?: string | null;
      id?: string | null;
      usOutlet?: boolean | null;
      bumped?: boolean | null;
      categoryUuids?: Array<string | null> | null;
      slug?: string | null;
      title?: string | null;
      description?: string | null;
      listingType?: core_apimessages_Listing_ListingType | null;
      watching?: boolean | null;
      state?: string | null;
      stateDescription?: string | null;
      sellerId?: string | null;
      currency?: string | null;
      offersEnabled?: boolean | null;
      isBuyerOfferEligible?: boolean | null;
      brandSlug?: string | null;
      categoryRootUuid?: string | null;
      make?: string | null;
      bumpEligible?: boolean | null;
      shopId?: string | null;
      inventory?: number | null;
      soldAsIs?: boolean | null;
      acceptedPaymentMethods?: Array<string | null> | null;
      condition?: {
        __typename?: "core_apimessages_Condition";
        displayName?: string | null;
        conditionSlug?: string | null;
        conditionUuid?: string | null;
      } | null;
      price?: {
        __typename?: "core_apimessages_Money";
        amountCents?: number | null;
        display?: string | null;
      } | null;
      pricing?: {
        __typename?: "core_apimessages_ListingPricing";
        buyerPrice?: {
          __typename?: "core_apimessages_Money";
          display?: string | null;
          currency?: string | null;
          amountCents?: number | null;
          amount?: string | null;
        } | null;
        originalPrice?: {
          __typename?: "core_apimessages_Money";
          display?: string | null;
        } | null;
        ribbon?: {
          __typename?: "core_apimessages_Ribbon";
          display?: string | null;
          reason?: core_apimessages_Ribbon_Reason | null;
        } | null;
      } | null;
      shipping?: {
        __typename?: "core_apimessages_ShippingPrices";
        freeExpeditedShipping?: boolean | null;
        localPickupOnly?: boolean | null;
        localPickup?: boolean | null;
        shippingPrices?: Array<{
          __typename?: "core_apimessages_ShippingPrice";
          _id?: string | null;
          shippingMethod?: core_apimessages_ShippingMethod | null;
          carrierCalculated?: boolean | null;
          destinationPostalCodeNeeded?: boolean | null;
          rate?: {
            __typename?: "core_apimessages_Money";
            amount?: string | null;
            amountCents?: number | null;
            currency?: string | null;
            display?: string | null;
          } | null;
        } | null> | null;
      } | null;
      shop?: {
        __typename?: "Shop";
        _id?: string | null;
        address?: {
          __typename?: "core_apimessages_Address";
          _id?: string | null;
          countryCode?: string | null;
          country?: {
            __typename?: "core_apimessages_Country";
            _id?: string | null;
            countryCode?: string | null;
            name?: string | null;
          } | null;
        } | null;
        returnPolicy?: {
          __typename?: "core_apimessages_ReturnPolicy";
          _id?: string | null;
          newReturnWindowDays?: number | null;
          usedReturnWindowDays?: number | null;
        } | null;
      } | null;
      preorderInfo?: {
        __typename?: "core_apimessages_PreorderInfo";
        onPreorder?: boolean | null;
        estimatedShipDate?: {
          __typename?: "google_protobuf_Timestamp";
          seconds?: number | null;
        } | null;
      } | null;
      watchThumbnails?: Array<{
        __typename?: "core_apimessages_Image";
        _id?: string | null;
        source?: string | null;
      } | null> | null;
      priceRecommendation?: {
        __typename?: "reverb_pricing_PriceRecommendation";
        priceMiddle?: {
          __typename?: "reverb_pricing_Money";
          amountCents?: number | null;
          currency?: string | null;
        } | null;
      } | null;
      images?: Array<{
        __typename?: "core_apimessages_Image";
        _id?: string | null;
        source?: string | null;
      } | null> | null;
      largeSquareImages?: Array<{
        __typename?: "core_apimessages_Image";
        _id?: string | null;
        source?: string | null;
      } | null> | null;
      certifiedPreOwned?: {
        __typename?: "core_apimessages_ListingCertifiedPreOwned";
        title?: string | null;
        badgeIconUrl?: string | null;
      } | null;
      categories?: Array<{
        __typename?: "Category";
        _id?: string | null;
        slug?: string | null;
        rootSlug?: string | null;
      } | null> | null;
      csp?: {
        __typename?: "CSP";
        _id?: string | null;
        id?: string | null;
        slug?: string | null;
        brand?: {
          __typename?: "Brand";
          _id?: string | null;
          slug?: string | null;
        } | null;
      } | null;
      publishedAt?: {
        __typename?: "google_protobuf_Timestamp";
        seconds?: number | null;
      } | null;
      sale?: {
        __typename?: "core_apimessages_Sale";
        _id?: string | null;
        id?: string | null;
        code?: string | null;
        buyerIneligibilityReason?: string | null;
      } | null;
    } | null> | null;
  } | null;
};

export type RecommendedShopsRowListingFieldsFragment = {
  __typename?: "Listing";
  _id?: string | null;
  id?: string | null;
  brandSlug?: string | null;
  categories?: Array<{
    __typename?: "Category";
    _id?: string | null;
    slug?: string | null;
  } | null> | null;
};

export type Core_Listing_RecommendedShopsRowQueryVariables = Exact<{
  itemRegion?: InputMaybe<Scalars["String"]["input"]>;
  itemRegionRelation?: InputMaybe<reverb_search_ListingItemRegionRelation>;
  brandSlugs?: InputMaybe<
    | Array<InputMaybe<Scalars["String"]["input"]>>
    | InputMaybe<Scalars["String"]["input"]>
  >;
  categorySlugs?: InputMaybe<
    | Array<InputMaybe<Scalars["String"]["input"]>>
    | InputMaybe<Scalars["String"]["input"]>
  >;
  shopSlugs?: InputMaybe<
    | Array<InputMaybe<Scalars["String"]["input"]>>
    | InputMaybe<Scalars["String"]["input"]>
  >;
}>;

export type Core_Listing_RecommendedShopsRowQuery = {
  __typename?: "Query";
  listingsAggregationSearch?: {
    __typename?: "reverb_search_ListingsAggregationResponse";
    shops?: Array<{
      __typename?: "Shop";
      _id?: string | null;
      id?: string | null;
      slug?: string | null;
      name?: string | null;
      preferredSeller?: boolean | null;
      quickShipper?: boolean | null;
      address?: {
        __typename?: "core_apimessages_Address";
        _id?: string | null;
        countryCode?: string | null;
        displayLocation?: string | null;
      } | null;
      user?: {
        __typename?: "User";
        _id?: string | null;
        avatar?: {
          __typename?: "core_apimessages_Image";
          _id?: string | null;
          source?: string | null;
        } | null;
        feedbackSummary?: {
          __typename?: "core_apimessages_FeedbackSummary";
          rollingRatingPercentage?: number | null;
          receivedCount?: number | null;
        } | null;
      } | null;
    } | null> | null;
  } | null;
};

export type NoCountFilterFragmentFragment = {
  __typename?: "reverb_search_Filter";
  name?: string | null;
  key?: string | null;
  aggregationName?: string | null;
  widgetType?: reverb_search_Filter_WidgetType | null;
  options?: Array<{
    __typename?: "reverb_search_FilterOption";
    name?: string | null;
    selected?: boolean | null;
    paramName?: string | null;
    setValues?: Array<string | null> | null;
    unsetValues?: Array<string | null> | null;
    all?: boolean | null;
    optionValue?: string | null;
  } | null> | null;
};

export type Core_SellFlow_Matched_SearchQueryVariables = Exact<{
  q?: InputMaybe<Scalars["String"]["input"]>;
  decades?: InputMaybe<
    | Array<InputMaybe<Scalars["String"]["input"]>>
    | InputMaybe<Scalars["String"]["input"]>
  >;
  finishes?: InputMaybe<
    | Array<InputMaybe<Scalars["String"]["input"]>>
    | InputMaybe<Scalars["String"]["input"]>
  >;
  brandNames?: InputMaybe<
    | Array<InputMaybe<Scalars["String"]["input"]>>
    | InputMaybe<Scalars["String"]["input"]>
  >;
  category_uuids?: InputMaybe<
    | Array<InputMaybe<Scalars["String"]["input"]>>
    | InputMaybe<Scalars["String"]["input"]>
  >;
  sellCardLimit?: InputMaybe<Scalars["Int"]["input"]>;
  excludedCategoryUuids?: InputMaybe<
    | Array<InputMaybe<Scalars["String"]["input"]>>
    | InputMaybe<Scalars["String"]["input"]>
  >;
  boostByClicks?: InputMaybe<Scalars["Boolean"]["input"]>;
  fullTextQueryOperand?: InputMaybe<reverb_search_FullTextQueryOperand>;
  sort?: InputMaybe<reverb_search_CSPSearchRequest_Sort>;
  fuzzy?: InputMaybe<Scalars["Boolean"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  listingsThatShipTo?: InputMaybe<Scalars["String"]["input"]>;
  hasExpressSaleBid: Scalars["Boolean"]["input"];
  includePriceRecommendations: Scalars["Boolean"]["input"];
  priceRecommendationCountryCode?: InputMaybe<Scalars["String"]["input"]>;
}>;

export type Core_SellFlow_Matched_SearchQuery = {
  __typename?: "Query";
  cspSearch?: {
    __typename?: "reverb_search_SearchResponse";
    total?: number | null;
    offset?: number | null;
    limit?: number | null;
    csps?: Array<{
      __typename?: "CSP";
      _id?: string | null;
      id?: string | null;
      title?: string | null;
      finishes?: Array<string | null> | null;
      slug?: string | null;
      canonicalProductIds?: Array<string | null> | null;
      isTradeInEligible?: boolean | null;
      image?: {
        __typename?: "core_apimessages_Image";
        _id?: string | null;
        source?: string | null;
      } | null;
      brand?: {
        __typename?: "Brand";
        _id?: string | null;
        name?: string | null;
      } | null;
      priceRecommendations?: Array<{
        __typename?: "reverb_pricing_PriceRecommendation";
        conditionUuid?: string | null;
        priceLow?: {
          __typename?: "reverb_pricing_Money";
          amountCents?: number | null;
          amount?: string | null;
          currency?: string | null;
        } | null;
        priceHigh?: {
          __typename?: "reverb_pricing_Money";
          amountCents?: number | null;
          amount?: string | null;
          currency?: string | null;
        } | null;
      } | null> | null;
      expressSalePriceEstimate?: {
        __typename?: "reverb_pricing_PriceRecommendation";
        priceLow?: {
          __typename?: "reverb_pricing_Money";
          amountCents?: number | null;
          currency?: string | null;
        } | null;
        priceHigh?: {
          __typename?: "reverb_pricing_Money";
          amountCents?: number | null;
          currency?: string | null;
        } | null;
      } | null;
      expressSaleItemBid?: {
        __typename?: "core_apimessages_CSPExpressSaleItemBid";
        cspUuid?: string | null;
        bidId?: string | null;
        carrier?: string | null;
        shopName?: string | null;
        estimatedPayout?: {
          __typename?: "core_apimessages_Money";
          display?: string | null;
          amount?: string | null;
        } | null;
      } | null;
    } | null> | null;
    filters?: Array<{
      __typename?: "reverb_search_Filter";
      name?: string | null;
      key?: string | null;
      aggregationName?: string | null;
      widgetType?: reverb_search_Filter_WidgetType | null;
      options?: Array<{
        __typename?: "reverb_search_FilterOption";
        name?: string | null;
        selected?: boolean | null;
        paramName?: string | null;
        setValues?: Array<string | null> | null;
        unsetValues?: Array<string | null> | null;
        all?: boolean | null;
        optionValue?: string | null;
      } | null> | null;
    } | null> | null;
  } | null;
};

export type Core_SellFlow_Unmatched_SearchQueryVariables = Exact<{
  q?: InputMaybe<Scalars["String"]["input"]>;
  decades?: InputMaybe<
    | Array<InputMaybe<Scalars["String"]["input"]>>
    | InputMaybe<Scalars["String"]["input"]>
  >;
  canonicalFinishes?: InputMaybe<
    | Array<InputMaybe<Scalars["String"]["input"]>>
    | InputMaybe<Scalars["String"]["input"]>
  >;
  categorySlugs?: InputMaybe<
    | Array<InputMaybe<Scalars["String"]["input"]>>
    | InputMaybe<Scalars["String"]["input"]>
  >;
  brandNames?: InputMaybe<
    | Array<InputMaybe<Scalars["String"]["input"]>>
    | InputMaybe<Scalars["String"]["input"]>
  >;
  category_uuids?: InputMaybe<
    | Array<InputMaybe<Scalars["String"]["input"]>>
    | InputMaybe<Scalars["String"]["input"]>
  >;
  excludedCategoryUuids?: InputMaybe<
    | Array<InputMaybe<Scalars["String"]["input"]>>
    | InputMaybe<Scalars["String"]["input"]>
  >;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
}>;

export type Core_SellFlow_Unmatched_SearchQuery = {
  __typename?: "Query";
  cspSearch?: {
    __typename?: "reverb_search_SearchResponse";
    filters?: Array<{
      __typename?: "reverb_search_Filter";
      name?: string | null;
      key?: string | null;
      aggregationName?: string | null;
      widgetType?: reverb_search_Filter_WidgetType | null;
      options?: Array<{
        __typename?: "reverb_search_FilterOption";
        name?: string | null;
        selected?: boolean | null;
        paramName?: string | null;
        setValues?: Array<string | null> | null;
        unsetValues?: Array<string | null> | null;
        all?: boolean | null;
        optionValue?: string | null;
      } | null> | null;
    } | null> | null;
  } | null;
  listingsSearch?: {
    __typename?: "reverb_search_SearchResponse";
    total?: number | null;
    offset?: number | null;
    limit?: number | null;
    listings?: Array<{
      __typename?: "Listing";
      _id?: string | null;
      id?: string | null;
      title?: string | null;
      finish?: string | null;
      canonicalProductId?: string | null;
      make?: string | null;
      slug?: string | null;
      images?: Array<{
        __typename: "core_apimessages_Image";
        _id?: string | null;
        source?: string | null;
      } | null> | null;
      price?: {
        __typename?: "core_apimessages_Money";
        display?: string | null;
      } | null;
    } | null> | null;
  } | null;
};

export type Core_CrossPlatform_MobileFetchPhotosQueryVariables = Exact<{
  listingId: Scalars["String"]["input"];
}>;

export type Core_CrossPlatform_MobileFetchPhotosQuery = {
  __typename?: "Query";
  listing?: {
    __typename?: "Listing";
    _id?: string | null;
    images?: Array<{
      __typename?: "core_apimessages_Image";
      _id?: string | null;
      id?: string | null;
      source?: string | null;
      itemId?: string | null;
      version?: string | null;
      itemType?: string | null;
      publicId?: string | null;
      height?: number | null;
      width?: number | null;
      transformation?: {
        __typename?: "core_apimessages_ImageEntry_Transformation";
        x?: string | null;
        y?: string | null;
        width?: string | null;
        height?: string | null;
        crop?: string | null;
        angle?: number | null;
      } | null;
    } | null> | null;
  } | null;
};

export type Core_CrossPlatform_MobileStartSessionMutationVariables = Exact<{
  listingId?: InputMaybe<Scalars["String"]["input"]>;
}>;

export type Core_CrossPlatform_MobileStartSessionMutation = {
  __typename?: "Mutation";
  updateCrossPlatformListingSession?: {
    __typename?: "core_apimessages_UpdateCrossPlatformListingSessionResponse";
    _?: boolean | null;
  } | null;
};

export type Core_CrossPlatform_MobileCancelSessionMutationVariables = Exact<{
  listingId?: InputMaybe<Scalars["String"]["input"]>;
}>;

export type Core_CrossPlatform_MobileCancelSessionMutation = {
  __typename?: "Mutation";
  updateCrossPlatformListingSession?: {
    __typename?: "core_apimessages_UpdateCrossPlatformListingSessionResponse";
    _?: boolean | null;
  } | null;
};

export type Core_CrossPlatform_MobileFetchStatusQueryVariables = Exact<{
  listingId?: InputMaybe<Scalars["String"]["input"]>;
}>;

export type Core_CrossPlatform_MobileFetchStatusQuery = {
  __typename?: "Query";
  crossPlatformListingStatus?: {
    __typename?: "core_apimessages_CrossPlatformListingStatusResponse";
    status?: core_apimessages_CrossPlatformListingStatusResponse_SessionStatus | null;
    ttlSeconds?: number | null;
  } | null;
};

export type Core_CrossPlatform_MobileSubmitPhotosMutationVariables = Exact<{
  input?: InputMaybe<Input_core_apimessages_UpdateListingRequest>;
}>;

export type Core_CrossPlatform_MobileSubmitPhotosMutation = {
  __typename?: "Mutation";
  crupdateListing?: { __typename?: "Listing"; _id?: string | null } | null;
};

export type ListingSellCardDataFragment = {
  __typename?: "Listing";
  _id?: string | null;
  id?: string | null;
  title?: string | null;
  finish?: string | null;
  canonicalProductId?: string | null;
  make?: string | null;
  slug?: string | null;
  images?: Array<{
    __typename: "core_apimessages_Image";
    _id?: string | null;
    source?: string | null;
  } | null> | null;
  price?: {
    __typename?: "core_apimessages_Money";
    display?: string | null;
  } | null;
};

export type Core_SellForm_SubmitShopDataMutationVariables = Exact<{
  input?: InputMaybe<Input_core_apimessages_UpdateMyShopRequest>;
}>;

export type Core_SellForm_SubmitShopDataMutation = {
  __typename?: "Mutation";
  updateMyShop?: {
    __typename?: "core_apimessages_UpdateMyShopResponse";
    shop?: { __typename?: "core_apimessages_Shop"; id?: string | null } | null;
    address?: {
      __typename?: "core_apimessages_Address";
      _id?: string | null;
      id?: string | null;
    } | null;
  } | null;
};

export type ShopAddressFieldsFragment = {
  __typename?: "core_apimessages_Address";
  _id?: string | null;
  primary?: boolean | null;
  name?: string | null;
  streetAddress?: string | null;
  extendedAddress?: string | null;
  region?: string | null;
  locality?: string | null;
  postalCode?: string | null;
  phone?: string | null;
  countryCode?: string | null;
  isComplete?: boolean | null;
};

export type SellFormSetupDataFragment = {
  __typename?: "rql_Me";
  _id?: string | null;
  uuid?: string | null;
  firstName?: string | null;
  shop?: {
    __typename?: "MyShop";
    _id?: string | null;
    name?: string | null;
    currencySetting?: string | null;
    defaultLocale?: string | null;
    address?: {
      __typename?: "core_apimessages_Address";
      _id?: string | null;
      primary?: boolean | null;
      name?: string | null;
      streetAddress?: string | null;
      extendedAddress?: string | null;
      region?: string | null;
      locality?: string | null;
      postalCode?: string | null;
      phone?: string | null;
      countryCode?: string | null;
      isComplete?: boolean | null;
    } | null;
  } | null;
};

export type Core_SellForm_SetupPageQueryVariables = Exact<{
  [key: string]: never;
}>;

export type Core_SellForm_SetupPageQuery = {
  __typename?: "Query";
  me?: {
    __typename?: "rql_Me";
    _id?: string | null;
    uuid?: string | null;
    firstName?: string | null;
    shippingAddresses?: Array<{
      __typename?: "core_apimessages_Address";
      _id?: string | null;
      primary?: boolean | null;
      name?: string | null;
      streetAddress?: string | null;
      extendedAddress?: string | null;
      region?: string | null;
      locality?: string | null;
      postalCode?: string | null;
      phone?: string | null;
      countryCode?: string | null;
      isComplete?: boolean | null;
    } | null> | null;
    shop?: {
      __typename?: "MyShop";
      _id?: string | null;
      name?: string | null;
      currencySetting?: string | null;
      defaultLocale?: string | null;
      address?: {
        __typename?: "core_apimessages_Address";
        _id?: string | null;
        primary?: boolean | null;
        name?: string | null;
        streetAddress?: string | null;
        extendedAddress?: string | null;
        region?: string | null;
        locality?: string | null;
        postalCode?: string | null;
        phone?: string | null;
        countryCode?: string | null;
        isComplete?: boolean | null;
      } | null;
    } | null;
  } | null;
  countries?: {
    __typename?: "core_apimessages_CountriesResponse";
    countries?: Array<{
      __typename?: "core_apimessages_Country";
      _id?: string | null;
      name?: string | null;
      countryCode?: string | null;
      subregionRequired?: boolean | null;
      subregions?: Array<{
        __typename?: "core_apimessages_Subregion";
        _id?: string | null;
        code?: string | null;
        name?: string | null;
      } | null> | null;
    } | null> | null;
  } | null;
};

export type Core_Trade_In_CSPQueryVariables = Exact<{
  cspId?: InputMaybe<Scalars["String"]["input"]>;
  priceRecommendationCountryCode?: InputMaybe<Scalars["String"]["input"]>;
}>;

export type Core_Trade_In_CSPQuery = {
  __typename?: "Query";
  cspEligibility?: {
    __typename?: "TradeInEligibility";
    appraisals?: Array<{
      __typename?: "TradeInAppraisal";
      price?: {
        __typename?: "core_apimessages_Money";
        amountCents?: number | null;
        currency?: string | null;
      } | null;
      condition?: {
        __typename?: "core_apimessages_Condition";
        conditionUuid?: string | null;
      } | null;
    } | null> | null;
    dealers?: Array<{
      __typename?: "Shop";
      _id?: string | null;
      id?: string | null;
      name?: string | null;
      user?: {
        __typename?: "User";
        _id?: string | null;
        avatar?: {
          __typename?: "core_apimessages_Image";
          _id?: string | null;
          source?: string | null;
        } | null;
      } | null;
    } | null> | null;
  } | null;
  csp?: {
    __typename?: "CSP";
    _id?: string | null;
    title?: string | null;
    finishes?: Array<string | null> | null;
    image?: {
      __typename?: "core_apimessages_Image";
      _id?: string | null;
      source?: string | null;
    } | null;
    brand?: {
      __typename?: "Brand";
      _id?: string | null;
      name?: string | null;
    } | null;
    canonicalProducts?: Array<{
      __typename?: "CanonicalProduct";
      _id?: string | null;
      id?: string | null;
      name?: string | null;
    } | null> | null;
    priceRecommendations?: Array<{
      __typename?: "reverb_pricing_PriceRecommendation";
      conditionUuid?: string | null;
      priceLow?: {
        __typename?: "reverb_pricing_Money";
        amountCents?: number | null;
        amount?: string | null;
        currency?: string | null;
      } | null;
      priceHigh?: {
        __typename?: "reverb_pricing_Money";
        amountCents?: number | null;
        amount?: string | null;
        currency?: string | null;
      } | null;
    } | null> | null;
  } | null;
};

export type SellSearchRowListingFragment = {
  __typename?: "Listing";
  _id?: string | null;
  id?: string | null;
  title?: string | null;
  images?: Array<{
    __typename?: "core_apimessages_Image";
    _id?: string | null;
    source?: string | null;
  } | null> | null;
};

export type Core_SellerQuickStartRow_MyDraftsSearchQueryVariables = Exact<{
  limit?: InputMaybe<Scalars["Int"]["input"]>;
}>;

export type Core_SellerQuickStartRow_MyDraftsSearchQuery = {
  __typename?: "Query";
  me?: {
    __typename?: "rql_Me";
    _id?: string | null;
    shop?: {
      __typename?: "MyShop";
      _id?: string | null;
      listings?: {
        __typename?: "reverb_search_SearchResponse";
        listings?: Array<{
          __typename?: "Listing";
          _id?: string | null;
          id?: string | null;
          title?: string | null;
          images?: Array<{
            __typename?: "core_apimessages_Image";
            _id?: string | null;
            source?: string | null;
          } | null> | null;
        } | null> | null;
      } | null;
    } | null;
  } | null;
};

export type Core_SellerQuickStartRow_MyGearCollectionSearchQueryVariables =
  Exact<{
    limit?: InputMaybe<Scalars["Int"]["input"]>;
  }>;

export type Core_SellerQuickStartRow_MyGearCollectionSearchQuery = {
  __typename?: "Query";
  gearCollectionItemsSearch?: {
    __typename?: "reverb_search_SearchResponse";
    gearCollectionItems?: Array<{
      __typename?: "GearCollectionItem";
      _id?: string | null;
      id?: string | null;
      title?: string | null;
      hideListingPhotos?: boolean | null;
      images?: Array<{
        __typename?: "core_apimessages_Image";
        _id?: string | null;
        source?: string | null;
      } | null> | null;
      listing?: {
        __typename?: "Listing";
        _id?: string | null;
        id?: string | null;
      } | null;
      originalListing?: {
        __typename?: "Listing";
        _id?: string | null;
        id?: string | null;
        title?: string | null;
        images?: Array<{
          __typename?: "core_apimessages_Image";
          _id?: string | null;
          source?: string | null;
        } | null> | null;
      } | null;
      csp?: {
        __typename?: "CSP";
        _id?: string | null;
        id?: string | null;
        title?: string | null;
        image?: {
          __typename?: "core_apimessages_Image";
          _id?: string | null;
          source?: string | null;
        } | null;
      } | null;
    } | null> | null;
  } | null;
};

export type Core_SellerQuickStartRow_MyBuyerOrderSearchQueryVariables = Exact<{
  limit?: InputMaybe<Scalars["Int"]["input"]>;
}>;

export type Core_SellerQuickStartRow_MyBuyerOrderSearchQuery = {
  __typename?: "Query";
  ordersSearch?: {
    __typename?: "reverb_search_SearchResponse";
    orders?: Array<{
      __typename?: "Order";
      _id?: string | null;
      id?: string | null;
      legacyOrderId?: string | null;
      protectionPlan?: boolean | null;
      listing?: {
        __typename?: "Listing";
        _id?: string | null;
        id?: string | null;
        title?: string | null;
        images?: Array<{
          __typename?: "core_apimessages_Image";
          _id?: string | null;
          source?: string | null;
        } | null> | null;
      } | null;
    } | null> | null;
  } | null;
};

export type ShippingProfilePricesFragment = {
  __typename?: "Shop";
  _id?: string | null;
  shippingProfiles?: Array<{
    __typename?: "core_apimessages_ShippingProfile";
    name?: string | null;
    id?: string | null;
    categoryUuids?: Array<string | null> | null;
    localPickup?: boolean | null;
    allShippingRates?: Array<{
      __typename?: "core_apimessages_ShippingRates";
      regionCode?: string | null;
      regionName?: string | null;
      rateType?: string | null;
      rate?: {
        __typename?: "core_apimessages_Money";
        display?: string | null;
      } | null;
      incrementalRate?: {
        __typename?: "core_apimessages_Money";
        display?: string | null;
      } | null;
      expeditedRate?: {
        __typename?: "core_apimessages_Money";
        display?: string | null;
      } | null;
      regionalRates?: Array<{
        __typename?: "core_apimessages_RegionalShippingRate";
        areas?: Array<string | null> | null;
        rate?: {
          __typename?: "core_apimessages_Money";
          display?: string | null;
        } | null;
      } | null> | null;
      regionalExpeditedRates?: Array<{
        __typename?: "core_apimessages_RegionalShippingRate";
        areas?: Array<string | null> | null;
        rate?: {
          __typename?: "core_apimessages_Money";
          display?: string | null;
        } | null;
      } | null> | null;
    } | null> | null;
  } | null> | null;
};

export type Core_SellForm_EditListingLayoutQueryVariables = Exact<{
  listingId: Scalars["String"]["input"];
}>;

export type Core_SellForm_EditListingLayoutQuery = {
  __typename?: "Query";
  me?: {
    __typename?: "rql_Me";
    _id?: string | null;
    uuid?: string | null;
    firstName?: string | null;
    shop?: {
      __typename?: "MyShop";
      _id?: string | null;
      name?: string | null;
      currencySetting?: string | null;
      defaultLocale?: string | null;
      address?: {
        __typename?: "core_apimessages_Address";
        _id?: string | null;
        primary?: boolean | null;
        name?: string | null;
        streetAddress?: string | null;
        extendedAddress?: string | null;
        region?: string | null;
        locality?: string | null;
        postalCode?: string | null;
        phone?: string | null;
        countryCode?: string | null;
        isComplete?: boolean | null;
      } | null;
    } | null;
  } | null;
  listing?: {
    __typename?: "Listing";
    _id?: string | null;
    id?: string | null;
    state?: string | null;
    sku?: string | null;
    year?: string | null;
    make?: string | null;
    countryOfOrigin?: string | null;
    canonicalProductId?: string | null;
    subcategoryUuids?: Array<string | null> | null;
    categoryRootUuid?: string | null;
    seedId?: string | null;
    seedType?: core_apimessages_Listing_SeedType | null;
    handmade?: boolean | null;
    hasInventory?: boolean | null;
    inventory?: number | null;
    upc?: string | null;
    upcDoesNotApply?: boolean | null;
    taxExempt?: boolean | null;
    relistable?: boolean | null;
    bumpEligible?: boolean | null;
    shippingProfileId?: string | null;
    prefersReverbShippingLabel?: boolean | null;
    vatIncluded?: boolean | null;
    vatIncludedHint?: string | null;
    soldAsIs?: boolean | null;
    listingType?: core_apimessages_Listing_ListingType | null;
    overriddenAttributes?: Array<string | null> | null;
    publishedAt?: {
      __typename?: "google_protobuf_Timestamp";
      seconds?: number | null;
    } | null;
    categories?: Array<{
      __typename?: "Category";
      _id?: string | null;
      id?: string | null;
      name?: string | null;
      root?: boolean | null;
      leaf?: boolean | null;
      rootSlug?: string | null;
    } | null> | null;
    localizedContents?: Array<{
      __typename?: "core_apimessages_LocalizedListingContent";
      locale?: string | null;
      make?: string | null;
      model?: string | null;
      finish?: string | null;
      title?: string | null;
      description?: string | null;
      prop65Warning?: string | null;
      defaultForShop?: boolean | null;
    } | null> | null;
    traits?: Array<{
      __typename?: "core_apimessages_Trait";
      keyId?: string | null;
      valueId?: string | null;
    } | null> | null;
    condition?: {
      __typename?: "core_apimessages_Condition";
      conditionSlug?: string | null;
    } | null;
    video?: {
      __typename?: "core_apimessages_Video";
      link?: string | null;
    } | null;
    bumpRate?: {
      __typename?: "core_apimessages_BumpRate";
      rate?: number | null;
    } | null;
    images?: Array<{
      __typename?: "core_apimessages_Image";
      _id?: string | null;
      id?: string | null;
      source?: string | null;
      itemId?: string | null;
      version?: string | null;
      itemType?: string | null;
      publicId?: string | null;
      height?: number | null;
      width?: number | null;
      transformation?: {
        __typename?: "core_apimessages_ImageEntry_Transformation";
        x?: string | null;
        y?: string | null;
        width?: string | null;
        height?: string | null;
        crop?: string | null;
        angle?: number | null;
      } | null;
    } | null> | null;
    allShippingPrices?: {
      __typename?: "core_apimessages_ShippingPrices";
      localPickup?: boolean | null;
      shippingPrices?: Array<{
        __typename?: "core_apimessages_ShippingPrice";
        _id?: string | null;
        shippingRegionCode?: string | null;
        shippingMethod?: core_apimessages_ShippingMethod | null;
        carrierCalculated?: boolean | null;
        originalRateVatExcluded?: {
          __typename?: "core_apimessages_Money";
          amount?: string | null;
          currency?: string | null;
        } | null;
      } | null> | null;
    } | null;
    shipmentPackage?: {
      __typename?: "ShipmentPackage";
      _id?: string | null;
      allowedCarriers?: Array<string | null> | null;
      packageSizeSuggestionId?: string | null;
      length?: {
        __typename?: "core_apimessages_Measurement";
        value?: string | null;
        unit?: string | null;
      } | null;
      width?: {
        __typename?: "core_apimessages_Measurement";
        value?: string | null;
        unit?: string | null;
      } | null;
      height?: {
        __typename?: "core_apimessages_Measurement";
        value?: string | null;
        unit?: string | null;
      } | null;
      weight?: {
        __typename?: "core_apimessages_Measurement";
        value?: string | null;
        unit?: string | null;
      } | null;
    } | null;
    sellerPrice?: {
      __typename?: "core_apimessages_Money";
      amountCents?: number | null;
      currency?: string | null;
      amount?: string | null;
      display?: string | null;
    } | null;
    sellerCost?: {
      __typename?: "core_apimessages_Money";
      amountCents?: number | null;
      currency?: string | null;
      amount?: string | null;
      display?: string | null;
    } | null;
    sellerReportedMap?: {
      __typename?: "core_apimessages_Money";
      amountCents?: number | null;
      currency?: string | null;
      amount?: string | null;
      display?: string | null;
    } | null;
    preorderInfo?: {
      __typename?: "core_apimessages_PreorderInfo";
      leadTimeDays?: number | null;
      shipDate?: {
        __typename?: "google_protobuf_Timestamp";
        seconds?: number | null;
      } | null;
    } | null;
    shop?: {
      __typename?: "Shop";
      _id?: string | null;
      currencySetting?: string | null;
      defaultLocale?: string | null;
      shopSupportedLocaleCodes?: Array<string | null> | null;
      preferredSeller?: boolean | null;
      hasAccountRep?: boolean | null;
      inclusiveVatPricingEnabled?: boolean | null;
      vatId?: string | null;
      firstTimeSeller?: boolean | null;
      sameDayShippingConfigured?: boolean | null;
      listableConditions?: Array<{
        __typename?: "core_apimessages_Condition";
        displayName?: string | null;
        conditionSlug?: string | null;
      } | null> | null;
      offerPolicy?: {
        __typename?: "core_apimessages_ShopConfig_OfferPolicy";
        autoRejectLowOffers?: boolean | null;
        lowballPercentage?: number | null;
      } | null;
      returnPolicy?: {
        __typename?: "core_apimessages_ReturnPolicy";
        _id?: string | null;
        newReturnWindowDays?: number | null;
        usedReturnWindowDays?: number | null;
      } | null;
      taxPolicies?: Array<{
        __typename?: "core_apimessages_ShopTaxPolicy";
        vatPolicy?: boolean | null;
      } | null> | null;
      shippingRegionSettings?: Array<{
        __typename?: "core_apimessages_ShippingRegionSetting";
        regionCode?: string | null;
        regionName?: string | null;
        isDomestic?: boolean | null;
        allowedToShipCarrierCalculated?: boolean | null;
      } | null> | null;
      address?: {
        __typename?: "core_apimessages_Address";
        _id?: string | null;
        region?: string | null;
        postalCode?: string | null;
        countryCode?: string | null;
      } | null;
      shippingProfiles?: Array<{
        __typename?: "core_apimessages_ShippingProfile";
        name?: string | null;
        id?: string | null;
        categoryUuids?: Array<string | null> | null;
        localPickup?: boolean | null;
        allShippingRates?: Array<{
          __typename?: "core_apimessages_ShippingRates";
          regionCode?: string | null;
          regionName?: string | null;
          rateType?: string | null;
          rate?: {
            __typename?: "core_apimessages_Money";
            display?: string | null;
          } | null;
          incrementalRate?: {
            __typename?: "core_apimessages_Money";
            display?: string | null;
          } | null;
          expeditedRate?: {
            __typename?: "core_apimessages_Money";
            display?: string | null;
          } | null;
          regionalRates?: Array<{
            __typename?: "core_apimessages_RegionalShippingRate";
            areas?: Array<string | null> | null;
            rate?: {
              __typename?: "core_apimessages_Money";
              display?: string | null;
            } | null;
          } | null> | null;
          regionalExpeditedRates?: Array<{
            __typename?: "core_apimessages_RegionalShippingRate";
            areas?: Array<string | null> | null;
            rate?: {
              __typename?: "core_apimessages_Money";
              display?: string | null;
            } | null;
          } | null> | null;
        } | null> | null;
      } | null> | null;
    } | null;
    scheduledPriceDrops?: Array<{
      __typename?: "core_apimessages_ScheduledPriceDrop";
      active?: boolean | null;
      dropAt?: any | null;
      newPrice?: {
        __typename?: "core_apimessages_Money";
        amountCents?: number | null;
        currency?: string | null;
        amount?: string | null;
        display?: string | null;
      } | null;
    } | null> | null;
  } | null;
  countries?: {
    __typename?: "core_apimessages_CountriesResponse";
    countries?: Array<{
      __typename?: "core_apimessages_Country";
      _id?: string | null;
      name?: string | null;
      countryCode?: string | null;
    } | null> | null;
  } | null;
};

export type Core_SellForm_NewListingLayoutQueryVariables = Exact<{
  shouldSeedListingData: Scalars["Boolean"]["input"];
  seedId?: InputMaybe<Scalars["String"]["input"]>;
  seedType?: InputMaybe<core_apimessages_Listing_SeedType>;
}>;

export type Core_SellForm_NewListingLayoutQuery = {
  __typename?: "Query";
  me?: {
    __typename?: "rql_Me";
    _id?: string | null;
    uuid?: string | null;
    firstName?: string | null;
    shop?: {
      __typename?: "MyShop";
      _id?: string | null;
      currencySetting?: string | null;
      defaultLocale?: string | null;
      shopSupportedLocaleCodes?: Array<string | null> | null;
      preferredSeller?: boolean | null;
      inclusiveVatPricingEnabled?: boolean | null;
      sameDayShippingConfigured?: boolean | null;
      firstTimeSeller?: boolean | null;
      name?: string | null;
      listableConditions?: Array<{
        __typename?: "core_apimessages_Condition";
        displayName?: string | null;
        conditionSlug?: string | null;
      } | null> | null;
      offerPolicy?: {
        __typename?: "core_apimessages_ShopConfig_OfferPolicy";
        autoRejectLowOffers?: boolean | null;
        autoAcceptHighOffers?: boolean | null;
      } | null;
      returnPolicy?: {
        __typename?: "core_apimessages_ReturnPolicy";
        _id?: string | null;
        newReturnWindowDays?: number | null;
        usedReturnWindowDays?: number | null;
      } | null;
      taxPolicies?: Array<{
        __typename?: "core_apimessages_ShopTaxPolicy";
        vatPolicy?: boolean | null;
      } | null> | null;
      shippingRegionSettings?: Array<{
        __typename?: "core_apimessages_ShippingRegionSetting";
        regionCode?: string | null;
        regionName?: string | null;
        isDomestic?: boolean | null;
        allowedToShipCarrierCalculated?: boolean | null;
      } | null> | null;
      address?: {
        __typename?: "core_apimessages_Address";
        _id?: string | null;
        region?: string | null;
        postalCode?: string | null;
        countryCode?: string | null;
        primary?: boolean | null;
        name?: string | null;
        streetAddress?: string | null;
        extendedAddress?: string | null;
        locality?: string | null;
        phone?: string | null;
        isComplete?: boolean | null;
      } | null;
      shippingProfiles?: Array<{
        __typename?: "core_apimessages_MyShippingProfile";
        name?: string | null;
        id?: string | null;
        categoryUuids?: Array<string | null> | null;
        localPickup?: boolean | null;
        shippingRates?: Array<{
          __typename?: "core_apimessages_ShippingRates";
          regionCode?: string | null;
          regionName?: string | null;
          rateType?: string | null;
          rate?: {
            __typename?: "core_apimessages_Money";
            display?: string | null;
          } | null;
          incrementalRate?: {
            __typename?: "core_apimessages_Money";
            display?: string | null;
          } | null;
          expeditedRate?: {
            __typename?: "core_apimessages_Money";
            display?: string | null;
          } | null;
          regionalRates?: Array<{
            __typename?: "core_apimessages_RegionalShippingRate";
            areas?: Array<string | null> | null;
            rate?: {
              __typename?: "core_apimessages_Money";
              display?: string | null;
            } | null;
          } | null> | null;
          regionalExpeditedRates?: Array<{
            __typename?: "core_apimessages_RegionalShippingRate";
            areas?: Array<string | null> | null;
            rate?: {
              __typename?: "core_apimessages_Money";
              display?: string | null;
            } | null;
          } | null> | null;
        } | null> | null;
      } | null> | null;
    } | null;
  } | null;
  seedListing?: {
    __typename?: "core_apimessages_SeedListingResponse";
    listing?: {
      __typename?: "core_apimessages_SeededListing";
      make?: string | null;
      model?: string | null;
      brandSlug?: string | null;
      categoryRootUuid?: string | null;
      subcategoryLeafUuids?: Array<string | null> | null;
      canonicalProductId?: string | null;
      comparisonShoppingPageId?: string | null;
      finish?: string | null;
      year?: string | null;
      countryOfOrigin?: string | null;
      conditionSlug?: string | null;
      description?: string | null;
      title?: string | null;
      handmade?: boolean | null;
      soldAsIs?: boolean | null;
      shippingProfileId?: string | null;
      sellerCost?: {
        __typename?: "core_apimessages_Money";
        amount?: string | null;
        amountCents?: number | null;
        currency?: string | null;
        display?: string | null;
      } | null;
      sellerPrice?: {
        __typename?: "core_apimessages_Money";
        amount?: string | null;
        amountCents?: number | null;
        currency?: string | null;
        display?: string | null;
      } | null;
    } | null;
  } | null;
  countries?: {
    __typename?: "core_apimessages_CountriesResponse";
    countries?: Array<{
      __typename?: "core_apimessages_Country";
      _id?: string | null;
      name?: string | null;
      countryCode?: string | null;
    } | null> | null;
  } | null;
};

export type Core_Listing_UpdateMutationVariables = Exact<{
  input?: InputMaybe<Input_core_apimessages_UpdateListingRequest>;
}>;

export type Core_Listing_UpdateMutation = {
  __typename?: "Mutation";
  crupdateListing?: {
    __typename?: "Listing";
    _id?: string | null;
    id?: string | null;
    state?: string | null;
    sku?: string | null;
    year?: string | null;
    make?: string | null;
    countryOfOrigin?: string | null;
    canonicalProductId?: string | null;
    subcategoryUuids?: Array<string | null> | null;
    categoryRootUuid?: string | null;
    seedId?: string | null;
    seedType?: core_apimessages_Listing_SeedType | null;
    handmade?: boolean | null;
    hasInventory?: boolean | null;
    inventory?: number | null;
    upc?: string | null;
    upcDoesNotApply?: boolean | null;
    taxExempt?: boolean | null;
    relistable?: boolean | null;
    bumpEligible?: boolean | null;
    shippingProfileId?: string | null;
    prefersReverbShippingLabel?: boolean | null;
    vatIncluded?: boolean | null;
    vatIncludedHint?: string | null;
    soldAsIs?: boolean | null;
    listingType?: core_apimessages_Listing_ListingType | null;
    overriddenAttributes?: Array<string | null> | null;
    publishedAt?: {
      __typename?: "google_protobuf_Timestamp";
      seconds?: number | null;
    } | null;
    categories?: Array<{
      __typename?: "Category";
      _id?: string | null;
      id?: string | null;
      name?: string | null;
      root?: boolean | null;
      leaf?: boolean | null;
      rootSlug?: string | null;
    } | null> | null;
    localizedContents?: Array<{
      __typename?: "core_apimessages_LocalizedListingContent";
      locale?: string | null;
      make?: string | null;
      model?: string | null;
      finish?: string | null;
      title?: string | null;
      description?: string | null;
      prop65Warning?: string | null;
      defaultForShop?: boolean | null;
    } | null> | null;
    traits?: Array<{
      __typename?: "core_apimessages_Trait";
      keyId?: string | null;
      valueId?: string | null;
    } | null> | null;
    condition?: {
      __typename?: "core_apimessages_Condition";
      conditionSlug?: string | null;
    } | null;
    video?: {
      __typename?: "core_apimessages_Video";
      link?: string | null;
    } | null;
    bumpRate?: {
      __typename?: "core_apimessages_BumpRate";
      rate?: number | null;
    } | null;
    images?: Array<{
      __typename?: "core_apimessages_Image";
      _id?: string | null;
      id?: string | null;
      source?: string | null;
      itemId?: string | null;
      version?: string | null;
      itemType?: string | null;
      publicId?: string | null;
      height?: number | null;
      width?: number | null;
      transformation?: {
        __typename?: "core_apimessages_ImageEntry_Transformation";
        x?: string | null;
        y?: string | null;
        width?: string | null;
        height?: string | null;
        crop?: string | null;
        angle?: number | null;
      } | null;
    } | null> | null;
    allShippingPrices?: {
      __typename?: "core_apimessages_ShippingPrices";
      localPickup?: boolean | null;
      shippingPrices?: Array<{
        __typename?: "core_apimessages_ShippingPrice";
        _id?: string | null;
        shippingRegionCode?: string | null;
        shippingMethod?: core_apimessages_ShippingMethod | null;
        carrierCalculated?: boolean | null;
        originalRateVatExcluded?: {
          __typename?: "core_apimessages_Money";
          amount?: string | null;
          currency?: string | null;
        } | null;
      } | null> | null;
    } | null;
    shipmentPackage?: {
      __typename?: "ShipmentPackage";
      _id?: string | null;
      allowedCarriers?: Array<string | null> | null;
      packageSizeSuggestionId?: string | null;
      length?: {
        __typename?: "core_apimessages_Measurement";
        value?: string | null;
        unit?: string | null;
      } | null;
      width?: {
        __typename?: "core_apimessages_Measurement";
        value?: string | null;
        unit?: string | null;
      } | null;
      height?: {
        __typename?: "core_apimessages_Measurement";
        value?: string | null;
        unit?: string | null;
      } | null;
      weight?: {
        __typename?: "core_apimessages_Measurement";
        value?: string | null;
        unit?: string | null;
      } | null;
    } | null;
    sellerPrice?: {
      __typename?: "core_apimessages_Money";
      amountCents?: number | null;
      currency?: string | null;
      amount?: string | null;
      display?: string | null;
    } | null;
    sellerCost?: {
      __typename?: "core_apimessages_Money";
      amountCents?: number | null;
      currency?: string | null;
      amount?: string | null;
      display?: string | null;
    } | null;
    sellerReportedMap?: {
      __typename?: "core_apimessages_Money";
      amountCents?: number | null;
      currency?: string | null;
      amount?: string | null;
      display?: string | null;
    } | null;
    preorderInfo?: {
      __typename?: "core_apimessages_PreorderInfo";
      leadTimeDays?: number | null;
      shipDate?: {
        __typename?: "google_protobuf_Timestamp";
        seconds?: number | null;
      } | null;
    } | null;
    shop?: {
      __typename?: "Shop";
      _id?: string | null;
      currencySetting?: string | null;
      defaultLocale?: string | null;
      shopSupportedLocaleCodes?: Array<string | null> | null;
      preferredSeller?: boolean | null;
      hasAccountRep?: boolean | null;
      inclusiveVatPricingEnabled?: boolean | null;
      vatId?: string | null;
      firstTimeSeller?: boolean | null;
      sameDayShippingConfigured?: boolean | null;
      listableConditions?: Array<{
        __typename?: "core_apimessages_Condition";
        displayName?: string | null;
        conditionSlug?: string | null;
      } | null> | null;
      offerPolicy?: {
        __typename?: "core_apimessages_ShopConfig_OfferPolicy";
        autoRejectLowOffers?: boolean | null;
        lowballPercentage?: number | null;
      } | null;
      returnPolicy?: {
        __typename?: "core_apimessages_ReturnPolicy";
        _id?: string | null;
        newReturnWindowDays?: number | null;
        usedReturnWindowDays?: number | null;
      } | null;
      taxPolicies?: Array<{
        __typename?: "core_apimessages_ShopTaxPolicy";
        vatPolicy?: boolean | null;
      } | null> | null;
      shippingRegionSettings?: Array<{
        __typename?: "core_apimessages_ShippingRegionSetting";
        regionCode?: string | null;
        regionName?: string | null;
        isDomestic?: boolean | null;
        allowedToShipCarrierCalculated?: boolean | null;
      } | null> | null;
      address?: {
        __typename?: "core_apimessages_Address";
        _id?: string | null;
        region?: string | null;
        postalCode?: string | null;
        countryCode?: string | null;
      } | null;
      shippingProfiles?: Array<{
        __typename?: "core_apimessages_ShippingProfile";
        name?: string | null;
        id?: string | null;
        categoryUuids?: Array<string | null> | null;
        localPickup?: boolean | null;
        allShippingRates?: Array<{
          __typename?: "core_apimessages_ShippingRates";
          regionCode?: string | null;
          regionName?: string | null;
          rateType?: string | null;
          rate?: {
            __typename?: "core_apimessages_Money";
            display?: string | null;
          } | null;
          incrementalRate?: {
            __typename?: "core_apimessages_Money";
            display?: string | null;
          } | null;
          expeditedRate?: {
            __typename?: "core_apimessages_Money";
            display?: string | null;
          } | null;
          regionalRates?: Array<{
            __typename?: "core_apimessages_RegionalShippingRate";
            areas?: Array<string | null> | null;
            rate?: {
              __typename?: "core_apimessages_Money";
              display?: string | null;
            } | null;
          } | null> | null;
          regionalExpeditedRates?: Array<{
            __typename?: "core_apimessages_RegionalShippingRate";
            areas?: Array<string | null> | null;
            rate?: {
              __typename?: "core_apimessages_Money";
              display?: string | null;
            } | null;
          } | null> | null;
        } | null> | null;
      } | null> | null;
    } | null;
    scheduledPriceDrops?: Array<{
      __typename?: "core_apimessages_ScheduledPriceDrop";
      active?: boolean | null;
      dropAt?: any | null;
      newPrice?: {
        __typename?: "core_apimessages_Money";
        amountCents?: number | null;
        currency?: string | null;
        amount?: string | null;
        display?: string | null;
      } | null;
    } | null> | null;
  } | null;
};

export type SellFormViewCountryFieldsFragment = {
  __typename?: "core_apimessages_CountriesResponse";
  countries?: Array<{
    __typename?: "core_apimessages_Country";
    _id?: string | null;
    name?: string | null;
    countryCode?: string | null;
  } | null> | null;
};

export type Core_CrossPlatform_DesktopFetchStatusQueryVariables = Exact<{
  listingId?: InputMaybe<Scalars["String"]["input"]>;
}>;

export type Core_CrossPlatform_DesktopFetchStatusQuery = {
  __typename?: "Query";
  crossPlatformListingStatus?: {
    __typename?: "core_apimessages_CrossPlatformListingStatusResponse";
    status?: core_apimessages_CrossPlatformListingStatusResponse_SessionStatus | null;
    ttlSeconds?: number | null;
  } | null;
};

export type Core_CrossPlatform_DesktopUpdateSessionMutationVariables = Exact<{
  input?: InputMaybe<Input_core_apimessages_UpdateCrossPlatformListingSessionRequest>;
}>;

export type Core_CrossPlatform_DesktopUpdateSessionMutation = {
  __typename?: "Mutation";
  updateCrossPlatformListingSession?: {
    __typename?: "core_apimessages_UpdateCrossPlatformListingSessionResponse";
    _?: boolean | null;
  } | null;
};

export type Core_CrossPlatform_AuthTokenQueryVariables = Exact<{
  [key: string]: never;
}>;

export type Core_CrossPlatform_AuthTokenQuery = {
  __typename?: "Query";
  bearerV2Token?: {
    __typename?: "core_apimessages_BearerV2TokenResponse";
    token?: string | null;
  } | null;
};

export type Core_SellForm_ListingCorrectionMutationVariables = Exact<{
  input?: InputMaybe<Input_core_apimessages_ListingCorrectionRequest>;
}>;

export type Core_SellForm_ListingCorrectionMutation = {
  __typename?: "Mutation";
  listingCorrection?: {
    __typename?: "core_apimessages_ListingCorrectionResponse";
    _?: boolean | null;
  } | null;
};

export type SellFormCanonicalProductCardDataFragment = {
  __typename?: "CSP";
  _id?: string | null;
  title?: string | null;
  model?: string | null;
  image?: {
    __typename?: "core_apimessages_Image";
    _id?: string | null;
    source?: string | null;
  } | null;
  canonicalProducts?: Array<{
    __typename?: "CanonicalProduct";
    _id?: string | null;
    id?: string | null;
    name?: string | null;
    model?: string | null;
    year?: string | null;
    finish?: string | null;
    countryOfOrigin?: string | null;
  } | null> | null;
  categories?: Array<{
    __typename?: "Category";
    _id?: string | null;
    name?: string | null;
  } | null> | null;
  brand?: {
    __typename?: "Brand";
    _id?: string | null;
    name?: string | null;
  } | null;
};

export type Core_SellForm_EstimatedNewListingPriceQueryVariables = Exact<{
  canonicalProductId: Scalars["String"]["input"];
}>;

export type Core_SellForm_EstimatedNewListingPriceQuery = {
  __typename?: "Query";
  canonicalproduct?: {
    __typename?: "CanonicalProduct";
    _id?: string | null;
    estimatedNewListingPrice?: {
      __typename?: "core_apimessages_EstimatedNewListingPrice";
      price?: {
        __typename?: "core_apimessages_Money";
        amountCents?: number | null;
        display?: string | null;
      } | null;
    } | null;
  } | null;
};

export type DataServices_SellForm_PriceScoreQueryVariables = Exact<{
  canonicalProductId: Scalars["String"]["input"];
  conditionUuid: Scalars["String"]["input"];
  price: Input_reverb_pricing_Money;
  countryCode: Scalars["String"]["input"];
}>;

export type DataServices_SellForm_PriceScoreQuery = {
  __typename?: "Query";
  priceScores?: {
    __typename?: "reverb_pricing_PriceScoresResponse";
    priceScores?: Array<{
      __typename?: "reverb_pricing_PriceScore";
      priceScore?: number | null;
    } | null> | null;
  } | null;
};

export type Core_SellForm_RedeemPromotionalCodeMutationVariables = Exact<{
  token?: InputMaybe<Scalars["String"]["input"]>;
}>;

export type Core_SellForm_RedeemPromotionalCodeMutation = {
  __typename?: "Mutation";
  promotionalCodeRedeem?: {
    __typename?: "core_apimessages_RedeemPromotionalCodeResponse";
    message?: string | null;
  } | null;
};

export type Core_SellForm_ReviewSectionPhotosQueryVariables = Exact<{
  listingId: Scalars["String"]["input"];
}>;

export type Core_SellForm_ReviewSectionPhotosQuery = {
  __typename?: "Query";
  listing?: {
    __typename?: "Listing";
    _id?: string | null;
    supersizeImages?: Array<{
      __typename?: "core_apimessages_Image";
      _id?: string | null;
      source?: string | null;
    } | null> | null;
    cardSquareImages?: Array<{
      __typename?: "core_apimessages_Image";
      _id?: string | null;
      source?: string | null;
    } | null> | null;
  } | null;
};

export type Search_SellForm_LiveListingsTableQueryVariables = Exact<{
  canonicalProductIds?: InputMaybe<
    | Array<InputMaybe<Scalars["String"]["input"]>>
    | InputMaybe<Scalars["String"]["input"]>
  >;
  conditionSlugs?: InputMaybe<
    | Array<InputMaybe<Scalars["String"]["input"]>>
    | InputMaybe<Scalars["String"]["input"]>
  >;
  itemRegion?: InputMaybe<Scalars["String"]["input"]>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
}>;

export type Search_SellForm_LiveListingsTableQuery = {
  __typename?: "Query";
  listingsSearch?: {
    __typename?: "reverb_search_SearchResponse";
    total?: number | null;
    offset?: number | null;
    listings?: Array<{
      __typename?: "Listing";
      _id?: string | null;
      publishedAt?: {
        __typename?: "google_protobuf_Timestamp";
        seconds?: number | null;
      } | null;
      condition?: {
        __typename?: "core_apimessages_Condition";
        displayName?: string | null;
      } | null;
      pricing?: {
        __typename?: "core_apimessages_ListingPricing";
        buyerPrice?: {
          __typename?: "core_apimessages_Money";
          display?: string | null;
        } | null;
      } | null;
    } | null> | null;
  } | null;
};

export type DataServices_SellForm__PricingRecommendationsQueryVariables =
  Exact<{
    priceRecommendationQueries?: InputMaybe<
      | Array<InputMaybe<Input_reverb_pricing_PriceRecommendationQuery>>
      | InputMaybe<Input_reverb_pricing_PriceRecommendationQuery>
    >;
    loggedOut: Scalars["Boolean"]["input"];
  }>;

export type DataServices_SellForm__PricingRecommendationsQuery = {
  __typename?: "Query";
  priceRecommendations?: {
    __typename?: "reverb_pricing_PriceRecommendationsResponse";
    priceRecommendations?: Array<{
      __typename?: "reverb_pricing_PriceRecommendation";
      priceLow?: {
        __typename?: "reverb_pricing_Money";
        amountCents?: number | null;
        amount?: string | null;
        currency?: string | null;
      } | null;
      priceMiddle?: {
        __typename?: "reverb_pricing_Money";
        amountCents?: number | null;
        amount?: string | null;
        currency?: string | null;
      } | null;
      priceHigh?: {
        __typename?: "reverb_pricing_Money";
        amountCents?: number | null;
        amount?: string | null;
        currency?: string | null;
      } | null;
    } | null> | null;
  } | null;
  me?: {
    __typename?: "rql_Me";
    _id?: string | null;
    uuid?: string | null;
    shop?: {
      __typename?: "MyShop";
      _id?: string | null;
      currency?: string | null;
    } | null;
  } | null;
  exchangeRates?: {
    __typename?: "reverb_config_cache_CurrencyResponse";
    rates?: Array<{
      __typename?: "reverb_config_cache_ExchangeRate";
      from?: string | null;
      to?: string | null;
      rate?: number | null;
    } | null> | null;
  } | null;
};

export type Core_SellForm_CSPSeedDataQueryVariables = Exact<{
  cspId?: InputMaybe<Scalars["String"]["input"]>;
}>;

export type Core_SellForm_CSPSeedDataQuery = {
  __typename?: "Query";
  csp?: {
    __typename?: "CSP";
    _id?: string | null;
    title?: string | null;
    model?: string | null;
    image?: {
      __typename?: "core_apimessages_Image";
      _id?: string | null;
      source?: string | null;
    } | null;
    canonicalProducts?: Array<{
      __typename?: "CanonicalProduct";
      _id?: string | null;
      id?: string | null;
      name?: string | null;
      model?: string | null;
      year?: string | null;
      finish?: string | null;
      countryOfOrigin?: string | null;
    } | null> | null;
    categories?: Array<{
      __typename?: "Category";
      _id?: string | null;
      name?: string | null;
    } | null> | null;
    brand?: {
      __typename?: "Brand";
      _id?: string | null;
      name?: string | null;
    } | null;
  } | null;
};

export type SellFormShopShippingFragment = {
  __typename?: "Shop";
  _id?: string | null;
  currencySetting?: string | null;
  sameDayShippingConfigured?: boolean | null;
  shippingRegionSettings?: Array<{
    __typename?: "core_apimessages_ShippingRegionSetting";
    regionCode?: string | null;
    regionName?: string | null;
    isDomestic?: boolean | null;
    allowedToShipCarrierCalculated?: boolean | null;
  } | null> | null;
  address?: {
    __typename?: "core_apimessages_Address";
    _id?: string | null;
    region?: string | null;
    postalCode?: string | null;
    countryCode?: string | null;
  } | null;
  shippingProfiles?: Array<{
    __typename?: "core_apimessages_ShippingProfile";
    name?: string | null;
    id?: string | null;
    categoryUuids?: Array<string | null> | null;
    localPickup?: boolean | null;
    allShippingRates?: Array<{
      __typename?: "core_apimessages_ShippingRates";
      regionCode?: string | null;
      regionName?: string | null;
      rateType?: string | null;
      rate?: {
        __typename?: "core_apimessages_Money";
        display?: string | null;
      } | null;
      incrementalRate?: {
        __typename?: "core_apimessages_Money";
        display?: string | null;
      } | null;
      expeditedRate?: {
        __typename?: "core_apimessages_Money";
        display?: string | null;
      } | null;
      regionalRates?: Array<{
        __typename?: "core_apimessages_RegionalShippingRate";
        areas?: Array<string | null> | null;
        rate?: {
          __typename?: "core_apimessages_Money";
          display?: string | null;
        } | null;
      } | null> | null;
      regionalExpeditedRates?: Array<{
        __typename?: "core_apimessages_RegionalShippingRate";
        areas?: Array<string | null> | null;
        rate?: {
          __typename?: "core_apimessages_Money";
          display?: string | null;
        } | null;
      } | null> | null;
    } | null> | null;
  } | null> | null;
};

export type Core_SellForm_FetchPackageSizeSuggestionQueryVariables = Exact<{
  canonicalProductId: Scalars["String"]["input"];
}>;

export type Core_SellForm_FetchPackageSizeSuggestionQuery = {
  __typename?: "Query";
  canonicalproduct?: {
    __typename?: "CanonicalProduct";
    _id?: string | null;
    packageSizeSuggestion?: {
      __typename?: "PackageSizeSuggestion";
      _id?: string | null;
      id?: string | null;
      length?: {
        __typename?: "core_apimessages_Measurement";
        unit?: string | null;
        value?: string | null;
      } | null;
      width?: {
        __typename?: "core_apimessages_Measurement";
        unit?: string | null;
        value?: string | null;
      } | null;
      height?: {
        __typename?: "core_apimessages_Measurement";
        unit?: string | null;
        value?: string | null;
      } | null;
      weight?: {
        __typename?: "core_apimessages_Measurement";
        unit?: string | null;
        value?: string | null;
      } | null;
    } | null;
  } | null;
};

export type HydrateSellFormFragment = {
  __typename?: "Listing";
  _id?: string | null;
  id?: string | null;
  state?: string | null;
  sku?: string | null;
  year?: string | null;
  make?: string | null;
  countryOfOrigin?: string | null;
  canonicalProductId?: string | null;
  subcategoryUuids?: Array<string | null> | null;
  categoryRootUuid?: string | null;
  seedId?: string | null;
  seedType?: core_apimessages_Listing_SeedType | null;
  handmade?: boolean | null;
  hasInventory?: boolean | null;
  inventory?: number | null;
  upc?: string | null;
  upcDoesNotApply?: boolean | null;
  taxExempt?: boolean | null;
  relistable?: boolean | null;
  bumpEligible?: boolean | null;
  shippingProfileId?: string | null;
  prefersReverbShippingLabel?: boolean | null;
  vatIncluded?: boolean | null;
  vatIncludedHint?: string | null;
  soldAsIs?: boolean | null;
  listingType?: core_apimessages_Listing_ListingType | null;
  overriddenAttributes?: Array<string | null> | null;
  publishedAt?: {
    __typename?: "google_protobuf_Timestamp";
    seconds?: number | null;
  } | null;
  categories?: Array<{
    __typename?: "Category";
    _id?: string | null;
    id?: string | null;
    name?: string | null;
    root?: boolean | null;
    leaf?: boolean | null;
    rootSlug?: string | null;
  } | null> | null;
  localizedContents?: Array<{
    __typename?: "core_apimessages_LocalizedListingContent";
    locale?: string | null;
    make?: string | null;
    model?: string | null;
    finish?: string | null;
    title?: string | null;
    description?: string | null;
    prop65Warning?: string | null;
    defaultForShop?: boolean | null;
  } | null> | null;
  traits?: Array<{
    __typename?: "core_apimessages_Trait";
    keyId?: string | null;
    valueId?: string | null;
  } | null> | null;
  condition?: {
    __typename?: "core_apimessages_Condition";
    conditionSlug?: string | null;
  } | null;
  video?: {
    __typename?: "core_apimessages_Video";
    link?: string | null;
  } | null;
  bumpRate?: {
    __typename?: "core_apimessages_BumpRate";
    rate?: number | null;
  } | null;
  images?: Array<{
    __typename?: "core_apimessages_Image";
    _id?: string | null;
    id?: string | null;
    source?: string | null;
    itemId?: string | null;
    version?: string | null;
    itemType?: string | null;
    publicId?: string | null;
    height?: number | null;
    width?: number | null;
    transformation?: {
      __typename?: "core_apimessages_ImageEntry_Transformation";
      x?: string | null;
      y?: string | null;
      width?: string | null;
      height?: string | null;
      crop?: string | null;
      angle?: number | null;
    } | null;
  } | null> | null;
  allShippingPrices?: {
    __typename?: "core_apimessages_ShippingPrices";
    localPickup?: boolean | null;
    shippingPrices?: Array<{
      __typename?: "core_apimessages_ShippingPrice";
      _id?: string | null;
      shippingRegionCode?: string | null;
      shippingMethod?: core_apimessages_ShippingMethod | null;
      carrierCalculated?: boolean | null;
      originalRateVatExcluded?: {
        __typename?: "core_apimessages_Money";
        amount?: string | null;
        currency?: string | null;
      } | null;
    } | null> | null;
  } | null;
  shipmentPackage?: {
    __typename?: "ShipmentPackage";
    _id?: string | null;
    allowedCarriers?: Array<string | null> | null;
    packageSizeSuggestionId?: string | null;
    length?: {
      __typename?: "core_apimessages_Measurement";
      value?: string | null;
      unit?: string | null;
    } | null;
    width?: {
      __typename?: "core_apimessages_Measurement";
      value?: string | null;
      unit?: string | null;
    } | null;
    height?: {
      __typename?: "core_apimessages_Measurement";
      value?: string | null;
      unit?: string | null;
    } | null;
    weight?: {
      __typename?: "core_apimessages_Measurement";
      value?: string | null;
      unit?: string | null;
    } | null;
  } | null;
  sellerPrice?: {
    __typename?: "core_apimessages_Money";
    amountCents?: number | null;
    currency?: string | null;
    amount?: string | null;
    display?: string | null;
  } | null;
  sellerCost?: {
    __typename?: "core_apimessages_Money";
    amountCents?: number | null;
    currency?: string | null;
    amount?: string | null;
    display?: string | null;
  } | null;
  sellerReportedMap?: {
    __typename?: "core_apimessages_Money";
    amountCents?: number | null;
    currency?: string | null;
    amount?: string | null;
    display?: string | null;
  } | null;
  preorderInfo?: {
    __typename?: "core_apimessages_PreorderInfo";
    leadTimeDays?: number | null;
    shipDate?: {
      __typename?: "google_protobuf_Timestamp";
      seconds?: number | null;
    } | null;
  } | null;
  shop?: {
    __typename?: "Shop";
    _id?: string | null;
    currencySetting?: string | null;
    defaultLocale?: string | null;
    shopSupportedLocaleCodes?: Array<string | null> | null;
    preferredSeller?: boolean | null;
    hasAccountRep?: boolean | null;
    inclusiveVatPricingEnabled?: boolean | null;
    vatId?: string | null;
    firstTimeSeller?: boolean | null;
    sameDayShippingConfigured?: boolean | null;
    listableConditions?: Array<{
      __typename?: "core_apimessages_Condition";
      displayName?: string | null;
      conditionSlug?: string | null;
    } | null> | null;
    offerPolicy?: {
      __typename?: "core_apimessages_ShopConfig_OfferPolicy";
      autoRejectLowOffers?: boolean | null;
      lowballPercentage?: number | null;
    } | null;
    returnPolicy?: {
      __typename?: "core_apimessages_ReturnPolicy";
      _id?: string | null;
      newReturnWindowDays?: number | null;
      usedReturnWindowDays?: number | null;
    } | null;
    taxPolicies?: Array<{
      __typename?: "core_apimessages_ShopTaxPolicy";
      vatPolicy?: boolean | null;
    } | null> | null;
    shippingRegionSettings?: Array<{
      __typename?: "core_apimessages_ShippingRegionSetting";
      regionCode?: string | null;
      regionName?: string | null;
      isDomestic?: boolean | null;
      allowedToShipCarrierCalculated?: boolean | null;
    } | null> | null;
    address?: {
      __typename?: "core_apimessages_Address";
      _id?: string | null;
      region?: string | null;
      postalCode?: string | null;
      countryCode?: string | null;
    } | null;
    shippingProfiles?: Array<{
      __typename?: "core_apimessages_ShippingProfile";
      name?: string | null;
      id?: string | null;
      categoryUuids?: Array<string | null> | null;
      localPickup?: boolean | null;
      allShippingRates?: Array<{
        __typename?: "core_apimessages_ShippingRates";
        regionCode?: string | null;
        regionName?: string | null;
        rateType?: string | null;
        rate?: {
          __typename?: "core_apimessages_Money";
          display?: string | null;
        } | null;
        incrementalRate?: {
          __typename?: "core_apimessages_Money";
          display?: string | null;
        } | null;
        expeditedRate?: {
          __typename?: "core_apimessages_Money";
          display?: string | null;
        } | null;
        regionalRates?: Array<{
          __typename?: "core_apimessages_RegionalShippingRate";
          areas?: Array<string | null> | null;
          rate?: {
            __typename?: "core_apimessages_Money";
            display?: string | null;
          } | null;
        } | null> | null;
        regionalExpeditedRates?: Array<{
          __typename?: "core_apimessages_RegionalShippingRate";
          areas?: Array<string | null> | null;
          rate?: {
            __typename?: "core_apimessages_Money";
            display?: string | null;
          } | null;
        } | null> | null;
      } | null> | null;
    } | null> | null;
  } | null;
  scheduledPriceDrops?: Array<{
    __typename?: "core_apimessages_ScheduledPriceDrop";
    active?: boolean | null;
    dropAt?: any | null;
    newPrice?: {
      __typename?: "core_apimessages_Money";
      amountCents?: number | null;
      currency?: string | null;
      amount?: string | null;
      display?: string | null;
    } | null;
  } | null> | null;
};

export type Core_PromotionalCodeVerifyQueryVariables = Exact<{
  token?: InputMaybe<Scalars["String"]["input"]>;
}>;

export type Core_PromotionalCodeVerifyQuery = {
  __typename?: "Query";
  promotionalCodeVerify?: {
    __typename?: "core_apimessages_VerifyPromotionalCodeResponse";
    success?: boolean | null;
    message?: string | null;
  } | null;
};

export type SellCardDataFragment = {
  __typename?: "CSP";
  _id?: string | null;
  id?: string | null;
  title?: string | null;
  finishes?: Array<string | null> | null;
  slug?: string | null;
  canonicalProductIds?: Array<string | null> | null;
  isTradeInEligible?: boolean | null;
  image?: {
    __typename?: "core_apimessages_Image";
    _id?: string | null;
    source?: string | null;
  } | null;
  brand?: {
    __typename?: "Brand";
    _id?: string | null;
    name?: string | null;
  } | null;
};

export type CSPPriceRecommendationDataFragment = {
  __typename?: "CSP";
  _id?: string | null;
  priceRecommendations?: Array<{
    __typename?: "reverb_pricing_PriceRecommendation";
    conditionUuid?: string | null;
    priceLow?: {
      __typename?: "reverb_pricing_Money";
      amountCents?: number | null;
      amount?: string | null;
      currency?: string | null;
    } | null;
    priceHigh?: {
      __typename?: "reverb_pricing_Money";
      amountCents?: number | null;
      amount?: string | null;
      currency?: string | null;
    } | null;
  } | null> | null;
};

export type SellCardWithCSPPriceRecommendationDataFragment = {
  __typename?: "CSP";
  _id?: string | null;
  id?: string | null;
  title?: string | null;
  finishes?: Array<string | null> | null;
  slug?: string | null;
  canonicalProductIds?: Array<string | null> | null;
  isTradeInEligible?: boolean | null;
  image?: {
    __typename?: "core_apimessages_Image";
    _id?: string | null;
    source?: string | null;
  } | null;
  brand?: {
    __typename?: "Brand";
    _id?: string | null;
    name?: string | null;
  } | null;
  priceRecommendations?: Array<{
    __typename?: "reverb_pricing_PriceRecommendation";
    conditionUuid?: string | null;
    priceLow?: {
      __typename?: "reverb_pricing_Money";
      amountCents?: number | null;
      amount?: string | null;
      currency?: string | null;
    } | null;
    priceHigh?: {
      __typename?: "reverb_pricing_Money";
      amountCents?: number | null;
      amount?: string | null;
      currency?: string | null;
    } | null;
  } | null> | null;
};

export type Core_SellFlow_SearchQueryVariables = Exact<{
  q?: InputMaybe<Scalars["String"]["input"]>;
  decades?: InputMaybe<
    | Array<InputMaybe<Scalars["String"]["input"]>>
    | InputMaybe<Scalars["String"]["input"]>
  >;
  finishes?: InputMaybe<
    | Array<InputMaybe<Scalars["String"]["input"]>>
    | InputMaybe<Scalars["String"]["input"]>
  >;
  brandNames?: InputMaybe<
    | Array<InputMaybe<Scalars["String"]["input"]>>
    | InputMaybe<Scalars["String"]["input"]>
  >;
  category_uuids?: InputMaybe<
    | Array<InputMaybe<Scalars["String"]["input"]>>
    | InputMaybe<Scalars["String"]["input"]>
  >;
  sellCardLimit?: InputMaybe<Scalars["Int"]["input"]>;
  excludedCategoryUuids?: InputMaybe<
    | Array<InputMaybe<Scalars["String"]["input"]>>
    | InputMaybe<Scalars["String"]["input"]>
  >;
  boostByClicks?: InputMaybe<Scalars["Boolean"]["input"]>;
  fullTextQueryOperand?: InputMaybe<reverb_search_FullTextQueryOperand>;
  sort?: InputMaybe<reverb_search_CSPSearchRequest_Sort>;
  fuzzy?: InputMaybe<Scalars["Boolean"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  listingsThatShipTo?: InputMaybe<Scalars["String"]["input"]>;
  hasExpressSaleBid: Scalars["Boolean"]["input"];
  includePriceRecommendations: Scalars["Boolean"]["input"];
  priceRecommendationCountryCode?: InputMaybe<Scalars["String"]["input"]>;
}>;

export type Core_SellFlow_SearchQuery = {
  __typename?: "Query";
  cspSearch?: {
    __typename?: "reverb_search_SearchResponse";
    total?: number | null;
    offset?: number | null;
    limit?: number | null;
    filters?: Array<{
      __typename?: "reverb_search_Filter";
      name?: string | null;
      key?: string | null;
      aggregationName?: string | null;
      widgetType?: reverb_search_Filter_WidgetType | null;
      options?: Array<{
        __typename?: "reverb_search_FilterOption";
        name?: string | null;
        selected?: boolean | null;
        paramName?: string | null;
        setValues?: Array<string | null> | null;
        unsetValues?: Array<string | null> | null;
        all?: boolean | null;
        optionValue?: string | null;
        count?: {
          __typename?: "google_protobuf_Int32Value";
          value?: number | null;
        } | null;
      } | null> | null;
    } | null> | null;
    csps?: Array<{
      __typename?: "CSP";
      _id?: string | null;
      id?: string | null;
      title?: string | null;
      finishes?: Array<string | null> | null;
      slug?: string | null;
      canonicalProductIds?: Array<string | null> | null;
      isTradeInEligible?: boolean | null;
      image?: {
        __typename?: "core_apimessages_Image";
        _id?: string | null;
        source?: string | null;
      } | null;
      brand?: {
        __typename?: "Brand";
        _id?: string | null;
        name?: string | null;
      } | null;
      priceRecommendations?: Array<{
        __typename?: "reverb_pricing_PriceRecommendation";
        conditionUuid?: string | null;
        priceLow?: {
          __typename?: "reverb_pricing_Money";
          amountCents?: number | null;
          amount?: string | null;
          currency?: string | null;
        } | null;
        priceHigh?: {
          __typename?: "reverb_pricing_Money";
          amountCents?: number | null;
          amount?: string | null;
          currency?: string | null;
        } | null;
      } | null> | null;
      expressSalePriceEstimate?: {
        __typename?: "reverb_pricing_PriceRecommendation";
        priceLow?: {
          __typename?: "reverb_pricing_Money";
          amountCents?: number | null;
          currency?: string | null;
        } | null;
        priceHigh?: {
          __typename?: "reverb_pricing_Money";
          amountCents?: number | null;
          currency?: string | null;
        } | null;
      } | null;
      expressSaleItemBid?: {
        __typename?: "core_apimessages_CSPExpressSaleItemBid";
        cspUuid?: string | null;
        bidId?: string | null;
        carrier?: string | null;
        shopName?: string | null;
        estimatedPayout?: {
          __typename?: "core_apimessages_Money";
          display?: string | null;
          amount?: string | null;
        } | null;
      } | null;
    } | null> | null;
  } | null;
};

export type Core_Trade_In_EligibilityQueryVariables = Exact<{
  cspId?: InputMaybe<Scalars["String"]["input"]>;
}>;

export type Core_Trade_In_EligibilityQuery = {
  __typename?: "Query";
  tradeInEligibility?: {
    __typename?: "TradeInEligibility";
    isEligible?: boolean | null;
  } | null;
};

export type Core_Sell_Search_AutocompleteQueryVariables = Exact<{
  query?: InputMaybe<Scalars["String"]["input"]>;
}>;

export type Core_Sell_Search_AutocompleteQuery = {
  __typename?: "Query";
  completions?: {
    __typename?: "reverb_search_SearchResponse";
    completions?: Array<{
      __typename?: "reverb_search_Completion";
      type?: reverb_search_CompletionType | null;
      options?: Array<{
        __typename?: "reverb_search_SuggestOption";
        slug?: string | null;
        id?: string | null;
        output?: string | null;
        completionPayload?: {
          __typename?: "reverb_search_SuggestOption_CompletionPayload";
          thumbnailUrl?: string | null;
          inventoryNew?: {
            __typename?: "reverb_search_Inventory";
            listingCount?: number | null;
            listingMinPrice?: {
              __typename?: "reverb_search_Money";
              cents?: number | null;
              currency?: string | null;
            } | null;
          } | null;
          inventoryUsed?: {
            __typename?: "reverb_search_Inventory";
            listingCount?: number | null;
            listingMinPrice?: {
              __typename?: "reverb_search_Money";
              cents?: number | null;
              currency?: string | null;
            } | null;
          } | null;
        } | null;
      } | null> | null;
    } | null> | null;
  } | null;
};

export type CoreSellerDashboardListingFragment = {
  __typename?: "Listing";
  _id?: string | null;
  id?: string | null;
  slug?: string | null;
  title?: string | null;
  sku?: string | null;
  price?: {
    __typename?: "core_apimessages_Money";
    display?: string | null;
    amountCents?: number | null;
    currency?: string | null;
  } | null;
  bumpRate?: {
    __typename?: "core_apimessages_BumpRate";
    rate?: number | null;
  } | null;
  bumpInteractionsCounts?: {
    __typename?: "arbiter_BumpInteractionCountsResponse";
    total?: number | null;
  } | null;
  images?: Array<{
    __typename?: "core_apimessages_Image";
    _id?: string | null;
    source?: string | null;
  } | null> | null;
  condition?: {
    __typename?: "core_apimessages_Condition";
    conditionUuid?: string | null;
    conditionSlug?: string | null;
    displayName?: string | null;
  } | null;
  csp?: {
    __typename?: "CSP";
    _id?: string | null;
    id?: string | null;
    title?: string | null;
    slug?: string | null;
  } | null;
  bumpRecommendations?: {
    __typename?: "reverb_search_BumpRecommendationsResults";
    bumpRecommendationAmount?: number | null;
  } | null;
};

export type Core_Seller_Dashboard_ListingsQueryVariables = Exact<{
  shopSlug?: InputMaybe<Scalars["String"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  categorySlugs?: InputMaybe<
    | Array<InputMaybe<Scalars["String"]["input"]>>
    | InputMaybe<Scalars["String"]["input"]>
  >;
  brandSlugs?: InputMaybe<
    | Array<InputMaybe<Scalars["String"]["input"]>>
    | InputMaybe<Scalars["String"]["input"]>
  >;
  conditionSlugs?: InputMaybe<
    | Array<InputMaybe<Scalars["String"]["input"]>>
    | InputMaybe<Scalars["String"]["input"]>
  >;
  query?: InputMaybe<Scalars["String"]["input"]>;
}>;

export type Core_Seller_Dashboard_ListingsQuery = {
  __typename?: "Query";
  listingsSearch?: {
    __typename?: "reverb_search_SearchResponse";
    total?: number | null;
    offset?: number | null;
    limit?: number | null;
    listings?: Array<{
      __typename?: "Listing";
      _id?: string | null;
      id?: string | null;
      slug?: string | null;
      title?: string | null;
      sku?: string | null;
      price?: {
        __typename?: "core_apimessages_Money";
        display?: string | null;
        amountCents?: number | null;
        currency?: string | null;
      } | null;
      bumpRate?: {
        __typename?: "core_apimessages_BumpRate";
        rate?: number | null;
      } | null;
      bumpInteractionsCounts?: {
        __typename?: "arbiter_BumpInteractionCountsResponse";
        total?: number | null;
      } | null;
      images?: Array<{
        __typename?: "core_apimessages_Image";
        _id?: string | null;
        source?: string | null;
      } | null> | null;
      condition?: {
        __typename?: "core_apimessages_Condition";
        conditionUuid?: string | null;
        conditionSlug?: string | null;
        displayName?: string | null;
      } | null;
      csp?: {
        __typename?: "CSP";
        _id?: string | null;
        id?: string | null;
        title?: string | null;
        slug?: string | null;
      } | null;
      bumpRecommendations?: {
        __typename?: "reverb_search_BumpRecommendationsResults";
        bumpRecommendationAmount?: number | null;
      } | null;
    } | null> | null;
  } | null;
};

export type Core_Seller_Dashboard_RevenueQueryVariables = Exact<{
  startDate?: InputMaybe<Scalars["String"]["input"]>;
  endDate?: InputMaybe<Scalars["String"]["input"]>;
  categorySlugs?: InputMaybe<
    | Array<InputMaybe<Scalars["String"]["input"]>>
    | InputMaybe<Scalars["String"]["input"]>
  >;
  brandSlugs?: InputMaybe<
    | Array<InputMaybe<Scalars["String"]["input"]>>
    | InputMaybe<Scalars["String"]["input"]>
  >;
  conditionSlugs?: InputMaybe<
    | Array<InputMaybe<Scalars["String"]["input"]>>
    | InputMaybe<Scalars["String"]["input"]>
  >;
  soldWithBumpOnly?: InputMaybe<Scalars["Boolean"]["input"]>;
  soldWithoutBumpOnly?: InputMaybe<Scalars["Boolean"]["input"]>;
}>;

export type Core_Seller_Dashboard_RevenueQuery = {
  __typename?: "Query";
  me?: {
    __typename?: "rql_Me";
    _id?: string | null;
    uuid?: string | null;
    shop?: {
      __typename?: "MyShop";
      _id?: string | null;
      slug?: string | null;
      interactions?: {
        __typename?: "arbiter_SellerInteractionTimeSeriesResponse";
        click?: number | null;
        addToCart?: number | null;
        addToWatchlist?: number | null;
        makeAnOffer?: number | null;
        total?: number | null;
      } | null;
      ordersSearch?: {
        __typename?: "reverb_search_SearchResponse";
        orderMetrics?: {
          __typename?: "reverb_search_OrderMetrics";
          orderCount?: number | null;
          totalSellerRevenueAmount?: {
            __typename?: "reverb_search_Money";
            cents?: number | null;
            currency?: string | null;
          } | null;
          totalBumpSpendAmount?: {
            __typename?: "reverb_search_Money";
            currency?: string | null;
            cents?: number | null;
          } | null;
        } | null;
        dateBuckets?: Array<{
          __typename?: "reverb_search_DateBucket";
          toDate?: string | null;
          fromDate?: string | null;
          metrics?: {
            __typename?: "reverb_search_OrderMetrics";
            totalSellerRevenueAmount?: {
              __typename?: "reverb_search_Money";
              cents?: number | null;
              currency?: string | null;
            } | null;
            totalBumpSpendAmount?: {
              __typename?: "reverb_search_Money";
              currency?: string | null;
              cents?: number | null;
            } | null;
          } | null;
        } | null> | null;
        filters?: Array<{
          __typename?: "reverb_search_Filter";
          name?: string | null;
          key?: string | null;
          aggregationName?: string | null;
          widgetType?: reverb_search_Filter_WidgetType | null;
          options?: Array<{
            __typename?: "reverb_search_FilterOption";
            name?: string | null;
            selected?: boolean | null;
            autoselected?: boolean | null;
            paramName?: string | null;
            setValues?: Array<string | null> | null;
            unsetValues?: Array<string | null> | null;
            all?: boolean | null;
            optionValue?: string | null;
            trackingValue?: string | null;
            count?: {
              __typename?: "google_protobuf_Int32Value";
              value?: number | null;
            } | null;
            subFilter?: {
              __typename?: "reverb_search_Filter";
              widgetType?: reverb_search_Filter_WidgetType | null;
              options?: Array<{
                __typename?: "reverb_search_FilterOption";
                name?: string | null;
                selected?: boolean | null;
                autoselected?: boolean | null;
                paramName?: string | null;
                setValues?: Array<string | null> | null;
                unsetValues?: Array<string | null> | null;
                all?: boolean | null;
                optionValue?: string | null;
                trackingValue?: string | null;
                count?: {
                  __typename?: "google_protobuf_Int32Value";
                  value?: number | null;
                } | null;
                subFilter?: {
                  __typename?: "reverb_search_Filter";
                  widgetType?: reverb_search_Filter_WidgetType | null;
                  options?: Array<{
                    __typename?: "reverb_search_FilterOption";
                    name?: string | null;
                    selected?: boolean | null;
                    autoselected?: boolean | null;
                    paramName?: string | null;
                    setValues?: Array<string | null> | null;
                    unsetValues?: Array<string | null> | null;
                    all?: boolean | null;
                    optionValue?: string | null;
                    trackingValue?: string | null;
                    count?: {
                      __typename?: "google_protobuf_Int32Value";
                      value?: number | null;
                    } | null;
                    subFilter?: {
                      __typename?: "reverb_search_Filter";
                      widgetType?: reverb_search_Filter_WidgetType | null;
                      options?: Array<{
                        __typename?: "reverb_search_FilterOption";
                        name?: string | null;
                        selected?: boolean | null;
                        autoselected?: boolean | null;
                        paramName?: string | null;
                        setValues?: Array<string | null> | null;
                        unsetValues?: Array<string | null> | null;
                        all?: boolean | null;
                        optionValue?: string | null;
                        trackingValue?: string | null;
                        count?: {
                          __typename?: "google_protobuf_Int32Value";
                          value?: number | null;
                        } | null;
                      } | null> | null;
                    } | null;
                  } | null> | null;
                } | null;
              } | null> | null;
            } | null;
          } | null> | null;
        } | null> | null;
      } | null;
    } | null;
  } | null;
};

export type Core_MyShopCampaigns_UpdateMutationVariables = Exact<{
  input?: InputMaybe<Input_core_apimessages_UpdateMyShopCampaignRequest>;
}>;

export type Core_MyShopCampaigns_UpdateMutation = {
  __typename?: "Mutation";
  crupdateMyShopCampaign?: {
    __typename?: "core_apimessages_MyShopCampaign";
    _id?: string | null;
    id?: string | null;
  } | null;
};

export type Core_Main_CategoriesQueryVariables = Exact<{
  entityId?: InputMaybe<Scalars["String"]["input"]>;
  entityType?: InputMaybe<core_apimessages_FormSelectCategoriesRequest_FormSelectEntityType>;
}>;

export type Core_Main_CategoriesQuery = {
  __typename?: "Query";
  formSelectCategories?: {
    __typename?: "core_apimessages_FormSelectCategoriesResponse";
    categories?: Array<{
      __typename?: "core_apimessages_FormSelectCategory";
      uuid?: string | null;
      name?: string | null;
    } | null> | null;
  } | null;
};

export type Core_MyShopCampaigns_IndexQueryVariables = Exact<{
  [key: string]: never;
}>;

export type Core_MyShopCampaigns_IndexQuery = {
  __typename?: "Query";
  me?: {
    __typename?: "rql_Me";
    _id?: string | null;
    uuid?: string | null;
    shop?: {
      __typename?: "MyShop";
      _id?: string | null;
      shopCampaigns?: Array<{
        __typename?: "core_apimessages_MyShopCampaign";
        _id?: string | null;
        id?: string | null;
        active?: boolean | null;
        code?: string | null;
        campaignType?: core_apimessages_MyShopCampaign_CampaignType | null;
        useRetroactiveSends?: boolean | null;
        applyToNewConditions?: boolean | null;
        applyToUsedConditions?: boolean | null;
        applyToBStockConditions?: boolean | null;
        discountSummary?: string | null;
        totalCouponCount?: number | null;
        redeemedCouponCount?: number | null;
        minPurchaseAmount?: {
          __typename?: "core_apimessages_Money";
          display?: string | null;
        } | null;
        excludedBrands?: Array<{
          __typename?: "Brand";
          _id?: string | null;
          name?: string | null;
        } | null> | null;
        excludedCategories?: Array<{
          __typename?: "Category";
          _id?: string | null;
          name?: string | null;
        } | null> | null;
      } | null> | null;
    } | null;
  } | null;
};

export type Core_MyShopCampaigns_QuickEditMutationVariables = Exact<{
  input?: InputMaybe<Input_core_apimessages_UpdateMyShopCampaignRequest>;
}>;

export type Core_MyShopCampaigns_QuickEditMutation = {
  __typename?: "Mutation";
  crupdateMyShopCampaign?: {
    __typename?: "core_apimessages_MyShopCampaign";
    _id?: string | null;
    id?: string | null;
  } | null;
};

export type Core_MyShopCampaigns_NewLayoutQueryVariables = Exact<{
  [key: string]: never;
}>;

export type Core_MyShopCampaigns_NewLayoutQuery = {
  __typename?: "Query";
  me?: {
    __typename?: "rql_Me";
    _id?: string | null;
    uuid?: string | null;
    shop?: {
      __typename?: "MyShop";
      _id?: string | null;
      name?: string | null;
      currencySetting?: string | null;
      shopCampaigns?: Array<{
        __typename?: "core_apimessages_MyShopCampaign";
        _id?: string | null;
        id?: string | null;
        active?: boolean | null;
        code?: string | null;
        campaignType?: core_apimessages_MyShopCampaign_CampaignType | null;
        discountPercent?: number | null;
        useRetroactiveSends?: boolean | null;
        applyToNewConditions?: boolean | null;
        applyToUsedConditions?: boolean | null;
        applyToBStockConditions?: boolean | null;
        discountAmount?: {
          __typename?: "core_apimessages_Money";
          display?: string | null;
        } | null;
        minPurchaseAmount?: {
          __typename?: "core_apimessages_Money";
          display?: string | null;
        } | null;
        maxDiscountAmount?: {
          __typename?: "core_apimessages_Money";
          display?: string | null;
        } | null;
        excludedBrands?: Array<{
          __typename?: "Brand";
          _id?: string | null;
          name?: string | null;
        } | null> | null;
        excludedCategories?: Array<{
          __typename?: "Category";
          _id?: string | null;
          name?: string | null;
        } | null> | null;
      } | null> | null;
    } | null;
  } | null;
};

export type ShopCampaignSummaryTableFragment = {
  __typename?: "core_apimessages_MyShopCampaign";
  _id?: string | null;
  id?: string | null;
  active?: boolean | null;
  code?: string | null;
  campaignType?: core_apimessages_MyShopCampaign_CampaignType | null;
  useRetroactiveSends?: boolean | null;
  applyToNewConditions?: boolean | null;
  applyToUsedConditions?: boolean | null;
  applyToBStockConditions?: boolean | null;
  discountSummary?: string | null;
  totalCouponCount?: number | null;
  redeemedCouponCount?: number | null;
  minPurchaseAmount?: {
    __typename?: "core_apimessages_Money";
    display?: string | null;
  } | null;
  excludedBrands?: Array<{
    __typename?: "Brand";
    _id?: string | null;
    name?: string | null;
  } | null> | null;
  excludedCategories?: Array<{
    __typename?: "Category";
    _id?: string | null;
    name?: string | null;
  } | null> | null;
};

export type Core_Payouts_MyDirectCheckoutProfileQueryVariables = Exact<{
  input?: InputMaybe<Input_core_apimessages_MyDirectCheckoutProfileRequest>;
}>;

export type Core_Payouts_MyDirectCheckoutProfileQuery = {
  __typename?: "Query";
  myDirectCheckoutProfile?: {
    __typename?: "core_apimessages_MyDirectCheckoutProfileResponse";
    plaidAccountId?: string | null;
    nameOnAccount?: string | null;
    maskedNameSummary?: string | null;
    bankName?: string | null;
    countryCode?: string | null;
    payoutMethod?: string | null;
    hasAcceptedReverbBankTerms?: boolean | null;
    profileErrors?: Array<string | null> | null;
    currency?: string | null;
  } | null;
};

export type ShopCardFieldsFragment = {
  __typename?: "Shop";
  _id?: string | null;
  name?: string | null;
  preferredSeller?: boolean | null;
  quickShipper?: boolean | null;
  address?: {
    __typename?: "core_apimessages_Address";
    _id?: string | null;
    displayLocation?: string | null;
  } | null;
  user?: {
    __typename?: "User";
    _id?: string | null;
    avatar?: {
      __typename?: "core_apimessages_Image";
      _id?: string | null;
      source?: string | null;
    } | null;
    feedbackSummary?: {
      __typename?: "core_apimessages_FeedbackSummary";
      rollingRatingPercentage?: number | null;
      receivedCount?: number | null;
    } | null;
  } | null;
};

export type MyShopOrderRefundStatsQueryVariables = Exact<{
  input?: InputMaybe<Input_core_apimessages_MyShopOrderRefundStatsRequest>;
}>;

export type MyShopOrderRefundStatsQuery = {
  __typename?: "Query";
  me?: {
    __typename?: "rql_Me";
    _id?: string | null;
    uuid?: string | null;
    shop?: {
      __typename?: "MyShop";
      _id?: string | null;
      uuid?: string | null;
      orderRefundStats?: {
        __typename?: "core_apimessages_MyShopOrderRefundStatsResponse";
        sellerInitiatedRefundCount?: number | null;
      } | null;
    } | null;
  } | null;
};

export type Core_ShopSettings_AuGstLayoutQueryVariables = Exact<{
  [key: string]: never;
}>;

export type Core_ShopSettings_AuGstLayoutQuery = {
  __typename?: "Query";
  me?: {
    __typename?: "rql_Me";
    _id?: string | null;
    uuid?: string | null;
    shop?: {
      __typename?: "MyShop";
      _id?: string | null;
      taxIdentifications?: Array<{
        __typename?: "core_apimessages_TaxIdentification";
        id?: string | null;
        taxId?: string | null;
        taxType?: core_apimessages_TaxIdentification_TaxType | null;
        status?: core_apimessages_TaxIdentification_Status | null;
        countryCode?: string | null;
      } | null> | null;
    } | null;
  } | null;
};

export type Core_ShopSettings_CreateTaxIdentificationMutationVariables = Exact<{
  input?: InputMaybe<Input_core_apimessages_CreateTaxIdentificationRequest>;
}>;

export type Core_ShopSettings_CreateTaxIdentificationMutation = {
  __typename?: "Mutation";
  createTaxIdentification?: {
    __typename?: "core_apimessages_CreateTaxIdentificationResponse";
    shop?: {
      __typename?: "MyShop";
      _id?: string | null;
      taxIdentifications?: Array<{
        __typename?: "core_apimessages_TaxIdentification";
        id?: string | null;
        taxId?: string | null;
        taxType?: core_apimessages_TaxIdentification_TaxType | null;
        status?: core_apimessages_TaxIdentification_Status | null;
        countryCode?: string | null;
      } | null> | null;
    } | null;
  } | null;
};

export type Core_ShopSettings_UpdateTaxIdentificationMutationVariables = Exact<{
  input?: InputMaybe<Input_core_apimessages_UpdateTaxIdentificationRequest>;
}>;

export type Core_ShopSettings_UpdateTaxIdentificationMutation = {
  __typename?: "Mutation";
  updateTaxIdentification?: {
    __typename?: "core_apimessages_UpdateTaxIdentificationResponse";
    shop?: {
      __typename?: "MyShop";
      _id?: string | null;
      taxIdentifications?: Array<{
        __typename?: "core_apimessages_TaxIdentification";
        id?: string | null;
        taxId?: string | null;
        status?: core_apimessages_TaxIdentification_Status | null;
        countryCode?: string | null;
      } | null> | null;
    } | null;
  } | null;
};

export type Core_ShopSettings_DeactivateTaxIdentificationMutationVariables =
  Exact<{
    input?: InputMaybe<Input_core_apimessages_DeactivateTaxIdentificationRequest>;
  }>;

export type Core_ShopSettings_DeactivateTaxIdentificationMutation = {
  __typename?: "Mutation";
  deactivateTaxIdentification?: {
    __typename?: "core_apimessages_DeactivateTaxIdentificationResponse";
    shop?: {
      __typename?: "MyShop";
      _id?: string | null;
      taxIdentifications?: Array<{
        __typename?: "core_apimessages_TaxIdentification";
        id?: string | null;
        taxId?: string | null;
        status?: core_apimessages_TaxIdentification_Status | null;
        countryCode?: string | null;
        active?: boolean | null;
      } | null> | null;
    } | null;
  } | null;
};

export type Core_SecondaryUsers_MySecondaryUsersQueryVariables = Exact<{
  [key: string]: never;
}>;

export type Core_SecondaryUsers_MySecondaryUsersQuery = {
  __typename?: "Query";
  me?: {
    __typename?: "rql_Me";
    _id?: string | null;
    name?: string | null;
    shop?: {
      __typename?: "MyShop";
      _id?: string | null;
      userShopPermissions?: Array<{
        __typename?: "UserShopPermission";
        _id?: string | null;
        id?: string | null;
        email?: string | null;
        permissionLevel?: core_apimessages_UserShopPermission_PermissionLevel | null;
        user?: {
          __typename?: "User";
          _id?: string | null;
          id?: string | null;
          name?: string | null;
        } | null;
      } | null> | null;
    } | null;
  } | null;
};

export type Core_SecondaryUsers_DeleteUserShopPermissionMutationVariables =
  Exact<{
    input?: InputMaybe<Input_core_apimessages_DeleteUserShopPermissionRequest>;
  }>;

export type Core_SecondaryUsers_DeleteUserShopPermissionMutation = {
  __typename?: "Mutation";
  deleteUserShopPermission?: {
    __typename?: "core_apimessages_DeleteUserShopPermissionResponse";
    id?: string | null;
  } | null;
};

export type Core_SecondaryUsers_ExportActivitiesMutationVariables = Exact<{
  input?: InputMaybe<Input_core_apimessages_ExportSecondaryUserActivitiesRequest>;
}>;

export type Core_SecondaryUsers_ExportActivitiesMutation = {
  __typename?: "Mutation";
  exportSecondaryUserActivities?: {
    __typename?: "core_apimessages_ExportSecondaryUserActivitiesResponse";
    enqueued?: boolean | null;
  } | null;
};

export type Core_SecondaryUsers_CreateUserShopPermissionMutationVariables =
  Exact<{
    input?: InputMaybe<Input_core_apimessages_CreateUserShopPermissionRequest>;
  }>;

export type Core_SecondaryUsers_CreateUserShopPermissionMutation = {
  __typename?: "Mutation";
  createUserShopPermission?: {
    __typename?: "core_apimessages_CreateOrUpdateUserShopPermissionResponse";
    userShopPermission?: {
      __typename?: "core_apimessages_UserShopPermission";
      id?: string | null;
      email?: string | null;
      permissionLevel?: core_apimessages_UserShopPermission_PermissionLevel | null;
    } | null;
  } | null;
};

export type Core_SecondaryUsers_UpdateUserShopPermissionMutationVariables =
  Exact<{
    input?: InputMaybe<Input_core_apimessages_UpdateUserShopPermissionRequest>;
  }>;

export type Core_SecondaryUsers_UpdateUserShopPermissionMutation = {
  __typename?: "Mutation";
  updateUserShopPermission?: {
    __typename?: "core_apimessages_CreateOrUpdateUserShopPermissionResponse";
    userShopPermission?: {
      __typename?: "core_apimessages_UserShopPermission";
      id?: string | null;
      email?: string | null;
      permissionLevel?: core_apimessages_UserShopPermission_PermissionLevel | null;
    } | null;
  } | null;
};

export type Core_ShopSettings_VatLayoutQueryVariables = Exact<{
  [key: string]: never;
}>;

export type Core_ShopSettings_VatLayoutQuery = {
  __typename?: "Query";
  me?: {
    __typename?: "rql_Me";
    _id?: string | null;
    uuid?: string | null;
    shop?: {
      __typename?: "MyShop";
      _id?: string | null;
      taxIdentifications?: Array<{
        __typename?: "core_apimessages_TaxIdentification";
        id?: string | null;
        taxId?: string | null;
        taxType?: core_apimessages_TaxIdentification_TaxType | null;
        status?: core_apimessages_TaxIdentification_Status | null;
        countryCode?: string | null;
      } | null> | null;
      address?: {
        __typename?: "core_apimessages_Address";
        _id?: string | null;
        country?: {
          __typename?: "core_apimessages_Country";
          _id?: string | null;
          countryCode?: string | null;
        } | null;
      } | null;
    } | null;
  } | null;
  countries?: {
    __typename?: "core_apimessages_CountriesResponse";
    countries?: Array<{
      __typename?: "core_apimessages_Country";
      _id?: string | null;
      countryCode?: string | null;
      name?: string | null;
    } | null> | null;
  } | null;
};

export type Core_User_SubscriptionsQueryVariables = Exact<{
  [key: string]: never;
}>;

export type Core_User_SubscriptionsQuery = {
  __typename?: "Query";
  me?: {
    __typename?: "rql_Me";
    _id?: string | null;
    uuid?: string | null;
    requireOptIn?: boolean | null;
    emailGlobalSubscribe?: rql_SubscribeState | null;
    emailSubscriptions?: Array<{
      __typename?: "rql_EmailSubscription";
      id?: string | null;
      subscribed?: boolean | null;
      translationKey?: rql_EmailSubscription_TranslationKey | null;
    } | null> | null;
  } | null;
};

export type Update_Email_SubscriptionMutationVariables = Exact<{
  input?: InputMaybe<Input_rql_EmailSubscription>;
}>;

export type Update_Email_SubscriptionMutation = {
  __typename?: "Mutation";
  setEmailSubscription?: {
    __typename?: "rql_EmailSubscription";
    id?: string | null;
    subscribed?: boolean | null;
    translationKey?: rql_EmailSubscription_TranslationKey | null;
  } | null;
};

export type Set_Email_Global_SubscribeMutationVariables = Exact<{
  input?: InputMaybe<Input_rql_SetGlobalSubscribeRequest>;
}>;

export type Set_Email_Global_SubscribeMutation = {
  __typename?: "Mutation";
  setEmailGlobalSubscribe?: {
    __typename?: "rql_Me";
    _id?: string | null;
    uuid?: string | null;
    emailGlobalSubscribe?: rql_SubscribeState | null;
  } | null;
};

export type Trigger_Opt_In_EmailMutationVariables = Exact<{
  input?: InputMaybe<Input_rql_Blank>;
}>;

export type Trigger_Opt_In_EmailMutation = {
  __typename?: "Mutation";
  triggerOptInEmail?: {
    __typename?: "braze_TriggerCampaignSendResponse";
    dispatchId?: string | null;
  } | null;
};

export type RequestTaxFormDownloadMutationVariables = Exact<{
  input?: InputMaybe<Input_core_apimessages_RequestTaxFormDownloadRequest>;
}>;

export type RequestTaxFormDownloadMutation = {
  __typename?: "Mutation";
  requestTaxFormDownload?: {
    __typename?: "core_apimessages_RequestTaxFormDownloadResponse";
    error?: string | null;
    taxFormDownload?: {
      __typename?: "core_apimessages_TaxFormDownload";
      id?: string | null;
      status?: core_apimessages_TaxFormDownload_Status | null;
    } | null;
  } | null;
};

export type FetchTaxFormDownloadMutationVariables = Exact<{
  input?: InputMaybe<Input_core_apimessages_TaxFormDownloadRequest>;
}>;

export type FetchTaxFormDownloadMutation = {
  __typename?: "Mutation";
  taxFormDownload?: {
    __typename?: "core_apimessages_TaxFormDownloadResponse";
    taxFormDownload?: {
      __typename?: "core_apimessages_TaxFormDownload";
      id?: string | null;
      status?: core_apimessages_TaxFormDownload_Status | null;
      url?: string | null;
    } | null;
  } | null;
};

export type Core_My_TaxProfile_QueryQueryVariables = Exact<{
  [key: string]: never;
}>;

export type Core_My_TaxProfile_QueryQuery = {
  __typename?: "Query";
  me?: {
    __typename?: "rql_Me";
    _id?: string | null;
    uuid?: string | null;
    shop?: {
      __typename?: "MyShop";
      _id?: string | null;
      address?: {
        __typename?: "core_apimessages_Address";
        _id?: string | null;
        uuid?: string | null;
        name?: string | null;
        streetAddress?: string | null;
        extendedAddress?: string | null;
        phone?: string | null;
        locality?: string | null;
        region?: string | null;
        postalCode?: string | null;
        country?: {
          __typename?: "core_apimessages_Country";
          _id?: string | null;
          countryCode?: string | null;
          name?: string | null;
        } | null;
      } | null;
      taxProfile?: {
        __typename?: "core_apimessages_TaxProfile";
        firstName?: string | null;
        id?: string | null;
        lastName?: string | null;
        mask?: string | null;
        name?: string | null;
        tinType?: string | null;
        verificationStatus?: core_apimessages_TaxProfile_TaxProfileVerificationStatus | null;
        verifiedAt?: any | null;
        address?: {
          __typename?: "core_apimessages_Address";
          _id?: string | null;
          uuid?: string | null;
          name?: string | null;
          streetAddress?: string | null;
          extendedAddress?: string | null;
          phone?: string | null;
          locality?: string | null;
          region?: string | null;
          regionName?: string | null;
          postalCode?: string | null;
          country?: {
            __typename?: "core_apimessages_Country";
            _id?: string | null;
            countryCode?: string | null;
            name?: string | null;
          } | null;
        } | null;
      } | null;
    } | null;
  } | null;
};

export type Core_DeleteTaxProfileMutationVariables = Exact<{
  [key: string]: never;
}>;

export type Core_DeleteTaxProfileMutation = {
  __typename?: "Mutation";
  deleteTaxProfile?: {
    __typename?: "core_apimessages_TaxProfile";
    id?: string | null;
  } | null;
};

export type Core_UpsertTaxProfileMutationVariables = Exact<{
  input?: InputMaybe<InputTaxProfileUpsertRequest>;
}>;

export type Core_UpsertTaxProfileMutation = {
  __typename?: "Mutation";
  upsertTaxProfile?: {
    __typename?: "core_apimessages_TaxProfile";
    id?: string | null;
  } | null;
};

export type Core_CreateTradeInMutationVariables = Exact<{
  input: CreateTradeInInput;
}>;

export type Core_CreateTradeInMutation = {
  __typename?: "Mutation";
  createTradeIn?: {
    __typename?: "TradeIn";
    _id?: string | null;
    id?: string | null;
    dealerAddress?: {
      __typename?: "core_apimessages_Address";
      _id?: string | null;
      streetAddress?: string | null;
      locality?: string | null;
      region?: string | null;
      postalCode?: string | null;
    } | null;
  } | null;
};

export type Core_Trade_In_CP_EligibilityQueryVariables = Exact<{
  canonicalProductId?: InputMaybe<Scalars["String"]["input"]>;
}>;

export type Core_Trade_In_CP_EligibilityQuery = {
  __typename?: "Query";
  canonicalProductEligibility?: {
    __typename?: "TradeInEligibility";
    isEligible?: boolean | null;
  } | null;
};

export type CartApplePayHookFieldsFragment = {
  __typename?: "Checkout";
  _id?: string | null;
  id?: string | null;
  type?: core_apimessages_Checkout_Type | null;
  total?: {
    __typename?: "core_apimessages_Checkout_Total";
    amount?: {
      __typename?: "core_apimessages_Money";
      amountCents?: number | null;
      currency?: string | null;
      amount?: string | null;
    } | null;
  } | null;
  lineItems?: Array<{
    __typename?: "core_apimessages_CheckoutLineItem";
    type?: core_apimessages_CheckoutLineItem_Type | null;
    label?: string | null;
    amount?: {
      __typename?: "core_apimessages_Money";
      amount?: string | null;
      amountCents?: number | null;
      currency?: string | null;
    } | null;
  } | null> | null;
};

export type Core_Checkout_StartApplePayCheckoutMutationVariables = Exact<{
  input?: InputMaybe<Input_core_apimessages_StartCheckoutRequest>;
}>;

export type Core_Checkout_StartApplePayCheckoutMutation = {
  __typename?: "Mutation";
  startCheckout?: {
    __typename?: "core_apimessages_StartCheckoutResponse";
    checkout?: {
      __typename?: "Checkout";
      _id?: string | null;
      id?: string | null;
      type?: core_apimessages_Checkout_Type | null;
      total?: {
        __typename?: "core_apimessages_Checkout_Total";
        amount?: {
          __typename?: "core_apimessages_Money";
          amountCents?: number | null;
          currency?: string | null;
          amount?: string | null;
        } | null;
      } | null;
      lineItems?: Array<{
        __typename?: "core_apimessages_CheckoutLineItem";
        type?: core_apimessages_CheckoutLineItem_Type | null;
        label?: string | null;
        amount?: {
          __typename?: "core_apimessages_Money";
          amount?: string | null;
          amountCents?: number | null;
          currency?: string | null;
        } | null;
      } | null> | null;
    } | null;
  } | null;
};

export type Core_Checkout_UpdateApplePayShippingEstimateMutationVariables =
  Exact<{
    input?: InputMaybe<Input_core_apimessages_CheckoutUpdateShippingEstimateRequest>;
  }>;

export type Core_Checkout_UpdateApplePayShippingEstimateMutation = {
  __typename?: "Mutation";
  updateCheckoutShippingEstimate?: {
    __typename?: "core_apimessages_CheckoutUpdateShippingEstimateResponse";
    checkout?: {
      __typename?: "Checkout";
      _id?: string | null;
      id?: string | null;
      type?: core_apimessages_Checkout_Type | null;
      total?: {
        __typename?: "core_apimessages_Checkout_Total";
        amount?: {
          __typename?: "core_apimessages_Money";
          amountCents?: number | null;
          currency?: string | null;
          amount?: string | null;
        } | null;
      } | null;
      lineItems?: Array<{
        __typename?: "core_apimessages_CheckoutLineItem";
        type?: core_apimessages_CheckoutLineItem_Type | null;
        label?: string | null;
        amount?: {
          __typename?: "core_apimessages_Money";
          amount?: string | null;
          amountCents?: number | null;
          currency?: string | null;
        } | null;
      } | null> | null;
    } | null;
  } | null;
};

export type Item2ProductViewTrackingDataFragment = {
  __typename?: "Listing";
  _id?: string | null;
  acceptedPaymentMethods?: Array<string | null> | null;
  id?: string | null;
  title?: string | null;
  brandSlug?: string | null;
  categoryRootUuid?: string | null;
  make?: string | null;
  categoryUuids?: Array<string | null> | null;
  state?: string | null;
  listingType?: core_apimessages_Listing_ListingType | null;
  bumpEligible?: boolean | null;
  shopId?: string | null;
  inventory?: number | null;
  soldAsIs?: boolean | null;
  currency?: string | null;
  usOutlet?: boolean | null;
  condition?: {
    __typename?: "core_apimessages_Condition";
    conditionUuid?: string | null;
    conditionSlug?: string | null;
  } | null;
  categories?: Array<{
    __typename?: "Category";
    _id?: string | null;
    slug?: string | null;
    rootSlug?: string | null;
  } | null> | null;
  csp?: {
    __typename?: "CSP";
    _id?: string | null;
    id?: string | null;
    slug?: string | null;
    brand?: {
      __typename?: "Brand";
      _id?: string | null;
      slug?: string | null;
    } | null;
  } | null;
  pricing?: {
    __typename?: "core_apimessages_ListingPricing";
    buyerPrice?: {
      __typename?: "core_apimessages_Money";
      amount?: string | null;
      currency?: string | null;
      amountCents?: number | null;
    } | null;
  } | null;
  publishedAt?: {
    __typename?: "google_protobuf_Timestamp";
    seconds?: number | null;
  } | null;
  sale?: {
    __typename?: "core_apimessages_Sale";
    _id?: string | null;
    id?: string | null;
    code?: string | null;
    buyerIneligibilityReason?: string | null;
  } | null;
  shipping?: {
    __typename?: "core_apimessages_ShippingPrices";
    freeExpeditedShipping?: boolean | null;
    localPickupOnly?: boolean | null;
    localPickup?: boolean | null;
    shippingPrices?: Array<{
      __typename?: "core_apimessages_ShippingPrice";
      _id?: string | null;
      shippingMethod?: core_apimessages_ShippingMethod | null;
      carrierCalculated?: boolean | null;
      destinationPostalCodeNeeded?: boolean | null;
      rate?: {
        __typename?: "core_apimessages_Money";
        amount?: string | null;
        amountCents?: number | null;
        currency?: string | null;
        display?: string | null;
      } | null;
    } | null> | null;
  } | null;
  certifiedPreOwned?: {
    __typename?: "core_apimessages_ListingCertifiedPreOwned";
    title?: string | null;
    badgeIconUrl?: string | null;
  } | null;
  shop?: {
    __typename?: "Shop";
    _id?: string | null;
    address?: {
      __typename?: "core_apimessages_Address";
      _id?: string | null;
      countryCode?: string | null;
    } | null;
    returnPolicy?: {
      __typename?: "core_apimessages_ReturnPolicy";
      _id?: string | null;
      newReturnWindowDays?: number | null;
      usedReturnWindowDays?: number | null;
    } | null;
  } | null;
};

export type User_Available_ActionsQueryVariables = Exact<{
  loggedOut: Scalars["Boolean"]["input"];
}>;

export type User_Available_ActionsQuery = {
  __typename?: "Query";
  me?: {
    __typename?: "rql_Me";
    _id?: string | null;
    uuid?: string | null;
    availableActions?: Array<{
      __typename?: "core_apimessages_AvailableAction";
      id?: core_apimessages_UserActionName | null;
      name?: core_apimessages_UserActionName | null;
      status?: core_apimessages_UserActionStatus | null;
    } | null> | null;
  } | null;
};

export type Core_User_Watchlist_LayoutQueryVariables = Exact<{
  useLiveListings: Scalars["Boolean"]["input"];
  profileSlug?: InputMaybe<Scalars["String"]["input"]>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
}>;

export type Core_User_Watchlist_LayoutQuery = {
  __typename?: "Query";
  liveWatches?: {
    __typename?: "WatchCollection";
    pageInfo?: { __typename?: "rql_PageInfo"; total?: number | null } | null;
    nodes?: Array<{
      __typename?: "Watch";
      _id?: string | null;
      listing?: {
        __typename?: "Listing";
        _id?: string | null;
        id?: string | null;
        categoryUuids?: Array<string | null> | null;
        state?: string | null;
        sellerId?: string | null;
        listingType?: core_apimessages_Listing_ListingType | null;
        offersEnabled?: boolean | null;
        isBuyerOfferEligible?: boolean | null;
        otherBuyersWithListingInCartCounts?: number | null;
        title?: string | null;
        slug?: string | null;
        make?: string | null;
        model?: string | null;
        upc?: string | null;
        stateDescription?: string | null;
        bumped?: boolean | null;
        watching?: boolean | null;
        soldAsIs?: boolean | null;
        usOutlet?: boolean | null;
        brandSlug?: string | null;
        categoryRootUuid?: string | null;
        bumpEligible?: boolean | null;
        shopId?: string | null;
        inventory?: number | null;
        acceptedPaymentMethods?: Array<string | null> | null;
        currency?: string | null;
        shop?: {
          __typename?: "Shop";
          _id?: string | null;
          name?: string | null;
          slug?: string | null;
          preferredSeller?: boolean | null;
          quickShipper?: boolean | null;
          quickResponder?: boolean | null;
          address?: {
            __typename?: "core_apimessages_Address";
            _id?: string | null;
            locality?: string | null;
            region?: string | null;
            displayLocation?: string | null;
            countryCode?: string | null;
            country?: {
              __typename?: "core_apimessages_Country";
              _id?: string | null;
              countryCode?: string | null;
              name?: string | null;
            } | null;
          } | null;
          returnPolicy?: {
            __typename?: "core_apimessages_ReturnPolicy";
            usedReturnWindowDays?: number | null;
            newReturnWindowDays?: number | null;
            _id?: string | null;
          } | null;
        } | null;
        seller?: {
          __typename?: "User";
          _id?: string | null;
          feedbackSummary?: {
            __typename?: "core_apimessages_FeedbackSummary";
            receivedCount?: number | null;
            rollingRatingPercentage?: number | null;
          } | null;
        } | null;
        csp?: {
          __typename?: "CSP";
          _id?: string | null;
          id?: string | null;
          slug?: string | null;
          webLink?: {
            __typename?: "core_apimessages_Link";
            href?: string | null;
          } | null;
          brand?: {
            __typename?: "Brand";
            _id?: string | null;
            slug?: string | null;
          } | null;
        } | null;
        pricing?: {
          __typename?: "core_apimessages_ListingPricing";
          buyerPrice?: {
            __typename?: "core_apimessages_Money";
            amount?: string | null;
            amountCents?: number | null;
            display?: string | null;
            currency?: string | null;
          } | null;
          originalPrice?: {
            __typename?: "core_apimessages_Money";
            display?: string | null;
          } | null;
          ribbon?: {
            __typename?: "core_apimessages_Ribbon";
            display?: string | null;
            reason?: core_apimessages_Ribbon_Reason | null;
          } | null;
        } | null;
        preorderInfo?: {
          __typename?: "core_apimessages_PreorderInfo";
          onPreorder?: boolean | null;
          estimatedShipDate?: {
            __typename?: "google_protobuf_Timestamp";
            seconds?: number | null;
          } | null;
        } | null;
        publishedAt?: {
          __typename?: "google_protobuf_Timestamp";
          seconds?: number | null;
        } | null;
        condition?: {
          __typename?: "core_apimessages_Condition";
          displayName?: string | null;
          conditionSlug?: string | null;
          conditionUuid?: string | null;
        } | null;
        images?: Array<{
          __typename?: "core_apimessages_Image";
          source?: string | null;
        } | null> | null;
        shipping?: {
          __typename?: "core_apimessages_ShippingPrices";
          freeExpeditedShipping?: boolean | null;
          localPickupOnly?: boolean | null;
          localPickup?: boolean | null;
          shippingPrices?: Array<{
            __typename?: "core_apimessages_ShippingPrice";
            _id?: string | null;
            shippingMethod?: core_apimessages_ShippingMethod | null;
            carrierCalculated?: boolean | null;
            destinationPostalCodeNeeded?: boolean | null;
            regional?: boolean | null;
            postalCode?: string | null;
            rate?: {
              __typename?: "core_apimessages_Money";
              amount?: string | null;
              amountCents?: number | null;
              currency?: string | null;
              display?: string | null;
            } | null;
          } | null> | null;
        } | null;
        watchThumbnails?: Array<{
          __typename?: "core_apimessages_Image";
          _id?: string | null;
          source?: string | null;
        } | null> | null;
        categories?: Array<{
          __typename?: "Category";
          _id?: string | null;
          slug?: string | null;
          rootSlug?: string | null;
        } | null> | null;
        sale?: {
          __typename?: "core_apimessages_Sale";
          _id?: string | null;
          id?: string | null;
          code?: string | null;
          buyerIneligibilityReason?: string | null;
        } | null;
        certifiedPreOwned?: {
          __typename?: "core_apimessages_ListingCertifiedPreOwned";
          title?: string | null;
          badgeIconUrl?: string | null;
        } | null;
        priceRecommendation?: {
          __typename?: "reverb_pricing_PriceRecommendation";
          priceMiddle?: {
            __typename?: "reverb_pricing_Money";
            amountCents?: number | null;
            currency?: string | null;
          } | null;
        } | null;
      } | null;
    } | null> | null;
  } | null;
  endedWatches?: {
    __typename?: "WatchCollection";
    pageInfo?: { __typename?: "rql_PageInfo"; total?: number | null } | null;
    nodes?: Array<{
      __typename?: "Watch";
      _id?: string | null;
      listing?: {
        __typename?: "Listing";
        _id?: string | null;
        id?: string | null;
        categoryUuids?: Array<string | null> | null;
        state?: string | null;
        sellerId?: string | null;
        listingType?: core_apimessages_Listing_ListingType | null;
        offersEnabled?: boolean | null;
        isBuyerOfferEligible?: boolean | null;
        otherBuyersWithListingInCartCounts?: number | null;
        title?: string | null;
        slug?: string | null;
        make?: string | null;
        model?: string | null;
        upc?: string | null;
        stateDescription?: string | null;
        bumped?: boolean | null;
        watching?: boolean | null;
        soldAsIs?: boolean | null;
        usOutlet?: boolean | null;
        brandSlug?: string | null;
        categoryRootUuid?: string | null;
        bumpEligible?: boolean | null;
        shopId?: string | null;
        inventory?: number | null;
        acceptedPaymentMethods?: Array<string | null> | null;
        currency?: string | null;
        shop?: {
          __typename?: "Shop";
          _id?: string | null;
          name?: string | null;
          slug?: string | null;
          preferredSeller?: boolean | null;
          quickShipper?: boolean | null;
          quickResponder?: boolean | null;
          address?: {
            __typename?: "core_apimessages_Address";
            _id?: string | null;
            locality?: string | null;
            region?: string | null;
            displayLocation?: string | null;
            countryCode?: string | null;
            country?: {
              __typename?: "core_apimessages_Country";
              _id?: string | null;
              countryCode?: string | null;
              name?: string | null;
            } | null;
          } | null;
          returnPolicy?: {
            __typename?: "core_apimessages_ReturnPolicy";
            usedReturnWindowDays?: number | null;
            newReturnWindowDays?: number | null;
            _id?: string | null;
          } | null;
        } | null;
        seller?: {
          __typename?: "User";
          _id?: string | null;
          feedbackSummary?: {
            __typename?: "core_apimessages_FeedbackSummary";
            receivedCount?: number | null;
            rollingRatingPercentage?: number | null;
          } | null;
        } | null;
        csp?: {
          __typename?: "CSP";
          _id?: string | null;
          id?: string | null;
          slug?: string | null;
          webLink?: {
            __typename?: "core_apimessages_Link";
            href?: string | null;
          } | null;
          brand?: {
            __typename?: "Brand";
            _id?: string | null;
            slug?: string | null;
          } | null;
        } | null;
        pricing?: {
          __typename?: "core_apimessages_ListingPricing";
          buyerPrice?: {
            __typename?: "core_apimessages_Money";
            amount?: string | null;
            amountCents?: number | null;
            display?: string | null;
            currency?: string | null;
          } | null;
          originalPrice?: {
            __typename?: "core_apimessages_Money";
            display?: string | null;
          } | null;
          ribbon?: {
            __typename?: "core_apimessages_Ribbon";
            display?: string | null;
            reason?: core_apimessages_Ribbon_Reason | null;
          } | null;
        } | null;
        preorderInfo?: {
          __typename?: "core_apimessages_PreorderInfo";
          onPreorder?: boolean | null;
          estimatedShipDate?: {
            __typename?: "google_protobuf_Timestamp";
            seconds?: number | null;
          } | null;
        } | null;
        publishedAt?: {
          __typename?: "google_protobuf_Timestamp";
          seconds?: number | null;
        } | null;
        condition?: {
          __typename?: "core_apimessages_Condition";
          displayName?: string | null;
          conditionSlug?: string | null;
          conditionUuid?: string | null;
        } | null;
        images?: Array<{
          __typename?: "core_apimessages_Image";
          source?: string | null;
        } | null> | null;
        shipping?: {
          __typename?: "core_apimessages_ShippingPrices";
          freeExpeditedShipping?: boolean | null;
          localPickupOnly?: boolean | null;
          localPickup?: boolean | null;
          shippingPrices?: Array<{
            __typename?: "core_apimessages_ShippingPrice";
            _id?: string | null;
            shippingMethod?: core_apimessages_ShippingMethod | null;
            carrierCalculated?: boolean | null;
            destinationPostalCodeNeeded?: boolean | null;
            regional?: boolean | null;
            postalCode?: string | null;
            rate?: {
              __typename?: "core_apimessages_Money";
              amount?: string | null;
              amountCents?: number | null;
              currency?: string | null;
              display?: string | null;
            } | null;
          } | null> | null;
        } | null;
        watchThumbnails?: Array<{
          __typename?: "core_apimessages_Image";
          _id?: string | null;
          source?: string | null;
        } | null> | null;
        categories?: Array<{
          __typename?: "Category";
          _id?: string | null;
          slug?: string | null;
          rootSlug?: string | null;
        } | null> | null;
        sale?: {
          __typename?: "core_apimessages_Sale";
          _id?: string | null;
          id?: string | null;
          code?: string | null;
          buyerIneligibilityReason?: string | null;
        } | null;
        certifiedPreOwned?: {
          __typename?: "core_apimessages_ListingCertifiedPreOwned";
          title?: string | null;
          badgeIconUrl?: string | null;
        } | null;
        priceRecommendation?: {
          __typename?: "reverb_pricing_PriceRecommendation";
          priceMiddle?: {
            __typename?: "reverb_pricing_Money";
            amountCents?: number | null;
            currency?: string | null;
          } | null;
        } | null;
      } | null;
    } | null> | null;
  } | null;
  user?: {
    __typename?: "User";
    _id?: string | null;
    firstName?: string | null;
  } | null;
};

export type MarketplaceQuerySuggestionsQueryVariables = Exact<{
  searchQueries?: InputMaybe<
    | Array<InputMaybe<Input_reverb_personalization_SearchQuery>>
    | InputMaybe<Input_reverb_personalization_SearchQuery>
  >;
}>;

export type MarketplaceQuerySuggestionsQuery = {
  __typename?: "Query";
  querySuggestions?: {
    __typename?: "reverb_personalization_QuerySuggestionsResponse";
    queries?: Array<{
      __typename?: "reverb_personalization_QuerySuggestionResponse";
      suggestions?: Array<{
        __typename?: "reverb_personalization_SearchQuery";
        query?: string | null;
        listings?: Array<{
          __typename?: "Listing";
          _id?: string | null;
          id?: string | null;
          images?: Array<{
            __typename?: "core_apimessages_Image";
            _id?: string | null;
            source?: string | null;
          } | null> | null;
        } | null> | null;
      } | null> | null;
    } | null> | null;
  } | null;
};

export type Core_WithSuggestionCheck_ListingsSearchQueryVariables = Exact<{
  query?: InputMaybe<Scalars["String"]["input"]>;
}>;

export type Core_WithSuggestionCheck_ListingsSearchQuery = {
  __typename?: "Query";
  listingsSearch?: {
    __typename?: "reverb_search_SearchResponse";
    total?: number | null;
  } | null;
};

export type Core_AcceptTradeInMutationVariables = Exact<{
  input: AcceptTradeInInput;
}>;

export type Core_AcceptTradeInMutation = {
  __typename?: "Mutation";
  acceptTradeIn?: {
    __typename?: "TradeIn";
    _id?: string | null;
    id?: string | null;
  } | null;
};

export type Core_RejectTradeInMutationVariables = Exact<{
  input: RejectTradeInInput;
}>;

export type Core_RejectTradeInMutation = {
  __typename?: "Mutation";
  rejectTradeIn?: {
    __typename?: "TradeIn";
    _id?: string | null;
    id?: string | null;
  } | null;
};

export type Core_Dealer_Trade_InQueryVariables = Exact<{
  tradeInId?: InputMaybe<Scalars["String"]["input"]>;
}>;

export type Core_Dealer_Trade_InQuery = {
  __typename?: "Query";
  tradeIn?: {
    __typename?: "TradeIn";
    _id?: string | null;
    id?: string | null;
    createdAt?: any | null;
    haveAppraisalsChanged?: boolean | null;
    canonicalProduct?: {
      __typename?: "core_apimessages_CanonicalProduct";
      id?: string | null;
      name?: string | null;
      make?: string | null;
      primaryImage?: {
        __typename?: "core_apimessages_Image";
        _id?: string | null;
        source?: string | null;
      } | null;
    } | null;
    seller?: {
      __typename?: "User";
      _id?: string | null;
      name?: string | null;
    } | null;
    appraisals?: Array<{
      __typename?: "TradeInAppraisal";
      condition?: {
        __typename?: "core_apimessages_Condition";
        conditionUuid?: string | null;
        conditionSlug?: string | null;
        displayName?: string | null;
      } | null;
      price?: {
        __typename?: "core_apimessages_Money";
        display?: string | null;
      } | null;
    } | null> | null;
    originalAppraisals?: Array<{
      __typename?: "TradeInAppraisal";
      condition?: {
        __typename?: "core_apimessages_Condition";
        conditionUuid?: string | null;
        conditionSlug?: string | null;
      } | null;
      price?: {
        __typename?: "core_apimessages_Money";
        display?: string | null;
      } | null;
    } | null> | null;
  } | null;
};

export type Core_Dealer_Trade_Ins_IndexQueryVariables = Exact<{
  [key: string]: never;
}>;

export type Core_Dealer_Trade_Ins_IndexQuery = {
  __typename?: "Query";
  me?: {
    __typename?: "rql_Me";
    _id?: string | null;
    shop?: {
      __typename?: "MyShop";
      _id?: string | null;
      tradeIns?: Array<{
        __typename?: "TradeIn";
        _id?: string | null;
        id?: string | null;
        state?: TradeInStatus | null;
        createdAt?: any | null;
        canonicalProduct?: {
          __typename?: "core_apimessages_CanonicalProduct";
          id?: string | null;
          name?: string | null;
          make?: string | null;
          primaryImage?: {
            __typename?: "core_apimessages_Image";
            _id?: string | null;
            source?: string | null;
          } | null;
        } | null;
        seller?: {
          __typename?: "User";
          _id?: string | null;
          name?: string | null;
        } | null;
      } | null> | null;
    } | null;
  } | null;
};

export type SellerHub_BumpStatsQueryVariables = Exact<{
  timePeriod?: InputMaybe<Scalars["String"]["input"]>;
}>;

export type SellerHub_BumpStatsQuery = {
  __typename?: "Query";
  me?: {
    __typename?: "rql_Me";
    _id?: string | null;
    shop?: {
      __typename?: "MyShop";
      _id?: string | null;
      bumpStats?: {
        __typename?: "core_apimessages_MyShopBumpStatsResponse";
        brandNew?: {
          __typename?: "core_apimessages_MyShopBumpStats";
          percentageSpent?: number | null;
          views?: number | null;
          spent?: {
            __typename?: "core_apimessages_Money";
            display?: string | null;
          } | null;
          sales?: {
            __typename?: "core_apimessages_Money";
            display?: string | null;
          } | null;
        } | null;
        used?: {
          __typename?: "core_apimessages_MyShopBumpStats";
          percentageSpent?: number | null;
          views?: number | null;
          spent?: {
            __typename?: "core_apimessages_Money";
            display?: string | null;
          } | null;
          sales?: {
            __typename?: "core_apimessages_Money";
            display?: string | null;
          } | null;
        } | null;
      } | null;
    } | null;
  } | null;
};

export type SellerHub_EarningsQueryVariables = Exact<{
  timePeriod?: InputMaybe<Scalars["String"]["input"]>;
}>;

export type SellerHub_EarningsQuery = {
  __typename?: "Query";
  me?: {
    __typename?: "rql_Me";
    _id?: string | null;
    shop?: {
      __typename?: "MyShop";
      _id?: string | null;
      earnings?: {
        __typename?: "core_apimessages_MyShopEarningsResponse";
        ordersTotal?: {
          __typename?: "core_apimessages_Money";
          display?: string | null;
        } | null;
        accommodationsTotal?: {
          __typename?: "core_apimessages_Money";
          amountCents?: number | null;
          display?: string | null;
        } | null;
      } | null;
    } | null;
  } | null;
};

export type SellerHub_SellerFeedbackQueryVariables = Exact<{
  userUuid?: InputMaybe<Scalars["String"]["input"]>;
}>;

export type SellerHub_SellerFeedbackQuery = {
  __typename?: "Query";
  user?: {
    __typename?: "User";
    _id?: string | null;
    feedback?: {
      __typename?: "core_apimessages_FeedbacksResponse";
      sellerRating?: number | null;
      total?: number | null;
    } | null;
  } | null;
};

export type SellerHub_NewSellerPromptQueryVariables = Exact<{
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  createdAfterDate?: InputMaybe<Scalars["String"]["input"]>;
}>;

export type SellerHub_NewSellerPromptQuery = {
  __typename?: "Query";
  ordersSearch?: {
    __typename?: "reverb_search_SearchResponse";
    orders?: Array<{
      __typename?: "Order";
      _id?: string | null;
      id?: string | null;
    } | null> | null;
  } | null;
};

export type SellerHub_ProfileQueryVariables = Exact<{ [key: string]: never }>;

export type SellerHub_ProfileQuery = {
  __typename?: "Query";
  me?: {
    __typename?: "rql_Me";
    _id?: string | null;
    firstName?: string | null;
    avatar?: {
      __typename?: "core_apimessages_Image";
      _id?: string | null;
      source?: string | null;
    } | null;
    shop?: {
      __typename?: "MyShop";
      _id?: string | null;
      slug?: string | null;
    } | null;
  } | null;
};

export type SellerHub_RecentMessagesQueryVariables = Exact<{
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  unrepliedOnly?: InputMaybe<Scalars["Boolean"]["input"]>;
}>;

export type SellerHub_RecentMessagesQuery = {
  __typename?: "Query";
  me?: {
    __typename?: "rql_Me";
    _id?: string | null;
    myMessages?: Array<{
      __typename?: "core_apimessages_MyMessage";
      body?: string | null;
      createdAt?: any | null;
      read?: boolean | null;
      conversationId?: string | null;
    } | null> | null;
  } | null;
};

export type SellerHub_RecentOffersQueryVariables = Exact<{
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  state?: InputMaybe<rql_NegotiationQuery_State>;
}>;

export type SellerHub_RecentOffersQuery = {
  __typename?: "Query";
  me?: {
    __typename?: "rql_Me";
    _id?: string | null;
    myNegotiations?: {
      __typename?: "reverb_search_SearchResponse";
      negotiations?: Array<{
        __typename?: "Negotiation";
        _id?: string | null;
        id?: string | null;
        taxIncluded?: boolean | null;
        taxIncludedHint?: string | null;
        buyer?: {
          __typename?: "User";
          _id?: string | null;
          shortname?: string | null;
        } | null;
        expiresAt?: {
          __typename?: "google_protobuf_Timestamp";
          seconds?: number | null;
        } | null;
        shippingLocation?: {
          __typename?: "core_apimessages_Address";
          _id?: string | null;
          displayLocation?: string | null;
        } | null;
        offers?: Array<{
          __typename?: "core_apimessages_Offer";
          status?: core_apimessages_Offer_OfferStatus | null;
        } | null> | null;
        lastOffer?: {
          __typename?: "core_apimessages_Offer";
          initiatingParty?: core_apimessages_NegotiationParty | null;
          status?: core_apimessages_Offer_OfferStatus | null;
          createdAt?: {
            __typename?: "google_protobuf_Timestamp";
            seconds?: number | null;
          } | null;
          prices?: {
            __typename?: "core_apimessages_OfferPrices";
            totalPrice?: {
              __typename?: "core_apimessages_ConvertedMoney";
              display?: {
                __typename?: "core_apimessages_Money";
                display?: string | null;
              } | null;
            } | null;
          } | null;
          offerItems?: Array<{
            __typename?: "core_apimessages_OfferItem";
            listing?: {
              __typename?: "Listing";
              _id?: string | null;
              title?: string | null;
              images?: Array<{
                __typename?: "core_apimessages_Image";
                _id?: string | null;
                source?: string | null;
              } | null> | null;
            } | null;
          } | null> | null;
        } | null;
      } | null> | null;
    } | null;
  } | null;
};

export type SellerHub_RecentOrdersQueryVariables = Exact<{
  limit?: InputMaybe<Scalars["Int"]["input"]>;
}>;

export type SellerHub_RecentOrdersQuery = {
  __typename?: "Query";
  ordersSearch?: {
    __typename?: "reverb_search_SearchResponse";
    orders?: Array<{
      __typename?: "Order";
      _id?: string | null;
      legacyOrderId?: string | null;
      listing?: {
        __typename?: "Listing";
        _id?: string | null;
        title?: string | null;
        images?: Array<{
          __typename?: "core_apimessages_Image";
          _id?: string | null;
          source?: string | null;
        } | null> | null;
      } | null;
      sellerOrder?: {
        __typename?: "core_apimessages_SellerOrder";
        shippingLabel?: {
          __typename?: "core_apimessages_OrderShippingLabel";
          purchaseShippingLabelWebUrl?: string | null;
        } | null;
        orderViewStatus?: {
          __typename?: "core_apimessages_OrderViewStatus";
          status?: string | null;
          description?: string | null;
        } | null;
      } | null;
    } | null> | null;
  } | null;
};

export type SellerHub_RecommendedDirectOffersQueryVariables = Exact<{
  sort?: InputMaybe<reverb_search_ListingsSearchRequest_Sort>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  hasWatchers?: InputMaybe<Scalars["Boolean"]["input"]>;
  noAutoOffers?: InputMaybe<Scalars["Boolean"]["input"]>;
}>;

export type SellerHub_RecommendedDirectOffersQuery = {
  __typename?: "Query";
  me?: {
    __typename?: "rql_Me";
    _id?: string | null;
    shop?: {
      __typename?: "MyShop";
      _id?: string | null;
      listings?: {
        __typename?: "reverb_search_SearchResponse";
        listings?: Array<{
          __typename?: "Listing";
          _id?: string | null;
          id?: string | null;
        } | null> | null;
      } | null;
    } | null;
  } | null;
};

export type SellerHub_RecommendedDirectOfferQueryVariables = Exact<{
  id?: InputMaybe<Scalars["String"]["input"]>;
}>;

export type SellerHub_RecommendedDirectOfferQuery = {
  __typename?: "Query";
  listing?: {
    __typename?: "Listing";
    _id?: string | null;
    id?: string | null;
    title?: string | null;
    counts?: {
      __typename?: "core_apimessages_ListingCounts";
      watchers?: number | null;
    } | null;
    autoOffers?: {
      __typename?: "core_apimessages_AutoOffer";
      id?: string | null;
    } | null;
    images?: Array<{
      __typename?: "core_apimessages_Image";
      _id?: string | null;
      source?: string | null;
    } | null> | null;
  } | null;
};

export type SellerHub_RecommendedPriceDropsQueryVariables = Exact<{
  sort?: InputMaybe<reverb_search_ListingsSearchRequest_Sort>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  notGreatValueForUsedListings?: InputMaybe<Scalars["Boolean"]["input"]>;
}>;

export type SellerHub_RecommendedPriceDropsQuery = {
  __typename?: "Query";
  me?: {
    __typename?: "rql_Me";
    _id?: string | null;
    shop?: {
      __typename?: "MyShop";
      _id?: string | null;
      listings?: {
        __typename?: "reverb_search_SearchResponse";
        listings?: Array<{
          __typename?: "Listing";
          _id?: string | null;
          id?: string | null;
        } | null> | null;
      } | null;
    } | null;
  } | null;
};

export type SellerHub_RecommendedPriceDropQueryVariables = Exact<{
  id?: InputMaybe<Scalars["String"]["input"]>;
  shopCurrency?: InputMaybe<Scalars["String"]["input"]>;
  shopCountryCode?: InputMaybe<Scalars["String"]["input"]>;
}>;

export type SellerHub_RecommendedPriceDropQuery = {
  __typename?: "Query";
  listing?: {
    __typename?: "Listing";
    _id?: string | null;
    id?: string | null;
    title?: string | null;
    sellerUuid?: string | null;
    vatIncluded?: boolean | null;
    vatIncludedHint?: string | null;
    sellerPrice?: {
      __typename?: "core_apimessages_Money";
      display?: string | null;
      amountCents?: number | null;
    } | null;
    priceRecommendation?: {
      __typename?: "reverb_pricing_PriceRecommendation";
      priceMiddle?: {
        __typename?: "reverb_pricing_Money";
        display?: string | null;
        amountCents?: number | null;
      } | null;
    } | null;
    images?: Array<{
      __typename?: "core_apimessages_Image";
      _id?: string | null;
      source?: string | null;
    } | null> | null;
  } | null;
};

export type SellerHub_ShopBadgesQueryVariables = Exact<{
  [key: string]: never;
}>;

export type SellerHub_ShopBadgesQuery = {
  __typename?: "Query";
  me?: {
    __typename?: "rql_Me";
    _id?: string | null;
    shop?: {
      __typename?: "MyShop";
      _id?: string | null;
      badges?: Array<{
        __typename?: "core_apimessages_ShopBadge";
        badgeType?: string | null;
        completedPercentage?: number | null;
        requirements?: Array<{
          __typename?: "core_apimessages_ShopBadgeRequirement";
          requirement?: string | null;
          completed?: boolean | null;
        } | null> | null;
      } | null> | null;
    } | null;
  } | null;
};

export type SellerHub_ShopStatsQueryVariables = Exact<{
  timePeriod?: InputMaybe<Scalars["String"]["input"]>;
}>;

export type SellerHub_ShopStatsQuery = {
  __typename?: "Query";
  me?: {
    __typename?: "rql_Me";
    _id?: string | null;
    uuid?: string | null;
    shop?: {
      __typename?: "MyShop";
      _id?: string | null;
      shopStats?: {
        __typename?: "core_apimessages_MyShopStatsResponse";
        salesChartData?: {
          __typename?: "core_apimessages_MyShopSalesChartData";
          currencySymbol?: string | null;
          salesInMoreThanOneCurrency?: boolean | null;
          labels?: Array<string | null> | null;
          series?: Array<{
            __typename?: "core_apimessages_MyShopSalesChartDataSet";
            dataSet?: Array<number | null> | null;
          } | null> | null;
        } | null;
        comparisonStats?: {
          __typename?: "core_apimessages_MyShopComparisonStats";
          currentStats?: {
            __typename?: "core_apimessages_MyShopStats";
            messages?: number | null;
            offers?: number | null;
            orders?: number | null;
            watchers?: number | null;
          } | null;
          changeStats?: {
            __typename?: "core_apimessages_MyShopStats";
            messages?: number | null;
            offers?: number | null;
            orders?: number | null;
            watchers?: number | null;
          } | null;
        } | null;
      } | null;
    } | null;
  } | null;
};

export type Search_MyListings_RecommendationsQueryVariables = Exact<{
  [key: string]: never;
}>;

export type Search_MyListings_RecommendationsQuery = {
  __typename?: "Query";
  me?: {
    __typename?: "rql_Me";
    _id?: string | null;
    uuid?: string | null;
    shop?: {
      __typename?: "MyShop";
      _id?: string | null;
      listings?: {
        __typename?: "reverb_search_SearchResponse";
        listingRecommendationStats?: {
          __typename?: "reverb_search_ListingRecommendationStats";
          totalGreaterThanCompetitivePrice?: number | null;
          totalWithWatchersAndWithoutAutoOffers?: number | null;
        } | null;
      } | null;
    } | null;
  } | null;
};

export type DigitalDetailsFieldsFragment = {
  __typename?: "Listing";
  _id?: string | null;
  id?: string | null;
  listingType?: core_apimessages_Listing_ListingType | null;
  digitalDetails?: {
    __typename?: "core_apimessages_ListingDigitalDetails";
    variants?: Array<{
      __typename?: "core_apimessages_ListingDigitalVariant";
      id?: string | null;
      downloadText?: string | null;
      fileUrl?: string | null;
      fileSize?: string | null;
      operatingSystems?: Array<string | null> | null;
      fileTypes?: Array<string | null> | null;
    } | null> | null;
    supportedFormats?: Array<{
      __typename?: "core_apimessages_ListingSupportedFormat";
      operatingSystem?: string | null;
      fileTypes?: Array<string | null> | null;
    } | null> | null;
  } | null;
};

export type Core_Listing_DigitalDetailsQueryVariables = Exact<{
  id?: InputMaybe<Scalars["String"]["input"]>;
}>;

export type Core_Listing_DigitalDetailsQuery = {
  __typename?: "Query";
  listing?: {
    __typename?: "Listing";
    _id?: string | null;
    id?: string | null;
    listingType?: core_apimessages_Listing_ListingType | null;
    digitalDetails?: {
      __typename?: "core_apimessages_ListingDigitalDetails";
      variants?: Array<{
        __typename?: "core_apimessages_ListingDigitalVariant";
        id?: string | null;
        downloadText?: string | null;
        fileUrl?: string | null;
        fileSize?: string | null;
        operatingSystems?: Array<string | null> | null;
        fileTypes?: Array<string | null> | null;
      } | null> | null;
      supportedFormats?: Array<{
        __typename?: "core_apimessages_ListingSupportedFormat";
        operatingSystem?: string | null;
        fileTypes?: Array<string | null> | null;
      } | null> | null;
    } | null;
  } | null;
};

export type DiscoverQueryVariables = Exact<{
  offset: Scalars["Int"]["input"];
  typesToInclude?: InputMaybe<
    Array<InputMaybe<rql_DiscoverInclude>> | InputMaybe<rql_DiscoverInclude>
  >;
  limit: Scalars["Int"]["input"];
}>;

export type DiscoverQuery = {
  __typename?: "Query";
  me?: {
    __typename?: "rql_Me";
    _id?: string | null;
    id?: string | null;
    discover?: {
      __typename?: "rql_DiscoverResponse";
      entries?: Array<{
        __typename: "rql_DiscoverEntry";
        id?: string | null;
        type?: rql_DiscoverEntryType | null;
        description?: string | null;
        listing?: {
          __typename?: "Listing";
          _id?: string | null;
          id?: string | null;
          usOutlet?: boolean | null;
          bumped?: boolean | null;
          categoryUuids?: Array<string | null> | null;
          slug?: string | null;
          title?: string | null;
          description?: string | null;
          listingType?: core_apimessages_Listing_ListingType | null;
          watching?: boolean | null;
          state?: string | null;
          stateDescription?: string | null;
          sellerId?: string | null;
          currency?: string | null;
          offersEnabled?: boolean | null;
          isBuyerOfferEligible?: boolean | null;
          brandSlug?: string | null;
          categoryRootUuid?: string | null;
          make?: string | null;
          bumpEligible?: boolean | null;
          shopId?: string | null;
          inventory?: number | null;
          soldAsIs?: boolean | null;
          acceptedPaymentMethods?: Array<string | null> | null;
          condition?: {
            __typename?: "core_apimessages_Condition";
            displayName?: string | null;
            conditionSlug?: string | null;
            conditionUuid?: string | null;
          } | null;
          price?: {
            __typename?: "core_apimessages_Money";
            amountCents?: number | null;
            display?: string | null;
          } | null;
          pricing?: {
            __typename?: "core_apimessages_ListingPricing";
            buyerPrice?: {
              __typename?: "core_apimessages_Money";
              display?: string | null;
              currency?: string | null;
              amountCents?: number | null;
              amount?: string | null;
            } | null;
            originalPrice?: {
              __typename?: "core_apimessages_Money";
              display?: string | null;
            } | null;
            ribbon?: {
              __typename?: "core_apimessages_Ribbon";
              display?: string | null;
              reason?: core_apimessages_Ribbon_Reason | null;
            } | null;
          } | null;
          shipping?: {
            __typename?: "core_apimessages_ShippingPrices";
            freeExpeditedShipping?: boolean | null;
            localPickupOnly?: boolean | null;
            localPickup?: boolean | null;
            shippingPrices?: Array<{
              __typename?: "core_apimessages_ShippingPrice";
              _id?: string | null;
              shippingMethod?: core_apimessages_ShippingMethod | null;
              carrierCalculated?: boolean | null;
              destinationPostalCodeNeeded?: boolean | null;
              rate?: {
                __typename?: "core_apimessages_Money";
                amount?: string | null;
                amountCents?: number | null;
                currency?: string | null;
                display?: string | null;
              } | null;
            } | null> | null;
          } | null;
          shop?: {
            __typename?: "Shop";
            _id?: string | null;
            address?: {
              __typename?: "core_apimessages_Address";
              _id?: string | null;
              countryCode?: string | null;
              country?: {
                __typename?: "core_apimessages_Country";
                _id?: string | null;
                countryCode?: string | null;
                name?: string | null;
              } | null;
            } | null;
            returnPolicy?: {
              __typename?: "core_apimessages_ReturnPolicy";
              _id?: string | null;
              newReturnWindowDays?: number | null;
              usedReturnWindowDays?: number | null;
            } | null;
          } | null;
          preorderInfo?: {
            __typename?: "core_apimessages_PreorderInfo";
            onPreorder?: boolean | null;
            estimatedShipDate?: {
              __typename?: "google_protobuf_Timestamp";
              seconds?: number | null;
            } | null;
          } | null;
          watchThumbnails?: Array<{
            __typename?: "core_apimessages_Image";
            _id?: string | null;
            source?: string | null;
          } | null> | null;
          priceRecommendation?: {
            __typename?: "reverb_pricing_PriceRecommendation";
            priceMiddle?: {
              __typename?: "reverb_pricing_Money";
              amountCents?: number | null;
              currency?: string | null;
            } | null;
          } | null;
          images?: Array<{
            __typename?: "core_apimessages_Image";
            _id?: string | null;
            source?: string | null;
          } | null> | null;
          largeSquareImages?: Array<{
            __typename?: "core_apimessages_Image";
            _id?: string | null;
            source?: string | null;
          } | null> | null;
          certifiedPreOwned?: {
            __typename?: "core_apimessages_ListingCertifiedPreOwned";
            title?: string | null;
            badgeIconUrl?: string | null;
          } | null;
          categories?: Array<{
            __typename?: "Category";
            _id?: string | null;
            slug?: string | null;
            rootSlug?: string | null;
          } | null> | null;
          csp?: {
            __typename?: "CSP";
            _id?: string | null;
            id?: string | null;
            slug?: string | null;
            brand?: {
              __typename?: "Brand";
              _id?: string | null;
              slug?: string | null;
            } | null;
          } | null;
          publishedAt?: {
            __typename?: "google_protobuf_Timestamp";
            seconds?: number | null;
          } | null;
          sale?: {
            __typename?: "core_apimessages_Sale";
            _id?: string | null;
            id?: string | null;
            code?: string | null;
            buyerIneligibilityReason?: string | null;
          } | null;
        } | null;
        ad?: {
          __typename?: "core_apimessages_Ad";
          uuid?: string | null;
          format?: core_apimessages_AdFormat | null;
          adGroupUuid?: string | null;
          adGroupName?: string | null;
          adCampaignUuid?: string | null;
          advertiserUuid?: string | null;
          imageAdCreative?: {
            __typename?: "core_apimessages_ImageAdCreative";
            title?: string | null;
            titleColor?: string | null;
            subtitle?: string | null;
            subtitleColor?: string | null;
            ctaText?: string | null;
            ctaHref?: string | null;
            ctaTextColor?: string | null;
            ctaButtonColor?: string | null;
            backgroundImage?: string | null;
            mobileBackgroundImage?: string | null;
            backgroundColor?: string | null;
            backgroundColors?: Array<string | null> | null;
            overlayImage?: string | null;
            description?: string | null;
            partnershipText?: string | null;
            hideAdCallout?: boolean | null;
          } | null;
        } | null;
        update?: {
          __typename?: "rql_Update";
          entity?: {
            __typename?: "rql_UpdateEntity";
            _id?: string | null;
            id?: string | null;
            type?: reverb_updates_Entity_EntityType | null;
            listings?: Array<{
              __typename?: "Listing";
              _id?: string | null;
              id?: string | null;
              usOutlet?: boolean | null;
              bumped?: boolean | null;
              categoryUuids?: Array<string | null> | null;
              slug?: string | null;
              title?: string | null;
              description?: string | null;
              listingType?: core_apimessages_Listing_ListingType | null;
              watching?: boolean | null;
              state?: string | null;
              stateDescription?: string | null;
              sellerId?: string | null;
              currency?: string | null;
              offersEnabled?: boolean | null;
              isBuyerOfferEligible?: boolean | null;
              brandSlug?: string | null;
              categoryRootUuid?: string | null;
              make?: string | null;
              bumpEligible?: boolean | null;
              shopId?: string | null;
              inventory?: number | null;
              soldAsIs?: boolean | null;
              acceptedPaymentMethods?: Array<string | null> | null;
              condition?: {
                __typename?: "core_apimessages_Condition";
                displayName?: string | null;
                conditionSlug?: string | null;
                conditionUuid?: string | null;
              } | null;
              price?: {
                __typename?: "core_apimessages_Money";
                amountCents?: number | null;
                display?: string | null;
              } | null;
              pricing?: {
                __typename?: "core_apimessages_ListingPricing";
                buyerPrice?: {
                  __typename?: "core_apimessages_Money";
                  display?: string | null;
                  currency?: string | null;
                  amountCents?: number | null;
                  amount?: string | null;
                } | null;
                originalPrice?: {
                  __typename?: "core_apimessages_Money";
                  display?: string | null;
                } | null;
                ribbon?: {
                  __typename?: "core_apimessages_Ribbon";
                  display?: string | null;
                  reason?: core_apimessages_Ribbon_Reason | null;
                } | null;
              } | null;
              shipping?: {
                __typename?: "core_apimessages_ShippingPrices";
                freeExpeditedShipping?: boolean | null;
                localPickupOnly?: boolean | null;
                localPickup?: boolean | null;
                shippingPrices?: Array<{
                  __typename?: "core_apimessages_ShippingPrice";
                  _id?: string | null;
                  shippingMethod?: core_apimessages_ShippingMethod | null;
                  carrierCalculated?: boolean | null;
                  destinationPostalCodeNeeded?: boolean | null;
                  rate?: {
                    __typename?: "core_apimessages_Money";
                    amount?: string | null;
                    amountCents?: number | null;
                    currency?: string | null;
                    display?: string | null;
                  } | null;
                } | null> | null;
              } | null;
              shop?: {
                __typename?: "Shop";
                _id?: string | null;
                address?: {
                  __typename?: "core_apimessages_Address";
                  _id?: string | null;
                  countryCode?: string | null;
                  country?: {
                    __typename?: "core_apimessages_Country";
                    _id?: string | null;
                    countryCode?: string | null;
                    name?: string | null;
                  } | null;
                } | null;
                returnPolicy?: {
                  __typename?: "core_apimessages_ReturnPolicy";
                  _id?: string | null;
                  newReturnWindowDays?: number | null;
                  usedReturnWindowDays?: number | null;
                } | null;
              } | null;
              preorderInfo?: {
                __typename?: "core_apimessages_PreorderInfo";
                onPreorder?: boolean | null;
                estimatedShipDate?: {
                  __typename?: "google_protobuf_Timestamp";
                  seconds?: number | null;
                } | null;
              } | null;
              watchThumbnails?: Array<{
                __typename?: "core_apimessages_Image";
                _id?: string | null;
                source?: string | null;
              } | null> | null;
              priceRecommendation?: {
                __typename?: "reverb_pricing_PriceRecommendation";
                priceMiddle?: {
                  __typename?: "reverb_pricing_Money";
                  amountCents?: number | null;
                  currency?: string | null;
                } | null;
              } | null;
              images?: Array<{
                __typename?: "core_apimessages_Image";
                _id?: string | null;
                source?: string | null;
              } | null> | null;
              largeSquareImages?: Array<{
                __typename?: "core_apimessages_Image";
                _id?: string | null;
                source?: string | null;
              } | null> | null;
              certifiedPreOwned?: {
                __typename?: "core_apimessages_ListingCertifiedPreOwned";
                title?: string | null;
                badgeIconUrl?: string | null;
              } | null;
              categories?: Array<{
                __typename?: "Category";
                _id?: string | null;
                slug?: string | null;
                rootSlug?: string | null;
              } | null> | null;
              csp?: {
                __typename?: "CSP";
                _id?: string | null;
                id?: string | null;
                slug?: string | null;
                brand?: {
                  __typename?: "Brand";
                  _id?: string | null;
                  slug?: string | null;
                } | null;
              } | null;
              publishedAt?: {
                __typename?: "google_protobuf_Timestamp";
                seconds?: number | null;
              } | null;
              sale?: {
                __typename?: "core_apimessages_Sale";
                _id?: string | null;
                id?: string | null;
                code?: string | null;
                buyerIneligibilityReason?: string | null;
              } | null;
            } | null> | null;
            listing?: {
              __typename?: "Listing";
              _id?: string | null;
              id?: string | null;
              usOutlet?: boolean | null;
              bumped?: boolean | null;
              categoryUuids?: Array<string | null> | null;
              slug?: string | null;
              title?: string | null;
              description?: string | null;
              listingType?: core_apimessages_Listing_ListingType | null;
              watching?: boolean | null;
              state?: string | null;
              stateDescription?: string | null;
              sellerId?: string | null;
              currency?: string | null;
              offersEnabled?: boolean | null;
              isBuyerOfferEligible?: boolean | null;
              brandSlug?: string | null;
              categoryRootUuid?: string | null;
              make?: string | null;
              bumpEligible?: boolean | null;
              shopId?: string | null;
              inventory?: number | null;
              soldAsIs?: boolean | null;
              acceptedPaymentMethods?: Array<string | null> | null;
              condition?: {
                __typename?: "core_apimessages_Condition";
                displayName?: string | null;
                conditionSlug?: string | null;
                conditionUuid?: string | null;
              } | null;
              price?: {
                __typename?: "core_apimessages_Money";
                amountCents?: number | null;
                display?: string | null;
              } | null;
              pricing?: {
                __typename?: "core_apimessages_ListingPricing";
                buyerPrice?: {
                  __typename?: "core_apimessages_Money";
                  display?: string | null;
                  currency?: string | null;
                  amountCents?: number | null;
                  amount?: string | null;
                } | null;
                originalPrice?: {
                  __typename?: "core_apimessages_Money";
                  display?: string | null;
                } | null;
                ribbon?: {
                  __typename?: "core_apimessages_Ribbon";
                  display?: string | null;
                  reason?: core_apimessages_Ribbon_Reason | null;
                } | null;
              } | null;
              shipping?: {
                __typename?: "core_apimessages_ShippingPrices";
                freeExpeditedShipping?: boolean | null;
                localPickupOnly?: boolean | null;
                localPickup?: boolean | null;
                shippingPrices?: Array<{
                  __typename?: "core_apimessages_ShippingPrice";
                  _id?: string | null;
                  shippingMethod?: core_apimessages_ShippingMethod | null;
                  carrierCalculated?: boolean | null;
                  destinationPostalCodeNeeded?: boolean | null;
                  rate?: {
                    __typename?: "core_apimessages_Money";
                    amount?: string | null;
                    amountCents?: number | null;
                    currency?: string | null;
                    display?: string | null;
                  } | null;
                } | null> | null;
              } | null;
              shop?: {
                __typename?: "Shop";
                _id?: string | null;
                address?: {
                  __typename?: "core_apimessages_Address";
                  _id?: string | null;
                  countryCode?: string | null;
                  country?: {
                    __typename?: "core_apimessages_Country";
                    _id?: string | null;
                    countryCode?: string | null;
                    name?: string | null;
                  } | null;
                } | null;
                returnPolicy?: {
                  __typename?: "core_apimessages_ReturnPolicy";
                  _id?: string | null;
                  newReturnWindowDays?: number | null;
                  usedReturnWindowDays?: number | null;
                } | null;
              } | null;
              preorderInfo?: {
                __typename?: "core_apimessages_PreorderInfo";
                onPreorder?: boolean | null;
                estimatedShipDate?: {
                  __typename?: "google_protobuf_Timestamp";
                  seconds?: number | null;
                } | null;
              } | null;
              watchThumbnails?: Array<{
                __typename?: "core_apimessages_Image";
                _id?: string | null;
                source?: string | null;
              } | null> | null;
              priceRecommendation?: {
                __typename?: "reverb_pricing_PriceRecommendation";
                priceMiddle?: {
                  __typename?: "reverb_pricing_Money";
                  amountCents?: number | null;
                  currency?: string | null;
                } | null;
              } | null;
              images?: Array<{
                __typename?: "core_apimessages_Image";
                _id?: string | null;
                source?: string | null;
              } | null> | null;
              largeSquareImages?: Array<{
                __typename?: "core_apimessages_Image";
                _id?: string | null;
                source?: string | null;
              } | null> | null;
              certifiedPreOwned?: {
                __typename?: "core_apimessages_ListingCertifiedPreOwned";
                title?: string | null;
                badgeIconUrl?: string | null;
              } | null;
              categories?: Array<{
                __typename?: "Category";
                _id?: string | null;
                slug?: string | null;
                rootSlug?: string | null;
              } | null> | null;
              csp?: {
                __typename?: "CSP";
                _id?: string | null;
                id?: string | null;
                slug?: string | null;
                brand?: {
                  __typename?: "Brand";
                  _id?: string | null;
                  slug?: string | null;
                } | null;
              } | null;
              publishedAt?: {
                __typename?: "google_protobuf_Timestamp";
                seconds?: number | null;
              } | null;
              sale?: {
                __typename?: "core_apimessages_Sale";
                _id?: string | null;
                id?: string | null;
                code?: string | null;
                buyerIneligibilityReason?: string | null;
              } | null;
            } | null;
            priceDrop?: {
              __typename?: "PriceChange";
              _id?: string | null;
              listing?: {
                __typename?: "Listing";
                _id?: string | null;
                id?: string | null;
                usOutlet?: boolean | null;
                bumped?: boolean | null;
                categoryUuids?: Array<string | null> | null;
                slug?: string | null;
                title?: string | null;
                description?: string | null;
                listingType?: core_apimessages_Listing_ListingType | null;
                watching?: boolean | null;
                state?: string | null;
                stateDescription?: string | null;
                sellerId?: string | null;
                currency?: string | null;
                offersEnabled?: boolean | null;
                isBuyerOfferEligible?: boolean | null;
                brandSlug?: string | null;
                categoryRootUuid?: string | null;
                make?: string | null;
                bumpEligible?: boolean | null;
                shopId?: string | null;
                inventory?: number | null;
                soldAsIs?: boolean | null;
                acceptedPaymentMethods?: Array<string | null> | null;
                condition?: {
                  __typename?: "core_apimessages_Condition";
                  displayName?: string | null;
                  conditionSlug?: string | null;
                  conditionUuid?: string | null;
                } | null;
                price?: {
                  __typename?: "core_apimessages_Money";
                  amountCents?: number | null;
                  display?: string | null;
                } | null;
                pricing?: {
                  __typename?: "core_apimessages_ListingPricing";
                  buyerPrice?: {
                    __typename?: "core_apimessages_Money";
                    display?: string | null;
                    currency?: string | null;
                    amountCents?: number | null;
                    amount?: string | null;
                  } | null;
                  originalPrice?: {
                    __typename?: "core_apimessages_Money";
                    display?: string | null;
                  } | null;
                  ribbon?: {
                    __typename?: "core_apimessages_Ribbon";
                    display?: string | null;
                    reason?: core_apimessages_Ribbon_Reason | null;
                  } | null;
                } | null;
                shipping?: {
                  __typename?: "core_apimessages_ShippingPrices";
                  freeExpeditedShipping?: boolean | null;
                  localPickupOnly?: boolean | null;
                  localPickup?: boolean | null;
                  shippingPrices?: Array<{
                    __typename?: "core_apimessages_ShippingPrice";
                    _id?: string | null;
                    shippingMethod?: core_apimessages_ShippingMethod | null;
                    carrierCalculated?: boolean | null;
                    destinationPostalCodeNeeded?: boolean | null;
                    rate?: {
                      __typename?: "core_apimessages_Money";
                      amount?: string | null;
                      amountCents?: number | null;
                      currency?: string | null;
                      display?: string | null;
                    } | null;
                  } | null> | null;
                } | null;
                shop?: {
                  __typename?: "Shop";
                  _id?: string | null;
                  address?: {
                    __typename?: "core_apimessages_Address";
                    _id?: string | null;
                    countryCode?: string | null;
                    country?: {
                      __typename?: "core_apimessages_Country";
                      _id?: string | null;
                      countryCode?: string | null;
                      name?: string | null;
                    } | null;
                  } | null;
                  returnPolicy?: {
                    __typename?: "core_apimessages_ReturnPolicy";
                    _id?: string | null;
                    newReturnWindowDays?: number | null;
                    usedReturnWindowDays?: number | null;
                  } | null;
                } | null;
                preorderInfo?: {
                  __typename?: "core_apimessages_PreorderInfo";
                  onPreorder?: boolean | null;
                  estimatedShipDate?: {
                    __typename?: "google_protobuf_Timestamp";
                    seconds?: number | null;
                  } | null;
                } | null;
                watchThumbnails?: Array<{
                  __typename?: "core_apimessages_Image";
                  _id?: string | null;
                  source?: string | null;
                } | null> | null;
                priceRecommendation?: {
                  __typename?: "reverb_pricing_PriceRecommendation";
                  priceMiddle?: {
                    __typename?: "reverb_pricing_Money";
                    amountCents?: number | null;
                    currency?: string | null;
                  } | null;
                } | null;
                images?: Array<{
                  __typename?: "core_apimessages_Image";
                  _id?: string | null;
                  source?: string | null;
                } | null> | null;
                largeSquareImages?: Array<{
                  __typename?: "core_apimessages_Image";
                  _id?: string | null;
                  source?: string | null;
                } | null> | null;
                certifiedPreOwned?: {
                  __typename?: "core_apimessages_ListingCertifiedPreOwned";
                  title?: string | null;
                  badgeIconUrl?: string | null;
                } | null;
                categories?: Array<{
                  __typename?: "Category";
                  _id?: string | null;
                  slug?: string | null;
                  rootSlug?: string | null;
                } | null> | null;
                csp?: {
                  __typename?: "CSP";
                  _id?: string | null;
                  id?: string | null;
                  slug?: string | null;
                  brand?: {
                    __typename?: "Brand";
                    _id?: string | null;
                    slug?: string | null;
                  } | null;
                } | null;
                publishedAt?: {
                  __typename?: "google_protobuf_Timestamp";
                  seconds?: number | null;
                } | null;
                sale?: {
                  __typename?: "core_apimessages_Sale";
                  _id?: string | null;
                  id?: string | null;
                  code?: string | null;
                  buyerIneligibilityReason?: string | null;
                } | null;
              } | null;
            } | null;
            promotion?: {
              __typename?: "MyUpdatesPromotion";
              _id?: string | null;
              title?: string | null;
            } | null;
            shop?: {
              __typename?: "Shop";
              _id?: string | null;
              name?: string | null;
              images?: Array<{
                __typename?: "core_apimessages_Image";
                _id?: string | null;
                source?: string | null;
              } | null> | null;
            } | null;
          } | null;
        } | null;
        article?: {
          __typename?: "Article";
          _id?: string | null;
          id?: string | null;
          title?: string | null;
          articleCategories?: Array<{
            __typename?: "core_apimessages_ArticleCategory";
            name?: string | null;
          } | null> | null;
          webLink?: {
            __typename?: "core_apimessages_Link";
            href?: string | null;
          } | null;
          images?: Array<{
            __typename?: "core_apimessages_Image";
            _id?: string | null;
            source?: string | null;
          } | null> | null;
        } | null;
        video?: {
          __typename?: "youtube_Video";
          id?: string | null;
          description?: string | null;
          title?: string | null;
        } | null;
      } | null> | null;
    } | null;
  } | null;
};

export type Core_Feed_SearchQueryVariables = Exact<{
  query?: InputMaybe<Scalars["String"]["input"]>;
  currency?: InputMaybe<Scalars["String"]["input"]>;
  priceMax?: InputMaybe<Scalars["String"]["input"]>;
  priceMin?: InputMaybe<Scalars["String"]["input"]>;
  yearMax?: InputMaybe<Scalars["String"]["input"]>;
  yearMin?: InputMaybe<Scalars["String"]["input"]>;
  sortSlug?: InputMaybe<Scalars["String"]["input"]>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  categorySlugs?: InputMaybe<
    | Array<InputMaybe<Scalars["String"]["input"]>>
    | InputMaybe<Scalars["String"]["input"]>
  >;
  brandSlugs?: InputMaybe<
    | Array<InputMaybe<Scalars["String"]["input"]>>
    | InputMaybe<Scalars["String"]["input"]>
  >;
  aggs?: InputMaybe<
    | Array<InputMaybe<reverb_search_ListingsSearchRequest_Aggregation>>
    | InputMaybe<reverb_search_ListingsSearchRequest_Aggregation>
  >;
  conditionSlugs?: InputMaybe<
    | Array<InputMaybe<Scalars["String"]["input"]>>
    | InputMaybe<Scalars["String"]["input"]>
  >;
  onSale?: InputMaybe<Scalars["Boolean"]["input"]>;
  handmade?: InputMaybe<Scalars["Boolean"]["input"]>;
  freeShipping?: InputMaybe<Scalars["Boolean"]["input"]>;
  freeExpeditedShipping?: InputMaybe<Scalars["Boolean"]["input"]>;
  acceptsOffers?: InputMaybe<Scalars["Boolean"]["input"]>;
  acceptsGiftCards?: InputMaybe<Scalars["Boolean"]["input"]>;
  preferredSeller?: InputMaybe<Scalars["Boolean"]["input"]>;
  acceptsPaymentPlans?: InputMaybe<Scalars["Boolean"]["input"]>;
  itemRegion?: InputMaybe<Scalars["String"]["input"]>;
  shippingRegionCode?: InputMaybe<
    | Array<InputMaybe<Scalars["String"]["input"]>>
    | InputMaybe<Scalars["String"]["input"]>
  >;
  decades?: InputMaybe<
    | Array<InputMaybe<Scalars["String"]["input"]>>
    | InputMaybe<Scalars["String"]["input"]>
  >;
  showListingLocation: Scalars["Boolean"]["input"];
  dealsAndSteals?: InputMaybe<Scalars["Boolean"]["input"]>;
}>;

export type Core_Feed_SearchQuery = {
  __typename?: "Query";
  me?: {
    __typename?: "rql_Me";
    _id?: string | null;
    uuid?: string | null;
    follows?: {
      __typename?: "reverb_feed_GetFollowsResponse";
      total?: number | null;
    } | null;
    feed?: {
      __typename?: "reverb_feed_FeedResponse";
      total?: number | null;
      offset?: number | null;
      limit?: number | null;
      filters?: Array<{
        __typename?: "reverb_search_Filter";
        name?: string | null;
        key?: string | null;
        aggregationName?: string | null;
        widgetType?: reverb_search_Filter_WidgetType | null;
        options?: Array<{
          __typename?: "reverb_search_FilterOption";
          name?: string | null;
          selected?: boolean | null;
          autoselected?: boolean | null;
          paramName?: string | null;
          setValues?: Array<string | null> | null;
          unsetValues?: Array<string | null> | null;
          all?: boolean | null;
          optionValue?: string | null;
          trackingValue?: string | null;
          count?: {
            __typename?: "google_protobuf_Int32Value";
            value?: number | null;
          } | null;
          subFilter?: {
            __typename?: "reverb_search_Filter";
            widgetType?: reverb_search_Filter_WidgetType | null;
            options?: Array<{
              __typename?: "reverb_search_FilterOption";
              name?: string | null;
              selected?: boolean | null;
              autoselected?: boolean | null;
              paramName?: string | null;
              setValues?: Array<string | null> | null;
              unsetValues?: Array<string | null> | null;
              all?: boolean | null;
              optionValue?: string | null;
              trackingValue?: string | null;
              count?: {
                __typename?: "google_protobuf_Int32Value";
                value?: number | null;
              } | null;
              subFilter?: {
                __typename?: "reverb_search_Filter";
                widgetType?: reverb_search_Filter_WidgetType | null;
                options?: Array<{
                  __typename?: "reverb_search_FilterOption";
                  name?: string | null;
                  selected?: boolean | null;
                  autoselected?: boolean | null;
                  paramName?: string | null;
                  setValues?: Array<string | null> | null;
                  unsetValues?: Array<string | null> | null;
                  all?: boolean | null;
                  optionValue?: string | null;
                  trackingValue?: string | null;
                  count?: {
                    __typename?: "google_protobuf_Int32Value";
                    value?: number | null;
                  } | null;
                  subFilter?: {
                    __typename?: "reverb_search_Filter";
                    widgetType?: reverb_search_Filter_WidgetType | null;
                    options?: Array<{
                      __typename?: "reverb_search_FilterOption";
                      name?: string | null;
                      selected?: boolean | null;
                      autoselected?: boolean | null;
                      paramName?: string | null;
                      setValues?: Array<string | null> | null;
                      unsetValues?: Array<string | null> | null;
                      all?: boolean | null;
                      optionValue?: string | null;
                      trackingValue?: string | null;
                      count?: {
                        __typename?: "google_protobuf_Int32Value";
                        value?: number | null;
                      } | null;
                    } | null> | null;
                  } | null;
                } | null> | null;
              } | null;
            } | null> | null;
          } | null;
        } | null> | null;
      } | null> | null;
      entries?: Array<{
        __typename?: "reverb_feed_FeedEntry";
        id?: number | null;
        type?: reverb_feed_FeedEntry_EntryType | null;
        deleted?: boolean | null;
        entry?:
          | {
              __typename?: "Article";
              _id?: string | null;
              id?: string | null;
              title?: string | null;
              images?: Array<{
                __typename?: "core_apimessages_Image";
                source?: string | null;
              } | null> | null;
              webLink?: {
                __typename?: "core_apimessages_Link";
                href?: string | null;
              } | null;
              articleCategories?: Array<{
                __typename?: "core_apimessages_ArticleCategory";
                name?: string | null;
                slug?: string | null;
              } | null> | null;
            }
          | {
              __typename?: "Listing";
              _id?: string | null;
              id?: string | null;
              usOutlet?: boolean | null;
              bumped?: boolean | null;
              categoryUuids?: Array<string | null> | null;
              slug?: string | null;
              title?: string | null;
              description?: string | null;
              listingType?: core_apimessages_Listing_ListingType | null;
              watching?: boolean | null;
              state?: string | null;
              stateDescription?: string | null;
              sellerId?: string | null;
              currency?: string | null;
              offersEnabled?: boolean | null;
              isBuyerOfferEligible?: boolean | null;
              brandSlug?: string | null;
              categoryRootUuid?: string | null;
              make?: string | null;
              bumpEligible?: boolean | null;
              shopId?: string | null;
              inventory?: number | null;
              soldAsIs?: boolean | null;
              acceptedPaymentMethods?: Array<string | null> | null;
              shop?: {
                __typename?: "Shop";
                _id?: string | null;
                address?: {
                  __typename?: "core_apimessages_Address";
                  _id?: string | null;
                  locality?: string | null;
                  countryCode?: string | null;
                  region?: string | null;
                  country?: {
                    __typename?: "core_apimessages_Country";
                    _id?: string | null;
                    countryCode?: string | null;
                    name?: string | null;
                  } | null;
                } | null;
                returnPolicy?: {
                  __typename?: "core_apimessages_ReturnPolicy";
                  _id?: string | null;
                  newReturnWindowDays?: number | null;
                  usedReturnWindowDays?: number | null;
                } | null;
              } | null;
              condition?: {
                __typename?: "core_apimessages_Condition";
                displayName?: string | null;
                conditionSlug?: string | null;
                conditionUuid?: string | null;
              } | null;
              price?: {
                __typename?: "core_apimessages_Money";
                amountCents?: number | null;
                display?: string | null;
              } | null;
              pricing?: {
                __typename?: "core_apimessages_ListingPricing";
                buyerPrice?: {
                  __typename?: "core_apimessages_Money";
                  display?: string | null;
                  currency?: string | null;
                  amountCents?: number | null;
                  amount?: string | null;
                } | null;
                originalPrice?: {
                  __typename?: "core_apimessages_Money";
                  display?: string | null;
                } | null;
                ribbon?: {
                  __typename?: "core_apimessages_Ribbon";
                  display?: string | null;
                  reason?: core_apimessages_Ribbon_Reason | null;
                } | null;
              } | null;
              shipping?: {
                __typename?: "core_apimessages_ShippingPrices";
                freeExpeditedShipping?: boolean | null;
                localPickupOnly?: boolean | null;
                localPickup?: boolean | null;
                shippingPrices?: Array<{
                  __typename?: "core_apimessages_ShippingPrice";
                  _id?: string | null;
                  shippingMethod?: core_apimessages_ShippingMethod | null;
                  carrierCalculated?: boolean | null;
                  destinationPostalCodeNeeded?: boolean | null;
                  rate?: {
                    __typename?: "core_apimessages_Money";
                    amount?: string | null;
                    amountCents?: number | null;
                    currency?: string | null;
                    display?: string | null;
                  } | null;
                } | null> | null;
              } | null;
              preorderInfo?: {
                __typename?: "core_apimessages_PreorderInfo";
                onPreorder?: boolean | null;
                estimatedShipDate?: {
                  __typename?: "google_protobuf_Timestamp";
                  seconds?: number | null;
                } | null;
              } | null;
              watchThumbnails?: Array<{
                __typename?: "core_apimessages_Image";
                _id?: string | null;
                source?: string | null;
              } | null> | null;
              priceRecommendation?: {
                __typename?: "reverb_pricing_PriceRecommendation";
                priceMiddle?: {
                  __typename?: "reverb_pricing_Money";
                  amountCents?: number | null;
                  currency?: string | null;
                } | null;
              } | null;
              images?: Array<{
                __typename?: "core_apimessages_Image";
                _id?: string | null;
                source?: string | null;
              } | null> | null;
              largeSquareImages?: Array<{
                __typename?: "core_apimessages_Image";
                _id?: string | null;
                source?: string | null;
              } | null> | null;
              certifiedPreOwned?: {
                __typename?: "core_apimessages_ListingCertifiedPreOwned";
                title?: string | null;
                badgeIconUrl?: string | null;
              } | null;
              categories?: Array<{
                __typename?: "Category";
                _id?: string | null;
                slug?: string | null;
                rootSlug?: string | null;
              } | null> | null;
              csp?: {
                __typename?: "CSP";
                _id?: string | null;
                id?: string | null;
                slug?: string | null;
                brand?: {
                  __typename?: "Brand";
                  _id?: string | null;
                  slug?: string | null;
                } | null;
              } | null;
              publishedAt?: {
                __typename?: "google_protobuf_Timestamp";
                seconds?: number | null;
              } | null;
              sale?: {
                __typename?: "core_apimessages_Sale";
                _id?: string | null;
                id?: string | null;
                code?: string | null;
                buyerIneligibilityReason?: string | null;
              } | null;
            }
          | null;
        metadata?: {
          __typename?: "reverb_feed_FeedMetadata";
          sourceType?: reverb_feed_FeedSourceType | null;
        } | null;
        localizedContents?: Array<{
          __typename?: "reverb_feed_FollowLocalizedContents";
          locale?: string | null;
          title?: string | null;
          shortDescription?: string | null;
        } | null> | null;
      } | null> | null;
    } | null;
  } | null;
};

export type MixedFeedRowFragment = {
  __typename?: "reverb_feed_FeedEntry";
  id?: number | null;
  type?: reverb_feed_FeedEntry_EntryType | null;
  deleted?: boolean | null;
  metadata?: {
    __typename?: "reverb_feed_FeedMetadata";
    sourceType?: reverb_feed_FeedSourceType | null;
  } | null;
  localizedContents?: Array<{
    __typename?: "reverb_feed_FollowLocalizedContents";
    locale?: string | null;
    title?: string | null;
    shortDescription?: string | null;
  } | null> | null;
  entry?:
    | {
        __typename?: "Article";
        _id?: string | null;
        id?: string | null;
        title?: string | null;
        images?: Array<{
          __typename?: "core_apimessages_Image";
          source?: string | null;
        } | null> | null;
        webLink?: {
          __typename?: "core_apimessages_Link";
          href?: string | null;
        } | null;
        articleCategories?: Array<{
          __typename?: "core_apimessages_ArticleCategory";
          name?: string | null;
          slug?: string | null;
        } | null> | null;
      }
    | {
        __typename?: "Listing";
        _id?: string | null;
        id?: string | null;
        usOutlet?: boolean | null;
        bumped?: boolean | null;
        categoryUuids?: Array<string | null> | null;
        slug?: string | null;
        title?: string | null;
        description?: string | null;
        listingType?: core_apimessages_Listing_ListingType | null;
        watching?: boolean | null;
        state?: string | null;
        stateDescription?: string | null;
        sellerId?: string | null;
        currency?: string | null;
        offersEnabled?: boolean | null;
        isBuyerOfferEligible?: boolean | null;
        brandSlug?: string | null;
        categoryRootUuid?: string | null;
        make?: string | null;
        bumpEligible?: boolean | null;
        shopId?: string | null;
        inventory?: number | null;
        soldAsIs?: boolean | null;
        acceptedPaymentMethods?: Array<string | null> | null;
        condition?: {
          __typename?: "core_apimessages_Condition";
          displayName?: string | null;
          conditionSlug?: string | null;
          conditionUuid?: string | null;
        } | null;
        price?: {
          __typename?: "core_apimessages_Money";
          amountCents?: number | null;
          display?: string | null;
        } | null;
        pricing?: {
          __typename?: "core_apimessages_ListingPricing";
          buyerPrice?: {
            __typename?: "core_apimessages_Money";
            display?: string | null;
            currency?: string | null;
            amountCents?: number | null;
            amount?: string | null;
          } | null;
          originalPrice?: {
            __typename?: "core_apimessages_Money";
            display?: string | null;
          } | null;
          ribbon?: {
            __typename?: "core_apimessages_Ribbon";
            display?: string | null;
            reason?: core_apimessages_Ribbon_Reason | null;
          } | null;
        } | null;
        shipping?: {
          __typename?: "core_apimessages_ShippingPrices";
          freeExpeditedShipping?: boolean | null;
          localPickupOnly?: boolean | null;
          localPickup?: boolean | null;
          shippingPrices?: Array<{
            __typename?: "core_apimessages_ShippingPrice";
            _id?: string | null;
            shippingMethod?: core_apimessages_ShippingMethod | null;
            carrierCalculated?: boolean | null;
            destinationPostalCodeNeeded?: boolean | null;
            rate?: {
              __typename?: "core_apimessages_Money";
              amount?: string | null;
              amountCents?: number | null;
              currency?: string | null;
              display?: string | null;
            } | null;
          } | null> | null;
        } | null;
        shop?: {
          __typename?: "Shop";
          _id?: string | null;
          address?: {
            __typename?: "core_apimessages_Address";
            _id?: string | null;
            countryCode?: string | null;
            country?: {
              __typename?: "core_apimessages_Country";
              _id?: string | null;
              countryCode?: string | null;
              name?: string | null;
            } | null;
          } | null;
          returnPolicy?: {
            __typename?: "core_apimessages_ReturnPolicy";
            _id?: string | null;
            newReturnWindowDays?: number | null;
            usedReturnWindowDays?: number | null;
          } | null;
        } | null;
        preorderInfo?: {
          __typename?: "core_apimessages_PreorderInfo";
          onPreorder?: boolean | null;
          estimatedShipDate?: {
            __typename?: "google_protobuf_Timestamp";
            seconds?: number | null;
          } | null;
        } | null;
        watchThumbnails?: Array<{
          __typename?: "core_apimessages_Image";
          _id?: string | null;
          source?: string | null;
        } | null> | null;
        priceRecommendation?: {
          __typename?: "reverb_pricing_PriceRecommendation";
          priceMiddle?: {
            __typename?: "reverb_pricing_Money";
            amountCents?: number | null;
            currency?: string | null;
          } | null;
        } | null;
        images?: Array<{
          __typename?: "core_apimessages_Image";
          _id?: string | null;
          source?: string | null;
        } | null> | null;
        largeSquareImages?: Array<{
          __typename?: "core_apimessages_Image";
          _id?: string | null;
          source?: string | null;
        } | null> | null;
        certifiedPreOwned?: {
          __typename?: "core_apimessages_ListingCertifiedPreOwned";
          title?: string | null;
          badgeIconUrl?: string | null;
        } | null;
        categories?: Array<{
          __typename?: "Category";
          _id?: string | null;
          slug?: string | null;
          rootSlug?: string | null;
        } | null> | null;
        csp?: {
          __typename?: "CSP";
          _id?: string | null;
          id?: string | null;
          slug?: string | null;
          brand?: {
            __typename?: "Brand";
            _id?: string | null;
            slug?: string | null;
          } | null;
        } | null;
        publishedAt?: {
          __typename?: "google_protobuf_Timestamp";
          seconds?: number | null;
        } | null;
        sale?: {
          __typename?: "core_apimessages_Sale";
          _id?: string | null;
          id?: string | null;
          code?: string | null;
          buyerIneligibilityReason?: string | null;
        } | null;
      }
    | null;
};

export type RemoveFeedEntryMutationVariables = Exact<{
  input?: InputMaybe<Input_reverb_feed_RemoveEntryRequest>;
}>;

export type RemoveFeedEntryMutation = {
  __typename?: "Mutation";
  removeFeedEntry?: {
    __typename?: "reverb_feed_RemoveEntryResponse";
    entry?: {
      __typename?: "reverb_feed_FeedEntry";
      id?: number | null;
      type?: reverb_feed_FeedEntry_EntryType | null;
      deleted?: boolean | null;
    } | null;
  } | null;
};

export type Core_HomePage_FeedQueryVariables = Exact<{
  limit?: InputMaybe<Scalars["Int"]["input"]>;
}>;

export type Core_HomePage_FeedQuery = {
  __typename?: "Query";
  me?: {
    __typename?: "rql_Me";
    _id?: string | null;
    uuid?: string | null;
    feed?: {
      __typename?: "reverb_feed_FeedResponse";
      total?: number | null;
      offset?: number | null;
      limit?: number | null;
      entries?: Array<{
        __typename?: "reverb_feed_FeedEntry";
        id?: number | null;
        type?: reverb_feed_FeedEntry_EntryType | null;
        deleted?: boolean | null;
        entry?:
          | {
              __typename?: "Article";
              _id?: string | null;
              id?: string | null;
              title?: string | null;
              images?: Array<{
                __typename?: "core_apimessages_Image";
                source?: string | null;
              } | null> | null;
              webLink?: {
                __typename?: "core_apimessages_Link";
                href?: string | null;
              } | null;
              articleCategories?: Array<{
                __typename?: "core_apimessages_ArticleCategory";
                name?: string | null;
                slug?: string | null;
              } | null> | null;
            }
          | {
              __typename?: "Listing";
              _id?: string | null;
              id?: string | null;
              usOutlet?: boolean | null;
              bumped?: boolean | null;
              categoryUuids?: Array<string | null> | null;
              slug?: string | null;
              title?: string | null;
              description?: string | null;
              listingType?: core_apimessages_Listing_ListingType | null;
              watching?: boolean | null;
              state?: string | null;
              stateDescription?: string | null;
              sellerId?: string | null;
              currency?: string | null;
              offersEnabled?: boolean | null;
              isBuyerOfferEligible?: boolean | null;
              brandSlug?: string | null;
              categoryRootUuid?: string | null;
              make?: string | null;
              bumpEligible?: boolean | null;
              shopId?: string | null;
              inventory?: number | null;
              soldAsIs?: boolean | null;
              acceptedPaymentMethods?: Array<string | null> | null;
              condition?: {
                __typename?: "core_apimessages_Condition";
                displayName?: string | null;
                conditionSlug?: string | null;
                conditionUuid?: string | null;
              } | null;
              price?: {
                __typename?: "core_apimessages_Money";
                amountCents?: number | null;
                display?: string | null;
              } | null;
              pricing?: {
                __typename?: "core_apimessages_ListingPricing";
                buyerPrice?: {
                  __typename?: "core_apimessages_Money";
                  display?: string | null;
                  currency?: string | null;
                  amountCents?: number | null;
                  amount?: string | null;
                } | null;
                originalPrice?: {
                  __typename?: "core_apimessages_Money";
                  display?: string | null;
                } | null;
                ribbon?: {
                  __typename?: "core_apimessages_Ribbon";
                  display?: string | null;
                  reason?: core_apimessages_Ribbon_Reason | null;
                } | null;
              } | null;
              shipping?: {
                __typename?: "core_apimessages_ShippingPrices";
                freeExpeditedShipping?: boolean | null;
                localPickupOnly?: boolean | null;
                localPickup?: boolean | null;
                shippingPrices?: Array<{
                  __typename?: "core_apimessages_ShippingPrice";
                  _id?: string | null;
                  shippingMethod?: core_apimessages_ShippingMethod | null;
                  carrierCalculated?: boolean | null;
                  destinationPostalCodeNeeded?: boolean | null;
                  rate?: {
                    __typename?: "core_apimessages_Money";
                    amount?: string | null;
                    amountCents?: number | null;
                    currency?: string | null;
                    display?: string | null;
                  } | null;
                } | null> | null;
              } | null;
              shop?: {
                __typename?: "Shop";
                _id?: string | null;
                address?: {
                  __typename?: "core_apimessages_Address";
                  _id?: string | null;
                  countryCode?: string | null;
                  country?: {
                    __typename?: "core_apimessages_Country";
                    _id?: string | null;
                    countryCode?: string | null;
                    name?: string | null;
                  } | null;
                } | null;
                returnPolicy?: {
                  __typename?: "core_apimessages_ReturnPolicy";
                  _id?: string | null;
                  newReturnWindowDays?: number | null;
                  usedReturnWindowDays?: number | null;
                } | null;
              } | null;
              preorderInfo?: {
                __typename?: "core_apimessages_PreorderInfo";
                onPreorder?: boolean | null;
                estimatedShipDate?: {
                  __typename?: "google_protobuf_Timestamp";
                  seconds?: number | null;
                } | null;
              } | null;
              watchThumbnails?: Array<{
                __typename?: "core_apimessages_Image";
                _id?: string | null;
                source?: string | null;
              } | null> | null;
              priceRecommendation?: {
                __typename?: "reverb_pricing_PriceRecommendation";
                priceMiddle?: {
                  __typename?: "reverb_pricing_Money";
                  amountCents?: number | null;
                  currency?: string | null;
                } | null;
              } | null;
              images?: Array<{
                __typename?: "core_apimessages_Image";
                _id?: string | null;
                source?: string | null;
              } | null> | null;
              largeSquareImages?: Array<{
                __typename?: "core_apimessages_Image";
                _id?: string | null;
                source?: string | null;
              } | null> | null;
              certifiedPreOwned?: {
                __typename?: "core_apimessages_ListingCertifiedPreOwned";
                title?: string | null;
                badgeIconUrl?: string | null;
              } | null;
              categories?: Array<{
                __typename?: "Category";
                _id?: string | null;
                slug?: string | null;
                rootSlug?: string | null;
              } | null> | null;
              csp?: {
                __typename?: "CSP";
                _id?: string | null;
                id?: string | null;
                slug?: string | null;
                brand?: {
                  __typename?: "Brand";
                  _id?: string | null;
                  slug?: string | null;
                } | null;
              } | null;
              publishedAt?: {
                __typename?: "google_protobuf_Timestamp";
                seconds?: number | null;
              } | null;
              sale?: {
                __typename?: "core_apimessages_Sale";
                _id?: string | null;
                id?: string | null;
                code?: string | null;
                buyerIneligibilityReason?: string | null;
              } | null;
            }
          | null;
        metadata?: {
          __typename?: "reverb_feed_FeedMetadata";
          sourceType?: reverb_feed_FeedSourceType | null;
        } | null;
        localizedContents?: Array<{
          __typename?: "reverb_feed_FollowLocalizedContents";
          locale?: string | null;
          title?: string | null;
          shortDescription?: string | null;
        } | null> | null;
      } | null> | null;
    } | null;
  } | null;
};

export type Core_AdyenBalanceShopQueryVariables = Exact<{
  [key: string]: never;
}>;

export type Core_AdyenBalanceShopQuery = {
  __typename?: "Query";
  me?: {
    __typename?: "rql_Me";
    _id?: string | null;
    id?: string | null;
    shop?: {
      __typename?: "MyShop";
      _id?: string | null;
      adyenBalanceOnboardingDetails?: {
        __typename?: "core_apimessages_AdyenBalanceOnboardingResponse";
        accountHolderId?: string | null;
      } | null;
    } | null;
  } | null;
};

export type Core_InformActVerification_StatusQueryVariables = Exact<{
  [key: string]: never;
}>;

export type Core_InformActVerification_StatusQuery = {
  __typename?: "Query";
  me?: {
    __typename?: "rql_Me";
    _id?: string | null;
    id?: string | null;
    shop?: {
      __typename?: "MyShop";
      _id?: string | null;
      address?: {
        __typename?: "core_apimessages_Address";
        _id?: string | null;
        countryCode?: string | null;
      } | null;
      informActThresholdStatus?: {
        __typename?: "core_apimessages_InformActThresholdStatus";
        id?: string | null;
        isCompliant?: boolean | null;
        hasValidTaxId?: boolean | null;
        hasValidBankAccount?: boolean | null;
        hasCompletedSellerVerification?: boolean | null;
        bankAccountVerifiedDate?: any | null;
        isPaypalOnly?: boolean | null;
      } | null;
      taxProfile?: {
        __typename?: "core_apimessages_TaxProfile";
        updatedAt?: any | null;
      } | null;
      internationalTaxProfile?: {
        __typename?: "core_apimessages_InternationalTaxProfile";
        updatedAt?: any | null;
      } | null;
    } | null;
  } | null;
};

export type Core_Trulioo_Verification_And_Policy_DataQueryVariables = Exact<{
  [key: string]: never;
}>;

export type Core_Trulioo_Verification_And_Policy_DataQuery = {
  __typename?: "Query";
  me?: {
    __typename?: "rql_Me";
    _id?: string | null;
    uuid?: string | null;
    shop?: {
      __typename?: "MyShop";
      _id?: string | null;
      shopType?: core_apimessages_Shop_ShopType | null;
      sellerVerificationPolicy?: {
        __typename?: "core_apimessages_SellerVerificationPolicyResponse";
        eligibleForInformAct?: boolean | null;
        eligibleForSellerVerification?: boolean | null;
        eligibleForSellerVerificationBackcast?: boolean | null;
        eligibleForColdStorageSellerVerificationBypass?: boolean | null;
      } | null;
      truliooOnboarding?: {
        __typename?: "core_apimessages_TruliooOnboarding";
        id?: string | null;
        verificationStatus?: core_apimessages_TruliooOnboarding_VerificationStatus | null;
        completedAt?: any | null;
        parentRecords?: Array<{
          __typename?: "core_apimessages_TruliooOnboardingRecord";
          id?: string | null;
          workflowStatus?: core_apimessages_TruliooOnboardingRecord_Status | null;
          entityType?: core_apimessages_TruliooOnboardingRecord_EntityType | null;
        } | null> | null;
        childrenRecords?: Array<{
          __typename?: "core_apimessages_TruliooOnboardingRecord";
          id?: string | null;
          workflowStatus?: core_apimessages_TruliooOnboardingRecord_Status | null;
          email?: string | null;
          isParent?: boolean | null;
          flowId?: string | null;
          entityType?: core_apimessages_TruliooOnboardingRecord_EntityType | null;
          fullName?: string | null;
        } | null> | null;
      } | null;
    } | null;
  } | null;
};

export type Core_RestartTruliooOnboardingWorkflowMutationVariables = Exact<{
  [key: string]: never;
}>;

export type Core_RestartTruliooOnboardingWorkflowMutation = {
  __typename?: "Mutation";
  restartTruliooOnboardingWorkflow?: {
    __typename?: "core_apimessages_TruliooOnboardingResponse";
    truliooOnboarding?: {
      __typename?: "core_apimessages_TruliooOnboarding";
      id?: string | null;
      verificationStatus?: core_apimessages_TruliooOnboarding_VerificationStatus | null;
      completedAt?: any | null;
    } | null;
  } | null;
};

export type Core_TruliooOnboardingRecordResendPscEmailMutationVariables =
  Exact<{
    input?: InputMaybe<Input_core_apimessages_TruliooOnboardingRecordResendPscEmailRequest>;
  }>;

export type Core_TruliooOnboardingRecordResendPscEmailMutation = {
  __typename?: "Mutation";
  truliooOnboardingRecordResendPscEmail?: {
    __typename?: "core_apimessages_TruliooOnboardingRecordResendPscEmailResponse";
    truliooOnboardingRecordId?: string | null;
  } | null;
};

export type Core_AdyenOnboardingLink_CreateMutationVariables = Exact<{
  input?: InputMaybe<Input_core_apimessages_AdyenOnboardingLinkRequest>;
}>;

export type Core_AdyenOnboardingLink_CreateMutation = {
  __typename?: "Mutation";
  createAdyenOnboardingLink?: {
    __typename?: "core_apimessages_AdyenOnboardingLinkResponse";
    url?: string | null;
  } | null;
};

export type AdyenOnboardingFormQueryVariables = Exact<{ [key: string]: never }>;

export type AdyenOnboardingFormQuery = {
  __typename?: "Query";
  countries?: {
    __typename?: "core_apimessages_CountriesResponse";
    countries?: Array<{
      __typename?: "core_apimessages_Country";
      _id?: string | null;
      name?: string | null;
      countryCode?: string | null;
      subregions?: Array<{
        __typename?: "core_apimessages_Subregion";
        _id?: string | null;
        name?: string | null;
        code?: string | null;
      } | null> | null;
    } | null> | null;
  } | null;
  me?: {
    __typename?: "rql_Me";
    _id?: string | null;
    uuid?: string | null;
    shop?: {
      __typename?: "MyShop";
      _id?: string | null;
      currency?: string | null;
      originCountryCode?: string | null;
      shopType?: core_apimessages_Shop_ShopType | null;
      billingMethodStatus?: core_apimessages_MyShopOnboardingResponse_BillingMethodStatus | null;
      paypalSetupStatus?: core_apimessages_MyShopOnboardingResponse_PaypalSetupStatus | null;
      returnPolicy?: {
        __typename?: "core_apimessages_ReturnPolicy";
        _id?: string | null;
        newReturnWindowDays?: number | null;
        usedReturnWindowDays?: number | null;
        restockingFeePercent?: number | null;
        specialConditions?: string | null;
      } | null;
      paypalProfile?: {
        __typename?: "core_apimessages_MyShopPaypalProfileResponse_PaypalProfile";
        email?: string | null;
      } | null;
      adyenBalanceOnboardingDetails?: {
        __typename?: "core_apimessages_AdyenBalanceOnboardingResponse";
        accountHolderId?: string | null;
        balanceAccountId?: string | null;
        legalEntityId?: string | null;
        transferInstrumentId?: string | null;
      } | null;
    } | null;
    creditCards?: Array<{
      __typename?: "core_apimessages_CreditCard";
      id?: string | null;
      last4?: string | null;
      cardType?: string | null;
      expirationYear?: string | null;
      expirationMonth?: string | null;
      cardholderName?: string | null;
    } | null> | null;
  } | null;
};

export type Core_SellerVerification_SetShopTypeMutationVariables = Exact<{
  input?: InputMaybe<Input_core_apimessages_UpdateMyShopRequest>;
}>;

export type Core_SellerVerification_SetShopTypeMutation = {
  __typename?: "Mutation";
  updateMyShop?: {
    __typename?: "core_apimessages_UpdateMyShopResponse";
    shop?: {
      __typename?: "core_apimessages_Shop";
      shopType?: core_apimessages_Shop_ShopType | null;
    } | null;
  } | null;
};

export type Core_SellerVerification_ShopDataQueryVariables = Exact<{
  [key: string]: never;
}>;

export type Core_SellerVerification_ShopDataQuery = {
  __typename?: "Query";
  me?: {
    __typename?: "rql_Me";
    _id?: string | null;
    uuid?: string | null;
    shop?: {
      __typename?: "MyShop";
      _id?: string | null;
      shopType?: core_apimessages_Shop_ShopType | null;
      truliooOnboarding?: {
        __typename?: "core_apimessages_TruliooOnboarding";
        id?: string | null;
        verificationStatus?: core_apimessages_TruliooOnboarding_VerificationStatus | null;
        parentRecords?: Array<{
          __typename?: "core_apimessages_TruliooOnboardingRecord";
          id?: string | null;
          entityType?: core_apimessages_TruliooOnboardingRecord_EntityType | null;
          workflowStatus?: core_apimessages_TruliooOnboardingRecord_Status | null;
          clientId?: string | null;
          flowId?: string | null;
        } | null> | null;
        childrenRecords?: Array<{
          __typename?: "core_apimessages_TruliooOnboardingRecord";
          id?: string | null;
          workflowStatus?: core_apimessages_TruliooOnboardingRecord_Status | null;
        } | null> | null;
      } | null;
    } | null;
  } | null;
};

export type Core_SellerVerification_CreateTruliooOnboardingRecordMutationVariables =
  Exact<{ [key: string]: never }>;

export type Core_SellerVerification_CreateTruliooOnboardingRecordMutation = {
  __typename?: "Mutation";
  createTruliooOnboardingRecord?: {
    __typename?: "core_apimessages_CreateTruliooOnboardingRecordResponse";
    truliooOnboardingRecord?: {
      __typename?: "core_apimessages_TruliooOnboardingRecord";
      id?: string | null;
      flowId?: string | null;
      clientId?: string | null;
      workflowStatus?: core_apimessages_TruliooOnboardingRecord_Status | null;
      entityType?: core_apimessages_TruliooOnboardingRecord_EntityType | null;
    } | null;
  } | null;
};

export type Core_SellerVerification_PrepublishStatusQueryVariables = Exact<{
  [key: string]: never;
}>;

export type Core_SellerVerification_PrepublishStatusQuery = {
  __typename?: "Query";
  me?: {
    __typename?: "rql_Me";
    _id?: string | null;
    uuid?: string | null;
    prepublishStatus?: {
      __typename?: "core_apimessages_PrepublishStatusResponse";
      nextStep?: core_apimessages_PrepublishStatusResponse_PrepublishStatus | null;
    } | null;
    shop?: {
      __typename?: "MyShop";
      _id?: string | null;
      truliooOnboarding?: {
        __typename?: "core_apimessages_TruliooOnboarding";
        id?: string | null;
        verificationStatus?: core_apimessages_TruliooOnboarding_VerificationStatus | null;
        truliooOnboardingRecords?: Array<{
          __typename?: "core_apimessages_TruliooOnboardingRecord";
          id?: string | null;
          workflowStatus?: core_apimessages_TruliooOnboardingRecord_Status | null;
        } | null> | null;
      } | null;
    } | null;
  } | null;
};

export type SetupShopPaymentMethodsMutationVariables = Exact<{
  input?: InputMaybe<Input_core_apimessages_SetupPaymentMethodsRequest>;
}>;

export type SetupShopPaymentMethodsMutation = {
  __typename?: "Mutation";
  setupShopPaymentMethods?: {
    __typename?: "core_apimessages_SetupPaymentMethodsResponse";
    errors?: Array<{
      __typename?: "core_apimessages_Error";
      message?: string | null;
    } | null> | null;
  } | null;
};

export type UpdateMyShopMutationVariables = Exact<{
  input?: InputMaybe<Input_core_apimessages_UpdateMyShopRequest>;
}>;

export type UpdateMyShopMutation = {
  __typename?: "Mutation";
  updateMyShop?: {
    __typename?: "core_apimessages_UpdateMyShopResponse";
    errors?: Array<{
      __typename?: "core_apimessages_Error";
      message?: string | null;
    } | null> | null;
  } | null;
};

export const AdCampaignDataFragmentDoc = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "AdCampaignData" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "AdCampaign" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "uuid" } },
          { kind: "Field", name: { kind: "Name", value: "name" } },
          { kind: "Field", name: { kind: "Name", value: "status" } },
          { kind: "Field", name: { kind: "Name", value: "startsAt" } },
          { kind: "Field", name: { kind: "Name", value: "endsAt" } },
          { kind: "Field", name: { kind: "Name", value: "advertiserUuid" } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<AdCampaignDataFragment, unknown>;
export const AdDataFragmentDoc = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "AdData" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "core_apimessages_Ad" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "uuid" } },
          { kind: "Field", name: { kind: "Name", value: "format" } },
          { kind: "Field", name: { kind: "Name", value: "adGroupUuid" } },
          { kind: "Field", name: { kind: "Name", value: "adGroupName" } },
          { kind: "Field", name: { kind: "Name", value: "adCampaignUuid" } },
          { kind: "Field", name: { kind: "Name", value: "advertiserUuid" } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<AdDataFragment, unknown>;
export const AdCspRowQueryDataFragmentDoc = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "AdCspRowQueryData" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "core_apimessages_CspRowQuery" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "brandSlugs" } },
          { kind: "Field", name: { kind: "Name", value: "categoryUuids" } },
          { kind: "Field", name: { kind: "Name", value: "cspSlugs" } },
          { kind: "Field", name: { kind: "Name", value: "rowTitle" } },
          { kind: "Field", name: { kind: "Name", value: "sort" } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<AdCspRowQueryDataFragment, unknown>;
export const AdGroupWithoutKeywordsDataFragmentDoc = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "AdGroupWithoutKeywordsData" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "AdGroup" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "uuid" } },
          { kind: "Field", name: { kind: "Name", value: "name" } },
          { kind: "Field", name: { kind: "Name", value: "adCampaignUuid" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "targetedCategoryUuids" },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "targetedCountryCodes" },
          },
          { kind: "Field", name: { kind: "Name", value: "targetedDevices" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "targetedBrandSlugs" },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "targetedAdZones" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "adZone" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "slug" } },
                    ],
                  },
                },
                { kind: "Field", name: { kind: "Name", value: "startsAt" } },
                { kind: "Field", name: { kind: "Name", value: "endsAt" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<AdGroupWithoutKeywordsDataFragment, unknown>;
export const FullFinancingFieldsFragmentDoc = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "FullFinancingFields" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Listing" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "estimatedMonthlyPaymentPlan" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "financingProgramSlug" },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "pricing" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "buyerPrice" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "amountCents" },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<FullFinancingFieldsFragment, unknown>;
export const CSPLightboxDataFragmentDoc = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "CSPLightboxData" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "CSP" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          {
            kind: "Field",
            alias: { kind: "Name", value: "squareImage" },
            name: { kind: "Name", value: "images" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "transform" },
                      value: {
                        kind: "StringValue",
                        value: "card_square",
                        block: false,
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "scope" },
                      value: {
                        kind: "StringValue",
                        value: "photos",
                        block: false,
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "limit" },
                      value: { kind: "IntValue", value: "20" },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "source" } },
              ],
            },
          },
          {
            kind: "Field",
            alias: { kind: "Name", value: "fullsizeImage" },
            name: { kind: "Name", value: "images" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "transform" },
                      value: {
                        kind: "StringValue",
                        value: "supersize",
                        block: false,
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "scope" },
                      value: {
                        kind: "StringValue",
                        value: "photos",
                        block: false,
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "limit" },
                      value: { kind: "IntValue", value: "20" },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "source" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "videos" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "videoLinks" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "youtubeVideoId" },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CSPLightboxDataFragment, unknown>;
export const ListingCardSignalsDataFragmentDoc = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "ListingCardSignalsData" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "reverb_signals_Signal" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "name" } },
          { kind: "Field", name: { kind: "Name", value: "title" } },
          { kind: "Field", name: { kind: "Name", value: "icon" } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ListingCardSignalsDataFragment, unknown>;
export const FinancingFieldsFragmentDoc = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "FinancingFields" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Listing" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "estimatedMonthlyPaymentPlan" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "estimatedMonthlyPayment" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "display" },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<FinancingFieldsFragment, unknown>;
export const ShopFieldsFragmentDoc = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "ShopFields" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Listing" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "shop" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "address" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "locality" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "countryCode" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "region" },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ShopFieldsFragment, unknown>;
export const ModalSidebarListingFragmentFragmentDoc = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "ModalSidebarListingFragment" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Listing" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "title" } },
          { kind: "Field", name: { kind: "Name", value: "slug" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "condition" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "displayName" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "images" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "transform" },
                      value: {
                        kind: "StringValue",
                        value: "card_square",
                        block: false,
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "source" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ModalSidebarListingFragmentFragment, unknown>;
export const PhotoTileFieldsFragmentDoc = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "PhotoTileFields" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Listing" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "slug" } },
          { kind: "Field", name: { kind: "Name", value: "title" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "images" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "transform" },
                      value: {
                        kind: "StringValue",
                        value: "card_square",
                        block: false,
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "count" },
                      value: { kind: "IntValue", value: "1" },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "scope" },
                      value: {
                        kind: "StringValue",
                        value: "photos",
                        block: false,
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "type" },
                      value: {
                        kind: "StringValue",
                        value: "Product",
                        block: false,
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "source" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<PhotoTileFieldsFragment, unknown>;
export const Item2PriceWithShippingCartItemDataFragmentDoc = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "Item2PriceWithShippingCartItemData" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "rql_Me" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "uuid" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "cart" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "cartItems" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "shopId" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "listingId" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "subtotal" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "amountCents" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "currency" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Item2PriceWithShippingCartItemDataFragment,
  unknown
>;
export const NestedFilterFragmentDoc = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "NestedFilter" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "reverb_search_Filter" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "name" } },
          { kind: "Field", name: { kind: "Name", value: "key" } },
          { kind: "Field", name: { kind: "Name", value: "aggregationName" } },
          { kind: "Field", name: { kind: "Name", value: "widgetType" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "options" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "count" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "value" } },
                    ],
                  },
                },
                { kind: "Field", name: { kind: "Name", value: "name" } },
                { kind: "Field", name: { kind: "Name", value: "selected" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "autoselected" },
                },
                { kind: "Field", name: { kind: "Name", value: "paramName" } },
                { kind: "Field", name: { kind: "Name", value: "setValues" } },
                { kind: "Field", name: { kind: "Name", value: "unsetValues" } },
                { kind: "Field", name: { kind: "Name", value: "all" } },
                { kind: "Field", name: { kind: "Name", value: "optionValue" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "trackingValue" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "subFilter" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "widgetType" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "options" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "count" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "value" },
                                  },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "name" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "selected" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "autoselected" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "paramName" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "setValues" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "unsetValues" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "all" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "optionValue" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "trackingValue" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "subFilter" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "widgetType" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "options" },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "count",
                                          },
                                          selectionSet: {
                                            kind: "SelectionSet",
                                            selections: [
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "value",
                                                },
                                              },
                                            ],
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "name" },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "selected",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "autoselected",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "paramName",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "setValues",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "unsetValues",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "all" },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "optionValue",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "trackingValue",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "subFilter",
                                          },
                                          selectionSet: {
                                            kind: "SelectionSet",
                                            selections: [
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "widgetType",
                                                },
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "options",
                                                },
                                                selectionSet: {
                                                  kind: "SelectionSet",
                                                  selections: [
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "count",
                                                      },
                                                      selectionSet: {
                                                        kind: "SelectionSet",
                                                        selections: [
                                                          {
                                                            kind: "Field",
                                                            name: {
                                                              kind: "Name",
                                                              value: "value",
                                                            },
                                                          },
                                                        ],
                                                      },
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "name",
                                                      },
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "selected",
                                                      },
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "autoselected",
                                                      },
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "paramName",
                                                      },
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "setValues",
                                                      },
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "unsetValues",
                                                      },
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "all",
                                                      },
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "optionValue",
                                                      },
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "trackingValue",
                                                      },
                                                    },
                                                  ],
                                                },
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<NestedFilterFragment, unknown>;
export const FlatFilterFragmentDoc = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "FlatFilter" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "reverb_search_Filter" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "name" } },
          { kind: "Field", name: { kind: "Name", value: "key" } },
          { kind: "Field", name: { kind: "Name", value: "aggregationName" } },
          { kind: "Field", name: { kind: "Name", value: "widgetType" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "options" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "count" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "value" } },
                    ],
                  },
                },
                { kind: "Field", name: { kind: "Name", value: "name" } },
                { kind: "Field", name: { kind: "Name", value: "selected" } },
                { kind: "Field", name: { kind: "Name", value: "paramName" } },
                { kind: "Field", name: { kind: "Name", value: "setValues" } },
                { kind: "Field", name: { kind: "Name", value: "unsetValues" } },
                { kind: "Field", name: { kind: "Name", value: "all" } },
                { kind: "Field", name: { kind: "Name", value: "optionValue" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<FlatFilterFragment, unknown>;
export const Item2LoggedInOffersCountDataFragmentDoc = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "Item2LoggedInOffersCountData" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "rql_Me" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "profile" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "totalBuyerOffersCreated" },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<Item2LoggedInOffersCountDataFragment, unknown>;
export const SellerOfferListingFragmentFragmentDoc = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "SellerOfferListingFragment" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Listing" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "sellerPrice" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "amount" } },
                { kind: "Field", name: { kind: "Name", value: "amountCents" } },
                { kind: "Field", name: { kind: "Name", value: "currency" } },
                { kind: "Field", name: { kind: "Name", value: "symbol" } },
                { kind: "Field", name: { kind: "Name", value: "display" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "sellerCost" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "display" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<SellerOfferListingFragmentFragment, unknown>;
export const OfferListingFragmentFragmentDoc = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "OfferListingFragment" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Listing" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "slug" } },
          { kind: "Field", name: { kind: "Name", value: "title" } },
          { kind: "Field", name: { kind: "Name", value: "hasInventory" } },
          { kind: "Field", name: { kind: "Name", value: "inventory" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "images" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "transform" },
                      value: {
                        kind: "StringValue",
                        value: "card_square",
                        block: false,
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "source" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "condition" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "displayName" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "conditionSlug" },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "price" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "amount" } },
                { kind: "Field", name: { kind: "Name", value: "amountCents" } },
                { kind: "Field", name: { kind: "Name", value: "currency" } },
                { kind: "Field", name: { kind: "Name", value: "symbol" } },
                { kind: "Field", name: { kind: "Name", value: "display" } },
              ],
            },
          },
          { kind: "Field", name: { kind: "Name", value: "taxIncluded" } },
          { kind: "Field", name: { kind: "Name", value: "taxIncludedHint" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "shop" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "name" } },
                { kind: "Field", name: { kind: "Name", value: "currency" } },
                { kind: "Field", name: { kind: "Name", value: "slug" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "address" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "displayLocation" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "locality" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "region" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "postalCode" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "countryCode" },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "lowballOfferPercentage" },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "sale" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "id" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "canonicalProductId" },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<OfferListingFragmentFragment, unknown>;
export const AutoOfferListingFragmentFragmentDoc = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "AutoOfferListingFragment" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Listing" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "title" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "price" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "amount" } },
                { kind: "Field", name: { kind: "Name", value: "amountCents" } },
                { kind: "Field", name: { kind: "Name", value: "currency" } },
                { kind: "Field", name: { kind: "Name", value: "symbol" } },
                { kind: "Field", name: { kind: "Name", value: "display" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "condition" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "displayName" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "sellerCost" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "display" } },
              ],
            },
          },
          { kind: "Field", name: { kind: "Name", value: "taxIncluded" } },
          { kind: "Field", name: { kind: "Name", value: "taxIncludedHint" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "sellerPrice" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "amountCents" } },
                { kind: "Field", name: { kind: "Name", value: "display" } },
                { kind: "Field", name: { kind: "Name", value: "currency" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "counts" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "watchers" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "autoOffers" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "listingId" } },
                { kind: "Field", name: { kind: "Name", value: "message" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "price" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "amount" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "currency" },
                      },
                    ],
                  },
                },
                { kind: "Field", name: { kind: "Name", value: "percentage" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "shippingPriceOverride" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "amount" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "amountCents" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "currency" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "symbol" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "display" },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "shipmentPackage" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<AutoOfferListingFragmentFragment, unknown>;
export const CreateUserActionMeDataFragmentDoc = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "CreateUserActionMeData" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "rql_Me" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "uuid" } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CreateUserActionMeDataFragment, unknown>;
export const ActionCacheFragmentDataFragmentDoc = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "ActionCacheFragmentData" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "core_apimessages_AvailableAction" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "name" } },
          { kind: "Field", name: { kind: "Name", value: "status" } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ActionCacheFragmentDataFragment, unknown>;
export const BuyListingNowApplePayDataFragmentDoc = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "BuyListingNowApplePayData" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Listing" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "currency" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "adyenCheckoutPaymentMethodsConfig" },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<BuyListingNowApplePayDataFragment, unknown>;
export const CheckoutDiscountCodeFormFieldsFragmentDoc = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "CheckoutDiscountCodeFormFields" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Checkout" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "paymentMethod" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "supportsDiscountCodes" },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CheckoutDiscountCodeFormFieldsFragment, unknown>;
export const CheckoutGuestContactInfoSummaryFieldsFragmentDoc = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "CheckoutGuestContactInfoSummaryFields" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Checkout" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "guest" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "firstName" } },
                { kind: "Field", name: { kind: "Name", value: "lastName" } },
                { kind: "Field", name: { kind: "Name", value: "email" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CheckoutGuestContactInfoSummaryFieldsFragment,
  unknown
>;
export const CheckoutNewShippingAddressFormFieldsFragmentDoc = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "CheckoutNewShippingAddressFormFields" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Checkout" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "guest" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "firstName" } },
                { kind: "Field", name: { kind: "Name", value: "lastName" } },
                { kind: "Field", name: { kind: "Name", value: "email" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "orders" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "shippingMethod" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "type" } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "shippingAddress" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "name" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "streetAddress" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "extendedAddress" },
                },
                { kind: "Field", name: { kind: "Name", value: "locality" } },
                { kind: "Field", name: { kind: "Name", value: "region" } },
                { kind: "Field", name: { kind: "Name", value: "postalCode" } },
                { kind: "Field", name: { kind: "Name", value: "countryCode" } },
                { kind: "Field", name: { kind: "Name", value: "phone" } },
                { kind: "Field", name: { kind: "Name", value: "isComplete" } },
                { kind: "Field", name: { kind: "Name", value: "regionName" } },
              ],
            },
          },
          {
            kind: "FragmentSpread",
            name: {
              kind: "Name",
              value: "CheckoutGuestContactInfoSummaryFields",
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "CheckoutGuestContactInfoSummaryFields" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Checkout" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "guest" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "firstName" } },
                { kind: "Field", name: { kind: "Name", value: "lastName" } },
                { kind: "Field", name: { kind: "Name", value: "email" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CheckoutNewShippingAddressFormFieldsFragment,
  unknown
>;
export const CheckoutTrackingFieldsFragmentDoc = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "CheckoutTrackingFields" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Checkout" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "source" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "paymentMethod" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "type" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CheckoutTrackingFieldsFragment, unknown>;
export const CheckoutExistingShippingAddressFormFieldsFragmentDoc = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: {
        kind: "Name",
        value: "CheckoutExistingShippingAddressFormFields",
      },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Checkout" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "orders" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "shippingMethod" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "type" } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "shippingAddress" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "id" } },
              ],
            },
          },
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "CheckoutTrackingFields" },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "CheckoutTrackingFields" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Checkout" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "source" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "paymentMethod" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "type" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CheckoutExistingShippingAddressFormFieldsFragment,
  unknown
>;
export const CheckoutShippingAddressFormFieldsFragmentDoc = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "CheckoutShippingAddressFormFields" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Checkout" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "shippingAddress" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "countryCode" } },
              ],
            },
          },
          {
            kind: "FragmentSpread",
            name: {
              kind: "Name",
              value: "CheckoutNewShippingAddressFormFields",
            },
          },
          {
            kind: "FragmentSpread",
            name: {
              kind: "Name",
              value: "CheckoutExistingShippingAddressFormFields",
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "CheckoutGuestContactInfoSummaryFields" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Checkout" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "guest" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "firstName" } },
                { kind: "Field", name: { kind: "Name", value: "lastName" } },
                { kind: "Field", name: { kind: "Name", value: "email" } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "CheckoutTrackingFields" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Checkout" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "source" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "paymentMethod" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "type" } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "CheckoutNewShippingAddressFormFields" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Checkout" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "guest" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "firstName" } },
                { kind: "Field", name: { kind: "Name", value: "lastName" } },
                { kind: "Field", name: { kind: "Name", value: "email" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "orders" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "shippingMethod" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "type" } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "shippingAddress" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "name" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "streetAddress" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "extendedAddress" },
                },
                { kind: "Field", name: { kind: "Name", value: "locality" } },
                { kind: "Field", name: { kind: "Name", value: "region" } },
                { kind: "Field", name: { kind: "Name", value: "postalCode" } },
                { kind: "Field", name: { kind: "Name", value: "countryCode" } },
                { kind: "Field", name: { kind: "Name", value: "phone" } },
                { kind: "Field", name: { kind: "Name", value: "isComplete" } },
                { kind: "Field", name: { kind: "Name", value: "regionName" } },
              ],
            },
          },
          {
            kind: "FragmentSpread",
            name: {
              kind: "Name",
              value: "CheckoutGuestContactInfoSummaryFields",
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: {
        kind: "Name",
        value: "CheckoutExistingShippingAddressFormFields",
      },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Checkout" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "orders" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "shippingMethod" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "type" } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "shippingAddress" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "id" } },
              ],
            },
          },
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "CheckoutTrackingFields" },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CheckoutShippingAddressFormFieldsFragment,
  unknown
>;
export const CountriesDataFragmentDoc = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "CountriesData" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Query" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "countries" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "countries" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      { kind: "Field", name: { kind: "Name", value: "name" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "countryCode" },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CountriesDataFragment, unknown>;
export const CreditCardDeleteButtonFieldsFragmentDoc = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "CreditCardDeleteButtonFields" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "rql_Me" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "creditCards" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CreditCardDeleteButtonFieldsFragment, unknown>;
export const ListingPayPalCalloutFieldsFragmentDoc = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "ListingPayPalCalloutFields" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Listing" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "currency" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "pricing" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "buyerPrice" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "amount" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "currency" },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ListingPayPalCalloutFieldsFragment, unknown>;
export const ListingPaymentOptionsFieldsFragmentDoc = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "ListingPaymentOptionsFields" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Listing" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "acceptedPaymentMethods" },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ListingPaymentOptionsFieldsFragment, unknown>;
export const ProductReviewCardFieldsFragmentDoc = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "ProductReviewCardFields" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "ProductReview" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "title" } },
          { kind: "Field", name: { kind: "Name", value: "body" } },
          { kind: "Field", name: { kind: "Name", value: "rating" } },
          { kind: "Field", name: { kind: "Name", value: "createdAt" } },
          { kind: "Field", name: { kind: "Name", value: "voteCount" } },
          { kind: "Field", name: { kind: "Name", value: "verified" } },
          { kind: "Field", name: { kind: "Name", value: "voted" } },
          { kind: "Field", name: { kind: "Name", value: "isMyReview" } },
          { kind: "Field", name: { kind: "Name", value: "reported" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "reviewer" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "shortname" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "links" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "avatar" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "href" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ProductReviewCardFieldsFragment, unknown>;
export const ProductReviewFilterFieldsFragmentDoc = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "ProductReviewFilterFields" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "reverb_search_Filter" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "name" } },
          { kind: "Field", name: { kind: "Name", value: "key" } },
          { kind: "Field", name: { kind: "Name", value: "widgetType" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "options" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "count" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "value" } },
                    ],
                  },
                },
                { kind: "Field", name: { kind: "Name", value: "name" } },
                { kind: "Field", name: { kind: "Name", value: "selected" } },
                { kind: "Field", name: { kind: "Name", value: "paramName" } },
                { kind: "Field", name: { kind: "Name", value: "setValues" } },
                { kind: "Field", name: { kind: "Name", value: "unsetValues" } },
                { kind: "Field", name: { kind: "Name", value: "optionValue" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ProductReviewFilterFieldsFragment, unknown>;
export const ProductReviewCSPFieldsFragmentDoc = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "ProductReviewCSPFields" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "CSP" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "slug" } },
          { kind: "Field", name: { kind: "Name", value: "title" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "myReview" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "body" } },
                { kind: "Field", name: { kind: "Name", value: "title" } },
                { kind: "Field", name: { kind: "Name", value: "rating" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ProductReviewCSPFieldsFragment, unknown>;
export const AdGroupWithKeywordsDataFragmentDoc = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "AdGroupWithKeywordsData" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "AdGroup" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "uuid" } },
          { kind: "Field", name: { kind: "Name", value: "name" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "targetedCategoryUuids" },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "targetedBrandSlugs" },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "targetedCountryCodes" },
          },
          { kind: "Field", name: { kind: "Name", value: "targetedDevices" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "targetedAdZones" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "adZone" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "slug" } },
                    ],
                  },
                },
                { kind: "Field", name: { kind: "Name", value: "startsAt" } },
                { kind: "Field", name: { kind: "Name", value: "endsAt" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "adGroupKeywords" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "keyword" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<AdGroupWithKeywordsDataFragment, unknown>;
export const AdGroupDataFragmentDoc = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "AdGroupData" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "AdGroup" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "uuid" } },
          { kind: "Field", name: { kind: "Name", value: "name" } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<AdGroupDataFragment, unknown>;
export const AdZoneDataFragmentDoc = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "AdZoneData" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "AdZone" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "uuid" } },
          { kind: "Field", name: { kind: "Name", value: "name" } },
          { kind: "Field", name: { kind: "Name", value: "slug" } },
          { kind: "Field", name: { kind: "Name", value: "status" } },
          { kind: "Field", name: { kind: "Name", value: "description" } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<AdZoneDataFragment, unknown>;
export const ImageAdCreativeDataFragmentDoc = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "ImageAdCreativeData" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "core_apimessages_ImageAdCreative" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "title" } },
          { kind: "Field", name: { kind: "Name", value: "titleColor" } },
          { kind: "Field", name: { kind: "Name", value: "subtitle" } },
          { kind: "Field", name: { kind: "Name", value: "subtitleColor" } },
          { kind: "Field", name: { kind: "Name", value: "ctaText" } },
          { kind: "Field", name: { kind: "Name", value: "ctaHref" } },
          { kind: "Field", name: { kind: "Name", value: "ctaTextColor" } },
          { kind: "Field", name: { kind: "Name", value: "ctaButtonColor" } },
          { kind: "Field", name: { kind: "Name", value: "backgroundImage" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "mobileBackgroundImage" },
          },
          { kind: "Field", name: { kind: "Name", value: "backgroundColor" } },
          { kind: "Field", name: { kind: "Name", value: "backgroundColors" } },
          { kind: "Field", name: { kind: "Name", value: "overlayImage" } },
          { kind: "Field", name: { kind: "Name", value: "description" } },
          { kind: "Field", name: { kind: "Name", value: "partnershipText" } },
          { kind: "Field", name: { kind: "Name", value: "hideAdCallout" } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ImageAdCreativeDataFragment, unknown>;
export const AdCardDataFragmentDoc = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "AdCardData" },
      typeCondition: { kind: "NamedType", name: { kind: "Name", value: "Ad" } },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "format" } },
          { kind: "Field", name: { kind: "Name", value: "uuid" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "imageAdCreative" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "FragmentSpread",
                  name: { kind: "Name", value: "ImageAdCreativeData" },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "ImageAdCreativeData" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "core_apimessages_ImageAdCreative" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "title" } },
          { kind: "Field", name: { kind: "Name", value: "titleColor" } },
          { kind: "Field", name: { kind: "Name", value: "subtitle" } },
          { kind: "Field", name: { kind: "Name", value: "subtitleColor" } },
          { kind: "Field", name: { kind: "Name", value: "ctaText" } },
          { kind: "Field", name: { kind: "Name", value: "ctaHref" } },
          { kind: "Field", name: { kind: "Name", value: "ctaTextColor" } },
          { kind: "Field", name: { kind: "Name", value: "ctaButtonColor" } },
          { kind: "Field", name: { kind: "Name", value: "backgroundImage" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "mobileBackgroundImage" },
          },
          { kind: "Field", name: { kind: "Name", value: "backgroundColor" } },
          { kind: "Field", name: { kind: "Name", value: "backgroundColors" } },
          { kind: "Field", name: { kind: "Name", value: "overlayImage" } },
          { kind: "Field", name: { kind: "Name", value: "description" } },
          { kind: "Field", name: { kind: "Name", value: "partnershipText" } },
          { kind: "Field", name: { kind: "Name", value: "hideAdCallout" } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<AdCardDataFragment, unknown>;
export const AdvertiserDataFragmentDoc = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "AdvertiserData" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Advertiser" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "uuid" } },
          { kind: "Field", name: { kind: "Name", value: "name" } },
          { kind: "Field", name: { kind: "Name", value: "brandSlugs" } },
          { kind: "Field", name: { kind: "Name", value: "shopSlugs" } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<AdvertiserDataFragment, unknown>;
export const myShopBillingMethodsFragmentDoc = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "myShopBillingMethods" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "rql_Me" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "uuid" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "shop" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "currency" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "originCountryCode" },
                },
                { kind: "Field", name: { kind: "Name", value: "shopType" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "billingMethodStatus" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "paypalSetupStatus" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "paypalProfile" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "email" } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "creditCards" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "last4" } },
                { kind: "Field", name: { kind: "Name", value: "cardType" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "expirationYear" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "expirationMonth" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "cardholderName" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "primaryBilling" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "address" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "postalCode" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "countryCode" },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<myShopBillingMethodsFragment, unknown>;
export const ListingForBuyerShippingFieldsFragmentDoc = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "ListingForBuyerShippingFields" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Listing" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "shipping" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "freeExpeditedShipping" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "localPickupOnly" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "shippingPrices" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "shippingMethod" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "carrierCalculated" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "regional" },
                      },
                      {
                        kind: "Field",
                        name: {
                          kind: "Name",
                          value: "destinationPostalCodeNeeded",
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "postalCode" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "rate" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "amount" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "amountCents" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "currency" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "display" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ListingForBuyerShippingFieldsFragment, unknown>;
export const ListingGreatValueDataFragmentDoc = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "ListingGreatValueData" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Listing" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "pricing" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "buyerPrice" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "currency" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "amountCents" },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "condition" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "conditionSlug" },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "priceRecommendation" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "priceMiddle" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "amountCents" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "currency" },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "shop" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "address" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "country" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "_id" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "countryCode" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          { kind: "Field", name: { kind: "Name", value: "currency" } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ListingGreatValueDataFragment, unknown>;
export const ListingCardCPODataFragmentDoc = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "ListingCardCPOData" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Listing" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "certifiedPreOwned" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "title" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "badgeIconUrl" },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ListingCardCPODataFragment, unknown>;
export const ListingForBuyerFieldsFragmentDoc = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "ListingForBuyerFields" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Listing" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "title" } },
          { kind: "Field", name: { kind: "Name", value: "slug" } },
          { kind: "Field", name: { kind: "Name", value: "listingType" } },
          { kind: "Field", name: { kind: "Name", value: "make" } },
          { kind: "Field", name: { kind: "Name", value: "model" } },
          { kind: "Field", name: { kind: "Name", value: "upc" } },
          { kind: "Field", name: { kind: "Name", value: "state" } },
          { kind: "Field", name: { kind: "Name", value: "stateDescription" } },
          { kind: "Field", name: { kind: "Name", value: "bumped" } },
          { kind: "Field", name: { kind: "Name", value: "watching" } },
          { kind: "Field", name: { kind: "Name", value: "soldAsIs" } },
          { kind: "Field", name: { kind: "Name", value: "usOutlet" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "publishedAt" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "seconds" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "condition" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "displayName" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "conditionSlug" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "conditionUuid" },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "pricing" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "buyerPrice" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "display" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "currency" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "amount" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "amountCents" },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "originalPrice" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "display" },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "ribbon" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "display" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "reason" },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "images" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "transform" },
                      value: {
                        kind: "StringValue",
                        value: "card_square",
                        block: false,
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "count" },
                      value: { kind: "IntValue", value: "3" },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "scope" },
                      value: {
                        kind: "StringValue",
                        value: "photos",
                        block: false,
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "type" },
                      value: {
                        kind: "StringValue",
                        value: "Product",
                        block: false,
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "source" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "shipping" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "shippingPrices" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "shippingMethod" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "carrierCalculated" },
                      },
                      {
                        kind: "Field",
                        name: {
                          kind: "Name",
                          value: "destinationPostalCodeNeeded",
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "rate" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "amount" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "amountCents" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "currency" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "display" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "freeExpeditedShipping" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "localPickupOnly" },
                },
                { kind: "Field", name: { kind: "Name", value: "localPickup" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "shop" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "name" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "returnPolicy" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "usedReturnWindowDays" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "newReturnWindowDays" },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "address" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "locality" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "region" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "country" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "_id" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "countryCode" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "name" },
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "displayLocation" },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "ListingForBuyerShippingFields" },
          },
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "ListingGreatValueData" },
          },
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "ListingCardCPOData" },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "ListingForBuyerShippingFields" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Listing" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "shipping" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "freeExpeditedShipping" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "localPickupOnly" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "shippingPrices" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "shippingMethod" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "carrierCalculated" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "regional" },
                      },
                      {
                        kind: "Field",
                        name: {
                          kind: "Name",
                          value: "destinationPostalCodeNeeded",
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "postalCode" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "rate" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "amount" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "amountCents" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "currency" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "display" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "ListingGreatValueData" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Listing" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "pricing" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "buyerPrice" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "currency" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "amountCents" },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "condition" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "conditionSlug" },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "priceRecommendation" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "priceMiddle" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "amountCents" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "currency" },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "shop" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "address" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "country" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "_id" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "countryCode" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          { kind: "Field", name: { kind: "Name", value: "currency" } },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "ListingCardCPOData" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Listing" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "certifiedPreOwned" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "title" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "badgeIconUrl" },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ListingForBuyerFieldsFragment, unknown>;
export const WatchBadgeDataFragmentDoc = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "WatchBadgeData" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Listing" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "title" } },
          { kind: "Field", name: { kind: "Name", value: "sellerId" } },
          { kind: "Field", name: { kind: "Name", value: "watching" } },
          {
            kind: "Field",
            alias: { kind: "Name", value: "watchThumbnails" },
            name: { kind: "Name", value: "images" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "type" },
                      value: {
                        kind: "StringValue",
                        value: "Product",
                        block: false,
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "scope" },
                      value: {
                        kind: "StringValue",
                        value: "photos",
                        block: false,
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "transform" },
                      value: {
                        kind: "StringValue",
                        value: "card_square",
                        block: false,
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "count" },
                      value: { kind: "IntValue", value: "1" },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "source" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<WatchBadgeDataFragment, unknown>;
export const mParticleListingFieldsFragmentDoc = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "mParticleListingFields" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Listing" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "title" } },
          { kind: "Field", name: { kind: "Name", value: "brandSlug" } },
          { kind: "Field", name: { kind: "Name", value: "categoryRootUuid" } },
          { kind: "Field", name: { kind: "Name", value: "make" } },
          { kind: "Field", name: { kind: "Name", value: "categoryUuids" } },
          { kind: "Field", name: { kind: "Name", value: "state" } },
          { kind: "Field", name: { kind: "Name", value: "listingType" } },
          { kind: "Field", name: { kind: "Name", value: "bumpEligible" } },
          { kind: "Field", name: { kind: "Name", value: "shopId" } },
          { kind: "Field", name: { kind: "Name", value: "inventory" } },
          { kind: "Field", name: { kind: "Name", value: "soldAsIs" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "acceptedPaymentMethods" },
          },
          { kind: "Field", name: { kind: "Name", value: "currency" } },
          { kind: "Field", name: { kind: "Name", value: "usOutlet" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "condition" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "conditionUuid" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "conditionSlug" },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "categories" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "slug" } },
                { kind: "Field", name: { kind: "Name", value: "rootSlug" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "csp" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "slug" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "brand" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      { kind: "Field", name: { kind: "Name", value: "slug" } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "pricing" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "buyerPrice" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "amount" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "currency" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "amountCents" },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "publishedAt" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "seconds" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "sale" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "code" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "buyerIneligibilityReason" },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "shipping" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "shippingPrices" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "shippingMethod" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "carrierCalculated" },
                      },
                      {
                        kind: "Field",
                        name: {
                          kind: "Name",
                          value: "destinationPostalCodeNeeded",
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "rate" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "amount" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "amountCents" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "currency" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "display" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "freeExpeditedShipping" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "localPickupOnly" },
                },
                { kind: "Field", name: { kind: "Name", value: "localPickup" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "certifiedPreOwned" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "title" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "badgeIconUrl" },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "shop" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "address" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "countryCode" },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "returnPolicy" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "newReturnWindowDays" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "usedReturnWindowDays" },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<mParticleListingFieldsFragment, unknown>;
export const ListingCreateOfferButtonDataFragmentDoc = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "ListingCreateOfferButtonData" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Listing" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "state" } },
          { kind: "Field", name: { kind: "Name", value: "offersEnabled" } },
          { kind: "Field", name: { kind: "Name", value: "listingType" } },
          { kind: "Field", name: { kind: "Name", value: "sellerId" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "isBuyerOfferEligible" },
          },
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "mParticleListingFields" },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "mParticleListingFields" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Listing" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "title" } },
          { kind: "Field", name: { kind: "Name", value: "brandSlug" } },
          { kind: "Field", name: { kind: "Name", value: "categoryRootUuid" } },
          { kind: "Field", name: { kind: "Name", value: "make" } },
          { kind: "Field", name: { kind: "Name", value: "categoryUuids" } },
          { kind: "Field", name: { kind: "Name", value: "state" } },
          { kind: "Field", name: { kind: "Name", value: "listingType" } },
          { kind: "Field", name: { kind: "Name", value: "bumpEligible" } },
          { kind: "Field", name: { kind: "Name", value: "shopId" } },
          { kind: "Field", name: { kind: "Name", value: "inventory" } },
          { kind: "Field", name: { kind: "Name", value: "soldAsIs" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "acceptedPaymentMethods" },
          },
          { kind: "Field", name: { kind: "Name", value: "currency" } },
          { kind: "Field", name: { kind: "Name", value: "usOutlet" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "condition" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "conditionUuid" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "conditionSlug" },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "categories" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "slug" } },
                { kind: "Field", name: { kind: "Name", value: "rootSlug" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "csp" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "slug" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "brand" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      { kind: "Field", name: { kind: "Name", value: "slug" } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "pricing" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "buyerPrice" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "amount" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "currency" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "amountCents" },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "publishedAt" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "seconds" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "sale" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "code" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "buyerIneligibilityReason" },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "shipping" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "shippingPrices" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "shippingMethod" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "carrierCalculated" },
                      },
                      {
                        kind: "Field",
                        name: {
                          kind: "Name",
                          value: "destinationPostalCodeNeeded",
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "rate" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "amount" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "amountCents" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "currency" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "display" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "freeExpeditedShipping" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "localPickupOnly" },
                },
                { kind: "Field", name: { kind: "Name", value: "localPickup" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "certifiedPreOwned" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "title" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "badgeIconUrl" },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "shop" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "address" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "countryCode" },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "returnPolicy" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "newReturnWindowDays" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "usedReturnWindowDays" },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ListingCreateOfferButtonDataFragment, unknown>;
export const ListingCardFieldsFragmentDoc = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "ListingCardFields" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Listing" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "ListingForBuyerFields" },
          },
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "WatchBadgeData" },
          },
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "ListingCreateOfferButtonData" },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "ListingForBuyerShippingFields" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Listing" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "shipping" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "freeExpeditedShipping" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "localPickupOnly" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "shippingPrices" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "shippingMethod" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "carrierCalculated" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "regional" },
                      },
                      {
                        kind: "Field",
                        name: {
                          kind: "Name",
                          value: "destinationPostalCodeNeeded",
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "postalCode" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "rate" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "amount" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "amountCents" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "currency" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "display" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "ListingGreatValueData" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Listing" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "pricing" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "buyerPrice" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "currency" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "amountCents" },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "condition" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "conditionSlug" },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "priceRecommendation" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "priceMiddle" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "amountCents" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "currency" },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "shop" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "address" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "country" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "_id" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "countryCode" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          { kind: "Field", name: { kind: "Name", value: "currency" } },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "ListingCardCPOData" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Listing" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "certifiedPreOwned" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "title" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "badgeIconUrl" },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "mParticleListingFields" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Listing" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "title" } },
          { kind: "Field", name: { kind: "Name", value: "brandSlug" } },
          { kind: "Field", name: { kind: "Name", value: "categoryRootUuid" } },
          { kind: "Field", name: { kind: "Name", value: "make" } },
          { kind: "Field", name: { kind: "Name", value: "categoryUuids" } },
          { kind: "Field", name: { kind: "Name", value: "state" } },
          { kind: "Field", name: { kind: "Name", value: "listingType" } },
          { kind: "Field", name: { kind: "Name", value: "bumpEligible" } },
          { kind: "Field", name: { kind: "Name", value: "shopId" } },
          { kind: "Field", name: { kind: "Name", value: "inventory" } },
          { kind: "Field", name: { kind: "Name", value: "soldAsIs" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "acceptedPaymentMethods" },
          },
          { kind: "Field", name: { kind: "Name", value: "currency" } },
          { kind: "Field", name: { kind: "Name", value: "usOutlet" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "condition" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "conditionUuid" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "conditionSlug" },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "categories" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "slug" } },
                { kind: "Field", name: { kind: "Name", value: "rootSlug" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "csp" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "slug" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "brand" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      { kind: "Field", name: { kind: "Name", value: "slug" } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "pricing" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "buyerPrice" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "amount" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "currency" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "amountCents" },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "publishedAt" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "seconds" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "sale" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "code" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "buyerIneligibilityReason" },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "shipping" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "shippingPrices" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "shippingMethod" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "carrierCalculated" },
                      },
                      {
                        kind: "Field",
                        name: {
                          kind: "Name",
                          value: "destinationPostalCodeNeeded",
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "rate" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "amount" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "amountCents" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "currency" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "display" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "freeExpeditedShipping" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "localPickupOnly" },
                },
                { kind: "Field", name: { kind: "Name", value: "localPickup" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "certifiedPreOwned" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "title" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "badgeIconUrl" },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "shop" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "address" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "countryCode" },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "returnPolicy" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "newReturnWindowDays" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "usedReturnWindowDays" },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "ListingForBuyerFields" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Listing" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "title" } },
          { kind: "Field", name: { kind: "Name", value: "slug" } },
          { kind: "Field", name: { kind: "Name", value: "listingType" } },
          { kind: "Field", name: { kind: "Name", value: "make" } },
          { kind: "Field", name: { kind: "Name", value: "model" } },
          { kind: "Field", name: { kind: "Name", value: "upc" } },
          { kind: "Field", name: { kind: "Name", value: "state" } },
          { kind: "Field", name: { kind: "Name", value: "stateDescription" } },
          { kind: "Field", name: { kind: "Name", value: "bumped" } },
          { kind: "Field", name: { kind: "Name", value: "watching" } },
          { kind: "Field", name: { kind: "Name", value: "soldAsIs" } },
          { kind: "Field", name: { kind: "Name", value: "usOutlet" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "publishedAt" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "seconds" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "condition" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "displayName" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "conditionSlug" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "conditionUuid" },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "pricing" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "buyerPrice" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "display" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "currency" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "amount" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "amountCents" },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "originalPrice" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "display" },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "ribbon" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "display" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "reason" },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "images" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "transform" },
                      value: {
                        kind: "StringValue",
                        value: "card_square",
                        block: false,
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "count" },
                      value: { kind: "IntValue", value: "3" },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "scope" },
                      value: {
                        kind: "StringValue",
                        value: "photos",
                        block: false,
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "type" },
                      value: {
                        kind: "StringValue",
                        value: "Product",
                        block: false,
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "source" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "shipping" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "shippingPrices" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "shippingMethod" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "carrierCalculated" },
                      },
                      {
                        kind: "Field",
                        name: {
                          kind: "Name",
                          value: "destinationPostalCodeNeeded",
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "rate" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "amount" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "amountCents" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "currency" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "display" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "freeExpeditedShipping" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "localPickupOnly" },
                },
                { kind: "Field", name: { kind: "Name", value: "localPickup" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "shop" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "name" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "returnPolicy" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "usedReturnWindowDays" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "newReturnWindowDays" },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "address" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "locality" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "region" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "country" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "_id" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "countryCode" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "name" },
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "displayLocation" },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "ListingForBuyerShippingFields" },
          },
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "ListingGreatValueData" },
          },
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "ListingCardCPOData" },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "WatchBadgeData" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Listing" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "title" } },
          { kind: "Field", name: { kind: "Name", value: "sellerId" } },
          { kind: "Field", name: { kind: "Name", value: "watching" } },
          {
            kind: "Field",
            alias: { kind: "Name", value: "watchThumbnails" },
            name: { kind: "Name", value: "images" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "type" },
                      value: {
                        kind: "StringValue",
                        value: "Product",
                        block: false,
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "scope" },
                      value: {
                        kind: "StringValue",
                        value: "photos",
                        block: false,
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "transform" },
                      value: {
                        kind: "StringValue",
                        value: "card_square",
                        block: false,
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "count" },
                      value: { kind: "IntValue", value: "1" },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "source" } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "ListingCreateOfferButtonData" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Listing" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "state" } },
          { kind: "Field", name: { kind: "Name", value: "offersEnabled" } },
          { kind: "Field", name: { kind: "Name", value: "listingType" } },
          { kind: "Field", name: { kind: "Name", value: "sellerId" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "isBuyerOfferEligible" },
          },
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "mParticleListingFields" },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ListingCardFieldsFragment, unknown>;
export const AddToCartButtonFieldsFragmentDoc = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "AddToCartButtonFields" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Listing" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "sellerId" } },
          { kind: "Field", name: { kind: "Name", value: "listingType" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "pricing" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "buyerPrice" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "amount" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "amountCents" },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "preorderInfo" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "onPreorder" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "estimatedShipDate" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "seconds" },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<AddToCartButtonFieldsFragment, unknown>;
export const BrowseListingsFieldsFragmentDoc = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "BrowseListingsFields" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Listing" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "csp" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "averageReviewRating" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "reviewsCount" },
                },
              ],
            },
          },
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "ListingCardFields" },
          },
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "AddToCartButtonFields" },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "ListingForBuyerShippingFields" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Listing" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "shipping" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "freeExpeditedShipping" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "localPickupOnly" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "shippingPrices" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "shippingMethod" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "carrierCalculated" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "regional" },
                      },
                      {
                        kind: "Field",
                        name: {
                          kind: "Name",
                          value: "destinationPostalCodeNeeded",
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "postalCode" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "rate" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "amount" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "amountCents" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "currency" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "display" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "ListingGreatValueData" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Listing" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "pricing" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "buyerPrice" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "currency" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "amountCents" },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "condition" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "conditionSlug" },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "priceRecommendation" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "priceMiddle" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "amountCents" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "currency" },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "shop" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "address" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "country" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "_id" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "countryCode" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          { kind: "Field", name: { kind: "Name", value: "currency" } },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "ListingCardCPOData" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Listing" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "certifiedPreOwned" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "title" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "badgeIconUrl" },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "ListingForBuyerFields" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Listing" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "title" } },
          { kind: "Field", name: { kind: "Name", value: "slug" } },
          { kind: "Field", name: { kind: "Name", value: "listingType" } },
          { kind: "Field", name: { kind: "Name", value: "make" } },
          { kind: "Field", name: { kind: "Name", value: "model" } },
          { kind: "Field", name: { kind: "Name", value: "upc" } },
          { kind: "Field", name: { kind: "Name", value: "state" } },
          { kind: "Field", name: { kind: "Name", value: "stateDescription" } },
          { kind: "Field", name: { kind: "Name", value: "bumped" } },
          { kind: "Field", name: { kind: "Name", value: "watching" } },
          { kind: "Field", name: { kind: "Name", value: "soldAsIs" } },
          { kind: "Field", name: { kind: "Name", value: "usOutlet" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "publishedAt" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "seconds" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "condition" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "displayName" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "conditionSlug" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "conditionUuid" },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "pricing" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "buyerPrice" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "display" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "currency" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "amount" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "amountCents" },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "originalPrice" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "display" },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "ribbon" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "display" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "reason" },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "images" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "transform" },
                      value: {
                        kind: "StringValue",
                        value: "card_square",
                        block: false,
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "count" },
                      value: { kind: "IntValue", value: "3" },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "scope" },
                      value: {
                        kind: "StringValue",
                        value: "photos",
                        block: false,
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "type" },
                      value: {
                        kind: "StringValue",
                        value: "Product",
                        block: false,
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "source" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "shipping" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "shippingPrices" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "shippingMethod" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "carrierCalculated" },
                      },
                      {
                        kind: "Field",
                        name: {
                          kind: "Name",
                          value: "destinationPostalCodeNeeded",
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "rate" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "amount" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "amountCents" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "currency" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "display" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "freeExpeditedShipping" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "localPickupOnly" },
                },
                { kind: "Field", name: { kind: "Name", value: "localPickup" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "shop" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "name" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "returnPolicy" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "usedReturnWindowDays" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "newReturnWindowDays" },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "address" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "locality" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "region" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "country" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "_id" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "countryCode" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "name" },
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "displayLocation" },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "ListingForBuyerShippingFields" },
          },
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "ListingGreatValueData" },
          },
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "ListingCardCPOData" },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "WatchBadgeData" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Listing" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "title" } },
          { kind: "Field", name: { kind: "Name", value: "sellerId" } },
          { kind: "Field", name: { kind: "Name", value: "watching" } },
          {
            kind: "Field",
            alias: { kind: "Name", value: "watchThumbnails" },
            name: { kind: "Name", value: "images" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "type" },
                      value: {
                        kind: "StringValue",
                        value: "Product",
                        block: false,
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "scope" },
                      value: {
                        kind: "StringValue",
                        value: "photos",
                        block: false,
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "transform" },
                      value: {
                        kind: "StringValue",
                        value: "card_square",
                        block: false,
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "count" },
                      value: { kind: "IntValue", value: "1" },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "source" } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "mParticleListingFields" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Listing" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "title" } },
          { kind: "Field", name: { kind: "Name", value: "brandSlug" } },
          { kind: "Field", name: { kind: "Name", value: "categoryRootUuid" } },
          { kind: "Field", name: { kind: "Name", value: "make" } },
          { kind: "Field", name: { kind: "Name", value: "categoryUuids" } },
          { kind: "Field", name: { kind: "Name", value: "state" } },
          { kind: "Field", name: { kind: "Name", value: "listingType" } },
          { kind: "Field", name: { kind: "Name", value: "bumpEligible" } },
          { kind: "Field", name: { kind: "Name", value: "shopId" } },
          { kind: "Field", name: { kind: "Name", value: "inventory" } },
          { kind: "Field", name: { kind: "Name", value: "soldAsIs" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "acceptedPaymentMethods" },
          },
          { kind: "Field", name: { kind: "Name", value: "currency" } },
          { kind: "Field", name: { kind: "Name", value: "usOutlet" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "condition" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "conditionUuid" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "conditionSlug" },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "categories" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "slug" } },
                { kind: "Field", name: { kind: "Name", value: "rootSlug" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "csp" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "slug" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "brand" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      { kind: "Field", name: { kind: "Name", value: "slug" } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "pricing" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "buyerPrice" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "amount" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "currency" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "amountCents" },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "publishedAt" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "seconds" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "sale" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "code" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "buyerIneligibilityReason" },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "shipping" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "shippingPrices" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "shippingMethod" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "carrierCalculated" },
                      },
                      {
                        kind: "Field",
                        name: {
                          kind: "Name",
                          value: "destinationPostalCodeNeeded",
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "rate" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "amount" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "amountCents" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "currency" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "display" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "freeExpeditedShipping" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "localPickupOnly" },
                },
                { kind: "Field", name: { kind: "Name", value: "localPickup" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "certifiedPreOwned" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "title" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "badgeIconUrl" },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "shop" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "address" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "countryCode" },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "returnPolicy" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "newReturnWindowDays" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "usedReturnWindowDays" },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "ListingCreateOfferButtonData" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Listing" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "state" } },
          { kind: "Field", name: { kind: "Name", value: "offersEnabled" } },
          { kind: "Field", name: { kind: "Name", value: "listingType" } },
          { kind: "Field", name: { kind: "Name", value: "sellerId" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "isBuyerOfferEligible" },
          },
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "mParticleListingFields" },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "ListingCardFields" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Listing" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "ListingForBuyerFields" },
          },
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "WatchBadgeData" },
          },
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "ListingCreateOfferButtonData" },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "AddToCartButtonFields" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Listing" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "sellerId" } },
          { kind: "Field", name: { kind: "Name", value: "listingType" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "pricing" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "buyerPrice" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "amount" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "amountCents" },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "preorderInfo" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "onPreorder" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "estimatedShipDate" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "seconds" },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<BrowseListingsFieldsFragment, unknown>;
export const BumpRecommendationsAttributesFragmentDoc = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "BumpRecommendationsAttributes" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Listing" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "condition" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "conditionUuid" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "conditionSlug" },
                },
                { kind: "Field", name: { kind: "Name", value: "displayName" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "csp" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "title" } },
                { kind: "Field", name: { kind: "Name", value: "slug" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<BumpRecommendationsAttributesFragment, unknown>;
export const CoreBumpRecommendationListingCardFragmentDoc = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "CoreBumpRecommendationListingCard" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Listing" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "images" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "type" },
                      value: {
                        kind: "StringValue",
                        value: "square",
                        block: false,
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "transform" },
                      value: {
                        kind: "StringValue",
                        value: "card_square",
                        block: false,
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "count" },
                      value: { kind: "IntValue", value: "1" },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "source" } },
              ],
            },
          },
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "hasInventory" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "sellerPrice" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "amount" } },
                { kind: "Field", name: { kind: "Name", value: "amountCents" } },
                { kind: "Field", name: { kind: "Name", value: "currency" } },
                { kind: "Field", name: { kind: "Name", value: "display" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "shipping" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "shippingPrices" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "shippingRegionCode" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "originalRate" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "amount" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "amountCents" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "currency" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "display" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "mParticleListingFields" },
          },
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "BumpRecommendationsAttributes" },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "mParticleListingFields" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Listing" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "title" } },
          { kind: "Field", name: { kind: "Name", value: "brandSlug" } },
          { kind: "Field", name: { kind: "Name", value: "categoryRootUuid" } },
          { kind: "Field", name: { kind: "Name", value: "make" } },
          { kind: "Field", name: { kind: "Name", value: "categoryUuids" } },
          { kind: "Field", name: { kind: "Name", value: "state" } },
          { kind: "Field", name: { kind: "Name", value: "listingType" } },
          { kind: "Field", name: { kind: "Name", value: "bumpEligible" } },
          { kind: "Field", name: { kind: "Name", value: "shopId" } },
          { kind: "Field", name: { kind: "Name", value: "inventory" } },
          { kind: "Field", name: { kind: "Name", value: "soldAsIs" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "acceptedPaymentMethods" },
          },
          { kind: "Field", name: { kind: "Name", value: "currency" } },
          { kind: "Field", name: { kind: "Name", value: "usOutlet" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "condition" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "conditionUuid" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "conditionSlug" },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "categories" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "slug" } },
                { kind: "Field", name: { kind: "Name", value: "rootSlug" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "csp" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "slug" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "brand" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      { kind: "Field", name: { kind: "Name", value: "slug" } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "pricing" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "buyerPrice" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "amount" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "currency" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "amountCents" },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "publishedAt" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "seconds" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "sale" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "code" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "buyerIneligibilityReason" },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "shipping" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "shippingPrices" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "shippingMethod" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "carrierCalculated" },
                      },
                      {
                        kind: "Field",
                        name: {
                          kind: "Name",
                          value: "destinationPostalCodeNeeded",
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "rate" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "amount" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "amountCents" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "currency" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "display" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "freeExpeditedShipping" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "localPickupOnly" },
                },
                { kind: "Field", name: { kind: "Name", value: "localPickup" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "certifiedPreOwned" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "title" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "badgeIconUrl" },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "shop" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "address" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "countryCode" },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "returnPolicy" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "newReturnWindowDays" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "usedReturnWindowDays" },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "BumpRecommendationsAttributes" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Listing" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "condition" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "conditionUuid" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "conditionSlug" },
                },
                { kind: "Field", name: { kind: "Name", value: "displayName" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "csp" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "title" } },
                { kind: "Field", name: { kind: "Name", value: "slug" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CoreBumpRecommendationListingCardFragment,
  unknown
>;
export const CartGooglePayButtonFieldsFragmentDoc = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "CartGooglePayButtonFields" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "rql_Me" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "cart" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "bundles" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "checkoutBundlingId" },
                      },
                      {
                        kind: "Field",
                        name: {
                          kind: "Name",
                          value: "availablePaymentMethods",
                        },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "type" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "googlePay" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "merchantId" },
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "merchantName",
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "gatewayMerchantId",
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "cardNetworks",
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "total" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "amount" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "amount" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "currency" },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CartGooglePayButtonFieldsFragment, unknown>;
export const CartItemAvailableProtectionPlansFragmentDoc = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "CartItemAvailableProtectionPlans" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "rql_Me" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "uuid" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "cart" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "bundles" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "cartItems" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "_id" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "uuid" },
                            },
                            {
                              kind: "Field",
                              name: {
                                kind: "Name",
                                value: "protectionPlanOptions",
                              },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "availablePlans",
                                    },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "planId",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "durationMonths",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "amount",
                                          },
                                          selectionSet: {
                                            kind: "SelectionSet",
                                            selections: [
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "display",
                                                },
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "amount",
                                                },
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CartItemAvailableProtectionPlansFragment, unknown>;
export const CartItemRowPricingFieldsFragmentDoc = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "CartItemRowPricingFields" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "core_apimessages_CartItem" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "listingTotal" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "discounted" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "discount" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "type" } },
                      { kind: "Field", name: { kind: "Name", value: "label" } },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "unitPrice" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "display" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "currency" },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "originalUnitPrice" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "display" },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "shippingTotal" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "discounted" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "discount" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "type" } },
                      { kind: "Field", name: { kind: "Name", value: "label" } },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "amountOwed" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "display" },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "originalAmountOwed" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "display" },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "listing" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "listingType" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "shipping" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "localPickupOnly" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "shippingPrices" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "_id" },
                            },
                            {
                              kind: "Field",
                              name: {
                                kind: "Name",
                                value: "carrierCalculated",
                              },
                            },
                            {
                              kind: "Field",
                              name: {
                                kind: "Name",
                                value: "destinationPostalCodeNeeded",
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "regional" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "shippingMethod" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "order" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "orderType" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CartItemRowPricingFieldsFragment, unknown>;
export const CartItemBuyNowButtonFieldsFragmentDoc = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "CartItemBuyNowButtonFields" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "core_apimessages_CartItem" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "order" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "legacyOrderId" },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "paymentMethods" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "type" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CartItemBuyNowButtonFieldsFragment, unknown>;
export const CartItemQuantitySelectFieldsFragmentDoc = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "CartItemQuantitySelectFields" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "rql_Me" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "uuid" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "cart" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "bundles" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "cartItems" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "_id" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "uuid" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "quantity" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "listing" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "_id" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "allowedBuyerQuantity",
                                    },
                                  },
                                  {
                                    kind: "FragmentSpread",
                                    name: {
                                      kind: "Name",
                                      value: "mParticleListingFields",
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "mParticleListingFields" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Listing" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "title" } },
          { kind: "Field", name: { kind: "Name", value: "brandSlug" } },
          { kind: "Field", name: { kind: "Name", value: "categoryRootUuid" } },
          { kind: "Field", name: { kind: "Name", value: "make" } },
          { kind: "Field", name: { kind: "Name", value: "categoryUuids" } },
          { kind: "Field", name: { kind: "Name", value: "state" } },
          { kind: "Field", name: { kind: "Name", value: "listingType" } },
          { kind: "Field", name: { kind: "Name", value: "bumpEligible" } },
          { kind: "Field", name: { kind: "Name", value: "shopId" } },
          { kind: "Field", name: { kind: "Name", value: "inventory" } },
          { kind: "Field", name: { kind: "Name", value: "soldAsIs" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "acceptedPaymentMethods" },
          },
          { kind: "Field", name: { kind: "Name", value: "currency" } },
          { kind: "Field", name: { kind: "Name", value: "usOutlet" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "condition" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "conditionUuid" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "conditionSlug" },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "categories" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "slug" } },
                { kind: "Field", name: { kind: "Name", value: "rootSlug" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "csp" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "slug" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "brand" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      { kind: "Field", name: { kind: "Name", value: "slug" } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "pricing" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "buyerPrice" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "amount" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "currency" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "amountCents" },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "publishedAt" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "seconds" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "sale" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "code" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "buyerIneligibilityReason" },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "shipping" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "shippingPrices" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "shippingMethod" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "carrierCalculated" },
                      },
                      {
                        kind: "Field",
                        name: {
                          kind: "Name",
                          value: "destinationPostalCodeNeeded",
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "rate" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "amount" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "amountCents" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "currency" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "display" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "freeExpeditedShipping" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "localPickupOnly" },
                },
                { kind: "Field", name: { kind: "Name", value: "localPickup" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "certifiedPreOwned" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "title" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "badgeIconUrl" },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "shop" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "address" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "countryCode" },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "returnPolicy" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "newReturnWindowDays" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "usedReturnWindowDays" },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CartItemQuantitySelectFieldsFragment, unknown>;
export const CartRemoveItemFieldsFragmentDoc = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "CartRemoveItemFields" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "rql_Me" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "uuid" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "cart" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "bundles" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "cartItems" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "_id" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "uuid" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "listing" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "_id" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                  },
                                  {
                                    kind: "FragmentSpread",
                                    name: {
                                      kind: "Name",
                                      value: "mParticleListingFields",
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "mParticleListingFields" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Listing" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "title" } },
          { kind: "Field", name: { kind: "Name", value: "brandSlug" } },
          { kind: "Field", name: { kind: "Name", value: "categoryRootUuid" } },
          { kind: "Field", name: { kind: "Name", value: "make" } },
          { kind: "Field", name: { kind: "Name", value: "categoryUuids" } },
          { kind: "Field", name: { kind: "Name", value: "state" } },
          { kind: "Field", name: { kind: "Name", value: "listingType" } },
          { kind: "Field", name: { kind: "Name", value: "bumpEligible" } },
          { kind: "Field", name: { kind: "Name", value: "shopId" } },
          { kind: "Field", name: { kind: "Name", value: "inventory" } },
          { kind: "Field", name: { kind: "Name", value: "soldAsIs" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "acceptedPaymentMethods" },
          },
          { kind: "Field", name: { kind: "Name", value: "currency" } },
          { kind: "Field", name: { kind: "Name", value: "usOutlet" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "condition" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "conditionUuid" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "conditionSlug" },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "categories" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "slug" } },
                { kind: "Field", name: { kind: "Name", value: "rootSlug" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "csp" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "slug" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "brand" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      { kind: "Field", name: { kind: "Name", value: "slug" } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "pricing" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "buyerPrice" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "amount" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "currency" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "amountCents" },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "publishedAt" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "seconds" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "sale" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "code" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "buyerIneligibilityReason" },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "shipping" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "shippingPrices" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "shippingMethod" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "carrierCalculated" },
                      },
                      {
                        kind: "Field",
                        name: {
                          kind: "Name",
                          value: "destinationPostalCodeNeeded",
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "rate" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "amount" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "amountCents" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "currency" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "display" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "freeExpeditedShipping" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "localPickupOnly" },
                },
                { kind: "Field", name: { kind: "Name", value: "localPickup" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "certifiedPreOwned" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "title" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "badgeIconUrl" },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "shop" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "address" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "countryCode" },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "returnPolicy" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "newReturnWindowDays" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "usedReturnWindowDays" },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CartRemoveItemFieldsFragment, unknown>;
export const CartMoveItemToWatchListFieldsFragmentDoc = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "CartMoveItemToWatchListFields" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "rql_Me" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "uuid" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "cart" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "bundles" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "cartItems" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "_id" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "uuid" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "listing" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "_id" },
                                  },
                                  {
                                    kind: "FragmentSpread",
                                    name: {
                                      kind: "Name",
                                      value: "mParticleListingFields",
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "mParticleListingFields" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Listing" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "title" } },
          { kind: "Field", name: { kind: "Name", value: "brandSlug" } },
          { kind: "Field", name: { kind: "Name", value: "categoryRootUuid" } },
          { kind: "Field", name: { kind: "Name", value: "make" } },
          { kind: "Field", name: { kind: "Name", value: "categoryUuids" } },
          { kind: "Field", name: { kind: "Name", value: "state" } },
          { kind: "Field", name: { kind: "Name", value: "listingType" } },
          { kind: "Field", name: { kind: "Name", value: "bumpEligible" } },
          { kind: "Field", name: { kind: "Name", value: "shopId" } },
          { kind: "Field", name: { kind: "Name", value: "inventory" } },
          { kind: "Field", name: { kind: "Name", value: "soldAsIs" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "acceptedPaymentMethods" },
          },
          { kind: "Field", name: { kind: "Name", value: "currency" } },
          { kind: "Field", name: { kind: "Name", value: "usOutlet" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "condition" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "conditionUuid" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "conditionSlug" },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "categories" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "slug" } },
                { kind: "Field", name: { kind: "Name", value: "rootSlug" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "csp" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "slug" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "brand" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      { kind: "Field", name: { kind: "Name", value: "slug" } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "pricing" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "buyerPrice" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "amount" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "currency" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "amountCents" },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "publishedAt" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "seconds" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "sale" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "code" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "buyerIneligibilityReason" },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "shipping" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "shippingPrices" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "shippingMethod" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "carrierCalculated" },
                      },
                      {
                        kind: "Field",
                        name: {
                          kind: "Name",
                          value: "destinationPostalCodeNeeded",
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "rate" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "amount" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "amountCents" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "currency" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "display" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "freeExpeditedShipping" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "localPickupOnly" },
                },
                { kind: "Field", name: { kind: "Name", value: "localPickup" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "certifiedPreOwned" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "title" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "badgeIconUrl" },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "shop" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "address" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "countryCode" },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "returnPolicy" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "newReturnWindowDays" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "usedReturnWindowDays" },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CartMoveItemToWatchListFieldsFragment, unknown>;
export const CartItemRowProtectionPlanFieldsFragmentDoc = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "CartItemRowProtectionPlanFields" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "rql_Me" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "uuid" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "cart" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "bundles" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "cartItems" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "_id" },
                            },
                            {
                              kind: "Field",
                              name: {
                                kind: "Name",
                                value: "protectionPlanEligible",
                              },
                            },
                            {
                              kind: "Field",
                              alias: {
                                kind: "Name",
                                value: "cartItemProtectionPlan",
                              },
                              name: { kind: "Name", value: "protectionPlan" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "planId" },
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "durationMonths",
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "amount" },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "display",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "amount",
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "listing" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "_id" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "slug" },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CartItemRowProtectionPlanFieldsFragment, unknown>;
export const CartItemRowFieldsFragmentDoc = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "CartItemRowFields" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "rql_Me" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "uuid" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "cart" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "bundles" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "cartItems" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "_id" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "uuid" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "actions" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "canChangeQuantity",
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "canRemoveFromCart",
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "canMoveToWatchlist",
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "alerts" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "type" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "text" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "severity" },
                                  },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "listing" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "_id" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value:
                                        "otherBuyersWithListingInCartCounts",
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "title" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "images" },
                                    arguments: [
                                      {
                                        kind: "Argument",
                                        name: { kind: "Name", value: "input" },
                                        value: {
                                          kind: "ObjectValue",
                                          fields: [
                                            {
                                              kind: "ObjectField",
                                              name: {
                                                kind: "Name",
                                                value: "transform",
                                              },
                                              value: {
                                                kind: "StringValue",
                                                value: "card_square",
                                                block: false,
                                              },
                                            },
                                            {
                                              kind: "ObjectField",
                                              name: {
                                                kind: "Name",
                                                value: "count",
                                              },
                                              value: {
                                                kind: "IntValue",
                                                value: "1",
                                              },
                                            },
                                            {
                                              kind: "ObjectField",
                                              name: {
                                                kind: "Name",
                                                value: "scope",
                                              },
                                              value: {
                                                kind: "StringValue",
                                                value: "photos",
                                                block: false,
                                              },
                                            },
                                            {
                                              kind: "ObjectField",
                                              name: {
                                                kind: "Name",
                                                value: "type",
                                              },
                                              value: {
                                                kind: "StringValue",
                                                value: "Product",
                                                block: false,
                                              },
                                            },
                                          ],
                                        },
                                      },
                                    ],
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "source",
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "shop" },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "_id" },
                                        },
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "slug" },
                                        },
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "name" },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "address",
                                          },
                                          selectionSet: {
                                            kind: "SelectionSet",
                                            selections: [
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "_id",
                                                },
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "displayLocation",
                                                },
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "preorderInfo",
                                    },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "onPreorder",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "estimatedShipDate",
                                          },
                                          selectionSet: {
                                            kind: "SelectionSet",
                                            selections: [
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "seconds",
                                                },
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "shipping" },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "freeExpeditedShipping",
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "order" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "_id" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "orderType" },
                                  },
                                ],
                              },
                            },
                            {
                              kind: "FragmentSpread",
                              name: {
                                kind: "Name",
                                value: "CartItemRowPricingFields",
                              },
                            },
                            {
                              kind: "FragmentSpread",
                              name: {
                                kind: "Name",
                                value: "CartItemBuyNowButtonFields",
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "CartItemQuantitySelectFields" },
          },
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "CartRemoveItemFields" },
          },
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "CartMoveItemToWatchListFields" },
          },
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "CartItemRowProtectionPlanFields" },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "mParticleListingFields" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Listing" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "title" } },
          { kind: "Field", name: { kind: "Name", value: "brandSlug" } },
          { kind: "Field", name: { kind: "Name", value: "categoryRootUuid" } },
          { kind: "Field", name: { kind: "Name", value: "make" } },
          { kind: "Field", name: { kind: "Name", value: "categoryUuids" } },
          { kind: "Field", name: { kind: "Name", value: "state" } },
          { kind: "Field", name: { kind: "Name", value: "listingType" } },
          { kind: "Field", name: { kind: "Name", value: "bumpEligible" } },
          { kind: "Field", name: { kind: "Name", value: "shopId" } },
          { kind: "Field", name: { kind: "Name", value: "inventory" } },
          { kind: "Field", name: { kind: "Name", value: "soldAsIs" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "acceptedPaymentMethods" },
          },
          { kind: "Field", name: { kind: "Name", value: "currency" } },
          { kind: "Field", name: { kind: "Name", value: "usOutlet" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "condition" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "conditionUuid" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "conditionSlug" },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "categories" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "slug" } },
                { kind: "Field", name: { kind: "Name", value: "rootSlug" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "csp" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "slug" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "brand" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      { kind: "Field", name: { kind: "Name", value: "slug" } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "pricing" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "buyerPrice" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "amount" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "currency" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "amountCents" },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "publishedAt" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "seconds" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "sale" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "code" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "buyerIneligibilityReason" },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "shipping" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "shippingPrices" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "shippingMethod" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "carrierCalculated" },
                      },
                      {
                        kind: "Field",
                        name: {
                          kind: "Name",
                          value: "destinationPostalCodeNeeded",
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "rate" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "amount" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "amountCents" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "currency" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "display" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "freeExpeditedShipping" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "localPickupOnly" },
                },
                { kind: "Field", name: { kind: "Name", value: "localPickup" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "certifiedPreOwned" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "title" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "badgeIconUrl" },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "shop" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "address" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "countryCode" },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "returnPolicy" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "newReturnWindowDays" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "usedReturnWindowDays" },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "CartItemRowPricingFields" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "core_apimessages_CartItem" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "listingTotal" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "discounted" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "discount" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "type" } },
                      { kind: "Field", name: { kind: "Name", value: "label" } },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "unitPrice" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "display" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "currency" },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "originalUnitPrice" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "display" },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "shippingTotal" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "discounted" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "discount" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "type" } },
                      { kind: "Field", name: { kind: "Name", value: "label" } },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "amountOwed" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "display" },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "originalAmountOwed" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "display" },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "listing" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "listingType" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "shipping" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "localPickupOnly" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "shippingPrices" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "_id" },
                            },
                            {
                              kind: "Field",
                              name: {
                                kind: "Name",
                                value: "carrierCalculated",
                              },
                            },
                            {
                              kind: "Field",
                              name: {
                                kind: "Name",
                                value: "destinationPostalCodeNeeded",
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "regional" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "shippingMethod" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "order" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "orderType" } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "CartItemBuyNowButtonFields" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "core_apimessages_CartItem" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "order" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "legacyOrderId" },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "paymentMethods" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "type" } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "CartItemQuantitySelectFields" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "rql_Me" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "uuid" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "cart" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "bundles" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "cartItems" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "_id" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "uuid" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "quantity" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "listing" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "_id" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "allowedBuyerQuantity",
                                    },
                                  },
                                  {
                                    kind: "FragmentSpread",
                                    name: {
                                      kind: "Name",
                                      value: "mParticleListingFields",
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "CartRemoveItemFields" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "rql_Me" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "uuid" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "cart" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "bundles" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "cartItems" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "_id" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "uuid" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "listing" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "_id" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                  },
                                  {
                                    kind: "FragmentSpread",
                                    name: {
                                      kind: "Name",
                                      value: "mParticleListingFields",
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "CartMoveItemToWatchListFields" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "rql_Me" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "uuid" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "cart" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "bundles" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "cartItems" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "_id" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "uuid" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "listing" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "_id" },
                                  },
                                  {
                                    kind: "FragmentSpread",
                                    name: {
                                      kind: "Name",
                                      value: "mParticleListingFields",
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "CartItemRowProtectionPlanFields" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "rql_Me" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "uuid" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "cart" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "bundles" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "cartItems" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "_id" },
                            },
                            {
                              kind: "Field",
                              name: {
                                kind: "Name",
                                value: "protectionPlanEligible",
                              },
                            },
                            {
                              kind: "Field",
                              alias: {
                                kind: "Name",
                                value: "cartItemProtectionPlan",
                              },
                              name: { kind: "Name", value: "protectionPlan" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "planId" },
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "durationMonths",
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "amount" },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "display",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "amount",
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "listing" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "_id" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "slug" },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CartItemRowFieldsFragment, unknown>;
export const CartBundleItemListFieldsFragmentDoc = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "CartBundleItemListFields" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "rql_Me" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "cart" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "bundles" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "cartItems" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "_id" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "uuid" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "CartItemRowFields" },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "CartItemRowPricingFields" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "core_apimessages_CartItem" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "listingTotal" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "discounted" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "discount" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "type" } },
                      { kind: "Field", name: { kind: "Name", value: "label" } },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "unitPrice" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "display" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "currency" },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "originalUnitPrice" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "display" },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "shippingTotal" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "discounted" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "discount" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "type" } },
                      { kind: "Field", name: { kind: "Name", value: "label" } },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "amountOwed" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "display" },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "originalAmountOwed" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "display" },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "listing" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "listingType" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "shipping" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "localPickupOnly" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "shippingPrices" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "_id" },
                            },
                            {
                              kind: "Field",
                              name: {
                                kind: "Name",
                                value: "carrierCalculated",
                              },
                            },
                            {
                              kind: "Field",
                              name: {
                                kind: "Name",
                                value: "destinationPostalCodeNeeded",
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "regional" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "shippingMethod" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "order" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "orderType" } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "CartItemBuyNowButtonFields" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "core_apimessages_CartItem" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "order" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "legacyOrderId" },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "paymentMethods" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "type" } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "mParticleListingFields" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Listing" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "title" } },
          { kind: "Field", name: { kind: "Name", value: "brandSlug" } },
          { kind: "Field", name: { kind: "Name", value: "categoryRootUuid" } },
          { kind: "Field", name: { kind: "Name", value: "make" } },
          { kind: "Field", name: { kind: "Name", value: "categoryUuids" } },
          { kind: "Field", name: { kind: "Name", value: "state" } },
          { kind: "Field", name: { kind: "Name", value: "listingType" } },
          { kind: "Field", name: { kind: "Name", value: "bumpEligible" } },
          { kind: "Field", name: { kind: "Name", value: "shopId" } },
          { kind: "Field", name: { kind: "Name", value: "inventory" } },
          { kind: "Field", name: { kind: "Name", value: "soldAsIs" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "acceptedPaymentMethods" },
          },
          { kind: "Field", name: { kind: "Name", value: "currency" } },
          { kind: "Field", name: { kind: "Name", value: "usOutlet" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "condition" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "conditionUuid" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "conditionSlug" },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "categories" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "slug" } },
                { kind: "Field", name: { kind: "Name", value: "rootSlug" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "csp" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "slug" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "brand" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      { kind: "Field", name: { kind: "Name", value: "slug" } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "pricing" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "buyerPrice" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "amount" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "currency" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "amountCents" },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "publishedAt" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "seconds" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "sale" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "code" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "buyerIneligibilityReason" },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "shipping" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "shippingPrices" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "shippingMethod" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "carrierCalculated" },
                      },
                      {
                        kind: "Field",
                        name: {
                          kind: "Name",
                          value: "destinationPostalCodeNeeded",
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "rate" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "amount" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "amountCents" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "currency" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "display" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "freeExpeditedShipping" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "localPickupOnly" },
                },
                { kind: "Field", name: { kind: "Name", value: "localPickup" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "certifiedPreOwned" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "title" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "badgeIconUrl" },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "shop" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "address" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "countryCode" },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "returnPolicy" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "newReturnWindowDays" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "usedReturnWindowDays" },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "CartItemQuantitySelectFields" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "rql_Me" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "uuid" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "cart" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "bundles" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "cartItems" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "_id" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "uuid" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "quantity" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "listing" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "_id" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "allowedBuyerQuantity",
                                    },
                                  },
                                  {
                                    kind: "FragmentSpread",
                                    name: {
                                      kind: "Name",
                                      value: "mParticleListingFields",
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "CartRemoveItemFields" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "rql_Me" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "uuid" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "cart" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "bundles" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "cartItems" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "_id" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "uuid" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "listing" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "_id" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                  },
                                  {
                                    kind: "FragmentSpread",
                                    name: {
                                      kind: "Name",
                                      value: "mParticleListingFields",
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "CartMoveItemToWatchListFields" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "rql_Me" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "uuid" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "cart" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "bundles" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "cartItems" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "_id" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "uuid" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "listing" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "_id" },
                                  },
                                  {
                                    kind: "FragmentSpread",
                                    name: {
                                      kind: "Name",
                                      value: "mParticleListingFields",
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "CartItemRowProtectionPlanFields" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "rql_Me" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "uuid" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "cart" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "bundles" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "cartItems" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "_id" },
                            },
                            {
                              kind: "Field",
                              name: {
                                kind: "Name",
                                value: "protectionPlanEligible",
                              },
                            },
                            {
                              kind: "Field",
                              alias: {
                                kind: "Name",
                                value: "cartItemProtectionPlan",
                              },
                              name: { kind: "Name", value: "protectionPlan" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "planId" },
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "durationMonths",
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "amount" },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "display",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "amount",
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "listing" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "_id" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "slug" },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "CartItemRowFields" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "rql_Me" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "uuid" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "cart" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "bundles" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "cartItems" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "_id" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "uuid" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "actions" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "canChangeQuantity",
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "canRemoveFromCart",
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "canMoveToWatchlist",
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "alerts" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "type" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "text" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "severity" },
                                  },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "listing" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "_id" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value:
                                        "otherBuyersWithListingInCartCounts",
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "title" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "images" },
                                    arguments: [
                                      {
                                        kind: "Argument",
                                        name: { kind: "Name", value: "input" },
                                        value: {
                                          kind: "ObjectValue",
                                          fields: [
                                            {
                                              kind: "ObjectField",
                                              name: {
                                                kind: "Name",
                                                value: "transform",
                                              },
                                              value: {
                                                kind: "StringValue",
                                                value: "card_square",
                                                block: false,
                                              },
                                            },
                                            {
                                              kind: "ObjectField",
                                              name: {
                                                kind: "Name",
                                                value: "count",
                                              },
                                              value: {
                                                kind: "IntValue",
                                                value: "1",
                                              },
                                            },
                                            {
                                              kind: "ObjectField",
                                              name: {
                                                kind: "Name",
                                                value: "scope",
                                              },
                                              value: {
                                                kind: "StringValue",
                                                value: "photos",
                                                block: false,
                                              },
                                            },
                                            {
                                              kind: "ObjectField",
                                              name: {
                                                kind: "Name",
                                                value: "type",
                                              },
                                              value: {
                                                kind: "StringValue",
                                                value: "Product",
                                                block: false,
                                              },
                                            },
                                          ],
                                        },
                                      },
                                    ],
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "source",
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "shop" },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "_id" },
                                        },
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "slug" },
                                        },
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "name" },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "address",
                                          },
                                          selectionSet: {
                                            kind: "SelectionSet",
                                            selections: [
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "_id",
                                                },
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "displayLocation",
                                                },
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "preorderInfo",
                                    },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "onPreorder",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "estimatedShipDate",
                                          },
                                          selectionSet: {
                                            kind: "SelectionSet",
                                            selections: [
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "seconds",
                                                },
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "shipping" },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "freeExpeditedShipping",
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "order" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "_id" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "orderType" },
                                  },
                                ],
                              },
                            },
                            {
                              kind: "FragmentSpread",
                              name: {
                                kind: "Name",
                                value: "CartItemRowPricingFields",
                              },
                            },
                            {
                              kind: "FragmentSpread",
                              name: {
                                kind: "Name",
                                value: "CartItemBuyNowButtonFields",
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "CartItemQuantitySelectFields" },
          },
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "CartRemoveItemFields" },
          },
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "CartMoveItemToWatchListFields" },
          },
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "CartItemRowProtectionPlanFields" },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CartBundleItemListFieldsFragment, unknown>;
export const CartBundlePaymentMethodSelectorFieldsFragmentDoc = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "CartBundlePaymentMethodSelectorFields" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "rql_Me" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "cart" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "bundles" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "checkoutBundlingId" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "total" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "amount" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "amountCents",
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "currency" },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: {
                          kind: "Name",
                          value: "availablePaymentMethods",
                        },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "type" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "name" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "affirm" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "financingProgramSlug",
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CartBundlePaymentMethodSelectorFieldsFragment,
  unknown
>;
export const CartBundleOrderSummaryFieldsFragmentDoc = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "CartBundleOrderSummaryFields" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "rql_Me" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "cart" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "bundles" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "checkoutBundlingId" },
                      },
                      {
                        kind: "Field",
                        name: {
                          kind: "Name",
                          value: "availablePaymentMethods",
                        },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "type" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "applePay" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "merchantId" },
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "merchantName",
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "paypal" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "currency" },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "paymentMethodIcons" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "slug" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "description" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "url" },
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "total" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "label" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "amount" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "display" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "currency" },
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "amountCents",
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "lineItems" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "type" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "label" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "amount" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "display" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "currency" },
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "amountCents",
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "FragmentSpread",
            name: {
              kind: "Name",
              value: "CartBundlePaymentMethodSelectorFields",
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "CartBundlePaymentMethodSelectorFields" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "rql_Me" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "cart" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "bundles" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "checkoutBundlingId" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "total" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "amount" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "amountCents",
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "currency" },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: {
                          kind: "Name",
                          value: "availablePaymentMethods",
                        },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "type" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "name" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "affirm" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "financingProgramSlug",
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CartBundleOrderSummaryFieldsFragment, unknown>;
export const CartBundleFreeShippingStatusesFieldsFragmentDoc = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "CartBundleFreeShippingStatusesFields" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "rql_Me" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "cart" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "bundles" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "freeShippingStatuses" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "shopId" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "isThresholdMet" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "percentProgress" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "amountRemaining" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "display" },
                                  },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "shop" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "_id" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "name" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "slug" },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CartBundleFreeShippingStatusesFieldsFragment,
  unknown
>;
export const CartBundleTitleFieldsFragmentDoc = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "CartBundleTitleFields" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "rql_Me" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "cart" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "bundles" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "title" } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CartBundleTitleFieldsFragment, unknown>;
export const CartBundleRowFieldsFragmentDoc = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "CartBundleRowFields" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "rql_Me" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "cart" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "bundles" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "freeShippingStatuses" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "shopId" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "CartBundleItemListFields" },
          },
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "CartBundleOrderSummaryFields" },
          },
          {
            kind: "FragmentSpread",
            name: {
              kind: "Name",
              value: "CartBundleFreeShippingStatusesFields",
            },
          },
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "CartBundleTitleFields" },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "CartItemRowPricingFields" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "core_apimessages_CartItem" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "listingTotal" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "discounted" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "discount" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "type" } },
                      { kind: "Field", name: { kind: "Name", value: "label" } },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "unitPrice" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "display" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "currency" },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "originalUnitPrice" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "display" },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "shippingTotal" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "discounted" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "discount" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "type" } },
                      { kind: "Field", name: { kind: "Name", value: "label" } },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "amountOwed" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "display" },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "originalAmountOwed" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "display" },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "listing" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "listingType" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "shipping" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "localPickupOnly" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "shippingPrices" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "_id" },
                            },
                            {
                              kind: "Field",
                              name: {
                                kind: "Name",
                                value: "carrierCalculated",
                              },
                            },
                            {
                              kind: "Field",
                              name: {
                                kind: "Name",
                                value: "destinationPostalCodeNeeded",
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "regional" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "shippingMethod" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "order" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "orderType" } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "CartItemBuyNowButtonFields" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "core_apimessages_CartItem" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "order" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "legacyOrderId" },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "paymentMethods" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "type" } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "mParticleListingFields" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Listing" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "title" } },
          { kind: "Field", name: { kind: "Name", value: "brandSlug" } },
          { kind: "Field", name: { kind: "Name", value: "categoryRootUuid" } },
          { kind: "Field", name: { kind: "Name", value: "make" } },
          { kind: "Field", name: { kind: "Name", value: "categoryUuids" } },
          { kind: "Field", name: { kind: "Name", value: "state" } },
          { kind: "Field", name: { kind: "Name", value: "listingType" } },
          { kind: "Field", name: { kind: "Name", value: "bumpEligible" } },
          { kind: "Field", name: { kind: "Name", value: "shopId" } },
          { kind: "Field", name: { kind: "Name", value: "inventory" } },
          { kind: "Field", name: { kind: "Name", value: "soldAsIs" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "acceptedPaymentMethods" },
          },
          { kind: "Field", name: { kind: "Name", value: "currency" } },
          { kind: "Field", name: { kind: "Name", value: "usOutlet" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "condition" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "conditionUuid" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "conditionSlug" },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "categories" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "slug" } },
                { kind: "Field", name: { kind: "Name", value: "rootSlug" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "csp" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "slug" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "brand" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      { kind: "Field", name: { kind: "Name", value: "slug" } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "pricing" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "buyerPrice" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "amount" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "currency" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "amountCents" },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "publishedAt" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "seconds" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "sale" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "code" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "buyerIneligibilityReason" },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "shipping" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "shippingPrices" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "shippingMethod" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "carrierCalculated" },
                      },
                      {
                        kind: "Field",
                        name: {
                          kind: "Name",
                          value: "destinationPostalCodeNeeded",
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "rate" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "amount" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "amountCents" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "currency" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "display" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "freeExpeditedShipping" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "localPickupOnly" },
                },
                { kind: "Field", name: { kind: "Name", value: "localPickup" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "certifiedPreOwned" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "title" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "badgeIconUrl" },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "shop" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "address" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "countryCode" },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "returnPolicy" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "newReturnWindowDays" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "usedReturnWindowDays" },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "CartItemQuantitySelectFields" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "rql_Me" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "uuid" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "cart" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "bundles" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "cartItems" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "_id" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "uuid" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "quantity" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "listing" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "_id" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "allowedBuyerQuantity",
                                    },
                                  },
                                  {
                                    kind: "FragmentSpread",
                                    name: {
                                      kind: "Name",
                                      value: "mParticleListingFields",
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "CartRemoveItemFields" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "rql_Me" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "uuid" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "cart" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "bundles" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "cartItems" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "_id" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "uuid" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "listing" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "_id" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                  },
                                  {
                                    kind: "FragmentSpread",
                                    name: {
                                      kind: "Name",
                                      value: "mParticleListingFields",
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "CartMoveItemToWatchListFields" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "rql_Me" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "uuid" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "cart" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "bundles" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "cartItems" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "_id" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "uuid" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "listing" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "_id" },
                                  },
                                  {
                                    kind: "FragmentSpread",
                                    name: {
                                      kind: "Name",
                                      value: "mParticleListingFields",
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "CartItemRowProtectionPlanFields" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "rql_Me" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "uuid" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "cart" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "bundles" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "cartItems" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "_id" },
                            },
                            {
                              kind: "Field",
                              name: {
                                kind: "Name",
                                value: "protectionPlanEligible",
                              },
                            },
                            {
                              kind: "Field",
                              alias: {
                                kind: "Name",
                                value: "cartItemProtectionPlan",
                              },
                              name: { kind: "Name", value: "protectionPlan" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "planId" },
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "durationMonths",
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "amount" },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "display",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "amount",
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "listing" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "_id" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "slug" },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "CartItemRowFields" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "rql_Me" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "uuid" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "cart" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "bundles" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "cartItems" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "_id" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "uuid" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "actions" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "canChangeQuantity",
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "canRemoveFromCart",
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "canMoveToWatchlist",
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "alerts" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "type" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "text" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "severity" },
                                  },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "listing" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "_id" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value:
                                        "otherBuyersWithListingInCartCounts",
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "title" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "images" },
                                    arguments: [
                                      {
                                        kind: "Argument",
                                        name: { kind: "Name", value: "input" },
                                        value: {
                                          kind: "ObjectValue",
                                          fields: [
                                            {
                                              kind: "ObjectField",
                                              name: {
                                                kind: "Name",
                                                value: "transform",
                                              },
                                              value: {
                                                kind: "StringValue",
                                                value: "card_square",
                                                block: false,
                                              },
                                            },
                                            {
                                              kind: "ObjectField",
                                              name: {
                                                kind: "Name",
                                                value: "count",
                                              },
                                              value: {
                                                kind: "IntValue",
                                                value: "1",
                                              },
                                            },
                                            {
                                              kind: "ObjectField",
                                              name: {
                                                kind: "Name",
                                                value: "scope",
                                              },
                                              value: {
                                                kind: "StringValue",
                                                value: "photos",
                                                block: false,
                                              },
                                            },
                                            {
                                              kind: "ObjectField",
                                              name: {
                                                kind: "Name",
                                                value: "type",
                                              },
                                              value: {
                                                kind: "StringValue",
                                                value: "Product",
                                                block: false,
                                              },
                                            },
                                          ],
                                        },
                                      },
                                    ],
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "source",
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "shop" },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "_id" },
                                        },
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "slug" },
                                        },
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "name" },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "address",
                                          },
                                          selectionSet: {
                                            kind: "SelectionSet",
                                            selections: [
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "_id",
                                                },
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "displayLocation",
                                                },
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "preorderInfo",
                                    },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "onPreorder",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "estimatedShipDate",
                                          },
                                          selectionSet: {
                                            kind: "SelectionSet",
                                            selections: [
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "seconds",
                                                },
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "shipping" },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "freeExpeditedShipping",
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "order" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "_id" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "orderType" },
                                  },
                                ],
                              },
                            },
                            {
                              kind: "FragmentSpread",
                              name: {
                                kind: "Name",
                                value: "CartItemRowPricingFields",
                              },
                            },
                            {
                              kind: "FragmentSpread",
                              name: {
                                kind: "Name",
                                value: "CartItemBuyNowButtonFields",
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "CartItemQuantitySelectFields" },
          },
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "CartRemoveItemFields" },
          },
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "CartMoveItemToWatchListFields" },
          },
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "CartItemRowProtectionPlanFields" },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "CartBundlePaymentMethodSelectorFields" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "rql_Me" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "cart" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "bundles" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "checkoutBundlingId" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "total" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "amount" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "amountCents",
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "currency" },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: {
                          kind: "Name",
                          value: "availablePaymentMethods",
                        },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "type" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "name" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "affirm" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "financingProgramSlug",
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "CartBundleItemListFields" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "rql_Me" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "cart" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "bundles" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "cartItems" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "_id" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "uuid" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "CartItemRowFields" },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "CartBundleOrderSummaryFields" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "rql_Me" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "cart" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "bundles" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "checkoutBundlingId" },
                      },
                      {
                        kind: "Field",
                        name: {
                          kind: "Name",
                          value: "availablePaymentMethods",
                        },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "type" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "applePay" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "merchantId" },
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "merchantName",
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "paypal" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "currency" },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "paymentMethodIcons" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "slug" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "description" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "url" },
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "total" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "label" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "amount" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "display" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "currency" },
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "amountCents",
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "lineItems" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "type" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "label" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "amount" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "display" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "currency" },
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "amountCents",
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "FragmentSpread",
            name: {
              kind: "Name",
              value: "CartBundlePaymentMethodSelectorFields",
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "CartBundleFreeShippingStatusesFields" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "rql_Me" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "cart" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "bundles" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "freeShippingStatuses" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "shopId" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "isThresholdMet" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "percentProgress" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "amountRemaining" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "display" },
                                  },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "shop" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "_id" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "name" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "slug" },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "CartBundleTitleFields" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "rql_Me" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "cart" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "bundles" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "title" } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CartBundleRowFieldsFragment, unknown>;
export const CartCombineAndSaveRowFieldsFragmentDoc = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "CartCombineAndSaveRowFields" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "rql_Me" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "cart" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "bundles" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "cartItems" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "_id" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "listingId" },
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: {
                          kind: "Name",
                          value: "combineAndSaveEligibleListing",
                        },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "_id" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "categoryRootUuid" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "shippingPrice" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "amountCents",
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "shop" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "_id" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "name" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "slug" },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CartCombineAndSaveRowFieldsFragment, unknown>;
export const CartHoistedExpressPayFieldsFragmentDoc = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "CartHoistedExpressPayFields" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "rql_Me" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "cart" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "bundles" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "checkoutBundlingId" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "total" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "amount" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "amount" },
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "amountCents",
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "currency" },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: {
                          kind: "Name",
                          value: "availablePaymentMethods",
                        },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "type" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "applePay" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "merchantId" },
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "merchantName",
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "paypal" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "currency" },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CartHoistedExpressPayFieldsFragment, unknown>;
export const CartLayoutMeFieldsFragmentDoc = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "CartLayoutMeFields" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "rql_Me" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "uuid" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "cart" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "cartItems" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      { kind: "Field", name: { kind: "Name", value: "uuid" } },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "bundles" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "checkoutBundlingId" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "total" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "label" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "amount" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "display" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "currency" },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "lineItems" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "type" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "amount" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "currency" },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "CartBundleRowFields" },
          },
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "CartCombineAndSaveRowFields" },
          },
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "CartHoistedExpressPayFields" },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "CartItemRowPricingFields" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "core_apimessages_CartItem" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "listingTotal" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "discounted" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "discount" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "type" } },
                      { kind: "Field", name: { kind: "Name", value: "label" } },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "unitPrice" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "display" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "currency" },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "originalUnitPrice" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "display" },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "shippingTotal" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "discounted" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "discount" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "type" } },
                      { kind: "Field", name: { kind: "Name", value: "label" } },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "amountOwed" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "display" },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "originalAmountOwed" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "display" },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "listing" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "listingType" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "shipping" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "localPickupOnly" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "shippingPrices" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "_id" },
                            },
                            {
                              kind: "Field",
                              name: {
                                kind: "Name",
                                value: "carrierCalculated",
                              },
                            },
                            {
                              kind: "Field",
                              name: {
                                kind: "Name",
                                value: "destinationPostalCodeNeeded",
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "regional" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "shippingMethod" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "order" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "orderType" } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "CartItemBuyNowButtonFields" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "core_apimessages_CartItem" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "order" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "legacyOrderId" },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "paymentMethods" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "type" } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "mParticleListingFields" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Listing" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "title" } },
          { kind: "Field", name: { kind: "Name", value: "brandSlug" } },
          { kind: "Field", name: { kind: "Name", value: "categoryRootUuid" } },
          { kind: "Field", name: { kind: "Name", value: "make" } },
          { kind: "Field", name: { kind: "Name", value: "categoryUuids" } },
          { kind: "Field", name: { kind: "Name", value: "state" } },
          { kind: "Field", name: { kind: "Name", value: "listingType" } },
          { kind: "Field", name: { kind: "Name", value: "bumpEligible" } },
          { kind: "Field", name: { kind: "Name", value: "shopId" } },
          { kind: "Field", name: { kind: "Name", value: "inventory" } },
          { kind: "Field", name: { kind: "Name", value: "soldAsIs" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "acceptedPaymentMethods" },
          },
          { kind: "Field", name: { kind: "Name", value: "currency" } },
          { kind: "Field", name: { kind: "Name", value: "usOutlet" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "condition" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "conditionUuid" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "conditionSlug" },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "categories" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "slug" } },
                { kind: "Field", name: { kind: "Name", value: "rootSlug" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "csp" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "slug" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "brand" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      { kind: "Field", name: { kind: "Name", value: "slug" } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "pricing" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "buyerPrice" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "amount" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "currency" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "amountCents" },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "publishedAt" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "seconds" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "sale" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "code" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "buyerIneligibilityReason" },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "shipping" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "shippingPrices" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "shippingMethod" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "carrierCalculated" },
                      },
                      {
                        kind: "Field",
                        name: {
                          kind: "Name",
                          value: "destinationPostalCodeNeeded",
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "rate" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "amount" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "amountCents" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "currency" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "display" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "freeExpeditedShipping" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "localPickupOnly" },
                },
                { kind: "Field", name: { kind: "Name", value: "localPickup" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "certifiedPreOwned" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "title" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "badgeIconUrl" },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "shop" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "address" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "countryCode" },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "returnPolicy" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "newReturnWindowDays" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "usedReturnWindowDays" },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "CartItemQuantitySelectFields" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "rql_Me" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "uuid" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "cart" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "bundles" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "cartItems" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "_id" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "uuid" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "quantity" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "listing" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "_id" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "allowedBuyerQuantity",
                                    },
                                  },
                                  {
                                    kind: "FragmentSpread",
                                    name: {
                                      kind: "Name",
                                      value: "mParticleListingFields",
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "CartRemoveItemFields" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "rql_Me" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "uuid" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "cart" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "bundles" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "cartItems" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "_id" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "uuid" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "listing" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "_id" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                  },
                                  {
                                    kind: "FragmentSpread",
                                    name: {
                                      kind: "Name",
                                      value: "mParticleListingFields",
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "CartMoveItemToWatchListFields" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "rql_Me" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "uuid" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "cart" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "bundles" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "cartItems" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "_id" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "uuid" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "listing" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "_id" },
                                  },
                                  {
                                    kind: "FragmentSpread",
                                    name: {
                                      kind: "Name",
                                      value: "mParticleListingFields",
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "CartItemRowProtectionPlanFields" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "rql_Me" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "uuid" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "cart" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "bundles" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "cartItems" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "_id" },
                            },
                            {
                              kind: "Field",
                              name: {
                                kind: "Name",
                                value: "protectionPlanEligible",
                              },
                            },
                            {
                              kind: "Field",
                              alias: {
                                kind: "Name",
                                value: "cartItemProtectionPlan",
                              },
                              name: { kind: "Name", value: "protectionPlan" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "planId" },
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "durationMonths",
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "amount" },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "display",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "amount",
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "listing" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "_id" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "slug" },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "CartItemRowFields" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "rql_Me" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "uuid" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "cart" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "bundles" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "cartItems" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "_id" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "uuid" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "actions" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "canChangeQuantity",
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "canRemoveFromCart",
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "canMoveToWatchlist",
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "alerts" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "type" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "text" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "severity" },
                                  },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "listing" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "_id" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value:
                                        "otherBuyersWithListingInCartCounts",
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "title" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "images" },
                                    arguments: [
                                      {
                                        kind: "Argument",
                                        name: { kind: "Name", value: "input" },
                                        value: {
                                          kind: "ObjectValue",
                                          fields: [
                                            {
                                              kind: "ObjectField",
                                              name: {
                                                kind: "Name",
                                                value: "transform",
                                              },
                                              value: {
                                                kind: "StringValue",
                                                value: "card_square",
                                                block: false,
                                              },
                                            },
                                            {
                                              kind: "ObjectField",
                                              name: {
                                                kind: "Name",
                                                value: "count",
                                              },
                                              value: {
                                                kind: "IntValue",
                                                value: "1",
                                              },
                                            },
                                            {
                                              kind: "ObjectField",
                                              name: {
                                                kind: "Name",
                                                value: "scope",
                                              },
                                              value: {
                                                kind: "StringValue",
                                                value: "photos",
                                                block: false,
                                              },
                                            },
                                            {
                                              kind: "ObjectField",
                                              name: {
                                                kind: "Name",
                                                value: "type",
                                              },
                                              value: {
                                                kind: "StringValue",
                                                value: "Product",
                                                block: false,
                                              },
                                            },
                                          ],
                                        },
                                      },
                                    ],
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "source",
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "shop" },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "_id" },
                                        },
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "slug" },
                                        },
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "name" },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "address",
                                          },
                                          selectionSet: {
                                            kind: "SelectionSet",
                                            selections: [
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "_id",
                                                },
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "displayLocation",
                                                },
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "preorderInfo",
                                    },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "onPreorder",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "estimatedShipDate",
                                          },
                                          selectionSet: {
                                            kind: "SelectionSet",
                                            selections: [
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "seconds",
                                                },
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "shipping" },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "freeExpeditedShipping",
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "order" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "_id" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "orderType" },
                                  },
                                ],
                              },
                            },
                            {
                              kind: "FragmentSpread",
                              name: {
                                kind: "Name",
                                value: "CartItemRowPricingFields",
                              },
                            },
                            {
                              kind: "FragmentSpread",
                              name: {
                                kind: "Name",
                                value: "CartItemBuyNowButtonFields",
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "CartItemQuantitySelectFields" },
          },
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "CartRemoveItemFields" },
          },
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "CartMoveItemToWatchListFields" },
          },
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "CartItemRowProtectionPlanFields" },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "CartBundleItemListFields" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "rql_Me" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "cart" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "bundles" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "cartItems" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "_id" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "uuid" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "CartItemRowFields" },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "CartBundlePaymentMethodSelectorFields" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "rql_Me" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "cart" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "bundles" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "checkoutBundlingId" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "total" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "amount" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "amountCents",
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "currency" },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: {
                          kind: "Name",
                          value: "availablePaymentMethods",
                        },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "type" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "name" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "affirm" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "financingProgramSlug",
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "CartBundleOrderSummaryFields" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "rql_Me" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "cart" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "bundles" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "checkoutBundlingId" },
                      },
                      {
                        kind: "Field",
                        name: {
                          kind: "Name",
                          value: "availablePaymentMethods",
                        },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "type" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "applePay" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "merchantId" },
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "merchantName",
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "paypal" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "currency" },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "paymentMethodIcons" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "slug" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "description" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "url" },
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "total" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "label" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "amount" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "display" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "currency" },
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "amountCents",
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "lineItems" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "type" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "label" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "amount" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "display" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "currency" },
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "amountCents",
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "FragmentSpread",
            name: {
              kind: "Name",
              value: "CartBundlePaymentMethodSelectorFields",
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "CartBundleFreeShippingStatusesFields" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "rql_Me" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "cart" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "bundles" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "freeShippingStatuses" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "shopId" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "isThresholdMet" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "percentProgress" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "amountRemaining" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "display" },
                                  },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "shop" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "_id" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "name" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "slug" },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "CartBundleTitleFields" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "rql_Me" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "cart" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "bundles" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "title" } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "CartBundleRowFields" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "rql_Me" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "cart" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "bundles" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "freeShippingStatuses" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "shopId" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "CartBundleItemListFields" },
          },
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "CartBundleOrderSummaryFields" },
          },
          {
            kind: "FragmentSpread",
            name: {
              kind: "Name",
              value: "CartBundleFreeShippingStatusesFields",
            },
          },
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "CartBundleTitleFields" },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "CartCombineAndSaveRowFields" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "rql_Me" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "cart" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "bundles" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "cartItems" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "_id" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "listingId" },
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: {
                          kind: "Name",
                          value: "combineAndSaveEligibleListing",
                        },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "_id" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "categoryRootUuid" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "shippingPrice" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "amountCents",
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "shop" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "_id" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "name" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "slug" },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "CartHoistedExpressPayFields" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "rql_Me" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "cart" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "bundles" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "checkoutBundlingId" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "total" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "amount" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "amount" },
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "amountCents",
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "currency" },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: {
                          kind: "Name",
                          value: "availablePaymentMethods",
                        },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "type" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "applePay" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "merchantId" },
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "merchantName",
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "paypal" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "currency" },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CartLayoutMeFieldsFragment, unknown>;
export const TopProductsCategoryFragmentDoc = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "TopProductsCategory" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Category" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "slug" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "children" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "name" } },
                { kind: "Field", name: { kind: "Name", value: "slug" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<TopProductsCategoryFragment, unknown>;
export const CSPAdditionalTrackingFieldsFragmentDoc = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "CSPAdditionalTrackingFields" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "CSP" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "slug" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "brand" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "slug" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "categories" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "slug" } },
                { kind: "Field", name: { kind: "Name", value: "rootSlug" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "parentCategories" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      { kind: "Field", name: { kind: "Name", value: "slug" } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "inventory" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "usedTotal" } },
                { kind: "Field", name: { kind: "Name", value: "newTotal" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CSPAdditionalTrackingFieldsFragment, unknown>;
export const CSPCardFragmentDoc = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "CSPCard" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "CSP" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "title" } },
          { kind: "Field", name: { kind: "Name", value: "slug" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "averageReviewRating" },
          },
          { kind: "Field", name: { kind: "Name", value: "reviewsCount" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "webLink" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "path" } },
                { kind: "Field", name: { kind: "Name", value: "href" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "image" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "transform" },
                      value: {
                        kind: "StringValue",
                        value: "card_square",
                        block: false,
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "source" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "brand" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "slug" } },
                { kind: "Field", name: { kind: "Name", value: "name" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "categories" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "slug" } },
                { kind: "Field", name: { kind: "Name", value: "rootSlug" } },
                { kind: "Field", name: { kind: "Name", value: "name" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "parentCategories" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      { kind: "Field", name: { kind: "Name", value: "name" } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "inventory" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "newLowPrice" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "display" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "amount" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "amountCents" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "currency" },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "usedLowPrice" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "display" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "amount" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "amountCents" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "currency" },
                      },
                    ],
                  },
                },
                { kind: "Field", name: { kind: "Name", value: "usedTotal" } },
                { kind: "Field", name: { kind: "Name", value: "newTotal" } },
              ],
            },
          },
          { kind: "Field", name: { kind: "Name", value: "finishes" } },
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "CSPAdditionalTrackingFields" },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "CSPAdditionalTrackingFields" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "CSP" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "slug" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "brand" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "slug" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "categories" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "slug" } },
                { kind: "Field", name: { kind: "Name", value: "rootSlug" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "parentCategories" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      { kind: "Field", name: { kind: "Name", value: "slug" } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "inventory" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "usedTotal" } },
                { kind: "Field", name: { kind: "Name", value: "newTotal" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CSPCardFragment, unknown>;
export const TopProductsCSPFragmentDoc = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "TopProductsCSP" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "CSP" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "summary" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "categories" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "name" } },
              ],
            },
          },
          { kind: "FragmentSpread", name: { kind: "Name", value: "CSPCard" } },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "CSPAdditionalTrackingFields" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "CSP" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "slug" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "brand" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "slug" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "categories" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "slug" } },
                { kind: "Field", name: { kind: "Name", value: "rootSlug" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "parentCategories" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      { kind: "Field", name: { kind: "Name", value: "slug" } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "inventory" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "usedTotal" } },
                { kind: "Field", name: { kind: "Name", value: "newTotal" } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "CSPCard" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "CSP" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "title" } },
          { kind: "Field", name: { kind: "Name", value: "slug" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "averageReviewRating" },
          },
          { kind: "Field", name: { kind: "Name", value: "reviewsCount" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "webLink" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "path" } },
                { kind: "Field", name: { kind: "Name", value: "href" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "image" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "transform" },
                      value: {
                        kind: "StringValue",
                        value: "card_square",
                        block: false,
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "source" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "brand" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "slug" } },
                { kind: "Field", name: { kind: "Name", value: "name" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "categories" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "slug" } },
                { kind: "Field", name: { kind: "Name", value: "rootSlug" } },
                { kind: "Field", name: { kind: "Name", value: "name" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "parentCategories" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      { kind: "Field", name: { kind: "Name", value: "name" } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "inventory" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "newLowPrice" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "display" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "amount" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "amountCents" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "currency" },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "usedLowPrice" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "display" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "amount" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "amountCents" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "currency" },
                      },
                    ],
                  },
                },
                { kind: "Field", name: { kind: "Name", value: "usedTotal" } },
                { kind: "Field", name: { kind: "Name", value: "newTotal" } },
              ],
            },
          },
          { kind: "Field", name: { kind: "Name", value: "finishes" } },
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "CSPAdditionalTrackingFields" },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<TopProductsCSPFragment, unknown>;
export const CheckoutApplePayLineItemFieldsFragmentDoc = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "CheckoutApplePayLineItemFields" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Checkout" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "total" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "amount" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "amount" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "currency" },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "lineItems" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "type" } },
                { kind: "Field", name: { kind: "Name", value: "label" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "amount" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "amount" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "amountCents" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "currency" },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CheckoutApplePayLineItemFieldsFragment, unknown>;
export const CheckoutApplePayHookFieldsFragmentDoc = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "CheckoutApplePayHookFields" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Checkout" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "type" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "guest" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "firstName" } },
                { kind: "Field", name: { kind: "Name", value: "lastName" } },
                { kind: "Field", name: { kind: "Name", value: "email" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "shippingAddress" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "name" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "streetAddress" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "extendedAddress" },
                },
                { kind: "Field", name: { kind: "Name", value: "locality" } },
                { kind: "Field", name: { kind: "Name", value: "region" } },
                { kind: "Field", name: { kind: "Name", value: "postalCode" } },
                { kind: "Field", name: { kind: "Name", value: "countryCode" } },
                { kind: "Field", name: { kind: "Name", value: "phone" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "paymentMethod" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "applePay" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "merchantName" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "merchantId" },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "total" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "amount" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "amountCents" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "currency" },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "lineItems" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "type" } },
                { kind: "Field", name: { kind: "Name", value: "label" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "amount" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "amount" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "amountCents" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "currency" },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "CheckoutApplePayLineItemFields" },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "CheckoutApplePayLineItemFields" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Checkout" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "total" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "amount" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "amount" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "currency" },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "lineItems" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "type" } },
                { kind: "Field", name: { kind: "Name", value: "label" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "amount" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "amount" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "amountCents" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "currency" },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CheckoutApplePayHookFieldsFragment, unknown>;
export const CheckoutCardContinueButtonFieldsFragmentDoc = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "CheckoutCardContinueButtonFields" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Checkout" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "id" } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CheckoutCardContinueButtonFieldsFragment, unknown>;
export const GooglePayManagerFieldsFragmentDoc = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "GooglePayManagerFields" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Checkout" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "status" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "paymentErrorMessage" },
          },
          { kind: "Field", name: { kind: "Name", value: "type" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "total" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "label" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "amount" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "amount" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "amountCents" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "currency" },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "guest" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "email" } },
                { kind: "Field", name: { kind: "Name", value: "firstName" } },
                { kind: "Field", name: { kind: "Name", value: "lastName" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "shippingAddress" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "name" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "streetAddress" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "extendedAddress" },
                },
                { kind: "Field", name: { kind: "Name", value: "locality" } },
                { kind: "Field", name: { kind: "Name", value: "region" } },
                { kind: "Field", name: { kind: "Name", value: "postalCode" } },
                { kind: "Field", name: { kind: "Name", value: "countryCode" } },
                { kind: "Field", name: { kind: "Name", value: "phone" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "lineItems" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "type" } },
                { kind: "Field", name: { kind: "Name", value: "label" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "amount" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "amount" },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GooglePayManagerFieldsFragment, unknown>;
export const CheckoutGooglePayContinueButtonFieldsFragmentDoc = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "CheckoutGooglePayContinueButtonFields" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Checkout" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "paymentMethod" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "googlePay" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "merchantId" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "merchantName" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "gatewayMerchantId" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "cardNetworks" },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "total" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "amount" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "amount" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "currency" },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "GooglePayManagerFields" },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "GooglePayManagerFields" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Checkout" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "status" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "paymentErrorMessage" },
          },
          { kind: "Field", name: { kind: "Name", value: "type" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "total" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "label" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "amount" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "amount" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "amountCents" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "currency" },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "guest" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "email" } },
                { kind: "Field", name: { kind: "Name", value: "firstName" } },
                { kind: "Field", name: { kind: "Name", value: "lastName" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "shippingAddress" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "name" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "streetAddress" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "extendedAddress" },
                },
                { kind: "Field", name: { kind: "Name", value: "locality" } },
                { kind: "Field", name: { kind: "Name", value: "region" } },
                { kind: "Field", name: { kind: "Name", value: "postalCode" } },
                { kind: "Field", name: { kind: "Name", value: "countryCode" } },
                { kind: "Field", name: { kind: "Name", value: "phone" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "lineItems" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "type" } },
                { kind: "Field", name: { kind: "Name", value: "label" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "amount" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "amount" },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CheckoutGooglePayContinueButtonFieldsFragment,
  unknown
>;
export const CheckoutKlarnaContinueButtonFieldsFragmentDoc = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "CheckoutKlarnaContinueButtonFields" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Checkout" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "id" } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CheckoutKlarnaContinueButtonFieldsFragment,
  unknown
>;
export const CheckoutPayPalContinueButtonFieldsFragmentDoc = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "CheckoutPayPalContinueButtonFields" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Checkout" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "id" } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CheckoutPayPalContinueButtonFieldsFragment,
  unknown
>;
export const CheckoutPaypalPayLaterButtonFieldsFragmentDoc = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "CheckoutPaypalPayLaterButtonFields" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Checkout" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "total" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "amount" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "currency" },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "paymentMethod" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "paypal" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "currency" },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CheckoutPaypalPayLaterButtonFieldsFragment,
  unknown
>;
export const CheckoutContinueButtonFieldsFragmentDoc = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "CheckoutContinueButtonFields" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Checkout" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "paymentMethod" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "type" } },
              ],
            },
          },
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "CheckoutApplePayHookFields" },
          },
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "CheckoutCardContinueButtonFields" },
          },
          {
            kind: "FragmentSpread",
            name: {
              kind: "Name",
              value: "CheckoutGooglePayContinueButtonFields",
            },
          },
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "CheckoutKlarnaContinueButtonFields" },
          },
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "CheckoutPayPalContinueButtonFields" },
          },
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "CheckoutPaypalPayLaterButtonFields" },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "CheckoutApplePayLineItemFields" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Checkout" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "total" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "amount" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "amount" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "currency" },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "lineItems" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "type" } },
                { kind: "Field", name: { kind: "Name", value: "label" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "amount" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "amount" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "amountCents" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "currency" },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "GooglePayManagerFields" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Checkout" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "status" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "paymentErrorMessage" },
          },
          { kind: "Field", name: { kind: "Name", value: "type" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "total" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "label" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "amount" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "amount" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "amountCents" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "currency" },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "guest" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "email" } },
                { kind: "Field", name: { kind: "Name", value: "firstName" } },
                { kind: "Field", name: { kind: "Name", value: "lastName" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "shippingAddress" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "name" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "streetAddress" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "extendedAddress" },
                },
                { kind: "Field", name: { kind: "Name", value: "locality" } },
                { kind: "Field", name: { kind: "Name", value: "region" } },
                { kind: "Field", name: { kind: "Name", value: "postalCode" } },
                { kind: "Field", name: { kind: "Name", value: "countryCode" } },
                { kind: "Field", name: { kind: "Name", value: "phone" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "lineItems" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "type" } },
                { kind: "Field", name: { kind: "Name", value: "label" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "amount" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "amount" },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "CheckoutApplePayHookFields" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Checkout" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "type" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "guest" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "firstName" } },
                { kind: "Field", name: { kind: "Name", value: "lastName" } },
                { kind: "Field", name: { kind: "Name", value: "email" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "shippingAddress" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "name" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "streetAddress" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "extendedAddress" },
                },
                { kind: "Field", name: { kind: "Name", value: "locality" } },
                { kind: "Field", name: { kind: "Name", value: "region" } },
                { kind: "Field", name: { kind: "Name", value: "postalCode" } },
                { kind: "Field", name: { kind: "Name", value: "countryCode" } },
                { kind: "Field", name: { kind: "Name", value: "phone" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "paymentMethod" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "applePay" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "merchantName" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "merchantId" },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "total" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "amount" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "amountCents" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "currency" },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "lineItems" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "type" } },
                { kind: "Field", name: { kind: "Name", value: "label" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "amount" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "amount" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "amountCents" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "currency" },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "CheckoutApplePayLineItemFields" },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "CheckoutCardContinueButtonFields" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Checkout" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "id" } },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "CheckoutGooglePayContinueButtonFields" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Checkout" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "paymentMethod" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "googlePay" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "merchantId" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "merchantName" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "gatewayMerchantId" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "cardNetworks" },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "total" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "amount" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "amount" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "currency" },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "GooglePayManagerFields" },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "CheckoutKlarnaContinueButtonFields" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Checkout" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "id" } },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "CheckoutPayPalContinueButtonFields" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Checkout" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "id" } },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "CheckoutPaypalPayLaterButtonFields" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Checkout" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "total" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "amount" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "currency" },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "paymentMethod" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "paypal" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "currency" },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CheckoutContinueButtonFieldsFragment, unknown>;
export const CheckoutOrderSummaryOrderItemSubtotalFieldsFragmentDoc = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: {
        kind: "Name",
        value: "CheckoutOrderSummaryOrderItemSubtotalFields",
      },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Checkout" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "orders" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "quantity" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "listing" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "pricing" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "originalPrice" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "display" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "currency" },
                                  },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "buyerPrice" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "display" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "currency" },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "subtotal" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "listingPrice" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "display" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "currency" },
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "discounted" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "discount" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "type" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "label" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CheckoutOrderSummaryOrderItemSubtotalFieldsFragment,
  unknown
>;
export const CheckoutOrderSummaryOrderItemShippingTotalFieldsFragmentDoc = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: {
        kind: "Name",
        value: "CheckoutOrderSummaryOrderItemShippingTotalFields",
      },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Checkout" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "orders" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "shippingTotal" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "originalAmountOwed" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "display" },
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "amountOwed" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "display" },
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "discounted" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "discount" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "type" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "label" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CheckoutOrderSummaryOrderItemShippingTotalFieldsFragment,
  unknown
>;
export const CheckoutOrderSummaryImageFieldsFragmentDoc = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "CheckoutOrderSummaryImageFields" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Checkout" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "orders" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "listing" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      { kind: "Field", name: { kind: "Name", value: "title" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "images" },
                        arguments: [
                          {
                            kind: "Argument",
                            name: { kind: "Name", value: "input" },
                            value: {
                              kind: "ObjectValue",
                              fields: [
                                {
                                  kind: "ObjectField",
                                  name: { kind: "Name", value: "transform" },
                                  value: {
                                    kind: "StringValue",
                                    value: "card_square",
                                    block: false,
                                  },
                                },
                                {
                                  kind: "ObjectField",
                                  name: { kind: "Name", value: "count" },
                                  value: { kind: "IntValue", value: "1" },
                                },
                                {
                                  kind: "ObjectField",
                                  name: { kind: "Name", value: "scope" },
                                  value: {
                                    kind: "StringValue",
                                    value: "photos",
                                    block: false,
                                  },
                                },
                                {
                                  kind: "ObjectField",
                                  name: { kind: "Name", value: "type" },
                                  value: {
                                    kind: "StringValue",
                                    value: "Product",
                                    block: false,
                                  },
                                },
                              ],
                            },
                          },
                        ],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "source" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CheckoutOrderSummaryImageFieldsFragment, unknown>;
export const CheckoutOrderQuantitySelectorFieldsFragmentDoc = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "CheckoutOrderQuantitySelectorFields" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Checkout" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "orders" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "quantity" } },
                { kind: "Field", name: { kind: "Name", value: "listingId" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "buyerCanChangeQuantity" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "listing" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "allowedBuyerQuantity" },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CheckoutOrderQuantitySelectorFieldsFragment,
  unknown
>;
export const CheckoutOrderShippingSelectorFieldsFragmentDoc = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "CheckoutOrderShippingSelectorFields" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Checkout" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "orders" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "listingId" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "availableShippingMethods" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "name" } },
                      { kind: "Field", name: { kind: "Name", value: "type" } },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "shippingMethod" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "name" } },
                      { kind: "Field", name: { kind: "Name", value: "type" } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CheckoutOrderShippingSelectorFieldsFragment,
  unknown
>;
export const CheckoutOrderSummaryOrderItemFieldsFragmentDoc = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "CheckoutOrderSummaryOrderItemFields" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Checkout" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "orders" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "cannotBeShippedToAddress" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "subtotal" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "discounted" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "discount" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "label" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "type" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                { kind: "Field", name: { kind: "Name", value: "taxIncluded" } },
                { kind: "Field", name: { kind: "Name", value: "taxLabel" } },
                { kind: "Field", name: { kind: "Name", value: "listingId" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "listing" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      { kind: "Field", name: { kind: "Name", value: "title" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "shop" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "_id" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "name" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "userUuid" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "address" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "_id" },
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "displayLocation",
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "protectionPlan" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "title" } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "FragmentSpread",
            name: {
              kind: "Name",
              value: "CheckoutOrderSummaryOrderItemSubtotalFields",
            },
          },
          {
            kind: "FragmentSpread",
            name: {
              kind: "Name",
              value: "CheckoutOrderSummaryOrderItemShippingTotalFields",
            },
          },
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "CheckoutOrderSummaryImageFields" },
          },
          {
            kind: "FragmentSpread",
            name: {
              kind: "Name",
              value: "CheckoutOrderQuantitySelectorFields",
            },
          },
          {
            kind: "FragmentSpread",
            name: {
              kind: "Name",
              value: "CheckoutOrderShippingSelectorFields",
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: {
        kind: "Name",
        value: "CheckoutOrderSummaryOrderItemSubtotalFields",
      },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Checkout" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "orders" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "quantity" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "listing" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "pricing" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "originalPrice" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "display" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "currency" },
                                  },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "buyerPrice" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "display" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "currency" },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "subtotal" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "listingPrice" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "display" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "currency" },
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "discounted" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "discount" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "type" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "label" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: {
        kind: "Name",
        value: "CheckoutOrderSummaryOrderItemShippingTotalFields",
      },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Checkout" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "orders" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "shippingTotal" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "originalAmountOwed" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "display" },
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "amountOwed" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "display" },
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "discounted" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "discount" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "type" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "label" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "CheckoutOrderSummaryImageFields" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Checkout" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "orders" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "listing" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      { kind: "Field", name: { kind: "Name", value: "title" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "images" },
                        arguments: [
                          {
                            kind: "Argument",
                            name: { kind: "Name", value: "input" },
                            value: {
                              kind: "ObjectValue",
                              fields: [
                                {
                                  kind: "ObjectField",
                                  name: { kind: "Name", value: "transform" },
                                  value: {
                                    kind: "StringValue",
                                    value: "card_square",
                                    block: false,
                                  },
                                },
                                {
                                  kind: "ObjectField",
                                  name: { kind: "Name", value: "count" },
                                  value: { kind: "IntValue", value: "1" },
                                },
                                {
                                  kind: "ObjectField",
                                  name: { kind: "Name", value: "scope" },
                                  value: {
                                    kind: "StringValue",
                                    value: "photos",
                                    block: false,
                                  },
                                },
                                {
                                  kind: "ObjectField",
                                  name: { kind: "Name", value: "type" },
                                  value: {
                                    kind: "StringValue",
                                    value: "Product",
                                    block: false,
                                  },
                                },
                              ],
                            },
                          },
                        ],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "source" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "CheckoutOrderQuantitySelectorFields" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Checkout" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "orders" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "quantity" } },
                { kind: "Field", name: { kind: "Name", value: "listingId" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "buyerCanChangeQuantity" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "listing" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "allowedBuyerQuantity" },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "CheckoutOrderShippingSelectorFields" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Checkout" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "orders" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "listingId" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "availableShippingMethods" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "name" } },
                      { kind: "Field", name: { kind: "Name", value: "type" } },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "shippingMethod" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "name" } },
                      { kind: "Field", name: { kind: "Name", value: "type" } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CheckoutOrderSummaryOrderItemFieldsFragment,
  unknown
>;
export const CheckoutOrderSummaryLineItemsFieldsFragmentDoc = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "CheckoutOrderSummaryLineItemsFields" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Checkout" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "total" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "label" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "amount" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "display" },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "lineItems" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "type" } },
                { kind: "Field", name: { kind: "Name", value: "label" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "amount" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "display" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "currency" },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CheckoutOrderSummaryLineItemsFieldsFragment,
  unknown
>;
export const CheckoutOrderSummaryFieldsFragmentDoc = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "CheckoutOrderSummaryFields" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Checkout" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "orders" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "listingId" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "paymentMethod" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "type" } },
                { kind: "Field", name: { kind: "Name", value: "isComplete" } },
              ],
            },
          },
          {
            kind: "FragmentSpread",
            name: {
              kind: "Name",
              value: "CheckoutOrderSummaryOrderItemFields",
            },
          },
          {
            kind: "FragmentSpread",
            name: {
              kind: "Name",
              value: "CheckoutOrderSummaryLineItemsFields",
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: {
        kind: "Name",
        value: "CheckoutOrderSummaryOrderItemSubtotalFields",
      },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Checkout" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "orders" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "quantity" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "listing" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "pricing" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "originalPrice" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "display" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "currency" },
                                  },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "buyerPrice" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "display" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "currency" },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "subtotal" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "listingPrice" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "display" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "currency" },
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "discounted" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "discount" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "type" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "label" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: {
        kind: "Name",
        value: "CheckoutOrderSummaryOrderItemShippingTotalFields",
      },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Checkout" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "orders" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "shippingTotal" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "originalAmountOwed" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "display" },
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "amountOwed" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "display" },
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "discounted" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "discount" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "type" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "label" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "CheckoutOrderSummaryImageFields" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Checkout" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "orders" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "listing" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      { kind: "Field", name: { kind: "Name", value: "title" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "images" },
                        arguments: [
                          {
                            kind: "Argument",
                            name: { kind: "Name", value: "input" },
                            value: {
                              kind: "ObjectValue",
                              fields: [
                                {
                                  kind: "ObjectField",
                                  name: { kind: "Name", value: "transform" },
                                  value: {
                                    kind: "StringValue",
                                    value: "card_square",
                                    block: false,
                                  },
                                },
                                {
                                  kind: "ObjectField",
                                  name: { kind: "Name", value: "count" },
                                  value: { kind: "IntValue", value: "1" },
                                },
                                {
                                  kind: "ObjectField",
                                  name: { kind: "Name", value: "scope" },
                                  value: {
                                    kind: "StringValue",
                                    value: "photos",
                                    block: false,
                                  },
                                },
                                {
                                  kind: "ObjectField",
                                  name: { kind: "Name", value: "type" },
                                  value: {
                                    kind: "StringValue",
                                    value: "Product",
                                    block: false,
                                  },
                                },
                              ],
                            },
                          },
                        ],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "source" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "CheckoutOrderQuantitySelectorFields" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Checkout" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "orders" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "quantity" } },
                { kind: "Field", name: { kind: "Name", value: "listingId" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "buyerCanChangeQuantity" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "listing" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "allowedBuyerQuantity" },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "CheckoutOrderShippingSelectorFields" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Checkout" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "orders" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "listingId" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "availableShippingMethods" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "name" } },
                      { kind: "Field", name: { kind: "Name", value: "type" } },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "shippingMethod" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "name" } },
                      { kind: "Field", name: { kind: "Name", value: "type" } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "CheckoutOrderSummaryOrderItemFields" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Checkout" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "orders" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "cannotBeShippedToAddress" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "subtotal" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "discounted" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "discount" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "label" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "type" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                { kind: "Field", name: { kind: "Name", value: "taxIncluded" } },
                { kind: "Field", name: { kind: "Name", value: "taxLabel" } },
                { kind: "Field", name: { kind: "Name", value: "listingId" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "listing" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      { kind: "Field", name: { kind: "Name", value: "title" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "shop" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "_id" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "name" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "userUuid" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "address" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "_id" },
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "displayLocation",
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "protectionPlan" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "title" } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "FragmentSpread",
            name: {
              kind: "Name",
              value: "CheckoutOrderSummaryOrderItemSubtotalFields",
            },
          },
          {
            kind: "FragmentSpread",
            name: {
              kind: "Name",
              value: "CheckoutOrderSummaryOrderItemShippingTotalFields",
            },
          },
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "CheckoutOrderSummaryImageFields" },
          },
          {
            kind: "FragmentSpread",
            name: {
              kind: "Name",
              value: "CheckoutOrderQuantitySelectorFields",
            },
          },
          {
            kind: "FragmentSpread",
            name: {
              kind: "Name",
              value: "CheckoutOrderShippingSelectorFields",
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "CheckoutOrderSummaryLineItemsFields" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Checkout" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "total" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "label" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "amount" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "display" },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "lineItems" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "type" } },
                { kind: "Field", name: { kind: "Name", value: "label" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "amount" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "display" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "currency" },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CheckoutOrderSummaryFieldsFragment, unknown>;
export const CheckoutPayPalPlaceOrderButtonFieldsFragmentDoc = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "CheckoutPayPalPlaceOrderButtonFields" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Checkout" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "total" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "amount" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "amountCents" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "currency" },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CheckoutPayPalPlaceOrderButtonFieldsFragment,
  unknown
>;
export const CheckoutAvailablePaymentMethodsHookFieldsFragmentDoc = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: {
        kind: "Name",
        value: "CheckoutAvailablePaymentMethodsHookFields",
      },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Checkout" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "availablePaymentMethods" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "type" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "googlePay" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "merchantId" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "merchantName" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "gatewayMerchantId" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "cardNetworks" },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "applePay" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "merchantId" },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CheckoutAvailablePaymentMethodsHookFieldsFragment,
  unknown
>;
export const CheckoutCreditCardWalletFieldsFragmentDoc = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "CheckoutCreditCardWalletFields" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Checkout" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "shippingAddress" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "uuid" } },
                { kind: "Field", name: { kind: "Name", value: "id" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "paymentMethod" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "isComplete" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "creditCard" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "needsReverification" },
                },
                { kind: "Field", name: { kind: "Name", value: "cardType" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "brand" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "type" } },
                      { kind: "Field", name: { kind: "Name", value: "name" } },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "display" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "summary" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "expirationDate" },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CheckoutCreditCardWalletFieldsFragment, unknown>;
export const CheckoutTokenizedCardPaymentFormFieldsFragmentDoc = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "CheckoutTokenizedCardPaymentFormFields" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Checkout" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "orders" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "shippingMethod" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "type" } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "shippingAddress" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "uuid" } },
                { kind: "Field", name: { kind: "Name", value: "name" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "streetAddress" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "extendedAddress" },
                },
                { kind: "Field", name: { kind: "Name", value: "postalCode" } },
                { kind: "Field", name: { kind: "Name", value: "region" } },
                { kind: "Field", name: { kind: "Name", value: "regionName" } },
                { kind: "Field", name: { kind: "Name", value: "locality" } },
                { kind: "Field", name: { kind: "Name", value: "phone" } },
                { kind: "Field", name: { kind: "Name", value: "primary" } },
                { kind: "Field", name: { kind: "Name", value: "isComplete" } },
                { kind: "Field", name: { kind: "Name", value: "countryCode" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "country" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      { kind: "Field", name: { kind: "Name", value: "name" } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CheckoutTokenizedCardPaymentFormFieldsFragment,
  unknown
>;
export const CheckoutCreditCardFormFieldsFragmentDoc = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "CheckoutCreditCardFormFields" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Checkout" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "CheckoutCreditCardWalletFields" },
          },
          {
            kind: "FragmentSpread",
            name: {
              kind: "Name",
              value: "CheckoutTokenizedCardPaymentFormFields",
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "CheckoutCreditCardWalletFields" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Checkout" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "shippingAddress" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "uuid" } },
                { kind: "Field", name: { kind: "Name", value: "id" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "paymentMethod" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "isComplete" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "creditCard" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "needsReverification" },
                },
                { kind: "Field", name: { kind: "Name", value: "cardType" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "brand" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "type" } },
                      { kind: "Field", name: { kind: "Name", value: "name" } },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "display" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "summary" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "expirationDate" },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "CheckoutTokenizedCardPaymentFormFields" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Checkout" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "orders" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "shippingMethod" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "type" } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "shippingAddress" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "uuid" } },
                { kind: "Field", name: { kind: "Name", value: "name" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "streetAddress" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "extendedAddress" },
                },
                { kind: "Field", name: { kind: "Name", value: "postalCode" } },
                { kind: "Field", name: { kind: "Name", value: "region" } },
                { kind: "Field", name: { kind: "Name", value: "regionName" } },
                { kind: "Field", name: { kind: "Name", value: "locality" } },
                { kind: "Field", name: { kind: "Name", value: "phone" } },
                { kind: "Field", name: { kind: "Name", value: "primary" } },
                { kind: "Field", name: { kind: "Name", value: "isComplete" } },
                { kind: "Field", name: { kind: "Name", value: "countryCode" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "country" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      { kind: "Field", name: { kind: "Name", value: "name" } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CheckoutCreditCardFormFieldsFragment, unknown>;
export const CheckoutAffirmBillingFormFieldsFragmentDoc = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "CheckoutAffirmBillingFormFields" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Checkout" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "billingAddress" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "isComplete" } },
                { kind: "Field", name: { kind: "Name", value: "name" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "streetAddress" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "extendedAddress" },
                },
                { kind: "Field", name: { kind: "Name", value: "locality" } },
                { kind: "Field", name: { kind: "Name", value: "region" } },
                { kind: "Field", name: { kind: "Name", value: "postalCode" } },
                { kind: "Field", name: { kind: "Name", value: "countryCode" } },
                { kind: "Field", name: { kind: "Name", value: "phone" } },
                { kind: "Field", name: { kind: "Name", value: "regionName" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CheckoutAffirmBillingFormFieldsFragment, unknown>;
export const SelectCheckoutPaymentMethodFieldsFragmentDoc = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "SelectCheckoutPaymentMethodFields" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Checkout" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "source" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "paymentMethod" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "type" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "applePay" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "merchantId" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "merchantName" },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "googlePay" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "merchantId" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "merchantName" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "gatewayMerchantId" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "cardNetworks" },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  SelectCheckoutPaymentMethodFieldsFragment,
  unknown
>;
export const PaymentMethodLabelFieldsFragmentDoc = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "PaymentMethodLabelFields" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Checkout" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "availablePaymentMethods" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "name" } },
                { kind: "Field", name: { kind: "Name", value: "type" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "affirm" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "financingProgramSlug" },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "lineItems" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "type" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "total" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "amount" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "amount" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "amountCents" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "currency" },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<PaymentMethodLabelFieldsFragment, unknown>;
export const PaymentMethodSelectorVisibilityHookFieldsFragmentDoc = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: {
        kind: "Name",
        value: "PaymentMethodSelectorVisibilityHookFields",
      },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Checkout" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "source" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "availablePaymentMethods" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "type" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  PaymentMethodSelectorVisibilityHookFieldsFragment,
  unknown
>;
export const CheckoutStandalonePayPalPaymentOptionsFieldsFragmentDoc = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: {
        kind: "Name",
        value: "CheckoutStandalonePayPalPaymentOptionsFields",
      },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Checkout" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "total" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "amount" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "currency" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "amountCents" },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "paymentMethod" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "type" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "paypal" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "currency" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "merchantId" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "clientId" },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CheckoutStandalonePayPalPaymentOptionsFieldsFragment,
  unknown
>;
export const CheckoutPaymentMethodFieldsFragmentDoc = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "CheckoutPaymentMethodFields" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Checkout" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "availablePaymentMethods" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "name" } },
                { kind: "Field", name: { kind: "Name", value: "type" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "applePay" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "merchantId" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "merchantName" },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "googlePay" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "merchantId" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "merchantName" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "gatewayMerchantId" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "cardNetworks" },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "FragmentSpread",
            name: {
              kind: "Name",
              value: "CheckoutAvailablePaymentMethodsHookFields",
            },
          },
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "CheckoutCreditCardFormFields" },
          },
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "CheckoutAffirmBillingFormFields" },
          },
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "SelectCheckoutPaymentMethodFields" },
          },
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "PaymentMethodLabelFields" },
          },
          {
            kind: "FragmentSpread",
            name: {
              kind: "Name",
              value: "PaymentMethodSelectorVisibilityHookFields",
            },
          },
          {
            kind: "FragmentSpread",
            name: {
              kind: "Name",
              value: "CheckoutStandalonePayPalPaymentOptionsFields",
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "CheckoutCreditCardWalletFields" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Checkout" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "shippingAddress" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "uuid" } },
                { kind: "Field", name: { kind: "Name", value: "id" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "paymentMethod" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "isComplete" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "creditCard" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "needsReverification" },
                },
                { kind: "Field", name: { kind: "Name", value: "cardType" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "brand" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "type" } },
                      { kind: "Field", name: { kind: "Name", value: "name" } },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "display" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "summary" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "expirationDate" },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "CheckoutTokenizedCardPaymentFormFields" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Checkout" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "orders" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "shippingMethod" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "type" } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "shippingAddress" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "uuid" } },
                { kind: "Field", name: { kind: "Name", value: "name" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "streetAddress" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "extendedAddress" },
                },
                { kind: "Field", name: { kind: "Name", value: "postalCode" } },
                { kind: "Field", name: { kind: "Name", value: "region" } },
                { kind: "Field", name: { kind: "Name", value: "regionName" } },
                { kind: "Field", name: { kind: "Name", value: "locality" } },
                { kind: "Field", name: { kind: "Name", value: "phone" } },
                { kind: "Field", name: { kind: "Name", value: "primary" } },
                { kind: "Field", name: { kind: "Name", value: "isComplete" } },
                { kind: "Field", name: { kind: "Name", value: "countryCode" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "country" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      { kind: "Field", name: { kind: "Name", value: "name" } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: {
        kind: "Name",
        value: "CheckoutAvailablePaymentMethodsHookFields",
      },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Checkout" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "availablePaymentMethods" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "type" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "googlePay" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "merchantId" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "merchantName" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "gatewayMerchantId" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "cardNetworks" },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "applePay" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "merchantId" },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "CheckoutCreditCardFormFields" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Checkout" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "CheckoutCreditCardWalletFields" },
          },
          {
            kind: "FragmentSpread",
            name: {
              kind: "Name",
              value: "CheckoutTokenizedCardPaymentFormFields",
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "CheckoutAffirmBillingFormFields" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Checkout" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "billingAddress" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "isComplete" } },
                { kind: "Field", name: { kind: "Name", value: "name" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "streetAddress" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "extendedAddress" },
                },
                { kind: "Field", name: { kind: "Name", value: "locality" } },
                { kind: "Field", name: { kind: "Name", value: "region" } },
                { kind: "Field", name: { kind: "Name", value: "postalCode" } },
                { kind: "Field", name: { kind: "Name", value: "countryCode" } },
                { kind: "Field", name: { kind: "Name", value: "phone" } },
                { kind: "Field", name: { kind: "Name", value: "regionName" } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "SelectCheckoutPaymentMethodFields" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Checkout" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "source" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "paymentMethod" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "type" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "applePay" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "merchantId" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "merchantName" },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "googlePay" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "merchantId" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "merchantName" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "gatewayMerchantId" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "cardNetworks" },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "PaymentMethodLabelFields" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Checkout" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "availablePaymentMethods" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "name" } },
                { kind: "Field", name: { kind: "Name", value: "type" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "affirm" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "financingProgramSlug" },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "lineItems" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "type" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "total" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "amount" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "amount" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "amountCents" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "currency" },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: {
        kind: "Name",
        value: "PaymentMethodSelectorVisibilityHookFields",
      },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Checkout" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "source" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "availablePaymentMethods" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "type" } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: {
        kind: "Name",
        value: "CheckoutStandalonePayPalPaymentOptionsFields",
      },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Checkout" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "total" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "amount" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "currency" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "amountCents" },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "paymentMethod" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "type" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "paypal" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "currency" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "merchantId" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "clientId" },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CheckoutPaymentMethodFieldsFragment, unknown>;
export const RedirectReturnCheckoutFieldsFragmentDoc = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "RedirectReturnCheckoutFields" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Checkout" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "status" } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<RedirectReturnCheckoutFieldsFragment, unknown>;
export const CheckoutThirdPartyAdsFieldsFragmentDoc = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "CheckoutThirdPartyAdsFields" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Checkout" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "type" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "total" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "amount" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "amount" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "currency" },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "orders" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "quantity" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "listing" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      { kind: "Field", name: { kind: "Name", value: "title" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "categories" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "_id" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "slug" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "rootSlug" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "subtotal" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "listingPrice" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "amount" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "guest" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "firstName" } },
                { kind: "Field", name: { kind: "Name", value: "lastName" } },
                { kind: "Field", name: { kind: "Name", value: "email" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CheckoutThirdPartyAdsFieldsFragment, unknown>;
export const GooglePayFinalizeActionFieldsFragmentDoc = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "GooglePayFinalizeActionFields" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "CheckoutAction" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "type" } },
          { kind: "Field", name: { kind: "Name", value: "redirectUrl" } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GooglePayFinalizeActionFieldsFragment, unknown>;
export const CheckoutActionHookFieldsFragmentDoc = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "CheckoutActionHookFields" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "CheckoutAction" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "type" } },
          { kind: "Field", name: { kind: "Name", value: "redirectUrl" } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CheckoutActionHookFieldsFragment, unknown>;
export const CheckoutContinueButtonStateFieldsFragmentDoc = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "CheckoutContinueButtonStateFields" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Checkout" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "shippingAddress" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "isComplete" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "paymentMethod" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "isComplete" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "orders" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "cannotBeShippedToAddress" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "shippingMethod" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "type" } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "guest" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "isComplete" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CheckoutContinueButtonStateFieldsFragment,
  unknown
>;
export const CheckoutCardPaymentHookCheckoutFieldsFragmentDoc = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "CheckoutCardPaymentHookCheckoutFields" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Checkout" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "total" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "amount" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "amountCents" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "currency" },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CheckoutCardPaymentHookCheckoutFieldsFragment,
  unknown
>;
export const AffirmCheckoutBuilderFieldsFragmentDoc = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "AffirmCheckoutBuilderFields" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Checkout" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "total" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "amount" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "amountCents" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "currency" },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "orders" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "quantity" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "subtotal" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "listingPrice" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "amountCents" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "listing" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      { kind: "Field", name: { kind: "Name", value: "title" } },
                      {
                        kind: "Field",
                        alias: { kind: "Name", value: "affirmImages" },
                        name: { kind: "Name", value: "images" },
                        arguments: [
                          {
                            kind: "Argument",
                            name: { kind: "Name", value: "input" },
                            value: {
                              kind: "ObjectValue",
                              fields: [
                                {
                                  kind: "ObjectField",
                                  name: { kind: "Name", value: "transform" },
                                  value: {
                                    kind: "StringValue",
                                    value: "card_square",
                                    block: false,
                                  },
                                },
                              ],
                            },
                          },
                        ],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "_id" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "source" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "paymentMethod" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "affirm" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "financingProgramSlug" },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "shippingAddress" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "name" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "streetAddress" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "extendedAddress" },
                },
                { kind: "Field", name: { kind: "Name", value: "locality" } },
                { kind: "Field", name: { kind: "Name", value: "region" } },
                { kind: "Field", name: { kind: "Name", value: "postalCode" } },
                { kind: "Field", name: { kind: "Name", value: "countryCode" } },
                { kind: "Field", name: { kind: "Name", value: "phone" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "billingAddress" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "name" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "streetAddress" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "extendedAddress" },
                },
                { kind: "Field", name: { kind: "Name", value: "locality" } },
                { kind: "Field", name: { kind: "Name", value: "region" } },
                { kind: "Field", name: { kind: "Name", value: "postalCode" } },
                { kind: "Field", name: { kind: "Name", value: "countryCode" } },
                { kind: "Field", name: { kind: "Name", value: "phone" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "lineItems" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "type" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "amount" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "amountCents" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "currency" },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "guest" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "email" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<AffirmCheckoutBuilderFieldsFragment, unknown>;
export const CheckoutAffirmPaymentFormHookFieldsFragmentDoc = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "CheckoutAffirmPaymentFormHookFields" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Checkout" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "AffirmCheckoutBuilderFields" },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "AffirmCheckoutBuilderFields" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Checkout" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "total" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "amount" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "amountCents" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "currency" },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "orders" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "quantity" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "subtotal" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "listingPrice" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "amountCents" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "listing" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      { kind: "Field", name: { kind: "Name", value: "title" } },
                      {
                        kind: "Field",
                        alias: { kind: "Name", value: "affirmImages" },
                        name: { kind: "Name", value: "images" },
                        arguments: [
                          {
                            kind: "Argument",
                            name: { kind: "Name", value: "input" },
                            value: {
                              kind: "ObjectValue",
                              fields: [
                                {
                                  kind: "ObjectField",
                                  name: { kind: "Name", value: "transform" },
                                  value: {
                                    kind: "StringValue",
                                    value: "card_square",
                                    block: false,
                                  },
                                },
                              ],
                            },
                          },
                        ],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "_id" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "source" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "paymentMethod" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "affirm" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "financingProgramSlug" },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "shippingAddress" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "name" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "streetAddress" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "extendedAddress" },
                },
                { kind: "Field", name: { kind: "Name", value: "locality" } },
                { kind: "Field", name: { kind: "Name", value: "region" } },
                { kind: "Field", name: { kind: "Name", value: "postalCode" } },
                { kind: "Field", name: { kind: "Name", value: "countryCode" } },
                { kind: "Field", name: { kind: "Name", value: "phone" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "billingAddress" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "name" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "streetAddress" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "extendedAddress" },
                },
                { kind: "Field", name: { kind: "Name", value: "locality" } },
                { kind: "Field", name: { kind: "Name", value: "region" } },
                { kind: "Field", name: { kind: "Name", value: "postalCode" } },
                { kind: "Field", name: { kind: "Name", value: "countryCode" } },
                { kind: "Field", name: { kind: "Name", value: "phone" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "lineItems" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "type" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "amount" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "amountCents" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "currency" },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "guest" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "email" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CheckoutAffirmPaymentFormHookFieldsFragment,
  unknown
>;
export const CheckoutDisplayStepHookFieldsFragmentDoc = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "CheckoutDisplayStepHookFields" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Checkout" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "paymentMethod" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "type" } },
                { kind: "Field", name: { kind: "Name", value: "isComplete" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "orders" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "shippingMethod" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "type" } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "guest" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "isComplete" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "shippingAddress" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "isComplete" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "billingAddress" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "isComplete" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CheckoutDisplayStepHookFieldsFragment, unknown>;
export const CheckoutDisplayCheckoutFieldsFragmentDoc = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "CheckoutDisplayCheckoutFields" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Checkout" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "CheckoutContinueButtonStateFields" },
          },
          {
            kind: "FragmentSpread",
            name: {
              kind: "Name",
              value: "CheckoutCardPaymentHookCheckoutFields",
            },
          },
          {
            kind: "FragmentSpread",
            name: {
              kind: "Name",
              value: "CheckoutAffirmPaymentFormHookFields",
            },
          },
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "CheckoutDisplayStepHookFields" },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "AffirmCheckoutBuilderFields" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Checkout" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "total" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "amount" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "amountCents" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "currency" },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "orders" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "quantity" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "subtotal" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "listingPrice" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "amountCents" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "listing" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      { kind: "Field", name: { kind: "Name", value: "title" } },
                      {
                        kind: "Field",
                        alias: { kind: "Name", value: "affirmImages" },
                        name: { kind: "Name", value: "images" },
                        arguments: [
                          {
                            kind: "Argument",
                            name: { kind: "Name", value: "input" },
                            value: {
                              kind: "ObjectValue",
                              fields: [
                                {
                                  kind: "ObjectField",
                                  name: { kind: "Name", value: "transform" },
                                  value: {
                                    kind: "StringValue",
                                    value: "card_square",
                                    block: false,
                                  },
                                },
                              ],
                            },
                          },
                        ],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "_id" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "source" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "paymentMethod" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "affirm" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "financingProgramSlug" },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "shippingAddress" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "name" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "streetAddress" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "extendedAddress" },
                },
                { kind: "Field", name: { kind: "Name", value: "locality" } },
                { kind: "Field", name: { kind: "Name", value: "region" } },
                { kind: "Field", name: { kind: "Name", value: "postalCode" } },
                { kind: "Field", name: { kind: "Name", value: "countryCode" } },
                { kind: "Field", name: { kind: "Name", value: "phone" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "billingAddress" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "name" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "streetAddress" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "extendedAddress" },
                },
                { kind: "Field", name: { kind: "Name", value: "locality" } },
                { kind: "Field", name: { kind: "Name", value: "region" } },
                { kind: "Field", name: { kind: "Name", value: "postalCode" } },
                { kind: "Field", name: { kind: "Name", value: "countryCode" } },
                { kind: "Field", name: { kind: "Name", value: "phone" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "lineItems" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "type" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "amount" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "amountCents" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "currency" },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "guest" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "email" } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "CheckoutContinueButtonStateFields" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Checkout" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "shippingAddress" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "isComplete" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "paymentMethod" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "isComplete" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "orders" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "cannotBeShippedToAddress" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "shippingMethod" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "type" } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "guest" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "isComplete" } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "CheckoutCardPaymentHookCheckoutFields" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Checkout" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "total" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "amount" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "amountCents" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "currency" },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "CheckoutAffirmPaymentFormHookFields" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Checkout" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "AffirmCheckoutBuilderFields" },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "CheckoutDisplayStepHookFields" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Checkout" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "paymentMethod" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "type" } },
                { kind: "Field", name: { kind: "Name", value: "isComplete" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "orders" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "shippingMethod" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "type" } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "guest" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "isComplete" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "shippingAddress" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "isComplete" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "billingAddress" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "isComplete" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CheckoutDisplayCheckoutFieldsFragment, unknown>;
export const CheckoutCardPaymentHookMeFieldsFragmentDoc = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "CheckoutCardPaymentHookMeFields" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "rql_Me" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "uuid" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "creditCards" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CheckoutCardPaymentHookMeFieldsFragment, unknown>;
export const CheckoutDisplayMeFieldsFragmentDoc = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "CheckoutDisplayMeFields" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "rql_Me" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "uuid" } },
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "CheckoutCardPaymentHookMeFields" },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "CheckoutCardPaymentHookMeFields" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "rql_Me" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "uuid" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "creditCards" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CheckoutDisplayMeFieldsFragment, unknown>;
export const CheckoutStepErrorMessageFieldsFragmentDoc = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "CheckoutStepErrorMessageFields" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Checkout" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "paymentErrorMessage" },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CheckoutStepErrorMessageFieldsFragment, unknown>;
export const CreditCardEditFormFieldsFragmentDoc = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "CreditCardEditFormFields" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "rql_Me" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "creditCards" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "brand" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "type" } },
                      { kind: "Field", name: { kind: "Name", value: "name" } },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "display" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "summary" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "expirationDate" },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "address" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "countryCode" },
                      },
                      { kind: "Field", name: { kind: "Name", value: "name" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "streetAddress" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "extendedAddress" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "locality" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "region" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "postalCode" },
                      },
                      { kind: "Field", name: { kind: "Name", value: "phone" } },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "expirationMonth" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "expirationYear" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "cardholderName" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "primaryForCheckout" },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CreditCardEditFormFieldsFragment, unknown>;
export const CreditCardWalletCreateFormFieldsFragmentDoc = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "CreditCardWalletCreateFormFields" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "rql_Me" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "shippingAddresses" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "uuid" } },
                { kind: "Field", name: { kind: "Name", value: "name" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "streetAddress" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "extendedAddress" },
                },
                { kind: "Field", name: { kind: "Name", value: "postalCode" } },
                { kind: "Field", name: { kind: "Name", value: "region" } },
                { kind: "Field", name: { kind: "Name", value: "regionName" } },
                { kind: "Field", name: { kind: "Name", value: "locality" } },
                { kind: "Field", name: { kind: "Name", value: "phone" } },
                { kind: "Field", name: { kind: "Name", value: "primary" } },
                { kind: "Field", name: { kind: "Name", value: "isComplete" } },
                { kind: "Field", name: { kind: "Name", value: "countryCode" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "country" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      { kind: "Field", name: { kind: "Name", value: "name" } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CreditCardWalletCreateFormFieldsFragment, unknown>;
export const CreditCardWalletRowSummaryFieldsFragmentDoc = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "CreditCardWalletRowSummaryFields" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "rql_Me" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "uuid" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "creditCards" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "primaryForCheckout" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "display" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "summary" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "expirationDate" },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "brand" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "type" } },
                      { kind: "Field", name: { kind: "Name", value: "name" } },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "address" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      { kind: "Field", name: { kind: "Name", value: "name" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "streetAddress" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "extendedAddress" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "locality" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "regionName" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "postalCode" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "country" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "_id" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "countryCode" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CreditCardWalletRowSummaryFieldsFragment, unknown>;
export const CreditCardWalletRowFieldsFragmentDoc = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "CreditCardWalletRowFields" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "rql_Me" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "uuid" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "creditCards" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
              ],
            },
          },
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "CreditCardWalletRowSummaryFields" },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "CreditCardWalletRowSummaryFields" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "rql_Me" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "uuid" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "creditCards" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "primaryForCheckout" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "display" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "summary" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "expirationDate" },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "brand" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "type" } },
                      { kind: "Field", name: { kind: "Name", value: "name" } },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "address" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      { kind: "Field", name: { kind: "Name", value: "name" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "streetAddress" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "extendedAddress" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "locality" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "regionName" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "postalCode" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "country" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "_id" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "countryCode" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CreditCardWalletRowFieldsFragment, unknown>;
export const CreditCardWalletLayoutFieldsFragmentDoc = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "CreditCardWalletLayoutFields" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "rql_Me" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "CreditCardWalletRowFields" },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "CreditCardWalletRowSummaryFields" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "rql_Me" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "uuid" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "creditCards" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "primaryForCheckout" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "display" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "summary" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "expirationDate" },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "brand" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "type" } },
                      { kind: "Field", name: { kind: "Name", value: "name" } },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "address" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      { kind: "Field", name: { kind: "Name", value: "name" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "streetAddress" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "extendedAddress" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "locality" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "regionName" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "postalCode" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "country" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "_id" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "countryCode" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "CreditCardWalletRowFields" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "rql_Me" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "uuid" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "creditCards" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
              ],
            },
          },
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "CreditCardWalletRowSummaryFields" },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CreditCardWalletLayoutFieldsFragment, unknown>;
export const ItemImageFieldsFragmentDoc = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "ItemImageFields" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Listing" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "title" } },
          { kind: "Field", name: { kind: "Name", value: "state" } },
          {
            kind: "Field",
            alias: { kind: "Name", value: "largeImages" },
            name: { kind: "Name", value: "images" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "type" },
                      value: {
                        kind: "StringValue",
                        value: "Product",
                        block: false,
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "scope" },
                      value: {
                        kind: "StringValue",
                        value: "photos",
                        block: false,
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "transform" },
                      value: {
                        kind: "StringValue",
                        value: "large",
                        block: false,
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "count" },
                      value: { kind: "IntValue", value: "25" },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "source" } },
              ],
            },
          },
          {
            kind: "Field",
            alias: { kind: "Name", value: "supersizeImages" },
            name: { kind: "Name", value: "images" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "type" },
                      value: {
                        kind: "StringValue",
                        value: "Product",
                        block: false,
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "scope" },
                      value: {
                        kind: "StringValue",
                        value: "photos",
                        block: false,
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "transform" },
                      value: {
                        kind: "StringValue",
                        value: "supersize",
                        block: false,
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "count" },
                      value: { kind: "IntValue", value: "25" },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "source" } },
              ],
            },
          },
          {
            kind: "Field",
            alias: { kind: "Name", value: "cardSquareImages" },
            name: { kind: "Name", value: "images" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "type" },
                      value: {
                        kind: "StringValue",
                        value: "Product",
                        block: false,
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "scope" },
                      value: {
                        kind: "StringValue",
                        value: "photos",
                        block: false,
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "transform" },
                      value: {
                        kind: "StringValue",
                        value: "card_square",
                        block: false,
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "count" },
                      value: { kind: "IntValue", value: "25" },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "source" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "video" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "youtubeVideoId" },
                },
                { kind: "Field", name: { kind: "Name", value: "empty" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "csp" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "videos" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "videoLinks" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "youtubeVideoId" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ItemImageFieldsFragment, unknown>;
export const FeaturedListingShopInfoDataFragmentDoc = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "FeaturedListingShopInfoData" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Listing" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "state" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "seller" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "uuid" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "feedbackSummary" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: {
                          kind: "Name",
                          value: "rollingRatingPercentage",
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "receivedCount" },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "shop" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "name" } },
                { kind: "Field", name: { kind: "Name", value: "slug" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "preferredSeller" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "quickShipper" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "quickResponder" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "address" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "displayLocation" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "countryCode" },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "paymentPolicy" },
                },
                { kind: "Field", name: { kind: "Name", value: "createdAt" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "orders" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "total" } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "shipping" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "localPickupOnly" },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<FeaturedListingShopInfoDataFragment, unknown>;
export const ListingShippingDisplayDataFragmentDoc = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "ListingShippingDisplayData" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Listing" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "shop" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "freeDomesticShippingRate" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "regionCode" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "freeShippingThreshold" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "amountCents" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "currency" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "ListingForBuyerShippingFields" },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "ListingForBuyerShippingFields" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Listing" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "shipping" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "freeExpeditedShipping" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "localPickupOnly" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "shippingPrices" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "shippingMethod" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "carrierCalculated" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "regional" },
                      },
                      {
                        kind: "Field",
                        name: {
                          kind: "Name",
                          value: "destinationPostalCodeNeeded",
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "postalCode" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "rate" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "amount" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "amountCents" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "currency" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "display" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ListingShippingDisplayDataFragment, unknown>;
export const TypicalNewPriceDisplayFieldsFragmentDoc = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "TypicalNewPriceDisplayFields" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Listing" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "condition" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "conditionUuid" },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "pricing" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "typicalNewPriceDisplay" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "amountDisplay" },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "originalPriceDescription" },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<TypicalNewPriceDisplayFieldsFragment, unknown>;
export const Item2PriceWithShippingDataFragmentDoc = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "Item2PriceWithShippingData" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Listing" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "state" } },
          { kind: "Field", name: { kind: "Name", value: "stateDescription" } },
          { kind: "Field", name: { kind: "Name", value: "taxIncluded" } },
          { kind: "Field", name: { kind: "Name", value: "taxIncludedHint" } },
          { kind: "Field", name: { kind: "Name", value: "listingType" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "pricing" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "originalPrice" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "display" },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "ribbon" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "display" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "reason" },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "buyerPrice" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "amountCents" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "currency" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "display" },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "shop" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "id" } },
              ],
            },
          },
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "ListingShippingDisplayData" },
          },
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "TypicalNewPriceDisplayFields" },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "ListingForBuyerShippingFields" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Listing" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "shipping" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "freeExpeditedShipping" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "localPickupOnly" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "shippingPrices" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "shippingMethod" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "carrierCalculated" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "regional" },
                      },
                      {
                        kind: "Field",
                        name: {
                          kind: "Name",
                          value: "destinationPostalCodeNeeded",
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "postalCode" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "rate" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "amount" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "amountCents" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "currency" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "display" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "ListingShippingDisplayData" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Listing" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "shop" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "freeDomesticShippingRate" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "regionCode" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "freeShippingThreshold" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "amountCents" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "currency" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "ListingForBuyerShippingFields" },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "TypicalNewPriceDisplayFields" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Listing" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "condition" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "conditionUuid" },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "pricing" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "typicalNewPriceDisplay" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "amountDisplay" },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "originalPriceDescription" },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<Item2PriceWithShippingDataFragment, unknown>;
export const CompareOtherSellersLinkDataFragmentDoc = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "CompareOtherSellersLinkData" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Listing" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "csp" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "id" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "webLink" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "href" } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CompareOtherSellersLinkDataFragment, unknown>;
export const ListingAffirmCalloutFieldsFragmentDoc = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "ListingAffirmCalloutFields" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Listing" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "estimatedMonthlyPaymentPlan" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "financingProgramSlug" },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "pricing" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "buyerPrice" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "display" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "amountCents" },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "shipping" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "shippingPrices" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "shippingMethod" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "rate" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "amountCents" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ListingAffirmCalloutFieldsFragment, unknown>;
export const ListingKlarnaCalloutFieldsFragmentDoc = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "ListingKlarnaCalloutFields" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Listing" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "pricing" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "buyerPrice" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "amountCents" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "currency" },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "shipping" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "shippingPrices" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "shippingMethod" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "rate" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "amountCents" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ListingKlarnaCalloutFieldsFragment, unknown>;
export const ListingFinancingCalloutFieldsFragmentDoc = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "ListingFinancingCalloutFields" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Listing" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "acceptedPaymentMethods" },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "pricing" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "buyerPrice" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "amountCents" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "amount" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "currency" },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "ListingAffirmCalloutFields" },
          },
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "ListingKlarnaCalloutFields" },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "ListingAffirmCalloutFields" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Listing" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "estimatedMonthlyPaymentPlan" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "financingProgramSlug" },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "pricing" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "buyerPrice" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "display" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "amountCents" },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "shipping" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "shippingPrices" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "shippingMethod" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "rate" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "amountCents" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "ListingKlarnaCalloutFields" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Listing" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "pricing" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "buyerPrice" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "amountCents" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "currency" },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "shipping" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "shippingPrices" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "shippingMethod" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "rate" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "amountCents" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ListingFinancingCalloutFieldsFragment, unknown>;
export const Item2StatsDataFragmentDoc = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "Item2StatsData" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Listing" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "hasInventory" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "condition" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "conditionUuid" },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "publishedAt" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "seconds" } },
              ],
            },
          },
          { kind: "Field", name: { kind: "Name", value: "listingType" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "counts" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "viewsDisplay" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "offersDisplay" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "watchersDisplay" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "downloadCountDisplay" },
                },
              ],
            },
          },
          { kind: "Field", name: { kind: "Name", value: "offersEnabled" } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<Item2StatsDataFragment, unknown>;
export const ListingGooglePayButtonFieldsFragmentDoc = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "ListingGooglePayButtonFields" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Listing" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "expressPay" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "estimatedTotal" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "amount" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "currency" },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "googlePay" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "merchantId" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "merchantName" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "gatewayMerchantId" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "cardNetworks" },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ListingGooglePayButtonFieldsFragment, unknown>;
export const BuyItNowExpressPayOptionsFieldsFragmentDoc = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "BuyItNowExpressPayOptionsFields" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Listing" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "currency" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "adyenCheckoutPaymentMethodsConfig" },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "expressPay" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "checkoutBundlingId" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "paypal" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "currency" },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "pricing" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "buyerPrice" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "amount" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "amountCents" },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "ListingGooglePayButtonFields" },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "ListingGooglePayButtonFields" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Listing" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "expressPay" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "estimatedTotal" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "amount" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "currency" },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "googlePay" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "merchantId" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "merchantName" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "gatewayMerchantId" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "cardNetworks" },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<BuyItNowExpressPayOptionsFieldsFragment, unknown>;
export const BuyItNowPaymentOptionsFieldsFragmentDoc = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "BuyItNowPaymentOptionsFields" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Listing" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "acceptedPaymentMethods" },
          },
          { kind: "Field", name: { kind: "Name", value: "sellerId" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "shop" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "address" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "countryCode" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "country" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "_id" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "name" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "shipping" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "localPickupOnly" },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "pricing" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "buyerPrice" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "amount" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "amountCents" },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "BuyItNowExpressPayOptionsFields" },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "ListingGooglePayButtonFields" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Listing" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "expressPay" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "estimatedTotal" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "amount" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "currency" },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "googlePay" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "merchantId" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "merchantName" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "gatewayMerchantId" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "cardNetworks" },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "BuyItNowExpressPayOptionsFields" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Listing" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "currency" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "adyenCheckoutPaymentMethodsConfig" },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "expressPay" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "checkoutBundlingId" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "paypal" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "currency" },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "pricing" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "buyerPrice" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "amount" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "amountCents" },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "ListingGooglePayButtonFields" },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<BuyItNowPaymentOptionsFieldsFragment, unknown>;
export const Item2CPODataFragmentDoc = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "Item2CPOData" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Listing" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "brandSlug" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "certifiedPreOwned" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "title" } },
                { kind: "Field", name: { kind: "Name", value: "description" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "tooltipDescription" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "badgeIconUrl" },
                },
                { kind: "Field", name: { kind: "Name", value: "brandName" } },
                { kind: "Field", name: { kind: "Name", value: "pageUrl" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "lightModeBrandIconUrl" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "darkModeBrandIconUrl" },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<Item2CPODataFragment, unknown>;
export const BuyItNowButtonFieldsFragmentDoc = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "BuyItNowButtonFields" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Listing" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "shipping" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "localPickupOnly" },
                },
              ],
            },
          },
          { kind: "Field", name: { kind: "Name", value: "usOutlet" } },
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "BuyItNowPaymentOptionsFields" },
          },
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "Item2CPOData" },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "ListingGooglePayButtonFields" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Listing" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "expressPay" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "estimatedTotal" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "amount" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "currency" },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "googlePay" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "merchantId" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "merchantName" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "gatewayMerchantId" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "cardNetworks" },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "BuyItNowExpressPayOptionsFields" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Listing" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "currency" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "adyenCheckoutPaymentMethodsConfig" },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "expressPay" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "checkoutBundlingId" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "paypal" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "currency" },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "pricing" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "buyerPrice" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "amount" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "amountCents" },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "ListingGooglePayButtonFields" },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "BuyItNowPaymentOptionsFields" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Listing" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "acceptedPaymentMethods" },
          },
          { kind: "Field", name: { kind: "Name", value: "sellerId" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "shop" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "address" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "countryCode" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "country" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "_id" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "name" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "shipping" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "localPickupOnly" },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "pricing" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "buyerPrice" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "amount" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "amountCents" },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "BuyItNowExpressPayOptionsFields" },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "Item2CPOData" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Listing" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "brandSlug" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "certifiedPreOwned" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "title" } },
                { kind: "Field", name: { kind: "Name", value: "description" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "tooltipDescription" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "badgeIconUrl" },
                },
                { kind: "Field", name: { kind: "Name", value: "brandName" } },
                { kind: "Field", name: { kind: "Name", value: "pageUrl" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "lightModeBrandIconUrl" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "darkModeBrandIconUrl" },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<BuyItNowButtonFieldsFragment, unknown>;
export const DigitalDetailsFieldsFragmentDoc = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "DigitalDetailsFields" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Listing" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "listingType" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "digitalDetails" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "variants" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "downloadText" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "fileUrl" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "fileSize" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "operatingSystems" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "fileTypes" },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "supportedFormats" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "operatingSystem" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "fileTypes" },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<DigitalDetailsFieldsFragment, unknown>;
export const Item2PricingDataFragmentDoc = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "Item2PricingData" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Listing" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "offersEnabled" } },
          { kind: "Field", name: { kind: "Name", value: "inventory" } },
          { kind: "Field", name: { kind: "Name", value: "hasInventory" } },
          { kind: "Field", name: { kind: "Name", value: "maxBuyerQuantity" } },
          { kind: "Field", name: { kind: "Name", value: "make" } },
          { kind: "Field", name: { kind: "Name", value: "model" } },
          { kind: "Field", name: { kind: "Name", value: "state" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "condition" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "conditionSlug" },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "shop" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "address" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "countryCode" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "country" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "_id" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "name" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "AddToCartButtonFields" },
          },
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "Item2PriceWithShippingData" },
          },
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "CompareOtherSellersLinkData" },
          },
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "ListingFinancingCalloutFields" },
          },
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "Item2StatsData" },
          },
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "BuyItNowButtonFields" },
          },
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "ListingCreateOfferButtonData" },
          },
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "DigitalDetailsFields" },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "ListingForBuyerShippingFields" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Listing" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "shipping" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "freeExpeditedShipping" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "localPickupOnly" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "shippingPrices" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "shippingMethod" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "carrierCalculated" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "regional" },
                      },
                      {
                        kind: "Field",
                        name: {
                          kind: "Name",
                          value: "destinationPostalCodeNeeded",
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "postalCode" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "rate" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "amount" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "amountCents" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "currency" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "display" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "ListingShippingDisplayData" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Listing" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "shop" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "freeDomesticShippingRate" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "regionCode" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "freeShippingThreshold" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "amountCents" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "currency" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "ListingForBuyerShippingFields" },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "TypicalNewPriceDisplayFields" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Listing" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "condition" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "conditionUuid" },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "pricing" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "typicalNewPriceDisplay" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "amountDisplay" },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "originalPriceDescription" },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "ListingAffirmCalloutFields" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Listing" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "estimatedMonthlyPaymentPlan" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "financingProgramSlug" },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "pricing" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "buyerPrice" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "display" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "amountCents" },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "shipping" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "shippingPrices" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "shippingMethod" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "rate" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "amountCents" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "ListingKlarnaCalloutFields" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Listing" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "pricing" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "buyerPrice" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "amountCents" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "currency" },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "shipping" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "shippingPrices" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "shippingMethod" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "rate" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "amountCents" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "ListingGooglePayButtonFields" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Listing" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "expressPay" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "estimatedTotal" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "amount" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "currency" },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "googlePay" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "merchantId" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "merchantName" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "gatewayMerchantId" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "cardNetworks" },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "BuyItNowExpressPayOptionsFields" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Listing" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "currency" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "adyenCheckoutPaymentMethodsConfig" },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "expressPay" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "checkoutBundlingId" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "paypal" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "currency" },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "pricing" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "buyerPrice" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "amount" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "amountCents" },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "ListingGooglePayButtonFields" },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "BuyItNowPaymentOptionsFields" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Listing" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "acceptedPaymentMethods" },
          },
          { kind: "Field", name: { kind: "Name", value: "sellerId" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "shop" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "address" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "countryCode" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "country" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "_id" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "name" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "shipping" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "localPickupOnly" },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "pricing" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "buyerPrice" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "amount" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "amountCents" },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "BuyItNowExpressPayOptionsFields" },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "Item2CPOData" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Listing" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "brandSlug" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "certifiedPreOwned" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "title" } },
                { kind: "Field", name: { kind: "Name", value: "description" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "tooltipDescription" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "badgeIconUrl" },
                },
                { kind: "Field", name: { kind: "Name", value: "brandName" } },
                { kind: "Field", name: { kind: "Name", value: "pageUrl" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "lightModeBrandIconUrl" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "darkModeBrandIconUrl" },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "mParticleListingFields" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Listing" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "title" } },
          { kind: "Field", name: { kind: "Name", value: "brandSlug" } },
          { kind: "Field", name: { kind: "Name", value: "categoryRootUuid" } },
          { kind: "Field", name: { kind: "Name", value: "make" } },
          { kind: "Field", name: { kind: "Name", value: "categoryUuids" } },
          { kind: "Field", name: { kind: "Name", value: "state" } },
          { kind: "Field", name: { kind: "Name", value: "listingType" } },
          { kind: "Field", name: { kind: "Name", value: "bumpEligible" } },
          { kind: "Field", name: { kind: "Name", value: "shopId" } },
          { kind: "Field", name: { kind: "Name", value: "inventory" } },
          { kind: "Field", name: { kind: "Name", value: "soldAsIs" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "acceptedPaymentMethods" },
          },
          { kind: "Field", name: { kind: "Name", value: "currency" } },
          { kind: "Field", name: { kind: "Name", value: "usOutlet" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "condition" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "conditionUuid" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "conditionSlug" },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "categories" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "slug" } },
                { kind: "Field", name: { kind: "Name", value: "rootSlug" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "csp" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "slug" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "brand" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      { kind: "Field", name: { kind: "Name", value: "slug" } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "pricing" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "buyerPrice" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "amount" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "currency" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "amountCents" },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "publishedAt" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "seconds" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "sale" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "code" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "buyerIneligibilityReason" },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "shipping" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "shippingPrices" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "shippingMethod" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "carrierCalculated" },
                      },
                      {
                        kind: "Field",
                        name: {
                          kind: "Name",
                          value: "destinationPostalCodeNeeded",
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "rate" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "amount" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "amountCents" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "currency" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "display" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "freeExpeditedShipping" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "localPickupOnly" },
                },
                { kind: "Field", name: { kind: "Name", value: "localPickup" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "certifiedPreOwned" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "title" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "badgeIconUrl" },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "shop" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "address" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "countryCode" },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "returnPolicy" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "newReturnWindowDays" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "usedReturnWindowDays" },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "AddToCartButtonFields" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Listing" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "sellerId" } },
          { kind: "Field", name: { kind: "Name", value: "listingType" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "pricing" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "buyerPrice" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "amount" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "amountCents" },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "preorderInfo" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "onPreorder" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "estimatedShipDate" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "seconds" },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "Item2PriceWithShippingData" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Listing" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "state" } },
          { kind: "Field", name: { kind: "Name", value: "stateDescription" } },
          { kind: "Field", name: { kind: "Name", value: "taxIncluded" } },
          { kind: "Field", name: { kind: "Name", value: "taxIncludedHint" } },
          { kind: "Field", name: { kind: "Name", value: "listingType" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "pricing" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "originalPrice" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "display" },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "ribbon" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "display" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "reason" },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "buyerPrice" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "amountCents" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "currency" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "display" },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "shop" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "id" } },
              ],
            },
          },
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "ListingShippingDisplayData" },
          },
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "TypicalNewPriceDisplayFields" },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "CompareOtherSellersLinkData" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Listing" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "csp" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "id" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "webLink" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "href" } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "ListingFinancingCalloutFields" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Listing" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "acceptedPaymentMethods" },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "pricing" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "buyerPrice" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "amountCents" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "amount" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "currency" },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "ListingAffirmCalloutFields" },
          },
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "ListingKlarnaCalloutFields" },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "Item2StatsData" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Listing" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "hasInventory" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "condition" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "conditionUuid" },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "publishedAt" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "seconds" } },
              ],
            },
          },
          { kind: "Field", name: { kind: "Name", value: "listingType" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "counts" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "viewsDisplay" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "offersDisplay" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "watchersDisplay" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "downloadCountDisplay" },
                },
              ],
            },
          },
          { kind: "Field", name: { kind: "Name", value: "offersEnabled" } },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "BuyItNowButtonFields" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Listing" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "shipping" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "localPickupOnly" },
                },
              ],
            },
          },
          { kind: "Field", name: { kind: "Name", value: "usOutlet" } },
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "BuyItNowPaymentOptionsFields" },
          },
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "Item2CPOData" },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "ListingCreateOfferButtonData" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Listing" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "state" } },
          { kind: "Field", name: { kind: "Name", value: "offersEnabled" } },
          { kind: "Field", name: { kind: "Name", value: "listingType" } },
          { kind: "Field", name: { kind: "Name", value: "sellerId" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "isBuyerOfferEligible" },
          },
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "mParticleListingFields" },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "DigitalDetailsFields" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Listing" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "listingType" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "digitalDetails" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "variants" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "downloadText" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "fileUrl" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "fileSize" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "operatingSystems" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "fileTypes" },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "supportedFormats" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "operatingSystem" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "fileTypes" },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<Item2PricingDataFragment, unknown>;
export const SignalsDataFragmentDoc = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "SignalsData" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Listing" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "state" } },
          { kind: "Field", name: { kind: "Name", value: "usOutlet" } },
          { kind: "Field", name: { kind: "Name", value: "currency" } },
          {
            kind: "Field",
            alias: { kind: "Name", value: "signals" },
            name: { kind: "Name", value: "signals" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "groups" },
                      value: {
                        kind: "ListValue",
                        values: [
                          { kind: "EnumValue", value: "ITEM_PAGE_WHY_TO_BUY" },
                          { kind: "EnumValue", value: "ITEM_PAGE_NUDGES" },
                          { kind: "EnumValue", value: "ITEM_PAGE_WHY_REVERB" },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "name" } },
                { kind: "Field", name: { kind: "Name", value: "group" } },
                { kind: "Field", name: { kind: "Name", value: "title" } },
                { kind: "Field", name: { kind: "Name", value: "subtitle" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "link" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "text" } },
                      { kind: "Field", name: { kind: "Name", value: "url" } },
                    ],
                  },
                },
                { kind: "Field", name: { kind: "Name", value: "icon" } },
                { kind: "Field", name: { kind: "Name", value: "iconDark" } },
                { kind: "Field", name: { kind: "Name", value: "tooltipText" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<SignalsDataFragment, unknown>;
export const Item2ReturnPolicyDataFragmentDoc = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "Item2ReturnPolicyData" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Listing" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "condition" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "conditionSlug" },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "shop" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "returnPolicy" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "newReturnWindowDays" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "usedReturnWindowDays" },
                      },
                    ],
                  },
                },
              ],
            },
          },
          { kind: "Field", name: { kind: "Name", value: "soldAsIs" } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<Item2ReturnPolicyDataFragment, unknown>;
export const FeaturedListingModuleDataFragmentDoc = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "FeaturedListingModuleData" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Listing" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "title" } },
          { kind: "Field", name: { kind: "Name", value: "slug" } },
          { kind: "Field", name: { kind: "Name", value: "description" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "condition" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "displayName" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "conditionSlug" },
                },
              ],
            },
          },
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "ItemImageFields" },
          },
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "FeaturedListingShopInfoData" },
          },
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "Item2PricingData" },
          },
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "SignalsData" },
          },
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "Item2ReturnPolicyData" },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "AddToCartButtonFields" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Listing" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "sellerId" } },
          { kind: "Field", name: { kind: "Name", value: "listingType" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "pricing" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "buyerPrice" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "amount" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "amountCents" },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "preorderInfo" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "onPreorder" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "estimatedShipDate" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "seconds" },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "ListingForBuyerShippingFields" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Listing" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "shipping" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "freeExpeditedShipping" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "localPickupOnly" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "shippingPrices" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "shippingMethod" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "carrierCalculated" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "regional" },
                      },
                      {
                        kind: "Field",
                        name: {
                          kind: "Name",
                          value: "destinationPostalCodeNeeded",
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "postalCode" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "rate" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "amount" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "amountCents" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "currency" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "display" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "ListingShippingDisplayData" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Listing" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "shop" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "freeDomesticShippingRate" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "regionCode" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "freeShippingThreshold" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "amountCents" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "currency" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "ListingForBuyerShippingFields" },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "TypicalNewPriceDisplayFields" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Listing" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "condition" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "conditionUuid" },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "pricing" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "typicalNewPriceDisplay" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "amountDisplay" },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "originalPriceDescription" },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "Item2PriceWithShippingData" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Listing" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "state" } },
          { kind: "Field", name: { kind: "Name", value: "stateDescription" } },
          { kind: "Field", name: { kind: "Name", value: "taxIncluded" } },
          { kind: "Field", name: { kind: "Name", value: "taxIncludedHint" } },
          { kind: "Field", name: { kind: "Name", value: "listingType" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "pricing" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "originalPrice" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "display" },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "ribbon" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "display" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "reason" },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "buyerPrice" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "amountCents" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "currency" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "display" },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "shop" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "id" } },
              ],
            },
          },
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "ListingShippingDisplayData" },
          },
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "TypicalNewPriceDisplayFields" },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "CompareOtherSellersLinkData" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Listing" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "csp" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "id" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "webLink" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "href" } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "ListingAffirmCalloutFields" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Listing" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "estimatedMonthlyPaymentPlan" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "financingProgramSlug" },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "pricing" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "buyerPrice" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "display" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "amountCents" },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "shipping" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "shippingPrices" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "shippingMethod" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "rate" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "amountCents" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "ListingKlarnaCalloutFields" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Listing" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "pricing" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "buyerPrice" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "amountCents" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "currency" },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "shipping" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "shippingPrices" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "shippingMethod" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "rate" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "amountCents" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "ListingFinancingCalloutFields" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Listing" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "acceptedPaymentMethods" },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "pricing" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "buyerPrice" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "amountCents" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "amount" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "currency" },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "ListingAffirmCalloutFields" },
          },
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "ListingKlarnaCalloutFields" },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "Item2StatsData" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Listing" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "hasInventory" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "condition" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "conditionUuid" },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "publishedAt" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "seconds" } },
              ],
            },
          },
          { kind: "Field", name: { kind: "Name", value: "listingType" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "counts" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "viewsDisplay" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "offersDisplay" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "watchersDisplay" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "downloadCountDisplay" },
                },
              ],
            },
          },
          { kind: "Field", name: { kind: "Name", value: "offersEnabled" } },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "ListingGooglePayButtonFields" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Listing" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "expressPay" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "estimatedTotal" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "amount" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "currency" },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "googlePay" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "merchantId" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "merchantName" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "gatewayMerchantId" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "cardNetworks" },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "BuyItNowExpressPayOptionsFields" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Listing" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "currency" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "adyenCheckoutPaymentMethodsConfig" },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "expressPay" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "checkoutBundlingId" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "paypal" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "currency" },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "pricing" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "buyerPrice" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "amount" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "amountCents" },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "ListingGooglePayButtonFields" },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "BuyItNowPaymentOptionsFields" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Listing" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "acceptedPaymentMethods" },
          },
          { kind: "Field", name: { kind: "Name", value: "sellerId" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "shop" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "address" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "countryCode" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "country" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "_id" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "name" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "shipping" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "localPickupOnly" },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "pricing" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "buyerPrice" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "amount" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "amountCents" },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "BuyItNowExpressPayOptionsFields" },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "Item2CPOData" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Listing" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "brandSlug" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "certifiedPreOwned" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "title" } },
                { kind: "Field", name: { kind: "Name", value: "description" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "tooltipDescription" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "badgeIconUrl" },
                },
                { kind: "Field", name: { kind: "Name", value: "brandName" } },
                { kind: "Field", name: { kind: "Name", value: "pageUrl" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "lightModeBrandIconUrl" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "darkModeBrandIconUrl" },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "BuyItNowButtonFields" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Listing" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "shipping" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "localPickupOnly" },
                },
              ],
            },
          },
          { kind: "Field", name: { kind: "Name", value: "usOutlet" } },
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "BuyItNowPaymentOptionsFields" },
          },
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "Item2CPOData" },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "mParticleListingFields" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Listing" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "title" } },
          { kind: "Field", name: { kind: "Name", value: "brandSlug" } },
          { kind: "Field", name: { kind: "Name", value: "categoryRootUuid" } },
          { kind: "Field", name: { kind: "Name", value: "make" } },
          { kind: "Field", name: { kind: "Name", value: "categoryUuids" } },
          { kind: "Field", name: { kind: "Name", value: "state" } },
          { kind: "Field", name: { kind: "Name", value: "listingType" } },
          { kind: "Field", name: { kind: "Name", value: "bumpEligible" } },
          { kind: "Field", name: { kind: "Name", value: "shopId" } },
          { kind: "Field", name: { kind: "Name", value: "inventory" } },
          { kind: "Field", name: { kind: "Name", value: "soldAsIs" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "acceptedPaymentMethods" },
          },
          { kind: "Field", name: { kind: "Name", value: "currency" } },
          { kind: "Field", name: { kind: "Name", value: "usOutlet" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "condition" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "conditionUuid" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "conditionSlug" },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "categories" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "slug" } },
                { kind: "Field", name: { kind: "Name", value: "rootSlug" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "csp" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "slug" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "brand" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      { kind: "Field", name: { kind: "Name", value: "slug" } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "pricing" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "buyerPrice" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "amount" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "currency" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "amountCents" },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "publishedAt" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "seconds" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "sale" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "code" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "buyerIneligibilityReason" },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "shipping" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "shippingPrices" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "shippingMethod" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "carrierCalculated" },
                      },
                      {
                        kind: "Field",
                        name: {
                          kind: "Name",
                          value: "destinationPostalCodeNeeded",
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "rate" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "amount" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "amountCents" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "currency" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "display" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "freeExpeditedShipping" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "localPickupOnly" },
                },
                { kind: "Field", name: { kind: "Name", value: "localPickup" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "certifiedPreOwned" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "title" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "badgeIconUrl" },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "shop" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "address" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "countryCode" },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "returnPolicy" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "newReturnWindowDays" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "usedReturnWindowDays" },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "ListingCreateOfferButtonData" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Listing" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "state" } },
          { kind: "Field", name: { kind: "Name", value: "offersEnabled" } },
          { kind: "Field", name: { kind: "Name", value: "listingType" } },
          { kind: "Field", name: { kind: "Name", value: "sellerId" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "isBuyerOfferEligible" },
          },
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "mParticleListingFields" },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "DigitalDetailsFields" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Listing" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "listingType" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "digitalDetails" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "variants" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "downloadText" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "fileUrl" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "fileSize" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "operatingSystems" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "fileTypes" },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "supportedFormats" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "operatingSystem" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "fileTypes" },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "ItemImageFields" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Listing" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "title" } },
          { kind: "Field", name: { kind: "Name", value: "state" } },
          {
            kind: "Field",
            alias: { kind: "Name", value: "largeImages" },
            name: { kind: "Name", value: "images" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "type" },
                      value: {
                        kind: "StringValue",
                        value: "Product",
                        block: false,
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "scope" },
                      value: {
                        kind: "StringValue",
                        value: "photos",
                        block: false,
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "transform" },
                      value: {
                        kind: "StringValue",
                        value: "large",
                        block: false,
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "count" },
                      value: { kind: "IntValue", value: "25" },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "source" } },
              ],
            },
          },
          {
            kind: "Field",
            alias: { kind: "Name", value: "supersizeImages" },
            name: { kind: "Name", value: "images" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "type" },
                      value: {
                        kind: "StringValue",
                        value: "Product",
                        block: false,
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "scope" },
                      value: {
                        kind: "StringValue",
                        value: "photos",
                        block: false,
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "transform" },
                      value: {
                        kind: "StringValue",
                        value: "supersize",
                        block: false,
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "count" },
                      value: { kind: "IntValue", value: "25" },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "source" } },
              ],
            },
          },
          {
            kind: "Field",
            alias: { kind: "Name", value: "cardSquareImages" },
            name: { kind: "Name", value: "images" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "type" },
                      value: {
                        kind: "StringValue",
                        value: "Product",
                        block: false,
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "scope" },
                      value: {
                        kind: "StringValue",
                        value: "photos",
                        block: false,
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "transform" },
                      value: {
                        kind: "StringValue",
                        value: "card_square",
                        block: false,
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "count" },
                      value: { kind: "IntValue", value: "25" },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "source" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "video" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "youtubeVideoId" },
                },
                { kind: "Field", name: { kind: "Name", value: "empty" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "csp" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "videos" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "videoLinks" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "youtubeVideoId" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "FeaturedListingShopInfoData" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Listing" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "state" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "seller" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "uuid" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "feedbackSummary" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: {
                          kind: "Name",
                          value: "rollingRatingPercentage",
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "receivedCount" },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "shop" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "name" } },
                { kind: "Field", name: { kind: "Name", value: "slug" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "preferredSeller" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "quickShipper" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "quickResponder" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "address" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "displayLocation" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "countryCode" },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "paymentPolicy" },
                },
                { kind: "Field", name: { kind: "Name", value: "createdAt" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "orders" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "total" } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "shipping" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "localPickupOnly" },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "Item2PricingData" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Listing" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "offersEnabled" } },
          { kind: "Field", name: { kind: "Name", value: "inventory" } },
          { kind: "Field", name: { kind: "Name", value: "hasInventory" } },
          { kind: "Field", name: { kind: "Name", value: "maxBuyerQuantity" } },
          { kind: "Field", name: { kind: "Name", value: "make" } },
          { kind: "Field", name: { kind: "Name", value: "model" } },
          { kind: "Field", name: { kind: "Name", value: "state" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "condition" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "conditionSlug" },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "shop" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "address" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "countryCode" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "country" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "_id" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "name" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "AddToCartButtonFields" },
          },
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "Item2PriceWithShippingData" },
          },
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "CompareOtherSellersLinkData" },
          },
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "ListingFinancingCalloutFields" },
          },
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "Item2StatsData" },
          },
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "BuyItNowButtonFields" },
          },
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "ListingCreateOfferButtonData" },
          },
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "DigitalDetailsFields" },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "SignalsData" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Listing" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "state" } },
          { kind: "Field", name: { kind: "Name", value: "usOutlet" } },
          { kind: "Field", name: { kind: "Name", value: "currency" } },
          {
            kind: "Field",
            alias: { kind: "Name", value: "signals" },
            name: { kind: "Name", value: "signals" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "groups" },
                      value: {
                        kind: "ListValue",
                        values: [
                          { kind: "EnumValue", value: "ITEM_PAGE_WHY_TO_BUY" },
                          { kind: "EnumValue", value: "ITEM_PAGE_NUDGES" },
                          { kind: "EnumValue", value: "ITEM_PAGE_WHY_REVERB" },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "name" } },
                { kind: "Field", name: { kind: "Name", value: "group" } },
                { kind: "Field", name: { kind: "Name", value: "title" } },
                { kind: "Field", name: { kind: "Name", value: "subtitle" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "link" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "text" } },
                      { kind: "Field", name: { kind: "Name", value: "url" } },
                    ],
                  },
                },
                { kind: "Field", name: { kind: "Name", value: "icon" } },
                { kind: "Field", name: { kind: "Name", value: "iconDark" } },
                { kind: "Field", name: { kind: "Name", value: "tooltipText" } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "Item2ReturnPolicyData" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Listing" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "condition" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "conditionSlug" },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "shop" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "returnPolicy" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "newReturnWindowDays" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "usedReturnWindowDays" },
                      },
                    ],
                  },
                },
              ],
            },
          },
          { kind: "Field", name: { kind: "Name", value: "soldAsIs" } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<FeaturedListingModuleDataFragment, unknown>;
export const FeaturedListingWithPriceFragmentDoc = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "FeaturedListingWithPrice" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Listing" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "price" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "amount" } },
                { kind: "Field", name: { kind: "Name", value: "amountCents" } },
              ],
            },
          },
          { kind: "Field", name: { kind: "Name", value: "bumped" } },
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "FeaturedListingModuleData" },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "ItemImageFields" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Listing" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "title" } },
          { kind: "Field", name: { kind: "Name", value: "state" } },
          {
            kind: "Field",
            alias: { kind: "Name", value: "largeImages" },
            name: { kind: "Name", value: "images" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "type" },
                      value: {
                        kind: "StringValue",
                        value: "Product",
                        block: false,
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "scope" },
                      value: {
                        kind: "StringValue",
                        value: "photos",
                        block: false,
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "transform" },
                      value: {
                        kind: "StringValue",
                        value: "large",
                        block: false,
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "count" },
                      value: { kind: "IntValue", value: "25" },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "source" } },
              ],
            },
          },
          {
            kind: "Field",
            alias: { kind: "Name", value: "supersizeImages" },
            name: { kind: "Name", value: "images" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "type" },
                      value: {
                        kind: "StringValue",
                        value: "Product",
                        block: false,
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "scope" },
                      value: {
                        kind: "StringValue",
                        value: "photos",
                        block: false,
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "transform" },
                      value: {
                        kind: "StringValue",
                        value: "supersize",
                        block: false,
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "count" },
                      value: { kind: "IntValue", value: "25" },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "source" } },
              ],
            },
          },
          {
            kind: "Field",
            alias: { kind: "Name", value: "cardSquareImages" },
            name: { kind: "Name", value: "images" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "type" },
                      value: {
                        kind: "StringValue",
                        value: "Product",
                        block: false,
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "scope" },
                      value: {
                        kind: "StringValue",
                        value: "photos",
                        block: false,
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "transform" },
                      value: {
                        kind: "StringValue",
                        value: "card_square",
                        block: false,
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "count" },
                      value: { kind: "IntValue", value: "25" },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "source" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "video" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "youtubeVideoId" },
                },
                { kind: "Field", name: { kind: "Name", value: "empty" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "csp" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "videos" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "videoLinks" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "youtubeVideoId" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "FeaturedListingShopInfoData" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Listing" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "state" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "seller" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "uuid" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "feedbackSummary" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: {
                          kind: "Name",
                          value: "rollingRatingPercentage",
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "receivedCount" },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "shop" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "name" } },
                { kind: "Field", name: { kind: "Name", value: "slug" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "preferredSeller" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "quickShipper" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "quickResponder" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "address" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "displayLocation" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "countryCode" },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "paymentPolicy" },
                },
                { kind: "Field", name: { kind: "Name", value: "createdAt" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "orders" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "total" } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "shipping" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "localPickupOnly" },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "AddToCartButtonFields" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Listing" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "sellerId" } },
          { kind: "Field", name: { kind: "Name", value: "listingType" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "pricing" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "buyerPrice" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "amount" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "amountCents" },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "preorderInfo" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "onPreorder" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "estimatedShipDate" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "seconds" },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "ListingForBuyerShippingFields" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Listing" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "shipping" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "freeExpeditedShipping" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "localPickupOnly" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "shippingPrices" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "shippingMethod" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "carrierCalculated" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "regional" },
                      },
                      {
                        kind: "Field",
                        name: {
                          kind: "Name",
                          value: "destinationPostalCodeNeeded",
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "postalCode" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "rate" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "amount" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "amountCents" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "currency" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "display" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "ListingShippingDisplayData" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Listing" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "shop" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "freeDomesticShippingRate" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "regionCode" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "freeShippingThreshold" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "amountCents" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "currency" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "ListingForBuyerShippingFields" },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "TypicalNewPriceDisplayFields" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Listing" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "condition" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "conditionUuid" },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "pricing" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "typicalNewPriceDisplay" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "amountDisplay" },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "originalPriceDescription" },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "Item2PriceWithShippingData" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Listing" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "state" } },
          { kind: "Field", name: { kind: "Name", value: "stateDescription" } },
          { kind: "Field", name: { kind: "Name", value: "taxIncluded" } },
          { kind: "Field", name: { kind: "Name", value: "taxIncludedHint" } },
          { kind: "Field", name: { kind: "Name", value: "listingType" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "pricing" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "originalPrice" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "display" },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "ribbon" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "display" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "reason" },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "buyerPrice" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "amountCents" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "currency" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "display" },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "shop" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "id" } },
              ],
            },
          },
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "ListingShippingDisplayData" },
          },
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "TypicalNewPriceDisplayFields" },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "CompareOtherSellersLinkData" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Listing" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "csp" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "id" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "webLink" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "href" } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "ListingAffirmCalloutFields" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Listing" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "estimatedMonthlyPaymentPlan" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "financingProgramSlug" },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "pricing" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "buyerPrice" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "display" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "amountCents" },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "shipping" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "shippingPrices" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "shippingMethod" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "rate" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "amountCents" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "ListingKlarnaCalloutFields" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Listing" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "pricing" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "buyerPrice" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "amountCents" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "currency" },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "shipping" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "shippingPrices" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "shippingMethod" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "rate" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "amountCents" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "ListingFinancingCalloutFields" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Listing" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "acceptedPaymentMethods" },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "pricing" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "buyerPrice" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "amountCents" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "amount" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "currency" },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "ListingAffirmCalloutFields" },
          },
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "ListingKlarnaCalloutFields" },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "Item2StatsData" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Listing" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "hasInventory" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "condition" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "conditionUuid" },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "publishedAt" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "seconds" } },
              ],
            },
          },
          { kind: "Field", name: { kind: "Name", value: "listingType" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "counts" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "viewsDisplay" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "offersDisplay" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "watchersDisplay" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "downloadCountDisplay" },
                },
              ],
            },
          },
          { kind: "Field", name: { kind: "Name", value: "offersEnabled" } },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "ListingGooglePayButtonFields" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Listing" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "expressPay" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "estimatedTotal" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "amount" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "currency" },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "googlePay" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "merchantId" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "merchantName" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "gatewayMerchantId" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "cardNetworks" },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "BuyItNowExpressPayOptionsFields" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Listing" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "currency" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "adyenCheckoutPaymentMethodsConfig" },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "expressPay" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "checkoutBundlingId" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "paypal" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "currency" },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "pricing" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "buyerPrice" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "amount" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "amountCents" },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "ListingGooglePayButtonFields" },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "BuyItNowPaymentOptionsFields" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Listing" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "acceptedPaymentMethods" },
          },
          { kind: "Field", name: { kind: "Name", value: "sellerId" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "shop" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "address" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "countryCode" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "country" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "_id" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "name" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "shipping" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "localPickupOnly" },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "pricing" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "buyerPrice" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "amount" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "amountCents" },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "BuyItNowExpressPayOptionsFields" },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "Item2CPOData" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Listing" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "brandSlug" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "certifiedPreOwned" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "title" } },
                { kind: "Field", name: { kind: "Name", value: "description" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "tooltipDescription" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "badgeIconUrl" },
                },
                { kind: "Field", name: { kind: "Name", value: "brandName" } },
                { kind: "Field", name: { kind: "Name", value: "pageUrl" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "lightModeBrandIconUrl" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "darkModeBrandIconUrl" },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "BuyItNowButtonFields" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Listing" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "shipping" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "localPickupOnly" },
                },
              ],
            },
          },
          { kind: "Field", name: { kind: "Name", value: "usOutlet" } },
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "BuyItNowPaymentOptionsFields" },
          },
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "Item2CPOData" },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "mParticleListingFields" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Listing" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "title" } },
          { kind: "Field", name: { kind: "Name", value: "brandSlug" } },
          { kind: "Field", name: { kind: "Name", value: "categoryRootUuid" } },
          { kind: "Field", name: { kind: "Name", value: "make" } },
          { kind: "Field", name: { kind: "Name", value: "categoryUuids" } },
          { kind: "Field", name: { kind: "Name", value: "state" } },
          { kind: "Field", name: { kind: "Name", value: "listingType" } },
          { kind: "Field", name: { kind: "Name", value: "bumpEligible" } },
          { kind: "Field", name: { kind: "Name", value: "shopId" } },
          { kind: "Field", name: { kind: "Name", value: "inventory" } },
          { kind: "Field", name: { kind: "Name", value: "soldAsIs" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "acceptedPaymentMethods" },
          },
          { kind: "Field", name: { kind: "Name", value: "currency" } },
          { kind: "Field", name: { kind: "Name", value: "usOutlet" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "condition" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "conditionUuid" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "conditionSlug" },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "categories" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "slug" } },
                { kind: "Field", name: { kind: "Name", value: "rootSlug" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "csp" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "slug" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "brand" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      { kind: "Field", name: { kind: "Name", value: "slug" } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "pricing" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "buyerPrice" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "amount" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "currency" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "amountCents" },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "publishedAt" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "seconds" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "sale" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "code" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "buyerIneligibilityReason" },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "shipping" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "shippingPrices" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "shippingMethod" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "carrierCalculated" },
                      },
                      {
                        kind: "Field",
                        name: {
                          kind: "Name",
                          value: "destinationPostalCodeNeeded",
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "rate" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "amount" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "amountCents" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "currency" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "display" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "freeExpeditedShipping" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "localPickupOnly" },
                },
                { kind: "Field", name: { kind: "Name", value: "localPickup" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "certifiedPreOwned" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "title" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "badgeIconUrl" },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "shop" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "address" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "countryCode" },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "returnPolicy" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "newReturnWindowDays" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "usedReturnWindowDays" },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "ListingCreateOfferButtonData" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Listing" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "state" } },
          { kind: "Field", name: { kind: "Name", value: "offersEnabled" } },
          { kind: "Field", name: { kind: "Name", value: "listingType" } },
          { kind: "Field", name: { kind: "Name", value: "sellerId" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "isBuyerOfferEligible" },
          },
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "mParticleListingFields" },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "DigitalDetailsFields" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Listing" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "listingType" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "digitalDetails" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "variants" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "downloadText" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "fileUrl" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "fileSize" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "operatingSystems" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "fileTypes" },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "supportedFormats" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "operatingSystem" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "fileTypes" },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "Item2PricingData" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Listing" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "offersEnabled" } },
          { kind: "Field", name: { kind: "Name", value: "inventory" } },
          { kind: "Field", name: { kind: "Name", value: "hasInventory" } },
          { kind: "Field", name: { kind: "Name", value: "maxBuyerQuantity" } },
          { kind: "Field", name: { kind: "Name", value: "make" } },
          { kind: "Field", name: { kind: "Name", value: "model" } },
          { kind: "Field", name: { kind: "Name", value: "state" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "condition" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "conditionSlug" },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "shop" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "address" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "countryCode" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "country" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "_id" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "name" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "AddToCartButtonFields" },
          },
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "Item2PriceWithShippingData" },
          },
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "CompareOtherSellersLinkData" },
          },
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "ListingFinancingCalloutFields" },
          },
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "Item2StatsData" },
          },
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "BuyItNowButtonFields" },
          },
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "ListingCreateOfferButtonData" },
          },
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "DigitalDetailsFields" },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "SignalsData" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Listing" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "state" } },
          { kind: "Field", name: { kind: "Name", value: "usOutlet" } },
          { kind: "Field", name: { kind: "Name", value: "currency" } },
          {
            kind: "Field",
            alias: { kind: "Name", value: "signals" },
            name: { kind: "Name", value: "signals" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "groups" },
                      value: {
                        kind: "ListValue",
                        values: [
                          { kind: "EnumValue", value: "ITEM_PAGE_WHY_TO_BUY" },
                          { kind: "EnumValue", value: "ITEM_PAGE_NUDGES" },
                          { kind: "EnumValue", value: "ITEM_PAGE_WHY_REVERB" },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "name" } },
                { kind: "Field", name: { kind: "Name", value: "group" } },
                { kind: "Field", name: { kind: "Name", value: "title" } },
                { kind: "Field", name: { kind: "Name", value: "subtitle" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "link" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "text" } },
                      { kind: "Field", name: { kind: "Name", value: "url" } },
                    ],
                  },
                },
                { kind: "Field", name: { kind: "Name", value: "icon" } },
                { kind: "Field", name: { kind: "Name", value: "iconDark" } },
                { kind: "Field", name: { kind: "Name", value: "tooltipText" } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "Item2ReturnPolicyData" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Listing" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "condition" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "conditionSlug" },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "shop" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "returnPolicy" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "newReturnWindowDays" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "usedReturnWindowDays" },
                      },
                    ],
                  },
                },
              ],
            },
          },
          { kind: "Field", name: { kind: "Name", value: "soldAsIs" } },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "FeaturedListingModuleData" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Listing" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "title" } },
          { kind: "Field", name: { kind: "Name", value: "slug" } },
          { kind: "Field", name: { kind: "Name", value: "description" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "condition" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "displayName" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "conditionSlug" },
                },
              ],
            },
          },
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "ItemImageFields" },
          },
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "FeaturedListingShopInfoData" },
          },
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "Item2PricingData" },
          },
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "SignalsData" },
          },
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "Item2ReturnPolicyData" },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<FeaturedListingWithPriceFragment, unknown>;
export const FeaturedListingCSPFragmentDoc = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "FeaturedListingCSP" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "CSP" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "inventory" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "newTotal" } },
                { kind: "Field", name: { kind: "Name", value: "usedTotal" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "categories" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "rootSlug" } },
                { kind: "Field", name: { kind: "Name", value: "slug" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<FeaturedListingCSPFragment, unknown>;
export const CSP2HeaderDataFragmentDoc = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "CSP2HeaderData" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "CSP" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "title" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "brand" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "name" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "webLink" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "href" } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "categories" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "name" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "cmsUrl" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "href" } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            alias: { kind: "Name", value: "cspImage" },
            name: { kind: "Name", value: "images" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "transform" },
                      value: {
                        kind: "StringValue",
                        value: "card_square",
                        block: false,
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "scope" },
                      value: {
                        kind: "StringValue",
                        value: "photos",
                        block: false,
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "limit" },
                      value: { kind: "IntValue", value: "1" },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "source" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "specs" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "productSpecs" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      { kind: "Field", name: { kind: "Name", value: "type" } },
                      { kind: "Field", name: { kind: "Name", value: "name" } },
                      { kind: "Field", name: { kind: "Name", value: "value" } },
                      { kind: "Field", name: { kind: "Name", value: "path" } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CSP2HeaderDataFragment, unknown>;
export const CSPReviewJumplinksDataFragmentDoc = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "CSPReviewJumplinksData" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "CSP" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "productReviewSearch" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "limit" },
                      value: { kind: "IntValue", value: "0" },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "stats" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "total" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "averageRating" },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CSPReviewJumplinksDataFragment, unknown>;
export const SellerInfoDataFragmentDoc = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "SellerInfoData" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Listing" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "seller" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "feedbackSummary" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "receivedCount" },
                      },
                      {
                        kind: "Field",
                        name: {
                          kind: "Name",
                          value: "rollingRatingPercentage",
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "shop" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "name" } },
                { kind: "Field", name: { kind: "Name", value: "slug" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "preferredSeller" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "quickShipper" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "quickResponder" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "address" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "displayLocation" },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<SellerInfoDataFragment, unknown>;
export const ListingDataFragmentDoc = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "ListingData" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Listing" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "upc" } },
          { kind: "Field", name: { kind: "Name", value: "finish" } },
          { kind: "Field", name: { kind: "Name", value: "model" } },
          { kind: "Field", name: { kind: "Name", value: "shopUuid" } },
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "SellerInfoData" },
          },
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "ListingCardFields" },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "ListingForBuyerShippingFields" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Listing" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "shipping" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "freeExpeditedShipping" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "localPickupOnly" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "shippingPrices" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "shippingMethod" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "carrierCalculated" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "regional" },
                      },
                      {
                        kind: "Field",
                        name: {
                          kind: "Name",
                          value: "destinationPostalCodeNeeded",
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "postalCode" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "rate" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "amount" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "amountCents" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "currency" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "display" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "ListingGreatValueData" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Listing" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "pricing" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "buyerPrice" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "currency" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "amountCents" },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "condition" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "conditionSlug" },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "priceRecommendation" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "priceMiddle" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "amountCents" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "currency" },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "shop" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "address" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "country" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "_id" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "countryCode" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          { kind: "Field", name: { kind: "Name", value: "currency" } },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "ListingCardCPOData" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Listing" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "certifiedPreOwned" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "title" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "badgeIconUrl" },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "ListingForBuyerFields" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Listing" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "title" } },
          { kind: "Field", name: { kind: "Name", value: "slug" } },
          { kind: "Field", name: { kind: "Name", value: "listingType" } },
          { kind: "Field", name: { kind: "Name", value: "make" } },
          { kind: "Field", name: { kind: "Name", value: "model" } },
          { kind: "Field", name: { kind: "Name", value: "upc" } },
          { kind: "Field", name: { kind: "Name", value: "state" } },
          { kind: "Field", name: { kind: "Name", value: "stateDescription" } },
          { kind: "Field", name: { kind: "Name", value: "bumped" } },
          { kind: "Field", name: { kind: "Name", value: "watching" } },
          { kind: "Field", name: { kind: "Name", value: "soldAsIs" } },
          { kind: "Field", name: { kind: "Name", value: "usOutlet" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "publishedAt" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "seconds" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "condition" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "displayName" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "conditionSlug" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "conditionUuid" },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "pricing" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "buyerPrice" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "display" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "currency" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "amount" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "amountCents" },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "originalPrice" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "display" },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "ribbon" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "display" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "reason" },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "images" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "transform" },
                      value: {
                        kind: "StringValue",
                        value: "card_square",
                        block: false,
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "count" },
                      value: { kind: "IntValue", value: "3" },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "scope" },
                      value: {
                        kind: "StringValue",
                        value: "photos",
                        block: false,
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "type" },
                      value: {
                        kind: "StringValue",
                        value: "Product",
                        block: false,
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "source" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "shipping" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "shippingPrices" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "shippingMethod" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "carrierCalculated" },
                      },
                      {
                        kind: "Field",
                        name: {
                          kind: "Name",
                          value: "destinationPostalCodeNeeded",
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "rate" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "amount" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "amountCents" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "currency" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "display" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "freeExpeditedShipping" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "localPickupOnly" },
                },
                { kind: "Field", name: { kind: "Name", value: "localPickup" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "shop" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "name" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "returnPolicy" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "usedReturnWindowDays" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "newReturnWindowDays" },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "address" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "locality" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "region" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "country" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "_id" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "countryCode" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "name" },
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "displayLocation" },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "ListingForBuyerShippingFields" },
          },
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "ListingGreatValueData" },
          },
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "ListingCardCPOData" },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "WatchBadgeData" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Listing" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "title" } },
          { kind: "Field", name: { kind: "Name", value: "sellerId" } },
          { kind: "Field", name: { kind: "Name", value: "watching" } },
          {
            kind: "Field",
            alias: { kind: "Name", value: "watchThumbnails" },
            name: { kind: "Name", value: "images" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "type" },
                      value: {
                        kind: "StringValue",
                        value: "Product",
                        block: false,
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "scope" },
                      value: {
                        kind: "StringValue",
                        value: "photos",
                        block: false,
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "transform" },
                      value: {
                        kind: "StringValue",
                        value: "card_square",
                        block: false,
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "count" },
                      value: { kind: "IntValue", value: "1" },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "source" } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "mParticleListingFields" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Listing" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "title" } },
          { kind: "Field", name: { kind: "Name", value: "brandSlug" } },
          { kind: "Field", name: { kind: "Name", value: "categoryRootUuid" } },
          { kind: "Field", name: { kind: "Name", value: "make" } },
          { kind: "Field", name: { kind: "Name", value: "categoryUuids" } },
          { kind: "Field", name: { kind: "Name", value: "state" } },
          { kind: "Field", name: { kind: "Name", value: "listingType" } },
          { kind: "Field", name: { kind: "Name", value: "bumpEligible" } },
          { kind: "Field", name: { kind: "Name", value: "shopId" } },
          { kind: "Field", name: { kind: "Name", value: "inventory" } },
          { kind: "Field", name: { kind: "Name", value: "soldAsIs" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "acceptedPaymentMethods" },
          },
          { kind: "Field", name: { kind: "Name", value: "currency" } },
          { kind: "Field", name: { kind: "Name", value: "usOutlet" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "condition" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "conditionUuid" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "conditionSlug" },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "categories" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "slug" } },
                { kind: "Field", name: { kind: "Name", value: "rootSlug" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "csp" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "slug" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "brand" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      { kind: "Field", name: { kind: "Name", value: "slug" } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "pricing" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "buyerPrice" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "amount" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "currency" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "amountCents" },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "publishedAt" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "seconds" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "sale" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "code" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "buyerIneligibilityReason" },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "shipping" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "shippingPrices" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "shippingMethod" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "carrierCalculated" },
                      },
                      {
                        kind: "Field",
                        name: {
                          kind: "Name",
                          value: "destinationPostalCodeNeeded",
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "rate" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "amount" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "amountCents" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "currency" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "display" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "freeExpeditedShipping" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "localPickupOnly" },
                },
                { kind: "Field", name: { kind: "Name", value: "localPickup" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "certifiedPreOwned" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "title" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "badgeIconUrl" },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "shop" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "address" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "countryCode" },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "returnPolicy" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "newReturnWindowDays" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "usedReturnWindowDays" },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "ListingCreateOfferButtonData" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Listing" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "state" } },
          { kind: "Field", name: { kind: "Name", value: "offersEnabled" } },
          { kind: "Field", name: { kind: "Name", value: "listingType" } },
          { kind: "Field", name: { kind: "Name", value: "sellerId" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "isBuyerOfferEligible" },
          },
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "mParticleListingFields" },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "SellerInfoData" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Listing" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "seller" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "feedbackSummary" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "receivedCount" },
                      },
                      {
                        kind: "Field",
                        name: {
                          kind: "Name",
                          value: "rollingRatingPercentage",
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "shop" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "name" } },
                { kind: "Field", name: { kind: "Name", value: "slug" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "preferredSeller" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "quickShipper" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "quickResponder" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "address" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "displayLocation" },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "ListingCardFields" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Listing" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "ListingForBuyerFields" },
          },
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "WatchBadgeData" },
          },
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "ListingCreateOfferButtonData" },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ListingDataFragment, unknown>;
export const RecentSearchListingDataFragmentDoc = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "RecentSearchListingData" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Listing" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "images" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "type" },
                      value: {
                        kind: "StringValue",
                        value: "Product",
                        block: false,
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "scope" },
                      value: {
                        kind: "StringValue",
                        value: "photos",
                        block: false,
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "transform" },
                      value: {
                        kind: "StringValue",
                        value: "card_square",
                        block: false,
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "count" },
                      value: { kind: "IntValue", value: "2" },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "source" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<RecentSearchListingDataFragment, unknown>;
export const ReverbHeaderCartItemFieldsFragmentDoc = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "ReverbHeaderCartItemFields" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "rql_Me" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "uuid" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "cart" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "cartItems" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      { kind: "Field", name: { kind: "Name", value: "uuid" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "quantity" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "listingTotal" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "discounted" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "discount" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "type" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "label" },
                                  },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "unitPrice" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "display" },
                                  },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: {
                                kind: "Name",
                                value: "originalUnitPrice",
                              },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "display" },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "listing" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "_id" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "title" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "images" },
                              arguments: [
                                {
                                  kind: "Argument",
                                  name: { kind: "Name", value: "input" },
                                  value: {
                                    kind: "ObjectValue",
                                    fields: [
                                      {
                                        kind: "ObjectField",
                                        name: {
                                          kind: "Name",
                                          value: "transform",
                                        },
                                        value: {
                                          kind: "StringValue",
                                          value: "card_square",
                                          block: false,
                                        },
                                      },
                                      {
                                        kind: "ObjectField",
                                        name: { kind: "Name", value: "count" },
                                        value: { kind: "IntValue", value: "1" },
                                      },
                                      {
                                        kind: "ObjectField",
                                        name: { kind: "Name", value: "scope" },
                                        value: {
                                          kind: "StringValue",
                                          value: "photos",
                                          block: false,
                                        },
                                      },
                                      {
                                        kind: "ObjectField",
                                        name: { kind: "Name", value: "type" },
                                        value: {
                                          kind: "StringValue",
                                          value: "Product",
                                          block: false,
                                        },
                                      },
                                    ],
                                  },
                                },
                              ],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "source" },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ReverbHeaderCartItemFieldsFragment, unknown>;
export const ReverbHeaderCartBadgeDataFragmentDoc = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "ReverbHeaderCartBadgeData" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "rql_Me" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "uuid" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "reverbCredits" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "display" } },
                { kind: "Field", name: { kind: "Name", value: "currency" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "cart" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "cartItems" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      { kind: "Field", name: { kind: "Name", value: "uuid" } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "ReverbHeaderCartItemFields" },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "ReverbHeaderCartItemFields" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "rql_Me" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "uuid" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "cart" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "cartItems" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      { kind: "Field", name: { kind: "Name", value: "uuid" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "quantity" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "listingTotal" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "discounted" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "discount" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "type" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "label" },
                                  },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "unitPrice" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "display" },
                                  },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: {
                                kind: "Name",
                                value: "originalUnitPrice",
                              },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "display" },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "listing" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "_id" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "title" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "images" },
                              arguments: [
                                {
                                  kind: "Argument",
                                  name: { kind: "Name", value: "input" },
                                  value: {
                                    kind: "ObjectValue",
                                    fields: [
                                      {
                                        kind: "ObjectField",
                                        name: {
                                          kind: "Name",
                                          value: "transform",
                                        },
                                        value: {
                                          kind: "StringValue",
                                          value: "card_square",
                                          block: false,
                                        },
                                      },
                                      {
                                        kind: "ObjectField",
                                        name: { kind: "Name", value: "count" },
                                        value: { kind: "IntValue", value: "1" },
                                      },
                                      {
                                        kind: "ObjectField",
                                        name: { kind: "Name", value: "scope" },
                                        value: {
                                          kind: "StringValue",
                                          value: "photos",
                                          block: false,
                                        },
                                      },
                                      {
                                        kind: "ObjectField",
                                        name: { kind: "Name", value: "type" },
                                        value: {
                                          kind: "StringValue",
                                          value: "Product",
                                          block: false,
                                        },
                                      },
                                    ],
                                  },
                                },
                              ],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "source" },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ReverbHeaderCartBadgeDataFragment, unknown>;
export const ShippingLabelOrderDataFragmentDoc = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "ShippingLabelOrderData" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Order" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "buyerUuid" } },
          { kind: "Field", name: { kind: "Name", value: "shippingMethod" } },
          { kind: "Field", name: { kind: "Name", value: "legacyOrderId" } },
          { kind: "Field", name: { kind: "Name", value: "quantity" } },
          { kind: "Field", name: { kind: "Name", value: "listingId" } },
          { kind: "Field", name: { kind: "Name", value: "orderType" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "settlementAmountProductSubtotal" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "amount" } },
                { kind: "Field", name: { kind: "Name", value: "amountCents" } },
                { kind: "Field", name: { kind: "Name", value: "currency" } },
                { kind: "Field", name: { kind: "Name", value: "symbol" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "createdAt" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "seconds" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "paidAt" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "seconds" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "customsInfo" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "description" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "tariffNumber" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "categoryName" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "countryOfOrigin" },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "amountShipping" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "amount" } },
                { kind: "Field", name: { kind: "Name", value: "amountCents" } },
                { kind: "Field", name: { kind: "Name", value: "currency" } },
                { kind: "Field", name: { kind: "Name", value: "symbol" } },
                { kind: "Field", name: { kind: "Name", value: "display" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "carrierCalculatedShippingAmount" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "amount" } },
                { kind: "Field", name: { kind: "Name", value: "amountCents" } },
                { kind: "Field", name: { kind: "Name", value: "currency" } },
                { kind: "Field", name: { kind: "Name", value: "symbol" } },
                { kind: "Field", name: { kind: "Name", value: "display" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "carrierCalculatedCarrier" },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ShippingLabelOrderDataFragment, unknown>;
export const ShippingLabelCountriesDataFragmentDoc = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "ShippingLabelCountriesData" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "core_apimessages_Country" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "name" } },
          { kind: "Field", name: { kind: "Name", value: "countryCode" } },
          { kind: "Field", name: { kind: "Name", value: "subregionRequired" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "subregions" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "code" } },
                { kind: "Field", name: { kind: "Name", value: "name" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ShippingLabelCountriesDataFragment, unknown>;
export const ShippingLabelListingDataFragmentDoc = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "ShippingLabelListingData" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Listing" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "title" } },
          { kind: "Field", name: { kind: "Name", value: "merchandisingType" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "images" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "transform" },
                      value: {
                        kind: "StringValue",
                        value: "thumb",
                        block: false,
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "count" },
                      value: { kind: "IntValue", value: "1" },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "scope" },
                      value: {
                        kind: "StringValue",
                        value: "photos",
                        block: false,
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "type" },
                      value: {
                        kind: "StringValue",
                        value: "Product",
                        block: false,
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "source" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ShippingLabelListingDataFragment, unknown>;
export const ShopContainerFragmentFragmentDoc = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "ShopContainerFragment" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Shop" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "showSold" } },
          { kind: "Field", name: { kind: "Name", value: "slug" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "freeDomesticShippingRate" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "regionCode" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "freeShippingThreshold" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "display" },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "sales" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "slug" } },
                { kind: "Field", name: { kind: "Name", value: "name" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "endsAt" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "seconds" },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "discountValue" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "buyerIneligibilityReason" },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ShopContainerFragmentFragment, unknown>;
export const MyShopCampaignCouponFieldsFragmentDoc = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "MyShopCampaignCouponFields" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "core_apimessages_ShopCampaignCoupon" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "code" } },
          { kind: "Field", name: { kind: "Name", value: "discountValue" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "descriptionSummary" },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "restrictionsSummary" },
          },
          { kind: "Field", name: { kind: "Name", value: "expiresAt" } },
          { kind: "Field", name: { kind: "Name", value: "status" } },
          { kind: "Field", name: { kind: "Name", value: "couponHeader" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "applyToNewConditions" },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "applyToUsedConditions" },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "applyToBStockConditions" },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "excludedBrands" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "name" } },
                { kind: "Field", name: { kind: "Name", value: "slug" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "excludedCategories" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "name" } },
                { kind: "Field", name: { kind: "Name", value: "id" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<MyShopCampaignCouponFieldsFragment, unknown>;
export const ShopHeaderFragmentFragmentDoc = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "ShopHeaderFragment" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Shop" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "slug" } },
          { kind: "Field", name: { kind: "Name", value: "name" } },
          { kind: "Field", name: { kind: "Name", value: "preferredSeller" } },
          { kind: "Field", name: { kind: "Name", value: "quickShipper" } },
          { kind: "Field", name: { kind: "Name", value: "quickResponder" } },
          { kind: "Field", name: { kind: "Name", value: "description" } },
          { kind: "Field", name: { kind: "Name", value: "userUuid" } },
          { kind: "Field", name: { kind: "Name", value: "userId" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "address" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "displayLocation" },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "banner" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "source" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "user" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "id" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "avatar" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "source" },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ShopHeaderFragmentFragment, unknown>;
export const ShopMetadataFragmentFragmentDoc = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "ShopMetadataFragment" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Shop" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "name" } },
          { kind: "Field", name: { kind: "Name", value: "slug" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "user" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "feedbackSummary" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: {
                          kind: "Name",
                          value: "rollingRatingPercentage",
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "receivedCount" },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ShopMetadataFragmentFragment, unknown>;
export const ShopReturnPoliciesFragmentFragmentDoc = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "ShopReturnPoliciesFragment" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Shop" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "userUuid" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "returnPolicy" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "newReturnWindowDays" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "usedReturnWindowDays" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "restockingFeePercent" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "specialConditions" },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ShopReturnPoliciesFragmentFragment, unknown>;
export const ShopTaxPoliciesDataFragmentDoc = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "ShopTaxPoliciesData" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Shop" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "taxPolicies" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "displayLocation" },
                },
                { kind: "Field", name: { kind: "Name", value: "displayRate" } },
                { kind: "Field", name: { kind: "Name", value: "policyType" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ShopTaxPoliciesDataFragment, unknown>;
export const ShopTabsFragmentFragmentDoc = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "ShopTabsFragment" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Shop" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "user" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "feedbackSummary" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: {
                          kind: "Name",
                          value: "rollingRatingPercentage",
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "receivedCount" },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ShopTabsFragmentFragment, unknown>;
export const SimilarListingsFieldsFragmentDoc = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "SimilarListingsFields" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Listing" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "ListingCardFields" },
          },
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "AddToCartButtonFields" },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "ListingForBuyerShippingFields" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Listing" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "shipping" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "freeExpeditedShipping" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "localPickupOnly" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "shippingPrices" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "shippingMethod" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "carrierCalculated" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "regional" },
                      },
                      {
                        kind: "Field",
                        name: {
                          kind: "Name",
                          value: "destinationPostalCodeNeeded",
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "postalCode" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "rate" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "amount" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "amountCents" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "currency" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "display" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "ListingGreatValueData" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Listing" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "pricing" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "buyerPrice" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "currency" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "amountCents" },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "condition" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "conditionSlug" },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "priceRecommendation" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "priceMiddle" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "amountCents" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "currency" },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "shop" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "address" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "country" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "_id" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "countryCode" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          { kind: "Field", name: { kind: "Name", value: "currency" } },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "ListingCardCPOData" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Listing" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "certifiedPreOwned" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "title" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "badgeIconUrl" },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "ListingForBuyerFields" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Listing" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "title" } },
          { kind: "Field", name: { kind: "Name", value: "slug" } },
          { kind: "Field", name: { kind: "Name", value: "listingType" } },
          { kind: "Field", name: { kind: "Name", value: "make" } },
          { kind: "Field", name: { kind: "Name", value: "model" } },
          { kind: "Field", name: { kind: "Name", value: "upc" } },
          { kind: "Field", name: { kind: "Name", value: "state" } },
          { kind: "Field", name: { kind: "Name", value: "stateDescription" } },
          { kind: "Field", name: { kind: "Name", value: "bumped" } },
          { kind: "Field", name: { kind: "Name", value: "watching" } },
          { kind: "Field", name: { kind: "Name", value: "soldAsIs" } },
          { kind: "Field", name: { kind: "Name", value: "usOutlet" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "publishedAt" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "seconds" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "condition" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "displayName" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "conditionSlug" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "conditionUuid" },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "pricing" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "buyerPrice" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "display" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "currency" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "amount" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "amountCents" },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "originalPrice" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "display" },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "ribbon" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "display" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "reason" },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "images" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "transform" },
                      value: {
                        kind: "StringValue",
                        value: "card_square",
                        block: false,
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "count" },
                      value: { kind: "IntValue", value: "3" },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "scope" },
                      value: {
                        kind: "StringValue",
                        value: "photos",
                        block: false,
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "type" },
                      value: {
                        kind: "StringValue",
                        value: "Product",
                        block: false,
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "source" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "shipping" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "shippingPrices" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "shippingMethod" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "carrierCalculated" },
                      },
                      {
                        kind: "Field",
                        name: {
                          kind: "Name",
                          value: "destinationPostalCodeNeeded",
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "rate" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "amount" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "amountCents" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "currency" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "display" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "freeExpeditedShipping" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "localPickupOnly" },
                },
                { kind: "Field", name: { kind: "Name", value: "localPickup" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "shop" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "name" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "returnPolicy" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "usedReturnWindowDays" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "newReturnWindowDays" },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "address" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "locality" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "region" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "country" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "_id" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "countryCode" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "name" },
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "displayLocation" },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "ListingForBuyerShippingFields" },
          },
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "ListingGreatValueData" },
          },
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "ListingCardCPOData" },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "WatchBadgeData" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Listing" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "title" } },
          { kind: "Field", name: { kind: "Name", value: "sellerId" } },
          { kind: "Field", name: { kind: "Name", value: "watching" } },
          {
            kind: "Field",
            alias: { kind: "Name", value: "watchThumbnails" },
            name: { kind: "Name", value: "images" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "type" },
                      value: {
                        kind: "StringValue",
                        value: "Product",
                        block: false,
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "scope" },
                      value: {
                        kind: "StringValue",
                        value: "photos",
                        block: false,
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "transform" },
                      value: {
                        kind: "StringValue",
                        value: "card_square",
                        block: false,
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "count" },
                      value: { kind: "IntValue", value: "1" },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "source" } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "mParticleListingFields" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Listing" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "title" } },
          { kind: "Field", name: { kind: "Name", value: "brandSlug" } },
          { kind: "Field", name: { kind: "Name", value: "categoryRootUuid" } },
          { kind: "Field", name: { kind: "Name", value: "make" } },
          { kind: "Field", name: { kind: "Name", value: "categoryUuids" } },
          { kind: "Field", name: { kind: "Name", value: "state" } },
          { kind: "Field", name: { kind: "Name", value: "listingType" } },
          { kind: "Field", name: { kind: "Name", value: "bumpEligible" } },
          { kind: "Field", name: { kind: "Name", value: "shopId" } },
          { kind: "Field", name: { kind: "Name", value: "inventory" } },
          { kind: "Field", name: { kind: "Name", value: "soldAsIs" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "acceptedPaymentMethods" },
          },
          { kind: "Field", name: { kind: "Name", value: "currency" } },
          { kind: "Field", name: { kind: "Name", value: "usOutlet" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "condition" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "conditionUuid" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "conditionSlug" },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "categories" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "slug" } },
                { kind: "Field", name: { kind: "Name", value: "rootSlug" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "csp" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "slug" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "brand" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      { kind: "Field", name: { kind: "Name", value: "slug" } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "pricing" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "buyerPrice" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "amount" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "currency" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "amountCents" },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "publishedAt" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "seconds" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "sale" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "code" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "buyerIneligibilityReason" },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "shipping" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "shippingPrices" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "shippingMethod" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "carrierCalculated" },
                      },
                      {
                        kind: "Field",
                        name: {
                          kind: "Name",
                          value: "destinationPostalCodeNeeded",
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "rate" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "amount" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "amountCents" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "currency" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "display" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "freeExpeditedShipping" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "localPickupOnly" },
                },
                { kind: "Field", name: { kind: "Name", value: "localPickup" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "certifiedPreOwned" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "title" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "badgeIconUrl" },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "shop" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "address" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "countryCode" },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "returnPolicy" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "newReturnWindowDays" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "usedReturnWindowDays" },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "ListingCreateOfferButtonData" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Listing" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "state" } },
          { kind: "Field", name: { kind: "Name", value: "offersEnabled" } },
          { kind: "Field", name: { kind: "Name", value: "listingType" } },
          { kind: "Field", name: { kind: "Name", value: "sellerId" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "isBuyerOfferEligible" },
          },
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "mParticleListingFields" },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "ListingCardFields" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Listing" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "ListingForBuyerFields" },
          },
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "WatchBadgeData" },
          },
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "ListingCreateOfferButtonData" },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "AddToCartButtonFields" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Listing" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "sellerId" } },
          { kind: "Field", name: { kind: "Name", value: "listingType" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "pricing" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "buyerPrice" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "amount" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "amountCents" },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "preorderInfo" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "onPreorder" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "estimatedShipDate" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "seconds" },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<SimilarListingsFieldsFragment, unknown>;
export const EUODRAddressFragmentDoc = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "EUODRAddress" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Shop" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "vatId" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "euOdrAddress" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "name" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "streetAddress" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "displayLocation" },
                },
                { kind: "Field", name: { kind: "Name", value: "postalCode" } },
                { kind: "Field", name: { kind: "Name", value: "email" } },
                { kind: "Field", name: { kind: "Name", value: "phone" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<EUODRAddressFragment, unknown>;
export const ExpressSaleItemBidDataFragmentDoc = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "ExpressSaleItemBidData" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "CSP" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "expressSalePriceEstimate" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "conditionUuid" },
                      value: {
                        kind: "StringValue",
                        value: "ae4d9114-1bd7-4ec5-a4ba-6653af5ac84d",
                        block: false,
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "priceLow" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "amountCents" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "currency" },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "priceHigh" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "amountCents" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "currency" },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "expressSaleItemBid" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "cspUuid" } },
                { kind: "Field", name: { kind: "Name", value: "bidId" } },
                { kind: "Field", name: { kind: "Name", value: "carrier" } },
                { kind: "Field", name: { kind: "Name", value: "shopName" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "estimatedPayout" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "display" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "amount" },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ExpressSaleItemBidDataFragment, unknown>;
export const FavoriteCTADataFragmentDoc = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "FavoriteCTAData" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "rql_Favorite" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "favorite" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "favorited" } },
                { kind: "Field", name: { kind: "Name", value: "title" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "searchableId" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "link" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "href" } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<FavoriteCTADataFragment, unknown>;
export const FavoriteSearchDataFragmentDoc = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "FavoriteSearchData" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "rql_Favorite" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "listingsSearchUrlParams" },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "favorite" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "title" } },
                { kind: "Field", name: { kind: "Name", value: "subtitle" } },
                { kind: "Field", name: { kind: "Name", value: "favorited" } },
                { kind: "Field", name: { kind: "Name", value: "feedEnabled" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "emailEnabled" },
                },
                { kind: "Field", name: { kind: "Name", value: "queryParams" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "entity" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "InlineFragment",
                  typeCondition: {
                    kind: "NamedType",
                    name: { kind: "Name", value: "core_apimessages_Favorite" },
                  },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "link" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "href" },
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "images" },
                        arguments: [
                          {
                            kind: "Argument",
                            name: { kind: "Name", value: "input" },
                            value: {
                              kind: "ObjectValue",
                              fields: [
                                {
                                  kind: "ObjectField",
                                  name: { kind: "Name", value: "count" },
                                  value: { kind: "IntValue", value: "1" },
                                },
                                {
                                  kind: "ObjectField",
                                  name: { kind: "Name", value: "transform" },
                                  value: {
                                    kind: "StringValue",
                                    value: "card_square",
                                    block: false,
                                  },
                                },
                              ],
                            },
                          },
                        ],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "source" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<FavoriteSearchDataFragment, unknown>;
export const FavoriteShopCTADataFragmentDoc = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "FavoriteShopCTAData" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Shop" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "name" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "address" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "countryCode" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "user" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "feedbackSummary" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: {
                          kind: "Name",
                          value: "rollingRatingPercentage",
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "receivedCount" },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<FavoriteShopCTADataFragment, unknown>;
export const FavoriteShopDataFragmentDoc = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "FavoriteShopData" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "rql_Favorite" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "favorite" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "title" } },
                { kind: "Field", name: { kind: "Name", value: "subtitle" } },
                { kind: "Field", name: { kind: "Name", value: "favorited" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "entity" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "InlineFragment",
                  typeCondition: {
                    kind: "NamedType",
                    name: { kind: "Name", value: "Shop" },
                  },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      { kind: "Field", name: { kind: "Name", value: "name" } },
                      { kind: "Field", name: { kind: "Name", value: "slug" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "address" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "_id" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "displayLocation" },
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "user" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "_id" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "feedbackSummary" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "rollingRatingPercentage",
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "receivedCount",
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "avatar" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "_id" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "source" },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<FavoriteShopDataFragment, unknown>;
export const InOtherCartsCardDataFragmentDoc = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "InOtherCartsCardData" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Listing" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "otherBuyersWithListingInCartCounts" },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<InOtherCartsCardDataFragment, unknown>;
export const FavoriteWatchListCardFragmentDoc = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "FavoriteWatchListCard" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Listing" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "slug" } },
          { kind: "Field", name: { kind: "Name", value: "state" } },
          { kind: "Field", name: { kind: "Name", value: "title" } },
          { kind: "Field", name: { kind: "Name", value: "usOutlet" } },
          { kind: "Field", name: { kind: "Name", value: "watching" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "condition" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "displayName" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "conditionSlug" },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "pricing" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "ribbon" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "display" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "reason" },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "buyerPrice" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "display" },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "originalPrice" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "display" },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "shop" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "name" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "address" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "displayLocation" },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "signals" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "groups" },
                      value: {
                        kind: "ListValue",
                        values: [{ kind: "EnumValue", value: "MP_GRID_CARD" }],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "icon" } },
                { kind: "Field", name: { kind: "Name", value: "title" } },
                { kind: "Field", name: { kind: "Name", value: "name" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "seller" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "feedbackSummary" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: {
                          kind: "Name",
                          value: "rollingRatingPercentage",
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "shippingPrice" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "amountCents" } },
                { kind: "Field", name: { kind: "Name", value: "display" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "shipping" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "localPickupOnly" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "freeExpeditedShipping" },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "images" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "transform" },
                      value: {
                        kind: "Variable",
                        name: {
                          kind: "Name",
                          value: "favoriteImagesTransform",
                        },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "count" },
                      value: { kind: "IntValue", value: "1" },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "scope" },
                      value: {
                        kind: "StringValue",
                        value: "photos",
                        block: false,
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "type" },
                      value: {
                        kind: "StringValue",
                        value: "Product",
                        block: false,
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "source" } },
              ],
            },
          },
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "WatchBadgeData" },
          },
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "AddToCartButtonFields" },
          },
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "ListingCreateOfferButtonData" },
          },
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "InOtherCartsCardData" },
          },
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "ListingGreatValueData" },
          },
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "ListingCardCPOData" },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "mParticleListingFields" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Listing" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "title" } },
          { kind: "Field", name: { kind: "Name", value: "brandSlug" } },
          { kind: "Field", name: { kind: "Name", value: "categoryRootUuid" } },
          { kind: "Field", name: { kind: "Name", value: "make" } },
          { kind: "Field", name: { kind: "Name", value: "categoryUuids" } },
          { kind: "Field", name: { kind: "Name", value: "state" } },
          { kind: "Field", name: { kind: "Name", value: "listingType" } },
          { kind: "Field", name: { kind: "Name", value: "bumpEligible" } },
          { kind: "Field", name: { kind: "Name", value: "shopId" } },
          { kind: "Field", name: { kind: "Name", value: "inventory" } },
          { kind: "Field", name: { kind: "Name", value: "soldAsIs" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "acceptedPaymentMethods" },
          },
          { kind: "Field", name: { kind: "Name", value: "currency" } },
          { kind: "Field", name: { kind: "Name", value: "usOutlet" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "condition" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "conditionUuid" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "conditionSlug" },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "categories" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "slug" } },
                { kind: "Field", name: { kind: "Name", value: "rootSlug" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "csp" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "slug" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "brand" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      { kind: "Field", name: { kind: "Name", value: "slug" } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "pricing" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "buyerPrice" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "amount" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "currency" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "amountCents" },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "publishedAt" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "seconds" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "sale" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "code" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "buyerIneligibilityReason" },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "shipping" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "shippingPrices" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "shippingMethod" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "carrierCalculated" },
                      },
                      {
                        kind: "Field",
                        name: {
                          kind: "Name",
                          value: "destinationPostalCodeNeeded",
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "rate" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "amount" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "amountCents" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "currency" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "display" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "freeExpeditedShipping" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "localPickupOnly" },
                },
                { kind: "Field", name: { kind: "Name", value: "localPickup" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "certifiedPreOwned" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "title" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "badgeIconUrl" },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "shop" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "address" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "countryCode" },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "returnPolicy" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "newReturnWindowDays" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "usedReturnWindowDays" },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "WatchBadgeData" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Listing" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "title" } },
          { kind: "Field", name: { kind: "Name", value: "sellerId" } },
          { kind: "Field", name: { kind: "Name", value: "watching" } },
          {
            kind: "Field",
            alias: { kind: "Name", value: "watchThumbnails" },
            name: { kind: "Name", value: "images" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "type" },
                      value: {
                        kind: "StringValue",
                        value: "Product",
                        block: false,
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "scope" },
                      value: {
                        kind: "StringValue",
                        value: "photos",
                        block: false,
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "transform" },
                      value: {
                        kind: "StringValue",
                        value: "card_square",
                        block: false,
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "count" },
                      value: { kind: "IntValue", value: "1" },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "source" } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "AddToCartButtonFields" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Listing" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "sellerId" } },
          { kind: "Field", name: { kind: "Name", value: "listingType" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "pricing" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "buyerPrice" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "amount" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "amountCents" },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "preorderInfo" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "onPreorder" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "estimatedShipDate" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "seconds" },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "ListingCreateOfferButtonData" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Listing" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "state" } },
          { kind: "Field", name: { kind: "Name", value: "offersEnabled" } },
          { kind: "Field", name: { kind: "Name", value: "listingType" } },
          { kind: "Field", name: { kind: "Name", value: "sellerId" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "isBuyerOfferEligible" },
          },
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "mParticleListingFields" },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "InOtherCartsCardData" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Listing" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "otherBuyersWithListingInCartCounts" },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "ListingGreatValueData" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Listing" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "pricing" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "buyerPrice" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "currency" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "amountCents" },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "condition" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "conditionSlug" },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "priceRecommendation" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "priceMiddle" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "amountCents" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "currency" },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "shop" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "address" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "country" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "_id" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "countryCode" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          { kind: "Field", name: { kind: "Name", value: "currency" } },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "ListingCardCPOData" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Listing" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "certifiedPreOwned" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "title" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "badgeIconUrl" },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<FavoriteWatchListCardFragment, unknown>;
export const GearCollectionSettingsDataFragmentDoc = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "GearCollectionSettingsData" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "rql_Me" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "uuid" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "gearCollectionSettings" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "autoAddOrders" },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GearCollectionSettingsDataFragment, unknown>;
export const GearCollectionCSPCardDataFragmentDoc = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "GearCollectionCSPCardData" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "CSP" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "title" } },
          { kind: "Field", name: { kind: "Name", value: "finishes" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "canonicalProductIds" },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "image" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "transform" },
                      value: {
                        kind: "StringValue",
                        value: "card_square",
                        block: false,
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "source" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "brand" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "name" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GearCollectionCSPCardDataFragment, unknown>;
export const GearCollectionCSPSummaryCardDataFragmentDoc = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "GearCollectionCSPSummaryCardData" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "CSP" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "title" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "image" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "transform" },
                      value: {
                        kind: "StringValue",
                        value: "card_square",
                        block: false,
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "source" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "brand" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "name" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "canonicalProducts" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "name" } },
                { kind: "Field", name: { kind: "Name", value: "model" } },
                { kind: "Field", name: { kind: "Name", value: "year" } },
                { kind: "Field", name: { kind: "Name", value: "finish" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GearCollectionCSPSummaryCardDataFragment, unknown>;
export const GcIndexCardPricesFragmentDoc = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "GcIndexCardPrices" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "GearCollectionItem" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "created" } },
          { kind: "Field", name: { kind: "Name", value: "updated" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "canonicalProductId" },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "price" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "amountCents" } },
                { kind: "Field", name: { kind: "Name", value: "currency" } },
                { kind: "Field", name: { kind: "Name", value: "display" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "esPriceEstimate" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "priceLow" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "amountCents" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "currency" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "display" },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "priceHigh" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "amountCents" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "currency" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "display" },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GcIndexCardPricesFragment, unknown>;
export const GearCollectionIndexCardFragmentDoc = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "GearCollectionIndexCard" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "GearCollectionItem" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "deleted" } },
          { kind: "Field", name: { kind: "Name", value: "title" } },
          { kind: "Field", name: { kind: "Name", value: "description" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "tags" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "name" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "images" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "transform" },
                      value: {
                        kind: "StringValue",
                        value: "card_square",
                        block: false,
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "count" },
                      value: { kind: "IntValue", value: "1" },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "source" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "listing" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "id" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "originalListing" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "images" },
                  arguments: [
                    {
                      kind: "Argument",
                      name: { kind: "Name", value: "input" },
                      value: {
                        kind: "ObjectValue",
                        fields: [
                          {
                            kind: "ObjectField",
                            name: { kind: "Name", value: "transform" },
                            value: {
                              kind: "StringValue",
                              value: "card_square",
                              block: false,
                            },
                          },
                          {
                            kind: "ObjectField",
                            name: { kind: "Name", value: "count" },
                            value: { kind: "IntValue", value: "1" },
                          },
                        ],
                      },
                    },
                  ],
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "source" },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "csp" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "slug" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "image" },
                  arguments: [
                    {
                      kind: "Argument",
                      name: { kind: "Name", value: "input" },
                      value: {
                        kind: "ObjectValue",
                        fields: [
                          {
                            kind: "ObjectField",
                            name: { kind: "Name", value: "transform" },
                            value: {
                              kind: "StringValue",
                              value: "card_square",
                              block: false,
                            },
                          },
                          {
                            kind: "ObjectField",
                            name: { kind: "Name", value: "count" },
                            value: { kind: "IntValue", value: "1" },
                          },
                        ],
                      },
                    },
                  ],
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "source" },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "GcIndexCardPrices" },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "GcIndexCardPrices" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "GearCollectionItem" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "created" } },
          { kind: "Field", name: { kind: "Name", value: "updated" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "canonicalProductId" },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "price" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "amountCents" } },
                { kind: "Field", name: { kind: "Name", value: "currency" } },
                { kind: "Field", name: { kind: "Name", value: "display" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "esPriceEstimate" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "priceLow" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "amountCents" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "currency" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "display" },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "priceHigh" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "amountCents" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "currency" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "display" },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GearCollectionIndexCardFragment, unknown>;
export const QuickAddOrderCardFieldsFragmentDoc = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "QuickAddOrderCardFields" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Order" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "legacyOrderId" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "createdAt" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "seconds" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "paidAt" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "seconds" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "listing" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "title" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "csp" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "images" },
                  arguments: [
                    {
                      kind: "Argument",
                      name: { kind: "Name", value: "input" },
                      value: {
                        kind: "ObjectValue",
                        fields: [
                          {
                            kind: "ObjectField",
                            name: { kind: "Name", value: "transform" },
                            value: {
                              kind: "StringValue",
                              value: "card_square",
                              block: false,
                            },
                          },
                          {
                            kind: "ObjectField",
                            name: { kind: "Name", value: "count" },
                            value: { kind: "IntValue", value: "1" },
                          },
                          {
                            kind: "ObjectField",
                            name: { kind: "Name", value: "scope" },
                            value: {
                              kind: "StringValue",
                              value: "photos",
                              block: false,
                            },
                          },
                          {
                            kind: "ObjectField",
                            name: { kind: "Name", value: "type" },
                            value: {
                              kind: "StringValue",
                              value: "Product",
                              block: false,
                            },
                          },
                        ],
                      },
                    },
                  ],
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "source" },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<QuickAddOrderCardFieldsFragment, unknown>;
export const GearCollectionCSPDataFragmentDoc = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "GearCollectionCSPData" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "CSP" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "title" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "brand" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "name" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "webLink" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "href" } },
                { kind: "Field", name: { kind: "Name", value: "path" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "image" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "transform" },
                      value: {
                        kind: "StringValue",
                        value: "card_square",
                        block: false,
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "source" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GearCollectionCSPDataFragment, unknown>;
export const GearCollectionItemShowDisplayDataFragmentDoc = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "GearCollectionItemShowDisplayData" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "GearCollectionItem" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "title" } },
          { kind: "Field", name: { kind: "Name", value: "description" } },
          { kind: "Field", name: { kind: "Name", value: "notes" } },
          { kind: "Field", name: { kind: "Name", value: "make" } },
          { kind: "Field", name: { kind: "Name", value: "model" } },
          { kind: "Field", name: { kind: "Name", value: "finish" } },
          { kind: "Field", name: { kind: "Name", value: "year" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "canonicalProductId" },
          },
          { kind: "Field", name: { kind: "Name", value: "subcategoryUuids" } },
          { kind: "Field", name: { kind: "Name", value: "serialNumber" } },
          { kind: "Field", name: { kind: "Name", value: "hideListingPhotos" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "hideListingDescription" },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "condition" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "conditionSlug" },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "tags" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "name" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "price" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "amount" } },
                { kind: "Field", name: { kind: "Name", value: "amountCents" } },
                { kind: "Field", name: { kind: "Name", value: "currency" } },
                { kind: "Field", name: { kind: "Name", value: "display" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "ownerCost" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "amount" } },
                { kind: "Field", name: { kind: "Name", value: "amountCents" } },
                { kind: "Field", name: { kind: "Name", value: "currency" } },
                { kind: "Field", name: { kind: "Name", value: "display" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "categories" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "name" } },
                { kind: "Field", name: { kind: "Name", value: "root" } },
                { kind: "Field", name: { kind: "Name", value: "leaf" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "originalListing" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "description" } },
                { kind: "Field", name: { kind: "Name", value: "title" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "images" },
                  arguments: [
                    {
                      kind: "Argument",
                      name: { kind: "Name", value: "input" },
                      value: {
                        kind: "ObjectValue",
                        fields: [
                          {
                            kind: "ObjectField",
                            name: { kind: "Name", value: "transform" },
                            value: {
                              kind: "StringValue",
                              value: "card_square",
                              block: false,
                            },
                          },
                        ],
                      },
                    },
                  ],
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "source" },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "listing" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "id" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "csp" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                {
                  kind: "FragmentSpread",
                  name: { kind: "Name", value: "GearCollectionCSPData" },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "dataServicesPriceEstimate" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "priceLow" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "amountCents" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "currency" },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "priceHigh" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "amountCents" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "currency" },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "priceMiddle" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "amountCents" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "currency" },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "priceMiddleThirtyDaysAgo" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "amountCents" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "currency" },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "images" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "transform" },
                      value: {
                        kind: "StringValue",
                        value: "card_square",
                        block: false,
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "source" } },
                { kind: "Field", name: { kind: "Name", value: "publicId" } },
                { kind: "Field", name: { kind: "Name", value: "version" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "fallbackType" },
                },
                { kind: "Field", name: { kind: "Name", value: "height" } },
                { kind: "Field", name: { kind: "Name", value: "width" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "transformation" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "x" } },
                      { kind: "Field", name: { kind: "Name", value: "y" } },
                      { kind: "Field", name: { kind: "Name", value: "width" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "height" },
                      },
                      { kind: "Field", name: { kind: "Name", value: "angle" } },
                      { kind: "Field", name: { kind: "Name", value: "crop" } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "GearCollectionCSPData" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "CSP" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "title" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "brand" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "name" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "webLink" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "href" } },
                { kind: "Field", name: { kind: "Name", value: "path" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "image" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "transform" },
                      value: {
                        kind: "StringValue",
                        value: "card_square",
                        block: false,
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "source" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GearCollectionItemShowDisplayDataFragment,
  unknown
>;
export const Item2Prop65WarningDataFragmentDoc = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "Item2Prop65WarningData" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Listing" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "sellerId" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "propSixtyFiveWarning" },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "propSixtyFiveWarningImg" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "href" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<Item2Prop65WarningDataFragment, unknown>;
export const Item2AboutThisListingDataFragmentDoc = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "Item2AboutThisListingData" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Listing" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "description" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "publishedAt" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "seconds" } },
              ],
            },
          },
          { kind: "Field", name: { kind: "Name", value: "hasInventory" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "condition" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "conditionUuid" },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "specs" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "productSpecs" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      { kind: "Field", name: { kind: "Name", value: "name" } },
                      { kind: "Field", name: { kind: "Name", value: "value" } },
                      { kind: "Field", name: { kind: "Name", value: "path" } },
                      { kind: "Field", name: { kind: "Name", value: "slug" } },
                      { kind: "Field", name: { kind: "Name", value: "type" } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "Item2Prop65WarningData" },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "Item2Prop65WarningData" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Listing" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "sellerId" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "propSixtyFiveWarning" },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "propSixtyFiveWarningImg" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "href" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<Item2AboutThisListingDataFragment, unknown>;
export const Item2AboutThisSellerDataFragmentDoc = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "Item2AboutThisSellerData" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Listing" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "seller" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "uuid" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "avatar" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "source" },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "feedbackSummary" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "receivedCount" },
                      },
                      {
                        kind: "Field",
                        name: {
                          kind: "Name",
                          value: "rollingRatingPercentage",
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "shop" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "name" } },
                { kind: "Field", name: { kind: "Name", value: "description" } },
                { kind: "Field", name: { kind: "Name", value: "slug" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "preferredSeller" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "quickShipper" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "quickResponder" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "address" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "displayLocation" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "countryCode" },
                      },
                    ],
                  },
                },
                { kind: "Field", name: { kind: "Name", value: "createdAt" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "orders" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "total" } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<Item2AboutThisSellerDataFragment, unknown>;
export const Item2AdWithCspsRowContainerDataFragmentDoc = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "Item2AdWithCspsRowContainerData" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Listing" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "categoryRootUuid" } },
          { kind: "Field", name: { kind: "Name", value: "categoryUuids" } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<Item2AdWithCspsRowContainerDataFragment, unknown>;
export const Item2AdminButtonsDataFragmentDoc = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "Item2AdminButtonsData" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Listing" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "slug" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "canonicalProductId" },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<Item2AdminButtonsDataFragment, unknown>;
export const Item2FallbackBreadcrumbsDataFragmentDoc = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "Item2FallbackBreadcrumbsData" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Listing" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "make" } },
          { kind: "Field", name: { kind: "Name", value: "brandSlug" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "categories" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "slug" } },
                { kind: "Field", name: { kind: "Name", value: "name" } },
                { kind: "Field", name: { kind: "Name", value: "rootSlug" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "webLink" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "href" } },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "cmsLink" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "href" } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "csp" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "id" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "specs" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "productSpecs" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "_id" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "type" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "name" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "value" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "path" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<Item2FallbackBreadcrumbsDataFragment, unknown>;
export const Item2BreadcrumbsDataFragmentDoc = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "Item2BreadcrumbsData" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Listing" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "breadcrumbs" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "title" } },
                { kind: "Field", name: { kind: "Name", value: "url" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "csp" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "slug" } },
                { kind: "Field", name: { kind: "Name", value: "title" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<Item2BreadcrumbsDataFragment, unknown>;
export const Item2DescriptionDataFragmentDoc = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "Item2DescriptionData" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Listing" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "description" } },
          { kind: "Field", name: { kind: "Name", value: "soldAsIs" } },
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "Item2Prop65WarningData" },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "Item2Prop65WarningData" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Listing" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "sellerId" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "propSixtyFiveWarning" },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "propSixtyFiveWarningImg" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "href" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<Item2DescriptionDataFragment, unknown>;
export const Item2FeedbackItemDataFragmentDoc = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "Item2FeedbackItemData" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "core_apimessages_Feedback" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "author" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "shortname" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "createdAt" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "seconds" } },
              ],
            },
          },
          { kind: "Field", name: { kind: "Name", value: "message" } },
          { kind: "Field", name: { kind: "Name", value: "orderTitle" } },
          { kind: "Field", name: { kind: "Name", value: "rating" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "recipient" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "shortname" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "listingLink" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "href" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "response" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "body" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "createdAt" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "seconds" },
                      },
                    ],
                  },
                },
              ],
            },
          },
          { kind: "Field", name: { kind: "Name", value: "type" } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<Item2FeedbackItemDataFragment, unknown>;
export const Item2FlagListingDataFragmentDoc = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "Item2FlagListingData" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Listing" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "state" } },
          { kind: "Field", name: { kind: "Name", value: "sellerId" } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<Item2FlagListingDataFragment, unknown>;
export const Item2FlagListingMeDataFragmentDoc = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "Item2FlagListingMeData" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "rql_Me" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "uuid" } },
          { kind: "Field", name: { kind: "Name", value: "status" } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<Item2FlagListingMeDataFragment, unknown>;
export const RecommendedShopsRowListingFieldsFragmentDoc = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "RecommendedShopsRowListingFields" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Listing" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "brandSlug" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "categories" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "slug" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<RecommendedShopsRowListingFieldsFragment, unknown>;
export const ItemFooterFieldsFragmentDoc = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "ItemFooterFields" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Listing" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "categoryRootUuid" } },
          { kind: "Field", name: { kind: "Name", value: "state" } },
          { kind: "Field", name: { kind: "Name", value: "shopId" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "combinedShippingParent" },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "shop" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "name" } },
                { kind: "Field", name: { kind: "Name", value: "slug" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "address" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "countryCode" },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "shipping" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "localPickupOnly" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "shippingPrices" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "shippingMethod" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "rate" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "amountCents" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "csp" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "slug" } },
              ],
            },
          },
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "RecommendedShopsRowListingFields" },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "RecommendedShopsRowListingFields" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Listing" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "brandSlug" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "categories" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "slug" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ItemFooterFieldsFragment, unknown>;
export const Item2AvailableActionsDataFragmentDoc = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "Item2AvailableActionsData" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "core_apimessages_AvailableAction" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "name" } },
          { kind: "Field", name: { kind: "Name", value: "status" } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<Item2AvailableActionsDataFragment, unknown>;
export const Item2MetadataDataFragmentDoc = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "Item2MetadataData" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Listing" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "title" } },
          { kind: "Field", name: { kind: "Name", value: "description" } },
          { kind: "Field", name: { kind: "Name", value: "finish" } },
          { kind: "Field", name: { kind: "Name", value: "make" } },
          { kind: "Field", name: { kind: "Name", value: "model" } },
          { kind: "Field", name: { kind: "Name", value: "slug" } },
          { kind: "Field", name: { kind: "Name", value: "inventory" } },
          { kind: "Field", name: { kind: "Name", value: "state" } },
          { kind: "Field", name: { kind: "Name", value: "brandSlug" } },
          { kind: "Field", name: { kind: "Name", value: "soldAsIs" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "categories" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "name" } },
                { kind: "Field", name: { kind: "Name", value: "rootSlug" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "cmsLink" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "href" } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "condition" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "conditionSlug" },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "pricing" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "buyerPrice" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "amount" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "currency" },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "preorderInfo" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "onPreorder" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "csp" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "productReviewSearch" },
                  arguments: [
                    {
                      kind: "Argument",
                      name: { kind: "Name", value: "input" },
                      value: {
                        kind: "ObjectValue",
                        fields: [
                          {
                            kind: "ObjectField",
                            name: { kind: "Name", value: "limit" },
                            value: { kind: "IntValue", value: "0" },
                          },
                        ],
                      },
                    },
                  ],
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "stats" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "total" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "averageRating" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "shop" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "name" } },
                { kind: "Field", name: { kind: "Name", value: "onVacation" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "returnPolicy" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "usedReturnWindowDays" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "newReturnWindowDays" },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            alias: { kind: "Name", value: "metaImage" },
            name: { kind: "Name", value: "images" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "type" },
                      value: {
                        kind: "StringValue",
                        value: "Product",
                        block: false,
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "scope" },
                      value: {
                        kind: "StringValue",
                        value: "photos",
                        block: false,
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "transform" },
                      value: {
                        kind: "StringValue",
                        value: "large",
                        block: false,
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "count" },
                      value: { kind: "IntValue", value: "25" },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "source" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "experiments" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "name" } },
                { kind: "Field", name: { kind: "Name", value: "bucket" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "shipping" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "localPickupOnly" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "shippingPrices" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "shippingMethod" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "carrierCalculated" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "regional" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "rate" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "amount" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "currency" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<Item2MetadataDataFragment, unknown>;
export const Item2OrderDetailsDataFragmentDoc = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "Item2OrderDetailsData" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Listing" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "orders" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "as" },
                      value: { kind: "EnumValue", value: "BUYER" },
                    },
                  ],
                },
              },
            ],
            directives: [
              {
                kind: "Directive",
                name: { kind: "Name", value: "skip" },
                arguments: [
                  {
                    kind: "Argument",
                    name: { kind: "Name", value: "if" },
                    value: {
                      kind: "Variable",
                      name: { kind: "Name", value: "loggedOut" },
                    },
                  },
                ],
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "nodes" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "legacyOrderId" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "status" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "paidAt" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "seconds" },
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "orderUrl" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "href" },
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "createdAt" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "seconds" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<Item2OrderDetailsDataFragment, unknown>;
export const Item2PaidLandingDataFragmentDoc = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "Item2PaidLandingData" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Listing" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "state" } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<Item2PaidLandingDataFragment, unknown>;
export const Item2PricingNegotiationsDataFragmentDoc = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "Item2PricingNegotiationsData" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Negotiation" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "lastOffer" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "status" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<Item2PricingNegotiationsDataFragment, unknown>;
export const Item2ProductModuleDataFragmentDoc = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "Item2ProductModuleData" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Listing" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "canonicalProductId" },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "condition" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "conditionSlug" },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "categories" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "slug" } },
                { kind: "Field", name: { kind: "Name", value: "name" } },
                { kind: "Field", name: { kind: "Name", value: "rootSlug" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "webLink" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "href" } },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "cmsLink" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "href" } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "csp" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "title" } },
                { kind: "Field", name: { kind: "Name", value: "slug" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "webLink" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "path" } },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "image" },
                  arguments: [
                    {
                      kind: "Argument",
                      name: { kind: "Name", value: "input" },
                      value: {
                        kind: "ObjectValue",
                        fields: [
                          {
                            kind: "ObjectField",
                            name: { kind: "Name", value: "transform" },
                            value: {
                              kind: "StringValue",
                              value: "card_square",
                              block: false,
                            },
                          },
                        ],
                      },
                    },
                  ],
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "source" },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "inventory" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "newLowPrice" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "display" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "amountCents" },
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "usedLowPrice" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "display" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "amountCents" },
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "usedTotal" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "newTotal" },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "specs" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "productSpecs" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "_id" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "type" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "name" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "value" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "path" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<Item2ProductModuleDataFragment, unknown>;
export const Item2ProductSpecsDataFragmentDoc = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "Item2ProductSpecsData" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Listing" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "publishedAt" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "seconds" } },
              ],
            },
          },
          { kind: "Field", name: { kind: "Name", value: "hasInventory" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "condition" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "conditionUuid" },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "shop" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "slug" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "specs" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "productSpecs" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      { kind: "Field", name: { kind: "Name", value: "name" } },
                      { kind: "Field", name: { kind: "Name", value: "value" } },
                      { kind: "Field", name: { kind: "Name", value: "path" } },
                      { kind: "Field", name: { kind: "Name", value: "slug" } },
                      { kind: "Field", name: { kind: "Name", value: "type" } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<Item2ProductSpecsDataFragment, unknown>;
export const ShippingWarningDataFragmentDoc = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "ShippingWarningData" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Listing" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "slug" } },
          { kind: "Field", name: { kind: "Name", value: "state" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "shipping" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "localPickupOnly" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "shippingPrices" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "shippingMethod" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "carrierCalculated" },
                      },
                      {
                        kind: "Field",
                        name: {
                          kind: "Name",
                          value: "destinationPostalCodeNeeded",
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "rate" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "amount" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "amountCents" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "currency" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "display" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "shop" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "userUuid" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ShippingWarningDataFragment, unknown>;
export const Item2ShopReviewsDataFragmentDoc = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "Item2ShopReviewsData" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Listing" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "sellerUuid" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "shop" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "id" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "seller" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "feedbackSummary" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "receivedCount" },
                      },
                      {
                        kind: "Field",
                        name: {
                          kind: "Name",
                          value: "rollingRatingPercentage",
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<Item2ShopReviewsDataFragment, unknown>;
export const Item2ShopModuleDataFragmentDoc = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "Item2ShopModuleData" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Listing" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "seller" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "uuid" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "avatar" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "source" },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "shop" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "name" } },
                { kind: "Field", name: { kind: "Name", value: "slug" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "preferredSeller" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "quickShipper" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "quickResponder" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "address" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "displayLocation" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "countryCode" },
                      },
                    ],
                  },
                },
                { kind: "Field", name: { kind: "Name", value: "createdAt" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "orders" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "total" } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "Item2ShopReviewsData" },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "Item2ShopReviewsData" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Listing" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "sellerUuid" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "shop" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "id" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "seller" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "feedbackSummary" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "receivedCount" },
                      },
                      {
                        kind: "Field",
                        name: {
                          kind: "Name",
                          value: "rollingRatingPercentage",
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<Item2ShopModuleDataFragment, unknown>;
export const Item2ShopOverviewDataFragmentDoc = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "Item2ShopOverviewData" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Listing" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "seller" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "feedbackSummary" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: {
                          kind: "Name",
                          value: "rollingRatingPercentage",
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "receivedCount" },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "avatar" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "source" },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "shop" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "name" } },
                { kind: "Field", name: { kind: "Name", value: "slug" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "address" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "displayLocation" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "countryCode" },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<Item2ShopOverviewDataFragment, unknown>;
export const Item2ListingPaymentPoliciesDataFragmentDoc = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "Item2ListingPaymentPoliciesData" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Listing" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "acceptedPaymentMethods" },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<Item2ListingPaymentPoliciesDataFragment, unknown>;
export const Item2ShopShippingPoliciesDataFragmentDoc = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "Item2ShopShippingPoliciesData" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Listing" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "shipping" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "localPickup" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "shippingPrices" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "shippingMethod" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "rate" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "display" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "shop" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "shippingPolicy" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "address" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "displayLocation" },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "allShippingPrices" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "shippingPrices" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "shippingMethod" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "rate" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "display" },
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "shippingRegionCode" },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<Item2ShopShippingPoliciesDataFragment, unknown>;
export const Item2ShopReturnPoliciesDataFragmentDoc = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "Item2ShopReturnPoliciesData" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Listing" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "listingType" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "acceptedPaymentMethods" },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "condition" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "conditionSlug" },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "estimatedMonthlyPaymentPlan" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "estimatedMonthlyPayment" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "display" },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "maxTermMonth" },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "shop" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "userUuid" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "returnPolicy" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "newReturnWindowDays" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "usedReturnWindowDays" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "restockingFeePercent" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "specialConditions" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "legacyReturnPolicyText" },
                      },
                    ],
                  },
                },
              ],
            },
          },
          { kind: "Field", name: { kind: "Name", value: "soldAsIs" } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<Item2ShopReturnPoliciesDataFragment, unknown>;
export const Item2ShopTaxPoliciesDataFragmentDoc = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "Item2ShopTaxPoliciesData" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Listing" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "taxPolicies" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "displayRate" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "displayLocation" },
                },
                { kind: "Field", name: { kind: "Name", value: "policyType" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<Item2ShopTaxPoliciesDataFragment, unknown>;
export const ShopPoliciesDataFragmentDoc = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "ShopPoliciesData" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Listing" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "Item2ListingPaymentPoliciesData" },
          },
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "Item2ShopShippingPoliciesData" },
          },
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "Item2ShopReturnPoliciesData" },
          },
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "Item2ShopTaxPoliciesData" },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "Item2ListingPaymentPoliciesData" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Listing" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "acceptedPaymentMethods" },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "Item2ShopShippingPoliciesData" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Listing" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "shipping" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "localPickup" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "shippingPrices" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "shippingMethod" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "rate" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "display" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "shop" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "shippingPolicy" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "address" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "displayLocation" },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "allShippingPrices" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "shippingPrices" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "shippingMethod" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "rate" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "display" },
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "shippingRegionCode" },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "Item2ShopReturnPoliciesData" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Listing" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "listingType" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "acceptedPaymentMethods" },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "condition" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "conditionSlug" },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "estimatedMonthlyPaymentPlan" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "estimatedMonthlyPayment" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "display" },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "maxTermMonth" },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "shop" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "userUuid" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "returnPolicy" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "newReturnWindowDays" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "usedReturnWindowDays" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "restockingFeePercent" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "specialConditions" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "legacyReturnPolicyText" },
                      },
                    ],
                  },
                },
              ],
            },
          },
          { kind: "Field", name: { kind: "Name", value: "soldAsIs" } },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "Item2ShopTaxPoliciesData" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Listing" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "taxPolicies" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "displayRate" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "displayLocation" },
                },
                { kind: "Field", name: { kind: "Name", value: "policyType" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ShopPoliciesDataFragment, unknown>;
export const Item2TitleDataFragmentDoc = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "Item2TitleData" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Listing" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "title" } },
          { kind: "Field", name: { kind: "Name", value: "listingType" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "condition" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "conditionSlug" },
                },
                { kind: "Field", name: { kind: "Name", value: "displayName" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "categories" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "slug" } },
                { kind: "Field", name: { kind: "Name", value: "name" } },
                { kind: "Field", name: { kind: "Name", value: "rootSlug" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "csp" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "id" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<Item2TitleDataFragment, unknown>;
export const Item2VideoFragmentDoc = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "Item2Video" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Listing" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "video" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "youtubeVideoId" },
                },
                { kind: "Field", name: { kind: "Name", value: "empty" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "csp" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "videos" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "videoLinks" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "youtubeVideoId" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<Item2VideoFragment, unknown>;
export const ItemPageEndedListingExploreRowFieldsFragmentDoc = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "ItemPageEndedListingExploreRowFields" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Listing" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "csp" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "id" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  ItemPageEndedListingExploreRowFieldsFragment,
  unknown
>;
export const ListingImagesFieldsFragmentDoc = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "ListingImagesFields" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Listing" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "images" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "transform" },
                      value: {
                        kind: "StringValue",
                        value: "card_square",
                        block: false,
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "count" },
                      value: { kind: "IntValue", value: "3" },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "scope" },
                      value: {
                        kind: "StringValue",
                        value: "photos",
                        block: false,
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "type" },
                      value: {
                        kind: "StringValue",
                        value: "Product",
                        block: false,
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "source" } },
              ],
            },
          },
          {
            kind: "Field",
            alias: { kind: "Name", value: "largeSquareImages" },
            name: { kind: "Name", value: "images" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "transform" },
                      value: {
                        kind: "StringValue",
                        value: "large_square",
                        block: false,
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "count" },
                      value: { kind: "IntValue", value: "3" },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "scope" },
                      value: {
                        kind: "StringValue",
                        value: "photos",
                        block: false,
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "type" },
                      value: {
                        kind: "StringValue",
                        value: "Product",
                        block: false,
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "source" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ListingImagesFieldsFragment, unknown>;
export const ListingsCollectionFragmentDoc = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "ListingsCollection" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Listing" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "usOutlet" } },
          { kind: "Field", name: { kind: "Name", value: "bumped" } },
          { kind: "Field", name: { kind: "Name", value: "categoryUuids" } },
          { kind: "Field", name: { kind: "Name", value: "slug" } },
          { kind: "Field", name: { kind: "Name", value: "title" } },
          { kind: "Field", name: { kind: "Name", value: "description" } },
          { kind: "Field", name: { kind: "Name", value: "listingType" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "condition" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "displayName" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "conditionSlug" },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "price" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "amountCents" } },
                { kind: "Field", name: { kind: "Name", value: "display" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "pricing" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "buyerPrice" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "display" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "currency" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "amountCents" },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "originalPrice" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "display" },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "ribbon" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "display" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "reason" },
                      },
                    ],
                  },
                },
              ],
            },
          },
          { kind: "Field", name: { kind: "Name", value: "watching" } },
          { kind: "Field", name: { kind: "Name", value: "state" } },
          { kind: "Field", name: { kind: "Name", value: "stateDescription" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "shipping" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "shippingPrices" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "shippingMethod" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "carrierCalculated" },
                      },
                      {
                        kind: "Field",
                        name: {
                          kind: "Name",
                          value: "destinationPostalCodeNeeded",
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "rate" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "amount" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "amountCents" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "currency" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "display" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "freeExpeditedShipping" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "localPickupOnly" },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "shop" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "address" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "country" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "_id" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "countryCode" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "name" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "AddToCartButtonFields" },
          },
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "WatchBadgeData" },
          },
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "ListingGreatValueData" },
          },
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "ListingCreateOfferButtonData" },
          },
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "ListingImagesFields" },
          },
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "ListingCardCPOData" },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "mParticleListingFields" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Listing" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "title" } },
          { kind: "Field", name: { kind: "Name", value: "brandSlug" } },
          { kind: "Field", name: { kind: "Name", value: "categoryRootUuid" } },
          { kind: "Field", name: { kind: "Name", value: "make" } },
          { kind: "Field", name: { kind: "Name", value: "categoryUuids" } },
          { kind: "Field", name: { kind: "Name", value: "state" } },
          { kind: "Field", name: { kind: "Name", value: "listingType" } },
          { kind: "Field", name: { kind: "Name", value: "bumpEligible" } },
          { kind: "Field", name: { kind: "Name", value: "shopId" } },
          { kind: "Field", name: { kind: "Name", value: "inventory" } },
          { kind: "Field", name: { kind: "Name", value: "soldAsIs" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "acceptedPaymentMethods" },
          },
          { kind: "Field", name: { kind: "Name", value: "currency" } },
          { kind: "Field", name: { kind: "Name", value: "usOutlet" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "condition" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "conditionUuid" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "conditionSlug" },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "categories" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "slug" } },
                { kind: "Field", name: { kind: "Name", value: "rootSlug" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "csp" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "slug" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "brand" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      { kind: "Field", name: { kind: "Name", value: "slug" } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "pricing" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "buyerPrice" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "amount" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "currency" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "amountCents" },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "publishedAt" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "seconds" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "sale" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "code" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "buyerIneligibilityReason" },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "shipping" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "shippingPrices" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "shippingMethod" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "carrierCalculated" },
                      },
                      {
                        kind: "Field",
                        name: {
                          kind: "Name",
                          value: "destinationPostalCodeNeeded",
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "rate" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "amount" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "amountCents" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "currency" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "display" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "freeExpeditedShipping" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "localPickupOnly" },
                },
                { kind: "Field", name: { kind: "Name", value: "localPickup" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "certifiedPreOwned" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "title" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "badgeIconUrl" },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "shop" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "address" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "countryCode" },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "returnPolicy" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "newReturnWindowDays" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "usedReturnWindowDays" },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "AddToCartButtonFields" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Listing" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "sellerId" } },
          { kind: "Field", name: { kind: "Name", value: "listingType" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "pricing" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "buyerPrice" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "amount" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "amountCents" },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "preorderInfo" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "onPreorder" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "estimatedShipDate" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "seconds" },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "WatchBadgeData" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Listing" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "title" } },
          { kind: "Field", name: { kind: "Name", value: "sellerId" } },
          { kind: "Field", name: { kind: "Name", value: "watching" } },
          {
            kind: "Field",
            alias: { kind: "Name", value: "watchThumbnails" },
            name: { kind: "Name", value: "images" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "type" },
                      value: {
                        kind: "StringValue",
                        value: "Product",
                        block: false,
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "scope" },
                      value: {
                        kind: "StringValue",
                        value: "photos",
                        block: false,
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "transform" },
                      value: {
                        kind: "StringValue",
                        value: "card_square",
                        block: false,
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "count" },
                      value: { kind: "IntValue", value: "1" },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "source" } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "ListingGreatValueData" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Listing" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "pricing" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "buyerPrice" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "currency" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "amountCents" },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "condition" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "conditionSlug" },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "priceRecommendation" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "priceMiddle" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "amountCents" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "currency" },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "shop" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "address" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "country" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "_id" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "countryCode" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          { kind: "Field", name: { kind: "Name", value: "currency" } },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "ListingCreateOfferButtonData" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Listing" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "state" } },
          { kind: "Field", name: { kind: "Name", value: "offersEnabled" } },
          { kind: "Field", name: { kind: "Name", value: "listingType" } },
          { kind: "Field", name: { kind: "Name", value: "sellerId" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "isBuyerOfferEligible" },
          },
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "mParticleListingFields" },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "ListingImagesFields" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Listing" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "images" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "transform" },
                      value: {
                        kind: "StringValue",
                        value: "card_square",
                        block: false,
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "count" },
                      value: { kind: "IntValue", value: "3" },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "scope" },
                      value: {
                        kind: "StringValue",
                        value: "photos",
                        block: false,
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "type" },
                      value: {
                        kind: "StringValue",
                        value: "Product",
                        block: false,
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "source" } },
              ],
            },
          },
          {
            kind: "Field",
            alias: { kind: "Name", value: "largeSquareImages" },
            name: { kind: "Name", value: "images" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "transform" },
                      value: {
                        kind: "StringValue",
                        value: "large_square",
                        block: false,
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "count" },
                      value: { kind: "IntValue", value: "3" },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "scope" },
                      value: {
                        kind: "StringValue",
                        value: "photos",
                        block: false,
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "type" },
                      value: {
                        kind: "StringValue",
                        value: "Product",
                        block: false,
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "source" } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "ListingCardCPOData" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Listing" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "certifiedPreOwned" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "title" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "badgeIconUrl" },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ListingsCollectionFragment, unknown>;
export const BumpKeyFragmentDoc = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "BumpKey" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Listing" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "bumpKey" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "key" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<BumpKeyFragment, unknown>;
export const ItemPageEndedListingExploreRowSearchFieldsFragmentDoc = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: {
        kind: "Name",
        value: "ItemPageEndedListingExploreRowSearchFields",
      },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Listing" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "ListingsCollection" },
          },
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "mParticleListingFields" },
          },
          { kind: "FragmentSpread", name: { kind: "Name", value: "BumpKey" } },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "AddToCartButtonFields" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Listing" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "sellerId" } },
          { kind: "Field", name: { kind: "Name", value: "listingType" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "pricing" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "buyerPrice" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "amount" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "amountCents" },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "preorderInfo" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "onPreorder" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "estimatedShipDate" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "seconds" },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "WatchBadgeData" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Listing" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "title" } },
          { kind: "Field", name: { kind: "Name", value: "sellerId" } },
          { kind: "Field", name: { kind: "Name", value: "watching" } },
          {
            kind: "Field",
            alias: { kind: "Name", value: "watchThumbnails" },
            name: { kind: "Name", value: "images" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "type" },
                      value: {
                        kind: "StringValue",
                        value: "Product",
                        block: false,
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "scope" },
                      value: {
                        kind: "StringValue",
                        value: "photos",
                        block: false,
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "transform" },
                      value: {
                        kind: "StringValue",
                        value: "card_square",
                        block: false,
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "count" },
                      value: { kind: "IntValue", value: "1" },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "source" } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "ListingGreatValueData" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Listing" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "pricing" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "buyerPrice" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "currency" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "amountCents" },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "condition" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "conditionSlug" },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "priceRecommendation" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "priceMiddle" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "amountCents" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "currency" },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "shop" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "address" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "country" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "_id" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "countryCode" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          { kind: "Field", name: { kind: "Name", value: "currency" } },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "mParticleListingFields" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Listing" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "title" } },
          { kind: "Field", name: { kind: "Name", value: "brandSlug" } },
          { kind: "Field", name: { kind: "Name", value: "categoryRootUuid" } },
          { kind: "Field", name: { kind: "Name", value: "make" } },
          { kind: "Field", name: { kind: "Name", value: "categoryUuids" } },
          { kind: "Field", name: { kind: "Name", value: "state" } },
          { kind: "Field", name: { kind: "Name", value: "listingType" } },
          { kind: "Field", name: { kind: "Name", value: "bumpEligible" } },
          { kind: "Field", name: { kind: "Name", value: "shopId" } },
          { kind: "Field", name: { kind: "Name", value: "inventory" } },
          { kind: "Field", name: { kind: "Name", value: "soldAsIs" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "acceptedPaymentMethods" },
          },
          { kind: "Field", name: { kind: "Name", value: "currency" } },
          { kind: "Field", name: { kind: "Name", value: "usOutlet" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "condition" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "conditionUuid" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "conditionSlug" },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "categories" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "slug" } },
                { kind: "Field", name: { kind: "Name", value: "rootSlug" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "csp" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "slug" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "brand" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      { kind: "Field", name: { kind: "Name", value: "slug" } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "pricing" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "buyerPrice" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "amount" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "currency" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "amountCents" },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "publishedAt" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "seconds" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "sale" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "code" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "buyerIneligibilityReason" },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "shipping" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "shippingPrices" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "shippingMethod" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "carrierCalculated" },
                      },
                      {
                        kind: "Field",
                        name: {
                          kind: "Name",
                          value: "destinationPostalCodeNeeded",
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "rate" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "amount" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "amountCents" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "currency" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "display" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "freeExpeditedShipping" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "localPickupOnly" },
                },
                { kind: "Field", name: { kind: "Name", value: "localPickup" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "certifiedPreOwned" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "title" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "badgeIconUrl" },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "shop" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "address" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "countryCode" },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "returnPolicy" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "newReturnWindowDays" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "usedReturnWindowDays" },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "ListingCreateOfferButtonData" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Listing" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "state" } },
          { kind: "Field", name: { kind: "Name", value: "offersEnabled" } },
          { kind: "Field", name: { kind: "Name", value: "listingType" } },
          { kind: "Field", name: { kind: "Name", value: "sellerId" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "isBuyerOfferEligible" },
          },
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "mParticleListingFields" },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "ListingImagesFields" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Listing" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "images" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "transform" },
                      value: {
                        kind: "StringValue",
                        value: "card_square",
                        block: false,
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "count" },
                      value: { kind: "IntValue", value: "3" },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "scope" },
                      value: {
                        kind: "StringValue",
                        value: "photos",
                        block: false,
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "type" },
                      value: {
                        kind: "StringValue",
                        value: "Product",
                        block: false,
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "source" } },
              ],
            },
          },
          {
            kind: "Field",
            alias: { kind: "Name", value: "largeSquareImages" },
            name: { kind: "Name", value: "images" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "transform" },
                      value: {
                        kind: "StringValue",
                        value: "large_square",
                        block: false,
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "count" },
                      value: { kind: "IntValue", value: "3" },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "scope" },
                      value: {
                        kind: "StringValue",
                        value: "photos",
                        block: false,
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "type" },
                      value: {
                        kind: "StringValue",
                        value: "Product",
                        block: false,
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "source" } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "ListingCardCPOData" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Listing" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "certifiedPreOwned" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "title" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "badgeIconUrl" },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "ListingsCollection" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Listing" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "usOutlet" } },
          { kind: "Field", name: { kind: "Name", value: "bumped" } },
          { kind: "Field", name: { kind: "Name", value: "categoryUuids" } },
          { kind: "Field", name: { kind: "Name", value: "slug" } },
          { kind: "Field", name: { kind: "Name", value: "title" } },
          { kind: "Field", name: { kind: "Name", value: "description" } },
          { kind: "Field", name: { kind: "Name", value: "listingType" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "condition" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "displayName" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "conditionSlug" },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "price" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "amountCents" } },
                { kind: "Field", name: { kind: "Name", value: "display" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "pricing" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "buyerPrice" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "display" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "currency" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "amountCents" },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "originalPrice" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "display" },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "ribbon" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "display" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "reason" },
                      },
                    ],
                  },
                },
              ],
            },
          },
          { kind: "Field", name: { kind: "Name", value: "watching" } },
          { kind: "Field", name: { kind: "Name", value: "state" } },
          { kind: "Field", name: { kind: "Name", value: "stateDescription" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "shipping" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "shippingPrices" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "shippingMethod" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "carrierCalculated" },
                      },
                      {
                        kind: "Field",
                        name: {
                          kind: "Name",
                          value: "destinationPostalCodeNeeded",
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "rate" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "amount" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "amountCents" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "currency" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "display" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "freeExpeditedShipping" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "localPickupOnly" },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "shop" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "address" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "country" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "_id" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "countryCode" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "name" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "AddToCartButtonFields" },
          },
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "WatchBadgeData" },
          },
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "ListingGreatValueData" },
          },
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "ListingCreateOfferButtonData" },
          },
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "ListingImagesFields" },
          },
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "ListingCardCPOData" },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "BumpKey" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Listing" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "bumpKey" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "key" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  ItemPageEndedListingExploreRowSearchFieldsFragment,
  unknown
>;
export const ListViewListingsFragmentDoc = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "ListViewListings" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Listing" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "categoryUuids" } },
          { kind: "Field", name: { kind: "Name", value: "state" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "shop" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "name" } },
                { kind: "Field", name: { kind: "Name", value: "slug" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "preferredSeller" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "quickShipper" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "quickResponder" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "address" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "locality" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "region" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "displayLocation" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "country" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "_id" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "countryCode" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "name" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "seller" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "feedbackSummary" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "receivedCount" },
                      },
                      {
                        kind: "Field",
                        name: {
                          kind: "Name",
                          value: "rollingRatingPercentage",
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "csp" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "webLink" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "href" } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "AddToCartButtonFields" },
          },
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "ListingCardFields" },
          },
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "ListingCreateOfferButtonData" },
          },
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "InOtherCartsCardData" },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "ListingForBuyerShippingFields" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Listing" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "shipping" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "freeExpeditedShipping" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "localPickupOnly" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "shippingPrices" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "shippingMethod" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "carrierCalculated" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "regional" },
                      },
                      {
                        kind: "Field",
                        name: {
                          kind: "Name",
                          value: "destinationPostalCodeNeeded",
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "postalCode" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "rate" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "amount" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "amountCents" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "currency" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "display" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "ListingGreatValueData" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Listing" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "pricing" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "buyerPrice" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "currency" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "amountCents" },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "condition" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "conditionSlug" },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "priceRecommendation" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "priceMiddle" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "amountCents" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "currency" },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "shop" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "address" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "country" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "_id" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "countryCode" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          { kind: "Field", name: { kind: "Name", value: "currency" } },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "ListingCardCPOData" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Listing" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "certifiedPreOwned" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "title" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "badgeIconUrl" },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "ListingForBuyerFields" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Listing" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "title" } },
          { kind: "Field", name: { kind: "Name", value: "slug" } },
          { kind: "Field", name: { kind: "Name", value: "listingType" } },
          { kind: "Field", name: { kind: "Name", value: "make" } },
          { kind: "Field", name: { kind: "Name", value: "model" } },
          { kind: "Field", name: { kind: "Name", value: "upc" } },
          { kind: "Field", name: { kind: "Name", value: "state" } },
          { kind: "Field", name: { kind: "Name", value: "stateDescription" } },
          { kind: "Field", name: { kind: "Name", value: "bumped" } },
          { kind: "Field", name: { kind: "Name", value: "watching" } },
          { kind: "Field", name: { kind: "Name", value: "soldAsIs" } },
          { kind: "Field", name: { kind: "Name", value: "usOutlet" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "publishedAt" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "seconds" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "condition" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "displayName" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "conditionSlug" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "conditionUuid" },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "pricing" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "buyerPrice" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "display" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "currency" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "amount" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "amountCents" },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "originalPrice" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "display" },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "ribbon" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "display" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "reason" },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "images" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "transform" },
                      value: {
                        kind: "StringValue",
                        value: "card_square",
                        block: false,
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "count" },
                      value: { kind: "IntValue", value: "3" },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "scope" },
                      value: {
                        kind: "StringValue",
                        value: "photos",
                        block: false,
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "type" },
                      value: {
                        kind: "StringValue",
                        value: "Product",
                        block: false,
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "source" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "shipping" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "shippingPrices" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "shippingMethod" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "carrierCalculated" },
                      },
                      {
                        kind: "Field",
                        name: {
                          kind: "Name",
                          value: "destinationPostalCodeNeeded",
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "rate" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "amount" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "amountCents" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "currency" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "display" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "freeExpeditedShipping" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "localPickupOnly" },
                },
                { kind: "Field", name: { kind: "Name", value: "localPickup" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "shop" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "name" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "returnPolicy" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "usedReturnWindowDays" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "newReturnWindowDays" },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "address" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "locality" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "region" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "country" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "_id" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "countryCode" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "name" },
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "displayLocation" },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "ListingForBuyerShippingFields" },
          },
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "ListingGreatValueData" },
          },
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "ListingCardCPOData" },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "WatchBadgeData" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Listing" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "title" } },
          { kind: "Field", name: { kind: "Name", value: "sellerId" } },
          { kind: "Field", name: { kind: "Name", value: "watching" } },
          {
            kind: "Field",
            alias: { kind: "Name", value: "watchThumbnails" },
            name: { kind: "Name", value: "images" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "type" },
                      value: {
                        kind: "StringValue",
                        value: "Product",
                        block: false,
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "scope" },
                      value: {
                        kind: "StringValue",
                        value: "photos",
                        block: false,
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "transform" },
                      value: {
                        kind: "StringValue",
                        value: "card_square",
                        block: false,
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "count" },
                      value: { kind: "IntValue", value: "1" },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "source" } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "mParticleListingFields" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Listing" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "title" } },
          { kind: "Field", name: { kind: "Name", value: "brandSlug" } },
          { kind: "Field", name: { kind: "Name", value: "categoryRootUuid" } },
          { kind: "Field", name: { kind: "Name", value: "make" } },
          { kind: "Field", name: { kind: "Name", value: "categoryUuids" } },
          { kind: "Field", name: { kind: "Name", value: "state" } },
          { kind: "Field", name: { kind: "Name", value: "listingType" } },
          { kind: "Field", name: { kind: "Name", value: "bumpEligible" } },
          { kind: "Field", name: { kind: "Name", value: "shopId" } },
          { kind: "Field", name: { kind: "Name", value: "inventory" } },
          { kind: "Field", name: { kind: "Name", value: "soldAsIs" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "acceptedPaymentMethods" },
          },
          { kind: "Field", name: { kind: "Name", value: "currency" } },
          { kind: "Field", name: { kind: "Name", value: "usOutlet" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "condition" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "conditionUuid" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "conditionSlug" },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "categories" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "slug" } },
                { kind: "Field", name: { kind: "Name", value: "rootSlug" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "csp" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "slug" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "brand" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      { kind: "Field", name: { kind: "Name", value: "slug" } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "pricing" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "buyerPrice" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "amount" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "currency" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "amountCents" },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "publishedAt" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "seconds" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "sale" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "code" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "buyerIneligibilityReason" },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "shipping" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "shippingPrices" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "shippingMethod" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "carrierCalculated" },
                      },
                      {
                        kind: "Field",
                        name: {
                          kind: "Name",
                          value: "destinationPostalCodeNeeded",
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "rate" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "amount" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "amountCents" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "currency" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "display" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "freeExpeditedShipping" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "localPickupOnly" },
                },
                { kind: "Field", name: { kind: "Name", value: "localPickup" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "certifiedPreOwned" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "title" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "badgeIconUrl" },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "shop" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "address" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "countryCode" },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "returnPolicy" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "newReturnWindowDays" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "usedReturnWindowDays" },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "ListingCreateOfferButtonData" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Listing" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "state" } },
          { kind: "Field", name: { kind: "Name", value: "offersEnabled" } },
          { kind: "Field", name: { kind: "Name", value: "listingType" } },
          { kind: "Field", name: { kind: "Name", value: "sellerId" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "isBuyerOfferEligible" },
          },
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "mParticleListingFields" },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "AddToCartButtonFields" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Listing" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "sellerId" } },
          { kind: "Field", name: { kind: "Name", value: "listingType" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "pricing" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "buyerPrice" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "amount" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "amountCents" },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "preorderInfo" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "onPreorder" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "estimatedShipDate" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "seconds" },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "ListingCardFields" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Listing" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "ListingForBuyerFields" },
          },
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "WatchBadgeData" },
          },
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "ListingCreateOfferButtonData" },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "InOtherCartsCardData" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Listing" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "otherBuyersWithListingInCartCounts" },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ListViewListingsFragment, unknown>;
export const SellerListingPriceRecommendationFragmentDoc = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "SellerListingPriceRecommendation" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Listing" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "priceRecommendation" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "currency" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "shopCurrency" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "countryCode" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "shopCountryCode" },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "priceLow" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "amount" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "amountCents" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "display" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "currency" },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "priceMiddle" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "amount" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "amountCents" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "display" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "currency" },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "priceHigh" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "amount" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "amountCents" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "display" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "currency" },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<SellerListingPriceRecommendationFragment, unknown>;
export const SellerListingCardFragmentDoc = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "SellerListingCard" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Listing" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "sellerUuid" } },
          { kind: "Field", name: { kind: "Name", value: "title" } },
          { kind: "Field", name: { kind: "Name", value: "slug" } },
          { kind: "Field", name: { kind: "Name", value: "sku" } },
          { kind: "Field", name: { kind: "Name", value: "state" } },
          { kind: "Field", name: { kind: "Name", value: "inventory" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "condition" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "conditionSlug" },
                },
                { kind: "Field", name: { kind: "Name", value: "displayName" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "bumpRate" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "rate" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "sellerPrice" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "amount" } },
                { kind: "Field", name: { kind: "Name", value: "amountCents" } },
                { kind: "Field", name: { kind: "Name", value: "symbol" } },
                { kind: "Field", name: { kind: "Name", value: "currency" } },
                { kind: "Field", name: { kind: "Name", value: "display" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "images" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "transform" },
                      value: {
                        kind: "StringValue",
                        value: "card_square",
                        block: false,
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "count" },
                      value: { kind: "IntValue", value: "1" },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "source" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "counts" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "views" } },
                { kind: "Field", name: { kind: "Name", value: "offers" } },
                { kind: "Field", name: { kind: "Name", value: "watchers" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "inBuyerCarts" },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "orderStats" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "totalOrders" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "publishedAt" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "seconds" } },
              ],
            },
          },
          { kind: "Field", name: { kind: "Name", value: "hasInventory" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "isAutoOfferEligible" },
          },
          {
            kind: "Field",
            name: {
              kind: "Name",
              value: "affirmZeroPercentFinancingConfiguration",
            },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "isEligible" } },
                { kind: "Field", name: { kind: "Name", value: "isEnabled" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "autoOffers" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "shop" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "currency" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "canCurateSales" },
                },
              ],
            },
          },
          { kind: "Field", name: { kind: "Name", value: "exclusiveChannel" } },
          { kind: "Field", name: { kind: "Name", value: "relistable" } },
          { kind: "Field", name: { kind: "Name", value: "vatIncluded" } },
          { kind: "Field", name: { kind: "Name", value: "vatIncludedHint" } },
          { kind: "Field", name: { kind: "Name", value: "taxExempt" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "publishValidation" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "isValid" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "errors" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "message" },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "shipmentPackage" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "length" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "value" } },
                      { kind: "Field", name: { kind: "Name", value: "unit" } },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "width" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "value" } },
                      { kind: "Field", name: { kind: "Name", value: "unit" } },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "height" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "value" } },
                      { kind: "Field", name: { kind: "Name", value: "unit" } },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "weight" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "value" } },
                      { kind: "Field", name: { kind: "Name", value: "unit" } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<SellerListingCardFragment, unknown>;
export const AvailableSalesMembershipsFragmentDoc = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "AvailableSalesMemberships" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Listing" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "availableSalesMemberships" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "reverbSalesMemberships" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "listingId" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "sale" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "_id" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "name" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "percent" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "startsAt" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "seconds" },
                                  },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "endsAt" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "seconds" },
                                  },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "links" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "self" },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "href" },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "totalLiveListings" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "isListingInSale" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "isListingEligible" },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "sellerSalesMemberships" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "listingId" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "sale" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "_id" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "name" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "percent" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "startsAt" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "seconds" },
                                  },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "endsAt" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "seconds" },
                                  },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "links" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "self" },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "href" },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "totalLiveListings" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "isListingInSale" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "isListingEligible" },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<AvailableSalesMembershipsFragment, unknown>;
export const CollectionInfoFragmentDoc = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "CollectionInfo" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "CollectionHeader" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "title" } },
          { kind: "Field", name: { kind: "Name", value: "metaTitle" } },
          { kind: "Field", name: { kind: "Name", value: "collectionId" } },
          { kind: "Field", name: { kind: "Name", value: "collectionType" } },
          { kind: "Field", name: { kind: "Name", value: "description" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "fullBleedImage" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "source" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CollectionInfoFragment, unknown>;
export const MarketplaceSuggestionsFragmentDoc = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "MarketplaceSuggestions" },
      typeCondition: {
        kind: "NamedType",
        name: {
          kind: "Name",
          value: "reverb_search_SearchResponse_Suggestion",
        },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [{ kind: "Field", name: { kind: "Name", value: "text" } }],
      },
    },
  ],
} as unknown as DocumentNode<MarketplaceSuggestionsFragment, unknown>;
export const OutletListingsFieldsFragmentDoc = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "OutletListingsFields" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Listing" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "ListingCardFields" },
          },
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "AddToCartButtonFields" },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "ListingForBuyerShippingFields" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Listing" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "shipping" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "freeExpeditedShipping" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "localPickupOnly" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "shippingPrices" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "shippingMethod" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "carrierCalculated" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "regional" },
                      },
                      {
                        kind: "Field",
                        name: {
                          kind: "Name",
                          value: "destinationPostalCodeNeeded",
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "postalCode" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "rate" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "amount" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "amountCents" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "currency" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "display" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "ListingGreatValueData" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Listing" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "pricing" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "buyerPrice" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "currency" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "amountCents" },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "condition" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "conditionSlug" },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "priceRecommendation" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "priceMiddle" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "amountCents" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "currency" },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "shop" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "address" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "country" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "_id" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "countryCode" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          { kind: "Field", name: { kind: "Name", value: "currency" } },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "ListingCardCPOData" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Listing" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "certifiedPreOwned" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "title" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "badgeIconUrl" },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "ListingForBuyerFields" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Listing" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "title" } },
          { kind: "Field", name: { kind: "Name", value: "slug" } },
          { kind: "Field", name: { kind: "Name", value: "listingType" } },
          { kind: "Field", name: { kind: "Name", value: "make" } },
          { kind: "Field", name: { kind: "Name", value: "model" } },
          { kind: "Field", name: { kind: "Name", value: "upc" } },
          { kind: "Field", name: { kind: "Name", value: "state" } },
          { kind: "Field", name: { kind: "Name", value: "stateDescription" } },
          { kind: "Field", name: { kind: "Name", value: "bumped" } },
          { kind: "Field", name: { kind: "Name", value: "watching" } },
          { kind: "Field", name: { kind: "Name", value: "soldAsIs" } },
          { kind: "Field", name: { kind: "Name", value: "usOutlet" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "publishedAt" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "seconds" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "condition" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "displayName" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "conditionSlug" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "conditionUuid" },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "pricing" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "buyerPrice" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "display" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "currency" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "amount" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "amountCents" },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "originalPrice" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "display" },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "ribbon" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "display" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "reason" },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "images" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "transform" },
                      value: {
                        kind: "StringValue",
                        value: "card_square",
                        block: false,
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "count" },
                      value: { kind: "IntValue", value: "3" },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "scope" },
                      value: {
                        kind: "StringValue",
                        value: "photos",
                        block: false,
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "type" },
                      value: {
                        kind: "StringValue",
                        value: "Product",
                        block: false,
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "source" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "shipping" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "shippingPrices" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "shippingMethod" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "carrierCalculated" },
                      },
                      {
                        kind: "Field",
                        name: {
                          kind: "Name",
                          value: "destinationPostalCodeNeeded",
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "rate" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "amount" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "amountCents" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "currency" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "display" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "freeExpeditedShipping" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "localPickupOnly" },
                },
                { kind: "Field", name: { kind: "Name", value: "localPickup" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "shop" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "name" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "returnPolicy" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "usedReturnWindowDays" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "newReturnWindowDays" },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "address" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "locality" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "region" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "country" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "_id" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "countryCode" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "name" },
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "displayLocation" },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "ListingForBuyerShippingFields" },
          },
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "ListingGreatValueData" },
          },
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "ListingCardCPOData" },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "WatchBadgeData" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Listing" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "title" } },
          { kind: "Field", name: { kind: "Name", value: "sellerId" } },
          { kind: "Field", name: { kind: "Name", value: "watching" } },
          {
            kind: "Field",
            alias: { kind: "Name", value: "watchThumbnails" },
            name: { kind: "Name", value: "images" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "type" },
                      value: {
                        kind: "StringValue",
                        value: "Product",
                        block: false,
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "scope" },
                      value: {
                        kind: "StringValue",
                        value: "photos",
                        block: false,
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "transform" },
                      value: {
                        kind: "StringValue",
                        value: "card_square",
                        block: false,
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "count" },
                      value: { kind: "IntValue", value: "1" },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "source" } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "mParticleListingFields" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Listing" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "title" } },
          { kind: "Field", name: { kind: "Name", value: "brandSlug" } },
          { kind: "Field", name: { kind: "Name", value: "categoryRootUuid" } },
          { kind: "Field", name: { kind: "Name", value: "make" } },
          { kind: "Field", name: { kind: "Name", value: "categoryUuids" } },
          { kind: "Field", name: { kind: "Name", value: "state" } },
          { kind: "Field", name: { kind: "Name", value: "listingType" } },
          { kind: "Field", name: { kind: "Name", value: "bumpEligible" } },
          { kind: "Field", name: { kind: "Name", value: "shopId" } },
          { kind: "Field", name: { kind: "Name", value: "inventory" } },
          { kind: "Field", name: { kind: "Name", value: "soldAsIs" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "acceptedPaymentMethods" },
          },
          { kind: "Field", name: { kind: "Name", value: "currency" } },
          { kind: "Field", name: { kind: "Name", value: "usOutlet" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "condition" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "conditionUuid" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "conditionSlug" },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "categories" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "slug" } },
                { kind: "Field", name: { kind: "Name", value: "rootSlug" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "csp" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "slug" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "brand" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      { kind: "Field", name: { kind: "Name", value: "slug" } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "pricing" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "buyerPrice" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "amount" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "currency" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "amountCents" },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "publishedAt" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "seconds" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "sale" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "code" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "buyerIneligibilityReason" },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "shipping" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "shippingPrices" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "shippingMethod" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "carrierCalculated" },
                      },
                      {
                        kind: "Field",
                        name: {
                          kind: "Name",
                          value: "destinationPostalCodeNeeded",
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "rate" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "amount" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "amountCents" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "currency" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "display" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "freeExpeditedShipping" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "localPickupOnly" },
                },
                { kind: "Field", name: { kind: "Name", value: "localPickup" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "certifiedPreOwned" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "title" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "badgeIconUrl" },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "shop" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "address" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "countryCode" },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "returnPolicy" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "newReturnWindowDays" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "usedReturnWindowDays" },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "ListingCreateOfferButtonData" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Listing" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "state" } },
          { kind: "Field", name: { kind: "Name", value: "offersEnabled" } },
          { kind: "Field", name: { kind: "Name", value: "listingType" } },
          { kind: "Field", name: { kind: "Name", value: "sellerId" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "isBuyerOfferEligible" },
          },
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "mParticleListingFields" },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "ListingCardFields" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Listing" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "ListingForBuyerFields" },
          },
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "WatchBadgeData" },
          },
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "ListingCreateOfferButtonData" },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "AddToCartButtonFields" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Listing" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "sellerId" } },
          { kind: "Field", name: { kind: "Name", value: "listingType" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "pricing" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "buyerPrice" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "amount" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "amountCents" },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "preorderInfo" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "onPreorder" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "estimatedShipDate" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "seconds" },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<OutletListingsFieldsFragment, unknown>;
export const OrderPacklinkShipmentDataFragmentDoc = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "OrderPacklinkShipmentData" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Order" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "legacyOrderId" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "packlinkShipment" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "reference" } },
                { kind: "Field", name: { kind: "Name", value: "status" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<OrderPacklinkShipmentDataFragment, unknown>;
export const MyShopPacklinkAccountDataFragmentDoc = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "MyShopPacklinkAccountData" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "rql_Me" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "uuid" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "shop" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "packlink" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "account" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "status" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<MyShopPacklinkAccountDataFragment, unknown>;
export const myShopCountryFragmentDoc = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "myShopCountry" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "rql_Me" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "uuid" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "shop" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "originCountryCode" },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<myShopCountryFragment, unknown>;
export const PayStatementCreditCardListFieldsFragmentDoc = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "PayStatementCreditCardListFields" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "rql_Me" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "creditCards" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "cardType" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "needsReverification" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "primaryBilling" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "display" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "summary" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "expirationDate" },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "brand" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "type" } },
                      { kind: "Field", name: { kind: "Name", value: "name" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "__typename" },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<PayStatementCreditCardListFieldsFragment, unknown>;
export const PhotoTileDataFragmentDoc = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "PhotoTileData" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Listing" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "slug" } },
          { kind: "Field", name: { kind: "Name", value: "title" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "images" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "transform" },
                      value: {
                        kind: "StringValue",
                        value: "card_square",
                        block: false,
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "count" },
                      value: { kind: "IntValue", value: "1" },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "scope" },
                      value: {
                        kind: "StringValue",
                        value: "photos",
                        block: false,
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "type" },
                      value: {
                        kind: "StringValue",
                        value: "Product",
                        block: false,
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "source" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<PhotoTileDataFragment, unknown>;
export const TransactionTablePriceRecordsDataFragmentDoc = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "TransactionTablePriceRecordsData" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "PublicPriceRecord" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "condition" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "displayName" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "createdAt" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "seconds" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "amountProduct" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "display" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<TransactionTablePriceRecordsDataFragment, unknown>;
export const PriceGuideToolFormContainerDataFragmentDoc = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "PriceGuideToolFormContainerData" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "CSP" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "title" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "brand" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "name" } },
              ],
            },
          },
          { kind: "Field", name: { kind: "Name", value: "categoryRootUuid" } },
          { kind: "Field", name: { kind: "Name", value: "categoryUuids" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "image" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "transform" },
                      value: {
                        kind: "StringValue",
                        value: "card_square",
                        block: false,
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "source" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "canonicalProducts" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "finish" } },
                { kind: "Field", name: { kind: "Name", value: "model" } },
                { kind: "Field", name: { kind: "Name", value: "name" } },
                { kind: "Field", name: { kind: "Name", value: "year" } },
              ],
            },
          },
          { kind: "Field", name: { kind: "Name", value: "slug" } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<PriceGuideToolFormContainerDataFragment, unknown>;
export const PriceWithShippingSortListingDataFragmentDoc = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "PriceWithShippingSortListingData" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Listing" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "pricing" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "buyerPrice" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "amountCents" },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "shipping" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "shippingPrices" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "shippingMethod" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "rate" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "amountCents" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<PriceWithShippingSortListingDataFragment, unknown>;
export const CMSPageEducationSectionDataFragmentDoc = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "CMSPageEducationSectionData" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "CMSPage" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "slug" } },
          { kind: "Field", name: { kind: "Name", value: "title" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "image" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "imageUrl" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CMSPageEducationSectionDataFragment, unknown>;
export const ProductFamilyHeaderFieldsFragmentDoc = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "ProductFamilyHeaderFields" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "CSP" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "brand" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "slug" } },
                { kind: "Field", name: { kind: "Name", value: "name" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "categories" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "parent" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      { kind: "Field", name: { kind: "Name", value: "slug" } },
                      { kind: "Field", name: { kind: "Name", value: "name" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "cmsLink" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "href" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ProductFamilyHeaderFieldsFragment, unknown>;
export const NoCountFilterFragmentFragmentDoc = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "NoCountFilterFragment" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "reverb_search_Filter" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "name" } },
          { kind: "Field", name: { kind: "Name", value: "key" } },
          { kind: "Field", name: { kind: "Name", value: "aggregationName" } },
          { kind: "Field", name: { kind: "Name", value: "widgetType" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "options" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "name" } },
                { kind: "Field", name: { kind: "Name", value: "selected" } },
                { kind: "Field", name: { kind: "Name", value: "paramName" } },
                { kind: "Field", name: { kind: "Name", value: "setValues" } },
                { kind: "Field", name: { kind: "Name", value: "unsetValues" } },
                { kind: "Field", name: { kind: "Name", value: "all" } },
                { kind: "Field", name: { kind: "Name", value: "optionValue" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<NoCountFilterFragmentFragment, unknown>;
export const ListingSellCardDataFragmentDoc = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "ListingSellCardData" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Listing" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "title" } },
          { kind: "Field", name: { kind: "Name", value: "finish" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "canonicalProductId" },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "images" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "transform" },
                      value: {
                        kind: "StringValue",
                        value: "card_square",
                        block: false,
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "count" },
                      value: { kind: "IntValue", value: "3" },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "scope" },
                      value: {
                        kind: "StringValue",
                        value: "photos",
                        block: false,
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "type" },
                      value: {
                        kind: "StringValue",
                        value: "Product",
                        block: false,
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "source" } },
                { kind: "Field", name: { kind: "Name", value: "__typename" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "price" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "display" } },
              ],
            },
          },
          { kind: "Field", name: { kind: "Name", value: "make" } },
          { kind: "Field", name: { kind: "Name", value: "slug" } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ListingSellCardDataFragment, unknown>;
export const ShopAddressFieldsFragmentDoc = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "ShopAddressFields" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "core_apimessages_Address" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "primary" } },
          { kind: "Field", name: { kind: "Name", value: "name" } },
          { kind: "Field", name: { kind: "Name", value: "streetAddress" } },
          { kind: "Field", name: { kind: "Name", value: "extendedAddress" } },
          { kind: "Field", name: { kind: "Name", value: "region" } },
          { kind: "Field", name: { kind: "Name", value: "locality" } },
          { kind: "Field", name: { kind: "Name", value: "postalCode" } },
          { kind: "Field", name: { kind: "Name", value: "phone" } },
          { kind: "Field", name: { kind: "Name", value: "countryCode" } },
          { kind: "Field", name: { kind: "Name", value: "isComplete" } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ShopAddressFieldsFragment, unknown>;
export const SellFormSetupDataFragmentDoc = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "SellFormSetupData" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "rql_Me" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "uuid" } },
          { kind: "Field", name: { kind: "Name", value: "firstName" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "shop" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "name" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "currencySetting" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "defaultLocale" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "address" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      {
                        kind: "FragmentSpread",
                        name: { kind: "Name", value: "ShopAddressFields" },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "ShopAddressFields" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "core_apimessages_Address" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "primary" } },
          { kind: "Field", name: { kind: "Name", value: "name" } },
          { kind: "Field", name: { kind: "Name", value: "streetAddress" } },
          { kind: "Field", name: { kind: "Name", value: "extendedAddress" } },
          { kind: "Field", name: { kind: "Name", value: "region" } },
          { kind: "Field", name: { kind: "Name", value: "locality" } },
          { kind: "Field", name: { kind: "Name", value: "postalCode" } },
          { kind: "Field", name: { kind: "Name", value: "phone" } },
          { kind: "Field", name: { kind: "Name", value: "countryCode" } },
          { kind: "Field", name: { kind: "Name", value: "isComplete" } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<SellFormSetupDataFragment, unknown>;
export const SellSearchRowListingFragmentDoc = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "SellSearchRowListing" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Listing" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "title" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "images" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "transform" },
                      value: {
                        kind: "StringValue",
                        value: "card_square",
                        block: false,
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "type" },
                      value: {
                        kind: "StringValue",
                        value: "Product",
                        block: false,
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "count" },
                      value: { kind: "IntValue", value: "3" },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "scope" },
                      value: {
                        kind: "StringValue",
                        value: "photos",
                        block: false,
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "source" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<SellSearchRowListingFragment, unknown>;
export const SellFormViewCountryFieldsFragmentDoc = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "SellFormViewCountryFields" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "core_apimessages_CountriesResponse" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "countries" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "name" } },
                { kind: "Field", name: { kind: "Name", value: "countryCode" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<SellFormViewCountryFieldsFragment, unknown>;
export const SellFormCanonicalProductCardDataFragmentDoc = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "SellFormCanonicalProductCardData" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "CSP" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "title" } },
          { kind: "Field", name: { kind: "Name", value: "model" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "image" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "transform" },
                      value: {
                        kind: "StringValue",
                        value: "card_square",
                        block: false,
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "source" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "canonicalProducts" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "name" } },
                { kind: "Field", name: { kind: "Name", value: "model" } },
                { kind: "Field", name: { kind: "Name", value: "year" } },
                { kind: "Field", name: { kind: "Name", value: "finish" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "countryOfOrigin" },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "categories" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "name" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "brand" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "name" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<SellFormCanonicalProductCardDataFragment, unknown>;
export const ShippingProfilePricesFragmentDoc = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "ShippingProfilePrices" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Shop" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "shippingProfiles" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "name" } },
                { kind: "Field", name: { kind: "Name", value: "id" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "categoryUuids" },
                },
                { kind: "Field", name: { kind: "Name", value: "localPickup" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "allShippingRates" },
                  arguments: [
                    {
                      kind: "Argument",
                      name: { kind: "Name", value: "input" },
                      value: {
                        kind: "ObjectValue",
                        fields: [
                          {
                            kind: "ObjectField",
                            name: { kind: "Name", value: "inOriginalCurrency" },
                            value: { kind: "BooleanValue", value: true },
                          },
                        ],
                      },
                    },
                  ],
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "regionCode" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "regionName" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "rateType" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "rate" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "display" },
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "incrementalRate" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "display" },
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "expeditedRate" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "display" },
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "regionalRates" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "areas" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "rate" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "display" },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "regionalExpeditedRates" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "areas" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "rate" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "display" },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ShippingProfilePricesFragment, unknown>;
export const SellFormShopShippingFragmentDoc = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "SellFormShopShipping" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Shop" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "currencySetting" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "sameDayShippingConfigured" },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "shippingRegionSettings" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "regionCode" } },
                { kind: "Field", name: { kind: "Name", value: "regionName" } },
                { kind: "Field", name: { kind: "Name", value: "isDomestic" } },
                {
                  kind: "Field",
                  name: {
                    kind: "Name",
                    value: "allowedToShipCarrierCalculated",
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "address" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "region" } },
                { kind: "Field", name: { kind: "Name", value: "postalCode" } },
                { kind: "Field", name: { kind: "Name", value: "countryCode" } },
              ],
            },
          },
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "ShippingProfilePrices" },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "ShippingProfilePrices" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Shop" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "shippingProfiles" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "name" } },
                { kind: "Field", name: { kind: "Name", value: "id" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "categoryUuids" },
                },
                { kind: "Field", name: { kind: "Name", value: "localPickup" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "allShippingRates" },
                  arguments: [
                    {
                      kind: "Argument",
                      name: { kind: "Name", value: "input" },
                      value: {
                        kind: "ObjectValue",
                        fields: [
                          {
                            kind: "ObjectField",
                            name: { kind: "Name", value: "inOriginalCurrency" },
                            value: { kind: "BooleanValue", value: true },
                          },
                        ],
                      },
                    },
                  ],
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "regionCode" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "regionName" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "rateType" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "rate" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "display" },
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "incrementalRate" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "display" },
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "expeditedRate" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "display" },
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "regionalRates" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "areas" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "rate" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "display" },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "regionalExpeditedRates" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "areas" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "rate" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "display" },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<SellFormShopShippingFragment, unknown>;
export const HydrateSellFormFragmentDoc = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "HydrateSellForm" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Listing" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "state" } },
          { kind: "Field", name: { kind: "Name", value: "sku" } },
          { kind: "Field", name: { kind: "Name", value: "year" } },
          { kind: "Field", name: { kind: "Name", value: "make" } },
          { kind: "Field", name: { kind: "Name", value: "countryOfOrigin" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "canonicalProductId" },
          },
          { kind: "Field", name: { kind: "Name", value: "subcategoryUuids" } },
          { kind: "Field", name: { kind: "Name", value: "categoryRootUuid" } },
          { kind: "Field", name: { kind: "Name", value: "seedId" } },
          { kind: "Field", name: { kind: "Name", value: "seedType" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "publishedAt" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "seconds" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "categories" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "name" } },
                { kind: "Field", name: { kind: "Name", value: "root" } },
                { kind: "Field", name: { kind: "Name", value: "leaf" } },
                { kind: "Field", name: { kind: "Name", value: "rootSlug" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "localizedContents" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "locale" } },
                { kind: "Field", name: { kind: "Name", value: "make" } },
                { kind: "Field", name: { kind: "Name", value: "model" } },
                { kind: "Field", name: { kind: "Name", value: "finish" } },
                { kind: "Field", name: { kind: "Name", value: "title" } },
                { kind: "Field", name: { kind: "Name", value: "description" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "prop65Warning" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "defaultForShop" },
                },
              ],
            },
          },
          { kind: "Field", name: { kind: "Name", value: "handmade" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "traits" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "keyId" } },
                { kind: "Field", name: { kind: "Name", value: "valueId" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "condition" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "conditionSlug" },
                },
              ],
            },
          },
          { kind: "Field", name: { kind: "Name", value: "hasInventory" } },
          { kind: "Field", name: { kind: "Name", value: "inventory" } },
          { kind: "Field", name: { kind: "Name", value: "upc" } },
          { kind: "Field", name: { kind: "Name", value: "upcDoesNotApply" } },
          { kind: "Field", name: { kind: "Name", value: "taxExempt" } },
          { kind: "Field", name: { kind: "Name", value: "relistable" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "video" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "link" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "bumpRate" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "rate" } },
              ],
            },
          },
          { kind: "Field", name: { kind: "Name", value: "bumpEligible" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "images" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "type" },
                      value: {
                        kind: "StringValue",
                        value: "Product",
                        block: false,
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "scope" },
                      value: {
                        kind: "StringValue",
                        value: "photos",
                        block: false,
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "transform" },
                      value: {
                        kind: "StringValue",
                        value: "card_square",
                        block: false,
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "source" } },
                { kind: "Field", name: { kind: "Name", value: "itemId" } },
                { kind: "Field", name: { kind: "Name", value: "version" } },
                { kind: "Field", name: { kind: "Name", value: "itemType" } },
                { kind: "Field", name: { kind: "Name", value: "publicId" } },
                { kind: "Field", name: { kind: "Name", value: "height" } },
                { kind: "Field", name: { kind: "Name", value: "width" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "transformation" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "x" } },
                      { kind: "Field", name: { kind: "Name", value: "y" } },
                      { kind: "Field", name: { kind: "Name", value: "width" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "height" },
                      },
                      { kind: "Field", name: { kind: "Name", value: "crop" } },
                      { kind: "Field", name: { kind: "Name", value: "angle" } },
                    ],
                  },
                },
              ],
            },
          },
          { kind: "Field", name: { kind: "Name", value: "shippingProfileId" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "allShippingPrices" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "localPickup" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "shippingPrices" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "shippingRegionCode" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "shippingMethod" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "carrierCalculated" },
                      },
                      {
                        kind: "Field",
                        name: {
                          kind: "Name",
                          value: "originalRateVatExcluded",
                        },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "amount" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "currency" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "shipmentPackage" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "length" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "value" } },
                      { kind: "Field", name: { kind: "Name", value: "unit" } },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "width" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "value" } },
                      { kind: "Field", name: { kind: "Name", value: "unit" } },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "height" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "value" } },
                      { kind: "Field", name: { kind: "Name", value: "unit" } },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "weight" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "value" } },
                      { kind: "Field", name: { kind: "Name", value: "unit" } },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "allowedCarriers" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "packageSizeSuggestionId" },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "prefersReverbShippingLabel" },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "sellerPrice" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "amountCents" } },
                { kind: "Field", name: { kind: "Name", value: "currency" } },
                { kind: "Field", name: { kind: "Name", value: "amount" } },
                { kind: "Field", name: { kind: "Name", value: "display" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "sellerCost" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "amountCents" } },
                { kind: "Field", name: { kind: "Name", value: "currency" } },
                { kind: "Field", name: { kind: "Name", value: "amount" } },
                { kind: "Field", name: { kind: "Name", value: "display" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "sellerReportedMap" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "amountCents" } },
                { kind: "Field", name: { kind: "Name", value: "currency" } },
                { kind: "Field", name: { kind: "Name", value: "amount" } },
                { kind: "Field", name: { kind: "Name", value: "display" } },
              ],
            },
          },
          { kind: "Field", name: { kind: "Name", value: "vatIncluded" } },
          { kind: "Field", name: { kind: "Name", value: "vatIncludedHint" } },
          { kind: "Field", name: { kind: "Name", value: "soldAsIs" } },
          { kind: "Field", name: { kind: "Name", value: "listingType" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "overriddenAttributes" },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "preorderInfo" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "shipDate" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "seconds" },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "leadTimeDays" },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "shop" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "currencySetting" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "defaultLocale" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "shopSupportedLocaleCodes" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "preferredSeller" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "hasAccountRep" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "inclusiveVatPricingEnabled" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "listableConditions" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "displayName" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "conditionSlug" },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "offerPolicy" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "autoRejectLowOffers" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "lowballPercentage" },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "returnPolicy" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "newReturnWindowDays" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "usedReturnWindowDays" },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "taxPolicies" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "vatPolicy" },
                      },
                    ],
                  },
                },
                { kind: "Field", name: { kind: "Name", value: "vatId" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "firstTimeSeller" },
                },
                {
                  kind: "FragmentSpread",
                  name: { kind: "Name", value: "SellFormShopShipping" },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "scheduledPriceDrops" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "active" } },
                { kind: "Field", name: { kind: "Name", value: "dropAt" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "newPrice" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "amountCents" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "currency" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "amount" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "display" },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "ShippingProfilePrices" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Shop" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "shippingProfiles" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "name" } },
                { kind: "Field", name: { kind: "Name", value: "id" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "categoryUuids" },
                },
                { kind: "Field", name: { kind: "Name", value: "localPickup" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "allShippingRates" },
                  arguments: [
                    {
                      kind: "Argument",
                      name: { kind: "Name", value: "input" },
                      value: {
                        kind: "ObjectValue",
                        fields: [
                          {
                            kind: "ObjectField",
                            name: { kind: "Name", value: "inOriginalCurrency" },
                            value: { kind: "BooleanValue", value: true },
                          },
                        ],
                      },
                    },
                  ],
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "regionCode" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "regionName" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "rateType" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "rate" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "display" },
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "incrementalRate" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "display" },
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "expeditedRate" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "display" },
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "regionalRates" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "areas" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "rate" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "display" },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "regionalExpeditedRates" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "areas" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "rate" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "display" },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "SellFormShopShipping" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Shop" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "currencySetting" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "sameDayShippingConfigured" },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "shippingRegionSettings" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "regionCode" } },
                { kind: "Field", name: { kind: "Name", value: "regionName" } },
                { kind: "Field", name: { kind: "Name", value: "isDomestic" } },
                {
                  kind: "Field",
                  name: {
                    kind: "Name",
                    value: "allowedToShipCarrierCalculated",
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "address" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "region" } },
                { kind: "Field", name: { kind: "Name", value: "postalCode" } },
                { kind: "Field", name: { kind: "Name", value: "countryCode" } },
              ],
            },
          },
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "ShippingProfilePrices" },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<HydrateSellFormFragment, unknown>;
export const SellCardDataFragmentDoc = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "SellCardData" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "CSP" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "title" } },
          { kind: "Field", name: { kind: "Name", value: "finishes" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "image" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "transform" },
                      value: {
                        kind: "StringValue",
                        value: "card_square",
                        block: false,
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "source" } },
              ],
            },
          },
          { kind: "Field", name: { kind: "Name", value: "slug" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "brand" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "name" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "canonicalProductIds" },
          },
          { kind: "Field", name: { kind: "Name", value: "isTradeInEligible" } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<SellCardDataFragment, unknown>;
export const CSPPriceRecommendationDataFragmentDoc = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "CSPPriceRecommendationData" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "CSP" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "priceRecommendations" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "conditionUuids" },
                      value: {
                        kind: "ListValue",
                        values: [
                          {
                            kind: "StringValue",
                            value: "f7a3f48c-972a-44c6-b01a-0cd27488d3f6",
                            block: false,
                          },
                          {
                            kind: "StringValue",
                            value: "ac5b9c1e-dc78-466d-b0b3-7cf712967a48",
                            block: false,
                          },
                        ],
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "countryCode" },
                      value: {
                        kind: "Variable",
                        name: {
                          kind: "Name",
                          value: "priceRecommendationCountryCode",
                        },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "conditionUuid" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "priceLow" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "amountCents" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "amount" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "currency" },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "priceHigh" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "amountCents" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "amount" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "currency" },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CSPPriceRecommendationDataFragment, unknown>;
export const SellCardWithCSPPriceRecommendationDataFragmentDoc = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "SellCardWithCSPPriceRecommendationData" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "CSP" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "SellCardData" },
          },
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "CSPPriceRecommendationData" },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "SellCardData" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "CSP" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "title" } },
          { kind: "Field", name: { kind: "Name", value: "finishes" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "image" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "transform" },
                      value: {
                        kind: "StringValue",
                        value: "card_square",
                        block: false,
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "source" } },
              ],
            },
          },
          { kind: "Field", name: { kind: "Name", value: "slug" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "brand" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "name" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "canonicalProductIds" },
          },
          { kind: "Field", name: { kind: "Name", value: "isTradeInEligible" } },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "CSPPriceRecommendationData" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "CSP" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "priceRecommendations" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "conditionUuids" },
                      value: {
                        kind: "ListValue",
                        values: [
                          {
                            kind: "StringValue",
                            value: "f7a3f48c-972a-44c6-b01a-0cd27488d3f6",
                            block: false,
                          },
                          {
                            kind: "StringValue",
                            value: "ac5b9c1e-dc78-466d-b0b3-7cf712967a48",
                            block: false,
                          },
                        ],
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "countryCode" },
                      value: {
                        kind: "Variable",
                        name: {
                          kind: "Name",
                          value: "priceRecommendationCountryCode",
                        },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "conditionUuid" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "priceLow" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "amountCents" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "amount" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "currency" },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "priceHigh" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "amountCents" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "amount" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "currency" },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  SellCardWithCSPPriceRecommendationDataFragment,
  unknown
>;
export const BumpRecommendationsFragmentFragmentDoc = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "BumpRecommendationsFragment" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Listing" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "bumpRecommendations" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "bumpRecommendationAmount" },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<BumpRecommendationsFragmentFragment, unknown>;
export const CoreSellerDashboardListingFragmentDoc = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "CoreSellerDashboardListing" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Listing" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "slug" } },
          { kind: "Field", name: { kind: "Name", value: "title" } },
          { kind: "Field", name: { kind: "Name", value: "sku" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "price" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "display" } },
                { kind: "Field", name: { kind: "Name", value: "amountCents" } },
                { kind: "Field", name: { kind: "Name", value: "currency" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "bumpRate" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "rate" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "bumpInteractionsCounts" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "total" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "images" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "transform" },
                      value: {
                        kind: "StringValue",
                        value: "thumb",
                        block: false,
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "count" },
                      value: { kind: "IntValue", value: "1" },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "scope" },
                      value: {
                        kind: "StringValue",
                        value: "photos",
                        block: false,
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "type" },
                      value: {
                        kind: "StringValue",
                        value: "Product",
                        block: false,
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "source" } },
              ],
            },
          },
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "BumpRecommendationsAttributes" },
          },
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "BumpRecommendationsFragment" },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "BumpRecommendationsAttributes" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Listing" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "condition" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "conditionUuid" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "conditionSlug" },
                },
                { kind: "Field", name: { kind: "Name", value: "displayName" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "csp" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "title" } },
                { kind: "Field", name: { kind: "Name", value: "slug" } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "BumpRecommendationsFragment" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Listing" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "bumpRecommendations" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "bumpRecommendationAmount" },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CoreSellerDashboardListingFragment, unknown>;
export const ShopCampaignSummaryTableFragmentDoc = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "ShopCampaignSummaryTable" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "core_apimessages_MyShopCampaign" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "active" } },
          { kind: "Field", name: { kind: "Name", value: "code" } },
          { kind: "Field", name: { kind: "Name", value: "campaignType" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "useRetroactiveSends" },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "applyToNewConditions" },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "applyToUsedConditions" },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "applyToBStockConditions" },
          },
          { kind: "Field", name: { kind: "Name", value: "discountSummary" } },
          { kind: "Field", name: { kind: "Name", value: "totalCouponCount" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "redeemedCouponCount" },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "minPurchaseAmount" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "display" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "excludedBrands" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "name" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "excludedCategories" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "name" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ShopCampaignSummaryTableFragment, unknown>;
export const ShopCardFieldsFragmentDoc = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "ShopCardFields" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Shop" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "name" } },
          { kind: "Field", name: { kind: "Name", value: "preferredSeller" } },
          { kind: "Field", name: { kind: "Name", value: "quickShipper" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "address" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "displayLocation" },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "user" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "avatar" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "source" },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "feedbackSummary" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: {
                          kind: "Name",
                          value: "rollingRatingPercentage",
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "receivedCount" },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ShopCardFieldsFragment, unknown>;
export const CartApplePayHookFieldsFragmentDoc = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "CartApplePayHookFields" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Checkout" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "type" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "total" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "amount" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "amountCents" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "currency" },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "lineItems" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "type" } },
                { kind: "Field", name: { kind: "Name", value: "label" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "amount" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "amount" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "amountCents" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "currency" },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "CheckoutApplePayLineItemFields" },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "CheckoutApplePayLineItemFields" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Checkout" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "total" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "amount" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "amount" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "currency" },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "lineItems" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "type" } },
                { kind: "Field", name: { kind: "Name", value: "label" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "amount" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "amount" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "amountCents" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "currency" },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CartApplePayHookFieldsFragment, unknown>;
export const Item2ProductViewTrackingDataFragmentDoc = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "Item2ProductViewTrackingData" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Listing" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "acceptedPaymentMethods" },
          },
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "mParticleListingFields" },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "mParticleListingFields" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Listing" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "title" } },
          { kind: "Field", name: { kind: "Name", value: "brandSlug" } },
          { kind: "Field", name: { kind: "Name", value: "categoryRootUuid" } },
          { kind: "Field", name: { kind: "Name", value: "make" } },
          { kind: "Field", name: { kind: "Name", value: "categoryUuids" } },
          { kind: "Field", name: { kind: "Name", value: "state" } },
          { kind: "Field", name: { kind: "Name", value: "listingType" } },
          { kind: "Field", name: { kind: "Name", value: "bumpEligible" } },
          { kind: "Field", name: { kind: "Name", value: "shopId" } },
          { kind: "Field", name: { kind: "Name", value: "inventory" } },
          { kind: "Field", name: { kind: "Name", value: "soldAsIs" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "acceptedPaymentMethods" },
          },
          { kind: "Field", name: { kind: "Name", value: "currency" } },
          { kind: "Field", name: { kind: "Name", value: "usOutlet" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "condition" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "conditionUuid" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "conditionSlug" },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "categories" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "slug" } },
                { kind: "Field", name: { kind: "Name", value: "rootSlug" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "csp" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "slug" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "brand" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      { kind: "Field", name: { kind: "Name", value: "slug" } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "pricing" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "buyerPrice" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "amount" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "currency" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "amountCents" },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "publishedAt" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "seconds" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "sale" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "code" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "buyerIneligibilityReason" },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "shipping" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "shippingPrices" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "shippingMethod" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "carrierCalculated" },
                      },
                      {
                        kind: "Field",
                        name: {
                          kind: "Name",
                          value: "destinationPostalCodeNeeded",
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "rate" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "amount" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "amountCents" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "currency" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "display" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "freeExpeditedShipping" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "localPickupOnly" },
                },
                { kind: "Field", name: { kind: "Name", value: "localPickup" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "certifiedPreOwned" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "title" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "badgeIconUrl" },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "shop" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "address" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "countryCode" },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "returnPolicy" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "newReturnWindowDays" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "usedReturnWindowDays" },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<Item2ProductViewTrackingDataFragment, unknown>;
export const MixedFeedRowFragmentDoc = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "MixedFeedRow" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "reverb_feed_FeedEntry" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "type" } },
          { kind: "Field", name: { kind: "Name", value: "deleted" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "metadata" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "sourceType" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "localizedContents" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "locale" } },
                { kind: "Field", name: { kind: "Name", value: "title" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "shortDescription" },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "entry" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "InlineFragment",
                  typeCondition: {
                    kind: "NamedType",
                    name: { kind: "Name", value: "Article" },
                  },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      { kind: "Field", name: { kind: "Name", value: "title" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "images" },
                        arguments: [
                          {
                            kind: "Argument",
                            name: { kind: "Name", value: "input" },
                            value: {
                              kind: "ObjectValue",
                              fields: [
                                {
                                  kind: "ObjectField",
                                  name: { kind: "Name", value: "count" },
                                  value: { kind: "IntValue", value: "1" },
                                },
                                {
                                  kind: "ObjectField",
                                  name: { kind: "Name", value: "scope" },
                                  value: {
                                    kind: "StringValue",
                                    value: "full_bleed_image",
                                    block: false,
                                  },
                                },
                                {
                                  kind: "ObjectField",
                                  name: { kind: "Name", value: "transform" },
                                  value: {
                                    kind: "StringValue",
                                    value: "card_square",
                                    block: false,
                                  },
                                },
                              ],
                            },
                          },
                        ],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "source" },
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "webLink" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "href" },
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "articleCategories" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "name" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "slug" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: "InlineFragment",
                  typeCondition: {
                    kind: "NamedType",
                    name: { kind: "Name", value: "Listing" },
                  },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      {
                        kind: "FragmentSpread",
                        name: { kind: "Name", value: "ListingsCollection" },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "AddToCartButtonFields" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Listing" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "sellerId" } },
          { kind: "Field", name: { kind: "Name", value: "listingType" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "pricing" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "buyerPrice" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "amount" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "amountCents" },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "preorderInfo" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "onPreorder" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "estimatedShipDate" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "seconds" },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "WatchBadgeData" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Listing" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "title" } },
          { kind: "Field", name: { kind: "Name", value: "sellerId" } },
          { kind: "Field", name: { kind: "Name", value: "watching" } },
          {
            kind: "Field",
            alias: { kind: "Name", value: "watchThumbnails" },
            name: { kind: "Name", value: "images" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "type" },
                      value: {
                        kind: "StringValue",
                        value: "Product",
                        block: false,
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "scope" },
                      value: {
                        kind: "StringValue",
                        value: "photos",
                        block: false,
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "transform" },
                      value: {
                        kind: "StringValue",
                        value: "card_square",
                        block: false,
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "count" },
                      value: { kind: "IntValue", value: "1" },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "source" } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "ListingGreatValueData" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Listing" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "pricing" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "buyerPrice" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "currency" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "amountCents" },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "condition" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "conditionSlug" },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "priceRecommendation" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "priceMiddle" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "amountCents" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "currency" },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "shop" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "address" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "country" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "_id" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "countryCode" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          { kind: "Field", name: { kind: "Name", value: "currency" } },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "mParticleListingFields" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Listing" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "title" } },
          { kind: "Field", name: { kind: "Name", value: "brandSlug" } },
          { kind: "Field", name: { kind: "Name", value: "categoryRootUuid" } },
          { kind: "Field", name: { kind: "Name", value: "make" } },
          { kind: "Field", name: { kind: "Name", value: "categoryUuids" } },
          { kind: "Field", name: { kind: "Name", value: "state" } },
          { kind: "Field", name: { kind: "Name", value: "listingType" } },
          { kind: "Field", name: { kind: "Name", value: "bumpEligible" } },
          { kind: "Field", name: { kind: "Name", value: "shopId" } },
          { kind: "Field", name: { kind: "Name", value: "inventory" } },
          { kind: "Field", name: { kind: "Name", value: "soldAsIs" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "acceptedPaymentMethods" },
          },
          { kind: "Field", name: { kind: "Name", value: "currency" } },
          { kind: "Field", name: { kind: "Name", value: "usOutlet" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "condition" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "conditionUuid" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "conditionSlug" },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "categories" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "slug" } },
                { kind: "Field", name: { kind: "Name", value: "rootSlug" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "csp" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "slug" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "brand" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      { kind: "Field", name: { kind: "Name", value: "slug" } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "pricing" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "buyerPrice" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "amount" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "currency" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "amountCents" },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "publishedAt" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "seconds" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "sale" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "code" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "buyerIneligibilityReason" },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "shipping" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "shippingPrices" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "shippingMethod" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "carrierCalculated" },
                      },
                      {
                        kind: "Field",
                        name: {
                          kind: "Name",
                          value: "destinationPostalCodeNeeded",
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "rate" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "amount" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "amountCents" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "currency" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "display" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "freeExpeditedShipping" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "localPickupOnly" },
                },
                { kind: "Field", name: { kind: "Name", value: "localPickup" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "certifiedPreOwned" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "title" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "badgeIconUrl" },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "shop" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "address" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "countryCode" },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "returnPolicy" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "newReturnWindowDays" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "usedReturnWindowDays" },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "ListingCreateOfferButtonData" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Listing" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "state" } },
          { kind: "Field", name: { kind: "Name", value: "offersEnabled" } },
          { kind: "Field", name: { kind: "Name", value: "listingType" } },
          { kind: "Field", name: { kind: "Name", value: "sellerId" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "isBuyerOfferEligible" },
          },
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "mParticleListingFields" },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "ListingImagesFields" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Listing" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "images" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "transform" },
                      value: {
                        kind: "StringValue",
                        value: "card_square",
                        block: false,
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "count" },
                      value: { kind: "IntValue", value: "3" },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "scope" },
                      value: {
                        kind: "StringValue",
                        value: "photos",
                        block: false,
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "type" },
                      value: {
                        kind: "StringValue",
                        value: "Product",
                        block: false,
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "source" } },
              ],
            },
          },
          {
            kind: "Field",
            alias: { kind: "Name", value: "largeSquareImages" },
            name: { kind: "Name", value: "images" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "transform" },
                      value: {
                        kind: "StringValue",
                        value: "large_square",
                        block: false,
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "count" },
                      value: { kind: "IntValue", value: "3" },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "scope" },
                      value: {
                        kind: "StringValue",
                        value: "photos",
                        block: false,
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "type" },
                      value: {
                        kind: "StringValue",
                        value: "Product",
                        block: false,
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "source" } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "ListingCardCPOData" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Listing" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "certifiedPreOwned" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "title" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "badgeIconUrl" },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "ListingsCollection" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Listing" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "usOutlet" } },
          { kind: "Field", name: { kind: "Name", value: "bumped" } },
          { kind: "Field", name: { kind: "Name", value: "categoryUuids" } },
          { kind: "Field", name: { kind: "Name", value: "slug" } },
          { kind: "Field", name: { kind: "Name", value: "title" } },
          { kind: "Field", name: { kind: "Name", value: "description" } },
          { kind: "Field", name: { kind: "Name", value: "listingType" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "condition" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "displayName" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "conditionSlug" },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "price" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "amountCents" } },
                { kind: "Field", name: { kind: "Name", value: "display" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "pricing" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "buyerPrice" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "display" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "currency" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "amountCents" },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "originalPrice" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "display" },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "ribbon" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "display" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "reason" },
                      },
                    ],
                  },
                },
              ],
            },
          },
          { kind: "Field", name: { kind: "Name", value: "watching" } },
          { kind: "Field", name: { kind: "Name", value: "state" } },
          { kind: "Field", name: { kind: "Name", value: "stateDescription" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "shipping" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "shippingPrices" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "shippingMethod" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "carrierCalculated" },
                      },
                      {
                        kind: "Field",
                        name: {
                          kind: "Name",
                          value: "destinationPostalCodeNeeded",
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "rate" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "amount" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "amountCents" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "currency" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "display" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "freeExpeditedShipping" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "localPickupOnly" },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "shop" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "address" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "country" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "_id" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "countryCode" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "name" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "AddToCartButtonFields" },
          },
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "WatchBadgeData" },
          },
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "ListingGreatValueData" },
          },
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "ListingCreateOfferButtonData" },
          },
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "ListingImagesFields" },
          },
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "ListingCardCPOData" },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<MixedFeedRowFragment, unknown>;
export const Core_GetAdyenRegionDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "Core_GetAdyenRegion" },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "getAdyenRegion" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "webRegion" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Core_GetAdyenRegionQuery,
  Core_GetAdyenRegionQueryVariables
>;
export const Commons_Cms_ArticlesCollectionDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "Commons_Cms_ArticlesCollection" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "ids" } },
          type: {
            kind: "ListType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "articles" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "ids" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "ids" },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "title" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "articleCategories" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "name" } },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "webLink" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "href" } },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "images" },
                  arguments: [
                    {
                      kind: "Argument",
                      name: { kind: "Name", value: "input" },
                      value: {
                        kind: "ObjectValue",
                        fields: [
                          {
                            kind: "ObjectField",
                            name: { kind: "Name", value: "type" },
                            value: {
                              kind: "StringValue",
                              value: "Article",
                              block: false,
                            },
                          },
                          {
                            kind: "ObjectField",
                            name: { kind: "Name", value: "scope" },
                            value: {
                              kind: "StringValue",
                              value: "full_bleed_image",
                              block: false,
                            },
                          },
                          {
                            kind: "ObjectField",
                            name: { kind: "Name", value: "transform" },
                            value: {
                              kind: "StringValue",
                              value: "card_wide",
                              block: false,
                            },
                          },
                        ],
                      },
                    },
                  ],
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "source" },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Commons_Cms_ArticlesCollectionQuery,
  Commons_Cms_ArticlesCollectionQueryVariables
>;
export const Commons_Cms_BrandCollectionDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "Commons_Cms_BrandCollection" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "maxCount" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "Int" } },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "curatedSetId" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "onSale" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "Boolean" } },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "saleCuratedSetId" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "priceMax" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "priceMin" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "yearMax" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "yearMin" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "categorySlugs" },
          },
          type: {
            kind: "ListType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "query" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "listingsSearch" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "aggregationsOnly" },
                      value: { kind: "BooleanValue", value: false },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "withTopCollections" },
                      value: {
                        kind: "ObjectValue",
                        fields: [
                          {
                            kind: "ObjectField",
                            name: { kind: "Name", value: "brandUuids" },
                            value: {
                              kind: "Variable",
                              name: { kind: "Name", value: "maxCount" },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "curatedSetId" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "curatedSetId" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "onSale" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "onSale" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "saleCuratedSetId" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "saleCuratedSetId" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "priceMax" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "priceMax" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "priceMin" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "priceMin" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "yearMax" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "yearMax" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "yearMin" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "yearMin" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "categorySlugs" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "categorySlugs" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "query" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "query" },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "topCollections" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "brands" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "_id" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "name" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "slug" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "webLink" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "href" },
                                  },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "images" },
                              arguments: [
                                {
                                  kind: "Argument",
                                  name: { kind: "Name", value: "input" },
                                  value: {
                                    kind: "ObjectValue",
                                    fields: [
                                      {
                                        kind: "ObjectField",
                                        name: { kind: "Name", value: "scope" },
                                        value: {
                                          kind: "StringValue",
                                          value: "full_bleed_brand",
                                          block: false,
                                        },
                                      },
                                      {
                                        kind: "ObjectField",
                                        name: {
                                          kind: "Name",
                                          value: "transform",
                                        },
                                        value: {
                                          kind: "StringValue",
                                          value: "card_wide",
                                          block: false,
                                        },
                                      },
                                      {
                                        kind: "ObjectField",
                                        name: { kind: "Name", value: "type" },
                                        value: {
                                          kind: "StringValue",
                                          value: "Brand",
                                          block: false,
                                        },
                                      },
                                    ],
                                  },
                                },
                              ],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "_id" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "source" },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Commons_Cms_BrandCollectionQuery,
  Commons_Cms_BrandCollectionQueryVariables
>;
export const Commons_Cms_CategoryCollectionDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "Commons_Cms_CategoryCollection" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "categoryUuids" },
          },
          type: {
            kind: "ListType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "categories" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "ids" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "categoryUuids" },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "name" } },
                { kind: "Field", name: { kind: "Name", value: "slug" } },
                { kind: "Field", name: { kind: "Name", value: "rootSlug" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "cmsLink" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "href" } },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "images" },
                  arguments: [
                    {
                      kind: "Argument",
                      name: { kind: "Name", value: "input" },
                      value: {
                        kind: "ObjectValue",
                        fields: [
                          {
                            kind: "ObjectField",
                            name: { kind: "Name", value: "type" },
                            value: {
                              kind: "StringValue",
                              value: "Category",
                              block: false,
                            },
                          },
                          {
                            kind: "ObjectField",
                            name: { kind: "Name", value: "count" },
                            value: { kind: "IntValue", value: "1" },
                          },
                          {
                            kind: "ObjectField",
                            name: { kind: "Name", value: "transform" },
                            value: {
                              kind: "StringValue",
                              value: "card_wide",
                              block: false,
                            },
                          },
                          {
                            kind: "ObjectField",
                            name: { kind: "Name", value: "scope" },
                            value: {
                              kind: "StringValue",
                              value: "full_bleed_brand",
                              block: false,
                            },
                          },
                        ],
                      },
                    },
                  ],
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "source" },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Commons_Cms_CategoryCollectionQuery,
  Commons_Cms_CategoryCollectionQueryVariables
>;
export const Commons_Cms_ListingsCollectionDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "Commons_Cms_ListingsCollection" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "acceptsGiftCards" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "Boolean" } },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "acceptsOffers" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "Boolean" } },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "acceptsPaymentPlans" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "Boolean" } },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "brandUuids" },
          },
          type: {
            kind: "ListType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "brandSlugs" },
          },
          type: {
            kind: "ListType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "bumpedOnly" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "Boolean" },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "boostByBumpRate" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "Boolean" },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "categorySlugs" },
          },
          type: {
            kind: "ListType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "categoryUuids" },
          },
          type: {
            kind: "ListType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "conditionSlugs" },
          },
          type: {
            kind: "ListType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "countryOfOrigin" },
          },
          type: {
            kind: "ListType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "cspId" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "curatedSetId" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "currency" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "freeExpeditedShipping" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "Boolean" } },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "freeShipping" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "Boolean" } },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "handmade" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "Boolean" } },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "holidaySale" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "Boolean" } },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "itemRegion" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "itemRegionRelation" },
          },
          type: {
            kind: "NamedType",
            name: {
              kind: "Name",
              value: "reverb_search_ListingItemRegionRelation",
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "limit" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "Int" } },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "listingIds" },
          },
          type: {
            kind: "ListType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "onSale" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "Boolean" } },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "preferredSeller" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "Boolean" } },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "priceMax" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "priceMin" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "query" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "saleCuratedSetId" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "saleSlugs" },
          },
          type: {
            kind: "ListType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "shippingRegionCodes" },
          },
          type: {
            kind: "ListType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "shopSlugs" },
          },
          type: {
            kind: "ListType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "sort" } },
          type: {
            kind: "NamedType",
            name: {
              kind: "Name",
              value: "reverb_search_ListingsSearchRequest_Sort",
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "yearMax" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "yearMin" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "showListingLocation" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "Boolean" },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "adKeywords" },
          },
          type: {
            kind: "ListType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "hasAdKeywords" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "Boolean" },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "traitSlugs" },
          },
          type: {
            kind: "ListType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "dealsOrWellPriced" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "Boolean" } },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "priceValueV2" },
          },
          type: {
            kind: "NamedType",
            name: {
              kind: "Name",
              value: "reverb_search_ListingsSearchRequest_PriceValue",
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "shipsToMe" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "Boolean" } },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "shouldSkipTracking" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "Boolean" },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "similarListingIds" },
          },
          type: {
            kind: "ListType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "localPickup" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "Boolean" } },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "excludeShopIds" },
          },
          type: {
            kind: "ListType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "randomSeed" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "Int" } },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "listingsSearch" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "acceptsGiftCards" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "acceptsGiftCards" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "acceptsOffers" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "acceptsOffers" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "acceptsPaymentPlans" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "acceptsPaymentPlans" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "brandUuids" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "brandUuids" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "brandSlugs" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "brandSlugs" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "bumpedOnly" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "bumpedOnly" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "boostByBumpRate" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "boostByBumpRate" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "categorySlugs" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "categorySlugs" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "categoryUuids" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "categoryUuids" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "conditionSlugs" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "conditionSlugs" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "countryOfOrigin" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "countryOfOrigin" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "cspId" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "cspId" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "curatedSetId" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "curatedSetId" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "currency" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "currency" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "freeExpeditedShipping" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "freeExpeditedShipping" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "freeShipping" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "freeShipping" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "handmade" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "handmade" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "holidaySale" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "holidaySale" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "ids" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "listingIds" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "itemRegion" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "itemRegion" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "itemRegionRelation" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "itemRegionRelation" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "limit" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "limit" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "onSale" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "onSale" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "preferredSeller" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "preferredSeller" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "priceMax" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "priceMax" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "priceMin" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "priceMin" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "query" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "query" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "saleCuratedSetId" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "saleCuratedSetId" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "saleSlugs" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "saleSlugs" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "shippingRegionCodes" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "shippingRegionCodes" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "shopSlugs" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "shopSlugs" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "sort" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "sort" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "traitSlugs" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "traitSlugs" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "yearMax" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "yearMax" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "yearMin" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "yearMin" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "statuses" },
                      value: {
                        kind: "ListValue",
                        values: [
                          { kind: "StringValue", value: "live", block: false },
                        ],
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "dealsOrWellPriced" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "dealsOrWellPriced" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "priceValueV2" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "priceValueV2" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "shipsToMe" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "shipsToMe" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "similarListingIds" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "similarListingIds" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "localPickup" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "localPickup" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "excludeShopIds" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "excludeShopIds" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "randomSeed" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "randomSeed" },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "listings" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      {
                        kind: "FragmentSpread",
                        name: { kind: "Name", value: "ListingsCollection" },
                      },
                      {
                        kind: "FragmentSpread",
                        name: { kind: "Name", value: "mParticleListingFields" },
                        directives: [
                          {
                            kind: "Directive",
                            name: { kind: "Name", value: "skip" },
                            arguments: [
                              {
                                kind: "Argument",
                                name: { kind: "Name", value: "if" },
                                value: {
                                  kind: "Variable",
                                  name: {
                                    kind: "Name",
                                    value: "shouldSkipTracking",
                                  },
                                },
                              },
                            ],
                          },
                        ],
                      },
                      {
                        kind: "FragmentSpread",
                        name: { kind: "Name", value: "BumpKey" },
                        directives: [
                          {
                            kind: "Directive",
                            name: { kind: "Name", value: "include" },
                            arguments: [
                              {
                                kind: "Argument",
                                name: { kind: "Name", value: "if" },
                                value: {
                                  kind: "Variable",
                                  name: { kind: "Name", value: "bumpedOnly" },
                                },
                              },
                            ],
                          },
                        ],
                      },
                      {
                        kind: "FragmentSpread",
                        name: { kind: "Name", value: "ShopFields" },
                        directives: [
                          {
                            kind: "Directive",
                            name: { kind: "Name", value: "include" },
                            arguments: [
                              {
                                kind: "Argument",
                                name: { kind: "Name", value: "if" },
                                value: {
                                  kind: "Variable",
                                  name: {
                                    kind: "Name",
                                    value: "showListingLocation",
                                  },
                                },
                              },
                            ],
                          },
                        ],
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "adServe" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "targeting" },
                      value: {
                        kind: "ObjectValue",
                        fields: [
                          {
                            kind: "ObjectField",
                            name: { kind: "Name", value: "keywords" },
                            value: {
                              kind: "Variable",
                              name: { kind: "Name", value: "adKeywords" },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            directives: [
              {
                kind: "Directive",
                name: { kind: "Name", value: "include" },
                arguments: [
                  {
                    kind: "Argument",
                    name: { kind: "Name", value: "if" },
                    value: {
                      kind: "Variable",
                      name: { kind: "Name", value: "hasAdKeywords" },
                    },
                  },
                ],
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "ad" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "FragmentSpread",
                        name: { kind: "Name", value: "AdData" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "imageAdCreative" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "FragmentSpread",
                              name: {
                                kind: "Name",
                                value: "ImageAdCreativeData",
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "AddToCartButtonFields" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Listing" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "sellerId" } },
          { kind: "Field", name: { kind: "Name", value: "listingType" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "pricing" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "buyerPrice" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "amount" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "amountCents" },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "preorderInfo" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "onPreorder" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "estimatedShipDate" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "seconds" },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "WatchBadgeData" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Listing" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "title" } },
          { kind: "Field", name: { kind: "Name", value: "sellerId" } },
          { kind: "Field", name: { kind: "Name", value: "watching" } },
          {
            kind: "Field",
            alias: { kind: "Name", value: "watchThumbnails" },
            name: { kind: "Name", value: "images" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "type" },
                      value: {
                        kind: "StringValue",
                        value: "Product",
                        block: false,
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "scope" },
                      value: {
                        kind: "StringValue",
                        value: "photos",
                        block: false,
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "transform" },
                      value: {
                        kind: "StringValue",
                        value: "card_square",
                        block: false,
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "count" },
                      value: { kind: "IntValue", value: "1" },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "source" } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "ListingGreatValueData" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Listing" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "pricing" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "buyerPrice" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "currency" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "amountCents" },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "condition" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "conditionSlug" },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "priceRecommendation" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "priceMiddle" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "amountCents" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "currency" },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "shop" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "address" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "country" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "_id" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "countryCode" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          { kind: "Field", name: { kind: "Name", value: "currency" } },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "mParticleListingFields" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Listing" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "title" } },
          { kind: "Field", name: { kind: "Name", value: "brandSlug" } },
          { kind: "Field", name: { kind: "Name", value: "categoryRootUuid" } },
          { kind: "Field", name: { kind: "Name", value: "make" } },
          { kind: "Field", name: { kind: "Name", value: "categoryUuids" } },
          { kind: "Field", name: { kind: "Name", value: "state" } },
          { kind: "Field", name: { kind: "Name", value: "listingType" } },
          { kind: "Field", name: { kind: "Name", value: "bumpEligible" } },
          { kind: "Field", name: { kind: "Name", value: "shopId" } },
          { kind: "Field", name: { kind: "Name", value: "inventory" } },
          { kind: "Field", name: { kind: "Name", value: "soldAsIs" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "acceptedPaymentMethods" },
          },
          { kind: "Field", name: { kind: "Name", value: "currency" } },
          { kind: "Field", name: { kind: "Name", value: "usOutlet" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "condition" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "conditionUuid" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "conditionSlug" },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "categories" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "slug" } },
                { kind: "Field", name: { kind: "Name", value: "rootSlug" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "csp" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "slug" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "brand" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      { kind: "Field", name: { kind: "Name", value: "slug" } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "pricing" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "buyerPrice" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "amount" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "currency" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "amountCents" },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "publishedAt" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "seconds" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "sale" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "code" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "buyerIneligibilityReason" },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "shipping" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "shippingPrices" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "shippingMethod" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "carrierCalculated" },
                      },
                      {
                        kind: "Field",
                        name: {
                          kind: "Name",
                          value: "destinationPostalCodeNeeded",
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "rate" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "amount" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "amountCents" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "currency" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "display" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "freeExpeditedShipping" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "localPickupOnly" },
                },
                { kind: "Field", name: { kind: "Name", value: "localPickup" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "certifiedPreOwned" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "title" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "badgeIconUrl" },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "shop" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "address" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "countryCode" },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "returnPolicy" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "newReturnWindowDays" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "usedReturnWindowDays" },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "ListingCreateOfferButtonData" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Listing" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "state" } },
          { kind: "Field", name: { kind: "Name", value: "offersEnabled" } },
          { kind: "Field", name: { kind: "Name", value: "listingType" } },
          { kind: "Field", name: { kind: "Name", value: "sellerId" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "isBuyerOfferEligible" },
          },
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "mParticleListingFields" },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "ListingImagesFields" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Listing" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "images" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "transform" },
                      value: {
                        kind: "StringValue",
                        value: "card_square",
                        block: false,
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "count" },
                      value: { kind: "IntValue", value: "3" },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "scope" },
                      value: {
                        kind: "StringValue",
                        value: "photos",
                        block: false,
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "type" },
                      value: {
                        kind: "StringValue",
                        value: "Product",
                        block: false,
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "source" } },
              ],
            },
          },
          {
            kind: "Field",
            alias: { kind: "Name", value: "largeSquareImages" },
            name: { kind: "Name", value: "images" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "transform" },
                      value: {
                        kind: "StringValue",
                        value: "large_square",
                        block: false,
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "count" },
                      value: { kind: "IntValue", value: "3" },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "scope" },
                      value: {
                        kind: "StringValue",
                        value: "photos",
                        block: false,
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "type" },
                      value: {
                        kind: "StringValue",
                        value: "Product",
                        block: false,
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "source" } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "ListingCardCPOData" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Listing" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "certifiedPreOwned" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "title" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "badgeIconUrl" },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "ListingsCollection" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Listing" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "usOutlet" } },
          { kind: "Field", name: { kind: "Name", value: "bumped" } },
          { kind: "Field", name: { kind: "Name", value: "categoryUuids" } },
          { kind: "Field", name: { kind: "Name", value: "slug" } },
          { kind: "Field", name: { kind: "Name", value: "title" } },
          { kind: "Field", name: { kind: "Name", value: "description" } },
          { kind: "Field", name: { kind: "Name", value: "listingType" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "condition" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "displayName" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "conditionSlug" },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "price" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "amountCents" } },
                { kind: "Field", name: { kind: "Name", value: "display" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "pricing" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "buyerPrice" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "display" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "currency" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "amountCents" },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "originalPrice" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "display" },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "ribbon" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "display" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "reason" },
                      },
                    ],
                  },
                },
              ],
            },
          },
          { kind: "Field", name: { kind: "Name", value: "watching" } },
          { kind: "Field", name: { kind: "Name", value: "state" } },
          { kind: "Field", name: { kind: "Name", value: "stateDescription" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "shipping" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "shippingPrices" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "shippingMethod" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "carrierCalculated" },
                      },
                      {
                        kind: "Field",
                        name: {
                          kind: "Name",
                          value: "destinationPostalCodeNeeded",
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "rate" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "amount" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "amountCents" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "currency" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "display" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "freeExpeditedShipping" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "localPickupOnly" },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "shop" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "address" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "country" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "_id" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "countryCode" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "name" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "AddToCartButtonFields" },
          },
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "WatchBadgeData" },
          },
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "ListingGreatValueData" },
          },
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "ListingCreateOfferButtonData" },
          },
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "ListingImagesFields" },
          },
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "ListingCardCPOData" },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "BumpKey" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Listing" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "bumpKey" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "key" } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "ShopFields" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Listing" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "shop" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "address" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "locality" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "countryCode" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "region" },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "AdData" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "core_apimessages_Ad" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "uuid" } },
          { kind: "Field", name: { kind: "Name", value: "format" } },
          { kind: "Field", name: { kind: "Name", value: "adGroupUuid" } },
          { kind: "Field", name: { kind: "Name", value: "adGroupName" } },
          { kind: "Field", name: { kind: "Name", value: "adCampaignUuid" } },
          { kind: "Field", name: { kind: "Name", value: "advertiserUuid" } },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "ImageAdCreativeData" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "core_apimessages_ImageAdCreative" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "title" } },
          { kind: "Field", name: { kind: "Name", value: "titleColor" } },
          { kind: "Field", name: { kind: "Name", value: "subtitle" } },
          { kind: "Field", name: { kind: "Name", value: "subtitleColor" } },
          { kind: "Field", name: { kind: "Name", value: "ctaText" } },
          { kind: "Field", name: { kind: "Name", value: "ctaHref" } },
          { kind: "Field", name: { kind: "Name", value: "ctaTextColor" } },
          { kind: "Field", name: { kind: "Name", value: "ctaButtonColor" } },
          { kind: "Field", name: { kind: "Name", value: "backgroundImage" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "mobileBackgroundImage" },
          },
          { kind: "Field", name: { kind: "Name", value: "backgroundColor" } },
          { kind: "Field", name: { kind: "Name", value: "backgroundColors" } },
          { kind: "Field", name: { kind: "Name", value: "overlayImage" } },
          { kind: "Field", name: { kind: "Name", value: "description" } },
          { kind: "Field", name: { kind: "Name", value: "partnershipText" } },
          { kind: "Field", name: { kind: "Name", value: "hideAdCallout" } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Commons_Cms_ListingsCollectionQuery,
  Commons_Cms_ListingsCollectionQueryVariables
>;
export const Core_Cms_PageCardsDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "Core_Cms_PageCards" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "cms1Slugs" },
          },
          type: {
            kind: "ListType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "cms2Slugs" },
          },
          type: {
            kind: "ListType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            alias: { kind: "Name", value: "cmsPages" },
            name: { kind: "Name", value: "cmsPagesFetch" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "slugs" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "cms2Slugs" },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "slug" } },
                { kind: "Field", name: { kind: "Name", value: "title" } },
                { kind: "Field", name: { kind: "Name", value: "pageType" } },
                { kind: "Field", name: { kind: "Name", value: "channels" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "image" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "imageUrl" },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "pageNotFound" },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "articles" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "slugs" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "cms1Slugs" },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "slug" } },
                { kind: "Field", name: { kind: "Name", value: "title" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "webLink" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "href" } },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "images" },
                  arguments: [
                    {
                      kind: "Argument",
                      name: { kind: "Name", value: "input" },
                      value: {
                        kind: "ObjectValue",
                        fields: [
                          {
                            kind: "ObjectField",
                            name: { kind: "Name", value: "type" },
                            value: {
                              kind: "StringValue",
                              value: "Article",
                              block: false,
                            },
                          },
                          {
                            kind: "ObjectField",
                            name: { kind: "Name", value: "scope" },
                            value: {
                              kind: "StringValue",
                              value: "full_bleed_image",
                              block: false,
                            },
                          },
                          {
                            kind: "ObjectField",
                            name: { kind: "Name", value: "transform" },
                            value: {
                              kind: "StringValue",
                              value: "card_wide",
                              block: false,
                            },
                          },
                        ],
                      },
                    },
                  ],
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "source" },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Core_Cms_PageCardsQuery,
  Core_Cms_PageCardsQueryVariables
>;
export const Commons_Cms_CspCollectionDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "Commons_Cms_CspCollection" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "curatedSetId" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "fullTextQuery" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "brandNames" },
          },
          type: {
            kind: "ListType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "brandSlugs" },
          },
          type: {
            kind: "ListType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "condition" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "countryOfOrigin" },
          },
          type: {
            kind: "ListType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "limit" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "Int" } },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "categoryUuids" },
          },
          type: {
            kind: "ListType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "sort" } },
          type: {
            kind: "NamedType",
            name: {
              kind: "Name",
              value: "reverb_search_CSPSearchRequest_Sort",
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "traitSlugs" },
          },
          type: {
            kind: "ListType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "slugs" },
          },
          type: {
            kind: "ListType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "yearMin" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "Int" } },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "yearMax" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "Int" } },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "cspSearch" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "slugs" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "slugs" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "curatedSetId" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "curatedSetId" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "fullTextQuery" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "fullTextQuery" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "brandNames" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "brandNames" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "brandSlugs" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "brandSlugs" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "condition" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "condition" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "countryOfOrigin" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "countryOfOrigin" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "limit" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "limit" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "withInventory" },
                      value: { kind: "BooleanValue", value: true },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "sort" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "sort" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "categoryUuids" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "categoryUuids" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "traitSlugs" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "traitSlugs" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "yearMin" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "yearMin" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "yearMax" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "yearMax" },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "csps" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      {
                        kind: "FragmentSpread",
                        name: { kind: "Name", value: "CSPCard" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "metaDescription" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "summary" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "brand" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "_id" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "name" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "CSPAdditionalTrackingFields" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "CSP" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "slug" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "brand" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "slug" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "categories" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "slug" } },
                { kind: "Field", name: { kind: "Name", value: "rootSlug" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "parentCategories" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      { kind: "Field", name: { kind: "Name", value: "slug" } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "inventory" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "usedTotal" } },
                { kind: "Field", name: { kind: "Name", value: "newTotal" } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "CSPCard" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "CSP" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "title" } },
          { kind: "Field", name: { kind: "Name", value: "slug" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "averageReviewRating" },
          },
          { kind: "Field", name: { kind: "Name", value: "reviewsCount" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "webLink" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "path" } },
                { kind: "Field", name: { kind: "Name", value: "href" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "image" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "transform" },
                      value: {
                        kind: "StringValue",
                        value: "card_square",
                        block: false,
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "source" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "brand" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "slug" } },
                { kind: "Field", name: { kind: "Name", value: "name" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "categories" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "slug" } },
                { kind: "Field", name: { kind: "Name", value: "rootSlug" } },
                { kind: "Field", name: { kind: "Name", value: "name" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "parentCategories" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      { kind: "Field", name: { kind: "Name", value: "name" } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "inventory" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "newLowPrice" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "display" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "amount" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "amountCents" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "currency" },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "usedLowPrice" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "display" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "amount" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "amountCents" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "currency" },
                      },
                    ],
                  },
                },
                { kind: "Field", name: { kind: "Name", value: "usedTotal" } },
                { kind: "Field", name: { kind: "Name", value: "newTotal" } },
              ],
            },
          },
          { kind: "Field", name: { kind: "Name", value: "finishes" } },
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "CSPAdditionalTrackingFields" },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Commons_Cms_CspCollectionQuery,
  Commons_Cms_CspCollectionQueryVariables
>;
export const Commons_Cms_CspFeatureDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "Commons_Cms_CspFeature" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "ids" } },
          type: {
            kind: "ListType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "csps" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "ids" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "ids" },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                {
                  kind: "FragmentSpread",
                  name: { kind: "Name", value: "CSPCard" },
                },
                { kind: "Field", name: { kind: "Name", value: "summary" } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "CSPAdditionalTrackingFields" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "CSP" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "slug" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "brand" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "slug" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "categories" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "slug" } },
                { kind: "Field", name: { kind: "Name", value: "rootSlug" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "parentCategories" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      { kind: "Field", name: { kind: "Name", value: "slug" } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "inventory" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "usedTotal" } },
                { kind: "Field", name: { kind: "Name", value: "newTotal" } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "CSPCard" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "CSP" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "title" } },
          { kind: "Field", name: { kind: "Name", value: "slug" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "averageReviewRating" },
          },
          { kind: "Field", name: { kind: "Name", value: "reviewsCount" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "webLink" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "path" } },
                { kind: "Field", name: { kind: "Name", value: "href" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "image" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "transform" },
                      value: {
                        kind: "StringValue",
                        value: "card_square",
                        block: false,
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "source" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "brand" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "slug" } },
                { kind: "Field", name: { kind: "Name", value: "name" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "categories" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "slug" } },
                { kind: "Field", name: { kind: "Name", value: "rootSlug" } },
                { kind: "Field", name: { kind: "Name", value: "name" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "parentCategories" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      { kind: "Field", name: { kind: "Name", value: "name" } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "inventory" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "newLowPrice" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "display" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "amount" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "amountCents" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "currency" },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "usedLowPrice" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "display" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "amount" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "amountCents" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "currency" },
                      },
                    ],
                  },
                },
                { kind: "Field", name: { kind: "Name", value: "usedTotal" } },
                { kind: "Field", name: { kind: "Name", value: "newTotal" } },
              ],
            },
          },
          { kind: "Field", name: { kind: "Name", value: "finishes" } },
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "CSPAdditionalTrackingFields" },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Commons_Cms_CspFeatureQuery,
  Commons_Cms_CspFeatureQueryVariables
>;
export const Commons_Cms_CspTilesDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "Commons_Cms_CspTiles" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "ids" } },
          type: {
            kind: "ListType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "fullTextQuery" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "brandNames" },
          },
          type: {
            kind: "ListType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "condition" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "countryOfOrigin" },
          },
          type: {
            kind: "ListType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "categoryUuids" },
          },
          type: {
            kind: "ListType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "sort" } },
          type: {
            kind: "NamedType",
            name: {
              kind: "Name",
              value: "reverb_search_CSPSearchRequest_Sort",
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "traitSlugs" },
          },
          type: {
            kind: "ListType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "yearMin" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "Int" } },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "yearMax" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "Int" } },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "cspSearch" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "ids" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "ids" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "fullTextQuery" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "fullTextQuery" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "brandNames" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "brandNames" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "condition" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "condition" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "countryOfOrigin" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "countryOfOrigin" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "categoryUuids" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "categoryUuids" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "sort" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "sort" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "traitSlugs" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "traitSlugs" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "yearMin" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "yearMin" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "yearMax" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "yearMax" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "withInventory" },
                      value: { kind: "BooleanValue", value: true },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "csps" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      {
                        kind: "FragmentSpread",
                        name: { kind: "Name", value: "CSPCard" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "metaDescription" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "summary" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "brand" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "_id" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "name" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "CSPAdditionalTrackingFields" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "CSP" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "slug" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "brand" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "slug" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "categories" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "slug" } },
                { kind: "Field", name: { kind: "Name", value: "rootSlug" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "parentCategories" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      { kind: "Field", name: { kind: "Name", value: "slug" } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "inventory" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "usedTotal" } },
                { kind: "Field", name: { kind: "Name", value: "newTotal" } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "CSPCard" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "CSP" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "title" } },
          { kind: "Field", name: { kind: "Name", value: "slug" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "averageReviewRating" },
          },
          { kind: "Field", name: { kind: "Name", value: "reviewsCount" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "webLink" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "path" } },
                { kind: "Field", name: { kind: "Name", value: "href" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "image" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "transform" },
                      value: {
                        kind: "StringValue",
                        value: "card_square",
                        block: false,
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "source" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "brand" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "slug" } },
                { kind: "Field", name: { kind: "Name", value: "name" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "categories" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "slug" } },
                { kind: "Field", name: { kind: "Name", value: "rootSlug" } },
                { kind: "Field", name: { kind: "Name", value: "name" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "parentCategories" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      { kind: "Field", name: { kind: "Name", value: "name" } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "inventory" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "newLowPrice" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "display" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "amount" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "amountCents" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "currency" },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "usedLowPrice" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "display" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "amount" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "amountCents" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "currency" },
                      },
                    ],
                  },
                },
                { kind: "Field", name: { kind: "Name", value: "usedTotal" } },
                { kind: "Field", name: { kind: "Name", value: "newTotal" } },
              ],
            },
          },
          { kind: "Field", name: { kind: "Name", value: "finishes" } },
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "CSPAdditionalTrackingFields" },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Commons_Cms_CspTilesQuery,
  Commons_Cms_CspTilesQueryVariables
>;
export const Commons_Cms_CuratedSetCollectionDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "Commons_Cms_CuratedSetCollection" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "ids" } },
          type: {
            kind: "ListType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "curatedSets" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "ids" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "ids" },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "title" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "webLink" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "href" } },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "images" },
                  arguments: [
                    {
                      kind: "Argument",
                      name: { kind: "Name", value: "input" },
                      value: {
                        kind: "ObjectValue",
                        fields: [
                          {
                            kind: "ObjectField",
                            name: { kind: "Name", value: "transform" },
                            value: {
                              kind: "StringValue",
                              value: "banner_wide",
                              block: false,
                            },
                          },
                          {
                            kind: "ObjectField",
                            name: { kind: "Name", value: "scope" },
                            value: {
                              kind: "StringValue",
                              value: "full_bleed_image",
                              block: false,
                            },
                          },
                          {
                            kind: "ObjectField",
                            name: { kind: "Name", value: "type" },
                            value: {
                              kind: "StringValue",
                              value: "CuratedSet",
                              block: false,
                            },
                          },
                        ],
                      },
                    },
                  ],
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "source" },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Commons_Cms_CuratedSetCollectionQuery,
  Commons_Cms_CuratedSetCollectionQueryVariables
>;
export const Commons_Cms_PersonalizedListingsCollectionDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: {
        kind: "Name",
        value: "Commons_Cms_PersonalizedListingsCollection",
      },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "collapsible" },
          },
          type: {
            kind: "NamedType",
            name: {
              kind: "Name",
              value: "reverb_search_ListingsSearchRequest_Collapsible",
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "conditionSlugs" },
          },
          type: {
            kind: "ListType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "holidaySale" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "Boolean" } },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "itemRegion" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "itemRegionRelation" },
          },
          type: {
            kind: "NamedType",
            name: {
              kind: "Name",
              value: "reverb_search_ListingItemRegionRelation",
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "listingsLimit" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "Int" } },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "recsLimit" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "Int" } },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "shippingRegionCodes" },
          },
          type: {
            kind: "ListType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "shouldSkipTracking" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "Boolean" },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "showListingLocation" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "Boolean" },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "sort" } },
          type: {
            kind: "NamedType",
            name: {
              kind: "Name",
              value: "reverb_search_ListingsSearchRequest_Sort",
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "cpToListingRecs" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "limit" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "recsLimit" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "listingsSearchRequest" },
                      value: {
                        kind: "ObjectValue",
                        fields: [
                          {
                            kind: "ObjectField",
                            name: { kind: "Name", value: "collapsible" },
                            value: {
                              kind: "Variable",
                              name: { kind: "Name", value: "collapsible" },
                            },
                          },
                          {
                            kind: "ObjectField",
                            name: { kind: "Name", value: "conditionSlugs" },
                            value: {
                              kind: "Variable",
                              name: { kind: "Name", value: "conditionSlugs" },
                            },
                          },
                          {
                            kind: "ObjectField",
                            name: { kind: "Name", value: "holidaySale" },
                            value: {
                              kind: "Variable",
                              name: { kind: "Name", value: "holidaySale" },
                            },
                          },
                          {
                            kind: "ObjectField",
                            name: { kind: "Name", value: "itemRegion" },
                            value: {
                              kind: "Variable",
                              name: { kind: "Name", value: "itemRegion" },
                            },
                          },
                          {
                            kind: "ObjectField",
                            name: { kind: "Name", value: "itemRegionRelation" },
                            value: {
                              kind: "Variable",
                              name: {
                                kind: "Name",
                                value: "itemRegionRelation",
                              },
                            },
                          },
                          {
                            kind: "ObjectField",
                            name: { kind: "Name", value: "limit" },
                            value: {
                              kind: "Variable",
                              name: { kind: "Name", value: "listingsLimit" },
                            },
                          },
                          {
                            kind: "ObjectField",
                            name: {
                              kind: "Name",
                              value: "shippingRegionCodes",
                            },
                            value: {
                              kind: "Variable",
                              name: {
                                kind: "Name",
                                value: "shippingRegionCodes",
                              },
                            },
                          },
                          {
                            kind: "ObjectField",
                            name: { kind: "Name", value: "sort" },
                            value: {
                              kind: "Variable",
                              name: { kind: "Name", value: "sort" },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "searchResponse" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "limit" } },
                      { kind: "Field", name: { kind: "Name", value: "total" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "listings" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "_id" },
                            },
                            {
                              kind: "FragmentSpread",
                              name: {
                                kind: "Name",
                                value: "ListingsCollection",
                              },
                            },
                            {
                              kind: "FragmentSpread",
                              name: {
                                kind: "Name",
                                value: "mParticleListingFields",
                              },
                              directives: [
                                {
                                  kind: "Directive",
                                  name: { kind: "Name", value: "skip" },
                                  arguments: [
                                    {
                                      kind: "Argument",
                                      name: { kind: "Name", value: "if" },
                                      value: {
                                        kind: "Variable",
                                        name: {
                                          kind: "Name",
                                          value: "shouldSkipTracking",
                                        },
                                      },
                                    },
                                  ],
                                },
                              ],
                            },
                            {
                              kind: "FragmentSpread",
                              name: { kind: "Name", value: "ShopFields" },
                              directives: [
                                {
                                  kind: "Directive",
                                  name: { kind: "Name", value: "include" },
                                  arguments: [
                                    {
                                      kind: "Argument",
                                      name: { kind: "Name", value: "if" },
                                      value: {
                                        kind: "Variable",
                                        name: {
                                          kind: "Name",
                                          value: "showListingLocation",
                                        },
                                      },
                                    },
                                  ],
                                },
                              ],
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "AddToCartButtonFields" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Listing" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "sellerId" } },
          { kind: "Field", name: { kind: "Name", value: "listingType" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "pricing" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "buyerPrice" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "amount" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "amountCents" },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "preorderInfo" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "onPreorder" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "estimatedShipDate" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "seconds" },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "WatchBadgeData" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Listing" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "title" } },
          { kind: "Field", name: { kind: "Name", value: "sellerId" } },
          { kind: "Field", name: { kind: "Name", value: "watching" } },
          {
            kind: "Field",
            alias: { kind: "Name", value: "watchThumbnails" },
            name: { kind: "Name", value: "images" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "type" },
                      value: {
                        kind: "StringValue",
                        value: "Product",
                        block: false,
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "scope" },
                      value: {
                        kind: "StringValue",
                        value: "photos",
                        block: false,
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "transform" },
                      value: {
                        kind: "StringValue",
                        value: "card_square",
                        block: false,
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "count" },
                      value: { kind: "IntValue", value: "1" },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "source" } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "ListingGreatValueData" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Listing" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "pricing" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "buyerPrice" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "currency" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "amountCents" },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "condition" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "conditionSlug" },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "priceRecommendation" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "priceMiddle" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "amountCents" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "currency" },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "shop" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "address" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "country" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "_id" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "countryCode" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          { kind: "Field", name: { kind: "Name", value: "currency" } },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "mParticleListingFields" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Listing" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "title" } },
          { kind: "Field", name: { kind: "Name", value: "brandSlug" } },
          { kind: "Field", name: { kind: "Name", value: "categoryRootUuid" } },
          { kind: "Field", name: { kind: "Name", value: "make" } },
          { kind: "Field", name: { kind: "Name", value: "categoryUuids" } },
          { kind: "Field", name: { kind: "Name", value: "state" } },
          { kind: "Field", name: { kind: "Name", value: "listingType" } },
          { kind: "Field", name: { kind: "Name", value: "bumpEligible" } },
          { kind: "Field", name: { kind: "Name", value: "shopId" } },
          { kind: "Field", name: { kind: "Name", value: "inventory" } },
          { kind: "Field", name: { kind: "Name", value: "soldAsIs" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "acceptedPaymentMethods" },
          },
          { kind: "Field", name: { kind: "Name", value: "currency" } },
          { kind: "Field", name: { kind: "Name", value: "usOutlet" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "condition" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "conditionUuid" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "conditionSlug" },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "categories" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "slug" } },
                { kind: "Field", name: { kind: "Name", value: "rootSlug" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "csp" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "slug" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "brand" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      { kind: "Field", name: { kind: "Name", value: "slug" } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "pricing" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "buyerPrice" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "amount" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "currency" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "amountCents" },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "publishedAt" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "seconds" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "sale" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "code" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "buyerIneligibilityReason" },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "shipping" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "shippingPrices" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "shippingMethod" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "carrierCalculated" },
                      },
                      {
                        kind: "Field",
                        name: {
                          kind: "Name",
                          value: "destinationPostalCodeNeeded",
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "rate" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "amount" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "amountCents" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "currency" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "display" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "freeExpeditedShipping" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "localPickupOnly" },
                },
                { kind: "Field", name: { kind: "Name", value: "localPickup" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "certifiedPreOwned" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "title" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "badgeIconUrl" },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "shop" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "address" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "countryCode" },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "returnPolicy" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "newReturnWindowDays" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "usedReturnWindowDays" },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "ListingCreateOfferButtonData" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Listing" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "state" } },
          { kind: "Field", name: { kind: "Name", value: "offersEnabled" } },
          { kind: "Field", name: { kind: "Name", value: "listingType" } },
          { kind: "Field", name: { kind: "Name", value: "sellerId" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "isBuyerOfferEligible" },
          },
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "mParticleListingFields" },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "ListingImagesFields" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Listing" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "images" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "transform" },
                      value: {
                        kind: "StringValue",
                        value: "card_square",
                        block: false,
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "count" },
                      value: { kind: "IntValue", value: "3" },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "scope" },
                      value: {
                        kind: "StringValue",
                        value: "photos",
                        block: false,
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "type" },
                      value: {
                        kind: "StringValue",
                        value: "Product",
                        block: false,
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "source" } },
              ],
            },
          },
          {
            kind: "Field",
            alias: { kind: "Name", value: "largeSquareImages" },
            name: { kind: "Name", value: "images" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "transform" },
                      value: {
                        kind: "StringValue",
                        value: "large_square",
                        block: false,
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "count" },
                      value: { kind: "IntValue", value: "3" },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "scope" },
                      value: {
                        kind: "StringValue",
                        value: "photos",
                        block: false,
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "type" },
                      value: {
                        kind: "StringValue",
                        value: "Product",
                        block: false,
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "source" } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "ListingCardCPOData" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Listing" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "certifiedPreOwned" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "title" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "badgeIconUrl" },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "ListingsCollection" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Listing" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "usOutlet" } },
          { kind: "Field", name: { kind: "Name", value: "bumped" } },
          { kind: "Field", name: { kind: "Name", value: "categoryUuids" } },
          { kind: "Field", name: { kind: "Name", value: "slug" } },
          { kind: "Field", name: { kind: "Name", value: "title" } },
          { kind: "Field", name: { kind: "Name", value: "description" } },
          { kind: "Field", name: { kind: "Name", value: "listingType" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "condition" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "displayName" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "conditionSlug" },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "price" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "amountCents" } },
                { kind: "Field", name: { kind: "Name", value: "display" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "pricing" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "buyerPrice" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "display" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "currency" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "amountCents" },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "originalPrice" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "display" },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "ribbon" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "display" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "reason" },
                      },
                    ],
                  },
                },
              ],
            },
          },
          { kind: "Field", name: { kind: "Name", value: "watching" } },
          { kind: "Field", name: { kind: "Name", value: "state" } },
          { kind: "Field", name: { kind: "Name", value: "stateDescription" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "shipping" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "shippingPrices" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "shippingMethod" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "carrierCalculated" },
                      },
                      {
                        kind: "Field",
                        name: {
                          kind: "Name",
                          value: "destinationPostalCodeNeeded",
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "rate" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "amount" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "amountCents" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "currency" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "display" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "freeExpeditedShipping" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "localPickupOnly" },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "shop" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "address" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "country" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "_id" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "countryCode" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "name" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "AddToCartButtonFields" },
          },
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "WatchBadgeData" },
          },
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "ListingGreatValueData" },
          },
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "ListingCreateOfferButtonData" },
          },
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "ListingImagesFields" },
          },
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "ListingCardCPOData" },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "ShopFields" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Listing" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "shop" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "address" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "locality" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "countryCode" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "region" },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Commons_Cms_PersonalizedListingsCollectionQuery,
  Commons_Cms_PersonalizedListingsCollectionQueryVariables
>;
export const Commons_Cms_RecentlyViewedListingsDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "Commons_Cms_RecentlyViewedListings" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "limit" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "Int" } },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "showListingLocation" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "Boolean" },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "shouldSkipTracking" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "Boolean" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "recentlyViewedListings" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "limit" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "limit" },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                {
                  kind: "FragmentSpread",
                  name: { kind: "Name", value: "ListingsCollection" },
                },
                {
                  kind: "FragmentSpread",
                  name: { kind: "Name", value: "mParticleListingFields" },
                  directives: [
                    {
                      kind: "Directive",
                      name: { kind: "Name", value: "skip" },
                      arguments: [
                        {
                          kind: "Argument",
                          name: { kind: "Name", value: "if" },
                          value: {
                            kind: "Variable",
                            name: { kind: "Name", value: "shouldSkipTracking" },
                          },
                        },
                      ],
                    },
                  ],
                },
                {
                  kind: "FragmentSpread",
                  name: { kind: "Name", value: "ShopFields" },
                  directives: [
                    {
                      kind: "Directive",
                      name: { kind: "Name", value: "include" },
                      arguments: [
                        {
                          kind: "Argument",
                          name: { kind: "Name", value: "if" },
                          value: {
                            kind: "Variable",
                            name: {
                              kind: "Name",
                              value: "showListingLocation",
                            },
                          },
                        },
                      ],
                    },
                  ],
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "AddToCartButtonFields" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Listing" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "sellerId" } },
          { kind: "Field", name: { kind: "Name", value: "listingType" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "pricing" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "buyerPrice" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "amount" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "amountCents" },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "preorderInfo" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "onPreorder" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "estimatedShipDate" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "seconds" },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "WatchBadgeData" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Listing" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "title" } },
          { kind: "Field", name: { kind: "Name", value: "sellerId" } },
          { kind: "Field", name: { kind: "Name", value: "watching" } },
          {
            kind: "Field",
            alias: { kind: "Name", value: "watchThumbnails" },
            name: { kind: "Name", value: "images" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "type" },
                      value: {
                        kind: "StringValue",
                        value: "Product",
                        block: false,
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "scope" },
                      value: {
                        kind: "StringValue",
                        value: "photos",
                        block: false,
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "transform" },
                      value: {
                        kind: "StringValue",
                        value: "card_square",
                        block: false,
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "count" },
                      value: { kind: "IntValue", value: "1" },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "source" } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "ListingGreatValueData" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Listing" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "pricing" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "buyerPrice" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "currency" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "amountCents" },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "condition" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "conditionSlug" },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "priceRecommendation" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "priceMiddle" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "amountCents" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "currency" },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "shop" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "address" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "country" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "_id" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "countryCode" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          { kind: "Field", name: { kind: "Name", value: "currency" } },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "mParticleListingFields" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Listing" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "title" } },
          { kind: "Field", name: { kind: "Name", value: "brandSlug" } },
          { kind: "Field", name: { kind: "Name", value: "categoryRootUuid" } },
          { kind: "Field", name: { kind: "Name", value: "make" } },
          { kind: "Field", name: { kind: "Name", value: "categoryUuids" } },
          { kind: "Field", name: { kind: "Name", value: "state" } },
          { kind: "Field", name: { kind: "Name", value: "listingType" } },
          { kind: "Field", name: { kind: "Name", value: "bumpEligible" } },
          { kind: "Field", name: { kind: "Name", value: "shopId" } },
          { kind: "Field", name: { kind: "Name", value: "inventory" } },
          { kind: "Field", name: { kind: "Name", value: "soldAsIs" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "acceptedPaymentMethods" },
          },
          { kind: "Field", name: { kind: "Name", value: "currency" } },
          { kind: "Field", name: { kind: "Name", value: "usOutlet" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "condition" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "conditionUuid" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "conditionSlug" },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "categories" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "slug" } },
                { kind: "Field", name: { kind: "Name", value: "rootSlug" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "csp" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "slug" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "brand" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      { kind: "Field", name: { kind: "Name", value: "slug" } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "pricing" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "buyerPrice" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "amount" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "currency" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "amountCents" },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "publishedAt" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "seconds" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "sale" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "code" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "buyerIneligibilityReason" },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "shipping" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "shippingPrices" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "shippingMethod" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "carrierCalculated" },
                      },
                      {
                        kind: "Field",
                        name: {
                          kind: "Name",
                          value: "destinationPostalCodeNeeded",
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "rate" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "amount" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "amountCents" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "currency" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "display" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "freeExpeditedShipping" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "localPickupOnly" },
                },
                { kind: "Field", name: { kind: "Name", value: "localPickup" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "certifiedPreOwned" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "title" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "badgeIconUrl" },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "shop" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "address" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "countryCode" },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "returnPolicy" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "newReturnWindowDays" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "usedReturnWindowDays" },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "ListingCreateOfferButtonData" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Listing" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "state" } },
          { kind: "Field", name: { kind: "Name", value: "offersEnabled" } },
          { kind: "Field", name: { kind: "Name", value: "listingType" } },
          { kind: "Field", name: { kind: "Name", value: "sellerId" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "isBuyerOfferEligible" },
          },
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "mParticleListingFields" },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "ListingImagesFields" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Listing" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "images" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "transform" },
                      value: {
                        kind: "StringValue",
                        value: "card_square",
                        block: false,
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "count" },
                      value: { kind: "IntValue", value: "3" },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "scope" },
                      value: {
                        kind: "StringValue",
                        value: "photos",
                        block: false,
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "type" },
                      value: {
                        kind: "StringValue",
                        value: "Product",
                        block: false,
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "source" } },
              ],
            },
          },
          {
            kind: "Field",
            alias: { kind: "Name", value: "largeSquareImages" },
            name: { kind: "Name", value: "images" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "transform" },
                      value: {
                        kind: "StringValue",
                        value: "large_square",
                        block: false,
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "count" },
                      value: { kind: "IntValue", value: "3" },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "scope" },
                      value: {
                        kind: "StringValue",
                        value: "photos",
                        block: false,
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "type" },
                      value: {
                        kind: "StringValue",
                        value: "Product",
                        block: false,
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "source" } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "ListingCardCPOData" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Listing" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "certifiedPreOwned" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "title" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "badgeIconUrl" },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "ListingsCollection" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Listing" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "usOutlet" } },
          { kind: "Field", name: { kind: "Name", value: "bumped" } },
          { kind: "Field", name: { kind: "Name", value: "categoryUuids" } },
          { kind: "Field", name: { kind: "Name", value: "slug" } },
          { kind: "Field", name: { kind: "Name", value: "title" } },
          { kind: "Field", name: { kind: "Name", value: "description" } },
          { kind: "Field", name: { kind: "Name", value: "listingType" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "condition" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "displayName" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "conditionSlug" },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "price" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "amountCents" } },
                { kind: "Field", name: { kind: "Name", value: "display" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "pricing" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "buyerPrice" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "display" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "currency" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "amountCents" },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "originalPrice" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "display" },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "ribbon" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "display" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "reason" },
                      },
                    ],
                  },
                },
              ],
            },
          },
          { kind: "Field", name: { kind: "Name", value: "watching" } },
          { kind: "Field", name: { kind: "Name", value: "state" } },
          { kind: "Field", name: { kind: "Name", value: "stateDescription" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "shipping" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "shippingPrices" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "shippingMethod" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "carrierCalculated" },
                      },
                      {
                        kind: "Field",
                        name: {
                          kind: "Name",
                          value: "destinationPostalCodeNeeded",
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "rate" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "amount" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "amountCents" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "currency" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "display" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "freeExpeditedShipping" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "localPickupOnly" },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "shop" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "address" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "country" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "_id" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "countryCode" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "name" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "AddToCartButtonFields" },
          },
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "WatchBadgeData" },
          },
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "ListingGreatValueData" },
          },
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "ListingCreateOfferButtonData" },
          },
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "ListingImagesFields" },
          },
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "ListingCardCPOData" },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "ShopFields" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Listing" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "shop" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "address" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "locality" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "countryCode" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "region" },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Commons_Cms_RecentlyViewedListingsQuery,
  Commons_Cms_RecentlyViewedListingsQueryVariables
>;
export const Commons_Cms_SubcategoryNavDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "Commons_Cms_SubcategoryNav" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "ids" } },
          type: {
            kind: "ListType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "slugs" },
          },
          type: {
            kind: "ListType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "categories" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "ids" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "ids" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "slugs" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "slugs" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "withChildren" },
                      value: { kind: "BooleanValue", value: true },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "withParent" },
                      value: { kind: "BooleanValue", value: true },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "name" } },
                { kind: "Field", name: { kind: "Name", value: "slug" } },
                { kind: "Field", name: { kind: "Name", value: "rootSlug" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "cmsLink" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "href" } },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "images" },
                  arguments: [
                    {
                      kind: "Argument",
                      name: { kind: "Name", value: "input" },
                      value: {
                        kind: "ObjectValue",
                        fields: [
                          {
                            kind: "ObjectField",
                            name: { kind: "Name", value: "type" },
                            value: {
                              kind: "StringValue",
                              value: "Category",
                              block: false,
                            },
                          },
                          {
                            kind: "ObjectField",
                            name: { kind: "Name", value: "count" },
                            value: { kind: "IntValue", value: "1" },
                          },
                          {
                            kind: "ObjectField",
                            name: { kind: "Name", value: "transform" },
                            value: {
                              kind: "StringValue",
                              value: "card_wide",
                              block: false,
                            },
                          },
                          {
                            kind: "ObjectField",
                            name: { kind: "Name", value: "scope" },
                            value: {
                              kind: "StringValue",
                              value: "full_bleed_brand",
                              block: false,
                            },
                          },
                        ],
                      },
                    },
                  ],
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "source" },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "childCategories" },
                  arguments: [
                    {
                      kind: "Argument",
                      name: { kind: "Name", value: "input" },
                      value: {
                        kind: "ObjectValue",
                        fields: [
                          {
                            kind: "ObjectField",
                            name: { kind: "Name", value: "withAggregations" },
                            value: {
                              kind: "EnumValue",
                              value: "CATEGORY_UUIDS",
                            },
                          },
                        ],
                      },
                    },
                  ],
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      { kind: "Field", name: { kind: "Name", value: "name" } },
                      { kind: "Field", name: { kind: "Name", value: "slug" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "rootSlug" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "cmsLink" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "href" },
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "images" },
                        arguments: [
                          {
                            kind: "Argument",
                            name: { kind: "Name", value: "input" },
                            value: {
                              kind: "ObjectValue",
                              fields: [
                                {
                                  kind: "ObjectField",
                                  name: { kind: "Name", value: "type" },
                                  value: {
                                    kind: "StringValue",
                                    value: "Category",
                                    block: false,
                                  },
                                },
                                {
                                  kind: "ObjectField",
                                  name: { kind: "Name", value: "count" },
                                  value: { kind: "IntValue", value: "1" },
                                },
                                {
                                  kind: "ObjectField",
                                  name: { kind: "Name", value: "transform" },
                                  value: {
                                    kind: "StringValue",
                                    value: "card_wide",
                                    block: false,
                                  },
                                },
                                {
                                  kind: "ObjectField",
                                  name: { kind: "Name", value: "scope" },
                                  value: {
                                    kind: "StringValue",
                                    value: "full_bleed_brand",
                                    block: false,
                                  },
                                },
                              ],
                            },
                          },
                        ],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "_id" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "source" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "parentCategories" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      { kind: "Field", name: { kind: "Name", value: "name" } },
                      { kind: "Field", name: { kind: "Name", value: "slug" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "rootSlug" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "cmsLink" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "href" },
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "images" },
                        arguments: [
                          {
                            kind: "Argument",
                            name: { kind: "Name", value: "input" },
                            value: {
                              kind: "ObjectValue",
                              fields: [
                                {
                                  kind: "ObjectField",
                                  name: { kind: "Name", value: "type" },
                                  value: {
                                    kind: "StringValue",
                                    value: "Category",
                                    block: false,
                                  },
                                },
                                {
                                  kind: "ObjectField",
                                  name: { kind: "Name", value: "count" },
                                  value: { kind: "IntValue", value: "1" },
                                },
                                {
                                  kind: "ObjectField",
                                  name: { kind: "Name", value: "transform" },
                                  value: {
                                    kind: "StringValue",
                                    value: "card_wide",
                                    block: false,
                                  },
                                },
                                {
                                  kind: "ObjectField",
                                  name: { kind: "Name", value: "scope" },
                                  value: {
                                    kind: "StringValue",
                                    value: "full_bleed_brand",
                                    block: false,
                                  },
                                },
                              ],
                            },
                          },
                        ],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "_id" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "source" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Commons_Cms_SubcategoryNavQuery,
  Commons_Cms_SubcategoryNavQueryVariables
>;
export const Core_Ad_Banner_WrapperDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "Core_Ad_Banner_Wrapper" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "keywords" },
          },
          type: {
            kind: "ListType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "adServe" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "targeting" },
                      value: {
                        kind: "ObjectValue",
                        fields: [
                          {
                            kind: "ObjectField",
                            name: { kind: "Name", value: "keywords" },
                            value: {
                              kind: "Variable",
                              name: { kind: "Name", value: "keywords" },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "ad" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "FragmentSpread",
                        name: { kind: "Name", value: "AdData" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "imageAdCreative" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "FragmentSpread",
                              name: {
                                kind: "Name",
                                value: "ImageAdCreativeData",
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "AdData" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "core_apimessages_Ad" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "uuid" } },
          { kind: "Field", name: { kind: "Name", value: "format" } },
          { kind: "Field", name: { kind: "Name", value: "adGroupUuid" } },
          { kind: "Field", name: { kind: "Name", value: "adGroupName" } },
          { kind: "Field", name: { kind: "Name", value: "adCampaignUuid" } },
          { kind: "Field", name: { kind: "Name", value: "advertiserUuid" } },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "ImageAdCreativeData" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "core_apimessages_ImageAdCreative" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "title" } },
          { kind: "Field", name: { kind: "Name", value: "titleColor" } },
          { kind: "Field", name: { kind: "Name", value: "subtitle" } },
          { kind: "Field", name: { kind: "Name", value: "subtitleColor" } },
          { kind: "Field", name: { kind: "Name", value: "ctaText" } },
          { kind: "Field", name: { kind: "Name", value: "ctaHref" } },
          { kind: "Field", name: { kind: "Name", value: "ctaTextColor" } },
          { kind: "Field", name: { kind: "Name", value: "ctaButtonColor" } },
          { kind: "Field", name: { kind: "Name", value: "backgroundImage" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "mobileBackgroundImage" },
          },
          { kind: "Field", name: { kind: "Name", value: "backgroundColor" } },
          { kind: "Field", name: { kind: "Name", value: "backgroundColors" } },
          { kind: "Field", name: { kind: "Name", value: "overlayImage" } },
          { kind: "Field", name: { kind: "Name", value: "description" } },
          { kind: "Field", name: { kind: "Name", value: "partnershipText" } },
          { kind: "Field", name: { kind: "Name", value: "hideAdCallout" } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Core_Ad_Banner_WrapperQuery,
  Core_Ad_Banner_WrapperQueryVariables
>;
export const Core_Ad_Ended_Listing_PageDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "Core_Ad_Ended_Listing_Page" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "categories" },
          },
          type: {
            kind: "ListType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "adServe" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "targeting" },
                      value: {
                        kind: "ObjectValue",
                        fields: [
                          {
                            kind: "ObjectField",
                            name: { kind: "Name", value: "categories" },
                            value: {
                              kind: "Variable",
                              name: { kind: "Name", value: "categories" },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "ad" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "FragmentSpread",
                        name: { kind: "Name", value: "AdData" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "imageAdCreative" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "FragmentSpread",
                              name: {
                                kind: "Name",
                                value: "ImageAdCreativeData",
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "cspRowQuery" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "FragmentSpread",
                                    name: {
                                      kind: "Name",
                                      value: "AdCspRowQueryData",
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "AdData" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "core_apimessages_Ad" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "uuid" } },
          { kind: "Field", name: { kind: "Name", value: "format" } },
          { kind: "Field", name: { kind: "Name", value: "adGroupUuid" } },
          { kind: "Field", name: { kind: "Name", value: "adGroupName" } },
          { kind: "Field", name: { kind: "Name", value: "adCampaignUuid" } },
          { kind: "Field", name: { kind: "Name", value: "advertiserUuid" } },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "ImageAdCreativeData" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "core_apimessages_ImageAdCreative" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "title" } },
          { kind: "Field", name: { kind: "Name", value: "titleColor" } },
          { kind: "Field", name: { kind: "Name", value: "subtitle" } },
          { kind: "Field", name: { kind: "Name", value: "subtitleColor" } },
          { kind: "Field", name: { kind: "Name", value: "ctaText" } },
          { kind: "Field", name: { kind: "Name", value: "ctaHref" } },
          { kind: "Field", name: { kind: "Name", value: "ctaTextColor" } },
          { kind: "Field", name: { kind: "Name", value: "ctaButtonColor" } },
          { kind: "Field", name: { kind: "Name", value: "backgroundImage" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "mobileBackgroundImage" },
          },
          { kind: "Field", name: { kind: "Name", value: "backgroundColor" } },
          { kind: "Field", name: { kind: "Name", value: "backgroundColors" } },
          { kind: "Field", name: { kind: "Name", value: "overlayImage" } },
          { kind: "Field", name: { kind: "Name", value: "description" } },
          { kind: "Field", name: { kind: "Name", value: "partnershipText" } },
          { kind: "Field", name: { kind: "Name", value: "hideAdCallout" } },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "AdCspRowQueryData" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "core_apimessages_CspRowQuery" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "brandSlugs" } },
          { kind: "Field", name: { kind: "Name", value: "categoryUuids" } },
          { kind: "Field", name: { kind: "Name", value: "cspSlugs" } },
          { kind: "Field", name: { kind: "Name", value: "rowTitle" } },
          { kind: "Field", name: { kind: "Name", value: "sort" } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Core_Ad_Ended_Listing_PageQuery,
  Core_Ad_Ended_Listing_PageQueryVariables
>;
export const Core_Dev_Ad_PreviewsDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "Core_Dev_Ad_Previews" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "offset" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "Int" } },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "limit" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "Int" } },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "status" },
          },
          type: {
            kind: "NamedType",
            name: {
              kind: "Name",
              value: "core_apimessages_AdCampaignsSearchRequest_Status",
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "adZoneSlugs" },
          },
          type: {
            kind: "ListType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "withinDate" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "advertiserUuids" },
          },
          type: {
            kind: "ListType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "advertiserOffset" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "Int" } },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "advertiserLimit" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "Int" } },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "adCampaignsSearch" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "offset" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "offset" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "limit" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "limit" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "status" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "status" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "adZoneSlugs" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "adZoneSlugs" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "withinDate" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "withinDate" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "advertiserUuids" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "advertiserUuids" },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "offset" } },
                { kind: "Field", name: { kind: "Name", value: "limit" } },
                { kind: "Field", name: { kind: "Name", value: "total" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "adCampaigns" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      {
                        kind: "FragmentSpread",
                        name: { kind: "Name", value: "AdCampaignData" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "adGroupCollection" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "_ids" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "adGroups" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "_id" },
                                  },
                                  {
                                    kind: "FragmentSpread",
                                    name: {
                                      kind: "Name",
                                      value: "AdGroupWithoutKeywordsData",
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "adCollection",
                                    },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "_ids" },
                                        },
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "ads" },
                                          selectionSet: {
                                            kind: "SelectionSet",
                                            selections: [
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "_id",
                                                },
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "uuid",
                                                },
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "imageAdCreative",
                                                },
                                                selectionSet: {
                                                  kind: "SelectionSet",
                                                  selections: [
                                                    {
                                                      kind: "FragmentSpread",
                                                      name: {
                                                        kind: "Name",
                                                        value:
                                                          "ImageAdCreativeData",
                                                      },
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "cspRowQuery",
                                                      },
                                                      selectionSet: {
                                                        kind: "SelectionSet",
                                                        selections: [
                                                          {
                                                            kind: "FragmentSpread",
                                                            name: {
                                                              kind: "Name",
                                                              value:
                                                                "AdCspRowQueryData",
                                                            },
                                                          },
                                                        ],
                                                      },
                                                    },
                                                  ],
                                                },
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "advertisersSearch" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "offset" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "advertiserOffset" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "limit" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "advertiserLimit" },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "offset" } },
                { kind: "Field", name: { kind: "Name", value: "limit" } },
                { kind: "Field", name: { kind: "Name", value: "total" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "advertisers" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      { kind: "Field", name: { kind: "Name", value: "uuid" } },
                      { kind: "Field", name: { kind: "Name", value: "name" } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "countries" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "countries" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      { kind: "Field", name: { kind: "Name", value: "name" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "countryCode" },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "adZonesSearch" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "offset" },
                      value: { kind: "IntValue", value: "0" },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "limit" },
                      value: { kind: "IntValue", value: "50" },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "adZones" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      { kind: "Field", name: { kind: "Name", value: "slug" } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "AdCampaignData" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "AdCampaign" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "uuid" } },
          { kind: "Field", name: { kind: "Name", value: "name" } },
          { kind: "Field", name: { kind: "Name", value: "status" } },
          { kind: "Field", name: { kind: "Name", value: "startsAt" } },
          { kind: "Field", name: { kind: "Name", value: "endsAt" } },
          { kind: "Field", name: { kind: "Name", value: "advertiserUuid" } },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "AdGroupWithoutKeywordsData" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "AdGroup" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "uuid" } },
          { kind: "Field", name: { kind: "Name", value: "name" } },
          { kind: "Field", name: { kind: "Name", value: "adCampaignUuid" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "targetedCategoryUuids" },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "targetedCountryCodes" },
          },
          { kind: "Field", name: { kind: "Name", value: "targetedDevices" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "targetedBrandSlugs" },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "targetedAdZones" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "adZone" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "slug" } },
                    ],
                  },
                },
                { kind: "Field", name: { kind: "Name", value: "startsAt" } },
                { kind: "Field", name: { kind: "Name", value: "endsAt" } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "ImageAdCreativeData" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "core_apimessages_ImageAdCreative" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "title" } },
          { kind: "Field", name: { kind: "Name", value: "titleColor" } },
          { kind: "Field", name: { kind: "Name", value: "subtitle" } },
          { kind: "Field", name: { kind: "Name", value: "subtitleColor" } },
          { kind: "Field", name: { kind: "Name", value: "ctaText" } },
          { kind: "Field", name: { kind: "Name", value: "ctaHref" } },
          { kind: "Field", name: { kind: "Name", value: "ctaTextColor" } },
          { kind: "Field", name: { kind: "Name", value: "ctaButtonColor" } },
          { kind: "Field", name: { kind: "Name", value: "backgroundImage" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "mobileBackgroundImage" },
          },
          { kind: "Field", name: { kind: "Name", value: "backgroundColor" } },
          { kind: "Field", name: { kind: "Name", value: "backgroundColors" } },
          { kind: "Field", name: { kind: "Name", value: "overlayImage" } },
          { kind: "Field", name: { kind: "Name", value: "description" } },
          { kind: "Field", name: { kind: "Name", value: "partnershipText" } },
          { kind: "Field", name: { kind: "Name", value: "hideAdCallout" } },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "AdCspRowQueryData" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "core_apimessages_CspRowQuery" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "brandSlugs" } },
          { kind: "Field", name: { kind: "Name", value: "categoryUuids" } },
          { kind: "Field", name: { kind: "Name", value: "cspSlugs" } },
          { kind: "Field", name: { kind: "Name", value: "rowTitle" } },
          { kind: "Field", name: { kind: "Name", value: "sort" } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Core_Dev_Ad_PreviewsQuery,
  Core_Dev_Ad_PreviewsQueryVariables
>;
export const Core_Ad_Image_FeatureDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "Core_Ad_Image_Feature" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "adServeInput" },
          },
          type: {
            kind: "NamedType",
            name: {
              kind: "Name",
              value: "Input_core_apimessages_AdServeRequest",
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "adServe" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "adServeInput" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "placements" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "adZones" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "slug" },
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "ad" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "FragmentSpread",
                              name: { kind: "Name", value: "AdData" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "imageAdCreative" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "FragmentSpread",
                                    name: {
                                      kind: "Name",
                                      value: "ImageAdCreativeData",
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "AdData" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "core_apimessages_Ad" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "uuid" } },
          { kind: "Field", name: { kind: "Name", value: "format" } },
          { kind: "Field", name: { kind: "Name", value: "adGroupUuid" } },
          { kind: "Field", name: { kind: "Name", value: "adGroupName" } },
          { kind: "Field", name: { kind: "Name", value: "adCampaignUuid" } },
          { kind: "Field", name: { kind: "Name", value: "advertiserUuid" } },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "ImageAdCreativeData" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "core_apimessages_ImageAdCreative" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "title" } },
          { kind: "Field", name: { kind: "Name", value: "titleColor" } },
          { kind: "Field", name: { kind: "Name", value: "subtitle" } },
          { kind: "Field", name: { kind: "Name", value: "subtitleColor" } },
          { kind: "Field", name: { kind: "Name", value: "ctaText" } },
          { kind: "Field", name: { kind: "Name", value: "ctaHref" } },
          { kind: "Field", name: { kind: "Name", value: "ctaTextColor" } },
          { kind: "Field", name: { kind: "Name", value: "ctaButtonColor" } },
          { kind: "Field", name: { kind: "Name", value: "backgroundImage" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "mobileBackgroundImage" },
          },
          { kind: "Field", name: { kind: "Name", value: "backgroundColor" } },
          { kind: "Field", name: { kind: "Name", value: "backgroundColors" } },
          { kind: "Field", name: { kind: "Name", value: "overlayImage" } },
          { kind: "Field", name: { kind: "Name", value: "description" } },
          { kind: "Field", name: { kind: "Name", value: "partnershipText" } },
          { kind: "Field", name: { kind: "Name", value: "hideAdCallout" } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Core_Ad_Image_FeatureQuery,
  Core_Ad_Image_FeatureQueryVariables
>;
export const Commons_Cms_CmsEmbedDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "Commons_Cms_CmsEmbed" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "slug" } },
          type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "admin" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "Boolean" } },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            alias: { kind: "Name", value: "pages" },
            name: { kind: "Name", value: "cmsPagesFetch" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "slugs" },
                      value: {
                        kind: "ListValue",
                        values: [
                          {
                            kind: "Variable",
                            name: { kind: "Name", value: "slug" },
                          },
                        ],
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "includeDrafts" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "admin" },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "slug" } },
                { kind: "Field", name: { kind: "Name", value: "actionText" } },
                { kind: "Field", name: { kind: "Name", value: "actionUrl" } },
                { kind: "Field", name: { kind: "Name", value: "authorName" } },
                { kind: "Field", name: { kind: "Name", value: "description" } },
                { kind: "Field", name: { kind: "Name", value: "goLiveAt" } },
                { kind: "Field", name: { kind: "Name", value: "headerStyle" } },
                { kind: "Field", name: { kind: "Name", value: "layoutSlug" } },
                { kind: "Field", name: { kind: "Name", value: "pageType" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "searchPlaceholder" },
                },
                { kind: "Field", name: { kind: "Name", value: "title" } },
                { kind: "Field", name: { kind: "Name", value: "metaTitle" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "metaDescription" },
                },
                { kind: "Field", name: { kind: "Name", value: "noIndex" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "image" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "imageUrl" },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "metaImage" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "imageUrl" },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "secondaryImage" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "imageUrl" },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "components" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "position" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "componentType" },
                      },
                      { kind: "Field", name: { kind: "Name", value: "data" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "layoutSection" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "experimentKey" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "experimentBucket" },
                      },
                    ],
                  },
                },
                { kind: "Field", name: { kind: "Name", value: "live" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "pageNotFound" },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Commons_Cms_CmsEmbedQuery,
  Commons_Cms_CmsEmbedQueryVariables
>;
export const Commons_Cms_DynamicPageDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "Commons_Cms_DynamicPage" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "slug" } },
          type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "admin" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "Boolean" } },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            alias: { kind: "Name", value: "pages" },
            name: { kind: "Name", value: "cmsPagesFetch" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "slugs" },
                      value: {
                        kind: "ListValue",
                        values: [
                          {
                            kind: "Variable",
                            name: { kind: "Name", value: "slug" },
                          },
                        ],
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "includeDrafts" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "admin" },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "slug" } },
                { kind: "Field", name: { kind: "Name", value: "title" } },
                { kind: "Field", name: { kind: "Name", value: "description" } },
                { kind: "Field", name: { kind: "Name", value: "metaTitle" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "metaDescription" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "componentIds" },
                },
                { kind: "Field", name: { kind: "Name", value: "pageType" } },
                { kind: "Field", name: { kind: "Name", value: "channels" } },
                { kind: "Field", name: { kind: "Name", value: "locale" } },
                { kind: "Field", name: { kind: "Name", value: "layoutSlug" } },
                { kind: "Field", name: { kind: "Name", value: "headerStyle" } },
                { kind: "Field", name: { kind: "Name", value: "actionText" } },
                { kind: "Field", name: { kind: "Name", value: "actionUrl" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "darkModeReady" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "searchPlaceholder" },
                },
                { kind: "Field", name: { kind: "Name", value: "noIndex" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "components" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "position" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "componentType" },
                      },
                      { kind: "Field", name: { kind: "Name", value: "data" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "layoutSection" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "experimentKey" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "experimentBucket" },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "image" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "imageUrl" },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "metaImage" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "imageUrl" },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "secondaryImage" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "imageUrl" },
                      },
                    ],
                  },
                },
                { kind: "Field", name: { kind: "Name", value: "breadcrumbs" } },
                { kind: "Field", name: { kind: "Name", value: "authorName" } },
                { kind: "Field", name: { kind: "Name", value: "goLiveAt" } },
                { kind: "Field", name: { kind: "Name", value: "live" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "pageNotFound" },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Commons_Cms_DynamicPageQuery,
  Commons_Cms_DynamicPageQueryVariables
>;
export const Commons_FavoriteShop_ListingsCollectionDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "Commons_FavoriteShop_ListingsCollection" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "shopSlugs" },
          },
          type: {
            kind: "ListType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "sort" } },
          type: {
            kind: "NamedType",
            name: {
              kind: "Name",
              value: "reverb_search_ListingsSearchRequest_Sort",
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "limit" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "Int" } },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "listingsSearch" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "sort" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "sort" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "shopSlugs" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "shopSlugs" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "limit" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "limit" },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "listings" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      {
                        kind: "FragmentSpread",
                        name: { kind: "Name", value: "ListingsCollection" },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "AddToCartButtonFields" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Listing" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "sellerId" } },
          { kind: "Field", name: { kind: "Name", value: "listingType" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "pricing" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "buyerPrice" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "amount" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "amountCents" },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "preorderInfo" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "onPreorder" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "estimatedShipDate" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "seconds" },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "WatchBadgeData" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Listing" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "title" } },
          { kind: "Field", name: { kind: "Name", value: "sellerId" } },
          { kind: "Field", name: { kind: "Name", value: "watching" } },
          {
            kind: "Field",
            alias: { kind: "Name", value: "watchThumbnails" },
            name: { kind: "Name", value: "images" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "type" },
                      value: {
                        kind: "StringValue",
                        value: "Product",
                        block: false,
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "scope" },
                      value: {
                        kind: "StringValue",
                        value: "photos",
                        block: false,
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "transform" },
                      value: {
                        kind: "StringValue",
                        value: "card_square",
                        block: false,
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "count" },
                      value: { kind: "IntValue", value: "1" },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "source" } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "ListingGreatValueData" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Listing" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "pricing" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "buyerPrice" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "currency" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "amountCents" },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "condition" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "conditionSlug" },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "priceRecommendation" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "priceMiddle" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "amountCents" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "currency" },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "shop" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "address" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "country" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "_id" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "countryCode" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          { kind: "Field", name: { kind: "Name", value: "currency" } },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "mParticleListingFields" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Listing" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "title" } },
          { kind: "Field", name: { kind: "Name", value: "brandSlug" } },
          { kind: "Field", name: { kind: "Name", value: "categoryRootUuid" } },
          { kind: "Field", name: { kind: "Name", value: "make" } },
          { kind: "Field", name: { kind: "Name", value: "categoryUuids" } },
          { kind: "Field", name: { kind: "Name", value: "state" } },
          { kind: "Field", name: { kind: "Name", value: "listingType" } },
          { kind: "Field", name: { kind: "Name", value: "bumpEligible" } },
          { kind: "Field", name: { kind: "Name", value: "shopId" } },
          { kind: "Field", name: { kind: "Name", value: "inventory" } },
          { kind: "Field", name: { kind: "Name", value: "soldAsIs" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "acceptedPaymentMethods" },
          },
          { kind: "Field", name: { kind: "Name", value: "currency" } },
          { kind: "Field", name: { kind: "Name", value: "usOutlet" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "condition" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "conditionUuid" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "conditionSlug" },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "categories" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "slug" } },
                { kind: "Field", name: { kind: "Name", value: "rootSlug" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "csp" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "slug" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "brand" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      { kind: "Field", name: { kind: "Name", value: "slug" } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "pricing" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "buyerPrice" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "amount" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "currency" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "amountCents" },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "publishedAt" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "seconds" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "sale" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "code" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "buyerIneligibilityReason" },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "shipping" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "shippingPrices" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "shippingMethod" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "carrierCalculated" },
                      },
                      {
                        kind: "Field",
                        name: {
                          kind: "Name",
                          value: "destinationPostalCodeNeeded",
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "rate" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "amount" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "amountCents" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "currency" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "display" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "freeExpeditedShipping" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "localPickupOnly" },
                },
                { kind: "Field", name: { kind: "Name", value: "localPickup" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "certifiedPreOwned" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "title" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "badgeIconUrl" },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "shop" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "address" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "countryCode" },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "returnPolicy" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "newReturnWindowDays" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "usedReturnWindowDays" },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "ListingCreateOfferButtonData" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Listing" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "state" } },
          { kind: "Field", name: { kind: "Name", value: "offersEnabled" } },
          { kind: "Field", name: { kind: "Name", value: "listingType" } },
          { kind: "Field", name: { kind: "Name", value: "sellerId" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "isBuyerOfferEligible" },
          },
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "mParticleListingFields" },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "ListingImagesFields" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Listing" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "images" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "transform" },
                      value: {
                        kind: "StringValue",
                        value: "card_square",
                        block: false,
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "count" },
                      value: { kind: "IntValue", value: "3" },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "scope" },
                      value: {
                        kind: "StringValue",
                        value: "photos",
                        block: false,
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "type" },
                      value: {
                        kind: "StringValue",
                        value: "Product",
                        block: false,
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "source" } },
              ],
            },
          },
          {
            kind: "Field",
            alias: { kind: "Name", value: "largeSquareImages" },
            name: { kind: "Name", value: "images" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "transform" },
                      value: {
                        kind: "StringValue",
                        value: "large_square",
                        block: false,
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "count" },
                      value: { kind: "IntValue", value: "3" },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "scope" },
                      value: {
                        kind: "StringValue",
                        value: "photos",
                        block: false,
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "type" },
                      value: {
                        kind: "StringValue",
                        value: "Product",
                        block: false,
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "source" } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "ListingCardCPOData" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Listing" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "certifiedPreOwned" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "title" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "badgeIconUrl" },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "ListingsCollection" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Listing" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "usOutlet" } },
          { kind: "Field", name: { kind: "Name", value: "bumped" } },
          { kind: "Field", name: { kind: "Name", value: "categoryUuids" } },
          { kind: "Field", name: { kind: "Name", value: "slug" } },
          { kind: "Field", name: { kind: "Name", value: "title" } },
          { kind: "Field", name: { kind: "Name", value: "description" } },
          { kind: "Field", name: { kind: "Name", value: "listingType" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "condition" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "displayName" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "conditionSlug" },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "price" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "amountCents" } },
                { kind: "Field", name: { kind: "Name", value: "display" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "pricing" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "buyerPrice" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "display" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "currency" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "amountCents" },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "originalPrice" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "display" },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "ribbon" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "display" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "reason" },
                      },
                    ],
                  },
                },
              ],
            },
          },
          { kind: "Field", name: { kind: "Name", value: "watching" } },
          { kind: "Field", name: { kind: "Name", value: "state" } },
          { kind: "Field", name: { kind: "Name", value: "stateDescription" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "shipping" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "shippingPrices" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "shippingMethod" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "carrierCalculated" },
                      },
                      {
                        kind: "Field",
                        name: {
                          kind: "Name",
                          value: "destinationPostalCodeNeeded",
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "rate" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "amount" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "amountCents" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "currency" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "display" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "freeExpeditedShipping" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "localPickupOnly" },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "shop" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "address" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "country" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "_id" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "countryCode" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "name" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "AddToCartButtonFields" },
          },
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "WatchBadgeData" },
          },
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "ListingGreatValueData" },
          },
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "ListingCreateOfferButtonData" },
          },
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "ListingImagesFields" },
          },
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "ListingCardCPOData" },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Commons_FavoriteShop_ListingsCollectionQuery,
  Commons_FavoriteShop_ListingsCollectionQueryVariables
>;
export const UndoDeleteMyFavoriteDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "UndoDeleteMyFavorite" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "input" },
          },
          type: {
            kind: "NamedType",
            name: {
              kind: "Name",
              value: "Input_core_apimessages_UndoDeleteMyFavoriteRequest",
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "undoDeleteMyFavorite" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "input" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "favorite" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "favorited" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "favoriteType" },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "listing" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "watching" },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UndoDeleteMyFavoriteMutation,
  UndoDeleteMyFavoriteMutationVariables
>;
export const Core_Homepage_RecentlyViewedSimilarListingsSearchDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: {
        kind: "Name",
        value: "Core_Homepage_RecentlyViewedSimilarListingsSearch",
      },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "limit" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "Int" } },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "recentSearchId0" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "recentSearchId1" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "skipQuery1" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "Boolean" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            alias: { kind: "Name", value: "similarListings0" },
            name: { kind: "Name", value: "similarListingsSearch" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "limit" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "limit" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "listingId" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "recentSearchId0" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "includeForLiveListings" },
                      value: { kind: "BooleanValue", value: true },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "listings" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      {
                        kind: "FragmentSpread",
                        name: { kind: "Name", value: "ListingsCollection" },
                      },
                      {
                        kind: "FragmentSpread",
                        name: { kind: "Name", value: "mParticleListingFields" },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            alias: { kind: "Name", value: "similarListings1" },
            name: { kind: "Name", value: "similarListingsSearch" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "limit" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "limit" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "listingId" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "recentSearchId1" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "includeForLiveListings" },
                      value: { kind: "BooleanValue", value: true },
                    },
                  ],
                },
              },
            ],
            directives: [
              {
                kind: "Directive",
                name: { kind: "Name", value: "skip" },
                arguments: [
                  {
                    kind: "Argument",
                    name: { kind: "Name", value: "if" },
                    value: {
                      kind: "Variable",
                      name: { kind: "Name", value: "skipQuery1" },
                    },
                  },
                ],
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "listings" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      {
                        kind: "FragmentSpread",
                        name: { kind: "Name", value: "ListingsCollection" },
                      },
                      {
                        kind: "FragmentSpread",
                        name: { kind: "Name", value: "mParticleListingFields" },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "AddToCartButtonFields" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Listing" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "sellerId" } },
          { kind: "Field", name: { kind: "Name", value: "listingType" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "pricing" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "buyerPrice" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "amount" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "amountCents" },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "preorderInfo" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "onPreorder" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "estimatedShipDate" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "seconds" },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "WatchBadgeData" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Listing" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "title" } },
          { kind: "Field", name: { kind: "Name", value: "sellerId" } },
          { kind: "Field", name: { kind: "Name", value: "watching" } },
          {
            kind: "Field",
            alias: { kind: "Name", value: "watchThumbnails" },
            name: { kind: "Name", value: "images" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "type" },
                      value: {
                        kind: "StringValue",
                        value: "Product",
                        block: false,
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "scope" },
                      value: {
                        kind: "StringValue",
                        value: "photos",
                        block: false,
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "transform" },
                      value: {
                        kind: "StringValue",
                        value: "card_square",
                        block: false,
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "count" },
                      value: { kind: "IntValue", value: "1" },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "source" } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "ListingGreatValueData" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Listing" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "pricing" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "buyerPrice" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "currency" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "amountCents" },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "condition" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "conditionSlug" },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "priceRecommendation" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "priceMiddle" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "amountCents" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "currency" },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "shop" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "address" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "country" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "_id" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "countryCode" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          { kind: "Field", name: { kind: "Name", value: "currency" } },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "mParticleListingFields" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Listing" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "title" } },
          { kind: "Field", name: { kind: "Name", value: "brandSlug" } },
          { kind: "Field", name: { kind: "Name", value: "categoryRootUuid" } },
          { kind: "Field", name: { kind: "Name", value: "make" } },
          { kind: "Field", name: { kind: "Name", value: "categoryUuids" } },
          { kind: "Field", name: { kind: "Name", value: "state" } },
          { kind: "Field", name: { kind: "Name", value: "listingType" } },
          { kind: "Field", name: { kind: "Name", value: "bumpEligible" } },
          { kind: "Field", name: { kind: "Name", value: "shopId" } },
          { kind: "Field", name: { kind: "Name", value: "inventory" } },
          { kind: "Field", name: { kind: "Name", value: "soldAsIs" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "acceptedPaymentMethods" },
          },
          { kind: "Field", name: { kind: "Name", value: "currency" } },
          { kind: "Field", name: { kind: "Name", value: "usOutlet" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "condition" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "conditionUuid" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "conditionSlug" },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "categories" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "slug" } },
                { kind: "Field", name: { kind: "Name", value: "rootSlug" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "csp" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "slug" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "brand" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      { kind: "Field", name: { kind: "Name", value: "slug" } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "pricing" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "buyerPrice" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "amount" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "currency" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "amountCents" },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "publishedAt" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "seconds" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "sale" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "code" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "buyerIneligibilityReason" },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "shipping" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "shippingPrices" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "shippingMethod" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "carrierCalculated" },
                      },
                      {
                        kind: "Field",
                        name: {
                          kind: "Name",
                          value: "destinationPostalCodeNeeded",
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "rate" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "amount" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "amountCents" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "currency" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "display" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "freeExpeditedShipping" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "localPickupOnly" },
                },
                { kind: "Field", name: { kind: "Name", value: "localPickup" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "certifiedPreOwned" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "title" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "badgeIconUrl" },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "shop" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "address" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "countryCode" },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "returnPolicy" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "newReturnWindowDays" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "usedReturnWindowDays" },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "ListingCreateOfferButtonData" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Listing" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "state" } },
          { kind: "Field", name: { kind: "Name", value: "offersEnabled" } },
          { kind: "Field", name: { kind: "Name", value: "listingType" } },
          { kind: "Field", name: { kind: "Name", value: "sellerId" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "isBuyerOfferEligible" },
          },
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "mParticleListingFields" },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "ListingImagesFields" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Listing" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "images" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "transform" },
                      value: {
                        kind: "StringValue",
                        value: "card_square",
                        block: false,
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "count" },
                      value: { kind: "IntValue", value: "3" },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "scope" },
                      value: {
                        kind: "StringValue",
                        value: "photos",
                        block: false,
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "type" },
                      value: {
                        kind: "StringValue",
                        value: "Product",
                        block: false,
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "source" } },
              ],
            },
          },
          {
            kind: "Field",
            alias: { kind: "Name", value: "largeSquareImages" },
            name: { kind: "Name", value: "images" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "transform" },
                      value: {
                        kind: "StringValue",
                        value: "large_square",
                        block: false,
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "count" },
                      value: { kind: "IntValue", value: "3" },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "scope" },
                      value: {
                        kind: "StringValue",
                        value: "photos",
                        block: false,
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "type" },
                      value: {
                        kind: "StringValue",
                        value: "Product",
                        block: false,
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "source" } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "ListingCardCPOData" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Listing" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "certifiedPreOwned" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "title" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "badgeIconUrl" },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "ListingsCollection" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Listing" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "usOutlet" } },
          { kind: "Field", name: { kind: "Name", value: "bumped" } },
          { kind: "Field", name: { kind: "Name", value: "categoryUuids" } },
          { kind: "Field", name: { kind: "Name", value: "slug" } },
          { kind: "Field", name: { kind: "Name", value: "title" } },
          { kind: "Field", name: { kind: "Name", value: "description" } },
          { kind: "Field", name: { kind: "Name", value: "listingType" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "condition" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "displayName" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "conditionSlug" },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "price" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "amountCents" } },
                { kind: "Field", name: { kind: "Name", value: "display" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "pricing" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "buyerPrice" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "display" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "currency" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "amountCents" },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "originalPrice" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "display" },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "ribbon" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "display" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "reason" },
                      },
                    ],
                  },
                },
              ],
            },
          },
          { kind: "Field", name: { kind: "Name", value: "watching" } },
          { kind: "Field", name: { kind: "Name", value: "state" } },
          { kind: "Field", name: { kind: "Name", value: "stateDescription" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "shipping" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "shippingPrices" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "shippingMethod" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "carrierCalculated" },
                      },
                      {
                        kind: "Field",
                        name: {
                          kind: "Name",
                          value: "destinationPostalCodeNeeded",
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "rate" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "amount" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "amountCents" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "currency" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "display" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "freeExpeditedShipping" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "localPickupOnly" },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "shop" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "address" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "country" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "_id" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "countryCode" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "name" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "AddToCartButtonFields" },
          },
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "WatchBadgeData" },
          },
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "ListingGreatValueData" },
          },
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "ListingCreateOfferButtonData" },
          },
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "ListingImagesFields" },
          },
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "ListingCardCPOData" },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Core_Homepage_RecentlyViewedSimilarListingsSearchQuery,
  Core_Homepage_RecentlyViewedSimilarListingsSearchQueryVariables
>;
export const Disco_RecentlyViewedListingPhotosRowDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "Disco_RecentlyViewedListingPhotosRow" },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "recentlyViewedListings" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "limit" },
                      value: { kind: "IntValue", value: "12" },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                {
                  kind: "FragmentSpread",
                  name: { kind: "Name", value: "PhotoTileFields" },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "PhotoTileFields" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Listing" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "slug" } },
          { kind: "Field", name: { kind: "Name", value: "title" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "images" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "transform" },
                      value: {
                        kind: "StringValue",
                        value: "card_square",
                        block: false,
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "count" },
                      value: { kind: "IntValue", value: "1" },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "scope" },
                      value: {
                        kind: "StringValue",
                        value: "photos",
                        block: false,
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "type" },
                      value: {
                        kind: "StringValue",
                        value: "Product",
                        block: false,
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "source" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Disco_RecentlyViewedListingPhotosRowQuery,
  Disco_RecentlyViewedListingPhotosRowQueryVariables
>;
export const Commons_ShippingRegionFilterDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "Commons_ShippingRegionFilter" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "paramName" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "selectedValues" },
          },
          type: {
            kind: "ListType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "shippingRegionFilter" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "paramName" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "paramName" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "selectedValues" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "selectedValues" },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "filter" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "name" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "aggregationName" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "widgetType" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "options" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "count" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "value" },
                                  },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "name" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "selected" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "paramName" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "setValues" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "unsetValues" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "all" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "optionValue" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Commons_ShippingRegionFilterQuery,
  Commons_ShippingRegionFilterQueryVariables
>;
export const Commons_SavedSearch_ListingsCollectionDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "Commons_SavedSearch_ListingsCollection" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "searchUrlParams" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
        },
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "sort" } },
          type: {
            kind: "NamedType",
            name: {
              kind: "Name",
              value: "reverb_search_ListingsSearchRequest_Sort",
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "limit" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "Int" } },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "listingsSearch" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "sort" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "sort" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "searchUrlParams" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "searchUrlParams" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "limit" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "limit" },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "listings" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      {
                        kind: "FragmentSpread",
                        name: { kind: "Name", value: "ListingsCollection" },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "AddToCartButtonFields" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Listing" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "sellerId" } },
          { kind: "Field", name: { kind: "Name", value: "listingType" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "pricing" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "buyerPrice" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "amount" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "amountCents" },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "preorderInfo" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "onPreorder" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "estimatedShipDate" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "seconds" },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "WatchBadgeData" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Listing" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "title" } },
          { kind: "Field", name: { kind: "Name", value: "sellerId" } },
          { kind: "Field", name: { kind: "Name", value: "watching" } },
          {
            kind: "Field",
            alias: { kind: "Name", value: "watchThumbnails" },
            name: { kind: "Name", value: "images" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "type" },
                      value: {
                        kind: "StringValue",
                        value: "Product",
                        block: false,
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "scope" },
                      value: {
                        kind: "StringValue",
                        value: "photos",
                        block: false,
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "transform" },
                      value: {
                        kind: "StringValue",
                        value: "card_square",
                        block: false,
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "count" },
                      value: { kind: "IntValue", value: "1" },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "source" } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "ListingGreatValueData" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Listing" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "pricing" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "buyerPrice" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "currency" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "amountCents" },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "condition" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "conditionSlug" },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "priceRecommendation" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "priceMiddle" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "amountCents" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "currency" },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "shop" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "address" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "country" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "_id" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "countryCode" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          { kind: "Field", name: { kind: "Name", value: "currency" } },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "mParticleListingFields" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Listing" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "title" } },
          { kind: "Field", name: { kind: "Name", value: "brandSlug" } },
          { kind: "Field", name: { kind: "Name", value: "categoryRootUuid" } },
          { kind: "Field", name: { kind: "Name", value: "make" } },
          { kind: "Field", name: { kind: "Name", value: "categoryUuids" } },
          { kind: "Field", name: { kind: "Name", value: "state" } },
          { kind: "Field", name: { kind: "Name", value: "listingType" } },
          { kind: "Field", name: { kind: "Name", value: "bumpEligible" } },
          { kind: "Field", name: { kind: "Name", value: "shopId" } },
          { kind: "Field", name: { kind: "Name", value: "inventory" } },
          { kind: "Field", name: { kind: "Name", value: "soldAsIs" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "acceptedPaymentMethods" },
          },
          { kind: "Field", name: { kind: "Name", value: "currency" } },
          { kind: "Field", name: { kind: "Name", value: "usOutlet" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "condition" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "conditionUuid" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "conditionSlug" },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "categories" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "slug" } },
                { kind: "Field", name: { kind: "Name", value: "rootSlug" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "csp" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "slug" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "brand" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      { kind: "Field", name: { kind: "Name", value: "slug" } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "pricing" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "buyerPrice" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "amount" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "currency" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "amountCents" },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "publishedAt" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "seconds" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "sale" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "code" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "buyerIneligibilityReason" },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "shipping" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "shippingPrices" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "shippingMethod" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "carrierCalculated" },
                      },
                      {
                        kind: "Field",
                        name: {
                          kind: "Name",
                          value: "destinationPostalCodeNeeded",
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "rate" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "amount" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "amountCents" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "currency" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "display" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "freeExpeditedShipping" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "localPickupOnly" },
                },
                { kind: "Field", name: { kind: "Name", value: "localPickup" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "certifiedPreOwned" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "title" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "badgeIconUrl" },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "shop" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "address" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "countryCode" },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "returnPolicy" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "newReturnWindowDays" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "usedReturnWindowDays" },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "ListingCreateOfferButtonData" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Listing" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "state" } },
          { kind: "Field", name: { kind: "Name", value: "offersEnabled" } },
          { kind: "Field", name: { kind: "Name", value: "listingType" } },
          { kind: "Field", name: { kind: "Name", value: "sellerId" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "isBuyerOfferEligible" },
          },
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "mParticleListingFields" },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "ListingImagesFields" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Listing" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "images" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "transform" },
                      value: {
                        kind: "StringValue",
                        value: "card_square",
                        block: false,
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "count" },
                      value: { kind: "IntValue", value: "3" },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "scope" },
                      value: {
                        kind: "StringValue",
                        value: "photos",
                        block: false,
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "type" },
                      value: {
                        kind: "StringValue",
                        value: "Product",
                        block: false,
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "source" } },
              ],
            },
          },
          {
            kind: "Field",
            alias: { kind: "Name", value: "largeSquareImages" },
            name: { kind: "Name", value: "images" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "transform" },
                      value: {
                        kind: "StringValue",
                        value: "large_square",
                        block: false,
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "count" },
                      value: { kind: "IntValue", value: "3" },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "scope" },
                      value: {
                        kind: "StringValue",
                        value: "photos",
                        block: false,
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "type" },
                      value: {
                        kind: "StringValue",
                        value: "Product",
                        block: false,
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "source" } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "ListingCardCPOData" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Listing" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "certifiedPreOwned" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "title" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "badgeIconUrl" },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "ListingsCollection" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Listing" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "usOutlet" } },
          { kind: "Field", name: { kind: "Name", value: "bumped" } },
          { kind: "Field", name: { kind: "Name", value: "categoryUuids" } },
          { kind: "Field", name: { kind: "Name", value: "slug" } },
          { kind: "Field", name: { kind: "Name", value: "title" } },
          { kind: "Field", name: { kind: "Name", value: "description" } },
          { kind: "Field", name: { kind: "Name", value: "listingType" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "condition" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "displayName" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "conditionSlug" },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "price" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "amountCents" } },
                { kind: "Field", name: { kind: "Name", value: "display" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "pricing" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "buyerPrice" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "display" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "currency" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "amountCents" },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "originalPrice" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "display" },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "ribbon" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "display" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "reason" },
                      },
                    ],
                  },
                },
              ],
            },
          },
          { kind: "Field", name: { kind: "Name", value: "watching" } },
          { kind: "Field", name: { kind: "Name", value: "state" } },
          { kind: "Field", name: { kind: "Name", value: "stateDescription" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "shipping" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "shippingPrices" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "shippingMethod" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "carrierCalculated" },
                      },
                      {
                        kind: "Field",
                        name: {
                          kind: "Name",
                          value: "destinationPostalCodeNeeded",
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "rate" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "amount" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "amountCents" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "currency" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "display" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "freeExpeditedShipping" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "localPickupOnly" },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "shop" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "address" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "country" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "_id" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "countryCode" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "name" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "AddToCartButtonFields" },
          },
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "WatchBadgeData" },
          },
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "ListingGreatValueData" },
          },
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "ListingCreateOfferButtonData" },
          },
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "ListingImagesFields" },
          },
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "ListingCardCPOData" },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Commons_SavedSearch_ListingsCollectionQuery,
  Commons_SavedSearch_ListingsCollectionQueryVariables
>;
export const Commons_SimilarCspsRowDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "Commons_SimilarCspsRow" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "similarCspSlug" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "similarListingId" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "limit" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "Int" } },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "cspSearch" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "similarCspSlug" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "similarCspSlug" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "similarListingId" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "similarListingId" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "limit" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "limit" },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "csps" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      {
                        kind: "FragmentSpread",
                        name: { kind: "Name", value: "CSPCard" },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "CSPAdditionalTrackingFields" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "CSP" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "slug" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "brand" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "slug" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "categories" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "slug" } },
                { kind: "Field", name: { kind: "Name", value: "rootSlug" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "parentCategories" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      { kind: "Field", name: { kind: "Name", value: "slug" } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "inventory" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "usedTotal" } },
                { kind: "Field", name: { kind: "Name", value: "newTotal" } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "CSPCard" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "CSP" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "title" } },
          { kind: "Field", name: { kind: "Name", value: "slug" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "averageReviewRating" },
          },
          { kind: "Field", name: { kind: "Name", value: "reviewsCount" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "webLink" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "path" } },
                { kind: "Field", name: { kind: "Name", value: "href" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "image" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "transform" },
                      value: {
                        kind: "StringValue",
                        value: "card_square",
                        block: false,
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "source" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "brand" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "slug" } },
                { kind: "Field", name: { kind: "Name", value: "name" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "categories" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "slug" } },
                { kind: "Field", name: { kind: "Name", value: "rootSlug" } },
                { kind: "Field", name: { kind: "Name", value: "name" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "parentCategories" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      { kind: "Field", name: { kind: "Name", value: "name" } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "inventory" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "newLowPrice" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "display" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "amount" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "amountCents" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "currency" },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "usedLowPrice" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "display" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "amount" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "amountCents" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "currency" },
                      },
                    ],
                  },
                },
                { kind: "Field", name: { kind: "Name", value: "usedTotal" } },
                { kind: "Field", name: { kind: "Name", value: "newTotal" } },
              ],
            },
          },
          { kind: "Field", name: { kind: "Name", value: "finishes" } },
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "CSPAdditionalTrackingFields" },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Commons_SimilarCspsRowQuery,
  Commons_SimilarCspsRowQueryVariables
>;
export const Core_Search_CompletionsQueryDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "Core_Search_CompletionsQuery" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "query" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
        },
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "skip" } },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "Boolean" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "completions" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "query" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "query" },
                      },
                    },
                  ],
                },
              },
            ],
            directives: [
              {
                kind: "Directive",
                name: { kind: "Name", value: "skip" },
                arguments: [
                  {
                    kind: "Argument",
                    name: { kind: "Name", value: "if" },
                    value: {
                      kind: "Variable",
                      name: { kind: "Name", value: "skip" },
                    },
                  },
                ],
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "completions" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "text" } },
                      { kind: "Field", name: { kind: "Name", value: "type" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "options" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "score" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "slug" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "text" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "output" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "scopes" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "output" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "slug" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "type" },
                                  },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: {
                                kind: "Name",
                                value: "completionPayload",
                              },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "thumbnailUrl",
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "inventoryNew",
                                    },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "listingCount",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "listingMinPrice",
                                          },
                                          selectionSet: {
                                            kind: "SelectionSet",
                                            selections: [
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "cents",
                                                },
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "currency",
                                                },
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "inventoryUsed",
                                    },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "listingCount",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "listingMinPrice",
                                          },
                                          selectionSet: {
                                            kind: "SelectionSet",
                                            selections: [
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "cents",
                                                },
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "currency",
                                                },
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Core_Search_CompletionsQueryQuery,
  Core_Search_CompletionsQueryQueryVariables
>;
export const Commons_MParticle_ArticleDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "Commons_MParticle_Article" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "slug" } },
          type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "article" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "slug" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "slug" },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "categories" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      { kind: "Field", name: { kind: "Name", value: "slug" } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Commons_MParticle_ArticleQuery,
  Commons_MParticle_ArticleQueryVariables
>;
export const Commons_MParticle_OrderBundlesDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "Commons_MParticle_OrderBundles" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "id" } },
          type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            alias: { kind: "Name", value: "orderBundle" },
            name: { kind: "Name", value: "orderbundle" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "id" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "id" },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "id" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "checkoutType" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "paymentMethod" },
                },
                { kind: "Field", name: { kind: "Name", value: "localPickup" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "amountTax" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "amount" },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "amountShipping" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "amount" },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "amountProductSubtotal" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "amount" },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "orders" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "nodes" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "_id" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "shopUuid" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "quantity" },
                            },
                            {
                              kind: "Field",
                              name: {
                                kind: "Name",
                                value: "settlementAmountProductSubtotal",
                              },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "amount" },
                                  },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "listing" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "_id" },
                                  },
                                  {
                                    kind: "FragmentSpread",
                                    name: {
                                      kind: "Name",
                                      value: "mParticleListingFields",
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "payments" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "paymentMethod",
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: {
                                kind: "Name",
                                value: "protectionPlanEligible",
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                { kind: "Field", name: { kind: "Name", value: "orderType" } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "mParticleListingFields" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Listing" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "title" } },
          { kind: "Field", name: { kind: "Name", value: "brandSlug" } },
          { kind: "Field", name: { kind: "Name", value: "categoryRootUuid" } },
          { kind: "Field", name: { kind: "Name", value: "make" } },
          { kind: "Field", name: { kind: "Name", value: "categoryUuids" } },
          { kind: "Field", name: { kind: "Name", value: "state" } },
          { kind: "Field", name: { kind: "Name", value: "listingType" } },
          { kind: "Field", name: { kind: "Name", value: "bumpEligible" } },
          { kind: "Field", name: { kind: "Name", value: "shopId" } },
          { kind: "Field", name: { kind: "Name", value: "inventory" } },
          { kind: "Field", name: { kind: "Name", value: "soldAsIs" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "acceptedPaymentMethods" },
          },
          { kind: "Field", name: { kind: "Name", value: "currency" } },
          { kind: "Field", name: { kind: "Name", value: "usOutlet" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "condition" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "conditionUuid" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "conditionSlug" },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "categories" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "slug" } },
                { kind: "Field", name: { kind: "Name", value: "rootSlug" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "csp" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "slug" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "brand" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      { kind: "Field", name: { kind: "Name", value: "slug" } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "pricing" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "buyerPrice" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "amount" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "currency" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "amountCents" },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "publishedAt" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "seconds" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "sale" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "code" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "buyerIneligibilityReason" },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "shipping" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "shippingPrices" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "shippingMethod" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "carrierCalculated" },
                      },
                      {
                        kind: "Field",
                        name: {
                          kind: "Name",
                          value: "destinationPostalCodeNeeded",
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "rate" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "amount" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "amountCents" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "currency" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "display" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "freeExpeditedShipping" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "localPickupOnly" },
                },
                { kind: "Field", name: { kind: "Name", value: "localPickup" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "certifiedPreOwned" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "title" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "badgeIconUrl" },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "shop" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "address" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "countryCode" },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "returnPolicy" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "newReturnWindowDays" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "usedReturnWindowDays" },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Commons_MParticle_OrderBundlesQuery,
  Commons_MParticle_OrderBundlesQueryVariables
>;
export const ReverifyCreditCardDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "ReverifyCreditCard" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "input" },
          },
          type: {
            kind: "NamedType",
            name: {
              kind: "Name",
              value: "Input_core_apimessages_ReverifyCreditCardRequest",
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "reverifyCreditCard" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "input" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "creditCard" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      { kind: "Field", name: { kind: "Name", value: "last4" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "cardType" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "expirationYear" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "expirationMonth" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "cardholderName" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "needsReverification" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "primaryForCheckout" },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  ReverifyCreditCardMutation,
  ReverifyCreditCardMutationVariables
>;
export const CreateMyWatchesDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "CreateMyWatches" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "input" },
          },
          type: {
            kind: "NamedType",
            name: {
              kind: "Name",
              value: "Input_core_apimessages_WatchesCreateRequest",
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "createWatch" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "input" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "listing" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "watching" },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CreateMyWatchesMutation,
  CreateMyWatchesMutationVariables
>;
export const DeleteMyWatchesDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "DeleteMyWatches" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "input" },
          },
          type: {
            kind: "NamedType",
            name: {
              kind: "Name",
              value: "Input_core_apimessages_WatchesDeleteRequest",
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "deleteWatch" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "input" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "listing" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "watching" },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  DeleteMyWatchesMutation,
  DeleteMyWatchesMutationVariables
>;
export const Commons_WatchedListingsDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "Commons_WatchedListings" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "limit" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "Int" } },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "showListingLocation" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "Boolean" },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "shouldSkipTracking" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "Boolean" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "me" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "uuid" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "profile" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "topProductTypeUuids" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "topProductTypes" },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "watches" },
                  arguments: [
                    {
                      kind: "Argument",
                      name: { kind: "Name", value: "input" },
                      value: {
                        kind: "ObjectValue",
                        fields: [
                          {
                            kind: "ObjectField",
                            name: { kind: "Name", value: "limit" },
                            value: {
                              kind: "Variable",
                              name: { kind: "Name", value: "limit" },
                            },
                          },
                          {
                            kind: "ObjectField",
                            name: { kind: "Name", value: "listingsState" },
                            value: { kind: "EnumValue", value: "LIVE" },
                          },
                        ],
                      },
                    },
                  ],
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "nodes" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "_id" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "listing" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "_id" },
                                  },
                                  {
                                    kind: "FragmentSpread",
                                    name: {
                                      kind: "Name",
                                      value: "ListingsCollection",
                                    },
                                  },
                                  {
                                    kind: "FragmentSpread",
                                    name: {
                                      kind: "Name",
                                      value: "mParticleListingFields",
                                    },
                                    directives: [
                                      {
                                        kind: "Directive",
                                        name: { kind: "Name", value: "skip" },
                                        arguments: [
                                          {
                                            kind: "Argument",
                                            name: { kind: "Name", value: "if" },
                                            value: {
                                              kind: "Variable",
                                              name: {
                                                kind: "Name",
                                                value: "shouldSkipTracking",
                                              },
                                            },
                                          },
                                        ],
                                      },
                                    ],
                                  },
                                  {
                                    kind: "FragmentSpread",
                                    name: { kind: "Name", value: "ShopFields" },
                                    directives: [
                                      {
                                        kind: "Directive",
                                        name: {
                                          kind: "Name",
                                          value: "include",
                                        },
                                        arguments: [
                                          {
                                            kind: "Argument",
                                            name: { kind: "Name", value: "if" },
                                            value: {
                                              kind: "Variable",
                                              name: {
                                                kind: "Name",
                                                value: "showListingLocation",
                                              },
                                            },
                                          },
                                        ],
                                      },
                                    ],
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "AddToCartButtonFields" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Listing" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "sellerId" } },
          { kind: "Field", name: { kind: "Name", value: "listingType" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "pricing" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "buyerPrice" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "amount" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "amountCents" },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "preorderInfo" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "onPreorder" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "estimatedShipDate" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "seconds" },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "WatchBadgeData" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Listing" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "title" } },
          { kind: "Field", name: { kind: "Name", value: "sellerId" } },
          { kind: "Field", name: { kind: "Name", value: "watching" } },
          {
            kind: "Field",
            alias: { kind: "Name", value: "watchThumbnails" },
            name: { kind: "Name", value: "images" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "type" },
                      value: {
                        kind: "StringValue",
                        value: "Product",
                        block: false,
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "scope" },
                      value: {
                        kind: "StringValue",
                        value: "photos",
                        block: false,
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "transform" },
                      value: {
                        kind: "StringValue",
                        value: "card_square",
                        block: false,
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "count" },
                      value: { kind: "IntValue", value: "1" },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "source" } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "ListingGreatValueData" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Listing" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "pricing" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "buyerPrice" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "currency" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "amountCents" },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "condition" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "conditionSlug" },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "priceRecommendation" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "priceMiddle" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "amountCents" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "currency" },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "shop" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "address" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "country" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "_id" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "countryCode" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          { kind: "Field", name: { kind: "Name", value: "currency" } },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "mParticleListingFields" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Listing" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "title" } },
          { kind: "Field", name: { kind: "Name", value: "brandSlug" } },
          { kind: "Field", name: { kind: "Name", value: "categoryRootUuid" } },
          { kind: "Field", name: { kind: "Name", value: "make" } },
          { kind: "Field", name: { kind: "Name", value: "categoryUuids" } },
          { kind: "Field", name: { kind: "Name", value: "state" } },
          { kind: "Field", name: { kind: "Name", value: "listingType" } },
          { kind: "Field", name: { kind: "Name", value: "bumpEligible" } },
          { kind: "Field", name: { kind: "Name", value: "shopId" } },
          { kind: "Field", name: { kind: "Name", value: "inventory" } },
          { kind: "Field", name: { kind: "Name", value: "soldAsIs" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "acceptedPaymentMethods" },
          },
          { kind: "Field", name: { kind: "Name", value: "currency" } },
          { kind: "Field", name: { kind: "Name", value: "usOutlet" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "condition" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "conditionUuid" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "conditionSlug" },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "categories" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "slug" } },
                { kind: "Field", name: { kind: "Name", value: "rootSlug" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "csp" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "slug" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "brand" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      { kind: "Field", name: { kind: "Name", value: "slug" } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "pricing" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "buyerPrice" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "amount" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "currency" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "amountCents" },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "publishedAt" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "seconds" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "sale" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "code" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "buyerIneligibilityReason" },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "shipping" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "shippingPrices" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "shippingMethod" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "carrierCalculated" },
                      },
                      {
                        kind: "Field",
                        name: {
                          kind: "Name",
                          value: "destinationPostalCodeNeeded",
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "rate" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "amount" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "amountCents" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "currency" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "display" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "freeExpeditedShipping" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "localPickupOnly" },
                },
                { kind: "Field", name: { kind: "Name", value: "localPickup" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "certifiedPreOwned" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "title" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "badgeIconUrl" },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "shop" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "address" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "countryCode" },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "returnPolicy" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "newReturnWindowDays" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "usedReturnWindowDays" },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "ListingCreateOfferButtonData" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Listing" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "state" } },
          { kind: "Field", name: { kind: "Name", value: "offersEnabled" } },
          { kind: "Field", name: { kind: "Name", value: "listingType" } },
          { kind: "Field", name: { kind: "Name", value: "sellerId" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "isBuyerOfferEligible" },
          },
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "mParticleListingFields" },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "ListingImagesFields" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Listing" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "images" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "transform" },
                      value: {
                        kind: "StringValue",
                        value: "card_square",
                        block: false,
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "count" },
                      value: { kind: "IntValue", value: "3" },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "scope" },
                      value: {
                        kind: "StringValue",
                        value: "photos",
                        block: false,
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "type" },
                      value: {
                        kind: "StringValue",
                        value: "Product",
                        block: false,
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "source" } },
              ],
            },
          },
          {
            kind: "Field",
            alias: { kind: "Name", value: "largeSquareImages" },
            name: { kind: "Name", value: "images" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "transform" },
                      value: {
                        kind: "StringValue",
                        value: "large_square",
                        block: false,
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "count" },
                      value: { kind: "IntValue", value: "3" },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "scope" },
                      value: {
                        kind: "StringValue",
                        value: "photos",
                        block: false,
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "type" },
                      value: {
                        kind: "StringValue",
                        value: "Product",
                        block: false,
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "source" } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "ListingCardCPOData" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Listing" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "certifiedPreOwned" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "title" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "badgeIconUrl" },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "ListingsCollection" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Listing" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "usOutlet" } },
          { kind: "Field", name: { kind: "Name", value: "bumped" } },
          { kind: "Field", name: { kind: "Name", value: "categoryUuids" } },
          { kind: "Field", name: { kind: "Name", value: "slug" } },
          { kind: "Field", name: { kind: "Name", value: "title" } },
          { kind: "Field", name: { kind: "Name", value: "description" } },
          { kind: "Field", name: { kind: "Name", value: "listingType" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "condition" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "displayName" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "conditionSlug" },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "price" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "amountCents" } },
                { kind: "Field", name: { kind: "Name", value: "display" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "pricing" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "buyerPrice" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "display" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "currency" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "amountCents" },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "originalPrice" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "display" },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "ribbon" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "display" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "reason" },
                      },
                    ],
                  },
                },
              ],
            },
          },
          { kind: "Field", name: { kind: "Name", value: "watching" } },
          { kind: "Field", name: { kind: "Name", value: "state" } },
          { kind: "Field", name: { kind: "Name", value: "stateDescription" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "shipping" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "shippingPrices" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "shippingMethod" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "carrierCalculated" },
                      },
                      {
                        kind: "Field",
                        name: {
                          kind: "Name",
                          value: "destinationPostalCodeNeeded",
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "rate" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "amount" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "amountCents" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "currency" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "display" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "freeExpeditedShipping" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "localPickupOnly" },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "shop" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "address" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "country" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "_id" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "countryCode" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "name" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "AddToCartButtonFields" },
          },
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "WatchBadgeData" },
          },
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "ListingGreatValueData" },
          },
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "ListingCreateOfferButtonData" },
          },
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "ListingImagesFields" },
          },
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "ListingCardCPOData" },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "ShopFields" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Listing" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "shop" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "address" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "locality" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "countryCode" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "region" },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Commons_WatchedListingsQuery,
  Commons_WatchedListingsQueryVariables
>;
export const Core_Get_Favorite_SearchesQueryDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "Core_Get_Favorite_SearchesQuery" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "limit" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "Int" } },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "offset" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "Int" } },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "me" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "uuid" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "favorites" },
                  arguments: [
                    {
                      kind: "Argument",
                      name: { kind: "Name", value: "input" },
                      value: {
                        kind: "ObjectValue",
                        fields: [
                          {
                            kind: "ObjectField",
                            name: { kind: "Name", value: "type" },
                            value: { kind: "EnumValue", value: "SAVED_SEARCH" },
                          },
                          {
                            kind: "ObjectField",
                            name: { kind: "Name", value: "limit" },
                            value: {
                              kind: "Variable",
                              name: { kind: "Name", value: "limit" },
                            },
                          },
                          {
                            kind: "ObjectField",
                            name: { kind: "Name", value: "offset" },
                            value: {
                              kind: "Variable",
                              name: { kind: "Name", value: "offset" },
                            },
                          },
                        ],
                      },
                    },
                  ],
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "favorites" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "title" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "subtitle" },
                            },
                            {
                              kind: "Field",
                              name: {
                                kind: "Name",
                                value: "listingsSearchUrlParams",
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "favorite" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "title" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "subtitle" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "favorited" },
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "feedEnabled",
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "emailEnabled",
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "queryParams",
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "link" },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "href" },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Core_Get_Favorite_SearchesQueryQuery,
  Core_Get_Favorite_SearchesQueryQueryVariables
>;
export const Commons_MParticle_ListingDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "Commons_MParticle_Listing" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "ids" } },
          type: {
            kind: "ListType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "shouldSkipTracking" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "Boolean" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "listings" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "ids" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "ids" },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                {
                  kind: "FragmentSpread",
                  name: { kind: "Name", value: "mParticleListingFields" },
                  directives: [
                    {
                      kind: "Directive",
                      name: { kind: "Name", value: "skip" },
                      arguments: [
                        {
                          kind: "Argument",
                          name: { kind: "Name", value: "if" },
                          value: {
                            kind: "Variable",
                            name: { kind: "Name", value: "shouldSkipTracking" },
                          },
                        },
                      ],
                    },
                  ],
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "mParticleListingFields" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Listing" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "title" } },
          { kind: "Field", name: { kind: "Name", value: "brandSlug" } },
          { kind: "Field", name: { kind: "Name", value: "categoryRootUuid" } },
          { kind: "Field", name: { kind: "Name", value: "make" } },
          { kind: "Field", name: { kind: "Name", value: "categoryUuids" } },
          { kind: "Field", name: { kind: "Name", value: "state" } },
          { kind: "Field", name: { kind: "Name", value: "listingType" } },
          { kind: "Field", name: { kind: "Name", value: "bumpEligible" } },
          { kind: "Field", name: { kind: "Name", value: "shopId" } },
          { kind: "Field", name: { kind: "Name", value: "inventory" } },
          { kind: "Field", name: { kind: "Name", value: "soldAsIs" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "acceptedPaymentMethods" },
          },
          { kind: "Field", name: { kind: "Name", value: "currency" } },
          { kind: "Field", name: { kind: "Name", value: "usOutlet" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "condition" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "conditionUuid" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "conditionSlug" },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "categories" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "slug" } },
                { kind: "Field", name: { kind: "Name", value: "rootSlug" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "csp" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "slug" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "brand" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      { kind: "Field", name: { kind: "Name", value: "slug" } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "pricing" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "buyerPrice" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "amount" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "currency" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "amountCents" },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "publishedAt" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "seconds" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "sale" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "code" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "buyerIneligibilityReason" },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "shipping" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "shippingPrices" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "shippingMethod" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "carrierCalculated" },
                      },
                      {
                        kind: "Field",
                        name: {
                          kind: "Name",
                          value: "destinationPostalCodeNeeded",
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "rate" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "amount" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "amountCents" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "currency" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "display" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "freeExpeditedShipping" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "localPickupOnly" },
                },
                { kind: "Field", name: { kind: "Name", value: "localPickup" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "certifiedPreOwned" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "title" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "badgeIconUrl" },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "shop" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "address" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "countryCode" },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "returnPolicy" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "newReturnWindowDays" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "usedReturnWindowDays" },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Commons_MParticle_ListingQuery,
  Commons_MParticle_ListingQueryVariables
>;
export const TrendingSearchesQueryDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "TrendingSearchesQuery" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "limit" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "Int" } },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "shouldSkipListings" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "Boolean" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "trendingSearches" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "nResults" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "limit" },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "trendingSearches" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "query" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "categoryUuid" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "categorySlug" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "queryParams" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "listings" },
                        directives: [
                          {
                            kind: "Directive",
                            name: { kind: "Name", value: "skip" },
                            arguments: [
                              {
                                kind: "Argument",
                                name: { kind: "Name", value: "if" },
                                value: {
                                  kind: "Variable",
                                  name: {
                                    kind: "Name",
                                    value: "shouldSkipListings",
                                  },
                                },
                              },
                            ],
                          },
                        ],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "_id" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "state" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "slug" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "images" },
                              arguments: [
                                {
                                  kind: "Argument",
                                  name: { kind: "Name", value: "input" },
                                  value: {
                                    kind: "ObjectValue",
                                    fields: [
                                      {
                                        kind: "ObjectField",
                                        name: { kind: "Name", value: "type" },
                                        value: {
                                          kind: "StringValue",
                                          value: "Product",
                                          block: false,
                                        },
                                      },
                                      {
                                        kind: "ObjectField",
                                        name: { kind: "Name", value: "scope" },
                                        value: {
                                          kind: "StringValue",
                                          value: "photos",
                                          block: false,
                                        },
                                      },
                                      {
                                        kind: "ObjectField",
                                        name: {
                                          kind: "Name",
                                          value: "transform",
                                        },
                                        value: {
                                          kind: "StringValue",
                                          value: "card_square",
                                          block: false,
                                        },
                                      },
                                      {
                                        kind: "ObjectField",
                                        name: { kind: "Name", value: "count" },
                                        value: { kind: "IntValue", value: "4" },
                                      },
                                    ],
                                  },
                                },
                              ],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "_id" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "source" },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  TrendingSearchesQueryQuery,
  TrendingSearchesQueryQueryVariables
>;
export const Core_Exchange_RatesDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "Core_Exchange_Rates" },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "exchangeRates" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "inverseReverseRates" },
                      value: { kind: "BooleanValue", value: true },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "rates" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "from" } },
                      { kind: "Field", name: { kind: "Name", value: "to" } },
                      { kind: "Field", name: { kind: "Name", value: "rate" } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Core_Exchange_RatesQuery,
  Core_Exchange_RatesQueryVariables
>;
export const Commons_Offers_ImmediatePaymentReviewDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "Commons_Offers_ImmediatePaymentReview" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "input" },
          },
          type: {
            kind: "NamedType",
            name: {
              kind: "Name",
              value:
                "Input_core_apimessages_EstimateNegotiationSalesTaxRequest",
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "estimateNegotiationSalesTax" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "input" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "amountTax" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "display" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "amountCents" },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Commons_Offers_ImmediatePaymentReviewQuery,
  Commons_Offers_ImmediatePaymentReviewQueryVariables
>;
export const Commons_Offers_EditOfferFormDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "Commons_Offers_EditOfferForm" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "listingId" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "negotiationId" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "isSeller" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "Boolean" },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "shippingRegionCode" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "exchangeRates" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "inverseReverseRates" },
                      value: { kind: "BooleanValue", value: true },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "rates" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "from" } },
                      { kind: "Field", name: { kind: "Name", value: "to" } },
                      { kind: "Field", name: { kind: "Name", value: "rate" } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "listing" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "id" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "listingId" },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "usOutlet" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "priceRecommendation" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "priceLow" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "amountCents" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "currency" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "display" },
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "priceMiddle" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "amountCents" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "currency" },
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "priceHigh" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "amountCents" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "currency" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "display" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "shipping" },
                  arguments: [
                    {
                      kind: "Argument",
                      name: { kind: "Name", value: "input" },
                      value: {
                        kind: "ObjectValue",
                        fields: [
                          {
                            kind: "ObjectField",
                            name: { kind: "Name", value: "shippingRegionCode" },
                            value: {
                              kind: "Variable",
                              name: {
                                kind: "Name",
                                value: "shippingRegionCode",
                              },
                            },
                          },
                        ],
                      },
                    },
                  ],
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "localPickupOnly" },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "offerBotRule" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "autoRejectPercentage" },
                      },
                    ],
                  },
                },
                {
                  kind: "FragmentSpread",
                  name: { kind: "Name", value: "OfferListingFragment" },
                },
                {
                  kind: "FragmentSpread",
                  name: { kind: "Name", value: "SellerOfferListingFragment" },
                  directives: [
                    {
                      kind: "Directive",
                      name: { kind: "Name", value: "include" },
                      arguments: [
                        {
                          kind: "Argument",
                          name: { kind: "Name", value: "if" },
                          value: {
                            kind: "Variable",
                            name: { kind: "Name", value: "isSeller" },
                          },
                        },
                      ],
                    },
                  ],
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "me" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "uuid" } },
                {
                  kind: "Field",
                  alias: { kind: "Name", value: "activeNegotiation" },
                  name: { kind: "Name", value: "myNegotiations" },
                  arguments: [
                    {
                      kind: "Argument",
                      name: { kind: "Name", value: "input" },
                      value: {
                        kind: "ObjectValue",
                        fields: [
                          {
                            kind: "ObjectField",
                            name: { kind: "Name", value: "ids" },
                            value: {
                              kind: "ListValue",
                              values: [
                                {
                                  kind: "Variable",
                                  name: {
                                    kind: "Name",
                                    value: "negotiationId",
                                  },
                                },
                              ],
                            },
                          },
                        ],
                      },
                    },
                  ],
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "negotiations" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "_id" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "buyerUuid" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "sellerUuid" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "taxIncluded" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "taxIncludedHint" },
                            },
                            {
                              kind: "Field",
                              name: {
                                kind: "Name",
                                value: "sellerShippingWarning",
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "state" },
                            },
                            {
                              kind: "Field",
                              name: {
                                kind: "Name",
                                value: "buyerWillPayImmediately",
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "buyer" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "_id" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "name" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "shop" },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "name" },
                                        },
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "slug" },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "shippingLocation" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "_id" },
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "displayLocation",
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "lastOffer" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "status" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "offerItems" },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "quantity",
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "prices" },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "price",
                                          },
                                          selectionSet: {
                                            kind: "SelectionSet",
                                            selections: [
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "display",
                                                },
                                                selectionSet: {
                                                  kind: "SelectionSet",
                                                  selections: [
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "amount",
                                                      },
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "amountCents",
                                                      },
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "currency",
                                                      },
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "symbol",
                                                      },
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "display",
                                                      },
                                                    },
                                                  ],
                                                },
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "original",
                                                },
                                                selectionSet: {
                                                  kind: "SelectionSet",
                                                  selections: [
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "amount",
                                                      },
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "amountCents",
                                                      },
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "currency",
                                                      },
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "symbol",
                                                      },
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "display",
                                                      },
                                                    },
                                                  ],
                                                },
                                              },
                                            ],
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "shippingPrice",
                                          },
                                          selectionSet: {
                                            kind: "SelectionSet",
                                            selections: [
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "display",
                                                },
                                                selectionSet: {
                                                  kind: "SelectionSet",
                                                  selections: [
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "amount",
                                                      },
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "amountCents",
                                                      },
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "currency",
                                                      },
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "symbol",
                                                      },
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "display",
                                                      },
                                                    },
                                                  ],
                                                },
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "original",
                                                },
                                                selectionSet: {
                                                  kind: "SelectionSet",
                                                  selections: [
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "amount",
                                                      },
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "amountCents",
                                                      },
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "currency",
                                                      },
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "symbol",
                                                      },
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "display",
                                                      },
                                                    },
                                                  ],
                                                },
                                              },
                                            ],
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "totalPrice",
                                          },
                                          selectionSet: {
                                            kind: "SelectionSet",
                                            selections: [
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "display",
                                                },
                                                selectionSet: {
                                                  kind: "SelectionSet",
                                                  selections: [
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "amount",
                                                      },
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "amountCents",
                                                      },
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "currency",
                                                      },
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "symbol",
                                                      },
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "display",
                                                      },
                                                    },
                                                  ],
                                                },
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "original",
                                                },
                                                selectionSet: {
                                                  kind: "SelectionSet",
                                                  selections: [
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "amount",
                                                      },
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "amountCents",
                                                      },
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "currency",
                                                      },
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "symbol",
                                                      },
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "display",
                                                      },
                                                    },
                                                  ],
                                                },
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "shippingAddresses" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      { kind: "Field", name: { kind: "Name", value: "name" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "displayLocation" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "streetAddress" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "isComplete" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "countryCode" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "primary" },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "creditCards" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "display" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "summary" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "expirationDate" },
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "primaryForCheckout" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "expirationYear" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "expirationMonth" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "needsReverification" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "address" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "_id" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "isComplete" },
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "cardType" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "brand" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "type" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "name" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "OfferListingFragment" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Listing" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "slug" } },
          { kind: "Field", name: { kind: "Name", value: "title" } },
          { kind: "Field", name: { kind: "Name", value: "hasInventory" } },
          { kind: "Field", name: { kind: "Name", value: "inventory" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "images" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "transform" },
                      value: {
                        kind: "StringValue",
                        value: "card_square",
                        block: false,
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "source" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "condition" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "displayName" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "conditionSlug" },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "price" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "amount" } },
                { kind: "Field", name: { kind: "Name", value: "amountCents" } },
                { kind: "Field", name: { kind: "Name", value: "currency" } },
                { kind: "Field", name: { kind: "Name", value: "symbol" } },
                { kind: "Field", name: { kind: "Name", value: "display" } },
              ],
            },
          },
          { kind: "Field", name: { kind: "Name", value: "taxIncluded" } },
          { kind: "Field", name: { kind: "Name", value: "taxIncludedHint" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "shop" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "name" } },
                { kind: "Field", name: { kind: "Name", value: "currency" } },
                { kind: "Field", name: { kind: "Name", value: "slug" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "address" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "displayLocation" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "locality" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "region" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "postalCode" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "countryCode" },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "lowballOfferPercentage" },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "sale" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "id" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "canonicalProductId" },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "SellerOfferListingFragment" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Listing" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "sellerPrice" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "amount" } },
                { kind: "Field", name: { kind: "Name", value: "amountCents" } },
                { kind: "Field", name: { kind: "Name", value: "currency" } },
                { kind: "Field", name: { kind: "Name", value: "symbol" } },
                { kind: "Field", name: { kind: "Name", value: "display" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "sellerCost" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "display" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Commons_Offers_EditOfferFormQuery,
  Commons_Offers_EditOfferFormQueryVariables
>;
export const Commons_Offers_NewOfferFormDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "Commons_Offers_NewOfferForm" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "listingId" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "shippingRegionCode" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "usePrimaryShippingAddress" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "Boolean" } },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "isSeller" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "Boolean" },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "postalCode" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "countries" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "countries" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      { kind: "Field", name: { kind: "Name", value: "name" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "countryCode" },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "exchangeRates" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "inverseReverseRates" },
                      value: { kind: "BooleanValue", value: true },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "rates" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "from" } },
                      { kind: "Field", name: { kind: "Name", value: "to" } },
                      { kind: "Field", name: { kind: "Name", value: "rate" } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "listing" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "id" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "listingId" },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "usOutlet" } },
                {
                  kind: "FragmentSpread",
                  name: { kind: "Name", value: "OfferListingFragment" },
                },
                {
                  kind: "FragmentSpread",
                  name: { kind: "Name", value: "SellerOfferListingFragment" },
                  directives: [
                    {
                      kind: "Directive",
                      name: { kind: "Name", value: "include" },
                      arguments: [
                        {
                          kind: "Argument",
                          name: { kind: "Name", value: "if" },
                          value: {
                            kind: "Variable",
                            name: { kind: "Name", value: "isSeller" },
                          },
                        },
                      ],
                    },
                  ],
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "shipping" },
                  arguments: [
                    {
                      kind: "Argument",
                      name: { kind: "Name", value: "input" },
                      value: {
                        kind: "ObjectValue",
                        fields: [
                          {
                            kind: "ObjectField",
                            name: { kind: "Name", value: "shippingRegionCode" },
                            value: {
                              kind: "Variable",
                              name: {
                                kind: "Name",
                                value: "shippingRegionCode",
                              },
                            },
                          },
                          {
                            kind: "ObjectField",
                            name: {
                              kind: "Name",
                              value: "usePrimaryShippingAddress",
                            },
                            value: {
                              kind: "Variable",
                              name: {
                                kind: "Name",
                                value: "usePrimaryShippingAddress",
                              },
                            },
                          },
                          {
                            kind: "ObjectField",
                            name: { kind: "Name", value: "postalCode" },
                            value: {
                              kind: "Variable",
                              name: { kind: "Name", value: "postalCode" },
                            },
                          },
                        ],
                      },
                    },
                  ],
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "localPickupOnly" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "shippingRegionCode" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "shippingAddress" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "_id" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "postalCode" },
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "shippingPrices" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "_id" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "rate" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "amount" },
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "amountCents",
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "currency" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "symbol" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "display" },
                                  },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "originalRate" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "amount" },
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "amountCents",
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "currency" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "symbol" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "display" },
                                  },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "shippingMethod" },
                            },
                            {
                              kind: "Field",
                              name: {
                                kind: "Name",
                                value: "carrierCalculated",
                              },
                            },
                            {
                              kind: "Field",
                              name: {
                                kind: "Name",
                                value: "destinationPostalCodeNeeded",
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "postalCode" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "offerBotRule" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "autoRejectPercentage" },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "priceRecommendation" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "priceLow" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "amountCents" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "currency" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "display" },
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "priceMiddle" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "amountCents" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "currency" },
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "priceHigh" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "amountCents" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "currency" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "display" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: {
                    kind: "Name",
                    value: "otherBuyersWithListingInCartCounts",
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "me" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "uuid" } },
                {
                  kind: "Field",
                  alias: { kind: "Name", value: "offerCount" },
                  name: { kind: "Name", value: "myNegotiations" },
                  arguments: [
                    {
                      kind: "Argument",
                      name: { kind: "Name", value: "input" },
                      value: {
                        kind: "ObjectValue",
                        fields: [
                          {
                            kind: "ObjectField",
                            name: { kind: "Name", value: "state" },
                            value: { kind: "EnumValue", value: "ACCEPTED" },
                          },
                          {
                            kind: "ObjectField",
                            name: { kind: "Name", value: "as" },
                            value: { kind: "EnumValue", value: "BUYER" },
                          },
                          {
                            kind: "ObjectField",
                            name: { kind: "Name", value: "limit" },
                            value: { kind: "IntValue", value: "1" },
                          },
                        ],
                      },
                    },
                  ],
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "total" } },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "shippingAddresses" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      { kind: "Field", name: { kind: "Name", value: "name" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "displayLocation" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "streetAddress" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "isComplete" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "countryCode" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "primary" },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "creditCards" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "display" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "summary" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "expirationDate" },
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "primaryForCheckout" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "expirationYear" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "expirationMonth" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "needsReverification" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "address" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "_id" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "isComplete" },
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "cardType" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "brand" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "type" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "name" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "OfferListingFragment" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Listing" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "slug" } },
          { kind: "Field", name: { kind: "Name", value: "title" } },
          { kind: "Field", name: { kind: "Name", value: "hasInventory" } },
          { kind: "Field", name: { kind: "Name", value: "inventory" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "images" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "transform" },
                      value: {
                        kind: "StringValue",
                        value: "card_square",
                        block: false,
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "source" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "condition" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "displayName" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "conditionSlug" },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "price" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "amount" } },
                { kind: "Field", name: { kind: "Name", value: "amountCents" } },
                { kind: "Field", name: { kind: "Name", value: "currency" } },
                { kind: "Field", name: { kind: "Name", value: "symbol" } },
                { kind: "Field", name: { kind: "Name", value: "display" } },
              ],
            },
          },
          { kind: "Field", name: { kind: "Name", value: "taxIncluded" } },
          { kind: "Field", name: { kind: "Name", value: "taxIncludedHint" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "shop" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "name" } },
                { kind: "Field", name: { kind: "Name", value: "currency" } },
                { kind: "Field", name: { kind: "Name", value: "slug" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "address" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "displayLocation" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "locality" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "region" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "postalCode" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "countryCode" },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "lowballOfferPercentage" },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "sale" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "id" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "canonicalProductId" },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "SellerOfferListingFragment" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Listing" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "sellerPrice" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "amount" } },
                { kind: "Field", name: { kind: "Name", value: "amountCents" } },
                { kind: "Field", name: { kind: "Name", value: "currency" } },
                { kind: "Field", name: { kind: "Name", value: "symbol" } },
                { kind: "Field", name: { kind: "Name", value: "display" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "sellerCost" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "display" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Commons_Offers_NewOfferFormQuery,
  Commons_Offers_NewOfferFormQueryVariables
>;
export const Commons_Offers_CreateOfferFormDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "Commons_Offers_CreateOfferForm" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "input" },
          },
          type: {
            kind: "NamedType",
            name: {
              kind: "Name",
              value: "Input_core_apimessages_CreateNegotiationRequest",
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "createNegotiation" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "input" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "negotiations" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "lastOffer" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "offerItems" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "listingId" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "quantity" },
                                  },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "initiatingParty" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "prices" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "price" },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "display",
                                          },
                                          selectionSet: {
                                            kind: "SelectionSet",
                                            selections: [
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "amount",
                                                },
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "amountCents",
                                                },
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "currency",
                                                },
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "symbol",
                                                },
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "display",
                                                },
                                              },
                                            ],
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "original",
                                          },
                                          selectionSet: {
                                            kind: "SelectionSet",
                                            selections: [
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "amount",
                                                },
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "amountCents",
                                                },
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "currency",
                                                },
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "symbol",
                                                },
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "display",
                                                },
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "shippingPrice",
                                    },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "display",
                                          },
                                          selectionSet: {
                                            kind: "SelectionSet",
                                            selections: [
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "amount",
                                                },
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "amountCents",
                                                },
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "currency",
                                                },
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "symbol",
                                                },
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "display",
                                                },
                                              },
                                            ],
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "original",
                                          },
                                          selectionSet: {
                                            kind: "SelectionSet",
                                            selections: [
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "amount",
                                                },
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "amountCents",
                                                },
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "currency",
                                                },
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "symbol",
                                                },
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "display",
                                                },
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "totalPrice" },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "display",
                                          },
                                          selectionSet: {
                                            kind: "SelectionSet",
                                            selections: [
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "amount",
                                                },
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "amountCents",
                                                },
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "currency",
                                                },
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "symbol",
                                                },
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "display",
                                                },
                                              },
                                            ],
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "original",
                                          },
                                          selectionSet: {
                                            kind: "SelectionSet",
                                            selections: [
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "amount",
                                                },
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "amountCents",
                                                },
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "currency",
                                                },
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "symbol",
                                                },
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "display",
                                                },
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Commons_Offers_CreateOfferFormMutation,
  Commons_Offers_CreateOfferFormMutationVariables
>;
export const Commons_Offers_UpdateOfferFormDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "Commons_Offers_UpdateOfferForm" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "input" },
          },
          type: {
            kind: "NamedType",
            name: {
              kind: "Name",
              value: "Input_core_apimessages_UpdateNegotiationRequest",
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "updateNegotiation" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "input" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "negotiation" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      { kind: "Field", name: { kind: "Name", value: "state" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "lastOffer" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "prices" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "price" },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "display",
                                          },
                                          selectionSet: {
                                            kind: "SelectionSet",
                                            selections: [
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "display",
                                                },
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "amountCents",
                                                },
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "currency",
                                                },
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "shippingPrice",
                                    },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "display",
                                          },
                                          selectionSet: {
                                            kind: "SelectionSet",
                                            selections: [
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "display",
                                                },
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "totalPrice" },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "display",
                                          },
                                          selectionSet: {
                                            kind: "SelectionSet",
                                            selections: [
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "display",
                                                },
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Commons_Offers_UpdateOfferFormMutation,
  Commons_Offers_UpdateOfferFormMutationVariables
>;
export const Core_AutoOffers_CreateAutoOfferMutationDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "Core_AutoOffers_CreateAutoOfferMutation" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "input" },
          },
          type: {
            kind: "NamedType",
            name: {
              kind: "Name",
              value: "Input_core_apimessages_CreateAutoOfferRequest",
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "createAutoOffer" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "input" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "autoOffer" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "listingId" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "message" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "price" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "amount" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "amountCents" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "currency" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "symbol" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "display" },
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "percentage" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "shippingPriceOverride" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "amount" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "amountCents" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "currency" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "symbol" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "display" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Core_AutoOffers_CreateAutoOfferMutationMutation,
  Core_AutoOffers_CreateAutoOfferMutationMutationVariables
>;
export const Core_AutoOffers_DeleteAutoOfferMutationDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "Core_AutoOffers_DeleteAutoOfferMutation" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "input" },
          },
          type: {
            kind: "NamedType",
            name: {
              kind: "Name",
              value: "Input_core_apimessages_DeleteAutoOfferRequest",
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "deleteAutoOffer" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "input" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "autoOffer" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "listingId" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "message" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "price" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "amount" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "amountCents" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "currency" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "symbol" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "display" },
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "percentage" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "shippingPriceOverride" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "amount" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "amountCents" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "currency" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "symbol" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "display" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Core_AutoOffers_DeleteAutoOfferMutationMutation,
  Core_AutoOffers_DeleteAutoOfferMutationMutationVariables
>;
export const Core_Track_Bump_InteractionDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "Core_Track_Bump_Interaction" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "input" },
          },
          type: {
            kind: "NamedType",
            name: {
              kind: "Name",
              value: "Input_core_apimessages_TrackBumpInteractionRequest",
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "trackBumpInteraction" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "input" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "bumpKey" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "key" } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Core_Track_Bump_InteractionMutation,
  Core_Track_Bump_InteractionMutationVariables
>;
export const Core_FetchCountriesDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "Core_FetchCountries" },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "countries" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "countries" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      { kind: "Field", name: { kind: "Name", value: "name" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "countryCode" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "subregionRequired" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "subregions" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "_id" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "name" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "code" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Core_FetchCountriesQuery,
  Core_FetchCountriesQueryVariables
>;
export const Core_Test_UseMutationDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "Core_Test_UseMutation" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "listingId" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "quantity" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "Int" } },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "editCart" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "listingId" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "listingId" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "quantity" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "quantity" },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "listingId" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Core_Test_UseMutationMutation,
  Core_Test_UseMutationMutationVariables
>;
export const Core_UserActions_CreateDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "Core_UserActions_Create" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "input" },
          },
          type: {
            kind: "NamedType",
            name: {
              kind: "Name",
              value: "Input_core_apimessages_CreateMyActionRequest",
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "createMyAction" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "input" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "errors" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "message" },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "action" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      { kind: "Field", name: { kind: "Name", value: "name" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "status" },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Core_UserActions_CreateMutation,
  Core_UserActions_CreateMutationVariables
>;
export const Core_RequestAccountDeletionDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "Core_RequestAccountDeletion" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "input" },
          },
          type: {
            kind: "NamedType",
            name: {
              kind: "Name",
              value: "Input_core_apimessages_RequestAccountDeletionRequest",
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "requestAccountDeletion" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "input" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "uuid" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Core_RequestAccountDeletionMutation,
  Core_RequestAccountDeletionMutationVariables
>;
export const Core_AddressAutocomplete_FindAutocompleteSuggestionsDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: {
        kind: "Name",
        value: "Core_AddressAutocomplete_FindAutocompleteSuggestions",
      },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "query" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "containerId" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "findAddressAutocompleteSuggestions" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "text" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "query" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "container" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "containerId" },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "suggestions" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      { kind: "Field", name: { kind: "Name", value: "type" } },
                      { kind: "Field", name: { kind: "Name", value: "text" } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Core_AddressAutocomplete_FindAutocompleteSuggestionsQuery,
  Core_AddressAutocomplete_FindAutocompleteSuggestionsQueryVariables
>;
export const Core_AddressAutocomplete_SelectAutocompleteSuggestionDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: {
        kind: "Name",
        value: "Core_AddressAutocomplete_SelectAutocompleteSuggestion",
      },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "id" } },
          type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: {
              kind: "Name",
              value: "selectAddressAutocompleteSuggestion",
            },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "id" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "id" },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "suggestion" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "streetAddress" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "extendedAddress" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "locality" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "region" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "postalCode" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "countryCode" },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Core_AddressAutocomplete_SelectAutocompleteSuggestionMutation,
  Core_AddressAutocomplete_SelectAutocompleteSuggestionMutationVariables
>;
export const Core_AdminRollouts_UserSearchDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "Core_AdminRollouts_UserSearch" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "search" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
        },
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "skip" } },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "Boolean" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "userSearch" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "search" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "search" },
                      },
                    },
                  ],
                },
              },
            ],
            directives: [
              {
                kind: "Directive",
                name: { kind: "Name", value: "skip" },
                arguments: [
                  {
                    kind: "Argument",
                    name: { kind: "Name", value: "if" },
                    value: {
                      kind: "Variable",
                      name: { kind: "Name", value: "skip" },
                    },
                  },
                ],
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "users" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "shopName" },
                      },
                      { kind: "Field", name: { kind: "Name", value: "email" } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Core_AdminRollouts_UserSearchQuery,
  Core_AdminRollouts_UserSearchQueryVariables
>;
export const Core_AdminRolloutsCompare_UserSearchDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "Core_AdminRolloutsCompare_UserSearch" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "search" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "userSearch" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "search" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "search" },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "users" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "shopName" },
                      },
                      { kind: "Field", name: { kind: "Name", value: "email" } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Core_AdminRolloutsCompare_UserSearchQuery,
  Core_AdminRolloutsCompare_UserSearchQueryVariables
>;
export const Core_Checkout_FetchDigitalWalletPaymentCheckoutDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: {
        kind: "Name",
        value: "Core_Checkout_FetchDigitalWalletPaymentCheckout",
      },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "id" } },
          type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "reverbApp" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "checkout" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "id" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "id" },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "action" },
                  arguments: [
                    {
                      kind: "Argument",
                      name: { kind: "Name", value: "input" },
                      value: {
                        kind: "ObjectValue",
                        fields: [
                          {
                            kind: "ObjectField",
                            name: { kind: "Name", value: "reverbApp" },
                            value: {
                              kind: "Variable",
                              name: { kind: "Name", value: "reverbApp" },
                            },
                          },
                        ],
                      },
                    },
                  ],
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      { kind: "Field", name: { kind: "Name", value: "type" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "redirectUrl" },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "paymentErrorMessage" },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Core_Checkout_FetchDigitalWalletPaymentCheckoutQuery,
  Core_Checkout_FetchDigitalWalletPaymentCheckoutQueryVariables
>;
export const StartCheckoutForListingDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "StartCheckoutForListing" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "input" },
          },
          type: {
            kind: "NamedType",
            name: {
              kind: "Name",
              value: "Input_core_apimessages_StartCheckoutForListingRequest",
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "startCheckoutForListing" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "input" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "redirectUrl" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  StartCheckoutForListingMutation,
  StartCheckoutForListingMutationVariables
>;
export const StartDigitalWalletCheckoutForListingDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "StartDigitalWalletCheckoutForListing" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "input" },
          },
          type: {
            kind: "NamedType",
            name: {
              kind: "Name",
              value: "Input_core_apimessages_StartCheckoutForListingRequest",
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "startCheckoutForListing" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "input" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "checkoutId" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  StartDigitalWalletCheckoutForListingMutation,
  StartDigitalWalletCheckoutForListingMutationVariables
>;
export const UpdateShippingEstimateMutationDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "UpdateShippingEstimateMutation" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "input" },
          },
          type: {
            kind: "NamedType",
            name: {
              kind: "Name",
              value:
                "Input_core_apimessages_CheckoutUpdateShippingEstimateRequest",
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "updateCheckoutShippingEstimate" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "input" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "checkout" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "total" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "label" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "amount" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "amount" },
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "amountCents",
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "currency" },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "lineItems" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "type" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "label" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "amount" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "amount" },
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "amountCents",
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "currency" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "symbol" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "display" },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateShippingEstimateMutationMutation,
  UpdateShippingEstimateMutationMutationVariables
>;
export const FinalizeDigitalWalletPaymentDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "FinalizeDigitalWalletPayment" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "input" },
          },
          type: {
            kind: "NamedType",
            name: {
              kind: "Name",
              value:
                "Input_core_apimessages_CheckoutFinalizeDigitalWalletPaymentRequest",
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "finalizeDigitalWalletPayment" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "input" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  FinalizeDigitalWalletPaymentMutation,
  FinalizeDigitalWalletPaymentMutationVariables
>;
export const Core_Checkout_ApplyDiscountCodeDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "Core_Checkout_ApplyDiscountCode" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "input" },
          },
          type: {
            kind: "NamedType",
            name: {
              kind: "Name",
              value: "Input_core_apimessages_ApplyCheckoutDiscountCodeRequest",
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "applyCheckoutDiscountCode" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "input" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "checkout" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Core_Checkout_ApplyDiscountCodeMutation,
  Core_Checkout_ApplyDiscountCodeMutationVariables
>;
export const Core_Checkout_HeaderDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "Core_Checkout_Header" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "id" } },
          type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "checkout" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "id" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "id" },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                {
                  kind: "FragmentSpread",
                  name: { kind: "Name", value: "CheckoutTrackingFields" },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "CheckoutTrackingFields" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Checkout" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "source" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "paymentMethod" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "type" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Core_Checkout_HeaderQuery,
  Core_Checkout_HeaderQueryVariables
>;
export const Core_Checkout_CreateKlarnaPaymentRedirectUrlDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: {
        kind: "Name",
        value: "Core_Checkout_CreateKlarnaPaymentRedirectUrl",
      },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "input" },
          },
          type: {
            kind: "NamedType",
            name: {
              kind: "Name",
              value:
                "Input_core_apimessages_CheckoutCreatePaymentRedirectUrlRequest",
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "createCheckoutPaymentRedirectUrl" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "input" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "checkout" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                    ],
                  },
                },
                { kind: "Field", name: { kind: "Name", value: "redirectUrl" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Core_Checkout_CreateKlarnaPaymentRedirectUrlMutation,
  Core_Checkout_CreateKlarnaPaymentRedirectUrlMutationVariables
>;
export const Core_Checkout_StartPaypalCheckout_RedirectDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: {
        kind: "Name",
        value: "Core_Checkout_StartPaypalCheckout_Redirect",
      },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "input" },
          },
          type: {
            kind: "NamedType",
            name: {
              kind: "Name",
              value: "Input_core_apimessages_StartPaypalCheckoutRequest",
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "startPaypalCheckout" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "input" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "checkout" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                    ],
                  },
                },
                { kind: "Field", name: { kind: "Name", value: "redirectUrl" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Core_Checkout_StartPaypalCheckout_RedirectMutation,
  Core_Checkout_StartPaypalCheckout_RedirectMutationVariables
>;
export const Core_Conversations_FlagMessageDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "Core_Conversations_FlagMessage" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "input" },
          },
          type: {
            kind: "NamedType",
            name: {
              kind: "Name",
              value: "Input_core_apimessages_ConversationFlagMessageRequest",
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "flagMessage" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "input" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Core_Conversations_FlagMessageMutation,
  Core_Conversations_FlagMessageMutationVariables
>;
export const Core_Checkout_DeleteCreditCardDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "Core_Checkout_DeleteCreditCard" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "input" },
          },
          type: {
            kind: "NamedType",
            name: {
              kind: "Name",
              value: "Input_core_apimessages_DeleteMyCreditCardRequest",
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "deleteMyCreditCard" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "input" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Core_Checkout_DeleteCreditCardMutation,
  Core_Checkout_DeleteCreditCardMutationVariables
>;
export const Core_MyAccount_CreditCardsOnFileDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "Core_MyAccount_CreditCardsOnFile" },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "countries" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "countries" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "countryCode" },
                      },
                      { kind: "Field", name: { kind: "Name", value: "name" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "subregionRequired" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "subregions" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "_id" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "code" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "name" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "me" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "uuid" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "creditCards" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      { kind: "Field", name: { kind: "Name", value: "last4" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "brand" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "type" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "name" },
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "display" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "summary" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "expirationDate" },
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "address" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "_id" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "countryCode" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "name" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "streetAddress" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "extendedAddress" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "locality" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "region" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "postalCode" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "phone" },
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "expirationMonth" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "expirationYear" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "cardholderName" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "primaryBilling" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "primaryForCheckout" },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Core_MyAccount_CreditCardsOnFileQuery,
  Core_MyAccount_CreditCardsOnFileQueryVariables
>;
export const Core_PaypalOnboardSellerDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "Core_PaypalOnboardSeller" },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "paypalOnboardSeller" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "actionUrl" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Core_PaypalOnboardSellerQuery,
  Core_PaypalOnboardSellerQueryVariables
>;
export const Core_AdminBlockProductReviewDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "Core_AdminBlockProductReview" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "input" },
          },
          type: {
            kind: "NamedType",
            name: {
              kind: "Name",
              value: "Input_core_apimessages_AdminBlockProductReviewRequest",
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "adminBlockProductReview" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "input" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Core_AdminBlockProductReviewMutation,
  Core_AdminBlockProductReviewMutationVariables
>;
export const Core_ProductReview_FlagDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "Core_ProductReview_Flag" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "input" },
          },
          type: {
            kind: "NamedType",
            name: {
              kind: "Name",
              value: "Input_core_apimessages_FlagProductReviewRequest",
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "flagProductReview" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "input" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Core_ProductReview_FlagMutation,
  Core_ProductReview_FlagMutationVariables
>;
export const Core_ProductReview_UpdateDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "Core_ProductReview_Update" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "input" },
          },
          type: {
            kind: "NamedType",
            name: {
              kind: "Name",
              value: "Input_core_apimessages_UpdateProductReviewRequest",
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "updateProductReview" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "input" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Core_ProductReview_UpdateMutation,
  Core_ProductReview_UpdateMutationVariables
>;
export const Core_ProductReview_VoteDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "Core_ProductReview_Vote" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "id" } },
          type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "voteProductReview" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "id" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "id" },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Core_ProductReview_VoteMutation,
  Core_ProductReview_VoteMutationVariables
>;
export const Core_Product_ReviewsDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "Core_Product_Reviews" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "cspId" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "offset" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "Int" } },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "ratings" },
          },
          type: {
            kind: "ListType",
            type: {
              kind: "NamedType",
              name: {
                kind: "Name",
                value: "reverb_search_ProductReviewsSearchRequest_Rating",
              },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "verified" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "Boolean" } },
        },
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "sort" } },
          type: {
            kind: "NamedType",
            name: {
              kind: "Name",
              value: "reverb_search_ProductReviewsSearchRequest_Sort",
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "fullTextQuery" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "csp" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "id" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "cspId" },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                {
                  kind: "FragmentSpread",
                  name: { kind: "Name", value: "ProductReviewCSPFields" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "productReviewSearch" },
                  arguments: [
                    {
                      kind: "Argument",
                      name: { kind: "Name", value: "input" },
                      value: {
                        kind: "ObjectValue",
                        fields: [
                          {
                            kind: "ObjectField",
                            name: { kind: "Name", value: "sort" },
                            value: {
                              kind: "Variable",
                              name: { kind: "Name", value: "sort" },
                            },
                          },
                          {
                            kind: "ObjectField",
                            name: { kind: "Name", value: "ratings" },
                            value: {
                              kind: "Variable",
                              name: { kind: "Name", value: "ratings" },
                            },
                          },
                          {
                            kind: "ObjectField",
                            name: { kind: "Name", value: "verified" },
                            value: {
                              kind: "Variable",
                              name: { kind: "Name", value: "verified" },
                            },
                          },
                          {
                            kind: "ObjectField",
                            name: { kind: "Name", value: "limit" },
                            value: { kind: "IntValue", value: "5" },
                          },
                          {
                            kind: "ObjectField",
                            name: { kind: "Name", value: "offset" },
                            value: {
                              kind: "Variable",
                              name: { kind: "Name", value: "offset" },
                            },
                          },
                          {
                            kind: "ObjectField",
                            name: { kind: "Name", value: "fullTextQuery" },
                            value: {
                              kind: "Variable",
                              name: { kind: "Name", value: "fullTextQuery" },
                            },
                          },
                        ],
                      },
                    },
                  ],
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "total" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "productReviews" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "_id" },
                            },
                            {
                              kind: "FragmentSpread",
                              name: {
                                kind: "Name",
                                value: "ProductReviewCardFields",
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "filters" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "FragmentSpread",
                              name: {
                                kind: "Name",
                                value: "ProductReviewFilterFields",
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  alias: { kind: "Name", value: "statsSearch" },
                  name: { kind: "Name", value: "productReviewSearch" },
                  arguments: [
                    {
                      kind: "Argument",
                      name: { kind: "Name", value: "input" },
                      value: {
                        kind: "ObjectValue",
                        fields: [
                          {
                            kind: "ObjectField",
                            name: { kind: "Name", value: "limit" },
                            value: { kind: "IntValue", value: "0" },
                          },
                        ],
                      },
                    },
                  ],
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "stats" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "total" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "averageRating" },
                            },
                            {
                              kind: "Field",
                              name: {
                                kind: "Name",
                                value: "ratingsDistribution",
                              },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "rating" },
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "reviewCount",
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "ProductReviewCSPFields" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "CSP" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "slug" } },
          { kind: "Field", name: { kind: "Name", value: "title" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "myReview" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "body" } },
                { kind: "Field", name: { kind: "Name", value: "title" } },
                { kind: "Field", name: { kind: "Name", value: "rating" } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "ProductReviewCardFields" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "ProductReview" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "title" } },
          { kind: "Field", name: { kind: "Name", value: "body" } },
          { kind: "Field", name: { kind: "Name", value: "rating" } },
          { kind: "Field", name: { kind: "Name", value: "createdAt" } },
          { kind: "Field", name: { kind: "Name", value: "voteCount" } },
          { kind: "Field", name: { kind: "Name", value: "verified" } },
          { kind: "Field", name: { kind: "Name", value: "voted" } },
          { kind: "Field", name: { kind: "Name", value: "isMyReview" } },
          { kind: "Field", name: { kind: "Name", value: "reported" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "reviewer" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "shortname" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "links" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "avatar" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "href" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "ProductReviewFilterFields" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "reverb_search_Filter" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "name" } },
          { kind: "Field", name: { kind: "Name", value: "key" } },
          { kind: "Field", name: { kind: "Name", value: "widgetType" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "options" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "count" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "value" } },
                    ],
                  },
                },
                { kind: "Field", name: { kind: "Name", value: "name" } },
                { kind: "Field", name: { kind: "Name", value: "selected" } },
                { kind: "Field", name: { kind: "Name", value: "paramName" } },
                { kind: "Field", name: { kind: "Name", value: "setValues" } },
                { kind: "Field", name: { kind: "Name", value: "unsetValues" } },
                { kind: "Field", name: { kind: "Name", value: "optionValue" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Core_Product_ReviewsQuery,
  Core_Product_ReviewsQueryVariables
>;
export const Core_AdminReports_GenerateAdminReportDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "Core_AdminReports_GenerateAdminReport" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "input" },
          },
          type: {
            kind: "NamedType",
            name: {
              kind: "Name",
              value: "Input_core_apimessages_GenerateAdminReportRequest",
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "generateAdminReport" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "input" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "reportEnqueued" },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Core_AdminReports_GenerateAdminReportMutation,
  Core_AdminReports_GenerateAdminReportMutationVariables
>;
export const Core_Update_Ad_CampaignDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "Core_Update_Ad_Campaign" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "input" },
          },
          type: {
            kind: "NamedType",
            name: {
              kind: "Name",
              value: "Input_core_apimessages_UpdateAdCampaignRequest",
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "updateAdCampaign" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "input" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "adCampaign" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "name" } },
                      { kind: "Field", name: { kind: "Name", value: "uuid" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "description" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "status" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "startsAt" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "endsAt" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "advertiserUuid" },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Core_Update_Ad_CampaignMutation,
  Core_Update_Ad_CampaignMutationVariables
>;
export const Core_Ad_Admin_Ad_Campaign_FormDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "Core_Ad_Admin_Ad_Campaign_Form" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "uuid" } },
          type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "adcampaign" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "uuid" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "uuid" },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                {
                  kind: "FragmentSpread",
                  name: { kind: "Name", value: "AdCampaignData" },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "AdCampaignData" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "AdCampaign" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "uuid" } },
          { kind: "Field", name: { kind: "Name", value: "name" } },
          { kind: "Field", name: { kind: "Name", value: "status" } },
          { kind: "Field", name: { kind: "Name", value: "startsAt" } },
          { kind: "Field", name: { kind: "Name", value: "endsAt" } },
          { kind: "Field", name: { kind: "Name", value: "advertiserUuid" } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Core_Ad_Admin_Ad_Campaign_FormQuery,
  Core_Ad_Admin_Ad_Campaign_FormQueryVariables
>;
export const Core_Ad_Admin_AdCampaignsDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "Core_Ad_Admin_AdCampaigns" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "advertiserUuid" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "advertiser" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "uuid" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "advertiserUuid" },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                {
                  kind: "FragmentSpread",
                  name: { kind: "Name", value: "AdvertiserData" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "adCampaignCollection" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_ids" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "adCampaigns" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "_id" },
                            },
                            {
                              kind: "FragmentSpread",
                              name: { kind: "Name", value: "AdCampaignData" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "AdvertiserData" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Advertiser" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "uuid" } },
          { kind: "Field", name: { kind: "Name", value: "name" } },
          { kind: "Field", name: { kind: "Name", value: "brandSlugs" } },
          { kind: "Field", name: { kind: "Name", value: "shopSlugs" } },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "AdCampaignData" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "AdCampaign" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "uuid" } },
          { kind: "Field", name: { kind: "Name", value: "name" } },
          { kind: "Field", name: { kind: "Name", value: "status" } },
          { kind: "Field", name: { kind: "Name", value: "startsAt" } },
          { kind: "Field", name: { kind: "Name", value: "endsAt" } },
          { kind: "Field", name: { kind: "Name", value: "advertiserUuid" } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Core_Ad_Admin_AdCampaignsQuery,
  Core_Ad_Admin_AdCampaignsQueryVariables
>;
export const Core_Update_AdDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "Core_Update_Ad" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "input" },
          },
          type: {
            kind: "NamedType",
            name: {
              kind: "Name",
              value: "Input_core_apimessages_UpdateAdRequest",
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "updateAd" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "input" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "ad" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "uuid" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "adGroupUuid" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "adCampaignUuid" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "imageAdCreative" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "FragmentSpread",
                              name: {
                                kind: "Name",
                                value: "ImageAdCreativeData",
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "localizedContents" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "locale" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "imageAdCreative" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "FragmentSpread",
                                    name: {
                                      kind: "Name",
                                      value: "ImageAdCreativeData",
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "ImageAdCreativeData" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "core_apimessages_ImageAdCreative" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "title" } },
          { kind: "Field", name: { kind: "Name", value: "titleColor" } },
          { kind: "Field", name: { kind: "Name", value: "subtitle" } },
          { kind: "Field", name: { kind: "Name", value: "subtitleColor" } },
          { kind: "Field", name: { kind: "Name", value: "ctaText" } },
          { kind: "Field", name: { kind: "Name", value: "ctaHref" } },
          { kind: "Field", name: { kind: "Name", value: "ctaTextColor" } },
          { kind: "Field", name: { kind: "Name", value: "ctaButtonColor" } },
          { kind: "Field", name: { kind: "Name", value: "backgroundImage" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "mobileBackgroundImage" },
          },
          { kind: "Field", name: { kind: "Name", value: "backgroundColor" } },
          { kind: "Field", name: { kind: "Name", value: "backgroundColors" } },
          { kind: "Field", name: { kind: "Name", value: "overlayImage" } },
          { kind: "Field", name: { kind: "Name", value: "description" } },
          { kind: "Field", name: { kind: "Name", value: "partnershipText" } },
          { kind: "Field", name: { kind: "Name", value: "hideAdCallout" } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Core_Update_AdMutation,
  Core_Update_AdMutationVariables
>;
export const Core_Ad_Admin_Ad_FormDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "Core_Ad_Admin_Ad_Form" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "uuid" } },
          type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "ad" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "uuid" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "uuid" },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                {
                  kind: "FragmentSpread",
                  name: { kind: "Name", value: "AdCardData" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "localizedContents" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "locale" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "imageAdCreative" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "FragmentSpread",
                              name: {
                                kind: "Name",
                                value: "ImageAdCreativeData",
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "cspRowQuery" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "FragmentSpread",
                                    name: {
                                      kind: "Name",
                                      value: "AdCspRowQueryData",
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "ImageAdCreativeData" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "core_apimessages_ImageAdCreative" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "title" } },
          { kind: "Field", name: { kind: "Name", value: "titleColor" } },
          { kind: "Field", name: { kind: "Name", value: "subtitle" } },
          { kind: "Field", name: { kind: "Name", value: "subtitleColor" } },
          { kind: "Field", name: { kind: "Name", value: "ctaText" } },
          { kind: "Field", name: { kind: "Name", value: "ctaHref" } },
          { kind: "Field", name: { kind: "Name", value: "ctaTextColor" } },
          { kind: "Field", name: { kind: "Name", value: "ctaButtonColor" } },
          { kind: "Field", name: { kind: "Name", value: "backgroundImage" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "mobileBackgroundImage" },
          },
          { kind: "Field", name: { kind: "Name", value: "backgroundColor" } },
          { kind: "Field", name: { kind: "Name", value: "backgroundColors" } },
          { kind: "Field", name: { kind: "Name", value: "overlayImage" } },
          { kind: "Field", name: { kind: "Name", value: "description" } },
          { kind: "Field", name: { kind: "Name", value: "partnershipText" } },
          { kind: "Field", name: { kind: "Name", value: "hideAdCallout" } },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "AdCardData" },
      typeCondition: { kind: "NamedType", name: { kind: "Name", value: "Ad" } },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "format" } },
          { kind: "Field", name: { kind: "Name", value: "uuid" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "imageAdCreative" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "FragmentSpread",
                  name: { kind: "Name", value: "ImageAdCreativeData" },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "AdCspRowQueryData" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "core_apimessages_CspRowQuery" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "brandSlugs" } },
          { kind: "Field", name: { kind: "Name", value: "categoryUuids" } },
          { kind: "Field", name: { kind: "Name", value: "cspSlugs" } },
          { kind: "Field", name: { kind: "Name", value: "rowTitle" } },
          { kind: "Field", name: { kind: "Name", value: "sort" } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Core_Ad_Admin_Ad_FormQuery,
  Core_Ad_Admin_Ad_FormQueryVariables
>;
export const Core_Copy_AdGroupDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "Core_Copy_AdGroup" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "input" },
          },
          type: {
            kind: "NamedType",
            name: {
              kind: "Name",
              value: "Input_core_apimessages_CopyAdGroupRequest",
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "copyAdGroup" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "input" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "adGroup" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "name" } },
                      { kind: "Field", name: { kind: "Name", value: "uuid" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "status" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "targetedCountryCodes" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "targetedCategoryUuids" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "targetedBrandSlugs" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "targetedAdZones" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "adZone" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "slug" },
                                  },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "startsAt" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "endsAt" },
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "targetedDevices" },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Core_Copy_AdGroupMutation,
  Core_Copy_AdGroupMutationVariables
>;
export const Core_Update_AdGroupDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "Core_Update_AdGroup" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "input" },
          },
          type: {
            kind: "NamedType",
            name: {
              kind: "Name",
              value: "Input_core_apimessages_UpdateAdGroupRequest",
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "updateAdGroup" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "input" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "adGroup" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "name" } },
                      { kind: "Field", name: { kind: "Name", value: "uuid" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "status" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "targetedCountryCodes" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "targetedCategoryUuids" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "targetedBrandSlugs" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "targetedAdZones" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "adZone" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "slug" },
                                  },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "startsAt" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "endsAt" },
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "targetedDevices" },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Core_Update_AdGroupMutation,
  Core_Update_AdGroupMutationVariables
>;
export const Core_Update_AdGroupKeywordsDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "Core_Update_AdGroupKeywords" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "input" },
          },
          type: {
            kind: "NamedType",
            name: {
              kind: "Name",
              value: "Input_core_apimessages_UpdateAdGroupKeywordsRequest",
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "updateAdGroupKeywords" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "input" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "adGroupKeywords" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Core_Update_AdGroupKeywordsMutation,
  Core_Update_AdGroupKeywordsMutationVariables
>;
export const Core_Ad_Admin_AdGroup_FormDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "Core_Ad_Admin_AdGroup_Form" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "uuid" } },
          type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "adgroup" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "uuid" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "uuid" },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                {
                  kind: "FragmentSpread",
                  name: { kind: "Name", value: "AdGroupWithKeywordsData" },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "countries" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "countries" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      { kind: "Field", name: { kind: "Name", value: "name" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "countryCode" },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "adZonesSearch" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "adZones" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      { kind: "Field", name: { kind: "Name", value: "slug" } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "AdGroupWithKeywordsData" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "AdGroup" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "uuid" } },
          { kind: "Field", name: { kind: "Name", value: "name" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "targetedCategoryUuids" },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "targetedBrandSlugs" },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "targetedCountryCodes" },
          },
          { kind: "Field", name: { kind: "Name", value: "targetedDevices" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "targetedAdZones" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "adZone" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "slug" } },
                    ],
                  },
                },
                { kind: "Field", name: { kind: "Name", value: "startsAt" } },
                { kind: "Field", name: { kind: "Name", value: "endsAt" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "adGroupKeywords" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "keyword" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Core_Ad_Admin_AdGroup_FormQuery,
  Core_Ad_Admin_AdGroup_FormQueryVariables
>;
export const Core_Ad_Admin_AdGroupsDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "Core_Ad_Admin_AdGroups" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "adCampaignUuid" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "advertiserUuid" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "adcampaign" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "uuid" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "adCampaignUuid" },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "uuid" } },
                { kind: "Field", name: { kind: "Name", value: "name" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "adGroupCollection" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_ids" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "adGroups" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "_id" },
                            },
                            {
                              kind: "FragmentSpread",
                              name: { kind: "Name", value: "AdGroupData" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "advertiser" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "uuid" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "advertiserUuid" },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                {
                  kind: "FragmentSpread",
                  name: { kind: "Name", value: "AdvertiserData" },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "AdGroupData" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "AdGroup" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "uuid" } },
          { kind: "Field", name: { kind: "Name", value: "name" } },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "AdvertiserData" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Advertiser" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "uuid" } },
          { kind: "Field", name: { kind: "Name", value: "name" } },
          { kind: "Field", name: { kind: "Name", value: "brandSlugs" } },
          { kind: "Field", name: { kind: "Name", value: "shopSlugs" } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Core_Ad_Admin_AdGroupsQuery,
  Core_Ad_Admin_AdGroupsQueryVariables
>;
export const Core_Delete_AdDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "Core_Delete_Ad" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "input" },
          },
          type: {
            kind: "NamedType",
            name: {
              kind: "Name",
              value: "Input_core_apimessages_DeleteAdRequest",
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "DeleteAd" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "input" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "ad" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "uuid" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "deletedAt" },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Core_Delete_AdMutation,
  Core_Delete_AdMutationVariables
>;
export const Core_Update_AdZoneDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "Core_Update_AdZone" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "input" },
          },
          type: {
            kind: "NamedType",
            name: {
              kind: "Name",
              value: "Input_core_apimessages_UpdateAdZoneRequest",
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "updateAdZone" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "input" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "adZone" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "uuid" } },
                      { kind: "Field", name: { kind: "Name", value: "name" } },
                      { kind: "Field", name: { kind: "Name", value: "slug" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "status" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "description" },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Core_Update_AdZoneMutation,
  Core_Update_AdZoneMutationVariables
>;
export const Core_Ad_Admin_Ad_Zone_FormDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "Core_Ad_Admin_Ad_Zone_Form" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "uuid" } },
          type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "adzone" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "uuid" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "uuid" },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                {
                  kind: "FragmentSpread",
                  name: { kind: "Name", value: "AdZoneData" },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "AdZoneData" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "AdZone" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "uuid" } },
          { kind: "Field", name: { kind: "Name", value: "name" } },
          { kind: "Field", name: { kind: "Name", value: "slug" } },
          { kind: "Field", name: { kind: "Name", value: "status" } },
          { kind: "Field", name: { kind: "Name", value: "description" } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Core_Ad_Admin_Ad_Zone_FormQuery,
  Core_Ad_Admin_Ad_Zone_FormQueryVariables
>;
export const Core_Ad_Admin_Ad_ZonesDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "Core_Ad_Admin_Ad_Zones" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "offset" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "Int" } },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "limit" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "Int" } },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "adZonesSearch" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "offset" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "offset" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "limit" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "limit" },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "adZones" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      {
                        kind: "FragmentSpread",
                        name: { kind: "Name", value: "AdZoneData" },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "AdZoneData" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "AdZone" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "uuid" } },
          { kind: "Field", name: { kind: "Name", value: "name" } },
          { kind: "Field", name: { kind: "Name", value: "slug" } },
          { kind: "Field", name: { kind: "Name", value: "status" } },
          { kind: "Field", name: { kind: "Name", value: "description" } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Core_Ad_Admin_Ad_ZonesQuery,
  Core_Ad_Admin_Ad_ZonesQueryVariables
>;
export const Core_Ad_Admin_AdsDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "Core_Ad_Admin_Ads" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "adGroupUuid" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "adCampaignUuid" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "advertiserUuid" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "adgroup" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "uuid" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "adGroupUuid" },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "name" } },
                { kind: "Field", name: { kind: "Name", value: "uuid" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "adCollection" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_ids" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "ads" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "_id" },
                            },
                            {
                              kind: "FragmentSpread",
                              name: { kind: "Name", value: "AdCardData" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "adcampaign" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "uuid" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "adCampaignUuid" },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "uuid" } },
                { kind: "Field", name: { kind: "Name", value: "name" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "advertiser" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "uuid" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "advertiserUuid" },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                {
                  kind: "FragmentSpread",
                  name: { kind: "Name", value: "AdvertiserData" },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "ImageAdCreativeData" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "core_apimessages_ImageAdCreative" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "title" } },
          { kind: "Field", name: { kind: "Name", value: "titleColor" } },
          { kind: "Field", name: { kind: "Name", value: "subtitle" } },
          { kind: "Field", name: { kind: "Name", value: "subtitleColor" } },
          { kind: "Field", name: { kind: "Name", value: "ctaText" } },
          { kind: "Field", name: { kind: "Name", value: "ctaHref" } },
          { kind: "Field", name: { kind: "Name", value: "ctaTextColor" } },
          { kind: "Field", name: { kind: "Name", value: "ctaButtonColor" } },
          { kind: "Field", name: { kind: "Name", value: "backgroundImage" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "mobileBackgroundImage" },
          },
          { kind: "Field", name: { kind: "Name", value: "backgroundColor" } },
          { kind: "Field", name: { kind: "Name", value: "backgroundColors" } },
          { kind: "Field", name: { kind: "Name", value: "overlayImage" } },
          { kind: "Field", name: { kind: "Name", value: "description" } },
          { kind: "Field", name: { kind: "Name", value: "partnershipText" } },
          { kind: "Field", name: { kind: "Name", value: "hideAdCallout" } },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "AdCardData" },
      typeCondition: { kind: "NamedType", name: { kind: "Name", value: "Ad" } },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "format" } },
          { kind: "Field", name: { kind: "Name", value: "uuid" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "imageAdCreative" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "FragmentSpread",
                  name: { kind: "Name", value: "ImageAdCreativeData" },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "AdvertiserData" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Advertiser" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "uuid" } },
          { kind: "Field", name: { kind: "Name", value: "name" } },
          { kind: "Field", name: { kind: "Name", value: "brandSlugs" } },
          { kind: "Field", name: { kind: "Name", value: "shopSlugs" } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Core_Ad_Admin_AdsQuery,
  Core_Ad_Admin_AdsQueryVariables
>;
export const Core_Update_AdvertiserDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "Core_Update_Advertiser" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "input" },
          },
          type: {
            kind: "NamedType",
            name: {
              kind: "Name",
              value: "Input_core_apimessages_UpdateAdvertiserRequest",
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "updateAdvertiser" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "input" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "advertiser" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "name" } },
                      { kind: "Field", name: { kind: "Name", value: "uuid" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "brandSlugs" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "shopSlugs" },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Core_Update_AdvertiserMutation,
  Core_Update_AdvertiserMutationVariables
>;
export const Core_Ad_Admin_Advertiser_FormDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "Core_Ad_Admin_Advertiser_Form" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "uuid" } },
          type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "advertiser" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "uuid" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "uuid" },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                {
                  kind: "FragmentSpread",
                  name: { kind: "Name", value: "AdvertiserData" },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "AdvertiserData" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Advertiser" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "uuid" } },
          { kind: "Field", name: { kind: "Name", value: "name" } },
          { kind: "Field", name: { kind: "Name", value: "brandSlugs" } },
          { kind: "Field", name: { kind: "Name", value: "shopSlugs" } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Core_Ad_Admin_Advertiser_FormQuery,
  Core_Ad_Admin_Advertiser_FormQueryVariables
>;
export const Core_Ad_Admin_AdvertisersDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "Core_Ad_Admin_Advertisers" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "offset" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "Int" } },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "limit" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "Int" } },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "advertisersSearch" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "offset" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "offset" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "limit" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "limit" },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "offset" } },
                { kind: "Field", name: { kind: "Name", value: "limit" } },
                { kind: "Field", name: { kind: "Name", value: "total" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "advertisers" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      {
                        kind: "FragmentSpread",
                        name: { kind: "Name", value: "AdvertiserData" },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "AdvertiserData" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Advertiser" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "uuid" } },
          { kind: "Field", name: { kind: "Name", value: "name" } },
          { kind: "Field", name: { kind: "Name", value: "brandSlugs" } },
          { kind: "Field", name: { kind: "Name", value: "shopSlugs" } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Core_Ad_Admin_AdvertisersQuery,
  Core_Ad_Admin_AdvertisersQueryVariables
>;
export const Core_AdaChat_TokenQueryDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "Core_AdaChat_TokenQuery" },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "me" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "uuid" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "adaChatToken" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "token" } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Core_AdaChat_TokenQueryQuery,
  Core_AdaChat_TokenQueryQueryVariables
>;
export const Core_Address_Form_Fields_Configs_QueryDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "Core_Address_Form_Fields_Configs_Query" },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "intlAddressFormConfig" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "configs" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "countryCode" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "fields" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "fieldName" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "displayNames" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "locale" },
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "displayName",
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "required" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Core_Address_Form_Fields_Configs_QueryQuery,
  Core_Address_Form_Fields_Configs_QueryQueryVariables
>;
export const Core_Address_VerifyDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "Core_Address_Verify" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "input" },
          },
          type: {
            kind: "NamedType",
            name: {
              kind: "Name",
              value: "Input_core_apimessages_AddressVerifyRequest",
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "addressVerify" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "input" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "suggestion" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "streetAddress" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "extendedAddress" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "locality" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "region" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "postalCode" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "countryCode" },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "address" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "streetAddress" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "extendedAddress" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "locality" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "region" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "postalCode" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "countryCode" },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Core_Address_VerifyQuery,
  Core_Address_VerifyQueryVariables
>;
export const Core_Accept_AddressSuggestionDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "Core_Accept_AddressSuggestion" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "input" },
          },
          type: {
            kind: "NamedType",
            name: {
              kind: "Name",
              value: "Input_core_apimessages_AcceptAddressSuggestionRequest",
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "acceptAddressSuggestion" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "input" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Core_Accept_AddressSuggestionMutation,
  Core_Accept_AddressSuggestionMutationVariables
>;
export const AddressBookDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "AddressBook" },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "countries" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "countries" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      { kind: "Field", name: { kind: "Name", value: "name" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "countryCode" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "subregions" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "_id" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "name" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "code" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "me" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "uuid" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "shippingAddresses" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      { kind: "Field", name: { kind: "Name", value: "uuid" } },
                      { kind: "Field", name: { kind: "Name", value: "name" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "streetAddress" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "extendedAddress" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "locality" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "region" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "regionName" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "postalCode" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "countryCode" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "country" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "_id" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "countryCode" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "name" },
                            },
                          ],
                        },
                      },
                      { kind: "Field", name: { kind: "Name", value: "phone" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "primary" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "legacyId" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "isComplete" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "verified" },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<AddressBookQuery, AddressBookQueryVariables>;
export const UpdateMyAddressDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "UpdateMyAddress" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "input" },
          },
          type: {
            kind: "NamedType",
            name: {
              kind: "Name",
              value: "Input_core_apimessages_UpdateAddressRequest",
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "updateMyAddress" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "input" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "address" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      { kind: "Field", name: { kind: "Name", value: "uuid" } },
                      { kind: "Field", name: { kind: "Name", value: "name" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "streetAddress" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "extendedAddress" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "locality" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "region" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "regionName" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "postalCode" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "countryCode" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "country" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "_id" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "countryCode" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "name" },
                            },
                          ],
                        },
                      },
                      { kind: "Field", name: { kind: "Name", value: "phone" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "primary" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "legacyId" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "isComplete" },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateMyAddressMutation,
  UpdateMyAddressMutationVariables
>;
export const DeleteMyAddressDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "DeleteMyAddress" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "input" },
          },
          type: {
            kind: "NamedType",
            name: {
              kind: "Name",
              value: "Input_core_apimessages_AddressDeleteRequest",
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "deleteMyAddress" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "input" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "uuid" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  DeleteMyAddressMutation,
  DeleteMyAddressMutationVariables
>;
export const CountriesDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "Countries" },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "countries" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "countries" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      { kind: "Field", name: { kind: "Name", value: "name" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "countryCode" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "subregions" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "_id" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "name" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "code" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CountriesQuery, CountriesQueryVariables>;
export const Core_AdminClaimOrderBundle_UserSearchDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "Core_AdminClaimOrderBundle_UserSearch" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "search" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "userSearch" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "search" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "search" },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "users" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      { kind: "Field", name: { kind: "Name", value: "email" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "fullName" },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Core_AdminClaimOrderBundle_UserSearchQuery,
  Core_AdminClaimOrderBundle_UserSearchQueryVariables
>;
export const Core_UpdateAdminNoteDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "Core_UpdateAdminNote" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "input" },
          },
          type: {
            kind: "NamedType",
            name: {
              kind: "Name",
              value: "Input_core_apimessages_AdminNoteUpdateRequest",
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "updateAdminNote" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "input" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "linkHtml" } },
                { kind: "Field", name: { kind: "Name", value: "createdAt" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "createdByName" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "lastEditedByName" },
                },
                { kind: "Field", name: { kind: "Name", value: "updatedAt" } },
                { kind: "Field", name: { kind: "Name", value: "userCanEdit" } },
                { kind: "Field", name: { kind: "Name", value: "body" } },
                { kind: "Field", name: { kind: "Name", value: "isPinned" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "versions" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "editedAt" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "editedByName" },
                      },
                      { kind: "Field", name: { kind: "Name", value: "body" } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Core_UpdateAdminNoteMutation,
  Core_UpdateAdminNoteMutationVariables
>;
export const Core_CreateAdminNoteDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "Core_CreateAdminNote" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "input" },
          },
          type: {
            kind: "NamedType",
            name: {
              kind: "Name",
              value: "Input_core_apimessages_AdminNoteCreateRequest",
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "createAdminNote" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "input" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "linkHtml" } },
                { kind: "Field", name: { kind: "Name", value: "createdAt" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "createdByName" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "lastEditedByName" },
                },
                { kind: "Field", name: { kind: "Name", value: "updatedAt" } },
                { kind: "Field", name: { kind: "Name", value: "userCanEdit" } },
                { kind: "Field", name: { kind: "Name", value: "body" } },
                { kind: "Field", name: { kind: "Name", value: "isPinned" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "versions" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "editedAt" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "editedByName" },
                      },
                      { kind: "Field", name: { kind: "Name", value: "body" } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Core_CreateAdminNoteMutation,
  Core_CreateAdminNoteMutationVariables
>;
export const adminNotesSearchDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "adminNotesSearch" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "input" },
          },
          type: {
            kind: "NamedType",
            name: {
              kind: "Name",
              value: "Input_core_apimessages_AdminNotesSearchRequest",
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "adminNotesSearch" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "input" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "adminNotes" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "linkHtml" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "createdAt" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "createdByName" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "lastEditedByName" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "updatedAt" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "userCanEdit" },
                      },
                      { kind: "Field", name: { kind: "Name", value: "body" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "isPinned" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "versions" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "editedAt" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "editedByName" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "body" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                { kind: "Field", name: { kind: "Name", value: "limit" } },
                { kind: "Field", name: { kind: "Name", value: "offset" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "totalResults" },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  adminNotesSearchQuery,
  adminNotesSearchQueryVariables
>;
export const shopAdyenBalancePlatformNotificationsDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "shopAdyenBalancePlatformNotifications" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "input" },
          },
          type: {
            kind: "NamedType",
            name: {
              kind: "Name",
              value:
                "Input_core_apimessages_ShopAdyenBalancePlatformNotificationsIndexRequest",
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: {
              kind: "Name",
              value: "shopAdyenBalancePlatformNotifications",
            },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "input" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "notifications" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "createdAt" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "rawBody" },
                      },
                    ],
                  },
                },
                { kind: "Field", name: { kind: "Name", value: "limit" } },
                { kind: "Field", name: { kind: "Name", value: "offset" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "totalResults" },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  shopAdyenBalancePlatformNotificationsQuery,
  shopAdyenBalancePlatformNotificationsQueryVariables
>;
export const Core_SearchAdminTax1099kReportingThresholdsDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: {
        kind: "Name",
        value: "Core_SearchAdminTax1099kReportingThresholds",
      },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "input" },
          },
          type: {
            kind: "NamedType",
            name: {
              kind: "Name",
              value:
                "Input_core_apimessages_AdminTax1099kReportingThresholdSearchRequest",
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "taxReportingThresholdSearch" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "input" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "totalResults" },
                },
                { kind: "Field", name: { kind: "Name", value: "offset" } },
                { kind: "Field", name: { kind: "Name", value: "limit" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "taxReportingThresholds" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      { kind: "Field", name: { kind: "Name", value: "year" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "region" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "salesAmountCents" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "transactionCount" },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Core_SearchAdminTax1099kReportingThresholdsQuery,
  Core_SearchAdminTax1099kReportingThresholdsQueryVariables
>;
export const Core_DeleteAdminTax1099kReportingThresholdDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: {
        kind: "Name",
        value: "Core_DeleteAdminTax1099kReportingThreshold",
      },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "input" },
          },
          type: {
            kind: "NamedType",
            name: {
              kind: "Name",
              value:
                "Input_core_apimessages_AdminTax1099kReportingThresholdDeleteRequest",
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: {
              kind: "Name",
              value: "deleteAdminTax1099kReportingThreshold",
            },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "input" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Core_DeleteAdminTax1099kReportingThresholdMutation,
  Core_DeleteAdminTax1099kReportingThresholdMutationVariables
>;
export const Core_UpdateAdminTax1099kReportingThresholdDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: {
        kind: "Name",
        value: "Core_UpdateAdminTax1099kReportingThreshold",
      },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "input" },
          },
          type: {
            kind: "NamedType",
            name: {
              kind: "Name",
              value:
                "Input_core_apimessages_AdminTax1099kReportingThresholdUpdateRequest",
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: {
              kind: "Name",
              value: "updateAdminTax1099kReportingThreshold",
            },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "input" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "year" } },
                { kind: "Field", name: { kind: "Name", value: "region" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "salesAmountCents" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "transactionCount" },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Core_UpdateAdminTax1099kReportingThresholdMutation,
  Core_UpdateAdminTax1099kReportingThresholdMutationVariables
>;
export const Core_CreateAdminTax1099kReportingThresholdDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: {
        kind: "Name",
        value: "Core_CreateAdminTax1099kReportingThreshold",
      },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "input" },
          },
          type: {
            kind: "NamedType",
            name: {
              kind: "Name",
              value:
                "Input_core_apimessages_AdminTax1099kReportingThresholdCreateRequest",
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: {
              kind: "Name",
              value: "createAdminTax1099kReportingThreshold",
            },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "input" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "year" } },
                { kind: "Field", name: { kind: "Name", value: "region" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "salesAmountCents" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "transactionCount" },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Core_CreateAdminTax1099kReportingThresholdMutation,
  Core_CreateAdminTax1099kReportingThresholdMutationVariables
>;
export const SEARCH_USERSDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "SEARCH_USERS" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "input" },
          },
          type: {
            kind: "NamedType",
            name: {
              kind: "Name",
              value: "Input_core_apimessages_AdminUserSearchRequest",
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "userSearch" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "input" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "users" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      { kind: "Field", name: { kind: "Name", value: "email" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "shopId" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "shopName" },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<SEARCH_USERSQuery, SEARCH_USERSQueryVariables>;
export const SearchUserDatadogErrorsDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "SearchUserDatadogErrors" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "input" },
          },
          type: {
            kind: "NamedType",
            name: {
              kind: "Name",
              value:
                "Input_core_apimessages_AdminUserDatadogErrorsSearchRequest",
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "datadogErrorsSearch" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "input" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "datadogErrors" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "key" } },
                      { kind: "Field", name: { kind: "Name", value: "type" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "content" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "context" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "timestamp" },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  SearchUserDatadogErrorsQuery,
  SearchUserDatadogErrorsQueryVariables
>;
export const Core_Category_BestSellersSearchDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "Core_Category_BestSellersSearch" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "categoryUuids" },
          },
          type: {
            kind: "ListType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "bestSellerThreshold" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "Int" } },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "cspSearch" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "sort" },
                      value: {
                        kind: "EnumValue",
                        value: "RECENT_ORDERS_COUNT_DESC",
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "limit" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "bestSellerThreshold" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "categoryUuids" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "categoryUuids" },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "resultIds" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Core_Category_BestSellersSearchQuery,
  Core_Category_BestSellersSearchQueryVariables
>;
export const GetBillingMethodDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "GetBillingMethod" },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "countries" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "countries" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      { kind: "Field", name: { kind: "Name", value: "name" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "countryCode" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "subregions" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "_id" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "name" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "code" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "me" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "uuid" } },
                {
                  kind: "FragmentSpread",
                  name: { kind: "Name", value: "myShopBillingMethods" },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "myShopBillingMethods" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "rql_Me" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "uuid" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "shop" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "currency" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "originCountryCode" },
                },
                { kind: "Field", name: { kind: "Name", value: "shopType" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "billingMethodStatus" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "paypalSetupStatus" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "paypalProfile" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "email" } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "creditCards" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "last4" } },
                { kind: "Field", name: { kind: "Name", value: "cardType" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "expirationYear" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "expirationMonth" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "cardholderName" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "primaryBilling" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "address" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "postalCode" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "countryCode" },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetBillingMethodQuery,
  GetBillingMethodQueryVariables
>;
export const UpdateMyShopBillingMethodDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "UpdateMyShopBillingMethod" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "input" },
          },
          type: {
            kind: "NamedType",
            name: {
              kind: "Name",
              value: "Input_core_apimessages_UpdateBillingMethodRequest",
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "updateMyShopBillingMethod" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "input" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "errors" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "message" },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "creditCard" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "adyenPaymentResult" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "action" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "paymentStatus" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "fingerprintToken" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "challengeToken" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "paymentData" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "threeDsOneData" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "paReq" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "md" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "termUrl" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "redirectUrl" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "billingMethodUuid" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "me" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      {
                        kind: "FragmentSpread",
                        name: { kind: "Name", value: "myShopBillingMethods" },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "myShopBillingMethods" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "rql_Me" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "uuid" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "shop" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "currency" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "originCountryCode" },
                },
                { kind: "Field", name: { kind: "Name", value: "shopType" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "billingMethodStatus" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "paypalSetupStatus" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "paypalProfile" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "email" } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "creditCards" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "last4" } },
                { kind: "Field", name: { kind: "Name", value: "cardType" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "expirationYear" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "expirationMonth" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "cardholderName" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "primaryBilling" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "address" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "postalCode" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "countryCode" },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateMyShopBillingMethodMutation,
  UpdateMyShopBillingMethodMutationVariables
>;
export const BillingMethodVerifyShopperDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "BillingMethodVerifyShopper" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "input" },
          },
          type: {
            kind: "NamedType",
            name: {
              kind: "Name",
              value: "Input_core_apimessages_BillingMethodVerifyShopperRequest",
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "billingMethodVerifyShopper" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "input" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "creditCard" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "billingMethodUuid" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "adyenPaymentResult" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "action" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "paymentStatus" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "challengeToken" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "paymentData" },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "me" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      {
                        kind: "FragmentSpread",
                        name: { kind: "Name", value: "myShopBillingMethods" },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "myShopBillingMethods" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "rql_Me" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "uuid" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "shop" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "currency" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "originCountryCode" },
                },
                { kind: "Field", name: { kind: "Name", value: "shopType" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "billingMethodStatus" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "paypalSetupStatus" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "paypalProfile" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "email" } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "creditCards" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "last4" } },
                { kind: "Field", name: { kind: "Name", value: "cardType" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "expirationYear" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "expirationMonth" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "cardholderName" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "primaryBilling" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "address" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "postalCode" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "countryCode" },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  BillingMethodVerifyShopperMutation,
  BillingMethodVerifyShopperMutationVariables
>;
export const Core_BrandCompletionsDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "Core_BrandCompletions" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "query" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
        },
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "skip" } },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "Boolean" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "completions" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "query" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "query" },
                      },
                    },
                  ],
                },
              },
            ],
            directives: [
              {
                kind: "Directive",
                name: { kind: "Name", value: "skip" },
                arguments: [
                  {
                    kind: "Argument",
                    name: { kind: "Name", value: "if" },
                    value: {
                      kind: "Variable",
                      name: { kind: "Name", value: "skip" },
                    },
                  },
                ],
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "completions" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "text" } },
                      { kind: "Field", name: { kind: "Name", value: "type" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "options" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "slug" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "output" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Core_BrandCompletionsQuery,
  Core_BrandCompletionsQueryVariables
>;
export const Core_Layout_BrowsePageDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "Core_Layout_BrowsePage" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "id" } },
          type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "browsepage" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "id" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "id" },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "title" } },
                { kind: "Field", name: { kind: "Name", value: "url" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "combinedFilters" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "breadcrumbs" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "title" } },
                      { kind: "Field", name: { kind: "Name", value: "url" } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Core_Layout_BrowsePageQuery,
  Core_Layout_BrowsePageQueryVariables
>;
export const Core_Layout_BrowsePageLinksDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "Core_Layout_BrowsePageLinks" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "id" } },
          type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "browsepage" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "id" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "id" },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "id" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "links" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "url" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "matchParams" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "paramName" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "value" },
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "matchType" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "combinedFilters" },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Core_Layout_BrowsePageLinksQuery,
  Core_Layout_BrowsePageLinksQueryVariables
>;
export const Core_Listing_BrowsePageGridDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "Core_Listing_BrowsePageGrid" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "acceptsAffirm" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "Boolean" } },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "acceptsOffers" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "Boolean" } },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "acceptsPaymentPlans" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "Boolean" } },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "applyProximityBoost" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "Boolean" } },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "boostedItemRegionCode" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "brandSlugs" },
          },
          type: {
            kind: "ListType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "categorySlugs" },
          },
          type: {
            kind: "ListType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "combinedShipping" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "Boolean" } },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "conditionSlugs" },
          },
          type: {
            kind: "ListType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "contexts" },
          },
          type: {
            kind: "ListType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "reverb_search_Context" },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "cspSlug" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "countryOfOrigin" },
          },
          type: {
            kind: "ListType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "curatedSetSlugs" },
          },
          type: {
            kind: "ListType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "currency" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "dealsAndSteals" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "Boolean" } },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "decades" },
          },
          type: {
            kind: "ListType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "fallbackToOr" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "Boolean" } },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "freeShipping" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "Boolean" } },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "freeExpeditedShipping" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "Boolean" } },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "handmade" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "Boolean" } },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "holidaySale" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "Boolean" } },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "itemCity" },
          },
          type: {
            kind: "ListType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "itemRegion" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "itemState" },
          },
          type: {
            kind: "ListType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "limit" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "Int" } },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "localPickup" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "Boolean" } },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "minSaleDiscountPercent" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "multiClientExperiments" },
          },
          type: {
            kind: "ListType",
            type: {
              kind: "NamedType",
              name: {
                kind: "Name",
                value: "Input_reverb_search_ExperimentData",
              },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "preferredSeller" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "Boolean" } },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "priceMax" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "priceMin" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "offset" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "Int" } },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "onSale" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "Boolean" } },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "query" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "saleSlugs" },
          },
          type: {
            kind: "ListType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "shippingRegionCode" },
          },
          type: {
            kind: "ListType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "showOnlySold" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "Boolean" } },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "shopSlug" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "showSold" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "Boolean" } },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "similarListingIds" },
          },
          type: {
            kind: "ListType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "sortSlug" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "terminateEarly" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "Boolean" } },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "traitValues" },
          },
          type: {
            kind: "ListType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "useExperimentalRecall" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "Boolean" } },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "withAggregations" },
          },
          type: {
            kind: "ListType",
            type: {
              kind: "NamedType",
              name: {
                kind: "Name",
                value: "reverb_search_ListingsSearchRequest_Aggregation",
              },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "withProximityFilter" },
          },
          type: {
            kind: "NamedType",
            name: {
              kind: "Name",
              value: "Input_reverb_search_ProximityFilterRequest",
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "yearMax" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "yearMin" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "listingsSearch" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "acceptsAffirm" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "acceptsAffirm" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "acceptsOffers" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "acceptsOffers" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "acceptsPaymentPlans" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "acceptsPaymentPlans" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "applyProximityBoost" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "applyProximityBoost" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "boostedItemRegionCode" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "boostedItemRegionCode" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "brandSlugs" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "brandSlugs" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "categorySlugs" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "categorySlugs" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "combinedShipping" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "combinedShipping" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "conditionSlugs" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "conditionSlugs" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "contexts" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "contexts" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "cspSlug" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "cspSlug" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "countryOfOrigin" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "countryOfOrigin" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "curatedSetSlugs" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "curatedSetSlugs" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "currency" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "currency" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "dealsAndSteals" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "dealsAndSteals" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "decades" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "decades" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "fallbackToOr" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "fallbackToOr" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "freeShipping" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "freeShipping" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "freeExpeditedShipping" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "freeExpeditedShipping" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "handmade" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "handmade" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "holidaySale" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "holidaySale" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "itemCity" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "itemCity" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "itemRegion" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "itemRegion" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "itemState" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "itemState" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "limit" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "limit" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "localPickup" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "localPickup" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "minSaleDiscountPercent" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "minSaleDiscountPercent" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "multiClientExperiments" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "multiClientExperiments" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "preferredSeller" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "preferredSeller" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "priceMin" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "priceMin" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "priceMax" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "priceMax" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "offset" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "offset" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "onSale" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "onSale" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "query" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "query" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "saleSlugs" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "saleSlugs" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "shippingRegionCodes" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "shippingRegionCode" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "showOnlySold" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "showOnlySold" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "shopSlug" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "shopSlug" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "showSold" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "showSold" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "similarListingIds" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "similarListingIds" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "sortSlug" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "sortSlug" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "terminateEarly" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "terminateEarly" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "traitValues" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "traitValues" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "useExperimentalRecall" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "useExperimentalRecall" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "withAggregations" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "withAggregations" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "withProximityFilter" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "withProximityFilter" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "yearMax" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "yearMax" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "yearMin" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "yearMin" },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "listings" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      {
                        kind: "FragmentSpread",
                        name: { kind: "Name", value: "BrowseListingsFields" },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "filters" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "FragmentSpread",
                        name: { kind: "Name", value: "NestedFilter" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "options" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "selected" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "autoselected" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                { kind: "Field", name: { kind: "Name", value: "total" } },
                { kind: "Field", name: { kind: "Name", value: "offset" } },
                { kind: "Field", name: { kind: "Name", value: "limit" } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "ListingForBuyerShippingFields" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Listing" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "shipping" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "freeExpeditedShipping" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "localPickupOnly" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "shippingPrices" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "shippingMethod" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "carrierCalculated" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "regional" },
                      },
                      {
                        kind: "Field",
                        name: {
                          kind: "Name",
                          value: "destinationPostalCodeNeeded",
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "postalCode" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "rate" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "amount" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "amountCents" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "currency" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "display" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "ListingGreatValueData" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Listing" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "pricing" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "buyerPrice" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "currency" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "amountCents" },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "condition" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "conditionSlug" },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "priceRecommendation" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "priceMiddle" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "amountCents" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "currency" },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "shop" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "address" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "country" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "_id" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "countryCode" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          { kind: "Field", name: { kind: "Name", value: "currency" } },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "ListingCardCPOData" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Listing" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "certifiedPreOwned" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "title" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "badgeIconUrl" },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "ListingForBuyerFields" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Listing" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "title" } },
          { kind: "Field", name: { kind: "Name", value: "slug" } },
          { kind: "Field", name: { kind: "Name", value: "listingType" } },
          { kind: "Field", name: { kind: "Name", value: "make" } },
          { kind: "Field", name: { kind: "Name", value: "model" } },
          { kind: "Field", name: { kind: "Name", value: "upc" } },
          { kind: "Field", name: { kind: "Name", value: "state" } },
          { kind: "Field", name: { kind: "Name", value: "stateDescription" } },
          { kind: "Field", name: { kind: "Name", value: "bumped" } },
          { kind: "Field", name: { kind: "Name", value: "watching" } },
          { kind: "Field", name: { kind: "Name", value: "soldAsIs" } },
          { kind: "Field", name: { kind: "Name", value: "usOutlet" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "publishedAt" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "seconds" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "condition" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "displayName" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "conditionSlug" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "conditionUuid" },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "pricing" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "buyerPrice" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "display" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "currency" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "amount" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "amountCents" },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "originalPrice" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "display" },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "ribbon" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "display" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "reason" },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "images" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "transform" },
                      value: {
                        kind: "StringValue",
                        value: "card_square",
                        block: false,
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "count" },
                      value: { kind: "IntValue", value: "3" },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "scope" },
                      value: {
                        kind: "StringValue",
                        value: "photos",
                        block: false,
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "type" },
                      value: {
                        kind: "StringValue",
                        value: "Product",
                        block: false,
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "source" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "shipping" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "shippingPrices" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "shippingMethod" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "carrierCalculated" },
                      },
                      {
                        kind: "Field",
                        name: {
                          kind: "Name",
                          value: "destinationPostalCodeNeeded",
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "rate" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "amount" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "amountCents" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "currency" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "display" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "freeExpeditedShipping" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "localPickupOnly" },
                },
                { kind: "Field", name: { kind: "Name", value: "localPickup" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "shop" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "name" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "returnPolicy" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "usedReturnWindowDays" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "newReturnWindowDays" },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "address" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "locality" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "region" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "country" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "_id" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "countryCode" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "name" },
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "displayLocation" },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "ListingForBuyerShippingFields" },
          },
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "ListingGreatValueData" },
          },
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "ListingCardCPOData" },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "WatchBadgeData" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Listing" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "title" } },
          { kind: "Field", name: { kind: "Name", value: "sellerId" } },
          { kind: "Field", name: { kind: "Name", value: "watching" } },
          {
            kind: "Field",
            alias: { kind: "Name", value: "watchThumbnails" },
            name: { kind: "Name", value: "images" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "type" },
                      value: {
                        kind: "StringValue",
                        value: "Product",
                        block: false,
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "scope" },
                      value: {
                        kind: "StringValue",
                        value: "photos",
                        block: false,
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "transform" },
                      value: {
                        kind: "StringValue",
                        value: "card_square",
                        block: false,
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "count" },
                      value: { kind: "IntValue", value: "1" },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "source" } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "mParticleListingFields" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Listing" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "title" } },
          { kind: "Field", name: { kind: "Name", value: "brandSlug" } },
          { kind: "Field", name: { kind: "Name", value: "categoryRootUuid" } },
          { kind: "Field", name: { kind: "Name", value: "make" } },
          { kind: "Field", name: { kind: "Name", value: "categoryUuids" } },
          { kind: "Field", name: { kind: "Name", value: "state" } },
          { kind: "Field", name: { kind: "Name", value: "listingType" } },
          { kind: "Field", name: { kind: "Name", value: "bumpEligible" } },
          { kind: "Field", name: { kind: "Name", value: "shopId" } },
          { kind: "Field", name: { kind: "Name", value: "inventory" } },
          { kind: "Field", name: { kind: "Name", value: "soldAsIs" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "acceptedPaymentMethods" },
          },
          { kind: "Field", name: { kind: "Name", value: "currency" } },
          { kind: "Field", name: { kind: "Name", value: "usOutlet" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "condition" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "conditionUuid" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "conditionSlug" },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "categories" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "slug" } },
                { kind: "Field", name: { kind: "Name", value: "rootSlug" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "csp" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "slug" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "brand" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      { kind: "Field", name: { kind: "Name", value: "slug" } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "pricing" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "buyerPrice" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "amount" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "currency" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "amountCents" },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "publishedAt" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "seconds" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "sale" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "code" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "buyerIneligibilityReason" },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "shipping" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "shippingPrices" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "shippingMethod" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "carrierCalculated" },
                      },
                      {
                        kind: "Field",
                        name: {
                          kind: "Name",
                          value: "destinationPostalCodeNeeded",
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "rate" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "amount" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "amountCents" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "currency" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "display" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "freeExpeditedShipping" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "localPickupOnly" },
                },
                { kind: "Field", name: { kind: "Name", value: "localPickup" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "certifiedPreOwned" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "title" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "badgeIconUrl" },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "shop" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "address" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "countryCode" },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "returnPolicy" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "newReturnWindowDays" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "usedReturnWindowDays" },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "ListingCreateOfferButtonData" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Listing" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "state" } },
          { kind: "Field", name: { kind: "Name", value: "offersEnabled" } },
          { kind: "Field", name: { kind: "Name", value: "listingType" } },
          { kind: "Field", name: { kind: "Name", value: "sellerId" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "isBuyerOfferEligible" },
          },
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "mParticleListingFields" },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "ListingCardFields" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Listing" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "ListingForBuyerFields" },
          },
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "WatchBadgeData" },
          },
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "ListingCreateOfferButtonData" },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "AddToCartButtonFields" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Listing" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "sellerId" } },
          { kind: "Field", name: { kind: "Name", value: "listingType" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "pricing" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "buyerPrice" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "amount" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "amountCents" },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "preorderInfo" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "onPreorder" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "estimatedShipDate" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "seconds" },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "BrowseListingsFields" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Listing" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "csp" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "averageReviewRating" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "reviewsCount" },
                },
              ],
            },
          },
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "ListingCardFields" },
          },
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "AddToCartButtonFields" },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "NestedFilter" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "reverb_search_Filter" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "name" } },
          { kind: "Field", name: { kind: "Name", value: "key" } },
          { kind: "Field", name: { kind: "Name", value: "aggregationName" } },
          { kind: "Field", name: { kind: "Name", value: "widgetType" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "options" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "count" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "value" } },
                    ],
                  },
                },
                { kind: "Field", name: { kind: "Name", value: "name" } },
                { kind: "Field", name: { kind: "Name", value: "selected" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "autoselected" },
                },
                { kind: "Field", name: { kind: "Name", value: "paramName" } },
                { kind: "Field", name: { kind: "Name", value: "setValues" } },
                { kind: "Field", name: { kind: "Name", value: "unsetValues" } },
                { kind: "Field", name: { kind: "Name", value: "all" } },
                { kind: "Field", name: { kind: "Name", value: "optionValue" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "trackingValue" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "subFilter" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "widgetType" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "options" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "count" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "value" },
                                  },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "name" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "selected" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "autoselected" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "paramName" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "setValues" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "unsetValues" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "all" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "optionValue" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "trackingValue" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "subFilter" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "widgetType" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "options" },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "count",
                                          },
                                          selectionSet: {
                                            kind: "SelectionSet",
                                            selections: [
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "value",
                                                },
                                              },
                                            ],
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "name" },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "selected",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "autoselected",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "paramName",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "setValues",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "unsetValues",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "all" },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "optionValue",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "trackingValue",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "subFilter",
                                          },
                                          selectionSet: {
                                            kind: "SelectionSet",
                                            selections: [
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "widgetType",
                                                },
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "options",
                                                },
                                                selectionSet: {
                                                  kind: "SelectionSet",
                                                  selections: [
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "count",
                                                      },
                                                      selectionSet: {
                                                        kind: "SelectionSet",
                                                        selections: [
                                                          {
                                                            kind: "Field",
                                                            name: {
                                                              kind: "Name",
                                                              value: "value",
                                                            },
                                                          },
                                                        ],
                                                      },
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "name",
                                                      },
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "selected",
                                                      },
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "autoselected",
                                                      },
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "paramName",
                                                      },
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "setValues",
                                                      },
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "unsetValues",
                                                      },
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "all",
                                                      },
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "optionValue",
                                                      },
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "trackingValue",
                                                      },
                                                    },
                                                  ],
                                                },
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Core_Listing_BrowsePageGridQuery,
  Core_Listing_BrowsePageGridQueryVariables
>;
export const Core_Bump_Recommendation_ListingDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "Core_Bump_Recommendation_Listing" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "id" } },
          type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "listing" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "id" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "id" },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                {
                  kind: "FragmentSpread",
                  name: {
                    kind: "Name",
                    value: "CoreBumpRecommendationListingCard",
                  },
                },
                {
                  kind: "FragmentSpread",
                  name: { kind: "Name", value: "BumpRecommendationsFragment" },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "mParticleListingFields" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Listing" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "title" } },
          { kind: "Field", name: { kind: "Name", value: "brandSlug" } },
          { kind: "Field", name: { kind: "Name", value: "categoryRootUuid" } },
          { kind: "Field", name: { kind: "Name", value: "make" } },
          { kind: "Field", name: { kind: "Name", value: "categoryUuids" } },
          { kind: "Field", name: { kind: "Name", value: "state" } },
          { kind: "Field", name: { kind: "Name", value: "listingType" } },
          { kind: "Field", name: { kind: "Name", value: "bumpEligible" } },
          { kind: "Field", name: { kind: "Name", value: "shopId" } },
          { kind: "Field", name: { kind: "Name", value: "inventory" } },
          { kind: "Field", name: { kind: "Name", value: "soldAsIs" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "acceptedPaymentMethods" },
          },
          { kind: "Field", name: { kind: "Name", value: "currency" } },
          { kind: "Field", name: { kind: "Name", value: "usOutlet" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "condition" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "conditionUuid" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "conditionSlug" },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "categories" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "slug" } },
                { kind: "Field", name: { kind: "Name", value: "rootSlug" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "csp" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "slug" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "brand" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      { kind: "Field", name: { kind: "Name", value: "slug" } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "pricing" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "buyerPrice" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "amount" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "currency" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "amountCents" },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "publishedAt" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "seconds" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "sale" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "code" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "buyerIneligibilityReason" },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "shipping" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "shippingPrices" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "shippingMethod" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "carrierCalculated" },
                      },
                      {
                        kind: "Field",
                        name: {
                          kind: "Name",
                          value: "destinationPostalCodeNeeded",
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "rate" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "amount" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "amountCents" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "currency" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "display" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "freeExpeditedShipping" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "localPickupOnly" },
                },
                { kind: "Field", name: { kind: "Name", value: "localPickup" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "certifiedPreOwned" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "title" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "badgeIconUrl" },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "shop" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "address" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "countryCode" },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "returnPolicy" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "newReturnWindowDays" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "usedReturnWindowDays" },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "BumpRecommendationsAttributes" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Listing" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "condition" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "conditionUuid" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "conditionSlug" },
                },
                { kind: "Field", name: { kind: "Name", value: "displayName" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "csp" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "title" } },
                { kind: "Field", name: { kind: "Name", value: "slug" } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "CoreBumpRecommendationListingCard" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Listing" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "images" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "type" },
                      value: {
                        kind: "StringValue",
                        value: "square",
                        block: false,
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "transform" },
                      value: {
                        kind: "StringValue",
                        value: "card_square",
                        block: false,
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "count" },
                      value: { kind: "IntValue", value: "1" },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "source" } },
              ],
            },
          },
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "hasInventory" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "sellerPrice" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "amount" } },
                { kind: "Field", name: { kind: "Name", value: "amountCents" } },
                { kind: "Field", name: { kind: "Name", value: "currency" } },
                { kind: "Field", name: { kind: "Name", value: "display" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "shipping" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "shippingPrices" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "shippingRegionCode" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "originalRate" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "amount" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "amountCents" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "currency" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "display" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "mParticleListingFields" },
          },
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "BumpRecommendationsAttributes" },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "BumpRecommendationsFragment" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Listing" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "bumpRecommendations" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "bumpRecommendationAmount" },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Core_Bump_Recommendation_ListingQuery,
  Core_Bump_Recommendation_ListingQueryVariables
>;
export const Core_Checkout_StartCartGooglePayDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "Core_Checkout_StartCartGooglePay" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "checkoutBundlingId" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "startCheckout" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "checkoutBundlingId" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "checkoutBundlingId" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "paymentMethodType" },
                      value: { kind: "EnumValue", value: "ANDROID_PAY" },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "checkout" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      {
                        kind: "FragmentSpread",
                        name: { kind: "Name", value: "GooglePayManagerFields" },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "GooglePayManagerFields" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Checkout" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "status" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "paymentErrorMessage" },
          },
          { kind: "Field", name: { kind: "Name", value: "type" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "total" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "label" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "amount" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "amount" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "amountCents" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "currency" },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "guest" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "email" } },
                { kind: "Field", name: { kind: "Name", value: "firstName" } },
                { kind: "Field", name: { kind: "Name", value: "lastName" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "shippingAddress" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "name" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "streetAddress" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "extendedAddress" },
                },
                { kind: "Field", name: { kind: "Name", value: "locality" } },
                { kind: "Field", name: { kind: "Name", value: "region" } },
                { kind: "Field", name: { kind: "Name", value: "postalCode" } },
                { kind: "Field", name: { kind: "Name", value: "countryCode" } },
                { kind: "Field", name: { kind: "Name", value: "phone" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "lineItems" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "type" } },
                { kind: "Field", name: { kind: "Name", value: "label" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "amount" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "amount" },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Core_Checkout_StartCartGooglePayMutation,
  Core_Checkout_StartCartGooglePayMutationVariables
>;
export const Core_CartItemUpdateProtectionPlanDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "Core_CartItemUpdateProtectionPlan" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "input" },
          },
          type: {
            kind: "NamedType",
            name: {
              kind: "Name",
              value:
                "Input_core_apimessages_UpdateCartItemProtectionPlanRequest",
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "crupdateCartItemProtectionPlan" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "input" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "cartItemUuid" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "protectionPlan" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "planId" },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Core_CartItemUpdateProtectionPlanMutation,
  Core_CartItemUpdateProtectionPlanMutationVariables
>;
export const Core_CartItemDeleteProtectionPlanDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "Core_CartItemDeleteProtectionPlan" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "input" },
          },
          type: {
            kind: "NamedType",
            name: {
              kind: "Name",
              value:
                "Input_core_apimessages_DeleteCartItemProtectionPlanRequest",
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "deleteCartItemProtectionPlan" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "input" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "cartItemUuid" },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Core_CartItemDeleteProtectionPlanMutation,
  Core_CartItemDeleteProtectionPlanMutationVariables
>;
export const Core_AvailableCartItemProtectionPlansDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "Core_AvailableCartItemProtectionPlans" },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "me" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "FragmentSpread",
                  name: {
                    kind: "Name",
                    value: "CartItemAvailableProtectionPlans",
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "CartItemAvailableProtectionPlans" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "rql_Me" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "uuid" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "cart" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "bundles" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "cartItems" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "_id" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "uuid" },
                            },
                            {
                              kind: "Field",
                              name: {
                                kind: "Name",
                                value: "protectionPlanOptions",
                              },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "availablePlans",
                                    },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "planId",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "durationMonths",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "amount",
                                          },
                                          selectionSet: {
                                            kind: "SelectionSet",
                                            selections: [
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "display",
                                                },
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "amount",
                                                },
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Core_AvailableCartItemProtectionPlansQuery,
  Core_AvailableCartItemProtectionPlansQueryVariables
>;
export const Core_Cart_UpdateItemQuantityDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "Core_Cart_UpdateItemQuantity" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "listingId" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "quantity" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "Int" } },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "editCart" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "listingId" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "listingId" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "quantity" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "quantity" },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "listingId" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Core_Cart_UpdateItemQuantityMutation,
  Core_Cart_UpdateItemQuantityMutationVariables
>;
export const Core_Cart_LayoutDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "Core_Cart_Layout" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "checkoutID" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
        },
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "skip" } },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "Boolean" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "getAdyenRegion" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "webRegion" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "me" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "FragmentSpread",
                  name: { kind: "Name", value: "CartLayoutMeFields" },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "checkout" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "id" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "checkoutID" },
                      },
                    },
                  ],
                },
              },
            ],
            directives: [
              {
                kind: "Directive",
                name: { kind: "Name", value: "skip" },
                arguments: [
                  {
                    kind: "Argument",
                    name: { kind: "Name", value: "if" },
                    value: {
                      kind: "Variable",
                      name: { kind: "Name", value: "skip" },
                    },
                  },
                ],
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "paymentErrorMessage" },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "CartItemRowPricingFields" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "core_apimessages_CartItem" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "listingTotal" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "discounted" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "discount" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "type" } },
                      { kind: "Field", name: { kind: "Name", value: "label" } },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "unitPrice" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "display" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "currency" },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "originalUnitPrice" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "display" },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "shippingTotal" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "discounted" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "discount" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "type" } },
                      { kind: "Field", name: { kind: "Name", value: "label" } },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "amountOwed" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "display" },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "originalAmountOwed" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "display" },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "listing" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "listingType" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "shipping" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "localPickupOnly" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "shippingPrices" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "_id" },
                            },
                            {
                              kind: "Field",
                              name: {
                                kind: "Name",
                                value: "carrierCalculated",
                              },
                            },
                            {
                              kind: "Field",
                              name: {
                                kind: "Name",
                                value: "destinationPostalCodeNeeded",
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "regional" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "shippingMethod" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "order" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "orderType" } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "CartItemBuyNowButtonFields" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "core_apimessages_CartItem" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "order" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "legacyOrderId" },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "paymentMethods" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "type" } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "mParticleListingFields" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Listing" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "title" } },
          { kind: "Field", name: { kind: "Name", value: "brandSlug" } },
          { kind: "Field", name: { kind: "Name", value: "categoryRootUuid" } },
          { kind: "Field", name: { kind: "Name", value: "make" } },
          { kind: "Field", name: { kind: "Name", value: "categoryUuids" } },
          { kind: "Field", name: { kind: "Name", value: "state" } },
          { kind: "Field", name: { kind: "Name", value: "listingType" } },
          { kind: "Field", name: { kind: "Name", value: "bumpEligible" } },
          { kind: "Field", name: { kind: "Name", value: "shopId" } },
          { kind: "Field", name: { kind: "Name", value: "inventory" } },
          { kind: "Field", name: { kind: "Name", value: "soldAsIs" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "acceptedPaymentMethods" },
          },
          { kind: "Field", name: { kind: "Name", value: "currency" } },
          { kind: "Field", name: { kind: "Name", value: "usOutlet" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "condition" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "conditionUuid" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "conditionSlug" },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "categories" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "slug" } },
                { kind: "Field", name: { kind: "Name", value: "rootSlug" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "csp" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "slug" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "brand" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      { kind: "Field", name: { kind: "Name", value: "slug" } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "pricing" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "buyerPrice" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "amount" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "currency" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "amountCents" },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "publishedAt" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "seconds" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "sale" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "code" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "buyerIneligibilityReason" },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "shipping" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "shippingPrices" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "shippingMethod" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "carrierCalculated" },
                      },
                      {
                        kind: "Field",
                        name: {
                          kind: "Name",
                          value: "destinationPostalCodeNeeded",
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "rate" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "amount" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "amountCents" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "currency" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "display" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "freeExpeditedShipping" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "localPickupOnly" },
                },
                { kind: "Field", name: { kind: "Name", value: "localPickup" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "certifiedPreOwned" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "title" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "badgeIconUrl" },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "shop" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "address" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "countryCode" },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "returnPolicy" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "newReturnWindowDays" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "usedReturnWindowDays" },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "CartItemQuantitySelectFields" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "rql_Me" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "uuid" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "cart" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "bundles" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "cartItems" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "_id" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "uuid" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "quantity" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "listing" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "_id" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "allowedBuyerQuantity",
                                    },
                                  },
                                  {
                                    kind: "FragmentSpread",
                                    name: {
                                      kind: "Name",
                                      value: "mParticleListingFields",
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "CartRemoveItemFields" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "rql_Me" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "uuid" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "cart" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "bundles" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "cartItems" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "_id" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "uuid" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "listing" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "_id" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                  },
                                  {
                                    kind: "FragmentSpread",
                                    name: {
                                      kind: "Name",
                                      value: "mParticleListingFields",
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "CartMoveItemToWatchListFields" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "rql_Me" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "uuid" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "cart" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "bundles" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "cartItems" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "_id" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "uuid" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "listing" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "_id" },
                                  },
                                  {
                                    kind: "FragmentSpread",
                                    name: {
                                      kind: "Name",
                                      value: "mParticleListingFields",
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "CartItemRowProtectionPlanFields" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "rql_Me" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "uuid" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "cart" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "bundles" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "cartItems" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "_id" },
                            },
                            {
                              kind: "Field",
                              name: {
                                kind: "Name",
                                value: "protectionPlanEligible",
                              },
                            },
                            {
                              kind: "Field",
                              alias: {
                                kind: "Name",
                                value: "cartItemProtectionPlan",
                              },
                              name: { kind: "Name", value: "protectionPlan" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "planId" },
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "durationMonths",
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "amount" },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "display",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "amount",
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "listing" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "_id" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "slug" },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "CartItemRowFields" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "rql_Me" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "uuid" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "cart" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "bundles" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "cartItems" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "_id" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "uuid" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "actions" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "canChangeQuantity",
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "canRemoveFromCart",
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "canMoveToWatchlist",
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "alerts" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "type" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "text" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "severity" },
                                  },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "listing" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "_id" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value:
                                        "otherBuyersWithListingInCartCounts",
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "title" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "images" },
                                    arguments: [
                                      {
                                        kind: "Argument",
                                        name: { kind: "Name", value: "input" },
                                        value: {
                                          kind: "ObjectValue",
                                          fields: [
                                            {
                                              kind: "ObjectField",
                                              name: {
                                                kind: "Name",
                                                value: "transform",
                                              },
                                              value: {
                                                kind: "StringValue",
                                                value: "card_square",
                                                block: false,
                                              },
                                            },
                                            {
                                              kind: "ObjectField",
                                              name: {
                                                kind: "Name",
                                                value: "count",
                                              },
                                              value: {
                                                kind: "IntValue",
                                                value: "1",
                                              },
                                            },
                                            {
                                              kind: "ObjectField",
                                              name: {
                                                kind: "Name",
                                                value: "scope",
                                              },
                                              value: {
                                                kind: "StringValue",
                                                value: "photos",
                                                block: false,
                                              },
                                            },
                                            {
                                              kind: "ObjectField",
                                              name: {
                                                kind: "Name",
                                                value: "type",
                                              },
                                              value: {
                                                kind: "StringValue",
                                                value: "Product",
                                                block: false,
                                              },
                                            },
                                          ],
                                        },
                                      },
                                    ],
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "source",
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "shop" },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "_id" },
                                        },
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "slug" },
                                        },
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "name" },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "address",
                                          },
                                          selectionSet: {
                                            kind: "SelectionSet",
                                            selections: [
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "_id",
                                                },
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "displayLocation",
                                                },
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "preorderInfo",
                                    },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "onPreorder",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "estimatedShipDate",
                                          },
                                          selectionSet: {
                                            kind: "SelectionSet",
                                            selections: [
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "seconds",
                                                },
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "shipping" },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "freeExpeditedShipping",
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "order" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "_id" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "orderType" },
                                  },
                                ],
                              },
                            },
                            {
                              kind: "FragmentSpread",
                              name: {
                                kind: "Name",
                                value: "CartItemRowPricingFields",
                              },
                            },
                            {
                              kind: "FragmentSpread",
                              name: {
                                kind: "Name",
                                value: "CartItemBuyNowButtonFields",
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "CartItemQuantitySelectFields" },
          },
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "CartRemoveItemFields" },
          },
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "CartMoveItemToWatchListFields" },
          },
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "CartItemRowProtectionPlanFields" },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "CartBundleItemListFields" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "rql_Me" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "cart" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "bundles" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "cartItems" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "_id" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "uuid" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "CartItemRowFields" },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "CartBundlePaymentMethodSelectorFields" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "rql_Me" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "cart" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "bundles" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "checkoutBundlingId" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "total" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "amount" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "amountCents",
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "currency" },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: {
                          kind: "Name",
                          value: "availablePaymentMethods",
                        },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "type" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "name" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "affirm" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "financingProgramSlug",
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "CartBundleOrderSummaryFields" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "rql_Me" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "cart" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "bundles" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "checkoutBundlingId" },
                      },
                      {
                        kind: "Field",
                        name: {
                          kind: "Name",
                          value: "availablePaymentMethods",
                        },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "type" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "applePay" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "merchantId" },
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "merchantName",
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "paypal" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "currency" },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "paymentMethodIcons" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "slug" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "description" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "url" },
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "total" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "label" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "amount" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "display" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "currency" },
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "amountCents",
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "lineItems" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "type" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "label" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "amount" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "display" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "currency" },
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "amountCents",
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "FragmentSpread",
            name: {
              kind: "Name",
              value: "CartBundlePaymentMethodSelectorFields",
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "CartBundleFreeShippingStatusesFields" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "rql_Me" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "cart" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "bundles" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "freeShippingStatuses" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "shopId" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "isThresholdMet" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "percentProgress" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "amountRemaining" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "display" },
                                  },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "shop" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "_id" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "name" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "slug" },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "CartBundleTitleFields" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "rql_Me" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "cart" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "bundles" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "title" } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "CartBundleRowFields" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "rql_Me" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "cart" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "bundles" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "freeShippingStatuses" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "shopId" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "CartBundleItemListFields" },
          },
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "CartBundleOrderSummaryFields" },
          },
          {
            kind: "FragmentSpread",
            name: {
              kind: "Name",
              value: "CartBundleFreeShippingStatusesFields",
            },
          },
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "CartBundleTitleFields" },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "CartCombineAndSaveRowFields" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "rql_Me" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "cart" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "bundles" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "cartItems" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "_id" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "listingId" },
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: {
                          kind: "Name",
                          value: "combineAndSaveEligibleListing",
                        },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "_id" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "categoryRootUuid" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "shippingPrice" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "amountCents",
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "shop" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "_id" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "name" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "slug" },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "CartHoistedExpressPayFields" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "rql_Me" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "cart" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "bundles" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "checkoutBundlingId" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "total" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "amount" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "amount" },
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "amountCents",
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "currency" },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: {
                          kind: "Name",
                          value: "availablePaymentMethods",
                        },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "type" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "applePay" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "merchantId" },
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "merchantName",
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "paypal" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "currency" },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "CartLayoutMeFields" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "rql_Me" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "uuid" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "cart" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "cartItems" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      { kind: "Field", name: { kind: "Name", value: "uuid" } },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "bundles" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "checkoutBundlingId" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "total" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "label" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "amount" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "display" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "currency" },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "lineItems" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "type" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "amount" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "currency" },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "CartBundleRowFields" },
          },
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "CartCombineAndSaveRowFields" },
          },
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "CartHoistedExpressPayFields" },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Core_Cart_LayoutQuery,
  Core_Cart_LayoutQueryVariables
>;
export const Core_Cart_MoveItemToWatchListDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "Core_Cart_MoveItemToWatchList" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "cartItemUuid" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "moveCartItemToWatchList" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "cartItemUuid" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "cartItemUuid" },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "listingId" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Core_Cart_MoveItemToWatchListMutation,
  Core_Cart_MoveItemToWatchListMutationVariables
>;
export const Core_Cart_RemoveItemDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "Core_Cart_RemoveItem" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "listingId" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "editCart" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "listingId" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "listingId" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "quantity" },
                      value: { kind: "IntValue", value: "0" },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "listingId" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Core_Cart_RemoveItemMutation,
  Core_Cart_RemoveItemMutationVariables
>;
export const Core_Cart_StartCheckoutDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "Core_Cart_StartCheckout" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "checkoutBundlingId" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "paymentMethodType" },
          },
          type: {
            kind: "NamedType",
            name: {
              kind: "Name",
              value: "core_apimessages_CheckoutPaymentMethod_Type",
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "startCheckout" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "checkoutBundlingId" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "checkoutBundlingId" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "paymentMethodType" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "paymentMethodType" },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Core_Cart_StartCheckoutMutation,
  Core_Cart_StartCheckoutMutationVariables
>;
export const Cart_SignalsDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "Cart_Signals" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "id" } },
          type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "skipLoadingSignals" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "Boolean" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "listing" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "id" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "id" },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "id" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "signals" },
                  arguments: [
                    {
                      kind: "Argument",
                      name: { kind: "Name", value: "input" },
                      value: {
                        kind: "ObjectValue",
                        fields: [
                          {
                            kind: "ObjectField",
                            name: { kind: "Name", value: "groups" },
                            value: {
                              kind: "ListValue",
                              values: [
                                {
                                  kind: "EnumValue",
                                  value: "CART_PAGE_WHY_TO_BUY",
                                },
                              ],
                            },
                          },
                        ],
                      },
                    },
                  ],
                  directives: [
                    {
                      kind: "Directive",
                      name: { kind: "Name", value: "skip" },
                      arguments: [
                        {
                          kind: "Argument",
                          name: { kind: "Name", value: "if" },
                          value: {
                            kind: "Variable",
                            name: { kind: "Name", value: "skipLoadingSignals" },
                          },
                        },
                      ],
                    },
                  ],
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "name" } },
                      { kind: "Field", name: { kind: "Name", value: "title" } },
                      { kind: "Field", name: { kind: "Name", value: "icon" } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<Cart_SignalsQuery, Cart_SignalsQueryVariables>;
export const Core_Form_Select_CategoriesDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "Core_Form_Select_Categories" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "entityId" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "entityType" },
          },
          type: {
            kind: "NamedType",
            name: {
              kind: "Name",
              value:
                "core_apimessages_FormSelectCategoriesRequest_FormSelectEntityType",
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "formSelectCategories" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "entityId" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "entityId" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "entityType" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "entityType" },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "categories" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "uuid" } },
                      { kind: "Field", name: { kind: "Name", value: "name" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "subcategories" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "uuid" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "name" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "subcategories" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "uuid" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "name" },
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "subcategories",
                                    },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "uuid" },
                                        },
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "name" },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Core_Form_Select_CategoriesQuery,
  Core_Form_Select_CategoriesQueryVariables
>;
export const Core_Category_TopProductsDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "Core_Category_TopProducts" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "currentCategorySlugs" },
          },
          type: {
            kind: "ListType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "categoryUUIDs" },
          },
          type: {
            kind: "ListType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "bestSellerThreshold" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "Int" } },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "categories" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "ids" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "categoryUUIDs" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "withChildren" },
                      value: { kind: "BooleanValue", value: true },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                {
                  kind: "FragmentSpread",
                  name: { kind: "Name", value: "TopProductsCategory" },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "cspSearch" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "sort" },
                      value: {
                        kind: "EnumValue",
                        value: "RECENT_ORDERS_COUNT_DESC",
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "limit" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "bestSellerThreshold" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "categorySlugs" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "currentCategorySlugs" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "withAggregations" },
                      value: {
                        kind: "ListValue",
                        values: [
                          { kind: "EnumValue", value: "CATEGORY_UUIDS" },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "csps" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      {
                        kind: "FragmentSpread",
                        name: { kind: "Name", value: "TopProductsCSP" },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "CSPAdditionalTrackingFields" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "CSP" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "slug" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "brand" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "slug" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "categories" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "slug" } },
                { kind: "Field", name: { kind: "Name", value: "rootSlug" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "parentCategories" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      { kind: "Field", name: { kind: "Name", value: "slug" } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "inventory" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "usedTotal" } },
                { kind: "Field", name: { kind: "Name", value: "newTotal" } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "CSPCard" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "CSP" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "title" } },
          { kind: "Field", name: { kind: "Name", value: "slug" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "averageReviewRating" },
          },
          { kind: "Field", name: { kind: "Name", value: "reviewsCount" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "webLink" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "path" } },
                { kind: "Field", name: { kind: "Name", value: "href" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "image" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "transform" },
                      value: {
                        kind: "StringValue",
                        value: "card_square",
                        block: false,
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "source" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "brand" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "slug" } },
                { kind: "Field", name: { kind: "Name", value: "name" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "categories" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "slug" } },
                { kind: "Field", name: { kind: "Name", value: "rootSlug" } },
                { kind: "Field", name: { kind: "Name", value: "name" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "parentCategories" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      { kind: "Field", name: { kind: "Name", value: "name" } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "inventory" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "newLowPrice" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "display" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "amount" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "amountCents" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "currency" },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "usedLowPrice" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "display" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "amount" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "amountCents" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "currency" },
                      },
                    ],
                  },
                },
                { kind: "Field", name: { kind: "Name", value: "usedTotal" } },
                { kind: "Field", name: { kind: "Name", value: "newTotal" } },
              ],
            },
          },
          { kind: "Field", name: { kind: "Name", value: "finishes" } },
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "CSPAdditionalTrackingFields" },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "TopProductsCategory" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Category" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "slug" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "children" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "name" } },
                { kind: "Field", name: { kind: "Name", value: "slug" } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "TopProductsCSP" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "CSP" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "summary" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "categories" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "name" } },
              ],
            },
          },
          { kind: "FragmentSpread", name: { kind: "Name", value: "CSPCard" } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Core_Category_TopProductsQuery,
  Core_Category_TopProductsQueryVariables
>;
export const Core_Checkout_BuyerCouponListDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "Core_Checkout_BuyerCouponList" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "id" } },
          type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "loggedOut" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "Boolean" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "me" },
            directives: [
              {
                kind: "Directive",
                name: { kind: "Name", value: "skip" },
                arguments: [
                  {
                    kind: "Argument",
                    name: { kind: "Name", value: "if" },
                    value: {
                      kind: "Variable",
                      name: { kind: "Name", value: "loggedOut" },
                    },
                  },
                ],
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "uuid" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "buyerCoupons" },
                  arguments: [
                    {
                      kind: "Argument",
                      name: { kind: "Name", value: "input" },
                      value: {
                        kind: "ObjectValue",
                        fields: [
                          {
                            kind: "ObjectField",
                            name: { kind: "Name", value: "context" },
                            value: { kind: "EnumValue", value: "CHECKOUT" },
                          },
                          {
                            kind: "ObjectField",
                            name: { kind: "Name", value: "checkoutUuid" },
                            value: {
                              kind: "Variable",
                              name: { kind: "Name", value: "id" },
                            },
                          },
                        ],
                      },
                    },
                  ],
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "shopCampaignCoupons" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "_id" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "code" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "couponHeader" },
                            },
                            {
                              kind: "Field",
                              name: {
                                kind: "Name",
                                value: "descriptionSummary",
                              },
                            },
                            {
                              kind: "Field",
                              name: {
                                kind: "Name",
                                value: "restrictionsSummary",
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "expiresAt" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "discountValue" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "status" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "excludedBrands" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "_id" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "name" },
                                  },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: {
                                kind: "Name",
                                value: "excludedCategories",
                              },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "_id" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "name" },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Core_Checkout_BuyerCouponListQuery,
  Core_Checkout_BuyerCouponListQueryVariables
>;
export const Core_Checkout_AuthorizationLayoutDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "Core_Checkout_AuthorizationLayout" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "id" } },
          type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "reverbApp" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "checkout" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "id" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "id" },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "id" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "checkoutBundlingId" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "adyenPaymentAction" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "action" },
                  arguments: [
                    {
                      kind: "Argument",
                      name: { kind: "Name", value: "input" },
                      value: {
                        kind: "ObjectValue",
                        fields: [
                          {
                            kind: "ObjectField",
                            name: { kind: "Name", value: "reverbApp" },
                            value: {
                              kind: "Variable",
                              name: { kind: "Name", value: "reverbApp" },
                            },
                          },
                        ],
                      },
                    },
                  ],
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      {
                        kind: "FragmentSpread",
                        name: {
                          kind: "Name",
                          value: "CheckoutActionHookFields",
                        },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "total" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "amount" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "amountCents" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "currency" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: "FragmentSpread",
                  name: { kind: "Name", value: "CheckoutTrackingFields" },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "CheckoutActionHookFields" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "CheckoutAction" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "type" } },
          { kind: "Field", name: { kind: "Name", value: "redirectUrl" } },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "CheckoutTrackingFields" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Checkout" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "source" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "paymentMethod" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "type" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Core_Checkout_AuthorizationLayoutQuery,
  Core_Checkout_AuthorizationLayoutQueryVariables
>;
export const Core_Checkout_SelectShippingAddressDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "Core_Checkout_SelectShippingAddress" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "input" },
          },
          type: {
            kind: "NamedType",
            name: {
              kind: "Name",
              value:
                "Input_core_apimessages_CheckoutSelectShippingAddressRequest",
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "selectCheckoutShippingAddress" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "input" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "checkout" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Core_Checkout_SelectShippingAddressMutation,
  Core_Checkout_SelectShippingAddressMutationVariables
>;
export const Core_Checkout_LayoutDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "Core_Checkout_Layout" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "id" } },
          type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "reverbApp" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "loggedOut" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "Boolean" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "checkout" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "id" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "id" },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "checkoutBundlingId" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "paymentMethod" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "type" } },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "shippingAddress" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "isComplete" },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "billingAddress" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "country" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "_id" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "name" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "orders" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: {
                          kind: "Name",
                          value: "cannotBeShippedToAddress",
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "shippingMethod" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "type" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "action" },
                  arguments: [
                    {
                      kind: "Argument",
                      name: { kind: "Name", value: "input" },
                      value: {
                        kind: "ObjectValue",
                        fields: [
                          {
                            kind: "ObjectField",
                            name: { kind: "Name", value: "reverbApp" },
                            value: {
                              kind: "Variable",
                              name: { kind: "Name", value: "reverbApp" },
                            },
                          },
                        ],
                      },
                    },
                  ],
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      {
                        kind: "FragmentSpread",
                        name: {
                          kind: "Name",
                          value: "CheckoutActionHookFields",
                        },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "isAllPaidWithBucks" },
                },
                {
                  kind: "FragmentSpread",
                  name: { kind: "Name", value: "CheckoutOrderSummaryFields" },
                },
                {
                  kind: "FragmentSpread",
                  name: {
                    kind: "Name",
                    value: "CheckoutShippingAddressFormFields",
                  },
                },
                {
                  kind: "FragmentSpread",
                  name: { kind: "Name", value: "CheckoutPaymentMethodFields" },
                },
                {
                  kind: "FragmentSpread",
                  name: { kind: "Name", value: "CheckoutContinueButtonFields" },
                },
                {
                  kind: "FragmentSpread",
                  name: {
                    kind: "Name",
                    value: "CheckoutStepErrorMessageFields",
                  },
                },
                {
                  kind: "FragmentSpread",
                  name: {
                    kind: "Name",
                    value: "CheckoutDisplayCheckoutFields",
                  },
                },
                {
                  kind: "FragmentSpread",
                  name: { kind: "Name", value: "CheckoutTrackingFields" },
                },
                {
                  kind: "FragmentSpread",
                  name: {
                    kind: "Name",
                    value: "PaymentMethodSelectorVisibilityHookFields",
                  },
                },
                {
                  kind: "FragmentSpread",
                  name: {
                    kind: "Name",
                    value: "CheckoutDiscountCodeFormFields",
                  },
                },
                {
                  kind: "FragmentSpread",
                  name: { kind: "Name", value: "CheckoutThirdPartyAdsFields" },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "me" },
            directives: [
              {
                kind: "Directive",
                name: { kind: "Name", value: "skip" },
                arguments: [
                  {
                    kind: "Argument",
                    name: { kind: "Name", value: "if" },
                    value: {
                      kind: "Variable",
                      name: { kind: "Name", value: "loggedOut" },
                    },
                  },
                ],
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "uuid" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "shippingAddresses" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      { kind: "Field", name: { kind: "Name", value: "uuid" } },
                    ],
                  },
                },
                {
                  kind: "FragmentSpread",
                  name: { kind: "Name", value: "CheckoutDisplayMeFields" },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: {
        kind: "Name",
        value: "CheckoutOrderSummaryOrderItemSubtotalFields",
      },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Checkout" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "orders" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "quantity" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "listing" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "pricing" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "originalPrice" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "display" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "currency" },
                                  },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "buyerPrice" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "display" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "currency" },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "subtotal" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "listingPrice" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "display" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "currency" },
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "discounted" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "discount" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "type" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "label" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: {
        kind: "Name",
        value: "CheckoutOrderSummaryOrderItemShippingTotalFields",
      },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Checkout" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "orders" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "shippingTotal" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "originalAmountOwed" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "display" },
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "amountOwed" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "display" },
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "discounted" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "discount" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "type" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "label" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "CheckoutOrderSummaryImageFields" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Checkout" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "orders" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "listing" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      { kind: "Field", name: { kind: "Name", value: "title" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "images" },
                        arguments: [
                          {
                            kind: "Argument",
                            name: { kind: "Name", value: "input" },
                            value: {
                              kind: "ObjectValue",
                              fields: [
                                {
                                  kind: "ObjectField",
                                  name: { kind: "Name", value: "transform" },
                                  value: {
                                    kind: "StringValue",
                                    value: "card_square",
                                    block: false,
                                  },
                                },
                                {
                                  kind: "ObjectField",
                                  name: { kind: "Name", value: "count" },
                                  value: { kind: "IntValue", value: "1" },
                                },
                                {
                                  kind: "ObjectField",
                                  name: { kind: "Name", value: "scope" },
                                  value: {
                                    kind: "StringValue",
                                    value: "photos",
                                    block: false,
                                  },
                                },
                                {
                                  kind: "ObjectField",
                                  name: { kind: "Name", value: "type" },
                                  value: {
                                    kind: "StringValue",
                                    value: "Product",
                                    block: false,
                                  },
                                },
                              ],
                            },
                          },
                        ],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "source" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "CheckoutOrderQuantitySelectorFields" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Checkout" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "orders" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "quantity" } },
                { kind: "Field", name: { kind: "Name", value: "listingId" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "buyerCanChangeQuantity" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "listing" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "allowedBuyerQuantity" },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "CheckoutOrderShippingSelectorFields" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Checkout" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "orders" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "listingId" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "availableShippingMethods" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "name" } },
                      { kind: "Field", name: { kind: "Name", value: "type" } },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "shippingMethod" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "name" } },
                      { kind: "Field", name: { kind: "Name", value: "type" } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "CheckoutOrderSummaryOrderItemFields" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Checkout" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "orders" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "cannotBeShippedToAddress" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "subtotal" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "discounted" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "discount" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "label" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "type" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                { kind: "Field", name: { kind: "Name", value: "taxIncluded" } },
                { kind: "Field", name: { kind: "Name", value: "taxLabel" } },
                { kind: "Field", name: { kind: "Name", value: "listingId" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "listing" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      { kind: "Field", name: { kind: "Name", value: "title" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "shop" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "_id" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "name" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "userUuid" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "address" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "_id" },
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "displayLocation",
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "protectionPlan" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "title" } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "FragmentSpread",
            name: {
              kind: "Name",
              value: "CheckoutOrderSummaryOrderItemSubtotalFields",
            },
          },
          {
            kind: "FragmentSpread",
            name: {
              kind: "Name",
              value: "CheckoutOrderSummaryOrderItemShippingTotalFields",
            },
          },
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "CheckoutOrderSummaryImageFields" },
          },
          {
            kind: "FragmentSpread",
            name: {
              kind: "Name",
              value: "CheckoutOrderQuantitySelectorFields",
            },
          },
          {
            kind: "FragmentSpread",
            name: {
              kind: "Name",
              value: "CheckoutOrderShippingSelectorFields",
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "CheckoutOrderSummaryLineItemsFields" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Checkout" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "total" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "label" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "amount" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "display" },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "lineItems" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "type" } },
                { kind: "Field", name: { kind: "Name", value: "label" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "amount" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "display" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "currency" },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "CheckoutGuestContactInfoSummaryFields" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Checkout" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "guest" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "firstName" } },
                { kind: "Field", name: { kind: "Name", value: "lastName" } },
                { kind: "Field", name: { kind: "Name", value: "email" } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "CheckoutNewShippingAddressFormFields" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Checkout" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "guest" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "firstName" } },
                { kind: "Field", name: { kind: "Name", value: "lastName" } },
                { kind: "Field", name: { kind: "Name", value: "email" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "orders" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "shippingMethod" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "type" } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "shippingAddress" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "name" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "streetAddress" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "extendedAddress" },
                },
                { kind: "Field", name: { kind: "Name", value: "locality" } },
                { kind: "Field", name: { kind: "Name", value: "region" } },
                { kind: "Field", name: { kind: "Name", value: "postalCode" } },
                { kind: "Field", name: { kind: "Name", value: "countryCode" } },
                { kind: "Field", name: { kind: "Name", value: "phone" } },
                { kind: "Field", name: { kind: "Name", value: "isComplete" } },
                { kind: "Field", name: { kind: "Name", value: "regionName" } },
              ],
            },
          },
          {
            kind: "FragmentSpread",
            name: {
              kind: "Name",
              value: "CheckoutGuestContactInfoSummaryFields",
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "CheckoutTrackingFields" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Checkout" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "source" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "paymentMethod" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "type" } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: {
        kind: "Name",
        value: "CheckoutExistingShippingAddressFormFields",
      },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Checkout" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "orders" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "shippingMethod" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "type" } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "shippingAddress" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "id" } },
              ],
            },
          },
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "CheckoutTrackingFields" },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: {
        kind: "Name",
        value: "CheckoutAvailablePaymentMethodsHookFields",
      },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Checkout" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "availablePaymentMethods" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "type" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "googlePay" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "merchantId" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "merchantName" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "gatewayMerchantId" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "cardNetworks" },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "applePay" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "merchantId" },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "CheckoutCreditCardWalletFields" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Checkout" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "shippingAddress" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "uuid" } },
                { kind: "Field", name: { kind: "Name", value: "id" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "paymentMethod" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "isComplete" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "creditCard" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "needsReverification" },
                },
                { kind: "Field", name: { kind: "Name", value: "cardType" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "brand" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "type" } },
                      { kind: "Field", name: { kind: "Name", value: "name" } },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "display" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "summary" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "expirationDate" },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "CheckoutTokenizedCardPaymentFormFields" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Checkout" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "orders" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "shippingMethod" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "type" } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "shippingAddress" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "uuid" } },
                { kind: "Field", name: { kind: "Name", value: "name" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "streetAddress" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "extendedAddress" },
                },
                { kind: "Field", name: { kind: "Name", value: "postalCode" } },
                { kind: "Field", name: { kind: "Name", value: "region" } },
                { kind: "Field", name: { kind: "Name", value: "regionName" } },
                { kind: "Field", name: { kind: "Name", value: "locality" } },
                { kind: "Field", name: { kind: "Name", value: "phone" } },
                { kind: "Field", name: { kind: "Name", value: "primary" } },
                { kind: "Field", name: { kind: "Name", value: "isComplete" } },
                { kind: "Field", name: { kind: "Name", value: "countryCode" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "country" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      { kind: "Field", name: { kind: "Name", value: "name" } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "CheckoutCreditCardFormFields" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Checkout" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "CheckoutCreditCardWalletFields" },
          },
          {
            kind: "FragmentSpread",
            name: {
              kind: "Name",
              value: "CheckoutTokenizedCardPaymentFormFields",
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "CheckoutAffirmBillingFormFields" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Checkout" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "billingAddress" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "isComplete" } },
                { kind: "Field", name: { kind: "Name", value: "name" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "streetAddress" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "extendedAddress" },
                },
                { kind: "Field", name: { kind: "Name", value: "locality" } },
                { kind: "Field", name: { kind: "Name", value: "region" } },
                { kind: "Field", name: { kind: "Name", value: "postalCode" } },
                { kind: "Field", name: { kind: "Name", value: "countryCode" } },
                { kind: "Field", name: { kind: "Name", value: "phone" } },
                { kind: "Field", name: { kind: "Name", value: "regionName" } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "SelectCheckoutPaymentMethodFields" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Checkout" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "source" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "paymentMethod" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "type" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "applePay" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "merchantId" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "merchantName" },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "googlePay" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "merchantId" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "merchantName" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "gatewayMerchantId" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "cardNetworks" },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "PaymentMethodLabelFields" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Checkout" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "availablePaymentMethods" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "name" } },
                { kind: "Field", name: { kind: "Name", value: "type" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "affirm" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "financingProgramSlug" },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "lineItems" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "type" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "total" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "amount" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "amount" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "amountCents" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "currency" },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: {
        kind: "Name",
        value: "PaymentMethodSelectorVisibilityHookFields",
      },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Checkout" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "source" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "availablePaymentMethods" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "type" } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: {
        kind: "Name",
        value: "CheckoutStandalonePayPalPaymentOptionsFields",
      },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Checkout" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "total" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "amount" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "currency" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "amountCents" },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "paymentMethod" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "type" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "paypal" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "currency" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "merchantId" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "clientId" },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "CheckoutApplePayLineItemFields" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Checkout" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "total" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "amount" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "amount" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "currency" },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "lineItems" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "type" } },
                { kind: "Field", name: { kind: "Name", value: "label" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "amount" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "amount" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "amountCents" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "currency" },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "CheckoutApplePayHookFields" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Checkout" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "type" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "guest" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "firstName" } },
                { kind: "Field", name: { kind: "Name", value: "lastName" } },
                { kind: "Field", name: { kind: "Name", value: "email" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "shippingAddress" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "name" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "streetAddress" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "extendedAddress" },
                },
                { kind: "Field", name: { kind: "Name", value: "locality" } },
                { kind: "Field", name: { kind: "Name", value: "region" } },
                { kind: "Field", name: { kind: "Name", value: "postalCode" } },
                { kind: "Field", name: { kind: "Name", value: "countryCode" } },
                { kind: "Field", name: { kind: "Name", value: "phone" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "paymentMethod" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "applePay" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "merchantName" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "merchantId" },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "total" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "amount" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "amountCents" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "currency" },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "lineItems" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "type" } },
                { kind: "Field", name: { kind: "Name", value: "label" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "amount" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "amount" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "amountCents" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "currency" },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "CheckoutApplePayLineItemFields" },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "CheckoutCardContinueButtonFields" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Checkout" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "id" } },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "GooglePayManagerFields" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Checkout" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "status" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "paymentErrorMessage" },
          },
          { kind: "Field", name: { kind: "Name", value: "type" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "total" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "label" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "amount" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "amount" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "amountCents" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "currency" },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "guest" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "email" } },
                { kind: "Field", name: { kind: "Name", value: "firstName" } },
                { kind: "Field", name: { kind: "Name", value: "lastName" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "shippingAddress" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "name" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "streetAddress" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "extendedAddress" },
                },
                { kind: "Field", name: { kind: "Name", value: "locality" } },
                { kind: "Field", name: { kind: "Name", value: "region" } },
                { kind: "Field", name: { kind: "Name", value: "postalCode" } },
                { kind: "Field", name: { kind: "Name", value: "countryCode" } },
                { kind: "Field", name: { kind: "Name", value: "phone" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "lineItems" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "type" } },
                { kind: "Field", name: { kind: "Name", value: "label" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "amount" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "amount" },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "CheckoutGooglePayContinueButtonFields" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Checkout" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "paymentMethod" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "googlePay" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "merchantId" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "merchantName" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "gatewayMerchantId" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "cardNetworks" },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "total" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "amount" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "amount" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "currency" },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "GooglePayManagerFields" },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "CheckoutKlarnaContinueButtonFields" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Checkout" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "id" } },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "CheckoutPayPalContinueButtonFields" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Checkout" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "id" } },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "CheckoutPaypalPayLaterButtonFields" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Checkout" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "total" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "amount" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "currency" },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "paymentMethod" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "paypal" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "currency" },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "CheckoutContinueButtonStateFields" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Checkout" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "shippingAddress" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "isComplete" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "paymentMethod" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "isComplete" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "orders" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "cannotBeShippedToAddress" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "shippingMethod" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "type" } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "guest" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "isComplete" } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "CheckoutCardPaymentHookCheckoutFields" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Checkout" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "total" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "amount" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "amountCents" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "currency" },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "AffirmCheckoutBuilderFields" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Checkout" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "total" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "amount" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "amountCents" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "currency" },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "orders" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "quantity" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "subtotal" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "listingPrice" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "amountCents" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "listing" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      { kind: "Field", name: { kind: "Name", value: "title" } },
                      {
                        kind: "Field",
                        alias: { kind: "Name", value: "affirmImages" },
                        name: { kind: "Name", value: "images" },
                        arguments: [
                          {
                            kind: "Argument",
                            name: { kind: "Name", value: "input" },
                            value: {
                              kind: "ObjectValue",
                              fields: [
                                {
                                  kind: "ObjectField",
                                  name: { kind: "Name", value: "transform" },
                                  value: {
                                    kind: "StringValue",
                                    value: "card_square",
                                    block: false,
                                  },
                                },
                              ],
                            },
                          },
                        ],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "_id" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "source" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "paymentMethod" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "affirm" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "financingProgramSlug" },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "shippingAddress" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "name" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "streetAddress" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "extendedAddress" },
                },
                { kind: "Field", name: { kind: "Name", value: "locality" } },
                { kind: "Field", name: { kind: "Name", value: "region" } },
                { kind: "Field", name: { kind: "Name", value: "postalCode" } },
                { kind: "Field", name: { kind: "Name", value: "countryCode" } },
                { kind: "Field", name: { kind: "Name", value: "phone" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "billingAddress" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "name" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "streetAddress" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "extendedAddress" },
                },
                { kind: "Field", name: { kind: "Name", value: "locality" } },
                { kind: "Field", name: { kind: "Name", value: "region" } },
                { kind: "Field", name: { kind: "Name", value: "postalCode" } },
                { kind: "Field", name: { kind: "Name", value: "countryCode" } },
                { kind: "Field", name: { kind: "Name", value: "phone" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "lineItems" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "type" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "amount" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "amountCents" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "currency" },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "guest" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "email" } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "CheckoutAffirmPaymentFormHookFields" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Checkout" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "AffirmCheckoutBuilderFields" },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "CheckoutDisplayStepHookFields" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Checkout" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "paymentMethod" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "type" } },
                { kind: "Field", name: { kind: "Name", value: "isComplete" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "orders" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "shippingMethod" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "type" } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "guest" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "isComplete" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "shippingAddress" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "isComplete" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "billingAddress" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "isComplete" } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "CheckoutCardPaymentHookMeFields" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "rql_Me" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "uuid" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "creditCards" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "CheckoutActionHookFields" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "CheckoutAction" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "type" } },
          { kind: "Field", name: { kind: "Name", value: "redirectUrl" } },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "CheckoutOrderSummaryFields" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Checkout" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "orders" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "listingId" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "paymentMethod" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "type" } },
                { kind: "Field", name: { kind: "Name", value: "isComplete" } },
              ],
            },
          },
          {
            kind: "FragmentSpread",
            name: {
              kind: "Name",
              value: "CheckoutOrderSummaryOrderItemFields",
            },
          },
          {
            kind: "FragmentSpread",
            name: {
              kind: "Name",
              value: "CheckoutOrderSummaryLineItemsFields",
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "CheckoutShippingAddressFormFields" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Checkout" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "shippingAddress" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "countryCode" } },
              ],
            },
          },
          {
            kind: "FragmentSpread",
            name: {
              kind: "Name",
              value: "CheckoutNewShippingAddressFormFields",
            },
          },
          {
            kind: "FragmentSpread",
            name: {
              kind: "Name",
              value: "CheckoutExistingShippingAddressFormFields",
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "CheckoutPaymentMethodFields" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Checkout" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "availablePaymentMethods" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "name" } },
                { kind: "Field", name: { kind: "Name", value: "type" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "applePay" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "merchantId" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "merchantName" },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "googlePay" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "merchantId" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "merchantName" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "gatewayMerchantId" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "cardNetworks" },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "FragmentSpread",
            name: {
              kind: "Name",
              value: "CheckoutAvailablePaymentMethodsHookFields",
            },
          },
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "CheckoutCreditCardFormFields" },
          },
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "CheckoutAffirmBillingFormFields" },
          },
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "SelectCheckoutPaymentMethodFields" },
          },
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "PaymentMethodLabelFields" },
          },
          {
            kind: "FragmentSpread",
            name: {
              kind: "Name",
              value: "PaymentMethodSelectorVisibilityHookFields",
            },
          },
          {
            kind: "FragmentSpread",
            name: {
              kind: "Name",
              value: "CheckoutStandalonePayPalPaymentOptionsFields",
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "CheckoutContinueButtonFields" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Checkout" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "paymentMethod" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "type" } },
              ],
            },
          },
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "CheckoutApplePayHookFields" },
          },
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "CheckoutCardContinueButtonFields" },
          },
          {
            kind: "FragmentSpread",
            name: {
              kind: "Name",
              value: "CheckoutGooglePayContinueButtonFields",
            },
          },
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "CheckoutKlarnaContinueButtonFields" },
          },
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "CheckoutPayPalContinueButtonFields" },
          },
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "CheckoutPaypalPayLaterButtonFields" },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "CheckoutStepErrorMessageFields" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Checkout" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "paymentErrorMessage" },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "CheckoutDisplayCheckoutFields" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Checkout" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "CheckoutContinueButtonStateFields" },
          },
          {
            kind: "FragmentSpread",
            name: {
              kind: "Name",
              value: "CheckoutCardPaymentHookCheckoutFields",
            },
          },
          {
            kind: "FragmentSpread",
            name: {
              kind: "Name",
              value: "CheckoutAffirmPaymentFormHookFields",
            },
          },
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "CheckoutDisplayStepHookFields" },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "CheckoutDiscountCodeFormFields" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Checkout" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "paymentMethod" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "supportsDiscountCodes" },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "CheckoutThirdPartyAdsFields" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Checkout" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "type" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "total" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "amount" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "amount" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "currency" },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "orders" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "quantity" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "listing" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      { kind: "Field", name: { kind: "Name", value: "title" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "categories" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "_id" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "slug" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "rootSlug" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "subtotal" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "listingPrice" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "amount" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "guest" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "firstName" } },
                { kind: "Field", name: { kind: "Name", value: "lastName" } },
                { kind: "Field", name: { kind: "Name", value: "email" } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "CheckoutDisplayMeFields" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "rql_Me" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "uuid" } },
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "CheckoutCardPaymentHookMeFields" },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Core_Checkout_LayoutQuery,
  Core_Checkout_LayoutQueryVariables
>;
export const Core_Checkout_UpdateOrderQuantityDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "Core_Checkout_UpdateOrderQuantity" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "checkoutId" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "listingId" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "quantity" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "Int" } },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "editCheckoutCart" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "id" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "checkoutId" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "order" },
                      value: {
                        kind: "ObjectValue",
                        fields: [
                          {
                            kind: "ObjectField",
                            name: { kind: "Name", value: "listingId" },
                            value: {
                              kind: "Variable",
                              name: { kind: "Name", value: "listingId" },
                            },
                          },
                          {
                            kind: "ObjectField",
                            name: { kind: "Name", value: "quantity" },
                            value: {
                              kind: "Variable",
                              name: { kind: "Name", value: "quantity" },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "checkout" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Core_Checkout_UpdateOrderQuantityMutation,
  Core_Checkout_UpdateOrderQuantityMutationVariables
>;
export const Core_Checkout_SelectOrderShippingMethodDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "Core_Checkout_SelectOrderShippingMethod" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "input" },
          },
          type: {
            kind: "NamedType",
            name: {
              kind: "Name",
              value:
                "Input_core_apimessages_CheckoutSelectOrderShippingMethodRequest",
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "selectCheckoutOrderShippingMethod" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "input" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "checkout" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Core_Checkout_SelectOrderShippingMethodMutation,
  Core_Checkout_SelectOrderShippingMethodMutationVariables
>;
export const Core_Checkout_UpdateStandalonePaypalPayOrderDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: {
        kind: "Name",
        value: "Core_Checkout_UpdateStandalonePaypalPayOrder",
      },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "input" },
          },
          type: {
            kind: "NamedType",
            name: {
              kind: "Name",
              value: "Input_core_apimessages_UpdateCheckoutPaypalOrderRequest",
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "updateCheckoutPaypalOrder" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "input" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Core_Checkout_UpdateStandalonePaypalPayOrderMutation,
  Core_Checkout_UpdateStandalonePaypalPayOrderMutationVariables
>;
export const Core_Checkout_FinalizePayPalPaymentDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "Core_Checkout_FinalizePayPalPayment" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "input" },
          },
          type: {
            kind: "NamedType",
            name: {
              kind: "Name",
              value:
                "Input_core_apimessages_FinalizePaypalCheckoutPaymentRequest",
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "finalizePaypalCheckoutPayment" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "input" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Core_Checkout_FinalizePayPalPaymentMutation,
  Core_Checkout_FinalizePayPalPaymentMutationVariables
>;
export const Core_Checkout_PaymentInProgressLayoutDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "Core_Checkout_PaymentInProgressLayout" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "id" } },
          type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "reverbApp" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "checkout" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "id" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "id" },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "action" },
                  arguments: [
                    {
                      kind: "Argument",
                      name: { kind: "Name", value: "input" },
                      value: {
                        kind: "ObjectValue",
                        fields: [
                          {
                            kind: "ObjectField",
                            name: { kind: "Name", value: "reverbApp" },
                            value: {
                              kind: "Variable",
                              name: { kind: "Name", value: "reverbApp" },
                            },
                          },
                        ],
                      },
                    },
                  ],
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      {
                        kind: "FragmentSpread",
                        name: {
                          kind: "Name",
                          value: "CheckoutActionHookFields",
                        },
                      },
                    ],
                  },
                },
                {
                  kind: "FragmentSpread",
                  name: { kind: "Name", value: "CheckoutTrackingFields" },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "CheckoutActionHookFields" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "CheckoutAction" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "type" } },
          { kind: "Field", name: { kind: "Name", value: "redirectUrl" } },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "CheckoutTrackingFields" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Checkout" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "source" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "paymentMethod" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "type" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Core_Checkout_PaymentInProgressLayoutQuery,
  Core_Checkout_PaymentInProgressLayoutQueryVariables
>;
export const Core_Checkout_SelectCheckoutPaymentMethodDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: {
        kind: "Name",
        value: "Core_Checkout_SelectCheckoutPaymentMethod",
      },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "input" },
          },
          type: {
            kind: "NamedType",
            name: {
              kind: "Name",
              value:
                "Input_core_apimessages_SelectCheckoutPaymentMethodRequest",
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "selectCheckoutPaymentMethod" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "input" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "checkout" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      {
                        kind: "FragmentSpread",
                        name: {
                          kind: "Name",
                          value: "SelectCheckoutPaymentMethodFields",
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "SelectCheckoutPaymentMethodFields" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Checkout" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "source" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "paymentMethod" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "type" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "applePay" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "merchantId" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "merchantName" },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "googlePay" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "merchantId" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "merchantName" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "gatewayMerchantId" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "cardNetworks" },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Core_Checkout_SelectCheckoutPaymentMethodMutation,
  Core_Checkout_SelectCheckoutPaymentMethodMutationVariables
>;
export const Core_Checkout_CreatePaypalOrderDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "Core_Checkout_CreatePaypalOrder" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "input" },
          },
          type: {
            kind: "NamedType",
            name: {
              kind: "Name",
              value: "Input_core_apimessages_CreateCheckoutPaypalOrderRequest",
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "createCheckoutPaypalOrder" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "input" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "redirectUrl" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Core_Checkout_CreatePaypalOrderMutation,
  Core_Checkout_CreatePaypalOrderMutationVariables
>;
export const Core_Checkout_CreatePaypalPayLaterOrderDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "Core_Checkout_CreatePaypalPayLaterOrder" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "input" },
          },
          type: {
            kind: "NamedType",
            name: {
              kind: "Name",
              value: "Input_core_apimessages_CreateCheckoutPaypalOrderRequest",
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "createCheckoutPaypalOrder" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "input" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "paypalOrderId" },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Core_Checkout_CreatePaypalPayLaterOrderMutation,
  Core_Checkout_CreatePaypalPayLaterOrderMutationVariables
>;
export const Core_Checkout_RedirectReturnLayoutDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "Core_Checkout_RedirectReturnLayout" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "id" } },
          type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "reverbApp" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "checkout" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "id" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "id" },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "paymentMethod" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "type" } },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "action" },
                  arguments: [
                    {
                      kind: "Argument",
                      name: { kind: "Name", value: "input" },
                      value: {
                        kind: "ObjectValue",
                        fields: [
                          {
                            kind: "ObjectField",
                            name: { kind: "Name", value: "reverbApp" },
                            value: {
                              kind: "Variable",
                              name: { kind: "Name", value: "reverbApp" },
                            },
                          },
                        ],
                      },
                    },
                  ],
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      {
                        kind: "FragmentSpread",
                        name: {
                          kind: "Name",
                          value: "CheckoutActionHookFields",
                        },
                      },
                    ],
                  },
                },
                {
                  kind: "FragmentSpread",
                  name: { kind: "Name", value: "RedirectReturnCheckoutFields" },
                },
                {
                  kind: "FragmentSpread",
                  name: { kind: "Name", value: "CheckoutTrackingFields" },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "CheckoutActionHookFields" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "CheckoutAction" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "type" } },
          { kind: "Field", name: { kind: "Name", value: "redirectUrl" } },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "RedirectReturnCheckoutFields" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Checkout" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "status" } },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "CheckoutTrackingFields" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Checkout" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "source" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "paymentMethod" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "type" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Core_Checkout_RedirectReturnLayoutQuery,
  Core_Checkout_RedirectReturnLayoutQueryVariables
>;
export const Core_Checkout_FinalizeRedirectPaymentCheckoutDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: {
        kind: "Name",
        value: "Core_Checkout_FinalizeRedirectPaymentCheckout",
      },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "input" },
          },
          type: {
            kind: "NamedType",
            name: {
              kind: "Name",
              value:
                "Input_core_apimessages_CheckoutFinalizeRedirectPaymentCheckoutRequest",
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "finalizeRedirectPaymentCheckout" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "input" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "checkout" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Core_Checkout_FinalizeRedirectPaymentCheckoutMutation,
  Core_Checkout_FinalizeRedirectPaymentCheckoutMutationVariables
>;
export const Core_Checkout_FinalizeAffirmCheckoutPaymentDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: {
        kind: "Name",
        value: "Core_Checkout_FinalizeAffirmCheckoutPayment",
      },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "input" },
          },
          type: {
            kind: "NamedType",
            name: {
              kind: "Name",
              value:
                "Input_core_apimessages_FinalizeAffirmCheckoutPaymentRequest",
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "finalizeAffirmCheckoutPayment" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "input" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "checkout" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Core_Checkout_FinalizeAffirmCheckoutPaymentMutation,
  Core_Checkout_FinalizeAffirmCheckoutPaymentMutationVariables
>;
export const Core_Checkout_FinalizeRedirectAuthPaymentDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: {
        kind: "Name",
        value: "Core_Checkout_FinalizeRedirectAuthPayment",
      },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "input" },
          },
          type: {
            kind: "NamedType",
            name: {
              kind: "Name",
              value:
                "Input_core_apimessages_FinalizeRedirectAuthCheckoutPaymentRequest",
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: {
              kind: "Name",
              value: "finalizeRedirectAuthCheckoutPayment",
            },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "input" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "checkout" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Core_Checkout_FinalizeRedirectAuthPaymentMutation,
  Core_Checkout_FinalizeRedirectAuthPaymentMutationVariables
>;
export const Core_Checkout_CreateStandalonePaypalPayOrderDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: {
        kind: "Name",
        value: "Core_Checkout_CreateStandalonePaypalPayOrder",
      },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "input" },
          },
          type: {
            kind: "NamedType",
            name: {
              kind: "Name",
              value: "Input_core_apimessages_CreateCheckoutPaypalOrderRequest",
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "createCheckoutPaypalOrder" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "input" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "paypalOrderId" },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Core_Checkout_CreateStandalonePaypalPayOrderMutation,
  Core_Checkout_CreateStandalonePaypalPayOrderMutationVariables
>;
export const Core_Checkout_FinalizeStandalonePaypalPayOrderDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: {
        kind: "Name",
        value: "Core_Checkout_FinalizeStandalonePaypalPayOrder",
      },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "input" },
          },
          type: {
            kind: "NamedType",
            name: {
              kind: "Name",
              value:
                "Input_core_apimessages_FinalizePaypalCheckoutPaymentRequest",
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "finalizePaypalCheckoutPayment" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "input" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Core_Checkout_FinalizeStandalonePaypalPayOrderMutation,
  Core_Checkout_FinalizeStandalonePaypalPayOrderMutationVariables
>;
export const Core_Checkout_StartPaypalCheckoutDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "Core_Checkout_StartPaypalCheckout" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "input" },
          },
          type: {
            kind: "NamedType",
            name: {
              kind: "Name",
              value: "Input_core_apimessages_StartPaypalCheckoutRequest",
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "startPaypalCheckout" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "input" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "checkout" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "paypalOrderId" },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Core_Checkout_StartPaypalCheckoutMutation,
  Core_Checkout_StartPaypalCheckoutMutationVariables
>;
export const Core_Checkout_StartListingGooglePayDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "Core_Checkout_StartListingGooglePay" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "listingId" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "quantity" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "Int" } },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "protectionPlanId" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "startCheckoutForListing" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "listingId" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "listingId" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "quantity" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "quantity" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "paymentMethod" },
                      value: { kind: "EnumValue", value: "ANDROID_PAY" },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "protectionPlanId" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "protectionPlanId" },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "checkout" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      {
                        kind: "FragmentSpread",
                        name: { kind: "Name", value: "GooglePayManagerFields" },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "GooglePayManagerFields" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Checkout" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "status" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "paymentErrorMessage" },
          },
          { kind: "Field", name: { kind: "Name", value: "type" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "total" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "label" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "amount" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "amount" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "amountCents" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "currency" },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "guest" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "email" } },
                { kind: "Field", name: { kind: "Name", value: "firstName" } },
                { kind: "Field", name: { kind: "Name", value: "lastName" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "shippingAddress" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "name" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "streetAddress" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "extendedAddress" },
                },
                { kind: "Field", name: { kind: "Name", value: "locality" } },
                { kind: "Field", name: { kind: "Name", value: "region" } },
                { kind: "Field", name: { kind: "Name", value: "postalCode" } },
                { kind: "Field", name: { kind: "Name", value: "countryCode" } },
                { kind: "Field", name: { kind: "Name", value: "phone" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "lineItems" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "type" } },
                { kind: "Field", name: { kind: "Name", value: "label" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "amount" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "amount" },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Core_Checkout_StartListingGooglePayMutation,
  Core_Checkout_StartListingGooglePayMutationVariables
>;
export const Core_Checkout_UpdateAffirmBillingAddressDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "Core_Checkout_UpdateAffirmBillingAddress" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "input" },
          },
          type: {
            kind: "NamedType",
            name: {
              kind: "Name",
              value:
                "Input_core_apimessages_UpdateCheckoutBillingAddressRequest",
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "updateCheckoutBillingAddress" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "input" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "checkout" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      {
                        kind: "FragmentSpread",
                        name: {
                          kind: "Name",
                          value: "AffirmCheckoutBuilderFields",
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "AffirmCheckoutBuilderFields" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Checkout" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "total" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "amount" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "amountCents" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "currency" },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "orders" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "quantity" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "subtotal" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "listingPrice" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "amountCents" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "listing" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      { kind: "Field", name: { kind: "Name", value: "title" } },
                      {
                        kind: "Field",
                        alias: { kind: "Name", value: "affirmImages" },
                        name: { kind: "Name", value: "images" },
                        arguments: [
                          {
                            kind: "Argument",
                            name: { kind: "Name", value: "input" },
                            value: {
                              kind: "ObjectValue",
                              fields: [
                                {
                                  kind: "ObjectField",
                                  name: { kind: "Name", value: "transform" },
                                  value: {
                                    kind: "StringValue",
                                    value: "card_square",
                                    block: false,
                                  },
                                },
                              ],
                            },
                          },
                        ],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "_id" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "source" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "paymentMethod" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "affirm" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "financingProgramSlug" },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "shippingAddress" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "name" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "streetAddress" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "extendedAddress" },
                },
                { kind: "Field", name: { kind: "Name", value: "locality" } },
                { kind: "Field", name: { kind: "Name", value: "region" } },
                { kind: "Field", name: { kind: "Name", value: "postalCode" } },
                { kind: "Field", name: { kind: "Name", value: "countryCode" } },
                { kind: "Field", name: { kind: "Name", value: "phone" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "billingAddress" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "name" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "streetAddress" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "extendedAddress" },
                },
                { kind: "Field", name: { kind: "Name", value: "locality" } },
                { kind: "Field", name: { kind: "Name", value: "region" } },
                { kind: "Field", name: { kind: "Name", value: "postalCode" } },
                { kind: "Field", name: { kind: "Name", value: "countryCode" } },
                { kind: "Field", name: { kind: "Name", value: "phone" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "lineItems" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "type" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "amount" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "amountCents" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "currency" },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "guest" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "email" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Core_Checkout_UpdateAffirmBillingAddressMutation,
  Core_Checkout_UpdateAffirmBillingAddressMutationVariables
>;
export const Core_Checkout_FinalizeCardPaymentDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "Core_Checkout_FinalizeCardPayment" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "input" },
          },
          type: {
            kind: "NamedType",
            name: {
              kind: "Name",
              value:
                "Input_core_apimessages_FinalizeCardCheckoutPaymentRequest",
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "finalizeCardCheckoutPayment" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "input" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "checkout" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Core_Checkout_FinalizeCardPaymentMutation,
  Core_Checkout_FinalizeCardPaymentMutationVariables
>;
export const Core_Checkout_UpdateShippingInfoDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "Core_Checkout_UpdateShippingInfo" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "input" },
          },
          type: {
            kind: "NamedType",
            name: {
              kind: "Name",
              value:
                "Input_core_apimessages_CheckoutUpdateShippingAddressRequest",
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "updateCheckoutShippingAddress" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "input" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "checkout" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Core_Checkout_UpdateShippingInfoMutation,
  Core_Checkout_UpdateShippingInfoMutationVariables
>;
export const Core_Checkout_UpdateGooglePayShippingEstimateDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: {
        kind: "Name",
        value: "Core_Checkout_UpdateGooglePayShippingEstimate",
      },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "input" },
          },
          type: {
            kind: "NamedType",
            name: {
              kind: "Name",
              value:
                "Input_core_apimessages_CheckoutUpdateShippingEstimateRequest",
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "updateCheckoutShippingEstimate" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "input" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "checkout" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      {
                        kind: "FragmentSpread",
                        name: { kind: "Name", value: "GooglePayManagerFields" },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "GooglePayManagerFields" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Checkout" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "status" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "paymentErrorMessage" },
          },
          { kind: "Field", name: { kind: "Name", value: "type" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "total" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "label" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "amount" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "amount" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "amountCents" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "currency" },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "guest" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "email" } },
                { kind: "Field", name: { kind: "Name", value: "firstName" } },
                { kind: "Field", name: { kind: "Name", value: "lastName" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "shippingAddress" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "name" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "streetAddress" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "extendedAddress" },
                },
                { kind: "Field", name: { kind: "Name", value: "locality" } },
                { kind: "Field", name: { kind: "Name", value: "region" } },
                { kind: "Field", name: { kind: "Name", value: "postalCode" } },
                { kind: "Field", name: { kind: "Name", value: "countryCode" } },
                { kind: "Field", name: { kind: "Name", value: "phone" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "lineItems" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "type" } },
                { kind: "Field", name: { kind: "Name", value: "label" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "amount" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "amount" },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Core_Checkout_UpdateGooglePayShippingEstimateMutation,
  Core_Checkout_UpdateGooglePayShippingEstimateMutationVariables
>;
export const Core_Checkout_FinalizeGooglePayCheckoutDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "Core_Checkout_FinalizeGooglePayCheckout" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "input" },
          },
          type: {
            kind: "NamedType",
            name: {
              kind: "Name",
              value:
                "Input_core_apimessages_CheckoutFinalizeDigitalWalletPaymentRequest",
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "finalizeDigitalWalletPayment" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "input" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "checkout" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      {
                        kind: "FragmentSpread",
                        name: { kind: "Name", value: "GooglePayManagerFields" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "action" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "_id" },
                            },
                            {
                              kind: "FragmentSpread",
                              name: {
                                kind: "Name",
                                value: "GooglePayFinalizeActionFields",
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "GooglePayManagerFields" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Checkout" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "status" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "paymentErrorMessage" },
          },
          { kind: "Field", name: { kind: "Name", value: "type" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "total" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "label" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "amount" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "amount" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "amountCents" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "currency" },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "guest" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "email" } },
                { kind: "Field", name: { kind: "Name", value: "firstName" } },
                { kind: "Field", name: { kind: "Name", value: "lastName" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "shippingAddress" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "name" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "streetAddress" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "extendedAddress" },
                },
                { kind: "Field", name: { kind: "Name", value: "locality" } },
                { kind: "Field", name: { kind: "Name", value: "region" } },
                { kind: "Field", name: { kind: "Name", value: "postalCode" } },
                { kind: "Field", name: { kind: "Name", value: "countryCode" } },
                { kind: "Field", name: { kind: "Name", value: "phone" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "lineItems" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "type" } },
                { kind: "Field", name: { kind: "Name", value: "label" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "amount" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "amount" },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "GooglePayFinalizeActionFields" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "CheckoutAction" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "type" } },
          { kind: "Field", name: { kind: "Name", value: "redirectUrl" } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Core_Checkout_FinalizeGooglePayCheckoutMutation,
  Core_Checkout_FinalizeGooglePayCheckoutMutationVariables
>;
export const Core_Checkout_ProcessApprovedPaypalOrderDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "Core_Checkout_ProcessApprovedPaypalOrder" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "input" },
          },
          type: {
            kind: "NamedType",
            name: {
              kind: "Name",
              value: "Input_core_apimessages_ProcessApprovedPaypalOrderRequest",
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "processApprovedPaypalOrder" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "input" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "checkout" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Core_Checkout_ProcessApprovedPaypalOrderMutation,
  Core_Checkout_ProcessApprovedPaypalOrderMutationVariables
>;
export const Core_Checkout_FinalizeApplePayPaymentDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "Core_Checkout_FinalizeApplePayPayment" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "input" },
          },
          type: {
            kind: "NamedType",
            name: {
              kind: "Name",
              value:
                "Input_core_apimessages_CheckoutFinalizeDigitalWalletPaymentRequest",
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "finalizeDigitalWalletPayment" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "input" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "checkout" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "action" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "_id" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "type" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "redirectUrl" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Core_Checkout_FinalizeApplePayPaymentMutation,
  Core_Checkout_FinalizeApplePayPaymentMutationVariables
>;
export const SubmitCheckoutVerifyShopperDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "SubmitCheckoutVerifyShopper" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "input" },
          },
          type: {
            kind: "NamedType",
            name: {
              kind: "Name",
              value: "Input_core_apimessages_CheckoutVerifyShopperRequest",
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "checkoutVerifyShopper" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "input" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "adyenPaymentResult" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "action" },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "paymentResultUrl" },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  SubmitCheckoutVerifyShopperMutation,
  SubmitCheckoutVerifyShopperMutationVariables
>;
export const SubmitGuestCheckoutVerifyShopperDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "SubmitGuestCheckoutVerifyShopper" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "input" },
          },
          type: {
            kind: "NamedType",
            name: {
              kind: "Name",
              value: "Input_core_apimessages_GuestCheckoutVerifyShopperRequest",
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "guestCheckoutVerifyShopper" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "input" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "adyenPaymentResult" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "action" },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "paymentResultUrl" },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  SubmitGuestCheckoutVerifyShopperMutation,
  SubmitGuestCheckoutVerifyShopperMutationVariables
>;
export const Core_Checkout_SelectCreditCardDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "Core_Checkout_SelectCreditCard" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "input" },
          },
          type: {
            kind: "NamedType",
            name: {
              kind: "Name",
              value: "Input_core_apimessages_SelectCheckoutCreditCardRequest",
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "selectCheckoutCreditCard" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "input" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "checkout" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Core_Checkout_SelectCreditCardMutation,
  Core_Checkout_SelectCreditCardMutationVariables
>;
export const Core_CreditCardWallet_UpdateMyCreditCardDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "Core_CreditCardWallet_UpdateMyCreditCard" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "input" },
          },
          type: {
            kind: "NamedType",
            name: {
              kind: "Name",
              value: "Input_core_apimessages_UpdateMyCreditCardRequest",
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "updateMyCreditCard" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "input" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "creditCard" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "cardholderName" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "primaryForCheckout" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "display" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "summary" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "expirationDate" },
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "address" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "_id" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "name" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "streetAddress" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "extendedAddress" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "locality" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "region" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "regionName" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "postalCode" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "phone" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "countryCode" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "country" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "_id" },
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "countryCode",
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Core_CreditCardWallet_UpdateMyCreditCardMutation,
  Core_CreditCardWallet_UpdateMyCreditCardMutationVariables
>;
export const Core_CreditCardWallet_CreateMyCreditCardDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "Core_CreditCardWallet_CreateMyCreditCard" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "input" },
          },
          type: {
            kind: "NamedType",
            name: {
              kind: "Name",
              value: "Input_core_apimessages_CreateMyCreditCardRequest",
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "createMyCreditCard" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "input" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "creditCard" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Core_CreditCardWallet_CreateMyCreditCardMutation,
  Core_CreditCardWallet_CreateMyCreditCardMutationVariables
>;
export const Core_CreditCardWallet_CreateFormLayoutDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "Core_CreditCardWallet_CreateFormLayout" },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "countries" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "countries" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "countryCode" },
                      },
                      { kind: "Field", name: { kind: "Name", value: "name" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "subregionRequired" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "subregions" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "_id" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "code" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "name" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "me" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "uuid" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "shippingAddresses" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "primary" },
                      },
                    ],
                  },
                },
                {
                  kind: "FragmentSpread",
                  name: {
                    kind: "Name",
                    value: "CreditCardWalletCreateFormFields",
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "CreditCardWalletCreateFormFields" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "rql_Me" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "shippingAddresses" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "uuid" } },
                { kind: "Field", name: { kind: "Name", value: "name" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "streetAddress" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "extendedAddress" },
                },
                { kind: "Field", name: { kind: "Name", value: "postalCode" } },
                { kind: "Field", name: { kind: "Name", value: "region" } },
                { kind: "Field", name: { kind: "Name", value: "regionName" } },
                { kind: "Field", name: { kind: "Name", value: "locality" } },
                { kind: "Field", name: { kind: "Name", value: "phone" } },
                { kind: "Field", name: { kind: "Name", value: "primary" } },
                { kind: "Field", name: { kind: "Name", value: "isComplete" } },
                { kind: "Field", name: { kind: "Name", value: "countryCode" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "country" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      { kind: "Field", name: { kind: "Name", value: "name" } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Core_CreditCardWallet_CreateFormLayoutQuery,
  Core_CreditCardWallet_CreateFormLayoutQueryVariables
>;
export const Core_CreditCardWallet_EditFormLayoutDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "Core_CreditCardWallet_EditFormLayout" },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "countries" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "countries" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "countryCode" },
                      },
                      { kind: "Field", name: { kind: "Name", value: "name" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "subregionRequired" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "subregions" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "_id" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "code" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "name" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "me" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "uuid" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "creditCards" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "primaryBilling" },
                      },
                    ],
                  },
                },
                {
                  kind: "FragmentSpread",
                  name: { kind: "Name", value: "CreditCardEditFormFields" },
                },
                {
                  kind: "FragmentSpread",
                  name: { kind: "Name", value: "CreditCardDeleteButtonFields" },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "CreditCardEditFormFields" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "rql_Me" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "creditCards" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "brand" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "type" } },
                      { kind: "Field", name: { kind: "Name", value: "name" } },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "display" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "summary" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "expirationDate" },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "address" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "countryCode" },
                      },
                      { kind: "Field", name: { kind: "Name", value: "name" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "streetAddress" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "extendedAddress" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "locality" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "region" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "postalCode" },
                      },
                      { kind: "Field", name: { kind: "Name", value: "phone" } },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "expirationMonth" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "expirationYear" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "cardholderName" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "primaryForCheckout" },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "CreditCardDeleteButtonFields" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "rql_Me" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "creditCards" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Core_CreditCardWallet_EditFormLayoutQuery,
  Core_CreditCardWallet_EditFormLayoutQueryVariables
>;
export const Core_CreditCardWallet_IndexDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "Core_CreditCardWallet_Index" },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "me" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "uuid" } },
                {
                  kind: "FragmentSpread",
                  name: { kind: "Name", value: "CreditCardWalletLayoutFields" },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "CreditCardWalletRowSummaryFields" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "rql_Me" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "uuid" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "creditCards" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "primaryForCheckout" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "display" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "summary" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "expirationDate" },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "brand" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "type" } },
                      { kind: "Field", name: { kind: "Name", value: "name" } },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "address" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      { kind: "Field", name: { kind: "Name", value: "name" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "streetAddress" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "extendedAddress" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "locality" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "regionName" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "postalCode" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "country" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "_id" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "countryCode" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "CreditCardWalletRowFields" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "rql_Me" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "uuid" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "creditCards" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
              ],
            },
          },
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "CreditCardWalletRowSummaryFields" },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "CreditCardWalletLayoutFields" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "rql_Me" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "CreditCardWalletRowFields" },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Core_CreditCardWallet_IndexQuery,
  Core_CreditCardWallet_IndexQueryVariables
>;
export const Csp_Featured_ListingDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "Csp_Featured_Listing" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "id" } },
          type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "listing" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "id" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "id" },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "id" } },
                {
                  kind: "FragmentSpread",
                  name: { kind: "Name", value: "FeaturedListingWithPrice" },
                },
                {
                  kind: "FragmentSpread",
                  name: { kind: "Name", value: "WatchBadgeData" },
                },
                {
                  kind: "FragmentSpread",
                  name: { kind: "Name", value: "Item2ProductViewTrackingData" },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "ItemImageFields" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Listing" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "title" } },
          { kind: "Field", name: { kind: "Name", value: "state" } },
          {
            kind: "Field",
            alias: { kind: "Name", value: "largeImages" },
            name: { kind: "Name", value: "images" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "type" },
                      value: {
                        kind: "StringValue",
                        value: "Product",
                        block: false,
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "scope" },
                      value: {
                        kind: "StringValue",
                        value: "photos",
                        block: false,
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "transform" },
                      value: {
                        kind: "StringValue",
                        value: "large",
                        block: false,
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "count" },
                      value: { kind: "IntValue", value: "25" },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "source" } },
              ],
            },
          },
          {
            kind: "Field",
            alias: { kind: "Name", value: "supersizeImages" },
            name: { kind: "Name", value: "images" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "type" },
                      value: {
                        kind: "StringValue",
                        value: "Product",
                        block: false,
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "scope" },
                      value: {
                        kind: "StringValue",
                        value: "photos",
                        block: false,
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "transform" },
                      value: {
                        kind: "StringValue",
                        value: "supersize",
                        block: false,
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "count" },
                      value: { kind: "IntValue", value: "25" },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "source" } },
              ],
            },
          },
          {
            kind: "Field",
            alias: { kind: "Name", value: "cardSquareImages" },
            name: { kind: "Name", value: "images" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "type" },
                      value: {
                        kind: "StringValue",
                        value: "Product",
                        block: false,
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "scope" },
                      value: {
                        kind: "StringValue",
                        value: "photos",
                        block: false,
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "transform" },
                      value: {
                        kind: "StringValue",
                        value: "card_square",
                        block: false,
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "count" },
                      value: { kind: "IntValue", value: "25" },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "source" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "video" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "youtubeVideoId" },
                },
                { kind: "Field", name: { kind: "Name", value: "empty" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "csp" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "videos" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "videoLinks" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "youtubeVideoId" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "FeaturedListingShopInfoData" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Listing" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "state" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "seller" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "uuid" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "feedbackSummary" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: {
                          kind: "Name",
                          value: "rollingRatingPercentage",
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "receivedCount" },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "shop" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "name" } },
                { kind: "Field", name: { kind: "Name", value: "slug" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "preferredSeller" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "quickShipper" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "quickResponder" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "address" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "displayLocation" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "countryCode" },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "paymentPolicy" },
                },
                { kind: "Field", name: { kind: "Name", value: "createdAt" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "orders" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "total" } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "shipping" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "localPickupOnly" },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "AddToCartButtonFields" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Listing" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "sellerId" } },
          { kind: "Field", name: { kind: "Name", value: "listingType" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "pricing" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "buyerPrice" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "amount" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "amountCents" },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "preorderInfo" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "onPreorder" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "estimatedShipDate" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "seconds" },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "ListingForBuyerShippingFields" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Listing" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "shipping" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "freeExpeditedShipping" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "localPickupOnly" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "shippingPrices" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "shippingMethod" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "carrierCalculated" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "regional" },
                      },
                      {
                        kind: "Field",
                        name: {
                          kind: "Name",
                          value: "destinationPostalCodeNeeded",
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "postalCode" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "rate" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "amount" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "amountCents" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "currency" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "display" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "ListingShippingDisplayData" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Listing" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "shop" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "freeDomesticShippingRate" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "regionCode" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "freeShippingThreshold" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "amountCents" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "currency" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "ListingForBuyerShippingFields" },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "TypicalNewPriceDisplayFields" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Listing" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "condition" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "conditionUuid" },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "pricing" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "typicalNewPriceDisplay" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "amountDisplay" },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "originalPriceDescription" },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "Item2PriceWithShippingData" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Listing" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "state" } },
          { kind: "Field", name: { kind: "Name", value: "stateDescription" } },
          { kind: "Field", name: { kind: "Name", value: "taxIncluded" } },
          { kind: "Field", name: { kind: "Name", value: "taxIncludedHint" } },
          { kind: "Field", name: { kind: "Name", value: "listingType" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "pricing" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "originalPrice" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "display" },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "ribbon" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "display" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "reason" },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "buyerPrice" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "amountCents" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "currency" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "display" },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "shop" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "id" } },
              ],
            },
          },
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "ListingShippingDisplayData" },
          },
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "TypicalNewPriceDisplayFields" },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "CompareOtherSellersLinkData" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Listing" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "csp" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "id" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "webLink" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "href" } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "ListingAffirmCalloutFields" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Listing" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "estimatedMonthlyPaymentPlan" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "financingProgramSlug" },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "pricing" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "buyerPrice" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "display" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "amountCents" },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "shipping" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "shippingPrices" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "shippingMethod" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "rate" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "amountCents" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "ListingKlarnaCalloutFields" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Listing" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "pricing" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "buyerPrice" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "amountCents" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "currency" },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "shipping" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "shippingPrices" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "shippingMethod" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "rate" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "amountCents" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "ListingFinancingCalloutFields" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Listing" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "acceptedPaymentMethods" },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "pricing" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "buyerPrice" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "amountCents" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "amount" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "currency" },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "ListingAffirmCalloutFields" },
          },
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "ListingKlarnaCalloutFields" },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "Item2StatsData" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Listing" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "hasInventory" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "condition" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "conditionUuid" },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "publishedAt" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "seconds" } },
              ],
            },
          },
          { kind: "Field", name: { kind: "Name", value: "listingType" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "counts" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "viewsDisplay" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "offersDisplay" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "watchersDisplay" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "downloadCountDisplay" },
                },
              ],
            },
          },
          { kind: "Field", name: { kind: "Name", value: "offersEnabled" } },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "ListingGooglePayButtonFields" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Listing" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "expressPay" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "estimatedTotal" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "amount" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "currency" },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "googlePay" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "merchantId" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "merchantName" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "gatewayMerchantId" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "cardNetworks" },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "BuyItNowExpressPayOptionsFields" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Listing" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "currency" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "adyenCheckoutPaymentMethodsConfig" },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "expressPay" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "checkoutBundlingId" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "paypal" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "currency" },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "pricing" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "buyerPrice" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "amount" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "amountCents" },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "ListingGooglePayButtonFields" },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "BuyItNowPaymentOptionsFields" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Listing" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "acceptedPaymentMethods" },
          },
          { kind: "Field", name: { kind: "Name", value: "sellerId" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "shop" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "address" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "countryCode" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "country" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "_id" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "name" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "shipping" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "localPickupOnly" },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "pricing" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "buyerPrice" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "amount" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "amountCents" },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "BuyItNowExpressPayOptionsFields" },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "Item2CPOData" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Listing" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "brandSlug" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "certifiedPreOwned" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "title" } },
                { kind: "Field", name: { kind: "Name", value: "description" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "tooltipDescription" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "badgeIconUrl" },
                },
                { kind: "Field", name: { kind: "Name", value: "brandName" } },
                { kind: "Field", name: { kind: "Name", value: "pageUrl" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "lightModeBrandIconUrl" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "darkModeBrandIconUrl" },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "BuyItNowButtonFields" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Listing" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "shipping" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "localPickupOnly" },
                },
              ],
            },
          },
          { kind: "Field", name: { kind: "Name", value: "usOutlet" } },
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "BuyItNowPaymentOptionsFields" },
          },
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "Item2CPOData" },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "mParticleListingFields" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Listing" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "title" } },
          { kind: "Field", name: { kind: "Name", value: "brandSlug" } },
          { kind: "Field", name: { kind: "Name", value: "categoryRootUuid" } },
          { kind: "Field", name: { kind: "Name", value: "make" } },
          { kind: "Field", name: { kind: "Name", value: "categoryUuids" } },
          { kind: "Field", name: { kind: "Name", value: "state" } },
          { kind: "Field", name: { kind: "Name", value: "listingType" } },
          { kind: "Field", name: { kind: "Name", value: "bumpEligible" } },
          { kind: "Field", name: { kind: "Name", value: "shopId" } },
          { kind: "Field", name: { kind: "Name", value: "inventory" } },
          { kind: "Field", name: { kind: "Name", value: "soldAsIs" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "acceptedPaymentMethods" },
          },
          { kind: "Field", name: { kind: "Name", value: "currency" } },
          { kind: "Field", name: { kind: "Name", value: "usOutlet" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "condition" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "conditionUuid" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "conditionSlug" },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "categories" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "slug" } },
                { kind: "Field", name: { kind: "Name", value: "rootSlug" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "csp" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "slug" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "brand" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      { kind: "Field", name: { kind: "Name", value: "slug" } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "pricing" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "buyerPrice" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "amount" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "currency" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "amountCents" },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "publishedAt" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "seconds" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "sale" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "code" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "buyerIneligibilityReason" },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "shipping" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "shippingPrices" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "shippingMethod" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "carrierCalculated" },
                      },
                      {
                        kind: "Field",
                        name: {
                          kind: "Name",
                          value: "destinationPostalCodeNeeded",
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "rate" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "amount" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "amountCents" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "currency" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "display" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "freeExpeditedShipping" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "localPickupOnly" },
                },
                { kind: "Field", name: { kind: "Name", value: "localPickup" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "certifiedPreOwned" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "title" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "badgeIconUrl" },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "shop" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "address" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "countryCode" },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "returnPolicy" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "newReturnWindowDays" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "usedReturnWindowDays" },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "ListingCreateOfferButtonData" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Listing" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "state" } },
          { kind: "Field", name: { kind: "Name", value: "offersEnabled" } },
          { kind: "Field", name: { kind: "Name", value: "listingType" } },
          { kind: "Field", name: { kind: "Name", value: "sellerId" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "isBuyerOfferEligible" },
          },
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "mParticleListingFields" },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "DigitalDetailsFields" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Listing" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "listingType" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "digitalDetails" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "variants" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "downloadText" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "fileUrl" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "fileSize" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "operatingSystems" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "fileTypes" },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "supportedFormats" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "operatingSystem" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "fileTypes" },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "Item2PricingData" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Listing" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "offersEnabled" } },
          { kind: "Field", name: { kind: "Name", value: "inventory" } },
          { kind: "Field", name: { kind: "Name", value: "hasInventory" } },
          { kind: "Field", name: { kind: "Name", value: "maxBuyerQuantity" } },
          { kind: "Field", name: { kind: "Name", value: "make" } },
          { kind: "Field", name: { kind: "Name", value: "model" } },
          { kind: "Field", name: { kind: "Name", value: "state" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "condition" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "conditionSlug" },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "shop" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "address" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "countryCode" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "country" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "_id" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "name" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "AddToCartButtonFields" },
          },
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "Item2PriceWithShippingData" },
          },
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "CompareOtherSellersLinkData" },
          },
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "ListingFinancingCalloutFields" },
          },
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "Item2StatsData" },
          },
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "BuyItNowButtonFields" },
          },
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "ListingCreateOfferButtonData" },
          },
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "DigitalDetailsFields" },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "SignalsData" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Listing" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "state" } },
          { kind: "Field", name: { kind: "Name", value: "usOutlet" } },
          { kind: "Field", name: { kind: "Name", value: "currency" } },
          {
            kind: "Field",
            alias: { kind: "Name", value: "signals" },
            name: { kind: "Name", value: "signals" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "groups" },
                      value: {
                        kind: "ListValue",
                        values: [
                          { kind: "EnumValue", value: "ITEM_PAGE_WHY_TO_BUY" },
                          { kind: "EnumValue", value: "ITEM_PAGE_NUDGES" },
                          { kind: "EnumValue", value: "ITEM_PAGE_WHY_REVERB" },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "name" } },
                { kind: "Field", name: { kind: "Name", value: "group" } },
                { kind: "Field", name: { kind: "Name", value: "title" } },
                { kind: "Field", name: { kind: "Name", value: "subtitle" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "link" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "text" } },
                      { kind: "Field", name: { kind: "Name", value: "url" } },
                    ],
                  },
                },
                { kind: "Field", name: { kind: "Name", value: "icon" } },
                { kind: "Field", name: { kind: "Name", value: "iconDark" } },
                { kind: "Field", name: { kind: "Name", value: "tooltipText" } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "Item2ReturnPolicyData" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Listing" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "condition" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "conditionSlug" },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "shop" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "returnPolicy" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "newReturnWindowDays" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "usedReturnWindowDays" },
                      },
                    ],
                  },
                },
              ],
            },
          },
          { kind: "Field", name: { kind: "Name", value: "soldAsIs" } },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "FeaturedListingModuleData" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Listing" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "title" } },
          { kind: "Field", name: { kind: "Name", value: "slug" } },
          { kind: "Field", name: { kind: "Name", value: "description" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "condition" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "displayName" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "conditionSlug" },
                },
              ],
            },
          },
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "ItemImageFields" },
          },
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "FeaturedListingShopInfoData" },
          },
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "Item2PricingData" },
          },
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "SignalsData" },
          },
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "Item2ReturnPolicyData" },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "FeaturedListingWithPrice" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Listing" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "price" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "amount" } },
                { kind: "Field", name: { kind: "Name", value: "amountCents" } },
              ],
            },
          },
          { kind: "Field", name: { kind: "Name", value: "bumped" } },
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "FeaturedListingModuleData" },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "WatchBadgeData" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Listing" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "title" } },
          { kind: "Field", name: { kind: "Name", value: "sellerId" } },
          { kind: "Field", name: { kind: "Name", value: "watching" } },
          {
            kind: "Field",
            alias: { kind: "Name", value: "watchThumbnails" },
            name: { kind: "Name", value: "images" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "type" },
                      value: {
                        kind: "StringValue",
                        value: "Product",
                        block: false,
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "scope" },
                      value: {
                        kind: "StringValue",
                        value: "photos",
                        block: false,
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "transform" },
                      value: {
                        kind: "StringValue",
                        value: "card_square",
                        block: false,
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "count" },
                      value: { kind: "IntValue", value: "1" },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "source" } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "Item2ProductViewTrackingData" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Listing" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "acceptedPaymentMethods" },
          },
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "mParticleListingFields" },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Csp_Featured_ListingQuery,
  Csp_Featured_ListingQueryVariables
>;
export const Core_Csp_HeaderDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "Core_Csp_Header" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "soldListingId" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "listing" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "id" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "soldListingId" },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "title" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "images" },
                  arguments: [
                    {
                      kind: "Argument",
                      name: { kind: "Name", value: "input" },
                      value: {
                        kind: "ObjectValue",
                        fields: [
                          {
                            kind: "ObjectField",
                            name: { kind: "Name", value: "transform" },
                            value: {
                              kind: "StringValue",
                              value: "thumb",
                              block: false,
                            },
                          },
                        ],
                      },
                    },
                  ],
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "source" },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Core_Csp_HeaderQuery,
  Core_Csp_HeaderQueryVariables
>;
export const Csp_Filters_And_ListingsQueryDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "Csp_Filters_And_ListingsQuery" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "acceptsAffirm" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "Boolean" } },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "acceptsGiftCards" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "Boolean" } },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "acceptsOffers" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "Boolean" } },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "acceptsPaymentPlans" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "Boolean" } },
        },
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "aggs" } },
          type: {
            kind: "ListType",
            type: {
              kind: "NamedType",
              name: {
                kind: "Name",
                value: "reverb_search_ListingsSearchRequest_Aggregation",
              },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "applyProximityBoost" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "Boolean" } },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "boostedItemRegionCode" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "bumpLimit" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "Int" } },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "dealsAndSteals" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "Boolean" } },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "decades" },
          },
          type: {
            kind: "ListType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "canonicalFinishes" },
          },
          type: {
            kind: "ListType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "categorySlugs" },
          },
          type: {
            kind: "ListType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "combinedShipping" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "Boolean" } },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "conditionSlugs" },
          },
          type: {
            kind: "ListType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "cspSlug" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "excludeLocalPickupOnly" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "Boolean" } },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "fallbackToEverywhereElse" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "Boolean" } },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "freeExpeditedShipping" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "Boolean" } },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "freeShipping" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "Boolean" } },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "fretboardMaterial" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "includeFullFinancingFields" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "Boolean" },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "itemRegion" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "itemRegionRelation" },
          },
          type: {
            kind: "NamedType",
            name: {
              kind: "Name",
              value: "reverb_search_ListingItemRegionRelation",
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "limit" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "Int" } },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "localPickup" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "Boolean" } },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "offset" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "Int" } },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "onSale" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "Boolean" } },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "preferredSeller" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "Boolean" } },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "priceMin" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "priceMax" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "query" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "shippingRegionCode" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "shippingRegionCodes" },
          },
          type: {
            kind: "ListType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "shouldHideBumpAndSponsoredListings" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "Boolean" },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "shouldSkipTracking" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "Boolean" },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "sort" } },
          type: {
            kind: "NamedType",
            name: {
              kind: "Name",
              value: "reverb_search_ListingsSearchRequest_Sort",
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "sortSlug" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "sponsoredConditionSlugs" },
          },
          type: {
            kind: "ListType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "traitValues" },
          },
          type: {
            kind: "ListType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "withProximityFilter" },
          },
          type: {
            kind: "NamedType",
            name: {
              kind: "Name",
              value: "Input_reverb_search_ProximityFilterRequest",
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "yearMax" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "yearMin" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "userCurrency" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            alias: { kind: "Name", value: "allListings" },
            name: { kind: "Name", value: "listingsSearch" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "applyProximityBoost" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "applyProximityBoost" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "boostedItemRegionCode" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "boostedItemRegionCode" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "cspSlug" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "cspSlug" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "conditionSlugs" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "conditionSlugs" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "statuses" },
                      value: {
                        kind: "ListValue",
                        values: [
                          { kind: "StringValue", value: "live", block: false },
                        ],
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "canonicalFinishes" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "canonicalFinishes" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "traitValues" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "traitValues" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "withAggregations" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "aggs" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "priceMin" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "priceMin" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "priceMax" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "priceMax" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "query" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "query" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "categorySlugs" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "categorySlugs" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "sort" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "sort" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "sortSlug" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "sortSlug" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "localPickup" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "localPickup" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "acceptsOffers" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "acceptsOffers" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "onSale" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "onSale" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "excludeLocalPickupOnly" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "excludeLocalPickupOnly" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "freeShipping" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "freeShipping" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "freeExpeditedShipping" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "freeExpeditedShipping" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "combinedShipping" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "combinedShipping" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "acceptsPaymentPlans" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "acceptsPaymentPlans" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "dealsAndSteals" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "dealsAndSteals" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "decades" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "decades" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "yearMax" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "yearMax" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "yearMin" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "yearMin" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "acceptsGiftCards" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "acceptsGiftCards" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "preferredSeller" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "preferredSeller" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "acceptsAffirm" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "acceptsAffirm" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "itemRegion" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "itemRegion" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "itemRegionRelation" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "itemRegionRelation" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "shippingRegionCodes" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "shippingRegionCodes" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "withProximityFilter" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "withProximityFilter" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "fallbackToEverywhereElse" },
                      value: {
                        kind: "Variable",
                        name: {
                          kind: "Name",
                          value: "fallbackToEverywhereElse",
                        },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "offset" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "offset" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "limit" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "limit" },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "total" } },
                { kind: "Field", name: { kind: "Name", value: "offset" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "filters" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "FragmentSpread",
                        name: { kind: "Name", value: "NestedFilter" },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "listings" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      {
                        kind: "FragmentSpread",
                        name: { kind: "Name", value: "ListingData" },
                      },
                      {
                        kind: "FragmentSpread",
                        name: { kind: "Name", value: "ListingsCollection" },
                      },
                      {
                        kind: "FragmentSpread",
                        name: { kind: "Name", value: "mParticleListingFields" },
                        directives: [
                          {
                            kind: "Directive",
                            name: { kind: "Name", value: "skip" },
                            arguments: [
                              {
                                kind: "Argument",
                                name: { kind: "Name", value: "if" },
                                value: {
                                  kind: "Variable",
                                  name: {
                                    kind: "Name",
                                    value: "shouldSkipTracking",
                                  },
                                },
                              },
                            ],
                          },
                        ],
                      },
                      {
                        kind: "FragmentSpread",
                        name: { kind: "Name", value: "FullFinancingFields" },
                        directives: [
                          {
                            kind: "Directive",
                            name: { kind: "Name", value: "include" },
                            arguments: [
                              {
                                kind: "Argument",
                                name: { kind: "Name", value: "if" },
                                value: {
                                  kind: "Variable",
                                  name: {
                                    kind: "Name",
                                    value: "includeFullFinancingFields",
                                  },
                                },
                              },
                            ],
                          },
                        ],
                      },
                      {
                        kind: "FragmentSpread",
                        name: { kind: "Name", value: "ListingGreatValueData" },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "fallbackListings" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      {
                        kind: "FragmentSpread",
                        name: { kind: "Name", value: "ListingData" },
                      },
                      {
                        kind: "FragmentSpread",
                        name: { kind: "Name", value: "ListingsCollection" },
                      },
                      {
                        kind: "FragmentSpread",
                        name: { kind: "Name", value: "mParticleListingFields" },
                        directives: [
                          {
                            kind: "Directive",
                            name: { kind: "Name", value: "skip" },
                            arguments: [
                              {
                                kind: "Argument",
                                name: { kind: "Name", value: "if" },
                                value: {
                                  kind: "Variable",
                                  name: {
                                    kind: "Name",
                                    value: "shouldSkipTracking",
                                  },
                                },
                              },
                            ],
                          },
                        ],
                      },
                      {
                        kind: "FragmentSpread",
                        name: { kind: "Name", value: "FullFinancingFields" },
                        directives: [
                          {
                            kind: "Directive",
                            name: { kind: "Name", value: "include" },
                            arguments: [
                              {
                                kind: "Argument",
                                name: { kind: "Name", value: "if" },
                                value: {
                                  kind: "Variable",
                                  name: {
                                    kind: "Name",
                                    value: "includeFullFinancingFields",
                                  },
                                },
                              },
                            ],
                          },
                        ],
                      },
                      {
                        kind: "FragmentSpread",
                        name: { kind: "Name", value: "ListingGreatValueData" },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            alias: { kind: "Name", value: "allBumped" },
            name: { kind: "Name", value: "bumpedSortedListings" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "cspSlug" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "cspSlug" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "conditionSlugs" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "conditionSlugs" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "statuses" },
                      value: {
                        kind: "ListValue",
                        values: [
                          { kind: "StringValue", value: "live", block: false },
                        ],
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "sort" },
                      value: { kind: "EnumValue", value: "PRICE_ASC" },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "filterSuperRegionCode" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "shippingRegionCode" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "bumpedOnly" },
                      value: { kind: "BooleanValue", value: true },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "canonicalFinishes" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "canonicalFinishes" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "traitValues" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "traitValues" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "withAggregations" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "aggs" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "priceMin" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "priceMin" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "priceMax" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "priceMax" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "query" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "query" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "categorySlugs" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "categorySlugs" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "localPickup" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "localPickup" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "acceptsOffers" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "acceptsOffers" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "onSale" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "onSale" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "freeShipping" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "freeShipping" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "freeExpeditedShipping" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "freeExpeditedShipping" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "combinedShipping" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "combinedShipping" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "acceptsPaymentPlans" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "acceptsPaymentPlans" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "dealsAndSteals" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "dealsAndSteals" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "decades" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "decades" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "yearMax" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "yearMax" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "yearMin" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "yearMin" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "acceptsGiftCards" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "acceptsGiftCards" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "preferredSeller" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "preferredSeller" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "acceptsAffirm" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "acceptsAffirm" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "shippingRegionCodes" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "shippingRegionCodes" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "withProximityFilter" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "withProximityFilter" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "currency" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "userCurrency" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: {
                        kind: "Name",
                        value: "bumpedSortedListingsQuery",
                      },
                      value: {
                        kind: "ObjectValue",
                        fields: [
                          {
                            kind: "ObjectField",
                            name: { kind: "Name", value: "condition" },
                            value: {
                              kind: "StringValue",
                              value: "all",
                              block: false,
                            },
                          },
                          {
                            kind: "ObjectField",
                            name: { kind: "Name", value: "bumpedMax" },
                            value: {
                              kind: "Variable",
                              name: { kind: "Name", value: "bumpLimit" },
                            },
                          },
                          {
                            kind: "ObjectField",
                            name: { kind: "Name", value: "total" },
                            value: {
                              kind: "Variable",
                              name: { kind: "Name", value: "bumpLimit" },
                            },
                          },
                          {
                            kind: "ObjectField",
                            name: { kind: "Name", value: "itemRegion" },
                            value: {
                              kind: "Variable",
                              name: { kind: "Name", value: "itemRegion" },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            directives: [
              {
                kind: "Directive",
                name: { kind: "Name", value: "skip" },
                arguments: [
                  {
                    kind: "Argument",
                    name: { kind: "Name", value: "if" },
                    value: {
                      kind: "Variable",
                      name: {
                        kind: "Name",
                        value: "shouldHideBumpAndSponsoredListings",
                      },
                    },
                  },
                ],
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "listings" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      {
                        kind: "FragmentSpread",
                        name: { kind: "Name", value: "ListingData" },
                      },
                      {
                        kind: "FragmentSpread",
                        name: { kind: "Name", value: "ListingsCollection" },
                      },
                      {
                        kind: "FragmentSpread",
                        name: { kind: "Name", value: "mParticleListingFields" },
                        directives: [
                          {
                            kind: "Directive",
                            name: { kind: "Name", value: "skip" },
                            arguments: [
                              {
                                kind: "Argument",
                                name: { kind: "Name", value: "if" },
                                value: {
                                  kind: "Variable",
                                  name: {
                                    kind: "Name",
                                    value: "shouldSkipTracking",
                                  },
                                },
                              },
                            ],
                          },
                        ],
                      },
                      {
                        kind: "FragmentSpread",
                        name: { kind: "Name", value: "FullFinancingFields" },
                        directives: [
                          {
                            kind: "Directive",
                            name: { kind: "Name", value: "include" },
                            arguments: [
                              {
                                kind: "Argument",
                                name: { kind: "Name", value: "if" },
                                value: {
                                  kind: "Variable",
                                  name: {
                                    kind: "Name",
                                    value: "includeFullFinancingFields",
                                  },
                                },
                              },
                            ],
                          },
                        ],
                      },
                      {
                        kind: "FragmentSpread",
                        name: { kind: "Name", value: "BumpKey" },
                      },
                      {
                        kind: "FragmentSpread",
                        name: { kind: "Name", value: "ListingGreatValueData" },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "cspSponsoredListing" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "cspSlug" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "cspSlug" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "conditionSlugs" },
                      value: {
                        kind: "Variable",
                        name: {
                          kind: "Name",
                          value: "sponsoredConditionSlugs",
                        },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "statuses" },
                      value: {
                        kind: "ListValue",
                        values: [
                          { kind: "StringValue", value: "live", block: false },
                        ],
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "sort" },
                      value: { kind: "EnumValue", value: "PRICE_ASC" },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "canonicalFinishes" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "canonicalFinishes" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "traitValues" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "traitValues" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "priceMin" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "priceMin" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "priceMax" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "priceMax" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "query" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "query" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "categorySlugs" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "categorySlugs" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "localPickup" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "localPickup" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "acceptsOffers" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "acceptsOffers" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "onSale" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "onSale" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "freeShipping" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "freeShipping" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "freeExpeditedShipping" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "freeExpeditedShipping" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "combinedShipping" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "combinedShipping" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "acceptsPaymentPlans" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "acceptsPaymentPlans" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "dealsAndSteals" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "dealsAndSteals" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "decades" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "decades" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "yearMax" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "yearMax" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "yearMin" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "yearMin" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "acceptsGiftCards" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "acceptsGiftCards" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "preferredSeller" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "preferredSeller" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "acceptsAffirm" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "acceptsAffirm" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "shippingRegionCodes" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "shippingRegionCodes" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "withProximityFilter" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "withProximityFilter" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "itemRegion" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "itemRegion" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "itemRegionRelation" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "itemRegionRelation" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "currency" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "userCurrency" },
                      },
                    },
                  ],
                },
              },
            ],
            directives: [
              {
                kind: "Directive",
                name: { kind: "Name", value: "skip" },
                arguments: [
                  {
                    kind: "Argument",
                    name: { kind: "Name", value: "if" },
                    value: {
                      kind: "Variable",
                      name: {
                        kind: "Name",
                        value: "shouldHideBumpAndSponsoredListings",
                      },
                    },
                  },
                ],
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "listing" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      {
                        kind: "FragmentSpread",
                        name: { kind: "Name", value: "ListingData" },
                      },
                      {
                        kind: "FragmentSpread",
                        name: { kind: "Name", value: "ListingsCollection" },
                      },
                      {
                        kind: "FragmentSpread",
                        name: { kind: "Name", value: "mParticleListingFields" },
                        directives: [
                          {
                            kind: "Directive",
                            name: { kind: "Name", value: "skip" },
                            arguments: [
                              {
                                kind: "Argument",
                                name: { kind: "Name", value: "if" },
                                value: {
                                  kind: "Variable",
                                  name: {
                                    kind: "Name",
                                    value: "shouldSkipTracking",
                                  },
                                },
                              },
                            ],
                          },
                        ],
                      },
                      {
                        kind: "FragmentSpread",
                        name: { kind: "Name", value: "FullFinancingFields" },
                        directives: [
                          {
                            kind: "Directive",
                            name: { kind: "Name", value: "include" },
                            arguments: [
                              {
                                kind: "Argument",
                                name: { kind: "Name", value: "if" },
                                value: {
                                  kind: "Variable",
                                  name: {
                                    kind: "Name",
                                    value: "includeFullFinancingFields",
                                  },
                                },
                              },
                            ],
                          },
                        ],
                      },
                      {
                        kind: "FragmentSpread",
                        name: { kind: "Name", value: "BumpKey" },
                      },
                      {
                        kind: "FragmentSpread",
                        name: { kind: "Name", value: "ListingGreatValueData" },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "runnerUp" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "listing" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "_id" },
                            },
                            {
                              kind: "FragmentSpread",
                              name: { kind: "Name", value: "ListingData" },
                            },
                            {
                              kind: "FragmentSpread",
                              name: {
                                kind: "Name",
                                value: "ListingsCollection",
                              },
                            },
                            {
                              kind: "FragmentSpread",
                              name: {
                                kind: "Name",
                                value: "mParticleListingFields",
                              },
                              directives: [
                                {
                                  kind: "Directive",
                                  name: { kind: "Name", value: "skip" },
                                  arguments: [
                                    {
                                      kind: "Argument",
                                      name: { kind: "Name", value: "if" },
                                      value: {
                                        kind: "Variable",
                                        name: {
                                          kind: "Name",
                                          value: "shouldSkipTracking",
                                        },
                                      },
                                    },
                                  ],
                                },
                              ],
                            },
                            {
                              kind: "FragmentSpread",
                              name: {
                                kind: "Name",
                                value: "FullFinancingFields",
                              },
                              directives: [
                                {
                                  kind: "Directive",
                                  name: { kind: "Name", value: "include" },
                                  arguments: [
                                    {
                                      kind: "Argument",
                                      name: { kind: "Name", value: "if" },
                                      value: {
                                        kind: "Variable",
                                        name: {
                                          kind: "Name",
                                          value: "includeFullFinancingFields",
                                        },
                                      },
                                    },
                                  ],
                                },
                              ],
                            },
                            {
                              kind: "FragmentSpread",
                              name: { kind: "Name", value: "BumpKey" },
                            },
                            {
                              kind: "FragmentSpread",
                              name: {
                                kind: "Name",
                                value: "ListingGreatValueData",
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "runnerUp" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "listing" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "_id" },
                                  },
                                  {
                                    kind: "FragmentSpread",
                                    name: {
                                      kind: "Name",
                                      value: "ListingData",
                                    },
                                  },
                                  {
                                    kind: "FragmentSpread",
                                    name: {
                                      kind: "Name",
                                      value: "ListingsCollection",
                                    },
                                  },
                                  {
                                    kind: "FragmentSpread",
                                    name: {
                                      kind: "Name",
                                      value: "mParticleListingFields",
                                    },
                                    directives: [
                                      {
                                        kind: "Directive",
                                        name: { kind: "Name", value: "skip" },
                                        arguments: [
                                          {
                                            kind: "Argument",
                                            name: { kind: "Name", value: "if" },
                                            value: {
                                              kind: "Variable",
                                              name: {
                                                kind: "Name",
                                                value: "shouldSkipTracking",
                                              },
                                            },
                                          },
                                        ],
                                      },
                                    ],
                                  },
                                  {
                                    kind: "FragmentSpread",
                                    name: {
                                      kind: "Name",
                                      value: "FullFinancingFields",
                                    },
                                    directives: [
                                      {
                                        kind: "Directive",
                                        name: {
                                          kind: "Name",
                                          value: "include",
                                        },
                                        arguments: [
                                          {
                                            kind: "Argument",
                                            name: { kind: "Name", value: "if" },
                                            value: {
                                              kind: "Variable",
                                              name: {
                                                kind: "Name",
                                                value:
                                                  "includeFullFinancingFields",
                                              },
                                            },
                                          },
                                        ],
                                      },
                                    ],
                                  },
                                  {
                                    kind: "FragmentSpread",
                                    name: { kind: "Name", value: "BumpKey" },
                                  },
                                  {
                                    kind: "FragmentSpread",
                                    name: {
                                      kind: "Name",
                                      value: "ListingGreatValueData",
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            alias: { kind: "Name", value: "finishAgg" },
            name: { kind: "Name", value: "listingsAggregationSearch" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "withAggregations" },
                      value: { kind: "EnumValue", value: "CANONICAL_FINISH" },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "cspSlug" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "cspSlug" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "traitValues" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "traitValues" },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "aggregationResults" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "name" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "aggregationDetails" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "key" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "count" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            alias: { kind: "Name", value: "fretboardMaterialAgg" },
            name: { kind: "Name", value: "listingsAggregationSearch" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "canonicalFinishes" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "canonicalFinishes" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "withAggregations" },
                      value: { kind: "EnumValue", value: "TRAITS" },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "cspSlug" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "cspSlug" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "traitValues" },
                      value: {
                        kind: "ListValue",
                        values: [
                          {
                            kind: "Variable",
                            name: { kind: "Name", value: "fretboardMaterial" },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "aggregationResults" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "name" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "displayName" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "aggregationDetails" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "key" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "displayName" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "count" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "SellerInfoData" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Listing" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "seller" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "feedbackSummary" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "receivedCount" },
                      },
                      {
                        kind: "Field",
                        name: {
                          kind: "Name",
                          value: "rollingRatingPercentage",
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "shop" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "name" } },
                { kind: "Field", name: { kind: "Name", value: "slug" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "preferredSeller" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "quickShipper" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "quickResponder" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "address" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "displayLocation" },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "ListingForBuyerShippingFields" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Listing" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "shipping" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "freeExpeditedShipping" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "localPickupOnly" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "shippingPrices" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "shippingMethod" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "carrierCalculated" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "regional" },
                      },
                      {
                        kind: "Field",
                        name: {
                          kind: "Name",
                          value: "destinationPostalCodeNeeded",
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "postalCode" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "rate" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "amount" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "amountCents" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "currency" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "display" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "ListingGreatValueData" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Listing" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "pricing" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "buyerPrice" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "currency" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "amountCents" },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "condition" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "conditionSlug" },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "priceRecommendation" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "priceMiddle" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "amountCents" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "currency" },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "shop" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "address" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "country" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "_id" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "countryCode" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          { kind: "Field", name: { kind: "Name", value: "currency" } },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "ListingCardCPOData" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Listing" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "certifiedPreOwned" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "title" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "badgeIconUrl" },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "ListingForBuyerFields" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Listing" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "title" } },
          { kind: "Field", name: { kind: "Name", value: "slug" } },
          { kind: "Field", name: { kind: "Name", value: "listingType" } },
          { kind: "Field", name: { kind: "Name", value: "make" } },
          { kind: "Field", name: { kind: "Name", value: "model" } },
          { kind: "Field", name: { kind: "Name", value: "upc" } },
          { kind: "Field", name: { kind: "Name", value: "state" } },
          { kind: "Field", name: { kind: "Name", value: "stateDescription" } },
          { kind: "Field", name: { kind: "Name", value: "bumped" } },
          { kind: "Field", name: { kind: "Name", value: "watching" } },
          { kind: "Field", name: { kind: "Name", value: "soldAsIs" } },
          { kind: "Field", name: { kind: "Name", value: "usOutlet" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "publishedAt" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "seconds" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "condition" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "displayName" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "conditionSlug" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "conditionUuid" },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "pricing" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "buyerPrice" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "display" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "currency" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "amount" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "amountCents" },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "originalPrice" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "display" },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "ribbon" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "display" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "reason" },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "images" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "transform" },
                      value: {
                        kind: "StringValue",
                        value: "card_square",
                        block: false,
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "count" },
                      value: { kind: "IntValue", value: "3" },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "scope" },
                      value: {
                        kind: "StringValue",
                        value: "photos",
                        block: false,
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "type" },
                      value: {
                        kind: "StringValue",
                        value: "Product",
                        block: false,
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "source" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "shipping" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "shippingPrices" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "shippingMethod" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "carrierCalculated" },
                      },
                      {
                        kind: "Field",
                        name: {
                          kind: "Name",
                          value: "destinationPostalCodeNeeded",
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "rate" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "amount" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "amountCents" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "currency" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "display" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "freeExpeditedShipping" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "localPickupOnly" },
                },
                { kind: "Field", name: { kind: "Name", value: "localPickup" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "shop" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "name" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "returnPolicy" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "usedReturnWindowDays" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "newReturnWindowDays" },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "address" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "locality" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "region" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "country" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "_id" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "countryCode" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "name" },
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "displayLocation" },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "ListingForBuyerShippingFields" },
          },
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "ListingGreatValueData" },
          },
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "ListingCardCPOData" },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "WatchBadgeData" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Listing" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "title" } },
          { kind: "Field", name: { kind: "Name", value: "sellerId" } },
          { kind: "Field", name: { kind: "Name", value: "watching" } },
          {
            kind: "Field",
            alias: { kind: "Name", value: "watchThumbnails" },
            name: { kind: "Name", value: "images" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "type" },
                      value: {
                        kind: "StringValue",
                        value: "Product",
                        block: false,
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "scope" },
                      value: {
                        kind: "StringValue",
                        value: "photos",
                        block: false,
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "transform" },
                      value: {
                        kind: "StringValue",
                        value: "card_square",
                        block: false,
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "count" },
                      value: { kind: "IntValue", value: "1" },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "source" } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "mParticleListingFields" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Listing" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "title" } },
          { kind: "Field", name: { kind: "Name", value: "brandSlug" } },
          { kind: "Field", name: { kind: "Name", value: "categoryRootUuid" } },
          { kind: "Field", name: { kind: "Name", value: "make" } },
          { kind: "Field", name: { kind: "Name", value: "categoryUuids" } },
          { kind: "Field", name: { kind: "Name", value: "state" } },
          { kind: "Field", name: { kind: "Name", value: "listingType" } },
          { kind: "Field", name: { kind: "Name", value: "bumpEligible" } },
          { kind: "Field", name: { kind: "Name", value: "shopId" } },
          { kind: "Field", name: { kind: "Name", value: "inventory" } },
          { kind: "Field", name: { kind: "Name", value: "soldAsIs" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "acceptedPaymentMethods" },
          },
          { kind: "Field", name: { kind: "Name", value: "currency" } },
          { kind: "Field", name: { kind: "Name", value: "usOutlet" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "condition" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "conditionUuid" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "conditionSlug" },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "categories" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "slug" } },
                { kind: "Field", name: { kind: "Name", value: "rootSlug" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "csp" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "slug" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "brand" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      { kind: "Field", name: { kind: "Name", value: "slug" } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "pricing" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "buyerPrice" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "amount" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "currency" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "amountCents" },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "publishedAt" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "seconds" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "sale" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "code" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "buyerIneligibilityReason" },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "shipping" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "shippingPrices" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "shippingMethod" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "carrierCalculated" },
                      },
                      {
                        kind: "Field",
                        name: {
                          kind: "Name",
                          value: "destinationPostalCodeNeeded",
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "rate" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "amount" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "amountCents" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "currency" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "display" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "freeExpeditedShipping" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "localPickupOnly" },
                },
                { kind: "Field", name: { kind: "Name", value: "localPickup" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "certifiedPreOwned" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "title" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "badgeIconUrl" },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "shop" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "address" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "countryCode" },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "returnPolicy" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "newReturnWindowDays" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "usedReturnWindowDays" },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "ListingCreateOfferButtonData" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Listing" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "state" } },
          { kind: "Field", name: { kind: "Name", value: "offersEnabled" } },
          { kind: "Field", name: { kind: "Name", value: "listingType" } },
          { kind: "Field", name: { kind: "Name", value: "sellerId" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "isBuyerOfferEligible" },
          },
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "mParticleListingFields" },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "ListingCardFields" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Listing" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "ListingForBuyerFields" },
          },
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "WatchBadgeData" },
          },
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "ListingCreateOfferButtonData" },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "AddToCartButtonFields" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Listing" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "sellerId" } },
          { kind: "Field", name: { kind: "Name", value: "listingType" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "pricing" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "buyerPrice" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "amount" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "amountCents" },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "preorderInfo" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "onPreorder" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "estimatedShipDate" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "seconds" },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "ListingImagesFields" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Listing" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "images" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "transform" },
                      value: {
                        kind: "StringValue",
                        value: "card_square",
                        block: false,
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "count" },
                      value: { kind: "IntValue", value: "3" },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "scope" },
                      value: {
                        kind: "StringValue",
                        value: "photos",
                        block: false,
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "type" },
                      value: {
                        kind: "StringValue",
                        value: "Product",
                        block: false,
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "source" } },
              ],
            },
          },
          {
            kind: "Field",
            alias: { kind: "Name", value: "largeSquareImages" },
            name: { kind: "Name", value: "images" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "transform" },
                      value: {
                        kind: "StringValue",
                        value: "large_square",
                        block: false,
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "count" },
                      value: { kind: "IntValue", value: "3" },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "scope" },
                      value: {
                        kind: "StringValue",
                        value: "photos",
                        block: false,
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "type" },
                      value: {
                        kind: "StringValue",
                        value: "Product",
                        block: false,
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "source" } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "NestedFilter" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "reverb_search_Filter" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "name" } },
          { kind: "Field", name: { kind: "Name", value: "key" } },
          { kind: "Field", name: { kind: "Name", value: "aggregationName" } },
          { kind: "Field", name: { kind: "Name", value: "widgetType" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "options" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "count" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "value" } },
                    ],
                  },
                },
                { kind: "Field", name: { kind: "Name", value: "name" } },
                { kind: "Field", name: { kind: "Name", value: "selected" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "autoselected" },
                },
                { kind: "Field", name: { kind: "Name", value: "paramName" } },
                { kind: "Field", name: { kind: "Name", value: "setValues" } },
                { kind: "Field", name: { kind: "Name", value: "unsetValues" } },
                { kind: "Field", name: { kind: "Name", value: "all" } },
                { kind: "Field", name: { kind: "Name", value: "optionValue" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "trackingValue" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "subFilter" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "widgetType" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "options" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "count" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "value" },
                                  },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "name" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "selected" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "autoselected" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "paramName" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "setValues" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "unsetValues" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "all" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "optionValue" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "trackingValue" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "subFilter" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "widgetType" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "options" },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "count",
                                          },
                                          selectionSet: {
                                            kind: "SelectionSet",
                                            selections: [
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "value",
                                                },
                                              },
                                            ],
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "name" },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "selected",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "autoselected",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "paramName",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "setValues",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "unsetValues",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "all" },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "optionValue",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "trackingValue",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "subFilter",
                                          },
                                          selectionSet: {
                                            kind: "SelectionSet",
                                            selections: [
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "widgetType",
                                                },
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "options",
                                                },
                                                selectionSet: {
                                                  kind: "SelectionSet",
                                                  selections: [
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "count",
                                                      },
                                                      selectionSet: {
                                                        kind: "SelectionSet",
                                                        selections: [
                                                          {
                                                            kind: "Field",
                                                            name: {
                                                              kind: "Name",
                                                              value: "value",
                                                            },
                                                          },
                                                        ],
                                                      },
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "name",
                                                      },
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "selected",
                                                      },
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "autoselected",
                                                      },
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "paramName",
                                                      },
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "setValues",
                                                      },
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "unsetValues",
                                                      },
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "all",
                                                      },
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "optionValue",
                                                      },
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "trackingValue",
                                                      },
                                                    },
                                                  ],
                                                },
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "ListingData" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Listing" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "upc" } },
          { kind: "Field", name: { kind: "Name", value: "finish" } },
          { kind: "Field", name: { kind: "Name", value: "model" } },
          { kind: "Field", name: { kind: "Name", value: "shopUuid" } },
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "SellerInfoData" },
          },
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "ListingCardFields" },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "ListingsCollection" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Listing" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "usOutlet" } },
          { kind: "Field", name: { kind: "Name", value: "bumped" } },
          { kind: "Field", name: { kind: "Name", value: "categoryUuids" } },
          { kind: "Field", name: { kind: "Name", value: "slug" } },
          { kind: "Field", name: { kind: "Name", value: "title" } },
          { kind: "Field", name: { kind: "Name", value: "description" } },
          { kind: "Field", name: { kind: "Name", value: "listingType" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "condition" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "displayName" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "conditionSlug" },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "price" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "amountCents" } },
                { kind: "Field", name: { kind: "Name", value: "display" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "pricing" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "buyerPrice" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "display" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "currency" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "amountCents" },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "originalPrice" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "display" },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "ribbon" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "display" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "reason" },
                      },
                    ],
                  },
                },
              ],
            },
          },
          { kind: "Field", name: { kind: "Name", value: "watching" } },
          { kind: "Field", name: { kind: "Name", value: "state" } },
          { kind: "Field", name: { kind: "Name", value: "stateDescription" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "shipping" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "shippingPrices" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "shippingMethod" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "carrierCalculated" },
                      },
                      {
                        kind: "Field",
                        name: {
                          kind: "Name",
                          value: "destinationPostalCodeNeeded",
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "rate" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "amount" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "amountCents" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "currency" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "display" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "freeExpeditedShipping" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "localPickupOnly" },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "shop" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "address" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "country" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "_id" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "countryCode" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "name" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "AddToCartButtonFields" },
          },
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "WatchBadgeData" },
          },
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "ListingGreatValueData" },
          },
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "ListingCreateOfferButtonData" },
          },
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "ListingImagesFields" },
          },
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "ListingCardCPOData" },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "FullFinancingFields" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Listing" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "estimatedMonthlyPaymentPlan" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "financingProgramSlug" },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "pricing" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "buyerPrice" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "amountCents" },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "BumpKey" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Listing" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "bumpKey" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "key" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Csp_Filters_And_ListingsQueryQuery,
  Csp_Filters_And_ListingsQueryQueryVariables
>;
export const Core_Csp_LayoutQueryDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "Core_Csp_LayoutQuery" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "auctionType" },
          },
          type: {
            kind: "NamedType",
            name: { kind: "Name", value: "arbiter_AuctionType" },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "canonicalFinish" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "cspSlug" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
        },
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "hfid" } },
          type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "hfidPresent" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "Boolean" },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "includeFullFinancingFields" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "Boolean" },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "itemRegion" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "shippingRegionCode" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "shouldSkipTracking" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "Boolean" },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "showListingLocation" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "Boolean" },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "traitValues" },
          },
          type: {
            kind: "ListType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "userCurrency" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "useNewBreadcrumbs" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "Boolean" },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "applyProximityBoost" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "Boolean" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "csp" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "slug" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "cspSlug" },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "title" } },
                { kind: "Field", name: { kind: "Name", value: "summary" } },
                { kind: "Field", name: { kind: "Name", value: "body" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "metaDescription" },
                },
                { kind: "Field", name: { kind: "Name", value: "model" } },
                { kind: "Field", name: { kind: "Name", value: "generic" } },
                { kind: "Field", name: { kind: "Name", value: "finishes" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "brand" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      { kind: "Field", name: { kind: "Name", value: "name" } },
                      { kind: "Field", name: { kind: "Name", value: "slug" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "webLink" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "href" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "categories" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      { kind: "Field", name: { kind: "Name", value: "name" } },
                      { kind: "Field", name: { kind: "Name", value: "slug" } },
                      { kind: "Field", name: { kind: "Name", value: "root" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "rootSlug" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "webLink" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "href" },
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "cmsUrl" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "href" },
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "parentCategories" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "_id" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "name" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "slug" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "rootSlug" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "webLink" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "href" },
                                  },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "cmsUrl" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "href" },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "videos" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "videoLinks" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "youtubeLink" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "youtubeVideoId" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "specs" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "productSpecs" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "_id" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "type" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "name" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "path" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "value" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "breadcrumbs" },
                  directives: [
                    {
                      kind: "Directive",
                      name: { kind: "Name", value: "include" },
                      arguments: [
                        {
                          kind: "Argument",
                          name: { kind: "Name", value: "if" },
                          value: {
                            kind: "Variable",
                            name: { kind: "Name", value: "useNewBreadcrumbs" },
                          },
                        },
                      ],
                    },
                  ],
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "title" } },
                      { kind: "Field", name: { kind: "Name", value: "url" } },
                    ],
                  },
                },
                {
                  kind: "Field",
                  alias: { kind: "Name", value: "supersize_images" },
                  name: { kind: "Name", value: "images" },
                  arguments: [
                    {
                      kind: "Argument",
                      name: { kind: "Name", value: "input" },
                      value: {
                        kind: "ObjectValue",
                        fields: [
                          {
                            kind: "ObjectField",
                            name: { kind: "Name", value: "transform" },
                            value: {
                              kind: "StringValue",
                              value: "supersize",
                              block: false,
                            },
                          },
                          {
                            kind: "ObjectField",
                            name: { kind: "Name", value: "scope" },
                            value: {
                              kind: "StringValue",
                              value: "photos",
                              block: false,
                            },
                          },
                          {
                            kind: "ObjectField",
                            name: { kind: "Name", value: "limit" },
                            value: { kind: "IntValue", value: "20" },
                          },
                        ],
                      },
                    },
                  ],
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "source" },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  alias: { kind: "Name", value: "large_images" },
                  name: { kind: "Name", value: "images" },
                  arguments: [
                    {
                      kind: "Argument",
                      name: { kind: "Name", value: "input" },
                      value: {
                        kind: "ObjectValue",
                        fields: [
                          {
                            kind: "ObjectField",
                            name: { kind: "Name", value: "transform" },
                            value: {
                              kind: "StringValue",
                              value: "large",
                              block: false,
                            },
                          },
                          {
                            kind: "ObjectField",
                            name: { kind: "Name", value: "scope" },
                            value: {
                              kind: "StringValue",
                              value: "photos",
                              block: false,
                            },
                          },
                          {
                            kind: "ObjectField",
                            name: { kind: "Name", value: "limit" },
                            value: { kind: "IntValue", value: "20" },
                          },
                        ],
                      },
                    },
                  ],
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "source" },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  alias: { kind: "Name", value: "hero_image" },
                  name: { kind: "Name", value: "images" },
                  arguments: [
                    {
                      kind: "Argument",
                      name: { kind: "Name", value: "input" },
                      value: {
                        kind: "ObjectValue",
                        fields: [
                          {
                            kind: "ObjectField",
                            name: { kind: "Name", value: "transform" },
                            value: {
                              kind: "StringValue",
                              value: "banner_wide",
                              block: false,
                            },
                          },
                          {
                            kind: "ObjectField",
                            name: { kind: "Name", value: "scope" },
                            value: {
                              kind: "StringValue",
                              value: "hero_image",
                              block: false,
                            },
                          },
                          {
                            kind: "ObjectField",
                            name: { kind: "Name", value: "limit" },
                            value: { kind: "IntValue", value: "1" },
                          },
                        ],
                      },
                    },
                  ],
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "source" },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  alias: { kind: "Name", value: "cspImage" },
                  name: { kind: "Name", value: "images" },
                  arguments: [
                    {
                      kind: "Argument",
                      name: { kind: "Name", value: "input" },
                      value: {
                        kind: "ObjectValue",
                        fields: [
                          {
                            kind: "ObjectField",
                            name: { kind: "Name", value: "transform" },
                            value: {
                              kind: "StringValue",
                              value: "card_square",
                              block: false,
                            },
                          },
                          {
                            kind: "ObjectField",
                            name: { kind: "Name", value: "scope" },
                            value: {
                              kind: "StringValue",
                              value: "photos",
                              block: false,
                            },
                          },
                          {
                            kind: "ObjectField",
                            name: { kind: "Name", value: "limit" },
                            value: { kind: "IntValue", value: "1" },
                          },
                        ],
                      },
                    },
                  ],
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "source" },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "canonicalProducts" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      { kind: "Field", name: { kind: "Name", value: "name" } },
                    ],
                  },
                },
                {
                  kind: "FragmentSpread",
                  name: { kind: "Name", value: "CSPAdditionalTrackingFields" },
                },
                {
                  kind: "FragmentSpread",
                  name: { kind: "Name", value: "CSPReviewJumplinksData" },
                },
                {
                  kind: "FragmentSpread",
                  name: { kind: "Name", value: "FeaturedListingCSP" },
                },
              ],
            },
          },
          {
            kind: "Field",
            alias: { kind: "Name", value: "featuredListing" },
            name: { kind: "Name", value: "featuredListing" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "cspSlug" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "cspSlug" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "userShippingRegion" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "shippingRegionCode" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "canonicalFinish" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "canonicalFinish" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "traitValues" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "traitValues" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "itemRegion" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "itemRegion" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "auctionType" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "auctionType" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "userCurrency" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "userCurrency" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "applyProximityBoost" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "applyProximityBoost" },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "listing" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      {
                        kind: "FragmentSpread",
                        name: { kind: "Name", value: "ListingData" },
                      },
                      {
                        kind: "FragmentSpread",
                        name: { kind: "Name", value: "ListingsCollection" },
                      },
                      {
                        kind: "FragmentSpread",
                        name: { kind: "Name", value: "mParticleListingFields" },
                        directives: [
                          {
                            kind: "Directive",
                            name: { kind: "Name", value: "skip" },
                            arguments: [
                              {
                                kind: "Argument",
                                name: { kind: "Name", value: "if" },
                                value: {
                                  kind: "Variable",
                                  name: {
                                    kind: "Name",
                                    value: "shouldSkipTracking",
                                  },
                                },
                              },
                            ],
                          },
                        ],
                      },
                      {
                        kind: "FragmentSpread",
                        name: { kind: "Name", value: "FullFinancingFields" },
                        directives: [
                          {
                            kind: "Directive",
                            name: { kind: "Name", value: "include" },
                            arguments: [
                              {
                                kind: "Argument",
                                name: { kind: "Name", value: "if" },
                                value: {
                                  kind: "Variable",
                                  name: {
                                    kind: "Name",
                                    value: "includeFullFinancingFields",
                                  },
                                },
                              },
                            ],
                          },
                        ],
                      },
                      {
                        kind: "FragmentSpread",
                        name: { kind: "Name", value: "ShopFields" },
                        directives: [
                          {
                            kind: "Directive",
                            name: { kind: "Name", value: "include" },
                            arguments: [
                              {
                                kind: "Argument",
                                name: { kind: "Name", value: "if" },
                                value: {
                                  kind: "Variable",
                                  name: {
                                    kind: "Name",
                                    value: "showListingLocation",
                                  },
                                },
                              },
                            ],
                          },
                        ],
                      },
                      {
                        kind: "FragmentSpread",
                        name: { kind: "Name", value: "BumpKey" },
                      },
                      {
                        kind: "FragmentSpread",
                        name: {
                          kind: "Name",
                          value: "FeaturedListingWithPrice",
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            alias: { kind: "Name", value: "hfid" },
            name: { kind: "Name", value: "listing" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "id" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "hfid" },
                      },
                    },
                  ],
                },
              },
            ],
            directives: [
              {
                kind: "Directive",
                name: { kind: "Name", value: "include" },
                arguments: [
                  {
                    kind: "Argument",
                    name: { kind: "Name", value: "if" },
                    value: {
                      kind: "Variable",
                      name: { kind: "Name", value: "hfidPresent" },
                    },
                  },
                ],
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                {
                  kind: "FragmentSpread",
                  name: { kind: "Name", value: "ListingData" },
                },
                {
                  kind: "FragmentSpread",
                  name: { kind: "Name", value: "ListingsCollection" },
                },
                {
                  kind: "FragmentSpread",
                  name: { kind: "Name", value: "mParticleListingFields" },
                  directives: [
                    {
                      kind: "Directive",
                      name: { kind: "Name", value: "skip" },
                      arguments: [
                        {
                          kind: "Argument",
                          name: { kind: "Name", value: "if" },
                          value: {
                            kind: "Variable",
                            name: { kind: "Name", value: "shouldSkipTracking" },
                          },
                        },
                      ],
                    },
                  ],
                },
                {
                  kind: "FragmentSpread",
                  name: { kind: "Name", value: "FullFinancingFields" },
                  directives: [
                    {
                      kind: "Directive",
                      name: { kind: "Name", value: "include" },
                      arguments: [
                        {
                          kind: "Argument",
                          name: { kind: "Name", value: "if" },
                          value: {
                            kind: "Variable",
                            name: {
                              kind: "Name",
                              value: "includeFullFinancingFields",
                            },
                          },
                        },
                      ],
                    },
                  ],
                },
                {
                  kind: "FragmentSpread",
                  name: { kind: "Name", value: "BumpKey" },
                },
                {
                  kind: "FragmentSpread",
                  name: { kind: "Name", value: "FeaturedListingWithPrice" },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "SellerInfoData" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Listing" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "seller" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "feedbackSummary" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "receivedCount" },
                      },
                      {
                        kind: "Field",
                        name: {
                          kind: "Name",
                          value: "rollingRatingPercentage",
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "shop" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "name" } },
                { kind: "Field", name: { kind: "Name", value: "slug" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "preferredSeller" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "quickShipper" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "quickResponder" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "address" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "displayLocation" },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "ListingForBuyerShippingFields" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Listing" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "shipping" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "freeExpeditedShipping" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "localPickupOnly" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "shippingPrices" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "shippingMethod" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "carrierCalculated" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "regional" },
                      },
                      {
                        kind: "Field",
                        name: {
                          kind: "Name",
                          value: "destinationPostalCodeNeeded",
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "postalCode" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "rate" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "amount" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "amountCents" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "currency" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "display" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "ListingGreatValueData" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Listing" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "pricing" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "buyerPrice" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "currency" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "amountCents" },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "condition" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "conditionSlug" },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "priceRecommendation" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "priceMiddle" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "amountCents" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "currency" },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "shop" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "address" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "country" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "_id" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "countryCode" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          { kind: "Field", name: { kind: "Name", value: "currency" } },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "ListingCardCPOData" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Listing" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "certifiedPreOwned" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "title" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "badgeIconUrl" },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "ListingForBuyerFields" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Listing" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "title" } },
          { kind: "Field", name: { kind: "Name", value: "slug" } },
          { kind: "Field", name: { kind: "Name", value: "listingType" } },
          { kind: "Field", name: { kind: "Name", value: "make" } },
          { kind: "Field", name: { kind: "Name", value: "model" } },
          { kind: "Field", name: { kind: "Name", value: "upc" } },
          { kind: "Field", name: { kind: "Name", value: "state" } },
          { kind: "Field", name: { kind: "Name", value: "stateDescription" } },
          { kind: "Field", name: { kind: "Name", value: "bumped" } },
          { kind: "Field", name: { kind: "Name", value: "watching" } },
          { kind: "Field", name: { kind: "Name", value: "soldAsIs" } },
          { kind: "Field", name: { kind: "Name", value: "usOutlet" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "publishedAt" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "seconds" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "condition" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "displayName" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "conditionSlug" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "conditionUuid" },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "pricing" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "buyerPrice" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "display" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "currency" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "amount" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "amountCents" },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "originalPrice" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "display" },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "ribbon" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "display" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "reason" },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "images" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "transform" },
                      value: {
                        kind: "StringValue",
                        value: "card_square",
                        block: false,
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "count" },
                      value: { kind: "IntValue", value: "3" },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "scope" },
                      value: {
                        kind: "StringValue",
                        value: "photos",
                        block: false,
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "type" },
                      value: {
                        kind: "StringValue",
                        value: "Product",
                        block: false,
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "source" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "shipping" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "shippingPrices" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "shippingMethod" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "carrierCalculated" },
                      },
                      {
                        kind: "Field",
                        name: {
                          kind: "Name",
                          value: "destinationPostalCodeNeeded",
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "rate" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "amount" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "amountCents" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "currency" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "display" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "freeExpeditedShipping" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "localPickupOnly" },
                },
                { kind: "Field", name: { kind: "Name", value: "localPickup" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "shop" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "name" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "returnPolicy" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "usedReturnWindowDays" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "newReturnWindowDays" },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "address" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "locality" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "region" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "country" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "_id" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "countryCode" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "name" },
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "displayLocation" },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "ListingForBuyerShippingFields" },
          },
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "ListingGreatValueData" },
          },
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "ListingCardCPOData" },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "WatchBadgeData" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Listing" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "title" } },
          { kind: "Field", name: { kind: "Name", value: "sellerId" } },
          { kind: "Field", name: { kind: "Name", value: "watching" } },
          {
            kind: "Field",
            alias: { kind: "Name", value: "watchThumbnails" },
            name: { kind: "Name", value: "images" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "type" },
                      value: {
                        kind: "StringValue",
                        value: "Product",
                        block: false,
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "scope" },
                      value: {
                        kind: "StringValue",
                        value: "photos",
                        block: false,
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "transform" },
                      value: {
                        kind: "StringValue",
                        value: "card_square",
                        block: false,
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "count" },
                      value: { kind: "IntValue", value: "1" },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "source" } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "mParticleListingFields" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Listing" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "title" } },
          { kind: "Field", name: { kind: "Name", value: "brandSlug" } },
          { kind: "Field", name: { kind: "Name", value: "categoryRootUuid" } },
          { kind: "Field", name: { kind: "Name", value: "make" } },
          { kind: "Field", name: { kind: "Name", value: "categoryUuids" } },
          { kind: "Field", name: { kind: "Name", value: "state" } },
          { kind: "Field", name: { kind: "Name", value: "listingType" } },
          { kind: "Field", name: { kind: "Name", value: "bumpEligible" } },
          { kind: "Field", name: { kind: "Name", value: "shopId" } },
          { kind: "Field", name: { kind: "Name", value: "inventory" } },
          { kind: "Field", name: { kind: "Name", value: "soldAsIs" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "acceptedPaymentMethods" },
          },
          { kind: "Field", name: { kind: "Name", value: "currency" } },
          { kind: "Field", name: { kind: "Name", value: "usOutlet" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "condition" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "conditionUuid" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "conditionSlug" },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "categories" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "slug" } },
                { kind: "Field", name: { kind: "Name", value: "rootSlug" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "csp" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "slug" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "brand" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      { kind: "Field", name: { kind: "Name", value: "slug" } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "pricing" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "buyerPrice" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "amount" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "currency" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "amountCents" },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "publishedAt" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "seconds" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "sale" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "code" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "buyerIneligibilityReason" },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "shipping" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "shippingPrices" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "shippingMethod" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "carrierCalculated" },
                      },
                      {
                        kind: "Field",
                        name: {
                          kind: "Name",
                          value: "destinationPostalCodeNeeded",
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "rate" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "amount" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "amountCents" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "currency" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "display" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "freeExpeditedShipping" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "localPickupOnly" },
                },
                { kind: "Field", name: { kind: "Name", value: "localPickup" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "certifiedPreOwned" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "title" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "badgeIconUrl" },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "shop" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "address" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "countryCode" },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "returnPolicy" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "newReturnWindowDays" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "usedReturnWindowDays" },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "ListingCreateOfferButtonData" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Listing" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "state" } },
          { kind: "Field", name: { kind: "Name", value: "offersEnabled" } },
          { kind: "Field", name: { kind: "Name", value: "listingType" } },
          { kind: "Field", name: { kind: "Name", value: "sellerId" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "isBuyerOfferEligible" },
          },
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "mParticleListingFields" },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "ListingCardFields" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Listing" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "ListingForBuyerFields" },
          },
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "WatchBadgeData" },
          },
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "ListingCreateOfferButtonData" },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "AddToCartButtonFields" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Listing" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "sellerId" } },
          { kind: "Field", name: { kind: "Name", value: "listingType" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "pricing" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "buyerPrice" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "amount" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "amountCents" },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "preorderInfo" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "onPreorder" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "estimatedShipDate" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "seconds" },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "ListingImagesFields" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Listing" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "images" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "transform" },
                      value: {
                        kind: "StringValue",
                        value: "card_square",
                        block: false,
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "count" },
                      value: { kind: "IntValue", value: "3" },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "scope" },
                      value: {
                        kind: "StringValue",
                        value: "photos",
                        block: false,
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "type" },
                      value: {
                        kind: "StringValue",
                        value: "Product",
                        block: false,
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "source" } },
              ],
            },
          },
          {
            kind: "Field",
            alias: { kind: "Name", value: "largeSquareImages" },
            name: { kind: "Name", value: "images" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "transform" },
                      value: {
                        kind: "StringValue",
                        value: "large_square",
                        block: false,
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "count" },
                      value: { kind: "IntValue", value: "3" },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "scope" },
                      value: {
                        kind: "StringValue",
                        value: "photos",
                        block: false,
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "type" },
                      value: {
                        kind: "StringValue",
                        value: "Product",
                        block: false,
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "source" } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "ItemImageFields" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Listing" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "title" } },
          { kind: "Field", name: { kind: "Name", value: "state" } },
          {
            kind: "Field",
            alias: { kind: "Name", value: "largeImages" },
            name: { kind: "Name", value: "images" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "type" },
                      value: {
                        kind: "StringValue",
                        value: "Product",
                        block: false,
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "scope" },
                      value: {
                        kind: "StringValue",
                        value: "photos",
                        block: false,
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "transform" },
                      value: {
                        kind: "StringValue",
                        value: "large",
                        block: false,
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "count" },
                      value: { kind: "IntValue", value: "25" },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "source" } },
              ],
            },
          },
          {
            kind: "Field",
            alias: { kind: "Name", value: "supersizeImages" },
            name: { kind: "Name", value: "images" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "type" },
                      value: {
                        kind: "StringValue",
                        value: "Product",
                        block: false,
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "scope" },
                      value: {
                        kind: "StringValue",
                        value: "photos",
                        block: false,
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "transform" },
                      value: {
                        kind: "StringValue",
                        value: "supersize",
                        block: false,
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "count" },
                      value: { kind: "IntValue", value: "25" },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "source" } },
              ],
            },
          },
          {
            kind: "Field",
            alias: { kind: "Name", value: "cardSquareImages" },
            name: { kind: "Name", value: "images" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "type" },
                      value: {
                        kind: "StringValue",
                        value: "Product",
                        block: false,
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "scope" },
                      value: {
                        kind: "StringValue",
                        value: "photos",
                        block: false,
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "transform" },
                      value: {
                        kind: "StringValue",
                        value: "card_square",
                        block: false,
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "count" },
                      value: { kind: "IntValue", value: "25" },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "source" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "video" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "youtubeVideoId" },
                },
                { kind: "Field", name: { kind: "Name", value: "empty" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "csp" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "videos" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "videoLinks" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "youtubeVideoId" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "FeaturedListingShopInfoData" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Listing" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "state" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "seller" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "uuid" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "feedbackSummary" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: {
                          kind: "Name",
                          value: "rollingRatingPercentage",
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "receivedCount" },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "shop" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "name" } },
                { kind: "Field", name: { kind: "Name", value: "slug" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "preferredSeller" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "quickShipper" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "quickResponder" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "address" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "displayLocation" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "countryCode" },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "paymentPolicy" },
                },
                { kind: "Field", name: { kind: "Name", value: "createdAt" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "orders" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "total" } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "shipping" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "localPickupOnly" },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "ListingShippingDisplayData" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Listing" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "shop" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "freeDomesticShippingRate" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "regionCode" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "freeShippingThreshold" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "amountCents" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "currency" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "ListingForBuyerShippingFields" },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "TypicalNewPriceDisplayFields" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Listing" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "condition" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "conditionUuid" },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "pricing" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "typicalNewPriceDisplay" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "amountDisplay" },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "originalPriceDescription" },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "Item2PriceWithShippingData" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Listing" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "state" } },
          { kind: "Field", name: { kind: "Name", value: "stateDescription" } },
          { kind: "Field", name: { kind: "Name", value: "taxIncluded" } },
          { kind: "Field", name: { kind: "Name", value: "taxIncludedHint" } },
          { kind: "Field", name: { kind: "Name", value: "listingType" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "pricing" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "originalPrice" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "display" },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "ribbon" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "display" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "reason" },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "buyerPrice" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "amountCents" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "currency" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "display" },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "shop" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "id" } },
              ],
            },
          },
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "ListingShippingDisplayData" },
          },
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "TypicalNewPriceDisplayFields" },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "CompareOtherSellersLinkData" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Listing" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "csp" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "id" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "webLink" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "href" } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "ListingAffirmCalloutFields" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Listing" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "estimatedMonthlyPaymentPlan" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "financingProgramSlug" },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "pricing" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "buyerPrice" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "display" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "amountCents" },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "shipping" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "shippingPrices" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "shippingMethod" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "rate" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "amountCents" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "ListingKlarnaCalloutFields" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Listing" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "pricing" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "buyerPrice" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "amountCents" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "currency" },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "shipping" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "shippingPrices" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "shippingMethod" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "rate" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "amountCents" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "ListingFinancingCalloutFields" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Listing" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "acceptedPaymentMethods" },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "pricing" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "buyerPrice" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "amountCents" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "amount" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "currency" },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "ListingAffirmCalloutFields" },
          },
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "ListingKlarnaCalloutFields" },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "Item2StatsData" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Listing" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "hasInventory" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "condition" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "conditionUuid" },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "publishedAt" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "seconds" } },
              ],
            },
          },
          { kind: "Field", name: { kind: "Name", value: "listingType" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "counts" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "viewsDisplay" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "offersDisplay" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "watchersDisplay" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "downloadCountDisplay" },
                },
              ],
            },
          },
          { kind: "Field", name: { kind: "Name", value: "offersEnabled" } },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "ListingGooglePayButtonFields" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Listing" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "expressPay" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "estimatedTotal" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "amount" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "currency" },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "googlePay" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "merchantId" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "merchantName" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "gatewayMerchantId" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "cardNetworks" },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "BuyItNowExpressPayOptionsFields" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Listing" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "currency" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "adyenCheckoutPaymentMethodsConfig" },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "expressPay" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "checkoutBundlingId" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "paypal" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "currency" },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "pricing" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "buyerPrice" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "amount" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "amountCents" },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "ListingGooglePayButtonFields" },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "BuyItNowPaymentOptionsFields" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Listing" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "acceptedPaymentMethods" },
          },
          { kind: "Field", name: { kind: "Name", value: "sellerId" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "shop" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "address" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "countryCode" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "country" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "_id" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "name" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "shipping" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "localPickupOnly" },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "pricing" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "buyerPrice" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "amount" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "amountCents" },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "BuyItNowExpressPayOptionsFields" },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "Item2CPOData" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Listing" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "brandSlug" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "certifiedPreOwned" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "title" } },
                { kind: "Field", name: { kind: "Name", value: "description" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "tooltipDescription" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "badgeIconUrl" },
                },
                { kind: "Field", name: { kind: "Name", value: "brandName" } },
                { kind: "Field", name: { kind: "Name", value: "pageUrl" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "lightModeBrandIconUrl" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "darkModeBrandIconUrl" },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "BuyItNowButtonFields" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Listing" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "shipping" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "localPickupOnly" },
                },
              ],
            },
          },
          { kind: "Field", name: { kind: "Name", value: "usOutlet" } },
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "BuyItNowPaymentOptionsFields" },
          },
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "Item2CPOData" },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "DigitalDetailsFields" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Listing" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "listingType" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "digitalDetails" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "variants" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "downloadText" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "fileUrl" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "fileSize" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "operatingSystems" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "fileTypes" },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "supportedFormats" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "operatingSystem" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "fileTypes" },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "Item2PricingData" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Listing" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "offersEnabled" } },
          { kind: "Field", name: { kind: "Name", value: "inventory" } },
          { kind: "Field", name: { kind: "Name", value: "hasInventory" } },
          { kind: "Field", name: { kind: "Name", value: "maxBuyerQuantity" } },
          { kind: "Field", name: { kind: "Name", value: "make" } },
          { kind: "Field", name: { kind: "Name", value: "model" } },
          { kind: "Field", name: { kind: "Name", value: "state" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "condition" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "conditionSlug" },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "shop" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "address" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "countryCode" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "country" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "_id" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "name" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "AddToCartButtonFields" },
          },
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "Item2PriceWithShippingData" },
          },
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "CompareOtherSellersLinkData" },
          },
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "ListingFinancingCalloutFields" },
          },
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "Item2StatsData" },
          },
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "BuyItNowButtonFields" },
          },
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "ListingCreateOfferButtonData" },
          },
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "DigitalDetailsFields" },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "SignalsData" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Listing" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "state" } },
          { kind: "Field", name: { kind: "Name", value: "usOutlet" } },
          { kind: "Field", name: { kind: "Name", value: "currency" } },
          {
            kind: "Field",
            alias: { kind: "Name", value: "signals" },
            name: { kind: "Name", value: "signals" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "groups" },
                      value: {
                        kind: "ListValue",
                        values: [
                          { kind: "EnumValue", value: "ITEM_PAGE_WHY_TO_BUY" },
                          { kind: "EnumValue", value: "ITEM_PAGE_NUDGES" },
                          { kind: "EnumValue", value: "ITEM_PAGE_WHY_REVERB" },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "name" } },
                { kind: "Field", name: { kind: "Name", value: "group" } },
                { kind: "Field", name: { kind: "Name", value: "title" } },
                { kind: "Field", name: { kind: "Name", value: "subtitle" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "link" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "text" } },
                      { kind: "Field", name: { kind: "Name", value: "url" } },
                    ],
                  },
                },
                { kind: "Field", name: { kind: "Name", value: "icon" } },
                { kind: "Field", name: { kind: "Name", value: "iconDark" } },
                { kind: "Field", name: { kind: "Name", value: "tooltipText" } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "Item2ReturnPolicyData" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Listing" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "condition" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "conditionSlug" },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "shop" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "returnPolicy" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "newReturnWindowDays" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "usedReturnWindowDays" },
                      },
                    ],
                  },
                },
              ],
            },
          },
          { kind: "Field", name: { kind: "Name", value: "soldAsIs" } },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "FeaturedListingModuleData" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Listing" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "title" } },
          { kind: "Field", name: { kind: "Name", value: "slug" } },
          { kind: "Field", name: { kind: "Name", value: "description" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "condition" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "displayName" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "conditionSlug" },
                },
              ],
            },
          },
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "ItemImageFields" },
          },
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "FeaturedListingShopInfoData" },
          },
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "Item2PricingData" },
          },
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "SignalsData" },
          },
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "Item2ReturnPolicyData" },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "CSPAdditionalTrackingFields" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "CSP" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "slug" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "brand" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "slug" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "categories" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "slug" } },
                { kind: "Field", name: { kind: "Name", value: "rootSlug" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "parentCategories" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      { kind: "Field", name: { kind: "Name", value: "slug" } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "inventory" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "usedTotal" } },
                { kind: "Field", name: { kind: "Name", value: "newTotal" } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "CSPReviewJumplinksData" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "CSP" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "productReviewSearch" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "limit" },
                      value: { kind: "IntValue", value: "0" },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "stats" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "total" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "averageRating" },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "FeaturedListingCSP" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "CSP" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "inventory" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "newTotal" } },
                { kind: "Field", name: { kind: "Name", value: "usedTotal" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "categories" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "rootSlug" } },
                { kind: "Field", name: { kind: "Name", value: "slug" } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "ListingData" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Listing" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "upc" } },
          { kind: "Field", name: { kind: "Name", value: "finish" } },
          { kind: "Field", name: { kind: "Name", value: "model" } },
          { kind: "Field", name: { kind: "Name", value: "shopUuid" } },
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "SellerInfoData" },
          },
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "ListingCardFields" },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "ListingsCollection" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Listing" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "usOutlet" } },
          { kind: "Field", name: { kind: "Name", value: "bumped" } },
          { kind: "Field", name: { kind: "Name", value: "categoryUuids" } },
          { kind: "Field", name: { kind: "Name", value: "slug" } },
          { kind: "Field", name: { kind: "Name", value: "title" } },
          { kind: "Field", name: { kind: "Name", value: "description" } },
          { kind: "Field", name: { kind: "Name", value: "listingType" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "condition" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "displayName" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "conditionSlug" },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "price" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "amountCents" } },
                { kind: "Field", name: { kind: "Name", value: "display" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "pricing" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "buyerPrice" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "display" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "currency" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "amountCents" },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "originalPrice" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "display" },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "ribbon" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "display" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "reason" },
                      },
                    ],
                  },
                },
              ],
            },
          },
          { kind: "Field", name: { kind: "Name", value: "watching" } },
          { kind: "Field", name: { kind: "Name", value: "state" } },
          { kind: "Field", name: { kind: "Name", value: "stateDescription" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "shipping" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "shippingPrices" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "shippingMethod" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "carrierCalculated" },
                      },
                      {
                        kind: "Field",
                        name: {
                          kind: "Name",
                          value: "destinationPostalCodeNeeded",
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "rate" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "amount" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "amountCents" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "currency" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "display" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "freeExpeditedShipping" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "localPickupOnly" },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "shop" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "address" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "country" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "_id" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "countryCode" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "name" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "AddToCartButtonFields" },
          },
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "WatchBadgeData" },
          },
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "ListingGreatValueData" },
          },
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "ListingCreateOfferButtonData" },
          },
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "ListingImagesFields" },
          },
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "ListingCardCPOData" },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "FullFinancingFields" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Listing" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "estimatedMonthlyPaymentPlan" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "financingProgramSlug" },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "pricing" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "buyerPrice" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "amountCents" },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "ShopFields" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Listing" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "shop" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "address" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "locality" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "countryCode" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "region" },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "BumpKey" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Listing" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "bumpKey" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "key" } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "FeaturedListingWithPrice" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Listing" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "price" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "amount" } },
                { kind: "Field", name: { kind: "Name", value: "amountCents" } },
              ],
            },
          },
          { kind: "Field", name: { kind: "Name", value: "bumped" } },
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "FeaturedListingModuleData" },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Core_Csp_LayoutQueryQuery,
  Core_Csp_LayoutQueryQueryVariables
>;
export const Core_Dashboard_UpdateShopEuAddressDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "Core_Dashboard_UpdateShopEuAddress" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "input" },
          },
          type: {
            kind: "NamedType",
            name: {
              kind: "Name",
              value: "Input_core_apimessages_UpdateAddressRequest",
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "updateMyAddress" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "input" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "address" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      { kind: "Field", name: { kind: "Name", value: "uuid" } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Core_Dashboard_UpdateShopEuAddressMutation,
  Core_Dashboard_UpdateShopEuAddressMutationVariables
>;
export const DeleteEuAddressDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "DeleteEuAddress" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "input" },
          },
          type: {
            kind: "NamedType",
            name: {
              kind: "Name",
              value: "Input_core_apimessages_AddressDeleteRequest",
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "deleteMyAddress" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "input" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "uuid" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  DeleteEuAddressMutation,
  DeleteEuAddressMutationVariables
>;
export const Core_Dashboard_EuOdrAddressDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "Core_Dashboard_EuOdrAddress" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "slug" } },
          type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "countries" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "countries" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      { kind: "Field", name: { kind: "Name", value: "name" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "countryCode" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "subregions" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "_id" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "name" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "code" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "shop" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "slug" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "slug" },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "euOdrAddress" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      { kind: "Field", name: { kind: "Name", value: "name" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "streetAddress" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "displayLocation" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "postalCode" },
                      },
                      { kind: "Field", name: { kind: "Name", value: "email" } },
                      { kind: "Field", name: { kind: "Name", value: "phone" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "countryCode" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "country" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "_id" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "countryCode" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "name" },
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "region" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "locality" },
                      },
                      { kind: "Field", name: { kind: "Name", value: "uuid" } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Core_Dashboard_EuOdrAddressQuery,
  Core_Dashboard_EuOdrAddressQueryVariables
>;
export const MyShopTaxPolicyDataDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "MyShopTaxPolicyData" },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "me" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "uuid" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "shop" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "address" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "_id" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "countryCode" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "countries" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "countries" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      { kind: "Field", name: { kind: "Name", value: "name" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "countryCode" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "subregions" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "_id" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "name" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "code" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  MyShopTaxPolicyDataQuery,
  MyShopTaxPolicyDataQueryVariables
>;
export const Core_Listing_CombineAndSaveRowDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "Core_Listing_CombineAndSaveRow" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "combinedShipping" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "Boolean" } },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "boostCombinedShippingCategories" },
          },
          type: {
            kind: "ListType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "shopId" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "listingsSearch" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "combinedShipping" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "combinedShipping" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: {
                        kind: "Name",
                        value: "boostCombinedShippingCategories",
                      },
                      value: {
                        kind: "Variable",
                        name: {
                          kind: "Name",
                          value: "boostCombinedShippingCategories",
                        },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "shopId" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "shopId" },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "listings" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      {
                        kind: "FragmentSpread",
                        name: { kind: "Name", value: "ListingsCollection" },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "AddToCartButtonFields" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Listing" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "sellerId" } },
          { kind: "Field", name: { kind: "Name", value: "listingType" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "pricing" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "buyerPrice" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "amount" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "amountCents" },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "preorderInfo" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "onPreorder" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "estimatedShipDate" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "seconds" },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "WatchBadgeData" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Listing" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "title" } },
          { kind: "Field", name: { kind: "Name", value: "sellerId" } },
          { kind: "Field", name: { kind: "Name", value: "watching" } },
          {
            kind: "Field",
            alias: { kind: "Name", value: "watchThumbnails" },
            name: { kind: "Name", value: "images" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "type" },
                      value: {
                        kind: "StringValue",
                        value: "Product",
                        block: false,
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "scope" },
                      value: {
                        kind: "StringValue",
                        value: "photos",
                        block: false,
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "transform" },
                      value: {
                        kind: "StringValue",
                        value: "card_square",
                        block: false,
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "count" },
                      value: { kind: "IntValue", value: "1" },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "source" } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "ListingGreatValueData" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Listing" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "pricing" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "buyerPrice" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "currency" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "amountCents" },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "condition" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "conditionSlug" },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "priceRecommendation" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "priceMiddle" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "amountCents" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "currency" },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "shop" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "address" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "country" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "_id" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "countryCode" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          { kind: "Field", name: { kind: "Name", value: "currency" } },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "mParticleListingFields" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Listing" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "title" } },
          { kind: "Field", name: { kind: "Name", value: "brandSlug" } },
          { kind: "Field", name: { kind: "Name", value: "categoryRootUuid" } },
          { kind: "Field", name: { kind: "Name", value: "make" } },
          { kind: "Field", name: { kind: "Name", value: "categoryUuids" } },
          { kind: "Field", name: { kind: "Name", value: "state" } },
          { kind: "Field", name: { kind: "Name", value: "listingType" } },
          { kind: "Field", name: { kind: "Name", value: "bumpEligible" } },
          { kind: "Field", name: { kind: "Name", value: "shopId" } },
          { kind: "Field", name: { kind: "Name", value: "inventory" } },
          { kind: "Field", name: { kind: "Name", value: "soldAsIs" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "acceptedPaymentMethods" },
          },
          { kind: "Field", name: { kind: "Name", value: "currency" } },
          { kind: "Field", name: { kind: "Name", value: "usOutlet" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "condition" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "conditionUuid" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "conditionSlug" },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "categories" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "slug" } },
                { kind: "Field", name: { kind: "Name", value: "rootSlug" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "csp" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "slug" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "brand" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      { kind: "Field", name: { kind: "Name", value: "slug" } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "pricing" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "buyerPrice" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "amount" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "currency" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "amountCents" },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "publishedAt" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "seconds" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "sale" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "code" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "buyerIneligibilityReason" },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "shipping" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "shippingPrices" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "shippingMethod" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "carrierCalculated" },
                      },
                      {
                        kind: "Field",
                        name: {
                          kind: "Name",
                          value: "destinationPostalCodeNeeded",
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "rate" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "amount" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "amountCents" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "currency" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "display" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "freeExpeditedShipping" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "localPickupOnly" },
                },
                { kind: "Field", name: { kind: "Name", value: "localPickup" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "certifiedPreOwned" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "title" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "badgeIconUrl" },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "shop" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "address" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "countryCode" },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "returnPolicy" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "newReturnWindowDays" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "usedReturnWindowDays" },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "ListingCreateOfferButtonData" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Listing" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "state" } },
          { kind: "Field", name: { kind: "Name", value: "offersEnabled" } },
          { kind: "Field", name: { kind: "Name", value: "listingType" } },
          { kind: "Field", name: { kind: "Name", value: "sellerId" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "isBuyerOfferEligible" },
          },
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "mParticleListingFields" },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "ListingImagesFields" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Listing" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "images" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "transform" },
                      value: {
                        kind: "StringValue",
                        value: "card_square",
                        block: false,
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "count" },
                      value: { kind: "IntValue", value: "3" },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "scope" },
                      value: {
                        kind: "StringValue",
                        value: "photos",
                        block: false,
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "type" },
                      value: {
                        kind: "StringValue",
                        value: "Product",
                        block: false,
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "source" } },
              ],
            },
          },
          {
            kind: "Field",
            alias: { kind: "Name", value: "largeSquareImages" },
            name: { kind: "Name", value: "images" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "transform" },
                      value: {
                        kind: "StringValue",
                        value: "large_square",
                        block: false,
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "count" },
                      value: { kind: "IntValue", value: "3" },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "scope" },
                      value: {
                        kind: "StringValue",
                        value: "photos",
                        block: false,
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "type" },
                      value: {
                        kind: "StringValue",
                        value: "Product",
                        block: false,
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "source" } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "ListingCardCPOData" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Listing" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "certifiedPreOwned" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "title" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "badgeIconUrl" },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "ListingsCollection" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Listing" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "usOutlet" } },
          { kind: "Field", name: { kind: "Name", value: "bumped" } },
          { kind: "Field", name: { kind: "Name", value: "categoryUuids" } },
          { kind: "Field", name: { kind: "Name", value: "slug" } },
          { kind: "Field", name: { kind: "Name", value: "title" } },
          { kind: "Field", name: { kind: "Name", value: "description" } },
          { kind: "Field", name: { kind: "Name", value: "listingType" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "condition" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "displayName" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "conditionSlug" },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "price" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "amountCents" } },
                { kind: "Field", name: { kind: "Name", value: "display" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "pricing" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "buyerPrice" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "display" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "currency" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "amountCents" },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "originalPrice" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "display" },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "ribbon" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "display" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "reason" },
                      },
                    ],
                  },
                },
              ],
            },
          },
          { kind: "Field", name: { kind: "Name", value: "watching" } },
          { kind: "Field", name: { kind: "Name", value: "state" } },
          { kind: "Field", name: { kind: "Name", value: "stateDescription" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "shipping" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "shippingPrices" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "shippingMethod" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "carrierCalculated" },
                      },
                      {
                        kind: "Field",
                        name: {
                          kind: "Name",
                          value: "destinationPostalCodeNeeded",
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "rate" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "amount" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "amountCents" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "currency" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "display" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "freeExpeditedShipping" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "localPickupOnly" },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "shop" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "address" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "country" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "_id" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "countryCode" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "name" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "AddToCartButtonFields" },
          },
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "WatchBadgeData" },
          },
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "ListingGreatValueData" },
          },
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "ListingCreateOfferButtonData" },
          },
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "ListingImagesFields" },
          },
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "ListingCardCPOData" },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Core_Listing_CombineAndSaveRowQuery,
  Core_Listing_CombineAndSaveRowQueryVariables
>;
export const Core_Cart_MyCouponListDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "Core_Cart_MyCouponList" },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "me" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "uuid" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "buyerCoupons" },
                  arguments: [
                    {
                      kind: "Argument",
                      name: { kind: "Name", value: "input" },
                      value: {
                        kind: "ObjectValue",
                        fields: [
                          {
                            kind: "ObjectField",
                            name: { kind: "Name", value: "context" },
                            value: { kind: "EnumValue", value: "CART" },
                          },
                        ],
                      },
                    },
                  ],
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "shopCampaignCoupons" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "_id" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "code" },
                            },
                            {
                              kind: "Field",
                              name: {
                                kind: "Name",
                                value: "descriptionSummary",
                              },
                            },
                            {
                              kind: "Field",
                              name: {
                                kind: "Name",
                                value: "restrictionsSummary",
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "discountValue" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "couponHeader" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "status" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "excludedBrands" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "_id" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "name" },
                                  },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: {
                                kind: "Name",
                                value: "excludedCategories",
                              },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "_id" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "name" },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Core_Cart_MyCouponListQuery,
  Core_Cart_MyCouponListQueryVariables
>;
export const Core_Marketplace_CspRowDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "Core_Marketplace_CspRow" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "autodirects" },
          },
          type: {
            kind: "NamedType",
            name: { kind: "Name", value: "reverb_search_Autodirects" },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "brands" },
          },
          type: {
            kind: "ListType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "categories" },
          },
          type: {
            kind: "ListType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "condition" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "contexts" },
          },
          type: {
            kind: "ListType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "reverb_search_Context" },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "countryOfOrigin" },
          },
          type: {
            kind: "ListType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "curatedSetSlugs" },
          },
          type: {
            kind: "ListType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "currency" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "decades" },
          },
          type: {
            kind: "ListType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "fullTextQuery" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "listingsThatShipTo" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "priceMin" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "priceMax" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
        },
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "sort" } },
          type: {
            kind: "NamedType",
            name: {
              kind: "Name",
              value: "reverb_search_CSPSearchRequest_Sort",
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "traitValues" },
          },
          type: {
            kind: "ListType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "yearMax" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "Int" } },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "yearMin" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "Int" } },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "cspSearch" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "autodirects" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "autodirects" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "brandSlugs" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "brands" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "categorySlugs" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "categories" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "condition" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "condition" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "contexts" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "contexts" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "countryOfOrigin" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "countryOfOrigin" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "curatedSetSlugs" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "curatedSetSlugs" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "currency" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "currency" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "decades" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "decades" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "fullTextQuery" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "fullTextQuery" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "fullTextQueryOperand" },
                      value: { kind: "EnumValue", value: "AND" },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "priceMin" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "priceMin" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "priceMax" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "priceMax" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "sort" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "sort" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "listingsThatShipTo" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "listingsThatShipTo" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "traitValues" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "traitValues" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "withInventory" },
                      value: { kind: "BooleanValue", value: true },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "yearMax" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "yearMax" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "yearMin" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "yearMin" },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "csps" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      {
                        kind: "FragmentSpread",
                        name: { kind: "Name", value: "CSPCard" },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "CSPAdditionalTrackingFields" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "CSP" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "slug" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "brand" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "slug" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "categories" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "slug" } },
                { kind: "Field", name: { kind: "Name", value: "rootSlug" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "parentCategories" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      { kind: "Field", name: { kind: "Name", value: "slug" } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "inventory" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "usedTotal" } },
                { kind: "Field", name: { kind: "Name", value: "newTotal" } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "CSPCard" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "CSP" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "title" } },
          { kind: "Field", name: { kind: "Name", value: "slug" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "averageReviewRating" },
          },
          { kind: "Field", name: { kind: "Name", value: "reviewsCount" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "webLink" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "path" } },
                { kind: "Field", name: { kind: "Name", value: "href" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "image" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "transform" },
                      value: {
                        kind: "StringValue",
                        value: "card_square",
                        block: false,
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "source" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "brand" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "slug" } },
                { kind: "Field", name: { kind: "Name", value: "name" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "categories" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "slug" } },
                { kind: "Field", name: { kind: "Name", value: "rootSlug" } },
                { kind: "Field", name: { kind: "Name", value: "name" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "parentCategories" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      { kind: "Field", name: { kind: "Name", value: "name" } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "inventory" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "newLowPrice" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "display" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "amount" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "amountCents" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "currency" },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "usedLowPrice" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "display" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "amount" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "amountCents" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "currency" },
                      },
                    ],
                  },
                },
                { kind: "Field", name: { kind: "Name", value: "usedTotal" } },
                { kind: "Field", name: { kind: "Name", value: "newTotal" } },
              ],
            },
          },
          { kind: "Field", name: { kind: "Name", value: "finishes" } },
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "CSPAdditionalTrackingFields" },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Core_Marketplace_CspRowQuery,
  Core_Marketplace_CspRowQueryVariables
>;
export const Category_Under_Price_QueryDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "Category_Under_Price_Query" },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "me" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "uuid" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "profile" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "topProductTypeUuids" },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "exchangeRates" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "inverseReverseRates" },
                      value: { kind: "BooleanValue", value: true },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "rates" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "from" } },
                      { kind: "Field", name: { kind: "Name", value: "to" } },
                      { kind: "Field", name: { kind: "Name", value: "rate" } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Category_Under_Price_QueryQuery,
  Category_Under_Price_QueryQueryVariables
>;
export const Core_MosaicTilesHomepageDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "Core_MosaicTilesHomepage" },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "mosaicTilesHomepage" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "mosaicTiles" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      { kind: "Field", name: { kind: "Name", value: "url" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "imageUrl" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "position" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "imageTitle" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "imageSubtitle" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "contentSponsorship" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "sponsorshipType" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "partnershipName" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Core_MosaicTilesHomepageQuery,
  Core_MosaicTilesHomepageQueryVariables
>;
export const Core_Homepage_RecentSearchesListingsSearchDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: {
        kind: "Name",
        value: "Core_Homepage_RecentSearchesListingsSearch",
      },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "limit" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "Int" } },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "query0" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "query1" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "query2" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "skipQuery2" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "Boolean" },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "query3" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "skipQuery3" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "Boolean" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            alias: { kind: "Name", value: "recentSearchListings0" },
            name: { kind: "Name", value: "listingsSearch" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "limit" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "limit" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "query" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "query0" },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "total" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "listings" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      { kind: "Field", name: { kind: "Name", value: "slug" } },
                      {
                        kind: "FragmentSpread",
                        name: {
                          kind: "Name",
                          value: "RecentSearchListingData",
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            alias: { kind: "Name", value: "recentSearchListings1" },
            name: { kind: "Name", value: "listingsSearch" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "limit" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "limit" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "query" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "query1" },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "total" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "listings" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      { kind: "Field", name: { kind: "Name", value: "slug" } },
                      {
                        kind: "FragmentSpread",
                        name: {
                          kind: "Name",
                          value: "RecentSearchListingData",
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            alias: { kind: "Name", value: "recentSearchListings2" },
            name: { kind: "Name", value: "listingsSearch" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "limit" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "limit" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "query" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "query2" },
                      },
                    },
                  ],
                },
              },
            ],
            directives: [
              {
                kind: "Directive",
                name: { kind: "Name", value: "skip" },
                arguments: [
                  {
                    kind: "Argument",
                    name: { kind: "Name", value: "if" },
                    value: {
                      kind: "Variable",
                      name: { kind: "Name", value: "skipQuery2" },
                    },
                  },
                ],
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "total" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "listings" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      { kind: "Field", name: { kind: "Name", value: "slug" } },
                      {
                        kind: "FragmentSpread",
                        name: {
                          kind: "Name",
                          value: "RecentSearchListingData",
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            alias: { kind: "Name", value: "recentSearchListings3" },
            name: { kind: "Name", value: "listingsSearch" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "limit" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "limit" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "query" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "query3" },
                      },
                    },
                  ],
                },
              },
            ],
            directives: [
              {
                kind: "Directive",
                name: { kind: "Name", value: "skip" },
                arguments: [
                  {
                    kind: "Argument",
                    name: { kind: "Name", value: "if" },
                    value: {
                      kind: "Variable",
                      name: { kind: "Name", value: "skipQuery3" },
                    },
                  },
                ],
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "total" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "listings" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      { kind: "Field", name: { kind: "Name", value: "slug" } },
                      {
                        kind: "FragmentSpread",
                        name: {
                          kind: "Name",
                          value: "RecentSearchListingData",
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "RecentSearchListingData" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Listing" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "images" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "type" },
                      value: {
                        kind: "StringValue",
                        value: "Product",
                        block: false,
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "scope" },
                      value: {
                        kind: "StringValue",
                        value: "photos",
                        block: false,
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "transform" },
                      value: {
                        kind: "StringValue",
                        value: "card_square",
                        block: false,
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "count" },
                      value: { kind: "IntValue", value: "2" },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "source" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Core_Homepage_RecentSearchesListingsSearchQuery,
  Core_Homepage_RecentSearchesListingsSearchQueryVariables
>;
export const Just_Listed_For_You_QueryDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "Just_Listed_For_You_Query" },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "me" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "uuid" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "profile" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "topProductTypeUuids" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "topProductTypes" },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Just_Listed_For_You_QueryQuery,
  Just_Listed_For_You_QueryQueryVariables
>;
export const Core_HomepageDependenciesDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "Core_HomepageDependencies" },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "me" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "uuid" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "feed" },
                  arguments: [
                    {
                      kind: "Argument",
                      name: { kind: "Name", value: "input" },
                      value: {
                        kind: "ObjectValue",
                        fields: [
                          {
                            kind: "ObjectField",
                            name: { kind: "Name", value: "channel" },
                            value: { kind: "EnumValue", value: "REVERB" },
                          },
                          {
                            kind: "ObjectField",
                            name: { kind: "Name", value: "limit" },
                            value: { kind: "IntValue", value: "0" },
                          },
                        ],
                      },
                    },
                  ],
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "total" } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Core_HomepageDependenciesQuery,
  Core_HomepageDependenciesQueryVariables
>;
export const LoggedOutMParticleProfileQueryDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "LoggedOutMParticleProfileQuery" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "mpid" } },
          type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "me" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "uuid" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "profile" },
                  arguments: [
                    {
                      kind: "Argument",
                      name: { kind: "Name", value: "input" },
                      value: {
                        kind: "ObjectValue",
                        fields: [
                          {
                            kind: "ObjectField",
                            name: { kind: "Name", value: "mpid" },
                            value: {
                              kind: "Variable",
                              name: { kind: "Name", value: "mpid" },
                            },
                          },
                        ],
                      },
                    },
                  ],
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: {
                          kind: "Name",
                          value: "topMostRecentProductTypes",
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  LoggedOutMParticleProfileQueryQuery,
  LoggedOutMParticleProfileQueryQueryVariables
>;
export const Regional_Listings_Row_QueryDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "Regional_Listings_Row_Query" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "itemRegion" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "conditionSlugs" },
          },
          type: {
            kind: "ListType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "sort" } },
          type: {
            kind: "NamedType",
            name: {
              kind: "Name",
              value: "reverb_search_ListingsSearchRequest_Sort",
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "limit" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "Int" } },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "listingsSearch" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "itemRegion" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "itemRegion" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "conditionSlugs" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "conditionSlugs" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "sort" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "sort" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "limit" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "limit" },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "listings" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      {
                        kind: "FragmentSpread",
                        name: { kind: "Name", value: "ListingsCollection" },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "AddToCartButtonFields" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Listing" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "sellerId" } },
          { kind: "Field", name: { kind: "Name", value: "listingType" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "pricing" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "buyerPrice" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "amount" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "amountCents" },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "preorderInfo" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "onPreorder" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "estimatedShipDate" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "seconds" },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "WatchBadgeData" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Listing" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "title" } },
          { kind: "Field", name: { kind: "Name", value: "sellerId" } },
          { kind: "Field", name: { kind: "Name", value: "watching" } },
          {
            kind: "Field",
            alias: { kind: "Name", value: "watchThumbnails" },
            name: { kind: "Name", value: "images" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "type" },
                      value: {
                        kind: "StringValue",
                        value: "Product",
                        block: false,
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "scope" },
                      value: {
                        kind: "StringValue",
                        value: "photos",
                        block: false,
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "transform" },
                      value: {
                        kind: "StringValue",
                        value: "card_square",
                        block: false,
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "count" },
                      value: { kind: "IntValue", value: "1" },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "source" } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "ListingGreatValueData" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Listing" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "pricing" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "buyerPrice" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "currency" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "amountCents" },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "condition" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "conditionSlug" },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "priceRecommendation" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "priceMiddle" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "amountCents" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "currency" },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "shop" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "address" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "country" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "_id" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "countryCode" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          { kind: "Field", name: { kind: "Name", value: "currency" } },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "mParticleListingFields" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Listing" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "title" } },
          { kind: "Field", name: { kind: "Name", value: "brandSlug" } },
          { kind: "Field", name: { kind: "Name", value: "categoryRootUuid" } },
          { kind: "Field", name: { kind: "Name", value: "make" } },
          { kind: "Field", name: { kind: "Name", value: "categoryUuids" } },
          { kind: "Field", name: { kind: "Name", value: "state" } },
          { kind: "Field", name: { kind: "Name", value: "listingType" } },
          { kind: "Field", name: { kind: "Name", value: "bumpEligible" } },
          { kind: "Field", name: { kind: "Name", value: "shopId" } },
          { kind: "Field", name: { kind: "Name", value: "inventory" } },
          { kind: "Field", name: { kind: "Name", value: "soldAsIs" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "acceptedPaymentMethods" },
          },
          { kind: "Field", name: { kind: "Name", value: "currency" } },
          { kind: "Field", name: { kind: "Name", value: "usOutlet" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "condition" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "conditionUuid" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "conditionSlug" },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "categories" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "slug" } },
                { kind: "Field", name: { kind: "Name", value: "rootSlug" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "csp" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "slug" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "brand" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      { kind: "Field", name: { kind: "Name", value: "slug" } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "pricing" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "buyerPrice" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "amount" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "currency" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "amountCents" },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "publishedAt" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "seconds" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "sale" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "code" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "buyerIneligibilityReason" },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "shipping" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "shippingPrices" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "shippingMethod" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "carrierCalculated" },
                      },
                      {
                        kind: "Field",
                        name: {
                          kind: "Name",
                          value: "destinationPostalCodeNeeded",
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "rate" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "amount" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "amountCents" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "currency" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "display" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "freeExpeditedShipping" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "localPickupOnly" },
                },
                { kind: "Field", name: { kind: "Name", value: "localPickup" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "certifiedPreOwned" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "title" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "badgeIconUrl" },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "shop" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "address" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "countryCode" },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "returnPolicy" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "newReturnWindowDays" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "usedReturnWindowDays" },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "ListingCreateOfferButtonData" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Listing" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "state" } },
          { kind: "Field", name: { kind: "Name", value: "offersEnabled" } },
          { kind: "Field", name: { kind: "Name", value: "listingType" } },
          { kind: "Field", name: { kind: "Name", value: "sellerId" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "isBuyerOfferEligible" },
          },
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "mParticleListingFields" },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "ListingImagesFields" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Listing" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "images" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "transform" },
                      value: {
                        kind: "StringValue",
                        value: "card_square",
                        block: false,
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "count" },
                      value: { kind: "IntValue", value: "3" },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "scope" },
                      value: {
                        kind: "StringValue",
                        value: "photos",
                        block: false,
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "type" },
                      value: {
                        kind: "StringValue",
                        value: "Product",
                        block: false,
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "source" } },
              ],
            },
          },
          {
            kind: "Field",
            alias: { kind: "Name", value: "largeSquareImages" },
            name: { kind: "Name", value: "images" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "transform" },
                      value: {
                        kind: "StringValue",
                        value: "large_square",
                        block: false,
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "count" },
                      value: { kind: "IntValue", value: "3" },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "scope" },
                      value: {
                        kind: "StringValue",
                        value: "photos",
                        block: false,
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "type" },
                      value: {
                        kind: "StringValue",
                        value: "Product",
                        block: false,
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "source" } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "ListingCardCPOData" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Listing" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "certifiedPreOwned" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "title" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "badgeIconUrl" },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "ListingsCollection" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Listing" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "usOutlet" } },
          { kind: "Field", name: { kind: "Name", value: "bumped" } },
          { kind: "Field", name: { kind: "Name", value: "categoryUuids" } },
          { kind: "Field", name: { kind: "Name", value: "slug" } },
          { kind: "Field", name: { kind: "Name", value: "title" } },
          { kind: "Field", name: { kind: "Name", value: "description" } },
          { kind: "Field", name: { kind: "Name", value: "listingType" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "condition" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "displayName" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "conditionSlug" },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "price" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "amountCents" } },
                { kind: "Field", name: { kind: "Name", value: "display" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "pricing" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "buyerPrice" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "display" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "currency" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "amountCents" },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "originalPrice" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "display" },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "ribbon" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "display" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "reason" },
                      },
                    ],
                  },
                },
              ],
            },
          },
          { kind: "Field", name: { kind: "Name", value: "watching" } },
          { kind: "Field", name: { kind: "Name", value: "state" } },
          { kind: "Field", name: { kind: "Name", value: "stateDescription" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "shipping" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "shippingPrices" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "shippingMethod" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "carrierCalculated" },
                      },
                      {
                        kind: "Field",
                        name: {
                          kind: "Name",
                          value: "destinationPostalCodeNeeded",
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "rate" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "amount" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "amountCents" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "currency" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "display" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "freeExpeditedShipping" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "localPickupOnly" },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "shop" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "address" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "country" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "_id" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "countryCode" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "name" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "AddToCartButtonFields" },
          },
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "WatchBadgeData" },
          },
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "ListingGreatValueData" },
          },
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "ListingCreateOfferButtonData" },
          },
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "ListingImagesFields" },
          },
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "ListingCardCPOData" },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Regional_Listings_Row_QueryQuery,
  Regional_Listings_Row_QueryQueryVariables
>;
export const Core_ReverbHeaderDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "Core_ReverbHeader" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "isShopAdmin" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "Boolean" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "me" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "uuid" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "shop" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      { kind: "Field", name: { kind: "Name", value: "slug" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "paymentMethod" },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "reverbCredits" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "display" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "currency" },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "payoutBalances" },
                  directives: [
                    {
                      kind: "Directive",
                      name: { kind: "Name", value: "include" },
                      arguments: [
                        {
                          kind: "Argument",
                          name: { kind: "Name", value: "if" },
                          value: {
                            kind: "Variable",
                            name: { kind: "Name", value: "isShopAdmin" },
                          },
                        },
                      ],
                    },
                  ],
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "display" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "currency" },
                      },
                    ],
                  },
                },
                {
                  kind: "FragmentSpread",
                  name: { kind: "Name", value: "ReverbHeaderCartBadgeData" },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "ReverbHeaderCartItemFields" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "rql_Me" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "uuid" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "cart" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "cartItems" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      { kind: "Field", name: { kind: "Name", value: "uuid" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "quantity" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "listingTotal" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "discounted" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "discount" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "type" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "label" },
                                  },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "unitPrice" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "display" },
                                  },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: {
                                kind: "Name",
                                value: "originalUnitPrice",
                              },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "display" },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "listing" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "_id" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "title" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "images" },
                              arguments: [
                                {
                                  kind: "Argument",
                                  name: { kind: "Name", value: "input" },
                                  value: {
                                    kind: "ObjectValue",
                                    fields: [
                                      {
                                        kind: "ObjectField",
                                        name: {
                                          kind: "Name",
                                          value: "transform",
                                        },
                                        value: {
                                          kind: "StringValue",
                                          value: "card_square",
                                          block: false,
                                        },
                                      },
                                      {
                                        kind: "ObjectField",
                                        name: { kind: "Name", value: "count" },
                                        value: { kind: "IntValue", value: "1" },
                                      },
                                      {
                                        kind: "ObjectField",
                                        name: { kind: "Name", value: "scope" },
                                        value: {
                                          kind: "StringValue",
                                          value: "photos",
                                          block: false,
                                        },
                                      },
                                      {
                                        kind: "ObjectField",
                                        name: { kind: "Name", value: "type" },
                                        value: {
                                          kind: "StringValue",
                                          value: "Product",
                                          block: false,
                                        },
                                      },
                                    ],
                                  },
                                },
                              ],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "source" },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "ReverbHeaderCartBadgeData" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "rql_Me" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "uuid" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "reverbCredits" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "display" } },
                { kind: "Field", name: { kind: "Name", value: "currency" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "cart" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "cartItems" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      { kind: "Field", name: { kind: "Name", value: "uuid" } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "ReverbHeaderCartItemFields" },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Core_ReverbHeaderQuery,
  Core_ReverbHeaderQueryVariables
>;
export const Core_Sale_HeaderDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "Core_Sale_Header" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "collectionType" },
          },
          type: {
            kind: "NamedType",
            name: {
              kind: "Name",
              value: "core_apimessages_CollectionHeader_CollectionType",
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "collectionSlug" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "collectionHeader" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "collectionType" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "collectionType" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "collectionSlug" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "collectionSlug" },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                {
                  kind: "FragmentSpread",
                  name: { kind: "Name", value: "CollectionInfo" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "sale" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      {
                        kind: "Field",
                        name: {
                          kind: "Name",
                          value: "buyerIneligibilityReason",
                        },
                      },
                      { kind: "Field", name: { kind: "Name", value: "state" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "percent" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "discountValue" },
                      },
                      { kind: "Field", name: { kind: "Name", value: "code" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "shopName" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "shopSlug" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "saleType" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "startsAt" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "seconds" },
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "endsAt" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "seconds" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "CollectionInfo" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "CollectionHeader" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "title" } },
          { kind: "Field", name: { kind: "Name", value: "metaTitle" } },
          { kind: "Field", name: { kind: "Name", value: "collectionId" } },
          { kind: "Field", name: { kind: "Name", value: "collectionType" } },
          { kind: "Field", name: { kind: "Name", value: "description" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "fullBleedImage" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "source" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Core_Sale_HeaderQuery,
  Core_Sale_HeaderQueryVariables
>;
export const Core_ShippingLabel_EditShipmentDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "Core_ShippingLabel_EditShipment" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "shipmentId" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "checkoutUuid" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "actionableStatuses" },
          },
          type: {
            kind: "ListType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "me" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "uuid" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "shipments" },
                  arguments: [
                    {
                      kind: "Argument",
                      name: { kind: "Name", value: "input" },
                      value: {
                        kind: "ObjectValue",
                        fields: [
                          {
                            kind: "ObjectField",
                            name: { kind: "Name", value: "ids" },
                            value: {
                              kind: "ListValue",
                              values: [
                                {
                                  kind: "Variable",
                                  name: { kind: "Name", value: "shipmentId" },
                                },
                              ],
                            },
                          },
                        ],
                      },
                    },
                  ],
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "orders" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "_id" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "legacyOrderId" },
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "toAddress" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "_id" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "name" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "streetAddress" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "extendedAddress" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "postalCode" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "phone" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "region" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "locality" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "countryCode" },
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "fromAddress" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "_id" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "name" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "streetAddress" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "extendedAddress" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "postalCode" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "phone" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "region" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "locality" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "countryCode" },
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "package" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: {
                                kind: "Name",
                                value: "predefinedPackage",
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "length" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "value" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "unit" },
                                  },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "height" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "value" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "unit" },
                                  },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "width" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "value" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "unit" },
                                  },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "weight" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "value" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "unit" },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "shippingProtection" },
                      },
                      {
                        kind: "Field",
                        name: {
                          kind: "Name",
                          value: "shippingProtectionPrice",
                        },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "amount" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "amountCents" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "currency" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "symbol" },
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "signatureRequired" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "mediaMailQualified" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "toAddressIsResidential" },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "sellerCheckout" },
                  arguments: [
                    {
                      kind: "Argument",
                      name: { kind: "Name", value: "input" },
                      value: {
                        kind: "ObjectValue",
                        fields: [
                          {
                            kind: "ObjectField",
                            name: { kind: "Name", value: "id" },
                            value: {
                              kind: "Variable",
                              name: { kind: "Name", value: "checkoutUuid" },
                            },
                          },
                        ],
                      },
                    },
                  ],
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      {
                        kind: "Field",
                        name: {
                          kind: "Name",
                          value: "ordersForShipmentSearch",
                        },
                        arguments: [
                          {
                            kind: "Argument",
                            name: { kind: "Name", value: "input" },
                            value: {
                              kind: "ObjectValue",
                              fields: [
                                {
                                  kind: "ObjectField",
                                  name: { kind: "Name", value: "offset" },
                                  value: { kind: "IntValue", value: "0" },
                                },
                                {
                                  kind: "ObjectField",
                                  name: { kind: "Name", value: "limit" },
                                  value: { kind: "IntValue", value: "50" },
                                },
                                {
                                  kind: "ObjectField",
                                  name: {
                                    kind: "Name",
                                    value: "actionableStatuses",
                                  },
                                  value: {
                                    kind: "Variable",
                                    name: {
                                      kind: "Name",
                                      value: "actionableStatuses",
                                    },
                                  },
                                },
                              ],
                            },
                          },
                        ],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: {
                                kind: "Name",
                                value: "ordersForShipment",
                              },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "_id" },
                                  },
                                  {
                                    kind: "FragmentSpread",
                                    name: {
                                      kind: "Name",
                                      value: "ShippingLabelOrderData",
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "listing" },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "_id" },
                                        },
                                        {
                                          kind: "FragmentSpread",
                                          name: {
                                            kind: "Name",
                                            value: "ShippingLabelListingData",
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: {
                          kind: "Name",
                          value: "flatRateShippingOptions",
                        },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: {
                                kind: "Name",
                                value: "flatRateShippingOptions",
                              },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "carrier" },
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "displayTitle",
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "displayName",
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "optionValue",
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "displayDimensions",
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "maximumPounds",
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "displayPosition",
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "countries" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "countries" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      {
                        kind: "FragmentSpread",
                        name: {
                          kind: "Name",
                          value: "ShippingLabelCountriesData",
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "ShippingLabelOrderData" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Order" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "buyerUuid" } },
          { kind: "Field", name: { kind: "Name", value: "shippingMethod" } },
          { kind: "Field", name: { kind: "Name", value: "legacyOrderId" } },
          { kind: "Field", name: { kind: "Name", value: "quantity" } },
          { kind: "Field", name: { kind: "Name", value: "listingId" } },
          { kind: "Field", name: { kind: "Name", value: "orderType" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "settlementAmountProductSubtotal" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "amount" } },
                { kind: "Field", name: { kind: "Name", value: "amountCents" } },
                { kind: "Field", name: { kind: "Name", value: "currency" } },
                { kind: "Field", name: { kind: "Name", value: "symbol" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "createdAt" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "seconds" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "paidAt" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "seconds" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "customsInfo" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "description" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "tariffNumber" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "categoryName" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "countryOfOrigin" },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "amountShipping" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "amount" } },
                { kind: "Field", name: { kind: "Name", value: "amountCents" } },
                { kind: "Field", name: { kind: "Name", value: "currency" } },
                { kind: "Field", name: { kind: "Name", value: "symbol" } },
                { kind: "Field", name: { kind: "Name", value: "display" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "carrierCalculatedShippingAmount" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "amount" } },
                { kind: "Field", name: { kind: "Name", value: "amountCents" } },
                { kind: "Field", name: { kind: "Name", value: "currency" } },
                { kind: "Field", name: { kind: "Name", value: "symbol" } },
                { kind: "Field", name: { kind: "Name", value: "display" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "carrierCalculatedCarrier" },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "ShippingLabelListingData" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Listing" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "title" } },
          { kind: "Field", name: { kind: "Name", value: "merchandisingType" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "images" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "transform" },
                      value: {
                        kind: "StringValue",
                        value: "thumb",
                        block: false,
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "count" },
                      value: { kind: "IntValue", value: "1" },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "scope" },
                      value: {
                        kind: "StringValue",
                        value: "photos",
                        block: false,
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "type" },
                      value: {
                        kind: "StringValue",
                        value: "Product",
                        block: false,
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "source" } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "ShippingLabelCountriesData" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "core_apimessages_Country" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "name" } },
          { kind: "Field", name: { kind: "Name", value: "countryCode" } },
          { kind: "Field", name: { kind: "Name", value: "subregionRequired" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "subregions" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "code" } },
                { kind: "Field", name: { kind: "Name", value: "name" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Core_ShippingLabel_EditShipmentQuery,
  Core_ShippingLabel_EditShipmentQueryVariables
>;
export const Core_ShippingLabel_EditShipmentForManualOrderDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: {
        kind: "Name",
        value: "Core_ShippingLabel_EditShipmentForManualOrder",
      },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "shipmentId" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "orderUuid" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "me" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "uuid" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "shipments" },
                  arguments: [
                    {
                      kind: "Argument",
                      name: { kind: "Name", value: "input" },
                      value: {
                        kind: "ObjectValue",
                        fields: [
                          {
                            kind: "ObjectField",
                            name: { kind: "Name", value: "ids" },
                            value: {
                              kind: "ListValue",
                              values: [
                                {
                                  kind: "Variable",
                                  name: { kind: "Name", value: "shipmentId" },
                                },
                              ],
                            },
                          },
                        ],
                      },
                    },
                  ],
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "orders" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "_id" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "legacyOrderId" },
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "toAddress" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "_id" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "name" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "streetAddress" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "extendedAddress" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "postalCode" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "phone" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "region" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "locality" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "countryCode" },
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "fromAddress" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "_id" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "name" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "streetAddress" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "extendedAddress" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "postalCode" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "phone" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "region" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "locality" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "countryCode" },
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "package" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: {
                                kind: "Name",
                                value: "predefinedPackage",
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "length" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "value" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "unit" },
                                  },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "height" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "value" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "unit" },
                                  },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "width" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "value" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "unit" },
                                  },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "weight" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "value" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "unit" },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "shippingProtection" },
                      },
                      {
                        kind: "Field",
                        name: {
                          kind: "Name",
                          value: "shippingProtectionPrice",
                        },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "amount" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "amountCents" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "currency" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "symbol" },
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "signatureRequired" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "mediaMailQualified" },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "order" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "uuid" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "orderUuid" },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                {
                  kind: "FragmentSpread",
                  name: { kind: "Name", value: "ShippingLabelOrderData" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "listing" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      {
                        kind: "FragmentSpread",
                        name: {
                          kind: "Name",
                          value: "ShippingLabelListingData",
                        },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "flatRateShippingOptions" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: {
                          kind: "Name",
                          value: "flatRateShippingOptions",
                        },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "carrier" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "displayTitle" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "displayName" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "optionValue" },
                            },
                            {
                              kind: "Field",
                              name: {
                                kind: "Name",
                                value: "displayDimensions",
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "maximumPounds" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "displayPosition" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "countries" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "countries" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      {
                        kind: "FragmentSpread",
                        name: {
                          kind: "Name",
                          value: "ShippingLabelCountriesData",
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "ShippingLabelOrderData" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Order" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "buyerUuid" } },
          { kind: "Field", name: { kind: "Name", value: "shippingMethod" } },
          { kind: "Field", name: { kind: "Name", value: "legacyOrderId" } },
          { kind: "Field", name: { kind: "Name", value: "quantity" } },
          { kind: "Field", name: { kind: "Name", value: "listingId" } },
          { kind: "Field", name: { kind: "Name", value: "orderType" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "settlementAmountProductSubtotal" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "amount" } },
                { kind: "Field", name: { kind: "Name", value: "amountCents" } },
                { kind: "Field", name: { kind: "Name", value: "currency" } },
                { kind: "Field", name: { kind: "Name", value: "symbol" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "createdAt" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "seconds" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "paidAt" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "seconds" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "customsInfo" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "description" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "tariffNumber" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "categoryName" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "countryOfOrigin" },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "amountShipping" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "amount" } },
                { kind: "Field", name: { kind: "Name", value: "amountCents" } },
                { kind: "Field", name: { kind: "Name", value: "currency" } },
                { kind: "Field", name: { kind: "Name", value: "symbol" } },
                { kind: "Field", name: { kind: "Name", value: "display" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "carrierCalculatedShippingAmount" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "amount" } },
                { kind: "Field", name: { kind: "Name", value: "amountCents" } },
                { kind: "Field", name: { kind: "Name", value: "currency" } },
                { kind: "Field", name: { kind: "Name", value: "symbol" } },
                { kind: "Field", name: { kind: "Name", value: "display" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "carrierCalculatedCarrier" },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "ShippingLabelListingData" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Listing" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "title" } },
          { kind: "Field", name: { kind: "Name", value: "merchandisingType" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "images" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "transform" },
                      value: {
                        kind: "StringValue",
                        value: "thumb",
                        block: false,
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "count" },
                      value: { kind: "IntValue", value: "1" },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "scope" },
                      value: {
                        kind: "StringValue",
                        value: "photos",
                        block: false,
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "type" },
                      value: {
                        kind: "StringValue",
                        value: "Product",
                        block: false,
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "source" } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "ShippingLabelCountriesData" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "core_apimessages_Country" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "name" } },
          { kind: "Field", name: { kind: "Name", value: "countryCode" } },
          { kind: "Field", name: { kind: "Name", value: "subregionRequired" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "subregions" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "code" } },
                { kind: "Field", name: { kind: "Name", value: "name" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Core_ShippingLabel_EditShipmentForManualOrderQuery,
  Core_ShippingLabel_EditShipmentForManualOrderQueryVariables
>;
export const MultiOrderCustomsInfoQueryDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "MultiOrderCustomsInfoQuery" },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "exchangeRates" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "inverseReverseRates" },
                      value: { kind: "BooleanValue", value: true },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "rates" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "from" } },
                      { kind: "Field", name: { kind: "Name", value: "to" } },
                      { kind: "Field", name: { kind: "Name", value: "rate" } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  MultiOrderCustomsInfoQueryQuery,
  MultiOrderCustomsInfoQueryQueryVariables
>;
export const Core_ShippingLabel_FormDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "Core_ShippingLabel_Form" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "checkoutUuid" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "actionableStatuses" },
          },
          type: {
            kind: "ListType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "me" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "uuid" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "sellerCheckout" },
                  arguments: [
                    {
                      kind: "Argument",
                      name: { kind: "Name", value: "input" },
                      value: {
                        kind: "ObjectValue",
                        fields: [
                          {
                            kind: "ObjectField",
                            name: { kind: "Name", value: "id" },
                            value: {
                              kind: "Variable",
                              name: { kind: "Name", value: "checkoutUuid" },
                            },
                          },
                        ],
                      },
                    },
                  ],
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      {
                        kind: "Field",
                        name: {
                          kind: "Name",
                          value: "ordersForShipmentSearch",
                        },
                        arguments: [
                          {
                            kind: "Argument",
                            name: { kind: "Name", value: "input" },
                            value: {
                              kind: "ObjectValue",
                              fields: [
                                {
                                  kind: "ObjectField",
                                  name: { kind: "Name", value: "offset" },
                                  value: { kind: "IntValue", value: "0" },
                                },
                                {
                                  kind: "ObjectField",
                                  name: { kind: "Name", value: "limit" },
                                  value: { kind: "IntValue", value: "50" },
                                },
                                {
                                  kind: "ObjectField",
                                  name: {
                                    kind: "Name",
                                    value: "actionableStatuses",
                                  },
                                  value: {
                                    kind: "Variable",
                                    name: {
                                      kind: "Name",
                                      value: "actionableStatuses",
                                    },
                                  },
                                },
                              ],
                            },
                          },
                        ],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: {
                                kind: "Name",
                                value: "ordersForShipment",
                              },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "_id" },
                                  },
                                  {
                                    kind: "FragmentSpread",
                                    name: {
                                      kind: "Name",
                                      value: "ShippingLabelOrderData",
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "listing" },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "_id" },
                                        },
                                        {
                                          kind: "FragmentSpread",
                                          name: {
                                            kind: "Name",
                                            value: "ShippingLabelListingData",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "shipmentPackage",
                                          },
                                          selectionSet: {
                                            kind: "SelectionSet",
                                            selections: [
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "_id",
                                                },
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "width",
                                                },
                                                selectionSet: {
                                                  kind: "SelectionSet",
                                                  selections: [
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "value",
                                                      },
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "unit",
                                                      },
                                                    },
                                                  ],
                                                },
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "height",
                                                },
                                                selectionSet: {
                                                  kind: "SelectionSet",
                                                  selections: [
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "value",
                                                      },
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "unit",
                                                      },
                                                    },
                                                  ],
                                                },
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "length",
                                                },
                                                selectionSet: {
                                                  kind: "SelectionSet",
                                                  selections: [
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "value",
                                                      },
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "unit",
                                                      },
                                                    },
                                                  ],
                                                },
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "weight",
                                                },
                                                selectionSet: {
                                                  kind: "SelectionSet",
                                                  selections: [
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "value",
                                                      },
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "unit",
                                                      },
                                                    },
                                                  ],
                                                },
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: {
                          kind: "Name",
                          value: "verifiedShippingAddress",
                        },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "address" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "_id" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "name" },
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "streetAddress",
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "extendedAddress",
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "locality" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "region" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "postalCode" },
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "countryCode",
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "phone" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "regionName" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "country" },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "_id" },
                                        },
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "name" },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: {
                                kind: "Name",
                                value: "verificationStatus",
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "isResidential" },
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: {
                          kind: "Name",
                          value: "flatRateShippingOptions",
                        },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: {
                                kind: "Name",
                                value: "flatRateShippingOptions",
                              },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "carrier" },
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "displayTitle",
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "displayName",
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "optionValue",
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "displayDimensions",
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "maximumPounds",
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "displayPosition",
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "shop" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "address" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "_id" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "name" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "streetAddress" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "extendedAddress" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "locality" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "region" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "postalCode" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "countryCode" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "phone" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "regionName" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "country" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "_id" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "name" },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "countries" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "countries" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      {
                        kind: "FragmentSpread",
                        name: {
                          kind: "Name",
                          value: "ShippingLabelCountriesData",
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "ShippingLabelOrderData" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Order" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "buyerUuid" } },
          { kind: "Field", name: { kind: "Name", value: "shippingMethod" } },
          { kind: "Field", name: { kind: "Name", value: "legacyOrderId" } },
          { kind: "Field", name: { kind: "Name", value: "quantity" } },
          { kind: "Field", name: { kind: "Name", value: "listingId" } },
          { kind: "Field", name: { kind: "Name", value: "orderType" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "settlementAmountProductSubtotal" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "amount" } },
                { kind: "Field", name: { kind: "Name", value: "amountCents" } },
                { kind: "Field", name: { kind: "Name", value: "currency" } },
                { kind: "Field", name: { kind: "Name", value: "symbol" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "createdAt" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "seconds" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "paidAt" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "seconds" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "customsInfo" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "description" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "tariffNumber" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "categoryName" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "countryOfOrigin" },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "amountShipping" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "amount" } },
                { kind: "Field", name: { kind: "Name", value: "amountCents" } },
                { kind: "Field", name: { kind: "Name", value: "currency" } },
                { kind: "Field", name: { kind: "Name", value: "symbol" } },
                { kind: "Field", name: { kind: "Name", value: "display" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "carrierCalculatedShippingAmount" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "amount" } },
                { kind: "Field", name: { kind: "Name", value: "amountCents" } },
                { kind: "Field", name: { kind: "Name", value: "currency" } },
                { kind: "Field", name: { kind: "Name", value: "symbol" } },
                { kind: "Field", name: { kind: "Name", value: "display" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "carrierCalculatedCarrier" },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "ShippingLabelListingData" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Listing" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "title" } },
          { kind: "Field", name: { kind: "Name", value: "merchandisingType" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "images" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "transform" },
                      value: {
                        kind: "StringValue",
                        value: "thumb",
                        block: false,
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "count" },
                      value: { kind: "IntValue", value: "1" },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "scope" },
                      value: {
                        kind: "StringValue",
                        value: "photos",
                        block: false,
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "type" },
                      value: {
                        kind: "StringValue",
                        value: "Product",
                        block: false,
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "source" } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "ShippingLabelCountriesData" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "core_apimessages_Country" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "name" } },
          { kind: "Field", name: { kind: "Name", value: "countryCode" } },
          { kind: "Field", name: { kind: "Name", value: "subregionRequired" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "subregions" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "code" } },
                { kind: "Field", name: { kind: "Name", value: "name" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Core_ShippingLabel_FormQuery,
  Core_ShippingLabel_FormQueryVariables
>;
export const Core_ShippingLabel_FormForManualOrderDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "Core_ShippingLabel_FormForManualOrder" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "orderUuid" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "order" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "uuid" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "orderUuid" },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                {
                  kind: "FragmentSpread",
                  name: { kind: "Name", value: "ShippingLabelOrderData" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "listing" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      {
                        kind: "FragmentSpread",
                        name: {
                          kind: "Name",
                          value: "ShippingLabelListingData",
                        },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "verifiedShippingAddress" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "address" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "_id" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "name" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "streetAddress" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "extendedAddress" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "locality" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "region" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "postalCode" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "countryCode" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "phone" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "regionName" },
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "verificationStatus" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "isResidential" },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "flatRateShippingOptions" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: {
                          kind: "Name",
                          value: "flatRateShippingOptions",
                        },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "carrier" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "displayTitle" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "displayName" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "optionValue" },
                            },
                            {
                              kind: "Field",
                              name: {
                                kind: "Name",
                                value: "displayDimensions",
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "maximumPounds" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "displayPosition" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "me" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "uuid" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "shop" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "address" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "_id" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "name" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "streetAddress" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "extendedAddress" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "locality" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "region" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "postalCode" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "countryCode" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "phone" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "regionName" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "countries" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "countries" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      {
                        kind: "FragmentSpread",
                        name: {
                          kind: "Name",
                          value: "ShippingLabelCountriesData",
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "ShippingLabelOrderData" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Order" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "buyerUuid" } },
          { kind: "Field", name: { kind: "Name", value: "shippingMethod" } },
          { kind: "Field", name: { kind: "Name", value: "legacyOrderId" } },
          { kind: "Field", name: { kind: "Name", value: "quantity" } },
          { kind: "Field", name: { kind: "Name", value: "listingId" } },
          { kind: "Field", name: { kind: "Name", value: "orderType" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "settlementAmountProductSubtotal" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "amount" } },
                { kind: "Field", name: { kind: "Name", value: "amountCents" } },
                { kind: "Field", name: { kind: "Name", value: "currency" } },
                { kind: "Field", name: { kind: "Name", value: "symbol" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "createdAt" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "seconds" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "paidAt" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "seconds" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "customsInfo" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "description" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "tariffNumber" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "categoryName" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "countryOfOrigin" },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "amountShipping" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "amount" } },
                { kind: "Field", name: { kind: "Name", value: "amountCents" } },
                { kind: "Field", name: { kind: "Name", value: "currency" } },
                { kind: "Field", name: { kind: "Name", value: "symbol" } },
                { kind: "Field", name: { kind: "Name", value: "display" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "carrierCalculatedShippingAmount" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "amount" } },
                { kind: "Field", name: { kind: "Name", value: "amountCents" } },
                { kind: "Field", name: { kind: "Name", value: "currency" } },
                { kind: "Field", name: { kind: "Name", value: "symbol" } },
                { kind: "Field", name: { kind: "Name", value: "display" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "carrierCalculatedCarrier" },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "ShippingLabelListingData" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Listing" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "title" } },
          { kind: "Field", name: { kind: "Name", value: "merchandisingType" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "images" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "transform" },
                      value: {
                        kind: "StringValue",
                        value: "thumb",
                        block: false,
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "count" },
                      value: { kind: "IntValue", value: "1" },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "scope" },
                      value: {
                        kind: "StringValue",
                        value: "photos",
                        block: false,
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "type" },
                      value: {
                        kind: "StringValue",
                        value: "Product",
                        block: false,
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "source" } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "ShippingLabelCountriesData" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "core_apimessages_Country" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "name" } },
          { kind: "Field", name: { kind: "Name", value: "countryCode" } },
          { kind: "Field", name: { kind: "Name", value: "subregionRequired" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "subregions" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "code" } },
                { kind: "Field", name: { kind: "Name", value: "name" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Core_ShippingLabel_FormForManualOrderQuery,
  Core_ShippingLabel_FormForManualOrderQueryVariables
>;
export const ShippingProtectionDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "ShippingProtection" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "input" },
          },
          type: {
            kind: "NamedType",
            name: {
              kind: "Name",
              value: "Input_core_apimessages_ShippingProtectionRequest",
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "shippingProtection" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "input" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "price" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "amount" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "amountCents" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "currency" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "symbol" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "display" },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "shippingProtectionStatus" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "signatureRequiredStatus" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "signatureRequiredCosts" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "carrier" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "amount" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "display" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  ShippingProtectionMutation,
  ShippingProtectionMutationVariables
>;
export const CreateShipmentDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "CreateShipment" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "input" },
          },
          type: {
            kind: "NamedType",
            name: {
              kind: "Name",
              value: "Input_core_apimessages_CreateShipmentRequest",
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "createShipment" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "input" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "id" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "toAddress" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      { kind: "Field", name: { kind: "Name", value: "name" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "streetAddress" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "extendedAddress" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "postalCode" },
                      },
                      { kind: "Field", name: { kind: "Name", value: "phone" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "region" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "locality" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "countryCode" },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "fromAddress" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      { kind: "Field", name: { kind: "Name", value: "name" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "streetAddress" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "extendedAddress" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "postalCode" },
                      },
                      { kind: "Field", name: { kind: "Name", value: "phone" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "region" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "locality" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "countryCode" },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "package" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "predefinedPackage" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "length" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "value" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "unit" },
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "height" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "value" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "unit" },
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "width" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "value" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "unit" },
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "weight" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "value" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "unit" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "shippingProtection" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "signatureRequired" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "mediaMailQualified" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "defaultPaymentMethod" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "carrierRates" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "displayPosition" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "carrier" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "rates" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "displayPosition" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "uuid" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "carrier" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "service" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "description" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "paymentMethod" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "rackRateAmount" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "amount" },
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "amountCents",
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "currency" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "symbol" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "display" },
                                  },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: {
                                kind: "Name",
                                value: "shippingProtectionAmount",
                              },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "amount" },
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "amountCents",
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "currency" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "symbol" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "display" },
                                  },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: {
                                kind: "Name",
                                value: "reverbDiscountAmount",
                              },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "amount" },
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "amountCents",
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "currency" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "symbol" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "display" },
                                  },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "totalAmount" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "amount" },
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "amountCents",
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "currency" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "symbol" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "display" },
                                  },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: {
                                kind: "Name",
                                value: "signatureRequiredAmount",
                              },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "amount" },
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "amountCents",
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "currency" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "symbol" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "display" },
                                  },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: {
                                kind: "Name",
                                value: "expeditedOrFaster",
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "shippingMethod" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "toAddressIsResidential" },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CreateShipmentMutation,
  CreateShipmentMutationVariables
>;
export const PurchaseShippingRateDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "PurchaseShippingRate" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "input" },
          },
          type: {
            kind: "NamedType",
            name: {
              kind: "Name",
              value: "Input_core_apimessages_PurchaseShippingRateRequest",
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "purchaseShippingRate" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "input" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "errors" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "message" },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  PurchaseShippingRateMutation,
  PurchaseShippingRateMutationVariables
>;
export const Core_ShippingLabel_ShipmentsDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "Core_ShippingLabel_Shipments" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "shipmentId" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "me" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "uuid" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "shipments" },
                  arguments: [
                    {
                      kind: "Argument",
                      name: { kind: "Name", value: "input" },
                      value: {
                        kind: "ObjectValue",
                        fields: [
                          {
                            kind: "ObjectField",
                            name: { kind: "Name", value: "ids" },
                            value: {
                              kind: "ListValue",
                              values: [
                                {
                                  kind: "Variable",
                                  name: { kind: "Name", value: "shipmentId" },
                                },
                              ],
                            },
                          },
                        ],
                      },
                    },
                  ],
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "orders" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "_id" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "legacyOrderId" },
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "toAddress" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "_id" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "name" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "streetAddress" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "extendedAddress" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "postalCode" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "phone" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "region" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "locality" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "countryCode" },
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "fromAddress" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "_id" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "name" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "streetAddress" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "extendedAddress" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "postalCode" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "phone" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "region" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "locality" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "countryCode" },
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "package" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: {
                                kind: "Name",
                                value: "predefinedPackage",
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "length" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "value" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "unit" },
                                  },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "height" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "value" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "unit" },
                                  },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "width" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "value" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "unit" },
                                  },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "weight" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "value" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "unit" },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "shippingProtection" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "signatureRequired" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "mediaMailQualified" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "defaultPaymentMethod" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "carrierRates" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "displayPosition" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "carrier" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "rates" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "displayPosition",
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "uuid" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "carrier" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "service" },
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "description",
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "paymentMethod",
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "rackRateAmount",
                                    },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "amount",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "amountCents",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "currency",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "symbol",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "display",
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "shippingProtectionAmount",
                                    },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "amount",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "amountCents",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "currency",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "symbol",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "display",
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "reverbDiscountAmount",
                                    },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "amount",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "amountCents",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "currency",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "symbol",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "display",
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "totalAmount",
                                    },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "amount",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "amountCents",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "currency",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "symbol",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "display",
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "signatureRequiredAmount",
                                    },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "amount",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "amountCents",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "currency",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "symbol",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "display",
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "expeditedOrFaster",
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "shippingMethod" },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Core_ShippingLabel_ShipmentsQuery,
  Core_ShippingLabel_ShipmentsQueryVariables
>;
export const Core_Shop_ListingsCountDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "Core_Shop_ListingsCount" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "shop" } },
          type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            alias: { kind: "Name", value: "shopListingsLive" },
            name: { kind: "Name", value: "listingsSearch" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "shopSlug" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "shop" },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "total" } },
              ],
            },
          },
          {
            kind: "Field",
            alias: { kind: "Name", value: "shopListingsAll" },
            name: { kind: "Name", value: "listingsSearch" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "shopSlug" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "shop" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "showSold" },
                      value: { kind: "BooleanValue", value: true },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "total" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Core_Shop_ListingsCountQuery,
  Core_Shop_ListingsCountQueryVariables
>;
export const Core_Shop_FindFavoriteDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "Core_Shop_FindFavorite" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "shop" } },
          type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "loggedOut" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "Boolean" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "findFavorite" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "shopSlug" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "shop" },
                      },
                    },
                  ],
                },
              },
            ],
            directives: [
              {
                kind: "Directive",
                name: { kind: "Name", value: "skip" },
                arguments: [
                  {
                    kind: "Argument",
                    name: { kind: "Name", value: "if" },
                    value: {
                      kind: "Variable",
                      name: { kind: "Name", value: "loggedOut" },
                    },
                  },
                ],
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "favorite" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Core_Shop_FindFavoriteQuery,
  Core_Shop_FindFavoriteQueryVariables
>;
export const Core_Shop_ContainerDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "Core_Shop_Container" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "shop" } },
          type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "shop" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "slug" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "shop" },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                {
                  kind: "FragmentSpread",
                  name: { kind: "Name", value: "ShopContainerFragment" },
                },
                {
                  kind: "FragmentSpread",
                  name: { kind: "Name", value: "ShopHeaderFragment" },
                },
                {
                  kind: "FragmentSpread",
                  name: { kind: "Name", value: "ShopMetadataFragment" },
                },
                {
                  kind: "FragmentSpread",
                  name: { kind: "Name", value: "ShopTabsFragment" },
                },
                {
                  kind: "FragmentSpread",
                  name: { kind: "Name", value: "FavoriteShopCTAData" },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "me" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "uuid" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "buyerCoupons" },
                  arguments: [
                    {
                      kind: "Argument",
                      name: { kind: "Name", value: "input" },
                      value: {
                        kind: "ObjectValue",
                        fields: [
                          {
                            kind: "ObjectField",
                            name: { kind: "Name", value: "context" },
                            value: { kind: "EnumValue", value: "SHOP" },
                          },
                          {
                            kind: "ObjectField",
                            name: { kind: "Name", value: "shopSlug" },
                            value: {
                              kind: "Variable",
                              name: { kind: "Name", value: "shop" },
                            },
                          },
                        ],
                      },
                    },
                  ],
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "shopCampaignCoupons" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "_id" },
                            },
                            {
                              kind: "FragmentSpread",
                              name: {
                                kind: "Name",
                                value: "MyShopCampaignCouponFields",
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "ShopContainerFragment" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Shop" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "showSold" } },
          { kind: "Field", name: { kind: "Name", value: "slug" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "freeDomesticShippingRate" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "regionCode" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "freeShippingThreshold" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "display" },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "sales" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "slug" } },
                { kind: "Field", name: { kind: "Name", value: "name" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "endsAt" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "seconds" },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "discountValue" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "buyerIneligibilityReason" },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "ShopHeaderFragment" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Shop" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "slug" } },
          { kind: "Field", name: { kind: "Name", value: "name" } },
          { kind: "Field", name: { kind: "Name", value: "preferredSeller" } },
          { kind: "Field", name: { kind: "Name", value: "quickShipper" } },
          { kind: "Field", name: { kind: "Name", value: "quickResponder" } },
          { kind: "Field", name: { kind: "Name", value: "description" } },
          { kind: "Field", name: { kind: "Name", value: "userUuid" } },
          { kind: "Field", name: { kind: "Name", value: "userId" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "address" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "displayLocation" },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "banner" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "source" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "user" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "id" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "avatar" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "source" },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "ShopMetadataFragment" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Shop" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "name" } },
          { kind: "Field", name: { kind: "Name", value: "slug" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "user" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "feedbackSummary" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: {
                          kind: "Name",
                          value: "rollingRatingPercentage",
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "receivedCount" },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "ShopTabsFragment" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Shop" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "user" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "feedbackSummary" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: {
                          kind: "Name",
                          value: "rollingRatingPercentage",
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "receivedCount" },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "FavoriteShopCTAData" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Shop" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "name" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "address" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "countryCode" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "user" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "feedbackSummary" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: {
                          kind: "Name",
                          value: "rollingRatingPercentage",
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "receivedCount" },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "MyShopCampaignCouponFields" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "core_apimessages_ShopCampaignCoupon" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "code" } },
          { kind: "Field", name: { kind: "Name", value: "discountValue" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "descriptionSummary" },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "restrictionsSummary" },
          },
          { kind: "Field", name: { kind: "Name", value: "expiresAt" } },
          { kind: "Field", name: { kind: "Name", value: "status" } },
          { kind: "Field", name: { kind: "Name", value: "couponHeader" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "applyToNewConditions" },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "applyToUsedConditions" },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "applyToBStockConditions" },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "excludedBrands" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "name" } },
                { kind: "Field", name: { kind: "Name", value: "slug" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "excludedCategories" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "name" } },
                { kind: "Field", name: { kind: "Name", value: "id" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Core_Shop_ContainerQuery,
  Core_Shop_ContainerQueryVariables
>;
export const Core_Shop_FeedbackDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "Core_Shop_Feedback" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "shop" } },
          type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "shop" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "slug" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "shop" },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "slug" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Core_Shop_FeedbackQuery,
  Core_Shop_FeedbackQueryVariables
>;
export const Core_Shop_InfoDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "Core_Shop_Info" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "shop" } },
          type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "shop" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "slug" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "shop" },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "description" } },
                { kind: "Field", name: { kind: "Name", value: "website" } },
                { kind: "Field", name: { kind: "Name", value: "name" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "shippingPolicy" },
                },
                {
                  kind: "Field",
                  name: {
                    kind: "Name",
                    value: "manufacturersSafetyInformation",
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "video" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "youtubeVideoId" },
                      },
                      { kind: "Field", name: { kind: "Name", value: "empty" } },
                    ],
                  },
                },
                {
                  kind: "FragmentSpread",
                  name: { kind: "Name", value: "ShopReturnPoliciesFragment" },
                },
                {
                  kind: "FragmentSpread",
                  name: { kind: "Name", value: "ShopTaxPoliciesData" },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "ShopReturnPoliciesFragment" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Shop" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "userUuid" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "returnPolicy" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "newReturnWindowDays" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "usedReturnWindowDays" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "restockingFeePercent" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "specialConditions" },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "ShopTaxPoliciesData" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Shop" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "taxPolicies" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "displayLocation" },
                },
                { kind: "Field", name: { kind: "Name", value: "displayRate" } },
                { kind: "Field", name: { kind: "Name", value: "policyType" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<Core_Shop_InfoQuery, Core_Shop_InfoQueryVariables>;
export const Core_Listing_SimilarListingsGridDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "Core_Listing_SimilarListingsGrid" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "acceptsAffirm" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "Boolean" } },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "acceptsGiftCards" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "Boolean" } },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "acceptsOffers" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "Boolean" } },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "acceptsPaymentPlans" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "Boolean" } },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "brandSlugs" },
          },
          type: {
            kind: "ListType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "categorySlugs" },
          },
          type: {
            kind: "ListType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "combinedShipping" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "Boolean" } },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "conditionSlugs" },
          },
          type: {
            kind: "ListType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "contexts" },
          },
          type: {
            kind: "ListType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "reverb_search_Context" },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "cspSlug" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "countryOfOrigin" },
          },
          type: {
            kind: "ListType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "curatedSetSlugs" },
          },
          type: {
            kind: "ListType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "currency" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "decades" },
          },
          type: {
            kind: "ListType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "fallbackToOr" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "Boolean" } },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "freeShipping" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "Boolean" } },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "freeExpeditedShipping" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "Boolean" } },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "handmade" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "Boolean" } },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "holidaySale" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "Boolean" } },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "itemCity" },
          },
          type: {
            kind: "ListType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "itemRegion" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "itemState" },
          },
          type: {
            kind: "ListType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "limit" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "Int" } },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "localPickup" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "Boolean" } },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "minSaleDiscountPercent" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "multiClientExperiments" },
          },
          type: {
            kind: "ListType",
            type: {
              kind: "NamedType",
              name: {
                kind: "Name",
                value: "Input_reverb_search_ExperimentData",
              },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "preferredSeller" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "Boolean" } },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "priceMax" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "priceMin" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "offset" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "Int" } },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "onSale" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "Boolean" } },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "query" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "saleSlugs" },
          },
          type: {
            kind: "ListType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "shippingRegionCode" },
          },
          type: {
            kind: "ListType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "showOnlySold" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "Boolean" } },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "shopSlug" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "showSold" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "Boolean" } },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "similarListingIds" },
          },
          type: {
            kind: "ListType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "sortSlug" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "terminateEarly" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "Boolean" } },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "traitValues" },
          },
          type: {
            kind: "ListType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "useExperimentalRecall" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "Boolean" } },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "withAggregations" },
          },
          type: {
            kind: "ListType",
            type: {
              kind: "NamedType",
              name: {
                kind: "Name",
                value: "reverb_search_ListingsSearchRequest_Aggregation",
              },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "withProximityFilter" },
          },
          type: {
            kind: "NamedType",
            name: {
              kind: "Name",
              value: "Input_reverb_search_ProximityFilterRequest",
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "yearMax" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "yearMin" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "listingsSearch" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "acceptsGiftCards" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "acceptsGiftCards" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "acceptsAffirm" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "acceptsAffirm" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "acceptsOffers" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "acceptsOffers" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "acceptsPaymentPlans" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "acceptsPaymentPlans" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "brandSlugs" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "brandSlugs" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "categorySlugs" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "categorySlugs" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "combinedShipping" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "combinedShipping" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "conditionSlugs" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "conditionSlugs" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "contexts" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "contexts" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "cspSlug" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "cspSlug" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "countryOfOrigin" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "countryOfOrigin" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "curatedSetSlugs" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "curatedSetSlugs" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "currency" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "currency" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "decades" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "decades" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "fallbackToOr" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "fallbackToOr" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "freeShipping" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "freeShipping" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "freeExpeditedShipping" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "freeExpeditedShipping" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "handmade" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "handmade" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "holidaySale" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "holidaySale" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "itemCity" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "itemCity" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "itemRegion" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "itemRegion" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "itemState" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "itemState" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "limit" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "limit" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "localPickup" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "localPickup" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "minSaleDiscountPercent" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "minSaleDiscountPercent" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "multiClientExperiments" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "multiClientExperiments" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "preferredSeller" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "preferredSeller" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "priceMin" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "priceMin" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "priceMax" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "priceMax" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "offset" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "offset" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "onSale" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "onSale" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "query" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "query" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "saleSlugs" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "saleSlugs" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "shippingRegionCodes" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "shippingRegionCode" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "showOnlySold" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "showOnlySold" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "shopSlug" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "shopSlug" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "showSold" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "showSold" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "similarListingIds" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "similarListingIds" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "sortSlug" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "sortSlug" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "terminateEarly" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "terminateEarly" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "traitValues" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "traitValues" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "useExperimentalRecall" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "useExperimentalRecall" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "withAggregations" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "withAggregations" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "withProximityFilter" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "withProximityFilter" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "yearMax" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "yearMax" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "yearMin" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "yearMin" },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "listings" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      {
                        kind: "FragmentSpread",
                        name: { kind: "Name", value: "SimilarListingsFields" },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "filters" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "FragmentSpread",
                        name: { kind: "Name", value: "NestedFilter" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "options" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "selected" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "autoselected" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                { kind: "Field", name: { kind: "Name", value: "total" } },
                { kind: "Field", name: { kind: "Name", value: "offset" } },
                { kind: "Field", name: { kind: "Name", value: "limit" } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "ListingForBuyerShippingFields" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Listing" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "shipping" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "freeExpeditedShipping" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "localPickupOnly" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "shippingPrices" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "shippingMethod" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "carrierCalculated" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "regional" },
                      },
                      {
                        kind: "Field",
                        name: {
                          kind: "Name",
                          value: "destinationPostalCodeNeeded",
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "postalCode" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "rate" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "amount" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "amountCents" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "currency" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "display" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "ListingGreatValueData" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Listing" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "pricing" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "buyerPrice" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "currency" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "amountCents" },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "condition" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "conditionSlug" },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "priceRecommendation" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "priceMiddle" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "amountCents" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "currency" },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "shop" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "address" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "country" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "_id" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "countryCode" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          { kind: "Field", name: { kind: "Name", value: "currency" } },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "ListingCardCPOData" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Listing" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "certifiedPreOwned" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "title" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "badgeIconUrl" },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "ListingForBuyerFields" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Listing" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "title" } },
          { kind: "Field", name: { kind: "Name", value: "slug" } },
          { kind: "Field", name: { kind: "Name", value: "listingType" } },
          { kind: "Field", name: { kind: "Name", value: "make" } },
          { kind: "Field", name: { kind: "Name", value: "model" } },
          { kind: "Field", name: { kind: "Name", value: "upc" } },
          { kind: "Field", name: { kind: "Name", value: "state" } },
          { kind: "Field", name: { kind: "Name", value: "stateDescription" } },
          { kind: "Field", name: { kind: "Name", value: "bumped" } },
          { kind: "Field", name: { kind: "Name", value: "watching" } },
          { kind: "Field", name: { kind: "Name", value: "soldAsIs" } },
          { kind: "Field", name: { kind: "Name", value: "usOutlet" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "publishedAt" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "seconds" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "condition" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "displayName" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "conditionSlug" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "conditionUuid" },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "pricing" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "buyerPrice" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "display" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "currency" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "amount" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "amountCents" },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "originalPrice" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "display" },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "ribbon" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "display" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "reason" },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "images" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "transform" },
                      value: {
                        kind: "StringValue",
                        value: "card_square",
                        block: false,
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "count" },
                      value: { kind: "IntValue", value: "3" },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "scope" },
                      value: {
                        kind: "StringValue",
                        value: "photos",
                        block: false,
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "type" },
                      value: {
                        kind: "StringValue",
                        value: "Product",
                        block: false,
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "source" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "shipping" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "shippingPrices" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "shippingMethod" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "carrierCalculated" },
                      },
                      {
                        kind: "Field",
                        name: {
                          kind: "Name",
                          value: "destinationPostalCodeNeeded",
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "rate" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "amount" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "amountCents" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "currency" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "display" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "freeExpeditedShipping" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "localPickupOnly" },
                },
                { kind: "Field", name: { kind: "Name", value: "localPickup" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "shop" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "name" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "returnPolicy" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "usedReturnWindowDays" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "newReturnWindowDays" },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "address" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "locality" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "region" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "country" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "_id" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "countryCode" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "name" },
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "displayLocation" },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "ListingForBuyerShippingFields" },
          },
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "ListingGreatValueData" },
          },
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "ListingCardCPOData" },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "WatchBadgeData" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Listing" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "title" } },
          { kind: "Field", name: { kind: "Name", value: "sellerId" } },
          { kind: "Field", name: { kind: "Name", value: "watching" } },
          {
            kind: "Field",
            alias: { kind: "Name", value: "watchThumbnails" },
            name: { kind: "Name", value: "images" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "type" },
                      value: {
                        kind: "StringValue",
                        value: "Product",
                        block: false,
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "scope" },
                      value: {
                        kind: "StringValue",
                        value: "photos",
                        block: false,
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "transform" },
                      value: {
                        kind: "StringValue",
                        value: "card_square",
                        block: false,
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "count" },
                      value: { kind: "IntValue", value: "1" },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "source" } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "mParticleListingFields" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Listing" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "title" } },
          { kind: "Field", name: { kind: "Name", value: "brandSlug" } },
          { kind: "Field", name: { kind: "Name", value: "categoryRootUuid" } },
          { kind: "Field", name: { kind: "Name", value: "make" } },
          { kind: "Field", name: { kind: "Name", value: "categoryUuids" } },
          { kind: "Field", name: { kind: "Name", value: "state" } },
          { kind: "Field", name: { kind: "Name", value: "listingType" } },
          { kind: "Field", name: { kind: "Name", value: "bumpEligible" } },
          { kind: "Field", name: { kind: "Name", value: "shopId" } },
          { kind: "Field", name: { kind: "Name", value: "inventory" } },
          { kind: "Field", name: { kind: "Name", value: "soldAsIs" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "acceptedPaymentMethods" },
          },
          { kind: "Field", name: { kind: "Name", value: "currency" } },
          { kind: "Field", name: { kind: "Name", value: "usOutlet" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "condition" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "conditionUuid" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "conditionSlug" },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "categories" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "slug" } },
                { kind: "Field", name: { kind: "Name", value: "rootSlug" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "csp" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "slug" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "brand" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      { kind: "Field", name: { kind: "Name", value: "slug" } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "pricing" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "buyerPrice" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "amount" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "currency" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "amountCents" },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "publishedAt" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "seconds" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "sale" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "code" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "buyerIneligibilityReason" },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "shipping" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "shippingPrices" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "shippingMethod" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "carrierCalculated" },
                      },
                      {
                        kind: "Field",
                        name: {
                          kind: "Name",
                          value: "destinationPostalCodeNeeded",
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "rate" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "amount" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "amountCents" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "currency" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "display" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "freeExpeditedShipping" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "localPickupOnly" },
                },
                { kind: "Field", name: { kind: "Name", value: "localPickup" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "certifiedPreOwned" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "title" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "badgeIconUrl" },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "shop" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "address" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "countryCode" },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "returnPolicy" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "newReturnWindowDays" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "usedReturnWindowDays" },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "ListingCreateOfferButtonData" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Listing" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "state" } },
          { kind: "Field", name: { kind: "Name", value: "offersEnabled" } },
          { kind: "Field", name: { kind: "Name", value: "listingType" } },
          { kind: "Field", name: { kind: "Name", value: "sellerId" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "isBuyerOfferEligible" },
          },
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "mParticleListingFields" },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "ListingCardFields" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Listing" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "ListingForBuyerFields" },
          },
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "WatchBadgeData" },
          },
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "ListingCreateOfferButtonData" },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "AddToCartButtonFields" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Listing" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "sellerId" } },
          { kind: "Field", name: { kind: "Name", value: "listingType" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "pricing" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "buyerPrice" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "amount" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "amountCents" },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "preorderInfo" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "onPreorder" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "estimatedShipDate" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "seconds" },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "SimilarListingsFields" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Listing" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "ListingCardFields" },
          },
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "AddToCartButtonFields" },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "NestedFilter" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "reverb_search_Filter" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "name" } },
          { kind: "Field", name: { kind: "Name", value: "key" } },
          { kind: "Field", name: { kind: "Name", value: "aggregationName" } },
          { kind: "Field", name: { kind: "Name", value: "widgetType" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "options" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "count" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "value" } },
                    ],
                  },
                },
                { kind: "Field", name: { kind: "Name", value: "name" } },
                { kind: "Field", name: { kind: "Name", value: "selected" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "autoselected" },
                },
                { kind: "Field", name: { kind: "Name", value: "paramName" } },
                { kind: "Field", name: { kind: "Name", value: "setValues" } },
                { kind: "Field", name: { kind: "Name", value: "unsetValues" } },
                { kind: "Field", name: { kind: "Name", value: "all" } },
                { kind: "Field", name: { kind: "Name", value: "optionValue" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "trackingValue" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "subFilter" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "widgetType" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "options" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "count" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "value" },
                                  },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "name" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "selected" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "autoselected" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "paramName" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "setValues" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "unsetValues" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "all" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "optionValue" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "trackingValue" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "subFilter" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "widgetType" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "options" },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "count",
                                          },
                                          selectionSet: {
                                            kind: "SelectionSet",
                                            selections: [
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "value",
                                                },
                                              },
                                            ],
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "name" },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "selected",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "autoselected",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "paramName",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "setValues",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "unsetValues",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "all" },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "optionValue",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "trackingValue",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "subFilter",
                                          },
                                          selectionSet: {
                                            kind: "SelectionSet",
                                            selections: [
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "widgetType",
                                                },
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "options",
                                                },
                                                selectionSet: {
                                                  kind: "SelectionSet",
                                                  selections: [
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "count",
                                                      },
                                                      selectionSet: {
                                                        kind: "SelectionSet",
                                                        selections: [
                                                          {
                                                            kind: "Field",
                                                            name: {
                                                              kind: "Name",
                                                              value: "value",
                                                            },
                                                          },
                                                        ],
                                                      },
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "name",
                                                      },
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "selected",
                                                      },
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "autoselected",
                                                      },
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "paramName",
                                                      },
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "setValues",
                                                      },
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "unsetValues",
                                                      },
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "all",
                                                      },
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "optionValue",
                                                      },
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "trackingValue",
                                                      },
                                                    },
                                                  ],
                                                },
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Core_Listing_SimilarListingsGridQuery,
  Core_Listing_SimilarListingsGridQueryVariables
>;
export const Core_Listing_SimilarListingsDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "Core_Listing_SimilarListings" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "listingId" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "itemRegion" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "itemRegionRelation" },
          },
          type: {
            kind: "NamedType",
            name: {
              kind: "Name",
              value: "reverb_search_ListingItemRegionRelation",
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "listing" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "id" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "listingId" },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "similarListings" },
                  arguments: [
                    {
                      kind: "Argument",
                      name: { kind: "Name", value: "input" },
                      value: {
                        kind: "ObjectValue",
                        fields: [
                          {
                            kind: "ObjectField",
                            name: {
                              kind: "Name",
                              value: "includeForLiveListings",
                            },
                            value: { kind: "BooleanValue", value: true },
                          },
                          {
                            kind: "ObjectField",
                            name: { kind: "Name", value: "itemRegion" },
                            value: {
                              kind: "Variable",
                              name: { kind: "Name", value: "itemRegion" },
                            },
                          },
                          {
                            kind: "ObjectField",
                            name: { kind: "Name", value: "itemRegionRelation" },
                            value: {
                              kind: "Variable",
                              name: {
                                kind: "Name",
                                value: "itemRegionRelation",
                              },
                            },
                          },
                          {
                            kind: "ObjectField",
                            name: { kind: "Name", value: "limit" },
                            value: { kind: "IntValue", value: "10" },
                          },
                        ],
                      },
                    },
                  ],
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      {
                        kind: "FragmentSpread",
                        name: { kind: "Name", value: "SimilarListingsFields" },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "ListingForBuyerShippingFields" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Listing" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "shipping" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "freeExpeditedShipping" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "localPickupOnly" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "shippingPrices" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "shippingMethod" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "carrierCalculated" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "regional" },
                      },
                      {
                        kind: "Field",
                        name: {
                          kind: "Name",
                          value: "destinationPostalCodeNeeded",
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "postalCode" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "rate" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "amount" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "amountCents" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "currency" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "display" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "ListingGreatValueData" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Listing" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "pricing" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "buyerPrice" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "currency" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "amountCents" },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "condition" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "conditionSlug" },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "priceRecommendation" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "priceMiddle" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "amountCents" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "currency" },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "shop" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "address" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "country" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "_id" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "countryCode" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          { kind: "Field", name: { kind: "Name", value: "currency" } },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "ListingCardCPOData" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Listing" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "certifiedPreOwned" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "title" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "badgeIconUrl" },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "ListingForBuyerFields" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Listing" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "title" } },
          { kind: "Field", name: { kind: "Name", value: "slug" } },
          { kind: "Field", name: { kind: "Name", value: "listingType" } },
          { kind: "Field", name: { kind: "Name", value: "make" } },
          { kind: "Field", name: { kind: "Name", value: "model" } },
          { kind: "Field", name: { kind: "Name", value: "upc" } },
          { kind: "Field", name: { kind: "Name", value: "state" } },
          { kind: "Field", name: { kind: "Name", value: "stateDescription" } },
          { kind: "Field", name: { kind: "Name", value: "bumped" } },
          { kind: "Field", name: { kind: "Name", value: "watching" } },
          { kind: "Field", name: { kind: "Name", value: "soldAsIs" } },
          { kind: "Field", name: { kind: "Name", value: "usOutlet" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "publishedAt" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "seconds" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "condition" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "displayName" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "conditionSlug" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "conditionUuid" },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "pricing" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "buyerPrice" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "display" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "currency" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "amount" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "amountCents" },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "originalPrice" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "display" },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "ribbon" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "display" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "reason" },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "images" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "transform" },
                      value: {
                        kind: "StringValue",
                        value: "card_square",
                        block: false,
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "count" },
                      value: { kind: "IntValue", value: "3" },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "scope" },
                      value: {
                        kind: "StringValue",
                        value: "photos",
                        block: false,
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "type" },
                      value: {
                        kind: "StringValue",
                        value: "Product",
                        block: false,
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "source" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "shipping" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "shippingPrices" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "shippingMethod" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "carrierCalculated" },
                      },
                      {
                        kind: "Field",
                        name: {
                          kind: "Name",
                          value: "destinationPostalCodeNeeded",
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "rate" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "amount" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "amountCents" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "currency" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "display" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "freeExpeditedShipping" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "localPickupOnly" },
                },
                { kind: "Field", name: { kind: "Name", value: "localPickup" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "shop" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "name" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "returnPolicy" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "usedReturnWindowDays" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "newReturnWindowDays" },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "address" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "locality" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "region" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "country" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "_id" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "countryCode" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "name" },
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "displayLocation" },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "ListingForBuyerShippingFields" },
          },
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "ListingGreatValueData" },
          },
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "ListingCardCPOData" },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "WatchBadgeData" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Listing" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "title" } },
          { kind: "Field", name: { kind: "Name", value: "sellerId" } },
          { kind: "Field", name: { kind: "Name", value: "watching" } },
          {
            kind: "Field",
            alias: { kind: "Name", value: "watchThumbnails" },
            name: { kind: "Name", value: "images" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "type" },
                      value: {
                        kind: "StringValue",
                        value: "Product",
                        block: false,
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "scope" },
                      value: {
                        kind: "StringValue",
                        value: "photos",
                        block: false,
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "transform" },
                      value: {
                        kind: "StringValue",
                        value: "card_square",
                        block: false,
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "count" },
                      value: { kind: "IntValue", value: "1" },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "source" } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "mParticleListingFields" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Listing" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "title" } },
          { kind: "Field", name: { kind: "Name", value: "brandSlug" } },
          { kind: "Field", name: { kind: "Name", value: "categoryRootUuid" } },
          { kind: "Field", name: { kind: "Name", value: "make" } },
          { kind: "Field", name: { kind: "Name", value: "categoryUuids" } },
          { kind: "Field", name: { kind: "Name", value: "state" } },
          { kind: "Field", name: { kind: "Name", value: "listingType" } },
          { kind: "Field", name: { kind: "Name", value: "bumpEligible" } },
          { kind: "Field", name: { kind: "Name", value: "shopId" } },
          { kind: "Field", name: { kind: "Name", value: "inventory" } },
          { kind: "Field", name: { kind: "Name", value: "soldAsIs" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "acceptedPaymentMethods" },
          },
          { kind: "Field", name: { kind: "Name", value: "currency" } },
          { kind: "Field", name: { kind: "Name", value: "usOutlet" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "condition" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "conditionUuid" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "conditionSlug" },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "categories" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "slug" } },
                { kind: "Field", name: { kind: "Name", value: "rootSlug" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "csp" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "slug" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "brand" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      { kind: "Field", name: { kind: "Name", value: "slug" } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "pricing" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "buyerPrice" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "amount" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "currency" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "amountCents" },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "publishedAt" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "seconds" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "sale" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "code" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "buyerIneligibilityReason" },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "shipping" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "shippingPrices" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "shippingMethod" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "carrierCalculated" },
                      },
                      {
                        kind: "Field",
                        name: {
                          kind: "Name",
                          value: "destinationPostalCodeNeeded",
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "rate" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "amount" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "amountCents" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "currency" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "display" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "freeExpeditedShipping" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "localPickupOnly" },
                },
                { kind: "Field", name: { kind: "Name", value: "localPickup" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "certifiedPreOwned" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "title" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "badgeIconUrl" },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "shop" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "address" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "countryCode" },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "returnPolicy" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "newReturnWindowDays" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "usedReturnWindowDays" },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "ListingCreateOfferButtonData" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Listing" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "state" } },
          { kind: "Field", name: { kind: "Name", value: "offersEnabled" } },
          { kind: "Field", name: { kind: "Name", value: "listingType" } },
          { kind: "Field", name: { kind: "Name", value: "sellerId" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "isBuyerOfferEligible" },
          },
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "mParticleListingFields" },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "ListingCardFields" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Listing" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "ListingForBuyerFields" },
          },
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "WatchBadgeData" },
          },
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "ListingCreateOfferButtonData" },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "AddToCartButtonFields" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Listing" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "sellerId" } },
          { kind: "Field", name: { kind: "Name", value: "listingType" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "pricing" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "buyerPrice" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "amount" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "amountCents" },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "preorderInfo" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "onPreorder" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "estimatedShipDate" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "seconds" },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "SimilarListingsFields" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Listing" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "ListingCardFields" },
          },
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "AddToCartButtonFields" },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Core_Listing_SimilarListingsQuery,
  Core_Listing_SimilarListingsQueryVariables
>;
export const Core_SiteBannerHeroDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "Core_SiteBannerHero" },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "siteBanner" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "heading" } },
                { kind: "Field", name: { kind: "Name", value: "subheading" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "callToAction" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "url" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "href" } },
                    ],
                  },
                },
                { kind: "Field", name: { kind: "Name", value: "hasPhotos" } },
                { kind: "Field", name: { kind: "Name", value: "imageUrl" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "contentSponsorship" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "sponsorshipType" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "partnershipName" },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Core_SiteBannerHeroQuery,
  Core_SiteBannerHeroQueryVariables
>;
export const Core_Listing_EuOdrAddressDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "Core_Listing_EuOdrAddress" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "slug" } },
          type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "shop" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "slug" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "slug" },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                {
                  kind: "FragmentSpread",
                  name: { kind: "Name", value: "EUODRAddress" },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "EUODRAddress" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Shop" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "vatId" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "euOdrAddress" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "name" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "streetAddress" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "displayLocation" },
                },
                { kind: "Field", name: { kind: "Name", value: "postalCode" } },
                { kind: "Field", name: { kind: "Name", value: "email" } },
                { kind: "Field", name: { kind: "Name", value: "phone" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Core_Listing_EuOdrAddressQuery,
  Core_Listing_EuOdrAddressQueryVariables
>;
export const Core_ExpressSaleCheckoutShowDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "Core_ExpressSaleCheckoutShow" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "checkoutId" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "expresssalecheckout" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "id" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "checkoutId" },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "status" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "csp" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      { kind: "Field", name: { kind: "Name", value: "title" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "brand" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "_id" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "name" },
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "finishes" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "image" },
                        arguments: [
                          {
                            kind: "Argument",
                            name: { kind: "Name", value: "input" },
                            value: {
                              kind: "ObjectValue",
                              fields: [
                                {
                                  kind: "ObjectField",
                                  name: { kind: "Name", value: "transform" },
                                  value: {
                                    kind: "StringValue",
                                    value: "card_square",
                                    block: false,
                                  },
                                },
                              ],
                            },
                          },
                        ],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "_id" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "source" },
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "specs" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "productSpecs" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "_id" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "type" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "name" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "value" },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "expressSaleItemBid" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "bidId" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "carrier" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "shopName" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "estimatedPayout" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "display" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "amount" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "amountCents" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "me" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "uuid" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "shippingAddresses" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      { kind: "Field", name: { kind: "Name", value: "uuid" } },
                      { kind: "Field", name: { kind: "Name", value: "name" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "streetAddress" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "extendedAddress" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "locality" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "region" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "postalCode" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "countryCode" },
                      },
                      { kind: "Field", name: { kind: "Name", value: "phone" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "primary" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "legacyId" },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Core_ExpressSaleCheckoutShowQuery,
  Core_ExpressSaleCheckoutShowQueryVariables
>;
export const Core_Create_ExpressSaleCheckoutDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "Core_Create_ExpressSaleCheckout" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "input" },
          },
          type: {
            kind: "NamedType",
            name: {
              kind: "Name",
              value: "Input_core_apimessages_CreateExpressSaleCheckoutRequest",
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "createExpressSaleCheckout" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "input" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "checkoutId" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Core_Create_ExpressSaleCheckoutMutation,
  Core_Create_ExpressSaleCheckoutMutationVariables
>;
export const Core_Complete_ExpressSaleCheckoutDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "Core_Complete_ExpressSaleCheckout" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "input" },
          },
          type: {
            kind: "NamedType",
            name: {
              kind: "Name",
              value:
                "Input_core_apimessages_CompleteExpressSaleCheckoutRequest",
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "completeExpressSaleCheckout" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "input" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "orderId" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Core_Complete_ExpressSaleCheckoutMutation,
  Core_Complete_ExpressSaleCheckoutMutationVariables
>;
export const DeleteMyFavoriteDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "DeleteMyFavorite" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "input" },
          },
          type: {
            kind: "NamedType",
            name: {
              kind: "Name",
              value: "Input_core_apimessages_DeleteMyFavoriteRequest",
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "deleteMyFavorite" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "input" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "favorite" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "favorited" },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  DeleteMyFavoriteMutation,
  DeleteMyFavoriteMutationVariables
>;
export const Core_Listing_ShopsRecommendedContentDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "Core_Listing_ShopsRecommendedContent" },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "recommendedShops" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "name" } },
                { kind: "Field", name: { kind: "Name", value: "slug" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "address" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "displayLocation" },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "user" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "feedbackSummary" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: {
                                kind: "Name",
                                value: "rollingRatingPercentage",
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "receivedCount" },
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "avatar" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "_id" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "source" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Core_Listing_ShopsRecommendedContentQuery,
  Core_Listing_ShopsRecommendedContentQueryVariables
>;
export const UpsertMyFavoriteDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "UpsertMyFavorite" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "input" },
          },
          type: {
            kind: "NamedType",
            name: {
              kind: "Name",
              value: "Input_core_apimessages_UpsertMyFavoriteRequest",
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "upsertMyFavorite" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "input" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "favorite" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "favorited" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "emailEnabled" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "feedEnabled" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "searchableId" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "searchableType" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "queryParams" },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpsertMyFavoriteMutation,
  UpsertMyFavoriteMutationVariables
>;
export const Core_FindFavoriteProvider_UpsertMyFavoriteDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: {
        kind: "Name",
        value: "Core_FindFavoriteProvider_UpsertMyFavorite",
      },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "input" },
          },
          type: {
            kind: "NamedType",
            name: {
              kind: "Name",
              value: "Input_core_apimessages_UpsertMyFavoriteRequest",
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "upsertMyFavorite" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "input" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "favorite" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "favorited" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "emailEnabled" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "feedEnabled" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "searchableId" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "searchableType" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "queryParams" },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Core_FindFavoriteProvider_UpsertMyFavoriteMutation,
  Core_FindFavoriteProvider_UpsertMyFavoriteMutationVariables
>;
export const Core_Get_Favorite_SearchesDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "Core_Get_Favorite_Searches" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "limit" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "Int" } },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "offset" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "Int" } },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "me" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "uuid" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "favorites" },
                  arguments: [
                    {
                      kind: "Argument",
                      name: { kind: "Name", value: "input" },
                      value: {
                        kind: "ObjectValue",
                        fields: [
                          {
                            kind: "ObjectField",
                            name: { kind: "Name", value: "type" },
                            value: { kind: "EnumValue", value: "SAVED_SEARCH" },
                          },
                          {
                            kind: "ObjectField",
                            name: { kind: "Name", value: "limit" },
                            value: {
                              kind: "Variable",
                              name: { kind: "Name", value: "limit" },
                            },
                          },
                          {
                            kind: "ObjectField",
                            name: { kind: "Name", value: "offset" },
                            value: {
                              kind: "Variable",
                              name: { kind: "Name", value: "offset" },
                            },
                          },
                        ],
                      },
                    },
                  ],
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "total" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "favorites" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "title" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "subtitle" },
                            },
                            {
                              kind: "FragmentSpread",
                              name: {
                                kind: "Name",
                                value: "FavoriteSearchData",
                              },
                            },
                            {
                              kind: "FragmentSpread",
                              name: { kind: "Name", value: "FavoriteCTAData" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "FavoriteSearchData" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "rql_Favorite" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "listingsSearchUrlParams" },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "favorite" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "title" } },
                { kind: "Field", name: { kind: "Name", value: "subtitle" } },
                { kind: "Field", name: { kind: "Name", value: "favorited" } },
                { kind: "Field", name: { kind: "Name", value: "feedEnabled" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "emailEnabled" },
                },
                { kind: "Field", name: { kind: "Name", value: "queryParams" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "entity" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "InlineFragment",
                  typeCondition: {
                    kind: "NamedType",
                    name: { kind: "Name", value: "core_apimessages_Favorite" },
                  },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "link" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "href" },
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "images" },
                        arguments: [
                          {
                            kind: "Argument",
                            name: { kind: "Name", value: "input" },
                            value: {
                              kind: "ObjectValue",
                              fields: [
                                {
                                  kind: "ObjectField",
                                  name: { kind: "Name", value: "count" },
                                  value: { kind: "IntValue", value: "1" },
                                },
                                {
                                  kind: "ObjectField",
                                  name: { kind: "Name", value: "transform" },
                                  value: {
                                    kind: "StringValue",
                                    value: "card_square",
                                    block: false,
                                  },
                                },
                              ],
                            },
                          },
                        ],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "source" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "FavoriteCTAData" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "rql_Favorite" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "favorite" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "favorited" } },
                { kind: "Field", name: { kind: "Name", value: "title" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "searchableId" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "link" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "href" } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Core_Get_Favorite_SearchesQuery,
  Core_Get_Favorite_SearchesQueryVariables
>;
export const UpsertMyFavoriteShopDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "UpsertMyFavoriteShop" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "input" },
          },
          type: {
            kind: "NamedType",
            name: {
              kind: "Name",
              value: "Input_core_apimessages_UpsertMyFavoriteRequest",
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "upsertMyFavorite" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "input" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "favorite" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "favorited" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "searchableId" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "searchableType" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "queryParams" },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpsertMyFavoriteShopMutation,
  UpsertMyFavoriteShopMutationVariables
>;
export const DeleteMyFavoriteShopDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "DeleteMyFavoriteShop" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "input" },
          },
          type: {
            kind: "NamedType",
            name: {
              kind: "Name",
              value: "Input_core_apimessages_DeleteMyFavoriteRequest",
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "deleteMyFavorite" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "input" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "favorite" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "favorited" },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  DeleteMyFavoriteShopMutation,
  DeleteMyFavoriteShopMutationVariables
>;
export const Core_FavoritesShops_LayoutDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "Core_FavoritesShops_Layout" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "limit" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "Int" } },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "offset" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "Int" } },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "me" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "uuid" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "favorites" },
                  arguments: [
                    {
                      kind: "Argument",
                      name: { kind: "Name", value: "input" },
                      value: {
                        kind: "ObjectValue",
                        fields: [
                          {
                            kind: "ObjectField",
                            name: { kind: "Name", value: "type" },
                            value: { kind: "EnumValue", value: "SHOP" },
                          },
                          {
                            kind: "ObjectField",
                            name: { kind: "Name", value: "limit" },
                            value: {
                              kind: "Variable",
                              name: { kind: "Name", value: "limit" },
                            },
                          },
                          {
                            kind: "ObjectField",
                            name: { kind: "Name", value: "offset" },
                            value: {
                              kind: "Variable",
                              name: { kind: "Name", value: "offset" },
                            },
                          },
                        ],
                      },
                    },
                  ],
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "total" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "favorites" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "FragmentSpread",
                              name: { kind: "Name", value: "FavoriteShopData" },
                            },
                            {
                              kind: "FragmentSpread",
                              name: { kind: "Name", value: "FavoriteCTAData" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "FavoriteShopData" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "rql_Favorite" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "favorite" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "title" } },
                { kind: "Field", name: { kind: "Name", value: "subtitle" } },
                { kind: "Field", name: { kind: "Name", value: "favorited" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "entity" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "InlineFragment",
                  typeCondition: {
                    kind: "NamedType",
                    name: { kind: "Name", value: "Shop" },
                  },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      { kind: "Field", name: { kind: "Name", value: "name" } },
                      { kind: "Field", name: { kind: "Name", value: "slug" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "address" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "_id" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "displayLocation" },
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "user" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "_id" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "feedbackSummary" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "rollingRatingPercentage",
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "receivedCount",
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "avatar" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "_id" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "source" },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "FavoriteCTAData" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "rql_Favorite" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "favorite" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "favorited" } },
                { kind: "Field", name: { kind: "Name", value: "title" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "searchableId" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "link" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "href" } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Core_FavoritesShops_LayoutQuery,
  Core_FavoritesShops_LayoutQueryVariables
>;
export const Core_Favorites_Watch_List_LayoutDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "Core_Favorites_Watch_List_Layout" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "liveInput" },
          },
          type: {
            kind: "NamedType",
            name: { kind: "Name", value: "Input_rql_FavoritesRequest" },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "endedInput" },
          },
          type: {
            kind: "NamedType",
            name: { kind: "Name", value: "Input_rql_FavoritesRequest" },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "shouldSkipTracking" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "Boolean" },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "onEndedTab" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "Boolean" },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "favoriteImagesTransform" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "me" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "uuid" } },
                { kind: "Field", name: { kind: "Name", value: "profileSlug" } },
                {
                  kind: "Field",
                  alias: { kind: "Name", value: "liveWatches" },
                  name: { kind: "Name", value: "favorites" },
                  arguments: [
                    {
                      kind: "Argument",
                      name: { kind: "Name", value: "input" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "liveInput" },
                      },
                    },
                  ],
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "filters" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "FragmentSpread",
                              name: { kind: "Name", value: "NestedFilter" },
                            },
                          ],
                        },
                      },
                      { kind: "Field", name: { kind: "Name", value: "total" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "favorites" },
                        directives: [
                          {
                            kind: "Directive",
                            name: { kind: "Name", value: "skip" },
                            arguments: [
                              {
                                kind: "Argument",
                                name: { kind: "Name", value: "if" },
                                value: {
                                  kind: "Variable",
                                  name: { kind: "Name", value: "onEndedTab" },
                                },
                              },
                            ],
                          },
                        ],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "entity" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "FragmentSpread",
                                    name: {
                                      kind: "Name",
                                      value: "FavoriteWatchListCard",
                                    },
                                  },
                                  {
                                    kind: "FragmentSpread",
                                    name: {
                                      kind: "Name",
                                      value: "mParticleListingFields",
                                    },
                                    directives: [
                                      {
                                        kind: "Directive",
                                        name: { kind: "Name", value: "skip" },
                                        arguments: [
                                          {
                                            kind: "Argument",
                                            name: { kind: "Name", value: "if" },
                                            value: {
                                              kind: "Variable",
                                              name: {
                                                kind: "Name",
                                                value: "shouldSkipTracking",
                                              },
                                            },
                                          },
                                        ],
                                      },
                                    ],
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  alias: { kind: "Name", value: "endedWatches" },
                  name: { kind: "Name", value: "favorites" },
                  arguments: [
                    {
                      kind: "Argument",
                      name: { kind: "Name", value: "input" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "endedInput" },
                      },
                    },
                  ],
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "filters" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "FragmentSpread",
                              name: { kind: "Name", value: "NestedFilter" },
                            },
                          ],
                        },
                      },
                      { kind: "Field", name: { kind: "Name", value: "total" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "favorites" },
                        directives: [
                          {
                            kind: "Directive",
                            name: { kind: "Name", value: "include" },
                            arguments: [
                              {
                                kind: "Argument",
                                name: { kind: "Name", value: "if" },
                                value: {
                                  kind: "Variable",
                                  name: { kind: "Name", value: "onEndedTab" },
                                },
                              },
                            ],
                          },
                        ],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "entity" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "FragmentSpread",
                                    name: {
                                      kind: "Name",
                                      value: "FavoriteWatchListCard",
                                    },
                                  },
                                  {
                                    kind: "FragmentSpread",
                                    name: {
                                      kind: "Name",
                                      value: "mParticleListingFields",
                                    },
                                    directives: [
                                      {
                                        kind: "Directive",
                                        name: { kind: "Name", value: "skip" },
                                        arguments: [
                                          {
                                            kind: "Argument",
                                            name: { kind: "Name", value: "if" },
                                            value: {
                                              kind: "Variable",
                                              name: {
                                                kind: "Name",
                                                value: "shouldSkipTracking",
                                              },
                                            },
                                          },
                                        ],
                                      },
                                    ],
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "WatchBadgeData" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Listing" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "title" } },
          { kind: "Field", name: { kind: "Name", value: "sellerId" } },
          { kind: "Field", name: { kind: "Name", value: "watching" } },
          {
            kind: "Field",
            alias: { kind: "Name", value: "watchThumbnails" },
            name: { kind: "Name", value: "images" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "type" },
                      value: {
                        kind: "StringValue",
                        value: "Product",
                        block: false,
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "scope" },
                      value: {
                        kind: "StringValue",
                        value: "photos",
                        block: false,
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "transform" },
                      value: {
                        kind: "StringValue",
                        value: "card_square",
                        block: false,
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "count" },
                      value: { kind: "IntValue", value: "1" },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "source" } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "AddToCartButtonFields" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Listing" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "sellerId" } },
          { kind: "Field", name: { kind: "Name", value: "listingType" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "pricing" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "buyerPrice" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "amount" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "amountCents" },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "preorderInfo" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "onPreorder" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "estimatedShipDate" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "seconds" },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "mParticleListingFields" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Listing" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "title" } },
          { kind: "Field", name: { kind: "Name", value: "brandSlug" } },
          { kind: "Field", name: { kind: "Name", value: "categoryRootUuid" } },
          { kind: "Field", name: { kind: "Name", value: "make" } },
          { kind: "Field", name: { kind: "Name", value: "categoryUuids" } },
          { kind: "Field", name: { kind: "Name", value: "state" } },
          { kind: "Field", name: { kind: "Name", value: "listingType" } },
          { kind: "Field", name: { kind: "Name", value: "bumpEligible" } },
          { kind: "Field", name: { kind: "Name", value: "shopId" } },
          { kind: "Field", name: { kind: "Name", value: "inventory" } },
          { kind: "Field", name: { kind: "Name", value: "soldAsIs" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "acceptedPaymentMethods" },
          },
          { kind: "Field", name: { kind: "Name", value: "currency" } },
          { kind: "Field", name: { kind: "Name", value: "usOutlet" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "condition" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "conditionUuid" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "conditionSlug" },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "categories" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "slug" } },
                { kind: "Field", name: { kind: "Name", value: "rootSlug" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "csp" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "slug" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "brand" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      { kind: "Field", name: { kind: "Name", value: "slug" } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "pricing" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "buyerPrice" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "amount" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "currency" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "amountCents" },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "publishedAt" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "seconds" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "sale" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "code" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "buyerIneligibilityReason" },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "shipping" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "shippingPrices" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "shippingMethod" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "carrierCalculated" },
                      },
                      {
                        kind: "Field",
                        name: {
                          kind: "Name",
                          value: "destinationPostalCodeNeeded",
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "rate" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "amount" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "amountCents" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "currency" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "display" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "freeExpeditedShipping" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "localPickupOnly" },
                },
                { kind: "Field", name: { kind: "Name", value: "localPickup" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "certifiedPreOwned" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "title" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "badgeIconUrl" },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "shop" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "address" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "countryCode" },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "returnPolicy" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "newReturnWindowDays" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "usedReturnWindowDays" },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "ListingCreateOfferButtonData" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Listing" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "state" } },
          { kind: "Field", name: { kind: "Name", value: "offersEnabled" } },
          { kind: "Field", name: { kind: "Name", value: "listingType" } },
          { kind: "Field", name: { kind: "Name", value: "sellerId" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "isBuyerOfferEligible" },
          },
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "mParticleListingFields" },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "InOtherCartsCardData" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Listing" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "otherBuyersWithListingInCartCounts" },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "ListingGreatValueData" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Listing" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "pricing" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "buyerPrice" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "currency" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "amountCents" },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "condition" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "conditionSlug" },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "priceRecommendation" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "priceMiddle" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "amountCents" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "currency" },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "shop" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "address" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "country" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "_id" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "countryCode" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          { kind: "Field", name: { kind: "Name", value: "currency" } },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "ListingCardCPOData" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Listing" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "certifiedPreOwned" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "title" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "badgeIconUrl" },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "NestedFilter" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "reverb_search_Filter" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "name" } },
          { kind: "Field", name: { kind: "Name", value: "key" } },
          { kind: "Field", name: { kind: "Name", value: "aggregationName" } },
          { kind: "Field", name: { kind: "Name", value: "widgetType" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "options" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "count" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "value" } },
                    ],
                  },
                },
                { kind: "Field", name: { kind: "Name", value: "name" } },
                { kind: "Field", name: { kind: "Name", value: "selected" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "autoselected" },
                },
                { kind: "Field", name: { kind: "Name", value: "paramName" } },
                { kind: "Field", name: { kind: "Name", value: "setValues" } },
                { kind: "Field", name: { kind: "Name", value: "unsetValues" } },
                { kind: "Field", name: { kind: "Name", value: "all" } },
                { kind: "Field", name: { kind: "Name", value: "optionValue" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "trackingValue" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "subFilter" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "widgetType" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "options" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "count" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "value" },
                                  },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "name" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "selected" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "autoselected" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "paramName" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "setValues" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "unsetValues" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "all" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "optionValue" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "trackingValue" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "subFilter" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "widgetType" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "options" },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "count",
                                          },
                                          selectionSet: {
                                            kind: "SelectionSet",
                                            selections: [
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "value",
                                                },
                                              },
                                            ],
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "name" },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "selected",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "autoselected",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "paramName",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "setValues",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "unsetValues",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "all" },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "optionValue",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "trackingValue",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "subFilter",
                                          },
                                          selectionSet: {
                                            kind: "SelectionSet",
                                            selections: [
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "widgetType",
                                                },
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "options",
                                                },
                                                selectionSet: {
                                                  kind: "SelectionSet",
                                                  selections: [
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "count",
                                                      },
                                                      selectionSet: {
                                                        kind: "SelectionSet",
                                                        selections: [
                                                          {
                                                            kind: "Field",
                                                            name: {
                                                              kind: "Name",
                                                              value: "value",
                                                            },
                                                          },
                                                        ],
                                                      },
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "name",
                                                      },
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "selected",
                                                      },
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "autoselected",
                                                      },
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "paramName",
                                                      },
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "setValues",
                                                      },
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "unsetValues",
                                                      },
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "all",
                                                      },
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "optionValue",
                                                      },
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "trackingValue",
                                                      },
                                                    },
                                                  ],
                                                },
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "FavoriteWatchListCard" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Listing" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "slug" } },
          { kind: "Field", name: { kind: "Name", value: "state" } },
          { kind: "Field", name: { kind: "Name", value: "title" } },
          { kind: "Field", name: { kind: "Name", value: "usOutlet" } },
          { kind: "Field", name: { kind: "Name", value: "watching" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "condition" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "displayName" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "conditionSlug" },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "pricing" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "ribbon" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "display" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "reason" },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "buyerPrice" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "display" },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "originalPrice" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "display" },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "shop" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "name" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "address" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "displayLocation" },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "signals" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "groups" },
                      value: {
                        kind: "ListValue",
                        values: [{ kind: "EnumValue", value: "MP_GRID_CARD" }],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "icon" } },
                { kind: "Field", name: { kind: "Name", value: "title" } },
                { kind: "Field", name: { kind: "Name", value: "name" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "seller" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "feedbackSummary" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: {
                          kind: "Name",
                          value: "rollingRatingPercentage",
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "shippingPrice" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "amountCents" } },
                { kind: "Field", name: { kind: "Name", value: "display" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "shipping" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "localPickupOnly" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "freeExpeditedShipping" },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "images" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "transform" },
                      value: {
                        kind: "Variable",
                        name: {
                          kind: "Name",
                          value: "favoriteImagesTransform",
                        },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "count" },
                      value: { kind: "IntValue", value: "1" },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "scope" },
                      value: {
                        kind: "StringValue",
                        value: "photos",
                        block: false,
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "type" },
                      value: {
                        kind: "StringValue",
                        value: "Product",
                        block: false,
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "source" } },
              ],
            },
          },
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "WatchBadgeData" },
          },
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "AddToCartButtonFields" },
          },
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "ListingCreateOfferButtonData" },
          },
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "InOtherCartsCardData" },
          },
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "ListingGreatValueData" },
          },
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "ListingCardCPOData" },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Core_Favorites_Watch_List_LayoutQuery,
  Core_Favorites_Watch_List_LayoutQueryVariables
>;
export const Core_FindFavoriteDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "Core_FindFavorite" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "listingsSearchRequest" },
          },
          type: {
            kind: "NamedType",
            name: {
              kind: "Name",
              value: "Input_reverb_search_ListingsSearchRequest",
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "shopSlug" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "findFavorite" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "listingsSearchRequest" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "listingsSearchRequest" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "shopSlug" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "shopSlug" },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "isFavorite" } },
                { kind: "Field", name: { kind: "Name", value: "canFavorite" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "favorite" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "favorited" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "searchableId" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "searchableType" },
                      },
                      { kind: "Field", name: { kind: "Name", value: "title" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "emailEnabled" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "feedEnabled" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "queryParams" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "subtitle" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "link" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "href" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Core_FindFavoriteQuery,
  Core_FindFavoriteQueryVariables
>;
export const Core_Post_Purchase_Shop_NudgeDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "Core_Post_Purchase_Shop_Nudge" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "shop" } },
          type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "shop" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "id" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "shop" },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                {
                  kind: "FragmentSpread",
                  name: { kind: "Name", value: "ShopHeaderFragment" },
                },
                {
                  kind: "FragmentSpread",
                  name: { kind: "Name", value: "FavoriteShopCTAData" },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "findFavorite" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "shopId" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "shop" },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "isFavorite" } },
                { kind: "Field", name: { kind: "Name", value: "canFavorite" } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "ShopHeaderFragment" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Shop" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "slug" } },
          { kind: "Field", name: { kind: "Name", value: "name" } },
          { kind: "Field", name: { kind: "Name", value: "preferredSeller" } },
          { kind: "Field", name: { kind: "Name", value: "quickShipper" } },
          { kind: "Field", name: { kind: "Name", value: "quickResponder" } },
          { kind: "Field", name: { kind: "Name", value: "description" } },
          { kind: "Field", name: { kind: "Name", value: "userUuid" } },
          { kind: "Field", name: { kind: "Name", value: "userId" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "address" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "displayLocation" },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "banner" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "source" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "user" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "id" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "avatar" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "source" },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "FavoriteShopCTAData" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Shop" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "name" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "address" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "countryCode" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "user" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "feedbackSummary" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: {
                          kind: "Name",
                          value: "rollingRatingPercentage",
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "receivedCount" },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Core_Post_Purchase_Shop_NudgeQuery,
  Core_Post_Purchase_Shop_NudgeQueryVariables
>;
export const DeclineFeedbackMutationDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "DeclineFeedbackMutation" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "input" },
          },
          type: {
            kind: "NamedType",
            name: {
              kind: "Name",
              value: "Input_core_apimessages_DeclineFeedbackRequest",
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "declineFeedbackRequest" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "input" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "orderUuid" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  DeclineFeedbackMutationMutation,
  DeclineFeedbackMutationMutationVariables
>;
export const Core_Feedback_OrderInfoDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "Core_Feedback_OrderInfo" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "orderUuid" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "me" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "uuid" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "orders" },
                  arguments: [
                    {
                      kind: "Argument",
                      name: { kind: "Name", value: "input" },
                      value: {
                        kind: "ObjectValue",
                        fields: [
                          {
                            kind: "ObjectField",
                            name: { kind: "Name", value: "uuids" },
                            value: {
                              kind: "ListValue",
                              values: [
                                {
                                  kind: "Variable",
                                  name: { kind: "Name", value: "orderUuid" },
                                },
                              ],
                            },
                          },
                        ],
                      },
                    },
                  ],
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "legacyOrderId" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "buyerUuid" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "buyer" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "_id" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "name" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "shop" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "name" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "slug" },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "status" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "amountTotal" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "amount" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "amountCents" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "currency" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "symbol" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "display" },
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "shippedAt" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "seconds" },
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "createdAt" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "seconds" },
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "listing" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "_id" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "slug" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "title" },
                            },
                            {
                              kind: "Field",
                              name: {
                                kind: "Name",
                                value: "merchandisingType",
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "shopUuid" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "images" },
                              arguments: [
                                {
                                  kind: "Argument",
                                  name: { kind: "Name", value: "input" },
                                  value: {
                                    kind: "ObjectValue",
                                    fields: [
                                      {
                                        kind: "ObjectField",
                                        name: {
                                          kind: "Name",
                                          value: "transform",
                                        },
                                        value: {
                                          kind: "StringValue",
                                          value: "thumb",
                                          block: false,
                                        },
                                      },
                                      {
                                        kind: "ObjectField",
                                        name: { kind: "Name", value: "count" },
                                        value: { kind: "IntValue", value: "1" },
                                      },
                                    ],
                                  },
                                },
                              ],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "_id" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "source" },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Core_Feedback_OrderInfoQuery,
  Core_Feedback_OrderInfoQueryVariables
>;
export const UpdateFeedbackDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "UpdateFeedback" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "input" },
          },
          type: {
            kind: "NamedType",
            name: {
              kind: "Name",
              value: "Input_core_apimessages_UpdateFeedbackRequest",
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "updateFeedbackRequest" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "input" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "feedback" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "rating" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "shippingSatisfactory" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "value" },
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "conditionSatisfactory" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "value" },
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "message" },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateFeedbackMutation,
  UpdateFeedbackMutationVariables
>;
export const CreateMyFeedbackMessageDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "CreateMyFeedbackMessage" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "input" },
          },
          type: {
            kind: "NamedType",
            name: {
              kind: "Name",
              value: "Input_core_apimessages_CreateMyFeedbackMessageRequest",
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "createMyFeedbackMessage" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "input" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CreateMyFeedbackMessageMutation,
  CreateMyFeedbackMessageMutationVariables
>;
export const DeleteMyFeedbackMessageDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "DeleteMyFeedbackMessage" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "input" },
          },
          type: {
            kind: "NamedType",
            name: {
              kind: "Name",
              value: "Input_core_apimessages_DeleteMyFeedbackMessageRequest",
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "deleteMyFeedbackMessage" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "input" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  DeleteMyFeedbackMessageMutation,
  DeleteMyFeedbackMessageMutationVariables
>;
export const MyFeedbackMessagesDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "MyFeedbackMessages" },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "myFeedbackMessages" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "feedbackMessages" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "message" },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  MyFeedbackMessagesQuery,
  MyFeedbackMessagesQueryVariables
>;
export const Core_Feedback_SellerShopInfoDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "Core_Feedback_SellerShopInfo" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "listingId" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "listing" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "id" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "listingId" },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "shop" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      { kind: "Field", name: { kind: "Name", value: "name" } },
                      { kind: "Field", name: { kind: "Name", value: "slug" } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Core_Feedback_SellerShopInfoQuery,
  Core_Feedback_SellerShopInfoQueryVariables
>;
export const Core_GearCollection_SettingsDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "Core_GearCollection_Settings" },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "me" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                {
                  kind: "FragmentSpread",
                  name: { kind: "Name", value: "GearCollectionSettingsData" },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "GearCollectionSettingsData" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "rql_Me" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "uuid" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "gearCollectionSettings" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "autoAddOrders" },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Core_GearCollection_SettingsQuery,
  Core_GearCollection_SettingsQueryVariables
>;
export const Core_GearCollection_UpdateSettingsDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "Core_GearCollection_UpdateSettings" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "input" },
          },
          type: {
            kind: "NamedType",
            name: {
              kind: "Name",
              value:
                "Input_core_apimessages_UpdateMyGearCollectionSettingsRequest",
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "updateMyGearCollectionSettings" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "input" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "gearCollectionSettings" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "autoAddOrders" },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Core_GearCollection_UpdateSettingsMutation,
  Core_GearCollection_UpdateSettingsMutationVariables
>;
export const Core_CSPVariantPicker_CSPDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "Core_CSPVariantPicker_CSP" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "id" } },
          type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "csp" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "id" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "id" },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "id" } },
                {
                  kind: "FragmentSpread",
                  name: {
                    kind: "Name",
                    value: "GearCollectionCSPSummaryCardData",
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "GearCollectionCSPSummaryCardData" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "CSP" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "title" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "image" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "transform" },
                      value: {
                        kind: "StringValue",
                        value: "card_square",
                        block: false,
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "source" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "brand" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "name" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "canonicalProducts" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "name" } },
                { kind: "Field", name: { kind: "Name", value: "model" } },
                { kind: "Field", name: { kind: "Name", value: "year" } },
                { kind: "Field", name: { kind: "Name", value: "finish" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Core_CSPVariantPicker_CSPQuery,
  Core_CSPVariantPicker_CSPQueryVariables
>;
export const DataServices_GearCollections_PriceRecommendationsDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: {
        kind: "Name",
        value: "DataServices_GearCollections_PriceRecommendations",
      },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "priceRecommendationQueries" },
          },
          type: {
            kind: "ListType",
            type: {
              kind: "NamedType",
              name: {
                kind: "Name",
                value: "Input_reverb_pricing_PriceRecommendationQuery",
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "priceRecommendations" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: {
                        kind: "Name",
                        value: "priceRecommendationQueries",
                      },
                      value: {
                        kind: "Variable",
                        name: {
                          kind: "Name",
                          value: "priceRecommendationQueries",
                        },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "priceRecommendations" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "conditionUuid" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "priceLow" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "amountCents" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "display" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "currency" },
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "priceMiddle" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "amountCents" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "display" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "currency" },
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "priceHigh" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "amountCents" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "display" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "currency" },
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "priceLowThirtyDaysAgo" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "amountCents" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "display" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "currency" },
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: {
                          kind: "Name",
                          value: "priceMiddleThirtyDaysAgo",
                        },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "amountCents" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "display" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "currency" },
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "priceHighThirtyDaysAgo" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "amountCents" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "display" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "currency" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  DataServices_GearCollections_PriceRecommendationsQuery,
  DataServices_GearCollections_PriceRecommendationsQueryVariables
>;
export const Core_Crupdate_GearCollectionItemDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "Core_Crupdate_GearCollectionItem" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "input" },
          },
          type: {
            kind: "NamedType",
            name: {
              kind: "Name",
              value: "Input_core_apimessages_UpdateGearCollectionItemRequest",
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "crupdateGearCollectionItem" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "input" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                {
                  kind: "FragmentSpread",
                  name: {
                    kind: "Name",
                    value: "GearCollectionItemShowDisplayData",
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "GearCollectionCSPData" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "CSP" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "title" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "brand" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "name" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "webLink" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "href" } },
                { kind: "Field", name: { kind: "Name", value: "path" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "image" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "transform" },
                      value: {
                        kind: "StringValue",
                        value: "card_square",
                        block: false,
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "source" } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "GearCollectionItemShowDisplayData" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "GearCollectionItem" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "title" } },
          { kind: "Field", name: { kind: "Name", value: "description" } },
          { kind: "Field", name: { kind: "Name", value: "notes" } },
          { kind: "Field", name: { kind: "Name", value: "make" } },
          { kind: "Field", name: { kind: "Name", value: "model" } },
          { kind: "Field", name: { kind: "Name", value: "finish" } },
          { kind: "Field", name: { kind: "Name", value: "year" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "canonicalProductId" },
          },
          { kind: "Field", name: { kind: "Name", value: "subcategoryUuids" } },
          { kind: "Field", name: { kind: "Name", value: "serialNumber" } },
          { kind: "Field", name: { kind: "Name", value: "hideListingPhotos" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "hideListingDescription" },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "condition" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "conditionSlug" },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "tags" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "name" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "price" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "amount" } },
                { kind: "Field", name: { kind: "Name", value: "amountCents" } },
                { kind: "Field", name: { kind: "Name", value: "currency" } },
                { kind: "Field", name: { kind: "Name", value: "display" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "ownerCost" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "amount" } },
                { kind: "Field", name: { kind: "Name", value: "amountCents" } },
                { kind: "Field", name: { kind: "Name", value: "currency" } },
                { kind: "Field", name: { kind: "Name", value: "display" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "categories" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "name" } },
                { kind: "Field", name: { kind: "Name", value: "root" } },
                { kind: "Field", name: { kind: "Name", value: "leaf" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "originalListing" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "description" } },
                { kind: "Field", name: { kind: "Name", value: "title" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "images" },
                  arguments: [
                    {
                      kind: "Argument",
                      name: { kind: "Name", value: "input" },
                      value: {
                        kind: "ObjectValue",
                        fields: [
                          {
                            kind: "ObjectField",
                            name: { kind: "Name", value: "transform" },
                            value: {
                              kind: "StringValue",
                              value: "card_square",
                              block: false,
                            },
                          },
                        ],
                      },
                    },
                  ],
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "source" },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "listing" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "id" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "csp" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                {
                  kind: "FragmentSpread",
                  name: { kind: "Name", value: "GearCollectionCSPData" },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "dataServicesPriceEstimate" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "priceLow" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "amountCents" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "currency" },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "priceHigh" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "amountCents" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "currency" },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "priceMiddle" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "amountCents" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "currency" },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "priceMiddleThirtyDaysAgo" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "amountCents" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "currency" },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "images" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "transform" },
                      value: {
                        kind: "StringValue",
                        value: "card_square",
                        block: false,
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "source" } },
                { kind: "Field", name: { kind: "Name", value: "publicId" } },
                { kind: "Field", name: { kind: "Name", value: "version" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "fallbackType" },
                },
                { kind: "Field", name: { kind: "Name", value: "height" } },
                { kind: "Field", name: { kind: "Name", value: "width" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "transformation" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "x" } },
                      { kind: "Field", name: { kind: "Name", value: "y" } },
                      { kind: "Field", name: { kind: "Name", value: "width" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "height" },
                      },
                      { kind: "Field", name: { kind: "Name", value: "angle" } },
                      { kind: "Field", name: { kind: "Name", value: "crop" } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Core_Crupdate_GearCollectionItemMutation,
  Core_Crupdate_GearCollectionItemMutationVariables
>;
export const Core_Delete_GearCollectionItemDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "Core_Delete_GearCollectionItem" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "input" },
          },
          type: {
            kind: "NamedType",
            name: {
              kind: "Name",
              value: "Input_core_apimessages_DeleteGearCollectionItemRequest",
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "deleteGearCollectionItem" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "input" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                {
                  kind: "FragmentSpread",
                  name: {
                    kind: "Name",
                    value: "GearCollectionItemShowDisplayData",
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "GearCollectionCSPData" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "CSP" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "title" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "brand" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "name" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "webLink" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "href" } },
                { kind: "Field", name: { kind: "Name", value: "path" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "image" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "transform" },
                      value: {
                        kind: "StringValue",
                        value: "card_square",
                        block: false,
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "source" } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "GearCollectionItemShowDisplayData" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "GearCollectionItem" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "title" } },
          { kind: "Field", name: { kind: "Name", value: "description" } },
          { kind: "Field", name: { kind: "Name", value: "notes" } },
          { kind: "Field", name: { kind: "Name", value: "make" } },
          { kind: "Field", name: { kind: "Name", value: "model" } },
          { kind: "Field", name: { kind: "Name", value: "finish" } },
          { kind: "Field", name: { kind: "Name", value: "year" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "canonicalProductId" },
          },
          { kind: "Field", name: { kind: "Name", value: "subcategoryUuids" } },
          { kind: "Field", name: { kind: "Name", value: "serialNumber" } },
          { kind: "Field", name: { kind: "Name", value: "hideListingPhotos" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "hideListingDescription" },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "condition" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "conditionSlug" },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "tags" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "name" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "price" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "amount" } },
                { kind: "Field", name: { kind: "Name", value: "amountCents" } },
                { kind: "Field", name: { kind: "Name", value: "currency" } },
                { kind: "Field", name: { kind: "Name", value: "display" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "ownerCost" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "amount" } },
                { kind: "Field", name: { kind: "Name", value: "amountCents" } },
                { kind: "Field", name: { kind: "Name", value: "currency" } },
                { kind: "Field", name: { kind: "Name", value: "display" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "categories" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "name" } },
                { kind: "Field", name: { kind: "Name", value: "root" } },
                { kind: "Field", name: { kind: "Name", value: "leaf" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "originalListing" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "description" } },
                { kind: "Field", name: { kind: "Name", value: "title" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "images" },
                  arguments: [
                    {
                      kind: "Argument",
                      name: { kind: "Name", value: "input" },
                      value: {
                        kind: "ObjectValue",
                        fields: [
                          {
                            kind: "ObjectField",
                            name: { kind: "Name", value: "transform" },
                            value: {
                              kind: "StringValue",
                              value: "card_square",
                              block: false,
                            },
                          },
                        ],
                      },
                    },
                  ],
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "source" },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "listing" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "id" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "csp" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                {
                  kind: "FragmentSpread",
                  name: { kind: "Name", value: "GearCollectionCSPData" },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "dataServicesPriceEstimate" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "priceLow" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "amountCents" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "currency" },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "priceHigh" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "amountCents" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "currency" },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "priceMiddle" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "amountCents" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "currency" },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "priceMiddleThirtyDaysAgo" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "amountCents" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "currency" },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "images" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "transform" },
                      value: {
                        kind: "StringValue",
                        value: "card_square",
                        block: false,
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "source" } },
                { kind: "Field", name: { kind: "Name", value: "publicId" } },
                { kind: "Field", name: { kind: "Name", value: "version" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "fallbackType" },
                },
                { kind: "Field", name: { kind: "Name", value: "height" } },
                { kind: "Field", name: { kind: "Name", value: "width" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "transformation" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "x" } },
                      { kind: "Field", name: { kind: "Name", value: "y" } },
                      { kind: "Field", name: { kind: "Name", value: "width" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "height" },
                      },
                      { kind: "Field", name: { kind: "Name", value: "angle" } },
                      { kind: "Field", name: { kind: "Name", value: "crop" } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Core_Delete_GearCollectionItemMutation,
  Core_Delete_GearCollectionItemMutationVariables
>;
export const Core_GearCollectionItem_FormDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "Core_GearCollectionItem_Form" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "shouldSeedListingData" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "Boolean" },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "shouldFetchCsp" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "Boolean" },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "seedId" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "seedType" },
          },
          type: {
            kind: "NamedType",
            name: { kind: "Name", value: "core_apimessages_Listing_SeedType" },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "cspId" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "seedListing" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "seedId" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "seedId" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "seedType" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "seedType" },
                      },
                    },
                  ],
                },
              },
            ],
            directives: [
              {
                kind: "Directive",
                name: { kind: "Name", value: "include" },
                arguments: [
                  {
                    kind: "Argument",
                    name: { kind: "Name", value: "if" },
                    value: {
                      kind: "Variable",
                      name: { kind: "Name", value: "shouldSeedListingData" },
                    },
                  },
                ],
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "listing" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "make" } },
                      { kind: "Field", name: { kind: "Name", value: "model" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "brandSlug" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "categoryRootUuid" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "subcategoryLeafUuids" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "canonicalProductId" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "finish" },
                      },
                      { kind: "Field", name: { kind: "Name", value: "year" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "conditionSlug" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "sellerCost" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "amount" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "amountCents" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "currency" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "display" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "csp" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "id" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "cspId" },
                      },
                    },
                  ],
                },
              },
            ],
            directives: [
              {
                kind: "Directive",
                name: { kind: "Name", value: "include" },
                arguments: [
                  {
                    kind: "Argument",
                    name: { kind: "Name", value: "if" },
                    value: {
                      kind: "Variable",
                      name: { kind: "Name", value: "shouldFetchCsp" },
                    },
                  },
                ],
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                {
                  kind: "FragmentSpread",
                  name: { kind: "Name", value: "GearCollectionCSPData" },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "GearCollectionCSPData" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "CSP" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "title" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "brand" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "name" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "webLink" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "href" } },
                { kind: "Field", name: { kind: "Name", value: "path" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "image" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "transform" },
                      value: {
                        kind: "StringValue",
                        value: "card_square",
                        block: false,
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "source" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Core_GearCollectionItem_FormQuery,
  Core_GearCollectionItem_FormQueryVariables
>;
export const Core_GearCollectionItem_DeleteFromIndexDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "Core_GearCollectionItem_DeleteFromIndex" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "input" },
          },
          type: {
            kind: "NamedType",
            name: {
              kind: "Name",
              value: "Input_core_apimessages_DeleteGearCollectionItemRequest",
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "deleteGearCollectionItem" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "input" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "deleted" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Core_GearCollectionItem_DeleteFromIndexMutation,
  Core_GearCollectionItem_DeleteFromIndexMutationVariables
>;
export const Search_GearCollectionItem_IndexDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "Search_GearCollectionItem_Index" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "limit" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "Int" } },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "offset" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "Int" } },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "categoryUuids" },
          },
          type: {
            kind: "ListType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "currency" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
        },
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "tags" } },
          type: {
            kind: "ListType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "gearCollectionItemsSearch" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "as" },
                      value: { kind: "EnumValue", value: "OWNER" },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "searchInput" },
                      value: {
                        kind: "ObjectValue",
                        fields: [
                          {
                            kind: "ObjectField",
                            name: { kind: "Name", value: "limit" },
                            value: {
                              kind: "Variable",
                              name: { kind: "Name", value: "limit" },
                            },
                          },
                          {
                            kind: "ObjectField",
                            name: { kind: "Name", value: "offset" },
                            value: {
                              kind: "Variable",
                              name: { kind: "Name", value: "offset" },
                            },
                          },
                          {
                            kind: "ObjectField",
                            name: { kind: "Name", value: "categoryUuids" },
                            value: {
                              kind: "Variable",
                              name: { kind: "Name", value: "categoryUuids" },
                            },
                          },
                          {
                            kind: "ObjectField",
                            name: { kind: "Name", value: "currency" },
                            value: {
                              kind: "Variable",
                              name: { kind: "Name", value: "currency" },
                            },
                          },
                          {
                            kind: "ObjectField",
                            name: { kind: "Name", value: "withAggregations" },
                            value: {
                              kind: "ListValue",
                              values: [
                                { kind: "EnumValue", value: "CATEGORY_UUIDS" },
                                { kind: "EnumValue", value: "TAGS" },
                              ],
                            },
                          },
                          {
                            kind: "ObjectField",
                            name: { kind: "Name", value: "tags" },
                            value: {
                              kind: "Variable",
                              name: { kind: "Name", value: "tags" },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "total" } },
                { kind: "Field", name: { kind: "Name", value: "offset" } },
                { kind: "Field", name: { kind: "Name", value: "limit" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "filters" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "FragmentSpread",
                        name: { kind: "Name", value: "FlatFilter" },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "gearCollectionItems" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      {
                        kind: "FragmentSpread",
                        name: {
                          kind: "Name",
                          value: "GearCollectionIndexCard",
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            alias: { kind: "Name", value: "statsSearch" },
            name: { kind: "Name", value: "gearCollectionItemsSearch" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "as" },
                      value: { kind: "EnumValue", value: "OWNER" },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "searchInput" },
                      value: {
                        kind: "ObjectValue",
                        fields: [
                          {
                            kind: "ObjectField",
                            name: {
                              kind: "Name",
                              value: "withCollectionStats",
                            },
                            value: { kind: "BooleanValue", value: true },
                          },
                          {
                            kind: "ObjectField",
                            name: { kind: "Name", value: "currency" },
                            value: {
                              kind: "Variable",
                              name: { kind: "Name", value: "currency" },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "total" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "gearCollectionStats" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: {
                          kind: "Name",
                          value: "totalCollectionEstimate",
                        },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "totalEstimateLow" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "amountCents",
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "currency" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "display" },
                                  },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: {
                                kind: "Name",
                                value: "totalEstimateHigh",
                              },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "amountCents",
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "currency" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "display" },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "GcIndexCardPrices" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "GearCollectionItem" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "created" } },
          { kind: "Field", name: { kind: "Name", value: "updated" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "canonicalProductId" },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "price" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "amountCents" } },
                { kind: "Field", name: { kind: "Name", value: "currency" } },
                { kind: "Field", name: { kind: "Name", value: "display" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "esPriceEstimate" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "priceLow" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "amountCents" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "currency" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "display" },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "priceHigh" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "amountCents" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "currency" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "display" },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "FlatFilter" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "reverb_search_Filter" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "name" } },
          { kind: "Field", name: { kind: "Name", value: "key" } },
          { kind: "Field", name: { kind: "Name", value: "aggregationName" } },
          { kind: "Field", name: { kind: "Name", value: "widgetType" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "options" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "count" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "value" } },
                    ],
                  },
                },
                { kind: "Field", name: { kind: "Name", value: "name" } },
                { kind: "Field", name: { kind: "Name", value: "selected" } },
                { kind: "Field", name: { kind: "Name", value: "paramName" } },
                { kind: "Field", name: { kind: "Name", value: "setValues" } },
                { kind: "Field", name: { kind: "Name", value: "unsetValues" } },
                { kind: "Field", name: { kind: "Name", value: "all" } },
                { kind: "Field", name: { kind: "Name", value: "optionValue" } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "GearCollectionIndexCard" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "GearCollectionItem" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "deleted" } },
          { kind: "Field", name: { kind: "Name", value: "title" } },
          { kind: "Field", name: { kind: "Name", value: "description" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "tags" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "name" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "images" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "transform" },
                      value: {
                        kind: "StringValue",
                        value: "card_square",
                        block: false,
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "count" },
                      value: { kind: "IntValue", value: "1" },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "source" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "listing" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "id" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "originalListing" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "images" },
                  arguments: [
                    {
                      kind: "Argument",
                      name: { kind: "Name", value: "input" },
                      value: {
                        kind: "ObjectValue",
                        fields: [
                          {
                            kind: "ObjectField",
                            name: { kind: "Name", value: "transform" },
                            value: {
                              kind: "StringValue",
                              value: "card_square",
                              block: false,
                            },
                          },
                          {
                            kind: "ObjectField",
                            name: { kind: "Name", value: "count" },
                            value: { kind: "IntValue", value: "1" },
                          },
                        ],
                      },
                    },
                  ],
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "source" },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "csp" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "slug" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "image" },
                  arguments: [
                    {
                      kind: "Argument",
                      name: { kind: "Name", value: "input" },
                      value: {
                        kind: "ObjectValue",
                        fields: [
                          {
                            kind: "ObjectField",
                            name: { kind: "Name", value: "transform" },
                            value: {
                              kind: "StringValue",
                              value: "card_square",
                              block: false,
                            },
                          },
                          {
                            kind: "ObjectField",
                            name: { kind: "Name", value: "count" },
                            value: { kind: "IntValue", value: "1" },
                          },
                        ],
                      },
                    },
                  ],
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "source" },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "GcIndexCardPrices" },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Search_GearCollectionItem_IndexQuery,
  Search_GearCollectionItem_IndexQueryVariables
>;
export const Core_OrderSearch_AddPastPurchasesToGearCollectionDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: {
        kind: "Name",
        value: "Core_OrderSearch_AddPastPurchasesToGearCollection",
      },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "actionableStatuses" },
          },
          type: {
            kind: "ListType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "listingCategoryUuids" },
          },
          type: {
            kind: "ListType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "createdAfterDate" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "minAmountProductCentsUsd" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "limit" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "Int" } },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "withoutGearCollectionItemOnly" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "Boolean" } },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "ordersSearch" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "as" },
                      value: { kind: "EnumValue", value: "BUYER" },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "searchFilters" },
                      value: {
                        kind: "ObjectValue",
                        fields: [
                          {
                            kind: "ObjectField",
                            name: { kind: "Name", value: "actionableStatuses" },
                            value: {
                              kind: "Variable",
                              name: {
                                kind: "Name",
                                value: "actionableStatuses",
                              },
                            },
                          },
                          {
                            kind: "ObjectField",
                            name: {
                              kind: "Name",
                              value: "listingCategoryUuids",
                            },
                            value: {
                              kind: "Variable",
                              name: {
                                kind: "Name",
                                value: "listingCategoryUuids",
                              },
                            },
                          },
                          {
                            kind: "ObjectField",
                            name: { kind: "Name", value: "createdAfterDate" },
                            value: {
                              kind: "Variable",
                              name: { kind: "Name", value: "createdAfterDate" },
                            },
                          },
                          {
                            kind: "ObjectField",
                            name: {
                              kind: "Name",
                              value: "minAmountProductCentsUsd",
                            },
                            value: {
                              kind: "Variable",
                              name: {
                                kind: "Name",
                                value: "minAmountProductCentsUsd",
                              },
                            },
                          },
                          {
                            kind: "ObjectField",
                            name: { kind: "Name", value: "limit" },
                            value: {
                              kind: "Variable",
                              name: { kind: "Name", value: "limit" },
                            },
                          },
                          {
                            kind: "ObjectField",
                            name: {
                              kind: "Name",
                              value: "withoutGearCollectionItemOnly",
                            },
                            value: {
                              kind: "Variable",
                              name: {
                                kind: "Name",
                                value: "withoutGearCollectionItemOnly",
                              },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "orders" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      {
                        kind: "FragmentSpread",
                        name: {
                          kind: "Name",
                          value: "QuickAddOrderCardFields",
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "QuickAddOrderCardFields" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Order" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "legacyOrderId" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "createdAt" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "seconds" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "paidAt" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "seconds" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "listing" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "title" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "csp" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "images" },
                  arguments: [
                    {
                      kind: "Argument",
                      name: { kind: "Name", value: "input" },
                      value: {
                        kind: "ObjectValue",
                        fields: [
                          {
                            kind: "ObjectField",
                            name: { kind: "Name", value: "transform" },
                            value: {
                              kind: "StringValue",
                              value: "card_square",
                              block: false,
                            },
                          },
                          {
                            kind: "ObjectField",
                            name: { kind: "Name", value: "count" },
                            value: { kind: "IntValue", value: "1" },
                          },
                          {
                            kind: "ObjectField",
                            name: { kind: "Name", value: "scope" },
                            value: {
                              kind: "StringValue",
                              value: "photos",
                              block: false,
                            },
                          },
                          {
                            kind: "ObjectField",
                            name: { kind: "Name", value: "type" },
                            value: {
                              kind: "StringValue",
                              value: "Product",
                              block: false,
                            },
                          },
                        ],
                      },
                    },
                  ],
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "source" },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Core_OrderSearch_AddPastPurchasesToGearCollectionQuery,
  Core_OrderSearch_AddPastPurchasesToGearCollectionQueryVariables
>;
export const CoreGearCollectionItemShowLayoutDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "CoreGearCollectionItemShowLayout" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "id" } },
          type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "gearcollectionitem" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "id" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "id" },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                {
                  kind: "FragmentSpread",
                  name: {
                    kind: "Name",
                    value: "GearCollectionItemShowDisplayData",
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "GearCollectionCSPData" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "CSP" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "title" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "brand" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "name" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "webLink" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "href" } },
                { kind: "Field", name: { kind: "Name", value: "path" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "image" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "transform" },
                      value: {
                        kind: "StringValue",
                        value: "card_square",
                        block: false,
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "source" } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "GearCollectionItemShowDisplayData" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "GearCollectionItem" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "title" } },
          { kind: "Field", name: { kind: "Name", value: "description" } },
          { kind: "Field", name: { kind: "Name", value: "notes" } },
          { kind: "Field", name: { kind: "Name", value: "make" } },
          { kind: "Field", name: { kind: "Name", value: "model" } },
          { kind: "Field", name: { kind: "Name", value: "finish" } },
          { kind: "Field", name: { kind: "Name", value: "year" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "canonicalProductId" },
          },
          { kind: "Field", name: { kind: "Name", value: "subcategoryUuids" } },
          { kind: "Field", name: { kind: "Name", value: "serialNumber" } },
          { kind: "Field", name: { kind: "Name", value: "hideListingPhotos" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "hideListingDescription" },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "condition" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "conditionSlug" },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "tags" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "name" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "price" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "amount" } },
                { kind: "Field", name: { kind: "Name", value: "amountCents" } },
                { kind: "Field", name: { kind: "Name", value: "currency" } },
                { kind: "Field", name: { kind: "Name", value: "display" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "ownerCost" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "amount" } },
                { kind: "Field", name: { kind: "Name", value: "amountCents" } },
                { kind: "Field", name: { kind: "Name", value: "currency" } },
                { kind: "Field", name: { kind: "Name", value: "display" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "categories" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "name" } },
                { kind: "Field", name: { kind: "Name", value: "root" } },
                { kind: "Field", name: { kind: "Name", value: "leaf" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "originalListing" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "description" } },
                { kind: "Field", name: { kind: "Name", value: "title" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "images" },
                  arguments: [
                    {
                      kind: "Argument",
                      name: { kind: "Name", value: "input" },
                      value: {
                        kind: "ObjectValue",
                        fields: [
                          {
                            kind: "ObjectField",
                            name: { kind: "Name", value: "transform" },
                            value: {
                              kind: "StringValue",
                              value: "card_square",
                              block: false,
                            },
                          },
                        ],
                      },
                    },
                  ],
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "source" },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "listing" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "id" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "csp" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                {
                  kind: "FragmentSpread",
                  name: { kind: "Name", value: "GearCollectionCSPData" },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "dataServicesPriceEstimate" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "priceLow" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "amountCents" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "currency" },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "priceHigh" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "amountCents" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "currency" },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "priceMiddle" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "amountCents" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "currency" },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "priceMiddleThirtyDaysAgo" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "amountCents" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "currency" },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "images" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "transform" },
                      value: {
                        kind: "StringValue",
                        value: "card_square",
                        block: false,
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "source" } },
                { kind: "Field", name: { kind: "Name", value: "publicId" } },
                { kind: "Field", name: { kind: "Name", value: "version" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "fallbackType" },
                },
                { kind: "Field", name: { kind: "Name", value: "height" } },
                { kind: "Field", name: { kind: "Name", value: "width" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "transformation" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "x" } },
                      { kind: "Field", name: { kind: "Name", value: "y" } },
                      { kind: "Field", name: { kind: "Name", value: "width" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "height" },
                      },
                      { kind: "Field", name: { kind: "Name", value: "angle" } },
                      { kind: "Field", name: { kind: "Name", value: "crop" } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CoreGearCollectionItemShowLayoutQuery,
  CoreGearCollectionItemShowLayoutQueryVariables
>;
export const Core_Gift_Card_Credit_CardsDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "Core_Gift_Card_Credit_Cards" },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "me" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "uuid" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "creditCards" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      { kind: "Field", name: { kind: "Name", value: "last4" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "cardType" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "expirationMonth" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "expirationYear" },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Core_Gift_Card_Credit_CardsQuery,
  Core_Gift_Card_Credit_CardsQueryVariables
>;
export const Core_ShopSettings_InternationalTaxProfileDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: {
        kind: "Name",
        value: "Core_ShopSettings_InternationalTaxProfile",
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "me" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "uuid" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "shop" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      {
                        kind: "Field",
                        name: {
                          kind: "Name",
                          value: "internationalTaxProfile",
                        },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "tinMask" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "tinType" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "name" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "dateOfBirth" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "address" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "_id" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "name" },
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "streetAddress",
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "extendedAddress",
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "locality" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "region" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "postalCode" },
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "countryCode",
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "country" },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "_id" },
                                        },
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "name" },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: {
                          kind: "Name",
                          value: "businessRegistrationNumber",
                        },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                            },
                            {
                              kind: "Field",
                              name: {
                                kind: "Name",
                                value: "registrationNumber",
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "countryCode" },
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "taxIdentifications" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "taxId" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "taxType" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "countryCode" },
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "address" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "_id" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "countryCode" },
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "shopType" },
                      },
                      {
                        kind: "Field",
                        name: {
                          kind: "Name",
                          value: "marketplaceTaxReportingCountryCode",
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "countries" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "countries" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "countryCode" },
                      },
                      { kind: "Field", name: { kind: "Name", value: "name" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "subregionRequired" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "subregions" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "_id" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "code" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "name" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Core_ShopSettings_InternationalTaxProfileQuery,
  Core_ShopSettings_InternationalTaxProfileQueryVariables
>;
export const UpdateInternationalTaxProfileDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "UpdateInternationalTaxProfile" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "input" },
          },
          type: {
            kind: "NamedType",
            name: {
              kind: "Name",
              value:
                "Input_core_apimessages_UpdateInternationalTaxProfileRequest",
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "updateInternationalTaxProfile" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "input" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "internationalTaxProfile" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "tinMask" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "tinType" },
                      },
                      { kind: "Field", name: { kind: "Name", value: "name" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "dateOfBirth" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "address" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "_id" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "name" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "streetAddress" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "extendedAddress" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "locality" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "region" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "postalCode" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "countryCode" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "country" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "_id" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "name" },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "businessRegistrationNumber" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "registrationNumber" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "countryCode" },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateInternationalTaxProfileMutation,
  UpdateInternationalTaxProfileMutationVariables
>;
export const Core_MyShopExportDac7OrderDataDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "Core_MyShopExportDac7OrderData" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "input" },
          },
          type: {
            kind: "NamedType",
            name: {
              kind: "Name",
              value: "Input_core_apimessages_MyShopExportDac7OrderDataRequest",
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "myShopExportDac7OrderData" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "input" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "success" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Core_MyShopExportDac7OrderDataMutation,
  Core_MyShopExportDac7OrderDataMutationVariables
>;
export const Core_About_This_Seller_Find_FavoriteDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "Core_About_This_Seller_Find_Favorite" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "shopSlug" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "loggedOut" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "Boolean" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "findFavorite" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "shopSlug" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "shopSlug" },
                      },
                    },
                  ],
                },
              },
            ],
            directives: [
              {
                kind: "Directive",
                name: { kind: "Name", value: "skip" },
                arguments: [
                  {
                    kind: "Argument",
                    name: { kind: "Name", value: "if" },
                    value: {
                      kind: "Variable",
                      name: { kind: "Name", value: "loggedOut" },
                    },
                  },
                ],
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "favorite" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Core_About_This_Seller_Find_FavoriteQuery,
  Core_About_This_Seller_Find_FavoriteQueryVariables
>;
export const Item2_Feedback_MetadataDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "Item2_Feedback_Metadata" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "sellerUuid" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "user" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "uuid" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "sellerUuid" },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                {
                  kind: "Field",
                  alias: { kind: "Name", value: "allTotal" },
                  name: { kind: "Name", value: "feedback" },
                  arguments: [
                    {
                      kind: "Argument",
                      name: { kind: "Name", value: "input" },
                      value: {
                        kind: "ObjectValue",
                        fields: [
                          {
                            kind: "ObjectField",
                            name: { kind: "Name", value: "type" },
                            value: { kind: "EnumValue", value: "RECEIVED" },
                          },
                        ],
                      },
                    },
                  ],
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "total" } },
                    ],
                  },
                },
                {
                  kind: "Field",
                  alias: { kind: "Name", value: "buyerTotal" },
                  name: { kind: "Name", value: "feedback" },
                  arguments: [
                    {
                      kind: "Argument",
                      name: { kind: "Name", value: "input" },
                      value: {
                        kind: "ObjectValue",
                        fields: [
                          {
                            kind: "ObjectField",
                            name: { kind: "Name", value: "type" },
                            value: { kind: "EnumValue", value: "BUYER" },
                          },
                        ],
                      },
                    },
                  ],
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "total" } },
                    ],
                  },
                },
                {
                  kind: "Field",
                  alias: { kind: "Name", value: "sellerTotal" },
                  name: { kind: "Name", value: "feedback" },
                  arguments: [
                    {
                      kind: "Argument",
                      name: { kind: "Name", value: "input" },
                      value: {
                        kind: "ObjectValue",
                        fields: [
                          {
                            kind: "ObjectField",
                            name: { kind: "Name", value: "type" },
                            value: { kind: "EnumValue", value: "SELLER" },
                          },
                        ],
                      },
                    },
                  ],
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "total" } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Item2_Feedback_MetadataQuery,
  Item2_Feedback_MetadataQueryVariables
>;
export const Item2_Seller_ReviewsDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "Item2_Seller_Reviews" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "sellerUuid" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
        },
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "type" } },
          type: {
            kind: "NamedType",
            name: { kind: "Name", value: "core_apimessages_Feedback_Type" },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "limit" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "Int" } },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "offset" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "Int" } },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "user" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "uuid" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "sellerUuid" },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "feedback" },
                  arguments: [
                    {
                      kind: "Argument",
                      name: { kind: "Name", value: "input" },
                      value: {
                        kind: "ObjectValue",
                        fields: [
                          {
                            kind: "ObjectField",
                            name: { kind: "Name", value: "limit" },
                            value: {
                              kind: "Variable",
                              name: { kind: "Name", value: "limit" },
                            },
                          },
                          {
                            kind: "ObjectField",
                            name: { kind: "Name", value: "offset" },
                            value: {
                              kind: "Variable",
                              name: { kind: "Name", value: "offset" },
                            },
                          },
                          {
                            kind: "ObjectField",
                            name: { kind: "Name", value: "type" },
                            value: {
                              kind: "Variable",
                              name: { kind: "Name", value: "type" },
                            },
                          },
                        ],
                      },
                    },
                  ],
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "total" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "feedbacks" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "FragmentSpread",
                              name: {
                                kind: "Name",
                                value: "Item2FeedbackItemData",
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "shop" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "slug" } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "Item2FeedbackItemData" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "core_apimessages_Feedback" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "author" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "shortname" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "createdAt" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "seconds" } },
              ],
            },
          },
          { kind: "Field", name: { kind: "Name", value: "message" } },
          { kind: "Field", name: { kind: "Name", value: "orderTitle" } },
          { kind: "Field", name: { kind: "Name", value: "rating" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "recipient" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "shortname" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "listingLink" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "href" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "response" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "body" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "createdAt" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "seconds" },
                      },
                    ],
                  },
                },
              ],
            },
          },
          { kind: "Field", name: { kind: "Name", value: "type" } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Item2_Seller_ReviewsQuery,
  Item2_Seller_ReviewsQueryVariables
>;
export const Core_Listing_LayoutDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "Core_Listing_Layout" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "id" } },
          type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "loggedOut" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "Boolean" },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "shouldSkipTracking" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "Boolean" },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "useNewBreadcrumbs" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "Boolean" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "listing" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "id" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "id" },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "state" } },
                { kind: "Field", name: { kind: "Name", value: "usOutlet" } },
                {
                  kind: "FragmentSpread",
                  name: { kind: "Name", value: "ShippingWarningData" },
                },
                {
                  kind: "FragmentSpread",
                  name: { kind: "Name", value: "Item2PricingData" },
                },
                {
                  kind: "FragmentSpread",
                  name: { kind: "Name", value: "Item2DescriptionData" },
                },
                {
                  kind: "FragmentSpread",
                  name: { kind: "Name", value: "Item2TitleData" },
                },
                {
                  kind: "FragmentSpread",
                  name: { kind: "Name", value: "Item2BreadcrumbsData" },
                  directives: [
                    {
                      kind: "Directive",
                      name: { kind: "Name", value: "include" },
                      arguments: [
                        {
                          kind: "Argument",
                          name: { kind: "Name", value: "if" },
                          value: {
                            kind: "Variable",
                            name: { kind: "Name", value: "useNewBreadcrumbs" },
                          },
                        },
                      ],
                    },
                  ],
                },
                {
                  kind: "FragmentSpread",
                  name: { kind: "Name", value: "Item2FallbackBreadcrumbsData" },
                  directives: [
                    {
                      kind: "Directive",
                      name: { kind: "Name", value: "skip" },
                      arguments: [
                        {
                          kind: "Argument",
                          name: { kind: "Name", value: "if" },
                          value: {
                            kind: "Variable",
                            name: { kind: "Name", value: "useNewBreadcrumbs" },
                          },
                        },
                      ],
                    },
                  ],
                },
                {
                  kind: "FragmentSpread",
                  name: { kind: "Name", value: "ItemImageFields" },
                },
                {
                  kind: "FragmentSpread",
                  name: { kind: "Name", value: "Item2Video" },
                },
                {
                  kind: "FragmentSpread",
                  name: { kind: "Name", value: "Item2FlagListingData" },
                },
                {
                  kind: "FragmentSpread",
                  name: { kind: "Name", value: "Item2MetadataData" },
                },
                {
                  kind: "FragmentSpread",
                  name: { kind: "Name", value: "Item2OrderDetailsData" },
                },
                {
                  kind: "FragmentSpread",
                  name: { kind: "Name", value: "Item2ProductViewTrackingData" },
                  directives: [
                    {
                      kind: "Directive",
                      name: { kind: "Name", value: "skip" },
                      arguments: [
                        {
                          kind: "Argument",
                          name: { kind: "Name", value: "if" },
                          value: {
                            kind: "Variable",
                            name: { kind: "Name", value: "shouldSkipTracking" },
                          },
                        },
                      ],
                    },
                  ],
                },
                {
                  kind: "FragmentSpread",
                  name: { kind: "Name", value: "Item2PaidLandingData" },
                },
                {
                  kind: "FragmentSpread",
                  name: { kind: "Name", value: "WatchBadgeData" },
                },
                {
                  kind: "FragmentSpread",
                  name: { kind: "Name", value: "Item2ProductSpecsData" },
                },
                {
                  kind: "FragmentSpread",
                  name: { kind: "Name", value: "ItemFooterFields" },
                },
                {
                  kind: "FragmentSpread",
                  name: {
                    kind: "Name",
                    value: "Item2AdWithCspsRowContainerData",
                  },
                },
                {
                  kind: "FragmentSpread",
                  name: { kind: "Name", value: "Item2ShopModuleData" },
                },
                {
                  kind: "FragmentSpread",
                  name: { kind: "Name", value: "Item2AboutThisSellerData" },
                },
                {
                  kind: "FragmentSpread",
                  name: { kind: "Name", value: "Item2ShopOverviewData" },
                },
                {
                  kind: "FragmentSpread",
                  name: { kind: "Name", value: "Item2ProductModuleData" },
                },
                {
                  kind: "FragmentSpread",
                  name: { kind: "Name", value: "Item2AboutThisListingData" },
                },
                {
                  kind: "FragmentSpread",
                  name: { kind: "Name", value: "Item2CPOData" },
                },
                {
                  kind: "FragmentSpread",
                  name: {
                    kind: "Name",
                    value: "ItemPageEndedListingExploreRowFields",
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "csp" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      { kind: "Field", name: { kind: "Name", value: "slug" } },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "shop" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      { kind: "Field", name: { kind: "Name", value: "name" } },
                      {
                        kind: "FragmentSpread",
                        name: { kind: "Name", value: "FavoriteShopCTAData" },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "pricing" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "buyerPrice" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "amount" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "currency" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "shipmentPackage" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "width" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "value" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "unit" },
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "height" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "value" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "unit" },
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "length" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "value" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "unit" },
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "weight" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "value" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "unit" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "experiments" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "name" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "bucket" },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "affirmFinancingPromotion" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "zeroPercentFinancing" },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "digitalDetails" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "variants" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "downloadText" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "fileUrl" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "fileSize" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "operatingSystems" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "fileTypes" },
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "supportedFormats" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "operatingSystem" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "fileTypes" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "AddToCartButtonFields" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Listing" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "sellerId" } },
          { kind: "Field", name: { kind: "Name", value: "listingType" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "pricing" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "buyerPrice" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "amount" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "amountCents" },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "preorderInfo" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "onPreorder" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "estimatedShipDate" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "seconds" },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "ListingForBuyerShippingFields" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Listing" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "shipping" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "freeExpeditedShipping" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "localPickupOnly" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "shippingPrices" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "shippingMethod" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "carrierCalculated" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "regional" },
                      },
                      {
                        kind: "Field",
                        name: {
                          kind: "Name",
                          value: "destinationPostalCodeNeeded",
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "postalCode" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "rate" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "amount" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "amountCents" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "currency" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "display" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "ListingShippingDisplayData" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Listing" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "shop" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "freeDomesticShippingRate" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "regionCode" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "freeShippingThreshold" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "amountCents" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "currency" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "ListingForBuyerShippingFields" },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "TypicalNewPriceDisplayFields" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Listing" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "condition" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "conditionUuid" },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "pricing" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "typicalNewPriceDisplay" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "amountDisplay" },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "originalPriceDescription" },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "Item2PriceWithShippingData" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Listing" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "state" } },
          { kind: "Field", name: { kind: "Name", value: "stateDescription" } },
          { kind: "Field", name: { kind: "Name", value: "taxIncluded" } },
          { kind: "Field", name: { kind: "Name", value: "taxIncludedHint" } },
          { kind: "Field", name: { kind: "Name", value: "listingType" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "pricing" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "originalPrice" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "display" },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "ribbon" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "display" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "reason" },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "buyerPrice" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "amountCents" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "currency" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "display" },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "shop" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "id" } },
              ],
            },
          },
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "ListingShippingDisplayData" },
          },
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "TypicalNewPriceDisplayFields" },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "CompareOtherSellersLinkData" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Listing" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "csp" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "id" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "webLink" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "href" } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "ListingAffirmCalloutFields" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Listing" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "estimatedMonthlyPaymentPlan" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "financingProgramSlug" },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "pricing" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "buyerPrice" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "display" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "amountCents" },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "shipping" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "shippingPrices" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "shippingMethod" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "rate" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "amountCents" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "ListingKlarnaCalloutFields" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Listing" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "pricing" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "buyerPrice" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "amountCents" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "currency" },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "shipping" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "shippingPrices" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "shippingMethod" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "rate" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "amountCents" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "ListingFinancingCalloutFields" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Listing" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "acceptedPaymentMethods" },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "pricing" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "buyerPrice" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "amountCents" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "amount" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "currency" },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "ListingAffirmCalloutFields" },
          },
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "ListingKlarnaCalloutFields" },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "Item2StatsData" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Listing" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "hasInventory" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "condition" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "conditionUuid" },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "publishedAt" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "seconds" } },
              ],
            },
          },
          { kind: "Field", name: { kind: "Name", value: "listingType" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "counts" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "viewsDisplay" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "offersDisplay" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "watchersDisplay" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "downloadCountDisplay" },
                },
              ],
            },
          },
          { kind: "Field", name: { kind: "Name", value: "offersEnabled" } },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "ListingGooglePayButtonFields" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Listing" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "expressPay" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "estimatedTotal" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "amount" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "currency" },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "googlePay" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "merchantId" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "merchantName" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "gatewayMerchantId" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "cardNetworks" },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "BuyItNowExpressPayOptionsFields" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Listing" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "currency" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "adyenCheckoutPaymentMethodsConfig" },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "expressPay" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "checkoutBundlingId" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "paypal" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "currency" },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "pricing" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "buyerPrice" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "amount" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "amountCents" },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "ListingGooglePayButtonFields" },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "BuyItNowPaymentOptionsFields" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Listing" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "acceptedPaymentMethods" },
          },
          { kind: "Field", name: { kind: "Name", value: "sellerId" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "shop" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "address" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "countryCode" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "country" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "_id" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "name" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "shipping" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "localPickupOnly" },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "pricing" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "buyerPrice" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "amount" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "amountCents" },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "BuyItNowExpressPayOptionsFields" },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "Item2CPOData" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Listing" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "brandSlug" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "certifiedPreOwned" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "title" } },
                { kind: "Field", name: { kind: "Name", value: "description" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "tooltipDescription" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "badgeIconUrl" },
                },
                { kind: "Field", name: { kind: "Name", value: "brandName" } },
                { kind: "Field", name: { kind: "Name", value: "pageUrl" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "lightModeBrandIconUrl" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "darkModeBrandIconUrl" },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "BuyItNowButtonFields" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Listing" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "shipping" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "localPickupOnly" },
                },
              ],
            },
          },
          { kind: "Field", name: { kind: "Name", value: "usOutlet" } },
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "BuyItNowPaymentOptionsFields" },
          },
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "Item2CPOData" },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "mParticleListingFields" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Listing" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "title" } },
          { kind: "Field", name: { kind: "Name", value: "brandSlug" } },
          { kind: "Field", name: { kind: "Name", value: "categoryRootUuid" } },
          { kind: "Field", name: { kind: "Name", value: "make" } },
          { kind: "Field", name: { kind: "Name", value: "categoryUuids" } },
          { kind: "Field", name: { kind: "Name", value: "state" } },
          { kind: "Field", name: { kind: "Name", value: "listingType" } },
          { kind: "Field", name: { kind: "Name", value: "bumpEligible" } },
          { kind: "Field", name: { kind: "Name", value: "shopId" } },
          { kind: "Field", name: { kind: "Name", value: "inventory" } },
          { kind: "Field", name: { kind: "Name", value: "soldAsIs" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "acceptedPaymentMethods" },
          },
          { kind: "Field", name: { kind: "Name", value: "currency" } },
          { kind: "Field", name: { kind: "Name", value: "usOutlet" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "condition" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "conditionUuid" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "conditionSlug" },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "categories" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "slug" } },
                { kind: "Field", name: { kind: "Name", value: "rootSlug" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "csp" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "slug" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "brand" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      { kind: "Field", name: { kind: "Name", value: "slug" } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "pricing" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "buyerPrice" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "amount" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "currency" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "amountCents" },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "publishedAt" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "seconds" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "sale" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "code" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "buyerIneligibilityReason" },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "shipping" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "shippingPrices" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "shippingMethod" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "carrierCalculated" },
                      },
                      {
                        kind: "Field",
                        name: {
                          kind: "Name",
                          value: "destinationPostalCodeNeeded",
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "rate" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "amount" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "amountCents" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "currency" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "display" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "freeExpeditedShipping" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "localPickupOnly" },
                },
                { kind: "Field", name: { kind: "Name", value: "localPickup" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "certifiedPreOwned" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "title" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "badgeIconUrl" },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "shop" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "address" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "countryCode" },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "returnPolicy" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "newReturnWindowDays" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "usedReturnWindowDays" },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "ListingCreateOfferButtonData" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Listing" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "state" } },
          { kind: "Field", name: { kind: "Name", value: "offersEnabled" } },
          { kind: "Field", name: { kind: "Name", value: "listingType" } },
          { kind: "Field", name: { kind: "Name", value: "sellerId" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "isBuyerOfferEligible" },
          },
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "mParticleListingFields" },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "DigitalDetailsFields" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Listing" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "listingType" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "digitalDetails" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "variants" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "downloadText" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "fileUrl" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "fileSize" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "operatingSystems" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "fileTypes" },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "supportedFormats" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "operatingSystem" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "fileTypes" },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "Item2Prop65WarningData" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Listing" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "sellerId" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "propSixtyFiveWarning" },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "propSixtyFiveWarningImg" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "href" } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "RecommendedShopsRowListingFields" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Listing" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "brandSlug" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "categories" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "slug" } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "Item2ShopReviewsData" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Listing" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "sellerUuid" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "shop" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "id" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "seller" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "feedbackSummary" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "receivedCount" },
                      },
                      {
                        kind: "Field",
                        name: {
                          kind: "Name",
                          value: "rollingRatingPercentage",
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "ShippingWarningData" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Listing" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "slug" } },
          { kind: "Field", name: { kind: "Name", value: "state" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "shipping" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "localPickupOnly" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "shippingPrices" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "shippingMethod" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "carrierCalculated" },
                      },
                      {
                        kind: "Field",
                        name: {
                          kind: "Name",
                          value: "destinationPostalCodeNeeded",
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "rate" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "amount" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "amountCents" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "currency" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "display" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "shop" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "userUuid" } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "Item2PricingData" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Listing" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "offersEnabled" } },
          { kind: "Field", name: { kind: "Name", value: "inventory" } },
          { kind: "Field", name: { kind: "Name", value: "hasInventory" } },
          { kind: "Field", name: { kind: "Name", value: "maxBuyerQuantity" } },
          { kind: "Field", name: { kind: "Name", value: "make" } },
          { kind: "Field", name: { kind: "Name", value: "model" } },
          { kind: "Field", name: { kind: "Name", value: "state" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "condition" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "conditionSlug" },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "shop" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "address" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "countryCode" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "country" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "_id" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "name" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "AddToCartButtonFields" },
          },
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "Item2PriceWithShippingData" },
          },
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "CompareOtherSellersLinkData" },
          },
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "ListingFinancingCalloutFields" },
          },
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "Item2StatsData" },
          },
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "BuyItNowButtonFields" },
          },
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "ListingCreateOfferButtonData" },
          },
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "DigitalDetailsFields" },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "Item2DescriptionData" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Listing" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "description" } },
          { kind: "Field", name: { kind: "Name", value: "soldAsIs" } },
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "Item2Prop65WarningData" },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "Item2TitleData" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Listing" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "title" } },
          { kind: "Field", name: { kind: "Name", value: "listingType" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "condition" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "conditionSlug" },
                },
                { kind: "Field", name: { kind: "Name", value: "displayName" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "categories" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "slug" } },
                { kind: "Field", name: { kind: "Name", value: "name" } },
                { kind: "Field", name: { kind: "Name", value: "rootSlug" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "csp" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "id" } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "Item2BreadcrumbsData" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Listing" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "breadcrumbs" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "title" } },
                { kind: "Field", name: { kind: "Name", value: "url" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "csp" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "slug" } },
                { kind: "Field", name: { kind: "Name", value: "title" } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "Item2FallbackBreadcrumbsData" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Listing" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "make" } },
          { kind: "Field", name: { kind: "Name", value: "brandSlug" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "categories" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "slug" } },
                { kind: "Field", name: { kind: "Name", value: "name" } },
                { kind: "Field", name: { kind: "Name", value: "rootSlug" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "webLink" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "href" } },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "cmsLink" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "href" } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "csp" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "id" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "specs" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "productSpecs" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "_id" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "type" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "name" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "value" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "path" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "ItemImageFields" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Listing" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "title" } },
          { kind: "Field", name: { kind: "Name", value: "state" } },
          {
            kind: "Field",
            alias: { kind: "Name", value: "largeImages" },
            name: { kind: "Name", value: "images" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "type" },
                      value: {
                        kind: "StringValue",
                        value: "Product",
                        block: false,
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "scope" },
                      value: {
                        kind: "StringValue",
                        value: "photos",
                        block: false,
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "transform" },
                      value: {
                        kind: "StringValue",
                        value: "large",
                        block: false,
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "count" },
                      value: { kind: "IntValue", value: "25" },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "source" } },
              ],
            },
          },
          {
            kind: "Field",
            alias: { kind: "Name", value: "supersizeImages" },
            name: { kind: "Name", value: "images" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "type" },
                      value: {
                        kind: "StringValue",
                        value: "Product",
                        block: false,
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "scope" },
                      value: {
                        kind: "StringValue",
                        value: "photos",
                        block: false,
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "transform" },
                      value: {
                        kind: "StringValue",
                        value: "supersize",
                        block: false,
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "count" },
                      value: { kind: "IntValue", value: "25" },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "source" } },
              ],
            },
          },
          {
            kind: "Field",
            alias: { kind: "Name", value: "cardSquareImages" },
            name: { kind: "Name", value: "images" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "type" },
                      value: {
                        kind: "StringValue",
                        value: "Product",
                        block: false,
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "scope" },
                      value: {
                        kind: "StringValue",
                        value: "photos",
                        block: false,
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "transform" },
                      value: {
                        kind: "StringValue",
                        value: "card_square",
                        block: false,
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "count" },
                      value: { kind: "IntValue", value: "25" },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "source" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "video" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "youtubeVideoId" },
                },
                { kind: "Field", name: { kind: "Name", value: "empty" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "csp" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "videos" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "videoLinks" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "youtubeVideoId" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "Item2Video" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Listing" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "video" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "youtubeVideoId" },
                },
                { kind: "Field", name: { kind: "Name", value: "empty" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "csp" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "videos" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "videoLinks" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "youtubeVideoId" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "Item2FlagListingData" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Listing" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "state" } },
          { kind: "Field", name: { kind: "Name", value: "sellerId" } },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "Item2MetadataData" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Listing" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "title" } },
          { kind: "Field", name: { kind: "Name", value: "description" } },
          { kind: "Field", name: { kind: "Name", value: "finish" } },
          { kind: "Field", name: { kind: "Name", value: "make" } },
          { kind: "Field", name: { kind: "Name", value: "model" } },
          { kind: "Field", name: { kind: "Name", value: "slug" } },
          { kind: "Field", name: { kind: "Name", value: "inventory" } },
          { kind: "Field", name: { kind: "Name", value: "state" } },
          { kind: "Field", name: { kind: "Name", value: "brandSlug" } },
          { kind: "Field", name: { kind: "Name", value: "soldAsIs" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "categories" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "name" } },
                { kind: "Field", name: { kind: "Name", value: "rootSlug" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "cmsLink" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "href" } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "condition" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "conditionSlug" },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "pricing" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "buyerPrice" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "amount" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "currency" },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "preorderInfo" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "onPreorder" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "csp" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "productReviewSearch" },
                  arguments: [
                    {
                      kind: "Argument",
                      name: { kind: "Name", value: "input" },
                      value: {
                        kind: "ObjectValue",
                        fields: [
                          {
                            kind: "ObjectField",
                            name: { kind: "Name", value: "limit" },
                            value: { kind: "IntValue", value: "0" },
                          },
                        ],
                      },
                    },
                  ],
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "stats" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "total" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "averageRating" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "shop" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "name" } },
                { kind: "Field", name: { kind: "Name", value: "onVacation" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "returnPolicy" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "usedReturnWindowDays" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "newReturnWindowDays" },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            alias: { kind: "Name", value: "metaImage" },
            name: { kind: "Name", value: "images" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "type" },
                      value: {
                        kind: "StringValue",
                        value: "Product",
                        block: false,
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "scope" },
                      value: {
                        kind: "StringValue",
                        value: "photos",
                        block: false,
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "transform" },
                      value: {
                        kind: "StringValue",
                        value: "large",
                        block: false,
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "count" },
                      value: { kind: "IntValue", value: "25" },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "source" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "experiments" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "name" } },
                { kind: "Field", name: { kind: "Name", value: "bucket" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "shipping" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "localPickupOnly" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "shippingPrices" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "shippingMethod" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "carrierCalculated" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "regional" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "rate" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "amount" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "currency" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "Item2OrderDetailsData" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Listing" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "orders" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "as" },
                      value: { kind: "EnumValue", value: "BUYER" },
                    },
                  ],
                },
              },
            ],
            directives: [
              {
                kind: "Directive",
                name: { kind: "Name", value: "skip" },
                arguments: [
                  {
                    kind: "Argument",
                    name: { kind: "Name", value: "if" },
                    value: {
                      kind: "Variable",
                      name: { kind: "Name", value: "loggedOut" },
                    },
                  },
                ],
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "nodes" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "legacyOrderId" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "status" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "paidAt" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "seconds" },
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "orderUrl" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "href" },
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "createdAt" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "seconds" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "Item2ProductViewTrackingData" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Listing" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "acceptedPaymentMethods" },
          },
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "mParticleListingFields" },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "Item2PaidLandingData" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Listing" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "state" } },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "WatchBadgeData" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Listing" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "title" } },
          { kind: "Field", name: { kind: "Name", value: "sellerId" } },
          { kind: "Field", name: { kind: "Name", value: "watching" } },
          {
            kind: "Field",
            alias: { kind: "Name", value: "watchThumbnails" },
            name: { kind: "Name", value: "images" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "type" },
                      value: {
                        kind: "StringValue",
                        value: "Product",
                        block: false,
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "scope" },
                      value: {
                        kind: "StringValue",
                        value: "photos",
                        block: false,
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "transform" },
                      value: {
                        kind: "StringValue",
                        value: "card_square",
                        block: false,
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "count" },
                      value: { kind: "IntValue", value: "1" },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "source" } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "Item2ProductSpecsData" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Listing" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "publishedAt" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "seconds" } },
              ],
            },
          },
          { kind: "Field", name: { kind: "Name", value: "hasInventory" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "condition" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "conditionUuid" },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "shop" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "slug" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "specs" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "productSpecs" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      { kind: "Field", name: { kind: "Name", value: "name" } },
                      { kind: "Field", name: { kind: "Name", value: "value" } },
                      { kind: "Field", name: { kind: "Name", value: "path" } },
                      { kind: "Field", name: { kind: "Name", value: "slug" } },
                      { kind: "Field", name: { kind: "Name", value: "type" } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "ItemFooterFields" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Listing" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "categoryRootUuid" } },
          { kind: "Field", name: { kind: "Name", value: "state" } },
          { kind: "Field", name: { kind: "Name", value: "shopId" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "combinedShippingParent" },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "shop" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "name" } },
                { kind: "Field", name: { kind: "Name", value: "slug" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "address" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "countryCode" },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "shipping" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "localPickupOnly" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "shippingPrices" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "shippingMethod" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "rate" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "amountCents" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "csp" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "slug" } },
              ],
            },
          },
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "RecommendedShopsRowListingFields" },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "Item2AdWithCspsRowContainerData" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Listing" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "categoryRootUuid" } },
          { kind: "Field", name: { kind: "Name", value: "categoryUuids" } },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "Item2ShopModuleData" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Listing" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "seller" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "uuid" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "avatar" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "source" },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "shop" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "name" } },
                { kind: "Field", name: { kind: "Name", value: "slug" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "preferredSeller" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "quickShipper" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "quickResponder" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "address" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "displayLocation" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "countryCode" },
                      },
                    ],
                  },
                },
                { kind: "Field", name: { kind: "Name", value: "createdAt" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "orders" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "total" } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "Item2ShopReviewsData" },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "Item2AboutThisSellerData" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Listing" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "seller" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "uuid" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "avatar" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "source" },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "feedbackSummary" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "receivedCount" },
                      },
                      {
                        kind: "Field",
                        name: {
                          kind: "Name",
                          value: "rollingRatingPercentage",
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "shop" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "name" } },
                { kind: "Field", name: { kind: "Name", value: "description" } },
                { kind: "Field", name: { kind: "Name", value: "slug" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "preferredSeller" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "quickShipper" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "quickResponder" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "address" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "displayLocation" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "countryCode" },
                      },
                    ],
                  },
                },
                { kind: "Field", name: { kind: "Name", value: "createdAt" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "orders" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "total" } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "Item2ShopOverviewData" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Listing" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "seller" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "feedbackSummary" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: {
                          kind: "Name",
                          value: "rollingRatingPercentage",
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "receivedCount" },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "avatar" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "source" },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "shop" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "name" } },
                { kind: "Field", name: { kind: "Name", value: "slug" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "address" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "displayLocation" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "countryCode" },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "Item2ProductModuleData" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Listing" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "canonicalProductId" },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "condition" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "conditionSlug" },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "categories" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "slug" } },
                { kind: "Field", name: { kind: "Name", value: "name" } },
                { kind: "Field", name: { kind: "Name", value: "rootSlug" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "webLink" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "href" } },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "cmsLink" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "href" } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "csp" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "title" } },
                { kind: "Field", name: { kind: "Name", value: "slug" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "webLink" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "path" } },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "image" },
                  arguments: [
                    {
                      kind: "Argument",
                      name: { kind: "Name", value: "input" },
                      value: {
                        kind: "ObjectValue",
                        fields: [
                          {
                            kind: "ObjectField",
                            name: { kind: "Name", value: "transform" },
                            value: {
                              kind: "StringValue",
                              value: "card_square",
                              block: false,
                            },
                          },
                        ],
                      },
                    },
                  ],
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "source" },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "inventory" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "newLowPrice" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "display" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "amountCents" },
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "usedLowPrice" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "display" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "amountCents" },
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "usedTotal" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "newTotal" },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "specs" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "productSpecs" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "_id" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "type" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "name" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "value" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "path" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "Item2AboutThisListingData" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Listing" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "description" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "publishedAt" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "seconds" } },
              ],
            },
          },
          { kind: "Field", name: { kind: "Name", value: "hasInventory" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "condition" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "conditionUuid" },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "specs" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "productSpecs" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      { kind: "Field", name: { kind: "Name", value: "name" } },
                      { kind: "Field", name: { kind: "Name", value: "value" } },
                      { kind: "Field", name: { kind: "Name", value: "path" } },
                      { kind: "Field", name: { kind: "Name", value: "slug" } },
                      { kind: "Field", name: { kind: "Name", value: "type" } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "Item2Prop65WarningData" },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "ItemPageEndedListingExploreRowFields" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Listing" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "csp" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "id" } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "FavoriteShopCTAData" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Shop" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "name" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "address" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "countryCode" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "user" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "feedbackSummary" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: {
                          kind: "Name",
                          value: "rollingRatingPercentage",
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "receivedCount" },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Core_Listing_LayoutQuery,
  Core_Listing_LayoutQueryVariables
>;
export const Core_Listing_Layout_SignalsDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "Core_Listing_Layout_Signals" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "id" } },
          type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "listing" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "id" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "id" },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                {
                  kind: "FragmentSpread",
                  name: { kind: "Name", value: "SignalsData" },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "SignalsData" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Listing" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "state" } },
          { kind: "Field", name: { kind: "Name", value: "usOutlet" } },
          { kind: "Field", name: { kind: "Name", value: "currency" } },
          {
            kind: "Field",
            alias: { kind: "Name", value: "signals" },
            name: { kind: "Name", value: "signals" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "groups" },
                      value: {
                        kind: "ListValue",
                        values: [
                          { kind: "EnumValue", value: "ITEM_PAGE_WHY_TO_BUY" },
                          { kind: "EnumValue", value: "ITEM_PAGE_NUDGES" },
                          { kind: "EnumValue", value: "ITEM_PAGE_WHY_REVERB" },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "name" } },
                { kind: "Field", name: { kind: "Name", value: "group" } },
                { kind: "Field", name: { kind: "Name", value: "title" } },
                { kind: "Field", name: { kind: "Name", value: "subtitle" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "link" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "text" } },
                      { kind: "Field", name: { kind: "Name", value: "url" } },
                    ],
                  },
                },
                { kind: "Field", name: { kind: "Name", value: "icon" } },
                { kind: "Field", name: { kind: "Name", value: "iconDark" } },
                { kind: "Field", name: { kind: "Name", value: "tooltipText" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Core_Listing_Layout_SignalsQuery,
  Core_Listing_Layout_SignalsQueryVariables
>;
export const Core_Listing_Layout_SimilarListingsDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "Core_Listing_Layout_SimilarListings" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "id" } },
          type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "itemRegion" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "itemRegionRelation" },
          },
          type: {
            kind: "NamedType",
            name: {
              kind: "Name",
              value: "reverb_search_ListingItemRegionRelation",
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "listing" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "id" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "id" },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "similarListings" },
                  arguments: [
                    {
                      kind: "Argument",
                      name: { kind: "Name", value: "input" },
                      value: {
                        kind: "ObjectValue",
                        fields: [
                          {
                            kind: "ObjectField",
                            name: { kind: "Name", value: "itemRegion" },
                            value: {
                              kind: "Variable",
                              name: { kind: "Name", value: "itemRegion" },
                            },
                          },
                          {
                            kind: "ObjectField",
                            name: { kind: "Name", value: "itemRegionRelation" },
                            value: {
                              kind: "Variable",
                              name: {
                                kind: "Name",
                                value: "itemRegionRelation",
                              },
                            },
                          },
                          {
                            kind: "ObjectField",
                            name: { kind: "Name", value: "limit" },
                            value: { kind: "IntValue", value: "10" },
                          },
                        ],
                      },
                    },
                  ],
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      {
                        kind: "FragmentSpread",
                        name: { kind: "Name", value: "SimilarListingsFields" },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "ListingForBuyerShippingFields" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Listing" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "shipping" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "freeExpeditedShipping" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "localPickupOnly" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "shippingPrices" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "shippingMethod" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "carrierCalculated" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "regional" },
                      },
                      {
                        kind: "Field",
                        name: {
                          kind: "Name",
                          value: "destinationPostalCodeNeeded",
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "postalCode" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "rate" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "amount" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "amountCents" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "currency" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "display" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "ListingGreatValueData" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Listing" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "pricing" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "buyerPrice" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "currency" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "amountCents" },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "condition" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "conditionSlug" },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "priceRecommendation" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "priceMiddle" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "amountCents" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "currency" },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "shop" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "address" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "country" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "_id" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "countryCode" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          { kind: "Field", name: { kind: "Name", value: "currency" } },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "ListingCardCPOData" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Listing" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "certifiedPreOwned" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "title" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "badgeIconUrl" },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "ListingForBuyerFields" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Listing" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "title" } },
          { kind: "Field", name: { kind: "Name", value: "slug" } },
          { kind: "Field", name: { kind: "Name", value: "listingType" } },
          { kind: "Field", name: { kind: "Name", value: "make" } },
          { kind: "Field", name: { kind: "Name", value: "model" } },
          { kind: "Field", name: { kind: "Name", value: "upc" } },
          { kind: "Field", name: { kind: "Name", value: "state" } },
          { kind: "Field", name: { kind: "Name", value: "stateDescription" } },
          { kind: "Field", name: { kind: "Name", value: "bumped" } },
          { kind: "Field", name: { kind: "Name", value: "watching" } },
          { kind: "Field", name: { kind: "Name", value: "soldAsIs" } },
          { kind: "Field", name: { kind: "Name", value: "usOutlet" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "publishedAt" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "seconds" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "condition" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "displayName" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "conditionSlug" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "conditionUuid" },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "pricing" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "buyerPrice" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "display" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "currency" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "amount" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "amountCents" },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "originalPrice" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "display" },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "ribbon" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "display" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "reason" },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "images" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "transform" },
                      value: {
                        kind: "StringValue",
                        value: "card_square",
                        block: false,
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "count" },
                      value: { kind: "IntValue", value: "3" },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "scope" },
                      value: {
                        kind: "StringValue",
                        value: "photos",
                        block: false,
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "type" },
                      value: {
                        kind: "StringValue",
                        value: "Product",
                        block: false,
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "source" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "shipping" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "shippingPrices" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "shippingMethod" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "carrierCalculated" },
                      },
                      {
                        kind: "Field",
                        name: {
                          kind: "Name",
                          value: "destinationPostalCodeNeeded",
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "rate" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "amount" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "amountCents" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "currency" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "display" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "freeExpeditedShipping" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "localPickupOnly" },
                },
                { kind: "Field", name: { kind: "Name", value: "localPickup" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "shop" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "name" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "returnPolicy" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "usedReturnWindowDays" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "newReturnWindowDays" },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "address" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "locality" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "region" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "country" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "_id" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "countryCode" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "name" },
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "displayLocation" },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "ListingForBuyerShippingFields" },
          },
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "ListingGreatValueData" },
          },
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "ListingCardCPOData" },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "WatchBadgeData" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Listing" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "title" } },
          { kind: "Field", name: { kind: "Name", value: "sellerId" } },
          { kind: "Field", name: { kind: "Name", value: "watching" } },
          {
            kind: "Field",
            alias: { kind: "Name", value: "watchThumbnails" },
            name: { kind: "Name", value: "images" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "type" },
                      value: {
                        kind: "StringValue",
                        value: "Product",
                        block: false,
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "scope" },
                      value: {
                        kind: "StringValue",
                        value: "photos",
                        block: false,
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "transform" },
                      value: {
                        kind: "StringValue",
                        value: "card_square",
                        block: false,
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "count" },
                      value: { kind: "IntValue", value: "1" },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "source" } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "mParticleListingFields" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Listing" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "title" } },
          { kind: "Field", name: { kind: "Name", value: "brandSlug" } },
          { kind: "Field", name: { kind: "Name", value: "categoryRootUuid" } },
          { kind: "Field", name: { kind: "Name", value: "make" } },
          { kind: "Field", name: { kind: "Name", value: "categoryUuids" } },
          { kind: "Field", name: { kind: "Name", value: "state" } },
          { kind: "Field", name: { kind: "Name", value: "listingType" } },
          { kind: "Field", name: { kind: "Name", value: "bumpEligible" } },
          { kind: "Field", name: { kind: "Name", value: "shopId" } },
          { kind: "Field", name: { kind: "Name", value: "inventory" } },
          { kind: "Field", name: { kind: "Name", value: "soldAsIs" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "acceptedPaymentMethods" },
          },
          { kind: "Field", name: { kind: "Name", value: "currency" } },
          { kind: "Field", name: { kind: "Name", value: "usOutlet" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "condition" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "conditionUuid" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "conditionSlug" },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "categories" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "slug" } },
                { kind: "Field", name: { kind: "Name", value: "rootSlug" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "csp" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "slug" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "brand" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      { kind: "Field", name: { kind: "Name", value: "slug" } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "pricing" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "buyerPrice" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "amount" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "currency" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "amountCents" },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "publishedAt" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "seconds" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "sale" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "code" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "buyerIneligibilityReason" },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "shipping" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "shippingPrices" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "shippingMethod" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "carrierCalculated" },
                      },
                      {
                        kind: "Field",
                        name: {
                          kind: "Name",
                          value: "destinationPostalCodeNeeded",
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "rate" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "amount" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "amountCents" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "currency" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "display" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "freeExpeditedShipping" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "localPickupOnly" },
                },
                { kind: "Field", name: { kind: "Name", value: "localPickup" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "certifiedPreOwned" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "title" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "badgeIconUrl" },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "shop" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "address" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "countryCode" },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "returnPolicy" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "newReturnWindowDays" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "usedReturnWindowDays" },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "ListingCreateOfferButtonData" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Listing" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "state" } },
          { kind: "Field", name: { kind: "Name", value: "offersEnabled" } },
          { kind: "Field", name: { kind: "Name", value: "listingType" } },
          { kind: "Field", name: { kind: "Name", value: "sellerId" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "isBuyerOfferEligible" },
          },
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "mParticleListingFields" },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "ListingCardFields" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Listing" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "ListingForBuyerFields" },
          },
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "WatchBadgeData" },
          },
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "ListingCreateOfferButtonData" },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "AddToCartButtonFields" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Listing" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "sellerId" } },
          { kind: "Field", name: { kind: "Name", value: "listingType" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "pricing" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "buyerPrice" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "amount" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "amountCents" },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "preorderInfo" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "onPreorder" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "estimatedShipDate" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "seconds" },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "SimilarListingsFields" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Listing" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "ListingCardFields" },
          },
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "AddToCartButtonFields" },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Core_Listing_Layout_SimilarListingsQuery,
  Core_Listing_Layout_SimilarListingsQueryVariables
>;
export const Core_Listing_Layout_MeDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "Core_Listing_Layout_Me" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "listingId" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "me" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                {
                  kind: "FragmentSpread",
                  name: {
                    kind: "Name",
                    value: "Item2PriceWithShippingCartItemData",
                  },
                },
                {
                  kind: "FragmentSpread",
                  name: { kind: "Name", value: "Item2LoggedInOffersCountData" },
                },
                {
                  kind: "FragmentSpread",
                  name: { kind: "Name", value: "CreateUserActionMeData" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "myNegotiations" },
                  arguments: [
                    {
                      kind: "Argument",
                      name: { kind: "Name", value: "input" },
                      value: {
                        kind: "ObjectValue",
                        fields: [
                          {
                            kind: "ObjectField",
                            name: { kind: "Name", value: "as" },
                            value: { kind: "EnumValue", value: "BUYER" },
                          },
                          {
                            kind: "ObjectField",
                            name: { kind: "Name", value: "limit" },
                            value: { kind: "IntValue", value: "1" },
                          },
                          {
                            kind: "ObjectField",
                            name: { kind: "Name", value: "listingIds" },
                            value: {
                              kind: "ListValue",
                              values: [
                                {
                                  kind: "Variable",
                                  name: { kind: "Name", value: "listingId" },
                                },
                              ],
                            },
                          },
                          {
                            kind: "ObjectField",
                            name: { kind: "Name", value: "sort" },
                            value: {
                              kind: "StringValue",
                              value: "updated_at|desc",
                              block: false,
                            },
                          },
                        ],
                      },
                    },
                  ],
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "negotiations" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "_id" },
                            },
                            {
                              kind: "FragmentSpread",
                              name: {
                                kind: "Name",
                                value: "Item2PricingNegotiationsData",
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "availableActions" },
                  arguments: [
                    {
                      kind: "Argument",
                      name: { kind: "Name", value: "input" },
                      value: {
                        kind: "ObjectValue",
                        fields: [
                          {
                            kind: "ObjectField",
                            name: { kind: "Name", value: "listingId" },
                            value: {
                              kind: "Variable",
                              name: { kind: "Name", value: "listingId" },
                            },
                          },
                        ],
                      },
                    },
                  ],
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "FragmentSpread",
                        name: {
                          kind: "Name",
                          value: "Item2AvailableActionsData",
                        },
                      },
                    ],
                  },
                },
                {
                  kind: "FragmentSpread",
                  name: { kind: "Name", value: "Item2FlagListingMeData" },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "Item2PriceWithShippingCartItemData" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "rql_Me" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "uuid" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "cart" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "cartItems" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "shopId" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "listingId" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "subtotal" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "amountCents" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "currency" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "Item2LoggedInOffersCountData" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "rql_Me" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "profile" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "totalBuyerOffersCreated" },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "CreateUserActionMeData" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "rql_Me" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "uuid" } },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "Item2PricingNegotiationsData" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Negotiation" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "lastOffer" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "status" } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "Item2AvailableActionsData" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "core_apimessages_AvailableAction" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "name" } },
          { kind: "Field", name: { kind: "Name", value: "status" } },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "Item2FlagListingMeData" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "rql_Me" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "uuid" } },
          { kind: "Field", name: { kind: "Name", value: "status" } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Core_Listing_Layout_MeQuery,
  Core_Listing_Layout_MeQueryVariables
>;
export const Core_ListingProtectionPlanOptionsDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "Core_ListingProtectionPlanOptions" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "listingId" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "listing" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "id" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "listingId" },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "protectionPlanOptions" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "availablePlans" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "planId" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "durationMonths" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "title" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "amount" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "amount" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "display" },
                                  },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "termsUrl" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Core_ListingProtectionPlanOptionsQuery,
  Core_ListingProtectionPlanOptionsQueryVariables
>;
export const Core_Listing_Item2ProductModuleListingRowDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: {
        kind: "Name",
        value: "Core_Listing_Item2ProductModuleListingRow",
      },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "canonicalProductIds" },
          },
          type: {
            kind: "ListType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "conditionSlugs" },
          },
          type: {
            kind: "ListType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "sort" } },
          type: {
            kind: "NamedType",
            name: {
              kind: "Name",
              value: "reverb_search_ListingsSearchRequest_Sort",
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "listingsSearch" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "canonicalProductIds" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "canonicalProductIds" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "conditionSlugs" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "conditionSlugs" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "bumpCount" },
                      value: { kind: "IntValue", value: "0" },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "sort" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "sort" },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "listings" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      {
                        kind: "FragmentSpread",
                        name: { kind: "Name", value: "ListingsCollection" },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "AddToCartButtonFields" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Listing" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "sellerId" } },
          { kind: "Field", name: { kind: "Name", value: "listingType" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "pricing" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "buyerPrice" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "amount" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "amountCents" },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "preorderInfo" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "onPreorder" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "estimatedShipDate" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "seconds" },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "WatchBadgeData" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Listing" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "title" } },
          { kind: "Field", name: { kind: "Name", value: "sellerId" } },
          { kind: "Field", name: { kind: "Name", value: "watching" } },
          {
            kind: "Field",
            alias: { kind: "Name", value: "watchThumbnails" },
            name: { kind: "Name", value: "images" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "type" },
                      value: {
                        kind: "StringValue",
                        value: "Product",
                        block: false,
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "scope" },
                      value: {
                        kind: "StringValue",
                        value: "photos",
                        block: false,
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "transform" },
                      value: {
                        kind: "StringValue",
                        value: "card_square",
                        block: false,
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "count" },
                      value: { kind: "IntValue", value: "1" },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "source" } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "ListingGreatValueData" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Listing" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "pricing" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "buyerPrice" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "currency" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "amountCents" },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "condition" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "conditionSlug" },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "priceRecommendation" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "priceMiddle" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "amountCents" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "currency" },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "shop" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "address" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "country" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "_id" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "countryCode" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          { kind: "Field", name: { kind: "Name", value: "currency" } },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "mParticleListingFields" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Listing" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "title" } },
          { kind: "Field", name: { kind: "Name", value: "brandSlug" } },
          { kind: "Field", name: { kind: "Name", value: "categoryRootUuid" } },
          { kind: "Field", name: { kind: "Name", value: "make" } },
          { kind: "Field", name: { kind: "Name", value: "categoryUuids" } },
          { kind: "Field", name: { kind: "Name", value: "state" } },
          { kind: "Field", name: { kind: "Name", value: "listingType" } },
          { kind: "Field", name: { kind: "Name", value: "bumpEligible" } },
          { kind: "Field", name: { kind: "Name", value: "shopId" } },
          { kind: "Field", name: { kind: "Name", value: "inventory" } },
          { kind: "Field", name: { kind: "Name", value: "soldAsIs" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "acceptedPaymentMethods" },
          },
          { kind: "Field", name: { kind: "Name", value: "currency" } },
          { kind: "Field", name: { kind: "Name", value: "usOutlet" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "condition" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "conditionUuid" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "conditionSlug" },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "categories" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "slug" } },
                { kind: "Field", name: { kind: "Name", value: "rootSlug" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "csp" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "slug" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "brand" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      { kind: "Field", name: { kind: "Name", value: "slug" } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "pricing" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "buyerPrice" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "amount" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "currency" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "amountCents" },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "publishedAt" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "seconds" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "sale" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "code" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "buyerIneligibilityReason" },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "shipping" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "shippingPrices" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "shippingMethod" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "carrierCalculated" },
                      },
                      {
                        kind: "Field",
                        name: {
                          kind: "Name",
                          value: "destinationPostalCodeNeeded",
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "rate" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "amount" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "amountCents" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "currency" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "display" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "freeExpeditedShipping" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "localPickupOnly" },
                },
                { kind: "Field", name: { kind: "Name", value: "localPickup" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "certifiedPreOwned" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "title" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "badgeIconUrl" },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "shop" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "address" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "countryCode" },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "returnPolicy" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "newReturnWindowDays" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "usedReturnWindowDays" },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "ListingCreateOfferButtonData" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Listing" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "state" } },
          { kind: "Field", name: { kind: "Name", value: "offersEnabled" } },
          { kind: "Field", name: { kind: "Name", value: "listingType" } },
          { kind: "Field", name: { kind: "Name", value: "sellerId" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "isBuyerOfferEligible" },
          },
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "mParticleListingFields" },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "ListingImagesFields" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Listing" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "images" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "transform" },
                      value: {
                        kind: "StringValue",
                        value: "card_square",
                        block: false,
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "count" },
                      value: { kind: "IntValue", value: "3" },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "scope" },
                      value: {
                        kind: "StringValue",
                        value: "photos",
                        block: false,
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "type" },
                      value: {
                        kind: "StringValue",
                        value: "Product",
                        block: false,
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "source" } },
              ],
            },
          },
          {
            kind: "Field",
            alias: { kind: "Name", value: "largeSquareImages" },
            name: { kind: "Name", value: "images" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "transform" },
                      value: {
                        kind: "StringValue",
                        value: "large_square",
                        block: false,
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "count" },
                      value: { kind: "IntValue", value: "3" },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "scope" },
                      value: {
                        kind: "StringValue",
                        value: "photos",
                        block: false,
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "type" },
                      value: {
                        kind: "StringValue",
                        value: "Product",
                        block: false,
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "source" } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "ListingCardCPOData" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Listing" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "certifiedPreOwned" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "title" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "badgeIconUrl" },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "ListingsCollection" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Listing" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "usOutlet" } },
          { kind: "Field", name: { kind: "Name", value: "bumped" } },
          { kind: "Field", name: { kind: "Name", value: "categoryUuids" } },
          { kind: "Field", name: { kind: "Name", value: "slug" } },
          { kind: "Field", name: { kind: "Name", value: "title" } },
          { kind: "Field", name: { kind: "Name", value: "description" } },
          { kind: "Field", name: { kind: "Name", value: "listingType" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "condition" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "displayName" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "conditionSlug" },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "price" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "amountCents" } },
                { kind: "Field", name: { kind: "Name", value: "display" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "pricing" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "buyerPrice" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "display" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "currency" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "amountCents" },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "originalPrice" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "display" },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "ribbon" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "display" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "reason" },
                      },
                    ],
                  },
                },
              ],
            },
          },
          { kind: "Field", name: { kind: "Name", value: "watching" } },
          { kind: "Field", name: { kind: "Name", value: "state" } },
          { kind: "Field", name: { kind: "Name", value: "stateDescription" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "shipping" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "shippingPrices" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "shippingMethod" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "carrierCalculated" },
                      },
                      {
                        kind: "Field",
                        name: {
                          kind: "Name",
                          value: "destinationPostalCodeNeeded",
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "rate" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "amount" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "amountCents" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "currency" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "display" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "freeExpeditedShipping" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "localPickupOnly" },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "shop" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "address" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "country" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "_id" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "countryCode" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "name" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "AddToCartButtonFields" },
          },
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "WatchBadgeData" },
          },
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "ListingGreatValueData" },
          },
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "ListingCreateOfferButtonData" },
          },
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "ListingImagesFields" },
          },
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "ListingCardCPOData" },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Core_Listing_Item2ProductModuleListingRowQuery,
  Core_Listing_Item2ProductModuleListingRowQueryVariables
>;
export const Core_Listing_ShippingBoxImageDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "Core_Listing_ShippingBoxImage" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "listingId" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "listing" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "id" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "listingId" },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "images" },
                  arguments: [
                    {
                      kind: "Argument",
                      name: { kind: "Name", value: "input" },
                      value: {
                        kind: "ObjectValue",
                        fields: [
                          {
                            kind: "ObjectField",
                            name: { kind: "Name", value: "transform" },
                            value: {
                              kind: "StringValue",
                              value: "card_square",
                              block: false,
                            },
                          },
                          {
                            kind: "ObjectField",
                            name: { kind: "Name", value: "count" },
                            value: { kind: "IntValue", value: "1" },
                          },
                        ],
                      },
                    },
                  ],
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "source" },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Core_Listing_ShippingBoxImageQuery,
  Core_Listing_ShippingBoxImageQueryVariables
>;
export const Item2_Shop_PoliciesDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "Item2_Shop_Policies" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "listingId" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "euUser" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "Boolean" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "listing" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "id" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "listingId" },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "shop" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      { kind: "Field", name: { kind: "Name", value: "uuid" } },
                      {
                        kind: "FragmentSpread",
                        name: { kind: "Name", value: "EUODRAddress" },
                        directives: [
                          {
                            kind: "Directive",
                            name: { kind: "Name", value: "include" },
                            arguments: [
                              {
                                kind: "Argument",
                                name: { kind: "Name", value: "if" },
                                value: {
                                  kind: "Variable",
                                  name: { kind: "Name", value: "euUser" },
                                },
                              },
                            ],
                          },
                        ],
                      },
                    ],
                  },
                },
                {
                  kind: "FragmentSpread",
                  name: { kind: "Name", value: "ShopPoliciesData" },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "Item2ListingPaymentPoliciesData" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Listing" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "acceptedPaymentMethods" },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "Item2ShopShippingPoliciesData" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Listing" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "shipping" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "localPickup" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "shippingPrices" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "shippingMethod" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "rate" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "display" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "shop" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "shippingPolicy" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "address" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "displayLocation" },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "allShippingPrices" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "shippingPrices" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "shippingMethod" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "rate" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "display" },
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "shippingRegionCode" },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "Item2ShopReturnPoliciesData" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Listing" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "listingType" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "acceptedPaymentMethods" },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "condition" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "conditionSlug" },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "estimatedMonthlyPaymentPlan" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "estimatedMonthlyPayment" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "display" },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "maxTermMonth" },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "shop" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "userUuid" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "returnPolicy" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "newReturnWindowDays" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "usedReturnWindowDays" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "restockingFeePercent" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "specialConditions" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "legacyReturnPolicyText" },
                      },
                    ],
                  },
                },
              ],
            },
          },
          { kind: "Field", name: { kind: "Name", value: "soldAsIs" } },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "Item2ShopTaxPoliciesData" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Listing" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "taxPolicies" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "displayRate" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "displayLocation" },
                },
                { kind: "Field", name: { kind: "Name", value: "policyType" } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "EUODRAddress" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Shop" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "vatId" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "euOdrAddress" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "name" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "streetAddress" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "displayLocation" },
                },
                { kind: "Field", name: { kind: "Name", value: "postalCode" } },
                { kind: "Field", name: { kind: "Name", value: "email" } },
                { kind: "Field", name: { kind: "Name", value: "phone" } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "ShopPoliciesData" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Listing" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "Item2ListingPaymentPoliciesData" },
          },
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "Item2ShopShippingPoliciesData" },
          },
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "Item2ShopReturnPoliciesData" },
          },
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "Item2ShopTaxPoliciesData" },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Item2_Shop_PoliciesQuery,
  Item2_Shop_PoliciesQueryVariables
>;
export const Core_Listing_ExploreRowDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "Core_Listing_ExploreRow" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "cspId" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "itemRegion" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "itemRegionRelation" },
          },
          type: {
            kind: "NamedType",
            name: {
              kind: "Name",
              value: "reverb_search_ListingItemRegionRelation",
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "shippingRegionCodes" },
          },
          type: {
            kind: "ListType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "similarListingIds" },
          },
          type: {
            kind: "ListType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "sort" } },
          type: {
            kind: "NamedType",
            name: {
              kind: "Name",
              value: "reverb_search_ListingsSearchRequest_Sort",
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "listingsSearch" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "boostByBumpRate" },
                      value: { kind: "BooleanValue", value: true },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "bumpedOnly" },
                      value: { kind: "BooleanValue", value: true },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "cspId" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "cspId" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "itemRegion" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "itemRegion" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "itemRegionRelation" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "itemRegionRelation" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "limit" },
                      value: { kind: "IntValue", value: "12" },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "shippingRegionCodes" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "shippingRegionCodes" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "similarListingIds" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "similarListingIds" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "sort" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "sort" },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "listings" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      {
                        kind: "FragmentSpread",
                        name: {
                          kind: "Name",
                          value: "ItemPageEndedListingExploreRowSearchFields",
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "AddToCartButtonFields" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Listing" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "sellerId" } },
          { kind: "Field", name: { kind: "Name", value: "listingType" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "pricing" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "buyerPrice" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "amount" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "amountCents" },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "preorderInfo" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "onPreorder" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "estimatedShipDate" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "seconds" },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "WatchBadgeData" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Listing" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "title" } },
          { kind: "Field", name: { kind: "Name", value: "sellerId" } },
          { kind: "Field", name: { kind: "Name", value: "watching" } },
          {
            kind: "Field",
            alias: { kind: "Name", value: "watchThumbnails" },
            name: { kind: "Name", value: "images" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "type" },
                      value: {
                        kind: "StringValue",
                        value: "Product",
                        block: false,
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "scope" },
                      value: {
                        kind: "StringValue",
                        value: "photos",
                        block: false,
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "transform" },
                      value: {
                        kind: "StringValue",
                        value: "card_square",
                        block: false,
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "count" },
                      value: { kind: "IntValue", value: "1" },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "source" } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "ListingGreatValueData" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Listing" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "pricing" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "buyerPrice" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "currency" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "amountCents" },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "condition" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "conditionSlug" },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "priceRecommendation" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "priceMiddle" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "amountCents" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "currency" },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "shop" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "address" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "country" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "_id" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "countryCode" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          { kind: "Field", name: { kind: "Name", value: "currency" } },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "mParticleListingFields" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Listing" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "title" } },
          { kind: "Field", name: { kind: "Name", value: "brandSlug" } },
          { kind: "Field", name: { kind: "Name", value: "categoryRootUuid" } },
          { kind: "Field", name: { kind: "Name", value: "make" } },
          { kind: "Field", name: { kind: "Name", value: "categoryUuids" } },
          { kind: "Field", name: { kind: "Name", value: "state" } },
          { kind: "Field", name: { kind: "Name", value: "listingType" } },
          { kind: "Field", name: { kind: "Name", value: "bumpEligible" } },
          { kind: "Field", name: { kind: "Name", value: "shopId" } },
          { kind: "Field", name: { kind: "Name", value: "inventory" } },
          { kind: "Field", name: { kind: "Name", value: "soldAsIs" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "acceptedPaymentMethods" },
          },
          { kind: "Field", name: { kind: "Name", value: "currency" } },
          { kind: "Field", name: { kind: "Name", value: "usOutlet" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "condition" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "conditionUuid" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "conditionSlug" },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "categories" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "slug" } },
                { kind: "Field", name: { kind: "Name", value: "rootSlug" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "csp" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "slug" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "brand" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      { kind: "Field", name: { kind: "Name", value: "slug" } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "pricing" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "buyerPrice" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "amount" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "currency" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "amountCents" },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "publishedAt" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "seconds" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "sale" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "code" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "buyerIneligibilityReason" },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "shipping" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "shippingPrices" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "shippingMethod" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "carrierCalculated" },
                      },
                      {
                        kind: "Field",
                        name: {
                          kind: "Name",
                          value: "destinationPostalCodeNeeded",
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "rate" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "amount" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "amountCents" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "currency" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "display" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "freeExpeditedShipping" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "localPickupOnly" },
                },
                { kind: "Field", name: { kind: "Name", value: "localPickup" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "certifiedPreOwned" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "title" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "badgeIconUrl" },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "shop" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "address" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "countryCode" },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "returnPolicy" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "newReturnWindowDays" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "usedReturnWindowDays" },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "ListingCreateOfferButtonData" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Listing" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "state" } },
          { kind: "Field", name: { kind: "Name", value: "offersEnabled" } },
          { kind: "Field", name: { kind: "Name", value: "listingType" } },
          { kind: "Field", name: { kind: "Name", value: "sellerId" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "isBuyerOfferEligible" },
          },
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "mParticleListingFields" },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "ListingImagesFields" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Listing" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "images" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "transform" },
                      value: {
                        kind: "StringValue",
                        value: "card_square",
                        block: false,
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "count" },
                      value: { kind: "IntValue", value: "3" },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "scope" },
                      value: {
                        kind: "StringValue",
                        value: "photos",
                        block: false,
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "type" },
                      value: {
                        kind: "StringValue",
                        value: "Product",
                        block: false,
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "source" } },
              ],
            },
          },
          {
            kind: "Field",
            alias: { kind: "Name", value: "largeSquareImages" },
            name: { kind: "Name", value: "images" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "transform" },
                      value: {
                        kind: "StringValue",
                        value: "large_square",
                        block: false,
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "count" },
                      value: { kind: "IntValue", value: "3" },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "scope" },
                      value: {
                        kind: "StringValue",
                        value: "photos",
                        block: false,
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "type" },
                      value: {
                        kind: "StringValue",
                        value: "Product",
                        block: false,
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "source" } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "ListingCardCPOData" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Listing" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "certifiedPreOwned" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "title" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "badgeIconUrl" },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "ListingsCollection" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Listing" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "usOutlet" } },
          { kind: "Field", name: { kind: "Name", value: "bumped" } },
          { kind: "Field", name: { kind: "Name", value: "categoryUuids" } },
          { kind: "Field", name: { kind: "Name", value: "slug" } },
          { kind: "Field", name: { kind: "Name", value: "title" } },
          { kind: "Field", name: { kind: "Name", value: "description" } },
          { kind: "Field", name: { kind: "Name", value: "listingType" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "condition" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "displayName" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "conditionSlug" },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "price" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "amountCents" } },
                { kind: "Field", name: { kind: "Name", value: "display" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "pricing" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "buyerPrice" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "display" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "currency" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "amountCents" },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "originalPrice" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "display" },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "ribbon" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "display" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "reason" },
                      },
                    ],
                  },
                },
              ],
            },
          },
          { kind: "Field", name: { kind: "Name", value: "watching" } },
          { kind: "Field", name: { kind: "Name", value: "state" } },
          { kind: "Field", name: { kind: "Name", value: "stateDescription" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "shipping" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "shippingPrices" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "shippingMethod" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "carrierCalculated" },
                      },
                      {
                        kind: "Field",
                        name: {
                          kind: "Name",
                          value: "destinationPostalCodeNeeded",
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "rate" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "amount" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "amountCents" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "currency" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "display" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "freeExpeditedShipping" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "localPickupOnly" },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "shop" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "address" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "country" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "_id" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "countryCode" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "name" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "AddToCartButtonFields" },
          },
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "WatchBadgeData" },
          },
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "ListingGreatValueData" },
          },
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "ListingCreateOfferButtonData" },
          },
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "ListingImagesFields" },
          },
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "ListingCardCPOData" },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "BumpKey" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Listing" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "bumpKey" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "key" } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: {
        kind: "Name",
        value: "ItemPageEndedListingExploreRowSearchFields",
      },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Listing" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "ListingsCollection" },
          },
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "mParticleListingFields" },
          },
          { kind: "FragmentSpread", name: { kind: "Name", value: "BumpKey" } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Core_Listing_ExploreRowQuery,
  Core_Listing_ExploreRowQueryVariables
>;
export const Core_QuickInventoryEdit_ListingDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "Core_QuickInventoryEdit_Listing" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "input" },
          },
          type: {
            kind: "NamedType",
            name: {
              kind: "Name",
              value: "Input_core_apimessages_UpdateListingRequest",
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "crupdateListing" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "input" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "state" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Core_QuickInventoryEdit_ListingMutation,
  Core_QuickInventoryEdit_ListingMutationVariables
>;
export const Core_AutoOfferModalDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "Core_AutoOfferModal" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "listingId" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "listing" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "id" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "listingId" },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                {
                  kind: "FragmentSpread",
                  name: { kind: "Name", value: "ModalSidebarListingFragment" },
                },
                {
                  kind: "FragmentSpread",
                  name: { kind: "Name", value: "AutoOfferListingFragment" },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "ModalSidebarListingFragment" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Listing" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "title" } },
          { kind: "Field", name: { kind: "Name", value: "slug" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "condition" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "displayName" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "images" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "transform" },
                      value: {
                        kind: "StringValue",
                        value: "card_square",
                        block: false,
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "source" } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "AutoOfferListingFragment" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Listing" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "title" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "price" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "amount" } },
                { kind: "Field", name: { kind: "Name", value: "amountCents" } },
                { kind: "Field", name: { kind: "Name", value: "currency" } },
                { kind: "Field", name: { kind: "Name", value: "symbol" } },
                { kind: "Field", name: { kind: "Name", value: "display" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "condition" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "displayName" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "sellerCost" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "display" } },
              ],
            },
          },
          { kind: "Field", name: { kind: "Name", value: "taxIncluded" } },
          { kind: "Field", name: { kind: "Name", value: "taxIncludedHint" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "sellerPrice" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "amountCents" } },
                { kind: "Field", name: { kind: "Name", value: "display" } },
                { kind: "Field", name: { kind: "Name", value: "currency" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "counts" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "watchers" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "autoOffers" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "listingId" } },
                { kind: "Field", name: { kind: "Name", value: "message" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "price" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "amount" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "currency" },
                      },
                    ],
                  },
                },
                { kind: "Field", name: { kind: "Name", value: "percentage" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "shippingPriceOverride" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "amount" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "amountCents" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "currency" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "symbol" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "display" },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "shipmentPackage" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Core_AutoOfferModalQuery,
  Core_AutoOfferModalQueryVariables
>;
export const Core_QuickPriceEdit_ListingDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "Core_QuickPriceEdit_Listing" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "listingId" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "shopCurrency" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "shopCountryCode" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "skipPriceRecommendation" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "Boolean" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "listing" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "id" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "listingId" },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                {
                  kind: "FragmentSpread",
                  name: { kind: "Name", value: "HydrateSellForm" },
                },
                {
                  kind: "FragmentSpread",
                  name: {
                    kind: "Name",
                    value: "SellerListingPriceRecommendation",
                  },
                  directives: [
                    {
                      kind: "Directive",
                      name: { kind: "Name", value: "skip" },
                      arguments: [
                        {
                          kind: "Argument",
                          name: { kind: "Name", value: "if" },
                          value: {
                            kind: "Variable",
                            name: {
                              kind: "Name",
                              value: "skipPriceRecommendation",
                            },
                          },
                        },
                      ],
                    },
                  ],
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "ShippingProfilePrices" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Shop" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "shippingProfiles" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "name" } },
                { kind: "Field", name: { kind: "Name", value: "id" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "categoryUuids" },
                },
                { kind: "Field", name: { kind: "Name", value: "localPickup" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "allShippingRates" },
                  arguments: [
                    {
                      kind: "Argument",
                      name: { kind: "Name", value: "input" },
                      value: {
                        kind: "ObjectValue",
                        fields: [
                          {
                            kind: "ObjectField",
                            name: { kind: "Name", value: "inOriginalCurrency" },
                            value: { kind: "BooleanValue", value: true },
                          },
                        ],
                      },
                    },
                  ],
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "regionCode" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "regionName" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "rateType" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "rate" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "display" },
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "incrementalRate" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "display" },
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "expeditedRate" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "display" },
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "regionalRates" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "areas" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "rate" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "display" },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "regionalExpeditedRates" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "areas" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "rate" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "display" },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "SellFormShopShipping" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Shop" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "currencySetting" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "sameDayShippingConfigured" },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "shippingRegionSettings" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "regionCode" } },
                { kind: "Field", name: { kind: "Name", value: "regionName" } },
                { kind: "Field", name: { kind: "Name", value: "isDomestic" } },
                {
                  kind: "Field",
                  name: {
                    kind: "Name",
                    value: "allowedToShipCarrierCalculated",
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "address" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "region" } },
                { kind: "Field", name: { kind: "Name", value: "postalCode" } },
                { kind: "Field", name: { kind: "Name", value: "countryCode" } },
              ],
            },
          },
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "ShippingProfilePrices" },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "HydrateSellForm" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Listing" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "state" } },
          { kind: "Field", name: { kind: "Name", value: "sku" } },
          { kind: "Field", name: { kind: "Name", value: "year" } },
          { kind: "Field", name: { kind: "Name", value: "make" } },
          { kind: "Field", name: { kind: "Name", value: "countryOfOrigin" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "canonicalProductId" },
          },
          { kind: "Field", name: { kind: "Name", value: "subcategoryUuids" } },
          { kind: "Field", name: { kind: "Name", value: "categoryRootUuid" } },
          { kind: "Field", name: { kind: "Name", value: "seedId" } },
          { kind: "Field", name: { kind: "Name", value: "seedType" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "publishedAt" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "seconds" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "categories" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "name" } },
                { kind: "Field", name: { kind: "Name", value: "root" } },
                { kind: "Field", name: { kind: "Name", value: "leaf" } },
                { kind: "Field", name: { kind: "Name", value: "rootSlug" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "localizedContents" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "locale" } },
                { kind: "Field", name: { kind: "Name", value: "make" } },
                { kind: "Field", name: { kind: "Name", value: "model" } },
                { kind: "Field", name: { kind: "Name", value: "finish" } },
                { kind: "Field", name: { kind: "Name", value: "title" } },
                { kind: "Field", name: { kind: "Name", value: "description" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "prop65Warning" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "defaultForShop" },
                },
              ],
            },
          },
          { kind: "Field", name: { kind: "Name", value: "handmade" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "traits" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "keyId" } },
                { kind: "Field", name: { kind: "Name", value: "valueId" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "condition" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "conditionSlug" },
                },
              ],
            },
          },
          { kind: "Field", name: { kind: "Name", value: "hasInventory" } },
          { kind: "Field", name: { kind: "Name", value: "inventory" } },
          { kind: "Field", name: { kind: "Name", value: "upc" } },
          { kind: "Field", name: { kind: "Name", value: "upcDoesNotApply" } },
          { kind: "Field", name: { kind: "Name", value: "taxExempt" } },
          { kind: "Field", name: { kind: "Name", value: "relistable" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "video" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "link" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "bumpRate" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "rate" } },
              ],
            },
          },
          { kind: "Field", name: { kind: "Name", value: "bumpEligible" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "images" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "type" },
                      value: {
                        kind: "StringValue",
                        value: "Product",
                        block: false,
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "scope" },
                      value: {
                        kind: "StringValue",
                        value: "photos",
                        block: false,
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "transform" },
                      value: {
                        kind: "StringValue",
                        value: "card_square",
                        block: false,
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "source" } },
                { kind: "Field", name: { kind: "Name", value: "itemId" } },
                { kind: "Field", name: { kind: "Name", value: "version" } },
                { kind: "Field", name: { kind: "Name", value: "itemType" } },
                { kind: "Field", name: { kind: "Name", value: "publicId" } },
                { kind: "Field", name: { kind: "Name", value: "height" } },
                { kind: "Field", name: { kind: "Name", value: "width" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "transformation" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "x" } },
                      { kind: "Field", name: { kind: "Name", value: "y" } },
                      { kind: "Field", name: { kind: "Name", value: "width" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "height" },
                      },
                      { kind: "Field", name: { kind: "Name", value: "crop" } },
                      { kind: "Field", name: { kind: "Name", value: "angle" } },
                    ],
                  },
                },
              ],
            },
          },
          { kind: "Field", name: { kind: "Name", value: "shippingProfileId" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "allShippingPrices" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "localPickup" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "shippingPrices" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "shippingRegionCode" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "shippingMethod" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "carrierCalculated" },
                      },
                      {
                        kind: "Field",
                        name: {
                          kind: "Name",
                          value: "originalRateVatExcluded",
                        },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "amount" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "currency" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "shipmentPackage" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "length" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "value" } },
                      { kind: "Field", name: { kind: "Name", value: "unit" } },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "width" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "value" } },
                      { kind: "Field", name: { kind: "Name", value: "unit" } },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "height" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "value" } },
                      { kind: "Field", name: { kind: "Name", value: "unit" } },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "weight" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "value" } },
                      { kind: "Field", name: { kind: "Name", value: "unit" } },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "allowedCarriers" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "packageSizeSuggestionId" },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "prefersReverbShippingLabel" },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "sellerPrice" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "amountCents" } },
                { kind: "Field", name: { kind: "Name", value: "currency" } },
                { kind: "Field", name: { kind: "Name", value: "amount" } },
                { kind: "Field", name: { kind: "Name", value: "display" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "sellerCost" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "amountCents" } },
                { kind: "Field", name: { kind: "Name", value: "currency" } },
                { kind: "Field", name: { kind: "Name", value: "amount" } },
                { kind: "Field", name: { kind: "Name", value: "display" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "sellerReportedMap" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "amountCents" } },
                { kind: "Field", name: { kind: "Name", value: "currency" } },
                { kind: "Field", name: { kind: "Name", value: "amount" } },
                { kind: "Field", name: { kind: "Name", value: "display" } },
              ],
            },
          },
          { kind: "Field", name: { kind: "Name", value: "vatIncluded" } },
          { kind: "Field", name: { kind: "Name", value: "vatIncludedHint" } },
          { kind: "Field", name: { kind: "Name", value: "soldAsIs" } },
          { kind: "Field", name: { kind: "Name", value: "listingType" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "overriddenAttributes" },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "preorderInfo" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "shipDate" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "seconds" },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "leadTimeDays" },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "shop" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "currencySetting" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "defaultLocale" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "shopSupportedLocaleCodes" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "preferredSeller" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "hasAccountRep" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "inclusiveVatPricingEnabled" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "listableConditions" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "displayName" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "conditionSlug" },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "offerPolicy" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "autoRejectLowOffers" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "lowballPercentage" },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "returnPolicy" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "newReturnWindowDays" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "usedReturnWindowDays" },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "taxPolicies" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "vatPolicy" },
                      },
                    ],
                  },
                },
                { kind: "Field", name: { kind: "Name", value: "vatId" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "firstTimeSeller" },
                },
                {
                  kind: "FragmentSpread",
                  name: { kind: "Name", value: "SellFormShopShipping" },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "scheduledPriceDrops" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "active" } },
                { kind: "Field", name: { kind: "Name", value: "dropAt" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "newPrice" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "amountCents" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "currency" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "amount" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "display" },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "SellerListingPriceRecommendation" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Listing" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "priceRecommendation" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "currency" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "shopCurrency" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "countryCode" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "shopCountryCode" },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "priceLow" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "amount" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "amountCents" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "display" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "currency" },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "priceMiddle" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "amount" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "amountCents" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "display" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "currency" },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "priceHigh" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "amount" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "amountCents" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "display" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "currency" },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Core_QuickPriceEdit_ListingQuery,
  Core_QuickPriceEdit_ListingQueryVariables
>;
export const Core_QuickPriceEdit_PriceUpdateDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "Core_QuickPriceEdit_PriceUpdate" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "input" },
          },
          type: {
            kind: "NamedType",
            name: {
              kind: "Name",
              value: "Input_core_apimessages_UpdateListingRequest",
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "crupdateListing" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "input" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "sellerPrice" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "amountCents" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "currency" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "amount" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "display" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "symbol" },
                      },
                    ],
                  },
                },
                { kind: "Field", name: { kind: "Name", value: "taxExempt" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Core_QuickPriceEdit_PriceUpdateMutation,
  Core_QuickPriceEdit_PriceUpdateMutationVariables
>;
export const Core_MyListings_SellerListingCardsDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "Core_MyListings_SellerListingCards" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "listingIds" },
          },
          type: {
            kind: "ListType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "shippingRegionCodes" },
          },
          type: {
            kind: "ListType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "itemRegion" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "shopCurrency" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "shopCountryCode" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "skipPriceRecommendation" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "Boolean" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "me" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "uuid" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "availableActions" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      { kind: "Field", name: { kind: "Name", value: "name" } },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "shop" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "acceptsAffirm" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "canCurateSales" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "currency" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "listings" },
                        arguments: [
                          {
                            kind: "Argument",
                            name: { kind: "Name", value: "input" },
                            value: {
                              kind: "ObjectValue",
                              fields: [
                                {
                                  kind: "ObjectField",
                                  name: { kind: "Name", value: "ids" },
                                  value: {
                                    kind: "Variable",
                                    name: { kind: "Name", value: "listingIds" },
                                  },
                                },
                                {
                                  kind: "ObjectField",
                                  name: {
                                    kind: "Name",
                                    value: "shippingRegionCodes",
                                  },
                                  value: {
                                    kind: "Variable",
                                    name: {
                                      kind: "Name",
                                      value: "shippingRegionCodes",
                                    },
                                  },
                                },
                                {
                                  kind: "ObjectField",
                                  name: { kind: "Name", value: "itemRegion" },
                                  value: {
                                    kind: "Variable",
                                    name: { kind: "Name", value: "itemRegion" },
                                  },
                                },
                              ],
                            },
                          },
                        ],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "listings" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "_id" },
                                  },
                                  {
                                    kind: "FragmentSpread",
                                    name: {
                                      kind: "Name",
                                      value: "SellerListingCard",
                                    },
                                  },
                                  {
                                    kind: "FragmentSpread",
                                    name: {
                                      kind: "Name",
                                      value: "SellerListingPriceRecommendation",
                                    },
                                    directives: [
                                      {
                                        kind: "Directive",
                                        name: { kind: "Name", value: "skip" },
                                        arguments: [
                                          {
                                            kind: "Argument",
                                            name: { kind: "Name", value: "if" },
                                            value: {
                                              kind: "Variable",
                                              name: {
                                                kind: "Name",
                                                value:
                                                  "skipPriceRecommendation",
                                              },
                                            },
                                          },
                                        ],
                                      },
                                    ],
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "SellerListingCard" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Listing" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "sellerUuid" } },
          { kind: "Field", name: { kind: "Name", value: "title" } },
          { kind: "Field", name: { kind: "Name", value: "slug" } },
          { kind: "Field", name: { kind: "Name", value: "sku" } },
          { kind: "Field", name: { kind: "Name", value: "state" } },
          { kind: "Field", name: { kind: "Name", value: "inventory" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "condition" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "conditionSlug" },
                },
                { kind: "Field", name: { kind: "Name", value: "displayName" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "bumpRate" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "rate" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "sellerPrice" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "amount" } },
                { kind: "Field", name: { kind: "Name", value: "amountCents" } },
                { kind: "Field", name: { kind: "Name", value: "symbol" } },
                { kind: "Field", name: { kind: "Name", value: "currency" } },
                { kind: "Field", name: { kind: "Name", value: "display" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "images" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "transform" },
                      value: {
                        kind: "StringValue",
                        value: "card_square",
                        block: false,
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "count" },
                      value: { kind: "IntValue", value: "1" },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "source" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "counts" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "views" } },
                { kind: "Field", name: { kind: "Name", value: "offers" } },
                { kind: "Field", name: { kind: "Name", value: "watchers" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "inBuyerCarts" },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "orderStats" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "totalOrders" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "publishedAt" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "seconds" } },
              ],
            },
          },
          { kind: "Field", name: { kind: "Name", value: "hasInventory" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "isAutoOfferEligible" },
          },
          {
            kind: "Field",
            name: {
              kind: "Name",
              value: "affirmZeroPercentFinancingConfiguration",
            },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "isEligible" } },
                { kind: "Field", name: { kind: "Name", value: "isEnabled" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "autoOffers" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "shop" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "currency" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "canCurateSales" },
                },
              ],
            },
          },
          { kind: "Field", name: { kind: "Name", value: "exclusiveChannel" } },
          { kind: "Field", name: { kind: "Name", value: "relistable" } },
          { kind: "Field", name: { kind: "Name", value: "vatIncluded" } },
          { kind: "Field", name: { kind: "Name", value: "vatIncludedHint" } },
          { kind: "Field", name: { kind: "Name", value: "taxExempt" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "publishValidation" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "isValid" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "errors" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "message" },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "shipmentPackage" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "length" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "value" } },
                      { kind: "Field", name: { kind: "Name", value: "unit" } },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "width" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "value" } },
                      { kind: "Field", name: { kind: "Name", value: "unit" } },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "height" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "value" } },
                      { kind: "Field", name: { kind: "Name", value: "unit" } },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "weight" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "value" } },
                      { kind: "Field", name: { kind: "Name", value: "unit" } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "SellerListingPriceRecommendation" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Listing" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "priceRecommendation" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "currency" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "shopCurrency" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "countryCode" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "shopCountryCode" },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "priceLow" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "amount" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "amountCents" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "display" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "currency" },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "priceMiddle" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "amount" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "amountCents" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "display" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "currency" },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "priceHigh" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "amount" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "amountCents" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "display" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "currency" },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Core_MyListings_SellerListingCardsQuery,
  Core_MyListings_SellerListingCardsQueryVariables
>;
export const Core_MyListings_BulkDeleteDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "Core_MyListings_BulkDelete" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "input" },
          },
          type: {
            kind: "NamedType",
            name: {
              kind: "Name",
              value: "Input_core_apimessages_BulkDeleteListingsRequest",
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "bulkDeleteListings" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "input" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  alias: { kind: "Name", value: "responses" },
                  name: { kind: "Name", value: "listings" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "listingId" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "success" },
                      },
                      {
                        kind: "Field",
                        alias: { kind: "Name", value: "errors" },
                        name: { kind: "Name", value: "deleteErrors" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "message" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Core_MyListings_BulkDeleteMutation,
  Core_MyListings_BulkDeleteMutationVariables
>;
export const Core_MyListings_BulkDeleteAsyncDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "Core_MyListings_BulkDeleteAsync" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "input" },
          },
          type: {
            kind: "NamedType",
            name: {
              kind: "Name",
              value: "Input_core_apimessages_BulkDeleteListingsAsyncRequest",
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "bulkDeleteListingsAsync" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "input" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "enqueued" } },
                { kind: "Field", name: { kind: "Name", value: "message" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Core_MyListings_BulkDeleteAsyncMutation,
  Core_MyListings_BulkDeleteAsyncMutationVariables
>;
export const Core_MyListings_BulkPublishDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "Core_MyListings_BulkPublish" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "input" },
          },
          type: {
            kind: "NamedType",
            name: {
              kind: "Name",
              value: "Input_core_apimessages_BulkPublishListingsRequest",
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "bulkPublishListings" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "input" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  alias: { kind: "Name", value: "responses" },
                  name: { kind: "Name", value: "listings" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "listingId" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "success" },
                      },
                      {
                        kind: "Field",
                        alias: { kind: "Name", value: "errors" },
                        name: { kind: "Name", value: "publishErrors" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "message" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "prepublishStepRequired" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "step" } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Core_MyListings_BulkPublishMutation,
  Core_MyListings_BulkPublishMutationVariables
>;
export const Core_MyListings_BulkPublishAsyncDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "Core_MyListings_BulkPublishAsync" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "input" },
          },
          type: {
            kind: "NamedType",
            name: {
              kind: "Name",
              value: "Input_core_apimessages_BulkPublishListingsAsyncRequest",
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "bulkPublishListingsAsync" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "input" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "enqueued" } },
                { kind: "Field", name: { kind: "Name", value: "message" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Core_MyListings_BulkPublishAsyncMutation,
  Core_MyListings_BulkPublishAsyncMutationVariables
>;
export const Core_MyListings_ExportListingsDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "Core_MyListings_ExportListings" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "input" },
          },
          type: {
            kind: "NamedType",
            name: {
              kind: "Name",
              value: "Input_core_apimessages_BulkExportListingsRequest",
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "bulkExportListings" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "input" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "enqueued" } },
                { kind: "Field", name: { kind: "Name", value: "message" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Core_MyListings_ExportListingsMutation,
  Core_MyListings_ExportListingsMutationVariables
>;
export const Core_MyListings_PublishAllListingsDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "Core_MyListings_PublishAllListings" },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "publishAllListings" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "enqueued" } },
                { kind: "Field", name: { kind: "Name", value: "message" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "prepublishStepRequired" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "step" } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Core_MyListings_PublishAllListingsMutation,
  Core_MyListings_PublishAllListingsMutationVariables
>;
export const Core_MyListings_BulkCreateAffirmDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "Core_MyListings_BulkCreateAffirm" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "input" },
          },
          type: {
            kind: "NamedType",
            name: {
              kind: "Name",
              value:
                "Input_core_apimessages_BulkCreateZeroPercentAffirmRequest",
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "bulkCreateZeroPercentAffirm" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "input" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "responses" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "listingId" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "success" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "errors" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "message" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Core_MyListings_BulkCreateAffirmMutation,
  Core_MyListings_BulkCreateAffirmMutationVariables
>;
export const Core_MyListings_BulkCreateAffirmAsyncDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "Core_MyListings_BulkCreateAffirmAsync" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "input" },
          },
          type: {
            kind: "NamedType",
            name: {
              kind: "Name",
              value:
                "Input_core_apimessages_BulkCreateZeroPercentAffirmAsyncRequest",
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "bulkCreateZeroPercentAffirmAsync" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "input" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "enqueued" } },
                { kind: "Field", name: { kind: "Name", value: "message" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Core_MyListings_BulkCreateAffirmAsyncMutation,
  Core_MyListings_BulkCreateAffirmAsyncMutationVariables
>;
export const Core_MyListings_BulkDeleteAffirmDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "Core_MyListings_BulkDeleteAffirm" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "input" },
          },
          type: {
            kind: "NamedType",
            name: {
              kind: "Name",
              value:
                "Input_core_apimessages_BulkDeleteZeroPercentAffirmRequest",
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "bulkDeleteZeroPercentAffirm" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "input" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "responses" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "listingId" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "success" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "errors" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "message" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Core_MyListings_BulkDeleteAffirmMutation,
  Core_MyListings_BulkDeleteAffirmMutationVariables
>;
export const Core_MyListings_BulkDeleteAffirmAsyncDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "Core_MyListings_BulkDeleteAffirmAsync" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "input" },
          },
          type: {
            kind: "NamedType",
            name: {
              kind: "Name",
              value:
                "Input_core_apimessages_BulkDeleteZeroPercentAffirmAsyncRequest",
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "bulkDeleteZeroPercentAffirmAsync" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "input" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "enqueued" } },
                { kind: "Field", name: { kind: "Name", value: "message" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Core_MyListings_BulkDeleteAffirmAsyncMutation,
  Core_MyListings_BulkDeleteAffirmAsyncMutationVariables
>;
export const Core_MyListings_BulkCreateBumpedDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "Core_MyListings_BulkCreateBumped" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "input" },
          },
          type: {
            kind: "NamedType",
            name: {
              kind: "Name",
              value: "Input_core_apimessages_BulkCreateBumpedRequest",
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "bulkCreateBumped" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "input" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "responses" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "listingId" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "success" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "errors" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "message" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Core_MyListings_BulkCreateBumpedMutation,
  Core_MyListings_BulkCreateBumpedMutationVariables
>;
export const Core_MyListings_BulkCreateBumpedAsyncDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "Core_MyListings_BulkCreateBumpedAsync" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "input" },
          },
          type: {
            kind: "NamedType",
            name: {
              kind: "Name",
              value: "Input_core_apimessages_BulkCreateBumpedAsyncRequest",
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "bulkCreateBumpedAsync" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "input" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "enqueued" } },
                { kind: "Field", name: { kind: "Name", value: "message" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Core_MyListings_BulkCreateBumpedAsyncMutation,
  Core_MyListings_BulkCreateBumpedAsyncMutationVariables
>;
export const Core_MyListings_BulkDeleteBumpedDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "Core_MyListings_BulkDeleteBumped" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "input" },
          },
          type: {
            kind: "NamedType",
            name: {
              kind: "Name",
              value: "Input_core_apimessages_BulkDeleteBumpedRequest",
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "bulkDeleteBumped" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "input" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "responses" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "listingId" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "success" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "errors" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "message" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Core_MyListings_BulkDeleteBumpedMutation,
  Core_MyListings_BulkDeleteBumpedMutationVariables
>;
export const Core_MyListings_BulkDeleteBumpedAsyncDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "Core_MyListings_BulkDeleteBumpedAsync" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "input" },
          },
          type: {
            kind: "NamedType",
            name: {
              kind: "Name",
              value: "Input_core_apimessages_BulkDeleteBumpedAsyncRequest",
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "bulkDeleteBumpedAsync" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "input" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "enqueued" } },
                { kind: "Field", name: { kind: "Name", value: "message" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Core_MyListings_BulkDeleteBumpedAsyncMutation,
  Core_MyListings_BulkDeleteBumpedAsyncMutationVariables
>;
export const Core_MyListings_BulkCreateAutoOffersDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "Core_MyListings_BulkCreateAutoOffers" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "input" },
          },
          type: {
            kind: "NamedType",
            name: {
              kind: "Name",
              value: "Input_core_apimessages_BulkCreateAutoOffersRequest",
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "bulkCreateAutoOffers" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "input" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "responses" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "listingId" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "success" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "errors" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "message" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Core_MyListings_BulkCreateAutoOffersMutation,
  Core_MyListings_BulkCreateAutoOffersMutationVariables
>;
export const Core_MyListings_BulkCreateAutoOffersAsyncDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: {
        kind: "Name",
        value: "Core_MyListings_BulkCreateAutoOffersAsync",
      },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "input" },
          },
          type: {
            kind: "NamedType",
            name: {
              kind: "Name",
              value: "Input_core_apimessages_BulkCreateAutoOffersAsyncRequest",
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "bulkCreateAutoOffersAsync" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "input" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "enqueued" } },
                { kind: "Field", name: { kind: "Name", value: "message" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Core_MyListings_BulkCreateAutoOffersAsyncMutation,
  Core_MyListings_BulkCreateAutoOffersAsyncMutationVariables
>;
export const Core_MyListings_BulkDeleteAutoOffersDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "Core_MyListings_BulkDeleteAutoOffers" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "input" },
          },
          type: {
            kind: "NamedType",
            name: {
              kind: "Name",
              value: "Input_core_apimessages_BulkDeleteAutoOffersRequest",
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "bulkDeleteAutoOffers" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "input" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "responses" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "listingId" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "success" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "errors" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "message" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Core_MyListings_BulkDeleteAutoOffersMutation,
  Core_MyListings_BulkDeleteAutoOffersMutationVariables
>;
export const Core_MyListings_BulkDeleteAutoOffersAsyncDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: {
        kind: "Name",
        value: "Core_MyListings_BulkDeleteAutoOffersAsync",
      },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "input" },
          },
          type: {
            kind: "NamedType",
            name: {
              kind: "Name",
              value: "Input_core_apimessages_BulkDeleteAutoOffersAsyncRequest",
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "bulkDeleteAutoOffersAsync" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "input" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "enqueued" } },
                { kind: "Field", name: { kind: "Name", value: "message" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Core_MyListings_BulkDeleteAutoOffersAsyncMutation,
  Core_MyListings_BulkDeleteAutoOffersAsyncMutationVariables
>;
export const Core_MyListings_BulkCreateSaleMembershipsDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: {
        kind: "Name",
        value: "Core_MyListings_BulkCreateSaleMemberships",
      },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "input" },
          },
          type: {
            kind: "NamedType",
            name: {
              kind: "Name",
              value: "Input_core_apimessages_BulkSaleMembershipsRequest",
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "bulkCreateSaleMemberships" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "input" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "responses" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "listingId" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "success" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "errors" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "message" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Core_MyListings_BulkCreateSaleMembershipsMutation,
  Core_MyListings_BulkCreateSaleMembershipsMutationVariables
>;
export const Core_MyListings_BulkDeleteSaleMembershipsDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: {
        kind: "Name",
        value: "Core_MyListings_BulkDeleteSaleMemberships",
      },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "input" },
          },
          type: {
            kind: "NamedType",
            name: {
              kind: "Name",
              value: "Input_core_apimessages_BulkSaleMembershipsRequest",
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "bulkDeleteSaleMemberships" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "input" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "responses" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "listingId" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "success" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "errors" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "message" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Core_MyListings_BulkDeleteSaleMembershipsMutation,
  Core_MyListings_BulkDeleteSaleMembershipsMutationVariables
>;
export const Core_MySalesDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "Core_MySales" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "listingIds" },
          },
          type: {
            kind: "ListType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "me" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "uuid" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "shop" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "sales" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "reverbSales" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "_id" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "name" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "startsAt" },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "seconds",
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "endsAt" },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "seconds",
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "state" },
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "discountValue",
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "links" },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "self" },
                                          selectionSet: {
                                            kind: "SelectionSet",
                                            selections: [
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "href",
                                                },
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "sellerSales" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "_id" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "name" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "startsAt" },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "seconds",
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "endsAt" },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "seconds",
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "state" },
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "discountValue",
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "links" },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "self" },
                                          selectionSet: {
                                            kind: "SelectionSet",
                                            selections: [
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "href",
                                                },
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "listings" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "ids" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "listingIds" },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "id" } },
                {
                  kind: "FragmentSpread",
                  name: { kind: "Name", value: "AvailableSalesMemberships" },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "AvailableSalesMemberships" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Listing" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "availableSalesMemberships" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "reverbSalesMemberships" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "listingId" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "sale" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "_id" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "name" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "percent" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "startsAt" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "seconds" },
                                  },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "endsAt" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "seconds" },
                                  },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "links" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "self" },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "href" },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "totalLiveListings" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "isListingInSale" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "isListingEligible" },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "sellerSalesMemberships" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "listingId" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "sale" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "_id" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "name" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "percent" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "startsAt" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "seconds" },
                                  },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "endsAt" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "seconds" },
                                  },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "links" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "self" },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "href" },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "totalLiveListings" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "isListingInSale" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "isListingEligible" },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<Core_MySalesQuery, Core_MySalesQueryVariables>;
export const Core_Listing_DeleteDraftDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "Core_Listing_DeleteDraft" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "input" },
          },
          type: {
            kind: "NamedType",
            name: {
              kind: "Name",
              value: "Input_core_apimessages_DraftListingDeleteRequest",
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "deleteDraftListing" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "input" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "listingId" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Core_Listing_DeleteDraftMutation,
  Core_Listing_DeleteDraftMutationVariables
>;
export const Core_ZeroFinancing_EnableDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "Core_ZeroFinancing_Enable" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "input" },
          },
          type: {
            kind: "NamedType",
            name: {
              kind: "Name",
              value:
                "Input_core_apimessages_MyAffirmCuratedSetMembershipRequest",
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "addListingToMyAffirmCuratedSet" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "input" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "curatedSetMembership" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "curatedSetId" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "productId" },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Core_ZeroFinancing_EnableMutation,
  Core_ZeroFinancing_EnableMutationVariables
>;
export const Core_ZeroFinancing_DisableDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "Core_ZeroFinancing_Disable" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "input" },
          },
          type: {
            kind: "NamedType",
            name: {
              kind: "Name",
              value:
                "Input_core_apimessages_MyAffirmCuratedSetMembershipRequest",
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: {
              kind: "Name",
              value: "removeListingFromMyAffirmCuratedSet",
            },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "input" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "curatedSetMembership" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "curatedSetId" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "productId" },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Core_ZeroFinancing_DisableMutation,
  Core_ZeroFinancing_DisableMutationVariables
>;
export const Core_MyListing_CreateSaleMembershipDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "Core_MyListing_CreateSaleMembership" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "input" },
          },
          type: {
            kind: "NamedType",
            name: {
              kind: "Name",
              value: "Input_core_apimessages_CreateMySaleMembershipRequest",
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "createMySaleMembership" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "input" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "saleMembership" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "listingId" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "sale" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "_id" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "name" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "percent" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "startsAt" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "seconds" },
                                  },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "endsAt" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "seconds" },
                                  },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "links" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "self" },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "href" },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "totalLiveListings" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "isListingInSale" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "isListingEligible" },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Core_MyListing_CreateSaleMembershipMutation,
  Core_MyListing_CreateSaleMembershipMutationVariables
>;
export const Core_MyListing_DeleteSaleMembershipDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "Core_MyListing_DeleteSaleMembership" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "input" },
          },
          type: {
            kind: "NamedType",
            name: {
              kind: "Name",
              value: "Input_core_apimessages_DeleteMySaleMembershipRequest",
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "deleteMySaleMembership" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "input" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "saleMembership" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "listingId" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "sale" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "_id" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "name" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "percent" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "startsAt" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "seconds" },
                                  },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "endsAt" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "seconds" },
                                  },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "links" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "self" },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "href" },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "totalLiveListings" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "isListingInSale" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "isListingEligible" },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Core_MyListing_DeleteSaleMembershipMutation,
  Core_MyListing_DeleteSaleMembershipMutationVariables
>;
export const Core_MyListing_AvailableSalesMembershipsDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "Core_MyListing_AvailableSalesMemberships" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "listingId" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "listing" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "id" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "listingId" },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "id" } },
                {
                  kind: "FragmentSpread",
                  name: { kind: "Name", value: "AvailableSalesMemberships" },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "AvailableSalesMemberships" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Listing" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "availableSalesMemberships" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "reverbSalesMemberships" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "listingId" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "sale" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "_id" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "name" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "percent" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "startsAt" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "seconds" },
                                  },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "endsAt" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "seconds" },
                                  },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "links" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "self" },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "href" },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "totalLiveListings" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "isListingInSale" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "isListingEligible" },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "sellerSalesMemberships" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "listingId" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "sale" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "_id" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "name" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "percent" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "startsAt" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "seconds" },
                                  },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "endsAt" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "seconds" },
                                  },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "links" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "self" },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "href" },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "totalLiveListings" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "isListingInSale" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "isListingEligible" },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Core_MyListing_AvailableSalesMembershipsQuery,
  Core_MyListing_AvailableSalesMembershipsQueryVariables
>;
export const Core_Listing_UpdateStateDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "Core_Listing_UpdateState" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "input" },
          },
          type: {
            kind: "NamedType",
            name: {
              kind: "Name",
              value: "Input_core_apimessages_UpdateListingStateRequest",
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "updateListingState" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "input" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "newState" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Core_Listing_UpdateStateMutation,
  Core_Listing_UpdateStateMutationVariables
>;
export const CombinedMarketplaceBumpTrackingDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "CombinedMarketplaceBumpTracking" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "input" },
          },
          type: {
            kind: "NamedType",
            name: {
              kind: "Name",
              value: "Input_arbiter_BulkTrackImpressionsRequest",
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "bulkTrackImpressions" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "input" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "listingIds" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CombinedMarketplaceBumpTrackingMutation,
  CombinedMarketplaceBumpTrackingMutationVariables
>;
export const Core_Marketplace_HeaderDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "Core_Marketplace_Header" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "collectionType" },
          },
          type: {
            kind: "NamedType",
            name: {
              kind: "Name",
              value: "core_apimessages_CollectionHeader_CollectionType",
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "collectionSlug" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "skipCollectionHeader" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "Boolean" },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "categorySlugs" },
          },
          type: {
            kind: "ListType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "brandSlugs" },
          },
          type: {
            kind: "ListType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "conditionSlugs" },
          },
          type: {
            kind: "ListType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "curatedSetSlug" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "shopSlug" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "yearMin" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "yearMax" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "query" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "collectionHeader" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "collectionType" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "collectionType" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "collectionSlug" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "collectionSlug" },
                      },
                    },
                  ],
                },
              },
            ],
            directives: [
              {
                kind: "Directive",
                name: { kind: "Name", value: "skip" },
                arguments: [
                  {
                    kind: "Argument",
                    name: { kind: "Name", value: "if" },
                    value: {
                      kind: "Variable",
                      name: { kind: "Name", value: "skipCollectionHeader" },
                    },
                  },
                ],
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                {
                  kind: "FragmentSpread",
                  name: { kind: "Name", value: "CollectionInfo" },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "searchMetadata" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "categorySlugs" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "categorySlugs" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "brandSlugs" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "brandSlugs" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "conditionSlugs" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "conditionSlugs" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "curatedSetSlug" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "curatedSetSlug" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "shopSlug" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "shopSlug" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "yearMin" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "yearMin" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "yearMax" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "yearMax" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "query" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "query" },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "title" } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "CollectionInfo" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "CollectionHeader" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "title" } },
          { kind: "Field", name: { kind: "Name", value: "metaTitle" } },
          { kind: "Field", name: { kind: "Name", value: "collectionId" } },
          { kind: "Field", name: { kind: "Name", value: "collectionType" } },
          { kind: "Field", name: { kind: "Name", value: "description" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "fullBleedImage" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "source" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Core_Marketplace_HeaderQuery,
  Core_Marketplace_HeaderQueryVariables
>;
export const Core_Marketplace_CombinedMarketplaceSearchDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: {
        kind: "Name",
        value: "Core_Marketplace_CombinedMarketplaceSearch",
      },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "inputListings" },
          },
          type: {
            kind: "NamedType",
            name: {
              kind: "Name",
              value: "Input_reverb_search_ListingsSearchRequest",
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "inputBumped" },
          },
          type: {
            kind: "NamedType",
            name: {
              kind: "Name",
              value: "Input_reverb_search_ListingsSearchRequest",
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "inputAggs" },
          },
          type: {
            kind: "NamedType",
            name: {
              kind: "Name",
              value: "Input_reverb_search_ListingsSearchRequest",
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "shouldntLoadBumps" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "Boolean" },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "shouldntLoadSuggestions" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "Boolean" },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "usingListView" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "Boolean" },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "signalGroups" },
          },
          type: {
            kind: "ListType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "reverb_signals_Signal_Group" },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "useSignalSystem" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "Boolean" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            alias: { kind: "Name", value: "bumpedSearch" },
            name: { kind: "Name", value: "listingsSearch" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "inputBumped" },
                },
              },
            ],
            directives: [
              {
                kind: "Directive",
                name: { kind: "Name", value: "skip" },
                arguments: [
                  {
                    kind: "Argument",
                    name: { kind: "Name", value: "if" },
                    value: {
                      kind: "Variable",
                      name: { kind: "Name", value: "shouldntLoadBumps" },
                    },
                  },
                ],
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "listings" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      {
                        kind: "FragmentSpread",
                        name: { kind: "Name", value: "ListingCardFields" },
                      },
                      {
                        kind: "FragmentSpread",
                        name: { kind: "Name", value: "WatchBadgeData" },
                      },
                      {
                        kind: "FragmentSpread",
                        name: { kind: "Name", value: "BumpKey" },
                      },
                      {
                        kind: "FragmentSpread",
                        name: { kind: "Name", value: "ShopFields" },
                      },
                      {
                        kind: "FragmentSpread",
                        name: { kind: "Name", value: "ListViewListings" },
                        directives: [
                          {
                            kind: "Directive",
                            name: { kind: "Name", value: "include" },
                            arguments: [
                              {
                                kind: "Argument",
                                name: { kind: "Name", value: "if" },
                                value: {
                                  kind: "Variable",
                                  name: {
                                    kind: "Name",
                                    value: "usingListView",
                                  },
                                },
                              },
                            ],
                          },
                        ],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "signals" },
                        arguments: [
                          {
                            kind: "Argument",
                            name: { kind: "Name", value: "input" },
                            value: {
                              kind: "ObjectValue",
                              fields: [
                                {
                                  kind: "ObjectField",
                                  name: { kind: "Name", value: "groups" },
                                  value: {
                                    kind: "Variable",
                                    name: {
                                      kind: "Name",
                                      value: "signalGroups",
                                    },
                                  },
                                },
                              ],
                            },
                          },
                        ],
                        directives: [
                          {
                            kind: "Directive",
                            name: { kind: "Name", value: "include" },
                            arguments: [
                              {
                                kind: "Argument",
                                name: { kind: "Name", value: "if" },
                                value: {
                                  kind: "Variable",
                                  name: {
                                    kind: "Name",
                                    value: "useSignalSystem",
                                  },
                                },
                              },
                            ],
                          },
                        ],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "FragmentSpread",
                              name: {
                                kind: "Name",
                                value: "ListingCardSignalsData",
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            alias: { kind: "Name", value: "aggsSearch" },
            name: { kind: "Name", value: "listingsSearch" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "inputAggs" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "filters" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "FragmentSpread",
                        name: { kind: "Name", value: "NestedFilter" },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "listingsSearch" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "inputListings" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "total" } },
                { kind: "Field", name: { kind: "Name", value: "offset" } },
                { kind: "Field", name: { kind: "Name", value: "limit" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "suggestedQueries" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "eligibleAutodirects" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "listings" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "esScore" },
                      },
                      {
                        kind: "FragmentSpread",
                        name: { kind: "Name", value: "ListingCardFields" },
                      },
                      {
                        kind: "FragmentSpread",
                        name: { kind: "Name", value: "WatchBadgeData" },
                      },
                      {
                        kind: "FragmentSpread",
                        name: { kind: "Name", value: "InOtherCartsCardData" },
                        directives: [
                          {
                            kind: "Directive",
                            name: { kind: "Name", value: "skip" },
                            arguments: [
                              {
                                kind: "Argument",
                                name: { kind: "Name", value: "if" },
                                value: {
                                  kind: "Variable",
                                  name: {
                                    kind: "Name",
                                    value: "useSignalSystem",
                                  },
                                },
                              },
                            ],
                          },
                        ],
                      },
                      {
                        kind: "FragmentSpread",
                        name: { kind: "Name", value: "ShopFields" },
                      },
                      {
                        kind: "FragmentSpread",
                        name: { kind: "Name", value: "ListViewListings" },
                        directives: [
                          {
                            kind: "Directive",
                            name: { kind: "Name", value: "include" },
                            arguments: [
                              {
                                kind: "Argument",
                                name: { kind: "Name", value: "if" },
                                value: {
                                  kind: "Variable",
                                  name: {
                                    kind: "Name",
                                    value: "usingListView",
                                  },
                                },
                              },
                            ],
                          },
                        ],
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "signals" },
                        arguments: [
                          {
                            kind: "Argument",
                            name: { kind: "Name", value: "input" },
                            value: {
                              kind: "ObjectValue",
                              fields: [
                                {
                                  kind: "ObjectField",
                                  name: { kind: "Name", value: "groups" },
                                  value: {
                                    kind: "Variable",
                                    name: {
                                      kind: "Name",
                                      value: "signalGroups",
                                    },
                                  },
                                },
                              ],
                            },
                          },
                        ],
                        directives: [
                          {
                            kind: "Directive",
                            name: { kind: "Name", value: "include" },
                            arguments: [
                              {
                                kind: "Argument",
                                name: { kind: "Name", value: "if" },
                                value: {
                                  kind: "Variable",
                                  name: {
                                    kind: "Name",
                                    value: "useSignalSystem",
                                  },
                                },
                              },
                            ],
                          },
                        ],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "FragmentSpread",
                              name: {
                                kind: "Name",
                                value: "ListingCardSignalsData",
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "fallbackListings" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      {
                        kind: "FragmentSpread",
                        name: { kind: "Name", value: "ListingCardFields" },
                      },
                      {
                        kind: "FragmentSpread",
                        name: { kind: "Name", value: "InOtherCartsCardData" },
                        directives: [
                          {
                            kind: "Directive",
                            name: { kind: "Name", value: "skip" },
                            arguments: [
                              {
                                kind: "Argument",
                                name: { kind: "Name", value: "if" },
                                value: {
                                  kind: "Variable",
                                  name: {
                                    kind: "Name",
                                    value: "useSignalSystem",
                                  },
                                },
                              },
                            ],
                          },
                        ],
                      },
                      {
                        kind: "FragmentSpread",
                        name: { kind: "Name", value: "WatchBadgeData" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "signals" },
                        arguments: [
                          {
                            kind: "Argument",
                            name: { kind: "Name", value: "input" },
                            value: {
                              kind: "ObjectValue",
                              fields: [
                                {
                                  kind: "ObjectField",
                                  name: { kind: "Name", value: "groups" },
                                  value: {
                                    kind: "Variable",
                                    name: {
                                      kind: "Name",
                                      value: "signalGroups",
                                    },
                                  },
                                },
                              ],
                            },
                          },
                        ],
                        directives: [
                          {
                            kind: "Directive",
                            name: { kind: "Name", value: "include" },
                            arguments: [
                              {
                                kind: "Argument",
                                name: { kind: "Name", value: "if" },
                                value: {
                                  kind: "Variable",
                                  name: {
                                    kind: "Name",
                                    value: "useSignalSystem",
                                  },
                                },
                              },
                            ],
                          },
                        ],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "FragmentSpread",
                              name: {
                                kind: "Name",
                                value: "ListingCardSignalsData",
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "suggestions" },
                  directives: [
                    {
                      kind: "Directive",
                      name: { kind: "Name", value: "skip" },
                      arguments: [
                        {
                          kind: "Argument",
                          name: { kind: "Name", value: "if" },
                          value: {
                            kind: "Variable",
                            name: {
                              kind: "Name",
                              value: "shouldntLoadSuggestions",
                            },
                          },
                        },
                      ],
                    },
                  ],
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "FragmentSpread",
                        name: { kind: "Name", value: "MarketplaceSuggestions" },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "ListingForBuyerShippingFields" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Listing" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "shipping" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "freeExpeditedShipping" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "localPickupOnly" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "shippingPrices" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "shippingMethod" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "carrierCalculated" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "regional" },
                      },
                      {
                        kind: "Field",
                        name: {
                          kind: "Name",
                          value: "destinationPostalCodeNeeded",
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "postalCode" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "rate" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "amount" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "amountCents" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "currency" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "display" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "ListingGreatValueData" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Listing" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "pricing" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "buyerPrice" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "currency" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "amountCents" },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "condition" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "conditionSlug" },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "priceRecommendation" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "priceMiddle" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "amountCents" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "currency" },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "shop" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "address" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "country" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "_id" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "countryCode" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          { kind: "Field", name: { kind: "Name", value: "currency" } },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "ListingCardCPOData" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Listing" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "certifiedPreOwned" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "title" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "badgeIconUrl" },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "ListingForBuyerFields" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Listing" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "title" } },
          { kind: "Field", name: { kind: "Name", value: "slug" } },
          { kind: "Field", name: { kind: "Name", value: "listingType" } },
          { kind: "Field", name: { kind: "Name", value: "make" } },
          { kind: "Field", name: { kind: "Name", value: "model" } },
          { kind: "Field", name: { kind: "Name", value: "upc" } },
          { kind: "Field", name: { kind: "Name", value: "state" } },
          { kind: "Field", name: { kind: "Name", value: "stateDescription" } },
          { kind: "Field", name: { kind: "Name", value: "bumped" } },
          { kind: "Field", name: { kind: "Name", value: "watching" } },
          { kind: "Field", name: { kind: "Name", value: "soldAsIs" } },
          { kind: "Field", name: { kind: "Name", value: "usOutlet" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "publishedAt" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "seconds" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "condition" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "displayName" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "conditionSlug" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "conditionUuid" },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "pricing" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "buyerPrice" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "display" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "currency" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "amount" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "amountCents" },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "originalPrice" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "display" },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "ribbon" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "display" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "reason" },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "images" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "transform" },
                      value: {
                        kind: "StringValue",
                        value: "card_square",
                        block: false,
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "count" },
                      value: { kind: "IntValue", value: "3" },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "scope" },
                      value: {
                        kind: "StringValue",
                        value: "photos",
                        block: false,
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "type" },
                      value: {
                        kind: "StringValue",
                        value: "Product",
                        block: false,
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "source" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "shipping" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "shippingPrices" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "shippingMethod" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "carrierCalculated" },
                      },
                      {
                        kind: "Field",
                        name: {
                          kind: "Name",
                          value: "destinationPostalCodeNeeded",
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "rate" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "amount" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "amountCents" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "currency" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "display" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "freeExpeditedShipping" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "localPickupOnly" },
                },
                { kind: "Field", name: { kind: "Name", value: "localPickup" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "shop" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "name" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "returnPolicy" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "usedReturnWindowDays" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "newReturnWindowDays" },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "address" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "locality" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "region" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "country" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "_id" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "countryCode" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "name" },
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "displayLocation" },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "ListingForBuyerShippingFields" },
          },
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "ListingGreatValueData" },
          },
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "ListingCardCPOData" },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "WatchBadgeData" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Listing" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "title" } },
          { kind: "Field", name: { kind: "Name", value: "sellerId" } },
          { kind: "Field", name: { kind: "Name", value: "watching" } },
          {
            kind: "Field",
            alias: { kind: "Name", value: "watchThumbnails" },
            name: { kind: "Name", value: "images" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "type" },
                      value: {
                        kind: "StringValue",
                        value: "Product",
                        block: false,
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "scope" },
                      value: {
                        kind: "StringValue",
                        value: "photos",
                        block: false,
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "transform" },
                      value: {
                        kind: "StringValue",
                        value: "card_square",
                        block: false,
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "count" },
                      value: { kind: "IntValue", value: "1" },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "source" } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "mParticleListingFields" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Listing" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "title" } },
          { kind: "Field", name: { kind: "Name", value: "brandSlug" } },
          { kind: "Field", name: { kind: "Name", value: "categoryRootUuid" } },
          { kind: "Field", name: { kind: "Name", value: "make" } },
          { kind: "Field", name: { kind: "Name", value: "categoryUuids" } },
          { kind: "Field", name: { kind: "Name", value: "state" } },
          { kind: "Field", name: { kind: "Name", value: "listingType" } },
          { kind: "Field", name: { kind: "Name", value: "bumpEligible" } },
          { kind: "Field", name: { kind: "Name", value: "shopId" } },
          { kind: "Field", name: { kind: "Name", value: "inventory" } },
          { kind: "Field", name: { kind: "Name", value: "soldAsIs" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "acceptedPaymentMethods" },
          },
          { kind: "Field", name: { kind: "Name", value: "currency" } },
          { kind: "Field", name: { kind: "Name", value: "usOutlet" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "condition" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "conditionUuid" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "conditionSlug" },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "categories" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "slug" } },
                { kind: "Field", name: { kind: "Name", value: "rootSlug" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "csp" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "slug" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "brand" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      { kind: "Field", name: { kind: "Name", value: "slug" } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "pricing" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "buyerPrice" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "amount" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "currency" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "amountCents" },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "publishedAt" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "seconds" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "sale" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "code" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "buyerIneligibilityReason" },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "shipping" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "shippingPrices" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "shippingMethod" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "carrierCalculated" },
                      },
                      {
                        kind: "Field",
                        name: {
                          kind: "Name",
                          value: "destinationPostalCodeNeeded",
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "rate" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "amount" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "amountCents" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "currency" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "display" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "freeExpeditedShipping" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "localPickupOnly" },
                },
                { kind: "Field", name: { kind: "Name", value: "localPickup" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "certifiedPreOwned" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "title" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "badgeIconUrl" },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "shop" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "address" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "countryCode" },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "returnPolicy" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "newReturnWindowDays" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "usedReturnWindowDays" },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "ListingCreateOfferButtonData" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Listing" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "state" } },
          { kind: "Field", name: { kind: "Name", value: "offersEnabled" } },
          { kind: "Field", name: { kind: "Name", value: "listingType" } },
          { kind: "Field", name: { kind: "Name", value: "sellerId" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "isBuyerOfferEligible" },
          },
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "mParticleListingFields" },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "AddToCartButtonFields" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Listing" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "sellerId" } },
          { kind: "Field", name: { kind: "Name", value: "listingType" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "pricing" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "buyerPrice" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "amount" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "amountCents" },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "preorderInfo" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "onPreorder" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "estimatedShipDate" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "seconds" },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "ListingCardFields" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Listing" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "ListingForBuyerFields" },
          },
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "WatchBadgeData" },
          },
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "ListingCreateOfferButtonData" },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "InOtherCartsCardData" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Listing" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "otherBuyersWithListingInCartCounts" },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "BumpKey" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Listing" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "bumpKey" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "key" } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "ShopFields" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Listing" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "shop" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "address" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "locality" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "countryCode" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "region" },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "ListViewListings" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Listing" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "categoryUuids" } },
          { kind: "Field", name: { kind: "Name", value: "state" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "shop" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "name" } },
                { kind: "Field", name: { kind: "Name", value: "slug" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "preferredSeller" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "quickShipper" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "quickResponder" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "address" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "locality" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "region" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "displayLocation" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "country" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "_id" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "countryCode" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "name" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "seller" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "feedbackSummary" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "receivedCount" },
                      },
                      {
                        kind: "Field",
                        name: {
                          kind: "Name",
                          value: "rollingRatingPercentage",
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "csp" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "webLink" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "href" } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "AddToCartButtonFields" },
          },
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "ListingCardFields" },
          },
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "ListingCreateOfferButtonData" },
          },
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "InOtherCartsCardData" },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "ListingCardSignalsData" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "reverb_signals_Signal" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "name" } },
          { kind: "Field", name: { kind: "Name", value: "title" } },
          { kind: "Field", name: { kind: "Name", value: "icon" } },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "NestedFilter" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "reverb_search_Filter" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "name" } },
          { kind: "Field", name: { kind: "Name", value: "key" } },
          { kind: "Field", name: { kind: "Name", value: "aggregationName" } },
          { kind: "Field", name: { kind: "Name", value: "widgetType" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "options" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "count" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "value" } },
                    ],
                  },
                },
                { kind: "Field", name: { kind: "Name", value: "name" } },
                { kind: "Field", name: { kind: "Name", value: "selected" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "autoselected" },
                },
                { kind: "Field", name: { kind: "Name", value: "paramName" } },
                { kind: "Field", name: { kind: "Name", value: "setValues" } },
                { kind: "Field", name: { kind: "Name", value: "unsetValues" } },
                { kind: "Field", name: { kind: "Name", value: "all" } },
                { kind: "Field", name: { kind: "Name", value: "optionValue" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "trackingValue" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "subFilter" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "widgetType" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "options" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "count" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "value" },
                                  },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "name" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "selected" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "autoselected" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "paramName" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "setValues" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "unsetValues" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "all" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "optionValue" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "trackingValue" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "subFilter" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "widgetType" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "options" },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "count",
                                          },
                                          selectionSet: {
                                            kind: "SelectionSet",
                                            selections: [
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "value",
                                                },
                                              },
                                            ],
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "name" },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "selected",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "autoselected",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "paramName",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "setValues",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "unsetValues",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "all" },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "optionValue",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "trackingValue",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "subFilter",
                                          },
                                          selectionSet: {
                                            kind: "SelectionSet",
                                            selections: [
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "widgetType",
                                                },
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "options",
                                                },
                                                selectionSet: {
                                                  kind: "SelectionSet",
                                                  selections: [
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "count",
                                                      },
                                                      selectionSet: {
                                                        kind: "SelectionSet",
                                                        selections: [
                                                          {
                                                            kind: "Field",
                                                            name: {
                                                              kind: "Name",
                                                              value: "value",
                                                            },
                                                          },
                                                        ],
                                                      },
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "name",
                                                      },
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "selected",
                                                      },
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "autoselected",
                                                      },
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "paramName",
                                                      },
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "setValues",
                                                      },
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "unsetValues",
                                                      },
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "all",
                                                      },
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "optionValue",
                                                      },
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "trackingValue",
                                                      },
                                                    },
                                                  ],
                                                },
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "MarketplaceSuggestions" },
      typeCondition: {
        kind: "NamedType",
        name: {
          kind: "Name",
          value: "reverb_search_SearchResponse_Suggestion",
        },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [{ kind: "Field", name: { kind: "Name", value: "text" } }],
      },
    },
  ],
} as unknown as DocumentNode<
  Core_Marketplace_CombinedMarketplaceSearchQuery,
  Core_Marketplace_CombinedMarketplaceSearchQueryVariables
>;
export const Core_Marketplace_MarketplaceListingsCountDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: {
        kind: "Name",
        value: "Core_Marketplace_MarketplaceListingsCount",
      },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "inputListings" },
          },
          type: {
            kind: "NamedType",
            name: {
              kind: "Name",
              value: "Input_reverb_search_ListingsSearchRequest",
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "listingsSearch" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "inputListings" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "total" } },
                { kind: "Field", name: { kind: "Name", value: "__typename" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Core_Marketplace_MarketplaceListingsCountQuery,
  Core_Marketplace_MarketplaceListingsCountQueryVariables
>;
export const Core_Shop_MarketplaceDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "Core_Shop_Marketplace" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "shop" } },
          type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "shop" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "slug" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "shop" },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "name" } },
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "userId" } },
                { kind: "Field", name: { kind: "Name", value: "onVacation" } },
                { kind: "Field", name: { kind: "Name", value: "showSold" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Core_Shop_MarketplaceQuery,
  Core_Shop_MarketplaceQueryVariables
>;
export const Core_Listing_OutletPageGridDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "Core_Listing_OutletPageGrid" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "autodirects" },
          },
          type: {
            kind: "NamedType",
            name: { kind: "Name", value: "reverb_search_Autodirects" },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "acceptsAffirm" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "Boolean" } },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "acceptsOffers" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "Boolean" } },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "acceptsPaymentPlans" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "Boolean" } },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "brandSlugs" },
          },
          type: {
            kind: "ListType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "categorySlugs" },
          },
          type: {
            kind: "ListType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "combinedShipping" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "Boolean" } },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "conditionSlugs" },
          },
          type: {
            kind: "ListType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "contexts" },
          },
          type: {
            kind: "ListType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "reverb_search_Context" },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "cspSlug" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "countryOfOrigin" },
          },
          type: {
            kind: "ListType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "curatedSetSlugs" },
          },
          type: {
            kind: "ListType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "currency" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "dealsAndSteals" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "Boolean" } },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "decades" },
          },
          type: {
            kind: "ListType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "fallbackToOr" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "Boolean" } },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "freeShipping" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "Boolean" } },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "freeExpeditedShipping" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "Boolean" } },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "handmade" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "Boolean" } },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "holidaySale" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "Boolean" } },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "itemCity" },
          },
          type: {
            kind: "ListType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "itemRegion" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "itemState" },
          },
          type: {
            kind: "ListType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "limit" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "Int" } },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "localPickup" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "Boolean" } },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "minSaleDiscountPercent" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "multiClientExperiments" },
          },
          type: {
            kind: "ListType",
            type: {
              kind: "NamedType",
              name: {
                kind: "Name",
                value: "Input_reverb_search_ExperimentData",
              },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "preferredSeller" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "Boolean" } },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "priceMax" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "priceMin" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "offset" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "Int" } },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "onSale" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "Boolean" } },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "query" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "saleSlugs" },
          },
          type: {
            kind: "ListType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "shippingRegionCode" },
          },
          type: {
            kind: "ListType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "showOnlySold" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "Boolean" } },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "shopSlug" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "showSold" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "Boolean" } },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "similarListingIds" },
          },
          type: {
            kind: "ListType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "sortSlug" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "terminateEarly" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "Boolean" } },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "traitValues" },
          },
          type: {
            kind: "ListType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "useExperimentalRecall" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "Boolean" } },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "withAggregations" },
          },
          type: {
            kind: "ListType",
            type: {
              kind: "NamedType",
              name: {
                kind: "Name",
                value: "reverb_search_ListingsSearchRequest_Aggregation",
              },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "withProximityFilter" },
          },
          type: {
            kind: "NamedType",
            name: {
              kind: "Name",
              value: "Input_reverb_search_ProximityFilterRequest",
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "yearMax" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "yearMin" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "outlet" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "Boolean" } },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "showMostWatchedListingsSort" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "Boolean" } },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "listingsSearch" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "autodirects" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "autodirects" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "acceptsAffirm" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "acceptsAffirm" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "acceptsOffers" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "acceptsOffers" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "acceptsPaymentPlans" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "acceptsPaymentPlans" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "brandSlugs" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "brandSlugs" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "categorySlugs" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "categorySlugs" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "combinedShipping" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "combinedShipping" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "conditionSlugs" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "conditionSlugs" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "contexts" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "contexts" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "cspSlug" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "cspSlug" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "countryOfOrigin" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "countryOfOrigin" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "curatedSetSlugs" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "curatedSetSlugs" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "currency" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "currency" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "dealsAndSteals" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "dealsAndSteals" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "decades" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "decades" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "fallbackToOr" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "fallbackToOr" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "freeShipping" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "freeShipping" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "freeExpeditedShipping" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "freeExpeditedShipping" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "handmade" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "handmade" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "holidaySale" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "holidaySale" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "itemCity" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "itemCity" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "itemRegion" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "itemRegion" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "itemState" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "itemState" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "limit" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "limit" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "localPickup" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "localPickup" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "minSaleDiscountPercent" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "minSaleDiscountPercent" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "multiClientExperiments" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "multiClientExperiments" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "preferredSeller" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "preferredSeller" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "priceMin" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "priceMin" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "priceMax" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "priceMax" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "offset" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "offset" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "onSale" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "onSale" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "query" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "query" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "saleSlugs" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "saleSlugs" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "shippingRegionCodes" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "shippingRegionCode" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "showOnlySold" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "showOnlySold" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "shopSlug" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "shopSlug" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "showSold" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "showSold" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "similarListingIds" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "similarListingIds" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "sortSlug" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "sortSlug" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "terminateEarly" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "terminateEarly" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "traitValues" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "traitValues" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "useExperimentalRecall" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "useExperimentalRecall" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "withAggregations" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "withAggregations" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "withProximityFilter" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "withProximityFilter" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "yearMax" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "yearMax" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "yearMin" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "yearMin" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "outlet" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "outlet" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: {
                        kind: "Name",
                        value: "showMostWatchedListingsSort",
                      },
                      value: {
                        kind: "Variable",
                        name: {
                          kind: "Name",
                          value: "showMostWatchedListingsSort",
                        },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "listings" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      {
                        kind: "FragmentSpread",
                        name: { kind: "Name", value: "OutletListingsFields" },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "filters" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "FragmentSpread",
                        name: { kind: "Name", value: "NestedFilter" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "options" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "selected" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "autoselected" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                { kind: "Field", name: { kind: "Name", value: "total" } },
                { kind: "Field", name: { kind: "Name", value: "offset" } },
                { kind: "Field", name: { kind: "Name", value: "limit" } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "ListingForBuyerShippingFields" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Listing" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "shipping" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "freeExpeditedShipping" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "localPickupOnly" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "shippingPrices" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "shippingMethod" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "carrierCalculated" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "regional" },
                      },
                      {
                        kind: "Field",
                        name: {
                          kind: "Name",
                          value: "destinationPostalCodeNeeded",
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "postalCode" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "rate" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "amount" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "amountCents" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "currency" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "display" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "ListingGreatValueData" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Listing" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "pricing" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "buyerPrice" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "currency" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "amountCents" },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "condition" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "conditionSlug" },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "priceRecommendation" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "priceMiddle" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "amountCents" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "currency" },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "shop" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "address" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "country" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "_id" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "countryCode" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          { kind: "Field", name: { kind: "Name", value: "currency" } },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "ListingCardCPOData" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Listing" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "certifiedPreOwned" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "title" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "badgeIconUrl" },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "ListingForBuyerFields" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Listing" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "title" } },
          { kind: "Field", name: { kind: "Name", value: "slug" } },
          { kind: "Field", name: { kind: "Name", value: "listingType" } },
          { kind: "Field", name: { kind: "Name", value: "make" } },
          { kind: "Field", name: { kind: "Name", value: "model" } },
          { kind: "Field", name: { kind: "Name", value: "upc" } },
          { kind: "Field", name: { kind: "Name", value: "state" } },
          { kind: "Field", name: { kind: "Name", value: "stateDescription" } },
          { kind: "Field", name: { kind: "Name", value: "bumped" } },
          { kind: "Field", name: { kind: "Name", value: "watching" } },
          { kind: "Field", name: { kind: "Name", value: "soldAsIs" } },
          { kind: "Field", name: { kind: "Name", value: "usOutlet" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "publishedAt" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "seconds" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "condition" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "displayName" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "conditionSlug" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "conditionUuid" },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "pricing" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "buyerPrice" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "display" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "currency" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "amount" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "amountCents" },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "originalPrice" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "display" },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "ribbon" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "display" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "reason" },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "images" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "transform" },
                      value: {
                        kind: "StringValue",
                        value: "card_square",
                        block: false,
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "count" },
                      value: { kind: "IntValue", value: "3" },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "scope" },
                      value: {
                        kind: "StringValue",
                        value: "photos",
                        block: false,
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "type" },
                      value: {
                        kind: "StringValue",
                        value: "Product",
                        block: false,
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "source" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "shipping" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "shippingPrices" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "shippingMethod" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "carrierCalculated" },
                      },
                      {
                        kind: "Field",
                        name: {
                          kind: "Name",
                          value: "destinationPostalCodeNeeded",
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "rate" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "amount" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "amountCents" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "currency" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "display" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "freeExpeditedShipping" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "localPickupOnly" },
                },
                { kind: "Field", name: { kind: "Name", value: "localPickup" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "shop" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "name" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "returnPolicy" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "usedReturnWindowDays" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "newReturnWindowDays" },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "address" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "locality" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "region" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "country" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "_id" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "countryCode" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "name" },
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "displayLocation" },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "ListingForBuyerShippingFields" },
          },
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "ListingGreatValueData" },
          },
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "ListingCardCPOData" },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "WatchBadgeData" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Listing" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "title" } },
          { kind: "Field", name: { kind: "Name", value: "sellerId" } },
          { kind: "Field", name: { kind: "Name", value: "watching" } },
          {
            kind: "Field",
            alias: { kind: "Name", value: "watchThumbnails" },
            name: { kind: "Name", value: "images" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "type" },
                      value: {
                        kind: "StringValue",
                        value: "Product",
                        block: false,
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "scope" },
                      value: {
                        kind: "StringValue",
                        value: "photos",
                        block: false,
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "transform" },
                      value: {
                        kind: "StringValue",
                        value: "card_square",
                        block: false,
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "count" },
                      value: { kind: "IntValue", value: "1" },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "source" } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "mParticleListingFields" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Listing" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "title" } },
          { kind: "Field", name: { kind: "Name", value: "brandSlug" } },
          { kind: "Field", name: { kind: "Name", value: "categoryRootUuid" } },
          { kind: "Field", name: { kind: "Name", value: "make" } },
          { kind: "Field", name: { kind: "Name", value: "categoryUuids" } },
          { kind: "Field", name: { kind: "Name", value: "state" } },
          { kind: "Field", name: { kind: "Name", value: "listingType" } },
          { kind: "Field", name: { kind: "Name", value: "bumpEligible" } },
          { kind: "Field", name: { kind: "Name", value: "shopId" } },
          { kind: "Field", name: { kind: "Name", value: "inventory" } },
          { kind: "Field", name: { kind: "Name", value: "soldAsIs" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "acceptedPaymentMethods" },
          },
          { kind: "Field", name: { kind: "Name", value: "currency" } },
          { kind: "Field", name: { kind: "Name", value: "usOutlet" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "condition" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "conditionUuid" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "conditionSlug" },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "categories" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "slug" } },
                { kind: "Field", name: { kind: "Name", value: "rootSlug" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "csp" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "slug" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "brand" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      { kind: "Field", name: { kind: "Name", value: "slug" } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "pricing" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "buyerPrice" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "amount" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "currency" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "amountCents" },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "publishedAt" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "seconds" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "sale" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "code" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "buyerIneligibilityReason" },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "shipping" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "shippingPrices" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "shippingMethod" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "carrierCalculated" },
                      },
                      {
                        kind: "Field",
                        name: {
                          kind: "Name",
                          value: "destinationPostalCodeNeeded",
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "rate" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "amount" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "amountCents" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "currency" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "display" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "freeExpeditedShipping" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "localPickupOnly" },
                },
                { kind: "Field", name: { kind: "Name", value: "localPickup" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "certifiedPreOwned" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "title" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "badgeIconUrl" },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "shop" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "address" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "countryCode" },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "returnPolicy" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "newReturnWindowDays" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "usedReturnWindowDays" },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "ListingCreateOfferButtonData" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Listing" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "state" } },
          { kind: "Field", name: { kind: "Name", value: "offersEnabled" } },
          { kind: "Field", name: { kind: "Name", value: "listingType" } },
          { kind: "Field", name: { kind: "Name", value: "sellerId" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "isBuyerOfferEligible" },
          },
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "mParticleListingFields" },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "ListingCardFields" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Listing" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "ListingForBuyerFields" },
          },
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "WatchBadgeData" },
          },
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "ListingCreateOfferButtonData" },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "AddToCartButtonFields" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Listing" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "sellerId" } },
          { kind: "Field", name: { kind: "Name", value: "listingType" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "pricing" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "buyerPrice" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "amount" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "amountCents" },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "preorderInfo" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "onPreorder" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "estimatedShipDate" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "seconds" },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "OutletListingsFields" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Listing" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "ListingCardFields" },
          },
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "AddToCartButtonFields" },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "NestedFilter" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "reverb_search_Filter" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "name" } },
          { kind: "Field", name: { kind: "Name", value: "key" } },
          { kind: "Field", name: { kind: "Name", value: "aggregationName" } },
          { kind: "Field", name: { kind: "Name", value: "widgetType" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "options" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "count" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "value" } },
                    ],
                  },
                },
                { kind: "Field", name: { kind: "Name", value: "name" } },
                { kind: "Field", name: { kind: "Name", value: "selected" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "autoselected" },
                },
                { kind: "Field", name: { kind: "Name", value: "paramName" } },
                { kind: "Field", name: { kind: "Name", value: "setValues" } },
                { kind: "Field", name: { kind: "Name", value: "unsetValues" } },
                { kind: "Field", name: { kind: "Name", value: "all" } },
                { kind: "Field", name: { kind: "Name", value: "optionValue" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "trackingValue" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "subFilter" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "widgetType" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "options" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "count" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "value" },
                                  },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "name" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "selected" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "autoselected" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "paramName" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "setValues" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "unsetValues" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "all" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "optionValue" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "trackingValue" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "subFilter" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "widgetType" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "options" },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "count",
                                          },
                                          selectionSet: {
                                            kind: "SelectionSet",
                                            selections: [
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "value",
                                                },
                                              },
                                            ],
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "name" },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "selected",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "autoselected",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "paramName",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "setValues",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "unsetValues",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "all" },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "optionValue",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "trackingValue",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "subFilter",
                                          },
                                          selectionSet: {
                                            kind: "SelectionSet",
                                            selections: [
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "widgetType",
                                                },
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "options",
                                                },
                                                selectionSet: {
                                                  kind: "SelectionSet",
                                                  selections: [
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "count",
                                                      },
                                                      selectionSet: {
                                                        kind: "SelectionSet",
                                                        selections: [
                                                          {
                                                            kind: "Field",
                                                            name: {
                                                              kind: "Name",
                                                              value: "value",
                                                            },
                                                          },
                                                        ],
                                                      },
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "name",
                                                      },
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "selected",
                                                      },
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "autoselected",
                                                      },
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "paramName",
                                                      },
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "setValues",
                                                      },
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "unsetValues",
                                                      },
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "all",
                                                      },
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "optionValue",
                                                      },
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "trackingValue",
                                                      },
                                                    },
                                                  ],
                                                },
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Core_Listing_OutletPageGridQuery,
  Core_Listing_OutletPageGridQueryVariables
>;
export const Core_Packlink_AccountStatusDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "Core_Packlink_AccountStatus" },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "me" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "uuid" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "shop" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "packlink" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "account" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "email" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "status" },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Core_Packlink_AccountStatusQuery,
  Core_Packlink_AccountStatusQueryVariables
>;
export const Core_Packlink_ShippingLabelButtonDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "Core_Packlink_ShippingLabelButton" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "orderId" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "authOnly" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "Boolean" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "order" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "id" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "orderId" },
                      },
                    },
                  ],
                },
              },
            ],
            directives: [
              {
                kind: "Directive",
                name: { kind: "Name", value: "skip" },
                arguments: [
                  {
                    kind: "Argument",
                    name: { kind: "Name", value: "if" },
                    value: {
                      kind: "Variable",
                      name: { kind: "Name", value: "authOnly" },
                    },
                  },
                ],
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                {
                  kind: "FragmentSpread",
                  name: { kind: "Name", value: "OrderPacklinkShipmentData" },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "me" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                {
                  kind: "FragmentSpread",
                  name: { kind: "Name", value: "MyShopPacklinkAccountData" },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "OrderPacklinkShipmentData" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Order" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "legacyOrderId" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "packlinkShipment" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "reference" } },
                { kind: "Field", name: { kind: "Name", value: "status" } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "MyShopPacklinkAccountData" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "rql_Me" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "uuid" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "shop" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "packlink" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "account" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "status" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Core_Packlink_ShippingLabelButtonQuery,
  Core_Packlink_ShippingLabelButtonQueryVariables
>;
export const Core_Packlink_CreateDraftDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "Core_Packlink_CreateDraft" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "input" },
          },
          type: {
            kind: "NamedType",
            name: {
              kind: "Name",
              value: "Input_core_apimessages_CreatePacklinkDraftRequest",
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "createPacklinkDraft" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "input" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "shipment" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Core_Packlink_CreateDraftMutation,
  Core_Packlink_CreateDraftMutationVariables
>;
export const Core_Packlink_LoginAccountDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "Core_Packlink_LoginAccount" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "input" },
          },
          type: {
            kind: "NamedType",
            name: {
              kind: "Name",
              value: "Input_core_apimessages_LoginMyPacklinkAccountRequest",
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "loginMyPacklinkAccount" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "input" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "shopId" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Core_Packlink_LoginAccountMutation,
  Core_Packlink_LoginAccountMutationVariables
>;
export const Core_Packlink_RegisterAccountDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "Core_Packlink_RegisterAccount" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "input" },
          },
          type: {
            kind: "NamedType",
            name: {
              kind: "Name",
              value: "Input_core_apimessages_RegisterMyPacklinkAccountRequest",
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "registerMyPacklinkAccount" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "input" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "shopId" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Core_Packlink_RegisterAccountMutation,
  Core_Packlink_RegisterAccountMutationVariables
>;
export const PayStatementCountriesDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "PayStatementCountries" },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "me" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "uuid" } },
                {
                  kind: "FragmentSpread",
                  name: { kind: "Name", value: "myShopCountry" },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "countries" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "countries" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "countryCode" },
                      },
                      { kind: "Field", name: { kind: "Name", value: "name" } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "myShopCountry" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "rql_Me" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "uuid" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "shop" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "originCountryCode" },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  PayStatementCountriesQuery,
  PayStatementCountriesQueryVariables
>;
export const CreditCardListQueryDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "CreditCardListQuery" },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "me" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "uuid" } },
                {
                  kind: "FragmentSpread",
                  name: {
                    kind: "Name",
                    value: "PayStatementCreditCardListFields",
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "PayStatementCreditCardListFields" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "rql_Me" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "creditCards" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "cardType" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "needsReverification" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "primaryBilling" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "display" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "summary" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "expirationDate" },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "brand" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "type" } },
                      { kind: "Field", name: { kind: "Name", value: "name" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "__typename" },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CreditCardListQueryQuery,
  CreditCardListQueryQueryVariables
>;
export const PayMyStatementDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "PayMyStatement" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "input" },
          },
          type: {
            kind: "NamedType",
            name: {
              kind: "Name",
              value: "Input_core_apimessages_PayMyStatementRequest",
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "payMyStatement" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "input" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "statementId" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "statementBalance" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "amountPaidCents" },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  PayMyStatementMutation,
  PayMyStatementMutationVariables
>;
export const Core_Payouts_CreateMyPlaidLinkTokenDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "Core_Payouts_CreateMyPlaidLinkToken" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "input" },
          },
          type: {
            kind: "NamedType",
            name: {
              kind: "Name",
              value: "Input_core_apimessages_CreateMyPlaidLinkTokenRequest",
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "createMyPlaidLinkToken" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "input" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "linkToken" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Core_Payouts_CreateMyPlaidLinkTokenMutation,
  Core_Payouts_CreateMyPlaidLinkTokenMutationVariables
>;
export const Core_Payouts_SaveMyPlaidAccountDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "Core_Payouts_SaveMyPlaidAccount" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "input" },
          },
          type: {
            kind: "NamedType",
            name: {
              kind: "Name",
              value: "Input_core_apimessages_SaveMyPlaidAccountRequest",
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "saveMyPlaidAccount" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "input" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "plaidAccountId" },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Core_Payouts_SaveMyPlaidAccountMutation,
  Core_Payouts_SaveMyPlaidAccountMutationVariables
>;
export const Core_Payouts_CancelPlaidAccountDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "Core_Payouts_CancelPlaidAccount" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "input" },
          },
          type: {
            kind: "NamedType",
            name: {
              kind: "Name",
              value: "Input_core_apimessages_CancelPlaidAccountRequest",
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "cancelPlaidAccount" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "input" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "shopId" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Core_Payouts_CancelPlaidAccountMutation,
  Core_Payouts_CancelPlaidAccountMutationVariables
>;
export const DataServices_PriceGuide_CspEstimatesDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "DataServices_PriceGuide_CspEstimates" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "cspPriceRecommendationQueries" },
          },
          type: {
            kind: "ListType",
            type: {
              kind: "NamedType",
              name: {
                kind: "Name",
                value: "Input_reverb_pricing_CSPPriceRecommendationQuery",
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "cspPriceRecommendations" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: {
                        kind: "Name",
                        value: "cspPriceRecommendationQueries",
                      },
                      value: {
                        kind: "Variable",
                        name: {
                          kind: "Name",
                          value: "cspPriceRecommendationQueries",
                        },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "priceRecommendations" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "conditionUuid" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "priceLow" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "amountCents" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "currency" },
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "priceMiddle" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "amountCents" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "currency" },
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "priceHigh" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "amountCents" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "currency" },
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "priceLowThirtyDaysAgo" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "amountCents" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "currency" },
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: {
                          kind: "Name",
                          value: "priceMiddleThirtyDaysAgo",
                        },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "amountCents" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "currency" },
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "priceHighThirtyDaysAgo" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "amountCents" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "currency" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  DataServices_PriceGuide_CspEstimatesQuery,
  DataServices_PriceGuide_CspEstimatesQueryVariables
>;
export const DataServices_PriceGuide_CpEstimatesDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "DataServices_PriceGuide_CpEstimates" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "priceRecommendationQueries" },
          },
          type: {
            kind: "ListType",
            type: {
              kind: "NamedType",
              name: {
                kind: "Name",
                value: "Input_reverb_pricing_PriceRecommendationQuery",
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "priceRecommendations" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: {
                        kind: "Name",
                        value: "priceRecommendationQueries",
                      },
                      value: {
                        kind: "Variable",
                        name: {
                          kind: "Name",
                          value: "priceRecommendationQueries",
                        },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "priceRecommendations" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "conditionUuid" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "priceLow" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "amountCents" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "currency" },
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "priceMiddle" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "amountCents" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "currency" },
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "priceHigh" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "amountCents" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "currency" },
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "priceLowThirtyDaysAgo" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "amountCents" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "currency" },
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: {
                          kind: "Name",
                          value: "priceMiddleThirtyDaysAgo",
                        },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "amountCents" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "currency" },
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "priceHighThirtyDaysAgo" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "amountCents" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "currency" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  DataServices_PriceGuide_CpEstimatesQuery,
  DataServices_PriceGuide_CpEstimatesQueryVariables
>;
export const Search_PriceGuide_TotalOrdersDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "Search_PriceGuide_TotalOrders" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "canonicalProductIds" },
          },
          type: {
            kind: "ListType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "sellerCountries" },
          },
          type: {
            kind: "ListType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "conditionSlugs" },
          },
          type: {
            kind: "ListType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "actionableStatuses" },
          },
          type: {
            kind: "ListType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "limit" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "Int" } },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "offset" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "Int" } },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "priceRecordsSearch" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "canonicalProductIds" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "canonicalProductIds" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "sellerCountries" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "sellerCountries" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "listingConditionSlugs" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "conditionSlugs" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "actionableStatuses" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "actionableStatuses" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "limit" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "limit" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "offset" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "offset" },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "total" } },
                { kind: "Field", name: { kind: "Name", value: "offset" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Search_PriceGuide_TotalOrdersQuery,
  Search_PriceGuide_TotalOrdersQueryVariables
>;
export const Search_PriceGuideTool_TransactionTableDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "Search_PriceGuideTool_TransactionTable" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "canonicalProductIds" },
          },
          type: {
            kind: "ListType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "sellerCountries" },
          },
          type: {
            kind: "ListType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "conditionSlugs" },
          },
          type: {
            kind: "ListType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "createdAfterDate" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "actionableStatuses" },
          },
          type: {
            kind: "ListType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "limit" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "Int" } },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "offset" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "Int" } },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "priceRecordsSearch" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "canonicalProductIds" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "canonicalProductIds" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "sellerCountries" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "sellerCountries" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "listingConditionSlugs" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "conditionSlugs" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "createdAfterDate" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "createdAfterDate" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "actionableStatuses" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "actionableStatuses" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "limit" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "limit" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "offset" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "offset" },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "priceRecords" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      {
                        kind: "FragmentSpread",
                        name: {
                          kind: "Name",
                          value: "TransactionTablePriceRecordsData",
                        },
                      },
                    ],
                  },
                },
                { kind: "Field", name: { kind: "Name", value: "total" } },
                { kind: "Field", name: { kind: "Name", value: "offset" } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "TransactionTablePriceRecordsData" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "PublicPriceRecord" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "condition" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "displayName" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "createdAt" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "seconds" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "amountProduct" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "display" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Search_PriceGuideTool_TransactionTableQuery,
  Search_PriceGuideTool_TransactionTableQueryVariables
>;
export const DataServices_PriceGuideToolEstimatesContainerDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: {
        kind: "Name",
        value: "DataServices_PriceGuideToolEstimatesContainer",
      },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "priceRecommendationQueries" },
          },
          type: {
            kind: "ListType",
            type: {
              kind: "NamedType",
              name: {
                kind: "Name",
                value: "Input_reverb_pricing_PriceRecommendationQuery",
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "priceRecommendations" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: {
                        kind: "Name",
                        value: "priceRecommendationQueries",
                      },
                      value: {
                        kind: "Variable",
                        name: {
                          kind: "Name",
                          value: "priceRecommendationQueries",
                        },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "priceRecommendations" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "priceLow" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "amountCents" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "currency" },
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "priceMiddle" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "amountCents" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "currency" },
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "priceHigh" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "amountCents" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "currency" },
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: {
                          kind: "Name",
                          value: "priceMiddleThirtyDaysAgo",
                        },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "amountCents" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "currency" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  DataServices_PriceGuideToolEstimatesContainerQuery,
  DataServices_PriceGuideToolEstimatesContainerQueryVariables
>;
export const Core_PriceGuideToolFormContainerDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "Core_PriceGuideToolFormContainer" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "cspSlug" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "csp" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "slug" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "cspSlug" },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                {
                  kind: "FragmentSpread",
                  name: {
                    kind: "Name",
                    value: "PriceGuideToolFormContainerData",
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "PriceGuideToolFormContainerData" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "CSP" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "title" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "brand" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "name" } },
              ],
            },
          },
          { kind: "Field", name: { kind: "Name", value: "categoryRootUuid" } },
          { kind: "Field", name: { kind: "Name", value: "categoryUuids" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "image" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "transform" },
                      value: {
                        kind: "StringValue",
                        value: "card_square",
                        block: false,
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "source" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "canonicalProducts" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "finish" } },
                { kind: "Field", name: { kind: "Name", value: "model" } },
                { kind: "Field", name: { kind: "Name", value: "name" } },
                { kind: "Field", name: { kind: "Name", value: "year" } },
              ],
            },
          },
          { kind: "Field", name: { kind: "Name", value: "slug" } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Core_PriceGuideToolFormContainerQuery,
  Core_PriceGuideToolFormContainerQueryVariables
>;
export const Search_PriceGuideTool_GearCollectionItemsDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: {
        kind: "Name",
        value: "Search_PriceGuideTool_GearCollectionItems",
      },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "canonicalProductIds" },
          },
          type: {
            kind: "ListType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "gearCollectionItemsSearch" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "as" },
                      value: { kind: "EnumValue", value: "OWNER" },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "searchInput" },
                      value: {
                        kind: "ObjectValue",
                        fields: [
                          {
                            kind: "ObjectField",
                            name: {
                              kind: "Name",
                              value: "canonicalProductIds",
                            },
                            value: {
                              kind: "Variable",
                              name: {
                                kind: "Name",
                                value: "canonicalProductIds",
                              },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "gearCollectionItems" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Search_PriceGuideTool_GearCollectionItemsQuery,
  Search_PriceGuideTool_GearCollectionItemsQueryVariables
>;
export const Search_PriceGuideToolTransactionGraphDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "Search_PriceGuideToolTransactionGraph" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "canonicalProductIds" },
          },
          type: {
            kind: "ListType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "sellerCountries" },
          },
          type: {
            kind: "ListType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "conditionUuids" },
          },
          type: {
            kind: "ListType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "createdAfterDate" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "actionableStatuses" },
          },
          type: {
            kind: "ListType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "priceRecordsSearch" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "canonicalProductIds" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "canonicalProductIds" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "sellerCountries" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "sellerCountries" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "listingConditionUuids" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "conditionUuids" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "createdAfterDate" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "createdAfterDate" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "actionableStatuses" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "actionableStatuses" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: {
                        kind: "Name",
                        value: "withAverageMonthlyProductPricesAggregations",
                      },
                      value: { kind: "BooleanValue", value: true },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "averageMonthlyProductPrices" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "date" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "docCount" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "averageProductPrice" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "amount" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "amountCents" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "currency" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "display" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Search_PriceGuideToolTransactionGraphQuery,
  Search_PriceGuideToolTransactionGraphQueryVariables
>;
export const Core_ProductFamilyPage_LayoutDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "Core_ProductFamilyPage_Layout" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "query" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "traitSlugs" },
          },
          type: {
            kind: "ListType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "listingsRowLimit" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "Int" } },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "listingsRowCuratedSetSlugs" },
          },
          type: {
            kind: "ListType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "listingsGridLimit" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "Int" } },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "listingsGridOffset" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "Int" } },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "currency" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "priceMax" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "priceMin" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "yearMax" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "yearMin" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "sortSlug" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "categorySlugs" },
          },
          type: {
            kind: "ListType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "brandSlugs" },
          },
          type: {
            kind: "ListType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "aggs" } },
          type: {
            kind: "ListType",
            type: {
              kind: "NamedType",
              name: {
                kind: "Name",
                value: "reverb_search_ListingsSearchRequest_Aggregation",
              },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "conditionSlugs" },
          },
          type: {
            kind: "ListType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "onSale" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "Boolean" } },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "handmade" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "Boolean" } },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "freeShipping" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "Boolean" } },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "freeExpeditedShipping" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "Boolean" } },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "acceptsOffers" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "Boolean" } },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "acceptsGiftCards" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "Boolean" } },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "preferredSeller" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "Boolean" } },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "acceptsPaymentPlans" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "Boolean" } },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "itemRegion" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "shippingRegionCodes" },
          },
          type: {
            kind: "ListType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "decades" },
          },
          type: {
            kind: "ListType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "cspSort" },
          },
          type: {
            kind: "NamedType",
            name: {
              kind: "Name",
              value: "reverb_search_CSPSearchRequest_Sort",
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "localPickup" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "Boolean" } },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "combinedShipping" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "Boolean" } },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "acceptsAffirm" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "Boolean" } },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "showOnlySold" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "Boolean" } },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "withProximityFilter" },
          },
          type: {
            kind: "NamedType",
            name: {
              kind: "Name",
              value: "Input_reverb_search_ProximityFilterRequest",
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "traitValues" },
          },
          type: {
            kind: "ListType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "dealsOrWellPriced" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "Boolean" } },
        },
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "slug" } },
          type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "shouldUseListingsGrid" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "Boolean" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "cspSearch" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "traitSlugs" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "traitSlugs" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "sort" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "cspSort" },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "csps" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      {
                        kind: "FragmentSpread",
                        name: { kind: "Name", value: "CSPCard" },
                      },
                      {
                        kind: "FragmentSpread",
                        name: {
                          kind: "Name",
                          value: "ProductFamilyHeaderFields",
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            alias: { kind: "Name", value: "listingsRowSearch" },
            name: { kind: "Name", value: "listingsSearch" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "limit" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "listingsRowLimit" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "traitSlugs" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "traitSlugs" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "curatedSetSlugs" },
                      value: {
                        kind: "Variable",
                        name: {
                          kind: "Name",
                          value: "listingsRowCuratedSetSlugs",
                        },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "dealsOrWellPriced" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "dealsOrWellPriced" },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "listings" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      {
                        kind: "FragmentSpread",
                        name: { kind: "Name", value: "ListingCardFields" },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            alias: { kind: "Name", value: "listingsGridSearch" },
            name: { kind: "Name", value: "listingsSearch" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "query" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "query" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "limit" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "listingsGridLimit" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "offset" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "listingsGridOffset" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "currency" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "currency" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "priceMax" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "priceMax" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "priceMin" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "priceMin" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "yearMax" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "yearMax" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "yearMin" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "yearMin" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "sortSlug" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "sortSlug" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "brandSlugs" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "brandSlugs" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "categorySlugs" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "categorySlugs" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "conditionSlugs" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "conditionSlugs" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "withAggregations" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "aggs" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "onSale" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "onSale" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "handmade" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "handmade" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "freeShipping" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "freeShipping" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "freeExpeditedShipping" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "freeExpeditedShipping" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "acceptsOffers" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "acceptsOffers" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "acceptsGiftCards" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "acceptsGiftCards" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "preferredSeller" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "preferredSeller" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "acceptsPaymentPlans" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "acceptsPaymentPlans" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "itemRegion" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "itemRegion" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "shippingRegionCodes" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "shippingRegionCodes" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "decades" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "decades" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "traitSlugs" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "traitSlugs" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "localPickup" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "localPickup" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "combinedShipping" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "combinedShipping" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "acceptsAffirm" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "acceptsAffirm" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "showOnlySold" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "showOnlySold" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "withProximityFilter" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "withProximityFilter" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "traitValues" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "traitValues" },
                      },
                    },
                  ],
                },
              },
            ],
            directives: [
              {
                kind: "Directive",
                name: { kind: "Name", value: "include" },
                arguments: [
                  {
                    kind: "Argument",
                    name: { kind: "Name", value: "if" },
                    value: {
                      kind: "Variable",
                      name: { kind: "Name", value: "shouldUseListingsGrid" },
                    },
                  },
                ],
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "total" } },
                { kind: "Field", name: { kind: "Name", value: "offset" } },
                { kind: "Field", name: { kind: "Name", value: "limit" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "filters" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "FragmentSpread",
                        name: { kind: "Name", value: "NestedFilter" },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "listings" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      {
                        kind: "FragmentSpread",
                        name: { kind: "Name", value: "ListingCardFields" },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            alias: { kind: "Name", value: "cmsPage" },
            name: { kind: "Name", value: "cmsPagesFetch" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "slugs" },
                      value: {
                        kind: "ListValue",
                        values: [
                          {
                            kind: "Variable",
                            name: { kind: "Name", value: "slug" },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "title" } },
                { kind: "Field", name: { kind: "Name", value: "metaTitle" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "metaDescription" },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "CSPAdditionalTrackingFields" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "CSP" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "slug" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "brand" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "slug" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "categories" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "slug" } },
                { kind: "Field", name: { kind: "Name", value: "rootSlug" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "parentCategories" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      { kind: "Field", name: { kind: "Name", value: "slug" } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "inventory" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "usedTotal" } },
                { kind: "Field", name: { kind: "Name", value: "newTotal" } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "ListingForBuyerShippingFields" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Listing" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "shipping" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "freeExpeditedShipping" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "localPickupOnly" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "shippingPrices" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "shippingMethod" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "carrierCalculated" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "regional" },
                      },
                      {
                        kind: "Field",
                        name: {
                          kind: "Name",
                          value: "destinationPostalCodeNeeded",
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "postalCode" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "rate" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "amount" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "amountCents" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "currency" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "display" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "ListingGreatValueData" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Listing" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "pricing" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "buyerPrice" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "currency" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "amountCents" },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "condition" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "conditionSlug" },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "priceRecommendation" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "priceMiddle" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "amountCents" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "currency" },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "shop" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "address" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "country" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "_id" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "countryCode" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          { kind: "Field", name: { kind: "Name", value: "currency" } },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "ListingCardCPOData" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Listing" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "certifiedPreOwned" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "title" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "badgeIconUrl" },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "ListingForBuyerFields" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Listing" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "title" } },
          { kind: "Field", name: { kind: "Name", value: "slug" } },
          { kind: "Field", name: { kind: "Name", value: "listingType" } },
          { kind: "Field", name: { kind: "Name", value: "make" } },
          { kind: "Field", name: { kind: "Name", value: "model" } },
          { kind: "Field", name: { kind: "Name", value: "upc" } },
          { kind: "Field", name: { kind: "Name", value: "state" } },
          { kind: "Field", name: { kind: "Name", value: "stateDescription" } },
          { kind: "Field", name: { kind: "Name", value: "bumped" } },
          { kind: "Field", name: { kind: "Name", value: "watching" } },
          { kind: "Field", name: { kind: "Name", value: "soldAsIs" } },
          { kind: "Field", name: { kind: "Name", value: "usOutlet" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "publishedAt" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "seconds" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "condition" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "displayName" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "conditionSlug" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "conditionUuid" },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "pricing" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "buyerPrice" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "display" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "currency" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "amount" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "amountCents" },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "originalPrice" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "display" },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "ribbon" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "display" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "reason" },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "images" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "transform" },
                      value: {
                        kind: "StringValue",
                        value: "card_square",
                        block: false,
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "count" },
                      value: { kind: "IntValue", value: "3" },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "scope" },
                      value: {
                        kind: "StringValue",
                        value: "photos",
                        block: false,
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "type" },
                      value: {
                        kind: "StringValue",
                        value: "Product",
                        block: false,
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "source" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "shipping" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "shippingPrices" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "shippingMethod" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "carrierCalculated" },
                      },
                      {
                        kind: "Field",
                        name: {
                          kind: "Name",
                          value: "destinationPostalCodeNeeded",
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "rate" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "amount" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "amountCents" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "currency" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "display" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "freeExpeditedShipping" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "localPickupOnly" },
                },
                { kind: "Field", name: { kind: "Name", value: "localPickup" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "shop" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "name" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "returnPolicy" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "usedReturnWindowDays" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "newReturnWindowDays" },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "address" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "locality" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "region" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "country" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "_id" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "countryCode" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "name" },
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "displayLocation" },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "ListingForBuyerShippingFields" },
          },
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "ListingGreatValueData" },
          },
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "ListingCardCPOData" },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "WatchBadgeData" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Listing" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "title" } },
          { kind: "Field", name: { kind: "Name", value: "sellerId" } },
          { kind: "Field", name: { kind: "Name", value: "watching" } },
          {
            kind: "Field",
            alias: { kind: "Name", value: "watchThumbnails" },
            name: { kind: "Name", value: "images" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "type" },
                      value: {
                        kind: "StringValue",
                        value: "Product",
                        block: false,
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "scope" },
                      value: {
                        kind: "StringValue",
                        value: "photos",
                        block: false,
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "transform" },
                      value: {
                        kind: "StringValue",
                        value: "card_square",
                        block: false,
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "count" },
                      value: { kind: "IntValue", value: "1" },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "source" } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "mParticleListingFields" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Listing" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "title" } },
          { kind: "Field", name: { kind: "Name", value: "brandSlug" } },
          { kind: "Field", name: { kind: "Name", value: "categoryRootUuid" } },
          { kind: "Field", name: { kind: "Name", value: "make" } },
          { kind: "Field", name: { kind: "Name", value: "categoryUuids" } },
          { kind: "Field", name: { kind: "Name", value: "state" } },
          { kind: "Field", name: { kind: "Name", value: "listingType" } },
          { kind: "Field", name: { kind: "Name", value: "bumpEligible" } },
          { kind: "Field", name: { kind: "Name", value: "shopId" } },
          { kind: "Field", name: { kind: "Name", value: "inventory" } },
          { kind: "Field", name: { kind: "Name", value: "soldAsIs" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "acceptedPaymentMethods" },
          },
          { kind: "Field", name: { kind: "Name", value: "currency" } },
          { kind: "Field", name: { kind: "Name", value: "usOutlet" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "condition" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "conditionUuid" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "conditionSlug" },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "categories" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "slug" } },
                { kind: "Field", name: { kind: "Name", value: "rootSlug" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "csp" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "slug" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "brand" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      { kind: "Field", name: { kind: "Name", value: "slug" } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "pricing" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "buyerPrice" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "amount" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "currency" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "amountCents" },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "publishedAt" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "seconds" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "sale" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "code" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "buyerIneligibilityReason" },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "shipping" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "shippingPrices" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "shippingMethod" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "carrierCalculated" },
                      },
                      {
                        kind: "Field",
                        name: {
                          kind: "Name",
                          value: "destinationPostalCodeNeeded",
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "rate" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "amount" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "amountCents" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "currency" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "display" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "freeExpeditedShipping" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "localPickupOnly" },
                },
                { kind: "Field", name: { kind: "Name", value: "localPickup" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "certifiedPreOwned" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "title" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "badgeIconUrl" },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "shop" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "address" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "countryCode" },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "returnPolicy" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "newReturnWindowDays" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "usedReturnWindowDays" },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "ListingCreateOfferButtonData" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Listing" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "state" } },
          { kind: "Field", name: { kind: "Name", value: "offersEnabled" } },
          { kind: "Field", name: { kind: "Name", value: "listingType" } },
          { kind: "Field", name: { kind: "Name", value: "sellerId" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "isBuyerOfferEligible" },
          },
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "mParticleListingFields" },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "CSPCard" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "CSP" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "title" } },
          { kind: "Field", name: { kind: "Name", value: "slug" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "averageReviewRating" },
          },
          { kind: "Field", name: { kind: "Name", value: "reviewsCount" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "webLink" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "path" } },
                { kind: "Field", name: { kind: "Name", value: "href" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "image" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "transform" },
                      value: {
                        kind: "StringValue",
                        value: "card_square",
                        block: false,
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "source" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "brand" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "slug" } },
                { kind: "Field", name: { kind: "Name", value: "name" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "categories" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "slug" } },
                { kind: "Field", name: { kind: "Name", value: "rootSlug" } },
                { kind: "Field", name: { kind: "Name", value: "name" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "parentCategories" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      { kind: "Field", name: { kind: "Name", value: "name" } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "inventory" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "newLowPrice" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "display" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "amount" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "amountCents" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "currency" },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "usedLowPrice" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "display" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "amount" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "amountCents" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "currency" },
                      },
                    ],
                  },
                },
                { kind: "Field", name: { kind: "Name", value: "usedTotal" } },
                { kind: "Field", name: { kind: "Name", value: "newTotal" } },
              ],
            },
          },
          { kind: "Field", name: { kind: "Name", value: "finishes" } },
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "CSPAdditionalTrackingFields" },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "ProductFamilyHeaderFields" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "CSP" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "brand" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "slug" } },
                { kind: "Field", name: { kind: "Name", value: "name" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "categories" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "parent" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      { kind: "Field", name: { kind: "Name", value: "slug" } },
                      { kind: "Field", name: { kind: "Name", value: "name" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "cmsLink" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "href" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "ListingCardFields" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Listing" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "ListingForBuyerFields" },
          },
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "WatchBadgeData" },
          },
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "ListingCreateOfferButtonData" },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "NestedFilter" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "reverb_search_Filter" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "name" } },
          { kind: "Field", name: { kind: "Name", value: "key" } },
          { kind: "Field", name: { kind: "Name", value: "aggregationName" } },
          { kind: "Field", name: { kind: "Name", value: "widgetType" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "options" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "count" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "value" } },
                    ],
                  },
                },
                { kind: "Field", name: { kind: "Name", value: "name" } },
                { kind: "Field", name: { kind: "Name", value: "selected" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "autoselected" },
                },
                { kind: "Field", name: { kind: "Name", value: "paramName" } },
                { kind: "Field", name: { kind: "Name", value: "setValues" } },
                { kind: "Field", name: { kind: "Name", value: "unsetValues" } },
                { kind: "Field", name: { kind: "Name", value: "all" } },
                { kind: "Field", name: { kind: "Name", value: "optionValue" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "trackingValue" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "subFilter" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "widgetType" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "options" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "count" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "value" },
                                  },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "name" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "selected" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "autoselected" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "paramName" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "setValues" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "unsetValues" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "all" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "optionValue" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "trackingValue" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "subFilter" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "widgetType" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "options" },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "count",
                                          },
                                          selectionSet: {
                                            kind: "SelectionSet",
                                            selections: [
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "value",
                                                },
                                              },
                                            ],
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "name" },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "selected",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "autoselected",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "paramName",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "setValues",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "unsetValues",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "all" },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "optionValue",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "trackingValue",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "subFilter",
                                          },
                                          selectionSet: {
                                            kind: "SelectionSet",
                                            selections: [
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "widgetType",
                                                },
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "options",
                                                },
                                                selectionSet: {
                                                  kind: "SelectionSet",
                                                  selections: [
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "count",
                                                      },
                                                      selectionSet: {
                                                        kind: "SelectionSet",
                                                        selections: [
                                                          {
                                                            kind: "Field",
                                                            name: {
                                                              kind: "Name",
                                                              value: "value",
                                                            },
                                                          },
                                                        ],
                                                      },
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "name",
                                                      },
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "selected",
                                                      },
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "autoselected",
                                                      },
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "paramName",
                                                      },
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "setValues",
                                                      },
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "unsetValues",
                                                      },
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "all",
                                                      },
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "optionValue",
                                                      },
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "trackingValue",
                                                      },
                                                    },
                                                  ],
                                                },
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Core_ProductFamilyPage_LayoutQuery,
  Core_ProductFamilyPage_LayoutQueryVariables
>;
export const Core_Update_Universal_Promo_CampaignDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "Core_Update_Universal_Promo_Campaign" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "input" },
          },
          type: {
            kind: "NamedType",
            name: {
              kind: "Name",
              value:
                "Input_core_apimessages_UpdateUniversalPromoCampaignRequest",
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "updateUniversalPromoCampaign" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "input" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "universalPromoCampaign" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Core_Update_Universal_Promo_CampaignMutation,
  Core_Update_Universal_Promo_CampaignMutationVariables
>;
export const Core_Universal_Promo_CampaignDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "Core_Universal_Promo_Campaign" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "campaignId" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "universalpromocampaign" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "id" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "campaignId" },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "name" } },
                { kind: "Field", name: { kind: "Name", value: "code" } },
                { kind: "Field", name: { kind: "Name", value: "expiresAt" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "marketingChannel" },
                },
                { kind: "Field", name: { kind: "Name", value: "newBuyer" } },
                { kind: "Field", name: { kind: "Name", value: "lapsedBuyer" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "lapsedBuyerLastPurchaseDate" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "categories" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "redemptionLimit" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "hasRedemptionLimit" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "universalPromoCurrencies" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "amount" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "currency" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "amount" },
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "minPurchaseAmount" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "currency" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "amount" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Core_Universal_Promo_CampaignQuery,
  Core_Universal_Promo_CampaignQueryVariables
>;
export const Core_RecentlyViewedCspsRowDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "Core_RecentlyViewedCspsRow" },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "recentlyViewedListings" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "limit" },
                      value: { kind: "IntValue", value: "25" },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "csp" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      {
                        kind: "FragmentSpread",
                        name: { kind: "Name", value: "CSPCard" },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "CSPAdditionalTrackingFields" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "CSP" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "slug" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "brand" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "slug" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "categories" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "slug" } },
                { kind: "Field", name: { kind: "Name", value: "rootSlug" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "parentCategories" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      { kind: "Field", name: { kind: "Name", value: "slug" } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "inventory" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "usedTotal" } },
                { kind: "Field", name: { kind: "Name", value: "newTotal" } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "CSPCard" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "CSP" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "title" } },
          { kind: "Field", name: { kind: "Name", value: "slug" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "averageReviewRating" },
          },
          { kind: "Field", name: { kind: "Name", value: "reviewsCount" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "webLink" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "path" } },
                { kind: "Field", name: { kind: "Name", value: "href" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "image" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "transform" },
                      value: {
                        kind: "StringValue",
                        value: "card_square",
                        block: false,
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "source" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "brand" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "slug" } },
                { kind: "Field", name: { kind: "Name", value: "name" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "categories" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "slug" } },
                { kind: "Field", name: { kind: "Name", value: "rootSlug" } },
                { kind: "Field", name: { kind: "Name", value: "name" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "parentCategories" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      { kind: "Field", name: { kind: "Name", value: "name" } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "inventory" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "newLowPrice" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "display" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "amount" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "amountCents" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "currency" },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "usedLowPrice" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "display" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "amount" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "amountCents" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "currency" },
                      },
                    ],
                  },
                },
                { kind: "Field", name: { kind: "Name", value: "usedTotal" } },
                { kind: "Field", name: { kind: "Name", value: "newTotal" } },
              ],
            },
          },
          { kind: "Field", name: { kind: "Name", value: "finishes" } },
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "CSPAdditionalTrackingFields" },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Core_RecentlyViewedCspsRowQuery,
  Core_RecentlyViewedCspsRowQueryVariables
>;
export const Core_RecommendedCspsRowDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "Core_RecommendedCspsRow" },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "me" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "uuid" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "recommendedListings" },
                  arguments: [
                    {
                      kind: "Argument",
                      name: { kind: "Name", value: "input" },
                      value: {
                        kind: "ObjectValue",
                        fields: [
                          {
                            kind: "ObjectField",
                            name: { kind: "Name", value: "limit" },
                            value: { kind: "IntValue", value: "5" },
                          },
                        ],
                      },
                    },
                  ],
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "listings" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "_id" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "csp" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "_id" },
                                  },
                                  {
                                    kind: "FragmentSpread",
                                    name: { kind: "Name", value: "CSPCard" },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "CSPAdditionalTrackingFields" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "CSP" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "slug" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "brand" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "slug" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "categories" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "slug" } },
                { kind: "Field", name: { kind: "Name", value: "rootSlug" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "parentCategories" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      { kind: "Field", name: { kind: "Name", value: "slug" } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "inventory" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "usedTotal" } },
                { kind: "Field", name: { kind: "Name", value: "newTotal" } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "CSPCard" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "CSP" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "title" } },
          { kind: "Field", name: { kind: "Name", value: "slug" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "averageReviewRating" },
          },
          { kind: "Field", name: { kind: "Name", value: "reviewsCount" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "webLink" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "path" } },
                { kind: "Field", name: { kind: "Name", value: "href" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "image" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "transform" },
                      value: {
                        kind: "StringValue",
                        value: "card_square",
                        block: false,
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "source" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "brand" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "slug" } },
                { kind: "Field", name: { kind: "Name", value: "name" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "categories" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "slug" } },
                { kind: "Field", name: { kind: "Name", value: "rootSlug" } },
                { kind: "Field", name: { kind: "Name", value: "name" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "parentCategories" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      { kind: "Field", name: { kind: "Name", value: "name" } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "inventory" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "newLowPrice" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "display" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "amount" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "amountCents" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "currency" },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "usedLowPrice" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "display" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "amount" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "amountCents" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "currency" },
                      },
                    ],
                  },
                },
                { kind: "Field", name: { kind: "Name", value: "usedTotal" } },
                { kind: "Field", name: { kind: "Name", value: "newTotal" } },
              ],
            },
          },
          { kind: "Field", name: { kind: "Name", value: "finishes" } },
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "CSPAdditionalTrackingFields" },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Core_RecommendedCspsRowQuery,
  Core_RecommendedCspsRowQueryVariables
>;
export const MeRecommendedListingsGridDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "MeRecommendedListingsGrid" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "query" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "currency" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "priceMax" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "priceMin" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "decades" },
          },
          type: {
            kind: "ListType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "yearMax" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "yearMin" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "sortSlug" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "limit" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "Int" } },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "offset" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "Int" } },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "categorySlugs" },
          },
          type: {
            kind: "ListType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "brandSlugs" },
          },
          type: {
            kind: "ListType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "aggs" } },
          type: {
            kind: "ListType",
            type: {
              kind: "NamedType",
              name: {
                kind: "Name",
                value: "reverb_search_ListingsSearchRequest_Aggregation",
              },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "conditionSlugs" },
          },
          type: {
            kind: "ListType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "onSale" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "Boolean" } },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "handmade" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "Boolean" } },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "freeShipping" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "Boolean" } },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "freeExpeditedShipping" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "Boolean" } },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "acceptsOffers" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "Boolean" } },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "acceptsGiftCards" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "Boolean" } },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "preferredSeller" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "Boolean" } },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "acceptsPaymentPlans" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "Boolean" } },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "itemRegion" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "shippingRegionCode" },
          },
          type: {
            kind: "ListType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "showOnlySold" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "Boolean" } },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "showSold" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "Boolean" } },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "traitValues" },
          },
          type: {
            kind: "ListType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "itemState" },
          },
          type: {
            kind: "ListType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "itemCity" },
          },
          type: {
            kind: "ListType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "shopSlug" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "curatedSetSlugs" },
          },
          type: {
            kind: "ListType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "saleSlugs" },
          },
          type: {
            kind: "ListType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "cspSlug" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
        },
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "sort" } },
          type: {
            kind: "NamedType",
            name: {
              kind: "Name",
              value: "reverb_search_ListingsSearchRequest_Sort",
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "combinedShipping" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "Boolean" } },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "acceptsAffirm" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "Boolean" } },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "terminateEarly" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "Boolean" } },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "collapsible" },
          },
          type: {
            kind: "NamedType",
            name: {
              kind: "Name",
              value: "reverb_search_ListingsSearchRequest_Collapsible",
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "fallbackToOr" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "Boolean" } },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "withProximityFilter" },
          },
          type: {
            kind: "NamedType",
            name: {
              kind: "Name",
              value: "Input_reverb_search_ProximityFilterRequest",
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "localPickup" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "Boolean" } },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "shouldSkipTracking" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "Boolean" },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "version" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "Int" } },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "me" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "uuid" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "recommendedListings" },
                  arguments: [
                    {
                      kind: "Argument",
                      name: { kind: "Name", value: "input" },
                      value: {
                        kind: "ObjectValue",
                        fields: [
                          {
                            kind: "ObjectField",
                            name: { kind: "Name", value: "query" },
                            value: {
                              kind: "Variable",
                              name: { kind: "Name", value: "query" },
                            },
                          },
                          {
                            kind: "ObjectField",
                            name: { kind: "Name", value: "currency" },
                            value: {
                              kind: "Variable",
                              name: { kind: "Name", value: "currency" },
                            },
                          },
                          {
                            kind: "ObjectField",
                            name: { kind: "Name", value: "priceMax" },
                            value: {
                              kind: "Variable",
                              name: { kind: "Name", value: "priceMax" },
                            },
                          },
                          {
                            kind: "ObjectField",
                            name: { kind: "Name", value: "priceMin" },
                            value: {
                              kind: "Variable",
                              name: { kind: "Name", value: "priceMin" },
                            },
                          },
                          {
                            kind: "ObjectField",
                            name: { kind: "Name", value: "decades" },
                            value: {
                              kind: "Variable",
                              name: { kind: "Name", value: "decades" },
                            },
                          },
                          {
                            kind: "ObjectField",
                            name: { kind: "Name", value: "yearMax" },
                            value: {
                              kind: "Variable",
                              name: { kind: "Name", value: "yearMax" },
                            },
                          },
                          {
                            kind: "ObjectField",
                            name: { kind: "Name", value: "yearMin" },
                            value: {
                              kind: "Variable",
                              name: { kind: "Name", value: "yearMin" },
                            },
                          },
                          {
                            kind: "ObjectField",
                            name: { kind: "Name", value: "sortSlug" },
                            value: {
                              kind: "Variable",
                              name: { kind: "Name", value: "sortSlug" },
                            },
                          },
                          {
                            kind: "ObjectField",
                            name: { kind: "Name", value: "traitValues" },
                            value: {
                              kind: "Variable",
                              name: { kind: "Name", value: "traitValues" },
                            },
                          },
                          {
                            kind: "ObjectField",
                            name: { kind: "Name", value: "limit" },
                            value: {
                              kind: "Variable",
                              name: { kind: "Name", value: "limit" },
                            },
                          },
                          {
                            kind: "ObjectField",
                            name: { kind: "Name", value: "offset" },
                            value: {
                              kind: "Variable",
                              name: { kind: "Name", value: "offset" },
                            },
                          },
                          {
                            kind: "ObjectField",
                            name: { kind: "Name", value: "categorySlugs" },
                            value: {
                              kind: "Variable",
                              name: { kind: "Name", value: "categorySlugs" },
                            },
                          },
                          {
                            kind: "ObjectField",
                            name: { kind: "Name", value: "brandSlugs" },
                            value: {
                              kind: "Variable",
                              name: { kind: "Name", value: "brandSlugs" },
                            },
                          },
                          {
                            kind: "ObjectField",
                            name: { kind: "Name", value: "withAggregations" },
                            value: {
                              kind: "Variable",
                              name: { kind: "Name", value: "aggs" },
                            },
                          },
                          {
                            kind: "ObjectField",
                            name: { kind: "Name", value: "conditionSlugs" },
                            value: {
                              kind: "Variable",
                              name: { kind: "Name", value: "conditionSlugs" },
                            },
                          },
                          {
                            kind: "ObjectField",
                            name: { kind: "Name", value: "onSale" },
                            value: {
                              kind: "Variable",
                              name: { kind: "Name", value: "onSale" },
                            },
                          },
                          {
                            kind: "ObjectField",
                            name: { kind: "Name", value: "handmade" },
                            value: {
                              kind: "Variable",
                              name: { kind: "Name", value: "handmade" },
                            },
                          },
                          {
                            kind: "ObjectField",
                            name: { kind: "Name", value: "freeShipping" },
                            value: {
                              kind: "Variable",
                              name: { kind: "Name", value: "freeShipping" },
                            },
                          },
                          {
                            kind: "ObjectField",
                            name: {
                              kind: "Name",
                              value: "freeExpeditedShipping",
                            },
                            value: {
                              kind: "Variable",
                              name: {
                                kind: "Name",
                                value: "freeExpeditedShipping",
                              },
                            },
                          },
                          {
                            kind: "ObjectField",
                            name: { kind: "Name", value: "acceptsOffers" },
                            value: {
                              kind: "Variable",
                              name: { kind: "Name", value: "acceptsOffers" },
                            },
                          },
                          {
                            kind: "ObjectField",
                            name: { kind: "Name", value: "acceptsGiftCards" },
                            value: {
                              kind: "Variable",
                              name: { kind: "Name", value: "acceptsGiftCards" },
                            },
                          },
                          {
                            kind: "ObjectField",
                            name: { kind: "Name", value: "preferredSeller" },
                            value: {
                              kind: "Variable",
                              name: { kind: "Name", value: "preferredSeller" },
                            },
                          },
                          {
                            kind: "ObjectField",
                            name: {
                              kind: "Name",
                              value: "acceptsPaymentPlans",
                            },
                            value: {
                              kind: "Variable",
                              name: {
                                kind: "Name",
                                value: "acceptsPaymentPlans",
                              },
                            },
                          },
                          {
                            kind: "ObjectField",
                            name: { kind: "Name", value: "itemRegion" },
                            value: {
                              kind: "Variable",
                              name: { kind: "Name", value: "itemRegion" },
                            },
                          },
                          {
                            kind: "ObjectField",
                            name: {
                              kind: "Name",
                              value: "shippingRegionCodes",
                            },
                            value: {
                              kind: "Variable",
                              name: {
                                kind: "Name",
                                value: "shippingRegionCode",
                              },
                            },
                          },
                          {
                            kind: "ObjectField",
                            name: { kind: "Name", value: "showOnlySold" },
                            value: {
                              kind: "Variable",
                              name: { kind: "Name", value: "showOnlySold" },
                            },
                          },
                          {
                            kind: "ObjectField",
                            name: { kind: "Name", value: "showSold" },
                            value: {
                              kind: "Variable",
                              name: { kind: "Name", value: "showSold" },
                            },
                          },
                          {
                            kind: "ObjectField",
                            name: { kind: "Name", value: "itemState" },
                            value: {
                              kind: "Variable",
                              name: { kind: "Name", value: "itemState" },
                            },
                          },
                          {
                            kind: "ObjectField",
                            name: { kind: "Name", value: "itemCity" },
                            value: {
                              kind: "Variable",
                              name: { kind: "Name", value: "itemCity" },
                            },
                          },
                          {
                            kind: "ObjectField",
                            name: { kind: "Name", value: "shopSlug" },
                            value: {
                              kind: "Variable",
                              name: { kind: "Name", value: "shopSlug" },
                            },
                          },
                          {
                            kind: "ObjectField",
                            name: { kind: "Name", value: "curatedSetSlugs" },
                            value: {
                              kind: "Variable",
                              name: { kind: "Name", value: "curatedSetSlugs" },
                            },
                          },
                          {
                            kind: "ObjectField",
                            name: { kind: "Name", value: "saleSlugs" },
                            value: {
                              kind: "Variable",
                              name: { kind: "Name", value: "saleSlugs" },
                            },
                          },
                          {
                            kind: "ObjectField",
                            name: { kind: "Name", value: "cspSlug" },
                            value: {
                              kind: "Variable",
                              name: { kind: "Name", value: "cspSlug" },
                            },
                          },
                          {
                            kind: "ObjectField",
                            name: { kind: "Name", value: "sort" },
                            value: {
                              kind: "Variable",
                              name: { kind: "Name", value: "sort" },
                            },
                          },
                          {
                            kind: "ObjectField",
                            name: { kind: "Name", value: "combinedShipping" },
                            value: {
                              kind: "Variable",
                              name: { kind: "Name", value: "combinedShipping" },
                            },
                          },
                          {
                            kind: "ObjectField",
                            name: { kind: "Name", value: "acceptsAffirm" },
                            value: {
                              kind: "Variable",
                              name: { kind: "Name", value: "acceptsAffirm" },
                            },
                          },
                          {
                            kind: "ObjectField",
                            name: { kind: "Name", value: "terminateEarly" },
                            value: {
                              kind: "Variable",
                              name: { kind: "Name", value: "terminateEarly" },
                            },
                          },
                          {
                            kind: "ObjectField",
                            name: { kind: "Name", value: "collapsible" },
                            value: {
                              kind: "Variable",
                              name: { kind: "Name", value: "collapsible" },
                            },
                          },
                          {
                            kind: "ObjectField",
                            name: { kind: "Name", value: "fallbackToOr" },
                            value: {
                              kind: "Variable",
                              name: { kind: "Name", value: "fallbackToOr" },
                            },
                          },
                          {
                            kind: "ObjectField",
                            name: {
                              kind: "Name",
                              value: "withProximityFilter",
                            },
                            value: {
                              kind: "Variable",
                              name: {
                                kind: "Name",
                                value: "withProximityFilter",
                              },
                            },
                          },
                          {
                            kind: "ObjectField",
                            name: { kind: "Name", value: "localPickup" },
                            value: {
                              kind: "Variable",
                              name: { kind: "Name", value: "localPickup" },
                            },
                          },
                          {
                            kind: "ObjectField",
                            name: {
                              kind: "Name",
                              value: "listingRecommendationsVersion",
                            },
                            value: {
                              kind: "Variable",
                              name: { kind: "Name", value: "version" },
                            },
                          },
                        ],
                      },
                    },
                  ],
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "total" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "offset" },
                      },
                      { kind: "Field", name: { kind: "Name", value: "limit" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "filters" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "FragmentSpread",
                              name: { kind: "Name", value: "NestedFilter" },
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "listings" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "_id" },
                            },
                            {
                              kind: "FragmentSpread",
                              name: {
                                kind: "Name",
                                value: "ListingsCollection",
                              },
                            },
                            {
                              kind: "FragmentSpread",
                              name: {
                                kind: "Name",
                                value: "mParticleListingFields",
                              },
                              directives: [
                                {
                                  kind: "Directive",
                                  name: { kind: "Name", value: "skip" },
                                  arguments: [
                                    {
                                      kind: "Argument",
                                      name: { kind: "Name", value: "if" },
                                      value: {
                                        kind: "Variable",
                                        name: {
                                          kind: "Name",
                                          value: "shouldSkipTracking",
                                        },
                                      },
                                    },
                                  ],
                                },
                              ],
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "AddToCartButtonFields" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Listing" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "sellerId" } },
          { kind: "Field", name: { kind: "Name", value: "listingType" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "pricing" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "buyerPrice" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "amount" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "amountCents" },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "preorderInfo" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "onPreorder" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "estimatedShipDate" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "seconds" },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "WatchBadgeData" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Listing" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "title" } },
          { kind: "Field", name: { kind: "Name", value: "sellerId" } },
          { kind: "Field", name: { kind: "Name", value: "watching" } },
          {
            kind: "Field",
            alias: { kind: "Name", value: "watchThumbnails" },
            name: { kind: "Name", value: "images" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "type" },
                      value: {
                        kind: "StringValue",
                        value: "Product",
                        block: false,
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "scope" },
                      value: {
                        kind: "StringValue",
                        value: "photos",
                        block: false,
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "transform" },
                      value: {
                        kind: "StringValue",
                        value: "card_square",
                        block: false,
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "count" },
                      value: { kind: "IntValue", value: "1" },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "source" } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "ListingGreatValueData" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Listing" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "pricing" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "buyerPrice" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "currency" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "amountCents" },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "condition" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "conditionSlug" },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "priceRecommendation" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "priceMiddle" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "amountCents" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "currency" },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "shop" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "address" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "country" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "_id" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "countryCode" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          { kind: "Field", name: { kind: "Name", value: "currency" } },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "mParticleListingFields" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Listing" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "title" } },
          { kind: "Field", name: { kind: "Name", value: "brandSlug" } },
          { kind: "Field", name: { kind: "Name", value: "categoryRootUuid" } },
          { kind: "Field", name: { kind: "Name", value: "make" } },
          { kind: "Field", name: { kind: "Name", value: "categoryUuids" } },
          { kind: "Field", name: { kind: "Name", value: "state" } },
          { kind: "Field", name: { kind: "Name", value: "listingType" } },
          { kind: "Field", name: { kind: "Name", value: "bumpEligible" } },
          { kind: "Field", name: { kind: "Name", value: "shopId" } },
          { kind: "Field", name: { kind: "Name", value: "inventory" } },
          { kind: "Field", name: { kind: "Name", value: "soldAsIs" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "acceptedPaymentMethods" },
          },
          { kind: "Field", name: { kind: "Name", value: "currency" } },
          { kind: "Field", name: { kind: "Name", value: "usOutlet" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "condition" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "conditionUuid" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "conditionSlug" },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "categories" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "slug" } },
                { kind: "Field", name: { kind: "Name", value: "rootSlug" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "csp" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "slug" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "brand" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      { kind: "Field", name: { kind: "Name", value: "slug" } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "pricing" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "buyerPrice" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "amount" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "currency" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "amountCents" },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "publishedAt" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "seconds" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "sale" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "code" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "buyerIneligibilityReason" },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "shipping" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "shippingPrices" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "shippingMethod" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "carrierCalculated" },
                      },
                      {
                        kind: "Field",
                        name: {
                          kind: "Name",
                          value: "destinationPostalCodeNeeded",
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "rate" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "amount" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "amountCents" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "currency" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "display" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "freeExpeditedShipping" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "localPickupOnly" },
                },
                { kind: "Field", name: { kind: "Name", value: "localPickup" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "certifiedPreOwned" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "title" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "badgeIconUrl" },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "shop" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "address" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "countryCode" },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "returnPolicy" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "newReturnWindowDays" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "usedReturnWindowDays" },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "ListingCreateOfferButtonData" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Listing" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "state" } },
          { kind: "Field", name: { kind: "Name", value: "offersEnabled" } },
          { kind: "Field", name: { kind: "Name", value: "listingType" } },
          { kind: "Field", name: { kind: "Name", value: "sellerId" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "isBuyerOfferEligible" },
          },
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "mParticleListingFields" },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "ListingImagesFields" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Listing" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "images" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "transform" },
                      value: {
                        kind: "StringValue",
                        value: "card_square",
                        block: false,
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "count" },
                      value: { kind: "IntValue", value: "3" },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "scope" },
                      value: {
                        kind: "StringValue",
                        value: "photos",
                        block: false,
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "type" },
                      value: {
                        kind: "StringValue",
                        value: "Product",
                        block: false,
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "source" } },
              ],
            },
          },
          {
            kind: "Field",
            alias: { kind: "Name", value: "largeSquareImages" },
            name: { kind: "Name", value: "images" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "transform" },
                      value: {
                        kind: "StringValue",
                        value: "large_square",
                        block: false,
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "count" },
                      value: { kind: "IntValue", value: "3" },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "scope" },
                      value: {
                        kind: "StringValue",
                        value: "photos",
                        block: false,
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "type" },
                      value: {
                        kind: "StringValue",
                        value: "Product",
                        block: false,
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "source" } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "ListingCardCPOData" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Listing" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "certifiedPreOwned" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "title" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "badgeIconUrl" },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "NestedFilter" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "reverb_search_Filter" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "name" } },
          { kind: "Field", name: { kind: "Name", value: "key" } },
          { kind: "Field", name: { kind: "Name", value: "aggregationName" } },
          { kind: "Field", name: { kind: "Name", value: "widgetType" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "options" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "count" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "value" } },
                    ],
                  },
                },
                { kind: "Field", name: { kind: "Name", value: "name" } },
                { kind: "Field", name: { kind: "Name", value: "selected" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "autoselected" },
                },
                { kind: "Field", name: { kind: "Name", value: "paramName" } },
                { kind: "Field", name: { kind: "Name", value: "setValues" } },
                { kind: "Field", name: { kind: "Name", value: "unsetValues" } },
                { kind: "Field", name: { kind: "Name", value: "all" } },
                { kind: "Field", name: { kind: "Name", value: "optionValue" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "trackingValue" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "subFilter" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "widgetType" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "options" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "count" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "value" },
                                  },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "name" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "selected" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "autoselected" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "paramName" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "setValues" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "unsetValues" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "all" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "optionValue" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "trackingValue" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "subFilter" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "widgetType" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "options" },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "count",
                                          },
                                          selectionSet: {
                                            kind: "SelectionSet",
                                            selections: [
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "value",
                                                },
                                              },
                                            ],
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "name" },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "selected",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "autoselected",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "paramName",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "setValues",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "unsetValues",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "all" },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "optionValue",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "trackingValue",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "subFilter",
                                          },
                                          selectionSet: {
                                            kind: "SelectionSet",
                                            selections: [
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "widgetType",
                                                },
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "options",
                                                },
                                                selectionSet: {
                                                  kind: "SelectionSet",
                                                  selections: [
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "count",
                                                      },
                                                      selectionSet: {
                                                        kind: "SelectionSet",
                                                        selections: [
                                                          {
                                                            kind: "Field",
                                                            name: {
                                                              kind: "Name",
                                                              value: "value",
                                                            },
                                                          },
                                                        ],
                                                      },
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "name",
                                                      },
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "selected",
                                                      },
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "autoselected",
                                                      },
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "paramName",
                                                      },
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "setValues",
                                                      },
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "unsetValues",
                                                      },
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "all",
                                                      },
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "optionValue",
                                                      },
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "trackingValue",
                                                      },
                                                    },
                                                  ],
                                                },
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "ListingsCollection" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Listing" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "usOutlet" } },
          { kind: "Field", name: { kind: "Name", value: "bumped" } },
          { kind: "Field", name: { kind: "Name", value: "categoryUuids" } },
          { kind: "Field", name: { kind: "Name", value: "slug" } },
          { kind: "Field", name: { kind: "Name", value: "title" } },
          { kind: "Field", name: { kind: "Name", value: "description" } },
          { kind: "Field", name: { kind: "Name", value: "listingType" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "condition" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "displayName" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "conditionSlug" },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "price" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "amountCents" } },
                { kind: "Field", name: { kind: "Name", value: "display" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "pricing" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "buyerPrice" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "display" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "currency" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "amountCents" },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "originalPrice" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "display" },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "ribbon" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "display" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "reason" },
                      },
                    ],
                  },
                },
              ],
            },
          },
          { kind: "Field", name: { kind: "Name", value: "watching" } },
          { kind: "Field", name: { kind: "Name", value: "state" } },
          { kind: "Field", name: { kind: "Name", value: "stateDescription" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "shipping" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "shippingPrices" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "shippingMethod" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "carrierCalculated" },
                      },
                      {
                        kind: "Field",
                        name: {
                          kind: "Name",
                          value: "destinationPostalCodeNeeded",
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "rate" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "amount" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "amountCents" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "currency" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "display" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "freeExpeditedShipping" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "localPickupOnly" },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "shop" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "address" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "country" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "_id" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "countryCode" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "name" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "AddToCartButtonFields" },
          },
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "WatchBadgeData" },
          },
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "ListingGreatValueData" },
          },
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "ListingCreateOfferButtonData" },
          },
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "ListingImagesFields" },
          },
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "ListingCardCPOData" },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  MeRecommendedListingsGridQuery,
  MeRecommendedListingsGridQueryVariables
>;
export const MeRecommendedListingsDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "MeRecommendedListings" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "limit" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "Int" } },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "offset" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "Int" } },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "shouldSkipTracking" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "Boolean" },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "version" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "Int" } },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "multiClientExperiments" },
          },
          type: {
            kind: "ListType",
            type: {
              kind: "NamedType",
              name: {
                kind: "Name",
                value: "Input_reverb_search_ExperimentData",
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "me" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "uuid" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "recommendedListings" },
                  arguments: [
                    {
                      kind: "Argument",
                      name: { kind: "Name", value: "input" },
                      value: {
                        kind: "ObjectValue",
                        fields: [
                          {
                            kind: "ObjectField",
                            name: { kind: "Name", value: "limit" },
                            value: {
                              kind: "Variable",
                              name: { kind: "Name", value: "limit" },
                            },
                          },
                          {
                            kind: "ObjectField",
                            name: { kind: "Name", value: "offset" },
                            value: {
                              kind: "Variable",
                              name: { kind: "Name", value: "offset" },
                            },
                          },
                          {
                            kind: "ObjectField",
                            name: {
                              kind: "Name",
                              value: "listingRecommendationsVersion",
                            },
                            value: {
                              kind: "Variable",
                              name: { kind: "Name", value: "version" },
                            },
                          },
                          {
                            kind: "ObjectField",
                            name: {
                              kind: "Name",
                              value: "multiClientExperiments",
                            },
                            value: {
                              kind: "Variable",
                              name: {
                                kind: "Name",
                                value: "multiClientExperiments",
                              },
                            },
                          },
                        ],
                      },
                    },
                  ],
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "listings" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "_id" },
                            },
                            {
                              kind: "FragmentSpread",
                              name: {
                                kind: "Name",
                                value: "ListingsCollection",
                              },
                            },
                            {
                              kind: "FragmentSpread",
                              name: {
                                kind: "Name",
                                value: "mParticleListingFields",
                              },
                              directives: [
                                {
                                  kind: "Directive",
                                  name: { kind: "Name", value: "skip" },
                                  arguments: [
                                    {
                                      kind: "Argument",
                                      name: { kind: "Name", value: "if" },
                                      value: {
                                        kind: "Variable",
                                        name: {
                                          kind: "Name",
                                          value: "shouldSkipTracking",
                                        },
                                      },
                                    },
                                  ],
                                },
                              ],
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "AddToCartButtonFields" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Listing" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "sellerId" } },
          { kind: "Field", name: { kind: "Name", value: "listingType" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "pricing" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "buyerPrice" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "amount" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "amountCents" },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "preorderInfo" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "onPreorder" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "estimatedShipDate" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "seconds" },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "WatchBadgeData" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Listing" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "title" } },
          { kind: "Field", name: { kind: "Name", value: "sellerId" } },
          { kind: "Field", name: { kind: "Name", value: "watching" } },
          {
            kind: "Field",
            alias: { kind: "Name", value: "watchThumbnails" },
            name: { kind: "Name", value: "images" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "type" },
                      value: {
                        kind: "StringValue",
                        value: "Product",
                        block: false,
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "scope" },
                      value: {
                        kind: "StringValue",
                        value: "photos",
                        block: false,
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "transform" },
                      value: {
                        kind: "StringValue",
                        value: "card_square",
                        block: false,
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "count" },
                      value: { kind: "IntValue", value: "1" },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "source" } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "ListingGreatValueData" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Listing" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "pricing" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "buyerPrice" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "currency" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "amountCents" },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "condition" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "conditionSlug" },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "priceRecommendation" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "priceMiddle" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "amountCents" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "currency" },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "shop" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "address" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "country" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "_id" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "countryCode" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          { kind: "Field", name: { kind: "Name", value: "currency" } },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "mParticleListingFields" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Listing" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "title" } },
          { kind: "Field", name: { kind: "Name", value: "brandSlug" } },
          { kind: "Field", name: { kind: "Name", value: "categoryRootUuid" } },
          { kind: "Field", name: { kind: "Name", value: "make" } },
          { kind: "Field", name: { kind: "Name", value: "categoryUuids" } },
          { kind: "Field", name: { kind: "Name", value: "state" } },
          { kind: "Field", name: { kind: "Name", value: "listingType" } },
          { kind: "Field", name: { kind: "Name", value: "bumpEligible" } },
          { kind: "Field", name: { kind: "Name", value: "shopId" } },
          { kind: "Field", name: { kind: "Name", value: "inventory" } },
          { kind: "Field", name: { kind: "Name", value: "soldAsIs" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "acceptedPaymentMethods" },
          },
          { kind: "Field", name: { kind: "Name", value: "currency" } },
          { kind: "Field", name: { kind: "Name", value: "usOutlet" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "condition" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "conditionUuid" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "conditionSlug" },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "categories" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "slug" } },
                { kind: "Field", name: { kind: "Name", value: "rootSlug" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "csp" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "slug" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "brand" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      { kind: "Field", name: { kind: "Name", value: "slug" } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "pricing" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "buyerPrice" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "amount" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "currency" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "amountCents" },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "publishedAt" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "seconds" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "sale" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "code" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "buyerIneligibilityReason" },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "shipping" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "shippingPrices" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "shippingMethod" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "carrierCalculated" },
                      },
                      {
                        kind: "Field",
                        name: {
                          kind: "Name",
                          value: "destinationPostalCodeNeeded",
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "rate" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "amount" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "amountCents" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "currency" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "display" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "freeExpeditedShipping" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "localPickupOnly" },
                },
                { kind: "Field", name: { kind: "Name", value: "localPickup" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "certifiedPreOwned" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "title" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "badgeIconUrl" },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "shop" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "address" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "countryCode" },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "returnPolicy" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "newReturnWindowDays" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "usedReturnWindowDays" },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "ListingCreateOfferButtonData" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Listing" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "state" } },
          { kind: "Field", name: { kind: "Name", value: "offersEnabled" } },
          { kind: "Field", name: { kind: "Name", value: "listingType" } },
          { kind: "Field", name: { kind: "Name", value: "sellerId" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "isBuyerOfferEligible" },
          },
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "mParticleListingFields" },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "ListingImagesFields" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Listing" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "images" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "transform" },
                      value: {
                        kind: "StringValue",
                        value: "card_square",
                        block: false,
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "count" },
                      value: { kind: "IntValue", value: "3" },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "scope" },
                      value: {
                        kind: "StringValue",
                        value: "photos",
                        block: false,
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "type" },
                      value: {
                        kind: "StringValue",
                        value: "Product",
                        block: false,
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "source" } },
              ],
            },
          },
          {
            kind: "Field",
            alias: { kind: "Name", value: "largeSquareImages" },
            name: { kind: "Name", value: "images" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "transform" },
                      value: {
                        kind: "StringValue",
                        value: "large_square",
                        block: false,
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "count" },
                      value: { kind: "IntValue", value: "3" },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "scope" },
                      value: {
                        kind: "StringValue",
                        value: "photos",
                        block: false,
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "type" },
                      value: {
                        kind: "StringValue",
                        value: "Product",
                        block: false,
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "source" } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "ListingCardCPOData" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Listing" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "certifiedPreOwned" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "title" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "badgeIconUrl" },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "ListingsCollection" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Listing" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "usOutlet" } },
          { kind: "Field", name: { kind: "Name", value: "bumped" } },
          { kind: "Field", name: { kind: "Name", value: "categoryUuids" } },
          { kind: "Field", name: { kind: "Name", value: "slug" } },
          { kind: "Field", name: { kind: "Name", value: "title" } },
          { kind: "Field", name: { kind: "Name", value: "description" } },
          { kind: "Field", name: { kind: "Name", value: "listingType" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "condition" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "displayName" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "conditionSlug" },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "price" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "amountCents" } },
                { kind: "Field", name: { kind: "Name", value: "display" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "pricing" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "buyerPrice" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "display" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "currency" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "amountCents" },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "originalPrice" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "display" },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "ribbon" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "display" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "reason" },
                      },
                    ],
                  },
                },
              ],
            },
          },
          { kind: "Field", name: { kind: "Name", value: "watching" } },
          { kind: "Field", name: { kind: "Name", value: "state" } },
          { kind: "Field", name: { kind: "Name", value: "stateDescription" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "shipping" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "shippingPrices" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "shippingMethod" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "carrierCalculated" },
                      },
                      {
                        kind: "Field",
                        name: {
                          kind: "Name",
                          value: "destinationPostalCodeNeeded",
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "rate" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "amount" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "amountCents" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "currency" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "display" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "freeExpeditedShipping" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "localPickupOnly" },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "shop" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "address" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "country" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "_id" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "countryCode" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "name" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "AddToCartButtonFields" },
          },
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "WatchBadgeData" },
          },
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "ListingGreatValueData" },
          },
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "ListingCreateOfferButtonData" },
          },
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "ListingImagesFields" },
          },
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "ListingCardCPOData" },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  MeRecommendedListingsQuery,
  MeRecommendedListingsQueryVariables
>;
export const CPListingsRecommendationsDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "CPListingsRecommendations" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "listingsLimit" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "Int" } },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "recsLimit" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "Int" } },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "shouldSkipTracking" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "Boolean" },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "sort" } },
          type: {
            kind: "NamedType",
            name: {
              kind: "Name",
              value: "reverb_search_ListingsSearchRequest_Sort",
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "collapsible" },
          },
          type: {
            kind: "NamedType",
            name: {
              kind: "Name",
              value: "reverb_search_ListingsSearchRequest_Collapsible",
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "shippingRegionCodes" },
          },
          type: {
            kind: "ListType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "multiClientExperiments" },
          },
          type: {
            kind: "ListType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "Input_rql_ExperimentData" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "cpToListingRecs" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "limit" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "recsLimit" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "multiClientExperiments" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "multiClientExperiments" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "listingsSearchRequest" },
                      value: {
                        kind: "ObjectValue",
                        fields: [
                          {
                            kind: "ObjectField",
                            name: { kind: "Name", value: "limit" },
                            value: {
                              kind: "Variable",
                              name: { kind: "Name", value: "listingsLimit" },
                            },
                          },
                          {
                            kind: "ObjectField",
                            name: { kind: "Name", value: "sort" },
                            value: {
                              kind: "Variable",
                              name: { kind: "Name", value: "sort" },
                            },
                          },
                          {
                            kind: "ObjectField",
                            name: { kind: "Name", value: "collapsible" },
                            value: {
                              kind: "Variable",
                              name: { kind: "Name", value: "collapsible" },
                            },
                          },
                          {
                            kind: "ObjectField",
                            name: {
                              kind: "Name",
                              value: "shippingRegionCodes",
                            },
                            value: {
                              kind: "Variable",
                              name: {
                                kind: "Name",
                                value: "shippingRegionCodes",
                              },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "recommendationEngineInputIds" },
                },
                {
                  kind: "Field",
                  name: {
                    kind: "Name",
                    value: "recommendationEngineOutputIds",
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "searchResponse" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "limit" } },
                      { kind: "Field", name: { kind: "Name", value: "total" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "listings" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "_id" },
                            },
                            {
                              kind: "FragmentSpread",
                              name: {
                                kind: "Name",
                                value: "ListingsCollection",
                              },
                            },
                            {
                              kind: "FragmentSpread",
                              name: {
                                kind: "Name",
                                value: "mParticleListingFields",
                              },
                              directives: [
                                {
                                  kind: "Directive",
                                  name: { kind: "Name", value: "skip" },
                                  arguments: [
                                    {
                                      kind: "Argument",
                                      name: { kind: "Name", value: "if" },
                                      value: {
                                        kind: "Variable",
                                        name: {
                                          kind: "Name",
                                          value: "shouldSkipTracking",
                                        },
                                      },
                                    },
                                  ],
                                },
                              ],
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "AddToCartButtonFields" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Listing" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "sellerId" } },
          { kind: "Field", name: { kind: "Name", value: "listingType" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "pricing" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "buyerPrice" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "amount" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "amountCents" },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "preorderInfo" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "onPreorder" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "estimatedShipDate" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "seconds" },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "WatchBadgeData" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Listing" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "title" } },
          { kind: "Field", name: { kind: "Name", value: "sellerId" } },
          { kind: "Field", name: { kind: "Name", value: "watching" } },
          {
            kind: "Field",
            alias: { kind: "Name", value: "watchThumbnails" },
            name: { kind: "Name", value: "images" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "type" },
                      value: {
                        kind: "StringValue",
                        value: "Product",
                        block: false,
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "scope" },
                      value: {
                        kind: "StringValue",
                        value: "photos",
                        block: false,
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "transform" },
                      value: {
                        kind: "StringValue",
                        value: "card_square",
                        block: false,
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "count" },
                      value: { kind: "IntValue", value: "1" },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "source" } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "ListingGreatValueData" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Listing" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "pricing" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "buyerPrice" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "currency" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "amountCents" },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "condition" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "conditionSlug" },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "priceRecommendation" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "priceMiddle" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "amountCents" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "currency" },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "shop" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "address" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "country" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "_id" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "countryCode" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          { kind: "Field", name: { kind: "Name", value: "currency" } },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "mParticleListingFields" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Listing" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "title" } },
          { kind: "Field", name: { kind: "Name", value: "brandSlug" } },
          { kind: "Field", name: { kind: "Name", value: "categoryRootUuid" } },
          { kind: "Field", name: { kind: "Name", value: "make" } },
          { kind: "Field", name: { kind: "Name", value: "categoryUuids" } },
          { kind: "Field", name: { kind: "Name", value: "state" } },
          { kind: "Field", name: { kind: "Name", value: "listingType" } },
          { kind: "Field", name: { kind: "Name", value: "bumpEligible" } },
          { kind: "Field", name: { kind: "Name", value: "shopId" } },
          { kind: "Field", name: { kind: "Name", value: "inventory" } },
          { kind: "Field", name: { kind: "Name", value: "soldAsIs" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "acceptedPaymentMethods" },
          },
          { kind: "Field", name: { kind: "Name", value: "currency" } },
          { kind: "Field", name: { kind: "Name", value: "usOutlet" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "condition" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "conditionUuid" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "conditionSlug" },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "categories" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "slug" } },
                { kind: "Field", name: { kind: "Name", value: "rootSlug" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "csp" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "slug" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "brand" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      { kind: "Field", name: { kind: "Name", value: "slug" } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "pricing" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "buyerPrice" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "amount" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "currency" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "amountCents" },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "publishedAt" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "seconds" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "sale" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "code" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "buyerIneligibilityReason" },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "shipping" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "shippingPrices" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "shippingMethod" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "carrierCalculated" },
                      },
                      {
                        kind: "Field",
                        name: {
                          kind: "Name",
                          value: "destinationPostalCodeNeeded",
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "rate" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "amount" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "amountCents" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "currency" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "display" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "freeExpeditedShipping" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "localPickupOnly" },
                },
                { kind: "Field", name: { kind: "Name", value: "localPickup" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "certifiedPreOwned" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "title" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "badgeIconUrl" },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "shop" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "address" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "countryCode" },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "returnPolicy" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "newReturnWindowDays" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "usedReturnWindowDays" },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "ListingCreateOfferButtonData" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Listing" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "state" } },
          { kind: "Field", name: { kind: "Name", value: "offersEnabled" } },
          { kind: "Field", name: { kind: "Name", value: "listingType" } },
          { kind: "Field", name: { kind: "Name", value: "sellerId" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "isBuyerOfferEligible" },
          },
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "mParticleListingFields" },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "ListingImagesFields" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Listing" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "images" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "transform" },
                      value: {
                        kind: "StringValue",
                        value: "card_square",
                        block: false,
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "count" },
                      value: { kind: "IntValue", value: "3" },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "scope" },
                      value: {
                        kind: "StringValue",
                        value: "photos",
                        block: false,
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "type" },
                      value: {
                        kind: "StringValue",
                        value: "Product",
                        block: false,
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "source" } },
              ],
            },
          },
          {
            kind: "Field",
            alias: { kind: "Name", value: "largeSquareImages" },
            name: { kind: "Name", value: "images" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "transform" },
                      value: {
                        kind: "StringValue",
                        value: "large_square",
                        block: false,
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "count" },
                      value: { kind: "IntValue", value: "3" },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "scope" },
                      value: {
                        kind: "StringValue",
                        value: "photos",
                        block: false,
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "type" },
                      value: {
                        kind: "StringValue",
                        value: "Product",
                        block: false,
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "source" } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "ListingCardCPOData" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Listing" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "certifiedPreOwned" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "title" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "badgeIconUrl" },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "ListingsCollection" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Listing" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "usOutlet" } },
          { kind: "Field", name: { kind: "Name", value: "bumped" } },
          { kind: "Field", name: { kind: "Name", value: "categoryUuids" } },
          { kind: "Field", name: { kind: "Name", value: "slug" } },
          { kind: "Field", name: { kind: "Name", value: "title" } },
          { kind: "Field", name: { kind: "Name", value: "description" } },
          { kind: "Field", name: { kind: "Name", value: "listingType" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "condition" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "displayName" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "conditionSlug" },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "price" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "amountCents" } },
                { kind: "Field", name: { kind: "Name", value: "display" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "pricing" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "buyerPrice" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "display" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "currency" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "amountCents" },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "originalPrice" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "display" },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "ribbon" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "display" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "reason" },
                      },
                    ],
                  },
                },
              ],
            },
          },
          { kind: "Field", name: { kind: "Name", value: "watching" } },
          { kind: "Field", name: { kind: "Name", value: "state" } },
          { kind: "Field", name: { kind: "Name", value: "stateDescription" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "shipping" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "shippingPrices" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "shippingMethod" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "carrierCalculated" },
                      },
                      {
                        kind: "Field",
                        name: {
                          kind: "Name",
                          value: "destinationPostalCodeNeeded",
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "rate" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "amount" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "amountCents" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "currency" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "display" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "freeExpeditedShipping" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "localPickupOnly" },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "shop" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "address" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "country" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "_id" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "countryCode" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "name" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "AddToCartButtonFields" },
          },
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "WatchBadgeData" },
          },
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "ListingGreatValueData" },
          },
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "ListingCreateOfferButtonData" },
          },
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "ListingImagesFields" },
          },
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "ListingCardCPOData" },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CPListingsRecommendationsQuery,
  CPListingsRecommendationsQueryVariables
>;
export const LoggedOutRecommendationsDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "LoggedOutRecommendations" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "mpid" } },
          type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "loggedOutRecommendations" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "mpid" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "mpid" },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "resultIds" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "listings" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      {
                        kind: "FragmentSpread",
                        name: { kind: "Name", value: "ListingsCollection" },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "AddToCartButtonFields" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Listing" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "sellerId" } },
          { kind: "Field", name: { kind: "Name", value: "listingType" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "pricing" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "buyerPrice" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "amount" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "amountCents" },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "preorderInfo" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "onPreorder" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "estimatedShipDate" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "seconds" },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "WatchBadgeData" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Listing" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "title" } },
          { kind: "Field", name: { kind: "Name", value: "sellerId" } },
          { kind: "Field", name: { kind: "Name", value: "watching" } },
          {
            kind: "Field",
            alias: { kind: "Name", value: "watchThumbnails" },
            name: { kind: "Name", value: "images" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "type" },
                      value: {
                        kind: "StringValue",
                        value: "Product",
                        block: false,
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "scope" },
                      value: {
                        kind: "StringValue",
                        value: "photos",
                        block: false,
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "transform" },
                      value: {
                        kind: "StringValue",
                        value: "card_square",
                        block: false,
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "count" },
                      value: { kind: "IntValue", value: "1" },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "source" } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "ListingGreatValueData" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Listing" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "pricing" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "buyerPrice" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "currency" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "amountCents" },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "condition" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "conditionSlug" },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "priceRecommendation" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "priceMiddle" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "amountCents" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "currency" },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "shop" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "address" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "country" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "_id" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "countryCode" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          { kind: "Field", name: { kind: "Name", value: "currency" } },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "mParticleListingFields" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Listing" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "title" } },
          { kind: "Field", name: { kind: "Name", value: "brandSlug" } },
          { kind: "Field", name: { kind: "Name", value: "categoryRootUuid" } },
          { kind: "Field", name: { kind: "Name", value: "make" } },
          { kind: "Field", name: { kind: "Name", value: "categoryUuids" } },
          { kind: "Field", name: { kind: "Name", value: "state" } },
          { kind: "Field", name: { kind: "Name", value: "listingType" } },
          { kind: "Field", name: { kind: "Name", value: "bumpEligible" } },
          { kind: "Field", name: { kind: "Name", value: "shopId" } },
          { kind: "Field", name: { kind: "Name", value: "inventory" } },
          { kind: "Field", name: { kind: "Name", value: "soldAsIs" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "acceptedPaymentMethods" },
          },
          { kind: "Field", name: { kind: "Name", value: "currency" } },
          { kind: "Field", name: { kind: "Name", value: "usOutlet" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "condition" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "conditionUuid" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "conditionSlug" },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "categories" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "slug" } },
                { kind: "Field", name: { kind: "Name", value: "rootSlug" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "csp" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "slug" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "brand" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      { kind: "Field", name: { kind: "Name", value: "slug" } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "pricing" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "buyerPrice" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "amount" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "currency" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "amountCents" },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "publishedAt" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "seconds" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "sale" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "code" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "buyerIneligibilityReason" },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "shipping" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "shippingPrices" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "shippingMethod" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "carrierCalculated" },
                      },
                      {
                        kind: "Field",
                        name: {
                          kind: "Name",
                          value: "destinationPostalCodeNeeded",
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "rate" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "amount" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "amountCents" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "currency" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "display" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "freeExpeditedShipping" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "localPickupOnly" },
                },
                { kind: "Field", name: { kind: "Name", value: "localPickup" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "certifiedPreOwned" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "title" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "badgeIconUrl" },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "shop" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "address" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "countryCode" },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "returnPolicy" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "newReturnWindowDays" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "usedReturnWindowDays" },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "ListingCreateOfferButtonData" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Listing" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "state" } },
          { kind: "Field", name: { kind: "Name", value: "offersEnabled" } },
          { kind: "Field", name: { kind: "Name", value: "listingType" } },
          { kind: "Field", name: { kind: "Name", value: "sellerId" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "isBuyerOfferEligible" },
          },
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "mParticleListingFields" },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "ListingImagesFields" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Listing" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "images" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "transform" },
                      value: {
                        kind: "StringValue",
                        value: "card_square",
                        block: false,
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "count" },
                      value: { kind: "IntValue", value: "3" },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "scope" },
                      value: {
                        kind: "StringValue",
                        value: "photos",
                        block: false,
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "type" },
                      value: {
                        kind: "StringValue",
                        value: "Product",
                        block: false,
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "source" } },
              ],
            },
          },
          {
            kind: "Field",
            alias: { kind: "Name", value: "largeSquareImages" },
            name: { kind: "Name", value: "images" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "transform" },
                      value: {
                        kind: "StringValue",
                        value: "large_square",
                        block: false,
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "count" },
                      value: { kind: "IntValue", value: "3" },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "scope" },
                      value: {
                        kind: "StringValue",
                        value: "photos",
                        block: false,
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "type" },
                      value: {
                        kind: "StringValue",
                        value: "Product",
                        block: false,
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "source" } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "ListingCardCPOData" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Listing" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "certifiedPreOwned" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "title" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "badgeIconUrl" },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "ListingsCollection" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Listing" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "usOutlet" } },
          { kind: "Field", name: { kind: "Name", value: "bumped" } },
          { kind: "Field", name: { kind: "Name", value: "categoryUuids" } },
          { kind: "Field", name: { kind: "Name", value: "slug" } },
          { kind: "Field", name: { kind: "Name", value: "title" } },
          { kind: "Field", name: { kind: "Name", value: "description" } },
          { kind: "Field", name: { kind: "Name", value: "listingType" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "condition" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "displayName" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "conditionSlug" },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "price" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "amountCents" } },
                { kind: "Field", name: { kind: "Name", value: "display" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "pricing" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "buyerPrice" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "display" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "currency" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "amountCents" },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "originalPrice" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "display" },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "ribbon" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "display" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "reason" },
                      },
                    ],
                  },
                },
              ],
            },
          },
          { kind: "Field", name: { kind: "Name", value: "watching" } },
          { kind: "Field", name: { kind: "Name", value: "state" } },
          { kind: "Field", name: { kind: "Name", value: "stateDescription" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "shipping" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "shippingPrices" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "shippingMethod" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "carrierCalculated" },
                      },
                      {
                        kind: "Field",
                        name: {
                          kind: "Name",
                          value: "destinationPostalCodeNeeded",
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "rate" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "amount" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "amountCents" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "currency" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "display" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "freeExpeditedShipping" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "localPickupOnly" },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "shop" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "address" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "country" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "_id" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "countryCode" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "name" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "AddToCartButtonFields" },
          },
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "WatchBadgeData" },
          },
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "ListingGreatValueData" },
          },
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "ListingCreateOfferButtonData" },
          },
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "ListingImagesFields" },
          },
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "ListingCardCPOData" },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  LoggedOutRecommendationsQuery,
  LoggedOutRecommendationsQueryVariables
>;
export const Core_Listing_RecommendedShopsRowDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "Core_Listing_RecommendedShopsRow" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "itemRegion" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "itemRegionRelation" },
          },
          type: {
            kind: "NamedType",
            name: {
              kind: "Name",
              value: "reverb_search_ListingItemRegionRelation",
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "brandSlugs" },
          },
          type: {
            kind: "ListType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "categorySlugs" },
          },
          type: {
            kind: "ListType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "shopSlugs" },
          },
          type: {
            kind: "ListType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "listingsAggregationSearch" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "withAggregations" },
                      value: {
                        kind: "ListValue",
                        values: [{ kind: "EnumValue", value: "SHOP_ID" }],
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "preferredSeller" },
                      value: { kind: "BooleanValue", value: true },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "brandSlugs" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "brandSlugs" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "categorySlugs" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "categorySlugs" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "itemRegion" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "itemRegion" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "itemRegionRelation" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "itemRegionRelation" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "shopSlugs" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "shopSlugs" },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "shops" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      { kind: "Field", name: { kind: "Name", value: "slug" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "address" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "_id" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "countryCode" },
                            },
                          ],
                        },
                      },
                      {
                        kind: "FragmentSpread",
                        name: { kind: "Name", value: "ShopCardFields" },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "ShopCardFields" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Shop" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "name" } },
          { kind: "Field", name: { kind: "Name", value: "preferredSeller" } },
          { kind: "Field", name: { kind: "Name", value: "quickShipper" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "address" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "displayLocation" },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "user" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "avatar" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "source" },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "feedbackSummary" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: {
                          kind: "Name",
                          value: "rollingRatingPercentage",
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "receivedCount" },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Core_Listing_RecommendedShopsRowQuery,
  Core_Listing_RecommendedShopsRowQueryVariables
>;
export const Core_SellFlow_Matched_SearchDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "Core_SellFlow_Matched_Search" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "q" } },
          type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "decades" },
          },
          type: {
            kind: "ListType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "finishes" },
          },
          type: {
            kind: "ListType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "brandNames" },
          },
          type: {
            kind: "ListType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "category_uuids" },
          },
          type: {
            kind: "ListType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "sellCardLimit" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "Int" } },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "excludedCategoryUuids" },
          },
          type: {
            kind: "ListType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "boostByClicks" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "Boolean" } },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "fullTextQueryOperand" },
          },
          type: {
            kind: "NamedType",
            name: { kind: "Name", value: "reverb_search_FullTextQueryOperand" },
          },
        },
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "sort" } },
          type: {
            kind: "NamedType",
            name: {
              kind: "Name",
              value: "reverb_search_CSPSearchRequest_Sort",
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "fuzzy" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "Boolean" } },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "offset" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "Int" } },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "listingsThatShipTo" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "hasExpressSaleBid" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "Boolean" },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "includePriceRecommendations" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "Boolean" },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "priceRecommendationCountryCode" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "cspSearch" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "fullTextQuery" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "q" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "decades" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "decades" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "finishes" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "finishes" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "brandNames" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "brandNames" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "categoryUuids" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "category_uuids" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "withAggregations" },
                      value: {
                        kind: "ListValue",
                        values: [
                          { kind: "EnumValue", value: "CATEGORY_UUIDS" },
                          { kind: "EnumValue", value: "FINISHES" },
                          { kind: "EnumValue", value: "DECADES" },
                          { kind: "EnumValue", value: "BRAND_NAMES" },
                        ],
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "excludedCategoryUuids" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "excludedCategoryUuids" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "limit" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "sellCardLimit" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "offset" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "offset" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "boostByClicks" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "boostByClicks" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "fullTextQueryOperand" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "fullTextQueryOperand" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "sort" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "sort" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "fuzzy" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "fuzzy" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "listingsThatShipTo" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "listingsThatShipTo" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "hasExpressSaleBid" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "hasExpressSaleBid" },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "csps" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      {
                        kind: "FragmentSpread",
                        name: { kind: "Name", value: "SellCardData" },
                      },
                      {
                        kind: "FragmentSpread",
                        name: {
                          kind: "Name",
                          value: "CSPPriceRecommendationData",
                        },
                        directives: [
                          {
                            kind: "Directive",
                            name: { kind: "Name", value: "include" },
                            arguments: [
                              {
                                kind: "Argument",
                                name: { kind: "Name", value: "if" },
                                value: {
                                  kind: "Variable",
                                  name: {
                                    kind: "Name",
                                    value: "includePriceRecommendations",
                                  },
                                },
                              },
                            ],
                          },
                        ],
                      },
                      {
                        kind: "FragmentSpread",
                        name: { kind: "Name", value: "ExpressSaleItemBidData" },
                        directives: [
                          {
                            kind: "Directive",
                            name: { kind: "Name", value: "include" },
                            arguments: [
                              {
                                kind: "Argument",
                                name: { kind: "Name", value: "if" },
                                value: {
                                  kind: "Variable",
                                  name: {
                                    kind: "Name",
                                    value: "hasExpressSaleBid",
                                  },
                                },
                              },
                            ],
                          },
                        ],
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "filters" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "FragmentSpread",
                        name: { kind: "Name", value: "NoCountFilterFragment" },
                      },
                    ],
                  },
                },
                { kind: "Field", name: { kind: "Name", value: "total" } },
                { kind: "Field", name: { kind: "Name", value: "offset" } },
                { kind: "Field", name: { kind: "Name", value: "limit" } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "SellCardData" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "CSP" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "title" } },
          { kind: "Field", name: { kind: "Name", value: "finishes" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "image" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "transform" },
                      value: {
                        kind: "StringValue",
                        value: "card_square",
                        block: false,
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "source" } },
              ],
            },
          },
          { kind: "Field", name: { kind: "Name", value: "slug" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "brand" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "name" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "canonicalProductIds" },
          },
          { kind: "Field", name: { kind: "Name", value: "isTradeInEligible" } },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "CSPPriceRecommendationData" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "CSP" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "priceRecommendations" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "conditionUuids" },
                      value: {
                        kind: "ListValue",
                        values: [
                          {
                            kind: "StringValue",
                            value: "f7a3f48c-972a-44c6-b01a-0cd27488d3f6",
                            block: false,
                          },
                          {
                            kind: "StringValue",
                            value: "ac5b9c1e-dc78-466d-b0b3-7cf712967a48",
                            block: false,
                          },
                        ],
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "countryCode" },
                      value: {
                        kind: "Variable",
                        name: {
                          kind: "Name",
                          value: "priceRecommendationCountryCode",
                        },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "conditionUuid" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "priceLow" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "amountCents" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "amount" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "currency" },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "priceHigh" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "amountCents" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "amount" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "currency" },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "ExpressSaleItemBidData" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "CSP" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "expressSalePriceEstimate" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "conditionUuid" },
                      value: {
                        kind: "StringValue",
                        value: "ae4d9114-1bd7-4ec5-a4ba-6653af5ac84d",
                        block: false,
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "priceLow" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "amountCents" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "currency" },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "priceHigh" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "amountCents" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "currency" },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "expressSaleItemBid" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "cspUuid" } },
                { kind: "Field", name: { kind: "Name", value: "bidId" } },
                { kind: "Field", name: { kind: "Name", value: "carrier" } },
                { kind: "Field", name: { kind: "Name", value: "shopName" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "estimatedPayout" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "display" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "amount" },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "NoCountFilterFragment" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "reverb_search_Filter" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "name" } },
          { kind: "Field", name: { kind: "Name", value: "key" } },
          { kind: "Field", name: { kind: "Name", value: "aggregationName" } },
          { kind: "Field", name: { kind: "Name", value: "widgetType" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "options" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "name" } },
                { kind: "Field", name: { kind: "Name", value: "selected" } },
                { kind: "Field", name: { kind: "Name", value: "paramName" } },
                { kind: "Field", name: { kind: "Name", value: "setValues" } },
                { kind: "Field", name: { kind: "Name", value: "unsetValues" } },
                { kind: "Field", name: { kind: "Name", value: "all" } },
                { kind: "Field", name: { kind: "Name", value: "optionValue" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Core_SellFlow_Matched_SearchQuery,
  Core_SellFlow_Matched_SearchQueryVariables
>;
export const Core_SellFlow_Unmatched_SearchDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "Core_SellFlow_Unmatched_Search" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "q" } },
          type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "decades" },
          },
          type: {
            kind: "ListType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "canonicalFinishes" },
          },
          type: {
            kind: "ListType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "categorySlugs" },
          },
          type: {
            kind: "ListType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "brandNames" },
          },
          type: {
            kind: "ListType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "category_uuids" },
          },
          type: {
            kind: "ListType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "excludedCategoryUuids" },
          },
          type: {
            kind: "ListType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "offset" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "Int" } },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "limit" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "Int" } },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "cspSearch" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "fullTextQuery" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "q" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "decades" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "decades" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "finishes" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "canonicalFinishes" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "brandNames" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "brandNames" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "categoryUuids" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "category_uuids" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "withAggregations" },
                      value: {
                        kind: "ListValue",
                        values: [
                          { kind: "EnumValue", value: "CATEGORY_UUIDS" },
                          { kind: "EnumValue", value: "FINISHES" },
                          { kind: "EnumValue", value: "DECADES" },
                          { kind: "EnumValue", value: "BRAND_NAMES" },
                        ],
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "excludedCategoryUuids" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "excludedCategoryUuids" },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "filters" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "FragmentSpread",
                        name: { kind: "Name", value: "NoCountFilterFragment" },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "listingsSearch" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "query" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "q" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "offset" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "offset" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "limit" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "limit" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "decades" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "decades" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "showOnlySold" },
                      value: { kind: "BooleanValue", value: true },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "canonicalFinishes" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "canonicalFinishes" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "categorySlugs" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "categorySlugs" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "excludeCategoryUuids" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "excludedCategoryUuids" },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "total" } },
                { kind: "Field", name: { kind: "Name", value: "offset" } },
                { kind: "Field", name: { kind: "Name", value: "limit" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "listings" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "FragmentSpread",
                        name: { kind: "Name", value: "ListingSellCardData" },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "NoCountFilterFragment" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "reverb_search_Filter" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "name" } },
          { kind: "Field", name: { kind: "Name", value: "key" } },
          { kind: "Field", name: { kind: "Name", value: "aggregationName" } },
          { kind: "Field", name: { kind: "Name", value: "widgetType" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "options" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "name" } },
                { kind: "Field", name: { kind: "Name", value: "selected" } },
                { kind: "Field", name: { kind: "Name", value: "paramName" } },
                { kind: "Field", name: { kind: "Name", value: "setValues" } },
                { kind: "Field", name: { kind: "Name", value: "unsetValues" } },
                { kind: "Field", name: { kind: "Name", value: "all" } },
                { kind: "Field", name: { kind: "Name", value: "optionValue" } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "ListingSellCardData" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Listing" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "title" } },
          { kind: "Field", name: { kind: "Name", value: "finish" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "canonicalProductId" },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "images" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "transform" },
                      value: {
                        kind: "StringValue",
                        value: "card_square",
                        block: false,
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "count" },
                      value: { kind: "IntValue", value: "3" },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "scope" },
                      value: {
                        kind: "StringValue",
                        value: "photos",
                        block: false,
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "type" },
                      value: {
                        kind: "StringValue",
                        value: "Product",
                        block: false,
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "source" } },
                { kind: "Field", name: { kind: "Name", value: "__typename" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "price" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "display" } },
              ],
            },
          },
          { kind: "Field", name: { kind: "Name", value: "make" } },
          { kind: "Field", name: { kind: "Name", value: "slug" } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Core_SellFlow_Unmatched_SearchQuery,
  Core_SellFlow_Unmatched_SearchQueryVariables
>;
export const Core_CrossPlatform_MobileFetchPhotosDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "Core_CrossPlatform_MobileFetchPhotos" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "listingId" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "listing" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "id" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "listingId" },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "images" },
                  arguments: [
                    {
                      kind: "Argument",
                      name: { kind: "Name", value: "input" },
                      value: {
                        kind: "ObjectValue",
                        fields: [
                          {
                            kind: "ObjectField",
                            name: { kind: "Name", value: "type" },
                            value: {
                              kind: "StringValue",
                              value: "Product",
                              block: false,
                            },
                          },
                          {
                            kind: "ObjectField",
                            name: { kind: "Name", value: "scope" },
                            value: {
                              kind: "StringValue",
                              value: "photos",
                              block: false,
                            },
                          },
                          {
                            kind: "ObjectField",
                            name: { kind: "Name", value: "transform" },
                            value: {
                              kind: "StringValue",
                              value: "card_square",
                              block: false,
                            },
                          },
                        ],
                      },
                    },
                  ],
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "source" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "itemId" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "version" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "itemType" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "publicId" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "height" },
                      },
                      { kind: "Field", name: { kind: "Name", value: "width" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "transformation" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "x" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "y" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "width" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "height" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "crop" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "angle" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Core_CrossPlatform_MobileFetchPhotosQuery,
  Core_CrossPlatform_MobileFetchPhotosQueryVariables
>;
export const Core_CrossPlatform_MobileStartSessionDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "Core_CrossPlatform_MobileStartSession" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "listingId" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "updateCrossPlatformListingSession" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "action" },
                      value: { kind: "EnumValue", value: "START" },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "listingId" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "listingId" },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Core_CrossPlatform_MobileStartSessionMutation,
  Core_CrossPlatform_MobileStartSessionMutationVariables
>;
export const Core_CrossPlatform_MobileCancelSessionDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "Core_CrossPlatform_MobileCancelSession" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "listingId" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "updateCrossPlatformListingSession" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "action" },
                      value: { kind: "EnumValue", value: "CANCEL" },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "listingId" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "listingId" },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Core_CrossPlatform_MobileCancelSessionMutation,
  Core_CrossPlatform_MobileCancelSessionMutationVariables
>;
export const Core_CrossPlatform_MobileFetchStatusDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "Core_CrossPlatform_MobileFetchStatus" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "listingId" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "crossPlatformListingStatus" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "listingId" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "listingId" },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "status" } },
                { kind: "Field", name: { kind: "Name", value: "ttlSeconds" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Core_CrossPlatform_MobileFetchStatusQuery,
  Core_CrossPlatform_MobileFetchStatusQueryVariables
>;
export const Core_CrossPlatform_MobileSubmitPhotosDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "Core_CrossPlatform_MobileSubmitPhotos" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "input" },
          },
          type: {
            kind: "NamedType",
            name: {
              kind: "Name",
              value: "Input_core_apimessages_UpdateListingRequest",
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "crupdateListing" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "input" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Core_CrossPlatform_MobileSubmitPhotosMutation,
  Core_CrossPlatform_MobileSubmitPhotosMutationVariables
>;
export const Core_SellForm_SubmitShopDataDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "Core_SellForm_SubmitShopData" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "input" },
          },
          type: {
            kind: "NamedType",
            name: {
              kind: "Name",
              value: "Input_core_apimessages_UpdateMyShopRequest",
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "updateMyShop" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "input" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "shop" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "address" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Core_SellForm_SubmitShopDataMutation,
  Core_SellForm_SubmitShopDataMutationVariables
>;
export const Core_SellForm_SetupPageDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "Core_SellForm_SetupPage" },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "me" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "uuid" } },
                {
                  kind: "FragmentSpread",
                  name: { kind: "Name", value: "SellFormSetupData" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "shippingAddresses" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      {
                        kind: "FragmentSpread",
                        name: { kind: "Name", value: "ShopAddressFields" },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "countries" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "countries" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      { kind: "Field", name: { kind: "Name", value: "name" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "countryCode" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "subregionRequired" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "subregions" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "_id" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "code" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "name" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "ShopAddressFields" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "core_apimessages_Address" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "primary" } },
          { kind: "Field", name: { kind: "Name", value: "name" } },
          { kind: "Field", name: { kind: "Name", value: "streetAddress" } },
          { kind: "Field", name: { kind: "Name", value: "extendedAddress" } },
          { kind: "Field", name: { kind: "Name", value: "region" } },
          { kind: "Field", name: { kind: "Name", value: "locality" } },
          { kind: "Field", name: { kind: "Name", value: "postalCode" } },
          { kind: "Field", name: { kind: "Name", value: "phone" } },
          { kind: "Field", name: { kind: "Name", value: "countryCode" } },
          { kind: "Field", name: { kind: "Name", value: "isComplete" } },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "SellFormSetupData" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "rql_Me" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "uuid" } },
          { kind: "Field", name: { kind: "Name", value: "firstName" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "shop" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "name" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "currencySetting" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "defaultLocale" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "address" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      {
                        kind: "FragmentSpread",
                        name: { kind: "Name", value: "ShopAddressFields" },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Core_SellForm_SetupPageQuery,
  Core_SellForm_SetupPageQueryVariables
>;
export const Core_Trade_In_CSPDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "Core_Trade_In_CSP" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "cspId" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "priceRecommendationCountryCode" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            alias: { kind: "Name", value: "cspEligibility" },
            name: { kind: "Name", value: "tradeInEligibility" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "cspId" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "cspId" },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "appraisals" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "price" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "amountCents" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "currency" },
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "condition" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "conditionUuid" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "dealers" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      { kind: "Field", name: { kind: "Name", value: "name" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "user" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "_id" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "avatar" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "_id" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "source" },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "csp" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "id" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "cspId" },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "image" },
                  arguments: [
                    {
                      kind: "Argument",
                      name: { kind: "Name", value: "input" },
                      value: {
                        kind: "ObjectValue",
                        fields: [
                          {
                            kind: "ObjectField",
                            name: { kind: "Name", value: "transform" },
                            value: {
                              kind: "StringValue",
                              value: "card_square",
                              block: false,
                            },
                          },
                        ],
                      },
                    },
                  ],
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "source" },
                      },
                    ],
                  },
                },
                { kind: "Field", name: { kind: "Name", value: "title" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "brand" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      { kind: "Field", name: { kind: "Name", value: "name" } },
                    ],
                  },
                },
                { kind: "Field", name: { kind: "Name", value: "finishes" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "canonicalProducts" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      { kind: "Field", name: { kind: "Name", value: "name" } },
                    ],
                  },
                },
                {
                  kind: "FragmentSpread",
                  name: { kind: "Name", value: "CSPPriceRecommendationData" },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "CSPPriceRecommendationData" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "CSP" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "priceRecommendations" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "conditionUuids" },
                      value: {
                        kind: "ListValue",
                        values: [
                          {
                            kind: "StringValue",
                            value: "f7a3f48c-972a-44c6-b01a-0cd27488d3f6",
                            block: false,
                          },
                          {
                            kind: "StringValue",
                            value: "ac5b9c1e-dc78-466d-b0b3-7cf712967a48",
                            block: false,
                          },
                        ],
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "countryCode" },
                      value: {
                        kind: "Variable",
                        name: {
                          kind: "Name",
                          value: "priceRecommendationCountryCode",
                        },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "conditionUuid" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "priceLow" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "amountCents" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "amount" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "currency" },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "priceHigh" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "amountCents" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "amount" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "currency" },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Core_Trade_In_CSPQuery,
  Core_Trade_In_CSPQueryVariables
>;
export const Core_SellerQuickStartRow_MyDraftsSearchDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "Core_SellerQuickStartRow_MyDraftsSearch" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "limit" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "Int" } },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "me" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "shop" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "listings" },
                        arguments: [
                          {
                            kind: "Argument",
                            name: { kind: "Name", value: "input" },
                            value: {
                              kind: "ObjectValue",
                              fields: [
                                {
                                  kind: "ObjectField",
                                  name: {
                                    kind: "Name",
                                    value: "shippingRegionCodes",
                                  },
                                  value: {
                                    kind: "ListValue",
                                    values: [
                                      {
                                        kind: "StringValue",
                                        value: "XX",
                                        block: false,
                                      },
                                    ],
                                  },
                                },
                                {
                                  kind: "ObjectField",
                                  name: { kind: "Name", value: "itemRegion" },
                                  value: {
                                    kind: "StringValue",
                                    value: "XX",
                                    block: false,
                                  },
                                },
                                {
                                  kind: "ObjectField",
                                  name: { kind: "Name", value: "statuses" },
                                  value: {
                                    kind: "ListValue",
                                    values: [
                                      {
                                        kind: "StringValue",
                                        value: "draft",
                                        block: false,
                                      },
                                    ],
                                  },
                                },
                                {
                                  kind: "ObjectField",
                                  name: { kind: "Name", value: "limit" },
                                  value: {
                                    kind: "Variable",
                                    name: { kind: "Name", value: "limit" },
                                  },
                                },
                                {
                                  kind: "ObjectField",
                                  name: { kind: "Name", value: "sort" },
                                  value: {
                                    kind: "EnumValue",
                                    value: "CREATED_AT_DESC",
                                  },
                                },
                              ],
                            },
                          },
                        ],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "listings" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "_id" },
                                  },
                                  {
                                    kind: "FragmentSpread",
                                    name: {
                                      kind: "Name",
                                      value: "SellSearchRowListing",
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "SellSearchRowListing" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Listing" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "title" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "images" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "transform" },
                      value: {
                        kind: "StringValue",
                        value: "card_square",
                        block: false,
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "type" },
                      value: {
                        kind: "StringValue",
                        value: "Product",
                        block: false,
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "count" },
                      value: { kind: "IntValue", value: "3" },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "scope" },
                      value: {
                        kind: "StringValue",
                        value: "photos",
                        block: false,
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "source" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Core_SellerQuickStartRow_MyDraftsSearchQuery,
  Core_SellerQuickStartRow_MyDraftsSearchQueryVariables
>;
export const Core_SellerQuickStartRow_MyGearCollectionSearchDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: {
        kind: "Name",
        value: "Core_SellerQuickStartRow_MyGearCollectionSearch",
      },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "limit" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "Int" } },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "gearCollectionItemsSearch" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "as" },
                      value: { kind: "EnumValue", value: "OWNER" },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "searchInput" },
                      value: {
                        kind: "ObjectValue",
                        fields: [
                          {
                            kind: "ObjectField",
                            name: { kind: "Name", value: "limit" },
                            value: {
                              kind: "Variable",
                              name: { kind: "Name", value: "limit" },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "gearCollectionItems" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      { kind: "Field", name: { kind: "Name", value: "title" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "hideListingPhotos" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "images" },
                        arguments: [
                          {
                            kind: "Argument",
                            name: { kind: "Name", value: "input" },
                            value: {
                              kind: "ObjectValue",
                              fields: [
                                {
                                  kind: "ObjectField",
                                  name: { kind: "Name", value: "transform" },
                                  value: {
                                    kind: "StringValue",
                                    value: "card_square",
                                    block: false,
                                  },
                                },
                                {
                                  kind: "ObjectField",
                                  name: { kind: "Name", value: "type" },
                                  value: {
                                    kind: "StringValue",
                                    value: "Product",
                                    block: false,
                                  },
                                },
                                {
                                  kind: "ObjectField",
                                  name: { kind: "Name", value: "count" },
                                  value: { kind: "IntValue", value: "3" },
                                },
                                {
                                  kind: "ObjectField",
                                  name: { kind: "Name", value: "scope" },
                                  value: {
                                    kind: "StringValue",
                                    value: "photos",
                                    block: false,
                                  },
                                },
                              ],
                            },
                          },
                        ],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "_id" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "source" },
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "listing" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "_id" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "originalListing" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "_id" },
                            },
                            {
                              kind: "FragmentSpread",
                              name: {
                                kind: "Name",
                                value: "SellSearchRowListing",
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "csp" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "_id" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "title" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "image" },
                              arguments: [
                                {
                                  kind: "Argument",
                                  name: { kind: "Name", value: "input" },
                                  value: {
                                    kind: "ObjectValue",
                                    fields: [
                                      {
                                        kind: "ObjectField",
                                        name: {
                                          kind: "Name",
                                          value: "transform",
                                        },
                                        value: {
                                          kind: "StringValue",
                                          value: "card_square",
                                          block: false,
                                        },
                                      },
                                    ],
                                  },
                                },
                              ],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "_id" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "source" },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "SellSearchRowListing" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Listing" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "title" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "images" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "transform" },
                      value: {
                        kind: "StringValue",
                        value: "card_square",
                        block: false,
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "type" },
                      value: {
                        kind: "StringValue",
                        value: "Product",
                        block: false,
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "count" },
                      value: { kind: "IntValue", value: "3" },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "scope" },
                      value: {
                        kind: "StringValue",
                        value: "photos",
                        block: false,
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "source" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Core_SellerQuickStartRow_MyGearCollectionSearchQuery,
  Core_SellerQuickStartRow_MyGearCollectionSearchQueryVariables
>;
export const Core_SellerQuickStartRow_MyBuyerOrderSearchDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: {
        kind: "Name",
        value: "Core_SellerQuickStartRow_MyBuyerOrderSearch",
      },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "limit" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "Int" } },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "ordersSearch" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "as" },
                      value: { kind: "EnumValue", value: "BUYER" },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "searchFilters" },
                      value: {
                        kind: "ObjectValue",
                        fields: [
                          {
                            kind: "ObjectField",
                            name: { kind: "Name", value: "actionableStatuses" },
                            value: {
                              kind: "ListValue",
                              values: [
                                {
                                  kind: "StringValue",
                                  value: "shipped",
                                  block: false,
                                },
                                {
                                  kind: "StringValue",
                                  value: "received",
                                  block: false,
                                },
                                {
                                  kind: "StringValue",
                                  value: "picked_up",
                                  block: false,
                                },
                              ],
                            },
                          },
                          {
                            kind: "ObjectField",
                            name: { kind: "Name", value: "limit" },
                            value: {
                              kind: "Variable",
                              name: { kind: "Name", value: "limit" },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "orders" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "legacyOrderId" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "listing" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "_id" },
                            },
                            {
                              kind: "FragmentSpread",
                              name: {
                                kind: "Name",
                                value: "SellSearchRowListing",
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "protectionPlan" },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "SellSearchRowListing" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Listing" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "title" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "images" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "transform" },
                      value: {
                        kind: "StringValue",
                        value: "card_square",
                        block: false,
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "type" },
                      value: {
                        kind: "StringValue",
                        value: "Product",
                        block: false,
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "count" },
                      value: { kind: "IntValue", value: "3" },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "scope" },
                      value: {
                        kind: "StringValue",
                        value: "photos",
                        block: false,
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "source" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Core_SellerQuickStartRow_MyBuyerOrderSearchQuery,
  Core_SellerQuickStartRow_MyBuyerOrderSearchQueryVariables
>;
export const Core_SellForm_EditListingLayoutDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "Core_SellForm_EditListingLayout" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "listingId" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "me" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                {
                  kind: "FragmentSpread",
                  name: { kind: "Name", value: "SellFormSetupData" },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "listing" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "id" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "listingId" },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                {
                  kind: "FragmentSpread",
                  name: { kind: "Name", value: "HydrateSellForm" },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "countries" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "FragmentSpread",
                  name: { kind: "Name", value: "SellFormViewCountryFields" },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "ShopAddressFields" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "core_apimessages_Address" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "primary" } },
          { kind: "Field", name: { kind: "Name", value: "name" } },
          { kind: "Field", name: { kind: "Name", value: "streetAddress" } },
          { kind: "Field", name: { kind: "Name", value: "extendedAddress" } },
          { kind: "Field", name: { kind: "Name", value: "region" } },
          { kind: "Field", name: { kind: "Name", value: "locality" } },
          { kind: "Field", name: { kind: "Name", value: "postalCode" } },
          { kind: "Field", name: { kind: "Name", value: "phone" } },
          { kind: "Field", name: { kind: "Name", value: "countryCode" } },
          { kind: "Field", name: { kind: "Name", value: "isComplete" } },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "ShippingProfilePrices" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Shop" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "shippingProfiles" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "name" } },
                { kind: "Field", name: { kind: "Name", value: "id" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "categoryUuids" },
                },
                { kind: "Field", name: { kind: "Name", value: "localPickup" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "allShippingRates" },
                  arguments: [
                    {
                      kind: "Argument",
                      name: { kind: "Name", value: "input" },
                      value: {
                        kind: "ObjectValue",
                        fields: [
                          {
                            kind: "ObjectField",
                            name: { kind: "Name", value: "inOriginalCurrency" },
                            value: { kind: "BooleanValue", value: true },
                          },
                        ],
                      },
                    },
                  ],
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "regionCode" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "regionName" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "rateType" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "rate" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "display" },
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "incrementalRate" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "display" },
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "expeditedRate" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "display" },
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "regionalRates" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "areas" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "rate" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "display" },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "regionalExpeditedRates" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "areas" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "rate" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "display" },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "SellFormShopShipping" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Shop" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "currencySetting" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "sameDayShippingConfigured" },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "shippingRegionSettings" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "regionCode" } },
                { kind: "Field", name: { kind: "Name", value: "regionName" } },
                { kind: "Field", name: { kind: "Name", value: "isDomestic" } },
                {
                  kind: "Field",
                  name: {
                    kind: "Name",
                    value: "allowedToShipCarrierCalculated",
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "address" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "region" } },
                { kind: "Field", name: { kind: "Name", value: "postalCode" } },
                { kind: "Field", name: { kind: "Name", value: "countryCode" } },
              ],
            },
          },
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "ShippingProfilePrices" },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "SellFormSetupData" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "rql_Me" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "uuid" } },
          { kind: "Field", name: { kind: "Name", value: "firstName" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "shop" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "name" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "currencySetting" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "defaultLocale" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "address" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      {
                        kind: "FragmentSpread",
                        name: { kind: "Name", value: "ShopAddressFields" },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "HydrateSellForm" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Listing" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "state" } },
          { kind: "Field", name: { kind: "Name", value: "sku" } },
          { kind: "Field", name: { kind: "Name", value: "year" } },
          { kind: "Field", name: { kind: "Name", value: "make" } },
          { kind: "Field", name: { kind: "Name", value: "countryOfOrigin" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "canonicalProductId" },
          },
          { kind: "Field", name: { kind: "Name", value: "subcategoryUuids" } },
          { kind: "Field", name: { kind: "Name", value: "categoryRootUuid" } },
          { kind: "Field", name: { kind: "Name", value: "seedId" } },
          { kind: "Field", name: { kind: "Name", value: "seedType" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "publishedAt" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "seconds" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "categories" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "name" } },
                { kind: "Field", name: { kind: "Name", value: "root" } },
                { kind: "Field", name: { kind: "Name", value: "leaf" } },
                { kind: "Field", name: { kind: "Name", value: "rootSlug" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "localizedContents" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "locale" } },
                { kind: "Field", name: { kind: "Name", value: "make" } },
                { kind: "Field", name: { kind: "Name", value: "model" } },
                { kind: "Field", name: { kind: "Name", value: "finish" } },
                { kind: "Field", name: { kind: "Name", value: "title" } },
                { kind: "Field", name: { kind: "Name", value: "description" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "prop65Warning" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "defaultForShop" },
                },
              ],
            },
          },
          { kind: "Field", name: { kind: "Name", value: "handmade" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "traits" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "keyId" } },
                { kind: "Field", name: { kind: "Name", value: "valueId" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "condition" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "conditionSlug" },
                },
              ],
            },
          },
          { kind: "Field", name: { kind: "Name", value: "hasInventory" } },
          { kind: "Field", name: { kind: "Name", value: "inventory" } },
          { kind: "Field", name: { kind: "Name", value: "upc" } },
          { kind: "Field", name: { kind: "Name", value: "upcDoesNotApply" } },
          { kind: "Field", name: { kind: "Name", value: "taxExempt" } },
          { kind: "Field", name: { kind: "Name", value: "relistable" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "video" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "link" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "bumpRate" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "rate" } },
              ],
            },
          },
          { kind: "Field", name: { kind: "Name", value: "bumpEligible" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "images" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "type" },
                      value: {
                        kind: "StringValue",
                        value: "Product",
                        block: false,
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "scope" },
                      value: {
                        kind: "StringValue",
                        value: "photos",
                        block: false,
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "transform" },
                      value: {
                        kind: "StringValue",
                        value: "card_square",
                        block: false,
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "source" } },
                { kind: "Field", name: { kind: "Name", value: "itemId" } },
                { kind: "Field", name: { kind: "Name", value: "version" } },
                { kind: "Field", name: { kind: "Name", value: "itemType" } },
                { kind: "Field", name: { kind: "Name", value: "publicId" } },
                { kind: "Field", name: { kind: "Name", value: "height" } },
                { kind: "Field", name: { kind: "Name", value: "width" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "transformation" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "x" } },
                      { kind: "Field", name: { kind: "Name", value: "y" } },
                      { kind: "Field", name: { kind: "Name", value: "width" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "height" },
                      },
                      { kind: "Field", name: { kind: "Name", value: "crop" } },
                      { kind: "Field", name: { kind: "Name", value: "angle" } },
                    ],
                  },
                },
              ],
            },
          },
          { kind: "Field", name: { kind: "Name", value: "shippingProfileId" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "allShippingPrices" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "localPickup" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "shippingPrices" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "shippingRegionCode" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "shippingMethod" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "carrierCalculated" },
                      },
                      {
                        kind: "Field",
                        name: {
                          kind: "Name",
                          value: "originalRateVatExcluded",
                        },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "amount" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "currency" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "shipmentPackage" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "length" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "value" } },
                      { kind: "Field", name: { kind: "Name", value: "unit" } },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "width" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "value" } },
                      { kind: "Field", name: { kind: "Name", value: "unit" } },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "height" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "value" } },
                      { kind: "Field", name: { kind: "Name", value: "unit" } },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "weight" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "value" } },
                      { kind: "Field", name: { kind: "Name", value: "unit" } },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "allowedCarriers" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "packageSizeSuggestionId" },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "prefersReverbShippingLabel" },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "sellerPrice" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "amountCents" } },
                { kind: "Field", name: { kind: "Name", value: "currency" } },
                { kind: "Field", name: { kind: "Name", value: "amount" } },
                { kind: "Field", name: { kind: "Name", value: "display" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "sellerCost" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "amountCents" } },
                { kind: "Field", name: { kind: "Name", value: "currency" } },
                { kind: "Field", name: { kind: "Name", value: "amount" } },
                { kind: "Field", name: { kind: "Name", value: "display" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "sellerReportedMap" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "amountCents" } },
                { kind: "Field", name: { kind: "Name", value: "currency" } },
                { kind: "Field", name: { kind: "Name", value: "amount" } },
                { kind: "Field", name: { kind: "Name", value: "display" } },
              ],
            },
          },
          { kind: "Field", name: { kind: "Name", value: "vatIncluded" } },
          { kind: "Field", name: { kind: "Name", value: "vatIncludedHint" } },
          { kind: "Field", name: { kind: "Name", value: "soldAsIs" } },
          { kind: "Field", name: { kind: "Name", value: "listingType" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "overriddenAttributes" },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "preorderInfo" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "shipDate" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "seconds" },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "leadTimeDays" },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "shop" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "currencySetting" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "defaultLocale" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "shopSupportedLocaleCodes" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "preferredSeller" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "hasAccountRep" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "inclusiveVatPricingEnabled" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "listableConditions" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "displayName" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "conditionSlug" },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "offerPolicy" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "autoRejectLowOffers" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "lowballPercentage" },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "returnPolicy" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "newReturnWindowDays" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "usedReturnWindowDays" },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "taxPolicies" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "vatPolicy" },
                      },
                    ],
                  },
                },
                { kind: "Field", name: { kind: "Name", value: "vatId" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "firstTimeSeller" },
                },
                {
                  kind: "FragmentSpread",
                  name: { kind: "Name", value: "SellFormShopShipping" },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "scheduledPriceDrops" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "active" } },
                { kind: "Field", name: { kind: "Name", value: "dropAt" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "newPrice" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "amountCents" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "currency" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "amount" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "display" },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "SellFormViewCountryFields" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "core_apimessages_CountriesResponse" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "countries" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "name" } },
                { kind: "Field", name: { kind: "Name", value: "countryCode" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Core_SellForm_EditListingLayoutQuery,
  Core_SellForm_EditListingLayoutQueryVariables
>;
export const Core_SellForm_NewListingLayoutDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "Core_SellForm_NewListingLayout" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "shouldSeedListingData" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "Boolean" },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "seedId" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "seedType" },
          },
          type: {
            kind: "NamedType",
            name: { kind: "Name", value: "core_apimessages_Listing_SeedType" },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "me" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "shop" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "currencySetting" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "defaultLocale" },
                      },
                      {
                        kind: "Field",
                        name: {
                          kind: "Name",
                          value: "shopSupportedLocaleCodes",
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "preferredSeller" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "listableConditions" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "displayName" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "conditionSlug" },
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "offerPolicy" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: {
                                kind: "Name",
                                value: "autoRejectLowOffers",
                              },
                            },
                            {
                              kind: "Field",
                              name: {
                                kind: "Name",
                                value: "autoAcceptHighOffers",
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "returnPolicy" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "_id" },
                            },
                            {
                              kind: "Field",
                              name: {
                                kind: "Name",
                                value: "newReturnWindowDays",
                              },
                            },
                            {
                              kind: "Field",
                              name: {
                                kind: "Name",
                                value: "usedReturnWindowDays",
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "taxPolicies" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "vatPolicy" },
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: {
                          kind: "Name",
                          value: "inclusiveVatPricingEnabled",
                        },
                      },
                      {
                        kind: "Field",
                        name: {
                          kind: "Name",
                          value: "sameDayShippingConfigured",
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "shippingRegionSettings" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "regionCode" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "regionName" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "isDomestic" },
                            },
                            {
                              kind: "Field",
                              name: {
                                kind: "Name",
                                value: "allowedToShipCarrierCalculated",
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "firstTimeSeller" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "address" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "_id" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "region" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "postalCode" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "countryCode" },
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "shippingProfiles" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "name" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "categoryUuids" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "localPickup" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "shippingRates" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "regionCode" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "regionName" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "rateType" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "rate" },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "display",
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "incrementalRate",
                                    },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "display",
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "expeditedRate",
                                    },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "display",
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "regionalRates",
                                    },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "areas",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "rate" },
                                          selectionSet: {
                                            kind: "SelectionSet",
                                            selections: [
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "display",
                                                },
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "regionalExpeditedRates",
                                    },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "areas",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "rate" },
                                          selectionSet: {
                                            kind: "SelectionSet",
                                            selections: [
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "display",
                                                },
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: "FragmentSpread",
                  name: { kind: "Name", value: "SellFormSetupData" },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "seedListing" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "seedId" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "seedId" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "seedType" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "seedType" },
                      },
                    },
                  ],
                },
              },
            ],
            directives: [
              {
                kind: "Directive",
                name: { kind: "Name", value: "include" },
                arguments: [
                  {
                    kind: "Argument",
                    name: { kind: "Name", value: "if" },
                    value: {
                      kind: "Variable",
                      name: { kind: "Name", value: "shouldSeedListingData" },
                    },
                  },
                ],
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "listing" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "make" } },
                      { kind: "Field", name: { kind: "Name", value: "model" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "brandSlug" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "categoryRootUuid" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "subcategoryLeafUuids" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "canonicalProductId" },
                      },
                      {
                        kind: "Field",
                        name: {
                          kind: "Name",
                          value: "comparisonShoppingPageId",
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "finish" },
                      },
                      { kind: "Field", name: { kind: "Name", value: "year" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "countryOfOrigin" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "conditionSlug" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "description" },
                      },
                      { kind: "Field", name: { kind: "Name", value: "title" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "handmade" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "soldAsIs" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "shippingProfileId" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "sellerCost" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "amount" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "amountCents" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "currency" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "display" },
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "sellerPrice" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "amount" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "amountCents" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "currency" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "display" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "countries" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "FragmentSpread",
                  name: { kind: "Name", value: "SellFormViewCountryFields" },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "ShopAddressFields" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "core_apimessages_Address" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "primary" } },
          { kind: "Field", name: { kind: "Name", value: "name" } },
          { kind: "Field", name: { kind: "Name", value: "streetAddress" } },
          { kind: "Field", name: { kind: "Name", value: "extendedAddress" } },
          { kind: "Field", name: { kind: "Name", value: "region" } },
          { kind: "Field", name: { kind: "Name", value: "locality" } },
          { kind: "Field", name: { kind: "Name", value: "postalCode" } },
          { kind: "Field", name: { kind: "Name", value: "phone" } },
          { kind: "Field", name: { kind: "Name", value: "countryCode" } },
          { kind: "Field", name: { kind: "Name", value: "isComplete" } },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "SellFormSetupData" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "rql_Me" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "uuid" } },
          { kind: "Field", name: { kind: "Name", value: "firstName" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "shop" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "name" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "currencySetting" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "defaultLocale" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "address" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      {
                        kind: "FragmentSpread",
                        name: { kind: "Name", value: "ShopAddressFields" },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "SellFormViewCountryFields" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "core_apimessages_CountriesResponse" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "countries" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "name" } },
                { kind: "Field", name: { kind: "Name", value: "countryCode" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Core_SellForm_NewListingLayoutQuery,
  Core_SellForm_NewListingLayoutQueryVariables
>;
export const Core_Listing_UpdateDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "Core_Listing_Update" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "input" },
          },
          type: {
            kind: "NamedType",
            name: {
              kind: "Name",
              value: "Input_core_apimessages_UpdateListingRequest",
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "crupdateListing" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "input" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                {
                  kind: "FragmentSpread",
                  name: { kind: "Name", value: "HydrateSellForm" },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "ShippingProfilePrices" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Shop" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "shippingProfiles" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "name" } },
                { kind: "Field", name: { kind: "Name", value: "id" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "categoryUuids" },
                },
                { kind: "Field", name: { kind: "Name", value: "localPickup" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "allShippingRates" },
                  arguments: [
                    {
                      kind: "Argument",
                      name: { kind: "Name", value: "input" },
                      value: {
                        kind: "ObjectValue",
                        fields: [
                          {
                            kind: "ObjectField",
                            name: { kind: "Name", value: "inOriginalCurrency" },
                            value: { kind: "BooleanValue", value: true },
                          },
                        ],
                      },
                    },
                  ],
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "regionCode" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "regionName" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "rateType" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "rate" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "display" },
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "incrementalRate" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "display" },
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "expeditedRate" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "display" },
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "regionalRates" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "areas" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "rate" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "display" },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "regionalExpeditedRates" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "areas" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "rate" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "display" },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "SellFormShopShipping" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Shop" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "currencySetting" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "sameDayShippingConfigured" },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "shippingRegionSettings" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "regionCode" } },
                { kind: "Field", name: { kind: "Name", value: "regionName" } },
                { kind: "Field", name: { kind: "Name", value: "isDomestic" } },
                {
                  kind: "Field",
                  name: {
                    kind: "Name",
                    value: "allowedToShipCarrierCalculated",
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "address" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "region" } },
                { kind: "Field", name: { kind: "Name", value: "postalCode" } },
                { kind: "Field", name: { kind: "Name", value: "countryCode" } },
              ],
            },
          },
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "ShippingProfilePrices" },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "HydrateSellForm" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Listing" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "state" } },
          { kind: "Field", name: { kind: "Name", value: "sku" } },
          { kind: "Field", name: { kind: "Name", value: "year" } },
          { kind: "Field", name: { kind: "Name", value: "make" } },
          { kind: "Field", name: { kind: "Name", value: "countryOfOrigin" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "canonicalProductId" },
          },
          { kind: "Field", name: { kind: "Name", value: "subcategoryUuids" } },
          { kind: "Field", name: { kind: "Name", value: "categoryRootUuid" } },
          { kind: "Field", name: { kind: "Name", value: "seedId" } },
          { kind: "Field", name: { kind: "Name", value: "seedType" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "publishedAt" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "seconds" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "categories" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "name" } },
                { kind: "Field", name: { kind: "Name", value: "root" } },
                { kind: "Field", name: { kind: "Name", value: "leaf" } },
                { kind: "Field", name: { kind: "Name", value: "rootSlug" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "localizedContents" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "locale" } },
                { kind: "Field", name: { kind: "Name", value: "make" } },
                { kind: "Field", name: { kind: "Name", value: "model" } },
                { kind: "Field", name: { kind: "Name", value: "finish" } },
                { kind: "Field", name: { kind: "Name", value: "title" } },
                { kind: "Field", name: { kind: "Name", value: "description" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "prop65Warning" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "defaultForShop" },
                },
              ],
            },
          },
          { kind: "Field", name: { kind: "Name", value: "handmade" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "traits" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "keyId" } },
                { kind: "Field", name: { kind: "Name", value: "valueId" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "condition" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "conditionSlug" },
                },
              ],
            },
          },
          { kind: "Field", name: { kind: "Name", value: "hasInventory" } },
          { kind: "Field", name: { kind: "Name", value: "inventory" } },
          { kind: "Field", name: { kind: "Name", value: "upc" } },
          { kind: "Field", name: { kind: "Name", value: "upcDoesNotApply" } },
          { kind: "Field", name: { kind: "Name", value: "taxExempt" } },
          { kind: "Field", name: { kind: "Name", value: "relistable" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "video" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "link" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "bumpRate" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "rate" } },
              ],
            },
          },
          { kind: "Field", name: { kind: "Name", value: "bumpEligible" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "images" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "type" },
                      value: {
                        kind: "StringValue",
                        value: "Product",
                        block: false,
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "scope" },
                      value: {
                        kind: "StringValue",
                        value: "photos",
                        block: false,
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "transform" },
                      value: {
                        kind: "StringValue",
                        value: "card_square",
                        block: false,
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "source" } },
                { kind: "Field", name: { kind: "Name", value: "itemId" } },
                { kind: "Field", name: { kind: "Name", value: "version" } },
                { kind: "Field", name: { kind: "Name", value: "itemType" } },
                { kind: "Field", name: { kind: "Name", value: "publicId" } },
                { kind: "Field", name: { kind: "Name", value: "height" } },
                { kind: "Field", name: { kind: "Name", value: "width" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "transformation" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "x" } },
                      { kind: "Field", name: { kind: "Name", value: "y" } },
                      { kind: "Field", name: { kind: "Name", value: "width" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "height" },
                      },
                      { kind: "Field", name: { kind: "Name", value: "crop" } },
                      { kind: "Field", name: { kind: "Name", value: "angle" } },
                    ],
                  },
                },
              ],
            },
          },
          { kind: "Field", name: { kind: "Name", value: "shippingProfileId" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "allShippingPrices" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "localPickup" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "shippingPrices" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "shippingRegionCode" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "shippingMethod" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "carrierCalculated" },
                      },
                      {
                        kind: "Field",
                        name: {
                          kind: "Name",
                          value: "originalRateVatExcluded",
                        },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "amount" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "currency" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "shipmentPackage" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "length" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "value" } },
                      { kind: "Field", name: { kind: "Name", value: "unit" } },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "width" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "value" } },
                      { kind: "Field", name: { kind: "Name", value: "unit" } },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "height" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "value" } },
                      { kind: "Field", name: { kind: "Name", value: "unit" } },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "weight" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "value" } },
                      { kind: "Field", name: { kind: "Name", value: "unit" } },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "allowedCarriers" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "packageSizeSuggestionId" },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "prefersReverbShippingLabel" },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "sellerPrice" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "amountCents" } },
                { kind: "Field", name: { kind: "Name", value: "currency" } },
                { kind: "Field", name: { kind: "Name", value: "amount" } },
                { kind: "Field", name: { kind: "Name", value: "display" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "sellerCost" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "amountCents" } },
                { kind: "Field", name: { kind: "Name", value: "currency" } },
                { kind: "Field", name: { kind: "Name", value: "amount" } },
                { kind: "Field", name: { kind: "Name", value: "display" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "sellerReportedMap" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "amountCents" } },
                { kind: "Field", name: { kind: "Name", value: "currency" } },
                { kind: "Field", name: { kind: "Name", value: "amount" } },
                { kind: "Field", name: { kind: "Name", value: "display" } },
              ],
            },
          },
          { kind: "Field", name: { kind: "Name", value: "vatIncluded" } },
          { kind: "Field", name: { kind: "Name", value: "vatIncludedHint" } },
          { kind: "Field", name: { kind: "Name", value: "soldAsIs" } },
          { kind: "Field", name: { kind: "Name", value: "listingType" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "overriddenAttributes" },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "preorderInfo" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "shipDate" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "seconds" },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "leadTimeDays" },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "shop" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "currencySetting" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "defaultLocale" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "shopSupportedLocaleCodes" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "preferredSeller" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "hasAccountRep" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "inclusiveVatPricingEnabled" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "listableConditions" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "displayName" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "conditionSlug" },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "offerPolicy" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "autoRejectLowOffers" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "lowballPercentage" },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "returnPolicy" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "newReturnWindowDays" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "usedReturnWindowDays" },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "taxPolicies" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "vatPolicy" },
                      },
                    ],
                  },
                },
                { kind: "Field", name: { kind: "Name", value: "vatId" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "firstTimeSeller" },
                },
                {
                  kind: "FragmentSpread",
                  name: { kind: "Name", value: "SellFormShopShipping" },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "scheduledPriceDrops" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "active" } },
                { kind: "Field", name: { kind: "Name", value: "dropAt" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "newPrice" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "amountCents" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "currency" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "amount" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "display" },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Core_Listing_UpdateMutation,
  Core_Listing_UpdateMutationVariables
>;
export const Core_CrossPlatform_DesktopFetchStatusDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "Core_CrossPlatform_DesktopFetchStatus" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "listingId" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "crossPlatformListingStatus" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "listingId" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "listingId" },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "status" } },
                { kind: "Field", name: { kind: "Name", value: "ttlSeconds" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Core_CrossPlatform_DesktopFetchStatusQuery,
  Core_CrossPlatform_DesktopFetchStatusQueryVariables
>;
export const Core_CrossPlatform_DesktopUpdateSessionDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "Core_CrossPlatform_DesktopUpdateSession" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "input" },
          },
          type: {
            kind: "NamedType",
            name: {
              kind: "Name",
              value:
                "Input_core_apimessages_UpdateCrossPlatformListingSessionRequest",
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "updateCrossPlatformListingSession" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "input" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Core_CrossPlatform_DesktopUpdateSessionMutation,
  Core_CrossPlatform_DesktopUpdateSessionMutationVariables
>;
export const Core_CrossPlatform_AuthTokenDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "Core_CrossPlatform_AuthToken" },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "bearerV2Token" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "client" },
                      value: {
                        kind: "EnumValue",
                        value: "LISTING_PHOTO_UPLOADER",
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "token" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Core_CrossPlatform_AuthTokenQuery,
  Core_CrossPlatform_AuthTokenQueryVariables
>;
export const Core_SellForm_ListingCorrectionDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "Core_SellForm_ListingCorrection" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "input" },
          },
          type: {
            kind: "NamedType",
            name: {
              kind: "Name",
              value: "Input_core_apimessages_ListingCorrectionRequest",
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "listingCorrection" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "input" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Core_SellForm_ListingCorrectionMutation,
  Core_SellForm_ListingCorrectionMutationVariables
>;
export const Core_SellForm_EstimatedNewListingPriceDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "Core_SellForm_EstimatedNewListingPrice" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "canonicalProductId" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "canonicalproduct" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "id" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "canonicalProductId" },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "estimatedNewListingPrice" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "price" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "amountCents" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "display" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Core_SellForm_EstimatedNewListingPriceQuery,
  Core_SellForm_EstimatedNewListingPriceQueryVariables
>;
export const DataServices_SellForm_PriceScoreDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "DataServices_SellForm_PriceScore" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "canonicalProductId" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "conditionUuid" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "price" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "Input_reverb_pricing_Money" },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "countryCode" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "priceScores" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "priceScoreQueries" },
                      value: {
                        kind: "ListValue",
                        values: [
                          {
                            kind: "ObjectValue",
                            fields: [
                              {
                                kind: "ObjectField",
                                name: {
                                  kind: "Name",
                                  value: "canonicalProductId",
                                },
                                value: {
                                  kind: "Variable",
                                  name: {
                                    kind: "Name",
                                    value: "canonicalProductId",
                                  },
                                },
                              },
                              {
                                kind: "ObjectField",
                                name: { kind: "Name", value: "conditionUuid" },
                                value: {
                                  kind: "Variable",
                                  name: {
                                    kind: "Name",
                                    value: "conditionUuid",
                                  },
                                },
                              },
                              {
                                kind: "ObjectField",
                                name: { kind: "Name", value: "price" },
                                value: {
                                  kind: "Variable",
                                  name: { kind: "Name", value: "price" },
                                },
                              },
                              {
                                kind: "ObjectField",
                                name: { kind: "Name", value: "countryCode" },
                                value: {
                                  kind: "Variable",
                                  name: { kind: "Name", value: "countryCode" },
                                },
                              },
                            ],
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "priceScores" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "priceScore" },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  DataServices_SellForm_PriceScoreQuery,
  DataServices_SellForm_PriceScoreQueryVariables
>;
export const Core_SellForm_RedeemPromotionalCodeDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "Core_SellForm_RedeemPromotionalCode" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "token" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "promotionalCodeRedeem" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "token" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "token" },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "message" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Core_SellForm_RedeemPromotionalCodeMutation,
  Core_SellForm_RedeemPromotionalCodeMutationVariables
>;
export const Core_SellForm_ReviewSectionPhotosDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "Core_SellForm_ReviewSectionPhotos" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "listingId" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "listing" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "id" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "listingId" },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                {
                  kind: "Field",
                  alias: { kind: "Name", value: "supersizeImages" },
                  name: { kind: "Name", value: "images" },
                  arguments: [
                    {
                      kind: "Argument",
                      name: { kind: "Name", value: "input" },
                      value: {
                        kind: "ObjectValue",
                        fields: [
                          {
                            kind: "ObjectField",
                            name: { kind: "Name", value: "type" },
                            value: {
                              kind: "StringValue",
                              value: "Product",
                              block: false,
                            },
                          },
                          {
                            kind: "ObjectField",
                            name: { kind: "Name", value: "scope" },
                            value: {
                              kind: "StringValue",
                              value: "photos",
                              block: false,
                            },
                          },
                          {
                            kind: "ObjectField",
                            name: { kind: "Name", value: "transform" },
                            value: {
                              kind: "StringValue",
                              value: "supersize",
                              block: false,
                            },
                          },
                          {
                            kind: "ObjectField",
                            name: { kind: "Name", value: "count" },
                            value: { kind: "IntValue", value: "25" },
                          },
                        ],
                      },
                    },
                  ],
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "source" },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  alias: { kind: "Name", value: "cardSquareImages" },
                  name: { kind: "Name", value: "images" },
                  arguments: [
                    {
                      kind: "Argument",
                      name: { kind: "Name", value: "input" },
                      value: {
                        kind: "ObjectValue",
                        fields: [
                          {
                            kind: "ObjectField",
                            name: { kind: "Name", value: "type" },
                            value: {
                              kind: "StringValue",
                              value: "Product",
                              block: false,
                            },
                          },
                          {
                            kind: "ObjectField",
                            name: { kind: "Name", value: "scope" },
                            value: {
                              kind: "StringValue",
                              value: "photos",
                              block: false,
                            },
                          },
                          {
                            kind: "ObjectField",
                            name: { kind: "Name", value: "transform" },
                            value: {
                              kind: "StringValue",
                              value: "card_square",
                              block: false,
                            },
                          },
                          {
                            kind: "ObjectField",
                            name: { kind: "Name", value: "count" },
                            value: { kind: "IntValue", value: "25" },
                          },
                        ],
                      },
                    },
                  ],
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "source" },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Core_SellForm_ReviewSectionPhotosQuery,
  Core_SellForm_ReviewSectionPhotosQueryVariables
>;
export const Search_SellForm_LiveListingsTableDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "Search_SellForm_LiveListingsTable" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "canonicalProductIds" },
          },
          type: {
            kind: "ListType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "conditionSlugs" },
          },
          type: {
            kind: "ListType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "itemRegion" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "limit" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "Int" } },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "offset" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "Int" } },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "listingsSearch" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "canonicalProductIds" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "canonicalProductIds" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "conditionSlugs" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "conditionSlugs" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "itemRegion" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "itemRegion" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "statuses" },
                      value: {
                        kind: "ListValue",
                        values: [
                          { kind: "StringValue", value: "live", block: false },
                        ],
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "limit" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "limit" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "offset" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "offset" },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "total" } },
                { kind: "Field", name: { kind: "Name", value: "offset" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "listings" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "publishedAt" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "seconds" },
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "condition" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "displayName" },
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "pricing" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "buyerPrice" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "display" },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Search_SellForm_LiveListingsTableQuery,
  Search_SellForm_LiveListingsTableQueryVariables
>;
export const DataServices_SellForm__PricingRecommendationsDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: {
        kind: "Name",
        value: "DataServices_SellForm__PricingRecommendations",
      },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "priceRecommendationQueries" },
          },
          type: {
            kind: "ListType",
            type: {
              kind: "NamedType",
              name: {
                kind: "Name",
                value: "Input_reverb_pricing_PriceRecommendationQuery",
              },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "loggedOut" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "Boolean" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "priceRecommendations" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: {
                        kind: "Name",
                        value: "priceRecommendationQueries",
                      },
                      value: {
                        kind: "Variable",
                        name: {
                          kind: "Name",
                          value: "priceRecommendationQueries",
                        },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "priceRecommendations" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "priceLow" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "amountCents" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "amount" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "currency" },
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "priceMiddle" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "amountCents" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "amount" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "currency" },
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "priceHigh" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "amountCents" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "amount" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "currency" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "me" },
            directives: [
              {
                kind: "Directive",
                name: { kind: "Name", value: "skip" },
                arguments: [
                  {
                    kind: "Argument",
                    name: { kind: "Name", value: "if" },
                    value: {
                      kind: "Variable",
                      name: { kind: "Name", value: "loggedOut" },
                    },
                  },
                ],
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "uuid" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "shop" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "currency" },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "exchangeRates" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "inverseReverseRates" },
                      value: { kind: "BooleanValue", value: true },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "rates" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "from" } },
                      { kind: "Field", name: { kind: "Name", value: "to" } },
                      { kind: "Field", name: { kind: "Name", value: "rate" } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  DataServices_SellForm__PricingRecommendationsQuery,
  DataServices_SellForm__PricingRecommendationsQueryVariables
>;
export const Core_SellForm_CSPSeedDataDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "Core_SellForm_CSPSeedData" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "cspId" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "csp" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "id" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "cspId" },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                {
                  kind: "FragmentSpread",
                  name: {
                    kind: "Name",
                    value: "SellFormCanonicalProductCardData",
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "SellFormCanonicalProductCardData" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "CSP" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "title" } },
          { kind: "Field", name: { kind: "Name", value: "model" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "image" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "transform" },
                      value: {
                        kind: "StringValue",
                        value: "card_square",
                        block: false,
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "source" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "canonicalProducts" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "name" } },
                { kind: "Field", name: { kind: "Name", value: "model" } },
                { kind: "Field", name: { kind: "Name", value: "year" } },
                { kind: "Field", name: { kind: "Name", value: "finish" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "countryOfOrigin" },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "categories" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "name" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "brand" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "name" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Core_SellForm_CSPSeedDataQuery,
  Core_SellForm_CSPSeedDataQueryVariables
>;
export const Core_SellForm_FetchPackageSizeSuggestionDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "Core_SellForm_FetchPackageSizeSuggestion" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "canonicalProductId" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "canonicalproduct" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "id" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "canonicalProductId" },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "packageSizeSuggestion" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "length" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "unit" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "value" },
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "width" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "unit" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "value" },
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "height" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "unit" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "value" },
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "weight" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "unit" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "value" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Core_SellForm_FetchPackageSizeSuggestionQuery,
  Core_SellForm_FetchPackageSizeSuggestionQueryVariables
>;
export const Core_PromotionalCodeVerifyDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "Core_PromotionalCodeVerify" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "token" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "promotionalCodeVerify" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "token" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "token" },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "success" } },
                { kind: "Field", name: { kind: "Name", value: "message" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Core_PromotionalCodeVerifyQuery,
  Core_PromotionalCodeVerifyQueryVariables
>;
export const Core_SellFlow_SearchDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "Core_SellFlow_Search" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "q" } },
          type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "decades" },
          },
          type: {
            kind: "ListType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "finishes" },
          },
          type: {
            kind: "ListType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "brandNames" },
          },
          type: {
            kind: "ListType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "category_uuids" },
          },
          type: {
            kind: "ListType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "sellCardLimit" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "Int" } },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "excludedCategoryUuids" },
          },
          type: {
            kind: "ListType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "boostByClicks" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "Boolean" } },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "fullTextQueryOperand" },
          },
          type: {
            kind: "NamedType",
            name: { kind: "Name", value: "reverb_search_FullTextQueryOperand" },
          },
        },
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "sort" } },
          type: {
            kind: "NamedType",
            name: {
              kind: "Name",
              value: "reverb_search_CSPSearchRequest_Sort",
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "fuzzy" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "Boolean" } },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "offset" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "Int" } },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "listingsThatShipTo" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "hasExpressSaleBid" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "Boolean" },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "includePriceRecommendations" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "Boolean" },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "priceRecommendationCountryCode" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "cspSearch" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "fullTextQuery" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "q" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "decades" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "decades" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "finishes" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "finishes" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "brandNames" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "brandNames" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "categoryUuids" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "category_uuids" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "withAggregations" },
                      value: {
                        kind: "ListValue",
                        values: [
                          { kind: "EnumValue", value: "CATEGORY_UUIDS" },
                          { kind: "EnumValue", value: "FINISHES" },
                          { kind: "EnumValue", value: "DECADES" },
                          { kind: "EnumValue", value: "BRAND_NAMES" },
                        ],
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "excludedCategoryUuids" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "excludedCategoryUuids" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "limit" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "sellCardLimit" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "offset" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "offset" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "boostByClicks" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "boostByClicks" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "fullTextQueryOperand" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "fullTextQueryOperand" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "sort" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "sort" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "fuzzy" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "fuzzy" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "listingsThatShipTo" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "listingsThatShipTo" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "hasExpressSaleBid" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "hasExpressSaleBid" },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "filters" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "FragmentSpread",
                        name: { kind: "Name", value: "FlatFilter" },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "csps" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      {
                        kind: "FragmentSpread",
                        name: { kind: "Name", value: "SellCardData" },
                      },
                      {
                        kind: "FragmentSpread",
                        name: {
                          kind: "Name",
                          value: "CSPPriceRecommendationData",
                        },
                        directives: [
                          {
                            kind: "Directive",
                            name: { kind: "Name", value: "include" },
                            arguments: [
                              {
                                kind: "Argument",
                                name: { kind: "Name", value: "if" },
                                value: {
                                  kind: "Variable",
                                  name: {
                                    kind: "Name",
                                    value: "includePriceRecommendations",
                                  },
                                },
                              },
                            ],
                          },
                        ],
                      },
                      {
                        kind: "FragmentSpread",
                        name: { kind: "Name", value: "ExpressSaleItemBidData" },
                        directives: [
                          {
                            kind: "Directive",
                            name: { kind: "Name", value: "include" },
                            arguments: [
                              {
                                kind: "Argument",
                                name: { kind: "Name", value: "if" },
                                value: {
                                  kind: "Variable",
                                  name: {
                                    kind: "Name",
                                    value: "hasExpressSaleBid",
                                  },
                                },
                              },
                            ],
                          },
                        ],
                      },
                    ],
                  },
                },
                { kind: "Field", name: { kind: "Name", value: "total" } },
                { kind: "Field", name: { kind: "Name", value: "offset" } },
                { kind: "Field", name: { kind: "Name", value: "limit" } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "FlatFilter" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "reverb_search_Filter" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "name" } },
          { kind: "Field", name: { kind: "Name", value: "key" } },
          { kind: "Field", name: { kind: "Name", value: "aggregationName" } },
          { kind: "Field", name: { kind: "Name", value: "widgetType" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "options" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "count" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "value" } },
                    ],
                  },
                },
                { kind: "Field", name: { kind: "Name", value: "name" } },
                { kind: "Field", name: { kind: "Name", value: "selected" } },
                { kind: "Field", name: { kind: "Name", value: "paramName" } },
                { kind: "Field", name: { kind: "Name", value: "setValues" } },
                { kind: "Field", name: { kind: "Name", value: "unsetValues" } },
                { kind: "Field", name: { kind: "Name", value: "all" } },
                { kind: "Field", name: { kind: "Name", value: "optionValue" } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "SellCardData" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "CSP" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "title" } },
          { kind: "Field", name: { kind: "Name", value: "finishes" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "image" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "transform" },
                      value: {
                        kind: "StringValue",
                        value: "card_square",
                        block: false,
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "source" } },
              ],
            },
          },
          { kind: "Field", name: { kind: "Name", value: "slug" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "brand" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "name" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "canonicalProductIds" },
          },
          { kind: "Field", name: { kind: "Name", value: "isTradeInEligible" } },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "CSPPriceRecommendationData" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "CSP" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "priceRecommendations" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "conditionUuids" },
                      value: {
                        kind: "ListValue",
                        values: [
                          {
                            kind: "StringValue",
                            value: "f7a3f48c-972a-44c6-b01a-0cd27488d3f6",
                            block: false,
                          },
                          {
                            kind: "StringValue",
                            value: "ac5b9c1e-dc78-466d-b0b3-7cf712967a48",
                            block: false,
                          },
                        ],
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "countryCode" },
                      value: {
                        kind: "Variable",
                        name: {
                          kind: "Name",
                          value: "priceRecommendationCountryCode",
                        },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "conditionUuid" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "priceLow" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "amountCents" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "amount" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "currency" },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "priceHigh" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "amountCents" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "amount" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "currency" },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "ExpressSaleItemBidData" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "CSP" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "expressSalePriceEstimate" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "conditionUuid" },
                      value: {
                        kind: "StringValue",
                        value: "ae4d9114-1bd7-4ec5-a4ba-6653af5ac84d",
                        block: false,
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "priceLow" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "amountCents" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "currency" },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "priceHigh" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "amountCents" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "currency" },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "expressSaleItemBid" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "cspUuid" } },
                { kind: "Field", name: { kind: "Name", value: "bidId" } },
                { kind: "Field", name: { kind: "Name", value: "carrier" } },
                { kind: "Field", name: { kind: "Name", value: "shopName" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "estimatedPayout" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "display" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "amount" },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Core_SellFlow_SearchQuery,
  Core_SellFlow_SearchQueryVariables
>;
export const Core_Trade_In_EligibilityDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "Core_Trade_In_Eligibility" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "cspId" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "tradeInEligibility" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "cspId" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "cspId" },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "isEligible" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Core_Trade_In_EligibilityQuery,
  Core_Trade_In_EligibilityQueryVariables
>;
export const Core_Sell_Search_AutocompleteDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "Core_Sell_Search_Autocomplete" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "query" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "completions" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "query" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "query" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "types" },
                      value: {
                        kind: "ListValue",
                        values: [
                          { kind: "EnumValue", value: "CSP" },
                          { kind: "EnumValue", value: "BRAND_MODEL" },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "completions" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "type" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "options" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "slug" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "output" },
                            },
                            {
                              kind: "Field",
                              name: {
                                kind: "Name",
                                value: "completionPayload",
                              },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "thumbnailUrl",
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "inventoryNew",
                                    },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "listingCount",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "listingMinPrice",
                                          },
                                          selectionSet: {
                                            kind: "SelectionSet",
                                            selections: [
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "cents",
                                                },
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "currency",
                                                },
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "inventoryUsed",
                                    },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "listingCount",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "listingMinPrice",
                                          },
                                          selectionSet: {
                                            kind: "SelectionSet",
                                            selections: [
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "cents",
                                                },
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "currency",
                                                },
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Core_Sell_Search_AutocompleteQuery,
  Core_Sell_Search_AutocompleteQueryVariables
>;
export const Core_Seller_Dashboard_ListingsDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "Core_Seller_Dashboard_Listings" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "shopSlug" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "offset" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "Int" } },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "limit" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "Int" } },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "categorySlugs" },
          },
          type: {
            kind: "ListType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "brandSlugs" },
          },
          type: {
            kind: "ListType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "conditionSlugs" },
          },
          type: {
            kind: "ListType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "query" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "listingsSearch" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "shopSlug" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "shopSlug" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "statuses" },
                      value: {
                        kind: "ListValue",
                        values: [
                          { kind: "StringValue", value: "live", block: false },
                        ],
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "sort" },
                      value: { kind: "EnumValue", value: "PUBLISHED_AT_DESC" },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "limit" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "limit" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "offset" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "offset" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "categorySlugs" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "categorySlugs" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "brandSlugs" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "brandSlugs" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "conditionSlugs" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "conditionSlugs" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "query" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "query" },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "total" } },
                { kind: "Field", name: { kind: "Name", value: "offset" } },
                { kind: "Field", name: { kind: "Name", value: "limit" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "listings" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      {
                        kind: "FragmentSpread",
                        name: {
                          kind: "Name",
                          value: "CoreSellerDashboardListing",
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "BumpRecommendationsAttributes" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Listing" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "condition" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "conditionUuid" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "conditionSlug" },
                },
                { kind: "Field", name: { kind: "Name", value: "displayName" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "csp" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "title" } },
                { kind: "Field", name: { kind: "Name", value: "slug" } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "BumpRecommendationsFragment" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Listing" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "bumpRecommendations" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "bumpRecommendationAmount" },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "CoreSellerDashboardListing" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Listing" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "slug" } },
          { kind: "Field", name: { kind: "Name", value: "title" } },
          { kind: "Field", name: { kind: "Name", value: "sku" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "price" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "display" } },
                { kind: "Field", name: { kind: "Name", value: "amountCents" } },
                { kind: "Field", name: { kind: "Name", value: "currency" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "bumpRate" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "rate" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "bumpInteractionsCounts" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "total" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "images" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "transform" },
                      value: {
                        kind: "StringValue",
                        value: "thumb",
                        block: false,
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "count" },
                      value: { kind: "IntValue", value: "1" },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "scope" },
                      value: {
                        kind: "StringValue",
                        value: "photos",
                        block: false,
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "type" },
                      value: {
                        kind: "StringValue",
                        value: "Product",
                        block: false,
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "source" } },
              ],
            },
          },
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "BumpRecommendationsAttributes" },
          },
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "BumpRecommendationsFragment" },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Core_Seller_Dashboard_ListingsQuery,
  Core_Seller_Dashboard_ListingsQueryVariables
>;
export const Core_Seller_Dashboard_RevenueDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "Core_Seller_Dashboard_Revenue" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "startDate" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "endDate" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "categorySlugs" },
          },
          type: {
            kind: "ListType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "brandSlugs" },
          },
          type: {
            kind: "ListType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "conditionSlugs" },
          },
          type: {
            kind: "ListType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "soldWithBumpOnly" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "Boolean" } },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "soldWithoutBumpOnly" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "Boolean" } },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "me" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "uuid" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "shop" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      { kind: "Field", name: { kind: "Name", value: "slug" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "interactions" },
                        arguments: [
                          {
                            kind: "Argument",
                            name: { kind: "Name", value: "input" },
                            value: {
                              kind: "ObjectValue",
                              fields: [
                                {
                                  kind: "ObjectField",
                                  name: { kind: "Name", value: "startDate" },
                                  value: {
                                    kind: "Variable",
                                    name: { kind: "Name", value: "startDate" },
                                  },
                                },
                                {
                                  kind: "ObjectField",
                                  name: { kind: "Name", value: "endDate" },
                                  value: {
                                    kind: "Variable",
                                    name: { kind: "Name", value: "endDate" },
                                  },
                                },
                              ],
                            },
                          },
                        ],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "click" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "addToCart" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "addToWatchlist" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "makeAnOffer" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "total" },
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "ordersSearch" },
                        arguments: [
                          {
                            kind: "Argument",
                            name: { kind: "Name", value: "input" },
                            value: {
                              kind: "ObjectValue",
                              fields: [
                                {
                                  kind: "ObjectField",
                                  name: {
                                    kind: "Name",
                                    value: "createdAfterDate",
                                  },
                                  value: {
                                    kind: "Variable",
                                    name: { kind: "Name", value: "startDate" },
                                  },
                                },
                                {
                                  kind: "ObjectField",
                                  name: {
                                    kind: "Name",
                                    value: "createdBeforeDate",
                                  },
                                  value: {
                                    kind: "Variable",
                                    name: { kind: "Name", value: "endDate" },
                                  },
                                },
                                {
                                  kind: "ObjectField",
                                  name: {
                                    kind: "Name",
                                    value: "withMetricAggregations",
                                  },
                                  value: { kind: "BooleanValue", value: true },
                                },
                                {
                                  kind: "ObjectField",
                                  name: {
                                    kind: "Name",
                                    value: "aggregationsOnly",
                                  },
                                  value: { kind: "BooleanValue", value: true },
                                },
                                {
                                  kind: "ObjectField",
                                  name: {
                                    kind: "Name",
                                    value: "withAggregations",
                                  },
                                  value: {
                                    kind: "ListValue",
                                    values: [
                                      {
                                        kind: "EnumValue",
                                        value: "BRAND_SLUGS",
                                      },
                                      {
                                        kind: "EnumValue",
                                        value: "CATEGORY_SLUGS",
                                      },
                                      {
                                        kind: "EnumValue",
                                        value: "CONDITION_SLUGS",
                                      },
                                    ],
                                  },
                                },
                                {
                                  kind: "ObjectField",
                                  name: {
                                    kind: "Name",
                                    value: "categorySlugs",
                                  },
                                  value: {
                                    kind: "Variable",
                                    name: {
                                      kind: "Name",
                                      value: "categorySlugs",
                                    },
                                  },
                                },
                                {
                                  kind: "ObjectField",
                                  name: { kind: "Name", value: "brandSlugs" },
                                  value: {
                                    kind: "Variable",
                                    name: { kind: "Name", value: "brandSlugs" },
                                  },
                                },
                                {
                                  kind: "ObjectField",
                                  name: {
                                    kind: "Name",
                                    value: "conditionSlugs",
                                  },
                                  value: {
                                    kind: "Variable",
                                    name: {
                                      kind: "Name",
                                      value: "conditionSlugs",
                                    },
                                  },
                                },
                                {
                                  kind: "ObjectField",
                                  name: {
                                    kind: "Name",
                                    value: "soldWithBumpOnly",
                                  },
                                  value: {
                                    kind: "Variable",
                                    name: {
                                      kind: "Name",
                                      value: "soldWithBumpOnly",
                                    },
                                  },
                                },
                                {
                                  kind: "ObjectField",
                                  name: {
                                    kind: "Name",
                                    value: "soldWithoutBumpOnly",
                                  },
                                  value: {
                                    kind: "Variable",
                                    name: {
                                      kind: "Name",
                                      value: "soldWithoutBumpOnly",
                                    },
                                  },
                                },
                              ],
                            },
                          },
                        ],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "orderMetrics" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "orderCount" },
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "totalSellerRevenueAmount",
                                    },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "cents",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "currency",
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "totalBumpSpendAmount",
                                    },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "currency",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "cents",
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "dateBuckets" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "toDate" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "fromDate" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "metrics" },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "totalSellerRevenueAmount",
                                          },
                                          selectionSet: {
                                            kind: "SelectionSet",
                                            selections: [
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "cents",
                                                },
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "currency",
                                                },
                                              },
                                            ],
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "totalBumpSpendAmount",
                                          },
                                          selectionSet: {
                                            kind: "SelectionSet",
                                            selections: [
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "currency",
                                                },
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "cents",
                                                },
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "filters" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "FragmentSpread",
                                    name: {
                                      kind: "Name",
                                      value: "NestedFilter",
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "NestedFilter" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "reverb_search_Filter" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "name" } },
          { kind: "Field", name: { kind: "Name", value: "key" } },
          { kind: "Field", name: { kind: "Name", value: "aggregationName" } },
          { kind: "Field", name: { kind: "Name", value: "widgetType" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "options" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "count" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "value" } },
                    ],
                  },
                },
                { kind: "Field", name: { kind: "Name", value: "name" } },
                { kind: "Field", name: { kind: "Name", value: "selected" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "autoselected" },
                },
                { kind: "Field", name: { kind: "Name", value: "paramName" } },
                { kind: "Field", name: { kind: "Name", value: "setValues" } },
                { kind: "Field", name: { kind: "Name", value: "unsetValues" } },
                { kind: "Field", name: { kind: "Name", value: "all" } },
                { kind: "Field", name: { kind: "Name", value: "optionValue" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "trackingValue" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "subFilter" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "widgetType" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "options" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "count" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "value" },
                                  },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "name" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "selected" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "autoselected" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "paramName" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "setValues" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "unsetValues" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "all" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "optionValue" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "trackingValue" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "subFilter" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "widgetType" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "options" },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "count",
                                          },
                                          selectionSet: {
                                            kind: "SelectionSet",
                                            selections: [
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "value",
                                                },
                                              },
                                            ],
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "name" },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "selected",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "autoselected",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "paramName",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "setValues",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "unsetValues",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "all" },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "optionValue",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "trackingValue",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "subFilter",
                                          },
                                          selectionSet: {
                                            kind: "SelectionSet",
                                            selections: [
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "widgetType",
                                                },
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "options",
                                                },
                                                selectionSet: {
                                                  kind: "SelectionSet",
                                                  selections: [
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "count",
                                                      },
                                                      selectionSet: {
                                                        kind: "SelectionSet",
                                                        selections: [
                                                          {
                                                            kind: "Field",
                                                            name: {
                                                              kind: "Name",
                                                              value: "value",
                                                            },
                                                          },
                                                        ],
                                                      },
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "name",
                                                      },
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "selected",
                                                      },
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "autoselected",
                                                      },
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "paramName",
                                                      },
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "setValues",
                                                      },
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "unsetValues",
                                                      },
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "all",
                                                      },
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "optionValue",
                                                      },
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "trackingValue",
                                                      },
                                                    },
                                                  ],
                                                },
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Core_Seller_Dashboard_RevenueQuery,
  Core_Seller_Dashboard_RevenueQueryVariables
>;
export const Core_MyShopCampaigns_UpdateDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "Core_MyShopCampaigns_Update" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "input" },
          },
          type: {
            kind: "NamedType",
            name: {
              kind: "Name",
              value: "Input_core_apimessages_UpdateMyShopCampaignRequest",
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "crupdateMyShopCampaign" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "input" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "id" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Core_MyShopCampaigns_UpdateMutation,
  Core_MyShopCampaigns_UpdateMutationVariables
>;
export const Core_Main_CategoriesDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "Core_Main_Categories" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "entityId" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "entityType" },
          },
          type: {
            kind: "NamedType",
            name: {
              kind: "Name",
              value:
                "core_apimessages_FormSelectCategoriesRequest_FormSelectEntityType",
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "formSelectCategories" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "entityId" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "entityId" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "entityType" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "entityType" },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "categories" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "uuid" } },
                      { kind: "Field", name: { kind: "Name", value: "name" } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Core_Main_CategoriesQuery,
  Core_Main_CategoriesQueryVariables
>;
export const Core_MyShopCampaigns_IndexDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "Core_MyShopCampaigns_Index" },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "me" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "uuid" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "shop" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "shopCampaigns" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "_id" },
                            },
                            {
                              kind: "FragmentSpread",
                              name: {
                                kind: "Name",
                                value: "ShopCampaignSummaryTable",
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "ShopCampaignSummaryTable" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "core_apimessages_MyShopCampaign" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "active" } },
          { kind: "Field", name: { kind: "Name", value: "code" } },
          { kind: "Field", name: { kind: "Name", value: "campaignType" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "useRetroactiveSends" },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "applyToNewConditions" },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "applyToUsedConditions" },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "applyToBStockConditions" },
          },
          { kind: "Field", name: { kind: "Name", value: "discountSummary" } },
          { kind: "Field", name: { kind: "Name", value: "totalCouponCount" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "redeemedCouponCount" },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "minPurchaseAmount" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "display" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "excludedBrands" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "name" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "excludedCategories" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "name" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Core_MyShopCampaigns_IndexQuery,
  Core_MyShopCampaigns_IndexQueryVariables
>;
export const Core_MyShopCampaigns_QuickEditDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "Core_MyShopCampaigns_QuickEdit" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "input" },
          },
          type: {
            kind: "NamedType",
            name: {
              kind: "Name",
              value: "Input_core_apimessages_UpdateMyShopCampaignRequest",
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "crupdateMyShopCampaign" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "input" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "id" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Core_MyShopCampaigns_QuickEditMutation,
  Core_MyShopCampaigns_QuickEditMutationVariables
>;
export const Core_MyShopCampaigns_NewLayoutDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "Core_MyShopCampaigns_NewLayout" },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "me" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "uuid" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "shop" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      { kind: "Field", name: { kind: "Name", value: "name" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "currencySetting" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "shopCampaigns" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "_id" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "active" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "code" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "campaignType" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "discountPercent" },
                            },
                            {
                              kind: "Field",
                              name: {
                                kind: "Name",
                                value: "useRetroactiveSends",
                              },
                            },
                            {
                              kind: "Field",
                              name: {
                                kind: "Name",
                                value: "applyToNewConditions",
                              },
                            },
                            {
                              kind: "Field",
                              name: {
                                kind: "Name",
                                value: "applyToUsedConditions",
                              },
                            },
                            {
                              kind: "Field",
                              name: {
                                kind: "Name",
                                value: "applyToBStockConditions",
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "discountAmount" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "display" },
                                  },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: {
                                kind: "Name",
                                value: "minPurchaseAmount",
                              },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "display" },
                                  },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: {
                                kind: "Name",
                                value: "maxDiscountAmount",
                              },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "display" },
                                  },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "excludedBrands" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "_id" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "name" },
                                  },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: {
                                kind: "Name",
                                value: "excludedCategories",
                              },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "_id" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "name" },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Core_MyShopCampaigns_NewLayoutQuery,
  Core_MyShopCampaigns_NewLayoutQueryVariables
>;
export const Core_Payouts_MyDirectCheckoutProfileDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "Core_Payouts_MyDirectCheckoutProfile" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "input" },
          },
          type: {
            kind: "NamedType",
            name: {
              kind: "Name",
              value: "Input_core_apimessages_MyDirectCheckoutProfileRequest",
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "myDirectCheckoutProfile" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "input" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "plaidAccountId" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "nameOnAccount" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "maskedNameSummary" },
                },
                { kind: "Field", name: { kind: "Name", value: "bankName" } },
                { kind: "Field", name: { kind: "Name", value: "countryCode" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "payoutMethod" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "hasAcceptedReverbBankTerms" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "profileErrors" },
                },
                { kind: "Field", name: { kind: "Name", value: "currency" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Core_Payouts_MyDirectCheckoutProfileQuery,
  Core_Payouts_MyDirectCheckoutProfileQueryVariables
>;
export const MyShopOrderRefundStatsDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "MyShopOrderRefundStats" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "input" },
          },
          type: {
            kind: "NamedType",
            name: {
              kind: "Name",
              value: "Input_core_apimessages_MyShopOrderRefundStatsRequest",
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "me" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "uuid" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "shop" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      { kind: "Field", name: { kind: "Name", value: "uuid" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "orderRefundStats" },
                        arguments: [
                          {
                            kind: "Argument",
                            name: { kind: "Name", value: "input" },
                            value: {
                              kind: "Variable",
                              name: { kind: "Name", value: "input" },
                            },
                          },
                        ],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: {
                                kind: "Name",
                                value: "sellerInitiatedRefundCount",
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  MyShopOrderRefundStatsQuery,
  MyShopOrderRefundStatsQueryVariables
>;
export const Core_ShopSettings_AuGstLayoutDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "Core_ShopSettings_AuGstLayout" },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "me" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "uuid" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "shop" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "taxIdentifications" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "taxId" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "taxType" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "status" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "countryCode" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Core_ShopSettings_AuGstLayoutQuery,
  Core_ShopSettings_AuGstLayoutQueryVariables
>;
export const Core_ShopSettings_CreateTaxIdentificationDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: {
        kind: "Name",
        value: "Core_ShopSettings_CreateTaxIdentification",
      },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "input" },
          },
          type: {
            kind: "NamedType",
            name: {
              kind: "Name",
              value: "Input_core_apimessages_CreateTaxIdentificationRequest",
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "createTaxIdentification" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "input" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "shop" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "taxIdentifications" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "taxId" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "taxType" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "status" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "countryCode" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Core_ShopSettings_CreateTaxIdentificationMutation,
  Core_ShopSettings_CreateTaxIdentificationMutationVariables
>;
export const Core_ShopSettings_UpdateTaxIdentificationDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: {
        kind: "Name",
        value: "Core_ShopSettings_UpdateTaxIdentification",
      },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "input" },
          },
          type: {
            kind: "NamedType",
            name: {
              kind: "Name",
              value: "Input_core_apimessages_UpdateTaxIdentificationRequest",
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "updateTaxIdentification" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "input" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "shop" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "taxIdentifications" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "taxId" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "status" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "countryCode" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Core_ShopSettings_UpdateTaxIdentificationMutation,
  Core_ShopSettings_UpdateTaxIdentificationMutationVariables
>;
export const Core_ShopSettings_DeactivateTaxIdentificationDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: {
        kind: "Name",
        value: "Core_ShopSettings_DeactivateTaxIdentification",
      },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "input" },
          },
          type: {
            kind: "NamedType",
            name: {
              kind: "Name",
              value:
                "Input_core_apimessages_DeactivateTaxIdentificationRequest",
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "deactivateTaxIdentification" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "input" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "shop" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "taxIdentifications" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "taxId" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "status" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "countryCode" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "active" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Core_ShopSettings_DeactivateTaxIdentificationMutation,
  Core_ShopSettings_DeactivateTaxIdentificationMutationVariables
>;
export const Core_SecondaryUsers_MySecondaryUsersDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "Core_SecondaryUsers_MySecondaryUsers" },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "me" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "name" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "shop" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "userShopPermissions" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "_id" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "email" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "permissionLevel" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "user" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "_id" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "name" },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Core_SecondaryUsers_MySecondaryUsersQuery,
  Core_SecondaryUsers_MySecondaryUsersQueryVariables
>;
export const Core_SecondaryUsers_DeleteUserShopPermissionDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: {
        kind: "Name",
        value: "Core_SecondaryUsers_DeleteUserShopPermission",
      },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "input" },
          },
          type: {
            kind: "NamedType",
            name: {
              kind: "Name",
              value: "Input_core_apimessages_DeleteUserShopPermissionRequest",
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "deleteUserShopPermission" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "input" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Core_SecondaryUsers_DeleteUserShopPermissionMutation,
  Core_SecondaryUsers_DeleteUserShopPermissionMutationVariables
>;
export const Core_SecondaryUsers_ExportActivitiesDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "Core_SecondaryUsers_ExportActivities" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "input" },
          },
          type: {
            kind: "NamedType",
            name: {
              kind: "Name",
              value:
                "Input_core_apimessages_ExportSecondaryUserActivitiesRequest",
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "exportSecondaryUserActivities" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "input" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "enqueued" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Core_SecondaryUsers_ExportActivitiesMutation,
  Core_SecondaryUsers_ExportActivitiesMutationVariables
>;
export const Core_SecondaryUsers_CreateUserShopPermissionDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: {
        kind: "Name",
        value: "Core_SecondaryUsers_CreateUserShopPermission",
      },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "input" },
          },
          type: {
            kind: "NamedType",
            name: {
              kind: "Name",
              value: "Input_core_apimessages_CreateUserShopPermissionRequest",
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "createUserShopPermission" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "input" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "userShopPermission" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      { kind: "Field", name: { kind: "Name", value: "email" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "permissionLevel" },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Core_SecondaryUsers_CreateUserShopPermissionMutation,
  Core_SecondaryUsers_CreateUserShopPermissionMutationVariables
>;
export const Core_SecondaryUsers_UpdateUserShopPermissionDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: {
        kind: "Name",
        value: "Core_SecondaryUsers_UpdateUserShopPermission",
      },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "input" },
          },
          type: {
            kind: "NamedType",
            name: {
              kind: "Name",
              value: "Input_core_apimessages_UpdateUserShopPermissionRequest",
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "updateUserShopPermission" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "input" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "userShopPermission" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      { kind: "Field", name: { kind: "Name", value: "email" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "permissionLevel" },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Core_SecondaryUsers_UpdateUserShopPermissionMutation,
  Core_SecondaryUsers_UpdateUserShopPermissionMutationVariables
>;
export const Core_ShopSettings_VatLayoutDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "Core_ShopSettings_VatLayout" },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "me" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "uuid" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "shop" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "taxIdentifications" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "taxId" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "taxType" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "status" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "countryCode" },
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "address" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "_id" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "country" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "_id" },
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "countryCode",
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "countries" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "countries" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "countryCode" },
                      },
                      { kind: "Field", name: { kind: "Name", value: "name" } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Core_ShopSettings_VatLayoutQuery,
  Core_ShopSettings_VatLayoutQueryVariables
>;
export const Core_User_SubscriptionsDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "Core_User_Subscriptions" },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "me" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "uuid" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "requireOptIn" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "emailSubscriptions" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "subscribed" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "translationKey" },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "emailGlobalSubscribe" },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Core_User_SubscriptionsQuery,
  Core_User_SubscriptionsQueryVariables
>;
export const Update_Email_SubscriptionDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "Update_Email_Subscription" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "input" },
          },
          type: {
            kind: "NamedType",
            name: { kind: "Name", value: "Input_rql_EmailSubscription" },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "setEmailSubscription" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "input" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "subscribed" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "translationKey" },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Update_Email_SubscriptionMutation,
  Update_Email_SubscriptionMutationVariables
>;
export const Set_Email_Global_SubscribeDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "Set_Email_Global_Subscribe" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "input" },
          },
          type: {
            kind: "NamedType",
            name: {
              kind: "Name",
              value: "Input_rql_SetGlobalSubscribeRequest",
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "setEmailGlobalSubscribe" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "input" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "uuid" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "emailGlobalSubscribe" },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Set_Email_Global_SubscribeMutation,
  Set_Email_Global_SubscribeMutationVariables
>;
export const Trigger_Opt_In_EmailDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "Trigger_Opt_In_Email" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "input" },
          },
          type: {
            kind: "NamedType",
            name: { kind: "Name", value: "Input_rql_Blank" },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "triggerOptInEmail" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "input" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "dispatchId" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Trigger_Opt_In_EmailMutation,
  Trigger_Opt_In_EmailMutationVariables
>;
export const RequestTaxFormDownloadDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "RequestTaxFormDownload" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "input" },
          },
          type: {
            kind: "NamedType",
            name: {
              kind: "Name",
              value: "Input_core_apimessages_RequestTaxFormDownloadRequest",
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "requestTaxFormDownload" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "input" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "taxFormDownload" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "status" },
                      },
                    ],
                  },
                },
                { kind: "Field", name: { kind: "Name", value: "error" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  RequestTaxFormDownloadMutation,
  RequestTaxFormDownloadMutationVariables
>;
export const FetchTaxFormDownloadDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "FetchTaxFormDownload" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "input" },
          },
          type: {
            kind: "NamedType",
            name: {
              kind: "Name",
              value: "Input_core_apimessages_TaxFormDownloadRequest",
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "taxFormDownload" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "input" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "taxFormDownload" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "status" },
                      },
                      { kind: "Field", name: { kind: "Name", value: "url" } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  FetchTaxFormDownloadMutation,
  FetchTaxFormDownloadMutationVariables
>;
export const Core_My_TaxProfile_QueryDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "Core_My_TaxProfile_Query" },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "me" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "uuid" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "shop" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "address" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "_id" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "uuid" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "name" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "streetAddress" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "extendedAddress" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "phone" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "locality" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "region" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "country" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "_id" },
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "countryCode",
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "name" },
                                  },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "postalCode" },
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "taxProfile" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "firstName" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "lastName" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "mask" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "name" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "tinType" },
                            },
                            {
                              kind: "Field",
                              name: {
                                kind: "Name",
                                value: "verificationStatus",
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "verifiedAt" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "address" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "_id" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "uuid" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "name" },
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "streetAddress",
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "extendedAddress",
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "phone" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "locality" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "region" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "regionName" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "country" },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "_id" },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "countryCode",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "name" },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "postalCode" },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Core_My_TaxProfile_QueryQuery,
  Core_My_TaxProfile_QueryQueryVariables
>;
export const Core_DeleteTaxProfileDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "Core_DeleteTaxProfile" },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "deleteTaxProfile" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Core_DeleteTaxProfileMutation,
  Core_DeleteTaxProfileMutationVariables
>;
export const Core_UpsertTaxProfileDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "Core_UpsertTaxProfile" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "input" },
          },
          type: {
            kind: "NamedType",
            name: { kind: "Name", value: "InputTaxProfileUpsertRequest" },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "upsertTaxProfile" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "input" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Core_UpsertTaxProfileMutation,
  Core_UpsertTaxProfileMutationVariables
>;
export const Core_CreateTradeInDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "Core_CreateTradeIn" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "input" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "CreateTradeInInput" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "createTradeIn" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "input" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "id" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "dealerAddress" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "streetAddress" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "locality" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "region" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "postalCode" },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Core_CreateTradeInMutation,
  Core_CreateTradeInMutationVariables
>;
export const Core_Trade_In_CP_EligibilityDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "Core_Trade_In_CP_Eligibility" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "canonicalProductId" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            alias: { kind: "Name", value: "canonicalProductEligibility" },
            name: { kind: "Name", value: "tradeInEligibility" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "canonicalProductId" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "canonicalProductId" },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "isEligible" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Core_Trade_In_CP_EligibilityQuery,
  Core_Trade_In_CP_EligibilityQueryVariables
>;
export const Core_Checkout_StartApplePayCheckoutDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "Core_Checkout_StartApplePayCheckout" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "input" },
          },
          type: {
            kind: "NamedType",
            name: {
              kind: "Name",
              value: "Input_core_apimessages_StartCheckoutRequest",
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "startCheckout" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "input" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "checkout" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      {
                        kind: "FragmentSpread",
                        name: { kind: "Name", value: "CartApplePayHookFields" },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "CheckoutApplePayLineItemFields" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Checkout" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "total" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "amount" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "amount" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "currency" },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "lineItems" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "type" } },
                { kind: "Field", name: { kind: "Name", value: "label" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "amount" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "amount" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "amountCents" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "currency" },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "CartApplePayHookFields" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Checkout" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "type" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "total" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "amount" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "amountCents" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "currency" },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "lineItems" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "type" } },
                { kind: "Field", name: { kind: "Name", value: "label" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "amount" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "amount" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "amountCents" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "currency" },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "CheckoutApplePayLineItemFields" },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Core_Checkout_StartApplePayCheckoutMutation,
  Core_Checkout_StartApplePayCheckoutMutationVariables
>;
export const Core_Checkout_UpdateApplePayShippingEstimateDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: {
        kind: "Name",
        value: "Core_Checkout_UpdateApplePayShippingEstimate",
      },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "input" },
          },
          type: {
            kind: "NamedType",
            name: {
              kind: "Name",
              value:
                "Input_core_apimessages_CheckoutUpdateShippingEstimateRequest",
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "updateCheckoutShippingEstimate" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "input" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "checkout" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      {
                        kind: "FragmentSpread",
                        name: { kind: "Name", value: "CartApplePayHookFields" },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "CheckoutApplePayLineItemFields" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Checkout" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "total" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "amount" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "amount" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "currency" },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "lineItems" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "type" } },
                { kind: "Field", name: { kind: "Name", value: "label" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "amount" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "amount" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "amountCents" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "currency" },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "CartApplePayHookFields" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Checkout" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "type" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "total" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "amount" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "amountCents" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "currency" },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "lineItems" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "type" } },
                { kind: "Field", name: { kind: "Name", value: "label" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "amount" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "amount" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "amountCents" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "currency" },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "CheckoutApplePayLineItemFields" },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Core_Checkout_UpdateApplePayShippingEstimateMutation,
  Core_Checkout_UpdateApplePayShippingEstimateMutationVariables
>;
export const User_Available_ActionsDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "User_Available_Actions" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "loggedOut" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "Boolean" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "me" },
            directives: [
              {
                kind: "Directive",
                name: { kind: "Name", value: "skip" },
                arguments: [
                  {
                    kind: "Argument",
                    name: { kind: "Name", value: "if" },
                    value: {
                      kind: "Variable",
                      name: { kind: "Name", value: "loggedOut" },
                    },
                  },
                ],
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                {
                  kind: "FragmentSpread",
                  name: { kind: "Name", value: "CreateUserActionMeData" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "availableActions" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "FragmentSpread",
                        name: {
                          kind: "Name",
                          value: "Item2AvailableActionsData",
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "CreateUserActionMeData" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "rql_Me" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "uuid" } },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "Item2AvailableActionsData" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "core_apimessages_AvailableAction" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "name" } },
          { kind: "Field", name: { kind: "Name", value: "status" } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  User_Available_ActionsQuery,
  User_Available_ActionsQueryVariables
>;
export const Core_User_Watchlist_LayoutDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "Core_User_Watchlist_Layout" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "useLiveListings" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "Boolean" },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "profileSlug" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "limit" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "Int" } },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "offset" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "Int" } },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            alias: { kind: "Name", value: "liveWatches" },
            name: { kind: "Name", value: "userWatchesSearch" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "listingsState" },
                      value: { kind: "EnumValue", value: "LIVE" },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "profileSlug" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "profileSlug" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "limit" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "limit" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "offset" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "offset" },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "pageInfo" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "total" } },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "nodes" },
                  directives: [
                    {
                      kind: "Directive",
                      name: { kind: "Name", value: "include" },
                      arguments: [
                        {
                          kind: "Argument",
                          name: { kind: "Name", value: "if" },
                          value: {
                            kind: "Variable",
                            name: { kind: "Name", value: "useLiveListings" },
                          },
                        },
                      ],
                    },
                  ],
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "listing" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "_id" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                            },
                            {
                              kind: "FragmentSpread",
                              name: { kind: "Name", value: "ListViewListings" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            alias: { kind: "Name", value: "endedWatches" },
            name: { kind: "Name", value: "userWatchesSearch" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "listingsState" },
                      value: { kind: "EnumValue", value: "NOT_LIVE" },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "profileSlug" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "profileSlug" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "limit" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "limit" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "offset" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "offset" },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "pageInfo" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "total" } },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "nodes" },
                  directives: [
                    {
                      kind: "Directive",
                      name: { kind: "Name", value: "skip" },
                      arguments: [
                        {
                          kind: "Argument",
                          name: { kind: "Name", value: "if" },
                          value: {
                            kind: "Variable",
                            name: { kind: "Name", value: "useLiveListings" },
                          },
                        },
                      ],
                    },
                  ],
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "listing" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "_id" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                            },
                            {
                              kind: "FragmentSpread",
                              name: { kind: "Name", value: "ListViewListings" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "user" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "slug" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "profileSlug" },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "firstName" } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "AddToCartButtonFields" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Listing" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "sellerId" } },
          { kind: "Field", name: { kind: "Name", value: "listingType" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "pricing" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "buyerPrice" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "amount" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "amountCents" },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "preorderInfo" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "onPreorder" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "estimatedShipDate" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "seconds" },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "ListingForBuyerShippingFields" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Listing" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "shipping" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "freeExpeditedShipping" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "localPickupOnly" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "shippingPrices" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "shippingMethod" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "carrierCalculated" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "regional" },
                      },
                      {
                        kind: "Field",
                        name: {
                          kind: "Name",
                          value: "destinationPostalCodeNeeded",
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "postalCode" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "rate" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "amount" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "amountCents" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "currency" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "display" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "ListingGreatValueData" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Listing" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "pricing" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "buyerPrice" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "currency" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "amountCents" },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "condition" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "conditionSlug" },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "priceRecommendation" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "priceMiddle" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "amountCents" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "currency" },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "shop" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "address" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "country" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "_id" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "countryCode" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          { kind: "Field", name: { kind: "Name", value: "currency" } },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "ListingCardCPOData" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Listing" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "certifiedPreOwned" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "title" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "badgeIconUrl" },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "ListingForBuyerFields" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Listing" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "title" } },
          { kind: "Field", name: { kind: "Name", value: "slug" } },
          { kind: "Field", name: { kind: "Name", value: "listingType" } },
          { kind: "Field", name: { kind: "Name", value: "make" } },
          { kind: "Field", name: { kind: "Name", value: "model" } },
          { kind: "Field", name: { kind: "Name", value: "upc" } },
          { kind: "Field", name: { kind: "Name", value: "state" } },
          { kind: "Field", name: { kind: "Name", value: "stateDescription" } },
          { kind: "Field", name: { kind: "Name", value: "bumped" } },
          { kind: "Field", name: { kind: "Name", value: "watching" } },
          { kind: "Field", name: { kind: "Name", value: "soldAsIs" } },
          { kind: "Field", name: { kind: "Name", value: "usOutlet" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "publishedAt" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "seconds" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "condition" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "displayName" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "conditionSlug" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "conditionUuid" },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "pricing" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "buyerPrice" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "display" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "currency" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "amount" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "amountCents" },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "originalPrice" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "display" },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "ribbon" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "display" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "reason" },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "images" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "transform" },
                      value: {
                        kind: "StringValue",
                        value: "card_square",
                        block: false,
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "count" },
                      value: { kind: "IntValue", value: "3" },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "scope" },
                      value: {
                        kind: "StringValue",
                        value: "photos",
                        block: false,
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "type" },
                      value: {
                        kind: "StringValue",
                        value: "Product",
                        block: false,
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "source" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "shipping" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "shippingPrices" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "shippingMethod" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "carrierCalculated" },
                      },
                      {
                        kind: "Field",
                        name: {
                          kind: "Name",
                          value: "destinationPostalCodeNeeded",
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "rate" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "amount" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "amountCents" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "currency" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "display" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "freeExpeditedShipping" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "localPickupOnly" },
                },
                { kind: "Field", name: { kind: "Name", value: "localPickup" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "shop" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "name" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "returnPolicy" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "usedReturnWindowDays" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "newReturnWindowDays" },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "address" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "locality" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "region" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "country" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "_id" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "countryCode" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "name" },
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "displayLocation" },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "ListingForBuyerShippingFields" },
          },
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "ListingGreatValueData" },
          },
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "ListingCardCPOData" },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "WatchBadgeData" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Listing" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "title" } },
          { kind: "Field", name: { kind: "Name", value: "sellerId" } },
          { kind: "Field", name: { kind: "Name", value: "watching" } },
          {
            kind: "Field",
            alias: { kind: "Name", value: "watchThumbnails" },
            name: { kind: "Name", value: "images" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "type" },
                      value: {
                        kind: "StringValue",
                        value: "Product",
                        block: false,
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "scope" },
                      value: {
                        kind: "StringValue",
                        value: "photos",
                        block: false,
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "transform" },
                      value: {
                        kind: "StringValue",
                        value: "card_square",
                        block: false,
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "count" },
                      value: { kind: "IntValue", value: "1" },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "source" } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "mParticleListingFields" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Listing" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "title" } },
          { kind: "Field", name: { kind: "Name", value: "brandSlug" } },
          { kind: "Field", name: { kind: "Name", value: "categoryRootUuid" } },
          { kind: "Field", name: { kind: "Name", value: "make" } },
          { kind: "Field", name: { kind: "Name", value: "categoryUuids" } },
          { kind: "Field", name: { kind: "Name", value: "state" } },
          { kind: "Field", name: { kind: "Name", value: "listingType" } },
          { kind: "Field", name: { kind: "Name", value: "bumpEligible" } },
          { kind: "Field", name: { kind: "Name", value: "shopId" } },
          { kind: "Field", name: { kind: "Name", value: "inventory" } },
          { kind: "Field", name: { kind: "Name", value: "soldAsIs" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "acceptedPaymentMethods" },
          },
          { kind: "Field", name: { kind: "Name", value: "currency" } },
          { kind: "Field", name: { kind: "Name", value: "usOutlet" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "condition" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "conditionUuid" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "conditionSlug" },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "categories" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "slug" } },
                { kind: "Field", name: { kind: "Name", value: "rootSlug" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "csp" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "slug" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "brand" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      { kind: "Field", name: { kind: "Name", value: "slug" } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "pricing" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "buyerPrice" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "amount" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "currency" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "amountCents" },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "publishedAt" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "seconds" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "sale" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "code" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "buyerIneligibilityReason" },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "shipping" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "shippingPrices" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "shippingMethod" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "carrierCalculated" },
                      },
                      {
                        kind: "Field",
                        name: {
                          kind: "Name",
                          value: "destinationPostalCodeNeeded",
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "rate" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "amount" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "amountCents" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "currency" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "display" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "freeExpeditedShipping" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "localPickupOnly" },
                },
                { kind: "Field", name: { kind: "Name", value: "localPickup" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "certifiedPreOwned" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "title" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "badgeIconUrl" },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "shop" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "address" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "countryCode" },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "returnPolicy" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "newReturnWindowDays" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "usedReturnWindowDays" },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "ListingCreateOfferButtonData" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Listing" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "state" } },
          { kind: "Field", name: { kind: "Name", value: "offersEnabled" } },
          { kind: "Field", name: { kind: "Name", value: "listingType" } },
          { kind: "Field", name: { kind: "Name", value: "sellerId" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "isBuyerOfferEligible" },
          },
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "mParticleListingFields" },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "ListingCardFields" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Listing" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "ListingForBuyerFields" },
          },
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "WatchBadgeData" },
          },
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "ListingCreateOfferButtonData" },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "InOtherCartsCardData" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Listing" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "otherBuyersWithListingInCartCounts" },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "ListViewListings" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Listing" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "categoryUuids" } },
          { kind: "Field", name: { kind: "Name", value: "state" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "shop" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "name" } },
                { kind: "Field", name: { kind: "Name", value: "slug" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "preferredSeller" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "quickShipper" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "quickResponder" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "address" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "locality" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "region" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "displayLocation" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "country" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "_id" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "countryCode" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "name" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "seller" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "feedbackSummary" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "receivedCount" },
                      },
                      {
                        kind: "Field",
                        name: {
                          kind: "Name",
                          value: "rollingRatingPercentage",
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "csp" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "webLink" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "href" } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "AddToCartButtonFields" },
          },
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "ListingCardFields" },
          },
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "ListingCreateOfferButtonData" },
          },
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "InOtherCartsCardData" },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Core_User_Watchlist_LayoutQuery,
  Core_User_Watchlist_LayoutQueryVariables
>;
export const MarketplaceQuerySuggestionsDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "MarketplaceQuerySuggestions" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "searchQueries" },
          },
          type: {
            kind: "ListType",
            type: {
              kind: "NamedType",
              name: {
                kind: "Name",
                value: "Input_reverb_personalization_SearchQuery",
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "querySuggestions" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "queries" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "searchQueries" },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "queries" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "suggestions" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "query" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "listings" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "_id" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "images" },
                                    arguments: [
                                      {
                                        kind: "Argument",
                                        name: { kind: "Name", value: "input" },
                                        value: {
                                          kind: "ObjectValue",
                                          fields: [
                                            {
                                              kind: "ObjectField",
                                              name: {
                                                kind: "Name",
                                                value: "type",
                                              },
                                              value: {
                                                kind: "StringValue",
                                                value: "Product",
                                                block: false,
                                              },
                                            },
                                            {
                                              kind: "ObjectField",
                                              name: {
                                                kind: "Name",
                                                value: "scope",
                                              },
                                              value: {
                                                kind: "StringValue",
                                                value: "photos",
                                                block: false,
                                              },
                                            },
                                            {
                                              kind: "ObjectField",
                                              name: {
                                                kind: "Name",
                                                value: "transform",
                                              },
                                              value: {
                                                kind: "StringValue",
                                                value: "card_square",
                                                block: false,
                                              },
                                            },
                                            {
                                              kind: "ObjectField",
                                              name: {
                                                kind: "Name",
                                                value: "count",
                                              },
                                              value: {
                                                kind: "IntValue",
                                                value: "1",
                                              },
                                            },
                                          ],
                                        },
                                      },
                                    ],
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "_id" },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "source",
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  MarketplaceQuerySuggestionsQuery,
  MarketplaceQuerySuggestionsQueryVariables
>;
export const Core_WithSuggestionCheck_ListingsSearchDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "Core_WithSuggestionCheck_ListingsSearch" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "query" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "listingsSearch" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "query" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "query" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "limit" },
                      value: { kind: "IntValue", value: "1" },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "total" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Core_WithSuggestionCheck_ListingsSearchQuery,
  Core_WithSuggestionCheck_ListingsSearchQueryVariables
>;
export const Core_AcceptTradeInDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "Core_AcceptTradeIn" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "input" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "AcceptTradeInInput" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "acceptTradeIn" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "input" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "id" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Core_AcceptTradeInMutation,
  Core_AcceptTradeInMutationVariables
>;
export const Core_RejectTradeInDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "Core_RejectTradeIn" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "input" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "RejectTradeInInput" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "rejectTradeIn" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "input" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "id" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Core_RejectTradeInMutation,
  Core_RejectTradeInMutationVariables
>;
export const Core_Dealer_Trade_InDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "Core_Dealer_Trade_In" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "tradeInId" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "tradeIn" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "id" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "tradeInId" },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "createdAt" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "haveAppraisalsChanged" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "canonicalProduct" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      { kind: "Field", name: { kind: "Name", value: "name" } },
                      { kind: "Field", name: { kind: "Name", value: "make" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "primaryImage" },
                        arguments: [
                          {
                            kind: "Argument",
                            name: { kind: "Name", value: "input" },
                            value: {
                              kind: "ObjectValue",
                              fields: [
                                {
                                  kind: "ObjectField",
                                  name: { kind: "Name", value: "transform" },
                                  value: {
                                    kind: "StringValue",
                                    value: "card_square",
                                    block: false,
                                  },
                                },
                              ],
                            },
                          },
                        ],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "_id" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "source" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "seller" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      { kind: "Field", name: { kind: "Name", value: "name" } },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "appraisals" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "condition" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "conditionUuid" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "conditionSlug" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "displayName" },
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "price" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "display" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "originalAppraisals" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "condition" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "conditionUuid" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "conditionSlug" },
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "price" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "display" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Core_Dealer_Trade_InQuery,
  Core_Dealer_Trade_InQueryVariables
>;
export const Core_Dealer_Trade_Ins_IndexDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "Core_Dealer_Trade_Ins_Index" },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "me" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "shop" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "tradeIns" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "_id" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "state" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "createdAt" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "canonicalProduct" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "name" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "make" },
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "primaryImage",
                                    },
                                    arguments: [
                                      {
                                        kind: "Argument",
                                        name: { kind: "Name", value: "input" },
                                        value: {
                                          kind: "ObjectValue",
                                          fields: [
                                            {
                                              kind: "ObjectField",
                                              name: {
                                                kind: "Name",
                                                value: "transform",
                                              },
                                              value: {
                                                kind: "StringValue",
                                                value: "card_square",
                                                block: false,
                                              },
                                            },
                                          ],
                                        },
                                      },
                                    ],
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "_id" },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "source",
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "seller" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "_id" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "name" },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Core_Dealer_Trade_Ins_IndexQuery,
  Core_Dealer_Trade_Ins_IndexQueryVariables
>;
export const SellerHub_BumpStatsDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "SellerHub_BumpStats" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "timePeriod" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "me" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "shop" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "bumpStats" },
                        arguments: [
                          {
                            kind: "Argument",
                            name: { kind: "Name", value: "input" },
                            value: {
                              kind: "ObjectValue",
                              fields: [
                                {
                                  kind: "ObjectField",
                                  name: { kind: "Name", value: "timePeriod" },
                                  value: {
                                    kind: "Variable",
                                    name: { kind: "Name", value: "timePeriod" },
                                  },
                                },
                              ],
                            },
                          },
                        ],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "brandNew" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "spent" },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "display",
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "sales" },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "display",
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "percentageSpent",
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "views" },
                                  },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "used" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "spent" },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "display",
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "sales" },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "display",
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "percentageSpent",
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "views" },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  SellerHub_BumpStatsQuery,
  SellerHub_BumpStatsQueryVariables
>;
export const SellerHub_EarningsDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "SellerHub_Earnings" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "timePeriod" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "me" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "shop" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "earnings" },
                        arguments: [
                          {
                            kind: "Argument",
                            name: { kind: "Name", value: "input" },
                            value: {
                              kind: "ObjectValue",
                              fields: [
                                {
                                  kind: "ObjectField",
                                  name: { kind: "Name", value: "timePeriod" },
                                  value: {
                                    kind: "Variable",
                                    name: { kind: "Name", value: "timePeriod" },
                                  },
                                },
                              ],
                            },
                          },
                        ],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "ordersTotal" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "display" },
                                  },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: {
                                kind: "Name",
                                value: "accommodationsTotal",
                              },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "amountCents",
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "display" },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  SellerHub_EarningsQuery,
  SellerHub_EarningsQueryVariables
>;
export const SellerHub_SellerFeedbackDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "SellerHub_SellerFeedback" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "userUuid" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "user" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "uuid" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "userUuid" },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "feedback" },
                  arguments: [
                    {
                      kind: "Argument",
                      name: { kind: "Name", value: "input" },
                      value: {
                        kind: "ObjectValue",
                        fields: [
                          {
                            kind: "ObjectField",
                            name: { kind: "Name", value: "type" },
                            value: { kind: "EnumValue", value: "SELLER" },
                          },
                          {
                            kind: "ObjectField",
                            name: { kind: "Name", value: "userUuid" },
                            value: {
                              kind: "Variable",
                              name: { kind: "Name", value: "userUuid" },
                            },
                          },
                        ],
                      },
                    },
                  ],
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "sellerRating" },
                      },
                      { kind: "Field", name: { kind: "Name", value: "total" } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  SellerHub_SellerFeedbackQuery,
  SellerHub_SellerFeedbackQueryVariables
>;
export const SellerHub_NewSellerPromptDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "SellerHub_NewSellerPrompt" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "limit" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "Int" } },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "createdAfterDate" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "ordersSearch" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "as" },
                      value: { kind: "EnumValue", value: "SELLER" },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "searchFilters" },
                      value: {
                        kind: "ObjectValue",
                        fields: [
                          {
                            kind: "ObjectField",
                            name: { kind: "Name", value: "limit" },
                            value: {
                              kind: "Variable",
                              name: { kind: "Name", value: "limit" },
                            },
                          },
                          {
                            kind: "ObjectField",
                            name: { kind: "Name", value: "createdAfterDate" },
                            value: {
                              kind: "Variable",
                              name: { kind: "Name", value: "createdAfterDate" },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "orders" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  SellerHub_NewSellerPromptQuery,
  SellerHub_NewSellerPromptQueryVariables
>;
export const SellerHub_ProfileDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "SellerHub_Profile" },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "me" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "firstName" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "avatar" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "source" },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "shop" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      { kind: "Field", name: { kind: "Name", value: "slug" } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  SellerHub_ProfileQuery,
  SellerHub_ProfileQueryVariables
>;
export const SellerHub_RecentMessagesDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "SellerHub_RecentMessages" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "limit" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "Int" } },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "unrepliedOnly" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "Boolean" } },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "me" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "myMessages" },
                  arguments: [
                    {
                      kind: "Argument",
                      name: { kind: "Name", value: "input" },
                      value: {
                        kind: "ObjectValue",
                        fields: [
                          {
                            kind: "ObjectField",
                            name: { kind: "Name", value: "limit" },
                            value: {
                              kind: "Variable",
                              name: { kind: "Name", value: "limit" },
                            },
                          },
                          {
                            kind: "ObjectField",
                            name: { kind: "Name", value: "unrepliedOnly" },
                            value: {
                              kind: "Variable",
                              name: { kind: "Name", value: "unrepliedOnly" },
                            },
                          },
                        ],
                      },
                    },
                  ],
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "body" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "createdAt" },
                      },
                      { kind: "Field", name: { kind: "Name", value: "read" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "conversationId" },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  SellerHub_RecentMessagesQuery,
  SellerHub_RecentMessagesQueryVariables
>;
export const SellerHub_RecentOffersDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "SellerHub_RecentOffers" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "limit" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "Int" } },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "state" },
          },
          type: {
            kind: "NamedType",
            name: { kind: "Name", value: "rql_NegotiationQuery_State" },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "me" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "myNegotiations" },
                  arguments: [
                    {
                      kind: "Argument",
                      name: { kind: "Name", value: "input" },
                      value: {
                        kind: "ObjectValue",
                        fields: [
                          {
                            kind: "ObjectField",
                            name: { kind: "Name", value: "as" },
                            value: { kind: "EnumValue", value: "SELLER" },
                          },
                          {
                            kind: "ObjectField",
                            name: { kind: "Name", value: "limit" },
                            value: {
                              kind: "Variable",
                              name: { kind: "Name", value: "limit" },
                            },
                          },
                          {
                            kind: "ObjectField",
                            name: { kind: "Name", value: "sort" },
                            value: {
                              kind: "StringValue",
                              value: "updated_at|desc",
                              block: false,
                            },
                          },
                          {
                            kind: "ObjectField",
                            name: { kind: "Name", value: "state" },
                            value: {
                              kind: "Variable",
                              name: { kind: "Name", value: "state" },
                            },
                          },
                        ],
                      },
                    },
                  ],
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "negotiations" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "_id" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "buyer" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "_id" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "shortname" },
                                  },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "expiresAt" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "seconds" },
                                  },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "taxIncluded" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "taxIncludedHint" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "shippingLocation" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "_id" },
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "displayLocation",
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "offers" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "status" },
                                  },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "lastOffer" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "initiatingParty",
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "status" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "createdAt" },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "seconds",
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "prices" },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "totalPrice",
                                          },
                                          selectionSet: {
                                            kind: "SelectionSet",
                                            selections: [
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "display",
                                                },
                                                selectionSet: {
                                                  kind: "SelectionSet",
                                                  selections: [
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "display",
                                                      },
                                                    },
                                                  ],
                                                },
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "offerItems" },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "listing",
                                          },
                                          selectionSet: {
                                            kind: "SelectionSet",
                                            selections: [
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "_id",
                                                },
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "title",
                                                },
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "images",
                                                },
                                                arguments: [
                                                  {
                                                    kind: "Argument",
                                                    name: {
                                                      kind: "Name",
                                                      value: "input",
                                                    },
                                                    value: {
                                                      kind: "ObjectValue",
                                                      fields: [
                                                        {
                                                          kind: "ObjectField",
                                                          name: {
                                                            kind: "Name",
                                                            value: "transform",
                                                          },
                                                          value: {
                                                            kind: "StringValue",
                                                            value: "thumb",
                                                            block: false,
                                                          },
                                                        },
                                                        {
                                                          kind: "ObjectField",
                                                          name: {
                                                            kind: "Name",
                                                            value: "count",
                                                          },
                                                          value: {
                                                            kind: "IntValue",
                                                            value: "1",
                                                          },
                                                        },
                                                        {
                                                          kind: "ObjectField",
                                                          name: {
                                                            kind: "Name",
                                                            value: "scope",
                                                          },
                                                          value: {
                                                            kind: "StringValue",
                                                            value: "photos",
                                                            block: false,
                                                          },
                                                        },
                                                        {
                                                          kind: "ObjectField",
                                                          name: {
                                                            kind: "Name",
                                                            value: "type",
                                                          },
                                                          value: {
                                                            kind: "StringValue",
                                                            value: "Product",
                                                            block: false,
                                                          },
                                                        },
                                                      ],
                                                    },
                                                  },
                                                ],
                                                selectionSet: {
                                                  kind: "SelectionSet",
                                                  selections: [
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "_id",
                                                      },
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "source",
                                                      },
                                                    },
                                                  ],
                                                },
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  SellerHub_RecentOffersQuery,
  SellerHub_RecentOffersQueryVariables
>;
export const SellerHub_RecentOrdersDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "SellerHub_RecentOrders" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "limit" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "Int" } },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "ordersSearch" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "as" },
                      value: { kind: "EnumValue", value: "SELLER" },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "searchFilters" },
                      value: {
                        kind: "ObjectValue",
                        fields: [
                          {
                            kind: "ObjectField",
                            name: { kind: "Name", value: "limit" },
                            value: {
                              kind: "Variable",
                              name: { kind: "Name", value: "limit" },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "orders" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "legacyOrderId" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "listing" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "_id" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "title" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "images" },
                              arguments: [
                                {
                                  kind: "Argument",
                                  name: { kind: "Name", value: "input" },
                                  value: {
                                    kind: "ObjectValue",
                                    fields: [
                                      {
                                        kind: "ObjectField",
                                        name: {
                                          kind: "Name",
                                          value: "transform",
                                        },
                                        value: {
                                          kind: "StringValue",
                                          value: "thumb",
                                          block: false,
                                        },
                                      },
                                      {
                                        kind: "ObjectField",
                                        name: { kind: "Name", value: "count" },
                                        value: { kind: "IntValue", value: "1" },
                                      },
                                      {
                                        kind: "ObjectField",
                                        name: { kind: "Name", value: "scope" },
                                        value: {
                                          kind: "StringValue",
                                          value: "photos",
                                          block: false,
                                        },
                                      },
                                      {
                                        kind: "ObjectField",
                                        name: { kind: "Name", value: "type" },
                                        value: {
                                          kind: "StringValue",
                                          value: "Product",
                                          block: false,
                                        },
                                      },
                                    ],
                                  },
                                },
                              ],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "_id" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "source" },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "sellerOrder" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "shippingLabel" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "purchaseShippingLabelWebUrl",
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "orderViewStatus" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "status" },
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "description",
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  SellerHub_RecentOrdersQuery,
  SellerHub_RecentOrdersQueryVariables
>;
export const SellerHub_RecommendedDirectOffersDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "SellerHub_RecommendedDirectOffers" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "sort" } },
          type: {
            kind: "NamedType",
            name: {
              kind: "Name",
              value: "reverb_search_ListingsSearchRequest_Sort",
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "limit" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "Int" } },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "hasWatchers" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "Boolean" } },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "noAutoOffers" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "Boolean" } },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "me" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "shop" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "listings" },
                        arguments: [
                          {
                            kind: "Argument",
                            name: { kind: "Name", value: "input" },
                            value: {
                              kind: "ObjectValue",
                              fields: [
                                {
                                  kind: "ObjectField",
                                  name: { kind: "Name", value: "statuses" },
                                  value: {
                                    kind: "ListValue",
                                    values: [
                                      {
                                        kind: "StringValue",
                                        value: "live",
                                        block: false,
                                      },
                                    ],
                                  },
                                },
                                {
                                  kind: "ObjectField",
                                  name: { kind: "Name", value: "sort" },
                                  value: {
                                    kind: "Variable",
                                    name: { kind: "Name", value: "sort" },
                                  },
                                },
                                {
                                  kind: "ObjectField",
                                  name: { kind: "Name", value: "limit" },
                                  value: {
                                    kind: "Variable",
                                    name: { kind: "Name", value: "limit" },
                                  },
                                },
                                {
                                  kind: "ObjectField",
                                  name: { kind: "Name", value: "hasWatchers" },
                                  value: {
                                    kind: "Variable",
                                    name: {
                                      kind: "Name",
                                      value: "hasWatchers",
                                    },
                                  },
                                },
                                {
                                  kind: "ObjectField",
                                  name: { kind: "Name", value: "noAutoOffers" },
                                  value: {
                                    kind: "Variable",
                                    name: {
                                      kind: "Name",
                                      value: "noAutoOffers",
                                    },
                                  },
                                },
                              ],
                            },
                          },
                        ],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "listings" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "_id" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  SellerHub_RecommendedDirectOffersQuery,
  SellerHub_RecommendedDirectOffersQueryVariables
>;
export const SellerHub_RecommendedDirectOfferDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "SellerHub_RecommendedDirectOffer" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "id" } },
          type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "listing" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "id" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "id" },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "title" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "counts" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "watchers" },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "autoOffers" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "images" },
                  arguments: [
                    {
                      kind: "Argument",
                      name: { kind: "Name", value: "input" },
                      value: {
                        kind: "ObjectValue",
                        fields: [
                          {
                            kind: "ObjectField",
                            name: { kind: "Name", value: "transform" },
                            value: {
                              kind: "StringValue",
                              value: "thumb",
                              block: false,
                            },
                          },
                          {
                            kind: "ObjectField",
                            name: { kind: "Name", value: "count" },
                            value: { kind: "IntValue", value: "1" },
                          },
                          {
                            kind: "ObjectField",
                            name: { kind: "Name", value: "scope" },
                            value: {
                              kind: "StringValue",
                              value: "photos",
                              block: false,
                            },
                          },
                          {
                            kind: "ObjectField",
                            name: { kind: "Name", value: "type" },
                            value: {
                              kind: "StringValue",
                              value: "Product",
                              block: false,
                            },
                          },
                        ],
                      },
                    },
                  ],
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "source" },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  SellerHub_RecommendedDirectOfferQuery,
  SellerHub_RecommendedDirectOfferQueryVariables
>;
export const SellerHub_RecommendedPriceDropsDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "SellerHub_RecommendedPriceDrops" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "sort" } },
          type: {
            kind: "NamedType",
            name: {
              kind: "Name",
              value: "reverb_search_ListingsSearchRequest_Sort",
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "limit" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "Int" } },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "notGreatValueForUsedListings" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "Boolean" } },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "me" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "shop" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "listings" },
                        arguments: [
                          {
                            kind: "Argument",
                            name: { kind: "Name", value: "input" },
                            value: {
                              kind: "ObjectValue",
                              fields: [
                                {
                                  kind: "ObjectField",
                                  name: { kind: "Name", value: "statuses" },
                                  value: {
                                    kind: "ListValue",
                                    values: [
                                      {
                                        kind: "StringValue",
                                        value: "live",
                                        block: false,
                                      },
                                    ],
                                  },
                                },
                                {
                                  kind: "ObjectField",
                                  name: { kind: "Name", value: "sort" },
                                  value: {
                                    kind: "Variable",
                                    name: { kind: "Name", value: "sort" },
                                  },
                                },
                                {
                                  kind: "ObjectField",
                                  name: { kind: "Name", value: "limit" },
                                  value: {
                                    kind: "Variable",
                                    name: { kind: "Name", value: "limit" },
                                  },
                                },
                                {
                                  kind: "ObjectField",
                                  name: {
                                    kind: "Name",
                                    value: "notGreatValueForUsedListings",
                                  },
                                  value: {
                                    kind: "Variable",
                                    name: {
                                      kind: "Name",
                                      value: "notGreatValueForUsedListings",
                                    },
                                  },
                                },
                              ],
                            },
                          },
                        ],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "listings" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "_id" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  SellerHub_RecommendedPriceDropsQuery,
  SellerHub_RecommendedPriceDropsQueryVariables
>;
export const SellerHub_RecommendedPriceDropDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "SellerHub_RecommendedPriceDrop" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "id" } },
          type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "shopCurrency" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "shopCountryCode" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "listing" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "id" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "id" },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "title" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "sellerPrice" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "display" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "amountCents" },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "priceRecommendation" },
                  arguments: [
                    {
                      kind: "Argument",
                      name: { kind: "Name", value: "input" },
                      value: {
                        kind: "ObjectValue",
                        fields: [
                          {
                            kind: "ObjectField",
                            name: { kind: "Name", value: "currency" },
                            value: {
                              kind: "Variable",
                              name: { kind: "Name", value: "shopCurrency" },
                            },
                          },
                          {
                            kind: "ObjectField",
                            name: { kind: "Name", value: "countryCode" },
                            value: {
                              kind: "Variable",
                              name: { kind: "Name", value: "shopCountryCode" },
                            },
                          },
                        ],
                      },
                    },
                  ],
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "priceMiddle" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "display" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "amountCents" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "images" },
                  arguments: [
                    {
                      kind: "Argument",
                      name: { kind: "Name", value: "input" },
                      value: {
                        kind: "ObjectValue",
                        fields: [
                          {
                            kind: "ObjectField",
                            name: { kind: "Name", value: "transform" },
                            value: {
                              kind: "StringValue",
                              value: "thumb",
                              block: false,
                            },
                          },
                          {
                            kind: "ObjectField",
                            name: { kind: "Name", value: "count" },
                            value: { kind: "IntValue", value: "1" },
                          },
                          {
                            kind: "ObjectField",
                            name: { kind: "Name", value: "scope" },
                            value: {
                              kind: "StringValue",
                              value: "photos",
                              block: false,
                            },
                          },
                          {
                            kind: "ObjectField",
                            name: { kind: "Name", value: "type" },
                            value: {
                              kind: "StringValue",
                              value: "Product",
                              block: false,
                            },
                          },
                        ],
                      },
                    },
                  ],
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "source" },
                      },
                    ],
                  },
                },
                { kind: "Field", name: { kind: "Name", value: "sellerUuid" } },
                { kind: "Field", name: { kind: "Name", value: "vatIncluded" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "vatIncludedHint" },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  SellerHub_RecommendedPriceDropQuery,
  SellerHub_RecommendedPriceDropQueryVariables
>;
export const SellerHub_ShopBadgesDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "SellerHub_ShopBadges" },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "me" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "shop" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "badges" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "badgeType" },
                            },
                            {
                              kind: "Field",
                              name: {
                                kind: "Name",
                                value: "completedPercentage",
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "requirements" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "requirement",
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "completed" },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  SellerHub_ShopBadgesQuery,
  SellerHub_ShopBadgesQueryVariables
>;
export const SellerHub_ShopStatsDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "SellerHub_ShopStats" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "timePeriod" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "me" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "uuid" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "shop" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "shopStats" },
                        arguments: [
                          {
                            kind: "Argument",
                            name: { kind: "Name", value: "input" },
                            value: {
                              kind: "ObjectValue",
                              fields: [
                                {
                                  kind: "ObjectField",
                                  name: { kind: "Name", value: "timePeriod" },
                                  value: {
                                    kind: "Variable",
                                    name: { kind: "Name", value: "timePeriod" },
                                  },
                                },
                              ],
                            },
                          },
                        ],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "salesChartData" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "currencySymbol",
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "salesInMoreThanOneCurrency",
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "labels" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "series" },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "dataSet",
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "comparisonStats" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "currentStats",
                                    },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "messages",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "offers",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "orders",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "watchers",
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "changeStats",
                                    },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "messages",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "offers",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "orders",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "watchers",
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  SellerHub_ShopStatsQuery,
  SellerHub_ShopStatsQueryVariables
>;
export const Search_MyListings_RecommendationsDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "Search_MyListings_Recommendations" },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "me" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "uuid" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "shop" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "listings" },
                        arguments: [
                          {
                            kind: "Argument",
                            name: { kind: "Name", value: "input" },
                            value: {
                              kind: "ObjectValue",
                              fields: [
                                {
                                  kind: "ObjectField",
                                  name: { kind: "Name", value: "statuses" },
                                  value: {
                                    kind: "ListValue",
                                    values: [
                                      {
                                        kind: "StringValue",
                                        value: "live",
                                        block: false,
                                      },
                                    ],
                                  },
                                },
                                {
                                  kind: "ObjectField",
                                  name: {
                                    kind: "Name",
                                    value: "withListingRecommendationStats",
                                  },
                                  value: { kind: "BooleanValue", value: true },
                                },
                              ],
                            },
                          },
                        ],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: {
                                kind: "Name",
                                value: "listingRecommendationStats",
                              },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "totalGreaterThanCompetitivePrice",
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value:
                                        "totalWithWatchersAndWithoutAutoOffers",
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Search_MyListings_RecommendationsQuery,
  Search_MyListings_RecommendationsQueryVariables
>;
export const Core_Listing_DigitalDetailsDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "Core_Listing_DigitalDetails" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "id" } },
          type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "listing" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "id" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "id" },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                {
                  kind: "FragmentSpread",
                  name: { kind: "Name", value: "DigitalDetailsFields" },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "DigitalDetailsFields" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Listing" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "listingType" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "digitalDetails" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "variants" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "downloadText" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "fileUrl" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "fileSize" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "operatingSystems" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "fileTypes" },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "supportedFormats" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "operatingSystem" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "fileTypes" },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Core_Listing_DigitalDetailsQuery,
  Core_Listing_DigitalDetailsQueryVariables
>;
export const DiscoverDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "Discover" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "offset" },
          },
          type: {
            kind: "NonNullType",
            type: { kind: "NamedType", name: { kind: "Name", value: "Int" } },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "typesToInclude" },
          },
          type: {
            kind: "ListType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "rql_DiscoverInclude" },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "limit" },
          },
          type: {
            kind: "NonNullType",
            type: { kind: "NamedType", name: { kind: "Name", value: "Int" } },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "me" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "id" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "discover" },
                  arguments: [
                    {
                      kind: "Argument",
                      name: { kind: "Name", value: "input" },
                      value: {
                        kind: "ObjectValue",
                        fields: [
                          {
                            kind: "ObjectField",
                            name: { kind: "Name", value: "limit" },
                            value: {
                              kind: "Variable",
                              name: { kind: "Name", value: "limit" },
                            },
                          },
                          {
                            kind: "ObjectField",
                            name: { kind: "Name", value: "offset" },
                            value: {
                              kind: "Variable",
                              name: { kind: "Name", value: "offset" },
                            },
                          },
                          {
                            kind: "ObjectField",
                            name: { kind: "Name", value: "typesToInclude" },
                            value: {
                              kind: "Variable",
                              name: { kind: "Name", value: "typesToInclude" },
                            },
                          },
                        ],
                      },
                    },
                  ],
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "entries" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "__typename" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "type" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "description" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "listing" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "_id" },
                                  },
                                  {
                                    kind: "FragmentSpread",
                                    name: {
                                      kind: "Name",
                                      value: "ListingsCollection",
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "ad" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "FragmentSpread",
                                    name: { kind: "Name", value: "AdData" },
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "imageAdCreative",
                                    },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "FragmentSpread",
                                          name: {
                                            kind: "Name",
                                            value: "ImageAdCreativeData",
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "update" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "entity" },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "_id" },
                                        },
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "id" },
                                        },
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "type" },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "listings",
                                          },
                                          selectionSet: {
                                            kind: "SelectionSet",
                                            selections: [
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "_id",
                                                },
                                              },
                                              {
                                                kind: "FragmentSpread",
                                                name: {
                                                  kind: "Name",
                                                  value: "ListingsCollection",
                                                },
                                              },
                                            ],
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "listing",
                                          },
                                          selectionSet: {
                                            kind: "SelectionSet",
                                            selections: [
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "_id",
                                                },
                                              },
                                              {
                                                kind: "FragmentSpread",
                                                name: {
                                                  kind: "Name",
                                                  value: "ListingsCollection",
                                                },
                                              },
                                            ],
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "priceDrop",
                                          },
                                          selectionSet: {
                                            kind: "SelectionSet",
                                            selections: [
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "_id",
                                                },
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "listing",
                                                },
                                                selectionSet: {
                                                  kind: "SelectionSet",
                                                  selections: [
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "_id",
                                                      },
                                                    },
                                                    {
                                                      kind: "FragmentSpread",
                                                      name: {
                                                        kind: "Name",
                                                        value:
                                                          "ListingsCollection",
                                                      },
                                                    },
                                                  ],
                                                },
                                              },
                                            ],
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "promotion",
                                          },
                                          selectionSet: {
                                            kind: "SelectionSet",
                                            selections: [
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "_id",
                                                },
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "title",
                                                },
                                              },
                                            ],
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "shop" },
                                          selectionSet: {
                                            kind: "SelectionSet",
                                            selections: [
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "_id",
                                                },
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "name",
                                                },
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "images",
                                                },
                                                arguments: [
                                                  {
                                                    kind: "Argument",
                                                    name: {
                                                      kind: "Name",
                                                      value: "input",
                                                    },
                                                    value: {
                                                      kind: "ObjectValue",
                                                      fields: [
                                                        {
                                                          kind: "ObjectField",
                                                          name: {
                                                            kind: "Name",
                                                            value: "type",
                                                          },
                                                          value: {
                                                            kind: "StringValue",
                                                            value: "Shop",
                                                            block: false,
                                                          },
                                                        },
                                                        {
                                                          kind: "ObjectField",
                                                          name: {
                                                            kind: "Name",
                                                            value: "transform",
                                                          },
                                                          value: {
                                                            kind: "StringValue",
                                                            value:
                                                              "card_square",
                                                            block: false,
                                                          },
                                                        },
                                                      ],
                                                    },
                                                  },
                                                ],
                                                selectionSet: {
                                                  kind: "SelectionSet",
                                                  selections: [
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "_id",
                                                      },
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "source",
                                                      },
                                                    },
                                                  ],
                                                },
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "article" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "_id" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "title" },
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "articleCategories",
                                    },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "name" },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "webLink" },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "href" },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "images" },
                                    arguments: [
                                      {
                                        kind: "Argument",
                                        name: { kind: "Name", value: "input" },
                                        value: {
                                          kind: "ObjectValue",
                                          fields: [
                                            {
                                              kind: "ObjectField",
                                              name: {
                                                kind: "Name",
                                                value: "type",
                                              },
                                              value: {
                                                kind: "StringValue",
                                                value: "Article",
                                                block: false,
                                              },
                                            },
                                            {
                                              kind: "ObjectField",
                                              name: {
                                                kind: "Name",
                                                value: "scope",
                                              },
                                              value: {
                                                kind: "StringValue",
                                                value: "full_bleed_image",
                                                block: false,
                                              },
                                            },
                                            {
                                              kind: "ObjectField",
                                              name: {
                                                kind: "Name",
                                                value: "transform",
                                              },
                                              value: {
                                                kind: "StringValue",
                                                value: "card_wide",
                                                block: false,
                                              },
                                            },
                                          ],
                                        },
                                      },
                                    ],
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "_id" },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "source",
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "video" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "description",
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "title" },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "AddToCartButtonFields" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Listing" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "sellerId" } },
          { kind: "Field", name: { kind: "Name", value: "listingType" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "pricing" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "buyerPrice" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "amount" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "amountCents" },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "preorderInfo" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "onPreorder" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "estimatedShipDate" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "seconds" },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "WatchBadgeData" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Listing" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "title" } },
          { kind: "Field", name: { kind: "Name", value: "sellerId" } },
          { kind: "Field", name: { kind: "Name", value: "watching" } },
          {
            kind: "Field",
            alias: { kind: "Name", value: "watchThumbnails" },
            name: { kind: "Name", value: "images" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "type" },
                      value: {
                        kind: "StringValue",
                        value: "Product",
                        block: false,
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "scope" },
                      value: {
                        kind: "StringValue",
                        value: "photos",
                        block: false,
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "transform" },
                      value: {
                        kind: "StringValue",
                        value: "card_square",
                        block: false,
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "count" },
                      value: { kind: "IntValue", value: "1" },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "source" } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "ListingGreatValueData" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Listing" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "pricing" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "buyerPrice" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "currency" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "amountCents" },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "condition" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "conditionSlug" },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "priceRecommendation" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "priceMiddle" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "amountCents" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "currency" },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "shop" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "address" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "country" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "_id" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "countryCode" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          { kind: "Field", name: { kind: "Name", value: "currency" } },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "mParticleListingFields" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Listing" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "title" } },
          { kind: "Field", name: { kind: "Name", value: "brandSlug" } },
          { kind: "Field", name: { kind: "Name", value: "categoryRootUuid" } },
          { kind: "Field", name: { kind: "Name", value: "make" } },
          { kind: "Field", name: { kind: "Name", value: "categoryUuids" } },
          { kind: "Field", name: { kind: "Name", value: "state" } },
          { kind: "Field", name: { kind: "Name", value: "listingType" } },
          { kind: "Field", name: { kind: "Name", value: "bumpEligible" } },
          { kind: "Field", name: { kind: "Name", value: "shopId" } },
          { kind: "Field", name: { kind: "Name", value: "inventory" } },
          { kind: "Field", name: { kind: "Name", value: "soldAsIs" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "acceptedPaymentMethods" },
          },
          { kind: "Field", name: { kind: "Name", value: "currency" } },
          { kind: "Field", name: { kind: "Name", value: "usOutlet" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "condition" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "conditionUuid" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "conditionSlug" },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "categories" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "slug" } },
                { kind: "Field", name: { kind: "Name", value: "rootSlug" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "csp" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "slug" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "brand" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      { kind: "Field", name: { kind: "Name", value: "slug" } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "pricing" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "buyerPrice" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "amount" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "currency" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "amountCents" },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "publishedAt" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "seconds" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "sale" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "code" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "buyerIneligibilityReason" },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "shipping" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "shippingPrices" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "shippingMethod" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "carrierCalculated" },
                      },
                      {
                        kind: "Field",
                        name: {
                          kind: "Name",
                          value: "destinationPostalCodeNeeded",
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "rate" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "amount" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "amountCents" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "currency" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "display" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "freeExpeditedShipping" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "localPickupOnly" },
                },
                { kind: "Field", name: { kind: "Name", value: "localPickup" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "certifiedPreOwned" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "title" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "badgeIconUrl" },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "shop" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "address" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "countryCode" },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "returnPolicy" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "newReturnWindowDays" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "usedReturnWindowDays" },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "ListingCreateOfferButtonData" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Listing" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "state" } },
          { kind: "Field", name: { kind: "Name", value: "offersEnabled" } },
          { kind: "Field", name: { kind: "Name", value: "listingType" } },
          { kind: "Field", name: { kind: "Name", value: "sellerId" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "isBuyerOfferEligible" },
          },
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "mParticleListingFields" },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "ListingImagesFields" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Listing" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "images" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "transform" },
                      value: {
                        kind: "StringValue",
                        value: "card_square",
                        block: false,
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "count" },
                      value: { kind: "IntValue", value: "3" },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "scope" },
                      value: {
                        kind: "StringValue",
                        value: "photos",
                        block: false,
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "type" },
                      value: {
                        kind: "StringValue",
                        value: "Product",
                        block: false,
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "source" } },
              ],
            },
          },
          {
            kind: "Field",
            alias: { kind: "Name", value: "largeSquareImages" },
            name: { kind: "Name", value: "images" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "transform" },
                      value: {
                        kind: "StringValue",
                        value: "large_square",
                        block: false,
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "count" },
                      value: { kind: "IntValue", value: "3" },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "scope" },
                      value: {
                        kind: "StringValue",
                        value: "photos",
                        block: false,
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "type" },
                      value: {
                        kind: "StringValue",
                        value: "Product",
                        block: false,
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "source" } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "ListingCardCPOData" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Listing" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "certifiedPreOwned" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "title" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "badgeIconUrl" },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "ListingsCollection" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Listing" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "usOutlet" } },
          { kind: "Field", name: { kind: "Name", value: "bumped" } },
          { kind: "Field", name: { kind: "Name", value: "categoryUuids" } },
          { kind: "Field", name: { kind: "Name", value: "slug" } },
          { kind: "Field", name: { kind: "Name", value: "title" } },
          { kind: "Field", name: { kind: "Name", value: "description" } },
          { kind: "Field", name: { kind: "Name", value: "listingType" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "condition" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "displayName" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "conditionSlug" },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "price" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "amountCents" } },
                { kind: "Field", name: { kind: "Name", value: "display" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "pricing" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "buyerPrice" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "display" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "currency" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "amountCents" },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "originalPrice" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "display" },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "ribbon" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "display" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "reason" },
                      },
                    ],
                  },
                },
              ],
            },
          },
          { kind: "Field", name: { kind: "Name", value: "watching" } },
          { kind: "Field", name: { kind: "Name", value: "state" } },
          { kind: "Field", name: { kind: "Name", value: "stateDescription" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "shipping" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "shippingPrices" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "shippingMethod" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "carrierCalculated" },
                      },
                      {
                        kind: "Field",
                        name: {
                          kind: "Name",
                          value: "destinationPostalCodeNeeded",
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "rate" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "amount" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "amountCents" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "currency" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "display" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "freeExpeditedShipping" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "localPickupOnly" },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "shop" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "address" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "country" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "_id" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "countryCode" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "name" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "AddToCartButtonFields" },
          },
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "WatchBadgeData" },
          },
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "ListingGreatValueData" },
          },
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "ListingCreateOfferButtonData" },
          },
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "ListingImagesFields" },
          },
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "ListingCardCPOData" },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "AdData" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "core_apimessages_Ad" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "uuid" } },
          { kind: "Field", name: { kind: "Name", value: "format" } },
          { kind: "Field", name: { kind: "Name", value: "adGroupUuid" } },
          { kind: "Field", name: { kind: "Name", value: "adGroupName" } },
          { kind: "Field", name: { kind: "Name", value: "adCampaignUuid" } },
          { kind: "Field", name: { kind: "Name", value: "advertiserUuid" } },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "ImageAdCreativeData" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "core_apimessages_ImageAdCreative" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "title" } },
          { kind: "Field", name: { kind: "Name", value: "titleColor" } },
          { kind: "Field", name: { kind: "Name", value: "subtitle" } },
          { kind: "Field", name: { kind: "Name", value: "subtitleColor" } },
          { kind: "Field", name: { kind: "Name", value: "ctaText" } },
          { kind: "Field", name: { kind: "Name", value: "ctaHref" } },
          { kind: "Field", name: { kind: "Name", value: "ctaTextColor" } },
          { kind: "Field", name: { kind: "Name", value: "ctaButtonColor" } },
          { kind: "Field", name: { kind: "Name", value: "backgroundImage" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "mobileBackgroundImage" },
          },
          { kind: "Field", name: { kind: "Name", value: "backgroundColor" } },
          { kind: "Field", name: { kind: "Name", value: "backgroundColors" } },
          { kind: "Field", name: { kind: "Name", value: "overlayImage" } },
          { kind: "Field", name: { kind: "Name", value: "description" } },
          { kind: "Field", name: { kind: "Name", value: "partnershipText" } },
          { kind: "Field", name: { kind: "Name", value: "hideAdCallout" } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<DiscoverQuery, DiscoverQueryVariables>;
export const Core_Feed_SearchDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "Core_Feed_Search" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "query" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "currency" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "priceMax" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "priceMin" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "yearMax" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "yearMin" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "sortSlug" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "limit" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "Int" } },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "offset" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "Int" } },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "categorySlugs" },
          },
          type: {
            kind: "ListType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "brandSlugs" },
          },
          type: {
            kind: "ListType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "aggs" } },
          type: {
            kind: "ListType",
            type: {
              kind: "NamedType",
              name: {
                kind: "Name",
                value: "reverb_search_ListingsSearchRequest_Aggregation",
              },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "conditionSlugs" },
          },
          type: {
            kind: "ListType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "onSale" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "Boolean" } },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "handmade" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "Boolean" } },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "freeShipping" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "Boolean" } },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "freeExpeditedShipping" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "Boolean" } },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "acceptsOffers" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "Boolean" } },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "acceptsGiftCards" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "Boolean" } },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "preferredSeller" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "Boolean" } },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "acceptsPaymentPlans" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "Boolean" } },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "itemRegion" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "shippingRegionCode" },
          },
          type: {
            kind: "ListType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "decades" },
          },
          type: {
            kind: "ListType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "showListingLocation" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "Boolean" },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "dealsAndSteals" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "Boolean" } },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "me" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "uuid" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "follows" },
                  arguments: [
                    {
                      kind: "Argument",
                      name: { kind: "Name", value: "input" },
                      value: {
                        kind: "ObjectValue",
                        fields: [
                          {
                            kind: "ObjectField",
                            name: { kind: "Name", value: "channel" },
                            value: { kind: "EnumValue", value: "REVERB" },
                          },
                        ],
                      },
                    },
                  ],
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "total" } },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "feed" },
                  arguments: [
                    {
                      kind: "Argument",
                      name: { kind: "Name", value: "input" },
                      value: {
                        kind: "ObjectValue",
                        fields: [
                          {
                            kind: "ObjectField",
                            name: { kind: "Name", value: "channel" },
                            value: { kind: "EnumValue", value: "REVERB" },
                          },
                          {
                            kind: "ObjectField",
                            name: { kind: "Name", value: "offset" },
                            value: {
                              kind: "Variable",
                              name: { kind: "Name", value: "offset" },
                            },
                          },
                          {
                            kind: "ObjectField",
                            name: { kind: "Name", value: "limit" },
                            value: {
                              kind: "Variable",
                              name: { kind: "Name", value: "limit" },
                            },
                          },
                          {
                            kind: "ObjectField",
                            name: { kind: "Name", value: "listingsFilter" },
                            value: {
                              kind: "ObjectValue",
                              fields: [
                                {
                                  kind: "ObjectField",
                                  name: { kind: "Name", value: "query" },
                                  value: {
                                    kind: "Variable",
                                    name: { kind: "Name", value: "query" },
                                  },
                                },
                                {
                                  kind: "ObjectField",
                                  name: { kind: "Name", value: "currency" },
                                  value: {
                                    kind: "Variable",
                                    name: { kind: "Name", value: "currency" },
                                  },
                                },
                                {
                                  kind: "ObjectField",
                                  name: { kind: "Name", value: "priceMax" },
                                  value: {
                                    kind: "Variable",
                                    name: { kind: "Name", value: "priceMax" },
                                  },
                                },
                                {
                                  kind: "ObjectField",
                                  name: { kind: "Name", value: "priceMin" },
                                  value: {
                                    kind: "Variable",
                                    name: { kind: "Name", value: "priceMin" },
                                  },
                                },
                                {
                                  kind: "ObjectField",
                                  name: { kind: "Name", value: "yearMax" },
                                  value: {
                                    kind: "Variable",
                                    name: { kind: "Name", value: "yearMax" },
                                  },
                                },
                                {
                                  kind: "ObjectField",
                                  name: { kind: "Name", value: "yearMin" },
                                  value: {
                                    kind: "Variable",
                                    name: { kind: "Name", value: "yearMin" },
                                  },
                                },
                                {
                                  kind: "ObjectField",
                                  name: { kind: "Name", value: "sortSlug" },
                                  value: {
                                    kind: "Variable",
                                    name: { kind: "Name", value: "sortSlug" },
                                  },
                                },
                                {
                                  kind: "ObjectField",
                                  name: { kind: "Name", value: "brandSlugs" },
                                  value: {
                                    kind: "Variable",
                                    name: { kind: "Name", value: "brandSlugs" },
                                  },
                                },
                                {
                                  kind: "ObjectField",
                                  name: {
                                    kind: "Name",
                                    value: "categorySlugs",
                                  },
                                  value: {
                                    kind: "Variable",
                                    name: {
                                      kind: "Name",
                                      value: "categorySlugs",
                                    },
                                  },
                                },
                                {
                                  kind: "ObjectField",
                                  name: {
                                    kind: "Name",
                                    value: "conditionSlugs",
                                  },
                                  value: {
                                    kind: "Variable",
                                    name: {
                                      kind: "Name",
                                      value: "conditionSlugs",
                                    },
                                  },
                                },
                                {
                                  kind: "ObjectField",
                                  name: {
                                    kind: "Name",
                                    value: "withAggregations",
                                  },
                                  value: {
                                    kind: "Variable",
                                    name: { kind: "Name", value: "aggs" },
                                  },
                                },
                                {
                                  kind: "ObjectField",
                                  name: { kind: "Name", value: "onSale" },
                                  value: {
                                    kind: "Variable",
                                    name: { kind: "Name", value: "onSale" },
                                  },
                                },
                                {
                                  kind: "ObjectField",
                                  name: { kind: "Name", value: "handmade" },
                                  value: {
                                    kind: "Variable",
                                    name: { kind: "Name", value: "handmade" },
                                  },
                                },
                                {
                                  kind: "ObjectField",
                                  name: { kind: "Name", value: "freeShipping" },
                                  value: {
                                    kind: "Variable",
                                    name: {
                                      kind: "Name",
                                      value: "freeShipping",
                                    },
                                  },
                                },
                                {
                                  kind: "ObjectField",
                                  name: {
                                    kind: "Name",
                                    value: "freeExpeditedShipping",
                                  },
                                  value: {
                                    kind: "Variable",
                                    name: {
                                      kind: "Name",
                                      value: "freeExpeditedShipping",
                                    },
                                  },
                                },
                                {
                                  kind: "ObjectField",
                                  name: {
                                    kind: "Name",
                                    value: "acceptsOffers",
                                  },
                                  value: {
                                    kind: "Variable",
                                    name: {
                                      kind: "Name",
                                      value: "acceptsOffers",
                                    },
                                  },
                                },
                                {
                                  kind: "ObjectField",
                                  name: {
                                    kind: "Name",
                                    value: "acceptsGiftCards",
                                  },
                                  value: {
                                    kind: "Variable",
                                    name: {
                                      kind: "Name",
                                      value: "acceptsGiftCards",
                                    },
                                  },
                                },
                                {
                                  kind: "ObjectField",
                                  name: {
                                    kind: "Name",
                                    value: "preferredSeller",
                                  },
                                  value: {
                                    kind: "Variable",
                                    name: {
                                      kind: "Name",
                                      value: "preferredSeller",
                                    },
                                  },
                                },
                                {
                                  kind: "ObjectField",
                                  name: {
                                    kind: "Name",
                                    value: "acceptsPaymentPlans",
                                  },
                                  value: {
                                    kind: "Variable",
                                    name: {
                                      kind: "Name",
                                      value: "acceptsPaymentPlans",
                                    },
                                  },
                                },
                                {
                                  kind: "ObjectField",
                                  name: { kind: "Name", value: "itemRegion" },
                                  value: {
                                    kind: "Variable",
                                    name: { kind: "Name", value: "itemRegion" },
                                  },
                                },
                                {
                                  kind: "ObjectField",
                                  name: {
                                    kind: "Name",
                                    value: "shippingRegionCodes",
                                  },
                                  value: {
                                    kind: "Variable",
                                    name: {
                                      kind: "Name",
                                      value: "shippingRegionCode",
                                    },
                                  },
                                },
                                {
                                  kind: "ObjectField",
                                  name: { kind: "Name", value: "decades" },
                                  value: {
                                    kind: "Variable",
                                    name: { kind: "Name", value: "decades" },
                                  },
                                },
                                {
                                  kind: "ObjectField",
                                  name: {
                                    kind: "Name",
                                    value: "dealsAndSteals",
                                  },
                                  value: {
                                    kind: "Variable",
                                    name: {
                                      kind: "Name",
                                      value: "dealsAndSteals",
                                    },
                                  },
                                },
                              ],
                            },
                          },
                        ],
                      },
                    },
                  ],
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "total" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "offset" },
                      },
                      { kind: "Field", name: { kind: "Name", value: "limit" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "filters" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "FragmentSpread",
                              name: { kind: "Name", value: "NestedFilter" },
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "entries" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "FragmentSpread",
                              name: { kind: "Name", value: "MixedFeedRow" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "entry" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "InlineFragment",
                                    typeCondition: {
                                      kind: "NamedType",
                                      name: { kind: "Name", value: "Listing" },
                                    },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "_id" },
                                        },
                                        {
                                          kind: "FragmentSpread",
                                          name: {
                                            kind: "Name",
                                            value: "ShopFields",
                                          },
                                          directives: [
                                            {
                                              kind: "Directive",
                                              name: {
                                                kind: "Name",
                                                value: "include",
                                              },
                                              arguments: [
                                                {
                                                  kind: "Argument",
                                                  name: {
                                                    kind: "Name",
                                                    value: "if",
                                                  },
                                                  value: {
                                                    kind: "Variable",
                                                    name: {
                                                      kind: "Name",
                                                      value:
                                                        "showListingLocation",
                                                    },
                                                  },
                                                },
                                              ],
                                            },
                                          ],
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "AddToCartButtonFields" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Listing" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "sellerId" } },
          { kind: "Field", name: { kind: "Name", value: "listingType" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "pricing" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "buyerPrice" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "amount" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "amountCents" },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "preorderInfo" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "onPreorder" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "estimatedShipDate" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "seconds" },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "WatchBadgeData" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Listing" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "title" } },
          { kind: "Field", name: { kind: "Name", value: "sellerId" } },
          { kind: "Field", name: { kind: "Name", value: "watching" } },
          {
            kind: "Field",
            alias: { kind: "Name", value: "watchThumbnails" },
            name: { kind: "Name", value: "images" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "type" },
                      value: {
                        kind: "StringValue",
                        value: "Product",
                        block: false,
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "scope" },
                      value: {
                        kind: "StringValue",
                        value: "photos",
                        block: false,
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "transform" },
                      value: {
                        kind: "StringValue",
                        value: "card_square",
                        block: false,
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "count" },
                      value: { kind: "IntValue", value: "1" },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "source" } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "ListingGreatValueData" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Listing" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "pricing" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "buyerPrice" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "currency" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "amountCents" },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "condition" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "conditionSlug" },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "priceRecommendation" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "priceMiddle" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "amountCents" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "currency" },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "shop" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "address" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "country" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "_id" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "countryCode" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          { kind: "Field", name: { kind: "Name", value: "currency" } },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "mParticleListingFields" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Listing" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "title" } },
          { kind: "Field", name: { kind: "Name", value: "brandSlug" } },
          { kind: "Field", name: { kind: "Name", value: "categoryRootUuid" } },
          { kind: "Field", name: { kind: "Name", value: "make" } },
          { kind: "Field", name: { kind: "Name", value: "categoryUuids" } },
          { kind: "Field", name: { kind: "Name", value: "state" } },
          { kind: "Field", name: { kind: "Name", value: "listingType" } },
          { kind: "Field", name: { kind: "Name", value: "bumpEligible" } },
          { kind: "Field", name: { kind: "Name", value: "shopId" } },
          { kind: "Field", name: { kind: "Name", value: "inventory" } },
          { kind: "Field", name: { kind: "Name", value: "soldAsIs" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "acceptedPaymentMethods" },
          },
          { kind: "Field", name: { kind: "Name", value: "currency" } },
          { kind: "Field", name: { kind: "Name", value: "usOutlet" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "condition" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "conditionUuid" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "conditionSlug" },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "categories" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "slug" } },
                { kind: "Field", name: { kind: "Name", value: "rootSlug" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "csp" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "slug" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "brand" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      { kind: "Field", name: { kind: "Name", value: "slug" } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "pricing" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "buyerPrice" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "amount" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "currency" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "amountCents" },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "publishedAt" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "seconds" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "sale" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "code" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "buyerIneligibilityReason" },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "shipping" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "shippingPrices" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "shippingMethod" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "carrierCalculated" },
                      },
                      {
                        kind: "Field",
                        name: {
                          kind: "Name",
                          value: "destinationPostalCodeNeeded",
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "rate" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "amount" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "amountCents" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "currency" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "display" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "freeExpeditedShipping" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "localPickupOnly" },
                },
                { kind: "Field", name: { kind: "Name", value: "localPickup" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "certifiedPreOwned" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "title" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "badgeIconUrl" },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "shop" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "address" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "countryCode" },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "returnPolicy" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "newReturnWindowDays" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "usedReturnWindowDays" },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "ListingCreateOfferButtonData" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Listing" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "state" } },
          { kind: "Field", name: { kind: "Name", value: "offersEnabled" } },
          { kind: "Field", name: { kind: "Name", value: "listingType" } },
          { kind: "Field", name: { kind: "Name", value: "sellerId" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "isBuyerOfferEligible" },
          },
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "mParticleListingFields" },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "ListingImagesFields" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Listing" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "images" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "transform" },
                      value: {
                        kind: "StringValue",
                        value: "card_square",
                        block: false,
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "count" },
                      value: { kind: "IntValue", value: "3" },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "scope" },
                      value: {
                        kind: "StringValue",
                        value: "photos",
                        block: false,
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "type" },
                      value: {
                        kind: "StringValue",
                        value: "Product",
                        block: false,
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "source" } },
              ],
            },
          },
          {
            kind: "Field",
            alias: { kind: "Name", value: "largeSquareImages" },
            name: { kind: "Name", value: "images" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "transform" },
                      value: {
                        kind: "StringValue",
                        value: "large_square",
                        block: false,
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "count" },
                      value: { kind: "IntValue", value: "3" },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "scope" },
                      value: {
                        kind: "StringValue",
                        value: "photos",
                        block: false,
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "type" },
                      value: {
                        kind: "StringValue",
                        value: "Product",
                        block: false,
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "source" } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "ListingCardCPOData" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Listing" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "certifiedPreOwned" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "title" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "badgeIconUrl" },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "ListingsCollection" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Listing" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "usOutlet" } },
          { kind: "Field", name: { kind: "Name", value: "bumped" } },
          { kind: "Field", name: { kind: "Name", value: "categoryUuids" } },
          { kind: "Field", name: { kind: "Name", value: "slug" } },
          { kind: "Field", name: { kind: "Name", value: "title" } },
          { kind: "Field", name: { kind: "Name", value: "description" } },
          { kind: "Field", name: { kind: "Name", value: "listingType" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "condition" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "displayName" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "conditionSlug" },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "price" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "amountCents" } },
                { kind: "Field", name: { kind: "Name", value: "display" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "pricing" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "buyerPrice" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "display" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "currency" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "amountCents" },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "originalPrice" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "display" },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "ribbon" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "display" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "reason" },
                      },
                    ],
                  },
                },
              ],
            },
          },
          { kind: "Field", name: { kind: "Name", value: "watching" } },
          { kind: "Field", name: { kind: "Name", value: "state" } },
          { kind: "Field", name: { kind: "Name", value: "stateDescription" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "shipping" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "shippingPrices" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "shippingMethod" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "carrierCalculated" },
                      },
                      {
                        kind: "Field",
                        name: {
                          kind: "Name",
                          value: "destinationPostalCodeNeeded",
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "rate" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "amount" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "amountCents" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "currency" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "display" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "freeExpeditedShipping" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "localPickupOnly" },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "shop" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "address" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "country" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "_id" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "countryCode" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "name" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "AddToCartButtonFields" },
          },
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "WatchBadgeData" },
          },
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "ListingGreatValueData" },
          },
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "ListingCreateOfferButtonData" },
          },
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "ListingImagesFields" },
          },
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "ListingCardCPOData" },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "NestedFilter" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "reverb_search_Filter" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "name" } },
          { kind: "Field", name: { kind: "Name", value: "key" } },
          { kind: "Field", name: { kind: "Name", value: "aggregationName" } },
          { kind: "Field", name: { kind: "Name", value: "widgetType" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "options" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "count" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "value" } },
                    ],
                  },
                },
                { kind: "Field", name: { kind: "Name", value: "name" } },
                { kind: "Field", name: { kind: "Name", value: "selected" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "autoselected" },
                },
                { kind: "Field", name: { kind: "Name", value: "paramName" } },
                { kind: "Field", name: { kind: "Name", value: "setValues" } },
                { kind: "Field", name: { kind: "Name", value: "unsetValues" } },
                { kind: "Field", name: { kind: "Name", value: "all" } },
                { kind: "Field", name: { kind: "Name", value: "optionValue" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "trackingValue" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "subFilter" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "widgetType" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "options" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "count" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "value" },
                                  },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "name" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "selected" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "autoselected" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "paramName" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "setValues" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "unsetValues" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "all" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "optionValue" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "trackingValue" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "subFilter" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "widgetType" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "options" },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "count",
                                          },
                                          selectionSet: {
                                            kind: "SelectionSet",
                                            selections: [
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "value",
                                                },
                                              },
                                            ],
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "name" },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "selected",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "autoselected",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "paramName",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "setValues",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "unsetValues",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "all" },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "optionValue",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "trackingValue",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "subFilter",
                                          },
                                          selectionSet: {
                                            kind: "SelectionSet",
                                            selections: [
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "widgetType",
                                                },
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "options",
                                                },
                                                selectionSet: {
                                                  kind: "SelectionSet",
                                                  selections: [
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "count",
                                                      },
                                                      selectionSet: {
                                                        kind: "SelectionSet",
                                                        selections: [
                                                          {
                                                            kind: "Field",
                                                            name: {
                                                              kind: "Name",
                                                              value: "value",
                                                            },
                                                          },
                                                        ],
                                                      },
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "name",
                                                      },
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "selected",
                                                      },
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "autoselected",
                                                      },
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "paramName",
                                                      },
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "setValues",
                                                      },
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "unsetValues",
                                                      },
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "all",
                                                      },
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "optionValue",
                                                      },
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "trackingValue",
                                                      },
                                                    },
                                                  ],
                                                },
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "MixedFeedRow" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "reverb_feed_FeedEntry" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "type" } },
          { kind: "Field", name: { kind: "Name", value: "deleted" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "metadata" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "sourceType" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "localizedContents" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "locale" } },
                { kind: "Field", name: { kind: "Name", value: "title" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "shortDescription" },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "entry" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "InlineFragment",
                  typeCondition: {
                    kind: "NamedType",
                    name: { kind: "Name", value: "Article" },
                  },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      { kind: "Field", name: { kind: "Name", value: "title" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "images" },
                        arguments: [
                          {
                            kind: "Argument",
                            name: { kind: "Name", value: "input" },
                            value: {
                              kind: "ObjectValue",
                              fields: [
                                {
                                  kind: "ObjectField",
                                  name: { kind: "Name", value: "count" },
                                  value: { kind: "IntValue", value: "1" },
                                },
                                {
                                  kind: "ObjectField",
                                  name: { kind: "Name", value: "scope" },
                                  value: {
                                    kind: "StringValue",
                                    value: "full_bleed_image",
                                    block: false,
                                  },
                                },
                                {
                                  kind: "ObjectField",
                                  name: { kind: "Name", value: "transform" },
                                  value: {
                                    kind: "StringValue",
                                    value: "card_square",
                                    block: false,
                                  },
                                },
                              ],
                            },
                          },
                        ],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "source" },
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "webLink" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "href" },
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "articleCategories" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "name" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "slug" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: "InlineFragment",
                  typeCondition: {
                    kind: "NamedType",
                    name: { kind: "Name", value: "Listing" },
                  },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      {
                        kind: "FragmentSpread",
                        name: { kind: "Name", value: "ListingsCollection" },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "ShopFields" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Listing" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "shop" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "address" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "locality" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "countryCode" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "region" },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Core_Feed_SearchQuery,
  Core_Feed_SearchQueryVariables
>;
export const RemoveFeedEntryDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "RemoveFeedEntry" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "input" },
          },
          type: {
            kind: "NamedType",
            name: {
              kind: "Name",
              value: "Input_reverb_feed_RemoveEntryRequest",
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "removeFeedEntry" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "input" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "entry" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      { kind: "Field", name: { kind: "Name", value: "type" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "deleted" },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  RemoveFeedEntryMutation,
  RemoveFeedEntryMutationVariables
>;
export const Core_HomePage_FeedDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "Core_HomePage_Feed" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "limit" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "Int" } },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "me" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "uuid" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "feed" },
                  arguments: [
                    {
                      kind: "Argument",
                      name: { kind: "Name", value: "input" },
                      value: {
                        kind: "ObjectValue",
                        fields: [
                          {
                            kind: "ObjectField",
                            name: { kind: "Name", value: "channel" },
                            value: { kind: "EnumValue", value: "REVERB" },
                          },
                          {
                            kind: "ObjectField",
                            name: { kind: "Name", value: "offset" },
                            value: { kind: "IntValue", value: "0" },
                          },
                          {
                            kind: "ObjectField",
                            name: { kind: "Name", value: "limit" },
                            value: {
                              kind: "Variable",
                              name: { kind: "Name", value: "limit" },
                            },
                          },
                        ],
                      },
                    },
                  ],
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "total" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "offset" },
                      },
                      { kind: "Field", name: { kind: "Name", value: "limit" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "entries" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "FragmentSpread",
                              name: { kind: "Name", value: "MixedFeedRow" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "entry" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "InlineFragment",
                                    typeCondition: {
                                      kind: "NamedType",
                                      name: { kind: "Name", value: "Listing" },
                                    },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "_id" },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "AddToCartButtonFields" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Listing" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "sellerId" } },
          { kind: "Field", name: { kind: "Name", value: "listingType" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "pricing" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "buyerPrice" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "amount" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "amountCents" },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "preorderInfo" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "onPreorder" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "estimatedShipDate" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "seconds" },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "WatchBadgeData" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Listing" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "title" } },
          { kind: "Field", name: { kind: "Name", value: "sellerId" } },
          { kind: "Field", name: { kind: "Name", value: "watching" } },
          {
            kind: "Field",
            alias: { kind: "Name", value: "watchThumbnails" },
            name: { kind: "Name", value: "images" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "type" },
                      value: {
                        kind: "StringValue",
                        value: "Product",
                        block: false,
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "scope" },
                      value: {
                        kind: "StringValue",
                        value: "photos",
                        block: false,
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "transform" },
                      value: {
                        kind: "StringValue",
                        value: "card_square",
                        block: false,
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "count" },
                      value: { kind: "IntValue", value: "1" },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "source" } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "ListingGreatValueData" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Listing" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "pricing" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "buyerPrice" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "currency" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "amountCents" },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "condition" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "conditionSlug" },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "priceRecommendation" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "priceMiddle" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "amountCents" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "currency" },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "shop" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "address" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "country" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "_id" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "countryCode" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          { kind: "Field", name: { kind: "Name", value: "currency" } },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "mParticleListingFields" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Listing" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "title" } },
          { kind: "Field", name: { kind: "Name", value: "brandSlug" } },
          { kind: "Field", name: { kind: "Name", value: "categoryRootUuid" } },
          { kind: "Field", name: { kind: "Name", value: "make" } },
          { kind: "Field", name: { kind: "Name", value: "categoryUuids" } },
          { kind: "Field", name: { kind: "Name", value: "state" } },
          { kind: "Field", name: { kind: "Name", value: "listingType" } },
          { kind: "Field", name: { kind: "Name", value: "bumpEligible" } },
          { kind: "Field", name: { kind: "Name", value: "shopId" } },
          { kind: "Field", name: { kind: "Name", value: "inventory" } },
          { kind: "Field", name: { kind: "Name", value: "soldAsIs" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "acceptedPaymentMethods" },
          },
          { kind: "Field", name: { kind: "Name", value: "currency" } },
          { kind: "Field", name: { kind: "Name", value: "usOutlet" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "condition" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "conditionUuid" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "conditionSlug" },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "categories" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "slug" } },
                { kind: "Field", name: { kind: "Name", value: "rootSlug" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "csp" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "slug" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "brand" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      { kind: "Field", name: { kind: "Name", value: "slug" } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "pricing" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "buyerPrice" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "amount" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "currency" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "amountCents" },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "publishedAt" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "seconds" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "sale" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "code" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "buyerIneligibilityReason" },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "shipping" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "shippingPrices" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "shippingMethod" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "carrierCalculated" },
                      },
                      {
                        kind: "Field",
                        name: {
                          kind: "Name",
                          value: "destinationPostalCodeNeeded",
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "rate" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "amount" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "amountCents" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "currency" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "display" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "freeExpeditedShipping" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "localPickupOnly" },
                },
                { kind: "Field", name: { kind: "Name", value: "localPickup" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "certifiedPreOwned" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "title" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "badgeIconUrl" },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "shop" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "address" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "countryCode" },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "returnPolicy" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "newReturnWindowDays" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "usedReturnWindowDays" },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "ListingCreateOfferButtonData" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Listing" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "state" } },
          { kind: "Field", name: { kind: "Name", value: "offersEnabled" } },
          { kind: "Field", name: { kind: "Name", value: "listingType" } },
          { kind: "Field", name: { kind: "Name", value: "sellerId" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "isBuyerOfferEligible" },
          },
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "mParticleListingFields" },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "ListingImagesFields" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Listing" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "images" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "transform" },
                      value: {
                        kind: "StringValue",
                        value: "card_square",
                        block: false,
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "count" },
                      value: { kind: "IntValue", value: "3" },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "scope" },
                      value: {
                        kind: "StringValue",
                        value: "photos",
                        block: false,
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "type" },
                      value: {
                        kind: "StringValue",
                        value: "Product",
                        block: false,
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "source" } },
              ],
            },
          },
          {
            kind: "Field",
            alias: { kind: "Name", value: "largeSquareImages" },
            name: { kind: "Name", value: "images" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "transform" },
                      value: {
                        kind: "StringValue",
                        value: "large_square",
                        block: false,
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "count" },
                      value: { kind: "IntValue", value: "3" },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "scope" },
                      value: {
                        kind: "StringValue",
                        value: "photos",
                        block: false,
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "type" },
                      value: {
                        kind: "StringValue",
                        value: "Product",
                        block: false,
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "source" } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "ListingCardCPOData" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Listing" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "certifiedPreOwned" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "title" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "badgeIconUrl" },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "ListingsCollection" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Listing" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "usOutlet" } },
          { kind: "Field", name: { kind: "Name", value: "bumped" } },
          { kind: "Field", name: { kind: "Name", value: "categoryUuids" } },
          { kind: "Field", name: { kind: "Name", value: "slug" } },
          { kind: "Field", name: { kind: "Name", value: "title" } },
          { kind: "Field", name: { kind: "Name", value: "description" } },
          { kind: "Field", name: { kind: "Name", value: "listingType" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "condition" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "displayName" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "conditionSlug" },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "price" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "amountCents" } },
                { kind: "Field", name: { kind: "Name", value: "display" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "pricing" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "buyerPrice" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "display" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "currency" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "amountCents" },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "originalPrice" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "display" },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "ribbon" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "display" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "reason" },
                      },
                    ],
                  },
                },
              ],
            },
          },
          { kind: "Field", name: { kind: "Name", value: "watching" } },
          { kind: "Field", name: { kind: "Name", value: "state" } },
          { kind: "Field", name: { kind: "Name", value: "stateDescription" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "shipping" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "shippingPrices" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "shippingMethod" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "carrierCalculated" },
                      },
                      {
                        kind: "Field",
                        name: {
                          kind: "Name",
                          value: "destinationPostalCodeNeeded",
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "rate" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "amount" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "amountCents" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "currency" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "display" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "freeExpeditedShipping" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "localPickupOnly" },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "shop" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "address" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "country" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "_id" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "countryCode" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "name" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "AddToCartButtonFields" },
          },
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "WatchBadgeData" },
          },
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "ListingGreatValueData" },
          },
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "ListingCreateOfferButtonData" },
          },
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "ListingImagesFields" },
          },
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "ListingCardCPOData" },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "MixedFeedRow" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "reverb_feed_FeedEntry" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "type" } },
          { kind: "Field", name: { kind: "Name", value: "deleted" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "metadata" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "sourceType" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "localizedContents" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "locale" } },
                { kind: "Field", name: { kind: "Name", value: "title" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "shortDescription" },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "entry" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "InlineFragment",
                  typeCondition: {
                    kind: "NamedType",
                    name: { kind: "Name", value: "Article" },
                  },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      { kind: "Field", name: { kind: "Name", value: "title" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "images" },
                        arguments: [
                          {
                            kind: "Argument",
                            name: { kind: "Name", value: "input" },
                            value: {
                              kind: "ObjectValue",
                              fields: [
                                {
                                  kind: "ObjectField",
                                  name: { kind: "Name", value: "count" },
                                  value: { kind: "IntValue", value: "1" },
                                },
                                {
                                  kind: "ObjectField",
                                  name: { kind: "Name", value: "scope" },
                                  value: {
                                    kind: "StringValue",
                                    value: "full_bleed_image",
                                    block: false,
                                  },
                                },
                                {
                                  kind: "ObjectField",
                                  name: { kind: "Name", value: "transform" },
                                  value: {
                                    kind: "StringValue",
                                    value: "card_square",
                                    block: false,
                                  },
                                },
                              ],
                            },
                          },
                        ],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "source" },
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "webLink" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "href" },
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "articleCategories" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "name" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "slug" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: "InlineFragment",
                  typeCondition: {
                    kind: "NamedType",
                    name: { kind: "Name", value: "Listing" },
                  },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      {
                        kind: "FragmentSpread",
                        name: { kind: "Name", value: "ListingsCollection" },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Core_HomePage_FeedQuery,
  Core_HomePage_FeedQueryVariables
>;
export const Core_AdyenBalanceShopDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "Core_AdyenBalanceShop" },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "me" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "id" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "shop" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      {
                        kind: "Field",
                        name: {
                          kind: "Name",
                          value: "adyenBalanceOnboardingDetails",
                        },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "accountHolderId" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Core_AdyenBalanceShopQuery,
  Core_AdyenBalanceShopQueryVariables
>;
export const Core_InformActVerification_StatusDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "Core_InformActVerification_Status" },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "me" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "id" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "shop" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "address" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "_id" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "countryCode" },
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: {
                          kind: "Name",
                          value: "informActThresholdStatus",
                        },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "isCompliant" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "hasValidTaxId" },
                            },
                            {
                              kind: "Field",
                              name: {
                                kind: "Name",
                                value: "hasValidBankAccount",
                              },
                            },
                            {
                              kind: "Field",
                              name: {
                                kind: "Name",
                                value: "hasCompletedSellerVerification",
                              },
                            },
                            {
                              kind: "Field",
                              name: {
                                kind: "Name",
                                value: "bankAccountVerifiedDate",
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "isPaypalOnly" },
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "taxProfile" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "updatedAt" },
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: {
                          kind: "Name",
                          value: "internationalTaxProfile",
                        },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "updatedAt" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Core_InformActVerification_StatusQuery,
  Core_InformActVerification_StatusQueryVariables
>;
export const Core_Trulioo_Verification_And_Policy_DataDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: {
        kind: "Name",
        value: "Core_Trulioo_Verification_And_Policy_Data",
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "me" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "uuid" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "shop" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "shopType" },
                      },
                      {
                        kind: "Field",
                        name: {
                          kind: "Name",
                          value: "sellerVerificationPolicy",
                        },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: {
                                kind: "Name",
                                value: "eligibleForInformAct",
                              },
                            },
                            {
                              kind: "Field",
                              name: {
                                kind: "Name",
                                value: "eligibleForSellerVerification",
                              },
                            },
                            {
                              kind: "Field",
                              name: {
                                kind: "Name",
                                value: "eligibleForSellerVerificationBackcast",
                              },
                            },
                            {
                              kind: "Field",
                              name: {
                                kind: "Name",
                                value:
                                  "eligibleForColdStorageSellerVerificationBypass",
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "truliooOnboarding" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                            },
                            {
                              kind: "Field",
                              name: {
                                kind: "Name",
                                value: "verificationStatus",
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "completedAt" },
                            },
                            {
                              kind: "Field",
                              alias: { kind: "Name", value: "parentRecords" },
                              name: {
                                kind: "Name",
                                value: "truliooOnboardingRecords",
                              },
                              arguments: [
                                {
                                  kind: "Argument",
                                  name: { kind: "Name", value: "input" },
                                  value: {
                                    kind: "ObjectValue",
                                    fields: [
                                      {
                                        kind: "ObjectField",
                                        name: {
                                          kind: "Name",
                                          value: "recordType",
                                        },
                                        value: {
                                          kind: "EnumValue",
                                          value: "PARENT",
                                        },
                                      },
                                    ],
                                  },
                                },
                              ],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "workflowStatus",
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "entityType" },
                                  },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              alias: { kind: "Name", value: "childrenRecords" },
                              name: {
                                kind: "Name",
                                value: "truliooOnboardingRecords",
                              },
                              arguments: [
                                {
                                  kind: "Argument",
                                  name: { kind: "Name", value: "input" },
                                  value: {
                                    kind: "ObjectValue",
                                    fields: [
                                      {
                                        kind: "ObjectField",
                                        name: {
                                          kind: "Name",
                                          value: "recordType",
                                        },
                                        value: {
                                          kind: "EnumValue",
                                          value: "CHILDREN",
                                        },
                                      },
                                    ],
                                  },
                                },
                              ],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "workflowStatus",
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "email" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "isParent" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "flowId" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "entityType" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "fullName" },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Core_Trulioo_Verification_And_Policy_DataQuery,
  Core_Trulioo_Verification_And_Policy_DataQueryVariables
>;
export const Core_RestartTruliooOnboardingWorkflowDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "Core_RestartTruliooOnboardingWorkflow" },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "restartTruliooOnboardingWorkflow" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "truliooOnboarding" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "verificationStatus" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "completedAt" },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Core_RestartTruliooOnboardingWorkflowMutation,
  Core_RestartTruliooOnboardingWorkflowMutationVariables
>;
export const Core_TruliooOnboardingRecordResendPscEmailDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: {
        kind: "Name",
        value: "Core_TruliooOnboardingRecordResendPscEmail",
      },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "input" },
          },
          type: {
            kind: "NamedType",
            name: {
              kind: "Name",
              value:
                "Input_core_apimessages_TruliooOnboardingRecordResendPscEmailRequest",
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: {
              kind: "Name",
              value: "truliooOnboardingRecordResendPscEmail",
            },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "input" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "truliooOnboardingRecordId" },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Core_TruliooOnboardingRecordResendPscEmailMutation,
  Core_TruliooOnboardingRecordResendPscEmailMutationVariables
>;
export const Core_AdyenOnboardingLink_CreateDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "Core_AdyenOnboardingLink_Create" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "input" },
          },
          type: {
            kind: "NamedType",
            name: {
              kind: "Name",
              value: "Input_core_apimessages_AdyenOnboardingLinkRequest",
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "createAdyenOnboardingLink" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "input" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "url" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Core_AdyenOnboardingLink_CreateMutation,
  Core_AdyenOnboardingLink_CreateMutationVariables
>;
export const AdyenOnboardingFormDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "AdyenOnboardingForm" },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "countries" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "countries" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      { kind: "Field", name: { kind: "Name", value: "name" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "countryCode" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "subregions" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "_id" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "name" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "code" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "me" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "uuid" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "shop" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "currency" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "originCountryCode" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "shopType" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "billingMethodStatus" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "paypalSetupStatus" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "returnPolicy" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "_id" },
                            },
                            {
                              kind: "Field",
                              name: {
                                kind: "Name",
                                value: "newReturnWindowDays",
                              },
                            },
                            {
                              kind: "Field",
                              name: {
                                kind: "Name",
                                value: "usedReturnWindowDays",
                              },
                            },
                            {
                              kind: "Field",
                              name: {
                                kind: "Name",
                                value: "restockingFeePercent",
                              },
                            },
                            {
                              kind: "Field",
                              name: {
                                kind: "Name",
                                value: "specialConditions",
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "paypalProfile" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "email" },
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: {
                          kind: "Name",
                          value: "adyenBalanceOnboardingDetails",
                        },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "accountHolderId" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "balanceAccountId" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "legalEntityId" },
                            },
                            {
                              kind: "Field",
                              name: {
                                kind: "Name",
                                value: "transferInstrumentId",
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "creditCards" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      { kind: "Field", name: { kind: "Name", value: "last4" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "cardType" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "expirationYear" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "expirationMonth" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "cardholderName" },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  AdyenOnboardingFormQuery,
  AdyenOnboardingFormQueryVariables
>;
export const Core_SellerVerification_SetShopTypeDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "Core_SellerVerification_SetShopType" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "input" },
          },
          type: {
            kind: "NamedType",
            name: {
              kind: "Name",
              value: "Input_core_apimessages_UpdateMyShopRequest",
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "updateMyShop" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "input" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "shop" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "shopType" },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Core_SellerVerification_SetShopTypeMutation,
  Core_SellerVerification_SetShopTypeMutationVariables
>;
export const Core_SellerVerification_ShopDataDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "Core_SellerVerification_ShopData" },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "me" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "uuid" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "shop" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "shopType" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "truliooOnboarding" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                            },
                            {
                              kind: "Field",
                              name: {
                                kind: "Name",
                                value: "verificationStatus",
                              },
                            },
                            {
                              kind: "Field",
                              alias: { kind: "Name", value: "parentRecords" },
                              name: {
                                kind: "Name",
                                value: "truliooOnboardingRecords",
                              },
                              arguments: [
                                {
                                  kind: "Argument",
                                  name: { kind: "Name", value: "input" },
                                  value: {
                                    kind: "ObjectValue",
                                    fields: [
                                      {
                                        kind: "ObjectField",
                                        name: {
                                          kind: "Name",
                                          value: "recordType",
                                        },
                                        value: {
                                          kind: "EnumValue",
                                          value: "PARENT",
                                        },
                                      },
                                    ],
                                  },
                                },
                              ],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "entityType" },
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "workflowStatus",
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "clientId" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "flowId" },
                                  },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              alias: { kind: "Name", value: "childrenRecords" },
                              name: {
                                kind: "Name",
                                value: "truliooOnboardingRecords",
                              },
                              arguments: [
                                {
                                  kind: "Argument",
                                  name: { kind: "Name", value: "input" },
                                  value: {
                                    kind: "ObjectValue",
                                    fields: [
                                      {
                                        kind: "ObjectField",
                                        name: {
                                          kind: "Name",
                                          value: "recordType",
                                        },
                                        value: {
                                          kind: "EnumValue",
                                          value: "CHILDREN",
                                        },
                                      },
                                    ],
                                  },
                                },
                              ],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "workflowStatus",
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Core_SellerVerification_ShopDataQuery,
  Core_SellerVerification_ShopDataQueryVariables
>;
export const Core_SellerVerification_CreateTruliooOnboardingRecordDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: {
        kind: "Name",
        value: "Core_SellerVerification_CreateTruliooOnboardingRecord",
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "createTruliooOnboardingRecord" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "truliooOnboardingRecord" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "flowId" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "clientId" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "workflowStatus" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "entityType" },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Core_SellerVerification_CreateTruliooOnboardingRecordMutation,
  Core_SellerVerification_CreateTruliooOnboardingRecordMutationVariables
>;
export const Core_SellerVerification_PrepublishStatusDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "Core_SellerVerification_PrepublishStatus" },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "me" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "uuid" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "prepublishStatus" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "nextStep" },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "shop" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "truliooOnboarding" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                            },
                            {
                              kind: "Field",
                              name: {
                                kind: "Name",
                                value: "verificationStatus",
                              },
                            },
                            {
                              kind: "Field",
                              name: {
                                kind: "Name",
                                value: "truliooOnboardingRecords",
                              },
                              arguments: [
                                {
                                  kind: "Argument",
                                  name: { kind: "Name", value: "input" },
                                  value: {
                                    kind: "ObjectValue",
                                    fields: [
                                      {
                                        kind: "ObjectField",
                                        name: {
                                          kind: "Name",
                                          value: "recordType",
                                        },
                                        value: {
                                          kind: "EnumValue",
                                          value: "PARENT",
                                        },
                                      },
                                    ],
                                  },
                                },
                              ],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "workflowStatus",
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Core_SellerVerification_PrepublishStatusQuery,
  Core_SellerVerification_PrepublishStatusQueryVariables
>;
export const SetupShopPaymentMethodsDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "SetupShopPaymentMethods" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "input" },
          },
          type: {
            kind: "NamedType",
            name: {
              kind: "Name",
              value: "Input_core_apimessages_SetupPaymentMethodsRequest",
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "setupShopPaymentMethods" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "input" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "errors" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "message" },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  SetupShopPaymentMethodsMutation,
  SetupShopPaymentMethodsMutationVariables
>;
export const UpdateMyShopDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "UpdateMyShop" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "input" },
          },
          type: {
            kind: "NamedType",
            name: {
              kind: "Name",
              value: "Input_core_apimessages_UpdateMyShopRequest",
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "updateMyShop" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "input" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "errors" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "message" },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateMyShopMutation,
  UpdateMyShopMutationVariables
>;
// codegen-compat-plugin
export namespace CoreGetAdyenRegion {
  /** @deprecated use Core_GetAdyenRegionQueryVariables instead */
  export type Variables = Core_GetAdyenRegionQueryVariables;
  /** @deprecated use Core_GetAdyenRegionQuery instead */
  export type Query = Core_GetAdyenRegionQuery;
}
export namespace CommonsCmsArticlesCollection {
  /** @deprecated use Commons_Cms_ArticlesCollectionQueryVariables instead */
  export type Variables = Commons_Cms_ArticlesCollectionQueryVariables;
  /** @deprecated use Commons_Cms_ArticlesCollectionQuery instead */
  export type Query = Commons_Cms_ArticlesCollectionQuery;
}
export namespace CommonsCmsBrandCollection {
  /** @deprecated use Commons_Cms_BrandCollectionQueryVariables instead */
  export type Variables = Commons_Cms_BrandCollectionQueryVariables;
  /** @deprecated use Commons_Cms_BrandCollectionQuery instead */
  export type Query = Commons_Cms_BrandCollectionQuery;
}
export namespace CommonsCmsCategoryCollection {
  /** @deprecated use Commons_Cms_CategoryCollectionQueryVariables instead */
  export type Variables = Commons_Cms_CategoryCollectionQueryVariables;
  /** @deprecated use Commons_Cms_CategoryCollectionQuery instead */
  export type Query = Commons_Cms_CategoryCollectionQuery;
}
export namespace CommonsCmsListingsCollection {
  /** @deprecated use Commons_Cms_ListingsCollectionQueryVariables instead */
  export type Variables = Commons_Cms_ListingsCollectionQueryVariables;
  /** @deprecated use Commons_Cms_ListingsCollectionQuery instead */
  export type Query = Commons_Cms_ListingsCollectionQuery;
}
export namespace CoreCmsPageCards {
  /** @deprecated use Core_Cms_PageCardsQueryVariables instead */
  export type Variables = Core_Cms_PageCardsQueryVariables;
  /** @deprecated use Core_Cms_PageCardsQuery instead */
  export type Query = Core_Cms_PageCardsQuery;
}
export namespace CommonsCmsCspCollection {
  /** @deprecated use Commons_Cms_CspCollectionQueryVariables instead */
  export type Variables = Commons_Cms_CspCollectionQueryVariables;
  /** @deprecated use Commons_Cms_CspCollectionQuery instead */
  export type Query = Commons_Cms_CspCollectionQuery;
}
export namespace CommonsCmsCspFeature {
  /** @deprecated use Commons_Cms_CspFeatureQueryVariables instead */
  export type Variables = Commons_Cms_CspFeatureQueryVariables;
  /** @deprecated use Commons_Cms_CspFeatureQuery instead */
  export type Query = Commons_Cms_CspFeatureQuery;
}
export namespace CommonsCmsCspTiles {
  /** @deprecated use Commons_Cms_CspTilesQueryVariables instead */
  export type Variables = Commons_Cms_CspTilesQueryVariables;
  /** @deprecated use Commons_Cms_CspTilesQuery instead */
  export type Query = Commons_Cms_CspTilesQuery;
}
export namespace CommonsCmsCuratedSetCollection {
  /** @deprecated use Commons_Cms_CuratedSetCollectionQueryVariables instead */
  export type Variables = Commons_Cms_CuratedSetCollectionQueryVariables;
  /** @deprecated use Commons_Cms_CuratedSetCollectionQuery instead */
  export type Query = Commons_Cms_CuratedSetCollectionQuery;
}
export namespace CommonsCmsPersonalizedListingsCollection {
  /** @deprecated use Commons_Cms_PersonalizedListingsCollectionQueryVariables instead */
  export type Variables =
    Commons_Cms_PersonalizedListingsCollectionQueryVariables;
  /** @deprecated use Commons_Cms_PersonalizedListingsCollectionQuery instead */
  export type Query = Commons_Cms_PersonalizedListingsCollectionQuery;
}
export namespace CommonsCmsRecentlyViewedListings {
  /** @deprecated use Commons_Cms_RecentlyViewedListingsQueryVariables instead */
  export type Variables = Commons_Cms_RecentlyViewedListingsQueryVariables;
  /** @deprecated use Commons_Cms_RecentlyViewedListingsQuery instead */
  export type Query = Commons_Cms_RecentlyViewedListingsQuery;
}
export namespace CommonsCmsSubcategoryNav {
  /** @deprecated use Commons_Cms_SubcategoryNavQueryVariables instead */
  export type Variables = Commons_Cms_SubcategoryNavQueryVariables;
  /** @deprecated use Commons_Cms_SubcategoryNavQuery instead */
  export type Query = Commons_Cms_SubcategoryNavQuery;
}
export namespace AddToCartButtonFields {
  /** @deprecated use AddToCartButtonFieldsFragment instead */
  export type Fragment = AddToCartButtonFieldsFragment;
}
export namespace ImageAdCreativeData {
  /** @deprecated use ImageAdCreativeDataFragment instead */
  export type Fragment = ImageAdCreativeDataFragment;
}
export namespace AdCampaignData {
  /** @deprecated use AdCampaignDataFragment instead */
  export type Fragment = AdCampaignDataFragment;
}
export namespace AdData {
  /** @deprecated use AdDataFragment instead */
  export type Fragment = AdDataFragment;
}
export namespace AdCspRowQueryData {
  /** @deprecated use AdCspRowQueryDataFragment instead */
  export type Fragment = AdCspRowQueryDataFragment;
}
export namespace CoreAdBannerWrapper {
  /** @deprecated use Core_Ad_Banner_WrapperQueryVariables instead */
  export type Variables = Core_Ad_Banner_WrapperQueryVariables;
  /** @deprecated use Core_Ad_Banner_WrapperQuery instead */
  export type Query = Core_Ad_Banner_WrapperQuery;
}
export namespace CoreAdEndedListingPage {
  /** @deprecated use Core_Ad_Ended_Listing_PageQueryVariables instead */
  export type Variables = Core_Ad_Ended_Listing_PageQueryVariables;
  /** @deprecated use Core_Ad_Ended_Listing_PageQuery instead */
  export type Query = Core_Ad_Ended_Listing_PageQuery;
}
export namespace AdGroupWithoutKeywordsData {
  /** @deprecated use AdGroupWithoutKeywordsDataFragment instead */
  export type Fragment = AdGroupWithoutKeywordsDataFragment;
}
export namespace CoreDevAdPreviews {
  /** @deprecated use Core_Dev_Ad_PreviewsQueryVariables instead */
  export type Variables = Core_Dev_Ad_PreviewsQueryVariables;
  /** @deprecated use Core_Dev_Ad_PreviewsQuery instead */
  export type Query = Core_Dev_Ad_PreviewsQuery;
}
export namespace CoreAdImageFeature {
  /** @deprecated use Core_Ad_Image_FeatureQueryVariables instead */
  export type Variables = Core_Ad_Image_FeatureQueryVariables;
  /** @deprecated use Core_Ad_Image_FeatureQuery instead */
  export type Query = Core_Ad_Image_FeatureQuery;
}
export namespace FullFinancingFields {
  /** @deprecated use FullFinancingFieldsFragment instead */
  export type Fragment = FullFinancingFieldsFragment;
}
export namespace CommonsCmsCmsEmbed {
  /** @deprecated use Commons_Cms_CmsEmbedQueryVariables instead */
  export type Variables = Commons_Cms_CmsEmbedQueryVariables;
  /** @deprecated use Commons_Cms_CmsEmbedQuery instead */
  export type Query = Commons_Cms_CmsEmbedQuery;
}
export namespace CSPLightboxData {
  /** @deprecated use CSPLightboxDataFragment instead */
  export type Fragment = CSPLightboxDataFragment;
}
export namespace CSPAdditionalTrackingFields {
  /** @deprecated use CSPAdditionalTrackingFieldsFragment instead */
  export type Fragment = CSPAdditionalTrackingFieldsFragment;
}
export namespace CSPCard {
  /** @deprecated use CSPCardFragment instead */
  export type Fragment = CSPCardFragment;
}
export namespace CommonsCmsDynamicPage {
  /** @deprecated use Commons_Cms_DynamicPageQueryVariables instead */
  export type Variables = Commons_Cms_DynamicPageQueryVariables;
  /** @deprecated use Commons_Cms_DynamicPageQuery instead */
  export type Query = Commons_Cms_DynamicPageQuery;
}
export namespace CommonsFavoriteShopListingsCollection {
  /** @deprecated use Commons_FavoriteShop_ListingsCollectionQueryVariables instead */
  export type Variables = Commons_FavoriteShop_ListingsCollectionQueryVariables;
  /** @deprecated use Commons_FavoriteShop_ListingsCollectionQuery instead */
  export type Query = Commons_FavoriteShop_ListingsCollectionQuery;
}
export namespace UndoDeleteMyFavorite {
  /** @deprecated use UndoDeleteMyFavoriteMutationVariables instead */
  export type Variables = UndoDeleteMyFavoriteMutationVariables;
  /** @deprecated use UndoDeleteMyFavoriteMutation instead */
  export type Mutation = UndoDeleteMyFavoriteMutation;
}
export namespace InOtherCartsCardData {
  /** @deprecated use InOtherCartsCardDataFragment instead */
  export type Fragment = InOtherCartsCardDataFragment;
}
export namespace ListingCardFields {
  /** @deprecated use ListingCardFieldsFragment instead */
  export type Fragment = ListingCardFieldsFragment;
}
export namespace ListingCardCPOData {
  /** @deprecated use ListingCardCPODataFragment instead */
  export type Fragment = ListingCardCPODataFragment;
}
export namespace ListingCardSignalsData {
  /** @deprecated use ListingCardSignalsDataFragment instead */
  export type Fragment = ListingCardSignalsDataFragment;
}
export namespace ListingShippingDisplayData {
  /** @deprecated use ListingShippingDisplayDataFragment instead */
  export type Fragment = ListingShippingDisplayDataFragment;
}
export namespace BumpKey {
  /** @deprecated use BumpKeyFragment instead */
  export type Fragment = BumpKeyFragment;
}
export namespace FinancingFields {
  /** @deprecated use FinancingFieldsFragment instead */
  export type Fragment = FinancingFieldsFragment;
}
export namespace ListingImagesFields {
  /** @deprecated use ListingImagesFieldsFragment instead */
  export type Fragment = ListingImagesFieldsFragment;
}
export namespace ShopFields {
  /** @deprecated use ShopFieldsFragment instead */
  export type Fragment = ShopFieldsFragment;
}
export namespace ListingsCollection {
  /** @deprecated use ListingsCollectionFragment instead */
  export type Fragment = ListingsCollectionFragment;
}
export namespace ModalSidebarListingFragment {
  /** @deprecated use ModalSidebarListingFragmentFragment instead */
  export type Fragment = ModalSidebarListingFragmentFragment;
}
export namespace PhotoTileFields {
  /** @deprecated use PhotoTileFieldsFragment instead */
  export type Fragment = PhotoTileFieldsFragment;
}
export namespace Item2PriceWithShippingCartItemData {
  /** @deprecated use Item2PriceWithShippingCartItemDataFragment instead */
  export type Fragment = Item2PriceWithShippingCartItemDataFragment;
}
export namespace Item2PriceWithShippingData {
  /** @deprecated use Item2PriceWithShippingDataFragment instead */
  export type Fragment = Item2PriceWithShippingDataFragment;
}
export namespace CoreHomepageRecentlyViewedSimilarListingsSearch {
  /** @deprecated use Core_Homepage_RecentlyViewedSimilarListingsSearchQueryVariables instead */
  export type Variables =
    Core_Homepage_RecentlyViewedSimilarListingsSearchQueryVariables;
  /** @deprecated use Core_Homepage_RecentlyViewedSimilarListingsSearchQuery instead */
  export type Query = Core_Homepage_RecentlyViewedSimilarListingsSearchQuery;
}
export namespace DiscoRecentlyViewedListingPhotosRow {
  /** @deprecated use Disco_RecentlyViewedListingPhotosRowQueryVariables instead */
  export type Variables = Disco_RecentlyViewedListingPhotosRowQueryVariables;
  /** @deprecated use Disco_RecentlyViewedListingPhotosRowQuery instead */
  export type Query = Disco_RecentlyViewedListingPhotosRowQuery;
}
export namespace CommonsShippingRegionFilter {
  /** @deprecated use Commons_ShippingRegionFilterQueryVariables instead */
  export type Variables = Commons_ShippingRegionFilterQueryVariables;
  /** @deprecated use Commons_ShippingRegionFilterQuery instead */
  export type Query = Commons_ShippingRegionFilterQuery;
}
export namespace NestedFilter {
  /** @deprecated use NestedFilterFragment instead */
  export type Fragment = NestedFilterFragment;
}
export namespace FlatFilter {
  /** @deprecated use FlatFilterFragment instead */
  export type Fragment = FlatFilterFragment;
}
export namespace CommonsSavedSearchListingsCollection {
  /** @deprecated use Commons_SavedSearch_ListingsCollectionQueryVariables instead */
  export type Variables = Commons_SavedSearch_ListingsCollectionQueryVariables;
  /** @deprecated use Commons_SavedSearch_ListingsCollectionQuery instead */
  export type Query = Commons_SavedSearch_ListingsCollectionQuery;
}
export namespace SellerInfoData {
  /** @deprecated use SellerInfoDataFragment instead */
  export type Fragment = SellerInfoDataFragment;
}
export namespace CommonsSimilarCspsRow {
  /** @deprecated use Commons_SimilarCspsRowQueryVariables instead */
  export type Variables = Commons_SimilarCspsRowQueryVariables;
  /** @deprecated use Commons_SimilarCspsRowQuery instead */
  export type Query = Commons_SimilarCspsRowQuery;
}
export namespace CoreSearchCompletionsQuery {
  /** @deprecated use Core_Search_CompletionsQueryQueryVariables instead */
  export type Variables = Core_Search_CompletionsQueryQueryVariables;
  /** @deprecated use Core_Search_CompletionsQueryQuery instead */
  export type Query = Core_Search_CompletionsQueryQuery;
}
export namespace CommonsMParticleArticle {
  /** @deprecated use Commons_MParticle_ArticleQueryVariables instead */
  export type Variables = Commons_MParticle_ArticleQueryVariables;
  /** @deprecated use Commons_MParticle_ArticleQuery instead */
  export type Query = Commons_MParticle_ArticleQuery;
}
export namespace CommonsMParticleOrderBundles {
  /** @deprecated use Commons_MParticle_OrderBundlesQueryVariables instead */
  export type Variables = Commons_MParticle_OrderBundlesQueryVariables;
  /** @deprecated use Commons_MParticle_OrderBundlesQuery instead */
  export type Query = Commons_MParticle_OrderBundlesQuery;
}
export namespace TypicalNewPriceDisplayFields {
  /** @deprecated use TypicalNewPriceDisplayFieldsFragment instead */
  export type Fragment = TypicalNewPriceDisplayFieldsFragment;
}
export namespace ReverifyCreditCard {
  /** @deprecated use ReverifyCreditCardMutationVariables instead */
  export type Variables = ReverifyCreditCardMutationVariables;
  /** @deprecated use ReverifyCreditCardMutation instead */
  export type Mutation = ReverifyCreditCardMutation;
}
export namespace WatchBadgeData {
  /** @deprecated use WatchBadgeDataFragment instead */
  export type Fragment = WatchBadgeDataFragment;
}
export namespace CreateMyWatches {
  /** @deprecated use CreateMyWatchesMutationVariables instead */
  export type Variables = CreateMyWatchesMutationVariables;
  /** @deprecated use CreateMyWatchesMutation instead */
  export type Mutation = CreateMyWatchesMutation;
}
export namespace DeleteMyWatches {
  /** @deprecated use DeleteMyWatchesMutationVariables instead */
  export type Variables = DeleteMyWatchesMutationVariables;
  /** @deprecated use DeleteMyWatchesMutation instead */
  export type Mutation = DeleteMyWatchesMutation;
}
export namespace CommonsWatchedListings {
  /** @deprecated use Commons_WatchedListingsQueryVariables instead */
  export type Variables = Commons_WatchedListingsQueryVariables;
  /** @deprecated use Commons_WatchedListingsQuery instead */
  export type Query = Commons_WatchedListingsQuery;
}
export namespace CoreGetFavoriteSearchesQuery {
  /** @deprecated use Core_Get_Favorite_SearchesQueryQueryVariables instead */
  export type Variables = Core_Get_Favorite_SearchesQueryQueryVariables;
  /** @deprecated use Core_Get_Favorite_SearchesQueryQuery instead */
  export type Query = Core_Get_Favorite_SearchesQueryQuery;
}
export namespace mParticleListingFields {
  /** @deprecated use mParticleListingFieldsFragment instead */
  export type Fragment = mParticleListingFieldsFragment;
}
export namespace CommonsMParticleListing {
  /** @deprecated use Commons_MParticle_ListingQueryVariables instead */
  export type Variables = Commons_MParticle_ListingQueryVariables;
  /** @deprecated use Commons_MParticle_ListingQuery instead */
  export type Query = Commons_MParticle_ListingQuery;
}
export namespace TrendingSearchesQuery {
  /** @deprecated use TrendingSearchesQueryQueryVariables instead */
  export type Variables = TrendingSearchesQueryQueryVariables;
  /** @deprecated use TrendingSearchesQueryQuery instead */
  export type Query = TrendingSearchesQueryQuery;
}
export namespace CoreExchangeRates {
  /** @deprecated use Core_Exchange_RatesQueryVariables instead */
  export type Variables = Core_Exchange_RatesQueryVariables;
  /** @deprecated use Core_Exchange_RatesQuery instead */
  export type Query = Core_Exchange_RatesQuery;
}
export namespace ListingGreatValueData {
  /** @deprecated use ListingGreatValueDataFragment instead */
  export type Fragment = ListingGreatValueDataFragment;
}
export namespace ListingForBuyerShippingFields {
  /** @deprecated use ListingForBuyerShippingFieldsFragment instead */
  export type Fragment = ListingForBuyerShippingFieldsFragment;
}
export namespace ListingForBuyerFields {
  /** @deprecated use ListingForBuyerFieldsFragment instead */
  export type Fragment = ListingForBuyerFieldsFragment;
}
export namespace ListingCreateOfferButtonData {
  /** @deprecated use ListingCreateOfferButtonDataFragment instead */
  export type Fragment = ListingCreateOfferButtonDataFragment;
}
export namespace Item2LoggedInOffersCountData {
  /** @deprecated use Item2LoggedInOffersCountDataFragment instead */
  export type Fragment = Item2LoggedInOffersCountDataFragment;
}
export namespace CommonsOffersImmediatePaymentReview {
  /** @deprecated use Commons_Offers_ImmediatePaymentReviewQueryVariables instead */
  export type Variables = Commons_Offers_ImmediatePaymentReviewQueryVariables;
  /** @deprecated use Commons_Offers_ImmediatePaymentReviewQuery instead */
  export type Query = Commons_Offers_ImmediatePaymentReviewQuery;
}
export namespace SellerOfferListingFragment {
  /** @deprecated use SellerOfferListingFragmentFragment instead */
  export type Fragment = SellerOfferListingFragmentFragment;
}
export namespace OfferListingFragment {
  /** @deprecated use OfferListingFragmentFragment instead */
  export type Fragment = OfferListingFragmentFragment;
}
export namespace CommonsOffersEditOfferForm {
  /** @deprecated use Commons_Offers_EditOfferFormQueryVariables instead */
  export type Variables = Commons_Offers_EditOfferFormQueryVariables;
  /** @deprecated use Commons_Offers_EditOfferFormQuery instead */
  export type Query = Commons_Offers_EditOfferFormQuery;
}
export namespace CommonsOffersNewOfferForm {
  /** @deprecated use Commons_Offers_NewOfferFormQueryVariables instead */
  export type Variables = Commons_Offers_NewOfferFormQueryVariables;
  /** @deprecated use Commons_Offers_NewOfferFormQuery instead */
  export type Query = Commons_Offers_NewOfferFormQuery;
}
export namespace CommonsOffersCreateOfferForm {
  /** @deprecated use Commons_Offers_CreateOfferFormMutationVariables instead */
  export type Variables = Commons_Offers_CreateOfferFormMutationVariables;
  /** @deprecated use Commons_Offers_CreateOfferFormMutation instead */
  export type Mutation = Commons_Offers_CreateOfferFormMutation;
}
export namespace CommonsOffersUpdateOfferForm {
  /** @deprecated use Commons_Offers_UpdateOfferFormMutationVariables instead */
  export type Variables = Commons_Offers_UpdateOfferFormMutationVariables;
  /** @deprecated use Commons_Offers_UpdateOfferFormMutation instead */
  export type Mutation = Commons_Offers_UpdateOfferFormMutation;
}
export namespace AutoOfferListingFragment {
  /** @deprecated use AutoOfferListingFragmentFragment instead */
  export type Fragment = AutoOfferListingFragmentFragment;
}
export namespace CoreAutoOffersCreateAutoOfferMutation {
  /** @deprecated use Core_AutoOffers_CreateAutoOfferMutationMutationVariables instead */
  export type Variables =
    Core_AutoOffers_CreateAutoOfferMutationMutationVariables;
  /** @deprecated use Core_AutoOffers_CreateAutoOfferMutationMutation instead */
  export type Mutation = Core_AutoOffers_CreateAutoOfferMutationMutation;
}
export namespace CoreAutoOffersDeleteAutoOfferMutation {
  /** @deprecated use Core_AutoOffers_DeleteAutoOfferMutationMutationVariables instead */
  export type Variables =
    Core_AutoOffers_DeleteAutoOfferMutationMutationVariables;
  /** @deprecated use Core_AutoOffers_DeleteAutoOfferMutationMutation instead */
  export type Mutation = Core_AutoOffers_DeleteAutoOfferMutationMutation;
}
export namespace CoreTrackBumpInteraction {
  /** @deprecated use Core_Track_Bump_InteractionMutationVariables instead */
  export type Variables = Core_Track_Bump_InteractionMutationVariables;
  /** @deprecated use Core_Track_Bump_InteractionMutation instead */
  export type Mutation = Core_Track_Bump_InteractionMutation;
}
export namespace CoreFetchCountries {
  /** @deprecated use Core_FetchCountriesQueryVariables instead */
  export type Variables = Core_FetchCountriesQueryVariables;
  /** @deprecated use Core_FetchCountriesQuery instead */
  export type Query = Core_FetchCountriesQuery;
}
export namespace CoreTestUseMutation {
  /** @deprecated use Core_Test_UseMutationMutationVariables instead */
  export type Variables = Core_Test_UseMutationMutationVariables;
  /** @deprecated use Core_Test_UseMutationMutation instead */
  export type Mutation = Core_Test_UseMutationMutation;
}
export namespace CreateUserActionMeData {
  /** @deprecated use CreateUserActionMeDataFragment instead */
  export type Fragment = CreateUserActionMeDataFragment;
}
export namespace ActionCacheFragmentData {
  /** @deprecated use ActionCacheFragmentDataFragment instead */
  export type Fragment = ActionCacheFragmentDataFragment;
}
export namespace CoreUserActionsCreate {
  /** @deprecated use Core_UserActions_CreateMutationVariables instead */
  export type Variables = Core_UserActions_CreateMutationVariables;
  /** @deprecated use Core_UserActions_CreateMutation instead */
  export type Mutation = Core_UserActions_CreateMutation;
}
export namespace CoreRequestAccountDeletion {
  /** @deprecated use Core_RequestAccountDeletionMutationVariables instead */
  export type Variables = Core_RequestAccountDeletionMutationVariables;
  /** @deprecated use Core_RequestAccountDeletionMutation instead */
  export type Mutation = Core_RequestAccountDeletionMutation;
}
export namespace CoreAddressAutocompleteFindAutocompleteSuggestions {
  /** @deprecated use Core_AddressAutocomplete_FindAutocompleteSuggestionsQueryVariables instead */
  export type Variables =
    Core_AddressAutocomplete_FindAutocompleteSuggestionsQueryVariables;
  /** @deprecated use Core_AddressAutocomplete_FindAutocompleteSuggestionsQuery instead */
  export type Query = Core_AddressAutocomplete_FindAutocompleteSuggestionsQuery;
}
export namespace CoreAddressAutocompleteSelectAutocompleteSuggestion {
  /** @deprecated use Core_AddressAutocomplete_SelectAutocompleteSuggestionMutationVariables instead */
  export type Variables =
    Core_AddressAutocomplete_SelectAutocompleteSuggestionMutationVariables;
  /** @deprecated use Core_AddressAutocomplete_SelectAutocompleteSuggestionMutation instead */
  export type Mutation =
    Core_AddressAutocomplete_SelectAutocompleteSuggestionMutation;
}
export namespace CoreAdminRolloutsUserSearch {
  /** @deprecated use Core_AdminRollouts_UserSearchQueryVariables instead */
  export type Variables = Core_AdminRollouts_UserSearchQueryVariables;
  /** @deprecated use Core_AdminRollouts_UserSearchQuery instead */
  export type Query = Core_AdminRollouts_UserSearchQuery;
}
export namespace CoreAdminRolloutsCompareUserSearch {
  /** @deprecated use Core_AdminRolloutsCompare_UserSearchQueryVariables instead */
  export type Variables = Core_AdminRolloutsCompare_UserSearchQueryVariables;
  /** @deprecated use Core_AdminRolloutsCompare_UserSearchQuery instead */
  export type Query = Core_AdminRolloutsCompare_UserSearchQuery;
}
export namespace BuyItNowButtonFields {
  /** @deprecated use BuyItNowButtonFieldsFragment instead */
  export type Fragment = BuyItNowButtonFieldsFragment;
}
export namespace BuyItNowExpressPayOptionsFields {
  /** @deprecated use BuyItNowExpressPayOptionsFieldsFragment instead */
  export type Fragment = BuyItNowExpressPayOptionsFieldsFragment;
}
export namespace BuyItNowPaymentOptionsFields {
  /** @deprecated use BuyItNowPaymentOptionsFieldsFragment instead */
  export type Fragment = BuyItNowPaymentOptionsFieldsFragment;
}
export namespace CoreCheckoutFetchDigitalWalletPaymentCheckout {
  /** @deprecated use Core_Checkout_FetchDigitalWalletPaymentCheckoutQueryVariables instead */
  export type Variables =
    Core_Checkout_FetchDigitalWalletPaymentCheckoutQueryVariables;
  /** @deprecated use Core_Checkout_FetchDigitalWalletPaymentCheckoutQuery instead */
  export type Query = Core_Checkout_FetchDigitalWalletPaymentCheckoutQuery;
}
export namespace StartCheckoutForListing {
  /** @deprecated use StartCheckoutForListingMutationVariables instead */
  export type Variables = StartCheckoutForListingMutationVariables;
  /** @deprecated use StartCheckoutForListingMutation instead */
  export type Mutation = StartCheckoutForListingMutation;
}
export namespace BuyListingNowApplePayData {
  /** @deprecated use BuyListingNowApplePayDataFragment instead */
  export type Fragment = BuyListingNowApplePayDataFragment;
}
export namespace StartDigitalWalletCheckoutForListing {
  /** @deprecated use StartDigitalWalletCheckoutForListingMutationVariables instead */
  export type Variables = StartDigitalWalletCheckoutForListingMutationVariables;
  /** @deprecated use StartDigitalWalletCheckoutForListingMutation instead */
  export type Mutation = StartDigitalWalletCheckoutForListingMutation;
}
export namespace UpdateShippingEstimateMutation {
  /** @deprecated use UpdateShippingEstimateMutationMutationVariables instead */
  export type Variables = UpdateShippingEstimateMutationMutationVariables;
  /** @deprecated use UpdateShippingEstimateMutationMutation instead */
  export type Mutation = UpdateShippingEstimateMutationMutation;
}
export namespace FinalizeDigitalWalletPayment {
  /** @deprecated use FinalizeDigitalWalletPaymentMutationVariables instead */
  export type Variables = FinalizeDigitalWalletPaymentMutationVariables;
  /** @deprecated use FinalizeDigitalWalletPaymentMutation instead */
  export type Mutation = FinalizeDigitalWalletPaymentMutation;
}
export namespace CheckoutCardContinueButtonFields {
  /** @deprecated use CheckoutCardContinueButtonFieldsFragment instead */
  export type Fragment = CheckoutCardContinueButtonFieldsFragment;
}
export namespace CheckoutCreditCardFormFields {
  /** @deprecated use CheckoutCreditCardFormFieldsFragment instead */
  export type Fragment = CheckoutCreditCardFormFieldsFragment;
}
export namespace CheckoutDiscountCodeFormFields {
  /** @deprecated use CheckoutDiscountCodeFormFieldsFragment instead */
  export type Fragment = CheckoutDiscountCodeFormFieldsFragment;
}
export namespace CoreCheckoutApplyDiscountCode {
  /** @deprecated use Core_Checkout_ApplyDiscountCodeMutationVariables instead */
  export type Variables = Core_Checkout_ApplyDiscountCodeMutationVariables;
  /** @deprecated use Core_Checkout_ApplyDiscountCodeMutation instead */
  export type Mutation = Core_Checkout_ApplyDiscountCodeMutation;
}
export namespace CoreCheckoutHeader {
  /** @deprecated use Core_Checkout_HeaderQueryVariables instead */
  export type Variables = Core_Checkout_HeaderQueryVariables;
  /** @deprecated use Core_Checkout_HeaderQuery instead */
  export type Query = Core_Checkout_HeaderQuery;
}
export namespace CheckoutKlarnaContinueButtonFields {
  /** @deprecated use CheckoutKlarnaContinueButtonFieldsFragment instead */
  export type Fragment = CheckoutKlarnaContinueButtonFieldsFragment;
}
export namespace CoreCheckoutCreateKlarnaPaymentRedirectUrl {
  /** @deprecated use Core_Checkout_CreateKlarnaPaymentRedirectUrlMutationVariables instead */
  export type Variables =
    Core_Checkout_CreateKlarnaPaymentRedirectUrlMutationVariables;
  /** @deprecated use Core_Checkout_CreateKlarnaPaymentRedirectUrlMutation instead */
  export type Mutation = Core_Checkout_CreateKlarnaPaymentRedirectUrlMutation;
}
export namespace CoreCheckoutStartPaypalCheckoutRedirect {
  /** @deprecated use Core_Checkout_StartPaypalCheckout_RedirectMutationVariables instead */
  export type Variables =
    Core_Checkout_StartPaypalCheckout_RedirectMutationVariables;
  /** @deprecated use Core_Checkout_StartPaypalCheckout_RedirectMutation instead */
  export type Mutation = Core_Checkout_StartPaypalCheckout_RedirectMutation;
}
export namespace CheckoutShippingAddressFormFields {
  /** @deprecated use CheckoutShippingAddressFormFieldsFragment instead */
  export type Fragment = CheckoutShippingAddressFormFieldsFragment;
}
export namespace CheckoutTokenizedCardPaymentFormFields {
  /** @deprecated use CheckoutTokenizedCardPaymentFormFieldsFragment instead */
  export type Fragment = CheckoutTokenizedCardPaymentFormFieldsFragment;
}
export namespace CoreConversationsFlagMessage {
  /** @deprecated use Core_Conversations_FlagMessageMutationVariables instead */
  export type Variables = Core_Conversations_FlagMessageMutationVariables;
  /** @deprecated use Core_Conversations_FlagMessageMutation instead */
  export type Mutation = Core_Conversations_FlagMessageMutation;
}
export namespace CountriesData {
  /** @deprecated use CountriesDataFragment instead */
  export type Fragment = CountriesDataFragment;
}
export namespace CreditCardDeleteButtonFields {
  /** @deprecated use CreditCardDeleteButtonFieldsFragment instead */
  export type Fragment = CreditCardDeleteButtonFieldsFragment;
}
export namespace CoreCheckoutDeleteCreditCard {
  /** @deprecated use Core_Checkout_DeleteCreditCardMutationVariables instead */
  export type Variables = Core_Checkout_DeleteCreditCardMutationVariables;
  /** @deprecated use Core_Checkout_DeleteCreditCardMutation instead */
  export type Mutation = Core_Checkout_DeleteCreditCardMutation;
}
export namespace CoreMyAccountCreditCardsOnFile {
  /** @deprecated use Core_MyAccount_CreditCardsOnFileQueryVariables instead */
  export type Variables = Core_MyAccount_CreditCardsOnFileQueryVariables;
  /** @deprecated use Core_MyAccount_CreditCardsOnFileQuery instead */
  export type Query = Core_MyAccount_CreditCardsOnFileQuery;
}
export namespace ListingAffirmCalloutFields {
  /** @deprecated use ListingAffirmCalloutFieldsFragment instead */
  export type Fragment = ListingAffirmCalloutFieldsFragment;
}
export namespace ListingFinancingCalloutFields {
  /** @deprecated use ListingFinancingCalloutFieldsFragment instead */
  export type Fragment = ListingFinancingCalloutFieldsFragment;
}
export namespace ListingKlarnaCalloutFields {
  /** @deprecated use ListingKlarnaCalloutFieldsFragment instead */
  export type Fragment = ListingKlarnaCalloutFieldsFragment;
}
export namespace ListingPayPalCalloutFields {
  /** @deprecated use ListingPayPalCalloutFieldsFragment instead */
  export type Fragment = ListingPayPalCalloutFieldsFragment;
}
export namespace ListingPaymentOptionsFields {
  /** @deprecated use ListingPaymentOptionsFieldsFragment instead */
  export type Fragment = ListingPaymentOptionsFieldsFragment;
}
export namespace CorePaypalOnboardSeller {
  /** @deprecated use Core_PaypalOnboardSellerQueryVariables instead */
  export type Variables = Core_PaypalOnboardSellerQueryVariables;
  /** @deprecated use Core_PaypalOnboardSellerQuery instead */
  export type Query = Core_PaypalOnboardSellerQuery;
}
export namespace CoreAdminBlockProductReview {
  /** @deprecated use Core_AdminBlockProductReviewMutationVariables instead */
  export type Variables = Core_AdminBlockProductReviewMutationVariables;
  /** @deprecated use Core_AdminBlockProductReviewMutation instead */
  export type Mutation = Core_AdminBlockProductReviewMutation;
}
export namespace ProductReviewCardFields {
  /** @deprecated use ProductReviewCardFieldsFragment instead */
  export type Fragment = ProductReviewCardFieldsFragment;
}
export namespace ProductReviewFilterFields {
  /** @deprecated use ProductReviewFilterFieldsFragment instead */
  export type Fragment = ProductReviewFilterFieldsFragment;
}
export namespace CoreProductReviewFlag {
  /** @deprecated use Core_ProductReview_FlagMutationVariables instead */
  export type Variables = Core_ProductReview_FlagMutationVariables;
  /** @deprecated use Core_ProductReview_FlagMutation instead */
  export type Mutation = Core_ProductReview_FlagMutation;
}
export namespace CoreProductReviewUpdate {
  /** @deprecated use Core_ProductReview_UpdateMutationVariables instead */
  export type Variables = Core_ProductReview_UpdateMutationVariables;
  /** @deprecated use Core_ProductReview_UpdateMutation instead */
  export type Mutation = Core_ProductReview_UpdateMutation;
}
export namespace CoreProductReviewVote {
  /** @deprecated use Core_ProductReview_VoteMutationVariables instead */
  export type Variables = Core_ProductReview_VoteMutationVariables;
  /** @deprecated use Core_ProductReview_VoteMutation instead */
  export type Mutation = Core_ProductReview_VoteMutation;
}
export namespace ProductReviewCSPFields {
  /** @deprecated use ProductReviewCSPFieldsFragment instead */
  export type Fragment = ProductReviewCSPFieldsFragment;
}
export namespace CoreProductReviews {
  /** @deprecated use Core_Product_ReviewsQueryVariables instead */
  export type Variables = Core_Product_ReviewsQueryVariables;
  /** @deprecated use Core_Product_ReviewsQuery instead */
  export type Query = Core_Product_ReviewsQuery;
}
export namespace CoreAdminReportsGenerateAdminReport {
  /** @deprecated use Core_AdminReports_GenerateAdminReportMutationVariables instead */
  export type Variables =
    Core_AdminReports_GenerateAdminReportMutationVariables;
  /** @deprecated use Core_AdminReports_GenerateAdminReportMutation instead */
  export type Mutation = Core_AdminReports_GenerateAdminReportMutation;
}
export namespace CoreUpdateAdCampaign {
  /** @deprecated use Core_Update_Ad_CampaignMutationVariables instead */
  export type Variables = Core_Update_Ad_CampaignMutationVariables;
  /** @deprecated use Core_Update_Ad_CampaignMutation instead */
  export type Mutation = Core_Update_Ad_CampaignMutation;
}
export namespace CoreAdAdminAdCampaignForm {
  /** @deprecated use Core_Ad_Admin_Ad_Campaign_FormQueryVariables instead */
  export type Variables = Core_Ad_Admin_Ad_Campaign_FormQueryVariables;
  /** @deprecated use Core_Ad_Admin_Ad_Campaign_FormQuery instead */
  export type Query = Core_Ad_Admin_Ad_Campaign_FormQuery;
}
export namespace CoreAdAdminAdCampaigns {
  /** @deprecated use Core_Ad_Admin_AdCampaignsQueryVariables instead */
  export type Variables = Core_Ad_Admin_AdCampaignsQueryVariables;
  /** @deprecated use Core_Ad_Admin_AdCampaignsQuery instead */
  export type Query = Core_Ad_Admin_AdCampaignsQuery;
}
export namespace CoreUpdateAd {
  /** @deprecated use Core_Update_AdMutationVariables instead */
  export type Variables = Core_Update_AdMutationVariables;
  /** @deprecated use Core_Update_AdMutation instead */
  export type Mutation = Core_Update_AdMutation;
}
export namespace CoreAdAdminAdForm {
  /** @deprecated use Core_Ad_Admin_Ad_FormQueryVariables instead */
  export type Variables = Core_Ad_Admin_Ad_FormQueryVariables;
  /** @deprecated use Core_Ad_Admin_Ad_FormQuery instead */
  export type Query = Core_Ad_Admin_Ad_FormQuery;
}
export namespace CoreCopyAdGroup {
  /** @deprecated use Core_Copy_AdGroupMutationVariables instead */
  export type Variables = Core_Copy_AdGroupMutationVariables;
  /** @deprecated use Core_Copy_AdGroupMutation instead */
  export type Mutation = Core_Copy_AdGroupMutation;
}
export namespace CoreUpdateAdGroup {
  /** @deprecated use Core_Update_AdGroupMutationVariables instead */
  export type Variables = Core_Update_AdGroupMutationVariables;
  /** @deprecated use Core_Update_AdGroupMutation instead */
  export type Mutation = Core_Update_AdGroupMutation;
}
export namespace CoreUpdateAdGroupKeywords {
  /** @deprecated use Core_Update_AdGroupKeywordsMutationVariables instead */
  export type Variables = Core_Update_AdGroupKeywordsMutationVariables;
  /** @deprecated use Core_Update_AdGroupKeywordsMutation instead */
  export type Mutation = Core_Update_AdGroupKeywordsMutation;
}
export namespace AdGroupWithKeywordsData {
  /** @deprecated use AdGroupWithKeywordsDataFragment instead */
  export type Fragment = AdGroupWithKeywordsDataFragment;
}
export namespace CoreAdAdminAdGroupForm {
  /** @deprecated use Core_Ad_Admin_AdGroup_FormQueryVariables instead */
  export type Variables = Core_Ad_Admin_AdGroup_FormQueryVariables;
  /** @deprecated use Core_Ad_Admin_AdGroup_FormQuery instead */
  export type Query = Core_Ad_Admin_AdGroup_FormQuery;
}
export namespace AdGroupData {
  /** @deprecated use AdGroupDataFragment instead */
  export type Fragment = AdGroupDataFragment;
}
export namespace CoreAdAdminAdGroups {
  /** @deprecated use Core_Ad_Admin_AdGroupsQueryVariables instead */
  export type Variables = Core_Ad_Admin_AdGroupsQueryVariables;
  /** @deprecated use Core_Ad_Admin_AdGroupsQuery instead */
  export type Query = Core_Ad_Admin_AdGroupsQuery;
}
export namespace CoreDeleteAd {
  /** @deprecated use Core_Delete_AdMutationVariables instead */
  export type Variables = Core_Delete_AdMutationVariables;
  /** @deprecated use Core_Delete_AdMutation instead */
  export type Mutation = Core_Delete_AdMutation;
}
export namespace CoreUpdateAdZone {
  /** @deprecated use Core_Update_AdZoneMutationVariables instead */
  export type Variables = Core_Update_AdZoneMutationVariables;
  /** @deprecated use Core_Update_AdZoneMutation instead */
  export type Mutation = Core_Update_AdZoneMutation;
}
export namespace CoreAdAdminAdZoneForm {
  /** @deprecated use Core_Ad_Admin_Ad_Zone_FormQueryVariables instead */
  export type Variables = Core_Ad_Admin_Ad_Zone_FormQueryVariables;
  /** @deprecated use Core_Ad_Admin_Ad_Zone_FormQuery instead */
  export type Query = Core_Ad_Admin_Ad_Zone_FormQuery;
}
export namespace AdZoneData {
  /** @deprecated use AdZoneDataFragment instead */
  export type Fragment = AdZoneDataFragment;
}
export namespace CoreAdAdminAdZones {
  /** @deprecated use Core_Ad_Admin_Ad_ZonesQueryVariables instead */
  export type Variables = Core_Ad_Admin_Ad_ZonesQueryVariables;
  /** @deprecated use Core_Ad_Admin_Ad_ZonesQuery instead */
  export type Query = Core_Ad_Admin_Ad_ZonesQuery;
}
export namespace AdCardData {
  /** @deprecated use AdCardDataFragment instead */
  export type Fragment = AdCardDataFragment;
}
export namespace CoreAdAdminAds {
  /** @deprecated use Core_Ad_Admin_AdsQueryVariables instead */
  export type Variables = Core_Ad_Admin_AdsQueryVariables;
  /** @deprecated use Core_Ad_Admin_AdsQuery instead */
  export type Query = Core_Ad_Admin_AdsQuery;
}
export namespace CoreUpdateAdvertiser {
  /** @deprecated use Core_Update_AdvertiserMutationVariables instead */
  export type Variables = Core_Update_AdvertiserMutationVariables;
  /** @deprecated use Core_Update_AdvertiserMutation instead */
  export type Mutation = Core_Update_AdvertiserMutation;
}
export namespace CoreAdAdminAdvertiserForm {
  /** @deprecated use Core_Ad_Admin_Advertiser_FormQueryVariables instead */
  export type Variables = Core_Ad_Admin_Advertiser_FormQueryVariables;
  /** @deprecated use Core_Ad_Admin_Advertiser_FormQuery instead */
  export type Query = Core_Ad_Admin_Advertiser_FormQuery;
}
export namespace AdvertiserData {
  /** @deprecated use AdvertiserDataFragment instead */
  export type Fragment = AdvertiserDataFragment;
}
export namespace CoreAdAdminAdvertisers {
  /** @deprecated use Core_Ad_Admin_AdvertisersQueryVariables instead */
  export type Variables = Core_Ad_Admin_AdvertisersQueryVariables;
  /** @deprecated use Core_Ad_Admin_AdvertisersQuery instead */
  export type Query = Core_Ad_Admin_AdvertisersQuery;
}
export namespace CoreAdaChatTokenQuery {
  /** @deprecated use Core_AdaChat_TokenQueryQueryVariables instead */
  export type Variables = Core_AdaChat_TokenQueryQueryVariables;
  /** @deprecated use Core_AdaChat_TokenQueryQuery instead */
  export type Query = Core_AdaChat_TokenQueryQuery;
}
export namespace CoreAddressFormFieldsConfigsQuery {
  /** @deprecated use Core_Address_Form_Fields_Configs_QueryQueryVariables instead */
  export type Variables = Core_Address_Form_Fields_Configs_QueryQueryVariables;
  /** @deprecated use Core_Address_Form_Fields_Configs_QueryQuery instead */
  export type Query = Core_Address_Form_Fields_Configs_QueryQuery;
}
export namespace CoreAddressVerify {
  /** @deprecated use Core_Address_VerifyQueryVariables instead */
  export type Variables = Core_Address_VerifyQueryVariables;
  /** @deprecated use Core_Address_VerifyQuery instead */
  export type Query = Core_Address_VerifyQuery;
}
export namespace CoreAcceptAddressSuggestion {
  /** @deprecated use Core_Accept_AddressSuggestionMutationVariables instead */
  export type Variables = Core_Accept_AddressSuggestionMutationVariables;
  /** @deprecated use Core_Accept_AddressSuggestionMutation instead */
  export type Mutation = Core_Accept_AddressSuggestionMutation;
}
export namespace AddressBook {
  /** @deprecated use AddressBookQueryVariables instead */
  export type Variables = AddressBookQueryVariables;
  /** @deprecated use AddressBookQuery instead */
  export type Query = AddressBookQuery;
}
export namespace UpdateMyAddress {
  /** @deprecated use UpdateMyAddressMutationVariables instead */
  export type Variables = UpdateMyAddressMutationVariables;
  /** @deprecated use UpdateMyAddressMutation instead */
  export type Mutation = UpdateMyAddressMutation;
}
export namespace DeleteMyAddress {
  /** @deprecated use DeleteMyAddressMutationVariables instead */
  export type Variables = DeleteMyAddressMutationVariables;
  /** @deprecated use DeleteMyAddressMutation instead */
  export type Mutation = DeleteMyAddressMutation;
}
export namespace Countries {
  /** @deprecated use CountriesQueryVariables instead */
  export type Variables = CountriesQueryVariables;
  /** @deprecated use CountriesQuery instead */
  export type Query = CountriesQuery;
}
export namespace CoreAdminClaimOrderBundleUserSearch {
  /** @deprecated use Core_AdminClaimOrderBundle_UserSearchQueryVariables instead */
  export type Variables = Core_AdminClaimOrderBundle_UserSearchQueryVariables;
  /** @deprecated use Core_AdminClaimOrderBundle_UserSearchQuery instead */
  export type Query = Core_AdminClaimOrderBundle_UserSearchQuery;
}
export namespace CoreUpdateAdminNote {
  /** @deprecated use Core_UpdateAdminNoteMutationVariables instead */
  export type Variables = Core_UpdateAdminNoteMutationVariables;
  /** @deprecated use Core_UpdateAdminNoteMutation instead */
  export type Mutation = Core_UpdateAdminNoteMutation;
}
export namespace CoreCreateAdminNote {
  /** @deprecated use Core_CreateAdminNoteMutationVariables instead */
  export type Variables = Core_CreateAdminNoteMutationVariables;
  /** @deprecated use Core_CreateAdminNoteMutation instead */
  export type Mutation = Core_CreateAdminNoteMutation;
}
export namespace adminNotesSearch {
  /** @deprecated use adminNotesSearchQueryVariables instead */
  export type Variables = adminNotesSearchQueryVariables;
  /** @deprecated use adminNotesSearchQuery instead */
  export type Query = adminNotesSearchQuery;
}
export namespace shopAdyenBalancePlatformNotifications {
  /** @deprecated use shopAdyenBalancePlatformNotificationsQueryVariables instead */
  export type Variables = shopAdyenBalancePlatformNotificationsQueryVariables;
  /** @deprecated use shopAdyenBalancePlatformNotificationsQuery instead */
  export type Query = shopAdyenBalancePlatformNotificationsQuery;
}
export namespace CoreSearchAdminTax1099kReportingThresholds {
  /** @deprecated use Core_SearchAdminTax1099kReportingThresholdsQueryVariables instead */
  export type Variables =
    Core_SearchAdminTax1099kReportingThresholdsQueryVariables;
  /** @deprecated use Core_SearchAdminTax1099kReportingThresholdsQuery instead */
  export type Query = Core_SearchAdminTax1099kReportingThresholdsQuery;
}
export namespace CoreDeleteAdminTax1099kReportingThreshold {
  /** @deprecated use Core_DeleteAdminTax1099kReportingThresholdMutationVariables instead */
  export type Variables =
    Core_DeleteAdminTax1099kReportingThresholdMutationVariables;
  /** @deprecated use Core_DeleteAdminTax1099kReportingThresholdMutation instead */
  export type Mutation = Core_DeleteAdminTax1099kReportingThresholdMutation;
}
export namespace CoreUpdateAdminTax1099kReportingThreshold {
  /** @deprecated use Core_UpdateAdminTax1099kReportingThresholdMutationVariables instead */
  export type Variables =
    Core_UpdateAdminTax1099kReportingThresholdMutationVariables;
  /** @deprecated use Core_UpdateAdminTax1099kReportingThresholdMutation instead */
  export type Mutation = Core_UpdateAdminTax1099kReportingThresholdMutation;
}
export namespace CoreCreateAdminTax1099kReportingThreshold {
  /** @deprecated use Core_CreateAdminTax1099kReportingThresholdMutationVariables instead */
  export type Variables =
    Core_CreateAdminTax1099kReportingThresholdMutationVariables;
  /** @deprecated use Core_CreateAdminTax1099kReportingThresholdMutation instead */
  export type Mutation = Core_CreateAdminTax1099kReportingThresholdMutation;
}
export namespace SEARCHUSERS {
  /** @deprecated use SEARCH_USERSQueryVariables instead */
  export type Variables = SEARCH_USERSQueryVariables;
  /** @deprecated use SEARCH_USERSQuery instead */
  export type Query = SEARCH_USERSQuery;
}
export namespace SearchUserDatadogErrors {
  /** @deprecated use SearchUserDatadogErrorsQueryVariables instead */
  export type Variables = SearchUserDatadogErrorsQueryVariables;
  /** @deprecated use SearchUserDatadogErrorsQuery instead */
  export type Query = SearchUserDatadogErrorsQuery;
}
export namespace CoreCategoryBestSellersSearch {
  /** @deprecated use Core_Category_BestSellersSearchQueryVariables instead */
  export type Variables = Core_Category_BestSellersSearchQueryVariables;
  /** @deprecated use Core_Category_BestSellersSearchQuery instead */
  export type Query = Core_Category_BestSellersSearchQuery;
}
export namespace myShopBillingMethods {
  /** @deprecated use myShopBillingMethodsFragment instead */
  export type Fragment = myShopBillingMethodsFragment;
}
export namespace GetBillingMethod {
  /** @deprecated use GetBillingMethodQueryVariables instead */
  export type Variables = GetBillingMethodQueryVariables;
  /** @deprecated use GetBillingMethodQuery instead */
  export type Query = GetBillingMethodQuery;
}
export namespace UpdateMyShopBillingMethod {
  /** @deprecated use UpdateMyShopBillingMethodMutationVariables instead */
  export type Variables = UpdateMyShopBillingMethodMutationVariables;
  /** @deprecated use UpdateMyShopBillingMethodMutation instead */
  export type Mutation = UpdateMyShopBillingMethodMutation;
}
export namespace BillingMethodVerifyShopper {
  /** @deprecated use BillingMethodVerifyShopperMutationVariables instead */
  export type Variables = BillingMethodVerifyShopperMutationVariables;
  /** @deprecated use BillingMethodVerifyShopperMutation instead */
  export type Mutation = BillingMethodVerifyShopperMutation;
}
export namespace CoreBrandCompletions {
  /** @deprecated use Core_BrandCompletionsQueryVariables instead */
  export type Variables = Core_BrandCompletionsQueryVariables;
  /** @deprecated use Core_BrandCompletionsQuery instead */
  export type Query = Core_BrandCompletionsQuery;
}
export namespace CoreLayoutBrowsePage {
  /** @deprecated use Core_Layout_BrowsePageQueryVariables instead */
  export type Variables = Core_Layout_BrowsePageQueryVariables;
  /** @deprecated use Core_Layout_BrowsePageQuery instead */
  export type Query = Core_Layout_BrowsePageQuery;
}
export namespace CoreLayoutBrowsePageLinks {
  /** @deprecated use Core_Layout_BrowsePageLinksQueryVariables instead */
  export type Variables = Core_Layout_BrowsePageLinksQueryVariables;
  /** @deprecated use Core_Layout_BrowsePageLinksQuery instead */
  export type Query = Core_Layout_BrowsePageLinksQuery;
}
export namespace BrowseListingsFields {
  /** @deprecated use BrowseListingsFieldsFragment instead */
  export type Fragment = BrowseListingsFieldsFragment;
}
export namespace CoreListingBrowsePageGrid {
  /** @deprecated use Core_Listing_BrowsePageGridQueryVariables instead */
  export type Variables = Core_Listing_BrowsePageGridQueryVariables;
  /** @deprecated use Core_Listing_BrowsePageGridQuery instead */
  export type Query = Core_Listing_BrowsePageGridQuery;
}
export namespace BumpRecommendationsAttributes {
  /** @deprecated use BumpRecommendationsAttributesFragment instead */
  export type Fragment = BumpRecommendationsAttributesFragment;
}
export namespace BumpRecommendationsFragment {
  /** @deprecated use BumpRecommendationsFragmentFragment instead */
  export type Fragment = BumpRecommendationsFragmentFragment;
}
export namespace CoreBumpRecommendationListingCard {
  /** @deprecated use CoreBumpRecommendationListingCardFragment instead */
  export type Fragment = CoreBumpRecommendationListingCardFragment;
}
export namespace CoreBumpRecommendationListing {
  /** @deprecated use Core_Bump_Recommendation_ListingQueryVariables instead */
  export type Variables = Core_Bump_Recommendation_ListingQueryVariables;
  /** @deprecated use Core_Bump_Recommendation_ListingQuery instead */
  export type Query = Core_Bump_Recommendation_ListingQuery;
}
export namespace CartBundleFreeShippingStatusesFields {
  /** @deprecated use CartBundleFreeShippingStatusesFieldsFragment instead */
  export type Fragment = CartBundleFreeShippingStatusesFieldsFragment;
}
export namespace CartBundleItemListFields {
  /** @deprecated use CartBundleItemListFieldsFragment instead */
  export type Fragment = CartBundleItemListFieldsFragment;
}
export namespace CartBundleOrderSummaryFields {
  /** @deprecated use CartBundleOrderSummaryFieldsFragment instead */
  export type Fragment = CartBundleOrderSummaryFieldsFragment;
}
export namespace CartBundlePaymentMethodSelectorFields {
  /** @deprecated use CartBundlePaymentMethodSelectorFieldsFragment instead */
  export type Fragment = CartBundlePaymentMethodSelectorFieldsFragment;
}
export namespace CartBundleRowFields {
  /** @deprecated use CartBundleRowFieldsFragment instead */
  export type Fragment = CartBundleRowFieldsFragment;
}
export namespace CartBundleTitleFields {
  /** @deprecated use CartBundleTitleFieldsFragment instead */
  export type Fragment = CartBundleTitleFieldsFragment;
}
export namespace CartCombineAndSaveRowFields {
  /** @deprecated use CartCombineAndSaveRowFieldsFragment instead */
  export type Fragment = CartCombineAndSaveRowFieldsFragment;
}
export namespace CartGooglePayButtonFields {
  /** @deprecated use CartGooglePayButtonFieldsFragment instead */
  export type Fragment = CartGooglePayButtonFieldsFragment;
}
export namespace CoreCheckoutStartCartGooglePay {
  /** @deprecated use Core_Checkout_StartCartGooglePayMutationVariables instead */
  export type Variables = Core_Checkout_StartCartGooglePayMutationVariables;
  /** @deprecated use Core_Checkout_StartCartGooglePayMutation instead */
  export type Mutation = Core_Checkout_StartCartGooglePayMutation;
}
export namespace CartHoistedExpressPayFields {
  /** @deprecated use CartHoistedExpressPayFieldsFragment instead */
  export type Fragment = CartHoistedExpressPayFieldsFragment;
}
export namespace CartItemBuyNowButtonFields {
  /** @deprecated use CartItemBuyNowButtonFieldsFragment instead */
  export type Fragment = CartItemBuyNowButtonFieldsFragment;
}
export namespace CartItemAvailableProtectionPlans {
  /** @deprecated use CartItemAvailableProtectionPlansFragment instead */
  export type Fragment = CartItemAvailableProtectionPlansFragment;
}
export namespace CartItemRowProtectionPlanFields {
  /** @deprecated use CartItemRowProtectionPlanFieldsFragment instead */
  export type Fragment = CartItemRowProtectionPlanFieldsFragment;
}
export namespace CoreCartItemUpdateProtectionPlan {
  /** @deprecated use Core_CartItemUpdateProtectionPlanMutationVariables instead */
  export type Variables = Core_CartItemUpdateProtectionPlanMutationVariables;
  /** @deprecated use Core_CartItemUpdateProtectionPlanMutation instead */
  export type Mutation = Core_CartItemUpdateProtectionPlanMutation;
}
export namespace CoreCartItemDeleteProtectionPlan {
  /** @deprecated use Core_CartItemDeleteProtectionPlanMutationVariables instead */
  export type Variables = Core_CartItemDeleteProtectionPlanMutationVariables;
  /** @deprecated use Core_CartItemDeleteProtectionPlanMutation instead */
  export type Mutation = Core_CartItemDeleteProtectionPlanMutation;
}
export namespace CoreAvailableCartItemProtectionPlans {
  /** @deprecated use Core_AvailableCartItemProtectionPlansQueryVariables instead */
  export type Variables = Core_AvailableCartItemProtectionPlansQueryVariables;
  /** @deprecated use Core_AvailableCartItemProtectionPlansQuery instead */
  export type Query = Core_AvailableCartItemProtectionPlansQuery;
}
export namespace CartItemQuantitySelectFields {
  /** @deprecated use CartItemQuantitySelectFieldsFragment instead */
  export type Fragment = CartItemQuantitySelectFieldsFragment;
}
export namespace CoreCartUpdateItemQuantity {
  /** @deprecated use Core_Cart_UpdateItemQuantityMutationVariables instead */
  export type Variables = Core_Cart_UpdateItemQuantityMutationVariables;
  /** @deprecated use Core_Cart_UpdateItemQuantityMutation instead */
  export type Mutation = Core_Cart_UpdateItemQuantityMutation;
}
export namespace CartItemRowFields {
  /** @deprecated use CartItemRowFieldsFragment instead */
  export type Fragment = CartItemRowFieldsFragment;
}
export namespace CartItemRowPricingFields {
  /** @deprecated use CartItemRowPricingFieldsFragment instead */
  export type Fragment = CartItemRowPricingFieldsFragment;
}
export namespace CartLayoutMeFields {
  /** @deprecated use CartLayoutMeFieldsFragment instead */
  export type Fragment = CartLayoutMeFieldsFragment;
}
export namespace CoreCartLayout {
  /** @deprecated use Core_Cart_LayoutQueryVariables instead */
  export type Variables = Core_Cart_LayoutQueryVariables;
  /** @deprecated use Core_Cart_LayoutQuery instead */
  export type Query = Core_Cart_LayoutQuery;
}
export namespace CartMoveItemToWatchListFields {
  /** @deprecated use CartMoveItemToWatchListFieldsFragment instead */
  export type Fragment = CartMoveItemToWatchListFieldsFragment;
}
export namespace CoreCartMoveItemToWatchList {
  /** @deprecated use Core_Cart_MoveItemToWatchListMutationVariables instead */
  export type Variables = Core_Cart_MoveItemToWatchListMutationVariables;
  /** @deprecated use Core_Cart_MoveItemToWatchListMutation instead */
  export type Mutation = Core_Cart_MoveItemToWatchListMutation;
}
export namespace CartRemoveItemFields {
  /** @deprecated use CartRemoveItemFieldsFragment instead */
  export type Fragment = CartRemoveItemFieldsFragment;
}
export namespace CoreCartRemoveItem {
  /** @deprecated use Core_Cart_RemoveItemMutationVariables instead */
  export type Variables = Core_Cart_RemoveItemMutationVariables;
  /** @deprecated use Core_Cart_RemoveItemMutation instead */
  export type Mutation = Core_Cart_RemoveItemMutation;
}
export namespace CoreCartStartCheckout {
  /** @deprecated use Core_Cart_StartCheckoutMutationVariables instead */
  export type Variables = Core_Cart_StartCheckoutMutationVariables;
  /** @deprecated use Core_Cart_StartCheckoutMutation instead */
  export type Mutation = Core_Cart_StartCheckoutMutation;
}
export namespace CartSignals {
  /** @deprecated use Cart_SignalsQueryVariables instead */
  export type Variables = Cart_SignalsQueryVariables;
  /** @deprecated use Cart_SignalsQuery instead */
  export type Query = Cart_SignalsQuery;
}
export namespace CoreFormSelectCategories {
  /** @deprecated use Core_Form_Select_CategoriesQueryVariables instead */
  export type Variables = Core_Form_Select_CategoriesQueryVariables;
  /** @deprecated use Core_Form_Select_CategoriesQuery instead */
  export type Query = Core_Form_Select_CategoriesQuery;
}
export namespace TopProductsCategory {
  /** @deprecated use TopProductsCategoryFragment instead */
  export type Fragment = TopProductsCategoryFragment;
}
export namespace TopProductsCSP {
  /** @deprecated use TopProductsCSPFragment instead */
  export type Fragment = TopProductsCSPFragment;
}
export namespace CoreCategoryTopProducts {
  /** @deprecated use Core_Category_TopProductsQueryVariables instead */
  export type Variables = Core_Category_TopProductsQueryVariables;
  /** @deprecated use Core_Category_TopProductsQuery instead */
  export type Query = Core_Category_TopProductsQuery;
}
export namespace CoreCheckoutBuyerCouponList {
  /** @deprecated use Core_Checkout_BuyerCouponListQueryVariables instead */
  export type Variables = Core_Checkout_BuyerCouponListQueryVariables;
  /** @deprecated use Core_Checkout_BuyerCouponListQuery instead */
  export type Query = Core_Checkout_BuyerCouponListQuery;
}
export namespace CheckoutAffirmBillingFormFields {
  /** @deprecated use CheckoutAffirmBillingFormFieldsFragment instead */
  export type Fragment = CheckoutAffirmBillingFormFieldsFragment;
}
export namespace CoreCheckoutAuthorizationLayout {
  /** @deprecated use Core_Checkout_AuthorizationLayoutQueryVariables instead */
  export type Variables = Core_Checkout_AuthorizationLayoutQueryVariables;
  /** @deprecated use Core_Checkout_AuthorizationLayoutQuery instead */
  export type Query = Core_Checkout_AuthorizationLayoutQuery;
}
export namespace CheckoutContinueButtonFields {
  /** @deprecated use CheckoutContinueButtonFieldsFragment instead */
  export type Fragment = CheckoutContinueButtonFieldsFragment;
}
export namespace CheckoutExistingShippingAddressFormFields {
  /** @deprecated use CheckoutExistingShippingAddressFormFieldsFragment instead */
  export type Fragment = CheckoutExistingShippingAddressFormFieldsFragment;
}
export namespace CoreCheckoutSelectShippingAddress {
  /** @deprecated use Core_Checkout_SelectShippingAddressMutationVariables instead */
  export type Variables = Core_Checkout_SelectShippingAddressMutationVariables;
  /** @deprecated use Core_Checkout_SelectShippingAddressMutation instead */
  export type Mutation = Core_Checkout_SelectShippingAddressMutation;
}
export namespace CheckoutGooglePayContinueButtonFields {
  /** @deprecated use CheckoutGooglePayContinueButtonFieldsFragment instead */
  export type Fragment = CheckoutGooglePayContinueButtonFieldsFragment;
}
export namespace CheckoutGuestContactInfoSummaryFields {
  /** @deprecated use CheckoutGuestContactInfoSummaryFieldsFragment instead */
  export type Fragment = CheckoutGuestContactInfoSummaryFieldsFragment;
}
export namespace CoreCheckoutLayout {
  /** @deprecated use Core_Checkout_LayoutQueryVariables instead */
  export type Variables = Core_Checkout_LayoutQueryVariables;
  /** @deprecated use Core_Checkout_LayoutQuery instead */
  export type Query = Core_Checkout_LayoutQuery;
}
export namespace CheckoutNewShippingAddressFormFields {
  /** @deprecated use CheckoutNewShippingAddressFormFieldsFragment instead */
  export type Fragment = CheckoutNewShippingAddressFormFieldsFragment;
}
export namespace CheckoutOrderQuantitySelectorFields {
  /** @deprecated use CheckoutOrderQuantitySelectorFieldsFragment instead */
  export type Fragment = CheckoutOrderQuantitySelectorFieldsFragment;
}
export namespace CheckoutOrderShippingSelectorFields {
  /** @deprecated use CheckoutOrderShippingSelectorFieldsFragment instead */
  export type Fragment = CheckoutOrderShippingSelectorFieldsFragment;
}
export namespace CheckoutOrderSummaryFields {
  /** @deprecated use CheckoutOrderSummaryFieldsFragment instead */
  export type Fragment = CheckoutOrderSummaryFieldsFragment;
}
export namespace CoreCheckoutUpdateOrderQuantity {
  /** @deprecated use Core_Checkout_UpdateOrderQuantityMutationVariables instead */
  export type Variables = Core_Checkout_UpdateOrderQuantityMutationVariables;
  /** @deprecated use Core_Checkout_UpdateOrderQuantityMutation instead */
  export type Mutation = Core_Checkout_UpdateOrderQuantityMutation;
}
export namespace CoreCheckoutSelectOrderShippingMethod {
  /** @deprecated use Core_Checkout_SelectOrderShippingMethodMutationVariables instead */
  export type Variables =
    Core_Checkout_SelectOrderShippingMethodMutationVariables;
  /** @deprecated use Core_Checkout_SelectOrderShippingMethodMutation instead */
  export type Mutation = Core_Checkout_SelectOrderShippingMethodMutation;
}
export namespace CheckoutOrderSummaryImageFields {
  /** @deprecated use CheckoutOrderSummaryImageFieldsFragment instead */
  export type Fragment = CheckoutOrderSummaryImageFieldsFragment;
}
export namespace CheckoutOrderSummaryLineItemsFields {
  /** @deprecated use CheckoutOrderSummaryLineItemsFieldsFragment instead */
  export type Fragment = CheckoutOrderSummaryLineItemsFieldsFragment;
}
export namespace CheckoutOrderSummaryOrderItemFields {
  /** @deprecated use CheckoutOrderSummaryOrderItemFieldsFragment instead */
  export type Fragment = CheckoutOrderSummaryOrderItemFieldsFragment;
}
export namespace CheckoutOrderSummaryOrderItemShippingTotalFields {
  /** @deprecated use CheckoutOrderSummaryOrderItemShippingTotalFieldsFragment instead */
  export type Fragment =
    CheckoutOrderSummaryOrderItemShippingTotalFieldsFragment;
}
export namespace CheckoutOrderSummaryOrderItemSubtotalFields {
  /** @deprecated use CheckoutOrderSummaryOrderItemSubtotalFieldsFragment instead */
  export type Fragment = CheckoutOrderSummaryOrderItemSubtotalFieldsFragment;
}
export namespace CoreCheckoutUpdateStandalonePaypalPayOrder {
  /** @deprecated use Core_Checkout_UpdateStandalonePaypalPayOrderMutationVariables instead */
  export type Variables =
    Core_Checkout_UpdateStandalonePaypalPayOrderMutationVariables;
  /** @deprecated use Core_Checkout_UpdateStandalonePaypalPayOrderMutation instead */
  export type Mutation = Core_Checkout_UpdateStandalonePaypalPayOrderMutation;
}
export namespace CheckoutPayPalPlaceOrderButtonFields {
  /** @deprecated use CheckoutPayPalPlaceOrderButtonFieldsFragment instead */
  export type Fragment = CheckoutPayPalPlaceOrderButtonFieldsFragment;
}
export namespace CoreCheckoutFinalizePayPalPayment {
  /** @deprecated use Core_Checkout_FinalizePayPalPaymentMutationVariables instead */
  export type Variables = Core_Checkout_FinalizePayPalPaymentMutationVariables;
  /** @deprecated use Core_Checkout_FinalizePayPalPaymentMutation instead */
  export type Mutation = Core_Checkout_FinalizePayPalPaymentMutation;
}
export namespace CoreCheckoutPaymentInProgressLayout {
  /** @deprecated use Core_Checkout_PaymentInProgressLayoutQueryVariables instead */
  export type Variables = Core_Checkout_PaymentInProgressLayoutQueryVariables;
  /** @deprecated use Core_Checkout_PaymentInProgressLayoutQuery instead */
  export type Query = Core_Checkout_PaymentInProgressLayoutQuery;
}
export namespace SelectCheckoutPaymentMethodFields {
  /** @deprecated use SelectCheckoutPaymentMethodFieldsFragment instead */
  export type Fragment = SelectCheckoutPaymentMethodFieldsFragment;
}
export namespace CheckoutPaymentMethodFields {
  /** @deprecated use CheckoutPaymentMethodFieldsFragment instead */
  export type Fragment = CheckoutPaymentMethodFieldsFragment;
}
export namespace CoreCheckoutSelectCheckoutPaymentMethod {
  /** @deprecated use Core_Checkout_SelectCheckoutPaymentMethodMutationVariables instead */
  export type Variables =
    Core_Checkout_SelectCheckoutPaymentMethodMutationVariables;
  /** @deprecated use Core_Checkout_SelectCheckoutPaymentMethodMutation instead */
  export type Mutation = Core_Checkout_SelectCheckoutPaymentMethodMutation;
}
export namespace CheckoutPayPalContinueButtonFields {
  /** @deprecated use CheckoutPayPalContinueButtonFieldsFragment instead */
  export type Fragment = CheckoutPayPalContinueButtonFieldsFragment;
}
export namespace CoreCheckoutCreatePaypalOrder {
  /** @deprecated use Core_Checkout_CreatePaypalOrderMutationVariables instead */
  export type Variables = Core_Checkout_CreatePaypalOrderMutationVariables;
  /** @deprecated use Core_Checkout_CreatePaypalOrderMutation instead */
  export type Mutation = Core_Checkout_CreatePaypalOrderMutation;
}
export namespace CheckoutPaypalPayLaterButtonFields {
  /** @deprecated use CheckoutPaypalPayLaterButtonFieldsFragment instead */
  export type Fragment = CheckoutPaypalPayLaterButtonFieldsFragment;
}
export namespace CoreCheckoutCreatePaypalPayLaterOrder {
  /** @deprecated use Core_Checkout_CreatePaypalPayLaterOrderMutationVariables instead */
  export type Variables =
    Core_Checkout_CreatePaypalPayLaterOrderMutationVariables;
  /** @deprecated use Core_Checkout_CreatePaypalPayLaterOrderMutation instead */
  export type Mutation = Core_Checkout_CreatePaypalPayLaterOrderMutation;
}
export namespace RedirectReturnCheckoutFields {
  /** @deprecated use RedirectReturnCheckoutFieldsFragment instead */
  export type Fragment = RedirectReturnCheckoutFieldsFragment;
}
export namespace CoreCheckoutRedirectReturnLayout {
  /** @deprecated use Core_Checkout_RedirectReturnLayoutQueryVariables instead */
  export type Variables = Core_Checkout_RedirectReturnLayoutQueryVariables;
  /** @deprecated use Core_Checkout_RedirectReturnLayoutQuery instead */
  export type Query = Core_Checkout_RedirectReturnLayoutQuery;
}
export namespace CoreCheckoutFinalizeRedirectPaymentCheckout {
  /** @deprecated use Core_Checkout_FinalizeRedirectPaymentCheckoutMutationVariables instead */
  export type Variables =
    Core_Checkout_FinalizeRedirectPaymentCheckoutMutationVariables;
  /** @deprecated use Core_Checkout_FinalizeRedirectPaymentCheckoutMutation instead */
  export type Mutation = Core_Checkout_FinalizeRedirectPaymentCheckoutMutation;
}
export namespace CoreCheckoutFinalizeAffirmCheckoutPayment {
  /** @deprecated use Core_Checkout_FinalizeAffirmCheckoutPaymentMutationVariables instead */
  export type Variables =
    Core_Checkout_FinalizeAffirmCheckoutPaymentMutationVariables;
  /** @deprecated use Core_Checkout_FinalizeAffirmCheckoutPaymentMutation instead */
  export type Mutation = Core_Checkout_FinalizeAffirmCheckoutPaymentMutation;
}
export namespace CoreCheckoutFinalizeRedirectAuthPayment {
  /** @deprecated use Core_Checkout_FinalizeRedirectAuthPaymentMutationVariables instead */
  export type Variables =
    Core_Checkout_FinalizeRedirectAuthPaymentMutationVariables;
  /** @deprecated use Core_Checkout_FinalizeRedirectAuthPaymentMutation instead */
  export type Mutation = Core_Checkout_FinalizeRedirectAuthPaymentMutation;
}
export namespace CheckoutStandalonePayPalPaymentOptionsFields {
  /** @deprecated use CheckoutStandalonePayPalPaymentOptionsFieldsFragment instead */
  export type Fragment = CheckoutStandalonePayPalPaymentOptionsFieldsFragment;
}
export namespace CoreCheckoutCreateStandalonePaypalPayOrder {
  /** @deprecated use Core_Checkout_CreateStandalonePaypalPayOrderMutationVariables instead */
  export type Variables =
    Core_Checkout_CreateStandalonePaypalPayOrderMutationVariables;
  /** @deprecated use Core_Checkout_CreateStandalonePaypalPayOrderMutation instead */
  export type Mutation = Core_Checkout_CreateStandalonePaypalPayOrderMutation;
}
export namespace CoreCheckoutFinalizeStandalonePaypalPayOrder {
  /** @deprecated use Core_Checkout_FinalizeStandalonePaypalPayOrderMutationVariables instead */
  export type Variables =
    Core_Checkout_FinalizeStandalonePaypalPayOrderMutationVariables;
  /** @deprecated use Core_Checkout_FinalizeStandalonePaypalPayOrderMutation instead */
  export type Mutation = Core_Checkout_FinalizeStandalonePaypalPayOrderMutation;
}
export namespace CheckoutThirdPartyAdsFields {
  /** @deprecated use CheckoutThirdPartyAdsFieldsFragment instead */
  export type Fragment = CheckoutThirdPartyAdsFieldsFragment;
}
export namespace CoreCheckoutStartPaypalCheckout {
  /** @deprecated use Core_Checkout_StartPaypalCheckoutMutationVariables instead */
  export type Variables = Core_Checkout_StartPaypalCheckoutMutationVariables;
  /** @deprecated use Core_Checkout_StartPaypalCheckoutMutation instead */
  export type Mutation = Core_Checkout_StartPaypalCheckoutMutation;
}
export namespace ListingGooglePayButtonFields {
  /** @deprecated use ListingGooglePayButtonFieldsFragment instead */
  export type Fragment = ListingGooglePayButtonFieldsFragment;
}
export namespace CoreCheckoutStartListingGooglePay {
  /** @deprecated use Core_Checkout_StartListingGooglePayMutationVariables instead */
  export type Variables = Core_Checkout_StartListingGooglePayMutationVariables;
  /** @deprecated use Core_Checkout_StartListingGooglePayMutation instead */
  export type Mutation = Core_Checkout_StartListingGooglePayMutation;
}
export namespace PaymentMethodLabelFields {
  /** @deprecated use PaymentMethodLabelFieldsFragment instead */
  export type Fragment = PaymentMethodLabelFieldsFragment;
}
export namespace AffirmCheckoutBuilderFields {
  /** @deprecated use AffirmCheckoutBuilderFieldsFragment instead */
  export type Fragment = AffirmCheckoutBuilderFieldsFragment;
}
export namespace CheckoutAvailablePaymentMethodsHookFields {
  /** @deprecated use CheckoutAvailablePaymentMethodsHookFieldsFragment instead */
  export type Fragment = CheckoutAvailablePaymentMethodsHookFieldsFragment;
}
export namespace CheckoutTrackingFields {
  /** @deprecated use CheckoutTrackingFieldsFragment instead */
  export type Fragment = CheckoutTrackingFieldsFragment;
}
export namespace GooglePayManagerFields {
  /** @deprecated use GooglePayManagerFieldsFragment instead */
  export type Fragment = GooglePayManagerFieldsFragment;
}
export namespace GooglePayFinalizeActionFields {
  /** @deprecated use GooglePayFinalizeActionFieldsFragment instead */
  export type Fragment = GooglePayFinalizeActionFieldsFragment;
}
export namespace CheckoutAffirmPaymentFormHookFields {
  /** @deprecated use CheckoutAffirmPaymentFormHookFieldsFragment instead */
  export type Fragment = CheckoutAffirmPaymentFormHookFieldsFragment;
}
export namespace CoreCheckoutUpdateAffirmBillingAddress {
  /** @deprecated use Core_Checkout_UpdateAffirmBillingAddressMutationVariables instead */
  export type Variables =
    Core_Checkout_UpdateAffirmBillingAddressMutationVariables;
  /** @deprecated use Core_Checkout_UpdateAffirmBillingAddressMutation instead */
  export type Mutation = Core_Checkout_UpdateAffirmBillingAddressMutation;
}
export namespace CheckoutApplePayHookFields {
  /** @deprecated use CheckoutApplePayHookFieldsFragment instead */
  export type Fragment = CheckoutApplePayHookFieldsFragment;
}
export namespace CheckoutCardPaymentHookCheckoutFields {
  /** @deprecated use CheckoutCardPaymentHookCheckoutFieldsFragment instead */
  export type Fragment = CheckoutCardPaymentHookCheckoutFieldsFragment;
}
export namespace CheckoutCardPaymentHookMeFields {
  /** @deprecated use CheckoutCardPaymentHookMeFieldsFragment instead */
  export type Fragment = CheckoutCardPaymentHookMeFieldsFragment;
}
export namespace CoreCheckoutFinalizeCardPayment {
  /** @deprecated use Core_Checkout_FinalizeCardPaymentMutationVariables instead */
  export type Variables = Core_Checkout_FinalizeCardPaymentMutationVariables;
  /** @deprecated use Core_Checkout_FinalizeCardPaymentMutation instead */
  export type Mutation = Core_Checkout_FinalizeCardPaymentMutation;
}
export namespace CheckoutContinueButtonStateFields {
  /** @deprecated use CheckoutContinueButtonStateFieldsFragment instead */
  export type Fragment = CheckoutContinueButtonStateFieldsFragment;
}
export namespace CheckoutDisplayStepHookFields {
  /** @deprecated use CheckoutDisplayStepHookFieldsFragment instead */
  export type Fragment = CheckoutDisplayStepHookFieldsFragment;
}
export namespace CoreCheckoutUpdateShippingInfo {
  /** @deprecated use Core_Checkout_UpdateShippingInfoMutationVariables instead */
  export type Variables = Core_Checkout_UpdateShippingInfoMutationVariables;
  /** @deprecated use Core_Checkout_UpdateShippingInfoMutation instead */
  export type Mutation = Core_Checkout_UpdateShippingInfoMutation;
}
export namespace CoreCheckoutUpdateGooglePayShippingEstimate {
  /** @deprecated use Core_Checkout_UpdateGooglePayShippingEstimateMutationVariables instead */
  export type Variables =
    Core_Checkout_UpdateGooglePayShippingEstimateMutationVariables;
  /** @deprecated use Core_Checkout_UpdateGooglePayShippingEstimateMutation instead */
  export type Mutation = Core_Checkout_UpdateGooglePayShippingEstimateMutation;
}
export namespace CoreCheckoutFinalizeGooglePayCheckout {
  /** @deprecated use Core_Checkout_FinalizeGooglePayCheckoutMutationVariables instead */
  export type Variables =
    Core_Checkout_FinalizeGooglePayCheckoutMutationVariables;
  /** @deprecated use Core_Checkout_FinalizeGooglePayCheckoutMutation instead */
  export type Mutation = Core_Checkout_FinalizeGooglePayCheckoutMutation;
}
export namespace PaymentMethodSelectorVisibilityHookFields {
  /** @deprecated use PaymentMethodSelectorVisibilityHookFieldsFragment instead */
  export type Fragment = PaymentMethodSelectorVisibilityHookFieldsFragment;
}
export namespace CoreCheckoutProcessApprovedPaypalOrder {
  /** @deprecated use Core_Checkout_ProcessApprovedPaypalOrderMutationVariables instead */
  export type Variables =
    Core_Checkout_ProcessApprovedPaypalOrderMutationVariables;
  /** @deprecated use Core_Checkout_ProcessApprovedPaypalOrderMutation instead */
  export type Mutation = Core_Checkout_ProcessApprovedPaypalOrderMutation;
}
export namespace CheckoutActionHookFields {
  /** @deprecated use CheckoutActionHookFieldsFragment instead */
  export type Fragment = CheckoutActionHookFieldsFragment;
}
export namespace CoreCheckoutFinalizeApplePayPayment {
  /** @deprecated use Core_Checkout_FinalizeApplePayPaymentMutationVariables instead */
  export type Variables =
    Core_Checkout_FinalizeApplePayPaymentMutationVariables;
  /** @deprecated use Core_Checkout_FinalizeApplePayPaymentMutation instead */
  export type Mutation = Core_Checkout_FinalizeApplePayPaymentMutation;
}
export namespace CheckoutApplePayLineItemFields {
  /** @deprecated use CheckoutApplePayLineItemFieldsFragment instead */
  export type Fragment = CheckoutApplePayLineItemFieldsFragment;
}
export namespace CheckoutDisplayCheckoutFields {
  /** @deprecated use CheckoutDisplayCheckoutFieldsFragment instead */
  export type Fragment = CheckoutDisplayCheckoutFieldsFragment;
}
export namespace CheckoutDisplayMeFields {
  /** @deprecated use CheckoutDisplayMeFieldsFragment instead */
  export type Fragment = CheckoutDisplayMeFieldsFragment;
}
export namespace CheckoutCreditCardWalletFields {
  /** @deprecated use CheckoutCreditCardWalletFieldsFragment instead */
  export type Fragment = CheckoutCreditCardWalletFieldsFragment;
}
export namespace SubmitCheckoutVerifyShopper {
  /** @deprecated use SubmitCheckoutVerifyShopperMutationVariables instead */
  export type Variables = SubmitCheckoutVerifyShopperMutationVariables;
  /** @deprecated use SubmitCheckoutVerifyShopperMutation instead */
  export type Mutation = SubmitCheckoutVerifyShopperMutation;
}
export namespace SubmitGuestCheckoutVerifyShopper {
  /** @deprecated use SubmitGuestCheckoutVerifyShopperMutationVariables instead */
  export type Variables = SubmitGuestCheckoutVerifyShopperMutationVariables;
  /** @deprecated use SubmitGuestCheckoutVerifyShopperMutation instead */
  export type Mutation = SubmitGuestCheckoutVerifyShopperMutation;
}
export namespace CoreCheckoutSelectCreditCard {
  /** @deprecated use Core_Checkout_SelectCreditCardMutationVariables instead */
  export type Variables = Core_Checkout_SelectCreditCardMutationVariables;
  /** @deprecated use Core_Checkout_SelectCreditCardMutation instead */
  export type Mutation = Core_Checkout_SelectCreditCardMutation;
}
export namespace CheckoutStepErrorMessageFields {
  /** @deprecated use CheckoutStepErrorMessageFieldsFragment instead */
  export type Fragment = CheckoutStepErrorMessageFieldsFragment;
}
export namespace CompareOtherSellersLinkData {
  /** @deprecated use CompareOtherSellersLinkDataFragment instead */
  export type Fragment = CompareOtherSellersLinkDataFragment;
}
export namespace CreditCardEditFormFields {
  /** @deprecated use CreditCardEditFormFieldsFragment instead */
  export type Fragment = CreditCardEditFormFieldsFragment;
}
export namespace CoreCreditCardWalletUpdateMyCreditCard {
  /** @deprecated use Core_CreditCardWallet_UpdateMyCreditCardMutationVariables instead */
  export type Variables =
    Core_CreditCardWallet_UpdateMyCreditCardMutationVariables;
  /** @deprecated use Core_CreditCardWallet_UpdateMyCreditCardMutation instead */
  export type Mutation = Core_CreditCardWallet_UpdateMyCreditCardMutation;
}
export namespace CreditCardWalletCreateFormFields {
  /** @deprecated use CreditCardWalletCreateFormFieldsFragment instead */
  export type Fragment = CreditCardWalletCreateFormFieldsFragment;
}
export namespace CoreCreditCardWalletCreateMyCreditCard {
  /** @deprecated use Core_CreditCardWallet_CreateMyCreditCardMutationVariables instead */
  export type Variables =
    Core_CreditCardWallet_CreateMyCreditCardMutationVariables;
  /** @deprecated use Core_CreditCardWallet_CreateMyCreditCardMutation instead */
  export type Mutation = Core_CreditCardWallet_CreateMyCreditCardMutation;
}
export namespace CoreCreditCardWalletCreateFormLayout {
  /** @deprecated use Core_CreditCardWallet_CreateFormLayoutQueryVariables instead */
  export type Variables = Core_CreditCardWallet_CreateFormLayoutQueryVariables;
  /** @deprecated use Core_CreditCardWallet_CreateFormLayoutQuery instead */
  export type Query = Core_CreditCardWallet_CreateFormLayoutQuery;
}
export namespace CoreCreditCardWalletEditFormLayout {
  /** @deprecated use Core_CreditCardWallet_EditFormLayoutQueryVariables instead */
  export type Variables = Core_CreditCardWallet_EditFormLayoutQueryVariables;
  /** @deprecated use Core_CreditCardWallet_EditFormLayoutQuery instead */
  export type Query = Core_CreditCardWallet_EditFormLayoutQuery;
}
export namespace CreditCardWalletLayoutFields {
  /** @deprecated use CreditCardWalletLayoutFieldsFragment instead */
  export type Fragment = CreditCardWalletLayoutFieldsFragment;
}
export namespace CoreCreditCardWalletIndex {
  /** @deprecated use Core_CreditCardWallet_IndexQueryVariables instead */
  export type Variables = Core_CreditCardWallet_IndexQueryVariables;
  /** @deprecated use Core_CreditCardWallet_IndexQuery instead */
  export type Query = Core_CreditCardWallet_IndexQuery;
}
export namespace CreditCardWalletRowFields {
  /** @deprecated use CreditCardWalletRowFieldsFragment instead */
  export type Fragment = CreditCardWalletRowFieldsFragment;
}
export namespace CreditCardWalletRowSummaryFields {
  /** @deprecated use CreditCardWalletRowSummaryFieldsFragment instead */
  export type Fragment = CreditCardWalletRowSummaryFieldsFragment;
}
export namespace FeaturedListingWithPrice {
  /** @deprecated use FeaturedListingWithPriceFragment instead */
  export type Fragment = FeaturedListingWithPriceFragment;
}
export namespace FeaturedListingCSP {
  /** @deprecated use FeaturedListingCSPFragment instead */
  export type Fragment = FeaturedListingCSPFragment;
}
export namespace CspFeaturedListing {
  /** @deprecated use Csp_Featured_ListingQueryVariables instead */
  export type Variables = Csp_Featured_ListingQueryVariables;
  /** @deprecated use Csp_Featured_ListingQuery instead */
  export type Query = Csp_Featured_ListingQuery;
}
export namespace CSP2HeaderData {
  /** @deprecated use CSP2HeaderDataFragment instead */
  export type Fragment = CSP2HeaderDataFragment;
}
export namespace CoreCspHeader {
  /** @deprecated use Core_Csp_HeaderQueryVariables instead */
  export type Variables = Core_Csp_HeaderQueryVariables;
  /** @deprecated use Core_Csp_HeaderQuery instead */
  export type Query = Core_Csp_HeaderQuery;
}
export namespace CSPReviewJumplinksData {
  /** @deprecated use CSPReviewJumplinksDataFragment instead */
  export type Fragment = CSPReviewJumplinksDataFragment;
}
export namespace ListingData {
  /** @deprecated use ListingDataFragment instead */
  export type Fragment = ListingDataFragment;
}
export namespace CspFiltersAndListingsQuery {
  /** @deprecated use Csp_Filters_And_ListingsQueryQueryVariables instead */
  export type Variables = Csp_Filters_And_ListingsQueryQueryVariables;
  /** @deprecated use Csp_Filters_And_ListingsQueryQuery instead */
  export type Query = Csp_Filters_And_ListingsQueryQuery;
}
export namespace CoreCspLayoutQuery {
  /** @deprecated use Core_Csp_LayoutQueryQueryVariables instead */
  export type Variables = Core_Csp_LayoutQueryQueryVariables;
  /** @deprecated use Core_Csp_LayoutQueryQuery instead */
  export type Query = Core_Csp_LayoutQueryQuery;
}
export namespace CoreDashboardUpdateShopEuAddress {
  /** @deprecated use Core_Dashboard_UpdateShopEuAddressMutationVariables instead */
  export type Variables = Core_Dashboard_UpdateShopEuAddressMutationVariables;
  /** @deprecated use Core_Dashboard_UpdateShopEuAddressMutation instead */
  export type Mutation = Core_Dashboard_UpdateShopEuAddressMutation;
}
export namespace DeleteEuAddress {
  /** @deprecated use DeleteEuAddressMutationVariables instead */
  export type Variables = DeleteEuAddressMutationVariables;
  /** @deprecated use DeleteEuAddressMutation instead */
  export type Mutation = DeleteEuAddressMutation;
}
export namespace CoreDashboardEuOdrAddress {
  /** @deprecated use Core_Dashboard_EuOdrAddressQueryVariables instead */
  export type Variables = Core_Dashboard_EuOdrAddressQueryVariables;
  /** @deprecated use Core_Dashboard_EuOdrAddressQuery instead */
  export type Query = Core_Dashboard_EuOdrAddressQuery;
}
export namespace MyShopTaxPolicyData {
  /** @deprecated use MyShopTaxPolicyDataQueryVariables instead */
  export type Variables = MyShopTaxPolicyDataQueryVariables;
  /** @deprecated use MyShopTaxPolicyDataQuery instead */
  export type Query = MyShopTaxPolicyDataQuery;
}
export namespace CoreListingCombineAndSaveRow {
  /** @deprecated use Core_Listing_CombineAndSaveRowQueryVariables instead */
  export type Variables = Core_Listing_CombineAndSaveRowQueryVariables;
  /** @deprecated use Core_Listing_CombineAndSaveRowQuery instead */
  export type Query = Core_Listing_CombineAndSaveRowQuery;
}
export namespace CoreCartMyCouponList {
  /** @deprecated use Core_Cart_MyCouponListQueryVariables instead */
  export type Variables = Core_Cart_MyCouponListQueryVariables;
  /** @deprecated use Core_Cart_MyCouponListQuery instead */
  export type Query = Core_Cart_MyCouponListQuery;
}
export namespace CoreMarketplaceCspRow {
  /** @deprecated use Core_Marketplace_CspRowQueryVariables instead */
  export type Variables = Core_Marketplace_CspRowQueryVariables;
  /** @deprecated use Core_Marketplace_CspRowQuery instead */
  export type Query = Core_Marketplace_CspRowQuery;
}
export namespace CategoryUnderPriceQuery {
  /** @deprecated use Category_Under_Price_QueryQueryVariables instead */
  export type Variables = Category_Under_Price_QueryQueryVariables;
  /** @deprecated use Category_Under_Price_QueryQuery instead */
  export type Query = Category_Under_Price_QueryQuery;
}
export namespace CoreMosaicTilesHomepage {
  /** @deprecated use Core_MosaicTilesHomepageQueryVariables instead */
  export type Variables = Core_MosaicTilesHomepageQueryVariables;
  /** @deprecated use Core_MosaicTilesHomepageQuery instead */
  export type Query = Core_MosaicTilesHomepageQuery;
}
export namespace RecentSearchListingData {
  /** @deprecated use RecentSearchListingDataFragment instead */
  export type Fragment = RecentSearchListingDataFragment;
}
export namespace CoreHomepageRecentSearchesListingsSearch {
  /** @deprecated use Core_Homepage_RecentSearchesListingsSearchQueryVariables instead */
  export type Variables =
    Core_Homepage_RecentSearchesListingsSearchQueryVariables;
  /** @deprecated use Core_Homepage_RecentSearchesListingsSearchQuery instead */
  export type Query = Core_Homepage_RecentSearchesListingsSearchQuery;
}
export namespace JustListedForYouQuery {
  /** @deprecated use Just_Listed_For_You_QueryQueryVariables instead */
  export type Variables = Just_Listed_For_You_QueryQueryVariables;
  /** @deprecated use Just_Listed_For_You_QueryQuery instead */
  export type Query = Just_Listed_For_You_QueryQuery;
}
export namespace CoreHomepageDependencies {
  /** @deprecated use Core_HomepageDependenciesQueryVariables instead */
  export type Variables = Core_HomepageDependenciesQueryVariables;
  /** @deprecated use Core_HomepageDependenciesQuery instead */
  export type Query = Core_HomepageDependenciesQuery;
}
export namespace LoggedOutMParticleProfileQuery {
  /** @deprecated use LoggedOutMParticleProfileQueryQueryVariables instead */
  export type Variables = LoggedOutMParticleProfileQueryQueryVariables;
  /** @deprecated use LoggedOutMParticleProfileQueryQuery instead */
  export type Query = LoggedOutMParticleProfileQueryQuery;
}
export namespace RegionalListingsRowQuery {
  /** @deprecated use Regional_Listings_Row_QueryQueryVariables instead */
  export type Variables = Regional_Listings_Row_QueryQueryVariables;
  /** @deprecated use Regional_Listings_Row_QueryQuery instead */
  export type Query = Regional_Listings_Row_QueryQuery;
}
export namespace CoreReverbHeader {
  /** @deprecated use Core_ReverbHeaderQueryVariables instead */
  export type Variables = Core_ReverbHeaderQueryVariables;
  /** @deprecated use Core_ReverbHeaderQuery instead */
  export type Query = Core_ReverbHeaderQuery;
}
export namespace ReverbHeaderCartBadgeData {
  /** @deprecated use ReverbHeaderCartBadgeDataFragment instead */
  export type Fragment = ReverbHeaderCartBadgeDataFragment;
}
export namespace ReverbHeaderCartItemFields {
  /** @deprecated use ReverbHeaderCartItemFieldsFragment instead */
  export type Fragment = ReverbHeaderCartItemFieldsFragment;
}
export namespace CoreSaleHeader {
  /** @deprecated use Core_Sale_HeaderQueryVariables instead */
  export type Variables = Core_Sale_HeaderQueryVariables;
  /** @deprecated use Core_Sale_HeaderQuery instead */
  export type Query = Core_Sale_HeaderQuery;
}
export namespace CoreShippingLabelEditShipment {
  /** @deprecated use Core_ShippingLabel_EditShipmentQueryVariables instead */
  export type Variables = Core_ShippingLabel_EditShipmentQueryVariables;
  /** @deprecated use Core_ShippingLabel_EditShipmentQuery instead */
  export type Query = Core_ShippingLabel_EditShipmentQuery;
}
export namespace CoreShippingLabelEditShipmentForManualOrder {
  /** @deprecated use Core_ShippingLabel_EditShipmentForManualOrderQueryVariables instead */
  export type Variables =
    Core_ShippingLabel_EditShipmentForManualOrderQueryVariables;
  /** @deprecated use Core_ShippingLabel_EditShipmentForManualOrderQuery instead */
  export type Query = Core_ShippingLabel_EditShipmentForManualOrderQuery;
}
export namespace MultiOrderCustomsInfoQuery {
  /** @deprecated use MultiOrderCustomsInfoQueryQueryVariables instead */
  export type Variables = MultiOrderCustomsInfoQueryQueryVariables;
  /** @deprecated use MultiOrderCustomsInfoQueryQuery instead */
  export type Query = MultiOrderCustomsInfoQueryQuery;
}
export namespace ShippingLabelOrderData {
  /** @deprecated use ShippingLabelOrderDataFragment instead */
  export type Fragment = ShippingLabelOrderDataFragment;
}
export namespace ShippingLabelCountriesData {
  /** @deprecated use ShippingLabelCountriesDataFragment instead */
  export type Fragment = ShippingLabelCountriesDataFragment;
}
export namespace ShippingLabelListingData {
  /** @deprecated use ShippingLabelListingDataFragment instead */
  export type Fragment = ShippingLabelListingDataFragment;
}
export namespace CoreShippingLabelForm {
  /** @deprecated use Core_ShippingLabel_FormQueryVariables instead */
  export type Variables = Core_ShippingLabel_FormQueryVariables;
  /** @deprecated use Core_ShippingLabel_FormQuery instead */
  export type Query = Core_ShippingLabel_FormQuery;
}
export namespace CoreShippingLabelFormForManualOrder {
  /** @deprecated use Core_ShippingLabel_FormForManualOrderQueryVariables instead */
  export type Variables = Core_ShippingLabel_FormForManualOrderQueryVariables;
  /** @deprecated use Core_ShippingLabel_FormForManualOrderQuery instead */
  export type Query = Core_ShippingLabel_FormForManualOrderQuery;
}
export namespace ShippingProtection {
  /** @deprecated use ShippingProtectionMutationVariables instead */
  export type Variables = ShippingProtectionMutationVariables;
  /** @deprecated use ShippingProtectionMutation instead */
  export type Mutation = ShippingProtectionMutation;
}
export namespace CreateShipment {
  /** @deprecated use CreateShipmentMutationVariables instead */
  export type Variables = CreateShipmentMutationVariables;
  /** @deprecated use CreateShipmentMutation instead */
  export type Mutation = CreateShipmentMutation;
}
export namespace PurchaseShippingRate {
  /** @deprecated use PurchaseShippingRateMutationVariables instead */
  export type Variables = PurchaseShippingRateMutationVariables;
  /** @deprecated use PurchaseShippingRateMutation instead */
  export type Mutation = PurchaseShippingRateMutation;
}
export namespace CoreShippingLabelShipments {
  /** @deprecated use Core_ShippingLabel_ShipmentsQueryVariables instead */
  export type Variables = Core_ShippingLabel_ShipmentsQueryVariables;
  /** @deprecated use Core_ShippingLabel_ShipmentsQuery instead */
  export type Query = Core_ShippingLabel_ShipmentsQuery;
}
export namespace ShopContainerFragment {
  /** @deprecated use ShopContainerFragmentFragment instead */
  export type Fragment = ShopContainerFragmentFragment;
}
export namespace MyShopCampaignCouponFields {
  /** @deprecated use MyShopCampaignCouponFieldsFragment instead */
  export type Fragment = MyShopCampaignCouponFieldsFragment;
}
export namespace CoreShopListingsCount {
  /** @deprecated use Core_Shop_ListingsCountQueryVariables instead */
  export type Variables = Core_Shop_ListingsCountQueryVariables;
  /** @deprecated use Core_Shop_ListingsCountQuery instead */
  export type Query = Core_Shop_ListingsCountQuery;
}
export namespace CoreShopFindFavorite {
  /** @deprecated use Core_Shop_FindFavoriteQueryVariables instead */
  export type Variables = Core_Shop_FindFavoriteQueryVariables;
  /** @deprecated use Core_Shop_FindFavoriteQuery instead */
  export type Query = Core_Shop_FindFavoriteQuery;
}
export namespace CoreShopContainer {
  /** @deprecated use Core_Shop_ContainerQueryVariables instead */
  export type Variables = Core_Shop_ContainerQueryVariables;
  /** @deprecated use Core_Shop_ContainerQuery instead */
  export type Query = Core_Shop_ContainerQuery;
}
export namespace CoreShopFeedback {
  /** @deprecated use Core_Shop_FeedbackQueryVariables instead */
  export type Variables = Core_Shop_FeedbackQueryVariables;
  /** @deprecated use Core_Shop_FeedbackQuery instead */
  export type Query = Core_Shop_FeedbackQuery;
}
export namespace ShopHeaderFragment {
  /** @deprecated use ShopHeaderFragmentFragment instead */
  export type Fragment = ShopHeaderFragmentFragment;
}
export namespace CoreShopInfo {
  /** @deprecated use Core_Shop_InfoQueryVariables instead */
  export type Variables = Core_Shop_InfoQueryVariables;
  /** @deprecated use Core_Shop_InfoQuery instead */
  export type Query = Core_Shop_InfoQuery;
}
export namespace ShopMetadataFragment {
  /** @deprecated use ShopMetadataFragmentFragment instead */
  export type Fragment = ShopMetadataFragmentFragment;
}
export namespace ShopReturnPoliciesFragment {
  /** @deprecated use ShopReturnPoliciesFragmentFragment instead */
  export type Fragment = ShopReturnPoliciesFragmentFragment;
}
export namespace ShopTaxPoliciesData {
  /** @deprecated use ShopTaxPoliciesDataFragment instead */
  export type Fragment = ShopTaxPoliciesDataFragment;
}
export namespace ShopTabsFragment {
  /** @deprecated use ShopTabsFragmentFragment instead */
  export type Fragment = ShopTabsFragmentFragment;
}
export namespace CoreListingSimilarListingsGrid {
  /** @deprecated use Core_Listing_SimilarListingsGridQueryVariables instead */
  export type Variables = Core_Listing_SimilarListingsGridQueryVariables;
  /** @deprecated use Core_Listing_SimilarListingsGridQuery instead */
  export type Query = Core_Listing_SimilarListingsGridQuery;
}
export namespace SimilarListingsFields {
  /** @deprecated use SimilarListingsFieldsFragment instead */
  export type Fragment = SimilarListingsFieldsFragment;
}
export namespace CoreListingSimilarListings {
  /** @deprecated use Core_Listing_SimilarListingsQueryVariables instead */
  export type Variables = Core_Listing_SimilarListingsQueryVariables;
  /** @deprecated use Core_Listing_SimilarListingsQuery instead */
  export type Query = Core_Listing_SimilarListingsQuery;
}
export namespace CoreSiteBannerHero {
  /** @deprecated use Core_SiteBannerHeroQueryVariables instead */
  export type Variables = Core_SiteBannerHeroQueryVariables;
  /** @deprecated use Core_SiteBannerHeroQuery instead */
  export type Query = Core_SiteBannerHeroQuery;
}
export namespace EUODRAddress {
  /** @deprecated use EUODRAddressFragment instead */
  export type Fragment = EUODRAddressFragment;
}
export namespace CoreListingEuOdrAddress {
  /** @deprecated use Core_Listing_EuOdrAddressQueryVariables instead */
  export type Variables = Core_Listing_EuOdrAddressQueryVariables;
  /** @deprecated use Core_Listing_EuOdrAddressQuery instead */
  export type Query = Core_Listing_EuOdrAddressQuery;
}
export namespace CoreExpressSaleCheckoutShow {
  /** @deprecated use Core_ExpressSaleCheckoutShowQueryVariables instead */
  export type Variables = Core_ExpressSaleCheckoutShowQueryVariables;
  /** @deprecated use Core_ExpressSaleCheckoutShowQuery instead */
  export type Query = Core_ExpressSaleCheckoutShowQuery;
}
export namespace CoreCreateExpressSaleCheckout {
  /** @deprecated use Core_Create_ExpressSaleCheckoutMutationVariables instead */
  export type Variables = Core_Create_ExpressSaleCheckoutMutationVariables;
  /** @deprecated use Core_Create_ExpressSaleCheckoutMutation instead */
  export type Mutation = Core_Create_ExpressSaleCheckoutMutation;
}
export namespace CoreCompleteExpressSaleCheckout {
  /** @deprecated use Core_Complete_ExpressSaleCheckoutMutationVariables instead */
  export type Variables = Core_Complete_ExpressSaleCheckoutMutationVariables;
  /** @deprecated use Core_Complete_ExpressSaleCheckoutMutation instead */
  export type Mutation = Core_Complete_ExpressSaleCheckoutMutation;
}
export namespace ExpressSaleItemBidData {
  /** @deprecated use ExpressSaleItemBidDataFragment instead */
  export type Fragment = ExpressSaleItemBidDataFragment;
}
export namespace DeleteMyFavorite {
  /** @deprecated use DeleteMyFavoriteMutationVariables instead */
  export type Variables = DeleteMyFavoriteMutationVariables;
  /** @deprecated use DeleteMyFavoriteMutation instead */
  export type Mutation = DeleteMyFavoriteMutation;
}
export namespace CoreListingShopsRecommendedContent {
  /** @deprecated use Core_Listing_ShopsRecommendedContentQueryVariables instead */
  export type Variables = Core_Listing_ShopsRecommendedContentQueryVariables;
  /** @deprecated use Core_Listing_ShopsRecommendedContentQuery instead */
  export type Query = Core_Listing_ShopsRecommendedContentQuery;
}
export namespace FavoriteCTAData {
  /** @deprecated use FavoriteCTADataFragment instead */
  export type Fragment = FavoriteCTADataFragment;
}
export namespace UpsertMyFavorite {
  /** @deprecated use UpsertMyFavoriteMutationVariables instead */
  export type Variables = UpsertMyFavoriteMutationVariables;
  /** @deprecated use UpsertMyFavoriteMutation instead */
  export type Mutation = UpsertMyFavoriteMutation;
}
export namespace CoreFindFavoriteProviderUpsertMyFavorite {
  /** @deprecated use Core_FindFavoriteProvider_UpsertMyFavoriteMutationVariables instead */
  export type Variables =
    Core_FindFavoriteProvider_UpsertMyFavoriteMutationVariables;
  /** @deprecated use Core_FindFavoriteProvider_UpsertMyFavoriteMutation instead */
  export type Mutation = Core_FindFavoriteProvider_UpsertMyFavoriteMutation;
}
export namespace FavoriteSearchData {
  /** @deprecated use FavoriteSearchDataFragment instead */
  export type Fragment = FavoriteSearchDataFragment;
}
export namespace CoreGetFavoriteSearches {
  /** @deprecated use Core_Get_Favorite_SearchesQueryVariables instead */
  export type Variables = Core_Get_Favorite_SearchesQueryVariables;
  /** @deprecated use Core_Get_Favorite_SearchesQuery instead */
  export type Query = Core_Get_Favorite_SearchesQuery;
}
export namespace FavoriteShopCTAData {
  /** @deprecated use FavoriteShopCTADataFragment instead */
  export type Fragment = FavoriteShopCTADataFragment;
}
export namespace UpsertMyFavoriteShop {
  /** @deprecated use UpsertMyFavoriteShopMutationVariables instead */
  export type Variables = UpsertMyFavoriteShopMutationVariables;
  /** @deprecated use UpsertMyFavoriteShopMutation instead */
  export type Mutation = UpsertMyFavoriteShopMutation;
}
export namespace DeleteMyFavoriteShop {
  /** @deprecated use DeleteMyFavoriteShopMutationVariables instead */
  export type Variables = DeleteMyFavoriteShopMutationVariables;
  /** @deprecated use DeleteMyFavoriteShopMutation instead */
  export type Mutation = DeleteMyFavoriteShopMutation;
}
export namespace FavoriteShopData {
  /** @deprecated use FavoriteShopDataFragment instead */
  export type Fragment = FavoriteShopDataFragment;
}
export namespace CoreFavoritesShopsLayout {
  /** @deprecated use Core_FavoritesShops_LayoutQueryVariables instead */
  export type Variables = Core_FavoritesShops_LayoutQueryVariables;
  /** @deprecated use Core_FavoritesShops_LayoutQuery instead */
  export type Query = Core_FavoritesShops_LayoutQuery;
}
export namespace FavoriteWatchListCard {
  /** @deprecated use FavoriteWatchListCardFragment instead */
  export type Fragment = FavoriteWatchListCardFragment;
}
export namespace CoreFavoritesWatchListLayout {
  /** @deprecated use Core_Favorites_Watch_List_LayoutQueryVariables instead */
  export type Variables = Core_Favorites_Watch_List_LayoutQueryVariables;
  /** @deprecated use Core_Favorites_Watch_List_LayoutQuery instead */
  export type Query = Core_Favorites_Watch_List_LayoutQuery;
}
export namespace CoreFindFavorite {
  /** @deprecated use Core_FindFavoriteQueryVariables instead */
  export type Variables = Core_FindFavoriteQueryVariables;
  /** @deprecated use Core_FindFavoriteQuery instead */
  export type Query = Core_FindFavoriteQuery;
}
export namespace CorePostPurchaseShopNudge {
  /** @deprecated use Core_Post_Purchase_Shop_NudgeQueryVariables instead */
  export type Variables = Core_Post_Purchase_Shop_NudgeQueryVariables;
  /** @deprecated use Core_Post_Purchase_Shop_NudgeQuery instead */
  export type Query = Core_Post_Purchase_Shop_NudgeQuery;
}
export namespace FeaturedListingModuleData {
  /** @deprecated use FeaturedListingModuleDataFragment instead */
  export type Fragment = FeaturedListingModuleDataFragment;
}
export namespace FeaturedListingShopInfoData {
  /** @deprecated use FeaturedListingShopInfoDataFragment instead */
  export type Fragment = FeaturedListingShopInfoDataFragment;
}
export namespace DeclineFeedbackMutation {
  /** @deprecated use DeclineFeedbackMutationMutationVariables instead */
  export type Variables = DeclineFeedbackMutationMutationVariables;
  /** @deprecated use DeclineFeedbackMutationMutation instead */
  export type Mutation = DeclineFeedbackMutationMutation;
}
export namespace CoreFeedbackOrderInfo {
  /** @deprecated use Core_Feedback_OrderInfoQueryVariables instead */
  export type Variables = Core_Feedback_OrderInfoQueryVariables;
  /** @deprecated use Core_Feedback_OrderInfoQuery instead */
  export type Query = Core_Feedback_OrderInfoQuery;
}
export namespace UpdateFeedback {
  /** @deprecated use UpdateFeedbackMutationVariables instead */
  export type Variables = UpdateFeedbackMutationVariables;
  /** @deprecated use UpdateFeedbackMutation instead */
  export type Mutation = UpdateFeedbackMutation;
}
export namespace CreateMyFeedbackMessage {
  /** @deprecated use CreateMyFeedbackMessageMutationVariables instead */
  export type Variables = CreateMyFeedbackMessageMutationVariables;
  /** @deprecated use CreateMyFeedbackMessageMutation instead */
  export type Mutation = CreateMyFeedbackMessageMutation;
}
export namespace DeleteMyFeedbackMessage {
  /** @deprecated use DeleteMyFeedbackMessageMutationVariables instead */
  export type Variables = DeleteMyFeedbackMessageMutationVariables;
  /** @deprecated use DeleteMyFeedbackMessageMutation instead */
  export type Mutation = DeleteMyFeedbackMessageMutation;
}
export namespace MyFeedbackMessages {
  /** @deprecated use MyFeedbackMessagesQueryVariables instead */
  export type Variables = MyFeedbackMessagesQueryVariables;
  /** @deprecated use MyFeedbackMessagesQuery instead */
  export type Query = MyFeedbackMessagesQuery;
}
export namespace CoreFeedbackSellerShopInfo {
  /** @deprecated use Core_Feedback_SellerShopInfoQueryVariables instead */
  export type Variables = Core_Feedback_SellerShopInfoQueryVariables;
  /** @deprecated use Core_Feedback_SellerShopInfoQuery instead */
  export type Query = Core_Feedback_SellerShopInfoQuery;
}
export namespace GearCollectionSettingsData {
  /** @deprecated use GearCollectionSettingsDataFragment instead */
  export type Fragment = GearCollectionSettingsDataFragment;
}
export namespace CoreGearCollectionSettings {
  /** @deprecated use Core_GearCollection_SettingsQueryVariables instead */
  export type Variables = Core_GearCollection_SettingsQueryVariables;
  /** @deprecated use Core_GearCollection_SettingsQuery instead */
  export type Query = Core_GearCollection_SettingsQuery;
}
export namespace CoreGearCollectionUpdateSettings {
  /** @deprecated use Core_GearCollection_UpdateSettingsMutationVariables instead */
  export type Variables = Core_GearCollection_UpdateSettingsMutationVariables;
  /** @deprecated use Core_GearCollection_UpdateSettingsMutation instead */
  export type Mutation = Core_GearCollection_UpdateSettingsMutation;
}
export namespace GearCollectionCSPCardData {
  /** @deprecated use GearCollectionCSPCardDataFragment instead */
  export type Fragment = GearCollectionCSPCardDataFragment;
}
export namespace GearCollectionCSPSummaryCardData {
  /** @deprecated use GearCollectionCSPSummaryCardDataFragment instead */
  export type Fragment = GearCollectionCSPSummaryCardDataFragment;
}
export namespace CoreCSPVariantPickerCSP {
  /** @deprecated use Core_CSPVariantPicker_CSPQueryVariables instead */
  export type Variables = Core_CSPVariantPicker_CSPQueryVariables;
  /** @deprecated use Core_CSPVariantPicker_CSPQuery instead */
  export type Query = Core_CSPVariantPicker_CSPQuery;
}
export namespace DataServicesGearCollectionsPriceRecommendations {
  /** @deprecated use DataServices_GearCollections_PriceRecommendationsQueryVariables instead */
  export type Variables =
    DataServices_GearCollections_PriceRecommendationsQueryVariables;
  /** @deprecated use DataServices_GearCollections_PriceRecommendationsQuery instead */
  export type Query = DataServices_GearCollections_PriceRecommendationsQuery;
}
export namespace GearCollectionCSPData {
  /** @deprecated use GearCollectionCSPDataFragment instead */
  export type Fragment = GearCollectionCSPDataFragment;
}
export namespace CoreCrupdateGearCollectionItem {
  /** @deprecated use Core_Crupdate_GearCollectionItemMutationVariables instead */
  export type Variables = Core_Crupdate_GearCollectionItemMutationVariables;
  /** @deprecated use Core_Crupdate_GearCollectionItemMutation instead */
  export type Mutation = Core_Crupdate_GearCollectionItemMutation;
}
export namespace CoreDeleteGearCollectionItem {
  /** @deprecated use Core_Delete_GearCollectionItemMutationVariables instead */
  export type Variables = Core_Delete_GearCollectionItemMutationVariables;
  /** @deprecated use Core_Delete_GearCollectionItemMutation instead */
  export type Mutation = Core_Delete_GearCollectionItemMutation;
}
export namespace CoreGearCollectionItemForm {
  /** @deprecated use Core_GearCollectionItem_FormQueryVariables instead */
  export type Variables = Core_GearCollectionItem_FormQueryVariables;
  /** @deprecated use Core_GearCollectionItem_FormQuery instead */
  export type Query = Core_GearCollectionItem_FormQuery;
}
export namespace GearCollectionIndexCard {
  /** @deprecated use GearCollectionIndexCardFragment instead */
  export type Fragment = GearCollectionIndexCardFragment;
}
export namespace CoreGearCollectionItemDeleteFromIndex {
  /** @deprecated use Core_GearCollectionItem_DeleteFromIndexMutationVariables instead */
  export type Variables =
    Core_GearCollectionItem_DeleteFromIndexMutationVariables;
  /** @deprecated use Core_GearCollectionItem_DeleteFromIndexMutation instead */
  export type Mutation = Core_GearCollectionItem_DeleteFromIndexMutation;
}
export namespace GcIndexCardPrices {
  /** @deprecated use GcIndexCardPricesFragment instead */
  export type Fragment = GcIndexCardPricesFragment;
}
export namespace SearchGearCollectionItemIndex {
  /** @deprecated use Search_GearCollectionItem_IndexQueryVariables instead */
  export type Variables = Search_GearCollectionItem_IndexQueryVariables;
  /** @deprecated use Search_GearCollectionItem_IndexQuery instead */
  export type Query = Search_GearCollectionItem_IndexQuery;
}
export namespace CoreOrderSearchAddPastPurchasesToGearCollection {
  /** @deprecated use Core_OrderSearch_AddPastPurchasesToGearCollectionQueryVariables instead */
  export type Variables =
    Core_OrderSearch_AddPastPurchasesToGearCollectionQueryVariables;
  /** @deprecated use Core_OrderSearch_AddPastPurchasesToGearCollectionQuery instead */
  export type Query = Core_OrderSearch_AddPastPurchasesToGearCollectionQuery;
}
export namespace QuickAddOrderCardFields {
  /** @deprecated use QuickAddOrderCardFieldsFragment instead */
  export type Fragment = QuickAddOrderCardFieldsFragment;
}
export namespace GearCollectionItemShowDisplayData {
  /** @deprecated use GearCollectionItemShowDisplayDataFragment instead */
  export type Fragment = GearCollectionItemShowDisplayDataFragment;
}
export namespace CoreGearCollectionItemShowLayout {
  /** @deprecated use CoreGearCollectionItemShowLayoutQueryVariables instead */
  export type Variables = CoreGearCollectionItemShowLayoutQueryVariables;
  /** @deprecated use CoreGearCollectionItemShowLayoutQuery instead */
  export type Query = CoreGearCollectionItemShowLayoutQuery;
}
export namespace CoreGiftCardCreditCards {
  /** @deprecated use Core_Gift_Card_Credit_CardsQueryVariables instead */
  export type Variables = Core_Gift_Card_Credit_CardsQueryVariables;
  /** @deprecated use Core_Gift_Card_Credit_CardsQuery instead */
  export type Query = Core_Gift_Card_Credit_CardsQuery;
}
export namespace CoreShopSettingsInternationalTaxProfile {
  /** @deprecated use Core_ShopSettings_InternationalTaxProfileQueryVariables instead */
  export type Variables =
    Core_ShopSettings_InternationalTaxProfileQueryVariables;
  /** @deprecated use Core_ShopSettings_InternationalTaxProfileQuery instead */
  export type Query = Core_ShopSettings_InternationalTaxProfileQuery;
}
export namespace UpdateInternationalTaxProfile {
  /** @deprecated use UpdateInternationalTaxProfileMutationVariables instead */
  export type Variables = UpdateInternationalTaxProfileMutationVariables;
  /** @deprecated use UpdateInternationalTaxProfileMutation instead */
  export type Mutation = UpdateInternationalTaxProfileMutation;
}
export namespace CoreMyShopExportDac7OrderData {
  /** @deprecated use Core_MyShopExportDac7OrderDataMutationVariables instead */
  export type Variables = Core_MyShopExportDac7OrderDataMutationVariables;
  /** @deprecated use Core_MyShopExportDac7OrderDataMutation instead */
  export type Mutation = Core_MyShopExportDac7OrderDataMutation;
}
export namespace Item2AboutThisListingData {
  /** @deprecated use Item2AboutThisListingDataFragment instead */
  export type Fragment = Item2AboutThisListingDataFragment;
}
export namespace Item2AboutThisSellerData {
  /** @deprecated use Item2AboutThisSellerDataFragment instead */
  export type Fragment = Item2AboutThisSellerDataFragment;
}
export namespace CoreAboutThisSellerFindFavorite {
  /** @deprecated use Core_About_This_Seller_Find_FavoriteQueryVariables instead */
  export type Variables = Core_About_This_Seller_Find_FavoriteQueryVariables;
  /** @deprecated use Core_About_This_Seller_Find_FavoriteQuery instead */
  export type Query = Core_About_This_Seller_Find_FavoriteQuery;
}
export namespace Item2AdWithCspsRowContainerData {
  /** @deprecated use Item2AdWithCspsRowContainerDataFragment instead */
  export type Fragment = Item2AdWithCspsRowContainerDataFragment;
}
export namespace Item2AdminButtonsData {
  /** @deprecated use Item2AdminButtonsDataFragment instead */
  export type Fragment = Item2AdminButtonsDataFragment;
}
export namespace Item2FallbackBreadcrumbsData {
  /** @deprecated use Item2FallbackBreadcrumbsDataFragment instead */
  export type Fragment = Item2FallbackBreadcrumbsDataFragment;
}
export namespace Item2BreadcrumbsData {
  /** @deprecated use Item2BreadcrumbsDataFragment instead */
  export type Fragment = Item2BreadcrumbsDataFragment;
}
export namespace Item2CPOData {
  /** @deprecated use Item2CPODataFragment instead */
  export type Fragment = Item2CPODataFragment;
}
export namespace Item2DescriptionData {
  /** @deprecated use Item2DescriptionDataFragment instead */
  export type Fragment = Item2DescriptionDataFragment;
}
export namespace Item2FeedbackMetadata {
  /** @deprecated use Item2_Feedback_MetadataQueryVariables instead */
  export type Variables = Item2_Feedback_MetadataQueryVariables;
  /** @deprecated use Item2_Feedback_MetadataQuery instead */
  export type Query = Item2_Feedback_MetadataQuery;
}
export namespace Item2FeedbackItemData {
  /** @deprecated use Item2FeedbackItemDataFragment instead */
  export type Fragment = Item2FeedbackItemDataFragment;
}
export namespace Item2SellerReviews {
  /** @deprecated use Item2_Seller_ReviewsQueryVariables instead */
  export type Variables = Item2_Seller_ReviewsQueryVariables;
  /** @deprecated use Item2_Seller_ReviewsQuery instead */
  export type Query = Item2_Seller_ReviewsQuery;
}
export namespace Item2FlagListingData {
  /** @deprecated use Item2FlagListingDataFragment instead */
  export type Fragment = Item2FlagListingDataFragment;
}
export namespace Item2FlagListingMeData {
  /** @deprecated use Item2FlagListingMeDataFragment instead */
  export type Fragment = Item2FlagListingMeDataFragment;
}
export namespace ItemFooterFields {
  /** @deprecated use ItemFooterFieldsFragment instead */
  export type Fragment = ItemFooterFieldsFragment;
}
export namespace ItemImageFields {
  /** @deprecated use ItemImageFieldsFragment instead */
  export type Fragment = ItemImageFieldsFragment;
}
export namespace Item2AvailableActionsData {
  /** @deprecated use Item2AvailableActionsDataFragment instead */
  export type Fragment = Item2AvailableActionsDataFragment;
}
export namespace CoreListingLayout {
  /** @deprecated use Core_Listing_LayoutQueryVariables instead */
  export type Variables = Core_Listing_LayoutQueryVariables;
  /** @deprecated use Core_Listing_LayoutQuery instead */
  export type Query = Core_Listing_LayoutQuery;
}
export namespace CoreListingLayoutSignals {
  /** @deprecated use Core_Listing_Layout_SignalsQueryVariables instead */
  export type Variables = Core_Listing_Layout_SignalsQueryVariables;
  /** @deprecated use Core_Listing_Layout_SignalsQuery instead */
  export type Query = Core_Listing_Layout_SignalsQuery;
}
export namespace CoreListingLayoutSimilarListings {
  /** @deprecated use Core_Listing_Layout_SimilarListingsQueryVariables instead */
  export type Variables = Core_Listing_Layout_SimilarListingsQueryVariables;
  /** @deprecated use Core_Listing_Layout_SimilarListingsQuery instead */
  export type Query = Core_Listing_Layout_SimilarListingsQuery;
}
export namespace CoreListingLayoutMe {
  /** @deprecated use Core_Listing_Layout_MeQueryVariables instead */
  export type Variables = Core_Listing_Layout_MeQueryVariables;
  /** @deprecated use Core_Listing_Layout_MeQuery instead */
  export type Query = Core_Listing_Layout_MeQuery;
}
export namespace Item2ListingPaymentPoliciesData {
  /** @deprecated use Item2ListingPaymentPoliciesDataFragment instead */
  export type Fragment = Item2ListingPaymentPoliciesDataFragment;
}
export namespace Item2ShopReturnPoliciesData {
  /** @deprecated use Item2ShopReturnPoliciesDataFragment instead */
  export type Fragment = Item2ShopReturnPoliciesDataFragment;
}
export namespace Item2ShopShippingPoliciesData {
  /** @deprecated use Item2ShopShippingPoliciesDataFragment instead */
  export type Fragment = Item2ShopShippingPoliciesDataFragment;
}
export namespace Item2MetadataData {
  /** @deprecated use Item2MetadataDataFragment instead */
  export type Fragment = Item2MetadataDataFragment;
}
export namespace SignalsData {
  /** @deprecated use SignalsDataFragment instead */
  export type Fragment = SignalsDataFragment;
}
export namespace Item2OrderDetailsData {
  /** @deprecated use Item2OrderDetailsDataFragment instead */
  export type Fragment = Item2OrderDetailsDataFragment;
}
export namespace Item2PaidLandingData {
  /** @deprecated use Item2PaidLandingDataFragment instead */
  export type Fragment = Item2PaidLandingDataFragment;
}
export namespace Item2PricingData {
  /** @deprecated use Item2PricingDataFragment instead */
  export type Fragment = Item2PricingDataFragment;
}
export namespace Item2PricingNegotiationsData {
  /** @deprecated use Item2PricingNegotiationsDataFragment instead */
  export type Fragment = Item2PricingNegotiationsDataFragment;
}
export namespace CoreListingProtectionPlanOptions {
  /** @deprecated use Core_ListingProtectionPlanOptionsQueryVariables instead */
  export type Variables = Core_ListingProtectionPlanOptionsQueryVariables;
  /** @deprecated use Core_ListingProtectionPlanOptionsQuery instead */
  export type Query = Core_ListingProtectionPlanOptionsQuery;
}
export namespace Item2ProductModuleData {
  /** @deprecated use Item2ProductModuleDataFragment instead */
  export type Fragment = Item2ProductModuleDataFragment;
}
export namespace CoreListingItem2ProductModuleListingRow {
  /** @deprecated use Core_Listing_Item2ProductModuleListingRowQueryVariables instead */
  export type Variables =
    Core_Listing_Item2ProductModuleListingRowQueryVariables;
  /** @deprecated use Core_Listing_Item2ProductModuleListingRowQuery instead */
  export type Query = Core_Listing_Item2ProductModuleListingRowQuery;
}
export namespace Item2ProductSpecsData {
  /** @deprecated use Item2ProductSpecsDataFragment instead */
  export type Fragment = Item2ProductSpecsDataFragment;
}
export namespace Item2Prop65WarningData {
  /** @deprecated use Item2Prop65WarningDataFragment instead */
  export type Fragment = Item2Prop65WarningDataFragment;
}
export namespace Item2ReturnPolicyData {
  /** @deprecated use Item2ReturnPolicyDataFragment instead */
  export type Fragment = Item2ReturnPolicyDataFragment;
}
export namespace CoreListingShippingBoxImage {
  /** @deprecated use Core_Listing_ShippingBoxImageQueryVariables instead */
  export type Variables = Core_Listing_ShippingBoxImageQueryVariables;
  /** @deprecated use Core_Listing_ShippingBoxImageQuery instead */
  export type Query = Core_Listing_ShippingBoxImageQuery;
}
export namespace ShippingWarningData {
  /** @deprecated use ShippingWarningDataFragment instead */
  export type Fragment = ShippingWarningDataFragment;
}
export namespace Item2ShopModuleData {
  /** @deprecated use Item2ShopModuleDataFragment instead */
  export type Fragment = Item2ShopModuleDataFragment;
}
export namespace Item2ShopOverviewData {
  /** @deprecated use Item2ShopOverviewDataFragment instead */
  export type Fragment = Item2ShopOverviewDataFragment;
}
export namespace ShopPoliciesData {
  /** @deprecated use ShopPoliciesDataFragment instead */
  export type Fragment = ShopPoliciesDataFragment;
}
export namespace Item2ShopPolicies {
  /** @deprecated use Item2_Shop_PoliciesQueryVariables instead */
  export type Variables = Item2_Shop_PoliciesQueryVariables;
  /** @deprecated use Item2_Shop_PoliciesQuery instead */
  export type Query = Item2_Shop_PoliciesQuery;
}
export namespace Item2ShopReviewsData {
  /** @deprecated use Item2ShopReviewsDataFragment instead */
  export type Fragment = Item2ShopReviewsDataFragment;
}
export namespace Item2ShopTaxPoliciesData {
  /** @deprecated use Item2ShopTaxPoliciesDataFragment instead */
  export type Fragment = Item2ShopTaxPoliciesDataFragment;
}
export namespace Item2StatsData {
  /** @deprecated use Item2StatsDataFragment instead */
  export type Fragment = Item2StatsDataFragment;
}
export namespace Item2TitleData {
  /** @deprecated use Item2TitleDataFragment instead */
  export type Fragment = Item2TitleDataFragment;
}
export namespace Item2Video {
  /** @deprecated use Item2VideoFragment instead */
  export type Fragment = Item2VideoFragment;
}
export namespace ItemPageEndedListingExploreRowFields {
  /** @deprecated use ItemPageEndedListingExploreRowFieldsFragment instead */
  export type Fragment = ItemPageEndedListingExploreRowFieldsFragment;
}
export namespace ItemPageEndedListingExploreRowSearchFields {
  /** @deprecated use ItemPageEndedListingExploreRowSearchFieldsFragment instead */
  export type Fragment = ItemPageEndedListingExploreRowSearchFieldsFragment;
}
export namespace CoreListingExploreRow {
  /** @deprecated use Core_Listing_ExploreRowQueryVariables instead */
  export type Variables = Core_Listing_ExploreRowQueryVariables;
  /** @deprecated use Core_Listing_ExploreRowQuery instead */
  export type Query = Core_Listing_ExploreRowQuery;
}
export namespace ListViewListings {
  /** @deprecated use ListViewListingsFragment instead */
  export type Fragment = ListViewListingsFragment;
}
export namespace CoreQuickInventoryEditListing {
  /** @deprecated use Core_QuickInventoryEdit_ListingMutationVariables instead */
  export type Variables = Core_QuickInventoryEdit_ListingMutationVariables;
  /** @deprecated use Core_QuickInventoryEdit_ListingMutation instead */
  export type Mutation = Core_QuickInventoryEdit_ListingMutation;
}
export namespace CoreAutoOfferModal {
  /** @deprecated use Core_AutoOfferModalQueryVariables instead */
  export type Variables = Core_AutoOfferModalQueryVariables;
  /** @deprecated use Core_AutoOfferModalQuery instead */
  export type Query = Core_AutoOfferModalQuery;
}
export namespace CoreQuickPriceEditListing {
  /** @deprecated use Core_QuickPriceEdit_ListingQueryVariables instead */
  export type Variables = Core_QuickPriceEdit_ListingQueryVariables;
  /** @deprecated use Core_QuickPriceEdit_ListingQuery instead */
  export type Query = Core_QuickPriceEdit_ListingQuery;
}
export namespace CoreQuickPriceEditPriceUpdate {
  /** @deprecated use Core_QuickPriceEdit_PriceUpdateMutationVariables instead */
  export type Variables = Core_QuickPriceEdit_PriceUpdateMutationVariables;
  /** @deprecated use Core_QuickPriceEdit_PriceUpdateMutation instead */
  export type Mutation = Core_QuickPriceEdit_PriceUpdateMutation;
}
export namespace SellerListingPriceRecommendation {
  /** @deprecated use SellerListingPriceRecommendationFragment instead */
  export type Fragment = SellerListingPriceRecommendationFragment;
}
export namespace SellerListingCard {
  /** @deprecated use SellerListingCardFragment instead */
  export type Fragment = SellerListingCardFragment;
}
export namespace CoreMyListingsSellerListingCards {
  /** @deprecated use Core_MyListings_SellerListingCardsQueryVariables instead */
  export type Variables = Core_MyListings_SellerListingCardsQueryVariables;
  /** @deprecated use Core_MyListings_SellerListingCardsQuery instead */
  export type Query = Core_MyListings_SellerListingCardsQuery;
}
export namespace CoreMyListingsBulkDelete {
  /** @deprecated use Core_MyListings_BulkDeleteMutationVariables instead */
  export type Variables = Core_MyListings_BulkDeleteMutationVariables;
  /** @deprecated use Core_MyListings_BulkDeleteMutation instead */
  export type Mutation = Core_MyListings_BulkDeleteMutation;
}
export namespace CoreMyListingsBulkDeleteAsync {
  /** @deprecated use Core_MyListings_BulkDeleteAsyncMutationVariables instead */
  export type Variables = Core_MyListings_BulkDeleteAsyncMutationVariables;
  /** @deprecated use Core_MyListings_BulkDeleteAsyncMutation instead */
  export type Mutation = Core_MyListings_BulkDeleteAsyncMutation;
}
export namespace CoreMyListingsBulkPublish {
  /** @deprecated use Core_MyListings_BulkPublishMutationVariables instead */
  export type Variables = Core_MyListings_BulkPublishMutationVariables;
  /** @deprecated use Core_MyListings_BulkPublishMutation instead */
  export type Mutation = Core_MyListings_BulkPublishMutation;
}
export namespace CoreMyListingsBulkPublishAsync {
  /** @deprecated use Core_MyListings_BulkPublishAsyncMutationVariables instead */
  export type Variables = Core_MyListings_BulkPublishAsyncMutationVariables;
  /** @deprecated use Core_MyListings_BulkPublishAsyncMutation instead */
  export type Mutation = Core_MyListings_BulkPublishAsyncMutation;
}
export namespace CoreMyListingsExportListings {
  /** @deprecated use Core_MyListings_ExportListingsMutationVariables instead */
  export type Variables = Core_MyListings_ExportListingsMutationVariables;
  /** @deprecated use Core_MyListings_ExportListingsMutation instead */
  export type Mutation = Core_MyListings_ExportListingsMutation;
}
export namespace CoreMyListingsPublishAllListings {
  /** @deprecated use Core_MyListings_PublishAllListingsMutationVariables instead */
  export type Variables = Core_MyListings_PublishAllListingsMutationVariables;
  /** @deprecated use Core_MyListings_PublishAllListingsMutation instead */
  export type Mutation = Core_MyListings_PublishAllListingsMutation;
}
export namespace CoreMyListingsBulkCreateAffirm {
  /** @deprecated use Core_MyListings_BulkCreateAffirmMutationVariables instead */
  export type Variables = Core_MyListings_BulkCreateAffirmMutationVariables;
  /** @deprecated use Core_MyListings_BulkCreateAffirmMutation instead */
  export type Mutation = Core_MyListings_BulkCreateAffirmMutation;
}
export namespace CoreMyListingsBulkCreateAffirmAsync {
  /** @deprecated use Core_MyListings_BulkCreateAffirmAsyncMutationVariables instead */
  export type Variables =
    Core_MyListings_BulkCreateAffirmAsyncMutationVariables;
  /** @deprecated use Core_MyListings_BulkCreateAffirmAsyncMutation instead */
  export type Mutation = Core_MyListings_BulkCreateAffirmAsyncMutation;
}
export namespace CoreMyListingsBulkDeleteAffirm {
  /** @deprecated use Core_MyListings_BulkDeleteAffirmMutationVariables instead */
  export type Variables = Core_MyListings_BulkDeleteAffirmMutationVariables;
  /** @deprecated use Core_MyListings_BulkDeleteAffirmMutation instead */
  export type Mutation = Core_MyListings_BulkDeleteAffirmMutation;
}
export namespace CoreMyListingsBulkDeleteAffirmAsync {
  /** @deprecated use Core_MyListings_BulkDeleteAffirmAsyncMutationVariables instead */
  export type Variables =
    Core_MyListings_BulkDeleteAffirmAsyncMutationVariables;
  /** @deprecated use Core_MyListings_BulkDeleteAffirmAsyncMutation instead */
  export type Mutation = Core_MyListings_BulkDeleteAffirmAsyncMutation;
}
export namespace CoreMyListingsBulkCreateBumped {
  /** @deprecated use Core_MyListings_BulkCreateBumpedMutationVariables instead */
  export type Variables = Core_MyListings_BulkCreateBumpedMutationVariables;
  /** @deprecated use Core_MyListings_BulkCreateBumpedMutation instead */
  export type Mutation = Core_MyListings_BulkCreateBumpedMutation;
}
export namespace CoreMyListingsBulkCreateBumpedAsync {
  /** @deprecated use Core_MyListings_BulkCreateBumpedAsyncMutationVariables instead */
  export type Variables =
    Core_MyListings_BulkCreateBumpedAsyncMutationVariables;
  /** @deprecated use Core_MyListings_BulkCreateBumpedAsyncMutation instead */
  export type Mutation = Core_MyListings_BulkCreateBumpedAsyncMutation;
}
export namespace CoreMyListingsBulkDeleteBumped {
  /** @deprecated use Core_MyListings_BulkDeleteBumpedMutationVariables instead */
  export type Variables = Core_MyListings_BulkDeleteBumpedMutationVariables;
  /** @deprecated use Core_MyListings_BulkDeleteBumpedMutation instead */
  export type Mutation = Core_MyListings_BulkDeleteBumpedMutation;
}
export namespace CoreMyListingsBulkDeleteBumpedAsync {
  /** @deprecated use Core_MyListings_BulkDeleteBumpedAsyncMutationVariables instead */
  export type Variables =
    Core_MyListings_BulkDeleteBumpedAsyncMutationVariables;
  /** @deprecated use Core_MyListings_BulkDeleteBumpedAsyncMutation instead */
  export type Mutation = Core_MyListings_BulkDeleteBumpedAsyncMutation;
}
export namespace CoreMyListingsBulkCreateAutoOffers {
  /** @deprecated use Core_MyListings_BulkCreateAutoOffersMutationVariables instead */
  export type Variables = Core_MyListings_BulkCreateAutoOffersMutationVariables;
  /** @deprecated use Core_MyListings_BulkCreateAutoOffersMutation instead */
  export type Mutation = Core_MyListings_BulkCreateAutoOffersMutation;
}
export namespace CoreMyListingsBulkCreateAutoOffersAsync {
  /** @deprecated use Core_MyListings_BulkCreateAutoOffersAsyncMutationVariables instead */
  export type Variables =
    Core_MyListings_BulkCreateAutoOffersAsyncMutationVariables;
  /** @deprecated use Core_MyListings_BulkCreateAutoOffersAsyncMutation instead */
  export type Mutation = Core_MyListings_BulkCreateAutoOffersAsyncMutation;
}
export namespace CoreMyListingsBulkDeleteAutoOffers {
  /** @deprecated use Core_MyListings_BulkDeleteAutoOffersMutationVariables instead */
  export type Variables = Core_MyListings_BulkDeleteAutoOffersMutationVariables;
  /** @deprecated use Core_MyListings_BulkDeleteAutoOffersMutation instead */
  export type Mutation = Core_MyListings_BulkDeleteAutoOffersMutation;
}
export namespace CoreMyListingsBulkDeleteAutoOffersAsync {
  /** @deprecated use Core_MyListings_BulkDeleteAutoOffersAsyncMutationVariables instead */
  export type Variables =
    Core_MyListings_BulkDeleteAutoOffersAsyncMutationVariables;
  /** @deprecated use Core_MyListings_BulkDeleteAutoOffersAsyncMutation instead */
  export type Mutation = Core_MyListings_BulkDeleteAutoOffersAsyncMutation;
}
export namespace CoreMyListingsBulkCreateSaleMemberships {
  /** @deprecated use Core_MyListings_BulkCreateSaleMembershipsMutationVariables instead */
  export type Variables =
    Core_MyListings_BulkCreateSaleMembershipsMutationVariables;
  /** @deprecated use Core_MyListings_BulkCreateSaleMembershipsMutation instead */
  export type Mutation = Core_MyListings_BulkCreateSaleMembershipsMutation;
}
export namespace CoreMyListingsBulkDeleteSaleMemberships {
  /** @deprecated use Core_MyListings_BulkDeleteSaleMembershipsMutationVariables instead */
  export type Variables =
    Core_MyListings_BulkDeleteSaleMembershipsMutationVariables;
  /** @deprecated use Core_MyListings_BulkDeleteSaleMembershipsMutation instead */
  export type Mutation = Core_MyListings_BulkDeleteSaleMembershipsMutation;
}
export namespace CoreMySales {
  /** @deprecated use Core_MySalesQueryVariables instead */
  export type Variables = Core_MySalesQueryVariables;
  /** @deprecated use Core_MySalesQuery instead */
  export type Query = Core_MySalesQuery;
}
export namespace CoreListingDeleteDraft {
  /** @deprecated use Core_Listing_DeleteDraftMutationVariables instead */
  export type Variables = Core_Listing_DeleteDraftMutationVariables;
  /** @deprecated use Core_Listing_DeleteDraftMutation instead */
  export type Mutation = Core_Listing_DeleteDraftMutation;
}
export namespace CoreZeroFinancingEnable {
  /** @deprecated use Core_ZeroFinancing_EnableMutationVariables instead */
  export type Variables = Core_ZeroFinancing_EnableMutationVariables;
  /** @deprecated use Core_ZeroFinancing_EnableMutation instead */
  export type Mutation = Core_ZeroFinancing_EnableMutation;
}
export namespace CoreZeroFinancingDisable {
  /** @deprecated use Core_ZeroFinancing_DisableMutationVariables instead */
  export type Variables = Core_ZeroFinancing_DisableMutationVariables;
  /** @deprecated use Core_ZeroFinancing_DisableMutation instead */
  export type Mutation = Core_ZeroFinancing_DisableMutation;
}
export namespace CoreMyListingCreateSaleMembership {
  /** @deprecated use Core_MyListing_CreateSaleMembershipMutationVariables instead */
  export type Variables = Core_MyListing_CreateSaleMembershipMutationVariables;
  /** @deprecated use Core_MyListing_CreateSaleMembershipMutation instead */
  export type Mutation = Core_MyListing_CreateSaleMembershipMutation;
}
export namespace CoreMyListingDeleteSaleMembership {
  /** @deprecated use Core_MyListing_DeleteSaleMembershipMutationVariables instead */
  export type Variables = Core_MyListing_DeleteSaleMembershipMutationVariables;
  /** @deprecated use Core_MyListing_DeleteSaleMembershipMutation instead */
  export type Mutation = Core_MyListing_DeleteSaleMembershipMutation;
}
export namespace AvailableSalesMemberships {
  /** @deprecated use AvailableSalesMembershipsFragment instead */
  export type Fragment = AvailableSalesMembershipsFragment;
}
export namespace CoreMyListingAvailableSalesMemberships {
  /** @deprecated use Core_MyListing_AvailableSalesMembershipsQueryVariables instead */
  export type Variables =
    Core_MyListing_AvailableSalesMembershipsQueryVariables;
  /** @deprecated use Core_MyListing_AvailableSalesMembershipsQuery instead */
  export type Query = Core_MyListing_AvailableSalesMembershipsQuery;
}
export namespace CoreListingUpdateState {
  /** @deprecated use Core_Listing_UpdateStateMutationVariables instead */
  export type Variables = Core_Listing_UpdateStateMutationVariables;
  /** @deprecated use Core_Listing_UpdateStateMutation instead */
  export type Mutation = Core_Listing_UpdateStateMutation;
}
export namespace CollectionInfo {
  /** @deprecated use CollectionInfoFragment instead */
  export type Fragment = CollectionInfoFragment;
}
export namespace CombinedMarketplaceBumpTracking {
  /** @deprecated use CombinedMarketplaceBumpTrackingMutationVariables instead */
  export type Variables = CombinedMarketplaceBumpTrackingMutationVariables;
  /** @deprecated use CombinedMarketplaceBumpTrackingMutation instead */
  export type Mutation = CombinedMarketplaceBumpTrackingMutation;
}
export namespace CoreMarketplaceHeader {
  /** @deprecated use Core_Marketplace_HeaderQueryVariables instead */
  export type Variables = Core_Marketplace_HeaderQueryVariables;
  /** @deprecated use Core_Marketplace_HeaderQuery instead */
  export type Query = Core_Marketplace_HeaderQuery;
}
export namespace CoreMarketplaceCombinedMarketplaceSearch {
  /** @deprecated use Core_Marketplace_CombinedMarketplaceSearchQueryVariables instead */
  export type Variables =
    Core_Marketplace_CombinedMarketplaceSearchQueryVariables;
  /** @deprecated use Core_Marketplace_CombinedMarketplaceSearchQuery instead */
  export type Query = Core_Marketplace_CombinedMarketplaceSearchQuery;
}
export namespace CoreMarketplaceMarketplaceListingsCount {
  /** @deprecated use Core_Marketplace_MarketplaceListingsCountQueryVariables instead */
  export type Variables =
    Core_Marketplace_MarketplaceListingsCountQueryVariables;
  /** @deprecated use Core_Marketplace_MarketplaceListingsCountQuery instead */
  export type Query = Core_Marketplace_MarketplaceListingsCountQuery;
}
export namespace CoreShopMarketplace {
  /** @deprecated use Core_Shop_MarketplaceQueryVariables instead */
  export type Variables = Core_Shop_MarketplaceQueryVariables;
  /** @deprecated use Core_Shop_MarketplaceQuery instead */
  export type Query = Core_Shop_MarketplaceQuery;
}
export namespace MarketplaceSuggestions {
  /** @deprecated use MarketplaceSuggestionsFragment instead */
  export type Fragment = MarketplaceSuggestionsFragment;
}
export namespace OutletListingsFields {
  /** @deprecated use OutletListingsFieldsFragment instead */
  export type Fragment = OutletListingsFieldsFragment;
}
export namespace CoreListingOutletPageGrid {
  /** @deprecated use Core_Listing_OutletPageGridQueryVariables instead */
  export type Variables = Core_Listing_OutletPageGridQueryVariables;
  /** @deprecated use Core_Listing_OutletPageGridQuery instead */
  export type Query = Core_Listing_OutletPageGridQuery;
}
export namespace CorePacklinkAccountStatus {
  /** @deprecated use Core_Packlink_AccountStatusQueryVariables instead */
  export type Variables = Core_Packlink_AccountStatusQueryVariables;
  /** @deprecated use Core_Packlink_AccountStatusQuery instead */
  export type Query = Core_Packlink_AccountStatusQuery;
}
export namespace OrderPacklinkShipmentData {
  /** @deprecated use OrderPacklinkShipmentDataFragment instead */
  export type Fragment = OrderPacklinkShipmentDataFragment;
}
export namespace MyShopPacklinkAccountData {
  /** @deprecated use MyShopPacklinkAccountDataFragment instead */
  export type Fragment = MyShopPacklinkAccountDataFragment;
}
export namespace CorePacklinkShippingLabelButton {
  /** @deprecated use Core_Packlink_ShippingLabelButtonQueryVariables instead */
  export type Variables = Core_Packlink_ShippingLabelButtonQueryVariables;
  /** @deprecated use Core_Packlink_ShippingLabelButtonQuery instead */
  export type Query = Core_Packlink_ShippingLabelButtonQuery;
}
export namespace CorePacklinkCreateDraft {
  /** @deprecated use Core_Packlink_CreateDraftMutationVariables instead */
  export type Variables = Core_Packlink_CreateDraftMutationVariables;
  /** @deprecated use Core_Packlink_CreateDraftMutation instead */
  export type Mutation = Core_Packlink_CreateDraftMutation;
}
export namespace CorePacklinkLoginAccount {
  /** @deprecated use Core_Packlink_LoginAccountMutationVariables instead */
  export type Variables = Core_Packlink_LoginAccountMutationVariables;
  /** @deprecated use Core_Packlink_LoginAccountMutation instead */
  export type Mutation = Core_Packlink_LoginAccountMutation;
}
export namespace CorePacklinkRegisterAccount {
  /** @deprecated use Core_Packlink_RegisterAccountMutationVariables instead */
  export type Variables = Core_Packlink_RegisterAccountMutationVariables;
  /** @deprecated use Core_Packlink_RegisterAccountMutation instead */
  export type Mutation = Core_Packlink_RegisterAccountMutation;
}
export namespace myShopCountry {
  /** @deprecated use myShopCountryFragment instead */
  export type Fragment = myShopCountryFragment;
}
export namespace PayStatementCountries {
  /** @deprecated use PayStatementCountriesQueryVariables instead */
  export type Variables = PayStatementCountriesQueryVariables;
  /** @deprecated use PayStatementCountriesQuery instead */
  export type Query = PayStatementCountriesQuery;
}
export namespace PayStatementCreditCardListFields {
  /** @deprecated use PayStatementCreditCardListFieldsFragment instead */
  export type Fragment = PayStatementCreditCardListFieldsFragment;
}
export namespace CreditCardListQuery {
  /** @deprecated use CreditCardListQueryQueryVariables instead */
  export type Variables = CreditCardListQueryQueryVariables;
  /** @deprecated use CreditCardListQueryQuery instead */
  export type Query = CreditCardListQueryQuery;
}
export namespace PayMyStatement {
  /** @deprecated use PayMyStatementMutationVariables instead */
  export type Variables = PayMyStatementMutationVariables;
  /** @deprecated use PayMyStatementMutation instead */
  export type Mutation = PayMyStatementMutation;
}
export namespace PhotoTileData {
  /** @deprecated use PhotoTileDataFragment instead */
  export type Fragment = PhotoTileDataFragment;
}
export namespace CorePayoutsCreateMyPlaidLinkToken {
  /** @deprecated use Core_Payouts_CreateMyPlaidLinkTokenMutationVariables instead */
  export type Variables = Core_Payouts_CreateMyPlaidLinkTokenMutationVariables;
  /** @deprecated use Core_Payouts_CreateMyPlaidLinkTokenMutation instead */
  export type Mutation = Core_Payouts_CreateMyPlaidLinkTokenMutation;
}
export namespace CorePayoutsSaveMyPlaidAccount {
  /** @deprecated use Core_Payouts_SaveMyPlaidAccountMutationVariables instead */
  export type Variables = Core_Payouts_SaveMyPlaidAccountMutationVariables;
  /** @deprecated use Core_Payouts_SaveMyPlaidAccountMutation instead */
  export type Mutation = Core_Payouts_SaveMyPlaidAccountMutation;
}
export namespace CorePayoutsCancelPlaidAccount {
  /** @deprecated use Core_Payouts_CancelPlaidAccountMutationVariables instead */
  export type Variables = Core_Payouts_CancelPlaidAccountMutationVariables;
  /** @deprecated use Core_Payouts_CancelPlaidAccountMutation instead */
  export type Mutation = Core_Payouts_CancelPlaidAccountMutation;
}
export namespace DataServicesPriceGuideCspEstimates {
  /** @deprecated use DataServices_PriceGuide_CspEstimatesQueryVariables instead */
  export type Variables = DataServices_PriceGuide_CspEstimatesQueryVariables;
  /** @deprecated use DataServices_PriceGuide_CspEstimatesQuery instead */
  export type Query = DataServices_PriceGuide_CspEstimatesQuery;
}
export namespace DataServicesPriceGuideCpEstimates {
  /** @deprecated use DataServices_PriceGuide_CpEstimatesQueryVariables instead */
  export type Variables = DataServices_PriceGuide_CpEstimatesQueryVariables;
  /** @deprecated use DataServices_PriceGuide_CpEstimatesQuery instead */
  export type Query = DataServices_PriceGuide_CpEstimatesQuery;
}
export namespace SearchPriceGuideTotalOrders {
  /** @deprecated use Search_PriceGuide_TotalOrdersQueryVariables instead */
  export type Variables = Search_PriceGuide_TotalOrdersQueryVariables;
  /** @deprecated use Search_PriceGuide_TotalOrdersQuery instead */
  export type Query = Search_PriceGuide_TotalOrdersQuery;
}
export namespace TransactionTablePriceRecordsData {
  /** @deprecated use TransactionTablePriceRecordsDataFragment instead */
  export type Fragment = TransactionTablePriceRecordsDataFragment;
}
export namespace SearchPriceGuideToolTransactionTable {
  /** @deprecated use Search_PriceGuideTool_TransactionTableQueryVariables instead */
  export type Variables = Search_PriceGuideTool_TransactionTableQueryVariables;
  /** @deprecated use Search_PriceGuideTool_TransactionTableQuery instead */
  export type Query = Search_PriceGuideTool_TransactionTableQuery;
}
export namespace DataServicesPriceGuideToolEstimatesContainer {
  /** @deprecated use DataServices_PriceGuideToolEstimatesContainerQueryVariables instead */
  export type Variables =
    DataServices_PriceGuideToolEstimatesContainerQueryVariables;
  /** @deprecated use DataServices_PriceGuideToolEstimatesContainerQuery instead */
  export type Query = DataServices_PriceGuideToolEstimatesContainerQuery;
}
export namespace PriceGuideToolFormContainerData {
  /** @deprecated use PriceGuideToolFormContainerDataFragment instead */
  export type Fragment = PriceGuideToolFormContainerDataFragment;
}
export namespace CorePriceGuideToolFormContainer {
  /** @deprecated use Core_PriceGuideToolFormContainerQueryVariables instead */
  export type Variables = Core_PriceGuideToolFormContainerQueryVariables;
  /** @deprecated use Core_PriceGuideToolFormContainerQuery instead */
  export type Query = Core_PriceGuideToolFormContainerQuery;
}
export namespace SearchPriceGuideToolGearCollectionItems {
  /** @deprecated use Search_PriceGuideTool_GearCollectionItemsQueryVariables instead */
  export type Variables =
    Search_PriceGuideTool_GearCollectionItemsQueryVariables;
  /** @deprecated use Search_PriceGuideTool_GearCollectionItemsQuery instead */
  export type Query = Search_PriceGuideTool_GearCollectionItemsQuery;
}
export namespace SearchPriceGuideToolTransactionGraph {
  /** @deprecated use Search_PriceGuideToolTransactionGraphQueryVariables instead */
  export type Variables = Search_PriceGuideToolTransactionGraphQueryVariables;
  /** @deprecated use Search_PriceGuideToolTransactionGraphQuery instead */
  export type Query = Search_PriceGuideToolTransactionGraphQuery;
}
export namespace PriceWithShippingSortListingData {
  /** @deprecated use PriceWithShippingSortListingDataFragment instead */
  export type Fragment = PriceWithShippingSortListingDataFragment;
}
export namespace CMSPageEducationSectionData {
  /** @deprecated use CMSPageEducationSectionDataFragment instead */
  export type Fragment = CMSPageEducationSectionDataFragment;
}
export namespace ProductFamilyHeaderFields {
  /** @deprecated use ProductFamilyHeaderFieldsFragment instead */
  export type Fragment = ProductFamilyHeaderFieldsFragment;
}
export namespace CoreProductFamilyPageLayout {
  /** @deprecated use Core_ProductFamilyPage_LayoutQueryVariables instead */
  export type Variables = Core_ProductFamilyPage_LayoutQueryVariables;
  /** @deprecated use Core_ProductFamilyPage_LayoutQuery instead */
  export type Query = Core_ProductFamilyPage_LayoutQuery;
}
export namespace CoreUpdateUniversalPromoCampaign {
  /** @deprecated use Core_Update_Universal_Promo_CampaignMutationVariables instead */
  export type Variables = Core_Update_Universal_Promo_CampaignMutationVariables;
  /** @deprecated use Core_Update_Universal_Promo_CampaignMutation instead */
  export type Mutation = Core_Update_Universal_Promo_CampaignMutation;
}
export namespace CoreUniversalPromoCampaign {
  /** @deprecated use Core_Universal_Promo_CampaignQueryVariables instead */
  export type Variables = Core_Universal_Promo_CampaignQueryVariables;
  /** @deprecated use Core_Universal_Promo_CampaignQuery instead */
  export type Query = Core_Universal_Promo_CampaignQuery;
}
export namespace CoreRecentlyViewedCspsRow {
  /** @deprecated use Core_RecentlyViewedCspsRowQueryVariables instead */
  export type Variables = Core_RecentlyViewedCspsRowQueryVariables;
  /** @deprecated use Core_RecentlyViewedCspsRowQuery instead */
  export type Query = Core_RecentlyViewedCspsRowQuery;
}
export namespace CoreRecommendedCspsRow {
  /** @deprecated use Core_RecommendedCspsRowQueryVariables instead */
  export type Variables = Core_RecommendedCspsRowQueryVariables;
  /** @deprecated use Core_RecommendedCspsRowQuery instead */
  export type Query = Core_RecommendedCspsRowQuery;
}
export namespace MeRecommendedListingsGrid {
  /** @deprecated use MeRecommendedListingsGridQueryVariables instead */
  export type Variables = MeRecommendedListingsGridQueryVariables;
  /** @deprecated use MeRecommendedListingsGridQuery instead */
  export type Query = MeRecommendedListingsGridQuery;
}
export namespace MeRecommendedListings {
  /** @deprecated use MeRecommendedListingsQueryVariables instead */
  export type Variables = MeRecommendedListingsQueryVariables;
  /** @deprecated use MeRecommendedListingsQuery instead */
  export type Query = MeRecommendedListingsQuery;
}
export namespace CPListingsRecommendations {
  /** @deprecated use CPListingsRecommendationsQueryVariables instead */
  export type Variables = CPListingsRecommendationsQueryVariables;
  /** @deprecated use CPListingsRecommendationsQuery instead */
  export type Query = CPListingsRecommendationsQuery;
}
export namespace LoggedOutRecommendations {
  /** @deprecated use LoggedOutRecommendationsQueryVariables instead */
  export type Variables = LoggedOutRecommendationsQueryVariables;
  /** @deprecated use LoggedOutRecommendationsQuery instead */
  export type Query = LoggedOutRecommendationsQuery;
}
export namespace RecommendedShopsRowListingFields {
  /** @deprecated use RecommendedShopsRowListingFieldsFragment instead */
  export type Fragment = RecommendedShopsRowListingFieldsFragment;
}
export namespace CoreListingRecommendedShopsRow {
  /** @deprecated use Core_Listing_RecommendedShopsRowQueryVariables instead */
  export type Variables = Core_Listing_RecommendedShopsRowQueryVariables;
  /** @deprecated use Core_Listing_RecommendedShopsRowQuery instead */
  export type Query = Core_Listing_RecommendedShopsRowQuery;
}
export namespace NoCountFilterFragment {
  /** @deprecated use NoCountFilterFragmentFragment instead */
  export type Fragment = NoCountFilterFragmentFragment;
}
export namespace CoreSellFlowMatchedSearch {
  /** @deprecated use Core_SellFlow_Matched_SearchQueryVariables instead */
  export type Variables = Core_SellFlow_Matched_SearchQueryVariables;
  /** @deprecated use Core_SellFlow_Matched_SearchQuery instead */
  export type Query = Core_SellFlow_Matched_SearchQuery;
}
export namespace CoreSellFlowUnmatchedSearch {
  /** @deprecated use Core_SellFlow_Unmatched_SearchQueryVariables instead */
  export type Variables = Core_SellFlow_Unmatched_SearchQueryVariables;
  /** @deprecated use Core_SellFlow_Unmatched_SearchQuery instead */
  export type Query = Core_SellFlow_Unmatched_SearchQuery;
}
export namespace CoreCrossPlatformMobileFetchPhotos {
  /** @deprecated use Core_CrossPlatform_MobileFetchPhotosQueryVariables instead */
  export type Variables = Core_CrossPlatform_MobileFetchPhotosQueryVariables;
  /** @deprecated use Core_CrossPlatform_MobileFetchPhotosQuery instead */
  export type Query = Core_CrossPlatform_MobileFetchPhotosQuery;
}
export namespace CoreCrossPlatformMobileStartSession {
  /** @deprecated use Core_CrossPlatform_MobileStartSessionMutationVariables instead */
  export type Variables =
    Core_CrossPlatform_MobileStartSessionMutationVariables;
  /** @deprecated use Core_CrossPlatform_MobileStartSessionMutation instead */
  export type Mutation = Core_CrossPlatform_MobileStartSessionMutation;
}
export namespace CoreCrossPlatformMobileCancelSession {
  /** @deprecated use Core_CrossPlatform_MobileCancelSessionMutationVariables instead */
  export type Variables =
    Core_CrossPlatform_MobileCancelSessionMutationVariables;
  /** @deprecated use Core_CrossPlatform_MobileCancelSessionMutation instead */
  export type Mutation = Core_CrossPlatform_MobileCancelSessionMutation;
}
export namespace CoreCrossPlatformMobileFetchStatus {
  /** @deprecated use Core_CrossPlatform_MobileFetchStatusQueryVariables instead */
  export type Variables = Core_CrossPlatform_MobileFetchStatusQueryVariables;
  /** @deprecated use Core_CrossPlatform_MobileFetchStatusQuery instead */
  export type Query = Core_CrossPlatform_MobileFetchStatusQuery;
}
export namespace CoreCrossPlatformMobileSubmitPhotos {
  /** @deprecated use Core_CrossPlatform_MobileSubmitPhotosMutationVariables instead */
  export type Variables =
    Core_CrossPlatform_MobileSubmitPhotosMutationVariables;
  /** @deprecated use Core_CrossPlatform_MobileSubmitPhotosMutation instead */
  export type Mutation = Core_CrossPlatform_MobileSubmitPhotosMutation;
}
export namespace ListingSellCardData {
  /** @deprecated use ListingSellCardDataFragment instead */
  export type Fragment = ListingSellCardDataFragment;
}
export namespace CoreSellFormSubmitShopData {
  /** @deprecated use Core_SellForm_SubmitShopDataMutationVariables instead */
  export type Variables = Core_SellForm_SubmitShopDataMutationVariables;
  /** @deprecated use Core_SellForm_SubmitShopDataMutation instead */
  export type Mutation = Core_SellForm_SubmitShopDataMutation;
}
export namespace ShopAddressFields {
  /** @deprecated use ShopAddressFieldsFragment instead */
  export type Fragment = ShopAddressFieldsFragment;
}
export namespace SellFormSetupData {
  /** @deprecated use SellFormSetupDataFragment instead */
  export type Fragment = SellFormSetupDataFragment;
}
export namespace CoreSellFormSetupPage {
  /** @deprecated use Core_SellForm_SetupPageQueryVariables instead */
  export type Variables = Core_SellForm_SetupPageQueryVariables;
  /** @deprecated use Core_SellForm_SetupPageQuery instead */
  export type Query = Core_SellForm_SetupPageQuery;
}
export namespace CoreTradeInCSP {
  /** @deprecated use Core_Trade_In_CSPQueryVariables instead */
  export type Variables = Core_Trade_In_CSPQueryVariables;
  /** @deprecated use Core_Trade_In_CSPQuery instead */
  export type Query = Core_Trade_In_CSPQuery;
}
export namespace SellSearchRowListing {
  /** @deprecated use SellSearchRowListingFragment instead */
  export type Fragment = SellSearchRowListingFragment;
}
export namespace CoreSellerQuickStartRowMyDraftsSearch {
  /** @deprecated use Core_SellerQuickStartRow_MyDraftsSearchQueryVariables instead */
  export type Variables = Core_SellerQuickStartRow_MyDraftsSearchQueryVariables;
  /** @deprecated use Core_SellerQuickStartRow_MyDraftsSearchQuery instead */
  export type Query = Core_SellerQuickStartRow_MyDraftsSearchQuery;
}
export namespace CoreSellerQuickStartRowMyGearCollectionSearch {
  /** @deprecated use Core_SellerQuickStartRow_MyGearCollectionSearchQueryVariables instead */
  export type Variables =
    Core_SellerQuickStartRow_MyGearCollectionSearchQueryVariables;
  /** @deprecated use Core_SellerQuickStartRow_MyGearCollectionSearchQuery instead */
  export type Query = Core_SellerQuickStartRow_MyGearCollectionSearchQuery;
}
export namespace CoreSellerQuickStartRowMyBuyerOrderSearch {
  /** @deprecated use Core_SellerQuickStartRow_MyBuyerOrderSearchQueryVariables instead */
  export type Variables =
    Core_SellerQuickStartRow_MyBuyerOrderSearchQueryVariables;
  /** @deprecated use Core_SellerQuickStartRow_MyBuyerOrderSearchQuery instead */
  export type Query = Core_SellerQuickStartRow_MyBuyerOrderSearchQuery;
}
export namespace ShippingProfilePrices {
  /** @deprecated use ShippingProfilePricesFragment instead */
  export type Fragment = ShippingProfilePricesFragment;
}
export namespace CoreSellFormEditListingLayout {
  /** @deprecated use Core_SellForm_EditListingLayoutQueryVariables instead */
  export type Variables = Core_SellForm_EditListingLayoutQueryVariables;
  /** @deprecated use Core_SellForm_EditListingLayoutQuery instead */
  export type Query = Core_SellForm_EditListingLayoutQuery;
}
export namespace CoreSellFormNewListingLayout {
  /** @deprecated use Core_SellForm_NewListingLayoutQueryVariables instead */
  export type Variables = Core_SellForm_NewListingLayoutQueryVariables;
  /** @deprecated use Core_SellForm_NewListingLayoutQuery instead */
  export type Query = Core_SellForm_NewListingLayoutQuery;
}
export namespace CoreListingUpdate {
  /** @deprecated use Core_Listing_UpdateMutationVariables instead */
  export type Variables = Core_Listing_UpdateMutationVariables;
  /** @deprecated use Core_Listing_UpdateMutation instead */
  export type Mutation = Core_Listing_UpdateMutation;
}
export namespace SellFormViewCountryFields {
  /** @deprecated use SellFormViewCountryFieldsFragment instead */
  export type Fragment = SellFormViewCountryFieldsFragment;
}
export namespace CoreCrossPlatformDesktopFetchStatus {
  /** @deprecated use Core_CrossPlatform_DesktopFetchStatusQueryVariables instead */
  export type Variables = Core_CrossPlatform_DesktopFetchStatusQueryVariables;
  /** @deprecated use Core_CrossPlatform_DesktopFetchStatusQuery instead */
  export type Query = Core_CrossPlatform_DesktopFetchStatusQuery;
}
export namespace CoreCrossPlatformDesktopUpdateSession {
  /** @deprecated use Core_CrossPlatform_DesktopUpdateSessionMutationVariables instead */
  export type Variables =
    Core_CrossPlatform_DesktopUpdateSessionMutationVariables;
  /** @deprecated use Core_CrossPlatform_DesktopUpdateSessionMutation instead */
  export type Mutation = Core_CrossPlatform_DesktopUpdateSessionMutation;
}
export namespace CoreCrossPlatformAuthToken {
  /** @deprecated use Core_CrossPlatform_AuthTokenQueryVariables instead */
  export type Variables = Core_CrossPlatform_AuthTokenQueryVariables;
  /** @deprecated use Core_CrossPlatform_AuthTokenQuery instead */
  export type Query = Core_CrossPlatform_AuthTokenQuery;
}
export namespace CoreSellFormListingCorrection {
  /** @deprecated use Core_SellForm_ListingCorrectionMutationVariables instead */
  export type Variables = Core_SellForm_ListingCorrectionMutationVariables;
  /** @deprecated use Core_SellForm_ListingCorrectionMutation instead */
  export type Mutation = Core_SellForm_ListingCorrectionMutation;
}
export namespace SellFormCanonicalProductCardData {
  /** @deprecated use SellFormCanonicalProductCardDataFragment instead */
  export type Fragment = SellFormCanonicalProductCardDataFragment;
}
export namespace CoreSellFormEstimatedNewListingPrice {
  /** @deprecated use Core_SellForm_EstimatedNewListingPriceQueryVariables instead */
  export type Variables = Core_SellForm_EstimatedNewListingPriceQueryVariables;
  /** @deprecated use Core_SellForm_EstimatedNewListingPriceQuery instead */
  export type Query = Core_SellForm_EstimatedNewListingPriceQuery;
}
export namespace DataServicesSellFormPriceScore {
  /** @deprecated use DataServices_SellForm_PriceScoreQueryVariables instead */
  export type Variables = DataServices_SellForm_PriceScoreQueryVariables;
  /** @deprecated use DataServices_SellForm_PriceScoreQuery instead */
  export type Query = DataServices_SellForm_PriceScoreQuery;
}
export namespace CoreSellFormRedeemPromotionalCode {
  /** @deprecated use Core_SellForm_RedeemPromotionalCodeMutationVariables instead */
  export type Variables = Core_SellForm_RedeemPromotionalCodeMutationVariables;
  /** @deprecated use Core_SellForm_RedeemPromotionalCodeMutation instead */
  export type Mutation = Core_SellForm_RedeemPromotionalCodeMutation;
}
export namespace CoreSellFormReviewSectionPhotos {
  /** @deprecated use Core_SellForm_ReviewSectionPhotosQueryVariables instead */
  export type Variables = Core_SellForm_ReviewSectionPhotosQueryVariables;
  /** @deprecated use Core_SellForm_ReviewSectionPhotosQuery instead */
  export type Query = Core_SellForm_ReviewSectionPhotosQuery;
}
export namespace SearchSellFormLiveListingsTable {
  /** @deprecated use Search_SellForm_LiveListingsTableQueryVariables instead */
  export type Variables = Search_SellForm_LiveListingsTableQueryVariables;
  /** @deprecated use Search_SellForm_LiveListingsTableQuery instead */
  export type Query = Search_SellForm_LiveListingsTableQuery;
}
export namespace DataServicesSellFormPricingRecommendations {
  /** @deprecated use DataServices_SellForm__PricingRecommendationsQueryVariables instead */
  export type Variables =
    DataServices_SellForm__PricingRecommendationsQueryVariables;
  /** @deprecated use DataServices_SellForm__PricingRecommendationsQuery instead */
  export type Query = DataServices_SellForm__PricingRecommendationsQuery;
}
export namespace CoreSellFormCSPSeedData {
  /** @deprecated use Core_SellForm_CSPSeedDataQueryVariables instead */
  export type Variables = Core_SellForm_CSPSeedDataQueryVariables;
  /** @deprecated use Core_SellForm_CSPSeedDataQuery instead */
  export type Query = Core_SellForm_CSPSeedDataQuery;
}
export namespace SellFormShopShipping {
  /** @deprecated use SellFormShopShippingFragment instead */
  export type Fragment = SellFormShopShippingFragment;
}
export namespace CoreSellFormFetchPackageSizeSuggestion {
  /** @deprecated use Core_SellForm_FetchPackageSizeSuggestionQueryVariables instead */
  export type Variables =
    Core_SellForm_FetchPackageSizeSuggestionQueryVariables;
  /** @deprecated use Core_SellForm_FetchPackageSizeSuggestionQuery instead */
  export type Query = Core_SellForm_FetchPackageSizeSuggestionQuery;
}
export namespace HydrateSellForm {
  /** @deprecated use HydrateSellFormFragment instead */
  export type Fragment = HydrateSellFormFragment;
}
export namespace CorePromotionalCodeVerify {
  /** @deprecated use Core_PromotionalCodeVerifyQueryVariables instead */
  export type Variables = Core_PromotionalCodeVerifyQueryVariables;
  /** @deprecated use Core_PromotionalCodeVerifyQuery instead */
  export type Query = Core_PromotionalCodeVerifyQuery;
}
export namespace SellCardData {
  /** @deprecated use SellCardDataFragment instead */
  export type Fragment = SellCardDataFragment;
}
export namespace CSPPriceRecommendationData {
  /** @deprecated use CSPPriceRecommendationDataFragment instead */
  export type Fragment = CSPPriceRecommendationDataFragment;
}
export namespace SellCardWithCSPPriceRecommendationData {
  /** @deprecated use SellCardWithCSPPriceRecommendationDataFragment instead */
  export type Fragment = SellCardWithCSPPriceRecommendationDataFragment;
}
export namespace CoreSellFlowSearch {
  /** @deprecated use Core_SellFlow_SearchQueryVariables instead */
  export type Variables = Core_SellFlow_SearchQueryVariables;
  /** @deprecated use Core_SellFlow_SearchQuery instead */
  export type Query = Core_SellFlow_SearchQuery;
}
export namespace CoreTradeInEligibility {
  /** @deprecated use Core_Trade_In_EligibilityQueryVariables instead */
  export type Variables = Core_Trade_In_EligibilityQueryVariables;
  /** @deprecated use Core_Trade_In_EligibilityQuery instead */
  export type Query = Core_Trade_In_EligibilityQuery;
}
export namespace CoreSellSearchAutocomplete {
  /** @deprecated use Core_Sell_Search_AutocompleteQueryVariables instead */
  export type Variables = Core_Sell_Search_AutocompleteQueryVariables;
  /** @deprecated use Core_Sell_Search_AutocompleteQuery instead */
  export type Query = Core_Sell_Search_AutocompleteQuery;
}
export namespace CoreSellerDashboardListing {
  /** @deprecated use CoreSellerDashboardListingFragment instead */
  export type Fragment = CoreSellerDashboardListingFragment;
}
export namespace CoreSellerDashboardListings {
  /** @deprecated use Core_Seller_Dashboard_ListingsQueryVariables instead */
  export type Variables = Core_Seller_Dashboard_ListingsQueryVariables;
  /** @deprecated use Core_Seller_Dashboard_ListingsQuery instead */
  export type Query = Core_Seller_Dashboard_ListingsQuery;
}
export namespace CoreSellerDashboardRevenue {
  /** @deprecated use Core_Seller_Dashboard_RevenueQueryVariables instead */
  export type Variables = Core_Seller_Dashboard_RevenueQueryVariables;
  /** @deprecated use Core_Seller_Dashboard_RevenueQuery instead */
  export type Query = Core_Seller_Dashboard_RevenueQuery;
}
export namespace CoreMyShopCampaignsUpdate {
  /** @deprecated use Core_MyShopCampaigns_UpdateMutationVariables instead */
  export type Variables = Core_MyShopCampaigns_UpdateMutationVariables;
  /** @deprecated use Core_MyShopCampaigns_UpdateMutation instead */
  export type Mutation = Core_MyShopCampaigns_UpdateMutation;
}
export namespace CoreMainCategories {
  /** @deprecated use Core_Main_CategoriesQueryVariables instead */
  export type Variables = Core_Main_CategoriesQueryVariables;
  /** @deprecated use Core_Main_CategoriesQuery instead */
  export type Query = Core_Main_CategoriesQuery;
}
export namespace CoreMyShopCampaignsIndex {
  /** @deprecated use Core_MyShopCampaigns_IndexQueryVariables instead */
  export type Variables = Core_MyShopCampaigns_IndexQueryVariables;
  /** @deprecated use Core_MyShopCampaigns_IndexQuery instead */
  export type Query = Core_MyShopCampaigns_IndexQuery;
}
export namespace CoreMyShopCampaignsQuickEdit {
  /** @deprecated use Core_MyShopCampaigns_QuickEditMutationVariables instead */
  export type Variables = Core_MyShopCampaigns_QuickEditMutationVariables;
  /** @deprecated use Core_MyShopCampaigns_QuickEditMutation instead */
  export type Mutation = Core_MyShopCampaigns_QuickEditMutation;
}
export namespace CoreMyShopCampaignsNewLayout {
  /** @deprecated use Core_MyShopCampaigns_NewLayoutQueryVariables instead */
  export type Variables = Core_MyShopCampaigns_NewLayoutQueryVariables;
  /** @deprecated use Core_MyShopCampaigns_NewLayoutQuery instead */
  export type Query = Core_MyShopCampaigns_NewLayoutQuery;
}
export namespace ShopCampaignSummaryTable {
  /** @deprecated use ShopCampaignSummaryTableFragment instead */
  export type Fragment = ShopCampaignSummaryTableFragment;
}
export namespace CorePayoutsMyDirectCheckoutProfile {
  /** @deprecated use Core_Payouts_MyDirectCheckoutProfileQueryVariables instead */
  export type Variables = Core_Payouts_MyDirectCheckoutProfileQueryVariables;
  /** @deprecated use Core_Payouts_MyDirectCheckoutProfileQuery instead */
  export type Query = Core_Payouts_MyDirectCheckoutProfileQuery;
}
export namespace ShopCardFields {
  /** @deprecated use ShopCardFieldsFragment instead */
  export type Fragment = ShopCardFieldsFragment;
}
export namespace MyShopOrderRefundStats {
  /** @deprecated use MyShopOrderRefundStatsQueryVariables instead */
  export type Variables = MyShopOrderRefundStatsQueryVariables;
  /** @deprecated use MyShopOrderRefundStatsQuery instead */
  export type Query = MyShopOrderRefundStatsQuery;
}
export namespace CoreShopSettingsAuGstLayout {
  /** @deprecated use Core_ShopSettings_AuGstLayoutQueryVariables instead */
  export type Variables = Core_ShopSettings_AuGstLayoutQueryVariables;
  /** @deprecated use Core_ShopSettings_AuGstLayoutQuery instead */
  export type Query = Core_ShopSettings_AuGstLayoutQuery;
}
export namespace CoreShopSettingsCreateTaxIdentification {
  /** @deprecated use Core_ShopSettings_CreateTaxIdentificationMutationVariables instead */
  export type Variables =
    Core_ShopSettings_CreateTaxIdentificationMutationVariables;
  /** @deprecated use Core_ShopSettings_CreateTaxIdentificationMutation instead */
  export type Mutation = Core_ShopSettings_CreateTaxIdentificationMutation;
}
export namespace CoreShopSettingsUpdateTaxIdentification {
  /** @deprecated use Core_ShopSettings_UpdateTaxIdentificationMutationVariables instead */
  export type Variables =
    Core_ShopSettings_UpdateTaxIdentificationMutationVariables;
  /** @deprecated use Core_ShopSettings_UpdateTaxIdentificationMutation instead */
  export type Mutation = Core_ShopSettings_UpdateTaxIdentificationMutation;
}
export namespace CoreShopSettingsDeactivateTaxIdentification {
  /** @deprecated use Core_ShopSettings_DeactivateTaxIdentificationMutationVariables instead */
  export type Variables =
    Core_ShopSettings_DeactivateTaxIdentificationMutationVariables;
  /** @deprecated use Core_ShopSettings_DeactivateTaxIdentificationMutation instead */
  export type Mutation = Core_ShopSettings_DeactivateTaxIdentificationMutation;
}
export namespace CoreSecondaryUsersMySecondaryUsers {
  /** @deprecated use Core_SecondaryUsers_MySecondaryUsersQueryVariables instead */
  export type Variables = Core_SecondaryUsers_MySecondaryUsersQueryVariables;
  /** @deprecated use Core_SecondaryUsers_MySecondaryUsersQuery instead */
  export type Query = Core_SecondaryUsers_MySecondaryUsersQuery;
}
export namespace CoreSecondaryUsersDeleteUserShopPermission {
  /** @deprecated use Core_SecondaryUsers_DeleteUserShopPermissionMutationVariables instead */
  export type Variables =
    Core_SecondaryUsers_DeleteUserShopPermissionMutationVariables;
  /** @deprecated use Core_SecondaryUsers_DeleteUserShopPermissionMutation instead */
  export type Mutation = Core_SecondaryUsers_DeleteUserShopPermissionMutation;
}
export namespace CoreSecondaryUsersExportActivities {
  /** @deprecated use Core_SecondaryUsers_ExportActivitiesMutationVariables instead */
  export type Variables = Core_SecondaryUsers_ExportActivitiesMutationVariables;
  /** @deprecated use Core_SecondaryUsers_ExportActivitiesMutation instead */
  export type Mutation = Core_SecondaryUsers_ExportActivitiesMutation;
}
export namespace CoreSecondaryUsersCreateUserShopPermission {
  /** @deprecated use Core_SecondaryUsers_CreateUserShopPermissionMutationVariables instead */
  export type Variables =
    Core_SecondaryUsers_CreateUserShopPermissionMutationVariables;
  /** @deprecated use Core_SecondaryUsers_CreateUserShopPermissionMutation instead */
  export type Mutation = Core_SecondaryUsers_CreateUserShopPermissionMutation;
}
export namespace CoreSecondaryUsersUpdateUserShopPermission {
  /** @deprecated use Core_SecondaryUsers_UpdateUserShopPermissionMutationVariables instead */
  export type Variables =
    Core_SecondaryUsers_UpdateUserShopPermissionMutationVariables;
  /** @deprecated use Core_SecondaryUsers_UpdateUserShopPermissionMutation instead */
  export type Mutation = Core_SecondaryUsers_UpdateUserShopPermissionMutation;
}
export namespace CoreShopSettingsVatLayout {
  /** @deprecated use Core_ShopSettings_VatLayoutQueryVariables instead */
  export type Variables = Core_ShopSettings_VatLayoutQueryVariables;
  /** @deprecated use Core_ShopSettings_VatLayoutQuery instead */
  export type Query = Core_ShopSettings_VatLayoutQuery;
}
export namespace CoreUserSubscriptions {
  /** @deprecated use Core_User_SubscriptionsQueryVariables instead */
  export type Variables = Core_User_SubscriptionsQueryVariables;
  /** @deprecated use Core_User_SubscriptionsQuery instead */
  export type Query = Core_User_SubscriptionsQuery;
}
export namespace UpdateEmailSubscription {
  /** @deprecated use Update_Email_SubscriptionMutationVariables instead */
  export type Variables = Update_Email_SubscriptionMutationVariables;
  /** @deprecated use Update_Email_SubscriptionMutation instead */
  export type Mutation = Update_Email_SubscriptionMutation;
}
export namespace SetEmailGlobalSubscribe {
  /** @deprecated use Set_Email_Global_SubscribeMutationVariables instead */
  export type Variables = Set_Email_Global_SubscribeMutationVariables;
  /** @deprecated use Set_Email_Global_SubscribeMutation instead */
  export type Mutation = Set_Email_Global_SubscribeMutation;
}
export namespace TriggerOptInEmail {
  /** @deprecated use Trigger_Opt_In_EmailMutationVariables instead */
  export type Variables = Trigger_Opt_In_EmailMutationVariables;
  /** @deprecated use Trigger_Opt_In_EmailMutation instead */
  export type Mutation = Trigger_Opt_In_EmailMutation;
}
export namespace RequestTaxFormDownload {
  /** @deprecated use RequestTaxFormDownloadMutationVariables instead */
  export type Variables = RequestTaxFormDownloadMutationVariables;
  /** @deprecated use RequestTaxFormDownloadMutation instead */
  export type Mutation = RequestTaxFormDownloadMutation;
}
export namespace FetchTaxFormDownload {
  /** @deprecated use FetchTaxFormDownloadMutationVariables instead */
  export type Variables = FetchTaxFormDownloadMutationVariables;
  /** @deprecated use FetchTaxFormDownloadMutation instead */
  export type Mutation = FetchTaxFormDownloadMutation;
}
export namespace CoreMyTaxProfileQuery {
  /** @deprecated use Core_My_TaxProfile_QueryQueryVariables instead */
  export type Variables = Core_My_TaxProfile_QueryQueryVariables;
  /** @deprecated use Core_My_TaxProfile_QueryQuery instead */
  export type Query = Core_My_TaxProfile_QueryQuery;
}
export namespace CoreDeleteTaxProfile {
  /** @deprecated use Core_DeleteTaxProfileMutationVariables instead */
  export type Variables = Core_DeleteTaxProfileMutationVariables;
  /** @deprecated use Core_DeleteTaxProfileMutation instead */
  export type Mutation = Core_DeleteTaxProfileMutation;
}
export namespace CoreUpsertTaxProfile {
  /** @deprecated use Core_UpsertTaxProfileMutationVariables instead */
  export type Variables = Core_UpsertTaxProfileMutationVariables;
  /** @deprecated use Core_UpsertTaxProfileMutation instead */
  export type Mutation = Core_UpsertTaxProfileMutation;
}
export namespace CoreCreateTradeIn {
  /** @deprecated use Core_CreateTradeInMutationVariables instead */
  export type Variables = Core_CreateTradeInMutationVariables;
  /** @deprecated use Core_CreateTradeInMutation instead */
  export type Mutation = Core_CreateTradeInMutation;
}
export namespace CoreTradeInCPEligibility {
  /** @deprecated use Core_Trade_In_CP_EligibilityQueryVariables instead */
  export type Variables = Core_Trade_In_CP_EligibilityQueryVariables;
  /** @deprecated use Core_Trade_In_CP_EligibilityQuery instead */
  export type Query = Core_Trade_In_CP_EligibilityQuery;
}
export namespace CartApplePayHookFields {
  /** @deprecated use CartApplePayHookFieldsFragment instead */
  export type Fragment = CartApplePayHookFieldsFragment;
}
export namespace CoreCheckoutStartApplePayCheckout {
  /** @deprecated use Core_Checkout_StartApplePayCheckoutMutationVariables instead */
  export type Variables = Core_Checkout_StartApplePayCheckoutMutationVariables;
  /** @deprecated use Core_Checkout_StartApplePayCheckoutMutation instead */
  export type Mutation = Core_Checkout_StartApplePayCheckoutMutation;
}
export namespace CoreCheckoutUpdateApplePayShippingEstimate {
  /** @deprecated use Core_Checkout_UpdateApplePayShippingEstimateMutationVariables instead */
  export type Variables =
    Core_Checkout_UpdateApplePayShippingEstimateMutationVariables;
  /** @deprecated use Core_Checkout_UpdateApplePayShippingEstimateMutation instead */
  export type Mutation = Core_Checkout_UpdateApplePayShippingEstimateMutation;
}
export namespace Item2ProductViewTrackingData {
  /** @deprecated use Item2ProductViewTrackingDataFragment instead */
  export type Fragment = Item2ProductViewTrackingDataFragment;
}
export namespace UserAvailableActions {
  /** @deprecated use User_Available_ActionsQueryVariables instead */
  export type Variables = User_Available_ActionsQueryVariables;
  /** @deprecated use User_Available_ActionsQuery instead */
  export type Query = User_Available_ActionsQuery;
}
export namespace CoreUserWatchlistLayout {
  /** @deprecated use Core_User_Watchlist_LayoutQueryVariables instead */
  export type Variables = Core_User_Watchlist_LayoutQueryVariables;
  /** @deprecated use Core_User_Watchlist_LayoutQuery instead */
  export type Query = Core_User_Watchlist_LayoutQuery;
}
export namespace MarketplaceQuerySuggestions {
  /** @deprecated use MarketplaceQuerySuggestionsQueryVariables instead */
  export type Variables = MarketplaceQuerySuggestionsQueryVariables;
  /** @deprecated use MarketplaceQuerySuggestionsQuery instead */
  export type Query = MarketplaceQuerySuggestionsQuery;
}
export namespace CoreWithSuggestionCheckListingsSearch {
  /** @deprecated use Core_WithSuggestionCheck_ListingsSearchQueryVariables instead */
  export type Variables = Core_WithSuggestionCheck_ListingsSearchQueryVariables;
  /** @deprecated use Core_WithSuggestionCheck_ListingsSearchQuery instead */
  export type Query = Core_WithSuggestionCheck_ListingsSearchQuery;
}
export namespace CoreAcceptTradeIn {
  /** @deprecated use Core_AcceptTradeInMutationVariables instead */
  export type Variables = Core_AcceptTradeInMutationVariables;
  /** @deprecated use Core_AcceptTradeInMutation instead */
  export type Mutation = Core_AcceptTradeInMutation;
}
export namespace CoreRejectTradeIn {
  /** @deprecated use Core_RejectTradeInMutationVariables instead */
  export type Variables = Core_RejectTradeInMutationVariables;
  /** @deprecated use Core_RejectTradeInMutation instead */
  export type Mutation = Core_RejectTradeInMutation;
}
export namespace CoreDealerTradeIn {
  /** @deprecated use Core_Dealer_Trade_InQueryVariables instead */
  export type Variables = Core_Dealer_Trade_InQueryVariables;
  /** @deprecated use Core_Dealer_Trade_InQuery instead */
  export type Query = Core_Dealer_Trade_InQuery;
}
export namespace CoreDealerTradeInsIndex {
  /** @deprecated use Core_Dealer_Trade_Ins_IndexQueryVariables instead */
  export type Variables = Core_Dealer_Trade_Ins_IndexQueryVariables;
  /** @deprecated use Core_Dealer_Trade_Ins_IndexQuery instead */
  export type Query = Core_Dealer_Trade_Ins_IndexQuery;
}
export namespace SellerHubBumpStats {
  /** @deprecated use SellerHub_BumpStatsQueryVariables instead */
  export type Variables = SellerHub_BumpStatsQueryVariables;
  /** @deprecated use SellerHub_BumpStatsQuery instead */
  export type Query = SellerHub_BumpStatsQuery;
}
export namespace SellerHubEarnings {
  /** @deprecated use SellerHub_EarningsQueryVariables instead */
  export type Variables = SellerHub_EarningsQueryVariables;
  /** @deprecated use SellerHub_EarningsQuery instead */
  export type Query = SellerHub_EarningsQuery;
}
export namespace SellerHubSellerFeedback {
  /** @deprecated use SellerHub_SellerFeedbackQueryVariables instead */
  export type Variables = SellerHub_SellerFeedbackQueryVariables;
  /** @deprecated use SellerHub_SellerFeedbackQuery instead */
  export type Query = SellerHub_SellerFeedbackQuery;
}
export namespace SellerHubNewSellerPrompt {
  /** @deprecated use SellerHub_NewSellerPromptQueryVariables instead */
  export type Variables = SellerHub_NewSellerPromptQueryVariables;
  /** @deprecated use SellerHub_NewSellerPromptQuery instead */
  export type Query = SellerHub_NewSellerPromptQuery;
}
export namespace SellerHubProfile {
  /** @deprecated use SellerHub_ProfileQueryVariables instead */
  export type Variables = SellerHub_ProfileQueryVariables;
  /** @deprecated use SellerHub_ProfileQuery instead */
  export type Query = SellerHub_ProfileQuery;
}
export namespace SellerHubRecentMessages {
  /** @deprecated use SellerHub_RecentMessagesQueryVariables instead */
  export type Variables = SellerHub_RecentMessagesQueryVariables;
  /** @deprecated use SellerHub_RecentMessagesQuery instead */
  export type Query = SellerHub_RecentMessagesQuery;
}
export namespace SellerHubRecentOffers {
  /** @deprecated use SellerHub_RecentOffersQueryVariables instead */
  export type Variables = SellerHub_RecentOffersQueryVariables;
  /** @deprecated use SellerHub_RecentOffersQuery instead */
  export type Query = SellerHub_RecentOffersQuery;
}
export namespace SellerHubRecentOrders {
  /** @deprecated use SellerHub_RecentOrdersQueryVariables instead */
  export type Variables = SellerHub_RecentOrdersQueryVariables;
  /** @deprecated use SellerHub_RecentOrdersQuery instead */
  export type Query = SellerHub_RecentOrdersQuery;
}
export namespace SellerHubRecommendedDirectOffers {
  /** @deprecated use SellerHub_RecommendedDirectOffersQueryVariables instead */
  export type Variables = SellerHub_RecommendedDirectOffersQueryVariables;
  /** @deprecated use SellerHub_RecommendedDirectOffersQuery instead */
  export type Query = SellerHub_RecommendedDirectOffersQuery;
}
export namespace SellerHubRecommendedDirectOffer {
  /** @deprecated use SellerHub_RecommendedDirectOfferQueryVariables instead */
  export type Variables = SellerHub_RecommendedDirectOfferQueryVariables;
  /** @deprecated use SellerHub_RecommendedDirectOfferQuery instead */
  export type Query = SellerHub_RecommendedDirectOfferQuery;
}
export namespace SellerHubRecommendedPriceDrops {
  /** @deprecated use SellerHub_RecommendedPriceDropsQueryVariables instead */
  export type Variables = SellerHub_RecommendedPriceDropsQueryVariables;
  /** @deprecated use SellerHub_RecommendedPriceDropsQuery instead */
  export type Query = SellerHub_RecommendedPriceDropsQuery;
}
export namespace SellerHubRecommendedPriceDrop {
  /** @deprecated use SellerHub_RecommendedPriceDropQueryVariables instead */
  export type Variables = SellerHub_RecommendedPriceDropQueryVariables;
  /** @deprecated use SellerHub_RecommendedPriceDropQuery instead */
  export type Query = SellerHub_RecommendedPriceDropQuery;
}
export namespace SellerHubShopBadges {
  /** @deprecated use SellerHub_ShopBadgesQueryVariables instead */
  export type Variables = SellerHub_ShopBadgesQueryVariables;
  /** @deprecated use SellerHub_ShopBadgesQuery instead */
  export type Query = SellerHub_ShopBadgesQuery;
}
export namespace SellerHubShopStats {
  /** @deprecated use SellerHub_ShopStatsQueryVariables instead */
  export type Variables = SellerHub_ShopStatsQueryVariables;
  /** @deprecated use SellerHub_ShopStatsQuery instead */
  export type Query = SellerHub_ShopStatsQuery;
}
export namespace SearchMyListingsRecommendations {
  /** @deprecated use Search_MyListings_RecommendationsQueryVariables instead */
  export type Variables = Search_MyListings_RecommendationsQueryVariables;
  /** @deprecated use Search_MyListings_RecommendationsQuery instead */
  export type Query = Search_MyListings_RecommendationsQuery;
}
export namespace DigitalDetailsFields {
  /** @deprecated use DigitalDetailsFieldsFragment instead */
  export type Fragment = DigitalDetailsFieldsFragment;
}
export namespace CoreListingDigitalDetails {
  /** @deprecated use Core_Listing_DigitalDetailsQueryVariables instead */
  export type Variables = Core_Listing_DigitalDetailsQueryVariables;
  /** @deprecated use Core_Listing_DigitalDetailsQuery instead */
  export type Query = Core_Listing_DigitalDetailsQuery;
}
export namespace Discover {
  /** @deprecated use DiscoverQueryVariables instead */
  export type Variables = DiscoverQueryVariables;
  /** @deprecated use DiscoverQuery instead */
  export type Query = DiscoverQuery;
}
export namespace CoreFeedSearch {
  /** @deprecated use Core_Feed_SearchQueryVariables instead */
  export type Variables = Core_Feed_SearchQueryVariables;
  /** @deprecated use Core_Feed_SearchQuery instead */
  export type Query = Core_Feed_SearchQuery;
}
export namespace MixedFeedRow {
  /** @deprecated use MixedFeedRowFragment instead */
  export type Fragment = MixedFeedRowFragment;
}
export namespace RemoveFeedEntry {
  /** @deprecated use RemoveFeedEntryMutationVariables instead */
  export type Variables = RemoveFeedEntryMutationVariables;
  /** @deprecated use RemoveFeedEntryMutation instead */
  export type Mutation = RemoveFeedEntryMutation;
}
export namespace CoreHomePageFeed {
  /** @deprecated use Core_HomePage_FeedQueryVariables instead */
  export type Variables = Core_HomePage_FeedQueryVariables;
  /** @deprecated use Core_HomePage_FeedQuery instead */
  export type Query = Core_HomePage_FeedQuery;
}
export namespace CoreAdyenBalanceShop {
  /** @deprecated use Core_AdyenBalanceShopQueryVariables instead */
  export type Variables = Core_AdyenBalanceShopQueryVariables;
  /** @deprecated use Core_AdyenBalanceShopQuery instead */
  export type Query = Core_AdyenBalanceShopQuery;
}
export namespace CoreInformActVerificationStatus {
  /** @deprecated use Core_InformActVerification_StatusQueryVariables instead */
  export type Variables = Core_InformActVerification_StatusQueryVariables;
  /** @deprecated use Core_InformActVerification_StatusQuery instead */
  export type Query = Core_InformActVerification_StatusQuery;
}
export namespace CoreTruliooVerificationAndPolicyData {
  /** @deprecated use Core_Trulioo_Verification_And_Policy_DataQueryVariables instead */
  export type Variables =
    Core_Trulioo_Verification_And_Policy_DataQueryVariables;
  /** @deprecated use Core_Trulioo_Verification_And_Policy_DataQuery instead */
  export type Query = Core_Trulioo_Verification_And_Policy_DataQuery;
}
export namespace CoreRestartTruliooOnboardingWorkflow {
  /** @deprecated use Core_RestartTruliooOnboardingWorkflowMutationVariables instead */
  export type Variables =
    Core_RestartTruliooOnboardingWorkflowMutationVariables;
  /** @deprecated use Core_RestartTruliooOnboardingWorkflowMutation instead */
  export type Mutation = Core_RestartTruliooOnboardingWorkflowMutation;
}
export namespace CoreTruliooOnboardingRecordResendPscEmail {
  /** @deprecated use Core_TruliooOnboardingRecordResendPscEmailMutationVariables instead */
  export type Variables =
    Core_TruliooOnboardingRecordResendPscEmailMutationVariables;
  /** @deprecated use Core_TruliooOnboardingRecordResendPscEmailMutation instead */
  export type Mutation = Core_TruliooOnboardingRecordResendPscEmailMutation;
}
export namespace CoreAdyenOnboardingLinkCreate {
  /** @deprecated use Core_AdyenOnboardingLink_CreateMutationVariables instead */
  export type Variables = Core_AdyenOnboardingLink_CreateMutationVariables;
  /** @deprecated use Core_AdyenOnboardingLink_CreateMutation instead */
  export type Mutation = Core_AdyenOnboardingLink_CreateMutation;
}
export namespace AdyenOnboardingForm {
  /** @deprecated use AdyenOnboardingFormQueryVariables instead */
  export type Variables = AdyenOnboardingFormQueryVariables;
  /** @deprecated use AdyenOnboardingFormQuery instead */
  export type Query = AdyenOnboardingFormQuery;
}
export namespace CoreSellerVerificationSetShopType {
  /** @deprecated use Core_SellerVerification_SetShopTypeMutationVariables instead */
  export type Variables = Core_SellerVerification_SetShopTypeMutationVariables;
  /** @deprecated use Core_SellerVerification_SetShopTypeMutation instead */
  export type Mutation = Core_SellerVerification_SetShopTypeMutation;
}
export namespace CoreSellerVerificationShopData {
  /** @deprecated use Core_SellerVerification_ShopDataQueryVariables instead */
  export type Variables = Core_SellerVerification_ShopDataQueryVariables;
  /** @deprecated use Core_SellerVerification_ShopDataQuery instead */
  export type Query = Core_SellerVerification_ShopDataQuery;
}
export namespace CoreSellerVerificationCreateTruliooOnboardingRecord {
  /** @deprecated use Core_SellerVerification_CreateTruliooOnboardingRecordMutationVariables instead */
  export type Variables =
    Core_SellerVerification_CreateTruliooOnboardingRecordMutationVariables;
  /** @deprecated use Core_SellerVerification_CreateTruliooOnboardingRecordMutation instead */
  export type Mutation =
    Core_SellerVerification_CreateTruliooOnboardingRecordMutation;
}
export namespace CoreSellerVerificationPrepublishStatus {
  /** @deprecated use Core_SellerVerification_PrepublishStatusQueryVariables instead */
  export type Variables =
    Core_SellerVerification_PrepublishStatusQueryVariables;
  /** @deprecated use Core_SellerVerification_PrepublishStatusQuery instead */
  export type Query = Core_SellerVerification_PrepublishStatusQuery;
}
export namespace SetupShopPaymentMethods {
  /** @deprecated use SetupShopPaymentMethodsMutationVariables instead */
  export type Variables = SetupShopPaymentMethodsMutationVariables;
  /** @deprecated use SetupShopPaymentMethodsMutation instead */
  export type Mutation = SetupShopPaymentMethodsMutation;
}
export namespace UpdateMyShop {
  /** @deprecated use UpdateMyShopMutationVariables instead */
  export type Variables = UpdateMyShopMutationVariables;
  /** @deprecated use UpdateMyShopMutation instead */
  export type Mutation = UpdateMyShopMutation;
}
